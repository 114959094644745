import React from "react";

import { push as Push } from "connected-react-router";
import { connect as Connect } from "react-redux";

import { LEVEL, SCOPE } from "~/constants";
import { SelectorsAction } from "~/store/notification";
import * as i18n from "~/util/i18n";
import Logger from "~/util/logger";

export const NAME = "ErrorBoundary";

export const PROP = {
    types: {},
    defaults: {},
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {};

    componentDidCatch(error, info) {
        const { dispatch } = this.props;
        dispatch(
            SelectorsAction.showNotification({
                message: i18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.DESKTOP, SCOPE.LOGIN],
            }),
        );
        Logger.trace("Catched [%s] '%s': %s", error.name, error.message, info.componentStack);
        dispatch(Push("/"));
    }

    static getDerivedStateFromError(error) {
        return { error };
    }

    render() {
        const { children } = this.props;
        return children;
    }
}

export default Connect()(Component);
