import {
    COMMA,
    DETAIL_LESS_ID_FORMS,
    DOT,
    EMPTY_STR,
    GLOBAL_UNEXPECTED_ERROR_KEY,
    ID_FORM,
    KEEP_FORM_STATE_URLS,
    KEEP_STATE_IN_URL_SCOPE,
    PIPE_SEPARATOR,
} from "~/constants";
import UtilLodash from "~/util/lodash";

import { get as Get } from "./i18n";

const getField = (formFieldList, idField) => formFieldList.filter((formField) => formField.idField === idField)[0];

const hasFieldValue = (formFieldValues, idField) => {
    const expression = formFieldValues && formFieldValues[idField] ? formFieldValues[idField] : null;
    return Array.isArray(expression) ? expression.length > 0 : expression != null;
};

const TRUE = 1;
const FALSE = 2;
const HAS_VALUE = 3;
const HAS_VALUE_FIELD = 4;
const HAS_NOT_VALUE = 5;
const HAS_NOT_VALUE_FIELD = 6;
const SHOWN = 7;
const SHOWN_FIELD = 8;
const HIDDEN = 9;
const HIDDEN_FIELD = 10;
const VALUE = 11;
const VALUE_FIELD = 12;
const VALUE_OPERATOR = 13;
const VALUE_RIGHT_SIDE = 14;
const TYPE = 15;
const ID_FIELD = 16;
const KEY_FIELD_TYPE = 17;
const VALUE_OPERATOR_TYPE = 18;
const VALUE_SELECTED = 19;
const KEY_FIELD_TYPE_HAS_PROPERTY = 20;
const VALUE_OPERATOR_TYPE_HAS_PROPERTY = 21;
const VALUE_SELECTED_HAS_PROPERTY = 22;
const ANY_VALUE = 23;
const ANY_VALUE_FIELDS = 24;
const ANY_VALUE_OPERATOR = 25;
const ANY_VALUE_RIGHT_SIDE = 26;
const ALL_VALUES = 27;
const ALL_VALUES_FIELDS = 28;
const ALL_VALUES_OPERATOR = 29;
const ALL_VALUES_RIGHT_SIDE = 30;
const ANY_FIELD_HAS_VALUE = 31;
const ANY_FIELD_HAS_VALUE_FIELDS = 32;
const ALL_FIELDS_HAVE_VALUE = 33;
const ALL_FIELDS_HAVE_VALUE_FIELDS = 34;
const ALL_FIELDS_ARE_EMPTY = 35;
const ALL_FIELDS_ARE_EMPTY_FIELDS = 36;

const operators = {
    "==": (leftSide, rightSide) => leftSide === rightSide,
    "!=": (leftSide, rightSide) => leftSide !== rightSide,
    ">": (leftSide, rightSide) => leftSide > rightSide,
    ">=": (leftSide, rightSide) => leftSide >= rightSide,
    "<": (leftSide, rightSide) => leftSide < rightSide,
    "<=": (leftSide, rightSide) => leftSide <= rightSide,
};

export const isNumeric = (val) => !Number.isNaN(val - parseFloat(val));

function evalSimpleValue(leftSide, operator, rightSide) {
    if (isNumeric(leftSide) && isNumeric(rightSide)) {
        return operators[operator](Number(leftSide), Number(rightSide));
    }
    if (isNumeric(leftSide) || isNumeric(rightSide)) {
        // not matching types
    } else if (typeof leftSide === "string" && typeof rightSide === "string") {
        if (operator === "==") {
            return leftSide === rightSide;
        }
        if (operator === "!=") {
            return leftSide !== rightSide;
        }
        return false;
    }
    return false;
}

export const evalOperation = (leftSide, operator, rightSide) => {
    if (Array.isArray(leftSide)) {
        if (operator === "!=") {
            /* if all values evals to true */
            return leftSide.every((ls) => evalOperation(ls, operator, rightSide));
        }
        /* if any value evals to true */
        return leftSide.some((ls) => evalOperation(ls, operator, rightSide));
    }
    let rightTokens = rightSide ? rightSide.toString().split(PIPE_SEPARATOR) : EMPTY_STR;
    if (!(rightTokens.length > 1)) {
        rightTokens = rightSide ? rightSide.toString().split("&") : EMPTY_STR;
    }
    if (rightTokens.length > 1) {
        /** we don't support using combined or's and and's */
        if (rightSide.indexOf(PIPE_SEPARATOR) !== -1) {
            return rightTokens.some((token) => evalSimpleValue(leftSide, operator, token));
        }
        return rightTokens.some((token) => !evalSimpleValue(leftSide, operator, token));
    }
    const isValid = evalSimpleValue(leftSide, operator, rightSide);
    if (typeof isValid === "boolean") {
        return isValid;
    }
    /* if not array or numeric or string, we got mismatched types */
    if (operator === "!=") {
        return true;
    }
    return false;
};

// This method is intended to validate several expressions.
// It returns an array of booleans as the result of each individual validation
const evalSeveral = (fieldValues, fields, operator, values) => {
    const evalValues = [];

    for (let i = 0; i < fields.length; i += 1) {
        let leftSide;
        const fieldTokens = fields[i].split(DOT);

        if (fieldTokens.length === 1) {
            // Get the value with the field name
            leftSide = (fieldValues && fieldValues[fields[i]]) || null;

            if (leftSide !== null && leftSide.value) {
                leftSide = typeof values[i] === "string" ? UtilLodash.toString(leftSide.value) : leftSide.value;
            } else if (UtilLodash.isObject(leftSide)) {
                const array = Object.values(leftSide);
                const [currency] = array;

                leftSide = currency;
            }
        } else if (fieldTokens.length === 2) {
            // Get the value with the field name and then take only a sub part of it,
            // for example debitAccount.currency
            const fieldValue = fieldValues && fieldValues[fieldTokens[0]];

            if (fieldValue) {
                const fieldValueEntries = Object.entries(fieldValue);

                // The desired token's value is selected as the leftSide
                for (let j = 0; j < fieldValueEntries.length; j += 1) {
                    const [entryKey, entryValue] = fieldValueEntries[j];

                    if (entryKey === fieldTokens[1]) {
                        leftSide = typeof values[i] === "string" ? UtilLodash.toString(entryValue) : entryValue;

                        break;
                    }
                }
            }
        }

        evalValues.push(evalOperation(leftSide, operator, values[i]));
    }

    return evalValues;
};

// This method is intended to validate whether each field within a list has value or not
// It returns an array of booleans as the result of each individual validation
const severalFieldsHaveValue = (fieldValues, fields) => {
    const evalValues = [];

    for (let i = 0; i < fields.length; i += 1) {
        const fieldTokens = fields[i].split(DOT);
        let valueToValidate;
        let result;

        // FIELD VALUE IS A PRIMITIVE, so it is retrieved using the field name (e.g. "reference")
        if (fieldTokens.length === 1) {
            valueToValidate = (fieldValues && fieldValues[fields[i]]) || null;
        } else if (fieldTokens.length === 2) {
            // FIELD VALUE IS AN OBJECT, so the field is retrieved and -first token-,
            // then the desired value -second token- (e.g. "debitAccount.currency")
            const fieldValue = fieldValues && fieldValues[fieldTokens[0]];

            // Field is retrieved
            if (fieldValue) {
                const fieldValueEntries = Object.entries(fieldValue);

                // The desired subValue is selected
                for (let j = 0; j < fieldValueEntries.length; j += 1) {
                    const [entryKey, entryValue] = fieldValueEntries[j];

                    if (entryKey === fieldTokens[1]) {
                        valueToValidate = entryValue;

                        break;
                    }
                }
            }
        }

        if (!valueToValidate) {
            result = false;
        } else if (Array.isArray(valueToValidate)) {
            result = valueToValidate.length > 0;
        } else if (typeof valueToValidate === "string") {
            result = valueToValidate.trim() !== EMPTY_STR;
        } else {
            result = valueToValidate != null;
        }

        evalValues.push(result);
    }

    // Array of booleans with the result of each individual validation
    return evalValues;
};

const isQuality = (qualityName, idField, fieldList, fieldValues) => {
    let quality = false;
    const field = getField(fieldList, idField);
    const regex = new RegExp(
        [
            "^",
            "(TRUE)|",
            "(FALSE)|",
            "(hasValue\\((.+)\\))|",
            "(hasNotValue\\((.+)\\))|",
            "(shown\\((.+)\\))|",
            "(hidden\\((.+)\\))|",
            "(value\\((.+)\\) (==|!=|<|<=|>=|>) '(.*)')|",
            "(type) (.*) '(.*)' (==|!=|<|<=|>=|>) '(.*)'|",
            "(typeHasProperty) (.*) (.*)|",
            "(anyValue\\((.+)\\) (==|!=|<|<=|>=|>) \\((.+)\\))|",
            "(allValues\\((.+)\\) (==|!=|<|<=|>=|>) \\((.+)\\))|",
            "(anyFieldHasValue\\((.+)\\))|",
            "(allFieldsHaveValue\\((.+)\\))|",
            "(allFieldsAreEmpty\\((.+)\\))",
            "$",
        ].join(EMPTY_STR),
    );
    const match = regex.exec(field[qualityName]);
    if (match !== null) {
        if (match[TRUE] !== undefined) {
            quality = true;
        } else if (match[FALSE] !== undefined) {
            quality = false;
        } else if (match[HAS_VALUE] !== undefined) {
            quality = hasFieldValue(fieldValues, match[HAS_VALUE_FIELD]);
        } else if (match[HAS_NOT_VALUE] !== undefined) {
            quality = !hasFieldValue(fieldValues, match[HAS_NOT_VALUE_FIELD]);
        } else if (match[SHOWN] !== undefined) {
            quality = isQuality(qualityName, match[SHOWN_FIELD], fieldList, fieldValues);
        } else if (match[HIDDEN] !== undefined) {
            quality = !isQuality(qualityName, match[HIDDEN_FIELD], fieldList, fieldValues);
        } else if (match[KEY_FIELD_TYPE_HAS_PROPERTY] !== undefined) {
            const objectType = fieldValues[match[VALUE_OPERATOR_TYPE_HAS_PROPERTY]];
            quality = objectType && objectType[match[VALUE_SELECTED_HAS_PROPERTY]];
        } else if (match[VALUE] !== undefined) {
            const operator = match[VALUE_OPERATOR];
            const rightSide = match[VALUE_RIGHT_SIDE];

            let leftSide;
            const fieldTokens = (match[VALUE_FIELD] || []).split(DOT);
            if (fieldTokens.length === 1) {
                // Get the value with the field name
                leftSide = (fieldValues && fieldValues[match[VALUE_FIELD]]) || null;
                if (leftSide !== null && leftSide.value) {
                    leftSide = leftSide.value;
                } else if (UtilLodash.isObject(leftSide)) {
                    const array = Object.values(leftSide);
                    const [currency] = array;
                    leftSide = currency;
                }
            } else if (fieldTokens.length === 2) {
                // Get the value with the field name and then take only a sub part of it,
                // for example debitAccount.currency
                const { fieldTokens: tokens } = fieldValues && fieldValues[fieldTokens[0]];
                [leftSide] = tokens;
            }
            quality = evalOperation(leftSide, operator, rightSide);
        } else if (match[TYPE] !== undefined) {
            const auxField = fieldList.filter((f) => match[ID_FIELD] === f.idField);

            if (fieldValues[match[ID_FIELD]].value) {
                const option = auxField[0].data.options.filter(
                    (opt) => fieldValues[match[ID_FIELD]].value === opt.id,
                )[0];

                const aux3 = option[match[KEY_FIELD_TYPE]];
                quality = evalOperation(aux3, match[VALUE_OPERATOR_TYPE], match[VALUE_SELECTED]);
            }
        } else if (match[ANY_VALUE] !== undefined) {
            // It would match any expression like
            // anyValue(field1, field2) OPERATOR (field1value, field2value)
            const fields = (match[ANY_VALUE_FIELDS] || []).split(COMMA);
            const values = (match[ANY_VALUE_RIGHT_SIDE] || []).split(COMMA);

            if (fields && values && fields.length !== values.length) {
                quality = false;
            } else {
                const operator = match[ANY_VALUE_OPERATOR];
                const evalValues = evalSeveral(fieldValues, fields, operator, values);

                quality = evalValues.some((element) => element === true);
            }
        } else if (match[ALL_VALUES] !== undefined) {
            // It would match any expression like
            // allValues(field1, field2) OPERATOR (field1value, field2value)
            const fields = (match[ALL_VALUES_FIELDS] || []).split(COMMA);
            const values = (match[ALL_VALUES_RIGHT_SIDE] || []).split(COMMA);

            if (fields && values && fields.length !== values.length) {
                quality = false;
            } else {
                const operator = match[ALL_VALUES_OPERATOR];
                const evalValues = evalSeveral(fieldValues, fields, operator, values);

                quality = evalValues.every((element) => element === true);
            }
        } else if (match[ANY_FIELD_HAS_VALUE] !== undefined) {
            // It would match any expression like
            // anyFieldHasValue(field1, field2, ..., fieldn)
            const fields = (match[ANY_FIELD_HAS_VALUE_FIELDS] || []).split(COMMA);

            if (fields.length > 0) {
                const evalValues = severalFieldsHaveValue(fieldValues, fields);

                quality = evalValues.some((element) => element === true);
            } else {
                quality = false;
            }
        } else if (match[ALL_FIELDS_HAVE_VALUE] !== undefined) {
            // It would match any expression like
            // anyFieldHasValue(field1, field2, ..., fieldn)
            const fields = (match[ALL_FIELDS_HAVE_VALUE_FIELDS] || []).split(COMMA);

            if (fields.length > 0) {
                const evalValues = severalFieldsHaveValue(fieldValues, fields);

                quality = evalValues.every((element) => element === true);
            } else {
                quality = false;
            }
        } else if (match[ALL_FIELDS_ARE_EMPTY] !== undefined) {
            // It would match any expression like
            // allFieldsAreEmpty(field1, field2, ..., fieldn)
            const fields = (match[ALL_FIELDS_ARE_EMPTY_FIELDS] || []).split(COMMA);

            if (fields.length > 0) {
                const evalValues = severalFieldsHaveValue(fieldValues, fields);

                quality = evalValues.every((element) => element === false);
            } else {
                quality = false;
            }
        }
    }

    return quality;
};

export const isVisible = (idField, fieldList, fieldValues) => isQuality("visible", idField, fieldList, fieldValues);

export const isRequired = (idField, fieldList, fieldValues) => isQuality("required", idField, fieldList, fieldValues);

export const isUseAlternativeLabel = (idField, fieldList, fieldValues) =>
    isQuality("useAlternativeLabel", idField, fieldList, fieldValues);

export const adjustIdFieldErrors = (errors, isI18N = false) => {
    const newErrors = {};
    Object.keys(errors).forEach((key) => {
        const error = errors[key];
        newErrors[key.replace(/^_/, EMPTY_STR)] = isI18N ? Get(error) : error;
    });
    return newErrors;
};

export const getTouchedFields = (errors) => {
    const touchedFields = {};
    const noField = "NO_FIELD";
    Object.keys(errors).forEach((key) => {
        if (key !== noField) {
            touchedFields[key] = true;
        }
    });

    return touchedFields;
};

export const getResponseMessage = (data) => {
    let message;

    if (data.data.NO_FIELD) {
        message = data.data.NO_FIELD;
    } else if (data.message) {
        message = data.message;
    } else {
        message = Get.get(GLOBAL_UNEXPECTED_ERROR_KEY);
    }
    return message;
};

export const credentialsWithUnderscore = (credentials) =>
    Object.entries(credentials).reduce((pairs, [key, value]) => ({ ...pairs, [`_${key}`]: value }), {});

export const hasIncorrectCredentials = (credentials, data) => Object.keys(credentials).some((key) => data.data[key]);

export const shouldKeepFormState = (state, nextRoute) =>
    KEEP_FORM_STATE_URLS.some((url) => {
        let result = state.prevRoute ? state.prevRoute.pathname.indexOf(url) !== -1 : state.selectedBanks;
        result = result || nextRoute.indexOf(url) !== -1;
        return result;
    });

export const shouldKeepStateInUrlScope = (nextRoute) =>
    KEEP_STATE_IN_URL_SCOPE.some((url) => {
        return nextRoute.indexOf(url) !== -1;
    });

export const checkIdFormInRoute = (nextRoute, idForm) => {
    return nextRoute.indexOf(idForm) !== -1;
};

export const transactionWithoutDetail = (idForm) => DETAIL_LESS_ID_FORMS.some((id) => id === idForm);

export const credentialsToUnderscoreFormat = (credentials) =>
    Object.entries(credentials).reduce((values, [key, value]) => ({ ...values, [`_${key}`]: value }), {});

export const isFromPaymentTransactionLines = (pathname, idForm) => {
    const isSalaryOrSuppliersPayment = idForm === ID_FORM.SALARY_PAYMENT || idForm === ID_FORM.SUPPLIERS_PAYMENT;
    const isBackFromTransactionLines = pathname.includes("/transaction/");
    return isBackFromTransactionLines && isSalaryOrSuppliersPayment;
};
