import React from "react";

import { push as Push } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, PRODUCT_TYPE, statementDateFormat as dateFormat } from "~/constants";
import { SelectorsAction as SelectorsActionAccount, SelectorsStore as SelectorsStoreAccount } from "~/store/account";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import * as UtilsDate from "~/util/date";
import DeviceUtils from "~/util/device";
import UtilLodash from "~/util/lodash";

import { LANG } from "~/components/DataDate";
import I18n from "~/components/I18n";
import SelectButton from "~/components/SelectButton";

export const PROP = {
    types: {
        account: PropTypes.shape({}),
        bankStatementsAccounts: PropTypes.array,
        label: PropTypes.string,
    },
    defaults: {
        account: {},
        bankStatementsAccounts: null,
        label: EMPTY_STR,
    },
};

export const NAME = "OperationsButton";

export function Component({ account, dispatch, bankStatementsAccounts, label, statements, lang }) {
    const { idProduct, permissions, productType } = account || {};

    const { transferInternal, transferForeign, transferLocal, transferThirdParties, requestCheckbook } =
        permissions || {};
    const hasStatements = !UtilLodash.isEmpty(bankStatementsAccounts);
    const isMobile = DeviceUtils.isMobileNative();
    const code = LANG[lang];
    const monthFormatter = new Intl.DateTimeFormat(code, { month: "long" });
    const yearFormatter = new Intl.DateTimeFormat(code, { year: "numeric" });

    const operations = [];

    if (transferInternal) {
        operations.push(
            <div
                key="menu.options.transfers.internal"
                onClick={() => dispatch(Push(`/form/transferInternal?debitAccount=${idProduct}`))}>
                <I18n id="menu.options.transfers.internal" />
            </div>,
        );
    }

    if (transferThirdParties) {
        operations.push(
            <div
                key="menu.options.transfers.thirdParties"
                onClick={() => dispatch(Push(`/form/transferThirdParties?debitAccount=${idProduct}`))}>
                <I18n id="menu.options.transfers.thirdParties" />
            </div>,
        );
    }

    if (transferLocal) {
        operations.push(
            <div
                key="menu.options.transfers.local"
                onClick={() => dispatch(Push(`/form/transferLocal?debitAccount=${idProduct}`))}>
                <I18n id="menu.options.transfers.local" />
            </div>,
        );
    }

    if (transferForeign) {
        operations.push(
            <div
                key="menu.options.transfers.foreign"
                onClick={() => dispatch(Push(`/form/transferForeign?debitAccount=${idProduct}`))}>
                <I18n id="menu.options.transfers.foreign" />
            </div>,
        );
    }

    if (requestCheckbook && productType === PRODUCT_TYPE.CHECKING_ACCOUNT) {
        operations.push(
            <div
                key="menu.accounts.requestCheckbook"
                onClick={() => dispatch(Push(`/form/requestCheckbook?debitAccount=${idProduct}`))}>
                <I18n id="menu.accounts.requestCheckbook" />
            </div>,
        );
    }

    if (hasStatements) {
        if (isMobile) {
            operations.push(
                <div
                    key="accounts.stateOfAccount"
                    onClick={() => dispatch(Push(`/accounts/${idProduct}/stateaccount`))}>
                    <I18n id="accounts.stateOfAccount" />
                </div>,
            );
        } else {
            const statementsChildren = statements.map(({ date, origen }) => {
                const dateAsDate = UtilsDate.forceGMT(UtilsDate.toDate(date));

                return {
                    labelValue: `${monthFormatter.format(dateAsDate)} ${yearFormatter.format(dateAsDate)}`,
                    onClick: () => {
                        const specificDate = UtilsDate.specificDate(dateAsDate, dateFormat);

                        dispatch(SelectorsActionAccount.pdfStateAccount({ date: specificDate, origen }));
                    },
                };
            });

            operations.push(
                <div
                    key="accounts.stateOfAccount"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}>
                    <I18n id="accounts.stateOfAccount" />
                    <ul>
                        {statementsChildren.map(({ labelValue, onClick }) => (
                            <li key={labelValue}>
                                <div onClick={onClick}>
                                    <I18n defaultValue={labelValue} />
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>,
            );
        }
    }

    if (productType !== PRODUCT_TYPE.CUI) {
        operations.push(
            <div key="accounts.change.of.alias" onClick={() => dispatch(Push(`/accounts/${idProduct}/setAlias`))}>
                <I18n id="accounts.change.of.alias" />
            </div>,
        );
    }

    operations.push(
        <div
            key="accounts.rates"
            onClick={() => dispatch(SelectorsActionAccount.setShowRatesModal({ showRatesModal: true }))}>
            <I18n id="accounts.rates" />
        </div>,
    );

    const exportChildren = [
        {
            key: "accounts.movements.filters.period.export.csv",
            onClick: () => {
                dispatch(SelectorsActionAccount.downloadMovements({ idAccount: idProduct, format: "csv" }));
            },
        },
        {
            key: "accounts.movements.filters.period.export.excel",
            onClick: () => {
                dispatch(SelectorsActionAccount.downloadMovements({ idAccount: idProduct, format: "xls" }));
            },
        },
        {
            key: "accounts.movements.filters.period.export.multicash",
            onClick: () => {
                dispatch(SelectorsActionAccount.setShowMulticashModal({ showMulticashModal: true }));
            },
        },
    ];

    operations.push(
        <div
            key="accounts.movements.filters.period.export"
            onClick={(e) => {
                e.stopPropagation();
            }}>
            <I18n id="accounts.movements.filters.period.export" />
            <ul>
                {exportChildren.map(({ key, onClick }) => (
                    <li key={key}>
                        <div onClick={onClick}>
                            <I18n id={key} />
                        </div>
                    </li>
                ))}
            </ul>
        </div>,
    );

    return <SelectButton label={label}>{operations}</SelectButton>;
}

Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

const mapStateToProps = (store) => {
    return {
        account: SelectorsStoreAccount.getSelectedAccount(store),
        isFetching: SelectorsStoreAccount.getFetching(store),
        bankStatementsAccounts: SelectorsStoreAccount.getStateOfAccount(store),
        statements: SelectorsStoreAccount.getStateOfAccount(store),
        lang: StoreI18n.getLang(store) || window.navigator.language.slice(0, 2),
    };
};

export default Connect(mapStateToProps)(Component);
