import { takeLatest as TakeLatest, call as Call, put as Put, select as Select } from "redux-saga/effects";

import { LEVEL, SCOPE, RESPONSE_TYPE, GLOBAL_UNEXPECTED_ERROR_KEY } from "~/constants";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { downloadPdf as downloadPDF } from "~/util/download";
import { adjustIdFieldErrors as AdjustIdFieldErrors } from "~/util/form";
import * as UtilsI18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsStore, SelectorsMiddleware } from "./_selectors";

export default [
    TakeLatest(TYPE.LOAD_LIST_PRE_SWIFT_MESSAGES_REQUEST, function* listSwifPretMessagesRequest() {
        const response = yield Call(SelectorsMiddleware.listSwiftPreMessagesRequest);

        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(SelectorsAction.listSwiftPreMessagesFailure());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.SWIFT],
                }),
            );
        } else {
            const { data } = response.data;
            yield Put(SelectorsAction.listSwiftPreMessagesSuccess(data));
        }
    }),
    TakeLatest(TYPE.LOAD_LIST_SWIFT_MESSAGES_REQUEST, function* listSwiftMessagesRequest() {
        const filters = yield Select(SelectorsStore.getFilters);
        const offset = yield Select(SelectorsStore.getOffset);

        const response = yield Call(SelectorsMiddleware.listSwiftMessagesRequest, { filters, offset });

        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(SelectorsAction.listSwiftMessagesFailure());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.SWIFT],
                }),
            );
        } else {
            const { data } = response.data;
            yield Put(SelectorsAction.listSwiftMessagesSuccess(data));
        }
    }),
    TakeLatest(TYPE.SWIFT_MESSAGES_DETAIL_REQUEST, function* swiftMessagesDetailRequest(props) {
        const response = yield Call(SelectorsMiddleware.swiftMessagesDetailRequest, props);

        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(SelectorsAction.swiftMessagesDetailFailure());
        } else {
            const { data } = response.data;
            yield Put(SelectorsAction.swiftMessagesDetailSuccess(data));
        }
    }),
    TakeLatest(TYPE.SWIFT_MESSAGES_DETAIL_SEND_MAIL_REQUEST, function* swiftMessagesDetailSendMailRequest(props) {
        const { formikBag } = props;
        const response = yield Call(SelectorsMiddleware.swiftMessagesDetailSendMailRequest, props);

        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(SelectorsAction.swiftMessagesDetailSendMailFailure());
            formikBag.setErrors(AdjustIdFieldErrors(response.data.data));
        } else {
            yield Put(SelectorsAction.swiftMessagesDetailSendMailSuccess());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("client.swift.sendMail.success"),
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.SWIFT_MESSAGE_DETAIL],
                }),
            );
        }
        formikBag.setSubmitting(false);
    }),
    TakeLatest(TYPE.PDF_SWIFT_MESSAGES_DETAIL_REQUEST, function* pdfSwiftMessagesDetailRequest(props) {
        const response = yield Call(SelectorsMiddleware.pdfSwiftMessagesDetailRequest, props);

        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(SelectorsAction.pdfSwiftMessagesDetailFailure());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.SWIFT_MESSAGE_DETAIL],
                }),
            );
        } else {
            const { content, fileName } = response.data.data;
            downloadPDF(fileName, content);
            yield Put(SelectorsAction.pdfSwiftMessagesDetailSuccess());
        }
    }),
];
