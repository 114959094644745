import { go as Go } from "connected-react-router";
import { takeLatest as TakeLatest, call as Call, put as Put } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE, SCREENS_BEFORE, GLOBAL_UNEXPECTED_ERROR_KEY } from "~/constants";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { credentialsToUnderscoreFormat } from "~/util/form";
import * as UtilI18n from "~/util/i18n";

const mapChannelsFormData = ({ idUser, caps }) => ({
    idUser,
    ...Object.entries(caps).reduce(
        ({ enabledChannels, maxAmounts, capFrequencies }, [channel, { amount, frequency }]) => ({
            enabledChannels: [...enabledChannels, channel === "topAmount" ? "all" : channel],
            maxAmounts: [...maxAmounts, amount],
            capFrequencies: [...capFrequencies, frequency],
        }),
        {
            enabledChannels: [],
            maxAmounts: [],
            capFrequencies: [],
        },
    ),
});

const loadChannelsRequest = (middleware, actions) =>
    function* loadChannelsRequestSaga(props) {
        // id
        const response = yield Call(middleware.loadChannelsRequest, props);

        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(actions.loadChannelsFailure());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION],
                }),
            );
        } else {
            const labelCurrency = UtilI18n.get(`currency.label.${response.data.data.currency}`);

            const enabledChannelsFrequencies = response.data.data.enabledChannelsFrequencies.map((channel) => ({
                value: channel,
                label: UtilI18n.get(`administration.channels.${channel}`),
            }));
            yield Put(
                actions.loadChannelsSuccess({ data: response.data.data, labelCurrency, enabledChannelsFrequencies }),
            );
        }
    };

const updateChannelsPreview = (middleware, actions) =>
    function* updateChannelsPreviewSaga(props) {
        const { data, setSubmitting } = props;
        const response = yield Call(middleware.updateChannelsPreview, { data: mapChannelsFormData(data) });

        setSubmitting(false);

        if (response.type === RESPONSE_TYPE.WARNING) {
            const errorMessage = response.data.data.NO_FIELD || UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY);

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION],
                }),
            );
        } else {
            const responseCredentials = yield Call(SelectorsMiddlewareForm.listCredentialsGroups, {
                idForm: null,
                idActivity: "administration.medium.modify.channels.send",
            });
            const credentialGroups = responseCredentials.data.data.groups;

            yield Put(actions.onSuccess(data.idUser));
            yield Put(actions.updateChannelsPreviewSuccess({ caps: data.caps, credentialGroups }));
        }
    };

const updateChannelsRequest = (middleware, actions) =>
    function* updateChannelsRequestSaga(props) {
        const { data, formikBag } = props;
        const { credentials, ...restOfParams } = data;
        const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);

        const response = yield Call(middleware.updateChannelsRequest, {
            data: {
                ...credentialsWithUnderscore,
                ...mapChannelsFormData(restOfParams),
            },
        });
        const { setSubmitting, setErrors } = formikBag;

        setSubmitting(false);

        if (response.type === RESPONSE_TYPE.WARNING) {
            const errorMessage = response.data.data.NO_FIELD || UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY);

            setErrors(response.data.data);
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION],
                }),
            );
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.ADMINISTRATION],
                }),
            );
            yield Put(Go(SCREENS_BEFORE.TWO_LESS));
            yield Put(actions.updateChannelsRequestSuccess());
        }
    };

const loadChannelsTicketRequest = (middleware, actions) =>
    function* loadChannelsTicketRequestSaga(props) {
        //  idTransaction
        const transactionResponse = yield Call(SelectorsMiddlewareForm.readTransaction, props);

        if (transactionResponse.type === RESPONSE_TYPE.WARNING) {
            yield Put(actions.loadChannelsTicketFailure());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION],
                }),
            );
        } else {
            // TODO: Revisar
            const {
                idUser,
                enabledChannels,
                maxAmounts,
                capFrequencies,
            } = transactionResponse.data.data.transaction.data;
            const channelsResponse = yield Call(middleware.loadChannelsRequest, { id: idUser });

            if (channelsResponse.type === RESPONSE_TYPE.WARNING) {
                yield Put(actions.loadChannelsTicketFailure());
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.ADMINISTRATION],
                    }),
                );
            } else {
                yield Put(actions.loadChannelsTicketSuccess());
                yield Put(
                    actions.loadChannelsSuccess({
                        data: {
                            ...channelsResponse.data.data,
                            ...enabledChannels.reduce(
                                ({ caps, topAmount }, channel, index) => {
                                    if (channel === "all") {
                                        return {
                                            caps,
                                            topAmount: {
                                                amount: maxAmounts[index],
                                                frequency: capFrequencies[index],
                                            },
                                        };
                                    }

                                    return {
                                        topAmount,
                                        caps: [
                                            ...caps,
                                            {
                                                channel,
                                                maximum: maxAmounts[index],
                                                frequency: capFrequencies[index],
                                            },
                                        ],
                                    };
                                },
                                { caps: [], topAmount: {} },
                            ),
                        },
                    }),
                );
            }
        }
    };
const sagas = (middleware, types, actions) => [
    TakeLatest(types.LOAD_CHANNELS_REQUEST, loadChannelsRequest(middleware, actions)),
    TakeLatest(types.UPDATE_CHANNELS_PREVIEW, updateChannelsPreview(middleware, actions)),
    TakeLatest(types.UPDATE_CHANNELS_REQUEST, updateChannelsRequest(middleware, actions)),
    TakeLatest(types.LOAD_CHANNELS_TICKET_REQUEST, loadChannelsTicketRequest(middleware, actions)),
];

export default sagas;
