import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreComex, SelectorsAction as SelectorsActionComex } from "~/store/comex";
import { SelectorsAction as SelectorsActionFile } from "~/store/files";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Ticket from "~/components/Ticket/Ticket";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const { transaction, ticketConfirmation, dispatch } = props;
    const { data } = transaction;

    const { subject, message, attachments, attachmentsNames } = data;

    const downloadFile = (idFile, fileName) => {
        dispatch(SelectorsActionFile.downloadFileRequest({ idFile, fileName }));
    };
    const fileIds = attachments ? attachments.split(",") : "";

    const shareTicket = (idTransactionToRead) => {
        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    };

    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}
                    handleModify={handleModify}
                    hasMobileComponent
                    displayDownloadPDF
                    handleDownloadPDF={() => shareTicket(transaction.idTransaction)}>
                    <Box flex directionColumn align="flex-start" className="mb-3">
                        <Box className="info-label">
                            <h4 className="bold">{UtilsI18n.get("comex.other.otherProcedures.step2.subject")}</h4>
                            <span>{subject}</span>
                        </Box>
                        {message && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get("comex.other.otherProcedures.step2.message")}</h4>
                                <span>{message}</span>
                            </Box>
                        )}
                        {attachments && (
                            <Box className="info-label">
                                <Box className="bold">
                                    {" "}
                                    {UtilsI18n.get("comex.other.otherProcedures.step2.attachments")}
                                </Box>
                                {fileIds.map((fileId, index) => (
                                    <Box className="data-wrapper">
                                        <span className="data-label">
                                            <a onClick={() => downloadFile(fileId, attachmentsNames[index])}>
                                                {attachmentsNames[index]}
                                            </a>
                                        </span>
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </Box>
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { idTransaction } = transaction;
        dispatch(
            SelectorsActionComex.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        dispatch(SelectorsActionComex.signTransactionPreview({ ...data }));
    }

    function handleModify() {
        const { idTransaction } = transaction;
        dispatch(SelectorsActionForm.modifyTransaction({ idTransactionToMove: idTransaction }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreComex.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
