import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import {
    SelectorsAction as SelectorsActionComexImport,
    SelectorsStore as SelectorsStoreComexImport,
} from "~/store/comex/import";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import HighOrder from "~/components/HighOrder";

import FormEdit from "./Step1Edit/Step1Edit";
import FormPreview from "./Step2Preview/Step2Preview";
import FormView from "./Step3View/Step3View";

export const NAME = "RequestEndorsement";

export const PROP = {
    types: {
        fetching: PropTypes.bool,
        mode: PropTypes.string.isRequired,
        ...TypesRedux,
    },
    defaults: {
        fetching: false,
        ...DefaultsRedux,
    },
};

export function Component(props) {
    const { fetching, mode } = props;

    return (
        <Container
            head-onBack={(mode !== MODE.EDIT && handleBack) || undefined}
            head-onBackWeb={mode === MODE.EDIT && handleBack}
            head-title={UtilsI18n.get("comex.import.requestEndorsement.title")}
            name={NAME}
            scopeToShowNotification={SCOPE.COMEX_IMPORT_REQUEST_ENDORSEMENT}
            step={mode}
            wait={fetching}>
            {mode === MODE.EDIT && <FormEdit />}
            {mode === MODE.PREVIEW && <FormPreview />}
            {mode === MODE.VIEW && <FormView ticketConfirmation />}
        </Container>
    );

    function handleBack() {
        const { dispatch, history } = props;

        if (mode === MODE.VIEW) {
            dispatch(SelectorsActionComex.restartFlow());
            dispatch(SelectorsActionComexImport.restartFlow());
        } else if (mode === MODE.EDIT) {
            history.goBack();
        } else {
            dispatch(SelectorsActionComex.toStepOne());
        }
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        fetching: SelectorsStoreComexImport.getFetching(store),
        mode: SelectorsStoreComexImport.getMode(store),
    };
};

export default HighOrder(Connect(mapStateToProps))(Component);
