import { ACTIVE, BLOCKED } from "~/constants";
import { MODE } from "~/constants/form";
import { TYPE as TypesBackoffice } from "~/store/backoffice";
import * as UtilsArray from "~/util/array";

import { PROP, TYPE } from "./_consts";

const groupFormValues = (state, group, permissions) => {
    if (!group) {
        return state.formValues;
    }

    return {
        description: group.description,
        name: group.name,
        permissions,
        status: group.blocked ? BLOCKED : ACTIVE,
        users: group.userList.map(({ idUser }) => idUser),
    };
};
export default (storeProp = PROP.defaults, action) => {
    const {
        data: {
            group: { blocked, description, name, userList } = {},
            adminGroup,
            adminUsers,
            availableGroups,
            availableUsers,
            credentialGroups,
            group,
            groups,
            idActivity,
            idTransaction,
            isAdminGroup,
            mode,
            permissions,
            selectedGroups,
            user,
        } = {},
        hasBack,
        hasClose,
        selectedGroupsIds,
    } = action;
    switch (action.type) {
        case TYPE.LOAD_GROUP_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.LOAD_GROUP_FORM_DATA_FAILURE:
        case TYPE.LOAD_GROUP_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.LOAD_GROUP_SUCCESS:
            return {
                name,
                description,
                adminUsers,
                adminGroup: isAdminGroup,
                groupStatus: blocked ? BLOCKED : ACTIVE,
                members: userList,
                fetching: false,
            };
        case TYPE.LOAD_GROUP_FORM_DATA_REQUEST:
            return { ...PROP.defaults, credentialGroups: storeProp.credentialGroups };
        case TYPE.LOAD_GROUP_FORM_DATA_SUCCESS:
            return {
                ...storeProp,
                availableUsers,
                adminUsers,
                adminGroup,
                fetching: false,
                formValues: groupFormValues(storeProp, group, permissions),
            };
        case TYPE.SUBMIT_GROUP_FORM_PREVIEW_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                formValues: {
                    name: action.formData.name,
                    description: action.formData.description,
                    status: action.formData.status,
                    permissions: action.formData.permissions,
                    users: action.formData.users,
                },
            };
        case TYPE.SUBMIT_GROUP_FORM_PREVIEW_SUCCESS:
            return { ...storeProp, credentialGroups, idTransaction, idActivity, mode, fetching: false };
        case TYPE.STEP_BACK_REQUEST:
            return { ...storeProp, mode: MODE.EDIT };
        case TYPE.SUBMIT_GROUP_FORM_REQUEST:
        case TYPE.LOAD_GROUPS_REQUEST:
            return { ...storeProp, fetching: true };
        case TYPE.SUBMIT_GROUP_FORM_SUCCESS:
        case TYPE.LOAD_GROUPS_FAILURE:
            return { ...storeProp, fetching: false };
        case TYPE.LOAD_GROUPS_SUCCESS:
            return { ...storeProp, availableGroups, selectedGroups, fetching: false };
        case TYPE.UPDATE_GROUPS_OF_USER_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                groupsToSave: storeProp.availableGroups.filter(
                    (groupAux) => selectedGroupsIds.indexOf(groupAux.idGroupAsString) > -1,
                ),
                credentialGroups,
                idTransaction,
                idActivity,
                fetching: false,
            };
        case TYPE.LOAD_GROUPS_OF_USER_TICKET_REQUEST:
            return { ...storeProp, fetching: true };
        case TYPE.LOAD_GROUPS_OF_USER_FAILURE:
            return { ...storeProp, fetching: false };
        case TYPE.LOAD_GROUPS_OF_USER_SUCCESS:
            return { ...storeProp, fetching: false, groups, user };
        case TYPE.UPDATE_HAS_CLOSE:
            return { ...storeProp, hasClose };
        case TYPE.UPDATE_HAS_BACK:
            return { ...storeProp, hasBack };
        case TYPE.RESET:
            return { ...storeProp, hasClose: false, hasBack: false };
        case TYPE.LOAD_FORM_SUCCESS: {
            return {
                ...storeProp,
                fetching: false,
                availableGroups,
                selectedGroups,
            };
        }
        case TypesBackoffice.READ_USER_DATA_SUCCESS:
            return {
                ...storeProp,
                availableUsers: UtilsArray.mapItemsIds(action.userData.availableUsers || [], "idUser"),
                fetching: false,
            };
        default:
            return { ...storeProp };
    }
};
