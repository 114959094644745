import React from "react";

import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { EMPTY_STR } from "~/constants";
import { STATUS } from "~/constants/transaction";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import {
    SelectorsStore as SelectorsStoreComexExport,
    SelectorsAction as SelectorsActionComexExport,
} from "~/store/comex/export";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import { specificDate as SpecificDate } from "~/util/date";
import DeviceUtils from "~/util/device";
import * as UtilsI18n from "~/util/i18n";
import * as numberUtils from "~/util/number";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import Token from "~/components/Token/Token";
import TicketPrintInformation from "~/pages/_components/TicketPrintInformation";

import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        isSubmitting: PropTypes.bool,
    },
    defaults: {
        isSubmitting: false,
    },
};

const printForm = () => {
    const elements = window.document.getElementsByClassName("no_print");

    for (let i = 0; i < elements.length; i += 1) {
        elements[i].style.display = "none";
    }

    window.print();
    for (let i = 0; i < elements.length; i += 1) {
        elements[i].style.display = EMPTY_STR;
    }

    return true;
};

export const buildLetter = (data, lang) => {
    if (data) {
        const {
            valueDate, // FECHA
            issuingBank, // Banco emisor
            operationNumber, // Numero de operacion
            transferAmount,
            creditLetterNumber,
            environment,
            dueDate,
            assigneeName, // Nombre del cesionario
            assigneeBank, // BANCO
            assigneeAccountNumber,
            expensesAssignment, // Gastos de la cesión
            pro, // A favor de
            currency,
            item,
        } = data;

        const buildAmount = (quantity, curr) =>
            `${UtilsI18n.get(`currency.label.${curr}`)} ${numberUtils.formatNumber(quantity, lang)} `;

        const i18nKey = `comex.export.cessionCreditLetter.letter.${pro === "TERCEROS" ? "third" : "bank"}`;

        const params = {
            FECHA: SpecificDate(valueDate),
            BANCO: issuingBank,
            NRO_OPERACION: operationNumber,
            IMPORTE: buildAmount(transferAmount, currency),
            IMPORTE_ORIGINAL: buildAmount(parseFloat(item.othersFields.IMPORTE), currency),
            NRO_LC: creditLetterNumber,
            AMBIENTE: environment,
            FECHA_VENCIMIENTO_OPERACION: SpecificDate(dueDate),
            NOMBRE_CESIONARIO: assigneeName,
            BANCO_CESIONARIO: assigneeBank,
            CUENTA_CESIONARIO: assigneeAccountNumber,
            GASTOS_CESION: expensesAssignment,
        };

        const letter = UtilsI18n.get(i18nKey, null, params);

        return <div dangerouslySetInnerHTML={{ __html: letter }} />;
    }
    return null;
};

export function Component(props) {
    const { isSubmitting, dispatch, formData, lang } = props;

    const shareTicket = () => {
        dispatch(
            SelectorsActionTransactions.shareTicketRequest({
                transactionData: formData,
                idActivity: "comex.export.cessionCreditLetter.send",
            }),
        );
    };

    return (
        <Form id={Style.ID}>
            <TicketPrintInformation />
            <Box flex directionColumn align="flex-start" className="mb-3 carta">
                {buildLetter(formData, lang)}
            </Box>

            <div className="no_print">
                <Token labelButton="global.send" isSubmitting={isSubmitting} />
            </div>
            {!DeviceUtils.isMobileNative() && (
                <div className="buttonsTicketConfirmation">
                    <Button
                        block={false}
                        bsStyle="link"
                        label="comex.preview.print.label"
                        onClick={() => printForm()}
                    />
                </div>
            )}
            <div className="buttonsTicketConfirmation">
                <Button bsStyle="link" block={false} label="forms.ticket.printPDF" onClick={() => shareTicket()} />
            </div>
        </Form>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        formData: SelectorsStoreComexExport.getComexFormData(store),
        lang: SelectorsStoreI18n.getLang(store),
        transaction: SelectorsStoreComexExport.getTransaction(store),
        user: SelectorsStoreSession.getUser(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithRouter,
    WithFormik({
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        validationSchema: (props) => {
            const { user } = props;

            return Yup.object().shape({
                otp: user.needsBiometric
                    ? Yup.string().nullable()
                    : Yup.string().required(UtilsI18n.get("comex.export.cessionCreditLetter.otp.required")),
            });
        },
        handleSubmit: ({ otp }, formikBag) => {
            const {
                dispatch,
                history,
                formData,
                transaction: { idTransaction, idActivity, idTransactionStatus },
            } = formikBag.props;

            const isFromTransactionsHistory = history.location.pathname.includes("/ticket");

            if (isFromTransactionsHistory && idTransactionStatus !== STATUS.DRAFT) {
                dispatch(
                    SelectorsActionComex.signTransaction({
                        idForm: null,
                        idActivity,
                        idTransaction,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            } else {
                dispatch(
                    SelectorsActionComexExport.cessionCreditLetterSendRequest({
                        formData,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            }
        },
    }),
)(Component);
