import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import {
    SelectorsAction as SelectorsActionTransfers,
    SelectorsStore as SelectorsStoreTranfers,
} from "~/store/transfers";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Ticket from "~/components/Ticket/Ticket";

import getAlias from "../../util/aliasEnrollmentUtil";
import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const { dispatch, ticketConfirmation, transaction } = props;
    const { data } = transaction || {};
    const { aliasData, accountToDelete, enrolledCurrencies } = data;

    const { label, currency } = accountToDelete;

    const alias = getAlias(aliasData);

    const shareTicket = (idTransactionToRead) => {
        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    };

    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}
                    hasMobileComponent
                    displayDownloadPDF
                    handleDownloadPDF={() => shareTicket(transaction.idTransaction)}>
                    <Box directionColumn align="flex-start" className="mb-3 data-ticket">
                        <Box className="data-wrapper">
                            <span className="data-label">
                                {UtilsI18n.get("transfers.alias.deletion.step3.account")}
                            </span>
                            <span>{label}</span>
                        </Box>
                        <Box className="data-wrapper">
                            <span className="data-label">
                                {UtilsI18n.get("transfers.alias.deletion.step3.currency")}
                            </span>
                            <span>{currency}</span>
                        </Box>
                        <Box className="data-wrapper">
                            <span className="data-label">{UtilsI18n.get("transfers.alias.deletion.step3.alias")}</span>
                            <span>{alias}</span>
                        </Box>
                        {enrolledCurrencies && (
                            <Box className="data-wrapper">
                                <span className="data-label">
                                    {UtilsI18n.get(
                                        `transfers.alias.enrollment.step3.enrolledCurrencies.${enrolledCurrencies}`,
                                    )}
                                </span>
                            </Box>
                        )}
                    </Box>
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { idTransaction } = transaction;
        dispatch(
            SelectorsActionTransfers.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        dispatch(SelectorsActionTransfers.signTransactionPreview({ data }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreTranfers.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
