import React from "react";

import PropTypes from "prop-types";

import Image from "~/components/Image";

import Style from "./IndeterminateCheckbox.rules.scss";

export const NAME = "IndeterminateCheckbox";

export const PROP = {
    types: {
        className: PropTypes.string,
        id: PropTypes.string.isRequired,
        label: PropTypes.node,
        onCheckClick: PropTypes.func.isRequired,
        optionsAmount: PropTypes.number.isRequired,
        selectedOptionsAmount: PropTypes.number.isRequired,
        tooltip: PropTypes.string,
    },
    defaults: { className: "c-control c-control--has-icon", label: null, tooltip: null },
};

export function Component(props) {
    const { id, onCheckClick, selectedOptionsAmount, optionsAmount, className, label, disabled, tooltip } = props;
    const isIndeterminate = selectedOptionsAmount > 0 && selectedOptionsAmount < optionsAmount;

    return (
        <div id={Style.ID} className={className} onClick={(event) => event.stopPropagation()}>
            <input
                id={id}
                tabIndex="-1"
                type="checkbox"
                className="c-control-input"
                onChange={onCheckClick}
                checked={selectedOptionsAmount > 0}
                indeterminate={`${isIndeterminate}`}
                readOnly
                disabled={disabled}
            />
            <label className="c-control-label" htmlFor={id}>
                <div className="c-control-icons">
                    <div className="c-control-mark">
                        <Image src="check.svg" className="svg-wrapper" />
                    </div>
                </div>
                {label && <div className="form-group-text">{label}</div>}
            </label>
            {tooltip && (
                <div className="tooltip">
                    {" "}
                    <span className="tooltip-text"> {tooltip}</span>
                </div>
            )}
        </div>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
