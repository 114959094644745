import { TYPE as TYPES_APP } from "~/store/app";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.CHANGE_PHONE_STEP3_REQUEST:
        case TYPE.CHANGE_PHONE_STEP2_REQUEST:
        case TYPE.CHANGE_MY_PHONE_REGISTER_BIOMETRIC_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.CHANGE_PHONE_STEP1_REQUEST:
            return {
                ...storeProp,
                isChangeMyPhone: true,
                fetching: true,
            };
        case TYPE.CHANGE_PHONE_STEP1_SUCCESS:
            return {
                ...storeProp,
                facephi: action.facephi,
                exchangeToken: action.exchangeToken,
                username: action.username,
                fetching: false,
            };
        case TYPE.CHANGE_PHONE_STEP1_ERROR:
        case TYPE.CHANGE_PHONE_STEP2_ERROR:
        case TYPE.CHANGE_PHONE_STEP3_ERROR:
        case TYPE.CHANGE_MY_PHONE_REGISTER_BIOMETRIC_REQUEST_ERROR:
            return {
                ...storeProp,
                fetching: false,
            };

        case TYPE.CHANGE_PHONE_STEP2_SUCCESS:
        case TYPE.CHANGE_MY_PHONE_REGISTER_BIOMETRIC_REQUEST_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.CHANGE_PHONE_STEP3_SUCCESS:
            return {
                ...storeProp,
                exchangeToken: action.exchangeToken,
                fetching: false,
            };
        case TYPES_APP.CLEAN_UP:
        case TYPE.CLEAN_UP:
            return {
                ...PROP.defaults,
            };
        default:
            return {
                ...storeProp,
            };
    }
};
