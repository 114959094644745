import React from "react";

import PropTypes from "prop-types";

import * as I18nUtils from "~/util/i18n";

import Button from "~/components/Button";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";

export const NAME = "SecuritySealField";

export const PROP = {
    types: {
        field: PropTypes.shape({ name: PropTypes.string }).isRequired,
        form: PropTypes.shape({
            touched: PropTypes.object,
            errors: PropTypes.shape({}),
        }).isRequired,
        imageSrc: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        hidelabel: PropTypes.bool,
        idForm: PropTypes.string.isRequired,
    },
    defaults: { imageSrc: "security-image.svg", hidelabel: false },
};
export function Component(props) {
    const {
        field,
        form: { touched, errors },
        hidelabel,
        idForm,
        imageSrc,
        onClick,
    } = props;
    const hasError = touched[field.name] && errors[field.name];
    return (
        <div className={`form-group  form-group--image-selector ${hasError ? "has-error" : ""}`}>
            {!hidelabel && <FieldLabel labelKey={`${idForm}.${field.name}.label`} />}
            <div className="security-image">
                <div className="image">
                    <figure>
                        <img src={imageSrc} alt={I18nUtils.get(`${idForm}.${field.name}.label`)} />
                    </figure>
                </div>
                <Button
                    onClick={onClick}
                    className="btn btn-outline btn-block"
                    label={`${idForm}.${field.name}.select`}
                />
            </div>
            {hasError && <FieldError error={errors[field.name].id} />}
        </div>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
