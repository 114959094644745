import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE } from "~/constants";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as i18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsMiddleware } from "./_selectors";

const sagas = [
    TakeLatest(TYPE.LIST_AGREEMENTS_REQUEST, listAgreementsRequest),
    TakeLatest(TYPE.LIST_AUTOMATIC_DEBITS_REQUEST, listAutomaticDebitsRequest),
];

export default sagas;

function* listAgreementsRequest(props) {
    const myProps = { ...props };
    delete myProps.type;
    const { type, data } = yield Call(SelectorsMiddleware.listAgreementsRequest, myProps);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.listAgreementsFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: i18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMBOS_LIST],
            }),
        );
    } else {
        yield Put(SelectorsAction.listAgreementsSuccess({ data: data.data }));
    }
}

function* listAutomaticDebitsRequest(props) {
    const myProps = { ...props };
    delete myProps.type;
    const { type, data } = yield Call(SelectorsMiddleware.listAutomaticDebitsRequest, myProps);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.listAutomaticDebitsFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: i18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.AUTOMATIC_DEBITS],
            }),
        );
    } else {
        yield Put(SelectorsAction.listAutomaticDebitsSuccess({ data: data.data }));
    }
}
