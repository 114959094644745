import React from "react";

import { replace as Replace } from "connected-react-router";
import { withFormik as WithFormik, Form, Field } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { EMPTY_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreForm, SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsStore as SelectorsStoreSettings, SelectorsAction as SelectorsActionSettings } from "~/store/settings";
import { SelectorsStore as SelectorsStoreTransfers } from "~/store/transfers";
import * as UtilsConfig from "~/util/config";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import ConfirmationModal from "~/pages/_components/ConfirmationModal";
import TextField from "~/pages/_components/fields/TextField";

import Style from "./ChangeMobilePhone.rules.scss";

const FORM_ID = "settings.changeMobilePhone";

export const NAME = "ChangeMobilePhoneConfirmation";

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        environmentType: PropTypes.string,
        isDesktop: PropTypes.bool.isRequired,
        isSubmitting: PropTypes.bool.isRequired,
        onUpdateEmailAccounts: PropTypes.bool,
        showModal: PropTypes.bool.isRequired,
        userFullName: PropTypes.string,
    },
    defaults: {
        environmentType: EMPTY_STR,
        isDesktop: false,
        isSubmitting: false,
        onUpdateEmailAccounts: false,
        showModal: false,
        userFullName: EMPTY_STR,
    },
};

export function Component(props) {
    const { dispatch, existsAccountsHSBC, isSubmitting, showModal, userFullName } = props;

    function goToSettings() {
        dispatch(Replace("/settings"));
    }

    function handleResend() {
        const { newMobilePhone, setErrors, setSubmitting } = props;

        const formikBag = {
            setSubmitting,
        };

        dispatch(
            SelectorsActionSettings.sendMobilePhoneCode({
                formikBag,
                isResending: true,
                mobilePhone: newMobilePhone,
                setErrors,
                setSubmitting,
            }),
        );
    }

    return (
        <React.Fragment>
            <div id={Style.ID}>
                <Form>
                    {showModal && (
                        <ConfirmationModal
                            handleAcept={() => goToSettings()}
                            handleCancel={() => dispatch(SelectorsActionForm.toggleModal())}
                            idLabel="global.modal.confirmation.message"
                            show={showModal}
                        />
                    )}
                    <div className="form--change_mobilePhone">
                        <div className="form--change_mobilePhone--title">
                            <I18n id="change.mobilePhone.verify.label" />
                        </div>
                        <div className="container--data">
                            <p className="text-lead-title">
                                <span>{`${userFullName} ${UtilsI18n.get("change.mobilePhone.checkPhone.title")}`}</span>
                            </p>
                            <p>
                                <I18n id="change.mobilePhone.checkNumber.description" />
                            </p>
                            <p className="text-lead-title">
                                <I18n id="change.mobilePhone.resend.code.title" />
                            </p>
                            <p>
                                <I18n id="change.mobilePhone.resend.code.description" />
                                <Button className="resend-code-button" onClick={() => handleResend()}>
                                    {` ${UtilsI18n.get("change.mobilePhone.resend.code.description.link")}`}
                                </Button>
                            </p>

                            {existsAccountsHSBC && (
                                <p>
                                    <I18n id="change.mobilePhone.resend.warning" />
                                </p>
                            )}

                            <div className="access-code">
                                <Field
                                    autoFocus
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    maxLength={UtilsConfig.getInteger("changeMobilePhone.verificationCode.length", 4)}
                                    name="mobilePhoneCode"
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form--change_mobilePhone">
                        <div />
                        <div className="buttons-container">
                            <Button
                                disabled={isSubmitting}
                                onClick={() => dispatch(SelectorsActionForm.toggleModal())}
                                size="md"
                                type="submit"
                                variant="secondary">
                                <I18n id="global.cancel" />
                            </Button>
                            <Button type="submit" size="md" variant="primary" disabled={isSubmitting}>
                                <I18n id="global.continue" />
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        </React.Fragment>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    environmentType: SelectorsStoreSession.getActiveEnvironmentType(store),
    existsAccountsHSBC: SelectorsStoreTransfers.existsAccountsHSBC(store),
    newMobilePhone: SelectorsStoreSettings.getNewMobilePhone(store),
    showModal: SelectorsStoreForm.showModal(store),
    userFullName: SelectorsStoreSession.getUserFullName(store),
});

export default HighOrder(
    WithRouter,
    Connect(mapStateToProps),
    HighOrder.Resizable,
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            mobilePhoneCode: EMPTY_STR,
        }),
        validationSchema: () =>
            Yup.object().shape({
                mobilePhoneCode: Yup.string().required(UtilsI18n.get(`${FORM_ID}.code.required`)),
            }),
        handleSubmit: ({ mobilePhoneCode }, { props, ...formikBag }) => {
            const { environmentType, dispatch } = props;

            dispatch(
                SelectorsActionSettings.updateMobilePhone({
                    environmentType,
                    formikBag,
                    mobilePhoneCode,
                }),
            );
        },
    }),
)(Component);
