import React from "react";

import { replace as Replace } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import {SCOPE } from "~/constants";
import { STATUS, DEFAULT_STATUS } from "~/constants/transaction";
import Container from "~/containers/Internal/Form/Filter";
import {
    SelectorsAction as SelectorsActionTransactions,
    SelectorsStore as SelectorsStoreTransactions,
} from "~/store/transactionsHistory";
import HighOrder from "~/components/HighOrder";
import ListHistory from "~/pages/transactionsHistory/_components/ListHistory";
import Style from "./TransactionsListHistory.rules.scss";

export const { NAMEWeb } = Style;

export const NAME = "TransactionsListHistory";

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        fetching: PropTypes.bool.isRequired,
        delayedTransactions: PropTypes.bool,
        totalTransactions: PropTypes.arrayOf(PropTypes.object),
        filter: PropTypes.shape({ status: PropTypes.string.isRequired }),
    },
    defaults: {
        delayedTransactions: null,
        totalTransactions: null,
        filter: null,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        selectedFilter: "last",
        statusFilter: DEFAULT_STATUS,
        selectAllPendingChecked: false,
        defaultFilter: false,
        idTransactionList: [],
    };

    constructor(props) {
        const { state } = props.location;
        super(props);

        if (state && state.statusFilter) {
            // NOTE: While the following could be fixed, it would generate a lot of warnings,
            //       because destructuring couldn't be used in the upper scope, and this is cleaner and harmless.
            // eslint-disable-next-line react/state-in-constructor
            this.state = {
                selectedFilter: state.selectedFilter,
                statusFilter: state.statusFilter,
                selectAllPendingChecked: state.selectAllPendingChecked,
            };
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.filter !== null) {
            return {
                ...prevState,
                statusFilter: nextProps.filter.status,
            };
        }

        return null;
    }

    componentDidMount() {
        const { filter } = this.props;
        if (filter) {
            this.setState({ defaultFilter: true });
        }
        this.handleClearTransactions();
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(SelectorsActionTransactions.clearTransactionList());
    }


    handleCheckAll = () => {
        const { selectAllPendingChecked } = this.state;
        this.setState({ selectAllPendingChecked: !selectAllPendingChecked });
        if (selectAllPendingChecked) {
            this.handleCheckTransactionList([]);
        }
    };

    handleTransactionsPreview = (approve) => {
        const { dispatch } = this.props;
        const { idTransactionList } = this.state;
        if (approve) {
            dispatch(SelectorsActionTransactions.approveTransactionsPreviewRequest({ idTransactionList }));
        } else {
            dispatch(SelectorsActionTransactions.cancelTransactionsPreviewRequest({ idTransactionList }));
        }
    };

    shouldRenderSelectAll = (statusFilter, changeFilter) =>
        statusFilter === STATUS.PENDING ||
        statusFilter.value === STATUS.PENDING ||
        (statusFilter === DEFAULT_STATUS && !changeFilter);

    renderList = () => {
        const { selectAllPendingChecked, statusFilter, idTransactionList } = this.state;
        const { isMobile } = this.props;
        return (
            <ListHistory
                currentFilter={statusFilter.value ? statusFilter.value : statusFilter}
                handleCheckChange={this.handleCheckAll}
                handleCheckTransactionList={this.handleCheckTransactionList}
                isMobile={isMobile}
                idTransactionList={idTransactionList}
            />
        );
    };

    handleCheckTransactionList = (listElement) => {
        this.setState({ idTransactionList: listElement });
    };

    handleBack = () => {
        const { history, dispatch } = this.props;
        const { pathname } = history.location;
        if (pathname === "/transactionsHistory/list") {
            dispatch(SelectorsActionTransactions.clearPreview());
            dispatch(SelectorsActionTransactions.setApproving(), { isApproving: null });
        } else {
            history.goBack();
        }
    };

    handleClose = () => {
        const { dispatch } = this.props;
        this.handleClearTransactions();
        const pathname = "/desktop";
        dispatch(
            Replace({
                pathname,
                state: { transition: "transition-flow-close" },
            }),
        );
    };

    handleClearTransactions = () => {
        this.setState({ selectAllPendingChecked: false });
        const { dispatch } = this.props;
        dispatch(SelectorsActionTransactions.clearPreview());
    };

    render() {
        const { fetching, idTransactionList, isMobile } = this.props;
        return (
            <Container
                name={NAME}
                head-title={"menu.transactions.history"}
                wait={fetching}
                head-onBackWeb={this.handleBack}
                head-onBack={ this.handleBack}
                head-onClose={this.handleClose}
                className={!isMobile && "list-transaction list-web-transaction"}
                image="transactions-step.svg"
                scopeToShowNotification={SCOPE.TRANSACTIONS}>
                                    <section>
                <div className={`${idTransactionList.length > 0 ? "approve-screen" : "list-screen"}`}>
                   {this.renderList()}
                </div>
                </section>
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    transactions: SelectorsStoreTransactions.getTransactions(store),
    idTransactionList: SelectorsStoreTransactions.getidApprovals(store),
    delayedTransactions: SelectorsStoreTransactions.getDelayedTransactions(store),
    fetching: SelectorsStoreTransactions.getFetching(store),
    isApproving: SelectorsStoreTransactions.getSignAction(store),
    changeFilter: SelectorsStoreTransactions.getFilterChange(store),
    filter: SelectorsStoreTransactions.getFilters(store),
});

export default Connect(mapStateToProps)(HighOrder.Resizable(Component));
