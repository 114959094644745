import React from "react";

import PropTypes from "prop-types";

import { connect as Connect } from "react-redux";

import { SelectorsStore as SelectorsStoreSession } from "~/store/session";

import { FORMAT_DATE_TICKET } from "~/constants";

import * as UtilsDate from "~/util/date";
import * as UtilsI18n from "~/util/i18n";

import Image from "~/components/Image";

export const NAME = "TicketPrintInformation";

export const PROP = {
    types: {},
    defaults: {},
};

// Informacion extra que se muestra al imprimir el comprobante

export function Component(props) {
    const { activeEnvironment, environments, userFullName } = props;
    const { name } = environments[activeEnvironment.id] || {};

    const date = UtilsDate.specificDate(new Date(), FORMAT_DATE_TICKET);

    return (
        <div className="only-print">
            <div className="enviroment-info">
                <span className="data-wrapper">{date}</span>
                <span className="data-label">{UtilsI18n.get("ticket.extraInformation.environment")}</span>
                <span className="data-wrapper">{name}</span>
                <span className="data-wrapper">{userFullName}</span>
            </div>
            <div className="logo-ticket">
                <Image src="logo-hsbc-black.svg" />
            </div>
        </div>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    userFullName: SelectorsStoreSession.getUserFullName(store),
    environments: SelectorsStoreSession.getEnvironments(store),
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store) || {},
});

export default Connect(mapStateToProps)(Component);