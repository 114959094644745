import { FactoryAction } from "~/util/redux";

import { TYPE, NAME } from "./_consts";

export const SelectorsAction = {
    fingerprintAvailability: () => FactoryAction(TYPE.FINGERPRINT_AVAILABILITY),
    fingerprintConfigurationPre: () => FactoryAction(TYPE.FINGERPRINT_CONFIGURATION_PRE),
    fingerprintVerification: () => FactoryAction(TYPE.FINGERPRINT_VERIFICATION_REQUEST),
    fingerprintDeleteDevice: (props) => FactoryAction(TYPE.FINGERPRINT_DELETE_DEVICE, props),
    fingerprintDeleteUserDevicePre: (props) => FactoryAction(TYPE.FINGERPRINT_DELETE_DEVICE_PRE, props),
    fingerprintHideWidget: (props) => FactoryAction(TYPE.FINGERPRINT_HIDE_WIDGET, props),
    enrollFingerprintOnDevice: (props) => FactoryAction(TYPE.ENROLL_FINGERPRINT_ON_DEVICE, props),
    fingerPrintAvailabilitySuccess: (props) => FactoryAction(TYPE.FINGERPRINT_AVAILABILITY_SUCCESS, props),
    fingerPrintConfigurationPreSuccess: (props) => FactoryAction(TYPE.FINGERPRINT_CONFIGURATION_PRE_SUCCESS, props),
    cleanUp: () => FactoryAction(TYPE.CLEAN_UP),
};

export const SelectorsStore = {
    getFetching: (store) => store[NAME].fetching,
    getFingerprintConfiguredUserDevices: (store) => store[NAME].fingerprintConfiguredUserDevices,
    getAvailability: (store) => store[NAME].availability,
    isDeviceWithFingerprint: (store) => store[NAME].isDeviceWithFingerprint,
    getDevice: (store) => store[NAME].device,
    isHidden: (store) => store[NAME].hidden,
    isShowAgain: (store) => store[NAME].showAgain,
    getPathFrom: (store) => store[NAME].pathFrom,
};
