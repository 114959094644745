import React from "react";

import ReactSelect from "react-select";

import { EVENT_BACKBUTTON } from "~/constants";
import UtilsDevice from "~/util/device";
import * as I18nUtils from "~/util/i18n";

function isElementScrollable(element) {
    const { overflowY } = window.getComputedStyle(element);

    return overflowY !== "visible" && overflowY !== "hidden" && element;
}

const HOTFIX = {
    CLASS_NAME: "scroll-disabled",
    ROOT: "root",
};

export const NAME = "Select";

export const PROP = {
    types: {},
    defaults: {},
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    selectRef = React.createRef();

    state = {
        closeInBackButton: false,
        scrollTop: null,
    };

    componentDidMount() {
        if (UtilsDevice.isMobileNative()) {
            document.addEventListener(EVENT_BACKBUTTON, this.backButtonAction);
        }
    }

    componentWillUnmount() {
        if (UtilsDevice.isMobileNative()) {
            document.removeEventListener(EVENT_BACKBUTTON, this.backButtonAction);
        }
    }

    backButtonAction = () => {
        this.setState({ closeInBackButton: true });
        this.selectRef.current.setState({ isOpen: false });
    };

    findScrollableContainer = () => {
        let { wrapper: target } = this.selectRef.current;

        do {
            if (isElementScrollable(target)) {
                return target;
            }

            target = target.parentElement;
        } while (target && target.id !== HOTFIX.ROOT);

        return null;
    };

    handleSelectOpen = () => {
        const { isOpenCallback } = this.props;
        const container = this.findScrollableContainer();

        if (container) {
            this.setState({ scrollTop: container.scrollTop });
        }

        document.querySelector(`#${HOTFIX.ROOT}`).classList.add(HOTFIX.CLASS_NAME);

        if (UtilsDevice.isMobileNative()) {
            window.history.pushState({}, NAME, NAME);
        }

        if (isOpenCallback) {
            isOpenCallback(true);
        }
    };

    handleSelectClose = () => {
        const { closeInBackButton, scrollTop } = this.state;
        const { isOpenCallback } = this.props;

        document.querySelector(`#${HOTFIX.ROOT}`).classList.remove(HOTFIX.CLASS_NAME);

        const container = this.findScrollableContainer();

        if (container && scrollTop) {
            container.scrollTop = scrollTop;

            this.setState({ scrollTop: null });
        }

        if (!closeInBackButton && UtilsDevice.isMobileNative()) {
            window.history.back();
        }

        this.setState({ closeInBackButton: false });

        if (isOpenCallback) {
            isOpenCallback(false);
        }
    };

    render() {
        const { isSearchableOnMobile = false, tooltip } = this.props;
        const noOptionText = I18nUtils.get("selector.noOption.text");
        const isMobile = UtilsDevice.isDisplayMobile();

        return (
            <React.Fragment>
                <ReactSelect
                    autosize={false}
                    inputProps={{ spellCheck: "false" }}
                    noResultsText={noOptionText}
                    onClose={() => this.handleSelectClose.call(this)}
                    onOpen={() => this.handleSelectOpen.call(this)}
                    optionClassName="needsclick"
                    ref={this.selectRef}
                    searchable={!isMobile || isSearchableOnMobile}
                    {...this.props}
                />
                {tooltip && (
                    <div className="tooltip">
                        <span className="tooltip-text"> {tooltip}</span>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default Component;
