import Resizable from "./_Resizable";

export { Resizable };

// Determine Component's displayName.
const getName = (Component) => Component.displayName || Component.name || "__UNKNOWN__";

/**
 *  HighOrder Component Composer.
 *  An easy way to compose HighOrderComponents and preserve their displayNames.
 *
 * @param  {...React.Component} HighOrderComponents - The reverse order on which to wrap the target component.
 * @returns {Function} - A closure with the signature (Component, name).
 *                       if the name isn't provided it will be obtained from either displayName or functionName.
 */
function Default(...HighOrderComponents) {
    return (BaseComponent, name = null) =>
        // Run HOCs in reverse order to mimic the nested call.
        HighOrderComponents.reverse().reduce((AccumulatorComponent, HighOrderComponent) => {
            const Component = HighOrderComponent(AccumulatorComponent);
            Component.displayName = `${getName(HighOrderComponent)}(${name || getName(AccumulatorComponent)})`;
            return Component;
        }, BaseComponent);
}
// Allow the subcomponents to be available even when only importing the default export.
Default.Resizable = Resizable;

export default Default;
