import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "fingerprint";

export const TYPE = FactoryType(NAME, [
    "CLEAN_UP",
    "ENROLL_FINGERPRINT_ON_DEVICE",
    "FINGERPRINT_AVAILABILITY",
    "FINGERPRINT_AVAILABILITY_SUCCESS",
    "FINGERPRINT_CONFIGURATION_PRE",
    "FINGERPRINT_CONFIGURATION_PRE_SUCCESS",
    "FINGERPRINT_DELETE_DEVICE",
    "FINGERPRINT_DELETE_DEVICE_PRE",
    "FINGERPRINT_HIDE_WIDGET",
    "FINGERPRINT_SHOW_WIDGET",
    "FINGERPRINT_VERIFICATION_REQUEST",
    "VERIFY_BIOMETRIC",
    "VERIFY_BIOMETRIC_SUCCESS",
    "VERIFY_BIOMETRIC_NO_ENROLLED_FINGERPRINT",
    "VERIFY_BIOMETRIC_ERROR",
    "FINGERPRINT_VERIFICATION_REQUEST",
]);

export const PROP = {};

PROP.types = {
    availability: PropTypes.object,
    device: PropTypes.object,
    fetching: PropTypes.bool,
    fingerprintConfiguredUserDevices: PropTypes.object,
    hidden: PropTypes.bool,
    isDeviceWithFingerprint: PropTypes.object,
    showAgain: PropTypes.bool,
    pathFrom: PropTypes.string,
    ...TypesRedux,
};

PROP.defaults = {
    availability: null,
    device: null,
    fetching: false,
    fingerprintConfiguredUserDevices: null,
    hidden: false,
    isDeviceWithFingerprint: null,
    showAgain: true,
    pathFrom: "",
    ...DefaultsRedux,
};
