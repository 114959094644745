import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { EMPTY_STR } from "~/constants";
import {
    SelectorsStore as SelectorsStoreWMSwitch,
    SelectorsAction as SelectorsActionWMSwitch,
} from "~/store/wm/funds/switch";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Token from "~/components/Token/Token";

import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        isSubmitting: PropTypes.bool,
    },
    defaults: {
        isSubmitting: false,
    },
};

export function Component(props) {
    const { isSubmitting, location, debitAccountAlias, paperToSwitchName, paperToBuyName, transaction } = props;
    const isFromTransactionsHistory = location.pathname.includes("/ticket");
    const { data } = transaction;
    function handleCancel() {
        const { dispatch } = props;
        if (isFromTransactionsHistory) {
            dispatch(GoBack());
        } else {
            dispatch(SelectorsActionWMSwitch.previewCancelRequest());
        }
    }

    return (
        <section id={Style.ID}>
            <Form>
                <div className="transfer-block">
                    <div className="transfer-data transfer-data-highlight">
                        <div className="data-label">
                            <I18n id="wm.funds.switch.paperToSwitch.label" />
                        </div>
                        <span className="data-amount">
                            <span>{paperToSwitchName || data.paperToSwitchName}</span>
                        </span>
                    </div>

                    <div className="transfer-data transfer-data-highlight">
                        <div className="data-label">
                            <I18n id="wm.funds.switch.paperToBuy.label" />
                        </div>
                        <span className="data-amount">
                            <span>{paperToBuyName || data.paperToBuyName}</span>
                        </span>
                    </div>

                    <div className="transfer-data transfer-data-highlight">
                        <div className="data-label">
                            <I18n id="wm.buy.accounts.ticket" />
                        </div>
                        <span className="data-amount">
                            <span>{debitAccountAlias || data.debitAccountAlias}</span>
                        </span>
                    </div>
                </div>
                <Token labelButton="global.send" isSubmitting={isSubmitting}>
                    <Button size="sm" variant="secondary" disabled={isSubmitting} onClick={handleCancel}>
                        <I18n id="global.cancel" />
                    </Button>
                </Token>
            </Form>
        </section>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    debitAccountAlias: SelectorsStoreWMSwitch.getDebitAccountAlias(store),
    disclaimer: SelectorsStoreWMSwitch.getDisclaimer(store),
    idDebitAccount: SelectorsStoreWMSwitch.getIdDebitAccount(store),
    idPaperToBuy: SelectorsStoreWMSwitch.getIdPaperSelectedToBuy(store),
    idPaperToSwitch: SelectorsStoreWMSwitch.getIdPaperSelectedToSwitch(store),
    isSubmitting: SelectorsStoreWMSwitch.getIsSubmitting(store),
    limitDate: SelectorsStoreWMSwitch.getlimitDate(store),
    orderCompleted: SelectorsStoreWMSwitch.getOrderCompleted(store),
    paperToBuyName: SelectorsStoreWMSwitch.getPaperToBuyName(store),
    paperToSwitchName: SelectorsStoreWMSwitch.getPaperToSwitchName(store),
    transaction: SelectorsStoreWMSwitch.getTransaction(store),
});

export default HighOrder(
    Connect(mapStateToProps),
    WithRouter,
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(UtilsI18n.get("client.wm.funds.sell.step2.otp.required")),
            }),
        handleSubmit: (data, formikBag) => {
            const {
                debitAccountAlias,
                disclaimer,
                dispatch,
                history,
                idDebitAccount,
                idPaperToBuy,
                idPaperToSwitch,
                limitDate,
                orderCompleted,
                paperToBuyName,
                paperToSwitchName,
                transaction: { idTransaction },
            } = formikBag.props;

            const { otp } = data;

            const isFromTransactionsHistory = history.location.pathname.includes("/ticket");
            if (isFromTransactionsHistory) {
                dispatch(
                    SelectorsActionWMSwitch.signTransaction({
                        idForm: null,
                        idActivity: "client.wm.funds.switch.send",
                        idTransaction,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            } else {
                dispatch(
                    SelectorsActionWMSwitch.paperSendFormRequest({
                        _otp: otp,
                        debitAccountAlias,
                        disclaimer,
                        idDebitAccount,
                        idPaperToBuy,
                        idPaperToSwitch,
                        limitDate,
                        orderCompleted,
                        paperToBuyName,
                        paperToSwitchName,
                        valueDate: new Date(),
                    }),
                );
            }
        },
    }),
)(Component);
