import React from "react";

import { push as Push } from "connected-react-router";
import { Form, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";

import { PLUS } from "~/constants";
import Container from "~/containers/Internal/Form/Simple";
import { SelectorsStore as SelectorsStoreSession, SelectorsAction as SelectorsActionSession } from "~/store/session";
import { SelectorsStore as SelectorsStoreSettings, SelectorsAction as SelectorsActionSettings } from "~/store/settings";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";

import Style from "./SyncUserMobilePhone.rules.scss";

const FORM_ID = "change.personal.syncMobilePhone.step1";

export const { NAME } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component({
    dispatch,
    fetchingForm,
    mobilePhone,
    mobilePhoneBT,
    mobilePrefix,
    userMobilePhoneForceSync,
}) {
    React.useEffect(() => {
        if (userMobilePhoneForceSync) {
            dispatch(SelectorsActionSettings.getUserData());
            dispatch(SelectorsActionSession.resetUserMobilePhoneForceSync());
        }
    }, [dispatch, userMobilePhoneForceSync]);

    const handleNoOneIsCorrect = () => {
        dispatch(SelectorsActionSession.selectUserMobilePhoneToSync({ userMobilePhoneSelected: null }));

        dispatch(Push("/settings/changeMobilePhone"));
    };

    const handleSelectMobilePhone = (userMobilePhoneSelected) => {
        dispatch(SelectorsActionSession.selectUserMobilePhoneToSync({ userMobilePhoneSelected }));

        dispatch(Push("/settings/changeMobilePhone"));
    };

    return (
        <Container name={NAME} wait={fetchingForm} head-title="change.personal.syncMobilePhone.title">
            <Form className="col col-12 col-lg-6 col-md-9 col-sm-12" id={Style.ID} noValidate="novalidate">
                <Box>
                    <Box className="form-section-title ">
                        <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.title`)}</h3>
                    </Box>
                    <div className="form-group">
                        {mobilePhone && mobilePrefix && (
                            <Button
                                className="button-option"
                                size="sm"
                                variant="secondary"
                                onClick={() => handleSelectMobilePhone(`${PLUS}${mobilePrefix}-${mobilePhone}`)}>
                                {`${PLUS}${mobilePrefix}-${mobilePhone}`}
                            </Button>
                        )}
                        <Button
                            className="button-option"
                            size="sm"
                            variant="secondary"
                            onClick={() => handleSelectMobilePhone(mobilePhoneBT)}>
                            {mobilePhoneBT}
                        </Button>
                        <Button
                            className="button-option button-option-nothing"
                            size="sm"
                            variant="secondary"
                            onClick={handleNoOneIsCorrect}>
                            <I18n id="change.personal.syncMobilePhone.step1.noOneIsCorrect" />
                        </Button>
                    </div>
                </Box>
            </Form>
        </Container>
    );
}

const mapStateToProps = (store) => ({
    fetchingForm: SelectorsStoreSettings.getFetchingForm(store),
    mobilePhone: SelectorsStoreSettings.getMobilePhone(store),
    mobilePhoneBT: SelectorsStoreSettings.getMobilePhoneBT(store),
    mobilePrefix: SelectorsStoreSettings.getMobilePrefix(store),
    userMobilePhoneForceSync: SelectorsStoreSession.getUserMobilePhoneForceSync(store),
});

export default HighOrder(
    WithRouter,
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: true,
    }),
)(Component);
