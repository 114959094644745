import { combineReducers } from "redux";

import { NAME as NAME_CHANNELS, Reducer as ReducerChannels } from "~/store/administration/common/channels";
import { NAME as NAME_DETAILS, Reducer as ReducerDetails } from "~/store/administration/common/details";
import { NAME as NAME_PERMISSIONS, Reducer as ReducerPermissions } from "~/store/administration/common/permissions";
import { NAME as NAME_SIGNATURES, Reducer as ReducerSignatures } from "~/store/administration/common/signatures";

import {
    TYPE_ADMINISTRATION_LEVEL_MEDIUM_CHANNELS,
    TYPE_ADMINISTRATION_LEVEL_MEDIUM_DETAILS,
    TYPE_ADMINISTRATION_LEVEL_MEDIUM_PERMISSION,
    TYPE_ADMINISTRATION_LEVEL_MEDIUM_SIGNATURE,
} from "./_consts";

export default combineReducers({
    [NAME_CHANNELS]: ReducerChannels(TYPE_ADMINISTRATION_LEVEL_MEDIUM_CHANNELS),
    [NAME_DETAILS]: ReducerDetails(TYPE_ADMINISTRATION_LEVEL_MEDIUM_DETAILS),
    [NAME_PERMISSIONS]: ReducerPermissions(TYPE_ADMINISTRATION_LEVEL_MEDIUM_PERMISSION),
    [NAME_SIGNATURES]: ReducerSignatures(TYPE_ADMINISTRATION_LEVEL_MEDIUM_SIGNATURE),
});
