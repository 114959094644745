import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex/";
import { SelectorsStore as SelectorsStoreComexExport } from "~/store/comex/export";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import I18n from "~/components/I18n";
import Ticket from "~/components/Ticket/Ticket";
import FormattedAmount from "~/pages/_components/FormattedAmount";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const { transaction, ticketConfirmation, dispatch } = props;
    const { data } = transaction;
    const { oldAmount, invoiceAmountCurrency, invoiceNumber, recipient, operationNumber, item } = data;

    const shareTicket = (idTransactionToRead) => {
        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    };

    const oldAmountData = oldAmount || (item?.othersFields ? item.othersFields.IMPORTE : EMPTY_STR);
    const recipientData = recipient || (item?.othersFields ? item.othersFields.GIRADO : EMPTY_STR);
    const invoiceNumberData = invoiceNumber || (item?.othersFields ? item.othersFields.NROFACTURA : EMPTY_STR);
    const invoiceAmountCurrencyData =
        invoiceAmountCurrency || (item?.othersFields ? item.othersFields.MONEDA : EMPTY_STR);

    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}
                    hasMobileComponent
                    displayDownloadPDF
                    handleDownloadPDF={() => shareTicket(transaction.idTransaction)}>
                    <Box flex directionColumn align="flex-start" className="mb-3">
                        <Box className="opening">
                            <I18n id="client.export.billingClaim.step2.opening" tag="p" className="mt-3" />
                        </Box>
                        <Box flex className="info-label">
                            <h4 className="bold">
                                {UtilsI18n.get("client.export.billingClaim.step2.operationNumber")}
                            </h4>
                            <span>{operationNumber}</span>
                        </Box>
                        <Box flex className="info-label">
                            <h4 className="bold">{UtilsI18n.get("client.export.billingClaim.step2.amount")}</h4>
                            <FormattedAmount
                                currency={invoiceAmountCurrencyData}
                                quantity={parseFloat(oldAmountData)}
                            />
                        </Box>
                        <Box flex cclassName="info-label">
                            <h4 className="bold">{UtilsI18n.get("client.export.billingClaim.step2.invoiceNumber")}</h4>
                            <span>{invoiceNumberData}</span>
                        </Box>
                        <Box flex cclassName="info-label">
                            <h4 className="bold">{UtilsI18n.get("client.export.billingClaim.step2.recipient")}</h4>
                            <span>{recipientData}</span>
                        </Box>
                        <Box className="opening">
                            <I18n id="client.export.billingClaim.step2.message" tag="p" className="mt-3 mb-3" />
                        </Box>
                    </Box>
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { idTransaction } = transaction;
        dispatch(
            SelectorsActionComex.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        dispatch(SelectorsActionComex.signTransactionPreview({ transaction }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreComexExport.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
