import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { FINANCING_PRODUCT_TYPES } from "~/constants";
import Container from "~/containers/Internal/Form/Filter";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsAction as SelectorsActionPosition, SelectorsStore as SelectorsStorePosition } from "~/store/position";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as UtilsI18n from "~/util/i18n";

import AccordionDetail from "~/pages/position/AccordionDetail";
import AccountItem from "~/pages/position/AccountItem";
import LinkOptions from "~/pages/position/LinkOptions";
import PopUp from "~/pages/position/PopUp";
import Style from "~/pages/position/Position.rules.scss";

export const { NAME, NAME_MESSAGES } = Style;

export const PROP = {
    types: {
        isChangingEnvironment: PropTypes.bool,
    },
    defaults: {
        isChangingEnvironment: false,
    },
};

export class Component extends React.Component {
    state = {
        forceMode: "opened",
        showModal: false,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const { collapsedElementsCount, positions } = nextProps;

        if (collapsedElementsCount === 0) {
            return { ...prevState, forceMode: "opened" };
        }

        if (collapsedElementsCount === positions.length) {
            return { ...prevState, forceMode: "closed" };
        }

        if (collapsedElementsCount > 0 && collapsedElementsCount < positions.length) {
            return prevState;
        }

        return null;
    }

    componentDidMount() {
        const { dispatch, isChangingEnvironment } = this.props;

        if (!isChangingEnvironment) {
            dispatch(SelectorsActionPosition.readPositionRequest({ productTypes: FINANCING_PRODUCT_TYPES }));
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;

        dispatch(SelectorsActionPosition.changeAllCollapsedElementsCount({ collapseAll: false }));
        dispatch(SelectorsActionPosition.deletePosition());
    }

    handleBack = () => {
        const { history } = this.props;

        history.goBack();
    };

    toggleAccordion = () => {
        const { collapsedElementsCount, dispatch, positions } = this.props;
        const { forceMode } = this.state;

        let collapseAll;

        if (collapsedElementsCount === 0) {
            collapseAll = true;
        } else if (collapsedElementsCount === positions.length) {
            collapseAll = false;
        } else if (collapsedElementsCount > 0 && collapsedElementsCount < positions.length) {
            collapseAll = forceMode === "opened";
        }

        dispatch(SelectorsActionPosition.changeAllCollapsedElementsCount({ collapseAll }));
    };

    handleOpenModal = (fields, data, currency) => {
        this.setState({ showModal: true, fields, data, currency });
    };

    render() {
        const { collapsedElementsCount, fetching, i18n, positions, lang } = this.props;
        const { closeAll, headTitle, openAll, productDetailLabel } = i18n;
        const { currency, data, fields, forceMode, showModal } = this.state;

        let buttonText;
        let showCloseAll;
        if (collapsedElementsCount === 0) {
            buttonText = closeAll;
            showCloseAll = true;
        }

        if (collapsedElementsCount === positions.length) {
            buttonText = openAll;
            showCloseAll = false;
        }

        if (collapsedElementsCount > 0 && collapsedElementsCount < positions.length) {
            buttonText = forceMode === "opened" ? closeAll : openAll;
            showCloseAll = forceMode === "opened";
        }

        return (
            <React.Fragment>
                <Container
                    wait={fetching}
                    name={NAME}
                    head-onBack={this.handleBack}
                    head-title={headTitle}
                    className="position"
                    image="financing.svg">
                    <div className="links header-content">
                        <LinkOptions
                            image="arrow-top.svg"
                            handleClick={() => this.toggleAccordion()}
                            text={buttonText}
                            className={showCloseAll && "invert"}
                        />
                    </div>
                    {positions.map((position) => {
                        return (
                            <AccordionDetail
                                key={position.productType}
                                title={UtilsI18n.get(`client.position.productType.${position.productType}`)}
                                total={position.total}
                                className={`container-accordion-detail position_${position.productType}`}
                                forceMode={forceMode}>
                                <AccountItem
                                    name={UtilsI18n.get(`client.position.productType.${position.productType}`)}
                                    productType={position.productType}
                                    total={position.total}
                                    lang={lang}
                                    totalByCurrency={position.totalByCurrencyMap}
                                    productsByCurrency={position.productsByCurrency}
                                    innerAccordionForceMode={forceMode}
                                    handleOpenModal={this.handleOpenModal}
                                />
                            </AccordionDetail>
                        );
                    })}
                    <PopUp
                        show={showModal}
                        title={productDetailLabel}
                        fields={fields}
                        data={data}
                        handleCloseModal={() => {
                            this.setState({ showModal: false });
                        }}
                        currency={currency}
                    />
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    collapsedElementsCount: SelectorsStorePosition.getCollapsedElementsCount(store),
    fetching: SelectorsStorePosition.isFetching(store),
    i18n: {
        openAll: StoreI18n.getMessage(store, "position.accordions.openAll"),
        closeAll: StoreI18n.getMessage(store, "position.accordions.closeAll"),
        headTitle: StoreI18n.getMessage(store, "financing.title"),
        productDetailLabel: StoreI18n.getMessage(store, "position.popup.productDetail"),
    },
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    positions: SelectorsStorePosition.getPositions(store),
    lang: StoreI18n.getLang(store),
});

export default Connect(mapStateToProps)(Component);
