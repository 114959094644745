import React from "react";

import { Form } from "formik";

import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";
import ConfirmationModal from "~/pages/_components/ConfirmationModal";

import RequiredSignatures from "~/pages/administration/_components/formComponentsSchema/RequiredSignatures";

export const NAME = "FormSignatures";

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const { administrationScheme, idForm, isSubmitting, routerActions, signatureGroupMap, submitForm, typeAction } =
        props;

    const [showModal, setShowModal] = React.useState(false);

    const handleModal = React.useCallback(() => {
        setShowModal(!showModal);
    }, [showModal]);

    const handleSubmit = React.useCallback(() => {
        submitForm();
        handleModal();
    }, [handleModal, submitForm]);

    const handleBack = React.useCallback(() => {
        routerActions.goBack();
    }, [routerActions]);

    return (
        <Form className="above-the-fold">
            <div>
                <ConfirmationModal
                    show={showModal}
                    handleCancel={handleModal}
                    idLabel="administration.signatures.modify.modal.title"
                    handleAcept={handleSubmit}
                />
                {signatureGroupMap && (
                    <React.Fragment>
                        <RequiredSignatures
                            administrationScheme={administrationScheme}
                            typeAction={typeAction}
                            idForm={idForm}
                            typeTransaction="AMOUNT"
                            name="amount"
                        />
                        <RequiredSignatures
                            administrationScheme={administrationScheme}
                            typeAction={typeAction}
                            idForm={idForm}
                            typeTransaction="NO_AMOUNT"
                            name="noAmount"
                        />
                    </React.Fragment>
                )}
            </div>

            <div className="buttons-container">
                <Button type="button" size="sm" variant="secondary" loading={isSubmitting} onClick={handleBack}>
                    <I18n id="global.cancel" />
                </Button>
                <Button type="button" size="sm" variant="primary" loading={isSubmitting} onClick={handleModal}>
                    <I18n id="global.save" />
                </Button>
            </div>
        </Form>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
