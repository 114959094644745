import React from "react";

import { routerActions as ActionsRouter } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { FACTORING_TYPES, SCOPE } from "~/constants";
import Container from "~/containers/Internal/Dash";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { PROP as STORE_PROP, SelectorsStore } from "~/store/transfers";

import Style from "./Factoring.rules.scss";
import FactoringItem from "./_Item";

export const { NAME } = Style;

export const PROP = {};
// TODO: Members coming from the store, should be assambled from imported declarations.
PROP.members = {
    /** An internal permission scheme, used to determine which menu items should be available */
    activeEnvironmentPermissions: {
        listPaymentOrders: PropTypes.bool.isRequired,
        uploadPaymentOrders: PropTypes.bool.isRequired,
        listSuppliers: PropTypes.bool.isRequired,
        uploadSuppliers: PropTypes.bool.isRequired,
    },
};
PROP.members.activeEnvironment = {
    permissions: PropTypes.shape(PROP.members.activeEnvironmentPermissions).isRequired,
};
PROP.types = {
    /** Obtained from the Store, information about curren'ts user environment */
    activeEnvironment: PropTypes.shape(PROP.members.activeEnvironment),
    ...STORE_PROP.types,
};
PROP.defaults = {
    ...STORE_PROP.defaults,
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        itemClick: false,
    };

    onItemClicked = (e, item, formPath) => {
        const { dispatch } = this.props;
        const { itemClick } = this.state;
        if (formPath && !itemClick) {
            // If the item was clicked you can not select another one
            this.setState({
                itemClick: true,
            });
            dispatch(ActionsRouter.push(formPath));
        }
    };

    handleBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    render() {
        const {
            activeEnvironment: {
                permissions: {
                    listPaymentOrders = false, // TODO: Mapear los permisos a medida que se vayan desarrollando las funcionalidades
                    uploadPaymentOrders = false,
                    listSuppliers = false,
                    uploadSuppliers = false,
                    listAdvancePayments = false,
                    advancePayments = false,
                },
                factoring,
            },
            fetching,
        } = this.props;
        return (
            <React.Fragment>
                <Container
                    name={NAME}
                    head-title="factoring.title"
                    head-onBackWeb={this.handleBack}
                    wait={fetching}
                    scopeToShowNotification={SCOPE.FACTORING}>
                    {(factoring === FACTORING_TYPES.PAYER || factoring === FACTORING_TYPES.BOTH) && (
                        <div className={`factoring-setup-block factoring-option ${Style.CLASS}`}>
                            <ul className="factoring-options-list">
                                <FactoringItem
                                    type="suppliers"
                                    hasPermission={listSuppliers}
                                    title="menu.factoring.suppliers"
                                    href="/suppliers"
                                    description="menu.factoring.suppliers.description"
                                    image="suppliers"
                                />

                                <FactoringItem
                                    type="uploadSuppliers"
                                    hasPermission={uploadSuppliers}
                                    title="menu.factoring.uploadSuppliers"
                                    href="/uploadSuppliers"
                                    description="menu.factoring.uploadSuppliers.description"
                                    image="upload-suppliers"
                                />
                                <FactoringItem
                                    type="paymentOrders"
                                    hasPermission={listPaymentOrders}
                                    title="menu.factoring.paymentOrders"
                                    href="/paymentOrders"
                                    description="menu.factoring.paymentOrders.description"
                                    image="paymentOrders"
                                />

                                <FactoringItem
                                    type="uploadPaymentOrders"
                                    hasPermission={uploadPaymentOrders}
                                    title="menu.factoring.uploadPaymentOrders"
                                    href="/uploadPaymentOrders"
                                    description="menu.factoring.uploadPaymentOrders.description"
                                    image="upload-paymentOrders"
                                />
                            </ul>
                        </div>
                    )}
                    {(factoring === FACTORING_TYPES.PAYEE || factoring === FACTORING_TYPES.BOTH) && (
                        <div className={`factoring-setup-block factoring-option ${Style.CLASS}`}>
                            <ul className="factoring-options-list">
                                <FactoringItem
                                    type="advancePayments"
                                    hasPermission={advancePayments}
                                    title="menu.factoring.advancePayments"
                                    href="/advancePayments/transaction"
                                    description="menu.factoring.advancePayments.description"
                                    image="advancePayments"
                                />

                                <FactoringItem
                                    type="listAdvancePayments"
                                    hasPermission={listAdvancePayments}
                                    title="menu.factoring.listAdvancePayments"
                                    href="/listAdvancePayments"
                                    description="menu.factoring.listAdvancePayments.description"
                                    image="listAdvancePayments"
                                />
                            </ul>
                        </div>
                    )}
                </Container>
            </React.Fragment>
        );
    }
}

export default Connect((store) => ({
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
    fetching: SelectorsStore.fetching(store),
}))(Component);
