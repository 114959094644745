import React from "react";

import { push as Push } from "connected-react-router";
import { withFormik as WithFormik, Form, Field } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import * as Yup from "yup";

import { EMPTY_STR, EVENT_BACKBUTTON } from "~/constants";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsAction as ActionsLogin, SelectorsStore as SelectorsLogin } from "~/store/login";
import { SelectorsAction as ActionsRecoverPassword } from "~/store/recoverypassword";
import UtilsDevice from "~/util/device";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button";
import LinkButton from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Captcha from "~/pages/_components/fields/credentials/Captcha";
import Credential from "~/pages/_components/fields/credentials/Credential";

const FORM_ID = "login.step2";

export const NAME = "Step2Content";

export const PROP = {
    types: {
        securitySeal: PropTypes.string.isRequired,
        showCaptcha: PropTypes.bool,
        isMobileNative: PropTypes.bool,
        isSubmitting: PropTypes.bool,
        isTrusted: PropTypes.bool,
    },
    defaults: {
        showCaptcha: false,
        isMobileNative: false,
        isSubmitting: false,
        isTrusted: false,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentWillUnmount() {
        if (UtilsDevice.isMobileNative()) {
            document.removeEventListener(EVENT_BACKBUTTON, this.ignoreBack);
        }
    }

    ignoreBack = () => false;

    handleBackAction = () => {
        if (UtilsDevice.isMobileNative()) {
            document.addEventListener(EVENT_BACKBUTTON, this.ignoreBack);
        }
    };

    handleRecoverPassword = () => {
        const { dispatch } = this.props;

        dispatch(ActionsRecoverPassword.recoverPassCleanUp());
        dispatch(Push("/recoveryPass"));
    };

    handleRecoverPasswordMobile = () => {
        const { dispatch } = this.props;

        dispatch(ActionsRecoverPassword.recoverPassCleanUp());
        dispatch(Push("/recoveryPassnoToken"));
    };

    render() {
        const { securitySeal, showCaptcha, isMobileNative, isMobile, isSubmitting } = this.props;
        return (
            <React.Fragment>
                <div className="login-header">
                    <span className="login-header-text">
                        <I18n id="login.step2.title" />
                    </span>
                </div>
                <div className="login-content">
                    <div className="login-row simple-row">
                        <div className="login-picture">
                            <img src={securitySeal} alt="securitySealAlternativeText" />
                        </div>
                    </div>
                    <div className="login-row simple-row">
                        <span className="login-message">
                            <I18n id="login.step2.verifyYourImage" />
                        </span>
                    </div>
                    <div className="login-row main-row">
                        <Form className="login-form">
                            <div className="login-form-field">
                                <div className="login-form-field-block">
                                    <div className="login-form-field-image">
                                        <Image src="web-token.svg" className="login-form-field-icon lock-icon" />
                                    </div>

                                    <Field
                                        idForm={FORM_ID}
                                        name="password"
                                        className="login-form-input input-icon"
                                        hidelabel
                                        component={Credential}
                                        autoFocus
                                        value={EMPTY_STR}
                                    />
                                    {showCaptcha && !isMobileNative && (
                                        <Field idForm={FORM_ID} name="captcha" component={Captcha} />
                                    )}
                                </div>
                                <span className="login-form-field-validation" />
                            </div>
                            <div className="login-form-field button-field">
                                {!isMobile && (
                                    <LinkButton className="link" onClick={() => this.handleRecoverPassword()}>
                                        <I18n id="login.step2.forgotPassword" />
                                    </LinkButton>
                                )}
                                {isMobile && (
                                    <LinkButton className="link" onClick={() => this.handleRecoverPasswordMobile()}>
                                        <I18n id="login.step2.forgotPassword" />
                                    </LinkButton>
                                )}
                                <Button
                                    className="login-form-button main-button"
                                    bsStyle="primary"
                                    label="login.comeIn"
                                    loading={isSubmitting}
                                    type="submit"
                                    onClick={this.handleBackAction}
                                />
                            </div>
                        </Form>
                    </div>
                    <div className="enrollment-content-dialog">
                        <span className="enrollment-content-dialog-text" />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default HighOrder(
    Connect((state) => ({
        isTrusted: SelectorsStoreConfig.getIsTrusted(state),
        securitySeal: SelectorsLogin.getSecuritySeal(state),
        showCaptcha: SelectorsLogin.getShowCaptcha(state),
    })),
    HighOrder.Resizable,
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            password: EMPTY_STR,
            captcha: EMPTY_STR,
        }),
        validationSchema: ({ isMobileNative, showCaptcha }) =>
            Yup.object().shape({
                password: Yup.string().required(UtilsI18n.get(`${FORM_ID}.password.required`)),
                captcha:
                    !isMobileNative && showCaptcha
                        ? Yup.string().required(UtilsI18n.get(`${FORM_ID}.captcha.required`))
                        : Yup.string(),
            }),
        handleSubmit: (data, formikBag) => {
            const { password, captcha } = data;
            formikBag.props.dispatch(ActionsLogin.loginStep2({ password, captcha, formikBag }));
        },
    }),
)(Component);
