import * as ApiMobile from "~/store/apiMobile";
import * as ApiWeb from "~/store/apiWeb";
import { addTimezoneHours as AddTimezoneHours } from "~/util/date";
import DeviceUtils from "~/util/device";

const addTimezoneHoursToScheduler = (params) => {
    const newParams = params;

    if (newParams && newParams.scheduler) {
        const { scheduler } = newParams;

        if (scheduler) {
            const { valueDate } = scheduler;

            if (valueDate) {
                const newScheduler = {
                    ...scheduler,
                    valueDate: AddTimezoneHours(valueDate),
                };

                delete newParams.scheduler;
                newParams.scheduler = newScheduler;
            }
        }
    }

    return newParams;
};

export const downloadWithAccessToken = (idActivity, params) => {
    if (DeviceUtils.isMobileNative()) {
        return ApiMobile.downloadWithAccessToken(idActivity, params);
    }

    return ApiWeb.downloadWithAccessToken(idActivity, params);
};

export const executeAnonymous = (idActivity, params, etag) => {
    if (DeviceUtils.isMobileNative()) {
        return ApiMobile.executeAnonymous(idActivity, params, etag);
    }

    return ApiWeb.executeAnonymous(idActivity, params, etag);
};

export const executeAnonymousWithWebToken = (idActivity, params, etag) => {
    if (DeviceUtils.isMobileNative()) {
        return ApiMobile.executeAnonymousWithWebToken(idActivity, params, etag);
    }

    return ApiWeb.executeAnonymousWithWebToken(idActivity, params, etag);
};

export const executeWithAccessToken = (idActivity, params, etag) => {
    const properTimezoneParams = addTimezoneHoursToScheduler(params);

    if (DeviceUtils.isMobileNative()) {
        return ApiMobile.executeWithAccessToken(idActivity, properTimezoneParams, etag);
    }

    return ApiWeb.executeWithAccessToken(idActivity, properTimezoneParams, etag);
};

export const executeWithExchangeToken = (idActivity, params, exchangeToken, etag) => {
    if (DeviceUtils.isMobileNative()) {
        return ApiMobile.executeWithExchangeToken(idActivity, params, exchangeToken, etag);
    }

    return ApiWeb.executeWithExchangeToken(idActivity, params, exchangeToken, etag);
};

export const executeWithWebToken = (idActivity, params, etag) => {
    const properTimezoneParams = addTimezoneHoursToScheduler(params);

    if (DeviceUtils.isMobileNative()) {
        return ApiMobile.executeWithWebToken(idActivity, properTimezoneParams, etag);
    }

    return ApiWeb.executeWithWebToken(idActivity, properTimezoneParams, etag);
};

export const executeWithWebTokenWithExchange = (idActivity, params, exchangeToken, etag) => {
    if (DeviceUtils.isMobileNative()) {
        return ApiMobile.executeWithWebToken(idActivity, params, exchangeToken, etag);
    }

    return ApiWeb.executeWithWebToken(idActivity, params, exchangeToken, etag);
};

export const setAuthToken = (token) => {
    if (DeviceUtils.isMobileNative()) {
        ApiMobile.setAuthToken(token);
    } else {
        ApiWeb.setAuthToken(token);
    }
};
