/* eslint-disable no-plusplus */
import React from "react";

import { Modal } from "react-bootstrap";
import NumberFormat from "react-number-format";

import * as i18nUtils from "~/util/i18n";

import Box from "~/components/Box";
import DataNumber from "~/components/DataNumber";
import GridLayout from "~/components/GridLayout";
import Image from "~/components/Image";

import Style from "./CreditLetterModal.rules.scss";

export const { NAME, NAME_MESSAGES, ID } = Style;
export const PROP = {
    types: {},
    defaults: {},
};

export class Component extends React.Component {
    renderFieldValue = (data, field, currency) => {
        if (field.type === "amount") {
            return (
                <DataNumber
                    value={data[field.id]}
                    prefix={field.forceCurrency ? field.forceCurrency : currency}
                    className="mobile-only"
                />
            );
        }
        if (data[field.id]) {
            return <span>{data[field.id]}</span>;
        }
        return <span>N/A</span>;
    };

    getRates = () => {
        const { data } = this.props;
        const rates = [];

        for (let i = 0; i < data.length; i++) {
            const r = data[i].rates;
            for (let j = 0; j < r.length; j++) {
                if (!rates.includes(r[j].amountFrom)) {
                    rates.push(r[j].amountFrom);
                }
            }
        }
        return rates;
    }

    render() {
        const { show, handleCloseModal, data, currency } = this.props;
        const rates = this.getRates();
        return (
            <Modal show={show} id={ID} className="termDepositModal">
                <Modal.Body>
                    <div className="component-modal">
                        <div className="closed clickable" onClick={handleCloseModal} role="button">
                            <Image src="close.svg" />
                        </div>
                        <div>
                            <h2>{i18nUtils.get(`investment.termDeposit.modal.title.${currency}`)}</h2>
                        </div>
                        <GridLayout>
                            <Box justify="center">
                                <p>{i18nUtils.get("investment.termDeposit.modal.header.term")}</p>
                            </Box>
                            {rates.map((item) => {
                                return (
                                    <Box justify="center">
                                        <NumberFormat
                                            className="form-control amount"
                                            fixedDecimalScale
                                            decimalScale="0"
                                            decimalSeparator=""
                                            thousandSeparator="."
                                            prefix="Desde "
                                            displayType="text"
                                            value={Math.round(item)}
                                        />
                                    </Box>
                                )
                            })}
                        </GridLayout>

                        {data.map((d) => (
                            <GridLayout className="body">
                                <Box justify="center"><p>{`${d.days} días`}</p></Box>
                                {rates.map((r) => {
                                    const element = d.rates.find((dr) => dr.amountFrom === r);
                                    return (
                                        <Box justify="center">
                                            <p>{`${element ? `${element.rate}%` : ""}`}</p>
                                        </Box>
                                    );
                                })}
                            </GridLayout>
                        ))}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
export default Component;
