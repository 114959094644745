import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";

import { EMPTY_STR, FIELD_SUBTYPE, CNY, ID_FIELD } from "~/constants";
import { MODE } from "~/constants/form";
import { SelectorsAction as SelectorActionsTransfer } from "~/store/transfers";
import { getInteger as GetInteger } from "~/util/config";
import { toNumber as ToNumber } from "~/util/number";

import I18n from "~/components/I18n";
import NumberFormatInput from "~/components/NumberFormatInput";
import Select from "~/components/Select";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import WithFocus from "~/pages/_components/withFocus";

import FormField from "~/pages/forms/_components/_fields/_commons/formField";

const INPUT_REGEX_REPLACE = /[^0-9.,]/g;

export const NAME = "Amount";

export const PROP = {
    types: {
        data: PropTypes.shape({
            options: PropTypes.array,
            thousandsSeparator: PropTypes.string.isRequired,
            decimalSeparator: PropTypes.string.isRequired,
            precision: PropTypes.number,
        }).isRequired,
        editing: PropTypes.bool,
        label: PropTypes.string,
        isRequired: PropTypes.bool,
        optionalMessage: PropTypes.string,
        paymentPurposes: PropTypes.array,
    },
    defaults: {
        editing: EMPTY_STR,
        label: EMPTY_STR,
        isRequired: false,
        optionalMessage: EMPTY_STR,
        paymentPurposes: null,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    quantityRef = null;

    componentDidMount() {
        const { mode, field, focus, data, value, setValue } = this.props;

        // This value is set here due to decimal conversion performed in the Java class.
        // Without this code, the amount's decimal part is turned into integer, because of the different
        // decimal character between languages.
        const amount = {
            currency: value ? value.currency : EMPTY_STR,
            quantity: value ? value.quantity : EMPTY_STR,
        };

        setValue(amount);

        if (mode === MODE.EDIT && field.value === EMPTY_STR) {
            this.handleChange({ value: data.options[0].id });
        }
        // First form field's focus is usually set to true
        if (this.quantityRef && focus) {
            this.quantityRef.focus();
        }
    }

    componentDidUpdate(prevProps) {
        const { value, setValue } = this.props;
        if (prevProps.value !== undefined && value && value.quantity !== prevProps.value.quantity) {
            const amount = {
                currency: value ? value.currency : EMPTY_STR,
                quantity: value ? value.quantity : EMPTY_STR,
            };

            setValue(amount);
        }
    }

    handleChange = (selectedCurrency = {}) => {
        if (selectedCurrency) {
            const {
                data: { decimalSeparator, precision },
                value,
                setValue,
                subType,
                form,
                dispatch,
            } = this.props;

            let quantity = this.quantityRef.value.replace(INPUT_REGEX_REPLACE, EMPTY_STR);
            quantity = ToNumber(quantity, decimalSeparator, precision);

            const amount = {
                currency: selectedCurrency.value || value.currency,
                quantity,
            };

            setValue(amount);

            if (subType === FIELD_SUBTYPE.PAYMENT_PURPOSE) {
                const { currency: idCurrency } = amount;

                if (CNY !== idCurrency && form.values?.purpose) {
                    form.setFieldValue(ID_FIELD.PURPOSE, null);
                }
            } else if (subType === FIELD_SUBTYPE.TRANSFER_THIRD_PARTIES) {
                dispatch(SelectorActionsTransfer.updateCurrency({ currency: amount.currency }));
            }
        }
    };

    render() {
        const {
            editing,
            value,
            placeholder,
            toggleIsFocused,
            data: { options, decimalSeparator, precision, thousandsSeparator },
            onBlur,
        } = this.props;

        const selectedCurrency = value ? value.currency : EMPTY_STR;
        const selectedQuantity = value ? value.quantity : EMPTY_STR;

        if (editing) {
            return (
                <div>
                    <div className="input-group" onFocus={toggleIsFocused} onBlur={toggleIsFocused}>
                        {options.length === 1 ? (
                            <span className="currency">{options[0].label}</span>
                        ) : (
                            <Select
                                className="currency-selector slideFromBottom flex-container"
                                name="currency"
                                onChange={this.handleChange}
                                value={selectedCurrency}
                                options={options.map((option) => ({
                                    value: option.id,
                                    label: option.label,
                                }))}
                                clearable={false}
                            />
                        )}

                        <NumberFormatInput
                            name="quantity"
                            className="form-control text-right"
                            type="tel"
                            displayType="input"
                            onBlur={onBlur}
                            onChange={this.handleChange}
                            maxLength={GetInteger("amount.maxLength")}
                            value={selectedQuantity}
                            placeholder={placeholder}
                            decimalScale={precision}
                            thousandSeparator={thousandsSeparator}
                            decimalSeparator={decimalSeparator}
                            fixedDecimalScale
                            getInputRef={(ref) => {
                                this.quantityRef = ref;
                            }}
                        />
                    </div>
                </div>
            );
        }
        return <FormattedAmount {...value} />;
    }
}

export default Compose(
    WithFocus,
    FormField({
        formClass: "form-group--composite",
        customLabel: (prop) => {
            const { editing, label, isRequired, optionalMessage } = prop;
            if (editing) {
                return (
                    <div className="form-group-text">
                        <label className="control-label" htmlFor="currency">
                            <I18n id="form.field.amount.currency" />
                            {!isRequired && <small className="text-optional">{optionalMessage}</small>}
                        </label>
                        <label className="control-label" htmlFor="quantity">
                            {label}
                        </label>
                    </div>
                );
            }
            return null;
        },
    }),
    Connect(),
)(Component);
