import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.INIT:
            return {
                ...storeProp,
                isLoading: true,
            };
        case TYPE.READY: {
            return {
                ...storeProp,
                isLoading: false,
            };
        }
        default:
            return storeProp;
    }
};
