import React from "react";

import { push as Push } from "connected-react-router";
import { parseISO } from "date-fns";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { DATA_NOT_AVAILABLE, EMPTY_STR, UNDEFINED_CURRENCY } from "~/constants";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import {
    SelectorsAction as SelectorsActionSwiftMessages,
    SelectorsStore as SelectorsStoreSwiftMessages,
} from "~/store/swift";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import * as UtilsConfig from "~/util/config";
import { differenceInCalendarDays } from "~/util/date";
import UtilFlag from "~/util/flag";
import { generateId as GenID } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import DataDate from "~/components/DataDate";
import DataNumber from "~/components/DataNumber";
import GridLayout from "~/components/GridLayout";
import Image from "~/components/Image";
import IntersectionObserver from "~/components/IntersectionObserver";
import Note from "~/components/Note";

import Style from "./_Messages.rules.scss";

export const { NAME, TYPE, NAME_NOTE, TYPE_NOTE } = Style;

export const PROP = {
    types: {
        swiftMessages: PropTypes.shape({
            incomingOutcoming: PropTypes.string,
            type: PropTypes.number,
            subtype: PropTypes.string,
            date: PropTypes.string,
            payerBeneficiary: PropTypes.string,
            beneficiaryAccount: PropTypes.string,
            reference: PropTypes.string,
            currency: PropTypes.string,
            amount: PropTypes.number,
        }),
    },
    defaults: {
        i18n: {
            loading: "Cargando",
            notAvailable: DATA_NOT_AVAILABLE,
            labelMore: "Más movimientos",
            labelNoMore: "No hay más movimientos",
        },
    },
};

export function Component(props) {
    const { dispatch, i18n, isFetching, moreLines, productGroupId, swiftMessages, isMobile } = props;
    const { loading, labelMore, labelNoMore, noRecords } = i18n;

    const handleTrackingEnvironment = () => {
        const availableEnvironments = UtilsConfig.getArray("client.gpi.tracking.enabled.environments");

        return availableEnvironments.length === 0 || availableEnvironments.includes(productGroupId);
    };

    const COLUMN_HEADINGS = [
        i18n.headerType,
        i18n.headerSubtype,
        i18n.headerDate,
        i18n.headerBeneficiary,
        i18n.headerReference,
        i18n.headerCurrency,
        i18n.headerAmount,
        EMPTY_STR,
        ...(handleTrackingEnvironment() ? [i18n.tracking] : []),
    ];

    const recordsFound = swiftMessages.length > 0;

    const handleTracking = (event, key) => {
        event.preventDefault();
        event.stopPropagation();

        dispatch(SelectorsActionTransactions.gpiTracking({ swiftKey: key.keyString }));
    };

    const handleTrackIcon = (subtype, incomingOutcoming, date) => {
        if (handleTrackingEnvironment()) {
            if (subtype !== "E") {
                return false;
            }

            if (incomingOutcoming !== "S") {
                return false;
            }

            const today = new Date();
            const daysBefore = parseInt(UtilsConfig.get("client.gpi.tracking.daysBefore"), 0);
            const dateAsDate = parseISO(date);
            const difference = differenceInCalendarDays(today, dateAsDate);

            if (difference <= daysBefore) {
                return true;
            }

            return false;
        }
        return false;
    };

    const render = () => (
        <React.Fragment>
            <GridLayout className={handleTrackingEnvironment() ? "header tracking" : "header"}>
                {COLUMN_HEADINGS.map((heading, index) => (
                    <Box
                        className={index === COLUMN_HEADINGS.length - 1 ? "last-element" : EMPTY_STR}
                        key={GenID()}
                        justify={index === 6 || index === COLUMN_HEADINGS.length - 1 ? "end" : EMPTY_STR}>
                        {heading}
                    </Box>
                ))}
            </GridLayout>
            {recordsFound ? (
                swiftMessages.map(
                    ({ type, subtype, date, beneficiary, reference, currency, incomingOutcoming, amount, key }) => (
                        <GridLayout
                            className={handleTrackingEnvironment() ? "item tracking" : "item"}
                            key={`swiftMessage-${GenID()}`}
                            onClick={() => onMessageClick(key)}>
                            <Box>
                                <div title={UtilsI18n.get(`swift.message.type.MT${type}.description`)}>MT{type}</div>
                            </Box>

                            <Box>{UtilsI18n.get(`swift.subtype.label.${subtype}`)}</Box>
                            <Box>
                                <DataDate value={date} />
                            </Box>
                            <Box>{beneficiary}</Box>
                            <Box>{reference}</Box>
                            <Box>
                                {/* Dont show currency when is undefined */}
                                {currency !== UNDEFINED_CURRENCY && (
                                    <React.Fragment>
                                        <Image src={`${UtilFlag.getFlag(`${currency}`)}.svg`} />
                                        {UtilsI18n.get(`currency.label.${currency}`)}
                                    </React.Fragment>
                                )}
                            </Box>
                            <Box justify="end">
                                {/* Dont show amount when its value is 0 */}
                                {amount !== 0 && <DataNumber value={amount} fixedDecimalScale />}
                            </Box>
                            <Box>
                                {amount !== 0 && (
                                    <Image src={`${incomingOutcoming === "S" ? "Debito" : "Credito"}.svg`} />
                                )}
                            </Box>

                            {handleTrackIcon(subtype, incomingOutcoming, date) && (
                                <Box justify="end" className="last-element">
                                    <div className="transaction-list-data align-center">
                                        <div className="tracking-button" onClick={(e) => handleTracking(e, key)}>
                                            <Image src="search.svg" />
                                        </div>
                                    </div>
                                </Box>
                            )}
                        </GridLayout>
                    ),
                )
            ) : (
                <Box flex justify="center">
                    <Note className="no-more-movements">{noRecords}</Note>
                </Box>
            )}
            {isFetching && (
                <Box flex justify="center">
                    <Note>{loading}</Note>
                </Box>
            )}
            {!isFetching && moreLines && (
                <Box flex justify="center">
                    <Note intersection-trigger="true" onClick={() => handleMovementsFetch({ isIntersecting: true })}>
                        {labelMore}
                    </Note>
                </Box>
            )}

            {!isFetching && !moreLines && recordsFound && (
                <Box flex justify="center">
                    <Note noMoreData>{labelNoMore}</Note>
                </Box>
            )}
        </React.Fragment>
    );

    return isMobile ? (
        <IntersectionObserver onIntersect={handleMovementsFetch}>{render()}</IntersectionObserver>
    ) : (
        render()
    );

    function handleMovementsFetch(ev) {
        const { isIntersecting } = ev;
        if (!isIntersecting) {
            return;
        }
        dispatch(SelectorsActionSwiftMessages.loadListSwiftMessagesRequest({ moreOrders: true }));
    }

    function onMessageClick(key) {
        dispatch(Push(`/swift/detail/${key.keyString}`));
    }
}
const mapStateToProps = (store) => ({
    swiftMessages: SelectorsStoreSwiftMessages.getSwiftMessages(store),
    isFetching: SelectorsStoreSwiftMessages.isFetching(store),
    moreLines: SelectorsStoreSwiftMessages.getMoreLines(store),
    i18n: {
        loading: StoreI18n.getMessage(store, "global.loading"),
        noRecords: StoreI18n.getMessage(store, "global.noRecords"),
        labelMore: StoreI18n.getMessage(store, "swift.list.moreMessages"),
        labelNoMore: StoreI18n.getMessage(store, "swift.list.noMoreMessages"),
        headerType: StoreI18n.getMessage(store, "swift.list.columnHeading.type"),
        headerSubtype: StoreI18n.getMessage(store, "swift.list.columnHeading.subtype"),
        headerDate: StoreI18n.getMessage(store, "swift.list.columnHeading.date"),
        headerBeneficiary: StoreI18n.getMessage(store, "swift.list.columnHeading.beneficiary"),
        headerReference: StoreI18n.getMessage(store, "swift.list.columnHeading.reference"),
        headerCurrency: StoreI18n.getMessage(store, "swift.list.columnHeading.currency"),
        headerAmount: StoreI18n.getMessage(store, "swift.list.columnHeading.amount"),
        tracking: StoreI18n.getMessage(store, "swift.list.columnHeading.tracking"),
    },
    productGroupId: SelectorsStoreSession.getProductGroupId(store),
});

export default Connect(mapStateToProps)(Component);
