import React from "react";

import PropTypes from "prop-types";

import Select from "~/components/Select";

import formField from "~/pages/forms/_components/_fields/_commons/formField";

export const NAME = "Branchlist";

export const PROP = {
    types: {
        data: PropTypes.shape({
            branches: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })).isRequired,
        }).isRequired,
        setValue: PropTypes.func.isRequired,
        editing: PropTypes.bool,
        value: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
    },
    defaults: { placeholder: "", editing: false },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    handleChange = ({ id }) => {
        const { setValue } = this.props;
        setValue(id);
    };

    render() {
        const {
            data: { branches },
            editing,
            value,
            placeholder,
        } = this.props;

        if (editing) {
            return (
                <div className="input-group">
                    <div style={{ flex: 1 }}>
                        <Select
                            placeholder={placeholder}
                            value={value}
                            clearable={false}
                            searchable={false}
                            onChange={this.handleChange}
                            valueKey="id"
                            labelKey="name"
                            options={branches}
                            className="flex-container slideFromBottom"
                        />
                    </div>
                </div>
            );
        }
        const { name } = branches.find(({ id }) => id === value) || {};
        return name || null;
    }
}

export default formField({
    formClass: "form-group--select",
})(Component);
