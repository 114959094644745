import React from "react";

import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { connect as Connect } from "react-redux";

import { SelectorsAction as SelectorsActionDesktop, SelectorsStore as SelectorsStoreDesktop } from "~/store/desktop";
import { SelectorsAction as SelectorsActionSession, SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as I18nUtil from "~/util/i18n";

import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Menu from "~/components/Menu";

export const NAME = "EnvironmentSelector";

export const PROP = {
    types: {
        environments: PropTypes.objectOf(PropTypes.object).isRequired,
        activeEnvironment: PropTypes.shape({
            id: PropTypes.number,
        }),
        user: PropTypes.shape({
            userFullName: PropTypes.string,
        }).isRequired,
        dispatch: PropTypes.func.isRequired,
        isMobile: PropTypes.bool,
    },
    defaults: {
        activeEnvironment: {},
        isMobile: false,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    handleEnvironmentClick = (id) => {
        const { dispatch } = this.props;
        this.handleClose();
        dispatch(
            SelectorsActionSession.changeEnvironment({
                idEnvironment: id,
                rememberEnvironment: false,
                formikBag: null,
                shouldNavigate: false,
            }),
        );
    };

    handleClose = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionDesktop.toggleEnvironmentModal());
    };

    handleShow = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionDesktop.toggleEnvironmentModal());
    };

    renderEnvironments = (ids) => {
        const { activeEnvironment, environments, isMobile } = this.props;
        const label = I18nUtil.get("menu.account.number");
        return ids.map((id) => (
            <Menu.Item
                isSelected={isMobile && activeEnvironment.id === id}
                key={`navBar.environment.${id}`}
                title={
                    <div>
                        <span className="environment-name">{environments[id].name}</span>
                        <span className="environment-number">{label}</span>
                        <span className="environment-number">{environments[id].productGroupId}</span>
                    </div>
                }
                image={`${environments[id].environmentType}.svg`}
                onClick={() => this.handleEnvironmentClick(id)}
            />
        ));
    };

    render() {
        const { activeEnvironment, environments, isMobile, showEnvironmentModal } = this.props;
        // Create an array containing all the environments.
        const ids = Object.keys(environments).map((key) => parseInt(key, 10));
        // If there are no other environments beside this one, there's nothing to do.
        if (!environments || !ids.length) {
            return null;
        }
        const { name, environmentType, productGroupId } = environments[activeEnvironment.id];

        return (
            // Send components for title and image, to send custom content
            <Menu.Section
                onClick={this.handleShow}
                className="dropdown-inHeader dropdown-inHeader-list menu-item item-environment"
                title={<span>{`${name} - ${I18nUtil.get("menu.account.number")}: ${productGroupId}`}</span>}
                image={
                    <div className="rounder-content">
                        <Image className="svg-icon" src={`${environmentType}.svg`} />
                    </div>
                }>
                {/* Render remaining environments as Menu.Items */}
                {isMobile ? (
                    <Modal show={showEnvironmentModal} onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-center">
                                <I18n id="settings.options.changeEnvironment" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{this.renderEnvironments(ids, environments)}</Modal.Body>
                    </Modal>
                ) : (
                    this.renderEnvironments(ids, environments)
                )}
            </Menu.Section>
        );
    }
}

export default Connect((store) => ({
    environments: SelectorsStoreSession.getEnvironments(store),
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
    user: SelectorsStoreSession.getUser(store),
    showEnvironmentModal: SelectorsStoreDesktop.showEnvironmentModal(store),
}))(Component);
