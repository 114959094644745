import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    readBackofficeTransaction: (props) => API.executeAnonymous(PATH.READ_BACKOFFICE_TRANSACTION, props),
    readEnvironmentData: (props) => API.executeAnonymous(PATH.MISC_READ_ENVIRONMENT_DATA, props),
    readUserData: (props) => API.executeAnonymous(PATH.MISC_READ_USER_DATA, props),
};

export const SelectorsAction = {
    backofficeTransactionFailure: (props) => FactoryAction(TYPE.BACKOFFICE_TRANSACTION_FAILURE, props),
    backofficeTransactionRequest: (props) => FactoryAction(TYPE.BACKOFFICE_TRANSACTION_REQUEST, props),
    backofficeTransactionSuccess: (props) => FactoryAction(TYPE.BACKOFFICE_TRANSACTION_SUCCESS, props),
    readEnvironmentDataFailure: (props) => FactoryAction(TYPE.READ_ENVIRONMENT_DATA_FAILURE, props),
    readEnvironmentDataRequest: (props) => FactoryAction(TYPE.READ_ENVIRONMENT_DATA_REQUEST, props),
    readEnvironmentDataSuccess: (props) => FactoryAction(TYPE.READ_ENVIRONMENT_DATA_SUCCESS, props),
    readUserDataFailure: (props) => FactoryAction(TYPE.READ_USER_DATA_FAILURE, props),
    readUserDataRequest: (props) => FactoryAction(TYPE.READ_USER_DATA_REQUEST, props),
    readUserDataSuccess: (props) => FactoryAction(TYPE.READ_USER_DATA_SUCCESS, props),
};

export const SelectorsStore = {
    getEnvironmentData: (store) => store[NAME].environmentData,
    getFetching: (store) => store[NAME].fetching,
    getIdForm: (store) => store[NAME].idForm,
    getTransaction: (store) => store[NAME].transaction,
    getUserData: (store) => store[NAME].userData,
};
