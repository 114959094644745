import { put as Put, takeLatest as TakeLatest, call as Call, select as Select } from "redux-saga/effects";

import {
    CDP_CURRENCY,
    CREDENTIAL_TYPE_OTP,
    LEVEL,
    RESPONSE_TYPE,
    SCOPE,
    SIGN_WITH_BIOMETRIC,
    SUPPORTED_FILE_TYPES,
    ZERO,
} from "~/constants";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import IsTrustedBiometric from "~/store/form/_sagas/_isTrustedBiometric";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { downloadPdf as downloadPDF, downloadXls } from "~/util/download";
import {
    credentialsWithUnderscore as CredentialsWithUnderscore,
    hasIncorrectCredentials as HasIncorrectCredentials,
    adjustIdFieldErrors as AdjustIdFieldErrors,
} from "~/util/form";
import * as UtilsI18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsStore, SelectorsMiddleware } from "./_selectors";

const sagas = [
    TakeLatest(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, cancelTransactionCustom),
    TakeLatest(TYPE.EXPORT_PDF, uploadPaymentOrdersExportPDF),
    TakeLatest(TYPE.PREVIEW_FORM_REQUEST, uploadPaymentOrdersPreviewFormRequest),
    TakeLatest(TYPE.READ_TRANSACTION_REQUEST, requestUploadPaymentOrdersTransaction),
    TakeLatest(TYPE.SEND_FORM_REQUEST, uploadPaymentOrdersSendFormRequest),
    TakeLatest(TYPE.SIGN_TRANSACTION_PREVIEW_REQUEST, signTransactionPreview),
    TakeLatest(TYPE.SIGN_TRANSACTION_REQUEST, signTransaction),
    TakeLatest(TYPE.DOWNLOAD_PAYMENT_ORDERS_REQUEST, downloadPaymentOrdersRequest),
];

export default sagas;

function* uploadPaymentOrdersPreviewFormRequest(props) {
    const { paymentOrdersList, totalizers } = props;

    yield Put(SelectorsAction.uploadPaymentOrdersPreviewFormSuccess({ paymentOrdersList, totalizers }));
}

function* uploadPaymentOrdersSendFormRequest(props) {
    const paymentOrdersList = yield Select(SelectorsStore.getPaymentOrders);
    const totalizersList = yield Select(SelectorsStore.getTotalizers);
    const totalizerUSD = totalizersList.filter((totalizer) => totalizer.currency === 2222);
    const totalizerUYU = totalizersList.filter((totalizer) => totalizer.currency === 0);

    const amountUSD = {
        currency: CDP_CURRENCY.USD,
        quantity: totalizerUSD[0]?.amount.toString() || ZERO,
    };

    const amountUYU = {
        currency: CDP_CURRENCY.UYU,
        quantity: totalizerUYU[0]?.amount.toString() || ZERO,
    };

    const { otp, formikBag } = props;
    const { type, data } = yield Call(SelectorsMiddleware.uploadPaymentOrdersSend, {
        paymentOrdersList,
        totalizersList,
        amountUYU,
        amountUSD,
        _otp: otp,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.uploadPaymentOrdersSendFormFailure());

        const hasIncorrectCredentials = Object.keys(data.data).some((key) => key === CREDENTIAL_TYPE_OTP);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
        }

        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.FACTORING_UPLOAD_PAYMENT_ORDERS],
            }),
        );

        formikBag.setSubmitting(false);
    } else {
        const { idTransaction } = data;
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });

        yield Put(SelectorsAction.uploadPaymentOrdersSendFormSuccess({ transaction }));
    }
}

function* requestUploadPaymentOrdersTransaction(props) {
    const { idTransaction } = props;
    const { type, data } = yield Call(SelectorsMiddlewareForm.readTransaction, {
        idTransactionToRead: idTransaction,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.FACTORING_UPLOAD_PAYMENT_ORDERS],
            }),
        );
    } else {
        yield Put(SelectorsAction.readUploadPaymentOrdersTransactionSuccess({ transaction: data.data.transaction }));
    }
}

function* uploadPaymentOrdersExportPDF(props) {
    const { invalidLinesData, uploadDetailList } = props;
    const { type, data } = yield Call(SelectorsMiddleware.uploadPaymentOrdersExportPDF, {
        invalidLines: invalidLinesData,
        uploadDetailList,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.FACTORING_UPLOAD_PAYMENT_ORDERS],
            }),
        );
    } else {
        const { content, fileName } = data.data;

        downloadPDF(fileName, content);
    }
}

function* cancelTransactionCustom(props) {
    const { credentials, idTransaction, formikBag } = props;

    yield* handleCancelTransaction(credentials, idTransaction, formikBag);

    formikBag.setSubmitting(false);
}

function* handleCancelTransaction(credentials, idTransaction, formikBag) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);
    const {
        data: { data },
        type,
    } = yield Call(SelectorsMiddlewareForm.cancelTransaction, {
        idTransactionToCancel: idTransaction,
        ...credentialsWithUnderscore,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data);
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("forms.cancelTransaction.errorMessage"),
                    level: LEVEL.ERROR,
                    scopes: SCOPE.FACTORING_UPLOAD_PAYMENT_ORDERS,
                }),
            );
        }
    } else {
        yield* requestUploadPaymentOrdersTransaction({ idTransaction });

        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("forms.cancelTransaction.confirmationMessage"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.FACTORING_UPLOAD_PAYMENT_ORDERS, SCOPE.DESKTOP],
            }),
        );
    }
}

// Importar ordenes de pago no tiene un preview.
// Solo hacemos esto para ir al paso 2 del formulario y dejar la misma estructura tipo de formularios dinamicos.
function* signTransactionPreview(props) {
    const { paymentOrdersList, totalizers, transaction } = props;

    yield Put(SelectorsAction.signTransactionPreviewSuccess({ paymentOrdersList, totalizers, transaction }));
}

function* signTransaction(props) {
    const { idForm, idActivity, idTransaction, credentials, formikBag } = props;
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
    }

    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { data, type } = yield Call(
        SelectorsMiddlewareForm.sign,
        { idForm, idTransaction, ...credentialsWithUnderscore },
        idActivity,
    );

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(AdjustIdFieldErrors(data.data));
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.FACTORING_UPLOAD_PAYMENT_ORDERS],
                }),
            );
        }

        formikBag.setSubmitting(false);
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });

        yield Put(
            SelectorsAction.uploadPaymentOrdersSendFormSuccess({
                transaction,
                idTransaction,
            }),
        );

        formikBag.setSubmitting(false);
    }
}

function* downloadPaymentOrdersRequest(props) {
    const { format, paymentOrdersList, totalizersList } = props;

    const { type, data } = yield Call(SelectorsMiddleware.downloadPaymentOrders, {
        paymentOrdersList,
        totalizersList,
        format,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.downloadPaymentOrdersFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.FACTORING_UPLOAD_PAYMENT_ORDERS],
            }),
        );
    } else {
        const { content, fileName } = data.data;
        switch (format) {
            case SUPPORTED_FILE_TYPES.XLS:
                downloadXls(fileName, content);

                break;
            default:
                break;
        }

        yield Put(SelectorsAction.downloadPaymentOrdersSuccess());
    }
}
