import { EMPTY_STR, REACT_APP_VERSION } from "~/constants";
import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    changeEnvironment: (props) => API.executeWithAccessToken(PATH.SESSION_CHANGE_ENVIRONMENT, props),
    check: () => API.executeWithAccessToken(PATH.SESSION_GET),
    checkFingerprintSession: (_accessToken) => {
        API.setAuthToken(_accessToken);

        return API.executeWithAccessToken(PATH.FINGERPRINT_SESSION_EXIST);
    },
    extend: () => API.executeWithAccessToken(PATH.SESSION_EXTEND),
    fingerprintLogin: (props) =>
        API.executeWithAccessToken(PATH.LOGIN_BIOMETRIC, {
            _deviceFingerprintData: props.fingerprintData,
            _deviceLocationVU: props.deviceLocationVU,
            _fingerprint: props.fingerprint,
            appVersion: REACT_APP_VERSION,
        }),
    loginStep1: (props) => API.executeAnonymousWithWebToken(PATH.SESSION_LOGIN_STEP1, props),
    // NOTE: password removed, see note on handleLoginStep2Request saga
    loginStep2: (exchangeToken, props) => API.executeWithExchangeToken(PATH.SESSION_LOGIN_STEP2, props, exchangeToken),
    // NOTE: password added, see note on handleLoginStep2Request saga
    loginStep3: (exchangeToken, props) =>
        API.executeWithExchangeToken(
            PATH.SESSION_LOGIN_STEP3,
            {
                _deviceFingerprintData: props.fingerprintData,
                _deviceLocationVU: props.deviceLocationVU,
                _fingerprint: props.fingerprint,
                // eslint-disable-next-line no-underscore-dangle
                _password: props._password,
                appVersion: REACT_APP_VERSION,
                environment: props.environment,
                setAsDefault: props.setAsDefault,
                ...props.assistant,
            },
            exchangeToken,
        ),
    loginStep4: (exchangeToken, props) =>
        API.executeWithExchangeToken(
            PATH.SESSION_LOGIN_STEP4,
            {
                _deviceFingerprintData: props.fingerprintData,
                _deviceLocationVU: props.deviceLocationVU,
                _fingerprint: props.fingerprint,
                acceptConditions: props.acceptConditions,
                appVersion: REACT_APP_VERSION,
            },
            exchangeToken,
        ),
    logout: () => {
        const response = API.executeWithAccessToken(PATH.SESSION_LOGOUT);

        API.setAuthToken(null);
        return response;
    },
    readPermissions: () => API.executeWithAccessToken(PATH.PERMISSIONS_READ),
    setAuthToken: (props) => API.setAuthToken(props),
};

export const SelectorsAction = {
    acceptMobileRequiredPermissions: (props) => FactoryAction(TYPE.ACCEPT_MOBILE_REQUIRED_PERMISSIONS, props),
    cancelEmailForceUpdateFlow: (props) => FactoryAction(TYPE.EMAIL_FORCE_UPDATE_CANCEL, props),
    cancelMobilePhoneForceUpdateFlow: (props) => FactoryAction(TYPE.MOBILE_PHONE_FORCE_UPDATE_CANCEL, props),
    cancelUserEmailForcedSync: (props) => FactoryAction(TYPE.CANCEL_USER_EMAIL_FORCED_SYNC, props),
    cancelUserMobilePhoneForcedSync: (props) => FactoryAction(TYPE.CANCEL_USER_MOBILE_PHONE_FORCED_SYNC, props),
    changeEnvironment: (props) => FactoryAction(TYPE.CHANGE_ENVIRONMENT_REQUEST, props),
    changeRiskProfileData: (props) => FactoryAction(TYPE.CHANGE_RISK_PROFILE_DATA, props),
    error: () => FactoryAction(TYPE.ERROR),
    expire: (props) => FactoryAction(TYPE.EXPIRE, props),
    extend: () => FactoryAction(TYPE.EXTEND),
    finishEmailForceUpdate: (props) => FactoryAction(TYPE.EMAIL_FORCE_UPDATE_SUCCESS, props),
    finishMobilePhoneForceUpdate: (props) => FactoryAction(TYPE.MOBILE_PHONE_FORCE_UPDATE_SUCCESS, props),
    finishUserEmailForceSync: (props) => FactoryAction(TYPE.FINISH_USER_EMAIL_FORCE_SYNC, props),
    finishUserMobilePhoneForceSync: (props) => FactoryAction(TYPE.FINISH_USER_MOBILE_PHONE_FORCE_SYNC, props),
    getDeviceLocationVU: () => FactoryAction(TYPE.GET_DEVICE_LOCATION_VU),
    getEmailForceUpdate: (props) => FactoryAction(TYPE.EMAIL_FORCE_UPDATE, props),
    getUserEmailForceSync: (props) => FactoryAction(TYPE.GET_USER_EMAIL_FORCE_SYNC, props),
    getUserMobilePhoneForceSync: (props) => FactoryAction(TYPE.GET_USER_MOBILE_PHONE_FORCE_SYNC, props),
    logout: () => FactoryAction(TYPE.LOGOUT_REQUEST),
    mobilePhoneForceUpdate: (props) => FactoryAction(TYPE.MOBILE_PHONE_FORCE_UPDATE, props),
    notifyUser: (props) => FactoryAction(TYPE.NOTIFY_USER, props),
    readPermissionsRequest: () => FactoryAction(TYPE.READ_PERMISSIONS_REQUEST),
    readPermissionsSuccess: (props) => FactoryAction(TYPE.READ_PERMISSIONS_SUCCESS, props),
    resetEmailForceUpdate: (props) => FactoryAction(TYPE.RESET_EMAIL_FORCE_UPDATE, props),
    resetMobilePhoneForceUpdate: (props) => FactoryAction(TYPE.RESET_MOBILE_PHONE_FORCE_UPDATE, props),
    resetSuggestAliasEnrollment: () => FactoryAction(TYPE.RESET_SUGGEST_ALIAS_ENROLLMENT),
    resetSuggestAliasEnrollmentShowHeader: () => FactoryAction(TYPE.RESET_SUGGEST_ALIAS_ENROLLMENT_SHOW_HEADER),
    resetUserEmailForceSync: (props) => FactoryAction(TYPE.RESET_USER_EMAIL_FORCE_SYNC, props),
    resetUserMobilePhoneForceSync: (props) => FactoryAction(TYPE.RESET_USER_MOBILE_PHONE_FORCE_SYNC, props),
    saveDeviceLocationVU: (props) => FactoryAction(TYPE.SAVE_DEVICE_LOCATION_VU, props),
    saveQrData: (props) => FactoryAction(TYPE.SAVE_QR_DATA, props),
    selectUserEmailToSync: (props) => FactoryAction(TYPE.SELECT_USER_EMAIL_TO_SYNC, props),
    selectUserMobilePhoneToSync: (props) => FactoryAction(TYPE.SELECT_USER_MOBILE_PHONE_TO_SYNC, props),
    suggestAliasEnrollment: (props) => FactoryAction(TYPE.SUGGEST_ALIAS_ENROLLMENT, props),
};

export const SelectorsStore = {
    getAccessToken: (store) => (store[NAME].user ? store[NAME].user.accessToken : null),
    getAccountDataForceUpdate: (store) => store[NAME].accountDataForceUpdate,
    getAccountDataNotify: (store) => store[NAME].accountDataNotify,
    getActiveEnvironment: (store) => store[NAME].activeEnvironment,
    getActiveEnvironmentForms: (store, idForm) =>
        store[NAME].activeEnvironment.forms[idForm] ? store[NAME].activeEnvironment.forms[idForm] : [],
    getActiveEnvironmentType: (store) => store[NAME].activeEnvironment.type,
    getAdministrationScheme: (store) => store[NAME].activeEnvironment?.administrationScheme,
    getDeviceLocationVU: (store) => store[NAME].deviceLocationVU,
    getEmailForceUpdate: (store) => store[NAME].emailForceUpdate,
    getEmailForceUpdateFlow: (store) => store[NAME].emailForceUpdateFlow,
    getEnvironments: (store) => store[NAME].environments,
    getForms: (store) => (store[NAME].activeEnvironment.forms ? store[NAME].activeEnvironment.forms : []),
    getMobilePhoneForceUpdate: (store) => store[NAME].mobilePhoneForceUpdate,
    getMobilePhoneForceUpdateFlow: (store) => store[NAME].mobilePhoneForceUpdateFlow,
    getMode: (store) => store[NAME].mode,
    getPersonalDataForceUpdate: (store) => store[NAME].personalDataForceUpdate,
    getPersonalDataNotify: (store) => store[NAME].personalDataNotify,
    getProductGroupId: (store) => store[NAME].productGroupId,
    getQrData: (store) => store[NAME].qrData,
    getSuggestAliasEnrollment: (store) => store[NAME].suggestAliasEnrollment,
    getSuggestAliasEnrollmentShowHeader: (store) => store[NAME].suggestAliasEnrollmentShowHeader,
    getUser: (store) => store[NAME].user,
    getUserEmail: (store) => (store[NAME].user ? store[NAME].user.email : null),
    getUserEmailForceSync: (store) => store[NAME].userEmailForceSync,
    getUserEmailSelected: (store) => store[NAME].userEmailSelected,
    getUserFullName: (store) => (store[NAME].user ? store[NAME].user.userFullName : EMPTY_STR),
    getUserId: (store) => (store[NAME].user ? store[NAME].user.userId : null),
    getUserMobilePhoneForceSync: (store) => store[NAME].userMobilePhoneForceSync,
    getUserMobilePhoneSelected: (store) => store[NAME].userMobilePhoneSelected,
    getUsername: (store) => (store[NAME].user ? store[NAME].user.userName : null),
    getUserSecuritySeal: (store) => (store[NAME].user ? store[NAME].user.securitySeal : null),
    getWebToken: (store) => store[NAME].webToken,
    isAdministrator: (store) => store[NAME].isAdministrator,
    isChangingEnvironment: (store) => store[NAME].changingEnvironment,
    isFetching: (store) => store[NAME].fetching,
    isLoggedIn: (store) => store[NAME].user && !!store[NAME].user.accessToken,
    isNotify: (store) => store[NAME].notifyUser,
};
