import React from "react";

import { Field } from "formik";
import PropTypes from "prop-types";
import ReactSelect from "react-select";

import { EMPTY_STR } from "~/constants";
import UtilsDevice from "~/util/device";
import * as I18nUtil from "~/util/i18n";

export const NAME = "Select";

export const PROP = {
    types: {
        name: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string,
                label: PropTypes.string,
            }),
        ),
        className: PropTypes.string,
        label: PropTypes.string,
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
    },
    defaults: { className: "form-group", label: EMPTY_STR, options: [], disabled: false, onChange: null },
};

export function Component(props) {
    const { name, className, label, disabled, onChange, options, placeholder, title } = props;
    const noOptionText = I18nUtil.get("selector.noOption.text");
    const isMobile = UtilsDevice.isDisplayMobile();

    return (
        <Field name={name}>
            {({ field, form }) => (
                <div title={title || EMPTY_STR} className={className}>
                    {label && (
                        <div className="form-group-text">
                            <label className="control-label">{label}</label>
                        </div>
                    )}
                    <div className="input-group">
                        <ReactSelect
                            {...field}
                            options={options}
                            clearable={false}
                            onChange={(value) => {
                                if (value) {
                                    if (onChange) {
                                        onChange(value.value);
                                    }
                                    form.setFieldValue(field.name, value.value);
                                }
                            }}
                            optionClassName="needsclick"
                            autosize={false}
                            placeholder={placeholder || EMPTY_STR}
                            disabled={disabled}
                            searchable={!isMobile}
                            inputProps={{ spellCheck: "false" }}
                            noResultsText={noOptionText}
                        />
                    </div>
                </div>
            )}
        </Field>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
