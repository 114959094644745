import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, TRIP_OR_PURCHASE } from "~/constants";
import {
    SelectorsAction as SelectorsActionCreditCards,
    SelectorsStore as SelectorsStoreCreditCards,
} from "~/store/creditCards/creditCard";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import DataDate from "~/components/DataDate";
import Ticket from "~/components/Ticket/Ticket";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const { dispatch, ticketConfirmation, transaction } = props;
    const { data } = transaction || {};
    const { alias, beginDate, countries, creditCardList, endDate, operationType, store } = data;

    const shareTicket = (idTransactionToRead) => {
        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    };

    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}
                    hasMobileComponent
                    displayDownloadPDF
                    handleDownloadPDF={() => shareTicket(transaction.idTransaction)}>
                    <Box directionColumn align="flex-start" className="mb-3 data-ticket">
                        <Box className="data-wrapper">
                            <span className="data-label">
                                {UtilsI18n.get("creditCards.tripOrOnlinePurchaseNotification.step3.operationType")}
                            </span>
                            <span className="data-name">
                                {UtilsI18n.get(
                                    `creditCards.tripOrOnlinePurchaseNotification.operationType.${operationType}`,
                                )}
                            </span>
                        </Box>
                        <Box className="data-wrapper">
                            <span className="data-label">
                                {UtilsI18n.get("creditCards.tripOrOnlinePurchaseNotification.step3.selectedAccount")}
                            </span>
                            <span className="data-name">{alias}</span>
                        </Box>
                        <Box className="data-wrapper">
                            <span className="data-label">
                                {UtilsI18n.get("creditCards.tripOrOnlinePurchaseNotification.step3.selectedCards")}
                            </span>
                            {creditCardList.map((creditCard) => (
                                <div className="card-container">
                                    <span className="data-name type-cc">
                                        {UtilsI18n.get(`client.creditcard.type.label.${creditCard.bin}`)}
                                    </span>
                                    <span className="number-cc">({creditCard.number})</span>
                                    <span className="data-name owner-cc">{creditCard.owner}</span>
                                </div>
                            ))}
                        </Box>

                        {operationType === TRIP_OR_PURCHASE.TRAVEL_NOTICE && (
                            <Box className="data-wrapper">
                                <span className="data-label">
                                    {UtilsI18n.get("creditCards.tripOrOnlinePurchaseNotification.step3.countries")}
                                </span>
                                <div className="container-countries">
                                    {countries.map((country) => (
                                        <span className="data-name">{country}</span>
                                    ))}
                                </div>
                            </Box>
                        )}

                        {operationType === TRIP_OR_PURCHASE.ONLINE_PURCHASE_NOTICE && (
                            <Box className="data-wrapper">
                                <span className="data-label">
                                    {UtilsI18n.get("creditCards.tripOrOnlinePurchaseNotification.step3.store")}
                                </span>
                                <span className="data-name">{store}</span>
                            </Box>
                        )}

                        <Box className="data-wrapper">
                            <span className="data-label">
                                {UtilsI18n.get("creditCards.tripOrOnlinePurchaseNotification.step3.beginDate")}
                            </span>
                            <DataDate value={beginDate} />
                        </Box>

                        <Box className="data-wrapper">
                            <span className="data-label">
                                {UtilsI18n.get("creditCards.tripOrOnlinePurchaseNotification.step3.endDate")}
                            </span>
                            <DataDate value={endDate} />
                        </Box>
                    </Box>
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { idTransaction } = transaction;
        dispatch(
            SelectorsActionCreditCards.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        dispatch(SelectorsActionCreditCards.signTransactionPreview({ data }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreCreditCards.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
