import React from "react";

import PropTypes from "prop-types";

import Namespace from "~/pages/_components/detailBox/DetailBox.scss";

import DetailBoxData from "./DetailBoxData";

export const { CLASS, NAME } = Namespace;

export const PROP = {
    types: {
        children: PropTypes.node.isRequired,
    },
    defaults: {
        children: null,
    },
};

export function Component(props) {
    const { children } = props;

    return <div className={CLASS}>{children}</div>;
}

Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

Component.Data = DetailBoxData;

export default Component;
