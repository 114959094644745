import React from "react";

import { push as Push, replace as Replace } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import {
    SelectorsStore as SelectorsStoreWMSwitch,
    SelectorsAction as SelectorsActionWMSwitch,
} from "~/store/wm/funds/switch";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import HighOrder from "~/components/HighOrder";

import FormEdit from "./Step1Edit/Step1Edit";
import FormPreview from "./Step2Preview/Step2Preview";
import FormView from "./Step3View/Step3View";

export const NAME = "SwitchFunds";

export const PROP = {
    types: {
        isFetching: PropTypes.bool,
        mode: PropTypes.string.isRequired,
        ...TypesRedux,
    },
    defaults: {
        isFetching: false,
        ...DefaultsRedux,
    },
};

export function Component(props) {
    const { mode, isFetching, idPaper, history, dispatch, isChangingEnvironment } = props;

    React.useEffect(() => {
        if (!isChangingEnvironment) {
            if (!idPaper || idPaper === 0) {
                dispatch(Replace("/investment"));
            } else {
                dispatch(SelectorsActionWMSwitch.resetForm());
                dispatch(SelectorsActionWMSwitch.preFormRequest({ idPaper, isReloadPaper: false }));
            }
        }
    }, [dispatch, history, idPaper, isChangingEnvironment]);

    function handleBack() {
        if (mode === MODE.EDIT) {
            dispatch(Push("/investment"));
        } else if (mode === MODE.PREVIEW) {
            dispatch(SelectorsActionWMSwitch.previewCancelRequest());
        } else if (mode === MODE.VIEW) {
            dispatch(SelectorsActionWMSwitch.restartFlow());
        }
    }

    return (
        <Container
            name={NAME}
            wait={isFetching}
            step={mode}
            head-onBack={handleBack}
            head-onBackWeb={handleBack}
            head-title={UtilsI18n.get("wm.funds.switch.title")}
            scopeToShowNotification={SCOPE.WEALTH_MANAGEMENT_SWITCH_FUNDS}>
            <React.Fragment>
                {mode === MODE.EDIT && <FormEdit />}
                {mode === MODE.PREVIEW && <FormPreview />}
                {mode === MODE.VIEW && <FormView ticketConfirmation />}
            </React.Fragment>
        </Container>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    isFetching: SelectorsStoreWMSwitch.isFetching(store),
    mode: SelectorsStoreWMSwitch.getMode(store),
    idPaper: SelectorsStoreWMSwitch.getIdPaperSelectedToSwitch(store),
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
});

export default HighOrder(Connect(mapStateToProps))(Component);
