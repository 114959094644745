import React from "react";

import { Modal } from "react-bootstrap";

import { getLang as GetLang } from "~/util/i18n";
import * as i18n from "~/util/i18n";
import { formatNumber } from "~/util/number";

import Box from "~/components/Box";
import DataNumber from "~/components/DataNumber";
import Image from "~/components/Image";

import Style from "./PopUp.rules.scss";

export const { NAME, NAME_MESSAGES, ID } = Style;
export const PROP = {
    types: {},
    defaults: {},
};

export class Component extends React.Component {
    renderFieldValue = (data, field, currency) => {
        let finalCurrency = currency;
        if (field.forceCurrency) {
            finalCurrency = field.forceCurrency;
        } else if (data.MONEDALINEAOTORGADA) {
            finalCurrency = data.MONEDALINEAOTORGADA;
        }
        if (field.type === "amount") {
            return <DataNumber value={data[field.id]} prefix={finalCurrency} className="mobile-only" />;
        }
        if (field.type === "number") {
            return <div>{formatNumber(data[field.id], GetLang())}</div>;
        }
        if (data[field.id]) {
            return <span>{data[field.id]}</span>;
        }
        return <span>N/A</span>;
    };

    render() {
        const { show, title, handleCloseModal, fields, data, currency } = this.props;
        return (
            <Modal show={show} id={ID}>
                <Modal.Body>
                    <div className="component-modal">
                        <div className="closed clickable" onClick={handleCloseModal} role="button">
                            <Image src="close.svg" />
                        </div>
                        <h2>{title}</h2>
                        <hr />
                        {fields &&
                            fields.map((field) => {
                                return (
                                    <Box flex>
                                        <label>{i18n.get("position.tables.headers.".concat(field.id))}: </label>
                                        {this.renderFieldValue(data, field, currency)}
                                    </Box>
                                );
                            })}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
export default Component;
