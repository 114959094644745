import axios from "axios";

import { CHANNEL, RESPONSE_TYPE } from "~/constants";
import Store from "~/store";
import DeviceUtils from "~/util/device";

axios.defaults.baseURL = window.API_URL;
axios.defaults.headers.common.Accept = "application/json, application/octet-stream";

axios.interceptors.response.use(
    (response) => {
        if (response.status === 204) {
            return response;
        }

        const { code } = response.data;

        if (!code || code.endsWith(RESPONSE_TYPE.ERROR)) {
            throw response;
        }

        // The error type is kept (I - Info, W - Warning, E - Error)
        response.type = response.data.code.slice(-1);

        return response;
    },
    (error) => {
        if (error.response) {
            const { status } = error.response;

            if (status === 304) {
                return error.response;
            }

            if (status === RESPONSE_TYPE.UNAUTHORIZED || status === 403) {
                throw error;
            }
        }

        // The attribute is added to know it's a saga's error
        const sagaError = error;
        sagaError.httpError = true;

        throw sagaError;
    },
);

export const channel = () => (DeviceUtils.isMobileNative() ? CHANNEL.PHONEGAP : CHANNEL.FRONTEND);

export const getAxios = () => axios;

export const downloadWithAccessToken = (idActivity, params) => {
    const { idFile } = params;

    return axios.get(`${idActivity}/${idFile}`, {
        responseType: "blob",
    });
};

export const executeAnonymous = (idActivity, params, etag = null) => {
    const { lang } = Store.getState().i18n;

    return axios.post(
        idActivity,
        {
            lang,
            ...params,
            channel: channel(),
        },
        {
            headers: {
                "If-None-Match": etag,
                Authorization: null,
            },
        },
    );
};

export const executeAnonymousWithWebToken = (idActivity, params, etag) => {
    const { lang } = Store.getState().i18n;
    const { isTrusted } = Store.getState().config;
    let paramsObject = params;
    let { _otp } = params;

    if (isTrusted) {
        const { webToken } = Store.getState().session;
        _otp = webToken;
        paramsObject = { ...params, _otp };
    }

    return axios.post(
        idActivity,
        {
            lang,
            ...paramsObject,
            channel: channel(),
        },
        {
            headers: {
                "If-None-Match": etag,
                Authorization: null,
            },
        },
    );
};

export const executeWithAccessToken = (idActivity, params, etag) => {
    return axios.post(
        idActivity,
        {
            ...params,
            channel: channel(),
        },
        {
            headers: {
                "If-None-Match": etag,
            },
        },
    );
};

export const executeWithExchangeToken = (idActivity, params, exchangeToken, etag) => {
    const { lang } = Store.getState().i18n;

    return axios.post(
        idActivity,
        {
            lang,
            channel: channel(),
            ...params,
        },
        {
            headers: {
                "If-None-Match": etag,
                Authorization: `exchange ${exchangeToken}`,
            },
        },
    );
};

export const executeWithWebToken = (idActivity, params, etag) => {
    const { isTrusted } = Store.getState().config;
    let paramsObject = params;
    let { _otp } = params;

    if (isTrusted) {
        const { webToken } = Store.getState().session;
        _otp = webToken;
        paramsObject = { ...params, _otp };
    }

    return axios.post(
        idActivity,
        {
            ...paramsObject,
            channel: channel(),
        },
        {
            headers: {
                "If-None-Match": etag,
            },
        },
    );
};

export const executeWithWebTokenWithExchange = (idActivity, params, exchangeToken, etag) => {
    const { isTrusted } = Store.getState().config;
    let paramsObject = params;
    let { _otp } = params;

    if (isTrusted) {
        const { webToken } = Store.getState().session;
        _otp = webToken;
        paramsObject = { ...params, _otp };
    }

    return axios.post(
        idActivity,
        { ...paramsObject, channel: channel() },
        {
            headers: {
                "If-None-Match": etag,
                Authorization: `exchange ${exchangeToken}`,
            },
        },
    );
};

export const setAuthToken = (token) => {
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
};
