import React from "react";

import { Field } from "formik";
import { connect as Connect } from "react-redux";

import { ZERO } from "~/constants";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import * as UtilsI18n from "~/util/i18n";
import { numberFormat as NumberFormatUtil } from "~/util/number";

import EditableDataNumber from "~/components/EditableDataNumber";
import HighOrder from "~/components/HighOrder";
import NumberFormatInput from "~/components/NumberFormatInput";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";

export const NAME = "Amount";

export const PROP = {
    types: {},
    defaults: {},
};
export function Component(props) {
    const { idForm, name, handleChange, handleBlur, errors, values, id, currency, textHelp, lang, tooltip } = props;
    const { decimalSeparator, thousandSeparator } = NumberFormatUtil(lang);

    let hasError = false;
    let valueField = 0;
    if (errors && values) {
        hasError = !!errors[name];
        valueField = values[name];
    }
    return (
        <div className='form-group'>
            <FieldLabel labelKey={`${idForm}.debitAccount.label`} />
            {textHelp && (
                <span className="text-help">
                    {UtilsI18n.get(`${idForm}.min.value`)}
                    {": "}
                    <NumberFormatInput
                        decimalSeparator={decimalSeparator}
                        thousandSeparator={thousandSeparator}
                        decimalScale={2}
                        value={parseFloat(textHelp, 10)}
                        displayType="text"
                        fixedDecimalScale
                        type="text"
                    />
                </span>
            )}
            <Field
                idForm={idForm}
                name={name}
                component={EditableDataNumber}
                id={id}
                onChange={handleChange}
                onBlur={handleBlur}
                value={valueField || ZERO}
                prefix={currency}
                className={hasError && "error-input"}
            />
            {tooltip && (
                <div className="tooltip">
                    <span className="tooltip-text"> {tooltip}</span>
                </div>
            )}
            {hasError && <FieldError error={errors ? errors[name] : null} />}
        </div>
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    lang: SelectorsStoreI18n.getLang(store),
});
export default HighOrder(Connect(mapStateToProps))(Component);
