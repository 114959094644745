import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    if (action.type.includes("PREVIEW") && action.type.includes("SUCCESS")) {
        return { ...storeProp, remove: true };
    }
    switch (action.type) {
        case TYPE.SHOW_NOTIFICATION:
            return {
                ...storeProp,
                message: action?.message,
                level: action.level,
                scopes: action.scopes,
                autoDismiss: action.autoDismiss,
                remove: false,
                children: action?.children,
            };
        case TYPE.REMOVE_NOTIFICATION:
            return PROP.defaults;

        default:
            return storeProp;
    }
};
