import PropTypes from "prop-types";

import { MODE } from "~/constants/form";
import { FactoryType } from "~/util/redux";

export const NAME = "settings";

export const PATH = {
    PREFERENCES_AUTHENTICATE: "/face.authenticate",
    PREFERENCES_CHANGE_EMAIL_ACCOUNTS_DATA: "/preferences.change.email.accounts.data",
    PREFERENCES_CHANGE_PASSWORD_SEND: "/preferences.changepassword.send",
    PREFERENCES_CHANGE_USER_DATA_ACCOUNTS: "/preferences.change.userData.accounts",
    PREFERENCES_CHANGE_USERDATA_ADDRESS: "/preferences.change.userData.address",
    PREFERENCES_CHANGE_USER_DATA_CMB: "/preferences.change.userDataCMB",
    PREFERENCES_CHANGE_USER_DATA_EMAIL: "/preferences.change.userData.email",
    PREFERENCES_CHANGE_USER_DATA_LIST_CITY: "/preferences.change.userData.list.city",
    PREFERENCES_CHANGE_USER_DATA_LIST_TOWN: "/preferences.change.userData.list.town",
    PREFERENCES_CHANGE_USERDATA_MODIFY_ACCOUNTS: "/preferences.change.userData.pre.modify.accounts",
    PREFERENCES_CHANGE_USER_DATA: "/preferences.change.userData.execute",
    PREFERENCES_CHANGE_USER_DATA_SECURITYSEALS_LIST: "/preferences.change.userData.securitySeals.list",
    PREFERENCES_FINGERPRINT_DELETE: "/preferences.fingerprint.delete",
    PREFERENCES_FINGERPRINT_PRE: "/preferences.fingerprint.pre",
    PREFERENCES_FINGERPRINT_SAVE: "/preferences.fingerprint.save",
    PREFERENCES_LANG_MODIFY: "/preferences.lang.modify",
    PREFERENCES_MODIFY_ACCOUNTS_DATA: "/preferences.modify.accounts.data",
    PREFERENCES_MODIFY_USER_CONFIGURATION_CMB: "/preferences.modifyuser.configuration.cmb",
    PREFERENCES_MODIFY_USER_CONFIGURATION: "/preferences.modifyuser.configuration.execute",
    PREFERENCES_NOTIFICATIONS_CONFIGURATION_MODIFY: "/preferences.notifications.configuration.modify.execute",
    PREFERENCES_NOTIFICATIONS_CONFIGURATION_MODIFY_PRE: "/preferences.notifications.configuration.modify.pre",
    PREFERENCES_NOTIFICATIONS_CONFIGURATION_PRE: "/preferences.notifications.configuration.pre",
    PREFERENCES_SECURITY_SEALS_LIST: "/preferences.securityseals.list",
    PREFERENCES_SECURITY_SEALS_MODIFY: "/preferences.securityseals.modify",
    PREFERENCES_UPDATE_EMAIL_ACCOUNTS_DATA: "/preferences.update.email.accounts.data",
    PREFERENCES_USER_DATA_MAIL_SEND_CODE: "/preferences.userData.mail.sendCode",
    PREFERENCES_USER_DATA_MAIL_UPDATE_CMB: "/preferences.userData.mail.update.cmb",
    PREFERENCES_USER_DATA_MAIL_UPDATE: "/preferences.userData.mail.update.execute",
    PREFERENCES_USER_DATA_MOBILE_PHONE_SEND_CODE: "/preferences.userData.mobilePhone.sendCode",
    PREFERENCES_USER_DATA_MOBILE_PHONE_SEND_CODE_RESEND: "/preferences.userData.mobilePhone.sendCode.resend",
    PREFERENCES_USER_DATA_MOBILE_PHONE_UPDATE: "/preferences.userData.mobilePhone.update",
    PREFERENCES_USER_DATA_MOBILE_PHONE_UPDATE_CMB: "/preferences.userData.mobilePhone.update.cmb",
    PREFERENCES_USER_DATA_MODIFY_PRE: "/preferences.userData.modify.pre",
};

export const TYPE = FactoryType(NAME, [
    "CANCEL_CUSTOM_TRANSACTION_REQUEST",
    "CHANGE_ADDRESSES_CITY_FAILURE",
    "CHANGE_ADDRESSES_CITY_LEGAL_SUCCESS",
    "CHANGE_ADDRESSES_CITY_MAILINGS_SUCCESS",
    "CHANGE_ADDRESSES_CITY_REQUEST",
    "CHANGE_ADDRESSES_CITY_SUCCESS",
    "CHANGE_ADDRESSES_DATA_FORM_REQUEST",
    "CHANGE_ADDRESSES_DEPARTAMENT_AND_CITY_REQUEST",
    "CHANGE_ADDRESSES_DEPARTAMENT_FAILURE",
    "CHANGE_ADDRESSES_DEPARTAMENT_LEGAL_SUCCESS",
    "CHANGE_ADDRESSES_DEPARTAMENT_MAILINGS_SUCCESS",
    "CHANGE_ADDRESSES_DEPARTAMENT_REQUEST",
    "CHANGE_ADDRESSES_DEPARTAMENT_SUCCESS",
    "CHANGE_ADDRESSES_FAILURE",
    "CHANGE_ADDRESSES_REQUEST",
    "CHANGE_ADDRESSES_SUBMIT",
    "CHANGE_ADDRESSES_SUCCESS",
    "CHANGE_EMAIL_VERIFY",
    "CHANGE_FORM_LEGAL",
    "CHANGE_LANGUAGE",
    "CHANGE_LANGUAGE_PRE",
    "CHANGE_LANGUAGE_PRE_ERROR",
    "CHANGE_LANGUAGE_PRE_SUCCESS",
    "CHANGE_PASSWORD_FAILURE",
    "CHANGE_PASSWORD_REQUEST",
    "CHANGE_PASSWORD_SUCCESS",
    "CHANGE_PERSONAL_DATA",
    "CHANGE_PERSONAL_DATA_CMB_SUCCESS",
    "CHANGE_PERSONAL_DATA_SECURITYSEALS_FAILURE",
    "CHANGE_PERSONAL_DATA_SECURITYSEALS_REQUEST",
    "CHANGE_PERSONAL_DATA_SECURITYSEALS_SUCCESS",
    "CHANGE_PERSONAL_DATA_SUCCESS",
    "CHANGE_SECURITY_SEAL_CONFIRMATION",
    "CHANGE_SECURITY_SEAL_CONFIRMATION_BACK",
    "CHANGE_SECURITY_SEAL_CONFIRMATION_ERROR",
    "CHANGE_SECURITY_SEAL_CONFIRMATION_PRE",
    "CHANGE_SECURITY_SEAL_CONFIRMATION_SUCCESS",
    "CHANGE_SECURITY_SEAL_PRE",
    "CHANGE_SECURITY_SEAL_PRE_SUCCESS",
    "CHANGE_SECURITYSEALS",
    "CLEAN_ERRORS",
    "COMMUNICATION_TYPE_PRE",
    "COMMUNICATION_TYPE_PRE_SUCCESS",
    "GET_USER_DATA",
    "GET_USER_DATA_FAILURE",
    "GET_USER_DATA_SUCCESS",
    "HIDE_MODAL_SECURITYSEALS",
    "MAIL_SEND_CODE",
    "MAIL_SEND_CODE_CANCEL",
    "MAIL_SEND_CODE_FAILURE",
    "MAIL_SEND_CODE_SUCCESS",
    "MAIL_UPDATE",
    "MAIL_UPDATE_CMB_SUCCESS",
    "MAIL_UPDATE_FAILURE",
    "MAIL_UPDATE_SUCCESS",
    "MOBILEPHONE_SEND_CODE",
    "MOBILEPHONE_SEND_CODE_CANCEL",
    "MOBILEPHONE_SEND_CODE_FAILURE",
    "MOBILEPHONE_SEND_CODE_SUCCESS",
    "MOBILEPHONE_UPDATE",
    "MOBILEPHONE_UPDATE_FAILURE",
    "MOBILEPHONE_UPDATE_SUCCESS",
    "MODIFY_NOTIFICATION_CONFIGURATIONS",
    "NO_CHANGE_FORM_LEGAL",
    "NOTIFICATIONS_CONFIGURATION_PRE",
    "NOTIFICATIONS_CONFIGURATION_PRE_SUCCESS",
    "PREFERENCES_CHANGE_USER_DATA_ACCOUNTS_CLEAN",
    "PREFERENCES_CHANGE_USER_DATA_ACCOUNTS_FAILURE",
    "PREFERENCES_CHANGE_USER_DATA_ACCOUNTS_REQUEST",
    "PREFERENCES_CHANGE_USER_DATA_ACCOUNTS_SUCCESS",
    "PREFERENCES_FORM_MODIFY_DATA_ACCOUNTS_FAILURE",
    "PREFERENCES_FORM_MODIFY_DATA_ACCOUNTS_REQUEST",
    "PREFERENCES_FORM_MODIFY_DATA_ACCOUNTS_SUCCESS",
    "PREFERENCES_LIST_EMAIL_DATA_ACCOUNTS_REQUEST",
    "PREFERENCES_LIST_EMAIL_DATA_ACCOUNTS_SUCCESS",
    "PREFERENCES_MODIFY_USER_CONFIGURATION_CMB_SUCCESS",
    "PREFERENCES_MODIFY_USER_CONFIGURATION_FAILURE",
    "PREFERENCES_MODIFY_USER_CONFIGURATION_REQUEST",
    "PREFERENCES_MODIFY_USER_CONFIGURATION_SUCCESS",
    "PREFERENCES_READ_TICKET_USER_CONFIGURATION_REQUEST",
    "PREFERENCES_READ_TICKET_USER_CONFIGURATION_SUCCESS",
    "PREFERENCES_UPDATE_EMAIL_ACCOUNTS_REQUEST",
    "PREFERENCES_USER_DATA_ACCOUNTS_CLEAN",
    "READ_TRANSACTION_REQUEST",
    "READ_TRANSACTION_SUCCESS",
    "SHOW_MODAL_SECURITYSEALS",
    "SIGN_TRANSACTION_PREVIEW_REQUEST",
    "SIGN_TRANSACTION_PREVIEW_SUCCESS",
    "SIGN_TRANSACTION_REQUEST",
]);

export const PROP = {};

PROP.types = {
    adresses: PropTypes.object,
    changeProcess: PropTypes.bool,
    changeTransactionData: PropTypes.object,
    cityList: PropTypes.array,
    communicationTransportsConfigurations: PropTypes.any,
    communicationTypes: PropTypes.any,
    correspondense: PropTypes.object,
    countryList: PropTypes.array,
    documents: PropTypes.array,
    errors: PropTypes.any,
    fetching: PropTypes.bool,
    fetchingAccountData: PropTypes.bool,
    fetchingForm: PropTypes.bool,
    infoUser: PropTypes.object,
    isChangeFormLegal: PropTypes.bool,
    legal: PropTypes.object,
    listEmail: PropTypes.object,
    loadData: PropTypes.array,
    maritalStatusList: PropTypes.array,
    mode: PropTypes.string,
    newEmail: PropTypes.string,
    newMobilePhone: PropTypes.string,
    personalData: PropTypes.object,
    prevMode: PropTypes.string,
    professions: PropTypes.array,
    securitySealList: PropTypes.array,
    securitySeal: PropTypes.object,
    securitySeals: PropTypes.array,
    selectedSecuritySealId: PropTypes.string,
    showModalSecuritySeal: PropTypes.bool,
    supportedLanguages: PropTypes.array,
    townList: PropTypes.array,
    userData: PropTypes.any,
};

PROP.defaults = {
    adresses: {},
    changeProcess: false,
    changeTransactionData: {},
    cityList: [],
    cityListLegal: [],
    cityListMailings: [],
    communicationTransportsConfigurations: null,
    communicationTypes: null,
    correspondense: {},
    countryList: [],
    documnets: [],
    errors: null,
    fetching: false,
    fetchingAccountData: false,
    fetchingForm: false,
    infoUser: {},
    isChangeFormLegal: false,
    legal: {},
    listEmail: [],
    loadData: [],
    maritalStatusList: [],
    mode: MODE.EDIT,
    newEmail: null,
    newMobilePhone: null,
    personalData: {},
    prevMode: null,
    professions: [],
    securitySeal: {},
    securitySealList: [],
    securitySeals: {},
    selectedSecuritySealId: null,
    showModalSecuritySeal: false,
    supportedLanguages: null,
    townList: [],
    townListLegal: [],
    townListMailings: [],
    userData: null,
};
