import React from "react";

import { Field } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { DEFAULT_COUNTRY, DOT, EMPTY_STR, ONLY_NUMBER, SPACE_STR, ZERO, ZERO_NUMBER } from "~/constants";
import { MODE } from "~/constants/form";
import { SelectorsAction, SelectorsStore } from "~/store/connectedParties";
import * as UtilI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";
import CountryCellPhoneField from "~/pages/_components/fields/CountryCellPhoneField";
import DateField from "~/pages/_components/fields/DateField";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import NumberInput from "~/pages/_components/fields/NumberInput";
import TextField from "~/pages/_components/fields/TextField";

import DocumentField from "../settings/changePersonalInformation/field/DocumentField";
import StyleField from "../settings/changePersonalInformation/field/MobilePhoneField.rules.scss";
import Style from "./ConnectedPartiesForm.rules.scss";
import FileUploader from "./field/FileUploader";
import RadioButton from "./field/RadioButton";
import Select from "./field/Selector";
import SelectorOrInput from "./field/SelectorOrInput";

const FORM_ID = "connectedParties";

export const NAME = "FormPersonalData";

export const PROP = {
    types: {
        ...TypesRedux,
        countryList: PropTypes.array,
        dispatch: PropTypes.func.isRequired,
        documents: PropTypes.array,
        isCorporate: PropTypes.bool.isRequired,
        maritalStatusList: PropTypes.array,
        professions: PropTypes.array,
        values: PropTypes.object.isRequired,
    },
    defaults: {
        ...DefaultsRedux,
        countryList: [],
        documents: [],
        isCorporate: true,
        maritalStatusList: [],
        professions: [],
    },
};

export function Component({
    cityList,
    cmbOnly,
    countryList,
    departmentsOfBirthList,
    dispatch,
    documents,
    infoUser,
    isCorporate,
    maritalStatusList,
    productGroupIds,
    professions,
    setFieldValue,
    townList,
    values,
    workActivities,
    workRoles,
    workSituations,
    ...rest
}) {
    const renderCity = values?.departament?.toString() !== ZERO;

    const [idContryTemp, setIdContryTemp] = React.useState(DEFAULT_COUNTRY);
    const [haveCitizenship2, setHaveCitizenship2] = React.useState(false);
    const [haveCitizenship3, setHaveCitizenship3] = React.useState(false);
    const [haveFiscalResidence2, setHaveFiscalResidence2] = React.useState(false);
    const [haveFiscalResidence3, setHaveFiscalResidence3] = React.useState(false);
    const [showAnnualIncome, setShowAnnualIncome] = React.useState(false);

    const handleChangeCountryOfBirth = (id) => {
        dispatch(SelectorsAction.listDepartmentsOfBirth({ idCountry: id }));
    };

    const handleChangeCountry = (id) => {
        setIdContryTemp(id);

        dispatch(SelectorsAction.listDepartments({ idCountry: id }));
    };

    const handleChangeDepartament = (id) => {
        if (id !== 0) {
            dispatch(SelectorsAction.changeAddressesCityRequest({ idCountry: idContryTemp, idCity: id }));
        }
    };

    const addOrRemoveCitizenship = (mode) => {
        if (mode === "add") {
            if (!haveCitizenship2) {
                setHaveCitizenship2(true);

                setFieldValue("countryOfCitizenship2", EMPTY_STR);
            } else {
                setHaveCitizenship3(true);

                setFieldValue("countryOfCitizenship3", EMPTY_STR);
            }
        } else if (haveCitizenship3) {
            setHaveCitizenship3(false);

            setFieldValue("countryOfCitizenship3", ZERO);
        } else {
            setHaveCitizenship2(false);

            setFieldValue("countryOfCitizenship2", ZERO);
        }
    };

    const addOrRemoveFiscalResidence = (mode) => {
        if (mode === "add") {
            if (!haveFiscalResidence2) {
                setHaveFiscalResidence2(true);

                setFieldValue("countryOfFiscalResidence2", EMPTY_STR);
                setFieldValue("fiscalId2", EMPTY_STR);
            } else {
                setHaveFiscalResidence3(true);

                setFieldValue("countryOfFiscalResidence3", EMPTY_STR);
                setFieldValue("fiscalId3", EMPTY_STR);
            }
        } else if (haveFiscalResidence3) {
            setHaveFiscalResidence3(false);

            setFieldValue("countryOfFiscalResidence3", ZERO);
            setFieldValue("fiscalId3", ZERO);
        } else {
            setHaveFiscalResidence2(false);

            setFieldValue("countryOfFiscalResidence2", ZERO);
            setFieldValue("fiscalId2", ZERO);
        }
    };

    const addProvideFoundTo = (isProvideFound, productGroupId) => {
        const newObject = { ...values.provideFoundTo, [productGroupId]: isProvideFound };

        setFieldValue("provideFoundTo", newObject);

        if (isProvideFound) {
            setShowAnnualIncome(true);

            setFieldValue("providesAnyFounds", true);
        } else if (!Object.entries(newObject).find((element) => element[1])) {
            setShowAnnualIncome(false);

            setFieldValue("annualIncome", ZERO);
            setFieldValue("providesAnyFounds", false);
        }
    };

    const yesNo = [
        {
            id: true,
            name: UtilI18n.get("global.yes"),
        },
        {
            id: false,
            name: UtilI18n.get("global.no"),
        },
    ];

    const dateFromBirth = new Date();
    dateFromBirth.setFullYear(dateFromBirth.getFullYear() - 100);

    return (
        <React.Fragment>
            <div className=" form--personal_information form--personal_information--seal">
                <div className="form--personal_information--title">
                    <I18n id="title.form.information" />
                </div>
                <div id={Style.ID} className="container--data">
                    {!isCorporate && (
                        <React.Fragment>
                            {/* First name */}
                            <div className="field-spacing field-phone">
                                <Field
                                    autoFocus={false}
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    mode={MODE.EDIT}
                                    name="firstName"
                                />
                            </div>

                            {/* Second name */}
                            <div className="field-spacing field-phone">
                                <Field
                                    autoFocus={false}
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    maxLength={25}
                                    mode={MODE.EDIT}
                                    name="middleName"
                                />
                            </div>

                            {/* Last Name */}
                            <div className="field-spacing field-phone">
                                <Field
                                    autoFocus={false}
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    mode={MODE.EDIT}
                                    name="lastName"
                                />
                            </div>

                            {/* Second last name */}
                            <div className="field-spacing field-phone">
                                <Field
                                    autoFocus={false}
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    maxLength={30}
                                    mode={MODE.EDIT}
                                    name="secondLastName"
                                />
                            </div>

                            {/* Gender */}
                            <div className="field-spacing field-phone">
                                <FieldLabel labelKey={`${FORM_ID}.gender.label`} />
                                <RadioButton
                                    autoFocus={false}
                                    hidePlaceholder
                                    keyprefix="gender.name.M"
                                    name="gender"
                                    value="M"
                                />
                                <RadioButton
                                    autoFocus={false}
                                    hidePlaceholder
                                    keyprefix="gender.name.F"
                                    name="gender"
                                    value="F"
                                />
                                {rest.errors.gender && <FieldError error={rest.errors.gender} />}
                            </div>

                            {/* Birthdate */}
                            <div className="field-spacing field-phone">
                                <Field
                                    component={DateField}
                                    dropdownMode="select"
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    maxDate={new Date()}
                                    minDate={dateFromBirth}
                                    name="birthdate"
                                    showYearDropdown
                                />
                            </div>

                            {/* Document */}
                            <div className="field-spacing field-phone">
                                <Field
                                    autoFocus={false}
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    mode={MODE.VIEW}
                                    name="document"
                                    pattern={ONLY_NUMBER}
                                    value={`${infoUser.documentType} (${infoUser.documentCountry}) N° ${infoUser.document}`}
                                />
                            </div>

                            {/* FileUpload:  Document images */}
                            <Field name="files" idForm={FORM_ID} component={FileUploader} value={EMPTY_STR} />

                            {/* Birth country */}
                            <div className=" field-spacing">
                                <Select
                                    idForm={FORM_ID}
                                    label={UtilI18n.get("connectedParties.countryofbirth")}
                                    labelKey="name"
                                    name="countryofbirth"
                                    nameType="idCountry"
                                    onChange={handleChangeCountryOfBirth}
                                    options={countryList}
                                    setFieldValue={setFieldValue}
                                    valueKey="id"
                                    {...rest}
                                />
                            </div>

                            {/* Birth place */}
                            <div className=" field-spacing">
                                <SelectorOrInput
                                    defaultsValue={EMPTY_STR}
                                    dependencyField={{
                                        country: values?.countryofbirth,
                                        fieldMe: values?.departamentOfBirth,
                                    }}
                                    idForm={FORM_ID}
                                    label={UtilI18n.get("connectedParties.departamentOfBirth")}
                                    labelKey="name"
                                    lengthOther={20}
                                    name="departamentOfBirth"
                                    nameOther="otherDepartamentOfBirth"
                                    nameType="idDepartament"
                                    options={departmentsOfBirthList}
                                    setFieldValue={setFieldValue}
                                    valueKey="id"
                                    {...rest}
                                />
                            </div>

                            {/* Citizenship */}
                            <div className="field-spacing">
                                <Select
                                    label={UtilI18n.get("connectedParties.countryOfCitizenship")}
                                    labelKey="name"
                                    name="countryOfCitizenship"
                                    options={countryList} // countryList
                                    setFieldValue={setFieldValue}
                                    valueKey="id"
                                    {...rest}
                                />
                            </div>

                            {/* Citizenship 2 */}
                            {haveCitizenship2 && (
                                <div className="field-spacing">
                                    <Select
                                        label={UtilI18n.get("connectedParties.countryOfCitizenship2")}
                                        labelKey="name"
                                        name="countryOfCitizenship2"
                                        options={countryList} // countryList
                                        setFieldValue={setFieldValue}
                                        valueKey="id"
                                        {...rest}
                                    />
                                </div>
                            )}

                            {/* Citizenship 3 */}
                            {haveCitizenship3 && (
                                <div className="field-spacing">
                                    <Select
                                        label={UtilI18n.get("connectedParties.countryOfCitizenship3")}
                                        labelKey="name"
                                        name="countryOfCitizenship3"
                                        options={countryList} // countryList
                                        setFieldValue={setFieldValue}
                                        valueKey="id"
                                        {...rest}
                                    />
                                </div>
                            )}

                            {/* Add Citizenship */}
                            {!haveCitizenship3 && (
                                <Button
                                    className="form--personal_information--bottom--cancel button-connectedparties-add-delete"
                                    onClick={() => addOrRemoveCitizenship("add")}
                                    size="md"
                                    type="button"
                                    variant="secondary">
                                    +
                                </Button>
                            )}

                            {/* Remove Citizenship */}
                            {(haveCitizenship2 || haveCitizenship3) && (
                                <Button
                                    className="form--personal_information--bottom--cancel button-connectedparties-add-delete"
                                    onClick={() => addOrRemoveCitizenship("remove")}
                                    size="md"
                                    type="button"
                                    variant="secondary">
                                    -
                                </Button>
                            )}

                            {/* American citizen */}
                            <div className="field-spacing">
                                <Select
                                    label={UtilI18n.get("connectedParties.americanCitizen")}
                                    labelKey="name"
                                    name="americanCitizen"
                                    options={yesNo} // yes, no
                                    setFieldValue={setFieldValue}
                                    valueKey="id"
                                    {...rest}
                                />
                            </div>

                            {/* Tax residence */}
                            <div className="field-spacing">
                                <Select
                                    label={UtilI18n.get("connectedParties.countryOfFiscalResidence")}
                                    labelKey="name"
                                    name="countryOfFiscalResidence"
                                    onChange={() => {
                                        setFieldValue("fiscalId", EMPTY_STR);
                                    }}
                                    options={countryList} // countryList
                                    setFieldValue={setFieldValue}
                                    valueKey="id"
                                    {...rest}
                                />
                            </div>

                            {/* Tax ID */}
                            <div className="field-spacing field-phone">
                                <Field
                                    autoFocus={false}
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    mode={MODE.EDIT}
                                    name="fiscalId"
                                />
                            </div>

                            {/* Tax residence 2 */}
                            {haveFiscalResidence2 && (
                                <div className="field-spacing">
                                    <Select
                                        label={UtilI18n.get("connectedParties.countryOfFiscalResidence2")}
                                        labelKey="name"
                                        name="countryOfFiscalResidence2"
                                        options={countryList}
                                        setFieldValue={setFieldValue}
                                        valueKey="id"
                                        {...rest}
                                    />
                                </div>
                            )}

                            {/* Tax ID 2 */}
                            {haveFiscalResidence2 && (
                                <div className="field-spacing field-phone">
                                    <Field
                                        autoFocus={false}
                                        component={TextField}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        mode={MODE.EDIT}
                                        name="fiscalId2"
                                    />
                                </div>
                            )}

                            {/* Tax residence 3 */}
                            {haveFiscalResidence3 && (
                                <div className="field-spacing">
                                    <Select
                                        label={UtilI18n.get("connectedParties.countryOfFiscalResidence3")}
                                        labelKey="name"
                                        name="countryOfFiscalResidence3"
                                        options={countryList}
                                        setFieldValue={setFieldValue}
                                        valueKey="id"
                                        {...rest}
                                    />
                                </div>
                            )}

                            {/* Tax ID 3 */}
                            {haveFiscalResidence3 && (
                                <div className="field-spacing field-phone">
                                    <Field
                                        autoFocus={false}
                                        component={TextField}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        mode={MODE.EDIT}
                                        name="fiscalId3"
                                    />
                                </div>
                            )}

                            {/* Add Tax residence */}
                            {!haveFiscalResidence3 && (
                                <Button
                                    className="form--personal_information--bottom--cancel button-connectedparties-add-delete"
                                    onClick={() => addOrRemoveFiscalResidence("add")}
                                    size="md"
                                    type="button"
                                    variant="secondary">
                                    +
                                </Button>
                            )}

                            {/* Remove Tax residence */}
                            {(haveFiscalResidence2 || haveFiscalResidence3) && (
                                <Button
                                    className="form--personal_information--bottom--cancel button-connectedparties-add-delete"
                                    onClick={() => addOrRemoveFiscalResidence("remove")}
                                    size="md"
                                    type="button"
                                    variant="secondary">
                                    -
                                </Button>
                            )}

                            {/* Phone */}
                            <div className="field-spacing field-phone">
                                <Field
                                    autoFocus={false}
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    maxLength={20}
                                    mode={MODE.EDIT}
                                    name="phone"
                                    pattern={ONLY_NUMBER}
                                    tooltip={UtilI18n.get("connectedParties.tooltip.phone")}
                                />
                            </div>

                            {/* Mobile phone (readonly if there is a preloaded value) */}
                            {typeof values.mobilePhone === "string" && (
                                <div className="field-spacing field-phone">
                                    <Field
                                        autoFocus={false}
                                        component={TextField}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        mode={MODE.VIEW}
                                        name="mobilePhone"
                                        value={values.mobilePhone}
                                    />
                                </div>
                            )}

                            {/* Mobile phone (mandatory if there is no preloaded value) */}
                            {typeof values.mobilePhone === "object" && (
                                <React.Fragment>
                                    <div className="field-mobilephone field-spacing" id={StyleField.ID}>
                                        <FieldLabel labelKey="connectedParties.mobilePhone.label" />
                                        <Field
                                            idForm={FORM_ID}
                                            name="mobilePhone"
                                            type="text"
                                            className="login-form-input"
                                            component={CountryCellPhoneField}
                                            hideLabel
                                            pattern={ONLY_NUMBER}
                                            hidePlaceholder={false}
                                            autoFocus={false}
                                            valueKey="value"
                                            labelKey="label"
                                        />
                                        <div className="tooltip tooltip-mobilePhone">
                                            <span className="tooltip-text">
                                                {UtilI18n.get("connectedParties.tooltip.mobilePhone")}
                                            </span>
                                        </div>
                                    </div>

                                    {/* Verification mobile phone */}
                                    <div className="field-mobilephone field-spacing" id={StyleField.ID}>
                                        <FieldLabel labelKey="connectedParties.verificationMobilePhone.label" />
                                        <Field
                                            idForm={FORM_ID}
                                            name="verificationMobilePhone"
                                            type="text"
                                            className="login-form-input"
                                            component={CountryCellPhoneField}
                                            hideLabel
                                            pattern={ONLY_NUMBER}
                                            hidePlaceholder={false}
                                            autoFocus={false}
                                            valueKey="value"
                                            labelKey="label"
                                        />
                                        <div className="tooltip tooltip-mobilePhone">
                                            <span className="tooltip-text">
                                                {UtilI18n.get("connectedParties.tooltip.mobilePhone")}
                                            </span>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}

                            {/* Email */}
                            <div className="field-spacing field-phone">
                                <Field
                                    autoFocus={false}
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    mode={MODE.VIEW}
                                    name="email"
                                    value={values.email}
                                />
                            </div>

                            {/* Marital status */}
                            <div className="field-spacing">
                                <Select
                                    label={UtilI18n.get("connectedParties.maritalStatus")}
                                    labelKey="name"
                                    name="maritalStatus"
                                    options={maritalStatusList}
                                    setFieldValue={setFieldValue}
                                    valueKey="id"
                                    {...rest}
                                />
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
            {/* Spouse */}
            {(values.maritalStatus === "1" || values.maritalStatus === "7" || values.maritalStatus === "8") && (
                <div className=" form--personal_information form--personal_information--data personal_info-cmb">
                    <div className="form--personal_information--title">
                        <I18n id="title.form.partnerdata" />
                    </div>
                    <div className="container--data">
                        {!isCorporate && (
                            <React.Fragment>
                                {/* Spouse names */}
                                <div className="field-spacing field-phone">
                                    <Field
                                        autoFocus={false}
                                        component={TextField}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        mode={MODE.EDIT}
                                        name="spouseName"
                                    />
                                </div>

                                {/* Spouse last names  */}
                                <div className="field-spacing field-phone">
                                    <Field
                                        autoFocus={false}
                                        component={TextField}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        mode={MODE.EDIT}
                                        name="spouseLastname"
                                    />
                                </div>

                                {/* Spouse document  */}
                                <div className="field-spacing">
                                    <Field
                                        autoFocus={false}
                                        component={DocumentField}
                                        data={{ countries: countryList, documentTypes: documents }}
                                        hideDocumentPlaceholder
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        mode={MODE.EDIT}
                                        name="spouseDocument"
                                        {...rest}
                                    />
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            )}

            <div className="form--personal_information form--personal_information--addresses">
                <div className="form--personal_information--title">
                    <I18n id="title.form.connectedParties.addresses" />
                </div>
                <div id={Style.ID} className="container--data">
                    <React.Fragment>
                        <div className=" field-spacing">
                            <Select
                                idForm={FORM_ID}
                                label="Pais"
                                labelKey="name"
                                name="country"
                                nameType="idCountry"
                                onChange={handleChangeCountry}
                                options={countryList}
                                setFieldValue={setFieldValue}
                                valueKey="id"
                                {...rest}
                            />
                        </div>

                        <div className=" field-spacing">
                            <SelectorOrInput
                                defaultsValue={EMPTY_STR}
                                dependencyField={{ country: values?.country, fieldMe: values?.departament }}
                                idForm={FORM_ID}
                                label="departament"
                                labelKey="name"
                                lengthOther={20}
                                name="departament"
                                nameOther="otherDepartament"
                                nameType="idDepartament"
                                onChange={handleChangeDepartament}
                                options={cityList}
                                setFieldValue={setFieldValue}
                                valueKey="id"
                                {...rest}
                            />
                        </div>

                        <div className=" field-spacing">
                            <SelectorOrInput
                                dependencyField={{ country: values?.country, fieldMe: values?.city }}
                                idForm={FORM_ID}
                                label="city"
                                labelKey="name"
                                lengthOther={30}
                                name="city"
                                nameOther="otherCity"
                                nameType="idCity"
                                options={townList}
                                renderSelector={renderCity}
                                setFieldValue={setFieldValue}
                                valueKey="id"
                                {...rest}
                            />
                        </div>

                        {/* Street */}
                        <div className="field-spacing field-phone">
                            <Field
                                autoFocus={false}
                                component={TextField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                mode={MODE.EDIT}
                                name="street"
                            />
                        </div>

                        {/* Door number */}
                        <div className="field-spacing field-phone">
                            <Field
                                autoFocus={false}
                                component={TextField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                mode={MODE.EDIT}
                                name="doorNumber"
                            />
                        </div>

                        {/* Apartment */}
                        <div className="field-spacing field-phone">
                            <Field
                                autoFocus={false}
                                component={TextField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                mode={MODE.EDIT}
                                name="apto"
                                optional={UtilI18n.get("form.field.optional")}
                            />
                        </div>

                        {/* Zip code */}
                        <div className="field-spacing field-phone">
                            <Field
                                autoFocus={false}
                                component={TextField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                maxLength={8}
                                mode={MODE.EDIT}
                                name="zipCode"
                                optional={UtilI18n.get("form.field.optional")}
                            />
                        </div>
                    </React.Fragment>
                </div>
            </div>

            {/* Accounts */}
            <div className=" form--personal_information form--personal_information--data personal_info-cmb">
                <div className="form--personal_information--title">
                    <I18n id="title.form.connectedPartiesAccounts" />
                </div>
                <div id={Style.ID} className="container--data">
                    <p>{UtilI18n.get("connectedPartiesange.provideFoundTo.title")}</p>
                    {productGroupIds.map((productGroupId, index) => {
                        const name = "provideFoundTo_".concat(index);
                        return (
                            <div className="field-spacing">
                                <Select
                                    label={UtilI18n.get("connectedParties.provideFoundTo")
                                        .concat(SPACE_STR)
                                        .concat(productGroupId)}
                                    labelKey="name"
                                    name={name}
                                    onChange={(isProvideFound) => {
                                        addProvideFoundTo(isProvideFound, productGroupId);
                                    }}
                                    options={yesNo} // yes, no
                                    setFieldValue={setFieldValue}
                                    valueKey="id"
                                    {...rest}
                                />
                            </div>
                        );
                    })}

                    {showAnnualIncome && (
                        <div className="field-spacing monthlyIncome">
                            <Field
                                allowNegative={false}
                                autoFocus={false}
                                component={NumberInput}
                                decimalScale={2}
                                decimalSeparator={DOT}
                                defaultValue={0}
                                fixedDecimalScale
                                hidePlaceholder
                                idForm={FORM_ID}
                                inputmode="numeric"
                                mode={MODE.EDIT}
                                name="annualIncome"
                                thousandSeparator={EMPTY_STR}
                                thousandsGroupStyle="thousand"
                            />
                        </div>
                    )}
                </div>
            </div>

            {/* Job data */}
            <div className=" form--personal_information form--personal_information--seal">
                <div className="form--personal_information--title">
                    <I18n id="title.form.workdata" />
                </div>
                <div id={Style.ID} className="container--data">
                    <React.Fragment>
                        <div className="field-spacing">
                            <Select
                                label={UtilI18n.get("connectedParties.profession.label")}
                                labelKey="label"
                                name="profession"
                                options={professions}
                                setFieldValue={setFieldValue}
                                valueKey="id"
                                {...rest}
                            />
                        </div>

                        {values?.profession === ZERO_NUMBER && (
                            <div className="field-spacing">
                                <Field
                                    autoFocus={false}
                                    component={TextField}
                                    hidelabel
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    maxLength={30}
                                    mode={MODE.EDIT}
                                    name="otherProfession"
                                />
                            </div>
                        )}
                        {!cmbOnly && (
                            <React.Fragment>
                                <div className="field-spacing">
                                    <Select
                                        label={UtilI18n.get("connectedParties.employmentSituation.label")}
                                        labelKey="name"
                                        name="employmentSituation"
                                        options={workSituations}
                                        setFieldValue={setFieldValue}
                                        valueKey="id"
                                        {...rest}
                                    />
                                </div>
                                <div className="field-spacing field-phone">
                                    <Field
                                        autoFocus={false}
                                        component={TextField}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        mode={MODE.EDIT}
                                        name="antiquity"
                                        optional={UtilI18n.get("form.field.optional")}
                                        pattern={ONLY_NUMBER}
                                    />
                                </div>
                                <div className="field-spacing">
                                    <Select
                                        label={UtilI18n.get("connectedParties.workRoles.label")}
                                        labelKey="name"
                                        name="workRole"
                                        options={workRoles}
                                        setFieldValue={setFieldValue}
                                        valueKey="id"
                                        {...rest}
                                    />
                                </div>
                                <div className="field-spacing field-phone">
                                    <Field
                                        autoFocus={false}
                                        component={TextField}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        mode={MODE.EDIT}
                                        name="companyName"
                                    />
                                </div>
                                <div className="field-spacing">
                                    <Select
                                        label={UtilI18n.get("connectedParties.workActivities.label")}
                                        labelKey="description"
                                        name="activityCode"
                                        options={workActivities}
                                        setFieldValue={setFieldValue}
                                        valueKey="id"
                                        {...rest}
                                    />
                                </div>
                                <div className="field-spacing field-phone">
                                    <Field
                                        autoFocus={false}
                                        component={TextField}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        maxLength={20}
                                        mode={MODE.EDIT}
                                        name="companyPhone"
                                        pattern={ONLY_NUMBER}
                                    />
                                </div>
                                <div className="field-spacing field-phone">
                                    <Field
                                        autoFocus={false}
                                        component={TextField}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        mode={MODE.EDIT}
                                        name="companyAddress"
                                    />
                                </div>
                                <div className="field-spacing field-phone">
                                    <Field
                                        autoFocus={false}
                                        component={TextField}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        mode={MODE.EDIT}
                                        name="employerName"
                                    />
                                </div>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                </div>
            </div>
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    cmbOnly: SelectorsStore.getCMBOnly(store),
    countryList: SelectorsStore.getCountryList(store),
    documents: SelectorsStore.getDocuments(store),
    loadData: SelectorsStore.getLoadData(store),
    maritalStatusList: SelectorsStore.getMaritalStatusList(store),
    productGroupIds: SelectorsStore.getProductGroupIds(store),
    professions: SelectorsStore.getProfessions(store),
    workActivities: SelectorsStore.getWorkActivities(store),
    workRoles: SelectorsStore.getWorkRoles(store),
    workSituations: SelectorsStore.getWorkSituations(store),
});

export default Connect(mapStateToProps)(Component);
