import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SPACE_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreTicket } from "~/store/administration/common/administrationTicket";
import * as UtilsI18n from "~/util/i18n";
import { numberFormat as NumberFormatUtil } from "~/util/number";

import I18n from "~/components/I18n";
import NumberFormatInput from "~/components/NumberFormatInput";

import DataDetalis from "~/pages/administration/_components/DataDetalis";
import Style from "~/pages/administration/_components/tickets/Ticket.rules.scss";

export const NAME = "AdministrationChannelsTicket";

export const PROP = {
    types: {
        isFromBackoffice: PropTypes.bool,
    },
    defaults: {
        isFromBackoffice: false,
    },
};

export function Component(props) {
    const {
        transaction: { data = {} },
        lang,
        isFromBackoffice,
    } = props;

    const { capFrequencies, maxAmounts, signatureLevel, userGroups, usernameLabel } = data || {};
    const { decimalSeparator, thousandSeparator } = NumberFormatUtil(lang || UtilsI18n.getLang());
    const groups = userGroups?.map((group) => group.name);

    const AdvancedTicket = ({ information, idLabel }) => {
        return (
            <div>
                <I18n className="data-label" id={idLabel} />
                <span>{information}</span>
            </div>
        );
    };

    const Amount = () => (
        <span>
            {UtilsI18n.get("administration.channels.information.frequency")}
            {SPACE_STR}
            {
                <NumberFormatInput
                    displayType="text"
                    thousandSeparator={thousandSeparator}
                    decimalSeparator={decimalSeparator}
                    value={maxAmounts}
                    decimalScale={2}
                    fixedDecimalScale
                />
            }
            <span> {UtilsI18n.get("administration.channels.topAmount.frequency")}</span>
            <span> {UtilsI18n.get(`administration.channels.${capFrequencies}`)}</span>
        </span>
    );

    return (
        <section id={isFromBackoffice && Style.ID}>
            <div className="data-wrapper">
                <DataDetalis
                    containerClassName="transfer-data data-wrapper"
                    label="administration.signature.user"
                    data={`${usernameLabel} `}
                />
                <div className="transfer-data data-wrapper">
                    <AdvancedTicket
                        information={signatureLevel || <I18n id="administration.users.edit.signatureLevel.dontSign" />}
                        idLabel="form.signatureLevel"
                    />
                </div>

                <div className="transfer-data data-wrapper">
                    <div>
                        <I18n className="data-label" id="administration.usergroups.user" />
                        {groups?.map((name) => (
                            <span>{name}, </span>
                        ))}
                    </div>
                </div>
                <div className="transfer-data data-wrapper">
                    <AdvancedTicket information={<Amount />} idLabel="administration.maxAmounts.user" />
                </div>
            </div>
        </section>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    transaction: SelectorsStoreTicket.getData(store),
});

export default Connect(mapStateToProps)(Component);
