import React from "react";

import Filesize from "filesize";
import { Field, Form, withFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import * as Yup from "yup";

import { LEVEL, EMPTY_STR, SCOPE } from "~/constants";
import {
    SelectorsAction as SelectorsActionCommunication,
    SelectorsStore as SelectorsStoreCommunication,
} from "~/store/communications";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as Config from "~/util/config";
import { generateId as GenerateId } from "~/util/general";
import * as I18nUtils from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import Image from "~/components/Image";
import RoundButton from "~/components/RoundButton";
import TextArea from "~/pages/_components/fields/TextArea";
import TextField from "~/pages/_components/fields/TextField";
import Selector from "~/pages/_components/fields/formik/Selector";

import Styles from "./Compose.rules.scss";

export const { CLASS, NAME } = Styles;

const FORM_ID = "communications.compose";

export const PROP = {
    types: {
        detail: PropTypes.shape({
            idCommunication: PropTypes.number,
            subject: PropTypes.string,
        }),
        dispatch: PropTypes.func.isRequired,
        isDesktop: PropTypes.bool,
        isFetching: PropTypes.bool,
        isReply: PropTypes.bool,
        onCloseClick: PropTypes.func,
        selectedFiles: PropTypes.arrayOf(PropTypes.any),
        trays: PropTypes.arrayOf(
            PropTypes.shape({
                idCommunicationTray: PropTypes.any.isRequired,
                name: PropTypes.string.isRequired,
            }),
        ).isRequired,
    },
    defaults: {
        detail: null,
        isDesktop: false,
        isFetching: false,
        isReply: false,
        onCloseClick: null,
        selectedFiles: [],
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        fileInputRef: React.createRef(),
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(SelectorsActionCommunication.communicationsTrayslistRequest());
    }

    componentWillUnmount() {
        const { dispatch } = this.props;

        dispatch(SelectorsActionCommunication.resetDataToNewMessage());
    }

    handleRemoveSelectedFile = (index) => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionCommunication.removeSelectedAttachment({ index }));
    };

    handleOnChangeFile(event) {
        event.stopPropagation();
        event.preventDefault();

        const file = event.target.files[0];
        const { dispatch } = this.props;

        if (file) {
            const fileTypes = Config.get("files.allowedFileExtensions");
            const fileMaxSize = Config.get("files.maxFileSize");
            const fileExtension = `${file.name.split(".").slice(-1)}`;

            if (fileTypes.indexOf(fileExtension.toLocaleLowerCase()) > -1) {
                if (file.size < fileMaxSize) {
                    dispatch(SelectorsActionCommunication.selectAttachment({ file }));
                } else {
                    dispatch(
                        SelectorsActionNotification.showNotification({
                            message: I18nUtils.get("communications.compose.attachment.maxFileSize"),
                            level: LEVEL.ERROR,
                            scopes: [SCOPE.COMMUNICATIONS, SCOPE.COMMUNICATIONS_SEND, SCOPE.COMMUNICATIONS_REPLY],
                        }),
                    );
                }
            } else {
                dispatch(
                    SelectorsActionNotification.showNotification({
                        message: I18nUtils.get("communications.compose.attachment.invalid"),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.COMMUNICATIONS, SCOPE.COMMUNICATIONS_SEND, SCOPE.COMMUNICATIONS_REPLY],
                    }),
                );
            }
        }
    }

    render() {
        const { trays, isReply, detail, selectedFiles } = this.props;

        const { fileInputRef } = this.state;

        const value = isReply
            ? {
                  value: detail.communicationTray.idCommunicationTray,
                  label: detail.communicationTray.name,
              }
            : EMPTY_STR;

        return (
            <Form>
                <Box className={CLASS}>
                    {!isReply && (
                        <Field
                            hidePlaceholder
                            component={TextField}
                            idForm={FORM_ID}
                            name="subject"
                            type="text"
                            maxLength={100}
                        />
                    )}

                    <Field
                        component={Selector}
                        options={trays
                            .map((tray) => ({
                                value: tray.idCommunicationTray,
                                label: tray.name,
                            }))
                            .sort((a, b) => {
                                const nameA = a.label.toUpperCase();
                                const nameB = b.label.toUpperCase();
                                if (nameA < nameB) {
                                    return -1;
                                }
                                if (nameA > nameB) {
                                    return 1;
                                }
                                return 0;
                            })}
                        className="slideFromBottom"
                        idForm={FORM_ID}
                        name="tray"
                        type="text"
                        settedValue={value}
                        isDisabled={isReply}
                    />

                    <Field hidePlaceholder component={TextArea} idForm={FORM_ID} name="body" maxLength={1500} />

                    <Box flex directionColumn>
                        <Button
                            className="attach needsclick"
                            variant="link"
                            size="sm"
                            onClick={() => fileInputRef.current.click()}>
                            <Image src="attach.svg" />
                            {I18nUtils.get("communications.compose.attach")}
                        </Button>

                        <Box className="file-container">
                            {selectedFiles && (
                                <Box>
                                    {selectedFiles.map((file, index) => (
                                        <Box key={GenerateId()} className="my-2 file-name">
                                            <Box flex directionColumn className="py-1">
                                                <p>{file.name}</p> <p>({Filesize(file.size)})</p>
                                            </Box>

                                            <RoundButton
                                                image="trash"
                                                aria-label="Remove"
                                                onClick={() => this.handleRemoveSelectedFile(index)}
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            )}
                        </Box>
                    </Box>

                    <Box flex justify="center" className="py-3">
                        <Button variant="primary" type="submit">
                            {I18nUtils.get("communications.compose.send")}
                        </Button>
                    </Box>
                </Box>

                <input
                    id="myInput"
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={(e) => this.handleOnChangeFile(e)}
                    onClick={(e) => {
                        e.target.value = null;
                    }}
                />
            </Form>
        );
    }
}

const mapStateToProps = (store) => ({
    dataToNewMessage: SelectorsStoreCommunication.getDataToNewMessage(store),
    detail: SelectorsStoreCommunication.detail(store),
    selectedFiles: SelectorsStoreCommunication.selectedAttachments(store),
    trays: SelectorsStoreCommunication.communicationTraysList(store),
});

export default HighOrder(
    Connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: (props) => {
            let subjectTxt = EMPTY_STR;

            if (props.isReply) {
                const { detail, preloadedMessage } = props;

                if (detail) {
                    subjectTxt = detail.subject;
                } else if (preloadedMessage) {
                    subjectTxt = preloadedMessage.subject;
                }
            }

            let result = {
                body: EMPTY_STR,
                subject: subjectTxt,
                tray: EMPTY_STR,
            };

            if (props.dataToNewMessage) {
                const { message, subject, trayLabel } = props.dataToNewMessage;

                result = {
                    body: message,
                    subject,
                    tray: trayLabel,
                };
            }

            return result;
        },
        validationSchema: () =>
            Yup.lazy(() =>
                Yup.object().shape({
                    subject: Yup.string().trim().required(I18nUtils.get("communications.subject.required")),
                    body: Yup.string().trim().required(I18nUtils.get("communications.body.required")),
                    tray: Yup.string().trim().required(I18nUtils.get("communications.tray.required")),
                }),
            ),
        handleSubmit: (fields, formikBag) => {
            const { dispatch, isDesktop, isReply, selectedFiles } = formikBag.props;

            const body = fields.body.trim();
            const subject = fields.subject.trim();
            const { tray } = fields;

            if (isReply) {
                const { detail } = formikBag.props;
                const { subject: subjectDetail, idCommunication, idThread } = detail;
                const re = `RE: ${subjectDetail}`;
                const params = {
                    body,
                    communicationPriority: 0,
                    idCommunication,
                    idThread,
                    isDesktop,
                    re,
                    selectedFiles,
                    tray,
                };

                dispatch(SelectorsActionCommunication.replyRequest(params));
            } else {
                dispatch(SelectorsActionCommunication.sendRequest({ body, isDesktop, selectedFiles, subject, tray }));
            }
        },
    }),
)(Component);
