import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import UtilsDevice from "~/util/device";
import Logger from "~/util/logger";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction } from "./_selectors";

const sagas = [
    TakeLatest(TYPE.LOAD_CAMPAIGN_REQUEST, loadCampaign),
    TakeLatest(TYPE.LOAD_CAMPAIGN_IMAGE, loadCampaignImage),
];

export default sagas;

function* loadCampaign(props) {
    const { section } = props;
    try {
        const response = yield Call(SelectorsMiddleware.fetchCampaign, { section });

        if (response.status !== 304) {
            const { data } = response.data;
            const { campaign } = data;
            if (campaign) {
                yield Put(SelectorsAction.loadCampaignImage({ item: campaign }));
            }
        }
    } catch (err) {
        Logger.error(err, "loadCampaign");
        yield Put(SelectorsAction.loadCampaignFailure());
    }
}

function getFittingImage([smallerImage, intermediateImage, biggerImage]) {
    if (UtilsDevice.isDisplayMobile()) {
        return smallerImage;
    }

    if (UtilsDevice.isIntermediateResolution()) {
        return intermediateImage;
    }

    return biggerImage;
}

function* loadCampaignImage(props) {
    const { item } = props;
    try {
        const { idImage, idCampaign } = getFittingImage(item.imageList);
        const response = yield Call(SelectorsMiddleware.fetchCampaignImage, {
            idImage,
            idCampaign,
        });

        if (response.status !== 304) {
            const { data } = response.data;
            yield Put(SelectorsAction.loadCampaignSuccess({ item: { ...item, image: data.image.content } }));
        }
    } catch (err) {
        Logger.error(err, "loadCampaignImage");
        yield Put(SelectorsAction.loadCampaignFailure());
    }
}
