import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import { SelectorsStore as SelectorsStoreComexExport } from "~/store/comex/export";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";

import Box from "~/components/Box";
import Ticket from "~/components/Ticket/Ticket";

import { buildLetter } from "../Step2Preview/Step2Preview";
import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const { dispatch, lang, ticketConfirmation, transaction } = props;
    const { data } = transaction;

    const shareTicket = (idTransactionToRead) => {
        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    };

    return (
        <section id={Style.ID}>
            <Ticket
                displayDownloadPDF
                handleDownloadPDF={() => shareTicket(transaction.idTransaction)}
                handleReject={handleReject}
                handleSign={handleSign}
                hasMobileComponent
                ticketConfirmation={ticketConfirmation}
                transaction={transaction}>
                <Box flex directionColumn align="flex-start" className="mb-3 carta">
                    {buildLetter(data, lang)}
                </Box>
            </Ticket>
        </section>
    );

    function handleReject(formikBag) {
        const { idTransaction } = transaction;
        dispatch(
            SelectorsActionComex.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        dispatch(SelectorsActionComex.signTransactionPreview({ data }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        lang: SelectorsStoreI18n.getLang(store),
        transaction: SelectorsStoreComexExport.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
