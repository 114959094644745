import { LOCATION_CHANGE } from "connected-react-router";

import { MODE } from "~/constants/form";
import { TYPE as TypesBackoffice } from "~/store/backoffice";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            return {
                ...storeProp,
                requestInfoData: PROP.defaults.requestInfoData,
                tempFormData: PROP.defaults.tempFormData,
                termDeposit: PROP.defaults.termDeposit,
            };
        }
        case TYPE.CURRENCY_HAS_CHANGED_REQUEST:
            return {
                ...storeProp,
                currencyHasChanged: !storeProp.currencyHasChanged,
            };
        case TYPE.REQUEST_INFO_PRE_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                availableForInvesting: {},
                availableForInvestChart: [],
            };
        case TYPE.MY_BRIEFCASE_PRE_REQUEST:
            return {
                ...PROP.defaults,
                fetching: true,
            };
        case TYPE.MY_BRIEFCASE_PRE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                availableForInvesting: action.availableForInvest,
                availableForInvestChart: action.availableForInvestChart,
                myPortfolioTotal: action.myPortfolioTotal,
                transactionList: action.transactionList,
                fundPapers: action.fundPapers,
                notifications: {
                    productsAboutToExpire: action.productsAboutToExpire,
                    documentsExpiredOrAboutToExpire: action.documentsExpiredOrAboutToExpire,
                    notificationsQuantity: action.notificationsQuantity,
                },
                hasW8Signed: action.hasW8Signed,
                hasRiskProfile: action.hasRiskProfile,
                hasDGI: action.hasDGI,
                resident: action.resident,
                hasProducts: action.hasProducts,
                hasInvestmentsModule: action.hasInvestmentsModule,
            };
        case TYPE.REQUEST_INFO_FAILURE:
        case TYPE.REQUEST_INFO_PRE_FAILURE:
        case TYPE.MY_BRIEFCASE_PRE_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.TERM_DEPOSIT_PRE_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                termDeposit: {
                    ...storeProp.termDeposit,
                    accountList: [],
                    currencies: [],
                    marketRate: {},
                    instructions: [],
                    unavailableDaysUSD: [],
                    unavailableDaysUYU: [],
                    mode: MODE.EDIT,
                },
            };
        case TYPE.TERM_DEPOSIT_PRE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                termDeposit: {
                    ...storeProp.termDeposit,
                    accountList: action.accountList,
                    currencies: action.currencies,
                    marketRate: action.marketRate,
                    instructions: action.instructions,
                    nextValidDate: action.nextValidDate,
                    timezoneOffset: action.timezoneOffset,
                    unavailableDaysUSD: action.unavailableDaysUSD,
                    unavailableDaysUYU: action.unavailableDaysUYU,
                },
            };
        case TYPE.TERM_DEPOSIT_CUSTOM_RATE_FAILURE:
        case TYPE.TERM_DEPOSIT_PRE_FAILURE:
        case TYPE.TERM_DEPOSIT_PREVIEW_FAILURE:
        case TYPE.SIGN_TRANSACTION_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.TERM_DEPOSIT_PREVIEW_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                termDeposit: {
                    ...storeProp.termDeposit,
                    formData: action.formData,
                },
            };
        case TYPE.TERM_DEPOSIT_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                termDeposit: {
                    ...storeProp.termDeposit,
                    mode: MODE.PREVIEW,
                    prevMode: storeProp.termDeposit.mode,
                },
            };
        case TYPE.TERM_DEPOSIT_SEND_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.TERM_DEPOSIT_SEND_FAILURE:
            return {
                ...storeProp,
                fetching: false,
                termDeposit: {
                    ...storeProp.termDeposit,
                    mode: MODE.EDIT,
                },
            };
        case TYPE.TERM_DEPOSIT_SEND_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                termDeposit: {
                    ...storeProp.termDeposit,
                    mode: MODE.VIEW,
                    prevMode: storeProp.termDeposit.mode,
                },
                transaction: action.transaction,
            };
        case TYPE.TERM_DEPOSIT_CUSTOM_RATE_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                tempFormData: action.tempFormData,
            };
        case TYPE.TERM_DEPOSIT_CUSTOM_RATE_SUCCESS:
            return {
                ...storeProp,
                tempFormData: {
                    ...storeProp.tempFormData,
                    customRate: action.rate,
                },
                termDeposit: {
                    ...storeProp.termDeposit,
                    customRate: action.rate,
                },
                fetching: false,
            };
        case TYPE.READ_TRANSACTION_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.READ_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                transaction: action.transaction,
            };
        case TYPE.CLOSE_TRANSACTION_PREVIEW:
            return {
                ...storeProp,
                fetching: false,
                termDeposit: {
                    ...storeProp.termDeposit,
                    mode: storeProp.termDeposit.prevMode,
                },
            };
        case TYPE.SIGN_TRANSACTION_PREVIEW:
            return {
                ...storeProp,
                termDeposit: {
                    ...storeProp.termDeposit,
                    prevMode: storeProp.termDeposit.mode,
                    mode: MODE.PREVIEW,
                },
            };
        case TYPE.TERM_DEPOSIT_TO_STEP_ONE:
            return {
                ...storeProp,
                termDeposit: {
                    ...storeProp.termDeposit,
                    mode: MODE.EDIT,
                },
            };
        case TYPE.REQUEST_INFO_PRE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                requestInfoData: {
                    ...action,
                    ...storeProp.requestInfoData,
                    mode: MODE.EDIT,
                },
            };
        case TYPE.REQUEST_INFO_PREVIEW_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                requestInfoData: {
                    ...storeProp.requestInfoData,
                    submitData: action.submitData,
                },
            };
        case TYPE.REQUEST_INFO_PREVIEW_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.REQUEST_INFO_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                requestInfoData: {
                    ...storeProp.requestInfoData,
                    mode: MODE.PREVIEW,
                },
            };
        case TYPE.REQUEST_INFO_SEND_FORM:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.REQUEST_INFO_SEND_FORM_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.REQUEST_INFO_SEND_FORM_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                requestInfoData: {
                    ...storeProp.requestInfoData,
                    mode: MODE.VIEW,
                },
            };
        case TYPE.RISK_PROFILE_READ_LAST:
            return {
                ...storeProp,
            };
        case TYPE.RISK_PROFILE_READ_LAST_FAILURE:
            return {
                ...storeProp,
            };
        case TYPE.RISK_PROFILE_READ_LAST_SUCCESS:
            return {
                ...storeProp,
                riskProfile: {
                    data: action.riskProfile,
                },
            };
        case TYPE.REQUEST_INFO_CLOSE_PREVIEW:
            return {
                ...storeProp,
                requestInfoData: {
                    ...storeProp.requestInfoData,
                    mode: MODE.EDIT,
                },
            };
        case TYPE.RISK_PROFILE_SEND_QUESTIONS_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.RISK_PROFILE_SEND_QUESTIONS_FAILURE:
        case TYPE.RISK_PROFILE_SEND_QUESTIONS_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.RESTART_FLOW:
            return PROP.defaults;
        case TYPE.FROM_RISK_PROFILE_QUIZ:
            return {
                ...storeProp,
                isFromRiskProfileQuiz: action.isFromRiskProfileQuiz,
            };
        case TypesBackoffice.BACKOFFICE_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                prevMode: storeProp.mode,
                transaction: action.transaction,
            };
        case TYPE.OPEN_CHANGE_DATE_MODAL: {
            return {
                ...storeProp,
                showChangeDateModal: true,
            };
        }
        case TYPE.CLOSE_CHANGE_DATE_MODAL: {
            return {
                ...storeProp,
                showChangeDateModal: false,
            };
        }
        default:
            return storeProp;
    }
};
