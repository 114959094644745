import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";

import Image from "~/components/Image";

export const NAME = "ItemInfo";

export const PROP = {
    types: { reference: PropTypes.string.isRequired, icon: PropTypes.string },
    defaults: { icon: EMPTY_STR },
};

export function Component(props) {
    const { icon, reference } = props;
    return (
        <React.Fragment>
            {(icon && (
                <div className="data-product-image">
                    <Image src={`${icon}.svg`} className="svg-icon svg-logo" />

                    <span>{reference}</span>
                </div>
            )) || <span className="data-desc">{reference}</span>}
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
