import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, TYPE, PATH } from "./_consts";

export const SelectorsMiddleware = {
    loadListRequest: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_USER_LIST, props),
    exportListRequest: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_USER_EXPORT, props),
    changeUsersStatusPreview: (props) =>
        API.executeWithAccessToken(PATH.ADMINISTRATION_USER_CHANGE_STATUS_PREVIEW, props),
    changeUsersStatusConfirmation: (props) =>
        API.executeWithAccessToken(PATH.ADMINISTRATION_USER_CHANGE_STATUS_CONFIRMATION, props),
    deleteUsersPreview: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_USER_DELETE_PREVIEW, props),
    deleteUsersConfirmation: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_USER_DELETE_CONFIRMATION, props),
    updateDispacther: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_USER_UPDATE_DISPATCHER, props),
    resendInviteRequest: (props) => API.executeWithAccessToken(PATH.RESEND_INVITE_REQUEST, props),
};
export const SelectorsAction = {
    loadListRequest: (props) => FactoryAction(TYPE.LOAD_LIST_REQUEST, props),
    loadListFailure: () => FactoryAction(TYPE.LOAD_LIST_FAILURE),
    loadListSuccess: (props) => FactoryAction(TYPE.LOAD_LIST_SUCCESS, props),
    loadMoreRequest: (props) => FactoryAction(TYPE.LOAD_MORE_REQUEST, props),
    loadMoreSuccess: (props) => FactoryAction(TYPE.LOAD_MORE_SUCCESS, props),
    exportListRequest: (props) => FactoryAction(TYPE.EXPORT_LIST_REQUEST, props),
    exportListSuccess: () => FactoryAction(TYPE.EXPORT_LIST_SUCCESS),
    exportListFailure: () => FactoryAction(TYPE.EXPORT_LIST_FAILURE),
    loadSingleSuccess: (props) => FactoryAction(TYPE.LOAD_SINGLE_SUCCESS, props),
    updateDispatcherRequest: (props) => FactoryAction(TYPE.UPDATE_DISPATCHER_REQUEST, props),
    updateDispatcherFailure: (props) => FactoryAction(TYPE.UPDATE_DISPATCHER_FAILURE, props),
    updateDispatcherSuccess: (props) => FactoryAction(TYPE.UPDATE_DISPATCHER_SUCCESS, props),
    changeUserStatusPreview: (props) => FactoryAction(TYPE.CHANGE_USER_STATUS_PREVIEW, props),
    changeUserStatusPreviewSuccess: (props) => FactoryAction(TYPE.CHANGE_USER_STATUS_PREVIEW_SUCCESS, props),
    changeUserStatusConfirmation: (props) => FactoryAction(TYPE.CHANGE_USER_STATUS_CONFIRMATION, props),
    changeUserStatusConfirmationSuccess: (props) => FactoryAction(TYPE.CHANGE_USER_STATUS_CONFIRMATION_SUCCESS, props),
    resendInviteRequest: (props) => FactoryAction(TYPE.RESEND_INVITE_REQUEST, props),
};

export const SelectorsStore = {
    getUsers: (store) => store[NAME].list,
    getInvitationCodes: (store) => store[NAME].invitationCodes,
    getUsersInfo: (store) => store[NAME].usersInfoById,
    isFetching: (store) => store[NAME].fetching,
    getUserInfo: (store, user) => (user ? store[NAME].usersInfoById[user.idUser] : null),
    isFetchingMoreUsers: (store) => store[NAME].fetchingMoreUsers,
    getCurrentPage: (store) => store[NAME].currentPage,
    getTotalPages: (store) => store[NAME].totalPages,
    getHasMoreData: (store) => store[NAME].hasMoreData,
    isFetchingExport: (store) => store[NAME].fetchingExport,
    getUserAction: (store) => store[NAME].userAction,
    getUsersToApplyAction: (store) => store[NAME].usersToApplyAction,
    getUsersNamesToApplyAction: (store) => store[NAME].usersNamesToApplyAction,
    getIdTransaction: (store) => store[NAME].idTransaction,
    getIdActivity: (store) => store[NAME].idActivity,
    getCredentialGroups: (store) => store[NAME].credentialGroups,
    notification: (store) => store[NAME].notificacion,
    getMessage: (store) => store[NAME].message,
    getLevel: (store) => store[NAME].level,
    getScopes: (store) => store[NAME].scopes,
    getCleanSelectionForUserActions: (store) => store[NAME].cleanSelectionForUserActions,
};
