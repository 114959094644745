import { put as Put, takeLatest as TakeLatest, call as Call, select as Select } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE, FORM_FIELD_ERRORS, SIGN_WITH_BIOMETRIC } from "~/constants";
import Store from "~/store";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import IsTrustedBiometric from "~/store/form/_sagas/_isTrustedBiometric";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import {
    credentialsWithUnderscore as CredentialsWithUnderscore,
    hasIncorrectCredentials as HasIncorrectCredentials,
} from "~/util/form";
import * as UtilsI18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction } from "./_selectors";

const sagas = [
    TakeLatest(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, cancelTransactionCustom),
    TakeLatest(TYPE.PREVIEW_PAPER_REQUEST, previewPaper),
    TakeLatest(TYPE.READ_PAPER_REQUEST, readPaper),
    TakeLatest(TYPE.READ_TRANSACTION_REQUEST, readTransactionRequest),
    TakeLatest(TYPE.SEND_FORM_PAPER_REQUEST, sendForm),
];

export default sagas;

function* cancelTransactionCustom(props) {
    const { credentials, idTransaction, formikBag } = props;

    yield* handleCancelTransaction(credentials, idTransaction, formikBag);

    formikBag.setSubmitting(false);
}

function* handleCancelTransaction(credentials, idTransaction, formikBag) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);
    const {
        data: { data },
        type,
    } = yield Call(SelectorsMiddlewareForm.cancelTransaction, {
        idTransactionToCancel: idTransaction,
        ...credentialsWithUnderscore,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data);
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("forms.cancelTransaction.errorMessage"),
                    level: LEVEL.ERROR,
                    scopes: SCOPE.WEALTH_MANAGEMENT_BUY_FUNDS,
                }),
            );
        }
    } else {
        yield* readTransactionRequest({ idTransaction });
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("forms.cancelTransaction.confirmationMessage"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.WEALTH_MANAGEMENT_BUY_FUNDS, SCOPE.DESKTOP],
            }),
        );
    }
}

function* previewPaper(props) {
    const { type, data } = yield Call(SelectorsMiddleware.wealthManagementFundsBuyPreview, props);
    if (type === RESPONSE_TYPE.WARNING) {
        props.formikBag.setErrors(data.data);

        yield Put(SelectorsAction.taskFinished());
        const errorMessage = data.data.NO_FIELD || UtilsI18n.get(FORM_FIELD_ERRORS);

        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.WEALTH_MANAGEMENT_BUY_FUNDS],
            }),
        );
    } else {
        yield Put(SelectorsAction.previewPaperSuccess({ data: data.data }));
    }
}

function* readPaper(props) {
    const { type, data } = yield Call(SelectorsMiddleware.wealthManagementFundsBuyPre, props);
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.taskFinished());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.WEALTH_MANAGEMENT_BUY_FUNDS],
            }),
        );
    } else {
        yield Put(SelectorsAction.readPaperSuccess({ data: data.data }));
    }
}

function* readTransactionRequest(props) {
    const { idTransaction } = props;
    const { type, data } = yield Call(SelectorsMiddlewareForm.readTransaction, {
        idTransactionToRead: idTransaction,
    });
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.WEALTH_MANAGEMENT_BUY_FUNDS],
            }),
        );
    } else {
        yield Put(SelectorsAction.readTransactionSuccess({ transaction: data.data.transaction }));
    }
}

function* sendForm(props) {
    const { formikBag } = props;
    const myProps = { ...props };
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
    }

    const { _otp } = myProps;

    const { webToken } = Store.getState().session;
    const tokenToUse = _otp || webToken;

    const { type, data } = yield Call(SelectorsMiddleware.wealthManagementFundsBuySend, { ...props, _otp: tokenToUse });
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.taskFinished());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.WEALTH_MANAGEMENT_BUY_FUNDS],
            }),
        );
    } else {
        const { idTransaction } = data;
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(SelectorsAction.paperSendFormSuccess({ transaction }));
    }
}
