import React from "react";

import PropTypes from "prop-types";

import PageLoading from "~/pages/_components/PageLoading";

export const NAME = "MainContainer";

export const PROP = {
    types: {
        children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
        showLoader: PropTypes.bool,
        form: PropTypes.bool,
        className: PropTypes.string,
    },
    defaults: {
        showLoader: false,
        className: "main-container",
        children: null,
        form: false,
    },
};

export function Component(props) {
    const { className, showLoader, children, form } = props;
    return (
        <React.Fragment>
            <div className="view-page">
                <div role="main" className="view-content">
                    <main className={className}>
                        {!form ? (
                            <PageLoading loading={showLoader}>{!showLoader && children} </PageLoading>
                        ) : (
                            !showLoader && children
                        )}
                    </main>
                </div>
            </div>
        </React.Fragment>
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
