import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "biometric";

export const PATH = {
    VERIFY_BIOMETRIC: "/migration.check.mobile.phone",
    VALIDATE_PASSWORD: "/fingerprint.validate.password",
    FACE_AUTHENTICATION: "/face.authenticate",
    FACE_REGISTER_ENROLLMENT: "/face.register.execute",
    FACE_REGISTER_MIGRATION: "/face.register.migration",
};

export const TYPE = FactoryType(NAME, [
    "SELECT_BIOMETRIC_TYPE",
    "VERIFY_BIOMETRIC_REQUEST",
    "VERIFY_BIOMETRIC_SUCCESS",
    "VERIFY_BIOMETRIC_ERROR",
    "VERIFY_BIOMETRIC_CANCEL",
    "NO_ENROLLED_FINGERPRINT",
    "REGISTER_BIOMETRIC_REQUEST",
    "REGISTER_BIOMETRIC_FACEPHI_REQUEST",
    "REGISTER_BIOMETRIC_FACEPHI_SUCCESS",
    "REGISTER_BIOMETRIC_FACEPHI_ERROR",
    "REGISTER_BIOMETRIC_SUCCESS",
    "REGISTER_BIOMETRIC_ERROR",
    "VALIDATE_PASSWORD_REQUEST",
    "VALIDATE_PASSWORD_SUCCESS",
    "VALIDATE_PASSWORD_ERROR",
    "TOGGLE_PASSWORD_MODAL",
    "SHOW_PASSWORD_MODAL",
    "HIDE_PASSWORD_MODAL",
    "VERIFY_FACEPHI_CHANGE_MY_PHONE_REQUEST",
    "CLEAN_UP",
    "RETRY_AUTH_NUMBER",
    "GENERATE_WEB_TOKEN",
]);

export const TYPE_VERIFY_BIOMETRIC = {
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
    CANCEL: "CANCEL",
    NO_ENROLLED_FINGERPRINT: "NO_ENROLLED_FINGERPRINT",
    TOGGLE_PASSWORD_MODAL: "TOGGLE_PASSWORD_MODAL",
};

export const PROP = {};

PROP.types = {
    fetching: PropTypes.bool,
    askPassword: PropTypes.bool,
    isFacephi: PropTypes.bool,
    pathFrom: PropTypes.string,
    statusVerifyBiometric: PropTypes.string,
    biometricType: PropTypes.string,
    retryAuthenticationNumber: PropTypes.int,
    ...TypesRedux,
};

PROP.defaults = {
    fetching: false,
    askPassword: false,
    isFacephi: false,
    pathFrom: "",
    statusVerifyBiometric: "",
    biometricType: "",
    retryAuthenticationNumber: 0,
    ...DefaultsRedux,
};
