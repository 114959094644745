import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR, TYPE_ADMINISTRATION, AMOUNT, ZERO_NUMBER, SCOPE } from "~/constants";
import Container from "~/containers/Internal/Administration/Simple";
import * as ConfigUtil from "~/util/config";
import { generateId as GenerateId } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import ButtonMore from "~/components/Button";
import Button from "~/components/Button/Button";
import GridLayout from "~/components/GridLayout";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";
import SelectButton from "~/components/SelectButton";
import FormattedAmount from "~/pages/_components/FormattedAmount";

import DeletePopUp from "~/pages/administration/advanced/_components/DeletePopUp";

import Style from "./SignatureSchemes.rules.scss";

export const NAME = "SignaturesSchemes";

export const PROP = {
    types: {
        actions: PropTypes.shape({
            exportListRequest: PropTypes.func,
            listSignaturesSchemesRequest: PropTypes.func,
            loadMoreRequest: PropTypes.func,
        }).isRequired,
        activeEnvironment: PropTypes.shape({
            administrationScheme: PropTypes.string,
            type: PropTypes.string,
        }).isRequired,
        currentPage: PropTypes.number,
        fetching: PropTypes.bool,
        fetchingExport: PropTypes.bool,
        fetchingMoreSignaturesSchemes: PropTypes.bool,
        hasMoreData: PropTypes.bool.isRequired,
        routerActions: PropTypes.shape({
            push: PropTypes.func,
        }).isRequired,
        signaturesSchemes: PropTypes.arrayOf(
            PropTypes.shape({
                capList: PropTypes.arrayOf(PropTypes.shape({ maximum: PropTypes.number })),
                idSignature: PropTypes.number,
                signatureType: PropTypes.string,
            }),
        ).isRequired,
        totalPages: PropTypes.number.isRequired,
    },
    defaults: { currentPage: 1, fetching: false, fetchingExport: false, fetchingMoreSignaturesSchemes: false },
};
export function Component(props) {
    const {
        actions,
        fetching,
        signaturesSchemes,
        totalPages,
        hasMoreData,
        fetchingMoreSignaturesSchemes,
        i18n,
        routerActions,
        activeEnvironment: { administrationScheme, type },
    } = props;
    const [showDeletePopUp, setShowDeletePopUp] = React.useState(false);
    const [idSignatureToDelete, setIdSignatureToDelete] = React.useState(0);
    const [groupMapToDelete, setGroupMapToDelete] = React.useState("");

    const isSimple = administrationScheme === TYPE_ADMINISTRATION.SIMPLE;
    const isAdvanced = administrationScheme === TYPE_ADMINISTRATION.ADVANCED;
    const isMedium = administrationScheme === TYPE_ADMINISTRATION.MEDIUM;

    React.useLayoutEffect(() => {
        if (isSimple) {
            routerActions.push("/");
        }
        actions.listSignaturesSchemesRequest({ params: { filters: { pageNumber: 1, orderBy: "id_signature ASC" } } });
    }, [actions, isSimple, routerActions]);

    const getSigners = (signatureScheme) => {
        const { groupsMap } = signatureScheme;

        return isMedium
            ? groupsMap.A
            : Object.keys(groupsMap).reduce((result, key) => result + key.toString().repeat(groupsMap[key]), EMPTY_STR);
    };

    const handleClickDownload = (format) => {
        actions.exportListRequest({ format });
    };

    const handleMoreDataClick = () => {
        const { currentPage } = props;

        actions.loadMoreRequest({ pageNumber: currentPage + 1, orderBy: "id_signature ASC" });
    };

    const btnHandlerOnClick = () => {
        routerActions.push(`/administration/${administrationScheme}/signaturesSchemes/create`);
    };

    const onRowClick = (idSignature) => {
        routerActions.push(`/administration/${administrationScheme}/signaturesSchemes/modify/${idSignature}`);
    };

    const onClickDeleteSignature = (idSignature, groupsMap, evt) => {
        evt.stopPropagation();
        setShowDeletePopUp(true);
        setIdSignatureToDelete(idSignature);
        setGroupMapToDelete(groupsMap);
    };

    const onSubmitDeleteSignature = () => {
        actions.deleteSignaturesSchemeRequest({
            signatureId: idSignatureToDelete,
            groupsMap: groupMapToDelete,
        });
        setShowDeletePopUp(false);
    };

    const renderPageHeader = () => {
        const { history } = props;

        return (
            <React.Fragment>
                <Box flex justify="end" className="box-button-content">
                    <Button
                        variant="secondary"
                        onClick={() => history.push(`/administration/advanced/signaturesSchemes/create`)}>
                        <Image src="plus.svg" />
                        {UtilsI18n.get("administration.signatures.new.title")}
                    </Button>
                    <SelectButton label={UtilsI18n.get("global.download")}>
                        <Link onClick={() => handleClickDownload("csv")}>
                            {UtilsI18n.get("client.lists.export.formatCSV")}
                        </Link>
                        <Link onClick={() => handleClickDownload("pdf")}>
                            {UtilsI18n.get("client.lists.export.formatPDF")}
                        </Link>
                    </SelectButton>
                </Box>
            </React.Fragment>
        );
    };

    const COLUMN_HEADINGS_MEDIUM = [i18n.signers, i18n.transactions, EMPTY_STR];
    const COLUMN_HEADINGS_ADVANCED = [i18n.signers, i18n.amount, i18n.transactions, EMPTY_STR];

    const COLUMN_TABLE = (isAdvanced && COLUMN_HEADINGS_ADVANCED) || (isMedium && COLUMN_HEADINGS_MEDIUM);
    const HeaderSignaturesSchemes = () => {
        return (
            <React.Fragment>
                <div className="title">
                    <span className="description">
                        <p>{i18n.get("administration.signature.advanced.index.description.one")}</p>
                        <p>{i18n.get("administration.signature.advanced.index.description.two")}</p>
                    </span>
                </div>
                <GridLayout className="header">
                    {COLUMN_TABLE.map((heading, index) => (
                        <div key={`header-${GenerateId()}`} id={`header-${index}`} className="listHeaders">
                            {heading}
                        </div>
                    ))}
                </GridLayout>
            </React.Fragment>
        );
    };

    const BodySignaturesSchemes = () => {
        return signaturesSchemes.map((signatureScheme) => {
            const { capList, idSignature, signatureType } = signatureScheme;

            let transactions = EMPTY_STR;
            let amount = "-";
            if (isAdvanced || isMedium) {
                transactions = i18n.get(`signatures.type.${signatureType}`);
            }

            if (signatureType === AMOUNT && capList?.length > ZERO_NUMBER) {
                amount = (
                    <FormattedAmount
                        className="data-amount"
                        frequencyInSpan={false}
                        currency={ConfigUtil.get("core.masterCurrency")}
                        quantity={
                            capList[0].maximum === -1
                                ? ConfigUtil.get(`default_cap_signature_${type}`)
                                : capList[0].maximum
                        }
                        frequency={i18n
                            .get("administration.signatures.advanced.by")
                            .concat(" ")
                            .concat(
                                i18n.get(`administration.signatures.transactions.capFrequency.${capList[0].frequency}`),
                            )}
                    />
                );
            }
            const groupsMap = getSigners(signatureScheme);
            return (
                <GridLayout key={idSignature} className="item" onClick={() => onRowClick(idSignature)}>
                    <div>
                        <div className="userName">
                            <p>{groupsMap}</p>
                        </div>
                    </div>
                    {isAdvanced && <div>{amount}</div>}
                    <div>{transactions}</div>
                    <div className="delete-icon-button-container">
                        <Button
                            className="delete-icon-button"
                            type="button"
                            size="sm"
                            variant="secundary"
                            onClick={(evt) => onClickDeleteSignature(idSignature, groupsMap, evt)}>
                            <Image src="trash.svg" className="svg-big-icon" />
                        </Button>
                    </div>
                </GridLayout>
            );
        });
    };

    const handleBack = () => {
        const { history } = props;
        history.goBack();
    };

    return (
        <Container
            name={NAME}
            wait={fetching}
            head-title="administration.signatures.list.title"
            head-onBack={handleBack}
            image="administration-title-icon.svg"
            scopeToShowNotification={SCOPE.ADMINISTRATION_SIGNATURES_SCHEME}>
            <div id={Style.ID}>
                {renderPageHeader()}
                <div>
                    {signaturesSchemes?.length ? (
                        <div>
                            <HeaderSignaturesSchemes />
                            <BodySignaturesSchemes />
                            <React.Fragment>
                                {totalPages > 1 &&
                                    (hasMoreData ? (
                                        <div className="no-more-data" key="noMoreUsers">
                                            <ButtonMore
                                                type="button"
                                                className="btn btn-link"
                                                onClick={handleMoreDataClick}
                                                loading={fetchingMoreSignaturesSchemes}
                                                label="administration.signatures.advanced"
                                            />
                                        </div>
                                    ) : (
                                        <div key="noMoreUsers">
                                            <p className="text-lead">
                                                <I18n id="administration.signatures.advanced.more.noMoreData" />
                                            </p>
                                        </div>
                                    ))}
                            </React.Fragment>
                        </div>
                    ) : (
                        <div>
                            <Image src="transfer.svg" className="svg-big-icon" />
                            <p className="text-lead">
                                <I18n id="administration.signatures.list.noRecords" />
                            </p>
                            <Button type="button" className="btn btn-primary btn-block" onClick={btnHandlerOnClick}>
                                <I18n id="administration.signatures.list.addGroup" />
                            </Button>
                        </div>
                    )}
                </div>
            </div>

            {showDeletePopUp && (
                <DeletePopUp
                    show
                    title={i18n.get("administration.signatures.advanced.deletePopup.title")}
                    description={i18n.get("administration.signatures.advanced.deletePopup.description")}
                    handleCloseModal={() => {
                        setShowDeletePopUp(false);
                    }}
                    onSubmit={onSubmitDeleteSignature}
                />
            )}
        </Container>
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default HighOrder(HighOrder.Resizable)(Component);
