import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import { SelectorsStore as SelectorsStoreWMBonds, SelectorsAction as SelectorsActionWMBonds } from "~/store/wm/bonds";
import * as UtilsI18n from "~/util/i18n";

import FormPreview from "./Step2Preview/Step2Preview";
import FormView from "./Step3View/Step3View";

export const NAME = "SellBondsTicket";

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const { dispatch, match, transaction, fetching, mode } = props;
    const { id } = match.params;

    React.useEffect(() => {
        dispatch(SelectorsActionWMBonds.readTransactionRequest({ idTransaction: id }));
    }, [dispatch, id]);

    const wait = fetching || !transaction.data;

    return (
        <Container
            name={NAME}
            wait={wait}
            step={mode}
            head-title={UtilsI18n.get("client.wm.bonds.sell.title")}
            head-onBack={handleBack}
            head-onBackWeb={handleBack}
            scopeToShowNotification={SCOPE.SELL_BONDS}>
            {mode === MODE.PREVIEW && <FormPreview />}
            {transaction.data && (mode === MODE.EDIT || mode === MODE.VIEW) && <FormView transaction={transaction} />}
        </Container>
    );

    function handleBack() {
        dispatch(GoBack());
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        fetching: SelectorsStoreWMBonds.getFetching(store),
        transaction: SelectorsStoreWMBonds.getTransaction(store),
        mode: SelectorsStoreWMBonds.getMode(store),
        ...PROP.defaults,
    };
};

export default Connect(mapStateToProps)(Component);
