import React from "react";

import { Field, Form, Formik } from "formik";
import { Modal } from "react-bootstrap";

import * as i18nUtils from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import GridLayout from "~/components/GridLayout";
import Image from "~/components/Image";
import TextArea from "~/pages/_components/fields/TextArea";
import TextField from "~/pages/_components/fields/TextField";

import Style from "./AddDocumentModal.rules.scss";

export const { NAME, NAME_MESSAGES } = Style;
export const PROP = {
    types: {},
    defaults: {},
};

const FORM_ID = "comex.creditLetter.modal.documentTypes";
const TRANSPORT_DOCUMENTS = [
    "CONOCIMIENTO_DE_EMBARQUE_MARITIMO",
    "DOCUMENTO_DE_TRANSPORTE_AEREO",
    "DOCUMENTO_DE_TRANSPORTE_TERRESTRE",
];
const incotermsDocuments = {
    PAGADERO_EN_DESTINO: ["EXW", "FCA", "FAS", "FOB"],
    PREPAGO: ["CFR", "CIF", "CPT", "CIP", "DAP", "DAT", "DDP"],
};

export function Component({ show, handleCloseModal, documentTypeList, terms, shippedBy, submit, document }) {
    const [state, setState] = React.useState({
        step: 1,
        title: "Seleccione el documento a agregar",
        otherFields: false,
        transportFields: false,
        commonFields: false,
        insuranceFields: false,
    });

    const handleCheckClick = (type, setFieldValue) => {
        const otherFields = false;
        let transportFields = false;
        let commonFields = false;
        let insuranceFields = false;
        if (TRANSPORT_DOCUMENTS.includes(type)) {
            transportFields = true;
            if (type === "CONOCIMIENTO_DE_EMBARQUE_MARITIMO" && setFieldValue) {
                setFieldValue(
                    "issuedTo",
                    i18nUtils.get("comex.creditLetter.modal.documentTypes.transports.MARITIMO.issuedTo"),
                );
                setFieldValue(
                    "notifyTo",
                    i18nUtils.get("comex.creditLetter.modal.documentTypes.transports.MARITIMO.notifyTo"),
                );
            } else if (type === "DOCUMENTO_DE_TRANSPORTE_AEREO" && setFieldValue) {
                setFieldValue(
                    "issuedTo",
                    i18nUtils.get("comex.creditLetter.modal.documentTypes.transports.AEREO.issuedTo"),
                );
            } else if (type === "DOCUMENTO_DE_TRANSPORTE_TERRESTRE" && setFieldValue) {
                setFieldValue(
                    "issuedTo",
                    i18nUtils.get("comex.creditLetter.modal.documentTypes.transports.TERRESTRE.issuedTo"),
                );
            }
        } else if (type === "POLIZA_DE_SEGURO") {
            insuranceFields = true;
        } else if (type === "OTROS") {
            commonFields = true;
            // otherFields = true;
        } else {
            commonFields = true;
        }

        setState({
            type,
            step: 2,
            otherFields,
            transportFields,
            commonFields,
            insuranceFields,
        });
    };

    if (Object.entries(document).length > 0 && state.step === 1) {
        handleCheckClick(document.type, null);
    }

    const reset = () => {
        setState({
            type: "",
            step: 1,
            otherFields: false,
            transportFields: false,
            commonFields: false,
            insuranceFields: false,
        });
    };

    const closeModal = () => {
        handleCloseModal();
        setTimeout(reset, 500);
    };

    const getDescription = (doc) => {
        const flete = incotermsDocuments.PAGADERO_EN_DESTINO.includes(terms) ? "PAGADERO_EN_DESTINO" : "PREPAGO";
        let messageKey = "";
        if (doc.type === "CONOCIMIENTO_DE_EMBARQUE_MARITIMO") {
            if (terms === "OTROS") {
                messageKey =
                    "comex.imports.creditLetter.transport.incotermsDocuments.CONOCIMIENTO_DE_EMBARQUE_MARITIMO.otros";
            } else {
                messageKey =
                    "comex.imports.creditLetter.transport.incotermsDocuments.CONOCIMIENTO_DE_EMBARQUE_MARITIMO";
            }
        } else if (doc.type === "DOCUMENTO_DE_TRANSPORTE_AEREO") {
            if (terms === "OTROS") {
                messageKey =
                    "comex.imports.creditLetter.transport.incotermsDocuments.DOCUMENTO_DE_TRANSPORTE_AEREO.otros";
            } else {
                messageKey = "comex.imports.creditLetter.transport.incotermsDocuments.DOCUMENTO_DE_TRANSPORTE_AEREO";
            }
        } else if (doc.type === "DOCUMENTO_DE_TRANSPORTE_TERRESTRE") {
            messageKey = "comex.imports.creditLetter.transport.incotermsDocuments.DOCUMENTO_DE_TRANSPORTE_TERRESTRE";
        } else {
            return doc.description;
        }

        return i18nUtils.replaceParams(i18nUtils.get(messageKey), {
            orden: doc.issuedTo,
            notificar: doc.notifyTo,
            flete: i18nUtils.get(`comex.imports.creditLetter.transport.incotermsDocuments.${flete}`),
        });
    };

    const onSubmit = (values) => {
        const { type } = state;
        const description = getDescription({ ...values, type });
        submit({ ...values, type, description });
        closeModal();
    };

    const filterDocumentTypeList = () => {
        let fliteredList = [...documentTypeList];
        if (terms && !terms.includes("I")) {
            fliteredList = fliteredList.filter((d) => d !== "POLIZA_DE_SEGURO");
        }

        if (shippedBy === "AEREO") {
            fliteredList = fliteredList.filter(
                (d) => d !== "CONOCIMIENTO_DE_EMBARQUE_MARITIMO" && d !== "DOCUMENTO_DE_TRANSPORTE_TERRESTRE",
            );
        } else if (shippedBy === "MARITIMO") {
            fliteredList = fliteredList.filter(
                (d) => d !== "DOCUMENTO_DE_TRANSPORTE_AEREO" && d !== "DOCUMENTO_DE_TRANSPORTE_TERRESTRE",
            );
        } else if (shippedBy === "TERRESTRE") {
            fliteredList = fliteredList.filter(
                (d) => d !== "DOCUMENTO_DE_TRANSPORTE_AEREO" && d !== "CONOCIMIENTO_DE_EMBARQUE_MARITIMO",
            );
        } else if (shippedBy === "") {
            fliteredList = fliteredList.filter(
                (d) =>
                    d !== "CONOCIMIENTO_DE_EMBARQUE_MARITIMO" &&
                    d !== "DOCUMENTO_DE_TRANSPORTE_TERRESTRE" &&
                    d !== "DOCUMENTO_DE_TRANSPORTE_AEREO" &&
                    d !== "POLIZA_DE_SEGURO",
            );
        }

        return fliteredList;
    };

    const { step, type, transportFields, otherFields, commonFields, insuranceFields } = state;

    return (
        <Modal show={show}>
            <Modal.Body>
                <Formik
                    enableReinitialize
                    initialValues={{
                        name: document.name || "",
                        description: document.description || "",
                        originals: document.originals || "",
                        copies: document.copies || "",
                        issuedTo: document.issuedTo || "",
                        notifyTo: document.notifyTo || "",
                        percentage: document.percentage || "",
                        daysInCustoms: document.daysInCustoms || "",
                    }}
                    onSubmit={onSubmit}
                    validate={(field) => {
                        const { copies, originals, percentage } = field;
                        const errors = {};

                        if (!parseInt(originals, 10) && !parseInt(copies, 10)) {
                            errors.originals = i18nUtils.get(`${FORM_ID}.oneField.required`);
                            errors.copies = i18nUtils.get(`${FORM_ID}.oneField.required`);
                        }
                        if (insuranceFields && percentage < 110) {
                            errors.percentage = i18nUtils.get(`${FORM_ID}.percentage.required`);
                        }

                        return errors;
                    }}>
                    {({ setFieldValue }) => (
                        <Form id={Style.ID} noValidate>
                            <div className="component-modal">
                                <div className="closed clickable" onClick={closeModal} role="button">
                                    <Image src="close.svg" />
                                </div>
                                <h2 className="title">
                                    {i18nUtils.get(
                                        // eslint-disable-next-line no-nested-ternary
                                        step === 1
                                            ? `${FORM_ID}.title`
                                            : Object.entries(document).length > 0
                                            ? `${FORM_ID}.${document.type}`
                                            : `${FORM_ID}.${type}`,
                                    )}
                                </h2>
                                {step === 1 &&
                                    filterDocumentTypeList().map((t) => (
                                        <div
                                            onClick={() => handleCheckClick(t, setFieldValue)}
                                            className="documentTypeRow"
                                            key={`documentType-row-${t}`}>
                                            <Box>{i18nUtils.get(`comex.creditLetter.modal.documentTypes.${t}`)}</Box>
                                        </div>
                                    ))}

                                {step === 2 && (
                                    <React.Fragment>
                                        {otherFields && (
                                            <GridLayout className="position-clickable" flexDiretion="row">
                                                <div className="vertical-direction">
                                                    <Field
                                                        idForm={FORM_ID}
                                                        name="name"
                                                        maxLength={100}
                                                        type="text"
                                                        placeholder=""
                                                        component={TextField}
                                                    />
                                                </div>
                                            </GridLayout>
                                        )}

                                        {transportFields && (
                                            <React.Fragment>
                                                <div className="vertical-direction">
                                                    <Field
                                                        idForm={FORM_ID}
                                                        name="issuedTo"
                                                        type="text"
                                                        placeholder=""
                                                        component={TextField}
                                                    />
                                                </div>
                                                <div className="vertical-direction">
                                                    <Field
                                                        idForm={FORM_ID}
                                                        name="notifyTo"
                                                        maxLength={160}
                                                        type="text"
                                                        placeholder=""
                                                        component={TextField}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        )}

                                        {commonFields && (
                                            <div className="vertical-direction">
                                                <Field
                                                    idForm={FORM_ID}
                                                    name="description"
                                                    type="text"
                                                    maxLength={160}
                                                    placeholder=""
                                                    component={TextArea}
                                                />
                                            </div>
                                        )}

                                        {insuranceFields && (
                                            <React.Fragment>
                                                <div className="vertical-direction amount">
                                                    <Field
                                                        idForm={FORM_ID}
                                                        name="percentage"
                                                        type="text"
                                                        pattern="[1-9][0-9]{0,2}?"
                                                        placeholder=""
                                                        component={TextField}
                                                    />
                                                </div>
                                                <div className="vertical-direction amount">
                                                    <Field
                                                        idForm={FORM_ID}
                                                        name="daysInCustoms"
                                                        type="text"
                                                        pattern="[0-9]{0,2}"
                                                        placeholder=""
                                                        component={TextField}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        )}

                                        <div className="vertical-direction amount no-error-icon">
                                            <Field
                                                component={TextField}
                                                autoComplete="off"
                                                pattern="[0-9]{0,2}"
                                                type="text"
                                                idForm={FORM_ID}
                                                name="originals"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="vertical-direction amount">
                                            <Field
                                                component={TextField}
                                                autoComplete="off"
                                                pattern="[0-9]{0,2}"
                                                type="text"
                                                idForm={FORM_ID}
                                                name="copies"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="container-button-center">
                                            <Button
                                                key="primaryButton"
                                                variant="secondary"
                                                bsStyle="secondary"
                                                label="global.cancel"
                                                loading={false}
                                                type="button"
                                                onClick={closeModal}
                                            />
                                            <Button
                                                idForm={FORM_ID}
                                                key="primaryButton"
                                                variant="primary"
                                                bsStyle="primary"
                                                label="comex.imports.creditLetter.form.attachments.add"
                                                loading={false}
                                                type="submit"
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
}

export default Component;
