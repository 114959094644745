import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";

import { EMPTY_STR, ZERO_NUMBER } from "~/constants";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { getInteger as GetInteger } from "~/util/config";
import { numberFormat, toNumber } from "~/util/number";

import NumberFormatInput from "~/components/NumberFormatInput";
import Select from "~/components/Select";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import withFocus from "~/pages/_components/withFocus";

export const NAME = "AmountField";

export const PROP = {
    types: {
        form: PropTypes.shape({
            setFieldTouched: PropTypes.func,
            setFieldValue: PropTypes.func,
            touched: PropTypes.object.isRequired,
            errors: PropTypes.object.isRequired,
        }).isRequired,
        data: PropTypes.shape({
            options: PropTypes.array,
        }),
        decimalPlaces: PropTypes.number,
        clearable: PropTypes.bool,
        disableSelect: PropTypes.bool,
        hideLabel: PropTypes.bool,
        hideCurrency: PropTypes.bool,
        field: PropTypes.shape({
            amount: PropTypes.number,
        }),
        fixedDecimalScale: PropTypes.bool,
        toggleIsFocused: PropTypes.func.isRequired,
        lang: PropTypes.string,
        placeholder: PropTypes.string,
        idForm: PropTypes.string.isRequired,
        isFocused: PropTypes.bool,
        maximumDecimals: PropTypes.number,
        maxLength: PropTypes.number,
        minimumDecimals: PropTypes.number,
        size: PropTypes.string,
        searchable: PropTypes.bool,
        inputZeroAllowed: PropTypes.bool,
    },
    defaults: {
        data: { options: [] },
        clearable: true,
        decimalPlaces: 0,
        disableSelect: false,
        fixedDecimalScale: false,
        hideLabel: false,
        hideCurrency: false,
        field: { amount: 0 },
        lang: EMPTY_STR,
        placeholder: EMPTY_STR,
        isFocused: false,
        maximumDecimals: 0,
        maxLength: 20,
        minimumDecimals: 0,
        size: EMPTY_STR,
        searchable: false,
        inputZeroAllowed: false,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    handleBlur = () => {
        const { field, form, toggleIsFocused, handleBlur } = this.props;

        toggleIsFocused();
        form.setFieldTouched(field.name);

        if (handleBlur) {
            handleBlur(field.value);
        }
    };

    handleCurrencyChange = (value) => {
        if (value) {
            const { field, form, onCurrencyChange } = this.props;
            if (onCurrencyChange) {
                onCurrencyChange(value.value);
            }
            form.setFieldValue(`${field.name}.currency`, value.value);
        }
    };

    handleInputChange = ({ target }) => {
        const { field, form, lang, maxLength, handleChange } = this.props;
        const { decimalSeparator } = numberFormat(lang);
        let { value } = target;
        // manage maxLength in pasted text
        const maxLengthStrings = maxLength + Math.floor(maxLength / 3);

        if (value.length > maxLengthStrings) {
            value = value.slice(0, maxLengthStrings);
        }
        if (handleChange) {
            handleChange(value);
        }
        form.setFieldValue(`${field.name}.amount`, toNumber(value, decimalSeparator));
    };

    render() {
        const {
            clearable,
            data,
            decimalPlaces,
            disableSelect,
            field,
            fixedDecimalScale,
            form: { touched, errors },
            hideLabel,
            idForm,
            isFocused,
            lang,
            placeholder,
            maximumDecimals,
            maxLength,
            minimumDecimals,
            toggleIsFocused,
            hideCurrency,
            size,
            tooltip,
            inputZeroAllowed,
        } = this.props;

        const { options } = data;
        const hasError = touched[field.name] && errors[field.name];
        const { decimalSeparator, thousandSeparator } = numberFormat(lang);
        const decimalScale = Math.max(Math.min(decimalPlaces, maximumDecimals), minimumDecimals);

        const maxLengthStrings = maxLength + Math.floor(maxLength / 3);
        const amountType = typeof field.value?.amount;

        const valueInput = field.value?.amount && amountType === "number" ? field.value.amount : null;
        const valueInputZeroAllowed = field.value?.amount && amountType === "number" ? field.value.amount : ZERO_NUMBER;
        let curr = field.value && field.value.currency;
        let value = inputZeroAllowed ? valueInputZeroAllowed : valueInput;
        // try to get value from quantity
        if (value === (null || ZERO_NUMBER) && field.value?.quantity) {
            value = field.value.quantity;
        } else if (value === (null || ZERO_NUMBER) && amountType === "object" && !curr) {
            value = field.value?.amount?.amount;
            curr = field.value?.amount?.currency;
        }

        return (
            <div
                className={`form-group form-group--composite ${hasError ? "has-error" : ""} ${
                    isFocused ? "has-focus" : ""
                } ${size === "small" ? "form-group--small" : ""}`}
                onBlur={this.handleBlur}
                onFocus={toggleIsFocused}>
                {!hideLabel && <FieldLabel labelKey={`${idForm}.${field.name}.label`} />}
                <div className="input-group">
                    {!hideCurrency && options.length === 1 ? (
                        <span className="currency">{options[0].label}</span>
                    ) : (
                        !hideCurrency && (
                            <Select
                                clearable={clearable}
                                className="currency-selector slideFromBottom flex-container"
                                disabled={disableSelect}
                                onChange={this.handleCurrencyChange}
                                options={options.map(({ id, label }) => ({
                                    value: id,
                                    label,
                                }))}
                                value={curr}
                            />
                        )
                    )}
                    <NumberFormatInput
                        allowNegative={false}
                        className="form-control"
                        decimalScale={decimalScale}
                        decimalSeparator={decimalSeparator}
                        fixedDecimalScale={fixedDecimalScale}
                        inputProps={{ maxLength: maxLengthStrings }}
                        onChange={this.handleInputChange}
                        thousandSeparator={thousandSeparator}
                        type="tel"
                        value={value}
                        placeholder={placeholder}
                    />
                    {tooltip && (
                        <div className="tooltip">
                            <span className="tooltip-text"> {tooltip}</span>
                        </div>
                    )}
                </div>

                {/* TODO revisar hoy esta parcheado para que funcione UYHSBCCDP-7675
                    El hasError tiene el texto del error y a nivel de la saga tuve que hacer un touched
                    store/wm/bonds/_sagas.js GetTouchedFields
                */}
                {hasError && <FieldError error={errors[field.name].amount || hasError} />}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lang: SelectorsStoreI18n.getLang(state),
    maximumDecimals: GetInteger("defaultDecimal.maximum"),
    minimumDecimals: GetInteger("defaultDecimal.minimum"),
});

export default Compose(Connect(mapStateToProps), withFocus)(Component);
