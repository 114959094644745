import React from "react";

import { Form, Field, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import { EMPTY_STR, HSBC, BROU } from "~/constants";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import {
    PROP as PROP_TRANSACTIONLINES,
    SelectorsAction as SelectorActionsTransactionLines,
    SelectorsStore as SelectorsStoreTransactionLines,
} from "~/store/transactionLines";
import { getArray, getInteger } from "~/util/config";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button/Button";
import EmailList from "~/pages/_components/fields/EmailList";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import Select from "~/pages/_components/fields/Select";
import TextField from "~/pages/_components/fields/TextField";
import AmountField from "~/pages/_components/fields/formik/AmountField";

import Style from "./SuppliersPaymentForm.rules.scss";

const FORM_ID = "suppliersPayment.manual";

export const NAME = "SuppliersPaymentLinesForm";

export const PROP = {
    types: {
        ...TypesRedux,
        localBanks: PropTypes.array.isRequired,
        initialValues: PropTypes.shape({
            address: PropTypes.string,
            credit: PropTypes.shape({
                amount: PropTypes.string.isRequired,
                currency: PropTypes.string.isRequired,
            }),
            creditAccountBank: PropTypes.string,
            creditAccountName: PropTypes.string,
            creditAccountNumber: PropTypes.string,
            email: PropTypes.string,
            reference: PropTypes.string,
        }).isRequired,
        onAdd: PropTypes.func.isRequired,
        onEdit: PropTypes.func.isRequired,
        paymentCurrency: PropTypes.string.isRequired,
        submitButtonLabel: PropTypes.string,
        transactionLines: PropTypes.array.isRequired,
    },
    defaults: {
        ...DefaultsRedux,
        localBanks: null,
        submitButtonLabel: "global.accept",
        transactionLines: [],
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    numInput = React.createRef();

    componentDidMount() {
        const { dispatch, localBanks } = this.props;

        if (localBanks.length === 0) {
            dispatch(SelectorActionsTransactionLines.listLocalBanksRequest());
        }
    }

    render() {
        const { emailValidationRegex, localBanks, paymentCurrency, submitButtonLabel, values } = this.props;

        const { address, credit, creditAccountBank, creditAccountName, creditAccountNumber, email, reference } = values;

        const isHSBC = creditAccountBank === HSBC;

        return (
            <Form className={Style.CLASS}>
                <Field
                    component={TextField}
                    id="creditAccountName"
                    idForm={FORM_ID}
                    maxLength={getInteger(`${FORM_ID}.maxLength.account`)}
                    name="creditAccountName"
                    placeholder={EMPTY_STR}
                    tooltip={!isHSBC ? UtilsI18n.get("client.pay.suppliers.addPayment.name.tooltip") : undefined}
                    value={creditAccountName}
                />
                {!isHSBC && (
                    <Field
                        component={TextField}
                        idForm={FORM_ID}
                        maxLength={getInteger(`${FORM_ID}.maxLength.address`)}
                        name="address"
                        optional={UtilsI18n.get("form.field.optional")}
                        placeholder={EMPTY_STR}
                        value={address}
                    />
                )}

                <div className="form-group">
                    <FieldLabel labelKey={`${FORM_ID}.creditAccountBank.label`} />
                    <Select
                        className="flex-container slideFromBottom"
                        id="creditAccountBank"
                        idForm={FORM_ID}
                        name="creditAccountBank"
                        options={localBanks}
                        searchable
                        value={creditAccountBank}
                    />
                </div>
                <Field
                    autoComplete="off"
                    component={TextField}
                    id="creditAccountNumber"
                    idForm={FORM_ID}
                    inputRef={this.numInput}
                    maxLength={getInteger("account.external.length")}
                    name="creditAccountNumber"
                    placeholder={EMPTY_STR}
                    tooltip={UtilsI18n.get("client.pay.addPayment.account.tooltip")}
                    value={creditAccountNumber}
                />

                <Field
                    clearable={false}
                    component={AmountField}
                    data={{
                        options: [
                            {
                                id: paymentCurrency,
                                label: UtilsI18n.get(`currency.label.${paymentCurrency}`),
                            },
                        ],
                    }}
                    idForm={FORM_ID}
                    maxLength={getInteger("amount.length")}
                    name="credit"
                    placeholder={EMPTY_STR}
                    searchable={false}
                    value={credit}
                    decimalPlaces={2}
                    fixedDecimalScale
                />
                <Field
                    component={TextField}
                    id="reference"
                    idForm={FORM_ID}
                    maxLength={getInteger(`${FORM_ID}.maxLength.reference`)}
                    name="reference"
                    optional={UtilsI18n.get("form.field.optional")}
                    placeholder={EMPTY_STR}
                    tooltip={UtilsI18n.get("client.pay.suppliers.addPayment.reference.tooltip")}
                    value={reference}
                />
                <Field
                    component={EmailList}
                    className="form-group email-field"
                    idForm={FORM_ID}
                    name="email"
                    maxLength={getInteger(`${FORM_ID}.maxLength.email`)}
                    optional={UtilsI18n.get("form.field.optional")}
                    placeholder={EMPTY_STR}
                    tooltip={UtilsI18n.get("client.pay.suppliers.addPayment.email.tooltip")}
                    value={email}
                    data={{ emailValidationRegex }}
                    hideSwiftTip
                    renderSuggestion={false}
                    limit={1}
                />
                <div className="form-group">
                    <Button block type="submit" variant="primary">
                        {UtilsI18n.get(submitButtonLabel)}
                    </Button>
                </div>
            </Form>
        );
    }
}

const mapStateToProps = (store) => ({
    emailValidationRegex: SelectorsStoreConfig.getConfig(store)["email.validationFormat"],
    lineNumber: SelectorsStoreTransactionLines.getSelectedLine(store),
    localBanks: SelectorsStoreTransactionLines.getLocalBanks(store),
    paymentCurrency: SelectorsStoreTransactionLines.getCurrency(store),
    selectedItem: SelectorsStoreTransactionLines.getSelectedItem(store) || PROP_TRANSACTIONLINES.initialValues,
    transactionLines: SelectorsStoreTransactionLines.getTransactionLines(store),
});

function createSchema(props) {
    const {
        transactionLines,
        selectedItem: { lineNumber },
    } = props;

    return Yup.lazy((values) =>
        Yup.object().shape({
            creditAccountName: Yup.string()
                .trim()
                .required(UtilsI18n.get(`${FORM_ID}.supplierName.required.error`)),
            creditAccountBank: Yup.string()
                .trim()
                .required(UtilsI18n.get(`${FORM_ID}.creditAccountBank.required.error`)),
            creditAccountNumber: Yup.string()
                .trim()
                .matches(/^\d+$/, UtilsI18n.get(`${FORM_ID}.creditAccountNumber.hsbc.number.error`))
                .test("exists", UtilsI18n.get(`${FORM_ID}.creditAccountNumber.exists.error`), (value) => {
                    const lineWithAccountNumber = transactionLines.find(
                        (line) =>
                            line.creditAccountNumber === value && line.creditAccountBank === values.creditAccountBank,
                    );
                    return !lineWithAccountNumber || (lineNumber && lineWithAccountNumber.lineNumber === lineNumber);
                })
                .when("creditAccountBank", {
                    is: (value) => value === HSBC,
                    then: Yup.string()
                        .min(1, UtilsI18n.get(`${FORM_ID}.creditAccountNumber.hsbc.min.error`))
                        .max(
                            getInteger("account.hsbc.length"),
                            UtilsI18n.get(`${FORM_ID}.creditAccountNumber.hsbc.min.error`),
                        ),
                })
                .when("creditAccountBank", {
                    is: (value) => value === BROU,
                    then: Yup.string().matches(
                        new RegExp(
                            `^([0-9]{${getArray("client.allowed.accountLengths.brou")[0]}}|[0-9]{${
                                getArray("client.allowed.accountLengths.brou")[1]
                            }})$`,
                        ),
                        UtilsI18n.get(`pay.multiline.error.212`),
                    ),
                })
                .required(UtilsI18n.get(`${FORM_ID}.creditAccountNumber.required.error`)),
            credit: Yup.object().shape({
                currency: Yup.string().required(),
                amount: Yup.number()
                    .typeError(UtilsI18n.get(`${FORM_ID}.credit.required.error`))
                    .min(0.0, `${FORM_ID}credit.positive.error`),
            }),
            email: Yup.lazy((val) =>
                Array.isArray(val)
                    ? Yup.array().of(
                          Yup.string().test("valid-email", values.currentError, (email) => {
                              return !values.currentError || email;
                          }),
                      )
                    : Yup.string().test("valid-email", values.currentError, (email) => {
                          return !values.currentError || email;
                      }),
            ),
        }),
    );
}

export default Compose(
    Connect(mapStateToProps),
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: ({
            selectedItem: {
                address: selectedAddress,
                creditAccountBank: selectedBank,
                creditAccountName: selectedName,
                creditAccountNumber: selectedAccount,
                creditAmountQuantity: selectedAmount,
                reference: selectedReference,
                email: selectedEmail,
            },
            localBanks,
            paymentCurrency,
        }) => ({
            address: selectedAddress,
            credit: {
                amount: selectedAmount || 0.0,
                currency: paymentCurrency,
            },
            creditAccountBank: selectedBank || (localBanks && localBanks.length > 0 ? localBanks[0].value : EMPTY_STR),
            creditAccountName: selectedName,
            creditAccountNumber: `${selectedAccount}`,
            reference: selectedReference,
            email: selectedEmail,
        }),
        validationSchema: (props) => createSchema(props),
        handleSubmit: (props, formikBag) => {
            const {
                props: {
                    localBanks,
                    onAdd,
                    onEdit,
                    selectedItem: { lineNumber },
                },
            } = formikBag;

            const {
                address,
                credit: { currency: creditAmountCurrency, amount: creditAmountQuantity },
                creditAccountBank,
                creditAccountName,
                creditAccountNumber,
                email,
                reference,
            } = props;
            const amount = creditAmountQuantity !== EMPTY_STR ? creditAmountQuantity : 0;

            const paymentLine = {
                creditAccountName,
                address: creditAccountBank !== HSBC ? address : null,
                creditAccountBank,
                creditAccountBankShortLabel: localBanks.find((bank) => bank.value === creditAccountBank)?.shortLabel,
                creditAccountNumber,
                creditAmountCurrency,
                creditAmountQuantity: amount,
                reference,
                email,
            };

            if (lineNumber) {
                onEdit({
                    paymentLine: {
                        ...paymentLine,
                        lineNumber,
                    },
                });
            } else {
                onAdd({ paymentLine });
            }
        },
    }),
)(Component);
