import React from "react";

import { Field } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, DEFAULT_COUNTRY, DEFAULT_CITY, ZERO, REGEX_IGNORE_ACCENTS } from "~/constants";
import { MODE } from "~/constants/form";
import { SelectorsAction } from "~/store/settings";
import * as ConfigUtil from "~/util/config";
import * as UtilI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import I18n from "~/components/I18n";
import TextArea from "~/pages/_components/fields/TextArea";

import FileUploader from "../changePersonalInformation/field/FileUploader";
import TextField from "../changePersonalInformation/field/InputText";
import Select from "../changePersonalInformation/field/Selector";
import SelectorOrInput from "../changePersonalInformation/field/SelectorOrInput";
import SelectorType from "../changePersonalInformation/field/SelectorType";
import Style from "../changePersonalInformation/form/FormAddresses.rules.scss";

const FORM_ID = "change.PersonalData";

export const NAME = "FormGeneric";

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        legal: PropTypes.object.isRequired,
        correspondense: PropTypes.object.isRequired,
        values: PropTypes.object.isRequired,
        setFieldValue: PropTypes.func.isRequired,
        addresses: PropTypes.object,
        countryList: PropTypes.array,
        cityList: PropTypes.array,
        townList: PropTypes.array,
        loadData: PropTypes.array,
        ...TypesRedux,
    },
    defaults: {
        ...DefaultsRedux,
        addresses: {},
        countryList: [{ id: EMPTY_STR, name: EMPTY_STR }],
        cityList: [{ id: EMPTY_STR, name: EMPTY_STR }],
        townList: [{ id: EMPTY_STR, name: EMPTY_STR }],
        loadData: [{ id: EMPTY_STR, name: EMPTY_STR }],
    },
};
export function Component({
    legal,
    correspondense,
    dispatch,
    values,
    setFieldValue,
    countryList,
    cityList,
    townList,
    loadData,
    typeAddresses,
    infoForm,
    addressesUser,
    infoLoadData,
    loadDataIsVisible,
    attachFileVisible,
    ...rest
}) {
    const [idContryTemp, setIdContryTemp] = React.useState(DEFAULT_COUNTRY);

    const extra = { addressesUser, legal: { ...infoLoadData } };
    const cityType = `city${typeAddresses}`;

    const handleChangeCountry = (id) => {
        setIdContryTemp(id);
        dispatch(SelectorsAction.changeAddressesDepartamentRequest({ idCountry: id, typeAddresses }));
    };

    const handleChangeDepartament = (id) => {
        if (id !== 0) {
            dispatch(
                SelectorsAction.changeAddressesCityRequest({ idCountry: idContryTemp, idCity: id, typeAddresses }),
            );
            setFieldValue(cityType, "");
        }
    };

    const customFilter = (option, searchText)=>{
        const optionLowerCase = option.name.toLowerCase();
        const text = searchText.toLowerCase();

        if(optionLowerCase.normalize("NFD").replace(REGEX_IGNORE_ACCENTS, "").includes(text) && optionLowerCase.charAt(0).includes(text.charAt(0))){
            return true;
        }
        else {
            return false
        }
    }

    const onChangeValue = (val) => {
        dispatch(
            SelectorsAction.changeAddressesDepartamentAndCityRequest({
                idCountry: extra[val].idCountry || DEFAULT_COUNTRY,
                idCity: extra[val].idDepartament || DEFAULT_CITY,
            }),
        );
        setFieldValue(`country${typeAddresses}`, extra[val].idCountry);
        setFieldValue(`departament${typeAddresses}`, extra[val].idDepartament);
        setFieldValue(`city${typeAddresses}`, extra[val].idCity);
        setFieldValue(`street${typeAddresses}`, extra[val].street);
        setFieldValue(`doorNumber${typeAddresses}`, extra[val].doorNumber);
        setFieldValue(`apto${typeAddresses}`, extra[val].apto);
        setFieldValue(`zipCode${typeAddresses}`, extra[val].zipCode);
        setFieldValue(`observations${typeAddresses}`, extra[val].observations);
    };

    const departamentType = `departament${typeAddresses}`;
    const textOpcinal = UtilI18n.get("form.field.optional");
    const renderCity = values[departamentType]?.toString() !== ZERO;
    const countryType = `country${typeAddresses}`;
    const loadDataPopInfo = loadData.filter((data) => data?.id !== typeAddresses?.toLocaleLowerCase());

    const typeFile = ConfigUtil.get("client.accounts.attachments.allowedFileExtensions").replaceAll("|", ", ");
    const tooltip = `${UtilI18n.get("change.PersonalData.filesLegal.size")} ${Number(
        ConfigUtil.get("client.accounts.attachments.maxFileSize"),
    ).toFixed(1)} MB ${UtilI18n.get("change.PersonalData.filesLegal.information")} ${typeFile} .`;
    
    return (
        <div className="change--data--form__generic">
            <div className="change--data--form__subtitle">
                <I18n id={`change.PersonalData.subtitle.${typeAddresses}`} />
            </div>
            <div id={Style.ID} className="container--data">
                <React.Fragment>
                    <div className=" field-spacing">
                        {loadDataIsVisible && (
                            <div className=" field-spacing">
                                <SelectorType
                                    idForm={FORM_ID}
                                    name={`loadData${typeAddresses}`}
                                    options={loadDataPopInfo}
                                    label={UtilI18n.get("change.PersonalData.loadData.label")}
                                    valueKey="id"
                                    labelKey="name"
                                    onChange={onChangeValue}
                                    customFilter={(option, searchText)=> customFilter(option, searchText)}
                                />
                            </div>
                        )}

                        <Select
                            idForm={FORM_ID}
                            name={countryType}
                            options={countryList}
                            label={UtilI18n.get("change.PersonalData.country.label")}
                            nameType="idCountry"
                            valueKey="id"
                            labelKey="name"
                            onChange={handleChangeCountry}
                            setFieldValue={setFieldValue}
                            customFilter={(option, searchText)=> customFilter(option, searchText)}
                            {...rest}
                        />
                    </div>

                    <div className=" field-spacing">
                        <SelectorOrInput
                            name={departamentType}
                            nameOther={`otherDepartament${typeAddresses}`}
                            nameType="idDepartament"
                            idForm={FORM_ID}
                            valueKey="id"
                            options={cityList}
                            labelKey="name"
                            label="departament"
                            onChange={handleChangeDepartament}
                            defaultsValue=""
                            dependencyField={{ country: values[countryType], fieldMe: values[departamentType] }}
                            setFieldValue={setFieldValue}
                            lengthOther={20}
                            customFilter={(option, searchText)=> customFilter(option, searchText)}
                            {...rest}
                        />
                    </div>

                    <div className=" field-spacing">
                        <SelectorOrInput
                            name={cityType}
                            nameOther={`otherCity${typeAddresses}`}
                            lengthOther={30}
                            nameType="idCity"
                            idForm={FORM_ID}
                            options={townList}
                            valueKey="id"
                            labelKey="name"
                            label="city"
                            dependencyField={{ country: values[countryType], fieldMe: values[cityType] }}
                            renderSelector={renderCity}
                            setFieldValue={setFieldValue}
                            customFilter={(option, searchText)=> customFilter(option, searchText)}
                            {...rest}
                        />
                    </div>

                    <div className=" field-spacing">
                        <TextField
                            idForm={FORM_ID}
                            nameType={`street${typeAddresses}`}
                            name={`street${typeAddresses}`}
                            autoFocus={false}
                            mode={MODE.EDIT}
                            hidePlaceholder
                            maxLength={35}
                            setFieldValue={setFieldValue}
                        />
                    </div>

                    <div className=" field-spacing">
                        <TextField
                            idForm={FORM_ID}
                            nameType={`doorNumber${typeAddresses}`}
                            name={`doorNumber${typeAddresses}`}
                            autoFocus={false}
                            mode={MODE.EDIT}
                            hidePlaceholder
                            maxLength={5}
                            setFieldValue={setFieldValue}
                        />
                    </div>

                    <div className=" field-spacing">
                        <TextField
                            idForm={FORM_ID}
                            nameType={`apto${typeAddresses}`}
                            name={`apto${typeAddresses}`}
                            autoFocus={false}
                            mode={MODE.EDIT}
                            hidePlaceholder
                            maxLength={6}
                            optional={textOpcinal}
                            setFieldValue={setFieldValue}
                        />
                    </div>

                    <div className=" field-spacing">
                        <TextField
                            idForm={FORM_ID}
                            nameType={`zipCode${typeAddresses}`}
                            name={`zipCode${typeAddresses}`}
                            autoFocus={false}
                            mode={MODE.EDIT}
                            hidePlaceholder
                            maxLength={8}
                            setFieldValue={setFieldValue}
                            optional={textOpcinal}
                        />
                    </div>

                    <div className="field-observations field-spacing ">
                        <div className="tooltip">
                            <span className="tooltip-text">
                                <div className="field-observations--information">
                                    <I18n id="change.PersonalData.observations.informative" />
                                </div>
                            </span>
                        </div>
                        <Field
                            idForm={FORM_ID}
                            name={`observations${typeAddresses}`}
                            component={TextArea}
                            autoFocus={false}
                            mode={MODE.EDIT}
                            hidePlaceholder
                            maxLength={75}
                        />
                    </div>

                    {attachFileVisible && (
                        <Field
                            name="filesLegal"
                            idForm={FORM_ID}
                            component={FileUploader}
                            value={EMPTY_STR}
                            tooltip={tooltip}
                        />
                    )}
                </React.Fragment>
            </div>
        </div>
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Connect(null)(Component);
