import React from "react";

import { push as Push } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import Container from "~/containers/Internal/Form/Filter";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";

import Style from "./RiskProfile.rules.scss";

export const NAME = "InvestmentRiskProfile";

export const PROP = {
    types: {
        ...TypesRedux,
        fetching: PropTypes.bool,
        dispatch: PropTypes.func.isRequired,
        values: PropTypes.object.isRequired,
        setFieldValue: PropTypes.func.isRequired,
    },
    defaults: {
        ...DefaultsRedux,
        fetching: false,
    },
};
export function Component({ dispatch, fetching, history }) {
    const handleClick = () => {
        dispatch(Push("/investment/riskProfileQuiz"));
    };

    const handleBack = React.useCallback(() => {
        history.goBack();
    }, [history]);

    return (
        <Container
            wait={fetching}
            name={NAME}
            head-onBack={handleBack}
            head-title="wm.riskProfile.step2.title"
            className="position"
            image="businessPortfolio.svg">
            <section id={Style.ID}>
                <Box className="form-risk-profile">
                    <Box className="container--data">
                        <I18n className="risk-header" id="wm.riskProfile.step2.header" />
                        <Box className="text-risk">
                            <I18n className="info-text" id="wm.riskProfile.step2.text.1" />
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <Box className="risk-button">
                        <Button type="button" size="md" variant="primary" onClick={handleClick}>
                            <I18n id="wm.riskProfile.step2.submit" />
                        </Button>
                    </Box>
                </Box>
            </section>
        </Container>
    );
}
export default Connect()(Component);
