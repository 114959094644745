import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "externalPayments";

export const TYPE = FactoryType(NAME, [
    "SET_PARAMS",
    "CLEAN_PARAMS",
    "BACK_URL_SISTARBANC",
    "LOGOUT_BEVSA",
    "BACK_URL_SISTARBANC_SUCCESS",
    "BACK_URL_SISTARBANC_ERROR",
]);

export const PROP = {};

PROP.types = {
    isFromSistarbanc: PropTypes.bool,
    isFromBevsa: PropTypes.bool,
    isExternalLogin: PropTypes.bool,
    queryString: PropTypes.string,
    ...TypesRedux,
};

PROP.defaults = {
    isFromSistarbanc: false,
    isExternalLogin: false,
    isFromBevsa: false,
    queryString: null,
    ...DefaultsRedux,
};
