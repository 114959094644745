import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.SET_PARAMS:
            return {
                ...storeProp,
                isExternalLogin: action.isExternalLogin,
                isFromSistarbanc: action.isFromSistarbanc,
                isFromBevsa: action.isFromBevsa,
                queryString: action.queryString,
            };
        case TYPE.CLEAN_PARAMS:
            return {
                ...PROP.defaults,
            };
        default:
            return storeProp;
    }
};
