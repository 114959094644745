import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction } from "./_selectors";

export default [TakeLatest(TYPE.LIST_LOANS_REQUEST, listLoansRequest), TakeLatest(TYPE.LOAN_READ_REQUEST, loanRead)];

function* listLoansRequest() {
    const response = yield Call(SelectorsMiddleware.listLoans);
    if (response && response.status === 200) {
        const { requestLoanPermission } = response.data.data;
        const { loansList } = response.data.data;

        yield Put(SelectorsAction.listLoanSuccess({ loansList, requestLoanPermission }));
    }
}

function* loanRead(props) {
    const myProps = { ...props };
    delete myProps.type;
    const response = yield Call(SelectorsMiddleware.readLoan, myProps);

    if (response && response.status === 200) {
        yield Put(SelectorsAction.loanReadSuccess({ ...response.data.data }));
    }
}
