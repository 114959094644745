import React from "react";

import { replace as Replace } from "connected-react-router";
import { withFormik as WithFormik, Form, Field } from "formik";
import PropTypes from "prop-types";
import { Grid, Row, Col } from "react-bootstrap";
import { connect as Connect } from "react-redux";

import Container from "~/containers/Internal/Form/Simple";
import { SelectorsAction as SelectorsActionSession, SelectorsStore as SelectorsStoreSession } from "~/store/session";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Environments, { PROP as PropEnvironments } from "~/pages/_components/Environments";

import Style from "./ChangeEnvironment.rules.scss";

// import Head from "~/pages/_components/Head";
// import MainContainer from "~/pages/_components/MainContainer";

export const NAME = "ChangeEnvironment";

export const PROP = {
    types: {
        environments: PropEnvironments.environments,
        activeEnvironment: PropEnvironments.activeEnvironment,
        isSubmitting: PropTypes.bool,
    },
    defaults: {
        environments: [],
        activeEnvironment: null,
        isSubmitting: false,
    },
};

export function Component(props) {
    const { environments, activeEnvironment, isSubmitting, dispatch, user } = props;

    let defaultEnvironment = activeEnvironment;
    /* eslint-disable no-restricted-syntax */
    for (const environment in environments) {
        /* eslint-disable eqeqeq */
        if (environments[environment].idEnvironment == user.defaultEnvironmentId) {
            defaultEnvironment = environments[environment];
            defaultEnvironment.id = Number(defaultEnvironment.idEnvironment);
        }
    }

    const close = () => {
        dispatch(
            Replace({
                pathname: "/settings",
                state: { transition: "transition-flow-close" },
            }),
        );
    };

    const handleBack = () => {
        const { history } = props;
        history.goBack();
    };
    return (
        <Container
            name={NAME}
            head-title="settings.changeEnvironment"
            head-onBackWeb={handleBack}
            head-onClose={close}
            wait={isSubmitting}
            className={Style.CLASS}>
            <Form className="above-the-fold">
                <section className="container--layout">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-lead">
                                    <I18n id="settings.changeEnvironment.title" />
                                </p>
                            </Col>
                        </Row>
                    </Grid>
                </section>
                <section className="container--layout flex-grow align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Field
                                    name="environment"
                                    environments={environments}
                                    component={Environments}
                                    activeEnvironment={defaultEnvironment}
                                />
                            </Col>
                        </Row>
                    </Grid>
                </section>
                <section className="container--layout">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Button type="submit" loading={isSubmitting} label="global.select" bsStyle="primary" />
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </Form>
        </Container>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default HighOrder(
    Connect((state) => ({
        environments: SelectorsStoreSession.getEnvironments(state),
        activeEnvironment: SelectorsStoreSession.getActiveEnvironment(state),
        user: SelectorsStoreSession.getUser(state),
    })),
    WithFormik({
        mapPropsToValues: ({ activeEnvironment }) => ({
            environment: activeEnvironment.id,
            rememberEnvironment: true,
        }),
        handleSubmit: ({ environment, rememberEnvironment }, formikBag) => {
            formikBag.props.dispatch(
                SelectorsActionSession.changeEnvironment({
                    idEnvironment: environment,
                    rememberEnvironment,
                    formikBag,
                    shouldNavigate: true,
                }),
            );
        },
    }),
)(Component);
