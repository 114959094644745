import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { CDP_CURRENCY, DATA_NOT_AVAILABLE, EMPTY_STR, SPACE_STR } from "~/constants";
import {
    SelectorsAction as SelectorsActionAccount,
    SelectorsStore as SelectorsStoreAccount,
    PROP as PROP_ACCOUNTS,
} from "~/store/account";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import * as UtilDate from "~/util/date";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import Element from "~/components/Component";
import DataDate from "~/components/DataDate";
import DataNumber from "~/components/DataNumber";
import GridLayout from "~/components/GridLayout";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import IntersectionObserver from "~/components/IntersectionObserver";
import Modal from "~/components/Modal";

import EditStatementNote from "./EditStatementNote";
import Multicash from "./Multicash";
import RatesList from "./RatesList";
import Filters from "./_Filters";
import Style from "./_Movements.rules.scss";
import { NAME_NOTE, TYPE_NOTE } from "./_index.scss";

export const { NAME, TYPE } = Style;

export const PROP = {
    types: {
        accountCurrency: PROP_ACCOUNTS.types.currency,
        filters: PROP_ACCOUNTS.types.filters,
        hasMore: PROP_ACCOUNTS.types.moreMovements,
        i18n: PropTypes.object,
        idAccount: PropTypes.string.isRequired,
        isFetchingMovements: PROP_ACCOUNTS.types.fetchingMovements,
        movements: PROP_ACCOUNTS.types.movements,
        pageNumber: PROP_ACCOUNTS.types.pageNumber,
    },
    defaults: {
        hasMore: PROP_ACCOUNTS.defaults.hasMoreMovements,
        i18n: {
            accountingDate: "Fecha contable",
            balance: "Saldo",
            bankCheck: "Cheque",
            concept: "Concepto",
            debitCredit: "Débito / Crédito",
            labelMore: "Más movimientos",
            labelNoMore: "No hay más movimientos",
            loading: "Cargando",
            notAvailable: DATA_NOT_AVAILABLE,
            reference: "Referencia",
        },
        isFetching: PROP_ACCOUNTS.defaults.isFetchingMovements,
        movements: PROP_ACCOUNTS.defaults.movements,
        page: PROP_ACCOUNTS.defaults.pageNumber,
    },
};

export function Component(props) {
    const {
        account,
        accountCurrency,
        dispatch,
        filters,
        hasMore,
        i18n,
        idAccount,
        isFetchingMovements,
        isMobile,
        movements,
        pageNumber,
        showMulticashModal,
        showNoteModal,
        showRatesModal,
        totalLaw,
    } = props;

    const COLUMN_HEADINGS = [
        i18n.accountingDate,
        i18n.concept,
        i18n.referenceCheck,
        i18n.debit,
        i18n.credit,
        i18n.balance,
    ];
    const { labelMore, labelNoMore, loading, noRecords, notAvailable, prefixCheck, prefixReference, totalLaw19210 } =
        i18n;

    const movementsItems = movements.map((item) => {
        const { balance, check, concept, credit, date, debit, idStatement, note, reference, valueDate } = item;

        return {
            balance,
            check,
            concept: concept || notAvailable,
            credit,
            currency: accountCurrency,
            date,
            debit,
            idStatement,
            imageType: debit !== 0 ? "Debito" : "Credito",
            key: `movement-${idStatement}`,
            noteImageType: note ? "withNote" : "withoutNote",
            reference,
            valueDate,
        };
    });
    const renderMovement = (
        { balance, check, concept, credit, date, debit, imageType, key, noteImageType, reference },
        index,
    ) => {
        return (
            <GridLayout className="body" key={`movement-${key}`}>
                <Box className="web-only">
                    <DataDate value={date} />
                </Box>
                <Box>
                    <Box className="mobile-only">
                        <Image src={`${imageType}.svg`} />
                    </Box>
                    <Box flex className="mobile-only" align="start">
                        <Box directionColumn>
                            <Box>{concept}</Box>
                            <Box>
                                {check !== 0 ? (
                                    <Box>
                                        {SPACE_STR}
                                        {prefixCheck} {check}
                                    </Box>
                                ) : (
                                    <Box>
                                        {reference.length > 0 ? `${prefixReference}` : null} {reference}
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    {concept && (
                        <Box flex directionColumn className="web-only" align="start">
                            <Box>{concept}</Box>
                        </Box>
                    )}
                </Box>
                <Box className="web-only">
                    {check || reference ? (
                        <Box className="web-only">{check !== 0 ? <Box>{check}</Box> : <Box>{reference}</Box>}</Box>
                    ) : (
                        <Box>{EMPTY_STR}</Box>
                    )}
                </Box>
                <Box className="mobile-only">
                    {debit > 0 ? (
                        <Box flex directionColumn align="end">
                            <Box>
                                <DataDate value={date} className="date-mobile" />
                            </Box>
                            <DataNumber
                                flex
                                directionColumn
                                align="end"
                                value={debit > 0 && debit}
                                fixedDecimalScale
                                isDebit={debit !== 0}
                            />
                        </Box>
                    ) : (
                        <Box>{EMPTY_STR}</Box>
                    )}
                    {credit > 0 ? (
                        <Box flex directionColumn align="end">
                            <Box>
                                <DataDate value={date} className="date-mobile" />
                            </Box>
                            <DataNumber value={credit > 0 && credit} fixedDecimalScale isDebit={debit !== 0} />
                        </Box>
                    ) : (
                        <Box>{EMPTY_STR}</Box>
                    )}
                </Box>

                {debit ? (
                    <Box className="web-only" justify="end">
                        <DataNumber value={debit > 0 && debit} fixedDecimalScale isDebit={debit !== 0} />
                        {debit > 0 && <Image src={`${imageType}.svg`} />}
                    </Box>
                ) : (
                    <Box className="web-only">{EMPTY_STR}</Box>
                )}
                {credit ? (
                    <Box className="web-only" justify="end">
                        <DataNumber value={credit > 0 && credit} fixedDecimalScale isDebit={debit !== 0} />
                        {credit > 0 && <Image src={`${imageType}.svg`} />}
                    </Box>
                ) : (
                    <Box className="web-only">{EMPTY_STR}</Box>
                )}

                <Box className="web-only" justify="end">
                    <DataNumber value={balance} />
                </Box>

                <Box className="web-only" justify="center">
                    <Button onClick={(e) => handleClick(e, true, movements[index], account)}>
                        <Image src={`${noteImageType}.svg`} />
                    </Button>
                </Box>
            </GridLayout>
        );
    };

    const recordsFound = movementsItems.length > 0;

    const render = () => (
        <React.Fragment>
            <div className="filter-print">
                <strong>{i18n.latestMovements}</strong>
                {filters.dateFrom && filters.dateTo && (
                    <I18n
                        className="filter-date"
                        id="accounts.movements.filters.period"
                        DATE_FROM={UtilDate.specificDate(filters.dateFrom)}
                        DATE_TO={UtilDate.specificDate(filters.dateTo)}
                    />
                )}
            </div>
            <Filters idAccount={account.idProduct} />
            <GridLayout className="header">
                {COLUMN_HEADINGS.map((heading, index) => (
                    <Box justify={index === 3 || index === 4 || index === 5 ? "end" : EMPTY_STR}>{heading}</Box>
                ))}
            </GridLayout>
            {recordsFound ? (
                movementsItems.map((movement, index) => {
                    return renderMovement(movement, index);
                })
            ) : (
                <Note value={noRecords} className="no-more-movements" />
            )}
            {movements && movements.length > 0 && (
                <div className="total-law" key="total-law">
                    <Box>
                        {totalLaw19210}
                        <DataNumber value={totalLaw} prefix={CDP_CURRENCY.UYU} />
                    </Box>
                </div>
            )}
            {isFetchingMovements && <Note value={loading} />}
            {!isFetchingMovements && hasMore && (
                <Note
                    intersection-trigger="true"
                    key="more"
                    value={labelMore}
                    onClick={() => handleMovementsFetch({ isIntersecting: true })}
                />
            )}
            {!isFetchingMovements && !hasMore && recordsFound && (
                <Note value={labelNoMore} className="no-more-movements" />
            )}
            <Modal
                show={showNoteModal}
                closeButton
                onClose={(e) => handleClick(e, false)}
                labelKey="accounts.note.modal.title">
                <EditStatementNote />
            </Modal>
            <Modal show={showRatesModal} closeButton onClose={handleCloseRates} labelKey="accounts.rates.title">
                <RatesList />
            </Modal>
            <Modal
                show={showMulticashModal}
                closeButton
                onClose={handleCloseMulticash}
                labelKey="client.accounts.export.multicash.title"
                backdrop="static">
                <Multicash onSubmitForm={handleCloseMulticash} />
            </Modal>
        </React.Fragment>
    );

    return isMobile ? (
        <IntersectionObserver onIntersect={handleMovementsFetch}>{render()}</IntersectionObserver>
    ) : (
        render()
    );

    function handleMovementsFetch(ev) {
        const { isIntersecting } = ev;

        if (!isIntersecting) {
            return;
        }

        const fetchFilters = { ...filters, pageNumber };

        dispatch(
            SelectorsActionAccount.fetchMoreMovements({
                idAccount,
                filters: fetchFilters,
            }),
        );
    }

    // TODO: Quitar utilizar el componente
    function Note({ value, ...rest }) {
        return (
            <Element type={TYPE_NOTE} name={NAME_NOTE} tag="span" {...rest}>
                {value}
            </Element>
        );
    }

    function handleClick(e, showModal, selectedMovement, selectedAccount) {
        if (showModal) {
            e.stopPropagation();

            dispatch(SelectorsActionAccount.setSelectedMovement({ selectedMovement }));

            const idProduct = selectedAccount && selectedAccount.idProduct ? selectedAccount.idProduct : null;
            const idStatement = selectedMovement && selectedMovement.idStatement ? selectedMovement.idStatement : null;

            dispatch(SelectorsActionAccount.readMovementNote({ idProduct, idStatement }));
        } else {
            dispatch(SelectorsActionAccount.setShowNoteModal({ showNoteModal: showModal }));
        }
    }

    function handleCloseMulticash() {
        dispatch(SelectorsActionAccount.setShowMulticashModal({ showMulticashModal: false }));
    }

    function handleCloseRates() {
        dispatch(SelectorsActionAccount.setShowRatesModal({ showRatesModal: false }));
    }
}

const mapStateToProps = (store) => ({
    account: SelectorsStoreAccount.getSelectedAccount(store),
    accountCurrency: SelectorsStoreAccount.getCurrency(store),
    fetching: SelectorsStoreAccount.getFetching(store),
    filters: SelectorsStoreAccount.getFilters(store),
    hasMore: SelectorsStoreAccount.getMoreMovements(store),
    i18n: {
        accountingDate: StoreI18n.getMessage(store, "accounts.movements.table.columnHeading.accountingDate"),
        balance: StoreI18n.getMessage(store, "accounts.movements.table.columnHeading.balance"),
        concept: StoreI18n.getMessage(store, "accounts.movements.table.columnHeading.concept"),
        credit: StoreI18n.getMessage(store, "accounts.movements.table.columnHeading.credit"),
        dateValue: StoreI18n.getMessage(store, "accounts.movement.detail.valueDate"),
        debit: StoreI18n.getMessage(store, "accounts.movements.table.columnHeading.debit"),
        labelMore: StoreI18n.getMessage(store, "accounts.movements.moreMovements"),
        labelNoMore: StoreI18n.getMessage(store, "accounts.movements.noMoreMovements"),
        latestMovements: StoreI18n.getMessage(store, "swift.list.filter.periodOptions.lastMovements"),
        loading: StoreI18n.getMessage(store, "global.loading"),
        noRecords: StoreI18n.getMessage(store, "global.noRecords"),
        notAvailable: StoreI18n.getMessage(store, "data.notAvailable.short"),
        prefixCheck: StoreI18n.getMessage(store, "accounts.movements.table.prefix.checkNumber"),
        prefixReference: StoreI18n.getMessage(store, "accounts.movements.table.prefix.reference"),
        reference: StoreI18n.getMessage(store, "accounts.movement.detail.reference"),
        referenceCheck: StoreI18n.getMessage(store, "accounts.movements.table.columnHeading.referenceCheck"),
        totalLaw19210: StoreI18n.getMessage(store, "accounts.movements.note.law19210"),
    },
    isFetchingMovements: SelectorsStoreAccount.getFetchingMovements(store),
    movements: SelectorsStoreAccount.getMovements(store),
    pageNumber: SelectorsStoreAccount.getPageNumber(store),
    showMulticashModal: SelectorsStoreAccount.getShowMulticashModal(store),
    showNoteModal: SelectorsStoreAccount.getShowNoteModal(store),
    showRatesModal: SelectorsStoreAccount.getShowRateModal(store),
    toShow: SelectorsStoreAccount.getFiltersPersonalized(store),
    totalLaw: SelectorsStoreAccount.getTotalLaw(store),
});

export default Connect(mapStateToProps)(HighOrder.Resizable(Component));
