import React from "react";

import PropTypes from "prop-types";
import { Col, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";

import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { SelectorsAction as SelectorsActionSettings, SelectorsStore as SelectorsStoreSettings } from "~/store/settings";
import { getArray } from "~/util/config";

import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Head from "~/pages/_components/Head";
import MainContainer from "~/pages/_components/MainContainer";
import PageLoading from "~/pages/_components/PageLoading";

export const NAME = "ChangeLanguage";
export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        fetching: PropTypes.bool.isRequired,
        activeLanguage: PropTypes.string.isRequired,
    },
    defaults: {},
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        supportedLanguages: getArray("frontend.i18n.languages"),
    };

    handleClick = (language) => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionSettings.changeLanguage({ language }));
    };

    getContent() {
        const { activeLanguage } = this.props;
        const { supportedLanguages } = this.state;

        return (
            <form className="above-the-fold">
                <section className="container--layout">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-lead">
                                    <I18n id="settings.changeLanguage.title" />
                                </p>
                            </Col>
                        </Row>
                    </Grid>
                </section>

                <section className="container--layout flex-grow align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <div className="form-group">
                                    <div className="form-check-group check-list">
                                        {Object.keys(supportedLanguages).map((idLanguage) => (
                                            <div
                                                className="c-control c-control--has-icon c-control--radio"
                                                key={`language-${idLanguage}`}>
                                                <input
                                                    defaultChecked={
                                                        supportedLanguages[idLanguage] === activeLanguage
                                                            ? "checked"
                                                            : ""
                                                    }
                                                    id={`language-${supportedLanguages[idLanguage]}`}
                                                    type="radio"
                                                    name="language"
                                                    className="c-control-input"
                                                    onClick={() => this.handleClick(supportedLanguages[idLanguage])}
                                                />
                                                <label
                                                    className="c-control-label"
                                                    htmlFor={`language-${supportedLanguages[idLanguage]}`}>
                                                    <div className="c-control-icons">
                                                        <div className="c-control-mark">
                                                            <Image src="check.svg" className="svg-icon svg-caret" />
                                                        </div>
                                                    </div>

                                                    <I18n
                                                        id={`settings.changeLanguage.label.${supportedLanguages[idLanguage]}`}
                                                    />
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </form>
        );
    }

    render() {
        const { fetching } = this.props;
        return (
            <React.Fragment>
                <Head title="settings.changeLanguage" closeLinkTo="/settings" />
                <PageLoading loading={fetching}>
                    <MainContainer>{this.getContent()}</MainContainer>
                </PageLoading>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    activeLanguage: SelectorsStoreI18n.getLang(state),
    fetching: SelectorsStoreSettings.getFetching(state),
});

export default connect(mapStateToProps)(Component);
