import React from "react";

import { goBack as GoBack } from "connected-react-router";
import PropTypes from "prop-types";
import { Grid, Row, Col } from "react-bootstrap";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import {
    SelectorsStore as CustomsPaymentSelectors,
    SelectorsAction as CustomPaymentActions,
} from "~/store/customsPayment";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import FormPreview from "./Step2Preview/Step2Preview";
import FormView from "./Step3View/Step3View";

export const NAME = "CustomsPaymentReadTicket";

export const PROP = {
    types: {
        fetching: PropTypes.bool.isRequired,
        isChangingEnvironment: PropTypes.bool,
        ...TypesRedux,
    },
    defaults: {
        i18n: {},
        isChangingEnvironment: false,
        ...DefaultsRedux,
    },
};

const FORM_ID = "payments.customsPayment.step3";

export class Component extends React.Component {
    componentDidMount() {
        const { dispatch, isChangingEnvironment, match } = this.props;
        const { idTransaction } = match.params ? match.params : {};

        if (idTransaction && !isChangingEnvironment) {
            dispatch(CustomPaymentActions.loadTicketRequest({ idTransaction }));
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(GoBack());
    };

    render() {
        const { fetching, transaction, mode } = this.props;
        const { data } = transaction;
        return (
            <Container
                name={NAME}
                wait={fetching}
                step={mode}
                head-title={UtilsI18n.get("payments.customsPayment.title")}
                head-onBack={this.handleBack}
                head-onBackWeb={this.handleBack}
                scopeToShowNotification={SCOPE.CUSTOMS_PAYMENT}>
                {mode === MODE.PREVIEW && <FormPreview transaction={transaction} />}
                {transaction.data && mode === MODE.VIEW && <FormView transaction={transaction} />}
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    fetching: CustomsPaymentSelectors.getFetching(store),
    i18n: {},
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    transaction: CustomsPaymentSelectors.getTransaction(store),
    mode: CustomsPaymentSelectors.getMode(store),
});

export default Connect(mapStateToProps)(Component);
