import React from "react";

import PropTypes from "prop-types";

import I18n from "~/components/I18n";
import Image from "~/components/Image";

export const NAME = "NoResults";

export const PROP = {
    types: { message: PropTypes.string.isRequired },
    defaults: {},
};

export function Component(props) {
    const { message } = props;
    return (
        <div className="text-center no-more-data">
            <div className="illustration-wrapper">
                <Image src="coloredIcons/mailbox-empty.svg" className="svg-big-icon" />
            </div>
            <p className="text-lead">
                <I18n id={message} />
            </p>
        </div>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
