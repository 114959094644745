import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, TYPE, PATH } from "./_consts";

export const SelectorsMiddleware = {
    communicationTraysListRequest: () => API.executeWithAccessToken(PATH.COMMUNICATIONS_SEND_PRE),
    detailRequest: (props) => API.executeWithAccessToken(PATH.COMMUNICATIONS_READ, props),
    latestCommunications: () => API.executeWithAccessToken(PATH.LATEST_COMMUNICATIONS),
    list: (props) => API.executeWithAccessToken(PATH.COMMUNICATIONS_LIST, props),
    listTypes: () => API.executeWithAccessToken(PATH.COMMUNICATIONS_LIST_PRE),
    markAsReadRequest: (props) => API.executeWithAccessToken(PATH.COMMUNICATIONS_MARK_READ, props),
    markAsUnReadRequest: (props) => API.executeWithAccessToken(PATH.COMMUNICATIONS_MARK_UNREAD, props),
    markListAsReadRequest: (props) => API.executeWithAccessToken(PATH.COMMUNICATIONS_MARK_LIST_READ, props),
    replyRequest: (props) => API.executeWithAccessToken(PATH.COMMUNICATIONS_REPLY, props),
    sendRequest: (props) => API.executeWithAccessToken(PATH.COMMUNICATIONS_SEND, props),
};

export const SelectorsAction = {
    addMessageFech: () => FactoryAction(TYPE.IS_FETCHING_ADD_FECHT),
    addMessageSucces: () => FactoryAction(TYPE.IS_FETCHING_ADD_SUCCES),
    communicationsTrayslistFailure: () => FactoryAction(TYPE.COMMUNICATION_TRAYS_LIST_FAILURE),
    communicationsTrayslistRequest: () => FactoryAction(TYPE.COMMUNICATION_TRAYS_LIST_REQUEST),
    communicationsTrayslistSuccess: (props) => FactoryAction(TYPE.COMMUNICATION_TRAYS_LIST_SUCCESS, props),
    dataToNewMessage: (props) => FactoryAction(TYPE.DATA_TO_NEW_MESSAGE, props),
    detailFailure: () => FactoryAction(TYPE.DETAIL_FAILURE),
    detailRequest: (props) => FactoryAction(TYPE.DETAIL_REQUEST, props),
    detailSuccess: (props) => FactoryAction(TYPE.DETAIL_SUCCESS, props),
    downloadAttachmentFailure: () => FactoryAction(TYPE.DOWNLOAD_ATTACHMENT_FAILURE),
    downloadAttachmentRequest: (props) => FactoryAction(TYPE.DOWNLOAD_ATTACHMENT_REQUEST, props),
    downloadAttachmentSuccess: () => FactoryAction(TYPE.DOWNLOAD_ATTACHMENT_SUCCESS),
    fetchMoreFailure: () => FactoryAction(TYPE.FETCH_MORE_FAILURE),
    fetchMoreRequest: (props) => FactoryAction(TYPE.FETCH_MORE_REQUEST, props),
    fetchMoreSuccess: (props) => FactoryAction(TYPE.FETCH_MORE_SUCCESS, props),
    hideAllPanels: () => FactoryAction(TYPE.LEFT_PANEL_HIDE),
    latestCommunicationSuccess: (props) => FactoryAction(TYPE.LATEST_COMMUNICATION_SUCCESS, props),
    listFailure: () => FactoryAction(TYPE.LIST_FAILURE),
    listRequest: (props) => FactoryAction(TYPE.LIST_REQUEST, props),
    listSuccess: (props) => FactoryAction(TYPE.LIST_SUCCESS, props),
    listTypesFailure: () => FactoryAction(TYPE.LIST_TYPES_FAILURE),
    listTypesRequest: () => FactoryAction(TYPE.LIST_TYPES_REQUEST),
    listTypesSuccess: (props) => FactoryAction(TYPE.LIST_TYPES_SUCCESS, props),
    markAsReadFailure: () => FactoryAction(TYPE.MARK_AS_READ_FAILURE),
    markAsReadRequest: (props) => FactoryAction(TYPE.MARK_AS_READ_REQUEST, props),
    markAsReadSuccess: () => FactoryAction(TYPE.MARK_AS_READ_SUCCESS),
    markAsUnReadFailure: () => FactoryAction(TYPE.MARK_AS_UNREAD_FAILURE),
    markAsUnReadRequest: (props) => FactoryAction(TYPE.MARK_AS_UNREAD_REQUEST, props),
    markAsUnReadSuccess: () => FactoryAction(TYPE.MARK_AS_UNREAD_SUCCESS),
    markListAsReadFailure: () => FactoryAction(TYPE.MARK_LIST_AS_READ_FAILURE),
    markListAsReadRequest: (props) => FactoryAction(TYPE.MARK_LIST_AS_READ_REQUEST, props),
    markListAsReadSuccess: (props) => FactoryAction(TYPE.MARK_LIST_AS_READ_SUCCESS, props),
    preloadedMessage: (props) => FactoryAction(TYPE.PRELOADED_MESSAGE, props),
    removeFromTray: (props) => FactoryAction(TYPE.REMOVE_FROM_TRAY, props),
    removeSelectedAttachment: (props) => FactoryAction(TYPE.REMOVE_ATTACHMENT, props),
    replyFailure: () => FactoryAction(TYPE.REPLY_FAILURE),
    replyRequest: (props) => FactoryAction(TYPE.REPLY_REQUEST, props),
    replySuccess: () => FactoryAction(TYPE.REPLY_SUCCESS),
    resetDataToNewMessage: () => FactoryAction(TYPE.RESET_DATA_TO_NEW_MESSAGE),
    selectAttachment: (props) => FactoryAction(TYPE.SELECT_ATTACHMENT, props),
    selectedMessage: () => FactoryAction(TYPE.LOADING),
    sendRequest: (props) => FactoryAction(TYPE.SEND_REQUEST, props),
    sendSuccess: () => FactoryAction(TYPE.SEND_SUCCESS),
    setActiveTray: (props) => FactoryAction(TYPE.SET_ACTIVE_TRAY, props),
    setAttachment: (props) => FactoryAction(TYPE.SET_ATTACHMENT, props),
    setListFilters: (props) => FactoryAction(TYPE.LIST_FILTERS, props),
    showComposePanel: (props) => FactoryAction(TYPE.LEFT_PANEL_COMPOSE, props),
    showReadPanel: () => FactoryAction(TYPE.LEFT_PANEL_READ),
    showReplyPanel: () => FactoryAction(TYPE.LEFT_PANEL_REPLY),
    toggleMessageStatus: (props) => FactoryAction(TYPE.TOGGLE_MESSAGE_STATUS, props),
};

export const SelectorsStore = {
    attachments: (store) => store[NAME].attachments,
    communicationTraysList: (store) => store[NAME].communicationTraysList,
    currentPage: (store) => store[NAME].currentPage,
    detail: (store) => store[NAME].detail,
    getActiveTray: (store) => store[NAME].activeTray,
    getDataToNewMessage: (store) => store[NAME].dataToNewMessage,
    getIsAddMessage: (store) => store[NAME].isFetchingAdd,
    getListFilters: (store) => store[NAME].listFilters,
    getPreloadedMessage: (store) => store[NAME].preloadedMessage,
    getUnreadCommunications: (store) => store[NAME].unreadCommunications,
    isFetching: (store) => store[NAME].isFetching,
    leftPanel: (store) => store[NAME].leftPanel,
    list: (store) => store[NAME].list,
    listTypes: (store) => store[NAME].types,
    selectedAttachments: (store) => store[NAME].selectedAttachments,
    totalPages: (store) => store[NAME].totalPages,
};
