import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    listRequest: (props) => {
        const { widget, ...rest } = props;
        return API.executeWithAccessToken(`${PATH.WIDGETS}${widget}`, rest);
    },

    pdfPosition: () => API.executeWithAccessToken(PATH.POSITION_PDF_DOWNLOAD),
};

export const SelectorsAction = {
    listRequest: (props) => FactoryAction(TYPE.LIST_REQUEST, props),
    listFailure: (props) => FactoryAction(TYPE.LIST_FAILURE, props),
    listSuccess: (props) => FactoryAction(TYPE.LIST_SUCCESS, props),
    deleteWidget: (props) => FactoryAction(TYPE.DELETE_WIDGET, props),
    downloadPdf: (props) => FactoryAction(TYPE.DOWNLOAD_POSITION, props),
    pdfSuccess: (props) => FactoryAction(TYPE.DOWNLOAD_SUCCESS, props),
    pdfFailure: (props) => FactoryAction(TYPE.DOWNLOAD_POSITION_FAILURE, props),
};

function objectKeysToLower(object = {}) {
    const entries = Object.entries(object);
    return entries.reduce((acc, [key, val]) => ({ ...acc, [key.toLowerCase()]: val }), {});
}

export const SelectorsStore = {
    getWidget: (store, name) => {
        const id = name.toLowerCase();
        const widget = objectKeysToLower(store.widgets)[id];
        const data = objectKeysToLower(widget.data)[id] || [];
        return { ...widget, data };
    },
    getWidgetAccountsCC: (store) => store[NAME].accounts.data.amount_CC,
    getFetching: (store) => store[NAME].position.fetching,
};
