import React from "react";

import { Form, Field, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import { EMPTY_STR, HSBC, BROU } from "~/constants";
import { MODE } from "~/constants/form";
import {
    PROP as PROP_TRANSACTIONLINES,
    SelectorsAction as SelectorActionsTransactionLines,
    SelectorsStore as SelectorsStoreTransactionLines,
} from "~/store/transactionLines";
import { getArray, getInteger } from "~/util/config";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button/Button";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import Select from "~/pages/_components/fields/Select";
import TextField from "~/pages/_components/fields/TextField";
import AmountField from "~/pages/_components/fields/formik/AmountField";

import Style from "./SalaryPaymentForm.rules.scss";

const FORM_ID = "salaryPayment.manual";

export const NAME = "SalaryPaymentLinesForm";

export const PROP = {
    types: {
        ...TypesRedux,
        localBanks: PropTypes.array.isRequired,
        transactionLines: PropTypes.array.isRequired,
        initialValues: PropTypes.shape({
            address: PropTypes.string,
            credit: PropTypes.shape({
                amount: PropTypes.string.isRequired,
                currency: PropTypes.string.isRequired,
            }),
            creditAccountName: PropTypes.string.isRequired,
            creditAccountNumber: PropTypes.string.isRequired,
        }).isRequired,
        onAdd: PropTypes.func.isRequired,
        onEdit: PropTypes.func.isRequired,
        paymentCurrency: PropTypes.string.isRequired,
        submitButtonLabel: PropTypes.string,
    },
    defaults: {
        ...DefaultsRedux,
        transactionLines: [],
        localBanks: null,
        submitButtonLabel: "global.accept",
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    numInput = React.createRef();

    componentDidMount() {
        const { dispatch, localBanks } = this.props;

        if (localBanks.length === 0) {
            dispatch(SelectorActionsTransactionLines.listLocalBanksRequest());
        }

        this.numInput.current.focus();
    }

    setCreditAccountName() {
        const { values } = this.props;
        const { creditAccountBank, creditAccountName, creditAccountNumber } = values;
        const isHSBC = creditAccountBank === HSBC;
        let response = EMPTY_STR;

        if (!isHSBC) {
            response = creditAccountName;
        } else if (isHSBC && creditAccountNumber.length === getInteger("account.hsbc.length")) {
            response = UtilsI18n.get(`${FORM_ID}.creditAccountName.mask`);
        }

        return response;
    }

    render() {
        const { localBanks, paymentCurrency, submitButtonLabel, values } = this.props;
        const { address, credit, creditAccountBank, creditAccountNumber } = values;
        const isHSBC = creditAccountBank === HSBC;

        return (
            <Form className={Style.CLASS}>
                <div className="form-group">
                    <FieldLabel labelKey={`${FORM_ID}.creditAccountBank.label`} />
                    <Select
                        className="flex-container slideFromBottom"
                        id="creditAccountBank"
                        idForm={FORM_ID}
                        name="creditAccountBank"
                        options={localBanks}
                        searchable
                        value={creditAccountBank}
                    />
                </div>

                {!isHSBC && (
                    <Field
                        component={FieldLabel}
                        idForm={FORM_ID}
                        labelKey="salaryPayment.manual.internalBank.disclaimer"
                        mode={MODE.VIEW}
                        name="disclaimer"
                    />
                )}

                <Field
                    autoComplete="off"
                    component={TextField}
                    id="creditAccountNumber"
                    idForm={FORM_ID}
                    inputRef={this.numInput}
                    maxLength={isHSBC ? getInteger("account.hsbc.length") : getInteger("account.external.length")}
                    name="creditAccountNumber"
                    placeholder={EMPTY_STR}
                    tooltip={!isHSBC ? UtilsI18n.get("client.pay.addPayment.account.tooltip") : undefined}
                    value={creditAccountNumber}
                />

                <Field
                    component={TextField}
                    idForm={FORM_ID}
                    mode={isHSBC ? MODE.VIEW : MODE.EDIT}
                    name="creditAccountName"
                    placeholder={EMPTY_STR}
                    value={this.setCreditAccountName()}
                    maxLength={35}
                    hidelabel={isHSBC && creditAccountNumber.length < getInteger("account.hsbc.length")}
                />

                {!isHSBC && (
                    <Field
                        component={TextField}
                        idForm={FORM_ID}
                        name="address"
                        placeholder={EMPTY_STR}
                        value={address}
                        maxLength={35}
                        optional={UtilsI18n.get("form.field.optional")}
                    />
                )}

                <Field
                    clearable={false}
                    component={AmountField}
                    data={{
                        options: [
                            {
                                id: paymentCurrency,
                                label: UtilsI18n.get(`currency.label.${paymentCurrency}`),
                            },
                        ],
                    }}
                    idForm={FORM_ID}
                    maxLength={getInteger("amount.length")}
                    name="credit"
                    placeholder={EMPTY_STR}
                    searchable={false}
                    value={credit}
                    decimalPlaces={2}
                    fixedDecimalScale
                />

                <div className="form-group">
                    <Button block type="submit" variant="primary">
                        {UtilsI18n.get(submitButtonLabel)}
                    </Button>
                </div>
            </Form>
        );
    }
}

const mapStateToProps = (store) => ({
    lineNumber: SelectorsStoreTransactionLines.getSelectedLine(store),
    localBanks: SelectorsStoreTransactionLines.getLocalBanks(store),
    transactionLines: SelectorsStoreTransactionLines.getTransactionLines(store),
    paymentCurrency: SelectorsStoreTransactionLines.getCurrency(store),
    selectedItem: SelectorsStoreTransactionLines.getSelectedItem(store) || PROP_TRANSACTIONLINES.initialValues,
});

function createSchema(props) {
    const {
        transactionLines,
        selectedItem: { lineNumber },
    } = props;
    return Yup.lazy((values) =>
        Yup.object().shape({
            creditAccountBank: Yup.string()
                .trim()
                .required(UtilsI18n.get(`${FORM_ID}.creditAccountBank.required.error`)),
            creditAccountNumber: Yup.string()
                .trim()
                .matches(/^\d+$/, UtilsI18n.get(`${FORM_ID}.creditAccountNumber.hsbc.number.error`))
                .test("exists", UtilsI18n.get(`${FORM_ID}.creditAccountNumber.exists.error`), (value) => {
                    const lineWithAccountNumber = transactionLines.find(
                        (line) =>
                            line.creditAccountNumber === value && line.creditAccountBank === values.creditAccountBank,
                    );
                    return !lineWithAccountNumber || (lineNumber && lineWithAccountNumber.lineNumber === lineNumber);
                })
                .when("creditAccountBank", {
                    is: (value) => value === HSBC,
                    then: Yup.string()
                        .min(1, UtilsI18n.get(`${FORM_ID}.creditAccountNumber.hsbc.min.error`))
                        .max(
                            getInteger("account.hsbc.length"),
                            UtilsI18n.get(`${FORM_ID}.creditAccountNumber.hsbc.min.error`),
                        ),
                })
                .when("creditAccountBank", {
                    is: (value) => value === BROU,
                    then: Yup.string().matches(
                        new RegExp(
                            `^([0-9]{${getArray("client.allowed.accountLengths.brou")[0]}}|[0-9]{${
                                getArray("client.allowed.accountLengths.brou")[1]
                            }})$`,
                        ),
                        UtilsI18n.get(`pay.multiline.error.212`),
                    ),
                })
                .required(UtilsI18n.get(`${FORM_ID}.creditAccountNumber.required.error`)),
            credit: Yup.object().shape({
                currency: Yup.string().required(),
                amount: Yup.number()
                    .typeError(UtilsI18n.get(`${FORM_ID}.credit.required.error`))
                    .min(0.0, UtilsI18n.get(`${FORM_ID}.credit.positive.error`)),
            }),
            creditAccountName: Yup.string()
                .trim()
                .when("creditAccountBank", {
                    is: (value) => value !== HSBC,
                    then: Yup.string().required(UtilsI18n.get(`${FORM_ID}.creditAccountName.required.error`)),
                }),
        }),
    );
}

export default Compose(
    Connect(mapStateToProps),
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: ({
            selectedItem: {
                address: selectedAddress,
                creditAccountBank: selectedBank,
                creditAccountName: selectedName,
                creditAccountNumber: selectedAccount,
                creditAmountQuantity: selectedAmount,
            },
            localBanks,
            paymentCurrency,
        }) => ({
            address: selectedAddress,
            credit: {
                amount: selectedAmount || 0.0,
                currency: paymentCurrency,
            },
            creditAccountBank: selectedBank || (localBanks && localBanks.length > 0 ? localBanks[0].value : EMPTY_STR),
            creditAccountName: selectedName,
            creditAccountNumber: `${selectedAccount}`,
        }),
        validationSchema: (props) => createSchema(props),
        handleSubmit: (props, formikBag) => {
            const {
                props: {
                    localBanks,
                    onAdd,
                    onEdit,
                    selectedItem: { lineNumber },
                },
            } = formikBag;

            const {
                address,
                credit: { currency: creditAmountCurrency, amount: creditAmountQuantity },
                creditAccountBank,
                creditAccountName,
                creditAccountNumber,
            } = props;
            const amount = creditAmountQuantity !== EMPTY_STR ? creditAmountQuantity : 0;

            const paymentLine = {
                creditAccountNumber,
                creditAccountName,
                creditAmountCurrency,
                creditAmountQuantity: amount,
                creditAccountBank,
                creditAccountBankShortLabel: localBanks.find((bank) => bank.value === creditAccountBank)?.shortLabel,
                address: creditAccountBank !== HSBC ? address : null,
            };

            if (lineNumber) {
                onEdit({
                    paymentLine: {
                        ...paymentLine,
                        lineNumber,
                    },
                });
            } else {
                onAdd({ paymentLine });
            }
        },
    }),
)(Component);
