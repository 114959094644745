import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { SelectorsStore as SelectorsStoreWMBonds, SelectorsAction as SelectorsActionWMBonds } from "~/store/wm/bonds";
import * as UtilsI18n from "~/util/i18n";
import * as UtilsNumber from "~/util/number";

import DataDate from "~/components/DataDate";
import DataNumber from "~/components/DataNumber";
import I18n from "~/components/I18n";
import Ticket from "~/components/Ticket/Ticket";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

const FORM_ID = "wm.bonds.sell.send";

export function Component(props) {
    const { lang, ticketConfirmation, transaction } = props;
    const { data } = transaction;
    const {
        currency,
        debitAccountAlias,
        expirationDate,
        isin,
        limitDate,
        nominalValue,
        orderCompleted,
        paperName,
        price,
        rate,
    } = data;

    const rateFixed = UtilsNumber.numberToLocaleFormat(rate, lang);

    const orderValid = orderCompleted ? (
        UtilsI18n.get(`${FORM_ID}.orderCompleted.label`)
    ) : (
        <DataDate value={limitDate} />
    );

    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    hasMobileComponent
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}>
                    <div className="data-section">
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="client.wm.bonds.buy.step3.bondToBuy" />
                            </span>
                            <span className="data-text">{paperName}</span>
                        </div>

                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="client.wm.bonds.buy.step3.isin" />
                            </span>
                            <span className="data-text">{isin}</span>
                        </div>

                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="client.wm.bonds.buy.step3.coupon" />
                            </span>
                            <span className="data-text">{rateFixed}%</span>
                        </div>

                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="client.wm.bonds.buy.step3.expirationDate" />
                            </span>
                            <span className="data-text">{expirationDate}</span>
                        </div>

                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="client.wm.bonds.buy.step3.price" />
                            </span>
                            <span className="data-text">
                                {UtilsI18n.get(`client.wm.bonds.buy.step3.price.${price}`)}
                            </span>
                        </div>

                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="client.wm.bonds.buy.step3.nominalValue" />
                            </span>
                            <span className="data-name">
                                <DataNumber value={nominalValue} prefix={currency} className="format-currency" />
                            </span>
                        </div>

                        <div className="data-wrapper ">
                            <span className="data-label">
                                <I18n id="client.wm.bonds.buy.step3.debitAccount" />
                            </span>
                            <span className="data-name name d-block">{debitAccountAlias} </span>
                        </div>
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="wm.allInvestments.orderValidUntil.label" />
                            </span>
                            <span className="data-name">{orderValid}</span>
                        </div>
                    </div>
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { dispatch } = props;
        const { idTransaction } = transaction;

        dispatch(
            SelectorsActionWMBonds.cancelTransaction({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        const { dispatch } = props;

        dispatch(SelectorsActionWMBonds.signTransactionPreview({ ...data }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        lang: SelectorsStoreI18n.getLang(store),
        transaction: SelectorsStoreWMBonds.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
