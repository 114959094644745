import React from "react";

import ClassNames from "classnames";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import ReactTagsInput from "react-tagsinput";

import { EMPTY_STR, PIPE_SEPARATOR, SPACE_STR } from "~/constants";
import * as UtilsI18n from "~/util/i18n";

import FieldError from "~/pages/_components/fields/FieldError";

import Style from "./SuggestionsTagsInput.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        suggestionsList: PropTypes.arrayOf(PropTypes.object).isRequired,
        tooltip: PropTypes.string,
        isFocused: PropTypes.bool,
    },
    defaults: {
        suggestionsList: [],
        tooltip: false,
        isFocused: false,
    },
};

export function Component(props) {
    const {
        field,
        fieldTags,
        form: { touched, errors },
        hideError,
        isFocused,
        showEmptyError,
        suggestionsList,
        tooltip,
    } = props;
    const [state, setState] = React.useState({ tags: [] });
    const [valueInput, setValueInput] = React.useState(EMPTY_STR);

    suggestionsList.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
            return -1;
        }

        if (nameA > nameB) {
            return 1;
        }

        return 0;
    });

    const filterList = () => {
        const { tags } = state;
        const filteredList = [];

        if (tags?.length > 0) {
            const filterTags = suggestionsList.filter(
                (listItem) => listItem.name !== tags.find((tag) => tag === listItem.name),
            );
            filteredList.push(...filterTags);
        } else if (fieldTags?.length > 0) {
            const filterFieldTags = suggestionsList.filter(
                (listItem2) => listItem2.name !== fieldTags.find((fieldTag) => fieldTag === listItem2.name),
            );
            filteredList.push(...filterFieldTags);
        } else {
            filteredList.push(...suggestionsList);
        }

        return filteredList;
    };

    const handleChange = (tags) => {
        const { onChange } = props;

        if (onChange) {
            onChange(tags);
        }

        setState({ tags });
    };

    const shouldRenderSuggestions = (value) => {
        return value && value.length > 0 && value.replace(/\s+/g, EMPTY_STR).toLowerCase();
    };

    const removeTicks = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, EMPTY_STR);
    };

    const autocompleteRenderInput = ({ addTag, ...rest }) => {
        const { ref, value } = rest;
        const handleOnChange = (e, { method }) => {
            if (method === "enter") {
                e.preventDefault();
            } else {
                rest.onChange(e);
            }
        };

        const inputValue = (value && value.toLowerCase().replace(/\s+/g, EMPTY_STR)) || EMPTY_STR;
        removeTicks(inputValue);
        const inputLength = inputValue.length;
        setValueInput(inputValue);

        const suggestions = filterList().filter((item) => {
            return (
                removeTicks(item.name).toLowerCase().replace(/\s+/g, EMPTY_STR).slice(0, inputLength) ===
                removeTicks(valueInput).toLowerCase().replace(/\s+/g, EMPTY_STR)
            );
        });

        return (
            <Autosuggest
                ref={ref}
                suggestions={suggestions}
                shouldRenderSuggestions={shouldRenderSuggestions}
                getSuggestionValue={(suggestion) =>
                    removeTicks(suggestion.name).toLowerCase().replace(/\s+/g, EMPTY_STR)
                }
                renderSuggestion={(suggestion) => <span>{suggestion.name}</span>}
                inputProps={{
                    ...rest,
                    onChange: handleOnChange,
                    placeholder: UtilsI18n.get("creditCards.tripOrOnlinePurchaseNotification.country.placeholder"),
                }}
                onSuggestionSelected={(e, { suggestion }) => {
                    addTag(suggestion.name);
                }}
                onSuggestionsClearRequested={() => {}}
                onSuggestionsFetchRequested={() => {}}
            />
        );
    };

    const handlePaste = (pasteValue) => {
        return pasteValue
            .replace(/[\r\n,;]/g, SPACE_STR)
            .split(SPACE_STR)
            .map((d) => d.replace(/\s+/g, EMPTY_STR));
    };

    const renderTag = ({ tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other }) => (
        <span key={key} {...other}>
            <span>{getTagDisplayValue(tag)}</span>
            {!disabled && <button type="button" className={classNameRemove} onClick={() => onRemove(key)} />}
        </span>
    );

    const validationRegexList = () => {};

    const regexList = () => {
        return suggestionsList.map((elem) => elem.name).join(PIPE_SEPARATOR);
    };

    const hasError = showEmptyError
        ? touched[field.name] && Object.keys(errors).includes(field.name)
        : touched[field.name] && errors[field.name];

    return (
        <div name={Style.NAME} className={Style.CLASS}>
            <div className={`${hasError ? "has-error" : EMPTY_STR} ${isFocused ? "has-focus" : EMPTY_STR}`}>
                <div className={ClassNames("tagsInput", "input-tag-suggestion", { "has-tooltip": tooltip })}>
                    <ReactTagsInput
                        renderInput={autocompleteRenderInput}
                        value={fieldTags || state.tags}
                        onChange={handleChange}
                        renderTag={renderTag}
                        pasteSplit={handlePaste}
                        addOnPaste
                        onlyUnique
                        validationRegex={new RegExp(regexList())}
                        onValidationReject={validationRegexList}
                    />
                    {tooltip && (
                        <div className="tooltip tooltip-item">
                            <div className="tooltip-text">
                                <span>{tooltip}</span>
                            </div>
                        </div>
                    )}
                </div>
                {!hideError && hasError && <FieldError error={errors[field.name]} />}
            </div>
        </div>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
