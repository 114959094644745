import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";

import { LEVEL, SCOPE } from "~/constants";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as I18n from "~/util/i18n";

import Image from "~/components/Image";
import Link from "~/components/Link";

export const NAME = "FactoringItem";

export const PROP = {};
PROP.members = {
    /** propTypes for ContentTitle sub-component */
    typesContent: {
        /** Either an I18n identifier, or an instance of a Component to be rendered as title */
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    },
};

PROP.types = {
    /** The address where the Item will link when clicked */
    href: PropTypes.string,
    /**  An event handler for Item clicks */
    onClick: PropTypes.func,
    /**  Boolean prop to change style whether the item is selected or not */
    hasPermission: PropTypes.bool,
    /**  In case you want to show a different style */
    className: PropTypes.string,
    /** Type of transaction */
    type: PropTypes.string.isRequired,
    ...PROP.members.typesContent,
};
PROP.defaults = {
    onClick: null,
    href: null,
    hasPermission: false,
    className: "factoring-options-item",
    ...PROP.members.defaultsContent,
};

export function Component(props) {
    const { onClick, hasPermission, href, className, dispatch, ...propsContent } = props;

    const handleClick = (...args) => {
        if (!hasPermission) {
            dispatch(
                SelectorsActionNotification.showNotification({
                    message: I18n.get("tranfer.permission"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.FACTORING],
                }),
            );
        } else if (onClick) {
            onClick.apply(this, args);
        }
    };
    return (
        <li className={className}>
            {href && hasPermission && (
                <Link to={href} onClick={handleClick}>
                    <Component.Content {...propsContent} />
                </Link>
            )}
            {(!href || (href && !hasPermission)) && (
                <a onClick={handleClick}>
                    <Component.Content {...propsContent} />
                </a>
            )}
        </li>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

Component.Content = function Content(props) {
    const { title, image, description } = props;

    return (
        <div className="item-content">
            <div className="item-image">
                <Image src={`${image}.svg`} />
            </div>
            {React.isValidElement(title) ? title : <span className="item-text">{I18n.get(title)}</span>}
            {React.isValidElement(description) ? (
                description
            ) : (
                <span className="item-description">{I18n.get(description)}</span>
            )}
        </div>
    );
};
Component.Content.propTypes = PROP.members.typesContent;
Component.Content.defaultProps = PROP.members.defaultsContent;

export default WithRouter(Connect()(Component));
