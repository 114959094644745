import React from "react";

import { push as Push } from "connected-react-router";
import { Field, Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import { EMPTY_STR, SCOPE } from "~/constants";
import Container from "~/containers/Internal/Dash";
import { SelectorsAction, SelectorsStore } from "~/store/migration";
import * as I18nUtils from "~/util/i18n";
import { GetTwoCharCountryByISOCode, PhoneNumberUtil } from "~/util/phone";
import { Types as TypesHtmlElement, Defaults as DefaultsHtmlElement } from "~/util/prop/html-element";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import CountryCellPhoneField from "~/pages/_components/fields/CountryCellPhoneField";

import CancelConfirmationModal from "~/pages/enrollment/_CancelConfirmationModal";

const FORM_ID = "migration.check.mobilePhone";

export const NAME = "CheckMobilePhone";
const FIELD_NAME = "mobilePhone";

export const PROP = {
    types: {
        ...TypesHtmlElement,
        ...TypesRedux,
        mobileNumber: PropTypes.string,
        isSubmitting: PropTypes.bool,
    },
    defaults: {
        mobileNumber: "",
        ...DefaultsHtmlElement,
        ...DefaultsRedux,
        isSubmitting: false,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {
        show: false,
    };

    handleMigrationCancel = () => {
        const { dispatch } = this.props;
        dispatch(Push("/desktop"));
    };

    handleCancelation = () => {
        this.setState({ show: false });
    };

    showModal = () => {
        this.setState({ show: true });
    };

    render() {
        const { show } = this.state;
        const { fetching, isSubmitting } = this.props;

        return (
            <React.Fragment>
                <CancelConfirmationModal
                    show={show}
                    handleCancelation={this.handleCancelation}
                    handleConfirmation={this.handleMigrationCancel}
                />
                <Container
                    name={NAME}
                    head-onClose={this.showModal}
                    wait={fetching}
                    scopeToShowNotification={SCOPE.CHECK_MOBILE_PHONE}>
                    <div className="login-header">
                        <span className="login-header-text">
                            <I18n id="migration.title" />
                        </span>
                    </div>
                    <div className="login-content">
                        <div className="login-row main-row">
                            <Form className="login-form">
                                <div className="login-form-field">
                                    <div className="login-form-field-block">
                                        <div className="login-form-field-image">
                                            <Image src="phone-icon.svg" className="login-form-field-icon aster-icon" />
                                        </div>
                                        <Field
                                            idForm={FORM_ID}
                                            name="mobilePhone"
                                            type="text"
                                            className="login-form-input"
                                            component={CountryCellPhoneField}
                                            hideLabel
                                            hidePlaceholder={false}
                                            autoFocus={false}
                                            value={EMPTY_STR}
                                        />
                                    </div>
                                </div>
                                <span className="login-form-field-validation" />
                                <div className="login-form-field">
                                    <Button
                                        className="login-form-button main-button"
                                        bsStyle="primary"
                                        label="global.continue"
                                        loading={isSubmitting}
                                        type="submit"
                                    />
                                </div>
                                <div className="login-form-field">
                                    <Button
                                        className="login-form-button simple-button"
                                        label="global.cancel"
                                        onClick={this.handleMigrationCancel}
                                    />
                                </div>
                            </Form>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    fetching: SelectorsStore.fetching(store),
});

export default Compose(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            [FIELD_NAME]: {},
        }),
        validationSchema: () =>
            Yup.object().shape({
                [FIELD_NAME]: Yup.object().shape({
                    prefix: Yup.string().required(I18nUtils.get(`${FORM_ID}.mobilePhone.mustBeAPhoneNumber`)),
                    value: Yup.string().required(I18nUtils.get(`${FORM_ID}.mobilePhone.mustBeAPhoneNumber`)),
                    country: Yup.string(),
                }),
            }),
        handleSubmit: (props, formikBag) => {
            const { country, value } = props[FIELD_NAME];
            const countryCode = GetTwoCharCountryByISOCode(country);
            const mobilePhoneUtils = PhoneNumberUtil.parse(value, countryCode);
            const mobilePhone = `${mobilePhoneUtils.get$Value(1)}-${mobilePhoneUtils.get$Value(2)}`;
            const mobilePhoneClean = `${mobilePhoneUtils.get$Value(2)}`;

            formikBag.props.dispatch(SelectorsAction.checkMobilePhone({ mobilePhone, mobilePhoneClean, formikBag }));
        },
    }),
)(Component);
