import {
    SelectorsAction as SelectorsActionPermissions,
    SelectorsStore as SelectorsStorePermissions,
} from "~/store/administration/common/permissions";
import * as API from "~/store/api";

import { TYPE_ADMINISTRATION_LEVEL_SIMPLE_PERMISSION, PATH } from "./_consts";

export const SelectorsMiddleware = {
    loadPermissionsRequest: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_SIMPLE_READ_PERMISSIONS, props), // id
    updatePermissionsPreview: (props) =>
        API.executeWithAccessToken(PATH.ADMINISTRATION_SIMPLE_MODIFY_PERMISSIONS_PREVIEW, props), // data
    updatePermissionsRequest: (props) =>
        API.executeWithAccessToken(PATH.ADMINISTRATION_SIMPLE_MODIFY_PERMISSIONS_SEND, props), // data
};

export const SelectorsAction = { ...SelectorsActionPermissions(TYPE_ADMINISTRATION_LEVEL_SIMPLE_PERMISSION) };

export const SelectorsStore = { ...SelectorsStorePermissions };
