import { EMPTY_STR } from "~/constants";
import { buildUrl as BuildUrl, setHeaders as ApiMobileSetHeaders } from "~/store/apiMobile";
import { channel as Channel, getAxios as GetAxios } from "~/store/apiWeb";
import DeviceUtils from "~/util/device";

const HEADERS_COMMON_ACCEPT = "application/json, application/octet-stream, multipart/form-data";
const HEADERS_CONTENT_TYPE = "application/json, multipart/form-data";
const SERIALIZER = "multipart";

export const ServerOptions = (params, onRemoveFile, onFileProcess, etag) => ({
    load: null,
    fetch: null,
    // eslint-disable-next-line no-unused-vars
    restore: (uniqueFileId, load, errorr, progress, abort, headers) => {
        const axios = GetAxios();
        const { CancelToken } = axios;
        const source = CancelToken.source();

        axios.post("/files.downloadStream", { idFile: uniqueFileId }).then(
            (response) => {
                const { ...data } = response.data;
                const { file, fileName } = data.data;
                data.data.idFile = uniqueFileId;
                data.data.isRestored = true;
                load(new File([file], fileName));
                if (onFileProcess) {
                    onFileProcess(data);
                }
            },
            // eslint-disable-next-line no-unused-vars
            (error) => {
                onRemoveFile({ serverId: uniqueFileId });
                abort(); // el archivo a restaurar no existe
            },
        );

        return {
            abort: () => {
                source.cancel("Operation canceled by the user.");
                abort();
            },
        };
    },
    process: (fieldName, file, metadata, load, error, progress, abort) => {
        const apiUploadUrl = "/files.upload";
        const formData = new FormData();

        const mapExtraData = new Map();
        const EXTRA_DATA_PREFIX = "__";

        Object.keys(params).forEach((key) => {
            if (key.startsWith(EXTRA_DATA_PREFIX)) {
                mapExtraData.set(key.split(EXTRA_DATA_PREFIX)[1], params[key]);
            } else {
                formData.append(key, params[key]);
            }
        });

        formData.append("channel", Channel());

        formData.append("_file", file, file.name);

        if (mapExtraData.size > 0) {
            const tempExtraData = {};
            mapExtraData.forEach((value, key) => {
                tempExtraData[key] = value;
            });
            formData.append("extraData", JSON.stringify(tempExtraData));
        }

        const headers = {
            "If-None-Match": etag || EMPTY_STR,
        };

        if (DeviceUtils.isMobileNative()) {
            // Overrides headers for file uploading only
            ApiMobileSetHeaders(HEADERS_COMMON_ACCEPT, HEADERS_CONTENT_TYPE, SERIALIZER);

            const idRequest = window.cordovaHTTP.httpPostForPond(
                BuildUrl(apiUploadUrl),
                formData,
                headers,
                (response) => {
                    const { ...data } = JSON.parse(response.data);
                    const { idFile } = data.data;

                    load(idFile);

                    if (onFileProcess) {
                        onFileProcess({ idFile, ...data });
                    }
                },
                (responseError) => {
                    // eslint-disable-next-line no-console
                    console.error(responseError.error);
                },
            );

            // Calling without parameters makes use of default values (setHeaders implementation)
            ApiMobileSetHeaders();

            return {
                abort: () => {
                    window.cordovaHTTP.httpRequestAbortForPond(
                        idRequest,
                        (response) => {
                            // eslint-disable-next-line no-console
                            console.log(
                                response.aborted
                                    ? "The request was successfully aborted by the user"
                                    : "No request to be aborted (or has already been processed)",
                            );
                        },
                        (responseError) => {
                            // eslint-disable-next-line no-console
                            console.error(responseError.error);
                        },
                    );

                    abort();
                },
            };
        }

        const axios = GetAxios();
        const { CancelToken } = axios;
        const source = CancelToken.source();

        const config = {
            cancelToken: source.token,
            headers,
            onUploadProgress: (e) => {
                progress(e.lengthComputable, e.loaded, e.total);
            },
        };

        axios.post(apiUploadUrl, formData, config).then((response) => {
            const { ...data } = response.data;
            const { idFile } = data.data;

            load(idFile);

            if (onFileProcess) {
                onFileProcess({ idFile, ...data });
            }
        });

        return {
            abort: () => {
                source.cancel("Operation canceled by the user.");

                abort();
            },
        };
    },
    revert: (uniqueFileId, load) => {
        if (uniqueFileId) {
            onRemoveFile({ serverId: uniqueFileId });
        }

        load();
    },
});

export default {
    serverOptions: ServerOptions,
};
