import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.RECOVER_PASS_CLEAN:
            return PROP.defaults;
        case TYPE.RECOVER_PASS_NO_TOKEN_STEP1_REQUEST:
        case TYPE.RECOVER_PASS_NO_TOKEN_STEP2_REQUEST:
        case TYPE.RECOVER_PASS_NO_TOKEN_STEP3_REQUEST:
        case TYPE.RECOVER_PASS_NO_TOKEN_STEP4_REQUEST:
        case TYPE.RESEND_VERIFICATION_CODE_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.RECOVER_PASS_NO_TOKEN_STEP1_FAILURE:
        case TYPE.RECOVER_PASS_NO_TOKEN_STEP2_FAILURE:
        case TYPE.RECOVER_PASS_NO_TOKEN_STEP3_FAILURE:
        case TYPE.RECOVER_PASS_NO_TOKEN_STEP4_FAILURE:
        case TYPE.RESEND_VERIFICATION_CODE_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.RECOVER_PASS_NO_TOKEN_STEP1_SUCCESS:
        case TYPE.RECOVER_PASS_NO_TOKEN_STEP3_SUCCESS:
        case TYPE.RESEND_VERIFICATION_CODE_SUCCESS:
            return {
                ...storeProp,
                exchangeToken: action.exchangeToken,
                fetching: false,
            };
        case TYPE.RECOVER_PASS_NO_TOKEN_STEP2_SUCCESS:
            return {
                ...storeProp,
                exchangeToken: action.exchangeToken,
                mobileNumber: action.mobileNumber,
                fetching: false,
            };
        case TYPE.RECOVER_PASS_NO_TOKEN_STEP4_SUCCESS:
            return {
                ...PROP.defaults,
                fetching: false,
            };
        default:
            return storeProp;
    }
};
