import React from "react";

import PropTypes from "prop-types";
import { Grid, Row, Col } from "react-bootstrap";
import { connect as Connect } from "react-redux";

import Container from "~/containers/Internal/Form/Simple";
import { SelectorsAction as SelectorsActionLoan, SelectorsStore as SelectorsStoreLoan } from "~/store/loan";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";

import LoanOperations from "~/pages/loans/_components/LoanOperations";

export const NAME = "MobileLoanOperations";

export const PROP = {};

PROP.types = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape({
        goBack: PropTypes.func.isRequired,
    }).isRequired,
    isChangingEnvironment: PropTypes.bool,
    loan: PropTypes.shape({
        idProduct: PropTypes.string,
        currency: PropTypes.string,
    }),
};

PROP.defaults = {
    isChangingEnvironment: false,
    loan: null,
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    componentDidMount() {
        const { dispatch, isChangingEnvironment, ...rest } = this.props;

        if (!isChangingEnvironment) {
            dispatch(SelectorsActionLoan.readLoan({ idLoan: rest.match.params.id }));
        }
    }

    goBack = () => {
        const { history } = this.props;

        history.goBack();
    };

    render() {
        const { loan } = this.props;

        return (
            <Container name={NAME} head-onBack={this.goBack} head-title="menu.operations">
                <div className="above-the-fold">
                    <section className="container--layout">
                        <Grid>
                            <Row className="justify-content-center">
                                <Col sm={12} md={9} lg={6} xl={6} className="col">
                                    {loan && <LoanOperations loan={loan} classNames="btn btn-block" />}
                                </Col>
                            </Row>
                        </Grid>
                    </section>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    loan: SelectorsStoreLoan.getSelectedLoan(store),
});

export default Connect(mapStateToProps)(Component);
