import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import {
    RESPONSE_TYPE,
    LEVEL,
    SCOPE,
    NO_W8_AVAILABLE,
    NO_RISK_PROFILE_AVAILABLE,
    FORM_FIELD_ERRORS,
} from "~/constants";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as UtilDownload from "~/util/download";
import {
    credentialsWithUnderscore as CredentialsWithUnderscore,
    hasIncorrectCredentials as HasIncorrectCredentials,
    adjustIdFieldErrors as AdjustIdFieldErrors,
    getTouchedFields as GetTouchedFields,
    getResponseMessage as GetResponseMessage,
} from "~/util/form";
import * as UtilI18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction } from "./_selectors";

export default [
    TakeLatest(TYPE.SEARCH_BY_ISIN_REQUEST, searchByIsin),
    TakeLatest(TYPE.BUY_BONDS_PRE_REQUEST, buyBondsPreRequest),
    TakeLatest(TYPE.BUY_BONDS_PREVIEW_REQUEST, buyBondsPreviewRequest),
    TakeLatest(TYPE.BUY_BONDS_SEND_FORM, buyBondsSendFormRequest),
    TakeLatest(TYPE.SELL_BONDS_PRE_REQUEST, sellBondsPreRequest),
    TakeLatest(TYPE.SELL_BONDS_PREVIEW_REQUEST, sellBondsPreviewRequest),
    TakeLatest(TYPE.SELL_BONDS_SEND_FORM, sellBondsSendFormRequest),
    TakeLatest(TYPE.READ_TRANSACTION_REQUEST, readTransactionRequest),
    TakeLatest(TYPE.CANCEL_TRANSACTION_REQUEST, cancelTransaction),
    TakeLatest(TYPE.SIGN_TRANSACTION_REQUEST, signTransaction),
    TakeLatest(TYPE.GET_PAPER, getPaper),
];

function* searchByIsin({ formData, formikBag }) {
    const { type, data } = yield Call(SelectorsMiddleware.searchByIsin, formData);

    if (type === RESPONSE_TYPE.WARNING) {
        formikBag.setErrors(data.data);
        yield Put(SelectorsAction.searchByIsinFailure());

        const message = GetResponseMessage(data);

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.BUY_BONDS],
            }),
        );
    } else {
        const { papersList } = data.data;
        yield Put(SelectorsAction.searchByIsinSuccess({ papersList }));
        if (papersList && papersList[0]) {
            if (formikBag.shouldCallPre) {
                const { idPaper } = papersList[0];
                yield Put(SelectorsAction.buyBondsPre({ idPaper }));
            }
        } else {
            yield Put(SelectorsAction.clearForm());
            if (formikBag.shouldDisplayIsinError) {
                formikBag.setErrors({ isin: UtilI18n.get("wm.bonds.buy.search.results.empty") });
            }
        }
    }
}

function* buyBondsPreRequest(props) {
    const { type, data } = yield Call(SelectorsMiddleware.buyBondsPreRequest, props);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.buyBondsPreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.BUY_BONDS],
            }),
        );
    } else {
        yield Put(SelectorsAction.buyBondsPreSuccess({ ...data.data }));
    }
}

function* buyBondsPreviewRequest({ previewData, formikBag }) {
    const { type, data } = yield Call(SelectorsMiddleware.buyBondsPreviewRequest, previewData);
    formikBag.setSubmitting(false);

    if (type === RESPONSE_TYPE.WARNING) {
        formikBag.setErrors(AdjustIdFieldErrors(data.data));
        formikBag.setTouched(GetTouchedFields(data.data)); // TODO ver xq hay q hacer esto
        const errorMessage = data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS);

        const { code } = data;

        if (NO_W8_AVAILABLE === code || NO_RISK_PROFILE_AVAILABLE === code) {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.BUY_BONDS],
                }),
            );
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get("forms.fieldsErrors"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.BUY_BONDS],
                }),
            );
        }

        yield Put(SelectorsAction.buyBondsPreviewFailure());
    } else {
        yield Put(SelectorsAction.buyBondsPreviewSuccess({ ...data.data }));
    }
}

function* buyBondsSendFormRequest({ submitData, credentials, formikBag }) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { type, data } = yield Call(SelectorsMiddleware.buyBondsRequest, {
        ...submitData,
        ...credentialsWithUnderscore,
    });
    const { idTransaction } = data;
    formikBag.setSubmitting(false);
    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);
        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get("forms.cancelTransaction.errorMessage"),
                    level: LEVEL.ERROR,
                    scopes: SCOPE.BUY_BONDS,
                }),
            );
        }
        yield Put(SelectorsAction.buyBondsSendFormFailure());
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(SelectorsAction.buyBondsSendFormSuccess({ transaction }));
    }
}

function* sellBondsPreRequest(props) {
    const { type, data } = yield Call(SelectorsMiddleware.sellBondsPreRequest, props);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.sellBondsPreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.SELL_BONDS],
            }),
        );
    } else {
        yield Put(SelectorsAction.sellBondsPreSuccess({ ...data.data }));
    }
}

function* sellBondsPreviewRequest({ formData, formikBag }) {
    const { type, data } = yield Call(SelectorsMiddleware.sellBondsPreviewRequest, formData);
    formikBag.setSubmitting(false);
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.sellBondsPreviewFailure());
        formikBag.setErrors(data.data);
        const { code } = data;
        if (NO_W8_AVAILABLE === code || NO_RISK_PROFILE_AVAILABLE === code) {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.SELL_BONDS],
                }),
            );
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get("forms.fieldsErrors"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.SELL_BONDS],
                }),
            );
        }
    } else {
        yield Put(SelectorsAction.sellBondsPreviewSuccess({ ...data.data }));
    }
}

function* sellBondsSendFormRequest({ formData, credentials, formikBag }) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { type, data } = yield Call(SelectorsMiddleware.sellBondsRequest, {
        ...formData,
        ...credentialsWithUnderscore,
    });
    const { idTransaction } = data;
    formikBag.setSubmitting(false);
    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);
        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get("forms.cancelTransaction.errorMessage"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.SELL_BONDS],
                }),
            );
        }
        yield Put(SelectorsAction.sellBondsSendFormFailure());
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(SelectorsAction.sellBondsSendFormSuccess({ transaction }));
    }
}

function* readTransactionRequest(props) {
    const { idTransaction } = props;
    const { type, data } = yield Call(SelectorsMiddlewareForm.readTransaction, {
        idTransactionToRead: idTransaction,
    });
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.BUY_BONDS],
            }),
        );
    } else {
        yield Put(SelectorsAction.readTransactionSuccess({ transaction: data.data.transaction }));
    }
}

function* cancelTransaction({ credentials, idTransaction, formikBag }) {
    formikBag.setSubmitting(false);

    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);
    const {
        data: { data },
        type,
    } = yield Call(SelectorsMiddlewareForm.cancelTransaction, {
        idTransactionToCancel: idTransaction,
        ...credentialsWithUnderscore,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data);
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get("forms.cancelTransaction.errorMessage"),
                    level: LEVEL.ERROR,
                    scopes: SCOPE.BUY_BONDS,
                }),
            );
        }
    } else {
        yield* readTransactionRequest({ idTransaction });
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("forms.cancelTransaction.confirmationMessage"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.BUY_BONDS, SCOPE.DESKTOP],
            }),
        );
    }
}

function* signTransaction(props) {
    const { idForm, idActivity, idTransaction, credentials, formikBag } = props;
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { data, type } = yield Call(
        SelectorsMiddlewareForm.sign,
        { idForm, idTransaction, ...credentialsWithUnderscore },
        idActivity,
    );
    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);
        if (hasIncorrectCredentials) {
            formikBag.setErrors(AdjustIdFieldErrors(data.data));
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.BUY_BONDS],
                }),
            );
        }
        formikBag.setSubmitting(false);
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(SelectorsAction.buyBondsSendFormSuccess({ transaction }));

        formikBag.setSubmitting(false);
    }
}

function* getPaper(props) {
    const { type, data } = yield Call(SelectorsMiddleware.getPaper, props);
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.SELL_BONDS, SCOPE.BUY_BONDS, SCOPE.INVESTMENT],
            }),
        );
    } else {
        const { content, fileName } = data.data;
        UtilDownload.downloadPdf(fileName, content);
    }
}
