import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, LEVEL, SCOPE, ONLY_NUMBER } from "~/constants";
import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import {
    SelectorsStore as SelectorsStoreComexImport,
    SelectorsAction as SelectorsActionComexImport,
    PROP as PropComex,
} from "~/store/comex/import";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsStore as i18nSelectors } from "~/store/i18n";
import { SelectorsAction as SelectorActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionSettings, SelectorsStore } from "~/store/settings";
import { SelectorsAction as SelectorsActionTemplate } from "~/store/template";
import * as ConfigUtil from "~/util/config";
import { formatDate as FormatDate, toDate } from "~/util/date";
import * as UtilsI18n from "~/util/i18n";
import { numberFormat } from "~/util/number";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import EmailList from "~/pages/_components/fields/EmailList";
import TextArea from "~/pages/_components/fields/TextArea";
import TextField from "~/pages/_components/fields/TextField";
import AmountField from "~/pages/_components/fields/formik/AmountField";

import OperationNumber from "~/pages/comEx/_components/OperationNumber";
import OperationNumberDetail from "~/pages/comEx/_components/OperationNumberDetail";
import CreateTemplateModal from "~/pages/forms/Step1Edit/_ModalTemplateCreate";
import ListTemplatesModal from "~/pages/forms/Step1Edit/_ModalTemplateList";
import { Termsandconditions } from "~/pages/forms/_components/_fields";
import Scheduler from "~/pages/forms/_components/_fields/Scheduler";
import DocumentField from "~/pages/settings/changePersonalInformation/field/DocumentField";

import Style from "../../../Step1Edit.rules.scss";

import { ReactComponent as DefaultIcon } from "~/assets/alertIconMedium.svg";

const FORM_ID = "comex.import.cancellationCreditLetter";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...PropComex.types,
    },
    defaults: {
        ...PropComex.defaults,
    },
};

const formatNumber = (decimalSeparator, thousandSeparator, decimalScale, num) => {
    const numParts = num
        .toFixed(decimalScale)
        .replace(".", decimalSeparator)
        .split(decimalSeparator);
    let l = numParts[0].length - 3;
    while (l > 0) {
        numParts[0] = `${numParts[0].slice(0, l)}${thousandSeparator}${numParts[0].slice(l)}`;
        l -= 3;
    }
    return `${numParts[0]}${decimalSeparator}${numParts[1]}`;
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        draftLoaded: false,
        loadOperationNumber: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(SelectorsActionComexImport.getCancellationCreditLetterPreRequest());
        dispatch(SelectorsActionSettings.changeAddressesRequest({}));
    }

    componentDidUpdate() {
        const {
            dispatch,
            setFieldError,
            setFieldTouched,
            setValues,
            transaction,
            setFieldValue,
            values,
            preData,
            match,
            formData = {},
        } = this.props;
        const { operationNumber } = match.params;
        const { availableOperationList } = preData;
        const { draftLoaded, loadOperationNumber } = this.state;

        if (!loadOperationNumber && availableOperationList.length > 0 && !values.operationNumber && operationNumber) {
            const operationSelected = availableOperationList.filter(
                (item) => item.othersFields.NROOPERACION === operationNumber,
            );
            this.setState({
                loadOperationNumber: true,
            });

            if (operationSelected.length > 0) {
                setFieldValue("item", operationSelected[0]);
                setFieldValue("operationNumber", operationNumber);
                setFieldValue("amountField", { amount: Math.abs(operationSelected[0].balance) });
            }
        }

        // si viene trasaction.data, no estoy volviendo del paso 2 y no se cargo el draft -> cargo draft
        if (transaction?.data && Object.keys(formData).length === 0 && !draftLoaded) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                draftLoaded: true,
            });
            const transactionData = transaction?.data;
            /* carga de errores */
            if (transactionData.backendFormErrors?.cdpErrorFields) {
                const { cdpErrorFields } = transactionData.backendFormErrors;
                // eslint-disable-next-line array-callback-return
                cdpErrorFields.map((errorField) => {
                    setFieldError(errorField, " ");
                    setFieldTouched(errorField, true);
                });
            }
            if (transactionData.backendFormErrors?.message) {
                const errorMessage = transactionData?.backendFormErrors?.message;
                dispatch(
                    SelectorActionNotification.showNotification({
                        message: errorMessage,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.COMEX_IMPORT_CANCELLATION_CREDIT_LETTER],
                    }),
                );
            }

            /*  carga del draft - modificaciones para que agarren bien los fields */
            if (transactionData.valueDate) {
                const valueDate = toDate(transactionData.valueDate);
                transactionData.valueDate = valueDate;
            }

            if (transactionData.authorizedDocumentDocumentType) {
                transactionData.authorizedDocumentDocumentType = transactionData.authorizedDocumentDocumentType.toString();
            }

            setValues(transaction.data);
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(GoBack());
    };

    handleFormKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            event.preventDefault();
        }
    };

    handleFieldKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            this.handleSearch();
        }
    };

    handleSelectChange = (id) => {
        const { setFieldValue } = this.props;
        setFieldValue("increment", id);
    };

    handleSelectChangeFieldName = (id, fieldName) => {
        const { setFieldValue } = this.props;
        setFieldValue(fieldName, id);
    };

    handleItemSelect = (item) => {
        const { setFieldValue, setFieldError } = this.props;
        const { othersFields } = item;
        if (othersFields) {
            setFieldValue("operationNumber", othersFields.NROOPERACION);
            setFieldValue("item", item);
            setFieldError("operationNumber", null);
            setFieldValue("amountField", { amount: Math.abs(item.balance) });
        } else {
            setFieldValue("item", null);
            setFieldValue("operationNumber", EMPTY_STR);
        }
    };

    handleModalLoad = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionComex.toggleCreditLetterList());
    };

    handleBlur = () => {
        const { values, preData, setFieldValue } = this.props;
        const { availableOperationList } = preData;
        if (values.operationNumber) {
            const operationSelected = availableOperationList.filter(
                (item) => item.othersFields.NROOPERACION === values.operationNumber,
            );
            if (operationSelected.length > 0) {
                setFieldValue("item", operationSelected[0]);
            } else {
                setFieldValue("item", null);
                setFieldValue("operationNumber", EMPTY_STR);
            }
        }
    };

    handleCancel = () => {
        const { dispatch, setSubmitting, transaction } = this.props;

        dispatch(
            SelectorsActionForm.cancelTransactionCustom({
                credentials: {},
                idTransaction: transaction.idTransaction,
                formikBag: { setSubmitting },
            }),
        );
    };

    handleDraft = () => {
        const { dispatch, values, transaction } = this.props;
        dispatch(
            SelectorsActionForm.saveDraft({
                idActivityDraft: "comex.import.cancellationCreditLetter.send",
                idTransactionToSave: transaction?.idTransaction,
                transactionData: values || {},
            }),
        );
    };

    handleTemplateSave = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionTemplate.createTemplate());
    };

    handleTemplateLoad = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionTemplate.toggleTemplateList());
    };

    handleSelectTemplate = (template) => {
        const { setValues, setErrors } = this.props;
        const templateToBeLoaded = template;
        setValues(templateToBeLoaded);
        setErrors({});
    };

    render() {
        const { values, emailValidationRegex, preData, countryList, documents, transaction } = this.props;
        const { nextValidDate, unavailableDays, availableOperationList } = preData;
        const termsAndConditions = "comex.import.cancellationCreditLetter.disclaimer";
        const i18nMap = {
            termsAndConditions,
        };
        const cancelEnabled = transaction && transaction.idTransactionStatus === STATUS.DRAFT;
        const othersFields = values.item ? values.item.othersFields : null;

        const currency = othersFields ? othersFields.MONEDA : EMPTY_STR;
        const noOption = UtilsI18n.get("selector.noOption.text");

        const showPartialPaymentWarning =
            values.item?.balance &&
            values.amountField?.amount &&
            values.amountField.amount < Math.abs(values.item.balance);

        const data = {
            options: [
                {
                    id: currency,
                    label: UtilsI18n.get(`currency.label.${currency}`),
                },
            ],
        };

        return (
            <React.Fragment>
                <Form
                    className="col col-12 col-lg-6 col-md-9 col-sm-12"
                    id={Style.ID}
                    noValidate="novalidate"
                    onKeyDown={this.handleFormKeyDown}>
                    <div className="form-section loadTemplates">
                        <Button
                            onClick={this.handleTemplateLoad}
                            label="forms.templates.load"
                            bsStyle="default"
                            image="template.svg"
                        />
                    </div>
                    <section className="fields container--layout align-items-center flex-grow">
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.section.operation`)}</h3>
                        </Box>

                        <Box flex align="end" className="form-group search-field">
                            <Field
                                idForm={FORM_ID}
                                name="operationNumber"
                                hidePlaceholder
                                pattern={ONLY_NUMBER}
                                type="text"
                                component={TextField}
                                onBlur={this.handleBlur}
                                tooltip={UtilsI18n.get("comex.import.cancellationCreditLetter.operationNumber.tooltip")}
                            />

                            <Box className="ml-3 mb-2">
                                <Button
                                    onClick={this.handleModalLoad}
                                    bsStyle="primary"
                                    label="global.search"
                                    className="search"
                                />
                            </Box>
                        </Box>

                        {values.item && <OperationNumberDetail othersFields={othersFields} idForm={FORM_ID} />}
                        <Field
                            clearable={false}
                            component={AmountField}
                            data={data}
                            idForm={FORM_ID}
                            name="amountField"
                            placeholder={EMPTY_STR}
                            searchable={false}
                            decimalPlaces={2}
                            fixedDecimalScale
                            hideCurrency={!currency}
                            handleBlur={this.handleAmountBlur}
                            maxLength={ConfigUtil.getInteger("amount.length")}
                        />
                        {showPartialPaymentWarning && (
                            <div className="field-warning form-group form-group--composite">
                                <DefaultIcon />
                                <span>
                                    {UtilsI18n.get(
                                        "comex.import.cancellationCollection.amountField.warning.partialPayment",
                                    )}
                                </span>
                            </div>
                        )}
                        <Field
                            component={TextArea}
                            idForm={FORM_ID}
                            name="observations"
                            mode={MODE.EDIT}
                            className="form-control"
                            maxLength={500}
                            hidePlaceholder
                        />

                        <Box className="form-group document-field">
                            <Field
                                idForm={FORM_ID}
                                name="authorizedDocument"
                                component={DocumentField}
                                data={{
                                    countries: countryList,
                                    documentTypes: documents,
                                }}
                                searchable
                                noOption={noOption}
                                inputProps={{ spellCheck: "false" }}
                                handleError
                                autoFocus={false}
                                hidePlaceholder
                                hideDocumentPlaceholder
                                tooltip={UtilsI18n.get("client.comex.generic.documentNumber.tooltip")}
                            />
                        </Box>

                        <Field
                            idForm={FORM_ID}
                            name="authorizedDocumentName"
                            hidePlaceholder
                            type="text"
                            component={TextField}
                            maxLength={140}
                            onBlur={this.handleBlur}
                        />
                        <Field
                            component={EmailList}
                            idForm={FORM_ID}
                            name="notificationEmails"
                            hideSwiftTip
                            className="form-group email-field"
                            value={values.notificationEmails}
                            renderSuggestion={false}
                            data={{ emailValidationRegex, addMessage: EMPTY_STR }}
                            isRequired={false}
                            optional={UtilsI18n.get("form.field.optional")}
                            tooltip={UtilsI18n.get(`${FORM_ID}.operationNumber.tooltip`)}
                        />

                        <hr />
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">
                                {UtilsI18n.get(`${FORM_ID}.section.showPreview`)}
                            </h3>
                        </Box>

                        <Field
                            component={Termsandconditions}
                            idForm={FORM_ID}
                            name="disclaimer"
                            mode={MODE.EDIT}
                            className="form-control"
                            i18nMap={i18nMap}
                        />
                        {preData.nextValidDate && (
                            <Field
                                component={Scheduler}
                                data={{
                                    firstWorkingDate: nextValidDate,
                                    maxDaysToSchedule: ConfigUtil.get("client.comex.valueDate.maxDaysToSchedule"),
                                    nonWorkingDays: unavailableDays,
                                    lang: "es",
                                    mode: "edit",
                                    programable: false,
                                    schedulable: true,
                                }}
                                name="scheduler"
                                idForm={FORM_ID}
                            />
                        )}
                    </section>

                    <footer>
                        <Button
                            onClick={this.handleTemplateSave}
                            className="templateSave"
                            label="forms.saveTemplate.link"
                        />
                        {cancelEnabled && <Button onClick={this.handleCancel} label="forms.cancelTransaction.link" />}
                        <Button type="submit" label="global.next" bsStyle="primary" />
                    </footer>
                </Form>
                <OperationNumber
                    list={availableOperationList}
                    idForm={FORM_ID}
                    handleItemClick={this.handleItemSelect}
                />
                <CreateTemplateModal values={values} idActivityTemplate="comex.import.cancellationCreditLetter.send" />
                <ListTemplatesModal
                    idActivityTemplate="comex.import.cancellationCreditLetter.send"
                    onSelect={this.handleSelectTemplate}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        preData: SelectorsStoreComexImport.getCancellationCreditLetterPre(store),
        countryList: SelectorsStore.getCountryList(store),
        documents: SelectorsStore.getDocuments(store),
        transaction: SelectorsStoreComexImport.getTransaction(store),
        formData: SelectorsStoreComexImport.getCancellationCreditLetterFormData(store),
        lang: i18nSelectors.getLang(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ formData }) => {
            return {
                operationNumber: formData?.operationNumber,
                dueDate: formData?.dueDate,
                boardingDate: formData?.boardingDate,
                increment: formData?.increment,
                invoiceAmount: formData?.amount,
                otherModifications: formData?.otherModifications,
                attachments: formData?.attachments,
                observations: formData?.observations,
                notificationEmails: formData?.notificationEmails,
                item: formData?.item,
                // documentCountryId: formData?.documentCountryId || "858", // URUGUAY
                amountField: formData?.amountField,
                scheduler: formData?.scheduler,
                valueDate: formData?.valueDate,
                accountNumber: formData?.accountNumber,
                invoiceAmountCurrency: formData?.invoiceAmountCurrency,
                authorizedDocument: formData?.authorizedDocument || { country: "858", type: "12", document: EMPTY_STR },
                authorizedDocumentName: formData?.authorizedDocumentName,
            };
        },
        handleSubmit: (props, formikBag) => {
            const { dispatch, preData, lang } = formikBag.props;
            const {
                item,
                attachments,
                invoiceAmount,
                scheduler,
                dueDate,
                boardingDate,
                amountField,
                authorizedDocumentDocumentNumber,
                authorizedDocumentDocumentType,
                authorizedDocument,
                ...rest
            } = props;

            const { decimalSeparator, thousandSeparator } = numberFormat(lang);

            const originalDueDate = item?.othersFields ? item.othersFields.FECHAVTO : EMPTY_STR;
            const oldAmount = item?.othersFields ? item.othersFields.IMPORTE : EMPTY_STR;
            const beneficiary = item?.othersFields ? item.othersFields.BENEFICIARIO : EMPTY_STR;
            const valueDateString = scheduler ? FormatDate(scheduler.valueDate) : EMPTY_STR;
            const dueDateString = dueDate ? FormatDate(new Date(dueDate)) : EMPTY_STR;
            const boardingDateString = boardingDate ? FormatDate(new Date(boardingDate)) : EMPTY_STR;
            const invoiceAmountCurrency = item?.othersFields ? item.othersFields.MONEDA : EMPTY_STR;
            const formData = {
                ...rest,
                scheduler,
                valueDate: valueDateString,
                dueDate: dueDateString,
                boardingDate: boardingDateString,
                originalDueDate,
                item,
                invoiceAmount: amountField?.amount || null,
                subOperationNumber: item?.othersFields?.SUBOPE || null,
                amountField,
                amount: { quantity: amountField?.amount || null, currency: invoiceAmountCurrency },
                oldAmount: parseFloat(oldAmount),
                beneficiary,
                accountNumber: preData.accountNumber,
                authorizedDocument,
                documentCountryId: authorizedDocument.country,
                authorizedDocumentDocumentNumber: authorizedDocument.document,
                authorizedDocumentDocumentType: parseInt(authorizedDocument.type, 10),
                invoiceAmountCurrency,
                formattedAmount:
                    amountField?.amount && formatNumber(decimalSeparator, thousandSeparator, 2, amountField?.amount),
            };
            dispatch(
                SelectorsActionComexImport.cancellationCreditLetterPreviewRequest({
                    formData,
                    formikBag,
                }),
            );
        },
    }),
)(Component);
