/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { DATA_NOT_AVAILABLE, CC_STATEMENT_TYPES, CDP_CURRENCY, EMPTY_STR } from "~/constants";
import {
    SelectorsStore as StoreCC,
    SelectorsAction as ActionCC,
    PROP as PROP_CC,
} from "~/store/creditCards/creditCard";
import { SelectorsStore as StoreI18n } from "~/store/i18n";

import Box from "~/components/Box";
import Element from "~/components/Component";
import DataDate from "~/components/DataDate";
import DataNumber from "~/components/DataNumber";
import GridLayout from "~/components/GridLayout";
import HighOrder from "~/components/HighOrder";
import IntersectionObserver from "~/components/IntersectionObserver";

import Style from "./_Movements.rules.scss";
import { NAME_NOTE, TYPE_NOTE } from "./_index.scss";

export const { NAME, TYPE } = Style;

export const PROP = {
    types: {
        creditCard: PropTypes.object,
        creditCardList: PROP_CC.types.creditCardList,
        id: PropTypes.string.isRequired,
        isFetching: PROP_CC.types.isFetchingMovements,
        i18n: PropTypes.object,
    },
    defaults: {
        creditCard: null,
        isFetching: PROP_CC.defaults.isFetchingMovements,
        i18n: {
            loading: "Cargando",
            notAvailable: DATA_NOT_AVAILABLE,
            labelMore: "Más movimientos",
            labelNoMore: "No hay más movimientos",
        },
    },
};

export function Component(props) {
    const { creditCard, creditCardList, i18n, dispatch, id, isFetching: isFetchingMovs, isMobile } = props;
    const {
        loading,
        notAvailable,
        labelMore,
        labelNoMore,
        labelDate,
        labelCard,
        labelConcept,
        labelAmountUY,
        labelAmountUS,
        labelPreviousBalance,
        labelCurrentBalance,
        noRecords,
        title,
    } = i18n;

    const COLUMN_HEADINGS = [labelDate, labelCard, labelConcept, labelAmountUY, labelAmountUS];

    const creditCardFound = creditCardList.find((item) => item.creditCardNumber === creditCard.number);

    const renderMovDetail = ({
        concept,
        date,
        creditCardNumber,
        amountUYP,
        amountUSD,
        idStatement,
        sourceAmountCurrency,
    }) => {
        return (
            <GridLayout className="body" key={`movement-${idStatement}`}>
                <div>
                    <DataDate value={date} />
                </div>
                <div className="web-only">{creditCardNumber}</div>
                <div>{concept || notAvailable}</div>
                <Box justify="end">
                    {/* Dont show amount when its value is 0 */}
                    {amountUYP !== 0 && <DataNumber value={amountUYP} prefix={sourceAmountCurrency} />}
                </Box>
                <Box justify="end">
                    {/* Dont show amount when its value is 0 */}
                    {amountUSD !== 0 && <DataNumber value={amountUSD} prefix={sourceAmountCurrency} />}
                </Box>
            </GridLayout>
        );
    };

    const renderNoRecords = () =>
        !isFetchingMovs ? <Note value={noRecords} className="no-more-movements" /> : <React.Fragment></React.Fragment>;

    const recordsFound = creditCardFound && creditCardFound.movements && creditCardFound.movements.length > 0;

    const render = () => (
        <React.Fragment>
            <span className="title">{title}</span>
            <GridLayout className="header">
                {COLUMN_HEADINGS.map((heading, index) => {
                    return (
                        <Box key={heading} justify={index === 3 || index === 4 ? "end" : EMPTY_STR}>
                            {heading}
                        </Box>
                    );
                })}
            </GridLayout>
            {recordsFound
                ? creditCardFound.movements.map((movement) => {
                      const { type } = movement;
                      if (type !== CC_STATEMENT_TYPES.DETAIL) {
                          const { idStatement, amountUYP, amountUSD } = movement;
                          return (
                              <GridLayout className="movHeading" key={`movement-${idStatement}`}>
                                  <div />
                                  <div />
                                  <div>
                                      {type === CC_STATEMENT_TYPES.START ? labelPreviousBalance : labelCurrentBalance}
                                  </div>
                                  <Box justify="end">
                                      <DataNumber value={amountUYP} prefix={CDP_CURRENCY.UYU} />
                                  </Box>
                                  <Box justify="end">
                                      <DataNumber value={amountUSD} prefix={CDP_CURRENCY.USD} />
                                  </Box>
                              </GridLayout>
                          );
                      }
                      return renderMovDetail(movement);
                  })
                : renderNoRecords()}

            {isFetchingMovs && <Note value={loading} />}
            {!isFetchingMovs && creditCardFound && creditCardFound.hasMoreMovements && (
                <Note
                    intersection-trigger="true"
                    key="more"
                    value={labelMore}
                    onClick={() => handleMovementsFetch({ isIntersecting: true })}
                />
            )}
            {!isFetchingMovs && creditCardFound && !creditCardFound.hasMoreMovements && recordsFound && (
                <Note value={labelNoMore} className="no-more-movements" />
            )}
        </React.Fragment>
    );

    return isMobile ? (
        <IntersectionObserver onIntersect={handleMovementsFetch}>{render()}</IntersectionObserver>
    ) : (
        render()
    );

    function handleMovementsFetch(ev) {
        const { isIntersecting } = ev;

        if (!isIntersecting) {
            return;
        }
        dispatch(
            ActionCC.creditCardMovementsRequest({
                creditCard,
                idCreditCard: id,
                pageNumber: creditCardFound.pageNumber ? creditCardFound.pageNumber + 1 : 1,
                pageNumberAux: creditCardFound.pageNumberAux + 1,
            }),
        );
    }

    function Note({ value, ...rest }) {
        return (
            <Element type={TYPE_NOTE} name={NAME_NOTE} tag="span" {...rest}>
                {value}
            </Element>
        );
    }
}

function mapStateToProps(store) {
    return {
        creditCardList: StoreCC.getCreditCardList(store) || [],
        isFetching: StoreCC.isFetchingMovementsByCreditCard(store),
        i18n: {
            notAvailable: StoreI18n.getMessage(store, "data.notAvailable.short"),
            noRecords: StoreI18n.getMessage(store, "global.noRecords"),
            loading: StoreI18n.getMessage(store, "global.loading"),
            labelMore: StoreI18n.getMessage(store, "accounts.movements.moreMovements"),
            labelNoMore: StoreI18n.getMessage(store, "accounts.movements.noMoreMovements"),
            labelDate: StoreI18n.getMessage(store, "creditCards.detail.movements.header.date"),
            labelCard: StoreI18n.getMessage(store, "creditCards.detail.movements.header.card"),
            labelConcept: StoreI18n.getMessage(store, "creditCards.detail.movements.header.concept"),
            labelAmountUY: StoreI18n.getMessage(store, "creditCards.detail.movements.header.amountUY"),
            labelAmountUS: StoreI18n.getMessage(store, "creditCards.detail.movements.header.amountUS"),
            labelPreviousBalance: StoreI18n.getMessage(store, "creditCards.detail.movements.type.start.label"),
            labelCurrentBalance: StoreI18n.getMessage(store, "creditCards.detail.movements.type.end.label"),
            title: StoreI18n.getMessage(store, "creditCards.detail.actions.title.movements"),
        },
    };
}

export default Connect(mapStateToProps)(HighOrder.Resizable(Component));
