import React from "react";

import classnames from "classnames";
import { Field, Form, withFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SelectorsAction as SelectorsActionAccount, SelectorsStore as SelectorsStoreAccount } from "~/store/account";
import { OPTIONS } from "~/store/account/_consts";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import * as UtilDate from "~/util/date";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import DateField from "~/pages/_components/fields/DateField";
import Select from "~/pages/_components/fields/Select";

import Style from "./_Filters.rules.scss";

const FORM_ID = "accounts.movements.filters.period";

export const { CLASS, NAME } = Style;

export const PROP = {
    types: {
        values: PropTypes.shape({
            dateFrom: PropTypes.string,
            dateTo: PropTypes.string,
            period: PropTypes.string,
        }).isRequired,
        i18n: PropTypes.object,
        idAccount: PropTypes.string.isRequired,
    },
    defaults: {
        i18n: {
            cleanFilter: "Limpiar filtro",
            currentweek: "Semana actual",
            currentmonth: "Mes actual",
            export: "Export",
            filter: "Filtrar",
            lastmonth: "Mes anterior",
            lastmonthsix: "Últimos 6 meses",
            period: "Período personalizado",
            today: "Hoy",
        },
    },
};

const DATE_RANGE = {
    [OPTIONS.TODAY]: UtilDate.getToday(),
    [OPTIONS.CURRENT_WEEK]: UtilDate.getLastWeek(),
    [OPTIONS.CURRENT_MONTH]: UtilDate.getCurrentPeriod(),
    [OPTIONS.LAST_MONTH]: UtilDate.getLastMonthPeriod(),
    [OPTIONS.LAST_SIX_MONTHS]: UtilDate.getLastSixMonths(),
};

export function Component(props) {
    const {
        i18n,
        setValues,
        values: { period, dateFrom, dateTo },
        dispatch,
        idAccount,
    } = props;

    const options = [
        { value: OPTIONS.LATEST_MOVS, label: i18n.latestMovements },
        { value: OPTIONS.TODAY, label: i18n.today },
        { value: OPTIONS.CURRENT_WEEK, label: i18n.currentweek },
        { value: OPTIONS.CURRENT_MONTH, label: i18n.currentmonth },
        { value: OPTIONS.LAST_MONTH, label: i18n.lastmonth },
        { value: OPTIONS.LAST_SIX_MONTHS, label: i18n.lastmonthsix },
        { value: OPTIONS.PERIOD, label: i18n.period },
    ];

    const handleChange = (value) => {
        if (value) {
            let filter = {
                period: value,
                dateFrom: null,
                dateTo: null,
            };

            if (DATE_RANGE[value]) {
                const [from, to] = DATE_RANGE[value];
                filter = {
                    ...filter,
                    dateFrom: from,
                    dateTo: to,
                };
            }

            if (value !== OPTIONS.PERIOD) {
                dispatch(SelectorsActionAccount.details({ idAccount, filters: filter, clearFilter: false }));
            } else {
                dispatch(SelectorsActionAccount.setFilter({ filters: filter, clearFilter: false }));
            }

            setValues(filter, false);
        }
    };

    const handleFormCleanup = () => {
        dispatch(
            SelectorsActionAccount.details({
                idAccount,
                filters: { period: OPTIONS.LATEST_MOVS, dateFrom: null, dateTo: null },
                clearFilter: true,
            }),
        );

        setValues(
            {
                period,
                dateFrom: null,
                dateTo: null,
            },
            false,
        );
    };

    return (
        <Form className={CLASS}>
            <Select
                clearable={false}
                labelKey="label"
                name="period"
                onChange={handleChange}
                options={options}
                value={period}
                valueKey="value"
            />
            <Field
                component={DateField}
                className={classnames({ hidden: period !== OPTIONS.PERIOD })}
                hideLabel
                idForm={FORM_ID}
                name="dateFrom"
                endDate={dateTo}
                selectsStart
                shouldClean={period !== OPTIONS.PERIOD}
                showMonthYearDropdown
                startDate={dateFrom}
                selectedDate={dateFrom}
                maxDate={new Date()}
            />
            <Field
                component={DateField}
                className={classnames({ hidden: period !== OPTIONS.PERIOD })}
                hideLabel
                endDate={dateTo}
                idForm={FORM_ID}
                name="dateTo"
                selectsEnd
                shouldClean={period !== OPTIONS.PERIOD}
                showMonthYearDropdown
                startDate={dateFrom}
                minDate={dateFrom}
                selectedDate={dateTo}
            />
            <Box flex justify="center" className={classnames({ "is-hidden": period !== OPTIONS.PERIOD })}>
                <Button disabled={!dateFrom || !dateTo} size="sm" type="submit" variant="primary">
                    {i18n.filter}
                </Button>
                <Button disabled={!dateFrom && !dateTo} onClick={handleFormCleanup} size="sm" variant="secondary">
                    {i18n.cleanFilter}
                </Button>
            </Box>
            {dateTo && dateFrom && (
                <Box>
                    <I18n
                        id="accounts.movements.filters.period"
                        DATE_FROM={UtilDate.specificDate(dateFrom)}
                        DATE_TO={UtilDate.specificDate(dateTo)}
                    />
                </Box>
            )}
        </Form>
    );
}

const mapStateToProps = (store) => ({
    i18n: {
        cleanFilter: StoreI18n.getMessage(store, "filters.clean"),
        currentweek: StoreI18n.getMessage(store, "accounts.movements.filters.currentweek"),
        currentmonth: StoreI18n.getMessage(store, "accounts.movements.filters.currentmonth"),
        export: StoreI18n.getMessage(store, "accounts.movements.filters.period.export"),
        filter: StoreI18n.getMessage(store, "product.filters.filter"),
        lastmonth: StoreI18n.getMessage(store, "accounts.movements.filters.lastmonth"),
        lastmonthsix: StoreI18n.getMessage(store, "accounts.movements.filters.lastmonthsix"),
        latestMovements: StoreI18n.getMessage(store, "swift.list.filter.periodOptions.lastMovements"),
        period: StoreI18n.getMessage(store, "accounts.movements.filters.personalized"),
        today: StoreI18n.getMessage(store, "accounts.movements.filters.today"),
    },
    filters: SelectorsStoreAccount.getFilters(store),
    clearFilter: SelectorsStoreAccount.getClearFilter(store),
});

export default HighOrder(
    Connect(mapStateToProps),
    withFormik({
        mapPropsToValues: ({ filters, clearFilter }) => {
            if (clearFilter) {
                return {
                    dateFrom: null,
                    dateTo: null,
                    period: OPTIONS.PERIOD,
                };
            }
            if (filters.period) {
                return filters;
            }
            return {
                dateFrom: null,
                dateTo: null,
                period: OPTIONS.LATEST_MOVS,
            };
        },
        handleSubmit: ({ dateFrom, dateTo, period }, formikBag) => {
            const { dispatch, idAccount } = formikBag.props;

            dispatch(
                SelectorsActionAccount.details({
                    idAccount,
                    filters: { dateFrom, dateTo, period },
                    clearFilter: false,
                }),
            );
        },
    }),
)(Component);
