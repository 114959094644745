import { routerActions } from "connected-react-router";
import { connect as Connect } from "react-redux";
import { bindActionCreators } from "redux";

import { SelectorsStore as SelectorsStorePermissions } from "~/store/administration/common/permissions/index";
import { SelectorsActionPermissionsMedium as SelectorsActionMedium } from "~/store/administration/medium";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { getUser } from "~/util/store/administration";

import Permissions from "~/pages/administration/_components/Permissions";

const mapStateToProps = (store) => ({
    fetching: SelectorsStorePermissions.isFetching(store),
    groups: SelectorsStorePermissions.getGroups(store),
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    products: SelectorsStorePermissions.getProducts(store),
    permissions: SelectorsStorePermissions.getPermissions(store),
    user: getUser(store),
    caps: SelectorsStorePermissions.getCaps(store),
    capFrequencyList: SelectorsStorePermissions.getCapFrequencyList(store),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(SelectorsActionMedium, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});
export default Connect(mapStateToProps, mapDispatchToProps)(Permissions);
