import PropTypes from "prop-types";

import { EMPTY_STR, ZERO_NUMBER } from "~/constants";
import { MODE } from "~/constants/form";
import { Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "servicePayment";

export const PATH = {
    FETCH_SERVICE_DATA: "/service.payment.get",
    QR_MODO_READ_TRANSACTION: "/payments.qrModo.readTransaction",
    SERVICE_PAYMENT_PRE: "/service.payment.pre",
    SERVICE_PAYMENT_PREVIEW: "/service.payment.preview",
    SERVICE_PAYMENT_SEND: "/service.payment.send",
};

export const TYPE = FactoryType(NAME, [
    "CANCEL_CUSTOM_TRANSACTION_REQUEST",
    "CLEAN_BILLS",
    "CLEAN_UP",
    "FETCH_SERVICE_DATA_FAILURE",
    "FETCH_SERVICE_DATA_REQUEST",
    "FETCH_SERVICE_DATA_SUCCESS",
    "FORM_TO_STEP_1",
    "HANDLE_FETCHING",
    "LAUNCH_SDK_MODO_REQUEST",
    "QR_MODO_READ_TRANSACTION_SUCCESS",
    "READ_TRANSACTION_FAILURE",
    "READ_TRANSACTION_REQUEST",
    "READ_TRANSACTION_SUCCESS",
    "SERVICE_PAYMENT_PRE_FAILURE",
    "SERVICE_PAYMENT_PRE_REQUEST",
    "SERVICE_PAYMENT_PRE_SUCCESS",
    "SERVICE_PAYMENT_PREVIEW_FAILURE",
    "SERVICE_PAYMENT_PREVIEW_REQUEST",
    "SERVICE_PAYMENT_PREVIEW_SUCCESS",
    "SERVICE_PAYMENT_SEND_FAILURE",
    "SERVICE_PAYMENT_SEND_REQUEST",
    "SERVICE_PAYMENT_SEND_SUCCESS",
    "SIGN_TRANSACTION_FAILURE",
    "SIGN_TRANSACTION_PREVIEW",
    "SIGN_TRANSACTION_REQUEST",
    "SIGN_TRANSACTION_SUCCESS",
]);

export const PROP = {};

PROP.members = {};

PROP.types = {
    debitAccountAlias: PropTypes.string,
    debitAccounts: PropTypes.array,
    debitAmount: PropTypes.object,
    documentsToPayList: PropTypes.array,
    fetching: PropTypes.bool,
    formData: PropTypes.object,
    isSameCurrency: PropTypes.bool,
    mode: PropTypes.object,
    prevMode: PropTypes.string,
    rate: PropTypes.number,
    rateFirstCurrency: PropTypes.string,
    rateSecondCurrency: PropTypes.string,
    sistarbancAvailableServices: PropTypes.array,
    sistarbancValidateResult: PropTypes.object,
    transaction: PropTypes.object,
    transactionDate: PropTypes.string,
    transactionHour: PropTypes.string,
};

PROP.defaults = {
    ...DefaultsRedux,
    debitAccountAlias: EMPTY_STR,
    debitAccounts: null,
    debitAmount: null,
    documentsToPayList: null,
    fetching: false,
    formData: null,
    isSameCurrency: false,
    mode: MODE.EDIT,
    prevMode: EMPTY_STR,
    rate: ZERO_NUMBER,
    rateFirstCurrency: EMPTY_STR,
    rateSecondCurrency: EMPTY_STR,
    sistarbancAvailableServices: null,
    sistarbancValidateResult: null,
    transaction: null,
    transactionDate: EMPTY_STR,
    transactionHour: EMPTY_STR,
};
