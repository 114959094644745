import React from "react";

import PropTypes from "prop-types";

import I18n from "~/components/I18n";
import Select from "~/components/Select";

import LapseSubOption, { PROP as PropLSO } from "~/pages/forms/_components/_fields/_scheduler/LapseSubOption";

export const NAME = "MonthlyFrequencySubOption";

export const PROP = {
    types: {
        onChange: PropTypes.func.isRequired,
        value: PropLSO.value,
        enabledWeekDays: PropLSO.enabledWeekDays,
        firstWorkingDate: PropLSO.firstWorkingDate,
        nonWorkingDays: PropLSO.nonWorkingDays,
    },
    defaults: {
        enabledWeekDays: null,
        nonWorkingDays: null,
        value: null,
        firstWorkingDate: null,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    handleChange = (selectedOption) => {
        const { onChange, value } = this.props;
        onChange({ ...value, day: selectedOption.id });
    };

    handleLapseValueChange = (lapseValue) => {
        const { onChange, value } = this.props;
        onChange({ ...value, ...lapseValue });
    };

    render() {
        const { enabledWeekDays, firstWorkingDate, nonWorkingDays, value } = this.props;

        const options = [];
        for (let idx = 1; idx <= 31; idx += 1) {
            options.push({ id: idx, label: `${idx}` });
        }

        return (
            <div className="scheduler-frequency-suboptions">
                <div className="form-group form-group--select form-group-row">
                    <div className="form-text-group">
                        <label className="control-label">
                            <I18n id="scheduler.executionMonthlySubOption" />
                        </label>
                    </div>
                    <div className="input-group">
                        <Select
                            className="slideFromBottom"
                            value={value.day}
                            clearable={false}
                            searchable={false}
                            onChange={this.handleChange}
                            valueKey="id"
                            labelKey="label"
                            options={options}
                        />
                    </div>
                </div>
                <LapseSubOption
                    enabledWeekDays={enabledWeekDays}
                    firstWorkingDate={firstWorkingDate}
                    nonWorkingDays={nonWorkingDays}
                    onChange={this.handleLapseValueChange}
                    value={value}
                />
            </div>
        );
    }
}

export default Component;
