import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { SelectorsStore as SelectorsStoreForm } from "~/store/form";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { countDecimalPlaces as CountDecimalPlaces, numberFormat as NumberFormatUtil } from "~/util/number";

import I18n from "~/components/I18n";
import Image from "~/components/Image";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import FormattedRate from "~/pages/_components/FormattedRate";

export const NAME = "TransferInternal";

export const PROP = {
    types: {
        previewData: PropTypes.shape({
            rateFirstCurrency: PropTypes.string,
            rateSecondCurrency: PropTypes.string,
            debitAmount: PropTypes.shape({
                currency: PropTypes.string.isRequired,
                quantity: PropTypes.number.isRequired,
            }).isRequired,
            amount: PropTypes.shape({
                currency: PropTypes.string.isRequired,
                quantity: PropTypes.number.isRequired,
            }).isRequired,
            creditCardAlias: PropTypes.string,
            debitAccountAlias: PropTypes.string.isRequired,
            creditAccountAlias: PropTypes.string.isRequired,
            creditCardMark: PropTypes.string,
            rate: PropTypes.number,
            manualRevisionWarning: PropTypes.bool,
            transferWithoutFundsWarning: PropTypes.bool,
        }).isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const { lang, previewData } = props;

    const {
        amount,
        creditAccountAlias,
        creditAmount,
        debitAccountAlias,
        debitAmount,
        manualRevisionWarning,
        rate,
        rateFirstCurrency,
        rateSecondCurrency,
        showRateUI,
        transferWithoutFundsWarning,
    } = previewData;

    const showDebitAmount = debitAmount.currency !== amount.currency;
    const showCreditAmount = creditAmount.currency !== amount.currency;
    const { decimalSeparator } = NumberFormatUtil(lang);

    const renderAmount = (className, label, amountToRender) => (
        <div className={`transfer-data ${className}`}>
            <span className="data-label">
                <I18n id={`forms.transferInternal.confirmation.${label}`} />
            </span>
            <FormattedAmount currency={amountToRender.currency} quantity={amountToRender.quantity} />
        </div>
    );

    const renderRate = (className, rateToRender, firstCurrency, secondCurrency) => (
        <div className={`transfer-data ${className}`}>
            <span className="data-label">
                <I18n id="forms.confirmation.rate" />
            </span>
            <FormattedRate
                decimalUI={showRateUI && CountDecimalPlaces(rateToRender, decimalSeparator)}
                firstCurrency={firstCurrency}
                secondCurrency={secondCurrency}
                quantity={rateToRender}
            />
        </div>
    );

    return (
        <React.Fragment>
            <p className="text-lead">
                <I18n id="forms.transferInternal.confirmation.title" />
            </p>

            <div className="transfer-block">
                {renderAmount("transfer-data-highlight", "amount", amount)}

                {showCreditAmount && (
                    <React.Fragment>
                        {renderAmount("data-wrapper", "creditAmount", creditAmount)}
                        {renderRate("data-wrapper", rate, rateFirstCurrency, rateSecondCurrency)}
                    </React.Fragment>
                )}

                {showDebitAmount && (
                    <React.Fragment>
                        {renderAmount("data-wrapper", "debitAmount", debitAmount)}
                        {renderRate("data-wrapper", rate, rateFirstCurrency, rateSecondCurrency)}
                    </React.Fragment>
                )}

                {showRateUI && (
                    <React.Fragment>
                        {renderRate("data-wrapper", rate, rateFirstCurrency, rateSecondCurrency)}
                    </React.Fragment>
                )}

                {manualRevisionWarning && (
                    <span className="data-warning">
                        <I18n id="client.transaction.manualRevisionWarning" />
                    </span>
                )}

                {transferWithoutFundsWarning && (
                    <span className="data-warning">
                        <I18n id="client.transaction.transferWithoutFundsWarning" />
                    </span>
                )}

                <div className="transfer-data transfer-data-flow">
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="forms.transferInternal.confirmation.debitAccount" />
                        </span>
                        <span className="data-name">{debitAccountAlias}</span>
                    </div>
                    <Image src="arrowRight.svg" className="svg-icon" />
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="forms.transferInternal.confirmation.creditAccount" />
                        </span>
                        <span className="data-name">{creditAccountAlias}</span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (state) => ({
    lang: SelectorsStoreI18n.getLang(state),
    name: SelectorsStoreForm.getName(state),
    previewData: SelectorsStoreForm.getPreviewData(state),
});

export default connect(mapStateToProps)(Component);
