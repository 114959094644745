import React from "react";

import ClassNames from "classnames";
import { Field } from "formik";
import PropTypes from "prop-types";

import UtilLodash from "~/util/lodash";

import FieldError from "~/pages/_components/fields/FieldError";
import MultiSelect from "~/pages/_components/fields/MultiSelect";

export const NAME = "MultiSelect";

export const PROP = {
    types: {
        valueKey: PropTypes.string,
    },
    defaults: {
        valueKey: "value",
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    handleSelect = ({ form, field }, option) => {
        const { errors, valueKey } = this.props;
        const { name, value = [] } = field;

        if (errors && errors[field.name]) {
            const newErrors = UtilLodash.omitElements(errors, field.name);

            form.setErrors(newErrors);
        }
        form.setFieldValue(name, [...value, option[valueKey]]);
    };

    handleDelete = ({ form, field }, option) => {
        const { valueKey } = this.props;

        form.setFieldValue(
            field.name,
            field.value.filter((selectedOption) => selectedOption !== option[valueKey]),
        );
    };

    render() {
        const { name } = this.props;

        return (
            <Field name={name}>
                {(formProps) => {
                    const { field, form } = formProps;
                    const { errors, touched } = form;
                    const hasError = touched[field.name] && errors[field.name];

                    return (
                        <div
                            className={ClassNames("form-group", {
                                "has-error": hasError,
                            })}>
                            <MultiSelect
                                onSelect={(option) => this.handleSelect(formProps, option)}
                                onDelete={(option) => this.handleDelete(formProps, option)}
                                values={formProps.field.value}
                                {...this.props}
                            />
                            {hasError && <FieldError error={errors[field.name]} />}
                        </div>
                    );
                }}
            </Field>
        );
    }
}

export default Component;
