import React from "react";

import ClassNames from "classnames";
import PropTypes from "prop-types";

import { generateId as GenID } from "~/util/general";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import Image from "~/components/Image";

import Namespaces from "./SelectButton.scss";

import useOpenElement from "~/hooks/useOpenElement";

export const { CLASS, NAME } = Namespaces;

// This is the new button to be used.
// Please use this instead of the one in the parent folder

const SIZES = ["sm", "md", "lg"];
const TYPES = ["button", "reset", "submit"];
const VARIANTS = ["primary", "secondary", "link"];

export const PROP = {
    types: {
        /** Whether the button is currently active or not */
        active: PropTypes.bool,
        /** Whether the button should behave like a block element */
        block: PropTypes.bool,
        /** Whether the button is disabled for clicking or not */
        disabled: PropTypes.bool,
        /** Element Id */
        id: PropTypes.string,
        /** A function to call once the button has been clicked */
        onClick: PropTypes.func,
        /** Size variants */
        size: PropTypes.oneOf(SIZES),
        /** Type variants */
        type: PropTypes.oneOf(TYPES),
        /** Style variants */
        variant: PropTypes.oneOf(VARIANTS),
    },
    defaults: {
        active: false,
        block: false,
        disabled: false,
        id: null,
        onClick: null,
        placeholder: null,
        size: null,
        type: "button",
        variant: null,
    },
};

export function Component({ children, className, id, label }) {
    const { ref, isOpen, setOpen } = useOpenElement(false);

    return (
        <Button
            className={ClassNames(CLASS, className, { "is-open": isOpen })}
            id={id}
            onClick={(e) => {
                e.stopPropagation();
                setOpen(!isOpen);
            }}
            htmlRef={ref}
            variant="secondary"
            size="sm">
            <Box flex justify="center" align="center">
                <span>{label}</span>
                <Image src="form-arrow-down.svg" />
            </Box>
            <ul>
                {children &&
                    (Array.isArray(children) ? (
                        children.map(
                            (child) =>
                                child && (
                                    <li key={GenID()} className={child.props.disableOption ? "disabled-option" : ""}>
                                        {child}
                                    </li>
                                ),
                        )
                    ) : (
                        <li key={GenID()} className={children.props.disableOption ? "disabled-option" : ""}>
                            {children}
                        </li>
                    ))}
            </ul>
        </Button>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
