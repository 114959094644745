import PropTypes from "prop-types";

import { EMPTY_STR, ZERO_NUMBER } from "~/constants";
import { MODE } from "~/constants/form";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "wm.funds.sell";

export const PATH = {
    GET_PAPER: "/client.wm.getPaper",
    WEALTH_MANAGEMENT_SELL_FUNDS_PRE: "/client.wm.funds.sell.pre",
    WEALTH_MANAGEMENT_SELL_FUNDS_PREVIEW: "/client.wm.funds.sell.preview",
    WEALTH_MANAGEMENT_SELL_FUNDS_RELOAD_PAPER: "/client.wm.funds.sell.reloadPaper",
    WEALTH_MANAGEMENT_SELL_FUNDS_SEND: "/client.wm.funds.sell.send",
};

export const TYPE = FactoryType(NAME, [
    "CANCEL_CUSTOM_TRANSACTION_REQUEST",
    "CLOSE_TRANSACTION_PREVIEW",
    "GET_PAPER",
    "PREVIEW_CANCEL_REQUEST",
    "PREVIEW_FORM_REQUEST",
    "PREVIEW_FORM_SUCCESS",
    "PRE_FORM_REQUEST",
    "PRE_FORM_SUCCESS",
    "READ_PAPER_INFO",
    "READ_PAPER_SUCCESS",
    "READ_TRANSACTION_REQUEST",
    "READ_TRANSACTION_SUCCESS",
    "RESET_FORM",
    "RESTART_FLOW",
    "SELECTED_ID_PAPER",
    "SEND_FORM_FAILURE",
    "SEND_FORM_REQUEST",
    "SEND_FORM_SUCCESS",
    "SIGN_TRANSACTION_PREVIEW_REQUEST",
    "SIGN_TRANSACTION_PREVIEW_SUCCESS",
    "SIGN_TRANSACTION_REQUEST",
    "TASK_FINISHED",
]);

export const PROP = {};

PROP.types = {
    ...TypesRedux,
    creditAccounts: PropTypes.array,
    currency: PropTypes.number,
    debitAccountAlias: PropTypes.string,
    disclaimer: PropTypes.bool,
    funds: PropTypes.array,
    idDebitAccount: PropTypes.string,
    idPaper: PropTypes.number,
    isFetching: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    mode: PropTypes.string,
    paper: PropTypes.object,
    paperName: PropTypes.string,
    prevMode: PropTypes.string,
    processingSale: PropTypes.number,
    share: PropTypes.number,
    timezoneOffset: PropTypes.number,
    transaction: PropTypes.object,
};

PROP.defaults = {
    ...DefaultsRedux,
    creditAccountAlias: EMPTY_STR,
    creditAccounts: [],
    currency: ZERO_NUMBER,
    disclaimer: false,
    funds: [],
    idDebitAccount: EMPTY_STR,
    idPaper: ZERO_NUMBER,
    isFetching: false,
    isSubmitting: false,
    mode: MODE.EDIT,
    paper: PropTypes.object,
    paperName: EMPTY_STR,
    prevMode: null,
    processingSale: ZERO_NUMBER,
    share: ZERO_NUMBER,
    shareAmount: ZERO_NUMBER,
    timezoneOffset: ZERO_NUMBER,
    transaction: {},
};
