import React from "react";

import { goBack as GoBack } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsStore as SelectorsStoreSettings, SelectorsAction as SelectorsActionSettings } from "~/store/settings";
import * as UtilsI18n from "~/util/i18n";

import FormView from "./ChangeMobilePhoneTicket";

export const NAME = "ChangeMobilePhoneTransaction";

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        transaction: PropTypes.shape({
            idTransaction: PropTypes.string,
            idTransactionStatus: PropTypes.string,
            valueDateAsString: PropTypes.string,
            executing: PropTypes.bool,
        }).isRequired,
        fetching: PropTypes.bool,
        match: PropTypes.objectOf(PropTypes.object).isRequired,
        mode: PropTypes.string.isRequired,
    },
    defaults: {
        transaction: null,
        fetching: false,
        mode: MODE.EDIT,
    },
};

export function Component({ dispatch, fetching, isChangingEnvironment, match, mode, prevMode, transaction }) {
    const { idTransaction } = match.params;

    React.useEffect(() => {
        if (!isChangingEnvironment) {
            dispatch(SelectorsActionSettings.readChangeTransactionDataRequest({ idTransaction }));
        }
    }, [dispatch, idTransaction, isChangingEnvironment]);

    function handleBack() {
        if (prevMode && prevMode !== mode && mode === MODE.PREVIEW) {
            dispatch(SelectorsActionSettings.updateMobilePhonePreviewCancel());
        } else {
            dispatch(GoBack());
        }
    }

    return (
        <Container
            name={NAME}
            wait={fetching}
            step={mode}
            head-title={UtilsI18n.get("change.personal.mobilePhone.title")}
            head-onBack={(mode !== MODE.VIEW && mode !== MODE.EDIT && handleBack) || undefined}
            head-onBackWeb={mode === MODE.EDIT && handleBack}
            scopeToShowNotification={SCOPE.CHANGE_MOBILE_PHONE}>
            {mode === MODE.VIEW && <FormView transaction={transaction} />}
        </Container>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    fetching: SelectorsStoreSettings.getFetching(store),
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    mode: SelectorsStoreSettings.getMode(store),
    prevMode: SelectorsStoreSettings.getPrevMode(store),
    transaction: SelectorsStoreSettings.getChangeTransactionData(store),
});

export default Connect(mapStateToProps)(Component);
