import PropTypes from "prop-types";

import { Types as TypesNazca } from "~/util/prop/nazca";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "loans";

export const PATH = {
    LOANS_LIST: "/loans.list",
    LOANS_READ: "/loans.read",
};

export const TYPE = FactoryType(NAME, [
    "LIST_LOANS_REQUEST",
    "LIST_LOANS_SUCCESS",
    "LOAN_READ_REQUEST",
    "LOAN_READ_SUCCESS",
    "SET_SELECTED_LOAN",
    "TOGGLE_OPTIONS",
    "CLOSE_OPTIONS",
    "SET_SELECTED_FILTER",
    "LIST_LOANS_SUCCESS",
    "LOAN_DETAILS_SUCCESS",
    "LOAN_READ_SUCCESS",
]);

export const PROP = {};
PROP.members = {
    // TODO: numberOfFees and numberOfPaidFees are either number or string throughout the project. Which one is it?
    typesLoan: {
        /** Current Environment identifier */
        idEnvironment: PropTypes.number,
        /** Current Product identifier */
        idProduct: PropTypes.string,
        /** Current Product Type */
        productType: PropTypes.oneOf(["PRESTAMO"]),
        /** Current Product Alias */
        productAlias: PropTypes.string,
        /** Same as Alias but with htmlentities  */
        /** A visual identifier for the loan */
        number: PropTypes.string,
        /** Current's loan next due date */
        nextDueDate: PropTypes.string,
        /** Consolidated amount for the loan */
        consolidatedAmount: TypesNazca.amount,
        /** Total amount for the loan */
        totalAmount: TypesNazca.amount,
        /** Current period's payment amount */
        paymentAmount: TypesNazca.amount,
        /** The amount remaining to be paid for the loan */
        pendingBalance: TypesNazca.amount,
        /** The interest rate for current loan */
        interestRate: PropTypes.number,
        /** The total interest paid for current loan */
        interests: TypesNazca.amount,
        /** The identifier for the currency in which the loan was made */
        currency: PropTypes.string,
    },
    defaultsLoan: {
        numberOfFees: null,
    },
};
PROP.types = {
    fetching: PropTypes.bool,
    isFetchingDetail: PropTypes.bool,
    loansList: PropTypes.arrayOf(PropTypes.shape(PROP.members.loan)),
    selectedLoan: PropTypes.shape(PROP.members.loan),
    ...TypesRedux,
};
PROP.defaults = {
    selectedLoan: {},
    statements: null,
    moreStatements: false,
    pageNumber: 1,
    fetching: false,
    isFetchingDetail: false,
    isOptionsVisible: false,
    selectedFilter: "allFees",
    selectedStatement: null,
    loansList: [],
    ...DefaultsRedux,
};
