import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    detailsRequest: (props) => API.executeWithAccessToken(PATH.DEBITCARDS_READ, props),

    listRequest: () => API.executeWithAccessToken(PATH.DEBITCARDS_LIST),

    changeStatusRequest: (props) => API.executeWithAccessToken(PATH.CHANGE_DEBIT_CARD_STATUS, props),
};

export const SelectorsAction = {
    readDebitCardRequest: (props) => FactoryAction(TYPE.READ_DEBIT_CARD_REQUEST, props),
    readDebitCardFailure: () => FactoryAction(TYPE.READ_DEBIT_CARD_FAILURE),
    readDebitCardSuccess: (props) => FactoryAction(TYPE.READ_DEBIT_CARD_SUCCESS, props),

    listRequest: (props) => FactoryAction(TYPE.LIST_REQUEST, props),
    listFailure: () => FactoryAction(TYPE.LIST_FAILURE),
    listSuccess: (props) => FactoryAction(TYPE.LIST_SUCCESS, props),

    changeStatusRequest: (props) => FactoryAction(TYPE.CHANGE_STATUS_REQUEST, props),
    changeStatusFailure: () => FactoryAction(TYPE.CHANGE_STATUS_FAILURE),
    changeStatusSuccess: (props) => FactoryAction(TYPE.CHANGE_STATUS_SUCCESS, props),

    cleanUp: () => FactoryAction(TYPE.CLEAN_UP),
};

export const SelectorsStore = {
    getFetching: (store) => store[NAME].isFetching,
    getDebitCard: (store) => store[NAME].debitCard,

    getFetchingCards: (store) => store[NAME].debitCards.isFetching,
    getList: (store) => store[NAME].debitCards.list,

    getFetchingChangeStatus: (store) => store[NAME].changeStatus.isFetching,
};
