export const FORM_ID = "comex.export.creditLetterPurchase";
export const FORM_STEP_2 = "comex.export.creditLetterPurchase.step2";

export const FIELD_NAME = {
    CREDIT_LETTER: "creditLetter",
    OPERATION_NUMBER: "operationNumber",
    CREDIT_LETTER_NUMBER: "creditLetterNumber",
    INVOICE_AMOUNT: "invoiceAmount",
    INVOICE_NUMBER: "invoiceNumber",
    UTILIZATION_AMOUNT: "utilizationAmount",
    COURIER_NAME: "courierName",
    INTEREST: "interestRate",
    FILES: "files",
    RECOURSE: "recourse",
    OBSERVATIONS: "observations",
    NOTIFICATION_EMAILS: "notificationEmails",
    DISCLAIMER: "disclaimer",
    SCHEDULER: "scheduler",
    DOCUMENTS: "documents",
    ITEM: "item",
    AMOUNT: "transferAmount",
    VALUE_DATE: "valueDate",
};

export const OTHERS_FIELDS = "othersFields";

export const ID_ACTIVITY_SEND = `comex.export.buyCreditLetter.send`;

export const i18nMap = {
    termsAndConditions: `${FORM_ID}.disclaimer`,
};

export const COLUMN_HEADINGS = ["document", "description", "original", "copies"];

export default {
    FIELD_NAME,
    FORM_ID,
    ID_ACTIVITY_SEND,
    i18nMap,
    COLUMN_HEADINGS,
};
