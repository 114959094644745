import React from "react";

import { Form, Field, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsAction, SelectorsStore } from "~/store/filesDownload";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as I18nUtil from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import Select from "~/pages/_components/fields/Select";
import TextField from "~/pages/_components/fields/TextField";

import Style from "./_Filters.rules.scss";

export const { NAME, CLASS } = Style;

const FORM_ID = "list.documents.filters";

export const PROP = {
    types: {
        categories: PropTypes.array,
        subCategories: PropTypes.array,
        isChangingEnvironment: PropTypes.bool,
    },
    defaults: {
        categories: [],
        subCategories: [],
        isChangingEnvironment: false,
    },
};

export function Component({
    categories,
    dispatch,
    lang,
    setFieldValue,
    subCategories,
    values: { categoryId, name, subCategoryId },
    isChangingEnvironment,
}) {
    const all = [
        {
            label: I18nUtil.get("global.all"),
            value: 0,
        },
    ];

    React.useEffect(() => {
        if (!isChangingEnvironment) {
            dispatch(SelectorsAction.resetFilters());
            dispatch(SelectorsAction.getFileDownloadsListRequest({}));
        }
    }, [dispatch, isChangingEnvironment]);

    const handleCategoryChange = (value) => {
        if(value){
            dispatch(SelectorsAction.getSubCategoriesRequest({ categoryId: parseInt(value, 0) }));
    
            setFieldValue("subCategoryId", 0, false);
    
            dispatch(SelectorsAction.resetList());
    
            dispatch(
                SelectorsAction.getFileDownloadsListRequest({
                    filters: { categoryId: parseInt(value, 0), subCategoryId: 0, name },
                }),
            );
        }
    };

    const handleSubCategoryChange = (value) => {
        if(value){
            dispatch(SelectorsAction.resetList());
    
            dispatch(
                SelectorsAction.getFileDownloadsListRequest({
                    filters: { categoryId, subCategoryId: parseInt(value, 0), name },
                }),
            );
        }
    };

    const capitalize = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const getSubCategories =
        subCategories &&
        subCategories.map(({ subcategoryId: value, [`label${capitalize(lang)}`]: label }) => ({
            label,
            value,
        }));

    return (
        <Form data-name={NAME} className={CLASS}>
            <Box className="search">
                <Field
                    autoFocus={false}
                    component={TextField}
                    idForm={FORM_ID}
                    hidePlaceholder
                    name="name"
                    type="text"
                    value={name}
                />
            </Box>
            <Box className="category">
                <Select
                    clearable={false}
                    labelKey="label"
                    label={I18nUtil.get("global.category")}
                    name="categoryId"
                    onChange={handleCategoryChange}
                    options={all.concat(categories)}
                    value={categoryId}
                    valueKey="value"
                />
            </Box>
            <Box className="sub-category">
                <Select
                    clearable={false}
                    disabled={categoryId === 0}
                    labelKey="label"
                    label={I18nUtil.get("global.subcategory")}
                    options={all.concat(getSubCategories)}
                    valueKey="value"
                    name="subCategoryId"
                    onChange={handleSubCategoryChange}
                    value={subCategoryId}
                />
            </Box>

            <Box flex align="end" justify="center" className="submit">
                <Button variant="primary" type="submit">
                    {I18nUtil.get("product.filters.filter")}
                </Button>
            </Box>
        </Form>
    );
}

const mapStateToProps = (store) => ({
    categories: SelectorsStore.getCategories(store),
    subCategories: SelectorsStore.getSubCategories(store),
    pageNumber: SelectorsStore.getPageNumber(store),
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
});

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        mapPropsToValues: () => ({
            categoryId: 0,
            subCategoryId: 0,
            name: EMPTY_STR,
        }),
        handleSubmit: (values, formikBag) => {
            const { dispatch } = formikBag.props;

            dispatch(SelectorsAction.resetList());

            dispatch(
                SelectorsAction.getFileDownloadsListRequest({
                    filters: { ...values },
                }),
            );
        },
    }),
)(Component);
