import { FactoryAction } from "~/util/redux";

import { NAME, TYPE } from "./_consts";

export const SelectorsAction = {
    showNotification: (props) => FactoryAction(TYPE.SHOW_NOTIFICATION, props),
    removeNotification: () => FactoryAction(TYPE.REMOVE_NOTIFICATION),
};

export const SelectorsStore = {
    getMessage: (store) => store[NAME].message,
    getLevel: (store) => store[NAME].level,
    getScopes: (store) => store[NAME].scopes,
    getAutoDismiss: (store) => store[NAME].autoDismiss,
    getRemove: (store) => store[NAME].remove,
    getChildren: (store) => store[NAME].children,
};
