import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";
import * as Yup from "yup";

import { EMPTY_STR, ONLY_NUMBER, LEVEL, SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { SelectorsStore as SelectorsStoreComex, SelectorsAction as SelectorsActionComex } from "~/store/comex";
import {
    SelectorsStore as SelectorsStoreComexExport,
    SelectorsAction as SelectorsActionComexExport,
    PROP as PropComex,
} from "~/store/comex/export";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsAction as SelectorActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionTemplate } from "~/store/template";
import * as ConfigUtil from "~/util/config";
import { formatDate as FormatDate, toDate } from "~/util/date";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import EmailList from "~/pages/_components/fields/EmailList";
import TextArea from "~/pages/_components/fields/TextArea";
import TextField from "~/pages/_components/fields/TextField";
import AmountField from "~/pages/_components/fields/formik/AmountField";
import Selector from "~/pages/_components/fields/formik/Selector";

import OperationNumber from "~/pages/comEx/_components/OperationNumber";
import OperationNumberDetail from "~/pages/comEx/_components/OperationNumberDetail";
import { Termsandconditions } from "~/pages/forms/_components/_fields";
import Scheduler from "~/pages/forms/_components/_fields/Scheduler";

import Style from "../../../Step1Edit.rules.scss";

const FORM_ID = "comex.export.authorizationDDiscrepancies";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...PropComex.types,
    },
    defaults: {
        ...PropComex.defaults,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        draftLoaded: false,
        loadOperationNumber: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(SelectorsActionComexExport.authorizationDocumentsDiscrepanciesPreRequest());
    }

    componentDidUpdate() {
        const {
            dispatch,
            setFieldError,
            setFieldTouched,
            setValues,
            transaction,
            match,
            preData: { availableOperationList },
            values,
            setFieldValue,
            formData = {},
        } = this.props;
        const { operationNumber } = match.params;
        const { draftLoaded, loadOperationNumber } = this.state;

        if (!loadOperationNumber && availableOperationList.length > 0 && !values.operationNumber && operationNumber) {
            const operationSelected = availableOperationList.filter(
                (item) => item.othersFields.NROOPERACION === operationNumber,
            );
            this.setState({
                loadOperationNumber: true,
            });

            if (operationSelected.length > 0) {
                setFieldValue("item", operationSelected[0]);
                setFieldValue("operationNumber", operationNumber);
            }
        }

        // si viene trasaction.data, no estoy volviendo del paso 2 y no se cargo el draft -> cargo draft
        if (transaction?.data && Object.keys(formData).length === 0 && !draftLoaded) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                draftLoaded: true,
            });
            const transactionData = transaction?.data;
            /* carga de errores */
            if (transactionData.backendFormErrors?.cdpErrorFields) {
                const { cdpErrorFields } = transactionData.backendFormErrors;
                // eslint-disable-next-line array-callback-return
                cdpErrorFields.map((errorField) => {
                    setFieldError(errorField, " ");
                    setFieldTouched(errorField, true);
                });
            }
            if (transactionData.backendFormErrors?.message) {
                const errorMessage = transactionData?.backendFormErrors?.message;
                dispatch(
                    SelectorActionNotification.showNotification({
                        message: errorMessage,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.COMEX_DISCREPANCY_DOCUMENT_AUTHORIZATION],
                    }),
                );
            }

            /*  carga del draft - modificaciones para que agarren bien los fields */
            if (transactionData.valueDate) {
                const valueDate = toDate(transactionData.valueDate);
                transactionData.valueDate = valueDate;
            }

            setValues(transaction.data);
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(GoBack());
    };

    buildOptions = () => {
        return [
            { id: EMPTY_STR, label: EMPTY_STR },
            {
                id: "INCREMENTO",
                label: UtilsI18n.get(`${FORM_ID}.incremento`),
            },
            {
                id: "DISMINUCION",
                label: UtilsI18n.get(`${FORM_ID}.disminucion`),
            },
        ];
    };

    handleFormKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            event.preventDefault();
        }
    };

    handleFieldKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            this.handleSearch();
        }
    };

    handleSelectChange = (id) => {
        const { setFieldValue } = this.props;
        setFieldValue("increment", id);
    };

    handleSelectChangeFieldName = (id, fieldName) => {
        const { setFieldValue } = this.props;
        setFieldValue(fieldName, id);
    };

    handleItemSelect = (item) => {
        const { setFieldValue, setFieldError } = this.props;

        const { othersFields } = item;
        if (othersFields) {
            setFieldValue("operationNumber", othersFields.NROOPERACION);
            setFieldValue("item", item);
            setFieldError("operationNumber", null);
        } else {
            setFieldValue("item", null);
            setFieldValue("operationNumber", EMPTY_STR);
        }
    };

    handleModalLoad = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionComex.toggleCreditLetterList());
    };

    handleBlur = () => {
        const { values, preData, setFieldValue } = this.props;
        const { availableOperationList } = preData;
        if (values.operationNumber) {
            const operationSelected = availableOperationList.filter(
                (item) => item.othersFields.NROOPERACION === values.operationNumber,
            );
            if (operationSelected.length > 0) {
                setFieldValue("item", operationSelected[0]);
            } else {
                setFieldValue("item", null);
                setFieldValue("operationNumber", EMPTY_STR);
            }
        }
    };

    handleCancel = () => {
        const { dispatch, setSubmitting, transaction } = this.props;

        dispatch(
            SelectorsActionForm.cancelTransactionCustom({
                credentials: {},
                idTransaction: transaction.idTransaction,
                formikBag: { setSubmitting },
            }),
        );
    };

    handleDraft = () => {
        const { dispatch, transaction, values } = this.props;
        dispatch(
            SelectorsActionForm.saveDraft({
                idActivityDraft: "comex.export.discrepancyDocumentAuthorization.send",
                idTransactionToSave: transaction?.idTransaction,
                transactionData: values || {},
            }),
        );
    };

    handleTemplateSave = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionTemplate.createTemplate());
    };

    handleTemplateLoad = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionTemplate.toggleTemplateList());
    };

    handleSelectTemplate = (template) => {
        const { setValues, setErrors } = this.props;
        const templateToBeLoaded = template;
        setValues(templateToBeLoaded);
        setErrors({});
    };

    render() {
        const { values, emailValidationRegex, preData, transaction } = this.props;
        const { nextValidDate, unavailableDays, availableOperationList } = preData;
        const termsAndConditions = "comex.export.authorizationDDiscrepancies.disclaimer";
        const i18nMap = {
            termsAndConditions,
        };
        const cancelEnabled = transaction && transaction.idTransactionStatus === STATUS.DRAFT;
        const othersFields = values.item ? values.item.othersFields : null;

        const currency = othersFields ? othersFields.MONEDA : EMPTY_STR;

        const data = {
            options: [
                {
                    id: currency,
                    label: UtilsI18n.get(`currency.label.${currency}`),
                },
            ],
        };

        const typeList = [
            {
                value: "APPROVAL",
                label: UtilsI18n.get(`${FORM_ID}.submissionType.option.1`),
            },
            {
                value: "DISCREPANCIES",
                label: UtilsI18n.get(`${FORM_ID}.submissionType.option.2`),
            },
            {
                value: "FOLLOWING",
                label: UtilsI18n.get(`${FORM_ID}.submissionType.option.3`),
            },
        ];

        return (
            <React.Fragment>
                <Form
                    className="col col-12 col-lg-6 col-md-9 col-sm-12"
                    id={Style.ID}
                    noValidate="novalidate"
                    onKeyDown={this.handleFormKeyDown}>
                    {/* <div className="form-section loadTemplates">
                        <Button
                            onClick={this.handleTemplateLoad}
                            label="forms.templates.load"
                            bsStyle="default"
                            image="template.svg"
                        />
                    </div> */}
                    <section className="fields container--layout align-items-center flex-grow">
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.section.operation`)}</h3>
                        </Box>

                        <Box flex align="end" className="form-group search-field">
                            <Field
                                idForm={FORM_ID}
                                name="operationNumber"
                                hidePlaceholder
                                pattern={ONLY_NUMBER}
                                type="text"
                                component={TextField}
                                onBlur={this.handleBlur}
                                tooltip={UtilsI18n.get(`${FORM_ID}.operationNumber.tooltip`)}
                            />

                            <Box className="ml-3 mb-2">
                                <Button
                                    onClick={this.handleModalLoad}
                                    bsStyle="primary"
                                    label="global.search"
                                    className="search"
                                />
                            </Box>
                        </Box>

                        {values.item && <OperationNumberDetail othersFields={othersFields} idForm={FORM_ID} />}

                        <Field
                            idForm={FORM_ID}
                            name="invoiceNumber"
                            hidePlaceholder
                            type="text"
                            maxLength={30}
                            component={TextField}
                            onBlur={this.handleBlur}
                        />
                        <Field
                            clearable={false}
                            component={AmountField}
                            data={data}
                            idForm={FORM_ID}
                            name="amountField"
                            placeholder={EMPTY_STR}
                            searchable={false}
                            decimalPlaces={2}
                            fixedDecimalScale
                            hideCurrency={!currency}
                            handleBlur={this.handleAmountBlur}
                            maxLength={ConfigUtil.getInteger("amount.length")}
                        />

                        <Field
                            idForm={FORM_ID}
                            name="submissionType"
                            component={Selector}
                            options={typeList}
                            hidePlaceholder
                        />

                        {values.submissionType === "FOLLOWING" && (
                            <Field
                                component={TextArea}
                                idForm={FORM_ID}
                                name="discrepancies"
                                mode={MODE.EDIT}
                                className="form-control"
                                maxLength={500}
                                hidePlaceholder
                                tooltip={UtilsI18n.get(`${FORM_ID}.discrepancies.tooltip`)}
                            />
                        )}

                        <Field
                            component={EmailList}
                            idForm={FORM_ID}
                            name="notificationEmails"
                            hideSwiftTip
                            className="form-group email-field"
                            renderSuggestion={false}
                            value={values.notificationEmails}
                            data={{ emailValidationRegex, addMessage: EMPTY_STR }}
                            isRequired={false}
                            optional={UtilsI18n.get("form.field.optional")}
                            tooltip={UtilsI18n.get(`${FORM_ID}.notificationEmails.tooltip`)}
                        />

                        {nextValidDate && (
                            <Field
                                component={Scheduler}
                                data={{
                                    firstWorkingDate: nextValidDate,
                                    maxDaysToSchedule: ConfigUtil.get("client.comex.valueDate.maxDaysToSchedule"),
                                    nonWorkingDays: unavailableDays,
                                    lang: "es",
                                    mode: "edit",
                                    programable: false,
                                    schedulable: true,
                                }}
                                name="scheduler"
                                idForm={FORM_ID}
                            />
                        )}
                        <hr />
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">
                                {UtilsI18n.get(`${FORM_ID}.section.showPreview`)}
                            </h3>
                        </Box>
                        <Field
                            component={Termsandconditions}
                            idForm={FORM_ID}
                            name="disclaimer"
                            mode={MODE.EDIT}
                            className="form-control"
                            i18nMap={i18nMap}
                        />
                    </section>

                    <footer>
                        {cancelEnabled && <Button onClick={this.handleCancel} label="forms.cancelTransaction.link" />}
                        <Button type="submit" label="global.next" bsStyle="primary" />
                    </footer>
                </Form>
                <OperationNumber
                    list={availableOperationList}
                    idForm={FORM_ID}
                    handleItemClick={this.handleItemSelect}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        preData: SelectorsStoreComexExport.getAuthorizationDocumentsDiscrepanciesPreData(store),
        formData: SelectorsStoreComexExport.getAuthorizationDocumentsDiscrepanciesFormData(store),
        transaction: SelectorsStoreComex.getTransaction(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: false,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: () =>
            Yup.object().shape({
                // operationNumber: Yup.string().required(UtilsI18n.get(`client.${FORM_ID}.operationNumber.required`)),
            }),
        mapPropsToValues: ({ formData }) => {
            return {
                scheduler: formData?.scheduler || EMPTY_STR,
                operationNumber: formData?.operationNumber,
                invoiceNumber: formData?.invoiceNumber,
                dueDate: formData?.dueDate,
                boardingDate: formData?.boardingDate,
                increment: formData?.increment,
                otherModifications: formData?.otherModifications,
                observations: formData?.observations,
                notificationEmails: formData?.notificationEmails,
                amountField: formData?.amountField,
                item: formData?.item,
                discrepancies: formData?.discrepancies,
                submissionType: formData?.submissionType || "APPROVAL",
            };
        },
        handleSubmit: (props, formikBag) => {
            const { dispatch } = formikBag.props;
            const {
                item,
                amountField,
                scheduler,
                dueDate,
                boardingDate,
                notificationEmails,
                discrepancies,
                submissionType,
            } = props;

            const originalDueDate = item?.othersFields ? item.othersFields.FECHAVTO : EMPTY_STR;
            const oldAmount = item?.othersFields ? item.othersFields.IMPORTE : EMPTY_STR;
            const beneficiary = item?.othersFields ? item.othersFields.BENEFICIARIO : EMPTY_STR;
            const creditLetterNumber = item?.othersFields ? item.othersFields.CARTADECREDITO : EMPTY_STR;
            const amount = amountField ? amountField.amount : 0;
            const valueDateString = scheduler ? FormatDate(scheduler.valueDate) : EMPTY_STR;
            const dueDateString = dueDate ? FormatDate(new Date(dueDate)) : EMPTY_STR;
            const boardingDateString = boardingDate ? FormatDate(new Date(boardingDate)) : EMPTY_STR;
            const invoiceAmountCurrency = item?.othersFields ? item.othersFields.MONEDA : EMPTY_STR;
            const formData = {
                ...props,
                discrepancies: submissionType === "FOLLOWING" ? discrepancies : null,
                valueDate: valueDateString,
                dueDate: dueDateString,
                boardingDate: boardingDateString,
                originalDueDate,
                amountField,
                invoiceAmount: amount,
                oldAmount,
                beneficiary,
                invoiceAmountCurrency,
                creditLetterNumber,
                notificationEmails: notificationEmails || [],
                scheduler,
            };

            dispatch(
                SelectorsActionComexExport.authorizationDocumentsDiscrepanciesPreviewRequest({
                    formData,
                    formikBag,
                }),
            );
        },
    }),
)(Component);
