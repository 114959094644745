import React from "react";

import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { COMMA, EMPTY_STR, SPACE_STR } from "~/constants";
import { STATUS } from "~/constants/transaction";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import {
    SelectorsStore as SelectorsStoreComexBondsAndGuarantees,
    SelectorsAction as SelectorsActionComexBondsAndGuarantees,
} from "~/store/comex/bondsAndGuarantees";
import { SelectorsAction as SelectorsActionFile } from "~/store/files";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import DeviceUtils from "~/util/device";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import DataDate from "~/components/DataDate";
import DataNumber from "~/components/DataNumber";
import HighOrder from "~/components/HighOrder";
import Token from "~/components/Token/Token";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import TicketPrintInformation from "~/pages/_components/TicketPrintInformation";

import { VALIDITY } from "~/pages/comEx/bondsAndGuarantees/request/Step1Edit/Step1Edit";
import FormatNumber from "~/pages/wm/funds/_component/FormatNumber";

import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        isSubmitting: PropTypes.bool,
    },
    defaults: {
        isSubmitting: false,
    },
};

const printForm = () => {
    const elements = window.document.getElementsByClassName("no_print");

    for (let i = 0; i < elements.length; i += 1) {
        elements[i].style.display = "none";
    }

    window.print();

    for (let i = 0; i < elements.length; i += 1) {
        elements[i].style.display = EMPTY_STR;
    }

    return true;
};

export function Component(props) {
    const { dispatch, formData, isSubmitting, location, transaction } = props;
    const {
        agreedCommission,
        application,
        attachments,
        attachmentsNames,
        authorized1Document,
        authorized1Name,
        authorized2Document,
        authorized2Name,
        automaticRenewal,
        beneficiaryAddress,
        beneficiaryCountryId,
        beneficiaryDocument,
        beneficiaryName,
        daysOf,
        dueDate,
        expenditure,
        expenditureOther,
        finalDueDate,
        formatType,
        guaranteeAmount,
        guaranteeAmountCurrency,
        guaranteedAddress,
        guaranteedDocument,
        guaranteedName,
        guaranteeObligations,
        guaranteeType,
        guaranteeTypeOther,
        informantBank,
        internationalIssued,
        issued,
        linkWithCompany,
        minimunAmount,
        notarialCertification,
        notificationDays,
        observations,
        partialPayments,
        payerAddress,
        payerContact,
        payerName,
        subjectGuarantee,
        validity,
        validityDate,
        valueDate,
    } = formData;
    const prefix = "comex.bondsAndGuarantees.requestBondsAndGuarantees";
    const showComission = agreedCommission !== EMPTY_STR;
    const showAmount = minimunAmount !== EMPTY_STR;

    const isFromTransactionsHistory = location.pathname.includes("/ticket");
    const data =
        isFromTransactionsHistory && transaction?.idTransactionStatus !== STATUS.DRAFT ? transaction.data : formData;

    const shareTicket = () => {
        dispatch(
            SelectorsActionTransactions.shareTicketRequest({
                transactionData: data,
                idActivity: `${prefix}.send`,
            }),
        );
    };

    const downloadFile = (idFile, fileName) => {
        dispatch(SelectorsActionFile.downloadFileRequest({ idFile, fileName }));
    };

    const fileIds = attachments ? attachments.split(COMMA) : EMPTY_STR;

    return (
        <Form id={Style.ID}>
            <TicketPrintInformation />
            <Box directionColumn align="flex-start" className="mb-3">
                <Box className="info-label">
                    <h4 className="bold">{UtilsI18n.get(`${prefix}.valueDate.label`)}</h4>
                    <DataDate value={valueDate} />
                </Box>
                {formatType && (
                    <Box className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${prefix}.formatType.label`)}</h4>
                        <span>{UtilsI18n.get(`${prefix}.formatType.${formatType}`)}</span>
                    </Box>
                )}
                {application && (
                    <Box className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${prefix}.application.label`)}</h4>
                        <span>{UtilsI18n.get(`${prefix}.application.${application}`)}</span>
                    </Box>
                )}
                {issued && (
                    <Box className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${prefix}.issued.label`)}</h4>
                        <span>{UtilsI18n.get(`${prefix}.issued.${issued}`)}</span>
                    </Box>
                )}

                {issued === "INTERNACIONAL" && (
                    <Box>
                        {internationalIssued && (
                            <Box className="info-label">
                                <span />
                                <span>{UtilsI18n.get(`${prefix}.internationalIssued.${internationalIssued}`)}</span>
                            </Box>
                        )}
                        {informantBank && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.informantBank.label`)}</h4>
                                <span>{informantBank}</span>
                            </Box>
                        )}
                        {expenditure && !expenditureOther && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.expenditure.label`)}</h4>
                                <span>{UtilsI18n.get(`${prefix}.expenditure.${expenditure}`)}</span>
                            </Box>
                        )}
                        {expenditureOther && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.expenditure.label`)}</h4>
                                <span>{expenditureOther}</span>
                            </Box>
                        )}
                    </Box>
                )}

                {payerName && (
                    <Box className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${prefix}.payerName.label`)}</h4>
                        <span>{payerName}</span>
                    </Box>
                )}
                {payerAddress && (
                    <Box className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${prefix}.payerAddress.label`)}</h4>
                        <span>{payerAddress}</span>
                    </Box>
                )}
                {payerContact && (
                    <Box className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${prefix}.payerContact.label`)}</h4>
                        <span>{payerContact}</span>
                    </Box>
                )}
                {beneficiaryDocument && beneficiaryDocument.document && (
                    <Box className="info-label">
                        <h4 className="bold">
                            {UtilsI18n.get(`${prefix}.beneficiaryDocument.documentNumberAndCountry`)}
                        </h4>
                        <span>
                            {UtilsI18n.get(`country.name.${beneficiaryDocument.country}`)}
                            {SPACE_STR}
                            {UtilsI18n.get(`documentType.label.${beneficiaryDocument.type}`)}
                            {SPACE_STR}
                            {beneficiaryDocument.document}
                        </span>
                    </Box>
                )}
                {beneficiaryName && (
                    <Box className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${prefix}.beneficiaryName.label`)}</h4>
                        <span>{beneficiaryName}</span>
                    </Box>
                )}
                {beneficiaryAddress && (
                    <Box className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${prefix}.beneficiaryAddress.label`)}</h4>
                        <span>{beneficiaryAddress}</span>
                    </Box>
                )}
                {beneficiaryCountryId && (
                    <Box className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${prefix}.beneficiaryCountry`)}</h4>
                        <span>{UtilsI18n.get(`country.name.${beneficiaryCountryId}`)}</span>
                    </Box>
                )}
                {guaranteeObligations && (
                    <Box className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${prefix}.guaranteeObligations.label`)}</h4>
                        <span>{UtilsI18n.get(`${prefix}.guaranteeObligations.${guaranteeObligations}`)}</span>
                    </Box>
                )}
                {guaranteeObligations === "OTROS" && (
                    <Box>
                        {guaranteedDocument && guaranteedDocument.document && (
                            <Box className="info-label">
                                <h4 className="bold">
                                    {UtilsI18n.get(`${prefix}.guaranteed.documentNumberAndCountry`)}
                                </h4>
                                <span>
                                    {UtilsI18n.get(`country.name.${guaranteedDocument.country}`)}
                                    {SPACE_STR}
                                    {UtilsI18n.get(`documentType.label.${guaranteedDocument.type}`)}
                                    {SPACE_STR}
                                    {guaranteedDocument.document}
                                </span>
                            </Box>
                        )}

                        {guaranteedName && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.guaranteedName.label`)}</h4>
                                <span>{guaranteedName}</span>
                            </Box>
                        )}
                        {guaranteedAddress && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.guaranteedAddress.label`)}</h4>
                                <span>{guaranteedAddress}</span>
                            </Box>
                        )}
                    </Box>
                )}
                {linkWithCompany && (
                    <Box className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${prefix}.linkWithCompany.label`)}</h4>
                        <span>{linkWithCompany}</span>
                    </Box>
                )}
                {guaranteeAmount && (
                    <Box className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${prefix}.guaranteeAmount.label`)}</h4>
                        <span>
                            <DataNumber
                                value={guaranteeAmount}
                                prefix={UtilsI18n.get(`core.currency.label.${guaranteeAmountCurrency}`)}
                            />
                        </span>
                    </Box>
                )}
                {validity && (
                    <Box className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${prefix}.validity.label`)}</h4>
                        <span className="bold">{UtilsI18n.get(`${prefix}.validity.${validity}`)}</span>
                        {validity !== VALIDITY.DATE_OF_ISSUE && validityDate !== EMPTY_STR && (
                            <DataDate value={validityDate} />
                        )}
                    </Box>
                )}
                {dueDate !== EMPTY_STR && (
                    <Box className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${prefix}.dueDate.label`)}</h4>
                        <span>
                            <DataDate value={dueDate} />
                        </span>
                    </Box>
                )}
                <Box className="info-label">
                    <h4 className="bold">{UtilsI18n.get(`${prefix}.automaticRenewal`)}</h4>

                    {automaticRenewal ? (
                        <Box>
                            <span className="bold">{UtilsI18n.get(`${prefix}.automaticRenewal.yes`)}.</span>
                            <span>{UtilsI18n.get(`${prefix}.daysOf.except`)}</span>
                            {daysOf} <span>{UtilsI18n.get(`${prefix}.daysOf.notify`)}</span>
                        </Box>
                    ) : (
                        <h4 className="bold">{UtilsI18n.get(`${prefix}.automaticRenewal.no`)}</h4>
                    )}
                </Box>

                {automaticRenewal && notificationDays && (
                    <Box>
                        <Box className="info-label">
                            <h4 className="bold">{UtilsI18n.get(`${prefix}.notificationDays.label`)}</h4>
                            <span className="bold">{notificationDays}</span>
                        </Box>
                        <span className="disclaimer-text">
                            {UtilsI18n.get(`${prefix}.notificationDays.disclaimer`)}
                        </span>
                    </Box>
                )}
                {finalDueDate !== EMPTY_STR && (
                    <Box className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${prefix}.finalDueDate.label`)}</h4>
                        <span>
                            <DataDate value={finalDueDate} />
                        </span>
                    </Box>
                )}
                {partialPayments && (
                    <Box className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${prefix}.partialPayments.label`)}</h4>
                        <span>{UtilsI18n.get(`${prefix}.partialPayments.${partialPayments}`)}</span>
                    </Box>
                )}
                {guaranteeType && !guaranteeTypeOther && (
                    <Box className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${prefix}.guaranteeType.label`)}</h4>
                        <span>{UtilsI18n.get(`${prefix}.guaranteeType.${guaranteeType}`)}</span>
                    </Box>
                )}
                {guaranteeTypeOther && (
                    <Box className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${prefix}.guaranteeType.label`)}</h4>
                        {guaranteeTypeOther}
                    </Box>
                )}
                {subjectGuarantee && (
                    <Box className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${prefix}.subjectGuarantee.label`)}</h4>
                        <span>{subjectGuarantee}</span>
                    </Box>
                )}
                <Box className="info-label">
                    <h4 className="bold">{UtilsI18n.get(`${prefix}.notarialCertification`)}</h4>

                    {notarialCertification ? (
                        <span>{UtilsI18n.get(`${prefix}.notarialCertification.yes`)}</span>
                    ) : (
                        <span>{UtilsI18n.get(`${prefix}.notarialCertification.no`)}</span>
                    )}
                </Box>

                {showComission && (
                    <Box className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${prefix}.agreedCommission.label`)}</h4>
                        <span>
                            <FormatNumber suffix=" %" amount={parseFloat(agreedCommission)} />
                        </span>
                    </Box>
                )}
                {showAmount && (
                    <Box className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${prefix}.minimunAmount.label`)}</h4>
                        <span>
                            <FormattedAmount currency="2222" quantity={parseFloat(minimunAmount)} />
                        </span>
                    </Box>
                )}
                {authorized1Document && authorized1Document.document && authorized1Document.type !== -1 && (
                    <Box>
                        <Box className="info-label">
                            <h4 className="bold">{UtilsI18n.get(`${prefix}.authorized1.documentNumber`)}</h4>
                            <span>
                                {UtilsI18n.get(`country.name.${authorized1Document.country}`)}
                                {SPACE_STR}
                                {UtilsI18n.get(`documentType.label.${authorized1Document.type}`)}
                                {SPACE_STR}
                                {authorized1Document.document}
                            </span>
                        </Box>

                        {authorized1Name && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.authorized1Name.label`)}</h4>
                                <span>{authorized1Name}</span>
                            </Box>
                        )}
                    </Box>
                )}
                {authorized2Document && authorized2Document.document && authorized2Document.type !== -1 && (
                    <Box>
                        <Box className="info-label">
                            <h4 className="bold">{UtilsI18n.get(`${prefix}.authorized2.documentNumber`)}</h4>
                            <span>
                                {UtilsI18n.get(`country.name.${authorized2Document.country}`)}
                                {SPACE_STR}
                                {UtilsI18n.get(`documentType.label.${authorized2Document.type}`)}
                                {SPACE_STR}
                                {authorized2Document.document}
                            </span>
                        </Box>
                        {authorized2Name && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.authorized2Name.label`)}</h4>
                                <span>{authorized2Name}</span>
                            </Box>
                        )}
                    </Box>
                )}

                {attachments && (
                    <Box className="info-label">
                        <Box className="bold"> {UtilsI18n.get(`${prefix}.attachments.heading1`)}</Box>
                        {fileIds.map((fileId, index) => (
                            <Box className="data-wrapper">
                                <span className="data-label">
                                    <a onClick={() => downloadFile(fileId, attachmentsNames[index])}>
                                        {attachmentsNames[index]}
                                    </a>
                                </span>
                            </Box>
                        ))}
                    </Box>
                )}
                {observations && (
                    <Box className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${prefix}.observations.label`)}</h4>
                        <span className="data-date">{observations}</span>
                    </Box>
                )}
            </Box>

            <div className="no_print">
                <Token labelButton="global.send" isSubmitting={isSubmitting} />
            </div>
            {!DeviceUtils.isMobileNative() && (
                <div className="buttonsTicketConfirmation">
                    <Button
                        block={false}
                        bsStyle="link"
                        label="comex.preview.print.label"
                        onClick={() => printForm()}
                    />
                </div>
            )}
            <div className="buttonsTicketConfirmation">
                <Button bsStyle="link" block={false} label="forms.ticket.printPDF" onClick={() => shareTicket()} />
            </div>
        </Form>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        formData: SelectorsStoreComexBondsAndGuarantees.getRequestBondsAndGuaranteesOpenFormData(store),
        transaction: SelectorsStoreComexBondsAndGuarantees.getTransaction(store),
        user: SelectorsStoreSession.getUser(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithRouter,
    WithFormik({
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        validationSchema: (props) => {
            const { user } = props;

            return Yup.object().shape({
                otp: user.needsBiometric
                    ? Yup.string().nullable()
                    : Yup.string().required(UtilsI18n.get("form.credential.otp.required")),
            });
        },
        handleSubmit: ({ otp }, formikBag) => {
            const {
                dispatch,
                formData,
                history,
                transaction: { idActivity, idTransaction, idTransactionStatus },
            } = formikBag.props;

            const isFromTransactionsHistory = history.location.pathname.includes("/ticket");

            if (isFromTransactionsHistory && idTransactionStatus !== STATUS.DRAFT) {
                dispatch(
                    SelectorsActionComex.signTransaction({
                        idForm: null,
                        idActivity,
                        idTransaction,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            } else {
                dispatch(
                    SelectorsActionComexBondsAndGuarantees.requestBondsAndGuaranteesSendRequest({
                        formData,
                        credentials: { otp },
                        formikBag,
                        idTransaction,
                    }),
                );
            }
        },
    }),
)(Component);
