import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    listRequest: (props) => API.executeWithAccessToken(PATH.REQUEST_LOAN_COMBOS_LIST, props),
};

export const SelectorsAction = {
    clearList: (props) => FactoryAction(TYPE.CLEAR_LIST, props),
    listRequest: (props) => FactoryAction(TYPE.LIST_REQUEST, props),
    listFailure: (props) => FactoryAction(TYPE.LIST_FAILURE, props),
    listSuccess: (props) => FactoryAction(TYPE.LIST_SUCCESS, props),
    changeValue: (props) => FactoryAction(TYPE.CHANGE_VALUE, props),
    changeValueSuccess: (props) => FactoryAction(TYPE.CHANGE_VALUE_NOT, props),
};

export const SelectorsStore = {
    getFetching: (store) => store[NAME].isFetching,
    getListTerm: (store) =>
        store[NAME].comboListTerm
            ? store[NAME].comboListTerm.map((option) => ({
                  id: option.id,
                  label: option.label,
              }))
            : null,
    getListDestination: (store) =>
        store[NAME].comboListDestination
            ? store[NAME].comboListDestination.map((option) => ({
                  id: option.id,
                  label: option.label,
              }))
            : null,
    getChangetValue: (store) => store[NAME].changeValue,
};
