import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { MODE } from "~/constants/form";
import Style from "~/containers/Internal/Form/Steps.rules.scss";
import * as UtilI18n from "~/util/i18n";
import { Categorize } from "~/util/prop";

import { Section } from "~/components/Component";
import Image from "~/components/Image";

import Header from "../../_Header";
import Form from "./index";

export const { NAME, TYPE } = Style;
export const PROP = {
    types: {
        "head-onBack": PropTypes.func,
        "head-title": PropTypes.string,
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        hideSteps: PropTypes.bool,
        step: PropTypes.string.isRequired,
    },
    defaults: {
        "head-onBack": null,
        "head-title": EMPTY_STR,
        className: EMPTY_STR,
        hideSteps: false,
    },
};

export function Component(props) {
    const { children, className, head, hideSteps, scopeToShowNotification, step } = Categorize(props);
    const { onBack, onBackWeb, title } = head;

    const modes = [
        {
            image: "transactions-step.svg",
            step: MODE.EDIT,
            title: UtilI18n.get("forms.steps.transactionDetails"),
        },
        {
            image: "icono-llave-digital.svg",
            step: MODE.PREVIEW,
            title: UtilI18n.get("forms.steps.confirmation"),
        },
        {
            image: "check-step.svg",
            step: MODE.VIEW,
            title: UtilI18n.get("forms.steps.summary"),
        },
    ];

    const hasBackClass = onBack || onBackWeb ? "hasBack" : EMPTY_STR;
    const hideStepsSection = hideSteps ? "hide-steps" : EMPTY_STR;
    const stepIndex = modes.findIndex((e) => e.step === step);

    const finalHeader = (
        <Header className={`${hasBackClass}`}>
            {hasBackClass === "hasBack" && (
                <Header.Button
                    aria-label="Back"
                    image="left-arrow.svg"
                    onClick={onBack || onBackWeb}
                    role="navigation"
                />
            )}
            <Header.Title value={title} />
        </Header>
    );

    return (
        <Form
            {...props}
            className={className ? `${className} ${Style.ID}` : Style.ID}
            header={finalHeader}
            scopeToShowNotification={scopeToShowNotification}>
            <Header>
                <ul className={`${Style.STEPS} ${hideStepsSection}`}>
                    {modes.map((currentMode, index) => {
                        return (
                            <li className={(index <= stepIndex && Style.ACTIVE) || EMPTY_STR}>
                                <Image src={currentMode.image} classNameInner="step-icon" />
                                <span>{currentMode.title}</span>
                            </li>
                        );
                    })}
                    <hr />
                </ul>
            </Header>
            <Section>{children}</Section>
        </Form>
    );
}

Component.displayName = NAME;
Component.proptTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
