import { routerMiddleware as MiddlewareRouter } from "connected-react-router";
import { applyMiddleware as Apply } from "redux";
import MiddlewareSaga from "redux-saga";

import History from "~/util/history";

export const Saga = MiddlewareSaga();

export const Router = MiddlewareRouter(History);

export default Apply(Router, Saga);
