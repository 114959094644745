import React from "react";

import { Field } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { LANGUAGE, ID_FORM } from "~/constants";
import { MODE } from "~/constants/form";
import { SelectorsStore as SelectorsStoreForm } from "~/store/form";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import * as ConfigUtils from "~/util/config";
import {
    isUseAlternativeLabel as IsUseAlternativeLabel,
    isVisible as IsVisible,
    isRequired as IsRequired,
} from "~/util/form";
import * as I18nUtils from "~/util/i18n";
import UtilLodash from "~/util/lodash";

import I18n from "~/components/I18n";

import * as FormFieldsComponents from "~/pages/forms/_components/_fields";
import Scheduler from "~/pages/forms/_components/_fields/Scheduler";

export const NAME = "Fields";

export const PROP = {
    types: {
        currentLang: PropTypes.string,
        formData: PropTypes.object,
        isFromBackoffice: PropTypes.bool,
        metadata: PropTypes.object,
        mode: PropTypes.string,
        validations: PropTypes.object,
    },
    defaults: {
        currentLang: LANGUAGE.ES,
        formData: {},
        isFromBackoffice: false,
        metadata: {},
        mode: MODE.EDIT,
        validations: {},
    },
};

export function Component(props) {
    const { currentLang, formData, isFromBackoffice, metadata, mode, prevMode, tooltip, validations } = props;
    const { enabledWeekDays, firstWorkingDate, idForm, nonWorkingDays, programable, schedulable } = metadata;
    let maxDateToScheduleConfig;

    switch (idForm) {
        case ID_FORM.SALARY_PAYMENT:
            maxDateToScheduleConfig = "client.pay.salary.maximumDateDaysAhead";

            break;
        case ID_FORM.SUPPLIERS_PAYMENT:
            maxDateToScheduleConfig = "client.pay.suppliers.maximumDateDaysAhead";

            break;
        default:
            maxDateToScheduleConfig = "client.transfers.valueDate.maxDaysToSchedule";

            break;
    }

    const maxDaysToSchedule = ConfigUtils.get(maxDateToScheduleConfig);
    const dateFormat = ConfigUtils.get(`frontend.shortDateFormat.${I18nUtils.getLang()}`);
    const fieldsList =
        mode !== MODE.VIEW
            ? metadata.fieldList
            : metadata.fieldList.map((field) =>
                  field.visibleTicket ? { ...field, visible: "TRUE", required: "FALSE" } : field,
              );
    const children = fieldsList
        .reduce((acc, field) => {
            if (
                (validations[field.idField].isVisible && (mode === MODE.VIEW || !field.ticketOnly)) ||
                (mode === MODE.EDIT && field.type === "sectiontitle")
            ) {
                const FormField = FormFieldsComponents[field.type.charAt(0).toUpperCase() + field.type.substr(1)];
                return [
                    ...acc,
                    <Field
                        {...field}
                        alternativeLabelMap={field.alternativeLabelMap}
                        component={FormField}
                        dateFormat={dateFormat}
                        dependeeFields={metadata.dependeeFields}
                        fieldList={fieldsList}
                        formData={formData}
                        formTitle={metadata.formNameMap[currentLang]}
                        isFromBackoffice={isFromBackoffice}
                        // isRequired is a Boolean
                        isRequired={validations[field.idField].isRequired}
                        // isRequiredCondition is a string, either "TRUE" or "FALSE",
                        // or a conditional like "value(name) != EMPTY_STR"
                        isRequiredCondition={field.required}
                        isUseAlternativeLabel={validations[field.idField].isUseAlternativeLabel}
                        key={field.idField}
                        lang={currentLang}
                        mode={mode}
                        name={field.idField}
                        prevMode={prevMode}
                        subType={field.subType}
                        tooltip={tooltip[field.idField].message}
                    />,
                ];
            }
            return acc;
        }, [])
        .concat(
            programable || schedulable ? (
                <Field
                    component={Scheduler}
                    data={{
                        enabledWeekDays,
                        firstWorkingDate,
                        maxDaysToSchedule,
                        nonWorkingDays,
                        lang: currentLang,
                        mode,
                        programable,
                        schedulable,
                    }}
                    name="scheduler"
                    idForm={idForm}
                />
            ) : (
                []
            ),
        );

    return children;
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

function mapStateToProps(store, props) {
    const mode = SelectorsStoreForm.getMode(store);
    const metadata = SelectorsStoreForm.getMetadata(store);
    const prevMode = SelectorsStoreForm.getPrevMode(store);

    const { validations } = metadata.fieldList.reduce(
        ({ validations: self, insideValues }, { idField, visibleTicket }) => {
            let isFieldVisible = false;

            if (visibleTicket && mode === MODE.VIEW) {
                isFieldVisible = true;
            } else {
                isFieldVisible = IsVisible(idField, metadata.fieldList, insideValues);
            }

            const insideValuesAux = insideValues;

            if (!isFieldVisible) {
                insideValuesAux[idField] = null;
            }

            return {
                validations: {
                    ...self,
                    [idField]: {
                        isVisible: isFieldVisible,
                        isRequired: IsRequired(idField, metadata.fieldList, insideValuesAux),
                        isUseAlternativeLabel: IsUseAlternativeLabel(idField, metadata.fieldList, insideValuesAux),
                    },
                },
                insideValues: insideValuesAux,
            };
        },
        { validations: {}, insideValues: UtilLodash.cloneDeep(props.values) },
    );

    const { tooltip } = metadata.fieldList.reduce(
        ({ tooltip: self }, { idField, idForm }) => {
            return {
                tooltip: {
                    ...self,
                    [idField]: {
                        message: SelectorsStoreI18n.getMessage(store, `${idForm}.${idField}.tooltip`) && (
                            <I18n id={`${idForm}.${idField}.tooltip`} />
                        ),
                    },
                },
            };
        },
        { tooltip: {} },
    );

    return {
        currentLang: SelectorsStoreI18n.getLang(store),
        formData: SelectorsStoreForm.getData(store),
        metadata,
        mode,
        prevMode,
        tooltip,
        validations,
    };
}

export default Connect(mapStateToProps)(Component);
