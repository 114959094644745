import { push as Push } from "connected-react-router";
import { call as Call, put as Put, takeLatest as TakeLatest, select as Select } from "redux-saga/effects";

import {
    BIOMETRIC_ID_USER,
    RESPONSE_TYPE,
    LEVEL,
    SCOPE,
    ERROR_MOBILE_IOS_128,
    ERROR_MOBILE_IOS_CANCEL,
    ERROR_MOBILE_IOS_CANCEL_BY_SYSTEM,
    API_PASSWORD_BLOCKED,
    API_INVALID_PASSWORD,
    BIOMETRIC_TYPE,
    BIOMETRIC_TYPES,
    ERROR_MOBILE_IOS_LOCK_OUT,
    ERROR_MOBILE_IOS_NOT_AVAILABLE,
    BIOMETRIC_FACE_ID,
} from "~/constants";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsStore as SelectorsStoreMigration } from "~/store/migration";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import {
    SelectorsAction as SelectorsActionSession,
    SelectorsStore as SelectorsStoreSession,
    TYPE as SessionTypes,
} from "~/store/session";
import * as UtilsConfig from "~/util/config";
import UtilsDevice, { DEVICE_MOBILE } from "~/util/device";
import * as Facephi from "~/util/facephi";
import * as FingerprintUtils from "~/util/fingerprint";
import { adjustIdFieldErrors } from "~/util/form";
import * as UtilsI18n from "~/util/i18n";
import * as UtilsSecureStorage from "~/util/secureStorage";
import * as SoftToken from "~/util/softToken";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction, SelectorsStore } from "./_selectors";

export default [
    TakeLatest(TYPE.VALIDATE_PASSWORD_REQUEST, function* validatePassword(props) {
        const { password, formikBag, isNotLogged } = props;
        let response;
        if (isNotLogged) {
            const { username } = props;

            response = yield Call(SelectorsMiddleware.validatePasswordAnonymous, {
                assistant: true, // setAsDefault environment
                _password: password,
                _idUser: username,
            });
        } else {
            const accessToken = yield Select(SelectorsStoreSession.getAccessToken);
            const username = yield Select(SelectorsStoreSession.getUsername);
            const { idEnvironment } = yield Select(SelectorsStoreSession.getActiveEnvironment);

            response = yield Call(SelectorsMiddleware.validatePasswordWithAccessToken, {
                accessToken,
                idEnvironment,
                assistant: true, // setAsDefault environment
                _password: password,
                _username: username,
            });
        }
        let webToken = null;
        const { code } = response.data;

        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(SelectorsAction.errorValidatePassword());
            formikBag.setSubmitting(false);

            switch (code) {
                case API_PASSWORD_BLOCKED:
                    if (!isNotLogged) {
                        yield Put(SelectorsActionSession.logout());
                        yield Put(
                            SelectorsActionNotification.showNotification({
                                message: UtilsI18n.get("validate.password.blocked"),
                                level: LEVEL.ERROR,
                                scopes: [SCOPE.EXTERNAL_DASHBOARD],
                            }),
                        );
                    } else {
                        formikBag.setErrors(adjustIdFieldErrors({ password: response.data.message }));
                    }
                    break;
                case API_INVALID_PASSWORD:
                    formikBag.setErrors(adjustIdFieldErrors({ password: response.data.message }));
                    break;
                default:
                    formikBag.setErrors(adjustIdFieldErrors({ password: response.data.message }));
                    break;
            }
        } else {
            try {
                webToken = yield Call(SoftToken.generateOTP); // TODO: ponerlo en una funcion aparte
                yield Put({
                    type: SessionTypes.SAVE_WEB_TOKEN, // TODO: ver donde guardar esto
                    webToken,
                });
                yield Put(SelectorsAction.successVerifyBiometric());
                yield Put(SelectorsAction.successValidatePassword());
            } catch (error) {
                yield Put(SelectorsAction.cancelVerifyBiometric());
                yield Put(SelectorsAction.errorValidatePassword());

                if (error === SoftToken.error.NO_PERMISSION_GRANTED) {
                    yield Put(
                        SelectorsActionNotification.showNotification({
                            message: UtilsI18n.get("vascoPermisson.error"),
                            level: LEVEL.ERROR,
                            scopes: [
                                SCOPE.EXTERNAL_DASHBOARD,
                                SCOPE.FORM,
                                SCOPE.LOGIN,
                                SCOPE.TRANSACTIONS,
                                SCOPE.PREFERENTIAL_TRADING_PRICE,
                            ],
                        }),
                    );
                }
            }
            formikBag.setSubmitting(false);
            formikBag.resetForm();
            yield Put(SelectorsAction.hidePasswordModal());
        }
    }),

    TakeLatest(TYPE.VERIFY_FACEPHI_CHANGE_MY_PHONE_REQUEST, function* verifyFacephiChangeMyPhone(props) {
        const { liveness, facephiUserChangeMyPhone } = props;
        if (UtilsDevice.isMobileNative()) {
            if (facephiUserChangeMyPhone) {
                let retryAuthenticationNumber = UtilsConfig.getInteger("authentication.facephiMaxRetries", 3);
                while (retryAuthenticationNumber > 0) {
                    try {
                        const facephiPluginResult = yield Call(Facephi.authenticate, liveness);
                        if (facephiPluginResult) {
                            let bioInfo;
                            let bioExtraInfo;
                            let response;
                            let userID;
                            switch (facephiPluginResult.finishStatus) {
                                case Facephi.status.Ok:
                                    bioInfo = facephiPluginResult.template;
                                    bioExtraInfo = {
                                        eyeGlassesScore: facephiPluginResult.eyeGlassesScore,
                                        livenessDiagnostic: facephiPluginResult.livenessDiagnostic,
                                        templateScore: facephiPluginResult.templateScore,
                                    };

                                    // Actualmente safeway no acepta ningun extraInfo
                                    bioExtraInfo = "";
                                    userID = facephiUserChangeMyPhone;
                                    response = yield Call(SelectorsMiddleware.faceAuthentication, {
                                        bioInfo,
                                        bioExtraInfo,
                                        userID,
                                    });

                                    if (response && response.status === 200) {
                                        const { data } = response.data;
                                        if (data.faceIsValid) {
                                            yield Put(
                                                SelectorsAction.successVerifyBiometric({
                                                    biometricType: BIOMETRIC_TYPES.facephi,
                                                }),
                                            );
                                            return true;
                                        }
                                    }
                                    retryAuthenticationNumber -= 1;
                                    break;
                                case Facephi.status.CancelByUser:
                                    yield Put(SelectorsAction.cancelVerifyBiometric());
                                    return false;
                                case Facephi.status.Timeout:
                                case Facephi.status.Error:
                                    if (facephiPluginResult.errorType === Facephi.error.CameraPermissionDenied) {
                                        yield Put(SelectorsAction.cancelVerifyBiometric());
                                        yield Put(
                                            SelectorsActionNotification.showNotification({
                                                message: UtilsI18n.get(
                                                    "enrollment.index.invitationCode.scanQRCode.error",
                                                ),
                                                level: LEVEL.ERROR,
                                                scopes: [SCOPE.CHANGE_PHONE],
                                            }),
                                        );
                                        return undefined;
                                    }
                                    break;
                                default:
                                    retryAuthenticationNumber -= 1;
                                    break;
                            }
                            if (retryAuthenticationNumber <= 0) {
                                yield Put(
                                    SelectorsActionNotification.showNotification({
                                        message: UtilsI18n.get("verify.biometric.error.max.attempts"),
                                        level: LEVEL.ERROR,
                                        scopes: [SCOPE.CHANGE_PHONE],
                                    }),
                                );
                                yield Put(SelectorsAction.cancelVerifyBiometric());
                                return false;
                            }
                        }
                    } catch (error) {
                        if (
                            Object.prototype.hasOwnProperty.call(error, "errorType") &&
                            error.errorType === Facephi.error.CameraPermissionDenied
                        ) {
                            yield Put(SelectorsAction.cancelVerifyBiometric());
                            yield Put(
                                SelectorsActionNotification.showNotification({
                                    message: UtilsI18n.get("enrollment.index.invitationCode.scanQRCode.error"),
                                    level: LEVEL.ERROR,
                                    scopes: [SCOPE.CHANGE_PHONE],
                                }),
                            );
                            return false;
                        }

                        retryAuthenticationNumber -= 1;
                        if (retryAuthenticationNumber <= 0) {
                            yield Put(
                                SelectorsActionNotification.showNotification({
                                    message: UtilsI18n.get("verify.biometric.error.max.attempts"),
                                    level: LEVEL.ERROR,
                                    scopes: [SCOPE.CHANGE_PHONE],
                                }),
                            );
                            yield Put(SelectorsAction.cancelVerifyBiometric());
                            return false;
                        }
                    }
                }
            }
        } else {
            yield Put(SelectorsAction.errorVerifyBiometric());
        }

        return false;
    }),

    TakeLatest(TYPE.VERIFY_BIOMETRIC_REQUEST, function* verifyBiometric(props) {
        const { liveness, pathFrom } = props;
        yield Put({
            type: SessionTypes.SAVE_WEB_TOKEN, // Se limpia el webToken
            webToken: null,
        });
        const platform = UtilsDevice.platform();
        const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);
        if (isTrusted && UtilsDevice.isMobileNative()) {
            let webToken = null;
            const availability = yield Call(FingerprintUtils.isAvailable);
            try {
                const biometricType = yield Call(UtilsSecureStorage.get, BIOMETRIC_TYPE);
                if (biometricType === BIOMETRIC_TYPES.fingerPrint) {
                    if (availability && availability.isHardwareDetected) {
                        if (availability.hasEnrolledFingerprints) {
                            const message = UtilsI18n.get("settings.fingerprintConfiguration.dialog.message.otp");

                            const result = yield Call(FingerprintUtils.verify, message);
                            if (result && result.withFingerprint) {
                                webToken = yield Call(SoftToken.generateOTP);
                                yield Put({
                                    type: SessionTypes.SAVE_WEB_TOKEN,
                                    webToken,
                                });

                                yield Put(
                                    SelectorsAction.successVerifyBiometric({
                                        biometricType: BIOMETRIC_TYPES.fingerPrint,
                                    }),
                                );
                            } else {
                                yield Put(SelectorsAction.errorVerifyBiometric());
                            }
                        } else {
                            yield Put(SelectorsAction.noEnrolledFingerPrint({ pathFrom }));
                            // Si no tengo registrada la huella, lo llevo a una pantalla para que la registre,
                            yield Put(Push("/enrollmentBiometric"));
                        }
                    } else {
                        if (availability.error === ERROR_MOBILE_IOS_NOT_AVAILABLE) {
                            yield Put(SelectorsAction.cancelVerifyBiometric());
                            yield Put(
                                SelectorsActionNotification.showNotification({
                                    message: UtilsI18n.get("biometric.error.not.available"),
                                    level: LEVEL.ERROR,
                                    scopes: [
                                        SCOPE.EXTERNAL_DASHBOARD,
                                        SCOPE.FORM,
                                        SCOPE.LOGIN,
                                        SCOPE.TRANSACTIONS,
                                        SCOPE.PREFERENTIAL_TRADING_PRICE,
                                    ],
                                }),
                            );
                            return false;
                        }
                        yield Put(SelectorsAction.errorVerifyBiometric());
                    }
                } else {
                    const facephiUser = yield Call(UtilsSecureStorage.get, BIOMETRIC_ID_USER);

                    if (facephiUser) {
                        let retryAuthenticationNumber = UtilsConfig.getInteger("authentication.facephiMaxRetries", 3);
                        while (retryAuthenticationNumber > 0) {
                            try {
                                const facephiPluginResult = yield Call(Facephi.authenticate, liveness);

                                if (facephiPluginResult) {
                                    let bioInfo;
                                    let bioExtraInfo;
                                    let response;
                                    let userID;
                                    switch (facephiPluginResult.finishStatus) {
                                        case Facephi.status.Ok:
                                            bioInfo = facephiPluginResult.template;
                                            bioExtraInfo = {
                                                eyeGlassesScore: facephiPluginResult.eyeGlassesScore,
                                                livenessDiagnostic: facephiPluginResult.livenessDiagnostic,
                                                templateScore: facephiPluginResult.templateScore,
                                            };

                                            // Actualmente safeway no acepta ningun extraInfo
                                            bioExtraInfo = "";
                                            userID = facephiUser;
                                            response = yield Call(SelectorsMiddleware.faceAuthentication, {
                                                bioInfo,
                                                bioExtraInfo,
                                                userID,
                                            });

                                            if (response && response.status === 200) {
                                                const { data } = response.data;
                                                if (data.faceIsValid) {
                                                    webToken = yield Call(SoftToken.generateOTP);
                                                    yield Put({
                                                        type: SessionTypes.SAVE_WEB_TOKEN,
                                                        webToken,
                                                    });
                                                    yield Put(
                                                        SelectorsAction.successVerifyBiometric({
                                                            biometricType: BIOMETRIC_TYPES.facephi,
                                                        }),
                                                    );
                                                    return true;
                                                }
                                                retryAuthenticationNumber -= 1;
                                            } else {
                                                retryAuthenticationNumber -= 1;
                                            }
                                            break;
                                        case Facephi.status.CancelByUser:
                                            yield Put(SelectorsAction.cancelVerifyBiometric());
                                            return false;
                                        case Facephi.status.Timeout:
                                        case Facephi.status.Error:
                                            if (
                                                facephiPluginResult.errorType === Facephi.error.CameraPermissionDenied
                                            ) {
                                                yield Put(SelectorsAction.cancelVerifyBiometric());
                                                yield Put(
                                                    SelectorsActionNotification.showNotification({
                                                        message: UtilsI18n.get(
                                                            "enrollment.index.invitationCode.scanQRCode.error",
                                                        ),
                                                        level: LEVEL.ERROR,
                                                        scopes: [
                                                            SCOPE.EXTERNAL_DASHBOARD,
                                                            SCOPE.FORM,
                                                            SCOPE.LOGIN,
                                                            SCOPE.TRANSACTIONS,
                                                            SCOPE.PREFERENTIAL_TRADING_PRICE,
                                                        ],
                                                    }),
                                                );
                                                return false;
                                            }

                                            break;
                                        default:
                                            retryAuthenticationNumber -= 1;
                                            break;
                                    }
                                    if (retryAuthenticationNumber <= 0) {
                                        yield Put(SelectorsAction.showPasswordModal());
                                        return false;
                                    }
                                }
                            } catch (error) {
                                if (
                                    Object.prototype.hasOwnProperty.call(error, "errorType") &&
                                    error.errorType === Facephi.error.CameraPermissionDenied
                                ) {
                                    yield Put(SelectorsAction.cancelVerifyBiometric());
                                    yield Put(
                                        SelectorsActionNotification.showNotification({
                                            message: UtilsI18n.get("enrollment.index.invitationCode.scanQRCode.error"),
                                            level: LEVEL.ERROR,
                                            scopes: [
                                                SCOPE.EXTERNAL_DASHBOARD,
                                                SCOPE.FORM,
                                                SCOPE.LOGIN,
                                                SCOPE.TRANSACTIONS,
                                                SCOPE.PREFERENTIAL_TRADING_PRICE,
                                            ],
                                        }),
                                    );
                                    return false;
                                }
                                retryAuthenticationNumber -= 1;
                                if (retryAuthenticationNumber <= 0) {
                                    yield Put(SelectorsAction.showPasswordModal());
                                    return false;
                                }
                            }
                        }
                    }
                }
            } catch (error) {
                switch (error) {
                    // En el caso de que no se den permisos o que falle isTrusted se guarda isTrusted en false
                    case SoftToken.error.NO_PERMISSION_GRANTED:
                        yield Put(SelectorsAction.cancelVerifyBiometric());
                        yield Put(
                            SelectorsActionNotification.showNotification({
                                message: UtilsI18n.get("vascoPermisson.error"),
                                level: LEVEL.ERROR,
                                scopes: [
                                    SCOPE.EXTERNAL_DASHBOARD,
                                    SCOPE.FORM,
                                    SCOPE.LOGIN,
                                    SCOPE.TRANSACTIONS,
                                    SCOPE.PREFERENTIAL_TRADING_PRICE,
                                ],
                            }),
                        );
                        return false;
                    default:
                        break;
                }
                const errorType = FingerprintUtils.getError(error);
                switch (platform) {
                    case DEVICE_MOBILE.ANDROID:
                        switch (errorType) {
                            case FingerprintUtils.fingerprintErrors.FINGERPRINT_ERROR:
                                yield Put(SelectorsAction.showPasswordModal());
                                break;
                            case FingerprintUtils.fingerprintErrors.FINGERPRINT_CANCELLED:
                                yield Put(SelectorsAction.cancelVerifyBiometric());
                                break;
                            default:
                                yield Put(SelectorsAction.errorVerifyBiometric());
                                break;
                        }

                        break;
                    case DEVICE_MOBILE.IOS:
                        switch (error.code) {
                            case ERROR_MOBILE_IOS_CANCEL:
                            case ERROR_MOBILE_IOS_128:
                            case ERROR_MOBILE_IOS_CANCEL_BY_SYSTEM:
                                if (availability.type === BIOMETRIC_FACE_ID) {
                                    yield Put(SelectorsAction.showPasswordModal());
                                } else {
                                    yield Put(SelectorsAction.cancelVerifyBiometric());
                                }
                                break;
                            case ERROR_MOBILE_IOS_LOCK_OUT:
                                yield Put(
                                    SelectorsActionNotification.showNotification({
                                        message: UtilsI18n.get("verify.biometric.error.lockout"),
                                        level: LEVEL.ERROR,
                                        scopes: [
                                            SCOPE.EXTERNAL_DASHBOARD,
                                            SCOPE.FORM,
                                            SCOPE.LOGIN,
                                            SCOPE.TRANSACTIONS,
                                            SCOPE.PREFERENTIAL_TRADING_PRICE,
                                        ],
                                    }),
                                );
                                yield Put(SelectorsAction.showPasswordModal());
                                break;

                            default:
                                yield Put(SelectorsAction.showPasswordModal());

                                break;
                        }
                        // -1 - Fingerprint scan failed more than 3 times
                        // -2 or -128 - User tapped the 'Cancel' button
                        // -3 - User tapped the 'Enter Passcode' or 'Enter Password' button
                        // -4 - The scan was cancelled by the system (Home button for example)
                        // -6 - TouchID is not Available
                        // -8 - TouchID is locked out from too many tries
                        break;
                    default:
                        yield Put(SelectorsAction.errorVerifyBiometric());
                        break;
                }
            }
        } else {
            yield Put(SelectorsAction.errorVerifyBiometric());
        }
        return false;
    }),

    TakeLatest(TYPE.REGISTER_BIOMETRIC_REQUEST, function* registerBiometricFingerPrint(props) {
        const { exchangeToken, isChangeMyPhone, userNameChangeMyPhone, pathFrom } = props;

        try {
            if (UtilsDevice.isMobileNative()) {
                const availability = yield Call(FingerprintUtils.isAvailable);

                const isBiometricAvailable = availability && availability.isHardwareDetected;

                if (isBiometricAvailable) {
                    if (availability.hasEnrolledFingerprints) {
                        const message = UtilsI18n.get("settings.fingerprintConfiguration.dialog.message");

                        const result = yield Call(FingerprintUtils.verify, message);
                        if (result && result.withFingerprint) {
                            yield Put(
                                SelectorsAction.successVerifyBiometric({
                                    biometricType: BIOMETRIC_TYPES.fingerPrint,
                                }),
                            );
                        } else {
                            yield Put(SelectorsAction.errorVerifyBiometric());
                        }
                    } else {
                        yield Put(SelectorsAction.noEnrolledFingerPrint({ pathFrom }));
                        // Si no tengo registrada la huella, lo llevo a una pantalla para que la registre,
                        yield Put(Push("/enrollmentBiometric"));
                    }
                } else {
                    yield Put(
                        SelectorsAction.registerBiometricFacephi({
                            exchangeToken,
                            isChangeMyPhone,
                            userNameChangeMyPhone,
                        }),
                    );
                }
            } else {
                yield Put(SelectorsAction.errorVerifyBiometric());
            }
        } catch (error) {
            const platform = UtilsDevice.platform();
            const errorType = FingerprintUtils.getError(error);

            switch (platform) {
                case DEVICE_MOBILE.ANDROID:
                    switch (errorType) {
                        case FingerprintUtils.fingerprintErrors.FINGERPRINT_ERROR:
                            yield Put(
                                SelectorsAction.registerBiometricFacephi({
                                    exchangeToken,
                                    isChangeMyPhone,
                                    userNameChangeMyPhone,
                                }),
                            );
                            break;
                        case FingerprintUtils.fingerprintErrors.FINGERPRINT_CANCELLED:
                            yield Put(SelectorsAction.cancelVerifyBiometric());
                            break;
                        default:
                            yield Put(
                                SelectorsActionNotification.showNotification({
                                    message: errorType,
                                    level: LEVEL.ERROR,
                                    scopes: [SCOPE.REGISTER_BIOMETRIC],
                                }),
                            );
                            yield Put(SelectorsAction.errorVerifyBiometric());
                            break;
                    }

                    break;
                case DEVICE_MOBILE.IOS:
                    if (
                        error.code === ERROR_MOBILE_IOS_CANCEL ||
                        error.code === ERROR_MOBILE_IOS_128 ||
                        error.code === ERROR_MOBILE_IOS_CANCEL_BY_SYSTEM
                    ) {
                        // Si el usuario cancelo
                        yield Put(SelectorsAction.cancelVerifyBiometric());
                    } else {
                        yield Put(
                            SelectorsAction.registerBiometricFacephi({
                                exchangeToken,
                                isChangeMyPhone,
                                userNameChangeMyPhone,
                            }),
                        );
                    }

                    // -1 - Fingerprint scan failed more than 3 times
                    // -2 or -128 - User tapped the 'Cancel' button
                    // -3 - User tapped the 'Enter Passcode' or 'Enter Password' button
                    // -4 - The scan was cancelled by the system (Home button for example)
                    // -6 - TouchID is not Available
                    // -8 - TouchID is locked out from too many tries
                    break;
                default:
                    break;
            }
        }
    }),

    TakeLatest(TYPE.REGISTER_BIOMETRIC_FACEPHI_REQUEST, function* registerBiometricFacephi(props) {
        const { exchangeToken, isChangeMyPhone, userNameChangeMyPhone } = props;
        if (UtilsDevice.isMobileNative()) {
            let retryAuthenticationNumber = yield Select(SelectorsStore.retryAuthenticationNumber);

            const registerBiometricMigration = yield Select(SelectorsStoreMigration.registerBiometricMigration);
            while (retryAuthenticationNumber) {
                try {
                    const facephiPluginResult = yield Call(Facephi.register);
                    if (facephiPluginResult) {
                        switch (facephiPluginResult.finishStatus) {
                            case Facephi.status.Ok: {
                                const bioInfo = facephiPluginResult.template;
                                let bioExtraInfo = {
                                    eyeGlassesScore: facephiPluginResult.eyeGlassesScore,
                                    livenessDiagnostic: facephiPluginResult.livenessDiagnostic,
                                    templateScore: facephiPluginResult.templateScore,
                                };
                                // Actualmente safeway no acepta ningun extraInfo
                                bioExtraInfo = "";

                                const selector = registerBiometricMigration
                                    ? SelectorsMiddleware.faceRegisterMigration
                                    : SelectorsMiddleware.faceRegisterEnrollment;
                                const response = yield Call(selector, {
                                    bioInfo,
                                    bioExtraInfo,
                                    _exchangeToken: exchangeToken,
                                    userNameChangeMyPhone,
                                    isChangeMyPhone,
                                });

                                if (response && response.status === 200) {
                                    const { data } = response.data;
                                    const { registerFaceOK } = data;

                                    if (registerFaceOK) {
                                        yield Put(
                                            SelectorsAction.successVerifyBiometric({
                                                biometricType: BIOMETRIC_TYPES.facephi,
                                            }),
                                        );
                                        return true;
                                    }
                                    throw new Error();
                                } else {
                                    throw new Error();
                                }
                            }
                            case Facephi.status.CancelByUser:
                                yield Put(SelectorsAction.cancelVerifyBiometric());
                                return undefined;
                            case Facephi.status.Error:
                                if (facephiPluginResult.errorType === Facephi.error.CameraPermissionDenied) {
                                    yield Put(SelectorsAction.errorVerifyBiometric());
                                    yield Put(
                                        SelectorsActionNotification.showNotification({
                                            message: UtilsI18n.get("enrollment.index.invitationCode.scanQRCode.error"),
                                            level: LEVEL.ERROR,
                                            scopes: [SCOPE.REGISTER_BIOMETRIC],
                                        }),
                                    );
                                    return undefined;
                                }
                                break;
                            default:
                                throw new Error();
                        }
                    }
                } catch (error) {
                    yield Put(SelectorsAction.errorRegisterBiometricFacephi());
                    if (
                        Object.prototype.hasOwnProperty.call(error, "errorType") &&
                        error.errorType === Facephi.error.CameraPermissionDenied
                    ) {
                        yield Put(SelectorsAction.cancelVerifyBiometric());
                        yield Put(
                            SelectorsActionNotification.showNotification({
                                message: UtilsI18n.get("enrollment.index.invitationCode.scanQRCode.error"),
                                level: LEVEL.ERROR,
                                scopes: [
                                    SCOPE.EXTERNAL_DASHBOARD,
                                    SCOPE.FORM,
                                    SCOPE.LOGIN,
                                    SCOPE.TRANSACTIONS,
                                    SCOPE.PREFERENTIAL_TRADING_PRICE,
                                ],
                            }),
                        );
                        return false;
                    }
                    retryAuthenticationNumber -= 1;

                    if (retryAuthenticationNumber <= 0) {
                        yield Put(SelectorsAction.errorVerifyBiometric());
                        if (registerBiometricMigration) {
                            yield Put(Push("/desktop"));
                            yield Put(
                                SelectorsActionNotification.showNotification({
                                    message: UtilsI18n.get("verify.biometric.error.max.attempts"),
                                    level: LEVEL.ERROR,
                                    scopes: [SCOPE.DESKTOP],
                                }),
                            );
                        } else {
                            yield Put(Push("/"));
                            yield Put(
                                SelectorsActionNotification.showNotification({
                                    message: UtilsI18n.get("verify.biometric.error.max.attempts"),
                                    level: LEVEL.ERROR,
                                    scopes: [SCOPE.EXTERNAL_DASHBOARD],
                                }),
                            );
                        }
                        return undefined;
                    }
                }
            }
        } else {
            yield Put(SelectorsAction.errorVerifyBiometric());
        }
        return undefined;
    }),

    TakeLatest(TYPE.GENERATE_WEB_TOKEN, function* generateWebToken() {
        try {
            const webToken = yield Call(SoftToken.generateOTP);
            yield Put({
                type: SessionTypes.SAVE_WEB_TOKEN,
                webToken,
            });
        } catch (error) {
            let errorMessage;
            switch (error) {
                case SoftToken.error.NO_PERMISSION_GRANTED:
                    errorMessage = UtilsI18n.get("vascoPermisson.error");
                    break;
                default:
                    errorMessage = error;
                    break;
            }

            yield Put(Push("/"));
            yield Put(SelectorsActionNotification.showNotification(errorMessage, "error", ["externalDashboard"]));
        }
    }),
];
