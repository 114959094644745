import React from "react";

import PropTypes from "prop-types";

import I18n from "~/components/I18n";
import Checkbox from "~/pages/_components/fields/Checkbox";

import Style from "./SmartGroups.rules.scss";

export const NAME = "SmartGroups";

export const PROP = {
    types: {
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        productTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
        value: PropTypes.arrayOf(PropTypes.string),
    },
    defaults: {},
};
export function Component(props) {
    const { name, value, onChange, productTypes } = props;

    return (
        <div className="form-group">
            {productTypes.map((productType) => {
                const inputValue = `ALL_${productType}`;

                return (
                    <React.Fragment>
                        <div id={Style.ID}>
                            <Checkbox
                                key={inputValue}
                                name={`${name}-${productType}`}
                                value={inputValue}
                                label={<I18n id={`productType.${productType}`} />}
                                onChange={onChange}
                                checked={value.includes(inputValue)}
                                formGroup={false}
                            />
                        </div>
                    </React.Fragment>
                );
            })}
        </div>
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
