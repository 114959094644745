import { replace as Replace } from "connected-react-router";
import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE, GLOBAL_UNEXPECTED_ERROR_KEY, FORM_FIELD_ERRORS } from "~/constants";
import { SelectorsAction as SelectorsActionUsers } from "~/store/administration/users/users";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as UtilI18n from "~/util/i18n";

const loadDetailsRequest = (middleware, actions) =>
    function* loadRequest(props) {
        // id
        const response = yield Call(middleware.loadDetailsRequest, props);

        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(actions.loadDetailsFailure());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION],
                }),
            );
        } else {
            const userExtendedInfo = {
                idUser: response.data.data.user.idUser,
                status: response.data.data.userEnvStatus,
                signatureLevel: response.data.data.signatureLevel,
                massiveEnabled: response.data.data.hasMassiveEnabled,
                dispatcher: response.data.data.dispatcher,
            };

            yield Put(SelectorsActionUsers.loadSingleSuccess({ data: userExtendedInfo }));
            const labelCurrency = UtilI18n.get(`currency.label.${response.data.data.currency}`);

            const enabledChannelsFrequencies = response.data.data?.enabledChannelsFrequencies?.map((channel) => ({
                value: channel,
                label: UtilI18n.get(`administration.channels.${channel}`),
            }));
            yield Put(
                actions.loadChannelsSuccess({ data: response.data.data, labelCurrency, enabledChannelsFrequencies }),
            );
            yield Put(actions.loadDetailsSuccess({ data: response.data.data, credentialGroups: {} }));
            if (actions.loadPermissionsSuccess !== undefined) {
                yield Put(actions.loadPermissionsSuccess({ data: response.data.data }));
            }
            if (actions.loadGroupsSuccess !== undefined) {
                yield Put(actions.loadGroupsSuccess({ data: response.data.data }));
            }
        }
    };

const updateSignatureRequest = (middleware, actions) =>
    function* updateRequest(props) {
        const { data, formikBag } = props;
        const { signatureLevel, ...rest } = data;

        const response = yield Call(middleware.updateSignatureRequest, {
            ...rest,
            signatureLevel,
        });
        const { setSubmitting, setErrors } = formikBag;

        setSubmitting(false);
        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(actions.updateSignatureRequestFailure());

            const errorMessage = response.data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS);

            setErrors(response.data.data);
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION],
                }),
            );
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.ADMINISTRATION_USERS],
                }),
            );
            yield Put(actions.updateChannelsRequestSuccess());
            yield Put(Replace("/administration/users"));
        }
    };

const loadFormRequest = (middleware, actions) =>
    function* loadRequest(props) {
        // id
        const response = yield Call(middleware.loadFormRequest, props);

        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(actions.loadDetailsFailure());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION],
                }),
            );
        } else {
            const listFrequencies = response.data.data.enabledChannelsFrequencies.map((channel) => ({
                value: channel,
                label: UtilI18n.get(`administration.channels.${channel}`),
            }));
            yield Put(actions.loadSignatureFormSuccess({ data: response.data.data, listFrequencies }));
            yield Put(actions.loadFormSuccess({ data: response.data.data, listFrequencies }));
        }
    };

const sagas = (middleware, types, actions) => [
    TakeLatest(types.LOAD_DETAILS_REQUEST, loadDetailsRequest(middleware, actions)),
    TakeLatest(types.UPDATE_SIGNATURE_REQUEST, updateSignatureRequest(middleware, actions)),
    TakeLatest(types.LOAD_FORM_REQUEST, loadFormRequest(middleware, actions)),
];

export default sagas;
