import { takeLatest as TakeLatest, put as Put, call as Call, select } from "redux-saga/effects";

import { LEVEL, SCOPE, RESPONSE_TYPE } from "~/constants";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as ConfigUtils from "~/util/config";
import * as i18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsMiddleware, SelectorsStore as Store } from "./_selectors";

export default [
    TakeLatest(TYPE.READ_DEBIT_CARD_REQUEST, readDebitCardRequest),
    TakeLatest(TYPE.LIST_REQUEST, listRequest),
    TakeLatest(TYPE.CHANGE_STATUS_REQUEST, changeDebitCardStatusRequest),
];

function* readDebitCardRequest(props) {
    const myProps = { ...props };
    delete myProps.type;
    const readDebitCardResponse = yield Call(SelectorsMiddleware.detailsRequest, myProps);

    if (readDebitCardResponse.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.readDebitCardFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: i18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.DEBIT_CARD_DETAILS],
            }),
        );
    } else {
        const { debitCard } = readDebitCardResponse.data.data;
        yield Put(SelectorsAction.readDebitCardSuccess({ debitCard }));
    }
}

function* listRequest() {
    const response = yield Call(SelectorsMiddleware.listRequest);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.listFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: i18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.CREDIT_CARDS_LIST],
            }),
        );
    } else {
        const { debitCards } = response.data.data;

        const params = { list: debitCards };
        yield Put(SelectorsAction.listSuccess(params));
    }
}

function* changeDebitCardStatusRequest(props) {
    const myProps = { ...props };
    delete myProps.type;
    const config = ConfigUtils.get(`client.debitCard.changeStatus.label.${myProps.order}`);

    const response = yield Call(SelectorsMiddleware.changeStatusRequest, myProps);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.changeStatusFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.DEBIT_CARD_DETAILS],
            }),
        );
    } else {
        const debitCard = yield select(Store.getDebitCard);
        const cardStatus = config;

        debitCard.cardStatus = cardStatus;
        yield Put(SelectorsAction.changeStatusSuccess({ debitCard }));
        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.data.message,
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.DEBIT_CARD_DETAILS],
            }),
        );
    }
}
