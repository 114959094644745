import React from "react";

import { Formik, Form, Field } from "formik";
import { arrayOf, bool, func, shape, string } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import NumberFormat from "react-number-format";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";

import { SCOPE } from "~/constants";
import AdministrationContainer from "~/containers/Internal/Administration/Simple";
import * as arrayUtils from "~/util/array";
import * as configUtils from "~/util/config";
import * as i18nUtils from "~/util/i18n";
import { countDecimalPlaces, numberFormat, toNumber } from "~/util/number";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { capitalizeFirstLetter } from "~/util/string";

import Button from "~/components/Button";
import Resizable from "~/components/HighOrder/_Resizable";
import I18n from "~/components/I18n";
import Container from "~/pages/_components/Container";
import MainContainer from "~/pages/_components/MainContainer";
import FieldError from "~/pages/_components/fields/FieldError";
import MultiSelect from "~/pages/_components/fields/MultiSelectField";
import SwitchField from "~/pages/_components/fields/SwitchField";
import Selector from "~/pages/_components/fields/formik/Selector";
import StepperFieldGroup from "~/pages/_components/fields/formik/StepperFieldGroup";

import DeletePopUp from "~/pages/administration/advanced/_components/DeletePopUp";

import Style from "./SignatureSchemesAdvanced.rules.scss";

export const NAME = "SignaturesSchemeCreate";

const FORM_ID = "administration.signatures.create";

export const PROP = {
    types: {
        ...TypesRedux,
    },
    defaults: {
        i18n: {},
        ...DefaultsRedux,
    },
};

export class Component extends React.Component {
    static propTypes = {
        actions: shape({
            createSignaturesSchemePreRequest: func,
        }),
        activeEnvironment: shape({
            administrationScheme: string,
        }).isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        capFrequencyList: arrayOf(string),
        fetching: bool,
        functionalGroups: arrayOf(
            shape({
                idFeature: string,
            }),
        ),
        environmentProducts: arrayOf(Object),
        isDesktop: bool.isRequired,
        masterCurrency: string,
        routerActions: shape({
            goBack: func,
        }),
        signatureTypeList: arrayOf(string),
        selectedFunctionalGroups: arrayOf(string),
        signature: shape({}),
        isModify: bool,
        match: shape({}),
        isChangingEnvironment: bool,
    };

    static defaultProps = {
        actions: null,
        capFrequencyList: [],
        fetching: false,
        functionalGroups: [],
        masterCurrency: configUtils.get("core.masterCurrency"),
        routerActions: null,
        signatureTypeList: null,
        selectedFunctionalGroups: [],
        environmentProducts: [],
        signature: {},
        match: {},
        isModify: false,
        isChangingEnvironment: false,
    };

    state = {
        showConfirmationModal: false,
    };

    componentDidMount() {
        const { actions, isModify, match, isChangingEnvironment } = this.props;

        if (!isChangingEnvironment) {
            const id = parseInt(match.params.id, 10);
            if (isModify) {
                actions.modifySignaturesSchemePreRequest({ id });
            } else {
                actions.createSignaturesSchemePreRequest();
            }
        }
    }

    handleSubmit = () => {
        this.setState({
            showConfirmationModal: true,
        });
    };

    handleConfirmation = (formikBag) => {
        const {
            isModify,
            actions,
            credentialGroups: credentials,
            activeEnvironment: { administrationScheme },
            match,
        } = this.props;

        const {
            functionalGroups,
            selectedProducts,
            signatureLevelsCounts,
            signatureType,
            topAmount,
            signatureDispatch,
        } = formikBag.values;

        if (isModify) {
            actions.modifySignaturesSchemeRequest({
                signatureData: {
                    signatureId: match.params.id,
                    functionalGroups,
                    credentials,
                    products: selectedProducts,
                    signatureLevelsCounts,
                    signatureType,
                    topAmount,
                    signatureDispatch,
                    administrationScheme,
                },
                formikBag,
            });
        } else {
            actions.createSignaturesSchemeRequest({
                signatureData: {
                    functionalGroups,
                    credentials,
                    products: selectedProducts,
                    signatureLevelsCounts,
                    signatureType,
                    topAmount,
                    signatureDispatch,
                },
                formikBag,
            });
        }
    };

    handleCancelConfirmation = () => {
        this.setState({
            showConfirmationModal: false,
        });
    };

    renderContent = () => {
        const {
            activeEnvironment: { administrationScheme },
            selectedFunctionalGroups,
            signature,
            isModify,
        } = this.props;

        let signatureLevelsCounts = {};
        if (administrationScheme === "advanced") {
            signatureLevelsCounts = configUtils.getArray("administration.signatures.signatureLevels").reduce(
                (acc, signLevel) => ({
                    ...acc,
                    [signLevel]:
                        isModify && signature && signature.groupsMap && signature.groupsMap[signLevel]
                            ? signature.groupsMap[signLevel]
                            : 0,
                }),
                signatureLevelsCounts,
            );
        } else {
            signatureLevelsCounts = {
                A: 0,
            };
        }

        let topAmount = {
            amount: 0,
            period: configUtils.get("administration.signatures.topAmount.defaultFrequency"),
        };
        let signatureType = "AMOUNT";
        let signatureDispatch = false;
        let selectedProducts = [];
        if (isModify) {
            topAmount =
                signature && signature.capList && signature.capList.length > 0
                    ? {
                          amount: signature.capList[0].maximum,
                          period: signature.capList[0].frequency,
                      }
                    : topAmount;
            signatureType = (signature && signature.signatureType) || "AMOUNT";
            signatureDispatch = (signature && signature.signatureDispatch) || false;
            selectedProducts = (signature && signature.products) || [];
        }

        const initialValues = {
            functionalGroups: selectedFunctionalGroups,
            signatureLevelsCounts,
            toggleFunctionalGroups: selectedFunctionalGroups.length > 0,
            topAmount,
            signatureType,
            signatureDispatch,
            selectedProducts,
        };

        return (
            <React.Fragment>
                <MainContainer>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        validationSchema={this.validationSchema}
                        validateOnBlur={false}
                        // validateOnChange={false}
                        render={(formikBag) => this.renderForm(formikBag)}
                    />
                </MainContainer>
            </React.Fragment>
        );
    };

    sectionTitle = (title, subTitle, hasLine) => (
        <div className="form-section-title">
            {hasLine && <hr />}
            <h3>{i18nUtils.get(title)}</h3>
            <p>{i18nUtils.get(subTitle)}</p>
        </div>
    );

    renderForm = (formikBag) => {
        const { values } = formikBag;
        const {
            activeEnvironment,
            capFrequencyList,
            functionalGroups,
            environmentProducts,
            masterCurrency,
            signatureTypeList,
            isModify,
            routerActions,
        } = this.props;
        const { administrationScheme } = activeEnvironment;
        const { signatureType, toggleFunctionalGroups, toggleProducts } = values;
        const { showConfirmationModal } = this.state;

        return (
            <Form id={Style.ID} className="above-the-fold">
                <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                        {this.renderRequiredSignatures(administrationScheme)}
                    </Col>
                </Container>
                <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                        {signatureTypeList &&
                            this.renderTransactions(
                                capFrequencyList,
                                functionalGroups,
                                masterCurrency,
                                toggleFunctionalGroups,
                                toggleProducts,
                                environmentProducts,
                                signatureTypeList,
                                administrationScheme === "advanced" && signatureType && signatureType === "AMOUNT",
                                signatureType,
                                formikBag,
                            )}
                    </Col>
                </Container>

                <div className="buttons-container">
                    <Button bsStyle="secundary" label="global.cancel" type="button" onClick={routerActions.goBack} />
                    <Button bsStyle="primary" label="global.continue" type="submit" />
                </div>

                {showConfirmationModal && (
                    <DeletePopUp
                        show
                        title={
                            isModify
                                ? i18nUtils.get("administration.signatures.advanced.modifyPopup.title")
                                : i18nUtils.get("administration.signatures.advanced.createPopup.title")
                        }
                        description={
                            isModify
                                ? i18nUtils.get("administration.signatures.advanced.modifyPopup.description")
                                : i18nUtils.get("administration.signatures.advanced.createPopup.description")
                        }
                        handleCloseModal={this.handleCancelConfirmation}
                        onSubmit={() => {
                            this.handleConfirmation(formikBag);
                        }}
                    />
                )}
            </Form>
        );
    };

    renderFunctionalGroups = (functionalGroups, showFunctionalGroups) => (
        <React.Fragment>
            <Field
                component={SwitchField}
                idForm={FORM_ID}
                name="toggleFunctionalGroups"
                label={i18nUtils.get("administration.signatures.functionalGroups.toggleFunctionalGroups")}
            />
            {showFunctionalGroups && (
                <MultiSelect
                    label="administration.signatures.functionalGroups.label"
                    name="functionalGroups"
                    options={arrayUtils.mapItemsIds(
                        functionalGroups.map((functionalGroup) => ({
                            label: i18nUtils.get(
                                `administration.signatures.functionalGroups.${functionalGroup.idFeature}`,
                            ),
                            value: functionalGroup.idFeature,
                        })),
                        "value",
                    )}
                    placeholder="administration.signatures.functionalGroups.add">
                    {({ label }) => <span className="data-desc">{label}</span>}
                </MultiSelect>
            )}
        </React.Fragment>
    );

    renderProducts = (environmentProducts, toggleProducts) => (
        <React.Fragment>
            <Field
                component={SwitchField}
                idForm={FORM_ID}
                name="toggleProducts"
                label={i18nUtils.get("administration.signatures.functionalGroups.toggleProducts")}
            />
            {toggleProducts && (
                <MultiSelect
                    label="administration.signatures.products.label"
                    name="selectedProducts"
                    options={arrayUtils.mapItemsIds(
                        environmentProducts.map((product) => ({
                            label: product.label,
                            value: product.idProduct,
                        })),
                        "value",
                    )}
                    placeholder="administration.signatures.products.add">
                    {({ label }) => <span className="data-desc">{label}</span>}
                </MultiSelect>
            )}
        </React.Fragment>
    );

    renderRequiredSignatures = (administrationScheme) => (
        <React.Fragment>
            <div className="form-section">
                <div className="form-section-title">
                    <I18n id="administration.signatures.create.requiredSignatures" />
                </div>
                <div className="form-content">
                    <div className="form-section-label">
                        <I18n id={`administration.signatures.create.${administrationScheme}.signersLevel`} />
                    </div>
                    <div className="form-group form-group--stepper-field signatureContainer">
                        <Field
                            component={StepperFieldGroup}
                            hideLabel={administrationScheme === "medium"}
                            idForm={FORM_ID}
                            className="signatureContainer signature"
                            name="signatureLevelsCounts"
                            min="0"
                            max={configUtils.get("administration.signatures.maxNeeded")}
                            options={
                                administrationScheme === "medium"
                                    ? ["A"]
                                    : configUtils.getArray("administration.signatures.signatureLevels")
                            }
                            showFirstErrorWhenEquals
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

    renderTransactions = (
        capFrequencyList,
        functionalGroups,
        masterCurrency,
        showFunctionalGroups,
        toggleProducts,
        environmentProducts,
        signatureTypeList,
        transactionWithAmount,
        signatureType,
        formikBag,
    ) => (
        <React.Fragment>
            <div className="form-section">
                <div className="form-section-title">
                    <I18n id="administration.signatures.create.transactions" />
                </div>
                <div className="form-content">
                    <div className="form-section-label">
                        <p>{i18nUtils.get("administration.signatures.create.transactions.subtitle.one")}</p>
                        <p>{i18nUtils.get("administration.signatures.create.transactions.subtitle.two")}</p>
                        <p>{i18nUtils.get("administration.signatures.create.transactions.subtitle.three")}</p>
                    </div>

                    <div className="form-group">
                        <div className="form-group-control-list">
                            <div className="Select flex-container slideFromBottom has-value Select--single">
                                <Field
                                    component={Selector}
                                    idForm={FORM_ID}
                                    name="signatureType"
                                    value={signatureType}
                                    className="flex-container slideFromBottom selector"
                                    optionClassName="needsclick"
                                    options={signatureTypeList.map((signature) => ({
                                        id: signature,
                                        label: i18nUtils.get(
                                            `administration.signatures.create.signatureType.${signature}`,
                                        ),
                                        value: signature,
                                    }))}
                                    renderAs="combo"
                                    searchable={false}
                                    creatable={false}
                                    clearable={false}
                                    hideLabel
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {transactionWithAmount && masterCurrency && capFrequencyList && this.topAmountFields(formikBag)}
        </React.Fragment>
    );

    topAmountFields = (formikBag) => {
        // eslint-disable-next-line react/prop-types
        const { lang, maximumDecimals, minimumDecimals, capFrequencyList, masterCurrency } = this.props;
        const { values, errors, touched } = formikBag;
        const { decimalSeparator, thousandSeparator } = numberFormat(lang);
        const decimalPlaces = this.amountRef ? countDecimalPlaces(this.amountRef.value, decimalSeparator) : 0;
        const decimalScale = Math.max(Math.min(decimalPlaces, maximumDecimals), minimumDecimals);
        const hasError = touched.topAmount && touched.topAmount.amount && errors.topAmount && errors.topAmount.amount;

        return (
            <div className="form-section">
                <div className="form-section-title">
                    <I18n id="administration.signatures.create.topAmount.title" />
                </div>
                <div className="form-content">
                    <div className="form-section-label">
                        <I18n id="administration.signatures.create.topAmount.subtitle" />
                    </div>

                    <div className="form-group form-group--composite topAmountSection" id="topAmount">
                        <div className="topAmountValue">
                            <NumberFormat
                                allowNegative={false}
                                className="form-control"
                                decimalScale={decimalScale}
                                decimalSeparator={decimalSeparator}
                                form={FORM_ID}
                                maxLength="15"
                                name="topAmount.amount"
                                fixedDecimalScale
                                onChange={({ target }) => {
                                    const num = toNumber(target.value, decimalSeparator);
                                    formikBag.setFieldValue(`topAmount.amount`, num);
                                }}
                                thousandSeparator={thousandSeparator}
                                type="text"
                                value={values.topAmount && values.topAmount.amount}
                            />
                            <I18n id={`currency.label.${masterCurrency}`} />
                            {hasError && <FieldError error={errors.topAmount.amount} />}
                        </div>
                        <div className="capFrequency">
                            <I18n id="administration.signatures.transactions.capFrequency.label" />
                            <Field
                                component={Selector}
                                idForm={FORM_ID}
                                name="topAmount.period"
                                className="flex-container slideFromBottom selector"
                                optionClassName="needsclick"
                                options={capFrequencyList.map((capFrequency) => ({
                                    label: capitalizeFirstLetter(
                                        i18nUtils.get(
                                            `administration.signatures.transactions.capFrequency.${capFrequency}`,
                                        ),
                                    ),
                                    value: capFrequency,
                                }))}
                                renderAs="combo"
                                creatable={false}
                                clearable={false}
                                hideLabel
                                value={values.topAmount && values.topAmount.period}
                            />
                        </div>
                    </div>

                    {/* {functionalGroups && this.renderFunctionalGroups(functionalGroups, showFunctionalGroups)}
                    {environmentProducts && this.renderProducts(environmentProducts, toggleProducts)} */}
                </div>
            </div>
        );
    };

    validationSchema = () => {
        const {
            activeEnvironment: { administrationScheme },
        } = this.props;
        const maxSignersCount = configUtils.get("administration.signatures.maxNeeded");

        return Yup.lazy((values) =>
            Yup.object().shape({
                functionalGroups: values.toggleFunctionalGroups
                    ? Yup.array()
                          .of(Yup.string())
                          .min(1, i18nUtils.get("administration.signatures.functionalGroups.atLeastOne"))
                    : Yup.array().notRequired(),
                signatureLevelsCounts: Yup.object().shape(
                    administrationScheme === "medium"
                        ? {
                              A: Yup.number()
                                  .moreThan(
                                      0,
                                      i18nUtils.get(`${FORM_ID}.signersCount.notInRange`, null, {
                                          maxSignersCount,
                                      }),
                                  )
                                  .max(
                                      maxSignersCount,
                                      i18nUtils.get(`${FORM_ID}.signersCount.notInRange`, null, {
                                          maxSignersCount,
                                      }),
                                  )
                                  .required(i18nUtils.get(`${FORM_ID}.signersCount.medium.required`))
                                  .typeError(i18nUtils.get(`${FORM_ID}.signersCount.medium.required`)),
                          }
                        : configUtils.getArray("administration.signatures.signatureLevels").reduce((levels, level) => {
                              const newLevels = levels;
                              newLevels[level] = Object.values(values.signatureLevelsCounts).some((value) => value)
                                  ? Yup.number()
                                        .min(
                                            0,
                                            i18nUtils.get(`${FORM_ID}.signersCount.notInRange`, null, {
                                                maxSignersCount,
                                            }),
                                        )
                                        .max(
                                            maxSignersCount,
                                            i18nUtils.get(`${FORM_ID}.signersCount.notInRange`, null, {
                                                maxSignersCount,
                                            }),
                                        )
                                        .required(i18nUtils.get(`${FORM_ID}.signersCount.advanced.required`))
                                        .typeError(i18nUtils.get(`${FORM_ID}.signersCount.advanced.required`))
                                  : Yup.number().moreThan(0, i18nUtils.get(`${FORM_ID}.signersCount.atLeastOne`));
                              return newLevels;
                          }, {}),
                ),
                signatureType: Yup.string().required(i18nUtils.get(`${FORM_ID}.signatureType.required`)),
                topAmount: Yup.object().when("signatureType", {
                    is: (signatureType) => administrationScheme === "advanced" && signatureType === "AMOUNT",
                    then: Yup.object().shape({
                        amount: Yup.number()
                            .moreThan(0, i18nUtils.get(`${FORM_ID}.topAmount.amount.advanced.mustBePositive`))
                            .required(i18nUtils.get(`${FORM_ID}.topAmount.amount.advanced.required`))
                            .typeError(i18nUtils.get(`${FORM_ID}.topAmount.amount.advanced.required`)),
                        period: Yup.string().required(),
                    }),
                    otherwise: Yup.object().notRequired(),
                }),
            }),
        );
    };

    render() {
        const { fetching, isDesktop, routerActions, isModify } = this.props;
        const formType = isModify ? "modify" : "create";

        if (!isDesktop) {
            return <Redirect to="/desktop" />;
        }

        return (
            <AdministrationContainer
                name={NAME}
                wait={fetching}
                head-title={`administration.signatures.${formType}.advanced.subtitle`}
                head-onBack={routerActions.goBack}
                image="administration-title-icon.svg"
                scopeToShowNotification={SCOPE.ADMINISTRATION_SIGNATURES_SCHEME}>
                {!fetching && this.renderContent()}
            </AdministrationContainer>
        );
    }
}

export default Resizable(Component);
