import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { DOT, SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import {
    SelectorsAction as SelectorsActionComexExport,
    SelectorsStore as SelectorsStoreComexExport,
} from "~/store/comex/export";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import HighOrder from "~/components/HighOrder";

import Step1Edit from "./Step1Edit/Step1Edit";
import Step2Preview from "./Step2Preview/Step2Preview";
import Step3View from "./Step3View/Step3View";

export const NAME = "PresentBillingDocuments";

export const PROP = {
    types: {
        fetching: PropTypes.bool.isRequired,
        setFieldValue: PropTypes.func.isRequired,
        validateForm: PropTypes.func.isRequired,
        ...TypesRedux,
    },
    defaults: {
        ...DefaultsRedux,
    },
};

export const CREDENTIAL_TYPE_OTP = "otp";

const formatNumber = (decimalSeparator, thousandSeparator, decimalScale, num) => {
    const numParts = num
        .toFixed(decimalScale)
        .replace(DOT, decimalSeparator)
        .split(decimalSeparator);
    let l = numParts[0].length - 3;
    while (l > 0) {
        numParts[0] = `${numParts[0].slice(0, l)}${thousandSeparator}${numParts[0].slice(l)}`;
        l -= 3;
    }
    return `${numParts[0]}${decimalSeparator}${numParts[1]}`;
};

export function Component(props) {
    const { dispatch, fetching, mode } = props;

    const handleBack = () => {
        const { history } = props;

        if (mode === MODE.VIEW) {
            dispatch(SelectorsActionComex.restartFlow());
            dispatch(SelectorsActionComexExport.restartFlow());
        } else if (mode === MODE.PREVIEW) {
            dispatch(SelectorsActionComex.toStepOne());
        } else {
            history.goBack();
        }
    };

    return (
        <Container
            head-onBack={handleBack}
            head-title="comex.export.presentBillingDocuments.form.title"
            image="businessPortfolio.svg"
            name={NAME}
            scopeToShowNotification={SCOPE.COMEX_EXPORT_PRESENT_BILLING_DOCUMENTS}
            step={mode}
            wait={fetching}>
            {mode === MODE.EDIT && <Step1Edit formatNumber={formatNumber} />}
            {mode === MODE.PREVIEW && <Step2Preview formatNumber={formatNumber} />}
            {mode === MODE.VIEW && <Step3View ticketConfirmation />}
        </Container>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    fetching: SelectorsStoreComexExport.getFetching(store),
    mode: SelectorsStoreComexExport.getMode(store),
});

export default HighOrder(Connect(mapStateToProps))(Component);
