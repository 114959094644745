import React from "react";

import { routerActions as ActionsRouter } from "connected-react-router";
import PropTypes from "prop-types";
import queryString from "query-string";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, SCOPE } from "~/constants";
import Container from "~/containers/Internal/Dash";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionTemplate } from "~/store/template";
import { PROP as STORE_PROP, SelectorsAction, SelectorsStore } from "~/store/transfers";
import UtilSearchBank from "~/util/bankSearchData";

import TransferHistory from "./_History";
import TransferItem from "./_Item";

export const NAME = "Transfers";

export const PROP = {};
PROP.members = {
    activeEnvironmentPermissions: {
        transferInternal: PropTypes.bool.isRequired,
        transferThirdParties: PropTypes.bool.isRequired,
        transferLocal: PropTypes.bool.isRequired,
        transferForeign: PropTypes.bool.isRequired,
    },
};
PROP.members.activeEnvironment = {
    permissions: PropTypes.shape(PROP.members.activeEnvironmentPermissions).isRequired,
};
PROP.types = {
    activeEnvironment: PropTypes.shape(PROP.members.activeEnvironment),
    ...STORE_PROP.types,
};
PROP.defaults = {
    ...STORE_PROP.defaults,
    LATEST_TRANSACTIONS: "latestTransactions",
    LATEST_TEMPLATES: "latestTemplates",
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        active: {
            latestTransactionsIsSelected: true,
        },
        itemClick: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(SelectorsAction.latestTransactionsListRequest());
    }

    toggleSelected = (e) => {
        const { dispatch } = this.props;

        this.setState({
            active: {
                latestTransactionsIsSelected: e.target.id === "latestTransactions",
            },
        });

        if (e.target.id === PROP.defaults.LATEST_TRANSACTIONS) {
            dispatch(SelectorsAction.latestTransactionsListRequest());
        } else if (e.target.id === PROP.defaults.LATEST_TEMPLATES) {
            dispatch(SelectorsAction.templatesListRequest());
        }
    };

    onItemClicked = (e, item, formPath, isTemplate) => {
        const { dispatch } = this.props;
        const { itemClick } = this.state;

        if (formPath && !itemClick) {
            // If the item was clicked you cannot select another one
            this.setState({
                itemClick: true,
            });

            dispatch(ActionsRouter.push(formPath));

            if (isTemplate) {
                dispatch(SelectorsActionTemplate.loadTemplateFromOtherPage({ loadTemplateFromOtherPage: true }));
                dispatch(SelectorsActionTemplate.persistLoadedTemplateName({ loadedTemplateName: item.name }));
                dispatch(SelectorsActionTemplate.loadTemplateList({ idForm: item.idForm }));
            }

            const amount = {
                currency: item.transactionData.amount.currency,
                quantity:
                    isTemplate && item.transactionData.amount.quantity
                        ? item.transactionData.amount.quantity
                        : EMPTY_STR,
            };

            const transactionData = { ...item.transactionData, amount };

            dispatch(SelectorsActionForm.setData({ data: transactionData }));

            UtilSearchBank.handleSearchBank(transactionData, dispatch);
        }
    };

    handleBack = () => {
        const { history } = this.props;

        history.goBack();
    };

    render() {
        const {
            activeEnvironment: {
                permissions: { transferForeign, transferInternal, transferLocal, transferThirdParties },
            },
            fetching,
            latestTransactionsList,
            location,
            templatesList,
        } = this.props;

        const {
            active: { latestTransactionsIsSelected },
        } = this.state;

        const list = latestTransactionsIsSelected ? latestTransactionsList : templatesList;
        const { query: params } = queryString.parseUrl(location.search);
        const paramString = queryString.stringify(params);

        return (
            <React.Fragment>
                <Container
                    head-title="transfers.title"
                    head-onBackWeb={this.handleBack}
                    name={NAME}
                    scopeToShowNotification={SCOPE.TRANSFER}
                    wait={fetching}>
                    <div className="transfer-setup-block transfer-options">
                        <ul className="transfer-options-list">
                            <TransferItem
                                hasPermission={transferInternal}
                                href={`form/transferInternal?${paramString}`}
                                title="menu.transfers.internal"
                                type="internal"
                            />
                            <TransferItem
                                hasPermission={transferThirdParties}
                                href={`form/transferThirdParties?${paramString}`}
                                title="menu.transfers.thirdParties"
                                type="thirdParties"
                            />
                            <TransferItem
                                hasPermission={transferLocal}
                                href={`form/transferLocal?${paramString}`}
                                title="menu.transfers.local"
                                type="local"
                            />
                            <TransferItem
                                hasPermission={transferForeign}
                                href={`form/transferForeign?${paramString}`}
                                title="menu.transfers.foreign"
                                type="foreign"
                            />
                        </ul>
                    </div>
                    <TransferHistory
                        fetching={fetching}
                        latestTransactionsIsSelected={latestTransactionsIsSelected}
                        list={list}
                        onItemClicked={this.onItemClicked}
                        toggleSelected={this.toggleSelected}
                    />
                </Container>
            </React.Fragment>
        );
    }
}

export default Connect((store) => ({
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
    fetching: SelectorsStore.fetching(store),
    latestTransactionsList: SelectorsStore.latestTransactionsList(store),
    templatesList: SelectorsStore.templatesListList(store),
}))(Component);
