import React from "react";

import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import ClassNames from "classnames";
import { connect as Connect } from "react-redux";

import { DOT, EMPTY_STR, POSITION_VALUES } from "~/constants";
import { SelectorsStore as StoreConfig } from "~/store/config";
import { SelectorsStore as StoreWidget, SelectorsAction as ActionWidget } from "~/store/widget";
import DeviceUtils from "~/util/device";
import { Categorize } from "~/util/prop";
import { ParseList, ParseProps, Prop } from "~/util/style";

import Box from "~/components/Box";
import DataNumber from "~/components/DataNumber";
import I18n from "~/components/I18n";
import Link from "~/components/Link";

import Style from "./Position.rules.scss";
import Widget from "./_common/_Widget";

export const { TYPE, NAME } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

Style.PROPS = ParseProps(Style.PROPS);
Style.COLORS = ParseList(Style.COLORS);

if (Style.COLORS.length !== 9) {
    throw new Error(`The position Widget expects at least 9 colors defined, got: ${Style.COLORS.length}`);
}

export function Component(props) {
    const { dispatch, isFetching, store: widget, type, position, currency, ...rest } = props;
    const keys = position.data && Object.keys(position.data);
    const { length } = `${position.total}`.replace(DOT, EMPTY_STR);
    const positionColors =
        position.data && POSITION_VALUES.reduce((acc, el, index) => ({ ...acc, [el]: Style.COLORS[index] }), []);

    const opts = {
        animate: true,
        legends: [],
        margin: {
            top: Prop(Style, "chart-margin-top", "int"),
            left: Prop(Style, "chart-margin-left", "int"),
            right: Prop(Style, "chart-margin-right", "int"),
            bottom: Prop(Style, "chart-margin-bottom", "int"),
        },
        colors: ({ id }) => positionColors[id],
        tooltip: (item) => (
            <Item item-id={item.id} item-value={item.value} role="tooltip">
                <span className="item-percent">{Math.round((item.value * 100) / position.total)}%</span>
            </Item>
        ),
    };

    React.useEffect(() => {
        dispatch(ActionWidget.listRequest({ widget }));
    }, [dispatch, widget]);

    return (
        <Widget
            {...rest}
            id={Style.ID}
            wait={isFetching}
            header-title="desktop.widgets.position.title"
            header-icon="Posicion.svg"
            header-href={DeviceUtils.isDisplayMobile() ? <HeaderLink /> : "/position"}>
            <section className={type}>
                <header>
                    {keys && keys.map((id) => <Item key={id} item-id={id} item-value={position.data[id]} />)}
                </header>
                {type === "PRM" && (
                    <main className="mr-3">
                        <Box flex directionColumn justify="between" className="h-100 pl-3 mr-4">
                            <I18n id="investment.piechart.info.title" tag="h3" />
                            <Box flex directionColumn className="value">
                                <I18n id="investment.piechart.info.subtitle" />
                                <DataNumber decimalScale={2} prefix={currency} value={position.total} />
                            </Box>
                        </Box>
                    </main>
                )}

                <footer>
                    {type === "CMB" && <CMB />}
                    {type === "PRM" && <PRM />}
                </footer>
            </section>
        </Widget>
    );

    function CMB() {
        const cmb = {
            ...opts,
            borderColor: { from: "color", modifiers: [["darker", 0.2]] },
            borderWidth: 1,
            keys,
            data: [{ ...position.data, id: "0" }], // only one bar is needed.
            indexBy: "id",
            enableLabel: false,
            groupMode: "stacked",
            layout: "horizontal",
            padding: 0,
            axisTop: null,
            axisRight: null,
            axisLeft: null,
            enableGridY: false,
            enableGridX: false,
        };

        return <ResponsiveBar {...cmb} />;
    }

    function PRM() {
        const data = Object.entries(position.data).reduce((acc, [id, value]) => acc.concat({ id, value }), []);
        const prm = {
            ...opts,
            data,
            sortByValue: true,
            innerRadius: 0.65,
            padAngle: 0,
            enableRadialLabels: false,
            enableSlicesLabels: false,
            isInteractive: false,
        };

        return (
            <React.Fragment>
                <DataNumber
                    prefix={currency}
                    decimalScale={2}
                    value={position.total}
                    className={ClassNames({ smaller: length > 8 })}
                />
                <ResponsivePie {...prm} />
            </React.Fragment>
        );
    }

    function Item(itemProps) {
        const { item, children, ...itemRest } = Categorize(itemProps);
        const color = positionColors[item.id];

        return (
            <div className="item" {...itemRest} style={{ "--color": color }}>
                <I18n id={`desktop.widgets.position.${item.id}`} className="item-id" />
                <DataNumber decimalScale={2} value={item.value} className="item-value" />
                {children}
            </div>
        );
    }

    function HeaderLink(linkProps) {
        const { children: linkChildren } = linkProps;

        return <Link onClick={handleClick}>{linkChildren}</Link>;

        function handleClick(ev) {
            ev.preventDefault();

            dispatch(ActionWidget.downloadPdf({ widget }));
        }
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

function mapStateToProps(store, props) {
    const { store: widget } = props;
    const { isFetching, data = {} } = StoreWidget.getWidget(store, widget) || {};
    const position =
        (data.position &&
            Object.values(data.position).reduce(
                (acc, { group, value }) => {
                    return {
                        data: { ...acc.data, [group]: value },
                        length: acc.length + 1,
                        total: acc.total + value,
                    };
                },
                { data: {}, total: 0, length: 0 },
            )) ||
        {};

    return {
        isFetching,
        type: data.segment,
        position,
        currency: StoreConfig.getItem(store, { key: "widget.currency.position" }),
    };
}

export default Connect(mapStateToProps)(Component);
