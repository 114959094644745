import React from "react";

import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsAction, SelectorsStore } from "~/store/settings";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import I18n from "~/components/I18n";
import Modal from "~/components/Modal";
import Container from "~/pages/_components/Container";
import MainContainer from "~/pages/_components/MainContainer";

export const NAME = "SecuritySealList";

export const PROP = {
    types: {
        ...TypesRedux,
        exchangeToken: PropTypes.string.isRequired,
        updateSecuritySeal: PropTypes.bool.isRequired,
        securitySeal: PropTypes.shape({
            id: PropTypes.string,
            image: PropTypes.string,
        }).isRequired,
        securitySealList: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                image: PropTypes.string,
            }),
        ).isRequired,
        fetching: PropTypes.bool,
        showSealModal: PropTypes.bool,
    },
    defaults: {
        ...DefaultsRedux,
        fetching: false,
        showSealModal: false,
    },
};

export function Component({ dispatch, securitySeal, fetching, securitySealList, showSealModal, onClick }) {
    React.useEffect(() => {
        dispatch(SelectorsAction.changePersonalDataSecuritysealsRequest());
    }, [dispatch]);

    const handleClick = (seal) => {
        onClick(false);

        dispatch(SelectorsAction.changeSecuritySeal({ securitySeal: { id: seal, image: securitySealList[seal] } }));
    };

    const handleHeaderClose = () => {
        onClick(false);
        return false;
    };

    return (
        <Modal show={showSealModal} onClose={handleHeaderClose}>
            <div className="enrollment page-login">
                <div className="modal-header">
                    <h4 className="modal-title">
                        <I18n id="change.SecuritySealList.image.title" />
                    </h4>
                    <button
                        type="button"
                        className="close"
                        onClick={handleHeaderClose}
                        onKeyDown={handleHeaderClose}
                        role="link"
                        tabIndex={0}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <MainContainer className="main-container" showLoader={fetching}>
                    <div className="above-the-fold">
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} className="col col-12 image-grid">
                                {securitySealList && securitySeal && (
                                    <Row>
                                        {Object.entries(securitySealList).map(([id, securitySealAux]) => {
                                            const colClass = `col col-4 image-grid-item ${
                                                id === securitySeal.id ? "is-current" : ""
                                            }`;

                                            return (
                                                <Col
                                                    xs={12}
                                                    sm={4}
                                                    md={3}
                                                    lg={2}
                                                    className={colClass}
                                                    key={`securitySeal-${id}`}>
                                                    <Button
                                                        className="btn-image"
                                                        id={id}
                                                        label={EMPTY_STR}
                                                        onClick={() => {
                                                            handleClick(id);
                                                        }}
                                                        style={{
                                                            backgroundImage: `url(${securitySealAux})`,
                                                        }}
                                                    />
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                )}
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </div>
        </Modal>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Connect((store) => ({
    // fetching: SelectorsStore.fetching(store),

    securitySealList: SelectorsStore.getSecuritySealList(store),
    securitySeal: SelectorsStore.getSecuritySeal(store),
}))(React.memo(Component));
