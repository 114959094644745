import React from "react";

import { arrayOf, node, oneOfType, func } from "prop-types";

import Element from "~/components/Component";
import Namespace from "~/components/GridLayout/GridLayout.scss";

export const { CLASS, NAME, TYPE, TAG } = Namespace;

export const PROP = {
    types: {
        children: oneOfType([arrayOf(node), node]).isRequired,
        onClick: func,
    },
    defaults: {
        children: null,
        onClick: null,
    },
};

export function Component({ children, className, tag, onClick }) {
    return (
        <Element className={className} name={NAME} tag={tag || TAG} type={TYPE} role="listitem" onClick={onClick}>
            {children}
        </Element>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
