import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    loadListRequest: (props) => API.executeWithAccessToken(PATH.TRANSACTIONS_LIST, props),
    // approveTransactionsPreviewRequest: (props) =>
    //     API.executeWithAccessToken(PATH.TRANSACTIONS_SIGN_BATCH_PREVIEW, props),
    // cancelTransactionsPreviewRequest: (props) =>
    //     API.executeWithAccessToken(PATH.TRANSACTIONS_CANCEL_BATCH_PREVIEW, props),
    // approveTransactionsRequest: (props) => API.executeWithWebToken(PATH.TRANSACTIONS_SIGN_BATCH_SEND, props),
    // cancelTransactionsRequest: (props) => API.executeWithWebToken(PATH.TRANSACTIONS_CANCEL_BATCH_SEND, props),
    deleteDraftRequest: (props) => API.executeWithAccessToken(PATH.CORE_DELETE_TRANSACTION_DRAFT, props),
    readTransaction: (props) => API.executeWithAccessToken(PATH.READ_TRANSACTION, props),

    shareTicketRequest: (props) => API.executeWithAccessToken(PATH.TRANSACTIONS_SHARE_TICKET_SEND, props),
};

export const SelectorsAction = {
    loadListRequest: (props) => FactoryAction(TYPE.LOAD_LIST_REQUEST, props),
    loadListFailure: () => FactoryAction(TYPE.LOAD_LIST_FAILURE),
    loadListSuccess: (props) => FactoryAction(TYPE.LOAD_LIST_SUCCESS, props),
    setFilters: (props) => FactoryAction(TYPE.SET_FILTERS, props),
    loadMoreTransactionsRequest: (props) => FactoryAction(TYPE.LOAD_MORE_TRANSACTIONS_REQUEST, props),
    loadMoreTransactionsSuccess: (props) => FactoryAction(TYPE.LOAD_MORE_TRANSACTIONS_SUCCESS, props),
    deleteDraftRequest: (props) => FactoryAction(TYPE.DELETE_DRAFT_REQUEST, props),
    deleteDraftFailure: () => FactoryAction(TYPE.DELETE_DRAFT_FAILURE),
    deleteDraftSuccess: (props) => FactoryAction(TYPE.DELETE_DRAFT_SUCCESS, props),
    readTransactionRequest: (props) => FactoryAction(TYPE.READ_TRANSACTION, props),
    readTransactionSuccess: (props) => FactoryAction(TYPE.READ_TRANSACTION_SUCCESS, props),
    readTransactionFailure: (props) => FactoryAction(TYPE.READ_TRANSACTION_FAILURE, props),
    // approveTransactionsPreviewRequest: (props) => FactoryAction(TYPE.APPROVE_TRANSACTIONS_PREVIEW_REQUEST, props),
    // approveTransactionsPreviewFailure: () => FactoryAction(TYPE.APPROVE_TRANSACTIONS_PREVIEW_FAILURE),
    // approveTransactionsPreviewSuccess: (props) => FactoryAction(TYPE.APPROVE_TRANSACTIONS_PREVIEW_SUCCESS, props),
    // cancelTransactionsPreviewRequest: (props) => FactoryAction(TYPE.CANCEL_TRANSACTIONS_PREVIEW_REQUEST, props),
    // cancelTransactionsPreviewFailure: () => FactoryAction(TYPE.CANCEL_TRANSACTIONS_PREVIEW_FAILURE),
    // cancelTransactionsPreviewSuccess: (props) => FactoryAction(TYPE.CANCEL_TRANSACTIONS_PREVIEW_SUCCESS, props),
    // approveTransactionsRequest: (props) => FactoryAction(TYPE.APPROVE_TRANSACTIONS_REQUEST, props),
    // approveTransactionsFailure: (props) => FactoryAction(TYPE.APPROVE_TRANSACTIONS_FAILURE, props),
    // approveTransactionsSuccess: (props) => FactoryAction(TYPE.APPROVE_TRANSACTIONS_SUCCESS, props),
    // cancelTransactionsRequest: (props) => FactoryAction(TYPE.CANCEL_TRANSACTIONS_REQUEST, props),
    // cancelTransactionsFailure: (props) => FactoryAction(TYPE.CANCEL_TRANSACTIONS_FAILURE, props),
    // cancelTransactionsSuccess: (props) => FactoryAction(TYPE.CANCEL_TRANSACTIONS_SUCCESS, props),
    clearPreview: () => FactoryAction(TYPE.CLEAR_PREVIEW),
    // setApproving: (props) => FactoryAction(TYPE.SET_APPROVING, props),
    clearTransactionList: () => FactoryAction(TYPE.CLEAR_TRANSACTION_LIST),
    resumeTransactions: (props) => FactoryAction(TYPE.RESUME_TRANSACTIONS, props),
    shareTicketRequest: (props) => FactoryAction(TYPE.SHARE_TICKET_REQUEST, props),
    shareTicketFailure: (props) => FactoryAction(TYPE.SHARE_TICKET_FAILURE, props),
    shareTicketSuccess: (props) => FactoryAction(TYPE.SHARE_TICKET_SUCCESS, props),
    // removeDelayedTransactionModal: (props) => FactoryAction(TYPE.DELAYED_STOP, props),
    selectedTransaction: () => FactoryAction(TYPE.LOADING),
    resetFilter: () => FactoryAction(TYPE.RESET_FILTER),
    // delayedStart: (props) => FactoryAction(TYPE.DELAYED_START, props),
};

export const SelectorsStore = {
    getTransactions: (store) => store[NAME].transactions,
    getTransaction: (store) => store[NAME].transaction,
    getDelayedTransactions: (store) => store[NAME].delayedTransactions,
    getTotalTransactions: (store) => store[NAME].totalTransactions,
    getFetching: (store) => store[NAME].isFetching,
    getHasMoreData: (store) => store[NAME].hasMoreData,
    getPageNumber: (store) => store[NAME].pageNumber,
    getFilters: (store) => store[NAME].filters,
    isDeletingDraft: (store) => store[NAME].isDeletingDraft,
    getidApprovals: (store) => store[NAME].idTransactionList,
    getSignAction: (store) => store[NAME].isApproving,
    getErrors: (store) => store[NAME].errors,
    getResumeTransaction: (store) => store[NAME].resumeTransaction,
    getCredentialsGroups: (store) => store[NAME].credentialsGroups,
    getFilterChange: (store) => store[NAME].isFilterChanged,
    getIsDelayed: (store) => store[NAME].isDelayed,
    getMode: (store) => store[NAME].mode,
};
