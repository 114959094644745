import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import { SelectorsStore as SelectorsStoreComexImport } from "~/store/comex/import";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import I18n from "~/components/I18n";
import Ticket from "~/components/Ticket/Ticket";
import FormattedAmount from "~/pages/_components/FormattedAmount";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const { transaction, ticketConfirmation, dispatch } = props;
    const { data } = transaction && transaction;
    const {
        cancellationType,
        operationNumber,
        invoiceAmount,
        formattedAmount,
        amountCurrency,
        drawer,
        oldAmount,
        authorizedDocument,
        authorizedDocumentName,
        accountNumber,
        scheduler,
    } = data;

    const shareTicket = (idTransactionToRead) => {
        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    };

    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}
                    displayDownloadPDF
                    handleDownloadPDF={() => shareTicket(transaction.idTransaction)}
                    hasMobileComponent>
                    <Box flex directionColumn align="flex-start" className="mb-3">
                        <div>
                            <I18n id="client.import.billingCancellation.step2.opening" tag="p" className="mt-3" />
                        </div>
                        <Box flex className="info-label">
                            <h4 className="bold">
                                {UtilsI18n.get("client.import.billingCancellation.step2.operationNumber")}
                            </h4>
                            <span>{operationNumber}</span>
                        </Box>
                        <Box flex className="info-label">
                            <h4 className="bold">{UtilsI18n.get("client.import.billingCancellation.step2.amount")}</h4>
                            <FormattedAmount currency={amountCurrency} quantity={parseFloat(invoiceAmount)} />
                        </Box>
                        <Box flex className="info-label">
                            <h4 className="bold">{UtilsI18n.get("client.import.billingCancellation.step2.drawer")}</h4>
                            <span>{drawer}</span>
                        </Box>
                        {cancellationType !== "total" || invoiceAmount + oldAmount < 0 ? (
                            <div className="info-label">
                                <I18n
                                    id="comex.import.cancelacionDeCobranza.letter.parcial"
                                    NRO_CUENTA={accountNumber}
                                    IMPORTE={formattedAmount}
                                    MONEDA={UtilsI18n.get(`core.currency.label.${amountCurrency}`)}
                                    TOTAL_PARCIAL={cancellationType}
                                    tag="p"
                                    className="mt-3"
                                />
                            </div>
                        ) : (
                            <div className="info-label">
                                <I18n
                                    id="comex.import.cancelacionDeCobranza.letter.total"
                                    NRO_CUENTA={accountNumber}
                                    IMPORTE={formattedAmount}
                                    MONEDA={UtilsI18n.get(`core.currency.label.${amountCurrency}`)}
                                    tag="p"
                                    className="mt-3"
                                />
                            </div>
                        )}
                        {authorizedDocumentName && (
                            <div className="info-label">
                                <I18n
                                    id="comex.import.cancelacionDeCobranza.letter.authorizedName"
                                    AUTORIZADO={authorizedDocumentName}
                                    TIPO_DOCUMENTO={UtilsI18n.get(`documentType.label.${authorizedDocument.type}`)}
                                    DOCUMENTO={authorizedDocument.document}
                                    tag="p"
                                    className="mt-3"
                                />
                            </div>
                        )}
                        <div className="info-label">
                            <I18n id="comex.import.cancelacionDeCobranza.letter.closing" tag="p" className="mt-3" />
                        </div>
                    </Box>
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { dispatch } = props;
        const { idTransaction } = transaction;
        dispatch(
            SelectorsActionComex.cancelTransactionCustom({
                credentials: { otp: "" },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        const { dispatch } = props;
        dispatch(SelectorsActionComex.signTransactionPreview({ transaction }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreComexImport.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
