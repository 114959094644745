import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { CREDIT_CARDS_LIMIT_INCREASE_TYPE, EMPTY_STR } from "~/constants";
import {
    SelectorsAction as SelectorsActionCreditCards,
    SelectorsStore as SelectorsStoreCreditCards,
} from "~/store/creditCards/creditCard";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import DataDate from "~/components/DataDate";
import I18n from "~/components/I18n";
import Ticket from "~/components/Ticket/Ticket";
import FormattedAmount from "~/pages/_components/FormattedAmount";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const { dispatch, ticketConfirmation, transaction } = props;
    const { data } = transaction || {};
    const { alias, amount, beginDate, currency, endDate, limitType } = data;

    const shareTicket = (idTransactionToRead) => {
        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    };

    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}
                    hasMobileComponent
                    displayDownloadPDF
                    handleDownloadPDF={() => shareTicket(transaction.idTransaction)}>
                    <Box directionColumn align="flex-start" className="mb-3 data-ticket">
                        <Box className="data-wrapper">
                            <span className="data-label">
                                {UtilsI18n.get("creditCards.limitIncrease.step2.selectedAccount")}
                            </span>
                            <span className="data-name">{alias}</span>
                        </Box>
                        <Box className="data-wrapper">
                            <span className="data-label">
                                {UtilsI18n.get("creditCards.limitIncrease.step2.newLimit")}
                            </span>
                            <FormattedAmount currency={currency} quantity={parseFloat(amount)} />
                        </Box>
                        <Box className="data-wrapper">
                            <span className="data-label">
                                <I18n id="creditCards.limitIncrease.step3.limit" />
                            </span>
                            <span className="data-name">
                                {UtilsI18n.get(
                                    `creditCards.limitIncrease.step2.${
                                        limitType || CREDIT_CARDS_LIMIT_INCREASE_TYPE.TEMPORARY
                                    }`,
                                )}
                            </span>
                        </Box>
                        <Box className="data-wrapper">
                            <span className="data-label">
                                {UtilsI18n.get("creditCards.limitIncrease.step3.incrementToday")}
                            </span>
                            <DataDate value={beginDate} />
                        </Box>
                        <Box className="data-wrapper">
                            <span className="data-label">
                                {UtilsI18n.get("creditCards.limitIncrease.step3.incrementDueDate")}
                            </span>
                            <DataDate value={endDate} />
                        </Box>
                    </Box>
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { idTransaction } = transaction;
        dispatch(
            SelectorsActionCreditCards.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        dispatch(SelectorsActionCreditCards.signTransactionPreview({ data }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreCreditCards.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
