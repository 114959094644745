import { routerActions } from "connected-react-router";
import { connect as Connect } from "react-redux";
import { bindActionCreators } from "redux";

import { SelectorsStore as SelectorsStoreSignatures } from "~/store/administration/common/signatures";
import { SelectorsActionSignaturesMedium } from "~/store/administration/medium";
import { SelectorsAction as formActions } from "~/store/form";
import { SelectorsStore as StoreSession } from "~/store/session";

import SignaturesSchemeCreate from "~/pages/administration/_components/SignaturesSchemeModify";

const FORM_ID = "administration.signatures.modify";

const mapStateToProps = (store) => ({
    activeEnvironment: StoreSession.getActiveEnvironment(store),
    capFrequencyList: SelectorsStoreSignatures.capFrequencyList(store),
    credentialGroups: SelectorsStoreSignatures.getCredentialGroups(store),
    environmentProducts: SelectorsStoreSignatures.environmentProducts(store),
    fetching: SelectorsStoreSignatures.fetching(store),
    functionalGroups: SelectorsStoreSignatures.functionalGroups(store),
    idActivity: SelectorsStoreSignatures.getIdActivity(store),
    idForm: FORM_ID,
    idTransaction: SelectorsStoreSignatures.getIdTransaction(store),
    masterCurrency: SelectorsStoreSignatures.masterCurrency(store),
    selectedFunctionalGroups: SelectorsStoreSignatures.selectedFunctionalGroups(store),
    selectedProducts: SelectorsStoreSignatures.selectedProducts(store),
    signature: SelectorsStoreSignatures.currentSignature(store),
    signatureGroupMap: SelectorsStoreSignatures.currentSignatureGroupMap(store),
    signatureTypeList: SelectorsStoreSignatures.signatureTypeList(store),
    signaturesSchemes: SelectorsStoreSignatures.signaturesSchemes(store),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(SelectorsActionSignaturesMedium, dispatch),
    formActions: bindActionCreators(formActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default Connect(mapStateToProps, mapDispatchToProps)(SignaturesSchemeCreate);
