import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SelectorsStore as SelectorsStoreTicket } from "~/store/administration/common/administrationTicket";

import I18n from "~/components/I18n";

import Style from "./SignaturesSchemeMediumTicket.rules.scss";

export const NAME = "SignaturesScheme";

export const PROP = {
    types: {
        transaction: PropTypes.object.isRequired,
    },
    defaults: {},
};

export function Component({ transaction }) {
    const sigAmount = transaction?.data?.signatureTypeAmountModify;
    const sigNoAmount = transaction?.data?.signatureTypeNoAmountModify;

    const listSignature = [sigAmount, sigNoAmount];

    const Schema = (info) => {
        const { typeTransaction, signatureCount } = info;

        return (
            <div id={Style.ID}>
                <div className="form--signature--modify--title ">
                    <div className="data-label">
                        <I18n id={`administration.signatures.modify.signatureType.${typeTransaction}`} />
                    </div>
                </div>

                <div className="form--signature--modify--field">
                    <div className="control-label form-group-text data-label">
                        <I18n id="administration.signatures.modify.medium.signersCount" />
                    </div>
                    <span>{signatureCount}</span>
                </div>
            </div>
        );
    };

    return (
        <div>
            {listSignature?.map((signature) => (
                <Schema
                    typeTransaction={signature?.typeSignature}
                    signatureCount={signature?.signatureLevelsCounts?.A}
                />
            ))}
        </div>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    transaction: SelectorsStoreTicket.getData(store),
});

export default Connect(mapStateToProps)(Component);
