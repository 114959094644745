import { call as Call, put as Put, takeLatest as TakeLatest, select as Select } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE } from "~/constants";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { downloadBase64 } from "~/util/download";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction, SelectorsStore } from "./_selectors";

export default [
    TakeLatest(TYPE.GET_CATEGORIES_REQUEST, getCategoriesRequest),
    TakeLatest(TYPE.GET_SUB_CATEGORIES_REQUEST, getSubCategoriesRequest),
    TakeLatest(TYPE.GET_FILE_DOWNLOADS_LIST_REQUEST, getFileDownloadsListRequest),
    TakeLatest(TYPE.GET_FILE_DOWNLOAD_REQUEST, getFileDownloadRequest),
];

function* getCategoriesRequest() {
    const {
        type,
        data: { message, data },
    } = yield Call(SelectorsMiddleware.getCategories);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.getCategoriesFailure({ error: message }));

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.FORM],
            }),
        );
    } else {
        yield Put(SelectorsAction.getCategoriesSuccess(data));
    }
}

function* getSubCategoriesRequest(props) {
    const {
        type,
        data: { message, data },
    } = yield Call(SelectorsMiddleware.getSubCategories, { categoryId: props.categoryId });
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.getSubCategoriesFailure({ error: message }));

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.FORM],
            }),
        );
    } else {
        yield Put(SelectorsAction.getSubCategoriesSuccess(data));
    }
}

function* getFileDownloadsListRequest() {
    const pageNumber = yield Select(SelectorsStore.getPageNumber);
    const filters = yield Select(SelectorsStore.getFilters);

    const {
        type,
        data: { message, data },
    } = yield Call(SelectorsMiddleware.getFileDownloadsList, { filters, pageNumber });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.getFileDownloadsListFailure({ error: message }));

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.FORM],
            }),
        );
    } else {
        yield Put(SelectorsAction.getFileDownloadsListSuccess(data));
    }
}

function* getFileDownloadRequest({ downloadId }) {
    const {
        type,
        data: { message, data },
    } = yield Call(SelectorsMiddleware.getFileDownload, { downloadId });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.downloadFileFailure({ error: message }));

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.FORM],
            }),
        );
    } else {
        const { content, fileName } = data;

        downloadBase64(fileName, content);
        yield Put(SelectorsAction.downloadFileSuccess());
    }
}
