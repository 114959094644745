import React from "react";

import { routerActions as RouterActions } from "connected-react-router";
import { connect as Connect } from "react-redux";
import { bindActionCreators } from "redux";

import { TYPE_ADMINISTRATION } from "~/constants";
import { SelectorsActionSignaturesAdvanced } from "~/store/administration/advanced";
import { SelectorsStore as SelectorsStoreTicket } from "~/store/administration/common/administrationTicket";
import { SelectorsStore } from "~/store/administration/common/details";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { getUser } from "~/util/store/administration";

import AdministrationTicket from "~/pages/administration/_components/tickets/AdministrationTicket";

import SignatureTicketContent from "./SignatureTicketContent";

export const NAME = "SignatureTicket";

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    return (
        <AdministrationTicket {...props}>
            <SignatureTicketContent {...props} />
        </AdministrationTicket>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store, props) => {
    const { lang } = props;

    return {
        fetching: SelectorsStore.isFetching(store),
        lang: lang || StoreI18n.getLang(store) || window.navigator.language.slice(0, 2),
        schemeName: TYPE_ADMINISTRATION.ADVANCED,
        signatureLevel: SelectorsStore.getSignatureLevel(store),
        transaction: SelectorsStoreTicket.getData(store),
        user: getUser(store),
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(SelectorsActionSignaturesAdvanced, dispatch),
    routerActions: bindActionCreators(RouterActions, dispatch),
});

export default Connect(mapStateToProps, mapDispatchToProps)(Component);
