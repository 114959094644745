import React from "react";

import PropTypes from "prop-types";

import { SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import * as UtilsI18n from "~/util/i18n";

import StepEdit from "./Step1Edit/Step1Edit";
import FormPreview from "./Step2Preview/Step2Preview";
import FormView from "./Step3View/Step3View";

export const NAME = "Form";

export const PROP = {
    types: {
        isChangingEnvironment: PropTypes.bool,
    },
    defaults: {
        isChangingEnvironment: false,
    },
};

export function Component(props) {
    const { idPaper, isChangingEnvironment, isFetching, mode, routerActions, SelectorsActionWMBuy } = props;

    React.useEffect(() => {
        if (idPaper === 0) {
            routerActions.goBack();
        }

        if (!isChangingEnvironment) {
            SelectorsActionWMBuy.readPaperRequest({ idPaper });
        }

        return () => {
            SelectorsActionWMBuy.resetForm();
        };
    }, [idPaper, isChangingEnvironment, routerActions, SelectorsActionWMBuy]);

    const handleBack = React.useCallback(() => {
        if (mode === MODE.EDIT) {
            routerActions.goBack();
        } else if (mode === MODE.PREVIEW) {
            SelectorsActionWMBuy.closeConfirmation();
        } else if (mode === MODE.VIEW) {
            SelectorsActionWMBuy.restartFlow();
        }
    }, [SelectorsActionWMBuy, mode, routerActions]);

    return (
        <Container
            name={NAME}
            wait={isFetching}
            step={mode}
            head-onBack={handleBack}
            head-onBackWeb={handleBack}
            head-title={UtilsI18n.get("client.wm.buy.title")}
            scopeToShowNotification={SCOPE.WEALTH_MANAGEMENT_BUY_FUNDS}>
            <React.Fragment>
                {mode === MODE.EDIT && <StepEdit />}
                {mode === MODE.PREVIEW && <FormPreview />}
                {mode === MODE.VIEW && <FormView ticketConfirmation />}
            </React.Fragment>
        </Container>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
