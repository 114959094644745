import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";
import { MODE } from "~/constants/form";

export const NAME = "transactionsHistory";

export const PATH = {
    TRANSACTIONS_LIST: "/nazca.transactions.list.execute",
    READ_TRANSACTION: "/nazca.transaction.read",
    CORE_DELETE_TRANSACTION_DRAFT: "/core.deleteTransactionDraft",
    TRANSACTIONS_SHARE_TICKET_SEND: "/transactions.shareTicket.send",
};

export const TYPE = FactoryType(NAME, [
    "LOAD_LIST_REQUEST",
    "LOAD_LIST_FAILURE",
    "LOAD_LIST_SUCCESS",
    "RESUME_TRANSACTIONS",
    "SET_FILTERS",
    "LOAD_MORE_TRANSACTIONS_REQUEST",
    "LOAD_MORE_TRANSACTIONS_SUCCESS",
    "DELETE_DRAFT_REQUEST",
    "DELETE_DRAFT_FAILURE",
    "DELETE_DRAFT_SUCCESS",
    "READ_TRANSACTION",
    "READ_TRANSACTION_SUCCESS",
    "READ_TRANSACTION_FAILURE",
    // "APPROVE_TRANSACTIONS_PREVIEW_REQUEST",
    // "APPROVE_TRANSACTIONS_PREVIEW_FAILURE",
    // "APPROVE_TRANSACTIONS_PREVIEW_SUCCESS",
    // "CANCEL_TRANSACTIONS_PREVIEW_REQUEST",
    // "CANCEL_TRANSACTIONS_PREVIEW_FAILURE",
    // "CANCEL_TRANSACTIONS_PREVIEW_SUCCESS",
    // "APPROVE_TRANSACTIONS_REQUEST",
    // "APPROVE_TRANSACTIONS_FAILURE",
    // "APPROVE_TRANSACTIONS_SUCCESS",
    // "CANCEL_TRANSACTIONS_REQUEST",
    // "CANCEL_TRANSACTIONS_FAILURE",
    // "CANCEL_TRANSACTIONS_SUCCESS",
    //    "DELAYED_START",
    //    "DELAYED_STOP",
    "CLEAR_PREVIEW",
    //   "SET_APPROVING",
    "CLEAR_TRANSACTION_LIST",
    "SHARE_TICKET_REQUEST",
    "SHARE_TICKET_FAILURE",
    "SHARE_TICKET_SUCCESS",
    "LOADING",
    "RESET_FILTER",
]);

export const PROP = {};

PROP.types = {
    transactions: PropTypes.object,
    isFetching: PropTypes.bool,
    filters: PropTypes.object,
    hasMoreData: PropTypes.bool,
    pageNumber: PropTypes.number,
    isDeletingDraft: PropTypes.bool,
    idTransactionList: PropTypes.array,
    isFilterChanged: PropTypes.bool,
    isApproving: PropTypes.string,
    isDelayed: PropTypes.bool,
    totalTransactions: PropTypes.string,
    errors: PropTypes.object,
    delayedTransactions: PropTypes.string,
    resumeTransaction: PropTypes.object,
    credentialsGroups: PropTypes.array,
    ...TypesRedux,
};

PROP.defaults = {
    transactions: null,
    isFetching: false,
    filters: null,
    hasMoreData: false,
    pageNumber: 1,
    isDeletingDraft: false,
    idTransactionList: [],
    isFilterChanged: false,
    isApproving: null,
    isDelayed: false,
    totalTransactions: null,
    errors: null,
    delayedTransactions: null,
    resumeTransaction: null,
    credentialsGroups: [],
    transaction: null,
    mode: MODE.EDIT,
    ...DefaultsRedux,
};
