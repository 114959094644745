import PropTypes from "prop-types";

import { EMPTY_STR, ZERO_NUMBER } from "~/constants";
import { MODE } from "~/constants/form";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "wm.funds.switch";

export const PATH = {
    WM_FUNDS_SWITCH_PRE: "/client.wm.funds.switch.pre",
    WM_FUNDS_SWITCH_PREVIEW: "/client.wm.funds.switch.preview",
    WM_FUNDS_SWITCH_READ_BUY_PAPER: "/client.wm.funds.readPaper",
    WM_FUNDS_SWITCH_SEND: "/client.wm.funds.switch.send",
};

export const TYPE = FactoryType(NAME, [
    "CANCEL_CUSTOM_TRANSACTION_REQUEST",
    "CLOSE_TRANSACTION_PREVIEW",
    "PREVIEW_CANCEL_REQUEST",
    "PREVIEW_PAPER_REQUEST",
    "PREVIEW_PAPER_SUCCESS",
    "PRE_FORM_REQUEST",
    "PRE_FORM_SUCCESS",
    "READ_PAPER_TO_BUY_REQUEST",
    "READ_PAPER_TO_BUY_SUCCESS",
    "READ_PAPER_TO_SWITCH_SUCCESS",
    "READ_TRANSACTION_REQUEST",
    "READ_TRANSACTION_SUCCESS",
    "RESET_FORM",
    "RESTART_FLOW",
    "SELECTED_ID_PAPER",
    "SEND_FORM_PAPER_REQUEST",
    "SEND_FORM_PAPER_SUCCESS",
    "SIGN_TRANSACTION_PREVIEW",
    "SIGN_TRANSACTION_REQUEST",
    "TASK_FINISHED",
]);

export const PROP = {};

PROP.types = {
    ...TypesRedux,
    amount: PropTypes.number,
    debitAccountAlias: PropTypes.string,
    debitAccounts: PropTypes.array,
    disclaimer: PropTypes.bool,
    errors: PropTypes.object,
    idDebitAccount: PropTypes.string,
    idPaperSelectedToBuy: PropTypes.number,
    idPaperSelectedToSwitch: PropTypes.number,
    isFetching: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    mode: PropTypes.string,
    paperToBuyName: PropTypes.string,
    paperToSwitchName: PropTypes.string,
    papersToBuy: PropTypes.array,
    papersToSwitch: PropTypes.array,
    prevMode: PropTypes.string,
    selectedPaperToBuy: PropTypes.object,
    selectedPaperToSwitch: PropTypes.object,
    timezoneOffset: PropTypes.number,
    transaction: PropTypes.object,
};

PROP.defaults = {
    ...DefaultsRedux,
    amount: ZERO_NUMBER,
    debitAccountAlias: EMPTY_STR,
    debitAccounts: [],
    disclaimer: false,
    errors: {},
    idDebitAccount: EMPTY_STR,
    idPaperSelectedToBuy: ZERO_NUMBER,
    idPaperSelectedToSwitch: ZERO_NUMBER,
    isFetching: false,
    isSubmitting: false,
    mode: MODE.EDIT,
    paperToBuyName: EMPTY_STR,
    paperToSwitchName: EMPTY_STR,
    papersToBuy: [],
    papersToSwitch: [],
    prevMode: null,
    selectedPaperToBuy: {},
    selectedPaperToSwitch: {},
    timezoneOffset: ZERO_NUMBER,
    transaction: {},
};
