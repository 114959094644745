import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "widgets";

export const PATH = { WIDGETS: "/widgets.", POSITION_PDF_DOWNLOAD: "/position.pdf.download" };

export const TYPE = FactoryType(NAME, [
    "DELETE_WIDGET",
    "DOWNLOAD_POSITION_FAILURE",
    "DOWNLOAD_POSITION",
    "DOWNLOAD_SUCCESS",
    "LIST_FAILURE",
    "LIST_REQUEST",
    "LIST_SUCCESS",
]);

export const PROP = {};

PROP.members = {
    accounts: {
        data: PropTypes.shape({ accounts: PropTypes.array }),
        isFetching: PropTypes.bool,
    },
    creditCards: {
        data: PropTypes.shape({ creditCards: PropTypes.array }),
        isFetching: PropTypes.bool,
    },
    debitCards: {
        data: PropTypes.shape({ debitCards: PropTypes.array }),
        isFetching: PropTypes.bool,
    },
    exchangeRates: {
        data: PropTypes.shape({ exchangeRates: PropTypes.array }),
        isFetching: PropTypes.bool,
    },
    factoring: {
        data: PropTypes.object,
        fetching: PropTypes.bool,
        isFetching: PropTypes.bool,
    },
    loans: {
        data: PropTypes.shape({ loans: PropTypes.array }),
        isFetching: PropTypes.bool,
    },
    notifications: {
        data: PropTypes.object,
        isFetching: PropTypes.bool,
    },
    pendingTransactions: {
        data: PropTypes.shape({ pendingTransactions: PropTypes.array }),
        isFetching: PropTypes.bool,
    },
    portfolio: {
        data: PropTypes.object,
        isFetching: PropTypes.bool,
    },
    position: {
        data: PropTypes.object,
        fetching: PropTypes.bool,
        isFetching: PropTypes.bool,
    },
    preferentialTradingPrice: {
        data: PropTypes.shape({ preferentialTradingPrice: PropTypes.array }),
        isFetching: PropTypes.bool,
    },
    qrPayments: {
        isFetching: PropTypes.bool,
    },
    servicePayments: {
        isFetching: PropTypes.bool,
    },
    termDeposit: {
        data: PropTypes.object,
        fetching: PropTypes.bool,
    },
};

PROP.types = {
    accounts: PropTypes.shape(PROP.members.accounts),
    creditCards: PropTypes.shape(PROP.members.creditCards),
    debitCards: PropTypes.shape(PROP.members.debitCards),
    exchangeRates: PropTypes.shape(PROP.members.exchangeRates),
    factoring: PropTypes.shape(PROP.members.factoring),
    loans: PropTypes.shape(PROP.members.loans),
    notifications: PropTypes.shape(PROP.members.notifications),
    pendingTransactions: PropTypes.shape(PROP.members.pendingTransactions),
    portfolio: PropTypes.shape(PROP.members.portfolio),
    position: PropTypes.shape(PROP.members.position),
    preferentialTradingPrice: PropTypes.shape(PROP.members.preferentialTradingPrice),
    qrPayments: PropTypes.shape(PROP.members.qrPayments),
    servicePayments: PropTypes.shape(PROP.members.servicePayments),
    termDeposit: PropTypes.shape(PROP.members.termDeposit),
    ...TypesRedux,
};

PROP.defaults = {
    accounts: {
        data: { accounts: [] },
        isFetching: false,
    },
    creditCards: {
        data: { creditCards: [] },
        isFetching: false,
    },
    debitCards: {
        data: { debitCards: [] },
        isFetching: false,
    },
    exchangeRates: {
        data: { exchangeRates: [] },
        isFetching: false,
    },
    factoring: {
        data: undefined,
        fetching: false,
        isFetching: false,
    },
    loans: {
        data: { loans: [] },
        isFetching: false,
    },
    notifications: {
        data: undefined,
        isFetching: false,
    },
    pendingTransactions: {
        data: { pendingTransactions: [] },
        isFetching: false,
    },
    portfolio: {
        data: undefined,
        isFetching: false,
    },
    position: {
        data: undefined,
        fetching: false,
        isFetching: false,
    },
    preferentialTradingPrice: {
        data: { preferentialTradingPrice: [] },
        isFetching: false,
    },
    qrPayments: {
        isFetching: false,
    },
    servicePayments: {
        isFetching: false,
    },
    termDeposit: {
        data: undefined,
        fetching: false,
    },
    ...DefaultsRedux,
};
