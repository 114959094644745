import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "recoveryPasswordNoToken";

export const PATH = {
    SESSION_RECOVER_PASSWORD_NO_TOKEN_STEP1: "/session.recoverPasswordNoToken.step1",
    SESSION_RECOVER_PASSWORD_NO_TOKEN_STEP2: "/session.recoverPasswordNoToken.step2",
    SESSION_RECOVER_PASSWORD_NO_TOKEN_STEP3: "/session.recoverPasswordNoToken.step3",
    SESSION_RECOVER_PASSWORD_NO_TOKEN_STEP4: "/session.recoverPasswordNoToken.step4",
    SESSION_RECOVER_PASSWORD_NO_TOKEN_RESEND_SMS: "/session.recoverPasswordNoToken.resend",
};

export const TYPE = FactoryType(NAME, [
    "RECOVER_PASS_CLEAN",
    "RECOVER_PASS_REMEMBER_USERNAME",
    "RECOVER_PASS_NO_TOKEN_STEP1_REQUEST",
    "RECOVER_PASS_NO_TOKEN_STEP1_FAILURE",
    "RECOVER_PASS_NO_TOKEN_STEP1_SUCCESS",
    "RECOVER_PASS_NO_TOKEN_STEP2_REQUEST",
    "RECOVER_PASS_NO_TOKEN_STEP2_FAILURE",
    "RECOVER_PASS_NO_TOKEN_STEP2_SUCCESS",
    "RECOVER_PASS_NO_TOKEN_STEP3_REQUEST",
    "RECOVER_PASS_NO_TOKEN_STEP3_FAILURE",
    "RECOVER_PASS_NO_TOKEN_STEP3_SUCCESS",
    "RECOVER_PASS_NO_TOKEN_STEP4_REQUEST",
    "RECOVER_PASS_NO_TOKEN_STEP4_FAILURE",
    "RECOVER_PASS_NO_TOKEN_STEP4_SUCCESS",
    "RESEND_VERIFICATION_CODE_REQUEST",
    "RESEND_VERIFICATION_CODE_SUCCESS",
    "RESEND_VERIFICATION_CODE_FAILURE",
]);

export const PROP = {};

PROP.types = {
    blocked: PropTypes.bool,
    exchangeToken: PropTypes.string,
    fetching: PropTypes.bool,
    mobileNumber: PropTypes.string,
    verificationCode: PropTypes.string,
    username: PropTypes.string,
    email: PropTypes.string,
    ...TypesRedux,
};

PROP.defaults = {
    blocked: false,
    exchangeToken: null,
    fetching: false,
    mobileNumber: null,
    verificationCode: null,
    username: null,
    email: null,
    ...DefaultsRedux,
};
