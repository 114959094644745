import React from "react";

import * as Router from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";

import {
    BEVSA,
    EXTERNAL_PAYMENTS,
    FORM_RENDER,
    ID_FORM,
    SCOPE,
    SCREENS_BEFORE,
    UNAVAILABLE_IN_MOBILE,
} from "~/constants";
import { MODE, GENERIC } from "~/constants/form";
import ContainerExternal from "~/containers/External/Payment/Steps";
import ContainerInternal from "~/containers/Internal/Form/Steps";
import { SelectorsStore as SelectorsStoreAutomaticDebit } from "~/store/automaticDebits";
import { SelectorsStore as SelectorsStoreBankSelector } from "~/store/bankSelector";
import { SelectorsStore as SelectorsStoreForm, SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionTemplate, SelectorsStore as SelectorsStoreTemplate } from "~/store/template";
import { SelectorsStore as SelectorsStoreTransfers } from "~/store/transfers";
import Device from "~/util/device";
import * as I18nUtils from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import { Resizable } from "~/components/HighOrder";

import Unavailable from "~/pages/error/Unavailable";
import FormWaiter from "~/pages/forms/_components/FormWaiter";

import FormEdit from "./Step1Edit/Step1Edit";
import FormPreview from "./Step2Preview/Step2Preview";
import FormView from "./Step3View/Step3View";

export const NAME = "Form";

export const PROP = {
    types: {
        isWaitingBiometry: PropTypes.bool,
        isWaitingData: PropTypes.bool,
        mode: PropTypes.string.isRequired,
        ...TypesRedux,
    },
    defaults: {
        isWaitingBiometry: false,
        isWaitingData: false,
        ...DefaultsRedux,
    },
};

export function Component(props) {
    const { dispatch, isMobile, isWaitingBiometry, isWaitingData, location = {}, mode } = props;

    const payExternal =
        location.pathname.toLowerCase().includes(EXTERNAL_PAYMENTS.SISTARBANC) ||
        location.pathname.toLowerCase().includes(EXTERNAL_PAYMENTS.BEVSA);

    const Container = payExternal ? ContainerExternal : ContainerInternal;

    const isAutomaticDebit = location.pathname.includes(ID_FORM.CREATE_AUTOMATIC_DEBIT);
    const isMassivePayment =
        location.pathname.includes(ID_FORM.SALARY_PAYMENT) || location.pathname.includes(ID_FORM.SUPPLIERS_PAYMENT);
    const isTransfer = location.pathname.toLowerCase().includes(FORM_RENDER.TRANSFER);

    const unavailableResolution = shouldReturnUnavailable();

    React.useEffect(() => {
        return () => {
            dispatch(SelectorsActionForm.saveDataOff());
            dispatch(SelectorsActionTemplate.toggleTemplateBack());
        };
    }, [dispatch]);

    return (
        <React.Fragment>
            <Container
                name={NAME}
                wait={isWaitingData && !isWaitingBiometry}
                step={mode}
                head-onClose={!payExternal && handleClose}
                head-onBack={
                    (payExternal && mode === MODE.PREVIEW && handleBack) ||
                    (!payExternal && mode !== MODE.VIEW && mode !== MODE.EDIT && handleBack) ||
                    (isTransfer && handleBack) ||
                    (isMassivePayment && mode === MODE.VIEW && handleBack) ||
                    (isAutomaticDebit && mode === MODE.VIEW && handleBack) ||
                    undefined
                }
                head-onBackWeb={mode === MODE.EDIT && handleBack}
                head-title={getTitle()}
                scopeToShowNotification={SCOPE.FORM}>
                {isWaitingBiometry && <FormWaiter />}
                {!isWaitingBiometry && !unavailableResolution && (
                    <React.Fragment>
                        {mode === MODE.EDIT && <FormEdit />}
                        {mode === MODE.PREVIEW && <FormPreview />}
                        {mode === MODE.VIEW && <FormView ticketConfirmation />}
                    </React.Fragment>
                )}
                {unavailableResolution && <Unavailable label="global.feature.resolution.unavailable" />}
            </Container>
        </React.Fragment>
    );

    function handleBack() {
        const { history } = props;

        if (Device.isMobileNative()) {
            window.history.back();
        }

        if (mode === MODE.EDIT) {
            history.goBack();
        } else if (mode === MODE.VIEW && (isTransfer || isMassivePayment || isAutomaticDebit)) {
            dispatch(SelectorsActionForm.cleanSteps({ payload: { location } }));
        } else {
            dispatch(SelectorsActionForm.closeConfirmation());
        }
    }

    function handleClose() {
        const { comeFrom, id } = props;

        if (!id.match(FORM_RENDER.TRANSFER)) {
            let uri = null;

            if (comeFrom === FORM_RENDER.WIDGET) {
                uri = `/${FORM_RENDER.DESKTOP}`;
            } else {
                uri = location.pathname.match(`/${FORM_RENDER.TRANSACTION}/`)
                    ? `/${FORM_RENDER.TRANSACTIONS}/list`
                    : `/${FORM_RENDER.DESKTOP}`;
            }

            return dispatch(Router.replace({ pathname: uri }));
        }

        if (location.search.match(FORM_RENDER.DEBIT_ACCOUNT)) {
            return dispatch(Router.go(SCREENS_BEFORE.TWO_LESS));
        }

        return dispatch(Router.goBack());
    }

    function getTitle() {
        const { transaction, currentLang, id, values = {} } = props;
        const { formNameMap } = props.metadata;

        let title = formNameMap[currentLang];

        if (id === ID_FORM.TRANSFER_LOCAL) {
            const { checkboxExpress } = values;

            if (checkboxExpress && checkboxExpress.length > 1) {
                title = I18nUtils.get("transfer.express.labelExpress");
            } else {
                title = I18nUtils.get("forms.transferLocal.formName");
            }
        } else if (id === ID_FORM.PAY_BEVSA) {
            const { data } = props;
            const { bevsaCommerceCode } = data;

            title = "forms.payBevsa.confirmation.titleDgi";

            if (bevsaCommerceCode === BEVSA.BPS) {
                title = "forms.payBevsa.confirmation.titleBps";
            }
        } else if (id === GENERIC) {
            const { activityName } = transaction;

            title = activityName;
        }

        return title;
    }

    function shouldReturnUnavailable() {
        return UNAVAILABLE_IN_MOBILE.find((pathname) => location.pathname.includes(pathname)) && isMobile;
    }
}

Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

const mapStateToProps = (store) => {
    const { needsBiometric } = SelectorsStoreSession.getUser(store) || {};

    const isWaitingBiometry = needsBiometric && SelectorsStoreForm.getWaitingForResponse(store);
    const isWaitingData =
        SelectorsStoreForm.getFetching(store) ||
        SelectorsStoreForm.getSubmit(store) ||
        SelectorsStoreAutomaticDebit.getFetching(store) ||
        SelectorsStoreBankSelector.getSearchBank(store) ||
        SelectorsStoreTemplate.isFetching(store) ||
        SelectorsStoreTransfers.fetching(store);

    return {
        currentLang: SelectorsStoreI18n.getLang(store),
        data: SelectorsStoreForm.getData(store),
        id: SelectorsStoreForm.getId(store),
        isWaitingBiometry,
        isWaitingData,
        metadata: SelectorsStoreForm.getMetadata(store),
        mode: SelectorsStoreForm.getMode(store),
        transaction: SelectorsStoreForm.getTransaction(store),
    };
};

export default Compose(Resizable, Connect(mapStateToProps))(Component);
