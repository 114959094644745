import { FactoryAction } from "~/util/redux";

import { TYPE } from "./_consts";

export const SelectorsAction = {
    saveLastHref: (props) => FactoryAction(TYPE.SAVE_LAST_HREF, props),
    deleteLastHref: () => FactoryAction(TYPE.DELETE_LAST_HREF),
    resetComeFromLogin: () => FactoryAction(TYPE.RESET_COME_FROM_LOGIN),
    showSessionExpirationModal: (props) => FactoryAction(TYPE.SHOW_SESSION_EXPIRATION_MODAL, props),
};

export const SelectorsStore = {
    getStatus: (state) => state.status,
    isComeFromLogin: (state) => state.status.comeFromLogin,
};
