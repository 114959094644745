import React from "react";

import * as StoreRouter from "connected-react-router";
import { push as Push } from "connected-react-router";
import { withFormik as WithFormik, Formik, Field, Form } from "formik";
import PropTypes from "prop-types";
import { Col, Grid, Row } from "react-bootstrap";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import {
    BEVSA,
    CREDENTIAL_TYPE_OTP,
    EMPTY_STR,
    FORM_PATH,
    ID_FORM,
    SPACE_STR,
    TRANSACTION_MUST_BE_AUTHORIZED_BY_APP,
    TRANSFER_ONLINE_PROCESSING,
} from "~/constants";
import { GENERIC, MSG_KEY } from "~/constants/form";
import { STATUS, STATUS_CAN_DOWNLOAD_PDF, STATUS_FINAL } from "~/constants/transaction";
import { SelectorsAction as SelectorsActionExternalPayments } from "~/store/externalPayments";
import { SelectorsStore as SelectorsStoreForm, SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsStore as StoreSession } from "~/store/session";
import { SelectorsStore as SelectorsStoreTemplate, SelectorsAction as SelectorsActionTemplate } from "~/store/template";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import { flattenArray as FlattenArray, removeDuplicateItems as RemoveDuplicateItems } from "~/util/array";
import { getArray as GetArray, getTimeInMillis as GetTimeInMillis } from "~/util/config";
import { specificDate as SpecificDate } from "~/util/date";
import DeviceUtils from "~/util/device";
import { transactionWithoutDetail as TransactionWithoutDetail } from "~/util/form";
import { generateId as GenerateId } from "~/util/general";
import * as I18nUtil from "~/util/i18n";
import { fixPrecision, getAsNumber } from "~/util/number";
import { requestReview as RequestReview } from "~/util/requestReview";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";
import ConfirmationModal from "~/pages/_components/ConfirmationModal";
import FormattedDate from "~/pages/_components/FormattedDate";
import TicketPrintInformation from "~/pages/_components/TicketPrintInformation";

import TransactionStatus from "~/pages/transactions/_components/TransactionStatus";

import Fields from "../_components/Fields";
import FormSignatures from "../_components/FormSignatures";
import FieldHandler from "../fieldHandler";
import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        childrenTransactions: PropTypes.array,
        idUser: PropTypes.string,
        isFromBackoffice: PropTypes.bool,
        metadata: PropTypes.object,
        parentTransaction: PropTypes.object,
        showModal: PropTypes.bool,
        ticketConfirmation: PropTypes.bool, // TODO: revisar
        transaction: PropTypes.shape({
            executing: PropTypes.bool,
            idTransaction: PropTypes.string,
            idTransactionStatus: PropTypes.string,
            transactionData: PropTypes.object,
            valueDateAsString: PropTypes.string,
        }),
        values: PropTypes.object,
    },
    defaults: {
        childrenTransactions: [],
        idUser: null,
        isFromBackoffice: false,
        metadata: {},
        parentTransaction: {},
        showModal: false,
        ticketConfirmation: false,
        transaction: {},
        values: {},
    },
};

export function Component(props) {
    const {
        childrenTransactions,
        isFromBackoffice,
        lang,
        location,
        metadata,
        parentTransaction,
        ticketConfirmation,
        transaction,
        values: propsValues,
    } = props;

    const [expressMessageStatus, setExpressMessageStatus] = React.useState(STATUS.PROCESSING);
    const [expressAlreadyRendered, setExpressAlreadyRendered] = React.useState(false);

    const {
        query: { urlVuelta, idTransaccion: idTransactionUrl, fecha, urlCONTROL },
    } = location;
    const {
        executing,
        idTransaction,
        idTransactionStatus,
        isExpressTransfer,
        returnCode,
        valueDateAsString,
        transactionData,
    } = transaction;
    const { idActivity, idForm, editableInMobile } = metadata;

    const isMobile = DeviceUtils.isDisplayMobile();
    const isFinal = STATUS_FINAL.includes(idTransactionStatus);
    const statusCanDownloadPDF = STATUS_CAN_DOWNLOAD_PDF.includes(idTransactionStatus);
    const pdfDownloadEnabled = GetArray("client.activities.PDF.download.in.web").includes(idActivity);

    const [hideDetails, setHideDetails] = React.useState(isMobile && location.pathname.includes(FORM_PATH));

    const hasActivity = idActivity && idActivity.length !== 0;

    const isExternal = idForm === ID_FORM.PAY_SISTARBANC || idForm === ID_FORM.PAY_BEVSA;
    const pending = idTransactionStatus !== STATUS.FINISHED && idTransactionStatus !== STATUS.FAILED;
    // Si  viene la urlVuelta en los parametros
    const externalPaymentAndRedirect = isExternal && !pending && (urlVuelta || urlCONTROL);

    const isEditable = !isMobile || editableInMobile;

    const handleExpressTransferMessage = React.useCallback(() => {
        if (isExpressTransfer) {
            setTimeout(() => {
                setExpressMessageStatus(STATUS.FINISHED);
            }, 5000);
        }
    }, [isExpressTransfer]);

    React.useEffect(() => {
        handleExpressTransferMessage();
    }, [handleExpressTransferMessage]);

    let textExternal;
    let timeout = 0;

    if (externalPaymentAndRedirect) {
        if (idForm === ID_FORM.PAY_SISTARBANC) {
            textExternal = "client.sistarbanc.paySistarbanc.paysuccess.confirmationText2";
            timeout = GetTimeInMillis("client.sistarbanc.redirectControlTime", 2000) / 1000; // in seconds
        } else {
            const { bevsaCommerceCode } = transaction.data;
            const formattedBevsaCommerceCode = getAsNumber(bevsaCommerceCode, lang).toString();

            if (formattedBevsaCommerceCode === BEVSA.BPS) {
                textExternal = "client.bevsa.paysuccess.confirmationTextBps";
            }
            if (formattedBevsaCommerceCode === BEVSA.DGI) {
                textExternal = "client.bevsa.paysuccess.confirmationTextDgi";
            }
            timeout = GetTimeInMillis("client.bevsa.redirectControlTime", 2000) / 1000; // in seconds
        }
    }
    const [secondsToRedirect, setSeconds] = React.useState(timeout);
    const [redirect, setRedirect] = React.useState(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const timer = () => setSeconds(secondsToRedirect - 1);

    // TODO: Mejorar la logica. Esto aplica para solucionar el problema de la vista de las acciones para los ticket
    let shouldDisplayTicketCollapsible = false;

    if (DeviceUtils.isDisplayMobile()) {
        // en vista mobile se muestra segun acciones de los tickets, en desktop siempre oculto
        shouldDisplayTicketCollapsible = location.pathname.includes(FORM_PATH)
            ? ticketConfirmation
            : idTransactionStatus !== STATUS.PENDING && idTransactionStatus !== STATUS.SCHEDULED;
    }

    React.useEffect(() => {
        if (externalPaymentAndRedirect) {
            if (secondsToRedirect <= 0) {
                if (redirect) {
                    const { dispatch } = props;

                    setRedirect(false);

                    if (idForm === ID_FORM.PAY_SISTARBANC) {
                        dispatch(
                            SelectorsActionExternalPayments.backUrlSistarBanc({ idTransactionUrl, fecha, urlVuelta }),
                        );
                    } else {
                        window.location.href = decodeURIComponent(urlCONTROL);
                        dispatch(SelectorsActionExternalPayments.logOutBevsa());
                    }
                }

                return undefined;
            }

            const id = setInterval(timer, 1000);

            return () => clearInterval(id);
        }
        return undefined;
    }, [
        externalPaymentAndRedirect,
        fecha,
        idForm,
        idTransaction,
        idTransactionUrl,
        isExternal,
        props,
        redirect,
        secondsToRedirect,
        timer,
        transaction.data,
        urlCONTROL,
        urlVuelta,
    ]);

    const shouldRenderConfirmation =
        !isFromBackoffice &&
        (idTransactionStatus === STATUS.SCHEDULED ||
            (!ticketConfirmation && idTransactionStatus === STATUS.PENDING && !executing));

    if (idForm === ID_FORM.PAY_CREDIT_CARD && propsValues.amountUYU === EMPTY_STR) {
        delete propsValues.debitAccountUYU;
    }

    RequestReview(DeviceUtils.isMobileNative(), idActivity, idTransactionStatus, location.pathname.includes(FORM_PATH));

    return (
        <div id={Style.ID}>
            <section className="container--layout align-items-center flex-grows">
                <Grid className="form-content">
                    <Row className="justify-content-center">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <TicketPrintInformation />
                            <div className="media-object ">
                                <figure>
                                    <TransactionStatus
                                        idTransactionStatus={idTransactionStatus}
                                        isExpressTransfer={isExpressTransfer}
                                        expressAlreadyRendered={expressAlreadyRendered}
                                    />
                                </figure>
                                <div className="media-object--text">
                                    <p className="text-lead">
                                        {idTransactionStatus === STATUS.PENDING && executing ? (
                                            <I18n id="transactionDelay.notification" />
                                        ) : (
                                            renderTransaction()
                                        )}
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </section>
            {!TransactionWithoutDetail(idForm) && !externalPaymentAndRedirect && (
                <section
                    className={`container--layout align-items-center ${
                        !shouldDisplayTicketCollapsible || (!hideDetails && "login-background-wrapper")
                    }`}>
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12 data-section">
                                <div className="section-title">
                                    <h3 className="data-title">
                                        <I18n id="forms.transaction.ticket" />
                                    </h3>

                                    {DeviceUtils.isMobileNative() && (
                                        <a className="share-button" onClick={() => handleShareClick(idTransaction)}>
                                            <Image src="share.svg" className="svg-icon" />
                                        </a>
                                    )}
                                </div>
                                <div className="data-wrapper">
                                    <span className="data-label">
                                        <I18n id="forms.transaction.ticket.idTransaction" />
                                    </span>
                                    <span className="data-name">{idTransaction}</span>
                                </div>
                                <div className="data-wrapper">
                                    <span className="data-label">
                                        <I18n id="forms.transaction.ticket.date" />
                                    </span>
                                    <span className="data-date">{valueDateAsString}</span>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <div xl="6" className="col col-12 col-lg-6 col-md-9 col-sm-12">
                                <Grid className="form-content">
                                    <Row className="justify-content-center">
                                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12 data-section">
                                            <Fields values={propsValues} isFromBackoffice={isFromBackoffice} />
                                        </Col>
                                    </Row>
                                </Grid>
                            </div>
                        </Row>

                        {idForm === GENERIC && (
                            <Row className="justify-content-center">
                                <Col sm={12} md={9} lg={6} xl={6} className="col col-12 data-section">
                                    {Object.keys(transactionData.json).map((key) => (
                                        <div key={key}>
                                            {/* TODO: Revisar */}
                                            {idActivity === "rub.payments.payCustoms" ? (
                                                <FieldCustoms name={key} data={transactionData.json[key]} />
                                            ) : (
                                                renderField(key, transactionData.json[key])
                                            )}
                                        </div>
                                    ))}
                                </Col>
                            </Row>
                        )}

                        <Row className="justify-content-center">
                            <FormSignatures transaction={transaction} />
                        </Row>

                        {parentTransaction && (
                            <Row>
                                <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                    <Link to={`/transaction/${parentTransaction.idTransaction}`}>
                                        <I18n
                                            id="forms.transaction.ticket.parent"
                                            idTransaction={parentTransaction.idTransaction}
                                            date={SpecificDate(parentTransaction.creationDateTime)}
                                        />
                                    </Link>
                                </Col>
                            </Row>
                        )}

                        {childrenTransactions && childrenTransactions.length > 0 && (
                            <Row>
                                <Col sm={12} md={9} lg={6} xl={6} className="col col-12 data-section">
                                    <div className="section-title">
                                        <h3 className="data-title">
                                            <I18n id="forms.transaction.ticket.children.title" />
                                        </h3>
                                    </div>

                                    <div className="table">
                                        <div className="table-body">
                                            {childrenTransactions.map((child) => (
                                                <div className="table-row" key={GenerateId()}>
                                                    <div className="table-data">
                                                        <div className="data-wrapper">
                                                            <span className="data-date">
                                                                <FormattedDate date={child.valueDateTime} />
                                                            </span>
                                                            <Link to={`/transaction/${child.idTransaction}`}>
                                                                <span className="data-numeric">
                                                                    {child.idTransaction}
                                                                </span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="table-data text-right">
                                                        <span className="data-aux">
                                                            <I18n
                                                                id={`transaction.status.${child.idTransactionStatus}`}
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        )}

                        {!DeviceUtils.isMobileNative() && !pdfDownloadEnabled && isFinal && (
                            <Row>
                                <Grid className="form-content">
                                    <Row className="justify-content-center">
                                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                            <div className="buttonsTicketConfirmation">
                                                <a onClick={() => printPage()}>
                                                    <I18n id="forms.ticket.print" />
                                                </a>
                                            </div>
                                        </Col>
                                    </Row>
                                </Grid>
                            </Row>
                        )}
                        {!DeviceUtils.isMobileNative() && pdfDownloadEnabled && statusCanDownloadPDF && (
                            <Row>
                                <Grid className="form-content">
                                    <Row className="justify-content-center">
                                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                            <div className="buttonsTicketConfirmation">
                                                <a onClick={() => handleShareClick(idTransaction)}>
                                                    <I18n id="forms.ticket.printPDF" />
                                                </a>
                                            </div>
                                        </Col>
                                    </Row>
                                </Grid>
                            </Row>
                        )}
                        {shouldRenderConfirmation && (
                            <Row>
                                <Grid className="form-content">
                                    <Row className="justify-content-center">
                                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                            <Confirmation />
                                        </Col>
                                    </Row>
                                </Grid>
                            </Row>
                        )}
                    </Grid>
                </section>
            )}
            {TransactionWithoutDetail(idForm) && renderConfirmationWithoutTicket()}
            {hasActivity && !externalPaymentAndRedirect && shouldDisplayTicketCollapsible && (
                <React.Fragment>
                    <section className="container--layout align-items-center">
                        <Grid className="form-content">
                            <Row className="justify-content-center">
                                <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                    {!TransactionWithoutDetail(idForm) && (
                                        <Button
                                            className="btn-secondary"
                                            label={hideDetails ? MSG_KEY.MORE : MSG_KEY.LESS}
                                            onClick={handleDetailClick}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Grid>
                    </section>
                </React.Fragment>
            )}

            {ticketConfirmation && isExternal && !externalPaymentAndRedirect && (
                <React.Fragment>
                    <section className="container--layout align-items-center">
                        <Grid className="form-content">
                            <Row className="justify-content-center">
                                <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                    <Button
                                        label="forms.returnDesktop.link"
                                        bsStyle="primary"
                                        onClick={handleDesktop}
                                    />
                                </Col>
                            </Row>
                        </Grid>
                    </section>
                </React.Fragment>
            )}

            {externalPaymentAndRedirect && (
                <React.Fragment>
                    <section className="container--layout align-items-center">
                        <Grid className="form-content">
                            <Row className="justify-content-center">
                                <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                    <div className="media-object--text">
                                        <p className="text-lead">
                                            <I18n id="client.sistarbanc.paySistarbanc.paysuccess.confirmationText" />
                                            {SPACE_STR}
                                            <b>{secondsToRedirect}</b>
                                            {SPACE_STR}
                                            <I18n id={textExternal} />
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Grid>
                    </section>
                </React.Fragment>
            )}
        </div>
    );

    function handleDetailClick() {
        setHideDetails(!hideDetails);
    }

    function printPage() {
        if (!expressAlreadyRendered) {
            setExpressAlreadyRendered(true);
        }

        window.print();
    }

    function handleShareClick(idTransactionToRead) {
        const { dispatch } = props;

        if (!expressAlreadyRendered) {
            setExpressAlreadyRendered(true);
        }

        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    }

    function handleDesktop() {
        const { dispatch } = props;

        dispatch(Push("/"));
    }

    function FieldCustoms(propsField) {
        const { data } = propsField;
        const name = propsField.name.toLowerCase();
        const value = data[name];

        if (name === "amount") {
            // TODO: Revisar
            return <Value className="data-amount">{transactionData.currency + value}</Value>;
        }

        if (name === "valuedate") {
            return (
                <Value label="date" className="data-date">
                    <FormattedDate date={value} />
                </Value>
            );
        }

        if (name === "idtransactionlist") {
            return (
                <Value label="totalPayments" className="data-text">
                    {value.length}
                </Value>
            );
        }

        let title;

        switch (name) {
            case "successfulpaymentlist":
                title = "successfulPayment";

                break;
            case "failedpaymentlist":
                title = "failedPayment";

                break;
            case "unconfirmedpaymentlist":
                title = "unconfirmedPayment";

                break;
            case "paymentstoprocess":
                title = "paymentsToProcess";

                break;
            default:
                return null;
        }

        if (!Array.isArray(value) || !value.length) {
            return null;
        }

        return (
            <Value title={`${title}.title`} className="data-text">
                <table className="table">
                    <tr>
                        <th>
                            <I18n id="transaction.ticket.idTransactionPayCustoms" />
                        </th>
                        <th>
                            <I18n id="transaction.ticket.descriptionPayCustoms" />
                        </th>
                        <th>
                            <I18n id="transaction.ticket.expirationDatePayCustoms" />
                        </th>
                        <th>
                            <I18n id="transaction.ticket.currencyPayCustomersPayCustoms" />
                        </th>
                        <th>
                            <I18n id="transaction.ticket.amountPayCustomersPayCustoms" />
                        </th>
                    </tr>
                    {value.map((row) => (
                        <tr>
                            <td className="data-text">{row.merchantPaymentId}</td>
                            <td className="data-text">{row.description}</td>
                            <td className="data-date">
                                <FormattedDate date={row.expiration} />
                            </td>
                            <td className="data-text">{row.currencyCode}</td>
                            <td className="data-text">{row.totalAmount}</td>
                        </tr>
                    ))}
                </table>
            </Value>
        );
    }

    function renderConfirmationWithoutTicket() {
        return (
            <section className="container--layout align-items-center">
                <Grid className="form-content">
                    <Row className="justify-content-center">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <p className="text-lead">
                                <strong>
                                    <I18n id="forms.transactionsWithoutTicket.step3.disclaimer" />
                                </strong>
                            </p>
                        </Col>
                    </Row>
                </Grid>
            </section>
        );
    }

    function renderTransaction() {
        if (externalPaymentAndRedirect) {
            return <I18n id="client.sistarbanc.paySistarbanc.paysuccess.confirmationTitle" />;
        }

        if (isExpressTransfer) {
            return (
                <I18n
                    AMOUNT={fixPrecision(getAsNumber(transaction.data.amount.quantity, lang))}
                    BANK={transaction.data.bank_name}
                    CURRENCY={I18nUtil.get(`currency.label.${transaction.data.amount.currency}`)}
                    id={`transaction.status.transferExpress.${expressMessageStatus}`}
                />
            );
        }

        if (idTransactionStatus === STATUS.PROCESSING && returnCode === TRANSFER_ONLINE_PROCESSING) {
            return <I18n id={`forms.transaction.ticket.status.${idTransactionStatus}.online`} />;
        }

        if (idTransactionStatus === STATUS.PENDING && returnCode === TRANSACTION_MUST_BE_AUTHORIZED_BY_APP) {
            return <I18n id="forms.transaction.ticket.status.MUST_BE_AUTHORIZED_BY_APP" />;
        }

        return <I18n id={`forms.transaction.ticket.status.${idTransactionStatus}`} />;
    }

    // TODO: Revisar con Sabri
    function renderField(key, value) {
        const keyLower = key.toLowerCase();
        const keyPrefix = "transaction.ticket.";
        const valueReturn = value;

        let titleKey = null;
        let labelKey = null;
        let className;

        labelKey = key;
        className = "data-text";

        switch (keyLower) {
            case "valuedate":
                labelKey = "date";
                className = "data-date";

                break;
            case "totalpayments":
            case "debitreference":
            case "notificacion":
            case "notification":
            case "totalsuppliers":
            case "userlabel":
            case "userslabel":
            case "idusertomodify":
            case "firstname":
            case "lastname":
            case "signaturelevel":
            case "grouplabel":
            case "formattedchannels":
            case "status":
            case "description":
            case "email":
            case "groups":
            case "companyname":
            case "agreementname":
            case "signaturegroup":
            case "signaturetype":
            case "amountscheme":
            case "amountschemesignature":
            case "noamountscheme":
            case "noamountschemesignature":
                className = "data-text";

                break;
            case "userstatus":
                labelKey = "status";
                className = "data-text";

                break;
            default:
                if (keyLower.includes("amount")) {
                    labelKey = "amount";
                    className = "data-amount";
                } else if (keyLower.includes("name")) {
                    labelKey = "name";
                    className = "data-text";
                } else if (keyLower.includes("permissions")) {
                    labelKey = "permissions";
                    className = "data-text";
                } else if (keyLower.includes("debitaccount")) {
                    titleKey = "debitAccount.title";
                    labelKey = "debitAccount";
                    className = "data-name";
                } else if (keyLower.includes("creditaccount")) {
                    titleKey = "creditAccount.title";
                    labelKey = "creditAccount";
                    className = "data-name";
                } else if (keyLower.includes("reference")) {
                    labelKey = "reference";
                    className = "data-text";
                } else if (keyLower.includes("date")) {
                    labelKey = "date";
                    className = "data-date";
                } else {
                    labelKey = null;
                    className = "data-text";
                }

                break;
        }

        if (valueReturn !== null) {
            return (
                <span className="data-wrapper" key={key}>
                    {titleKey !== null && (
                        <div className="section-title">
                            <h3 className="data-title">
                                <I18n id={`${keyPrefix}${titleKey}`} />
                            </h3>
                        </div>
                    )}
                    <span className="data-label">
                        {labelKey !== null ? <I18n id={`${keyPrefix}${labelKey}`} /> : key}
                    </span>
                    <span className={className} dangerouslySetInnerHTML={{ __html: valueReturn }} />
                </span>
            );
        }

        return null;
    }

    function Value(propsValue) {
        const { title, label, ...restValue } = propsValue;
        const prefix = "transaction.ticket.";

        return (
            <span className="data-wrapper">
                {title && (
                    <div className="section-title">
                        <h3 className="data-title">
                            <I18n id={`${prefix}${title}`} />
                        </h3>
                    </div>
                )}
                <span className="data-label">{label && <I18n id={`${prefix}${label}`} />}</span>
                <span {...restValue} />
            </span>
        );
    }

    function Confirmation() {
        const { dispatch, credentials, transactionCredential, showModal } = props;
        const initialValues = {
            credentials: credentials.reduce((values, credential) => ({ ...values, [credential]: EMPTY_STR }), {}),
        };

        return (
            <div className="above-the-fold">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    render={(formikBag) => (
                        <Form>
                            {showModal && (
                                <ConfirmationModal
                                    show
                                    handleAcept={() => handleReject(formikBag)}
                                    handleCancel={() => dispatch(SelectorsActionForm.toggleModal())}
                                    idLabel="transaction.ticket.cancelConfirmation"
                                />
                            )}
                            <section>
                                {transactionCredential === CREDENTIAL_TYPE_OTP &&
                                    credentials.map((credential) => (
                                        <div key={credential}>
                                            <Field
                                                idForm="form.credential"
                                                name={credential}
                                                component={Credential}
                                                type={credential}
                                            />
                                        </div>
                                    ))}
                            </section>
                            <section>
                                <Grid>
                                    <Row>
                                        <div className="transactionTicketConfirmation">
                                            <div className="buttonsTicketConfirmation">
                                                {idTransactionStatus === STATUS.PENDING && (
                                                    <Button
                                                        label="forms.signTransaction.link"
                                                        bsStyle="primary"
                                                        loading={formikBag.isSubmitting}
                                                        onClick={handleSign}
                                                    />
                                                )}
                                            </div>

                                            <div className="buttonsTicketConfirmation">
                                                <Button
                                                    label="forms.cancelTransaction.link"
                                                    bsStyle="primary"
                                                    loading={formikBag.isSubmitting}
                                                    onClick={() => dispatch(SelectorsActionForm.toggleModal())}
                                                />
                                            </div>
                                            {idForm !== GENERIC && !isExternal && isEditable && (
                                                <div className="buttonsTicketConfirmation">
                                                    <Button
                                                        label="forms.modifyTransaction.link"
                                                        bsStyle="outline"
                                                        loading={formikBag.isSubmitting}
                                                        onClick={handleModify}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </Row>
                                </Grid>
                            </section>
                        </Form>
                    )}
                />
            </div>
        );

        function handleReject(formikBag) {
            dispatch(
                SelectorsActionForm.cancelTransactionCustom({
                    credentials: { otp: EMPTY_STR },
                    idTransaction,
                    formikBag,
                }),
            );
        }

        function handleSign() {
            dispatch(SelectorsActionForm.signTransactionPreview({ idForm, idTransaction, idActivity }));
        }

        function handleModify() {
            dispatch(SelectorsActionTemplate.loadTemplateList({ idForm }));
            dispatch(SelectorsActionForm.modifyTransaction({ idTransactionToMove: idTransaction }));
        }

        function handleSubmit() {
            // TODO: This is not used. why?
        }

        function validationSchema() {
            const { user } = props;

            if (!user.needsBiometric) {
                return Yup.object().shape(
                    credentials.reduce(
                        (values, credential) => ({
                            ...values,
                            [credential]: Yup.string().required(I18nUtil.get(`form.credential.${credential}.required`)),
                        }),
                        {},
                    ),
                );
            }

            return Yup.object().shape(
                // En el caso se que sea SOFT credentials esta en null
                credentials.reduce(
                    (values, credential) => ({
                        ...values,
                        [credential]: Yup.string().nullable(),
                    }),
                    {},
                ),
            );
        }
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

function mapStateToProps(store) {
    return {
        childrenTransactions: SelectorsStoreForm.getChildrenTransactions(store),
        credentials: Compose(
            (array) => array.filter((item) => item !== "accessToken"),
            RemoveDuplicateItems,
            FlattenArray,
            (array) => array.map(({ credentials }) => credentials),
        )(SelectorsStoreForm.getCredentialsGroups(store)),
        formIsFetching: SelectorsStoreForm.getFetching(store),
        idUser: StoreSession.getUserId(store),
        isCancellingTransaction: SelectorsStoreForm.getIsCancellingTransaction(store),
        isVisible: SelectorsStoreTemplate.isCreateModalVisible(store),
        lang: StoreI18n.getLang(store) || window.navigator.language.slice(0, 2),
        location: StoreRouter.getLocation(store),
        metadata: SelectorsStoreForm.getMetadata(store),
        parentTransaction: SelectorsStoreForm.getParentTransaction(store),
        showModal: SelectorsStoreForm.showModal(store),
        transaction: SelectorsStoreForm.getTransaction(store),
        transactionCredential: SelectorsStoreForm.getTransactionCredential(store),
        values: SelectorsStoreForm.getValues(store),
    };
}

export default HighOrder(Connect(mapStateToProps), WithFormik(FieldHandler))(Component);
