import { routerActions } from "connected-react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { SelectorsActionSignaturesAdvanced } from "~/store/administration/advanced";
import { SelectorsStore as SelectorsStoreSignatures } from "~/store/administration/common/signatures";
import { SelectorsStore as i18nSelectors } from "~/store/i18n";
import { SelectorsStore as StoreSession } from "~/store/session";
import { getInteger } from "~/util/config";


import SignaturesSchemeCreate from "~/pages/administration/_components/SignaturesSchemeCreate";

const mapStateToProps = (state) => ({
    activeEnvironment: StoreSession.getActiveEnvironment(state),
    capFrequencyList: SelectorsStoreSignatures.capFrequencyList(state),
    caps: SelectorsStoreSignatures.caps(state),
    fetching: SelectorsStoreSignatures.fetching(state),
    functionalGroups: SelectorsStoreSignatures.functionalGroups(state),
    environmentProducts: SelectorsStoreSignatures.environmentProducts(state),
    masterCurrency: SelectorsStoreSignatures.masterCurrency(state),
    signature: SelectorsStoreSignatures.currentSignature(state),
    signatureGroupMap: SelectorsStoreSignatures.currentSignatureGroupMap(state),
    signatureTypeList: SelectorsStoreSignatures.signatureTypeList(state),
    credentialGroups: SelectorsStoreSignatures.getCredentialGroups(state),
    idTransaction: SelectorsStoreSignatures.getIdTransaction(state),
    idActivity: SelectorsStoreSignatures.getIdActivity(state),
    selectedProducts: SelectorsStoreSignatures.selectedProducts(state),
    signatureLevelsCounts: SelectorsStoreSignatures.signatureLevelsCounts(state),
    signatureType: SelectorsStoreSignatures.signatureType(state),
    topAmount: SelectorsStoreSignatures.topAmount(state),
    signatureDispatch: SelectorsStoreSignatures.signatureDispatch(state),
    isModify: true,
    lang: i18nSelectors.getLang(state),
    maximumDecimals: getInteger("defaultDecimal.maximum"),
    minimumDecimals: getInteger("defaultDecimal.minimum"),
    isChangingEnvironment: StoreSession.isChangingEnvironment(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(SelectorsActionSignaturesAdvanced, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignaturesSchemeCreate);
