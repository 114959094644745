import React from "react";

import PropTypes from "prop-types";

import Button from "~/components/Button";
import I18n from "~/components/I18n";
import Link from "~/components/Link";

export const NAME = "LoanAction";

export const PROP = {
    types: {
        handleClick: PropTypes.func,
        className: PropTypes.string,
        labelKey: PropTypes.string,
        to: PropTypes.string,
    },
    defaults: {
        handleClick: null,
        to: null,
        className: null,
        labelKey: null,
    },
};

export function Component(props) {
    const { className, labelKey, to, handleClick } = props;
    function onClick() {
        if (handleClick) {
            handleClick();
        }
    }
    return (
        (to && (
            <Link className={className || "btn btn-quiet"} to={to}>
                <I18n id={labelKey} />
            </Link>
        )) || <Button className={className} label={labelKey} onClick={onClick} />
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
