import React from "react";

import PropTypes from "prop-types";

export const NAME = "ItemName";

export const PROP = {
    types: { name: PropTypes.string.isRequired },
    defaults: {},
};

export function Component(props) {
    const { name } = props;
    return <h4 className="data-name product-title">{name}</h4>;
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
