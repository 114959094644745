import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, NO_DATA, TYPE_DATA_ACCOUNTS } from "~/constants";
import { SelectorsStore as SelectorsStoreSettings } from "~/store/settings";
import * as UtilsI18n from "~/util/i18n";

import I18n from "~/components/I18n";
import FieldReadOnly from "~/pages/_components/fields/FieldReadOnly";

import Style from "./ChangeDataAccountsContent.rules.scss";

export const NAME = "ChangeDataAccountsContent";

export const PROP = {
    types: {
        transaction: PropTypes.object,
    },
    defaults: {
        transaction: {},
    },
};

export function Component({ transaction }) {
    let infoUserModify;

    if (transaction) {
        infoUserModify =  transaction.data?.infoUserModify;
    }

    let infoLegal = {};
    let infoMailings = {};
    let isMailings = EMPTY_STR;

    if (infoUserModify) {
        const {
            legalAddressApartment,
            legalAddressCityName,
            legalAddressCountry,
            legalAddressNumber,
            legalAddressObservations,
            legalAddressPostalCode,
            legalAddressStreet,
            legalAddressTownName,
            mailings,
            mailingsAddressApartment,
            mailingsAddressCityName,
            mailingsAddressCountry,
            mailingsAddressNumber,
            mailingsAddressObservations,
            mailingsAddressPostalCode,
            mailingsAddressStreet,
            mailingsAddressTownName,
        } = infoUserModify;

        infoLegal = {
            apto: legalAddressApartment,
            city: legalAddressTownName,
            country: UtilsI18n.get(`country.name.${legalAddressCountry}`),
            departament: legalAddressCityName,
            doorNumber: legalAddressNumber,
            observations: legalAddressObservations,
            street: legalAddressStreet,
            zipCode: legalAddressPostalCode,
        };

        infoMailings = {
            apto: mailingsAddressApartment,
            city: mailingsAddressTownName,
            country: UtilsI18n.get(`country.name.${mailingsAddressCountry}`),
            departament: mailingsAddressCityName,
            doorNumber: mailingsAddressNumber,
            observations: mailingsAddressObservations,
            street: mailingsAddressStreet,
            zipCode: mailingsAddressPostalCode,
        };

        isMailings = mailings ? "change.PersonalData.mailings.yes" : "change.PersonalData.mailings.no";
    }

    return (
        <React.Fragment>
            <div id={Style.ID}>
                <div>
                    <div>
                        <div className="form-section-title">
                            <I18n id={`change.PersonalData.ticket.title.${TYPE_DATA_ACCOUNTS.LEGAL}`} />
                        </div>
                        <div className="container--data--addresses">
                            {Object.keys(infoLegal).map((key) => (
                                <FieldReadOnly
                                    value={infoLegal[key] || NO_DATA}
                                    label={`change.PersonalData.${key}.label`}
                                    className=" data-wrapper"
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className="form-section-title">
                            <I18n id={`change.PersonalData.ticket.title.${TYPE_DATA_ACCOUNTS.MAILINGS}`} />{" "}
                        </div>
                    </div>
                    <div className="container--data--addresses">
                        {Object.keys(infoMailings).map((key) => (
                            <FieldReadOnly
                                value={infoMailings[key] || NO_DATA}
                                label={`change.PersonalData.${key}.label`}
                                className=" data-wrapper"
                            />
                        ))}
                    </div>
                    <div>
                        <div className="data-wrapper mailings">
                            <div className="data-label">
                                <I18n id={`change.mailings.title.${TYPE_DATA_ACCOUNTS.MAILINGS}`} />
                            </div>

                            <div className="mailings-type">
                                <I18n id={isMailings} />
                            </div>
                        </div>
                        <div className="terms">
                            <div className="terms-block">
                                <div className="contract-block-content">
                                    <I18n id="client.envconfiguration.index.mailings.message" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreSettings.getChangeTransactionData(store),
    };
};

export default Connect(mapStateToProps)(Component);
