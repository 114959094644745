import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import {
    SelectorsAction as SelectosActionTransactionLines,
    SelectorsStore as SelectorsStoreTransactionLines,
} from "~/store/transactionLines";
import * as UtilsI18n from "~/util/i18n";

import EditableDataNumber from "~/components/EditableDataNumber";
import EditableText from "~/components/EditableText";

import Style from "./SuppliersPaymentRow.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        values: PropTypes.shape({
            creditAccountName: PropTypes.string,
            type: PropTypes.string,
            credit: PropTypes.shape({
                amount: PropTypes.string.isRequired,
                currency: PropTypes.string.isRequired,
            }),
            reference: PropTypes.string,
        }).isRequired,
        idForm: PropTypes.string.isRequired,
        localBanks: PropTypes.array,
        isReadOnly: PropTypes.bool.isRequired,
    },
    defaults: {
        values: {},
        idForm: EMPTY_STR,
        localBanks: [],
        isReadOnly: false,
    },
};

export function Component(props) {
    const { dispatch, idForm, id, lineNumber, values, isReadOnly } = props;

    const { creditAccountBankShortLabel, creditAccountName, creditAmountCurrency } = values;

    const [creditAmountQuantity, setCreditAmountQuantity] = React.useState(values.creditAmountQuantity);
    const [reference, setReference] = React.useState(values.reference);

    const handleChange = (value) => setCreditAmountQuantity(value || 0);

    const handleChangeReference = (event) => setReference(event.target.value);

    const textDefault = UtilsI18n.get(`client.notApply`);

    const handleBlur = () => {
        const paymentLine = { ...values, reference, creditAmountQuantity, lineNumber };
        const param = { paymentLine, idForm };
        dispatch(SelectosActionTransactionLines.editTransactionLine(param));
    };

    return (
        <React.Fragment>
            <div className="input-row">{creditAccountBankShortLabel}</div>
            <div className="input-row">{creditAccountName}</div>
            <div className="input-row">
                <EditableText
                    placeholder={textDefault}
                    value={reference}
                    onChange={handleChangeReference}
                    onBlur={handleBlur}
                    readOnly={isReadOnly}
                />
            </div>
            <div>{EMPTY_STR}</div>
            <div className="input-row pl-1">
                <EditableDataNumber
                    id={id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={creditAmountQuantity}
                    prefix={creditAmountCurrency}
                    readOnly={isReadOnly}
                />
            </div>
        </React.Fragment>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    localBanks: SelectorsStoreTransactionLines.getLocalBanks(store),
});

export default Connect(mapStateToProps)(React.memo(Component));
