import React from "react";

import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { EMPTY_STR, PRICE_CLASSIFICATION } from "~/constants";
import {
    SelectorsStore as SelectorsStoreWMShares,
    SelectorsAction as SelectorsActionWMShares,
} from "~/store/wm/shares";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import DataNumber from "~/components/DataNumber";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Token from "~/components/Token/Token";
import Notification from "~/pages/_components/Notification";

import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        suppliersDisplayList: PropTypes.array.isRequired,
        isSubmitting: PropTypes.bool,
    },
    defaults: {
        isSubmitting: false,
    },
};

export function Component(props) {
    const {
        isSubmitting,
        formData: {
            sellingType,
            sharesAmount,
            sharesQuantity,
            limitFrom,
            paperName,
            amount,
            price,
            creditAccountAlias,
            currency,
        },
    } = props;
    const sharePrice = price === PRICE_CLASSIFICATION.LIMIT ? limitFrom : sharesAmount;
    const replace = { QUANTITY: sharesQuantity };

    return (
        <Form id={Style.ID}>
            <Notification scopeToShow="formConfirmation" />

            <Box flex directionColumn align="center" className="mb-3">
                <I18n id="forms.default.confirmation.title" tag="p" />
                <div className="paper-name">
                    <I18n id="client.wm.shares.sell.step2.paperName" tag="p" className="mt-3" />
                    <I18n id={`client.wm.shares.sell.step2.sellingType.${sellingType}`} tag="p" className="mt-3" />
                    <I18n id="client.wm.shares.sell.step2.sharesQuantity" tag="p" className="mt-3" {...replace} />
                </div>
                <h3 className="bold">{paperName}</h3>

                <I18n id="client.wm.shares.sell.step2.amount" tag="p" className="mt-3" />
                <h3>
                    <DataNumber value={amount} prefix={currency} />
                </h3>
                <I18n id={`client.wm.shares.sell.step2.priceType.${price}`} tag="h3" />
                <h3>
                    <DataNumber value={sharePrice} prefix={currency} />
                </h3>

                <I18n id="client.wm.shares.sell.step2.creditAccount" tag="p" className="mt-3" />
                <h3>{creditAccountAlias}</h3>
            </Box>
            <Token labelButton="global.send" isSubmitting={isSubmitting} />
        </Form>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        formData: SelectorsStoreWMShares.getFormData(store),
        transaction: SelectorsStoreWMShares.getTransaction(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithRouter,
    WithFormik({
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(UtilsI18n.get("client.wm.shares.step2.otp.required")),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const {
                dispatch,
                history,
                formData,
                transaction: { idTransaction, idActivity },
            } = formikBag.props;

            const isFromTransactionsHistory = history.location.pathname.includes("/ticket");
            if (isFromTransactionsHistory) {
                dispatch(
                    SelectorsActionWMShares.signTransaction({
                        idForm: null,
                        idActivity,
                        idTransaction,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            } else {
                dispatch(
                    SelectorsActionWMShares.sellSharesSend({
                        formData,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            }
        },
    }),
)(Component);
