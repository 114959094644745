import React from "react";

import { connect as Connect } from "react-redux";

import { UI } from "~/constants";
import { SelectorsAction as ActionWidget, SelectorsStore as StoreWidget } from "~/store/widget";
import UtilFlag from "~/util/flag";

import DataNumber from "~/components/DataNumber";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";
import Slides from "~/components/Slides";

import Style from "./Accounts.rules.scss";
import Widget from "./_common/_Widget";

export const { NAME, TYPE } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

const TYPES = {
    CA: "savings",
    CC: "checking",
    CUI: "CUI",
};

export function Component(props) {
    const { dispatch, store: widget, accounts, isFetching, isMobile, ...rest } = props;

    React.useEffect(() => {
        dispatch(ActionWidget.listRequest({ widget }));
    }, [dispatch, widget]);

    const renderImageCurrency = (type) => (isMobile ? type !== TYPES.CUI : true);

    const renderCurrency = (type) => (isMobile ? type === TYPES.CUI : false);

    return (
        <Widget
            {...rest}
            id={Style.ID}
            wait={isFetching}
            header-icon="menu-accounts.svg"
            header-title="menu.accounts"
            header-href="/accounts">
            {!accounts.length ? (
                <I18n id="desktop.widgets.accounts.empty" />
            ) : (
                <Slides elementsPerSlide={2}>
                    {accounts.map(({ key, alias, currency, balance, type, permissions, uiBalance }) => (
                        <div key={key}>
                            <Link className={type !== TYPES.CUI && "link-data"} to={`/accounts/${key}`}>
                                <div className="account-name">
                                    <h4>{alias}</h4>
                                    {!isMobile && type === TYPES.CUI && (
                                        <h2 className="ui-balance">
                                            <I18n id="accounts.equivalentUIBalance" />
                                            <div className="ui-balance-value">
                                                <DataNumber value={uiBalance} />
                                            </div>
                                        </h2>
                                    )}
                                </div>

                                {isMobile && type === TYPES.CUI && (
                                    <h2 className="ui-balance">
                                        <I18n id="accounts.equivalentUIBalance.mobile" />
                                        <div className="ui-balance-value">
                                            <span>{UI}</span>
                                            <DataNumber value={uiBalance} />
                                        </div>
                                    </h2>
                                )}

                                {renderImageCurrency(type) && <Image src={`${UtilFlag.getFlag(currency)}.svg`} />}

                                <h3>
                                    {renderCurrency(type) && <I18n id={`currency.label.${currency}`} />}
                                    <DataNumber value={balance} />
                                </h3>
                            </Link>
                            <RenderOperations permissions={permissions} idProduct={key} productType={type} />
                        </div>
                    ))}
                </Slides>
            )}
        </Widget>
    );

    function RenderOperations(account) {
        const { idProduct, permissions, productType } = account;
        const {
            transferInternal,
            transferForeign,
            transferLocal,
            transferThirdParties,
            requestCheckbook,
            preferentialTradingPrice,
        } = permissions || {};

        const accountOperations = [
            {
                key: "accounts.movements",
                to: `/accounts/${idProduct}`,
            },
            requestCheckbook &&
                productType === TYPES.CC && {
                    key: "menu.accounts.requestCheckbook",
                    to: `/form/requestCheckbook?idAccount=${idProduct}`,
                },
            preferentialTradingPrice && {
                key: "menu.accounts.preferentialTradingPrice",
                to: "/preferentialTradingPrice",
            },
            (transferInternal || transferForeign || transferLocal || transferThirdParties) && {
                key: "menu.transfers",
                to:
                    productType === TYPES.CUI
                        ? `/form/transferInternal?debitAccount=${idProduct}`
                        : `/transfers?debitAccount=${idProduct}`,
            },
        ].filter(Boolean);

        return (
            <div className="operations">
                {accountOperations.map(({ key, to }) => (
                    <Link key={key} to={to}>
                        <I18n id={key} />
                    </Link>
                ))}
            </div>
        );
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

function mapStateToProps(store, props) {
    const { store: widget } = props;
    const { isFetching, data = [] } = StoreWidget.getWidget(store, widget) || {};
    const accounts = data.map((item) => {
        const { idProduct: key, productAlias: alias, productType, balance, currency, permissions, uiBalance } = item;

        return {
            alias,
            balance,
            currency,
            key,
            permissions,
            type: TYPES[productType] || "unknown",
            uiBalance,
        };
    });

    return { isFetching, accounts };
}

export default HighOrder(Connect(mapStateToProps), HighOrder.Resizable)(Component);
