import React from "react";

import { Field, Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import { EMPTY_STR, SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/Simple";
import { SelectorsAction as SelectorsActionLoan, SelectorsStore as SelectorsStoreLoan } from "~/store/loan";
import { SelectorsAction as SelectorsActionProducts } from "~/store/products";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { get as GetConfig } from "~/util/config";
import * as I18nUtils from "~/util/i18n";

import Button from "~/components/Button";
import Wrap from "~/pages/_components/Container";
import TextField from "~/pages/_components/fields/TextField";

const FORM_ID = "loans.details.alias";

export const NAME = "SetAlias";

export const PROP = {
    types: {
        history: PropTypes.shape({
            goBack: PropTypes.func.isRequired,
        }).isRequired,
        isChangingEnvironment: PropTypes.bool,
        isSubmitting: PropTypes.bool.isRequired,
    },
    defaults: {
        isChangingEnvironment: false,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        const { dispatch, isChangingEnvironment, ...rest } = this.props;

        if (!isChangingEnvironment) {
            dispatch(SelectorsActionLoan.readLoan({ idLoan: rest.match.params.id }));
            dispatch(SelectorsActionLoan.closeOptions());
        }
    }

    render() {
        const { isSubmitting, history } = this.props;

        return (
            <Container
                name={NAME}
                head-title="generic.changeAlias.title"
                head-onBackWeb={history.goBack}
                head-onClose={history.goBack}
                wait={isSubmitting}
                scopeToShowNotification={SCOPE.LOANS_SET_ALIAS}>
                <Form className="above-the-fold">
                    <Wrap className="align-items-center flex-grow container--layout">
                        <Col sm={12} md={9} lg={6} xl={6} className="col">
                            <Field
                                autoFocus
                                component={TextField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                name="setAlias"
                                type="text"
                            />
                        </Col>
                    </Wrap>
                    <Wrap className="align-items-center container--layout">
                        <Col sm={12} md={9} lg={6} xl={6} className="col">
                            <Button type="submit" bsStyle="primary" label="global.accept" loading={isSubmitting} />
                        </Col>
                    </Wrap>
                </Form>
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    loan: SelectorsStoreLoan.getSelectedLoan(store),
});

export default Compose(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            setAlias: props.loan ? props.loan.productAlias : EMPTY_STR,
            productId: props.match.params.id,
        }),
        validationSchema: () =>
            Yup.object().shape({
                setAlias: Yup.string()
                    .trim()
                    .required(I18nUtils.get("generic.field.required"))
                    .matches(
                        new RegExp(GetConfig("core.productAlias.regexPattern")),
                        I18nUtils.get("form.character.restriction"),
                    ),
            }),
        handleSubmit: ({ productId, setAlias }, formikBag) => {
            formikBag.props.dispatch(
                SelectorsActionProducts.changeProductAlias({
                    alias: setAlias,
                    idProduct: productId,
                    backAfterCall: true,
                }),
                formikBag,
            );
        },
    }),
)(Component);
