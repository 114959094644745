import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import { SelectorsStore } from "~/store/comex/import";
import { SelectorsAction as SelectorsActionFile } from "~/store/files";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { specificDate as SpecificDate } from "~/util/date";
import * as i18nUtils from "~/util/i18n";

import DataNumber from "~/components/DataNumber";
import GridLayout from "~/components/GridLayout";
import I18n from "~/components/I18n";
import Ticket from "~/components/Ticket/Ticket";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        transaction: PropTypes.shape({
            idTransaction: PropTypes.string,
            idTransactionStatus: PropTypes.string,
            valueDateAsString: PropTypes.string,
            executing: PropTypes.bool,
        }).isRequired,
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const { transaction, ticketConfirmation, dispatch } = props;
    const { data } = transaction && transaction;

    const COLUMN_HEADINGS = [
        i18nUtils.get("comex.imports.creditLetter.form.columnheaders.document"),
        i18nUtils.get("comex.imports.creditLetter.form.columnheaders.description"),
        i18nUtils.get("comex.imports.creditLetter.form.columnheaders.original"),
        i18nUtils.get("comex.imports.creditLetter.form.columnheaders.copies"),
    ];

    const fileIds = data && data.attachments ? data.attachments.split(",") : "";

    const downloadFile = (idFile, fileName) => {
        dispatch(SelectorsActionFile.downloadFileRequest({ idFile, fileName }));
    };

    const shareTicket = (idTransactionToRead) => {
        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    };
    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}
                    handleModify={handleModify}
                    displayDownloadPDF
                    hasMobileComponent
                    handleDownloadPDF={() => shareTicket(transaction.idTransaction)}>
                    {data.advisingBankBIC && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.advisingBankBIC.label" />
                            </span>
                            <span className="data-name">{data.advisingBankBIC}</span>
                        </div>
                    )}

                    {data.advisingBankName && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.advisingBankName.label" />
                            </span>
                            <span className="data-name">{data.advisingBankName}</span>
                        </div>
                    )}

                    {data.advisingBankAddress && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.advisingBankAddress.label" />
                            </span>
                            <span className="data-name">{data.advisingBankAddress}</span>
                        </div>
                    )}

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.confirmationInstructions" />
                        </span>
                        <span className="data-name">
                            {data.confirmationInstructions
                                ? i18nUtils.get("comex.generic.yes")
                                : i18nUtils.get("comex.generic.no")}
                        </span>
                    </div>

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.transferable" />
                        </span>
                        <span className="data-name">
                            {data.transferable ? i18nUtils.get("comex.generic.yes") : i18nUtils.get("comex.generic.no")}
                        </span>
                    </div>

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.domestica" />
                        </span>
                        <span className="data-name">
                            {data.domestic ? i18nUtils.get("comex.generic.yes") : i18nUtils.get("comex.generic.no")}
                        </span>
                    </div>

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.expirationDate.label" />
                        </span>
                        <span className="data-name">{SpecificDate(data.expirationDate, "DD/MM/YYYY")}</span>
                    </div>

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.expirationPlace.label" />
                        </span>
                        <span className="data-name">{data.expirationPlace}</span>
                    </div>

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.purchaserName" />
                        </span>
                        <span className="data-name">{data.purchaserName}</span>
                    </div>

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.purchaserAddress.label" />
                        </span>
                        <span className="data-name">{data.purchaserAddress}</span>
                    </div>

                    {data.beneficiaryLine1 && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.beneficiaryLine1.label" />
                            </span>
                            <span className="data-name">{data.beneficiaryLine1}</span>
                        </div>
                    )}

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.beneficiaryLine2to5.label" />
                        </span>
                        <span className="data-name">
                            {data.beneficiaryLine2} {data.beneficiaryLine3} {data.beneficiaryLine4}{" "}
                            {data.beneficiaryLine5}
                        </span>
                    </div>

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.amount.label" />
                        </span>
                        <span className="data-name">
                            <DataNumber
                                value={data.amount.quantity}
                                prefix={data.amount.currency}
                                className="mobile-only"
                            />
                        </span>
                    </div>

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.amountTolerance.label" />
                        </span>
                        <span className="data-name">
                            {data.amountTolerance
                                ? i18nUtils.get("comex.imports.creditLetter.form.amountTolerance.description")
                                : i18nUtils.get("comex.generic.yes")}
                        </span>
                    </div>
                    {!data.amountTolerance && (
                        <React.Fragment>
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="comex.imports.creditLetter.form.amountToleranceMin.label" />
                                </span>
                                <div className="tolerance-percetage">
                                    <span className="data-name">{data.amountToleranceMin}</span>
                                    <I18n id="comex.imports.creditLetter.form.percentageSymbol" />
                                </div>
                            </div>
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="comex.imports.creditLetter.form.amountToleranceMax.label" />
                                </span>
                                <div className="tolerance-percetage">
                                    <span className="data-name">{data.amountToleranceMax}</span>
                                    <I18n id="comex.imports.creditLetter.form.percentageSymbol" />
                                </div>
                            </div>
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="comex.imports.creditLetter.form.data.toleranceMerchandise" />
                                </span>
                                <span className="data-name">
                                    {data.toleranceMerchandise
                                        ? i18nUtils.get("comex.generic.yes")
                                        : i18nUtils.get("comex.generic.no")}
                                </span>
                            </div>
                        </React.Fragment>
                    )}

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.available.label" />
                        </span>
                        <span className="data-name">{data.available}</span>
                    </div>

                    {data.availablePeriodDays && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.availablePeriodDays.label" />
                            </span>
                            <span className="data-name">{data.availablePeriodDays} </span>
                        </div>
                    )}

                    {data.availablePeriodFrom && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.availablePeriodFrom" />
                            </span>
                            <span className="data-name">
                                {i18nUtils.get(
                                    `comex.imports.creditLetter.form.availablePeriodFrom.${data.availablePeriodFrom}`,
                                )}{" "}
                            </span>
                        </div>
                    )}

                    {data.availableMixed && (
                        <React.Fragment>
                            <div className="data-wrapper">
                                <span className="data-name">{data.availableMixed} </span>
                            </div>
                        </React.Fragment>
                    )}

                    {data.availablePeriodOther && (
                        <React.Fragment>
                            <div className="data-wrapper">
                                <span className="data-name">{data.availablePeriodOther} </span>
                            </div>
                        </React.Fragment>
                    )}

                    {data.available && data.available === "Plazo" && (
                        <React.Fragment>
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="comex.imports.creditLetter.form.data.requiresLetterOfCredit" />
                                </span>
                                <span className="data-name">
                                    {data.requiresLetterOfCredit
                                        ? i18nUtils.get("comex.generic.yes")
                                        : i18nUtils.get("comex.generic.no")}
                                </span>
                            </div>
                        </React.Fragment>
                    )}

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.partialShipments.label" />
                        </span>
                        <span className="data-name">
                            {data.partialShipments
                                ? i18nUtils.get("comex.imports.creditLetter.form.partialShipments.description")
                                : i18nUtils.get("comex.imports.creditLetter.form.partialShipments.no.description")}
                        </span>
                    </div>

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.transhipment.label" />
                        </span>
                        <span className="data-name">
                            {data.transhipment
                                ? i18nUtils.get("comex.imports.creditLetter.form.partialShipments.description")
                                : i18nUtils.get("comex.imports.creditLetter.form.partialShipments.no.description")}
                        </span>
                    </div>

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.shippingExpirationDate.label" />
                        </span>
                        <span className="data-name">{SpecificDate(data.shippingExpirationDate, "DD/MM/YYYY")}</span>
                    </div>

                    {data.shippingPeriod && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.shippingPeriod.label" />
                            </span>
                            <span className="data-name">{data.shippingPeriod}</span>
                        </div>
                    )}

                    {data.deliveryLocation && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.deliveryLocation.label" />
                            </span>
                            <span className="data-name">{data.deliveryLocation}</span>
                        </div>
                    )}

                    {data.shipmentAirPort && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.shipmentAirPort.label" />
                            </span>
                            <span className="data-name">{data.shipmentAirPort}</span>
                        </div>
                    )}

                    {data.destinationAirPort && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.destinationAirPort.label" />
                            </span>
                            <span className="data-name">{data.destinationAirPort}</span>
                        </div>
                    )}

                    {data.destinationPlace && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.destinationPlace.label" />
                            </span>
                            <span className="data-name">{data.destinationPlace}</span>
                        </div>
                    )}

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.description.label" />
                        </span>
                        <span className="data-name">{data.description}</span>
                    </div>

                    {data.shippedBy && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.shippedBy.label" />
                            </span>
                            <span className="data-name">{data.shippedBy}</span>
                        </div>
                    )}

                    {data.terms && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.terms" />
                            </span>
                            <span className="data-name">
                                {i18nUtils.get(`incoterms.${data.terms}`)} {data.termsDetail}
                            </span>
                        </div>
                    )}

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.documents" />
                        </span>
                    </div>

                    <GridLayout className="header ticket-table-section">
                        {COLUMN_HEADINGS.map((heading) => {
                            return <div>{heading}</div>;
                        })}
                    </GridLayout>
                    {data.documents &&
                        data.documents.map((document) => (
                            <GridLayout className="item ticket-table-section">
                                <div>{i18nUtils.get(`comex.creditLetter.modal.documentTypes.${document.type}`)}</div>
                                <div>{document.description}</div>
                                <div>{document.originals}</div>
                                <div>{document.copies}</div>
                            </GridLayout>
                        ))}

                    {data.authorizeDisclaimer && data.shippedBy === "TERRESTRE" && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.authorizeDisclaimer" />
                            </span>
                            <span className="data-name">
                                {i18nUtils.get("comex.imports.creditLetter.form.preField")}{" "}
                                {i18nUtils.get(
                                    `comex.imports.creditLetter.form.authorizeCopies.option.${data.authorizeCopies}`,
                                )}{" "}
                                {i18nUtils.get("comex.imports.creditLetter.form.authorizeDisclaimer.postField")}{" "}
                            </span>
                        </div>
                    )}

                    {data.instructions && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.instructions.label" />
                            </span>
                            <span className="data-name">{data.instructions}</span>
                        </div>
                    )}

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.docsPresentationDays.label" />
                        </span>
                        <div className="tolerance-percetage">
                            <span className="data-name">{data.docsPresentationDays}</span>
                            <I18n id="comex.imports.creditLetter.form.docsPresentationDays.description" />
                        </div>
                    </div>

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.bankExpenses.label" />
                        </span>
                        <span className="data-name">
                            {i18nUtils.get(`comex.imports.creditLetter.form.bankExpenses.${data.bankExpenses}`)}
                        </span>
                    </div>

                    {data.bankExpensesOther && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.bankExpensesOther" />
                            </span>
                            <span className="data-name">{data.bankExpensesOther}</span>
                        </div>
                    )}

                    {/* adjuntos */}
                    {data.attachments && (
                        <React.Fragment>
                            <div className="data-wrapper">
                                {[i18nUtils.get("comex.imports.creditLetter.form.attachments.heading1")].map(
                                    (heading) => {
                                        return <span className="data-label">{heading}</span>;
                                    },
                                )}
                            </div>
                            {fileIds.map((fileId, index) => (
                                <div className="data-wrapper">
                                    <span className="data-label">
                                        <a onClick={() => downloadFile(fileId, data.attachmentsNames[index])}>
                                            {data.attachmentsNames[index]}
                                        </a>
                                    </span>
                                </div>
                            ))}
                        </React.Fragment>
                    )}
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { idTransaction } = transaction;
        dispatch(
            SelectorsActionComex.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        dispatch(SelectorsActionComex.signTransactionPreview({ transaction }));
    }
    
    function handleModify() {
        const { idTransaction } = transaction;
        dispatch(SelectorsActionForm.modifyTransaction({ idTransactionToMove: idTransaction }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStore.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
