import { TYPE as TYPE_CHANNELS } from "~/store/administration/common/channels";
import { TYPE as TYPE_DETAILS } from "~/store/administration/common/details";
import { TYPE as TYPE_PERMISSIONS } from "~/store/administration/common/permissions";
import { TYPE as TYPE_SIGNATURES } from "~/store/administration/common/signatures";

export const NAME = "medium";

export const PATH = {
    ADMINISTRATION_MEDIUM_USER_DETAILS_READ: "/administration.medium.user.details.read",
    ADMINISTRATION_MEDIUM_READ_PERMISSIONS: "/administration.medium.read.permissions",
    ADMINISTRATION_MEDIUM_MODIFY_PERMISSIONS_PREVIEW: "/administration.medium.modify.permissions.preview",
    ADMINISTRATION_MEDIUM_MODIFY_PERMISSIONS_SEND: "/administration.medium.modify.permissions.send",
    ADMINISTRATION_MEDIUM_READ_CHANNELS: "/administration.medium.read.channels",
    ADMINISTRATION_MEDIUM_MODIFY_CHANNELS_PREVIEW: "/administration.medium.modify.channels.preview",
    ADMINISTRATION_MEDIUM_MODIFY_CHANNELS_SEND: "/administration.medium.modify.channels.send",
    ADMINISTRATION_SIGNATURES_CREATE_SEND: "/administration.signatures.create.send",
    ADMINISTRATION_SIGNATURES_CREATE_PRE: "/administration.signatures.create.pre",
    ADMINISTRATION_SIGNATURES_LIST: "/administration.signatures.list",
    ADMINISTRATION_SIGNATURES_DELETE_SEND: "/administration.signatures.delete.send",
    ADMINISTRATION_SIGNATURES_DELETE_PRE: "/administration.signatures.delete.pre",
    ADMINISTRATION_SIGNATURES_MODIFY_SEND: "/administration.signatures.modify.send",
    ADMINISTRATION_SIGNATURES_MODIFY_PRE: "/administration.signatures.modify.pre",
    ADMINISTRATION_MEDIUM_MODIFY_SIGNATURE_SEND: "/administration.medium.modify.signature.send",
    ADMINISTRATION_SIGNATURES_EXPORT: "/administration.signatures.export",
    ADMINISTRATION_SIGNATURES_MEDIUM_MODIFY_SEND: "/administration.signatures.medium.modify.send",
};

// Exportamos los TYPE con el prefijo del nivel de administracion que corresponda.

export const TYPE_ADMINISTRATION_LEVEL_MEDIUM_CHANNELS = TYPE_CHANNELS(NAME);
export const TYPE_ADMINISTRATION_LEVEL_MEDIUM_DETAILS = TYPE_DETAILS(NAME);
export const TYPE_ADMINISTRATION_LEVEL_MEDIUM_PERMISSION = TYPE_PERMISSIONS(NAME);
export const TYPE_ADMINISTRATION_LEVEL_MEDIUM_SIGNATURE = TYPE_SIGNATURES(NAME);
