import { EMPTY_STR, QR_MODO_TYPE_ACCOUNT, URUGUAY } from "~/constants";
import * as UtilsConfig from "~/util/config";
import UtilsDevice from "~/util/device";
import * as UtilsI18n from "~/util/i18n";

export const initialize = (configInitialize) => {
    if (UtilsDevice.isMobileNative()) {
        return window.modoSdk.initialize(configInitialize);
    }

    return undefined;
};

export const launchSdk = (params) => {
    if (UtilsDevice.isMobileNative()) {
        return window.modoSdk.launchSdk(params);
    }

    return undefined;
};

export const updateHeaders = (params) => {
    if (UtilsDevice.isMobileNative()) {
        return window.modoSdk.updateHeaders(params);
    }

    return undefined;
};

const getTypeAccountModo = (type) => {
    if (type in QR_MODO_TYPE_ACCOUNT) {
        return QR_MODO_TYPE_ACCOUNT[type];
    }

    return EMPTY_STR;
};

export const mapAccountsToModo = (account) => {
    const accountDescription = account.productAlias.replace(
        `${UtilsI18n.get(`core.productType.label.${account.productType}`)} `,
        EMPTY_STR,
    );

    return {
        accountNumber: account.idProduct,
        balance: account.balance,
        countryCode: URUGUAY,
        currency: UtilsI18n.get(`currency.label.${account.currency}`), // "UYU",
        institutionCode: UtilsConfig.get("client.qrModo.institutionCode"),
        lastDigits: accountDescription,
        typeAccount: getTypeAccountModo(account.productType), // "SAVING","CURRENT"
        verifierDigit: 2,
    };
};
