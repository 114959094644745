import React from "react";

import { ResponsivePie } from "@nivo/pie";
import NumberFormat from "react-number-format";

import * as i18nUtils from "~/util/i18n";

import Style from "./PieChartSection.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export class Component extends React.Component {
    render() {
        const { data, myPortfolioTotal } = this.props;
        return (
            <div className="pieChartSection">
                <div className="infoSection">
                    <h2>{i18nUtils.get(`investment.piechart.info.title`)}</h2>
                    <p>{i18nUtils.get(`investment.piechart.info.subtitle`)}</p>
                    <span>{`${i18nUtils.get(`core.currency.label.2222`)}  `}</span>
                    <NumberFormat
                        className="form-control amount"
                        fixedDecimalScale
                        decimalScale="2"
                        decimalSeparator=","
                        thousandSeparator="."
                        displayType="text"
                        value={myPortfolioTotal}
                    />
                </div>
                <div className="cartContainer">
                    <ResponsivePie
                        data={data
                            .filter((d1) => d1.roundedPercent > 0)
                            .map((d) => {
                                return {
                                    id: `${i18nUtils.get(`investment.piechart.legends.${d.group}`)}     ${
                                        d.roundedPercent
                                    }%`,
                                    value: d.roundedPercent,
                                    color: i18nUtils.get(`investment.piechart.legends.${d.group}.color`),
                                };
                            })}
                        margin={{ top: 0, right: 190, bottom: 0, left: 0 }}
                        sortByValue
                        innerRadius={0.5}
                        colors={(datum) => datum.color }
                        borderWidth={1}
                        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                        enableRadialLabels={false}
                        enableSlicesLabels={false}
                        left={0}
                        isInteractive={false}
                        legends={[
                            {
                                anchor: "top-right",
                                direction: "column",
                                justify: false,
                                translateX: 100,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemsSpacing: 3,
                                symbolSize: 13,
                                itemDirection: "left-to-right",
                            },
                        ]}
                    />
                </div>
            </div>
        );
    }
}

export default Component;
