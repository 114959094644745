import React from "react";

import { goBack as GoBack } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import { SelectorsStore as SelectorsStoreSettings, SelectorsAction as SelectorsActionSettings } from "~/store/settings";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import HighOrder from "~/components/HighOrder";

import FormPreview from "../changeEmail/ChangeEmailConfirmation";
import FormView from "../ticket/ChangeEmailTicket";
import FormEdit from "./ChangeEmailDataAccountsSendCode";

export const NAME = "ChangeEmail";

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        isFetching: PropTypes.bool,
        mode: PropTypes.string.isRequired,
        ...TypesRedux,
    },
    defaults: {
        isFetching: false,
        ...DefaultsRedux,
    },
};

export function Component({ dispatch, isFetching, mode }) {
    React.useEffect(() => {
        dispatch(SelectorsActionSettings.getUserData());
    }, [dispatch]);

    function handleBack() {
        if (mode === MODE.PREVIEW) {
            dispatch(SelectorsActionSettings.updateMailPreviewCancel());
        } else {
            dispatch(GoBack());
        }
    }

    return (
        <Container
            name={NAME}
            wait={isFetching}
            step={mode}
            head-onBack={(mode !== MODE.VIEW && mode !== MODE.EDIT && handleBack) || undefined}
            head-onBackWeb={mode === MODE.EDIT && handleBack}
            head-title={UtilsI18n.get("change.account.email.title")}
            scopeToShowNotification={SCOPE.CHANGE_EMAIL}>
            <React.Fragment>
                {mode === MODE.EDIT && <FormEdit onCancel={handleBack} />}
                {mode === MODE.PREVIEW && <FormPreview onBack={handleBack} onUpdateEmailAccounts />}
                {mode === MODE.VIEW && <FormView ticketConfirmation />}
            </React.Fragment>
        </Container>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    isFetching: SelectorsStoreSettings.getFetchingForm(store),
    mode: SelectorsStoreSettings.getMode(store),
});

export default HighOrder(Connect(mapStateToProps))(Component);
