import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";

import { COMMA, EMPTY_STR, LANGUAGE, LEVEL, NO_DATA, ONLY_NUMBER, SCOPE, SPACE_STR } from "~/constants";
import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { SelectorsAction as SelectorsActionComex, SelectorsStore as SelectorsStoreComex } from "~/store/comex";
import {
    SelectorsAction as SelectorsActionComexImport,
    PROP as PropComex,
    SelectorsStore as SelectorsStoreComexImport,
} from "~/store/comex/import";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsAction as SelectorActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionTemplate } from "~/store/template";
import * as ConfigUtil from "~/util/config";
import { toDate as ToDate } from "~/util/date";
import { isEmptyObj } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import Select from "~/components/Select";
import EmailList from "~/pages/_components/fields/EmailList";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TextArea from "~/pages/_components/fields/TextArea";
import TextField from "~/pages/_components/fields/TextField";

import FileUploader from "~/pages/comEx/_components/FileUploader";
import OperationNumber from "~/pages/comEx/_components/OperationNumber";
import OperationNumberDetail from "~/pages/comEx/_components/OperationNumberDetail";
import CreateTemplateModal from "~/pages/forms/Step1Edit/_ModalTemplateCreate";
import ListTemplatesModal from "~/pages/forms/Step1Edit/_ModalTemplateList";
import { Termsandconditions } from "~/pages/forms/_components/_fields";
import Scheduler from "~/pages/forms/_components/_fields/Scheduler";

import Style from "../../../Step1Edit.rules.scss";

const FORM_ID = "comex.import.requestEndorsement";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...PropComex.types,
    },
    defaults: {
        ...PropComex.defaults,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        draftLoaded: false,
    };

    componentDidMount() {
        const { dispatch, formData } = this.props;

        if (isEmptyObj(formData)) {
            dispatch(SelectorsActionComexImport.requestEndorsementPreRequest());
        }
    }

    componentDidUpdate() {
        const { dispatch, setFieldError, setFieldTouched, setValues, transaction } = this.props;

        const { draftLoaded } = this.state;

        if (transaction?.data && !draftLoaded) {
            this.setState({
                draftLoaded: true,
            });

            const transactionData = transaction?.data;

            /* carga de errores */
            if (transactionData.backendFormErrors?.cdpErrorFields) {
                const { cdpErrorFields } = transactionData.backendFormErrors;

                cdpErrorFields.map((errorField) => {
                    if (errorField === "valueDate") {
                        setFieldError("scheduler", SPACE_STR);
                        setFieldTouched("scheduler", true);

                        transactionData.scheduler = {
                            editing: true,
                            valueDate: transactionData?.valueDate,
                            selectedOption: transactionData?.selectedOption,
                        };
                    } else {
                        setFieldError(errorField, SPACE_STR);
                        setFieldTouched(errorField, true);
                    }

                    return null;
                });
            }

            if (transactionData.backendFormErrors?.message) {
                const errorMessage = transactionData?.backendFormErrors?.message;

                dispatch(
                    SelectorActionNotification.showNotification({
                        message: errorMessage,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.COMEX_IMPORT_REQUEST_ENDORSEMENT],
                    }),
                );
            }

            setValues(transaction.data);
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(GoBack());
    };

    buildOptions = () => {
        return [
            {
                id: "AEREO",
                label: UtilsI18n.get(`${FORM_ID}.aereo`),
            },
            {
                id: "MARITIMO",
                label: UtilsI18n.get(`${FORM_ID}.maritimo`),
            },
        ];
    };

    handleFormKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            event.preventDefault();
        }
    };

    handleFieldKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            this.handleSearch();
        }
    };

    handleItemSelect = (item) => {
        const { setFieldError, setFieldValue } = this.props;

        const { othersFields } = item;
        if (othersFields) {
            setFieldValue("operationNumber", othersFields.NROOPERACION);
            setFieldValue("item", item);
            setFieldError("operationNumber", null);
        } else {
            setFieldValue("item", null);
            setFieldValue("operationNumber", EMPTY_STR);
        }
    };

    handleModalLoad = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionComex.toggleCreditLetterList());
    };

    handleBlur = () => {
        const { preData, setFieldValue, values } = this.props;
        const { availableOperationList } = preData;

        if (values.operationNumber) {
            const operationSelected = availableOperationList.filter(
                (item) => item.othersFields.NROOPERACION === values.operationNumber,
            );

            if (operationSelected.length > 0) {
                setFieldValue("item", operationSelected[0]);
            } else {
                setFieldValue("item", null);
                setFieldValue("operationNumber", EMPTY_STR);
            }
        } else {
            setFieldValue("item", null);
            setFieldValue("operationNumber", EMPTY_STR);
        }
    };

    handleCancel = () => {
        const { dispatch, setSubmitting, transaction } = this.props;

        dispatch(
            SelectorsActionForm.cancelTransactionCustom({
                credentials: {},
                idTransaction: transaction.idTransaction,
                formikBag: { setSubmitting },
            }),
        );
    };

    handleDraft = () => {
        const { dispatch, transaction, values } = this.props;

        dispatch(
            SelectorsActionForm.saveDraft({
                idActivityDraft: "comex.import.requestEndorsement.send",
                idTransactionToSave: transaction?.idTransaction,
                transactionData: values || {},
            }),
        );
    };

    handleTemplateSave = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionTemplate.createTemplate());
    };

    handleTemplateLoad = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionTemplate.toggleTemplateList());
    };

    handleSelectTemplate = (template) => {
        const { setErrors, setValues } = this.props;
        const templateToBeLoaded = template;

        if (templateToBeLoaded.scheduler.valueDate) {
            const valueDate = ToDate(templateToBeLoaded.scheduler.valueDate);

            templateToBeLoaded.scheduler.valueDate = valueDate;
        }

        setValues(templateToBeLoaded);
        setErrors({});
    };

    handleSelectChange = (id) => {
        const { setFieldValue } = this.props;

        setFieldValue("documentType", id);
    };

    render() {
        const { emailValidationRegex, errors, preData, setFieldValue, transaction, values } = this.props;
        const options = this.buildOptions();
        const { availableOperationList, nextValidDate, unavailableDays } = preData;
        const termsAndConditions = "comex.import.requestEndorsement.disclaimer";
        const i18nMap = {
            termsAndConditions,
        };

        const documentType = (values && values.documentType) || "AEREO";

        const cancelEnabled = transaction && transaction.idTransactionStatus === STATUS.DRAFT;

        const othersFields = values.item ? values.item.othersFields : null;

        return (
            <React.Fragment>
                <Form
                    className="col col-12 col-lg-6 col-md-9 col-sm-12"
                    id={Style.ID}
                    noValidate="novalidate"
                    onKeyDown={this.handleFormKeyDown}>
                    <div className="form-section loadTemplates">
                        <Button
                            onClick={this.handleTemplateLoad}
                            label="forms.templates.load"
                            bsStyle="default"
                            image="template.svg"
                        />
                    </div>
                    <section className="fields container--layout align-items-center flex-grow">
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.operation`)}</h3>
                        </Box>
                        <Box flex align="end" className="form-group search-field">
                            <Field
                                idForm={FORM_ID}
                                name="operationNumber"
                                hidePlaceholder
                                pattern={ONLY_NUMBER}
                                type="text"
                                component={TextField}
                                onBlur={this.handleBlur}
                                tooltip={UtilsI18n.get("comex.import.requestEndorsement.operationNumber.tooltip")}
                            />

                            <Box className="ml-3 mb-2">
                                <Button
                                    onClick={this.handleModalLoad}
                                    label="global.search"
                                    bsStyle="primary"
                                    className="search"
                                />
                            </Box>
                        </Box>
                        {values.item && <OperationNumberDetail othersFields={othersFields} idForm={FORM_ID} />}

                        <Box className="form-group">
                            <FieldLabel labelKey={`${FORM_ID}.document.label`} />

                            <Box className="input-group">
                                <Field
                                    name="documentType"
                                    component={Select}
                                    placeholder={EMPTY_STR}
                                    value={documentType}
                                    clearable={false}
                                    onChange={(item) => {
                                        if (item) {
                                            this.handleSelectChange(item.id);
                                        }
                                    }}
                                    valueKey="id"
                                    labelKey="label"
                                    options={options}
                                    className="flex-container slideFromBottom"
                                    optionClassName="needsclick"
                                    idForm={FORM_ID}
                                />
                            </Box>
                        </Box>
                        <Field
                            idForm={FORM_ID}
                            name="documentNumber"
                            hidePlaceholder
                            type="text"
                            component={TextField}
                            maxLength={40}
                        />
                        <Field
                            component={TextArea}
                            idForm={FORM_ID}
                            name="observations"
                            mode={MODE.EDIT}
                            className="form-control"
                            maxLength={500}
                            hidePlaceholder
                        />

                        <Box className="form-group">
                            <Field
                                name="files"
                                idForm={FORM_ID}
                                emptyMessageKey={`${FORM_ID}.attachments.emptyMessage`}
                                maxFiles={5}
                                errors={errors}
                                component={FileUploader}
                                values={values}
                                setFieldValue={setFieldValue}
                            />
                        </Box>

                        <Field
                            component={EmailList}
                            idForm={FORM_ID}
                            name="notificationEmails"
                            hideSwiftTip
                            className="form-group email-field"
                            renderSuggestion={false}
                            data={{ emailValidationRegex, addMessage: EMPTY_STR }}
                            value={values.notificationEmails}
                            tooltip={UtilsI18n.get(`${FORM_ID}.notificationEmails.tooltip`)}
                        />

                        <hr />
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">
                                {UtilsI18n.get(`${FORM_ID}.section.showPreview`)}
                            </h3>
                        </Box>

                        <Field
                            component={Termsandconditions}
                            idForm={FORM_ID}
                            name="disclaimer"
                            mode={MODE.EDIT}
                            className="form-control"
                            i18nMap={i18nMap}
                        />
                        {nextValidDate && (
                            <Field
                                component={Scheduler}
                                data={{
                                    firstWorkingDate: nextValidDate,
                                    maxDaysToSchedule: ConfigUtil.get("client.comex.valueDate.maxDaysToSchedule"),
                                    nonWorkingDays: unavailableDays,
                                    lang: LANGUAGE.ES,
                                    mode: MODE.EDIT,
                                    programable: false,
                                    schedulable: true,
                                }}
                                name="scheduler"
                                idForm={FORM_ID}
                            />
                        )}
                    </section>
                    <footer>
                        <Button
                            onClick={this.handleTemplateSave}
                            className="templateSave"
                            label="forms.saveTemplate.link"
                        />
                        {cancelEnabled && <Button onClick={this.handleCancel} label="forms.cancelTransaction.link" />}
                        <Button
                            key="primaryButton"
                            variant="primary"
                            bsStyle="primary"
                            label="global.next"
                            loading={false}
                            type="submit"
                        />
                    </footer>
                </Form>
                <ListTemplatesModal
                    idActivityTemplate="comex.import.modifyCreditLetter.send"
                    onSelect={this.handleSelectTemplate}
                />
                <CreateTemplateModal
                    values={values}
                    idActivityTemplate="comex.import.modifyCreditLetter.send"
                    backdrop="static"
                />
                <OperationNumber
                    list={availableOperationList}
                    idForm={FORM_ID}
                    handleItemClick={this.handleItemSelect}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        formData: SelectorsStoreComexImport.getRequestEndorsementFromData(store),
        preData: SelectorsStoreComexImport.getRequestEndorsementPre(store),
        transaction: SelectorsStoreComex.getTransaction(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ formData }) => {
            const attachments = formData?.attachments;
            const hasAttachments = attachments !== undefined && attachments !== EMPTY_STR;

            return {
                attachmentsNames: formData?.attachmentsNames || [],
                documentNumber: formData?.documentNumber || EMPTY_STR,
                documentType: formData?.documentType || "AEREO",
                files: hasAttachments ? attachments?.split(COMMA) : [],
                item: formData?.item || null,
                notificationEmails: formData?.notificationEmails,
                observations: formData?.observations || EMPTY_STR,
                operationNumber: formData?.operationNumber || EMPTY_STR,
                scheduler: formData?.scheduler,
            };
        },
        handleSubmit: (props, formikBag) => {
            const { dispatch } = formikBag.props;
            const { item, files, invoiceAmount, scheduler, documentNumber, ...rest } = props;
            const oldAmount = item?.othersFields ? item.othersFields.IMPORTE : EMPTY_STR;
            const beneficiary =
                item?.othersFields?.BENEFICIARIO === EMPTY_STR || item?.othersFields?.BENEFICIARIO === SPACE_STR
                    ? NO_DATA
                    : item?.othersFields?.BENEFICIARIO;
            const attachmentsFiles = files ? files.join(COMMA) : EMPTY_STR;
            const invoiceAmountCurrency = item?.othersFields ? item.othersFields.MONEDA : EMPTY_STR;
            const operationNumberString = rest.operationNumber ? rest.operationNumber : EMPTY_STR;
            const documentType = rest.documentType || "AEREO";

            const formData = {
                ...rest,
                attachments: attachmentsFiles,
                beneficiary,
                documentNumber,
                documentType,
                invoiceAmountCurrency,
                item,
                oldAmount,
                operationNumber: operationNumberString,
                scheduler,
                selectedOption: scheduler?.selectedOption,
            };

            dispatch(
                SelectorsActionComexImport.requestEndorsementPreviewRequest({
                    formData,
                    formikBag,
                }),
            );
        },
    }),
)(Component);
