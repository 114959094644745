import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.GET_FILE_DOWNLOADS_LIST_REQUEST:
            return {
                ...storeProp,
                filters: action.filters || storeProp.filters,
                fetching: true,
            };
        case TYPE.GET_FILE_DOWNLOADS_LIST_SUCCESS: {
            const { downloadsList, moreLines, pageNumber } = action;
            return {
                ...storeProp,
                pageNumber,
                downloadsList: storeProp.downloadsList ? storeProp.downloadsList.concat(downloadsList) : downloadsList,
                moreLines,
                filters: action.filters || storeProp.filters,
                fetching: false,
            };
        }
        case TYPE.GET_CATEGORIES_FAILURE:
        case TYPE.GET_SUB_CATEGORIES_FAILURE:
        case TYPE.GET_FILE_DOWNLOAD_FAILURE:
        case TYPE.GET_FILE_DOWNLOADS_LIST_FAILURE:
        case TYPE.GET_FILE_DOWNLOAD_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.GET_CATEGORIES_REQUEST:
        case TYPE.GET_SUB_CATEGORIES_REQUEST:
        case TYPE.GET_FILE_DOWNLOAD_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.GET_CATEGORIES_SUCCESS:
            return {
                ...storeProp,
                categories: action.categories,
                fetching: false,
            };
        case TYPE.GET_SUB_CATEGORIES_SUCCESS:
            return {
                ...storeProp,
                subCategories: action.subCategories,
                fetching: false,
            };
        case TYPE.RESET_FILTERS:
            return {
                storeProp: PROP.defaults.storeProp,
                filters: PROP.defaults.filters,
                pageNumber: PROP.defaults.pageNumber,
            };
        case TYPE.RESET_LIST:
            // reset list and paginate
            return {
                ...storeProp,
                downloadsList: null,
                moreLines: PROP.defaults.moreLines,
                pageNumber: PROP.defaults.pageNumber,
            };
        default:
            return storeProp;
    }
};
