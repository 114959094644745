import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { DOT, SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import {
    SelectorsAction as SelectorsActionInvestment,
    SelectorsStore as SelectorsStoreInvestment,
} from "~/store/investment";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import HighOrder from "~/components/HighOrder";

import Step1Edit from "./Step1Edit/Step1Edit";
import Step2Preview from "./Step2Preview/Step2Preview";
import Step3View from "./Step3View/Step3View";
import Style from "./TermDeposit.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        fetching: PropTypes.bool.isRequired,
        setFieldValue: PropTypes.func.isRequired,
        validateForm: PropTypes.func.isRequired,
        ...TypesRedux,
    },
    defaults: {
        i18n: {},
        ...DefaultsRedux,
    },
};

const FORM_ID = "investment.termDeposit.form";

export const CREDENTIAL_TYPE_OTP = "otp";

const formatNumber = (decimalSeparator, thousandSeparator, decimalScale, num) => {
    const numParts = num.toFixed(decimalScale).replace(DOT, decimalSeparator).split(decimalSeparator);
    let l = numParts[0].length - 3;

    while (l > 0) {
        numParts[0] = `${numParts[0].slice(0, l)}${thousandSeparator}${numParts[0].slice(l)}`;

        l -= 3;
    }

    return `${numParts[0]}${decimalSeparator}${numParts[1]}`;
};

export function Component(props) {
    const {
        dispatch,
        fetching,
        termDeposit: { mode },
    } = props;

    const handleBack = () => {
        const { history } = props;

        if (mode === MODE.VIEW) {
            dispatch(SelectorsActionInvestment.restartFlow());
        } else if (mode === MODE.PREVIEW) {
            dispatch(SelectorsActionInvestment.termDepositToStepOne());
        } else {
            history.goBack();
        }
    };

    return (
        <Container
            wait={fetching}
            name={NAME}
            step={mode}
            head-onBack={handleBack}
            head-title={`${FORM_ID}.title`}
            className="TermDeposit"
            image="businessPortfolio.svg"
            scopeToShowNotification={SCOPE.INVESTMENT_OTHER_INVESTMENTS}>
            {mode === MODE.EDIT && <Step1Edit formatNumber={formatNumber} />}
            {mode === MODE.PREVIEW && <Step2Preview formatNumber={formatNumber} />}
            {mode === MODE.VIEW && <Step3View ticketConfirmation />}
        </Container>
    );
}

const mapStateToProps = (store) => ({
    fetching: SelectorsStoreInvestment.getFetching(store),
    termDeposit: SelectorsStoreInvestment.getTermDeposit(store),
});

export default HighOrder(Connect(mapStateToProps))(Component);
