import React from "react";

import { goBack as GoBack } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import { SelectorsStore as SelectorsStoreSettings, SelectorsAction as SelectorsActionSettings } from "~/store/settings";
import * as UtilsI18n from "~/util/i18n";

import FormView from "./ChangeEmailTicket";
import FormPreview from "./ChangeEmailTransactionPreview";

export const NAME = "ChangeEmailTransaction";

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        transaction: PropTypes.shape({
            idTransaction: PropTypes.string,
            idTransactionStatus: PropTypes.string,
            valueDateAsString: PropTypes.string,
            executing: PropTypes.bool,
        }).isRequired,
        fetching: PropTypes.bool,
        match: PropTypes.objectOf(PropTypes.object).isRequired,
        mode: PropTypes.string.isRequired,
    },
    defaults: {
        transaction: null,
        fetching: false,
        mode: MODE.EDIT,
    },
};

export function Component({ dispatch, match, transaction, fetching, mode, prevMode }) {
    const { idTransaction } = match.params;

    React.useEffect(() => {
        dispatch(SelectorsActionSettings.readChangeTransactionDataRequest({ idTransaction }));
    }, [dispatch, idTransaction]);

    function handleBack() {
        if (prevMode && prevMode !== mode && mode === MODE.PREVIEW) {
            dispatch(SelectorsActionSettings.updateMailPreviewCancel());
        } else {
            dispatch(GoBack());
        }
    }

    const headTitle =
        transaction?.idActivity === "preferences.update.email.accounts.data"
            ? "change.account.email.title"
            : "change.personal.email.title";

    return (
        <Container
            name={NAME}
            wait={fetching}
            step={mode}
            head-title={UtilsI18n.get(headTitle)}
            head-onBack={(mode !== MODE.VIEW && mode !== MODE.EDIT && handleBack) || undefined}
            head-onBackWeb={mode === MODE.EDIT && handleBack}
            scopeToShowNotification={SCOPE.CHANGE_EMAIL}>
            {mode === MODE.PREVIEW && <FormPreview transaction={transaction} />}
            {(mode === MODE.EDIT || mode === MODE.VIEW) && <FormView transaction={transaction} />}
        </Container>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    ...PROP.defaults,
    fetching: SelectorsStoreSettings.getFetching(store),
    transaction: SelectorsStoreSettings.getChangeTransactionData(store),
    mode: SelectorsStoreSettings.getMode(store),
    prevMode: SelectorsStoreSettings.getPrevMode(store),
});

export default Connect(mapStateToProps)(Component);
