import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import * as i18nUtils from "~/util/i18n";

import Style from "./_DetailedItem.rules.scss";

export const NAME = "BankSearchDetailedItem";

export const PROP = {
    types: {
        bankName: PropTypes.string.isRequired,
        bankAddress: PropTypes.string.isRequired,
        bankCountryLabel: PropTypes.string.isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const { bankName, bankAddress, bankCountryLabel } = props;
    return (
        <div className={classnames("table", "form-group", `${Style.CLASS}`)}>
            <div className="table-body">
                <div className="table-row">
                    <div className="table-data">
                        <div className="data-label">{i18nUtils.get("forms.bankselector.infoBankName")}</div>
                    </div>
                    <div className="table-data text-right">
                        <div className="data-aux">{bankName}</div>
                    </div>
                </div>
                <div className="table-row">
                    <div className="table-data">
                        <div className="data-label">{i18nUtils.get("forms.bankselector.infoBankAddress")}</div>
                    </div>
                    <div className="table-data text-right">
                        <div className="data-aux">{bankAddress}</div>
                    </div>
                </div>
                <div className="table-row">
                    <div className="table-data">
                        <div className="data-label">{i18nUtils.get("forms.bankselector.infoBankCountry")}</div>
                    </div>
                    <div className="table-data text-right">
                        <div className="data-aux">{bankCountryLabel}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
