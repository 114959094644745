import React from "react";

import PropTypes from "prop-types";

import * as i18nUtils from "~/util/i18n";

import Link from "~/components/Link";

export const NAME = "ColumnHeader";

export const PROP = {
    types: {
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        linkText: PropTypes.string.isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const { title, url, linkText } = props;
    return (
        <header className="col-header">
            <h3>{i18nUtils.get(title)}</h3>
            {url && (
                <Link className="btn btn-quiet" to={url}>
                    {i18nUtils.get(linkText)}
                </Link>
            )}
        </header>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
