import React from "react";

import { push as Push } from "connected-react-router";
import { ButtonGroup } from "react-bootstrap";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import {
    SelectorsAction as SelectorsActionCommunication,
    SelectorsStore as SelectorsStoreCommunication,
    TRAYS,
} from "~/store/communications";
import { getArray as GetArray } from "~/util/config";
import * as I18nUtils from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import Note from "~/components/Note";
import Select from "~/components/Select";
import Container from "~/pages/_components/Container";

import MessageItem from "~/pages/communications/_components/MessageItem";
import NoResults from "~/pages/communications/_components/NoResults";

import Namespaces from "./List.rules.scss";

export const { NAME, TYPE, NAME_NOTE, TYPE_NOTE } = Namespaces;
export const PROP = {
    types: {},
    defaults: {},
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        selectedFilter: "all",
        searchSubject: EMPTY_STR,
    };

    handleUnreadClick = () => {
        const { dispatch } = this.props;
        dispatch(
            SelectorsActionCommunication.listRequest({
                direction: "BANK_TO_CUSTOMER",
                onlyUnread: true,
                activeTray: TRAYS.UNREAD,
            }),
        );
    };

    handleAllClick = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionCommunication.listRequest({ direction: "BANK_TO_CUSTOMER", activeTray: TRAYS.ALL }));
    };

    handleSentClick = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionCommunication.listRequest({ direction: "CUSTOMER_TO_BANK", activeTray: TRAYS.SENT }));
    };

    handleReadClick = () => {
        const { dispatch } = this.props;
        dispatch(
            SelectorsActionCommunication.listRequest({
                direction: "BANK_TO_CUSTOMER",
                onlyUnread: false,
                activeTray: TRAYS.READ,
            }),
        );
    };

    handleSelectMessageClick = (idCommunication, idThread, userRead, index) => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionCommunication.selectedMessage());

        dispatch(Push(`/communications/read/${idThread}/${idCommunication}`));

        if (!userRead) {
            dispatch(SelectorsActionCommunication.toggleMessageStatus({ index }));
        }
    };

    handleChangeMessageStatus = (e, idCommunication, userRead, index) => {
        e.stopPropagation();
        e.preventDefault();

        const { dispatch, activeTray } = this.props;

        if (userRead) {
            dispatch(SelectorsActionCommunication.markAsUnReadRequest({ idCommunication }));
        } else {
            dispatch(SelectorsActionCommunication.markAsReadRequest({ idCommunication }));
        }

        if (activeTray !== TRAYS.ALL) {
            dispatch(SelectorsActionCommunication.removeFromTray({ index }));
        } else {
            dispatch(SelectorsActionCommunication.toggleMessageStatus({ index }));
        }
    };

    handleMarkAllAsRead = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionCommunication.markListAsReadRequest());
    };

    handleFetchMoreClick = () => {
        const { isFetching, currentPage, dispatch, activeTray } = this.props;
        const { selectedFilter } = this.state;

        let filters = {};

        if (!isFetching) {
            switch (activeTray) {
                case TRAYS.READ:
                    filters.direction = "BANK_TO_CUSTOMER";
                    filters.onlyUnread = false;
                    break;
                case TRAYS.UNREAD:
                    filters.direction = "BANK_TO_CUSTOMER";
                    filters.onlyUnread = true;
                    break;
                case TRAYS.SENT:
                    filters.direction = "CUSTOMER_TO_BANK";
                    break;
                case TRAYS.ALL:
                    filters.direction = "BANK_TO_CUSTOMER";
                    filters.communicationType = selectedFilter === "all" ? null : selectedFilter;
                    break;
                default:
                    filters = {};
            }

            filters.pageNumber = currentPage + 1;

            dispatch(SelectorsActionCommunication.fetchMoreRequest(filters));
        }
    };

    handleSearchChange = (e) => {
        this.setState({ searchSubject: e.target.value });
    };

    handleFilterChange = (communicationType) => {
        if(communicationType){
            const { dispatch } = this.props;
            dispatch(
                SelectorsActionCommunication.listRequest({
                    direction: "BANK_TO_CUSTOMER",
                    communicationType: communicationType.value === "all" ? null : communicationType.value,
                }),
            );
            this.setState({ selectedFilter: communicationType.value });
        }
    };

    getItems = () => {
        const { list } = this.props;
        const { searchSubject } = this.state;
        const search = searchSubject.toLocaleLowerCase();

        return list.reduce((acc, communication, index) => {
            if (communication.subject.toLocaleLowerCase().indexOf(search) >= 0) {
                return [
                    ...acc,
                    <MessageItem
                        communication={communication}
                        index={index}
                        handleChangeMessageStatus={this.handleChangeMessageStatus}
                        handleSelectMessageClick={this.handleSelectMessageClick}
                        key={communication.idCommunication}
                    />,
                ];
            }
            return acc;
        }, []);
    };

    getUnreadIdCommunicationList = () => {
        const { list } = this.props;
        const returnList = list.reduce((acc, communication, index) => {
            if (!communication.userRead) {
                return [...acc, { idCommunication: communication.idCommunication, index }];
            }
            return acc;
        }, []);

        return returnList.length > 0 ? returnList : null;
    };

    render() {
        const { currentPage, totalPages, isFetching, activeTray, unreadCommunications } = this.props;
        const { searchSubject, selectedFilter } = this.state;
        const moreMessages = totalPages > currentPage;
        const communicationTypesFilters = GetArray("core.communications.communicationTypes.filters");
        const list = this.getItems();
        return (
            <React.Fragment>
                <section className="align-items-center container--layout">
                    <ButtonGroup className="icon-tags">
                        <Button
                            label="communications.trays.received"
                            className={`btn btn-outline btn-hint-icon ${
                                activeTray === TRAYS.ALL ? "active" : EMPTY_STR
                            }`}
                            onClick={this.handleAllClick}
                            image="inbox.svg"
                        />
                        <Button
                            label="communications.trays.unread"
                            className={`btn btn-outline btn-hint-icon ${
                                activeTray === TRAYS.UNREAD ? "active" : EMPTY_STR
                            }`}
                            onClick={this.handleUnreadClick}
                            image="email.svg"
                        />
                        <Button
                            label="communications.trays.read"
                            className={`btn btn-outline btn-hint-icon ${
                                activeTray === TRAYS.READ ? "active" : EMPTY_STR
                            }`}
                            onClick={this.handleReadClick}
                            image="read.svg"
                        />
                        <Button
                            label="communications.trays.sent"
                            className={`btn btn-outline btn-hint-icon ${
                                activeTray === TRAYS.SENT ? "active" : EMPTY_STR
                            }`}
                            onClick={this.handleSentClick}
                            image="sent.svg"
                        />
                    </ButtonGroup>
                    {activeTray === TRAYS.ALL && (
                        <Select
                            value={selectedFilter}
                            clearable={false}
                            onChange={this.handleFilterChange}
                            valueKey="value"
                            labelKey="label"
                            options={communicationTypesFilters.map((type) => ({
                                value: type,
                                label: I18nUtils.get(`communications.communicationsTypes.${type}`),
                            }))}
                            className="flex-container slideFromBottom"
                        />
                    )}
                    <div className="form-group">
                        <div className="input-group">
                            <input
                                type="search"
                                className="form-control"
                                placeholder={I18nUtils.get("communications.list.search")}
                                required=""
                                id="search"
                                autoComplete="off"
                                onChange={this.handleSearchChange}
                            />
                        </div>
                    </div>
                    {activeTray === TRAYS.ALL && unreadCommunications > 0 && (
                        <Button
                            label="communications.markAllAsRead"
                            className="btn btn-link "
                            onClick={() => this.handleMarkAllAsRead()}
                        />
                    )}
                </section>
                <section className="flex-grow align-items-center container--layout messagesList">
                    {(list.length > 0 && (
                        <React.Fragment>
                            <ul className="message-list">{list}</ul>
                            {isFetching && (
                                <Box flex justify="center">
                                    <Note>{I18nUtils.get("global.loading")}</Note>
                                </Box>
                            )}
                            {!isFetching && moreMessages && (
                                <Box flex justify="center">
                                    <Note onClick={this.handleFetchMoreClick}>
                                        {I18nUtils.get("communications.messages.more")}
                                    </Note>
                                </Box>
                            )}
                            {!isFetching && !moreMessages && (
                                <Box flex justify="center">
                                    <Note className="no-more-movements">
                                        {I18nUtils.get("communications.message.list.end")}
                                    </Note>
                                </Box>
                            )}
                        </React.Fragment>
                    )) || (
                        <Container className="container--layout align-items-center">
                            {searchSubject ? (
                                <NoResults message="communications.list.search.empty" />
                            ) : (
                                <NoResults message="communications.list.empty" />
                            )}
                        </Container>
                    )}
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    activeTray: SelectorsStoreCommunication.getActiveTray(store),
    list: SelectorsStoreCommunication.list(store),
    currentPage: SelectorsStoreCommunication.currentPage(store),
    totalPages: SelectorsStoreCommunication.totalPages(store),
    isFetching: SelectorsStoreCommunication.isFetching(store),
    unreadCommunications: SelectorsStoreCommunication.getUnreadCommunications(store),
});

export default HighOrder(Connect(mapStateToProps), HighOrder.Resizable)(Component);
