import { MODE } from "~/constants/form";
import { TYPE as TypesBackoffice } from "~/store/backoffice";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    const { data, transaction, idPaper } = action;

    switch (action.type) {
        case TYPE.PRE_FORM_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
                idPaperSelectedToSwitch: idPaper,
                mode: MODE.EDIT,
            };
        case TYPE.PRE_FORM_SUCCESS:
            return {
                ...storeProp,
                debitAccounts: data.debitAccounts,
                errors: data.errors,
                isFetching: false,
                papersToBuy: data.similarPaperList,
                papersToSwitch: data.myPapers,
                selectedPaperToSwitch: data.paper,
                timezoneOffset: action.timezoneOffset,
            };
        case TYPE.READ_PAPER_TO_SWITCH_SUCCESS:
            return {
                ...storeProp,
                debitAccounts: data.debitAccounts,
                errors: data.errors,
                idPaperSelectedToBuy: PROP.defaults.idPaperSelectedToBuy,
                isFetching: false,
                papersToBuy: data.similarPaperList,
                selectedPaperToBuy: PROP.defaults.selectedPaperToBuy,
                selectedPaperToSwitch: data.paper,
                timezoneOffset: action.timezoneOffset,
            };
        case TYPE.READ_PAPER_TO_BUY_REQUEST:
            return {
                ...storeProp,
                idPaperSelectedToBuy: idPaper,
                isFetching: true,
            };
        case TYPE.READ_PAPER_TO_BUY_SUCCESS:
            return {
                ...storeProp,
                selectedPaperToBuy: data.paper,
                errors: data.errors,
                isFetching: false,
            };
        case TYPE.PREVIEW_PAPER_REQUEST:
            return {
                ...storeProp,
                disclaimer: action.disclaimer,
                isFetching: true,
            };
        case TYPE.PREVIEW_CANCEL_REQUEST: {
            return {
                ...storeProp,
                mode: MODE.EDIT,
            };
        }
        case TYPE.PREVIEW_PAPER_SUCCESS: {
            const { detail } = data;
            return {
                ...storeProp,
                debitAccountAlias: detail.commissionAccountAlias,
                idDebitAccount: detail.commissionAccountId,
                idPaperSelectedToBuy: detail.buyingIdPaper,
                idPaperSelectedToSwitch: detail.idPaper,
                isFetching: false,
                limitDate: detail.limitDate,
                mode: MODE.PREVIEW,
                orderCompleted: detail.orderCompleted,
                paperToBuyName: detail.buyingPaperName,
                paperToSwitchName: detail.paperName,
            };
        }
        case TYPE.SEND_FORM_PAPER_SUCCESS: {
            return {
                ...storeProp,
                transaction,
                isFetching: false,
                mode: MODE.VIEW,
            };
        }
        case TYPE.TASK_FINISHED:
            return {
                ...storeProp,
                isFetching: false,
                mode: MODE.EDIT,
            };
        case TYPE.SEND_FORM_PAPER_REQUEST:
        case TYPE.SIGN_TRANSACTION_REQUEST:
        case TYPE.READ_TRANSACTION_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.READ_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                transaction,
            };
        case TYPE.CLOSE_TRANSACTION_PREVIEW:
            return {
                ...storeProp,
                fetching: false,
                mode: storeProp.prevMode,
            };
        case TYPE.SELECTED_ID_PAPER:
            return {
                ...storeProp,
                idPaperSelectedToSwitch: idPaper,
            };
        case TYPE.RESET_FORM:
            return {
                ...PROP.defaults,
                idPaperSelectedToSwitch: storeProp.idPaperSelectedToSwitch,
            };
        case TYPE.RESTART_FLOW:
            return PROP.defaults;
        case TYPE.SIGN_TRANSACTION_PREVIEW:
            return {
                ...storeProp,
                fetching: false,
                prevMode: storeProp.mode,
                mode: MODE.PREVIEW,
            };
        case TypesBackoffice.BACKOFFICE_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                transaction,
            };
        default:
            return storeProp;
    }
};
