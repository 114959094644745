import React from "react";

import { replace, routerActions } from "connected-react-router";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router";
import { compose, bindActionCreators } from "redux";

import { SCREENS_BEFORE, EMPTY_STR, SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/Simple";
import {
    SelectorsAction as SelectorsActionBankSelector,
    SelectorsStore as SelectorsStoreBankSelector,
} from "~/store/bankSelector";
import { SelectorsAction as SelectorsActionForm, SelectorsStore as SelectorsStoreForm } from "~/store/form";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import * as i18nUtils from "~/util/i18n";

import HighOrder from "~/components/HighOrder";
import Wrap from "~/pages/_components/Container";

import BankSearchForm from "~/pages/forms/_components/_fields/_bankselector/bankSearch/Form";
import BankSearchList from "~/pages/forms/_components/_fields/_bankselector/bankSearch/List";

import Style from "./_BankSearch.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        isSelecting: false,
    };

    constructor(props) {
        super(props);
        const { dispatch } = props;
        this.boundActionCreators = bindActionCreators(SelectorsActionBankSelector, dispatch);
    }

    handleSelect = (bank) => {
        const { dispatch, idField, match } = this.props;
        const { isSelecting } = this.state;
        const { type, code, bankCountryLabel, bankName } = bank;
        if (!isSelecting) {
            this.setState({ isSelecting: true });
            const debitAccountParam =
                match && match.params && match.params.debitAccount
                    ? `?debitAccount=${match.params.debitAccount}`
                    : null;
            dispatch(routerActions.push(`/form/${match.params.formId}${debitAccountParam}`));
            dispatch(
                SelectorsActionForm.setData({
                    data: { [idField]: { type, code, bank: { bankCountryLabel, bankName } } },
                }),
            );
            dispatch(SelectorsActionBankSelector.bankSelected({ bank, idField }));
        }
    };

    renderForm = (routeProps) => {
        const { data } = this.props;
        return <BankSearchForm {...routeProps} {...data} {...this.boundActionCreators} />;
    };

    renderList = () => {
        const { searchResults, dispatch, idForm } = this.props;
        return (
            <BankSearchList
                {...searchResults}
                {...this.boundActionCreators}
                onSelect={this.handleSelect}
                dispatch={dispatch}
                idForm={idForm}
            />
        );
    };

    handleBack = () => {
        const { dispatch, match, location } = this.props;
        if (location.pathname.includes("/results")) {
            const path = location.pathname.replace("/results", EMPTY_STR);
            dispatch(replace(path));
        } else {
            const param =
                match.params && match.params.debitAccount ? `?debitAccount=${match.params.debitAccount}` : EMPTY_STR;
            dispatch(replace(`/form/transferForeign${param}`));
        }
    };

    goBackToPass = () => {
        const { history, dispatch } = this.props;
        dispatch(SelectorsActionBankSelector.resetSelectedBanks());
        history.go(SCREENS_BEFORE.TWO_LESS);
    };

    render() {
        const { match, location, isDesktop, formNameMap, lang, searchResults, fetching } = this.props;
        const isResultsVisible = location.pathname === `${match.url}/results`;

        const subTitle =
            isResultsVisible && !isDesktop
                ? i18nUtils.get("forms.bankselector.searchResults")
                : i18nUtils.get("forms.bankselector.searchBank");
        // fetching
        return (
            <Container
                name={NAME}
                head-title={formNameMap[lang]}
                wait={fetching}
                head-onClose={this.goBackToPass}
                head-onBackWeb={this.handleBack}
                head-onBack={this.handleBack}
                className={`${Style.CLASS} ${isResultsVisible ? "isResultsVisible" : EMPTY_STR}`}
                scopeToShowNotification={SCOPE.FORM}>
                <Wrap className="container--layout align-items-center">
                    <p className="text-lead">{subTitle}</p>
                </Wrap>
                <Route path={match.url} render={this.renderForm} exact />
                <Route path={`${match.url}/results`} component={this.renderList} />
                {isResultsVisible && !searchResults.items.length && <Redirect to={match.url} />}
            </Container>
        );
    }
}

const mapStateToProps = (state, { match }) => ({
    ...SelectorsStoreForm.getMetadata(state),
    ...SelectorsStoreForm.getField(state, match.params.fieldId),
    fetching: SelectorsStoreForm.getFetching(state),
    lang: SelectorsStoreI18n.getLang(state),
    searchResults: {
        items: SelectorsStoreBankSelector.getBanks(state),
        hasMoreResults: SelectorsStoreBankSelector.getHasMoreResults(state),
    },
});

export default compose(connect(mapStateToProps), HighOrder.Resizable)(Component);
