export function $(...args) {
    return [...args].filter(Boolean).join(" ");
}

const NAMESPACE = { NAME: "UNKNOWN_NAME", TYPE: "UNKNOWN_TYPE" };
const CLASSNAME = "";

export function $class(target, { when: enabled = true, className = CLASSNAME, Namespace = NAMESPACE }) {
    const { NAME, TYPE } = Namespace;
    const name = [[TYPE, NAME].join("--"), target].join("__");
    const names = (className || CLASSNAME).split(" ");
    const index = names.indexOf(name);
    // remove target from className
    if (!enabled && index > -1) {
        names.splice(index, 1);
    }
    // add target from className
    if (enabled && index < 0) {
        names.push(name);
    }
    const result = names.join(" ").trim();
    return result.length ? result : undefined;
}

export function ParseList(list, sep = ",") {
    if (typeof list !== "string") {
        throw new Error(`Expecting a string List, got: ${typeof list}`);
    }
    return list.split(sep).map((item) => item.trim());
}

export function ParseProps(assoc) {
    const list = ParseList(assoc);
    return list.reduce((acc, cur) => {
        const [key, val] = ParseList(cur, " ");
        return {
            ...acc,
            [key]: val,
        };
    }, {});
}

export function Prop(src, key, type = false) {
    if (!src.PROPS) {
        throw new Error(`Given styles, do not contain an PROP export`);
    }
    const val = src.PROPS[key];
    switch (type) {
        case "int":
            return parseInt(val, 10);
        default:
            return val;
    }
}

export default {
    $,
    $class,
};
