import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    changeStatusRequest: (props) => API.executeWithAccessToken(PATH.CHANGE_CREDIT_CARD_STATUS, props),
    detailRequest: (props) => API.executeWithAccessToken(PATH.CREDITCARD_LIST_STATEMENT_DETAILS, props),
    detailsRequest: (props) => API.executeWithAccessToken(PATH.CREDITCARDS_READ, props),
    limitIncreasePreRequest: () => API.executeWithAccessToken(PATH.LIMIT_INCREASE_PRE),
    limitIncreasePreviewRequest: (props) => API.executeWithAccessToken(PATH.LIMIT_INCREASE_PREVIEW, props),
    limitIncreaseSendRequest: (props) => API.executeWithAccessToken(PATH.LIMIT_INCREASE_SEND, props),
    listRequest: () => API.executeWithAccessToken(PATH.CREDITCARDS_LIST),
    movementsRequest: (props) => API.executeWithAccessToken(PATH.CREDITCARDS_LIST_STATEMENTS, props),
    purchaseNotificationPreRequest: () => API.executeWithAccessToken(PATH.PURCHASE_NOTIFICATION_PRE),
    purchaseNotificationPreviewRequest: (props) =>
        API.executeWithAccessToken(PATH.PURCHASE_NOTIFICATION_PREVIEW, props),
    purchaseNotificationSendRequest: (props) => API.executeWithAccessToken(PATH.PURCHASE_NOTIFICATION_SEND, props),
    statementDownloadRequest: (props) => API.executeWithAccessToken(PATH.CREDITCARDS_STATEMENT_DOWNLOAD, props),
    statementsRequest: (props) => API.executeWithAccessToken(PATH.CREDITCARDS_STATEMENTS, props),
    tripOrOnlinePurchaseNotificationPreRequest: () =>
        API.executeWithAccessToken(PATH.TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_PRE),
    tripOrOnlinePurchaseNotificationPreviewRequest: (props) =>
        API.executeWithAccessToken(PATH.TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_PREVIEW, props),
    tripOrOnlinePurchaseNotificationSendRequest: (props) =>
        API.executeWithAccessToken(PATH.TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_SEND, props),
    updateNoteRequest: (props) => API.executeWithAccessToken(PATH.NOTES_EDIT_STATEMENT_NOTE, props),
};

export const SelectorsAction = {
    cancelTransactionCustom: (props) => FactoryAction(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, props),
    changeStatusFailure: () => FactoryAction(TYPE.CHANGE_STATUS_FAILURE),
    changeStatusRequest: (props) => FactoryAction(TYPE.CHANGE_STATUS_REQUEST, props),
    changeStatusSuccess: (props) => FactoryAction(TYPE.CHANGE_STATUS_SUCCESS, props),
    cleanUp: () => FactoryAction(TYPE.CLEAN_UP),
    creditCardMovementsFailure: () => FactoryAction(TYPE.CREDIT_CARD_MOVEMENTS_FAILURE),
    creditCardMovementsRequest: (props) => FactoryAction(TYPE.CREDIT_CARD_MOVEMENTS_REQUEST, props),
    creditCardMovementsSuccess: (props) => FactoryAction(TYPE.CREDIT_CARD_MOVEMENTS_SUCCESS, props),
    creditCardStatementDownloadFailure: () => FactoryAction(TYPE.CREDIT_CARD_STATEMENT_DOWNLOAD_FAILURE),
    creditCardStatementDownloadRequest: (props) => FactoryAction(TYPE.CREDIT_CARD_STATEMENT_DOWNLOAD_REQUEST, props),
    creditCardStatementDownloadSuccess: () => FactoryAction(TYPE.CREDIT_CARD_STATEMENT_DOWNLOAD_SUCCESS),
    creditCardStatementsFailure: () => FactoryAction(TYPE.CREDIT_CARD_STATEMENTS_FAILURE),
    creditCardStatementsRequest: (props) => FactoryAction(TYPE.CREDIT_CARD_STATEMENTS_REQUEST, props),
    creditCardStatementsSuccess: (props) => FactoryAction(TYPE.CREDIT_CARD_STATEMENTS_SUCCESS, props),
    detailFailure: () => FactoryAction(TYPE.DETAILS_FAILURE),
    detailFailureMovement: () => FactoryAction(TYPE.DETAILS_FAILURE_MOVEMENT),
    detailRequest: (props) => FactoryAction(TYPE.DETAILS_REQUEST, props),
    detailRequestMovement: (props) => FactoryAction(TYPE.DETAILS_REQUEST_MOVEMENT, props),
    detailSuccess: (props) => FactoryAction(TYPE.DETAILS_SUCCESS, props),
    detailSuccessMovement: (props) => FactoryAction(TYPE.DETAILS_SUCCESS_MOVEMENT, props),
    fetchMoreMovementsFailure: () => FactoryAction(TYPE.FETCH_MORE_MOVEMENTS_FAILURE),
    fetchMoreMovementsRequest: (props) => FactoryAction(TYPE.FETCH_MORE_MOVEMENTS_REQUEST, props),
    fetchMoreMovementsSuccess: (props) => FactoryAction(TYPE.FETCH_MORE_MOVEMENTS_SUCCESS, props),
    hideOptions: () => FactoryAction(TYPE.HIDE_OPTIONS),
    limitIncreasePreFailure: () => FactoryAction(TYPE.LIMIT_INCREASE_PRE_FAILURE),
    limitIncreasePreRequest: () => FactoryAction(TYPE.LIMIT_INCREASE_PRE_REQUEST),
    limitIncreasePreSuccess: (props) => FactoryAction(TYPE.LIMIT_INCREASE_PRE_SUCCESS, props),
    limitIncreasePreviewFailure: () => FactoryAction(TYPE.LIMIT_INCREASE_PREVIEW_FAILURE),
    limitIncreasePreviewRequest: (props) => FactoryAction(TYPE.LIMIT_INCREASE_PREVIEW_REQUEST, props),
    limitIncreasePreviewSuccess: (props) => FactoryAction(TYPE.LIMIT_INCREASE_PREVIEW_SUCCESS, props),
    limitIncreaseSendFailure: () => FactoryAction(TYPE.LIMIT_INCREASE_SEND_FAILURE),
    limitIncreaseSendRequest: (props) => FactoryAction(TYPE.LIMIT_INCREASE_SEND_REQUEST, props),
    limitIncreaseSendSuccess: (props) => FactoryAction(TYPE.LIMIT_INCREASE_SEND_SUCCESS, props),
    listFailure: () => FactoryAction(TYPE.LIST_FAILURE),
    listRequest: (props) => FactoryAction(TYPE.LIST_REQUEST, props),
    listSuccess: (props) => FactoryAction(TYPE.LIST_SUCCESS, props),
    movementsFailure: () => FactoryAction(TYPE.MOVEMENTS_FAILURE),
    movementsRequest: (props) => FactoryAction(TYPE.MOVEMENTS_REQUEST, props),
    movementsSuccess: (props) => FactoryAction(TYPE.MOVEMENTS_SUCCESS, props),
    purchaseNotificationPreFailure: () => FactoryAction(TYPE.PURCHASE_NOTIFICATION_PRE_FAILURE),
    purchaseNotificationPreRequest: () => FactoryAction(TYPE.PURCHASE_NOTIFICATION_PRE_REQUEST),
    purchaseNotificationPreSuccess: (props) => FactoryAction(TYPE.PURCHASE_NOTIFICATION_PRE_SUCCESS, props),
    purchaseNotificationPreviewFailure: () => FactoryAction(TYPE.PURCHASE_NOTIFICATION_PREVIEW_FAILURE),
    purchaseNotificationPreviewRequest: (props) => FactoryAction(TYPE.PURCHASE_NOTIFICATION_PREVIEW_REQUEST, props),
    purchaseNotificationPreviewSuccess: (props) => FactoryAction(TYPE.PURCHASE_NOTIFICATION_PREVIEW_SUCCESS, props),
    purchaseNotificationSendFailure: () => FactoryAction(TYPE.PURCHASE_NOTIFICATION_SEND_FAILURE),
    purchaseNotificationSendRequest: (props) => FactoryAction(TYPE.PURCHASE_NOTIFICATION_SEND_REQUEST, props),
    purchaseNotificationSendSuccess: (props) => FactoryAction(TYPE.PURCHASE_NOTIFICATION_SEND_SUCCESS, props),
    readCreditCardFailure: () => FactoryAction(TYPE.READ_CREDIT_CARD_FAILURE),
    readCreditCardRequest: (props) => FactoryAction(TYPE.READ_CREDIT_CARD_REQUEST, props),
    readCreditCardSuccess: (props) => FactoryAction(TYPE.READ_CREDIT_CARD_SUCCESS, props),
    readTransactionFailure: (props) => FactoryAction(TYPE.READ_TRANSACTION_FAILURE, props),
    readTransactionRequest: (props) => FactoryAction(TYPE.READ_TRANSACTION_REQUEST, props),
    readTransactionSuccess: (props) => FactoryAction(TYPE.READ_TRANSACTION_SUCCESS, props),
    showOptions: () => FactoryAction(TYPE.SHOW_OPTIONS),
    signTransaction: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_REQUEST, props),
    signTransactionFailure: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_FAILURE, props),
    signTransactionPreview: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_PREVIEW, props),
    signTransactionSuccess: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_SUCCESS, props),
    toStepOne: () => FactoryAction(TYPE.FORM_TO_STEP_1),
    tripOrOnlinePurchaseNotificationPreFailure: () =>
        FactoryAction(TYPE.TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_PRE_FAILURE),
    tripOrOnlinePurchaseNotificationPreRequest: () =>
        FactoryAction(TYPE.TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_PRE_REQUEST),
    tripOrOnlinePurchaseNotificationPreSuccess: (props) =>
        FactoryAction(TYPE.TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_PRE_SUCCESS, props),
    tripOrOnlinePurchaseNotificationPreviewFailure: () =>
        FactoryAction(TYPE.TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_PREVIEW_FAILURE),
    tripOrOnlinePurchaseNotificationPreviewRequest: (props) =>
        FactoryAction(TYPE.TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_PREVIEW_REQUEST, props),
    tripOrOnlinePurchaseNotificationPreviewSuccess: (props) =>
        FactoryAction(TYPE.TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_PREVIEW_SUCCESS, props),
    tripOrOnlinePurchaseNotificationSendFailure: () =>
        FactoryAction(TYPE.TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_SEND_FAILURE),
    tripOrOnlinePurchaseNotificationSendRequest: (props) =>
        FactoryAction(TYPE.TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_SEND_REQUEST, props),
    tripOrOnlinePurchaseNotificationSendSuccess: (props) =>
        FactoryAction(TYPE.TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_SEND_SUCCESS, props),
    updateNoteFailure: () => FactoryAction(TYPE.UPDATE_NOTE_FAILURE),
    updateNoteRequest: (props) => FactoryAction(TYPE.UPDATE_NOTE_REQUEST, props),
    updateNoteSuccess: (props) => FactoryAction(TYPE.UPDATE_NOTE_SUCCESS, props),
};

export const SelectorsStore = {
    getCreditCard: (store) => store[NAME].creditCard,
    getCreditCardStatements: (store) => store[NAME].statements,
    getCreditCardList: (store) => store[NAME].creditCardList,
    getDetail: (store) => store[NAME].detail,
    getDownloading: (store) => store[NAME].isDownloading,
    getFetching: (store) => store[NAME].isFetching,
    getFetchingCards: (store) => store[NAME].creditCards.isFetching,
    getLimitIncreaseFormData: (store) => store[NAME].limitIncrease,
    getList: (store) => store[NAME].creditCards.list,
    getMode: (store) => store[NAME].mode,
    getMovement: (store) => store[NAME].creditCardMovementDetails.detail,
    getMovements: (store) => store[NAME].movements,
    getPurchaseNotificationFormData: (store) => store[NAME].purchaseData,
    getPageNumber: (store) => store[NAME].pageNumber,
    getTimezoneOffset: (store) => store[NAME].timezoneOffset,
    getTransaction: (store) => store[NAME].transaction,
    getTripNoticeFormData: (store) => store[NAME].tripOrOnlinePurchase,
    getVisaMasterAccounts: (store) => store[NAME].visaMasterAccounts,
    isChangingStatusCreditCard: (store) => store[NAME].isChangingStatusCreditCard,
    isFetching: (store) => store[NAME].creditCardMovementDetails.isFetching,
    isFetchingMovements: (store) => store[NAME].isFetchingMovements,
    isFetchingMovementsByCreditCard: (store) => store[NAME].isFetchingMovementsByCreditCard,
    isHasMoreMovements: (store) => store[NAME].hasMoreMovements,
    isOptionsVisible: (store) => store[NAME].isOptionsVisible,
};
