import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    listPapers: () => API.executeWithAccessToken(PATH.LIST_PAPERS),
    readPaperDetails: (props) => API.executeWithAccessToken(PATH.READ_PAPER_DETAILS, props),
    readPaperChartValues: (props) => API.executeWithAccessToken(PATH.READ_PAPER_CHART_VALUES, props),
    downloadFamilyDescription: (props) => API.executeWithAccessToken(PATH.DOWNLOAD_FAMILY_DESCRIPTON, props),
};

export const SelectorsAction = {
    requestListFundPapers: (props) => FactoryAction(TYPE.LIST_PAPERS_REQUEST, props),
    requestListFundPaperSuccess: (props) => FactoryAction(TYPE.LIST_PAPERS_SUCCESS, props),
    requestListFundPaperFailure: () => FactoryAction(TYPE.LIST_PAPERS_FAILURE),
    updateFilters: (props) => FactoryAction(TYPE.UPDATE_FILTERS, props),
    resetFilters: (props) => FactoryAction(TYPE.RESET_FILTERS, props),
    requestPaperDetails: (props) => FactoryAction(TYPE.PAPER_DETAILS_REQUEST, props),
    requestPaperDetailsSuccess: (props) => FactoryAction(TYPE.PAPER_DETAILS_SUCCESS, props),

    chartValuesRequest: (props) => FactoryAction(TYPE.READ_PAPER_CHART_VALUES_REQUEST, props),
    chartValuesFailure: () => FactoryAction(TYPE.READ_PAPER_CHART_VALUES_FAILURE),
    chartValuesSuccess: (props) => FactoryAction(TYPE.READ_PAPER_CHART_VALUES_SUCCESS, props),
    resetChartValues: () => FactoryAction(TYPE.RESET_CHART_VALUES),

    getFamilyProductDescription: (props) => FactoryAction(TYPE.FAMILY_PRODUCT_DESCRIPTION_REQUEST, props),
};

export const SelectorsStore = {
    getFundPapersList: (store) => store[NAME].fundPapers,
    getFundFamiliesList: (store) => store[NAME].familiesList,
    getCategoriesList: (store) => store[NAME].categoriesList,
    getFetching: (store) => store[NAME].fetching,
    getFilters: (store) => store[NAME].filters,
    getPaperDetail: (store) => store[NAME].paperDetail,
    getFormData: (store) => store[NAME].formData,
    getChartPeriods: (store) => store[NAME].chartPeriods,
    getChartValues: (store) => store[NAME].chartValues,
    getFetchingChartValues: (store) => store[NAME].fetchingChartValues,
};
