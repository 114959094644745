import React from "react";

import { push as Push } from "connected-react-router";
import { Field, Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import { CREDENTIAL_TYPE_OTP, EMPTY_STR, SCOPE, LEVEL, VALIDATION_ERROR } from "~/constants";
import Container from "~/containers/External/Form";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import {
    SelectorsAction as SelectorsActionRecoverPassword,
    SelectorsStore as SelectorsStoreRecoverPassword,
} from "~/store/recoverypassword";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import TextField from "~/pages/_components/fields/TextField";
import Credential from "~/pages/_components/fields/credentials/Credential";

import Style from "./RecoveryPass.scss";

export const NAME = "RecoveryPassStep1";
const { CLASS } = Style;
const FORM_ID = "recoveryPassword.step1";
export const PROP = {
    types: {
        isFetching: PropTypes.bool,
    },
    defaults: {
        isFetching: false,
    },
};
export function Component(props) {
    const { isFetching, dispatch, errors } = props;
    const onHeaderClose = () => {
        dispatch(Push("/login"));
    };
    const handleLinkClick = () => {
        dispatch(Push("/recoveryPass/step2"));
    };

    React.useEffect(() => {
        if (Object.keys(errors).length > 0) {
            dispatch(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get(`returnCode.${VALIDATION_ERROR}`),

                    level: LEVEL.ERROR,

                    scopes: [SCOPE.RECOVERY_PASSWORD],
                }),
            );
        }
    }, [dispatch, errors]);

    return (
        <Container
            className={CLASS}
            head-onBack={onHeaderClose}
            head-title={`${FORM_ID}.pageTitle`}
            icon={<Image src="administration-title-icon.svg" />}
            id={NAME}
            name={NAME}
            wait={isFetching}
            scopeToShowNotification={SCOPE.RECOVERY_PASSWORD}>
            <section>
                <p>
                    <I18n id={`${FORM_ID}.intro`} />
                </p>
                <Button className="link" onClick={() => handleLinkClick()}>
                    <I18n id={`${FORM_ID}.alreadyHaveTheCode`} />
                </Button>
            </section>
            <Form>
                <div>
                    <Field
                        autoComplete="off"
                        autoFocus
                        component={TextField}
                        id="username"
                        idForm={FORM_ID}
                        name="username"
                        placeholder={EMPTY_STR}
                    />
                </div>
                <div>
                    <Field
                        autoComplete="off"
                        component={TextField}
                        id="email"
                        idForm={FORM_ID}
                        name="email"
                        placeholder={EMPTY_STR}
                    />
                </div>
                <div>
                    <span className="token">
                        <Field
                            autoComplete="off"
                            component={Credential}
                            hidePlaceholder
                            id="otp"
                            idForm={FORM_ID}
                            name="otp"
                            type={CREDENTIAL_TYPE_OTP}
                        />
                        <div className="tooltip">
                            <span className="tooltip-text">{UtilsI18n.get("recoverPassword.token.tooltip")}</span>
                        </div>
                    </span>
                </div>
                <div>
                    <Button className="primary" type="submit">
                        {UtilsI18n.get("global.continue")}
                    </Button>
                </div>
            </Form>
        </Container>
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
const mapStateToProps = (store) => ({
    isFetching: SelectorsStoreRecoverPassword.isFetching(store),
});
export default Compose(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            username: EMPTY_STR,
            email: EMPTY_STR,
            otp: EMPTY_STR,
        }),
        validationSchema: ({ isTrusted }) =>
            Yup.lazy(() => {
                return Yup.object().shape({
                    username: Yup.string().required(UtilsI18n.get(`${FORM_ID}.username.required`)),
                    email: Yup.string()
                        .trim()
                        .email(UtilsI18n.get("global.userconfiguration.emailIncorrectFormat"))
                        .required(UtilsI18n.get("global.userconfiguration.emailEmpty")),
                    otp: !isTrusted ? Yup.string().required(UtilsI18n.get(`${FORM_ID}.otp.required`)) : Yup.string(),
                });
            }),
        handleSubmit: (data, formikBag) => {
            const { dispatch } = formikBag.props;
            const { email, otp, username } = data;
            dispatch(SelectorsActionRecoverPassword.recoverPassStep1Request({ formikBag, username, email, otp }));
        },
    }),
)(Component);
