import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { CDP_CURRENCY } from "~/constants";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import * as UtilsI18n from "~/util/i18n";

export const NAME = "ExtraData";

export const PROP = {
    types: {
        detail: PropTypes.arrayOf({
            documentType: PropTypes.string,
            documentNumber: PropTypes.string,
            currency: PropTypes.string,
        }).isRequired,
    },
    defaults: {},
};

/**
 * Componente utilitario que permite renderizar los datos extra que se muestran para cada linea, para cada proveedor.
 */

export function Component(props) {
    const {
        detail: {
            docType,
            docNumber,
            currency,
            addressStreet,
            addressPostalCode,
            addressState,
            addressCity,
            addressCountry,
            phone,
            email,
            contactPerson,
        },
        i18n,
    } = props;
    return (
        <React.Fragment>
            <div>
                <label>{i18n.document}</label>
                <div>
                    {docType} {docNumber}
                </div>
            </div>
            <div>
                <label>{i18n.currency}</label>
                <Currency curr={currency} />
            </div>
            <div>
                <label>{i18n.address}</label>
                <div>{addressStreet}</div>
            </div>
            <div>
                <label>{i18n.zipCode}</label>
                <div>{addressPostalCode}</div>
            </div>
            <div>
                <label>{i18n.department}</label>
                <div>{addressState}</div>
            </div>
            <div>
                <label>{i18n.city}</label>
                <div>{addressCity}</div>
            </div>
            <div>
                <label>{i18n.country}</label>
                <div>{UtilsI18n.get(`country.name.${addressCountry}`)}</div>
            </div>
            <div>
                <label>{i18n.contactPhone}</label>
                <div>{phone}</div>
            </div>
            <div>
                <label>{i18n.email}</label>
                <div>{email}</div>
            </div>
            <div>
                <label>{i18n.contactPerson}</label>
                <div>{contactPerson}</div>
            </div>
        </React.Fragment>
    );

    function Currency({ curr }) {
        return (
            <div>
                {curr.toString() === CDP_CURRENCY.UI ? (
                    <React.Fragment>
                        {`${UtilsI18n.get(`currency.label.${CDP_CURRENCY.UYU}`)},
                        ${UtilsI18n.get(`currency.label.${CDP_CURRENCY.USD}`)}`}
                    </React.Fragment>
                ) : (
                    UtilsI18n.get(`currency.label.${curr}`)
                )}
            </div>
        );
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        i18n: {
            document: StoreI18n.getMessage(store, "client.factoring.uploadSuppliers.extraData.document"),
            currency: StoreI18n.getMessage(store, "client.factoring.uploadSuppliers.extraData.currency"),
            address: StoreI18n.getMessage(store, "client.factoring.uploadSuppliers.extraData.address"),
            zipCode: StoreI18n.getMessage(store, "client.factoring.uploadSuppliers.extraData.zipCode"),
            department: StoreI18n.getMessage(store, "client.factoring.uploadSuppliers.extraData.department"),
            city: StoreI18n.getMessage(store, "client.factoring.uploadSuppliers.extraData.city"),
            country: StoreI18n.getMessage(store, "client.factoring.uploadSuppliers.extraData.country"),
            contactPhone: StoreI18n.getMessage(store, "client.factoring.uploadSuppliers.extraData.contactPhone"),
            fax: StoreI18n.getMessage(store, "client.factoring.uploadSuppliers.extraData.fax"),
            contactType: StoreI18n.getMessage(store, "client.factoring.uploadSuppliers.extraData.contactType"),
            email: StoreI18n.getMessage(store, "client.factoring.uploadSuppliers.extraData.email"),
            contactPerson: StoreI18n.getMessage(store, "client.factoring.uploadSuppliers.extraData.contactPerson"),
        },
    };
};

export default Connect(mapStateToProps)(Component);
