import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { withFormik as WithFormik, Form, Field } from "formik";
import PropTypes from "prop-types";
import { Grid, Row, Col } from "react-bootstrap";
import { connect as Connect } from "react-redux";
import * as Yup from "yup";

import { EMPTY_STR, SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/Simple";
import { SelectorsAction as SelectorsActionSettings } from "~/store/settings";
import * as ConfigUtils from "~/util/config";
import * as I18nUtils from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import PasswordRules from "~/pages/_components/PasswordRules";
import Credential from "~/pages/_components/fields/credentials/Credential";

import Style from "./ChangePassword.rules.scss";

export const NAME = "ChangePassword";

export const PROP = {
    types: {
        ...TypesRedux,
        isSubmitting: PropTypes.bool.isRequired,
    },
    defaults: {
        ...DefaultsRedux,
    },
};

const FORM_ID = "settings.changePassword";

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    handleHeaderBack = () => {
        const { dispatch } = this.props;
        dispatch(GoBack());
    };

    render() {
        const { isSubmitting } = this.props;
        const maxLength = ConfigUtils.getInteger("core.password.maxLength");

        return (
            <Container
                name={NAME}
                className={Style.CLASS}
                head-title="settings.changePassword"
                head-onBackWeb={this.handleHeaderBack}
                head-onClose={this.handleHeaderBack}
                wait={isSubmitting}
                scopeToShowNotification={SCOPE.CHANGE_PASSWORD}>
                <Form className="above-the-fold">
                    <section className="container--layout flex-grow align-items-center">
                        <Grid className="form-content">
                            <Row className="justify-content-center">
                                <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                    <p className="text-lead">
                                        <I18n id="settings.changePassword.textInfo" />
                                    </p>
                                    <Field
                                        name="password"
                                        idForm={FORM_ID}
                                        component={Credential}
                                        placeholder={EMPTY_STR}
                                    />

                                    <Field
                                        name="newPassword"
                                        idForm={FORM_ID}
                                        component={Credential}
                                        showStrength
                                        maxLength={maxLength}
                                        placeholder={EMPTY_STR}
                                    />

                                    <Field
                                        name="newPasswordConfirmation"
                                        idForm={FORM_ID}
                                        component={Credential}
                                        maxLength={maxLength}
                                        placeholder={EMPTY_STR}
                                    />
                                    <PasswordRules>
                                        <I18n
                                            id="global.passwordRules.rule1"
                                            MIN_LENGTH={ConfigUtils.get("core.password.minLength")}
                                        />
                                        <I18n id="global.passwordRules.rule2" />
                                        <I18n id="global.passwordRules.rule3" />
                                    </PasswordRules>
                                </Col>
                            </Row>
                        </Grid>
                    </section>
                    <section className="container--layout">
                        <Grid className="form-content">
                            <Row className="justify-content-center">
                                <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                    <Button
                                        label="global.modify"
                                        loading={isSubmitting}
                                        type="submit"
                                        bsStyle="primary"
                                    />
                                </Col>
                            </Row>
                        </Grid>
                    </section>
                </Form>
            </Container>
        );
    }
}

export default HighOrder(
    Connect(null),
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            password: EMPTY_STR,
            newPassword: EMPTY_STR,
            newPasswordConfirmation: EMPTY_STR,
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    password: Yup.string().trim().required(I18nUtils.get("global.password.required")),
                    newPassword: Yup.string()
                        .required(I18nUtils.get("global.newPassword.required"))
                        .min(
                            ConfigUtils.get("core.password.minLength"),
                            I18nUtils.get("global.password.minLengthTooShort"),
                        ),
                    newPasswordConfirmation: Yup.string()
                        .required(I18nUtils.get("global.newPasswordConfirmation.required"))
                        .oneOf([values.newPassword], I18nUtils.get("global.newPasswordConfirmation.notEquals")),
                }),
            ),
        handleSubmit: (values, formikBag) => {
            const { password, newPassword, newPasswordConfirmation } = values;
            formikBag.props.dispatch(
                SelectorsActionSettings.changePassword({
                    password,
                    newPassword,
                    newPasswordConfirmation,
                    formikBag,
                }),
            );
        },
    }),
)(Component);
