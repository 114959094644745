import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    verifyBiometric: (props) => API.executeWithAccessToken(PATH.VERIFY_BIOMETRIC, props),
    registerBiometric: (props) => API.executeWithAccessToken(PATH.REGISTER_BIOMETRIC, props),
    validatePasswordWithAccessToken: (props) => API.executeWithAccessToken(PATH.VALIDATE_PASSWORD, props),
    validatePasswordAnonymous: (props) => API.executeAnonymous(PATH.VALIDATE_PASSWORD, props),
    faceAuthentication: (props) => API.executeAnonymous(PATH.FACE_AUTHENTICATION, props),
    faceRegisterEnrollment: (props) => {
        const { _exchangeToken } = props;
        return API.executeWithExchangeToken(PATH.FACE_REGISTER_ENROLLMENT, props, _exchangeToken);
    },
    faceRegisterMigration: (props) => API.executeWithAccessToken(PATH.FACE_REGISTER_MIGRATION, props),
};

export const SelectorsAction = {
    verifyBiometric: (props) => FactoryAction(TYPE.VERIFY_BIOMETRIC_REQUEST, props),
    errorVerifyBiometric: (props) => FactoryAction(TYPE.VERIFY_BIOMETRIC_ERROR, props),
    cancelVerifyBiometric: (props) => FactoryAction(TYPE.VERIFY_BIOMETRIC_CANCEL, props),
    successVerifyBiometric: (props) => FactoryAction(TYPE.VERIFY_BIOMETRIC_SUCCESS, props),

    registerBiometric: (props) => FactoryAction(TYPE.REGISTER_BIOMETRIC_REQUEST, props),
    errorRegisterBiometric: (props) => FactoryAction(TYPE.REGISTER_BIOMETRIC_ERROR, props),
    successRegisterBiometric: (props) => FactoryAction(TYPE.REGISTER_BIOMETRIC_SUCCESS, props),

    registerBiometricFacephi: (props) => FactoryAction(TYPE.REGISTER_BIOMETRIC_FACEPHI_REQUEST, props),
    errorRegisterBiometricFacephi: (props) => FactoryAction(TYPE.REGISTER_BIOMETRIC_FACEPHI_ERROR, props),
    successRegisterBiometricFacephi: (props) => FactoryAction(TYPE.REGISTER_BIOMETRIC_FACEPHI_SUCCESS, props),

    validatePassword: (props) => FactoryAction(TYPE.VALIDATE_PASSWORD_REQUEST, props),
    errorValidatePassword: (props) => FactoryAction(TYPE.VALIDATE_PASSWORD_ERROR, props),
    successValidatePassword: (props) => FactoryAction(TYPE.VALIDATE_PASSWORD_SUCCESS, props),

    showPasswordModal: (props) => FactoryAction(TYPE.SHOW_PASSWORD_MODAL, props),
    hidePasswordModal: (props) => FactoryAction(TYPE.HIDE_PASSWORD_MODAL, props),
    noEnrolledFingerPrint: (props) => FactoryAction(TYPE.NO_ENROLLED_FINGERPRINT, props),

    verifyFacephiChangeMyPhone: (props) => FactoryAction(TYPE.VERIFY_FACEPHI_CHANGE_MY_PHONE_REQUEST, props),

    generateWebToken: (props) => FactoryAction(TYPE.GENERATE_WEB_TOKEN, props),

    selectBiometricType: (props) => FactoryAction(TYPE.SELECT_BIOMETRIC_TYPE, props),

    cleanUp: (props) => FactoryAction(TYPE.CLEAN_UP, props),
    retryAuthNumber: (props) => FactoryAction(TYPE.RETRY_AUTH_NUMBER, props),
};

export const SelectorsStore = {
    fetching: (store) => store[NAME].fetching,
    askPassword: (store) => store[NAME].askPassword,
    statusVerifyBiometric: (store) => store[NAME].statusVerifyBiometric,
    biometricType: (store) => store[NAME].biometricType,
    isFacephi: (store) => store[NAME].isFacephi,
    retryAuthenticationNumber: (store) => store[NAME].retryAuthenticationNumber,
    pathFrom: (store) => store[NAME].pathFrom,
};
