import React from "react";

import { routerActions } from "connected-react-router";

import { SelectorsAction as SelectorsActionBankSelector } from "~/store/bankSelector";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import * as i18nUtils from "~/util/i18n";

import BankSearchDetailedItem from "~/pages/forms/_components/_fields/_bankselector/bankSearch/DetailedItem";

export const NAME = "bankSearchOuterComponents";

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(BaseComponent) {
    return class _Component extends React.Component {
        static displayName = NAME;

        static defaultProps = PROP.defaults;

        static propTypes = PROP.types;

        componentWillUnmount() {
            const { history, match, dispatch } = this.props;

            if (history.location.pathname.indexOf(match.url) === -1) {
                dispatch(SelectorsActionBankSelector.resetSelectedBanks());
            }
        }

        handleClick = () => {
            const { dispatch, match, field, form } = this.props;
            const {
                value: { type },
            } = field || {};

            const debitAccount =
                form.values && form.values.debitAccount && form.values.debitAccount.value
                    ? form.values.debitAccount.value
                    : null;

            dispatch(SelectorsActionBankSelector.setNetworkType({ networkType: type }));
            dispatch(routerActions.push(`${match.url}/bankSearch/${field.name}/${debitAccount}`));
            dispatch(SelectorsActionForm.setData({ data: form.values }));
        };

        render() {
            const { selectedBank, formMode, errorSelectingBank } = this.props;

            return (
                <React.Fragment>
                    <BaseComponent {...this.props} />
                    {formMode === "edit" && (
                        <div className="form-group">
                            {`${i18nUtils.get("forms.bankselector.searchText")} `}
                            <a onClick={this.handleClick}>{i18nUtils.get("forms.bankselector.searchLink")}</a>
                            {selectedBank && !errorSelectingBank && <BankSearchDetailedItem {...selectedBank} />}
                        </div>
                    )}
                </React.Fragment>
            );
        }
    };
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
