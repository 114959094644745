import { LOCATION_CHANGE } from "connected-react-router";

import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { TYPE as TypesBackoffice } from "~/store/backoffice";

import { TYPE as TYPE_COMEX } from "../_consts";
import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            return {
                ...PROP.defaults,
                creditLetterOpen: {
                    ...PROP.defaults.creditLetterOpen,
                    // formData: storeProp.creditLetterOpen.formData,
                },
            };
        }
        case TYPE.RESTART_FLOW:
            return PROP.defaults;
        // Request Bonds And Guarantees
        case TYPE.REQUEST_BONDS_AND_GUARANTEES_PRE_REQUEST:
        case TYPE.REQUEST_BONDS_AND_GUARANTEES_SEND_REQUEST:
        case TYPE_COMEX.READ_TRANSACTION_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.REQUEST_BONDS_AND_GUARANTEES_PREVIEW_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                requestBondsAndGuarantees: {
                    ...storeProp.requestBondsAndGuarantees,
                    prevData: action.formData,
                },
            };
        case TYPE.REQUEST_BONDS_AND_GUARANTEES_PRE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,

                requestBondsAndGuarantees: {
                    ...storeProp.requestBondsAndGuarantees,
                    preData: action,
                },
            };
        case TYPE.REQUEST_BONDS_AND_GUARANTEES_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                mode: MODE.PREVIEW,
                fetching: false,
                requestBondsAndGuarantees: {
                    ...storeProp.requestBondsAndGuarantees,
                    formData: storeProp.requestBondsAndGuarantees.prevData,
                },
                prevMode: storeProp.mode,
            };

        case TYPE.REQUEST_BONDS_AND_GUARANTEES_SEND_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                transaction: action.transaction,
                prevMode: storeProp.mode,
            };

        case TYPE.REQUEST_BONDS_AND_GUARANTEES_SEND_FAILURE:
        case TYPE.REQUEST_BONDS_AND_GUARANTEES_PRE_FAILURE:
        case TYPE.REQUEST_BONDS_AND_GUARANTEES_PREVIEW_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };

        // Modify Bonds And Guarantees
        case TYPE.MODIFY_BONDS_AND_GUARANTEES_PRE_REQUEST:
        case TYPE.MODIFY_BONDS_AND_GUARANTEES_SEND_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.MODIFY_BONDS_AND_GUARANTEES_PREVIEW_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                modifyBondsAndGuarantees: {
                    ...storeProp.modifyBondsAndGuarantees,
                    prevData: action.formData,
                },
            };
        case TYPE.MODIFY_BONDS_AND_GUARANTEES_PRE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,

                modifyBondsAndGuarantees: {
                    ...storeProp.modifyBondsAndGuarantees,
                    preData: action,
                },
            };
        case TYPE.MODIFY_BONDS_AND_GUARANTEES_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                mode: MODE.PREVIEW,
                fetching: false,

                modifyBondsAndGuarantees: {
                    ...storeProp.modifyBondsAndGuarantees,
                    formData: storeProp.modifyBondsAndGuarantees.prevData,
                },
            };

        case TYPE.MODIFY_BONDS_AND_GUARANTEES_SEND_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };

        case TYPE.MODIFY_BONDS_AND_GUARANTEES_SEND_FAILURE:
        case TYPE.MODIFY_BONDS_AND_GUARANTEES_PRE_FAILURE:
        case TYPE.MODIFY_BONDS_AND_GUARANTEES_PREVIEW_FAILURE:
        case TYPE_COMEX.READ_TRANSACTION_FAILURE:
        case TYPE_COMEX.SIGN_TRANSACTION_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.DRAFT_LOADED:
            return {
                ...storeProp,
                draftLoaded: true,
            };
        case TYPE_COMEX.CLOSE_TRANSACTION_PREVIEW:
            return {
                ...storeProp,
                mode: storeProp.prevMode,
            };
        case TYPE_COMEX.COMEX_FORM_TO_STEP_1:
            return {
                ...storeProp,
                draftLoaded: storeProp.draftLoaded, // Se mantiene el valor
                mode: MODE.EDIT,
            };

        case TYPE_COMEX.READ_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                transaction: action.transaction,
                draftLoaded: false,
                fetching: false,
                mode: action.transaction.idTransactionStatus === STATUS.DRAFT ? MODE.EDIT : MODE.VIEW,
            };
        case TYPE_COMEX.SIGN_TRANSACTION_PREVIEW:
            return {
                ...storeProp,
                prevMode: storeProp.mode,
                mode: MODE.PREVIEW,
                requestBondsAndGuarantees: {
                    ...storeProp.requestBondsAndGuarantees,
                    formData: action.data,
                },
            };
        case TYPE_COMEX.SIGN_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                mode: MODE.VIEW,
                prevMode: storeProp.mode,
                transaction: action.transaction,
            };
        case TYPE.COMEX_FORM_SEND_DATA_FAILURE:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.EDIT,
            };
        case TypesBackoffice.BACKOFFICE_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                prevMode: storeProp.mode,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };
        default:
            return storeProp;
    }
};
