import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

const formatDate = (date) => date.toISOString().split("T")[0];

export const SelectorsMiddleware = {
    listPaymentOrdersRequest: (props) => {
        const { offset, isExport, filters } = props;
        let { issueDateFrom, issueDateTo, dueDateFrom, dueDateTo } = filters;
        const { currency, status, paymentOrder, supplierName } = filters;

        issueDateFrom = issueDateFrom ? formatDate(issueDateFrom) : null;
        issueDateTo = issueDateTo ? formatDate(issueDateTo) : null;
        dueDateFrom = dueDateFrom ? formatDate(dueDateFrom) : null;
        dueDateTo = dueDateTo ? formatDate(dueDateTo) : null;

        return API.executeWithAccessToken(PATH.PAYMENT_ORDERS_LIST, {
            offset,
            isExport,
            currency,

            issueDateFrom,
            issueDateTo,
            dueDateFrom,
            dueDateTo,
            status,
            paymentOrder,
            supplierName,
        });
    },
    listSuppliersRequest: (props) => {
        const { pageNumber, filters } = props;
        const { name, documentType, documentNumber } = filters;

        return API.executeWithAccessToken(PATH.SUPPLIERS_LIST, {
            pageNumber,
            documentType,
            documentNumber,
            name,
        });
    },
    exportPaymentOrdersRequest: (props) => {
        const { offset, isExport, filters } = props;
        let { issueDateFrom, issueDateTo, dueDateFrom, dueDateTo } = filters;
        const { currency, status, paymentOrder, supplierName } = filters;

        issueDateFrom = issueDateFrom ? formatDate(issueDateFrom) : null;
        issueDateTo = issueDateTo ? formatDate(issueDateTo) : null;
        dueDateFrom = dueDateFrom ? formatDate(dueDateFrom) : null;
        dueDateTo = dueDateTo ? formatDate(dueDateTo) : null;

        return API.executeWithAccessToken(PATH.EXPORT_PAYMENT_ORDERS_LIST, {
            offset,
            isExport,
            currency,
            issueDateFrom,
            issueDateTo,
            dueDateFrom,
            dueDateTo,
            status,
            paymentOrder,
            supplierName,
        });
    },
};
export const SelectorsAction = {
    loadListPaymentOrdersRequest: (props) => FactoryAction(TYPE.LOAD_LIST_PAYMENT_ORDERS_REQUEST, props),
    listPaymentOrdersFailure: () => FactoryAction(TYPE.LOAD_LIST_PAYMENT_ORDERS_FAILURE),
    listPaymentOrdersSuccess: (props) => FactoryAction(TYPE.LOAD_LIST_PAYMENT_ORDERS_SUCCESS, props),
    resetFilters: () => FactoryAction(TYPE.RESET_FILTERS),
    loadListSuppliersRequest: (props) => FactoryAction(TYPE.LOAD_LIST_SUPPLIERS_REQUEST, props),
    listSuppliersFailure: () => FactoryAction(TYPE.LOAD_LIST_SUPPLIERS_FAILURE),
    listSuppliersSuccess: (props) => FactoryAction(TYPE.LOAD_LIST_SUPPLIERS_SUCCESS, props),
    exportPaymentOrdersRequest: (props) => FactoryAction(TYPE.EXPORT_PAYMENT_ORDERS_REQUEST, props),
    exportPaymentOrdersFailure: () => FactoryAction(TYPE.EXPORT_PAYMENT_ORDERS_FAILURE),
    exportPaymentOrdersSuccess: (props) => FactoryAction(TYPE.EXPORT_PAYMENT_ORDERS_SUCCESS, props),
};

export const SelectorsStore = {
    getPaymentOrders: (store) => store[NAME].paymentOrderList,
    getSuppliers: (store) => store[NAME].suppliersList,
    isFetching: (store) => store[NAME].isFetching,
    getOffset: (store) => store[NAME].offset,
    getPageNumber: (store) => store[NAME].pageNumber,
    getMoreLines: (store) => store[NAME].moreLines,
    getFirstTime: (store) => store[NAME].firstTime,
    getFilters: (store) => store[NAME].filters,
    getMoreOrders: (store) => store[NAME].moreOrders,
    getFiltersSuppliers: (store) => store[NAME].filtersSuppliers,
};
