import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.GENERAL_CONDITIONS_PRE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                body: action.body,
                latestNews: action.latestNews,
                idCondition: action.idCondition,
            };
        case TYPE.GENERAL_CONDITIONS_SIGN_REQUEST:
            return {
                ...storeProp,
                body: storeProp.body,
                fetching: true,
            };
        case TYPE.GENERAL_CONDITIONS_PRE_REQUEST:
        case TYPE.GENERAL_CONDITIONS_FETCHING:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.GENERAL_CONDITIONS_FROM_ENVIRONMENT:
            return {
                ...storeProp,
                isEnvironmentChanging: true,
                environment: action.idEnvironment,
                rememberEnvironment: action.rememberEnvironment,
                fetching: false,
                suggestMigration: false,
            };
        case TYPE.GENERAL_CONDITIONS_FROM_LOGIN:
            return {
                ...storeProp,
                suggestMigration: action.suggestMigration,
                isEnvironmentChanging: false,
                fetching: false,
            };
        case TYPE.GENERAL_CONDITIONS_PRE_ERROR:
        case TYPE.GENERAL_CONDITIONS_SIGN_ERROR:
        case TYPE.GENERAL_CONDITIONS_SIGN_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
            };

        default:
            return {
                ...storeProp,
            };
    }
};
