import React from "react";

import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";

import { SelectorsStore as i18nSelectors } from "~/store/i18n";
import {
    SelectorsStore as SelectorsStoreInvestment,
    SelectorsAction as SelectorsActionInvestment,
} from "~/store/investment";
import { getInteger } from "~/util/config";

import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";

import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        suppliersDisplayList: PropTypes.array.isRequired,
        isSubmitting: PropTypes.bool,
    },
    defaults: {
        isSubmitting: false,
    },
};

export function Component(props) {
    const { isSubmitting } = props;

    return (
        <Form id={Style.ID}>
            <section className="message">
                <I18n id="investment.riskProfile.authorize" />
            </section>

            <Button type="submit" size="sm" variant="primary" disabled={isSubmitting}>
                <I18n id="transaction.accept" />
            </Button>
        </Form>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreInvestment.getTransaction(store),
        termDeposit: SelectorsStoreInvestment.getTermDeposit(store),
        lang: i18nSelectors.getLang(store),
        maximumDecimals: getInteger("defaultDecimal.maximum"),
        minimumDecimals: getInteger("defaultDecimal.minimum"),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithRouter,
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({}),
        handleSubmit: (data, formikBag) => {
            const {
                dispatch,
                transaction: { idTransaction },
            } = formikBag.props;
            dispatch(
                SelectorsActionInvestment.signTransaction({
                    idForm: null,
                    idActivity: "investment.riskProfile.send",
                    idTransaction,
                    credentials: {},
                    formikBag,
                }),
            );
        },
    }),
)(Component);
