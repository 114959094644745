import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, ZERO_NUMBER } from "~/constants";
import { SelectorsAction as SelectorsActionWMShares } from "~/store/wm/shares";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import FieldLabel from "~/pages/_components/fields/FieldLabel";

export const NAME = "PaperSell";

export const PROP = {
    types: {
        availableForSelling: PropTypes.number,
        isSelling: PropTypes.bool,
        processingSale: PropTypes.number,
    },
    defaults: {
        availableForSelling: ZERO_NUMBER,
        isSelling: false,
        processingSale: ZERO_NUMBER,
    },
};

export function Component(props) {
    const { othersFields, isSelling } = props;

    const handleClick = () => {
        const { dispatch, routerActions } = props;
        const { LINK1, LINK2, LINK3, PAPEL, PDFOLINK, TIPOPRODUCTOORIGEN } = othersFields;
        let link;

        if (PDFOLINK === "LINK") {
            link = `${LINK1 || EMPTY_STR}${LINK2 || EMPTY_STR}${LINK3 || EMPTY_STR}`;

            if (link.toUpperCase().startsWith("HTTP://")) {
                routerActions.push(link);
            } else {
                dispatch(
                    SelectorsActionWMShares.getPaper({
                        idPaper: PAPEL,
                        productType: TIPOPRODUCTOORIGEN,
                        detailType: "localLink",
                    }),
                );
            }
        } else {
            dispatch(
                SelectorsActionWMShares.getPaper({
                    idPaper: PAPEL,
                    productType: TIPOPRODUCTOORIGEN,
                    detailType: "localPDF",
                }),
            );
        }
    };

    const renderSelling = () => {
        const { availableForSelling, processingSale } = props;
        const { CANTIDAD: quantity, ISIN: isin, TICKER: ticker } = othersFields;

        return (
            <Box className="form-group py-3">
                <Box className="grid-container paper px-4 py-2">
                    <Box flex>
                        <FieldLabel labelKey="client.wm.shares.sell.paper.isin" />
                        <Box className="py-3 pl-3" tag="span">
                            {isin}
                        </Box>
                    </Box>
                    <Box flex>
                        <FieldLabel labelKey="client.wm.shares.sell.paper.ticker" />
                        <Box className="py-3 pl-3" tag="span">
                            {ticker}
                        </Box>
                    </Box>
                    <Box flex>
                        <FieldLabel labelKey="client.wm.shares.sell.paper.quantity" />
                        <Box className="py-3 pl-3" tag="span">
                            {quantity}
                        </Box>
                    </Box>
                    {processingSale > 0 && (
                        <Box flex>
                            <FieldLabel useColon labelKey="client.wm.allInvestments.sell.processingSale" />
                            <Box className="py-3 pl-3" tag="span">
                                {processingSale}
                            </Box>
                        </Box>
                    )}
                    {processingSale > 0 && (
                        <Box flex>
                            <Box flex>
                                <FieldLabel useColon labelKey="client.wm.allInvestments.sell.availableForSelling" />
                                <Box className="py-3 pl-3" tag="span">
                                    {availableForSelling}
                                </Box>
                            </Box>
                        </Box>
                    )}
                    <Box flex justify="end">
                        <Box flex>
                            <Box className="py-3 pl-3" tag="span">
                                <a onClick={handleClick}>{UtilsI18n.get("client.wm.viewDetail")}</a>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    };

    if (isSelling) {
        return renderSelling();
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Connect()(Component);
