import { LOCATION_CHANGE } from "connected-react-router";

import { ZERO } from "~/constants";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return {
                ...PROP.defaults,
            };
        case TYPE.LIST_PAPERS_REQUEST:
        case TYPE.PAPER_DETAILS_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.LIST_PAPERS_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                familiesList: action.familiesList,
                fundPapers: action.papersList,
                categoriesList: action.categoriesList,
                filters: { ...storeProp.filters, category: action.categoryFilter },
            };
        case TYPE.LIST_PAPERS_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.RESET_FILTERS: {
            const defaultFilters = { ...PROP.defaults.filters };

            if (action.riskProfileCode && action.riskProfileCode === ZERO) {
                defaultFilters.category = "all";
            }

            return { ...storeProp, filters: defaultFilters };
        }
        case TYPE.UPDATE_FILTERS:
            return {
                ...storeProp,
                filters: { family: action.family, category: action.category, year: action.year },
                fetching: false,
            };
        case TYPE.PAPER_DETAILS_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                paperDetail: action.paperDetail,
                chartPeriods: action.chartPeriods,
            };
        case TYPE.READ_PAPER_CHART_VALUES_REQUEST:
            return {
                ...storeProp,
                fetchingChartValues: true,
            };
        case TYPE.READ_PAPER_CHART_VALUES_FAILURE:
            return {
                ...storeProp,
                fetchingChartValues: false,
            };
        case TYPE.READ_PAPER_CHART_VALUES_SUCCESS:
            return {
                ...storeProp,
                chartValues: action.paperValues,
                fetchingChartValues: false,
            };
        case TYPE.RESET_CHART_VALUES:
            return {
                ...storeProp,
                chartPeriods: PROP.defaults.chartPeriods,
                chartValues: PROP.defaults.chartValues,
            };
        default:
            return storeProp;
    }
};
