import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";

import formField from "~/pages/forms/_components/_fields/_commons/formField";

export const NAME = "Label";

export const PROP = {
    types: {
        label: PropTypes.string.isRequired,
    },
    defaults: {
        label: EMPTY_STR,
    },
};

export function Component(props) {
    const { label } = props;
    return <small className="text-optional">{label}</small>;
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default formField({ pureRender: true })(Component);
