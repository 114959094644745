import React from "react";

import PropTypes from "prop-types";
import { Grid, Row } from "react-bootstrap";

import { EVENT_BACKBUTTON } from "~/constants";
import UtilsDevice from "~/util/device";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import Modal from "~/components/Modal";

export const NAME = "ConfirmationModal";

export const PROP = {
    types: {
        show: PropTypes.bool.isRequired,
        handleAcept: PropTypes.func.isRequired,
        handleCancel: PropTypes.func.isRequired,
        idLabel: PropTypes.string.isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const { show, handleAcept, handleCancel, idLabel } = props;

    React.useEffect(() => {
        if (UtilsDevice.isMobileNative()) {
            document.addEventListener(EVENT_BACKBUTTON, () => handleCancel());
        }

        return () => {
            if (UtilsDevice.isMobileNative()) {
                document.removeEventListener(EVENT_BACKBUTTON, () => handleCancel());
            }
        };
    }, []);

    const title = UtilsI18n.get(idLabel);
    return (
        <Modal show={show} onHide={() => handleCancel()}>
            <div className="modal-text">
                <h2>{title}</h2>
            </div>
            <div className="modal-buttons">
                <section>
                    <Grid>
                        <Row>
                            <Button
                                label="global.accept"
                                bsStyle="primary"
                                loading={false}
                                onClick={() => handleAcept()}
                            />
                        </Row>
                    </Grid>
                </section>
                <section>
                    <Grid>
                        <Row>
                            <Button label="global.cancel" loading={false} onClick={() => handleCancel()} />
                        </Row>
                    </Grid>
                </section>
            </div>
        </Modal>
    );
}

Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

export default HighOrder(HighOrder.Resizable)(Component);
