/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";

import PropTypes from "prop-types";

import Element from "~/components/Component";
import IntersectionObserver from "~/components/IntersectionObserver";

import Namespace from "./Slides.scss";

export const { NAME, TYPE } = Namespace;

export const PROP = {
    types: {
        children: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
        elementsPerSlide: PropTypes.number,
    },
    defaults: {
        elementsPerSlide: 1,
    },
};

export function Component(props) {
    const { children, elementsPerSlide, handleChangeSlide, ...rest } = props;
    const refSlides = React.useRef({});
    const refButtons = React.useRef({});
    let data = {};

    if (!Array.isArray(children)) {
        throw new Error(`Expecting an Array, got "${typeof children}"`);
    }

    const items = React.Children.map(children, (item) => {
        const { key } = item;
        if (typeof key !== "string") {
            throw new Error("Expecting a key prop for every child.");
        }
        // disable drag and drop on direct elements (ie: links are draggable by default)
        const { props: props_ } = item;
        return {
            key,
            item: React.cloneElement(item, { ...props_, draggable: false }),
        };
    });

    let navButtons = items.map(({ key }, i) => (
        <button
            key={key}
            id={i}
            onClick={handleNav.bind(null, key)}
            type="button"
            aria-label={i}
            ref={(el) => {
                refButtons.current[key] = el;
            }}
        />
    ));

    if (elementsPerSlide > 1) {
        navButtons = navButtons.filter((button, i) => i % elementsPerSlide === 0);
    }

    return (
        <Element tag="section" name={NAME} type={TYPE} {...rest}>
            <section
                role="list"
                onMouseDown={handleDragStart}
                onMouseLeave={handleDragEnd}
                onMouseUp={handleDragEnd}
                onMouseMove={handleDrag}>
                <IntersectionObserver onIntersect={handleIntersect} settings-threshold={0.9}>
                    {items.map(({ key, item }, i) => (
                        <div key={key} id={i} role="listitem" intersection-trigger="true">
                            {item}
                        </div>
                    ))}
                </IntersectionObserver>
            </section>
            <nav role="navigation">{navButtons}</nav>
        </Element>
    );

    function handleNav(key) {
        const { current: slides } = refSlides;
        const slide = slides[key];
        if (slide) {
            slide.scrollIntoView({ block: "nearest", inline: "end" });
        }
    }

    function handleIntersect(ev) {
        const { target, key, isIntersecting } = ev;
        const { current: buttons } = refButtons;
        const { current: slides } = refSlides;

        const accountKeys = Object.keys(buttons);
        let button = buttons[key];
        slides[key] = target;

        if (button) {
            button.disabled = isIntersecting;
            button.value = isIntersecting;
        } else if (isIntersecting) {
            const [firstButtonKey] = accountKeys;
            button = buttons[firstButtonKey];
            button.disabled = true;
            button.value = true;
        }

        const buttonsWithValue = [];
        accountKeys.forEach((k) => {
            const { value } = buttons[k];
            if (value === "true") {
                buttonsWithValue.push(k);
            }
        });

        if (buttonsWithValue.length === 2) {
            const [firstKey, secondKey] = buttonsWithValue;
            const firstButton = buttons[firstKey];
            const secondButton = buttons[secondKey];
            if (firstButton.disabled === true && firstButton.value === "true") {
                secondButton.value = true;
                secondButton.disabled = true;
                firstButton.disabled = false;
            }
        }

        if (isIntersecting && handleChangeSlide) {
            handleChangeSlide(key);
        }
        // if all elements are visible, no need to show the navBar;
        const els = Object.values(buttons);
        if (els.length) {
            const { parentElement } = els[0];
            parentElement.className =
                els.length === els.filter(({ disabled }) => disabled).length ? Namespace.CLASS_EMPTY : "";
        }
    }

    function handleDragStart(ev) {
        const { currentTarget } = ev;
        if (currentTarget.scrollWidth <= currentTarget.clientWidth) {
            return;
        }
        // only when there's content to scroll
        data = {
            isActive: true,
            isDrag: false,
            x: ev.pageX - currentTarget.offsetLeft,
            scrollLeft: currentTarget.scrollLeft,
        };
        currentTarget.classList.add(Namespace.CLASS_ACTIVE);
        currentTarget.querySelectorAll("a").forEach((el) => el.addEventListener("click", handleClickReplace));
    }

    function handleClickReplace(ev) {
        const { currentTarget } = ev;
        if (data.isDrag) {
            ev.preventDefault();
        }
        currentTarget.removeEventListener("click", handleClickReplace);
    }

    function handleDragEnd(ev) {
        if (data.isActive) {
            ev.currentTarget.classList.remove(Namespace.CLASS_ACTIVE);
            data.isActive = false;
        }
    }

    function handleDrag(ev) {
        if (data.isActive) {
            data.isDrag = true;
            const { currentTarget } = ev;
            const walk = ev.pageX - currentTarget.offsetLeft - data.x;
            currentTarget.scrollLeft = data.scrollLeft - walk;
        }
    }
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
