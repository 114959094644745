import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import Container from "~/containers/Internal/Form/Filter";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsAction as SelectorsActionPosition, SelectorsStore as SelectorsStorePosition } from "~/store/position";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as i18nUtils from "~/util/i18n";

import Element from "~/components/Component";
import Modal from "~/components/Modal";

import AccordionDetail from "./AccordionDetail";
import AccountItem from "./AccountItem";
import DownloadDate from "./DownloadDate";
import LinkOptions from "./LinkOptions";
import PopUp from "./PopUp";
import Style from "./Position.rules.scss";
import { groupByCurrencyProductType, loansProductType } from "./constants";

export const { NAME, NAME_MESSAGES, NAME_NOTE, TYPE_NOTE } = Style;

export const PROP = {
    types: { isChangingEnvironment: PropTypes.bool },
    defaults: { isChangingEnvironment: false },
};

export class Component extends React.Component {
    state = {
        forceMode: "opened",
        showFooterNote: false,
        showModal: false,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const { collapsedElementsCount, positions } = nextProps;

        if (collapsedElementsCount === 0) {
            return { ...prevState, forceMode: "opened" };
        }

        if (collapsedElementsCount === positions.length) {
            return { ...prevState, forceMode: "closed" };
        }

        if (collapsedElementsCount > 0 && collapsedElementsCount < positions.length) {
            return prevState;
        }

        return null;
    }

    componentDidMount() {
        const { dispatch, isChangingEnvironment } = this.props;

        if (!isChangingEnvironment) {
            dispatch(SelectorsActionPosition.readPositionRequest());
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;

        dispatch(SelectorsActionPosition.changeAllCollapsedElementsCount({ collapseAll: false }));
        dispatch(SelectorsActionPosition.deletePosition());
    }

    getPositionPDF = (order) => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionPosition.showDownloadDateModal({ order, showDownloadDateModal: true }));
    };

    handleBack = () => {
        const { history } = this.props;

        history.goBack();
    };

    handleCloseDownloadDateModal = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionPosition.showDownloadDateModal({ showDownloadDateModal: false }));
    };

    toggleAccordion = () => {
        const { collapsedElementsCount, dispatch, positions } = this.props;
        const { forceMode } = this.state;

        let collapseAll;

        if (collapsedElementsCount === 0) {
            collapseAll = true;
        } else if (collapsedElementsCount === positions.length) {
            collapseAll = false;
        } else if (collapsedElementsCount > 0 && collapsedElementsCount < positions.length) {
            collapseAll = forceMode === "opened";
        }

        dispatch(SelectorsActionPosition.changeAllCollapsedElementsCount({ collapseAll }));
    };

    handleOpenModal = (fields, data, currency, title) => {
        this.setState({ currency, data, fields, showModal: true, title });
    };

    render() {
        const { collapsedElementsCount, fetching, i18n, positions, showDownloadDateModal, lang } = this.props;
        const { closeAll, disclaimer1, disclaimer2, downloadByCurrency, downloadByProduct, headTitle, openAll } = i18n;
        const { currency, data, fields, forceMode, showFooterNote, showModal, title } = this.state;

        let buttonText;
        let showCloseAll;
        if (collapsedElementsCount === 0) {
            buttonText = closeAll;
            showCloseAll = true;
        }

        if (collapsedElementsCount === positions.length) {
            buttonText = openAll;
            showCloseAll = false;
        }

        if (collapsedElementsCount > 0 && collapsedElementsCount < positions.length) {
            buttonText = forceMode === "opened" ? closeAll : openAll;
            showCloseAll = forceMode === "opened";
        }

        const filteredPosition = positions.filter((item) => item.productType !== 50 && item.productType !== 500);

        return (
            <React.Fragment>
                <Container
                    wait={fetching}
                    name={NAME}
                    head-onBack={this.handleBack}
                    head-title={headTitle}
                    className="position"
                    image="Posicion.svg">
                    <div className="links header-content">
                        <LinkOptions text={i18nUtils.get("client.position.download")}>
                            <LinkOptions
                                handleClick={() => this.getPositionPDF("currency")}
                                text={downloadByCurrency}
                            />
                            <LinkOptions handleClick={() => this.getPositionPDF("product")} text={downloadByProduct} />
                        </LinkOptions>
                        <LinkOptions
                            image="arrow-top.svg"
                            handleClick={() => this.toggleAccordion()}
                            text={buttonText}
                            className={showCloseAll && "invert"}
                        />
                    </div>
                    {filteredPosition.map((position) => {
                        if (!showFooterNote && loansProductType.includes(position.productType)) {
                            this.setState({
                                showFooterNote: true,
                            });
                        }

                        return (
                            <AccordionDetail
                                className={`container-accordion-detail position_${position.productType}`}
                                forceMode={forceMode}
                                key={position.productType}
                                title={i18nUtils.get(`client.position.productType.${position.productType}`)}
                                total={position.total}>
                                <AccountItem
                                    groupByCurrency={groupByCurrencyProductType.includes(position.productType)}
                                    handleOpenModal={this.handleOpenModal}
                                    innerAccordionForceMode={forceMode}
                                    lang={lang}
                                    name={i18nUtils.get(`client.position.productType.${position.productType}`)}
                                    productsByCurrency={position.productsByCurrency}
                                    productType={position.productType}
                                    total={position.total}
                                    totalByCurrency={position.totalByCurrencyMap}
                                />
                            </AccordionDetail>
                        );
                    })}
                    <PopUp
                        show={showModal}
                        title={title}
                        fields={fields}
                        data={data}
                        handleCloseModal={() => {
                            this.setState({ showModal: false });
                        }}
                        currency={currency}
                    />
                    {showFooterNote && (
                        <React.Fragment>
                            <Element
                                type={TYPE_NOTE}
                                name={NAME_NOTE}
                                tag="span"
                                intersection-trigger="true"
                                key="disclamer1"
                                className="disclaimer">
                                {disclaimer1}
                            </Element>
                            <Element
                                type={TYPE_NOTE}
                                name={NAME_NOTE}
                                tag="span"
                                intersection-trigger="true"
                                key="disclamer2"
                                className="disclaimer">
                                {disclaimer2}
                            </Element>
                        </React.Fragment>
                    )}
                    <Modal
                        show={showDownloadDateModal}
                        closeButton
                        onClose={this.handleCloseDownloadDateModal}
                        labelKey="client.position.download.date"
                        backdrop="static">
                        <DownloadDate onCloseDownloadDateModalForm={this.handleCloseDownloadDateModal} />
                    </Modal>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    collapsedElementsCount: SelectorsStorePosition.getCollapsedElementsCount(store),
    fetching: SelectorsStorePosition.isFetching(store),
    i18n: {
        closeAll: StoreI18n.getMessage(store, "position.accordions.closeAll"),
        disclaimer1: StoreI18n.getMessage(store, "position.disclaimer1"),
        disclaimer2: StoreI18n.getMessage(store, "position.disclaimer2"),
        downloadByCurrency: StoreI18n.getMessage(store, "position.downloadByCurrency"),
        downloadByProduct: StoreI18n.getMessage(store, "position.downloadByProduct"),
        firstDisclamer: StoreI18n.getMessage(store, "position.firstDisclamer"),
        headTitle: StoreI18n.getMessage(store, "position.title"),
        openAll: StoreI18n.getMessage(store, "position.accordions.openAll"),
    },
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    order: SelectorsStorePosition.getOrder(store),
    positions: SelectorsStorePosition.getPositions(store),
    showDownloadDateModal: SelectorsStorePosition.getShowDownloadDateModal(store),
    lang: StoreI18n.getLang(store),
});

export default Connect(mapStateToProps)(Component);
