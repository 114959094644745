import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/Filter";
import {
    SelectorsStore as SelectorsStoreAdvancePayments,
    SelectorsAction as SelectorsActionAdvancePayments,
} from "~/store/factoring/advancePayments";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Style from "./List.rules.scss";
import Filters from "./_Filters";
import AdvancePayments from "./_advancePayments";

export const { NAME } = Style;

export const PROP = {
    types: {
        fetching: PropTypes.bool.isRequired,
        i18n: PropTypes.object,
        ...TypesRedux,
    },
    defaults: {
        ...DefaultsRedux,
    },
};

export function Component(props) {
    const { dispatch, fetching, i18n } = props;
    React.useEffect(() => {
        dispatch(SelectorsActionAdvancePayments.listAdvancePaymentOrdersRequest());
    }, [dispatch]);

    const handleBack = () => {
        const { history } = props;
        history.goBack();
    };

    return (
        <React.Fragment>
            <Container
                wait={fetching}
                name={NAME}
                head-onBack={handleBack}
                head-title={i18n.headTitle}
                image="paymentOrders.svg"
                scopeToShowNotification={SCOPE.LIST_ADVANCE_PAYMENTS}>
                <section>
                    <Filters />
                    <AdvancePayments />
                </section>
            </Container>
        </React.Fragment>
    );
}

const mapStateToProps = (store) => ({
    fetching: SelectorsStoreAdvancePayments.isFetching(store),
    i18n: {
        headTitle: StoreI18n.getMessage(store, "listAdvancePayments.list.head.title"),
    },
});

export default Connect(mapStateToProps)(Component);
