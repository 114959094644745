import { DEFAULT, UI, COUNTRY_TO_CURRENCY } from "~/constants";
import Currency from "~/util/currency";

export default Object.create({
    /**
     * Returns a label  using a value.
     *
     * @param {string} value - The  currency value.
     * @returns {string}
     */
    getFlag(currency) {
        const value = Currency()
            .filter((elem) => elem[0] === currency)
            .map((e) => e[1])
            .pop();
        const key = value === undefined || value === UI ? DEFAULT : value;
        return `currencyFlags/${key}`;
    },

    /**
     * Returns a label  using a value.
     *
     * @param {string} value - The  currency value.
     * @returns {string}
     */
    getFlagByCountry(country) {
        const key = COUNTRY_TO_CURRENCY[country] || country;
        return `currencyFlags/${key}`;
    },
});
