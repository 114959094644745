import React from "react";

import Style from "./QRPayments.rules.scss";
import Widget from "./_common/_Widget";

export const { TYPE, NAME } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    return (
        <Widget
            {...props}
            header-href="/servicePayment/qrModo/list"
            header-iconRight="toke-logo-with-container.svg"
            header-title="desktop.widgets.qrPayments.title"
            widgetAlternative
            id={Style.ID}
        />
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
