import { EMPTY_STR } from "~/constants";
import { STATUS, STATUS_ICON } from "~/constants/transaction";

export const getTransactionStatusIcon = (idTransactionStatus) => {
    let result = EMPTY_STR;
    switch (idTransactionStatus) {
        case STATUS.PENDING:
            result = STATUS_ICON.PENDING;
            break;
        case STATUS.CANCELLED:
            result = STATUS_ICON.CANCELLED;
            break;
        case STATUS.SCHEDULED:
            result = STATUS_ICON.SCHEDULED;
            break;
        case STATUS.FINISHED:
            result = STATUS_ICON.FINISHED;
            break;
        case STATUS.FAILED:
        case STATUS.RETURNED:
            result = STATUS_ICON.FAILED_RETURNED;
            break;
        case STATUS.PROCESSING:
        case STATUS.RUNNING:
            result = STATUS_ICON.PROCESSING;
            break;
        case STATUS.DRAFT:
            result = STATUS_ICON.DRAFT;
            break;
        default:
            result = STATUS_ICON.PROCESSING;
    }
    return result;
};

export function generateId() {
    return (
        Date.now().toString(36) +
        Math.random()
            .toString(36)
            .substr(2, 5)
    ).toUpperCase();
}

export function isEmptyObj(obj) {
    if (Object.keys(obj).length === 0) {
        return true;
    }
    return false;
}

/**
 * Validate if the transition is being made from a widget to a product detail page.
 * Applies to Accounts, CreditCards and Loans widgets.
 *
 * @param {String} currentPath
 * @param {String} nextPath
 */

export function isWidgetProductTransition(currentPath, nextPath) {
    return (
        currentPath === "/desktop" &&
        (nextPath === "/accounts/:id" || nextPath === "/loans/:id/" || nextPath === "/creditCards/:id")
    );
}
export const getNestedObject = (nestedObj, pathArr) =>
    pathArr.reduce((obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined), nestedObj);
