import { push as Push } from "connected-react-router";
import { call as Call, put as Put, select as Select, takeLatest as TakeLatest } from "redux-saga/effects";

import { LEVEL, SCOPE, RESPONSE_TYPE, SIGN_WITH_BIOMETRIC, VALIDATION_ERROR, HSBC, CDP_CURRENCY } from "~/constants";
import Store from "~/store";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import IsTrustedBiometric from "~/store/form/_sagas/_isTrustedBiometric";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsStore as SelectorsStoreTransfers } from "~/store/transfers";
import {
    adjustIdFieldErrors as AdjustIdFieldErrors,
    credentialsWithUnderscore as CredentialsWithUnderscore,
    hasIncorrectCredentials as HasIncorrectCredentials,
} from "~/util/form";
import * as UtilsI18n from "~/util/i18n";
import UtilLodash from "~/util/lodash";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsMiddleware } from "./_selectors";

export default [
    TakeLatest(TYPE.ALIAS_DELETION_PREVIEW_REQUEST, aliasDeletionPreviewRequest),
    TakeLatest(TYPE.ALIAS_DELETION_SEND_REQUEST, aliasDeletionSendRequest),
    TakeLatest(TYPE.ALIAS_ENROLLMENT_PRE_REQUEST, aliasEnrollmentPreRequest),
    TakeLatest(TYPE.ALIAS_ENROLLMENT_PREVIEW_REQUEST, aliasEnrollmentPreviewRequest),
    TakeLatest(TYPE.ALIAS_ENROLLMENT_SEND_REQUEST, aliasEnrollmentSendRequest),
    TakeLatest(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, cancelTransactionCustom),
    TakeLatest(TYPE.GET_ACCOUNTS_BY_ALIAS_REQUEST, getAccountsByAliasListRequest),
    TakeLatest(TYPE.LATEST_TRANSACTIONS_LIST_REQUEST, latestTransactionsListRequest),
    TakeLatest(TYPE.READ_TRANSACTION_REQUEST, readTransactionRequest),
    TakeLatest(TYPE.TEMPLATES_LIST_REQUEST, templatesListRequest),
    TakeLatest(TYPE.SIGN_TRANSACTION_REQUEST, signTransaction),
];

function* aliasDeletionPreviewRequest({ formData }) {
    const { type, data } = yield Call(SelectorsMiddleware.aliasDeletionPreview, formData);

    if (type === RESPONSE_TYPE.WARNING) {
        const message = data.code === VALIDATION_ERROR ? UtilsI18n.get("forms.fieldsErrors") : data.message;

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.TRANSFERS_ALIAS_ADMINISTRATION],
            }),
        );

        yield Put(SelectorsAction.aliasDeletionPreviewFailure());
    } else {
        yield Put(SelectorsAction.aliasDeletionPreviewSuccess({ ...data.data }));
        yield Put(Push("/transfers/aliasDeletion"));
    }
}

function* aliasDeletionSendRequest({ formData, formikBag, otp }) {
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
    }

    const { webToken } = Store.getState().session;

    const tokenToUse = otp || webToken;

    let response;

    if (UtilLodash.isEmpty(tokenToUse)) {
        response = {
            data: {
                code: VALIDATION_ERROR,
                message: UtilsI18n.get(`returnCode.${VALIDATION_ERROR}`),
                data: {
                    otp: UtilsI18n.get("returnCode.COR027W"),
                },
            },
            status: 200,
            type: RESPONSE_TYPE.WARNING,
        };
    } else {
        response = yield Call(SelectorsMiddleware.aliasDeletionSend, {
            ...formData,
            _otp: tokenToUse,
        });
    }

    if (response.type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = Object.keys(response.data.data).some((key) => key === "otp");

        let message = UtilsI18n.get("global.unexpectedError");

        if (hasIncorrectCredentials) {
            formikBag.setErrors(response.data.data);
        } else if (VALIDATION_ERROR === response.data.code) {
            message = response.data.data.message;
        }

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.TRANSFERS_ALIAS_ADMINISTRATION],
            }),
        );

        formikBag.setSubmitting(false);

        yield Put(SelectorsAction.aliasDeletionSendFailure());
    } else {
        const { idTransaction } = response.data;
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });

        yield Put(SelectorsAction.aliasDeletionSendSuccess({ transaction }));
    }
}

function* aliasEnrollmentPreRequest() {
    const { type, data } = yield Call(SelectorsMiddleware.aliasEnrollmentPre);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.aliasEnrollmentPreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.TRANSFERS_ALIAS_ADMINISTRATION],
            }),
        );
    } else {
        yield Put(SelectorsAction.aliasEnrollmentPreSuccess({ ...data.data }));
    }
}

function* aliasEnrollmentPreviewRequest({ formData, formikBag, values }) {
    const accountByALias = yield Select(SelectorsStoreTransfers.getAccountsByAlias);
    const accountsHSBC = accountByALias.filter((account) => account.bcuCode === HSBC);

    if (accountsHSBC.length > 0) {
        const existsAccountHSBCUYU = accountsHSBC.some((account) => account.currency === CDP_CURRENCY.UYU);
        const existsAccountHSBCUSD = accountsHSBC.some((account) => account.currency === CDP_CURRENCY.USD);

        if (existsAccountHSBCUYU && existsAccountHSBCUSD) {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("transfers.alias.error.10413"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.TRANSFERS_ALIAS_ADMINISTRATION],
                }),
            );

            yield Put(SelectorsAction.aliasEnrollmentPreviewFailure());

            return;
        }
    }

    const { type, data } = yield Call(SelectorsMiddleware.aliasEnrollmentPreview, formData);

    if (type === RESPONSE_TYPE.WARNING) {
        const message = data.code === VALIDATION_ERROR ? UtilsI18n.get("forms.fieldsErrors") : data.message;

        Object.keys(values).forEach((field) => formikBag.setFieldTouched(field, true));
        formikBag.setErrors(AdjustIdFieldErrors(data.data));

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.TRANSFERS_ALIAS_ADMINISTRATION],
            }),
        );

        yield Put(SelectorsAction.aliasEnrollmentPreviewFailure());
    } else {
        yield Put(SelectorsAction.aliasEnrollmentPreviewSuccess({ ...data.data }));
    }

    formikBag.setSubmitting(false);
}

function* aliasEnrollmentSendRequest({ formData, formikBag, otp }) {
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
    }

    const { webToken } = Store.getState().session;

    const tokenToUse = otp || webToken;

    let response;

    if (UtilLodash.isEmpty(tokenToUse)) {
        response = {
            data: {
                code: VALIDATION_ERROR,
                message: UtilsI18n.get(`returnCode.${VALIDATION_ERROR}`),
                data: {
                    otp: UtilsI18n.get("returnCode.COR027W"),
                },
            },
            status: 200,
            type: RESPONSE_TYPE.WARNING,
        };
    } else {
        response = yield Call(SelectorsMiddleware.aliasEnrollmentSend, {
            ...formData,
            _otp: tokenToUse,
        });
    }

    if (response.type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = Object.keys(response.data.data).some((key) => key === "otp");

        let message = UtilsI18n.get("global.unexpectedError");

        if (hasIncorrectCredentials) {
            formikBag.setErrors(response.data.data);
        } else if (VALIDATION_ERROR === response.data.code) {
            message = response.data.data.message;
        }

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.TRANSFERS_ALIAS_ADMINISTRATION],
            }),
        );

        formikBag.setSubmitting(false);

        yield Put(SelectorsAction.aliasEnrollmentSendFailure());
    } else {
        const { idTransaction } = response.data;
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });

        yield Put(SelectorsAction.aliasEnrollmentSendSuccess({ transaction }));
    }
}

function* cancelTransactionCustom(props) {
    const { credentials, idTransaction, formikBag } = props;

    yield* handleCancelTransaction(credentials, idTransaction, formikBag);

    formikBag.setSubmitting(false);
}

function* getAccountsByAliasListRequest(props) {
    const { params, form } = props;

    const response = yield Call(SelectorsMiddleware.getAccountsByAliasList, params);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.getAccountsByAliasFailure());

        if (form) {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.data.NO_FIELD,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.FORM],
                }),
            );

            Object.keys(form.values).forEach((field) => form.setFieldTouched(field, true));
        }

        const { isFromAdministrationSection } = params;

        if (isFromAdministrationSection) {
            yield Put(Push("/transfers/aliasEnrollment"));
        }
    } else {
        const { accountsByAlias } = response.data.data;

        if (!accountsByAlias || accountsByAlias === null) {
            yield Put(SelectorsAction.getAccountsByAliasFailure());

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("client.transfer.alias.accountsByAlias.noRecords"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.FORM],
                }),
            );
        } else {
            if (form) {
                form.setErrors({});
            }

            yield Put(SelectorsAction.getAccountsByAliasSuccess(response.data.data));
        }
    }
}

function* handleCancelTransaction(credentials, idTransaction, formikBag) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const {
        data: { data },
        type,
    } = yield Call(SelectorsMiddlewareForm.cancelTransaction, {
        idTransactionToCancel: idTransaction,
        ...credentialsWithUnderscore,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data);
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("forms.cancelTransaction.errorMessage"),
                    level: LEVEL.ERROR,
                    scopes: SCOPE.TRANSFERS_ALIAS_ADMINISTRATION,
                }),
            );
        }
    } else {
        yield* readTransactionRequest({ idTransaction });

        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("forms.cancelTransaction.confirmationMessage"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.TRANSACTIONS],
            }),
        );
    }
}

function* latestTransactionsListRequest() {
    const response = yield Call(SelectorsMiddleware.latestTransactionsList);

    if (response && response.status === 200) {
        yield Put(SelectorsAction.latestTransactionsListSuccess(response.data.data));
    }
}

function* readTransactionRequest(props) {
    const { idTransaction } = props;
    const { type, data } = yield Call(SelectorsMiddlewareForm.readTransaction, {
        idTransactionToRead: idTransaction,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.TRANSFERS_ALIAS_ADMINISTRATION],
            }),
        );
    } else {
        yield Put(SelectorsAction.readTransactionSuccess({ transaction: data.data.transaction }));
    }
}

function* signTransaction(props) {
    const { credentials, idActivity, idForm, idTransaction, formikBag } = props;
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
    }

    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { data, type } = yield Call(
        SelectorsMiddlewareForm.sign,
        { idForm, idTransaction, ...credentialsWithUnderscore },
        idActivity,
    );

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(AdjustIdFieldErrors(data.data));
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.TRANSFERS_ALIAS_ADMINISTRATION],
                }),
            );
        }

        yield Put(SelectorsAction.signTransactionFailure());

        formikBag.setSubmitting(false);
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, { idTransactionToRead: idTransaction });

        yield Put(SelectorsAction.signTransactionSuccess({ transaction }));

        formikBag.setSubmitting(false);
    }
}

function* templatesListRequest() {
    const response = yield Call(SelectorsMiddleware.templatesList);

    if (response && response.status === 200) {
        yield Put(SelectorsAction.templatesListSuccess(response.data.data));
    }
}
