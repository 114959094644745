import React from "react";

import { routerActions } from "connected-react-router";
import { connect as Connect } from "react-redux";

import { SelectorsStore as SelectorsStoreWMBonds, SelectorsAction as SelectorsActionsWMBonds } from "~/store/wm/bonds";
import { generateId as GenID } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import GridLayout from "~/components/GridLayout";
import HighOrder from "~/components/HighOrder";

import Style from "./_SearchItemsList.rules.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const { papersList } = props;

    const headerLabels = [
        UtilsI18n.get("wm.bonds.buy.search.issuer.label"),
        UtilsI18n.get("wm.bonds.buy.search.isin.label"),
        UtilsI18n.get("wm.bonds.buy.search.exp.label"),
        UtilsI18n.get("wm.bonds.buy.search.table.coupon.label"),
        UtilsI18n.get("wm.bonds.buy.search.currency.label"),
    ];

    return (
        <Box className={CLASS}>
            <GridLayout>
                {headerLabels.map((heading) => (
                    <Box key={`heading-${GenID()}`}>{heading}</Box>
                ))}
            </GridLayout>
            {papersList && papersList.map((paper) => renderItem(paper))}
            {papersList && papersList.length === 0 && (
                <Box flex justify="center">
                    <Box className="my-4">{UtilsI18n.get("environment.filter.noresults")}</Box>
                </Box>
            )}
        </Box>
    );

    function renderItem(paper) {
        const { description, idPaper, isin, expirationDate, rate, currency } = paper;
        return (
            <GridLayout className="search-item" key={`searchItem-${GenID()}`} onClick={() => handleClick(idPaper)}>
                <Box>
                    <Box>{description}</Box>
                </Box>
                <Box>
                    <Box>{isin}</Box>
                </Box>
                <Box>
                    <Box>{expirationDate}</Box>
                </Box>
                <Box>
                    <Box>{rate}%</Box>
                </Box>
                <Box>
                    <Box>{UtilsI18n.get(`currency.label.${currency}`)}</Box>
                </Box>
            </GridLayout>
        );
    }

    function handleClick(idPaper) {
        const { dispatch } = props;
        dispatch(SelectorsActionsWMBonds.buyBondsPre({ idPaper }));
        dispatch(routerActions.push("/wm/bonds/buy"));
    }
}

Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

const mapStateToProps = (store) => {
    return {
        papersList: SelectorsStoreWMBonds.getPapersList(store),
    };
};

export default HighOrder(Connect(mapStateToProps))(Component);
