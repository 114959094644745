import { PROP } from "./_consts";

export default (TYPE) =>
    (storeProp = PROP.defaults, action) => {
        const {
            credentialGroups,
            data: {
                capFrequencyList,
                caps,
                currentPage,
                defaultSignatureCap,
                enabledChannels,
                environmentProducts,
                functionalGroups,
                masterCurrency,
                selectedFunctionalGroups,
                selectedProducts,
                signature,
                signatureDispatch,
                signatureGroupMap,
                signatureId,
                signatureLevel,
                signatureLevelsCounts,
                signatureType,
                signatureTypeList,
                signatures,
                topAmount,
                totalPages,
            } = {},
            idActivity,
            idTransaction,
            pageNumber, // TODO: Revisar si viene en el data.
        } = action;

        switch (action.type) {
            case TYPE.CREATE_SIGNATURES_SCHEME_CONFIRM_PRE:
                return {
                    ...storeProp,
                    credentialGroups,
                    idActivity,
                    idTransaction,
                    selectedFunctionalGroups,
                    selectedProducts,
                    signatureDispatch,
                    signatureLevelsCounts,
                    signatureType,
                    topAmount,
                };
            case TYPE.CREATE_SIGNATURES_SCHEME_FAILURE:
            case TYPE.CREATE_SIGNATURES_SCHEME_PRE_FAILURE:
            case TYPE.CREATE_SIGNATURES_SCHEME_SUCCESS:
            case TYPE.DELETE_SIGNATURES_SCHEME_FAILURE:
            case TYPE.DELETE_SIGNATURES_SCHEME_PRE_FAILURE:
            case TYPE.DELETE_SIGNATURES_SCHEME_SUCCESS:
            case TYPE.LIST_SIGNATURES_SCHEMES_FAILURE:
            case TYPE.LOAD_SIGNATURES_SCHEMES_TICKET_FAILURE:
            case TYPE.LOAD_SIGNATURE_TICKET_FAILURE:
            case TYPE.MODIFY_SIGNATURES_SCHEME_FAILURE:
            case TYPE.MODIFY_SIGNATURES_SCHEME_PRE_FAILURE:
            case TYPE.MODIFY_SIGNATURES_SCHEME_SUCCESS:
                return {
                    ...storeProp,
                    isFetching: false,
                };
            case TYPE.CREATE_SIGNATURES_SCHEME_PRE_REQUEST:
            case TYPE.DELETE_SIGNATURES_SCHEME_PRE_REQUEST:
            case TYPE.DELETE_SIGNATURES_SCHEME_REQUEST:
            case TYPE.LOAD_SIGNATURES_SCHEMES_TICKET_REQUEST:
            case TYPE.LOAD_SIGNATURE_TICKET_REQUEST:
            case TYPE.MODIFY_SIGNATURES_SCHEME_PRE_REQUEST:
            case TYPE.MODIFY_SIGNATURES_SCHEME_REQUEST:
            case TYPE.SIGNATURES_SCHEME_EXPORT_LIST_REQUEST:
                return {
                    ...storeProp,
                    isFetching: true,
                };
            case TYPE.CREATE_SIGNATURES_SCHEME_PRE_SUCCESS:
                return {
                    ...storeProp,
                    capFrequencyList,
                    credentialGroups,
                    enabledChannels,
                    environmentProducts,
                    functionalGroups,
                    isFetching: false,
                    masterCurrency,
                    signatureDispatch,
                    signatureTypeList,
                };
            case TYPE.DELETE_SIGNATURES_SCHEME_PRE_SUCCESS:
                return {
                    ...storeProp,
                    capFrequencyList,
                    caps,
                    credentialGroups,
                    currentSignature: signature,
                    currentSignatureGroupMap: signatureGroupMap,
                    enabledChannels,
                    isFetching: false,
                    masterCurrency,
                    selectedFunctionalGroups,
                    signatureTypeList,
                };
            case TYPE.LIST_SIGNATURES_SCHEMES_REQUEST:
                return {
                    ...storeProp,
                    currentPage: pageNumber,
                    isFetching: true,
                };
            case TYPE.LIST_SIGNATURES_SCHEMES_SUCCESS:
                return {
                    ...storeProp,
                    currentPage,
                    defaultSignatureCap,
                    hasMoreData: currentPage < totalPages,
                    isFetching: false,
                    masterCurrency,
                    signaturesSchemes: signatures,
                    totalPages,
                };
            case TYPE.MODIFY_SIGNATURES_SCHEME_CONFIRM_PRE:
                return {
                    ...storeProp,
                    credentialGroups,
                    idActivity,
                    idTransaction,
                    selectedFunctionalGroups,
                    selectedProducts,
                    signatureDispatch,
                    signatureId,
                    signatureLevelsCounts,
                    signatureType,
                    topAmount,
                };
            case TYPE.DELETE_SIGNATURES_SCHEME_CONFIRM_PRE:
                return {
                    ...storeProp,
                    idActivity,
                    idTransaction,
                };
            case TYPE.MODIFY_SIGNATURES_SCHEME_PRE_SUCCESS:
                return {
                    ...storeProp,
                    capFrequencyList,
                    caps,
                    credentialGroups,
                    currentSignature: signature,
                    currentSignatureGroupMap: signatureGroupMap,
                    enabledChannels,
                    environmentProducts,
                    functionalGroups,
                    isFetching: false,
                    masterCurrency,
                    selectedFunctionalGroups,
                    signatureTypeList,
                };
            case TYPE.SIGNATURES_SCHEME_EXPORT_LIST_FAILURE:
            case TYPE.SIGNATURES_SCHEME_EXPORT_LIST_SUCCESS:
                return {
                    ...storeProp,
                    isFetching: false,
                };
            case TYPE.SIGNATURES_SCHEME_LOAD_MORE_REQUEST:
                return {
                    ...storeProp,
                    fetchingMoreSignaturesSchemes: true,
                };
            case TYPE.SIGNATURES_SCHEME_LOAD_MORE_SUCCESS:
                return {
                    ...storeProp,
                    currentPage,
                    fetchingMoreSignaturesSchemes: false,
                    hasMoreData: currentPage < totalPages,
                    signaturesSchemes: storeProp.signaturesSchemes
                        ? storeProp.signaturesSchemes.concat(signatures)
                        : signatures,
                };
            case TYPE.LOAD_SIGNATURES_SCHEMES_TICKET_SUCCESS:
                return {
                    ...storeProp,
                    fetching: false,
                    functionalGroups,
                    signatureDispatch,
                    signatureLevelsCounts,
                    signatureType,
                    topAmount,
                };
            case TYPE.LOAD_SIGNATURE_TICKET_SUCCESS:
                return {
                    ...storeProp,
                    fetching: false,
                    signatureLevel,
                };
            case TYPE.LOAD_FORM_SUCCESS:
                return {
                    ...storeProp,
                    fetching: false,
                };
            default:
                return { ...storeProp };
        }
    };
