import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, TYPE, PATH } from "./_consts";

export const SelectorsMiddleware = {
    listMessages: (props) => API.executeAnonymous(PATH.LIST_MESSAGES, props),
};

export const SelectorsAction = {
    setLang: (props) => FactoryAction(TYPE.SET_LANG, props),
    setInitLang: (props) => FactoryAction(TYPE.SET_INIT_LANG, props),
    updateRequest: () => FactoryAction(TYPE.UPDATE_REQUEST),
    updateFailure: () => FactoryAction(TYPE.UPDATE_FAILURE),
    updateSuccess: (props) => FactoryAction(TYPE.UPDATE_SUCCESS, props),
};

export const SelectorsStore = {
    getMessage: (store, messageId) => store[NAME].items && store[NAME].items[messageId],
    getMessages: (store) => store[NAME].items,
    getLang: (store) => store[NAME].lang,
    getFetching: (store) => store[NAME].fetching,
};
