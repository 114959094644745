import { BIOMETRIC_TYPES, EMPTY_STR } from "~/constants";

import { TYPE, PROP, TYPE_VERIFY_BIOMETRIC } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.SELECT_BIOMETRIC_TYPE: {
            const isFacephi = action.biometricType === BIOMETRIC_TYPES.facephi;

            return {
                biometricType: action.biometricType,
                isFacephi,
            };
        }
        case TYPE.VERIFY_BIOMETRIC_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                statusVerifyBiometric: TYPE_VERIFY_BIOMETRIC.SUCCESS,
                biometricType: action.biometricType,
            };
        case TYPE.VERIFY_BIOMETRIC_ERROR:
            return {
                ...storeProp,
                fetching: false,
                statusVerifyBiometric: TYPE_VERIFY_BIOMETRIC.ERROR,
            };
        case TYPE.SHOW_PASSWORD_MODAL:
            return {
                ...storeProp,
                askPassword: true,
                statusVerifyBiometric: EMPTY_STR,
            };
        case TYPE.HIDE_PASSWORD_MODAL:
            return {
                ...storeProp,
                askPassword: false,
            };
        case TYPE.NO_ENROLLED_FINGERPRINT:
            return {
                ...storeProp,
                fetching: false,
                statusVerifyBiometric: TYPE_VERIFY_BIOMETRIC.NO_ENROLLED_FINGERPRINT,
                pathFrom: action.pathFrom,
            };
        case TYPE.VERIFY_BIOMETRIC_CANCEL:
            return {
                ...storeProp,
                fetching: false,
                statusVerifyBiometric: TYPE_VERIFY_BIOMETRIC.CANCEL,
            };
        case TYPE.VALIDATE_PASSWORD_ERROR:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.VALIDATE_PASSWORD_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
            };

        case TYPE.REGISTER_BIOMETRIC_FACEPHI_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                isFacephi: true,
            };
        case TYPE.REGISTER_BIOMETRIC_REQUEST:
            return {
                ...storeProp,
                isFacephi: false,
                fetching: true,
            };
        case TYPE.RETRY_AUTH_NUMBER:
            return {
                ...storeProp,
                retryAuthenticationNumber: action.retryAuthenticationNumber,
            };
        case TYPE.VERIFY_BIOMETRIC_REQUEST:
        case TYPE.VERIFY_FACEPHI_CHANGE_MY_PHONE_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.REGISTER_BIOMETRIC_FACEPHI_ERROR:
            return {
                ...storeProp,
                retryAuthenticationNumber: storeProp.retryAuthenticationNumber - 1,
            };
        case TYPE.CLEAN_UP:
            return {
                ...PROP.defaults,
            };
        default:
            return {
                ...storeProp,
                statusVerifyBiometric: EMPTY_STR,
            };
    }
};
