import React from "react";

import { Field, Form, withFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import { SCOPE, EMPTY_STR } from "~/constants";
import Container from "~/containers/Internal/Administration/Simple";
import {
    SelectorsStore as SelectorsStoreUserInvite,
    SelectorsAction as SelectorsActionUserInvite,
} from "~/store/administration/users/usersInvite";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";

import DocumentField from "~/pages/settings/changePersonalInformation/field/DocumentField";

import Style from "./UserInvite.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        countries: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })).isRequired,
        defaultCountry: PropTypes.string.isRequired,
        defaultDocumentType: PropTypes.string.isRequired,
        documentTypes: PropTypes.arrayOf(
            PropTypes.shape({ id_country_code: PropTypes.string, id_document_type: PropTypes.string }),
        ).isRequired,
        isFetching: PropTypes.bool,
        isSubmitting: PropTypes.bool,
        isValid: PropTypes.bool,
        ...TypesRedux,
    },
    defaults: {
        isFetching: false,
        ...DefaultsRedux,
    },
};

const FORM_ID = "administration.users.invite";

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(SelectorsActionUserInvite.userInvitePre());
    }

    handleBack = () => {
        const { history } = this.props;

        history.goBack();
    };

    render() {
        const { countries, defaultCountry, defaultDocumentType, documentTypes, isFetching, ...rest } = this.props;

        return (
            <Container
                head-onBack={this.handleBack}
                head-title="administration.users.invite.title"
                image="administration-title-icon.svg"
                name={NAME}
                scopeToShowNotification={SCOPE.ADMINISTRATION_USERS_INVITE}
                wait={!countries.length || isFetching}>
                <div id={Style.ID}>
                    <Form>
                        <div className="title">
                            <I18n id="administration.users.invite.title" />
                        </div>
                        <div className="content">
                            <p className="text-lead">
                                <I18n id="administration.users.document.subtitle" />
                            </p>
                            <div className="field-document">
                                <Field
                                    idForm={FORM_ID}
                                    name="document"
                                    component={DocumentField}
                                    data={{ countries, documentTypes }}
                                    defaultCountry={defaultCountry}
                                    defaultDocumentType={defaultDocumentType}
                                    autoFocus={false}
                                    hidePlaceholder
                                    hideDocumentPlaceholder
                                    {...rest}
                                />
                            </div>
                        </div>
                        <footer>
                            <Button size="sm" variant="secondary" onClick={this.handleBack}>
                                <I18n id="global.cancel" />
                            </Button>
                            <Button type="submit" size="sm" variant="primary">
                                <I18n id="global.continue" />
                            </Button>
                        </footer>
                    </Form>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    countries: SelectorsStoreUserInvite.getCountryList(store),
    defaultCountry: SelectorsStoreUserInvite.getDefaultCountry(store),
    defaultDocumentType: SelectorsStoreUserInvite.getDefaultDocumentType(store),
    documentTypes: SelectorsStoreUserInvite.getDocumentTypeList(store),
    isFetching: SelectorsStoreUserInvite.getFetching(store),
});

export default Compose(
    Connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ defaultCountry, defaultDocumentType }) => ({
            captcha: EMPTY_STR,
            document: {
                country: defaultCountry,
                document: EMPTY_STR,
                type: defaultDocumentType,
            },
        }),
        validationSchema: () =>
            Yup.object().shape({
                document: Yup.object().shape({
                    document: Yup.string().required(UtilsI18n.get(`${FORM_ID}.documentNumber.required`)),
                    type: Yup.string().required(UtilsI18n.get(`${FORM_ID}.documentType.required`)),
                }),
            }),
        handleSubmit: ({ document }, formikBag) => {
            const { dispatch } = formikBag.props;

            dispatch(SelectorsActionUserInvite.userInviteVerify({ document, captcha: null, formikBag }));
        },
    }),
)(Component);
