import React from "react";

import { Form, Field, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import * as Yup from "yup";

import { EMPTY_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import {
    SelectorsAction as SelectorsActionSwiftMessages,
    SelectorsStore as SelectorsStoreSwiftMessages,
} from "~/store/swift";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import EmailList from "~/pages/_components/fields/EmailList";

import Style from "./DetailSendMail.rules.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: { messageDetail: PropTypes.object },
    defaults: { messageDetail: null },
};

const FORM_ID = "swift.detail.sendMail";

export function Component(props) {
    const { isFetchingSendMail, dispatch, emailValidationRegex, values, errors } = props;
    const { emails } = values;
    const hasError = !(values.emails.length > 0) || errors.emails !== null; // si esta vacio o si hay algun error

    function handleCloseModal() {
        dispatch(SelectorsActionSwiftMessages.setShowSendMailModal({ showSendMailModal: false }));
    }

    return (
        <Form className={CLASS}>
            <Field
                component={EmailList}
                idForm={FORM_ID}
                name="emails"
                className="form-group"
                renderSuggestion={false}
                data={{ emailValidationRegex, addMessage: EMPTY_STR }}
                value={emails}
            />
            <footer>
                <Button label="global.cancel" onClick={handleCloseModal} block={false} />
                <Button
                    label="global.send"
                    className="btn-primary"
                    loading={isFetchingSendMail}
                    loadingLabel="global.sending"
                    block={false}
                    disabled={hasError}
                    type="submit"
                />
            </footer>
        </Form>
    );
}

const mapStateToProps = (store) => ({
    isFetchingSendMail: SelectorsStoreSwiftMessages.isFetchingSendMail(store),
    emailValidationRegex: SelectorsStoreConfig.getConfig(store)["email.validationFormat"],
});

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            emails: [],
            currentError: null,
        }),
        validationSchema: () =>
            Yup.lazy(({ currentError }) => {
                return Yup.object().shape({
                    emails: Yup.array(Yup.string()).min(
                        1,
                        currentError || UtilsI18n.get("client.swift.sendMail.emptyEmails"),
                    ),
                });
            }),
        handleSubmit: ({ emails }, formikBag) => {
            const { dispatch, messageDetail } = formikBag.props;
            dispatch(
                SelectorsActionSwiftMessages.swiftMessagesDetailSendMailRequest({ messageDetail, emails, formikBag }),
            );
        },
    }),
)(Component);
