import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR, CDP_CURRENCY } from "~/constants";

import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import ItemExpirationDate from "~/pages/_components/item/ExpirationDate";
import ItemInfo from "~/pages/_components/item/Info";
import ItemName from "~/pages/_components/item/Name";

export const NAME = "ListItemMobile";

export const PROP = {
    types: {
        single: PropTypes.bool.isRequired,
        path: PropTypes.string,
        name: PropTypes.string,
        title: PropTypes.string,
        reference: PropTypes.string,
        icon: PropTypes.string.isRequired,
        expirationDate: PropTypes.string,
        dueDate: PropTypes.string,
        expirationText: PropTypes.string.isRequired,
        paymentUSD: PropTypes.number,
        amount: PropTypes.number,
        number: PropTypes.string,
        amountLabel: PropTypes.string,
        currency: PropTypes.string,
        isLoan: PropTypes.bool,
        installmentAmountUI: PropTypes.string,
    },
    defaults: {
        path: false,
        name: EMPTY_STR,
        title: EMPTY_STR,
        reference: EMPTY_STR,
        expirationDate: EMPTY_STR,
        dueDate: EMPTY_STR,
        paymentUSD: 0,
        amount: 0,
        number: EMPTY_STR,
        amountLabel: EMPTY_STR,
        currency: EMPTY_STR,
        isLoan: false,
        installmentAmountUI: null,
    },
};

export function Component(props) {
    const {
        path,
        name,
        reference,
        icon,
        expirationDate,
        dueDate,
        expirationText,
        amount,
        paymentUSD,
        amountLabel,
        single,
        number,
        isExpired,
        isLoan,
        currency,
        isAccount,
        installmentAmountUI,
    } = props;

    return (
        <Link className={`newProduct ${isExpired ? "is-expired" : ""}`} to={path}>
            <div className="newProduct-row newProduct-row--header">
                {!single && (
                    <div className="newProduct-cell newProduct-cell--ellipsis">
                        <ItemName name={name} />
                        <p className="data-product">
                            <ItemInfo reference={reference} />
                        </p>
                    </div>
                )}
                {icon && (
                    <div className="newProduct-cell">
                        <Image src={`${icon}.svg`} className="svg-icon" />
                    </div>
                )}
            </div>

            <div className="newProduct-row">
                {expirationDate && (
                    <div className="newProduct-cell">
                        <span>
                            <ItemName name={`${name} ${number}`} />
                        </span>
                        <ItemExpirationDate date={dueDate} expirationText={expirationText} />
                    </div>
                )}
                <div className="newProduct-cell text-right">
                    <div>
                        {!isLoan && !isAccount && <I18n id="widget.creditCard.totalToPay" />}
                        <div className="data-label">{amountLabel}</div>
                        <FormattedAmount
                            quantity={amount}
                            currency={isLoan || !expirationDate ? currency : CDP_CURRENCY.UYU}
                            quatityNote={installmentAmountUI ? `(${installmentAmountUI})` : null}
                        />
                        {expirationDate && !isLoan && (
                            <FormattedAmount quantity={paymentUSD} currency={CDP_CURRENCY.USD} />
                        )}
                    </div>
                </div>
            </div>
        </Link>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
