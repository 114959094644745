import React from "react";

import classNames from "classnames";
import { Field } from "formik";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { PERMISSIONS } from "~/constants";
import { SelectorsStore as SelectorsStorePermissions } from "~/store/administration/common/permissions";
import * as AdministrationUtils from "~/util/administration";

import I18n from "~/components/I18n";
import { AccordionContext } from "~/pages/_components/Accordion";
import IndeterminateCheckbox from "~/pages/_components/IndeterminateCheckbox";
import withContext from "~/pages/_components/withContext";

import { PermissionsPanelContext } from "~/pages/administration/_components/advancedPermissionsForm/PermissionsPanel";

export const NAME = "PermissionField";

export const PROP = {
    types: {
        setValues: PropTypes.func.isRequired,
        groups: AdministrationUtils.groupsPropType.isRequired,
        values: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
        products: PropTypes.arrayOf(
            PropTypes.shape({
                productType: PropTypes.string,
            }),
        ).isRequired,
        idItem: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        permissionList: PropTypes.arrayOf(
            PropTypes.shape({
                productTypes: PropTypes.string,
            }),
        ).isRequired,
        number: PropTypes.number,
        parentOption: PropTypes.shape({
            number: PropTypes.number,
        }),
        mode: PropTypes.oneOf(["view", "edit"]).isRequired,
    },
    defaults: { products: [], number: null, parentOption: null },
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidUpdate({ accordionContext }) {
        const { itemOpen: prevItemOpen } = accordionContext;
        const {
            permissionsPanelContext: { onBlur, activeIdItem },
            accordionContext: { itemOpen },
            number,
            idItem,
            parentOption,
        } = this.props;
        const index = number !== null ? number : parentOption.number;

        // If another accordion item was opened, close this panel
        if (prevItemOpen === index && itemOpen !== index && activeIdItem === idItem) {
            onBlur();
        }
    }

    handleCheckClick = (field, form, productTypes) => {
        const { setValues, values } = form;
        const { name } = field;

        setValues({
            ...values,
            permissions: {
                ...values.permissions,
                [name]: values.permissions[name] && values.permissions[name].length ? [] : productTypes,
            },
        });
    };

    handleClick = () => {
        const {
            permissionsPanelContext: { onClick: panelHandleClick },
            accordionContext: { onClick: accordionHandleClick, itemOpen },
            number,
            parentOption,
        } = this.props;
        const index = number !== null ? number : parentOption.number;

        if (itemOpen !== index) {
            accordionHandleClick(index);
        }
        panelHandleClick(this.props);
    };

    render() {
        const {
            permissionsPanelContext: { activeIdItem },
            idItem,
            permissionList,
            products,
            mode,
        } = this.props;
        const [permissions] = permissionList;

        return (
            <Field name={idItem} key={idItem}>
                {({ form, field }) => {
                    const values = form.values.permissions[idItem] || [];
                    const productTypes = permissions.productTypes.split(",");
                    const mappedProductTypes = productTypes.map((productType) => `ALL_${productType}`);
                    const filteredProducts = products.filter(({ productType }) => productTypes.includes(productType));
                    const { selectedSmartGroupAmount, smartGroupAmount } = AdministrationUtils.smartGroupsOptionsCount(
                        productTypes,
                        values,
                    );
                    const { selectedProductsAmount, productsAmount } = AdministrationUtils.productsOptionsCount(
                        filteredProducts.map(({ value }) => ({ idProduct: value })),
                        values,
                    );
                    const smartGroupAmountText = `${selectedSmartGroupAmount} / ${smartGroupAmount}`;

                    return (
                        <button
                            className={classNames("collapsable-button", {
                                "is-active": activeIdItem === idItem,
                            })}
                            type="button"
                            onClick={this.handleClick}>
                            {mode === "edit" && (
                                <IndeterminateCheckbox
                                    id={idItem}
                                    onCheckClick={() => this.handleCheckClick(field, form, mappedProductTypes)}
                                    className="c-control c-control--has-icon c-control--checkbox"
                                    selectedOptionsAmount={
                                        selectedSmartGroupAmount > selectedProductsAmount
                                            ? selectedSmartGroupAmount
                                            : selectedProductsAmount
                                    }
                                    optionsAmount={
                                        selectedSmartGroupAmount > selectedProductsAmount
                                            ? smartGroupAmount
                                            : productsAmount
                                    }
                                />
                            )}
                            <span>
                                <I18n id={`administration.permissions.advanced.${idItem}`} />
                                {field.name === PERMISSIONS.PRODUCT_READ && (
                                    <React.Fragment>
                                        <span className="list-item-hint">{smartGroupAmountText}</span>
                                    </React.Fragment>
                                )}
                            </span>
                        </button>
                    );
                }}
            </Field>
        );
    }
}

const mapStateToProps = (state) => ({
    products: SelectorsStorePermissions.getMappedProducts(state),
});

export default compose(
    connect(mapStateToProps),
    withContext(PermissionsPanelContext, "permissionsPanelContext"),
    withContext(AccordionContext, "accordionContext"),
)(Component);
