import React from "react";

import { push as Push } from "connected-react-router";
import { withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EVENT_BACKBUTTON } from "~/constants";
import Container from "~/containers/External/Form";
import { Defaults as DefaultsHtmlElement, Types as TypesHtmlElement } from "~/util/prop/html-element";
import { Defaults as DefaultsRedux, Types as TypesRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";

import Style from "./FinishMigration.rules.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {
        ...TypesHtmlElement,
        ...TypesRedux,
        exchangeToken: PropTypes.string.isRequired,
        finishState: PropTypes.string.isRequired,
    },
    defaults: {
        ...DefaultsHtmlElement,
        ...DefaultsRedux,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    componentDidMount() {
        document.addEventListener(EVENT_BACKBUTTON, this.backButtonAction);
    }

    componentWillUnmount() {
        document.removeEventListener(EVENT_BACKBUTTON, this.backButtonAction);
    }

    backButtonAction = () => {
        const { dispatch } = this.props;
        dispatch(Push("/desktop"));
    };

    handleDesktop = () => {
        const { dispatch } = this.props;
        dispatch(Push("/desktop"));
    };

    render() {
        const { isSubmitting } = this.props;
        return (
            <Container name={NAME} head-onClose={this.handleDesktop}>
                <div className={`migration ${CLASS}`}>
                    <div className="success">
                        <div className="success-block">
                            <div className="migration-row simple-row">
                                <span className="migration-message">
                                    <Image src="finished.svg" className="svg-icon" />
                                </span>
                            </div>
                            <div className="migration-row simple-row">
                                <span className="migration-message">
                                    <I18n id="migration.finish.body" />
                                </span>
                            </div>
                            <div className="migration-row main-row">
                                <div className="migration-form-field button-field">
                                    <Button
                                        className="success-form-button"
                                        bsStyle="primary"
                                        loading={isSubmitting}
                                        label="migration.finish.link"
                                        onClick={this.handleDesktop}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        );
    }
}

export default HighOrder(
    Connect(null),
    HighOrder.Resizable,
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        handleSubmit: () => {},
    }),
)(Component);
