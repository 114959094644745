import React from "react";

import { EMPTY_STR } from "~/constants";
import { PROP as STORE_PROP } from "~/store/preferentialTradingPrice";

import Button from "~/components/Button";
import Modal from "~/components/Modal";

export const NAME = "ModalFX";

export const PROP = {
    types: {
        ...STORE_PROP.types,
    },
    defaults: {
        ...STORE_PROP.defaults,
        dispatch: () => null,
        path: { EMPTY_STR },
    },
};
export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {};

    render() {
        const { show, handleClose, messageTitle, messageBody } = this.props;
        return (
            <Modal show={show}>
                <div className="modal-text">
                    <h2>{messageTitle}</h2>
                    <p className="text-lead">
                        <span>{messageBody}</span>
                    </p>
                </div>

                <div className="modal-buttons">
                    <div className="justify-content-center row">
                        <Button className="btn-primary" label="global.accept" onClick={handleClose} />
                    </div>
                </div>
            </Modal>
        );
    }
}

export default Component;
