import { takeLatest as TakeLatest, call as Call, put as Put } from "redux-saga/effects";

import { LEVEL, SCOPE, RESPONSE_TYPE } from "~/constants";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { downloadPdf } from "~/util/download";
import * as i18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsMiddleware } from "./_selectors";

export default [
    TakeLatest(TYPE.READ_POSITION_REQUEST, readPositionRequest),
    TakeLatest(TYPE.GET_POSITION_PDF, getPositionPDF),
];

function* readPositionRequest({ productTypes }) {
    const response = yield Call(SelectorsMiddleware.readPosition, { productTypes });

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.listSwiftMessagesFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: i18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.POSITION],
            }),
        );
    } else {
        const { data } = response.data;

        yield Put(SelectorsAction.readPositionSuccess(data));
    }
}

function* getPositionPDF({ downloadDate, order }) {
    const response = yield Call(SelectorsMiddleware.getPositionPDF, { date: downloadDate, order });

    if (response && response.status === 200) {
        const { content, fileName } = response.data.data;

        downloadPdf(fileName, content);

        yield Put({
            type: TYPE.GET_POSITION_PDF_SUCCESS,
        });
    }
}
