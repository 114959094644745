import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "backoffice";

export const PATH = {
    MISC_READ_ENVIRONMENT_DATA: "/misc.read.environment.data",
    MISC_READ_USER_DATA: "/misc.read.user.data",
    READ_BACKOFFICE_TRANSACTION: "/core.forms.backoffice.read",
};

export const TYPE = FactoryType(NAME, [
    "BACKOFFICE_TRANSACTION_FAILURE",
    "BACKOFFICE_TRANSACTION_REQUEST",
    "BACKOFFICE_TRANSACTION_SUCCESS",
    "READ_ENVIRONMENT_DATA_FAILURE",
    "READ_ENVIRONMENT_DATA_REQUEST",
    "READ_ENVIRONMENT_DATA_SUCCESS",
    "READ_USER_DATA_FAILURE",
    "READ_USER_DATA_REQUEST",
    "READ_USER_DATA_SUCCESS",
]);

export const PROP = {};

PROP.types = {
    ...TypesRedux,
    data: PropTypes.object,
    environmentData: PropTypes.object,
    fetching: PropTypes.bool,
    formMetadata: PropTypes.object,
    idForm: PropTypes.string,
    transaction: PropTypes.object,
    userData: PropTypes.object,
};

PROP.defaults = {
    ...DefaultsRedux,
    data: null,
    environmentData: null,
    fetching: false,
    formMetadata: null,
    idForm: null,
    transaction: null,
    userData: null,
};
