import PropTypes from "prop-types";

import { EMPTY_STR, NOT_ES } from "~/constants";
import { MODE } from "~/constants/form";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "comexExport";

export const PATH = {
    TRANSFER_CREDIT_LETTER_PRE_REQUEST: "/comex.export.transferCreditLetter.pre",
    TRANSFER_CREDIT_LETTER_PREVIEW_REQUEST: "/comex.export.transferCreditLetter.preview",
    TRANSFER_CREDIT_LETTER_REQUEST: "/comex.export.transferCreditLetter.send",

    PRESENTATION_CREDIT_LETTER_PRE_REQUEST: "/comex.export.presentationDocumentsCreditLetter.pre",
    PRESENTATION_CREDIT_LETTER_PREVIEW_REQUEST: "/comex.export.presentationDocumentsCreditLetter.preview",
    PRESENTATION_CREDIT_LETTER_REQUEST: "/comex.export.presentationDocumentsCreditLetter.send",

    PRESENT_BILLING_DOCUMENTS_PRE_REQUEST: "/comex.export.presentBillingDocuments.pre",
    PRESENT_BILLING_DOCUMENTS_PREVIEW_REQUEST: "/comex.export.presentBillingDocuments.preview",
    PRESENT_BILLING_DOCUMENTS_SEND_REQUEST: "/comex.export.presentBillingDocuments.send",

    PURCHASE_DOCUMENTS_PRE_REQUEST: "/comex.export.buyCreditLetter.pre",
    PURCHASE_DOCUMENTS_PREVIEW_REQUEST: "/comex.export.buyCreditLetter.preview",
    PURCHASE_DOCUMENTS_SEND_REQUEST: "/comex.export.buyCreditLetter.send",

    BILLING_CLAIM_SEND_REQUEST: "/comex.export.billingClaim.send",
    BILLING_CLAIM_PREVIEW_REQUEST: "/comex.export.billingClaim.preview",
    BILLING_CLAIM_PRE_REQUEST: "/comex.export.billingClaim.pre",

    AUTHORIZATION_DOCUMENTS_DISCREPANCIES_PRE_REQUEST: "/comex.export.discrepancyDocumentAuthorization.pre",
    AUTHORIZATION_DOCUMENTS_DISCREPANCIES_PREVIEW_REQUEST: "/comex.export.discrepancyDocumentAuthorization.preview",
    AUTHORIZATION_DOCUMENTS_DISCREPANCIES_SEND_REQUEST: "/comex.export.discrepancyDocumentAuthorization.send",

    CESSION_CREDIT_LETTER_PRE_REQUEST: "/comex.export.cessionCreditLetter.pre",
    CESSION_CREDIT_LETTER_PREVIEW_REQUEST: "/comex.export.cessionCreditLetter.preview",
    CESSION_CREDIT_LETTER_SEND_REQUEST: "/comex.export.cessionCreditLetter.send",
};

export const TYPE = FactoryType(NAME, [
    /** TRANSFER CREDIT LETTER */
    "TRANSFER_CREDIT_LETTER_PRE_REQUEST",
    "TRANSFER_CREDIT_LETTER_PRE_SUCCESS",
    "TRANSFER_CREDIT_LETTER_PRE_FAILURE",
    "TRANSFER_CREDIT_LETTER_PREVIEW_REQUEST",
    "TRANSFER_CREDIT_LETTER_PREVIEW_SUCCESS",
    "TRANSFER_CREDIT_LETTER_PREVIEW_FAILURE",
    "TRANSFER_CREDIT_LETTER_SEND_FORM",
    "TRANSFER_CREDIT_LETTER_SEND_FORM_SUCCESS",
    "TRANSFER_CREDIT_LETTER_SEND_FORM_FAILURE",

    /** PRESENTATION CREDIT LETTER */
    "PRESENTATION_CREDIT_LETTER_PRE_REQUEST",
    "PRESENTATION_CREDIT_LETTER_PRE_SUCCESS",
    "PRESENTATION_CREDIT_LETTER_PRE_FAILURE",

    "PRESENTATION_CREDIT_LETTER_PREVIEW_REQUEST",
    "PRESENTATION_CREDIT_LETTER_PREVIEW_SUCCESS",
    "PRESENTATION_CREDIT_LETTER_PREVIEW_FAILURE",

    "PRESENTATION_CREDIT_LETTER_SEND_FORM",
    "PRESENTATION_CREDIT_LETTER_SEND_FORM_SUCCESS",
    "PRESENTATION_CREDIT_LETTER_SEND_FORM_FAILURE",
    /** ---------------------------------- */

    /** PRESENT BILLING DOCUMENTS */
    "PRESENT_BILLING_DOCUMENTS_PRE_REQUEST",
    "PRESENT_BILLING_DOCUMENTS_PRE_SUCCESS",
    "PRESENT_BILLING_DOCUMENTS_PRE_FAILURE",
    "PRESENT_BILLING_DOCUMENTS_PREVIEW_REQUEST",
    "PRESENT_BILLING_DOCUMENTS_PREVIEW_SUCCESS",
    "PRESENT_BILLING_DOCUMENTS_PREVIEW_FAILURE",
    "PRESENT_BILLING_DOCUMENTS_SEND_REQUEST",
    "PRESENT_BILLING_DOCUMENTS_SEND_SUCCESS",
    "PRESENT_BILLING_DOCUMENTS_SEND_FAILURE",
    /** ---------------------------------- */

    /** Purchase Documents */
    "PURCHASE_DOCUMENTS_PRE_REQUEST",
    "PURCHASE_DOCUMENTS_PRE_SUCCESS",
    "PURCHASE_DOCUMENTS_PRE_FAILURE",

    "PURCHASE_DOCUMENTS_PREVIEW_REQUEST",
    "PURCHASE_DOCUMENTS_PREVIEW_SUCCESS",
    "PURCHASE_DOCUMENTS_PREVIEW_FAILURE",

    "PURCHASE_DOCUMENTS_SEND_FORM",
    "PURCHASE_DOCUMENTS_SEND_FORM_SUCCESS",
    "PURCHASE_DOCUMENTS_SEND_FORM_FAILURE",
    /** ---------------------------------- */

    /** BILLING CLAIM */
    "BILLING_CLAIM_PRE_REQUEST",
    "BILLING_CLAIM_PRE_SUCCESS",
    "BILLING_CLAIM_PRE_FAILURE",
    "BILLING_CLAIM_PREVIEW_REQUEST",
    "BILLING_CLAIM_PREVIEW_SUCCESS",
    "BILLING_CLAIM_PREVIEW_FAILURE",
    "BILLING_CLAIM_SEND_REQUEST",
    "BILLING_CLAIM_SEND_SUCCESS",
    "BILLING_CLAIM_SEND_FAILURE",

    /** AUTHORIZATION DOCUMENTS DISCREPANCIES */
    "AUTHORIZATION_DOCUMENTS_DISCREPANCIES_PRE_REQUEST",
    "AUTHORIZATION_DOCUMENTS_DISCREPANCIES_PRE_SUCCESS",
    "AUTHORIZATION_DOCUMENTS_DISCREPANCIES_PRE_FAILURE",
    "AUTHORIZATION_DOCUMENTS_DISCREPANCIES_PREVIEW_REQUEST",
    "AUTHORIZATION_DOCUMENTS_DISCREPANCIES_PREVIEW_SUCCESS",
    "AUTHORIZATION_DOCUMENTS_DISCREPANCIES_PREVIEW_FAILURE",
    "AUTHORIZATION_DOCUMENTS_DISCREPANCIES_SEND_REQUEST",
    "AUTHORIZATION_DOCUMENTS_DISCREPANCIES_SEND_SUCCESS",
    "AUTHORIZATION_DOCUMENTS_DISCREPANCIES_SEND_FAILURE",

    /** CESSION CREDIT LETTER */
    "CESSION_CREDIT_LETTER_PRE_REQUEST",
    "CESSION_CREDIT_LETTER_PRE_SUCCESS",
    "CESSION_CREDIT_LETTER_PRE_FAILURE",

    "CESSION_CREDIT_LETTER_PREVIEW_REQUEST",
    "CESSION_CREDIT_LETTER_PREVIEW_SUCCESS",
    "CESSION_CREDIT_LETTER_PREVIEW_FAILURE",

    "CESSION_CREDIT_LETTER_SEND_REQUEST",
    "CESSION_CREDIT_LETTER_SEND_SUCCESS",
    "CESSION_CREDIT_LETTER_SEND_FAILURE",

    "COMEX_FORM_SEND_DATA_FAILURE",

    "RESTART_FLOW",
]);

export const PROP = {};

PROP.members = {};

PROP.types = {
    ...TypesRedux,
    fetching: PropTypes.bool,
    mode: PropTypes.string,
    transaction: PropTypes.object,
    transactionList: PropTypes.array,
    comexForm: PropTypes.object,
};

PROP.defaults = {
    ...DefaultsRedux,
    amountString: EMPTY_STR,
    banks: [],
    fetching: false,
    mode: MODE.EDIT,
    positions: {
        positionExport: [],
        positionExportTotal: 0,
        positionGuarantee: [],
        positionGuaranteeTotal: 0,
        positionImport: [],
        positionImportTotal: 0,
        positionPrefinancing: [],
    },
    prevMode: EMPTY_STR,
    selectedBank: {},
    showBankSearch: false,
    transaction: {},
    transactionList: false,
    comexForm: {
        preData: {
            availableOperationList: [],
            countryList: [],
            currencyList: [],
            maxDaysToSchedule: 0,
            nextValidDate: EMPTY_STR,
            unavailableDays: [],
        },
        formData: {},
        prevData: {
            discrepancies: NOT_ES,
        },
    },
    presentBillingDocuments: {
        preData: {
            documentList: [],
            currencyList: [],
            maxDaysToSchedule: 0,
            nextValidDate: EMPTY_STR,
            unavailableDays: [],
        },
        formData: {},
        idForm: "comex.export.presentBillingDocuments.form",
    },
    billingClaim: {
        preData: {
            nextValidDate: EMPTY_STR,
            availableOperationList: [],
            maxDaysToSchedule: 0,
            unavailableDays: [],
            currencyList: [],
            message: EMPTY_STR,
        },
        formData: {},
        prevData: {},
    },
    authorizationDocumentsDiscrepancies: {
        preData: {
            nextValidDate: EMPTY_STR,
            availableOperationList: [],
            maxDaysToSchedule: 0,
            unavailableDays: [],
            currencyList: [],
            message: EMPTY_STR,
        },
        formData: {},
        prevData: {},
    },
};
