import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/Simple";
import { SelectorsAction, SelectorsStore } from "~/store/communications";

import Style from "~/pages/communications/CommunicationMain.rules.scss";
import Reader from "~/pages/communications/_components/Reader";

export const { NAME } = Style;
export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        detail: PropTypes.shape({
            idCommunication: PropTypes.number,
            subject: PropTypes.string,
            communicationTray: PropTypes.shape({
                name: PropTypes.string,
            }),
            body: PropTypes.string,
            sentDateAsString: PropTypes.string,
        }),
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            }).isRequired,
        }).isRequired,
        isFetching: PropTypes.bool.isRequired,
        history: PropTypes.shape({
            goBack: PropTypes.func.isRequired,
        }).isRequired,
    },
    defaults: { detail: null },
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        const { dispatch, match } = this.props;
        const { idThread, idCommunication } = match.params;
        dispatch(SelectorsAction.detailRequest({ idThread, idCommunication }));
    }

    goBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    render() {
        const { detail, isFetching } = this.props;

        let auxDetail = {};
        if (detail) {
            auxDetail = { ...detail };
        }
        return (
            <Container
                name={NAME}
                head-title={auxDetail.subject}
                wait={isFetching}
                head-onBackWeb={this.goBack}
                head-onClose={this.goBack}
                className={Style.CLASS}
                scopeToShowNotification={SCOPE.COMMUNICATIONS}>
                <div>
                    <Reader />
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    detail: SelectorsStore.detail(store),
    isFetching: SelectorsStore.isFetching(store),
});

export default Connect(mapStateToProps)(Component);
