import React from "react";

import * as i18nUtils from "~/util/i18n";

import HighOrder from "~/components/HighOrder";
import Container from "~/pages/_components/Container";

import BankSearchItem from "~/pages/forms/_components/_fields/_bankselector/bankSearch/Item";

import Style from "./_BankSearchList.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {},
    defaults: {},
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    renderMoreResults = () => {
        const { hasMoreResults } = this.props;
        let element = null;

        if (hasMoreResults) {
            element = (
                <div className="table-row">
                    <div className="table-data">{i18nUtils.get("forms.bankselector.hasMoreResults")}</div>
                </div>
            );
        }

        return element;
    };

    render() {
        const { items, onSelect } = this.props;

        return (
            <Container className={Style.CLASS}>
                <div className="table">
                    <div className="table-body">
                        {items.map((item) => (
                            <BankSearchItem key={item.code} {...item} onSelect={onSelect} />
                        ))}
                        {this.renderMoreResults()}
                    </div>
                </div>
            </Container>
        );
    }
}

export default HighOrder.Resizable(Component);
