import React from "react";

import * as StoreRouter from "connected-react-router";
import { push as Push } from "connected-react-router";
import { withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { Col, Grid, Row } from "react-bootstrap";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";

import { FORM_PATH } from "~/constants";
import { STATUS, STATUS_FINAL } from "~/constants/transaction";
import { SelectorsStore as SelectorsStoreForm } from "~/store/form";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsStore as StoreSession } from "~/store/session";
import { SelectorsStore as SelectorsStoreTemplate } from "~/store/template";
import { flattenArray as FlattenArray, removeDuplicateItems as RemoveDuplicateItems } from "~/util/array";
import DeviceUtils from "~/util/device";
import { generateId as Id } from "~/util/general";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import FormattedDate from "~/pages/_components/FormattedDate";
import TicketPrintInformation from "~/pages/_components/TicketPrintInformation";

import TransactionStatus from "~/pages/transactions/_components/TransactionStatus";

import FieldHandler from "../fieldHandler";
import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        transaction: PropTypes.shape({
            executing: PropTypes.bool,
            idTransaction: PropTypes.string,
            idTransactionStatus: PropTypes.string,
            valueDate: PropTypes.string,
            transactionData: PropTypes.object,
        }),
        metadata: PropTypes.object,
        values: PropTypes.object,
        ticketConfirmation: PropTypes.bool, // TODO: revisar
        parentTransaction: PropTypes.object,
        childrenTransactions: PropTypes.array,
        idUser: PropTypes.string,
    },
    defaults: {
        transaction: {},
        metadata: {},
        values: {},
        ticketConfirmation: false,
        parentTransaction: {},
        childrenTransactions: [],
        idUser: null,
    },
};

export function Component(props) {
    const { transaction, ticketConfirmation, location } = props;

    const { idTransaction, idTransactionStatus, fullDescription, signatures } = transaction;
    const isMobile = DeviceUtils.isDisplayMobile();
    const isFinal = STATUS_FINAL.includes(idTransactionStatus);

    const [hideDetails] = React.useState(isMobile && location.pathname.includes(FORM_PATH));
    const dateTimeFormat = "DD/MM/YYYY HH:mm";

    // TODO: Mejorar la logica. Esto aplica para solucionar el problema de la vista de las acciones para los ticket
    let shouldDisplayTicketCollapsible = false;
    if (DeviceUtils.isDisplayMobile()) {
        // en vista mobile se muestra segun acciones de los tickets, en desktop siempre oculto
        shouldDisplayTicketCollapsible = location.pathname.includes(FORM_PATH)
            ? ticketConfirmation
            : idTransactionStatus !== STATUS.PENDING && idTransactionStatus !== STATUS.SCHEDULED;
    }

    return (
        <div id={Style.ID}>
            <section className="container--layout align-items-center flex-grows">
                <Grid className="form-content">
                    <Row className="justify-content-center">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <TicketPrintInformation />
                            <div className="media-object ">
                                <figure>
                                    <TransactionStatus idTransactionStatus={idTransactionStatus} />
                                </figure>
                                <div className="media-object--text">
                                    <p className="text-lead">
                                        <I18n id={`forms.transaction.ticket.status.${idTransactionStatus}`} />
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </section>

            <section
                className={`container--layout align-items-center ${
                    !shouldDisplayTicketCollapsible || (!hideDetails && "login-background-wrapper")
                }`}>
                <Grid className="form-content">
                    <div className="datosTrans">
                        <ul>
                            <li className="title">
                                <I18n id="forms.transaction.ticket.idTransaction" />
                            </li>
                            <li>{idTransaction}</li>
                            <div className="clearfix" />
                        </ul>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: fullDescription }} />
                    {signatures && signatures.length > 0 && (
                        <div className="datosTrans">
                            <ul>
                                <li className="title">
                                    <I18n id="forms.transaction.ticket.participants" />
                                </li>
                                {signatures.map((sig) => (
                                    <li key={Id()}>
                                        {sig.userFullName} ({sig.usernameLabel ? sig.usernameLabel : sig.idUser}) -{" "}
                                        <FormattedDate date={sig.creationDateTime} customFormat={dateTimeFormat} />{" "}
                                        <br />
                                    </li>
                                ))}
                                <div className="clearfix" />
                            </ul>
                        </div>
                    )}

                    {!DeviceUtils.isMobileNative() && isFinal && (
                        <Row>
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                        <div className="buttonsTicketConfirmation">
                                            <a onClick={() => printPage()}>
                                                <I18n id="forms.ticket.print" />
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            </Grid>
                        </Row>
                    )}
                </Grid>
            </section>

            {ticketConfirmation && (
                <React.Fragment>
                    <section className="container--layout align-items-center">
                        <Grid className="form-content">
                            <Row className="justify-content-center">
                                <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                    <Button
                                        label="forms.returnDesktop.link"
                                        bsStyle="primary"
                                        onClick={handleDesktop}
                                    />
                                </Col>
                            </Row>
                        </Grid>
                    </section>
                </React.Fragment>
            )}
        </div>
    );

    function printPage() {
        window.print();
    }

    function handleDesktop() {
        const { dispatch } = props;
        dispatch(Push("/"));
    }
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

function mapStateToProps(store) {
    return {
        values: SelectorsStoreForm.getValues(store),
        location: StoreRouter.getLocation(store),
        parentTransaction: SelectorsStoreForm.getParentTransaction(store),
        childrenTransactions: SelectorsStoreForm.getChildrenTransactions(store),
        metadata: SelectorsStoreForm.getMetadata(store),
        isCancellingTransaction: SelectorsStoreForm.getIsCancellingTransaction(store),
        isVisible: SelectorsStoreTemplate.isCreateModalVisible(store),
        formIsFetching: SelectorsStoreForm.getFetching(store),
        transactionCredential: SelectorsStoreForm.getTransactionCredential(store),
        credentials: Compose(
            (array) => array.filter((item) => item !== "accessToken"),
            RemoveDuplicateItems,
            FlattenArray,
            (array) => array.map(({ credentials }) => credentials),
        )(SelectorsStoreForm.getCredentialsGroups(store)),
        idUser: StoreSession.getUserId(store),
        lang: StoreI18n.getLang(store) || window.navigator.language.slice(0, 2),
    };
}

export default HighOrder(Connect(mapStateToProps), WithFormik(FieldHandler))(Component);
