import React from "react";

import { routerActions as ActionsRouter } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { API_FILE_PROCESS_ERROR, CDP_CURRENCY, VALIDATION_ERROR, SCOPE, LEVEL, EMPTY_STR } from "~/constants";
import { ID_FORM, MODE, SCHEDULER_OPTIONS } from "~/constants/form";
import { SelectorsAction as SelectorsActionFile } from "~/store/files";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import {
    SelectorsAction as SelectorsActionMultilineFile,
    SelectorsStore as SelectorsStoreMultilineFile,
} from "~/store/multilinefile";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import {
    SelectorsAction as SelectorsActionTransactionLines,
    SelectorsStore as SelectorsStoreTransactionLines,
} from "~/store/transactionLines";
import * as UtilsConfig from "~/util/config";
import { isFutureDate } from "~/util/date";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";
import FileUploader from "~/pages/_components/FileUploader";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import DetailBox from "~/pages/_components/detailBox/DetailBox";
import FieldError from "~/pages/_components/fields/FieldError";

import FileActions from "~/pages/forms/_components/_fields/_commons/FileActions";

export const NAME = "FilePayment";

export const PROP = {
    types: {
        ...TypesRedux,
        idFile: PropTypes.number,
        idRelatedFile: PropTypes.string,
        localBanks: PropTypes.array,
        paymentCurrency: PropTypes.string.isRequired,
        processedFileData: PropTypes.shape().isRequired,
        setValue: PropTypes.func.isRequired,
    },
    defaults: {
        ...DefaultsRedux,
        localBanks: [],
        processedFileData: null,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        const { dispatch, localBanks, processedFileData, setValue } = this.props;

        if (!this.isReadOnly() && localBanks.length === 0) {
            dispatch(SelectorsActionTransactionLines.listLocalBanksRequest());
        }

        if (processedFileData.hasFile) {
            setValue([processedFileData.filesMetadata]);
        }
    }

    handleFileProcess = (response) => {
        const { dispatch, form, idForm } = this.props;
        const { data } = response;
        const { setValues, values } = form;
        const param = { ...data, idForm };

        if (response.code !== API_FILE_PROCESS_ERROR && response.code !== VALIDATION_ERROR) {
            let origin = null;

            if (idForm === ID_FORM.SUPPLIERS_PAYMENT) {
                const currencies = this.props.fieldList.find(
                    (field) => field.idField === "currencySelector",
                ).optionList;
                const { id: currencyId } = currencies.find((currency) => currency.id === data.totalAmount.currency);

                origin = { currencySelector: [currencyId] };
            } else if (idForm === ID_FORM.SALARY_PAYMENT) {
                const accounts = this.props.fieldList.find((field) => field.idField === "account").data.options;
                const { id: accountId, frequentDestination: isFrequentDestination } = accounts.find(
                    (account) => account.currency === data.totalAmount.currency,
                );

                origin = { account: { value: accountId, isFrequentDestination } };
            }

            origin = {
                ...values,
                ...origin,
                debitReference: data.debitReference,
                scheduler: {
                    editing: true,
                    selectedOption: isFutureDate(data.debitDate) ? SCHEDULER_OPTIONS.FUTURE : SCHEDULER_OPTIONS.TODAY,
                    valueDate: data.debitDate,
                },
            };

            setValues(origin, false);
            dispatch(SelectorsActionMultilineFile.onFileProcess(param));
        } else {
            dispatch(SelectorsActionMultilineFile.onFileRemoved());
            dispatch(SelectorsActionMultilineFile.cleanPond({ forcePondCleanUp: true }));
            dispatch(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get(data.message),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.FORM],
                }),
            );
        }
    };

    isReadOnly = () => {
        const { mode } = this.props;

        return mode === MODE.VIEW || mode === MODE.PREVIEW;
    };

    renderLinesInfo = (downloadFile) => {
        const { processedFileData } = this.props;
        const { invalidLines, validLines } = processedFileData;

        if (!invalidLines) {
            return (
                <React.Fragment>
                    <div>
                        {validLines}
                        <I18n className="total-line" id="forms.inputFile.massivePayments.total.lines" />
                    </div>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <div>
                    {validLines + invalidLines}
                    <I18n id="forms.inputFile.massivePayments.total.lines" />
                </div>
                <div>
                    <I18n id="forms.inputFile.massivePayments.invalid.lines" /> {invalidLines} / {validLines}{" "}
                    <I18n id="forms.inputFile.massivePayments.valid.lines" />
                </div>
                <button
                    type="button"
                    className="btn btn-asLink btn-dl-error"
                    onClick={() => downloadFile({ isDownloadingRelatedFile: true })}>
                    <I18n id="forms.inputFile.massivePayments.download.errors" />
                </button>
            </React.Fragment>
        );
    };

    renderAmount = () => {
        const { processedFileData } = this.props;
        const { invalidLines, totalAmount } = processedFileData;

        if (!invalidLines) {
            return <FormattedAmount className="data-desc" {...totalAmount} />;
        }

        return (
            <React.Fragment>
                <FormattedAmount className="data-desc" {...totalAmount} />*
                <div className="detailBox-data-aux">
                    <I18n id="forms.inputFile.massivePayments.calculated.amount.info" />
                </div>
            </React.Fragment>
        );
    };

    handleClick = () => {
        const {
            dispatch,
            idFile,
            idForm,
            idRelatedFile,
            idField,
            form: { values },
        } = this.props;

        dispatch(
            SelectorsActionFile.getFileTransactionLinesRequest({
                idFile,
                idForm,
                idFormField: idField,
                deleteFile: true,
            }),
        );

        dispatch(SelectorsActionForm.setData({ data: { ...values } }));

        dispatch(SelectorsActionTransactionLines.setIsEditingPayment({ isEditingPayment: false, lineNumber: 0 }));

        dispatch(ActionsRouter.push(`/form/${idForm}/manual`));

        const _idRelatedFile = parseInt(idRelatedFile, 10);
        if (_idRelatedFile) {
            dispatch(SelectorsActionMultilineFile.deleteFileRequest({ idFile: _idRelatedFile }));
        }
    };

    renderDetail = ({ downloadFile }) => {
        const { processedFileData } = this.props;
        const { message, invalidLineNumbers, invalidLines, invalidFile, totalAmount } = processedFileData;

        if (invalidLines === null) {
            return null;
        }

        return (
            <React.Fragment>
                {invalidLines > 0 && (
                    <div className="alert alert-warning mTop" role="alert">
                        {invalidFile ? (
                            <I18n
                                id="forms.inputFile.massivePayments.invalid.file"
                                percentage={UtilsConfig.getInteger(
                                    "forms.fields.multilinefile.minimum.invalid.percentage.allowed",
                                )}
                            />
                        ) : (
                            <I18n
                                id="forms.inputFile.massivePayments.invalid.lines.warning"
                                invalidLines={invalidLines}
                            />
                        )}
                    </div>
                )}

                {invalidLineNumbers > 0 && (
                    <div className="alert alert-warning mTop" role="alert">
                        <FieldError error={message} className="has-error" />
                    </div>
                )}
                <DetailBox>
                    <DetailBox.Data label="forms.inputFile.massivePayments.currency">
                        <I18n id={`currency.label.${totalAmount.currency || CDP_CURRENCY.UYU}`} />
                    </DetailBox.Data>
                    <DetailBox.Data label="forms.inputFile.massivePayments.lines">
                        {this.renderLinesInfo(downloadFile)}
                    </DetailBox.Data>
                    <DetailBox.Data label="forms.inputFile.massivePayments.totalAmount">
                        {this.renderAmount()}
                    </DetailBox.Data>
                    <div className="detailBox-row">
                        <Button block variant="primary" onClick={this.handleClick}>
                            {UtilsI18n.get("forms.inputFile.massivePayments.editPayments")}
                        </Button>
                    </div>
                </DetailBox>
            </React.Fragment>
        );
    };

    isValid = () => {
        const { processedFileData } = this.props;
        const { invalidHeader, invalidFile, hasFile } = processedFileData;
        return !invalidHeader && !invalidFile && hasFile;
    };

    setExtraData = () => {
        const { dispatch, form } = this.props;
        const { value: idDebitAccount } = form.values.account || {};
        const debitCurrency = form.values.currencySelector && form.values.currencySelector[0];

        const extraData = {
            debitCurrency,
            idDebitAccount,
        };

        dispatch(SelectorsActionMultilineFile.setExtraData({ extraData }));
    };

    render() {
        const { acceptedFileTypes, dispatch, form, formTitle, idField, idForm, label, maxFileSizeMB, setValue, value } =
            this.props;
        const { setValues, values } = form;

        const activity = `pay.multiline.${idForm === ID_FORM.SALARY_PAYMENT ? "salary" : "suppliers"}.send`;

        return (
            <FileActions
                {...this.props}
                renderPreview={this.renderDetail}
                render={({ onRemoveFile, onAddFile, downloadFile }) => (
                    <React.Fragment>
                        <FileUploader
                            name={idField}
                            idActivity={activity}
                            idForm={idForm}
                            idFormField={idField}
                            description={`${formTitle} - ${label}`}
                            files={value}
                            allowMultiple={false}
                            maxFileSize={`${maxFileSizeMB}mb`}
                            maxTotalFileSize={`${maxFileSizeMB}mb`}
                            maxFiles={1}
                            allowImagePreview
                            acceptedFileTypes={acceptedFileTypes}
                            onFileProcess={this.handleFileProcess}
                            onAddFile={onAddFile}
                            onRemoveFile={(file) => {
                                setValues(
                                    {
                                        ...values,
                                        debitReference: EMPTY_STR,
                                        scheduler: {
                                            editing: true,
                                            selectedOption: SCHEDULER_OPTIONS.TODAY,
                                        },
                                    },
                                    false,
                                );
                                onRemoveFile(file);
                                dispatch(SelectorsActionMultilineFile.onFileRemoved());
                                this.setExtraData();
                            }}
                            setValue={setValue}
                        />
                        {this.renderDetail({ downloadFile })}
                    </React.Fragment>
                )}
            />
        );
    }
}

const mapStateToProps = (store) => ({
    idFile: SelectorsStoreMultilineFile.getIdFile(store),
    localBanks: SelectorsStoreTransactionLines.getLocalBanks(store),
});

export default Connect(mapStateToProps)(Component);
