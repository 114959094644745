import React from "react";

import { goBack as GoBack } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { ENVIRONMENT_TYPE, SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import ContainerSimple from "~/containers/Internal/Form/Simple";
import ContainerSteps from "~/containers/Internal/Form/Steps";
import { SelectorsStore as SelectorsStoreSession, SelectorsAction as SelectorsActionSession } from "~/store/session";
import { SelectorsStore as SelectorsStoreSettings, SelectorsAction as SelectorsActionSettings } from "~/store/settings";
import { SelectorsStore as SelectorsStoreTransfers } from "~/store/transfers";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import HighOrder from "~/components/HighOrder";

import FormView from "../ticket/ChangeMobilePhoneTicket";
import FormPreview from "./ChangeMobilePhoneConfirmation";
import FormEdit from "./ChangeMobilePhoneSendCode";

export const NAME = "ChangePersonalMobilePhone";

export const PROP = {
    types: {
        isFetching: PropTypes.bool,
        mode: PropTypes.string.isRequired,
        ...TypesRedux,
    },
    defaults: {
        isFetching: false,
        ...DefaultsRedux,
    },
};

export function Component({ dispatch, environmentType, fetching, isFetching, mode }) {
    React.useEffect(() => {
        dispatch(SelectorsActionSettings.getUserData());
    }, [dispatch]);

    React.useLayoutEffect(() => {
        dispatch(SelectorsActionSettings.changePersonalData({ environmentType }));

        return () => {
            dispatch(SelectorsActionSettings.cleanPersonalInformation());
        };
    }, [dispatch, environmentType]);

    function handleBack() {
        if (mode === MODE.PREVIEW) {
            dispatch(SelectorsActionSettings.updateMobilePhoneCancel());
        } else {
            dispatch(SelectorsActionSession.cancelUserMobilePhoneForcedSync());
            dispatch(SelectorsActionSession.cancelMobilePhoneForceUpdateFlow());

            dispatch(GoBack());
        }
    }

    return environmentType !== ENVIRONMENT_TYPE.CORPORATE ? (
        <ContainerSteps
            head-onBack={(mode !== MODE.VIEW && mode !== MODE.EDIT && handleBack) || undefined}
            head-onBackWeb={mode === MODE.EDIT && handleBack}
            head-title={UtilsI18n.get("change.personal.mobilePhone.title")}
            name={NAME}
            scopeToShowNotification={SCOPE.CHANGE_MOBILE_PHONE}
            step={mode}
            wait={isFetching || fetching}>
            <React.Fragment>
                {mode === MODE.EDIT && <FormEdit onCancel={handleBack} />}
                {mode === MODE.PREVIEW && <FormPreview />}
                {mode === MODE.VIEW && <FormView ticketConfirmation />}
            </React.Fragment>
        </ContainerSteps>
    ) : (
        <ContainerSimple
            head-onBack={(mode !== MODE.VIEW && mode !== MODE.EDIT && handleBack) || undefined}
            head-onBackWeb={mode === MODE.EDIT && handleBack}
            head-title={UtilsI18n.get("change.personal.mobilePhone.title")}
            name={NAME}
            wait={isFetching}>
            {mode === MODE.EDIT && <FormEdit onCancel={handleBack} />}
            {mode === MODE.PREVIEW && <FormPreview />}
        </ContainerSimple>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    environmentType: SelectorsStoreSession.getActiveEnvironmentType(store),
    fetching: SelectorsStoreTransfers.fetching(store),
    isFetching: SelectorsStoreSettings.getFetchingForm(store),
    mode: SelectorsStoreSettings.getMode(store),
});

export default HighOrder(Connect(mapStateToProps))(Component);
