import { EMPTY_STR } from "~/constants";
import { TYPE as TYPES_APP } from "~/store/app";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.VERIFY_INVITATION_CODE_REQUEST:
            return {
                ...storeProp,
                // ...PROP.defaults, // TODO: ver si queda, o se sustituye por el cleanUp // nacho saco esto, me caga el cambie mi telefono
                invitationCode: action.invitationCode,
                fetching: true,
            };
        case TYPE.VERIFY_INVITATION_CODE_SUCCESS:
            return {
                ...storeProp,
                exchangeToken: action.exchangeToken,
                fetching: false,
                otpType: action.otpType,
                mobileNumber: action.mobileNumber,
                needsBiometric: action.needsBiometric,
            };
        case TYPE.VERIFY_INVITATION_CODE_ERROR:
            return {
                ...storeProp,
                fetching: false,
                client: null,
                accountList: null,
            };

        case TYPE.REGISTER_PERSONAL_DATA_PRE_REQUEST_SUCCESS:
            return {
                ...storeProp,
                exchangeToken: action.exchangeToken,
                accountList: action.accountList,
                client: action.client,
                securitySeal: action.securitySeal,
                invitationCode: EMPTY_STR,
                fetching: false,
            };

        case TYPE.SHOW_SECURITY_SEALS_MODAL:
            return {
                ...storeProp,
                showSealModal: true,
            };
        case TYPE.HIDE_SECURITY_SEALS_MODAL:
            return {
                ...storeProp,
                showSealModal: false,
            };
        case TYPE.SHOW_SECURITY_SEALS_CONFIRMATION_MODAL:
            return {
                ...storeProp,
                seal: action.seal,
                showSealConfirmationModal: true,
            };
        case TYPE.HIDE_SECURITY_SEALS_CONFIRMATION_MODAL:
            return {
                ...storeProp,
                showSealConfirmationModal: false,
            };
        case TYPE.LIST_SECURITY_SEALS_REQUEST_SUCCESS:
            return {
                ...storeProp,
                exchangeToken: action.exchangeToken,
                securitySealList: action.securitySealList,
                fetchingSecuritySeals: false,
            };
        case TYPE.REGISTER_PERSONAL_DATA_REQUEST_SUCCESS:
            return {
                ...storeProp,
                finishState: action.finishState,
                exchangeToken: action.exchangeToken,
                username: action.username,
                fetching: false,
            };
        case TYPE.SAVE_SELECTED_SECURITY_SEAL:
            return {
                ...storeProp,
                securitySeal: action.selectedSecuritySeal,
            };
        case TYPE.FINGERPRINT_REGISTER_SUCCESS:
            return {
                ...storeProp,
                exchangeToken: action.exchangeToken,
                seed: action.seed,
            };
        case TYPE.ENROLLMENT_FINISH_SOFT_SUCCESS:
        case TYPE.FINISH_ENROLLMENT_HARD_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                finishState: action.finishState,
            };
        case TYPE.FINISH_CHANGE_MY_PHONE:
            return {
                ...storeProp,
                finishState: "changeMyPhone",
            };

        case TYPE.REGISTER_PERSONAL_DATA_REQUEST:
        case TYPE.REGISTER_PERSONAL_DATA_PRE_REQUEST:
        case TYPE.FINISH_ENROLLMENT_HARD_REQUEST:
        case TYPE.VERIFY_VERIFICATION_CODE_REQUEST:
        case TYPE.ENROLLMENT_SECURITY_TIPS_REQUEST:
        case TYPE.ENROLLMENT_ADD_SOFT_OTP_REQUEST:
        case TYPE.ENROLLMENT_FINISH_SOFT_REQUEST:
        case TYPE.ENROLLMENT_ADD_SOFT_OTP_SUCCESS: // Sigue cargando porque falta hacer el finish
        case TYPE.RESEND_VERIFICATION_CODE_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.LIST_SECURITY_SEALS_REQUEST:
            return {
                ...storeProp,
                fetchingSecuritySeals: true,
            };
        case TYPES_APP.CLEAN_UP:
        case TYPE.CLEAN_UP:
            return {
                ...PROP.defaults,
            };

        case TYPE.RESEND_VERIFICATION_CODE_SUCCESS:
        case TYPE.VERIFY_VERIFICATION_CODE_SUCCESS:
            return {
                ...storeProp,
                exchangeToken: action.exchangeToken,
                fetching: false,
            };
        case TYPE.FINISH_ENROLLMENT_HARD_ERROR:
        case TYPE.ENROLLMENT_ADD_SOFT_OTP_ERROR:
        case TYPE.RESEND_VERIFICATION_CODE_ERROR:
        case TYPE.VERIFY_VERIFICATION_CODE_ERROR:
        case TYPE.ENROLLMENT_SECURITY_TIPS_SUCCESS:
        case TYPE.ENROLLMENT_SECURITY_TIPS_ERROR:
        case TYPE.ENROLLMENT_FINISH_SOFT_ERROR:
        case TYPE.REGISTER_PERSONAL_DATA_PRE_REQUEST_ERROR:
        case TYPE.REGISTER_PERSONAL_DATA_REQUEST_ERROR:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.LIST_SECURITY_SEALS_REQUEST_ERROR:
            return {
                ...storeProp,
                fetchingSecuritySeals: false,
            };
        default:
            return {
                ...storeProp,
            };
    }
};
