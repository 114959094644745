import React from "react";

// import I18n from "~/components/I18n";
import Col from "react-bootstrap/lib/Col";

import { CDP_CURRENCY, EMPTY_STR } from "~/constants/index";
import UtilFlag from "~/util/flag";
import * as i18n from "~/util/i18n";

import DataNumber from "~/components/DataNumber";
import Image from "~/components/Image";

import Style from "./AccordionDetail.rules.scss";

export const { NAME, NAME_MESSAGES } = Style;

export const PROP = {
    types: {},
    defaults: {
        hideTotalOnRight: false,
        forbidOpening: false,
    },
};

export class Component extends React.Component {
    state = {
        isConfirmation: false,
        forceModeState: "none",
    };

    componentDidMount() {
        const { isActive = true } = this.props;
        if (!isActive) {
            this.setState({ isConfirmation: false });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { forceMode } = nextProps;
        const { forceModeState } = prevState;
        if (forceMode === "opened" && (forceModeState === "none" || forceModeState === "closed")) {
            return { ...prevState, isConfirmation: true, forceModeState: forceMode };
        }
        if (forceMode === "closed" && !(forceModeState === "none" || forceModeState === "closed")) {
            return { ...prevState, isConfirmation: false, forceModeState: forceMode };
        }
        return null;
    }

    handleChange = () => {
        const { forbidOpening } = this.props;
        if (!forbidOpening) {
            const { isConfirmation } = this.state;
            this.setState({
                isConfirmation: !isConfirmation,
                forceMode: "none",
            });
        }
    };

    render() {
        const {
            key,
            title,
            total,
            className = EMPTY_STR,
            currency = parseInt(CDP_CURRENCY.USD, 10),
            hasFlag,
        } = this.props;
        const { isConfirmation } = this.state;

        return (
            <div className={`accordion-detail ${className && className}`} ref={this.node} key={key}>
                {hasFlag && (
                    <p>
                        <span>{i18n.get(`currency.label.${currency}`)}</span>
                        <Image src={`${UtilFlag.getFlag(currency)}.svg`} />
                    </p>
                )}
                <Col
                    className={`accordion-detail-title ${isConfirmation && "active"}`}
                    onClick={() => this.handleChange()}>
                    <div data-name="title" className="title">
                        {title && title}
                        <div className={`content-flex ${isConfirmation && "invert"}`}>
                            {total && <DataNumber value={total} prefix={currency} className="mobile-only" />}
                            <Image src="arrow-top.svg" className="link-option" />
                        </div>
                    </div>
                </Col>
                {isConfirmation && <div className="content-data">{this.props.children}</div>}
            </div>
        );
    }
}

export default Component;
