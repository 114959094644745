import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.CHANGE_ALL_COLLAPSED_ELEMENTS_COUNT: {
            const multiplier = action.collapseAll ? 1 : 0;

            return {
                ...storeProp,
                collapsedElementsCount: storeProp.position.length * multiplier,
            };
        }
        case TYPE.CHANGE_COLLAPSED_ELEMENTS_COUNT: {
            const newCount = storeProp.collapsedElementsCount + action.increment;

            return {
                ...storeProp,
                collapsedElementsCount:
                    newCount >= 0 || newCount < storeProp.position.length ? newCount : storeProp.collapsedElementsCount,
            };
        }
        case TYPE.DELETE_POSITION:
            return {
                ...storeProp,
                position: [],
            };
        case TYPE.GET_POSITION_PDF:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.GET_POSITION_PDF_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
            };
        case TYPE.READ_POSITION_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.READ_POSITION_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                position: action.position,
            };
        case TYPE.SHOW_DOWNLOAD_DATE_MODAL:
            return {
                ...storeProp,
                order: action.order,
                showDownloadDateModal: action.showDownloadDateModal,
            };
        default:
            return {
                ...storeProp,
            };
    }
};
