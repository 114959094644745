import { push as Push, replace as Replace } from "connected-react-router";
import {
    delay as Delay,
    call as Call,
    put as Put,
    takeLatest as TakeLatest,
    select as Select,
} from "redux-saga/effects";

import { LEVEL, SCOPE, EMPTY_STR, RESPONSE_TYPE } from "~/constants";
import { SelectorsStore as SelectorsStoreCommunication } from "~/store/communications";
import { SelectorsMiddleware as SelectorsMiddlewareFile } from "~/store/files";
import { TYPE as TYPE_LOGIN } from "~/store/login";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as ConfigUtil from "~/util/config";
import { downloadBase64 as download } from "~/util/download";
import * as UtilI18n from "~/util/i18n";
import Logger from "~/util/logger";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsMiddleware, SelectorsStore } from "./_selectors";

const sagas = [
    TakeLatest(TYPE.DETAIL_REQUEST, detailRequest),
    TakeLatest(TYPE.SEND_REQUEST, sendRequest),
    TakeLatest(TYPE.DOWNLOAD_ATTACHMENT_REQUEST, downloadAttachment),
    TakeLatest(TYPE.MARK_AS_READ_REQUEST, markAsReadRequest),
    TakeLatest(TYPE.MARK_LIST_AS_READ_REQUEST, markListAsReadRequest),
    TakeLatest(TYPE.MARK_AS_UNREAD_REQUEST, markAsUnReadRequest),
    TakeLatest(TYPE.REPLY_REQUEST, replyRequest),
    TakeLatest(TYPE.SELECT_ATTACHMENT, selectAttachment),
    TakeLatest(TYPE_LOGIN.LOGIN_SUCCESS, refreshLatestCommunications),
    TakeLatest(TYPE.LIST_TYPES_REQUEST, listTypes),
    TakeLatest([TYPE.LIST_REQUEST, TYPE.FETCH_MORE_REQUEST], list),
    TakeLatest(TYPE.COMMUNICATION_TRAYS_LIST_REQUEST, communicationTrayslistRequest),
];

export default sagas;

function* detailRequest(props) {
    const { idCommunication, idThread } = props;

    const params = {
        communicationId: idCommunication,
        threadId: idThread,
    };

    const response = yield Call(SelectorsMiddleware.detailRequest, params);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.detailFailure);
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMMUNICATIONS],
            }),
        );
    } else {
        const { communication, attachments } = response.data.data;

        yield Put(SelectorsAction.detailSuccess({ communication, attachments }));
    }
}

function* sendRequest(props) {
    const { tray: idCommunicationTray, subject, body, selectedFiles: fileList, isDesktop } = props;

    try {
        const response = yield Call(SelectorsMiddleware.sendRequest, {
            idCommunicationTray,
            subject,
            fileList,
            body,
        });

        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(SelectorsAction.sendFailure());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get("communications.compose.send.error"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.COMMUNICATIONS],
                }),
            );
        } else {
            yield Put(SelectorsAction.sendSuccess());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get("communications.compose.sent"),
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.COMMUNICATIONS],
                }),
            );
            if (isDesktop) {
                yield Put(SelectorsAction.hideAllPanels());
            } else {
                yield Put(Push("/communications"));
            }
        }
    } catch (e) {
        yield Put(SelectorsAction.sendFailure);
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("communications.compose.send.error"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMMUNICATIONS],
            }),
        );
    }
}

function* replyRequest(props) {
    const {
        tray: idCommunicationTray,
        re: subject,
        body,
        idCommunication,
        communicationPriority,
        selectedFiles: fileList,
    } = props;

    try {
        const response = yield Call(SelectorsMiddleware.replyRequest, {
            idCommunicationTray,
            subject,
            body,
            fileList,
            idCommunication,
            communicationPriority,
        });

        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(SelectorsAction.replyFailure());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get("communications.compose.send.error"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.COMMUNICATIONS],
                }),
            );
        } else {
            yield Put(SelectorsAction.replySuccess());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get("communications.compose.sent"),
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.COMMUNICATIONS],
                }),
            );
            yield Put(Replace(`/communications`));
        }
    } catch (e) {
        yield Put(SelectorsAction.replyFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("communications.compose.send.error"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMMUNICATIONS],
            }),
        );
    }
}

function* downloadAttachment(props) {
    const { idFile } = props;
    const response = yield Call(SelectorsMiddlewareFile.download, { idFile });

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.sendFailure);
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMMUNICATIONS],
            }),
        );
    } else {
        const { fileName, content } = response.data.data;

        download(fileName, content);

        yield Put(SelectorsAction.downloadAttachmentSuccess());
    }
}

function* markAsReadRequest(props) {
    const { idCommunication } = props;
    const middlewareParams = { idCommunication };
    const response = yield Call(SelectorsMiddleware.markAsReadRequest, middlewareParams);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.markAsReadFailure);
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMMUNICATIONS],
            }),
        );
    } else {
        yield Put(SelectorsAction.markAsReadSuccess());
    }
}

function* markListAsReadRequest() {
    const response = yield Call(SelectorsMiddleware.markListAsReadRequest);
    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.markListAsReadFailure);
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMMUNICATIONS],
            }),
        );
    } else {
        const currentList = yield Select(SelectorsStoreCommunication.list);
        const nextList = [];
        currentList.forEach((m) => {
            let next = m;
            if (!m.userRead) {
                next = { ...m, userRead: true };
            }
            nextList.push(next);
        });
        yield Put(SelectorsAction.markListAsReadSuccess({ nextList }));
    }
}

function* markAsUnReadRequest(props) {
    const { idCommunication } = props;
    const middlewareParams = { idCommunication };
    const response = yield Call(SelectorsMiddleware.markAsUnReadRequest, middlewareParams);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.markAsReadFailure);
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMMUNICATIONS],
            }),
        );
    } else {
        yield Put(SelectorsAction.markAsUnReadSuccess());
    }
}

const readFileAsDataUrl = (file) => {
    const fileReader = new FileReader();

    return new Promise((resolve) => {
        fileReader.onload = (fileLoadedEvent) => {
            resolve(fileLoadedEvent.target.result);
        };
        fileReader.readAsDataURL(file);
    });
};

const removePrefix = (str) => {
    return str.replace(/^data:[a-z]+\/[a-z]+;base64,/, "");
};

function* selectAttachment(props) {
    const { file } = props;
    const selectedAttachmentsResult = yield Select(SelectorsStore.selectedAttachments);
    const exitingFiles =
        selectedAttachmentsResult && selectedAttachmentsResult.filter((attachment) => attachment.name === file.name);
    if (exitingFiles && exitingFiles.length > 0) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("communications.attachment.selected"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMMUNICATIONS, SCOPE.COMMUNICATIONS_SEND, SCOPE.COMMUNICATIONS_REPLY],
            }),
        );
    } else {
        const fileAsDataUrl = yield readFileAsDataUrl(file);
        const result = yield removePrefix(fileAsDataUrl);
        yield Put(
            SelectorsAction.setAttachment({
                file: {
                    name: file.name,
                    size: file.size,
                    content: result,
                },
            }),
        );
    }
}

function* refreshLatestCommunications() {
    while (true) {
        const hasActiveSession = yield Select(SelectorsStoreSession.isLoggedIn);
        if (!hasActiveSession) {
            break;
        }

        try {
            const response = yield Call(SelectorsMiddleware.latestCommunications);
            if (response.status !== 304) {
                const { data } = response.data;
                const currentUnreadCommunications = yield Select(SelectorsStore.getUnreadCommunications);

                if (currentUnreadCommunications !== data.unreadCommunications) {
                    const listFilters = yield Select(SelectorsStore.getListFilters);
                    const responseMiddleware = yield Call(SelectorsMiddleware.list, listFilters);
                    const { communications, currentPage, totalPages } = responseMiddleware.data.data;

                    yield Put(SelectorsAction.listSuccess({ list: communications, currentPage, totalPages }));
                    yield Put(
                        SelectorsAction.latestCommunicationSuccess({ unreadCommunications: data.unreadCommunications }),
                    );
                }
            }
        } catch (err) {
            Logger.error(err, EMPTY_STR);
        }

        yield Delay(ConfigUtil.get("communications.refreshRate", 30) * 1000);
    }
}

function* listTypes() {
    const response = yield Call(SelectorsMiddleware.listTypes);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.listTypesFailure);
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMMUNICATIONS],
            }),
        );
    } else {
        const { communicationTypes } = response.data.data;
        yield Put(SelectorsAction.listTypesSuccess({ communicationTypes }));
    }
}

function* list(filters) {
    const response = yield Call(SelectorsMiddleware.list, filters);

    yield Put(SelectorsAction.setListFilters({ filters }));
    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.listFailure);
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMMUNICATIONS],
            }),
        );
    } else {
        const { communications, currentPage, totalPages } = response.data.data;

        if (currentPage > 1) {
            yield Put(SelectorsAction.fetchMoreSuccess({ list: communications, currentPage, totalPages }));
        } else {
            yield Put(SelectorsAction.listSuccess({ list: communications, currentPage, totalPages }));
        }
    }
}

function* communicationTrayslistRequest() {
    const response = yield Call(SelectorsMiddleware.communicationTraysListRequest);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.detailFailure);
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMMUNICATIONS],
            }),
        );
    } else {
        const { communicationTrays } = response.data.data;

        yield Put(SelectorsAction.communicationsTrayslistSuccess({ communicationTraysList: communicationTrays }));
    }
}
