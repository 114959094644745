/* global document  navigator */
import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";

import { EMPTY_STR, EVENT_BACKBUTTON, PREVENT_BACK_BUTTON_ROUTES } from "~/constants";
import { SelectorsAction as SelectorsActionDesktop, SelectorsStore as SelectorsStoreDesktop } from "~/store/desktop";
import { SelectorsStore as SelectorsStoreForm } from "~/store/form";
import { SelectorsStore } from "~/store/preferentialTradingPrice";
import { SelectorsAction as SelectorsActionTemplate, SelectorsStore as SelectorsStoreTemplate } from "~/store/template";
import DeviceUtils from "~/util/device";
import * as i18n from "~/util/i18n";

import HighOrder from "~/components/HighOrder";

export const NAME = "BackButtonListener";

export const PROP = {
    types: {
        history: PropTypes.shape({
            goBack: PropTypes.func.isRequired,
        }).isRequired,
        showEnvironmentModal: PropTypes.bool.isRequired,
        dispatch: PropTypes.func.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
        fetching: PropTypes.bool,
        submit: PropTypes.bool,
    },
    defaults: { fetching: false, submit: false },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        if (DeviceUtils.isMobileNative()) {
            document.addEventListener(EVENT_BACKBUTTON, this.backButtonAction);
        }
    }

    componentWillUnmount() {
        if (DeviceUtils.isMobileNative()) {
            document.removeEventListener(EVENT_BACKBUTTON, this.backButtonAction);
        }
    }

    onConfirm = (buttonIndex) => {
        if (buttonIndex === 2) {
            navigator.app.exitApp();
        }
    };

    backButtonAction = () => {
        const {
            history,
            location,
            showEnvironmentModal,
            dispatch,
            fetching,
            submit,
            securityTips,
            isTemplateModalVisible,
        } = this.props;
        const preventGoBack = PREVENT_BACK_BUTTON_ROUTES.includes(location.pathname);
        if (location.pathname === "/desktop" && !showEnvironmentModal) {
            navigator.notification.confirm(i18n.get("desktop.askCloseApp"), this.onConfirm, EMPTY_STR, [
                i18n.get("global.no"),
                i18n.get("global.yes"),
            ]);
        } else if (
            !preventGoBack &&
            !showEnvironmentModal &&
            !isTemplateModalVisible &&
            !fetching &&
            !submit &&
            !securityTips
        ) {
            history.goBack();
        }
        if (showEnvironmentModal) {
            dispatch(SelectorsActionDesktop.toggleEnvironmentModal());
        }
        if (isTemplateModalVisible) {
            dispatch(SelectorsActionTemplate.closeCreateModal());
        }
    };

    render() {
        return null;
    }
}

const mapStateToProps = (store) => ({
    showEnvironmentModal: SelectorsStoreDesktop.showEnvironmentModal(store),
    fetching: SelectorsStore.fetching(store),
    submit: SelectorsStoreForm.getSubmit(store),
    isTemplateModalVisible: SelectorsStoreTemplate.isCreateModalVisible(store),
});

export default WithRouter(Connect(mapStateToProps)(HighOrder.Resizable(Component)));
