import React from "react";

export const NAME = "withFocus";

export const PROP = {
    types: {},
    defaults: {},
};
export function Component(BaseComponent) {
    return class _Component extends React.Component {
        static defaultProps = PROP.defaults;

        static propTypes = PROP.types;

        static displayName = `withFocus${BaseComponent.name}`;

        state = {
            isFocused: false,
        };

        toggleIsFocused = () => {
            this.setState((prevState) => ({ isFocused: !prevState.isFocused }));
        };

        render() {
            const { isFocused } = this.state;
            return <BaseComponent {...this.props} toggleIsFocused={this.toggleIsFocused} isFocused={isFocused} />;
        }
    };
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
