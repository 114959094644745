import React from "react";

import PropTypes from "prop-types";

import { getLang as GetLang } from "~/util/i18n";
import { numberFormat as NumberFormat, toNumber as ToNumber } from "~/util/number";

import ConnectedAmountField from "~/pages/_components/fields/AmountField";

export const NAME = "FormikAmountField";

export const PROP = {
    types: {
        field: PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.object.isRequired,
        }).isRequired,
        form: PropTypes.shape({
            errors: PropTypes.object.isRequired,
            setFieldTouched: PropTypes.func.isRequired,
            setFieldValue: PropTypes.func.isRequired,
            touched: PropTypes.object.isRequired,
        }).isRequired,
        onCurrencyChange: PropTypes.func,
        onInputChange: PropTypes.func,
        inputZeroAllowed: PropTypes.bool,
    },
    defaults: {},
};

export function Component(props) {
    const { field, form } = props;

    return (
        <ConnectedAmountField
            {...field}
            hasError={form.touched[field.name] && form.errors[field.name]}
            onCurrencyChange={handleCurrencyChange}
            onInputChange={handleInputChange}
            onBlur={handleBlur}
            error={form.errors[field.name] && form.errors[field.name].amount}
            {...props}
            {...field.value}
        />
    );

    function handleBlur() {
        form.setFieldTouched(field.name);
    }

    function handleCurrencyChange({ value }) {
        const { onCurrencyChange } = props;

        if (onCurrencyChange) {
            onCurrencyChange(value);
        } else {
            form.setFieldValue(`${field.name}.currency`, value);
        }
    }

    function handleInputChange({ target }) {
        const { onInputChange } = props;
        const { decimalSeparator } = NumberFormat(GetLang());
        const number = ToNumber(target.value, decimalSeparator);

        if (onInputChange) {
            onInputChange(number);
        } else {
            form.setFieldValue(`${field.name}.amount`, number);
        }
    }
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
