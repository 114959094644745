import React from "react";

import { Modal } from "react-bootstrap";
import NumberFormat from "react-number-format";

import { EMPTY_STR } from "~/constants";
import * as UtilsI18n from "~/util/i18n";
import * as UtilsNumber from "~/util/number";

import Box from "~/components/Box";
import DataNumber from "~/components/DataNumber";
import GridLayout from "~/components/GridLayout";
import Image from "~/components/Image";

import Style from "./TermDepositModal.rules.scss";

export const { NAME, NAME_MESSAGES, ID } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export class Component extends React.Component {
    renderFieldValue = (data, field, currency) => {
        if (field.type === "amount") {
            return (
                <DataNumber
                    value={data[field.id]}
                    prefix={field.forceCurrency ? field.forceCurrency : currency}
                    className="mobile-only"
                />
            );
        }

        if (data[field.id]) {
            return <span>{data[field.id]}</span>;
        }

        return <span>N/A</span>;
    };

    getRates = () => {
        const { data } = this.props;
        const rates = [];

        for (let i = 0; i < data.length; i += 1) {
            const r = data[i].rates;

            for (let j = 0; j < r.length; j += 1) {
                if (!rates.includes(r[j].amountFrom)) {
                    rates.push(r[j].amountFrom);
                }
            }
        }

        return rates;
    };

    render() {
        const {
            currency,
            data,
            decimalSeparator,
            handleCloseModal,
            maximumDecimals,
            minimumDecimals,
            show,
            thousandSeparator,
        } = this.props;
        const rates = this.getRates();

        return (
            <Modal show={show} id={ID} className="termDepositModal">
                <Modal.Body>
                    <div className="component-modal">
                        <div className="closed clickable" onClick={handleCloseModal} role="button">
                            <Image src="close.svg" />
                        </div>
                        <div>
                            <h2>{UtilsI18n.get(`investment.termDeposit.modal.title.${currency}`)}</h2>
                        </div>
                        <GridLayout>
                            <Box justify="center">
                                <p>{UtilsI18n.get("investment.termDeposit.modal.header.term")}</p>
                            </Box>
                            {rates.map((item) => {
                                const decimalPlaces = UtilsNumber.countDecimalPlaces(item, decimalSeparator);
                                const decimalScale = Math.max(
                                    Math.min(decimalPlaces, maximumDecimals),
                                    minimumDecimals,
                                );

                                return (
                                    <Box justify="center">
                                        <NumberFormat
                                            className="form-control amount"
                                            fixedDecimalScale
                                            decimalScale={decimalScale}
                                            decimalSeparator={decimalSeparator}
                                            thousandSeparator={thousandSeparator}
                                            prefix={`${UtilsI18n.get(
                                                "investment.termDeposit.modal.header.amountPrefix",
                                            )} `}
                                            displayType="text"
                                            value={item}
                                        />
                                    </Box>
                                );
                            })}
                        </GridLayout>

                        {data.map((d) => (
                            <GridLayout className="body">
                                <Box justify="center">
                                    <p>{`${d.days} ${UtilsI18n.get("widget.termDeposit.days")}`}</p>
                                </Box>
                                {rates.map((r) => {
                                    const element = d.rates.find((dr) => dr.amountFrom === r);
                                    const rate = element?.rate >= 1 ? element?.rate : element?.rate;

                                    return (
                                        <Box justify="center">
                                            <p>{`${
                                                element
                                                    ? `${UtilsNumber.formatNumber(rate, UtilsI18n.getLang())}%`
                                                    : EMPTY_STR
                                            }`}</p>
                                        </Box>
                                    );
                                })}
                            </GridLayout>
                        ))}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default Component;
