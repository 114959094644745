import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { TYPE as TypesBackoffice } from "~/store/backoffice";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.CONNECTEDPARTIES_PRE_REQUEST:
            return {
                ...storeProp,
                fetchingForm: true,
            };
        case TYPE.CONNECTEDPARTIES_PRE_SUCCESS:
            return {
                ...storeProp,
                cityList: action.cityList,
                cmbOnly: action.cmbOnly,
                correspondense: action.correspondense,
                countryList: action.countryList,
                departmentsOfBirth: action.cityList,
                documents: action.documents,
                fetching: false,
                fetchingForm: false,
                infoUser: action.infoUser,
                legal: action.legal,
                loadData: action.loadData,
                maritalStatusList: action.maritalStatusList,
                productGroupIds: action.productGroupIds,
                professions: action.professions,
                townList: action.townList,
                workActivities: action.workActivities,
                workRoles: action.workRoles,
                workSituations: action.workSituations,
            };
        case TYPE.CONNECTEDPARTIES_LIST_DEPARTMENTS_OF_BIRTH_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.CONNECTEDPARTIES_LIST_DEPARTMENTS_OF_BIRTH_SUCCESS:
            return {
                ...storeProp,
                departmentsOfBirth: action.cityList,
                fetching: false,
            };
        case TYPE.CONNECTEDPARTIES_LIST_DEPARTMENTS_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.CONNECTEDPARTIES_LIST_DEPARTMENTS_SUCCESS:
            return {
                ...storeProp,
                cityList: action.cityList,
                townList: PROP.defaults.townList,
                fetching: false,
            };
        case TYPE.CONNECTEDPARTIES_LIST_CITIES_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.CONNECTEDPARTIES_LIST_CITIES_SUCCESS:
            return {
                ...storeProp,
                townList: action.townList,
                fetching: false,
            };
        case TYPE.CONNECTEDPARTIES_SUBMIT_FORM_REQUEST:
            return { ...storeProp, fetching: true };
        case TYPE.CONNECTEDPARTIES_SUBMIT_FORM_SUCCESS:
        case TYPE.CONNECTEDPARTIES_SUBMIT_FORM_FAILURE:
            return { ...storeProp, fetching: false };
        case TYPE.READ_TRANSACTION_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.READ_TRANSACTION_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.READ_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                transaction: action.transaction,
                mode: action.transaction.idTransactionStatus === STATUS.DRAFT ? MODE.EDIT : MODE.VIEW,
            };
        case TYPE.SET_REGISTERED_BANTOTAL:
            return {
                ...storeProp,
                regiteredBantotal: action.regiteredBantotal,
            };
        case TypesBackoffice.BACKOFFICE_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                prevMode: storeProp.mode,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };
        default:
            return storeProp;
    }
};
