import React from "react";

import { Formik, Field, Form } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import { CREDENTIAL_TYPE_OTP, EMPTY_STR, HARD_OTP } from "~/constants";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import {
    SelectorsAction as SelectorsActionTransactions,
    SelectorsStore as SelectorsStoreTransactions,
} from "~/store/transactions";
import { flattenArray as FlattenArray, removeDuplicateItems as RemoveDuplicateItems } from "~/util/array";
import * as I18nUtil from "~/util/i18n";

import Button from "~/components/Button";
import I18n from "~/components/I18n";
import Credential from "~/pages/_components/fields/credentials/Credential";

import Style from "./ApproveMultiple.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        credentials: PropTypes.oneOfType([PropTypes.array]).isRequired,
    },
    defaults: {},
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    validationSchema = () => {
        const { credentials, user } = this.props;
        if (user.otpType === HARD_OTP) {
            return Yup.object().shape(
                credentials.reduce(
                    (values, credential) => ({
                        ...values,
                        [credential]: Yup.string().required(I18nUtil.get(`form.credential.${credential}.required`)),
                    }),
                    {},
                ),
            );
        }
        return Yup.object().shape(
            // En el caso se que sea SOFT credentials esta en null
            credentials.reduce(
                (values, credential) => ({
                    ...values,
                    [credential]: Yup.string().nullable(),
                }),
                {},
            ),
        );
    };

    renderForm = () => {
        const { credentials, idTransactionList, isApproving } = this.props;
        const textSimple = isApproving ? "transactions.simpleApprove" : "transactions.simpleCancelling";
        const replace = { CANT: idTransactionList.length };
        const textMultiple = isApproving ? "transactions.approvement" : "transactions.cancelling";

        return (
            <Form id={Style.NAME} className="approve-page-form">
                <section>
                    <div className="form-approve-multiple simple-label">
                        {isApproving ? (
                            <I18n id="transactions.multipleApprove" />
                        ) : (
                            <I18n id="transactions.multipleCancelation" />
                        )}
                    </div>
                    <div className="form-approve-multiple bold-label">
                        {idTransactionList.length > 1 ? (
                            <I18n id={textMultiple} {...replace} />
                        ) : (
                            <I18n id={textSimple} />
                        )}
                    </div>
                </section>
                <section className="credential-section">
                    {credentials.map((credential) => (
                        <div key={credential}>
                            <Field
                                idForm="form.credential"
                                name={credential}
                                component={Credential}
                                type={credential}
                                hidePlaceholder={credential === CREDENTIAL_TYPE_OTP}
                            />
                        </div>
                    ))}
                </section>
                <section>
                    <Button className="btn btn-primary" label="global.send" type="submit" />
                </section>
            </Form>
        );
    };

    handleSubmit = (credentials, formikBag) => {
        const { dispatch, isApproving, idTransactionList } = this.props;
        const { otp } = credentials;
        if (isApproving) {
            dispatch(SelectorsActionTransactions.approveTransactionsRequest({ idTransactionList, otp, formikBag }));
        } else {
            dispatch(SelectorsActionTransactions.cancelTransactionsRequest({ idTransactionList, otp, formikBag }));
        }
    };

    render() {
        const { credentials } = this.props;

        const initialValues = credentials.reduce((values, credential) => ({ ...values, [credential]: EMPTY_STR }), {});

        return (
            <div className="approve-page">
                <Formik
                    initialValues={initialValues}
                    validationSchema={this.validationSchema}
                    onSubmit={this.handleSubmit}
                    render={() => this.renderForm()}
                />
            </div>
        );
    }
}

const mapStateToProps = (store) => ({
    credentials: Compose(
        (array) => array.filter((item) => item !== "accessToken"),
        RemoveDuplicateItems,
        FlattenArray,
        (array) => array.map(({ credentials }) => credentials),
    )(SelectorsStoreTransactions.getCredentialsGroups(store)),
    isApproving: SelectorsStoreTransactions.getSignAction(store),
    user: SelectorsStoreSession.getUser(store),
});

export default Connect(mapStateToProps)(Component);
