import PropTypes from "prop-types";

import { FactoryType } from "~/util/redux";

export const NAME = "i18n";

export const PATH = {
    LIST_MESSAGES: "/messages.listMessages",
};

export const TYPE = FactoryType(NAME, [
    "SET_LANG",
    "SET_INIT_LANG",
    "UPDATE_REQUEST",
    "UPDATE_SUCCESS",
    "UPDATE_FAILURE",
]);

export const PROP = {};

PROP.types = {
    lang: PropTypes.string,
    etag: PropTypes.string,
    items: PropTypes.object,
    message: PropTypes.string,
    fetching: PropTypes.bool,
};

PROP.defaults = {
    lang: null,
    etag: null,
    items: null,
    fetching: true,
    message: null,
};
