import React from "react";

import Classnames from "classnames";
import { push as Push } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";

import { SCOPE, CDP_CURRENCY, PERCENT, DASH, SPACE_STR, ZERO, SCREENS_BEFORE } from "~/constants";
import Container from "~/containers/Internal/Administration/Simple";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import {
    SelectorsStore as SelectorsStoreInvestment,
    SelectorsAction as SelectorsActionInvestment,
} from "~/store/investment";
import { SelectorsAction as SelectorsActionPosition, SelectorsStore as SelectorsStorePosition } from "~/store/position";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionWMBuy } from "~/store/wm/funds/buy";
import { SelectorsAction as SelectorsActionWMSell } from "~/store/wm/funds/sell";
import { SelectorsStore as SelectorsStoreWM, SelectorsAction as SelectorsActionWM } from "~/store/wm/listPapers";
import { generateId as GenerateId } from "~/util/general";

import Box from "~/components/Box";
import Button from "~/components/Button";
import DataNumber from "~/components/DataNumber";
import GridLayout from "~/components/GridLayout";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";
import WidgetImage from "~/components/WidgetImage";

import ConfirmModal from "~/pages/investment/ConfirmModal";

import Style from "./ListPapers.rules.scss";
import PapersFilters from "./components/PapersFilters";
import PopUp from "./components/PopUp";

export const { NAME, TYPE_NOTE, NAME_NOTE } = Style;

export const PROP = {};

PROP.members = {
    activeEnvironmentPermissions: {
        funds: PropTypes.bool.isRequired,
    },
};

PROP.members.activeEnvironment = {
    permissions: PropTypes.shape(PROP.members.activeEnvironmentPermissions).isRequired,
};

PROP.types = {
    activeEnvironment: PropTypes.shape(PROP.members.activeEnvironment),
};

PROP.defaults = {};

export function Component(props) {
    const {
        activeEnvironment,
        currency = parseInt(CDP_CURRENCY.USD, 10),
        dispatch,
        familiesList,
        fetching,
        history,
        i18n,
        isChangingEnvironment,
        isFromRiskProfileQuiz,
        papersList,
        positions,
        storeFilters,
    } = props;
    const selectedFamily = storeFilters.family;
    const { environmentData, riskProfileCode } = activeEnvironment;
    const { hasRiskProfile, hasW8Signed } = environmentData;
    const defaultModalValues = {
        currentFamily: null,
        currentIdProduct: null,
        currentLink: null,
        showDetailsModal: false,
        showEvolutionModal: false,
    };
    const showBestsellersAndPrefered = riskProfileCode !== ZERO;

    const [updatedPapersList, setUpdatedPapersList] = React.useState([]);
    const [updatedPositionList, setUpdatedPositionList] = React.useState([]);
    const [modalValues, updateModalValues] = React.useState(defaultModalValues);
    const [allIsPressed, setAllIsPressed] = React.useState(!showBestsellersAndPrefered);
    const [preferedIsPressed, setPreferedIsPressed] = React.useState(false);
    const [positionsIsPressed, setPositionsIsPressed] = React.useState(false);
    const [portfolioIsPressed, setPortfolioIsPressed] = React.useState(false);
    const [rate1yearIsPressed, setRate1yearIsPressed] = React.useState(false);
    const [rate3yearIsPressed, setRate3yearIsPressed] = React.useState(false);
    const [rate5yearIsPressed, setRate5yearIsPressed] = React.useState(false);

    React.useEffect(() => {
        return () => {
            dispatch(SelectorsActionPosition.deletePosition());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (!isChangingEnvironment) {
            dispatch(SelectorsActionPosition.deletePosition());
            dispatch(SelectorsActionPosition.readPositionRequest({ productTypes: [25] }));

            if (riskProfileCode === ZERO) {
                showAllFunds();
            } else {
                showBestsellers();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isChangingEnvironment, riskProfileCode]);

    React.useEffect(() => {
        if (!isChangingEnvironment && papersList?.length === 0) {
            dispatch(SelectorsActionWM.requestListFundPapers());
        }
    }, [dispatch, isChangingEnvironment, papersList]);

    React.useEffect(() => {
        if (!isChangingEnvironment) {
            const updatedList = updateList();
            const updatedPList = updatePositionList();

            const { family, category } = storeFilters;
            const sortingByYear = rate1yearIsPressed || rate3yearIsPressed || rate5yearIsPressed;

            if (family === "all" && category === "all" && !sortingByYear) {
                setUpdatedPapersList(papersList);
            } else {
                setUpdatedPapersList(updatedList);

                if (updatedPList?.length > 0) {
                    setUpdatedPositionList(updatedPList);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeFilters, isChangingEnvironment, papersList]);

    React.useEffect(() => {
        if (positions?.length > 0) {
            const positionList = buildPositionList();

            setUpdatedPositionList(positionList);
            showPositions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [positions]);

    function buildPositionList() {
        const productsArr = [];

        positions.forEach((position) => {
            if (position.productType === 25) {
                const product = position.products;

                product.map((element) => {
                    return productsArr.push(element.othersFields);
                });
            }
        });

        const positionList = productsArr.map((productElement) => {
            const paperFound = papersList.find((paperElement) => paperElement.idProduct === productElement.PAPEL);

            return {
                ...productElement,
                category: paperFound?.category,
                family: paperFound?.family,
                idProduct: paperFound?.idProduct,
                link: paperFound?.link,
                name: paperFound?.name,
                rate1year: paperFound?.rate1year,
                rate3year: paperFound?.rate3year,
                rate5year: paperFound?.rate5year,
            };
        });

        return positionList;
    }

    function updateList() {
        const { category, family, year } = storeFilters;
        let paperList = [];

        if (papersList) {
            paperList = [...papersList];

            if (family !== "all") {
                paperList = paperList.filter((paper) => {
                    return paper.family === family;
                });
            }

            if (category !== "all") {
                paperList = paperList.filter((paper) => {
                    return paper.category === category;
                });
            }

            if (year) {
                paperList = paperList.sort((a, b) => {
                    return b[year] - a[year];
                });
            }
        }

        return paperList;
    }

    function updatePositionList() {
        const { year } = storeFilters;
        let sortedList = buildPositionList();

        if (year) {
            sortedList = sortedList.sort((a, b) => {
                if (!(b[year] || a[year])) {
                    return 0;
                }

                if (!b[year]) {
                    return -1;
                }

                if (!a[year]) {
                    return 1;
                }

                return b[year] - a[year];
            });
        }

        return sortedList;
    }

    function closeEvolutionModal() {
        updateModalValues({ ...modalValues, showEvolutionModal: false });
        dispatch(SelectorsActionWM.resetChartValues());
    }

    function handleEvolutionModal(idProduct, link, family) {
        dispatch(SelectorsActionWM.requestPaperDetails({ paperId: idProduct }));
        updateModalValues({
            ...modalValues,
            currentFamily: family,
            currentIdProduct: idProduct,
            currentLink: link,
            showEvolutionModal: true,
        });
    }

    function handleClickDetails(newData) {
        const newLink = newData?.link || modalValues.currentLink;
        const newFamily = newData?.family || modalValues.currentFamily;
        const newModalValues = {
            ...modalValues,
            currentFamily: newFamily,
            currentLink: newLink,
            showEvolutionModal: false,
        };

        dispatch(SelectorsActionWM.resetChartValues());

        if (newFamily === "hsbc") {
            openDetails(newModalValues);
        } else {
            updateModalValues({ ...newModalValues, showDetailsModal: true });
        }
    }

    function handleBuy(idPaper) {
        dispatch(SelectorsActionWMBuy.selectedIdPaper({ idPaper }));
        dispatch(Push(`/wm/funds/buy`));
    }

    function handleSell(idPaper) {
        dispatch(SelectorsActionWMSell.selectedIdPaper({ idPaper }));
        dispatch(Push(`/wm/funds/sell`));
    }

    function openDetails(newModalValues) {
        const finalLink = newModalValues?.currentLink || modalValues.currentLink;
        const finalFamily = newModalValues?.currentFamily || modalValues.currentFamily;
        const win = window.common.openLink(finalLink);

        if (win) {
            win.focus();
        }

        const finalModalValues = {
            currentFamily: finalFamily,
            currentLink: finalLink,
            showDetailsModal: false,
            showEvolutionModal: false,
        };

        updateModalValues(finalModalValues);
        dispatch(SelectorsActionWM.resetChartValues());
    }

    function sortByYearRate(year) {
        const yearRate = `rate${year}year`;
        const filters = { ...storeFilters, year: yearRate };

        dispatch(SelectorsActionWM.updateFilters(filters));

        switch (year) {
            case 1:
                setRate1yearIsPressed(!rate1yearIsPressed);
                setRate3yearIsPressed(false);
                setRate5yearIsPressed(false);

                break;
            case 3:
                setRate1yearIsPressed(false);
                setRate3yearIsPressed(!rate3yearIsPressed);
                setRate5yearIsPressed(false);

                break;
            case 5:
                setRate1yearIsPressed(false);
                setRate3yearIsPressed(false);
                setRate5yearIsPressed(!rate5yearIsPressed);

                break;
            default:
                setRate1yearIsPressed(false);
                setRate3yearIsPressed(false);
                setRate5yearIsPressed(false);
        }
    }

    function showPrefered(category) {
        if (!preferedIsPressed) {
            const filters = { ...storeFilters, family: "all", category };

            dispatch(SelectorsActionWM.updateFilters(filters));
        }

        setPreferedIsPressed(!preferedIsPressed);

        if (preferedIsPressed) {
            setPreferedIsPressed(true);
        }

        if (positionsIsPressed || portfolioIsPressed || allIsPressed) {
            setPositionsIsPressed(false);
            setPortfolioIsPressed(false);
            setAllIsPressed(false);
        }
    }

    function showAllFunds() {
        if (!allIsPressed) {
            const filters = { ...storeFilters, family: "all", category: "all" };

            dispatch(SelectorsActionWM.updateFilters(filters));
        }

        setAllIsPressed(!allIsPressed);

        if (allIsPressed) {
            setAllIsPressed(true);
        }

        if (positionsIsPressed || portfolioIsPressed || preferedIsPressed) {
            setPositionsIsPressed(false);
            setPortfolioIsPressed(false);
            setPreferedIsPressed(false);
        }
    }

    function showBestsellers(category) {
        if (!portfolioIsPressed) {
            const filters = { ...storeFilters, family: "all", category };

            dispatch(SelectorsActionWM.updateFilters(filters));
        }

        setPortfolioIsPressed(!portfolioIsPressed);

        if (portfolioIsPressed) {
            setPortfolioIsPressed(true);
        }

        if (positionsIsPressed || preferedIsPressed || allIsPressed) {
            setPositionsIsPressed(false);
            setPreferedIsPressed(false);
            setAllIsPressed(false);
        }
    }

    function showPositions() {
        if (!positionsIsPressed) {
            const filters = { ...storeFilters, family: "all", category: "all" };

            dispatch(SelectorsActionWM.updateFilters(filters));
        }

        setPositionsIsPressed(!positionsIsPressed);

        if (positionsIsPressed) {
            setPositionsIsPressed(true);
        }

        if (portfolioIsPressed || preferedIsPressed || allIsPressed) {
            setPortfolioIsPressed(false);
            setPreferedIsPressed(false);
            setAllIsPressed(false);
        }
    }

    function checkRiskProfile(paper) {
        if (riskProfileCode === ZERO) {
            return paper.category !== "DESTACADOS";
        }

        return paper;
    }

    function handleBack() {
        if (isFromRiskProfileQuiz) {
            dispatch(SelectorsActionInvestment.setFromRiskProfileQuiz({ isFromRiskProfileQuiz: false }));

            history.go(SCREENS_BEFORE.THREE_LESS);
        } else {
            history.goBack();
        }
    }

    const familiesObj = {};

    familiesList.forEach((family) => {
        familiesObj[family.value] = [family.label];
    });

    const showPositionButton = updatedPositionList.length > 0;

    return (
        <React.Fragment>
            <Container
                name={NAME}
                head-title="wm.investment.funds.list.title"
                head-onBackWeb={handleBack}
                wait={fetching}
                scopeToShowNotification={SCOPE.PAPERS_LIST}>
                {!hasRiskProfile || !hasW8Signed ? (
                    <div>
                        {!hasRiskProfile && (
                            <div className="expired-notification">
                                <I18n id="wm.documentsAboutToExpire.riskProfile.expired" />
                            </div>
                        )}
                        {!hasW8Signed && (
                            <div className="expired-notification">
                                <I18n id="wm.documentsAboutToExpire.w8.expired" />
                            </div>
                        )}
                    </div>
                ) : (
                    <div id={Style.CLASS}>
                        {modalValues.showEvolutionModal && (
                            <PopUp
                                show={modalValues.showEvolutionModal}
                                handleCloseModal={() => closeEvolutionModal()}
                                downloadDetail={handleClickDetails}
                                handleBuy={() => handleBuy(modalValues.currentIdProduct)}
                                currentLink={modalValues.currentLink}
                                idPaper={modalValues.currentIdProduct}
                            />
                        )}
                        {modalValues.showDetailsModal && (
                            <ConfirmModal
                                show={modalValues.showDetailsModal}
                                handleCloseModal={() => updateModalValues({ ...modalValues, showDetailsModal: false })}
                                onSubmit={() => openDetails()}
                            />
                        )}
                        <Box
                            className={Classnames(
                                { "button-head": showPositionButton },
                                { "button-header": !showPositionButton },
                            )}>
                            {showPositionButton && (
                                <Button
                                    bsStyle={Classnames(
                                        { primary: positionsIsPressed },
                                        { secondary: !positionsIsPressed },
                                    )}
                                    label="wm.investment.filter.portfolio.label"
                                    onClick={() => showPositions()}
                                />
                            )}
                            {showBestsellersAndPrefered && (
                                <Button
                                    bsStyle={Classnames(
                                        { primary: portfolioIsPressed },
                                        { secondary: !portfolioIsPressed },
                                        { "header-fix": !showPositionButton },
                                    )}
                                    label="wm.investment.filter.bestsellers.label"
                                    onClick={() => showBestsellers("PORTAFOLIO")}
                                />
                            )}
                            {showBestsellersAndPrefered && (
                                <Button
                                    bsStyle={Classnames(
                                        { primary: preferedIsPressed },
                                        { secondary: !preferedIsPressed },
                                        { "header-fix": !showPositionButton },
                                    )}
                                    label="wm.investment.filter.highlights.label"
                                    onClick={() => showPrefered("DESTACADOS")}
                                />
                            )}
                            <Button
                                bsStyle={Classnames(
                                    { primary: allIsPressed },
                                    { secondary: !allIsPressed },
                                    { "header-fix": !showPositionButton },
                                )}
                                label="wm.investment.filter.all.label"
                                onClick={() => showAllFunds()}
                            />
                        </Box>

                        {allIsPressed && <PapersFilters {...props} />}

                        <div className="container-rate">
                            <div className="container-label">
                                <span>{i18n.rateTitle}</span>
                            </div>
                            <div className="container-sort">
                                <Box className="sort">
                                    <Button
                                        className={Classnames({
                                            "select-sort": rate1yearIsPressed,
                                            "button-sort": rate1yearIsPressed,
                                        })}
                                        bsStyle="link"
                                        label="wm.investment.rate.ytd.label"
                                        onClick={() => sortByYearRate(1)}
                                    />
                                    <Button
                                        className={Classnames({
                                            "select-sort": rate3yearIsPressed,
                                            "button-sort": rate3yearIsPressed,
                                        })}
                                        bsStyle="link"
                                        label="wm.investment.rate.three.label"
                                        onClick={() => sortByYearRate(3)}
                                    />
                                    <Button
                                        className={Classnames({
                                            "select-sort": rate5yearIsPressed,
                                            "button-sort": rate5yearIsPressed,
                                        })}
                                        bsStyle="link"
                                        label="wm.investment.rate.five.label"
                                        onClick={() => sortByYearRate(5)}
                                    />
                                </Box>
                            </div>
                        </div>

                        {allIsPressed && selectedFamily !== "all" && (
                            <div className="family">
                                <GridLayout className="family-description">
                                    <div>
                                        <h4>{familiesObj[selectedFamily]}</h4>
                                        <div dangerouslySetInnerHTML={{ __html: i18n.familyDescription }} />
                                        <Link
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (selectedFamily) {
                                                    dispatch(
                                                        SelectorsActionWM.getFamilyProductDescription({
                                                            familyName: selectedFamily,
                                                        }),
                                                    );
                                                }
                                            }}>
                                            {i18n.familyProductDescription}
                                        </Link>
                                    </div>
                                </GridLayout>
                            </div>
                        )}
                        {!positionsIsPressed &&
                            (updatedPapersList !== undefined && updatedPapersList.length > 0 ? (
                                updatedPapersList
                                    ?.filter(checkRiskProfile)
                                    .map(
                                        ({
                                            family,
                                            category,
                                            name,
                                            link,
                                            idProduct,
                                            rate1year,
                                            rate3year,
                                            rate5year,
                                        }) => (
                                            <GridLayout key={`papers-${GenerateId()}`} className="item">
                                                <Box className="container-head">
                                                    <Box className="category-title">
                                                        <Box className="name only-web">{name}</Box>
                                                        <Box className="category">
                                                            {category} <span>{family && DASH}</span>
                                                            {SPACE_STR}
                                                            <span>{familiesObj[family]}</span>
                                                        </Box>
                                                        <WidgetImage
                                                            className="download only-mobile"
                                                            onClick={() =>
                                                                handleClickDetails({ idProduct, link, family })
                                                            }
                                                            src="download_line.svg"
                                                        />
                                                    </Box>
                                                    <Box className="name only-mobile">{name}</Box>
                                                    <WidgetImage
                                                        className="download only-web"
                                                        onClick={() => handleClickDetails({ idProduct, link, family })}
                                                        src="download_line.svg"
                                                    />
                                                </Box>
                                                <Box className="container">
                                                    <Box className="details">
                                                        <Box className="rate-title">
                                                            <span>{i18n.rateLabel}</span>
                                                        </Box>
                                                        <Box
                                                            className={Classnames("row", {
                                                                "select-sort": rate1yearIsPressed,
                                                                "not-select-sort":
                                                                    rate3yearIsPressed || rate5yearIsPressed,
                                                                down: rate1year < 0,
                                                                up: rate1year > 0,
                                                                zero: rate1year === 0,
                                                                "no-data": rate1year === undefined,
                                                                disable: rate3yearIsPressed || rate5yearIsPressed,
                                                            })}>
                                                            <WidgetImage className="only-mobile" src="long-arrow.svg" />
                                                            <span>
                                                                {rate1year ? (
                                                                    <div>
                                                                        {rate1year}
                                                                        {PERCENT}
                                                                        <WidgetImage
                                                                            className="only-web"
                                                                            src="long-arrow.svg"
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <span>{i18n.notAvailable}</span>
                                                                )}
                                                            </span>
                                                            <p className="rate-label">{i18n.rateYTD}</p>
                                                        </Box>
                                                        <Box
                                                            className={Classnames("row", {
                                                                "select-sort": rate3yearIsPressed,
                                                                "not-select-sort":
                                                                    rate1yearIsPressed || rate5yearIsPressed,
                                                                down: rate3year < 0,
                                                                up: rate3year > 0,
                                                                zero: rate3year === 0,
                                                                "no-data": rate3year === undefined,
                                                                disable: rate1yearIsPressed || rate5yearIsPressed,
                                                            })}>
                                                            <WidgetImage className="only-mobile" src="long-arrow.svg" />
                                                            <span>
                                                                {rate3year ? (
                                                                    <div>
                                                                        {rate3year}
                                                                        {PERCENT}
                                                                        <WidgetImage
                                                                            className="only-web"
                                                                            src="long-arrow.svg"
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <span>{i18n.notAvailable}</span>
                                                                )}
                                                            </span>
                                                            <p className="rate-label">{i18n.rateThree}</p>
                                                        </Box>
                                                        <Box
                                                            className={Classnames("row", {
                                                                "select-sort": rate5yearIsPressed,
                                                                "not-select-sort":
                                                                    rate1yearIsPressed || rate3yearIsPressed,
                                                                down: rate5year < 0,
                                                                up: rate5year > 0,
                                                                zero: rate5year === 0,
                                                                "no-data": rate5year === undefined,
                                                                disable: rate1yearIsPressed || rate3yearIsPressed,
                                                            })}>
                                                            <WidgetImage className="only-mobile" src="long-arrow.svg" />
                                                            <span>
                                                                {rate5year ? (
                                                                    <div>
                                                                        {rate5year}
                                                                        {PERCENT}
                                                                        <WidgetImage
                                                                            className="only-web"
                                                                            src="long-arrow.svg"
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <span>{i18n.notAvailable}</span>
                                                                )}
                                                            </span>
                                                            <p className="rate-label">{i18n.rateFive}</p>
                                                        </Box>
                                                    </Box>
                                                    <Box className="container-button" justify="end">
                                                        <Button
                                                            className="more"
                                                            onClick={() =>
                                                                handleEvolutionModal(idProduct, link, family)
                                                            }
                                                            bsStyle="link"
                                                            label="wm.investment.fund.item.option.more.detail"
                                                        />
                                                        <Button
                                                            className="buy"
                                                            onClick={() => handleBuy(idProduct)}
                                                            bsStyle="primary"
                                                            label="wm.investment.fund.item.option.buy"
                                                        />
                                                    </Box>
                                                </Box>
                                            </GridLayout>
                                        ),
                                    )
                            ) : (
                                <div className="no-products"> {i18n.noProducts} </div>
                            ))}

                        {positionsIsPressed &&
                            (updatedPapersList !== undefined && updatedPapersList.length > 0 ? (
                                updatedPositionList?.map(
                                    ({
                                        family,
                                        category,
                                        name,
                                        link,
                                        idProduct,
                                        rate1year,
                                        rate3year,
                                        rate5year,
                                        DESCRIPCION: description,
                                        CANTIDAD: quantity,
                                        PAPEL: idPaper,
                                        PRECIOCOMPRA: purchasePrice,
                                        PRECIOMERCADO: marketPrice,
                                        VALORCOMPRA: purchaseValue,
                                    }) => (
                                        <GridLayout key={`papers-${GenerateId()}`} className="item">
                                            <Box className="container-head">
                                                <Box className="category-title">
                                                    <Box className="name only-web">{name || description}</Box>
                                                    <Box className="category">
                                                        {category} <span>{family && DASH}</span>
                                                        {SPACE_STR}
                                                        <span>{familiesObj[family]}</span>
                                                    </Box>
                                                    <WidgetImage
                                                        className="download only-mobile"
                                                        onClick={() => handleClickDetails({ idProduct, link, family })}
                                                        src="download_line.svg"
                                                    />
                                                </Box>
                                                <Box className="name only-mobile">{name || description}</Box>
                                                <WidgetImage
                                                    className="download only-web"
                                                    onClick={() => handleClickDetails({ idProduct, link, family })}
                                                    src="download_line.svg"
                                                />
                                            </Box>

                                            <Box flex justify="space-between" className="container-details-web">
                                                <Box flex directionColumn className="container-details">
                                                    <Box>
                                                        {i18n.quantity}
                                                        {SPACE_STR}
                                                        {quantity}
                                                    </Box>
                                                    <Box>
                                                        {i18n.purchasePrice}
                                                        {SPACE_STR}
                                                        <DataNumber value={purchasePrice} prefix={currency} />
                                                    </Box>
                                                    <Box>
                                                        {i18n.purchaseValue}
                                                        {SPACE_STR}
                                                        <DataNumber value={purchaseValue} prefix={currency} />
                                                    </Box>
                                                    <Box>
                                                        {i18n.marketPrice}
                                                        {SPACE_STR}
                                                        <DataNumber value={marketPrice} prefix={currency} />
                                                    </Box>
                                                </Box>
                                                <Box className="container-button aside-logo only-web">
                                                    {family && (
                                                        <Box>
                                                            <Image src={`fund_family_${family}.png`} />
                                                        </Box>
                                                    )}
                                                    <Button
                                                        className="details-more"
                                                        onClick={() => handleEvolutionModal(idProduct, link, family)}
                                                        bsStyle="link"
                                                        label="wm.investment.fund.item.option.more.detail"
                                                    />
                                                </Box>
                                            </Box>
                                            <Box className="container">
                                                <Box className="details">
                                                    <Box className="rate-title">
                                                        <span>{i18n.rateLabel}</span>
                                                    </Box>
                                                    <Box
                                                        className={Classnames("row", {
                                                            "select-sort": rate1yearIsPressed,
                                                            "not-select-sort": rate3yearIsPressed || rate5yearIsPressed,
                                                            down: rate1year < 0,
                                                            up: rate1year > 0,
                                                            zero: rate1year === 0,
                                                            "no-data": rate1year === undefined,
                                                            disable: rate3yearIsPressed || rate5yearIsPressed,
                                                        })}>
                                                        <WidgetImage className="only-mobile" src="long-arrow.svg" />
                                                        <span>
                                                            {rate1year ? (
                                                                <div>
                                                                    {rate1year}
                                                                    {PERCENT}
                                                                    <WidgetImage
                                                                        className="only-web"
                                                                        src="long-arrow.svg"
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <span>{i18n.notAvailable}</span>
                                                            )}
                                                        </span>
                                                        <p
                                                            className={Classnames("rate-label", {
                                                                "select-sort": rate1yearIsPressed,
                                                            })}>
                                                            {i18n.rateYTD}
                                                        </p>
                                                    </Box>
                                                    <Box
                                                        className={Classnames("row", {
                                                            "select-sort": rate3yearIsPressed,
                                                            "not-select-sort": rate1yearIsPressed || rate5yearIsPressed,
                                                            down: rate3year < 0,
                                                            up: rate3year > 0,
                                                            zero: rate3year === 0,
                                                            "no-data": rate3year === undefined,
                                                            disable: rate1yearIsPressed || rate5yearIsPressed,
                                                        })}>
                                                        <WidgetImage className="only-mobile" src="long-arrow.svg" />
                                                        <span>
                                                            {rate3year ? (
                                                                <div>
                                                                    {rate3year}
                                                                    {PERCENT}
                                                                    <WidgetImage
                                                                        className="only-web"
                                                                        src="long-arrow.svg"
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <span>{i18n.notAvailable}</span>
                                                            )}
                                                        </span>
                                                        <p
                                                            className={Classnames("rate-label", {
                                                                "select-sort": rate3yearIsPressed,
                                                            })}>
                                                            {i18n.rateThree}
                                                        </p>
                                                    </Box>
                                                    <Box
                                                        className={Classnames("row", {
                                                            "select-sort": rate5yearIsPressed,
                                                            "not-select-sort": rate1yearIsPressed || rate3yearIsPressed,
                                                            down: rate5year < 0,
                                                            up: rate5year > 0,
                                                            zero: rate5year === 0,
                                                            "no-data": rate5year === undefined,
                                                            disable: rate1yearIsPressed || rate3yearIsPressed,
                                                        })}>
                                                        <WidgetImage className="only-mobile" src="long-arrow.svg" />
                                                        <span>
                                                            {rate5year ? (
                                                                <div>
                                                                    {rate5year}
                                                                    {PERCENT}
                                                                    <WidgetImage
                                                                        className="only-web"
                                                                        src="long-arrow.svg"
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <span>{i18n.notAvailable}</span>
                                                            )}
                                                        </span>
                                                        <p
                                                            className={Classnames("rate-label", {
                                                                "select-sort": rate5yearIsPressed,
                                                            })}>
                                                            {i18n.rateFive}
                                                        </p>
                                                    </Box>
                                                </Box>
                                                <Box className="container-button aside-logo only-mobile">
                                                    <Button
                                                        className="details-more"
                                                        onClick={() => handleEvolutionModal(idProduct, link, family)}
                                                        bsStyle="link"
                                                        label="wm.investment.fund.item.option.more.detail"
                                                    />
                                                    {family && (
                                                        <Box>
                                                            <Image src={`fund_family_${family}.png`} />
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Box>
                                            <Box className="container-button funds" justify="end">
                                                <Button
                                                    className="buy portfolio-button"
                                                    onClick={() => handleBuy(idPaper)}
                                                    bsStyle="primary"
                                                    label="wm.investment.fund.item.option.buy"
                                                />
                                                <Button
                                                    className="sell"
                                                    onClick={() => handleSell(idPaper)}
                                                    bsStyle="secondary"
                                                    label="wm.investment.fund.item.option.sell"
                                                />
                                            </Box>
                                        </GridLayout>
                                    ),
                                )
                            ) : (
                                <div className="no-products"> {i18n.noProducts} </div>
                            ))}
                        <div className="disclaimer" dangerouslySetInnerHTML={{ __html: i18n.disclaimer }} />
                    </div>
                )}
            </Container>
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
    categoriesList: SelectorsStoreWM.getCategoriesList(store),
    familiesList: SelectorsStoreWM.getFundFamiliesList(store),
    fetching: SelectorsStoreWM.getFetching(store) || SelectorsStorePosition.isFetching(store),
    i18n: {
        askPriceInfo: StoreI18n.getMessage(store, "wm.papers.askInfo"),
        categories: StoreI18n.getMessage(store, "wm.investment.funds.list.categories"),
        disclaimer: StoreI18n.getMessage(store, "wm.funds.general.disclaimer"),
        familyDescription: StoreI18n.getMessage(
            store,
            `wm.fundFamilies.${SelectorsStoreWM.getFilters(store).family}.description`,
        ),
        familyProductDescription: StoreI18n.getMessage(store, "wm.fundFamilies.descriptionFile.link"),
        funds: StoreI18n.getMessage(store, "wm.investment.funds.list.label"),
        marketPrice: StoreI18n.getMessage(store, "wm.investment.position.product.marketPrice"),
        noProducts: StoreI18n.getMessage(store, "wd.investment.fund.papers.noProducts"),
        notAvailable: StoreI18n.getMessage(store, "client.notAvailable"),
        price: StoreI18n.getMessage(store, "wm.investment.funds.list.price"),
        product: StoreI18n.getMessage(store, "wm.investment.funds.list.product"),
        profile: StoreI18n.getMessage(store, "wm.funds.list.evolution.HSBCprofile"),
        purchasePrice: StoreI18n.getMessage(store, "wm.investment.position.product.purchasePrice"),
        purchaseValue: StoreI18n.getMessage(store, "wm.investment.position.product.purchaseValue"),
        quantity: StoreI18n.getMessage(store, "wm.investment.position.product.quantity"),
        rateFive: StoreI18n.getMessage(store, "wm.investment.rate.five.label"),
        rateLabel: StoreI18n.getMessage(store, "wm.investment.rate.detail.label"),
        rateThree: StoreI18n.getMessage(store, "wm.investment.rate.three.label"),
        rateTitle: StoreI18n.getMessage(store, "wm.investment.rate.title.label"),
        rateYTD: StoreI18n.getMessage(store, "wm.investment.rate.ytd.label"),
    },
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    isFromRiskProfileQuiz: SelectorsStoreInvestment.isFromRiskProfileQuiz(store),
    papersList: SelectorsStoreWM.getFundPapersList(store),
    positions: SelectorsStorePosition.getPositions(store),
    storeFilters: SelectorsStoreWM.getFilters(store),
});

export default HighOrder(WithRouter, Connect(mapStateToProps))(Component);
