import React from "react";

import NumberFormat from "react-number-format";

export const NAME = "NumberFormatInput";

export const PROP = {
    types: {},
    defaults: {},
};

/**
 * El componente NumberFormatInput es un wrapper que tiene como objetivo encapsular el uso de la libreria
 * react-number-format.
 * @param {props} props validas para react-number-format
 */
export function Component(props) {
    return <NumberFormat {...props} />;
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
