import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { TYPE as TypesBackoffice } from "~/store/backoffice";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.CHANGE_LANGUAGE_PRE:
        case TYPE.CHANGE_LANGUAGE:
        case TYPE.PREFERENCES_MODIFY_USER_CONFIGURATION_REQUEST:
        case TYPE.CHANGE_PERSONAL_DATA:
        case TYPE.PREFERENCES_READ_TICKET_USER_CONFIGURATION_REQUEST:
            return { ...storeProp, fetching: true };
        case TYPE.CHANGE_LANGUAGE_PRE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                supportedLanguages: action.supportedLanguages,
            };
        case TYPE.PREFERENCES_CHANGE_USER_DATA_ACCOUNTS_FAILURE:
            return {
                ...storeProp,
                fetching: false,
                fetchingAccountData: false,
            };
        case TYPE.CHANGE_LANGUAGE_PRE_ERROR:
        case TYPE.PREFERENCES_MODIFY_USER_CONFIGURATION_FAILURE:
        case TYPE.PREFERENCES_FORM_MODIFY_DATA_ACCOUNTS_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.NOTIFICATIONS_CONFIGURATION_PRE:
            return { ...storeProp, fetching: true, communicationTypes: null };
        case TYPE.NOTIFICATIONS_CONFIGURATION_PRE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                communicationTypes: action.communicationTypes,
            };
        case TYPE.COMMUNICATION_TYPE_PRE:
            return {
                ...storeProp,
                fetching: true,
                communicationTransportsConfigurations: null,
            };
        case TYPE.COMMUNICATION_TYPE_PRE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                communicationTransportsConfigurations: action.communicationTransportsConfigurations,
            };
        case TYPE.MODIFY_NOTIFICATION_CONFIGURATIONS:
            return {
                ...storeProp,
                communicationTransportsConfigurations: {
                    ...storeProp.communicationTransportsConfigurations,
                    subscriptions: {
                        ...storeProp.communicationTransportsConfigurations.subscriptions,
                        [action.transport]: action.subscribed,
                    },
                },
            };
        case TYPE.GET_USER_DATA_SUCCESS:
            return { ...storeProp, userData: action.data, mode: PROP.defaults.mode, fetchingForm: false };
        case TYPE.GET_USER_DATA:
        case TYPE.MAIL_SEND_CODE:
        case TYPE.MOBILEPHONE_SEND_CODE:
        case TYPE.MAIL_UPDATE:
        case TYPE.MOBILEPHONE_UPDATE:
        case TYPE.PREFERENCES_UPDATE_EMAIL_ACCOUNTS_REQUEST:
            return { ...storeProp, fetchingForm: true };
        case TYPE.MAIL_SEND_CODE_FAILURE:
        case TYPE.MOBILEPHONE_SEND_CODE_FAILURE:
            return { ...storeProp, fetchingForm: false };
        case TYPE.MAIL_SEND_CODE_SUCCESS:
            return {
                ...storeProp,
                fetchingForm: false,
                prevMode: storeProp.mode,
                mode: MODE.PREVIEW,
                newEmail: action.newEmail,
            };
        case TYPE.MOBILEPHONE_SEND_CODE_SUCCESS:
            return {
                ...storeProp,
                fetchingForm: false,
                prevMode: storeProp.mode,
                mode: MODE.PREVIEW,
                newMobilePhone: action.newMobilePhone,
            };
        case TYPE.MOBILEPHONE_SEND_CODE_CANCEL:
            return { ...storeProp, mode: storeProp.prevMode };
        case TYPE.MAIL_SEND_CODE_CANCEL:
            return { ...storeProp, mode: storeProp.prevMode };
        case TYPE.MOBILEPHONE_UPDATE_FAILURE:
        case TYPE.MAIL_UPDATE_FAILURE:
            return { ...storeProp, fetchingForm: false };
        case TYPE.MAIL_UPDATE_SUCCESS:
            return {
                ...storeProp,
                changeTransactionData: action.transaction,
                fetchingForm: false,
                fetching: false,
                prevMode: storeProp.mode,
                mode: MODE.VIEW,
            };
        case TYPE.UPDATE_MAIL_CMB_SUCCESS:
            return { ...storeProp, fetchingForm: false, mode: MODE.EDIT };
        case TYPE.MOBILEPHONE_UPDATE_SUCCESS:
            return {
                ...storeProp,
                changeTransactionData: action.transaction,
                fetchingForm: false,
                fetching: false,
                prevMode: storeProp.mode,
                mode: MODE.VIEW,
            };
        case TYPE.READ_TRANSACTION_REQUEST:
            return {
                ...storeProp,
                mode: MODE.EDIT,
                fetching: true,
            };
        case TYPE.READ_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                changeTransactionData: action.transaction,
                mode: action.transaction.idTransactionStatus === STATUS.DRAFT ? MODE.EDIT : MODE.VIEW,
            };
        case TYPE.CLOSE_TRANSACTION_PREVIEW:
            return {
                ...storeProp,
                fetching: false,
                mode: storeProp.prevMode,
            };
        case TYPE.SIGN_TRANSACTION_REQUEST:
        case TYPE.SIGN_TRANSACTION_PREVIEW_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.SIGN_TRANSACTION_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                prevMode: storeProp.mode,
                mode: MODE.PREVIEW,
            };
        case TYPE.SIGN_TRANSACTION_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.CHANGE_SECURITY_SEAL_PRE:
            return { ...storeProp, fetching: true, selectedSecuritySealId: null };
        case TYPE.CHANGE_SECURITY_SEAL_PRE_SUCCESS:
            return { ...storeProp, fetching: false, securitySeals: action.securitySeals };
        case TYPE.CHANGE_SECURITY_SEAL_CONFIRMATION_PRE:
            return { ...storeProp, selectedSecuritySealId: action.selectedSecuritySealId };
        case TYPE.CHANGE_SECURITY_SEAL_CONFIRMATION:
        case TYPE.CHANGE_SECURITY_SEAL_CONFIRMATION_SUCCESS:
        case TYPE.CLEAN_ERRORS:
            return { ...storeProp, errors: null };
        case TYPE.CHANGE_SECURITY_SEAL_CONFIRMATION_BACK:
            return { ...storeProp, selectedSecuritySealId: null, errors: null };
        case TYPE.CHANGE_SECURITY_SEAL_CONFIRMATION_ERROR:
            return { ...storeProp, errors: action.errors };
        case TYPE.CHANGE_PERSONAL_DATA_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                infoUser: action.infoUser,
                personalData: action.personalData,
                adresses: action.adresses,
                securitySeal: action.securitySeal,
                changePersonalDataInProgress: action.changePersonalDataInProgress,
                newEmail: null,
            };
        case TYPE.PREFERENCES_CHANGE_USER_DATA_ACCOUNTS_CLEAN:
            return {
                ...storeProp,
                infoUser: PROP.defaults.infoUser,
                adresses: PROP.defaults.adresses,
                personalData: PROP.defaults.personalData,
                securitySeal: PROP.defaults.securitySeal,
            };
        case TYPE.CHANGE_PERSONAL_DATA_CMB_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                infoUser: action.infoUser,
                personalData: action.personalData,
                securitySeal: action.securitySeal,
                newEmail: null,
                changePersonalDataInProgress: action.changePersonalDataInProgress,
            };
        case TYPE.CHANGE_PERSONAL_DATA_SECURITYSEALS_SUCCESS:
            return {
                ...storeProp,
                securitySealList: action.securitySealList,
            };
        case TYPE.SHOW_MODAL_SECURITYSEALS:
            return { ...storeProp, showModalSecuritySeal: true };

        case TYPE.HIDE_MODAL_SECURITYSEALS:
            return { ...storeProp, showModalSecuritySeal: false };

        case TYPE.CHANGE_SECURITYSEALS:
            return {
                ...storeProp,
                securitySeal: action.securitySeal,
            };
        case TYPE.CHANGE_ADDRESSES_SUCCESS:
            return {
                ...storeProp,
                legal: action.legal,
                correspondense: action.correspondense,
                countryList: action.countryList,
                cityList: action.cityList,
                townList: action.townList,
                documents: action.documents,
                professions: action.professions,
                loadData: action.loadData,
                maritalStatusList: action.maritalStatusList,
                fetchingForm: false,
            };
        case TYPE.CHANGE_ADDRESSES_SUBMIT:
            return {
                ...storeProp,
                adresses: action.address,
            };
        case TYPE.CHANGE_ADDRESSES_DEPARTAMENT_SUCCESS:
            return {
                ...storeProp,
                cityList: action.cityList,
                townList: PROP.defaults.townList,
                fetching: false,
            };
        case TYPE.CHANGE_ADDRESSES_CITY_SUCCESS:
            return {
                ...storeProp,
                townList: action.townList,
                fetching: false,
            };
        case TYPE.PREFERENCES_MODIFY_USER_CONFIGURATION_CMB_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.PREFERENCES_MODIFY_USER_CONFIGURATION_SUCCESS:
        case TYPE.PREFERENCES_READ_TICKET_USER_CONFIGURATION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                changeTransactionData: action.transaction,
            };
        case TYPE.CHANGE_ADDRESSES_REQUEST:
            return {
                ...storeProp,
                fetchingForm: true,
            };
        case TYPE.CHANGE_ADDRESSES_FAILURE:
            return {
                ...storeProp,
                fetchingForm: false,
            };

        case TYPE.PREFERENCES_CHANGE_USER_DATA_ACCOUNTS_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                fetchingAccountData: true,
            };
        case TYPE.PREFERENCES_FORM_MODIFY_DATA_ACCOUNTS_REQUEST:
        case TYPE.CHANGE_ADDRESSES_DEPARTAMENT_REQUEST:
        case TYPE.CHANGE_ADDRESSES_DEPARTAMENT_AND_CITY_REQUEST:
        case TYPE.CHANGE_ADDRESSES_DATA_FORM_REQUEST:
        case TYPE.CHANGE_ADDRESSES_CITY_REQUEST:
        case TYPE.PREFERENCES_LIST_EMAIL_DATA_ACCOUNTS_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.PREFERENCES_CHANGE_USER_DATA_ACCOUNTS_SUCCESS:
            return {
                ...storeProp,
                changeAccountDataInProgress: action.changeAccountDataInProgress,
                correspondense: action.correspondense,
                fetching: false,
                fetchingAccountData: false,
                infoUser: action.infoUser,
                legal: action.legal,
            };
        case TYPE.PREFERENCES_USER_DATA_ACCOUNTS_CLEAN:
            return {
                ...storeProp,
                legal: PROP.defaults.legal,
                correspondense: PROP.defaults.correspondense,
                infoUser: PROP.defaults.infoUser,
            };
        case TYPE.PREFERENCES_FORM_MODIFY_DATA_ACCOUNTS_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                legal: action.legal,
                countryList: action.countryList,
                correspondense: action.correspondense,
                addressesUser: action.addressesUser,
                cityListLegal: action.cityListLegal,
                townListLegal: action.townListLegal,
                cityListMailings: action.cityListMailings,
                townListMailings: action.townListMailings,
                loadData: action.loadData,
            };
        case TYPE.CHANGE_ADDRESSES_DEPARTAMENT_LEGAL_SUCCESS:
            return {
                ...storeProp,
                cityListLegal: action.cityList,
                townListLegal: PROP.defaults.townList,
                fetching: false,
            };
        case TYPE.CHANGE_ADDRESSES_DEPARTAMENT_MAILINGS_SUCCESS:
            return {
                ...storeProp,
                cityListMailings: action.cityList,
                townListMailings: PROP.defaults.townList,
                fetching: false,
            };
        case TYPE.CHANGE_ADDRESSES_CITY_LEGAL_SUCCESS:
            return {
                ...storeProp,
                townListLegal: action.townList,
                fetching: false,
            };
        case TYPE.CHANGE_ADDRESSES_CITY_MAILINGS_SUCCESS:
            return {
                ...storeProp,
                townListMailings: action.townList,
                fetching: false,
            };
        case TYPE.CHANGE_FORM_LEGAL:
            return {
                ...storeProp,
                isChangeFormLegal: true,
            };
        case TYPE.NO_CHANGE_FORM_LEGAL:
            return {
                ...storeProp,
                isChangeFormLegal: false,
            };
        case TYPE.PREFERENCES_LIST_EMAIL_DATA_ACCOUNTS_SUCCESS:
            return {
                ...storeProp,
                listEmail: action.listEmail,
                fetching: false,
            };
        case TypesBackoffice.BACKOFFICE_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                changeTransactionData: action.transaction,
                fetching: false,
                prevMode: storeProp.mode,
                mode: MODE.VIEW,
            };
        default:
            return storeProp;
    }
};
