import { PROP } from "./_consts";

export default (TYPE) => (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.LOAD_DETAILS_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.LOAD_DETAILS_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.LOAD_DETAILS_SUCCESS:
            return {
                ...storeProp,
                credentialGroups: action.data.credentialGroups,
                signatureLevel: action.data.signatureLevel,
                hasMassiveEnabled: action.data.hasMassiveEnabled,
                userEnvStatus: action.data.userEnvStatus,
                adminGroupsIds: action.data.adminGroupsIds,
                fetching: false,
            };
        case TYPE.UPDATE_SIGNATURE_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.UPDATE_SIGNATURE_REQUEST_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.UPDATE_SIGNATURE_REQUEST_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                signatureLevel: storeProp.signatureLevel === null ? "A" : null,
            };
        case TYPE.LOAD_DATA_FOR_SIGN_UPDATE_SIGNATURE:
            return {
                ...storeProp,
                signatureLevel: action.data.signatureLevel,
                credentialGroups: action.data.credentialGroups,
                idTransaction: action.data.idTransaction,
                idActivity: action.data.idActivity,
            };
        case TYPE.SEND_FORM_SUCCESS:
            return {
                ...PROP.defaults,
            };
        case TYPE.LOAD_FORM_SUCCESS:
            return {
                ...storeProp,
                adminGroupsIds: action.data.adminGroupsIds,
                fetching: false,
            };
        case TYPE.LOAD_FORM_REQUEST: {
            return {
                ...storeProp,
                fetching: true,
            };
        }
        default:
            return storeProp;
    }
};
