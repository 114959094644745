import React from "react";

import { Field } from "formik";
import PropTypes from "prop-types";

import { CREDENTIAL_TYPE_OTP } from "~/constants";
import { get as Get } from "~/util/i18n";

import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";
import Credential from "~/pages/_components/fields/credentials/Credential";

import Style from "./Token.rules.scss";

export const NAME = "Token";

export const PROP = {
    types: { labelButton: PropTypes.string, isSubmitting: PropTypes.bool },
    defaults: { labelButton: "global.send", isSubmitting: false },
};
export function Component({ labelButton, isSubmitting, children, ...rest }) {
    return (
        <section id={Style.ID}>
            <div className="info-text">
                <span>{`${Get("form.credential.hint.multipurpose")} ${Get(labelButton)}`}</span>
            </div>
            <Field
                idForm="token"
                name="otp"
                className="login-form-input input-icon"
                component={Credential}
                autoFocus={false}
                type={CREDENTIAL_TYPE_OTP}
                hidePlaceholder
                {...rest}
            />
            <div className="buttons-container">
                {children}
                <Button type="submit" size="sm" variant="primary" disabled={isSubmitting}>
                    <I18n id={labelButton} />
                </Button>
            </div>
        </section>
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
