import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";

import { SelectorsAction as SelectorsActionFiles } from "~/store/files";

import FormField from "~/pages/forms/_components/_fields/_commons/formField";

export const NAME = "Filelink";

export const PROP = {
    types: {
        label: PropTypes.string,
        lang: PropTypes.string.isRequired,
        hintMap: PropTypes.objectOf(PropTypes.string).isRequired,
        editing: PropTypes.bool.isRequired,
        fileListMap: PropTypes.objectOf(
            PropTypes.shape({
                idFile: PropTypes.number.isRequired,
                fileName: PropTypes.string.isRequired,
            }),
        ).isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const { label, hintMap, lang, editing, fileListMap } = props;

    if (!editing) {
        return null;
    }

    return (
        <div className="form-group">
            {label && <p>{label}</p>}
            <button type="button" className="btn btn-asLink" onClick={() => downloadFile(fileListMap[lang])}>
                {hintMap && hintMap[lang]}
            </button>
        </div>
    );

    function downloadFile(params) {
        const { dispatch } = props;
        dispatch(SelectorsActionFiles.downloadFileLinkRequest(params));
    }
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Compose(FormField({ pureRender: true }), Connect())(Component);
