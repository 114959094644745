import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Field, Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { EMPTY_STR, SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/Simple";
import { SelectorsAction as SelectorsActionAccount, SelectorsStore as SelectorsStoreAccount } from "~/store/account";
import { SelectorsAction as SelectorsActionProducts } from "~/store/products";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { get as GetConfig } from "~/util/config";
import * as I18nUtils from "~/util/i18n";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import TextField from "~/pages/_components/fields/TextField";

const FORM_ID = "accounts.alias";

export const NAME = "SetAlias";

export const PROP = {
    types: {
        account: PropTypes.shape({
            idProduct: PropTypes.string,
            permissions: PropTypes.shape({
                requestCheckbook: PropTypes.bool,
                transferThirdParties: PropTypes.bool,
                transferForeign: PropTypes.bool,
                transferInternal: PropTypes.bool,
                transferLocal: PropTypes.bool,
            }),
        }),
        fetching: PropTypes.bool.isRequired,
        isChangingEnvironment: PropTypes.bool,
        isSubmitting: PropTypes.bool.isRequired,
    },
    defaults: {
        defaults: { account: null },
        isChangingEnvironment: false,
    },
};

export function Component(props) {
    const { dispatch, idAccount, isChangingEnvironment, isFetching, isSubmitting } = props;

    React.useEffect(() => {
        if (!isChangingEnvironment) {
            dispatch(SelectorsActionAccount.readAccount({ idAccount }));
            dispatch(SelectorsActionAccount.closeOptions());
        }
    }, [dispatch, idAccount, isChangingEnvironment]);

    return (
        <React.Fragment>
            <Container
                name={NAME}
                head-onBack={handleBack}
                head-title="generic.changeAlias.title"
                wait={isFetching}
                scopeToShowNotification={SCOPE.ACCOUNT_SET_ALIAS}>
                <Form className="above-the-fold">
                    <Field
                        autoFocus={false}
                        component={TextField}
                        hidePlaceholder
                        idForm={FORM_ID}
                        name="setAlias"
                        type="text"
                    />
                    <Button type="submit" bsStyle="primary" label="global.accept" loading={isSubmitting} />
                </Form>
            </Container>
        </React.Fragment>
    );

    function handleBack() {
        dispatch(GoBack());
    }
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store, props) => ({
    account: SelectorsStoreAccount.getSelectedAccount(store),
    idAccount: props.match.params.id,
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    fetching: SelectorsStoreAccount.getFetching(store),
});

export default HighOrder(
    WithRouter,
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            setAlias: props.account ? props.account.productAlias : EMPTY_STR,
            productId: props.match.params.id,
        }),
        validationSchema: () =>
            Yup.object().shape({
                setAlias: Yup.string()
                    .trim()
                    .required(I18nUtils.get("generic.field.required"))
                    .matches(
                        new RegExp(GetConfig("core.productAlias.regexPattern")),
                        I18nUtils.get("form.character.restriction"),
                    ),
            }),
        handleSubmit: ({ productId, setAlias }, formikBag) => {
            formikBag.props.dispatch(
                SelectorsActionProducts.changeProductAlias({
                    alias: setAlias,
                    idProduct: productId,
                    backAfterCall: true,
                }),
                formikBag,
            );
        },
    }),
)(Component);
