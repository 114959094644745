import { connectRouter as ConnectRouter } from "connected-react-router";
import { combineReducers as Combine } from "redux";
import { persistReducer as PersistReducer } from "redux-persist";
import Storage from "redux-persist/lib/storage";
import StorageSession from "redux-persist/lib/storage/session";

import { Reducer as ReducerAboutIbanca, NAME as NAME_ABOUT_IBANCA } from "~/store/aboutIbanca";
import { Reducer as ReducerAccount, NAME as NAME_ACCOUNT } from "~/store/account";
import { NAME as NAME_ADMINISTRATION, Reducer as ReducerAdministration } from "~/store/administration";
import {
    NAME as NAME_ADMINISTRATION_GROUPS,
    Reducer as ReducerAdministrationGroups,
} from "~/store/administration/advanced";
import {
    Reducer as ReducerAdministrationTicket,
    NAME as NAME_ADMINISTRATION_TICKET,
} from "~/store/administration/common/administrationTicket";
import { Reducer as ReducerGroups, NAME as NAME_GROUPS } from "~/store/administration/groups";
import {
    NAME as NAME_ADMINISTRATION_USERS,
    Reducer as ReducerAdministrationUsers,
} from "~/store/administration/users/users";
import {
    NAME as NAME_ADMINISTRATION_USERS_INVITE,
    Reducer as ReducerAdministrationUsersInvite,
} from "~/store/administration/users/usersInvite";
import { Reducer as ReducerApp, NAME as NAME_APP } from "~/store/app";
import { Reducer as ReducerAutomaticDebit, NAME as NAME_AUTOMATIC_DEBITS } from "~/store/automaticDebits";
import { Reducer as ReducerBackoffice, NAME as NAME_BACKOFFICE } from "~/store/backoffice";
import { Reducer as ReducerBankSelector, NAME as NAME_BANK_SELECTOR } from "~/store/bankSelector";
import { Reducer as ReducerBiometric, NAME as NAME_BIOMETRIC } from "~/store/biometric";
import { Reducer as ReducerCampaigns, NAME as NAME_CAMPAIGNS } from "~/store/campaigns";
import { Reducer as ReducerChangeMyPhone, NAME as NAME_CHANGE_MY_PHONE } from "~/store/changeMyPhone";
import { Reducer as ReducerComex, NAME as NAME_COMEX } from "~/store/comex";
import {
    Reducer as ReducerComexBondsAndGuarantees,
    NAME as NAME_COMEX_BONDS_AND_GUARANTEES,
} from "~/store/comex/bondsAndGuarantees";
import { Reducer as ReducerComexExport, NAME as NAME_COMEX_EXPORT } from "~/store/comex/export";
import { Reducer as ReducerComexImport, NAME as NAME_COMEX_IMPORT } from "~/store/comex/import";
import { Reducer as ReducerCommunication, NAME as NAME_COMMUNICATION } from "~/store/communications";
import { Reducer as ReducerConfig, NAME as NAME_CONFIG } from "~/store/config";
import { Reducer as ReducerConnectedParties, NAME as NAME_CONNECTED_PARTIES } from "~/store/connectedParties";
import { Reducer as ReducerCC, NAME as NAME_CC } from "~/store/creditCards/creditCard";
import { Reducer as ReducerCCPN, NAME as NAME_CCPN } from "~/store/creditCards/purchaseNotification";
import { Reducer as ReducerCustomsPayment, NAME as NAME_CUSTOMS_PAYMENT } from "~/store/customsPayment";
import { Reducer as ReducerDC, NAME as NAME_DC } from "~/store/debitCards";
import { Reducer as ReducerDesktop, NAME as NAME_DESKTOP } from "~/store/desktop";
import { Reducer as ReducerEnrollment, NAME as NAME_ENROLLMENT } from "~/store/enrollment";
import { Reducer as ReducerExternalPayments, NAME as NAME_EXTERNAL_PAYMENTS } from "~/store/externalPayments";
import { Reducer as ReducerFactoring, NAME as NAME_FACTORING } from "~/store/factoring";
import {
    Reducer as ReducerFactoringAdvancePayments,
    NAME as NAME_FACTORING_ADVANCE_PAYMETS,
} from "~/store/factoring/advancePayments";
import {
    Reducer as ReducerFactoringUploadPaymentOrders,
    NAME as NAME_FACTORING_UPLOAD_PAYMENT_ORDERS,
} from "~/store/factoring/uploadPaymentOrders";
import {
    Reducer as ReducerFactoringUploadSuppliers,
    NAME as NAME_FACTORING_UPLOAD_SUPPLIERS,
} from "~/store/factoring/uploadSuppliers";
import { Reducer as ReducerFiles, NAME as NAME_FILES } from "~/store/files";
import { Reducer as ReducerFilesDownload, NAME as NAME_FILES_DOWNLOAD } from "~/store/filesDownload";
import { Reducer as ReducerFingerPrint, NAME as NAME_FINGERPRINT } from "~/store/fingerprint";
import { Reducer as ReducerForm, NAME as NAME_FORM } from "~/store/form";
import { Reducer as ReducerGeneralConditions, NAME as NAME_GENERAL_CONDITIONS } from "~/store/generalConditions";
import { Reducer as ReducerI18n, NAME as NAME_I18N } from "~/store/i18n";
import { Reducer as ReducerInvestment, NAME as NAME_INVESTMENT } from "~/store/investment";
import { Reducer as ReducerLoan, NAME as NAME_LOAN } from "~/store/loan";
import { Reducer as ReducerLoanRequest, NAME as NAME_LOAN_REQUEST } from "~/store/loanRequest";
import { Reducer as ReducerMapLocations, NAME as NAME_MAP_LOCATIONS } from "~/store/locations";
import { Reducer as ReducerLogin, NAME as NAME_LOGIN } from "~/store/login";
import { Reducer as ReducerMigration, NAME as NAME_MIGRATION } from "~/store/migration";
import { Reducer as ReducerMultilineFile, NAME as NAME_MULTILINE_FILE } from "~/store/multilinefile";
import { Reducer as ReducerNotification, NAME as NAME_NOTIFICATION } from "~/store/notification";
import { Reducer as ReducerPosition, NAME as NAME_POSITION } from "~/store/position";
import { Reducer as ReducerPTP, NAME as NAME_PTP } from "~/store/preferentialTradingPrice";
import { Reducer as ReducerRecoveryPassword, NAME as NAME_RECOVERY_PASSWORD } from "~/store/recoverypassword";
import {
    Reducer as ReducerRecoveryPasswordNoToken,
    NAME as NAME_RECOVERY_PASSWORD_NO_TOKEN,
} from "~/store/recoverypassword/noToken";
import { Reducer as ReducerServicePayment, NAME as NAME_SERVICE_PAYMENT } from "~/store/servicePayments";
import { Reducer as ReducerSession, NAME as NAME_SESSION } from "~/store/session";
import { Reducer as ReducerSettings, NAME as NAME_SETTINGS } from "~/store/settings";
import { Reducer as ReducerShare, NAME as NAME_SHARE } from "~/store/share";
import { Reducer as ReducerStatus, NAME as NAME_STATUS } from "~/store/status";
import { Reducer as ReducerSwift, NAME as NAME_SWIFT } from "~/store/swift";
import { Reducer as ReducerTemplate, NAME as NAME_TEMPLATE } from "~/store/template";
import { Reducer as ReducerTransactionLines, NAME as NAME_TRANSACTION_LINES } from "~/store/transactionLines";
import { Reducer as ReducerTransactions, NAME as NAME_TRANSACTIONS } from "~/store/transactions";
import { Reducer as ReducerTransactionsHistory, NAME as NAME_TRANSACTIONS_HISTORY } from "~/store/transactionsHistory";
import { Reducer as ReducerTransfers, NAME as NAME_TRANSFERS } from "~/store/transfers";
import { Reducer as ReducerWidget, NAME as NAME_WIDGET } from "~/store/widget";
import { Reducer as ReducerWMBonds, NAME as NAME_WM_BONDS } from "~/store/wm/bonds";
import { Reducer as ReducerWMBuy, NAME as NAME_WM_BUY } from "~/store/wm/funds/buy";
import { Reducer as ReducerWMSellFunds, NAME as NAME_WM_SELL_FUNDS } from "~/store/wm/funds/sell";
import { Reducer as ReducerWMSwitchFunds, NAME as NAME_WM_SWITCH_PAPERS } from "~/store/wm/funds/switch";
import { Reducer as ReducerWMListPapers, NAME as NAME_WM_LIST_PAPERS } from "~/store/wm/listPapers";
import { Reducer as ReducerWMShares, NAME as NAME_WM_SHARES } from "~/store/wm/shares";
import History from "~/util/history";

export default PersistReducer(
    {
        key: "root",
        storage: Storage,
        whitelist: ["config", "i18n"],
    },
    Combine({
        router: ConnectRouter(History),
        [NAME_ABOUT_IBANCA]: ReducerAboutIbanca,
        [NAME_ACCOUNT]: ReducerAccount,
        [NAME_ADMINISTRATION_GROUPS]: ReducerAdministrationGroups,
        [NAME_ADMINISTRATION]: ReducerAdministration,
        [NAME_ADMINISTRATION_TICKET]: ReducerAdministrationTicket,
        [NAME_ADMINISTRATION_USERS_INVITE]: ReducerAdministrationUsersInvite,
        [NAME_ADMINISTRATION_USERS]: ReducerAdministrationUsers,
        [NAME_APP]: ReducerApp,
        [NAME_AUTOMATIC_DEBITS]: ReducerAutomaticDebit,
        [NAME_BACKOFFICE]: ReducerBackoffice,
        [NAME_BANK_SELECTOR]: ReducerBankSelector,
        [NAME_BIOMETRIC]: ReducerBiometric,
        [NAME_CAMPAIGNS]: ReducerCampaigns,
        [NAME_CCPN]: ReducerCCPN,
        [NAME_CC]: ReducerCC,
        [NAME_CHANGE_MY_PHONE]: ReducerChangeMyPhone,
        [NAME_COMEX_BONDS_AND_GUARANTEES]: ReducerComexBondsAndGuarantees,
        [NAME_COMEX_EXPORT]: ReducerComexExport,
        [NAME_COMEX_IMPORT]: ReducerComexImport,
        [NAME_COMEX]: ReducerComex,
        [NAME_COMMUNICATION]: PersistReducer(
            {
                key: "communication",
                storage: StorageSession,
                blacklist: ["isFetching"],
            },
            ReducerCommunication,
        ),
        [NAME_CONFIG]: ReducerConfig,
        [NAME_CONNECTED_PARTIES]: ReducerConnectedParties,
        [NAME_CUSTOMS_PAYMENT]: ReducerCustomsPayment,
        [NAME_DC]: ReducerDC,
        [NAME_DESKTOP]: ReducerDesktop,
        [NAME_ENROLLMENT]: ReducerEnrollment,
        [NAME_EXTERNAL_PAYMENTS]: ReducerExternalPayments,
        [NAME_FACTORING_ADVANCE_PAYMETS]: ReducerFactoringAdvancePayments,
        [NAME_FACTORING]: ReducerFactoring,
        [NAME_FACTORING_UPLOAD_PAYMENT_ORDERS]: ReducerFactoringUploadPaymentOrders,
        [NAME_FACTORING_UPLOAD_SUPPLIERS]: ReducerFactoringUploadSuppliers,
        [NAME_FILES_DOWNLOAD]: ReducerFilesDownload,
        [NAME_FILES]: ReducerFiles,
        [NAME_FINGERPRINT]: PersistReducer(
            {
                key: "fingerprint",
                whitelist: ["hidden", "showAgain"],
                storage: Storage,
            },
            ReducerFingerPrint,
        ),
        [NAME_FORM]: ReducerForm,
        [NAME_GENERAL_CONDITIONS]: ReducerGeneralConditions,
        [NAME_GROUPS]: ReducerGroups,
        [NAME_I18N]: ReducerI18n,
        [NAME_INVESTMENT]: ReducerInvestment,
        [NAME_LOAN]: ReducerLoan,
        [NAME_LOAN_REQUEST]: ReducerLoanRequest,
        [NAME_LOGIN]: PersistReducer(
            {
                key: "login",
                storage: StorageSession,
                whitelist: ["rememberedUser"],
            },
            ReducerLogin,
        ),
        [NAME_MAP_LOCATIONS]: ReducerMapLocations,
        [NAME_MIGRATION]: ReducerMigration,
        [NAME_MULTILINE_FILE]: ReducerMultilineFile,
        [NAME_NOTIFICATION]: ReducerNotification,
        [NAME_POSITION]: ReducerPosition,
        [NAME_PTP]: ReducerPTP,
        [NAME_RECOVERY_PASSWORD]: ReducerRecoveryPassword,
        [NAME_RECOVERY_PASSWORD_NO_TOKEN]: ReducerRecoveryPasswordNoToken,
        [NAME_SERVICE_PAYMENT]: ReducerServicePayment,
        [NAME_SESSION]: PersistReducer(
            {
                key: "session",
                storage: StorageSession,
                blacklist: ["showCaptcha"],
            },
            ReducerSession,
        ),
        [NAME_SETTINGS]: ReducerSettings,
        [NAME_SHARE]: ReducerShare,
        [NAME_STATUS]: ReducerStatus,
        [NAME_SWIFT]: ReducerSwift,
        [NAME_TEMPLATE]: ReducerTemplate,
        [NAME_TRANSACTION_LINES]: ReducerTransactionLines,
        [NAME_TRANSACTIONS]: ReducerTransactions,
        [NAME_TRANSACTIONS_HISTORY]: ReducerTransactionsHistory,
        [NAME_TRANSFERS]: ReducerTransfers,
        [NAME_WIDGET]: ReducerWidget,
        [NAME_WM_BONDS]: ReducerWMBonds,
        [NAME_WM_BUY]: ReducerWMBuy,
        [NAME_WM_LIST_PAPERS]: ReducerWMListPapers,
        [NAME_WM_SELL_FUNDS]: ReducerWMSellFunds,
        [NAME_WM_SHARES]: ReducerWMShares,
        [NAME_WM_SWITCH_PAPERS]: ReducerWMSwitchFunds,
    }),
);
