import { replace as Replace, push as Push } from "connected-react-router";
import { call as Call, put as Put, select as Select, takeLatest as TakeLatest } from "redux-saga/effects";

import { LEVEL, MOBILE_LOGIN_TERMS_NOT_ACCEPTED, RESPONSE_TYPE, SCOPE, SLASH } from "~/constants";
import { TYPE as TYPES_APP } from "~/store/app";
import { SelectorsAction as SelectorsActionCampaigns } from "~/store/campaigns";
import { SelectorsAction as SelectorsActionDesktop } from "~/store/desktop";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsAction as SelectorsActionGeneralConditions } from "~/store/generalConditions";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { TYPE as StatusTypes } from "~/store/status";
import * as UtilI18n from "~/util/i18n";
import * as UtilsSoftToken from "~/util/softToken";
import * as UtilsVUFingerprint from "~/util/vuFingerprint";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsMiddleware, SelectorsStore } from "./_selectors";

const sagas = [
    TakeLatest(TYPE.ACCEPT_MOBILE_REQUIRED_PERMISSIONS, acceptMobileRequiredPermissions),
    TakeLatest(TYPE.CHANGE_ENVIRONMENT_REQUEST, handleChangeEnvironmentRequest),
    TakeLatest(TYPE.EXPIRE, expireSession),
    TakeLatest(TYPE.EXTEND, extendSession),
    TakeLatest(TYPE.GET_DEVICE_LOCATION_VU, saveDeviceLocationVU),
    TakeLatest(TYPE.LOGOUT_REQUEST, handleLogoutRequest),
    TakeLatest(TYPE.READ_PERMISSIONS_REQUEST, readPermissionsRequest),
];

export default sagas;

function* acceptMobileRequiredPermissions() {
    yield Call(UtilsSoftToken.vascoHasPermission);
}

function* expireSession(props) {
    // dispatching action to save last href in status state
    yield Put({ type: StatusTypes.SAVE_LAST_HREF, props });
    yield Put({ type: TYPE.LOGOUT_REQUEST });

    yield Put(
        SelectorsActionNotification.showNotification({
            message: UtilI18n.get("session.expired"),
            level: LEVEL.ERROR,
            scopes: [SCOPE.EXTERNAL_DASHBOARD],
        }),
    );
}

function* extendSession() {
    yield Call(SelectorsMiddleware.extend);

    yield Put({ type: TYPE.EXTEND_SUCCESS });
}

function* handleChangeEnvironmentRequest(props) {
    const { externalPayment, formikBag, idEnvironment, location, payload, rememberEnvironment, shouldNavigate } = props;

    const response = yield Call(SelectorsMiddleware.changeEnvironment, {
        idEnvironmentToChange: idEnvironment,
        setAsDefault: rememberEnvironment,
    });

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put({ type: TYPE.CHANGE_ENVIRONMENT_ERROR });

        const { code } = response.data;

        if (MOBILE_LOGIN_TERMS_NOT_ACCEPTED === code) {
            yield Put(
                SelectorsActionGeneralConditions.generalConditionsFromEnvironment({
                    idEnvironment,
                    rememberEnvironment,
                }),
            );

            yield Put(Push("/generalConditionsLogin"));
        }
    } else {
        const user = yield Select(SelectorsStore.getUser);

        const { data } = response.data;

        let forms = null;

        if (data.forms) {
            forms = {};

            for (let i = 0; i < data.forms.length; i += 1) {
                let category = forms[data.forms[i].category];

                if (!category) {
                    category = [];

                    forms[data.forms[i].category] = category;
                }

                category.push(data.forms[i]);
            }
        }

        const environment = {
            administrationScheme: data.administrationScheme,
            environmentData: data.environmentData,
            factoring: data.factoring,
            forms,
            id: data.activeIdEnvironment,
            isPremierException: data.activeEnvironmentIsPremierException,
            name: data.activeEnvironmentName,
            permissions: data.permissions,
            riskProfileCode: data.riskProfileCode,
            riskProfileExpiration: data.riskProfileExpiration,
            type: data.activeEnvironmentType,
        };
        const { environments } = response.data.data;
        const { isAdministrator } = response.data.data;

        if (rememberEnvironment) {
            // When the change is successful, the active environment becomes user's default environment
            user.defaultEnvironmentId = data.activeIdEnvironment;
        }

        yield Put({
            environment,
            environments,
            isAdministrator,
            type: TYPE.CHANGE_ENVIRONMENT_SUCCESS,
            user,
        });

        yield Put(
            SelectorsAction.notifyUser({
                accountDataForceUpdate: data.accountDataForceUpdate,
                accountDataNotify: data.accountDataNotify,
                personalDataForceUpdate: data.personalDataForceUpdate,
                personalDataNotify: data.personalDataNotify,
            }),
        );

        yield Put(
            SelectorsAction.suggestAliasEnrollment({
                suggestAliasEnrollment: data.suggestAliasEnrollment,
            }),
        );

        const qrName = {
            en: data["qrName-en"],
            es: data["qrName-es"],
            pt: data["qrName-pt"],
        };

        const qrData = {
            qrCurrentInstallment: data.qrCurrentInstallment,
            qrDueDate: data.qrDueDate,
            qrEnabled: data.qrEnabled,
            qrLimitAmount: data.qrLimitAmount,
            qrLimitCurrency: data.qrLimitCurrency,
            qrMaxLimitAmount: data.qrMaxLimitAmount,
            qrMaxLimitCurrency: data.qrMaxLimitCurrency,
            qrName,
            qrTypeDiscount: data.qrTypeDiscount,
        };

        yield Put(SelectorsAction.saveQrData({ qrData }));

        if (!externalPayment) {
            if (location && location.pathname !== "/desktop" && !data.personalDataForceUpdate) {
                yield Put(Replace("/desktop"));
            }

            // Previous environment's campaigns are removed
            yield Put(SelectorsActionCampaigns.removeCampaigns());

            if (!shouldNavigate) {
                yield Put(SelectorsActionDesktop.loadLayoutRequest());
            }

            if (formikBag) {
                formikBag.setSubmitting(false);
            }
        } else {
            yield Put(SelectorsActionForm.readformRequest({ payload }));
        }
    }
}

function* handleLogoutRequest() {
    yield Put(Push(SLASH));

    try {
        yield Put(SelectorsActionCampaigns.removeCampaigns());

        yield Call(SelectorsMiddleware.logout);
    } catch (err) {
        /* do something with the error */
    }

    yield Put({ type: TYPES_APP.CLEAN_UP });
}

function* readPermissionsRequest() {
    const response = yield Call(SelectorsMiddleware.readPermissions);

    if (response) {
        yield Put(SelectorsAction.readPermissionsSuccess({ permissions: response.data.data.permissions }));
    }
}

function* saveDeviceLocationVU() {
    const deviceLocationVU = yield Call(UtilsVUFingerprint.getDeviceLocationVU);

    yield Put(SelectorsAction.saveDeviceLocationVU({ deviceLocationVU }));
}
