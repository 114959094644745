import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, TYPE, PATH } from "./_consts";

export const SelectorsMiddleware = {
    loadListRequest: (props) => {
        const { pageNumber = 1, orderBy = "name ASC" } = props;
        return API.executeWithAccessToken(PATH.ADMINISTRATION_GROUPS_LIST, {
            pageNumber,
            orderBy,
        });
    },

    exportListRequest: (props) => {
        const { format = "xls", orderBy = "name ASC" } = props;
        return API.executeWithAccessToken(PATH.ADMINISTRATION_GROUPS_EXPORT, {
            format,
            orderBy,
        });
    },

    changeGroupsStatusPreview: (props) =>
        API.executeWithAccessToken(PATH.ADMINISTRATION_GROUPS_BLOCKUNBLOCK_PREVIEW, props),

    changeGroupsStatusConfirmation: (props) =>
        API.executeWithAccessToken(PATH.ADMINISTRATION_GROUPS_BLOCKUNBLOCK_SEND, props), // params, credentials

    deleteGroupsPreview: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_GROUPS_DELETE_PREVIEW, props), // params

    deleteGroupsConfirmation: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_GROUPS_DELETE_SEND, props), // params, credentials
};

export const SelectorsAction = {
    loadListRequest: (props) => FactoryAction(TYPE.LOAD_LIST_REQUEST, props), // filters
    loadMoreRequest: (props) => FactoryAction(TYPE.LOAD_MORE_REQUEST, props), // filters
    exportListRequest: (props) => FactoryAction(TYPE.EXPORT_LIST_REQUEST, props), // format
    loadListFailure: () => FactoryAction(TYPE.LOAD_LIST_FAILURE),
    loadListSuccess: (props) => FactoryAction(TYPE.LOAD_LIST_SUCCESS, props), // data
    changeGroupStatusPreview: (props) => FactoryAction(TYPE.CHANGE_GROUP_STATUS_PREVIEW, props), // groupList, groupNameList, groupAction
    changeGroupsStatusConfirmation: (props) => FactoryAction(TYPE.CHANGE_GROUP_STATUS_CONFIRMATION, props), // groupsToApplyAction, groupNameList, groupAction, credentials, formikBag
    loadMoreSuccess: (props) => FactoryAction(TYPE.LOAD_MORE_SUCCESS, props),
    exportListFailure: () => FactoryAction(TYPE.EXPORT_LIST_FAILURE),
    exportListSuccess: () => FactoryAction(TYPE.EXPORT_LIST_SUCCESS),
    changeGroupStatusPreviewSuccess: (props) => FactoryAction(TYPE.CHANGE_GROUP_STATUS_PREVIEW_SUCCESS, props),
    changeGroupStatusPreviewError: (props) => FactoryAction(TYPE.CHANGE_GROUP_STATUS_PREVIEW_ERROR, props),
    hideModal: (props) => FactoryAction(TYPE.HIDE_MODAL, props),
};

export const SelectorsStore = {
    getGroups: (store) => store[NAME].list,
    getGroupsExtendedInfo: (store) => store[NAME].groupsExtendedInfo,
    getCurrentPage: (store) => store[NAME].currentPage,
    getTotalPages: (store) => store[NAME].totalPages,
    isFetching: (store) => store[NAME].fetching,
    isFetchingExport: (store) => store[NAME].fetchingExport,
    getHasMoreData: (store) => store[NAME].hasMoreData,
    isFetchingMoreGroups: (store) => store[NAME].fetchingMoreGroups,
    getGroupAction: (store) => store[NAME].groupAction,
    getGroupsToApplyAction: (store) => store[NAME].groupsToApplyAction,
    getGroupsNamesToApplyAction: (store) => store[NAME].groupsNamesToApplyAction,
    getIdTransaction: (store) => store[NAME].idTransaction,
    getIdActivity: (store) => store[NAME].idActivity,
    getCredentialGroups: (store) => store[NAME].credentialGroups,
    showModal: (store) => store[NAME].showModal,
};
