import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    const {
        pageNumber,
        credentialGroups,
        groupList,
        groupNameList,
        groupAction,
        idTransaction,
        idActivity,
        data: { groups, groupsExtendedInfo, currentPage, totalPages, list } = {},
    } = action;
    switch (action.type) {
        case TYPE.LOAD_LIST_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                currentPage: pageNumber,
            };
        case TYPE.LOAD_LIST_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.LOAD_LIST_SUCCESS:
            return {
                ...storeProp,
                list: groups,
                groupsExtendedInfo,
                currentPage,
                totalPages,
                hasMoreData: currentPage < totalPages,
                fetching: false,
            };
        case TYPE.LOAD_MORE_REQUEST:
            return {
                ...storeProp,
                fetchingMoreGroups: true,
            };
        case TYPE.EXPORT_LIST_REQUEST:
            return {
                ...storeProp,
                fetchingExport: true,
            };
        case TYPE.EXPORT_LIST_FAILURE:
        case TYPE.EXPORT_LIST_SUCCESS:
            return {
                ...storeProp,
                fetchingExport: false,
            };

        case TYPE.LOAD_MORE_SUCCESS:
            return {
                ...storeProp,
                fetchingMoreGroups: false,
                list: storeProp.list ? storeProp.list.concat(groups) : list,
                groupsExtendedInfo: storeProp.groupsExtendedInfo
                    ? { ...storeProp.groupsExtendedInfo, ...groupsExtendedInfo }
                    : groupsExtendedInfo,
                hasMoreData: currentPage < totalPages,
                currentPage,
            };
        case TYPE.CHANGE_GROUP_STATUS_PREVIEW:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.CHANGE_GROUP_STATUS_PREVIEW_ERROR:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.CHANGE_GROUP_STATUS_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                credentialGroups,
                groupAction,
                groupsToApplyAction: groupList,
                groupsNamesToApplyAction: groupNameList,
                idTransaction,
                idActivity,
                fetching: false,
                showModal: true,
            };
        case TYPE.CHANGE_GROUP_STATUS_CONFIRMATION:
            return {
                ...storeProp,
                fetching: true,
            };

        case TYPE.HIDE_MODAL:
            return {
                ...storeProp,
                showModal: false,
                fetching: false,
            };

        default:
            return storeProp;
    }
};
