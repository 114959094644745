import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    transferCreditLetterPreRequest: () => API.executeWithAccessToken(PATH.TRANSFER_CREDIT_LETTER_PRE_REQUEST),
    transferCreditLetterPreviewRequest: (props) =>
        API.executeWithAccessToken(PATH.TRANSFER_CREDIT_LETTER_PREVIEW_REQUEST, props),
    transferCreditLetterRequest: (props) => API.executeWithAccessToken(PATH.TRANSFER_CREDIT_LETTER_REQUEST, props),

    /** PRESENTATION CREDIT LETTER */
    presentationCreditLetterPreRequest: () => API.executeWithAccessToken(PATH.PRESENTATION_CREDIT_LETTER_PRE_REQUEST),
    presentationCreditLetterPreviewRequest: (props) =>
        API.executeWithAccessToken(PATH.PRESENTATION_CREDIT_LETTER_PREVIEW_REQUEST, props),
    presentationCreditLetterRequest: (props) =>
        API.executeWithAccessToken(PATH.PRESENTATION_CREDIT_LETTER_REQUEST, props),
    /** ---------------------------------- */
    /** PRESENTATION BILLING DOCUMENTS */
    presentBillingDocumentsPreRequest: () => API.executeWithAccessToken(PATH.PRESENT_BILLING_DOCUMENTS_PRE_REQUEST),
    presentBillingDocumentsPreviewRequest: (props) =>
        API.executeWithAccessToken(PATH.PRESENT_BILLING_DOCUMENTS_PREVIEW_REQUEST, props),
    presentBillingDocumentsSendRequest: (props) =>
        API.executeWithAccessToken(PATH.PRESENT_BILLING_DOCUMENTS_SEND_REQUEST, props),

    /** BILLING CLAIM */
    billingClaimPreRequest: () => API.executeWithAccessToken(PATH.BILLING_CLAIM_PRE_REQUEST),
    billingClaimPreviewRequest: (props) => API.executeWithAccessToken(PATH.BILLING_CLAIM_PREVIEW_REQUEST, props),
    billingClaimSendRequest: (props) => API.executeWithAccessToken(PATH.BILLING_CLAIM_SEND_REQUEST, props),

    /** PURCHASE DOCUMENTS */
    purchaseDocumentsPreRequest: () => API.executeWithAccessToken(PATH.PURCHASE_DOCUMENTS_PRE_REQUEST),
    purchaseDocumentsPreviewRequest: (props) =>
        API.executeWithAccessToken(PATH.PURCHASE_DOCUMENTS_PREVIEW_REQUEST, props),
    purchaseDocumentsSendRequest: (props) => API.executeWithAccessToken(PATH.PURCHASE_DOCUMENTS_SEND_REQUEST, props),

    /** AUTHORIZATION DOCUMENTS DISCREPANCIES */
    authorizationDocumentsDiscrepanciesPreRequest: () =>
        API.executeWithAccessToken(PATH.AUTHORIZATION_DOCUMENTS_DISCREPANCIES_PRE_REQUEST),
    authorizationDocumentsDiscrepanciesPreviewRequest: (props) =>
        API.executeWithAccessToken(PATH.AUTHORIZATION_DOCUMENTS_DISCREPANCIES_PREVIEW_REQUEST, props),
    authorizationDocumentsDiscrepanciesSendRequest: (props) =>
        API.executeWithAccessToken(PATH.AUTHORIZATION_DOCUMENTS_DISCREPANCIES_SEND_REQUEST, props),
    /** ---------------------------------- */
    /** CESSION CREDIT LETTER */
    cessionCreditLetterPreRequest: () => API.executeWithAccessToken(PATH.CESSION_CREDIT_LETTER_PRE_REQUEST),
    cessionCreditLetterPreviewRequest: (props) =>
        API.executeWithAccessToken(PATH.CESSION_CREDIT_LETTER_PREVIEW_REQUEST, props),
    cessionCreditLetterSendRequest: (props) =>
        API.executeWithAccessToken(PATH.CESSION_CREDIT_LETTER_SEND_REQUEST, props),
    /** ---------------------------------- */
};

export const SelectorsAction = {
    /** BILLING CLAIM */
    billingClaimPreRequest: () => FactoryAction(TYPE.BILLING_CLAIM_PRE_REQUEST),
    billingClaimPreSuccess: (props) => FactoryAction(TYPE.BILLING_CLAIM_PRE_SUCCESS, props),
    billingClaimPreFailure: () => FactoryAction(TYPE.BILLING_CLAIM_PRE_FAILURE),

    billingClaimPreviewRequest: (props) => FactoryAction(TYPE.BILLING_CLAIM_PREVIEW_REQUEST, props),
    billingClaimPreviewSuccess: (props) => FactoryAction(TYPE.BILLING_CLAIM_PREVIEW_SUCCESS, props),
    billingClaimPreviewFailure: (props) => FactoryAction(TYPE.BILLING_CLAIM_PREVIEW_FAILURE, props),

    billingClaimSendRequest: (props) => FactoryAction(TYPE.BILLING_CLAIM_SEND_REQUEST, props),
    billingClaimSendSuccess: (props) => FactoryAction(TYPE.BILLING_CLAIM_SEND_SUCCESS, props),
    billingClaimSendFailure: (props) => FactoryAction(TYPE.BILLING_CLAIM_SEND_FAILURE, props),

    /** PRESENTA BILLING DOCUMENTS */
    presentBillingDocumentsPreRequest: () => FactoryAction(TYPE.PRESENT_BILLING_DOCUMENTS_PRE_REQUEST),
    presentBillingDocumentsPreSuccess: (props) => FactoryAction(TYPE.PRESENT_BILLING_DOCUMENTS_PRE_SUCCESS, props),
    presentBillingDocumentsPreFailure: () => FactoryAction(TYPE.PRESENT_BILLING_DOCUMENTS_PRE_FAILURE),
    presentBillingDocumentsPreviewRequest: (props) =>
        FactoryAction(TYPE.PRESENT_BILLING_DOCUMENTS_PREVIEW_REQUEST, props),
    presentBillingDocumentsPreviewSuccess: (props) =>
        FactoryAction(TYPE.PRESENT_BILLING_DOCUMENTS_PREVIEW_SUCCESS, props),
    presentBillingDocumentsPreviewFailure: (props) =>
        FactoryAction(TYPE.PRESENT_BILLING_DOCUMENTS_PREVIEW_FAILURE, props),
    presentBillingDocumentsSendRequest: (props) => FactoryAction(TYPE.PRESENT_BILLING_DOCUMENTS_SEND_REQUEST, props),
    presentBillingDocumentsSendSuccess: (props) => FactoryAction(TYPE.PRESENT_BILLING_DOCUMENTS_SEND_SUCCESS, props),
    presentBillingDocumentsSendFailure: (props) => FactoryAction(TYPE.PRESENT_BILLING_DOCUMENTS_SEND_FAILURE, props),
    /** ---------------------------------- */

    /** PRESENTATION CREDIT LETTER */
    presentationCreditLetterPreRequest: () => FactoryAction(TYPE.PRESENTATION_CREDIT_LETTER_PRE_REQUEST),
    presentationCreditLetterPreSuccess: (props) => FactoryAction(TYPE.PRESENTATION_CREDIT_LETTER_PRE_SUCCESS, props),
    presentationCreditLetterPreFailure: () => FactoryAction(TYPE.PRESENTATION_CREDIT_LETTER_PRE_FAILURE),
    presentationCreditLetterPreviewRequest: (props) =>
        FactoryAction(TYPE.PRESENTATION_CREDIT_LETTER_PREVIEW_REQUEST, props),
    presentationCreditLetterPreviewSuccess: (props) =>
        FactoryAction(TYPE.PRESENTATION_CREDIT_LETTER_PREVIEW_SUCCESS, props),
    presentationCreditLetterPreviewFailure: () => FactoryAction(TYPE.PRESENTATION_CREDIT_LETTER_PREVIEW_FAILURE),
    presentationCreditLetterSendRequest: (props) => FactoryAction(TYPE.PRESENTATION_CREDIT_LETTER_SEND_FORM, props),
    presentationCreditLetterSendSuccess: (props) =>
        FactoryAction(TYPE.PRESENTATION_CREDIT_LETTER_SEND_FORM_SUCCESS, props),
    presentationCreditLetterSendFailure: () => FactoryAction(TYPE.PRESENTATION_CREDIT_LETTER_SEND_FORM_FAILURE),

    /** PURCHASE DOCUMENTS */
    purchaseDocumentsPreRequest: () => FactoryAction(TYPE.PURCHASE_DOCUMENTS_PRE_REQUEST),
    purchaseDocumentsPreSuccess: (props) => FactoryAction(TYPE.PURCHASE_DOCUMENTS_PRE_SUCCESS, props),
    purchaseDocumentsPreFailure: () => FactoryAction(TYPE.PURCHASE_DOCUMENTS_PRE_FAILURE),
    purchaseDocumentsPreviewRequest: (props) => FactoryAction(TYPE.PURCHASE_DOCUMENTS_PREVIEW_REQUEST, props),
    purchaseDocumentsPreviewSuccess: (props) => FactoryAction(TYPE.PURCHASE_DOCUMENTS_PREVIEW_SUCCESS, props),
    purchaseDocumentsPreviewFailure: (props) => FactoryAction(TYPE.PURCHASE_DOCUMENTS_PREVIEW_FAILURE, props),
    purchaseDocumentsSendRequest: (props) => FactoryAction(TYPE.PURCHASE_DOCUMENTS_SEND_FORM, props),
    purchaseDocumentsSendSuccess: (props) => FactoryAction(TYPE.PURCHASE_DOCUMENTS_SEND_FORM_SUCCESS, props),
    purchaseDocumentsSendFailure: (props) => FactoryAction(TYPE.PURCHASE_DOCUMENTS_SEND_FORM_FAILURE, props),
    /** ---------------------------------- */

    // Transfer credit letter
    transferCreditLetterPreRequest: () => FactoryAction(TYPE.TRANSFER_CREDIT_LETTER_PRE_REQUEST),
    transferCreditLetterPreSuccess: (props) => FactoryAction(TYPE.TRANSFER_CREDIT_LETTER_PRE_SUCCESS, props),
    transferCreditLetterPreFailure: () => FactoryAction(TYPE.TRANSFER_CREDIT_LETTER_PRE_FAILURE),
    transferCreditLetterPreviewRequest: (props) => FactoryAction(TYPE.TRANSFER_CREDIT_LETTER_PREVIEW_REQUEST, props),
    transferCreditLetterPreviewSuccess: (props) => FactoryAction(TYPE.TRANSFER_CREDIT_LETTER_PREVIEW_SUCCESS, props),
    transferCreditLetterPreviewFailure: () => FactoryAction(TYPE.TRANSFER_CREDIT_LETTER_PREVIEW_FAILURE),
    transferCreditLetterSendRequest: (props) => FactoryAction(TYPE.TRANSFER_CREDIT_LETTER_SEND_FORM, props),
    transferCreditLetterSendSuccess: (props) => FactoryAction(TYPE.TRANSFER_CREDIT_LETTER_SEND_FORM_SUCCESS, props),
    transferCreditLetterSendFailure: () => FactoryAction(TYPE.TRANSFER_CREDIT_LETTER_SEND_FORM_FAILURE),

    /** AUTHORIZATION DOCUMENTS DISCREPANCIES */
    authorizationDocumentsDiscrepanciesPreRequest: () =>
        FactoryAction(TYPE.AUTHORIZATION_DOCUMENTS_DISCREPANCIES_PRE_REQUEST),
    authorizationDocumentsDiscrepanciesPreSuccess: (props) =>
        FactoryAction(TYPE.AUTHORIZATION_DOCUMENTS_DISCREPANCIES_PRE_SUCCESS, props),
    authorizationDocumentsDiscrepanciesPreFailure: () =>
        FactoryAction(TYPE.AUTHORIZATION_DOCUMENTS_DISCREPANCIES_PRE_FAILURE),
    authorizationDocumentsDiscrepanciesPreviewRequest: (props) =>
        FactoryAction(TYPE.AUTHORIZATION_DOCUMENTS_DISCREPANCIES_PREVIEW_REQUEST, props),
    authorizationDocumentsDiscrepanciesPreviewSuccess: (props) =>
        FactoryAction(TYPE.AUTHORIZATION_DOCUMENTS_DISCREPANCIES_PREVIEW_SUCCESS, props),
    authorizationDocumentsDiscrepanciesPreviewFailure: () =>
        FactoryAction(TYPE.AUTHORIZATION_DOCUMENTS_DISCREPANCIES_PREVIEW_FAILURE),
    authorizationDocumentsDiscrepanciesSendRequest: (props) =>
        FactoryAction(TYPE.AUTHORIZATION_DOCUMENTS_DISCREPANCIES_SEND_REQUEST, props),
    authorizationDocumentsDiscrepanciesSendSuccess: (props) =>
        FactoryAction(TYPE.AUTHORIZATION_DOCUMENTS_DISCREPANCIES_SEND_SUCCESS, props),
    authorizationDocumentsDiscrepanciesSendFailure: () =>
        FactoryAction(TYPE.AUTHORIZATION_DOCUMENTS_DISCREPANCIES_SEND_FAILURE),

    // Cession credit letter
    cessionCreditLetterPreRequest: () => FactoryAction(TYPE.CESSION_CREDIT_LETTER_PRE_REQUEST),
    cessionCreditLetterPreSuccess: (props) => FactoryAction(TYPE.CESSION_CREDIT_LETTER_PRE_SUCCESS, props),
    cessionCreditLetterPreFailure: () => FactoryAction(TYPE.CESSION_CREDIT_LETTER_PRE_FAILURE),
    cessionCreditLetterPreviewRequest: (props) => FactoryAction(TYPE.CESSION_CREDIT_LETTER_PREVIEW_REQUEST, props),
    cessionCreditLetterPreviewFailure: (props) => FactoryAction(TYPE.CESSION_CREDIT_LETTER_PREVIEW_FAILURE, props),
    cessionCreditLetterPreviewSuccess: (props) => FactoryAction(TYPE.CESSION_CREDIT_LETTER_PREVIEW_SUCCESS, props),
    cessionCreditLetterSendRequest: (props) => FactoryAction(TYPE.CESSION_CREDIT_LETTER_SEND_REQUEST, props),
    cessionCreditLetterSendFailure: (props) => FactoryAction(TYPE.CESSION_CREDIT_LETTER_SEND_FAILURE, props),
    cessionCreditLetterSendSuccess: (props) => FactoryAction(TYPE.CESSION_CREDIT_LETTER_SEND_SUCCESS, props),
    comexFormSendDataFailure: () => FactoryAction(TYPE.COMEX_FORM_SEND_DATA_FAILURE),

    // Restart flow
    restartFlow: () => FactoryAction(TYPE.RESTART_FLOW),
};

export const SelectorsStore = {
    getFetching: (store) => store[NAME].fetching,
    getMode: (store) => store[NAME].mode,
    getPrevMode: (store) => store[NAME].prevMode,
    getTransaction: (store) => store[NAME].transaction,

    getPresentBillingDocumentsPre: (store) => store[NAME].presentBillingDocuments.preData,
    getPresentBillingDocumentsFormData: (store) => store[NAME].presentBillingDocuments.formData,
    getPresentBillingDocumentsIdForm: (store) => store[NAME].presentBillingDocuments.idForm,

    getComexFormData: (store) => store[NAME].comexForm.formData,
    getComexFormPre: (store) => store[NAME].comexForm.preData,
    getComexFormPrevData: (store) => store[NAME].comexForm.prevData,
    getcomexFormTicket: (store) => store[NAME].comexForm.formTicket,

    getBillingClaimPrevData: (store) => store[NAME].billingClaim.prevData,
    getBillingClaimFormData: (store) => store[NAME].billingClaim.formData,
    getBillingClaimPre: (store) => store[NAME].billingClaim.preData,

    getAuthorizationDocumentsDiscrepanciesPrevData: (store) => store[NAME].authorizationDocumentsDiscrepancies.prevData,
    getAuthorizationDocumentsDiscrepanciesFormData: (store) => store[NAME].authorizationDocumentsDiscrepancies.formData,
    getAuthorizationDocumentsDiscrepanciesPreData: (store) => store[NAME].authorizationDocumentsDiscrepancies.preData,

    getCessionCreditLetterPre: (store) => store[NAME].cessionCreditLetter.preData,
    getCessionCreditLetterFormData: (store) => store[NAME].cessionCreditLetter.formData,
};
