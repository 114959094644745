import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router";
import { compose as Compose } from "redux";

import { EMPTY_STR, ID_FIELD, ID_FORM } from "~/constants";
import { MODE } from "~/constants/form";
import {
    SelectorsAction as SelectorsActionBankSelector,
    SelectorsStore as SelectorsStoreBankSelector,
} from "~/store/bankSelector";
import { SelectorsStore as SelectorsStoreForm } from "~/store/form";
import UtilsDevice from "~/util/device";
import { isEmptyObj } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";
import * as StringUtils from "~/util/string";

import HighOrder from "~/components/HighOrder";
import Select from "~/components/Select";
import SelectorInput from "~/pages/_components/fields/SelectorInput";
import WithFocus from "~/pages/_components/withFocus";

import BankSearchOuterComponents from "~/pages/forms/_components/_fields/_bankselector/bankSearch/outerComponents";
import FormField from "~/pages/forms/_components/_fields/_commons/formField";

export const NAME = "Bankselector";

export const PROP = {
    types: {
        data: PropTypes.shape({
            codes: PropTypes.array.isRequired,
            countries: PropTypes.array.isRequired,
        }),
        defaultValue: PropTypes.string,
        editing: PropTypes.bool.isRequired,
        isFocused: PropTypes.bool.isRequired,
        maxLength: PropTypes.number,
        onBlur: PropTypes.func.isRequired,
        placeholder: PropTypes.string,
        setValue: PropTypes.func.isRequired,
        subType: PropTypes.string.isRequired,
        toggleIsFocused: PropTypes.func.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    },
    defaults: {
        data: {},
        defaultValue: EMPTY_STR,
        maxLength: 11,
        placeholder: EMPTY_STR,
        value: {},
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {
        actualType: EMPTY_STR,
        actualValue: EMPTY_STR,
        touched: false,
    };

    componentDidMount() {
        const { defaultValue, dispatch, idField, selectedBank, setValue, value } = this.props;

        if (!UtilsDevice.isMobileNative()) {
            window.addEventListener("keyup", this.checkPressedKey);
            window.addEventListener("mousedown", this.lostFocus);
            window.addEventListener("wheel", this.lostFocus);
        }

        if (defaultValue !== null && (!value || isEmptyObj(value))) {
            setValue({ type: defaultValue });
            this.setState({ actualType: defaultValue });
        } else if (selectedBank) {
            dispatch(SelectorsActionBankSelector.bankSelected({ bank: selectedBank, idField }));
        }
    }

    componentWillUnmount() {
        if (!UtilsDevice.isMobileNative()) {
            window.removeEventListener("keyup", this.checkPressedKey);
            window.removeEventListener("mousedown", this.lostFocus);
            window.removeEventListener("wheel", this.lostFocus);
        }
    }

    checkPressedKey = (event) => {
        // TAB or ENTER
        if (event.keyCode === 9 || event.keyCode === 13) {
            this.lostFocus();
        }
    };

    getBank = () => {
        const {
            dispatch,
            form: { setErrors, values },
            idField,
            onBlur,
            setValue,
            value,
        } = this.props;
        const { actualType, actualValue } = this.state;

        if (actualType !== EMPTY_STR && actualValue !== EMPTY_STR) {
            if (UtilsDevice.isMobileNative()) {
                // Busca de nuevo en caso de que el usuario haya hecho un cambio
                onBlur();
            }

            // trim swift code for the search
            setValue({ type: value.type, code: value.code?.trim() || EMPTY_STR });

            dispatch(
                SelectorsActionBankSelector.searchBankRequest({
                    //  Values expected by the service
                    filters: {
                        country: EMPTY_STR,
                        name: EMPTY_STR,
                        ...value,
                    },
                    idField,
                    setErrors,
                    setValue,
                    values,
                }),
            );
        }

        this.setState({
            actualType: value.type,
            actualValue: value.code,
        });
    };

    handleBlur = (event) => {
        if (event) {
            this.getBank();
        }
    };

    handleChange = (type, codeInput) => {
        const { dispatch, idField, maxLength, setValue, value } = this.props;
        const selectorType = type.value ? type.value : type;
        const code = codeInput ? codeInput.toUpperCase() : EMPTY_STR;

        if (code.length <= maxLength) {
            // UYHSBCCDP-2156 - En Android el atributo MaxLenth no esta funcionando en el caso que haya letras

            //  If type has changed, reset input code
            if (value.type !== selectorType) {
                dispatch(SelectorsActionBankSelector.resetSelectedBank({ idField }));
                setValue({ type: type.value, code: EMPTY_STR, bank: {} });
            } else {
                setValue({ type: type.value || type, code, bank: {} });
                this.setState({ actualType: type.value || type, actualValue: code, touched: true });
            }
        }
    };

    handleChangeDefault = (index) => {
        const { setValue } = this.props;

        setValue({ type: index.value, code: index.label });
    };

    lostFocus = () => {
        const { touched, ...restState } = this.state;

        if (touched) {
            this.getBank();

            this.setState({
                ...restState,
                touched: false,
            });
        }
    };

    render() {
        const {
            data,
            editing,
            form,
            idField,
            idForm,
            isFocused,
            maxLength,
            mode,
            placeholder,
            subType,
            toggleIsFocused,
            value,
        } = this.props;
        const { code = EMPTY_STR, type = EMPTY_STR } = value;
        const { codes } = data;
        const { setValues, values } = form;

        if (editing) {
            if (subType === "default") {
                return (
                    <div className="input-group">
                        <div style={{ flex: 1 }}>
                            <Select
                                className="flex-container slideFromBottom"
                                clearable={false}
                                isFocused={isFocused}
                                name="type"
                                onBlur={this.handleBlur}
                                onChange={(selectValue) => this.handleChangeDefault(selectValue)}
                                options={codes.slice(1, codes.length).map(({ id, label }) => ({
                                    label,
                                    value: id,
                                }))}
                                placeholder={placeholder}
                                searchable={false}
                                value={type}
                            />
                        </div>
                    </div>
                );
            }
            return (
                <SelectorInput
                    selectProps={{
                        name: "type",
                        options: codes.slice(1, codes.length).map(({ id, label }) => ({
                            value: id,
                            label: UtilsI18n.get(`forms.${label}`),
                        })),
                        value: type,
                    }}
                    inputMaxLength={maxLength}
                    inputProps={{ name: "code", value: code || EMPTY_STR, placeholder }}
                    isFocused={isFocused}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    toggleIsFocused={toggleIsFocused}
                />
            );
        }

        if (subType === "default") {
            return (
                <div className="data-wrapper">
                    <span className="data-name">{code}</span>
                </div>
            );
        }
        if (
            idForm === ID_FORM.TRANSFER_FOREIGN_FORM &&
            idField === ID_FIELD.INTERMEDIARY_BANK_CODE &&
            mode === MODE.VIEW
        ) {
            const valuesToSet = {
                ...values,
                [ID_FIELD.INTERMEDIARY_BANK_CODE]: {},
            };

            setValues(valuesToSet, false);
            return <span />;
        }
        if (
            idForm === ID_FORM.TRANSFER_FOREIGN_FORM &&
            idField === ID_FIELD.CREDIT_BANK_CODE &&
            mode === MODE.VIEW &&
            !StringUtils.isEmpty(values[ID_FIELD.CREDIT_BANK_LABEL])
        ) {
            const valuesToSet = {
                ...values,
                [idField]: {},
            };

            setValues(valuesToSet, false);
            return <span />;
        }

        return (
            <React.Fragment>
                <span>
                    {type} <span>{code}</span>
                </span>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    errorSelectingBank: SelectorsStoreBankSelector.getErrorSelectingBank(state, ownProps.idField),
    formMode: SelectorsStoreForm.getMode(state),
    selectedBank: SelectorsStoreBankSelector.getSelectedBank(state, ownProps.idField),
});

const hocClassName = (data) => {
    const { subType } = data;
    if (subType === "default") {
        return "form-group--composite";
    }
    return EMPTY_STR;
};

export default Compose(
    WithRouter,
    Connect(mapStateToProps),
    HighOrder.Resizable,
    WithFocus,
    BankSearchOuterComponents,
    FormField({
        formClass: hocClassName,
        isEmptyValue: ({ type, code }) => !code || !type,
    }),
)(Component);
