import React from "react";

import ClassNames from "classnames";
import { goBack as GoBack, routerActions } from "connected-react-router";
import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";
import * as Yup from "yup";

import {
    BOND_CLASSIFICATION,
    EMPTY_STR,
    ISIN_MAX_LENGTH_WM,
    PRICE_CLASSIFICATION,
    WM_OTHER_INVESTMENTS_TYPES,
} from "~/constants";
import { MODE } from "~/constants/form";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import {
    SelectorsStore as SelectorsStoreWMBonds,
    SelectorsAction as SelectorsActionWMBonds,
    PROP as PropWMBonds,
} from "~/store/wm/bonds";
import * as UtilsConfig from "~/util/config";
import { addDay as AddDay } from "~/util/date";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Select from "~/components/Select";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import DateField from "~/pages/_components/fields/DateField";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TextArea from "~/pages/_components/fields/TextArea";
import TextField from "~/pages/_components/fields/TextField";
import AmountField from "~/pages/_components/fields/formik/AmountField";

import { Termsandconditions } from "~/pages/forms/_components/_fields";
import Checkbox from "~/pages/forms/_components/_fields/_commons/Checkbox";
import NominalValue from "~/pages/wm/otherInvestments/bonds/_components/NominalValue";
import PaperBuy from "~/pages/wm/otherInvestments/bonds/_components/PaperBuy";

import Style from "./Step1Edit.rules.scss";

const FORM_ID = "wm.bonds.buy.send";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...PropWMBonds.types,
    },
    defaults: {
        ...PropWMBonds.defaults,
    },
};

const ISIN_FIELD_NAME = "isin";

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidUpdate() {
        const { debitAccountsList, values } = this.props;

        if (!values.idDebitAccount && debitAccountsList[0]) {
            this.handleChange(debitAccountsList[0].value);
        }
    }

    buildAmountOptions = (curr) => {
        return [
            {
                id: curr,
                label: UtilsI18n.get(`currency.label.${curr}`),
            },
        ];
    };

    buildProductOptions = (options) => {
        return options.map((elem) => {
            if (elem.balance && elem.balance.quantity !== 0) {
                return {
                    id: elem.value,
                    label: (
                        <React.Fragment>
                            <span className="control-label">{elem.label}</span>
                            <FormattedAmount quantity={elem.balance.quantity} currency={elem.balance.currency} />
                        </React.Fragment>
                    ),
                    alias: elem.label,
                };
            }

            return {
                id: elem.value,
                label: elem.productAlias,
                alias: elem.label,
            };
        });
    };

    customFilter = (option, searchText) => {
        if (
            option.alias.toLowerCase().includes(searchText.toLowerCase()) ||
            option.id.toLowerCase().includes(searchText.toLowerCase())
        ) {
            return true;
        }
        return false;
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(GoBack());
    };

    handleChange = (id) => {
        const { setFieldValue } = this.props;

        setFieldValue("idDebitAccount", id);
    };

    handleCheckChange = (fieldName) => {
        const { setFieldValue, values } = this.props;

        setFieldValue(fieldName, !values[fieldName]);
    };

    handleClick = () => {
        const { dispatch, values } = this.props;
        const { isin } = values;

        const route = isin ? `/wm/bonds/buy/bondSearch/${isin}` : "/wm/bonds/buy/bondSearch";

        dispatch(SelectorsActionWMBonds.clearSearchResults());
        dispatch(routerActions.push(route));
    };

    handleFieldKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            this.handleSearch();
        }
    };

    handleFormKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            event.preventDefault();
        }
    };

    handleSearch = () => {
        const {
            dispatch,
            setErrors,
            setFieldError,
            setFieldTouched,
            setSubmitting,
            values: { isin },
        } = this.props;

        if (isin && isin.length === 12) {
            dispatch(
                SelectorsActionWMBonds.searchByIsin({
                    formData: {
                        classification: BOND_CLASSIFICATION.indistinct,
                        isin,
                        type: WM_OTHER_INVESTMENTS_TYPES.BONOS,
                    },
                    formikBag: {
                        setErrors,
                        setSubmitting,
                        shouldCallPre: true,
                        shouldDisplayIsinError: true,
                    },
                }),
            );
        } else {
            /*
            setFieldTouched allows TextField to show error properly because of the code on TextField.jsx around line 167
            const hasError = touched[field.name] && errors[field.name]
            */
            setFieldTouched(ISIN_FIELD_NAME, true, false);
            setFieldError(ISIN_FIELD_NAME, UtilsI18n.get("wm.bonds.buy.search.results.field.length"), false);

            dispatch(SelectorsActionWMBonds.clearForm());
        }
    };

    render() {
        const { activeEnvironment, debitAccountsList, errors, paper, timezoneOffset, touched, values } = this.props;
        const {
            currency,
            description,
            detailLink,
            detailType,
            expirationDate,
            idPaper,
            minimunPurchase,
            productTypeOrigin,
            purchaseMultiple,
            rate,
            riskLevel: paperRisk,
        } = paper;

        const userRisk = activeEnvironment && activeEnvironment.riskProfileCode;
        const newOptions = this.buildProductOptions(debitAccountsList);
        const price = PRICE_CLASSIFICATION.MARKET;
        const productId = (values && values.idDebitAccount) || EMPTY_STR;
        const termsAndConditions = "client.wm.bonds.buy.disclaimer";
        const showAcceptOptionText = UtilsI18n.get("client.wm.bonds.finish.conditions");

        const i18nMap = {
            showAcceptOptionText,
            termsAndConditions,
        };

        const hasErrorDebitAccount = touched.idDebitAccount && errors.idDebitAccount && !values.idDebitAccount;

        const data = {
            options: [
                {
                    id: currency,
                    label: UtilsI18n.get(`currency.label.${currency}`),
                },
            ],
        };

        const otherFields = {
            currency,
            description,
            detailLink,
            detailType,
            expirationDate,
            idPaper,
            productTypeOrigin,
            rate,
        };

        const maxDaysToShow = UtilsConfig.getInteger("client.wm.allInvestments.orderValid.maxDaysToShow");
        const minDate = new Date();
        const maxDate = AddDay(minDate, maxDaysToShow);

        return (
            <Form
                className="col col-12 col-lg-6 col-md-9 col-sm-12"
                id={Style.ID}
                noValidate="novalidate"
                onKeyDown={this.handleFormKeyDown}>
                <div>
                    <I18n id="client.wm.otherInvestments.subTitle" />
                    <section className="fields container--layout align-items-center flex-grow">
                        <hr />
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.buyData`)}</h3>
                        </Box>
                        {/* ISIN */}
                        <Box flex align="end" className="search-field">
                            <Field
                                idForm={FORM_ID}
                                name={ISIN_FIELD_NAME}
                                hidePlaceholder
                                type="text"
                                component={TextField}
                                maxLength={ISIN_MAX_LENGTH_WM}
                                tooltip={UtilsI18n.get("client.wm.bonds.buy.isin.tooltip")}
                                onKeyDown={(event) => this.handleFieldKeyDown(event)}
                            />
                            <Box className={ClassNames("ml-3", { "mb-2": !errors.isin }, { "mb-6": errors.isin })}>
                                <Button onClick={this.handleSearch} variant="primary">
                                    {UtilsI18n.get("global.search")}
                                </Button>
                            </Box>
                        </Box>
                        <Box className="form-group">
                            <Button variant="link-underline" onClick={this.handleClick}>
                                {UtilsI18n.get("client.wm.bonds.buy.isin.search")}
                            </Button>
                        </Box>
                        {paperRisk > userRisk && (
                            <Box flex align="start" className="form-group pt-3 alert">
                                <Image src="alert.svg" />
                                <I18n id="client.wm.bonds.buy.notificationPaperRisk" className="ml-2" />
                            </Box>
                        )}
                        {currency && <PaperBuy othersFields={otherFields} />}
                        {/* Nominal value */}
                        <Field
                            clearable={false}
                            component={AmountField}
                            data={data}
                            decimalPlaces={2}
                            fixedDecimalScale
                            hideCurrency={!currency}
                            id="nominalValue"
                            idForm={FORM_ID}
                            maxLength={UtilsConfig.getInteger("amount.length")}
                            name="nominalValue"
                            placeholder={EMPTY_STR}
                            searchable={false}
                            value={currency}
                        />
                        {purchaseMultiple && minimunPurchase && (
                            <NominalValue minimum={minimunPurchase} multiple={purchaseMultiple} />
                        )}
                        {/* Price */}
                        <Field
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            mode="VIEW"
                            name="price"
                            type="text"
                            value={UtilsI18n.get(`client.wm.bonds.buy.price.${price}`)}
                        />
                        {/* Order valid until */}
                        <div className="form-group">
                            <FieldLabel
                                labelKey="wm.allInvestments.orderValidUntil.label"
                                classContainer={`${
                                    errors.orderCompleted && touched.orderCompleted ? "has-error" : EMPTY_STR
                                }`}
                            />
                            <Box className="label-checkbox">
                                <Field
                                    name="orderCompleted"
                                    label={UtilsI18n.get(`${FORM_ID}.orderCompleted.label`)}
                                    checked={values.orderCompleted}
                                    component={Checkbox}
                                    onChange={() => this.handleCheckChange("orderCompleted")}
                                />
                            </Box>
                            {errors.orderCompleted && touched.orderCompleted && (
                                <FieldError error={errors.orderCompleted} />
                            )}
                        </div>

                        {!values.orderCompleted && (
                            <React.Fragment>
                                <Field
                                    autoComplete="off"
                                    component={DateField}
                                    id="limitDate"
                                    idForm={FORM_ID}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    name="limitDate"
                                    timezoneOffset={timezoneOffset}
                                />
                                <Box className="form-group">
                                    <div id="disclaimer-text">
                                        {UtilsI18n.get("wm.allInvestments.afterValidDate.disclaimer")}
                                    </div>
                                </Box>
                            </React.Fragment>
                        )}
                        {/* Observations */}
                        <Field
                            className="form-control"
                            component={TextArea}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={650}
                            mode={MODE.EDIT}
                            name="observations"
                            optional={UtilsI18n.get("form.field.optional")}
                        />
                        {/* Debit account */}
                        <Box className={`form-group ${hasErrorDebitAccount ? "has-error" : EMPTY_STR}`}>
                            <FieldLabel labelKey={`${FORM_ID}.debitAccount.label`} />
                            <Box className="input-group">
                                <Field
                                    className="flex-container slideFromBottom"
                                    clearable={false}
                                    component={Select}
                                    filterOption={this.customFilter}
                                    idForm={FORM_ID}
                                    labelKey="label"
                                    name="idDebitAccount"
                                    onChange={(item) => {
                                        if (item) {
                                            this.handleChange(item.id);
                                        }
                                    }}
                                    optionClassName="needsclick"
                                    options={newOptions}
                                    placeholder={EMPTY_STR}
                                    ref={this.selectorRef}
                                    value={productId}
                                    valueKey="id"
                                />
                            </Box>
                            {hasErrorDebitAccount && (
                                <Box>
                                    <FieldError error={errors.idDebitAccount} />
                                </Box>
                            )}
                        </Box>
                        {/* Disclaimer */}
                        <Box className="form-group label-checkbox">
                            <Field
                                className="form-control"
                                component={Termsandconditions}
                                i18nMap={i18nMap}
                                idForm={FORM_ID}
                                name="disclaimer"
                                mode={MODE.EDIT}
                                showAcceptOption
                            />
                        </Box>
                    </section>
                    {/* Submit */}
                    <Box flex justify="center" className="mt-3">
                        <Button type="submit" variant="primary">
                            {UtilsI18n.get("global.sendInstruction")}
                        </Button>
                    </Box>
                </div>
            </Form>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
        debitAccountsList: SelectorsStoreWMBonds.getDebitAccountsList(store),
        idPaper: SelectorsStoreWMBonds.getIdPaper(store),
        paper: SelectorsStoreWMBonds.getPaper(store),
        papersList: SelectorsStoreWMBonds.getPapersList(store),
        previewData: SelectorsStoreWMBonds.getPreviewData(store),
        timezoneOffset: SelectorsStoreWMBonds.getTimezoneOffset(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: () =>
            Yup.lazy((props) =>
                Yup.object().shape({
                    disclaimer: Yup.boolean().oneOf([true], UtilsI18n.get("client.wm.bonds.buy.disclaimerRequired")),
                    idDebitAccount: Yup.string().required(UtilsI18n.get("client.wm.bonds.buy.emptyDebitAccount")),
                    isin: Yup.string().required(UtilsI18n.get(`${FORM_ID}.isin.required`)),
                    limitDate: props.orderCompleted
                        ? Yup.string().notRequired()
                        : Yup.string().trim().required(UtilsI18n.get("wm.allInvestments.limitDate.required")),
                    nominalValue: Yup.object().shape({
                        amount: Yup.string().required(UtilsI18n.get(`${FORM_ID}.nominalValue.required`)),
                    }),
                }),
            ),
        mapPropsToValues: (props) => {
            const { paper, previewData } = props;

            return {
                disclaimer: previewData.disclaimer || false,
                idDebitAccount: previewData.idDebitAccount || EMPTY_STR,
                isin: previewData.isin || (paper && paper.isin) || EMPTY_STR,
                limitDate: previewData.limitDate || EMPTY_STR,
                nominalValue: { amount: previewData.nominalValue } || EMPTY_STR,
                observations: previewData.observations || EMPTY_STR,
                orderCompleted: previewData?.orderCompleted !== false,
                price: PRICE_CLASSIFICATION.MARKET,
            };
        },
        handleSubmit: (props, formikBag) => {
            const { disclaimer, idDebitAccount, limitDate, nominalValue, observations, orderCompleted, price } = props;
            const { dispatch, paper } = formikBag.props;
            const { currency, description, expirationDate, idPaper, isin, rate } = paper;

            dispatch(
                SelectorsActionWMBonds.buyBondsPreview({
                    previewData: {
                        currency,
                        disclaimer,
                        expirationDate,
                        idDebitAccount,
                        idPaper,
                        isin,
                        limitDate: orderCompleted ? EMPTY_STR : limitDate,
                        nominalValue: nominalValue ? nominalValue.amount : EMPTY_STR,
                        observations,
                        orderCompleted,
                        paperName: description,
                        price,
                        rate,
                    },
                    formikBag,
                }),
            );
        },
    }),
)(Component);
