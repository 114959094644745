import React from "react";

import PropTypes from "prop-types";

import PropHtml from "~/util/prop/html-element";

import Style from "./PageLoading.scss";

export const NAME = "PageLoading";

export const PROP = {
    types: {
        loading: PropTypes.bool,
        children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
        ...PropHtml.types,
    },
    defaults: {
        loading: true,
        children: null,
        ...PropHtml.defaults,
    },
};

export function Component(props) {
    const { loading, children, id, className } = props;
    return (
        <React.Fragment>
            {loading && (
                <div id={id} className={`preloader form-load ${className}`}>
                    <div className="loader" id={Style.ID_WAIT} />
                </div>
            )}
            {children}
        </React.Fragment>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
