import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SelectorsAction as SelectorsActionPreferentialTradingPrice } from "~/store/preferentialTradingPrice";
import * as UtilDate from "~/util/date";

import Button from "~/components/Button/Button";

import Style from "./_BulletinList.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {
        list: PropTypes.array,
    },
    defaults: {},
};

export function Component(props) {
    const { list, dispatch } = props;
    return (
        <div className={CLASS}>
            {list.map(({ date, name, fileName }) => (
                <React.Fragment>
                    <div>
                        <Button onClick={() => dowloadPDF(fileName)}>
                            {UtilDate.i18nDate(date, "dd MMMM yyyy")} - {name}
                        </Button>
                    </div>
                </React.Fragment>
            ))}
        </div>
    );

    function dowloadPDF(fileName) {
        dispatch(SelectorsActionPreferentialTradingPrice.bulletinPdfDownloadRequest({ fileName }));
    }
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Connect()(Component);
