import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, NO_DATA } from "~/constants";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import { SelectorsStore as SelectorsStoreComexExport } from "~/store/comex/export";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Ticket from "~/components/Ticket/Ticket";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const { transaction, ticketConfirmation, dispatch } = props;
    const { data } = transaction;

    const {
        creditLetterNumber,
        accountNumber,
        issuingBank,
        interestRate,
        transferAmountInWords,
        amountInWords,
        currency,
        operationNumber,
        subOperationNumber,
        amountString,
        transferAmountString,
    } = data;
    const shareTicket = (idTransactionToRead) => {
        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    };
    const buildAmount = (amountBuild) => `${UtilsI18n.get(`currency.label.${currency}`)} ${amountBuild} `;
    function handleReject(formikBag) {
        const { idTransaction } = transaction;
        dispatch(
            SelectorsActionComex.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        dispatch(SelectorsActionComex.signTransactionPreview({ data }));
    }
    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}
                    hasMobileComponent
                    displayDownloadPDF
                    handleDownloadPDF={() => shareTicket(transaction.idTransaction)}>
                    <Box flex directionColumn align="flex-start" className="mb-3">
                        <p
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: UtilsI18n.get("comex.export.creditLetterPurchase.letter.notCeded", null, {
                                    FECHA: EMPTY_STR,
                                    NRO_SUB_OPERACION: subOperationNumber ? `${subOperationNumber}.` : NO_DATA,
                                    NRO_DE_CUENTA: accountNumber ?? EMPTY_STR,
                                    NRO_OPERACION: operationNumber ?? NO_DATA,
                                    NRO_CARTA_CREDITO: creditLetterNumber ?? EMPTY_STR,
                                    BANCO_EMISOR: issuingBank ?? EMPTY_STR,
                                    BENEFICIARIO: accountNumber ?? EMPTY_STR,
                                    IMPORTE: transferAmountString ? buildAmount(transferAmountString) : NO_DATA,
                                    IMPORTE_LETRA: transferAmountInWords ? `( ${transferAmountInWords} )` : EMPTY_STR,
                                    TASA: interestRate ?? EMPTY_STR,
                                    SALDO_CONTABLE: amountString ? buildAmount(amountString) : NO_DATA,
                                    SALDO_CONTABLE_LETRA: amountInWords ? `( ${amountInWords} ) ` : EMPTY_STR,
                                }),
                            }}
                        />
                    </Box>
                </Ticket>
            )}
        </section>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreComexExport.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
