import Debug from "debug";

import { NODE_ENV, NODE_ENV_PRODUCTION } from "~/constants";

const BASE = "CDP";

export const LEVEL = {
    trace: { name: "TRACE", color: "gray" },
    info: { name: "INFO", color: "lightgreen" },
    warn: { name: "WARN", color: "orange" },
    error: { name: "ERROR", color: "red" },
};

if (NODE_ENV !== NODE_ENV_PRODUCTION) {
    localStorage.setItem("debug", `${BASE}:*`);
}

export function Logger({ name, color }, ...args) {
    // Set the prefix which will cause debug to enable the message
    const namespace = `${BASE}:${name}`;
    const debug = Debug(namespace);
    debug.color = color;
    if (NODE_ENV !== NODE_ENV_PRODUCTION && name === LEVEL.error.name) {
        // eslint-disable-next-line no-console
        console.error(namespace, ...args);
    } else {
        debug(...args);
    }
}

export default Object.entries(LEVEL).reduce((acc, [key, val]) => ({ ...acc, [key]: Logger.bind(Logger, val) }), {});
