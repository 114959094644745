import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import * as i18n from "~/util/i18n";

import I18n from "~/components/I18n";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TagsInput from "~/pages/_components/fields/TagsInput";

import Style from "./EmailList.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        field: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired,
        idForm: PropTypes.string.isRequired,
        isRequired: PropTypes.bool,
        renderSuggestion: PropTypes.bool,
        placeholder: PropTypes.string,
        hideLabel: PropTypes.bool,
        data: PropTypes.shape({
            emailValidationRegex: PropTypes.string,
            addMessage: PropTypes.string,
        }).isRequired,
        hideSwiftTip: PropTypes.bool,
        limit: PropTypes.number,
    },
    defaults: {
        isRequired: false,
        renderSuggestion: true,
        hideLabel: false,
        placeholder: null,
        hideSwiftTip: false,
        limit: -1,
    },
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        mails: [],
    };

    static getDerivedStateFromProps(nextProps) {
        const { value } = nextProps;
        // para soportar carga de borradores/templates en form estaticos
        if (value && value.length > 0) {
            return {
                mails: value,
            };
        }
        return {
            mails: [],
        };
    }

    shouldRenderSuggestions = (value) => {
        const { data, renderSuggestion } = this.props;
        return value && renderSuggestion && new RegExp(data.emailValidationRegex).test(value);
    };

    renderSuggestion = (suggestion) => {
        const { data } = this.props;
        return <span>{`${data.addMessage} ${suggestion}`}</span>;
    };

    handleValidationReject = ([newValue]) => {
        const { value, isRequired, field, form, idForm } = this.props;
        let currentError = null;
        if (value && value.length === 0 && isRequired) {
            currentError = i18n.get(`${idForm}.${field.name}.requiredError`);
        } else if (newValue !== EMPTY_STR) {
            currentError = i18n.get(`${idForm}.${field.name}.invalidError`);
        }
        form.setFieldError(field.name, currentError);
        form.setFieldValue("currentError", currentError);
        form.setFieldTouched(field.name);
    };

    getSuggestions = (inputValue) => [inputValue];

    isTextValid = (newText) => {
        const { emailIsTextValid } = this.props;
        return new RegExp(emailIsTextValid).test(newText);
    };

    handleChange = (mails) => {
        const { field, form, handleChange } = this.props;

        if (handleChange) {
            handleChange(mails);
        }
        form.setFieldError(field.name, null);

        this.setState({ mails });

        form.setFieldValue(field.name, mails);
    };

    render() {
        const {
            field,
            data: { emailValidationRegex },
            hideLabel,
            form: { touched, errors },
            placeholder,
            className,
            idForm,
            tooltip,
            optional,
            hideSwiftTip,
            limit,
            maxLength,
        } = this.props;

        const { mails } = this.state;

        const hasError = touched && touched[field.name] && errors && errors[field.name];

        return (
            <div className={classnames({ "has-error": hasError }, `${Style.CLASS}`, className)}>
                {!hideLabel && <FieldLabel optional={optional} labelKey={`${idForm}.${field.name}.label`} />}

                {!hideSwiftTip && (
                    <div className="help-text">
                        <I18n id="client.swift.sendMail.modal.tip" />
                    </div>
                )}
                <TagsInput
                    value={mails || []}
                    onChange={this.handleChange}
                    validationRegex={new RegExp(emailValidationRegex)}
                    onValidationReject={this.handleValidationReject}
                    shouldRenderSuggestions={this.shouldRenderSuggestions}
                    renderSuggestion={this.renderSuggestion}
                    getSuggestions={this.getSuggestions}
                    isTextValid={this.isTextValid}
                    placeholder={mails && mails.length ? EMPTY_STR : placeholder}
                    maxTags={limit}
                    maxLength={maxLength}
                />
                {tooltip && (
                    <div className="tooltip">
                        <span className="tooltip-text"> {tooltip}</span>
                    </div>
                )}

                {hasError && <FieldError error={errors[field.name]} />}
            </div>
        );
    }
}

const mapStateToProps = (store) => ({
    emailIsTextValid: SelectorsStoreConfig.getConfig(store)["email.isTextValid"],
});

export default Connect(mapStateToProps)(Component);
