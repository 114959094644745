import React from "react";

import { push as Push } from "connected-react-router";
import { Field, Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import { CREDENTIAL_TYPE_PASSWORD, EMPTY_STR } from "~/constants";
import Container from "~/containers/External/Form";
import {
    SelectorsAction as SelectorsActionRecoverPassword,
    SelectorsStore as SelectorsStoreRecoverPassword,
} from "~/store/recoverypassword";
import * as ConfigUtils from "~/util/config";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Credential from "~/pages/_components/fields/credentials/Credential";

import Style from "./RecoveryPass.scss";

export const NAME = "RecoveryPassStep3";
const { CLASS } = Style;

const FORM_ID = "recoveryPassword.step3";

export const PROP = {
    types: {
        isFetching: PropTypes.bool,
    },
    defaults: {
        isFetching: false,
    },
};

export function Component(props) {
    const { dispatch, isFetching } = props;

    const onHeaderClose = () => {
        dispatch(Push("/"));
    };

    return (
        <Container
            icon={<Image src="administration-title-icon.svg" />}
            id={NAME}
            head-title={`${FORM_ID}.pageTitle`}
            name={NAME}
            head-onClose={onHeaderClose}
            className={CLASS}
            wait={isFetching}>
            <Form>
                <Field
                    component={Credential}
                    idForm={FORM_ID}
                    name="password"
                    placeholder={EMPTY_STR}
                    showStrength
                    type={CREDENTIAL_TYPE_PASSWORD}
                />
                <Field
                    component={Credential}
                    idForm={FORM_ID}
                    name="passwordConfirmation"
                    placeholder={EMPTY_STR}
                    type={CREDENTIAL_TYPE_PASSWORD}
                />
                <section>
                    <p className="help">
                        <I18n id="global.passwordRules.rule1" MIN_LENGTH={ConfigUtils.get("core.password.minLength")} />
                    </p>
                    <p className="help">
                        <I18n id="global.passwordRules.rule2" />
                    </p>
                    <p className="help">
                        <I18n id="global.passwordRules.rule3" />
                    </p>
                </section>
                <div>
                    <Button className="primary" type="submit">
                        {UtilsI18n.get("global.save")}
                    </Button>
                </div>
            </Form>
        </Container>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    isFetching: SelectorsStoreRecoverPassword.isFetching(store),
});

export default Compose(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            password: EMPTY_STR,
            passwordConfirmation: EMPTY_STR,
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    password: Yup.string()
                        .required(UtilsI18n.get("global.newPassword.required"))
                        .min(
                            ConfigUtils.get("core.password.minLength"),
                            UtilsI18n.get("global.password.minLengthTooShort"),
                        ),
                    passwordConfirmation: Yup.string()
                        .required(UtilsI18n.get("global.newPasswordConfirmation.required"))
                        .oneOf([values.password], UtilsI18n.get("global.newPasswordConfirmation.notEquals")),
                }),
            ),
        handleSubmit: (data, formikBag) => {
            const { dispatch } = formikBag.props;
            const { password, passwordConfirmation } = data;

            dispatch(
                SelectorsActionRecoverPassword.recoverPassStep3Request({ formikBag, password, passwordConfirmation }),
            );
        },
    }),
)(Component);
