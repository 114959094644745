import React from "react";

import ClassNames from "classnames";
import { Field, Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import * as Yup from "yup";

import { EMPTY_STR, ISIN_MAX_LENGTH_WM, PRICE_CLASSIFICATION, SHARE_CLASSIFICATION } from "~/constants";
import { MODE } from "~/constants/form";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import {
    SelectorsStore as SelectorsStoreWMShares,
    SelectorsAction as SelectorsActionWMShares,
} from "~/store/wm/shares";
import * as UtilsConfig from "~/util/config";
import { addDay as AddDay } from "~/util/date";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import RadioOption from "~/components/RadioOption";
import Select from "~/components/Select";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import DateField from "~/pages/_components/fields/DateField";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TextArea from "~/pages/_components/fields/TextArea";
import TextField from "~/pages/_components/fields/TextField";
import AmountField from "~/pages/_components/fields/formik/AmountField";

import { Termsandconditions } from "~/pages/forms/_components/_fields";
import Checkbox from "~/pages/forms/_components/_fields/_commons/Checkbox";
import PaperBuy from "~/pages/wm/otherInvestments/shares/_components/PaperBuy";
import TickerField from "~/pages/wm/otherInvestments/shares/_components/TickerField";

import Style from "./Step1Edit.rules.scss";

export const { ID, NAME } = Style;

export const PROP = {
    types: {
        isFetching: PropTypes.bool,
    },
    defaults: {
        isFetching: false,
    },
};

const FORM_ID = "client.wm.shares.buy.send";

const ISIN_FIELD_NAME = "isin";

export function Component(props) {
    const {
        activeEnvironment,
        debitAccountsList,
        dispatch,
        errors,
        paper,
        resetForm,
        setFieldValue,
        timezoneOffset,
        touched,
        values,
    } = props;
    const { currency, riskLevel } = paper;

    const i18nMap = {
        termsAndConditions: "client.wm.shares.buy.disclaimer",
        showAcceptOptionText: UtilsI18n.get("client.wm.shares.finish.conditions"),
    };

    const hasErrorDebitAccount = touched.idDebitAccount && errors.idDebitAccount && !values.idDebitAccount;

    const data = {
        options: [
            {
                id: currency,
                label: UtilsI18n.get(`currency.label.${currency}`),
            },
        ],
    };

    const buildProductOptions =
        debitAccountsList &&
        debitAccountsList.map((elem) => {
            if (elem.balance && elem.balance.quantity !== 0) {
                return {
                    id: elem.value,
                    label: (
                        <React.Fragment>
                            <span className="control-label">{elem.label}</span>
                            <FormattedAmount quantity={elem.balance.quantity} currency={elem.balance.currency} />
                        </React.Fragment>
                    ),
                };
            }

            return {
                id: elem.value,
                label: elem.productAlias,
            };
        });

    const handleSearch = () => {
        const {
            setErrors,
            setTouched,
            values: {
                amountToInvest,
                classification,
                disclaimer,
                idDebitAccount,
                isin,
                limitTo,
                observations,
                price,
                ticker,
            },
        } = props;

        dispatch(
            SelectorsActionWMShares.searchSharesRequest({
                previewData: {
                    classification,
                    ...(classification === SHARE_CLASSIFICATION.PREFERRED && { isin }),
                    ...(classification === SHARE_CLASSIFICATION.ORDINARY && {
                        amountToInvest: amountToInvest.amount,
                        disclaimer,
                        idDebitAccount,
                        initialPaper: paper || {},
                        initialTicker1: ticker.ticker1,
                        initialTicker2: ticker.ticker2,
                        limitTo,
                        observations,
                        price,
                        ticker1: ticker.ticker1,
                        ticker2: ticker.ticker2,
                    }),
                },
                formikBag: {
                    setErrors,
                    setTouched,
                    shouldCallPre: true,
                    shouldDisplayIsinError: true,
                    shouldRedirectToSearchForm: true,
                },
            }),
        );
    };

    const handleCheckChange = (fieldName) => {
        setFieldValue(fieldName, !values[fieldName]);
    };

    const handleClassificationChange = (value) => {
        if (Object.entries(paper).length) {
            dispatch(
                SelectorsActionWMShares.clearOnClassificationChange({
                    previewData: {
                        ...values,
                        classification: value,
                        amountToInvest: values.amountToInvest.amount,
                    },
                }),
            );
        } else {
            resetForm({
                ...values,
                classification: value,
                idDebitAccount: EMPTY_STR,
                isin: EMPTY_STR,
                ticker: { ticker1: EMPTY_STR, ticker2: EMPTY_STR },
            });
        }
    };

    const handleFormKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            event.preventDefault();
        }
    };

    const handleFieldKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            handleSearch();
        }
    };

    const maxDaysToShow = UtilsConfig.getInteger("client.wm.allInvestments.orderValid.maxDaysToShow");
    const minDate = new Date();
    const maxDate = AddDay(minDate, maxDaysToShow);

    return (
        <Form id={ID} noValidate="novalidate" onKeyDown={handleFormKeyDown}>
            <div>
                <I18n id="client.wm.otherInvestments.subTitle" />
                <section className="fields container--layout align-items-center flex-grow">
                    <hr />
                    <Box className="form-section-title">
                        <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.buyData`)}</h3>
                    </Box>
                    <Box>
                        {/* Classification */}
                        <Box className="form-group">
                            <Box className="mt-5">
                                <FieldLabel labelKey={`${FORM_ID}.classification.label`} />
                                <Box flex>
                                    <Field name="classification">
                                        {({ field: { value } }) => (
                                            <React.Fragment>
                                                <RadioOption
                                                    checked={value === SHARE_CLASSIFICATION.ORDINARY}
                                                    formId={FORM_ID}
                                                    group="classification"
                                                    mode={MODE.EDIT}
                                                    onChange={handleClassificationChange}
                                                    value={SHARE_CLASSIFICATION.ORDINARY}
                                                />
                                                <RadioOption
                                                    checked={value === SHARE_CLASSIFICATION.PREFERRED}
                                                    className="ml-3"
                                                    formId={FORM_ID}
                                                    group="classification"
                                                    mode={MODE.EDIT}
                                                    onChange={handleClassificationChange}
                                                    value={SHARE_CLASSIFICATION.PREFERRED}
                                                />
                                            </React.Fragment>
                                        )}
                                    </Field>
                                </Box>
                            </Box>
                        </Box>
                        {/* Ticker/ISIN */}
                        <Box flex align="end" className="search-field">
                            {values.classification === SHARE_CLASSIFICATION.ORDINARY ? (
                                <Field
                                    autoFocus={false}
                                    component={TickerField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="ticker"
                                    onKeyDown={(event) => handleFieldKeyDown(event)}
                                    tooltip={UtilsI18n.get("client.wm.shares.buy.ticker.tooltip")}
                                    type="text"
                                />
                            ) : (
                                <Field
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    maxLength={ISIN_MAX_LENGTH_WM}
                                    name={ISIN_FIELD_NAME}
                                    onKeyDown={(event) => handleFieldKeyDown(event)}
                                    tooltip={UtilsI18n.get("client.wm.shares.buy.isin.tooltip")}
                                    type="text"
                                />
                            )}
                            <Box className={ClassNames("ml-3", { "mb-2": !errors.isin }, { "mb-6": errors.isin })}>
                                <Button onClick={handleSearch} variant="primary">
                                    {UtilsI18n.get("global.search")}
                                </Button>
                            </Box>
                        </Box>
                        {/* Risk level */}
                        {riskLevel &&
                            activeEnvironment.riskProfileCode &&
                            riskLevel > activeEnvironment.riskProfileCode && (
                                <Box flex align="start" className="form-group pt-3 alert">
                                    <Image src="alert.svg" />
                                    <I18n id="client.wm.shares.buy.notificationPaperRisk" className="ml-2" />
                                </Box>
                            )}
                        {/* Paper details */}
                        {currency && <PaperBuy paper={paper} />}
                        {/* Amount to invest */}
                        <Field
                            clearable={false}
                            component={AmountField}
                            data={data}
                            decimalPlaces={2}
                            fixedDecimalScale
                            hideCurrency={!currency}
                            id="amountToInvest"
                            idForm={FORM_ID}
                            maxLength={UtilsConfig.getInteger("amount.length")}
                            name="amountToInvest"
                            placeholder={EMPTY_STR}
                            searchable={false}
                        />
                        {/* Price */}
                        <Box className="form-group">
                            <Box className="mt-5">
                                <FieldLabel labelKey={`${FORM_ID}.price.label`} />
                                <Box flex>
                                    <Field name="price">
                                        {({ field: { value } }) => (
                                            <React.Fragment>
                                                <RadioOption
                                                    checked={value === PRICE_CLASSIFICATION.MARKET}
                                                    formId={FORM_ID}
                                                    group="price"
                                                    mode={MODE.EDIT}
                                                    onChange={(val) => setFieldValue("price", val)}
                                                    value={PRICE_CLASSIFICATION.MARKET}
                                                />
                                                <RadioOption
                                                    checked={value === PRICE_CLASSIFICATION.LIMIT}
                                                    className="ml-3"
                                                    formId={FORM_ID}
                                                    group="price"
                                                    mode={MODE.EDIT}
                                                    onChange={(val) => setFieldValue("price", val)}
                                                    value={PRICE_CLASSIFICATION.LIMIT}
                                                />
                                            </React.Fragment>
                                        )}
                                    </Field>
                                </Box>
                            </Box>
                        </Box>
                        {/* Limit to */}
                        {values.price === PRICE_CLASSIFICATION.LIMIT && (
                            <Field
                                clearable={false}
                                component={AmountField}
                                data={data}
                                decimalPlaces={2}
                                fixedDecimalScale
                                hideCurrency={!currency}
                                id="limitTo"
                                idForm={FORM_ID}
                                maxLength={UtilsConfig.getInteger("amount.length")}
                                name="limitTo"
                                placeholder={EMPTY_STR}
                                searchable={false}
                            />
                        )}
                        {/* Order valid until */}
                        <div className="form-group">
                            <FieldLabel
                                labelKey="wm.allInvestments.orderValidUntil.label"
                                classContainer={`${
                                    errors.orderCompleted && touched.orderCompleted ? "has-error" : EMPTY_STR
                                }`}
                            />
                            <Box className="label-checkbox">
                                <Field
                                    name="orderCompleted"
                                    label={UtilsI18n.get(`${FORM_ID}.orderCompleted.label`)}
                                    checked={values.orderCompleted}
                                    component={Checkbox}
                                    onChange={() => handleCheckChange("orderCompleted")}
                                />
                            </Box>
                            {errors.orderCompleted && touched.orderCompleted && (
                                <FieldError error={errors.orderCompleted} />
                            )}
                        </div>
                        {!values.orderCompleted && (
                            <React.Fragment>
                                <Field
                                    autoComplete="off"
                                    component={DateField}
                                    id="limitDate"
                                    idForm={FORM_ID}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    name="limitDate"
                                    timezoneOffset={timezoneOffset}
                                />
                                <Box className="form-group">
                                    <div id="disclaimer-text">
                                        {UtilsI18n.get("wm.allInvestments.afterValidDate.disclaimer")}
                                    </div>
                                </Box>
                            </React.Fragment>
                        )}
                        {/* Observations */}
                        <Field
                            className="form-control"
                            component={TextArea}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={650}
                            mode={MODE.EDIT}
                            name="observations"
                            optional={UtilsI18n.get("form.field.optional")}
                        />
                        {/* Debit account */}
                        <Box className={`form-group select-field ${hasErrorDebitAccount ? "has-error" : EMPTY_STR}`}>
                            <FieldLabel labelKey={`${FORM_ID}.debitAccount.label`} />
                            <Box className="input-group">
                                <Field
                                    className="flex-container slideFromBottom"
                                    clearable={false}
                                    component={Select}
                                    idForm={FORM_ID}
                                    labelKey="label"
                                    name="idDebitAccount"
                                    onChange={({ id }) => {
                                        if (id) {
                                            setFieldValue("idDebitAccount", id);
                                        }
                                    }}
                                    optionClassName="needsclick"
                                    options={buildProductOptions}
                                    placeholder={EMPTY_STR}
                                    value={values.idDebitAccount}
                                    valueKey="id"
                                />
                            </Box>
                        </Box>
                        {hasErrorDebitAccount && (
                            <Box className="form-group">
                                <FieldError error={errors.idDebitAccount} />
                            </Box>
                        )}
                        {/* Disclaimer */}
                        <Box className="form-group label-checkbox">
                            <Field
                                className="form-control"
                                component={Termsandconditions}
                                i18nMap={i18nMap}
                                idForm={FORM_ID}
                                mode={MODE.EDIT}
                                name="disclaimer"
                                showAcceptOption
                            />
                        </Box>
                    </Box>
                </section>
                {/* Submit */}
                <Box flex justify="center" className="mt-3">
                    <Button type="submit" variant="primary">
                        {UtilsI18n.get("global.send")}
                    </Button>
                </Box>
            </div>
        </Form>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
    debitAccountsList: SelectorsStoreWMShares.getDebitAccountsList(store),
    paper: SelectorsStoreWMShares.getPaper(store),
    previewData: SelectorsStoreWMShares.getPreviewData(store),
    selectedType: SelectorsStoreWMShares.getSelectedType(store),
    timezoneOffset: SelectorsStoreWMShares.getTimezoneOffset(store),
});

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        mapPropsToValues: (props) => {
            const { paper = {}, previewData = {}, selectedType } = props;
            return {
                amountToInvest: { amount: previewData.amountToInvest } || EMPTY_STR,
                classification: selectedType || SHARE_CLASSIFICATION.ORDINARY,
                disclaimer: previewData.disclaimer || false,
                idDebitAccount: previewData.idDebitAccount || EMPTY_STR,
                isin: previewData.isin || paper.isin || EMPTY_STR,
                limitDate: previewData.limitDate || EMPTY_STR,
                limitTo: previewData.limitTo || EMPTY_STR,
                observations: previewData.observations || EMPTY_STR,
                orderCompleted: previewData?.orderCompleted !== false,
                price: previewData.price || PRICE_CLASSIFICATION.MARKET,
                ticker: (Object.keys(paper).length && paper !== previewData.initialPaper && previewData.ticker) || {
                    ticker1: previewData.initialTicker1 || EMPTY_STR,
                    ticker2: previewData.initialTicker2 || EMPTY_STR,
                },
            };
        },
        validationSchema: () =>
            Yup.lazy((props) =>
                Yup.object().shape({
                    amountToInvest: Yup.object().shape({
                        amount: Yup.string().required(UtilsI18n.get(`${FORM_ID}.amountToInvest.required`)),
                    }),
                    disclaimer: Yup.boolean().oneOf([true], UtilsI18n.get("client.wm.shares.buy.disclaimerRequired")),
                    idDebitAccount: Yup.string().required(UtilsI18n.get("client.wm.shares.buy.emptyDebitAccount")),
                    ...(props.classification === SHARE_CLASSIFICATION.PREFERRED && {
                        isin: Yup.string().required(UtilsI18n.get(`${FORM_ID}.isin.required`)),
                    }),
                    ...(props.classification === SHARE_CLASSIFICATION.ORDINARY && {
                        ticker: Yup.object().shape({
                            ticker1: Yup.string().required(UtilsI18n.get(`${FORM_ID}.isin.required`)),
                            ticker2: Yup.string().required(UtilsI18n.get(`${FORM_ID}.isin.required`)),
                        }),
                    }),
                    limitDate: props.orderCompleted
                        ? Yup.string().notRequired()
                        : Yup.string().trim().required(UtilsI18n.get("wm.allInvestments.limitDate.required")),
                    ticker: Yup.object().required(UtilsI18n.get(`${FORM_ID}.ticker.required`)),
                }),
            ),
        handleSubmit: (values, formikBag) => {
            const {
                amountToInvest,
                classification,
                disclaimer,
                idDebitAccount,
                limitDate,
                limitTo,
                observations,
                orderCompleted,
                price,
            } = values;
            const { dispatch, paper } = formikBag.props;
            const { description, idPaper, isin, ticker } = paper;

            dispatch(
                SelectorsActionWMShares.buySharesPreview({
                    previewData: {
                        amountToInvest: amountToInvest ? amountToInvest.amount : EMPTY_STR,
                        classification,
                        description,
                        disclaimer,
                        idDebitAccount,
                        idPaper,
                        isin,
                        limitDate: orderCompleted ? EMPTY_STR : limitDate,
                        limitTo: limitTo ? limitTo.amount : EMPTY_STR,
                        observations,
                        orderCompleted,
                        price,
                        ticker,
                    },
                    formikBag,
                }),
            );
        },
    }),
)(Component);
