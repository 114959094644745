/* global window */

import { routerActions as RouterActions, LOCATION_CHANGE } from "connected-react-router";
import QueryString from "query-string";
import { call as Call, put as Put, takeLatest as TakeLatest, select as Select } from "redux-saga/effects";

import {
    API_TRANSACTION_LINES_ERRORS,
    CONFIRMATION,
    EMPTY_STR,
    EXPRESS_TRANSFER,
    FORM,
    FORM_RENDER,
    ID_FORM as ID_FORM_CONSTANTS,
    LEVEL,
    PAY_CREDITCARD,
    REACT_APP_ROUTER_BASE,
    RESPONSE_TYPE,
    SCOPE,
    SIGN_WITH_BIOMETRIC,
    TRANSACTION_MUST_BE_AUTHORIZED_BY_APP,
    TRANSFER_CANNOT_BE_ONLINE,
    TRANSFER_WITH_BIOMETRIC,
    VALIDATION_ERROR,
} from "~/constants";
import { POSTFIX, ID_ACTIVITY, ID_FORM, MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionTransactionLines } from "~/store/transactionLines";
import Device from "~/util/device";
import {
    adjustIdFieldErrors as AdjustIdFieldErrors,
    credentialsWithUnderscore as CredentialsWithUnderscore,
    hasIncorrectCredentials as HasIncorrectCredentials,
    transactionWithoutDetail as TransactionWithoutDetail,
} from "~/util/form";
import * as I18nUtil from "~/util/i18n";

import { TYPE } from "../_consts";
import { SelectorsAction, SelectorsMiddleware, SelectorsStore } from "../_selectors";
import BankSelected from "./_bankSelected";
import IsTrustedBiometric from "./_isTrustedBiometric";
import RouteTicket from "./_routeTicket";

const sagas = [
    TakeLatest(LOCATION_CHANGE, readForm),
    TakeLatest(LOCATION_CHANGE, readTransaction),
    TakeLatest(TYPE.CLEAN_STEPS, readForm),
    TakeLatest(TYPE.PREVIEW_FORM_REQUEST, previewForm),
    TakeLatest(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, cancelTransactionCustom),
    TakeLatest(TYPE.CANCEL_TRANSACTION_PRE_REQUEST, cancelTransactionPre),
    TakeLatest(TYPE.CANCEL_TRANSACTION_REQUEST, cancelTransaction),
    TakeLatest(TYPE.MODIFY_TRANSACTION_REQUEST, modifyTransaction),
    TakeLatest(TYPE.READ_FORM_REQUEST, readForm),
    TakeLatest(TYPE.READ_TRANSACTION_REQUEST, readTransaction),
    TakeLatest(TYPE.SAVE_DRAFT_REQUEST, saveDraftTransaction),
    TakeLatest(TYPE.SEND_FORM_REQUEST, sendForm),
    TakeLatest(TYPE.SIGN_TRANSACTION_PREVIEW_REQUEST, signTransactionPreview),
    TakeLatest(TYPE.SIGN_TRANSACTION_REQUEST, signTransaction),
];

export default sagas;

function generateCommission(idForm, data) {
    let commission;

    if (idForm === ID_FORM_CONSTANTS.TRANSFER_LOCAL) {
        const { commissionAmountOffline, commissionAmountOnline } = data;

        commission = {
            commissionAmountOffline,
            commissionAmountOnline,
        };
    }

    return commission;
}

function* readForm({ payload }) {
    const [, route, idForm] = payload.location.pathname.split("/");
    const mode = yield Select(SelectorsStore.getMode);
    const shouldKeep = yield Select(SelectorsStore.getShouldKeepFormState);
    if (route === FORM && !shouldKeep) {
        //    Prevent calling form.read
        if (idForm === ID_FORM.FROM_BIOMETRIC) {
            yield Put(SelectorsAction.fromBiometric());
        } else if (mode === MODE.EDIT) {
            const { query: params } = QueryString.parseUrl(payload.location.search);

            const {
                type,
                data: { data, code },
            } = yield Call(SelectorsMiddleware.readForm, { idForm, params });
            if (type === RESPONSE_TYPE.WARNING) {
                yield Put(
                    SelectorsAction.readformfailure({
                        notification: {
                            type: LEVEL.ERROR,
                            code,
                        },
                    }),
                );
            } else {
                const { form: formMetadata, formData } = data;
                yield Put(SelectorsAction.readFormSuccess({ idForm, formMetadata, formData }));
            }
        }
    }
}

function* previewForm(props) {
    const { idForm, idActivity, idTransaction, values, formikBag } = props;
    let newValues = values;

    if (idForm === ID_FORM.SALARY_PAYMENT || idForm === ID_FORM.SUPPLIERS_PAYMENT) {
        if (values.loadingMethod[0] === "file") {
            newValues = {
                ...values,
                manualInput: [],
            };
        } else {
            newValues = {
                ...values,
                file: [],
            };
        }
    }

    if (idForm === ID_FORM.TRANSFER_FOREIGN) {
        newValues = yield* BankSelected(values);
    }

    const { type, data } = yield Call(
        SelectorsMiddleware.preview,
        {
            idForm,
            idTransaction,
            ...newValues,
        },
        idActivity.replace(POSTFIX.SEND, POSTFIX.PREVIEW),
    );

    if (type === RESPONSE_TYPE.WARNING) {
        if (data.data.NO_FIELD) {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.data.NO_FIELD,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.FORM],
                }),
            );
        } else {
            const message = data.code === VALIDATION_ERROR ? I18nUtil.get("forms.fieldsErrors") : data.message;
            yield Put(
                SelectorsActionNotification.showNotification({
                    message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.FORM],
                }),
            );
        }

        // manage errors in trasaction lines returned as validation_error
        const transactionLinesErrors = Object.fromEntries(
            Object.entries(data.data).filter(([k]) => k.includes("line@")),
        );
        if (data.code === VALIDATION_ERROR && transactionLinesErrors) {
            yield Put(SelectorsActionTransactionLines.setErrors(transactionLinesErrors));
        }

        if (data.code === API_TRANSACTION_LINES_ERRORS) {
            yield Put(SelectorsActionTransactionLines.setErrors(data.data));
        }

        formikBag.setErrors(AdjustIdFieldErrors(data.data));
        formikBag.setSubmitting(false);
        yield Put(SelectorsAction.sendFormFail());
    } else {
        // TODO: a WARNING here must be treated as an ERROR, right?
        const response = yield Call(SelectorsMiddleware.listCredentialsGroups, {
            idForm,
            idActivityToRead: idActivity,
        });

        yield Put(
            SelectorsAction.previewFormSuccess({
                commission: generateCommission(idForm, data.data),
                credentialsGroups: response.data.data.groups,
                idForm,
                previewData: data.data,
                submitAction: SelectorsAction.sendForm,
                submitActionParams: { idForm, idActivity, idTransaction, values: newValues },
            }),
        );

        formikBag.setSubmitting(false);

        if (Device.isMobileNative()) {
            const stateObj = {};
            window.history.pushState(stateObj, CONFIRMATION, CONFIRMATION);
        }

        if (idForm === ID_FORM.TRANSFER_LOCAL) {
            yield Put(SelectorsAction.setData({ data: values }));
        }
    }
}

function* sendForm(props) {
    const { idForm, idActivity, idTransaction, values, formikBag, credentials } = props;
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, TRANSFER_WITH_BIOMETRIC);
    }

    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    yield Put(SelectorsAction.transactionRequestDelay());

    const { data, type } = yield Call(
        SelectorsMiddleware.send,
        { idForm, idTransaction, ...values, ...credentialsWithUnderscore },
        idActivity,
    );

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            if (isTrusted) {
                const message = data.data?.otp || data.message;
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.FORM],
                    }),
                );
            } else {
                formikBag.setErrors(AdjustIdFieldErrors(data.data));
            }

            yield Put(SelectorsAction.sendFormCredentialFailure({ code: data.code }));
        } else if (data.code === TRANSFER_CANNOT_BE_ONLINE) {
            const {
                creditAccountAlias,
                debitAccountAlias,
                deferredInformationMessage,
                isOnlineEnabled,
                showExecutionOptions,
            } = data.data;

            const {
                data: {
                    data: { groups },
                },
            } = yield Call(SelectorsMiddleware.listCredentialsGroups, {
                idForm,
                idActivityToRead: idActivity,
            });

            const transactionResponse = yield Call(SelectorsMiddleware.readTransaction, {
                idTransactionToRead: data.idTransaction,
            });

            const { transaction } = transactionResponse.data.data;

            const otherOptionData = {
                ...transaction.data,
                creditAccountAlias,
                creditAmount: transaction.data?.creditAmount || transaction.data.amount,
                debitAccountAlias,
                debitAmount: transaction.data?.debitAmount || transaction.data.amount,
                deferredInformationMessage,
                idCreditBank: transaction.data.idCreditBank[0],
                isOnlineEnabled,
                showExecutionOptions,
            };

            const errorMessage = data.data.NO_FIELD || data.message;

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.FORM],
                }),
            );

            formikBag.resetForm();

            yield Put(
                SelectorsAction.suggestOtherLocalTransferOption({
                    idForm,
                    credentialsGroups: groups,
                    submitAction: SelectorsAction.sendForm,
                    submitActionParams: { idForm, idActivity, idTransaction: transaction.idTransaction, values },
                    previewData: otherOptionData,
                }),
            );
        } else {
            yield Put(SelectorsAction.sendFormDataFailure());

            const errorMessage = data.data.NO_FIELD || data.message;

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.FORM],
                }),
            );
        }

        formikBag.setSubmitting(false);
    } else {
        const isExpressTransfer = idActivity === ID_ACTIVITY.TRANSFER_LOCAL && data.code === EXPRESS_TRANSFER;
        let transaction;

        if (TransactionWithoutDetail(idForm)) {
            transaction = {
                data: { idForm },
                idActivity,
                idTransaction,
                idForm,
                idTransactionStatus: STATUS.FINISHED,
                isExpressTransfer,
            };
        } else {
            const transactionResponse = yield Call(SelectorsMiddleware.readTransaction, {
                idTransactionToRead: data.idTransaction,
            });

            transaction = transactionResponse.data.data.transaction;

            const { idTransactionStatus, returnCode } = transaction;

            // Notification to authorize the transaction through APP is added (if needed)
            if (
                idTransactionStatus === STATUS.PENDING &&
                returnCode === TRANSACTION_MUST_BE_AUTHORIZED_BY_APP &&
                !Device.isMobileNative()
            ) {
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: I18nUtil.get("client.transactions.transaction.feasibility.notification"),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.FORM],
                    }),
                );
            }
        }

        formikBag.setSubmitting(false);

        yield Put(SelectorsAction.sendFormSuccess({ transaction: { ...transaction, isExpressTransfer } }));
    }

    formikBag.setSubmitting(false);

    return undefined;
}

function* readTransaction(props) {
    const { payload } = props;

    const pathname = payload.location.pathname.replace(REACT_APP_ROUTER_BASE, EMPTY_STR); // remove router base

    const [, route, idTransaction] = pathname.split("/");

    const shouldKeep = yield Select(SelectorsStore.getShouldKeepFormState);

    if (route && route.toLowerCase() === FORM_RENDER.TRANSACTION && !shouldKeep) {
        const transactionResponse = yield Call(SelectorsMiddleware.readTransaction, {
            idTransactionToRead: idTransaction,
        });

        if (transactionResponse.type === RESPONSE_TYPE.WARNING) {
            yield Put(
                SelectorsAction.readTransactionFailure({
                    notification: { type: LEVEL.ERROR, code: transactionResponse.data.code },
                }),
            );
        } else {
            const {
                idForm,
                formVersion,
                idActivity,
                idTransaction: idTransactionToRead,
                data: { creditCard },
            } = transactionResponse.data.data.transaction;

            const dataRead =
                creditCard && idForm === PAY_CREDITCARD
                    ? { idForm, formVersion, idTransactionToRead, creditCard }
                    : { idForm, formVersion, idTransactionToRead, data: null };

            if (idForm === null) {
                const administrationScheme = yield Select((state) =>
                    SelectorsStoreSession.getAdministrationScheme(state),
                );
                yield Put(RouterActions.replace(RouteTicket[idActivity](idTransaction, administrationScheme)));
            } else {
                const formResponse = yield Call(SelectorsMiddleware.readForm, dataRead);

                if (formResponse.type === RESPONSE_TYPE.WARNING) {
                    yield Put(
                        SelectorsAction.readformfailure({
                            notification: {
                                type: LEVEL.ERROR,
                                code: formResponse.data.code,
                            },
                        }),
                    );
                } else {
                    const { children, parent, transaction, transactionCredential } = transactionResponse.data.data;
                    const { idTransactionStatus, returnCode } = transaction;

                    const {
                        data: {
                            data: { groups },
                        },
                    } = yield Call(SelectorsMiddleware.listCredentialsGroups, {
                        idForm,
                        idActivityToRead: idActivity,
                    });

                    // Se agrego control para el caso en  el que el flujo se inicia desde el boton modificar
                    // en donde el payload.search no viene
                    // igual esto deberiamos modificarlo para usar el routerAction (el from sugio para
                    // determinar si se viene desde un widget, asi al vovler regreso al desktop y no a la
                    // lista de transaccion)
                    const { query } = payload.location.search
                        ? QueryString.parseUrl(payload.location.search)
                        : EMPTY_STR;
                    const from = query && query.from ? query.from : EMPTY_STR;
                    yield Put(
                        SelectorsAction.readTransactionSuccess({
                            idForm: transaction.idForm,
                            transaction,
                            childrenTransactions: children,
                            parentTransaction: parent,
                            credentialsGroups: groups,
                            formMetadata: formResponse.data.data.form,
                            comeFrom: from,
                            transactionCredential,
                        }),
                    );

                    // Notification to authorize the transaction through APP is added (if needed)
                    if (
                        idTransactionStatus === STATUS.PENDING &&
                        returnCode === TRANSACTION_MUST_BE_AUTHORIZED_BY_APP &&
                        !Device.isMobileNative()
                    ) {
                        yield Put(
                            SelectorsActionNotification.showNotification({
                                message: I18nUtil.get("client.transactions.transaction.feasibility.notification"),
                                level: LEVEL.ERROR,
                                scopes: [SCOPE.FORM],
                            }),
                        );
                    }
                }
            }
        }
    }
}

function* saveDraftTransaction(props) {
    const { idForm, idActivityDraft } = props;
    const response = yield Call(SelectorsMiddleware.saveDraft, props);
    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: I18nUtil.get("forms.saveDraft.errorMessage"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.FORM],
            }),
        );
        yield Put(SelectorsAction.saveDraftFailure());
    } else {
        let route = "/desktop";
        if (idActivityDraft.startsWith("comex")) {
            route = "/comex";
        }
        const confirmationMessage = I18nUtil.get("forms.saveDraft.confirmationMessage");
        yield Put(SelectorsAction.saveDraftSuccess({ idForm, data: response.data.data }));
        yield Put(RouterActions.push(route));
        yield Put(
            SelectorsActionNotification.showNotification({
                message: confirmationMessage,
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.ACCOUNTS, SCOPE.DESKTOP, SCOPE.COMEX],
            }),
        );
    }
}

function* cancelTransactionPre(props) {
    const {
        type,
        data: {
            data: { groups },
        },
    } = yield Call(SelectorsMiddleware.listCredentialsGroups, props);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: I18nUtil.get("forms.cancelTransaction.pre.error"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.FORM],
            }),
        );
        yield Put(SelectorsAction.cancelTransactionPreError());
    } else {
        yield Put(SelectorsAction.cancelTransactionPreSuccess({ credentialsGroups: groups }));
    }
}

function* handleCancelTransaction(credentials, idTransaction, formikBag) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const {
        data: { data },
        type,
    } = yield Call(SelectorsMiddleware.cancelTransaction, {
        idTransactionToCancel: idTransaction,
        ...credentialsWithUnderscore,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data);
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: I18nUtil.get("forms.cancelTransaction.errorMessage"),
                    level: LEVEL.ERROR,
                    scopes: SCOPE.FORM,
                }),
            );
        }
    } else {
        yield Put(
            SelectorsAction.readTransaction({
                payload: window.location.pathname.includes("/transaction")
                    ? window
                    : { location: { pathname: `/transaction/${idTransaction}` } },
            }),
        );

        yield Put(SelectorsAction.cancelTransactionSuccess());

        yield Put(
            SelectorsActionNotification.showNotification({
                message: I18nUtil.get("forms.cancelTransaction.confirmationMessage"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.FORM, SCOPE.DESKTOP],
            }),
        );
    }
}

function* cancelTransaction(props) {
    const { credentials, formikBag } = props;
    const { idTransaction } = formikBag.props;

    yield* handleCancelTransaction(credentials, idTransaction, formikBag);

    formikBag.setSubmitting(false);
}

function* cancelTransactionCustom(props) {
    const { credentials, idTransaction, formikBag } = props;

    yield* handleCancelTransaction(credentials, idTransaction, formikBag);

    formikBag.setSubmitting(false);
}

function* modifyTransaction(props) {
    const { type } = yield Call(SelectorsMiddleware.moveToDraftTransaction, props);
    const { idTransactionToMove } = props;
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: I18nUtil.get("forms.modifyTransaction.errorMessage"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.FORM],
            }),
        );
        yield Put(SelectorsAction.cancelTransactionFailure());
    } else {
        yield Put(
            SelectorsAction.readTransaction({
                payload: { location: { pathname: `/transaction/${idTransactionToMove}` } },
            }),
        );
    }
}

function* signTransactionPreview(props) {
    const { idForm, idActivity, idTransaction, ticketData } = props;
    const {
        data: {
            data: { groups },
        },
    } = yield Call(SelectorsMiddleware.listCredentialsGroups, {
        idForm,
        idActivityToRead: idActivity,
    });

    const { type, data } = yield Call(
        SelectorsMiddleware.signPreview,
        { idForm, idTransaction },
        idActivity.replace(POSTFIX.SEND, POSTFIX.PREVIEW),
    );

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.signTransactionPreviewFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.FORM],
            }),
        );
    } else {
        yield Put(
            SelectorsAction.signTransactionPreviewSuccess({
                commission: generateCommission(idForm, data.data),
                credentialsGroups: groups,
                idForm,
                submitAction: SelectorsAction.signTransaction,
                submitActionParams: { idForm, idActivity, idTransaction },
                previewData: data.data,
                ticketData,
            }),
        );
    }
}

function* signTransaction(props) {
    const { idForm, idActivity, idTransaction, credentials, formikBag, values } = props;
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);
    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
    }

    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    yield Put(SelectorsAction.transactionRequestDelay());

    const { data, type } = yield Call(
        SelectorsMiddleware.sign,
        { idForm, idTransaction, ...values, ...credentialsWithUnderscore },
        idActivity,
    );

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);
        if (hasIncorrectCredentials) {
            formikBag.setErrors(AdjustIdFieldErrors(data.data));
            yield Put(SelectorsAction.sendFormCredentialFailure({ code: data.code }));
            if (isTrusted) {
                const message = data.data?.otp || data.message;
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.FORM],
                    }),
                );
            }
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.FORM],
                }),
            );
        }
        formikBag.setSubmitting(false);
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddleware.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(
            SelectorsAction.sendFormSuccess({
                transaction,
            }),
        );
        formikBag.setSubmitting(false);
    }
}
