import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { SelectorsAction as ActionWM } from "~/store/wm";
import * as UtilsI18n from "~/util/i18n";
import * as UtilsNumber from "~/util/number";

import Box from "~/components/Box";
import FieldLabel from "~/pages/_components/fields/FieldLabel";

export const NAME = "PaperBuy";

export const PROP = {
    types: { isSelling: PropTypes.bool },
    defaults: {
        isSelling: false,
    },
};

export function Component(props) {
    const { othersFields, lang } = props;

    const { description, currency, expirationDate, rate, productTypeOrigin } = othersFields;

    function handleClick() {
        const {
            dispatch,
            othersFields: { detailType, detailLink, idPaper },
        } = props;

        const link = detailType === "external" ? detailLink : null;
        if (!link) {
            dispatch(ActionWM.paperDetailRequest({ idPaper, productType: productTypeOrigin, detailType }));
        } else {
            const win = window.open(link, "_blank");
            if (win) {
                win.focus();
                win.opener = null;
            }
        }
    }

    const rateFixed = UtilsNumber.numberToLocaleFormat(rate, lang);

    return (
        <Box className="form-group py-3">
            <Box className="grid-container paper px-4 py-2">
                <Box flex className="description-label">
                    <FieldLabel labelKey="client.wm.bonds.buy.paper.issuer" useColon />
                    <Box className="py-3 pl-3" tag="span">
                        {description}
                    </Box>
                </Box>
                <Box flex>
                    <FieldLabel labelKey="client.wm.bonds.buy.paper.currency" useColon />
                    <Box className="py-3 pl-3" tag="span">
                        {UtilsI18n.get(`currency.label.${currency}`)}
                    </Box>
                </Box>
                <Box flex>
                    <FieldLabel labelKey="client.wm.bonds.buy.paper.expirationDate" useColon />
                    <Box className="py-3 pl-3" tag="span">
                        {expirationDate}
                    </Box>
                </Box>
                <Box flex>
                    <FieldLabel labelKey="client.wm.bonds.buy.paper.description" useColon />
                    <Box className="py-3 pl-3" tag="span">
                        {UtilsI18n.get(`client.wm.bonds.buy.paper.type.${productTypeOrigin}`)}
                    </Box>
                </Box>
                <Box flex>
                    <FieldLabel labelKey="client.wm.bonds.buy.paper.coupon" useColon />
                    <Box className="py-3 pl-3" tag="span">
                        {rateFixed}%
                    </Box>
                </Box>
                <Box flex justify="end" className="details-btn">
                    <Box flex tag="span">
                        <a onClick={() => handleClick()}>{UtilsI18n.get("wm.view.details")}</a>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    lang: SelectorsStoreI18n.getLang(store),
});

export default Connect(mapStateToProps)(Component);
