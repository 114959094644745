import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    checkMobilePhone: (props) => API.executeWithAccessToken(PATH.MIGRATION_CHECK_MOBILE_PHONE, props),
    changeMobilePhone: (props) => API.executeWithAccessToken(PATH.MIGRATION_CHANGE_MOBILE_PHONE, props),
    securtityTips: (props) => API.executeWithAccessToken(PATH.MIGRATION_SECURITY_TIPS, props),
    addSoftOTPWithBiometric: (props) => API.executeWithAccessToken(PATH.MIGRATION_ADD_SOFT_OTP, props),
    migrationSuggest: (props) => API.executeWithAccessToken(PATH.MIGRATION_SUGGEST, props),
    finishMigrationtSoft: (props) => API.executeWithAccessToken(PATH.MIGRATION_FINISH_SOFT, props),
    revokeSoftOtp: (props) => API.executeWithAccessToken(PATH.MIGRATION_REVOKE_SOFT_OTP, props),
};

export const SelectorsAction = {
    checkMobilePhone: (props) => FactoryAction(TYPE.CHECK_MOBILE_REQUEST, props),
    errorCheckMobilePhone: (props) => FactoryAction(TYPE.CHECK_MOBILE_ERROR, props),
    successCheckMobilePhone: (props) => FactoryAction(TYPE.CHECK_MOBILE_SUCCESS, props),

    migrationSuggest: (props) => FactoryAction(TYPE.MIGRATION_SUGGEST_REQUEST, props),
    errorMigrationSuggest: (props) => FactoryAction(TYPE.MIGRATION_SUGGEST_ERROR, props),
    successMigrationSuggest: (props) => FactoryAction(TYPE.MIGRATION_SUGGEST_SUCCESS, props),

    changeMobilePhone: (props) => FactoryAction(TYPE.CHANGE_MOBILE_REQUEST, props),
    errorChangeMobilePhone: (props) => FactoryAction(TYPE.CHANGE_MOBILE_ERROR, props),
    successChangeMobilePhone: (props) => FactoryAction(TYPE.CHANGE_MOBILE_SUCCESS, props),

    securtityTips: (props) => FactoryAction(TYPE.SECURITY_TIPS_REQUEST, props),
    securtityTipsSuccess: (props) => FactoryAction(TYPE.SECURITY_TIPS_SUCESS, props),
    securtityTipsError: (props) => FactoryAction(TYPE.SECURITY_TIPS_ERROR, props),

    addSoftOTPWithBiometric: (props) => FactoryAction(TYPE.ADD_SOFT_OTP_REQUEST, props),
    addSoftOTPWithBiometricSuccess: (props) => FactoryAction(TYPE.ADD_SOFT_OTP_SUCCESS, props),
    addSoftOTPWithBiometricError: (props) => FactoryAction(TYPE.ADD_SOFT_OTP_ERROR, props),

    registerBiometricMigration: (props) => FactoryAction(TYPE.REGISTER_BIOMETRIC_MIGRATION, props),

    securityTipsModalTrue: (props) => FactoryAction(TYPE.SECURITY_TIPS_MODAL_TRUE, props),
    securityTipsModalFalse: (props) => FactoryAction(TYPE.SECURITY_TIPS_MODAL_FALSE, props),

    finishMigrationSoft: (props) => FactoryAction(TYPE.MIGRATION_FINISH_SOFT_REQUEST, props),
    finishMigrationSoftSuccess: (props) => FactoryAction(TYPE.MIGRATION_FINISH_SOFT_SUCCESS, props),
    finishMigrationSoftError: (props) => FactoryAction(TYPE.MIGRATION_FINISH_SOFT_ERROR, props),

    revokeSoftOtp: (props) => FactoryAction(TYPE.MIGRATION_REVOKE_SOFT_REQUEST, props),
    revokeSoftOtpSuccess: (props) => FactoryAction(TYPE.MIGRATION_REVOKE_SOFT_SUCCESS, props),
    revokeSoftOtpError: (props) => FactoryAction(TYPE.MIGRATION_REVOKE_SOFT_ERROR, props),
    cleanUp: (props) => FactoryAction(TYPE.CLEAN_UP, props),
};

export const SelectorsStore = {
    fetching: (store) => store[NAME].fetching,
    currentMobilePhone: (store) => store[NAME].currentMobilePhone,
    mobilePhone: (store) => store[NAME].mobilePhone,
    mobilePhoneClean: (store) => store[NAME].mobilePhoneClean,
    registerBiometricMigration: (store) => store[NAME].registerBiometricMigration,
    idUser: (store) => store[NAME].idUser,
    getSecurityTipsModal: (store) => store[NAME].secutityTipsModalFalse,
    getRegisterMigrationFinish: (store) => store[NAME].registerMigrationFinish,
};
