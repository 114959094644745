import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";

import { COMMA, EMPTY_STR, LEVEL, SCOPE, SPACE_STR } from "~/constants";
import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import {
    SelectorsStore as SelectorsStoreComex,
    SelectorsAction as SelectorsActionComex,
    PROP as PropComex,
} from "~/store/comex";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsAction as SelectorActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionTemplate } from "~/store/template";
import * as ConfigUtil from "~/util/config";
import { formatDate as FormatDate, toDate as ToDate } from "~/util/date";
import { isEmptyObj } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import EmailList from "~/pages/_components/fields/EmailList";
import TextArea from "~/pages/_components/fields/TextArea";
import TextField from "~/pages/_components/fields/TextField";

import FileUploader from "~/pages/comEx/_components/FileUploader";
import CreateTemplateModal from "~/pages/forms/Step1Edit/_ModalTemplateCreate";
import ListTemplatesModal from "~/pages/forms/Step1Edit/_ModalTemplateList";
import { Termsandconditions } from "~/pages/forms/_components/_fields";
import Scheduler from "~/pages/forms/_components/_fields/Scheduler";

import Style from "../../../Step1Edit.rules.scss";

const FORM_ID = "comex.other.otherProcedures";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...PropComex.types,
    },
    defaults: {
        ...PropComex.defaults,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        draftLoaded: false,
    };

    componentDidMount() {
        const { dispatch, formData } = this.props;

        if (isEmptyObj(formData)) {
            dispatch(SelectorsActionComex.otherProcedurePreRequest());
        }
    }

    componentDidUpdate() {
        const { dispatch, setFieldError, setFieldTouched, setValues, transaction } = this.props;

        const { draftLoaded } = this.state;

        if (transaction?.data && !draftLoaded) {
            this.setState({
                draftLoaded: true,
            });

            const transactionData = transaction?.data;
            /* carga de errores */
            if (transactionData.backendFormErrors?.cdpErrorFields) {
                const { cdpErrorFields } = transactionData.backendFormErrors;

                cdpErrorFields.map((errorField) => {
                    if (errorField === "valueDate") {
                        setFieldError("scheduler", SPACE_STR);
                        setFieldTouched("scheduler", true);

                        transactionData.scheduler = {
                            editing: true,
                            valueDate: transactionData?.valueDate,
                            selectedOption: transactionData?.selectedOption,
                        };
                    } else {
                        setFieldError(errorField, SPACE_STR);
                        setFieldTouched(errorField, true);
                    }

                    return null;
                });
            }

            if (transactionData.backendFormErrors?.message) {
                const errorMessage = transactionData?.backendFormErrors?.message;

                dispatch(
                    SelectorActionNotification.showNotification({
                        message: errorMessage,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.COMEX_OTHERS],
                    }),
                );
            }

            setValues(transaction.data);
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(GoBack());
    };

    handleFormKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            event.preventDefault();
        }
    };

    handleFieldKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            this.handleSearch();
        }
    };

    handleDraft = () => {
        const { dispatch, transaction, values } = this.props;

        dispatch(
            SelectorsActionForm.saveDraft({
                idActivityDraft: "comex.export.transferCreditLetter.send",
                idTransactionToSave: transaction?.idTransaction,
                transactionData: values || {},
            }),
        );
    };

    handleDraft = () => {
        const { dispatch, transaction, values } = this.props;

        dispatch(
            SelectorsActionForm.saveDraft({
                idActivityDraft: "comex.import.genericForm.send",
                idTransactionToSave: transaction?.idTransaction,
                transactionData: values || {},
            }),
        );
    };

    handleTemplateSave = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionTemplate.createTemplate());
    };

    handleTemplateLoad = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionTemplate.toggleTemplateList());
    };

    handleSelectTemplate = (template) => {
        const { setValues, setErrors } = this.props;
        const templateToBeLoaded = template;

        if (templateToBeLoaded.scheduler.valueDate) {
            const valueDate = ToDate(templateToBeLoaded.scheduler.valueDate);

            templateToBeLoaded.scheduler.valueDate = valueDate;
        }

        setValues(templateToBeLoaded);
        setErrors({});
    };

    render() {
        const { emailValidationRegex, errors, preData, setFieldValue, transaction, values } = this.props;
        const { nextValidDate, unavailableDays } = preData;
        const termsAndConditions = "comex.other.otherProcedures.disclaimer";
        const i18nMap = {
            termsAndConditions,
        };

        const cancelEnabled = transaction && transaction.idTransactionStatus === STATUS.DRAFT;

        return (
            <React.Fragment>
                <Form
                    className="col col-12 col-lg-6 col-md-9 col-sm-12"
                    id={Style.ID}
                    noValidate="novalidate"
                    onKeyDown={this.handleFormKeyDown}>
                    <div className="form-section loadTemplates">
                        <Button
                            onClick={this.handleTemplateLoad}
                            label="forms.templates.load"
                            bsStyle="default"
                            image="template.svg"
                        />
                    </div>
                    <section className="fields container--layout align-items-center flex-grow">
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.section.operation`)}</h3>
                        </Box>
                        <Field
                            idForm={FORM_ID}
                            name="subject"
                            hidePlaceholder
                            type="text"
                            maxLength={80}
                            component={TextField}
                            tooltip={UtilsI18n.get(`${FORM_ID}.subject.tooltip`)}
                        />

                        <Field
                            component={TextArea}
                            idForm={FORM_ID}
                            name="message"
                            mode={MODE.EDIT}
                            className="form-control"
                            maxLength={500}
                            hidePlaceholder
                            optional={UtilsI18n.get("form.field.optional")}
                        />

                        <Box className="form-group">
                            <Field
                                name="files"
                                idForm={FORM_ID}
                                emptyMessageKey={`${FORM_ID}.attachments.emptyMessage`}
                                maxFiles={5}
                                errors={errors}
                                component={FileUploader}
                                values={values}
                                setFieldValue={setFieldValue}
                            />
                        </Box>

                        <Field
                            component={EmailList}
                            idForm={FORM_ID}
                            name="notificationEmails"
                            hideSwiftTip
                            className="form-group email-field"
                            renderSuggestion={false}
                            data={{ emailValidationRegex, addMessage: EMPTY_STR }}
                            value={values.notificationEmails}
                            tooltip={UtilsI18n.get(`${FORM_ID}.notificationEmails.tooltip`)}
                        />

                        <hr />
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">
                                {UtilsI18n.get(`${FORM_ID}.section.showPreview`)}
                            </h3>
                        </Box>

                        <Field
                            component={Termsandconditions}
                            idForm={FORM_ID}
                            name="disclaimer"
                            mode={MODE.EDIT}
                            className="form-control"
                            i18nMap={i18nMap}
                        />
                        {nextValidDate && (
                            <Field
                                component={Scheduler}
                                data={{
                                    firstWorkingDate: nextValidDate,
                                    maxDaysToSchedule: ConfigUtil.get("client.comex.valueDate.maxDaysToSchedule"),
                                    nonWorkingDays: unavailableDays,
                                    lang: "es",
                                    mode: "edit",
                                    programable: false,
                                    schedulable: true,
                                }}
                                name="scheduler"
                                idForm={FORM_ID}
                            />
                        )}
                    </section>
                    <footer>
                        <Button onClick={this.handleDraft} label="forms.saveDraft.link" />
                        <Button
                            onClick={this.handleTemplateSave}
                            className="templateSave"
                            label="forms.saveTemplate.link"
                        />
                        {cancelEnabled && <Button onClick={this.handleCancel} label="forms.cancelTransaction.link" />}
                        <Button
                            key="primaryButton"
                            variant="primary"
                            bsStyle="primary"
                            label="global.next"
                            loading={false}
                            type="submit"
                        />
                    </footer>
                </Form>
                <ListTemplatesModal
                    idActivityTemplate="comex.import.genericForm.send"
                    onSelect={this.handleSelectTemplate}
                />
                <CreateTemplateModal
                    values={values}
                    idActivityTemplate="comex.import.genericForm.send"
                    backdrop="static"
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        formData: SelectorsStoreComex.getOtherProcedureFormData(store),
        preData: SelectorsStoreComex.getOtherProcedurePre(store),
        transaction: SelectorsStoreComex.getTransaction(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ formData }) => {
            const attachments = formData?.attachments;
            const hasAttachments = attachments !== undefined && attachments !== EMPTY_STR;

            return {
                scheduler: formData?.valueDate
                    ? {
                          editing: true,
                          valueDate: formData?.valueDate,
                          selectedOption: formData?.selectedOption,
                      }
                    : EMPTY_STR,
                subject: formData?.subject || EMPTY_STR,
                message: formData?.message || EMPTY_STR,
                files: hasAttachments ? attachments?.split(COMMA) : [],
                attachmentsNames: formData?.attachmentsNames || [],
                notificationEmails: formData?.notificationEmails,
            };
        },
        handleSubmit: (props, formikBag) => {
            const { dispatch } = formikBag.props;
            const { item, files, scheduler, ...rest } = props;

            const attachmentsFiles = files ? files.join(COMMA) : EMPTY_STR;
            const valueDateString = scheduler ? FormatDate(scheduler.valueDate) : EMPTY_STR;

            const formData = {
                ...rest,
                valueDate: valueDateString,
                selectedOption: scheduler?.selectedOption,
                attachments: attachmentsFiles,
                scheduler,
            };

            dispatch(
                SelectorsActionComex.otherProcedurePreviewRequest({
                    formData,
                    formikBag,
                }),
            );
        },
    }),
)(Component);
