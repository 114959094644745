import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Field, Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import MaskedInput from "react-text-mask";
import * as Yup from "yup";

import { EMPTY_STR } from "~/constants";
import {
    SelectorsAction as CustomsPaymentActions,
    SelectorsStore as CustomsPaymentSelectors,
} from "~/store/customsPayment";
import * as ArrayUtils from "~/util/array";
import * as UtilsConfig from "~/util/config";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import Select from "~/components/Select";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TextField from "~/pages/_components/fields/TextField";
import Selector from "~/pages/_components/fields/formik/Selector";

import PaymentList from "../_components/PaymentList";
import Style from "./Step1Edit.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        fetching: PropTypes.bool.isRequired,
        setFieldValue: PropTypes.func.isRequired,
        validateForm: PropTypes.func.isRequired,
        ...TypesRedux,
    },
    defaults: {
        i18n: {},
        ...DefaultsRedux,
    },
};

const FORM_ID = "payments.customsPayment.step1";

const UYU = "UYU";
const USD = "USD";
const UYU_VALUE_0 = "0";
const UYU_VALUE_0000 = "0000";
const USD_VALUE = "2222";

const getRadioButtonOptions = () => [
    { id: "RUT", label: "RUT" },
    { id: "DUA", label: "DUA" },
];

const getCurrencyOptions = () => {
    const paymentCurrencies = UtilsConfig.getArray(`payments.customsPayment.form.currencies`);
    return [].concat(
        paymentCurrencies.map((option) => ({
            value: option,
            label: option
                ? UtilsI18n.get(`${FORM_ID}.currency.${option}`)
                : UtilsI18n.get(`${FORM_ID}.currency.emptyOption`),
        })),
    );
};

const mapCurrencyCodeValue = (code) => {
    switch (code) {
        case UYU_VALUE_0:
            return UYU;
        case UYU_VALUE_0000:
            return UYU;
        case USD_VALUE:
            return USD;
        default:
            return UYU;
    }
};

const getFirstAccountValue = (accounts) => {
    const account = accounts.find((a) => mapCurrencyCodeValue(a.extraInfo.split("|")[4]) === UYU);
    return account ? account.idProduct : EMPTY_STR;
};

export const arrayEquals = (array1, array2) => {
    return array1.length === array2.length && ArrayUtils.intersection(array1, array2).length === array1.length;
};

export class Component extends React.Component {
    state = {
        selectedPayments: [],
        checkboxesValues: [],
        totalAmount: 0,
        allowedAccounts: [{ label: UtilsI18n.get(`${FORM_ID}.account.emptyOption`), value: EMPTY_STR }],
        allowedPayementItems: [],
        paymentItems: [],
    };

    fetched = false;

    componentDidMount() {
        const { dispatch, accounts } = this.props;
        if (!accounts || accounts.length === 0) {
            dispatch(CustomsPaymentActions.listPaymentsPreRequest());
        }
    }

    static getDerivedStateFromProps(props, state) {
        const checkboxesValues = [];
        const { paymentItems, accounts } = props;
        const ACCOUNT_EMPTY_OPTION = [{ label: UtilsI18n.get(`${FORM_ID}.account.emptyOption`), value: EMPTY_STR }];

        let allowedAccounts = state && state.allowedAccounts ? state.allowedAccounts : ACCOUNT_EMPTY_OPTION;
        if (accounts && accounts.length > 0 && allowedAccounts.length === 1 && props.values) {
            allowedAccounts = ACCOUNT_EMPTY_OPTION.concat(
                accounts
                    .filter((a) => mapCurrencyCodeValue(a.extraInfo.split("|")[4]) === props.values.currency)
                    .map((ac) => {
                        return { label: ac.productAlias, value: ac.idProduct };
                    }),
            );
        }

        const paymentItemsReceived =
            paymentItems && state && state.paymentItems && !arrayEquals(paymentItems, state.paymentItems);
        if (paymentItemsReceived) {
            const allowedPayementItems = paymentItems.filter((a) => a.currencyCode === props.values.currency);
            let totalAmount = 0;
            allowedPayementItems.forEach((element) => {
                const item = props.selectedPayments.find((p) => p.merchantPaymentId === element.merchantPaymentId);
                if (item) {
                    checkboxesValues.push(true);
                    totalAmount += item.totalAmount;
                } else {
                    checkboxesValues.push(false);
                }
            });
            return {
                ...state,
                checkboxesValues,
                selectedPayments: props.selectedPayments,
                totalAmount,
                allowedPayementItems,
                paymentItems,
                allowedAccounts,
            };
        }
        return {
            ...state,
            allowedAccounts,
        };
    }

    HandleBack = () => {
        const { dispatch } = this.props;
        dispatch(GoBack());
    };

    payItems = () => {
        const { setFieldValue, submitForm } = this.props;
        const { selectedPayments } = this.state;
        setFieldValue("selectedPayments", selectedPayments);
        submitForm();
    };

    selectPayment = (id, index) => {
        const { paymentItems } = this.props;
        const newItem = paymentItems.find((p) => p.merchantPaymentId === id);

        this.setState((prevState) => ({
            selectedPayments: prevState.selectedPayments.find((p) => p.merchantPaymentId === newItem.merchantPaymentId)
                ? prevState.selectedPayments.filter((p) => p.merchantPaymentId !== newItem.merchantPaymentId)
                : prevState.selectedPayments.concat(newItem),
            checkboxesValues: {
                ...prevState.checkboxesValues,
                [index]: !prevState.checkboxesValues[index],
            },
            totalAmount: prevState.selectedPayments.find((p) => p.merchantPaymentId === newItem.merchantPaymentId)
                ? prevState.totalAmount - newItem.totalAmount
                : prevState.totalAmount + newItem.totalAmount,
        }));
    };

    handleCurrencyChange = (e) => {
        if (e) {
            const { setFieldValue, accounts, paymentItems } = this.props;
            setFieldValue("currency", e.value);
            setFieldValue("account", EMPTY_STR);

            let allowedAccounts = accounts.filter((a) => mapCurrencyCodeValue(a.extraInfo.split("|")[4]) === e.value);
            const allowedPayementItems = paymentItems.filter((a) => a.currencyCode === e.value);
            allowedAccounts = [{ label: UtilsI18n.get(`${FORM_ID}.account.emptyOption`), value: EMPTY_STR }].concat(
                allowedAccounts.map((a) => {
                    return { label: a.productAlias, value: a.idProduct };
                }),
            );
            this.setState({
                selectedPayments: [],
                allowedAccounts,
                totalAmount: 0,
                allowedPayementItems,
                checkboxesValues: allowedPayementItems.map(() => false),
            });
        }
    };

    handleChangeAccount = (e) => {
        if (e) {
            const { setFieldValue } = this.props;
            setFieldValue("account", e.value);
        }
    };

    handleChangeDocumentType = () => {
        const { setFieldValue, setFieldTouched } = this.props;
        setFieldValue("documentNumber", "");
        setFieldTouched("documentNumber", false, false);
    };

    render() {
        const { values, status, fetching, touched, errors, mode } = this.props;
        const { checkboxesValues, totalAmount, selectedPayments, allowedPayementItems, allowedAccounts } = this.state;
        this.fetched = this.fetched || (!fetching && status && status.fetched);
        const noOptionText = UtilsI18n.get("selector.noOption.text");
        return (
            <React.Fragment>
                <section>
                    <Form id={Style.ID} noValidate="novalidate" className="col col-12 col-lg-6 col-md-9 col-sm-12">
                        <div className="form-section-title">
                            <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.initialData`)}</h3>
                            <div className="Select flex-container slideFromBottom has-value Select--single">
                                <div className="container-payment">
                                    <FieldLabel labelKey={`${FORM_ID}.documentType.label`} />
                                    <Field
                                        idForm={Style.ID}
                                        component={Selector}
                                        options={getRadioButtonOptions()}
                                        onChange={this.handleChangeDocumentType}
                                        name="documentType"
                                        renderAs="radio"
                                        inLineControl
                                    />

                                    <FieldLabel labelKey={`${FORM_ID}.documentNumber.label`} />
                                    {values.documentType === "DUA" ? (
                                        <Field
                                            idForm={FORM_ID}
                                            name="documentNumber"
                                            hidelabel
                                            placeholder={UtilsI18n.get(`${FORM_ID}.documentNumber.placeholder`)}
                                            type="text"
                                            component={TextField}
                                            renderAs={MaskedInput}
                                            className="form-control letter-spacing-max"
                                            keepCharPositions
                                            mask={[
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                "-",
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                "-",
                                                /\d/,
                                                /\d/,
                                            ]}
                                            showMask
                                        />
                                    ) : (
                                        <Field
                                            idForm={FORM_ID}
                                            name="documentNumber"
                                            hidelabel
                                            placeholder={UtilsI18n.get(`${FORM_ID}.documentNumber.placeholder`)}
                                            type="text"
                                            component={TextField}
                                            numbersOnly
                                        />
                                    )}

                                    <FieldLabel labelKey={`${FORM_ID}.currency.label`} />
                                    <Field
                                        idForm={Style.ID}
                                        component={Select}
                                        value={values.currency}
                                        options={getCurrencyOptions()}
                                        onChange={this.handleCurrencyChange}
                                        name="currency"
                                        className="flex-container slideFromBottom"
                                        optionClassName="needsclick"
                                        searchable
                                        inputProps={{ spellCheck: "false" }}
                                        noResultsText={noOptionText}
                                        creatable={false}
                                        clearable={false}
                                    />
                                    {touched.currency && errors.currency && !values.currency && (
                                        <FieldError error={errors.currency} />
                                    )}

                                    <FieldLabel labelKey={`${FORM_ID}.account.label`} />
                                    <Field
                                        idForm={Style.ID}
                                        component={Select}
                                        value={values.account}
                                        options={allowedAccounts}
                                        onChange={this.handleChangeAccount}
                                        name="account"
                                        className="flex-container slideFromBottom"
                                        optionClassName="needsclick"
                                        searchable
                                        inputProps={{ spellCheck: "false" }}
                                        noResultsText={noOptionText}
                                        creatable={false}
                                        clearable={false}
                                    />
                                    {touched.account && errors.account && !values.account && (
                                        <FieldError error={errors.account} />
                                    )}

                                    <Button
                                        key="primaryButton"
                                        variant="primary"
                                        bsStyle="primary"
                                        label={`${FORM_ID}.button.show`}
                                        loading={false}
                                        type="submit"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-section-title">
                            <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.table.title`)}</h3>
                            <div className="Select flex-container slideFromBottom has-value Select--single">
                                <PaymentList
                                    paymentItems={allowedPayementItems}
                                    selectPayment={this.selectPayment}
                                    checkboxesValues={checkboxesValues}
                                    totalAmount={totalAmount}
                                    currency={values.currency}
                                    formId={FORM_ID}
                                    fetched={this.fetched}
                                    mode={mode}
                                />
                            </div>
                        </div>
                        {selectedPayments.length > 0 && (
                            <div className="container-button-center">
                                <Button
                                    key="primaryButton"
                                    variant="primary"
                                    bsStyle="primary"
                                    label={`${FORM_ID}.button.accept`}
                                    loading={false}
                                    type="button"
                                    onClick={this.payItems}
                                />
                            </div>
                        )}
                    </Form>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    fetching: CustomsPaymentSelectors.getFetching(store),
    accounts: CustomsPaymentSelectors.getDebitAccountList(store),
    paymentItems: CustomsPaymentSelectors.getPayments(store),
    mode: CustomsPaymentSelectors.getMode(store),
    documentType: CustomsPaymentSelectors.getDocumentType(store),
    documentNumber: CustomsPaymentSelectors.getDocumentNumber(store),
    currency: CustomsPaymentSelectors.getCurrency(store),
    debitAccount: CustomsPaymentSelectors.getDebitAccount(store),
    selectedPayments: CustomsPaymentSelectors.getSelectedPayments(store),
    i18n: {},
});

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            documentNumber: props.documentNumber || EMPTY_STR,
            currency: props.currency || UYU,
            account: props.debitAccount || getFirstAccountValue(props.accounts),
            documentType: props.documentType || "DUA",
        }),
        validationSchema: () =>
            Yup.object().shape({
                documentNumber: Yup.string().required(UtilsI18n.get(`${FORM_ID}.documentNumber.required`)),
                currency: Yup.string().required(UtilsI18n.get(`${FORM_ID}.currency.required`)),
                account: Yup.string().required(UtilsI18n.get(`${FORM_ID}.account.required`)),
                documentType: Yup.string().required(UtilsI18n.get(`${FORM_ID}.documentType.required`)),
            }),
        handleSubmit: (data, formikBag) => {
            const { dispatch } = formikBag.props;
            if (data.selectedPayments) {
                dispatch(
                    CustomsPaymentActions.finishStep1({
                        selectedPayments: data.selectedPayments,
                        debitAccount: data.account,
                        currency: data.currency,
                    }),
                );
            } else {
                formikBag.setStatus({ fetched: true });
                dispatch(CustomsPaymentActions.listPaymentsRequest({ data, formikBag }));
            }
        },
    }),
)(Component);
