import { push as Push } from "connected-react-router";

import { Sagas as SagasPermissions } from "~/store/administration/common/permissions";

import { TYPE_ADMINISTRATION_LEVEL_SIMPLE_PERMISSION } from "./_consts";
import { SelectorsMiddleware, SelectorsAction } from "./_selectors";

export default [
    ...SagasPermissions(SelectorsMiddleware, TYPE_ADMINISTRATION_LEVEL_SIMPLE_PERMISSION, {
        ...SelectorsAction,
        onSuccess: (id) => Push(`/administration/simple/permissions/${id}/confirm`),
    }),
];
