import React from "react";

import { Form, withFormik as WithFormik, Field } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import { SCOPE, ONLY_NUMBER } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import { SelectorsStore, SelectorsAction } from "~/store/investment";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as UtilI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";
import TextField from "~/pages/_components/fields/TextField";

import Select from "../../settings/changePersonalInformation/field/Selector";
import Style from "./RequestInfo.rules.scss";
import Step2Preview from "./Step2Preview/Step2Preview";
import Step3View from "./Step3View/Step3View";

const FORM_ID = "wm.requestInfo";

export const NAME = "InvestMentRequestInfo";

export const PROP = {
    types: {
        ...TypesRedux,
        fetching: PropTypes.bool,
        dispatch: PropTypes.func.isRequired,
        values: PropTypes.object.isRequired,
        setFieldValue: PropTypes.func.isRequired,
    },
    defaults: {
        ...DefaultsRedux,
        fetching: false,
    },
};
export function Component({
    dispatch,
    fetching,
    isSubmitting,
    values,
    touched,
    errors,
    history,
    isChangingEnvironment,
    data: { contactFormList, contactTimeList, mode },
    ...rest
}) {
    React.useLayoutEffect(() => {
        if (!isChangingEnvironment) {
            dispatch(SelectorsAction.requestInfoPreRequest());
        }
        return () => {};
    }, [dispatch, isChangingEnvironment]);
    const handleBack = () => {
        if (mode === MODE.EDIT) {
            history.goBack();
        } else {
            dispatch(SelectorsAction.closePreview());
        }
    };

    return (
        <Container
            name={NAME}
            head-onBack={mode !== MODE.VIEW && handleBack}
            head-title="wm.requestInfo.title"
            wait={fetching}
            step={mode}
            scopeToShowNotification={SCOPE.REQUEST_INFO}>
            {/* Paso 1 */}
            {mode === MODE.EDIT && (
                <div id={Style.ID}>
                    <Form>
                        <div className="form--personal_information form--personal_information--seal">
                            <div className="form--personal_information--title">
                                <I18n id="wm.requestInfo.subtitle" />
                            </div>
                            <div className="container--data">
                                {/* Forma de contacto */}
                                <div className=" field-spacing">
                                    <Select
                                        idForm={FORM_ID}
                                        name="contactType"
                                        options={contactFormList}
                                        label={UtilI18n.get("wm.requestInfo.contactType")}
                                        valueKey="id"
                                        labelKey="name"
                                        {...rest}
                                    />
                                </div>

                                {/* Telefono */}
                                <div className="field-spacing field-phone">
                                    <Field
                                        idForm={FORM_ID}
                                        name="phone"
                                        component={TextField}
                                        autoFocus={false}
                                        mode={MODE.EDIT}
                                        hidePlaceholder
                                        pattern={ONLY_NUMBER}
                                    />
                                </div>

                                {/* Email */}
                                <div className="field-spacing field-phone">
                                    <Field
                                        idForm={FORM_ID}
                                        name="email"
                                        component={TextField}
                                        autoFocus={false}
                                        mode={MODE.EDIT}
                                        hidePlaceholder
                                    />
                                </div>

                                {/* Horario de contacto */}
                                <div
                                    className={
                                        touched.contactTime && errors.contactTime
                                            ? "field-spacing has-error-select"
                                            : "field-spacing "
                                    }>
                                    <Select
                                        idForm={FORM_ID}
                                        name="contactTime"
                                        options={contactTimeList}
                                        label={UtilI18n.get("wm.requestInfo.contactTime")}
                                        valueKey="id"
                                        labelKey="name"
                                        {...rest}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form--personal_information form--personal_information--bottom personal_info-cmb">
                            <div />
                            <div className="buttons-container">
                                <Button type="submit" size="md" variant="primary" disabled={isSubmitting}>
                                    <I18n id="wm.requestInfo.submit" />
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            )}

            {/* Paso 2 */}
            {mode === MODE.PREVIEW && <Step2Preview />}

            {/* Paso 3 */}
            {mode === MODE.VIEW && <Step3View />}
        </Container>
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Compose(
    Connect((store) => ({
        fetching: SelectorsStore.getFetching(store),
        data: SelectorsStore.getRequestInfoData(store),
        isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    })),
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: ({ data: { mobilePhone, email, submitData } }) => {
            return {
                contactType: submitData.contactType || "phone",
                phone: submitData.phone || mobilePhone || "",
                email: submitData.email || email,
                contactTime: submitData.contactTime || "",
            };
        },
        validationSchema: () => {
            return Yup.lazy((values) => {
                return Yup.object().shape({
                    contactType: Yup.string().required(UtilI18n.get(`${FORM_ID}.emptyContactForm`)),
                    phone:
                        values && values.contactType === "phone"
                            ? Yup.string().required(UtilI18n.get(`${FORM_ID}.phoneEmpty`))
                            : Yup.string(),
                    email:
                        values && values.contactType === "email"
                            ? Yup.string().required(UtilI18n.get(`${FORM_ID}.emailEmpty`))
                            : Yup.string(),
                    contactTime: Yup.string().required(UtilI18n.get(`${FORM_ID}.emptyContactTime`)),
                });
            });
        },
        handleSubmit: (submitData, formikBag) => {
            const { dispatch } = formikBag.props;

            formikBag.setSubmitting(false);

            dispatch(SelectorsAction.requestInfoPreview({ submitData, formikBag }));
        },
    }),
)(React.memo(Component));
