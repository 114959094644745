import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";

import HighOrder from "~/components/HighOrder";
import ListItemMobile from "~/pages/_components/listItem/ListItemMobile";

export const NAME = "ListItem";

export const PROP = {
    types: {
        path: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                pathname: PropTypes.string,
                search: PropTypes.string,
            }),
        ]).isRequired,
        name: PropTypes.string,
        title: PropTypes.string.isRequired,
        reference: PropTypes.string,
        currency: PropTypes.string,
        amount: PropTypes.number.isRequired,
        amountLabel: PropTypes.string,
        icon: PropTypes.string,
        expirationDate: PropTypes.string,
        expiredText: PropTypes.string,
        expirationText: PropTypes.string,
        isExpired: PropTypes.bool,
    },
    defaults: {
        icon: EMPTY_STR,
        amountLabel: EMPTY_STR,
        expirationDate: EMPTY_STR,
        expiredText: EMPTY_STR,
        expirationText: EMPTY_STR,
        isExpired: false,
        name: EMPTY_STR,
        reference: EMPTY_STR,
        currency: EMPTY_STR,
    },
};
export function Component(props) {
    const { widget, isDesktop, ...rest } = props;
    return <ListItemMobile {...rest} />;
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default HighOrder.Resizable(Component);
