import React from "react";

import { connect as Connect } from "react-redux";

import { CDP_CURRENCY, DATA_NOT_AVAILABLE, EMPTY_STR } from "~/constants";
import {
    SelectorsStore as SelectorsStoreSuppliers,
    SelectorsAction as SelectorsActionSuppliers,
} from "~/store/factoring";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import UtilFlag from "~/util/flag";
import { generateId as GenID } from "~/util/general";
import * as i18nUtils from "~/util/i18n";

import Box from "~/components/Box";
import Element from "~/components/Component";
import ExpandElement from "~/components/ExpandElement";
import GridLayout from "~/components/GridLayout";
import Image from "~/components/Image";
import IntersectionObserver from "~/components/IntersectionObserver";

import { NAME_NOTE, TYPE_NOTE } from "../_index.scss";
import Style from "./_Suppliers.rules.scss";

export const { NAME, TYPE } = Style;

export const PROP = {
    types: {},
    defaults: {
        i18n: {
            loading: "Cargando",
            notAvailable: DATA_NOT_AVAILABLE,
            labelMore: "Más movimientos",
            labelNoMore: "No hay más movimientos",
            document: "Document",
            email: "E-mail",
            contact: "Contact",
        },
    },
};

export function Component(props) {
    const { dispatch, suppliers, moreLines, fetching, i18n } = props;
    const { loading, labelMore, labelNoMore, noRecords } = i18n;

    const recordsFound = suppliers.length > 0;

    const COLUMN_HEADINGS = [i18n.code, i18n.name, i18n.wayToPay, i18n.accountAndCurrency, EMPTY_STR];

    return (
        <IntersectionObserver onIntersect={handleMovementsFetch}>
            <GridLayout className="header">
                {COLUMN_HEADINGS.map((heading, index) => (
                    <Box key={GenID()} justify={index === 3 ? "end" : EMPTY_STR}>
                        {heading}
                    </Box>
                ))}
            </GridLayout>
            {recordsFound ? (
                suppliers.map(
                    ({
                        code,
                        name,
                        paymentType,
                        account,
                        currency,
                        detail: { documentType, documentNumber, email, contactPerson },
                    }) => {
                        const currencyLabel =
                            currency !== Number(CDP_CURRENCY.UI)
                                ? i18nUtils.get(`currency.label.${currency}`)
                                : `${i18nUtils.get(`currency.label.${CDP_CURRENCY.UYU}`)} | ${i18nUtils.get(
                                      `currency.label.${CDP_CURRENCY.USD}`,
                                  )}`;
                        return (
                            <ExpandElement key={`suppliers-${GenID()}`}>
                                <GridLayout>
                                    <div>{code}</div>
                                    <div>{name}</div>
                                    <div>{i18nUtils.get(`client.factoring.suppliers.paymentType.${paymentType}`)}</div>
                                    <Box justify="end">
                                        {currency.toString() === CDP_CURRENCY.UI ? (
                                            <React.Fragment>
                                                <Image src={`${UtilFlag.getFlag(CDP_CURRENCY.UYU)}.svg`} />
                                                <Image src={`${UtilFlag.getFlag(CDP_CURRENCY.USD)}.svg`} />
                                            </React.Fragment>
                                        ) : (
                                            <Image src={`${UtilFlag.getFlag(`${currency.toString()}`)}.svg`} />
                                        )}
                                        {currencyLabel} {account}
                                    </Box>
                                </GridLayout>
                                <GridLayout>
                                    <div>
                                        <label>{`${i18n.document}:`}</label>
                                        {documentType} {documentNumber}
                                    </div>
                                    <div>
                                        <label>{`${i18n.email}:`}</label>
                                        {email}
                                    </div>
                                    <div>
                                        <label>{`${i18n.currency}:`}</label>
                                        {currencyLabel}
                                    </div>
                                    <div>
                                        <label>{`${i18n.contact}:`}</label>
                                        {contactPerson}
                                    </div>
                                </GridLayout>
                            </ExpandElement>
                        );
                    },
                )
            ) : (
                <Note value={noRecords} className="no-more-movements" />
            )}
            {fetching && <Note value={loading} />}
            {!fetching && moreLines && (
                <Note
                    onClick={() => handleMovementsFetch({ isIntersecting: true })}
                    intersection-trigger="true"
                    key="more"
                    value={labelMore}
                />
            )}

            {!fetching && !moreLines && recordsFound && (
                <Note
                    value={labelNoMore}
                    onClick={() => handleMovementsFetch({ isIntersecting: false })}
                    className="no-more-movements"
                />
            )}
        </IntersectionObserver>
    );

    function handleMovementsFetch(ev) {
        const { isIntersecting } = ev;
        if (!isIntersecting) {
            return;
        }
        dispatch(SelectorsActionSuppliers.loadListSuppliersRequest({ moreOrders: true }));
    }

    function Note({ value, ...rest }) {
        return (
            <Element type={TYPE_NOTE} name={NAME_NOTE} tag="span" {...rest}>
                {value}
            </Element>
        );
    }
}
const mapStateToProps = (store) => ({
    suppliers: SelectorsStoreSuppliers.getSuppliers(store),
    fetching: SelectorsStoreSuppliers.isFetching(store),
    moreLines: SelectorsStoreSuppliers.getMoreLines(store),

    i18n: {
        notAvailable: StoreI18n.getMessage(store, "data.notAvailable.short"),
        loading: StoreI18n.getMessage(store, "global.loading"),
        noRecords: StoreI18n.getMessage(store, "global.noRecords"),
        labelMore: StoreI18n.getMessage(store, "list.suppliers.moreSuppliers"),
        labelNoMore: StoreI18n.getMessage(store, "list.suppliers.noMoreSuppliers"),
        document: StoreI18n.getMessage(store, "list.suppliers.document.label"),
        email: StoreI18n.getMessage(store, "list.suppliers.email.label"),
        contact: StoreI18n.getMessage(store, "list.suppliers.contact.label"),
        currency: StoreI18n.getMessage(store, "list.suppliers.currency.label"),
        code: StoreI18n.getMessage(store, "client.factoring.suppliers.list.supplierCode"),
        name: StoreI18n.getMessage(store, "client.factoring.suppliers.list.supplierName"),
        wayToPay: StoreI18n.getMessage(store, "client.factoring.suppliers.list.type"),
        accountAndCurrency: StoreI18n.getMessage(store, "client.factoring.suppliers.list.account"),
    },
});
export default Connect(mapStateToProps)(Component);
