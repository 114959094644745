import React from "react";

import { replace as Replace } from "connected-react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { SPACE_STR, SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/Simple";
import { SelectorsAction as SelectorsActionSettings, SelectorsStore as SelectorsStoreSettings } from "~/store/settings";
import { getArray } from "~/util/config";
import * as i18n from "~/util/i18n";

import I18n from "~/components/I18n";
import SwitchField from "~/pages/_components/fields/SwitchField";

import Style from "./NotificationsConfigurationTransports.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        communicationTransportsConfigurations: PropTypes.oneOfType([PropTypes.array]),
        match: PropTypes.shape({ path: PropTypes.string }),
        fetching: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    },
    defaults: { fetching: false, communicationTransportsConfigurations: [], match: {} },
};
export function Component(props) {
    const {
        fetching,
        dispatch,
        match: {
            params: { communicationType },
        },
    } = props;

    React.useEffect(() => {
        dispatch(SelectorsActionSettings.communicationTypePre({ communicationType }));
    }, [communicationType, dispatch]);

    return (
        <Container
            name={Style.NAME}
            className={Style.CLASS}
            head-title="settings.notificationsConfiguration"
            wait={fetching}
            head-onClose={handleClose}
            head-onBack={handleBack}
            scopeToShowNotification={SCOPE.SETTINGS_NOTIFICATIONS_CONFIGURATION_MODIFY}>
            {getContent()}
        </Container>
    );

    function handleClose() {
        const pathname = "/settings";
        dispatch(
            Replace({
                pathname,
            }),
        );
    }

    function handleBack() {
        const { history } = props;
        history.goBack();
    }

    function toggleSwitch({ target }, id) {
        dispatch(
            SelectorsActionSettings.modifyNotificationConfigurations({
                notificationType: communicationType,
                transport: id,
                subscribed: target.value,
            }),
        );
    }

    function renderDefaultTransports(defaultTransports) {
        return (
            defaultTransports.length > 0 && (
                <p>
                    <I18n id="communications.communicationsTypes.defaultTransports" />
                    {SPACE_STR}
                    {Object.keys(defaultTransports).map((defaultTransportIndex) => {
                        const key = defaultTransports[defaultTransportIndex];
                        return (
                            <strong key={key}>
                                <I18n
                                    id={`userInfo.preferences.notificationsConfiguration.${key.toLowerCase()}.label`}
                                />
                            </strong>
                        );
                    })}
                </p>
            )
        );
    }

    function renderCommunicationTransportsConfigurations(defaultTransports) {
        const { communicationTransportsConfigurations } = props;
        return Object.entries(communicationTransportsConfigurations.subscriptions).map(
            ([transportConfigurationKey, transportConfigurationValue]) => {
                const transportConfigurationName = transportConfigurationKey;

                if (!defaultTransports.includes(transportConfigurationName.toUpperCase())) {
                    return (
                        <SwitchField
                            name={transportConfigurationName}
                            key={transportConfigurationName}
                            label={i18n.get(
                                `userInfo.preferences.notificationsConfiguration.${transportConfigurationName}.label`,
                            )}
                            value={transportConfigurationValue}
                            onChange={(event) => toggleSwitch(event, transportConfigurationName)}
                        />
                    );
                }

                return null;
            },
        );
    }

    function getContent() {
        const { communicationTransportsConfigurations } = props;
        const defaultTransports = getArray(`core.communications.communicationTypes.${communicationType}.default`);
        const defaultTransportsUpper = defaultTransports.map((item) => item.toUpperCase());
        const comunicationType = i18n.get(`communications.communicationsTypes.${communicationType}`);
        return (
            communicationTransportsConfigurations && (
                <section>
                    <p className="message">
                        <I18n id="settings.notificationsConfiguration.description.base" />{" "}
                        <strong>{comunicationType.toLowerCase()}</strong>
                    </p>
                    <div className="options">{renderCommunicationTransportsConfigurations(defaultTransportsUpper)}</div>
                    {renderDefaultTransports(defaultTransportsUpper)}
                </section>
            )
        );
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (state) => ({
    communicationTransportsConfigurations: SelectorsStoreSettings.getCommunicationTransportsConfigurations(state),
    fetching: SelectorsStoreSettings.getFetching(state),
});

export default withRouter(connect(mapStateToProps)(Component));
