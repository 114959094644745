import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";

import { CDP_CURRENCY, LOAN_TYPES, NO_DATA, SLASH } from "~/constants";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { SelectorsStore as SelectorsStoreLoan } from "~/store/loan";
import Device from "~/util/device";
import { generateId as GenerateId } from "~/util/general";
import { fixNumberAsStringPrecision, formatNumber } from "~/util/number";

import { Resizable } from "~/components/HighOrder";
import I18n from "~/components/I18n";

import FormattedAmount from "~/pages/_components/FormattedAmount";
import StateItems from "~/pages/loans/_components/InformationItem";

import Style from "./Information.rules.scss";

export const NAME = "Information";

export const PROP = {
    types: {
        lang: PropTypes.string.isRequired,
        isMobile: PropTypes.bool.isRequired,
        loan: PropTypes.shape({
            idProduct: PropTypes.string.isRequired,
            productAlias: PropTypes.string,
            number: PropTypes.string.isRequired,
            interestRate: PropTypes.number.isRequired,
            information: PropTypes.shape({
                productType: PropTypes.number,
                accountCurrency: PropTypes.string,
                capital: PropTypes.number,
                startDate: PropTypes.string,
                dueDate: PropTypes.string,
                operationNumber: PropTypes.string,
                rate: PropTypes.number,
                installmentAmount: PropTypes.number,
                installmentAmountUI: PropTypes.string,
                lastInstallmentPaid: PropTypes.string,
                nextInstallment: PropTypes.string,
                interests: PropTypes.number,
                overdue: PropTypes.string,
                total: PropTypes.number,
            }).isRequired,
        }).isRequired,
    },
    defaults: {
        isMobile: false,
    },
};

export function Component(props) {
    const { loan, isMobile } = props;
    const { information } = loan;
    const { installmentAmountUI } = information;
    const i18nPrefix = "loans.information";

    const BALANCE = "balance";
    const BCU_RATE = "BCURate";
    const UNPAID_AMOUNT_DUE = "unpaidAmountDue";
    const CAPITAL = "capital";
    const CURRENT_RATE = "currentRate";
    const DESCRIPTION = "description";
    const DUE_DATE = "dueDate";
    const INSTALLMENT_AMOUNT = "installmentAmount";
    const INTERESTS_UPON_MADURITY = "interestsUponMadurity";
    const INTERESTS = "interests";
    const INTEREST_ARREARS = "interestArrears";
    const LAST_INSTALLMENT_PAID = "lastInstallmentPaid";
    const NEXT_DUE_DATE = "nextDueDate";
    const NEXT_INTEREST_PAYMENT = "nextInterestPayment";
    const NEXT_INSTALLMENT = "nextInstallment";
    const NEXT_PRINCIPAL_INSTALLMENT = "nextPrincipalInstallment";
    const OPERATION_NUMBER = "operationNumber";
    const NUMBER_INSTALLMENTS = "numberInstallments";
    const RATE = "rate";
    const START_DATE = "startDate";
    const TOTAL = "total";
    const ADMINISTRATIVE_EXPENSES = "administrativeExpenses";
    const TOTAL_TO_PAY = "totalToPay";

    const HIGHLIGHTED_ITEMS = [CAPITAL, INSTALLMENT_AMOUNT, TOTAL];

    const buildItem = (labelPostFix, labelAux) => (
        <StateItems key={GenerateId()} label={`${i18nPrefix}.${labelPostFix}`} labelAux={labelAux} />
    );

    const buildAmountItem = (labelPostFix, currency, quantity, quantityNote) => (
        <StateItems
            key={GenerateId()}
            label={`${i18nPrefix}.${labelPostFix}`}
            currency1={currency}
            quantity1={quantity}
            quatity1Note={quantityNote ? `(${quantityNote})` : null}
            highlight={!buildCommonsLoans && (HIGHLIGHTED_ITEMS.find((label) => label === labelPostFix))}
        />
    );

    const buildLoanCommonsAmountItem = (labelPostFix, currency, quantity, quantityNote) => (
        <StateItems
            key={GenerateId()}
            label={`${i18nPrefix}.${labelPostFix}`}
            currency1={currency}
            quantity1={quantity === 0 ? "0,00" : quantity}
            quatity1Note={quantityNote ? `(${quantityNote})` : null}
        />
    );

    const buildLoanCommonsAmountItemTooltip = (labelPostFix, currency, quantity) => (
        <section className="tooltip-item">
                <StateItems
                    key={GenerateId()}
                    label={`${i18nPrefix}.${labelPostFix}`}
                    currency1={currency}
                    quantity1={quantity}
                />
                <div className="tooltip tooltip-item">
                    <div className="tooltip-text">
                        <I18n id="loans.information.administrativeExpenses.tooltip"/>
                    </div>
                </div>
        </section>
    );

    const buildLoanCommonsTotalTooltip = (labelPostFix, currency, quantity, currencyExpenses, expenses) => (
        <section className="total-container">
            <div className="total-amount-to-pay">
                <StateItems
                    key={GenerateId()}
                    label={`${i18nPrefix}.${labelPostFix}`}
                    currency1={currency}
                    quantity1={quantity}
                    highlight={HIGHLIGHTED_ITEMS.find((label) => label === labelPostFix)}
                />
                {quantity > 0 && !isMobile  &&
                    <div className="tooltip">
                        <div className="tooltip-text">
                            <p><I18n id="loans.information.total.tooltip.one"/></p>
                            <p>
                                <I18n id="loans.information.total.tooltip.two"/>
                                <FormattedAmount quantity={expenses} currency={currencyExpenses} />
                            </p>
                            <p><I18n id="loans.information.total.tooltip.three"/></p>
                        </div>
                    </div>
                }
            </div>
            {quantity > 0 && isMobile  &&
                <div className="disclaimer">
                    <p>
                        <I18n id="loans.information.total.tooltip.oneTwo.mobile"/>
                        <FormattedAmount quantity={expenses} currency={currencyExpenses} />
                    </p>
                    <p><I18n id="loans.information.total.tooltip.three"/></p>
                </div>
            }
        </section>
    );

    const buildFixedTerm = () => {
        const listItem = [];

        const {
            rate,
            accountCurrency,
            capital,
            startDate,
            description,
            dueDate,
            operationNumber,
            balance,
            nextDueDate,
            interestsUponMadurity,
        } = information;

        listItem.push(buildItem(DESCRIPTION, description));
        listItem.push(buildAmountItem(BALANCE, accountCurrency, balance, null));
        listItem.push(buildItem(DUE_DATE, dueDate));
        listItem.push(buildItem(RATE, `${fixNumberAsStringPrecision(rate)} %`));
        listItem.push(buildItem(NEXT_DUE_DATE, nextDueDate));
        listItem.push(buildAmountItem(INTERESTS_UPON_MADURITY, accountCurrency, interestsUponMadurity, null));
        listItem.push(buildAmountItem(CAPITAL, accountCurrency, capital, null));
        listItem.push(buildItem(START_DATE, startDate));
        listItem.push(buildItem(OPERATION_NUMBER, operationNumber));

        return listItem;
    };

    const buildCommonsLoans = () => {
        const listItem = [];
        const { lang } = props;
        const {
            accountCurrency,
            BCURateBankCash,
            BCURateFinancialSystem,
            BCURateOverdueBalances,
            capital,
            description,
            dueDate,
            paidInstalments,
            installmentAmount,
            interests,
            lastInstallmentPaid,
            nextInstallment,
            rate,
            startDate,
            total,
            totalArrears,
            totalDollarPackage,
            totalExpiredPesosPackages,
            totalExpiredDollarPackages,
            totalInstalments,
            totalPesosPackage,
            totalToPay,
        } = information;

        const rateBCU = BCURateBankCash + BCURateFinancialSystem;
        const administrativeExpensesPesos = (totalPesosPackage + totalExpiredPesosPackages);
        const administrativeExpensesDollar = (totalDollarPackage + totalExpiredDollarPackages);
        const expenses = administrativeExpensesPesos > 0 ? administrativeExpensesPesos : administrativeExpensesDollar;
        const currencyExpenses = (administrativeExpensesPesos > 0 ? CDP_CURRENCY.UYU : CDP_CURRENCY.USD);
        const administrativeExpenses = (administrativeExpensesPesos === 0 && administrativeExpensesDollar === 0) ? formatNumber(0, lang) : expenses;
        const dateLastPaid = lastInstallmentPaid ? lastInstallmentPaid : NO_DATA;
        const currencyAdministrativeExpenses = (administrativeExpensesPesos === 0 && administrativeExpensesDollar === 0) ? CDP_CURRENCY.UYU : currencyExpenses;

        if ( !isMobile ) {    
            listItem.push(buildItem(DESCRIPTION, description));
            listItem.push(buildLoanCommonsAmountItem(TOTAL, accountCurrency, total, null));
            listItem.push(buildItem(START_DATE, startDate));
            listItem.push(buildLoanCommonsAmountItem(INSTALLMENT_AMOUNT, accountCurrency, installmentAmount, installmentAmountUI));
            listItem.push(buildLoanCommonsAmountItem(INTERESTS, accountCurrency, interests, null));
            listItem.push(buildItem(DUE_DATE, dueDate));
            listItem.push(buildLoanCommonsAmountItem(CAPITAL, accountCurrency, capital, null));
            listItem.push(buildLoanCommonsAmountItem(INTEREST_ARREARS, accountCurrency, totalArrears, null));
            listItem.push(buildItem(LAST_INSTALLMENT_PAID, dateLastPaid));
            listItem.push(buildItem(NUMBER_INSTALLMENTS, <span>{paidInstalments}{SLASH}{totalInstalments}</span>));
            listItem.push(buildLoanCommonsAmountItem(BCU_RATE, accountCurrency, rateBCU, null));
            listItem.push(buildItem(NEXT_INSTALLMENT, nextInstallment));
            listItem.push(buildItem(RATE, `${fixNumberAsStringPrecision(rate)} %`));
            listItem.push(buildLoanCommonsAmountItemTooltip(ADMINISTRATIVE_EXPENSES, currencyAdministrativeExpenses, administrativeExpenses));
            listItem.push(buildLoanCommonsAmountItem(UNPAID_AMOUNT_DUE, accountCurrency, BCURateOverdueBalances, null));
            listItem.push(buildLoanCommonsTotalTooltip(TOTAL_TO_PAY, accountCurrency, totalToPay, currencyAdministrativeExpenses, administrativeExpenses));
        } else {
            listItem.push(buildItem(DESCRIPTION, description));
            listItem.push(buildLoanCommonsAmountItem(INSTALLMENT_AMOUNT, accountCurrency, installmentAmount, installmentAmountUI));
            listItem.push(buildLoanCommonsAmountItem(CAPITAL, accountCurrency, capital, null));
            listItem.push(buildItem(NUMBER_INSTALLMENTS, <span>{paidInstalments}{SLASH}{totalInstalments}</span>));
            listItem.push(buildItem(RATE, `${fixNumberAsStringPrecision(rate)} %`));
            listItem.push(buildLoanCommonsAmountItem(TOTAL, accountCurrency, total, null));
            listItem.push(buildLoanCommonsAmountItem(INTERESTS, accountCurrency, interests, null));
            listItem.push(buildLoanCommonsAmountItem(INTEREST_ARREARS, accountCurrency, totalArrears, null));
            listItem.push(buildLoanCommonsAmountItem(BCU_RATE, accountCurrency, rateBCU, null));
            listItem.push(buildLoanCommonsAmountItemTooltip(ADMINISTRATIVE_EXPENSES, currencyAdministrativeExpenses, administrativeExpenses));
            listItem.push(buildItem(START_DATE, startDate));
            listItem.push(buildItem(DUE_DATE, dueDate));
            listItem.push(buildItem(LAST_INSTALLMENT_PAID, dateLastPaid));
            listItem.push(buildItem(NEXT_INSTALLMENT, nextInstallment));
            listItem.push(buildLoanCommonsAmountItem(UNPAID_AMOUNT_DUE, accountCurrency, BCURateOverdueBalances, null));
            listItem.push(buildLoanCommonsTotalTooltip(TOTAL_TO_PAY, accountCurrency, totalToPay, currencyAdministrativeExpenses, administrativeExpenses));
        }

        return listItem;
    };

    const buildAmortizing = () => {
        const listItem = [];

        const {
            accountCurrency,
            balance,
            currentRate,
            description,
            nextPrincipalInstallment,
            nextInterestPayment,
            nextDueDate,
            capital,
            startDate,
            dueDate,
            operationNumber,
        } = information;

        listItem.push(buildItem(DESCRIPTION, description));
        listItem.push(buildAmountItem(BALANCE, accountCurrency, balance, installmentAmountUI));
        listItem.push(buildItem(CURRENT_RATE, `${fixNumberAsStringPrecision(currentRate)} %`));
        listItem.push(buildAmountItem(NEXT_PRINCIPAL_INSTALLMENT, accountCurrency, nextPrincipalInstallment, null));
        listItem.push(buildAmountItem(NEXT_INTEREST_PAYMENT, accountCurrency, nextInterestPayment, null));
        listItem.push(buildItem(NEXT_DUE_DATE, nextDueDate));
        listItem.push(buildItem(OPERATION_NUMBER, operationNumber));
        listItem.push(buildItem(START_DATE, startDate));
        listItem.push(buildAmountItem(CAPITAL, accountCurrency, capital, null));
        listItem.push(buildItem(DUE_DATE, dueDate));

        return listItem;
    };

    const getListItem = () => {
        let listItem = [];

        const { productType } = information;

        switch (productType) {
            case LOAN_TYPES.AUTOMOBILE:
            case LOAN_TYPES.CONSUMER:
            case LOAN_TYPES.LOAN:
                listItem = buildCommonsLoans();
                break;
            case LOAN_TYPES.FIXED_TERM:
                listItem = buildFixedTerm();
                break;
            case LOAN_TYPES.AMORTIZING:
                listItem = buildAmortizing();
                break;
            default:
                break;
        }

        return listItem;
    };

    const uiNote = !Device.isDisplayDesktop() && installmentAmountUI && (
        <section className="centered">
            <I18n id="product.ui.note" className="data-legend" />
        </section>
    );

    return (
        <React.Fragment>
            {uiNote}
            {/* TODO: revisar si esta bien ya que los datos son fijos */}
            <div id={buildCommonsLoans && Style.ID} className="items">{getListItem().map((item) => item)}</div>
        </React.Fragment>
    );
}

const mapStateToProps = (store) => ({
    loan: SelectorsStoreLoan.getSelectedLoan(store),
    lang: SelectorsStoreI18n.getLang(store),
});

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Compose(Connect(mapStateToProps), Resizable)(Component);
