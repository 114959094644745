import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import {
    SelectorsStore as SelectorsStoreUploadSuppliers,
    SelectorsAction as SelectorsActionUploadSuppliers,
} from "~/store/factoring/uploadSuppliers";

import I18n from "~/components/I18n";
import Ticket from "~/components/Ticket/Ticket";

import UploadSuppliersDisplayList from "../_components/UploadSuppliersDisplayList";
import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        transaction: PropTypes.shape({
            idTransaction: PropTypes.string,
            idTransactionStatus: PropTypes.string,
            valueDateAsString: PropTypes.string,
            executing: PropTypes.bool,
        }).isRequired,
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const { transaction, ticketConfirmation } = props;
    return (
        <section id={Style.ID}>
            <Ticket
                ticketConfirmation={ticketConfirmation}
                transaction={transaction}
                hasMobileComponent
                handleReject={handleReject}
                handleSign={handleSign}>
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="client.factoring.uploadSuppliers.transaction.step3.extraData" />
                    </span>
                    <UploadSuppliersDisplayList />
                </div>
            </Ticket>
        </section>
    );

    function handleReject(formikBag) {
        const { dispatch } = props;
        const { idTransaction } = transaction;
        dispatch(
            SelectorsActionUploadSuppliers.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        const { dispatch } = props;
        const suppliersList = transaction && transaction.data ? transaction.data.suppliersList : null;
        dispatch(SelectorsActionUploadSuppliers.signTransactionPreview({ suppliersList, transaction }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreUploadSuppliers.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
