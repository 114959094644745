import React from "react";

import ClassNames from "classnames";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, DASH } from "~/constants";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import UtilsDevice from "~/util/device";
import * as UtilsI18n from "~/util/i18n";
import * as UtilsNumber from "~/util/number";

import Box from "~/components/Box";
import DataDate from "~/components/DataDate";
import DataNumber from "~/components/DataNumber";
import ExpandElement from "~/components/ExpandElement";
import GridLayout from "~/components/GridLayout";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";

import Style from "./_advancePaymentLines.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        list: PropTypes.array.isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const [isMobile, setIsMobile] = React.useState(UtilsDevice.isDisplayMobile());

    React.useEffect(() => {
        const widthChangeListener = () => setIsMobile(UtilsDevice.isDisplayMobile());
        window.addEventListener("resize", widthChangeListener);
        return () => {
            window.removeEventListener("resize", widthChangeListener);
        };
    }, []);

    const { i18n, list } = props;

    const COLUMN_HEADINGS = (isMobile && [
        i18n.companyLabel,
        i18n.headingDueDate,
        i18n.labelDiscountableAmount,
        EMPTY_STR,
    ]) || [i18n.companyLabel, i18n.labelOrder, i18n.headingDueDate, i18n.labelDiscountableAmount, EMPTY_STR];

    return (
        <div id={Style.ID}>
            <GridLayout className="header">
                {COLUMN_HEADINGS.map((heading, index) => (
                    <Box justify={index === 3 && "end"}> {heading}</Box>
                ))}
            </GridLayout>
            {renderListItems()}
        </div>
    );

    function renderListItems() {
        return list.map(
            ({
                amount,
                companyName,
                currency,
                dueDate,
                interests,
                netAmount,
                paymentOrder,
                rate,
                returnCode,
                term,
            }) => {
                return (
                    <ExpandElement>
                        {renderItem(companyName, currency, paymentOrder, dueDate, netAmount)}
                        <GridLayout className={returnCode > 0 ? "bg-error" : "detail"}>
                            {isMobile && (
                                <div>
                                    <label>{i18n.labelOrder}:</label>
                                    <span>{paymentOrder}</span>
                                </div>
                            )}
                            {!returnCode > 0 && (
                                <div>
                                    <label>
                                        {UtilsI18n.get("client.factoring.advancePaymentOrders.list.interests")}
                                    </label>
                                    {DASH}{interests}
                                </div>
                            )}
                            {!returnCode > 0 && (
                                <div>
                                    <label>{i18n.labelRate}</label>
                                    {UtilsNumber.numberToLocaleFormat(rate, UtilsI18n.getLang())}%
                                </div>
                            )}
                            {!returnCode > 0 && (
                                <div>
                                    <label>{i18n.labelTerm}</label>
                                    {term}
                                </div>
                            )}
                            <div>
                                <label>{UtilsI18n.get("client.factoring.advancePaymentOrders.list.orderAmount")}</label>
                                <DataNumber value={amount} prefix={currency} className="amount" />
                            </div>
                            {returnCode > 0 && (
                                <label className="error-message">
                                    <I18n id={`client.factoring.advancePayments.error.${returnCode}`} />
                                </label>
                            )}
                        </GridLayout>
                    </ExpandElement>
                );
            },
        );
    }

    function renderItem(companyName, currency, paymentOrder, dueDate, netAmount) {
        return (
            <GridLayout>
                <div className={ClassNames({ "data-wrapper": isMobile })}>
                    <span className={ClassNames({ "data-name": isMobile })}>{companyName}</span>
                </div>
                {!isMobile && (
                    <div className={ClassNames({ "data-wrapper": isMobile })}>
                        <span className={ClassNames({ "data-name": isMobile })}>{paymentOrder}</span>
                    </div>
                )}
                <div className={ClassNames({ "data-wrapper": isMobile })}>
                    <span className={ClassNames({ "data-name": isMobile })}>
                        <DataDate value={dueDate} />
                    </span>
                </div>
                <Box className={ClassNames({ "data-wrapper": isMobile })} justify={!isMobile && "end"}>
                    <DataNumber className={ClassNames({ "data-name": isMobile })} value={netAmount} prefix={currency} />
                </Box>
            </GridLayout>
        );
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        i18n: {
            companyLabel: StoreI18n.getMessage(store, "transaction.ticket.companyName"),
            labelRate: StoreI18n.getMessage(store, "listAdvancePayments.list.detail.rate"),
            labelDiscountableAmount: StoreI18n.getMessage(
                store,
                "client.factoring.advancePaymentOrders.transaction.netAmount",
            ),
            labelTerm: StoreI18n.getMessage(store, "listAdvancePayments.list.detail.term"),
            labelOrder: StoreI18n.getMessage(store, "client.factoring.advancePaymentOrders.transaction.step3.order"),
            headingDueDate: StoreI18n.getMessage(store, "listAdvancePayments.list.header.dueDate"),
        },
    };
};

export default HighOrder(Connect(mapStateToProps))(Component);
