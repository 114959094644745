import React from "react";

import { Field } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { ENVIRONMENT_TYPE, SPACE_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreForm } from "~/store/form";
import { SelectorsStore as StoreSession } from "~/store/session";
import * as ConfigUtils from "~/util/config";
import * as UtilsI18n from "~/util/i18n";
import { formatNumber as FormatNumber } from "~/util/number";

import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import FormattedRate from "~/pages/_components/FormattedRate";
import Selector from "~/pages/_components/fields/formik/Selector";

import Style from "./TransferLocal.rules.scss";

export const { NAME, CLASS } = Style;

let confTxt;

const FORM_ID = "transferLocal";

export const PROP = {
    types: {
        previewData: PropTypes.shape({
            amount: PropTypes.shape({
                currency: PropTypes.string,
                quantity: PropTypes.number,
            }).isRequired,
            creditCardAlias: PropTypes.string,
            creditCardMark: PropTypes.string,
            debitAccountAlias: PropTypes.string,
            debitAmount: PropTypes.shape({
                currency: PropTypes.string,
                quantity: PropTypes.number,
            }).isRequired,
            manualRevisionWarning: PropTypes.bool.isRequired,
            transferWithoutFundsWarning: PropTypes.bool,
            rate: PropTypes.number,
            rateFirstCurrency: PropTypes.string,
            rateSecondCurrency: PropTypes.string,
        }).isRequired,
        metaData: PropTypes.shape({
            fieldList: PropTypes.arrayOf(PropTypes.object),
        }).isRequired,
        values: PropTypes.object.isRequired,
    },
    defaults: {},
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        const {
            formikProps: { setFieldValue },
            previewData,
            transaction,
        } = this.props;
        const { isOnlineEnabled } = previewData;

        const options = this.buildOptions();
        const defaultOption = ConfigUtils.get("client.transactions.online.defaultExecutionType");
        let optionChecked = null;

        if ((defaultOption !== null && defaultOption !== "none") || !isOnlineEnabled) {
            for (let i = 0; i < options.length; i += 1) {
                const elem = options[i];

                if (!elem.disabled && elem.id === defaultOption) {
                    optionChecked = elem.id;
                    break;
                }
            }
        }

        if (optionChecked) {
            setFieldValue("executionType", optionChecked);
        } else {
            const allDisabled = this.allDisabled(options);
            if (allDisabled) {
                if (transaction && transaction.data && transaction.data._executionType) {
                    setFieldValue("executionType", transaction.data._executionType);
                } else {
                    setFieldValue("executionType", "deferred");
                }
            }
        }
    }

    allDisabled = (options) => {
        let allDisabled = true;
        for (let j = 0; j < options.length; j += 1) {
            const elem = options[j];
            if (!elem.disabled) {
                allDisabled = false;
            }
        }
        return allDisabled;
    };

    buildExtraText = (commission) => {
        const showCommission = ConfigUtils.getBoolean("client.transactions.online.showCommission");

        if (!showCommission) {
            return undefined;
        }

        const { currentLang } = this.props;
        const { currency, quantity } = commission || {};

        const params = {
            commission: !commission
                ? UtilsI18n.get("transfer.executionType.cost.notAvailable.label")
                : UtilsI18n.get(`currency.label.${currency}`) + SPACE_STR + FormatNumber(quantity, currentLang),
        };

        return UtilsI18n.get("transfer.executionType.cost.label", null, params);
    };

    buildOptions = () => {
        const { commission, previewData, transaction } = this.props;
        const { commissionAmountOffline, commissionAmountOnline } = commission;
        const { isOnlineEnabled } = previewData;

        return [
            {
                id: "online",
                value: "online",
                label: UtilsI18n.get("transfer.executionType.online.label"),
                disabled: !isOnlineEnabled || (transaction && transaction.idTransactionStatus === "PENDING"),
                extraText: this.buildExtraText(commissionAmountOnline),
            },
            {
                id: "deferred",
                value: "deferred",
                label: UtilsI18n.get("transfer.executionType.deferred.label"),
                disabled: transaction && transaction.idTransactionStatus === "PENDING",
                extraText: this.buildExtraText(commissionAmountOffline),
            },
        ];
    };

    handleChangeExecutionType = () => {
        const {
            formikProps: { setFieldTouched },
        } = this.props;

        setFieldTouched("executionType");
    };

    render() {
        const {
            activeEnvironment,
            formikProps: { values },
            metaData,
            previewData,
        } = this.props;
        const {
            amount,
            creditAccountAlias,
            creditAccountOwner,
            creditAmount,
            debitAccountAlias,
            debitAmount,
            idCreditBank,
            manualRevisionWarning,
            onlineInformationMessage,
            rate,
            rateFirstCurrency,
            rateSecondCurrency,
            transferWithoutFundsWarning,
        } = previewData;
        const { fieldList } = metaData;

        const { type } = activeEnvironment;
        const bankList = fieldList && fieldList.find((currentField) => currentField.idField === "idCreditBank");
        const creditBankName =
            bankList && bankList.optionList.find((currentBank) => currentBank.id === idCreditBank).label;
        const showConfirmationText = creditAccountOwner !== undefined;
        const showDebitAmount = debitAmount.currency !== amount.currency;
        const showCreditAmount = creditAmount.currency !== amount.currency;

        const renderAmount = (className, label, amountToRender) => (
            <div className={`transfer-data ${className}`}>
                <span className="data-label">
                    <I18n id={`forms.transferLocal.confirmation.${label}`} />
                </span>
                <FormattedAmount currency={amountToRender.currency} quantity={amountToRender.quantity} />
            </div>
        );

        const renderRate = (className, rateToRender, firstCurrency, secondCurrency) => (
            <div className={`transfer-data ${className}`}>
                <span className="data-label">
                    <I18n id="forms.confirmation.rate" />
                </span>
                <FormattedRate firstCurrency={firstCurrency} secondCurrency={secondCurrency} quantity={rateToRender} />
            </div>
        );

        if (creditAccountOwner !== undefined) {
            confTxt = (
                <span className="text-lead">
                    <I18n
                        id="client.transferLocal.confirmation.creditAccountOwner"
                        idBank={creditBankName}
                        caOwner={creditAccountOwner}
                    />
                </span>
            );
        }

        return (
            <React.Fragment>
                <p className="text-lead">
                    <I18n id="forms.transferLocal.confirmation.title" />
                </p>

                <div className="transfer-block">
                    {renderAmount("transfer-data-highlight", "amount", amount)}

                    {showCreditAmount && (
                        <React.Fragment>
                            {renderAmount("data-wrapper", "creditAmount", creditAmount)}
                            {renderRate("data-wrapper", rate, rateFirstCurrency, rateSecondCurrency)}
                        </React.Fragment>
                    )}

                    {showDebitAmount && (
                        <React.Fragment>
                            {renderAmount("data-wrapper", "debitAmount", debitAmount)}
                            {renderRate("data-wrapper", rate, rateFirstCurrency, rateSecondCurrency)}
                        </React.Fragment>
                    )}

                    {manualRevisionWarning && (
                        <span className="data-warning">
                            <I18n id="client.transaction.manualRevisionWarning" />
                        </span>
                    )}

                    {transferWithoutFundsWarning && (
                        <span className="data-warning">
                            <I18n id="client.transaction.transferWithoutFundsWarning" />
                        </span>
                    )}

                    <div className="transfer-data transfer-data-flow">
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="forms.transferLocal.confirmation.debitAccount" />{" "}
                            </span>
                            <span className="data-name">{debitAccountAlias}</span>
                        </div>
                        <Image src="arrowRight.svg" className="svg-icon" />
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="forms.transferLocal.confirmation.creditAccount" />{" "}
                            </span>
                            <span className="data-name">{creditBankName}</span>
                            <span className="data-name">{creditAccountAlias}</span>
                        </div>
                    </div>

                    <React.Fragment>
                        <div className="transfer-data transfer-data-highlight">
                            <I18n id="transfer.executionType.label" />
                            <div className={CLASS}>
                                <Field
                                    idForm={FORM_ID}
                                    key="executionType"
                                    name="executionType"
                                    component={Selector}
                                    onChange={this.handleChangeExecutionType}
                                    options={this.buildOptions()}
                                    renderAs="radio"
                                    inLineControl
                                />
                            </div>
                            {onlineInformationMessage && (
                                <div className="data-wrapper">
                                    <span className="data-name">
                                        <span>{onlineInformationMessage}</span>
                                    </span>
                                </div>
                            )}
                            {type === ENVIRONMENT_TYPE.CORPORATE && values && values.executionType === "online" && (
                                <div className={CLASS}>
                                    <div className="container-scrollable">
                                        <span className="scrollable">
                                            <I18n id="transfer.deferred.message" />
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </React.Fragment>

                    {showConfirmationText && <React.Fragment>{confTxt}</React.Fragment>}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    activeEnvironment: StoreSession.getActiveEnvironment(store) || {},
    commission: SelectorsStoreForm.getCommission(store) || {},
    metaData: SelectorsStoreForm.getMetadata(store),
    name: SelectorsStoreForm.getName(store),
    previewData: SelectorsStoreForm.getPreviewData(store),
    transaction: SelectorsStoreForm.getTransaction(store),
});

export default HighOrder(Connect(mapStateToProps))(Component);
