import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    paperDetailRequest: (props) => API.executeWithAccessToken(PATH.GET_PAPER_REQUEST, props),
};

export const SelectorsAction = {
    paperDetailRequest: (props) => FactoryAction(TYPE.GET_PAPER_REQUEST, props),
    paperDetailRequestFailure: () => FactoryAction(TYPE.GET_PAPER_FAILURE),
    paperDetailRequestSuccess: () => FactoryAction(TYPE.GET_PAPER_SUCCESS),
};

export const SelectorsStore = {
    isDownloading: (store) => store[NAME].isDownloading,
};
