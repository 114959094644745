/* global process */
export const { NODE_ENV, REACT_APP_PINNING_DISABLED, REACT_APP_ROUTER_BASE, REACT_APP_VERSION } = process.env;
export const NODE_ENV_PRODUCTION = "production";

export const CONTAINER_TYPE = {
    INTERNAL: "Internal",
    EXTERNAL: "External",
};

export const PAGE = {
    HOME: "Home",
    ACCOUNTS: "Accounts",
    CREDIT_CARDS: "Creditcards",
    LOANS: "Loans",
    SETTINGS: "Settings",
    FORMS: "Forms",
    COMMUNICATIONS: "Communications",
    TRANSACTIONS: "Transactions",
    FX: "Fx",
    TRANSFERS: "Transfers",
    MIGRATION: "Migration",
};

// TODO: this should come from ~/style/component
export const COMPONENT_TYPE = {
    MAIN: "Main",
    CONTAINER: "Container",
    PAGE: "Page",
    SECTION: "Section",
    ELEMENT: "Element",
};

export const BANK_PRODUCTS = {
    ACCOUNTS: "accounts",
    CREDIT_CARDS: "creditCards",
    LOANS: "loans",
    PREFERENTIAL_TRADING_PRICE: "preferentialTradingPrice",
};

export const CHANNEL = {
    FRONTEND: "frontend",
    PHONEGAP: "phonegap",
};

export const CC_TYPES = {
    VISA: "visa",
    MASTER: "mastercard",
    indeterminate: "indeterminate",
};

export const DC_TYPES = {
    VISA: "visa",
    MASTER: "maestro",
};

export const CC_STATEMENT_TYPES = {
    START: "inicio",
    DETAIL: "detalle",
    END: "fin",
};

export const BEVSA = {
    BPS: "999",
    DGI: "96",
};
export const DATA_NOT_AVAILABLE = "N/D";

export const TIMER_MINUTES = "00:";

export const COMMA = ",";
export const DOT = ".";
export const EMPTY_STR = "";
export const DASH = "-";
export const PERCENT = "%";
export const PIPE_SEPARATOR = "|";
export const SLASH = "/";
export const SPACE_STR = " ";
export const PLUS = "+";
export const CC_BRAND_VISA = "VISA";

export const ENVIRONMENT_TYPE = {
    CORPORATE: "corporate",
    PREMIER: "premier",
    RETAIL: "retail",
};

export const FINGERPRINT_AUTH_TOKEN = "fingerprintAuthToken";
export const BIOMETRIC_ID_USER = "idUser";
export const BIOMETRIC_TYPE = "biometricType";
export const USER_PERSONAL_NAME = "personalName";
export const IS_CDP_USER = "isCDPUser";

export const BIOMETRIC_TYPES = {
    facephi: "facephi",
    fingerPrint: "fingerPrint",
};

export const KEEP_FORM_STATE_URLS = ["bankSearch", "enrollmentBiometric"];

export const KEEP_STATE_IN_URL_SCOPE = ["salaryPayment", "suppliersPayment", "wm/bonds", "wm/shares"];

export const LEVEL = {
    ERROR: "error",
    SUCCESS: "success",
    TRANSFER: "transfer",
    WARNING: "warning",
};

export const CDP_CURRENCY = {
    USD: "2222",
    UYU: "0",
    EUR: "1111",
    GBP: "2700",
    JPY: "3600",
    ARS: "500",
    BRL: "1000",
    UI: "9999",
};

export const UNDEFINED_CURRENCY = -1;

export const SCREENS_BEFORE = {
    ONE_LESS: -1,
    TWO_LESS: -2,
    THREE_LESS: -3,
};

export const SAVINGS_ACCOUNT_BT_VALUE = "320";

export const PRODUCT_TYPE = {
    CREDIT_CARD: "TC",
    CHECKING_ACCOUNT: "CC",
    CUI: "CUI",
    SAVINGS_ACCOUNT: "CA",
};

export const FACTORING_TYPES = {
    BOTH: "BOTH",
    PAYER: "PAYER",
    PAYEE: "PAYEE",
};

// TODO: ver si no habria que sacar estos valores del config
export const FORMAT_DATE = "yyyy-MM-dd HH:mm";
export const FORMAT_DATE_SCHEDULER_FIELD = "MM/dd/yyyy";
export const FORMAT_DATE_TICKET = "dd/MM/yyyy HH:mm";
export const FORMAT_SHORT = "yyyy-MM-dd";

export const FILTER = {
    TODAY: "today",
    CURRENT_WEEK: "currentweek",
    CURRENT_MONTH: "currentmonth",
    LAST_MONTH: "lastmonth",
    LAST_MONTH_SIX: "lastmonthsix",
};

export const NO_TRANSITION = "noTransition";

export const LANGUAGE = {
    EN: "en",
    ES: "es",
    PT: "pt",
};

export const CREDENTIAL_TYPE_PASSWORD = "password";
export const CREDENTIAL_TYPE_PIN = "pin";
export const CREDENTIAL_TYPE_OTP = "otp";
export const CREDENTIAL_TYPE_VERIFICATION_CODE = "verificationCode";
export const MAX_LENGTH_OTP = 6; // TODO: esto deberia venir desde ibanca no?
export const MIN_LENGTH_OTP = 1; // TODO: esto deberia venir desde ibanca no?
export const INVITATION_CODE_GROUP_LENGTH = 4;

export const SOFT_OTP = "SOFT";
export const HARD_OTP = "HARD";
export const FINGERPRINT_CONFIGURATION = "FINGERPRINT_CONFIGURATION";
export const FINGERPRINT_ENROLLMENT = "FINGERPRINT_ENROLLMENT";
export const REGISTER_BIOMETRIC = "REGISTER_BIOMETRIC";
export const TRANSFER_WITH_BIOMETRIC = "TRANSFER_WITH_BIOMETRIC";
export const SIGN_WITH_BIOMETRIC = "SIGN_WITH_BIOMETRIC";
export const SOFT_TOKEN_BIOMETRIC = "SOFT_TOKEN_BIOMETRIC";
export const ASSOCIATE_USER_BIOMETRIC = "ASSOCIATE_USER_BIOMETRIC";
export const FX_BIOMETRIC = "FX_BIOMETRIC";
export const LOGIN_STEP1 = "LOGIN_STEP1";

export const SOFT_TOKEN_TIMER = 20;
export const RESEND_VERIFICATION_CODE_TIMER = 30000;

export const ERROR_MOBILE_IOS_128 = -128;
export const ERROR_MOBILE_IOS_NOT_AVAILABLE = -6;
export const ERROR_MOBILE_IOS_LOCK_OUT = -8;
export const ERROR_MOBILE_IOS_CANCEL = -2;
export const ERROR_MOBILE_IOS_FAILED = -1;
export const ERROR_MOBILE_IOS_CANCEL_BY_SYSTEM = -4;
export const BIOMETRIC_FACE_ID = "face";
export const TERMS = "terms";
export const RESPONSIBILITY = "responsibility";

export const APIErrorCodes = {
    API508W: "invalid",
    API509W: "expired",
    API510W: "alreadyUsed",
    API511W: "cancelled",
    API512W: "invalid",
    API521W: "invalidPersonType",
};

export const APIFieldErrorCodes = {
    API016W: {
        errors: ["username", "secondFactorAuth"],
        mustShowCaptcha: false,
    },
    API019W: {
        errors: ["password"],
        mustShowCaptcha: false,
    },
    API020W: {
        errors: ["password"],
        mustShowCaptcha: true,
    },
    API021W: {
        errors: ["username", "secondFactorAuth"],
        mustShowCaptcha: true,
    },
};

export const RESPONSE_TYPE = {
    UNAUTHORIZED: 401,
    ERROR: "E",
    WARNING: "W",
};

export const EXTERNAL_PAYMENTS = {
    SISTARBANC: "sistarbanc",
    BEVSA: "bevsa",
    IS_EXTERNAL: "externalPayment",
};

export const DEFAULT = "UYU";
export const UI = "UI";
export const STATE_NUMBER = 12;
export const PASSPORT_CODE = "19";

export const HSBC = "157";
export const BROU = "1";

export const API_ENROLLMENT_INVALID_CHANNEL = "API540W";
export const API_FILE_PROCESS_ERROR = "API542W";
export const API_INVALID_IP_RANGE = "API058W";
export const API_INVALID_PASSWORD = "API019W";
export const API_INVALID_PASSWORD_CAPTCHA_REQUIRED = "API020W";
export const API_INVALID_USER_OR_SECOND_FACTOR = "API016W";
export const API_INVALID_USER_OR_SECOND_FACTOR_CAPTCHA_REQUIRED = "API021W";
export const API_FINGER_SESSION_ENDED = "API041W";
export const API_PASSWORD_BLOCKED = "API524W";
export const API_SESSION_EXPIRED = "API004W";
export const API_TRANSACTION_LINES_ERRORS = "API544W";
export const ERROR_FORM = "BAK002W";
export const EXPRESS_TRANSFER = "COR000I";
export const INSUFFICIENT_SIGNATURES = "COR023I";
export const INVALID_CAPTCHA = "COR050W";
export const INVALID_DOC_AFFILIATE = "API564E";
export const INVALID_EMAIL = "API552W";
export const INVALID_TOKEN = "COR027W";
export const INVITATION_CODE_IN_USE = "API517W";
export const IO_ERROR = "COR002E";
export const IP_NOT_AUTHORIZED = "COR041E";
export const MOBILE_LOGIN_TERMS_NOT_ACCEPTED = "API515W";
export const NO_CREDITCARDS_ENABLED_FOR_PAY = "API555E";
export const NO_RESPONSE_RECEIVED = "CLI999E";
export const NO_RISK_PROFILE_AVAILABLE = "API563W";
export const NO_W8_AVAILABLE = "API562W";
export const SCHEDULED = "COR013I";
export const SYSTEM_UNAVAILABLE = "COR097E";
export const USER_DISABLED = "COR019E";
export const USER_DOESNT_EXIST = "COR018W";
export const USER_NEEDS_CONNECTED_PARTIES = "API551E";
export const USER_OTP_NOT_ACTIVE = "API516W";
export const TERM_DEPOSIT_INVALID_ACCOUNT_CURRENCY = "API559W";
export const TERM_DEPOSIT_INVALID_DEBIT_ACCOUNT = "API560W";
export const TERM_DEPOSIT_INSUFFICIENT_BALANCE = "API561W";
export const TRANSFER_CANNOT_BE_ONLINE = "API567W";
export const TRANSFER_ONLINE_PROCESSING = "API568I";
export const TRANSACTION_MUST_BE_AUTHORIZED_BY_APP = "API570I";
export const VALIDATION_ERROR = "COR020W";

export const BADGE_MAX = "+99";

export const LOAN_TYPES = {
    AUTOMOBILE: 28,
    CONSUMER: 29,
    LOAN: 30,
    FIXED_TERM: 31,
    AMORTIZING: 32,
};

export const FINANCING_PRODUCT_TYPES = [28, 29, 30, 31, 32, 33, 34, 35, 36];

export const ERROR_TITLE = {
    es: "Ha ocurrido un error",
    en: "An error has occurred",
    pt: "Ocorreu um erro",
};

export const ERROR_MESSAGE = {
    es:
        "Estimado cliente: ha ocurrido un problema procesando su solicitud. " +
        "Por favor espere unos minutos y vuelva a intentarlo más tarde.",
    en:
        "Dear customer, a problem has occurred processing your request. " +
        "Please wait a few minutes and try again later",
    pt:
        "Prezado cliente, ocorreu um problema ao processar sua solicitação. " +
        "Aguarde alguns minutos e tente novamente mais tarde",
};

export const ERROR_BUTTON = {
    es: "Volver",
    en: "Return",
    pt: "Voltar",
};

export const NOTIFICATION_DURATION = {
    TERMS_NOT_ACCEPTED: 10,
    MED: 7,
};

// TODO: revisar si no esta en el config
export const REGEX_DEFAULT_CELLPHONE_FORMAT = /^(\+?\d{1,3}[- ]?)?\d{8,16}$/;

export const REGEX_SPACE_STR = / /g;

export const REGEX_IGNORE_ACCENTS = /[\u0300-\u036f]/g;

export const FORM_RENDER = {
    DESKTOP: "desktop",
    DEBIT_ACCOUNT: "debitAccount",
    TRANSFER: "transfer",
    TRANSACTION: "transaction",
    TRANSACTION_HISTORY: "transactionhistory",
    TRANSACTIONS: "transactions",
    TEMPLATES: "templates",
    WIDGET: "widget",
};

export const MAXIMUM_FRACTION_DIGITS_RATE_FX = 4;

export const MILLION = 1000000;

export const DETAIL_LESS_ID_FORMS = ["requestLoan", "requestCreditCard"];

export const POSITION_VALUES = ["shares", "fixedIncome", "funding", "cash", "others"];

export const BANK_FIELDS = { CREDIT_BANK_CODE: "creditBankCode", INTERMEDIARY_BANKCODE: "intermediaryBankCode" };

export const EVENT_BACKBUTTON = "backbutton";

export const MSG_RETURN_CODE_PREFIX = "returnCode";

export const ALL = "ALL";

export const PAY_CREDITCARD = "payCreditCard";

export const AMOUNT_USD = "amountUSD";

export const TIMEOUT = 1000;

export const COUNTRY_TO_CURRENCY = {
    ARE: "AED",
    ARG: "ARS",
    AUS: "AUD",
    BOL: "BOB",
    BRA: "BRL",
    CAN: "CAD",
    CHL: "CLP",
    CHN: "CNY",
    COL: "COP",
    CRI: "CRC",
    DEU: "DEU",
    DNK: "DKK",
    ECU: "ECU",
    ESP: "ESP",
    EUR: "EUR",
    FIN: "FIN",
    FRA: "FRA",
    GBR: "GBP",
    GUY: "GYD",
    HKG: "HKD",
    HUN: "HUF",
    IND: "INR",
    ISL: "ISK",
    JPN: "JPY",
    KOR: "KRW",
    LTU: "LIT",
    MEX: "MXN",
    MYS: "MYR",
    NLD: "NLG",
    NOR: "NOK",
    NZL: "NZD",
    PER: "PEN",
    PRT: "PRT",
    PRY: "PRG",
    RUS: "RUB",
    SGP: "SGD",
    SUR: "SRD",
    TUR: "TRY",
    URY: "UYU",
    USA: "USD",
    VEN: "VES",
    MYT: "FRA",
    NCL: "FRA",
    SPM: "FRA",
    SHN: "GBR",
    SJM: "NOR",
    BVT: "NOR",
    UMI: "USD",
    WLF: "FRA",
    ATF: "FRA",
    REU: "FRA",
};

export const PREVENT_BACK_BUTTON_ROUTES = ["/generalConditionsLogin", "/registerBiometric"];

export const CONFIRMATION = "confirmation";

export const FORM = "form";

export const SCOPE = {
    ACCOUNTS: "accounts",
    ACCOUNT_DETAILS: "account/details",
    ACCOUNT_DOWNLOAD_PDF_FAILURE: "accountDownloadPdfFailure",
    ACCOUNT_SET_ALIAS: "accounts/setAlias",
    ADMINISTRATION: "administration",
    ADMINISTRATION_CREATE_SIGNATURE_SCHEME: "administrationCreateSignatureScheme",
    ADMINISTRATION_DELETE_SIGNATURES_SCHEME: "administrationDeleteSignaturesScheme",
    ADMINISTRATION_GROUPS_OF_USER: "administrationGroupsOfUser",
    ADMINISTRATION_MODIFY_SIGNATURES_SCHEME: "administrationModifySignaturesScheme",
    ADMINISTRATION_SIGNATURES_SCHEME: "administrationSignaturesSchemes",
    ADMINISTRATION_USERS: "administrationUsers",
    ADMINISTRATION_USERS_INVITE: "administrationUsersInvite",
    ADVANCE_PAYMENTS: "advancePayments",
    ADVANCE_PAYMENT_ORDERS: "advancePaymentOrders",
    AUTOMATIC_DEBITS: "automaticDebits",
    BACKOFFICE_TRANSACTION: "backofficeTransaction",
    BIOMETRIC_TYPE_CHOICE: "biometricTypeChoice",
    BUY_BONDS: "wm.bonds.buy",
    BUY_SHARES: "wm.shares.buy",
    CHANGE_EMAIL: "changeEmail",
    CHANGE_LANGUAGE: "changeLanguage",
    CHANGE_MOBILE_PHONE: "changeMobilePhone",
    CHANGE_PASSWORD: "changePassword",
    CHANGE_PHONE: "changePhone",
    CHANGE_PHONE_2: "changePhone2",
    CHANGE_PHONE_3: "changePhone3",
    CHANGE_PHONE_CONFIRMATION: "changePhoneConfirmation",
    CHECK_MOBILE_PHONE: "checkMobilePhone",
    COMBOS_LIST: "combosList",
    COMEX: "comex",
    COMEX_BILLING_CANCELLATION: "comex.imports.billingCancellation",
    COMEX_BILLING_CLAIM: "comex.export.billingClaim",
    COMEX_BONDS_AND_GUARANTEES: "comex.bondsAndGuarantees",
    COMEX_BONDS_AND_GUARANTEES_MODIFY: "comex.bondsAndGuarantees.modifyBondsAndGuarantees",
    COMEX_DISCREPANCY_DOCUMENT_AUTHORIZATION: "comex.export.authorizationDDiscrepancies",
    COMEX_EXPORT_CESSION_CREDIT_LETTER: "comex.export.cessionCreditLetter",
    COMEX_EXPORT_CREDIT_LETTER_TRANSFER: "comex.export.creditLetterTransfer",
    COMEX_EXPORT_PRESENT_BILLING_DOCUMENTS: "comex.export.presentBillingDocuments",
    COMEX_IMPORT_CANCELLATION_CREDIT_LETTER: "comex.import.cancellationCreditLetter",
    COMEX_IMPORT_CREDIT_LETTER_MODIFY: "comex.imports.creditLetterModify",
    COMEX_IMPORT_CREDIT_LETTER_OPEN: "comex.imports.creditLetterOpen",
    COMEX_IMPORT_DOCUMENT_PRESENTATION: "comex.import.presentationLDDomesticCredit",
    COMEX_IMPORT_LIFT_CREDIT_LETTER_DISCREPANCIES: "comex.imports.liftCreditLetterWothDiscrepancies",
    COMEX_IMPORT_REQUEST_ENDORSEMENT: "comex.imports.requestEndorsement",
    COMEX_OTHERS: "comex.other.otherProcedures",
    COMMUNICATIONS: "communications",
    COMMUNICATIONS_REPLY: "communications.reply",
    COMMUNICATIONS_SEND: "communications.send",
    CREDIT_CARD_DETAILS: "creditCardDetails",
    CREDIT_CARD_MOVEMENT_DETAIL: "creditCardMovementDetail",
    CREDIT_CARD_STATEMENT_DOWNLOAD: "creditCardStatementDownload",
    CREDIT_CARDS_LIMIT_INCREASE: "creditCardsLimitIncrease",
    CREDIT_CARDS_LIST: "creditCardsList",
    CREDIT_CARDS_PAYMENT: "creditCardsPayment",
    CREDIT_CARDS_PURCHASE_NOTIFICATION: "creditCardsPurchaseNotification",
    CREDIT_CARDS_SET_ALIAS: "creditCards/setAlias",
    CREDIT_CARDS_TRIP_OR_ONLINE_PURCHASE_NOTIFICATION: "creditCardsTripOrOnlinePurchaseNotification",
    CUSTOMS_PAYMENT: "customsPayment",
    DEBIT_CARD_DETAILS: "debitCardDetails",
    DESKTOP: "desktop",
    EXTERNAL_DASHBOARD: "externalDashboard",
    FACTORING: "factoring",
    FACTORING_UPLOAD_PAYMENT_ORDERS: "factoringUploadPaymentOrders",
    FACTORING_UPLOAD_SUPPLIERS: "factoringUploadSuppliers",
    FINGER_PRINT_CONFIGURATION: "fingerprintConfiguration",
    FORM: "form",
    FORM_CONFIRMATION: "formConfirmation",
    GENERAL_CONDITIONS_LOGIN: "generalConditionsLogin",
    INVESTMENT: "investment",
    INVESTMENT_OTHER_INVESTMENTS: "otherInvestments",
    INVITATION_CODE: "invitationCode",
    LIST_ADVANCE_PAYMENTS: "listAdvancePayments",
    LOANS: "loans",
    LOANS_DETAILS: "loans/details",
    LOANS_SET_ALIAS: "loans/setAlias",
    LOGIN: "login",
    MIGRATION_SUGGEST: "migrationSuggest",
    MOVEMENT_DETAIL: "movementDetail",
    OTHER_INVESTMENT: "otherInvestment",
    PAPERS_LIST: "wm.papers.list",
    PASSWORD_MODAL: "passwordModal",
    PAYMENT_ORDERS: "paymentOrders",
    PAYMENT_PURPOSES_LIST: "paymentPurposesList",
    PERSONAL_INFORMATION: "personalInformation",
    POSITION: "position",
    PREFERENTIAL_TRADING_PRICE: "preferentialTradingPrice",
    QR_MODO: "qrModo",
    RECOVERY_PASSWORD: "recoveryPassword",
    REGISTER_BIOMETRIC: "registerBiometric",
    REGISTER_PERSONAL_DATA: "registerPersonalData",
    REQUEST_INFO: "wm.requestInfo",
    RISK_PROFILE_RESUME: "wm.riskProfile.resume",
    RISK_PROFILE_STEP_1: "wm.riskProfile.step1",
    RISK_PROFILE_STEP_3: "wm.riskProfile.step3",
    SECURITY_TIPS: "securityTips",
    SELL_BONDS: "wm.bonds.sell",
    SELL_SHARES: "wm.shares.sell",
    SERVICE_PAYMENT: "service.payment",
    SETTINGS: "settings",
    SETTINGS_NOTIFICATIONS_CONFIGURATION_MODIFY: "settings.notificationsConfiguration.modify",
    SOFT_OTP: "softOTP",
    SUPPLIERS: "suppliers",
    SWIFT: "swift",
    SWIFT_MESSAGE_DETAIL: "swiftMessageDetail",
    TERM_DEPOSIT_TICKET: "termDepositTicket",
    TRANSACTIONS: "transactions",
    TRANSFER: "transfer",
    TRANSFERS_ALIAS_ADMINISTRATION: "transferAliasAdministration",
    VALIDATE_USER_EMAIL: "validateUserEmail",
    VERIFICATION_CODE: "verificationCode",
    WEALTH_MANAGEMENT_BUY_FUNDS: "wm.funds.buy",
    WEALTH_MANAGEMENT_SELL_FUNDS: "wm.funds.sell",
    WEALTH_MANAGEMENT_SWITCH_FUNDS: "wm.funds.switch",
};

export const ID_FORM = {
    CREATE_AUTOMATIC_DEBIT: "createAutomaticDebit",
    CREDIT_CARD_PURCHASE_NOTIFICATION: "creditCardPurchaseNotification",
    PAY_BEVSA: "payBevsa",
    PAY_CREDIT_CARD: "payCreditCard",
    PAY_SISTARBANC: "paySistarbanc",
    PREFERENTIAL_TRADING_PRICE: "preferentialTradingPrice",
    REQUEST_CHECKBOOK: "requestCheckbook",
    REQUEST_CREDIT_CARD: "requestCreditCard",
    REQUEST_LOAN: "requestLoan",
    SALARY_PAYMENT: "salaryPayment",
    SUPPLIERS_PAYMENT: "suppliersPayment",
    TRANSFER_FOREIGN_FORM: "transferForeign",
    TRANSFER_INTERNAL: "transferInternal",
    TRANSFER_LOCAL: "transferLocal",
    TRANSFER_THIRD_PARTIES: "transferThirdParties",
};

export const ID_FORM_COMEX = {
    BILLING_CANCELLATION: "comex.import.cancellationCollection",
    BILLING_CLAIM: "comex.export.billingClaim",
    CANCELLATION_COLLECTION: "comex.import.cancellationCollection",
    CANCELLATION_CREDIT_LETTER: "comex.import.cancellationCreditLetter",
    CESSION_CREDIT_LETTER: "comex.export.cessionCreditLetter",
    COMEX_IMPORT_DOCUMENT_PRESENTATION: "comex.import.presentationLDDomesticCredit",
    CREDIT_LETTER_PURCHASE: "comex.export.creditLetterPurchase",
    DISCREPANCY_DOCUMENT_AUTHORIZATION: "comex.export.authorizationDDiscrepancies",
    LIFT_CREDIT_LETTER_DISCREPANCIES: "comex.import.liftCreditLetterWithDiscrepancies",
    MODIFY_BONDS_AND_GUARANTEES: "comex.bondsAndGuarantees.modifyBondsAndGuarantees",
    MODIFY_CREDIT_LETTER: "comex.import.modifyCreditLetter",
    PRESENTATION_CREDIT_LETTER: "comex.export.presentationDocumentsCreditLetter",
    REQUEST_ENDORSEMENT: "comex.import.requestEndorsement",
    TRANSFER_CREDIT_LETTER: "comex.export.transferCreditLetter",
};

export const ID_FIELD = {
    AMOUNT: "amount",
    AMOUNT_USD: "amountUSD",
    AMOUNT_UYU: "amountUYU",
    CHECKBOX_EXPRESS: "checkboxExpress",
    CONCEPT: "concept",
    CREDIT_ACCOUNT: "creditAccount",
    CREDIT_BANK_CODE: "creditBankCode",
    CREDIT_BANK_LABEL: "creditBankLabel",
    DEACTIVATE: "deactivate",
    DEBIT_ACCOUNT: "debitAccount",
    ID_AGREEMENT: "idAgreement",
    INTERMEDIARY_BANK_CODE: "intermediaryBankCode",
    INTERMEDIARY_BANK_LABEL: "intermediaryBankLabel",
    PURPOSE: "purpose",
    REQUEST_LOAN_CONTACT_TIME: "idContactTime",
    REQUEST_LOAN_CONTACT_TYPE: "idContactType",
    REQUEST_LOAN_DESTINATION: "destination",
    REQUEST_LOAN_TERM: "term",
};

export const FIELD_SUBTYPE = {
    ALIAS_DEPENDANT: "aliasDependant",
    ALIAS_DEPENDANT_ACCOUNT_TYPE: "aliasDependantAccountType",
    AUTOMATIC_DEBIT_ACCOUNT: "automaticDebitAccount",
    AUTOMATIC_DEBIT_AGREEMENT: "automaticDebitAgreement",
    COMPANY_DEPENDENT: "companyDependent",
    CONDITION_WITH_DATE: "conditionWithDate",
    CREDIT_CARD_SELECTOR: "creditCardSelector",
    CURRENCY_DISCLAIMER: "currencyDisclaimer",
    EDIT_ONLY: "editOnly",
    ENVIRONMENT_TYPE: "environments",
    INFO: "info",
    LIST_COMPANIES: "listCompanies",
    LOCAL_BANKS: "localBanks",
    MULTILINE_PAYMENT: "multilinePayment",
    PAYMENT_PURPOSE: "paymentPurpose",
    PURCHASE_NOTIFICATION: "purchaseNotification",
    REFERENCE: "reference",
    REQUEST_LOAN_TYPE: "requestLoanType",
    REQUEST_LOAN_TYPE_DEPENDENT: "requestLoanTypeDependent",
    TRANSFER_FOREIGN: "transferForeign",
    TRANSFER_THIRD_PARTIES: "transferThirdParties",
};

export const OPTION_PAY_CREDIT_CARD = { SPE: "specify", TOTAL: "total", MIN: "minimum", NO: "no" };
export const REQUEST_LOAN_CONTACT_TYPE_VALUE_PHONE = "phone";
export const SELECTED_CHECKBOX = "selected";
export const CAMPAIGN_SECTION_HEADER = "desktop-header";

export const SUPPORTED_FILE_TYPES = {
    CSV: "csv",
    JPG: "jpg",
    JPEG: "jpeg",
    PDF: "pdf",
    PNG: "png",
    TXT: "txt",
    XLS: "xls",
};

export const ADMINISTRATION_USER_ACTIONS = {
    BLOCK: "block",
    UNBLOCK: "unblock",
    DELETE: "delete",
};

export const ADMINISTRATION_USER_ACTIONS_TO_STATUS = {
    [ADMINISTRATION_USER_ACTIONS.BLOCK]: "blocked",
    [ADMINISTRATION_USER_ACTIONS.UNBLOCK]: "active",
};

export const GLOBAL_UNEXPECTED_ERROR_KEY = "global.unexpectedError";
export const FORM_FIELD_ERRORS = "forms.fieldsErrors";

export const TRANSACTION_DATA = "transactionData";

export const PAYMENT_TYPE = {
    CASH: "cash",
    FEES: "fees",
};

export const NO_DATA = "N/A";

export const FORM_PATH = "/form/";
export const TRANSACTION_PATH = "/transaction/:idTransaction";

export const MASSIVE_PAYMENTS_LOADING_METHOD = {
    FILE: "file",
    MANUAL: "manual",
};

export const NO_SPOUSE = ["2", "5", "6", null];

export const EXCLUDED_FIELD = [
    "idMaritalStatus",
    "idProfession",
    "spouseName",
    "spouseLastname",
    "spouseDocument",
    "spouseDocumentCountry",
    "spouseDocumentType",
    "spouseDocumentCountryName",
    "spouseDocumentTypeName",
    "maritalStatus",
];

export const PROFESSION_NOT_PRIVATE = [2, 3, 15];
export const PROFESSION_UNEMPLOYED = [3, 164];

export const IS_COUNTRY_ACTIVE = ["858", "32", "76"];

export const ONLY_NUMBER = "^[0-9]*$";

export const CNY = "4150";
export const ZERO = "0";
export const ONE = "1";
export const ZERO_NUMBER = 0;
export const DEFAULT_COUNTRY = "858";
export const US_COUNTRY = "840";
export const DEFAULT_CITY = "1";
export const DEFAULT_OPTION = "1";
export const DEFAULT_MARITAL_STATUS = "5";
export const DEFAULT_OPTION_ADDRESS = 0;
export const URUGUAY_PHONE_PREFIX = "598";

export const TYPE_ADMINISTRATION = { SIMPLE: "simple", MEDIUM: "medium", ADVANCED: "advanced" };
export const AMOUNT = "AMOUNT";
export const NO_AMOUNT = "NO_AMOUNT";

export const SCHEMA_SIGNATURES_DEFAULT = "A";

export const TYPE_DATA_ACCOUNTS = {
    LEGAL: "Legal",
    MAILINGS: "Mailings",
};

export const TYPE_CI = "12";

export const ACTIVE = "active";
export const BLOCKED = "blocked";
export const HASHTAG = "#";

export const YES = "yes";
export const NO = "no";

export const DEFAULT_SIGNATURE = "A";
export const NO_SIGNATURE = "N";
export const MIDDLE_DASH = "-";
export const UNDERSCORE = "_";
export const COMMA_PLUS_SPACE = ", ";

export const mobileUrls = ["/enrollmentBiometric", "/changePhoneStep1", "/recoveryPassnoToken"];

export const TRANSFER_FOREIGN_EXPENDITURE_BY_BEN = "BEN";

export const statementDateFormat = "MM-YYYY";

export const ISIN_MAX_LENGTH_WM = 12;

export const INVESTMENTS_TYPES = {
    BONDS: "bonds",
    FUNDS: "funds",
    SHARES: "shares",
    TERM_DEPOSIT: "termDeposit",
};

export const WM_OTHER_INVESTMENTS_TYPES = {
    BONOS: "BONOS",
    ACCIONES: "ACCIONES",
};

export const BOND_CLASSIFICATION = {
    indistinct: "AMBOS",
    sovereigns: "SOBERANOS",
    corporate: "CORPORATIVOS",
};

export const SHARE_CLASSIFICATION = {
    ORDINARY: "ORDINARIAS",
    PREFERRED: "PREFERIDAS",
};

export const PAPER = {
    CURRENCY: "MONEDA",
    ISIN: "ISIN",
    LEVEL_RISK: "NIVELRIESGO",
};

export const PERMISSIONS = {
    ACCOUNTS: "accounts",
    AUTOMATIC_DEBITS: "automaticDebits",
    COMEX_BILLING_CLAIM: "billingClaim",
    COMEX_EXPORT_READ: "comexExportRead",
    COMEX_GUARANTEES_READ: "comexGuaranteesRead",
    COMEX_IMPORT_READ: "comexImportRead",
    COMEX_MODIFY_BONDS_AND_GUARANTEES: "comexGuaranteesModification",
    COMEX_MODIFY_CREDIT_LETTER: "comexCreditLetterModification",
    COMEX_EXPORT_CREDIT_LETTER_DOCUMENT_PRESENTATION: "comexExportCreditLetterDocumentPresentation",
    COMEX_TRANSFER_CREDIT_LETTER: "comexCreditLetterTransfer",
    COMEX_CESSION_CREDIT_LETTER: "comexCreditLetterCession",
    CREDITCARDS: "creditcards",
    CREDIT_CARDS_PURCHASE_NOTIFICATION: "creditcardsPurchaseNotification",
    CUSTOMS_PAYMENT: "customsPayment",
    FINANCING: "financing",
    FUNDS: "funds",
    HAS_ANY_FACTORING_PERMISSION: "hasAnyFactoringPermission",
    LOANS: "loans",
    OTHER_INVESTMENTS: "otherInvestments",
    PAY_CREDIT_CARD: "payCreditCard",
    POSITION: "position",
    PREFERENTIAL_TRADING_PRICE: "preferentialTradingPrice",
    REQUEST_CHECKBOOK: "requestCheckbook",
    SALARY_PAYMENT: "salaryPayment",
    SUPPLIERS_PAYMENT: "suppliersPayment",
    SWIFT: "swift",
    COMEX_IMPORT_BILLING_CANCELLATION: "comexBillingCancellation",
    COMEX_CREDIT_LETTER_PURCHASE: "comexCreditLetterPurchase",
    COMEX_IMPORT_DOCUMENT_PRESENTATION: "comexImportCreditLetterDocumentPresentation",
    COMEX_IMPORT_CREDIT_LETTER: "comexCreditLetterOpening",
    COMEX_IMPORT_CANCELLATION_CREDIT_LETTER: "comexCreditLetterCancellation",
    COMEX_IMPORT_LIFT_CREDIT_LETTER_DISCREPANCIES: "comexCreditLetterDiscrepanciesUprising",
    COMEX_IMPORT_REQUEST_ENDORSEMENT: "comexRequestEndorsement",
    COMEX_EXPORT_AUTHORIZATION_DDISCREPANCIES: "comexSentDocumentsAuthorization",
    COMEX_OTHER_PROCEDURES: "comexGenericForm",
    COMEX_EXPORT_PRESENT_BILLING_DOCUMENTS: "comexBillingDocumentPresentation",
    COMEX_EXPORT_BILLING_CLAIM: "comexBillingClaim",
    PRODUCT_READ: "product.read",
    PAYMENTS: "payments",
    SERVICE_PAYMENT: "paySistarbanc",
};

export const PRICE_CLASSIFICATION = {
    MARKET: "Mercado",
    LIMIT: "Limite",
};

export const BANKTYPE = {
    ABA: "ABA",
    CHIPS: "CHIPS",
    SWIFT: "SWIFT",
};
export const YES_ES = "SI";
export const NOT_ES = "NO";

export const UNAVAILABLE_IN_MOBILE = ["suppliersPayment", "salaryPayment"];
export const PATH_TO = {
    ACCOUNT_DATA_FORM: "/settings/changeDataAccounts/form",
    ALIAS_ENROLLMENT_FORM: "/transfers/aliasEnrollment",
    CHANGE_EMAIL: "/settings/changeEmail",
    CHANGE_MOBILE_PHONE: "/settings/changeMobilePhone",
    CHANGE_PERSONAL_DATA_TICKET: "/settings/changePersonalInformation/ticket",
    PERSONAL_DATA_FORM: "/settings/changePersonalInformation/form",
    SYNC_USER_EMAIL_FORM: "/settings/syncUserEmail/form",
    SYNC_USER_MOBILE_PHONE_FORM: "/settings/syncUserMobilePhone/form",
};

export const PREVIOUS = "previous";
export const NEXT = "next";

export const CREDIT_CARDS_LIMIT_INCREASE_TYPE = {
    PERMANENT: "permanent",
    TEMPORARY: "temporary",
};

export const TRIP_OR_PURCHASE = {
    ONLINE_PURCHASE_NOTICE: "onlinePurchase",
    TRAVEL_NOTICE: "travel",
};

export const ALIAS_TYPES = {
    PHONE: {
        key: "phone",
        btValue: "01",
    },
    EMAIL: {
        key: "email",
        btValue: "02",
    },
    TEXT: {
        key: "text",
        btValue: "03",
    },
};

export const REQUEST_CHECKBOOK_DELIVERY_METHOD_BRANCH = ["branch"];

export const CREDIT_CARD_OWNER = "Titular";

export const QR_MODO_TYPE_ACCOUNT = {
    CC: "CURRENT",
    CA: "SAVINGS",
};

export const QR_MODO_STATUS_REPONSE = {
    INCOMPLETE: "INCOMPLETE",
    REJECTED: "REJECTED",
    SUCCESS: "SUCCESS",
    TIMEOUT: "TIMEOUT",
};

export const QR_MODO_COUPON_ENABLED = "OBSEQUIO";

export const QR_MODO_TYPE_DISCOUNT = {
    AMOUNT: "IMPORTEFIJO",
    COUPON: "CUPON",
    NONE: "SINDESCUENTO",
    PERCENTAGE: "PORCENTAJEFIJO",
};

export const URUGUAY = "UY";
