import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { DASH, EMPTY_STR } from "~/constants";
import { SelectorsStore as StoreCampaigns } from "~/store/campaigns";
import { SelectorsStore as StoreConfig } from "~/store/config";

import { Section } from "~/components/Component";
import Link from "~/components/Link";

import Style from "./_Campaigns.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        campaigns: PropTypes.arrayOf(
            PropTypes.shape({
                data: PropTypes.string.isRequired,
                key: PropTypes.string.isRequired,
                url: PropTypes.string,
            }).isRequired,
        ),
    },
    defaults: {
        campaigns: [],
    },
};

export function Component(props) {
    const { campaigns, ...rest } = props;

    return (
        <Section id={Style.ID} name={NAME} {...rest}>
            {campaigns.map(({ key, url, data }) =>
                !url ? (
                    <img key={key} src={data} alt={EMPTY_STR} />
                ) : (
                    <Link to={url}>
                        <img key={key} src={data} alt={EMPTY_STR} />
                    </Link>
                ),
            )}
        </Section>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

function mapStateToProps(store) {
    const baseURL = StoreConfig.getItem(store, { key: "client.baseURL" });

    const campaigns = (StoreCampaigns.getCampaigns(store) || []).map((campaign, index) => {
        const { clickable: isLink, url, image, ...item } = campaign;
        const key = [index, campaign.idCampaign].join(DASH);

        return {
            ...item,
            key,
            data: `data: image/png;base64, ${image}`,
            url: isLink && url.replace(baseURL, EMPTY_STR),
        };
    });

    return { campaigns };
}

export default Connect(mapStateToProps)(Component);
