import React from "react";

import { push as Push } from "connected-react-router";
import { Form, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";

import { SCOPE } from "~/constants";
import Container from "~/containers/Internal/Dash";
import { SelectorsAction } from "~/store/migration";
import { Types as TypesHtmlElement, Defaults as DefaultsHtmlElement } from "~/util/prop/html-element";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import I18n from "~/components/I18n";

import CancelConfirmationModal from "~/pages/enrollment/_CancelConfirmationModal";

export const NAME = "MigrationSuggest";

export const PROP = {
    types: {
        ...TypesHtmlElement,
        ...TypesRedux,
    },
    defaults: {
        ...DefaultsHtmlElement,
        ...DefaultsRedux,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {
        show: false,
    };

    handleMigrationCancel = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsAction.migrationSuggest());
        dispatch(Push("/desktop"));
    };

    handleCancelation = () => {
        this.setState({ show: false });
    };

    showModal = () => {
        this.setState({ show: true });
    };

    render() {
        const { show } = this.state;

        return (
            <React.Fragment>
                <CancelConfirmationModal
                    show={show}
                    handleCancelation={this.handleCancelation}
                    handleConfirmation={this.handleMigrationCancel}
                />
                <Container name={NAME} head-onClose={this.showModal} scopeToShowNotification={SCOPE.MIGRATION_SUGGEST}>
                    <div className="login-header">
                        <span className="login-header-text">
                            <I18n id="migration.title" />
                        </span>
                    </div>
                    <div className="login-content">
                        <div className="login-row simple-row">
                            <span className="login-message">
                                <I18n id="migration.message" />
                            </span>
                        </div>
                        <div className="login-row main-row">
                            <Form className="login-form">
                                <div className="login-form-field">
                                    <Button
                                        className="login-form-button main-button"
                                        bsStyle="primary"
                                        label="global.continue"
                                        loading={false}
                                        type="submit"
                                    />
                                </div>
                                <div className="login-form-field">
                                    <Button
                                        className="login-form-button simple-button"
                                        label="global.cancel"
                                        onClick={this.handleMigrationCancel}
                                    />
                                </div>
                            </Form>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

export default Compose(
    Connect(null),
    WithFormik({
        handleSubmit: (data, formikBag) => {
            const { dispatch } = formikBag.props;
            dispatch(SelectorsAction.migrationSuggest({ formikBag }));
            dispatch(Push("/checkPhone"));
        },
    }),
)(Component);
