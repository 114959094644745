import React from "react";

import { routerActions as ActionRouter } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router";
import { Link } from "react-router-dom";

import { DATA_NOT_AVAILABLE, SCOPE } from "~/constants";
import Container from "~/containers/Internal/Info";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsAction as SelectorsActionLoan, SelectorsStore as SelectorsStoreLoan } from "~/store/loan";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";

import I18n from "~/components/I18n";
import SelectButton from "~/components/SelectButton";

import Information from "~/pages/loans/_components/Information";

import Style from "./Details.rules.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {
        i18n: PropTypes.object,
        id: PropTypes.string.isRequired,
        isChangingEnvironment: PropTypes.bool,
        isFetching: PropTypes.bool,
        loans: PropTypes.oneOfType([PropTypes.array]),
    },
    defaults: {
        i18n: {
            notAvailable: DATA_NOT_AVAILABLE,
        },
        isChangingEnvironment: false,
        isFetching: false,
    },
};

export function Component(props) {
    const prevId = React.useRef();
    const { dispatch, i18n, id, isChangingEnvironment, isFetching, loan } = props;
    const wait = isFetching || !loan || id !== prevId.current;

    React.useEffect(() => {
        if (!isChangingEnvironment && (!loan || id !== prevId.current)) {
            dispatch(
                SelectorsActionLoan.readLoan({
                    idLoan: id,
                }),
            );
        }
        prevId.current = id;
    }, [dispatch, id, isChangingEnvironment, loan]);

    const slides = [];

    if (!wait) {
        const { currency, information } = loan;
        const { totalToPay } = information;

        slides.push(
            [
                {
                    label: "loans.nextDue",
                    type: "Number",
                    props: {
                        value: totalToPay,
                        prefix: currency,
                        ...(information.installmentAmountUI && { suffix: `(${information.installmentAmountUI})` }),
                    },
                },
            ],
            [
                {
                    label: "loans.details.nextDueDate",
                    type: "Date",
                    props: {
                        value: loan.nextDueDate,
                    },
                },
            ],
        );
    }

    return (
        <React.Fragment>
            <Container
                name={Style.NAME_INFO}
                wait={wait}
                className={CLASS}
                head-title={loan.productAlias || loan.number}
                info-button-component={OperationsButton}
                head-onBack={handleHeadBack}
                info-button-onClick={handleHeadClick}
                info-button-label={i18n.infoButton}
                info-data={slides}
                scopeToShowNotification={SCOPE.LOANS_DETAILS}>
                {!wait && <Information />}
            </Container>
        </React.Fragment>
    );

    function handleHeadBack() {
        dispatch(ActionRouter.push(`/loans`));
    }

    function handleHeadClick() {
        dispatch(ActionRouter.push(`/loans/${loan.idProduct}/operations`));
    }

    function OperationsButton() {
        const loanButtons = [
            {
                key: "loan.alias",
                to: `/loans/${loan.idProduct}/setAlias`,
            },
        ];

        return (
            <SelectButton label={i18n.infoButton}>
                {loanButtons.map(({ key, to }) => (
                    <Link key={key} to={to}>
                        <I18n id={key} />
                    </Link>
                ))}
            </SelectButton>
        );
    }
}

const mapStateToProps = (store, props) => {
    const { match } = props;
    const i18n = {
        infoButton: StoreI18n.getMessage(store, "menu.operations"),
        loading: StoreI18n.getMessage(store, "global.loading"),
        notAvailable: StoreI18n.getMessage(store, "data.notAvailable.short"),
    };
    return {
        id: match.params.id,
        activeEnvironmentForms: SelectorsStoreSession.getActiveEnvironmentForms(store, "loans"),
        i18n: {
            ...i18n,
            getCurrency: (id) =>
                DATA_NOT_AVAILABLE
                    ? i18n.notAvailable
                    : StoreI18n.getMessage(store, `currency.label.${id}`) || i18n.notAvailable,
        },
        isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
        isFetching: SelectorsStoreLoan.getFetching(store),
        loan: SelectorsStoreLoan.getSelectedLoan(store),
        selectedFilter: SelectorsStoreLoan.getSelectedFilter(store),
    };
};

export default Connect(mapStateToProps)(WithRouter(Component));
