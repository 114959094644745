import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";

import { SCOPE } from "~/constants";
import Container from "~/containers/External/Dash";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { SelectorsAction as LoginActions, SelectorsStore as LoginSelectors } from "~/store/login";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import HighOrder from "~/components/HighOrder";
import WithExchangeToken from "~/pages/_components/withExchangeToken";

import Step2Content from "~/pages/login/_components/Step2Content";

export const NAME = "LoginStep2";

export const PROP = {
    types: {
        ...TypesRedux,
        isInFlow: PropTypes.bool,
        i18Nfetching: PropTypes.bool,
    },

    defaults: {
        ...DefaultsRedux,
        isInFlow: false,
        i18Nfetching: false,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    handleExitAction = () => {
        const { fetching, dispatch } = this.props;
        if (!fetching) {
            dispatch(LoginActions.reset());
        }
        return false;
    };

    render() {
        const { isInFlow, i18Nfetching, fetching } = this.props;
        return (
            <React.Fragment>
                {isInFlow && (
                    <Container
                        name={NAME}
                        head-onClose={this.handleExitAction}
                        wait={i18Nfetching || fetching}
                        scopeToShowNotification={SCOPE.LOGIN}>
                        <div className="enrollment page-login">
                            <Step2Content fetch={i18Nfetching} />
                        </div>
                    </Container>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    userFullName: LoginSelectors.getUserFullName(store),
    isInFlow: LoginSelectors.getIsInFlow(store),
    isFetchingMessages: LoginSelectors.getFetching(store),
    i18Nfetching: SelectorsStoreI18n.getFetching(store),
    fetching: LoginSelectors.getFetching(store),
});

export default HighOrder(WithRouter, Connect(mapStateToProps), WithExchangeToken)(Component);
