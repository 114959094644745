import React from "react";

import PropTypes from "prop-types";

import Container from "~/components/Component";

import Namespace from "./_Main.scss";

export const { NAME, TYPE } = Namespace;

export const PROP = {
    types: {
        wait: PropTypes.bool,
        name: PropTypes.string.isRequired,
    },
    defaults: {
        wait: false,
    },
};

export const preventFocusBackground = (e) => {
    e.preventDefault();
    document.activeElement.blur();
};

export function Component(props) {
    const { wait, ...rest } = props;
    let $wait = document.getElementById(Namespace.ID_WAIT);
    if ($wait) {
        $wait.parentElement.removeChild($wait);
        document.removeEventListener("keydown", preventFocusBackground, true);
    }
    if (wait === true) {
        const $root = document.getElementById("root");
        $wait = document.createElement("div");
        $wait.id = Namespace.ID_WAIT;
        $root.parentElement.insertBefore($wait, $root);
        document.addEventListener("keydown", preventFocusBackground, true);
    }
    return <Container {...rest} tag="main" role="main" type={TYPE} />;
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
