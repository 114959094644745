import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";

import { SelectorsStore as SelectorsStoreForm } from "~/store/form";

import I18n from "~/components/I18n";
import FormattedAmount from "~/pages/_components/FormattedAmount";

export const NAME = "PayCreditCard";

export const PROP = {
    types: {
        previewData: PropTypes.shape({
            debitAmountUYU: PropTypes.shape({
                currency: PropTypes.string.isRequired,
                quantity: PropTypes.number.isRequired,
            }).isRequired,
            debitAmountUSD: PropTypes.shape({
                currency: PropTypes.string.isRequired,
                quantity: PropTypes.number.isRequired,
            }).isRequired,
            amountUYU: PropTypes.shape({
                currency: PropTypes.string.isRequired,
                quantity: PropTypes.number.isRequired,
            }).isRequired,
            amountUSD: PropTypes.shape({
                currency: PropTypes.string.isRequired,
                quantity: PropTypes.number.isRequired,
            }).isRequired,
            creditCardAlias: PropTypes.string.isRequired,
            debitAccountAliasUYU: PropTypes.string.isRequired,
            debitAccountAliasUSD: PropTypes.string.isRequired,
            creditCardMark: PropTypes.string.isRequired,
        }).isRequired,
    },
    defaults: {},
};
export function Component(props) {
    const { previewData } = props;
    const {
        debitAmountUYU,
        debitAmountUSD,
        amountUYU,
        amountUSD,
        creditCardAlias,
        debitAccountAliasUYU,
        debitAccountAliasUSD,
        creditCardMark,
    } = previewData;

    const showdebitAmountUYU = debitAmountUYU.currency !== amountUYU.currency;
    const showdebitAmountUSD = debitAmountUSD.currency !== amountUSD.currency;

    const renderAmount = (className, label, amountToRender) => (
        <div className={`transfer-data ${className}`}>
            <span className="data-label">
                <I18n id={`forms.payCreditCard.confirmation.${label}`} />
            </span>
            <FormattedAmount currency={amountToRender.currency} quantity={amountToRender.quantity} />
        </div>
    );
    const amountClassname = amountUYU.quantity > 0 && amountUSD.quantity > 0 ? "two-item" : "one-item";

    return (
        <React.Fragment>
            <p className="text-lead">
                <I18n id="forms.payCreditCard.confirmation.title" />
            </p>

            <div className="transfer-block">
                <div className="transfer-data transfer-data-highlight">
                    <span className="data-label">
                        <I18n id="forms.payCreditCard.confirmation.creditCard" />
                    </span>
                    <span className="data-amount">
                        {creditCardMark} {creditCardAlias}
                    </span>
                </div>

                <div className={classNames(`transfer-detail ${amountClassname}`)}>
                    {amountUYU.quantity > 0 && (
                        <div>
                            <div className="transfer-data transfer-data-highlight">
                                <div className="transfer-data transfer-data-highlight">
                                    <span className="data-amount">
                                        <I18n id="creditCard.payment.UYU" />
                                    </span>
                                </div>
                                <span className="data-label">
                                    <I18n id="forms.payCreditCard.confirmation.debitAccount" />
                                </span>
                                <span className="data-amount">{debitAccountAliasUYU}</span>
                            </div>
                            {showdebitAmountUYU &&
                                renderAmount("transfer-data-highlight", "debitAmount", debitAmountUYU)}
                            {renderAmount("transfer-data-highlight", "amount", amountUYU)}
                        </div>
                    )}
                    {amountUSD.quantity > 0 && (
                        <div>
                            <div className="transfer-data transfer-data-highlight">
                                <div className="transfer-data transfer-data-highlight">
                                    <span className="data-amount">
                                        <I18n id="creditCard.payment.USD" />
                                    </span>
                                </div>
                                <span className="data-label">
                                    <I18n id="forms.payCreditCard.confirmation.debitAccount" />
                                </span>
                                <span className="data-amount">{debitAccountAliasUSD}</span>
                            </div>
                            {showdebitAmountUSD &&
                                renderAmount("transfer-data-highlight", "debitAmount", debitAmountUSD)}
                            {renderAmount("transfer-data-highlight", "amount", amountUSD)}
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (state) => ({
    name: SelectorsStoreForm.getName(state),
    previewData: SelectorsStoreForm.getPreviewData(state),
});

export default connect(mapStateToProps)(Component);
