import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import { SelectorsStore as SelectorsStoreComex } from "~/store/comex/import";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import I18n from "~/components/I18n";
import Ticket from "~/components/Ticket/Ticket";
import FormattedAmount from "~/pages/_components/FormattedAmount";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const { transaction, ticketConfirmation, dispatch } = props;
    const { data } = transaction;

    const { operationNumber, documentType, documentNumber, beneficiary, oldAmount, invoiceAmountCurrency } = data;

    const shareTicket = (idTransactionToRead) => {
        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    };

    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}
                    hasMobileComponent
                    displayDownloadPDF
                    handleDownloadPDF={() => shareTicket(transaction.idTransaction)}>
                    <Box flex directionColumn align="flex-start" className="mb-3">
                        <div className="opening">
                            <I18n id="comex.import.requestEndorsement.step2.opening" tag="p" className="mt-3" />
                        </div>
                        <div className="info-label">
                            <h4 className="bold">
                                {UtilsI18n.get("comex.import.requestEndorsement.step2.operationNumber")}
                            </h4>
                            <span>{operationNumber}</span>
                        </div>
                        <div className="info-label">
                            <h4 className="bold">{UtilsI18n.get("comex.import.requestEndorsement.step2.amount")}</h4>
                            <FormattedAmount currency={invoiceAmountCurrency} quantity={parseFloat(oldAmount)} />
                        </div>
                        <div className="info-label">
                            <h4 className="bold">{UtilsI18n.get("comex.import.requestEndorsement.step2.recipient")}</h4>
                            <span>{beneficiary}</span>
                        </div>

                        <div className="opening">
                            <span>{UtilsI18n.get("comex.import.requestEndorsement.step2.content")}</span>
                            <span className="bold">{` ${documentType}`}</span>
                            <span>{UtilsI18n.get("comex.import.requestEndorsement.step2.contentNro")}</span>
                            <span className="bold">{` ${documentNumber}`}</span>
                        </div>
                        <I18n id="comex.import.requestEndorsement.step2.terms" tag="p" className="mt-3 mb-3" />
                    </Box>
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { idTransaction } = transaction;
        dispatch(
            SelectorsActionComex.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        dispatch(SelectorsActionComex.signTransactionPreview({ ...data }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreComex.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
