/* eslint-disable no-underscore-dangle */
import GooglePhone from "google-libphonenumber";

import { EMPTY_STR, URUGUAY } from "~/constants";
import * as UtilsConfig from "~/util/config";
import Flag from "~/util/flag";
import * as UtilsI18n from "~/util/i18n";
import Logger from "~/util/logger";

export const PhoneNumberUtil = GooglePhone.PhoneNumberUtil.getInstance();

export const MOBILE_COUNTRY_FILTER = {
    ALL: "all",
    TOP_SEEDED_ONLY: "topSeededOnly",
    URUGUAY_ONLY: "uruguayOnly",
};

function makeOptionList(codeList) {
    const optionList =
        codeList && codeList.length > 0
            ? codeList.map((country) => {
                  const code = UtilsConfig.get(`cellPhone.code.${country}`);

                  return {
                      value: code,
                      flag: Flag.getFlagByCountry(country),
                      countryName: UtilsI18n.get(`mobilePhone.countryName.${country}`),
                      country,
                  };
              })
            : [];

    return optionList;
}

export function GetMobileCountryOptions(filter = MOBILE_COUNTRY_FILTER.ALL) {
    const topSeededCountries = makeOptionList(
        filter !== MOBILE_COUNTRY_FILTER.URUGUAY_ONLY ? UtilsConfig.getArray("country.codes.topSeeded") : ["URY"],
    );
    const otherCountries = makeOptionList(
        filter === MOBILE_COUNTRY_FILTER.ALL ? UtilsConfig.getArray("country.codes") : [],
    );

    if (filter === MOBILE_COUNTRY_FILTER.ALL) {
        otherCountries.sort((option1, option2) => {
            const countryName1 = option1.countryName.toUpperCase();
            const countryName2 = option2.countryName.toUpperCase();

            if (countryName1 < countryName2) {
                return -1;
            }

            if (countryName1 > countryName2) {
                return 1;
            }

            return 0;
        });
    }

    const options = [...topSeededCountries, ...otherCountries];

    return options;
}

export function GetPrefixAndNumber(phoneNumber) {
    const phoneNumberObject = {
        number: phoneNumber,
        prefix: EMPTY_STR,
    };

    try {
        const parsedPhoneNumber = PhoneNumberUtil.parseAndKeepRawInput(phoneNumber, EMPTY_STR);
        const { 1: parsedPrefix, 2: parsedNumber } = parsedPhoneNumber.values_;

        phoneNumberObject.number = String(parsedNumber);
        phoneNumberObject.prefix = "+".concat(String(parsedPrefix));
    } catch (error) {
        Logger.error(error, EMPTY_STR);
    }

    return phoneNumberObject;
}

export function GetTwoCharCountryByISOCode(isoCode) {
    const countryCode = UtilsConfig.get(`countryCodeByMobileCode.${isoCode}`);

    return countryCode || URUGUAY;
}

export function ValidateInternationalPhoneNumber({ country, value }) {
    const countryCode = GetTwoCharCountryByISOCode(country);

    try {
        const phone = PhoneNumberUtil.parse(value, countryCode);
        return PhoneNumberUtil.isValidNumber(phone);
    } catch (e) {
        return false;
    }
}

export default {
    getMobileCountryOptions: GetMobileCountryOptions,
    getPrefixAndNumber: GetPrefixAndNumber,
    getTwoCharCountryByISOCode: GetTwoCharCountryByISOCode,
    validateInternationalPhoneNumber: ValidateInternationalPhoneNumber,
};
