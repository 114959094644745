import { push as Push } from "connected-react-router";
import { call as Call, put as Put, takeLatest as TakeLatest, select as Select } from "redux-saga/effects";

import { FINGERPRINT_AUTH_TOKEN, LEVEL, RESPONSE_TYPE, SCOPE } from "~/constants";
import { SelectorsAction as SelectorsActionEnrollment } from "~/store/enrollment";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import UtilsDevice, { DEVICE_MOBILE } from "~/util/device";
import { adjustIdFieldErrors } from "~/util/form";
import * as UtilsSecureStorage from "~/util/secureStorage";
import * as UtilsVUFingerprint from "~/util/vuFingerprint";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction, SelectorsStore } from "./_selectors";

export default [
    TakeLatest(TYPE.CHANGE_PHONE_STEP1_REQUEST, handleFacephiRequest),
    TakeLatest(TYPE.CHANGE_PHONE_STEP2_REQUEST, handleInvitationCodeRequest),
    TakeLatest(TYPE.CHANGE_PHONE_STEP3_REQUEST, handleValidateOtp),
    TakeLatest(TYPE.CHANGE_MY_PHONE_REGISTER_BIOMETRIC_REQUEST, registerBiometric),
];

function* handleFacephiRequest(props) {
    const { formikBag, password, username } = props;

    const response = yield Call(SelectorsMiddleware.changePhoneStep1, {
        _password: password,
        _usernameToChangePhone: username,
    });

    if (response.type === RESPONSE_TYPE.WARNING) {
        // Wrong credentials || blocked user
        if (response.data.data.NO_FIELD) {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.data.NO_FIELD,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.CHANGE_PHONE],
                }),
            );
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.CHANGE_PHONE],
                }),
            );
        }

        yield Put(SelectorsAction.errorChangePhoneStep1());

        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
    } else {
        const { _exchangeToken, _facephi } = response.data.data;

        yield Put(
            SelectorsAction.successChangePhoneStep1({
                exchangeToken: _exchangeToken,
                facephi: _facephi,
                username,
            }),
        );

        yield Put(Push("/changeMyPhone/changePhoneStep2"));
    }

    formikBag.setSubmitting(false);

    return undefined;
}

function* handleInvitationCodeRequest(props) {
    const { username } = props;
    const exchangeToken = yield Select(SelectorsStore.exchangeToken);

    const response = yield Call(SelectorsMiddleware.changePhoneStep2, {
        _exchangeToken: exchangeToken,
        username,
    });

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.CHANGE_PHONE_2],
            }),
        );
        yield Put(SelectorsAction.errorChangePhoneStep2());
    } else {
        yield Put(SelectorsAction.successChangePhoneStep2());

        yield Put(Push("/changeMyPhone/invitationCode"));
    }
}

function* handleValidateOtp(props) {
    const { formikBag, otp } = props;
    const { username } = formikBag.props;
    const exchangeToken = yield Select(SelectorsStore.exchangeToken);

    const response = yield Call(SelectorsMiddleware.changePhoneStep3, {
        _exchangeToken: exchangeToken,
        _otp: otp,
        username,
    });

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.CHANGE_PHONE_3],
            }),
        );

        formikBag.setErrors(adjustIdFieldErrors(response.data.data));

        yield Put(SelectorsAction.errorChangePhoneStep3());
    } else {
        yield Put(SelectorsAction.successChangePhoneStep3({ exchangeToken }));

        yield Put(Push("/biometricTypeChoice"));
    }

    formikBag.setSubmitting(false);
}

function* registerBiometric(props) {
    let deviceId = "deviceId";
    let deviceModel = "deviceModel";

    const { username, biometricType } = props;
    if (UtilsDevice.isMobileNative()) {
        deviceId = UtilsDevice.id();
        deviceModel = UtilsDevice.model();
    }

    let deviceLocationVU = yield Select(SelectorsStoreSession.getDeviceLocationVU);

    if (!deviceLocationVU || (deviceLocationVU.latitude === null && deviceLocationVU.longitude === null)) {
        deviceLocationVU = yield Call(UtilsVUFingerprint.getDeviceLocationVU);
    }

    const fingerprint = yield Call(UtilsVUFingerprint.getFingerprintVU);
    let fingerprintData = yield Call(UtilsVUFingerprint.getFingerprintDeviceInfoVU);

    if (UtilsDevice.mobileOS() === DEVICE_MOBILE.IOS) {
        fingerprintData = JSON.stringify(fingerprintData);
    }

    const exchangeToken = yield Select(SelectorsStore.exchangeToken);
    const response = yield Call(SelectorsMiddleware.registerBiometricChangePhone, {
        _deviceFingerprintData: fingerprintData,
        _deviceLocationVU: deviceLocationVU,
        _exchangeToken: exchangeToken,
        _fingerprint: fingerprint,
        biometricType,
        deviceId,
        deviceModel,
        username,
    });

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.REGISTER_BIOMETRIC],
            }),
        );
        yield Put(SelectorsAction.errorRegisterBiometric());
    } else {
        const { accessToken } = response.data.data;

        yield Put(SelectorsActionEnrollment.finishChangeMyPhone());
        yield Put(SelectorsAction.successRegisterBiometric());

        yield Put(Push("/changeMyPhone/finishEnrollment"));

        const resultFingerPrint = yield Call(UtilsSecureStorage.set, FINGERPRINT_AUTH_TOKEN, accessToken);

        if (resultFingerPrint) {
            sessionStorage.setItem("widget.biometric.hide", true);
        }
    }
}
