import React from "react";

import { Form, withFormik as WithFormik, Field } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";

import { COMMA, EMPTY_STR, LANGUAGE, LEVEL, ONLY_NUMBER, SCOPE, SPACE_STR } from "~/constants";
import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { SelectorsAction as SelectorsActionComex, SelectorsStore as SelectorsStoreComex } from "~/store/comex";
import {
    SelectorsAction as SelectorsActionComexExport,
    SelectorsStore as SelectorsStoreComexExport,
} from "~/store/comex/export";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsAction as SelectorActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionTemplate } from "~/store/template";
import { getInteger } from "~/util/config";
import * as ConfigUtil from "~/util/config";
import { formatDate as FormatDate, toDate } from "~/util/date";
import { isEmptyObj } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import EmailList from "~/pages/_components/fields/EmailList";
import TextArea from "~/pages/_components/fields/TextArea";
import TextField from "~/pages/_components/fields/TextField";
import AmountField from "~/pages/_components/fields/formik/AmountField";
import Selector from "~/pages/_components/fields/formik/Selector";

import Style from "~/pages/comEx/Step1Edit.rules.scss";
import OperationNumber from "~/pages/comEx/_components/OperationNumber";
import OperationNumberDetail from "~/pages/comEx/_components/OperationNumberDetail";
import CreateTemplateModal from "~/pages/forms/Step1Edit/_ModalTemplateCreate";
import ListTemplatesModal from "~/pages/forms/Step1Edit/_ModalTemplateList";
import { Termsandconditions } from "~/pages/forms/_components/_fields";
import Scheduler from "~/pages/forms/_components/_fields/Scheduler";

import FileUploader from "../../../_components/FileUploader";

export const { NAME } = Style;

export const PROP = {
    types: {
        isSubmitting: PropTypes.bool,
        suppliersDisplayList: PropTypes.array.isRequired,
    },
    defaults: {
        isSubmitting: false,
    },
};

const FORM_ID = "comex.export.cessionCreditLetter";

const ASSIGNEE_OPTION = "CESIONARIO";
const ASSIGNOR_OPTION = "CEDIDO";
const BANK_OPTION = "BANCO";
const THIRD_OPTION = "TERCEROS";

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        draftLoaded: false,
    };

    proOptions = [
        { value: BANK_OPTION, label: UtilsI18n.get(`${FORM_ID}.pro.option.bank`) },
        { value: THIRD_OPTION, label: UtilsI18n.get(`${FORM_ID}.pro.option.third`) },
    ];

    expensesAssignmentOptions = [
        { value: ASSIGNOR_OPTION, label: UtilsI18n.get(`${FORM_ID}.expensesAssignment.option.assignor`) },
        { value: ASSIGNEE_OPTION, label: UtilsI18n.get(`${FORM_ID}.expensesAssignment.option.assignee`) },
    ];

    componentDidMount() {
        const { dispatch, formData } = this.props;

        if (isEmptyObj(formData)) {
            dispatch(SelectorsActionComexExport.cessionCreditLetterPreRequest());
        }
    }

    componentDidUpdate() {
        const {
            dispatch,
            match,
            preData,
            setFieldError,
            setFieldTouched,
            setFieldValue,
            setValues,
            transaction,
            values,
        } = this.props;

        const { draftLoaded } = this.state;
        const { operationNumber } = match.params;
        const { availableOperationList } = preData;

        if (availableOperationList && availableOperationList.length > 0 && !values.operationNumber && operationNumber) {
            const operationSelected = availableOperationList.filter(
                (item) => item.othersFields.NROOPERACION === operationNumber,
            );

            if (operationSelected.length > 0) {
                setFieldValue("item", operationSelected[0]);
                setFieldValue("operationNumber", operationNumber);
            }
        }

        if (transaction?.data && !draftLoaded) {
            this.setState({
                draftLoaded: true,
            });

            const transactionData = transaction?.data;
            /* carga de errores */
            if (transactionData.backendFormErrors?.cdpErrorFields) {
                const { cdpErrorFields } = transactionData.backendFormErrors;

                cdpErrorFields.map((errorField) => {
                    if (errorField === "valueDate") {
                        setFieldError("scheduler", SPACE_STR);
                        setFieldTouched("scheduler", true);

                        transactionData.scheduler = {
                            editing: true,
                            valueDate: transactionData?.valueDate,
                            selectedOption: transactionData?.selectedOption,
                        };
                    } else {
                        setFieldError(errorField, SPACE_STR);
                        setFieldTouched(errorField, true);
                    }

                    return null;
                });
            }

            if (transactionData.backendFormErrors?.message) {
                const errorMessage = transactionData?.backendFormErrors?.message;

                dispatch(
                    SelectorActionNotification.showNotification({
                        message: errorMessage,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.COMEX_EXPORT_CESSION_CREDIT_LETTER],
                    }),
                );
            }

            /*  carga del draft - modificaciones para que agarren bien los fields */
            if (transactionData.valueDate) {
                const valueDate = toDate(transactionData.valueDate);

                transactionData.scheduler = {
                    editing: true,
                    valueDate,
                    selectedOption: transactionData.selectedOption,
                };

                transactionData.valueDate = valueDate;
            }

            if (transactionData.notificationEmails && typeof transactionData.notificationEmails === "string") {
                transactionData.notificationEmails = transactionData.notificationEmails.split(COMMA);
            }

            if (transactionData.transferAmount && typeof transactionData.transferAmount === "number") {
                transactionData.transferAmount = {
                    amount: transactionData.transferAmount,
                    currency: transactionData.transferAmountCurrency,
                };
            }

            setValues(transaction.data);
        }
    }

    handleItemSelect = (item) => {
        const { setFieldValue } = this.props;
        const { othersFields } = item;

        if (othersFields) {
            setFieldValue("operationNumber", othersFields.NROOPERACION);
            setFieldValue("transferAmount", {
                amount: parseFloat(othersFields.IMPORTE),
                currency: othersFields.MONEDA,
            });
            setFieldValue("item", item);
        } else {
            setFieldValue("item", null);
            setFieldValue("operationNumber", EMPTY_STR);
        }
    };

    handleProSelect = () => {
        const { setFieldValue } = this.props;

        setFieldValue("expensesAssignment", "CESIONARIO");
    };

    handleBlur = () => {
        const { preData, setFieldValue, values } = this.props;
        const { availableOperationList } = preData;

        if (values.operationNumber) {
            const operationSelected = availableOperationList.filter(
                (item) => item.othersFields.NROOPERACION === values.operationNumber,
            );

            if (operationSelected.length > 0) {
                const item = operationSelected[0];

                setFieldValue("item", item);
                setFieldValue("transferAmount", {
                    amount: parseFloat(item.othersFields.IMPORTE),
                    currency: item.othersFields.MONEDA,
                });
            } else {
                setFieldValue("item", null);
                setFieldValue("operationNumber", EMPTY_STR);
                setFieldValue("transferAmount", EMPTY_STR);
            }
        } else {
            setFieldValue("item", null);
            setFieldValue("operationNumber", EMPTY_STR);
            setFieldValue("transferAmount", EMPTY_STR);
        }
    };

    handleModalLoad = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionComex.toggleCreditLetterList());
    };

    // TEMPLATE AND DRAFT
    handleCancel = () => {
        const { dispatch, setSubmitting, transaction } = this.props;

        dispatch(
            SelectorsActionForm.cancelTransactionCustom({
                credentials: {},
                idTransaction: transaction.idTransaction,
                formikBag: { setSubmitting },
            }),
        );
    };

    handleDraft = () => {
        const { dispatch, transaction, values } = this.props;

        dispatch(
            SelectorsActionForm.saveDraft({
                idActivityDraft: "comex.export.cessionCreditLetter.send",
                idTransactionToSave: transaction?.idTransaction,
                transactionData: values || {},
            }),
        );
    };

    handleTemplateSave = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionTemplate.createTemplate());
    };

    handleTemplateLoad = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionTemplate.toggleTemplateList());
    };

    handleSelectTemplate = (template) => {
        const { dispatch, setErrors, setValues } = this.props;
        const templateToBeLoaded = template;

        if (templateToBeLoaded.scheduler.valueDate) {
            const valueDate = toDate(templateToBeLoaded.scheduler.valueDate);

            templateToBeLoaded.scheduler.valueDate = valueDate;
        }

        setValues(templateToBeLoaded);
        dispatch(SelectorsActionForm.saveData());
        setErrors({});
    };

    step1Form = () => {
        const { emailValidationRegex, errors, preData, setFieldValue, transaction, values } = this.props;
        const { nextValidDate, unavailableDays } = preData;
        const cancelEnabled = transaction && transaction.idTransactionStatus === STATUS.DRAFT;
        const othersFields = values.item ? values.item.othersFields : null;

        const termsAndConditions = "comex.export.cessionCreditLetter.disclaimer";
        const i18nMap = {
            termsAndConditions,
        };

        const currency = othersFields ? othersFields.MONEDA : EMPTY_STR;

        const amountData = {
            options: [
                {
                    id: currency,
                    label: UtilsI18n.get(`currency.label.${currency}`),
                },
            ],
        };

        return (
            <React.Fragment>
                <div className="form-section loadTemplates">
                    <Button
                        onClick={this.handleTemplateLoad}
                        label="forms.templates.load"
                        bsStyle="default"
                        image="template.svg"
                    />
                </div>
                <section className="fields container--layout align-items-center flex-grow">
                    <Box className="form-section-title">
                        <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.section.operation`)}</h3>
                    </Box>
                    {/* Operation Number */}
                    <Box flex align="end" className="form-group search-field">
                        <Field
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            name="operationNumber"
                            onBlur={this.handleBlur}
                            pattern={ONLY_NUMBER}
                            tooltip={UtilsI18n.get("comex.export.transferCreditLetter.operationNumber.tooltip")}
                            type="text"
                            maxLength={999}
                        />

                        <Box className="ml-3 mb-2">
                            <Button
                                onClick={this.handleModalLoad}
                                label="global.search"
                                bsStyle="primary"
                                className="search"
                            />
                        </Box>
                    </Box>
                    {values.item && <OperationNumberDetail othersFields={othersFields} idForm={FORM_ID} />}
                    <Field
                        component={Selector}
                        idForm={FORM_ID}
                        hidePlaceholder
                        name="pro"
                        className="flex-container slideFromBottom selector-letter"
                        optionClassName="needsclick"
                        options={this.proOptions}
                        renderAs="combo"
                        creatable={false}
                        clearable={false}
                        onChange={this.handleProSelect}
                    />
                    <Field
                        className="form-control"
                        clearable={false}
                        component={AmountField}
                        data={amountData}
                        decimalPlaces={2}
                        fixedDecimalScale
                        idForm={FORM_ID}
                        name="transferAmount"
                        placeholder={EMPTY_STR}
                        searchable={false}
                        hideCurrency={!currency}
                        maxLength={getInteger("amount.length")}
                    />
                    {values.pro === "TERCEROS" && (
                        <React.Fragment>
                            <Field
                                className="form-control"
                                component={TextField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                maxLength={65}
                                mode={MODE.EDIT}
                                name="assigneeName"
                                type="text"
                                tooltip={UtilsI18n.get("comex.export.cessionCreditLetter.assigneeName.tooltip")}
                            />
                            <Field
                                className="form-control"
                                component={TextField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                maxLength={250}
                                mode={MODE.EDIT}
                                name="assigneeBank"
                                type="text"
                                tooltip={UtilsI18n.get("comex.export.cessionCreditLetter.assigneeBank.tooltip")}
                            />
                            <Field
                                className="form-control"
                                component={TextField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                maxLength={65}
                                mode={MODE.EDIT}
                                name="assigneeAccountNumber"
                                type="text"
                                tooltip={UtilsI18n.get(
                                    "comex.export.cessionCreditLetter.assigneeAccountNumber.tooltip",
                                )}
                            />
                            <Field
                                component={Selector}
                                idForm={FORM_ID}
                                hidePlaceholder
                                name="expensesAssignment"
                                className="flex-container slideFromBottom selector-letter"
                                optionClassName="needsclick"
                                options={this.expensesAssignmentOptions}
                                renderAs="combo"
                                creatable={false}
                                clearable={false}
                            />
                        </React.Fragment>
                    )}
                    <Box className="form-group">
                        <Field
                            component={FileUploader}
                            emptyMessageKey={`${FORM_ID}.files.emptyMessage`}
                            errors={errors}
                            idForm={FORM_ID}
                            maxFiles={5}
                            name="files"
                            optional={UtilsI18n.get("form.field.optional")}
                            setFieldValue={setFieldValue}
                            values={values}
                        />
                    </Box>
                    <Field
                        className="form-control"
                        component={TextArea}
                        hidePlaceholder
                        idForm={FORM_ID}
                        maxLength={500}
                        mode={MODE.EDIT}
                        name="observations"
                        optional={UtilsI18n.get("form.field.optional")}
                    />
                    <Field
                        className="form-group email-field"
                        component={EmailList}
                        data={{ emailValidationRegex, addMessage: EMPTY_STR }}
                        idForm={FORM_ID}
                        name="notificationEmails"
                        hideSwiftTip
                        optional={UtilsI18n.get("form.field.optional")}
                        renderSuggestion={false}
                        tooltip={UtilsI18n.get(`${FORM_ID}.notificationEmails.tooltip`)}
                        value={values.notificationEmails}
                    />
                </section>
                <section className="fields container--layout align-items-center flex-grow">
                    <Box className="form-section-title">
                        <h3 className="form-section-title-text">Previsualizar carta</h3>
                    </Box>
                    <Field
                        component={Termsandconditions}
                        idForm={FORM_ID}
                        name="disclaimer"
                        mode={MODE.EDIT}
                        className="form-control"
                        i18nMap={i18nMap}
                    />
                    {nextValidDate && (
                        <Field
                            component={Scheduler}
                            data={{
                                firstWorkingDate: nextValidDate,
                                maxDaysToSchedule: ConfigUtil.get("client.comex.valueDate.maxDaysToSchedule"),
                                nonWorkingDays: unavailableDays,
                                lang: LANGUAGE.ES,
                                mode: MODE.EDIT,
                                programable: false,
                                schedulable: true,
                            }}
                            name="scheduler"
                            idForm={FORM_ID}
                        />
                    )}
                </section>
                <footer>
                    <Button
                        onClick={this.handleTemplateSave}
                        label="forms.saveTemplate.link"
                        className="templateSave"
                    />
                    {cancelEnabled && <Button onClick={this.handleCancel} label="forms.cancelTransaction.link" />}
                    <Button
                        key="primaryButton"
                        variant="primary"
                        bsStyle="primary"
                        label="comex.imports.creditLetter.form.attachments.next"
                        loading={false}
                        type="submit"
                    />
                </footer>
            </React.Fragment>
        );
    };

    render() {
        const { preData, values } = this.props;
        const { availableOperationList } = preData;

        return (
            <React.Fragment>
                <Form id={Style.ID} className="col col-12 col-lg-6 col-md-9 col-sm-12 creditLetter">
                    {this.step1Form()}
                </Form>

                <ListTemplatesModal
                    idActivityTemplate="comex.imports.creditLetterOpen.send"
                    onSelect={this.handleSelectTemplate}
                />
                <CreateTemplateModal values={values} idActivityTemplate="comex.export.cessionCredditLetter.send" />
                <OperationNumber
                    list={availableOperationList}
                    idForm={FORM_ID}
                    handleItemClick={this.handleItemSelect}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    emailValidationRegex: SelectorsStoreConfig.getConfig(store)["email.validationFormat"],
    fetching: SelectorsStoreComex.getFetching(store),
    formData: SelectorsStoreComexExport.getComexFormData(store),
    preData: SelectorsStoreComexExport.getComexFormPre(store),
    transaction: SelectorsStoreComex.getTransaction(store),
});

export default HighOrder(
    WithRouter,
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: false,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ formData }) => {
            const attachments = formData?.attachments;
            const hasAttachments = attachments !== undefined && attachments !== EMPTY_STR;

            return {
                assigneeAccountNumber: formData?.assigneeAccountNumber ? formData.assigneeAccountNumber : EMPTY_STR,
                assigneeBank: formData?.assigneeBank ? formData.assigneeBank : EMPTY_STR,
                assigneeName: formData?.assigneeName ? formData.assigneeName : EMPTY_STR,
                attachmentsNames: formData?.attachmentsNames || [],
                expensesAssignment: formData?.expensesAssignment ? formData.expensesAssignment : EMPTY_STR,
                files: hasAttachments ? attachments?.split(COMMA) : [],
                item: formData?.item || null,
                notificationEmails: formData?.notificationEmails ? formData?.notificationEmails.split(COMMA) : [],
                observations: formData?.observations || EMPTY_STR,
                operationNumber: formData?.operationNumber || EMPTY_STR,
                pro: formData?.pro ? formData.pro : BANK_OPTION,
                scheduler: formData?.valueDate
                    ? { editing: true, selectedOption: formData?.selectedOption, valueDate: formData?.valueDate }
                    : null,
                transferAmount: { amount: formData?.transferAmount, currency: formData?.transferAmountCurrency },
            };
        },
        handleSubmit: (data, formikBag) => {
            const { dispatch } = formikBag.props;
            const { item, scheduler, files, ...rest } = data;

            const attachmentsFiles = files ? files.join(COMMA) : EMPTY_STR;
            const valueDateString = scheduler ? FormatDate(scheduler.valueDate) : EMPTY_STR;
            const dateParts = item?.othersFields?.FECHAVTO ? item.othersFields.FECHAVTO.split("/") : null;
            const operationNumberString = rest.operationNumber ? rest.operationNumber : EMPTY_STR;
            const notificationEmails = rest.notificationEmails ? rest.notificationEmails.join(COMMA) : EMPTY_STR;
            // month is 0-based, that's why we need dataParts[1] - 1
            const dueDate = dateParts
                ? FormatDate(new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]))
                : EMPTY_STR;
            const currency = item?.othersFields?.MONEDA ? item.othersFields.MONEDA : EMPTY_STR;
            const transferAmount = rest.transferAmount ? rest.transferAmount.amount : EMPTY_STR;

            const formData = {
                ...rest,
                amount: { quantity: transferAmount, currency }, // to show in pending transactions
                attachments: attachmentsFiles,
                creditLetterNumber: item?.othersFields?.CARTADECREDITO ? item.othersFields.CARTADECREDITO : EMPTY_STR,
                currency,
                dueDate,
                issuingBank: item?.othersFields?.EMISOR ? item.othersFields.EMISOR : EMPTY_STR,
                item,
                notificationEmails,
                operationNumber: operationNumberString,
                originalAmount: item?.othersFields?.IMPORTE ? item.othersFields.IMPORTE : EMPTY_STR,
                scheduler,
                selectedOption: scheduler?.selectedOption,
                transferAmount,
                valueDate: valueDateString,
            };

            dispatch(SelectorsActionComexExport.cessionCreditLetterPreviewRequest({ formData, formikBag }));
        },
    }),
)(Component);
