import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR, FORMAT_SHORT } from "~/constants";
import { get as Get } from "~/util/config";
import { nowFormat as NowFormat, specificDate as SpecificDate, toDate as ToDate } from "~/util/date";
import UtilLodash from "~/util/lodash";

import GenericDate from "~/components/GenericDate";
import withFocus from "~/pages/_components/withFocus";

import formField from "~/pages/forms/_components/_fields/_commons/formField";

export const NAME = "Date";

export const PROP = {
    types: {
        dateFormat: PropTypes.string,
        editing: PropTypes.bool,
        name: PropTypes.string.isRequired,
        onBlur: PropTypes.func.isRequired,
        placeholder: PropTypes.string,
        setValue: PropTypes.func.isRequired,
        value: PropTypes.oneOfType([PropTypes.instanceOf(Date)]),
    },
    defaults: { dateFormat: FORMAT_SHORT, editing: false, placeholder: EMPTY_STR, value: null },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = { startDate: null };

    constructor(props) {
        super(props);

        const { setValue, subType, value } = props;

        if (subType !== "emptyDate") {
            setValue(value || NowFormat(FORMAT_SHORT));
        }
    }

    handleChangeRaw = (event) => {
        event.preventDefault();
    };

    handleDateChange = (date) => {
        const { formData } = this.props;

        this.setState({
            startDate: date,
        });

        // Hack for allowing to select the same date as today and avoid the unwanted behaivour
        // produced by Axios' conversion to UTC
        if (formData) {
            const { timezoneOffset } = formData;

            if (timezoneOffset) {
                date.setMinutes(formData.timezoneOffset - date.getTimezoneOffset());
            }
        }

        const { setValue } = this.props;

        let value = null;

        if (date) {
            value = date;
        }

        setValue(value);
    };

    render() {
        const { dateFormat, editing, name, onBlur, placeholder, subType, value } = this.props;
        let element = null;

        const CustomInput = React.forwardRef((props, ref) => (
            <input onClick={props.onClick} value={props.value} type="text" readOnly ref={ref} />
        ));

        if (editing) {
            const { startDate } = this.state;

            let dateValue = startDate;

            if (value) {
                dateValue = ToDate(value);
            }

            element = (
                <GenericDate
                    customInput={<CustomInput />}
                    id={name}
                    dateFormat={Get("frontend.shortDateFormat")}
                    name={name}
                    selected={dateValue}
                    className="form-control"
                    onChange={this.handleDateChange}
                    onChangeRaw={this.handleChangeRaw}
                    placeholderText={placeholder}
                    showMonthYearDropdown={false}
                    onBlur={onBlur}
                    subType={subType}
                />
            );
        } else {
            element = <span>{SpecificDate(value, dateFormat)}</span>;
        }

        return element;
    }
}

export default UtilLodash.flowRight(withFocus, formField({ formClass: "form-group--datepicker" }))(Component);
