import React from "react";

import { goBack as GoBack } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { MODE } from "~/constants/form";
import { SelectorsStore as CustomsPaymentSelectors } from "~/store/customsPayment";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Box from "~/components/Box";
import DataDate from "~/components/DataDate";
import DataNumber from "~/components/DataNumber";
import GridLayout from "~/components/GridLayout";

import Checkbox from "~/pages/forms/_components/_fields/_commons/Checkbox";

export const NAME = "payments.customsPayment.step1.table";

export const PROP = {
    types: {
        isFromBackoffice: PropTypes.bool,
        fetching: PropTypes.bool.isRequired,
        ...TypesRedux,
    },
    defaults: {
        isFromBackoffice: false,
        i18n: {},
        ...DefaultsRedux,
    },
};

export class Component extends React.Component {
    HandleBack = () => {
        const { dispatch } = this.props;
        dispatch(GoBack());
    };

    handleCheckClick = (merchantPaymentId, index) => {
        const { mode, selectPayment } = this.props;
        if (mode !== MODE.VIEW && selectPayment) {
            selectPayment(merchantPaymentId, index);
        }
    };

    handleCheck = () => {};

    render() {
        const { paymentItems, fetched, totalAmount, checkboxesValues, mode, currency } = this.props;
        const viewMode = mode === MODE.VIEW;
        const COLUMN_HEADINGS = [
            UtilsI18n.get(`${NAME}.header.columnOne`),
            UtilsI18n.get(`${NAME}.header.columnTwo`),
            UtilsI18n.get(`${NAME}.header.columnThree`),
            UtilsI18n.get(`${NAME}.header.columnFour`),
            UtilsI18n.get(`${NAME}.header.columnFive`),
        ];

        return (
            <React.Fragment>
                <GridLayout className="header">
                    {COLUMN_HEADINGS.map((heading, index) => (
                        <Box key={`payment-header-${heading}`} className="web-only" justify={index === 4 && "end"}>
                            {heading}
                        </Box>
                    ))}
                </GridLayout>
                {paymentItems.map(
                    (
                        { merchantPaymentId, description, totalAmount: amount, expiration, status, currencyCode },
                        index,
                    ) => (
                        <GridLayout
                            onClick={() => this.handleCheckClick(merchantPaymentId, index)}
                            className="body"
                            key={`payment-row-${merchantPaymentId}`}>
                            <Box>
                                <Checkbox
                                    checked={(checkboxesValues && checkboxesValues[index]) || viewMode}
                                    readOnly={viewMode}
                                    onChange={this.handleCheck}
                                />
                                {merchantPaymentId}
                            </Box>
                            <Box>{description}</Box>
                            <Box>
                                <Box flex directionColumn align="start">
                                    <DataDate value={expiration} />
                                </Box>
                            </Box>
                            <Box className="web-only">{UtilsI18n.get(`${NAME}.status.${status}`)}</Box>
                            <Box justify="end">
                                <DataNumber value={amount} prefix={currencyCode} className="mobile-only" />
                            </Box>
                        </GridLayout>
                    ),
                )}
                {paymentItems.length === 0 && fetched && (
                    <div className="emtpyRow">{UtilsI18n.get(`${NAME}.emtpyRow`)}</div>
                )}
                {paymentItems.length > 0 && !viewMode && (
                    <div>
                        <div className="totalAmount">
                            <p className="boldText">{UtilsI18n.get(`${NAME}.allPaymentsSelected`)} </p>
                            <DataNumber value={totalAmount} prefix={currency} className="mobile-only" />
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    fetching: CustomsPaymentSelectors.getFetching(store),
});

export default Connect(mapStateToProps)(Component);
