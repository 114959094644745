import React from "react";

import { routerActions as ActionsRouter } from "connected-react-router";
import { Form } from "formik";
import PropTypes from "prop-types";
import { Modal, Grid, Row } from "react-bootstrap";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";

import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsAction as SelectorsActionTransfers } from "~/store/transfers";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";

import Style from "./_ModalTransferRedirect.rules.scss";

export const NAME = "ModalTransferRedirect";

export const PROP = {
    types: {
        showModal: PropTypes.bool,
    },
    defaults: {
        showModal: false,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    handleSelect = (selectedOption) => {
        const { dispatch, form, hsbcAccounts, transferAlias } = this.props;

        const account = hsbcAccounts[selectedOption];

        dispatch(ActionsRouter.push("/form/transferThirdParties"));

        dispatch(
            SelectorsActionForm.setData({
                data: {
                    amount: form.values.amount,
                    otherAccountCurrency: account.currency,
                    otherAccountNumber: account.accountNumber,
                    otherAccountType: account.accountType,
                    redirectionPerformed: true,
                    selectedOption,
                    transferAlias: {
                        aliasData: transferAlias.aliasData,
                        aliasType: transferAlias.aliasType,
                    },
                },
            }),
        );

        dispatch(SelectorsActionTransfers.toggleShowModal({ showModal: false }));
    };

    hideModal = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionTransfers.toggleShowModal({ showModal: false }));
    };

    showModal = () => {
        const { optionList } = this.props;

        return (
            <Modal show onHide={this.hideModal}>
                <Form className="modal-container" id={Style.ID}>
                    <Modal.Header closeButton>
                        <section className="container--layout align-items-center">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Modal.Title>
                                        <I18n id="client.transfer.alias.modal.title" />
                                    </Modal.Title>
                                </Row>
                            </Grid>
                        </section>
                    </Modal.Header>
                    <Modal.Body>
                        <section className="container--layout align-items-center flex-grow">
                            <Grid className="form-content">
                                <Row className="justify-content-center control-label-custom">
                                    <I18n id="client.transfer.alias.modal.text1" />
                                </Row>
                                <Row className="justify-content-center control-label-center">
                                    <I18n id="client.transfer.alias.modal.text2" />
                                </Row>
                                <div className="form-group-control-list">
                                    {optionList.map(({ id, label }) => (
                                        <Button
                                            bsStyle="primary"
                                            label={<span>{label}</span>}
                                            onClick={() => this.handleSelect(id)}
                                            type="button"
                                        />
                                    ))}
                                </div>
                            </Grid>
                        </section>
                        <section className="container--layout align-items-center">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <I18n id="client.transfer.alias.modal.text3" />
                                </Row>
                                <Button
                                    label="client.transfer.alias.modal.button.text"
                                    onClick={this.hideModal}
                                    type="button"
                                />
                            </Grid>
                        </section>
                    </Modal.Body>
                </Form>
            </Modal>
        );
    };

    render() {
        const { showModal } = this.props;

        return <React.Fragment>{showModal && this.showModal()}</React.Fragment>;
    }
}

export default Compose(Connect(), HighOrder.Resizable)(Component);
