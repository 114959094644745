import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { SelectorsStore as SelectorsStoreForm } from "~/store/form";
import { SelectorsAction as ActionsBankSelector } from "~/store/bankSelector";

import I18n from "~/components/I18n";
import Image from "~/components/Image";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import FormattedRate from "~/pages/_components/FormattedRate";

export const NAME = "TransferForeign";

export const PROP = {
    types: {
        previewData: PropTypes.shape({
            rateFirstCurrency: PropTypes.string,
            rateSecondCurrency: PropTypes.string,
            debitAmount: PropTypes.shape({
                currency: PropTypes.string,
                quantity: PropTypes.number,
            }).isRequired,
            amount: PropTypes.shape({
                currency: PropTypes.string,
                quantity: PropTypes.number,
            }).isRequired,
            creditCardAlias: PropTypes.string,
            debitAccountAlias: PropTypes.string,
            rate: PropTypes.number,
            manualRevisionWarning: PropTypes.bool.isRequired,
            transferWithoutFundsWarning: PropTypes.bool,
        }).isRequired,
        creditBankCode: PropTypes.shape({
            bank: PropTypes.shape({
                bankCountryLabel: PropTypes.string,
                bankName: PropTypes.string,
            }),
        }),
    },
    defaults: {
        creditBankCode: {
            bank: {
                bankCountryLabel: "",
                bankName: "",
            },
        },
    },
};

export function Component(props) {
    const { previewData, dispatch } = props;
    const {
        amount,
        debitAmount,
        debitAccountAlias,
        creditAmount,
        creditAccountAlias,
        returnCode,
        rate,
        creditBankCode,
        rateFirstCurrency,
        rateSecondCurrency,
        manualRevisionWarning,
        transferWithoutFundsWarning,
    } = previewData;
    const { bank } = creditBankCode;

    //Se quita el banco seleccionado al desmontar el componente
    React.useLayoutEffect(() => {
        return () => {
            dispatch(ActionsBankSelector.clearSelectedBank());
        };
    }, [dispatch]);

    const showDebitAmount = debitAmount.currency !== amount.currency;
    const showCreditAmount = creditAmount.currency !== amount.currency;
    const renderAmount = (className, label, amountToRender) => (
        <div className={`transfer-data ${className}`}>
            <span className="data-label">
                <I18n id={`forms.transferLocal.confirmation.${label}`} />{" "}
            </span>
            <FormattedAmount currency={amountToRender.currency} quantity={amountToRender.quantity} />
        </div>
    );
    const renderRate = (className, rateToRender, firstCurrency, secondCurrency) => (
        <div className={`transfer-data ${className}`}>
            <span className="data-label">
                <I18n id="forms.confirmation.rate" />{" "}
            </span>
            <FormattedRate firstCurrency={firstCurrency} secondCurrency={secondCurrency} quantity={rateToRender} />
        </div>
    );

    if (returnCode === 28) {
        return (
            <React.Fragment>
                <p className="text-lead">
                    <I18n id="forms.transferForeign.confirmation.manualRevisionWarning" />
                </p>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <p className="text-lead">
                <I18n id="forms.transferLocal.confirmation.title" />
            </p>

            <div className="transfer-block">
                {renderAmount("transfer-data-highlight", "amount", amount)}

                {showCreditAmount && (
                    <React.Fragment>
                        {renderAmount("data-wrapper", "creditAmount", creditAmount)}
                        {renderRate("data-wrapper", rate, rateFirstCurrency, rateSecondCurrency)}
                    </React.Fragment>
                )}

                {showDebitAmount && (
                    <React.Fragment>
                        {renderAmount("data-wrapper", "debitAmount", debitAmount)}
                        {renderRate("data-wrapper", rate, rateFirstCurrency, rateSecondCurrency)}
                    </React.Fragment>
                )}

                {manualRevisionWarning && (
                    <span className="data-warning">
                        <I18n id="client.transaction.manualRevisionWarning" />
                    </span>
                )}

                {transferWithoutFundsWarning && (
                    <span className="data-warning">
                        <I18n id="client.transaction.transferWithoutFundsWarning" />
                    </span>
                )}

                <div className="transfer-data transfer-data-flow">
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="forms.transferLocal.confirmation.debitAccount" />{" "}
                        </span>
                        <span className="data-name">{debitAccountAlias}</span>
                    </div>
                    <Image src="arrowRight.svg" className="svg-icon" />
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="forms.transferLocal.confirmation.creditAccount" />{" "}
                        </span>
                        <span className="data-name">{creditAccountAlias}</span>
                        <span className="data-name">{bank.bankName}</span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (state) => ({
    previewData: SelectorsStoreForm.getPreviewData(state),
});

export default connect(mapStateToProps)(Component);
