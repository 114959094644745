import React from "react";

import PropTypes from "prop-types";
import { Grid, Row, Col } from "react-bootstrap";
import { connect as Connect } from "react-redux";

import { REACT_APP_VERSION, EMPTY_STR, ENVIRONMENT_TYPE, LEVEL, SPACE_STR, SCOPE } from "~/constants";
import { TRIMMED_MENU_SETTINGS } from "~/constants/release-conf";
import Container from "~/containers/Internal/Form/Simple";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionSession, SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsStore as SelectorsStoreSettings, SelectorsAction as SelectorsActionSettings } from "~/store/settings";
import { generateId as Id } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";
import { getLastLoginDate as LastLoginDate, getLastLoginPlace as LastLoginPlace } from "~/util/settings";

import I18n from "~/components/I18n";
import Link from "~/components/Link";
import MainContainer from "~/pages/_components/MainContainer";

import SettingsOption from "~/pages/settings/_components/SettingsOption";

export const NAME = "Settings";

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        environments: PropTypes.objectOf(
            PropTypes.shape({
                environmentType: PropTypes.string,
                name: PropTypes.string,
            }),
        ),
        isMobile: PropTypes.bool,
        user: PropTypes.shape({
            defaultAvatarId: PropTypes.string,
            email: PropTypes.string.isRequired,
            userFullName: PropTypes.string.isRequired,
        }).isRequired,
    },
    defaults: {
        environments: null,
        isMobile: false,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = { fetching: false };

    constructor(props) {
        super(props);

        this.containerRef = React.createRef();
    }

    componentDidMount() {
        const { dispatch, environmentType, isMobile } = this.props;

        if (isMobile && this.containerRef.current.clientHeight > document.body.clientHeight) {
            this.setState({});
        }

        dispatch(SelectorsActionSettings.modifyDateAccountsRequest());
        dispatch(SelectorsActionSettings.changePersonalData({ environmentType }));
    }

    logOut = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionSession.logout());
    };

    renderVersion = () => {
        if (REACT_APP_VERSION) {
            const version = REACT_APP_VERSION;

            return (
                <React.Fragment>
                    <I18n id="global.version" /> {version}
                </React.Fragment>
            );
        }

        return EMPTY_STR;
    };

    stepForward = () => {
        this.setState({ fetching: true });
    };

    handleBack = () => {
        const { history } = this.props;

        history.goBack();
    };

    handleClickChangeAccountInfo = () => {
        const { changeAccountDataInProgress, dispatch } = this.props;

        if (changeAccountDataInProgress) {
            dispatch(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("client.userconfiguration.accounts.data.transactionIsNotAllowed"),
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.SETTINGS],
                }),
            );
        }
    };

    render() {
        const { changeAccountDataInProgress, environments, environmentType, fetchingAccountData, user } = this.props;
        const { fetching } = this.state;

        const baseUrl = "/settings";
        const msgKeyPre = "settings.options";
        const version = this.renderVersion();

        let personalInformation = {
            changePersonalInformation: (
                <SettingsOption
                    linkTo={`${baseUrl}/changePersonalInformation/form`}
                    messageKey={`${msgKeyPre}.changePersonalInformation`}
                />
            ),
        };

        if (environmentType !== ENVIRONMENT_TYPE.CORPORATE) {
            personalInformation = {
                ...personalInformation,
                changePersonalAccount: (
                    <SettingsOption
                        onClick={this.handleClickChangeAccountInfo}
                        linkTo={changeAccountDataInProgress ? baseUrl : `${baseUrl}/changeDataAccounts/form`}
                        messageKey={`${msgKeyPre}.changeDataAccounts`}
                    />
                ),
            };
        }

        const securityOptions = {
            changeEmail: <SettingsOption linkTo={`${baseUrl}/changeEmail`} messageKey={`${msgKeyPre}.changeEmail`} />,
            changePhone: <SettingsOption linkTo={`${baseUrl}/changePhone`} messageKey={`${msgKeyPre}.changePhone`} />,
            changePassword: (
                <SettingsOption
                    linkTo={`${baseUrl}/changePassword`}
                    messageKey={`${msgKeyPre}.changePassword`}
                    onClick={this.stepForward}
                />
            ),
            changeSecuritySeal: (
                <SettingsOption
                    linkTo={`${baseUrl}/changeSecuritySeal`}
                    messageKey={`${msgKeyPre}.changeSecuritySeal`}
                />
            ),
        };

        const configurationOptions = {
            biometricsConfiguration: (
                <SettingsOption
                    linkTo={`${baseUrl}/fingerprintConfiguration`}
                    messageKey={`${msgKeyPre}.biometricsConfiguration`}
                    onClick={this.stepForward}
                />
            ),
            notificationsConfiguration: (
                <SettingsOption
                    linkTo={`${baseUrl}/notificationsConfiguration`}
                    messageKey={`${msgKeyPre}.notificationsConfiguration`}
                />
            ),
            changeEnvironment:
                environments && Object.keys(environments).length > 1 ? (
                    <SettingsOption
                        linkTo={`${baseUrl}/changeEnvironment`}
                        messageKey={`${msgKeyPre}.changeEnvironment`}
                        onClick={this.stepForward}
                    />
                ) : null,
            changeLanguage: (
                <SettingsOption linkTo={`${baseUrl}/changeLanguage`} messageKey={`${msgKeyPre}.changeLanguage`} />
            ),
        };

        const otherOptions = {
            privacy: <SettingsOption linkTo={`${baseUrl}/privacy`} messageKey="global.privacy" />,
            termAndConditions: (
                <SettingsOption linkTo={`${baseUrl}/termsAndConditions`} messageKey="global.termAndConditions" />
            ),
            support: <SettingsOption linkTo={`${baseUrl}/support`} messageKey="global.support" />,
        };

        const availablePersonalInformation = [];

        TRIMMED_MENU_SETTINGS.information.forEach((option) => {
            if (!option.value) {
                availablePersonalInformation.push(option.key);
            }
        });

        const availableSecurityOptions = [];

        TRIMMED_MENU_SETTINGS.security.forEach((option) => {
            if (!option.value) {
                availableSecurityOptions.push(option.key);
            }
        });

        const availableConfigurationOptions = [];

        TRIMMED_MENU_SETTINGS.configuration.forEach((option) => {
            if (!option.value) {
                availableConfigurationOptions.push(option.key);
            }
        });

        const availableOtherOptions = [];

        TRIMMED_MENU_SETTINGS.other.forEach((option) => {
            if (!option.value) {
                availableOtherOptions.push(option.key);
            }
        });

        return (
            <Container
                name={NAME}
                head-title="settings.index"
                head-onBackWeb={this.handleBack}
                scopeToShowNotification={SCOPE.SETTINGS}
                wait={fetchingAccountData}>
                <div className="view-morphing">
                    <section className="container--layout align-items-center section-content-heading">
                        <Grid>
                            <Row className="justify-content-center">
                                <Component.SettingsData user={user} />
                            </Row>
                        </Grid>
                    </section>
                </div>
                <MainContainer showLoader={fetching}>
                    <div className="above-the-fold tight-containers" ref={this.containerRef}>
                        <section className="container--layout flex-grow align-items-center">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Component.Section
                                        titleKey="personalInformation"
                                        options={personalInformation}
                                        available={availablePersonalInformation}
                                        className=""
                                    />
                                    <Component.Section
                                        titleKey="security"
                                        options={securityOptions}
                                        available={availableSecurityOptions}
                                        className=""
                                    />
                                    <Component.Section
                                        titleKey="configuration"
                                        options={configurationOptions}
                                        available={availableConfigurationOptions}
                                        className=""
                                    />
                                </Row>
                            </Grid>
                        </section>
                        <section className="container--layout align-items-center ">
                            <Grid>
                                <Row className="justify-content-center">
                                    <Col sm={12} className="col">
                                        <Component.SectionItems
                                            options={otherOptions}
                                            available={availableOtherOptions}
                                        />
                                    </Col>
                                    <Col className="col col-12">
                                        <div className="flex-container">
                                            <small className="text-muted">{version}</small>
                                        </div>
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </div>
                </MainContainer>
            </Container>
        );
    }
}

Component.PreviousLoginInfoSection = function PreviousLoginInfoSection(props) {
    const { loginInfo } = props;

    return (
        <Col sm={12} md={4} lg={4} className="col col-12 content-data-wrapper">
            <span className="data-wrapper data-wrapper-inline content-data-small">
                <span className="data-desc">
                    <I18n id="settings.lastLogin.date" />
                    {SPACE_STR}
                </span>
                <span className="data-data">
                    <strong>{LastLoginDate(loginInfo)}</strong>
                </span>
            </span>
            <span className="data-wrapper data-wrapper-inline content-data-small">
                <span className="data-desc">
                    <I18n id="settings.lastLogin.from" />
                    {SPACE_STR}
                </span>
                <span className="data-desc">
                    <Link to="/settings/lastLogin">
                        <strong>{LastLoginPlace(loginInfo)}</strong>
                    </Link>
                </span>
            </span>
        </Col>
    );
};

Component.PreviousLoginInfoSection.propTypes = {
    loginInfo: PropTypes.shape({
        city: PropTypes.string,
        country: PropTypes.string,
        date: PropTypes.string,
        idUser: PropTypes.string,
        ip: PropTypes.string,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
    }).isRequired,
};

Component.SettingsData = function SettingsData(props) {
    const {
        user: { previousLoginInfo, userFullName },
    } = props;

    return (
        <React.Fragment>
            <Col className="col col-12">
                <Grid>
                    <Row className="content-data-row">
                        <Col className="col content-data-wrapper col-12" sm={12} md={4} lg={4}>
                            <span className="data-text content-data-strong">{userFullName}</span>
                        </Col>

                        {previousLoginInfo.length > 0 && (
                            <Component.PreviousLoginInfoSection loginInfo={previousLoginInfo[0]} />
                        )}
                    </Row>
                </Grid>
            </Col>
        </React.Fragment>
    );
};

Component.SettingsData.propTypes = {
    user: PropTypes.shape({
        email: PropTypes.string.isRequired,
        previousLoginInfo: PropTypes.arrayOf(PropTypes.object),
        userFullName: PropTypes.string.isRequired,
    }).isRequired,
};

Component.Section = function Section(props) {
    const { available, className, options, titleKey } = props;

    return (
        available.length > 0 && (
            <Col md={6} sm={12} className={`${className || EMPTY_STR}`}>
                <h4 className="navigational-list-title">
                    <I18n id={`settings.title.${titleKey}`} />
                </h4>
                <Component.SectionItems options={options} available={available} />
            </Col>
        )
    );
};

Component.SectionItems = function SectionItems(props) {
    const { available, options } = props;

    return (
        <ul className="navigational-list">
            {available.map((key) => (
                <React.Fragment key={Id()}>{options[key]}</React.Fragment>
            ))}
        </ul>
    );
};

Component.Section.propTypes = {
    available: PropTypes.arrayOf(PropTypes.string).isRequired,
    className: PropTypes.string,
    options: PropTypes.objectOf(PropTypes.element).isRequired,
    titleKey: PropTypes.string.isRequired,
};

Component.Section.defaultProps = { className: EMPTY_STR };

Component.SectionItems.propTypes = {
    available: PropTypes.arrayOf(PropTypes.string).isRequired,
    options: PropTypes.objectOf(PropTypes.element).isRequired,
};

const mapStateToProps = (store) => ({
    changeAccountDataInProgress: SelectorsStoreSettings.getChangeAccountDataInProgress(store),
    environments: SelectorsStoreSession.getEnvironments(store),
    environmentType: SelectorsStoreSession.getActiveEnvironment(store).type || {},
    fetchingAccountData: SelectorsStoreSettings.getFetchingAccountData(store),
    user: SelectorsStoreSession.getUser(store),
});

export default Connect(mapStateToProps)(Component);
