import {
    addDays as AddDays,
    differenceInCalendarDays as DifferenceInCalendarDays,
    differenceInMonths as DifferenceInMonths,
    eachMonthOfInterval as EachMonthOfInterval,
    format as Format,
    getDate as GetDate,
    getDaysInMonth as GetDaysInMonth,
    getISOWeek as GetISOWeek,
    getMonth as GetMonth,
    getYear as GetYear,
    isDate,
    isFuture as IsFuture,
    isPast as IsPast,
    isSameDay as IsSameDay,
    parseISO,
    setDate as SetDate,
    setHours as SetHours,
    setMilliseconds as SetMilliseconds,
    setMinutes as SetMinutes,
    setMonth as SetMonth,
    setSeconds as SetSeconds,
    setYear as SetYear,
    startOfWeek as StartOfWeek,
    subYears as SubYears,
} from "date-fns";
import { es, enGB as en, pt } from "date-fns/locale";

import { DASH, SLASH } from "~/constants";
import Store from "~/store";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import * as ConfigUtils from "~/util/config";
import { getLang as GetLang } from "~/util/i18n";

export const locales = { en, es, pt };

const days = {
    es: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
    en: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    pt: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
};

const daysShort = {
    es: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
    en: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    pt: ["Do", "Se", "Te", "Qua", "Qui", "Vi", "Sa"],
};

/**
 * Modify the date format
 * @param {String} format -date format
 * @returns {String}: the modified photogram returns the years and days of 'Y' to 'y', 'D' to 'd'
 */
const transformFormat = (format) => format.replace(/Y/g, "y").replace(/D/g, "d");

/**
 * Check if the dates is a date and if it is not transformed into a native Date
 * @param {*} date - data to see if it is a date or not
 * @returns {Date}: return date in Date format
 */
export const toDate = (date) => {
    if (isDate(date)) {
        return date;
    }

    return parseISO(date.split(SLASH).join(DASH));
};

/**
 * Returns the date formatted as required
 * @param {date} date - date to format
 * @param {string} format - datefns format
 */
export const applyFormat = (date, format) => {
    return Format(date, format);
};

export const getLastYear = (date = new Date()) => [
    new Date(date.getFullYear() - 1, date.getMonth(), date.getDate()),
    new Date(),
];

export const getLastMonthPeriod = (date = new Date()) =>
    date.getMonth() === 0
        ? [new Date(date.getFullYear() - 1, 11, 1), new Date(date.getFullYear() - 1, 11, 31)]
        : [new Date(date.getFullYear(), date.getMonth() - 1, 1), new Date(date.getFullYear(), date.getMonth(), 0)];

export const getSecondLastMonthPeriod = (date = new Date()) =>
    date.getMonth() === 0
        ? [new Date(date.getFullYear() - 1, 10, 1), new Date(date.getFullYear() - 1, 10, 30)]
        : [new Date(date.getFullYear(), date.getMonth() - 2, 1), new Date(date.getFullYear(), date.getMonth() - 1, 0)];

export const getCurrentPeriod = (date = new Date()) => [new Date(date.getFullYear(), date.getMonth(), 1), date];

export const getLastSixMonths = (date = new Date()) => [new Date(date.getFullYear(), date.getMonth() - 6, 1), date];

export const getDateSixMonthsAgo = (date = new Date()) => new Date(date.getFullYear(), date.getMonth() - 6, 1);

export const getLastWeek = (date = new Date()) => [StartOfWeek(date, { weekStartsOn: 1 }), date];

export const getToday = (date = new Date()) => [date, date];

// -------------------------------------------------------------------------------
/**
 * check if the date is passed
 * @param {Date} date -date to verify if it is past
 * @returns {boolean} : returns true if it is passed false if it is not
 */
export const isDateLessThanToday = (date) => {
    let result = false;

    if (date instanceof Date) {
        result = IsPast(date);
    }

    return result;
};

// -------------------------------------------------------------------------------
/**
 * check if passed date it's a future date
 * @param {Date} date - validation date
 * @returns {boolean} : returns true if it is passed false if it is not
 */
export const isFutureDate = (date) => {
    if (date) {
        return IsFuture(toDate(date));
    }

    return false;
};

// -------------------------------------------------------------------------------
/**
 * Returns the date instant with the chosen format
 * @param {String} format -the chosen format.
 * @returns {Date} Returns today's formatted date
 */
export const nowFormat = (format) => Format(new Date(), transformFormat(format));

// -------------------------------------------------------------------------------
/**
 * Apply a format to the date
 * @param {Date} date - date to format.
 * @param {String} format -format for the default value date {FORMAT_SHORT}, yyyy-MM-dd
 * @returns {Date} :return formatted date
 */

export const specificDate = (date, format = ConfigUtils.get(`frontend.shortDateFormat.${GetLang()}`)) => {
    const parseFormat = transformFormat(format);

    if (date) {
        return Format(toDate(date), parseFormat);
    }

    return Format(new Date(), parseFormat);
};

// -------------------------------------------------------------------------------
/**
 * Compare if the dates are the same
 * @param {Date} firstDate - first date to compare
 * @param {Date} secondDate - date with which it is met if they are equal default value {new Date ()}
 * @returns {boolean}: returns true or false depending on whether the dates are the same or not
 */
export const isSameDay = (firstDate, secondDate = new Date()) => IsSameDay(firstDate, secondDate);

// -------------------------------------------------------------------------------
/**
 * Add days to the date
 * @param {Date} date - date to which you want to add days
 * @param {Number} cantDays -number of days to add
 * @returns {Date} : return the date with the days added
 */
export const addDay = (date, cantDays) => AddDays(date, cantDays);

// -------------------------------------------------------------------------------
/**
 * generate a date depending on the language
 * @param {Date} date - date to format.
 * @param {String} formatStr -format for the default value date {PP}
 * @returns {Date} :return formatted date
 */
export const i18nDate = (date, formatStr = "PP") =>
    Format(toDate(date), transformFormat(formatStr), {
        locale: locales[SelectorsStoreI18n.getLang(Store.getState())],
    });

// -------------------------------------------------------------------------------
/**
 * Returns the days of the week according to language
 */
export const weekdays = () => days[SelectorsStoreI18n.getLang(Store.getState())];

// -------------------------------------------------------------------------------
/**
 * Returns the days of the week with short format according to language
 */
export const weekdaysMin = () => daysShort[SelectorsStoreI18n.getLang(Store.getState())];

// -------------------------------------------------------------------------------
/**
 * Modify the time of the dates hour, minutes, second
 * @param {Date} date - date to modify
 * @param {Number} hours - Set with the value the hours
 * @param {Number} minute -Set with the value the minute
 * @param {Number} second -Set with the value the second
 */
export const setTime = (date = new Date(), hours = 0, minute = 0, second = 0, milliseconds = 0) =>
    SetHours(SetMinutes(SetSeconds(SetMilliseconds(date, milliseconds), second), minute), hours);

// -------------------------------------------------------------------------------
/**
 * Modify the time with respect to year, month and day
 * @param {Date} date - date to modify
 * @param {Number} year - How many years are subtracted from the current year
 * @param {Number} month - How many months are subtracted from the current month
 * @param {Number} day - Set the day on which you want
 */
export const setDate = (date, year = 0, month = 0, day = 1) =>
    SetYear(SetMonth(SetDate(date, day), GetMonth(new Date()) - month), GetYear(new Date()) - year);

// -------------------------------------------------------------------------------
/**
 * It says how many days the month has
 * @param {Date} date - date to modify
 * @returns {Number} :returns the last day of the month
 */
export const lastDaysOfTheMonth = (date) => GetDaysInMonth(date);

// -------------------------------------------------------------------------------
/**
 * Return the date with the start day of the week
 * @param {Date} date  date to modify
 * @returns {Date} :the date with the start day of that week returns, being Monday the start of the week
 */
export const startOfWeek = (date) => StartOfWeek(date, { weekStartsOn: 1 });

export const formatDate = (date) => date.toISOString().split("T")[0];

// -------------------------------------------------------------------------------
/**
 * Get the ISO week of the given date.
 * @param {Date} date  date to modify
 * @returns {Date} :the date with the start day of that week returns, being Monday the start of the week
 */
export const getISOWeek = (date) => GetISOWeek(date);

/**
 * Returns the day of the month of the given date
 * @param {date} date
 */
export const getMonthDay = (date) => GetDate(date);

/**
 * Get the year of the given date.
 * @param {date} date
 */
export const getYear = (date = new Date()) => GetYear(date);

/**
 * Get the month of the given date.
 * @param {date} date
 */
export const getMonth = (date = new Date()) => GetMonth(date);

/**
 * Return the array of months within the specified time interval.
 * @param {date} start
 * @param {date} end
 */
export const eachMonthOfInterval = (start, end) => EachMonthOfInterval({ start, end });

export const addTimezoneHours = (date) => {
    const newDate = new Date(date);
    const timeZoneOffsetInMinutes = date.getTimezoneOffset();

    if (timeZoneOffsetInMinutes < 0) {
        newDate.setMinutes(date.getMinutes() - timeZoneOffsetInMinutes);
    }

    return newDate;
};

export const differenceInCalendarDays = (start, end) => DifferenceInCalendarDays(start, end);

export const differenceInMonths = (start, end) => DifferenceInMonths(start, end);

export const forceGMT = (date) => {
    const newDate = new Date(date);
    const timeZoneOffsetInMinutes = date.getTimezoneOffset();

    newDate.setMinutes(date.getMinutes() + timeZoneOffsetInMinutes);

    return newDate;
};

/**
 * Return a Date from a string with 'd/m/Y'
 * @param {string} string
 */
export const stringToDate = (string) => {
    const parts = string.split(SLASH);

    return new Date(parts[2], parts[1] - 1, parts[0]);
};

export const substractYears = (date, daysToSubstract) => SubYears(date, daysToSubstract);
