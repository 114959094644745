import React from "react";

import { push as Push } from "connected-react-router";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router";

import { EMPTY_STR, LEVEL, MSG_RETURN_CODE_PREFIX, SCOPE } from "~/constants";
import { SelectorsAction, SelectorsStore } from "~/store/enrollment";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import ExternalFooterMobile from "~/components/ExternalFooterMobile";
import I18n from "~/components/I18n";
import Modal from "~/components/Modal";
import Container from "~/pages/_components/Container";
import MainContainer from "~/pages/_components/MainContainer";

export const NAME = "SecuritySealSelector";

export const PROP = {
    types: {
        ...TypesRedux,
        exchangeToken: PropTypes.string.isRequired,
        updateSecuritySeal: PropTypes.bool.isRequired,
        securitySeal: PropTypes.shape({
            id: PropTypes.string,
            image: PropTypes.string,
        }).isRequired,
        securitySealList: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                image: PropTypes.string,
            }),
        ).isRequired,
        fetchingSecuritySeals: PropTypes.bool,
        showSealModal: PropTypes.bool,
    },
    defaults: {
        ...DefaultsRedux,
        fetchingSecuritySeals: false,
        showSealModal: false,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        const { dispatch, exchangeToken } = this.props;

        if (exchangeToken) {
            dispatch(SelectorsAction.requestSecuritySeals({ exchangeToken }));
        } else {
            // TODO: Revisar, si queda habria que limpiar el store
            dispatch(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get(`${MSG_RETURN_CODE_PREFIX}.API006E`),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.INVITATION_CODE],
                }),
            );
            dispatch(Push("/invitationCode"));
        }
    }

    handleClick = (seal) => {
        const { dispatch } = this.props;

        dispatch(SelectorsAction.hideSecuritySealModal());
        dispatch(SelectorsAction.showSecuritySealConfirmationModal({ seal }));
    };

    handleHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsAction.hideSecuritySealModal());
        return false;
    };

    render() {
        const { securitySeal, fetchingSecuritySeals, securitySealList, showSealModal } = this.props;
        return (
            <Modal show={showSealModal} backdrop="static">
                <div className="enrollment page-login">
                    <div className="modal-header">
                        <h4 className="modal-title">
                            <I18n id="activities.enrollment.userData.image.title" />
                        </h4>
                        <button
                            type="button"
                            className="close"
                            onClick={this.handleHeaderClose}
                            onKeyDown={this.handleHeaderClose}
                            role="link"
                            tabIndex={0}>
                            <span aria-hidden="true">×</span>
                            <span className="sr-only">Close</span>
                        </button>
                    </div>
                    <MainContainer className="main-container" showLoader={fetchingSecuritySeals}>
                        <div className="above-the-fold">
                            <Container
                                className="container--layout flex-grow align-items-center"
                                gridClassName="form-content">
                                <Col sm={12} md={9} lg={6} className="col col-12 image-grid">
                                    <p className="text-lead">
                                        <I18n id="enrollment.registerPersonalData.securitySeal.title" />
                                    </p>

                                    {securitySealList && securitySeal && (
                                        <Row>
                                            {/* TODO: agregar validacion por si llegan a venir vacia la lista
                                                    de sellos, que muestre un mensaje? */}
                                            {Object.entries(securitySealList).map(([id, securitySealAux]) => {
                                                const colClass = `col col-4 image-grid-item ${
                                                    id === securitySeal.id ? "is-current" : ""
                                                }`;

                                                return (
                                                    <Col
                                                        xs={12}
                                                        sm={4}
                                                        md={3}
                                                        lg={2}
                                                        className={colClass}
                                                        key={`securitySeal-${id}`}>
                                                        <Button
                                                            className="btn-image"
                                                            id={id}
                                                            label={EMPTY_STR}
                                                            onClick={() => {
                                                                this.handleClick(id);
                                                            }}
                                                            style={{
                                                                backgroundImage: `url(${securitySealAux})`,
                                                            }}
                                                        />
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    )}
                                </Col>
                            </Container>
                        </div>
                    </MainContainer>
                    <ExternalFooterMobile />
                </div>
            </Modal>
        );
    }
}

export default Connect((store) => ({
    exchangeToken: SelectorsStore.exchangeToken(store),
    fetchingSecuritySeals: SelectorsStore.fetchingSecuritySeals(store),
    showSealModal: SelectorsStore.showSealModal(store),
    securitySealList: SelectorsStore.securitySealList(store),
    securitySeal: SelectorsStore.securitySeal(store),
}))(WithRouter(Component));
