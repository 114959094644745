import React from "react";

import { Modal, Grid, Row, Col } from "react-bootstrap";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, EVENT_BACKBUTTON, ID_FORM_COMEX, NO_DATA, SPACE_STR } from "~/constants";
import {
    PROP as PropComex,
    SelectorsAction as SelectorsActionComex,
    SelectorsStore as SelectorsStoreComex,
} from "~/store/comex";
import { generateId as GenID } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import GridLayout from "~/components/GridLayout";
import I18n from "~/components/I18n";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import MainContainer from "~/pages/_components/MainContainer";

import Style from "./OperationNumber.rules.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {
        ...PropComex.types,
    },
    defaults: {
        ...PropComex.defaults,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        document.addEventListener(EVENT_BACKBUTTON, this.closeModal, true);
    }

    componentWillUnmount() {
        document.removeEventListener(EVENT_BACKBUTTON, this.closeModal, true);
    }

    closeModal = () => {
        const { isVisible } = this.props;

        if (isVisible) {
            const { dispatch } = this.props;

            dispatch(SelectorsActionComex.toggleCreditLetterList());
        }
    };

    handleHide = () => {
        const { dispatch, isVisible } = this.props;

        if (isVisible) {
            dispatch(SelectorsActionComex.toggleCreditLetterList());
        }
    };

    renderItem = (item) => {
        const { handleItemClick, idForm } = this.props;
        const { othersFields } = item;
        let content;

        switch (idForm) {
            case ID_FORM_COMEX.BILLING_CLAIM: {
                const { NRODECOBRANZA, MONEDA, IMPORTE, NROFACTURA, GIRADO } = othersFields;
                content = (
                    <React.Fragment>
                        <Box>
                            <Box>{NRODECOBRANZA}</Box>
                        </Box>
                        <Box>
                            <FormattedAmount quantity={parseFloat(IMPORTE)} currency={MONEDA} />
                        </Box>
                        <Box>
                            <Box>{NROFACTURA}</Box>
                        </Box>
                        <Box justify="end">
                            <Box>{GIRADO}</Box>
                        </Box>
                    </React.Fragment>
                );
                break;
            }
            case ID_FORM_COMEX.MODIFY_BONDS_AND_GUARANTEES: {
                const { NROOPERACION, MONEDA, IMPORTE, BENEFICIARIO } = othersFields;
                content = (
                    <React.Fragment>
                        <Box>
                            <Box>{NROOPERACION}</Box>
                        </Box>

                        <Box>
                            <FormattedAmount quantity={parseFloat(IMPORTE)} currency={MONEDA} />
                        </Box>

                        <Box justify="end">
                            <Box>{BENEFICIARIO}</Box>
                        </Box>
                    </React.Fragment>
                );
                break;
            }

            case ID_FORM_COMEX.MODIFY_CREDIT_LETTER:
            case ID_FORM_COMEX.REQUEST_ENDORSEMENT: {
                const { NROOPERACION, BENEFICIARIO, MONEDA, IMPORTE } = othersFields;
                const beneficiary = BENEFICIARIO === EMPTY_STR || BENEFICIARIO === SPACE_STR ? NO_DATA : BENEFICIARIO;

                content = (
                    <React.Fragment>
                        <Box>
                            <Box>{NROOPERACION}</Box>
                        </Box>
                        <Box>
                            <Box>{beneficiary}</Box>
                        </Box>

                        <Box justify="end">
                            <FormattedAmount quantity={parseFloat(IMPORTE)} currency={MONEDA} />
                        </Box>
                    </React.Fragment>
                );
                break;
            }
            case ID_FORM_COMEX.CANCELLATION_CREDIT_LETTER: {
                const { NROOPERACION, BENEFICIARIO, MONEDA, IMPORTE, SUBOPE } = othersFields;
                const beneficiary = BENEFICIARIO === EMPTY_STR || BENEFICIARIO === SPACE_STR ? "N/A" : BENEFICIARIO;
                const subOperation = !SUBOPE || SUBOPE === EMPTY_STR || SUBOPE === SPACE_STR ? "N/A" : SUBOPE;

                content = (
                    <React.Fragment>
                        <Box>
                            <Box>{NROOPERACION}</Box>
                        </Box>
                        <Box>
                            <Box>{subOperation}</Box>
                        </Box>
                        <Box>
                            <FormattedAmount quantity={parseFloat(IMPORTE)} currency={MONEDA} />
                        </Box>
                        <Box justify="end">
                            <Box>{beneficiary}</Box>
                        </Box>
                    </React.Fragment>
                );
                break;
            }
            case ID_FORM_COMEX.BILLING_CANCELLATION: {
                const { NROOPERACION, IMPORTE, MONEDA, GIRADOR } = othersFields;

                content = (
                    <React.Fragment>
                        <Box>
                            <Box>{NROOPERACION}</Box>
                        </Box>
                        <Box>
                            <FormattedAmount quantity={parseFloat(IMPORTE)} currency={MONEDA} />
                        </Box>
                        <Box justify="end">
                            <Box>{GIRADOR}</Box>
                        </Box>
                    </React.Fragment>
                );
                break;
            }
            case ID_FORM_COMEX.TRANSFER_CREDIT_LETTER: {
                const { CARTADECREDITO, IMPORTE, MONEDA, NROOPERACION, ORDENANTE } = othersFields;
                const payer = ORDENANTE === EMPTY_STR || ORDENANTE === SPACE_STR ? NO_DATA : ORDENANTE;

                content = (
                    <React.Fragment>
                        <Box>
                            <Box>{NROOPERACION}</Box>
                        </Box>
                        <Box>
                            <Box>{payer}</Box>
                        </Box>
                        <Box>
                            <FormattedAmount quantity={parseFloat(IMPORTE)} currency={MONEDA} />
                        </Box>
                        <Box justify="end">
                            <Box>{CARTADECREDITO}</Box>
                        </Box>
                    </React.Fragment>
                );
                break;
            }
            case ID_FORM_COMEX.DISCREPANCY_DOCUMENT_AUTHORIZATION: {
                const { NROOPERACION, CARTADECREDITO, MONEDA, IMPORTE } = othersFields;

                content = (
                    <React.Fragment>
                        <Box>
                            <Box>{NROOPERACION}</Box>
                        </Box>
                        <Box>
                            <Box>{CARTADECREDITO}</Box>
                        </Box>

                        <Box justify="end">
                            <FormattedAmount quantity={parseFloat(IMPORTE)} currency={MONEDA} />
                        </Box>
                    </React.Fragment>
                );
                break;
            }
            case ID_FORM_COMEX.COMEX_IMPORT_DOCUMENT_PRESENTATION:
            case ID_FORM_COMEX.PRESENTATION_CREDIT_LETTER: {
                const { NROOPERACION, CARTADECREDITO, MONEDA, IMPORTE } = othersFields;

                content = (
                    <React.Fragment>
                        <Box>
                            <Box>{NROOPERACION}</Box>
                        </Box>
                        <Box>
                            <Box>{CARTADECREDITO}</Box>
                        </Box>

                        <Box justify="end">
                            <FormattedAmount quantity={parseFloat(IMPORTE)} currency={MONEDA} />
                        </Box>
                    </React.Fragment>
                );
                break;
            }
            case ID_FORM_COMEX.CREDIT_LETTER_PURCHASE: {
                const { NROOPERACION, SUBOPE, CARTADECREDITO, MONEDA, IMPORTE, ORDENANTE } = othersFields;

                content = (
                    <React.Fragment>
                        <Box>
                            <Box>{NROOPERACION}</Box>
                        </Box>
                        <Box>
                            <Box>{SUBOPE}</Box>
                        </Box>
                        <Box>
                            <Box>{CARTADECREDITO}</Box>
                        </Box>

                        <Box>
                            <FormattedAmount quantity={parseFloat(IMPORTE)} currency={MONEDA} />
                        </Box>
                        <Box justify="end">
                            <Box>{ORDENANTE}</Box>
                        </Box>
                    </React.Fragment>
                );
                break;
            }
            case ID_FORM_COMEX.LIFT_CREDIT_LETTER_DISCREPANCIES: {
                const { NROOPERACION, SUBOPE, MONEDA, IMPORTE, BENEFICIARIO } = othersFields;

                content = (
                    <React.Fragment>
                        <Box>
                            <Box>{NROOPERACION}</Box>
                        </Box>
                        <Box>
                            <Box>{SUBOPE}</Box>
                        </Box>
                        <Box>
                            <FormattedAmount quantity={parseFloat(IMPORTE)} currency={MONEDA} />
                        </Box>
                        <Box justify="end">
                            <Box>{BENEFICIARIO}</Box>
                        </Box>
                    </React.Fragment>
                );
                break;
            }
            case ID_FORM_COMEX:
            case ID_FORM_COMEX.CESSION_CREDIT_LETTER: {
                const { CARTADECREDITO, IMPORTE, MONEDA, NROOPERACION, ORDENANTE } = othersFields;
                const payer = ORDENANTE === EMPTY_STR || ORDENANTE === SPACE_STR ? NO_DATA : ORDENANTE;

                content = (
                    <React.Fragment>
                        <Box>
                            <Box>{NROOPERACION}</Box>
                        </Box>
                        <Box>
                            <Box>{CARTADECREDITO}</Box>
                        </Box>
                        <Box>
                            <FormattedAmount quantity={parseFloat(IMPORTE)} currency={MONEDA} />
                        </Box>
                        <Box justify="end">
                            <Box>{payer}</Box>
                        </Box>
                    </React.Fragment>
                );
                break;
            }
            default:
                break;
        }

        return (
            <GridLayout
                className="search-item"
                key={`searchItem-${GenID()}`}
                onClick={() => {
                    handleItemClick(item);
                    this.handleHide();
                }}>
                {content}
            </GridLayout>
        );
    };

    renderList = () => {
        const { list, idForm } = this.props;
        let headerLabels = [];
        switch (idForm) {
            case ID_FORM_COMEX.BILLING_CLAIM:
                headerLabels = [
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.operationNumber`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.amount`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.invoiceNumber`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.recipient`),
                ];

                break;
            case ID_FORM_COMEX.MODIFY_BONDS_AND_GUARANTEES:
                headerLabels = [
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.operationNumber`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.amount`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.beneficiary`),
                ];

                break;
            case ID_FORM_COMEX.MODIFY_CREDIT_LETTER:
            case ID_FORM_COMEX.REQUEST_ENDORSEMENT:
                headerLabels = [
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.operationNumber`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.recipient`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.amount`),
                ];

                break;
            case ID_FORM_COMEX.TRANSFER_CREDIT_LETTER:
                headerLabels = [
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.operationNumber`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.payer`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.amount`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.creditLetterNumber`),
                ];

                break;
            case ID_FORM_COMEX.BILLING_CANCELLATION:
                headerLabels = [
                    UtilsI18n.get(`${idForm}.dialog.operationNumber`),
                    UtilsI18n.get(`${idForm}.dialog.amount`),
                    UtilsI18n.get(`${idForm}.dialog.drawer`),
                ];

                break;
            case ID_FORM_COMEX.DISCREPANCY_DOCUMENT_AUTHORIZATION:
            case ID_FORM_COMEX.PRESENTATION_CREDIT_LETTER:
                headerLabels = [
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.operationNumber`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.recipient`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.amount`),
                ];

                break;
            case ID_FORM_COMEX.LIFT_CREDIT_LETTER_DISCREPANCIES:
                headerLabels = [
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.nroOp`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.subope`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.amount`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.beneficiary`),
                ];

                break;
            case ID_FORM_COMEX.CREDIT_LETTER_PURCHASE:
                headerLabels = [
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.operationNumber`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.sub.operation`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.creditLetterNumber`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.amount`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.payer`),
                ];

                break;
            case ID_FORM_COMEX.COMEX_IMPORT_DOCUMENT_PRESENTATION:
                headerLabels = [
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.operationNumber`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.recipient`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.amount`),
                ];

                break;
            case ID_FORM_COMEX.CANCELLATION_CREDIT_LETTER:
                headerLabels = [
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.operationNumber`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.suboperation`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.amount`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.beneficiary`),
                ];

                break;
            case ID_FORM_COMEX.CESSION_CREDIT_LETTER:
                headerLabels = [
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.operationNumber`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.creditLetterNumber`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.amount`),
                    UtilsI18n.get(`${idForm}.operationNumber.dialog.payer`),
                ];

                break;
            default:
                break;
        }

        return (
            <React.Fragment>
                <Box className={CLASS}>
                    <GridLayout className="header">
                        {headerLabels.map((heading, index) => (
                            <Box
                                justify={index === headerLabels.length - 1 ? "end" : EMPTY_STR}
                                key={`heading-${GenID()}`}>
                                {heading}
                            </Box>
                        ))}
                    </GridLayout>
                    {list && list.map((item) => this.renderItem(item))}
                    {list && list.length === 0 && (
                        <Box flex justify="center">
                            <Box className="my-4">{UtilsI18n.get("environment.filter.noresults")}</Box>
                        </Box>
                    )}
                </Box>
            </React.Fragment>
        );
    };

    render() {
        const { isVisible, isFetching, idForm } = this.props;

        return (
            <React.Fragment>
                <Modal onHide={this.handleHide} show={isVisible} className="operation-number-modal">
                    <Modal.Header closeButton>
                        <section className="container--layout align-items-center">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6}>
                                        <Modal.Title>
                                            <I18n id={`${idForm}.operationNumber.dialog.title`} />
                                        </Modal.Title>
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </Modal.Header>
                    <Modal.Body>
                        <MainContainer showLoader={isFetching} shouldHideOnLoad>
                            <section className="container--layout align-items-start flex-grow">
                                <Grid className="form-content">
                                    <Row className="justify-content-center">
                                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                            {this.renderList()}
                                        </Col>
                                    </Row>
                                </Grid>
                            </section>
                        </MainContainer>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    isVisible: SelectorsStoreComex.isOperationNumberModalVisible(store),
    isFetching: SelectorsStoreComex.getFetching(store),
});

export default Connect(mapStateToProps)(Component);
