import { LOCATION_CHANGE } from "connected-react-router";

import { shouldKeepFormState as ShouldKeepFormState } from "~/util/form";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            const shouldKeep = ShouldKeepFormState(storeProp, action.payload.location.pathname);
            if (shouldKeep) {
                return {
                    ...storeProp,
                };
            }
            return {
                ...PROP.defaults,
            };
        }
        case TYPE.CLEAR_LIST:
            return {
                isFetching: false,
                listAgreements: [],
            };
        case TYPE.LIST_AGREEMENTS_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.LIST_AGREEMENTS_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
            };
        case TYPE.LIST_AGREEMENTS_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                changeValue: true,
                listAgreements: action.data.listAgreements,
            };
        case TYPE.LIST_AUTOMATIC_DEBITS_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.LIST_AUTOMATIC_DEBITS_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
            };
        case TYPE.LIST_AUTOMATIC_DEBITS_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                listAutomaticDebits: action.data.automaticDebits,
                listBanredAutomaticDebits: action.data.banredAutomaticDebits,
            };
        case TYPE.CHANGE_VALUE:
            return {
                ...storeProp,
                changeValue: !storeProp.changeValue,
            };
        case TYPE.CHANGE_NEWS_DATE:
            return {
                ...storeProp,
                newsDate: action.newsDate,
            };
        default:
            return storeProp;
    }
};
