import React from "react";

import { push as Push } from "connected-react-router";
import { Field, Form, withFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import * as Yup from "yup";

import { SelectorsAction as SelectorsActionAccount } from "~/store/account";
import * as I18n from "~/util/i18n";

import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import DateField from "~/pages/_components/fields/DateField";

const FORM_ID = "accounts.movements.filters.period";

export const NAME = "PeriodFilter";

export const PROP = {
    types: {
        values: PropTypes.shape({
            dateFrom: PropTypes.string,
            dateTo: PropTypes.string,
        }).isRequired,
        isSubmitting: PropTypes.bool.isRequired,
        personalized: PropTypes.bool.isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const {
        values: { dateFrom, dateTo },
        personalized,
    } = props;

    const [selectedDateFrom, setSelectedDateFrom] = React.useState(null);
    const [selectedDateTo, setSelectedDateTo] = React.useState(null);
    const [disabled, setDisabled] = React.useState(false);

    return (
        <React.Fragment>
            {personalized && (
                <Form>
                    <Field
                        id="startDate"
                        component={DateField}
                        endDate={dateTo}
                        hidePlaceholder
                        idForm={FORM_ID}
                        name="dateFrom"
                        selectsStart
                        showMonthYearDropdown
                        startDate={dateFrom}
                        handleChange={handleChangeDateFrom}
                        selectedDate={selectedDateFrom}
                    />
                    <Field
                        id="endDate"
                        component={DateField}
                        endDate={dateTo}
                        hidePlaceholder
                        idForm={FORM_ID}
                        name="dateTo"
                        selectsEnd
                        showMonthYearDropdown
                        startDate={dateFrom}
                        minDate={selectedDateFrom}
                        handleChange={handleChangeDateTo}
                        selectedDate={selectedDateTo}
                    />
                    <Button disabled={disabled} block={false} variant="primary" type="submit">
                        {I18n.get("product.filters.filter")}
                    </Button>
                </Form>
            )}
        </React.Fragment>
    );

    function handleChangeDateFrom(selectedDate) {
        setSelectedDateFrom(selectedDate);
        if (selectedDate) {
            setDisabled(false);
        }
    }

    function handleChangeDateTo(selectedDate) {
        setSelectedDateTo(selectedDate);
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default HighOrder(
    Connect(),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            dateFrom: null,
            dateTo: null,
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    dateFrom: values.dateTo
                        ? Yup.date()
                              .typeError(I18n.get(`${FORM_ID}.dateFrom.error.empty`))
                              .max(values.dateTo, I18n.get(`${FORM_ID}.dateFrom.error`))
                        : Yup.date().typeError(I18n.get(`${FORM_ID}.dateFrom.error.empty`)),
                    dateTo: values.dateFrom
                        ? Yup.date()
                              .typeError(I18n.get(`${FORM_ID}.dateTo.error.empty`))
                              .min(values.dateFrom, I18n.get(`${FORM_ID}.dateTo.error`))
                        : Yup.date().typeError(I18n.get(`${FORM_ID}.dateTo.error.empty`)),
                }),
            ),
        handleSubmit: ({ ...filters }, formikBag) => {
            const { dispatch, isDesktop, productId } = formikBag.props;
            if (isDesktop) {
                dispatch(SelectorsActionAccount.details({ productId, filters, formikBag }));
            } else {
                dispatch(
                    SelectorsActionAccount.setMobileFilter({
                        filter: "period",
                        ...filters,
                        filtersPersonalized: false,
                    }),
                );
                dispatch(SelectorsActionAccount.cleanMovements());
                dispatch(Push(`/accounts/${productId}/filters/results`));
            }
        },
    }),
)(Component);
