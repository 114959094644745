import { go as Go } from "connected-react-router";
import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE, GLOBAL_UNEXPECTED_ERROR_KEY, SCREENS_BEFORE } from "~/constants";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionSession } from "~/store/session";
import * as UtilI18n from "~/util/i18n";
import * as UtilPermissions from "~/util/permissions";

const allowsProductSelection = ({ childrenList, permissionList }) =>
    !childrenList.length &&
    permissionList.length &&
    (permissionList[0].simpleAllowProductSelection || permissionList[0].mediumAllowProductSelection);
const parsePermissionsResponse = (data) => {
    const { groups } = data;

    // This is to convert generic permissions to permissions on specific products
    // when needed
    const permissionsFromGroups = groups.reduce((result, group) => {
        if (group.childrenList.length) {
            const innerPermissions = group.childrenList.reduce(
                (innerResult, innerPermission) => ({
                    ...innerResult,
                    [innerPermission.idItem]: allowsProductSelection(innerPermission)
                        ? UtilPermissions.convertSmartGroupsToProducts(
                              data.permissions[innerPermission.idItem],
                              data.products,
                          )
                        : data.permissions[innerPermission.idItem],
                }),
                {},
            );
            return {
                ...result,
                ...innerPermissions,
            };
        }
        return {
            ...result,
            [group.idItem]: allowsProductSelection(group)
                ? UtilPermissions.convertSmartGroupsToProducts(data.permissions[group.idItem], data.products)
                : data.permissions[group.idItem],
        };
    }, {});
    // Not all the permissions belong to a group so I also need the permissions from the data.permissions object
    const permissions = {
        ...data.permissions,
        ...permissionsFromGroups,
    };
    return {
        ...data,
        permissions,
    };
};
const loadPermissionsRequest = (middleware, actions) =>
    function* loadPermissions(props) {
        // id

        const permissionsResponse = yield Call(middleware.loadPermissionsRequest, props);

        if (permissionsResponse.type === RESPONSE_TYPE.WARNING) {
            yield Put(actions.loadPermissionsFailure());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION],
                }),
            );
        } else {
            const data = parsePermissionsResponse(permissionsResponse.data.data);
            yield Put(actions.loadPermissionsSuccess({ data }));
        }
    };

const updatePermissionsRequest = (middleware, actions) =>
    function* updatePermissions(props) {
        //  data, formikBag
        const { data, formikBag } = props;
        const { ...restOfParams } = data;

        const response = yield Call(middleware.updatePermissionsRequest, { ...restOfParams });
        const { setSubmitting, setErrors } = formikBag;
        setSubmitting(false);

        if (response.type === RESPONSE_TYPE.WARNING) {
            const errorMessage = response.data.data.NO_FIELD || UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY);
            setErrors(response.data.data);
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION],
                }),
            );
        } else {
            if (response.data.data.permissions) {
                yield Put(
                    SelectorsActionSession.readPermissionsSuccess({ permissions: response.data.data.permissions }),
                );
            }

            yield Put(Go(SCREENS_BEFORE.ONE_LESS));

            yield Put(actions.updatePermissionsRequestSuccess());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.ADMINISTRATION_USERS],
                }),
            );
        }
    };

const loadPermissionsTicketRequest = (middleware, actions) =>
    function* loadPermissionsTicket(props) {
        // idTransaction
        const transactionResponse = yield Call(SelectorsMiddlewareForm.readTransaction, props);

        if (transactionResponse.type === RESPONSE_TYPE.WARNING) {
            yield Put(actions.loadPermissionsTicketFailure());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION],
                }),
            );
        } else {
            const { idUser, permissions } = transactionResponse.data.data.transaction.data;
            const permissionsResponse = yield Call(middleware.loadPermissionsRequest, { id: idUser });

            if (permissionsResponse.type === RESPONSE_TYPE.WARNING) {
                yield Put(actions.loadPermissionsTicketFailure());
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.ADMINISTRATION],
                    }),
                );
            } else {
                yield Put(actions.loadPermissionsTicketSuccess());
                yield Put(actions.loadPermissionsSuccess({ data: { ...permissionsResponse.data.data, permissions } }));
            }
        }
    };

const sagas = (middleware, types, actions) => [
    TakeLatest(types.LOAD_PERMISSIONS_REQUEST, loadPermissionsRequest(middleware, actions)),
    TakeLatest(types.UPDATE_PERMISSIONS_REQUEST, updatePermissionsRequest(middleware, actions)),
    TakeLatest(types.LOAD_PERMISSIONS_TICKET_REQUEST, loadPermissionsTicketRequest(middleware, actions)),
];
export default sagas;
