import React from "react";

import ClassNames from "classnames";
import PropTypes from "prop-types";

import I18n from "~/components/I18n";
import Image from "~/components/Image";
import FieldError from "~/pages/_components/fields/FieldError";

import Style from "./Checkbox.rules.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        controlStyle: PropTypes.string,
        error: PropTypes.arrayOf(PropTypes.string),
        hideLabel: PropTypes.bool,
    },
    defaults: { controlStyle: "checkbox", error: null, hideLabel: false },
};

export function Component(props) {
    const { controlStyle, idForm, label, error, hideLabel, name, ...rest } = props;
    return (
        <div
            className={ClassNames(
                "c-control c-control-block form-group",
                CLASS,
                { "c-control--switch": controlStyle === "switch" },
                { "c-control--has-icon c-control--checkbox": controlStyle === "checkbox" },
                { "has-error": error },
            )}>
            <input id={name} tabIndex="-1" className="c-control-input" type="checkbox" {...rest} readOnly />

            <label className="c-control-label" htmlFor={name}>
                {controlStyle === ("checkbox" || "radio") && (
                    <div className="c-control-icons">
                        <div className="c-control-mark">
                            <Image src="check.svg" className="svg-icon svg-caret" />
                        </div>
                    </div>
                )}
                {!hideLabel && (
                    <div className="form-group-text">{label || <I18n id={`${idForm}.${name}.label`} />}</div>
                )}
            </label>
            {error && <FieldError error={error} />}
        </div>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
