import React from "react";

import PropTypes from "prop-types";

import UtilFlag from "~/util/flag";
import { generateId as GenID } from "~/util/general";
import { get as GetI18n } from "~/util/i18n";

import Image from "~/components/Image";

import Style from "./CurrencyExchangeRates.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {
        quotes: PropTypes.array,
    },
    defaults: {},
};

export function Component(props) {
    const { quotes } = props;
    return (
        <section className={CLASS}>
            <h3>{GetI18n("currencyExchangeRatesWidget.title")}</h3>
            <ul>
                {quotes.map(
                    ({ currency1, currency1Label, currency2, currency2Label, buyingRateClient, sellingRateClient }) => (
                        <li key={GenID()}>
                            <div>
                                <Image src={`${UtilFlag.getFlag(`${currency2}`)}.svg`} />
                                <span>
                                    {currency2Label}:{currency1Label}
                                </span>
                                <Image src={`${UtilFlag.getFlag(`${currency1}`)}.svg`} />
                            </div>
                            <span>{sellingRateClient}</span>
                            <span>{buyingRateClient}</span>
                        </li>
                    ),
                )}
            </ul>
        </section>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
