import * as ArrayUtils from "~/util/array";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.LOAD_LAYOUT_REQUEST:
            return {
                ...storeProp,
                isEditable: false,
                fetching: true,
            };
        case TYPE.LOAD_LAYOUT_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.LOAD_LAYOUT_SUCCESS:
            return {
                ...storeProp,
                widgets: action.widgets,
                layout: action.layout,
                fetching: false,
            };
        case TYPE.SET_LAYOUT:
        case TYPE.SAVE_LAYOUT_SUCCESS:
            return {
                ...storeProp,
                layout: action.layout,
            };

        case TYPE.TOGGLE_IS_EDITABLE:
            return {
                ...storeProp,
                isEditable: !storeProp.isEditable,
            };

        case TYPE.ADD_AVAILABLE_WIDGET:
            return {
                ...storeProp,
                widgets: [...storeProp.availableWidgets, { ...action.widget, row: 0, column: 0 }],
            };
        case TYPE.REMOVE_AVAILABLE_WIDGET:
            return {
                ...storeProp,
                widgets: ArrayUtils.removeElement(storeProp.availableWidgets, action.index),
            };
        case TYPE.TOGGLE_ENVIRONMENT_MODAL:
            return {
                ...storeProp,
                showEnvironmentModal: !storeProp.showEnvironmentModal,
            };
        default:
            return {
                ...storeProp,
            };
    }
};
