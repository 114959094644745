import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { MODE } from "~/constants/form";
import { DEVICE_MOBILE } from "~/util/device";
import * as i18n from "~/util/i18n";
import UtilLodash from "~/util/lodash";

import HighOrder from "~/components/HighOrder";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import withFocus from "~/pages/_components/withFocus";

export const NAME = "TextField";

export const PROP = {
    types: {
        field: PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.any,
        }).isRequired,
        form: PropTypes.shape({
            setFieldValue: PropTypes.func.isRequired,
            touched: PropTypes.object.isRequired,
            errors: PropTypes.shape({
                environments: PropTypes.shape({
                    environmentType: PropTypes.string,
                    idEnvironment: PropTypes.string,
                    name: PropTypes.string,
                }),
                selectEnvironment: PropTypes.bool,
            }),
        }).isRequired,
        type: PropTypes.string,
        autoComplete: PropTypes.string,
        maxLength: PropTypes.number,
        pattern: PropTypes.string,
        autoFocus: PropTypes.bool,
        inputFunctions: PropTypes.element,
        inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
        hidelabel: PropTypes.bool,
        hidePlaceholder: PropTypes.bool,
        handleOnChange: PropTypes.func,
        isDesktop: PropTypes.bool,
        isMobile: PropTypes.bool,
        isMobileNative: PropTypes.bool,
        mobileOS: PropTypes.string,
        renderAs: PropTypes.string,
        autoCapitalize: PropTypes.string,
        mode: PropTypes.string,
        optional: PropTypes.string,
        numbersOnly: PropTypes.bool,
    },
    defaults: {
        type: "text",
        mode: MODE.EDIT,
        autoComplete: "on",
        maxLength: 50,
        autoFocus: false,
        inputFunctions: null,
        inputRef: React.createRef(),
        pattern: null,
        hidelabel: false,
        hidePlaceholder: false,
        renderAs: "input",
        autoCapitalize: "sentences",
        isMobile: false,
        isMobileNative: false,
        handleOnChange: null,
        mobileOS: EMPTY_STR,
        isDesktop: false,
        optional: null,
        numbersOnly: false,
        showEmptyError: false,
        handleOnChangeAtLast: false,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        window.addEventListener("resize", this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    }

    onResize = () => {
        const { isFocused, isDesktop, mobileOS } = this.props;
        if (isFocused && !isDesktop && mobileOS === DEVICE_MOBILE.ANDROID) {
            // Como findDOMNode esta deprecado,  la propiedad this.node
            // se obtiene en el render seteando una referencia
            this.node.scrollIntoView({
                block: "center",
                behavior: "smooth",
            });
        }
    };

    handleBlur = (event) => {
        const { toggleIsFocused, field, onBlur } = this.props;

        field.onBlur(event);
        if (onBlur) {
            onBlur(event);
        }
        toggleIsFocused();
    };

    handleOnChange = (event) => {
        const {
            pattern,
            handleOnChange,
            maxLength,
            field: { onChange, name },
            form: { errors, setErrors },
            numbersOnly,
            handleOnChangeAtLast,
        } = this.props;

        /* eslint no-control-regex: "off" */
        const reg = numbersOnly ? /[\D]/g : /[^\x00-\xFF]/g;

        if (!reg.test(event.target.value)) {
            if (!pattern || event.target.validity.valid) {
                if (event.target.value.length <= maxLength) {
                    if (handleOnChangeAtLast) {
                        onChange(event);

                        if (handleOnChange) {
                            handleOnChange(event);
                        }
                    } else {
                        if (handleOnChange) {
                            handleOnChange(event);
                        }

                        onChange(event);
                    }

                    if (errors[name]) {
                        const newErrors = UtilLodash.omitElements(errors, [name]);
                        setErrors(newErrors);
                    }
                }
            }
        }
    };

    render() {
        const {
            field,
            form: { touched, errors },
            inputFunctions,
            inputRef,
            hidelabel,
            hidePlaceholder,
            idForm,
            showStrength,
            handleOnChange,
            isDesktop,
            isMobile,
            isMobileNative,
            mobileOS,
            toggleIsFocused,
            isFocused,
            renderAs: Element,
            autoCapitalize,
            mode,
            value,
            optional,
            tooltip,
            addType,
            classContainer = "",
            showEmptyError,
            handleOnChangeAtLast,
            hideError,
            ...props
        } = this.props;

        const hasError = showEmptyError
            ? touched[field.name] && Object.keys(errors).includes(field.name)
            : touched[field.name] && errors[field.name];

        return (
            <div
                ref={(node) => {
                    this.node = node;
                }}
                className={`form-group ${hasError ? "has-error" : ""} ${
                    isFocused ? "has-focus" : ""
                } ${classContainer}`}>
                {!hidelabel && <FieldLabel optional={optional} labelKey={`${idForm}.${field.name}.label`} />}
                {mode === MODE.EDIT ? (
                    <div>
                        <div className="input-group">
                            <Element
                                className="form-control"
                                placeholder={
                                    hidePlaceholder ? EMPTY_STR : i18n.get(`${idForm}.${field.name}.placeholder`)
                                }
                                {...field}
                                {...props}
                                onFocus={toggleIsFocused}
                                onBlur={this.handleBlur}
                                ref={inputRef}
                                onChange={this.handleOnChange}
                                autoCapitalize={autoCapitalize}
                            />
                            {inputFunctions}
                            {tooltip && (
                                <div className="tooltip">
                                    <span className="tooltip-text"> {tooltip}</span>
                                </div>
                            )}
                            {addType && <div className="add-type">{addType}</div>}
                        </div>
                    </div>
                ) : (
                    <div>{value}</div>
                )}

                {!hideError && hasError && <FieldError error={errors[field.name]} />}
            </div>
        );
    }
}

export default HighOrder(HighOrder.Resizable, withFocus)(Component);
