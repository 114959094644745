import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { CREDIT_CARDS_LIMIT_INCREASE_TYPE, CREDIT_CARD_OWNER, EMPTY_STR, FORMAT_SHORT } from "~/constants";
import { MODE } from "~/constants/form";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import {
    PROP as PropCreditCards,
    SelectorsAction as SelectorsActionCreditCards,
    SelectorsStore as SelectorsStoreCreditCards,
} from "~/store/creditCards/creditCard";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import * as ConfigUtil from "~/util/config";
import { i18nDate as I18nDate } from "~/util/date";
import * as UtilsI18n from "~/util/i18n";
import { numberToLocaleFormat, formatNumber } from "~/util/number";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import Select from "~/components/Select";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TextField from "~/pages/_components/fields/TextField";
import AmountField from "~/pages/_components/fields/formik/AmountField";

import { Termsandconditions } from "~/pages/forms/_components/_fields";
import FormatNumber from "~/pages/wm/funds/_component/FormatNumber";

import Style from "./Step1Edit.rules.scss";

const FORM_ID = "creditCards.limitIncrease";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...PropCreditCards.types,
    },
    defaults: {
        ...PropCreditCards.defaults,
    },
};

export class Component extends React.Component {
    state = {
        selectedAccount: {},
        allVisaMasterAccounts: [],
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(SelectorsActionCreditCards.limitIncreasePreRequest());
    }

    componentDidUpdate() {
        const { formData, preData } = this.props;
        const { allVisaMasterAccounts } = this.state;

        if (!allVisaMasterAccounts.length && preData) {
            const formDataSelectedAccount =
                formData && preData.find((account) => account.visaMasterAccount === formData.visaMasterAccount);

            this.setState({ allVisaMasterAccounts: preData });
            this.setState({ selectedAccount: formDataSelectedAccount || preData[0] });
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(GoBack());
    };

    handleAccountChange = (item) => {
        const { setErrors, setFieldValue } = this.props;
        const { allVisaMasterAccounts } = this.state;

        const selectedAccount = allVisaMasterAccounts.find((account) => account.visaMasterAccount === item);

        setFieldValue("accountVisaMaster", item);
        setFieldValue("beginDate", selectedAccount.incrementToday);
        setFieldValue("endDate", selectedAccount.incrementDueDate);
        setFieldValue("newLimit", { amount: selectedAccount.maxLimit });

        this.setState({
            selectedAccount,
        });

        setErrors({});
    };

    buildOptions = () => {
        const { allVisaMasterAccounts } = this.state;

        return allVisaMasterAccounts.map((account) => {
            const { creditCardList } = account || {};
            const titularCreditCard =
                creditCardList && creditCardList.find((creditCard) => creditCard.ownershipType === CREDIT_CARD_OWNER);

            if (titularCreditCard) {
                return {
                    error: account.incrementErrorCode,
                    id: account.visaMasterAccount,
                    label: (
                        <div className="select-card-option needsclick">
                            <span className="needsclick">{`${account.alias}`}</span>
                            <span className="needsclick">{`(${titularCreditCard.number})`}</span>
                        </div>
                    ),
                };
            }

            return {
                error: account.incrementErrorCode,
                id: account.visaMasterAccount,
                label: (
                    <div className="select-card-option needsclick">
                        <span className="needsclick">{`${account.alias}`}</span>
                    </div>
                ),
            };
        });
    };

    render() {
        const { dateFormat, lang, values } = this.props;
        const { selectedAccount } = this.state;
        const termsAndConditions = `${FORM_ID}.disclaimer`;
        const { creditCardList, currentLimit, incrementErrorCode, incrementAllowed, limitCurrency, maxLimit } =
            selectedAccount;
        const options = this.buildOptions();

        const errorCodes = ["1", "4", "6", "7", "8"];
        const errorType = errorCodes.includes(incrementErrorCode) ? "general" : incrementErrorCode;
        const i18nMap = {
            termsAndConditions,
        };

        const currencyData = {
            options: [
                {
                    id: limitCurrency,
                    label: UtilsI18n.get(`currency.label.${limitCurrency}`),
                },
            ],
        };

        return (
            <React.Fragment>
                <Form
                    className="col col-12 col-lg-6 col-md-9 col-sm-12"
                    id={Style.ID}
                    noValidate="novalidate"
                    onKeyDown={this.handleFormKeyDown}>
                    <section>
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.title`)}</h3>
                        </Box>
                        <Box className="form-group form-data">
                            {/* Visa/Master account */}
                            <Box className="input-group">
                                <Field
                                    component={Select}
                                    placeholder={EMPTY_STR}
                                    value={values.accountVisaMaster}
                                    clearable={false}
                                    onChange={(item) => {
                                        if (item) {
                                            this.handleAccountChange(item.id);
                                        }
                                    }}
                                    valueKey="id"
                                    labelKey="label"
                                    options={options}
                                    className="flex-container slideFromBottom card-select-container"
                                    optionClassName="needsclick"
                                    idForm={FORM_ID}
                                    name="accountVisaMaster"
                                />
                            </Box>
                            {incrementAllowed && <FieldLabel labelKey={`${FORM_ID}.cards.label`} />}
                            {/* Credit card list */}
                            {creditCardList ? (
                                creditCardList.map((creditCard) => {
                                    return (
                                        <span className="product">
                                            {UtilsI18n.get(`client.creditcard.type.label.${creditCard.bin}`)} (
                                            {creditCard.number}) {creditCard.owner}
                                        </span>
                                    );
                                })
                            ) : (
                                <span>{UtilsI18n.get(`${FORM_ID}.noCards.label`)}</span>
                            )}
                            {!incrementAllowed && (
                                <FieldError
                                    error={UtilsI18n.replaceParams(UtilsI18n.get(`${FORM_ID}.error.${errorType}`), {
                                        limitCurrency: UtilsI18n.get(`core.currency.label.${limitCurrency}`),
                                        maxLimit: formatNumber(maxLimit || 0, lang),
                                    })}
                                />
                            )}
                            {/* Current limit */}
                            {selectedAccount && incrementAllowed && (
                                <Box className="info-label">
                                    <span className="highlights">{UtilsI18n.get(`${FORM_ID}.currentLimit.label`)}</span>
                                    <span className="limitCurrency">
                                        {UtilsI18n.get(`currency.label.${limitCurrency}`)}
                                    </span>
                                    <FormatNumber amount={parseFloat(currentLimit, 10)} />
                                </Box>
                            )}
                            {/* New limit */}
                            {incrementAllowed && (
                                <React.Fragment>
                                    <Box className="input-editable">
                                        <Field
                                            clearable
                                            component={AmountField}
                                            data={currencyData}
                                            idForm={FORM_ID}
                                            name="newLimit"
                                            value={values.newLimit}
                                            placeholder={EMPTY_STR}
                                            searchable={false}
                                            decimalPlaces={2}
                                            fixedDecimalScale
                                            inputZeroAllowed
                                            tooltip={UtilsI18n.replaceParams(UtilsI18n.get(`${FORM_ID}.tooltip`), {
                                                limitCurrency: UtilsI18n.get(`core.currency.label.${limitCurrency}`),
                                                maxLimit: numberToLocaleFormat(maxLimit, lang),
                                            })}
                                            maxLength={ConfigUtil.getInteger("amount.length")}
                                        />
                                    </Box>
                                    <Box className="info-label date">
                                        <span className="highlights">{UtilsI18n.get(`${FORM_ID}.incrementToday`)}</span>
                                        <Field
                                            mode={MODE.VIEW}
                                            hidelabel
                                            component={TextField}
                                            name="beginDate"
                                            value={values?.beginDate && I18nDate(values.beginDate, dateFormat)}
                                        />
                                    </Box>
                                    <Box className="info-label date">
                                        <span className="highlights">
                                            {UtilsI18n.get(`${FORM_ID}.incrementDueDate`)}
                                        </span>
                                        <Field
                                            mode={MODE.VIEW}
                                            hidelabel
                                            component={TextField}
                                            name="endDate"
                                            value={values?.endDate && I18nDate(values.endDate, dateFormat)}
                                        />
                                    </Box>
                                </React.Fragment>
                            )}
                        </Box>
                        {incrementAllowed && (
                            <React.Fragment>
                                <Box className="form-section-title">
                                    <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.finish`)}</h3>
                                </Box>
                                <Box className="disclaimer">
                                    <Field
                                        component={Termsandconditions}
                                        idForm={FORM_ID}
                                        name="disclaimer"
                                        mode={MODE.EDIT}
                                        className="form-control"
                                        i18nMap={i18nMap}
                                    />
                                </Box>
                                <footer className="footer">
                                    <Button
                                        key="primaryButton"
                                        variant="primary"
                                        bsStyle="primary"
                                        label={`${FORM_ID}.request`}
                                        loading={false}
                                        type="submit"
                                    />
                                </footer>
                            </React.Fragment>
                        )}
                    </section>
                </Form>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        dateFormat: SelectorsStoreConfig.getConfig(store)["frontend.shortDateFormat"]
            ? SelectorsStoreConfig.getConfig(store)["frontend.shortDateFormat"].toUpperCase()
            : FORMAT_SHORT,
        fetching: SelectorsStoreCreditCards.getFetching(store),
        formData: SelectorsStoreCreditCards.getLimitIncreaseFormData(store),
        lang: SelectorsStoreI18n.getLang(store),
        preData: SelectorsStoreCreditCards.getVisaMasterAccounts(store),
    };
};

export default HighOrder(
    WithRouter,
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: true,
        validationSchema: (props) => {
            const { lang } = props;

            return Yup.lazy((values) => {
                const selectedAccount = props.preData.find(
                    (account) => account.visaMasterAccount === values.accountVisaMaster,
                );

                return Yup.object().shape({
                    newLimit: Yup.object().shape({
                        amount: Yup.number()
                            .moreThan(selectedAccount?.currentLimit, UtilsI18n.get(`${FORM_ID}.error.currentLimit`))
                            .typeError(UtilsI18n.get(`${FORM_ID}.error.notANumber`))
                            .max(
                                selectedAccount?.maxLimit,
                                UtilsI18n.replaceParams(UtilsI18n.get(`${FORM_ID}.error.5`), {
                                    limitCurrency: UtilsI18n.get(
                                        `core.currency.label.${selectedAccount?.limitCurrency}`,
                                    ),
                                    maxLimit: numberToLocaleFormat(selectedAccount?.maxLimit, lang),
                                }),
                            ),
                    }),
                });
            });
        },
        mapPropsToValues: (props) => {
            const { formData, preData } = props;

            return {
                accountVisaMaster:
                    (formData && formData.visaMasterAccount) || (preData && preData[0].visaMasterAccount),
                beginDate: (formData && formData.beginDate) || (preData && preData[0].incrementToday),
                endDate: (formData && formData.endDate) || (preData && preData[0].incrementDueDate),
                newLimit: { amount: (formData && formData.newLimit) || (preData && preData[0].maxLimit) },
            };
        },
        handleSubmit: (props, formikBag) => {
            const { dispatch, preData } = formikBag.props;
            const { accountVisaMaster, newLimit, beginDate, endDate } = props;
            const amount = newLimit ? newLimit.amount : 0;
            const selectedAccount = preData?.find((account) => account.visaMasterAccount === accountVisaMaster);
            const idCreditCard = selectedAccount?.idProduct;

            // It will be replaced by a form field when PERMANENT limit is implemented
            const limitType = CREDIT_CARDS_LIMIT_INCREASE_TYPE.TEMPORARY;

            const formData = {
                accountVisaMaster,
                amount,
                beginDate,
                endDate,
                idCreditCard,
                limitType,
            };

            dispatch(SelectorsActionCreditCards.limitIncreasePreviewRequest(formData));
        },
    }),
)(Component);
