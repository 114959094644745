import React from "react";

import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { CREDIT_CARD_OWNER, EMPTY_STR, FORMAT_SHORT, TRIP_OR_PURCHASE, UNDERSCORE } from "~/constants";
import { MODE } from "~/constants/form";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import {
    PROP as PropCreditCards,
    SelectorsAction as SelectorsActionCreditCards,
    SelectorsStore as SelectorsStoreCreditCards,
} from "~/store/creditCards/creditCard";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import * as UtilsDate from "~/util/date";
import * as UtilsI18n from "~/util/i18n";
import { GetMobileCountryOptions } from "~/util/phone";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Select from "~/components/Select";
import DateField from "~/pages/_components/fields/DateField";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import SuggestionsTagsInput from "~/pages/_components/fields/SuggestionsTagsInput";
import TextField from "~/pages/_components/fields/TextField";

import { Termsandconditions } from "~/pages/forms/_components/_fields";
import Checkbox from "~/pages/forms/_components/_fields/_commons/Checkbox";

import Style from "./Step1Edit.rules.scss";

const FORM_ID = "creditCards.tripOrOnlinePurchaseNotification";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...PropCreditCards.types,
    },
    defaults: {
        ...PropCreditCards.defaults,
    },
};

export class Component extends React.Component {
    state = {
        allVisaMasterAccounts: [],
        selectedAccount: {},
        beginDate: new Date(),
        endDate: null,
        showDateDisclaimer: false,
        checkError: false,
    };

    static getDerivedStateFromProps(prevProps, prevState) {
        const { preData, tripNoticeData } = prevProps;
        const { allVisaMasterAccounts } = prevState || {};

        if (!allVisaMasterAccounts?.length && preData) {
            let selectedAccount = preData[0];

            if (tripNoticeData) {
                selectedAccount = preData.find(
                    (visaMasterAccount) => visaMasterAccount.visaMasterAccount === tripNoticeData.accountVisaMaster,
                );
            }

            return {
                ...prevState,
                allVisaMasterAccounts: preData,
                selectedAccount,
            };
        }

        return { ...prevState };
    }

    componentDidMount() {
        const { dispatch, setFieldValue, tripNoticeData } = this.props;

        dispatch(SelectorsActionCreditCards.tripOrOnlinePurchaseNotificationPreRequest());

        if (tripNoticeData) {
            const beginDate = UtilsDate.toDate(tripNoticeData.beginDate);
            const endDate = UtilsDate.toDate(tripNoticeData.endDate);
            const showDisclaimer = Math.abs(UtilsDate.differenceInMonths(beginDate, endDate)) > 5;

            this.setState({ showDateDisclaimer: showDisclaimer });

            if (showDisclaimer) {
                setFieldValue("disclaimerWaiver", true);
            }
        }
    }

    buildOptions = () => {
        const { allVisaMasterAccounts } = this.state;

        return allVisaMasterAccounts.map((account) => {
            const { creditCardList } = account || {};
            const creditCardOwner =
                creditCardList && creditCardList.find((creditCard) => creditCard.ownershipType === CREDIT_CARD_OWNER);

            if (creditCardOwner) {
                return {
                    error: account.incrementErrorCode,
                    id: account.visaMasterAccount,
                    label: (
                        <div className="select-card-option needsclick">
                            <span className="needsclick">{`${account.alias}`}</span>
                            <span className="needsclick">{`(${creditCardOwner.number})`}</span>
                        </div>
                    ),
                };
            }

            return {
                error: account.incrementErrorCode,
                id: account.visaMasterAccount,
                label: (
                    <div className="select-card-option needsclick">
                        <span className="needsclick">{`${account.alias}`}</span>
                    </div>
                ),
            };
        });
    };

    buildOperationTypeOptions = () => {
        const options = [
            {
                id: TRIP_OR_PURCHASE.TRAVEL_NOTICE,
                label: UtilsI18n.get(`${FORM_ID}.operationType.${TRIP_OR_PURCHASE.TRAVEL_NOTICE}`),
            },
            {
                id: TRIP_OR_PURCHASE.ONLINE_PURCHASE_NOTICE,
                label: UtilsI18n.get(`${FORM_ID}.operationType.${TRIP_OR_PURCHASE.ONLINE_PURCHASE_NOTICE}`),
            },
        ];

        return options;
    };

    handleAccountChange = (item) => {
        const { setFieldValue, setErrors } = this.props;
        const { allVisaMasterAccounts } = this.state;
        const selectedAccount = allVisaMasterAccounts.find((account) => account.visaMasterAccount === item);
        const { creditCardList } = selectedAccount;

        setFieldValue("accountVisaMaster", item);

        if (creditCardList) {
            setFieldValue(
                "checkboxes",
                creditCardList.map(() => false),
            );
        }

        this.setState({
            selectedAccount,
            checkError: false,
        });
        setErrors({});
    };

    handleOperationTypeChange = (item) => {
        const { setFieldValue, setErrors } = this.props;

        setFieldValue("operationType", item);

        setErrors({});
    };

    handleCountriesChange = (tags) => {
        const { setError, setFieldError, setFieldValue, setTouched } = this.props;
        const lastValue = tags[tags.length - 1];
        const oldTags = tags.slice(0, tags.length - 1);

        if (oldTags.indexOf(lastValue) !== -1) {
            setError(UtilsI18n.get("client.tagsInput.repeated.value"));
            setTouched();
        } else {
            setFieldValue("countries", tags);
            setFieldError("countries", null);
        }
    };

    handleChangeDateFrom(selectedDate, fieldName, otherFieldName) {
        const { setFieldError, setFieldValue } = this.props;
        const { [otherFieldName]: otherFieldValue } = this.state;

        setFieldValue(fieldName, selectedDate);
        setFieldError(fieldName, null);
        this.setState({ [fieldName]: selectedDate });

        if (otherFieldValue !== null) {
            this.setState({
                showDateDisclaimer: Math.abs(UtilsDate.differenceInMonths(selectedDate, otherFieldValue)) > 5,
            });
        }

        return selectedDate;
    }

    handleCheckChange(i) {
        const { setFieldValue, values } = this.props;
        const { checkboxes } = values;

        checkboxes[i] = !checkboxes[i];

        this.setState({ checkError: checkboxes.includes(true) });

        setFieldValue("checkboxes", checkboxes);
    }

    render() {
        const { timezoneOffset, values } = this.props;
        const { beginDate, checkError, selectedAccount, showDateDisclaimer } = this.state;

        const termsAndConditions = `${FORM_ID}.disclaimer`;
        const i18nMap = {
            termsAndConditions,
        };

        const options = this.buildOptions();

        const dateFrom = new Date();
        const countriesOptions = GetMobileCountryOptions();
        const countries = [];
        const isTravelNotice = values.operationType === TRIP_OR_PURCHASE.TRAVEL_NOTICE;
        const isOnlinePurchase = values.operationType === TRIP_OR_PURCHASE.ONLINE_PURCHASE_NOTICE;

        countriesOptions.map((option) => countries.push({ name: option.countryName }));

        return (
            <React.Fragment>
                <Form
                    className="col col-12 col-lg-6 col-md-9 col-sm-12"
                    id={Style.ID}
                    noValidate="novalidate"
                    onKeyDown={this.handleFormKeyDown}>
                    <section>
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">
                                <I18n id={`${FORM_ID}.operationType.${values.operationType}`} />
                            </h3>
                        </Box>
                        <Box className="form-group form-data">
                            {/* Operation type */}
                            <Box className="input-group">
                                <Field
                                    className="flex-container slideFromBottom"
                                    clearable={false}
                                    component={Select}
                                    idForm={FORM_ID}
                                    labelKey="label"
                                    name="operationType"
                                    onChange={(item) => {
                                        if (item) {
                                            this.handleOperationTypeChange(item.id);
                                        }
                                    }}
                                    optionClassName="needsclick"
                                    options={this.buildOperationTypeOptions()}
                                    placeholder={EMPTY_STR}
                                    value={values.operationType}
                                    valueKey="id"
                                />
                            </Box>
                            {/* Account Visa/Master */}
                            <Box className="input-group">
                                <Field
                                    className="flex-container slideFromBottom card-select-container"
                                    clearable={false}
                                    component={Select}
                                    idForm={FORM_ID}
                                    labelKey="label"
                                    name="accountVisaMaster"
                                    onChange={(item) => {
                                        if (item) {
                                            this.handleAccountChange(item.id);
                                        }
                                    }}
                                    optionClassName="needsclick"
                                    options={options}
                                    placeholder={EMPTY_STR}
                                    value={values.accountVisaMaster}
                                    valueKey="id"
                                />
                            </Box>
                            {/* Credit card list */}
                            {selectedAccount.creditCardList ? (
                                selectedAccount.creditCardList.map((creditCard, i) => {
                                    const labelCC = (
                                        <div className="card-container">
                                            <span className="data-name type-cc">
                                                {UtilsI18n.get(`client.creditcard.type.label.${creditCard.bin}`)}
                                            </span>
                                            <span className="number-cc">({creditCard.number})</span>
                                            <span className="data-name owner-cc">{creditCard.owner}</span>
                                        </div>
                                    );

                                    return (
                                        <Box id className="container-product">
                                            <Field
                                                name={`${selectedAccount.idProduct}${UNDERSCORE}${i}`}
                                                idForm={FORM_ID}
                                                canRenderFieldError={!checkError}
                                                label={labelCC}
                                                controlStyle="checkbox"
                                                component={Checkbox}
                                                checked={values.checkboxes[i]}
                                                onChange={() => this.handleCheckChange(i)}
                                            />
                                        </Box>
                                    );
                                })
                            ) : (
                                <I18n id={`${FORM_ID}.noCards.label`} />
                            )}

                            {/* Begin date */}
                            <Box className="info-label datepicker">
                                <Field
                                    component={DateField}
                                    handleChange={(e) => this.handleChangeDateFrom(e, "beginDate", "endDate")}
                                    hidePlaceholder
                                    id="beginDate"
                                    idForm={FORM_ID}
                                    maxDate={null}
                                    minDate={dateFrom}
                                    mode={MODE.EDIT}
                                    name="beginDate"
                                    showMonthYearDropdown
                                    timezoneOffset={timezoneOffset}
                                    tooltip={
                                        <I18n
                                            id={`${FORM_ID}.tooltip.${
                                                isTravelNotice
                                                    ? TRIP_OR_PURCHASE.TRAVEL_NOTICE
                                                    : TRIP_OR_PURCHASE.ONLINE_PURCHASE_NOTICE
                                            }.beginDate`}
                                        />
                                    }
                                />
                            </Box>
                            {/* End date */}
                            <Box className="info-label datepicker">
                                <Field
                                    component={DateField}
                                    handleChange={(e) => this.handleChangeDateFrom(e, "endDate", "beginDate")}
                                    hidePlaceholder
                                    id="endDate"
                                    idForm={FORM_ID}
                                    maxDate={null}
                                    minDate={beginDate}
                                    mode={MODE.EDIT}
                                    name="endDate"
                                    showMonthYearDropdown
                                    timezoneOffset={timezoneOffset}
                                    tooltip={
                                        <I18n
                                            id={`${FORM_ID}.tooltip.${
                                                isTravelNotice
                                                    ? TRIP_OR_PURCHASE.TRAVEL_NOTICE
                                                    : TRIP_OR_PURCHASE.ONLINE_PURCHASE_NOTICE
                                            }.endDate`}
                                        />
                                    }
                                />
                            </Box>
                            {/* Countries */}
                            {isTravelNotice && (
                                <Box className="info-label">
                                    <FieldLabel labelKey={`${FORM_ID}.country.label`} />
                                    <Field
                                        className="form-control"
                                        component={SuggestionsTagsInput}
                                        fieldTags={values.countries}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        mode={MODE.EDIT}
                                        name="countries"
                                        onChange={this.handleCountriesChange}
                                        suggestionsList={countries}
                                        tooltip={<I18n id={`${FORM_ID}.tooltip.countriesList`} />}
                                    />
                                </Box>
                            )}
                            {/* Store */}
                            {isOnlinePurchase && (
                                <Box className="info-label purchase-notice">
                                    <Field
                                        autoComplete="off"
                                        className="form-control"
                                        component={TextField}
                                        hidePlaceholder
                                        maxLength="80"
                                        idForm={FORM_ID}
                                        mode={MODE.EDIT}
                                        name="store"
                                        tooltip={<I18n id={`${FORM_ID}.tooltip.onlinePurchase`} />}
                                        type="text"
                                    />
                                </Box>
                            )}
                        </Box>
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">
                                <I18n id={`${FORM_ID}.finish`} />
                            </h3>
                        </Box>
                        <Box className="disclaimer">
                            {showDateDisclaimer && (
                                <div className="waiver">
                                    <Field
                                        className="form-control"
                                        component={Termsandconditions}
                                        i18nMap={i18nMap}
                                        idForm={FORM_ID}
                                        mode={MODE.EDIT}
                                        name="disclaimerWaiver"
                                        showAcceptOption
                                    />
                                </div>
                            )}
                            <div className="form-group disclaimer-container">
                                <div className="disclaimer-text">
                                    <I18n
                                        id={`${FORM_ID}.disclaimer.${
                                            isTravelNotice
                                                ? TRIP_OR_PURCHASE.TRAVEL_NOTICE
                                                : TRIP_OR_PURCHASE.ONLINE_PURCHASE_NOTICE
                                        }.first`}
                                    />
                                    <I18n id={`${FORM_ID}.disclaimer.second`} />
                                    <span>
                                        <I18n id={`${FORM_ID}.disclaimer.preLink`} />
                                        <I18n id={`${FORM_ID}.disclaimer.link`} />
                                    </span>
                                </div>
                            </div>
                        </Box>
                        <footer className="footer">
                            <Button
                                bsStyle="primary"
                                key="primaryButton"
                                label={`${FORM_ID}.notify`}
                                loading={false}
                                type="submit"
                                variant="primary"
                            />
                        </footer>
                    </section>
                </Form>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        dateFormat: SelectorsStoreConfig.getConfig(store)["frontend.shortDateFormat"]
            ? SelectorsStoreConfig.getConfig(store)["frontend.shortDateFormat"].toUpperCase()
            : FORMAT_SHORT,
        fetching: SelectorsStoreCreditCards.getFetching(store),
        lang: SelectorsStoreI18n.getLang(store),
        preData: SelectorsStoreCreditCards.getVisaMasterAccounts(store),
        timezoneOffset: SelectorsStoreCreditCards.getTimezoneOffset(store),
        tripNoticeData: SelectorsStoreCreditCards.getTripNoticeFormData(store),
    };
};

export default HighOrder(
    WithRouter,
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: (props) => {
            return Yup.lazy((values) => {
                const { preData } = props;
                const { accountVisaMaster, beginDate, checkboxes, endDate, operationType } = values;
                const selectedAccount = preData.find((account) => account.visaMasterAccount === accountVisaMaster);
                const { creditCardList } = selectedAccount;
                const checkToHighlightIfError = `${selectedAccount.idProduct}${UNDERSCORE}${
                    creditCardList?.length - 1
                }`;

                return Yup.object().shape({
                    beginDate: Yup.date()
                        .max(Yup.ref("endDate"), UtilsI18n.get(`${FORM_ID}.endDate.rangeError.higher`))
                        .required(UtilsI18n.get(`${FORM_ID}.beginDate.required`)),
                    countries:
                        operationType === TRIP_OR_PURCHASE.TRAVEL_NOTICE
                            ? Yup.array().min(1, UtilsI18n.get(`${FORM_ID}.countries.required`))
                            : Yup.array().notRequired(),
                    disclaimerWaiver:
                        Math.abs(UtilsDate.differenceInMonths(beginDate, endDate)) > 5
                            ? Yup.boolean().oneOf([true], UtilsI18n.get(`${FORM_ID}.disclaimerWaiver.required`))
                            : Yup.boolean().notRequired(),
                    endDate: Yup.date()
                        .min(Yup.ref("beginDate"), UtilsI18n.get(`${FORM_ID}.endDate.rangeError.under`))
                        .required(UtilsI18n.get(`${FORM_ID}.endDate.required`)),
                    store:
                        operationType === TRIP_OR_PURCHASE.ONLINE_PURCHASE_NOTICE
                            ? Yup.string().required(UtilsI18n.get(`${FORM_ID}.store.required`))
                            : Yup.string().notRequired(),
                    [checkToHighlightIfError]: checkboxes.some((checkbox) => checkbox === true)
                        ? Yup.boolean().notRequired()
                        : Yup.boolean().oneOf([true], UtilsI18n.get(`${FORM_ID}.checkboxes.required`)),
                });
            });
        },
        mapPropsToValues: ({ preData, tripNoticeData }) => {
            const checksToHighlightIfErrors = [];

            if (preData) {
                preData.forEach((accountVisaMaster) =>
                    checksToHighlightIfErrors.push(
                        accountVisaMaster.creditCardList
                            ? `${accountVisaMaster.idProduct}${UNDERSCORE}${
                                  accountVisaMaster.creditCardList.length - 1
                              }`
                            : `${accountVisaMaster.idProduct}${UNDERSCORE}undefined`,
                    ),
                );
            }

            let checkboxes;

            if (tripNoticeData && tripNoticeData.checkboxes) {
                checkboxes = Array(tripNoticeData.checkboxes.length).fill(false);

                for (let i = 0; i < tripNoticeData.checkboxes.length; i += 1) {
                    checkboxes[tripNoticeData.checkboxes[i]] = true;
                }
            } else {
                checkboxes = preData && preData[0].creditCardList ? preData[0].creditCardList.map(() => false) : [];
            }

            const values = {
                accountVisaMaster:
                    (tripNoticeData && tripNoticeData.accountVisaMaster) || (preData && preData[0].visaMasterAccount),
                beginDate: (tripNoticeData && UtilsDate.toDate(tripNoticeData.beginDate)) || EMPTY_STR,
                checkboxes,
                countries: (tripNoticeData && tripNoticeData.countries) || [],
                endDate: (tripNoticeData && UtilsDate.toDate(tripNoticeData.endDate)) || EMPTY_STR,
                operationType: (tripNoticeData && tripNoticeData.operationType) || TRIP_OR_PURCHASE.TRAVEL_NOTICE,
                store: (tripNoticeData && tripNoticeData.store) || EMPTY_STR,
                disclaimerWaiver: (tripNoticeData && tripNoticeData.disclaimerWaiver) || false,
            };

            for (let i = 0; i < checksToHighlightIfErrors.length; i += 1) {
                values[checksToHighlightIfErrors[i]] = false;
            }

            return values;
        },
        handleSubmit: (props, formikBag) => {
            const { dispatch } = formikBag.props;
            const {
                accountVisaMaster,
                beginDate,
                checkboxes,
                countries,
                disclaimerWaiver,
                endDate,
                operationType,
                store,
            } = props;

            const countriesOptions = GetMobileCountryOptions();
            const countryCodes = [];

            countries.forEach((selectedCountry) =>
                countryCodes.push(countriesOptions.find((country) => country.countryName === selectedCountry)?.country),
            );

            const formData = {
                accountVisaMaster,
                beginDate,
                checkboxes: checkboxes.map((check, i) => (check ? i : -1)).filter((index) => index !== -1),
                countries,
                countryCodes,
                disclaimerWaiver,
                endDate,
                operationType,
                store,
            };

            dispatch(SelectorsActionCreditCards.tripOrOnlinePurchaseNotificationPreviewRequest(formData));
        },
    }),
)(Component);
