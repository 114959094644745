import React from "react";

import { routerActions as RouterActions } from "connected-react-router";
import { connect as Connect } from "react-redux";
import { bindActionCreators } from "redux";

import { SelectorsStore as SelectorsStoreTicket } from "~/store/administration/common/administrationTicket";
import { SelectorsAction, SelectorsStore } from "~/store/administration/simple";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { getUser } from "~/util/store/administration";

import AdministrationTicket from "~/pages/administration/_components/tickets/AdministrationTicket";
import PermissionsTicket from "~/pages/administration/_components/tickets/PermissionsTicket";

export const NAME = "PermissionsTicketContent";

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    return (
        <AdministrationTicket {...props}>
            <PermissionsTicket {...props} />
        </AdministrationTicket>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    fetching: SelectorsStoreTicket.isFetching(store),
    fetchingPermissions: SelectorsStore.isFetchingTicket(store),
    lang: SelectorsStoreI18n.getLang(store),
    transactionData: SelectorsStoreTicket.getData(store),
    user: getUser(store),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(SelectorsAction, dispatch),
    routerActions: bindActionCreators(RouterActions, dispatch),
});

export default Connect(mapStateToProps, mapDispatchToProps)(Component);
