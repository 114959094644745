import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR, PASSPORT_CODE, REGEX_IGNORE_ACCENTS, REGEX_SPACE_STR } from "~/constants";
import { isEmpty as IsEmpty } from "~/util/string";

import HighOrder from "~/components/HighOrder";
import Select from "~/components/Select";
import WithFocus from "~/pages/_components/withFocus";

import FormField from "~/pages/forms/_components/_fields/_commons/formField";

export const NAME = "Document";

export const PROP = {
    types: {
        defaultCountry: PropTypes.string.isRequired,
        defaultDocumentType: PropTypes.string.isRequired,
        data: PropTypes.shape({
            countries: PropTypes.array,
            documentCountryMap: PropTypes.object,
        }).isRequired,
        editing: PropTypes.bool,
        value: PropTypes.shape({
            documentCountry: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            documentType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            document: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
        setValue: PropTypes.func.isRequired,
        focus: PropTypes.bool,
        placeholder: PropTypes.string,
        onBlur: PropTypes.func.isRequired,
        toggleIsFocused: PropTypes.func.isRequired,
        readOnly: PropTypes.bool,
        minLength: PropTypes.number,
        maxLength: PropTypes.number,
    },
    defaults: {
        editing: false,
        focus: false,
        placeholder: EMPTY_STR,
        readOnly: false,
        value: null,
        minLength: 0,
        maxLength: 30,
    },
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {};

    constructor(props) {
        super(props);
        this.docNumberRef = null;
    }

    componentDidMount() {
        const { editing, value, focus } = this.props;
        if (editing && !value) {
            const { defaultCountry, defaultDocumentType, setValue } = this.props;

            this.setState({
                country: defaultCountry,
                type: defaultDocumentType,
                document: EMPTY_STR,
            });

            setValue(EMPTY_STR);
        }
        // al primer campo del formulario por lo general se le pasa focus en true
        if (this.docNumberRef && focus) {
            this.docNumberRef.focus();
        }
    }

    customFilter (option, searchText){
        const optionLowerCase = option.name.toLowerCase();
        const text = searchText.toLowerCase();

        if(optionLowerCase.normalize("NFD").replace(REGEX_IGNORE_ACCENTS, "").includes(text) && optionLowerCase.charAt(0).includes(text.charAt(0))){
            return true;
        }
        else {
            return false
        }
    }

    handleCountryChange = ( value) => {
        if(value){
            const documentCountry = value.id
            const {
                setValue,
                data: { documentCountryMap },
                defaultDocumentType,
                defaultCountry,
            } = this.props;
    
            const { country: previousCountry, type: previousType, document: previousDocument } = this.state;
    
            if (documentCountry === previousCountry) {
                return;
            }
    
            const existsType = (country, type) => documentCountryMap[country].some(({ id }) => id === type);
            let documentType = EMPTY_STR;
    
            if (documentCountry === defaultCountry) {
                documentType = defaultDocumentType;
            } else if (!IsEmpty(previousDocument) && existsType(documentCountry, previousType)) {
                documentType = previousType;
            } else if (existsType(documentCountry, defaultDocumentType)) {
                documentType = defaultDocumentType;
            }
    
            // If no document was found, the first option would be selected by default
            if (documentType === EMPTY_STR) {
                let defaultIndex = 0;
    
                // If the country has documents other than PASSPORT, the first of those will be selected
                const existsTypeDefault = (country) => documentCountryMap[country].some(({ id }) => id !== PASSPORT_CODE);
                if (existsTypeDefault(documentCountry)) {
                    defaultIndex = documentCountryMap[documentCountry].findIndex(({ id }) => id !== PASSPORT_CODE);
                }
    
                const { id: idDocCountry } = documentCountryMap[documentCountry][defaultIndex];
                documentType = idDocCountry;
            }
    
            const newDocument = documentType === previousType ? previousDocument : EMPTY_STR;
    
            this.setState({
                country: documentCountry,
                type: documentType,
                document: newDocument,
            });
    
            setValue({
                documentType,
                documentCountry,
                document: newDocument,
            });
        }
    };

    handleTypeChange = ({ id }) => {
        const { setValue } = this.props;

        const { country, document } = this.state;

        this.setState({
            type: id,
        });

        setValue({
            documentCountry: country,
            documentType: id,
            document,
        });
    };

    handleDocumentChange = ({ target: { value } }) => {
        const { setValue } = this.props;

        const { country, type } = this.state;

        this.setState({
            document: this.sanitizeString(value),
        });

        setValue({
            documentCountry: country,
            documentType: type,
            document: this.sanitizeString(value),
        });
    };

    sanitizeString = (value) => {
        return value.replace(REGEX_SPACE_STR, EMPTY_STR);
    };

    render() {
        const {
            editing,
            value,
            placeholder,
            toggleIsFocused,
            readOnly,
            defaultCountry,
            defaultDocumentType,
            data: { countries, documentCountryMap },
            minLength,
            maxLength,
        } = this.props;

        const { country, type } = this.state;

        let selectedCountry = null;
        const newState = {};
        if (country && !value) {
            selectedCountry = country;
        } else {
            selectedCountry = value && value.documentCountry ? value.documentCountry : defaultCountry;
            newState.country = selectedCountry;
        }

        let selectedType = null;
        if (type && !value) {
            selectedType = type;
        } else {
            selectedType = value && value.documentType ? value.documentType : defaultDocumentType;
            newState.type = selectedType;
        }

        if (!country || !type) {
            this.setState(newState);
        }

        const selectedNumber = value && value.document ? value.document : EMPTY_STR;

        const documentTypes = documentCountryMap[selectedCountry];

        if (editing) {
            return (
                <div className="input-group" onFocus={toggleIsFocused} onBlur={toggleIsFocused}>
                    {countries.length > 1 && (
                        <Select
                            className="currency-selector slideFromBottom flex-container document"
                            name="country"
                            onChange={this.handleCountryChange}
                            value={selectedCountry}
                            valueKey="id"
                            labelKey="name"
                            options={countries}
                            clearable={false}
                            filterOption={this.customFilter}
                        />
                    )}
                    <Select
                        className="currency-selector slideFromBottom flex-container document"
                        name="documentType"
                        searchable={false}
                        onChange={this.handleTypeChange}
                        value={selectedType}
                        valueKey="id"
                        labelKey="name"
                        options={documentTypes}
                        clearable={false}
                    />
                    <input
                        className="form-control"
                        name="documentNumber"
                        type="text"
                        onChange={this.handleDocumentChange}
                        ref={(ref) => {
                            this.docNumberRef = ref;
                        }}
                        placeholder={placeholder}
                        readOnly={readOnly}
                        value={selectedNumber}
                        minLength={minLength}
                        maxLength={maxLength}
                    />
                </div>
            );
        }
        const { name: documentCountryLabel } = countries.find(({ id }) => id === selectedCountry) || {};

        const { name: documentTypeLabel } = documentTypes.find(({ id }) => id === selectedType) || {};

        return (
            <span>
                {documentTypeLabel} {selectedCountry !== defaultCountry && documentCountryLabel} {selectedNumber}
            </span>
        );
    }
}

export default HighOrder(
    WithFocus,
    FormField({
        formClass: "form-group--composite",
        isEmptyValue: (value) => {
            const { documentCountry, documentType, document } = value || {};
            return !documentCountry || !documentType || !document;
        },
    }),
)(Component);
