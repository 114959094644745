import React from "react";

import { Field, Form, Formik } from "formik";
import { Modal } from "react-bootstrap";

import * as i18nUtils from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import Image from "~/components/Image";
import TextArea from "~/pages/_components/fields/TextArea";
import TextField from "~/pages/_components/fields/TextField";

import Style from "./AddDocumentModal.rules.scss";

export const { NAME, NAME_MESSAGES } = Style;
export const PROP = {
    types: {},
    defaults: {},
};

const FORM_ID = "comex.creditLetter.modal.documentTypes";

export function Component({ show, handleCloseModal, documentTypeList, terms, shippedBy, submit, document }) {
    const [state, setState] = React.useState({
        step: 1,
        title: "Seleccione el documento a agregar",
    });

    const handleCheckClick = (type) => {
        setState({
            type,
            step: 2,
        });
    };

    if (Object.entries(document).length > 0 && state.step === 1) {
        handleCheckClick(document.type, null);
    }

    const reset = () => {
        setState({
            type: "",
            step: 1,
        });
    };

    const closeModal = () => {
        handleCloseModal();
        setTimeout(reset, 500);
    };

    const onSubmit = (values) => {
        const { type } = state;
        const { description } = values;

        submit({ ...values, type, description });

        closeModal();
    };

    const filterDocumentTypeList = () => {
        let fliteredList = [...documentTypeList];
        if (terms && !terms.includes("I")) {
            fliteredList = fliteredList.filter((d) => d !== "POLIZA_DE_SEGURO");
        }

        if (shippedBy === "AEREO") {
            fliteredList = fliteredList.filter(
                (d) => d !== "CONOCIMIENTO_DE_EMBARQUE_MARITIMO" && d !== "DOCUMENTO_DE_TRANSPORTE_TERRESTRE",
            );
        } else if (shippedBy === "MARITIMO") {
            fliteredList = fliteredList.filter(
                (d) => d !== "DOCUMENTO_DE_TRANSPORTE_AEREO" && d !== "DOCUMENTO_DE_TRANSPORTE_TERRESTRE",
            );
        } else if (shippedBy === "TERRESTRE") {
            fliteredList = fliteredList.filter(
                (d) => d !== "DOCUMENTO_DE_TRANSPORTE_AEREO" && d !== "CONOCIMIENTO_DE_EMBARQUE_MARITIMO",
            );
        } else if (shippedBy === "") {
            fliteredList = fliteredList.filter(
                (d) =>
                    d !== "CONOCIMIENTO_DE_EMBARQUE_MARITIMO" &&
                    d !== "DOCUMENTO_DE_TRANSPORTE_TERRESTRE" &&
                    d !== "DOCUMENTO_DE_TRANSPORTE_AEREO" &&
                    d !== "POLIZA_DE_SEGURO",
            );
        }

        return fliteredList;
    };

    const { step, type } = state;

    return (
        <Modal show={show}>
            <Modal.Body>
                <Formik
                    enableReinitialize
                    initialValues={{
                        description: document.description || "",
                        originals: document.originals || "",
                        copies: document.copies || "",
                    }}
                    onSubmit={onSubmit}
                    validate={(field) => {
                        const { copies, originals } = field;
                        const errors = {};

                        if (!parseInt(originals, 10) && !parseInt(copies, 10)) {
                            errors.originals = i18nUtils.get(`${FORM_ID}.oneField.required`);
                            errors.copies = i18nUtils.get(`${FORM_ID}.oneField.required`);
                        }

                        return errors;
                    }}>
                    {() => (
                        <Form id={Style.ID} noValidate>
                            <div className="component-modal">
                                <div className="closed clickable" onClick={closeModal} role="button">
                                    <Image src="close.svg" />
                                </div>
                                <h2 className="title">
                                    {i18nUtils.get(
                                        // eslint-disable-next-line no-nested-ternary
                                        step === 1
                                            ? `${FORM_ID}.title`
                                            : Object.entries(document).length > 0
                                            ? `${FORM_ID}.${document.type}`
                                            : `${FORM_ID}.${type}`,
                                    )}
                                </h2>
                                {step === 1 &&
                                    filterDocumentTypeList().map((t) => (
                                        <div
                                            onClick={() => handleCheckClick(t)}
                                            className="documentTypeRow"
                                            key={`documentType-row-${t}`}>
                                            <Box>{i18nUtils.get(`comex.creditLetter.modal.documentTypes.${t}`)}</Box>
                                        </div>
                                    ))}

                                {step === 2 && (
                                    <React.Fragment>
                                        <div className="vertical-direction">
                                            <Field
                                                idForm={FORM_ID}
                                                name="description"
                                                type="text"
                                                maxLength={160}
                                                placeholder=""
                                                component={TextArea}
                                            />
                                        </div>
                                        <div className="vertical-direction amount no-error-icon">
                                            <Field
                                                component={TextField}
                                                autoComplete="off"
                                                pattern="[0-9]{0,2}"
                                                type="text"
                                                idForm={FORM_ID}
                                                name="originals"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="vertical-direction amount">
                                            <Field
                                                component={TextField}
                                                autoComplete="off"
                                                pattern="[0-9]{0,2}"
                                                type="text"
                                                idForm={FORM_ID}
                                                name="copies"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="container-button-center">
                                            <Button
                                                key="primaryButton"
                                                variant="secondary"
                                                bsStyle="secondary"
                                                label="global.cancel"
                                                loading={false}
                                                type="button"
                                                onClick={closeModal}
                                            />
                                            <Button
                                                idForm={FORM_ID}
                                                key="primaryButton"
                                                variant="primary"
                                                bsStyle="primary"
                                                label="comex.imports.creditLetter.form.attachments.add"
                                                loading={false}
                                                type="submit"
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
}

export default Component;
