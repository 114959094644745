import PropTypes from "prop-types";

import { FactoryType } from "~/util/redux";

export const NAME = "APP";

export const TYPE = FactoryType(NAME, ["INIT", "READY", "CLEAN_UP"]);

export const PROP = {
    types: {
        isLoading: PropTypes.bool,
    },
    defaults: {
        isLoading: false,
    },
};
