import React from "react";

import { connect as Connect } from "react-redux";

import { REQUEST_CHECKBOOK_DELIVERY_METHOD_BRANCH } from "~/constants";
import { SelectorsStore as SelectorsStoreForm, PROP as PropForm } from "~/store/form";
import { ToRequired } from "~/util/prop";

import I18n from "~/components/I18n";

export const NAME = "RequestCheckbook";

export const PROP = {
    types: {
        ...ToRequired(PropForm.types, ["previewData"]),
    },
    defaults: {
        ...PropForm.defaults,
    },
};
export function Component(props) {
    const {
        previewData: {
            accountAlias,
            branchName,
            checkbookQty,
            checkbookType,
            contactPhone,
            deliveryAddress,
            deliveryMethod,
            deliveryMethodValue,
            documentNumber,
            documentType,
            estimatedTimeOfDelivery,
            firstReceiverDocumentNumber,
            firstReceiverName,
            name,
            secondDocumentType,
            secondReceiverDocumentNumber,
            secondReceiverName,
            thirdDocumentType,
            thirdReceiverDocumentNumber,
            thirdReceiverName,
        },
    } = props;

    return (
        <React.Fragment>
            <div className="data-section">
                <p className="section-title">
                    <I18n id="forms.requestCheckbook.confirmation.title" />
                </p>
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.requestCheckbook.confirmation.idAccountName" />
                    </span>
                    <span className="data-name">{accountAlias}</span>
                </div>
                {checkbookType && (
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="forms.requestCheckbook.confirmation.checkbookType" />
                        </span>
                        <span className="data-name">{checkbookType}</span>
                    </div>
                )}
                {checkbookQty && (
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="forms.requestCheckbook.confirmation.checkbookQty" />
                        </span>
                        <span className="data-name">{checkbookQty}</span>
                    </div>
                )}
            </div>
            <div className="data-section">
                <div className="section-title">
                    <h3 className="form-section-title-text">
                        <I18n id="forms.requestCheckbook.confirmation.checkbookDelivery" />
                    </h3>
                </div>
                {deliveryMethod && (
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="forms.requestCheckbook.confirmation.deliveryMethod" />
                        </span>
                        <span className="data-name">{deliveryMethodValue}</span>
                    </div>
                )}
                {REQUEST_CHECKBOOK_DELIVERY_METHOD_BRANCH[0] === deliveryMethod && (
                    <React.Fragment>
                        {branchName && (
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="forms.requestCheckbook.confirmation.idBranch" />
                                </span>
                                <span className="data-name">{branchName}</span>
                            </div>
                        )}
                    </React.Fragment>
                )}
                {REQUEST_CHECKBOOK_DELIVERY_METHOD_BRANCH[0] !== deliveryMethod && (
                    <React.Fragment>
                        {contactPhone && (
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="forms.requestCheckbook.confirmation.contactPhone" />
                                </span>
                                <span className="data-name">{contactPhone}</span>
                            </div>
                        )}
                        {deliveryAddress && (
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="forms.requestCheckbook.confirmation.deliveryAddress" />
                                </span>
                                <span className="data-name">{deliveryAddress}</span>
                            </div>
                        )}
                        {estimatedTimeOfDelivery && (
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="forms.requestCheckbook.confirmation.estimatedTimeOfDelivery" />
                                </span>
                                <span className="data-name">{estimatedTimeOfDelivery}</span>
                            </div>
                        )}
                    </React.Fragment>
                )}
            </div>
            {REQUEST_CHECKBOOK_DELIVERY_METHOD_BRANCH[0] === deliveryMethod && (
                <div className="data-section">
                    {(documentType || documentNumber || name) && (
                        <div className="section-title">
                            <h3 className="form-section-title-text">
                                <I18n id="forms.requestCheckbook.confirmation.authorizedToPickup" />
                            </h3>
                        </div>
                    )}
                    {documentType && documentNumber && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="forms.requestCheckbook.confirmation.document" />
                            </span>
                            <span className="data-name">
                                <I18n id={`documentType.label.${documentType}`} /> {documentNumber}
                            </span>
                        </div>
                    )}
                    {name && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="forms.requestCheckbook.confirmation.name" />
                            </span>
                            <span className="data-name">{name}</span>
                        </div>
                    )}
                </div>
            )}
            {REQUEST_CHECKBOOK_DELIVERY_METHOD_BRANCH[0] !== deliveryMethod && (
                <div className="data-section">
                    <div className="section-title">
                        <h3 className="form-section-title-text">
                            <I18n id="forms.requestCheckbook.confirmation.authorizedToPickup" />
                        </h3>
                    </div>
                    {documentType && firstReceiverDocumentNumber && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="forms.requestCheckbook.confirmation.document" />
                            </span>
                            <span className="data-name">
                                <I18n id={`documentType.label.${documentType}`} /> {firstReceiverDocumentNumber}
                            </span>
                        </div>
                    )}
                    {firstReceiverName && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="forms.requestCheckbook.confirmation.name" />
                            </span>
                            <span className="data-name">{firstReceiverName}</span>
                        </div>
                    )}
                    {secondDocumentType && secondReceiverDocumentNumber && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="forms.requestCheckbook.confirmation.document" />
                            </span>
                            <span className="data-name">
                                <I18n id={`documentType.label.${secondDocumentType}`} /> {secondReceiverDocumentNumber}
                            </span>
                        </div>
                    )}
                    {secondReceiverName && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="forms.requestCheckbook.confirmation.name" />
                            </span>
                            <span className="data-name">{secondReceiverName}</span>
                        </div>
                    )}
                    {thirdDocumentType && thirdReceiverDocumentNumber && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="forms.requestCheckbook.confirmation.document" />
                            </span>
                            <span className="data-name">
                                <I18n id={`documentType.label.${thirdDocumentType}`} /> {thirdReceiverDocumentNumber}
                            </span>
                        </div>
                    )}
                    {thirdReceiverName && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="forms.requestCheckbook.confirmation.name" />
                            </span>
                            <span className="data-name">{thirdReceiverName}</span>
                        </div>
                    )}
                </div>
            )}
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (state) => ({
    name: SelectorsStoreForm.getName(state),
    previewData: SelectorsStoreForm.getPreviewData(state),
});

export default Connect(mapStateToProps)(Component);
