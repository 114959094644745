import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { useLocation } from "react-router-dom";

import { SelectorsStore as StoreI18n, PROP as PROP_I18N } from "~/store/i18n";
import {
    SelectorsStore as StoreSession,
    SelectorsAction as ActionSession,
    PROP as PROP_SESSION,
} from "~/store/session";
import * as I18nUtil from "~/util/i18n";

import Container from "~/components/Component";
import I18n from "~/components/I18n";
import WidgetImage from "~/components/WidgetImage";

import Style from "./_Environment.rules.scss";

export const { NAME, TYPE } = Style;
export const PROP = {
    types: {
        environments: PROP_SESSION.types.environments,
        label: PROP_I18N.types.message,
        current: PropTypes.number,
    },
    defaults: {
        environments: PROP_SESSION.defaults.environments,
        label: PROP_I18N.defaults.message,
        current: null,
    },
};

export function Component(props) {
    const { dispatch, environments, label, pathname, ...rest } = props;
    const location = useLocation();
    const [filter, setFilter] = React.useState("");
    const Environments = environments
        .filter(
            (env) =>
                env.isCurrent ||
                !filter.length ||
                (filter.length && `${env.name}${env.group}`.toLowerCase().indexOf(filter.toLowerCase()) !== -1),
        )
        .map((env) => <Environment key={env.id} {...env} />);

    const Curr = Environments.slice(0, 1);
    const Rest = Environments.slice(1);
    return (
        <Container {...rest} id={Style.ID} tag="section" name={NAME} type={TYPE}>
            <input type="checkbox" id={Style.ID_TOGGLE} />
            {Curr}
            {environments.length > 1 && (
                <React.Fragment>
                    <label htmlFor={Style.ID_TOGGLE} />
                    <nav role="navigation" aria-label="terciary">
                        <input
                            type="text"
                            value={filter}
                            placeholder={I18nUtil.get("environments.list.filter")}
                            onChange={handleFilter}
                        />
                        {Rest}
                        {(filter.length && !Rest.length && <I18n id="environment.filter.noresults" />) || undefined}
                    </nav>
                </React.Fragment>
            )}
        </Container>
    );

    function handleFilter(ev) {
        setFilter(ev.target.value.length ? ev.target.value : "");
    }

    function handleClick(ev) {
        const { currentTarget } = ev;
        const { key } = currentTarget.dataset;
        document.getElementById(Style.ID_TOGGLE).checked = false;
        dispatch(
            ActionSession.changeEnvironment({
                idEnvironment: parseInt(key, 10),
                rememberEnvironment: false,
                formikBag: null,
                shouldNavigate: false,
                location,
            }),
        );
    }

    function Environment(env) {
        const { id, type, name, group, isCurrent } = env;
        return (
            <button type="button" disabled={isCurrent} data-key={id} onClick={handleClick}>
                <WidgetImage src={`${type}.svg`} />
                <dl>
                    <dt>{name}</dt>
                    <dd>
                        <small>{label}</small>
                        <span>{group}</span>
                    </dd>
                </dl>
            </button>
        );
    }
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    const available = StoreSession.getEnvironments(store) || [];
    const current = StoreSession.getActiveEnvironment(store).id;
    const environments = Object.values(available)
        .map((env) => ({
            id: env.idEnvironment,
            type: env.environmentType,
            name: env.name,
            group: env.productGroupId,
            isCurrent: String(env.idEnvironment) === String(current),
        }))
        .sort((a) => (a.isCurrent ? -1 : 1));

    return {
        environments,
        current,
        label: StoreI18n.getMessage(store, "menu.account.number"),
    };
};

export default Connect(mapStateToProps)(Component);
