import PropTypes from "prop-types";

export const Members = {};

/** Nazca propTypes */
export const Types = {
    /** These sometimes come as "N/D" (meaning Not available) so they're either a String or a Number */
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

/** Nazca defaultProps */
export const Defaults = {
    amount: null,
};

export default {
    members: Members,
    types: Types,
    defaults: Defaults,
};
