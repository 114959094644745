import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsAction } from "~/store/comex";
import { SelectorsStore as SelectorsStoreComexImport } from "~/store/comex/import";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import I18n from "~/components/I18n";
import Ticket from "~/components/Ticket/Ticket";
import FormattedAmount from "~/pages/_components/FormattedAmount";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const { transaction, ticketConfirmation, dispatch } = props;
    const { data } = transaction;

    const {
        operationNumber,
        subOperationNumber,
        invoiceAmount,
        formattedAmount,
        beneficiary,
        oldAmount,
        invoiceAmountCurrency,
        accountNumber,
        authorizedDocumentName,
        authorizedDocumentDocumentType,
        authorizedDocumentDocumentNumber,
    } = data;

    const shareTicket = () => {
        dispatch(
            SelectorsActionTransactions.shareTicketRequest({
                transactionData: data,
                idActivity: "comex.import.cancellationCreditLetter.send",
            }),
        );
    };

    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    displayDownloadPDF
                    handleDownloadPDF={() => shareTicket(transaction.idTransaction)}
                    handleSign={handleSign}
                    hasMobileComponent>
                    <Box flex directionColumn align="flex-start" className="mb-3">
                        <div className="opening">
                            <I18n id="client.import.cancellationCreditLetter.step2.opening" tag="p" className="mt-3" />
                        </div>
                        <div className="info-label">
                            <h4 className="bold">
                                {UtilsI18n.get("client.import.cancellationCreditLetter.step2.operationNumber")}
                            </h4>
                            <span>
                                {operationNumber}
                                {subOperationNumber && "." + subOperationNumber}
                            </span>
                        </div>
                        <div className="info-label">
                            <h4 className="bold">
                                {UtilsI18n.get("client.import.cancellationCreditLetter.step2.amount")}
                            </h4>
                            <FormattedAmount currency={invoiceAmountCurrency} quantity={parseFloat(invoiceAmount)} />
                        </div>
                        <div className="info-label">
                            <h4 className="bold">
                                {UtilsI18n.get("client.import.cancellationCreditLetter.step2.recipient")}
                            </h4>
                            <span>{beneficiary}</span>
                        </div>

                        <div className="opening">
                            <I18n
                                id={`client.import.cancellationCreditLetter.step2.content.${
                                    Math.abs(oldAmount) > invoiceAmount ? "partial" : "total"
                                }`}
                                accountNumber={accountNumber}
                                currency={UtilsI18n.get(`core.currency.label.${invoiceAmountCurrency}`)}
                                quantity={formattedAmount}
                                tag="p"
                                className="mt-3"
                            />
                        </div>
                        {authorizedDocumentDocumentNumber && (
                            <I18n
                                id="client.import.cancellationCreditLetter.step2.terms"
                                userName={authorizedDocumentName}
                                documentNumber={authorizedDocumentDocumentNumber}
                                documentType={UtilsI18n.get(`documentType.label.${authorizedDocumentDocumentType}`)}
                                tag="p"
                                className="mt-3 mb-3"
                            />
                        )}
                    </Box>
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { idTransaction } = transaction;
        dispatch(
            SelectorsAction.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        dispatch(SelectorsAction.signTransactionPreview({ ...data }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreComexImport.getTransaction(store),
        formData: SelectorsStoreComexImport.getCancellationCreditLetterFormData(store),
    };
};

export default Connect(mapStateToProps)(Component);
