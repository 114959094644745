/* global document window */
import React from "react";

import Device from "~/util/device";

export const NAME = "HighOrder.Resizable";

export const PROP = {
    types: {},
    defaults: {},
};

/**
 * Higher-Order Component para el manejo del evento resize de la ventana.
 * Se utiliza en conjunto con el HOC resizable
 * @param {React.Component} BaseComponent
 */
export function Component(BaseComponent) {
    return class _Component extends React.Component {
        static displayName = NAME;

        static defaultProps = PROP.defaults;

        static propTypes = PROP.types;

        state = {
            display: Device.display(),
        };

        _timeout = null;

        componentDidMount() {
            window.addEventListener("resize", this.onResize);
        }

        componentWillUnmount() {
            window.removeEventListener("resize", this.onResize);

            if (this._timeout) {
                clearTimeout(this._timeout);
            }
        }

        onResize = () => {
            clearTimeout(this._timeout);
            this._timeout = setTimeout(this.updateDisplay, 100);
        };

        updateDisplay = () => {
            const { display } = this.state;
            const newDisplay = Device.display();

            if (display !== newDisplay) {
                // Dispatch 'display-changed' event
                document.dispatchEvent(new Event("display-changed"));

                this.setState({
                    display: newDisplay,
                });
            }
        };

        render() {
            // TODO: Find alternatives for using this component throught the project.
            return (
                <BaseComponent
                    {...this.props}
                    isDesktop={Device.isDisplayDesktop()}
                    isMobile={Device.isDisplayMobile()}
                    isMobileNative={Device.isMobileNative()}
                    mobileOS={Device.mobileOS()}
                />
            );
        }
    };
}

export default Component;
