import React from "react";

import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { EMPTY_STR, SPACE_STR, YES_ES } from "~/constants";
import { STATUS } from "~/constants/transaction";
import { SelectorsStore as SelectorsStoreComex, SelectorsAction as SelectorsActionComex } from "~/store/comex";
import {
    SelectorsStore as SelectorsStoreComexExport,
    SelectorsAction as SelectorsActionComexExport,
} from "~/store/comex/export";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import DeviceUtils from "~/util/device";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import DataDate from "~/components/DataDate";
import GridLayout from "~/components/GridLayout";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Token from "~/components/Token/Token";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import TicketPrintInformation from "~/pages/_components/TicketPrintInformation";

import { FORM_STEP_2, ID_ACTIVITY_SEND, COLUMN_HEADINGS } from "../FormConstants";
import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        isSubmitting: PropTypes.bool,
    },
    defaults: {
        isSubmitting: false,
    },
};

const printForm = () => {
    const elements = window.document.getElementsByClassName("no_print");

    for (let i = 0; i < elements.length; i += 1) {
        elements[i].style.display = "none";
    }

    window.print();

    for (let i = 0; i < elements.length; i += 1) {
        elements[i].style.display = EMPTY_STR;
    }

    return true;
};

export function Component(props) {
    const { isSubmitting, dispatch, formData } = props;
    const {
        valueDate,
        operationNumber,
        courierAccountNumber,
        courierName,
        oldAmount,
        originalCreditLetterNumber,
        documents,
        invoiceNumber,
        invoiceAmountCurrency,
        utilizationAmount,
        utilizationAmountCurrency,
        creditLetter,
        discrepancies,
    } = formData;

    const shareTicket = () => {
        dispatch(
            SelectorsActionTransactions.shareTicketRequest({
                transactionData: formData,
                idActivity: ID_ACTIVITY_SEND,
            }),
        );
    };

    const showDiscrepancies = discrepancies === YES_ES;

    return (
        <Form id={Style.ID}>
            <TicketPrintInformation />
            <Box flex directionColumn align="flex-start" className="mb-3">
                <DataDate value={valueDate} />
                <div className="opening">
                    <I18n id={`${FORM_STEP_2}.opening`} tag="p" className="mt-3" />
                </div>
                {creditLetter && (
                    <div className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${FORM_STEP_2}.operationNumber`)}</h4>
                        <span>{operationNumber}</span>
                    </div>
                )}
                <div className="info-label">
                    <h4 className="bold">{UtilsI18n.get(`${FORM_STEP_2}.original.letter`)}</h4>
                    <span>{originalCreditLetterNumber}</span>
                </div>
                <div className="info-label">
                    <h4 className="bold">{UtilsI18n.get(`${FORM_STEP_2}.amount`)}</h4>
                    <FormattedAmount currency={invoiceAmountCurrency} quantity={parseFloat(oldAmount)} />
                </div>
                <div className="info-label">
                    <h4 className="bold">{UtilsI18n.get(`${FORM_STEP_2}.invoiceNumber`)}</h4>
                    <span>{invoiceNumber}</span>
                </div>
                <div className="info-label">
                    <h4 className="bold">{UtilsI18n.get(`${FORM_STEP_2}.utilization`)}</h4>
                    <FormattedAmount currency={utilizationAmountCurrency} quantity={parseFloat(utilizationAmount)} />
                </div>

                <div className="opening">
                    <I18n id={`${FORM_STEP_2}.documents`} tag="p" className="mt-3" />
                </div>
                <GridLayout className="header ticket-table-section">
                    {COLUMN_HEADINGS.map((heading) => {
                        return <div>{UtilsI18n.get(`${FORM_STEP_2}.columnheaders.${heading}`)}</div>;
                    })}
                </GridLayout>
                {documents &&
                    documents.map((document) => (
                        <GridLayout className="item ticket-table-section">
                            <div>{UtilsI18n.get(`comex.creditLetter.modal.documentTypes.${document.type}`)}</div>
                            <div>{document.description}</div>
                            <div>{document.originals}</div>
                            <div>{document.copies}</div>
                        </GridLayout>
                    ))}

                {!creditLetter && (
                    <div className="mt-5">
                        <I18n id={`${FORM_STEP_2}.original.present`} tag="p" />
                    </div>
                )}
                {courierName && (
                    <div className={creditLetter ? "mt-5" : " mt-1"}>
                        <div>
                            {UtilsI18n.get(`${FORM_STEP_2}.courierName`)}
                            {courierName}
                            {SPACE_STR}
                            {UtilsI18n.get(`${FORM_STEP_2}.courierAcountNumber`)}
                            {courierAccountNumber}
                        </div>
                    </div>
                )}
                {showDiscrepancies && (
                    <div className={`${!courierName ? "mt-5" : " mt-1"} opening`}>
                        <I18n id={`${FORM_STEP_2}.discrepancies`} tag="p" className="mt-1" />
                    </div>
                )}
            </Box>

            <div className="no_print">
                <Token labelButton="global.send" isSubmitting={isSubmitting} />
            </div>
            {!DeviceUtils.isMobileNative() && (
                <div className="buttonsTicketConfirmation">
                    <Button
                        block={false}
                        bsStyle="link"
                        label="comex.preview.print.label"
                        onClick={() => printForm()}
                    />
                </div>
            )}
            <div className="buttonsTicketConfirmation">
                <Button bsStyle="link" block={false} label="forms.ticket.printPDF" onClick={() => shareTicket()} />
            </div>
        </Form>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        formData: SelectorsStoreComexExport.getComexFormData(store),
        transaction: SelectorsStoreComex.getTransaction(store),
        user: SelectorsStoreSession.getUser(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithRouter,
    WithFormik({
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        validationSchema: (props) => {
            const { user } = props;

            return Yup.object().shape({
                otp: user.needsBiometric
                    ? Yup.string().nullable()
                    : Yup.string().required(UtilsI18n.get(`${FORM_STEP_2}.otp.required`)),
            });
        },
        handleSubmit: ({ otp }, formikBag) => {
            const {
                dispatch,
                history,
                formData,
                transaction: { idTransaction, idActivity, idTransactionStatus },
            } = formikBag.props;

            const isFromTransactionsHistory = history.location.pathname.includes("/ticket");

            if (isFromTransactionsHistory && idTransactionStatus !== STATUS.DRAFT) {
                dispatch(
                    SelectorsActionComex.signTransaction({
                        idForm: null,
                        idActivity,
                        idTransaction,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            } else {
                dispatch(
                    SelectorsActionComexExport.presentationCreditLetterSendRequest({
                        formData,
                        credentials: { otp },
                        formikBag,
                        idTransaction,
                    }),
                );
            }
        },
    }),
)(Component);
