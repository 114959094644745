const ticketMap = {
    "administration.advanced.group.create.send": "GroupFormDataTicketContent",
    "administration.advanced.group.modify.send": "GroupFormDataTicketContent",
    "administration.advanced.send.user.data": "AdministrationChannelsTicket",
    "administration.groups.blockunblock.send": "GroupFormBlockTicketContent",
    "administration.groups.delete.send": "GroupFormBlockTicketContent",
    "administration.medium.modify.permissions.send": "PermissionsTicket",
    "administration.signatures.create.send": "SignaturesSchemeAdvancedTicket",
    "administration.signatures.delete.send": "SignaturesSchemeAdvancedTicket",
    "administration.signatures.medium.modify.send": "SignaturesSchemeMediumTicket",
    "administration.signatures.modify.send": "SignaturesSchemeAdvancedTicket",
    "administration.simple.modify.permissions.send": "PermissionsTicket",
    "administration.users.invite.send": "UserInviteTicket",
    "client.factoring.advancePayments.send": "AdvancePaymentsTransactionTicket",
    "client.factoring.paymentOrders.upload.execute": "UploadPaymentOrdersTicket",
    "client.factoring.suppliers.upload.execute": "UploadSuppliersTicket",
    "client.wm.bonds.buy.send": "BuyBondsTicket",
    "client.wm.bonds.sell.send": "SellBondsTicket",
    "client.wm.funds.buy.send": "BuyFundsTicket",
    "client.wm.funds.sell.send": "SellFundsTicket",
    "client.wm.funds.switch.send": "SwitchFundsTicket",
    "client.wm.shares.buy.send": "BuySharesTicket",
    "client.wm.shares.sell.send": "SellSharesTicket",
    "comex.bondsAndGuarantees.modifyBondsAndGuarantees.send": "ModifyBondsAndGuaranteesTicket",
    "comex.bondsAndGuarantees.requestBondsAndGuarantees.send": "RequestBondsAndGuaranteesTicket",
    "comex.export.billingClaim.send": "BillingClaimTicket",
    "comex.export.buyCreditLetter.send": "DocumentsPurchaseTicket",
    "comex.export.cessionCreditLetter.send": "CessionCreditLetterTicket",
    "comex.export.discrepancyDocumentAuthorization.send": "AuthorizationWithDiscrepanciesTicket",
    "comex.export.presentationDocumentsCreditLetter.send": "DocumentPresentationCreditLetterTicket",
    "comex.export.presentBillingDocuments.send": "BillingDocumentsPresentationTicket",
    "comex.export.transferCreditLetter.send": "TransferCreditLetterTicket",
    "comex.import.billingCancellation.send": "BillingCancellationTicket",
    "comex.import.cancellationCreditLetter.send": "CancellationCreditLetterTicket",
    "comex.import.creditLetterDocumentPresentation.send": "PresentationDomesticCreditLetterTicket",
    "comex.import.genericForm.send": "OtherProceduresTicket",
    "comex.import.liftCreditLetterWithDiscrepancies.send": "LiftCreditLetterWithDiscrepanciesTicket",
    "comex.import.modifyCreditLetter.send": "ModifyCreditLetterTicket",
    "comex.import.requestEndorsement.send": "RequestEndorsementTicket",
    "comex.imports.creditLetterOpen.send": "OpenCreditLetterTicket",
    "connectedParties.registerBantotal": "ConnectedPartiesTicket",
    "creditCards.limit.increase.send": "LimitIncreaseTicket",
    "creditCards.purchaseNotification.send": "PurchaseNotificationTicket",
    "creditCards.tripOrOnlinePurchaseNotification.send": "TripOrOnlinePurchaseNotificationTicket",
    "investment.riskProfile.send": "RiskProfileTicket",
    "investment.termDeposit.send": "TermDepositTicket",
    "investment.termDepositCMB.send": "TermDepositTicket",
    "pay.customs.payCustoms": "CustomsPaymentTicket",
    "payments.qrModo.send": "QRModoPaymentTicket",
    "preferences.modify.accounts.data": "ChangeAccountDataTicket",
    "preferences.modifyuser.configuration.execute": "ChangePersonalInformationTicket",
    "preferences.update.email.accounts.data": "ChangeEmailTicket",
    "preferences.userData.mail.update.execute": "ChangeEmailTicket",
    "preferences.userData.mobilePhone.update": "ChangeMobilePhoneTicket",
    "preferentialTradingPrice.send": "PreferentialTradingPriceTicket",
    "service.payment.send": "ServicePaymentTicket",
    "transfers.alias.administration.aliasDeletionSend": "AliasDeletionTicket",
    "transfers.alias.administration.aliasEnrollmentSend": "AliasEnrollmentTicket",
};

export default ticketMap;
