import React from "react";

import { routerActions as RouterActions } from "connected-react-router";
import { connect as Connect } from "react-redux";
import { bindActionCreators } from "redux";

import { SelectorsStore as SelectorsStoreGroup } from "~/store/administration/advanced/group";
import { SelectorsStore as SelectorsStoreGroupTicket } from "~/store/administration/common/administrationTicket";

import AdministrationTicket from "~/pages/administration/_components/tickets/AdministrationTicket";
import GroupFormDataTicketContent from "~/pages/administration/_components/tickets/GroupFormDataTicketContent";

export const NAME = "GroupFormDataTicket";

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    return (
        <AdministrationTicket {...props}>
            <GroupFormDataTicketContent {...props} />
        </AdministrationTicket>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    externalFetching: SelectorsStoreGroup.isFetching(store),
    transactionData: SelectorsStoreGroupTicket.getData(store),
});

const mapDispatchToProps = (dispatch) => ({
    routerActions: bindActionCreators(RouterActions, dispatch),
});

export default Connect(mapStateToProps, mapDispatchToProps)(Component);
