import { LOCATION_CHANGE } from "connected-react-router";

import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { TYPE as TypesBackoffice } from "~/store/backoffice";
import { TYPE as TypeBiometric } from "~/store/biometric";
import { TYPE as SessionTypes } from "~/store/session";
import { TYPE as TemplateTypes } from "~/store/template";
import { TYPE as Transactionsypes } from "~/store/transactions";
import {
    shouldKeepFormState as ShouldKeepFormState,
    shouldKeepStateInUrlScope as ShouldKeepStateInUrlScope,
    checkIdFormInRoute as CheckIdFormInRoute,
    isFromPaymentTransactionLines as IsFromPaymentTransactionLines,
} from "~/util/form";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            let shouldKeepStateInUrlScope = ShouldKeepStateInUrlScope(action.payload.location.pathname);
            if (storeProp.id !== undefined) {
                shouldKeepStateInUrlScope =
                    shouldKeepStateInUrlScope && CheckIdFormInRoute(action.payload.location.pathname, storeProp.id);
            }
            const shouldKeep = ShouldKeepFormState(storeProp, action.payload.location.pathname);
            const isFromPayment = IsFromPaymentTransactionLines(action.payload.location.pathname, storeProp.id);

            if (shouldKeep || storeProp.saveData || shouldKeepStateInUrlScope || isFromPayment) {
                return {
                    ...storeProp,
                    prevRoute: action.payload.location,
                    shouldKeepFormState: shouldKeep || shouldKeepStateInUrlScope || isFromPayment,
                };
            }
            return {
                ...PROP.defaults,
                prevRoute: action.payload.location,
                shouldKeepFormState: shouldKeep || shouldKeepStateInUrlScope,
            };
        }
        case TYPE.CANCEL_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                isCancellingTransaction: false,
                showModal: false,
            };
        case TYPE.CANCEL_TRANSACTION_PRE_ERROR:
            return {
                ...storeProp,
                fetching: false,
                isCancellingTransaction: false,
            };
        case TYPE.CANCEL_TRANSACTION_PRE_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                isCancellingTransaction: true,
            };

        case TYPE.CANCEL_TRANSACTION_PRE_SUCCESS:
            return {
                ...storeProp,
                credentialsGroups: action.credentialsGroups,
                fetching: false,
            };

        case TYPE.SAVE_DRAFT_REQUEST:
        case TYPE.MODIFY_TRANSACTION_REQUEST:
        case TYPE.SIGN_TRANSACTION_PREVIEW_REQUEST:
        case TYPE.READ_TRANSACTION_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.SIGN_TRANSACTION_PREVIEW_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.FROM_BIOMETRIC:
        case TYPE.SAVE_DRAFT_FAILURE:
        case TYPE.SAVE_DRAFT_SUCCESS:
        case TYPE.MODIFY_TRANSACTION_FAILURE:
        case TYPE.READ_TRANSACTION_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };

        case TYPE.READ_FORM_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case SessionTypes.CHANGE_ENVIRONMENT_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case SessionTypes.CHANGE_ENVIRONMENT_ERROR:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.READ_FORM_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                id: action.idForm,
                metadata: action.formMetadata,
                data: {
                    ...action.formData,
                    ...storeProp.data,
                },
                transaction: {},
            };

        case TYPE.PREVIEW_FORM_REQUEST:
            return {
                ...storeProp,
                submit: true,
                values: action.values,
            };
        case TYPE.PREVIEW_FORM_SUCCESS:
        case TYPE.SIGN_TRANSACTION_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                commission: action?.commission,
                credentialsGroups: action.credentialsGroups,
                data: {
                    ...storeProp.data,
                    ...action.submitActionParams.values,
                },
                fetching: false,
                mode: MODE.PREVIEW,
                prevMode: storeProp.mode,
                previewData: action.previewData,
                submit: false,
                submitAction: action.submitAction,
                submitActionParams: {
                    values: { scheduler: null },
                    ...action.submitActionParams,
                },
            };
        case TYPE.SUGGEST_OTHER_LOCAL_TRANSFER_OPTION:
            return {
                ...storeProp,
                credentialsGroups: action.credentialsGroups,
                data: { ...storeProp.data, ...action.submitActionParams.values },
                fetching: false,
                mode: MODE.PREVIEW,
                prevMode: MODE.EDIT,
                previewData: action.previewData,
                submit: false,
                submitAction: action.submitAction,
                submitActionParams: {
                    values: { scheduler: null },
                    ...action.submitActionParams,
                },
                waitingForResponse: false,
            };
        case TYPE.SEND_FORM_SUCCESS:
            return {
                ...storeProp,
                credentialsGroups: [],
                idTransaction: action.idTransaction,
                fetching: false,
                submit: false,
                transaction: action.transaction,
                data: action.transaction.data,
                mode: MODE.VIEW,
                waitingForResponse: false,
                shouldKeepFormState: false,
            };

        case TYPE.SEND_FORM_DATA_FAILURE:
            return {
                ...storeProp,
                fetching: false,
                submit: false,
                mode: MODE.EDIT,
                waitingForResponse: false,
                shouldKeepFormState: false,
            };
        case Transactionsypes.DELAYED_START:
            return {
                ...storeProp,
                submit: false,
            };
        case TYPE.SEND_FORM_CREDENTIAL_FAILURE:
            return {
                ...storeProp,
                fetching: false,
                waitingForResponse: false,
                submit: false,
            };
        case TYPE.READ_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                transaction: action.transaction,
                childrenTransactions: action.childrenTransactions,
                parentTransaction: action.parentTransaction,
                id: action.idForm,
                data: action.transaction.data,
                metadata: action.formMetadata,
                mode: action.transaction.idTransactionStatus === STATUS.DRAFT ? MODE.EDIT : MODE.VIEW,
                comeFrom: action.comeFrom,
                credentialsGroups: action.credentialsGroups,
                transactionCredential: action.transactionCredential,
            };
        case TYPE.CLOSE_TRANSACTION_PREVIEW:
            return {
                ...storeProp,
                fetching: false,
                mode: storeProp.prevMode,
                prevMode: storeProp.mode,
            };
        case TemplateTypes.SELECT_TEMPLATE:
            return {
                ...storeProp,
                data: action.item.transactionData,
            };
        case TYPE.SET_DATA:
            return {
                ...storeProp,
                data: { ...storeProp.data, ...action.data },
            };
        case TYPE.FORM_DATA_SAVE:
            return { ...storeProp, formData: { ...action.data } };

        case TYPE.FORM_DATA_RESET:
            return { ...storeProp, formData: {} };
        case TYPE.TRANSACTION_REQUEST_DELAY: {
            return {
                ...storeProp,
                waitingForResponse: true,
            };
        }
        case TYPE.SEND_FORM_REQUEST:
            return { ...storeProp, submit: true };
        case TYPE.SEND_FORM_FAIL:
            return { ...storeProp, submit: false };
        case TYPE.SAVE_DATA:
            return { ...storeProp, saveData: true };
        case TYPE.SAVE_DATA_OFF:
            return { ...storeProp, saveData: false };
        case TYPE.CHANGE_FORM_METADATA:
            return {
                ...storeProp,
                metadata: action.formMetadata,
            };
        case TYPE.DEPENDEE_CHANGED:
            return {
                ...storeProp,
                metadata: {
                    ...storeProp.metadata,
                    dependeeFields: {
                        ...storeProp.metadata.dependeeFields,
                        [action.idField]: action.value,
                    },
                },
            };
        case TYPE.TOGGLE_MODAL:
            return {
                ...storeProp,
                showModal: !storeProp.showModal,
            };
        case TYPE.SWITCH_MASSIVE_PAYMENTS_TO_MANUAL:
            return {
                ...storeProp,
                data: {
                    ...storeProp.data,
                    loadingMethod: ["manual"],
                    file: [],
                },
            };
        case TYPE.CLEAN_STEPS:
            return {
                ...storeProp,
                data: {},
                fetching: true,
                mode: MODE.EDIT,
                transaction: {},
            };
        case TYPE.CLEAN_ALL:
            return PROP.defaults;
        case TypeBiometric.SHOW_PASSWORD_MODAL:
            return {
                ...storeProp,
                submit: false,
            };
        case TypesBackoffice.BACKOFFICE_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                credentialsGroups: [],
                data: action.transactionData,
                fetching: false,
                id: action.transaction.idForm,
                idTransaction: action.idTransaction,
                metadata: action.form,
                mode: MODE.VIEW,
                shouldKeepFormState: false,
                submit: false,
                transaction: action.transaction,
                waitingForResponse: false,
            };
        default:
            return storeProp;
    }
};
