import React from "react";

import PropTypes from "prop-types";

import { FIELD_SUBTYPE, EMPTY_STR } from "~/constants";
import * as UtilsI18n from "~/util/i18n";

import TagsInput from "~/pages/_components/fields/TagsInput";

import formField from "~/pages/forms/_components/_fields/_commons/formField";

export const NAME = "Emaillist";

export const PROP = {
    types: {
        value: PropTypes.arrayOf(PropTypes.string),
        setValue: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,
        setTouched: PropTypes.func.isRequired,
        isRequired: PropTypes.bool,
        editing: PropTypes.bool,
        placeholder: PropTypes.string,
        i18n: PropTypes.func.isRequired,
        data: PropTypes.shape({
            emailValidationRegex: PropTypes.string,
            addMessage: PropTypes.string,
        }).isRequired,
    },
    defaults: { isRequired: false, editing: false, placeholder: null, value: [] },
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        const { value, setValue } = this.props;
        setValue(value);
    }

    shouldRenderSuggestions = (value) => {
        const { data } = this.props;
        return value && new RegExp(data.emailValidationRegex).test(value);
    };

    renderSuggestion = (suggestion) => {
        const { data } = this.props;
        return <span>{`${data.addMessage} ${suggestion}`}</span>;
    };

    handleChange = (tags) => {
        const { setValue, setError, setTouched } = this.props;
        const lastValue = tags[tags.length - 1];
        const oldTags = tags.slice(0, tags.length - 1);
        if (oldTags.indexOf(lastValue) !== -1) {
            setError(UtilsI18n.get("client.tagsInput.repeated.value"));
            setTouched();
        } else {
            setValue(tags);
        }
    };

    handleValidationReject = ([newValue]) => {
        const {
            value,
            i18n,
            setError,
            setTouched,
            isRequired,
            subType,
            form: { values },
        } = this.props;

        if (value && value.length === 0 && isRequired) {
            setError(i18n("requiredError"));
        } else if (newValue !== EMPTY_STR && subType === FIELD_SUBTYPE.REFERENCE) {
            const { referenceLength: MINLENGTH } = values.idAgreement;
            setError(UtilsI18n.get("pay.automaticDebit.referenceMinLengthNotReached", null, { MINLENGTH }));
        } else if (newValue !== "") {
            setError(i18n("invalidError"));
        } else {
            setError(null);
        }

        setTouched();
    };

    getSuggestions = (inputValue) => [inputValue];

    isTextValid = (newText) => {
        const {
            subType,
            form: { values },
        } = this.props;
        let regex = /^[a-zA-Z0-9.@-]*$/;

        if (subType === FIELD_SUBTYPE.REFERENCE && values.idAgreement.maxReferenceLength > 0) {
            const { maxReferenceLength } = values.idAgreement;
            regex = new RegExp(`^[a-zA-Z0-9.@-]{0,${maxReferenceLength}}$`);
        }
        return regex.test(newText);
    };

    render() {
        const {
            editing,
            value,
            data: { emailValidationRegex },
            placeholder,
            subType,
            form: { values },
        } = this.props;
        let tooltipIcon = null;
        let tooltipText = null;

        let validationRegex = new RegExp(emailValidationRegex);

        if (subType === FIELD_SUBTYPE.REFERENCE) {
            const { exampleIcon, exampleText, maxReferenceLength, referenceLength } = values.idAgreement;
            tooltipIcon = exampleIcon;
            tooltipText = exampleText;
            const regexString =
                maxReferenceLength > 0
                    ? `^[a-zA-Z0-9.@-]{${referenceLength},${maxReferenceLength}}$`
                    : "^[a-zA-Z0-9.@-]*$";
            validationRegex = new RegExp(regexString);
        }

        if (editing) {
            return (
                <div className="tagsInput">
                    <TagsInput
                        value={value}
                        onChange={this.handleChange}
                        validationRegex={validationRegex}
                        onValidationReject={this.handleValidationReject}
                        shouldRenderSuggestions={this.shouldRenderSuggestions}
                        renderSuggestion={this.renderSuggestion}
                        getSuggestions={this.getSuggestions}
                        isTextValid={this.isTextValid}
                        placeholder={value && value.length ? "" : placeholder}
                    />
                    {(tooltipIcon || tooltipText) && (
                        <div className="tooltip">
                            {" "}
                            <span className={`tooltip-text ${tooltipIcon ? "reference-tooltip" : ""}`}>
                                {tooltipIcon && <img src={tooltipIcon} alt="referenceAlternativeText" />}
                                {tooltipText}
                            </span>
                        </div>
                    )}
                </div>
            );
        }
        return <span>{value ? value.join(", ") : ""}</span>;
    }
}

export default formField({
    formClass: "form-group--emailList",
})(Component);
