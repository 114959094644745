import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Form, withFormik as WithFormik, Field } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import {
    DEFAULT_COUNTRY,
    DEFAULT_MARITAL_STATUS,
    DEFAULT_OPTION,
    DEFAULT_OPTION_ADDRESS,
    EMPTY_STR,
    IS_COUNTRY_ACTIVE,
    LEVEL,
    NO_SPOUSE,
    TYPE_CI,
    SCOPE,
    ZERO,
    ZERO_NUMBER,
} from "~/constants";
import Container from "~/containers/Internal/Administration/Simple";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsStore, SelectorsAction } from "~/store/settings";
import * as UtilsI18n from "~/util/i18n";
import { getLang as GetLang } from "~/util/i18n";
import { numberToLocaleFormat as NumberToLocaleFormat } from "~/util/number";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";
import Token from "~/components/Token/Token";
import SecuritySealField from "~/pages/_components/fields/SecuritySealField";

import SecuritySealList from "../modal/SecuritySealList";
import FormAddresses from "./FormAddresses";
import FormPersonalData from "./FormPersonalData";
import Style from "./PersonalInformationForm.rules.scss";

const FORM_ID = "change.PersonalData";

export const NAME = "PersonalInformationForm";

export const PROP = {
    types: {
        ...TypesRedux,
        errors: PropTypes.object.isRequired,
        fetching: PropTypes.bool,
        isSubmitting: PropTypes.bool.isRequired,
        securitySeal: PropTypes.shape({
            id: PropTypes.string,
            image: PropTypes.string,
        }).isRequired,
        setFieldValue: PropTypes.func.isRequired,
        values: PropTypes.object.isRequired,
    },
    defaults: {
        ...DefaultsRedux,
        fetching: false,
    },
};
export function Component({
    countryList,
    dispatch,
    environmentType,
    errors,
    fetchingForm,
    fetching,
    isSubmitting,
    personalDataForceUpdate,
    securitySeal,
    setFieldValue,
    values,
    ...rest
}) {
    const [showSeal, setShowSeal] = React.useState(false);

    React.useEffect(() => {
        if (Object.keys(errors).length > 0) {
            dispatch(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get(`forms.fieldsErrors`),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.PERSONAL_INFORMATION],
                }),
            );
        }
    }, [dispatch, errors]);

    React.useLayoutEffect(() => {
        dispatch(SelectorsAction.changePersonalData({ environmentType }));
        return () => {
            dispatch(SelectorsAction.cleanPersonalInformation());
        };
    }, [dispatch, environmentType]);

    const handleBack = React.useCallback(() => {
        dispatch(GoBack());
    }, [dispatch]);

    const handleShowSealSelector = () => {
        setShowSeal(true);
    };

    const handleHideSealSelector = () => {
        setShowSeal(false);
    };

    return (
        <Container
            name={NAME}
            head-onBack={!personalDataForceUpdate && handleBack}
            head-title="change.personal.data.title"
            wait={fetchingForm || fetching}
            scopeToShowNotification={SCOPE.PERSONAL_INFORMATION}>
            <SecuritySealList showSealModal={showSeal} onClick={handleHideSealSelector} />
            <div id={Style.ID}>
                <Form>
                    <div className="form--personal_information form--personal_information--seal">
                        <div className="form--personal_information--title">
                            <I18n id="title.form.seal" />
                        </div>
                        <div className="container--data containerinformation--image">
                            <Field
                                idForm={FORM_ID}
                                name="securitySeal"
                                component={SecuritySealField}
                                imageSrc={securitySeal?.image}
                                onClick={handleShowSealSelector}
                                hidelabel
                            />
                        </div>
                    </div>
                    <div className=" form--personal_information form--personal_information--data">
                        <div className="form--personal_information--title">
                            <I18n id="title.form.information" />
                        </div>
                        <FormPersonalData
                            values={values}
                            isCorporate={false}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            {...rest}
                        />
                    </div>
                    <div className="form--personal_information form--personal_information--addresses">
                        <div className="form--personal_information--title">
                            <I18n id="title.form.addresses" />
                        </div>
                        <FormAddresses values={values} setFieldValue={setFieldValue} errors={errors} {...rest} />
                    </div>

                    <div className=" form--personal_information form--personal_information--bottom token">
                        <div className="form--personal_information--title">
                            <I18n id="title.form.token" />
                        </div>
                        <Token labelButton="global.save" isSubmitting={isSubmitting} {...rest}>
                            <Button
                                type="button"
                                size="sm"
                                variant="secondary"
                                disabled={isSubmitting}
                                className="form--personal_information--bottom--cancel"
                                onClick={handleBack}>
                                <I18n id="global.cancel" />
                            </Button>
                        </Token>
                    </div>
                </Form>
            </div>
        </Container>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Compose(
    Connect((store) => ({
        addresses: SelectorsStore.getAdresses(store),
        changePersonalDataInProgress: SelectorsStore.getChangePersonalDataInProgress(store),
        cityList: SelectorsStore.getCityList(store),
        correspondense: SelectorsStore.getCorrespondense(store),
        countryList: SelectorsStore.getCountryList(store),
        documents: SelectorsStore.getDocuments(store),
        environmentType: SelectorsStoreSession.getActiveEnvironment(store).type || {},
        fetchingForm: SelectorsStore.getFetchingForm(store),
        fetching: SelectorsStore.getFetching(store),
        idUser: SelectorsStoreSession.getUserId(store),
        infoUser: SelectorsStore.getInfoUser(store),
        legal: SelectorsStore.getLegal(store),
        minIncome: SelectorsStoreConfig.getConfig(store)["client.userconfiguration.professions.minIncome"],
        personalData: SelectorsStore.getPersonalData(store),
        personalDataForceUpdate: SelectorsStoreSession.getPersonalDataForceUpdate(store),
        professions: SelectorsStore.getProfessions(store),
        securitySeal: SelectorsStore.getSecuritySeal(store),
        townList: SelectorsStore.getTownList(store),
        user: SelectorsStoreSession.getUser(store),
    })),
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: ({ addresses, personalData }) => {
            return {
                apto: addresses?.apto || EMPTY_STR,
                city: Number(addresses?.idCity) || DEFAULT_OPTION_ADDRESS,
                country: addresses?.idCountry?.toString() || DEFAULT_COUNTRY,
                departament: Number(addresses?.idDepartament) || DEFAULT_OPTION_ADDRESS,
                doorNumber: addresses?.doorNumber || EMPTY_STR,
                maritalStatus: personalData?.idMaritalStatus?.toString() || DEFAULT_MARITAL_STATUS,
                monthlyIncome: parseFloat(personalData?.monthlyIncome) || ZERO_NUMBER,
                observations: addresses?.observations || EMPTY_STR,
                otherCity: (addresses?.idCity === ZERO && addresses?.city) || EMPTY_STR,
                otherDepartament: (addresses?.idDepartament === ZERO && addresses?.departament) || EMPTY_STR,
                otp: EMPTY_STR,
                phone: personalData?.phone || EMPTY_STR,
                profession: personalData?.idProfession?.toString() || DEFAULT_OPTION,
                spouseDocument: {
                    country: personalData?.spouseDocumentCountry || DEFAULT_COUNTRY,
                    document: personalData?.spouseDocument || EMPTY_STR,
                    type: personalData?.spouseDocumentType || TYPE_CI,
                },
                spouseLastname: personalData?.spouseLastname || EMPTY_STR,
                spouseName: personalData?.spouseName || EMPTY_STR,
                street: addresses?.street || EMPTY_STR,
                zipCode: addresses?.zipCode || EMPTY_STR,
            };
        },
        handleSubmit: (data, formikBag) => {
            const {
                changePersonalDataInProgress,
                cityList,
                dispatch,
                documents,
                idUser,
                infoUser: { name },
                professions,
                securitySeal,
                townList,
            } = formikBag.props;

            formikBag.setSubmitting(false);

            if (changePersonalDataInProgress) {
                dispatch(
                    SelectorsActionNotification.showNotification({
                        message: UtilsI18n.get("client.userconfiguration.transactionIsNotAllowed"),
                        level: LEVEL.SUCCESS,
                        scopes: [SCOPE.PERSONAL_INFORMATION],
                    }),
                );

                return;
            }

            const {
                apto,
                city,
                country,
                departament,
                doorNumber,
                files,
                maritalStatus,
                mobilePhone,
                monthlyIncome,
                observations,
                otherCity,
                otherDepartament,
                otherProfession,
                otp,
                phone,
                profession,
                spouseDocument,
                spouseLastname,
                spouseName,
                street,
                zipCode,
            } = data;

            const countryDocumentName = UtilsI18n.get(`country.name.${spouseDocument?.country}`);
            const countryValue = country?.toString().length === 0 ? ZERO : country?.toString();
            const cityValue = departament?.toString().length === 0 ? ZERO : departament?.toString();
            const towsValue = city?.toString().length === 0 ? ZERO : city?.toString();
            let infoUserModify = {
                addressObservations: observations,
                addressOtherCity: otherDepartament,
                addressOtherTown: otherCity,
                apto,
                city: cityValue,
                cityName: cityList?.find((c) => c?.id.toString() === departament.toString())?.name || otherDepartament,
                country: countryValue,
                doorNumber,
                fullName: name,
                idSeal: securitySeal?.id,
                idUserToModify: idUser,
                incomeAttachments: files?.idFile,
                incomeAttachmentsNames: files?.fileName,
                maritalStatus,
                mobilePhone: `${mobilePhone?.prefix}-${mobilePhone?.mobilePhone}`,
                monthlyIncome: monthlyIncome.toString(),
                otherProfession,
                phone,
                prefix: mobilePhone?.prefix,
                professionDescription:
                    profession !== ZERO
                        ? professions.find((prof) => prof.id === Number.parseInt(profession, 10))?.label
                        : otherProfession,
                profession: profession?.toString(),
                street,
                townName: townList?.find((c) => c?.id.toString() === city.toString())?.name || otherCity,
                town: towsValue,
                zipCode,
            };
            if (!NO_SPOUSE.includes(maritalStatus)) {
                const spouseDocType = documents
                    // eslint-disable-next-line camelcase
                    .filter((doc) => doc?.id_country_code === spouseDocument?.country)
                    // eslint-disable-next-line camelcase
                    ?.find((doc) => doc?.id_document_type === spouseDocument?.type)?.id_document_label;
                const spouseFullName = `${data?.spouseName} ${data?.spouseLastname}`;
                const spouseDocumenFull = `${spouseDocType} (${countryDocumentName}) ${spouseDocument?.document}`;
                infoUserModify = {
                    ...infoUserModify,
                    spouseDocCountry: spouseDocument?.country || ZERO,
                    spouseDocNumber: spouseDocument?.document || EMPTY_STR,
                    spouseDocType: spouseDocument?.type || ZERO,
                    spouseDocumenFull,
                    spouseFullName,
                    spouseLastname,
                    spouseName,
                };
            }

            dispatch(SelectorsAction.modifyUserRequest({ infoUserModify, _otp: otp, formikBag }));
        },
        validationSchema: (props) => {
            const { documents, user } = props;

            return Yup.lazy((values) => {
                return Yup.object().shape({
                    city:
                        IS_COUNTRY_ACTIVE.includes(values?.country) && values?.city !== 0
                            ? Yup.string().trim().required(UtilsI18n.get("client.userconfiguration.emptyTown"))
                            : Yup.string().notRequired(),
                    country: Yup.string().trim().required(UtilsI18n.get("client.userconfiguration.emptyCountry")),
                    departament:
                        IS_COUNTRY_ACTIVE.includes(values?.country) && values?.departament !== 0
                            ? Yup.string().trim().required(UtilsI18n.get("client.userconfiguration.emptyCity"))
                            : Yup.string().notRequired(),
                    doorNumber: Yup.string().trim().required(UtilsI18n.get("client.userconfiguration.emptyNumber")),
                    maritalStatus: Yup.string()
                        .trim()
                        .required(UtilsI18n.get("client.userconfiguration.emptyMaritalStatus")),
                    otherCity:
                        !IS_COUNTRY_ACTIVE.includes(values.country) || values.city === 0
                            ? Yup.string().trim().required(UtilsI18n.get("client.userconfiguration.emptyOtherTown"))
                            : Yup.string().notRequired(),
                    otherDepartament:
                        !IS_COUNTRY_ACTIVE.includes(values.country) || values?.departament === 0
                            ? Yup.string().trim().required(UtilsI18n.get("client.userconfiguration.emptyOtherCity"))
                            : Yup.string().notRequired(),
                    otherProfession:
                        values.profession === ZERO
                            ? Yup.string().trim().required(UtilsI18n.get("client.userconfiguration.emptyProfession"))
                            : Yup.string().notRequired(),
                    otp: user.needsBiometric
                        ? Yup.string().nullable()
                        : Yup.string()
                              .trim()
                              .required(UtilsI18n.get("form.credential.otp.required"))
                              .min(6, UtilsI18n.get("returnCode.COR027W")),
                    phone: Yup.string().trim().required(UtilsI18n.get("client.userconfiguration.emptyPhone")),
                    profession: Yup.string().trim().required(UtilsI18n.get("client.userconfiguration.emptyProfession")),
                    monthlyIncome:
                        typeof values.monthlyIncome === "string"
                            ? Yup.string().test({
                                  name: "income validation",
                                  test: (vals) => {
                                      const formatted = parseFloat(NumberToLocaleFormat(vals, GetLang()));
                                      return formatted >= 0;
                                  },
                                  message: UtilsI18n.get("client.userconfiguration.invalidIncome"),
                                  params: null,
                                  exclusive: true,
                              })
                            : Yup.number()
                                  .required(UtilsI18n.get("client.userconfiguration.emptyIncome"))
                                  .min(0, UtilsI18n.get("client.userconfiguration.invalidIncome")),
                    spouseDocument: !NO_SPOUSE.includes(values.maritalStatus)
                        ? Yup.object()
                              .shape({
                                  country: Yup.string().required(
                                      UtilsI18n.get("client.userconfiguration.emptyDocCountry"),
                                  ),
                                  document: Yup.string().required(
                                      UtilsI18n.get("client.userconfiguration.emptyDocNumber"),
                                  ),
                                  type: Yup.string().required(UtilsI18n.get("client.userconfiguration.emptyDocType")),
                              })
                              .test({
                                  name: "spouse_document",
                                  test: (vals) => {
                                      const { country, type } = vals;

                                      return documents
                                          .filter((document) => document.id_country_code === country)
                                          .some((document) => document.id_document_type === type);
                                  },
                                  message: UtilsI18n.get("client.userconfiguration.emptyDocType"),
                                  params: null,
                                  exclusive: true,
                              })
                        : Yup.object().notRequired(),
                    spouseLastname: !NO_SPOUSE.includes(values.maritalStatus)
                        ? Yup.string().trim().required(UtilsI18n.get("client.userconfiguration.emptyPartnerLastName"))
                        : Yup.string().notRequired(),
                    spouseName: !NO_SPOUSE.includes(values.maritalStatus)
                        ? Yup.string().trim().required(UtilsI18n.get("client.userconfiguration.emptyPartnerFirstName"))
                        : Yup.string().notRequired(),
                    street: Yup.string().trim().required(UtilsI18n.get("client.userconfiguration.emptyStreet")),
                });
            });
        },
    }),
)(Component);
