import React from "react";

import { push as Push } from "connected-react-router";
import * as StoreRouter from "connected-react-router";
import { withFormik as WithFormik } from "formik";
import QueryString from "query-string";
import { connect as Connect } from "react-redux";
import * as Yup from "yup";

import { EMPTY_STR, EVENT_BACKBUTTON, SCOPE } from "~/constants";
import Container from "~/containers/External/Dash";
import Store from "~/store";
import { SelectorsAction, SelectorsStore } from "~/store/enrollment";
import { SelectorsAction as SelectorsActionSession } from "~/store/session";
import UtilsDevice from "~/util/device";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesHtmlElement, Defaults as DefaultsHtmlElement } from "~/util/prop/html-element";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import WithExchangeToken from "~/pages/_components/withExchangeToken";

import CancelConfirmationModal from "~/pages/enrollment/_CancelConfirmationModal";

import InvitationCodeForm from "./_InvitationCodeForm";

const FORM_ID = "enrollment.index.invitationCode";

export const NAME = "InvitationCode";

export const PROP = {
    types: {
        ...TypesHtmlElement,
        ...TypesRedux,
    },
    defaults: {
        ...DefaultsHtmlElement,
        ...DefaultsRedux,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        show: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;

        if (UtilsDevice.isMobileNative()) {
            dispatch(SelectorsActionSession.acceptMobileRequiredPermissions());
        }

        document.addEventListener(EVENT_BACKBUTTON, this.showModal, true);
    }

    componentWillUnmount() {
        document.removeEventListener(EVENT_BACKBUTTON, this.showModal, true);
    }

    handleConfirmation = () => {
        const { dispatch } = this.props;
        dispatch(Push("/"));
    };

    handleCancelation = () => {
        this.setState({ show: false });
    };

    showModal = () => {
        this.setState({ show: true });
    };

    render() {
        const { show } = this.state;
        const { setFieldValue, dispatch, fetching } = this.props;
        const legend = UtilsDevice.isMobileNative() ? "legend" : "legend.web";
        return (
            <React.Fragment>
                <CancelConfirmationModal
                    show={show}
                    handleCancelation={this.handleCancelation}
                    handleConfirmation={this.handleConfirmation}
                />
                <Container
                    name={NAME}
                    head-onClose={this.showModal}
                    wait={fetching}
                    scopeToShowNotification={SCOPE.INVITATION_CODE}>
                    <div className="login login-step step-4">
                        <div className="login-header">
                            <div className="login-header-image">
                                <Image src="enrollment.svg" className="login-header-icon" />
                            </div>
                            <span className="login-header-text">
                                <I18n id={`${FORM_ID}.title`} />
                            </span>
                        </div>
                        <div className="login-content">
                            <div className="login-row simple-row">
                                <span className="login-message">
                                    <I18n id={`${FORM_ID}.${legend}`} />
                                </span>
                            </div>
                            <div className="login-row main-row">
                                <InvitationCodeForm
                                    formId={FORM_ID}
                                    setFieldValue={setFieldValue}
                                    dispatch={dispatch}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="enrollment-content-dialog">
                        <span className="enrollment-content-dialog-text" />
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    fetching: SelectorsStore.fetching(store),
    invitationCode: SelectorsStore.invitationCode(store),
});

export default HighOrder(
    Connect(mapStateToProps),
    WithExchangeToken,
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => {
            const store = Store.getState();
            const location = StoreRouter.getLocation(store);
            const { query } = QueryString.parseUrl(location.search);
            return {
                invitationCode: query.data || EMPTY_STR,
            };
        },
        validationSchema: () =>
            Yup.object().shape({
                invitationCode: Yup.string()
                    .trim()
                    .required(UtilsI18n.get(`${FORM_ID}.empty`)),
            }),
        handleSubmit: (data, formikBag) => {
            const { dispatch } = formikBag.props;
            const { invitationCode } = data;
            dispatch(SelectorsAction.verifyInvitationCode({ invitationCode, formikBag }));
        },
    }),
)(Component);
