import React from "react";

import PropTypes from "prop-types";
import { compose } from "redux";

import { EMPTY_STR, FIELD_SUBTYPE, ID_FIELD, ID_FORM, MIDDLE_DASH, REGEX_SPACE_STR } from "~/constants";
import { MODE } from "~/constants/form";
import * as i18n from "~/util/i18n";
import UtilsLodash from "~/util/lodash";
import Logger from "~/util/logger";

import withFocus from "~/pages/_components/withFocus";

import formField from "~/pages/forms/_components/_fields/_commons/formField";

export const NAME = "Text";

export const PROP = {
    types: {
        editing: PropTypes.bool,
        maxLength: PropTypes.number,
        minLength: PropTypes.number,
        name: PropTypes.string,
        placeholder: PropTypes.string,
        setValue: PropTypes.func.isRequired,
        toggleIsFocused: PropTypes.func.isRequired,
        typeInput: PropTypes.string,
        validationRegularExpresion: PropTypes.string,
        value: PropTypes.string,
    },
    defaults: {
        editing: false,
        maxLength: 0,
        minLength: 0,
        name: EMPTY_STR,
        placeholder: EMPTY_STR,
        typeInput: "text",
        validationRegularExpresion: EMPTY_STR,
        value: EMPTY_STR,
    },
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        dependeeFields: null,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const { dependeeFields, dependencies, editing, prevMode, setValue, subType } = nextProps;
        const { dependeeFields: dependeeFieldsState } = prevState;

        const dependeeValue = dependencies && dependeeFields && dependeeFields[dependencies[0]];
        const dependeeValueState = dependencies && dependeeFieldsState && dependeeFieldsState[dependencies[0]];

        const changeValue =
            (!dependeeValueState && dependeeValue) ||
            (dependeeValueState &&
                dependeeValue &&
                dependeeValue.value &&
                (typeof dependeeValue.value === "object"
                    ? !UtilsLodash.isEqual(dependeeValueState.value, dependeeValue.value)
                    : dependeeValueState.value !== dependeeValue.value));

        if (editing && changeValue) {
            const subTypeAsArray = subType.split(MIDDLE_DASH);

            switch (subTypeAsArray[0]) {
                case FIELD_SUBTYPE.ALIAS_DEPENDANT:
                    if (subTypeAsArray.length > 1 && prevMode !== MODE.PREVIEW) {
                        setValue(dependeeValue.value[subTypeAsArray[1]] || EMPTY_STR);
                    }

                    break;
                default:
                    break;
            }

            return {
                dependeeFields,
            };
        }

        return null;
    }

    handleChange = (params) => {
        const { idValidation, maxLength, setValue } = this.props;
        const { target } = params;
        let { value: val } = target;

        if (idValidation === "onlyNumbers") {
            val = this.sanitizeString(val);

            target.value = val;
        }

        if (val.length <= maxLength) {
            setValue(val);
        }

        this.customMinLengthValidation({ target });
    };

    sanitizeString = (value) => {
        return value.replace(REGEX_SPACE_STR, EMPTY_STR);
    };

    customMinLengthValidation = ({ target }) => {
        const { minLength } = this.props;

        if (minLength) {
            const { value } = target;

            if (value && value.length < minLength) {
                target.setCustomValidity(
                    i18n.get("generic.text.field.minLength.warning.message", null, {
                        MINLENGTH: minLength,
                        CURRENTLENGTH: value.length,
                    }),
                );
            } else {
                target.setCustomValidity(EMPTY_STR);
            }
        }
    };

    render() {
        const {
            editing,
            idField,
            idForm,
            maxLength,
            minLength,
            name,
            placeholder,
            toggleIsFocused,
            typeInput,
            validationRegularExpresion,
            value,
        } = this.props;

        if (editing) {
            return (
                <div className="input-group">
                    <input
                        className="form-control"
                        maxLength={maxLength}
                        minLength={minLength}
                        name={name}
                        onBlur={toggleIsFocused}
                        onChange={this.handleChange}
                        onFocus={toggleIsFocused}
                        onInvalid={this.customMinLengthValidation}
                        pattern={validationRegularExpresion}
                        placeholder={placeholder}
                        type={typeInput}
                        value={value || EMPTY_STR}
                    />
                </div>
            );
        }

        if (
            (idForm === ID_FORM.TRANSFER_FOREIGN_FORM && idField === ID_FIELD.CREDIT_BANK_LABEL) ||
            (idForm === ID_FORM.TRANSFER_FOREIGN_FORM && idField === ID_FIELD.INTERMEDIARY_BANK_LABEL) ||
            (idForm === ID_FORM.CREDIT_CARD_PURCHASE_NOTIFICATION && idField === ID_FIELD.DEACTIVATE)
        ) {
            return (
                <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: value,
                    }}
                />
            );
        }

        return <span>{value}</span>;
    }
}

export default compose(
    withFocus,
    formField({
        isValidValue: (value, props) => {
            const { validationRegularExpresion } = props;

            if (validationRegularExpresion) {
                try {
                    const regex = new RegExp(validationRegularExpresion);

                    return regex.test(value);
                } catch (err) {
                    Logger.error(err);

                    return true;
                }
            }
            return true;
        },
    }),
)(Component);
