import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, YES_ES } from "~/constants";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import { SelectorsStore as SelectorsStoreComexExport } from "~/store/comex/export";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import GridLayout from "~/components/GridLayout";
import I18n from "~/components/I18n";
import Ticket from "~/components/Ticket/Ticket";
import FormattedAmount from "~/pages/_components/FormattedAmount";

import { COLUMN_HEADINGS, FORM_STEP_2 } from "../FormConstants";
import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const { dispatch, transaction, ticketConfirmation } = props;
    const { data } = transaction;
    const {
        operationNumber,
        courierAcountNumber,
        courierName,
        oldAmount,
        originalCreditLetterNumber,
        documents,
        invoiceNumber,
        invoiceAmountCurrency,
        utilizationAmount,
        utilizationAmountCurrency,
        creditLetter,
        discrepancies,
    } = data;

    const showDiscrepancies = discrepancies === YES_ES;

    function handleReject(formikBag) {
        const { idTransaction } = transaction;
        dispatch(
            SelectorsActionComex.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleModify() {
        const { idTransaction } = transaction;
        dispatch(SelectorsActionForm.modifyTransaction({ idTransactionToMove: idTransaction }));
    }
    function handleSign() {
        dispatch(SelectorsActionComex.signTransactionPreview({ data }));
    }
    const shareTicket = (idTransactionToRead) => {
        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    };

    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}
                    handleModify={handleModify}
                    hasMobileComponent
                    displayDownloadPDF
                    handleDownloadPDF={() => shareTicket(transaction.idTransaction)}>
                    <Box flex directionColumn align="flex-start" className="mb-3">
                        <div className="opening">
                            <I18n id={`${FORM_STEP_2}.opening`} tag="p" className="mt-3" />
                        </div>
                        {creditLetter && (
                            <div className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${FORM_STEP_2}.operationNumber`)}</h4>
                                <span>{operationNumber}</span>
                            </div>
                        )}
                        <div className="info-label">
                            <h4 className="bold">{UtilsI18n.get(`${FORM_STEP_2}.original.letter`)}</h4>
                            <span>{originalCreditLetterNumber}</span>
                        </div>
                        <div className="info-label">
                            <h4 className="bold">{UtilsI18n.get(`${FORM_STEP_2}.amount`)}</h4>
                            <FormattedAmount currency={invoiceAmountCurrency} quantity={parseFloat(oldAmount)} />
                        </div>
                        <div className="info-label">
                            <h4 className="bold">{UtilsI18n.get(`${FORM_STEP_2}.invoiceNumber`)}</h4>
                            <span>{invoiceNumber}</span>
                        </div>
                        <div className="info-label">
                            <h4 className="bold">{UtilsI18n.get(`${FORM_STEP_2}.utilization`)}</h4>
                            <FormattedAmount
                                currency={utilizationAmountCurrency}
                                quantity={parseFloat(utilizationAmount)}
                            />
                        </div>

                        <div className="opening">
                            <I18n id={`${FORM_STEP_2}.documents`} tag="p" className="mt-3" />
                        </div>
                        <GridLayout className="header ticket-table-section">
                            {COLUMN_HEADINGS.map((heading) => {
                                return <div>{UtilsI18n.get(`${FORM_STEP_2}.columnheaders.${heading}`)}</div>;
                            })}
                        </GridLayout>
                        {documents &&
                            documents.map((document) => (
                                <GridLayout className="item ticket-table-section">
                                    <div>
                                        {UtilsI18n.get(`comex.creditLetter.modal.documentTypes.${document.type}`)}
                                    </div>
                                    <div>{document.description}</div>
                                    <div>{document.originals}</div>
                                    <div>{document.copies}</div>
                                </GridLayout>
                            ))}

                        {!creditLetter && (
                            <div className="mt-5">
                                <I18n id={`${FORM_STEP_2}.original.present`} tag="p" />
                            </div>
                        )}
                        {courierName && (
                            <div className={creditLetter ? "mt-5" : " mt-1"}>
                                <div>
                                    {UtilsI18n.get(`${FORM_STEP_2}.courierName`)}
                                    {courierName}
                                    {UtilsI18n.get(`${FORM_STEP_2}.courierAcountNumber`)}
                                    {courierAcountNumber}
                                </div>
                            </div>
                        )}
                        {showDiscrepancies && (
                            <div className={`${!courierName ? "mt-5" : " mt-1"} opening`}>
                                <I18n id={`${FORM_STEP_2}.discrepancies`} tag="p" className="mt-1" />
                            </div>
                        )}
                    </Box>
                </Ticket>
            )}
        </section>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreComexExport.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
