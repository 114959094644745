/* eslint-disable no-console */
import React from "react";

import { push as Push } from "connected-react-router";
import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants/index";
import { STATUS } from "~/constants/transaction";
import RouteTicket from "~/store/form/_sagas/_routeTicket";
import * as i18nUtils from "~/util/i18n";

import GridLayout from "~/components/GridLayout";

import Style from "./TransactionItem.rules.scss";

import { ReactComponent as ProcessingIcon } from "~/assets/alertIconCron.svg";
import { ReactComponent as DefaultIcon } from "~/assets/alertIconMedium.svg";
import { ReactComponent as ScheduleIcon } from "~/assets/schedulet.svg";

export const { NAME_MESSAGES, NAME_NOTE, TYPE_NOTE } = Style;
export const NAME = "TransactionItem";

export const PROP = {
    types: {
        transaction: PropTypes.arrayOf(
            PropTypes.shape({
                transaction: PropTypes.shape({
                    idTransaction: PropTypes.string.isRequired,
                    submitDateTime: PropTypes.string.isRequired,
                    idTransactionStatus: PropTypes.string.isRequired,
                    activityName: PropTypes.string.isRequired,
                    idActivity: PropTypes.string.isRequired,
                    amountDescription: PropTypes.string,
                    settlementDate: PropTypes.string,
                }).isRequired,
                cancelEnabled: PropTypes.bool.isRequired,
                editableInMobile: PropTypes.bool.isRequired,
            }),
        ).isRequired,
    },
    defaults: { transaction: [] },
};

const HEADERS = [
    "investment.myBriefcase.transactions.header.one",
    "investment.myBriefcase.transactions.header.two",
    "investment.myBriefcase.transactions.header.three",
    "investment.myBriefcase.transactions.header.four",
    "investment.myBriefcase.transactions.header.five",
];
const MAX_ROWS = 5;

export class Component extends React.Component {
    getImage = (transactionStatus) => {
        if (transactionStatus === STATUS.SCHEDULED) {
            return <ScheduleIcon />;
        }
        if (transactionStatus === STATUS.PROCESSING || transactionStatus === STATUS.ACCEPTED) {
            return <ProcessingIcon />;
        }
        return <DefaultIcon />;
    };

    redirectToTicket = (transaction) => {
        const { dispatch } = this.props;
        const { idActivity, idTransaction } = transaction;
        const urlToTicket = RouteTicket[idActivity](idTransaction);
        dispatch(Push(urlToTicket));
    };

    render() {
        const { transactions } = this.props;
        const items = [...transactions];

        return (
            <section>
                <div className="transactionItem">
                    <div className={items.length > MAX_ROWS ? "header-smaller" : "header"}>
                        <GridLayout>
                            {HEADERS.map((heading) => (
                                <div>{i18nUtils.get(heading)}</div>
                            ))}
                        </GridLayout>
                    </div>
                    <div className={items.length > MAX_ROWS ? "table-body scrollable" : "table-body"}>
                        {items.map(({ transaction }) => (
                            <GridLayout
                                className="body position-clickable"
                                onClick={() => {
                                    this.redirectToTicket(transaction);
                                }}>
                                <div>{transaction.submitDateTime}</div>
                                <div>{i18nUtils.get(`activities.${transaction.idActivity}`)}</div>
                                <div>{transaction.settlementDate ? transaction.settlementDate : EMPTY_STR}</div>
                                <div>{transaction.amountDescription ? transaction.amountDescription : EMPTY_STR}</div>
                                <div className="transactionItemStatus transaction-status-display">
                                    <span>
                                        {i18nUtils.get(
                                            `wm.myBriefCase.transactionStatus.${transaction.idTransactionStatus}`,
                                        )}
                                    </span>
                                    {this.getImage(transaction.idTransactionStatus)}
                                </div>
                            </GridLayout>
                        ))}
                    </div>
                    <footer>{i18nUtils.get("client.wm.transactions.table.disclaimer")}</footer>
                </div>
            </section>
        );
    }
}
export default Component;
