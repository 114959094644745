import React from "react";

import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/Simple";
import { SelectorsStore as SelectorsStoreCommunication } from "~/store/communications";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Style from "~/pages/communications/CommunicationMain.rules.scss";
import Compose from "~/pages/communications/_components/Compose";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...TypesRedux,
    },
    defaults: {
        ...DefaultsRedux,
    },
};

export function Component(props) {
    const handleBack = () => {
        const { history } = props;
        history.goBack();
    };

    const { isFetching } = props;
    return (
        <Container
            name={NAME}
            className={Style.CLASS}
            head-title="communications.compose.message.new"
            head-onClose={handleBack}
            head-onBackWeb={handleBack}
            wait={isFetching}
            scopeToShowNotification={SCOPE.COMMUNICATIONS_SEND}>
            <Compose />
        </Container>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapsStateToProps = (store) => ({
    isFetching: SelectorsStoreCommunication.isFetching(store),
});

export default Connect(mapsStateToProps)(Component);
