import React from "react";

import PropTypes from "prop-types";
import { ProgressBar } from "react-bootstrap";
import { connect as Connect } from "react-redux";

import { LANGUAGE, PERCENT, QR_MODO_COUPON_ENABLED, QR_MODO_TYPE_DISCOUNT } from "~/constants";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import * as UtilsConfig from "~/util/config";
import * as UtilsI18n from "~/util/i18n";
import * as UtilsNumber from "~/util/number";

import ButtonFilter from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";

import Style from "./Coupon.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        buttonEnabled: PropTypes.bool,
        data: PropTypes.object,
    },
    defaults: {
        buttonEnabled: false,
        data: null,
    },
};

export function Component(props) {
    const { buttonEnabled, data, isMobile, lang } = props;

    const unlockedCoupon = data.qrCurrentInstallment === QR_MODO_COUPON_ENABLED;

    const quantityCoupons = unlockedCoupon ? null : data.qrCurrentInstallment[0];
    const maxCoupons = unlockedCoupon ? null : data.qrCurrentInstallment[2];
    const percentageBar = unlockedCoupon ? null : (quantityCoupons / maxCoupons) * 100;

    const { decimalSeparator } = UtilsNumber.numberFormat(lang);
    const formattedValueByLang = UtilsNumber.toNumberFormat(data.qrLimitAmount, decimalSeparator);

    const principalTextCondition = () => {
        if (unlockedCoupon) {
            if (isMobile) {
                return "servicePayment.qrModo.coupon.unlockedCoupon";
            }

            return "servicePayment.qrModo.coupon.useApp";
        }

        return "servicePayment.qrModo.coupon.stepperText";
    };

    const parseSymbol = (symbol) => {
        if (symbol !== PERCENT) {
            return ` ${UtilsI18n.get(`core.currency.label.${symbol}`)} `;
        }

        return ` ${symbol} `;
    };

    const getLang = () => {
        if (lang === LANGUAGE.EN) {
            return data.qrName.en;
        }

        if (lang === LANGUAGE.PT) {
            return data.qrName.pt;
        }

        return data.qrName.es;
    };

    return (
        <div name={Style.NAME} className={Style.CLASS}>
            <div className="wrapper">
                <div className="coupon-container">
                    <div className="coupon">
                        <div className="component">
                            <React.Fragment>
                                <div className="coupon-principal-text">
                                    <I18n id={principalTextCondition()} />
                                </div>
                                {buttonEnabled && isMobile && (
                                    <div className="button-container">
                                        <Link to="/servicePayment/qrModo/otpRequest">
                                            <ButtonFilter
                                                className="btn btn-primary btn-filter btn-filled"
                                                onClick={() => {}}>
                                                {UtilsI18n.get("servicePayment.qrModo.coupon.buttonText")}
                                            </ButtonFilter>
                                        </Link>
                                    </div>
                                )}
                                {unlockedCoupon ? (
                                    <Image className="coupon-icon" src="gift-icon.svg" />
                                ) : (
                                    <div
                                        className={
                                            buttonEnabled
                                                ? "progress-bar-container"
                                                : "progress-bar-container bar-center"
                                        }>
                                        {data.qrTypeDiscount === QR_MODO_TYPE_DISCOUNT.COUPON && (
                                            <ProgressBar className="coupon-progress-bar" now={percentageBar} />
                                        )}
                                        <div className="coupon-count">
                                            {quantityCoupons}/{maxCoupons}
                                        </div>
                                    </div>
                                )}
                            </React.Fragment>
                            <div className="coupon-bottom">
                                <a href={UtilsConfig.get("client.qrModo.generalConditions.url")}>
                                    {UtilsI18n.get("servicePayment.qrModo.coupon.terms")}
                                </a>
                            </div>
                        </div>

                        <div className="separator" />

                        <div className="component">
                            <div className="coupon-principal-text">{getLang()}</div>
                            <div className="coupon-value">
                                {data.qrLimitCurrency === PERCENT ? (
                                    <React.Fragment>
                                        <span className="number">{formattedValueByLang}</span>
                                        <span className="symbol">{parseSymbol(data.qrLimitCurrency)}</span>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <span className="symbol">{parseSymbol(data.qrLimitCurrency)}</span>
                                        <span className="number">{formattedValueByLang}</span>
                                    </React.Fragment>
                                )}
                            </div>
                            <div className="coupon-secondary-text">
                                <I18n id="servicePayment.qrModo.coupon.valid" /> {data.qrDueDate}
                            </div>
                            {data.qrTypeDiscount !== QR_MODO_TYPE_DISCOUNT.AMOUNT && (
                                <div className="coupon-secondary-text">
                                    <I18n id="servicePayment.qrModo.coupon.maxDiscountLimit" />
                                    {parseSymbol(data.qrMaxLimitCurrency)}
                                    {data.qrMaxLimitAmount}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store, props) => {
    const { lang } = props;

    return {
        lang: lang || StoreI18n.getLang(store) || window.navigator.language.slice(0, 2),
    };
};

export default Connect(mapStateToProps)(HighOrder.Resizable(Component));
