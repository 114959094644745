import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { CAMPAIGN_SECTION_HEADER } from "~/constants";
import { SelectorsStore as StoreCampaigns, SelectorsAction as SelectorsActionCampaigns } from "~/store/campaigns";
import { SelectorsStore as StoreSession } from "~/store/session";
import Logger from "~/util/logger";
import { Categorize } from "~/util/prop";

import Page from "../_Page";
import Campaigns from "./_Campaigns";
import Style from "./index.rules.scss";

import Internal, { PROP as PROP_INTERNAL, WARN_HEAD } from "..";

export const { NAME, TYPE } = Style;
export const PROP = {
    types: {
        ...PROP_INTERNAL.types,
        campaigns: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string.isRequired,
                data: PropTypes.string.isRequired,
                url: PropTypes.string,
            }).isRequired,
        ),
        isFetching: PropTypes.bool,
        /* The current environment's type (from store) */
        type: PropTypes.string.isRequired,
    },
    defaults: {
        ...PROP_INTERNAL.defaults,
        campaigns: [],
        isFetching: false,
    },
};

/** This is  a description. */
export function Component(props) {
    const {
        dispatch,
        isFetching,
        name,
        type,
        children,
        head,
        wait,
        campaigns,
        scopeToShowNotification,
        ...rest
    } = Categorize(props);
    if (head) {
        Logger.warn(WARN_HEAD, head);
    }

    React.useEffect(() => {
        if (!campaigns.length && !isFetching) {
            dispatch(SelectorsActionCampaigns.loadCampaign({ section: CAMPAIGN_SECTION_HEADER }));
        }
    }, [campaigns, dispatch, isFetching]);

    return (
        <Internal
            id={Style.ID}
            className={type}
            name={NAME}
            wait={wait}
            scopeToShowNotification={scopeToShowNotification}>
            <Page {...rest} name={name}>
                {campaigns && <Campaigns section={CAMPAIGN_SECTION_HEADER} />}
                {children}
            </Page>
        </Internal>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    campaigns: StoreCampaigns.getCampaigns(store),
    isFetching: StoreCampaigns.getFetching(store),
    type: StoreSession.getActiveEnvironment(store).type,
});

export default Connect(mapStateToProps)(Component);
