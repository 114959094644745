import { LOCATION_CHANGE } from "connected-react-router";

import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { TYPE as TypesBackoffice } from "~/store/backoffice";

import { TYPE as TYPE_COMEX } from "../_consts";
import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            return {
                ...PROP.defaults,
                transferCreditLetter: {
                    ...PROP.defaults.transferCreditLetter,
                },
                comexForm: {
                    ...PROP.defaults.comexForm,
                },
            };
        }
        case TYPE.RESTART_FLOW:
            return PROP.defaults;
        case TYPE.PRESENT_BILLING_DOCUMENTS_PRE_REQUEST:
        case TYPE.PRESENT_BILLING_DOCUMENTS_SEND_REQUEST:
        case TYPE.PRESENTATION_CREDIT_LETTER_PRE_REQUEST:
        case TYPE.PRESENTATION_CREDIT_LETTER_SEND_FORM:
        case TYPE.PURCHASE_DOCUMENTS_PRE_REQUEST:
        case TYPE.PURCHASE_DOCUMENTS_SEND_FORM:
        case TYPE.TRANSFER_CREDIT_LETTER_PRE_REQUEST:
        case TYPE.TRANSFER_CREDIT_LETTER_SEND_FORM:
        case TYPE.BILLING_CLAIM_SEND_REQUEST:
        case TYPE.BILLING_CLAIM_PRE_REQUEST:
        case TYPE.CESSION_CREDIT_LETTER_PRE_REQUEST:
        case TYPE.CESSION_CREDIT_LETTER_SEND_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.PRESENTATION_CREDIT_LETTER_PRE_SUCCESS:
        case TYPE.PURCHASE_DOCUMENTS_PRE_SUCCESS:
        case TYPE.TRANSFER_CREDIT_LETTER_PRE_SUCCESS:
        case TYPE.CESSION_CREDIT_LETTER_PRE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                comexForm: {
                    ...storeProp.comexForm,
                    preData: action,
                },
            };
        case TYPE.PRESENTATION_CREDIT_LETTER_PREVIEW_REQUEST:
        case TYPE.PURCHASE_DOCUMENTS_PREVIEW_REQUEST:
        case TYPE.TRANSFER_CREDIT_LETTER_PREVIEW_REQUEST:
        case TYPE.CESSION_CREDIT_LETTER_PREVIEW_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                comexForm: {
                    ...storeProp.comexForm,
                    prevData: action.formData,
                },
            };
        case TYPE.PRESENTATION_CREDIT_LETTER_PREVIEW_SUCCESS:
        case TYPE.PURCHASE_DOCUMENTS_PREVIEW_SUCCESS:
        case TYPE.TRANSFER_CREDIT_LETTER_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                mode: MODE.PREVIEW,
                fetching: false,
                prevMode: storeProp.mode,
                comexForm: {
                    ...storeProp.comexForm,
                    formData: storeProp.comexForm.prevData,
                    formTicket: action.data,
                },
            };
        case TYPE.PRESENTATION_CREDIT_LETTER_SEND_FORM_SUCCESS:
        case TYPE.PURCHASE_DOCUMENTS_SEND_FORM_SUCCESS:
        case TYPE.TRANSFER_CREDIT_LETTER_SEND_FORM_SUCCESS:
        case TYPE.CESSION_CREDIT_LETTER_SEND_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                prevMode: storeProp.mode,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };
        case TYPE.PRESENTATION_CREDIT_LETTER_PREVIEW_FAILURE:
        case TYPE.PRESENTATION_CREDIT_LETTER_PRE_FAILURE:
        case TYPE.PRESENTATION_CREDIT_LETTER_SEND_FORM_FAILURE:
        case TYPE.PRESENT_BILLING_DOCUMENTS_PRE_FAILURE:
        case TYPE.PRESENT_BILLING_DOCUMENTS_PREVIEW_FAILURE:
        case TYPE.PRESENT_BILLING_DOCUMENTS_SEND_FAILURE:
        case TYPE.PURCHASE_DOCUMENTS_PRE_FAILURE:
        case TYPE.PURCHASE_DOCUMENTS_PREVIEW_FAILURE:
        case TYPE.PURCHASE_DOCUMENTS_SEND_FORM_FAILURE:
        case TYPE.TRANSFER_CREDIT_LETTER_PRE_FAILURE:
        case TYPE.TRANSFER_CREDIT_LETTER_PREVIEW_FAILURE:
        case TYPE.TRANSFER_CREDIT_LETTER_SEND_FORM_FAILURE:
        case TYPE.BILLING_CLAIM_PREVIEW_FAILURE:
        case TYPE.BILLING_CLAIM_PRE_FAILURE:
        case TYPE.BILLING_CLAIM_SEND_FAILURE:
        case TYPE.CESSION_CREDIT_LETTER_PRE_FAILURE:
        case TYPE.CESSION_CREDIT_LETTER_PREVIEW_FAILURE:
        case TYPE.CESSION_CREDIT_LETTER_SEND_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE_COMEX.LOAD_BANK_LIST_REQUEST:
        case TYPE_COMEX.READ_TRANSACTION_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE_COMEX.LOAD_BANK_LIST_FAILURE:
        case TYPE_COMEX.LOAD_BANK_LIST_SUCCESS:
        case TYPE_COMEX.READ_TRANSACTION_FAILURE:
        case TYPE_COMEX.SIGN_TRANSACTION_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE_COMEX.CLOSE_TRANSACTION_PREVIEW:
            return {
                ...storeProp,
                mode: storeProp.prevMode,
            };
        case TYPE_COMEX.COMEX_FORM_TO_STEP_1:
            return {
                ...storeProp,
                mode: MODE.EDIT,
            };
        case TYPE_COMEX.COMEX_FORM_TO_STEP_3:
            return {
                ...storeProp,
                mode: MODE.VIEW,
            };
        case TYPE_COMEX.READ_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                transaction: action.transaction,
                mode: action.transaction.idTransactionStatus === STATUS.DRAFT ? MODE.EDIT : MODE.VIEW,
            };

        case TYPE_COMEX.SIGN_TRANSACTION_PREVIEW:
            return {
                ...storeProp,
                prevMode: storeProp.mode,
                mode: MODE.PREVIEW,
                comexForm: {
                    ...storeProp.comexForm,
                    formData: action.data,
                    formTicket: action.data,
                },
            };
        case TYPE_COMEX.SIGN_TRANSACTION_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };

        case TYPE_COMEX.SIGN_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,

                mode: MODE.VIEW,
                prevMode: storeProp.mode,
                transaction: action.transaction,
            };
        case TYPE.PRESENT_BILLING_DOCUMENTS_PRE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                presentBillingDocuments: {
                    ...storeProp.presentBillingDocuments,
                    preData: action,
                },
            };
        case TYPE.PRESENT_BILLING_DOCUMENTS_PREVIEW_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                presentBillingDocuments: {
                    ...storeProp.presentBillingDocuments,
                    formData: action.formData,
                },
            };
        case TYPE.PRESENT_BILLING_DOCUMENTS_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.PREVIEW,
                prevMode: storeProp.mode,
            };
        case TYPE.PRESENT_BILLING_DOCUMENTS_SEND_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                prevMode: storeProp.mode,
                transaction: action.transaction,
            };

        case TYPE.BILLING_CLAIM_PRE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                billingClaim: {
                    ...storeProp.billingClaim,
                    preData: action,
                },
            };
        case TYPE.BILLING_CLAIM_PREVIEW_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                billingClaim: {
                    ...storeProp.billingClaim,
                    formData: action.formData,
                },
            };
        case TYPE.BILLING_CLAIM_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.PREVIEW,
                prevMode: storeProp.mode,
            };
        case TYPE.BILLING_CLAIM_SEND_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                prevMode: storeProp.mode,
                transaction: action.transaction,
            };
        case TYPE.AUTHORIZATION_DOCUMENTS_DISCREPANCIES_PRE_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.AUTHORIZATION_DOCUMENTS_DISCREPANCIES_PRE_FAILURE:
            return {
                ...storeProp,
                fetching: false,
                authorizationDocumentsDiscrepancies: {
                    ...storeProp.authorizationDocumentsDiscrepancies,
                    preData: {},
                },
            };
        case TYPE.AUTHORIZATION_DOCUMENTS_DISCREPANCIES_PRE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                authorizationDocumentsDiscrepancies: {
                    ...storeProp.authorizationDocumentsDiscrepancies,
                    preData: action.data,
                },
            };
        case TYPE.AUTHORIZATION_DOCUMENTS_DISCREPANCIES_PREVIEW_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                authorizationDocumentsDiscrepancies: {
                    ...storeProp.authorizationDocumentsDiscrepancies,
                    formData: action.formData,
                },
            };
        case TYPE.AUTHORIZATION_DOCUMENTS_DISCREPANCIES_PREVIEW_FAILURE:
            return {
                ...storeProp,
                fetching: false,
                authorizationDocumentsDiscrepancies: {
                    ...storeProp.authorizationDocumentsDiscrepancies,
                },
            };
        case TYPE.AUTHORIZATION_DOCUMENTS_DISCREPANCIES_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.PREVIEW,
                prevMode: storeProp.mode,
            };

        case TYPE.AUTHORIZATION_DOCUMENTS_DISCREPANCIES_SEND_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.AUTHORIZATION_DOCUMENTS_DISCREPANCIES_SEND_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.AUTHORIZATION_DOCUMENTS_DISCREPANCIES_SEND_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                prevMode: storeProp.mode,
                transaction: action.transaction,
            };
        /** CESSION CREDIT LETTER */

        case TYPE.CESSION_CREDIT_LETTER_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                mode: MODE.PREVIEW,
                fetching: false,
                prevMode: storeProp.mode,
                comexForm: {
                    ...storeProp.comexForm,
                    formData: { ...storeProp.comexForm.prevData, environment: action.environment },
                    formTicket: action.data,
                },
            };
        case TYPE.COMEX_FORM_SEND_DATA_FAILURE:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.EDIT,
            };
        case TypesBackoffice.BACKOFFICE_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                prevMode: storeProp.mode,
                transaction: action.transaction,
            };
        default:
            return storeProp;
    }
};
