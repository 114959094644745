import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "wm";

export const PATH = {
    GET_PAPER_REQUEST: "/client.wm.getPaper",
};

export const TYPE = FactoryType(NAME, ["GET_PAPER_REQUEST", "GET_PAPER_FAILURE", "GET_PAPER_SUCCESS"]);

export const PROP = {};

PROP.types = {
    isDownloading: PropTypes.bool,
    ...TypesRedux,
};

PROP.defaults = {
    isDownloading: false, // TODO ver si lo vamos a usar
    ...DefaultsRedux,
};
