import { FactoryAction } from "~/util/redux";
import { getScheme as GetScheme } from "~/util/store/administration";

import { NAME } from "./_consts";

export const SelectorsAction = (TYPE) => ({
    loadDetailsRequest: (props) => FactoryAction(TYPE.LOAD_DETAILS_REQUEST, props), // id
    loadDetailsFailure: () => FactoryAction(TYPE.LOAD_DETAILS_FAILURE),
    loadDetailsSuccess: (props) => FactoryAction(TYPE.LOAD_DETAILS_SUCCESS, props), // data
    updateSignatureRequest: (props) => FactoryAction(TYPE.UPDATE_SIGNATURE_REQUEST, props), // data formikBag
    updateSignatureRequestFailure: () => FactoryAction(TYPE.UPDATE_SIGNATURE_REQUEST_FAILURE),
    updateSignatureRequestSuccess: () => FactoryAction(TYPE.UPDATE_SIGNATURE_REQUEST_SUCCESS),
    loadDataForSignUpdateSignature: (props) => FactoryAction(TYPE.LOAD_DATA_FOR_SIGN_UPDATE_SIGNATURE, props), // data

    loadFormRequest: (props) => FactoryAction(TYPE.LOAD_FORM_REQUEST, props), // id
    loadFormFailure: () => FactoryAction(TYPE.LOAD_FORM_FAILURE),
    loadFormSuccess: (props) => FactoryAction(TYPE.LOAD_FORM_SUCCESS, props), // data
});

export const SelectorsStore = {
    hasSignatureEnabled: (store) => GetScheme(store)[NAME]?.signatureLevel !== null,
    getSignatureLevel: (store) => GetScheme(store)[NAME]?.signatureLevel,
    hasMassiveEnabled: (store) => GetScheme(store)[NAME]?.hasMassiveEnabled,
    getUserEnvStatus: (store) => GetScheme(store)[NAME]?.userEnvStatus,
    getAdminGroupsIds: (store) => GetScheme(store)[NAME]?.adminGroupsIds,
    isFetching: (store) => (GetScheme(store)[NAME] ? GetScheme(store)[NAME]?.fetching : false),
    getCredentialGroups: (store) => GetScheme(store)[NAME]?.credentialGroups,
    getIdTransaction: (store) => GetScheme(store)[NAME]?.idTransaction,
    getIdActivity: (store) => GetScheme(store)[NAME]?.idActivity,
};
