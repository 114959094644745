import PropTypes from "prop-types";

export const Members = {};

/** HtmlElement propTypes */
export const Types = {
    /** An Id to pass to the final element */
    id: PropTypes.string,
    /** A className to pass to the final element */
    className: PropTypes.string,
    /** Custom css styles to apply to the element */
    style: PropTypes.shape({}),
};

/** HtmlElement defaultProps */
export const Defaults = {
    id: null,
    className: null,
    styles: null,
};

export default {
    members: Members,
    types: Types,
    defaults: Defaults,
};
