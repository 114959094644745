import React from "react";

import { Form, withFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SelectorsAction as SelectorsActionAccount, SelectorsStore as SelectorsStoreAccount } from "~/store/account";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import * as UtilDate from "~/util/date";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import DataDate from "~/components/DataDate";
import HighOrder from "~/components/HighOrder";
import Select from "~/pages/_components/fields/Select";

import Style from "./_VouchersFilters.rules.scss";

export const { CLASS, NAME } = Style;

export const PROP = {
    types: {
        values: PropTypes.shape({
            year: PropTypes.number,
            month: PropTypes.number,
            currency: PropTypes.number,
        }).isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const {
        i18n,
        values: { year },
    } = props;

    const currentYear = UtilDate.getYear();
    const yearsOptions = [
        { value: currentYear, label: currentYear.toString() },
        { value: currentYear - 1, label: (currentYear - 1).toString() },
        { value: currentYear - 2, label: (currentYear - 2).toString() },
    ];

    const currencyOptions = [
        { value: 2222, label: UtilsI18n.get("currency.label.2222") },
        { value: 0, label: UtilsI18n.get("currency.label.0") },
    ];

    const initialMonths = UtilDate.eachMonthOfInterval(new Date(currentYear, 0, 1), new Date()).map((date) => {
        return {
            value: date.getMonth() + 1,
            label: <DataDate raw value={date} type-year={false} type-month="long" type-day={false} />,
        };
    });
    const [monthOptions, setMonthOptions] = React.useState(initialMonths);

    React.useEffect(() => {
        const end = year === currentYear ? new Date() : new Date(year, 11, 1);
        const newMonthOptions = UtilDate.eachMonthOfInterval(new Date(year, 0, 1), end).map((date) => {
            return {
                value: date.getMonth() + 1,
                label: <DataDate raw value={date} type-year={false} type-month="long" type-day={false} />,
            };
        });
        setMonthOptions(newMonthOptions);
    }, [currentYear, year]);

    return (
        <Form className={CLASS}>
            <Select
                clearable={false}
                labelKey="label"
                name="currency"
                options={currencyOptions}
                valueKey="value"
                label={i18n.labelCurrency}
            />
            <Select
                clearable={false}
                labelKey="label"
                name="year"
                options={yearsOptions}
                valueKey="value"
                label={i18n.labelYear}
            />
            <Select
                clearable={false}
                labelKey="label"
                name="month"
                options={monthOptions}
                valueKey="value"
                label={i18n.labelMonth}
            />
            <Box justify="center">
                <Button size="sm" type="submit" variant="primary">
                    {i18n.filter}
                </Button>
            </Box>
        </Form>
    );
}

const mapStateToProps = (store) => ({
    i18n: {
        filter: StoreI18n.getMessage(store, "product.filters.filter"),
        labelCurrency: StoreI18n.getMessage(store, "client.account.billingTickets.currency.label"),
        labelYear: StoreI18n.getMessage(store, "client.account.billingTickets.year.label"),
        labelMonth: StoreI18n.getMessage(store, "client.account.billingTickets.month.label"),
    },
    filters: SelectorsStoreAccount.getEBillingTicketsFilters(store),
});

export default HighOrder(
    Connect(mapStateToProps),
    withFormik({
        mapPropsToValues: (props) => {
            const { filters } = props;
            return { ...filters };
        },
        handleSubmit: ({ currency, year, month }, formikBag) => {
            const { dispatch } = formikBag.props;
            dispatch(
                SelectorsActionAccount.listBillingTickets({
                    filters: { year, month, currency },
                    page: 1,
                    cleanList: true,
                }),
            );
        },
    }),
)(Component);
