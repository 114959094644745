import { LOCATION_CHANGE } from "connected-react-router";

import { EMPTY_STR } from "~/constants";
import { ID_FORM } from "~/constants/form";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            const { pathname } = action.payload.location;

            return {
                ...storeProp,
                loadedTemplateName:
                    (pathname.includes(ID_FORM.SALARY_PAYMENT) ||
                        pathname.includes(ID_FORM.SUPPLIERS_PAYMENT) ||
                        pathname.includes(ID_FORM.TRANSFER_FOREIGN)) &&
                    pathname.includes(storeProp.idFormLoadedTemplate)
                        ? storeProp.loadedTemplateName
                        : PROP.defaults.loadedTemplateName,
            };
        }
        case TYPE.TOGGLE_CONFIRMATION_MODAL:
            return {
                ...storeProp,
                showConfirmationModal: !storeProp.showConfirmationModal,
                createTemplateModalVisible: !storeProp.createTemplateModalVisible,
                fetching: false,
            };
        case TYPE.TOGGLE_TEMPLATE_LIST:
            return {
                ...storeProp,
                templateListModalVisible: !storeProp.templateListModalVisible,
            };
        case TYPE.TOGGLE_TEMPLATE_BACK:
            return { ...storeProp, templateListModalVisible: false };
        case TYPE.CREATE_TEMPLATE:
            return {
                ...storeProp,
                createTemplateModalVisible: true,
            };
        case TYPE.CLOSE_CREATE_TEMPLATE:
            return {
                ...storeProp,
                createTemplateModalVisible: false,
            };
        case TYPE.LOAD_TEMPLATE_LIST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.LOAD_TEMPLATE_LIST_SUCCESS:
            return {
                ...storeProp,
                list: action.templates,
                fetching: false,
            };
        case TYPE.LOAD_TEMPLATE_LIST_FAILURE:
            return {
                ...storeProp,
                list: [],
                fetching: false,
            };
        case TYPE.SAVE_TEMPLATE_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.SAVE_TEMPLATE_FAILURE:
        case TYPE.SAVE_TEMPLATE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                createTemplateModalVisible: false,
                showConfirmationModal: false,
            };
        case TYPE.DELETE_TEMPLATE_SUCCESS:
            return {
                ...storeProp,
                list: storeProp.list.filter((item) => item.idTransactionTemplate !== action.idTransactionTemplate),
            };
        case TYPE.PERSIST_LOADED_TEMPLATE_NAME:
            return {
                ...storeProp,
                idFormLoadedTemplate: action.idFormLoadedTemplate || EMPTY_STR,
                loadedTemplateName: action.loadedTemplateName,
            };
        case TYPE.LOAD_TEMPLATE_FROM_OTHER_PAGE:
            return {
                ...storeProp,
                loadTemplateFromOtherPage: action.loadTemplateFromOtherPage,
            };
        default:
            return storeProp;
    }
};
