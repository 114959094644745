import React from "react";

import { push as Push, replace as Replace } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { Link } from "react-router-dom";

import { ONE, SCOPE, SLASH, ZERO } from "~/constants";
import Container from "~/containers/Internal/Form/Filter";
import {
    SelectorsAction as SelectorsActionInvestment,
    SelectorsStore as SelectorsStoreInvestment,
} from "~/store/investment";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as UtilDate from "~/util/date";
import * as i18nUtils from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Box from "~/components/Box";
import I18n from "~/components/I18n";
import Image from "~/components/Image";

import Style from "./RiskProfile.rules.scss";

export const NAME = "InvestmentRiskProfile";

export const PROP = {
    types: {
        ...TypesRedux,
        dispatch: PropTypes.func.isRequired,
        fetching: PropTypes.bool,
        setFieldValue: PropTypes.func.isRequired,
        values: PropTypes.object.isRequired,
    },
    defaults: {
        ...DefaultsRedux,
        fetching: false,
    },
};
export class Component extends React.Component {
    componentDidMount() {
        const { activeEnvironment, dispatch } = this.props;
        const { riskProfileCode, riskProfileExpiration } = activeEnvironment;

        if (riskProfileCode === ZERO && riskProfileExpiration === null) {
            dispatch(Replace(`/investment/riskProfile`));
        } else {
            dispatch(SelectorsActionInvestment.riskProfileReadLast());
        }
    }

    handleClick = () => {
        const { dispatch } = this.props;

        dispatch(Push("/investment/riskProfileQuiz"));
    };

    handleDownload = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionInvestment.riskProfileDownloadPDF());
    };

    handleBack = () => {
        const { history } = this.props;

        history.goBack();
    };

    render() {
        const { activeEnvironment, riskProfile } = this.props;
        const { riskProfileCode, riskProfileExpiration } = activeEnvironment;

        const ulData = i18nUtils.get(`gnb.wm.riskProfile.detail.riskProfile.${riskProfileCode}.body`);
        const dateParts = activeEnvironment.riskProfileExpiration
            ? activeEnvironment.riskProfileExpiration.split(SLASH)
            : null;
        const expirationDate = dateParts ? new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]) : new Date();

        return (
            <Container
                className="position"
                head-onBack={this.handleBack}
                image="businessPortfolio.svg"
                head-title="wm.riskProfile.title"
                name={NAME}
                scopeToShowNotification={SCOPE.RISK_PROFILE_RESUME}>
                <section id={Style.ID} className="container">
                    <Box className="risk-title">
                        <I18n id="gnb.wm.riskProfile.detail.title" />
                    </Box>
                    <Box className="risk-profile-section q-results rounded-box info-grey">
                        <Box className="q-results rounded-box info-grey">
                            <I18n
                                className="uppercase-text"
                                id={`gnb.wm.riskProfile.detail.riskProfile.${riskProfileCode}.name`}
                            />
                            {riskProfile && (
                                <Link
                                    className="link-risk fRight"
                                    to={`/investment/transaction/riskProfile/${
                                        riskProfile && riskProfile.idTransaction
                                    }/ticket`}>
                                    <I18n id="gnb.wm.riskProfile.detail.seeQuestionnaire" />
                                </Link>
                            )}
                        </Box>
                        <Box className="q-results rounded-box info-yellow">
                            <Box className="expiration-text">
                                {UtilDate.isFutureDate(expirationDate) ? (
                                    <I18n id="gnb.wm.riskProfile.detail.validTo" />
                                ) : (
                                    <I18n id="gnb.wm.riskProfile.detail.expired" />
                                )}
                                <span className="date">{riskProfileExpiration}</span>
                            </Box>
                            <Link className="link-risk-black fRight" to="/investment/riskProfile2">
                                <I18n id="gnb.wm.riskProfile.detail.renew" />
                            </Link>
                        </Box>
                    </Box>
                    <Box className="comment-q-results">
                        <I18n id={`gnb.wm.riskProfile.detail.riskProfile.${riskProfileCode}.introduction`} />
                    </Box>
                    <Box className="risk-profile-extra-section">
                        <Box className="data">
                            <Box className="risk-subtitle">
                                <I18n id="gnb.wm.riskProfile.detail.riskProfile.1.subtitle" />
                            </Box>
                            <Box className="container-data">
                                <div dangerouslySetInnerHTML={{ __html: ulData }} />
                            </Box>
                        </Box>
                        <Box className="graph">
                            <Box className="q-results rounded-box info-graphic">
                                <Box className="title-graphic">
                                    <I18n id="gnb.wm.riskProfile.detail.riskProfile.1.graphic.title" />
                                </Box>
                                <Box className="div-graphic">
                                    <Box className="fLeft">
                                        <Image src={`riskProfile/portfolio_${riskProfileCode}.png`} />
                                    </Box>
                                    <Box className="fRight">
                                        <Box className="reference-text">
                                            <Image src="riskProfile/greenIcon.png" />
                                            <Box className="graphic">
                                                <I18n
                                                    id={`gnb.wm.riskProfile.detail.riskProfile.${riskProfileCode}.item1.graphic.label`}
                                                />
                                                <I18n
                                                    className="text-right"
                                                    id={`gnb.wm.riskProfile.detail.riskProfile.${riskProfileCode}.item1.graphic.percentage`}
                                                />
                                            </Box>
                                        </Box>
                                        <Box className="reference-text">
                                            <Image src="riskProfile/orangeIcon.png" />
                                            <Box className="graphic">
                                                <I18n
                                                    id={`gnb.wm.riskProfile.detail.riskProfile.${riskProfileCode}.item2.graphic.label`}
                                                />
                                                <I18n
                                                    className="text-right"
                                                    id={`gnb.wm.riskProfile.detail.riskProfile.${riskProfileCode}.item2.graphic.percentage`}
                                                />
                                            </Box>
                                        </Box>
                                        {riskProfileCode !== ONE && (
                                            <React.Fragment>
                                                <Box className="reference-text">
                                                    <Image src="riskProfile/pinkIcon.png" />
                                                    <Box className="graphic">
                                                        <I18n
                                                            id={`gnb.wm.riskProfile.detail.riskProfile.${riskProfileCode}.item3.graphic.label`}
                                                        />
                                                        <I18n
                                                            className="text-right"
                                                            id={`gnb.wm.riskProfile.detail.riskProfile.${riskProfileCode}.item3.graphic.percentage`}
                                                        />
                                                    </Box>
                                                </Box>
                                                <Box className="reference-text">
                                                    <Image src="riskProfile/skyIcon.png" />
                                                    <Box className="graphic">
                                                        <I18n
                                                            id={`gnb.wm.riskProfile.detail.riskProfile.${riskProfileCode}.item4.graphic.label`}
                                                        />
                                                        <I18n
                                                            className="text-right"
                                                            id={`gnb.wm.riskProfile.detail.riskProfile.${riskProfileCode}.item4.graphic.percentage`}
                                                        />
                                                    </Box>
                                                </Box>
                                            </React.Fragment>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="link-risk-pdf">
                        <Link onClick={this.handleDownload} to="#">
                            <I18n id="wm.riskProfile.link" />
                        </Link>
                    </Box>
                </section>
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
    hasRiskProfile: SelectorsStoreInvestment.getHasRiskProfile(store),
    riskProfile: SelectorsStoreInvestment.getLastRiskProfile(store),
});

export default Connect(mapStateToProps)(Component);
