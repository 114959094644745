import React from "react";

import { Formik, Field, Form } from "formik";
import PropTypes from "prop-types";
import { Grid, Row, Col } from "react-bootstrap";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import { CREDENTIAL_TYPE_OTP, DOT, EMPTY_STR, EVENT_BACKBUTTON, ID_FORM, LANGUAGE, UNDERSCORE } from "~/constants";
import { SelectorsAction as SelectorsActionForm, SelectorsStore as SelectorsStoreForm } from "~/store/form";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { flattenArray, removeDuplicateItems } from "~/util/array";
import * as i18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import I18n from "~/components/I18n";
import Credential from "~/pages/_components/fields/credentials/Credential";

import * as ConfirmationMessages from "~/pages/forms/Step2Preview/_confirmations/Index";

import Fields from "../_components/Fields";
import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        credentials: PropTypes.oneOfType([PropTypes.array]).isRequired,
        currentLang: PropTypes.string,
        metadata: PropTypes.oneOfType([PropTypes.any]),
        previewData: PropTypes.shape(),
        submit: PropTypes.bool,
        submitAction: PropTypes.func.isRequired,
        submitActionParams: PropTypes.oneOfType([PropTypes.object]),
        user: PropTypes.shape({
            otpType: PropTypes.string.isRequired,
        }).isRequired,
        ...TypesRedux,
    },
    defaults: { currentLang: LANGUAGE.ES, metadata: {}, submitActionParams: null, submit: false, ...DefaultsRedux },
};

export function Component(props) {
    const { credentials, currentLang, dispatch, metadata, submit } = props;

    const { idForm } = metadata;
    const idComponent = (idForm.charAt(0).toUpperCase() + idForm.substr(1)).replace(DOT, UNDERSCORE);
    const requireSomeCredential = credentials.length > 0;
    const initialValues = credentials.reduce((values, credential) => ({ ...values, [credential]: EMPTY_STR }), {});

    if (ID_FORM.TRANSFER_LOCAL === idForm) {
        initialValues.executionType = EMPTY_STR;
    }

    const Message = ConfirmationMessages[idComponent];

    React.useEffect(() => {
        document.addEventListener(EVENT_BACKBUTTON, handleModalClose, false);
        SelectorsActionForm.formDataReset();

        return () => {
            document.removeEventListener(EVENT_BACKBUTTON, handleModalClose, false);
        };

        function handleModalClose(event) {
            event.preventDefault();

            if (!submit) {
                dispatch(SelectorsActionForm.closeConfirmation());
            }
        }
    }, [dispatch, submit]);

    return (
        <Formik initialValues={initialValues} validationSchema={handleSchema} onSubmit={handleSubmit}>
            {(formikProps) => {
                const { isSubmitting } = formikProps;
                return (
                    <Form noValidate>
                        <React.Fragment>
                            <div id={Style.ID}>
                                <section className="container--layout align-items-center flex-grow">
                                    <Grid className="form-content">
                                        <Row className="justify-content-center">
                                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                                {Message ? (
                                                    <Message
                                                        currentLang={currentLang}
                                                        formikProps={formikProps}
                                                        metadata={metadata}
                                                    />
                                                ) : (
                                                    <React.Fragment>
                                                        {requireSomeCredential && (
                                                            <p className="text-lead">
                                                                <I18n id="forms.default.confirmation.title" />
                                                            </p>
                                                        )}
                                                        <Fields />
                                                    </React.Fragment>
                                                )}
                                            </Col>
                                        </Row>
                                    </Grid>
                                </section>

                                <section className="container--layout align-items-center">
                                    <Grid className="form-content container--flex-middle">
                                        <Row className="justify-content-center">
                                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                                {requireSomeCredential ? (
                                                    <I18n id="form.credential.hint" />
                                                ) : (
                                                    <I18n id="form.confirmation.without.credential" />
                                                )}
                                                {credentials.map((credential) => (
                                                    <div key={credential}>
                                                        <Field
                                                            idForm="form.credential"
                                                            name={credential}
                                                            component={Credential}
                                                            type={credential}
                                                            hidePlaceholder={credential === CREDENTIAL_TYPE_OTP}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                ))}
                                            </Col>
                                        </Row>
                                    </Grid>
                                </section>
                                <section className="container--layout align-items-center">
                                    <Grid className="form-footer">
                                        <Row className="justify-content-center">
                                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                                <Button
                                                    type="submit"
                                                    label="global.send"
                                                    bsStyle="primary"
                                                    loading={isSubmitting}
                                                />
                                            </Col>
                                        </Row>
                                    </Grid>
                                </section>
                            </div>
                        </React.Fragment>
                    </Form>
                );
            }}
        </Formik>
    );

    function handleSchema() {
        const { user } = props;

        if (user.needsBiometric) {
            return Yup.object().shape(
                credentials.reduce(
                    (values, credential) => ({
                        ...values,
                        [credential]: Yup.string().nullable(),
                    }),
                    {},
                ),
            );
        }

        const schema = credentials.reduce(
            (values, credential) => ({
                ...values,
                [credential]: Yup.string().required(i18n.get(`form.credential.${credential}.required`)),
            }),
            {},
        );

        if (ID_FORM.TRANSFER_LOCAL === idForm) {
            schema.executionType = Yup.string().required(i18n.get(`${ID_FORM.TRANSFER_LOCAL}.executionType.required`));
        }

        return Yup.object().shape(schema);
    }

    function handleSubmit(_credentials, formikBag) {
        const {
            submitAction,
            submitActionParams: { idForm: id, idActivity, idTransaction, values },
        } = props;

        dispatch(submitAction({ idForm: id, idActivity, idTransaction, values, credentials: _credentials, formikBag }));
    }
}

Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

const mapStateToProps = (store) => {
    const credentials = Compose(
        (array) => array.filter((item) => item !== "accessToken"),
        removeDuplicateItems,
        flattenArray,
        (array) => array.map((item) => item.credentials),
    )(SelectorsStoreForm.getCredentialsGroups(store));

    const metadata = SelectorsStoreForm.getMetadata(store);

    return {
        credentials,
        currentLang: SelectorsStoreI18n.getLang(store),
        metadata,
        name: SelectorsStoreForm.getName(store),
        previewData: SelectorsStoreForm.getPreviewData(store),
        submit: SelectorsStoreForm.getSubmit(store),
        submitAction: SelectorsStoreForm.getSubmitAction(store),
        submitActionParams: SelectorsStoreForm.getSubmitActionParams(store),
        user: SelectorsStoreSession.getUser(store),
    };
};

export default Connect(mapStateToProps)(Component);
