import { TYPE, PROP } from "./_consts";

export default (state = PROP.defaults, action = {}) => {
    switch (action.type) {
        case TYPE.SET_LANG:
            return { ...state, lang: action.lang };
        case TYPE.SET_INIT_LANG:
            return { ...state, lang: action.lang };
        case TYPE.UPDATE_REQUEST:
            return { ...state, fetching: true };
        case TYPE.UPDATE_SUCCESS:
            return {
                ...state,
                fetching: false,
                items: action.items,
                etag: action.etag,
                lang: action.lang,
            };
        case TYPE.UPDATE_FAILURE:
            return { ...state, fetching: false };
        default:
            return state;
    }
};
