import React from "react";

import ClassNames from "classnames";
import PropTypes from "prop-types";

import { get as getI18n } from "~/util/i18n";

import I18n from "~/components/I18n";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import FormattedDate from "~/pages/_components/FormattedDate";

export const NAME = "StateItems";

export const PROP = {
    types: {
        itemRef: PropTypes.func,
        label: PropTypes.string,
        date: PropTypes.string,
        quantity1: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        quatity1Note: PropTypes.string,
        currency1: PropTypes.string,
        labelMin: PropTypes.string,
        quantity2: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        labelAux: PropTypes.string,
        dispatch: PropTypes.func,
        highlight: PropTypes.bool,
    },
    defaults: {
        itemRef: () => {},
    },
};

export function Component(props) {
    const {
        itemRef,
        label,
        date,
        quantity1,
        quatity1Note,
        currency1,
        labelMin,
        quantity2,
        labelAux,
        highlight,
    } = props;
    const subElement = (
        <React.Fragment>
            {date && (
                <span className="data-date">
                    <FormattedDate date={date} />
                </span>
            )}
            {quantity1 && (
                <span className={ClassNames({ lg: highlight })}>
                    <FormattedAmount quantity={quantity1} currency={currency1} quatityNote={quatity1Note} />
                </span>
            )}
            {labelMin && (
                <React.Fragment>
                    <span className="data-label">
                        <I18n id={labelMin} />
                    </span>
                    <span>
                        <FormattedAmount quantity={quantity2} currency={currency1} />
                    </span>
                </React.Fragment>
            )}
            {labelAux && <span className="data-aux">{labelAux}</span>}
        </React.Fragment>
    );

    const element = (
        <React.Fragment>
            {label && <h4 className="data-label">{getI18n(label)}</h4>}
            {subElement}
        </React.Fragment>
    );

    return (
        <section ref={itemRef} role="listitem">
            {element}
        </section>
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
