// TODO: replace this with FormattedDate? or the other way around.
import React from "react";

import ClassNames from "classnames";
import { connect as Connect } from "react-redux";

import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { Categorize } from "~/util/prop";

import DataNumber from "~/components/DataNumber";
import I18n from "~/components/I18n";

import Namespace from "./DataAccount.scss";

export const { NAME, TYPE, TAG: DataAccount, CLASS } = Namespace;
export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const { label, prefix, title, value } = Categorize(props);
    return (
        <DataAccount className={ClassNames(CLASS)}>
            <div className="title">{title}</div>
            <div>
                <I18n id={label} />
            </div>
            <DataNumber prefix={prefix} value={value} />
        </DataAccount>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store, props) => {
    const { lang } = props;
    return {
        notAvailable: StoreI18n.getMessage(store, "data.notAvailable.short"),
        lang: lang || StoreI18n.getLang(store) || window.navigator.language.slice(0, 2),
    };
};

export default Connect(mapStateToProps)(Component);
