import React from "react";

import { Formik } from "formik";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { EMPTY_STR, LEVEL, SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Administration/Simple";
import {
    SelectorsStore as SelectorsStoreGroup,
    SelectorsAction as SelectorsActionGroup,
} from "~/store/administration/advanced/group";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as I18nUtils from "~/util/i18n";

import I18n from "~/components/I18n";

import DeletePopUp from "~/pages/administration/advanced/_components/DeletePopUp";

import Style from "./Detail.rules.scss";
import GroupForm from "./Form";

export const { NAME, CLASS } = Style;

const FORM_ID = "administration.advanced.group.create";

export const PROP = {
    types: {
        credentialGroups: PropTypes.arrayOf({}),
        dispatch: PropTypes.func.isRequired,
        fetching: PropTypes.bool,
        formValues: PropTypes.shape({}).isRequired,
        hasBack: PropTypes.bool.isRequired,
        hasClose: PropTypes.bool.isRequired,
        idActivity: PropTypes.string,
        idTransaction: PropTypes.string,
        location: PropTypes.shape({}).isRequired,
        match: PropTypes.shape({
            path: PropTypes.string,
            url: PropTypes.string,
        }).isRequired,
    },
    defaults: {
        credentialGroups: [],
        fetching: false,
        idActivity: null,
        idTransaction: null,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    componentDidMount() {
        const { dispatch, isChangingEnvironment, match } = this.props;

        if (!isChangingEnvironment) {
            const { id } = match.params;

            dispatch(SelectorsActionGroup.loadGroupFormDataRequest({ id }));
        }
    }

    handleBack = () => {
        const { dispatch, history, mode } = this.props;

        if (mode === MODE.VIEW) {
            dispatch(SelectorsActionGroup.goStepBack());
        } else {
            history.goBack();
        }
    };

    handleSubmit = (values, formikBag) => {
        const { dispatch, idActivity, idTransaction, match, mode } = this.props;

        if (mode === MODE.VIEW) {
            if (!idTransaction) {
                dispatch(
                    SelectorsActionGroup.submitGroupFormRequest({
                        formData: { ...values },
                        idGroup: match.params.id,
                        formikBag,
                    }),
                );
            } else {
                const { description, name, permissions, status, users, ...credentials } = values;
                const paramsSign = { idActivity, idForm: null, idTransaction };

                dispatch(SelectorsActionForm.signTransaction({ ...paramsSign, credentials, formikBag }));
            }
        } else {
            dispatch(
                SelectorsActionGroup.submitGroupFormPreviewRequest({
                    formData: { ...values },
                    idGroup: match.params.id,
                    formikBag,
                }),
            );
        }
    };

    validate = (values) => {
        const { dispatch } = this.props;
        let errors;

        if (values.name.trim() === EMPTY_STR) {
            errors = {};
            errors.name = I18nUtils.get(`${FORM_ID}.name.required`);
        }

        if (errors) {
            dispatch(
                SelectorsActionNotification.showNotification({
                    message: I18nUtils.get("forms.fieldsErrors"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION],
                }),
            );
        }

        return errors;
    };

    render() {
        const { dispatch, fetching, formValues, match, mode } = this.props;
        const buttonText = match.params.id ? `${FORM_ID}.button.modify.text` : `${FORM_ID}.button.create.text`;

        return (
            <Container
                name={NAME}
                wait={fetching}
                head-title={`administration.advanced.group.${!match.params.id ? "create" : "modify"}.title`}
                head-onBack={this.handleBack}
                image="administration-title-icon.svg"
                className={CLASS}
                scopeToShowNotification={SCOPE.ADMINISTRATION}>
                <p>
                    <I18n id="administration.advanced.group.permissionHeader" />
                </p>
                <Formik
                    initialValues={formValues}
                    onSubmit={this.handleSubmit}
                    enableReinitialize
                    validateOnChange={false}
                    validateOnBlur={false}
                    validate={this.validate}>
                    {(props) => (
                        <React.Fragment>
                            {mode === "edit" && <GroupForm {...props} idForm={FORM_ID} buttonText={buttonText} />}
                            {mode === "view" && (
                                <DeletePopUp
                                    show
                                    title={
                                        match.params.id
                                            ? I18nUtils.get("administration.groups.advanced.modifyPopup.title")
                                            : I18nUtils.get("administration.groups.advanced.createPopup.title")
                                    }
                                    description={
                                        match.params.id
                                            ? I18nUtils.get("administration.groups.advanced.modifyPopup.description")
                                            : I18nUtils.get("administration.groups.advanced.createPopup.description")
                                    }
                                    handleCloseModal={() => dispatch(SelectorsActionGroup.goStepBack())}
                                    onSubmit={() => {
                                        const { values, ...rest } = props;
                                        this.handleSubmit(values, rest);
                                    }}
                                />
                            )}
                        </React.Fragment>
                    )}
                </Formik>
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    adminUsers: SelectorsStoreGroup.getAdminUsers(store),
    availableUsers: SelectorsStoreGroup.getAvailableUsers(store),
    credentialGroups: SelectorsStoreGroup.getCredentialGroups(store),
    fetching: SelectorsStoreGroup.isFetching(store),
    formValues: SelectorsStoreGroup.getFormValues(store),
    hasBack: SelectorsStoreGroup.isHasBack(store),
    hasClose: SelectorsStoreGroup.isHasClose(store),
    idActivity: SelectorsStoreGroup.getIdActivity(store),
    idTransaction: SelectorsStoreGroup.getIdTransaction(store),
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    mode: SelectorsStoreGroup.getMode(store),
});

export default connect(mapStateToProps)(Component);
