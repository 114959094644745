import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { CDP_CURRENCY } from "~/constants";
import { SelectorsStore as StoreI18n } from "~/store/i18n";

import Box from "~/components/Box";
import DataNumber from "~/components/DataNumber";
import GridLayout from "~/components/GridLayout";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import FormattedDate from "~/pages/_components/FormattedDate";

import Style from "./_KioskDeposit.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        kioskDeposit: PropTypes.shape({
            list: PropTypes.array.isRequired,
            totalUSD: PropTypes.string.isRequired,
            totalUYU: PropTypes.string.isRequired,
        }).isRequired,
        accountCurrency: PropTypes.string.isRequired,
    },

    defaults: {},
};

export function Component(props) {
    const { kioskDeposit, i18n } = props;
    const { list, totalUSD, totalUYU } = kioskDeposit;

    const COLUMN_HEADINGS = [i18n.date, i18n.envelopeNumber, i18n.terminal, i18n.depositType, i18n.amount];

    return (
        <div id={Style.ID}>
            <div className="totalHeader">
                <label>{i18n.infoLabel}</label>
                <div>
                    <I18n id="client.account.kioskDeposit.total" />
                    <DataNumber value={totalUYU} prefix={CDP_CURRENCY.UYU} />
                    {` | `}
                    <DataNumber value={totalUSD} prefix={CDP_CURRENCY.USD} />
                </div>
            </div>

            <GridLayout className="header">
                {COLUMN_HEADINGS.map((heading, index) => (
                    <Box justify={index === 4 && "end"}> {heading}</Box>
                ))}
            </GridLayout>
            {renderListItems()}
        </div>
    );

    function renderListItems() {
        return list.map(({ date, envelopeNumber, terminal, currency, amount, depositType }) => {
            return (
                <GridLayout>
                    <div>
                        <FormattedDate date={date} customFormat="DD/MM/YYYY HH:mm:ss" />
                    </div>
                    <div>{envelopeNumber}</div>
                    <div>{terminal}</div>
                    <div>{depositType}</div>
                    <Box justify="end">
                        <DataNumber prefix={currency} value={amount} />
                    </Box>
                </GridLayout>
            );
        });
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        i18n: {
            date: StoreI18n.getMessage(store, "client.account.kioskDeposit.date"),
            envelopeNumber: StoreI18n.getMessage(store, "client.account.kioskDeposit.envelopeNumber"),
            terminal: StoreI18n.getMessage(store, "client.account.kioskDeposit.terminal"),
            currency: StoreI18n.getMessage(store, "client.account.kioskDeposit.currency"),
            amount: StoreI18n.getMessage(store, "client.account.kioskDeposit.amount"),
            depositType: StoreI18n.getMessage(store, "client.account.kioskDeposit.type"),
            infoLabel: StoreI18n.getMessage(store, "client.account.kioskDeposit.infoLabel"),
        },
    };
};

export default HighOrder(Connect(mapStateToProps))(Component);
