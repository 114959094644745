import React from "react";

import { PRODUCT_TYPE, EMPTY_STR, LOAN_TYPES } from "~/constants/index";
import * as UtilsConfig from "~/util/config";
import UtilFlag from "~/util/flag";
import { generateId as GenerateId } from "~/util/general";
import * as i18n from "~/util/i18n";
import { formatNumber } from "~/util/number";
import * as StringUtils from "~/util/string";

import Element from "~/components/Component";
import DataNumber from "~/components/DataNumber";
import GridLayout from "~/components/GridLayout";
import Image from "~/components/Image";
import Link from "~/components/Link";

import AccordionDetail from "./AccordionDetail";
import Style from "./AccountItem.rules.scss";
import { NAME_NOTE, TYPE_NOTE } from "./_index.scss";
import {
    accountItemProductTypes,
    modalFieldsByProductType,
    productsWithPaginatedOption,
    tableHeadersByProductType,
    urlRedirectByProductType,
} from "./constants";

export const { NAME, NAME_MESSAGES } = Style;
export const PROP = {
    types: {},
    defaults: {},
};

export class Component extends React.Component {
    state = {
        increment: UtilsConfig.getInteger("client.position.rowsPerProduct"),
        maxRows: UtilsConfig.getInteger("client.position.rowsPerProduct"),
    };

    isPaginatedProduct = () => {
        const { productType } = this.props;
        const { maxRows } = this.state;

        return maxRows !== -1 && productsWithPaginatedOption.includes(productType);
    };

    setMaxRows = (currency) => {
        const { productsByCurrency } = this.props;
        const { increment, maxRows } = this.state;

        const size = productsByCurrency[currency].length;
        const nextSize = maxRows + increment;

        this.setState(() => ({ maxRows: size < nextSize ? -1 : nextSize }));
    };

    getItems = (currency) => {
        const { productsByCurrency, productType, lang } = this.props;
        const { maxRows } = this.state;

        let items = productsByCurrency[currency];

        if (this.isPaginatedProduct()) {
            items = productsByCurrency[currency].slice(0, maxRows);
        }

        return items.map((product) => (
            <GridLayout
                className="body position-clickable"
                key={`movement-${product.productIdBantotal}`}
                onClick={() => {
                    this.handleOpenModal(
                        modalFieldsByProductType[productType],
                        product.othersFields,
                        currency,
                        this.getTitle(product),
                    );
                }}>
                {tableHeadersByProductType[productType].map((headerKey, index) => {
                    let dataValue = EMPTY_STR;

                    if (headerKey.isProductAttribute) {
                        // The value belongs to the product, not to otherFields
                        dataValue = product[headerKey.productAttribute];
                    }
                    if (!dataValue) {
                        dataValue = product.othersFields[headerKey.id];
                    }

                    if (headerKey.type === "amount") {
                        return (
                            <DataNumber
                                key={headerKey.id}
                                value={dataValue}
                                prefix={headerKey.forceCurrency ? headerKey.forceCurrency : currency}
                                className={index !== 0 ? "position-amount" : EMPTY_STR}
                                suffix={
                                    productType === LOAN_TYPES.LOAN &&
                                    product.othersFields.UI &&
                                    `(${product.othersFields.UI})`
                                }
                            />
                        );
                    }

                    if (headerKey.type === "number") {
                        return <div key={headerKey.id}>{formatNumber(dataValue, lang)}</div>;
                    }

                    if (StringUtils.isEmpty(dataValue)) {
                        return <div key={headerKey.id}>N/A</div>;
                    }

                    return <div key={headerKey.id}>{dataValue}</div>;
                })}
            </GridLayout>
        ));
    };

    handleOpenModal = (fields, data, currency, title) => {
        const { handleOpenModal } = this.props;

        handleOpenModal(fields, data, currency, title);
    };

    getTitle = (product) => {
        let result = i18n.get("position.popup.productDetail");

        if (product.productType === PRODUCT_TYPE.CREDIT_CARD) {
            result = `${i18n.get("position.popup.productDetail")}: ${product.productAlias}`;
        }

        return result;
    };

    resolveFormat = (currency, groupByCurrency) => {
        const { productsByCurrency, productType } = this.props;

        if (accountItemProductTypes.includes(productType)) {
            // List format
            return (
                <div className="account-item">
                    {
                        <p>
                            <span>{i18n.get(`currency.label.${currency}`)}</span>
                            <Image src={`${UtilFlag.getFlag(currency)}.svg`} />
                        </p>
                    }
                    {productsByCurrency[currency].map(({ idProduct, productAlias, balance, hasPermission }) => (
                        <div className="account-item-balance" key={GenerateId()}>
                            <p>
                                {hasPermission ? (
                                    <Link key={idProduct} to={urlRedirectByProductType[productType].concat(idProduct)}>
                                        {productAlias}
                                        <span>
                                            <span className="bold">
                                                {i18n.get("accounts.movements.table.columnHeading.balance")}:{" "}
                                            </span>
                                            <DataNumber value={balance} prefix={currency} />
                                        </span>
                                    </Link>
                                ) : (
                                    <Link
                                        className="no-clickable"
                                        onClick={(event) => event.preventDefault()}
                                        key={idProduct}
                                        to="/">
                                        {productAlias}
                                        <span>
                                            <span className="bold">
                                                {i18n.get("accounts.movements.table.columnHeading.balance")}:{" "}
                                            </span>
                                            <DataNumber value={balance} prefix={currency} />
                                        </span>
                                    </Link>
                                )}
                            </p>
                        </div>
                    ))}
                </div>
            );
        }

        if (groupByCurrency) {
            const { productName, totalByCurrency } = this.props;
            return (
                <AccordionDetail
                    className={`container-accordion-detail position_${productType}${groupByCurrency &&
                        "_groupByCurrency"}`}
                    currency={currency}
                    key={productType}
                    title={productName}
                    total={totalByCurrency[parseInt(currency, 10)].total}
                    hasFlag
                    isSubaccordion
                    forceMode="opened">
                    {this.resolveFormat(currency, false)}
                </AccordionDetail>
            );
        }
        return (
            // Table format
            <React.Fragment>
                <GridLayout className="header">
                    {tableHeadersByProductType[productType].map((heading, index) => (
                        <div
                            key={heading.id}
                            className={heading.type === "amount" && index !== 0 ? "position-amount" : EMPTY_STR}>
                            {i18n.get("position.tables.headers.".concat(heading.id))}
                        </div>
                    ))}
                </GridLayout>
                {this.getItems(currency)}
                {this.isPaginatedProduct() && (
                    <Element
                        type={TYPE_NOTE}
                        name={NAME_NOTE}
                        tag="span"
                        intersection-trigger="true"
                        key="more"
                        onClick={() => this.setMaxRows(currency)}
                        className="positionMoreMovements">
                        {i18n.get("position.tables.moreItems")}
                    </Element>
                )}
            </React.Fragment>
        );
    };

    render() {
        const { groupByCurrency, productsByCurrency } = this.props;
        const currencies = Object.keys(productsByCurrency);

        return (
            <section>
                {currencies.map((currency) => (
                    <div key={GenerateId()}>{this.resolveFormat(currency, groupByCurrency)}</div>
                ))}
            </section>
        );
    }
}
export default Component;
