import { routerActions } from "connected-react-router";
import { connect as Connect } from "react-redux";
import { bindActionCreators } from "redux";

import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsStore, SelectorsAction } from "~/store/wm/funds/buy";

import FormBuy from "./FormBuy";

const mapStateToProps = (store) => ({
    idPaper: SelectorsStore.getIdPaperSelected(store),
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    isFetching: SelectorsStore.isFetching(store),
    mode: SelectorsStore.getMode(store),
});

const mapDispatchToProps = (dispatch) => ({
    routerActions: bindActionCreators(routerActions, dispatch),
    SelectorsActionWMBuy: bindActionCreators(SelectorsAction, dispatch),
});

export default Connect(mapStateToProps, mapDispatchToProps)(FormBuy);
