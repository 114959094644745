import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { HSBC, EMPTY_STR, ZERO } from "~/constants";
import { MODE } from "~/constants/form";
import { STATUS_ICON } from "~/constants/transaction";
import {
    SelectorsAction as SelectosActionTransactionLines,
    SelectorsStore as SelectorsStoreTransactionLines,
} from "~/store/transactionLines";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import EditableDataNumber from "~/components/EditableDataNumber";
import WidgetImage from "~/components/WidgetImage";

import Style from "./SalaryPaymentRow.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        values: PropTypes.shape({
            creditAccountName: PropTypes.string,
            credit: PropTypes.shape({
                amount: PropTypes.string.isRequired,
                currency: PropTypes.string.isRequired,
            }),
            creditAccountBank: PropTypes.string,
            creditAccountNumber: PropTypes.string,
        }).isRequired,
        idForm: PropTypes.string.isRequired,
        localBanks: PropTypes.array,
        isReadOnly: PropTypes.bool.isRequired,
        paymentCurrency: PropTypes.string,
    },
    defaults: {
        values: {},
        idForm: EMPTY_STR,
        localBanks: [],
        isReadOnly: false,
        paymentCurrency: null,
    },
};

export function Component(props) {
    const { dispatch, id, idForm, isReadOnly, lineNumber, mode, paymentCurrency, values } = props;

    const {
        creditAccountBank,
        creditAccountBankShortLabel,
        creditAccountName,
        creditAccountNumber,
        creditAmountCurrency,
        fromFile,
        payroll,
        status,
    } = values;

    const [creditAmountQuantity, setCreditAmountQuantity] = React.useState(values.creditAmountQuantity);

    const handleChange = (value) => setCreditAmountQuantity(value || 0);

    const handleBlur = () => {
        const paymentLine = { ...values, creditAmountQuantity, lineNumber };
        const param = { paymentLine, idForm };
        dispatch(SelectosActionTransactionLines.editTransactionLine(param));
    };

    return (
        <React.Fragment>
            <div className="input-row">{creditAccountBankShortLabel}</div>
            <div className="input-row">{creditAccountNumber}</div>
            <div className="input-row">
                {creditAccountBank !== HSBC || payroll || fromFile
                    ? creditAccountName
                    : UtilsI18n.get(`${idForm}.manual.creditAccountName.mask`)}
            </div>
            <Box justify="end" className="input-row pl-1">
                <EditableDataNumber
                    id={id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={creditAmountQuantity || ZERO}
                    prefix={creditAmountCurrency || paymentCurrency}
                    readOnly={isReadOnly}
                />
            </Box>
            {status && mode === MODE.VIEW && (
                <Box justify="end" className="input-row pl-2">
                    {UtilsI18n.get(`transaction.status.${status}`)}
                    <WidgetImage src={`${STATUS_ICON[status]}.svg`} />
                </Box>
            )}
        </React.Fragment>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    localBanks: SelectorsStoreTransactionLines.getLocalBanks(store),
});

export default Connect(mapStateToProps)(React.memo(Component));
