import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    fetchServiceData: (props) => API.executeWithAccessToken(PATH.FETCH_SERVICE_DATA, props),
    qrModoReadTransaction: (props) => API.executeWithAccessToken(PATH.QR_MODO_READ_TRANSACTION, props),
    servicePaymentPre: () => API.executeWithAccessToken(PATH.SERVICE_PAYMENT_PRE),
    servicePaymentPreview: (props) => API.executeWithAccessToken(PATH.SERVICE_PAYMENT_PREVIEW, props),
    servicePaymentSend: (props) => API.executeWithAccessToken(PATH.SERVICE_PAYMENT_SEND, props),
};

export const SelectorsAction = {
    cancelTransactionCustom: (props) => FactoryAction(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, props),
    cleanBills: () => FactoryAction(TYPE.CLEAN_BILLS),
    cleanUp: () => FactoryAction(TYPE.CLEAN_UP),
    fetchServiceDataFailure: () => FactoryAction(TYPE.FETCH_SERVICE_DATA_FAILURE),
    fetchServiceDataRequest: (props) => FactoryAction(TYPE.FETCH_SERVICE_DATA_REQUEST, props),
    fetchServiceDataSuccess: (props) => FactoryAction(TYPE.FETCH_SERVICE_DATA_SUCCESS, props),
    handleFetching: (props) => FactoryAction(TYPE.HANDLE_FETCHING, props),
    launchSdkModo: (props) => FactoryAction(TYPE.LAUNCH_SDK_MODO_REQUEST, props),
    qrModoReadTransactionSuccess: (props) => FactoryAction(TYPE.QR_MODO_READ_TRANSACTION_SUCCESS, props),
    readTransactionFailure: (props) => FactoryAction(TYPE.READ_TRANSACTION_FAILURE, props),
    readTransactionRequest: (props) => FactoryAction(TYPE.READ_TRANSACTION_REQUEST, props),
    readTransactionSuccess: (props) => FactoryAction(TYPE.READ_TRANSACTION_SUCCESS, props),
    servicePaymentPreFailure: () => FactoryAction(TYPE.SERVICE_PAYMENT_PRE_FAILURE),
    servicePaymentPreRequest: () => FactoryAction(TYPE.SERVICE_PAYMENT_PRE_REQUEST),
    servicePaymentPreSuccess: (props) => FactoryAction(TYPE.SERVICE_PAYMENT_PRE_SUCCESS, props),
    servicePaymentPreviewFailure: () => FactoryAction(TYPE.SERVICE_PAYMENT_PREVIEW_FAILURE),
    servicePaymentPreviewRequest: (props) => FactoryAction(TYPE.SERVICE_PAYMENT_PREVIEW_REQUEST, props),
    servicePaymentPreviewSuccess: (props) => FactoryAction(TYPE.SERVICE_PAYMENT_PREVIEW_SUCCESS, props),
    servicePaymentSendFailure: () => FactoryAction(TYPE.SERVICE_PAYMENT_SEND_FAILURE),
    servicePaymentSendRequest: (props) => FactoryAction(TYPE.SERVICE_PAYMENT_SEND_REQUEST, props),
    servicePaymentSendSuccess: (props) => FactoryAction(TYPE.SERVICE_PAYMENT_SEND_SUCCESS, props),
    signTransaction: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_REQUEST, props),
    signTransactionFailure: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_FAILURE, props),
    signTransactionPreview: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_PREVIEW, props),
    signTransactionSuccess: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_SUCCESS, props),
    toStepOne: () => FactoryAction(TYPE.FORM_TO_STEP_1),
};

export const SelectorsStore = {
    getDebitAccountAlias: (store) => store[NAME].debitAccountAlias,
    getDebitAccounts: (store) => store[NAME].debitAccounts,
    getDebitAmount: (store) => store[NAME].debitAmount,
    getDocumentsToPay: (store) => store[NAME].documentsToPayList,
    getFetching: (store) => store[NAME].fetching,
    getFormData: (store) => store[NAME].formData,
    getMode: (store) => store[NAME].mode,
    getRate: (store) => store[NAME].rate,
    getRateFirstCurrency: (store) => store[NAME].rateFirstCurrency,
    getRateSecondCurrency: (store) => store[NAME].rateSecondCurrency,
    getSistarbancAvailableServices: (store) => store[NAME].sistarbancAvailableServices,
    getSistarbancValidateResult: (store) => store[NAME].sistarbancValidateResult,
    getTransaction: (store) => store[NAME].transaction,
    getTransactionDate: (store) => store[NAME].transactionDate,
    getTransactionHour: (store) => store[NAME].transactionHour,
    isSameCurrency: (store) => store[NAME].isSameCurrency,
};
