import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    deleteItem: (props) => API.executeWithAccessToken(PATH.CORE_DELETE_TRANSACTION_TEMPLATE, props),
    loadList: (props) => API.executeWithAccessToken(PATH.CORE_LIST_TRANSACTION_TEMPLATES, props),
};

export const SelectorsAction = {
    closeCreateModal: () => FactoryAction(TYPE.CLOSE_CREATE_TEMPLATE),
    createTemplate: () => FactoryAction(TYPE.CREATE_TEMPLATE),
    deleteTemplate: (props) => FactoryAction(TYPE.DELETE_TEMPLATE, props),
    deleteTemplateSuccess: (props) => FactoryAction(TYPE.DELETE_TEMPLATE_SUCCESS, props),
    loadTemplateFromOtherPage: (props) => FactoryAction(TYPE.LOAD_TEMPLATE_FROM_OTHER_PAGE, props),
    loadTemplateList: (props) => FactoryAction(TYPE.LOAD_TEMPLATE_LIST, props),
    loadTemplateListSuccess: (props) => FactoryAction(TYPE.LOAD_TEMPLATE_LIST_SUCCESS, props),
    persistLoadedTemplateName: (props) => FactoryAction(TYPE.PERSIST_LOADED_TEMPLATE_NAME, props),
    saveTemplateFailure: () => FactoryAction(TYPE.SAVE_TEMPLATE_FAILURE),
    saveTemplate: (props) => FactoryAction(TYPE.SAVE_TEMPLATE_REQUEST, props),
    savetemplatesuccess: (props) => FactoryAction(TYPE.SAVE_TEMPLATE_SUCCESS, props),
    setSelectedTemplate: (props) => FactoryAction(TYPE.SET_SELECTED_TEMPLATE, props),
    toggleShowConfirmationModal: (props) => FactoryAction(TYPE.TOGGLE_CONFIRMATION_MODAL, props),
    toggleTemplateBack: () => FactoryAction(TYPE.TOGGLE_TEMPLATE_BACK),
    toggleTemplateList: () => FactoryAction(TYPE.TOGGLE_TEMPLATE_LIST),
};

export const SelectorsStore = {
    getTemplateList: (store) => store[NAME].list,
    getShowConfirmationModal: (store) => store[NAME].showConfirmationModal,
    getLoadedTemplateName: (store) => store[NAME].loadedTemplateName,
    isCreateModalVisible: (store) => store[NAME].createTemplateModalVisible,
    isFetching: (store) => store[NAME].fetching,
    isLoadTemplateFromOtherPage: (store) => store[NAME].loadTemplateFromOtherPage,
    isTemplateListModalVisible: (store) => store[NAME].templateListModalVisible,
};
