import React from "react";

import FieldReadOnly from "~/pages/_components/fields/FieldReadOnly";
import { EMPTY_STR } from "~/constants";

import Style from "./ChangeDataAccountsContent.rules.scss";

export const NAME = "ChangeEmailContent";

export const PROP = {
    types: {},
    defaults: {},
};
export function Component({ transaction }) {
    const { newEmail, username } = transaction?.data || EMPTY_STR;

    return (
        <React.Fragment>
            <div id={Style.ID}>
                <div>
                    <div>
                        {username && (
                            <div className="container--data--addresses">
                                <FieldReadOnly
                                    value={username}
                                    label="change.PersonalData.user.label"
                                    className=" data-wrapper"
                                />
                            </div>
                        )}
                        <div className="container--data--addresses">
                            <FieldReadOnly
                                value={newEmail}
                                label="change.PersonalData.newEmail.label"
                                className=" data-wrapper"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;
export default Component;
