import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    loadListRequest: (props) => API.executeWithAccessToken(PATH.BANK_LIST, props),
};

export const SelectorsAction = {
    bankSelected: (props) => FactoryAction(TYPE.BANK_SELECTED, props),
    clearSelectedBank: () => FactoryAction(TYPE.CLEAR_SELECTED_BANK),
    loadListRequest: (props) => FactoryAction(TYPE.LOAD_LIST_REQUEST, props),
    loadListFailure: () => FactoryAction(TYPE.LOAD_LIST_FAILURE),
    loadListSuccess: (props) => FactoryAction(TYPE.LOAD_LIST_SUCCESS, props),
    resetSelectedBanks: () => FactoryAction(TYPE.RESET_SELECTED_BANKS),
    resetSelectedBank: (props) => FactoryAction(TYPE.RESET_SELECTED_BANK, props),
    searchBankRequest: (props) => FactoryAction(TYPE.SEARCH_BANK_REQUEST, props),
    searchBankFailure: (props) => FactoryAction(TYPE.SEARCH_BANK_FAILURE, props),
    searchBankSuccess: (props) => FactoryAction(TYPE.SEARCH_BANK_SUCCESS, props),
    searchBankTempalte: (props) => FactoryAction(TYPE.SEARCH_BANK_SUCCESS_TEMPLATES, props),
    setNetworkType: (props) => FactoryAction(TYPE.SET_NETWORK_TYPE, props),
};

export const SelectorsStore = {
    getBanks: (store) => store[NAME].banksResults,
    getErrorSelectingBank: (store, idField) => store[NAME].errorSelectingBank[idField],
    getHasMoreResults: (store) => store[NAME].hasMoreResults,
    getSearchBank: (store) => store[NAME].searchBankForm,
    getSelectedBank: (store, idField) => store[NAME].selectedBanks[idField],
    getNetworkType: (store) => store[NAME].networkType,
};
