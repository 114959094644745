import React from "react";

import { push as Push } from "connected-react-router";
import { withFormik, Form, Field } from "formik";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { compose } from "redux";
import * as Yup from "yup";

import { SCOPE } from "~/constants";
import Container from "~/containers/External/Form";
import { SelectorsAction as LoginActions, SelectorsStore as LoginSelectors } from "~/store/login";
import * as i18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import Image from "~/components/Image";
import Checkbox from "~/pages/_components/fields/formik/Checkbox";

import Style from "./LoginStep4.rules.scss";

const FORM_ID = "login.step4";

export const { NAME } = Style;

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        isDesktop: PropTypes.bool.isRequired,
        isSubmitting: PropTypes.bool.isRequired,
        termsAndConditions: PropTypes.shape({
            body: PropTypes.string.isRequired,
        }).isRequired,
    },
    defaults: {},
};
export function Component(props) {
    const {
        termsAndConditions,
        isSubmitting,
        location: { withFlow },
    } = props;
    if (!withFlow) {
        return <Redirect to="/" />;
    }
    const onHeaderClose = () => {
        const { dispatch } = props;
        dispatch(Push("/"));
    };

    return (
        <React.Fragment>
            <Container
                name={NAME}
                className={Style.CLASS}
                head-onClose={onHeaderClose}
                head-title="termsAndConditions.title"
                scopeToShowNotification={SCOPE.LOGIN}
                wait={isSubmitting}>
                <a onClick={onHeaderClose}>
                    <Image className="cross" src="cross.svg" />
                </a>
                <Form className="above-the-fold">
                    <Box>
                        <div className="input-group input-group-text-area">
                            <div className="form-control">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: termsAndConditions && termsAndConditions.body,
                                    }}
                                />
                            </div>
                        </div>
                    </Box>
                    <Box className="conditions-buttons">
                        <Field name="acceptConditions" idForm={FORM_ID} controlStyle="checkbox" component={Checkbox} />
                        <Button
                            className="button-step"
                            type="submit"
                            loading={isSubmitting}
                            label="login.comeIn"
                            bsStyle="primary"
                        />
                    </Box>
                </Form>
            </Container>
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (state) => ({
    termsAndConditions: LoginSelectors.getTermsAndConditions(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({ acceptConditions: false }),
        validationSchema: (props) => {
            const { termsAndConditions } = props;
            const { showExpiration } = termsAndConditions;

            return Yup.object().shape({
                acceptConditions: showExpiration
                    ? Yup.boolean().oneOf([true], i18n.get(`${FORM_ID}.acceptConditions.required`))
                    : Yup.boolean().notRequired(),
            });
        },
        handleSubmit: ({ acceptConditions }, formikBag) => {
            formikBag.props.dispatch(LoginActions.loginStep4({ acceptConditions, formikBag }));
        },
    }),
)(Component);
