import PropTypes from "prop-types";

import { MODE } from "~/constants/form";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "investments";

export const PATH = {
    MY_BRIEFCASE_PRE_REQUEST: "/investment.myBriefcase.pre",
    REQUEST_INFO_PRE_REQUEST: "/investment.requestInfo.pre",
    REQUEST_INFO_PREVIEW_REQUEST: "/investment.requestInfo.preview",
    REQUEST_INFO_REQUEST: "/investment.requestInfo.execute",
    RISK_PROFILE_DOWNLOAD_PDF_REQUEST: "/investment.wm.download",
    RISK_PROFILE_READ_LAST: "/investment.riskProfile.preview",
    RISK_PROFILE_SEND_QUESTIONS_REQUEST: "/investment.riskProfile.send",
    TERM_DEPOSIT_PRE_REQUEST: "/investment.termDeposit.pre",
    TERM_DEPOSIT_PREVIEW_REQUEST: "/investment.termDeposit.preview",
    TERM_DEPOSIT_SEND_REQUEST: "/investment.termDeposit.send",
    TERM_DEPOSIT_CMB_PREVIEW_REQUEST: "/investment.termDepositCMB.preview",
    TERM_DEPOSIT_CMB_SEND_REQUEST: "/investment.termDepositCMB.send",
    TERM_DEPOSIT_GET_CUSTOM_RATE: "/investment.termDeposit.customRate",
};

export const TYPE = FactoryType(NAME, [
    "CANCEL_CUSTOM_TRANSACTION_REQUEST",
    "CLOSE_CHANGE_DATE_MODAL",
    "CLOSE_TRANSACTION_PREVIEW",
    "CURRENCY_HAS_CHANGED_REQUEST",
    "FROM_RISK_PROFILE_QUIZ",
    "MY_BRIEFCASE_PRE_FAILURE",
    "MY_BRIEFCASE_PRE_REQUEST",
    "MY_BRIEFCASE_PRE_SUCCESS",
    "OPEN_CHANGE_DATE_MODAL",
    "READ_TRANSACTION_REQUEST",
    "READ_TRANSACTION_SUCCESS",
    "REQUEST_INFO_CLOSE_PREVIEW",
    "REQUEST_INFO_PRE_FAILURE",
    "REQUEST_INFO_PRE_REQUEST",
    "REQUEST_INFO_PRE_SUCCESS",
    "REQUEST_INFO_PREVIEW_FAILURE",
    "REQUEST_INFO_PREVIEW_REQUEST",
    "REQUEST_INFO_PREVIEW_SUCCESS",
    "REQUEST_INFO_SEND_FORM",
    "REQUEST_INFO_SEND_FORM_FAILURE",
    "REQUEST_INFO_SEND_FORM_SUCCESS",
    "RESTART_FLOW",
    "RISK_PROFILE_DOWNLOAD_PDF_FAILURE",
    "RISK_PROFILE_DOWNLOAD_PDF_REQUEST",
    "RISK_PROFILE_DOWNLOAD_PDF_SUCCESS",
    "RISK_PROFILE_READ_LAST",
    "RISK_PROFILE_READ_LAST_FAILURE",
    "RISK_PROFILE_READ_LAST_SUCCESS",
    "RISK_PROFILE_SEND_QUESTIONS_FAILURE",
    "RISK_PROFILE_SEND_QUESTIONS_REQUEST",
    "RISK_PROFILE_SEND_QUESTIONS_SUCCESS",
    "SIGN_TRANSACTION_FAILURE",
    "SIGN_TRANSACTION_PREVIEW",
    "SIGN_TRANSACTION_REQUEST",
    "TERM_DEPOSIT_PRE_FAILURE",
    "TERM_DEPOSIT_PRE_REQUEST",
    "TERM_DEPOSIT_PRE_SUCCESS",
    "TERM_DEPOSIT_PREVIEW_FAILURE",
    "TERM_DEPOSIT_PREVIEW_REQUEST",
    "TERM_DEPOSIT_PREVIEW_SUCCESS",
    "TERM_DEPOSIT_SEND_FAILURE",
    "TERM_DEPOSIT_SEND_REQUEST",
    "TERM_DEPOSIT_SEND_SUCCESS",
    "TERM_DEPOSIT_TO_STEP_ONE",
    "TERM_DEPOSIT_CUSTOM_RATE_FAILURE",
    "TERM_DEPOSIT_CUSTOM_RATE_REQUEST",
    "TERM_DEPOSIT_CUSTOM_RATE_SUCCESS",
]);

export const PROP = {};

PROP.members = {};

PROP.types = {
    ...TypesRedux,
    availableForInvestChart: PropTypes.array,
    availableForInvesting: PropTypes.object,
    fetching: PropTypes.bool,
    fundPapers: PropTypes.array,
    hasProducts: PropTypes.bool,
    isFromRiskProfileQuiz: PropTypes.bool,
    myPortfolioTotal: PropTypes.number,
    notifications: PropTypes.object,
    termDeposit: PropTypes.object,
    requestInfoData: PropTypes.object,
    riskProfile: PropTypes.object,
    showChangeDateModal: PropTypes.bool,
    tempFormData: PropTypes.object,
    transaction: PropTypes.object,
    transactionList: PropTypes.array,
};

PROP.defaults = {
    ...DefaultsRedux,
    availableForInvestChart: [],
    availableForInvesting: {},
    currencyHasChanged: false,
    fetching: false,
    fundPapers: [],
    hasProducts: false,
    isFromRiskProfileQuiz: false,
    myPortfolioTotal: 0,
    notifications: { documentsExpiredOrAboutToExpire: [], notificationsQuantity: 0, productsAboutToExpire: [] },
    termDeposit: {
        accountList: [],
        currencies: [],
        formData: {},
        instructions: [],
        marketRate: {},
        mode: MODE.EDIT,
    },
    requestInfoData: {
        mode: MODE.EDIT,
        submitData: {},
    },
    riskProfile: {},
    showChangeDateModal: false,
    tempFormData: {},
    transaction: {},
    transactionList: [],
};
