import React from "react";

import PropTypes from "prop-types";

import FieldLabel from "~/pages/_components/fields/FieldLabel";

import FormField from "~/pages/forms/_components/_fields/_commons/formField";

export const NAME = "Intro";

export const PROP = {
    types: {
        lang: PropTypes.string.isRequired,
        hintMap: PropTypes.objectOf(PropTypes.string).isRequired,
        editing: PropTypes.bool.isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const { hintMap, lang, editing } = props;

    if (!editing) {
        return null;
    }

    return (
        <React.Fragment>
            {hintMap && (
                <div className="form-group">
                    <FieldLabel labelText={hintMap[lang]} />
                </div>
            )}
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default FormField({ pureRender: true })(Component);
