import React from "react";

import PropTypes from "prop-types";

import { SelectorsAction as SelectorsActionTicket } from "~/store/administration/common/administrationTicket";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";

import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        suppliersDisplayList: PropTypes.array.isRequired,
        isSubmitting: PropTypes.bool,
    },
    defaults: {
        isSubmitting: false,
    },
};

export function Component(props) {
    const { isSubmitting, dispatch, transactionData } = props;

    return (
        <section id={Style.ID}>
            <section className="message">
                <span>{UtilsI18n.get("administration.ticket.previewMessage")}</span>
            </section>
            <div className="buttonContainer">
                <Button size="sm" variant="secondary" disabled={isSubmitting} onClick={handleCancel}>
                    <I18n id="global.cancel" />
                </Button>
                <Button size="sm" variant="primary" disabled={isSubmitting} onClick={handleSign}>
                    <I18n id="global.send" />
                </Button>
            </div>
        </section>
    );

    function handleSign() {
        dispatch(
            SelectorsActionTicket.signAdministrativeTransactionRequest({
                idActivity: transactionData.idActivity,
                idTransaction: transactionData.idTransaction,
                ticketData: transactionData.data,
            }),
        );
    }

    function handleCancel() {
        dispatch(SelectorsActionTicket.cancelPreviewStep());
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
