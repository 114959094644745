import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/Filter";
import {
    SelectorsAction as SelectorsActionSuppliers,
    SelectorsStore as SelectorsStoreSuppliers,
} from "~/store/factoring";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Style from "./List.rules.scss";
import Filters from "./_Filters";
import Suppliers from "./_Suppliers";

export const { NAME, NAME_SUPPLIERS } = Style;

export const PROP = {
    types: {
        isChangingEnvironment: PropTypes.bool,
        fetching: PropTypes.bool.isRequired,
        ...TypesRedux,
    },
    defaults: {
        isChangingEnvironment: false,
        ...DefaultsRedux,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        const { dispatch, firstTime, isChangingEnvironment } = this.props;
        if (!firstTime && !isChangingEnvironment) {
            dispatch(SelectorsActionSuppliers.loadListSuppliersRequest({ moreOrders: false, firstTime: true }));
        }
    }

    handleBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    render() {
        const { fetching, i18n } = this.props;
        const { listSuppliersSubTitle } = i18n;

        return (
            <React.Fragment>
                <Container
                    wait={fetching}
                    name={NAME_SUPPLIERS}
                    head-onBack={this.handleBack}
                    head-title="menu.factoring.suppliers"
                    image="suppliers.svg"
                    scopeToShowNotification={SCOPE.SUPPLIERS}>
                    <section>
                        <Filters />
                        <Suppliers
                            container-title={listSuppliersSubTitle}
                            container-role="list"
                            name={NAME_SUPPLIERS}
                        />
                    </section>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    fetching: SelectorsStoreSuppliers.isFetching(store),
    firstTime: SelectorsStoreSuppliers.getFirstTime(store),
    i18n: {
        listSuppliersSubTitle: StoreI18n.getMessage(store, "client.factoring.suppliers.list.subTitle"),
    },
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
});

export default Connect(mapStateToProps)(Component);
