/* global window */

import React from "react";

import { push as Push } from "connected-react-router";
import { withFormik as WithFormik, Form } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { Redirect } from "react-router-dom";

import {
    ERROR_MESSAGE,
    ERROR_TITLE,
    INVALID_DOC_AFFILIATE,
    IO_ERROR,
    IP_NOT_AUTHORIZED,
    LANGUAGE,
    MSG_RETURN_CODE_PREFIX,
    NO_CREDITCARDS_ENABLED_FOR_PAY,
} from "~/constants";
import { E_SSL_HANDSHAKE } from "~/store/apiMobile";
import { SelectorsAction as LoginActions } from "~/store/login";
import { SelectorsAction as SelectorsActionSession, SelectorsStore as SelectorsStoreSession } from "~/store/session";
import UtilsDevice from "~/util/device";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import Modal from "~/components/Modal";

export const NAME = "Error";

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        hasActiveSession: PropTypes.bool,
        location: PropTypes.shape({
            code: PropTypes.string,
            message: PropTypes.string,
        }).isRequired,
    },
    defaults: { hasActiveSession: false },
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        lang: LANGUAGE.ES,
    };

    componentDidMount() {
        window.common.hideSplashScreen();
        // TODO:ver como hacer para tomar el idioma en el navegador
        if (UtilsDevice.isMobileNative()) {
            document.addEventListener("deviceready", this.onDeviceReady, false);
        }

        const $wait = document.getElementById("root-wait");
        if ($wait) {
            $wait.parentElement.removeChild($wait);
        }
    }

    componentWillUnmount() {
        if (UtilsDevice.isMobileNative()) {
            document.removeEventListener("deviceready", this.onDeviceReady);
        }
    }

    onDeviceReady = () => {
        navigator.globalization.getPreferredLanguage(
            (language) => {
                const langNative = language.value.split("-").shift();
                this.setState({ lang: langNative });
            },
            () => this.setState({ lang: LANGUAGE.ES }),
        );
    };

    handleClickGoToSafe = () => {
        const { dispatch, hasActiveSession, location } = this.props;
        switch (location.code) {
            case IO_ERROR: // IO_ERROR
                dispatch(LoginActions.reset());
                break;
            case E_SSL_HANDSHAKE:
            case IP_NOT_AUTHORIZED: // IP_NOT_AUTHORIZED
                dispatch(SelectorsActionSession.logout());
                break;
            default:
                break;
        }

        let path = "/";
        if (hasActiveSession) {
            // En caso de que de error y tenga la sessionActiva se pasa el fetching a false
            dispatch(SelectorsActionSession.error());
            path = "/desktop";
        }

        if (location.code !== E_SSL_HANDSHAKE) {
            dispatch(Push(path));
        } else {
            UtilsDevice.forcedAppExit();
        }
    };

    getErrorButtonLabel = (code) => {
        if (code === E_SSL_HANDSHAKE) {
            return "global.close";
        }

        const errorBack = "error.back";
        const errkey = `${errorBack}.${code}`;
        return UtilsI18n.get(errkey) === `*${errkey}*` ? errorBack : errkey;
    };

    getErrorMessage = (code, message, lang) => {
        const msgKey = `${MSG_RETURN_CODE_PREFIX}.${code}`;
        let errorMessage = message || UtilsI18n.get(msgKey);
        if (!errorMessage || errorMessage === `*${msgKey}*`) {
            errorMessage = UtilsI18n.get("error.default");
            if (!errorMessage || errorMessage === "*error.default*") {
                errorMessage = ERROR_MESSAGE[lang];
            }
        }
        return errorMessage;
    };

    renderCustomMessage = (code, message, lang) => {
        const errorMessage = this.getErrorMessage(code, message, lang);
        const errorMessageHint = UtilsI18n.get(`${MSG_RETURN_CODE_PREFIX}.${code}.hint`);
        const errorButtonLabel = this.getErrorButtonLabel(code);
        return (
            <Modal show>
                <div className="modal-text">
                    <h2>{errorMessage}</h2>
                    {errorMessageHint && (
                        <p className="text-lead">
                            <span>{errorMessageHint}</span>
                        </p>
                    )}
                </div>
                <div className="modal-buttons">
                    <Form className="login-form">
                        <div className="login-form-field button-field">
                            <Button
                                bsStyle="secondary"
                                type="submit"
                                label={errorButtonLabel}
                                onClick={this.handleClickGoToSafe}
                            />
                        </div>
                    </Form>
                </div>
            </Modal>
        );
    };

    renderErrorWithHtml = (errorMessage, code) => {
        return (
            <p className="text-left">
                <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: errorMessage,
                    }}
                />
                <span className="code-span"> ({code})</span>
            </p>
        );
    };

    render() {
        const { location } = this.props;
        const { lang } = this.state;

        const { code, message, description } = location;

        if (!code) {
            return <Redirect to="/desktop" />;
        }

        if (NO_CREDITCARDS_ENABLED_FOR_PAY === code) {
            return this.renderCustomMessage(code, message, description);
        }

        let errorTitle = UtilsI18n.get("error.title");
        if (!errorTitle || errorTitle === "*error.title*") {
            errorTitle = ERROR_TITLE[lang];
        }

        const errorMessage = this.getErrorMessage(code, message);

        const errorButtonLabel = this.getErrorButtonLabel(code);

        const renderWithHtml = INVALID_DOC_AFFILIATE === code;

        return (
            <Modal show>
                <div className="modal-text">
                    <h2>{errorTitle}</h2>

                    {renderWithHtml ? (
                        this.renderErrorWithHtml(errorMessage, code)
                    ) : (
                        <p className="text-lead">
                            <span>{errorMessage}</span>
                            <span className="code-span"> ({code})</span>
                        </p>
                    )}
                    {description && (
                        <p className="text-lead">
                            <span>{description}</span>
                        </p>
                    )}
                </div>
                <div className="modal-buttons">
                    <Form className="login-form">
                        <div className="login-form-field button-field">
                            <Button
                                bsStyle="secondary"
                                type="submit"
                                label={errorButtonLabel}
                                onClick={this.handleClickGoToSafe}
                            />
                        </div>
                    </Form>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (store) => ({
    hasActiveSession: SelectorsStoreSession.isLoggedIn(store),
});

export default HighOrder(
    Connect(mapStateToProps),
    HighOrder.Resizable,
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({}),
        validationSchema: () => ({}),
        handleSubmit: () => {},
    }),
)(Component);
