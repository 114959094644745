import PropTypes from "prop-types";

import { FactoryType } from "~/util/redux";

export const NAME = "aboutIbanca";

export const TYPE = FactoryType(NAME, ["SHOW_ABOUT_IBANCA_MODAL"]);

export const PROP = {
    types: {
        showAboutIbancaModal: PropTypes.bool,
    },
    defaults: {
        showAboutIbancaModal: false,
    },
};
