import React from "react";

import { replace as Replace } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import Container from "~/containers/Internal/Form/Simple";
import { SelectorsAction as SelectorsActionSettings, SelectorsStore as SelectorsStoreSettings } from "~/store/settings";

import I18n from "~/components/I18n";

import SettingsOption from "~/pages/settings/_components/SettingsOption";

import Style from "./NotificationsConfiguration.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        communicationTypes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
        fetching: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    },
    defaults: {
        communicationTypes: null,
        fetching: false,
    },
};

export function Component(props) {
    const { fetching, dispatch } = props;

    React.useEffect(() => {
        dispatch(SelectorsActionSettings.notificationsConfigurationPre());
    }, [dispatch]);

    return (
        <Container
            name={Style.NAME}
            className={Style.CLASS}
            head-title="settings.notificationsConfiguration"
            wait={fetching}
            head-onBackWeb={handleClose}
            head-onClose={handleClose}>
            {getContent()}
        </Container>
    );

    function handleClose() {
        const pathname = "/settings";
        dispatch(
            Replace({
                pathname,
            }),
        );
    }

    function renderCommunicationTypes() {
        const { communicationTypes } = props;
        return communicationTypes.map((commType) => {
            const [key] = Object.entries(commType)[0];
            return (
                <SettingsOption
                    linkTo={`/settings/notificationsConfiguration/${key}`}
                    messageKey={`communications.communicationsTypes.${key}`}
                />
            );
        });
    }

    function getContent() {
        const { communicationTypes } = props;
        if (!communicationTypes) {
            return undefined;
        }

        return (
            <section>
                <p>
                    <I18n id="settings.notificationsConfiguration.title" />
                </p>
                <ul className="navigational-list">{renderCommunicationTypes()}</ul>
            </section>
        );
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (state) => ({
    communicationTypes: SelectorsStoreSettings.getCommunicationTypes(state),
    fetching: SelectorsStoreSettings.getFetching(state),
});

export default Connect(mapStateToProps)(Component);
