import { PROP } from "./_consts";

export default (TYPE) => (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.LOAD_CHANNELS_REQUEST:
        case TYPE.UPDATE_CHANNELS_REQUEST_SUCCESS:
            return {
                ...storeProp,
                fetching: true,
            };

        case TYPE.LOAD_CHANNELS_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.LOAD_CHANNELS_SUCCESS: {
            const { data, labelCurrency, enabledChannelsFrequencies } = action;
            return {
                ...storeProp,
                caps: data.caps.reduce(
                    (caps, { channel, maximum, frequency }) => ({
                        ...caps,
                        [channel]: {
                            amount: maximum,
                            currency: data.currency,
                            frequency,
                        },
                    }),
                    {},
                ),
                currencies: [
                    {
                        id: data.currency,
                        value: data.currency,
                        label: labelCurrency,
                    },
                ],
                topAmount: data.topAmount,
                nonRemovableChannels: data.nonRemovableChannels || [],
                enabledChannels: data.enabledChannels
                    ? data.enabledChannels.filter((channel) => channel !== "all")
                    : [],
                enabledChannelsFrequencies: data.enabledChannelsFrequencies && enabledChannelsFrequencies,
                fetching: false,
            };
        }
        case TYPE.UPDATE_CHANNELS_PREVIEW_SUCCESS: {
            const {
                caps: { topAmount, ...caps },
                credentialGroups,
                idTransaction,
                idActivity,
            } = action;

            return {
                ...storeProp,
                caps: Object.entries(caps).reduce(
                    (capsWithCurrencies, [cap, values]) => ({
                        ...capsWithCurrencies,
                        [cap]: { ...values, currency: storeProp.currencies[0].value },
                    }),
                    {},
                ),
                topAmount,
                credentialGroups,
                idTransaction,
                idActivity,
            };
        }

        case TYPE.LOAD_CHANNELS_TICKET_REQUEST:
            return {
                ...storeProp,
                fetchingTicket: true,
            };
        case TYPE.LOAD_CHANNELS_TICKET_FAILURE:
        case TYPE.LOAD_CHANNELS_TICKET_SUCCESS:
            return {
                ...storeProp,
                fetchingTicket: false,
            };
        case TYPE.LOAD_FORM_SUCCESS: {
            const { listFrequencies, data } = action;
            return {
                ...storeProp,
                fetching: false,
                enabledChannelsFrequencies: listFrequencies,
                topAmount: data.topAmount,
            };
        }
        default:
            return { ...storeProp };
    }
};
