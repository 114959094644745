import React from "react";

import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { EMPTY_STR, TRIP_OR_PURCHASE } from "~/constants";
import { STATUS } from "~/constants/transaction";
import {
    SelectorsAction as SelectorsActionCreditCards,
    SelectorsStore as SelectorsStoreCreditCards,
} from "~/store/creditCards/creditCard";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { specificDate as SpecificDate } from "~/util/date";
import * as UtilsI18n from "~/util/i18n";

import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Token from "~/components/Token/Token";
import TicketPrintInformation from "~/pages/_components/TicketPrintInformation";

import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        isSubmitting: PropTypes.bool,
    },
    defaults: {
        isSubmitting: false,
    },
};

const loadData = (props) => {
    const { tripNoticeData, location, transaction } = props;
    const isFromTransactionsHistory = location.pathname.includes("/ticket");

    if (isFromTransactionsHistory && transaction?.idTransactionStatus === STATUS.PENDING) {
        const { data } = transaction;
        return {
            limitCurrency: data.currency,
            newLimit: data.amount,
            alias: data.alias,
            limitType: data.limitType,
            incrementToday: data.beginDate,
            incrementDueDate: data.endDate,
        };
    }
    const { formData, accountVisaMaster } = tripNoticeData;

    return formData.find((account) => account.visaMasterAccount === accountVisaMaster);
};

export function Component(props) {
    const { isSubmitting, transaction, tripNoticeData } = props;
    const selectedAccount = loadData(props);
    const { alias } = selectedAccount;
    const { beginDate, creditCardList, countries, endDate, operationType, store } = tripNoticeData || transaction.data;
    const isTravelNotice = operationType === TRIP_OR_PURCHASE.TRAVEL_NOTICE;
    const isOnlinePurchase = operationType === TRIP_OR_PURCHASE.ONLINE_PURCHASE_NOTICE;

    return (
        <Form id={Style.ID}>
            <TicketPrintInformation />
            <React.Fragment>
                <section className="justify-content-center">
                    <I18n className="text-lead" id="forms.transferInternal.confirmation.title" />
                    <div className="notice-block">
                        <div className="notice-data notice-data-highlight">
                            <I18n
                                className="data-label"
                                id={`creditCards.tripOrOnlinePurchaseNotification.step2.title.${operationType}`}
                            />
                            <span className="data-account">
                                <I18n
                                    className="data-label"
                                    id="creditCards.tripOrOnlinePurchaseNotification.step2.selectedAccount"
                                />
                                <span className="alias">{alias}</span>
                            </span>
                        </div>
                        <div className="notice-data notice-data-flow">
                            <div className="data-wrapper align-end">
                                <div>
                                    <span className="data-label">
                                        <I18n
                                            className="data-label"
                                            id="creditCards.tripOrOnlinePurchaseNotification.step2.selectedCards"
                                        />
                                    </span>
                                </div>
                                <div className="container-cardList data-flex-column">
                                    {creditCardList.map((creditCard) => (
                                        <div className="data-flex-column cc-container">
                                            <span className="data-name label-highligh">
                                                {UtilsI18n.get(`client.creditcard.type.label.${creditCard.bin}`)} (
                                                {creditCard.number})
                                            </span>
                                            <span className="data-name">{creditCard.owner}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <Image src="arrowRight.svg" className="svg-icon" />
                            <div className="data-wrapper align-start">
                                <div className="data-label">
                                    <div className="date data-flex-column">
                                        {isTravelNotice && (
                                            <I18n
                                                id="creditCards.tripOrOnlinePurchaseNotification.step2.date.travel"
                                                beginDate={SpecificDate(beginDate)}
                                                endDate={SpecificDate(endDate)}
                                                className="head"
                                            />
                                        )}
                                        {isOnlinePurchase && (
                                            <I18n
                                                id="creditCards.tripOrOnlinePurchaseNotification.step2.date.onlinePurchase"
                                                beginDate={SpecificDate(beginDate)}
                                                endDate={SpecificDate(endDate)}
                                                className="head"
                                            />
                                        )}
                                        <I18n
                                            id="creditCards.tripOrOnlinePurchaseNotification.step2.date"
                                            beginDate={SpecificDate(beginDate)}
                                            endDate={SpecificDate(endDate)}
                                            className="mb-3"
                                        />
                                    </div>
                                    {isTravelNotice && (
                                        <I18n
                                            className="notice-label"
                                            id="creditCards.tripOrOnlinePurchaseNotification.step2.label.countries"
                                        />
                                    )}
                                    {isTravelNotice &&
                                        countries.map((country) => (
                                            <div className="container-data data-flex-column">
                                                <span className="data-name">{country}</span>
                                            </div>
                                        ))}
                                    {isOnlinePurchase && (
                                        <div className="container-data store data-flex-column">
                                            <I18n
                                                className="notice-label"
                                                id="creditCards.tripOrOnlinePurchaseNotification.step2.label.store"
                                            />
                                            <span className="data-name">{store}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Token labelButton="global.send" isSubmitting={isSubmitting} />
            </React.Fragment>
        </Form>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        tripNoticeData: SelectorsStoreCreditCards.getTripNoticeFormData(store),
        transaction: SelectorsStoreCreditCards.getTransaction(store),
        user: SelectorsStoreSession.getUser(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithRouter,
    WithFormik({
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        validationSchema: (props) => {
            const { user } = props;

            return Yup.object().shape({
                otp: user.needsBiometric
                    ? Yup.string().nullable()
                    : Yup.string().required(UtilsI18n.get("form.credential.otp.required")),
            });
        },
        handleSubmit: (props, formikBag) => {
            const { dispatch, history, tripNoticeData, transaction } = formikBag.props;
            const { idActivity, idTransaction } = transaction || EMPTY_STR;
            const isFromTransactionsHistory = history.location.pathname.includes("/ticket");
            const { otp } = props;

            if (isFromTransactionsHistory) {
                dispatch(
                    SelectorsActionCreditCards.signTransaction({
                        idForm: null,
                        idActivity,
                        idTransaction,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            } else {
                const {
                    accountVisaMaster,
                    beginDate,
                    checkboxes,
                    countries,
                    countryCodes,
                    creditCardList,
                    endDate,
                    formData,
                    operationType,
                    store,
                } = tripNoticeData;

                const selectedAccount = formData.find((account) => account.visaMasterAccount === accountVisaMaster);

                const data = {
                    accountVisaMaster: selectedAccount.visaMasterAccount,
                    alias: selectedAccount.alias,
                    beginDate,
                    brand: selectedAccount.visaMasterBrand,
                    checkboxes,
                    countries,
                    countryCodes,
                    creditCardList,
                    endDate,
                    idCreditCard: selectedAccount.idProduct,
                    operationType,
                    store,
                };

                dispatch(
                    SelectorsActionCreditCards.tripOrOnlinePurchaseNotificationSendRequest({
                        formData: data,
                        otp,
                        formikBag,
                    }),
                );
            }
        },
    }),
)(Component);
