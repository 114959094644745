import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, SPACE_STR } from "~/constants";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import { SelectorsStore as SelectorsStoreComexBondsAndGuarantees } from "~/store/comex/bondsAndGuarantees";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import DataDate from "~/components/DataDate";
import I18n from "~/components/I18n";
import Ticket from "~/components/Ticket/Ticket";
import FormattedAmount from "~/pages/_components/FormattedAmount";

import { EMISSION_TYPE } from "../Step1Edit/Step1Edit";
import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const { dispatch, ticketConfirmation, transaction } = props;
    const { data } = transaction;

    const {
        dueDate,
        emissionType,
        increment,
        invoiceAmount,
        invoiceAmountCurrency,
        item,
        oldAmount,
        operationNumber,
        otherModifications,
        pledgeOfDeposit,
        recipient,
    } = data;

    const invoiceAmountData = invoiceAmount?.amount ? invoiceAmount.amount : invoiceAmount;
    const invoiceAmountCurrencyData =
        invoiceAmountCurrency || (item?.othersFields ? item.othersFields.MONEDA : EMPTY_STR);
    const oldAmountData = oldAmount || (item?.othersFields ? item.othersFields.IMPORTE : EMPTY_STR);
    const recipientData = recipient || (item?.othersFields ? item.othersFields.BENEFICIARIO : EMPTY_STR);

    const shareTicket = (idTransactionToRead) => {
        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    };

    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}
                    handleModify={handleModify}
                    hasMobileComponent
                    displayDownloadPDF
                    handleDownloadPDF={() => shareTicket(transaction.idTransaction)}>
                    <Box flex directionColumn align="flex-start" className="mb-3">
                        <Box className="opening">
                            <I18n
                                id="comex.bondsAndGuarantees.modifyBondsAndGuarantees.step2.opening"
                                tag="p"
                                className="mt-3"
                            />
                        </Box>
                        <Box flex className="info-label">
                            <h4 className="bold">
                                {UtilsI18n.get(
                                    "comex.bondsAndGuarantees.modifyBondsAndGuarantees.step2.operationNumber",
                                )}
                            </h4>
                            <span>{operationNumber}</span>
                        </Box>
                        <Box flex className="info-label">
                            <h4 className="bold">
                                {UtilsI18n.get("comex.bondsAndGuarantees.modifyBondsAndGuarantees.step2.amount")}
                            </h4>
                            <FormattedAmount
                                currency={invoiceAmountCurrencyData}
                                quantity={parseFloat(oldAmountData)}
                            />
                        </Box>
                        <Box flex cclassName="info-label">
                            <h4 className="bold">
                                {UtilsI18n.get("comex.bondsAndGuarantees.modifyBondsAndGuarantees.step2.recipient")}
                            </h4>
                            <span>{recipientData}</span>
                        </Box>

                        <Box className="opening">
                            <I18n
                                id="comex.bondsAndGuarantees.modifyBondsAndGuarantees.step2.content"
                                tag="p"
                                className="mt-3"
                            />
                        </Box>
                        {dueDate && (
                            <Box flex className="info-label">
                                <I18n id="comex.bondsAndGuarantees.modifyBondsAndGuarantees.step2.dueDate" tag="p" />
                                <DataDate value={dueDate} />
                            </Box>
                        )}
                        {invoiceAmount && (
                            <Box flex className="info-label">
                                <I18n
                                    id={`comex.bondsAndGuarantees.modifyBondsAndGuarantees.step2.invoiceAmount.${increment}`}
                                    tag="p"
                                />
                                {SPACE_STR}
                                <FormattedAmount currency={invoiceAmountCurrencyData} quantity={invoiceAmountData} />
                            </Box>
                        )}
                        {emissionType === EMISSION_TYPE.PLEDGE_OF_DEPOSIT && (
                            <Box flex className="info-label">
                                <I18n
                                    id="comex.bondsAndGuarantees.modifyBondsAndGuarantees.step2.emissionType"
                                    tag="p"
                                />
                                <span>
                                    <I18n
                                        id={`comex.bondsAndGuarantees.modifyBondsAndGuarantees.step2.emissionType.${emissionType}`}
                                        tag="p"
                                    />
                                </span>
                            </Box>
                        )}
                        {pledgeOfDeposit && (
                            <Box flex className="info-label">
                                <I18n
                                    id="comex.bondsAndGuarantees.modifyBondsAndGuarantees.step2.pledgeOfDeposit"
                                    tag="p"
                                />
                                <span>{pledgeOfDeposit}</span>
                            </Box>
                        )}
                        {otherModifications && (
                            <Box flex className="info-label">
                                <I18n
                                    id="comex.bondsAndGuarantees.modifyBondsAndGuarantees.step2.otherModifications"
                                    tag="p"
                                />
                                <span>{otherModifications}</span>
                            </Box>
                        )}
                        <I18n
                            id="comex.bondsAndGuarantees.modifyBondsAndGuarantees.step2.terms"
                            tag="p"
                            className="mt-3"
                        />
                    </Box>
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { idTransaction } = transaction;

        dispatch(
            SelectorsActionComex.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                formikBag,
                idTransaction,
            }),
        );
    }

    function handleSign() {
        dispatch(SelectorsActionComex.signTransactionPreview({ ...data }));
    }

    function handleModify() {
        const { idTransaction } = transaction;
        dispatch(SelectorsActionForm.modifyTransaction({ idTransactionToMove: idTransaction }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreComexBondsAndGuarantees.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
