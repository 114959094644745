import React from "react";

import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import PropTypes from "prop-types";
import { FilePond, registerPlugin as RegisterPlugin } from "react-filepond";
import { connect as Connect } from "react-redux";
import "filepond/dist/filepond.min.css";

import { DOT, EMPTY_STR } from "~/constants";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import {
    SelectorsAction as SelectorsActionMultilineFile,
    SelectorsStore as SelectorsStoreMultilineFile,
} from "~/store/multilinefile";
import * as UtilsI18n from "~/util/i18n";
import { ServerOptions } from "~/util/multilineFile";

RegisterPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

export const NAME = "FileUploader";

export const PROP = {
    types: {
        description: PropTypes.string,
        forcePondCleanUp: PropTypes.bool,
        idActivity: PropTypes.string,
        idForm: PropTypes.string,
        idFormField: PropTypes.string,
        ignoreLimbo: PropTypes.bool,
        onAddFile: PropTypes.func,
        onRemoveFile: PropTypes.func,
    },
    defaults: {
        description: null,
        forcePondCleanUp: false,
        idActivity: null,
        idForm: null,
        idFormField: null,
        ignoreLimbo: false,
        onAddFile: null,
        onRemoveFile: null,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        debitCurrency: EMPTY_STR,
        files: [],
        idDebitAccount: EMPTY_STR,
        filesDefaultLoaded: false,
    };

    pondRef = React.createRef();

    static getDerivedStateFromProps(nextProps, prevState) {
        const { formExtraData } = nextProps;
        const { debitCurrency, idDebitAccount } = formExtraData || {};
        const { debitCurrency: stateDebitCurrency, idDebitAccount: stateIdDebitAccount } = prevState;

        let hasChange = false;
        let newState = prevState;
        if (debitCurrency !== stateDebitCurrency) {
            newState = {
                ...newState,
                debitCurrency,
            };
            hasChange = true;
        }
        if (idDebitAccount !== stateIdDebitAccount) {
            newState = {
                ...newState,
                idDebitAccount,
            };
            hasChange = true;
        }

        return hasChange ? newState : null;
    }

    componentDidMount() {
        const { files, ignoreLimbo } = this.props;
        const { filesDefaultLoaded } = this.state;

        if (files && files.length > 0 && !ignoreLimbo && !filesDefaultLoaded) {
            this.setState({ filesDefaultLoaded: true }, () => {
                files.forEach((element) => {
                    this.pondRef.current.addFile(element, {
                        type: "limbo",
                    });
                });
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { files, ignoreLimbo } = this.props;
        const { dispatch, forcePondCleanUp } = prevProps;
        const { files: filesState, filesDefaultLoaded } = this.state;

        // When there is an uploaded file, it has a generated fileId
        const hasFile = filesState.length > 0 && filesState[0].fileId;
        if (forcePondCleanUp) {
            dispatch(SelectorsActionMultilineFile.cleanPond({ forcePondCleanUp: false }));

            if (hasFile) {
                this.cleanFiles(true);
            }
        }

        // When there is an uploaded file which couldn't be processed, it has a generated fileId as NaN
        if (Number.isNaN(hasFile)) {
            this.cleanFiles(true);
        }

        if (files && files.length > 0 && !ignoreLimbo && !filesDefaultLoaded) {
            this.setState({ filesDefaultLoaded: true }, () => {
                files.forEach((element) => {
                    this.pondRef.current.addFile(element, {
                        type: "limbo",
                    });
                });
            });
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        const { files } = this.state;
        const hasFile = files.length > 0 && files[0].fileId;

        this.cleanFiles(false);

        if (hasFile) {
            dispatch(SelectorsActionForm.switchMassivePaymentsToManual());
        }

        this.pondRef.current = null;
    }

    shouldComponentUpdate(nextProps) {
        const { formExtraData } = nextProps;
        const { debitCurrency, idDebitAccount } = formExtraData || {};
        const { debitCurrency: stateDebitCurrency, files, idDebitAccount: stateIdDebitAccount } = this.state;

        if (debitCurrency !== stateDebitCurrency || idDebitAccount !== stateIdDebitAccount) {
            return true;
        }

        if (nextProps.files && files) {
            return nextProps.files.length !== files.length;
        }

        return true;
    }

    onProcessFile = (error, fileItem) => {
        const { onAddFile } = this.props;

        if (!error && fileItem) {
            const { files } = this.props;
            const file = {
                fileId: parseInt(fileItem.serverId, 10),
                fileName: fileItem.filename,
                fileSize: fileItem.fileSize,
                fileType: fileItem.fileType,
            };

            this.setState({ files: [...files, file] }, () => {
                if (onAddFile) {
                    onAddFile(file);
                }
            });
        }
    };

    onRemoveFile = ({ serverId }) => {
        const { onRemoveFile, idRelatedFile, dispatch } = this.props;
        const { files } = this.state;

        this.setState({ files: files.filter((file) => file.fileId !== serverId) }, () => {
            if (onRemoveFile) {
                onRemoveFile(parseInt(serverId, 10));
            }
        });

        const idFile = parseInt(serverId, 10);
        if (idFile) {
            dispatch(SelectorsActionMultilineFile.deleteFileRequest({ idFile }));
        }
        const _idRelatedFile = parseInt(idRelatedFile, 10);
        if (_idRelatedFile) {
            dispatch(SelectorsActionMultilineFile.deleteFileRequest({ idFile: _idRelatedFile }));
        }

        return true;
    };

    getLabels = () => ({
        labelIdle: UtilsI18n.get("file.upload.input.labelIdle"),
        labelFileWaitingForSize: UtilsI18n.get("file.upload.input.labelFileWaitingForSize"),
        labelFileSizeNotAvailable: UtilsI18n.get("file.upload.input.labelFileSizeNotAvailable"),
        labelFileLoading: UtilsI18n.get("file.upload.input.labelFileLoading"),
        labelFileLoadError: UtilsI18n.get("file.upload.input.labelFileLoadError"),
        labelFileProcessing: UtilsI18n.get("file.upload.input.labelFileProcessing"),
        labelFileProcessingComplete: UtilsI18n.get("file.upload.input.labelFileProcessingComplete"),
        labelFileProcessingAborted: UtilsI18n.get("file.upload.input.labelFileProcessingAborted"),
        labelFileProcessingError: UtilsI18n.get("file.upload.input.labelFileProcessingError"),
        labelTapToCancel: UtilsI18n.get("file.upload.input.labelTapToCancel"),
        labelTapToRetry: UtilsI18n.get("file.upload.input.labelTapToRetry"),
        labelTapToUndo: UtilsI18n.get("file.upload.input.labelTapToUndo"),
        labelButtonRemoveItem: UtilsI18n.get("file.upload.input.labelButtonRemoveItem"),
        labelButtonAbortItemLoad: UtilsI18n.get("file.upload.input.labelButtonAbortItemLoad"),
        labelButtonRetryItemLoad: UtilsI18n.get("file.upload.input.labelButtonRetryItemLoad"),
        labelButtonAbortItemProcessing: UtilsI18n.get("file.upload.input.labelButtonAbortItemProcessing"),
        labelButtonUndoItemProcessing: UtilsI18n.get("file.upload.input.labelButtonUndoItemProcessing"),
        labelButtonRetryItemProcessing: UtilsI18n.get("file.upload.input.labelButtonRetryItemProcessing"),
        labelButtonProcessItem: UtilsI18n.get("file.upload.input.labelButtonProcessItem"),
        labelMaxFileSizeExceeded: UtilsI18n.get("file.upload.input.labelMaxFileSizeExceeded"),
        labelMaxFileSize: UtilsI18n.get("file.upload.input.labelMaxFileSize"),
        labelMaxTotalFileSizeExceeded: UtilsI18n.get("file.upload.input.labelTotalFileSizeExceeded"),
        labelMaxTotalFileSize: UtilsI18n.get("file.upload.input.labelMaxTotalFileSize"),
        labelFileTypeNotAllowed: UtilsI18n.get("file.upload.input.labelFileTypeNotAllowed"),
        fileValidateTypeLabelExpectedTypes: UtilsI18n.get("file.upload.input.fileValidateTypeLabelExpectedTypes"),
    });

    cleanFiles(forceFileRemoval) {
        const { setValue } = this.props;

        if (forceFileRemoval) {
            this.pondRef.current.removeFiles();
        }

        this.setState({ debitCurrency: EMPTY_STR, files: [], idDebitAccount: EMPTY_STR });

        if (setValue) {
            setValue([]);
        }
    }

    render() {
        const {
            accessToken,
            idActivity,
            idForm,
            idFormField,
            description,
            files,
            onFileProcess,
            labelValidation,
            ...rest
        } = this.props;
        const { debitCurrency, idDebitAccount } = this.state;

        let labels = this.getLabels();
        if (labelValidation) {
            labels = { ...labels, ...labelValidation };
        }

        const params = {
            __debitCurrency: debitCurrency,
            __idDebitAccount: idDebitAccount,
            description,
            idActivity,
            idForm,
            idFormField,
        };
        const serverOptions = ServerOptions(params, this.onRemoveFile, onFileProcess);

        return (
            <FilePond
                {...labels}
                {...rest}
                allowFileSizeValidation
                beforeRemoveFile={this.onRemoveFile}
                onprocessfile={this.onProcessFile}
                fileValidateTypeDetectType={(file, type) =>
                    new Promise((resolve) => {
                        const fileExtension = `${file.name.split(DOT).slice(-1)}`;
                        const formattedType = fileExtension.toLocaleLowerCase() === "rar" ? "application/x-rar" : type;

                        resolve(formattedType);
                    })
                }
                onupdatefiles={(fileItems) => {
                    this.setState({
                        files: fileItems.map((fileItem) => fileItem.file),
                    });
                }}
                ref={this.pondRef}
                server={serverOptions}
            />
        );
    }
}

const mapStateToProps = (store) => ({
    forcePondCleanUp: SelectorsStoreMultilineFile.pondHasToBeCleaned(store),
    formExtraData: SelectorsStoreMultilineFile.getFormExtraData(store),
    idRelatedFile: SelectorsStoreMultilineFile.getIdRelatedFile(store),
});

export default Connect(mapStateToProps)(Component);
