import React from "react";

import { node, string } from "prop-types";

// eslint-disable-next-line import/no-cycle
import AccordionItem from "~/pages/_components/AccordionItem";

export const AccordionContext = React.createContext();

export const NAME = "Accordion";

export const PROP = {
    types: {
        children: node.isRequired,
        className: string,
    },
    defaults: { className: "navigational-list" },
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    static Item = AccordionItem;

    state = {
        itemOpen: null,
    };

    handleClick = (number) => {
        const { itemOpen } = this.state;

        if (number === itemOpen) {
            this.setState({ itemOpen: null });
        } else {
            this.setState({ itemOpen: number });
        }
    };

    render() {
        const { className, children } = this.props;
        const { itemOpen } = this.state;
        return (
            <AccordionContext.Provider value={{ itemOpen, onClick: this.handleClick }}>
                <ul className={className}>{children}</ul>
            </AccordionContext.Provider>
        );
    }
}

export default Component;
