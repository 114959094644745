import React from "react";

import { compose as Compose } from "redux";

import { EMPTY_STR } from "~/constants";
import { PROP as STORE_PROP } from "~/store/preferentialTradingPrice";
import * as UtilsI18n from "~/util/i18n";

import Select from "~/components/Select";
import FieldError from "~/pages/_components/fields/FieldError";
import WithFocus from "~/pages/_components/withFocus";

import Style from "./AccountSelectors.rules.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {
        ...STORE_PROP.accountSelectors,
    },
    defaults: {
        ...STORE_PROP.defaultsAccountsSelectors,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {};

    inputRefBuying = React.createRef();

    inputRefSelling = React.createRef();

    handleChangeAccount = (account) => {
        if(account){
            const { handleChangeAccount, handleBlurError, fieldName } = this.props;
            handleChangeAccount(account);
            handleBlurError(fieldName);
        }
    };

    handleBlur = () => {
        const { toggleIsFocused, handleBlurError, fieldName } = this.props;
        toggleIsFocused();
        handleBlurError(fieldName);
    };

    customFilter(option, searchText) {
        if (
            option.accountAlias.toLowerCase().includes(searchText.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
      }

    render() {
        const { accountValue, fieldName, errors, message, isFocused, toggleIsFocused, newOptionsAccount } = this.props;
        const productId = accountValue && accountValue.value ? accountValue.value : accountValue;
        // Muestra los errores si el preview devolvio alguno y si el campo no ha sido "tocado"
        const hasError =
            !isFocused && errors !== null && !errors[fieldName.concat("Touched")] ? errors[fieldName] : false;
        return (
            <div
                className={`form-group ${hasError ? "has-error" : EMPTY_STR} ${
                    isFocused ? "has-focus" : EMPTY_STR
                } ${CLASS}`}>
                <div className="form-group-text">
                    <label className="control-label">{UtilsI18n.get(message)}</label>
                </div>
                <div>
                    <div className="input-group">
                        <Select
                            value={productId}
                            clearable={false}
                            onChange={this.handleChangeAccount}
                            onFocus={toggleIsFocused}
                            onBlur={this.handleBlur}
                            filterOption={this.customFilter}
                            valueKey="value"
                            labelKey="label"
                            options={newOptionsAccount}
                            className="flex-container slideFromBottom"
                        />
                    </div>
                    {hasError && <FieldError error={errors[fieldName]} />}
                </div>
            </div>
        );
    }
}

export default Compose(WithFocus)(Component);
