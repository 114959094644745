import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SelectorsStore as SelectorsStoreSession } from "~/store/session";

import Image from "~/components/Image";
import FieldError from "~/pages/_components/fields/FieldError";

export const NAME = "Environments";

export const PROP = {
    types: {
        environments: PropTypes.objectOf(PropTypes.object).isRequired,
        activeEnvironment: PropTypes.shape({
            id: PropTypes.number,
        }),
        field: PropTypes.shape({
            name: PropTypes.string,
        }),
        form: PropTypes.shape({
            setFieldValue: PropTypes.func.isRequired,
            errors: PropTypes.objectOf(PropTypes.string), // TODO: Determine errors type
        }).isRequired,
    },
    defaults: {
        activeEnvironment: {},
        field: null,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    handleClick = (value) => {
        const { field, form } = this.props;
        form.setFieldValue(field.name, value);
    };

    render() {
        const {
            environments: environmentsObject,
            field,
            form: { errors },
            user: { defaultEnvironmentId },
        } = this.props;

        const environments = Object.entries(environmentsObject);

        return (
            <div className={`form-group ${errors[field.name] ? "has-error" : ""}`}>
                <div className="form-check-group selection-list">
                    {environments.map(([id, environment]) => (
                        <div className="c-control c-control--radio" key={id}>
                            <input
                                defaultChecked={Number(id) === defaultEnvironmentId}
                                id={`environment-${id}`}
                                type="radio"
                                name="environment"
                                className="c-control-input"
                                onClick={() => this.handleClick(id)}
                            />

                            <label className="c-control-label" htmlFor={`environment-${id}`}>
                                <Image src={`${environment.environmentType}.svg`} className="svg-icon" />
                                {environment.name}
                            </label>
                        </div>
                    ))}
                </div>
                {errors[field.name] && (
                    <div className="text-center">
                        <FieldError error={errors[field.name]} />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (store) => ({
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
    user: SelectorsStoreSession.getUser(store),
});

export default Connect(mapStateToProps)(Component);
