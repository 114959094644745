import React from "react";

import PropTypes from "prop-types";
import { Grid, Row, Col } from "react-bootstrap";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";

import { SPACE_STR, EMPTY_STR } from "~/constants";
import { MSG_KEY } from "~/constants/form";
import {
    PROP as STORE_PROP,
    OPERATION_TYPE,
    SelectorsStore as SelectorsStorePreferencialTradingPrice,
} from "~/store/preferentialTradingPrice";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import { getInteger as GetInteger } from "~/util/config";
import DeviceUtils from "~/util/device";
import * as UtilsI18n from "~/util/i18n";
import * as UtilsNumber from "~/util/number";
import { requestReview as RequestReview } from "~/util/requestReview";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import NumberFormatInput from "~/components/NumberFormatInput";
import TicketPrintInformation from "~/pages/_components/TicketPrintInformation";

import FormSignatures from "~/pages/forms/_components/FormSignatures";
import TransactionStatus from "~/pages/transactions/_components/TransactionStatus";

import { CLASS } from "../PreferentialTradingPrice.rules.scss";
import Style from "./ConfirmationFX.rules.scss";

export const NAME = "PreferentialTradingPrice";

export const PROP = {
    types: {
        ...STORE_PROP.types.sendData,
        dispatch: PropTypes.func.isRequired,
        isFromBackoffice: PropTypes.bool,
        transaction: PropTypes.shape({
            executing: PropTypes.bool,
            idTransaction: PropTypes.string,
            idTransactionStatus: PropTypes.string,
            valueDateAsString: PropTypes.string,
        }).isRequired,
    },
    defaults: {
        ...STORE_PROP.defaultsSendData,
        dispatch: null,
        isFromBackoffice: false,
        transaction: null,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {
        showDetails: true,
    };

    handleClick = () => {
        this.setState(({ showDetails }) => ({ showDetails: !showDetails }));

        this.shouldDisplayTicketCollapsible();
    };

    shareTicket = (idTransactionToRead) => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    };

    printPage = () => {
        window.print();
    };

    shouldDisplayTicketCollapsible = () => {
        const { isTransaction } = this.props;
        const { showDetails } = this.state;

        if ((isTransaction && !showDetails) || (!isTransaction && showDetails)) {
            return false;
        }

        return "ticket-hide";
    };

    renderTicket() {
        const { minimumDecimals, transaction, history } = this.props;

        const { from: locationFrom = EMPTY_STR } = history.location?.state || {};

        const isMobile = DeviceUtils.isDisplayMobile();

        const { data = {}, idTransaction, idTransactionStatus, valueDateAsString, idActivity } = transaction;

        const {
            creditAccount,
            creditAccountLabel,
            creditAmount,
            debitAccount,
            debitAccountLabel,
            debitAmount,
            operationType,
            quotation,
        } = data;

        const rateCurrency1 =
            operationType === OPERATION_TYPE.BANK_IS_BUYING ? creditAmount?.currency : debitAmount?.currency;
        const rateCurrency2 =
            operationType === OPERATION_TYPE.BANK_IS_BUYING ? debitAmount?.currency : creditAmount?.currency;

        const info = {
            sourceAmount: debitAmount?.quantity,
            sourceAccount: debitAccountLabel || debitAccount,
            sourceCurrency: UtilsI18n.get(`currency.label.${debitAmount?.currency}`),
            destinationCurrency: UtilsI18n.get(`currency.label.${creditAmount?.currency}`),
            destinationAmount: creditAmount?.quantity,
            destinationAccount: creditAccountLabel || creditAccount,
            quotationCurrency1: UtilsI18n.get(`currency.label.${rateCurrency1}`),
            quotationCurrency2: UtilsI18n.get(`currency.label.${rateCurrency2}`),
            quotation,
        };

        const { decimalSeparator, thousandSeparator } = UtilsNumber.numberFormat(UtilsI18n.getLang());

        let decimalPlaces;
        let decimalScale;

        if (info.quotation) {
            decimalPlaces = UtilsNumber.countDecimalPlaces(info?.quotation, decimalSeparator);
            decimalScale = Math.max(decimalPlaces, minimumDecimals);
        }

        const isTicketFromFlow =
            history.location.pathname.includes("/ticket") && !locationFrom.includes("/transactions/list");

        RequestReview(DeviceUtils.isMobileNative(), idActivity, idTransactionStatus, isTicketFromFlow);

        return (
            <div id={Style.ID}>
                {transaction.idTransaction && (
                    <React.Fragment>
                        <section className="container--layout align-items-center flex-grow">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                        <TicketPrintInformation />
                                        <div className="media-object ">
                                            <figure>
                                                {idTransactionStatus && (
                                                    <TransactionStatus idTransactionStatus={idTransactionStatus} />
                                                )}
                                            </figure>
                                            <div className="media-object--text">
                                                <p className="text-lead">
                                                    <I18n
                                                        id={`forms.transaction.ticket.status.${idTransactionStatus}`}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Grid>
                        </section>

                        <section
                            className={`container--layout align-items-center  ${
                                isMobile && this.shouldDisplayTicketCollapsible()
                            }`}>
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12 data-section">
                                        <h3 className="data-title">
                                            <I18n id="forms.transaction.ticket" />
                                        </h3>
                                        {DeviceUtils.isMobileNative() && (
                                            <a className="share-button" onClick={() => this.shareTicket(idTransaction)}>
                                                <Image src="share.svg" className="svg-icon" />
                                            </a>
                                        )}
                                        <div className="data-wrapper">
                                            <span className="data-label">
                                                <I18n id="forms.transaction.ticket.idTransaction" />
                                            </span>
                                            <span className="data-name">{idTransaction}</span>
                                        </div>
                                        <div className="data-wrapper">
                                            <span className="data-label">
                                                <I18n id="forms.transaction.ticket.date" />
                                            </span>
                                            <span className="data-date">{valueDateAsString}</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="justify-content-center">
                                    <div xl="6" className="col col-12 col-lg-6 col-md-9 col-sm-12 data-section">
                                        <div className="data-wrapper">
                                            <span className="data-label">
                                                <I18n id="form.fx.debitAccount" />
                                            </span>
                                            <span className="data-name">{info.sourceAccount}</span>
                                        </div>
                                        <div className="data-wrapper">
                                            <span className="data-label">
                                                <I18n id="form.fx.debitAmount" />
                                            </span>
                                            <span className="data-name">
                                                {info.sourceCurrency}
                                                {SPACE_STR}
                                                {UtilsNumber.numberToLocaleFormat(
                                                    info.sourceAmount,
                                                    UtilsI18n.getLang(),
                                                )}
                                            </span>
                                        </div>
                                        <div className="data-wrapper">
                                            <span className="data-label">
                                                <I18n id="form.fx.creditAccount" />
                                            </span>
                                            <span className="data-name">{info.destinationAccount}</span>
                                        </div>
                                        <div className="data-wrapper">
                                            <span className="data-label">
                                                <I18n id="form.fx.creditAmount" />
                                            </span>
                                            <span className="data-name">
                                                {info.destinationCurrency}
                                                {SPACE_STR}
                                                {UtilsNumber.numberToLocaleFormat(
                                                    info.destinationAmount,
                                                    UtilsI18n.getLang(),
                                                )}
                                            </span>
                                        </div>
                                        <div className="data-wrapper">
                                            <span className="data-label">
                                                <I18n id="form.fx.typeOfCotization" />
                                            </span>
                                            <span className="data-name">
                                                <NumberFormatInput
                                                    decimalSeparator={decimalSeparator}
                                                    thousandSeparator={thousandSeparator}
                                                    decimalScale={decimalScale}
                                                    value={info?.quotation}
                                                    displayType="text"
                                                    renderText={EMPTY_STR}
                                                    fixedDecimalScale
                                                />
                                                {SPACE_STR}
                                                {info.quotationCurrency1}
                                                {SPACE_STR}
                                                <I18n id="client.preferentialTradingPrice.by" />
                                                {SPACE_STR}
                                                {info.quotationCurrency2}
                                            </span>
                                        </div>
                                    </div>
                                </Row>

                                <Row className="justify-content-center">
                                    <FormSignatures transaction={transaction} />
                                </Row>
                            </Grid>
                            {!DeviceUtils.isMobileNative() && (
                                <Grid className="form-content">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12 ">
                                        <div className="buttonsTicketConfirmation">
                                            <a onClick={() => this.printPage()}>
                                                <I18n id="forms.ticket.print" />
                                            </a>
                                        </div>
                                    </Col>
                                </Grid>
                            )}
                        </section>

                        {isMobile && (
                            <section className="container--layout align-items-center">
                                <Grid className="form-content">
                                    <Row className="justify-content-center">
                                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                            <Button
                                                className="btn-secondary"
                                                label={
                                                    this.shouldDisplayTicketCollapsible() ? MSG_KEY.MORE : MSG_KEY.LESS
                                                }
                                                onClick={this.handleClick}
                                            />
                                        </Col>
                                    </Row>
                                </Grid>
                            </section>
                        )}
                    </React.Fragment>
                )}
            </div>
        );
    }

    render() {
        const { isFromBackoffice } = this.props;

        return (
            <React.Fragment>
                {isFromBackoffice && <section className={CLASS}>{this.renderTicket()}</section>}
                {!isFromBackoffice && this.renderTicket()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        isTransaction: SelectorsStorePreferencialTradingPrice.stepTransaction(store),
        maximumDecimals: GetInteger("defaultDecimal.maximum"),
        minimumDecimals: GetInteger("defaultDecimal.minimum"),
        transaction: SelectorsStorePreferencialTradingPrice.getTransaction(store),
    };
};

export default HighOrder(Connect(mapStateToProps), WithRouter)(Component);
