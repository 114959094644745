import React from "react";

import { PROP as STORE_PROP, OPERATIONS as STORE_OPERATIONS } from "~/store/preferentialTradingPrice";

import I18n from "~/components/I18n";

import Style from "./BuyingAndSelling.rules.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {
        ...STORE_PROP.buyingAndSelling,
    },
    defaults: {
        ...STORE_PROP.defaultBuyingAndSelling,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {
        classSelected: true,
    };

    inputRefBuying = React.createRef();

    inputRefSelling = React.createRef();

    // TODO: Setting the state here will trigger re-rendering, this isn't a correct approach,fix this.
    componentDidMount() {
        const { operation, changeOperation } = this.props;
        this.setState({ classSelected: operation === STORE_OPERATIONS.SELLING });
        if (changeOperation) {
            this.handleSelling();
        }
    }

    handleSelling = () => {
        const { handleSelling } = this.props;
        handleSelling();
        this.setState({
            classSelected: true,
        });
    };

    handleBuying = () => {
        const { handleBuying } = this.props;
        handleBuying();
        this.setState({
            classSelected: false,
        });
    };

    render() {
        const { classSelected } = this.state;
        const { buyingRateClient, sellingRateClient, currencyLabel, operation, sellingCurrencyLabel } = this.props;
        let idSale = "";
        let idBuy = "";
        if (operation === STORE_OPERATIONS.BUYING) {
            // TODO:Hay que ver como se soluciona esto con UI
            idSale = "operation";
            idBuy = "operationSelected";
        } else {
            idSale = "operationSelected";
            idBuy = "operation";
        }

        return (
            <div className={`purchase-sale ${CLASS}`}>
                <div
                    id={idSale}
                    className={`white_container ${classSelected ? "selected" : ""}`}
                    role="button"
                    tabIndex="0"
                    onClick={this.handleSelling}
                    onKeyPress={this.handleSelling}
                    ref={this.inputRefSelling}>
                    <p id="pOperacionVenta">
                        <I18n id="form.fx.operacionVenta" /> {currencyLabel}
                    </p>
                    <p className="currencyLabel">
                        {sellingCurrencyLabel} <b>{sellingRateClient} </b>
                    </p>
                </div>

                <div
                    id={idBuy}
                    className={`white_container ${!classSelected ? "selected" : ""}`}
                    role="button"
                    tabIndex="0"
                    onClick={this.handleBuying}
                    onKeyPress={this.handleBuying}
                    ref={this.inputRefBuying}>
                    <p id="pOperacionCompra">
                        <I18n id="form.fx.operacionCompra" /> {currencyLabel}
                    </p>
                    <p className="currencyLabel">
                        {sellingCurrencyLabel} <b>{buyingRateClient} </b>
                    </p>
                </div>
            </div>
        );
    }
}

export default Component;
