import React from "react";

import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import * as ConfigUtil from "~/util/config";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import FileUploader from "~/pages/_components/FileUploader";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";

export const NAME = "FileUploadSuppliers";

export const PROP = {
    types: {
        ...TypesRedux,
    },
    defaults: {
        ...DefaultsRedux,
    },
};

const ACTIVITY_ID = "preferences.modifyuser.configuration.execute";
const FIELD_ID = "files";
const MAX_FILE_SIZE_MB = ConfigUtil.get("client.accounts.attachments.maxFileSize") || 2;
const ACCEPTED_FILE_TYPES = [
    "application/vnd.oasis.opendocument.text",
    "application/vnd.oasis.opendocument.spreadsheet",
    "application/vnd.oasis.opendocument.presentation",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/pdf",
    "application/zip",
    "application/x-rar",
    "application/x-zip-compressed",
    "application/vnd.rar",
    "application/txt",
    "text/plain",
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/tiff",
    "image/tif",
    "image/gif",
    "image/bmp",
];

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    handleFileProcess = (response) => {
        const { form } = this.props;
        const { data } = response;
        form.setFieldValue(FIELD_ID, data);
    };

    render() {
        const {
            value,
            setValue,
            form,
            idForm,
            field: { name },
            tooltip,
            label,
        } = this.props;
        const hasError = form?.errors[name]?.idFile ? "fileError" : "";
        return (
            <React.Fragment>
                <div className="fileTooltip">
                    <FieldLabel labelKey={`${idForm}.${FIELD_ID}.label`} />
                    {label && (
                        <div className="field-files--information">
                            <span>{UtilsI18n.get(`change.PersonalData.${name}.information`)}</span>
                        </div>
                    )}
                    <div className={hasError}>
                        <FileUploader
                            name={name}
                            idActivity={ACTIVITY_ID}
                            idForm=""
                            idFormField={name}
                            files={value}
                            allowMultiple={false}
                            maxFileSize={`${MAX_FILE_SIZE_MB}mb`}
                            maxTotalFileSize={`${MAX_FILE_SIZE_MB}mb`}
                            maxFiles={1}
                            acceptedFileTypes={ACCEPTED_FILE_TYPES}
                            onFileProcess={this.handleFileProcess}
                            labelValidation={{
                                fileValidateTypeLabelExpectedTypes: UtilsI18n.get(
                                    "change.PersonalData.filesLegal.type",
                                ),
                            }}
                            labelIdle={UtilsI18n.get(`client.change.PersonalData.${name}.label`)}
                            onRemoveFile={() => {
                                form.setFieldValue(FIELD_ID, EMPTY_STR);
                            }}
                            setValue={setValue}
                        />
                    </div>

                    <div className="">
                        {tooltip && (
                            <div className="tooltip">
                                <span className="tooltip-text"> {tooltip}</span>
                            </div>
                        )}
                    </div>
                </div>
                <FieldError error={form?.errors[name]?.idFile} />
            </React.Fragment>
        );
    }
}

export default Connect(null)(Component);
