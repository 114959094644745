import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SelectorsStore as SelectorsStorePermissions } from "~/store/administration/common/permissions";
import * as AdministrationUtils from "~/util/administration";

import Accordion from "~/pages/_components/Accordion";
import AccordionItem from "~/pages/_components/AccordionItem";
import ProductLabelIcon from "~/pages/_components/ProductLabelIcon";

import PermissionsAmount from "~/pages/administration/_components/PermissionsAmount";

export const NAME = "PermissionsList";

export const PROP = {
    types: {
        permissions: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
        products: PropTypes.arrayOf(
            PropTypes.shape({
                idProduct: PropTypes.string,
                number: PropTypes.string,
                productAlias: PropTypes.string,
                label: PropTypes.string,
            }),
        ).isRequired,
        groups: AdministrationUtils.groupsPropType.isRequired,
        children: PropTypes.func.isRequired,
    },
    defaults: { permissions: {} },
};

export function Component(props) {
    const renderProducts = (option) => {
        const { permissions: permissionsFromProps, products: productFromProps } = props;

        return (
            <React.Fragment>
                {productFromProps.reduce((products, { idProduct, number, productAlias, label }) => {
                    const permissions = permissionsFromProps[option.idItem];

                    if (permissions && !permissions.includes(idProduct)) {
                        return products;
                    }

                    return [
                        ...products,
                        <li className="permission-item" key={idProduct}>
                            <div className="list-item-inner">
                                <div
                                    style={{
                                        display: "inline-flex",
                                    }}
                                    className="product-label">
                                    <ProductLabelIcon number={number} />
                                    <div className="product-label-text">
                                        <div className="product-label-name">{productAlias || label}</div>
                                        {productAlias && <div className="product-label-info">{label}</div>}
                                    </div>
                                </div>
                            </div>
                        </li>,
                    ];
                }, [])}
            </React.Fragment>
        );
    };

    const renderItem = (option) => (
        <li className="list--permissions-item-one-option" key={option.idItem}>
            <div className="list-item-inner-one">{option.label}</div>
        </li>
    );

    const loadOptions = (childrenList) =>
        childrenList.reduce((categoryOptions, option) => {
            if (option.childrenList && option.childrenList.length) {
                const subOptions = loadOptions(option.childrenList);

                if (!subOptions.length) {
                    return categoryOptions;
                }

                return [
                    ...categoryOptions,
                    <li className="permission-item" key={option.idItem}>
                        <div className="list-item-inner">
                            <span className="navigational-list-subtitle">{option.label}</span>
                        </div>
                        <ul className="list">{subOptions}</ul>
                    </li>,
                ];
            }

            const { permissions: permissionsFromProps } = props;

            const permissions = permissionsFromProps[option.idItem] || [];

            if (permissions.length) {
                const [permission] = option.permissionList || [];

                return [
                    ...categoryOptions,
                    permission && permission.simpleAllowProductSelection ? (
                        <li className="permission-item" key={option.idItem}>
                            <div className="list-item-inner">
                                <span className="navigational-list-subtitle">{option.label}</span>
                            </div>
                            {renderProducts(option)}
                        </li>
                    ) : (
                        renderItem(option)
                    ),
                ];
            }

            return categoryOptions;
        }, []);

    const { children, groups, permissions = {} } = props;
    return (
        <PermissionsAmount permissions={permissions}>
            {(amountsById, totalAmountsById) => {
                if (!Object.keys(amountsById).length) {
                    return children();
                }

                return children(
                    <Accordion className="list--permissions">
                        {groups.reduce((mainCategories, option) => {
                            const { childrenList, idItem, label, permissionList = [] } = option;
                            const categoryOptions = loadOptions(childrenList);
                            const optionsAmount = amountsById[idItem];
                            const totalOptionsAmount = totalAmountsById[idItem];
                            const [permission] = permissionList;
                            const quantityText = `${optionsAmount} / ${totalOptionsAmount} `;

                            if ((permission && permission.simpleAllowProductSelection) || categoryOptions.length) {
                                if (permission && permission.simpleAllowProductSelection && !optionsAmount) {
                                    return mainCategories;
                                }

                                return [
                                    ...mainCategories,
                                    <AccordionItem
                                        key={idItem}
                                        number={mainCategories.length}
                                        item={
                                            <div>
                                                <div className="arrow-class" />
                                                <span className="list-item-label">{label}</span>
                                                <span className="list-item-hint">{quantityText}</span>
                                            </div>
                                        }>
                                        {permission && permission.simpleAllowProductSelection ? (
                                            renderProducts(option)
                                        ) : (
                                            <React.Fragment>{categoryOptions}</React.Fragment>
                                        )}
                                    </AccordionItem>,
                                ];
                            }

                            if (!optionsAmount) {
                                return mainCategories;
                            }

                            return [...mainCategories, renderItem(option)];
                        }, [])}
                    </Accordion>,
                );
            }}
        </PermissionsAmount>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    products: SelectorsStorePermissions.getProducts(store),
    groups: SelectorsStorePermissions.getGroups(store),
});

export default Connect(mapStateToProps)(Component);
