import { push as Push } from "connected-react-router";
import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE } from "~/constants";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionSession } from "~/store/session";
import * as I18nUtils from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction } from "./_selectors";

export default [
    TakeLatest(TYPE.GENERAL_CONDITIONS_PRE_REQUEST, function* generalConditionsPre() {
        const response = yield Call(SelectorsMiddleware.generalConditionsPre, {});

        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: I18nUtils.get(response.data.data.NO_FIELD),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.GENERAL_CONDITIONS_LOGIN],
                }),
            );

            yield Put(SelectorsAction.errorGeneralConditionPre());
        } else {
            const { body, latestNews, idCondition } = response.data.data;
            yield Put(
                SelectorsAction.successGeneralConditionPre({
                    body,
                    latestNews,
                    idCondition,
                }),
            );
        }
    }),

    TakeLatest(TYPE.GENERAL_CONDITIONS_SIGN_REQUEST, function* generalConditionsSign(props) {
        const { formikBag, acceptConditions } = props;
        const { environment, rememberEnvironment, suggestMigration } = formikBag.props;
        if (acceptConditions) {
            const { idCondition, isEnvironmentChanging } = formikBag.props;
            const response = yield Call(SelectorsMiddleware.generalConditionsSign, { idCondition });

            if (response.type === RESPONSE_TYPE.WARNING) {
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: I18nUtils.get(response.data.data.NO_FIELD),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.GENERAL_CONDITIONS_LOGIN],
                    }),
                );
                yield Put(SelectorsAction.errorGeneralConditionSign());
            } else if (isEnvironmentChanging) {
                yield Put(
                    SelectorsActionSession.changeEnvironment({
                        idEnvironment: environment,
                        rememberEnvironment,
                        formikBag,
                        shouldNavigate: true,
                    }),
                );
            } else {
                if (suggestMigration) {
                    yield Put(Push("/migrationSuggest"));
                } else {
                    yield Put(Push("/desktop"));
                }
                yield Put(SelectorsAction.successGeneralConditionSign());
            }
        } else {
            yield Put(SelectorsAction.errorGeneralConditionSign());
            const error = "login.step4.acceptConditions.required";
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: I18nUtils.get(error),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.GENERAL_CONDITIONS_LOGIN],
                }),
            );
        }
    }),
];
