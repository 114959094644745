import { LOCATION_CHANGE } from "connected-react-router";

import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    cancelTransaction: (props) => API.executeWithAccessToken(PATH.CORE_CANCEL_TRANSACTION, props),
    listCredentialsGroups: (props) => API.executeWithAccessToken(PATH.CORE_LIST_TRANSACTION_CREDENTIALS_GROUPS, props),
    moveToDraftTransaction: (props) => API.executeWithAccessToken(PATH.CORE_MOVE_TO_DRAFT_TRANSACTION, props),
    preview: (props, idActivity) => API.executeWithAccessToken(`/${idActivity}`, props),
    readForm: (props) => API.executeWithAccessToken(PATH.CORE_FORMS_READ, props),
    readTransaction: (props) => API.executeWithAccessToken(PATH.CORE_READ_TRANSACTION, props),
    saveDraft: (props) => API.executeWithAccessToken(PATH.CORE_SAVE_DRAFT_TRANSACTION, props),
    saveTemplate: (props) => API.executeWithAccessToken(PATH.CORE_CREATE_TRANSACTION_TEMPLATE, props),
    send: (props, idActivity) => API.executeWithWebToken(`/${idActivity}`, props),
    sign: (props, idActivity) => API.executeWithWebToken(`/${idActivity}`, props),
    signPreview: (props, idActivity) => API.executeWithAccessToken(`/${idActivity}`, props),
};

export const SelectorsAction = {
    cancelTransaction: (props) => FactoryAction(TYPE.CANCEL_TRANSACTION_REQUEST, props),
    cancelTransactionCustom: (props) => FactoryAction(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, props),
    cancelTransactionFailure: () => FactoryAction(TYPE.CANCEL_TRANSACTION_FAILURE),
    cancelTransactionPre: (props) => FactoryAction(TYPE.CANCEL_TRANSACTION_PRE_REQUEST, props),
    cancelTransactionPreError: () => FactoryAction(TYPE.CANCEL_TRANSACTION_PRE_ERROR),
    cancelTransactionPreSuccess: (props) => FactoryAction(TYPE.CANCEL_TRANSACTION_PRE_SUCCESS, props),
    cancelTransactionSuccess: () => FactoryAction(TYPE.CANCEL_TRANSACTION_SUCCESS),
    cleanAll: (props) => FactoryAction(TYPE.CLEAN_ALL, props),
    cleanSteps: (props) => FactoryAction(TYPE.CLEAN_STEPS, props),
    closeConfirmation: () => FactoryAction(TYPE.CLOSE_TRANSACTION_PREVIEW),
    dependeeChanged: (props) => FactoryAction(TYPE.DEPENDEE_CHANGED, props),
    formClosed: () => FactoryAction(TYPE.FORM_CLOSED),
    formDataReset: () => FactoryAction(TYPE.FORM_DATA_RESET),
    formDataSave: (props) => FactoryAction(TYPE.FORM_DATA_SAVE, props),
    fromBiometric: () => FactoryAction(TYPE.FROM_BIOMETRIC),
    isMobileTransaction: (props) => FactoryAction(LOCATION_CHANGE, props),
    modifyFormContent: (props) => FactoryAction(TYPE.CHANGE_FORM_METADATA, props),
    modifyTransaction: (props) => FactoryAction(TYPE.MODIFY_TRANSACTION_REQUEST, props),
    previewForm: (props) => FactoryAction(TYPE.PREVIEW_FORM_REQUEST, props),
    previewFormSuccess: (props) => FactoryAction(TYPE.PREVIEW_FORM_SUCCESS, props),
    readFormSuccess: (props) => FactoryAction(TYPE.READ_FORM_SUCCESS, props),
    readTransaction: (props) => FactoryAction(TYPE.READ_TRANSACTION_REQUEST, props),
    readTransactionFailure: (props) => FactoryAction(TYPE.READ_TRANSACTION_FAILURE, props),
    readTransactionSuccess: (props) => FactoryAction(TYPE.READ_TRANSACTION_SUCCESS, props),
    readformRequest: (props) => FactoryAction(TYPE.READ_FORM_REQUEST, props), // No hace nada en el reducer
    readformfailure: (props) => FactoryAction(TYPE.READ_FORM_FAILURE, props), // No hace nada en el reducer
    saveData: () => FactoryAction(TYPE.SAVE_DATA),
    saveDataOff: () => FactoryAction(TYPE.SAVE_DATA_OFF),
    saveDraft: (props) => FactoryAction(TYPE.SAVE_DRAFT_REQUEST, props),
    saveDraftFailure: () => FactoryAction(TYPE.SAVE_DRAFT_FAILURE),
    saveDraftSuccess: (props) => FactoryAction(TYPE.SAVE_DRAFT_SUCCESS, props), // El reducer no hace nada con los datos
    sendForm: (props) => FactoryAction(TYPE.SEND_FORM_REQUEST, props),
    sendFormCredentialFailure: (props) => FactoryAction(TYPE.SEND_FORM_CREDENTIAL_FAILURE, props),
    sendFormDataFailure: () => FactoryAction(TYPE.SEND_FORM_DATA_FAILURE),
    sendFormFail: () => FactoryAction(TYPE.SEND_FORM_FAIL),
    sendFormSuccess: (props) => FactoryAction(TYPE.SEND_FORM_SUCCESS, props),
    setData: (props) => FactoryAction(TYPE.SET_DATA, props),
    signTransaction: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_REQUEST, props),
    signTransactionPreview: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_PREVIEW_REQUEST, props),
    signTransactionPreviewFailure: () => FactoryAction(TYPE.SIGN_TRANSACTION_PREVIEW_FAILURE),
    signTransactionPreviewSuccess: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_PREVIEW_SUCCESS, props),
    suggestOtherLocalTransferOption: (props) => FactoryAction(TYPE.SUGGEST_OTHER_LOCAL_TRANSFER_OPTION, props),
    switchMassivePaymentsToManual: (props) => FactoryAction(TYPE.SWITCH_MASSIVE_PAYMENTS_TO_MANUAL, props),
    toggleModal: () => FactoryAction(TYPE.TOGGLE_MODAL),
    transactionRequestDelay: () => FactoryAction(TYPE.TRANSACTION_REQUEST_DELAY),
};

export const SelectorsStore = {
    getChildrenTransactions: (store) => store[NAME].childrenTransactions,
    getComeFrom: (store) => store[NAME].comeFrom,
    getCommission: (store) => store[NAME].commission,
    getCredentialsGroups: (store) => store[NAME].credentialsGroups,
    getData: (store) => store[NAME].data,
    getFetching: (store) => store[NAME].fetching,
    getField: (store, id) => store[NAME].metadata.fieldList.find(({ idField }) => idField === id),
    getFormDataBank: (store) => store[NAME].formData.bank,
    getFormDataCountry: (store) => store[NAME].formData.country,
    getFormDataSelect: (store) => store[NAME].formData.select,
    getId: (store) => store[NAME].id,
    getIsCancellingTransaction: (store) => store[NAME].isCancellingTransaction,
    getMetadata: (store) => store[NAME].metadata,
    getMode: (store) => store[NAME].mode,
    getName: (store) => store[NAME].name,
    getParentTransaction: (store) => store[NAME].parentTransaction,
    getPrevMode: (store) => store[NAME].prevMode,
    getPreviewData: (store) => store[NAME].previewData,
    getShouldKeepFormState: (store) => store[NAME].shouldKeepFormState,
    getSubmit: (store) => store[NAME].submit,
    getSubmitAction: (store) => store[NAME].submitAction,
    getSubmitActionParams: (store) => store[NAME].submitActionParams,
    getTransaction: (store) => store[NAME].transaction,
    getTransactionCredential: (store) => store[NAME].transactionCredential,
    getTransactionFormMetadata: (store) => store[NAME].metadata,
    getValues: (store) => store[NAME].values,
    getWaitingForResponse: (store) => store[NAME].waitingForResponse,
    showModal: (store) => store[NAME].showModal,
};
