import React from "react";

import Container from "~/containers/External/Form";
import { Types as TypesHtmlElement, Defaults as DefaultsHtmlElement } from "~/util/prop/html-element";

import I18n from "~/components/I18n";

export const NAME = "GeneralConditions";

export const PROP = {
    types: {
        ...TypesHtmlElement,
    },
    defaults: {
        ...DefaultsHtmlElement,
    },
};

export function Component(props) {
    const onHeaderClose = () => {
        const { history } = props;
        history.goBack();
    };
    return (
        <Container name={NAME} head-onBack={onHeaderClose} head-title="information.option.generalConditions">
            <div className="terms">
                <div className="terms-block">
                    <div className="contract-block-content">
                        <I18n id="termsAndConditions.body" />
                    </div>
                </div>
            </div>
            <div className="enrollment-content-dialog">
                <span className="enrollment-content-dialog-text" />
            </div>
        </Container>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
