import React from "react";

import PropTypes from "prop-types";

import UtilLogger from "~/util/logger";

import { ID } from "./index.module.scss";

export const NAME = "Components_TokenAnimation";

export const PROP = {
    types: {
        /** The text that will be displayed at the center of the animation. (optional, default: "") */
        token: PropTypes.string,
        /** Whether the component should be running or not. (required, default: true) */
        enabled: PropTypes.bool.isRequired,
        /** The amount of time in seconfd for the animation to last. (required, default: null) */
        duration: PropTypes.number.isRequired,
        /** Called everytime the interval reached a step.(optional, default: null) */
        onStep: PropTypes.func,
        /** Called everytime the interval reached completion.(optional, default: null) */
        onComplete: PropTypes.func,
        /** The decimal percentage that represents a full timer. (optional, default: 1) */
        full: (...params) => {
            const [props, propName] = params;
            const prop = parseFloat(props[propName]); // TODO: should validate, instead of parse.
            return prop < 0 || prop > 1 ? new Error("Expecting a number > 0 and <= 1", ...params) : null;
        },
        /** The number of steps the animation should have. (optional, default: 4) */
        steps: (...params) => {
            const [props, propName] = params;
            const prop = parseInt(props[propName], 10); // TODO: should validate, instead of parse.
            return prop < 1 ? new Error("Expecting a number >= 1", ...params) : null;
        },
    },
    defaults: {
        enabled: true,
        duration: null,
        onStep: null,
        onComplete: null,
        full: 1,
        steps: 4,
    },
};

// Original State.
export const STATE = {
    /** The amount of completion the interval has. `null` means 100%  */
    percentage: null,
    /** When the interval was last enabled. */
    date: null,
};

export class Component extends React.Component {
    /** How many milliseconds there are in a second */
    static SECOND = 1000;

    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    /** An interval that will be called {props.steps} times in {props.duration} seconds. */
    interval = null;

    state = STATE;

    componentDidMount() {
        const { enabled } = this.props;
        return enabled && this.handleEnable();
    }

    componentDidUpdate(prevProps) {
        const { enabled } = this.props;
        if (enabled === prevProps.enabled) {
            return undefined;
        }
        return enabled ? this.handleEnable() : this.handleDisable();
    }

    componentWillUnmount() {
        // Make sure everything gets cleaned-up after dismounting.
        this.handleDisable();
    }

    // -----------------------------------------------------------------------------------

    /** @return {number} props.duration converted to milliseconds. */
    getDuration = () => {
        const { duration } = this.props;
        return duration * Component.SECOND;
    };

    /** @return {number} the number of milliseconds each step lasts */
    getDurationStep = () => {
        const { steps } = this.props;
        const duration = this.getDuration();
        return Math.floor(duration / steps);
    };

    /** Sets the component to its default state */
    setStateReset = (callback) => this.setState(STATE, callback);

    /** Sets the date to current time, reseting the {since} countdown */
    setStateComplete = (callback, date = new Date()) => this.setState({ ...STATE, date, percentage: 0 }, callback);

    /** Sets percentage on state, default: initial percetage on state. */
    setStateStep = (callback, percentage = STATE.percentage) => this.setState({ percentage }, callback);

    /** Handles the disabling of the interval by clearing it and resetting the state */
    handleDisable = () => {
        clearInterval(this.interval);
        this.setStateReset();
    };

    /** Handles the enabling of the interval by determining its duration, setting it and reseting the state */
    handleEnable = () => {
        // return false;
        this.interval = setInterval(this.handleInterval, this.getDurationStep());
        this.setStateComplete(this.handleInterval);
    };

    /** Calculates how much time has passed since interval was enabled or stepped, calls corresponding handler. */
    handleInterval = () => {
        const { date } = this.state;
        const params = { now: new Date() };
        params.since = Math.abs(date - params.now);
        this.handleStep(params);
    };

    /** Sets the state to reflect the step the interval is currently on. */
    handleStep = (params) => {
        const { onStep, steps } = this.props;
        const { since } = params;
        const duration = this.getDuration();
        /** Times in milliseconds that each step falls into (minus one to avoid overflow)
         *  @type {[number]}
         *  @example 10s divided into 4 steps would be: [2499, 4999, 7499, 9999] */
        const times = [...Array(steps).keys()].map((n) => (n + 1) * (1 / steps) * duration - 1);
        /** Whats the closest step time right now?
         * @type {number} */
        const time = [0, ...times].reduce((pre, cur) => (Math.abs(cur - since) < Math.abs(pre - since) ? cur : pre));
        /** The integer percentage the closest {time} got, relative to {duration} */
        const percentage = Number((time / duration).toFixed(2)) * 100;
        if (percentage === 100) {
            return this.handleComplete(params);
        }
        return this.setStateStep(() => {
            UtilLogger.trace(`${NAME}:handleStep`, percentage);
            return onStep && onStep.call(this, params);
        }, percentage);
    };

    /** The interval has completed, trigger corresponding callback and reinitialize the state */
    handleComplete = (params) => {
        const { onComplete } = this.props;
        this.setStateComplete(() => {
            UtilLogger.trace(`${NAME}:handleComplete`);
            return onComplete && onComplete.call(this, params);
        }, params.now);
    };

    // -----------------------------------------------------------------------------------

    render() {
        const { percentage, hasCompleted } = this.state;
        const { enabled, steps, token } = this.props;
        const isEmpty = percentage === null;
        const animationDuration = `${Math.round(this.getDuration() / steps)}ms`;
        const className = [ID, enabled && !isEmpty && `${ID}-${percentage}`, !enabled && `${ID}-disabled`]
            .filter(Boolean) // removes all falsy values
            .join(" ");
        return (
            !(percentage === 0 && hasCompleted) && (
                <section className={className}>
                    <span>{token}</span>
                    {// creates an array with their index as value.
                    [...Array(steps).keys()].map((index) => (
                        <div key={index} style={{ animationDuration }} />
                    ))}
                </section>
            )
        );
    }
}

export default Component;
