import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreAdministration } from "~/store/administration/users/users";
import * as UtilsI18n from "~/util/i18n";
import { getUser } from "~/util/store/administration";

import Style from "./AdministrationHeading.rules.scss";

export const NAME = "AdministrationHeading";

export const PROP = {
    types: {
        userEnvInfo: PropTypes.shape({ status: PropTypes.string.isRequired }),
    },
    defaults: { userEnvInfo: {} },
};

export function Component(props) {
    const {
        user: { firstName, lastName, email },
        userEnvInfo: { status },
    } = props;

    return (
        <div className="container-head" id={Style.ID}>
            <div className="container-element">
                <span className="data-label">{UtilsI18n.get("administration.permissions.user")}</span>
                <span className="data-info">{`${firstName} ${lastName}`}</span>
            </div>
            <div className="container-element">
                <span className="data-label">{UtilsI18n.get("administration.permissions.email")}</span>
                <span className="data-info">{email}</span>
            </div>
            {status && (
                <div className="container-element">
                    <span className="data-label">{UtilsI18n.get("administration.permissions.status")}</span>
                    <span className={`status ${status === "blocked" ? "status-blocked" : EMPTY_STR}`}>
                        {UtilsI18n.get(`user.status.${status}`)}
                    </span>
                </div>
            )}
        </div>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    user: getUser(store),
    userEnvInfo: SelectorsStoreAdministration.getUserInfo(store, getUser(store)),
});

export default connect(mapStateToProps)(Component);
