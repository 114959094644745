import React from "react";

import { Field, Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import { EMPTY_STR } from "~/constants";
import { SelectorsAction as SelectorsActionAccount, SelectorsStore as SelectorsStoreAccount } from "~/store/account";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import * as UtilsConfig from "~/util/config";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";
import TextField from "~/pages/_components/fields/TextField";

import Namespace from "./Multicash.rules.scss";

export const { CLASS, NAME } = Namespace;

const FORM_ID = "accounts.movements.multicash";

export const PROP = {
    types: {
        ...TypesRedux,
        account: PropTypes.shape({}),
        i18n: PropTypes.object,
    },
    defaults: {
        ...DefaultsRedux,
        account: {
            idProduct: EMPTY_STR,
        },
        i18n: {
            accept: "Aceptar",
        },
    },
};
export function Component(props) {
    const { i18n } = props;

    return (
        <Form className={CLASS}>
            <I18n id="client.accounts.export.multicash.label" />
            <Field
                autoComplete="off"
                component={TextField}
                hidelabel
                idForm={FORM_ID}
                maxLength={UtilsConfig.getInteger("client.multicash.sequenceNumber.maxLength")}
                name="sequenceNumber"
                placeholder={EMPTY_STR}
            />

            <div className="form-group">
                <Button block type="submit" variant="primary">
                    {i18n.accept}
                </Button>
            </div>
        </Form>
    );
}

Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

const mapStateToProps = (store) => ({
    account: SelectorsStoreAccount.getSelectedAccount(store),
    i18n: {
        accept: StoreI18n.getMessage(store, "global.accept"),
    },
});

function createSchema() {
    return Yup.lazy(() =>
        Yup.object().shape({
            sequenceNumber: Yup.string()
                .trim()
                .matches(/^\d+$/, UtilsI18n.get(`${FORM_ID}.sequenceNumber.number.error`))
                .required(UtilsI18n.get(`${FORM_ID}.sequenceNumber.required.error`)),
        }),
    );
}

export default Compose(
    Connect(mapStateToProps),
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: () => ({
            sequenceNumber: EMPTY_STR,
        }),
        validationSchema: (props) => createSchema(props),
        handleSubmit: ({ sequenceNumber }, formikBag) => {
            const {
                account: { idProduct },
                dispatch,
                onSubmitForm,
            } = formikBag.props;

            dispatch(
                SelectorsActionAccount.downloadMovements({ idAccount: idProduct, format: "multicash", sequenceNumber }),
            );

            onSubmitForm();
        },
    }),
)(Component);
