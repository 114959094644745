import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE } from "~/constants";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction } from "./_selectors";

export default [
    TakeLatest(TYPE.GET_PHONE_AND_ACTIVE_REQUEST, function* handlePhoneAndActiveRequest(props) {
        const { id } = props;
        const response = yield Call(SelectorsMiddleware.purchaseNotificationGetPhoneAndActive, { idCreditCard: id });

        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.FORM],
                }),
            );
            yield Put(SelectorsAction.errorPurchaseNotificationGetPhoneAndActive());
        } else {
            yield Put(SelectorsAction.successPurchaseNotificationGetPhoneAndActive(response.data.data));
        }
    }),
];
