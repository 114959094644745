import CloneDeep from "lodash.clonedeep";
import Difference from "lodash.difference";
import DifferenceWith from "lodash.differencewith";
import FlowRight from "lodash.flowright";
import GroupBy from "lodash.groupby";
import IsEmpty from "lodash.isempty";
import IsEqual from "lodash.isequal";
import IsObject from "lodash.isplainobject";
import Omit from "lodash.omit";

export default {
    // -------------------------------------------------------------------------------
    /**
     * This method creates an object composed of the own and inherited enumerable property paths of object that are not
     * omitted
     * @param {Object} object - The source object.
     * @param {...(string|string[])} omitElements - The property paths to omit.
     * @returns {object} :Returns the new object.
     */
    omitElements(object, ...omitElements) {
        return Omit(object, omitElements);
    },
    // -------------------------------------------------------------------------------
    /**
     * Checks if value is an empty object, collection, map, or set.
     * @param {*} value  -The value to check.
     * @returns {boolean} : Returns true if value is empty, else false.
     */
    isEmpty(value) {
        return IsEmpty(value);
    },
    // -------------------------------------------------------------------------------
    /**
     * Creates an object composed of keys generated from the results of running each element
     * @param {Arrat| Object} collection  - The collection to iterate over.
     * @param {function} iterate - The iteratee to transform keys
     * @returns {Object} : Returns the composed aggregate object.
     */
    groupBy(collection, iterate) {
        return GroupBy(collection, iterate);
    },
    // -------------------------------------------------------------------------------
    /**
     * Comparison between two values to determine if they are equivalent.
     * @param {*} value - The value to compare.
     * @param {*} other - The other value to compare.
     * @returns {boolean} :Returns true if the values are equivalent, else false.
     */
    isEqual(value, other = null) {
        return IsEqual(value, other);
    },
    // -------------------------------------------------------------------------------
    /**
     * creates a function that invokes the given functions from right to left
     * @param {...(Function|Function[])} funcs -- the functions to invoke.
     * @returns {function}  Returns the new composite function.
     */
    flowRight(...funcs) {
        return FlowRight(funcs);
    },
    // -------------------------------------------------------------------------------
    /**
     * Checks if value is a plain object
     * @param {*} value -The value to check.
     * @returns {boolean} : Returns true if value is a plain object, else false.
     */
    isObject(value) {
        return IsObject(value);
    },
    // -------------------------------------------------------------------------------
    /**
     * Creates a shallow  it recursively clones value.
     * @param {*} value - The value to recusively clone .
     * @returns {*} : Returns the deep cloned value.
     */
    cloneDeep(element) {
        return CloneDeep(element);
    },
    // -------------------------------------------------------------------------------
    /**
     * Creates an array of array values not included in the other given array.
     * @param {Array} collection - The array to inspect.
     * @param {...Object[]} - The values to exclude.
     * @returns {Array} : Returns the new array of filtered values.
     */
    difference(array1, array2) {
        return Difference(array1, array2);
    },
    // -------------------------------------------------------------------------------
    /**
     * Creates an array of array values not included in the other given array, based on a comparison function.
     * @param {Array} collection - The array to inspect.
     * @param {...Object[]} - The values to exclude.
     * @returns {Array} : Returns the new array of filtered values.
     */
    differenceWith(array1, array2) {
        return DifferenceWith(array1, array2, IsEqual);
    },
};
