import React from "react";

import Classnames from "classnames";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SelectorsAction as SelectorsActionAboutIbanca } from "~/store/aboutIbanca";
import { SelectorsStore as StoreConfig, STORE, PROP as PROP_CONFIG } from "~/store/config";
import { SelectorsStore as StoreI18n, PROP as PROP_I18N } from "~/store/i18n";
import { SelectorsStore as StoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionSettings } from "~/store/settings";
import DeviceUtils from "~/util/device";

import { Section } from "~/components/Component";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";

import Style from "./_Footer.rules.scss";

export const { NAME, TYPE } = Style;
export const PROP = {
    types: {
        langs: PropTypes.shape({
            all: PROP_CONFIG.types.languages,
            curr: PROP_I18N.types.lang,
        }).isRequired,
        tel: PropTypes.shape({
            mobile: PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            }).isRequired,
            contact: PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    },
    defaults: {},
};

const FLAGS = {
    en: { image: "flags/US.svg", label: "English" },
    es: { image: "flags/UY.svg", label: "Español" },
    pt: { image: "flags/BR.svg", label: "Português" },
};

export function Component(props) {
    const { langs, qrData, tel } = props;
    const languages = langs.all.filter((lang) => lang !== langs.curr);
    const year = new Date().getFullYear();

    function handleShowAboutIbancaModal() {
        const { dispatch } = props;

        dispatch(SelectorsActionAboutIbanca.setShowAboutIbancaModal({ showAboutIbancaModal: true }));
    }

    return (
        <Section tag="footer" name={NAME} id={Style.ID}>
            <nav
                role="navigation"
                aria-label="footer primary"
                className={Classnames({ "mobile-native": DeviceUtils.isMobileNative() })}>
                <Link to="/desktop">
                    <Image src="footer-home.svg" />
                    <I18n id="menu.desktop" />
                </Link>
                <Link to="/transfers">
                    <Image src="footer-transfers.svg" />
                    <I18n id="menu.transfers" />
                </Link>
                {DeviceUtils.isMobileNative() && qrData && qrData.qrEnabled && (
                    <div className="wrapper-qr">
                        <Link className="qr-button" to="/servicePayment/qrModo/otpRequest">
                            <Image classNameOuter="qr" src="qr-code.svg" />
                            <Image classNameOuter="toke" src="toke-logo-white.svg" />
                        </Link>
                    </div>
                )}
                <Link to="/preferentialTradingPrice">
                    <Image src="footer-preferential-trading-price.svg" />
                    <I18n id="footer.preferentialTradingPrice" />
                </Link>
                <Link to="/share">
                    <Image src="share-blanco.svg" />
                    <I18n id="share.label" />
                </Link>
            </nav>

            <nav role="navigation" aria-label="footer secondary">
                <section
                    id={Style.ID_LANGUAGE}
                    role="group"
                    aria-label="language"
                    style={{ "--length": languages.length }}>
                    {languages.map((lang) => (
                        <button
                            key={lang}
                            onClick={() => {
                                handleChangeLanguage(lang);
                            }}
                            role="menuitem"
                            type="button">
                            <Image src={FLAGS[lang].image} />
                            <I18n id={`settings.changeLanguage.label.${lang}`} defaultValue={FLAGS[lang].label} />
                        </button>
                    ))}
                </section>

                <small id={Style.ID_COPYRIGHT}>Copyright &copy; {year} — HSBC</small>

                <Link id={Style.ID_TERMS} to="/generalConditions">
                    <I18n id="footer.generalConditions" defaultValue="Condiciones generales" />
                </Link>

                <Link id={Style.ID_SECURITY} to="/security">
                    <I18n id="footer.security" defaultValue="Seguridad" />
                </Link>

                <Link id={Style.ID_LOCATIONS} to="/mapLocations">
                    <I18n id="footer.branches" defaultValue="Sucursales" />
                </Link>

                <Link id={Style.ID_INFORMATION} to="/information">
                    <I18n id="externalDashboard.information" defaultValue="Información" />
                </Link>

                <Link id={Style.ID_DEPOSITS} to="/depositGuarantee">
                    <I18n id="footer.depositGuarantee" defaultValue="Garantía de depósito" />
                </Link>

                <Link id={Style.ID_ABOUT} to="/" onClick={handleShowAboutIbancaModal}>
                    <I18n id="footer.aboutIbanca" defaultValue="Conozca iBanca" />
                </Link>

                <section id={Style.ID_CONTACT} role="group" aria-label="contact">
                    <span>
                        <Image src="whatsapp-external-footer.svg" />
                        <I18n id="core.mobile.contactNumber" defaultValue={tel.mobile.label} />
                    </span>
                    <span>
                        <Image src="telephone.svg" />
                        <I18n id="core.contactNumber" defaultValue={tel.contact.label} />
                    </span>
                    <small>
                        <I18n id="footer.contactHour" defaultValue="Lunes a viernes de 9 a 20 hs" />
                    </small>
                </section>
            </nav>
        </Section>
    );

    function handleChangeLanguage(lang) {
        const { langs: langsAux, dispatch } = props;

        if (lang !== langsAux.curr) {
            dispatch(SelectorsActionSettings.changeLanguage({ language: lang }));
        }
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    const langs = {
        all: StoreConfig.getItem(store, { key: "frontend.i18n.languages", type: STORE.TYPE_ARRAY }) || [],
        curr: StoreI18n.getLang(store),
    };
    const qrData = StoreSession.getQrData(store);
    const tel = {
        mobile: {
            label: StoreConfig.getItem(store, { key: "core.mobile.contactNumber.label" }),
            value: StoreConfig.getItem(store, { key: "core.mobile.contactNumber.value" }),
        },
        contact: {
            label: StoreConfig.getItem(store, { key: "core.contactNumber.label" }),
            value: StoreConfig.getItem(store, { key: "core.contactNumber.value" }),
        },
    };

    return { langs, qrData, tel };
};

export default Connect(mapStateToProps)(Component);
