import React from "react";

import { Field } from "formik";
import { shape, func } from "prop-types";
import { connect } from "react-redux";

import { PERMISSIONS } from "~/constants";
import { SelectorsStore as SelectorsStorePermissions } from "~/store/administration/common/permissions";
import * as administrationUtils from "~/util/administration";
import { get as Get } from "~/util/i18n";

import Accordion from "~/pages/_components/Accordion";
import AccordionItem from "~/pages/_components/AccordionItem";
import IndeterminateCheckbox from "~/pages/_components/IndeterminateCheckbox";
import StyleCheckbox from "~/pages/_components/IndeterminateCheckbox.rules.scss";
import Checkbox from "~/pages/_components/fields/Checkbox";

import PermissionsAmount from "~/pages/administration/_components/AdvancedPermissionsAmount";
import PermissionsList from "~/pages/administration/_components/AdvancedPermissionsList";
import PermissionField from "~/pages/administration/_components/advancedPermissionsForm/PermissionField";

const ID_FORM = "administration.permissions";

export const NAME = "AdvancedPermissionsForm";

export const PROP = {
    types: {
        setValues: func.isRequired,
        groups: administrationUtils.groupsPropType.isRequired,
        // TODO this is an object with object that has object inside having keys with string values, need to solve this somehow
        values: shape({
            permissions: administrationUtils.permissionsPropType,
        }),
    },
    defaults: { values: {} },
};

export function Component(props) {
    const dependsFromFields = ({ childrenList }, idItemToFind) =>
        childrenList.reduce((flattenedOptions, option) => {
            if (!option.childrenList.length) {
                if (option.dependsOn === idItemToFind) {
                    return [...flattenedOptions, option.idItem];
                }

                return flattenedOptions;
            }

            return [...flattenedOptions, ...dependsFromFields(option, idItemToFind)];
        }, []);

    const setValueFunction = ({ nextValue, setValues, dependsOn, values, idItem, parentOption }) => {
        let value = { [idItem]: nextValue };
        if (nextValue && !nextValue.length && dependsOn) {
            value = { [idItem]: nextValue, [dependsOn]: nextValue };
        } else if (nextValue && nextValue.length && !dependsOn) {
            value = [idItem, ...dependsFromFields(parentOption, idItem)].reduce(
                (acc, id) => ({ ...acc, [id]: nextValue }),
                {},
            );
        }

        setValues({
            ...values,
            permissions: {
                ...values.permissions,
                ...value,
            },
        });
    };

    const fillValues = ({ permissionList, childrenList, idItem }) => {
        if (permissionList.length && permissionList[0].advancedAllowProductSelection) {
            return { [idItem]: permissionList[0].productTypes.split(",").map((type) => `ALL_${type}`) };
        }

        if (childrenList.length) {
            return childrenList.reduce(
                (acc, option) => ({
                    ...acc,
                    ...fillValues(option, acc),
                }),
                {},
            );
        }

        return {
            [idItem]: permissionList[0].productTypes?.length
                ? permissionList[0].productTypes.split(",").map((type) => `ALL_${type}`)
                : ["NONE"],
        };
    };

    const handleCheckClick = (values, option, setValues, selectedOptionsAmount) => {
        const filled = fillValues(option);

        if (selectedOptionsAmount === 0) {
            setValues({
                ...values,
                permissions: {
                    ...values.permissions,
                    ...filled,
                },
            });
        } else {
            const keysToRemove = Object.keys(filled);
            setValues({
                ...values,
                permissions: Object.entries(values.permissions).reduce(
                    (filteredValues, [key, value]) => ({
                        ...filteredValues,
                        [key]: keysToRemove.includes(key) ? [] : value,
                    }),
                    {},
                ),
            });
        }
    };

    const { values, groups, mode, setValues } = props;

    const renderField = ({ idItem, label, number, productAlias, permissionList, ...option }) => (
        <Field name="permissions" key={idItem}>
            {({ form, field }) => {
                const value = field.value[idItem] || [];
                let allActive = ["NONE"];
                if (permissionList[0].productTypes?.length) {
                    allActive = permissionList[0].productTypes.split(",").map((type) => `ALL_${type}`);
                }
                return (
                    <React.Fragment>
                        <div className="permission-item" id={StyleCheckbox.ID}>
                            <Checkbox
                                name={idItem}
                                onChange={() => {
                                    setValueFunction({
                                        ...option,
                                        ...form,
                                        idItem,
                                        nextValue: value.length ? [] : allActive,
                                    });
                                }}
                                checked={value.length > 0}
                                formGroup={false}
                                label={Get(`${ID_FORM}.advanced.${idItem}`)}
                                idForm={ID_FORM}
                            />
                        </div>
                    </React.Fragment>
                );
            }}
        </Field>
    );

    const renderOptions = (option, isFirstLevel) => {
        if (option.childrenList && option.childrenList.length) {
            return (
                <React.Fragment key={option.idItem}>
                    {!isFirstLevel && <div className="navigational-list-subtitle">{option.label}</div>}

                    {option.childrenList.map((subOption) => renderOptions({ ...subOption, parentOption: option }))}
                </React.Fragment>
            );
        }

        if (!option.permissionList[0].advancedAllowProductSelection) {
            return renderField(option);
        }

        const field = <PermissionField mode="edit" key={option.idItem} {...option} />;

        if (isFirstLevel) {
            return field;
        }
        return (
            <div className="permission-item" id={StyleCheckbox.ID}>
                {field}
            </div>
        );
    };

    if (mode !== "edit") {
        return <PermissionsList permissions={values.permissions} />;
    }

    return (
        <PermissionsAmount permissions={values.permissions}>
            {(amountsById, totalAmountsById) => (
                <div style={{ position: "relative" }}>
                    <Accordion className="list list--permissions">
                        {groups.map((group, index) => {
                            const { idItem, ordinal } = group;
                            const selectedOptionsAmount = amountsById[idItem] || 0;
                            const optionsAmount = totalAmountsById[idItem];
                            const options = renderOptions(
                                { ...group, parentOption: { ...group }, number: index },
                                true,
                            );
                            const quantityText = `${selectedOptionsAmount} / ${optionsAmount}`;

                            if (
                                options.props &&
                                (typeof options.props.children === "function" || !options.props.children)
                            ) {
                                return (
                                    <li key={idItem} className="list--permissions-item list-item--noChilds">
                                        {options}
                                    </li>
                                );
                            }

                            return (
                                <AccordionItem
                                    key={idItem}
                                    number={index}
                                    item={
                                        <React.Fragment>
                                            <IndeterminateCheckbox
                                                id={`${idItem}${ordinal}`}
                                                selectedOptionsAmount={selectedOptionsAmount}
                                                optionsAmount={optionsAmount}
                                                onCheckClick={() =>
                                                    handleCheckClick(values, group, setValues, selectedOptionsAmount)
                                                }
                                            />
                                            <span>
                                                <span>{Get(`${ID_FORM}.${idItem}`)}</span>
                                                <span className="list-item-hint">{quantityText}</span>
                                            </span>
                                            <div className="arrow-class" />
                                        </React.Fragment>
                                    }>
                                    {idItem === PERMISSIONS.PAYMENTS && (
                                        <div className="permission-description">
                                            {Get(`${ID_FORM}.${idItem}.description`)}
                                        </div>
                                    )}
                                    {options}
                                </AccordionItem>
                            );
                        })}
                    </Accordion>
                </div>
            )}
        </PermissionsAmount>
    );
}

const mapStateToProps = (state) => ({
    products: SelectorsStorePermissions.getProducts(state),
    groups: SelectorsStorePermissions.getGroups(state),
});
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default connect(mapStateToProps)(Component);
