import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "filesDownload";

export const PATH = {
    DOWNLOAD_FILE: "/client.downloads.read",
    GET_CATEGORIES: "/client.downloads.list.pre",
    GET_FILE_DOWNLOADS_LIST: "/client.downloads.list.execute",
    GET_SUB_CATEGORIES: "/client.downloads.listSubcategories",
};

export const TYPE = FactoryType(NAME, [
    "GET_FILE_DOWNLOAD_REQUEST",
    "GET_FILE_DOWNLOAD_SUCCESS",
    "GET_FILE_DOWNLOAD_FAILURE",

    "GET_FILE_DOWNLOADS_LIST_REQUEST",
    "GET_FILE_DOWNLOADS_LIST_SUCCESS",
    "GET_FILE_DOWNLOADS_LIST_FAILURE",

    "GET_CATEGORIES_REQUEST",
    "GET_CATEGORIES_SUCCESS",
    "GET_CATEGORIES_FAILURE",

    "GET_SUB_CATEGORIES_REQUEST",
    "GET_SUB_CATEGORIES_SUCCESS",
    "GET_SUB_CATEGORIES_FAILURE",

    "RESET_FILTERS",
    "RESET_LIST",
]);

export const PROP = {};

PROP.members = {
    filters: {
        categoryId: PropTypes.number,
        name: PropTypes.string,
        subCategoryId: PropTypes.number,
    },
};

PROP.types = {
    categories: PropTypes.array,
    downloadsList: PropTypes.array,
    fetching: PropTypes.bool,
    orderBy: PropTypes.string,
    pageNumber: PropTypes.number,
    subCategories: PropTypes.array,
    moreLines: PropTypes.bool,
    filters: PropTypes.shape(PROP.members.filters),
    ...TypesRedux,
};

PROP.defaults = {
    categories: null,
    downloadsList: null,
    fetching: false,
    orderBy: null,
    pageNumber: 1,
    subCategories: null,
    moreLines: false,
    filters: {
        categoryId: 0,
        name: EMPTY_STR,
        subCategoryId: 0,
    },
    ...DefaultsRedux,
};
