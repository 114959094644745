import React from "react";

import { Field, Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsAction as SelectorsActionPosition, SelectorsStore as SelectorsStorePosition } from "~/store/position";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button/Button";
import DateField from "~/pages/_components/fields/DateField";

import Namespace from "./DownloadDate.rules.scss";

export const { CLASS, NAME } = Namespace;

const FORM_ID = "position.download.date";

export const PROP = {
    types: {
        ...TypesRedux,
        i18n: PropTypes.object,
    },
    defaults: {
        ...DefaultsRedux,
        i18n: {
            accept: "Aceptar",
        },
    },
};
export function Component(props) {
    const { i18n } = props;

    return (
        <Form className={CLASS}>
            <Field
                autoComplete="off"
                component={DateField}
                hideLabel
                hidePlaceholder
                id="downloadDate"
                idForm={FORM_ID}
                maxDate={null}
                name="downloadDate"
                showMonthYearDropdown
            />

            <div className="form-group">
                <Button block type="submit" variant="primary">
                    {i18n.accept}
                </Button>
            </div>
        </Form>
    );
}

Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

const mapStateToProps = (store) => ({
    i18n: {
        accept: StoreI18n.getMessage(store, "global.accept"),
    },
    order: SelectorsStorePosition.getOrder(store),
});

function createSchema() {
    return Yup.lazy(() =>
        Yup.object().shape({
            downloadDate: Yup.date().required(UtilsI18n.get(`${FORM_ID}.downloadDate.required.error`)),
        }),
    );
}

export default Compose(
    Connect(mapStateToProps),
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: () => ({
            downloadDate: new Date(),
        }),
        validationSchema: (props) => createSchema(props),
        handleSubmit: ({ downloadDate }, formikBag) => {
            const { dispatch, onCloseDownloadDateModalForm, order } = formikBag.props;

            dispatch(SelectorsActionPosition.getPositionPDF({ downloadDate, order }));

            onCloseDownloadDateModalForm();
        },
    }),
)(Component);
