import React from "react";

import { push as Push } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { CDP_CURRENCY, EMPTY_STR, LEVEL, SCOPE, SPACE_STR } from "~/constants";
import { STATUS } from "~/constants/transaction";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import {
    SelectorsAction as SelectorsActionTransactions,
    SelectorsStore as SelectorsStoreTransactions,
} from "~/store/transactions";
import * as UtilI18n from "~/util/i18n";
import { Types as TypesRedux } from "~/util/prop/redux";

import DataNumber from "~/components/DataNumber";
import GridLayout from "~/components/GridLayout";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";
import ChevromRight from "~/pages/_components/listItem/ChevromRight";

import TransactionStatus from "~/pages/transactionsHistory/_components/TransactionStatus";

export const NAME = "TransactionsItemHistory";

export const PROP = {
    types: {
        ...TypesRedux,
        approve: PropTypes.bool,
        isDeletingDraft: PropTypes.bool,
        itemRef: PropTypes.func,
        transaction: PropTypes.shape({
            transaction: PropTypes.shape({
                idTransactionStatus: PropTypes.string.isRequired,
                activityName: PropTypes.string.isRequired,
                idActivity: PropTypes.string.isRequired,
                valueDateAsString: PropTypes.string.isRequired,
                valueDateLabel: PropTypes.string.isRequired,
                shortDescriptionForMobile: PropTypes.string.isRequired,
                signaturesLabel: PropTypes.string.isRequired,
                fullCreationDateTimeAsString: PropTypes.string.isRequired,
                statusLabel: PropTypes.string.isRequired,
            }).isRequired,
            cancelEnabled: PropTypes.bool.isRequired,
            editableInMobile: PropTypes.bool.isRequired,
        }).isRequired,
        onClickApproval: PropTypes.func,
    },

    defaults: {
        isDeletingDraft: false,
        approve: false,
        itemRef: () => {},
        onClickApproval: () => {},
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {
        isChecked: false,
    };

    componentDidMount() {
        const { approve } = this.props;
        this.setState({ isChecked: approve });
    }

    handleDeleteTransactionDraft = (event, idTransaction) => {
        event.stopPropagation();
        event.preventDefault();

        const { dispatch } = this.props;
        dispatch(SelectorsActionTransactions.deleteDraftRequest({ idTransactionToDelete: idTransaction }));
    };

    handleCancelTransaction = (event, idTransaction) => {
        event.stopPropagation();
        event.preventDefault();

        const { dispatch } = this.props;
        dispatch(Push(`/form/requestTransactionCancellation?referenceToCancel=${idTransaction}`));
    };

    handleCheckTransaction = (idTransaction) => {
        const { onClickApproval } = this.props;
        const { isChecked } = this.state;
        onClickApproval(idTransaction);
        this.setState({ isChecked: !isChecked });
    };

    getImage = (transactionStatus) => {
        let imgName;
        switch (transactionStatus) {
            case STATUS.FINISHED:
                imgName = "ok";
                break;
            case STATUS.DRAFT:
                imgName = "draft_1";
                break;
            case STATUS.SCHEDULED:
                imgName = "schedulet";
                break;
            case STATUS.CANCELLED:
            case STATUS.FAILED:
                imgName = "alertIconError";
                break;
            case STATUS.PROCESSING:
                imgName = "alertIconCron";
                break;
            case STATUS.ACCEPTED:
                imgName = "process";
                break;
            default:
                imgName = "alertIconMedium";
                break;
        }
        return `${imgName}.svg`;
    };

    getClassImage = (transactionStatus) => {
        if (transactionStatus === STATUS.DRAFT) {
            return "transaction-status gray";
        }
        if (transactionStatus === STATUS.SCHEDULED) {
            return "transaction-status gray";
        }
        return "transaction-status";
    };

    onClick = () => {
        const { dispatch, transaction: transactionReceived } = this.props;
        const { transaction } = transactionReceived;
        const { idTransactionStatus } = transaction;
        const isNotEditable = idTransactionStatus === STATUS.DRAFT || idTransactionStatus === STATUS.RETURNED;
        if (isNotEditable) {
            dispatch(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get("transactions.trasactionItem.isNotEditable"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.TRANSACTIONS],
                }),
            );
        } else {
            dispatch(SelectorsActionTransactions.selectedTransaction());
        }
    };

    getStatus = (statusLabel, signaturesLabel, idTransactionStatus) => {
        const { isMobile } = this.props;

        return (
            <React.Fragment>
                <div className="status transaction-status-display">
                    <Image
                        src={this.getImage(idTransactionStatus)}
                        className={this.getClassImage(idTransactionStatus)}
                    />
                    <span>{statusLabel}</span>
                </div>
                {!isMobile && signaturesLabel && (
                    <React.Fragment>
                        <div>
                            {signaturesLabel}
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    };

    render() {
        const { itemRef, transaction: transactionReceived, isMobile } = this.props;
        const {
            transaction,
            shortDescriptionForMobile,
            transactionAmountDescription,
            signaturesLabel,
            valueDate,
            submitDateTime,
        } = transactionReceived;

        const {
            idTransaction,
            idTransactionStatus,
            idActivity,
            fullCreationDateTimeAsString,
            activityName,
            statusLabel,
            signatures,
            totalAmount,
        } = transaction;

        const secondAmountData = null;
        const showAmount = totalAmount > 0;

        const isNotEditable = idTransactionStatus === STATUS.DRAFT || idTransactionStatus === STATUS.RETURNED;

        const to = isNotEditable ? "" : `/transactionHistory/${idTransaction}`;

        const content = (
            <React.Fragment>
                <div className="table-data table-data-icon">
                    <TransactionStatus idTransactionStatus={idTransactionStatus} showLabel={false} />
                </div>

                <div className="table-data">
                    <span className="data-text">
                        {activityName}
                    </span>
                    {!isMobile && shortDescriptionForMobile !== null && shortDescriptionForMobile !== EMPTY_STR && (
                        <span className="data-date">
                            <span className="content" dangerouslySetInnerHTML={{ __html: shortDescriptionForMobile }} />
                        </span>
                    )}
                </div>
                <div className="table-data table-data-date">
                    {valueDate ? (
                        <div>
                            <I18n id="transaction.list.paymentDate" />
                            {SPACE_STR}

                            {valueDate}
                        </div>
                    ) : (
                        undefined
                    )}
                    {submitDateTime ? (
                        <div>
                            <I18n id="client.payments.automaticDebit.processedDate" />
                            {SPACE_STR}
                            {submitDateTime}
                        </div>
                    ) : (
                        undefined
                    )}
                    {showAmount && transactionAmountDescription}
                    {secondAmountData && (
                        <DataNumber value={secondAmountData.amount} prefix={secondAmountData.currency} />
                    )}
                </div>
                <div className="table-data data-status">
                    {this.getStatus(statusLabel, signaturesLabel, idTransactionStatus)}
                </div>
                <ChevromRight />
            </React.Fragment>
        );

        return (
            <div className="flex-display">
                <Link to={to} className="table-row" innerRef={itemRef} onClick={this.onClick}>
                    {isMobile ? (
                        <React.Fragment>{content}</React.Fragment>
                    ) : (
                        <GridLayout className="body">
                            <div className="transaction-list-data">
                                <div className="title">{activityName}</div>
                                <div dangerouslySetInnerHTML={{ __html: shortDescriptionForMobile }} />
                            </div>
                            <div className="transaction-list-data">
                                <div className="date">{fullCreationDateTimeAsString}</div>
                                {valueDate ? (
                                    <div>
                                        <I18n id="transaction.list.paymentDate" />
                                        {SPACE_STR}

                                        {valueDate}
                                    </div>
                                ) : (
                                    undefined
                                )}
                                {submitDateTime ? (
                                    <div>
                                        <I18n id="client.payments.automaticDebit.processedDate" />
                                        {SPACE_STR}

                                        {submitDateTime}
                                    </div>
                                ) : (
                                    undefined
                                )}
                            </div>
                            <div className="align-right transaction-list-data">
                                {showAmount && transactionAmountDescription}
                                {secondAmountData && (
                                    <DataNumber value={secondAmountData.amount} prefix={secondAmountData.currency} />
                                )}
                            </div>
                            <div className="transaction-list-data transaction-list-data-status">
                                {this.getStatus(statusLabel, signaturesLabel, idTransactionStatus)}
                            </div>
                        </GridLayout>
                    )}
                </Link>
            </div>
        );
    }
}

const mapStateToProps = (store) => ({
    isDeletingDraft: SelectorsStoreTransactions.isDeletingDraft(store),
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
    administrationScheme: SelectorsStoreSession.getAdministrationScheme(store),
});

export default Connect(mapStateToProps)(Component);
