import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import * as UtilI18n from "~/util/i18n";
import { Categorize } from "~/util/prop";

import Style from "./Cards.rules.scss";
import Form from "./index";

export const { NAME, TYPE } = Style;

export const PROP = {
    types: {
        children: PropTypes.node.isRequired,
        "head-title": PropTypes.string,
    },
    defaults: {
        "head-title": EMPTY_STR,
    },
};

export function Component(props) {
    const {
        children,
        head: { title },
    } = Categorize(props);

    const className = props.className ? `${props.className} ${Style.ID}` : Style.ID;
    return (
        <Form {...props} className={className}>
            <div className="section-title">{title && <span>{UtilI18n.get(title)}</span>}</div>
            {children}
        </Form>
    );
}
Component.displayName = NAME;
Component.proptTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
