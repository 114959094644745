import React from "react";

import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { CREDIT_CARDS_LIMIT_INCREASE_TYPE, EMPTY_STR } from "~/constants";
import { STATUS } from "~/constants/transaction";
import {
    SelectorsAction as SelectorsActionCreditCards,
    SelectorsStore as SelectorsStoreCreditCards,
} from "~/store/creditCards/creditCard";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as UtilsI18n from "~/util/i18n";

import DataDate from "~/components/DataDate";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Token from "~/components/Token/Token";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import TicketPrintInformation from "~/pages/_components/TicketPrintInformation";

import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        isSubmitting: PropTypes.bool,
    },
    defaults: {
        isSubmitting: false,
    },
};

const loadData = (props) => {
    const { limitIncreaseData, location, transaction } = props;

    const isFromTransactionsHistory = location.pathname.includes("/ticket");

    if (isFromTransactionsHistory && transaction?.idTransactionStatus === STATUS.PENDING) {
        const { data } = transaction;

        return {
            alias: data.alias,
            incrementDueDate: data.endDate,
            incrementToday: data.beginDate,
            limitCurrency: data.currency,
            limitType: data.limitType,
            newLimit: data.amount,
        };
    }

    const { formData, visaMasterAccount } = limitIncreaseData;
    const selectedAccount = formData.find((account) => account.visaMasterAccount === visaMasterAccount);

    selectedAccount.newLimit = limitIncreaseData.newLimit;

    return selectedAccount;
};

export function Component(props) {
    const { isSubmitting } = props;

    const selectedAccount = loadData(props);

    const { alias, incrementDueDate, incrementToday, limitCurrency, limitType, newLimit } = selectedAccount;

    return (
        <Form id={Style.ID}>
            <TicketPrintInformation />
            <React.Fragment>
                <section className="justify-content-center">
                    <I18n className="text-lead" id="forms.transferInternal.confirmation.title" />
                    <div className="increase-block">
                        <div className="increase-data increase-data-highlight">
                            <I18n className="data-label" id="creditCards.limitIncrease.step2.newLimit" />
                            <span className="data-amount">
                                <FormattedAmount currency={limitCurrency.toString()} quantity={parseFloat(newLimit)} />
                            </span>
                        </div>
                        <div className="increase-data increase-data-flow">
                            <div className="data-wrapper align-end">
                                <span className="data-label">
                                    <I18n className="data-label" id="creditCards.limitIncrease.step2.selectedAccount" />
                                </span>
                                <span className="data-name">{alias}</span>
                            </div>
                            <Image src="arrowRight.svg" className="svg-icon" />
                            <div className="data-wrapper align-start">
                                <div className="data-label">
                                    <div>
                                        <I18n id="creditCards.limitIncrease.step2.limit" />
                                        <I18n
                                            className="data-name"
                                            id={`creditCards.limitIncrease.step2.${
                                                limitType || CREDIT_CARDS_LIMIT_INCREASE_TYPE.TEMPORARY
                                            }`}
                                        />
                                    </div>
                                    <div className="date">
                                        <I18n id="creditCards.limitIncrease.step2.incrementToday" />
                                        <DataDate value={incrementToday} />
                                    </div>
                                    <div className="date">
                                        <I18n id="creditCards.limitIncrease.step2.incrementDueDate" />
                                        <DataDate value={incrementDueDate} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Token labelButton="global.send" isSubmitting={isSubmitting} />
            </React.Fragment>
        </Form>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        limitIncreaseData: SelectorsStoreCreditCards.getLimitIncreaseFormData(store),
        transaction: SelectorsStoreCreditCards.getTransaction(store),
        user: SelectorsStoreSession.getUser(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithRouter,
    WithFormik({
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        validationSchema: (props) => {
            const { user } = props;

            return Yup.object().shape({
                otp: user.needsBiometric
                    ? Yup.string().nullable()
                    : Yup.string().required(UtilsI18n.get("form.credential.otp.required")),
            });
        },
        handleSubmit: (props, formikBag) => {
            const { otp } = props;
            const { dispatch, history, limitIncreaseData, transaction } = formikBag.props;
            const { idActivity, idTransaction } = transaction || EMPTY_STR;
            const isFromTransactionsHistory = history.location.pathname.includes("/ticket");

            if (isFromTransactionsHistory) {
                dispatch(
                    SelectorsActionCreditCards.signTransaction({
                        idForm: null,
                        idActivity,
                        idTransaction,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            } else {
                const { formData, limitType, newLimit, visaMasterAccount } = limitIncreaseData;

                const selectedAccount = formData?.find((account) => account.visaMasterAccount === visaMasterAccount);

                const data = {
                    alias: selectedAccount.alias,
                    accountVisaMaster: visaMasterAccount,
                    amount: newLimit,
                    beginDate: selectedAccount.incrementToday,
                    brand: selectedAccount.visaMasterBrand,
                    currency: selectedAccount.limitCurrency,
                    endDate: selectedAccount.incrementDueDate,
                    idCreditCard: selectedAccount.idProduct,
                    limitType,
                };

                dispatch(
                    SelectorsActionCreditCards.limitIncreaseSendRequest({
                        formData: data,
                        otp,
                        formikBag,
                    }),
                );
            }
        },
    }),
)(Component);
