import React from "react";

import PropTypes from "prop-types";

import ItemName from "~/pages/_components/item/Name";
import ChevromRight from "~/pages/_components/listItem/ChevromRight";

export const NAME = "BankSearchItem";

export const PROP = {
    types: {
        onSelect: PropTypes.func.isRequired,
        codeNumber: PropTypes.number,
        bankName: PropTypes.string,
        bankCountryLabel: PropTypes.string,
    },
    defaults: {
        codeNumber: null,
        bankName: "",
        bankCountryLabel: "",
    },
};

export function Component(props) {
    const { bankCountryLabel, onSelect, codeNumber, bankName } = props;

    const handleClick = () => onSelect(props);

    const Data = () => {
        return (
            <div className="table-data content-data">
                <div className="data-aux">{codeNumber}</div>
                <div className="data-aux  data-bank-name">
                    <span>{bankCountryLabel}</span>
                </div>
            </div>
        );
    };

    return (
        <div className="table-row" onClick={handleClick}>
            <div className="content">
                <Data />
                <div className="content-item--name">
                    <ItemName name={bankName} />
                </div>
            </div>
            <ChevromRight />
        </div>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
