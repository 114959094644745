import React from "react";

import { connect as Connect } from "react-redux";

import { TERMS, RESPONSIBILITY, EMPTY_STR } from "~/constants";
import { SelectorsAction } from "~/store/preferentialTradingPrice";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Link from "~/components/Link";

export const NAME = "Disclaimer";

export const PROP = {
    types: {
        ...TypesRedux,
    },
    defaults: {
        ...DefaultsRedux,
    },
};

export function Component(props) {
    const { dispatch } = props;

    const disclaimerText1 = UtilsI18n.get("preferentialTradingPrice.disclaimerText");
    const secondDisclaimerText1 = UtilsI18n.get("preferentialTradingPrice.secondDisclaimer.text1");
    const disclaimerLinkText1 = UtilsI18n.get("preferentialTradingPrice.disclaimerLinkText_1");
    const secondDisclaimerText2 = UtilsI18n.get("preferentialTradingPrice.secondDisclaimer.text2");
    const disclaimerLinkText2 = UtilsI18n.get("preferentialTradingPrice.disclaimerLinkText_2");
    const secondDisclaimerText3 = UtilsI18n.get("preferentialTradingPrice.secondDisclaimer.text3");

    function handleTermsAndConditionsClick(e) {
        const name = TERMS;

        dispatch(SelectorsAction.disclaimer({ name }));
        e.preventDefault();
    }

    function handleResponsabilityClick(e) {
        const name = RESPONSIBILITY;

        dispatch(SelectorsAction.disclaimer({ name }));
        e.preventDefault();
    }

    return (
        <div className="disclaimer-container">
            <div id="disclaimer-text">{disclaimerText1} </div>
            <br />
            <div id="disclaimer-text" className="no-justify">
                {`${secondDisclaimerText1} `}
                <Link to={EMPTY_STR} onClick={handleTermsAndConditionsClick}>
                    {`${disclaimerLinkText1} `}
                </Link>
                {`${secondDisclaimerText2} `}
                <Link to={EMPTY_STR} onClick={handleResponsabilityClick}>
                    {`${disclaimerLinkText2} `}
                </Link>
                {secondDisclaimerText3}
            </div>
            <br />
        </div>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Connect()(Component);
