import React from "react";

import { goBack as GoBack } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import {
    SelectorsStore as CustomsPaymentSelectors,
    SelectorsAction as CustomsPaymentActions,
} from "~/store/customsPayment";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import FormEdit from "./Step1Edit/Step1Edit";
import FormPreview from "./Step2Preview/Step2Preview";
import FormView from "./Step3View/Step3View";

// export const { NAME } = Style;

export const NAME = "CustomsPayments";
export const PROP = {
    types: {
        fetching: PropTypes.bool.isRequired,
        ...TypesRedux,
    },
    defaults: {
        fetching: false,
        ...DefaultsRedux,
    },
};

export class Component extends React.Component {
    handleBack = () => {
        const { dispatch, mode } = this.props;

        if (mode === MODE.VIEW) {
            dispatch(CustomsPaymentActions.restartFlow());
        } else if (mode === MODE.PREVIEW) {
            dispatch(CustomsPaymentActions.goToStep1());
        } else {
            dispatch(GoBack());
        }
    };

    render() {
        const { mode, fetching, transaction } = this.props;

        return (
            <React.Fragment>
                <Container
                    head-onBack={(mode !== MODE.EDIT && this.handleBack) || undefined}
                    head-onBackWeb={mode === MODE.EDIT && this.handleBack}
                    head-title={UtilsI18n.get(`payments.customsPayment.title`)}
                    name={NAME}
                    scopeToShowNotification={SCOPE.CUSTOMS_PAYMENT}
                    step={mode}
                    wait={fetching}>
                    <section>
                        {mode === MODE.EDIT && <FormEdit />}
                        {mode === MODE.PREVIEW && <FormPreview />}
                        {mode === MODE.VIEW && <FormView transaction={transaction} />}
                    </section>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    accounts: CustomsPaymentSelectors.getDebitAccountList(store),
    fetching: CustomsPaymentSelectors.getFetching(store),
    mode: CustomsPaymentSelectors.getMode(store),
    transaction: CustomsPaymentSelectors.getTransaction(store),
});

export default Connect(mapStateToProps)(Component);
