import { LOCATION_CHANGE } from "connected-react-router";

import { HSBC } from "~/constants";
import { MODE } from "~/constants/form";
import { TYPE as TypesBackoffice } from "~/store/backoffice";
import { TYPE as TYPE_FORM } from "~/store/form";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return {
                ...storeProp,
                accountsByAlias: PROP.defaults.accountsByAlias,
            };
        case TYPE.ALIAS_DELETION_PREVIEW_REQUEST:
            return {
                ...storeProp,
                formData: action.formData,
                fetching: true,
            };
        case TYPE.ALIAS_DELETION_PREVIEW_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.ALIAS_DELETION_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.PREVIEW,
            };
        case TYPE.ALIAS_DELETION_SEND_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.ALIAS_DELETION_SEND_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.ALIAS_DELETION_SEND_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };
        case TYPE.ALIAS_ENROLLMENT_PRE_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                mode: MODE.EDIT,
            };
        case TYPE.ALIAS_ENROLLMENT_PRE_FAILURE:
        case TYPE.ALIAS_ENROLLMENT_PRE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                userAlias: {
                    aliasData: action.mobilePhone.mobileNumber,
                    aliasType: action.mobilePhone.mobilePrefix,
                },
                userAliasHasBeenFetched: true,
            };
        case TYPE.ALIAS_ENROLLMENT_RESET:
            return {
                ...storeProp,
                userAliasHasBeenFetched: false,
            };
        case TYPE.ALIAS_ENROLLMENT_PREVIEW_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                formData: action.formData,
            };
        case TYPE.ALIAS_ENROLLMENT_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.PREVIEW,
            };
        case TYPE.ALIAS_ENROLLMENT_PREVIEW_FAILURE:
        case TYPE.ALIAS_ENROLLMENT_SEND_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.ALIAS_ENROLLMENT_SEND_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.ALIAS_ENROLLMENT_SEND_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };
        case TYPE.CLEAR_ACCOUNTS_BY_ALIAS_LIST:
            return {
                ...storeProp,
                accountsByAlias: PROP.defaults.accountsByAlias,
            };
        case TYPE.GET_ACCOUNTS_BY_ALIAS_FAILURE:
            return {
                ...storeProp,
                accountsByAliasSearchRequested: false,
                accountsByAliasFetching: false,
            };
        case TYPE.GET_ACCOUNTS_BY_ALIAS_REQUEST:
            return {
                ...storeProp,
                accountsByAliasSearchRequested: true,
                accountsByAliasFetching: true,
            };
        case TYPE.GET_ACCOUNTS_BY_ALIAS_SUCCESS:
            return {
                ...storeProp,
                accountsByAlias: action.accountsByAlias,
                accountsByAliasFetching: false,
                accountsByAliasSearchRequested: false,
                existsAccountsHSBC: action.accountsByAlias.some((account) => HSBC === account.bcuCode),
                localBanks: storeProp.localBanks.length > 0 ? storeProp.localBanks : action.localBanks,
            };
        case TYPE.LATEST_TRANSACTIONS_LIST_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                latestTransactionsList: action.transactions,
            };
        case TYPE.LATEST_TRANSACTIONS_LIST_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                latestTransactionsList: action.transactions,
            };
        case TYPE.TEMPLATES_LIST_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                templatesList: action.templates,
            };
        case TYPE.TEMPLATES_LIST_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                templatesList: action.templates,
            };
        case TYPE.FORM_TO_STEP_1:
            return {
                ...storeProp,
                mode: MODE.EDIT,
            };
        case TYPE.TOGGLE_TEMPLATE_HAS_BEEN_LOADED_FALSE:
            return {
                ...storeProp,
                templateHasBeenLoaded: false,
            };
        case TYPE.TOGGLE_SHOW_MODAL:
            return {
                ...storeProp,
                showModal: action.showModal,
            };
        case TYPE.UPDATE_CURRENCY:
            return {
                ...storeProp,
                currency: action.currency,
            };
        case TYPE.READ_TRANSACTION_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.READ_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                transaction: action.transaction,
                mode: MODE.VIEW,
            };
        case TYPE.SIGN_TRANSACTION_PREVIEW:
            return {
                ...storeProp,
                mode: MODE.PREVIEW,
                prevMode: storeProp.mode,
            };
        case TYPE.SIGN_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                prevMode: storeProp.mode,
                transaction: action.transaction,
            };
        case TYPE_FORM.CLEAN_STEPS:
            return {
                ...storeProp,
                accountsByAlias: PROP.defaults.accountsByAlias,
            };
        case TYPE_FORM.SAVE_DATA:
            return {
                ...storeProp,
                templateHasBeenLoaded: true,
            };
        case TypesBackoffice.BACKOFFICE_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                prevMode: storeProp.mode,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };
        case TYPE.PASS_QUERIED_ACCOUNTS_BY_ALIAS:
            return {
                ...storeProp,
                accountsByAlias: action.accountsByAlias,
            };
        default:
            return storeProp;
    }
};
