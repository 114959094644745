import React from "react";

import PropTypes from "prop-types";

import {
    CREDENTIAL_TYPE_PASSWORD,
    CREDENTIAL_TYPE_PIN,
    CREDENTIAL_TYPE_OTP,
    CREDENTIAL_TYPE_VERIFICATION_CODE,
    ONLY_NUMBER,
} from "~/constants";

import OTP from "~/pages/_components/fields/credentials/Otp";
import Password from "~/pages/_components/fields/credentials/Password";

export const NAME = "Credential";

export const PROP = {
    types: {
        type: PropTypes.string,
        hidePlaceholder: PropTypes.bool,
    },
    defaults: {
        type: CREDENTIAL_TYPE_PASSWORD,
        hidePlaceholder: false,
    },
};

export function Component(props) {
    const { type, hidePlaceholder, ...propsCredentials } = props;

    let element = null;

    switch (type) {
        case CREDENTIAL_TYPE_PASSWORD:
            element = <Password {...props} />;
            break;
        case CREDENTIAL_TYPE_PIN:
            element = (
                <Password
                    autoComplete="off"
                    autoFocus={false}
                    maxLength={6}
                    pattern={ONLY_NUMBER}
                    type={type}
                    {...propsCredentials}
                />
            );
            break;
        case CREDENTIAL_TYPE_VERIFICATION_CODE:
            element = (
                <OTP
                    autoComplete="off"
                    renderhard
                    autoFocus={false}
                    maxLength={4}
                    pattern={ONLY_NUMBER}
                    {...propsCredentials}
                />
            );
            break;
        case CREDENTIAL_TYPE_OTP:
            element = (
                <OTP
                    autoComplete="off"
                    hidePlaceholder={hidePlaceholder}
                    autoFocus={false}
                    maxLength={6}
                    pattern={ONLY_NUMBER}
                    type={type}
                    {...propsCredentials}
                />
            );
            break;
        default:
            break;
    }
    return element;
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
