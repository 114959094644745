import React from "react";

import { push as Push } from "connected-react-router";
import { Form, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";

import Container from "~/containers/Internal/Form/Simple";
import { SelectorsStore as SelectorsStoreSession, SelectorsAction as SelectorsActionSession } from "~/store/session";
import { SelectorsStore as SelectorsStoreSettings, SelectorsAction as SelectorsActionSettings } from "~/store/settings";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";

import Style from "./SyncUserEmail.rules.scss";

const FORM_ID = "change.personal.syncEmail.step1";

export const { NAME } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component({ dispatch, email, emailBT, fetchingForm, userEmailForceSync }) {
    React.useEffect(() => {
        if (userEmailForceSync) {
            dispatch(SelectorsActionSettings.getUserData());
            dispatch(SelectorsActionSession.resetUserEmailForceSync());
        }
    }, [dispatch, userEmailForceSync]);

    const handleNoOneIsCorrect = () => {
        dispatch(SelectorsActionSession.selectUserEmailToSync({ userEmailSelected: null }));

        dispatch(Push("/settings/changeEmail"));
    };

    const handleSelectEmail = (userEmailSelected) => {
        dispatch(SelectorsActionSession.selectUserEmailToSync({ userEmailSelected }));

        dispatch(Push("/settings/changeEmail"));
    };

    return (
        <Container name={NAME} head-title="change.personal.syncEmail.title" wait={fetchingForm}>
            <Form className="col col-12 col-lg-6 col-md-9 col-sm-12" id={Style.ID} noValidate="novalidate">
                <div>
                    <Box className="form-section-title ">
                        <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.title`)}</h3>
                    </Box>
                    <div className="form-group">
                        {email && (
                            <Button
                                className="button-option"
                                size="sm"
                                variant="secondary"
                                onClick={() => handleSelectEmail(email)}>
                                {email}
                            </Button>
                        )}
                        <Button
                            className="button-option"
                            size="sm"
                            variant="secondary"
                            onClick={() => handleSelectEmail(emailBT)}>
                            {emailBT}
                        </Button>
                        <Button
                            className="button-option button-option-nothing"
                            size="sm"
                            variant="secondary"
                            onClick={handleNoOneIsCorrect}>
                            <I18n id="change.personal.syncEmail.step1.noOneIsCorrect" />
                        </Button>
                    </div>
                </div>
            </Form>
        </Container>
    );
}

const mapStateToProps = (store) => ({
    email: SelectorsStoreSettings.getEmail(store),
    emailBT: SelectorsStoreSettings.getEmailBT(store),
    fetchingForm: SelectorsStoreSettings.getFetchingForm(store),
    userEmailForceSync: SelectorsStoreSession.getUserEmailForceSync(store),
});

export default HighOrder(
    WithRouter,
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: true,
    }),
)(Component);
