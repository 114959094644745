import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.GET_PHONE_AND_ACTIVE_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.GET_PHONE_AND_ACTIVE_SUCCESS:
            return {
                ...storeProp,
                phone: action.phone,
                active: action.active,
                fetching: false,
            };
        case TYPE.GET_PHONE_AND_ACTIVE_ERROR:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.CLEAN_UP:
            return {
                ...PROP.defaults,
            };
        case TYPE.CHANGE:
            return {
                ...storeProp,
                change: true,
            };
        case TYPE.NOT_CHANGE:
            return {
                ...storeProp,
                change: false,
            };
        default:
            return {
                ...storeProp,
            };
    }
};
