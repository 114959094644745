import { TYPE as TYPES_APP } from "~/store/app";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action = {}) => {
    switch (action.type) {
        case TYPE.RESET: {
            const { rememberedUser, fingerprintLoginFail } = action;
            return {
                ...storeProp,
                rememberedUser,
                fingerprintLoginFail,
                disabledButton: false,
                exchangeToken: null,
            };
        }
        case TYPE.INIT_LOGIN_FLOW: {
            const { rememberedUser } = action;
            return {
                ...storeProp,
                rememberedUser,
            };
        }
        case TYPE.LOGIN_STEP_1_REQUEST: {
            return {
                ...storeProp,
                fetching: true,
                submit: true,
                notification: null,
            };
        }
        // NOTE: Added "environment" for Nazca3. See comment on login saga (step3 request)
        case TYPE.LOGIN_STEP_1_SUCCESS: {
            const { exchangeToken, lang, securitySeal, userFullName, environment } = action;
            return {
                ...storeProp,
                fetching: false,
                showCaptcha: false,
                submit: false,
                exchangeToken,
                lang, // TODO: Lang is not being sent by the Step1 handler, is this expected?
                securitySeal,
                userFullName,
                environment,
            };
        }
        case TYPE.LOGIN_STEP_2_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                notification: null,
            };

        case TYPE.LOGIN_STEP_3_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                notification: null,
            };
        case TYPE.LOGIN_STEP_3_SUCCESS: {
            const { termsAndConditions } = action;
            return {
                ...storeProp,
                fetching: false,
                termsAndConditions,
            };
        }
        case TYPE.LOGIN_STEP_4_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                notification: null,
            };
        case TYPE.LOGIN_SUCCESS: {
            const { rememberedUser } = action;
            return {
                ...storeProp,
                rememberedUser,
                fingerprintLoginFail: false,
                submit: false,
            };
        }
        case TYPE.REMOVE_REMEMBERED_USER:
            return {
                ...storeProp,
                rememberedUser: PROP.defaults.rememberedUser,
            };
        case TYPE.SET_REMEMBERED_USER: {
            const { rememberedUser } = action;
            return {
                ...storeProp,
                rememberedUser,
            };
        }
        case TYPE.LOGIN_FAILURE:
            return {
                ...storeProp,
                fetching: false,
                fingerprintSubmiting: false,
            };
        case TYPE.LOGIN_FAILURE_REQUIRE_CAPTCHA:
            return {
                ...storeProp,
                fetching: false,
                showCaptcha: true,
                fingerprintSubmiting: false,
            };
        case TYPE.LOGIN_FINGERPRINT_FAILURE:
            return {
                ...storeProp,
                fingerprintLoginFail: true,
                fetching: false,
                showCaptcha: false,
                fingerprintSubmiting: false,
                disabledButton: false,
            };
        case TYPE.FINGERPRINT_LOGIN_SUBMIT:
            return {
                ...storeProp,
                fingerprintSubmiting: true,
            };
        case TYPE.FINGERPRINT_LOGIN_PRE: {
            return {
                ...storeProp,
                fetching: true,
            };
        }
        case TYPE.FINGERPRINT_LOGIN_PRE_SUCCESS: {
            const { exchangeToken } = action;
            return {
                ...storeProp,
                exchangeToken,
                fetching: false,
            };
        }
        case TYPE.LOGIN_FINGERPRINT_DISCARD:
            return {
                ...storeProp,
                disabledButton: false,
                fetching: false,
            };
        case TYPE.LOGIN_FAILURE_LOADER:
            return {
                ...storeProp,
                submit: false,
                fetching: false,
            };
        case TYPES_APP.CLEAN_UP:
        case TYPE.CLEAN_UP: {
            const { disabledButton, fingerprintLoginFail } = storeProp;

            return {
                ...PROP.defaults,
                disabledButton,
                fingerprintLoginFail,
            };
        }
        default:
            return {
                ...storeProp,
            };
    }
};
