import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SelectorsStore as StoreI18n } from "~/store/i18n";
import {
    SelectorsAction as SelectorsActionPreferentialTradingPrice,
    SelectorsStore as SelectorsStorePreferentialTradingPrice,
} from "~/store/preferentialTradingPrice";
import * as UtilDate from "~/util/date";

import Button from "~/components/Button/Button";
import Image from "~/components/Image";
import Modal from "~/components/Modal";

import Style from "./Bulletin.scss";
import BulletinList from "./_BulletinList";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {
        showBulletinModal: PropTypes.bool,
        isFetchingBulletin: PropTypes.bool,
        bulletinList: PropTypes.array,
    },
    defaults: {},
};

export function Component(props) {
    const { showBulletinModal, bulletinList, isFetchingBulletin, dispatch, i18n } = props;
    const wait = isFetchingBulletin || bulletinList == null || !bulletinList.length;

    let lastBulletin = null;

    React.useEffect(() => {
        dispatch(SelectorsActionPreferentialTradingPrice.bulletinListRequest());
    }, [dispatch]);

    if (!wait) {
        lastBulletin = bulletinList[0];
    }
    return (
        !wait && (
            <section className={CLASS}>
                <h3>{i18n.bulletinTitle}</h3>
                <div>
                    <Image src="downloadFile.svg" />
                    <div>
                        <Button className="link" onClick={() => dowloadPDF(lastBulletin.fileName)}>
                            {i18n.downloadPDFLabel}
                        </Button>
                        <p>
                            {UtilDate.i18nDate(lastBulletin.date, "dd MMMM yyyy")} - {lastBulletin.name}
                        </p>
                        <Button className="link" onClick={() => handleShowModal(true)}>
                            {i18n.previousEditionLabel}
                        </Button>
                    </div>
                    <Modal
                        show={showBulletinModal}
                        closeButton
                        onClose={() => handleShowModal(false)}
                        labelKey="preferentialTradingPrice.bulletin.title">
                        <BulletinList list={bulletinList} />
                    </Modal>
                </div>
            </section>
        )
    );

    function dowloadPDF(fileName) {
        dispatch(SelectorsActionPreferentialTradingPrice.bulletinPdfDownloadRequest({ fileName }));
    }

    function handleShowModal(value) {
        dispatch(SelectorsActionPreferentialTradingPrice.setShowBulletinModal({ showBulletinModal: value }));
    }
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    bulletinList: SelectorsStorePreferentialTradingPrice.getBulletinList(store),
    showBulletinModal: SelectorsStorePreferentialTradingPrice.getShowBulletinModal(store),
    isFetchingBulletin: SelectorsStorePreferentialTradingPrice.isFetchingBulletin(store),
    i18n: {
        bulletinTitle: StoreI18n.getMessage(store, "preferentialTradingPrice.bulletin.title"),
        downloadPDFLabel: StoreI18n.getMessage(store, "preferentialTradingPrice.bulletin.downloadPDF.label"),
        previousEditionLabel: StoreI18n.getMessage(store, "preferentialTradingPrice.bulletin.previousEditions.label"),
    },
});

export default Connect(mapStateToProps)(Component);
