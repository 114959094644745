import React from "react";

import ClassNames from "classnames";
import PropTypes from "prop-types";

import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";

import Style from "./WidgetCard.rules.scss";

export const { NAME, TYPE } = Style;

export const PROP = {
    types: {
        href: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        containerStyle: PropTypes.string.isRequired,
        nameCard: PropTypes.string.isRequired,
        children: PropTypes.arrayOf(PropTypes.element).isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const { href, image, containerStyle, title, children, nameCard, ...rest } = props;

    return (
        <Link
            {...rest}
            to={href}
            draggable={false}
            className={ClassNames(Style.ID, { "container-style": containerStyle })}>
            <Image src={image} />
            <h4>{title}</h4>
            <ul>
                {React.Children.map(children, (child) => {
                    if (!child.key) {
                        throw new Error(`All ${NAME} children must have a valid key.`);
                    }
                    return (
                        <li key={child.key}>
                            <I18n id={child.key} />
                            {child}
                        </li>
                    );
                })}
            </ul>
            {nameCard && <h3>{nameCard}</h3>}
        </Link>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
