import React from "react";

import { withFormik, Form, Field } from "formik";
import libphonenumber from "google-libphonenumber";
import PropTypes from "prop-types";
import { Grid, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import * as Yup from "yup";

import { URUGUAY } from "~/constants";
import { SelectorsAction as SelectorsActionSettings, SelectorsStore as SelectorsStoreSettings } from "~/store/settings";
import * as i18n from "~/util/i18n";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Head from "~/pages/_components/Head";
import MainContainer from "~/pages/_components/MainContainer";
import TextField from "~/pages/_components/fields/TextField";
import Credential from "~/pages/_components/fields/credentials/Credential";

const FORM_ID = "settings.changePhone";

export const NAME = "ChangePhone";

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        isDesktop: PropTypes.bool,
        isSubmitting: PropTypes.bool,
        fetching: PropTypes.bool,
    },
    defaults: { isDesktop: false, isSubmitting: false, fetching: false },
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(SelectorsActionSettings.getUserData());
    }

    changePhoneForm = () => {
        const { isDesktop, isSubmitting } = this.props;

        return (
            <Form className="above-the-fold">
                <section className="container--layout flex-grow align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-lead">
                                    <I18n id="settings.changePhone.explanation" />
                                </p>
                                <Field
                                    idForm={FORM_ID}
                                    name="mobilePhone"
                                    type="text"
                                    component={TextField}
                                    autoFocus={isDesktop}
                                />
                            </Col>
                        </Row>
                    </Grid>
                </section>

                <section className="container--layout">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <hr />
                                <h4>
                                    <I18n id="settings.changePhone.verifyWithCredential" />
                                </h4>
                                <Field idForm={FORM_ID} name="pin" component={Credential} type="pin" />
                            </Col>
                        </Row>
                    </Grid>
                </section>
                <section className="container--layout">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Button type="submit" bsStyle="primary" label="global.modify" loading={isSubmitting} />
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </Form>
        );
    };

    render() {
        const { fetching } = this.props;
        return (
            <React.Fragment>
                <Head title="settings.changePhone" closeLinkTo="/settings" />
                <MainContainer showLoader={fetching}>{this.changePhoneForm()}</MainContainer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    currentPhone: SelectorsStoreSettings.getMobilePhone(state),
});

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

Yup.addMethod(Yup.string, "phone", function YupPhoneChecker() {
    return this.test({
        name: "phone",
        exclusive: true,
        message: i18n.get(`${FORM_ID}.mobilePhone.mustBeAPhoneNumber`),
        test: (value) => {
            try {
                const phone = phoneUtil.parse(value, URUGUAY);
                return phoneUtil.isValidNumber(phone);
            } catch (e) {
                return false;
            }
        },
    });
});

export default compose(
    withRouter,
    connect(mapStateToProps),
    HighOrder.Resizable,
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            mobilePhone: props.currentPhone || "",
            pin: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                mobilePhone: Yup.string()
                    .required(i18n.get(`${FORM_ID}.mobilePhone.required`))
                    .phone(),
                pin: Yup.string().required(i18n.get(`${FORM_ID}.pin.required`)),
            }),
        handleSubmit: ({ mobilePhone, pin }, formikBag) => {
            formikBag.props.dispatch(SelectorsActionSettings.sendMobilePhoneCode({ mobilePhone, pin, formikBag }));
        },
    }),
)(Component);
