import React from "react";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { replace as Replace } from "connected-react-router";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router";

import { ENVIRONMENT_TYPE, EMPTY_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreCommunication } from "~/store/communications";
import { SelectorsAction as SelectorsActionSession, SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as i18n from "~/util/i18n";
import { LastLogin } from "~/util/settings";

import Buttons from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";
import ComponentMenu from "~/components/Menu";
import Badge from "~/pages/_components/Badge";
import Dropdown from "~/pages/_components/Dropdown";
import EnvironmentSelector from "~/pages/_components/EnvironmentSelector";
import Header from "~/pages/_components/Header";
import Menu from "~/pages/_components/Menu";

export const NAME = "Head";

export const PROP = {
    types: {
        // i18n head title
        title: PropTypes.string,
        // specify a translated text instead a i18n key
        titleText: PropTypes.string,
        // back link url
        backLinkTo: PropTypes.string,
        // back function, ignored if backLinkTo is specified
        onBack: PropTypes.func,
        // close link url
        closeLinkTo: PropTypes.string,
        // onClose function link, ignored if closeLinkTo is specified
        onClose: PropTypes.func,
        // link url, it links located under title
        linkTo: PropTypes.string,
        // I18n id of link text, it is located under title
        linkText: PropTypes.string,
        // head logo
        logo: PropTypes.element,
        // called right after button with closeLinkTo is clicked
        onCloseClick: PropTypes.func,
        handleClick: PropTypes.func,
        handleInviteClick: PropTypes.func,
        hasActiveSession: PropTypes.bool.isRequired,
        isMobile: PropTypes.bool,
        dispatch: PropTypes.func.isRequired,
        onAdd: PropTypes.bool,
        addLinkTo: PropTypes.string,
        addLinkToLabel: PropTypes.string,
        isDesktop: PropTypes.bool,
        exportList: PropTypes.bool,
        isFetchingExport: PropTypes.bool,
        unreadCommunications: PropTypes.number,
        children: PropTypes.element,
        user: PropTypes.objectOf(PropTypes.object),
        activeEnvironment: PropTypes.objectOf(PropTypes.object),
        environments: PropTypes.objectOf(PropTypes.object),
        match: PropTypes.objectOf(PropTypes.object).isRequired,
        handleClickAdd: PropTypes.func,
    },
    defaults: {
        onCloseClick: () => {},
        handleClickAdd: () => {},
        logo: null,
        linkText: EMPTY_STR,
        linkTo: EMPTY_STR,
        addLinkTo: EMPTY_STR,
        addLinkToLabel: EMPTY_STR,
        onClose: null,
        closeLinkTo: null,
        handleClick: null,
        handleInviteClick: null,
        children: null,
        onBack: null,
        backLinkTo: null,
        titleText: EMPTY_STR,
        title: EMPTY_STR,
        onAdd: false,
        isMobile: false,
        isDesktop: false,
        exportList: false,
        isFetchingExport: false,
        unreadCommunications: undefined,
        user: {},
        activeEnvironment: {},
        environments: [],
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        menuMobile: false,
    };

    renderLeftOption = () => {
        const { backLinkTo, onBack } = this.props;
        if (backLinkTo) {
            return (
                <div className="toolbar-item toolbar-item--fixed toolbar-item-left">
                    <Link className="btn toolbar-btn view-back" to={backLinkTo}>
                        <Image className="svg-icon svg-caret" src="arrowLeft.svg" />
                    </Link>
                </div>
            );
        }
        if (onBack) {
            return (
                <div className="toolbar-item toolbar-item--fixed toolbar-item-left">
                    <Button onClick={onBack} className="toolbar-btn view-back">
                        <Image className="svg-icon svg-caret" src="arrowLeft.svg" />
                    </Button>
                </div>
            );
        }
        return null;
    };

    renderCollapsedMenu = () => {
        const { hasActiveSession, isMobile, user, activeEnvironment, match, environments } = this.props;
        const { name, productGroupId } = environments[activeEnvironment.id];
        const { menuMobile } = this.state;
        const previousLoginInfo = user ? user.previousLoginInfo : null;
        const lastLogin = !(previousLoginInfo && previousLoginInfo.length)
            ? null
            : {
                  date: LastLogin.date(previousLoginInfo[0]),
                  place: LastLogin.place(previousLoginInfo[0]),
              };

        if (hasActiveSession && isMobile) {
            return (
                <React.Fragment>
                    <Button
                        onClick={() => this.setState({ menuMobile: !menuMobile })}
                        className="navbar-toggle collapsed"
                    />
                    <SwipeableDrawer
                        className="swiper-menu"
                        open={menuMobile}
                        onClose={() => this.setState({ menuMobile: false })}
                        onOpen={() => this.setState({ menuMobile: true })}>
                        <div tabIndex={0} role="button" onKeyDown={() => this.setState({ menuMobile: false })}>
                            <div className="menu-mobile-head">
                                <span className="environment-name">{name}</span>
                                <span className="environment-number">
                                    <I18n id="menu.account.number" /> {productGroupId}
                                </span>
                            </div>
                            <div className="menu-mobile">
                                <Menu className="" isMobile={isMobile} path={match.url} />
                            </div>
                            <div className="menu-mobile-foot">
                                <ComponentMenu.Item
                                    className="menu-list-item logout"
                                    title="global.logout"
                                    onClick={() => this.handleLogoutClick()}
                                    image="cerrarSesion.svg"
                                />
                                {/* TODO: hardcoded para trabajo de estilos */}
                                <span className="last-loggin-info">
                                    <I18n id="menu.last.access" /> {lastLogin ? lastLogin.date : "29/11/2018 15:10hs"}
                                </span>
                            </div>
                        </div>
                    </SwipeableDrawer>
                </React.Fragment>
            );
        }

        return null;
    };

    close = () => {
        const { onCloseClick, dispatch, closeLinkTo } = this.props;
        onCloseClick();
        dispatch(
            Replace({
                pathname: closeLinkTo,
                state: { transition: "transition-flow-close" },
            }),
        );
    };

    add = () => {
        const { onAdd, dispatch, addLinkTo, handleClickAdd } = this.props;
        if (onAdd) {
            onAdd();
        } else {
            handleClickAdd();
            dispatch(
                Replace({
                    pathname: addLinkTo,
                    state: { transition: "transition-flow-close" },
                }),
            );
        }
    };

    renderRightOption = () => {
        const {
            addLinkTo,
            onAdd,
            isDesktop,
            addLinkToLabel,
            closeLinkTo,
            onClose,
            exportList,
            hasActiveSession,
            isMobile,
            unreadCommunications,
        } = this.props;

        if (addLinkTo || onAdd) {
            const classes = isDesktop ? "btn-outline" : "toolbar-btn view-close";

            return (
                <div className="toolbar-item toolbar-item--fixed toolbar-item-right">
                    <Buttons
                        onClick={this.add}
                        className={classes}
                        image="plus.svg"
                        label={isDesktop ? addLinkToLabel : ""}
                    />
                </div>
            );
        }
        if (closeLinkTo) {
            return (
                <div className="toolbar-item toolbar-item--fixed toolbar-item-right">
                    <Buttons
                        className="toolbar-btn view-close"
                        onClick={this.close}
                        image="cross.svg"
                        label="global.close"
                    />
                </div>
            );
        }
        if (onClose) {
            return (
                <div className="toolbar-item toolbar-item-right toolbar-item--fixed">
                    <Button onClick={onClose} className="toolbar-btn view-close">
                        <Image src="cross.svg" />
                        <span>
                            <I18n id="global.close" />
                        </span>
                    </Button>
                </div>
            );
        }
        if (exportList) {
            const { handleClick, handleInviteClick, isFetchingExport } = this.props;

            return (
                <React.Fragment>
                    {handleInviteClick && (
                        <div className="toolbar-item toolbar-item--fixed toolbar-item-right">
                            <Buttons
                                onClick={handleInviteClick}
                                className="btn btn-small btn-outline"
                                image="plus.svg"
                                label="administration.users.list.addUser"
                            />
                        </div>
                    )}

                    <div className="toolbar-item toolbar-item--fixed">
                        <Dropdown
                            image="download.svg"
                            label="global.download"
                            buttonClass="btn btn-outline"
                            fetching={isFetchingExport}
                            pullRight>
                            <div role="button" addLinkTo={() => handleClick("pdf")}>
                                {i18n.get("global.download.pdf")}
                            </div>
                            <div role="button" onClick={() => handleClick("xls")}>
                                {i18n.get("global.download.xls")}
                            </div>
                        </Dropdown>
                    </div>
                </React.Fragment>
            );
        }
        if (hasActiveSession && isMobile && !closeLinkTo && !onClose) {
            return (
                <div className="toolbar-item toolbar-item--fixed toolbar-item-right">
                    <Link className="btn toolbar-btn view-back" to="/communications">
                        <Badge count={unreadCommunications} />
                        <Image className="svg-icon svg-caret" src="header-notifications.svg" />
                    </Link>
                </div>
            );
        }
        return null;
    };

    handleLogoutClick = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionSession.logout());
    };

    renderCenterContent = () => {
        const { children, logo, replace, title, titleText, activeEnvironment } = this.props;
        const type = activeEnvironment ? activeEnvironment.type : undefined;

        const logoSrc = type === ENVIRONMENT_TYPE.PREMIER ? "logo-hsbc-premier" : "logo-hsbc";
        return (
            (title || titleText || logo) && (
                <React.Fragment>
                    {logo && <Image className="svg-icon mobile-header-logo" src={`${logoSrc}.svg`} />}
                    {title && (
                        <h2>
                            <I18n id={title} {...replace} />
                        </h2>
                    )}
                    {children}
                    {titleText && <h2>{titleText}</h2>}

                    {this.getCenterOption()}
                </React.Fragment>
            )
        );
    };

    getCenterOption = () => {
        const { linkTo, linkText } = this.props;
        if (linkTo) {
            return (
                <p>
                    <Link to={linkTo}>
                        <I18n id={linkText} />
                    </Link>
                </p>
            );
        }
        return null;
    };

    render() {
        const { logo, isMobile, backLinkTo, onBack } = this.props;
        return (
            <div className="view-header-mobile">
                <Header>
                    {this.renderLeftOption()}

                    <div className="toolbar-item view-title">{this.renderCenterContent()}</div>

                    {this.renderRightOption()}
                    {!backLinkTo && !onBack && this.renderCollapsedMenu()}
                </Header>
                {logo && isMobile && <EnvironmentSelector isMobile />}
            </div>
        );
    }
}

const mapStateToProps = (store) => ({
    hasActiveSession: SelectorsStoreSession.isLoggedIn(store),
    unreadCommunications: SelectorsStoreCommunication.getUnreadCommunications(store),
    user: SelectorsStoreSession.getUser(store),
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
    environments: SelectorsStoreSession.getEnvironments(store),
});

export default HighOrder(WithRouter, Connect(mapStateToProps), HighOrder.Resizable)(Component);
