import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SelectorsStore as SelectorsStorePermissions } from "~/store/administration/common/permissions";
import * as UtilAdministration from "~/util/administration";
import * as UtilArray from "~/util/array";

export const NAME = "PermissionsAmount";

export const PROP = {
    types: {
        groups: UtilAdministration.groupsPropType.isRequired,
        permissions: UtilAdministration.permissionsPropType.isRequired,
        products: PropTypes.arrayOf(
            PropTypes.shape({
                idProduct: PropTypes.string,
            }),
        ),
        children: PropTypes.func.isRequired,
    },
    defaults: { products: [] },
};
export function Component(props) {
    const { children, groups, permissions } = props;

    const countOptions = ({ childrenList, permissionList, idItem }, predicate) => {
        if (permissionList.length && permissionList[0].simpleAllowProductSelection) {
            const { products } = props;

            return predicate ? predicate(idItem, products) : products.length;
        }

        if (childrenList.length) {
            return childrenList.reduce((amount, option) => amount + countOptions(option, predicate), 0);
        }

        if (predicate && !predicate(idItem)) {
            return 0;
        }

        return 1;
    };

    return children(
        groups.reduce((amounts, group) => {
            const amount = countOptions(group, (idItem, products) => {
                if (products) {
                    return UtilArray.intersection(
                        permissions[idItem] || [],
                        products.map(({ idProduct }) => idProduct),
                    ).length;
                }

                return permissions[idItem] && permissions[idItem].length;
            });

            if (!amount) {
                return amounts;
            }

            return {
                ...amounts,
                [group.idItem]: amount,
            };
        }, {}),
        groups.reduce((amounts, group) => ({ ...amounts, [group.idItem]: countOptions(group) }), {}),
    );
}

const mapStateToProps = (state, ownProps) => ({
    products: SelectorsStorePermissions.getProducts(state),
    permissions: ownProps.permissions || SelectorsStorePermissions.getPermissions(state),
    groups: SelectorsStorePermissions.getGroups(state),
});

export default Connect(mapStateToProps)(Component);
