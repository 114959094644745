import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "template";

export const PATH = {
    CORE_DELETE_TRANSACTION_TEMPLATE: "/core.deleteTransactionTemplate",
    CORE_LIST_TRANSACTION_TEMPLATES: "/core.listTransactionTemplates",
};

export const TYPE = FactoryType(NAME, [
    "CLOSE_CREATE_TEMPLATE",
    "CREATE_TEMPLATE",
    "DELETE_TEMPLATE",
    "DELETE_TEMPLATE_FAILURE",
    "DELETE_TEMPLATE_SUCCESS",
    "LOAD_TEMPLATE_FROM_OTHER_PAGE",
    "LOAD_TEMPLATE_LIST",
    "LOAD_TEMPLATE_LIST_FAILURE",
    "LOAD_TEMPLATE_LIST_SUCCESS",
    "PERSIST_LOADED_TEMPLATE_NAME",
    "SAVE_TEMPLATE_FAILURE",
    "SAVE_TEMPLATE_REQUEST",
    "SAVE_TEMPLATE_SUCCESS",
    "SET_SELECTED_TEMPLATE",
    "TOGGLE_CONFIRMATION_MODAL",
    "TOGGLE_TEMPLATE_BACK",
    "TOGGLE_TEMPLATE_LIST",
]);

export const PROP = {};

PROP.members = {};

PROP.types = {
    createTemplateModalVisible: PropTypes.bool,
    fetching: PropTypes.bool,
    idFormLoadedTemplate: PropTypes.string,
    list: PropTypes.array,
    loadedTemplateName: PropTypes.string,
    loadTemplateFromOtherPage: PropTypes.bool,
    showConfirmationModal: PropTypes.bool,
    templateListModalVisible: PropTypes.bool,
    ...TypesRedux,
};

PROP.defaults = {
    createTemplateModalVisible: false,
    fetching: false,
    idFormLoadedTemplate: EMPTY_STR,
    list: [],
    loadedTemplateName: EMPTY_STR,
    loadTemplateFromOtherPage: false,
    showConfirmationModal: false,
    templateListModalVisible: false,
    ...DefaultsRedux,
};
