import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";

import { COMMA, EMPTY_STR, LANGUAGE, LEVEL, ONLY_NUMBER, SCOPE, SPACE_STR } from "~/constants";
import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import {
    SelectorsStore as SelectorsStoreComexImport,
    SelectorsAction as SelectorsActionComexImport,
    PROP as PropComex,
} from "~/store/comex/import";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsAction as SelectorActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionTemplate } from "~/store/template";
import * as ConfigUtil from "~/util/config";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import EmailList from "~/pages/_components/fields/EmailList";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TextArea from "~/pages/_components/fields/TextArea";
import TextField from "~/pages/_components/fields/TextField";
import AmountField from "~/pages/_components/fields/formik/AmountField";

import FileUploader from "~/pages/comEx/_components/FileUploader";
import OperationNumber from "~/pages/comEx/_components/OperationNumber";
import OperationNumberDetail from "~/pages/comEx/_components/OperationNumberDetail";
import CreateTemplateModal from "~/pages/forms/Step1Edit/_ModalTemplateCreate";
import ListTemplatesModal from "~/pages/forms/Step1Edit/_ModalTemplateList";
import { Termsandconditions } from "~/pages/forms/_components/_fields";
import Scheduler from "~/pages/forms/_components/_fields/Scheduler";
import Checkbox from "~/pages/forms/_components/_fields/_commons/Checkbox";

import Style from "../../../Step1Edit.rules.scss";
import AddDocument from "./AddDocument";
import AddDocumentModal from "./AddDocumentModal";

const FORM_ID = "comex.import.presentationLDDomesticCredit";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...PropComex.types,
    },
    defaults: {
        ...PropComex.defaults,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        documentEdition: {},
        draftLoaded: false,
        loadOperationNumber: false,
        showDocumentsModal: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(SelectorsActionComexImport.documentPresentationPreRequest());
    }

    componentDidUpdate() {
        const {
            dispatch,
            formData = {},
            match,
            preData,
            setFieldError,
            setFieldTouched,
            setFieldValue,
            setValues,
            transaction,
            values,
        } = this.props;
        const { operationNumber } = match.params;
        const { availableOperationList } = preData;
        const { draftLoaded, loadOperationNumber } = this.state;

        if (!loadOperationNumber && availableOperationList.length > 0 && !values.operationNumber && operationNumber) {
            const operationSelected = availableOperationList.filter(
                (item) => item.othersFields.NROOPERACION === operationNumber,
            );

            this.setState({
                loadOperationNumber: true,
            });

            if (operationSelected.length > 0) {
                setFieldValue("item", operationSelected[0]);
                setFieldValue("operationNumber", operationNumber);
                setFieldValue("amountField", { currency: operationSelected[0].accountCurrency });
                setFieldValue("oldAmount", operationSelected[0].balance);
            }
        }

        // si viene trasaction.data, no estoy volviendo del paso 2 y no se cargo el draft -> cargo draft
        if (transaction?.data && Object.keys(formData).length === 0 && !draftLoaded) {
            this.setState({
                draftLoaded: true,
            });

            const transactionData = transaction?.data;

            /* carga de errores */
            if (transactionData.backendFormErrors?.cdpErrorFields) {
                const { cdpErrorFields } = transactionData.backendFormErrors;

                cdpErrorFields.map((errorField) => {
                    setFieldError(errorField, SPACE_STR);
                    setFieldTouched(errorField, true);
                    return null;
                });
            }

            if (transactionData.backendFormErrors?.message) {
                const errorMessage = transactionData?.backendFormErrors?.message;

                dispatch(
                    SelectorActionNotification.showNotification({
                        message: errorMessage,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.COMEX_IMPORT_DOCUMENT_PRESENTATION],
                    }),
                );
            }

            setValues(transaction.data);
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(GoBack());
    };

    handleFormKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            event.preventDefault();
        }
    };

    handleFieldKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            this.handleSearch();
        }
    };

    handleSelectChange = (id) => {
        const { setFieldValue } = this.props;

        setFieldValue("increment", id);
    };

    handleSelectChangeFieldName = (id, fieldName) => {
        const { setFieldValue } = this.props;

        setFieldValue(fieldName, id);
    };

    handleItemSelect = (item) => {
        const { setFieldError, setFieldValue } = this.props;
        const { othersFields } = item;

        if (othersFields) {
            setFieldValue("operationNumber", othersFields.NROOPERACION);
            setFieldValue("item", item);
            setFieldError("operationNumber", null);
            setFieldValue("amountField", { amount: EMPTY_STR, currency: item.accountCurrency });
            setFieldValue("oldAmount", item.balance);
            setFieldValue(`formattedAmount`, item.balance);
            setFieldError("operationNumber", null);
        } else {
            setFieldValue("item", null);
            setFieldValue("operationNumber", EMPTY_STR);
            setFieldValue("amountField", EMPTY_STR);
            setFieldValue("oldAmount", EMPTY_STR);
        }
    };

    handleModalLoad = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionComex.toggleCreditLetterList());
    };

    handleBlur = () => {
        const { preData, setFieldError, setFieldValue, values } = this.props;
        const { availableOperationList } = preData;

        if (values.operationNumber) {
            const operationSelected = availableOperationList.filter(
                (item) => item.othersFields.NROOPERACION === values.operationNumber,
            );

            if (operationSelected.length > 0) {
                setFieldValue("item", operationSelected[0]);
                setFieldValue("amountField", { amount: EMPTY_STR, currency: operationSelected[0].accountCurrency });
                setFieldValue("oldAmount", operationSelected[0].balance);
                setFieldValue(`formattedAmount`, operationSelected[0].balance * -1);
                setFieldError("operationNumber", null);
            } else {
                setFieldValue("item", null);
                setFieldValue("operationNumber", EMPTY_STR);
                setFieldValue("amountField", EMPTY_STR);
                setFieldValue("oldAmount", EMPTY_STR);
            }
        } else {
            setFieldValue("item", null);
            setFieldValue("operationNumber", EMPTY_STR);
            setFieldValue("amountField", EMPTY_STR);
            setFieldValue("oldAmount", EMPTY_STR);
        }
    };

    handleCancel = () => {
        const { dispatch, setSubmitting, transaction } = this.props;

        dispatch(
            SelectorsActionForm.cancelTransactionCustom({
                credentials: {},
                idTransaction: transaction.idTransaction,
                formikBag: { setSubmitting },
            }),
        );
    };

    handleDraft = () => {
        const { dispatch, transaction, values } = this.props;

        dispatch(
            SelectorsActionForm.saveDraft({
                idActivityDraft: "comex.import.creditLetterDocumentPresentation.send",
                idTransactionToSave: transaction?.idTransaction,
                transactionData: values,
            }),
        );
    };

    handleTemplateSave = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionTemplate.createTemplate());
    };

    handleTemplateLoad = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionTemplate.toggleTemplateList());
    };

    handleSelectTemplate = (template) => {
        const { initialValues, setErrors, setValues } = this.props;

        setValues(initialValues);
        setValues(template);
        setErrors({});
    };

    handleCheckChange = (fieldName) => {
        const { setFieldValue, values } = this.props;

        setFieldValue(fieldName, !values[fieldName]);
    };

    editDocument = (doc) => {
        this.setState({
            documentEdition: doc,
            showDocumentsModal: true,
        });
    };

    getAvailableDocumentTypeList = () => {
        const {
            preData: { documentList },
            values: { documents },
        } = this.props;

        if (!documents.length) {
            return documentList;
        }

        const unavailableDocs = documents.filter((d) => d.type !== "OTROS" && d.type !== "FACTURA_COMERCIAL");

        return documentList.filter((d) => !unavailableDocs.find((d2) => d2.type === d));
    };

    addEditDocument = (doc) => {
        const { setFieldError, setFieldValue, values } = this.props;
        const { documentEdition } = this.state;

        let newDocuments;

        if (Object.entries(documentEdition).length > 0) {
            const index = values.documents.findIndex((d) => d === documentEdition);

            newDocuments = [...values.documents];
            newDocuments[index] = doc;
        } else {
            newDocuments = [...values.documents].concat(doc);

            setFieldError("documents", EMPTY_STR);
        }

        setFieldValue("documents", newDocuments);

        this.setState({
            documentEdition: {},
        });
    };

    handleDocumentModal = (show) => {
        this.setState({ showDocumentsModal: show });
    };

    render() {
        const { emailValidationRegex, errors, preData, setFieldValue, transaction, values } = this.props;
        const { availableOperationList, documentList, nextValidDate, unavailableDays } = preData;
        const termsAndConditions = "comex.import.presentationLDDomesticCredit.disclaimer";
        const i18nMap = {
            termsAndConditions,
        };
        const { documentEdition, showDocumentsModal } = this.state;
        const cancelEnabled = transaction && transaction.idTransactionStatus === STATUS.DRAFT;
        const othersFields = values.item ? values.item.othersFields : null;

        const currency = othersFields ? othersFields.MONEDA : EMPTY_STR;

        const data = {
            options: [
                {
                    id: currency,
                    label: UtilsI18n.get(`currency.label.${currency}`),
                },
            ],
        };

        let documentListFilter = [];

        const addDocumnets = values.documents
            ?.map((doc) => doc.type)
            .filter((type) => type !== "FACTURA_COMERCIAL" && type !== "OTROS");

        if (documentList) {
            documentListFilter = documentList.filter((doc) => !addDocumnets.includes(doc));
        }

        return (
            <React.Fragment>
                <Form
                    className="col col-12 col-lg-6 col-md-9 col-sm-12"
                    id={Style.ID}
                    noValidate="novalidate"
                    onKeyDown={this.handleFormKeyDown}>
                    <div className="form-section loadTemplates">
                        <Button
                            onClick={this.handleTemplateLoad}
                            label="forms.templates.load"
                            bsStyle="default"
                            image="template.svg"
                        />
                    </div>
                    <section className="fields container--layout align-items-center flex-grow">
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.section.operation`)}</h3>
                        </Box>

                        <Box flex align="end" className="form-group search-field">
                            <Field
                                idForm={FORM_ID}
                                name="operationNumber"
                                hidePlaceholder
                                pattern={ONLY_NUMBER}
                                type="text"
                                component={TextField}
                                onBlur={this.handleBlur}
                                tooltip={UtilsI18n.get(`${FORM_ID}.operationNumber.tooltip`)}
                            />

                            <Box className="ml-3 mb-2">
                                <Button
                                    onClick={this.handleModalLoad}
                                    bsStyle="primary"
                                    label="global.search"
                                    className="search"
                                />
                            </Box>
                        </Box>

                        {values.item && <OperationNumberDetail othersFields={othersFields} idForm={FORM_ID} />}

                        <Field
                            idForm={FORM_ID}
                            name="invoiceNumber"
                            hidePlaceholder
                            type="text"
                            component={TextField}
                            maxLength={80}
                        />

                        <Field
                            clearable={false}
                            component={AmountField}
                            data={data}
                            idForm={FORM_ID}
                            name="amountField"
                            placeholder={EMPTY_STR}
                            searchable={false}
                            decimalPlaces={2}
                            fixedDecimalScale
                            hideCurrency={!currency}
                            handleBlur={this.handleAmountBlur}
                            handleChange={(formattedValue) => {
                                setFieldValue(`formattedAmount`, formattedValue);
                            }}
                            tooltip={UtilsI18n.get(`${FORM_ID}.utilizationAmount.tooltip`)}
                            maxLength={ConfigUtil.getInteger("amount.length")}
                        />

                        <Box className="form-group">
                            <FieldLabel labelKey={`${FORM_ID}.discrepancies.label`} classContainer="check-field" />
                            <Checkbox
                                checked={values.discrepancies}
                                name="discrepancies"
                                onChange={() =>
                                    this.handleSelectChangeFieldName(!values.discrepancies, "discrepancies")
                                }
                            />
                        </Box>
                        <Box className="form-group show">
                            <AddDocument
                                formId={FORM_ID}
                                {...this.props}
                                editDocument={this.editDocument}
                                showDocumentModal={() => this.handleDocumentModal(true)}
                            />
                        </Box>

                        <Box className="form-group">
                            <Field
                                name="files"
                                idForm={FORM_ID}
                                emptyMessageKey={`${FORM_ID}.attachments.emptyMessage`}
                                maxFiles={5}
                                errors={errors}
                                component={FileUploader}
                                values={values}
                                setFieldValue={setFieldValue}
                            />
                        </Box>

                        <Field
                            component={TextArea}
                            idForm={FORM_ID}
                            name="observations"
                            mode={MODE.EDIT}
                            className="form-control"
                            maxLength={500}
                            hidePlaceholder
                        />

                        <Field
                            component={EmailList}
                            idForm={FORM_ID}
                            name="notificationEmailsField"
                            hideSwiftTip
                            className="form-group email-field"
                            renderSuggestion={false}
                            value={values.notificationEmailsField}
                            data={{ emailValidationRegex, addMessage: EMPTY_STR }}
                            optional={UtilsI18n.get("form.field.optional")}
                            tooltip={UtilsI18n.get(`${FORM_ID}.notificationEmails.tooltip`)}
                        />

                        <hr />
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">
                                {UtilsI18n.get(`${FORM_ID}.section.showPreview`)}
                            </h3>
                        </Box>

                        <Field
                            component={Termsandconditions}
                            idForm={FORM_ID}
                            name="disclaimer"
                            mode={MODE.EDIT}
                            className="form-control"
                            i18nMap={i18nMap}
                        />
                        {nextValidDate && (
                            <Field
                                component={Scheduler}
                                data={{
                                    firstWorkingDate: nextValidDate,
                                    maxDaysToSchedule: ConfigUtil.get("client.comex.valueDate.maxDaysToSchedule"),
                                    nonWorkingDays: unavailableDays,
                                    lang: LANGUAGE.ES,
                                    mode: MODE.EDIT,
                                    programable: false,
                                    schedulable: true,
                                }}
                                name="scheduler"
                                idForm={FORM_ID}
                            />
                        )}
                    </section>

                    <footer>
                        <Button onClick={this.handleDraft} label="forms.saveDraft.link" />
                        {cancelEnabled && <Button onClick={this.handleCancel} label="forms.cancelTransaction.link" />}
                        <Button type="submit" label="global.next" bsStyle="primary" />
                    </footer>
                </Form>
                <AddDocumentModal
                    show={showDocumentsModal}
                    terms={values.terms}
                    submit={this.addEditDocument}
                    handleCloseModal={() => {
                        this.handleDocumentModal(false);
                        this.setState({ documentEdition: {} });
                    }}
                    document={documentEdition}
                    documentTypeList={documentListFilter}
                />
                <OperationNumber
                    list={availableOperationList}
                    idForm={FORM_ID}
                    handleItemClick={this.handleItemSelect}
                />
                <CreateTemplateModal
                    values={values}
                    idActivityTemplate="comex.import.creditLetterDocumentPresentation.send"
                />
                <ListTemplatesModal
                    idActivityTemplate="comex.import.creditLetterDocumentPresentation.send"
                    onSelect={this.handleSelectTemplate}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        formData: SelectorsStoreComexImport.getDocumentPresentationFormData(store),
        preData: SelectorsStoreComexImport.getDocumentPresentationPre(store),
        transaction: SelectorsStoreComexImport.getTransaction(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: false,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ formData }) => {
            return {
                amountField: formData?.amountField || { amount: EMPTY_STR },
                discrepancies: formData?.discrepancies || false,
                documents: formData?.documents || [],
                files:
                    formData?.attachments && formData?.attachments !== EMPTY_STR
                        ? formData?.attachments.split(COMMA)
                        : [],
                formattedAmount: formData?.formattedAmount || EMPTY_STR,
                invoiceNumber: formData?.invoiceNumber || EMPTY_STR,
                item: formData?.item || null,
                notificationEmailsField: formData?.notificationEmailsField || [],
                observations: formData?.observations || EMPTY_STR,
                oldAmount: formData?.oldAmount,
                operationNumber: formData?.operationNumber || EMPTY_STR,
                originalAmount: formData?.originalAmount,
                scheduler: formData?.scheduler,
            };
        },
        handleSubmit: (props, formikBag) => {
            const { dispatch, transaction } = formikBag.props;
            const { amountField, files, item, oldAmount, scheduler, ...rest } = props;

            const invoiceAmountCurrency = item?.othersFields ? item.othersFields.MONEDA : EMPTY_STR;
            const utilizationAmount = amountField.amount;
            const utilizationAmountCurrency = amountField.currency;

            const formData = {
                ...rest,
                amount: { currency: amountField.currency, quantity: amountField.amount },
                amountField,
                attachments: files && files.length > 0 ? files.join(COMMA) : EMPTY_STR,
                idTransaction: transaction?.idTransaction,
                invoiceAmountCurrency,
                item,
                oldAmount,
                originalAmountCurrency: amountField.currency,
                originalAmount: oldAmount,
                scheduler,
                utilizationAmount,
                utilizationAmountCurrency,
                valueDate: scheduler.valueDate,
            };

            dispatch(
                SelectorsActionComexImport.documentPresentationPreviewRequest({
                    formData,
                    formikBag,
                }),
            );
        },
    }),
)(Component);
