import React from "react";

import { goBack as GoBack, push as Push } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/Filter";
import {
    SelectorsAction as SelectorsActionAutomaticDebits,
    SelectorsStore as SelectorsStoreAutomaticDebits,
} from "~/store/automaticDebits";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button";

import Style from "./List.rules.scss";
import AutomaticDebits from "./_AutomaticDebits";

export const { NAME, NAME_AUTOMATIC_DEBITS } = Style;

export const PROP = {
    types: {
        fetching: PropTypes.bool.isRequired,
        ...TypesRedux,
    },
    defaults: {
        i18n: {
            listAutomaticDebitsSubTitle: "Débitos automáticos.",
        },
        ...DefaultsRedux,
    },
};
export function Component(props) {
    const { fetching, i18n, dispatch } = props;

    React.useEffect(() => {
        dispatch(SelectorsActionAutomaticDebits.listAutomaticDebitsRequest());
    }, [dispatch]);

    const { listAutomaticDebitsSubTitle } = i18n;
    return (
        <React.Fragment>
            <Container
                wait={fetching}
                name={NAME}
                head-onBack={handleBack}
                head-title="menu.payments.automaticDebits"
                image="paymentOrders.svg"
                scopeToShowNotification={SCOPE.AUTOMATIC_DEBITS}>
                <section>
                    <Button
                        id="createAutomaticDebit"
                        bsStyle="link"
                        block={false}
                        label="client.payment.createAutomaticDebit.label"
                        onClick={handleOpenForm}
                    />

                    <AutomaticDebits
                        container-title={listAutomaticDebitsSubTitle}
                        container-role="list"
                        name={NAME_AUTOMATIC_DEBITS}
                    />
                </section>
            </Container>
        </React.Fragment>
    );

    function handleBack() {
        dispatch(GoBack());
    }

    function handleOpenForm() {
        dispatch(Push("/form/createAutomaticDebit"));
    }
}

Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

const mapStateToProps = (store) => ({
    fetching: SelectorsStoreAutomaticDebits.getFetching(store),
    i18n: {
        listAutomaticDebitSubTitle: StoreI18n.getMessage(store, "client.payments.automaticDebit.list.subTitle"),
    },
});

export default Connect(mapStateToProps)(Component);
