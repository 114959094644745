import React from "react";

import PropTypes from "prop-types";
import { NavDropdown } from "react-bootstrap";

import { EMPTY_STR } from "~/constants";
import { generateId as GenID } from "~/util/general";

import Item, { PROP as PropItem } from "./_Item";

export const NAME = "MenuSection";

export const PROP = {};
PROP.members = {
    /* propTypes for Children sub-component. */
    typesChildren: {
        children: PropTypes.node,
        /** When there are no children and this prop is sent, A unique Item is created automagically. */
        href: PropTypes.string,
        /** When there are no children and this prop is sent, A Item is generated for each form in the array  */
        items: PropTypes.arrayOf(PropTypes.shape(PropItem.types)),
    },
    /** defaultProps for Children sub-component */
    defaultsChildren: {
        children: null,
        href: null,
        items: [],
    },
};
PROP.types = {
    /** Should a different className be used? default: "menu-list-item" */
    className: PropTypes.string,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
    ...PropItem.members.typesContent,
    ...PROP.members.typesChildren,
};

PROP.defaults = {
    className: "menu-list-item",
    isSelected: false,
    onClick: null,
    ...PROP.members.defaultsContent,
    ...PROP.members.defaultsChildren,
};

/**
 * The Exposed component.
 * Renders a NavDropdown using a Item.Content component as title and lays the ground to use Items as children.
 * @param {PROP.types} props
 */
export function Component(props) {
    const { className, isSelected, onClick } = props;
    return (
        <NavDropdown
            onClick={onClick}
            title={<Item.Content {...props} />}
            id="navigation bar"
            className={className.concat(isSelected ? " selected" : EMPTY_STR)}
            active={isSelected}>
            <Component.Children {...props} />
        </NavDropdown>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

/**
 * Renders one of the following:
 * - The children elements sent when consuming the component.
 * - An automagically generated Item with the same name of the section. (href prop)
 * @param {PROP.members.typesChildren} props
 */
Component.Children = function Children(props) {
    const { children, href, title } = props;
    let { items } = props;
    if (href) {
        // title and href are available, prepend it as item.
        items = [{ title, href }].concat(items);
    }
    return (
        <React.Fragment>
            {children}
            {items.map((item) => (
                <Item key={GenID()} title={item.title} href={item.href} />
            ))}
        </React.Fragment>
    );
};
Component.Children.propTypes = PROP.members.typesChildren;
Component.Children.defaultProps = PROP.members.defaultsChildren;

export default Component;
