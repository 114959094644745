import { FactoryAction } from "~/util/redux";
import { getScheme as GetScheme } from "~/util/store/administration";

import { NAME } from "./_consts";

export const SelectorsAction = (TYPE) => ({
    loadPermissionsRequest: (props) => FactoryAction(TYPE.LOAD_PERMISSIONS_REQUEST, props), // id
    loadPermissionsFailure: () => FactoryAction(TYPE.LOAD_PERMISSIONS_FAILURE),
    loadPermissionsSuccess: (props) => FactoryAction(TYPE.LOAD_PERMISSIONS_SUCCESS, props), // data
    updatePermissionsPreview: (props) => FactoryAction(TYPE.UPDATE_PERMISSIONS_PREVIEW, props), // data  setSubmitting
    updatePermissionsPreviewSuccess: (props) => FactoryAction(TYPE.UPDATE_PERMISSIONS_PREVIEW_SUCCESS, props), // permissions credentialGroups idTransaction idActivity
    updatePermissionsRequest: (props) => FactoryAction(TYPE.UPDATE_PERMISSIONS_REQUEST, props), // data formikBag
    updatePermissionsRequestSuccess: () => FactoryAction(TYPE.UPDATE_PERMISSIONS_REQUEST_SUCCESS),

    // ticket
    loadPermissionsTicketRequest: (props) => FactoryAction(TYPE.LOAD_PERMISSIONS_TICKET_REQUEST, props), // idTransaction
    loadPermissionsTicketFailure: () => FactoryAction(TYPE.LOAD_PERMISSIONS_TICKET_FAILURE),
    loadPermissionsTicketSuccess: () => FactoryAction(TYPE.LOAD_PERMISSIONS_TICKET_SUCCESS),
});

export const SelectorsStore = {
    getProducts: (store) => (GetScheme(store) ? GetScheme(store)[NAME].products : []),
    getMappedProducts: (store) =>
        GetScheme(store)[NAME].products.map(({ idProduct, productAlias, productType }) => ({
            value: idProduct,
            productAlias,
            productType,
        })),
    getPermissions: (store) => GetScheme(store)[NAME].permissions,
    getFuturePermissions: (store) => GetScheme(store)[NAME].futurePermissions,
    getGroups: (store) => (GetScheme(store) ? GetScheme(store)[NAME].groups : []),
    isFetching: (store) => GetScheme(store)[NAME].fetching,
    getIdTransaction: (store) => GetScheme(store)[NAME].idTransaction,
    getIdActivity: (store) => GetScheme(store)[NAME].idActivity,
    getCredentialGroups: (store) => GetScheme(store)[NAME].credentialGroups,
    getCaps: (store) => GetScheme(store)[NAME].caps,
    getCapFrequencyList: (store) => GetScheme(store)[NAME].capFrequencyList,
    isFetchingTicket: (store) => GetScheme(store)[NAME].fetchingTicket,
};
