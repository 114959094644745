import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "swift";

export const PATH = {
    SWIFT_MESSAGES_LIST: "/client.swift.ListSwift",
    SWIFT_PRE_MESSAGES_LIST: "/client.swift.ListSwiftPre",
    SWIFT_MESSAGES_DETAIL: "/client.swift.readSwift.execute",
    SWIFT_MESSAGES_DETAIL_SEND_MAIL: "/client.swift.sendMail",
    PDF_SWIFT_MESSAGES_DETAIL: "/client.swift.readSwift.pdf",
};

export const TYPE = FactoryType(NAME, [
    "LOAD_LIST_PRE_SWIFT_MESSAGES_REQUEST",
    "LOAD_LIST_PRE_SWIFT_MESSAGES_FAILURE",
    "LOAD_LIST_PRE_SWIFT_MESSAGES_SUCCESS",
    "LOAD_LIST_SWIFT_MESSAGES_REQUEST",
    "LOAD_LIST_SWIFT_MESSAGES_FAILURE",
    "LOAD_LIST_SWIFT_MESSAGES_SUCCESS",
    "SWIFT_MESSAGES_DETAIL_REQUEST",
    "SWIFT_MESSAGES_DETAIL_FAILURE",
    "SWIFT_MESSAGES_DETAIL_SUCCESS",
    "SWIFT_MESSAGES_DETAIL_SEND_MAIL_REQUEST",
    "SWIFT_MESSAGES_DETAIL_SEND_MAIL_FAILURE",
    "SWIFT_MESSAGES_DETAIL_SEND_MAIL_SUCCESS",
    "PDF_SWIFT_MESSAGES_DETAIL_REQUEST",
    "PDF_SWIFT_MESSAGES_DETAIL_FAILURE",
    "PDF_SWIFT_MESSAGES_DETAIL_SUCCESS",
    "SET_SHOW_SEND_MAIL_MODAL",
    "RESET_FILTERS",
    "KEEP_FILTERS",
]);

export const PROP = {};

PROP.members = {
    filters: {
        period: PropTypes.string,
        incomingOutgoing: PropTypes.string,
        currency: PropTypes.string,
        beneficiary: PropTypes.string,
        reference: PropTypes.string,
    },
};

PROP.types = {
    swiftMessages: PropTypes.array,
    messageDetail: PropTypes.object,
    offset: PropTypes.number,
    nextOffset: PropTypes.number,
    moreLines: PropTypes.bool,
    filters: PropTypes.shape(PROP.members.filters),
    isFetching: PropTypes.bool,
    types: PropTypes.array,
    subTypes: PropTypes.array,
    validCurrencyTypes: PropTypes.array,
    showSendMailModal: PropTypes.bool,
    isFetchingSendMail: PropTypes.bool,
    keepFilters: PropTypes.bool,
    ...TypesRedux,
};

PROP.defaults = {
    swiftMessages: [],
    messageDetail: {},
    types: [],
    subTypes: [],
    validCurrencyTypes: [],
    offset: 1,
    moreLines: false,
    filters: {
        period: "latest",
        incomingOutgoing: "T",
        type: "all",
        subtype: "all",
        currency: EMPTY_STR,
        beneficiary: EMPTY_STR,
        reference: EMPTY_STR,
        amountFrom: null,
        amountTo: null,
    },
    isFetching: false,
    showSendMailModal: false,
    isFetchingSendMail: false,
    keepFilters: false,
    ...DefaultsRedux,
};
