import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, MIDDLE_DASH } from "~/constants";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { generateId as GenerateId } from "~/util/general";
import { Categorize } from "~/util/prop";

import { Section } from "~/components/Component";
import DataAccount from "~/components/DataAccount";
import DataDate, { PROP as PROP_DATE } from "~/components/DataDate";
import DataNumber, { PROP as PROP_NUMBER } from "~/components/DataNumber";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import IntersectionObserver from "~/components/IntersectionObserver";

import Header from "../../_Header";
import Style from "./_Detail.rules.scss";

export const { NAME, TYPE } = Style;
export const PROP = {
    types: {
        /** The main label for the Details section */
        "title-label": PropTypes.string,
        /** A route for the icon shown beside the Title on the details section. */
        "title-image": PropTypes.string,
        /** The product id sent to  title label */
        "title-data": PropTypes.string,
        /** The label for the action button */
        "button-label": PropTypes.string,
        /** Triggered when action button clicked */
        "button-onClick": PropTypes.func,
        /** Data needed to render the data slides */
        data: PropTypes.arrayOf(
            /** Each slide in the Details section */
            PropTypes.arrayOf(
                /** Each row in the Slide  */
                PropTypes.shape({
                    /** The label for the data shown */
                    label: PropTypes.string.isRequired,
                    /** The type for the data renderer ("Number") */
                    type: PropTypes.string,
                    /** The direction of the data rendered */
                    columnView: PropTypes.bool,
                    /** The props sent to the data renderer */
                    props: PropTypes.oneOf([PropTypes.shape(PROP_NUMBER), PropTypes.shape(PROP_DATE)]),
                }).isRequired,
            ).isRequired,
        ),
    },
    defaults: {
        data: null,
        "title-label": EMPTY_STR,
        "title-data": EMPTY_STR,
        "title-image": null,
        "button-label": EMPTY_STR,
        "button-onClick": null,
    },
};

const NAME_SLIDE = "slide";
const NAME_ITEM = "item";
const NAME_NAV = "nav";

const RENDERERS = {
    Account: DataAccount,
    Number: DataNumber,
    Date: DataDate,
};

/* eslint-disable react/no-array-index-key */
export function Component(props) {
    const { alias, button, data, getMessage, isCUI, isMobile, navigationText, onBack, title, ...rest } =
        Categorize(props);
    const {
        activeEnvironment: { name: enviromentName },
    } = props;
    const refButtons = React.useRef({});
    const refSection = React.useRef(null);
    const hasBody = data && data.length;
    const hasNav = hasBody && data.length > 1;

    const slashNav =
        navigationText &&
        navigationText.flatMap((value, index, array) =>
            array.length - 1 !== index // check for the last item
                ? [value, "/"]
                : value,
        );

    return (
        <div data-name="header-detail-wrapper">
            <div className="logo-print">
                <Image src="logo-hsbc-black.svg" />
            </div>
            {onBack && (
                <div className="header-navigation">
                    <Header.Button aria-label="Back" role="navigation" image="left-arrow.svg" onClick={onBack} />
                    {slashNav &&
                        slashNav.map((text, index) => {
                            if (index === slashNav.length - 3) {
                                return (
                                    <u key={`nav-${index}`} onClick={onBack}>
                                        {text}
                                    </u>
                                );
                            }
                            return <span>{text}</span>;
                        })}
                </div>
            )}
            <Section {...rest} name={NAME} type={TYPE}>
                <h2 className="box-h2">
                    <span className="name-print">{enviromentName}</span>
                    {/* screen visibility is handled in each functionality */}
                    <div>{alias && <span id="alias">{getMessage(alias) || alias}</span>}</div>
                    {(title.label || title.image || title.data) && (
                        <div className="withColor">
                            {title.label && <span id="label">{getMessage(title.label) || title.label}</span>}
                            {title.image && <Image id="img" src={title.image} />}
                            {title.data && <strong id="data">{title.data}</strong>}
                            <strong className="name-print">
                                {title.data.substring(0, title.data.indexOf(MIDDLE_DASH))}
                            </strong>
                        </div>
                    )}
                </h2>
                {hasBody && (
                    <section ref={refSection}>
                        <IntersectionObserver onIntersect={handleIntersect} settings-threshold={1.0}>
                            {data.map((values, i) => {
                                const key = `${NAME_SLIDE}-${i}`;
                                return (
                                    <ul className={(isCUI && "is-cui") || null} intersection-trigger="true" key={key}>
                                        {values.map(({ label, type, columnView, props: p }, j) => {
                                            const Renderer = RENDERERS[type];
                                            return (
                                                <li
                                                    className={columnView ? "column-view" : null}
                                                    key={`${key}-${NAME_ITEM}-${j}`}>
                                                    {label && <I18n id={label} />}
                                                    {Renderer ? <Renderer {...p} /> : <RenderRawData {...p} />}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                );
                            })}
                        </IntersectionObserver>
                    </section>
                )}
                {hasNav && (
                    <nav>
                        {data.map((_, i) => (
                            <button
                                type="button"
                                ref={(el) => {
                                    refButtons.current[i] = el;
                                }}
                                key={`${NAME_NAV}-${i}`}
                                onClick={() => handleNavClick(i)}>
                                {i}
                            </button>
                        ))}
                    </nav>
                )}
                <RenderOperationsButton />
            </Section>
        </div>
    );

    function RenderOperationsButton() {
        const { component: OperationsButton, label: buttonLabel, onClick } = button;

        // label and button event required to be rendered

        if (buttonLabel && onClick) {
            if (isMobile || (!isMobile && !OperationsButton)) {
                return (
                    <button type="button" onClick={onClick}>
                        {getMessage(buttonLabel) || buttonLabel}
                    </button>
                );
            }
            return <OperationsButton label={getMessage(buttonLabel) || buttonLabel} />;
        }
        return null;
    }

    function RenderRawData(toRender) {
        return Object.values(toRender).map((text) => <span key={GenerateId()}>{text}</span>);
    }

    function handleNavClick(i) {
        const target = refSection.current.childNodes[i];
        target.scrollIntoView();
    }

    function handleIntersect(ev) {
        const { isIntersecting, key } = ev;
        const { current: buttons } = refButtons;
        const index = parseInt(key.replace(`${NAME_SLIDE}-`, EMPTY_STR), 10);
        if (isIntersecting) {
            Object.values(buttons).forEach((el, i) => {
                el.disabled = index === i; // eslint-disable-line no-param-reassign
            });
        }
    }
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

function mapStateToProps(store) {
    return {
        activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
        getMessage: (key) => StoreI18n.getMessage(store, key),
    };
}

export default Connect(mapStateToProps)(HighOrder.Resizable(Component));
