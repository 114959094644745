import React from "react";

import { Field } from "formik";
import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { MODE } from "~/constants/form";

import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TextField from "~/pages/_components/fields/TextField";

import Select from "./Selector";

export const NAME = "SelectorOrInput";

export const PROP = {
    types: {
        idForm: PropTypes.string,
        options: PropTypes.array,
        name: PropTypes.string,
        label: PropTypes.string,
        dispatch: PropTypes.func.isRequired,
        dependencyField: PropTypes.object,
        setFieldValue: PropTypes.func.isRequired,
        renderSelector: PropTypes.bool,
    },
    defaults: {
        idForm: EMPTY_STR,
        options: [],
        name: EMPTY_STR,
        label: EMPTY_STR,
        dependencyField: [],
        renderSelector: true,
    },
};
export function Component(props) {
    const {
        idForm,
        options,
        name,
        nameOther,
        label,
        dispatch,
        dependencyField,
        setFieldValue,
        lengthOther,
        renderSelector,
        field,
        errors,
        touched,
        ...res
    } = props;
    const renderCondi =
        dependencyField.country === "858" || dependencyField.country === "32" || dependencyField.country === "76";

    React.useEffect(() => {
        if (!renderCondi) {
            setFieldValue(name, EMPTY_STR);
        }
    }, [name, renderCondi, setFieldValue]);

    const FieldText = (
        <Field
            idForm={idForm}
            name={nameOther}
            component={TextField}
            autoFocus={false}
            mode={MODE.EDIT}
            hidelabel
            hidePlaceholder
            maxLength={lengthOther}
            {...res}
        />
    );
    
    return (
        <React.Fragment>
            <FieldLabel labelKey={`${idForm}.${name}.label`} />
            {renderCondi && renderSelector ? (
                <Select name={name} options={options} setFieldValue={setFieldValue} {...res} />
            ) : (
                FieldText
            )}

            {renderSelector && renderCondi && dependencyField?.fieldMe?.toString() === "0" && FieldText}

        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;
export default Component;
