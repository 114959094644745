import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    listAdvancePaymentsOrdersRequest: (props) => API.executeWithAccessToken(PATH.ADVANCE_PAYMENTS_LIST, props),
    advancePaymentsPreview: (props) => API.executeWithAccessToken(PATH.ADVANCE_PAYMENTS_PREVIEW, props),
    advancePaymentsSend: (props) => API.executeWithAccessToken(PATH.ADVANCE_PAYMENTS_SEND, props),
};
export const SelectorsAction = {
    advancePaymentsTransactionPreviewRequest: (props) =>
        FactoryAction(TYPE.ADVANCE_PAYMENTS_TRANSACTION_PREVIEW_REQUEST, props),
    advancePaymentsTransactionPreviewFailure: (props) =>
        FactoryAction(TYPE.ADVANCE_PAYMENTS_TRANSACTION_PREVIEW_FAILURE, props),
    advancePaymentsTransactionPreviewSuccess: (props) =>
        FactoryAction(TYPE.ADVANCE_PAYMENTS_TRANSACTION_PREVIEW_SUCCESS, props),
    advancePaymentsTransactionSendRequest: (props) =>
        FactoryAction(TYPE.ADVANCE_PAYMENTS_TRANSACTION_SEND_REQUEST, props),
    advancePaymentsTransactionSendFailure: (props) =>
        FactoryAction(TYPE.ADVANCE_PAYMENTS_TRANSACTION_SEND_FAILURE, props),
    advancePaymentsTransactionSendSuccess: (props) =>
        FactoryAction(TYPE.ADVANCE_PAYMENTS_TRANSACTION_SEND_SUCCESS, props),
    cancelTransactionCustom: (props) => FactoryAction(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, props),
    cleanOrdersToDiscount: (props) => FactoryAction(TYPE.CLEAN_ORDERS_TO_DISCOUNT, props),
    listAdvancePaymentOrdersRequest: (props) => FactoryAction(TYPE.LIST_ADVANCE_PAYMENTS_REQUEST, props),
    listAdvancePaymentOrdersFailure: () => FactoryAction(TYPE.LIST_ADVANCE_PAYMENTS_FAILURE),
    listAdvancePaymentOrdersSuccess: (props) => FactoryAction(TYPE.LIST_ADVANCE_PAYMENTS_SUCCESS, props),
    readAdvancePaymentTransactionRequest: (props) => FactoryAction(TYPE.READ_TRANSACTION_REQUEST, props),
    readAdvancePaymentTransactionFailure: (props) => FactoryAction(TYPE.READ_TRANSACTION_FAILURE, props),
    readAdvancePaymentTransactionSuccess: (props) => FactoryAction(TYPE.READ_TRANSACTION_SUCCESS, props),
    resetFilters: () => FactoryAction(TYPE.RESET_FILTERS),
    restartFlow: () => FactoryAction(TYPE.RESTART_FLOW),
    returnToEditMode: (props) => FactoryAction(TYPE.CHANGE_TO_EDIT_MODE, props),
    signTransaction: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_REQUEST, props),
    signTransactionPreview: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_PREVIEW_REQUEST, props),
    signTransactionPreviewSuccess: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_PREVIEW_SUCCESS, props),
};

export const SelectorsStore = {
    getAdvancePaymentsList: (store) => store[NAME].list,
    isFetching: (store) => store[NAME].isFetching,
    getOffset: (store) => store[NAME].offset,
    getPageNumber: (store) => store[NAME].pageNumber,
    getMoreLines: (store) => store[NAME].moreLines,
    getMoreOrders: (store) => store[NAME].moreOrders,
    getFiltersAdvancePaymentsList: (store) => store[NAME].filtersAdvancePaymentsList,
    getTotalAdvancePaymentBalanceAmountUSD: (store) => store[NAME].totalAdvancePaymentBalanceAmountUSD,
    getTotalAdvancePaymentBalanceAmountUYU: (store) => store[NAME].totalAdvancePaymentBalanceAmountUYU,
    getTotalDiscountableUYU: (store) => store[NAME].totalDiscountableUYU,
    getTotalDiscountableUSD: (store) => store[NAME].totalDiscountableUSD,
    getCurrentDiscountableUYU: (store) => store[NAME].currentDiscountableUYU,
    getCurrentDiscountableUSD: (store) => store[NAME].currentDiscountableUSD,
    getTotalAdvancePaymentAmountUSD: (store) => store[NAME].totalAdvancePaymentAmountUSD,
    getTotalAdvancePaymentAmountUYU: (store) => store[NAME].totalAdvancePaymentAmountUYU,
    getMode: (store) => store[NAME].mode,
    getOrdersToDiscountList: (store) => store[NAME].ordersToDiscount,
    getTransaction: (store) => store[NAME].transaction,
};
