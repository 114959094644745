import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";

import { COMMA, EMPTY_STR, LANGUAGE, LEVEL, SCOPE, SPACE_STR } from "~/constants";
import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { SelectorsAction as SelectorsActionComex, SelectorsStore as SelectorsStoreComex } from "~/store/comex";
import {
    SelectorsAction as SelectorsActionComexExport,
    PROP as PropComex,
    SelectorsStore as SelectorsStoreComexExport,
} from "~/store/comex/export";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsAction as SelectorActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionTemplate } from "~/store/template";
import * as ConfigUtil from "~/util/config";
import { formatDate as FormatDate, toDate as ToDate } from "~/util/date";
import { isEmptyObj } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import EmailList from "~/pages/_components/fields/EmailList";
import TextField from "~/pages/_components/fields/TextField";

import FileUploader from "~/pages/comEx/_components/FileUploader";
import OperationNumber from "~/pages/comEx/_components/OperationNumber";
import OperationNumberDetail from "~/pages/comEx/_components/OperationNumberDetail";
import CreateTemplateModal from "~/pages/forms/Step1Edit/_ModalTemplateCreate";
import ListTemplatesModal from "~/pages/forms/Step1Edit/_ModalTemplateList";
import { Termsandconditions } from "~/pages/forms/_components/_fields";
import Scheduler from "~/pages/forms/_components/_fields/Scheduler";

import Style from "../../../Step1Edit.rules.scss";

const FORM_ID = "comex.export.billingClaim";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...PropComex.types,
    },
    defaults: {
        ...PropComex.defaults,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        draftLoaded: false,
        loadOperationNumber: false,
    };

    componentDidMount() {
        const { dispatch, formData } = this.props;

        if (isEmptyObj(formData)) {
            dispatch(SelectorsActionComexExport.billingClaimPreRequest());
        }
    }

    componentDidUpdate() {
        const {
            dispatch,
            formData,
            match,
            preData,
            setFieldError,
            setFieldTouched,
            setFieldValue,
            setValues,
            transaction,
            values,
        } = this.props;
        const { availableOperationList } = preData;
        const { operationNumber } = match.params;
        const { draftLoaded, loadOperationNumber } = this.state;

        if (!loadOperationNumber && availableOperationList.length > 0 && !values.operationNumber && operationNumber) {
            const operationSelected = availableOperationList.filter(
                (item) => item.othersFields.NROOPERACION === operationNumber,
            );

            this.setState({
                loadOperationNumber: true,
            });

            if (operationSelected.length > 0) {
                setFieldValue("item", operationSelected[0]);
                setFieldValue("operationNumber", operationNumber);
            }
        }

        if (transaction?.data && Object.keys(formData).length === 0 && preData.nextValidDate && !draftLoaded) {
            this.setState({
                draftLoaded: true,
            });

            const transactionData = transaction?.data;

            /* carga de errores */
            if (transactionData.backendFormErrors?.cdpErrorFields) {
                const { cdpErrorFields } = transactionData.backendFormErrors;

                cdpErrorFields.map((errorField) => {
                    if (errorField === "valueDate") {
                        setFieldError("scheduler", SPACE_STR);
                        setFieldTouched("scheduler", true);

                        transactionData.scheduler = {
                            editing: true,
                            valueDate: transactionData?.valueDate,
                            selectedOption: transactionData?.selectedOption,
                        };
                    } else {
                        setFieldError(errorField, SPACE_STR);
                        setFieldTouched(errorField, true);
                    }

                    return null;
                });
            }

            if (transactionData.backendFormErrors?.message) {
                const errorMessage = transactionData?.backendFormErrors?.message;

                dispatch(
                    SelectorActionNotification.showNotification({
                        message: errorMessage,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.COMEX_BILLING_CLAIM],
                    }),
                );
            } // modificaciones para que agarren bien los fields
            /*  carga del draft */
            if (!transactionData.scheduler) {
                transactionData.scheduler = {
                    editing: true,
                    valueDate: transactionData?.valueDate,
                    selectedOption: transactionData?.selectedOption,
                };
            }
            if (transactionData.valueDate) {
                const valueDate = ToDate(transactionData.valueDate);

                transactionData.valueDate = valueDate;
            }

            setValues(transaction.data);
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(GoBack());
    };

    handleFormKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            event.preventDefault();
        }
    };

    handleFieldKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            this.handleSearch();
        }
    };

    handleItemSelect = (item) => {
        const { setFieldError, setFieldValue } = this.props;

        const { othersFields } = item;
        if (othersFields) {
            setFieldValue("operationNumber", othersFields.NROOPERACION);
            setFieldValue("item", item);
            setFieldError("operationNumber", null);
        } else {
            setFieldValue("item", null);
            setFieldValue("operationNumber", EMPTY_STR);
        }
    };

    handleModalLoad = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionComex.toggleCreditLetterList());
    };

    handleBlur = () => {
        const { preData, setFieldValue, values } = this.props;
        const { availableOperationList } = preData;

        if (values.operationNumber) {
            const operationSelected = availableOperationList.filter(
                (item) => item.othersFields.NROOPERACION === values.operationNumber,
            );

            if (operationSelected.length > 0) {
                setFieldValue("item", operationSelected[0]);
            } else {
                setFieldValue("item", null);
                setFieldValue("operationNumber", EMPTY_STR);
            }
        }
    };

    handleCancel = () => {
        const { dispatch, setSubmitting, transaction } = this.props;

        dispatch(
            SelectorsActionForm.cancelTransactionCustom({
                credentials: {},
                idTransaction: transaction.idTransaction,
                formikBag: { setSubmitting },
            }),
        );
    };

    handleDraft = () => {
        const { dispatch, transaction, values } = this.props;

        dispatch(
            SelectorsActionForm.saveDraft({
                idActivityDraft: "comex.export.billingClaim.send",
                idTransactionToSave: transaction?.idTransaction,
                transactionData: values || {},
            }),
        );
    };

    handleTemplateSave = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionTemplate.createTemplate());
    };

    handleTemplateLoad = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionTemplate.toggleTemplateList());
    };

    handleSelectTemplate = (template) => {
        const { setErrors, setValues } = this.props;
        const templateToBeLoaded = template;

        setValues(templateToBeLoaded);
        setErrors({});
    };

    render() {
        const { emailValidationRegex, errors, idForm, preData, setFieldValue, transaction, values } = this.props;
        const { availableOperationList, nextValidDate, unavailableDays } = preData;
        const termsAndConditions = "comex.export.billingClaim.disclaimer";
        const i18nMap = {
            termsAndConditions,
        };
        const cancelEnabled = transaction && transaction.idTransactionStatus === STATUS.DRAFT;
        const othersFields = values.item ? values.item.othersFields : null;

        return (
            <React.Fragment>
                <Form
                    className="col col-12 col-lg-6 col-md-9 col-sm-12"
                    id={Style.ID}
                    noValidate="novalidate"
                    onKeyDown={this.handleFormKeyDown}>
                    <div className="form-section loadTemplates">
                        <Button
                            onClick={this.handleTemplateLoad}
                            label="forms.templates.load"
                            bsStyle="default"
                            image="template.svg"
                        />
                    </div>
                    <section className="fields container--layout align-items-center flex-grow">
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.section.operation`)}</h3>
                        </Box>

                        <Box flex align="end" className="form-group search-field">
                            <Field
                                idForm={FORM_ID}
                                name="operationNumber"
                                hidePlaceholder
                                numbersOnly
                                type="text"
                                component={TextField}
                                onBlur={this.handleBlur}
                                tooltip={UtilsI18n.get(`${FORM_ID}.operationNumber.tooltip`)}
                            />

                            <Box className="ml-3 mb-2">
                                <Button
                                    onClick={this.handleModalLoad}
                                    bsStyle="primary"
                                    label="global.search"
                                    className="search"
                                />
                            </Box>
                        </Box>

                        {values.item && <OperationNumberDetail othersFields={othersFields} idForm={FORM_ID} />}

                        <Box className="form-group">
                            <Field
                                name="files"
                                idForm={FORM_ID}
                                emptyMessageKey={`${FORM_ID}.attachments.emptyMessage`}
                                maxFiles={5}
                                errors={errors}
                                component={FileUploader}
                                values={values}
                                setFieldValue={setFieldValue}
                            />
                        </Box>

                        <Field
                            component={EmailList}
                            idForm={FORM_ID}
                            name="notificationEmails"
                            hideSwiftTip
                            className="form-group email-field"
                            renderSuggestion={false}
                            data={{ emailValidationRegex, addMessage: EMPTY_STR }}
                            isRequired={false}
                            optional={UtilsI18n.get("form.field.optional")}
                            tooltip={UtilsI18n.get(`${FORM_ID}.notificationEmails.tooltip`)}
                            value={values.notificationEmails}
                        />

                        <hr />
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">
                                {UtilsI18n.get(`${FORM_ID}.section.showPreview`)}
                            </h3>
                        </Box>

                        <Field
                            component={Termsandconditions}
                            idForm={FORM_ID}
                            name="disclaimer"
                            mode={MODE.EDIT}
                            className="form-control"
                            i18nMap={i18nMap}
                        />
                        {nextValidDate && (
                            <Field
                                component={Scheduler}
                                data={{
                                    firstWorkingDate: nextValidDate,
                                    maxDaysToSchedule: ConfigUtil.get("client.comex.valueDate.maxDaysToSchedule"),
                                    nonWorkingDays: unavailableDays,
                                    lang: LANGUAGE.ES,
                                    mode: MODE.EDIT,
                                    programable: false,
                                    schedulable: true,
                                }}
                                name="scheduler"
                                idForm={idForm}
                            />
                        )}
                    </section>

                    <footer>
                        <Button
                            onClick={this.handleTemplateSave}
                            className="templateSave"
                            label="forms.saveTemplate.link"
                        />
                        {cancelEnabled && <Button onClick={this.handleCancel} label="forms.cancelTransaction.link" />}
                        <Button
                            key="primaryButton"
                            variant="primary"
                            bsStyle="primary"
                            label="global.next"
                            loading={false}
                            type="submit"
                        />
                    </footer>
                </Form>
                <ListTemplatesModal
                    idActivityTemplate="comex.export.billingClaim.send"
                    onSelect={this.handleSelectTemplate}
                />
                <CreateTemplateModal values={values} idActivityTemplate="comex.export.billingClaim.send" />
                <OperationNumber
                    list={availableOperationList}
                    idForm={FORM_ID}
                    handleItemClick={this.handleItemSelect}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        formData: SelectorsStoreComexExport.getBillingClaimFormData(store),
        preData: SelectorsStoreComexExport.getBillingClaimPre(store),
        transaction: SelectorsStoreComex.getTransaction(store),
    };
};

export default HighOrder(
    WithRouter,
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: false,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ formData }) => {
            return {
                files:
                    formData?.attachments && formData?.attachments !== EMPTY_STR
                        ? formData?.attachments.split(COMMA)
                        : [],
                invoiceAmountCurrency: formData?.invoiceAmountCurrency || EMPTY_STR,
                invoiceNumber: formData?.invoiceNumber || EMPTY_STR,
                item: formData?.item || null,
                notificationEmails: formData?.notificationEmails || EMPTY_STR,
                oldAmount: formData?.oldAmount || EMPTY_STR,
                operationNumber: formData?.operationNumber || EMPTY_STR,
                recipient: formData?.recipient || EMPTY_STR,
                scheduler: formData?.scheduler,
            };
        },
        handleSubmit: (props, formikBag) => {
            const { dispatch } = formikBag.props;
            const { attachments, files, item, invoiceAmount, scheduler, ...rest } = props;

            const oldAmount = item?.othersFields ? item.othersFields.IMPORTE : EMPTY_STR;
            const recipient = item?.othersFields ? item.othersFields.GIRADO : EMPTY_STR;
            const invoiceNumber = item?.othersFields ? item.othersFields.NROFACTURA : EMPTY_STR;
            const attachmentsFiles = files && files.length > 0 ? files.join(COMMA) : EMPTY_STR;
            const valueDateString = scheduler ? FormatDate(scheduler.valueDate) : EMPTY_STR;
            const invoiceAmountCurrency = item?.othersFields ? item.othersFields.MONEDA : EMPTY_STR;

            const formData = {
                ...rest,
                attachments: attachmentsFiles,
                invoiceAmountCurrency,
                invoiceNumber,
                item,
                oldAmount,
                recipient,
                scheduler,
                valueDate: valueDateString,
            };

            dispatch(
                SelectorsActionComexExport.billingClaimPreviewRequest({
                    formData,
                    formikBag,
                }),
            );
        },
    }),
)(Component);
