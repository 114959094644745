import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { EMPTY_STR, ONLY_NUMBER, PRICE_CLASSIFICATION, ZERO_NUMBER } from "~/constants";
import { MODE } from "~/constants/form";
import {
    SelectorsStore as SelectorsStoreWMShares,
    SelectorsAction as SelectorsActionWMShares,
    PROP as PropWMShares,
} from "~/store/wm/shares";
import * as UtilsConfig from "~/util/config";
import { addDay as AddDay } from "~/util/date";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import RadioOption from "~/components/RadioOption";
import Select from "~/components/Select";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import DateField from "~/pages/_components/fields/DateField";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import FormGroup from "~/pages/_components/fields/FormGroup";
import TextField from "~/pages/_components/fields/TextField";
import AmountField from "~/pages/_components/fields/formik/AmountField";

import { Termsandconditions } from "~/pages/forms/_components/_fields";
import Checkbox from "~/pages/forms/_components/_fields/_commons/Checkbox";

import PaperSell from "../../_components/PaperSell";
import Style from "./Step1Edit.rules.scss";

const FORM_ID = "wm.shares.sell.send";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...PropWMShares.types,
    },
    defaults: {
        ...PropWMShares.defaults,
    },
};

const SELLING_TYPES = {
    total: "Total",
    partial: "Parcial",
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        const { dispatch, match } = this.props;
        const { idPaper } = match.params;

        if (idPaper) {
            dispatch(SelectorsActionWMShares.sellSharesPre({ idPaper }));
        }
    }

    componentDidUpdate() {
        const { creditAccountList, match, paper, prevMode, processingSale, setFieldValue } = this.props;
        const { values } = this.props;
        const { othersFields } = paper;

        if (!values.idCreditAccount && creditAccountList[0]) {
            this.handleChange(creditAccountList[0].idProduct);
        }

        const { idPaper } = match.params;

        if (!values.idPaper && idPaper) {
            this.handlePaperChange(idPaper);
        }

        if (othersFields) {
            const { CANTIDAD } = othersFields;
            let sharesQuantity = CANTIDAD;

            if (prevMode !== MODE.PREVIEW) {
                sharesQuantity -= processingSale;
            }
            if (
                sharesQuantity > 0 &&
                (!values.sharesQuantity ||
                    (values.sellingType === SELLING_TYPES.total && values.sharesQuantity !== sharesQuantity))
            ) {
                setFieldValue("sharesQuantity", sharesQuantity);
            }
        }
    }

    buildAmountOptions = (curr) => {
        return [
            {
                id: curr,
                label: UtilsI18n.get(`currency.label.${curr}`),
            },
        ];
    };

    buildPaperOptions = () => {
        const { shares } = this.props;

        return shares.map((elem) => {
            return {
                id: elem.idProduct,
                label: elem.name,
            };
        });
    };

    buildProductOptions = (options) => {
        return options.map((elem) => {
            if (elem.balance && elem.balance.quantity !== 0) {
                return {
                    id: elem.idProduct,
                    label: (
                        <React.Fragment>
                            <span className="control-label">{elem.label}</span>
                            <FormattedAmount quantity={elem.balance.quantity} currency={elem.balance.currency} />
                        </React.Fragment>
                    ),
                };
            }

            return {
                id: elem.idProduct,
                label: elem.productAlias,
            };
        });
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(GoBack());
    };

    handleChange = (id) => {
        const { setFieldValue } = this.props;

        setFieldValue("idCreditAccount", id);
    };

    handleCheckChange = (fieldName) => {
        const { setFieldValue, values } = this.props;

        setFieldValue(fieldName, !values[fieldName]);
    };

    handlePaperChange = (id) => {
        const { dispatch, setFieldValue } = this.props;

        dispatch(SelectorsActionWMShares.sellSharesPre({ idPaper: id }));

        setFieldValue("idPaper", id);
        setFieldValue("sharesQuantity", 0);
    };

    handleRadioChange = (id, name) => {
        const { paper, processingSale, setFieldValue } = this.props;
        const { othersFields } = paper;
        let amount = 0;

        if (id === SELLING_TYPES.total) {
            amount = othersFields ? othersFields.CANTIDAD - processingSale : 0;
        }

        setFieldValue("sharesQuantity", amount);
        setFieldValue(name, id);
    };

    handleRadioPriceChange = (value) => {
        const { setFieldValue } = this.props;

        setFieldValue("price", value);
    };

    render() {
        const { creditAccountList, errors, paper, processingSale, timezoneOffset, touched, values } = this.props;

        const { othersFields } = paper && paper;
        const currency = othersFields ? othersFields.MONEDA : EMPTY_STR;
        const newOptions = this.buildProductOptions(creditAccountList);
        const sharesOptions = this.buildPaperOptions();
        const selectedPaper = values && values.idPaper ? values.idPaper : EMPTY_STR;
        const productId = values && values.idCreditAccount ? values.idCreditAccount : EMPTY_STR;

        const showAcceptOptionText = UtilsI18n.get("client.wm.bonds.finish.conditions");
        const termsAndConditions = "client.wm.shares.sell.disclaimer";
        const i18nMap = {
            termsAndConditions,
            showAcceptOptionText,
        };
        const hasErrorCreditAccount = touched.idCreditAccount && errors.idCreditAccount && !values.idCreditAccount;
        const data = {
            options: [
                {
                    id: currency,
                    label: UtilsI18n.get(`currency.label.${currency}`),
                },
            ],
        };

        const availableForSelling = parseFloat(othersFields?.CANTIDAD || ZERO_NUMBER, 10) - processingSale;
        const maxDaysToShow = UtilsConfig.getInteger("client.wm.allInvestments.orderValid.maxDaysToShow");
        const minDate = new Date();
        const maxDate = AddDay(minDate, maxDaysToShow);

        return (
            <Form id={Style.ID} noValidate="novalidate" className="col col-12 col-lg-6 col-md-9 col-sm-12">
                <section className="fields container--layout align-items-center flex-grow">
                    <hr />
                    <Box className="form-section-title">
                        <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.sellData`)}</h3>
                    </Box>

                    {/* Shares to sell */}
                    <Box className="form-group">
                        <FieldLabel labelKey={`${FORM_ID}.paper.label`} />

                        <Box className="input-group">
                            <Field
                                className="flex-container slideFromBottom"
                                clearable={false}
                                component={Select}
                                idForm={FORM_ID}
                                labelKey="label"
                                onChange={(item) => {
                                    if (item) {
                                        this.handlePaperChange(item.id);
                                    }
                                }}
                                optionClassName="needsclick"
                                options={sharesOptions}
                                placeholder={EMPTY_STR}
                                value={selectedPaper}
                                valueKey="id"
                            />
                        </Box>
                    </Box>
                    {othersFields && (
                        <PaperSell
                            availableForSelling={availableForSelling}
                            isSelling
                            processingSale={processingSale}
                            othersFields={othersFields}
                        />
                    )}

                    {/* Selling type */}
                    <Box className="form-group">
                        <FormGroup>
                            <FieldLabel labelKey="wm.shares.sell.type.label" />
                            <Field name="sellingType">
                                {({ field: { value } }) => (
                                    <Box className="radio-options">
                                        <RadioOption
                                            checked={value === SELLING_TYPES.total}
                                            className="option"
                                            formId={FORM_ID}
                                            group="sellingType"
                                            mode={MODE.EDIT}
                                            onChange={this.handleRadioChange}
                                            value={SELLING_TYPES.total}
                                        />
                                        <RadioOption
                                            checked={value === SELLING_TYPES.partial}
                                            className="option"
                                            formId={FORM_ID}
                                            group="sellingType"
                                            mode={MODE.EDIT}
                                            onChange={this.handleRadioChange}
                                            value={SELLING_TYPES.partial}
                                        />
                                    </Box>
                                )}
                            </Field>
                        </FormGroup>
                    </Box>

                    {/* Shares quantity */}
                    <Field
                        classContainer={values.sellingType === SELLING_TYPES.total ? "sell-total" : EMPTY_STR}
                        component={TextField}
                        hidePlaceholder
                        idForm={FORM_ID}
                        mode={values.sellingType === SELLING_TYPES.total ? MODE.VIEW : MODE.EDIT}
                        name="sharesQuantity"
                        pattern={ONLY_NUMBER}
                        searchable={false}
                        type="text"
                        value={availableForSelling}
                    />

                    {/* Price */}
                    <Box className="form-group">
                        <FormGroup>
                            <FieldLabel labelKey="wm.shares.price.type.label" />
                            <Field name="price">
                                {({ field: { value } }) => (
                                    <Box className="radio-options">
                                        <RadioOption
                                            checked={value === PRICE_CLASSIFICATION.MARKET}
                                            className="option"
                                            formId={FORM_ID}
                                            group="price"
                                            mode={MODE.EDIT}
                                            onChange={this.handleRadioPriceChange}
                                            value={PRICE_CLASSIFICATION.MARKET}
                                        />
                                        <RadioOption
                                            checked={value === PRICE_CLASSIFICATION.LIMIT}
                                            className="option"
                                            formId={FORM_ID}
                                            group="price"
                                            mode={MODE.EDIT}
                                            onChange={this.handleRadioPriceChange}
                                            value={PRICE_CLASSIFICATION.LIMIT}
                                        />
                                    </Box>
                                )}
                            </Field>
                        </FormGroup>
                    </Box>
                    {values.price === PRICE_CLASSIFICATION.LIMIT && (
                        <Field
                            clearable={false}
                            component={AmountField}
                            data={data}
                            decimalPlaces={2}
                            fixedDecimalScale
                            hideCurrency={!currency}
                            idForm={FORM_ID}
                            maxLength={UtilsConfig.getInteger("amount.length")}
                            name="limitFrom"
                            placeholder={EMPTY_STR}
                            searchable={false}
                            value={currency}
                        />
                    )}

                    {/* Order valid until */}
                    <div className="form-group">
                        <FieldLabel
                            labelKey="wm.allInvestments.orderValidUntil.label"
                            classContainer={`${
                                errors.orderCompleted && touched.orderCompleted ? "has-error" : EMPTY_STR
                            }`}
                        />
                        <Box className="label-checkbox">
                            <Field
                                name="orderCompleted"
                                label={UtilsI18n.get(`${FORM_ID}.orderCompleted.label`)}
                                checked={values.orderCompleted}
                                component={Checkbox}
                                onChange={() => this.handleCheckChange("orderCompleted")}
                            />
                        </Box>

                        {errors.orderCompleted && touched.orderCompleted && (
                            <FieldError error={errors.orderCompleted} />
                        )}
                    </div>

                    {!values.orderCompleted && (
                        <React.Fragment>
                            <Field
                                autoComplete="off"
                                component={DateField}
                                id="limitDate"
                                idForm={FORM_ID}
                                minDate={minDate}
                                maxDate={maxDate}
                                name="limitDate"
                                timezoneOffset={timezoneOffset}
                            />
                            <Box className="form-group">
                                <div id="disclaimer-text">
                                    {UtilsI18n.get("wm.allInvestments.afterValidDate.disclaimer")}
                                </div>
                            </Box>
                        </React.Fragment>
                    )}

                    {/* Credit account */}
                    <Box className={`form-group ${hasErrorCreditAccount ? "has-error" : EMPTY_STR}`}>
                        <FieldLabel labelKey={`${FORM_ID}.creditAccount.label`} />

                        <Box className="input-group">
                            <Field
                                className="flex-container slideFromBottom"
                                clearable={false}
                                component={Select}
                                idForm={FORM_ID}
                                labelKey="label"
                                onChange={(item) => {
                                    if (item) {
                                        this.handleChange(item.id);
                                    }
                                }}
                                optionClassName="needsclick"
                                options={newOptions}
                                placeholder={EMPTY_STR}
                                value={productId}
                                valueKey="id"
                            />
                        </Box>
                    </Box>
                    <Box className="form-group">
                        {hasErrorCreditAccount && <FieldError error={errors.idCreditAccount} />}
                    </Box>

                    {/* Terms */}
                    <Box className="form-group label-checkbox">
                        <Field
                            classContainer="disclaimer"
                            component={Termsandconditions}
                            i18nMap={i18nMap}
                            idForm={FORM_ID}
                            mode={MODE.EDIT}
                            name="disclaimer"
                            showAcceptOption
                        />
                    </Box>
                </section>
                <Button bsStyle="primary" label="global.send" type="submit" block={false} />
            </Form>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        creditAccountList: SelectorsStoreWMShares.getCreditAccountList(store),
        formData: SelectorsStoreWMShares.getFormData(store),
        paper: SelectorsStoreWMShares.getPaper(store),
        prevMode: SelectorsStoreWMShares.getPrevMode(store),
        processingSale: SelectorsStoreWMShares.getProcessingSale(store),
        shares: SelectorsStoreWMShares.getSharesList(store),
        timezoneOffset: SelectorsStoreWMShares.getTimezoneOffset(store),
    };
};

export default HighOrder(
    WithRouter,
    Connect(mapStateToProps),
    WithFormik({
        mapPropsToValues: ({ formData }) => {
            return {
                disclaimer: formData.disclaimer ? formData.disclaimer : false,
                idCreditAccount: formData.idCreditAccount ? formData.idCreditAccount : EMPTY_STR,
                idPaper: formData.idPaper,
                limitDate: formData.limitDate || EMPTY_STR,
                limitFrom: { amount: formData.limitFrom },
                orderCompleted: formData?.orderCompleted !== false,
                price: formData.price ? formData.price : PRICE_CLASSIFICATION.MARKET,
                sellingType: formData.sellingType ? formData.sellingType : SELLING_TYPES.total,
                sharesQuantity: formData.sharesQuantity ? formData.sharesQuantity : EMPTY_STR,
            };
        },
        validationSchema: () =>
            Yup.lazy((props) =>
                Yup.object().shape({
                    disclaimer: Yup.boolean().oneOf([true], UtilsI18n.get("client.wm.shares.sell.disclaimerRequired")),
                    idCreditAccount: Yup.string().required(UtilsI18n.get("client.wm.shares.sell.emptyCreditAccount")),
                    limitDate: props.orderCompleted
                        ? Yup.string().notRequired()
                        : Yup.string().trim().required(UtilsI18n.get("wm.allInvestments.limitDate.required")),
                }),
            ),
        handleSubmit: (
            {
                disclaimer,
                idCreditAccount,
                idPaper,
                limitDate,
                limitFrom,
                orderCompleted,
                price,
                sellingType,
                sharesQuantity,
            },
            formikBag,
        ) => {
            const { dispatch, paper } = formikBag.props;
            const limitFromAmount = limitFrom?.amount;
            const haslimitFromAmount = limitFromAmount && limitFromAmount !== EMPTY_STR;
            const limitAmount = haslimitFromAmount ? limitFromAmount : EMPTY_STR;
            const {
                othersFields: { DESCRIPCION: paperName },
            } = paper;

            dispatch(
                SelectorsActionWMShares.sellSharesPreview({
                    formData: {
                        amount: ZERO_NUMBER,
                        disclaimer,
                        idCreditAccount,
                        idPaper,
                        limitDate: orderCompleted ? EMPTY_STR : limitDate,
                        limitFrom: limitAmount,
                        orderCompleted,
                        paperName,
                        price,
                        sellingType,
                        sharesAmount: ZERO_NUMBER,
                        sharesQuantity,
                    },
                    formikBag,
                }),
            );
        },
    }),
)(Component);
