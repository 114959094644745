import { push as Push, replace as Replace, goBack as GoBack } from "connected-react-router";
import {
    call as Call,
    takeEvery as TakeEvery,
    takeLatest as TakeLatest,
    put as Put,
    select as Select,
} from "redux-saga/effects";

import {
    ENVIRONMENT_TYPE,
    LEVEL,
    MSG_RETURN_CODE_PREFIX,
    RESPONSE_TYPE,
    SCOPE,
    SIGN_WITH_BIOMETRIC,
    TYPE_DATA_ACCOUNTS,
    VALIDATION_ERROR,
} from "~/constants";
import Store from "~/store";
import { TYPE as TYPES_APP } from "~/store/app";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import IsTrustedBiometric from "~/store/form/_sagas/_isTrustedBiometric";
import { SelectorsAction as SelectorsActionI18n } from "~/store/i18n";
import { SelectorsAction as ActionsLogin, SelectorsStore as SelectorsLogin } from "~/store/login";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsStore as SelectorsStoreSession, SelectorsAction as SelectorsActionSession } from "~/store/session";
import { SelectorsStore as SelectorsStoreSettings, SelectorsAction as SelectorsActionSettings } from "~/store/settings";
import {
    credentialsWithUnderscore as CredentialsWithUnderscore,
    hasIncorrectCredentials as HasIncorrectCredentials,
    adjustIdFieldErrors as AdjustIdFieldErrors,
} from "~/util/form";
import * as UtilsI18n from "~/util/i18n";
import UtilLodash from "~/util/lodash";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction } from "./_selectors";

const sagas = [
    TakeLatest(TYPE.CHANGE_LANGUAGE_PRE, changeLanguage),
    TakeLatest(TYPE.CHANGE_PASSWORD_REQUEST, changePassword),
    TakeLatest(TYPE.CHANGE_SECURITY_SEAL_PRE, changeSecuritySealPre),
    TakeLatest(TYPE.CHANGE_SECURITY_SEAL_CONFIRMATION_PRE, changeSecuritySealConfirmationPre),
    TakeLatest(TYPE.CHANGE_SECURITY_SEAL_CONFIRMATION_BACK, changeSecuritySealConfirmationBack),
    TakeLatest(TYPE.CHANGE_SECURITY_SEAL_CONFIRMATION, changeSecuritySealConfirmation),
    TakeLatest(TYPE.NOTIFICATIONS_CONFIGURATION_PRE, notificationsConfigurationPre),
    TakeLatest(TYPE.COMMUNICATION_TYPE_PRE, communicationTypePre),
    TakeEvery(TYPE.MODIFY_NOTIFICATION_CONFIGURATIONS, modifyNotificationConfigurations),
    TakeLatest(TYPE.GET_USER_DATA, getUserData),
    TakeLatest(TYPE.MAIL_SEND_CODE, sendMailCode),
    TakeLatest(TYPE.MAIL_UPDATE, updateMail),
    TakeLatest(TYPE.MOBILEPHONE_SEND_CODE, sendMobilePhoneCode),
    TakeLatest(TYPE.MOBILEPHONE_UPDATE, updateMobilePhone),
    TakeLatest(TYPE.CHANGE_PERSONAL_DATA, changePersonalData),
    TakeLatest(TYPE.CHANGE_PERSONAL_DATA_SECURITYSEALS_REQUEST, changePersonalDataSecurityseal),
    TakeLatest(TYPE.CHANGE_EMAIL_VERIFY, changeEmailVerify),
    TakeLatest(TYPE.CHANGE_ADDRESSES_REQUEST, changeAddressesRequest),
    TakeLatest(TYPE.CHANGE_ADDRESSES_DEPARTAMENT_REQUEST, changeDepartamentRequest),
    TakeLatest(TYPE.CHANGE_ADDRESSES_CITY_REQUEST, changeCityRequest),
    TakeLatest(TYPE.PREFERENCES_MODIFY_USER_CONFIGURATION_REQUEST, modifyUserRequest),
    TakeLatest(TYPE.PREFERENCES_READ_TICKET_USER_CONFIGURATION_REQUEST, readSettingsTransaction),
    TakeLatest(TYPE.PREFERENCES_CHANGE_USER_DATA_ACCOUNTS_REQUEST, modifyDateAccounts),
    TakeLatest(TYPE.PREFERENCES_FORM_MODIFY_DATA_ACCOUNTS_REQUEST, modifyFormDataAccounts),
    TakeLatest(TYPE.CHANGE_ADDRESSES_DEPARTAMENT_AND_CITY_REQUEST, changeDepartamentAndCityRequest),
    TakeLatest(TYPE.CHANGE_ADDRESSES_DATA_FORM_REQUEST, changeAddressesDataForm),
    TakeLatest(TYPE.PREFERENCES_LIST_EMAIL_DATA_ACCOUNTS_REQUEST, listEmailAccounts),
    TakeLatest(TYPE.PREFERENCES_UPDATE_EMAIL_ACCOUNTS_REQUEST, updateEmailAccounts),
    TakeLatest(TYPE.READ_TRANSACTION_REQUEST, requestChangeTransactionData),
    TakeLatest(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, cancelTransactionCustom),
    TakeLatest(TYPE.SIGN_TRANSACTION_PREVIEW_REQUEST, signTransactionPreview),
    TakeLatest(TYPE.SIGN_TRANSACTION_REQUEST, signTransaction),
];

export default sagas;

function* changeLanguage(props) {
    const { language } = props;

    const result = yield Call(SelectorsMiddleware.changeLanguage, { userLang: language });
    if (result && result.status === 200) {
        yield Put(SelectorsActionI18n.setLang({ lang: language }));
        yield Put(SelectorsAction.changeLanguagePreSuccess());
    } else {
        const confirmationMessage = UtilsI18n.get("settings.changeLanguage.errorMessage");
        yield Put(
            SelectorsActionNotification.showNotification({
                message: confirmationMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.CHANGE_LANGUAGE],
            }),
        );
        yield Put(SelectorsAction.changeLanguagePreError());
    }
}

function* notificationsConfigurationPre() {
    const response = yield Call(SelectorsMiddleware.notificationsConfigurationPre);

    if (response && response.status === 200) {
        const { communicationConfigurations } = response.data.data;

        yield Put(
            SelectorsAction.notificationsConfigurationPreSuccess({
                communicationTypes: communicationConfigurations,
            }),
        );
    }
}

function* communicationTypePre(props) {
    const { communicationType } = props;
    const response = yield Call(SelectorsMiddleware.communicationTypePre, { communicationType });

    if (response && response.status === 200) {
        const communicationTransportsConfigurations = response.data.data.communicationConfigurations;
        yield Put(SelectorsAction.communicationTypePreSuccess({ communicationTransportsConfigurations }));
    } else {
        yield Put(Push("/desktop"));
    }
}

function* modifyNotificationConfigurations(props) {
    const { notificationType, transport, subscribed } = props;

    const response = yield Call(SelectorsMiddleware.modifyNotificationConfigurations, {
        notificationType,
        transport,
        subscribed,
    });

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("settings.notificationsConfiguration.errorMessage"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.SETTINGS_NOTIFICATIONS_CONFIGURATION_MODIFY],
            }),
        );
    }
}

function* getUserData() {
    const response = yield Call(SelectorsMiddleware.getUserData);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.SETTINGS],
            }),
        );
        yield Put(Replace("/settings"));
    } else {
        const { data } = response.data;
        yield Put(SelectorsAction.getUserDataSuccess({ data }));
    }
}

function* sendMailCode(props) {
    const { forceNotMatchUserEmail, formikBag, mail, otp, validate } = props;

    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
    }

    const { webToken } = Store.getState().session;

    const tokenToUse = otp || webToken;

    let response;

    if (UtilLodash.isEmpty(tokenToUse)) {
        response = {
            data: {
                code: VALIDATION_ERROR,
                message: UtilsI18n.get(`returnCode.${VALIDATION_ERROR}`),
                data: {
                    otp: UtilsI18n.get("returnCode.COR027W"),
                },
            },
            status: 200,
            type: RESPONSE_TYPE.WARNING,
        };
    } else {
        response = yield Call(SelectorsMiddleware.sendMailCode, {
            forceNotMatchUserEmail,
            mail,
            _otp: tokenToUse,
            validate,
        });
    }

    if (response.type === RESPONSE_TYPE.WARNING) {
        if (response.data.data.pin === "returnCode.COR091W") {
            yield Put({ type: TYPES_APP.CLEAN_UP });

            yield Put(
                Push({
                    pathname: "/session/expiredByMaxAttempts",
                }),
            );
        } else {
            yield Put(SelectorsAction.sendMailCodeFailure());

            formikBag.setErrors(AdjustIdFieldErrors(response.data.data));
        }
    } else {
        const newEmail = response.data.data.mail;

        yield Put(SelectorsAction.sendMailCodeSuccess({ newEmail }));
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("change.email.information"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.CHANGE_EMAIL],
            }),
        );
    }

    formikBag.setSubmitting(false);
}

function* updateMail(props) {
    const { mailCode, environmentType, formikBag } = props;
    const username = yield Select(SelectorsStoreSession.getUsername);

    const userEmailSelected = yield Select(SelectorsStoreSession.getUserEmailSelected);

    const emailForceFlow = yield Select(SelectorsStoreSession.getEmailForceUpdateFlow);

    const newEmail = yield Select(SelectorsStoreSettings.getNewEmail);

    const params = {
        isUserEmailForceSyncFlow: !!userEmailSelected || !!emailForceFlow,
        mailCode,
        newEmail,
        username,
    };

    const response =
        environmentType !== ENVIRONMENT_TYPE.CORPORATE
            ? yield Call(SelectorsMiddleware.updateMail, params)
            : yield Call(SelectorsMiddleware.updateMailCMB, params);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.updateMailFailure());

        formikBag.setErrors(AdjustIdFieldErrors(response.data.data));
    } else {
        const { mail } = response.data.data;
        const rememberedUser = yield Select(SelectorsLogin.getRememberedUser);

        if (rememberedUser !== null) {
            yield Put(ActionsLogin.setRememberedUser({ ...rememberedUser, username: mail }));
        }

        if (userEmailSelected) {
            yield Put(SelectorsActionSession.finishUserEmailForceSync());
        }

        if (emailForceFlow) {
            yield Put(SelectorsActionSession.finishEmailForceUpdate());
        }

        if (environmentType !== ENVIRONMENT_TYPE.CORPORATE) {
            const { idTransaction } = response.data;
            const {
                data: {
                    data: { transaction },
                },
            } = yield Call(SelectorsMiddlewareForm.readTransaction, {
                idTransactionToRead: idTransaction,
            });

            yield Put(SelectorsActionSettings.updateMailSuccess({ transaction }));
        } else {
            yield Put(Replace("/settings"));
            yield Put(SelectorsActionSettings.updateMailCMBSuccess());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("change.email.successful"),
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.PERSONAL_INFORMATION, SCOPE.SETTINGS],
                }),
            );
        }
    }
    formikBag.setSubmitting(false);
}

function* sendMobilePhoneCode(props) {
    const { forceNotMatchUserMobilePhone, formikBag, isResending, mobilePhone, otp, setErrors, setSubmitting } = props;

    let response;

    if (isResending) {
        response = yield Call(SelectorsMiddleware.sendMobilePhoneCodeResending, { mobilePhone, isResending });
    } else {
        const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

        if (isTrusted) {
            yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
        }

        const { webToken } = Store.getState().session;

        const tokenToUse = otp || webToken;

        if (UtilLodash.isEmpty(tokenToUse)) {
            response = {
                data: {
                    code: VALIDATION_ERROR,
                    message: UtilsI18n.get(`returnCode.${VALIDATION_ERROR}`),
                    data: {
                        otp: UtilsI18n.get("returnCode.COR027W"),
                    },
                },
                status: 200,
                type: RESPONSE_TYPE.WARNING,
            };
        } else {
            response = yield Call(SelectorsMiddleware.sendMobilePhoneCode, {
                forceNotMatchUserMobilePhone,
                mobilePhone,
                _otp: tokenToUse,
            });
        }
    }

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.sendMobilePhoneCodeFailure());

        setErrors(AdjustIdFieldErrors(response.data.data));
    } else {
        const newMobilePhone = response.data.data.mobilePhone;
        const smsHasBeenResent = response.data.data.resent;

        yield Put(SelectorsAction.sendMobilePhoneCodeSuccess({ newMobilePhone }));
        yield Put(
            SelectorsActionNotification.showNotification({
                message: smsHasBeenResent
                    ? UtilsI18n.get("change.mobilePhone.information.resent")
                    : UtilsI18n.get("change.mobilePhone.information"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.CHANGE_MOBILE_PHONE],
            }),
        );
    }

    setSubmitting(false);
}

function* updateMobilePhone(props) {
    const { environmentType, formikBag, mobilePhoneCode } = props;

    const mobilePhoneForceUpdateFlow = yield Select(SelectorsStoreSession.getMobilePhoneForceUpdateFlow);
    const userMobilePhoneSelected = yield Select(SelectorsStoreSession.getUserMobilePhoneSelected);

    const response =
        environmentType !== ENVIRONMENT_TYPE.CORPORATE
            ? yield Call(SelectorsMiddleware.updateMobilePhone, {
                  mobilePhoneCode,
                  isUserMobilePhoneForceSyncFlow: !!userMobilePhoneSelected || !!mobilePhoneForceUpdateFlow,
              })
            : yield Call(SelectorsMiddleware.updateMobilePhoneCMB, { mobilePhoneCode });

    const message = response.data.data.message || response.data.message;

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.updateMobilePhoneFailure());

        formikBag.setErrors(AdjustIdFieldErrors(response.data.data));
    } else if (environmentType !== ENVIRONMENT_TYPE.CORPORATE) {
        if (userMobilePhoneSelected) {
            yield Put(SelectorsActionSession.finishUserMobilePhoneForceSync());
        }

        if (mobilePhoneForceUpdateFlow) {
            yield Put(SelectorsActionSession.finishMobilePhoneForceUpdate());
        }

        const { idTransaction } = response.data;
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });

        yield Put(SelectorsActionSettings.updateMobilePhoneSuccess({ transaction }));
    } else {
        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.SETTINGS],
            }),
        );
        yield Put(Replace("/settings"));
    }
    formikBag.setSubmitting(false);
}

function* changePassword(props) {
    const { password, newPassword, newPasswordConfirmation, formikBag } = props;
    const response = yield Call(SelectorsMiddleware.changePassword, {
        _password: password,
        _newPassword: newPassword,
        _newPasswordConfirmation: newPasswordConfirmation,
    });
    if (response.type === RESPONSE_TYPE.WARNING) {
        formikBag.setErrors(AdjustIdFieldErrors(response.data.data));
        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.CHANGE_PASSWORD],
            }),
        );
    } else {
        const msgKey = `${MSG_RETURN_CODE_PREFIX}.${response.data.code}`;
        yield Put(Push("/desktop"));
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get(msgKey),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.DESKTOP],
            }),
        );
    }
    formikBag.setSubmitting(false);
}

function* changeSecuritySealPre() {
    const response = yield Call(SelectorsMiddleware.listSecuritySeals);
    const { _securitySeals } = response.data.data;

    if (response && response.status === 200) {
        yield Put(SelectorsAction.changeSecuritySealPreSuccess({ securitySeals: _securitySeals }));
    }
}

function* changeSecuritySealConfirmationPre() {
    yield Put(Push("/settings/changeSecuritySeal/confirmation"));
}

function* changeSecuritySealConfirmationBack() {
    yield Put(Push("/settings/changeSecuritySeal/"));
}

function* changeSecuritySealConfirmation(props) {
    const { securitySeal, pin, formikBag } = props;
    const response = yield Call(SelectorsMiddleware.modifySecuritySeal, {
        _securitySeal: securitySeal,
        _pin: pin,
    });

    if (response && response.status === 200) {
        if (response.type === RESPONSE_TYPE.WARNING) {
            const { data } = response.data;
            formikBag.setErrors(AdjustIdFieldErrors(data));

            yield Put(SelectorsAction.changeSecuritySealConfirmationError({ errors: data }));
        } else {
            const confirmationMessage = UtilsI18n.get("settings.changeSecuritySeal.confirmationMessage");

            yield Put(SelectorsAction.changeSecuritySealConfirmationSuccess({ securitySeal }));
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: confirmationMessage,
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.SETTINGS],
                }),
            );
            yield Put(Push("/settings"));
        }
    }
}

function* changePersonalData(props) {
    const { environmentType } = props;
    const response =
        environmentType !== ENVIRONMENT_TYPE.CORPORATE
            ? yield Call(SelectorsMiddleware.changePersonalData)
            : yield Call(SelectorsMiddleware.changePersonalDataCMB);

    const {
        type,
        data: {
            data: { infoUser, adresses, personalData, _securitySeal, changeProcessData },
            message,
        },
    } = response;
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.PERSONAL_INFORMATION],
            }),
        );
    } else if (environmentType !== ENVIRONMENT_TYPE.CORPORATE) {
        yield Put(
            SelectorsAction.changePersonalDataSuccess({
                infoUser,
                adresses,
                personalData,
                securitySeal: _securitySeal,
                changePersonalDataInProgress: changeProcessData,
            }),
        );
    } else {
        yield Put(
            SelectorsAction.changePersonalDataCMBSuccess({
                infoUser,
                personalData,
                securitySeal: _securitySeal,
                changePersonalDataInProgress: changeProcessData,
            }),
        );
    }
}

function* changePersonalDataSecurityseal() {
    const {
        type,
        data: {
            data: { _securitySealList },
        },
    } = yield Call(SelectorsMiddleware.changePersonalDataSecuritysealsRequest);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.changePersonalDataSecuritysealsFailure());
    } else {
        yield Put(
            SelectorsAction.changePersonalDataSecuritysealsSuccess({
                securitySealList: _securitySealList,
            }),
        );
    }
}

function* changeEmailVerify(props) {
    const { email, handlerModal } = props;
    const { type } = yield Call(SelectorsMiddleware.changeEmailVerify, { email });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.changePersonalDataSecuritysealsFailure());
    } else {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("change.email.information"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.SETTINGS],
            }),
        );
    }

    handlerModal(false);
}

function* changeAddressesRequest(props) {
    const {
        type,
        data: {
            data: {
                legal,
                correspondense,
                countryList,
                cityList,
                townList,
                documents,
                professions,
                loadData,
                maritalStatusList,
            },
        },
    } = yield Call(SelectorsMiddleware.changeAddressesRequest, props);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.changeAddressesFailure());
        yield Put(GoBack());
    } else {
        yield Put(
            SelectorsAction.changeAddressesSuccess({
                legal,
                correspondense,
                countryList,
                cityList,
                townList,
                documents,
                professions,
                loadData,
                maritalStatusList,
            }),
        );
    }
}

function* saveAddress(typeAddresses, userAddress, legalAddress, mailingsAddress) {
    if (typeAddresses) {
        if (typeAddresses === TYPE_DATA_ACCOUNTS.LEGAL) {
            yield Put(legalAddress);
        }
        if (typeAddresses === TYPE_DATA_ACCOUNTS.MAILINGS) {
            yield Put(mailingsAddress);
        }
    } else {
        yield Put(userAddress);
    }
}
function* changeDepartamentRequest(props) {
    const { typeAddresses, ...rest } = props;
    const {
        type,
        data: {
            data: { cityList },
        },
    } = yield Call(SelectorsMiddleware.changeListCity, rest);
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.changeAddressesDepartamentFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("change.email.information"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.PERSONAL_INFORMATION],
            }),
        );
    } else {
        yield saveAddress(
            typeAddresses,
            SelectorsAction.changeAddressesDepartamentSuccess({
                cityList,
            }),
            SelectorsAction.addressesDepartamentLegalSuccess({
                cityList,
            }),
            SelectorsAction.addressesDepartamentMailingsSuccess({
                cityList,
            }),
        );
    }
}

function* changeCityRequest(props) {
    const { typeAddresses, ...rest } = props;

    const {
        type,
        data: {
            data: { townList },
        },
    } = yield Call(SelectorsMiddleware.changeListTown, rest);
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.changeAddressesDepartamentFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("change.email.information"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.PERSONAL_INFORMATION],
            }),
        );
    } else {
        yield saveAddress(
            typeAddresses,
            SelectorsAction.changeAddressesCitySuccess({
                townList,
            }),
            SelectorsAction.addressesCityLegalSuccess({
                townList,
            }),
            SelectorsAction.addressesCityMailingsSuccess({
                townList,
            }),
        );
    }
}

function* modifyUserRequest(props) {
    const { formikBag } = props;
    const myProps = { ...props };
    const isCorporate = myProps.environmentType === ENVIRONMENT_TYPE.CORPORATE;
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);
    delete myProps.type;

    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
    }

    const { infoUserModify, _otp } = myProps;

    const { webToken } = Store.getState().session;

    const tokenToUse = _otp || webToken;

    const response = isCorporate
        ? yield Call(SelectorsMiddleware.modifyUserCMB, { infoUserModify, _otp: tokenToUse })
        : yield Call(SelectorsMiddleware.modifyUser, { infoUserModify, _otp: tokenToUse });

    if (response.type === RESPONSE_TYPE.WARNING) {
        if (isCorporate) {
            formikBag.setErrors({ mobilePhone: response.data.data });
        } else {
            formikBag.setErrors(response.data.data);
        }

        yield Put(SelectorsAction.modifyUserFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("forms.fieldsErrors"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.PERSONAL_INFORMATION],
            }),
        );
    } else {
        const accountDataNotify = yield Select(SelectorsStoreSession.getAccountDataNotify);
        const accountDataForceUpdate = yield Select(SelectorsStoreSession.getAccountDataForceUpdate);
        yield Put(
            SelectorsActionSession.notifyUser({
                personalDataNotify: false,
                personalDataForceUpdate: false,
                accountDataNotify,
                accountDataForceUpdate,
            }),
        );
        if (isCorporate) {
            yield Put(SelectorsAction.modifyUserCMBSuccess());
            yield Put(Replace("/settings"));
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("change.personalData.cmb.successful"),
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.SETTINGS],
                }),
            );
        } else {
            const transactionResponse = yield Call(SelectorsMiddlewareForm.readTransaction, {
                idTransactionToRead: response.data.idTransaction,
            });

            const { transaction } = transactionResponse.data.data;
            yield Put(SelectorsAction.saveChangeData({ transaction }));
            yield Put(Push("/settings/changePersonalInformation/ticket"));
        }
    }
}

function* readSettingsTransaction(props) {
    const { idTransaction } = props;
    const transactionResponse = yield Call(SelectorsMiddlewareForm.readTransaction, {
        idTransactionToRead: idTransaction,
    });

    if (transactionResponse.type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: transactionResponse.data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.PERSONAL_INFORMATION],
            }),
        );
    } else {
        yield Put(SelectorsAction.saveChangeData({ transaction: transactionResponse.data.data.transaction }));
    }
}

function* modifyDateAccounts() {
    const response = yield Call(SelectorsMiddleware.changePreDateAccounts);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.modifyDateAccountsFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.PERSONAL_INFORMATION],
            }),
        );
    } else {
        const {
            data: {
                data: { correspondense, legal, infoUser, changeProcessData },
            },
        } = response;
        yield Put(
            SelectorsAction.modifyDateAccountsSuccess({
                correspondense,
                legal,
                infoUser,
                changeAccountDataInProgress: changeProcessData,
            }),
        );
    }
}

function* modifyFormDataAccounts() {
    const response = yield Call(SelectorsMiddleware.modifyFormDataAccounts);
    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.modifyFormDataAccountsFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.PERSONAL_INFORMATION],
            }),
        );
    } else {
        const {
            data: {
                data: {
                    correspondense,
                    legal,
                    addressesUser,
                    countryList,
                    cityListLegal,
                    townListLegal,
                    cityListMailings,
                    townListMailings,
                    loadData,
                },
            },
        } = response;
        yield Put(
            SelectorsAction.modifyFormDataAccountsSuccess({
                correspondense,
                legal,
                countryList,
                addressesUser,
                cityListLegal,
                townListLegal,
                cityListMailings,
                townListMailings,
                loadData,
            }),
        );
    }
}

function* changeDepartamentAndCityRequest(props) {
    const { idCountry, idCity } = props;

    const responseDepartament = yield Call(SelectorsMiddleware.changeListCity, { idCountry });

    if (responseDepartament.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.changeAddressesDepartamentFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: responseDepartament.response.data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.PERSONAL_INFORMATION],
            }),
        );
    } else {
        const { data } = responseDepartament;
        yield Put(
            SelectorsAction.addressesDepartamentMailingsSuccess({
                cityList: data.data.cityList,
            }),
        );
    }
    const reponseCity = yield Call(SelectorsMiddleware.changeListTown, { idCountry, idCity });

    if (reponseCity.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.changeAddressesDepartamentFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: reponseCity.response.data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.PERSONAL_INFORMATION],
            }),
        );
    } else {
        const { data } = reponseCity;
        yield Put(
            SelectorsAction.addressesCityMailingsSuccess({
                townList: data.data.townList,
            }),
        );
    }
}

function* changeAddressesDataForm(props) {
    const { formikBag } = props;
    const myProps = { ...props };
    delete myProps.type;
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
    }

    const { infoUserModify, _otp } = myProps;

    const { webToken } = Store.getState().session;

    const tokenToUse = _otp || webToken;

    const response = yield Call(SelectorsMiddleware.modifyAccountsData, { infoUserModify, _otp: tokenToUse });

    if (response.type === RESPONSE_TYPE.WARNING) {
        formikBag.setErrors(response.data.data);
        yield Put(SelectorsAction.modifyUserFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.PERSONAL_INFORMATION],
            }),
        );
    } else {
        const personalDataNotify = yield Select(SelectorsStoreSession.getPersonalDataNotify);
        const personalDataForceUpdate = yield Select(SelectorsStoreSession.getPersonalDataForceUpdate);
        yield Put(
            SelectorsActionSession.notifyUser({
                personalDataNotify,
                personalDataForceUpdate,
                accountDataNotify: false,
                accountDataForceUpdate: false,
            }),
        );
        const { idTransaction } = response.data;
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });

        yield Put(SelectorsAction.readChangeTransactionDataSuccess({ transaction }));

        yield Put(Replace(`/settings/changeDataAccounts/${idTransaction}/ticket`));
    }
}

function* listEmailAccounts() {
    const response = yield Call(SelectorsMiddleware.listEmailAccounts);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.modifyUserFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.PERSONAL_INFORMATION],
            }),
        );
    } else {
        yield Put(SelectorsAction.listEmailAccountsSuccess({ listEmail: response.data?.data?.listEmail }));
    }
}

function* updateEmailAccounts(props) {
    const { mailCode, formikBag } = props;
    const newEmail = yield Select(SelectorsStoreSettings.getNewEmail);
    const params = { mailCode, newEmail };
    const response = yield Call(SelectorsMiddleware.updateMailAccounts, params);

    if (response.type === RESPONSE_TYPE.WARNING) {
        formikBag.setErrors(AdjustIdFieldErrors(response.data.data));
    } else {
        const { idTransaction } = response.data;
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(SelectorsActionSettings.updateMailSuccess({ transaction }));
    }
    formikBag.setSubmitting(false);
}

function* requestChangeTransactionData(props) {
    const { idTransaction } = props;
    const { type, data } = yield Call(SelectorsMiddlewareForm.readTransaction, {
        idTransactionToRead: idTransaction,
    });
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.CHANGE_EMAIL],
            }),
        );
    } else {
        yield Put(SelectorsAction.readChangeTransactionDataSuccess({ transaction: data.data.transaction }));
    }
}

function* cancelTransactionCustom(props) {
    const { credentials, idTransaction, formikBag } = props;
    yield* handleCancelTransaction(credentials, idTransaction, formikBag);

    formikBag.setSubmitting(false);
}

function* handleCancelTransaction(credentials, idTransaction, formikBag) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);
    const {
        data: { data },
        type,
    } = yield Call(SelectorsMiddlewareForm.cancelTransaction, {
        idTransactionToCancel: idTransaction,
        ...credentialsWithUnderscore,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data);
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("forms.cancelTransaction.errorMessage"),
                    level: LEVEL.ERROR,
                    scopes: SCOPE.CHANGE_EMAIL,
                }),
            );
        }
    } else {
        yield* requestChangeTransactionData({ idTransaction });
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("forms.cancelTransaction.confirmationMessage"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.CHANGE_EMAIL, SCOPE.DESKTOP, SCOPE.PERSONAL_INFORMATION],
            }),
        );
    }
}

function* signTransactionPreview() {
    yield Put(SelectorsAction.signTransactionPreviewSuccess());
}

function* signTransaction(props) {
    const { idForm, idActivity, idTransaction, credentials, formikBag } = props;
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { data, type } = yield Call(
        SelectorsMiddlewareForm.sign,
        { idForm, idTransaction, ...credentialsWithUnderscore },
        idActivity,
    );

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(AdjustIdFieldErrors(data.data));
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.CHANGE_EMAIL],
                }),
            );
        }
        yield Put(SelectorsActionSettings.signTransactionFailure);
        formikBag.setSubmitting(false);
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(
            SelectorsAction.updateMailSuccess({
                transaction,
            }),
        );
        formikBag.setSubmitting(false);
    }
}
