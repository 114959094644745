import React from "react";

import FileDownload from "js-file-download";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SUPPORTED_FILE_TYPES } from "~/constants";
import { PROP as FILES_PROP } from "~/store/files";
import { SelectorsStore as SelectorsStoreForm } from "~/store/form";
import { SelectorsStore as SelectorsStoreMultilineFile } from "~/store/multilinefile";
import { SelectorsStore as SelectorsStoreTransactionLines } from "~/store/transactionLines";

import PaymentLines from "~/pages/forms/_components/_fields/_transactionlines/PaymentLines";

export const NAME = "FormPaymentLines";

export const PROP = {
    types: {
        isConfirmation: PropTypes.bool,
        isFromBackoffice: PropTypes.bool,
        isFetching: PropTypes.bool.isRequired,
        paymentCurrency: PropTypes.string.isRequired,
        transactionLines: PropTypes.arrayOf(PropTypes.shape(FILES_PROP.listItemProps)),
    },
    defaults: {
        isConfirmation: false,
        isFromBackoffice: false,
        transactionLines: [],
    },
};

export function Component(props) {
    const { isFetching, isFromBackoffice, paymentCurrency, transactionLines } = props;

    return (
        <PaymentLines
            formats={[SUPPORTED_FILE_TYPES.XLS, SUPPORTED_FILE_TYPES.TXT]}
            isFetching={isFetching}
            isFromBackoffice={isFromBackoffice}
            onDownloadClick={handleDownloadClick}
            paymentCurrency={paymentCurrency}
            {...props}
        />
    );

    function linesToData(lines, currency) {
        const id = Date.now();
        return lines.reduce(
            (data, { creditAccountNumber, creditAmountCurrency, creditAmountQuantity, creditAccountName }) =>
                `${data}\n${creditAccountNumber},${creditAmountCurrency},${creditAmountQuantity},${creditAccountName}`,
            `${id},${currency}`,
        );
    }

    function handleDownloadClick(filterState, format) {
        const { filter } = filterState;

        const linesToDownload = transactionLines.filter((line) => !filter || filter(line));
        const data = linesToData(linesToDownload, paymentCurrency);

        FileDownload(data, `fileName.${format}`);
    }
}

Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

const mapStateToProps = (store) => ({
    formMetadata: SelectorsStoreForm.getMetadata(store),
    idFile: SelectorsStoreMultilineFile.getIdFile(store),
    isEditingPayment: SelectorsStoreTransactionLines.isEditingPayment(store),
    isFetching: SelectorsStoreTransactionLines.getFetching(store),
    paymentCurrency: SelectorsStoreTransactionLines.getCurrency(store),
    transactionLines: SelectorsStoreTransactionLines.getTransactionLines(store),
});

export default Connect(mapStateToProps)(Component);
