import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "permissions";

export const TYPE = (name) =>
    FactoryType(name, [
        "LOAD_PERMISSIONS_REQUEST",
        "LOAD_PERMISSIONS_FAILURE",
        "LOAD_PERMISSIONS_SUCCESS",
        "UPDATE_PERMISSIONS_PREVIEW",
        "UPDATE_PERMISSIONS_PREVIEW_SUCCESS",
        "UPDATE_PERMISSIONS_REQUEST",
        "UPDATE_PERMISSIONS_REQUEST_SUCCESS",
        "LOAD_PERMISSIONS_TICKET_REQUEST",
        "LOAD_PERMISSIONS_TICKET_FAILURE",
        "LOAD_PERMISSIONS_TICKET_SUCCESS",
    ]);

export const PROP = {};

PROP.members = {};

PROP.types = {
    products: PropTypes.array,
    permissions: PropTypes.any,
    futurePermissions: PropTypes.any,
    groups: PropTypes.array,
    fetching: PropTypes.bool,
    idTransaction: PropTypes.any,
    idActivity: PropTypes.any,
    credentialGroups: PropTypes.array,
    fetchingTicket: PropTypes.bool,
    caps: PropTypes.number,
    capFrequencyList: PropTypes.array,
    ...TypesRedux,
};

PROP.defaults = {
    products: [],
    permissions: null,
    futurePermissions: null,
    groups: [],
    fetching: true,
    idTransaction: null,
    idActivity: null,
    credentialGroups: [],
    fetchingTicket: true,
    caps: 0,
    capFrequencyList: [],
    ...DefaultsRedux,
};
