import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import * as creditCardsUtils from "~/util/creditCards";

import Image from "~/components/Image";

export const NAME = "ProductLabelIcon";

export const PROP = {
    types: { number: PropTypes.string },
    defaults: { number: EMPTY_STR },
};
export function Component(props) {
    const { number } = props;

    const brand = creditCardsUtils.detectBrand(number);

    if (!number) {
        return null;
    }

    return (
        <div className="product-label-icon">
            <Image src={`${brand}.svg`} className="svg-icon" />
            <span className="visually-hidden">{brand}</span>
        </div>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
