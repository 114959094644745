import React from "react";

import PropTypes from "prop-types";
import { Grid, Row, Col } from "react-bootstrap";
import { connect as Connect } from "react-redux";

import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionSettings, SelectorsStore as SelectorsStoreSettings } from "~/store/settings";

import Button from "~/components/Button";
import I18n from "~/components/I18n";
import Head from "~/pages/_components/Head";
import MainContainer, { PROP as PropMainContainer } from "~/pages/_components/MainContainer";

export const NAME = "ChangeSecuritySeal";

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        securitySeals: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string.isRequired).isRequired).isRequired,
        currentSecuritySeal: PropTypes.string,
        fetching: PropMainContainer.showLoader,
    },
    defaults: {
        currentSecuritySeal: "",
        fetching: null,
    },
};
export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(SelectorsActionSettings.changeSecuritySealPre());
    }

    handleClick = (id) => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionSettings.changeSecuritySealConfirmationPre({ id }));
    };

    getContent() {
        const { securitySeals, currentSecuritySeal } = this.props;
        const seals = Object.entries(securitySeals); // [[key,val], ...]
        return (
            <form className="above-the-fold">
                <section className="container--layout flex-grow align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-lead">
                                    <I18n id="settings.changeSecuritySeal.title" />
                                </p>
                            </Col>
                        </Row>
                    </Grid>
                </section>

                <section className="container--layout flex-grow align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12 image-grid">
                                <Row>
                                    {seals.map(([key, val]) => (
                                        <Col
                                            sm={4}
                                            md={3}
                                            className={`col col-4 ${key === currentSecuritySeal ? "is-current" : ""}`}
                                            key={`securitySeal-${key}`}>
                                            <Button
                                                className="btn-image"
                                                id={key}
                                                label=""
                                                onClick={() => this.handleClick(key)}
                                                style={{
                                                    backgroundImage: `url(${val})`,
                                                }}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </form>
        );
    }

    render() {
        const { fetching } = this.props;
        return (
            <React.Fragment>
                <Head title="settings.changeSecuritySeal" closeLinkTo="/settings" />
                <MainContainer showLoader={fetching}>{this.getContent()}</MainContainer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    securitySeals: SelectorsStoreSettings.getSecuritySeals(state),
    fetching: SelectorsStoreSettings.getFetching(state),
    currentSecuritySeal: SelectorsStoreSession.getUserSecuritySeal(state),
});

export default Connect(mapStateToProps)(Component);
