import React from "react";

import { connect as Connect } from "react-redux";

import { SPACE_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { numberFormat as NumberFormatUtil } from "~/util/number";

import I18n from "~/components/I18n";
import NumberFormatInput from "~/components/NumberFormatInput";

export const NAME = "FormatNumber";

export const PROP = {
    types: {},
    defaults: {},
};
export function Component({ amount, currency, lang, prefix, suffix }) {
    const { decimalSeparator, thousandSeparator } = NumberFormatUtil(lang);

    const renderText = (value) => {
        return (
            <span>
                {currency && (
                    <span className="data-amount-currency">
                        <I18n id={`currency.label.${currency}`} />
                        {SPACE_STR}
                    </span>
                )}
                {value}
            </span>
        );
    };
    return (
        <React.Fragment>
            {prefix && <small>{prefix} </small>}

            <NumberFormatInput
                decimalSeparator={decimalSeparator}
                thousandSeparator={thousandSeparator}
                decimalScale={2}
                value={parseFloat(amount, 10)}
                displayType="text"
                renderText={renderText}
                suffix={amount?.abbreviature}
                fixedDecimalScale
            />
            {suffix && <small>{suffix}</small>}
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    lang: SelectorsStoreI18n.getLang(store),
});

export default Connect(mapStateToProps)(Component);
