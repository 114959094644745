import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Form, Field, withFormik as WithFormik } from "formik";
import NumberFormat from "react-number-format";
import { connect as Connect } from "react-redux";

import { CDP_CURRENCY, COMMA, EMPTY_STR, LEVEL, REGEX_IGNORE_ACCENTS, SCOPE, SPACE_STR } from "~/constants";
import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { SelectorsStore as SelectorsStoreComex } from "~/store/comex";
import {
    SelectorsAction as SelectorsActionComexBondsAndGuarantees,
    PROP as PropComex,
    SelectorsStore as SelectorsStoreComexBondsAndGuarantees,
} from "~/store/comex/bondsAndGuarantees";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { SelectorsAction as SelectorActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionTemplate } from "~/store/template";
import * as UtilsConfig from "~/util/config";
import {
    formatDate as FormatDate,
    toDate as ToDate,
    addDay as AddDay,
    specificDate as SpecificDate,
} from "~/util/date";
import { isEmptyObj } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";
import { toNumber as ToNumber, numberFormat as NumberFormatUtil } from "~/util/number";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import NumberFormatInput from "~/components/NumberFormatInput";
import Select from "~/components/Select";
import DateField from "~/pages/_components/fields/DateField";
import EmailList from "~/pages/_components/fields/EmailList";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TextArea from "~/pages/_components/fields/TextArea";
import TextField from "~/pages/_components/fields/TextField";
import AmountField from "~/pages/_components/fields/formik/AmountField";
import Selector from "~/pages/_components/fields/formik/Selector";

import FileUploader from "~/pages/comEx/_components/FileUploader";
import CreateTemplateModal from "~/pages/forms/Step1Edit/_ModalTemplateCreate";
import ListTemplatesModal from "~/pages/forms/Step1Edit/_ModalTemplateList";
import { Termsandconditions } from "~/pages/forms/_components/_fields";
import Checkbox from "~/pages/forms/_components/_fields/_commons/Checkbox";
import DocumentField from "~/pages/settings/changePersonalInformation/field/DocumentField";

import Style from "../../../Step1Edit.rules.scss";

const FORM_ID = "comex.bondsAndGuarantees.requestBondsAndGuarantees";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...PropComex.types,
    },
    defaults: {
        ...PropComex.defaults,
    },
};

const FORMAT_TYPE = {
    SPECIFIC: "FORMATO_ESPECIFICO",
    YOUR: "VUESTRO_FORMATO",
};

const APPLICATION_TYPE = {
    LINE_OF_CREDIT: "LINEA_DE_CREDITO",
    PLEDGE_OF_DEPOSIT: "PRENDA_DE_DEPOSITO",
};

const ISSUED = {
    LOCAL: "LOCAL",
    INTERNATIONAL: "INTERNACIONAL",
};

const INTERNATIONAL_ISSUED = {
    LOCAL: "LOCAL",
    DIRECT: "DIRECTA",
};

const EXPENDITURE = {
    PAYER: "ORDENANTE",
    BENEFICIARY: "BENEFICIARIO",
    OTHER: "OTRO",
};

const GUARANTEE_OBLIGATIONS = {
    OWN: "PROPIOS",
    THIRD: "TERCEROS",
};

export const VALIDITY = {
    DATE_OF_ISSUE: "FECHA_EMIS_GARANTIA",
    FROM: "A_PARTIR_DE",
};

const PARTIAL_PAYMENTS = {
    YES: "PERMITIDOS",
    NO: "NO_PERMITIDOS",
};

const GUARANTEE_TYPE = {
    SUPPLY_MAINTENANCE: "MANTEN_OFERTA",
    FAITHFUL_COMPLIANCE: "FIEL_CUMPLIMIENTO",
    ADVANCE_FINANCIAL: "ANTICIPO_FINANCIERO",
    REPAIR_FUND: "FONDO_DE_REPARO",
    CONTRACT_COMPLIANCE: "CUMPL_CONTRATO_ALQ",
    TEMPORARY_ASMISSION: "ADMISION_TEMPORARIA",
    OTHERS: "OTROS",
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        loadInitialValues: false,
    };

    componentDidMount() {
        const { dispatch, formData } = this.props;

        if (isEmptyObj(formData)) {
            dispatch(SelectorsActionComexBondsAndGuarantees.requestBondsAndGuaranteesPreRequest());
        }
    }

    componentDidUpdate() {
        const {
            dispatch,
            draftLoaded,
            preData,
            setFieldError,
            setFieldTouched,
            setFieldValue,
            setValues,
            transaction,
            values,
        } = this.props;
        const { loadInitialValues } = this.state;
        const { nextValidDate, senderName, address, defaultCountry, defaultDocumentType } = preData;

        if (!loadInitialValues && !isEmptyObj(preData)) {
            this.setState({
                loadInitialValues: true,
            });

            if (senderName) {
                setFieldValue("payerName", senderName);
            }

            if (!values.payerAddress && address) {
                setFieldValue("payerAddress", address);
            }

            if (nextValidDate) {
                setFieldValue("valueDate", SpecificDate(nextValidDate, UtilsConfig.get("frontend.shortDateFormat")));
            }

            if (!values.validityDate && nextValidDate) {
                setFieldValue("validityDate", ToDate(nextValidDate));
            }

            if (!values.beneficiaryCountryId && defaultCountry) {
                setFieldValue("beneficiaryCountryId", defaultCountry);
            }

            if (!values.guaranteedDocument && defaultCountry && defaultDocumentType) {
                setFieldValue("guaranteedDocument", { type: defaultDocumentType, country: defaultCountry });
            }
        }

        if (transaction?.data && !draftLoaded) {
            dispatch(SelectorsActionComexBondsAndGuarantees.drafLoaded());

            const transactionData = transaction?.data;
            /* carga de errores */
            if (transactionData.backendFormErrors?.cdpErrorFields) {
                const { cdpErrorFields } = transactionData.backendFormErrors;

                cdpErrorFields.map((errorField) => {
                    setFieldError(errorField, SPACE_STR);
                    setFieldTouched(errorField, true);

                    return null;
                });
            }

            if (transactionData.backendFormErrors?.message) {
                const errorMessage = transactionData?.backendFormErrors?.message;

                dispatch(
                    SelectorActionNotification.showNotification({
                        message: errorMessage,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.COMEX_BONDS_AND_GUARANTEES],
                    }),
                );
            } // modificaciones para que agarren bien los fields
            /*  carga del draft */

            if (transactionData.dueDate) {
                const dueDate = ToDate(transactionData.dueDate);

                transactionData.dueDate = dueDate;
            }
            if (transactionData.validityDate) {
                const validityDate = ToDate(transactionData.validityDate);

                transactionData.validityDate = validityDate;
            }
            if (transactionData.finalDueDate) {
                const finalDueDate = ToDate(transactionData.finalDueDate);

                transactionData.finalDueDate = finalDueDate;
            }
            if (transactionData.guaranteeAmount && transactionData.guaranteeAmountCurrency) {
                const guaranteeAmount = {
                    amount: transactionData.guaranteeAmount,
                    currency: transactionData.guaranteeAmountCurrency,
                };

                transactionData.guaranteeAmount = guaranteeAmount;
            }

            setValues(transaction.data);
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(GoBack());
    };

    buildOptions = (originalOptions, field, empty) => {
        let options = Object.keys(originalOptions).map((o) => {
            return {
                label: UtilsI18n.get(`${FORM_ID}.${field}.${o}`),
                value: originalOptions[o],
            };
        });

        if (empty) {
            options = [{ value: EMPTY_STR, label: "\u00A0" }, ...options];
        }

        return options;
    };

    handleFormKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            event.preventDefault();
        }
    };

    // CANCEL
    handleCancel = () => {
        const { dispatch, setSubmitting, transaction } = this.props;

        dispatch(
            SelectorsActionForm.cancelTransactionCustom({
                credentials: {},
                idTransaction: transaction.idTransaction,
                formikBag: { setSubmitting },
            }),
        );
    };

    // DRAFT
    handleDraft = () => {
        const { dispatch, transaction, values } = this.props;

        dispatch(
            SelectorsActionForm.saveDraft({
                idActivityDraft: "comex.bondsAndGuarantees.requestBondsAndGuarantees.send",
                idTransactionToSave: transaction?.idTransaction,
                transactionData: values || {},
            }),
        );
    };

    // TEMPLATE
    handleTemplateSave = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionTemplate.createTemplate());
    };

    handleTemplateLoad = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionTemplate.toggleTemplateList());
    };

    handleDaysChange = (value) => {
        const { setFieldValue } = this.props;
        const dayOfValue = ToNumber(value);
        let daysOf = 0;

        if (dayOfValue) {
            daysOf = dayOfValue + 15;
        }

        setFieldValue("notificationDays", daysOf);
        setFieldValue("daysOf", dayOfValue);
    };

    handleSelectTemplate = (template) => {
        const { preData, setErrors, setValues } = this.props;
        const { nextValidDate, senderName } = preData;
        const templateToBeLoaded = template;

        if (templateToBeLoaded.dueDate) {
            const dueDate = ToDate(templateToBeLoaded.dueDate);

            templateToBeLoaded.dueDate = dueDate;
        }

        if (templateToBeLoaded.validityDate) {
            const validityDate = ToDate(templateToBeLoaded.validityDate);

            templateToBeLoaded.validityDate = validityDate;
        }

        if (templateToBeLoaded.finalDueDate) {
            const finalDueDate = ToDate(templateToBeLoaded.finalDueDate);

            templateToBeLoaded.finalDueDate = finalDueDate;
        }

        templateToBeLoaded.valueDate = FormatDate(ToDate(nextValidDate));
        templateToBeLoaded.payerName = senderName;

        setValues(templateToBeLoaded);
        setErrors({});
    };

    customFilter = (option, searchText) => {
        const optionLowerCase = option.name.toLowerCase();
        const text = searchText.toLowerCase();

        if (
            optionLowerCase
                .normalize("NFD")
                .replace(REGEX_IGNORE_ACCENTS, EMPTY_STR)
                .includes(text) &&
            optionLowerCase.charAt(0).includes(text.charAt(0))
        ) {
            return true;
        }

        return false;
    };

    handleInputChange = ({ target }) => {
        const { lang, setFieldValue } = this.props;
        const { decimalSeparator } = NumberFormatUtil(lang);
        const { id, value } = target;

        setFieldValue(id, ToNumber(value, decimalSeparator));
    };

    handleChange = (id, name) => {
        const { setFieldError, setFieldValue } = this.props;

        setFieldValue(name, id);
        setFieldError(name, null);
    };

    handleSelectorChange = (value, name) => {
        const { setFieldValue } = this.props;
        const { preData } = this.props;

        switch (name) {
            case "application": {
                if (value !== APPLICATION_TYPE.PLEDGE_OF_DEPOSIT) {
                    setFieldValue("pledgeOfDeposit", EMPTY_STR);
                }

                break;
            }
            case "issued": {
                if (value !== ISSUED.INTERNATIONAL) {
                    setFieldValue("internationalIssued", EMPTY_STR);
                    setFieldValue("informantBank", EMPTY_STR);
                    setFieldValue("expenditureOther", EMPTY_STR);
                    setFieldValue("expenditure", EXPENDITURE.PAYER);
                }

                break;
            }
            case "guaranteeObligations": {
                const { defaultCountry, defaultDocumentType } = preData;

                if (value !== GUARANTEE_OBLIGATIONS.THIRD) {
                    setFieldValue("guaranteedName", EMPTY_STR);
                    setFieldValue("guaranteedAddress", EMPTY_STR);
                    setFieldValue("guaranteedDocument", { type: defaultDocumentType, country: defaultCountry });
                    setFieldValue("linkWithCompany", EMPTY_STR);
                }

                break;
            }
            case "validity": {
                const { nextValidDate } = preData;

                if (value !== VALIDITY.FROM) {
                    setFieldValue("validityDate", ToDate(nextValidDate));
                }

                break;
            }
            case "expenditure": {
                if (value !== EXPENDITURE.OTHER) {
                    setFieldValue("expenditureOther", EMPTY_STR);
                }

                break;
            }
            case "guaranteeType": {
                if (value !== GUARANTEE_TYPE.OTHERS) {
                    setFieldValue("guaranteeTypeOther", EMPTY_STR);
                }

                break;
            }
            default:
                break;
        }
    };

    handleChangeCheckBox = (fieldName) => {
        const { setFieldValue, values } = this.props;

        setFieldValue(fieldName, !values[fieldName]);
    };

    render() {
        const { emailValidationRegex, errors, lang, preData, setFieldValue, touched, transaction, values } = this.props;
        const formatOptions = this.buildOptions(FORMAT_TYPE, "formatType");
        const applicationOptions = this.buildOptions(APPLICATION_TYPE, "application");
        const issuedOptionsOptions = this.buildOptions(ISSUED, "issued");
        const internationalIssuedOptions = this.buildOptions(INTERNATIONAL_ISSUED, "internationalIssued");
        const expenditureOptions = this.buildOptions(EXPENDITURE, "expenditure");
        const guaranteeObligationsOptions = this.buildOptions(GUARANTEE_OBLIGATIONS, "guaranteeObligations");
        const validityOptions = this.buildOptions(VALIDITY, "validity");
        const partialPaymentsOptions = this.buildOptions(PARTIAL_PAYMENTS, "partialPayments");
        const guaranteeTypeOptions = this.buildOptions(GUARANTEE_TYPE, "guaranteeType");
        const {
            countryList = [],
            currencyList = [],
            defaultCountry = EMPTY_STR,
            defaultDocumentType = EMPTY_STR,
            documentTypeList = [],
            maxDaysToSchedule = 0,
            nextValidDate: nextValidDateString,
        } = preData;
        const termsAndConditions = `${FORM_ID}.disclaimer`;
        const i18nMap = {
            termsAndConditions,
        };
        const nextValidDate = nextValidDateString ? ToDate(nextValidDateString) : new Date();
        const maxDateValidity = AddDay(nextValidDate, maxDaysToSchedule);
        const minDate = nextValidDateString ? ToDate(nextValidDateString) : new Date();
        const hasErrorBeneficiaryCountryId =
            touched.beneficiaryCountryId && errors.beneficiaryCountryId && !values.beneficiaryCountryId;

        const hasErrorAgreedCommission = touched.agreedCommission && errors.agreedCommission;
        const hasErrorMinimunAmount = touched.minimunAmount && errors.minimunAmount;
        const { decimalSeparator, thousandSeparator } = NumberFormatUtil(lang);

        const cancelEnabled = transaction && transaction.idTransactionStatus === STATUS.DRAFT;

        return (
            <React.Fragment>
                <Form
                    className="col col-12 col-lg-6 col-md-9 col-sm-12"
                    id={Style.ID}
                    noValidate="novalidate"
                    onKeyDown={this.handleFormKeyDown}>
                    <div className="form-section loadTemplates showTemplate">
                        <Button
                            onClick={this.handleTemplateLoad}
                            label="forms.templates.load"
                            bsStyle="default"
                            image="template.svg"
                        />
                    </div>
                    <section className="fields container--layout align-items-center flex-grow">
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.section.operation`)}</h3>
                        </Box>
                        <Field
                            idForm={FORM_ID}
                            name="valueDate"
                            id="valueDate"
                            component={TextField}
                            mode={MODE.VIEW}
                            hidePlaceholder
                            value={values.valueDate}
                            autoComplete="off"
                        />

                        <Field
                            component={Selector}
                            placeholder={EMPTY_STR}
                            name="formatType"
                            hidePlaceholder
                            options={formatOptions}
                            noResultsText={EMPTY_STR}
                            idForm={FORM_ID}
                            creatable={false}
                            clearable={false}
                            renderAs="combo"
                            className="flex-container slideFromBottom selector-letter"
                            optionClassName="needsclick"
                        />

                        {values.formatType === FORMAT_TYPE.SPECIFIC ? (
                            <Box className="form-group alert-icon">
                                <Image src="alertIconMedium.svg" />
                                <div className="form-help-text">{UtilsI18n.get(`${FORM_ID}.formatType.tooltip`)}</div>
                            </Box>
                        ) : (
                            <Box className="form-group specificFormat">
                                <I18n
                                    id={`${FORM_ID}.specificFormat`}
                                    URL_PREFIX={UtilsConfig.get(
                                        "client.transactions.comex.pdfs.url.prefix",
                                        "/formularios/comex",
                                    )}
                                    tag="p"
                                    className="mt-3"
                                />
                            </Box>
                        )}

                        <Field
                            component={Selector}
                            name="application"
                            hidePlaceholder
                            options={applicationOptions}
                            noResultsText={EMPTY_STR}
                            idForm={FORM_ID}
                            onChange={(item) => {
                                if (item) {
                                    this.handleSelectorChange(item.value, "application");
                                }
                            }}
                            creatable={false}
                            clearable={false}
                            renderAs="combo"
                            className="flex-container slideFromBottom selector-letter"
                            optionClassName="needsclick"
                        />

                        {values.application === APPLICATION_TYPE.PLEDGE_OF_DEPOSIT && (
                            <Field
                                component={TextArea}
                                idForm={FORM_ID}
                                name="pledgeOfDeposit"
                                mode={MODE.EDIT}
                                className="form-control"
                                maxLength={1500}
                                hidePlaceholder
                                hidelabel
                            />
                        )}

                        <Field
                            component={Selector}
                            placeholder={EMPTY_STR}
                            name="issued"
                            hidePlaceholder
                            value={values.issued}
                            clearable={false}
                            options={issuedOptionsOptions}
                            className="flex-container slideFromBottom selector-letter"
                            optionClassName="needsclick"
                            idForm={FORM_ID}
                            onChange={(item) => {
                                if (item) {
                                    this.handleSelectorChange(item.value, "issued");
                                }
                            }}
                        />

                        {values.issued === ISSUED.INTERNATIONAL && (
                            <React.Fragment>
                                <Field
                                    component={Selector}
                                    hidePlaceholder
                                    name="internationalIssued"
                                    hideLabel
                                    value={values.internationalIssued}
                                    clearable={false}
                                    searchable={false}
                                    valueKey="id"
                                    labelKey="label"
                                    options={internationalIssuedOptions}
                                    className="flex-container slideFromBottom selector-letter"
                                    optionClassName="needsclick"
                                    idForm={FORM_ID}
                                />

                                <Field
                                    component={TextArea}
                                    idForm={FORM_ID}
                                    name="informantBank"
                                    mode={MODE.EDIT}
                                    className="form-control"
                                    maxLength={50}
                                    hidePlaceholder
                                    tooltip={UtilsI18n.get(`${FORM_ID}.informantBank.tooltip`)}
                                />
                                <Box className="form-group">
                                    <div id="disclaimer-text">
                                        {UtilsI18n.get(`${FORM_ID}.informantBank.disclaimer.approval`)}
                                    </div>
                                </Box>

                                <Field
                                    component={Selector}
                                    hidePlaceholder
                                    placeholder={EMPTY_STR}
                                    name="expenditure"
                                    value={values.expenditure}
                                    clearable={false}
                                    valueKey="id"
                                    labelKey="label"
                                    options={expenditureOptions}
                                    className="flex-container slideFromBottom selector-letter"
                                    optionClassName="needsclick"
                                    idForm={FORM_ID}
                                    optional={UtilsI18n.get("form.field.optional")}
                                    onChange={(item) => {
                                        if (item) {
                                            this.handleSelectorChange(item.value, "expenditure");
                                        }
                                    }}
                                />

                                {values.expenditure === EXPENDITURE.OTHER && (
                                    <Field
                                        idForm={FORM_ID}
                                        hidelabel
                                        name="expenditureOther"
                                        id="expenditureOther"
                                        component={TextField}
                                        hidePlaceholder
                                        maxLength={12}
                                    />
                                )}
                            </React.Fragment>
                        )}
                        <Field
                            mode={MODE.VIEW}
                            idForm={FORM_ID}
                            name="payerName"
                            id="payerName"
                            component={TextField}
                            value={values.payerName}
                        />
                        <Field
                            idForm={FORM_ID}
                            name="payerAddress"
                            id="payerAddress"
                            component={TextField}
                            maxLength={50}
                            hidePlaceholder
                        />
                        <Field
                            component={TextArea}
                            idForm={FORM_ID}
                            name="payerContact"
                            mode={MODE.EDIT}
                            className="form-control"
                            maxLength={50}
                            hidePlaceholder
                            tooltip={UtilsI18n.get(`${FORM_ID}.payerContact.tooltip`)}
                            optional={UtilsI18n.get("form.field.optional")}
                        />
                        <Field
                            idForm={FORM_ID}
                            name="beneficiaryName"
                            id="beneficiaryName"
                            component={TextField}
                            maxLength={140}
                            hidePlaceholder
                            tooltip={UtilsI18n.get(`${FORM_ID}.beneficiaryName.tooltip`)}
                        />
                        <Field
                            idForm={FORM_ID}
                            name="beneficiaryAddress"
                            id="beneficiaryAddress"
                            component={TextField}
                            maxLength={140}
                            hidePlaceholder
                            tooltip={UtilsI18n.get(`${FORM_ID}.beneficiaryAddress.tooltip`)}
                        />
                        <Box className={`form-group ${hasErrorBeneficiaryCountryId ? "has-error" : EMPTY_STR}`}>
                            <FieldLabel labelKey={`${FORM_ID}.beneficiaryCountry`} />
                            <Box className="input-group country-select">
                                <Field
                                    component={Select}
                                    placeholder={EMPTY_STR}
                                    clearable={false}
                                    value={values.beneficiaryCountryId}
                                    valueKey="id"
                                    labelKey="name"
                                    filterOption={this.customFilter}
                                    options={countryList}
                                    className="flex-container slideFromBottom"
                                    optionClassName="needsclick"
                                    idForm={FORM_ID}
                                    onChange={(item) => {
                                        if (item) {
                                            this.handleChange(item.id, "beneficiaryCountryId");
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box className="form-group">
                            {hasErrorBeneficiaryCountryId && <FieldError error={errors.beneficiaryCountryId} />}
                        </Box>

                        <Box className="form-group document-field">
                            <Field
                                idForm={FORM_ID}
                                name="beneficiaryDocument"
                                component={DocumentField}
                                data={{ countries: countryList, documentTypes: documentTypeList }}
                                defaultCountry={defaultCountry}
                                defaultDocumentType={defaultDocumentType}
                                value={values.beneficiaryDocument}
                                autoFocus={false}
                                hidePlaceholder
                                hideDocumentPlaceholder
                                handleError
                                tooltip={UtilsI18n.get("client.comex.generic.documentNumber.tooltip")}
                            />
                        </Box>

                        <Field
                            component={Selector}
                            hidePlaceholder
                            placeholder={EMPTY_STR}
                            name="guaranteeObligations"
                            clearable={false}
                            value={values.guaranteeObligations}
                            options={guaranteeObligationsOptions}
                            className="flex-container slideFromBottom selector-letter"
                            optionClassName="needsclick"
                            idForm={FORM_ID}
                            onChange={(item) => {
                                if (item) {
                                    this.handleSelectorChange(item.value, "guaranteeObligations");
                                }
                            }}
                        />

                        {values.guaranteeObligations === GUARANTEE_OBLIGATIONS.THIRD && (
                            <React.Fragment>
                                <Field
                                    idForm={FORM_ID}
                                    name="guaranteedName"
                                    id="guaranteedName"
                                    component={TextField}
                                    maxLength={140}
                                    hidePlaceholder
                                    tooltip={UtilsI18n.get(`${FORM_ID}.guaranteedName.tooltip`)}
                                />
                                <Field
                                    idForm={FORM_ID}
                                    name="guaranteedAddress"
                                    id="guaranteedAddress"
                                    component={TextField}
                                    maxLength={140}
                                    hidePlaceholder
                                    tooltip={UtilsI18n.get(`${FORM_ID}.guaranteedAddress.tooltip`)}
                                />
                                <Box className="form-group document-field">
                                    <Field
                                        idForm={FORM_ID}
                                        name="guaranteedDocument"
                                        component={DocumentField}
                                        data={{ countries: countryList, documentTypes: documentTypeList }}
                                        defaultCountry={defaultCountry}
                                        defaultDocumentType={defaultDocumentType}
                                        autoFocus={false}
                                        hidePlaceholder
                                        hideDocumentPlaceholder
                                        handleError
                                        value={values.guaranteedDocument}
                                        tooltip={UtilsI18n.get("client.comex.generic.documentNumber.tooltip")}
                                    />
                                </Box>

                                <Field
                                    idForm={FORM_ID}
                                    name="linkWithCompany"
                                    id="linkWithCompany"
                                    component={TextField}
                                    maxLength={50}
                                    hidePlaceholder
                                    optional={UtilsI18n.get("form.field.optional")}
                                    tooltip={UtilsI18n.get(`${FORM_ID}.linkWithCompany.tooltip`)}
                                />
                                <Box className="form-group">
                                    <div id="disclaimer-text">
                                        {UtilsI18n.get(`${FORM_ID}.linkWithCompany.disclaimer`)}
                                    </div>
                                </Box>
                            </React.Fragment>
                        )}
                        <Field
                            clearable={false}
                            component={AmountField}
                            data={{
                                options: currencyList.map((c) => {
                                    return { id: c, label: UtilsI18n.get(`core.currency.label.${c}`) };
                                }),
                            }}
                            idForm={FORM_ID}
                            name="guaranteeAmount"
                            placeholder={EMPTY_STR}
                            decimalPlaces={2}
                            fixedDecimalScale
                            maxLength={UtilsConfig.getInteger("amount.length")}
                        />

                        <Field
                            component={Selector}
                            hidePlaceholder
                            placeholder={EMPTY_STR}
                            name="validity"
                            value={values.validity}
                            clearable={false}
                            options={validityOptions}
                            className="flex-container slideFromBottom selector-letter"
                            optionClassName="needsclick"
                            idForm={FORM_ID}
                            onChange={(item) => {
                                if (item) {
                                    this.handleSelectorChange(item.value, "validity");
                                }
                            }}
                        />

                        {values.validity === VALIDITY.FROM && (
                            <Field
                                idForm={FORM_ID}
                                name="validityDate"
                                id="validityDate"
                                component={DateField}
                                hidePlaceholder
                                autoComplete="off"
                                hideLabel
                                minDate={minDate}
                                maxDate={maxDateValidity}
                            />
                        )}
                        <Field
                            idForm={FORM_ID}
                            name="dueDate"
                            id="dueDate"
                            component={DateField}
                            hidePlaceholder
                            autoComplete="off"
                            minDate={minDate}
                            maxDate={null}
                        />
                        <Box className="form-group">
                            <FieldLabel labelKey={`${FORM_ID}.automaticRenewal`} classContainer="label-check" />
                            <Checkbox
                                idForm={FORM_ID}
                                checked={values.automaticRenewal}
                                onChange={() => this.handleChangeCheckBox("automaticRenewal")}
                                name="automaticRenewal"
                            />
                        </Box>
                        {values.automaticRenewal && (
                            <React.Fragment>
                                <Box className="form-group">
                                    {UtilsI18n.get(`${FORM_ID}.daysOf.except`)}

                                    <NumberFormat
                                        className="daysOf"
                                        value={values.daysOf}
                                        maxLength="2"
                                        fixedDecimalScale
                                        displayType="input"
                                        type="text"
                                        onValueChange={({ value }) => this.handleDaysChange(value)}
                                    />
                                    {UtilsI18n.get(`${FORM_ID}.daysOf.notify`)}
                                    <div className="tooltip">
                                        <span className="tooltip-text">
                                            {UtilsI18n.get(`${FORM_ID}.daysOf.tooltip`)}
                                        </span>
                                    </div>
                                </Box>
                                <Field
                                    mode={MODE.VIEW}
                                    idForm={FORM_ID}
                                    name="notificationDays"
                                    id="notificationDays"
                                    component={TextField}
                                    value={values.notificationDays}
                                />
                                <Box className="form-group">
                                    <div id="disclaimer-text">
                                        {UtilsI18n.get(`${FORM_ID}.notificationDays.disclaimer`)}
                                    </div>
                                </Box>

                                <Field
                                    idForm={FORM_ID}
                                    name="finalDueDate"
                                    id="finalDueDate"
                                    minDate={values.dueDate}
                                    maxDate={null}
                                    component={DateField}
                                    hidePlaceholder
                                    autoComplete="off"
                                    optional={UtilsI18n.get("form.field.optional")}
                                />
                            </React.Fragment>
                        )}

                        <Field
                            component={Selector}
                            hidePlaceholder
                            placeholder={EMPTY_STR}
                            name="partialPayments"
                            value={values.partialPayments}
                            clearable={false}
                            options={partialPaymentsOptions}
                            className="flex-container slideFromBottom selector-letter"
                            optionClassName="needsclick"
                            idForm={FORM_ID}
                        />

                        <Field
                            component={Selector}
                            hidePlaceholder
                            placeholder={EMPTY_STR}
                            name="guaranteeType"
                            value={values.guaranteeType}
                            clearable={false}
                            options={guaranteeTypeOptions}
                            className="flex-container slideFromBottom selector-letter"
                            optionClassName="needsclick"
                            idForm={FORM_ID}
                            onChange={(item) => {
                                if (item) {
                                    this.handleSelectorChange(item.value, "guaranteeType");
                                }
                            }}
                        />

                        {values.guaranteeType === GUARANTEE_TYPE.OTHERS && (
                            <Field
                                idForm={FORM_ID}
                                name="guaranteeTypeOther"
                                id="guaranteeTypeOther"
                                component={TextField}
                                maxLength={65}
                                hidePlaceholder
                                hidelabel
                            />
                        )}

                        <Field
                            component={TextArea}
                            idForm={FORM_ID}
                            name="subjectGuarantee"
                            mode={MODE.EDIT}
                            className="form-control"
                            maxLength={500}
                            hidePlaceholder
                            tooltip={UtilsI18n.get(
                                "comex.bondsAndGuarantees.requestBondsAndGuarantees.subjectGuarantee.tooltip",
                            )}
                        />
                        <Box className="form-group">
                            <FieldLabel labelKey={`${FORM_ID}.notarialCertification`} classContainer="label-check" />
                            <Checkbox
                                idForm={FORM_ID}
                                checked={values.notarialCertification}
                                name="notarialCertification"
                                onChange={() => this.handleChangeCheckBox("notarialCertification")}
                            />
                        </Box>
                        <Box className={`form-group ${hasErrorAgreedCommission ? "has-error" : EMPTY_STR}`}>
                            <FieldLabel labelKey={`${FORM_ID}.agreedCommission.label`} />
                            <Box className="input-group">
                                <Field
                                    id="agreedCommission"
                                    idForm={FORM_ID}
                                    allowNegative={false}
                                    className="form-control"
                                    component={NumberFormatInput}
                                    decimalScale={2}
                                    decimalSeparator={decimalSeparator}
                                    fixedDecimalScale
                                    maxLength={20}
                                    name="agreedCommission"
                                    thousandSeparator={thousandSeparator}
                                    type="input"
                                    sufix="%"
                                    value={values.agreedCommission}
                                    onChange={this.handleInputChange}
                                />
                                <div className="add-type">
                                    {UtilsI18n.get(`${FORM_ID}.agreedCommission.percentage`)}
                                </div>
                                <div className="tooltip">
                                    <span className="tooltip-text">
                                        {UtilsI18n.get(`${FORM_ID}.agreedCommission.tooltip`)}
                                    </span>
                                </div>
                            </Box>
                        </Box>
                        <Box className="form-group">
                            {hasErrorAgreedCommission && <FieldError error={errors.agreedCommission} />}
                        </Box>

                        <Box className={`form-group ${hasErrorMinimunAmount ? "has-error" : EMPTY_STR}`}>
                            <FieldLabel labelKey={`${FORM_ID}.minimunAmount.label`} />
                            <Box className="input-group">
                                <Field
                                    id="minimunAmount"
                                    idForm={FORM_ID}
                                    allowNegative={false}
                                    className="form-control"
                                    component={NumberFormatInput}
                                    decimalScale={2}
                                    decimalSeparator={decimalSeparator}
                                    fixedDecimalScale
                                    maxLength={20}
                                    name="minimunAmount"
                                    thousandSeparator={thousandSeparator}
                                    type="input"
                                    sufix="%"
                                    value={values.minimunAmount}
                                    onChange={this.handleInputChange}
                                />
                            </Box>
                        </Box>
                        <Box className="form-group">
                            {hasErrorMinimunAmount && <FieldError error={errors.minimunAmount} />}
                        </Box>

                        <Box className="form-group">
                            <div id="disclaimer-text">{UtilsI18n.get(`${FORM_ID}.minimunAmount.disclaimer`)}</div>
                        </Box>

                        <Box className="form-group document-field">
                            <Field
                                idForm={FORM_ID}
                                name="authorized1Document"
                                component={DocumentField}
                                data={{ countries: countryList, documentTypes: documentTypeList }}
                                defaultCountry={defaultCountry}
                                defaultDocumentType={defaultDocumentType}
                                autoFocus={false}
                                hidePlaceholder
                                hideDocumentPlaceholder
                                handleError
                                value={values.authorized1Document}
                                tooltip={UtilsI18n.get("client.comex.generic.documentNumber.tooltip")}
                            />
                        </Box>
                        <Field
                            idForm={FORM_ID}
                            name="authorized1Name"
                            id="authorized1Name"
                            component={TextField}
                            maxLength={140}
                            hidePlaceholder
                            tooltip={UtilsI18n.get(`${FORM_ID}.authorized1Name.tooltip`)}
                        />
                        <Box className="form-group document-field">
                            <Field
                                idForm={FORM_ID}
                                name="authorized2Document"
                                component={DocumentField}
                                data={{ countries: countryList, documentTypes: documentTypeList }}
                                defaultCountry={defaultCountry}
                                defaultDocumentType={defaultDocumentType}
                                autoFocus={false}
                                hidePlaceholder
                                hideDocumentPlaceholder
                                value={values.authorized2Document}
                                tooltip={UtilsI18n.get("client.comex.generic.documentNumber.tooltip")}
                                optional={UtilsI18n.get("form.field.optional")}
                                handleError
                            />
                        </Box>
                        <Field
                            idForm={FORM_ID}
                            name="authorized2Name"
                            id="authorized2Name"
                            component={TextField}
                            maxLength={140}
                            hidePlaceholder
                            tooltip={UtilsI18n.get(`${FORM_ID}.authorized2Name.tooltip`)}
                        />
                        <Box className="form-group">
                            <Field
                                name="files"
                                idForm={FORM_ID}
                                emptyMessageKey={`${FORM_ID}.attachments.emptyMessage`}
                                maxFiles={5}
                                errors={errors}
                                component={FileUploader}
                                values={values}
                                setFieldValue={setFieldValue}
                            />
                        </Box>
                        <Field
                            component={TextArea}
                            idForm={FORM_ID}
                            name="observations"
                            mode={MODE.EDIT}
                            className="form-control"
                            maxLength={500}
                            hidePlaceholder
                        />
                        <Field
                            component={EmailList}
                            idForm={FORM_ID}
                            name="notificationEmails"
                            hideSwiftTip
                            className="form-group email-field"
                            renderSuggestion={false}
                            data={{ emailValidationRegex, addMessage: EMPTY_STR }}
                            value={values.notificationEmails}
                            tooltip={UtilsI18n.get(`${FORM_ID}.notificationEmails.tooltip`)}
                        />

                        <hr />
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">
                                {UtilsI18n.get(`${FORM_ID}.section.showPreview`)}
                            </h3>
                        </Box>

                        <Field
                            component={Termsandconditions}
                            idForm={FORM_ID}
                            name="disclaimer"
                            mode={MODE.EDIT}
                            className="form-control"
                            i18nMap={i18nMap}
                        />
                    </section>
                    <footer>
                        <Button onClick={this.handleDraft} label="forms.saveDraft.link" />
                        <Button onClick={this.handleTemplateSave} label="forms.saveTemplate.link" />
                        {cancelEnabled && <Button onClick={this.handleCancel} label="forms.cancelTransaction.link" />}
                        <Button
                            key="primaryButton"
                            variant="primary"
                            bsStyle="primary"
                            label="global.next"
                            loading={false}
                            type="submit"
                        />
                    </footer>
                </Form>
                <ListTemplatesModal
                    idActivityTemplate="comex.bondsAndGuarantees.requestBondsAndGuarantees.send"
                    onSelect={this.handleSelectTemplate}
                />
                <CreateTemplateModal
                    values={values}
                    idActivityTemplate="comex.bondsAndGuarantees.requestBondsAndGuarantees.send"
                    backdrop="static"
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        draftLoaded: SelectorsStoreComexBondsAndGuarantees.getDraftLoaded(store),
        formData: SelectorsStoreComexBondsAndGuarantees.getRequestBondsAndGuaranteesOpenFormData(store),
        lang: SelectorsStoreI18n.getLang(store),
        preData: SelectorsStoreComexBondsAndGuarantees.getRequestBondsAndGuaranteesOpenPre(store),
        transaction: SelectorsStoreComex.getTransaction(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: false,
        validateOnChange: false,
        validateOnBlur: false,

        mapPropsToValues: ({ formData }) => {
            const attachments = formData?.attachments;
            const hasAttachments = attachments && attachments !== EMPTY_STR;
            return {
                agreedCommission: formData?.agreedCommission >= 0 ? formData?.agreedCommission : EMPTY_STR,
                application: formData?.application || EMPTY_STR,
                attachmentsNames: formData?.attachmentsNames || [],
                authorized1Document: formData?.authorized1Document,
                authorized1Name: formData?.authorized1Name || EMPTY_STR,
                authorized2Document: formData?.authorized2Document,
                authorized2Name: formData?.authorized2Name || EMPTY_STR,
                automaticRenewal: formData?.automaticRenewal || false,
                beneficiaryAddress: formData?.beneficiaryAddress || EMPTY_STR,
                beneficiaryCountryId: formData?.beneficiaryCountryId,
                beneficiaryDocument: formData?.beneficiaryDocument,
                beneficiaryName: formData?.beneficiaryName || EMPTY_STR,
                daysOf: formData?.daysOf || 30,
                dueDate: formData.dueDate ? ToDate(formData.dueDate) : EMPTY_STR,
                expenditure: formData?.expenditure || EXPENDITURE.PAYER,
                expenditureOther: formData?.expenditure || EMPTY_STR,
                files: hasAttachments ? attachments?.split(COMMA) : [],
                finalDueDate: formData.finalDueDate ? ToDate(formData.finalDueDate) : EMPTY_STR,
                formatType: formData?.formatType || FORMAT_TYPE.YOUR,
                guaranteeAmount: {
                    currency: formData?.guaranteeAmountCurrency || CDP_CURRENCY.USD,
                    amount: formData?.guaranteeAmount || null,
                },
                guaranteedAddress: formData?.guaranteedAddress || EMPTY_STR,
                guaranteedDocument: formData?.guaranteedDocument,
                guaranteedName: formData?.guaranteedName || EMPTY_STR,
                guaranteeObligations: formData?.guaranteeObligations || GUARANTEE_OBLIGATIONS.OWN,
                guaranteeType: formData?.guaranteeType || GUARANTEE_TYPE.SUPPLY_MAINTENANCE,
                guaranteeTypeOther: formData?.guaranteeTypeOther || EMPTY_STR,
                informantBank: formData?.informantBank || EMPTY_STR,
                internationalIssued: formData?.internationalIssued || EMPTY_STR,
                issued: formData?.issued || ISSUED.LOCAL,
                linkWithCompany: formData?.linkWithCompany || EMPTY_STR,
                minimunAmount: formData?.minimunAmount >= 0 ? formData?.minimunAmount : EMPTY_STR,
                notarialCertification: formData?.notarialCertification || false,
                notificationDays: formData?.notificationDays || 45,
                notificationEmails: formData?.notificationEmails || [],
                observations: formData?.observations || EMPTY_STR,
                partialPayments: formData?.partialPayments || PARTIAL_PAYMENTS.NO,
                payerAddress: formData?.payerAddress || EMPTY_STR,
                payerContact: formData?.payerContact || EMPTY_STR,
                payerName: formData?.payerName || EMPTY_STR,
                pledgeOfDeposit: formData?.pledgeOfDeposit || EMPTY_STR,
                subjectGuarantee: formData?.subjectGuarantee || EMPTY_STR,
                validityDate: formData.validityDate ? ToDate(formData.validityDate) : null,
                validity: formData?.validity || VALIDITY.DATE_OF_ISSUE,
                valueDate: formData?.valueDate,
            };
        },
        handleSubmit: (props, formikBag) => {
            const { dispatch, transaction, preData } = formikBag.props;
            const {
                boardingDate,
                dueDate,
                files,
                finalDueDate,
                guaranteeAmount,
                validityDate,
                ...rest
            } = props;

            const { nextValidDate } = preData;
            const amount = guaranteeAmount ? guaranteeAmount.amount : 0;
            const currency = guaranteeAmount ? guaranteeAmount.currency : 0;
            const attachmentsFiles = files ? files.join(COMMA) : EMPTY_STR;
            const validityDateString = validityDate ? FormatDate(new Date(validityDate)) : EMPTY_STR;
            const dueDateString = dueDate ? FormatDate(new Date(dueDate)) : EMPTY_STR;
            const finalDueDateString = finalDueDate ? FormatDate(new Date(finalDueDate)) : EMPTY_STR;
            const valueDateString = nextValidDate ? FormatDate(ToDate(nextValidDate)) : FormatDate(new Date());
            const formData = {
                ...rest,
                attachments: attachmentsFiles,
                dueDate: dueDateString,
                finalDueDate: finalDueDateString,
                guaranteeAmount: amount,
                guaranteeAmountCurrency: currency,
                idTransaction: transaction?.idTransaction,
                validityDate: validityDateString,
                valueDate: valueDateString,
            };

            dispatch(
                SelectorsActionComexBondsAndGuarantees.requestBondsAndGuaranteesPreviewRequest({
                    formData,
                    formikBag,
                }),
            );
        },
    }),
)(Component);
