import React from "react";

import PropTypes from "prop-types";

import { Categorize, Prefix } from "~/util/prop";

import { Page } from "~/components/Component";

import Style from "./_index.scss";

import External from "..";

export const { NAME, TYPE } = Style;
export const PROP = {
    types: {
        name: PropTypes.string.isRequired,
        children: PropTypes.node.isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const { wait, head, scopeToShowNotification, ...propsPage } = Categorize(props);
    const { children, name, ...rest } = propsPage;
    return (
        <External
            {...Prefix("head", head)}
            id={Style.ID}
            name={NAME}
            wait={wait}
            scopeToShowNotification={scopeToShowNotification}
            isPayment>
            <Page {...rest} name={name}>
                {children}
            </Page>
        </External>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
