import React from "react";

import { push as Push } from "connected-react-router";
import PropTypes from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router";
import { compose as Compose } from "redux";

import { EMPTY_STR, LEVEL, SCOPE, MSG_RETURN_CODE_PREFIX } from "~/constants";
import { SelectorsAction, SelectorsStore } from "~/store/enrollment";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as I18nUtils from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import ExternalFooterMobile from "~/components/ExternalFooterMobile";
import ExternalHeaderMobile from "~/components/ExternalHeaderMobile";
import I18n from "~/components/I18n";
import Modal from "~/components/Modal";
import Container from "~/pages/_components/Container";
import MainContainer from "~/pages/_components/MainContainer";

export const NAME = "SecuritySealConfirmation";

export const PROP = {
    types: {
        ...TypesRedux,
        seal: PropTypes.number,
        exchangeToken: PropTypes.string.isRequired,
        updateSecuritySeal: PropTypes.bool.isRequired,
        securitySealList: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                image: PropTypes.string,
            }),
        ),
        selectedSecuritySeal: PropTypes.shape({
            id: PropTypes.string,
            image: PropTypes.string,
        }),
        match: PropTypes.shape({
            params: PropTypes.shape({ id: PropTypes.string }),
        }).isRequired,
        showSealConfirmationModal: PropTypes.bool.isRequired,
    },
    defaults: {
        ...DefaultsRedux,
        showSealConfirmationModal: false,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        const { dispatch, exchangeToken } = this.props;
        if (!exchangeToken) {
            // TODO: Revisar, si queda habria que limpiar el store
            dispatch(
                SelectorsActionNotification.showNotification({
                    message: I18nUtils.get(`${MSG_RETURN_CODE_PREFIX}.API006E`),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.INVITATION_CODE],
                }),
            );
            dispatch(Push("/invitationCode"));
        }
    }

    handleClick = () => {
        const {
            dispatch,

            seal,
            securitySealList,
        } = this.props;

        let { selectedSecuritySeal } = this.props;

        selectedSecuritySeal = {
            id: seal,
            image: securitySealList[seal],
        };

        dispatch(SelectorsAction.saveSelectedSecuritySeal({ selectedSecuritySeal }));
        dispatch(SelectorsAction.hideSecuritySealConfirmationModal());
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsAction.hideSecuritySealConfirmationModal());
        dispatch(SelectorsAction.showSecuritySealModal());
    };

    render() {
        const { seal, securitySealList, showSealConfirmationModal } = this.props;

        return (
            <Modal show={showSealConfirmationModal}>
                <div className="enrollment page-login">
                    {/* TODO: Hay que agregarle al header la opcion de tener un boton volver */}
                    <ExternalHeaderMobile />
                    <MainContainer className="main-container">
                        <div className="above-the-fold">
                            <Container
                                className="container--layout align-items-center flex-grow security-image-enrollment"
                                gridClassName="form-content">
                                <Col sm={12} md={10} lg={6} xl={6} className="col col-12">
                                    <p className="text-lead">
                                        <I18n id="enrollment.registerPersonalData.securitySeal.confirmation.title" />
                                    </p>
                                    <div className="security-image">
                                        <div className="image">
                                            <figure>
                                                {securitySealList && (
                                                    <img src={securitySealList[seal]} alt={EMPTY_STR} />
                                                )}
                                            </figure>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12} md={10} lg={6} xl={6} className="col col-12">
                                    <div>
                                        <Button
                                            bsStyle="primary"
                                            label="global.accept"
                                            onClick={this.handleClick}
                                            type="button"
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            className="btn btn-outline btn-block"
                                            label="enrollment.registerPersonalData.securitySeal.confirmation.back"
                                            onClick={this.handleBack}
                                            type="button"
                                        />
                                    </div>
                                </Col>
                            </Container>
                        </div>
                    </MainContainer>
                    <ExternalFooterMobile />
                </div>
            </Modal>
        );
    }
}

export default Compose(
    Connect((store) => ({
        exchangeToken: SelectorsStore.exchangeToken(store),
        seal: SelectorsStore.seal(store),
        showSealConfirmationModal: SelectorsStore.showSealConfirmationModal(store),
        securitySealList: SelectorsStore.securitySealList(store),
        updateSecuritySeal: SelectorsStore.updateSecuritySeal(store),
        selectedSecuritySeal: SelectorsStore.selectedSecuritySeal(store),
    })),
)(WithRouter(Component));
