import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";

import Image from "~/components/Image";
import FieldError from "~/pages/_components/fields/FieldError";

import Style from "~/pages/forms/_components/_fields/_commons/Checkbox.rules.scss";

export const NAME = "Checkbox";

export const PROP = {
    types: {
        checked: PropTypes.bool.isRequired,
        label: PropTypes.string,
        controlStyle: PropTypes.string,
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        isDisabled: PropTypes.bool,
    },
    defaults: { controlStyle: "checkbox", isDisabled: false, label: EMPTY_STR },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    handleClick = () => {
        const { onChange, value } = this.props;

        onChange(value);
    };

    render() {
        const { checked, controlStyle, field, form, isDisabled, label, canRenderFieldError, value } = this.props;
        const { touched, errors } = form || {};

        let hasError;

        if (touched && errors) {
            hasError = canRenderFieldError && touched[field.name] && errors[field.name];
        }

        return (
            <div id={Style.ID}>
                <div
                    onClick={this.handleClick}
                    className="c-control c-control--has-icon c-control--checkbox c-control-block">
                    <input
                        disabled={isDisabled}
                        readOnly
                        className="c-control-input"
                        type="checkbox"
                        value={value}
                        checked={checked}
                    />
                    <label className="c-control-label">
                        {controlStyle === ("checkbox" || "radio") && (
                            <div className="c-control-icons">
                                <div className="c-control-mark">
                                    <Image src="check.svg" className="svg-icon svg-caret" />
                                </div>
                            </div>
                        )}
                        <div className="form-group-text">{label}</div>
                    </label>
                </div>
                {hasError && <FieldError error={errors[field.name]} />}
            </div>
        );
    }
}

export default Component;
