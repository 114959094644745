import React from "react";

import { routerActions as RouterActions } from "connected-react-router";
import { connect as Connect } from "react-redux";
import { bindActionCreators } from "redux";

import { SelectorsStore as SelectorsStoreGroupTicket } from "~/store/administration/common/administrationTicket";

import AdministrationTicket from "~/pages/administration/_components/tickets/AdministrationTicket";
import GroupFormBlockTicketContent from "~/pages/administration/_components/tickets/GroupFormBlockTicketContent";

export const NAME = "GroupFormBlockTicketConfirmation";

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    return (
        <AdministrationTicket {...props}>
            <GroupFormBlockTicketContent {...props} />
        </AdministrationTicket>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (state) => ({
    transactionData: SelectorsStoreGroupTicket.getData(state),
});

const mapDispatchToProps = (dispatch) => ({
    routerActions: bindActionCreators(RouterActions, dispatch),
});

export default Connect(mapStateToProps, mapDispatchToProps)(Component);
