import { DOT, EMPTY_STR, FORMAT_SHORT } from "~/constants";
import { get as Get } from "~/util/config";
import { specificDate as SpecificDate } from "~/util/date";
import { getLang as GetLang } from "~/util/i18n";

export const getLastLoginDate = (previousLoginInfo) => {
    if (previousLoginInfo) {
        const { date } = previousLoginInfo;

        const format = Get(`menu.lastConnection.formatCDP.${GetLang()}`, FORMAT_SHORT);

        return SpecificDate(date, format);
    }
    return null;
};

export const getLastLoginPlace = (previousLoginInfo) => {
    if (previousLoginInfo) {
        const { country, city } = previousLoginInfo;
        return city !== EMPTY_STR ? `${city}, ${country}` : country;
    }
    return null;
};

export const getLastLoginPosition = (previousLoginList) => {
    if (previousLoginList && previousLoginList.length > 1) {
        return [previousLoginList[0]];
    }
    return null;
};

export const LastLogin = {
    date: getLastLoginDate,
    place: getLastLoginPlace,
    position: getLastLoginPosition,
};

export const calculateScoreStrengthPassword = (pass) => {
    let score = 0;
    const letters = /[A-Za-z]/;
    const digits = /\d/;
    const chars = /[#_?!@$%^&*,(-)-.+]/;

    if (!pass) {
        return score;
    }

    const regexx = [letters, digits, chars];

    for (let i = 0; i < regexx.length; i += 1) {
        if (regexx[i].test(pass)) {
            score += 1;
        }
    }

    if (pass.length >= 8) {
        score *= 100 / 3;
    } else {
        score *= 16 + pass.length;
    }

    return parseInt(score, 10);
};

export const isUserMakingUseOfMinVersion = (userChannelVersion, operationMinChannelVersion) => {
    const userChannelVersionSplit = userChannelVersion.split(DOT);
    const operationMinChannelVersionSplit = operationMinChannelVersion.split(DOT);

    // Version numbers' first parts are compared
    if (Number(userChannelVersionSplit[0]) < Number(operationMinChannelVersionSplit[0])) {
        // User version is less than required min version
        return false;
    }

    if (Number(userChannelVersionSplit[0]) > Number(operationMinChannelVersionSplit[0])) {
        // User version is greater than required min version
        return true;
    }

    // FIRST PARTS ARE EQUAL, then version numbers' second parts are compared
    if (Number(userChannelVersionSplit[1]) < Number(operationMinChannelVersionSplit[1])) {
        // User version is less than required min version
        return false;
    }

    if (Number(userChannelVersionSplit[1]) > Number(operationMinChannelVersionSplit[1])) {
        // User version is greater than required min version
        return true;
    }

    // SECOND PARTS ARE EQUAL, then version numbers' third parts are compared
    if (Number(userChannelVersionSplit[2]) < Number(operationMinChannelVersionSplit[2])) {
        // User version is less than required min version
        return false;
    }

    if (Number(userChannelVersionSplit[2]) > Number(operationMinChannelVersionSplit[2])) {
        // User version is greater than required min version
        return true;
    }

    // BOTH VERSIONS' FIRST THREE PARTS ARE EQUAL,
    // now it is checked if either the user or the min version has a fourth part (hotfix release)
    if (userChannelVersionSplit.length < operationMinChannelVersionSplit.length) {
        // User is using the version, but not the required hotfix
        return false;
    }

    if (userChannelVersionSplit.length > operationMinChannelVersionSplit.length) {
        // User has a higher hotfix version, but it is not the case of the required min version
        return true;
    }

    if (userChannelVersionSplit.length === 3) {
        // Both versions are equal (NO HOTFIXES)
        return true;
    }

    // BOTH USER AND REQUIRED MIN VERSION ARE HOTFIXES, now the last (fourth) part is compared
    if (Number(userChannelVersionSplit[3]) < Number(operationMinChannelVersionSplit[3])) {
        // User version is less than required min version
        return false;
    }

    if (Number(userChannelVersionSplit[3]) > Number(operationMinChannelVersionSplit[3])) {
        // User version is greater than required min version
        return true;
    }

    // Both versions are the same
    return true;
};

export default {
    getLastLoginDate,
    getLastLoginPlace,
    getLastLoginPosition,
    getPasswordStrengh: calculateScoreStrengthPassword,
    isUserMakingUseOfMinVersion,
};
