import React from "react";

import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import { compose as Compose } from "redux";

import { EMPTY_STR } from "~/constants";
import { STATUS, STATUS_FINAL } from "~/constants/transaction";
import { SelectorsStore as SelectorsStoreForm } from "~/store/form";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { flattenArray as FlattenArray, removeDuplicateItems as RemoveDuplicateItems } from "~/util/array";
import DeviceUtils from "~/util/device";
import { requestReview as RequestReview } from "~/util/requestReview";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import ConfirmationModal from "~/pages/_components/ConfirmationModal";
import TicketPrintInformation from "~/pages/_components/TicketPrintInformation";

import Unavailable from "~/pages/error/Unavailable";
import FormSignatures from "~/pages/forms/_components/FormSignatures";
import TransactionStatus from "~/pages/transactions/_components/TransactionStatus";

import Style from "./Ticket.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        children: PropTypes.node,
        hasMobileComponent: PropTypes.bool,
        transaction: PropTypes.shape({
            executing: PropTypes.bool,
            idTransaction: PropTypes.string,
            idTransactionStatus: PropTypes.string,
            signatures: PropTypes.array,
            valueDateAsString: PropTypes.string,
        }).isRequired,
    },
    defaults: {
        children: null,
        hasMobileComponent: false,
    },
};

export function Component(props) {
    const {
        children,
        className,
        displayDownloadPDF = false,
        handleDownloadPDF,
        hasMobileComponent,
        history,
        isDesktop,
        ticketConfirmation,
        transaction,
    } = props;
    const { from: locationFrom } = history.location.state || {};
    const { executing, idTransaction, idActivity, idTransactionStatus, signatures, valueDateAsString } = transaction;
    const isFinal = STATUS_FINAL.includes(idTransactionStatus);

    const displayConfirmation =
        !ticketConfirmation &&
        ((idTransactionStatus === STATUS.PENDING && !executing) || idTransactionStatus === STATUS.SCHEDULED);

    const isTicketFromFlow =
        history.location.pathname?.includes("/ticket") && !locationFrom?.includes("/transactions/list");

    RequestReview(DeviceUtils.isMobileNative(), idActivity, idTransactionStatus, isTicketFromFlow);

    return (
        <div className={className || EMPTY_STR} id={Style.ID}>
            {isDesktop || hasMobileComponent ? (
                <React.Fragment>
                    <TransactionStatusSection />
                    <section>
                        <div>
                            <TransactionGeneralInformation />
                            {children}
                        </div>
                        <FormSignatures transaction={transaction} />
                        {!DeviceUtils.isMobileNative() && isFinal && (
                            <div className="buttonsTicketConfirmation">
                                <a onClick={() => printPage()}>
                                    <I18n id="forms.ticket.print" />
                                </a>
                            </div>
                        )}
                        {displayDownloadPDF && (
                            <div className="buttonsTicketConfirmation">
                                <a onClick={() => handleDownloadPDF()}>
                                    <I18n id="forms.ticket.printPDF" />
                                </a>
                            </div>
                        )}
                        {displayConfirmation && <Confirmation />}
                    </section>
                </React.Fragment>
            ) : (
                <Unavailable label="global.feature.resolution.unavailable" />
            )}
        </div>
    );

    function printPage() {
        window.print();
    }

    function TransactionStatusSection() {
        let i18nKey =
            idTransactionStatus === STATUS.PENDING && executing
                ? "transactionDelay.notification"
                : `forms.transaction.ticket.status.${idTransactionStatus}`;

        if (idActivity) {
            if (idActivity.startsWith("client.wm")) {
                switch (idTransactionStatus) {
                    case STATUS.PROCESSING:
                    case STATUS.ACCEPTED:
                        i18nKey += ".wm";
                        break;
                    default:
                        break;
                }
            } else if (idActivity.startsWith("comex")) {
                switch (idTransactionStatus) {
                    case STATUS.ACCEPTED:
                        i18nKey = `comex.transactionStatus.${idTransactionStatus}`;
                        break;
                    default:
                        break;
                }
            }
        }

        return (
            <section>
                <TicketPrintInformation />
                {idTransactionStatus && (
                    <figure>
                        <TransactionStatus idTransactionStatus={idTransactionStatus} />
                    </figure>
                )}
                <div>
                    <p className="text-lead">
                        <I18n id={i18nKey} />
                    </p>
                </div>
            </section>
        );
    }

    function TransactionGeneralInformation() {
        return (
            <React.Fragment>
                <div className="data-section">
                    <h3 className="data-title">
                        <I18n id="forms.transaction.ticket" />
                    </h3>
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="forms.transaction.ticket.idTransaction" />
                        </span>
                        <span className="data-name">{idTransaction}</span>
                    </div>
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="forms.transaction.ticket.date" />
                        </span>
                        <span className="data-date">{valueDateAsString}</span>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    function Confirmation() {
        const { handleModify, handleReject, handleSign, idUser } = props;
        const [showCancel, setShowCancel] = React.useState(false);
        const userHasSigned = signatures?.some((signature) => signature.idUser === idUser);

        return (
            <div className="above-the-fold">
                <Formik
                    render={(formikBag) => (
                        <Form>
                            <ConfirmationModal
                                show={showCancel}
                                handleAcept={() => handleReject(formikBag)}
                                handleCancel={() => setShowCancel(false)}
                                idLabel="transaction.ticket.cancelConfirmation"
                            />
                            <section>
                                <div className="transactionTicketConfirmation">
                                    <div className="buttonsTicketConfirmation">
                                        {idTransactionStatus === STATUS.PENDING && !userHasSigned && (
                                            <Button
                                                label="forms.signTransaction.link"
                                                bsStyle="primary"
                                                loading={formikBag.isSubmitting}
                                                onClick={handleSign}
                                            />
                                        )}
                                    </div>

                                    <div className="buttonsTicketConfirmation">
                                        <Button
                                            label="forms.cancelTransaction.link"
                                            bsStyle="primary"
                                            loading={formikBag.isSubmitting}
                                            onClick={() => setShowCancel(true)}
                                        />
                                    </div>

                                    {!DeviceUtils.isDisplayMobile() &&
                                        idTransactionStatus === STATUS.PENDING &&
                                        handleModify && (
                                            <div className="buttonsTicketConfirmation">
                                                <Button
                                                    label="forms.modifyTransaction.link"
                                                    bsStyle="outline"
                                                    loading={formikBag.isSubmitting}
                                                    onClick={handleModify}
                                                />
                                            </div>
                                        )}
                                </div>
                            </section>
                        </Form>
                    )}
                />
            </div>
        );
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

function mapStateToProps(store) {
    return {
        credentials: Compose(
            (array) => array.filter((item) => item !== "accessToken"),
            RemoveDuplicateItems,
            FlattenArray,
            (array) => array.map(({ credentials }) => credentials),
        )(SelectorsStoreForm.getCredentialsGroups(store)),
        idUser: SelectorsStoreSession.getUserId(store),
    };
}

export default HighOrder(WithRouter, HighOrder.Resizable, Connect(mapStateToProps))(Component);
