import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { MODE } from "~/constants/form";
import * as i18nUtils from "~/util/i18n";
import UtilLodash from "~/util/lodash";

import Box from "~/components/Box";
import RoundButton from "~/components/RoundButton";
import Select from "~/components/Select";

import Style from "./MultiSelect.rules.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {
        children: PropTypes.func.isRequired,
        deletable: PropTypes.bool,
        hidePlaceholder: PropTypes.string,
        labelKey: PropTypes.string,
        mode: PropTypes.oneOf([MODE.EDIT, MODE.VIEW]),
        name: PropTypes.string.isRequired,
        noResultsText: PropTypes.string,
        onDelete: PropTypes.func.isRequired,
        onSelect: PropTypes.func.isRequired,
        options: PropTypes.shape({
            ids: PropTypes.arrayOf(PropTypes.string),
            byId: PropTypes.objectOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
        }).isRequired,
        optionalCondition: PropTypes.func,
        placeholder: PropTypes.string,
        renderCloseButton: PropTypes.func,
        searchable: PropTypes.bool,
        valueKey: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
    },
    defaults: {
        deletable: true,
        hidePlaceholder: EMPTY_STR,
        labelKey: "label",
        mode: MODE.EDIT,
        noResultsText: "global.no.results",
        optionalCondition: null,
        placeholder: EMPTY_STR,
        renderCloseButton: null,
        searchable: true,
        valueKey: "value",
        values: [],
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    renderCloseButton = (option) => {
        const { onDelete, renderCloseButton } = this.props;

        if (renderCloseButton) {
            return renderCloseButton(option);
        }

        return <RoundButton image="trash" aria-label="Close" onClick={() => onDelete(option)} />;
    };

    render() {
        const {
            children,
            deletable,
            hidePlaceholder,
            label,
            mode,
            noResultsText,
            onDelete,
            onSelect,
            optionalCondition,
            options,
            placeholder,
            tooltip,
            values,
            ...props
        } = this.props;
        const i18nLabel = label && i18nUtils.get(label);

        if (mode === MODE.VIEW) {
            return (
                <div className="form-group">
                    <div className="form-group-text">
                        <span className="control-label">{label}</span>
                    </div>
                    <ul className="form-group-control-list list">
                        {values.length ? (
                            values.map((id) => {
                                const { valueKey, labelKey } = this.props;
                                const option = options.byId[id];

                                return (
                                    <li key={option[valueKey]} className="list-item">
                                        {option[labelKey]}
                                    </li>
                                );
                            })
                        ) : (
                            <li className="list-item">{i18nUtils.get("administration.members.empty")}</li>
                        )}
                    </ul>
                </div>
            );
        }

        const { byId, ids } = options;

        const availableOptions =
            ids && ids.filter((id) => !values.some((selectedOption) => id === selectedOption)).map((id) => byId[id]);

        let optionsToRender = availableOptions;

        if (optionalCondition) {
            optionsToRender = availableOptions.filter((option) => optionalCondition(option));
        }

        return (
            <Box className={CLASS}>
                <Select
                    onChange={onSelect}
                    options={optionsToRender}
                    noResultsText={i18nUtils.get(noResultsText)}
                    placeholder={hidePlaceholder ? EMPTY_STR : i18nUtils.get(placeholder)}
                    label={i18nLabel}
                    {...props}
                />
                {tooltip && (
                    <div className="tooltip">
                        <span className="tooltip-text"> {tooltip}</span>
                    </div>
                )}

                <Box className="mt-2">
                    {values.map((value, index) => {
                        const option = byId[value] || {};
                        return (
                            !UtilLodash.isEmpty(option) && (
                                <Box flex justify="between" key={value} num={index} className="list-item p-2">
                                    {children(option)}
                                    {deletable && this.renderCloseButton(option)}
                                </Box>
                            )
                        );
                    })}
                </Box>
            </Box>
        );
    }
}

export default Component;
