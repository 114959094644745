import PropTypes from "prop-types";

import { MODE } from "~/constants/form";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "comex.bondsAndGuarantees";

export const PATH = {
    REQUEST_BONDS_AND_GUARANTEES_PRE_REQUEST: "/comex.bondsAndGuarantees.requestBondsAndGuarantees.pre",
    REQUEST_BONDS_AND_GUARANTEES_PREVIEW_REQUEST: "/comex.bondsAndGuarantees.requestBondsAndGuarantees.preview",
    REQUEST_BONDS_AND_GUARANTEES_REQUEST: "/comex.bondsAndGuarantees.requestBondsAndGuarantees.send",

    MODIFY_BONDS_AND_GUARANTEES_PRE_REQUEST: "/comex.bondsAndGuarantees.modifyBondsAndGuarantees.pre",
    MODIFY_BONDS_AND_GUARANTEES_PREVIEW_REQUEST: "/comex.bondsAndGuarantees.modifyBondsAndGuarantees.preview",
    MODIFY_BONDS_AND_GUARANTEES_REQUEST: "/comex.bondsAndGuarantees.modifyBondsAndGuarantees.send",
};
export const TYPE = FactoryType(NAME, [
    // Request Bonds And Guarantees
    "REQUEST_BONDS_AND_GUARANTEES_PRE_REQUEST",
    "REQUEST_BONDS_AND_GUARANTEES_PRE_SUCCESS",
    "REQUEST_BONDS_AND_GUARANTEES_PRE_FAILURE",

    "REQUEST_BONDS_AND_GUARANTEES_PREVIEW_REQUEST",
    "REQUEST_BONDS_AND_GUARANTEES_PREVIEW_SUCCESS",
    "REQUEST_BONDS_AND_GUARANTEES_PREVIEW_FAILURE",

    "REQUEST_BONDS_AND_GUARANTEES_SEND_REQUEST",
    "REQUEST_BONDS_AND_GUARANTEES_SEND_SUCCESS",
    "REQUEST_BONDS_AND_GUARANTEES_SEND_FAILURE",

    // Modify Bonds And Guarantees
    "MODIFY_BONDS_AND_GUARANTEES_PRE_REQUEST",
    "MODIFY_BONDS_AND_GUARANTEES_PRE_SUCCESS",
    "MODIFY_BONDS_AND_GUARANTEES_PRE_FAILURE",

    "MODIFY_BONDS_AND_GUARANTEES_PREVIEW_REQUEST",
    "MODIFY_BONDS_AND_GUARANTEES_PREVIEW_SUCCESS",
    "MODIFY_BONDS_AND_GUARANTEES_PREVIEW_FAILURE",

    "MODIFY_BONDS_AND_GUARANTEES_SEND_REQUEST",
    "MODIFY_BONDS_AND_GUARANTEES_SEND_SUCCESS",
    "MODIFY_BONDS_AND_GUARANTEES_SEND_FAILURE",

    "COMEX_FORM_SEND_DATA_FAILURE",
    "DRAFT_LOADED",
    "RESTART_FLOW",
]);

export const PROP = {};

PROP.members = {};

PROP.types = {
    ...TypesRedux,
    fetching: PropTypes.bool,
    transaction: PropTypes.object,
    requestBondsAndGuarantees: PropTypes.object,
    mode: PropTypes.string,
};

PROP.defaults = {
    ...DefaultsRedux,
    fetching: false,
    transaction: {},
    mode: MODE.EDIT,
    prevMode: "",
    requestBondsAndGuarantees: {
        preData: {},
        formData: {},
        prevData: {},
    },
    modifyBondsAndGuarantees: {
        preData: {
            nextValidDate: "",
            availableOperationList: [],
            maxDaysToSchedule: 0,
            unavailableDays: [],
            currencyList: [],
        },
        formData: {},
        prevData: {},
    },
    draftLoaded: false,
};
