import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    wealthManagementFundsBuyPre: (props) => API.executeWithAccessToken(PATH.WM_FUNDS_BUY_PRE, props),
    wealthManagementFundsBuyPreview: (props) => API.executeWithAccessToken(PATH.WM_FUNDS_BUY_PREVIEW, props),
    wealthManagementFundsBuySend: (props) => API.executeWithAccessToken(PATH.WM_FUNDS_BUY_SEND, props),
};

export const SelectorsAction = {
    cancelTransactionCustom: (props) => FactoryAction(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, props),
    closeConfirmation: () => FactoryAction(TYPE.CLOSE_TRANSACTION_PREVIEW),
    paperSendFormRequest: (props) => FactoryAction(TYPE.SEND_FORM_PAPER_REQUEST, props),
    paperSendFormSuccess: (props) => FactoryAction(TYPE.SEND_FORM_PAPER_SUCCESS, props),
    previewCancelRequest: () => FactoryAction(TYPE.PREVIEW_CANCEL_REQUEST),
    previewPaperRequest: (props) => FactoryAction(TYPE.PREVIEW_PAPER_REQUEST, props),
    previewPaperSuccess: (props) => FactoryAction(TYPE.PREVIEW_PAPER_SUCCESS, props),
    readPaperRequest: (props) => FactoryAction(TYPE.READ_PAPER_REQUEST, props),
    readPaperSuccess: (props) => FactoryAction(TYPE.READ_PAPER_SUCCESS, props),
    readTransactionRequest: (props) => FactoryAction(TYPE.READ_TRANSACTION_REQUEST, props),
    readTransactionSuccess: (props) => FactoryAction(TYPE.READ_TRANSACTION_SUCCESS, props),
    resetForm: () => FactoryAction(TYPE.RESET_FORM),
    restartFlow: () => FactoryAction(TYPE.RESTART_FLOW),
    selectedIdPaper: (props) => FactoryAction(TYPE.SELECTED_ID_PAPER, props),
    signTransaction: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_REQUEST, props),
    signTransactionPreview: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_PREVIEW_REQUEST, props),
    signTransactionPreviewSuccess: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_PREVIEW_SUCCESS, props),
    taskFinished: () => FactoryAction(TYPE.TASK_FINISHED),
};

export const SelectorsStore = {
    getAmount: (store) => store[NAME].amount,
    getCurrency: (store) => store[NAME].currency,
    getDebitAccountAlias: (store) => store[NAME].debitAccountAlias,
    getDebitAccounts: (store) => store[NAME].debitAccounts,
    getDisclaimer: (store) => store[NAME].disclaimer,
    getIdDebitAccount: (store) => store[NAME].idDebitAccount,
    getIdPaper: (store) => store[NAME].idPaper,
    getIdPaperSelected: (store) => store[NAME].idPaperSelected,
    getIsSubmitting: (store) => store[NAME].isSubmitting,
    getlimitDate: (store) => store[NAME].limitDate,
    getMode: (store) => store[NAME].mode,
    getOrderCompleted: (store) => store[NAME].orderCompleted,
    getPaper: (store) => store[NAME].paper,
    getPaperName: (store) => store[NAME].paperName,
    getPrevMode: (store) => store[NAME].prevMode,
    getTimezoneOffset: (store) => store[NAME].timezoneOffset,
    getTransaction: (store) => store[NAME].transaction,
    isFetching: (store) => store[NAME].isFetching,
};
