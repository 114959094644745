import { FactoryAction } from "~/util/redux";
import { getScheme as GetScheme } from "~/util/store/administration";

import { NAME } from "./_consts";

export const SelectorsAction = (TYPE) => ({
    loadChannelsRequest: (props) => FactoryAction(TYPE.LOAD_CHANNELS_REQUEST, props), // id
    loadChannelsFailure: () => FactoryAction(TYPE.LOAD_CHANNELS_FAILURE),
    loadChannelsSuccess: (props) => FactoryAction(TYPE.LOAD_CHANNELS_SUCCESS, props), // data
    updateChannelsPreview: (props) => FactoryAction(TYPE.UPDATE_CHANNELS_PREVIEW, props), // data setSubmitting
    updateChannelsPreviewSuccess: (props) => FactoryAction(TYPE.UPDATE_CHANNELS_PREVIEW_SUCCESS, props), // caps credentialGroups idTransaction idActivity
    updateChannelsRequest: (props) => FactoryAction(TYPE.UPDATE_CHANNELS_REQUEST, props), // data formikBag
    updateChannelsRequestSuccess: () => FactoryAction(TYPE.UPDATE_CHANNELS_REQUEST_SUCCESS),

    loadChannelsTicketRequest: (props) => FactoryAction(TYPE.LOAD_CHANNELS_TICKET_REQUEST, props), // idTransaction
    loadChannelsTicketFailure: () => FactoryAction(TYPE.LOAD_CHANNELS_TICKET_FAILURE),
    loadChannelsTicketSuccess: () => FactoryAction(TYPE.LOAD_CHANNELS_TICKET_SUCCESS),
});

export const SelectorsStore = {
    getCaps: (store) => GetScheme(store)[NAME]?.caps,
    getCurrencies: (store) => GetScheme(store)[NAME]?.currencies,
    getTopAmount: (store) => GetScheme(store)[NAME]?.topAmount,
    getNonRemovableChannels: (store) => GetScheme(store)[NAME]?.nonRemovableChannels,
    getEnabledChannels: (store) => GetScheme(store)[NAME]?.enabledChannels,
    getEnabledChannelsFrequencies: (store) => GetScheme(store)[NAME]?.enabledChannelsFrequencies,
    isFetching: (store) => (store ? GetScheme(store)[NAME]?.fetching : false),
    getIdTransaction: (store) => GetScheme(store)[NAME]?.idTransaction,
    getIdActivity: (store) => GetScheme(store)[NAME]?.idActivity,
    getCredentialGroups: (store) => GetScheme(store)[NAME]?.credentialGroups,
    isFetchingTicket: (store) => GetScheme(store)[NAME]?.fetchingTicket,
};
