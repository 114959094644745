import React from "react";

import { ResponsiveLine } from "@nivo/line";
import ClassNames from "classnames";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SPACE_STR } from "~/constants";
import {
    SelectorsAction as SelectorsActionPreferentialTradingPrice,
    SelectorsStore as SelectorsStorePreferentialTradingPrice,
} from "~/store/preferentialTradingPrice";
import * as UtilDate from "~/util/date";
import { generateId as GenID } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";

import AnimWait from "~/components/AnimWait";

import Style from "./HistoricalRatesChart.scss";

export const { NAME, CLASS, COLOR } = Style;

export const PROP = {
    types: {
        historicalRates: PropTypes.array,
        formData: PropTypes.array,
    },
    defaults: {},
};

export function Component(props) {
    const { dispatch, historicalRates, formData, isFetching } = props;
    const { selectedCurrency } = formData;
    const { currency1, currency2, label, label2 } = selectedCurrency;
    const currencyPairLabel = `${label2}-${label}`;
    const chartData = {};

    const filterByPeriod = {
        ONE_WEEK: 7,
        ONE_MONTH: 30,
        ONE_YEAR: 365,
        FIVE_YEARS: 1825,
    };

    const [selectedPeriod, setSelectedPeriod] = React.useState(Object.keys(filterByPeriod)[0]);
    React.useEffect(() => {
        const days = filterByPeriod[selectedPeriod];
        dispatch(SelectorsActionPreferentialTradingPrice.historicalRatesRequest({ days }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, selectedPeriod]);

    if (!isFetching && historicalRates && historicalRates.length) {
        const index = historicalRates.findIndex((currencyPair) => {
            return (
                currencyPair.currencyOne === parseInt(currency1, 10) &&
                currencyPair.currencyTwo === parseInt(currency2, 10)
            );
        });
        const { dailyQuotes } = historicalRates[index];
        chartData.id = currencyPairLabel;
        chartData.data = [];

        dailyQuotes.forEach((quote) => {
            const date = UtilDate.toDate(quote.date);
            const mmYyyy = UtilDate.applyFormat(date, "yyyy-MM-dd");
            chartData.data.push({ x: mmYyyy, y: quote.quotation.toFixed(2) });
        });
        chartData.data.reverse();
    }

    return (
        <section className={CLASS}>
            <div className="chart-header">
                <h3>
                    {UtilsI18n.get("preferentialTradingPrice.historicalChart.title")} {currencyPairLabel}
                </h3>
                <PeriodSelector />
            </div>
            {isFetching && <AnimWait />}
            {!isFetching && (
                <ResponsiveLine
                    data={[chartData]}
                    margin={{ top: 10, right: 10, bottom: 50, left: 35 }}
                    enableSlices="x"
                    xScale={{ type: "time", format: "%Y-%m-%d" }}
                    xFormat="time:%Y-%m-%d"
                    yScale={{ type: "linear", min: "auto", max: "auto", stacked: true, reverse: false }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        format: (value) => {
                            const gmtedValue = UtilDate.forceGMT(value);
                            const formattedValue = UtilDate.i18nDate(gmtedValue, xAxisFormatter())
                                .split(SPACE_STR)
                                .map((date) => date.charAt(0).toUpperCase() + date.slice(1))
                                .join(SPACE_STR);

                            return formattedValue;
                        },
                        tickValues: 4,
                    }}
                    axisLeft={{
                        tickValues: 5,
                        tickSize: 0,
                    }}
                    colors={COLOR}
                    sliceTooltip={({ slice }) => {
                        const { data } = slice.points[0];
                        const gmtedValue = UtilDate.forceGMT(data.x);
                        const day = UtilDate.applyFormat(gmtedValue, "dd.MM.yyyy");
                        return (
                            <div className="chartTooltip">
                                <strong>{day}</strong>
                                <div>
                                    <span>{label}</span>
                                    <span>{data.y}</span>
                                </div>
                            </div>
                        );
                    }}
                    enablePoints={false}
                    enableCrosshair
                />
            )}
        </section>
    );

    function xAxisFormatter() {
        switch (true) {
            case filterByPeriod[selectedPeriod] === filterByPeriod.ONE_WEEK:
            case filterByPeriod[selectedPeriod] === filterByPeriod.ONE_MONTH:
                return "dd MMM";
            case filterByPeriod[selectedPeriod] === filterByPeriod.ONE_YEAR:
                return "MMM";
            default:
                return "yyyy";
        }
    }

    function PeriodSelector() {
        return (
            <div className="period-selector">
                {Object.keys(filterByPeriod).map((period) => (
                    <span
                        key={GenID()}
                        className={ClassNames({ selected: period === selectedPeriod })}
                        onClick={() => {
                            setSelectedPeriod(period);
                        }}>
                        {UtilsI18n.get(`preferentialTradingPrice.historicalChart.period.${period}`)}
                    </span>
                ))}
            </div>
        );
    }
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    historicalRates: SelectorsStorePreferentialTradingPrice.getHistoricalRates(store),
    isFetching: SelectorsStorePreferentialTradingPrice.getFetchingHistoricalRates(store),
    formData: SelectorsStorePreferentialTradingPrice.formData(store),
});

export default Connect(mapStateToProps)(Component);
