import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { EMPTY_STR, QR_MODO_COUPON_ENABLED, QR_MODO_TYPE_DISCOUNT, SCOPE, ZERO } from "~/constants";
import Container from "~/containers/Internal/Form/Cards";
import { SelectorsAction as SelectorsActionAccount, SelectorsStore as SelectorsStoreAccount } from "~/store/account";
import { SelectorsStore as SelectorsStoreBiometric, TYPE_VERIFY_BIOMETRIC } from "~/store/biometric";
import {
    SelectorsAction as SelectorsActionServicePayment,
    SelectorsStore as SelectorsStorePayment,
} from "~/store/servicePayments";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as UtilsI18n from "~/util/i18n";

import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Token from "~/components/Token/Token";

import Style from "./OtpRequest.rules.scss";
import Coupon from "./_components/Coupon";

export const { NAME } = Style;

export const PROP = {
    types: {
        accounts: PropTypes.shape({
            balance: PropTypes.number,
            currency: PropTypes.string,
            idProduct: PropTypes.string,
            isSubmitting: PropTypes.bool,
            number: PropTypes.string,
            permissions: PropTypes.shape({
                preferentialTradingPrice: PropTypes.bool,
                requestCheckbook: PropTypes.bool,
                transferForeign: PropTypes.bool,
                transferInternal: PropTypes.bool,
                transferLocal: PropTypes.bool,
                transferThirdParties: PropTypes.bool,
            }),
            productAlias: PropTypes.string,
            productType: PropTypes.string,
            productTypeLabel: PropTypes.string,
        }),
        dispatch: PropTypes.func.isRequired,
    },
    defaults: {
        accounts: null,
        isSubmitting: false,
    },
};

export function Component(props) {
    const {
        accounts,
        askPassword,
        dispatch,
        fetching,
        isSubmitting,
        qrData,
        resetForm,
        setSubmitting,
        statusVerifyBiometric,
        user,
    } = props;

    const [biometricAlreadyRequested, setBiometricAlreadyRequested] = React.useState(false);

    React.useLayoutEffect(() => {
        dispatch(SelectorsActionServicePayment.handleFetching({ fetching: false }));
        dispatch(SelectorsActionAccount.listAccounts({ isFromQRModoOtpRequest: true }));
    }, [dispatch]);

    React.useEffect(() => {
        if (
            !biometricAlreadyRequested &&
            accounts &&
            accounts.length > ZERO &&
            user.needsBiometric &&
            qrData.qrCurrentInstallment !== QR_MODO_COUPON_ENABLED
        ) {
            setBiometricAlreadyRequested(true);

            const formikBag = { resetForm, setSubmitting };
            const otp = EMPTY_STR;

            dispatch(SelectorsActionServicePayment.launchSdkModo({ formikBag, otp }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accounts, biometricAlreadyRequested]);

    if (user.needsBiometric) {
        if (statusVerifyBiometric === TYPE_VERIFY_BIOMETRIC.CANCEL) {
            dispatch(SelectorsActionServicePayment.handleFetching({ fetching: true }));
            dispatch(GoBack());
        }

        if (statusVerifyBiometric === TYPE_VERIFY_BIOMETRIC.SUCCESS) {
            dispatch(SelectorsActionServicePayment.handleFetching({ fetching: true }));
        }
    }

    const isLoading = fetching;
    const isEmpty = !fetching && !accounts.length;

    return (
        <Container
            className={Style.CLASS}
            head-title={UtilsI18n.get("servicePayment.qrModo.transaction.title")}
            name={NAME}
            scopeToShowNotification={SCOPE.QR_MODO}
            wait={isLoading && !askPassword}>
            {!isLoading && (
                <Form id={Style.CLASS}>
                    <React.Fragment>
                        {isLoading && <I18n id="global.loading" />}
                        {isEmpty && <I18n id="accounts.list.empty" />}

                        {!isEmpty && (
                            <React.Fragment>
                                {qrData.qrEnabled &&
                                    qrData.qrTypeDiscount !== EMPTY_STR &&
                                    qrData.qrTypeDiscount !== QR_MODO_TYPE_DISCOUNT.NONE && (
                                        <Coupon data={qrData} buttonEnabled={false} />
                                    )}
                                <Token labelButton="global.send" isSubmitting={isSubmitting} />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                </Form>
            )}
        </Container>
    );
}

Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

const mapStateToProps = (store) => ({
    accounts: SelectorsStoreAccount.getAccounts(store),
    askPassword: SelectorsStoreBiometric.askPassword(store),
    fetching:
        SelectorsStorePayment.getFetching(store) ||
        SelectorsStoreAccount.getFetching(store) ||
        SelectorsStoreBiometric.fetching(store),
    qrData: SelectorsStoreSession.getQrData(store),
    statusVerifyBiometric: SelectorsStoreBiometric.statusVerifyBiometric(store),
    user: SelectorsStoreSession.getUser(store),
});

export default HighOrder(
    Connect(mapStateToProps),
    WithRouter,
    WithFormik({
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        validationSchema: (props) => {
            const { user } = props;

            return Yup.object().shape({
                otp: user.needsBiometric
                    ? Yup.string().nullable()
                    : Yup.string().required(UtilsI18n.get("form.credential.otp.required")),
            });
        },
        handleSubmit: (props, formikBag) => {
            const { dispatch } = formikBag.props;
            const { otp } = props;

            dispatch(SelectorsActionServicePayment.handleFetching({ fetching: true }));
            dispatch(SelectorsActionServicePayment.launchSdkModo({ formikBag, otp }));
        },
    }),
)(Component);
