import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    listCities: (props) => API.executeWithAccessToken(PATH.CONNECTED_PARTIES_LIST_CITIES, props),
    listDepartments: (props) => API.executeWithAccessToken(PATH.CONNECTED_PARTIES_LIST_DEPARTMENTS, props),
    registerBantotalPre: (props) => API.executeWithAccessToken(PATH.CONNECTED_PARTIES_PRE, props),
    submitForm: (props) => API.executeWithAccessToken(PATH.CONNECTED_PARTIES_SUBMIT_FORM, props),
};

export const SelectorsAction = {
    changeAddressesCityFailure: () => FactoryAction(TYPE.CONNECTEDPARTIES_LIST_CITIES_FAILURE),
    changeAddressesCityRequest: (props) => FactoryAction(TYPE.CONNECTEDPARTIES_LIST_CITIES_REQUEST, props),
    changeAddressesCitySuccess: (props) => FactoryAction(TYPE.CONNECTEDPARTIES_LIST_CITIES_SUCCESS, props),
    listDepartments: (props) => FactoryAction(TYPE.CONNECTEDPARTIES_LIST_DEPARTMENTS_REQUEST, props),
    listDepartmentsFailure: () => FactoryAction(TYPE.CONNECTEDPARTIES_LIST_DEPARTMENTS_FAILURE),
    listDepartmentsOfBirth: (props) => FactoryAction(TYPE.CONNECTEDPARTIES_LIST_DEPARTMENTS_OF_BIRTH_REQUEST, props),
    listDepartmentsOfBirthFailure: () => FactoryAction(TYPE.CONNECTEDPARTIES_LIST_DEPARTMENTS_OF_BIRTH_FAILURE),
    listDepartmentsOfBirthSuccess: (props) =>
        FactoryAction(TYPE.CONNECTEDPARTIES_LIST_DEPARTMENTS_OF_BIRTH_SUCCESS, props),
    listDepartmentsSuccess: (props) => FactoryAction(TYPE.CONNECTEDPARTIES_LIST_DEPARTMENTS_SUCCESS, props),
    readTransactionFailure: (props) => FactoryAction(TYPE.READ_TRANSACTION_FAILURE, props),
    readTransactionRequest: (props) => FactoryAction(TYPE.READ_TRANSACTION_REQUEST, props),
    readTransactionSuccess: (props) => FactoryAction(TYPE.READ_TRANSACTION_SUCCESS, props),
    registerBantotalPre: (props) => FactoryAction(TYPE.CONNECTEDPARTIES_PRE_REQUEST, props),
    registerBantotalPreFailure: () => FactoryAction(TYPE.CONNECTEDPARTIES_PRE_FAILURE),
    registerBantotalPreSuccess: (props) => FactoryAction(TYPE.CONNECTEDPARTIES_PRE_SUCCESS, props),
    setRegisteredBantotal: (props) => FactoryAction(TYPE.SET_REGISTERED_BANTOTAL, props),
    submitFormConnectedParties: (props) => FactoryAction(TYPE.CONNECTEDPARTIES_SUBMIT_FORM_REQUEST, props),
    submitFormConnectedPartiesFailure: () => FactoryAction(TYPE.CONNECTEDPARTIES_SUBMIT_FORM_FAILURE),
    submitFormConnectedPartiesSuccess: () => FactoryAction(TYPE.CONNECTEDPARTIES_SUBMIT_FORM_SUCCESS),
};

export const SelectorsStore = {
    getCMBOnly: (store) => store[NAME].cmbOnly,
    getChangeProcess: (store) => store[NAME].changeProcess,
    getChangeTransactionData: (store) => store[NAME].changeTransactionData,
    getCityList: (store) => store[NAME].cityList,
    getCorrespondense: (store) => store[NAME].correspondense,
    getCountryList: (store) => store[NAME].countryList,
    getDepartmentsOfBirthList: (store) => store[NAME].departmentsOfBirth,
    getDocuments: (store) => store[NAME].documents,
    getErrors: (store) => store[NAME].errors,
    getFetching: (store) => store[NAME].fetching,
    getFetchingForm: (store) => store[NAME].fetchingForm,
    getInfoUser: (store) => store[NAME].infoUser,
    getLegal: (store) => store[NAME].legal,
    getLoadData: (store) => store[NAME].loadData,
    getMaritalStatusList: (store) => store[NAME].maritalStatusList,
    getProductGroupIds: (store) => store[NAME].productGroupIds,
    getProfessions: (store) => store[NAME].professions,
    getTownList: (store) => store[NAME].townList,
    getTransaction: (store) => store[NAME].transaction,
    getWorkActivities: (store) => store[NAME].workActivities,
    getWorkRoles: (store) => store[NAME].workRoles,
    getWorkSituations: (store) => store[NAME].workSituations,
    isRegisteredBantotal: (store) => store[NAME].regiteredBantotal,
};
