import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";

import {
    EMPTY_STR,
    FIELD_SUBTYPE,
    ID_FIELD,
    ID_FORM,
    REGEX_IGNORE_ACCENTS,
    TRANSFER_FOREIGN_EXPENDITURE_BY_BEN,
} from "~/constants";
import { MODE, ID_FORM as ID_FORM_OTHER } from "~/constants/form";
import {
    SelectorsAction as SelectorsActionAutomaticDebits,
    SelectorsStore as SelectorsStoreAutomaticDebits,
} from "~/store/automaticDebits";
import { SelectorsStore as SelectorsStoreBackoffice } from "~/store/backoffice";
import { SelectorsAction as SelectorsActionForm, SelectorsStore as SelectorsStoreForm } from "~/store/form";
import {
    SelectorsAction as SelectorsActionloansRequest,
    SelectorsStore as SelectorsStoreLoansRequest,
} from "~/store/loanRequest";
import { SelectorsAction as SelectorsActionSession, SelectorsStore as SelectorsStoreSession } from "~/store/session";
import {
    SelectorsAction as SelectorsActionTransactionLines,
    SelectorsStore as SelectorsStoreTransactionLines,
} from "~/store/transactionLines";
import {
    SelectorsStore as SelectorsStoreTransfer,
    SelectorsAction as SelectorActionsTransfer,
} from "~/store/transfers";
import * as UtilsConfig from "~/util/config";
import * as UtilsI18n from "~/util/i18n";
import UtilsLodash from "~/util/lodash";

import HighOrder from "~/components/HighOrder";
import Select from "~/components/Select";
import { PROP as PropEnvironments } from "~/pages/_components/Environments";

import CheckboxGroup from "~/pages/forms/_components/_fields/_commons/CheckboxGroup";
import RadioButtonGroup from "~/pages/forms/_components/_fields/_commons/RadioButtonGroup";
import formField from "~/pages/forms/_components/_fields/_commons/formField";

export const NAME = "Selector";

export const PROP = {
    types: {
        activeEnvironment: PropEnvironments.activeEnvironment,
        defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        editing: PropTypes.oneOfType([PropTypes.any]),
        optionList: PropTypes.oneOfType([PropTypes.array]).isRequired,
        placeholder: PropTypes.string,
        renderAs: PropTypes.string.isRequired,
        setValue: PropTypes.func.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    },
    defaults: {
        activeEnvironment: null,
        defaultValue: EMPTY_STR,
        editing: null,
        placeholder: EMPTY_STR,
        value: EMPTY_STR,
    },
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        comboListDestinationState: null,
        comboListTermState: null,
        dependeeFields: null,
        idElementState: EMPTY_STR,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            changeValueLoanType,
            comboListDestination,
            comboListTerm,
            dependeeFields,
            dependencies,
            dispatch,
            editing,
            newCurrency,
            prevMode,
            setValue,
            subType,
            value,
        } = nextProps;
        const { dependeeFields: dependeeFieldsState } = prevState;

        const dependeeValue = dependencies && dependeeFields && dependeeFields[dependencies[0]];
        const dependeeValueState = dependencies && dependeeFieldsState && dependeeFieldsState[dependencies[0]];

        const changeValue =
            (!dependeeValueState && dependeeValue) ||
            (dependeeValueState &&
                dependeeValue &&
                dependeeValue.value &&
                (typeof dependeeValue.value === "object"
                    ? !UtilsLodash.isEqual(dependeeValueState.value, dependeeValue.value)
                    : dependeeValueState.value !== dependeeValue.value));

        if (newCurrency && subType === FIELD_SUBTYPE.TRANSFER_THIRD_PARTIES) {
            setValue([newCurrency]);

            dispatch(SelectorActionsTransfer.updateCurrency({ currency: null }));

            return null;
        }

        if ((editing && changeValue) || changeValueLoanType) {
            switch (subType) {
                case FIELD_SUBTYPE.ALIAS_DEPENDANT_ACCOUNT_TYPE:
                    if (prevMode !== MODE.PREVIEW) {
                        setValue([dependeeValue.value.accountType]);
                    }

                    break;
                case FIELD_SUBTYPE.LOCAL_BANKS:
                    if (prevMode !== MODE.PREVIEW) {
                        setValue([dependeeValue.value.bcuCode]);
                    }

                    break;
                case FIELD_SUBTYPE.PAYMENT_PURPOSE:
                    return {
                        dependeeFields,
                    };
                case FIELD_SUBTYPE.PURCHASE_NOTIFICATION:
                    setValue(dependeeValue.purchaseNotificationActive);

                    break;
                case FIELD_SUBTYPE.REQUEST_LOAN_TYPE_DEPENDENT:
                    if (value.length !== 0) {
                        // Checks not empty to avoid infinite loop
                        setValue([]);
                    }

                    return {
                        comboListDestinationState: comboListDestination,
                        comboListTermState: comboListTerm,
                    };
                case FIELD_SUBTYPE.TRANSFER_THIRD_PARTIES:
                    if (prevMode !== MODE.PREVIEW) {
                        setValue([dependeeValue.value.currency]);
                    }

                    break;
                default:
                    break;
            }

            return {
                dependeeFields,
            };
        }

        return null;
    }

    componentDidMount() {
        const { activeEnvironment, defaultValue, dispatch, isFromBackoffice, mode, setValue, subType, value } =
            this.props;

        if (subType === FIELD_SUBTYPE.LIST_COMPANIES && value !== EMPTY_STR && mode === MODE.EDIT) {
            //  If value come from store, update form to load agreements
            dispatch(SelectorsActionAutomaticDebits.listAgreementsRequest({ idCompany: value[0] }));
        }

        if (subType === FIELD_SUBTYPE.ENVIRONMENT_TYPE) {
            if (isFromBackoffice) {
                const { environmentData } = this.props;

                setValue([environmentData?.idEnvironment]);
            } else if (mode === MODE.EDIT) {
                setValue([activeEnvironment.id]);
            } else {
                setValue([value]);
            }
        } else if (defaultValue !== null && !value) {
            setValue([defaultValue]);
        } else if (!value) {
            setValue([]);
        } else if (!Array.isArray(value)) {
            setValue([value]);
        }
    }

    componentWillUnmount() {
        const { idField, setValue } = this.props;
        // clear checkboxExpress field when not visible
        if (idField === ID_FIELD.CHECKBOX_EXPRESS) {
            setValue([EMPTY_STR]);
        }
    }

    handleChange = (newValue) => {
        if (newValue) {
            const {
                changeValueLoanType,
                dispatch,
                form: { resetForm },
                history,
                idField,
                idForm,
                renderAs,
                setValue,
                subType,
                value,
            } = this.props;
            const { id } = newValue;

            let selectedValues;

            if (renderAs === "combo" && newValue && !(subType === FIELD_SUBTYPE.COMPANY_DEPENDENT)) {
                selectedValues = [newValue.id];
            } else if (renderAs === "radio") {
                selectedValues = [newValue];
            } else {
                selectedValues = newValue;
            }

            if (subType === FIELD_SUBTYPE.MULTILINE_PAYMENT) {
                const { idElementState } = this.state;

                if (id !== idElementState) {
                    dispatch(SelectorsActionForm.dependeeChanged({ idField, value: newValue }));

                    this.setState((prevState) => ({
                        ...prevState,
                        idElementState: id,
                    }));
                }

                dispatch(SelectorsActionTransactionLines.setCurrency({ currency: id }));
            } else if (subType === FIELD_SUBTYPE.REQUEST_LOAN_TYPE && value && value[0] !== id) {
                dispatch(SelectorsActionloansRequest.changeValue());
                dispatch(SelectorsActionloansRequest.listRequest({ idLoan: id }));
            } else if (subType === FIELD_SUBTYPE.ENVIRONMENT_TYPE && value && value[0].toString() !== id) {
                dispatch(
                    SelectorsActionSession.changeEnvironment({
                        externalPayment: true,
                        formikBag: null,
                        idEnvironment: id,
                        payload: history,
                        rememberEnvironment: false,
                        shouldNavigate: false,
                    }),
                );
            } else if (changeValueLoanType) {
                dispatch(SelectorsActionloansRequest.changeValueSuccess());
            } else if (subType === FIELD_SUBTYPE.LIST_COMPANIES) {
                resetForm({ values: null }, false);

                dispatch(SelectorsActionAutomaticDebits.listAgreementsRequest({ idCompany: newValue.id }));
            } else if (subType === FIELD_SUBTYPE.TRANSFER_FOREIGN && newValue === TRANSFER_FOREIGN_EXPENDITURE_BY_BEN) {
                const {
                    form: { setValues, values },
                } = this.props;

                setValues(
                    {
                        ...values,
                        debitCommissionsCheckbox: [],
                        idExpensesAccount: values.debitAccount,
                    },
                    false,
                );
            } else if (
                idField === "debitCommissionsCheckbox" &&
                idForm === ID_FORM.TRANSFER_FOREIGN_FORM &&
                newValue.length < 1
            ) {
                const {
                    form: { setValues, values },
                } = this.props;

                const newValues = values;

                delete newValues.idExpensesAccount;

                setValues(values, false);
            }

            setValue(selectedValues);
        }
    };

    getFieldIndex = (fieldName) => {
        const { metadata } = this.props;
        const { fieldList } = metadata;

        let fieldIndex = -1;

        fieldList.forEach((field, index) => {
            if (field.idField === fieldName) {
                fieldIndex = index;
            }
        });

        return fieldIndex;
    };

    customFilter = (option, searchText) => {
        const optionLowerCase = option.label.toLowerCase();
        const text = searchText.toLowerCase();

        if (
            optionLowerCase.normalize("NFD").replace(REGEX_IGNORE_ACCENTS, EMPTY_STR).includes(text) &&
            optionLowerCase.charAt(0).includes(text.charAt(0))
        ) {
            return true;
        }

        return false;
    };

    viewAsCheck() {
        const { optionList, value } = this.props;
        const checkValue = value || [];

        return <CheckboxGroup options={optionList} values={checkValue} onChange={this.handleChange} mode="view" />;
    }

    renderAsCheck() {
        const { optionList, value } = this.props;
        const checkValue = value || [];

        return <CheckboxGroup options={optionList} values={checkValue} onChange={this.handleChange} />;
    }

    renderAsRadio() {
        const { optionList, value, idForm, hasTransactionLines } = this.props;
        const radioValue = value ? value[0] : EMPTY_STR;

        // show confirmation modal when chaging option in payments flow
        const showConfirmationModal =
            (idForm === ID_FORM_OTHER.SALARY_PAYMENT || idForm === ID_FORM_OTHER.SUPPLIERS_PAYMENT) &&
            hasTransactionLines;

        return (
            <RadioButtonGroup
                showConfirmationModal={showConfirmationModal}
                value={radioValue}
                options={optionList}
                onChange={this.handleChange}
            />
        );
    }

    renderAsCombo() {
        const { optionList, value, placeholder, setValue, subType, idField, idForm } = this.props;
        let comboValue = value || EMPTY_STR;

        comboValue = Array.isArray(comboValue) ? comboValue[0] : comboValue;
        comboValue = comboValue === EMPTY_STR ? setValue([]) : comboValue;
        const classEmpty = comboValue === EMPTY_STR ? "empty" : EMPTY_STR;
        let showPermissionDisclaimer = false;
        let optList = optionList;

        if (subType === FIELD_SUBTYPE.ENVIRONMENT_TYPE) {
            const { activeEnvironment } = this.props;
            const { permissions } = activeEnvironment;
            const { payBevsa, paySistarbanc } = permissions;

            if ((idForm === ID_FORM.PAY_SISTARBANC && !paySistarbanc) || (idForm === ID_FORM.PAY_BEVSA && !payBevsa)) {
                showPermissionDisclaimer = true;
            }
        }

        if (subType === FIELD_SUBTYPE.PAYMENT_PURPOSE) {
            const { dependencies, form } = this.props;
            const { values } = form;

            const dependencyField = dependencies[0];
            const { currency } = values[dependencyField];

            const paymentPurposesList = UtilsConfig.getArray(`transfersForeign.paymentPurposes.${currency}`);

            if (paymentPurposesList.length > 0) {
                optList = paymentPurposesList.map((option) => ({
                    id: option,
                    label: UtilsI18n.get(`client.transferForeign.purpose.${option}`),
                }));

                if (value.length === 0) {
                    comboValue = optList[0] ? optList[0].id : EMPTY_STR;

                    setValue([comboValue]);
                }
            }
        }

        if (subType === FIELD_SUBTYPE.REQUEST_LOAN_TYPE_DEPENDENT) {
            const { comboListTermState, comboListDestinationState } = this.state;
            const { comboListTerm, comboListDestination } = this.props;

            if (comboListDestinationState === null || comboListTermState === null) {
                if (idField === ID_FIELD.REQUEST_LOAN_TERM && comboListTerm) {
                    optList = comboListTerm;
                }

                if (idField === ID_FIELD.REQUEST_LOAN_DESTINATION && comboListDestination) {
                    optList = comboListDestination;
                }
            } else {
                if (idField === ID_FIELD.REQUEST_LOAN_TERM && comboListTermState) {
                    optList = comboListTermState;
                }

                if (idField === ID_FIELD.REQUEST_LOAN_DESTINATION && comboListDestinationState) {
                    optList = comboListDestinationState;
                }
            }
        }
        if (subType === FIELD_SUBTYPE.COMPANY_DEPENDENT && idField === ID_FIELD.ID_AGREEMENT) {
            const { listAgreementsState } = this.state;

            optList = listAgreementsState;
        }

        return (
            <div>
                <div className="input-group">
                    <div style={{ flex: 1 }}>
                        <Select
                            className={`flex-container slideFromBottom ${classEmpty}`}
                            clearable={false}
                            filterOption={this.customFilter}
                            labelKey="label"
                            onChange={this.handleChange}
                            options={optList}
                            placeholder={placeholder}
                            value={comboValue}
                            valueKey="id"
                        />
                    </div>
                </div>
                {showPermissionDisclaimer && (
                    <div className="disclaimer-container">
                        <div id="disclaimer-text">{UtilsI18n.get("externalPayment.environment.disclaimerText")}</div>
                    </div>
                )}
            </div>
        );
    }

    renderEditMode() {
        const { renderAs } = this.props;

        let render;

        if (renderAs === "combo") {
            return this.renderAsCombo();
        }

        if (renderAs === "check") {
            return this.renderAsCheck();
        }

        if (renderAs === "radio") {
            return this.renderAsRadio();
        }

        return render;
    }

    renderViewMode() {
        const { isFromBackoffice, optionList, renderAs, subType, value } = this.props;

        if (subType === FIELD_SUBTYPE.EDIT_ONLY) {
            return null;
        }

        if (renderAs === "check") {
            return this.viewAsCheck();
        }

        // value is the option selected, we treat it as a string to compare
        // eslint-disable-next-line eqeqeq
        const options = optionList ? optionList.filter((option) => value.toString() == option.id) : [];

        if (subType === FIELD_SUBTYPE.PAYMENT_PURPOSE) {
            value.map((option) =>
                options.push({ id: option, label: UtilsI18n.get(`client.transferForeign.purpose.${option}`) }),
            );
        } else if (subType === FIELD_SUBTYPE.ENVIRONMENT_TYPE && isFromBackoffice) {
            const { environmentData } = this.props;

            if (environmentData) {
                options.push({ id: environmentData.idEnvironment, label: environmentData.name });
            }
        }

        if (options.length === 0) {
            return null;
        }

        return (
            <ul>
                {options.map((option) => (
                    <li key={option.id}>{option.label}</li>
                ))}
            </ul>
        );
    }

    render() {
        const { editing } = this.props;

        if (editing) {
            return this.renderEditMode();
        }

        return this.renderViewMode();
    }
}

const mapStateToProps = (store) => ({
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
    changeValueLoanType: SelectorsStoreLoansRequest.getChangetValue(store),
    comboListDestination: SelectorsStoreLoansRequest.getListDestination(store),
    comboListTerm: SelectorsStoreLoansRequest.getListTerm(store),
    environmentData: SelectorsStoreBackoffice.getEnvironmentData(store),
    hasTransactionLines: SelectorsStoreTransactionLines.getTransactionLines(store).length > 0,
    listAgreements: SelectorsStoreAutomaticDebits.getListAgreements(store),
    metadata: SelectorsStoreForm.getMetadata(store),
    newCurrency: SelectorsStoreTransfer.getNewCurrency(store),
});

export default WithRouter(
    HighOrder(
        formField({
            formClass: "form-group--select",
            isEmptyValue: (value) => value.length === 0,
            isValidValue: (value) => Array.isArray(value),
        }),
        Connect(mapStateToProps),
    )(Component),
);
