import React from "react";

import Image from "~/components/Image";

export const NAME = "ChevromRight";

export const PROP = {
    types: {},
    defaults: {},
};

export function Component() {
    return (
        <div className="table-data table-data-icon">
            <Image src="chevromRight.svg" className="svg-icon svg-caret" />
        </div>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
