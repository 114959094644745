import React from "react";

import classNames from "classnames";
import { Field } from "formik";
import PropTypes from "prop-types";

import FieldError from "~/pages/_components/fields/FieldError";
import StepperField from "~/pages/_components/fields/formik/StepperField";

export const NAME = "StepperFieldGroup";

export const PROP = {
    types: {
        field: PropTypes.shape({
            name: PropTypes.string,
        }).isRequired,
        form: PropTypes.shape({
            errors: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)])),
            touched: PropTypes.objectOf(
                PropTypes.oneOfType([
                    PropTypes.arrayOf(PropTypes.bool),
                    PropTypes.bool,
                    PropTypes.objectOf(PropTypes.bool),
                ]),
            ),
        }).isRequired,
        handleChange: PropTypes.func,
        hideLabel: PropTypes.bool,
        idForm: PropTypes.string.isRequired,
        max: PropTypes.string.isRequired,
        min: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(PropTypes.string).isRequired,
        showFirstErrorWhenEquals: PropTypes.bool,
    },
    defaults: { handleChange: null, hideLabel: false, showFirstErrorWhenEquals: false },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    sameErrorForAll = (errors, field, optionsLength) =>
        optionsLength > 1 &&
        Object.keys(errors[field.name]).length === optionsLength &&
        Object.values(errors[field.name]).every((error) => Object.values(errors[field.name])[0] === error);

    renderErrors = (errors, field, options, showFirstErrorWhenEquals) => {
        if (showFirstErrorWhenEquals && this.sameErrorForAll(errors, field, options.length)) {
            return <FieldError error={Object.values(errors[field.name])[0]} />;
        }

        return Object.keys(errors[field.name]).map((error) => (
            <FieldError error={`${error} - ${errors[field.name][error]}`} key={error} />
        ));
    };

    render() {
        const {
            field,
            form,
            handleChange,
            hideLabel,
            idForm,
            max,
            min,
            options,
            showFirstErrorWhenEquals,
        } = this.props;
        const { touched, errors } = form;
        const hasError = touched[field.name] && errors[field.name];

        return (
            <React.Fragment>
                <div className="stepper-list">
                    {options.map((option) => (
                        <Field
                            className={classNames({
                                "has-error": hasError && Object.keys(errors[field.name]).includes(option),
                            })}
                            component={StepperField}
                            handleChange={handleChange}
                            hideLabel={hideLabel}
                            idForm={idForm}
                            key={option}
                            name={`${field.name}.${option}`}
                            max={max}
                            min={min}
                        />
                    ))}
                </div>
                <div
                    className={classNames({
                        "has-error": hasError,
                    })}>
                    {hasError && this.renderErrors(errors, field, options, showFirstErrorWhenEquals)}
                </div>
            </React.Fragment>
        );
    }
}

export default Component;
