import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE, VALIDATION_ERROR, FORM_FIELD_ERRORS } from "~/constants";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import {
    credentialsWithUnderscore as CredentialsWithUnderscore,
    hasIncorrectCredentials as HasIncorrectCredentials,
} from "~/util/form";
import * as UtilI18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction } from "./_selectors";

export default [
    // Open Credit Letter
    TakeLatest(TYPE.IMPORT_CREDIT_LETTER_PRE_REQUEST, importCreditLetterOpenPreRequest),
    TakeLatest(TYPE.IMPORT_CREDIT_LETTER_PREVIEW_REQUEST, importCreditLetterOpenPreviewRequest),
    TakeLatest(TYPE.IMPORT_CREDIT_LETTER_SEND_REQUEST, importCreditLetterOpenSendRequest),
    // Modify Credit Letter
    TakeLatest(TYPE.MODIFY_CREDIT_LETTER_PRE_REQUEST, modifyCreditLetterPre),
    TakeLatest(TYPE.MODIFY_CREDIT_LETTER_PREVIEW_REQUEST, modifyCreditLetterPreview),
    TakeLatest(TYPE.MODIFY_CREDIT_LETTER_SEND_FORM, modifyCreditLetter),

    // Cancellation Credit Letter
    TakeLatest(TYPE.COMEX_CANCELLATION_CREDIT_LETTER_PRE_REQUEST, cancelCreditLetterPre),
    TakeLatest(TYPE.CANCELLATION_CREDIT_LETTER_PREVIEW_REQUEST, cancelCreditLetterPreview),
    TakeLatest(TYPE.CANCELLATION_CREDIT_LETTER_SEND_REQUEST, cancelCreditLetter),

    // Request Endorsement
    TakeLatest(TYPE.REQUEST_ENDORSEMENT_PRE_REQUEST, requestEndorsementPre),
    // Billing cancellation
    TakeLatest(TYPE.BILLING_CANCELATION_PRE_REQUEST, billingCancellationPre),
    TakeLatest(TYPE.BILLING_CANCELATION_PREVIEW_REQUEST, billingCancellationPreview),
    TakeLatest(TYPE.BILLING_CANCELATION_SEND_FORM, billingCancellation),
    TakeLatest(TYPE.REQUEST_ENDORSEMENT_PREVIEW_REQUEST, requestEndorsementPreview),
    TakeLatest(TYPE.REQUEST_ENDORSEMENT_SEND_REQUEST, requestEndorsement),

    //  Lift Credit Letter With Discrepancies
    TakeLatest(TYPE.LIFT_CREDIT_LETTER_DISCREPANCIES_PRE_REQUEST, liftCreditLetterDiscrepanciesPre),
    TakeLatest(TYPE.LIFT_CREDIT_LETTER_DISCREPANCIES_PREVIEW_REQUEST, liftCreditLetterDiscrepanciesPreview),
    TakeLatest(TYPE.LIFT_CREDIT_LETTER_DISCREPANCIES_SEND_FORM, liftCreditLetterDiscrepanciesSend),

    // Billing cancellation
    TakeLatest(TYPE.DOCUMENT_PRESENTATION_PRE_REQUEST, documentPresentationPre),
    TakeLatest(TYPE.DOCUMENT_PRESENTATION_PREVIEW_REQUEST, documentPresentationPreview),
    TakeLatest(TYPE.DOCUMENT_PRESENTATION_SEND_FORM, documentPresentation),
];

// Open Credit Letter
function* importCreditLetterOpenPreRequest() {
    const { type, data } = yield Call(SelectorsMiddleware.importCreditLetterOpenPreRequest);
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.importCreditLetterOpenPreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX],
            }),
        );
    } else {
        yield Put(SelectorsAction.importCreditLetterOpenPreSuccess({ ...data.data }));
    }
}

function* importCreditLetterOpenPreviewRequest({ formData, formikBag }) {
    const { type, data } = yield Call(SelectorsMiddleware.importCreditLetterOpenPreviewRequest, formData);
    if (type === RESPONSE_TYPE.WARNING) {
        formikBag.setErrors(data.data);

        const touchedFields = {};
        const fieldsWithErrors = Object.keys(data.data);
        for (let index = 0; index < fieldsWithErrors.length; index += 1) {
            const field = fieldsWithErrors[index];
            touchedFields[field] = true;
            data[field] = data[field] === "" ? " " : data[field];
        }
        formikBag.setTouched(touchedFields);

        yield Put(SelectorsAction.importCreditLetterOpenPreviewFailure());
        const errorMessage =
            data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;
        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_IMPORT_CREDIT_LETTER_OPEN],
            }),
        );
    } else {
        yield Put(SelectorsAction.importCreditLetterOpenPreviewSuccess({ ...data.data }));
    }
    formikBag.setSubmitting(false);
}

function* importCreditLetterOpenSendRequest({ formData, formikBag }) {
    const { type, data } = yield Call(SelectorsMiddleware.importCreditLetterOpenSendRequest, formData);
    if (type === RESPONSE_TYPE.WARNING) {
        const { _otp: otp } = formData;
        const hasIncorrectCredentials = HasIncorrectCredentials({ otp }, data);
        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
            yield Put(SelectorsAction.importCreditLetterOpenSendFailure());
        } else {
            yield Put(SelectorsAction.comexFormSendDataFailure());
            const errorMessage =
                data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.COMEX_IMPORT_CREDIT_LETTER_OPEN],
                }),
            );
        }
    } else {
        const { idTransaction } = data;
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });

        yield Put(SelectorsAction.importCreditLetterOpenSendSuccess({ transaction }));
    }
    formikBag.setSubmitting(false);
}

// Modify Credit Letter
function* modifyCreditLetterPre() {
    const { type, data } = yield Call(SelectorsMiddleware.modifyCreditLetterPreRequest);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.modifyCreditLetterPreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX],
            }),
        );
    } else {
        yield Put(SelectorsAction.modifyCreditLetterPreSuccess({ ...data.data }));
    }
}

function* modifyCreditLetterPreview({ formData, formikBag }) {
    const { type, data } = yield Call(SelectorsMiddleware.modifyCreditLetterPreviewRequest, formData);
    formikBag.setSubmitting(false);
    const errorMessage =
        data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) :  data.message;
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.modifyCreditLetterPreviewFailure());
        formikBag.setErrors(data.data);
        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_IMPORT_CREDIT_LETTER_MODIFY],
            }),
        );
    } else {
        yield Put(SelectorsAction.modifyCreditLetterPreviewSuccess({ ...data.data }));
    }
}

function* modifyCreditLetter({ formData, credentials, formikBag, idTransaction }) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { type, data } = yield Call(SelectorsMiddleware.modifyCreditLetterRequest, {
        ...formData,
        idTransaction,
        ...credentialsWithUnderscore,
    });
    formikBag.setSubmitting(false);

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);
        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
            yield Put(SelectorsAction.modifyCreditLetterSendFailure());
        } else {
            yield Put(SelectorsAction.comexFormSendDataFailure());
            const errorMessage =
                data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) :  data.message;
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.COMEX_IMPORT_CREDIT_LETTER_MODIFY],
                }),
            );
        }
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: data.idTransaction,
        });
        yield Put(SelectorsAction.modifyCreditLetterSendSuccess({ transaction }));
    }
}

// Canceallation Credit Letter
function* cancelCreditLetterPre() {
    const { type, data } = yield Call(SelectorsMiddleware.cancellationCreditLetterPreRequest);
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.getCancellationCreditLetterPreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX],
            }),
        );
    } else {
        yield Put(SelectorsAction.getCancellationCreditLetterPreSuccess({ ...data.data }));
    }
}

function* cancelCreditLetterPreview({ formData, formikBag }) {
    const { type, data } = yield Call(SelectorsMiddleware.cancellationCreditLetterPreviewRequest, formData);
    formikBag.setSubmitting(false);
    const errorMessage =
        data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.cancellationCreditLetterPreviewFailure());
        formikBag.setErrors(data.data);
        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_IMPORT_CANCELLATION_CREDIT_LETTER],
            }),
        );
    } else {
        yield Put(SelectorsAction.cancellationCreditLetterPreviewSuccess({ ...data.data }));
    }
}

function* cancelCreditLetter({ formData, credentials, formikBag, idTransaction }) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { type, data } = yield Call(SelectorsMiddleware.cancellationCreditLetterRequest, {
        ...formData,
        idTransaction,
        ...credentialsWithUnderscore,
    });
    formikBag.setSubmitting(false);

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);
        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
            yield Put(SelectorsAction.cancellationCreditLetterSendFailure());
        } else {
            yield Put(SelectorsAction.comexFormSendDataFailure());
            const errorMessage =
                data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.COMEX_IMPORT_CANCELLATION_CREDIT_LETTER],
                }),
            );
        }
    } else {
        const { idTransaction: idTransactionResp } = data;

        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransactionResp,
        });
        yield Put(SelectorsAction.cancellationCreditLetterSendSuccess({ transaction }));
    }
}

// Billing Cancellation
function* billingCancellationPre() {
    const { type, data } = yield Call(SelectorsMiddleware.billingCancellationPreRequest);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.billingCancellationPreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_BILLING_CANCELLATION],
            }),
        );
    } else {
        yield Put(SelectorsAction.billingCancellationPreSuccess({ ...data.data }));
    }
}

function* billingCancellationPreview({ formData, formikBag }) {
    const { type, data } = yield Call(SelectorsMiddleware.billingCancellationPreviewRequest, formData);
    formikBag.setSubmitting(false);
    const errorMessage =
        data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.billingCancellationPreviewFailure());
        formikBag.setErrors(data.data);
        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_BILLING_CANCELLATION],
            }),
        );
    } else {
        yield Put(SelectorsAction.billingCancellationPreviewSuccess({ ...data.data }));
    }
}

function* billingCancellation({ formData, credentials, formikBag }) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { type, data } = yield Call(SelectorsMiddleware.billingCancellationRequest, {
        ...formData,
        ...credentialsWithUnderscore,
    });
    const { idTransaction } = data;
    formikBag.setSubmitting(false);

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);
        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
            yield Put(SelectorsAction.billingCancellationSendFailure());
        } else {
            yield Put(SelectorsAction.comexFormSendDataFailure());
            const errorMessage =
                data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.COMEX_BILLING_CANCELLATION],
                }),
            );
        }
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(SelectorsAction.billingCancellationSendSuccess({ transaction }));
    }
}

function* requestEndorsementPre() {
    const { type, data } = yield Call(SelectorsMiddleware.requestEndorsementPreRequest);
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.requestEndorsementPreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_IMPORT_REQUEST_ENDORSEMENT],
            }),
        );
    } else {
        yield Put(SelectorsAction.requestEndorsementPreSuccess({ ...data.data }));
    }
}

function* requestEndorsementPreview({ formData, formikBag }) {
    const { type, data } = yield Call(SelectorsMiddleware.requestEndorsementPreviewRequest, formData);
    formikBag.setSubmitting(false);
    const errorMessage =
        data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.requestEndorsementPreviewFailure());
        formikBag.setErrors(data.data);
        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_IMPORT_REQUEST_ENDORSEMENT],
            }),
        );
    } else {
        yield Put(SelectorsAction.requestEndorsementPreviewSuccess({ ...data.data }));
    }
}

function* requestEndorsement({ formData, credentials, formikBag }) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { type, data } = yield Call(SelectorsMiddleware.requestEndorsementRequest, {
        ...formData,
        ...credentialsWithUnderscore,
    });
    const { idTransaction } = data;
    formikBag.setSubmitting(false);

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
            yield Put(SelectorsAction.requestEndorsementSendFailure());
        } else {
            yield Put(SelectorsAction.comexFormSendDataFailure());
            const errorMessage =
                data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.COMEX_IMPORT_REQUEST_ENDORSEMENT],
                }),
            );
        }
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(SelectorsAction.requestEndorsementSendSuccess({ transaction }));
    }
}

//  Lift Credit Letter With Discrepancies
function* liftCreditLetterDiscrepanciesPre() {
    const { type, data } = yield Call(SelectorsMiddleware.liftCreditLetterDiscrepanciesPreRequest);
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.liftCreditLetterDiscrepanciesPreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_IMPORT_LIFT_CREDIT_LETTER_DISCREPANCIES],
            }),
        );
    } else {
        yield Put(SelectorsAction.liftCreditLetterDiscrepanciesPreSuccess({ ...data.data }));
    }
}

function* liftCreditLetterDiscrepanciesPreview({ formData, formikBag }) {
    const { type, data } = yield Call(SelectorsMiddleware.liftCreditLetterDiscrepanciesPreviewRequest, formData);
    formikBag.setSubmitting(false);
    const errorMessage =
        data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.liftCreditLetterDiscrepanciesPreviewFailure());
        formikBag.setErrors(data.data);
        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_IMPORT_LIFT_CREDIT_LETTER_DISCREPANCIES],
            }),
        );
    } else {
        yield Put(SelectorsAction.liftCreditLetterDiscrepanciesPreviewSuccess({ ...data.data }));
    }
}

function* liftCreditLetterDiscrepanciesSend({ formData, credentials, formikBag }) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { type, data } = yield Call(SelectorsMiddleware.liftCreditLetterWithDiscrepanciesRequest, {
        ...formData,
        ...credentialsWithUnderscore,
    });
    const { idTransaction } = data;
    formikBag.setSubmitting(false);

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);
        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
            yield Put(SelectorsAction.liftCreditLetterDiscrepanciesSendFailure());
        } else {
            yield Put(SelectorsAction.comexFormSendDataFailure());
            const errorMessage =
                data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.COMEX_IMPORT_LIFT_CREDIT_LETTER_DISCREPANCIES],
                }),
            );
        }
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(SelectorsAction.liftCreditLetterDiscrepanciesSendSuccess({ transaction }));
    }
}

// Document Presentation
function* documentPresentationPre() {
    const { type, data } = yield Call(SelectorsMiddleware.documentPresentationPreRequest);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.documentPresentationPreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_IMPORT_DOCUMENT_PRESENTATION],
            }),
        );
    } else {
        yield Put(SelectorsAction.documentPresentationPreSuccess({ ...data.data }));
    }
}

function* documentPresentationPreview({ formData, formikBag }) {
    const { type, data } = yield Call(SelectorsMiddleware.documentPresentationPreviewRequest, formData);
    formikBag.setSubmitting(false);
    const errorMessage =
        data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.documentPresentationPreviewFailure());
        formikBag.setErrors(data.data);
        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_IMPORT_DOCUMENT_PRESENTATION],
            }),
        );
    } else {
        yield Put(SelectorsAction.documentPresentationPreviewSuccess({ ...data.data }));
    }
}

function* documentPresentation({ formData, credentials, formikBag }) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { type, data } = yield Call(SelectorsMiddleware.documentPresentationRequest, {
        ...formData,
        ...credentialsWithUnderscore,
    });
    const { idTransaction } = data;
    formikBag.setSubmitting(false);

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);
        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
        } else {
            yield Put(SelectorsAction.comexFormSendDataFailure());
            const errorMessage =
                data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.COMEX_IMPORT_DOCUMENT_PRESENTATION],
                }),
            );
        }
        yield Put(SelectorsAction.documentPresentationSendFailure());
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(SelectorsAction.documentPresentationSendSuccess({ transaction }));
    }
}
