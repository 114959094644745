import React from "react";

import { Formik, Form } from "formik";
import { Grid, Row, Col } from "react-bootstrap";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";

import { SelectorsStore, SelectorsAction } from "~/store/investment";
import * as i18n from "~/util/i18n";
import { Types as TypesRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import I18n from "~/components/I18n";

import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...TypesRedux,
    },
    defaults: {
        data: {},
    },
};

export function Component(props) {
    const {
        dispatch,
        data: { submitData = {} },
    } = props;

    return (
        <React.Fragment>
            <div id={Style.ID}>
                <section className="container--layout align-items-center flex-grow">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                {/* <p className="text-lead">
                                    <I18n id="wm.requestInfo.title" />
                                </p> */}

                                <div className="data-wrapper">
                                    <span className="data-label">
                                        <I18n id="wm.requestInfo.contactType" />{" "}
                                    </span>
                                    <span className="data-text">
                                        {i18n.get(
                                            `investments.requestInvestments.contactData.${submitData.contactType}`,
                                        )}
                                    </span>
                                </div>

                                {submitData.phone && (
                                    <div className="data-wrapper">
                                        <span className="data-label">
                                            <I18n id="wm.requestInfo.phone.label" />{" "}
                                        </span>
                                        <span className="data-text">{submitData.phone}</span>
                                    </div>
                                )}

                                {submitData.email && (
                                    <div className="data-wrapper">
                                        <span className="data-label">
                                            <I18n id="wm.requestInfo.email.label" />{" "}
                                        </span>
                                        <span className="data-text">{submitData.email}</span>
                                    </div>
                                )}

                                {submitData.contactTime && (
                                    <div className="data-wrapper">
                                        <span className="data-label">
                                            <I18n id="wm.requestInfo.contactTime" />{" "}
                                        </span>
                                        <span className="data-text">
                                            {i18n.get(`wm.requestInfo.${submitData.contactTime}`)}
                                        </span>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Grid>
                </section>
                <Formik onSubmit={handleSubmit}>
                    {(formikProps) => {
                        const { isSubmitting } = formikProps;
                        return (
                            <Form class="button-container" noValidate>
                                <section className="container--layout align-items-center">
                                    <Grid className="form-content container--flex-middle">
                                        <Row className="justify-content-center">
                                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                                <I18n id="form.confirmation.without.credential" />
                                            </Col>
                                        </Row>
                                    </Grid>
                                </section>
                                <section className="align-items-center">
                                    <Grid className="form-footer">
                                        <Row className="justify-content-center">
                                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                                <Button
                                                    type="submit"
                                                    label="global.send"
                                                    bsStyle="primary"
                                                    loading={isSubmitting}
                                                />
                                            </Col>
                                        </Row>
                                    </Grid>
                                </section>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </React.Fragment>
    );

    function handleSubmit(_credentials, formikBag) {
        dispatch(
            SelectorsAction.requestInfoSendForm({
                submitData,
                formikBag,
            }),
        );
    }
}
Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

export default Compose(
    Connect((store) => ({
        fetching: SelectorsStore.getFetching(store),
        data: SelectorsStore.getRequestInfoData(store),
    })),
)(React.memo(Component));
