import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    changeProductAlias: (props) => API.executeWithAccessToken(PATH.CHANGE_ALIAS, props),
};

export const SelectorsAction = {
    changeProductAlias: (props) => FactoryAction(TYPE.CHANGE_PRODUCT_ALIAS, props),
    changeProductAliasSuccess: (props) => FactoryAction(TYPE.CHANGE_PRODUCT_ALIAS_SUCCESS, props),
};
