import React from "react";

import PropTypes from "prop-types";
import { compose } from "redux";

import Logger from "~/util/logger";

import withFocus from "~/pages/_components/withFocus";

import formField from "~/pages/forms/_components/_fields/_commons/formField";

import Style from "./Textarea.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        maxLength: PropTypes.number.isRequired,
        validationRegularExpresion: PropTypes.string,
        minLength: PropTypes.number,
        setValue: PropTypes.func.isRequired,
        editing: PropTypes.bool,
        placeholder: PropTypes.string,
        value: PropTypes.string,
        name: PropTypes.string,
        toggleIsFocused: PropTypes.func.isRequired,
    },
    defaults: { minLength: 0, validationRegularExpresion: "", editing: false, placeholder: "", value: "", name: "" },
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    handleChange = ({ target: { value } }) => {
        const { setValue } = this.props;
        setValue(value);
    };

    render() {
        const {
            editing,
            placeholder,
            minLength,
            maxLength,
            value,
            name,
            validationRegularExpresion,
            toggleIsFocused,
        } = this.props;

        if (editing) {
            return (
                <div className="input-group">
                    <textarea
                        className={`form-control ${Style.CLASS}`}
                        name={name}
                        value={value}
                        onChange={this.handleChange}
                        onBlur={toggleIsFocused}
                        minLength={minLength}
                        maxLength={maxLength}
                        placeholder={placeholder}
                        pattern={validationRegularExpresion}
                        onFocus={toggleIsFocused}
                    />
                </div>
            );
        }
        return <span>{value}</span>;
    }
}

export default compose(
    withFocus,
    formField({
        isValidValue: (value, props) => {
            const { validationRegularExpresion } = props;

            if (validationRegularExpresion) {
                try {
                    const regex = new RegExp(validationRegularExpresion);
                    return regex.test(value);
                } catch (err) {
                    Logger.error(err);
                    return true;
                }
            }

            return true;
        },
    }),
)(Component);
