import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import { compose as Compose } from "redux";

import { ALIAS_TYPES, CDP_CURRENCY, EMPTY_STR, PIPE_SEPARATOR, SAVINGS_ACCOUNT_BT_VALUE } from "~/constants";
import { MODE } from "~/constants/form";
import { SelectorsAction as SelectorsActionForm, SelectorsStore as SelectorsStoreForm } from "~/store/form";
import {
    SelectorsAction as SelectorsActionTransfers,
    SelectorsStore as SelectorsStoreTransfers,
} from "~/store/transfers";
import * as UtilsConfig from "~/util/config";
import * as UtilsI18n from "~/util/i18n";
import { GetMobileCountryOptions } from "~/util/phone";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import Image from "~/components/Image";
import SelectorInput from "~/pages/_components/fields/SelectorInput";
import WithFocus from "~/pages/_components/withFocus";

import AliasOuterComponents from "~/pages/forms/_components/_fields/_aliasComponents/OuterComponents";
import FormField from "~/pages/forms/_components/_fields/_commons/formField";

export const NAME = "Alias";

export const PROP = {
    types: { label: PropTypes.string.isRequired },
    defaults: {},
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {
        options: [],
    };

    componentDidMount() {
        const { dispatch, editing, mode, setValue, value } = this.props;

        if (editing) {
            const { options } = this.state;

            const allOptions = [];

            if (options.length === 0) {
                const aliasTypes = UtilsConfig.getArray("client.transfers.aliasTypes.enabled", [ALIAS_TYPES.PHONE.key]);

                // Prefixes
                if (aliasTypes.some((aliasType) => aliasType === ALIAS_TYPES.PHONE.key)) {
                    const prefixOptions = GetMobileCountryOptions()?.map((option) => ({
                        country: option.country,
                        countryName: option.countryName,
                        label: (
                            <React.Fragment className="needsclick">
                                <Image src={`${option.flag}.svg`} className="svg-icon needsclick" />
                                <span className="control-label needsclick"> {option.value}</span>
                                <span className="control-label label-country needsclick"> {option.countryName}</span>
                            </React.Fragment>
                        ),
                        value: option.value,
                    }));

                    allOptions.push(...prefixOptions);
                }

                // Email
                if (aliasTypes.some((aliasType) => aliasType === ALIAS_TYPES.EMAIL.key)) {
                    allOptions.unshift({
                        label: UtilsI18n.get(`client.transfer.aliasType.${ALIAS_TYPES.EMAIL.key}`),
                        value: ALIAS_TYPES.EMAIL.key,
                    });
                }

                // Text
                if (aliasTypes.some((aliasType) => aliasType === ALIAS_TYPES.TEXT.key)) {
                    allOptions.unshift({
                        label: UtilsI18n.get(`client.transfer.aliasType.${ALIAS_TYPES.EMAIL.key}`),
                        value: ALIAS_TYPES.TEXT.key,
                    });
                }

                this.setState({
                    options: allOptions,
                });
            }

            setValue({
                aliasData: (value && value.aliasData) || EMPTY_STR,
                aliasType:
                    (value && value.aliasType) ||
                    (allOptions.length > 0 ? allOptions[0].value : [{ label: EMPTY_STR, value: EMPTY_STR }]),
            });

            if (value) {
                this.search();
            }
        } else if (mode === MODE.VIEW) {
            dispatch(SelectorsActionTransfers.clearAccountsByAliasList());
        }
    }

    componentDidUpdate(prevProps) {
        const { dispatch, templateHasBeenLoaded, value } = this.props;
        const { templateHasBeenLoaded: prevTemplateHasBeenLoaded } = prevProps;
        const { aliasData, aliasType } = value || {};

        if (templateHasBeenLoaded && templateHasBeenLoaded !== prevTemplateHasBeenLoaded) {
            dispatch(SelectorsActionTransfers.clearAccountsByAliasList());

            if (aliasData !== EMPTY_STR && aliasType !== EMPTY_STR) {
                this.search();
            }

            dispatch(SelectorsActionTransfers.toggleTemplateHasBeenLoadedFalse());
        }
    }

    handleChange = (selectorValue, inputValue) => {
        const { setValue, value } = this.props;

        const aliasData = inputValue || EMPTY_STR;
        let aliasType = selectorValue.value ? selectorValue.value : selectorValue;

        //  If selectorValue has changed, reset input code
        if (value.aliasType !== aliasType) {
            setValue({ aliasType: selectorValue.value, aliasData });
        } else {
            aliasType = selectorValue;

            setValue({ aliasType: selectorValue.value || aliasType, aliasData });
        }
    };

    search = () => {
        const { dispatch, form, idField, idForm, localBanks, value } = this.props;
        const { setFieldValue } = form;
        const { aliasData, aliasType } = value || {};

        if (aliasData && aliasData.trim() !== EMPTY_STR) {
            dispatch(SelectorsActionTransfers.clearAccountsByAliasList());

            setFieldValue("searchedAlias", value);

            const newValue = {
                value: {
                    accountName: EMPTY_STR,
                    accountNumber: EMPTY_STR,
                    accountType: SAVINGS_ACCOUNT_BT_VALUE,
                    aliasData,
                    aliasType,
                    bcuCode: EMPTY_STR,
                    currency: CDP_CURRENCY.UYU,
                },
            };

            dispatch(SelectorsActionForm.dependeeChanged({ idField, value: newValue }));

            let aliasDataToUse = aliasData;
            let aliasTypeToUse = aliasType;

            if (aliasType !== ALIAS_TYPES.EMAIL.key && aliasType !== ALIAS_TYPES.TEXT.key) {
                aliasTypeToUse = ALIAS_TYPES.PHONE.btValue;

                // Special treatment for phone aliases
                aliasDataToUse = aliasType.substring(1).concat(PIPE_SEPARATOR).concat(aliasData);
            }

            const params = {
                aliasData: aliasDataToUse,
                aliasType: aliasTypeToUse,
                idForm,
                isFromAdministrationSection: false,
                localBanksAlreadyFetched: localBanks.length > 0,
            };

            dispatch(SelectorsActionTransfers.getAccountsByAliasRequest({ form, params }));
        }
    };

    render() {
        const { editing, isFocused, maxLength, toggleIsFocused, value } = this.props;
        const { aliasData, aliasType } = value || {};
        const { options } = this.state;

        const inputType = aliasType === ALIAS_TYPES.EMAIL.key || aliasType === ALIAS_TYPES.TEXT.key ? "text" : "tel";

        if (editing && options.length > 0) {
            return (
                <div>
                    <Box flex justify="between" align="center">
                        <div className="selector-country">
                            <SelectorInput
                                selectProps={{
                                    name: "aliasType",
                                    options,
                                    value: aliasType,
                                }}
                                inputProps={{ name: "aliasData", type: inputType, value: aliasData }}
                                inputMaxLength={maxLength}
                                isFocused={isFocused}
                                onChange={this.handleChange}
                                toggleIsFocused={toggleIsFocused}
                                mobilePhoneFieldStyled
                            />
                        </div>
                        <Box>
                            <Button
                                className="btn-search-alias needsclick"
                                bsStyle="primary"
                                bsSize="large"
                                key="primaryButton"
                                label="global.search"
                                loading={false}
                                onClick={this.search}
                                variant="primary"
                            />
                        </Box>
                    </Box>
                </div>
            );
        }

        const aliasTypeToShow =
            aliasType === ALIAS_TYPES.EMAIL.key || aliasType === ALIAS_TYPES.TEXT.key
                ? UtilsI18n.get(`client.transfer.aliasType.${aliasType}`)
                : aliasType;

        return (
            <React.Fragment>
                <span>{`${aliasTypeToShow} ${aliasData}`}</span>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    accountsByAlias: SelectorsStoreTransfers.getAccountsByAlias(store),
    accountsByAliasSearchRequested: SelectorsStoreTransfers.isAccountsByAliasSearchRequested(store),
    formMode: SelectorsStoreForm.getMode(store),
    isFetching: SelectorsStoreTransfers.fetching(store),
    localBanks: SelectorsStoreTransfers.getLocalBanks(store),
    showModal: SelectorsStoreTransfers.isShowModal(store),
    templateHasBeenLoaded: SelectorsStoreTransfers.isTemplateHasBeenLoaded(store),
});

export default Compose(
    WithRouter,
    Connect(mapStateToProps),
    HighOrder.Resizable,
    WithFocus,
    AliasOuterComponents,
    FormField(),
)(Component);
