import { TYPE as TYPES_APP } from "~/store/app";
import { TYPE as TypesBackoffice } from "~/store/backoffice";
import { TYPE as TYPES_DESKTOP } from "~/store/desktop";
import { TYPE as LoginTypes } from "~/store/login";
import { TYPE as TYPE_SETTINGS } from "~/store/settings";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPES_APP.CLEAN_UP:
        case LoginTypes.INIT_LOGIN_FLOW:
        case TYPE.LOGOUT_REQUEST:
            return PROP.defaults;
        case LoginTypes.LOGIN_SUCCESS:
            return {
                ...PROP.defaults,
                activeEnvironment: action.environment,
                environments: action.environments,
                productGroupId: action.environments[action.environment.id].productGroupId,
                user: action.user,
                isAdministrator: action.isAdministrator,
                notifyUser: action.notifyUser,
            };
        case TYPE.NEEDS_BIOMETRIC:
            return {
                ...storeProp,
                user: action.user,
            };
        case LoginTypes.FINGERPRINT_LOGIN_PRE_SUCCESS:
            return {
                ...storeProp,
                activeEnvironment: action.environment,
            };
        case TYPE.CHANGE_ENVIRONMENT_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                changingEnvironment: true,
            };
        case TYPE.CHANGE_ENVIRONMENT_SUCCESS:
            return {
                ...storeProp,
                activeEnvironment: action.environment,
                environments: action.environments,
                productGroupId: action.environments[action.environment.id].productGroupId,
                fetching: false,
                isAdministrator: action.isAdministrator,
                // When the change is successful, user's default environment is overwritten inside the saga
                // that's why the user is updated here
                user: action.user,
            };
        case TYPE.CHANGE_ENVIRONMENT_ERROR:
        case TYPE.ERROR:
        case TYPES_DESKTOP.LOAD_LAYOUT_SUCCESS:
            return {
                ...storeProp,
                changingEnvironment: false,
                fetching: false,
            };
        case TYPE_SETTINGS.CHANGE_PERSONAL_DATA_SUCCESS:
            return {
                ...storeProp,
            };
        case TYPE_SETTINGS.PREFERENCES_MODIFY_USER_CONFIGURATION_SUCCESS:
            return {
                ...storeProp,
                personalDataForceUpdate: false,
            };
        case TYPE_SETTINGS.CHANGE_SECURITY_SEAL_CONFIRMATION_SUCCESS:
            return {
                ...storeProp,
                user: {
                    ...storeProp.user,
                    securitySeal: action.securitySeal,
                },
            };
        case TYPE.READ_PERMISSIONS_SUCCESS:
            return {
                ...storeProp,
                activeEnvironment: {
                    ...storeProp.activeEnvironment,
                    permissions: action.permissions,
                },
            };
        case TYPE.CHANGE_RISK_PROFILE_DATA:
            return {
                ...storeProp,
                activeEnvironment: {
                    ...storeProp.activeEnvironment,
                    riskProfileExpiration: action.riskProfileExpiration,
                    riskProfileCode: action.riskProfileCode.toString(),
                    environmentData: {
                        ...storeProp.activeEnvironment.environmentData,
                        hasProfileCode: action.hasProfileCode,
                        hasRiskProfile: action.hasRiskProfile,
                    },
                },
            };
        case TYPE.SAVE_WEB_TOKEN:
            return {
                ...storeProp,
                webToken: action.webToken,
            };
        case TYPE.NOTIFY_USER:
            return {
                ...storeProp,
                personalDataNotify: action.personalDataNotify,
                personalDataForceUpdate: action.personalDataForceUpdate,
                accountDataNotify: action.accountDataNotify,
                accountDataForceUpdate: action.accountDataForceUpdate,
            };
        case TYPE.RESET_FETCHING:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.SAVE_DEVICE_LOCATION_VU:
            return {
                ...storeProp,
                deviceLocationVU: action.deviceLocationVU,
            };
        case TYPE.SAVE_QR_DATA:
            return {
                ...storeProp,
                qrData: action.qrData,
            };
        case TYPE.SUGGEST_ALIAS_ENROLLMENT:
            return {
                ...storeProp,
                suggestAliasEnrollment: action.suggestAliasEnrollment,
                suggestAliasEnrollmentShowHeader: false,
            };
        case TYPE.RESET_SUGGEST_ALIAS_ENROLLMENT:
            return {
                ...storeProp,
                suggestAliasEnrollment: false,
                suggestAliasEnrollmentShowHeader: true,
            };
        case TYPE.RESET_SUGGEST_ALIAS_ENROLLMENT_SHOW_HEADER:
            return {
                ...storeProp,
                suggestAliasEnrollmentShowHeader: false,
            };
        case TYPE.GET_USER_EMAIL_FORCE_SYNC:
            return {
                ...storeProp,
                userEmailForceSync: action.userEmailForceSync,
                userEmailSelected: null,
            };

        case TYPE.SELECT_USER_EMAIL_TO_SYNC:
            return {
                ...storeProp,
                userEmailSelected: action.userEmailSelected,
            };
        case TYPE.CANCEL_USER_EMAIL_FORCED_SYNC:
        case TYPE.FINISH_USER_EMAIL_FORCE_SYNC:
            return {
                ...storeProp,
                userEmailSelected: null,
            };
        case TYPE.RESET_USER_EMAIL_FORCE_SYNC:
            return {
                ...storeProp,
                userEmailForceSync: false,
            };
        case TYPE.GET_USER_MOBILE_PHONE_FORCE_SYNC:
            return {
                ...storeProp,
                userMobilePhoneForceSync: action.userMobilePhoneForceSync,
                userMobilePhoneSelected: null,
            };
        case TYPE.SELECT_USER_MOBILE_PHONE_TO_SYNC:
            return {
                ...storeProp,
                userMobilePhoneSelected: action.userMobilePhoneSelected,
            };
        case TYPE.CANCEL_USER_MOBILE_PHONE_FORCED_SYNC:
        case TYPE.FINISH_USER_MOBILE_PHONE_FORCE_SYNC:
            return {
                ...storeProp,
                userMobilePhoneSelected: null,
            };
        case TYPE.RESET_USER_MOBILE_PHONE_FORCE_SYNC:
            return {
                ...storeProp,
                userMobilePhoneForceSync: false,
                userMobilePhoneSelected: null,
            };
        case TYPE.EMAIL_FORCE_UPDATE:
            return {
                ...storeProp,
                emailForceUpdate: action.emailForceUpdate,
                emailForceUpdateFlow: action.emailForceUpdate,
            };
        case TYPE.EMAIL_FORCE_UPDATE_CANCEL:
        case TYPE.EMAIL_FORCE_UPDATE_SUCCESS:
            return {
                ...storeProp,
                emailForceUpdateFlow: false,
            };
        case TYPE.RESET_EMAIL_FORCE_UPDATE:
            return {
                ...storeProp,
                emailForceUpdate: false,
            };
        case TYPE.MOBILE_PHONE_FORCE_UPDATE:
            return {
                ...storeProp,
                mobilePhoneForceUpdate: action.mobilePhoneForceUpdate,
                mobilePhoneForceUpdateFlow: action.mobilePhoneForceUpdate,
            };
        case TYPE.MOBILE_PHONE_FORCE_UPDATE_CANCEL:
        case TYPE.MOBILE_PHONE_FORCE_UPDATE_SUCCESS:
            return {
                ...storeProp,
                mobilePhoneForceUpdateFlow: false,
            };
        case TYPE.RESET_MOBILE_PHONE_FORCE_UPDATE:
            return {
                ...storeProp,
                mobilePhoneForceUpdate: false,
            };
        case TypesBackoffice.READ_ENVIRONMENT_DATA_SUCCESS:
            return {
                ...storeProp,
                activeEnvironment: action.environmentData,
                fetching: false,
            };
        default:
            return storeProp;
    }
};
