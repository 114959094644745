import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { ID_FORM } from "~/constants/form";
import {
    SelectorsAction as SelectorsActionTransactionLines,
    SelectorsStore as SelectorsStoreTransactionLines,
} from "~/store/transactionLines";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import Element from "~/components/Component";

import Namespace from "./Header.rules.scss";

export const { CLASS, NAME, TYPE } = Namespace;

export const PROP = {
    types: {
        ...TypesRedux,
        idForm: PropTypes.string.isRequired,
        backUrl: PropTypes.string.isRequired,
        linesBeingEdited: PropTypes.number.isRequired,
        isEditingPayment: PropTypes.bool.isRequired,
        linesWithNoAmount: PropTypes.number.isRequired,
    },
    defaults: {
        ...DefaultsRedux,
    },
};

export function Component(props) {
    const { dispatch, isEditingPayment, linesWithNoAmount, idForm } = props;

    const hasLinesWithNoAmount = linesWithNoAmount > 0;
    const isSalaryPayment = idForm === ID_FORM.SALARY_PAYMENT;
    const showFormLabelKey = isSalaryPayment
        ? "salaryPayment.manual.addPayment.showForm"
        : "suppliersPayment.manual.addPayment.showForm";

    return (
        <Element tag={Box} className={classnames(CLASS)} flex directionColumn>
            <Box>
                <Box flex justify="end" className="mt-5">
                    <Button variant="link" size="sm" onClick={handleAddPaymentClick} disabled={isEditingPayment}>
                        {UtilsI18n.get(showFormLabelKey)}
                    </Button>
                    {isSalaryPayment && (
                        <span className="import-nomina">
                            <Button variant="link" size="sm" onClick={handleImportPayrollClick}>
                                {UtilsI18n.get("salaryPayment.manual.import.payroll")}
                            </Button>
                            <div className="tooltip">
                                <span className="tooltip-text">{UtilsI18n.get("salaryPayment.payroll.tooltip")}</span>
                            </div>
                        </span>
                    )}
                </Box>
            </Box>
            {hasLinesWithNoAmount && (
                <Box>
                    <Box flex justify="start" align="center" className="box-warning">
                        <span>{UtilsI18n.get("salaryPayment.manual.addPayment.linesWithNoAmount")}</span>
                        <Button variant="link" size="sm" onClick={handleDeleteLinesWithNoAmount}>
                            {UtilsI18n.get("salaryPayment.manual.addPayment.deleteLinesWithNoAmount")}
                        </Button>
                    </Box>
                </Box>
            )}
        </Element>
    );

    function handleAddPaymentClick() {
        dispatch(SelectorsActionTransactionLines.setIsEditingPayment({ isEditingPayment: true, lineNumber: 0 }));
    }

    function handleImportPayrollClick() {
        const { transactionLines } = props;
        if (transactionLines.length > 0) {
            dispatch(SelectorsActionTransactionLines.importPayrollConfirmationRequest({ confirmationRequest: true }));
        } else {
            dispatch(SelectorsActionTransactionLines.showImportedPayroll());
        }
    }

    function handleDeleteLinesWithNoAmount() {
        dispatch(SelectorsActionTransactionLines.cleanLinesWithNoAmount());
    }
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    linesBeingEdited: SelectorsStoreTransactionLines.getLinesBeingEdited(store),
    linesWithNoAmount: SelectorsStoreTransactionLines.getLinesWithNoAmount(store).length,
    transactionLines: SelectorsStoreTransactionLines.getTransactionLines(store),
});

export default Connect(mapStateToProps)(Component);
