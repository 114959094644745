import React from "react";

import PropTypes from "prop-types";

import { weekdaysMin as WeekdaysMin } from "~/util/date";
import { generateId } from "~/util/general";

import LapseSubOption from "~/pages/forms/_components/_fields/_scheduler/LapseSubOption";

export const NAME = "WeeklyFrequencySubOption";

export const PROP = {
    types: {
        enabledWeekDays: PropTypes.arrayOf(PropTypes.bool).isRequired,
        firstWorkingDate: PropTypes.string.isRequired,
        nonWorkingDays: PropTypes.arrayOf(PropTypes.string).isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.objectOf(PropTypes.any).isRequired,
    },
    defaults: {},
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    getWeekDayOptions = () => {
        const { enabledWeekDays, value } = this.props;

        const weekDayOptions = [];
        WeekdaysMin().forEach((elem, idx) => {
            weekDayOptions.push(
                <li className="c-control c-control--radio" key={generateId()}>
                    <input
                        className="c-control-input"
                        type="radio"
                        id={idx + 1}
                        value={idx + 1}
                        checked={value.day === idx + 1}
                        disabled={!enabledWeekDays[idx + 1]}
                        onChange={() => this.handleDayChange(idx + 1)}
                    />
                    <label className="c-control-label" htmlFor={idx + 1}>
                        {elem.toUpperCase()}
                    </label>
                </li>,
            );
        });

        return weekDayOptions;
    };

    handleDayChange = (day) => {
        const { enabledWeekDays, onChange, value } = this.props;

        if (enabledWeekDays[day]) {
            onChange({ ...value, day });
        }
    };

    handleLapseValueChange = (lapseValue) => {
        const { onChange, value } = this.props;

        onChange({ ...value, ...lapseValue });
    };

    render() {
        const { enabledWeekDays, firstWorkingDate, nonWorkingDays, value } = this.props;

        return (
            <div className="scheduler-frequency-suboptions">
                <div className="form-group">
                    <ul className="form-check-group weekday-selection">{this.getWeekDayOptions()}</ul>
                </div>
                <LapseSubOption
                    enabledWeekDays={enabledWeekDays}
                    firstWorkingDate={firstWorkingDate}
                    nonWorkingDays={nonWorkingDays}
                    onChange={this.handleLapseValueChange}
                    value={value}
                />
            </div>
        );
    }
}

export default Component;
