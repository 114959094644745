import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    const {
        pageNumber,
        userList,
        userNameList,
        userAction,
        credentialGroups,
        idTransaction,
        idActivity,
        data = {}, // TODO: Revisar el pasaje de props dentro de data.
    } = action;

    const { currentPage, inviteEnabled, totalPages, users, usersExtendedInfo, invitationCodes } = data;

    switch (action.type) {
        case TYPE.LOAD_LIST_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                currentPage: pageNumber,
            };

        case TYPE.LOAD_LIST_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };

        case TYPE.LOAD_LIST_SUCCESS:
            return {
                list: users,
                invitationCodes,
                usersInfoById: usersExtendedInfo,
                currentPage,
                totalPages,
                hasMoreData: currentPage < totalPages,
                isInviteEnabled: inviteEnabled,
                fetching: false,
            };

        case TYPE.LOAD_SINGLE_SUCCESS:
            return {
                ...storeProp,
                usersInfoById: {
                    ...storeProp.usersInfoById,
                    [data.idUser]: data,
                },
                fetching: false,
            };

        case TYPE.UPDATE_DISPATCHER_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };

        case TYPE.UPDATE_DISPATCHER_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };

        case TYPE.UPDATE_DISPATCHER_SUCCESS:
            return {
                ...storeProp,
                usersInfoById: {
                    ...storeProp.usersInfoById,
                    [data.idUser]: data,
                },
                fetching: false,
            };

        case TYPE.LOAD_MORE_REQUEST:
            return {
                ...storeProp,
                fetchingMoreUsers: true,
            };

        case TYPE.LOAD_MORE_SUCCESS:
            return {
                ...storeProp,
                fetchingMoreUsers: false,
                list: storeProp.list ? storeProp.list.concat(data.users) : data.list,
                usersInfoById: storeProp.usersInfoById
                    ? { ...storeProp.usersInfoById, ...usersExtendedInfo }
                    : data.usersInfoById,
                hasMoreData: currentPage < totalPages,
                currentPage,
            };

        case TYPE.EXPORT_LIST_REQUEST:
            return {
                ...storeProp,
                fetchingMoreUsers: true,
            };

        case TYPE.EXPORT_LIST_FAILURE:
        case TYPE.EXPORT_LIST_SUCCESS:
            return {
                ...storeProp,
                fetchingMoreUsers: false,
            };

        case TYPE.CHANGE_USER_STATUS_PREVIEW:
            return {
                ...storeProp,
                fetching: true,
            };

        case TYPE.CHANGE_USER_STATUS_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                userAction,
                usersToApplyAction: userList,
                usersNamesToApplyAction: userNameList,
                idTransaction,
                idActivity,
                credentialGroups,
                cleanSelectionForUserActions: false,
            };

        case TYPE.CHANGE_USER_STATUS_CONFIRMATION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                cleanSelectionForUserActions: true,
            };
        default:
            return {
                ...storeProp,
            };
    }
};
