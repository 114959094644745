import React from "react";

import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";
import * as Yup from "yup";

import { COMMA, EMPTY_STR, LANGUAGE, LEVEL, ONLY_NUMBER, SCOPE, SPACE_STR } from "~/constants";
import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { SelectorsStore as SelectorsStoreComex, SelectorsAction as SelectorsActionComex } from "~/store/comex";
import {
    SelectorsStore as SelectorsStoreComexExport,
    SelectorsAction as SelectorsActionComexExport,
    PROP as PropComex,
} from "~/store/comex/export";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { SelectorsAction as SelectorActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionTemplate } from "~/store/template";
import * as UtilsConfig from "~/util/config";
import { formatDate as FormatDate, toDate as ToDate } from "~/util/date";
import * as UtilsI18n from "~/util/i18n";
import { toNumber as ToNumber, numberFormat as NumberFormatUtil } from "~/util/number";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import NumberFormatInput from "~/components/NumberFormatInput";
import EmailList from "~/pages/_components/fields/EmailList";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TextArea from "~/pages/_components/fields/TextArea";
import TextField from "~/pages/_components/fields/TextField";
import AmountField from "~/pages/_components/fields/formik/AmountField";

import FileUploader from "~/pages/comEx/_components/FileUploader";
import OperationNumber from "~/pages/comEx/_components/OperationNumber";
import OperationNumberDetail from "~/pages/comEx/_components/OperationNumberDetail";
import CreateTemplateModal from "~/pages/forms/Step1Edit/_ModalTemplateCreate";
import ListTemplatesModal from "~/pages/forms/Step1Edit/_ModalTemplateList";
import { Termsandconditions } from "~/pages/forms/_components/_fields";
import Scheduler from "~/pages/forms/_components/_fields/Scheduler";
import Checkbox from "~/pages/forms/_components/_fields/_commons/Checkbox";

import Style from "../../../Step1Edit.rules.scss";
import { FORM_ID, ID_ACTIVITY_SEND, i18nMap, FIELD_NAME, OTHERS_FIELDS } from "../FormConstants";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...PropComex.types,
    },
    defaults: {
        ...PropComex.defaults,
    },
};

export function Component(props) {
    const {
        dispatch,
        emailValidationRegex,
        errors,
        lang,
        preData,
        setFieldError,
        setFieldTouched,
        setFieldValue,
        setSubmitting,
        setValues,
        transaction,
        values,
    } = props;
    const { availableOperations, nextValidDate, unavailableDays } = preData;

    React.useEffect(() => {
        dispatch(SelectorsActionComexExport.purchaseDocumentsPreRequest());
    }, [dispatch]);

    React.useEffect(() => {
        if (transaction?.data) {
            const transactionData = transaction?.data;
            /* carga de errores */
            if (transactionData.backendFormErrors?.cdpErrorFields) {
                const { cdpErrorFields } = transactionData.backendFormErrors;

                cdpErrorFields.map((errorField) => {
                    if (errorField === "valueDate") {
                        setFieldError("scheduler", SPACE_STR);
                        setFieldTouched("scheduler", true);

                        transactionData.scheduler = {
                            editing: true,
                            valueDate: transactionData?.valueDate,
                            selectedOption: transactionData?.selectedOption,
                        };
                    } else {
                        setFieldError(errorField, SPACE_STR);
                        setFieldTouched(errorField, true);
                    }
                    return null;
                });
            }
            if (transactionData.backendFormErrors?.message) {
                const errorMessage = transactionData?.backendFormErrors?.message;

                dispatch(
                    SelectorActionNotification.showNotification({
                        message: errorMessage,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.COMEX],
                    }),
                );
            } // modificaciones para que agarren bien los fields

            if (transactionData.valueDate) {
                const valueDate = ToDate(transactionData.valueDate);

                transactionData.valueDate = valueDate;
            }

            if (transactionData.dueDate) {
                const dueDate = ToDate(transactionData.dueDate);

                transactionData.dueDate = dueDate;
            }
            if (transactionData.boardingDate) {
                const boardingDate = ToDate(transactionData.boardingDate);

                transactionData.boardingDate = boardingDate;
            }

            let dataTransaction;
            if (transactionData) {
                dataTransaction = {
                    ...transactionData,
                    [FIELD_NAME.AMOUNT]: {
                        amount: transactionData[FIELD_NAME.AMOUNT],
                        currency: transactionData.currency,
                    },
                };
            }

            setValues(dataTransaction || transactionData);
        }
    }, [dispatch, setFieldError, setFieldTouched, setValues, transaction]);

    const handleFormKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            event.preventDefault();
        }
    };

    const handleSelectChangeFieldName = (id, fieldName) => {
        setFieldValue(fieldName, id);
    };

    const handleItemSelect = (item) => {
        const { othersFields } = item;

        if (othersFields) {
            setFieldValue("operationNumber", othersFields.NROOPERACION);
            setFieldValue("item", item);
            setFieldError("operationNumber", null);
        } else {
            setFieldValue("item", null);
            setFieldValue("operationNumber", EMPTY_STR);
        }
    };

    const handleModalLoad = () => {
        dispatch(SelectorsActionComex.toggleCreditLetterList());
    };

    const handleBlur = () => {
        if (values.operationNumber) {
            const selectedOperations = availableOperations.filter(
                (item) => item.othersFields.NROOPERACION === values.operationNumber,
            );

            if (selectedOperations.length > 0) {
                let subOpe = selectedOperations[0];

                if (values.item) {
                    subOpe = selectedOperations.find(
                        (item) => values.item.othersFields.SUBOPE === item.othersFields.SUBOPE,
                    );
                }

                setFieldValue("item", subOpe);
            } else {
                setFieldValue("item", null);
                setFieldValue("operationNumber", EMPTY_STR);
            }
        } else {
            setFieldValue("item", null);
        }
    };

    const handleCancel = () => {
        dispatch(
            SelectorsActionForm.cancelTransactionCustom({
                credentials: {},
                idTransaction: transaction.idTransaction,
                formikBag: { setSubmitting },
            }),
        );
    };

    const handleTemplateSave = () => {
        dispatch(SelectorsActionTemplate.createTemplate());
    };

    const handleTemplateLoad = () => {
        dispatch(SelectorsActionTemplate.toggleTemplateList());
    };

    const handleSelectTemplate = (template) => {
        const { setErrors } = props;
        const templateToBeLoaded = template;

        setValues(templateToBeLoaded);
        setErrors({});
    };

    const cancelEnabled = transaction && transaction.idTransactionStatus === STATUS.DRAFT;

    const othersFields = values?.item?.othersFields ?? {};

    const currencyImport = values?.item?.accountCurrency;
    let optionImport;

    if (currencyImport) {
        optionImport = [
            {
                id: currencyImport,
                label: UtilsI18n.get(`currency.label.${currencyImport}`),
            },
        ];
    }

    React.useEffect(() => {
        if (currencyImport) {
            setFieldValue("utilizationAmount.currency", currencyImport);
        }
    }, [currencyImport, setFieldValue]);

    const textOpcinal = UtilsI18n.get("form.field.optional");

    const handleInputChange = ({ target }) => {
        const { decimalSeparator } = NumberFormatUtil(lang);
        const { id, value } = target;

        setFieldValue(id, ToNumber(value, decimalSeparator));
    };

    const { decimalSeparator, thousandSeparator } = NumberFormatUtil(lang);

    return (
        <React.Fragment>
            <Form id={Style.ID} noValidate="novalidate" onKeyDown={handleFormKeyDown}>
                <div className="form-section loadTemplates">
                    <Button
                        onClick={handleTemplateLoad}
                        label="forms.templates.load"
                        bsStyle="default"
                        image="template.svg"
                    />
                </div>
                <section className="fields container--layout align-items-center flex-grow">
                    <Box className="form-section-title">
                        <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.section.operation`)}</h3>
                    </Box>

                    <Box flex align="end" className="form-group search-field">
                        <Field
                            idForm={FORM_ID}
                            name={FIELD_NAME.OPERATION_NUMBER}
                            hidePlaceholder
                            pattern={ONLY_NUMBER}
                            type="text"
                            component={TextField}
                            onBlur={handleBlur}
                            tooltip={UtilsI18n.get(`${FORM_ID}.operationNumber.tooltip`)}
                        />

                        <Box className="ml-3 mb-2">
                            <Button
                                onClick={handleModalLoad}
                                bsStyle="primary"
                                label="global.search"
                                className="search"
                            />
                        </Box>
                    </Box>

                    {values.item && <OperationNumberDetail othersFields={othersFields} idForm={FORM_ID} />}

                    <Box className="form-group checkbox-container">
                        <Checkbox
                            checked={values.recourse}
                            label={UtilsI18n.get(`${FORM_ID}.recourse.label`)}
                            name={FIELD_NAME.RECOURSE}
                            onChange={() => handleSelectChangeFieldName(!values.recourse, "recourse")}
                        />
                    </Box>
                    {!values.recourse ? (
                        <React.Fragment>
                            <Field
                                clearable={false}
                                component={AmountField}
                                data={{ options: optionImport ?? [] }}
                                decimalPlaces={2}
                                fixedDecimalScale
                                hideCurrency={!currencyImport}
                                idForm={FORM_ID}
                                maxLength={UtilsConfig.getInteger("amount.length")}
                                name={FIELD_NAME.AMOUNT}
                                placeholder={EMPTY_STR}
                                searchable={false}
                                value={currencyImport ?? EMPTY_STR}
                            />

                            <Box className={`form-group ${errors[FIELD_NAME.INTEREST] ? "has-error" : ""}`}>
                                <FieldLabel labelKey="comex.export.creditLetterPurchase.interestRate.label" />
                                <Box className="input-group">
                                    <Field
                                        allowNegative={false}
                                        className="form-control"
                                        component={NumberFormatInput}
                                        decimalScale={2}
                                        decimalSeparator={decimalSeparator}
                                        fixedDecimalScale
                                        id={FIELD_NAME.INTEREST}
                                        idForm={FORM_ID}
                                        maxLength={6}
                                        name={FIELD_NAME.INTEREST}
                                        onChange={handleInputChange}
                                        sufix="%"
                                        thousandSeparator={thousandSeparator}
                                        type="input"
                                        value={values[FIELD_NAME.INTEREST]}
                                    />
                                    <div className="add-type">
                                        {UtilsI18n.get(`${FORM_ID}.interestRate.percentage`)}
                                    </div>
                                </Box>
                                {errors[FIELD_NAME.INTEREST] && <FieldError error={errors[FIELD_NAME.INTEREST]} />}
                            </Box>

                            <Field
                                component={TextArea}
                                idForm={FORM_ID}
                                name={FIELD_NAME.OBSERVATIONS}
                                mode={MODE.EDIT}
                                className="form-control"
                                maxLength={500}
                                hidePlaceholder
                                optional={textOpcinal}
                            />

                            <Box className="form-group">
                                <Field
                                    name={FIELD_NAME.FILES}
                                    idForm={FORM_ID}
                                    emptyMessageKey={`${FORM_ID}.attachments.emptyMessage`}
                                    maxFiles={5}
                                    errors={errors}
                                    component={FileUploader}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                />
                            </Box>

                            <Field
                                component={EmailList}
                                idForm={FORM_ID}
                                name={FIELD_NAME.NOTIFICATION_EMAILS}
                                hideSwiftTip
                                className="form-group email-field"
                                renderSuggestion={false}
                                data={{ emailValidationRegex, addMessage: EMPTY_STR }}
                                isRequired={false}
                                optional={UtilsI18n.get("form.field.optional")}
                                tooltip={UtilsI18n.get(`${FORM_ID}.notificationEmails.tooltip`)}
                                value={values[FIELD_NAME.NOTIFICATION_EMAILS]}
                            />
                        </React.Fragment>
                    ) : (
                        <Box className="form-group alert-icon">
                            <Image src="alertIconMedium.svg" />
                            <div className="form-help-text">
                                <I18n
                                    id={`${FORM_ID}.recourse.text`}
                                    URL_PREFIX={UtilsConfig.get(
                                        "client.transactions.comex.pdfs.url.prefix",
                                        "/formularios/comex",
                                    )}
                                    tag="p"
                                    className="mt-3"
                                />
                            </div>
                        </Box>
                    )}

                    <hr />
                    <Box className="form-section-title">
                        <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.section.showPreview`)}</h3>
                    </Box>

                    <Field
                        component={Termsandconditions}
                        idForm={FORM_ID}
                        name={FIELD_NAME.DISCLAIMER}
                        mode={MODE.EDIT}
                        className="form-control"
                        i18nMap={i18nMap}
                    />
                    <Field
                        component={Scheduler}
                        data={{
                            firstWorkingDate: nextValidDate,
                            maxDaysToSchedule: UtilsConfig.get("client.comex.valueDate.maxDaysToSchedule"),
                            nonWorkingDays: unavailableDays,
                            lang: LANGUAGE.ES,
                            mode: MODE.EDIT,
                            programable: false,
                            schedulable: true,
                        }}
                        name={FIELD_NAME.SCHEDULER}
                        idForm={FORM_ID}
                    />
                </section>

                <footer>
                    {cancelEnabled && <Button onClick={handleCancel} label="forms.cancelTransaction.link" />}
                    <Button onClick={handleTemplateSave} label="forms.saveTemplate.link" className="templateSave" />
                    {!values.recourse && <Button type="submit" label="global.next" bsStyle="primary" />}
                </footer>
            </Form>
            <ListTemplatesModal idActivityTemplate={ID_ACTIVITY_SEND} onSelect={handleSelectTemplate} />
            <CreateTemplateModal values={values} idActivityTemplate={ID_ACTIVITY_SEND} backdrop="static" />
            <OperationNumber list={availableOperations} idForm={FORM_ID} handleItemClick={handleItemSelect} />
        </React.Fragment>
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => {
    return {
        lang: SelectorsStoreI18n.getLang(store),
        preData: SelectorsStoreComexExport.getComexFormPre(store),
        prevData: SelectorsStoreComexExport.getComexFormPrevData(store),
        transaction: SelectorsStoreComex.getTransaction(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: false,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: () =>
            Yup.object().shape({
                interestRate: Yup.number().max(99.99, UtilsI18n.get(`client.${FORM_ID}.interestRate.invalid`)),
                operationNumber: Yup.string().required(UtilsI18n.get(`client.${FORM_ID}.operationNumber.required`)),
            }),
        mapPropsToValues: ({ prevData }) => {
            const attachments = prevData?.attachments;
            const hasAttachments = attachments !== undefined && attachments !== EMPTY_STR;

            return {
                [FIELD_NAME.OPERATION_NUMBER]: prevData[FIELD_NAME.OPERATION_NUMBER] ?? EMPTY_STR,
                [FIELD_NAME.RECOURSE]: prevData[FIELD_NAME.RECOURSE] ?? false,
                [FIELD_NAME.OBSERVATIONS]: prevData[FIELD_NAME.OBSERVATIONS] ?? EMPTY_STR,
                [FIELD_NAME.FILES]: hasAttachments ? attachments?.split(COMMA) : [],
                [FIELD_NAME.ITEM]: prevData[FIELD_NAME.ITEM] ?? null,
                [FIELD_NAME.INTEREST]: prevData[FIELD_NAME.INTEREST] ?? EMPTY_STR,
                [FIELD_NAME.OBSERVATIONS]: prevData[FIELD_NAME.OBSERVATIONS] ?? EMPTY_STR,
                [FIELD_NAME.SCHEDULER]: prevData?.scheduler
                    ? {
                          editing: true,
                          valueDate: prevData?.scheduler.valueDate,
                          selectedOption: prevData?.scheduler.selectedOption,
                      }
                    : null,
                [FIELD_NAME.AMOUNT]: {
                    amount: prevData?.transferAmount ?? EMPTY_STR,
                    currency: prevData?.currency ?? EMPTY_STR,
                },
                [FIELD_NAME.NOTIFICATION_EMAILS]: prevData[FIELD_NAME.NOTIFICATION_EMAILS] ?? EMPTY_STR,
            };
        },
        handleSubmit: (props, formikBag) => {
            const item = props[FIELD_NAME.ITEM][OTHERS_FIELDS];
            const { dispatch } = formikBag.props;
            const { files, scheduler } = props;
            const attachmentsFiles = files ? files.join(COMMA) : EMPTY_STR;
            const valueDateString = scheduler ? FormatDate(scheduler.valueDate) : new Date();

            const formData = {
                amount: { quantity: props[FIELD_NAME.AMOUNT].amount, currency: item?.MONEDA },
                attachments: attachmentsFiles,
                ceded: EMPTY_STR,
                creditLetterNumber: item?.CARTADECREDITO,
                currency: item?.MONEDA,
                interestRate: props[FIELD_NAME.INTEREST],
                invoiceAmount: item?.IMPORTE,
                issuingBank: item?.EMISOR,
                item: props[FIELD_NAME.ITEM],
                notificationEmails: props[FIELD_NAME.NOTIFICATION_EMAILS],
                observations: props[FIELD_NAME.OBSERVATIONS],
                operationNumber: props[FIELD_NAME.OPERATION_NUMBER],
                payer: item?.ORDENANTE,
                scheduler,
                subOperationNumber: item?.SUBOPE,
                transferAmount: props[FIELD_NAME.AMOUNT].amount,
                valueDate: valueDateString,
                withResource: props[FIELD_NAME.RECOURSE] ?? false,
            };

            dispatch(
                SelectorsActionComexExport.purchaseDocumentsPreviewRequest({
                    formData,
                    formikBag,
                }),
            );
        },
    }),
)(Component);
