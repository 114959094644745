import { STATUS } from "~/constants/transaction";
import * as UtilsConfig from "~/util/config";

export const requestReview = (isMobile, idActivity, idTransactionStatus, isFromTransactionFlow) => {
    const allowedActivities = UtilsConfig.getArray("review.request.ratePlugin");
    const isIdActivityAllowed = allowedActivities.includes(idActivity);

    if (isMobile && isIdActivityAllowed && idTransactionStatus === STATUS.FINISHED && isFromTransactionFlow) {
        setTimeout(() => {
            window.appReview.requestReview();
        }, 500);
    }
};

export default requestReview;
