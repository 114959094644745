import React from "react";

import { Field } from "formik";

import { TYPE_ADMINISTRATION, SCHEMA_SIGNATURES_DEFAULT, ONE } from "~/constants";
import * as ConfigUtil from "~/util/config";
import * as I18nUtils from "~/util/i18n";

import I18n from "~/components/I18n";
import StepperFieldGroup from "~/pages/_components/fields/formik/StepperFieldGroup";

export const NAME = "RequiredSignatures";

export const PROP = {
    types: {},
    defaults: {},
};
export function Component({ administrationScheme, typeAction, idForm, typeTransaction, name }) {
    const isMedium = administrationScheme === TYPE_ADMINISTRATION.MEDIUM;
    return (
        <React.Fragment>
            <div className="form-section">
                <div className="form-section-title">
                    <I18n id={`administration.signatures.modify.signatureType.${typeTransaction}`} />
                </div>
                <div className="form-content">
                    <div className="control-label">
                        <I18n id={`administration.signatures.${typeAction}.requiredSignatures`} />{" "}
                    </div>
                    <div className="form-section-label">
                        <I18n id={`administration.signatures.${typeAction}.${administrationScheme}.signersLevel`} />
                    </div>

                    <div className="form-group form-group--stepper-field">
                        <div className="control-label form-group-text">
                            <I18n id={`administration.signatures.${typeAction}.${administrationScheme}.signersCount`} />
                        </div>
                        <div className="form-section-label">
                            <span>
                                {I18nUtils.get("administration.signatures.modify.signersCount.information")}
                                {ConfigUtil.get("administration.signatures.maxNeeded")}
                            </span>
                        </div>

                        <Field
                            component={StepperFieldGroup}
                            hideLabel={isMedium}
                            idForm={idForm}
                            name={`${name}SignatureLevelsCounts`}
                            min={ONE}
                            max={ConfigUtil.get("administration.signatures.maxNeeded")}
                            options={
                                isMedium
                                    ? [SCHEMA_SIGNATURES_DEFAULT]
                                    : ConfigUtil.getArray("administration.signatures.signatureLevels")
                            }
                            showFirstErrorWhenEquals
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;
export default Component;
