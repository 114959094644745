import React from "react";

import PropTypes from "prop-types";

import Checkbox from "~/pages/forms/_components/_fields/_commons/Checkbox";

export const NAME = "CheckboxGroup";

export const PROP = {
    types: {
        labelKey: PropTypes.string,
        valueKey: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.any).isRequired,
        options: PropTypes.arrayOf(PropTypes.any).isRequired,
        onChange: PropTypes.func.isRequired,
        mode: PropTypes.string,
    },
    defaults: { labelKey: "label", valueKey: "id", mode: "" },
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    handleCheckboxChange = (selectedValue) => {
        const { values, onChange } = this.props;
        const currentValues = [...values];

        const index = currentValues.indexOf(selectedValue);
        if (index > -1) {
            currentValues.splice(index, 1);
        } else {
            currentValues.push(selectedValue);
        }
        onChange(currentValues);
    };

    renderViewMode() {
        const { options, values } = this.props;
        let selected;

        values.forEach((value) => {
            selected = options.filter((option) => value.indexOf(option.id) !== -1);
        });

        return (
            selected.length && (
                <ul>
                    {selected.map((option) => (
                        <li key={option.id}>{option.label}</li>
                    ))}
                </ul>
            )
        );
    }

    render() {
        const { options, values, labelKey, valueKey, mode } = this.props;
        if (mode === "view" || mode === "preview") {
            return this.renderViewMode();
        }

        return (
            <div className="form-group-control-list">
                {options.map((option) => (
                    <Checkbox
                        key={option[valueKey]}
                        value={option[valueKey]}
                        label={option[labelKey]}
                        checked={values ? values.includes(option[valueKey]) : false}
                        onChange={this.handleCheckboxChange}
                    />
                ))}
            </div>
        );
    }
}

export default Component;
