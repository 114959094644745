import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.CLEAN_UP:
            return {
                ...storeProp,
                hidden: action.hidden,
                showAgain: action.showAgain,
            };
        case TYPE.FINGERPRINT_AVAILABILITY:
            return {
                ...storeProp,
                availability: null,
            };
        case TYPE.FINGERPRINT_AVAILABILITY_SUCCESS:
            return {
                ...storeProp,
                availability: action.availability,
            };
        case TYPE.FINGERPRINT_CONFIGURATION_PRE:
            return {
                ...storeProp,
                fetching: true,
                fingerprintConfiguredUserDevices: null,
                isDeviceWithFingerprint: null,
            };
        case TYPE.FINGERPRINT_CONFIGURATION_PRE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                fingerprintConfiguredUserDevices: action.fingerprintConfiguredUserDevices,
                isDeviceWithFingerprint: action.isDeviceWithFingerprint,
            };
        case TYPE.FINGERPRINT_DELETE_DEVICE_PRE:
            return {
                ...storeProp,
                device: action.device,
            };
        case TYPE.FINGERPRINT_HIDE_WIDGET:
            return {
                ...storeProp,
                hidden: true,
                showAgain: action.showAgain,
            };
        case TYPE.FINGERPRINT_SHOW_WIDGET:
            return {
                ...storeProp,
                hidden: false,
            };
        default:
            return storeProp;
    }
};
