import React from "react";

import { Field, Form, withFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import {
    DEFAULT_SIGNATURE,
    EMPTY_STR,
    HARD_OTP,
    NO,
    NO_SIGNATURE,
    ONLY_NUMBER,
    SCOPE,
    SOFT_OTP,
    TYPE_ADMINISTRATION,
    YES,
} from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Administration/Simple";
import {
    SelectorsStore as SelectorsStoreUserInvite,
    SelectorsAction as SelectorsActionUserInvite,
} from "~/store/administration/users/usersInvite";
import { SelectorsStore as StoreSession } from "~/store/session";
import * as UtilsConfig from "~/util/config";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";
import IndeterminateCheckbox from "~/pages/_components/IndeterminateCheckbox";
import CountryCellPhoneField from "~/pages/_components/fields/CountryCellPhoneField";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TextField from "~/pages/_components/fields/TextField";
import MultiSelect from "~/pages/_components/fields/formik/MultiSelect";
import Selector from "~/pages/_components/fields/formik/Selector";

import StyleField from "~/pages/settings/changePersonalInformation/field/MobilePhoneField.rules.scss";
import SelectorType from "~/pages/settings/changePersonalInformation/field/SelectorType";

import Style from "./UserInviteStep2.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        activeEnvironment: PropTypes.shape({ administrationScheme: PropTypes.string }).isRequired,
        groupList: PropTypes.arrayOf(PropTypes.shape({ idGroupAsString: PropTypes.string, name: PropTypes.string }))
            .isRequired,
        isFetching: PropTypes.bool,
        isInvitingNewUser: PropTypes.bool.isRequired,
        isSubmitting: PropTypes.bool.isRequired,
        isValid: PropTypes.bool.isRequired,
        roleList: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, label: PropTypes.string })).isRequired,
        selectedDocument: PropTypes.shape({
            country: PropTypes.string,
            document: PropTypes.string,
            type: PropTypes.string,
        }).isRequired,
        signatureLevels: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, label: PropTypes.string })),
        ...TypesRedux,
    },
    defaults: {
        isFetching: false,
        ...DefaultsRedux,
    },
};

const FORM_ID = "administration.users.invite";
const OTP_TYPE_FIELD = "otpType";

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    renderMediumSchemeConfiguration = () => {
        const { roleList } = this.props;

        const transactionSignatureOptions = [
            { id: YES, label: UtilsI18n.get("administration.users.invite.transaction.signature.yes") },
            { id: NO, label: UtilsI18n.get("administration.users.invite.transaction.signature.no") },
        ];

        return (
            <section>
                <div className="title">
                    <I18n id="administration.users.invite.initial.configuration.subtitle" />
                </div>
                <div className="content">
                    <div className="form-group form-group--select">
                        <div className="form-group-text">
                            <label className="control-label">
                                <I18n id="administration.users.invite.transaction.signature" />
                            </label>
                        </div>
                        <div className="field-spacing signatures-combo">
                            <Field
                                component={Selector}
                                options={transactionSignatureOptions}
                                idForm={FORM_ID}
                                name="signatureLevel"
                                renderAs="radio"
                            />
                        </div>
                    </div>
                    {roleList.length > 0 && (
                        <div className="form-group-text">
                            <label className="control-label">
                                <I18n id="administration.users.invite.roles.label" />
                            </label>
                        </div>
                    )}
                    <div className="field-spacing roles-combo">
                        <Field
                            name="roleGroup"
                            idForm={FORM_ID}
                            render={({ form, field }) => {
                                const error = form ? form.errors.roleGroup : null;
                                return (
                                    <React.Fragment>
                                        {roleList.map(({ id, label }) => (
                                            <IndeterminateCheckbox
                                                id={id}
                                                label={label}
                                                onCheckClick={() => {
                                                    if (field.value.includes(id)) {
                                                        const nextValue = field.value.filter((value) => value !== id);
                                                        form.setFieldValue("roleGroup", nextValue);
                                                    } else {
                                                        const nextValue = field.value.concat(id);
                                                        form.setFieldValue("roleGroup", nextValue);
                                                    }
                                                }}
                                                selectedOptionsAmount={field.value.includes(id)}
                                                value={id}
                                            />
                                        ))}
                                        {error && <FieldError error={error} />}
                                    </React.Fragment>
                                );
                            }}
                        />
                    </div>
                </div>
            </section>
        );
    };

    handleChange = (value) => {
        const { setFieldValue } = this.props;

        setFieldValue(OTP_TYPE_FIELD, value);
    };

    handleBack = () => {
        const { history } = this.props;

        history.goBack();
    };

    renderAdvancedSchemeConfiguration = () => {
        const { errors, groupList, signatureLevels } = this.props;

        const groupOptionsMap = new Map();

        groupList.forEach((group) => {
            groupOptionsMap.set(group.idGroupAsString, group.name);
        });

        const ids = groupList && groupList.map((groups) => groups.idGroup);
        let byId = {};

        for (let index = 0; index < groupList?.length; index += 1) {
            byId = { ...byId, [groupList[index].idGroup]: groupList[index] };
        }

        return (
            <section>
                <div className="title">
                    <I18n id="administration.users.invite.initial.configuration.subtitle" />
                </div>
                <div className="content">
                    <div className="form-group form-group--select">
                        <div className="form-group-text">
                            <label className="control-label">
                                <I18n id="administration.users.edit.signatureLevel" />
                            </label>
                        </div>
                        <div className="signature-level-field">
                            <Field
                                component={Selector}
                                options={signatureLevels}
                                idForm={FORM_ID}
                                name="signatureLevel"
                                renderAs="radio"
                                inLineControl
                                tooltip={UtilsI18n.get(
                                    "administration.advanced.users.invite.multipleStep.signatureLevel.tip",
                                )}
                            />
                        </div>
                    </div>
                    <div className="form-group-text">
                        <label className="control-label">
                            <I18n id="administration.users.edit.groups" />
                        </label>
                    </div>
                    <div className="form--groups groups">
                        <MultiSelect
                            name="groups"
                            label={`${FORM_ID}.members.list.title`}
                            options={{ ids, byId }}
                            labelKey="name"
                            valueKey="idGroup"
                            hidePlaceholder
                            errors={errors}
                            tooltip={UtilsI18n.get("administration.advanced.users.invite.multipleStep.3.status.tip")}>
                            {(info) => (
                                <React.Fragment>
                                    <span className="data-desc">{info?.name}</span>
                                </React.Fragment>
                            )}
                        </MultiSelect>
                    </div>
                </div>
            </section>
        );
    };

    render() {
        const {
            activeEnvironment: { administrationScheme },
            existentUserInfo,
            isFetching,
            isInvitingNewUser,
            selectedDocument,
            values: { email, firstName, lastName, otpType },
            ...rest
        } = this.props;

        if (!selectedDocument) {
            return <Redirect to="/administration/users/userInvite" />;
        }

        const userIsInBTMode =
            existentUserInfo && existentUserInfo.firstName && existentUserInfo.lastName ? MODE.VIEW : MODE.EDIT;
        const inputFieldsViewMode = isInvitingNewUser ? MODE.EDIT : MODE.VIEW;

        const otpTypes = [
            { id: SOFT_OTP, label: UtilsI18n.get("backoffice.invitationCodes.otpType.SOFT") },
            { id: HARD_OTP, label: UtilsI18n.get("backoffice.invitationCodes.otpType.HARD") },
        ];

        return (
            <Container
                head-onBack={this.handleBack}
                head-title="administration.users.invite.title"
                image="administration-title-icon.svg"
                name={NAME}
                scopeToShowNotification={SCOPE.ADMINISTRATION}
                wait={isFetching}>
                <div id={Style.ID}>
                    <Form>
                        <section>
                            <div className="title">
                                <I18n id="administration.users.invite.personal.data.subtitle" />
                            </div>
                            <div className="content">
                                <div className="field-spacing">
                                    <Field
                                        mode={userIsInBTMode}
                                        component={TextField}
                                        idForm={FORM_ID}
                                        name="firstName"
                                        type="text"
                                        value={firstName}
                                        hidePlaceholder
                                        tooltip={UtilsI18n.get("administration.users.invite.firstName.tooltip")}
                                    />
                                </div>
                                <div className="field-spacing">
                                    <Field
                                        mode={userIsInBTMode}
                                        component={TextField}
                                        idForm={FORM_ID}
                                        name="lastName"
                                        type="text"
                                        value={lastName}
                                        hidePlaceholder
                                        tooltip={UtilsI18n.get("administration.users.invite.lastName.tooltip")}
                                    />
                                </div>
                                <div className="field-spacing">
                                    <Field
                                        mode={inputFieldsViewMode}
                                        component={TextField}
                                        idForm={FORM_ID}
                                        name="email"
                                        type="text"
                                        value={!isInvitingNewUser ? email : EMPTY_STR}
                                        hidePlaceholder
                                        tooltip={UtilsI18n.get("administration.users.invite.email.tooltip")}
                                    />
                                </div>
                                {inputFieldsViewMode === MODE.EDIT && (
                                    <div className="field-spacing">
                                        <SelectorType
                                            name="otpType"
                                            options={otpTypes}
                                            label={UtilsI18n.get("backoffice.invitationCodes.create.otpType")}
                                            valueKey="id"
                                            labelKey="label"
                                            onChange={this.handleChange}
                                            {...rest}
                                        />
                                    </div>
                                )}
                                {inputFieldsViewMode === MODE.EDIT && otpType === SOFT_OTP && (
                                    <div className="mobilePhone--field">
                                        <div className="field-spacing" id={StyleField.ID}>
                                            <FieldLabel labelKey="change.PersonalData.mobilePhone.label" />
                                            <Field
                                                idForm={FORM_ID}
                                                name="mobilePhone"
                                                type="text"
                                                className="login-form-input"
                                                component={CountryCellPhoneField}
                                                hideLabel
                                                pattern={ONLY_NUMBER}
                                                hidePlaceholder={false}
                                                autoFocus={false}
                                                valueKey="value"
                                                labelKey="label"
                                                tooltip={UtilsI18n.get(
                                                    "administration.users.invite.mobilePhone.tooltip",
                                                )}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </section>
                        {administrationScheme === TYPE_ADMINISTRATION.MEDIUM
                            ? this.renderMediumSchemeConfiguration()
                            : this.renderAdvancedSchemeConfiguration()}
                        <footer>
                            <Button size="sm" variant="secondary" onClick={this.handleBack}>
                                <I18n id="global.cancel" />
                            </Button>
                            <Button type="submit" size="sm" variant="primary">
                                <I18n id="global.accept" />
                            </Button>
                        </footer>
                    </Form>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    activeEnvironment: StoreSession.getActiveEnvironment(store),
    encryptedDocument: SelectorsStoreUserInvite.getEncryptedDocument(store),
    existentUserInfo: SelectorsStoreUserInvite.getExistentUserInfo(store),
    groupList: SelectorsStoreUserInvite.getGroupList(store),
    isFetching: SelectorsStoreUserInvite.getFetching(store),
    isInvitingNewUser: SelectorsStoreUserInvite.isInvitingNewUser(store),
    selectedDocument: SelectorsStoreUserInvite.getSelectedDocument(store),
    roleList: SelectorsStoreUserInvite.getRoleList(store),
    signatureLevels: UtilsConfig.getArray("administration.signatures.signatureLevels")
        .map((value) => ({
            id: value,
            label: value,
        }))
        .concat({
            id: NO_SIGNATURE,
            label: UtilsI18n.get("administration.users.edit.signatureLevel.dontSign"),
        }),
});

export default Compose(
    Connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => {
            const { existentUserInfo, isInvitingNewUser } = props;
            return {
                email: !isInvitingNewUser && existentUserInfo ? existentUserInfo.email : EMPTY_STR,
                firstName: existentUserInfo ? existentUserInfo.firstName : EMPTY_STR,
                groups: [],
                lastName: existentUserInfo ? existentUserInfo.lastName : EMPTY_STR,
                mobilePhone: !isInvitingNewUser && existentUserInfo ? existentUserInfo.mobilePhone : EMPTY_STR,
                otpType: SOFT_OTP,
                roleGroup: [],
                signatureLevel: EMPTY_STR,
            };
        },
        validationSchema: (props) => {
            const {
                isInvitingNewUser,
                activeEnvironment: { administrationScheme },
            } = props;

            return Yup.lazy((values) => {
                return Yup.object().shape({
                    email: isInvitingNewUser
                        ? Yup.string()
                              .email(UtilsI18n.get("administration.users.emailIncorrectFormat"))
                              .required(UtilsI18n.get("administration.users.emailEmpty"))
                        : Yup.string().notRequired(),
                    firstName: isInvitingNewUser
                        ? Yup.string().required(UtilsI18n.get("administration.users.firstNameEmpty"))
                        : Yup.string().notRequired(),
                    lastName: isInvitingNewUser
                        ? Yup.string().required(UtilsI18n.get("administration.users.lastNameEmpty"))
                        : Yup.string().notRequired(),
                    mobilePhone:
                        // eslint-disable-next-line no-nested-ternary
                        isInvitingNewUser && values.otpType === SOFT_OTP
                            ? Yup.object().shape({
                                  prefix: Yup.string().required(
                                      UtilsI18n.get(`${FORM_ID}.validations.mobilePhone.mustBeAPhoneNumber`),
                                  ),
                                  value: Yup.string().required(
                                      UtilsI18n.get(`${FORM_ID}.validations.mobilePhone.mustBeAPhoneNumber`),
                                  ),
                                  country: Yup.string(),
                              })
                            : typeof values.mobilePhone === "string"
                            ? Yup.string().notRequired()
                            : Yup.object().notRequired(),
                    roleGroup:
                        administrationScheme === TYPE_ADMINISTRATION.MEDIUM
                            ? Yup.array().of(Yup.string()).min(1, UtilsI18n.get("administration.users.emptyRole"))
                            : Yup.array().notRequired(),
                    groups:
                        administrationScheme === TYPE_ADMINISTRATION.ADVANCED
                            ? Yup.array().required(UtilsI18n.get("administration.users.mustSelectGroup"))
                            : Yup.array().notRequired(),
                    signatureLevel: Yup.string().required(UtilsI18n.get("administration.users.emptySignatureLevel")),
                });
            });
        },
        handleSubmit: (data, formikBag) => {
            const {
                activeEnvironment: { administrationScheme },
                dispatch,
                encryptedDocument,
                existentUserInfo,
                groupList,
                isInvitingNewUser,
                selectedDocument,
            } = formikBag.props;

            let { signatureLevel } = data;

            if (administrationScheme === TYPE_ADMINISTRATION.MEDIUM) {
                signatureLevel = data.signatureLevel === NO ? NO_SIGNATURE : DEFAULT_SIGNATURE;
            }

            const groupNames = groupList
                .filter((group) => data.groups.indexOf(group.idGroupAsString) > -1)
                .map((x) => x.name);

            const confirmationParams = {
                ...data,
                docCountry: selectedDocument.country,
                docNumber: selectedDocument.document,
                docType: selectedDocument.type,
                document: encryptedDocument,
                email: isInvitingNewUser ? data.email : existentUserInfo.email,
                firstName: isInvitingNewUser ? data.firstName : existentUserInfo.firstName,
                groupNames,
                lastName: isInvitingNewUser ? data.lastName : existentUserInfo.lastName,
                mobilePhone: isInvitingNewUser ? data.mobilePhone : existentUserInfo.mobilePhone,
                otpType: isInvitingNewUser ? data.otpType : null,
                signatureLevel,
            };

            dispatch(SelectorsActionUserInvite.userInviteConfirm({ confirmationParams, credentials: null, formikBag }));
        },
    }),
)(Component);
