import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    comexDesktopPreRequest: () => API.executeWithAccessToken(PATH.COMEX_DESKTOP_PRE_REQUEST),
    getAmountText: (props) => API.executeWithAccessToken(PATH.GET_AMOUNT_TEXT_REQUEST, props),
    loadBankListRequest: (props) => API.executeWithAccessToken(PATH.LOAD_BANK_LIST_REQUEST, props),
    otherProcedurePreRequest: (props) => API.executeWithAccessToken(PATH.OTHER_PROCEDURES_PRE_REQUEST, props),
    otherProcedurePreviewRequest: (props) => API.executeWithAccessToken(PATH.OTHER_PROCEDURES_PREVIEW_REQUEST, props),
    otherProcedureSendRequest: (props) => API.executeWithAccessToken(PATH.OTHER_PROCEDURES_SEND_REQUEST, props),
};

export const SelectorsAction = {
    changeAllCollapsedElementsCount: (props) => FactoryAction(TYPE.CHANGE_ALL_COLLAPSED_ELEMENTS_COUNT, props),
    changeCollapsedElementsCount: (props) => FactoryAction(TYPE.CHANGE_COLLAPSED_ELEMENTS_COUNT, props),
    comexDesktopPreRequest: () => FactoryAction(TYPE.COMEX_DESKTOP_PRE_REQUEST),
    comexDesktopPreSuccess: (props) => FactoryAction(TYPE.COMEX_DESKTOP_PRE_SUCCESS, props),
    comexDesktopPreFailure: (props) => FactoryAction(TYPE.COMEX_DESKTOP_PRE_FAILURE, props),
    comexFormSendDataFailure: () => FactoryAction(TYPE.COMEX_FORM_SEND_DATA_FAILURE),
    getAmountTextRequest: (props) => FactoryAction(TYPE.GET_AMOUNT_TEXT_REQUEST, props),
    getAmountTextSuccess: (props) => FactoryAction(TYPE.GET_AMOUNT_TEXT_SUCCESS, props),
    getAmountTextFailure: () => FactoryAction(TYPE.GET_AMOUNT_TEXT_FAILURE),
    loadBankListRequest: (props) => FactoryAction(TYPE.LOAD_BANK_LIST_REQUEST, props),
    loadBankListFailure: (props) => FactoryAction(TYPE.LOAD_BANK_LIST_FAILURE, props),
    loadBankListSuccess: (props) => FactoryAction(TYPE.LOAD_BANK_LIST_SUCCESS, props),
    restartFlow: () => FactoryAction(TYPE.RESTART_FLOW),
    searchBankRequest: (props) => FactoryAction(TYPE.SEARCH_BANK_REQUEST, props),
    searchBankFailure: (props) => FactoryAction(TYPE.SEARCH_BANK_FAILURE, props),
    searchBankSuccess: (props) => FactoryAction(TYPE.SEARCH_BANK_SUCCESS, props),
    setSelectedBank: (props) => FactoryAction(TYPE.SET_SELECTED_BANK, props),
    setBankSearchInfo: (props) => FactoryAction(TYPE.SET_BANK_SEARCH_INFO, props),
    toggleCreditLetterList: (props) => FactoryAction(TYPE.TOGGLE_CREDIT_LETTER, props),
    toggleModalBack: () => FactoryAction(TYPE.TOGGLE_MODAL_BACK),
    toStepOne: () => FactoryAction(TYPE.COMEX_FORM_TO_STEP_1),
    toStepThree: () => FactoryAction(TYPE.COMEX_FORM_TO_STEP_3),

    // Other procedure
    otherProcedurePreRequest: () => FactoryAction(TYPE.OTHER_PROCEDURES_PRE_REQUEST),
    otherProcedurePreSuccess: (props) => FactoryAction(TYPE.OTHER_PROCEDURES_PRE_SUCCESS, props),
    otherProcedurePreFailure: () => FactoryAction(TYPE.OTHER_PROCEDURES_PRE_FAILURE),

    otherProcedurePreviewRequest: (props) => FactoryAction(TYPE.OTHER_PROCEDURES_PREVIEW_REQUEST, props),
    otherProcedurePreviewSuccess: (props) => FactoryAction(TYPE.OTHER_PROCEDURES_PREVIEW_SUCCESS, props),
    otherProcedurePreviewFailure: () => FactoryAction(TYPE.OTHER_PROCEDURES_PREVIEW_FAILURE),

    otherProcedureSendRequest: (props) => FactoryAction(TYPE.OTHER_PROCEDURES_SEND_REQUEST, props),
    otherProcedureSendSuccess: (props) => FactoryAction(TYPE.OTHER_PROCEDURES_SEND_SUCCESS, props),
    otherProcedureSendFailure: () => FactoryAction(TYPE.OTHER_PROCEDURES_SEND_FAILURE),

    // tickets and signing
    cancelTransaction: (props) => FactoryAction(TYPE.CANCEL_TRANSACTION_REQUEST, props),
    cancelTransactionCustom: (props) => FactoryAction(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, props),
    closeConfirmation: () => FactoryAction(TYPE.CLOSE_TRANSACTION_PREVIEW),
    readTransactionRequest: (props) => FactoryAction(TYPE.READ_TRANSACTION_REQUEST, props),
    readTransactionSuccess: (props) => FactoryAction(TYPE.READ_TRANSACTION_SUCCESS, props),
    readTransactionFailure: (props) => FactoryAction(TYPE.READ_TRANSACTION_FAILURE, props),
    signTransaction: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_REQUEST, props),
    signTransactionPreview: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_PREVIEW, props),
    signTransactionSuccess: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_SUCCESS, props),
    signTransactionFailure: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_FAILURE, props),
};

export const SelectorsStore = {
    getAmountText: (store) => store[NAME].amountString,
    getBanks: (store) => store[NAME].banks,
    getCollapsedElementsCount: (store) => store[NAME].collapsedElementsCount,
    getFetching: (store) => store[NAME].fetching,
    getMode: (store) => store[NAME].mode,
    getOtherProcedureFormData: (store) => store[NAME].otherProcedure.formData,
    getOtherProcedurePre: (store) => store[NAME].otherProcedure.preData,
    getPositions: (store) => store[NAME].positions,
    getPrevMode: (store) => store[NAME].prevMode,
    getSelectedBank: (store) => store[NAME].selectedBank,
    getShowBankSearch: (store) => store[NAME].showBankSearch,
    getTransactionList: (store) => store[NAME].transactionList,
    getTransaction: (store) => store[NAME].transaction,
    isOperationNumberModalVisible: (store) => store[NAME].operationNumberModalVisible,
};
