import React from "react";

import { Field } from "formik";
import PropTypes from "prop-types";
import ReactSelect from "react-select";

import { EMPTY_STR } from "~/constants";

import FieldError from "~/pages/_components/fields/FieldError";

import Style from "./Selector.rules.scss";

export const NAME = "Select";

export const PROP = {
    types: {
        name: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string,
                label: PropTypes.string,
            }),
        ).isRequired,
        className: PropTypes.string,
        label: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.bool,
        title: PropTypes.string,
        noOption: PropTypes.string,
        searchable: PropTypes.bool,
        nameType: PropTypes.string,
        setFieldValue: PropTypes.func.isRequired,
    },
    defaults: {
        className: "form-group",
        label: EMPTY_STR,
        options: [],
        noOption: EMPTY_STR,
        onChange: null,
        placeholder: null,
        title: EMPTY_STR,
        searchable: false,
        nameType: EMPTY_STR,
    },
};

export function Component(props) {
    const {
        name,
        className,
        label,
        onChange,
        options,
        placeholder,
        title,
        noOption,
        searchable,
        nameType,
        setFieldValue,
        errors,
        ...rest
    } = props;

    const handleChange = () => setFieldValue("loadData", EMPTY_STR);

    return (
        <div id={Style.ID}>
            <Field name={name}>
                {({ field, form }) => (
                    <div title={title || EMPTY_STR} className={`${className} ${errors && errors[name] ? "has-error" : ""}`}>
                        {label && (
                            <div className="form-group-text">
                                <label className="control-label">{label}</label>
                            </div>
                        )}
                        <div className="input-group">
                            <ReactSelect
                                {...field}
                                {...rest}
                                options={options}
                                clearable={false}
                                noResultsText={noOption}
                                searchable={searchable}
                                autosize={false}
                                onChange={(value) => {
                                    if (onChange) {
                                        onChange(value?.id);
                                    }
                                    handleChange();
                                    form.setFieldValue(field.name, value?.id);
                                }}
                                optionClassName="needsclick"
                                placeholder={placeholder || EMPTY_STR}
                            />
                        </div>
                        <FieldError error={form?.errors[name]} />
                    </div>
                )}
            </Field>
        </div>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
