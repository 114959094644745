import React from "react";

import { push as Push } from "connected-react-router";
import { Form, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import ContainerExternal from "~/containers/External/Form";
import ContainerInternal from "~/containers/Internal/Form/Simple";
import { SelectorsAction, SelectorsStore } from "~/store/enrollment";
import {
    SelectorsStore as SelectorsStoreMigration,
    SelectorsAction as SelectorsActionMigration,
} from "~/store/migration";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import DeviceUtils from "~/util/device";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import WithExchangeToken from "~/pages/_components/withExchangeToken";

import CancelConfirmationModal from "~/pages/enrollment/_CancelConfirmationModal";

import Style from "./SecurityTips.rules.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {
        ...TypesRedux,
    },
    defaults: {
        ...DefaultsRedux,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {
        show: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;

        if (DeviceUtils.isMobileNative()) {
            dispatch(SelectorsActionMigration.securityTipsModalTrue());
            // document.addEventListener(EVENT_BACKBUTTON, this.showModal);
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;

        if (DeviceUtils.isMobileNative()) {
            dispatch(SelectorsActionMigration.securityTipsModalFalse());
            // document.removeEventListener(EVENT_BACKBUTTON, this.showModal);
        }
    }

    handleConfirmation = () => {
        const { dispatch, registerBiometricMigration } = this.props;
        if (registerBiometricMigration) {
            dispatch(Push("/desktop"));
        } else {
            dispatch(Push("/"));
        }
    };

    handleCancelation = () => {
        this.setState({ show: false });
    };

    showModal = () => {
        this.setState({ show: true });
    };

    render() {
        const { show } = this.state;
        const { fetching, fetchingMigration, isLoggedIn } = this.props;

        const fetchingPage = fetching ? true : fetchingMigration;

        const Container = ({ ...rest }) =>
            isLoggedIn ? <ContainerInternal {...rest} /> : <ContainerExternal {...rest} />;
        Container.displayName = `${NAME}.Container`;

        return (
            <React.Fragment>
                <CancelConfirmationModal
                    show={show}
                    handleCancelation={this.handleCancelation}
                    handleConfirmation={this.handleConfirmation}
                />
                <Container
                    id={NAME}
                    name={NAME}
                    head-onClose={this.showModal}
                    head-title="enrollment.securityTips.title"
                    wait={fetchingPage}
                    scopeToShowNotification={SCOPE.SECURITY_TIPS}>
                    <div className={`contract ${CLASS}`}>
                        <Form className="contract-form">
                            <div className="contract-content">
                                <div className="contract-block">
                                    <div className="contract-block-content">
                                        <I18n id="enrollment.securityTips.1" />
                                    </div>
                                </div>
                            </div>

                            <div className="contract-footer">
                                <Button
                                    className="contract-button main-button"
                                    bsStyle="primary"
                                    label="global.continue"
                                    loading={false}
                                    type="submit"
                                />
                            </div>
                        </Form>
                    </div>

                    <div className="enrollment-content-dialog">
                        <span className="enrollment-content-dialog-text" />
                    </div>
                </Container>{" "}
            </React.Fragment>
        );
    }
}

export default HighOrder(
    Connect((store) => ({
        exchangeToken: SelectorsStore.exchangeToken(store),
        client: SelectorsStore.client(store),
        registerBiometricMigration: SelectorsStoreMigration.registerBiometricMigration(store),
        idUser: SelectorsStoreMigration.idUser(store),
        fetching: SelectorsStore.fetching(store),
        fetchingMigration: SelectorsStoreMigration.fetching(store),
        isLoggedIn: SelectorsStoreSession.isLoggedIn(store),
    })),
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        handleSubmit: (data, formikBag) => {
            const { dispatch, registerBiometricMigration, idUser } = formikBag.props;
            if (registerBiometricMigration) {
                dispatch(SelectorsActionMigration.securtityTips({ idUser, formikBag }));
            } else {
                dispatch(SelectorsAction.securityTips({ formikBag }));
            }
        },
    }),
    WithExchangeToken,
)(Component);
