import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    getPositionPDF: (order) => API.executeWithAccessToken(PATH.GET_POSITION_PDF, order),
    readPosition: (productTypes) => API.executeWithAccessToken(PATH.GET_POSITION, productTypes),
};

export const SelectorsAction = {
    changeAllCollapsedElementsCount: (props) => FactoryAction(TYPE.CHANGE_ALL_COLLAPSED_ELEMENTS_COUNT, props),
    changeCollapsedElementsCount: (props) => FactoryAction(TYPE.CHANGE_COLLAPSED_ELEMENTS_COUNT, props),
    deletePosition: () => FactoryAction(TYPE.DELETE_POSITION),
    getPositionPDF: (props) => FactoryAction(TYPE.GET_POSITION_PDF, props),
    readPositionRequest: (props) => FactoryAction(TYPE.READ_POSITION_REQUEST, props),
    readPositionFailure: () => FactoryAction(TYPE.READ_POSITION_FAILURE),
    readPositionSuccess: (props) => FactoryAction(TYPE.READ_POSITION_SUCCESS, props),
    showDownloadDateModal: (props) => FactoryAction(TYPE.SHOW_DOWNLOAD_DATE_MODAL, props),
};

export const SelectorsStore = {
    getPositions: (store) => store[NAME].position,
    getCollapsedElementsCount: (store) => store[NAME].collapsedElementsCount,
    getShowDownloadDateModal: (store) => store[NAME].showDownloadDateModal || false,
    getOrder: (store) => store[NAME].order,
    isFetching: (store) => store[NAME].isFetching,
};
