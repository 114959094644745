import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { MODE } from "~/constants/form";
import * as I18n from "~/util/i18n";

import HighOrder from "~/components/HighOrder";
import NumberFormatInput from "~/components/NumberFormatInput";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import WithFocus from "~/pages/_components/withFocus";

export const NAME = "NumberInput";

export const PROP = {
    types: {
        field: PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.any,
            onBlur: PropTypes.func.isRequired,
        }).isRequired,
        form: PropTypes.shape({
            touched: PropTypes.object.isRequired,
            errors: PropTypes.shape({
                environments: PropTypes.shape({
                    environmentType: PropTypes.string,
                    idEnvironment: PropTypes.string,
                    name: PropTypes.string,
                }),
                selectEnvironment: PropTypes.bool,
            }),
            handleChange: PropTypes.func.isRequired,
        }).isRequired,
        maxLength: PropTypes.number,
        inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
        hidelabel: PropTypes.bool,
        hidePlaceholder: PropTypes.bool,
        mode: PropTypes.string,
    },
    defaults: {
        mode: MODE.EDIT,
        maxLength: 50,
        inputRef: React.createRef(),
        hidelabel: false,
        hidePlaceholder: false,
    },
};

export class Component extends React.Component {
    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    static displayName = NAME;

    handleBlur = (event) => {
        const {
            toggleIsFocused,
            field: { onBlur },
        } = this.props;
        onBlur(event);
        toggleIsFocused();
    };

    render() {
        const {
            field,
            form: { touched, errors, handleChange },
            inputRef,
            hidelabel,
            hidePlaceholder,
            idForm,
            toggleIsFocused,
            isFocused,
            mode,
            value,
            ...props
        } = this.props;

        const hasError = touched[field.name] && errors[field.name];

        return (
            <div
                ref={(node) => {
                    this.node = node;
                }}
                className={`form-group ${hasError ? "has-error" : ""} ${isFocused ? "has-focus" : ""}`}>
                {!hidelabel && <FieldLabel labelKey={`${idForm}.${field.name}.label`} />}
                {mode === MODE.EDIT ? (
                    <div className="input-group">
                        <NumberFormatInput
                            {...props}
                            {...field}
                            className="form-control"
                            allowLeadingZeros
                            name={field.name}
                            onFocus={toggleIsFocused}
                            type="tel"
                            idField={field.name}
                            onChange={handleChange}
                            placeholder={hidePlaceholder ? EMPTY_STR : I18n.get(`${idForm}.${field.name}.placeholder`)}
                            onBlur={this.handleBlur}
                            ref={inputRef}
                        />
                    </div>
                ) : (
                    <div>{value}</div>
                )}
                {hasError && <FieldError error={errors[field.name]} />}
            </div>
        );
    }
}

export default HighOrder(HighOrder.Resizable, WithFocus)(Component);
