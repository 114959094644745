import React from "react";

import { Form, Formik } from "formik";
import { connect as Connect } from "react-redux";

import { SPACE_STR, ZERO } from "~/constants";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsAction as SelectorsActionWM } from "~/store/wm/listPapers";
import { capitalizeFirstLetter as CapitalizeFirstLetter } from "~/util/string";

import HighOrder from "~/components/HighOrder";
import Select from "~/components/Select";

import Style from "./PapersFilters.rules.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const {
        activeEnvironment,
        categoriesList,
        dispatch,
        familiesList,
        i18n,
        isChangingEnvironment,
        papersList,
        storeFilters,
    } = props;
    const { riskProfileCode } = activeEnvironment;
    const { fundsLabel, categoriesLabel } = i18n;

    const [categories, setCategories] = React.useState(false);

    const handleResetFilters = React.useCallback(() => {
        // eslint-disable-next-line no-return-assign, no-param-reassign
        document.querySelectorAll("[name=family]").forEach((x) => (x.checked = false));

        dispatch(SelectorsActionWM.resetFilters({ riskProfileCode }));
    }, [dispatch, riskProfileCode]);

    React.useEffect(() => {
        if (!isChangingEnvironment && papersList?.length === 0) {
            handleResetFilters();
        }
    }, [handleResetFilters, isChangingEnvironment, papersList]);

    React.useEffect(() => {
        let filteredList = categoriesList?.filter((category) => {
            return category.value !== "PORTAFOLIO";
        });

        if (riskProfileCode === ZERO) {
            filteredList = filteredList?.filter((category) => {
                return category.value !== "DESTACADOS";
            });
        }

        setCategories(filteredList);
    }, [categoriesList, riskProfileCode]);

    const familyOptions = [];

    familiesList.forEach((family) => {
        familyOptions.push({
            value: family.value,
            label:
                family.label !== i18n.allFundsLabel
                    ? family.label
                          .toLowerCase()
                          .split(SPACE_STR)
                          .map((name) => CapitalizeFirstLetter(name))
                          .join(SPACE_STR)
                    : family.label,
        });
    });

    const handleFamilyChange = (family) => {
        const filters = { ...storeFilters, family };

        dispatch(SelectorsActionWM.updateFilters(filters));
    };

    const handleCategoryChange = (category) => {
        const filters = { ...storeFilters, category };

        dispatch(SelectorsActionWM.updateFilters(filters));
    };

    return (
        <Formik initialValues={storeFilters}>
            <Form className={CLASS}>
                <div className="container-filter">
                    <div className="basic-filter" onClick={(event) => event.stopPropagation()}>
                        <Select
                            className="select-filter"
                            clearable={false}
                            labelKey="label"
                            onChange={(category) => handleCategoryChange(category.value)}
                            options={categories}
                            valueKey="value"
                            value={storeFilters.category}
                            name="category"
                            label={categoriesLabel}
                        />
                        <Select
                            className="select-filter"
                            clearable={false}
                            labelKey="label"
                            onChange={(family) => handleFamilyChange(family.value)}
                            options={familyOptions}
                            valueKey="value"
                            value={storeFilters.family}
                            name="family"
                            label={fundsLabel}
                        />
                    </div>
                </div>
            </Form>
        </Formik>
    );
}

const mapStateToProps = (store) => ({
    i18n: {
        allFundsLabel: StoreI18n.getMessage(store, "wm.investment.all.funds.label"),
        categoriesLabel: StoreI18n.getMessage(store, "wm.investment.categories.label"),
        cleanFiltersLabel: StoreI18n.getMessage(store, "product.filters.cleanFilters"),
        filterLabel: StoreI18n.getMessage(store, "product.filters.filter"),
        fundsLabel: StoreI18n.getMessage(store, "wm.investment.funds.label"),
    },
});

export default HighOrder(Connect(mapStateToProps))(Component);
