import React from "react";

import { Formik, Form, Field } from "formik";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

import { EMPTY_STR, SCOPE, TYPE_ADMINISTRATION } from "~/constants";
import Container from "~/containers/Internal/Administration/Simple";
import { getInteger as GetInteger } from "~/util/config";
import * as i18nUtils from "~/util/i18n";
import { capitalizeFirstLetter } from "~/util/string";

import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";
import ConfirmationModal from "~/pages/_components/ConfirmationModal";
import AmountField from "~/pages/_components/fields/formik/AmountField";

import AdministrationHeading from "~/pages/administration/_components/AdministrationHeading";
import PermissionsForm from "~/pages/administration/_components/PermissionsForm";
import SelectorType from "~/pages/settings/changePersonalInformation/field/SelectorType";

import Style from "./Permissions.rules.scss";

const groupShape = {
    idItem: PropTypes.string,
    label: PropTypes.string,
    ordinal: PropTypes.number,
};

groupShape.childrenList = PropTypes.arrayOf(PropTypes.shape(groupShape)).isRequired;

export const NAME = "Permissions";

export const PROP = {
    types: {
        actions: PropTypes.shape({
            loadPermissionsRequest: PropTypes.func.isRequired,
            updatePermissionsPreview: PropTypes.func.isRequired,
        }).isRequired,
        confirmRoute: PropTypes.element.isRequired,
        groups: PropTypes.arrayOf(
            PropTypes.shape({
                idItem: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
                ordinal: PropTypes.number.isRequired,
            }),
        ).isRequired,
        isChangingEnvironment: PropTypes.bool.isRequired,
        isFetching: PropTypes.bool.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({ id: PropTypes.string.isRequired }),
            url: PropTypes.string.isRequired,
        }).isRequired,
        permissions: PropTypes.shape({}),
        products: PropTypes.arrayOf(PropTypes.shape(groupShape)).isRequired,
        routerActions: PropTypes.shape({
            goBack: PropTypes.func.isRequired,
        }).isRequired,
    },
    defaults: {
        capFrequencyList: [],
        isChangingEnvironment: false,
        permissions: null,
    },
};

const ID_FORM = "administration.permissions";

export function Component(props) {
    const {
        actions,
        capFrequencyList,
        caps = {},
        credentials,
        fetching,
        formActions,
        groups,
        idActivity,
        idTransaction,
        isChangingEnvironment,
        match,
        permissions,
        routerActions,
        user,
    } = props;
    const isLoading = fetching && !groups.length;

    React.useLayoutEffect(() => {
        if (!isChangingEnvironment) {
            actions.loadPermissionsRequest({ id: match.params.id });
        }
    }, [actions, isChangingEnvironment, match.params.id]);

    const [showModal, setShowModal] = React.useState(false);

    const handleModal = React.useCallback(() => {
        setShowModal(!showModal);
    }, [showModal]);

    const amountMedium = (params) => {
        const { values } = params;

        const cap = caps.maximum < 0 ? null : caps.maximum;

        return (
            <div className="form-group form-group--composite topAmountSection" id="topAmount">
                <h4 className="form-title">
                    <I18n id="administration.permissions.maxAmount.label" />
                </h4>
                <I18n id="administration.permissions.maxAmount.description" />
                <div>
                    <div className="topAmountValue">
                        <Field
                            clearable={false}
                            component={AmountField}
                            idForm={ID_FORM}
                            name="maxAmount"
                            placeholder={EMPTY_STR}
                            searchable={false}
                            decimalPlaces={2}
                            fixedDecimalScale
                            value={cap}
                            hideCurrency
                            hideLabel
                            maxLength={GetInteger("amount.length")}
                        />
                        <I18n id="currency.label.2222" />
                    </div>
                    <div className="capFrequency">
                        <I18n id="administration.signatures.transactions.capFrequency.label" />
                        <SelectorType
                            labelKey="label"
                            className="form-group select-small ellipsis-text big-height"
                            options={capFrequencyList?.map((capFrequency) => ({
                                label: capitalizeFirstLetter(
                                    i18nUtils.get(
                                        `administration.signatures.transactions.capFrequency.${capFrequency}`,
                                    ),
                                ),
                                value: capFrequency,
                            }))}
                            valueKey="value"
                            name="period"
                            value={values && values.period}
                            classNameContent="selector--content"
                        />
                    </div>
                </div>
            </div>
        );
    };

    const handleSubmit = (params) => {
        const { values } = params;
        const { maxAmount, period, ...rest } = values;

        handleModal();

        if (!idTransaction) {
            actions.updatePermissionsRequest({
                data: {
                    capFrequencies: period,
                    credentials,
                    idUser: match.params.id,
                    maxAmount: maxAmount.amount === EMPTY_STR ? null : maxAmount.amount,
                    permissions: rest,
                    status: user.idUserStatus,
                },
                formikBag: params,
            });
        } else {
            const paramsSign = { idActivity, idForm: null, idTransaction };

            formActions.signTransaction({ ...paramsSign, formikBag: params });
        }
    };

    function RenderContent(params) {
        const { isSubmitting } = params;
        const { activeEnvironment } = props;

        return (
            <div id={Style.ID}>
                <Form>
                    <ConfirmationModal
                        show={showModal}
                        handleCancel={handleModal}
                        idLabel="administration.user.modify.modal.title"
                        handleAcept={() => handleSubmit(params)}
                    />
                    <section>
                        <div>
                            <header>
                                <AdministrationHeading />
                            </header>
                            <div className="list">
                                <h4 className="form-title">
                                    <I18n id="administration.permissions.functionalities" />
                                </h4>
                                <PermissionsForm {...params} />
                            </div>
                            <div className="footer-description">
                                <div>
                                    <I18n id="administration.permissions.pay.maxAmount.description" />
                                </div>
                                <div>
                                    <I18n id="administration.permissions.preferentialTradingPrice.description" />
                                </div>
                            </div>
                            {activeEnvironment?.administrationScheme !== TYPE_ADMINISTRATION.SIMPLE &&
                                amountMedium(params)}
                        </div>
                    </section>
                    <section className="list--permissions-button">
                        <div>
                            <Button
                                type="button"
                                size="sm"
                                variant="primary"
                                loading={isSubmitting}
                                onClick={handleModal}>
                                <I18n id="administration.permissions.modify" />
                            </Button>
                        </div>
                    </section>
                </Form>
            </div>
        );
    }

    const cap = caps.maximum < 0 ? EMPTY_STR : caps.maximum;

    return (
        <React.Fragment>
            <Formik
                initialValues={{ ...permissions, maxAmount: { amount: cap }, period: caps.frequency }}
                onSubmit={handleSubmit}
                enableReinitialize={isLoading}>
                {(prop) => (
                    <Switch>
                        <Route path={match.url} exact>
                            <Container
                                name={NAME}
                                wait={fetching}
                                head-title="administration.permissions.configurePermissions"
                                head-onBack={routerActions.goBack}
                                image="administration-title-icon.svg"
                                scopeToShowNotification={SCOPE.ADMINISTRATION}>
                                {!isLoading && <RenderContent {...prop} />}
                            </Container>
                        </Route>
                    </Switch>
                )}
            </Formik>
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
