import React from "react";

import Bounty from "bounty";

import * as UtilsI18n from "~/util/i18n";

import Style from "./FormWaiter.rules.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export const MAX = 999999;
export const DURATION = 3000;
export const LAG = DURATION * 0.1;
export const CSS_SPACING = 10; // pixels
export const CSS_HEIGHT = 1; // decimal percentage

export class Component extends React.Component {
    static displayName = Style.NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    ref = React.createRef();

    interval = null;

    value = {};

    count = 0;

    componentDidMount() {
        this.setVal();
        this.animate();
        this.interval = setInterval(this.animate, DURATION + LAG);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    static rndpad = (max = MAX) =>
        String(Math.round(Math.random() * parseInt(max, 10)) + 1).padStart(String(max).length, "0");

    animate = () => {
        const { current: el } = this.ref;
        const { next, prev } = this.value;
        // NOTE: letterSpacing should always be written in pixels, if you're from the
        //       future and want to change this, checkout: https://github.com/mikolalysenko/to-px
        //       for conversion between units.
        const styles = getComputedStyle(el);
        const letterSpacing = parseInt(styles.letterSpacing, 10) || CSS_SPACING;
        const lineHeight = parseInt(styles.lineHeight, 10) / parseInt(styles.fontSize, 10) || CSS_HEIGHT;
        Bounty({
            el,
            letterSpacing,
            lineHeight,
            initialValue: prev,
            value: next,
            animationDelay: 0,
            duration: DURATION,
        });
        this.setVal();
    };

    setVal = () => {
        this.value = {
            prev: this.value.next || Component.rndpad(),
            next: Component.rndpad(),
        };
    };

    render() {
        return (
            <section className={Style.CLASS}>
                <div className={`${Style.CLASS}-container`}>
                    <p className={`${Style.CLASS}-title`}>{UtilsI18n.get("form.waiter.title")}</p>
                    <div ref={this.ref} className={`${Style.CLASS}-tokenizer`} />
                </div>
            </section>
        );
    }
}

export default Component;
