/* global document */
import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";

import Button from "~/components/Button";

export const NAME = "Dropdown";

export const PROP = {
    types: {
        /**
         * Align the menu to the right side of the Dropdown
         */
        fetching: PropTypes.bool,
        buttonClass: PropTypes.string,
        label: PropTypes.string,
        image: PropTypes.string,
    },
    defaults: { fetching: false, buttonClass: EMPTY_STR, label: EMPTY_STR, image: EMPTY_STR },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        isOpen: false,
    };

    node = React.createRef();

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick, false);
    }

    handleClick = ({ target }) => {
        const { isOpen } = this.state;
        if (!this.node.current.contains(target) && isOpen) {
            this.toggleOpen();
        }
    };

    toggleOpen = () => {
        this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    };

    render() {
        const { image, fetching, buttonClass, label } = this.props;

        return (
            <div className="dropdown" ref={this.node}>
                <Button
                    image={image}
                    loading={fetching}
                    onClick={this.toggleOpen}
                    block={false}
                    className={buttonClass}
                    label={label}
                />
            </div>
        );
    }
}

export default Component;
