import { SelectorsAction as SelectorsActionChannels } from "~/store/administration/common/channels";
import { SelectorsAction as SelectorsActionDetails } from "~/store/administration/common/details";
import {
    SelectorsAction as SelectorsActionPermissions,
    SelectorsStore as SelectorsStorePermissions,
} from "~/store/administration/common/permissions";
import { SelectorsAction as SelectorsActionSignatures } from "~/store/administration/common/signatures";
import * as API from "~/store/api";

import {
    TYPE_ADMINISTRATION_LEVEL_MEDIUM_CHANNELS,
    TYPE_ADMINISTRATION_LEVEL_MEDIUM_DETAILS,
    TYPE_ADMINISTRATION_LEVEL_MEDIUM_PERMISSION,
    TYPE_ADMINISTRATION_LEVEL_MEDIUM_SIGNATURE,
    PATH,
} from "./_consts";

export const SelectorsMiddleware = {
    loadDetailsRequest: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_MEDIUM_USER_DETAILS_READ, props), // id

    loadPermissionsRequest: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_MEDIUM_READ_PERMISSIONS, props), // id

    updatePermissionsPreview: (props) =>
        API.executeWithAccessToken(PATH.ADMINISTRATION_MEDIUM_MODIFY_PERMISSIONS_PREVIEW, props), // data

    updatePermissionsRequest: (props) =>
        API.executeWithAccessToken(PATH.ADMINISTRATION_MEDIUM_MODIFY_PERMISSIONS_SEND, props), // data

    loadChannelsRequest: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_MEDIUM_READ_CHANNELS, props), // id

    updateChannelsPreview: (props) =>
        API.executeWithAccessToken(PATH.ADMINISTRATION_MEDIUM_MODIFY_CHANNELS_PREVIEW, props), // data

    updateChannelsRequest: (props) =>
        API.executeWithAccessToken(PATH.ADMINISTRATION_MEDIUM_MODIFY_CHANNELS_SEND, props), // data

    createSignaturesScheme: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_SIGNATURES_CREATE_SEND, props), // data

    createSignaturesSchemePre: () => API.executeWithAccessToken(PATH.ADMINISTRATION_SIGNATURES_CREATE_PRE),

    listSignaturesSchemes: (props) => {
        const { pageNumber = 1, orderBy = "id_signature ASC" } = props || {};
        return API.executeWithAccessToken(PATH.ADMINISTRATION_SIGNATURES_LIST, {
            pageNumber,
            orderBy,
        });
    },

    deleteSignaturesScheme: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_SIGNATURES_DELETE_SEND, props), // data

    deleteSignaturesSchemePre: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_SIGNATURES_DELETE_PRE, props), // id

    modifySignaturesScheme: (props) =>
        API.executeWithAccessToken(PATH.ADMINISTRATION_SIGNATURES_MEDIUM_MODIFY_SEND, props), // data

    modifySignaturesSchemePre: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_SIGNATURES_MODIFY_PRE, props), // id

    updateSignatureRequest: (props) =>
        API.executeWithAccessToken(PATH.ADMINISTRATION_MEDIUM_MODIFY_SIGNATURE_SEND, props), // data

    exportSignatureSchemes: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_SIGNATURES_EXPORT, props), // data
};

export const SelectorsActionDetailsMedium = { ...SelectorsActionDetails(TYPE_ADMINISTRATION_LEVEL_MEDIUM_DETAILS) };
export const SelectorsActionPermissionsMedium = {
    ...SelectorsActionPermissions(TYPE_ADMINISTRATION_LEVEL_MEDIUM_PERMISSION),
};
export const SelectorsActionChannelsMedium = { ...SelectorsActionChannels(TYPE_ADMINISTRATION_LEVEL_MEDIUM_CHANNELS) };
export const SelectorsActionSignaturesMedium = {
    ...SelectorsActionSignatures(TYPE_ADMINISTRATION_LEVEL_MEDIUM_SIGNATURE),
};
export const SelectorsStore = { ...SelectorsStorePermissions };
