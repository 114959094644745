import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, TYPE, PATH } from "./_consts";

export const SelectorsMiddleware = {
    userInviteConfirm: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_USER_INVITE_SEND, props),
    userInvitePreRequest: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_USER_INVITE_PRE_REQUEST, props),
    userInviteVerify: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_USER_INVITE_VERIFY, props),
};

export const SelectorsAction = {
    userInviteConfirm: (props) => FactoryAction(TYPE.ADMINISTRATION_USER_INVITE_SEND, props),
    userInviteConfirmFailure: (props) => FactoryAction(TYPE.ADMINISTRATION_USER_INVITE_SEND_FAILURE, props),
    userInviteFinish: () => FactoryAction(TYPE.ADMINISTRATION_USER_INVITE_FINISH),
    userInvitePre: () => FactoryAction(TYPE.ADMINISTRATION_USER_INVITE_PRE_REQUEST),
    userInvitePreFailure: () => FactoryAction(TYPE.ADMINISTRATION_USER_INVITE_PRE_FAILURE),
    userInvitePreSuccess: (props) => FactoryAction(TYPE.ADMINISTRATION_USER_INVITE_PRE_REQUEST_SUCCESS, props),
    userInviteVerify: (props) => FactoryAction(TYPE.ADMINISTRATION_USER_INVITE_VERIFY, props),
    userInviteVerifyFailure: (props) => FactoryAction(TYPE.ADMINISTRATION_USER_INVITE_VERIFY_FAILURE, props),
    userInviteVerifySuccess: (props) => FactoryAction(TYPE.ADMINISTRATION_USER_INVITE_VERIFY_SUCCESS, props),
};

export const SelectorsStore = {
    getConfirmationParams: (store) => store[NAME].confirmationParams,
    getCountryList: (store) => store[NAME].countryList,
    getCredentialGroups: (store) => store[NAME].credentialGroups,
    getDefaultCountry: (store) => store[NAME].defaultCountry,
    getDefaultDocumentType: (store) => store[NAME].defaultDocumentType,
    getDocumentTypeList: (store) => store[NAME].documentTypeList,
    getEncryptedDocument: (store) => store[NAME].encryptedDocument,
    getExistentUserInfo: (store) => store[NAME].existentUserInfo,
    getFetching: (store) => store[NAME].fetching,
    getGroupList: (store) => store[NAME].groupList,
    getIdActivity: (store) => store[NAME].idActivity,
    getIdTransaction: (store) => store[NAME].idTransaction,
    getRoleList: (store) => store[NAME].roleList,
    getSelectedDocument: (store) => store[NAME].selectedDocument,
    isInvitingNewUser: (store) => store[NAME].isInvitingNewUser,
};
