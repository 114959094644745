import React from "react";

import { connect as Connect } from "react-redux";

import { SelectorsStore as SelectorsStoreForm, PROP as PropForm } from "~/store/form";
import { ToRequired } from "~/util/prop";

import I18n from "~/components/I18n";

export const NAME = "RequestCreditCard";

export const PROP = {
    types: {
        ...ToRequired(PropForm.types, ["previewData"]),
    },
    defaults: {
        ...PropForm.defaults,
    },
};
export function Component(props) {
    const {
        previewData: {
            idRequestCreditCard,
            idCreditCardType,
            idContactType,
            idPhone,
            contactEmail,
            idContactTime,
            idAdditional,
            idName,
        },
    } = props;

    return (
        <React.Fragment>
            <p className="text-lead">
                <I18n id="forms.requestCreditCard.confirmation.title" />
            </p>

            <div className="transfer-block">
                <div className="transfer-data transfer-data-flow">
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="forms.requestCreditCard.confirmation.idRequestCreditCard" />
                        </span>
                        <span className="data-name">
                            <I18n id={`forms.requestCreditCard.confirmation.idCreditCardType.${idRequestCreditCard}`} />
                        </span>
                    </div>
                </div>
            </div>

            {idCreditCardType && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.requestCreditCard.confirmation.idCreditCardType" />
                    </span>
                    <span className="data-text">{idCreditCardType}</span>
                </div>
            )}
            {idContactType && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.requestCreditCard.confirmation.idContactType" />
                    </span>
                    <span className="data-text">{idContactType}</span>
                </div>
            )}
            {idPhone && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.requestCreditCard.confirmation.idPhone" />
                    </span>
                    <span className="data-text">{idPhone}</span>
                </div>
            )}
            {contactEmail && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.requestCreditCard.confirmation.idEmail" />
                    </span>
                    <span className="data-text">{contactEmail}</span>
                </div>
            )}
            {idContactTime && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.requestCreditCard.confirmation.idContactTime" />
                    </span>
                    <span className="data-text">{idContactTime}</span>
                </div>
            )}
            {idAdditional && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.requestCreditCard.confirmation.idAdditional" />
                    </span>
                    <span className="data-text">{idAdditional}</span>
                </div>
            )}
            {idName && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.requestCreditCard.confirmation.idName" />
                    </span>
                    <span className="data-text">{idName}</span>
                </div>
            )}
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (state) => ({
    name: SelectorsStoreForm.getName(state),
    previewData: SelectorsStoreForm.getPreviewData(state),
});

export default Connect(mapStateToProps)(Component);
