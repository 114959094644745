import React from "react";

import { push as Push } from "connected-react-router";
import { connect as Connect } from "react-redux";
import { Route, useHistory, matchPath } from "react-router-dom";

import { EMPTY_STR } from "~/constants";
import { SelectorsStore as StoreSession } from "~/store/session";

export const NAME = "RouteAdministration";

export const PROP = {
    types: {},
    defaults: {},
};
export function Component(props) {
    const { activeEnvironment, typeAdmin, path, dispatch } = props;
    const {
        location: { pathname },
    } = useHistory();

    const { administrationScheme = EMPTY_STR } = activeEnvironment;

    const matchPaths = matchPath(pathname, {
        path,
    });
    if (matchPaths) {
        if (administrationScheme === typeAdmin) {
            return <Route {...props} />;
        }
        dispatch(Push("/desktop"));
    }

    return null;
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;
const mapStateToProps = (store) => ({
    activeEnvironment: StoreSession.getActiveEnvironment(store) || {},
});
export default Connect(mapStateToProps)(Component);
