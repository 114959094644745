import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";

import { EMPTY_STR, ONLY_NUMBER, SPACE_STR, LEVEL, SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { SelectorsAction as SelectorsActionComex, SelectorsStore as SelectorsStoreComex } from "~/store/comex";
import {
    SelectorsAction as SelectorsActionComexImport,
    PROP as PropComex,
    SelectorsStore as SelectorsStoreComexImport,
} from "~/store/comex/import";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsAction as SelectorActionNotification } from "~/store/notification";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionTemplate } from "~/store/template";
import * as ConfigUtil from "~/util/config";
import { formatDate as FormatDate, toDate as ToDate } from "~/util/date";
import { isEmptyObj } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import EmailList from "~/pages/_components/fields/EmailList";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TextArea from "~/pages/_components/fields/TextArea";
import TextField from "~/pages/_components/fields/TextField";

import OperationNumber from "~/pages/comEx/_components/OperationNumber";
import OperationNumberDetail from "~/pages/comEx/_components/OperationNumberDetail";
import CreateTemplateModal from "~/pages/forms/Step1Edit/_ModalTemplateCreate";
import ListTemplatesModal from "~/pages/forms/Step1Edit/_ModalTemplateList";
import { Termsandconditions } from "~/pages/forms/_components/_fields";
import Scheduler from "~/pages/forms/_components/_fields/Scheduler";
import Checkbox from "~/pages/forms/_components/_fields/_commons/Checkbox";
import DocumentField from "~/pages/settings/changePersonalInformation/field/DocumentField";

import Style from "../../../Step1Edit.rules.scss";

const FORM_ID = "comex.import.liftCreditLetterWithDiscrepancies";

const ID_ACTIVITY = "comex.import.liftCreditLetterWithDiscrepancies.send";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...PropComex.types,
    },
    defaults: {
        ...PropComex.defaults,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        draftLoaded: false,
        loadOperationNumber: false,
    };

    componentDidMount() {
        const { dispatch, formData } = this.props;
        if (isEmptyObj(formData)) {
            dispatch(SelectorsActionComexImport.liftCreditLetterDiscrepanciesPreRequest());
        } else {
            this.handleBlur();
        }
    }

    componentDidUpdate() {
        const {
            dispatch,
            formData,
            setFieldError,
            setFieldValue,
            setFieldTouched,
            setValues,
            transaction,
            values,
            match,
            preData,
        } = this.props;
        const { operationNumber } = match.params;
        const { availableOperationList } = preData;
        const { draftLoaded, loadOperationNumber } = this.state;

        if (!loadOperationNumber && availableOperationList.length > 0 && !values.operationNumber && operationNumber) {
            const operationSelected = availableOperationList.filter(
                (item) => item.othersFields.NROOPERACION === operationNumber,
            );
            this.setState({
                loadOperationNumber: true,
            });

            if (operationSelected.length > 0) {
                setFieldValue("item", operationSelected[0]);
                setFieldValue("operationNumber", operationNumber);
            }
        }
        if (transaction?.data && Object.keys(formData).length === 0 && preData.nextValidDate && !draftLoaded) {
            this.setState({
                draftLoaded: true,
            });
            const transactionData = transaction?.data;
            /* carga de errores */
            if (transactionData.backendFormErrors?.cdpErrorFields) {
                const { cdpErrorFields } = transactionData.backendFormErrors;
                cdpErrorFields.map((errorField) => {
                    if (errorField === "valueDate") {
                        setFieldError("scheduler", SPACE_STR);
                        setFieldTouched("scheduler", true);
                        transactionData.scheduler = {
                            editing: true,
                            valueDate: transactionData?.valueDate,
                            selectedOption: transactionData?.selectedOption,
                        };
                    } else {
                        setFieldError(errorField, SPACE_STR);
                        setFieldTouched(errorField, true);
                    }

                    return null;
                });
            }
            if (transactionData.backendFormErrors?.message) {
                const errorMessage = transactionData?.backendFormErrors?.message;
                dispatch(
                    SelectorActionNotification.showNotification({
                        message: errorMessage,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.COMEX_IMPORT_LIFT_CREDIT_LETTER_DISCREPANCIES],
                    }),
                );
            } // modificaciones para que agarren bien los fields
            if (transactionData.valueDate) {
                const valueDate = ToDate(transactionData.valueDate);
                transactionData.valueDate = valueDate;
            }
            setValues(transaction.data);
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(GoBack());
    };

    handleFormKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            event.preventDefault();
        }
    };

    handleModalLoad = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionComex.toggleCreditLetterList());
    };

    handleBlur = () => {
        const { values, preData, setFieldValue } = this.props;
        const { availableOperationList } = preData;
        if (values.operationNumber) {
            const operationSelected = availableOperationList.filter(
                (item) => item.othersFields.NROOPERACION === values.operationNumber,
            );
            if (operationSelected.length > 0) {
                setFieldValue("item", operationSelected[0]);
            } else {
                setFieldValue("item", null);
                setFieldValue("operationNumber", EMPTY_STR);
            }
        } else {
            setFieldValue("item", null);
            setFieldValue("operationNumber", EMPTY_STR);
        }
    };

    handleSelectChangeFieldName = (id, fieldName) => {
        const { setFieldValue } = this.props;
        setFieldValue(fieldName, id);
    };

    handleItemSelect = (item) => {
        const { setFieldValue } = this.props;

        const { othersFields } = item;
        if (othersFields) {
            setFieldValue("operationNumber", othersFields.NROOPERACION);
            setFieldValue("item", item);
        } else {
            setFieldValue("item", null);
            setFieldValue("operationNumber", EMPTY_STR);
        }
    };

    // TEMPLATE AND DRAFT
    handleCancel = () => {
        const { dispatch, setSubmitting, transaction } = this.props;

        dispatch(
            SelectorsActionForm.cancelTransactionCustom({
                credentials: {},
                idTransaction: transaction.idTransaction,
                formikBag: { setSubmitting },
            }),
        );
    };

    handleDraft = () => {
        const { dispatch, values, transaction } = this.props;

        dispatch(
            SelectorsActionForm.saveDraft({
                idActivityDraft: ID_ACTIVITY,
                idTransactionToSave: transaction?.idTransaction,
                transactionData: values || {},
            }),
        );
    };

    handleTemplateSave = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionTemplate.createTemplate());
    };

    handleTemplateLoad = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionTemplate.toggleTemplateList());
    };

    handleSelectTemplate = (template) => {
        const { setValues, setErrors } = this.props;
        const templateToBeLoaded = template;
        if (templateToBeLoaded.scheduler.valueDate) {
            const valueDate = ToDate(templateToBeLoaded.scheduler.valueDate);
            templateToBeLoaded.scheduler.valueDate = valueDate;
        }
        setValues(templateToBeLoaded);
        setErrors({});
    };

    isValidProductType = (item) => {
        const { productTypeBantotal } = item;

        return productTypeBantotal === 88 || productTypeBantotal === 92;
    };

    render() {
        const { values, emailValidationRegex, preData, transaction } = this.props;

        const { countryList, documentTypeList, nextValidDate, unavailableDays, availableOperationList } = preData;
        const termsAndConditions = "comex.import.liftCreditLetterWithDiscrepancies.disclaimer";
        const i18nMap = {
            termsAndConditions,
        };
        const cancelEnabled = transaction && transaction.idTransactionStatus === STATUS.DRAFT;
        const othersFields = values.item ? values.item.othersFields : null;
        const noOption = UtilsI18n.get("selector.noOption.text");

        const showAuthorizeDebit = values.item ? this.isValidProductType(values.item) : true;
        return (
            <React.Fragment>
                <Form
                    className="col col-12 col-lg-6 col-md-9 col-sm-12"
                    id={Style.ID}
                    noValidate="novalidate"
                    onKeyDown={this.handleFormKeyDown}>
                    <div className="form-section loadTemplates">
                        <Button
                            onClick={this.handleTemplateLoad}
                            label="forms.templates.load"
                            bsStyle="default"
                            image="template.svg"
                        />
                    </div>
                    <section className="fields container--layout align-items-center flex-grow">
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.operation`)}</h3>
                        </Box>

                        <Box flex align="end" className="form-group search-field">
                            <Field
                                idForm={FORM_ID}
                                name="operationNumber"
                                hidePlaceholder
                                pattern={ONLY_NUMBER}
                                type="text"
                                component={TextField}
                                onBlur={this.handleBlur}
                                tooltip={UtilsI18n.get(
                                    "comex.import.liftCreditLetterWithDiscrepancies.operationNumber.tooltip",
                                )}
                            />

                            <Box className="ml-3 mb-2">
                                <Button
                                    onClick={this.handleModalLoad}
                                    label="global.search"
                                    bsStyle="primary"
                                    className="search"
                                />
                            </Box>
                        </Box>
                        {values.item && <OperationNumberDetail othersFields={othersFields} idForm={FORM_ID} />}

                        {showAuthorizeDebit && (
                            <Box className="form-group checkbox-container">
                                <FieldLabel labelKey={`${FORM_ID}.authorization.label`} />
                                <Checkbox
                                    checked={values.authorizeDebit}
                                    label={UtilsI18n.get(`${FORM_ID}.authorization.detail`)}
                                    name="authorizeDebit"
                                    onChange={() =>
                                        this.handleSelectChangeFieldName(!values.authorizeDebit, "authorizeDebit")
                                    }
                                />
                            </Box>
                        )}

                        <Field
                            component={TextArea}
                            idForm={FORM_ID}
                            name="observations"
                            mode={MODE.EDIT}
                            className="form-control"
                            maxLength={500}
                            hidePlaceholder
                            optional={UtilsI18n.get("form.field.optional")}
                        />
                        <Box className="form-group document-field">
                            <Field
                                idForm={FORM_ID}
                                name="authorizedDocument"
                                component={DocumentField}
                                data={{
                                    countries: countryList,
                                    documentTypes: documentTypeList,
                                }}
                                searchable
                                noOption={noOption}
                                inputProps={{ spellCheck: "false" }}
                                handleError
                                autoFocus={false}
                                hidePlaceholder
                                hideDocumentPlaceholder
                                tooltip={UtilsI18n.get("client.comex.generic.documentNumber.tooltip")}
                            />
                        </Box>

                        <Field
                            idForm={FORM_ID}
                            name="name"
                            hidePlaceholder
                            type="text"
                            component={TextField}
                            maxLength={140}
                            tooltip={UtilsI18n.get(`${FORM_ID}.name.tooltip`)}
                        />

                        <Field
                            component={EmailList}
                            idForm={FORM_ID}
                            name="notificationEmails"
                            hideSwiftTip
                            className="form-group email-field"
                            renderSuggestion={false}
                            data={{ emailValidationRegex, addMessage: EMPTY_STR }}
                            value={values.notificationEmails}
                            tooltip={UtilsI18n.get(`${FORM_ID}.notificationEmails.tooltip`)}
                        />
                        {nextValidDate && (
                            <Field
                                component={Scheduler}
                                data={{
                                    firstWorkingDate: nextValidDate,
                                    maxDaysToSchedule: ConfigUtil.get("client.comex.valueDate.maxDaysToSchedule"),
                                    nonWorkingDays: unavailableDays,
                                    lang: "es",
                                    mode: "edit",
                                    programable: false,
                                    schedulable: true,
                                }}
                                name="scheduler"
                                idForm={FORM_ID}
                            />
                        )}
                        <hr />
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.letterPreview`)}</h3>
                        </Box>

                        <Field
                            component={Termsandconditions}
                            idForm={FORM_ID}
                            name="disclaimer"
                            mode={MODE.EDIT}
                            className="form-control"
                            i18nMap={i18nMap}
                        />
                    </section>
                    <footer>
                        <Button
                            onClick={this.handleTemplateSave}
                            className="templateSave"
                            label="forms.saveTemplate.link"
                        />
                        {cancelEnabled && <Button onClick={this.handleCancel} label="forms.cancelTransaction.link" />}
                        <Button
                            key="primaryButton"
                            variant="primary"
                            bsStyle="primary"
                            label="global.next"
                            loading={false}
                            type="submit"
                        />
                    </footer>
                </Form>
                <ListTemplatesModal idActivityTemplate={ID_ACTIVITY} onSelect={this.handleSelectTemplate} />
                <CreateTemplateModal values={values} idActivityTemplate={ID_ACTIVITY} />
                <OperationNumber
                    list={availableOperationList}
                    idForm={FORM_ID}
                    handleItemClick={this.handleItemSelect}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        preData: SelectorsStoreComexImport.getLiftCreditLetterDiscrepanciesPre(store),
        formData: SelectorsStoreComexImport.getLiftCreditLetterDiscrepanciesFormData(store),
        prevData: SelectorsStoreComexImport.getLiftCreditLetterDiscrepanciesPrevData(store),
        transaction: SelectorsStoreComex.getTransaction(store),
        emailValidationRegex: SelectorsStoreConfig.getConfig(store)["email.validationFormat"],
        activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
        environments: SelectorsStoreSession.getEnvironments(store),
    };
};

export default HighOrder(
    WithRouter,
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ formData, prevData }) => {
            return {
                scheduler: formData?.valueDate
                    ? {
                          editing: true,
                          valueDate: formData?.valueDate,
                          selectedOption: formData?.selectedOption,
                      }
                    : EMPTY_STR,
                operationNumber: formData?.operationNumber || EMPTY_STR,
                authorizeDebit: formData?.authorizeDebit || prevData?.authorizeDebit,
                observations: formData?.observations || EMPTY_STR,
                documentTypeLabel: formData?.documentTypeLabel || "C.I.",
                authorizedDocument: {
                    country: formData?.idCountry || "858",
                    type: formData?.documentType || "12",
                    document: formData?.documentNumber || EMPTY_STR,
                },
                name: formData?.name || EMPTY_STR,
                notificationEmails: formData?.notificationEmails || EMPTY_STR,
                productType: formData?.productType || EMPTY_STR,
            };
        },
        handleSubmit: (props, formikBag) => {
            const {
                dispatch,
                activeEnvironment,
                environments,
                preData: { documentTypeList },
            } = formikBag.props;
            const { productGroupId } = environments[activeEnvironment.id];
            const { item, scheduler, authorizedDocument, ...rest } = props;
            const beneficiary =
                item?.othersFields?.BENEFICIARIO === EMPTY_STR || item?.othersFields?.BENEFICIARIO === SPACE_STR
                    ? "N/A"
                    : item?.othersFields?.BENEFICIARIO;
            const valueDateString = scheduler ? FormatDate(scheduler.valueDate) : EMPTY_STR;
            const operationNumberString = rest.operationNumber ? rest.operationNumber : EMPTY_STR;
            const subOperationNumber = item?.othersFields?.SUBOPE;
            const invoiceAmount = item?.othersFields?.IMPORTE;
            const currency = item?.othersFields?.MONEDA;
            const amount = { quantity: invoiceAmount, currency };
            const productType = item?.productTypeBantotal;
            const { id_document_label: documentTypeLabel } = documentTypeList.find(
                ({ id_country_code, id_document_type }) =>
                    id_country_code === authorizedDocument.country && id_document_type === authorizedDocument.type,
            );
            const formData = {
                ...rest,
                idCountry: authorizedDocument.country,
                documentType: authorizedDocument.type,
                documentNumber: authorizedDocument.document,
                operationNumber: operationNumberString,
                documentTypeLabel,
                valueDate: valueDateString,
                beneficiary,
                selectedOption: scheduler?.selectedOption,
                subOperationNumber,
                invoiceAmount,
                amount,
                currency,
                productType,
                item,
                productGroupId,
                scheduler,
            };
            dispatch(
                SelectorsActionComexImport.liftCreditLetterDiscrepanciesPreviewRequest({
                    formData,
                    formikBag,
                }),
            );
        },
    }),
)(Component);
