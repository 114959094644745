import { takeLatest as TakeLatest, put as Put, call as Call } from "redux-saga/effects";

import { LEVEL, SCOPE, RESPONSE_TYPE } from "~/constants";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import DeviceUtils from "~/util/device";
import { downloadPdf } from "~/util/download";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsMiddleware } from "./_selectors";

export default [TakeLatest(TYPE.GET_PAPER_REQUEST, paperDetailDownloadRequest)];

function* paperDetailDownloadRequest(props) {
    const { idPaper, productType, detailType } = props;
    const params = { idPaper, productType, detailType };

    const response = yield Call(SelectorsMiddleware.paperDetailRequest, params);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.paperDetailRequestFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.BUY_SHARES, SCOPE.BUY_BONDS],
            }),
        );
    } else {
        const { content, fileName } = response.data.data;

        if (DeviceUtils.isMobileNative()) {
            try {
                yield Put(SelectorsAction.paperDetailRequestSuccess());
                yield Call(window.pdf.save, response.data.data);
            } catch (error) {
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: response.data.message,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.BUY_SHARES, SCOPE.BUY_BONDS],
                    }),
                );
            }
        } else {
            downloadPdf(fileName, content);
            yield Put(SelectorsAction.paperDetailRequestSuccess());
        }
    }
}
