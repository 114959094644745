import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/Steps";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionSettings, SelectorsStore as SelectorsStoreSettings } from "~/store/settings";

import Ticket from "~/components/Ticket/Ticket";

import ChangeDataAccountsContent from "./ChangeDataAccountsContent";

export const NAME = "ChangeDataAccountsTicket";

export const PROP = {
    types: {
        isChangingEnvironment: PropTypes.bool,
    },
    defaults: {
        isChangingEnvironment: false,
    },
};

export function Component(props) {
    const { dispatch, fetching, isChangingEnvironment, match, mode, transaction } = props;
    const { idTransaction } = match.params;

    React.useEffect(() => {
        if (!isChangingEnvironment) {
            dispatch(SelectorsActionSettings.readChangeTransactionDataRequest({ idTransaction }));
        }
    }, [dispatch, idTransaction, isChangingEnvironment]);

    function handleSign() {
        dispatch(SelectorsActionSettings.signTransactionPreview({ transaction }));
    }

    function handleReject(formikBag) {
        const { idTransaction: idTransactionData } = transaction;

        dispatch(
            SelectorsActionSettings.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction: idTransactionData,
                formikBag,
            }),
        );
    }

    const handleBack = () => {
        const { history } = props;

        history.goBack();
    };

    return (
        <Container
            head-onBack={handleBack}
            head-title={transaction.activityName}
            hideSteps
            name={NAME}
            scopeToShowNotification={SCOPE.PERSONAL_INFORMATION}
            step={mode}
            wait={fetching}>
            <Ticket
                {...props}
                transaction={transaction}
                hasMobileComponent
                handleReject={handleReject}
                handleSign={handleSign}>
                {transaction && <ChangeDataAccountsContent />}
            </Ticket>
        </Container>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    fetching: SelectorsStoreSettings.getFetching(store),
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    mode: SelectorsStoreSettings.getMode(store),
    transaction: SelectorsStoreSettings.getChangeTransactionData(store),
});

export default Connect(mapStateToProps)(Component);
