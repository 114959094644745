import { routerActions as RouterActions } from "connected-react-router";
import { call as Call, put as Put, takeLatest as TakeLatest, takeEvery as TakeEvery } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE } from "~/constants";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { adjustIdFieldErrors } from "~/util/form";
import * as I18nUtils from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsMiddleware } from "./_selectors";

const sagas = [
    TakeLatest(TYPE.LOAD_LIST_REQUEST, loadListRequest),
    TakeEvery(TYPE.SEARCH_BANK_REQUEST, searchBankRequest),
];

export default sagas;

function* loadListRequest(props) {
    const { filters, currentUrl, setSubmitting, navigationAction, setErrors } = props;
    const response = yield Call(SelectorsMiddleware.loadListRequest, filters);
    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.loadListFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: I18nUtils.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.FORM],
            }),
        );
        setErrors(adjustIdFieldErrors(response.data.data));
        setSubmitting(false);
    } else if (!response.data.data.banks.length) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: I18nUtils.get("forms.bankselector.noRecordsFound"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.FORM],
            }),
        );

        setSubmitting(false);
    } else {
        const { data } = response.data;
        yield Put(SelectorsAction.loadListSuccess({ data }));

        yield Put(RouterActions[navigationAction](`${currentUrl}/results`));
    }
}

function* searchBankRequest(props) {
    const { filters, setErrors, setValue, values, idField, fromTemplate } = props;
    const { type, code } = filters;
    if (code) {
        const {
            type: typeResp,
            data: {
                data: { code: bank_code, banks },
            },
        } = yield Call(SelectorsMiddleware.loadListRequest, filters);
        if (typeResp === RESPONSE_TYPE.WARNING) {
            if (!fromTemplate) {
                if (bank_code) {
                    setErrors({ [idField]: bank_code });
                } else {
                    yield Put(
                        SelectorsActionNotification.showNotification({
                            message: I18nUtils.get("global.unexpectedError"),
                            level: LEVEL.ERROR,
                            scopes: [SCOPE.FORM],
                        }),
                    );
                }
            }

            yield Put(SelectorsAction.searchBankFailure({ idField }));
            // If the form is being open from a template we dont want to show errors
            return undefined;
        }
        if (!banks.length || banks[0].code !== code) {
            if (!fromTemplate) {
                setErrors({ [idField]: I18nUtils.get("forms.bankselector.noRecordsFound") });
            }
            yield Put(SelectorsAction.searchBankFailure({ idField }));
        } else {
            const bank = banks[0];
            yield Put(SelectorsAction.searchBankSuccess());
            yield Put(SelectorsAction.bankSelected({ bank, idField }));
            if (!fromTemplate) {
                const { bankCountryLabel, bankName } = bank;
                const newValues = values;
                newValues[idField] = { type, code, bank: { bankCountryLabel, bankName } };
                yield Put(
                    SelectorsActionForm.setData({
                        newValues,
                    }),
                );
                setValue({ type, code, bank: { bankCountryLabel, bankName } });
            }
        }
    } else {
        yield Put(SelectorsAction.searchBankFailure({ idField }));
    }
    return undefined;
}
