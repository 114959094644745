import React from "react";

import { push, routerActions as RouterActions } from "connected-react-router";
import { Formik, Field, Form } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { bindActionCreators } from "redux";

import { COUNTRY_TO_CURRENCY, EMPTY_STR, NO_SIGNATURE, SCOPE, TYPE_ADMINISTRATION } from "~/constants";
import Container from "~/containers/Internal/Administration/Simple";
import { SelectorsActionDetailsAdvanced } from "~/store/administration/advanced";
import { SelectorsStore as SelectorsStoreGroup } from "~/store/administration/advanced/group";
import { SelectorsStore as SelectorsStoreChannels } from "~/store/administration/common/channels";
import { SelectorsStore } from "~/store/administration/common/details";
import { SelectorsStore as SelectorsStoreUser } from "~/store/administration/users/users";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsStore as StoreSession } from "~/store/session";
import * as configUtils from "~/util/config";
import * as i18nUtils from "~/util/i18n";
import { getUser } from "~/util/store/administration";

import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";
import ConfirmationModal from "~/pages/_components/ConfirmationModal";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import AmountField from "~/pages/_components/fields/formik/AmountField";
import MultiSelect from "~/pages/_components/fields/formik/MultiSelect";
import Selector from "~/pages/_components/fields/formik/Selector";

import SelectorType from "~/pages/settings/changePersonalInformation/field/SelectorType";

import Style from "./SignatureConfirmForm.rules.scss";

const FORM_ID = "administration.advanced.signature.modify";

export const NAME = "UserConfigAdminForm";

export const PROP = {
    types: {
        actions: PropTypes.objectOf(PropTypes.func).isRequired,
        credentialGroups: PropTypes.arrayOf(
            PropTypes.shape({ idCredentialGroup: PropTypes.string, credentials: PropTypes.arrayOf(PropTypes.string) }),
        ).isRequired,
        fetching: PropTypes.bool,
        formActions: PropTypes.objectOf(PropTypes.func).isRequired,
        hasSignatureEnabled: PropTypes.bool,
        idActivity: PropTypes.string,
        idTransaction: PropTypes.string,
        match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string.isRequired }) }).isRequired,
        routerActions: PropTypes.shape({ goBack: PropTypes.func }),
        schemeName: PropTypes.string.isRequired,
        signatureLevel: PropTypes.string,
        signatureLevels: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, label: PropTypes.string })),
        user: PropTypes.shape({ firstName: PropTypes.string, lastName: PropTypes.string }).isRequired,
    },
    defaults: {
        fetching: false,
        hasSignatureEnabled: false,
        idActivity: null,
        idTransaction: null,
        routerActions: null,
        signatureLevel: EMPTY_STR,
        signatureLevels: [],
    },
};

export function Component(props) {
    const {
        actions,
        activeEnvironment: { administrationScheme },
        activeUser,
        adminGroupsIds,
        availableGroups,
        enabledChannelsFrequencies,
        formActions,
        idActivity,
        idTransaction,
        isFetching,
        match,
        Push,
        routerActions,
        schemeName,
        selectedGroups,
        signatureLevels,
        topAmount: { frequency, maximum },
        user,
        userInfo,
    } = props;

    const idUser = match.params.id;
    const isAdvanced = administrationScheme === TYPE_ADMINISTRATION.ADVANCED;

    React.useEffect(() => {
        if (idUser && isAdvanced) {
            actions.loadFormRequest({ id: idUser });
        } else {
            routerActions.replace("/administration/users");
        }
    }, [actions, isAdvanced, idUser, routerActions]);

    const [showModal, setShowModal] = React.useState(false);

    const handleModal = React.useCallback(() => {
        setShowModal(!showModal);
    }, [showModal]);

    const ids = availableGroups && availableGroups.map((groups) => groups.idGroup);
    const selectedIdGroups = selectedGroups?.map((selected) => selected.idGroup);

    let byId = {};
    for (let index = 0; index < availableGroups?.length; index += 1) {
        byId = { ...byId, [availableGroups[index].idGroup]: availableGroups[index] };
    }

    const isAdminGroup = (group) => {
        return (
            (adminGroupsIds && !adminGroupsIds.some((adminGroupId) => adminGroupId === group.idGroup.toString())) ||
            false
        );
    };

    const RenderFormContent = () => {
        if (schemeName === TYPE_ADMINISTRATION.ADVANCED) {
            const masterCurrency = configUtils.get("core.masterCurrency");
            const masterCurrencyLabel = i18nUtils.get(`currency.label.${masterCurrency}`);

            return (
                <React.Fragment>
                    <div className="form--userconfig">
                        <div className="field--signature">
                            <FieldLabel labelKey="form.signatureLevel" />

                            <Field
                                component={Selector}
                                options={signatureLevels}
                                idForm={FORM_ID}
                                name="signatureLevel"
                                value={userInfo?.signatureLevel || NO_SIGNATURE}
                                renderAs="radio"
                                inLineControl
                                tooltip={i18nUtils.get(
                                    "administration.advanced.users.invite.multipleStep.signatureLevel.tip",
                                )}
                                disabled={activeUser?.userId === idUser}
                            />

                            <Button
                                variant="link"
                                onClick={() => Push("/administration/advanced/signaturesSchemes/create")}>
                                {i18nUtils.get("administration.signatures.new.schema")}
                            </Button>
                        </div>
                        <div className="form-group-wrapper form-group-wrapper-inline">
                            <Field
                                idForm="administration"
                                name="topAmount"
                                component={AmountField}
                                maxLength={configUtils.getInteger("amount.length")}
                                data={{ options: [{ label: COUNTRY_TO_CURRENCY.COUNTRY_TO_CURRENCY }] }}
                                clearable={false}
                            />
                            <SelectorType
                                name="frequency"
                                className="form-group select-small ellipsis-text big-height"
                                label={`${masterCurrencyLabel} ${i18nUtils.get(
                                    "administration.channels.topAmount.frequency",
                                )}`}
                                options={enabledChannelsFrequencies}
                                classNameContent="selector--content"
                            />
                        </div>

                        <div className="form--groups">
                            <FieldLabel labelKey="administration.groups.label" />
                            <MultiSelect
                                optionalCondition={isAdminGroup}
                                label={`${FORM_ID}.members.list.title`}
                                labelKey="name"
                                name="groups"
                                options={{ ids, byId }}
                                placeholder={`${FORM_ID}.users.placeholder`}
                                tooltip={i18nUtils.get(
                                    "administration.advanced.users.modify.multipleStep.addGroups.tip",
                                )}
                                valueKey="idGroup">
                                {(info) => (
                                    <React.Fragment>
                                        <span className="data-desc">{info?.name}</span>
                                    </React.Fragment>
                                )}
                            </MultiSelect>
                            <Button variant="link" onClick={() => Push("/administration/advanced/createGroup/step1")}>
                                {i18nUtils.get("administration.advanced.group.create.link.text")}
                            </Button>
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        return null;
    };

    const formatSubmitSignatureValue = (sigLevel) => {
        return sigLevel === NO_SIGNATURE ? null : sigLevel;
    };

    const handleSubmit = (data, formikBag) => {
        const userGroups = availableGroups.filter((group) => data.groups.includes(group?.idGroup));

        if (!idTransaction && match?.params?.id) {
            handleModal(); // close modal

            actions.updateSignatureRequest({
                data: {
                    idUser,
                    signatureLevel: formatSubmitSignatureValue(data.signatureLevel),
                    maxAmounts: data.topAmount.amount,
                    groups: data.groups,
                    capFrequencies: data.frequency || [],
                    userGroups,
                    usernameLabel: user?.usernameLabel,
                },
                formikBag,
            });
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };

            formActions.signTransaction({ ...paramsSign, formikBag });
        }
    };

    const handleBack = () => {
        routerActions.replace("/administration/users");
    };

    const userNameLastname = ` ${user?.firstName || EMPTY_STR} ${user?.lastName || EMPTY_STR}`;
    const userIdLabel = ` (${user?.usernameLabel || EMPTY_STR})`;

    return (
        <React.Fragment>
            <Container
                name={NAME}
                wait={isFetching || !byId}
                head-title="administration.user.configure"
                head-onBack={handleBack}
                image="administration-title-icon.svg"
                scopeToShowNotification={SCOPE.ADMINISTRATION}>
                <div id={Style.ID}>
                    <div className="container-element">
                        <div className="container-element__center">
                            <span className="data-label">{i18nUtils.get("administration.permissions.user")}</span>
                            <span className="data-info">{userNameLastname}</span>
                            <span className="data-info">{userIdLabel}</span>
                        </div>
                    </div>
                    <div className="above-the-fold">
                        <div className="subtitle">
                            <I18n id="administration.user.configure" />
                        </div>
                        {availableGroups?.length > 0 && (
                            <Formik
                                initialValues={{
                                    signatureLevel: userInfo?.signatureLevel || NO_SIGNATURE,
                                    frequency,
                                    topAmount: { amount: maximum },
                                    groups: selectedIdGroups,
                                }}
                                enableReinitialize
                                onSubmit={handleSubmit}>
                                {({ isSubmitting, submitForm }) => (
                                    <Form className="form-content">
                                        <ConfirmationModal
                                            show={showModal}
                                            handleCancel={handleModal}
                                            idLabel="administration.modal.label"
                                            handleAcept={submitForm}
                                        />
                                        <RenderFormContent />
                                        <div>
                                            <div className="buttons-container">
                                                <Button
                                                    type="button"
                                                    size="sm"
                                                    variant="secondary"
                                                    loading={isSubmitting}
                                                    onClick={handleBack}>
                                                    <I18n id="global.cancel" />
                                                </Button>
                                                <Button
                                                    type="button"
                                                    size="sm"
                                                    variant="primary"
                                                    loading={isSubmitting}
                                                    onClick={handleModal}>
                                                    <I18n id="administration.advanced.group.create.button.confirm.text" />
                                                </Button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        )}
                    </div>
                </div>
            </Container>
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    activeEnvironment: StoreSession.getActiveEnvironment(store),
    activeUser: StoreSession.getUser(store),
    availableGroups: SelectorsStoreGroup.getAvailableGroups(store),
    adminGroupsIds: SelectorsStore.getAdminGroupsIds(store),
    credentialGroups: SelectorsStore.getCredentialGroups(store),
    currencies: SelectorsStoreChannels.getCurrencies(store),
    enabledChannelsFrequencies: SelectorsStoreChannels.getEnabledChannelsFrequencies(store),
    idActivity: SelectorsStore.getIdActivity(store),
    idTransaction: SelectorsStore.getIdTransaction(store),
    isFetching: SelectorsStore.isFetching(store),
    selectedGroups: SelectorsStoreGroup.getSelectedGroups(store),
    signatureLevels: configUtils
        .getArray("administration.signatures.signatureLevels")
        .map((value) => ({ id: value, label: value }))
        .concat({ id: NO_SIGNATURE, label: i18nUtils.get("administration.users.edit.signatureLevel.dontSign") }),
    signatureLevel: SelectorsStore.getSignatureLevel(store),
    topAmount: SelectorsStoreChannels.getTopAmount(store) || {},
    user: getUser(store),
    userInfo: SelectorsStoreUser.getUserInfo(store, getUser(store)),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(SelectorsActionDetailsAdvanced, dispatch),
    formActions: bindActionCreators(SelectorsActionForm, dispatch),
    routerActions: bindActionCreators(RouterActions, dispatch),
    Push: bindActionCreators(push, dispatch),
    schemeName: TYPE_ADMINISTRATION.ADVANCED,
});

export default Connect(mapStateToProps, mapDispatchToProps)(Component);
