import { replace as Replace } from "connected-react-router";
import { spawn as Spawn, put as Put, all as All, call as Call, delay as Delay } from "redux-saga/effects";

import {
    NO_CREDITCARDS_ENABLED_FOR_PAY,
    NO_RESPONSE_RECEIVED,
    RESPONSE_TYPE,
    SPACE_STR,
    SYSTEM_UNAVAILABLE,
    USER_DISABLED,
    USER_NEEDS_CONNECTED_PARTIES,
} from "~/constants";
import { preventFocusBackground } from "~/containers/_Main";
import { NAME as NAME_ACCOUNTS, Sagas as SagasAccounts } from "~/store/account";
import {
    NAME as NAME_ADMINISTRATION_GROUPS,
    Sagas as SagasAdministrationGroups,
} from "~/store/administration/advanced";
import {
    NAME as NAME_ADMINISTRATION_TICKET,
    Sagas as SagasAdministrationTicket,
} from "~/store/administration/common/administrationTicket";
import { NAME as NAME_GROUPS, Sagas as SagasGroups } from "~/store/administration/groups";
import { NAME as NAME_MEDIUM, Sagas as SagasMedium } from "~/store/administration/medium";
import { NAME as NAME_SIMPLE, Sagas as SagasSimple } from "~/store/administration/simple";
import {
    NAME as NAME_ADMINISTRATION_USERS,
    Sagas as SagasAdministrationUsers,
} from "~/store/administration/users/users";
import {
    NAME as NAME_ADMINISTRATION_USERS_INVITE,
    Sagas as SagasAdministrationUsersInvite,
} from "~/store/administration/users/usersInvite";
import { NAME as NAME_APP, Sagas as SagasApp, TYPE as TYPES_APP } from "~/store/app";
import { NAME as NAME_AUTOMATIC_DEBITS, Sagas as SagasAutomaticDebits } from "~/store/automaticDebits";
import { NAME as NAME_BACKOFFICE, Sagas as SagasBackoffice } from "~/store/backoffice";
import { NAME as NAME_BANKSELECTOR, Sagas as SagasBankSelector } from "~/store/bankSelector";
import { NAME as NAME_BIOMETRIC, Sagas as SagasBiometric } from "~/store/biometric";
import { NAME as NAME_CAMPAIGNS, Sagas as SagasCampaigns } from "~/store/campaigns";
import { NAME as NAME_CHANGEMYPHONE, Sagas as SagasChangeMyPhone } from "~/store/changeMyPhone";
import { NAME as NAME_COMEX, Sagas as SagasComex } from "~/store/comex";
import {
    NAME as NAME_COMEX_BONDS_AND_GUARANTEES,
    Sagas as SagasComexBondsAndGuarantees,
} from "~/store/comex/bondsAndGuarantees";
import { NAME as NAME_COMEX_EXPORT, Sagas as SagasComexExport } from "~/store/comex/export";
import { NAME as NAME_COMEX_IMPORT, Sagas as SagasComexImport } from "~/store/comex/import";
import { NAME as NAME_COMMUNICATIONS, Sagas as SagasCommunications } from "~/store/communications";
import { NAME as NAME_CONFIG, Sagas as SagasConfig } from "~/store/config";
import { NAME as NAME_CONNECTED_PARTIES, Sagas as SagasConnectedParties } from "~/store/connectedParties";
import { TYPE as CONNECTED_PARTIES_TYPES } from "~/store/connectedParties/_consts";
import { NAME as NAME_CREDITCARD, Sagas as SagasCreditCard } from "~/store/creditCards/creditCard";
import {
    NAME as NAME_CREDITCARDSPURCHASENOTIFICATION,
    Sagas as SagasCreditCardsPurchaseNotification,
} from "~/store/creditCards/purchaseNotification";
import { NAME as NAME_CUSTOMS_PAYMENT, Sagas as SagasCustomsPayment } from "~/store/customsPayment";
import { NAME as NAME_DEBITCARD, Sagas as SagasDebitCard } from "~/store/debitCards";
import { NAME as NAME_DESKTOP, Sagas as SagasDesktop } from "~/store/desktop";
import {
    NAME as NAME_ENROLLMENT,
    Sagas as SagasEnrollment,
    SelectorsAction as SelectorActionEnrollment,
} from "~/store/enrollment";
import { NAME as NAME_EXTERNAL_PAYMENTS, Sagas as SagasExternalPayments } from "~/store/externalPayments";
import { NAME as NAME_FACTORING, Sagas as SagasFactoring } from "~/store/factoring";
import {
    NAME as NAME_FACTORING_ADVANCE_PAYMENTS,
    Sagas as SagasFactoringAdvancePayments,
} from "~/store/factoring/advancePayments";
import {
    NAME as NAME_FACTORING_UPLOAD_PAYMENT_ORDERS,
    Sagas as SagasFactoringUploadPaymentOrders,
} from "~/store/factoring/uploadPaymentOrders";
import {
    NAME as NAME_FACTORING_UPLOAD_SUPPLIERS,
    Sagas as SagasFactoringUploadSuppliers,
} from "~/store/factoring/uploadSuppliers";
import { NAME as NAME_FILES, Sagas as SagasFiles } from "~/store/files";
import { NAME as NAME_FILES_DOWNLOAD, Sagas as SagasFilesDownload } from "~/store/filesDownload";
import { NAME as NAME_FINGERPRINT, Sagas as SagasFingerPrint } from "~/store/fingerprint";
import { NAME as NAME_FORM, Sagas as SagasForm } from "~/store/form";
import { NAME as NAME_GENERALCONDITIONS, Sagas as SagasGeneralConditions } from "~/store/generalConditions";
import { NAME as NAME_I18N, Sagas as SagasI18n } from "~/store/i18n";
import { NAME as NAME_INVESTMENT, Sagas as SagasInvestment } from "~/store/investment";
import { NAME as NAME_LOAN, Sagas as SagasLoan } from "~/store/loan";
import { NAME as NAME_LOANREQUEST, Sagas as SagasLoanRequest } from "~/store/loanRequest";
import { NAME as NAME_LOCATIONS, Sagas as SagasLocations } from "~/store/locations";
import { NAME as NAME_LOGIN, Sagas as SagasLogin, SelectorsAction as SelectorsActionLogin } from "~/store/login";
import { NAME as NAME_MIGRATION, Sagas as SagasMigration } from "~/store/migration";
import { NAME as NAME_MULTILINE_FILE, Sagas as SagasMultilineFile } from "~/store/multilinefile";
import { NAME as NAME_POSITION, Sagas as SagasPosition } from "~/store/position";
import {
    NAME as NAME_PREFERENTIALTRADINGPRICE,
    Sagas as SagasPreferentialTradingPrice,
} from "~/store/preferentialTradingPrice";
import { NAME as NAME_PRODUCTS, Sagas as SagasProducts } from "~/store/products";
import { NAME as NAME_RECOVERYPASSWORD, Sagas as SagasRecoveryPassword } from "~/store/recoverypassword";
import {
    NAME as NAME_RECOVERY_PASSWORD_NO_TOKEN,
    Sagas as SagasRecoveryPasswordNoToken,
} from "~/store/recoverypassword/noToken";
import { NAME as NAME_SERVICE_PAYMENT, Sagas as SagasServicePayment } from "~/store/servicePayments";
import {
    SelectorsAction as SelectorsActionSession,
    NAME as NAME_SESSION,
    Sagas as SagasSession,
} from "~/store/session";
import { TYPE as SESSION_TYPES } from "~/store/session/_consts";
import { NAME as NAME_SETTINGS, Sagas as SagasSettings } from "~/store/settings";
import { NAME as NAME_SHARE, Sagas as SagasShare } from "~/store/share";
import { NAME as NAME_STATUS, Sagas as SagasStatus } from "~/store/status";
import { NAME as NAME_SWIFT, Sagas as SagasSwift } from "~/store/swift";
import { NAME as NAME_TEMPLATE, Sagas as SagasTemplate } from "~/store/template";
import { NAME as NAME_TRANSACTION_LINES, Sagas as SagasTransactionLines } from "~/store/transactionLines";
import { NAME as NAME_TRANSACTIONS, Sagas as SagasTransactions } from "~/store/transactions";
import { NAME as NAME_TRANSACTIONS_HISTORY, Sagas as SagasTransactionsHistory } from "~/store/transactionsHistory";
import { NAME as NAME_TRANSFERS, Sagas as SagasTransfers } from "~/store/transfers";
import { NAME as NAME_WIDGET, Sagas as SagasWidget } from "~/store/widget";
import { NAME as NAME_WM, Sagas as SagasWM } from "~/store/wm";
import { NAME as NAME_WM_BONDS, Sagas as SagasWMBonds } from "~/store/wm/bonds";
import { NAME as NAME_WM_BUY_FUNDS, Sagas as SagasWMBuyFunds } from "~/store/wm/funds/buy";
import { NAME as NAME_WM_SELL_FUNDS, Sagas as SagasWMSellFunds } from "~/store/wm/funds/sell";
import { NAME as NAME_WM_SWITCH_PAPERS, Sagas as SagasWMSwitchFunds } from "~/store/wm/funds/switch";
import { NAME as NAME_WM_LIST_PAPERS, Sagas as SagasWMListPapers } from "~/store/wm/listPapers";
import { NAME as NAME_WM_SHARES, Sagas as SagasWMShares } from "~/store/wm/shares";
import Logger from "~/util/logger";

const SAGAS = {
    [NAME_ACCOUNTS]: SagasAccounts,
    [NAME_ADMINISTRATION_GROUPS]: SagasAdministrationGroups,
    [NAME_ADMINISTRATION_TICKET]: SagasAdministrationTicket,
    [NAME_ADMINISTRATION_USERS_INVITE]: SagasAdministrationUsersInvite,
    [NAME_ADMINISTRATION_USERS]: SagasAdministrationUsers,
    [NAME_APP]: SagasApp,
    [NAME_AUTOMATIC_DEBITS]: SagasAutomaticDebits,
    [NAME_BACKOFFICE]: SagasBackoffice,
    [NAME_BANKSELECTOR]: SagasBankSelector,
    [NAME_BIOMETRIC]: SagasBiometric,
    [NAME_CAMPAIGNS]: SagasCampaigns,
    [NAME_CHANGEMYPHONE]: SagasChangeMyPhone,
    [NAME_COMEX_BONDS_AND_GUARANTEES]: SagasComexBondsAndGuarantees,
    [NAME_COMEX_EXPORT]: SagasComexExport,
    [NAME_COMEX_IMPORT]: SagasComexImport,
    [NAME_COMEX]: SagasComex,
    [NAME_COMMUNICATIONS]: SagasCommunications,
    [NAME_CONFIG]: SagasConfig,
    [NAME_CONNECTED_PARTIES]: SagasConnectedParties,
    [NAME_CREDITCARD]: SagasCreditCard,
    [NAME_CREDITCARDSPURCHASENOTIFICATION]: SagasCreditCardsPurchaseNotification,
    [NAME_CUSTOMS_PAYMENT]: SagasCustomsPayment,
    [NAME_DEBITCARD]: SagasDebitCard,
    [NAME_DESKTOP]: SagasDesktop,
    [NAME_ENROLLMENT]: SagasEnrollment,
    [NAME_EXTERNAL_PAYMENTS]: SagasExternalPayments,
    [NAME_FACTORING_ADVANCE_PAYMENTS]: SagasFactoringAdvancePayments,
    [NAME_FACTORING]: SagasFactoring,
    [NAME_FACTORING_UPLOAD_PAYMENT_ORDERS]: SagasFactoringUploadPaymentOrders,
    [NAME_FACTORING_UPLOAD_SUPPLIERS]: SagasFactoringUploadSuppliers,
    [NAME_FILES_DOWNLOAD]: SagasFilesDownload,
    [NAME_FILES]: SagasFiles,
    [NAME_FINGERPRINT]: SagasFingerPrint,
    [NAME_FORM]: SagasForm,
    [NAME_GENERALCONDITIONS]: SagasGeneralConditions,
    [NAME_GROUPS]: SagasGroups,
    [NAME_I18N]: SagasI18n,
    [NAME_INVESTMENT]: SagasInvestment,
    [NAME_LOANREQUEST]: SagasLoanRequest,
    [NAME_LOAN]: SagasLoan,
    [NAME_LOCATIONS]: SagasLocations,
    [NAME_LOGIN]: SagasLogin,
    [NAME_MEDIUM]: SagasMedium,
    [NAME_MIGRATION]: SagasMigration,
    [NAME_MULTILINE_FILE]: SagasMultilineFile,
    [NAME_POSITION]: SagasPosition,
    [NAME_PREFERENTIALTRADINGPRICE]: SagasPreferentialTradingPrice,
    [NAME_PRODUCTS]: SagasProducts,
    [NAME_RECOVERYPASSWORD]: SagasRecoveryPassword,
    [NAME_RECOVERY_PASSWORD_NO_TOKEN]: SagasRecoveryPasswordNoToken,
    [NAME_SERVICE_PAYMENT]: SagasServicePayment,
    [NAME_SESSION]: SagasSession,
    [NAME_SETTINGS]: SagasSettings,
    [NAME_SHARE]: SagasShare,
    [NAME_SIMPLE]: SagasSimple,
    [NAME_STATUS]: SagasStatus,
    [NAME_SWIFT]: SagasSwift,
    [NAME_TEMPLATE]: SagasTemplate,
    [NAME_TRANSACTION_LINES]: SagasTransactionLines,
    [NAME_TRANSACTIONS]: SagasTransactions,
    [NAME_TRANSACTIONS_HISTORY]: SagasTransactionsHistory,
    [NAME_TRANSFERS]: SagasTransfers,
    [NAME_WIDGET]: SagasWidget,
    [NAME_WM_BONDS]: SagasWMBonds,
    [NAME_WM_BUY_FUNDS]: SagasWMBuyFunds,
    [NAME_WM_LIST_PAPERS]: SagasWMListPapers,
    [NAME_WM]: SagasWM,
    [NAME_WM_SELL_FUNDS]: SagasWMSellFunds,
    [NAME_WM_SHARES]: SagasWMShares,
    [NAME_WM_SWITCH_PAPERS]: SagasWMSwitchFunds,
};

export default function* rootSaga() {
    const all = Object.entries(SAGAS).reduce(
        (acc, [name, sagas]) => acc.concat(sagas.map((saga) => Spawn(handleSaga.bind(null, { name, saga })))),
        [],
    );

    yield All(all);
}

function* handleSaga({ name, saga }) {
    let isSyncError = false;
    let httpError = false;

    function resetSyncError() {
        isSyncError = false;
    }

    while (true) {
        isSyncError = true;
        httpError = false;

        try {
            setTimeout(resetSyncError);

            yield Call(function* wrapSaga() {
                yield saga;
            });

            Logger.error(
                [
                    `Unexpected ${name} saga termination. Root sagas are supposed to be sagas that`,
                    "live during the whole app lifetime!",
                ].join(SPACE_STR),
                saga,
            );
        } catch (err) {
            httpError = typeof err.httpError !== "undefined";

            if (!httpError && isSyncError) {
                err.message = `Startup Error in [${name}] saga: ${err.message}`;
                throw err;
            }

            yield Call(handleHttpError, err);
        }

        if (!httpError) {
            // Para evitar que fallas infinitas bloqueen el browser...
            Logger.warn(name, " will be restarted after 1 second");

            yield Delay(1000);
        }
    }
}

function* handleHttpError(error) {
    if (error.data) {
        const { code } = error.data;

        if (code === USER_NEEDS_CONNECTED_PARTIES) {
            yield Put({ type: CONNECTED_PARTIES_TYPES.CONNECTEDPARTIES_FORCE_TO_COMPLETE_FORM });
            yield Put(SelectorActionEnrollment.cleanUp());
            yield Put({ type: SESSION_TYPES.RESET_FETCHING });
        } else if (code === NO_CREDITCARDS_ENABLED_FOR_PAY) {
            let errorObject = {
                pathname: "/error",
                code,
                message: error.data.message,
            };

            if (error.data.data.description) {
                errorObject = {
                    ...errorObject,
                    description: error.data.data.description,
                };
            }

            yield Put(Replace(errorObject));
        } else {
            yield Put(SelectorActionEnrollment.cleanUp());

            switch (code) {
                // TODO: Add known error codes as new cases for avoid general error message

                // User is blocked
                case USER_DISABLED:
                case SYSTEM_UNAVAILABLE:
                    yield Put({ type: TYPES_APP.CLEAN_UP });

                    break;
                default:
                    break;
            }
            Logger.error("[API Error Handler]:1", error.data, error.status);

            let errorObject = {
                pathname: code === SYSTEM_UNAVAILABLE ? "/serverError" : "/error",
                code,
                message: error.data.message,
            };

            if (error.data.data.description) {
                errorObject = {
                    ...errorObject,
                    description: error.data.data.description,
                };
            }

            yield Put(Replace(errorObject));
        }
    } else if (
        error.response &&
        (error.response.status === RESPONSE_TYPE.UNAUTHORIZED || error.response.status === 403)
    ) {
        Logger.error("[API Error Handler]:2", error.response);
        // The request was made and the server responded,
        // but with a status code outside of 2xx
        yield Put(SelectorsActionSession.expire());
    } else if (error.request) {
        let errorMessage;

        if (error.code) {
            Logger.error("[SSL Pinning Error]:5", error.request);

            errorMessage =
                "Su conexión a internet no cumple con los requisitos mínimos. Comuníquese al Centro de atención al cliente: +598 2915 1010";
        } else {
            Logger.error("[API Error Handler]:3", error.request);
        }

        // The request was made but no response was received
        yield Put(SelectorsActionLogin.cleanUp());
        yield Put(
            Replace({
                pathname: "/error",
                code: error.code || NO_RESPONSE_RECEIVED,
                message: errorMessage,
            }),
        );
    } else {
        Logger.error("[API Error Handler]:4", error);
        // Something happened in setting up the request that triggered an Error
        yield Put(
            Replace({
                pathname: "/error",
                code: NO_RESPONSE_RECEIVED,
            }),
        );
    }

    document.removeEventListener("keydown", preventFocusBackground, true);
}
