import { createBrowserHistory as HistoryBrowser, createHashHistory as HistoryHash } from "history";

import { REACT_APP_ROUTER_BASE, EMPTY_STR } from "~/constants";

import Device from "./device";

export default Device.isMobileNative()
    ? HistoryHash({
          hashType: "slash",
          getUserConfirmation: (message, callback) => callback(window.confirm(message)),
      })
    : HistoryBrowser({
          basename: REACT_APP_ROUTER_BASE || EMPTY_STR,
      });
