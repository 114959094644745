import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR, REGEX_IGNORE_ACCENTS } from "~/constants";
import * as I18nUtil from "~/util/i18n";

import I18n from "~/components/I18n";
import Select from "~/components/Select";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import WithFocus from "~/pages/_components/withFocus";

import Style from "./DocumentField.rules.scss";

export const NAME = "DocumentField";

export const PROP = {
    types: {
        clearable: PropTypes.bool,
        countries: PropTypes.arrayOf(PropTypes.object),
        disableDocumentTypeSelect: PropTypes.bool,
        disableSelect: PropTypes.bool,
        documentTypes: PropTypes.arrayOf(PropTypes.object),
        hideDocumentCountryLabel: PropTypes.bool,
        hideDocumentPlaceholder: PropTypes.bool,
        tooltip: PropTypes.string,
        optional: PropTypes.string,
        handleError: PropTypes.bool,
    },
    defaults: {
        clearable: false,
        disableSelect: false,
        hideDocumentCountryLabel: false,
        hideDocumentPlaceholder: false,
        countries: [],
        disableDocumentTypeSelect: false,
        documentTypes: [],
        tooltip: "",
        optional: null,
        handleError: false,
    },
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidUpdate(prevProps) {
        const { form, field, defaultDocumentType, defaultCountry } = this.props;
        if (!prevProps.defaultDocumentType && defaultDocumentType && defaultCountry) {
            form.setFieldValue(field.name, {
                ...field.value,
                type: defaultDocumentType,
                country: defaultCountry,
            });
        }
    }

    handleCountryChange = (selectedOption) => {
        const {
            data: { documentTypes },
            field,
            form,
        } = this.props;
        form.setFieldValue(field.name, {
            ...field.value,
            country: selectedOption ? selectedOption.value : null,
            type: selectedOption
                ? documentTypes.filter((documentType) => documentType.id_country_code === selectedOption.value)[0]
                      .id_document_type
                : null,
        });
    };

    handleDocumentChange = (event) => {
        const { field, form, handleDocumentChange } = this.props;

        if (handleDocumentChange) {
            handleDocumentChange(event);
        }

        form.setFieldValue(field.name, { ...field.value, document: event.target.value });
    };

    handleTypeChange = (selectedOption) => {
        const { field, form } = this.props;

        form.setFieldValue(field.name, {
            ...field.value,
            type: selectedOption ? selectedOption.value : null,
        });
    };

    customFilter(option, searchText) {
        const country = option.label.toLowerCase();
        const text = searchText.toLowerCase();
        if(
            country.normalize("NFD").replace(REGEX_IGNORE_ACCENTS, "").includes(text)
            &&
            country.charAt(0).includes(text.charAt(0))
        ){
            return true;
        }else {
            return false
        }
    }

    render() {
        const {
            clearable,
            data: { countries, documentTypes },
            defaultCountry,
            defaultDocumentType,
            disableDocumentTypeSelect,
            disableSelect,
            field,
            form: { touched, errors },
            hideDocumentCountryLabel,
            hideDocumentPlaceholder,
            idForm,
            inputRef,
            tooltip,
            optional,
            handleError,
        } = this.props;
        const hasError = {
            countries:
                touched[field.name] && errors[field.name]
                    ? touched[field.name].country && errors[field.name].country
                    : false,
            document:
                touched[field.name] && errors[field.name]
                    ? touched[field.name].document && errors[field.name].document
                    : false,
            documentTypes:
                touched[field.name] && errors[field.name] ? touched[field.name].type && errors[field.name].type : false,
            error:
                handleError && touched[field.name] && errors[field.name]
                    ? touched[field.name] && errors[field.name]
                    : false,
        };

        return (
            <React.Fragment>
                <div id={Style.ID}>
                    <div
                        className={`form-group form-group--composite ${
                            hasError.documentType || hasError.document || hasError.countries || hasError.error ? "has-error" : ""
                        }`}>
                        {!hideDocumentCountryLabel && <FieldLabel optional={optional} labelKey={`${idForm}.${field.name}.label`} />}

                        <div className="input-group">
                            <Select
                                placeholder={EMPTY_STR}
                                clearable={clearable}
                                className="country-selector flex-container"
                                disabled={disableSelect}
                                onChange={this.handleCountryChange}
                                options={
                                    countries
                                        ? countries.map(({ id, name }) => ({
                                              value: id,
                                              label: name,
                                          }))
                                        : []
                                }
                                matchProp="any"
                                value={field.value?.country || defaultCountry}
                                filterOption={this.customFilter}
                            />
                            <Select
                                placeholder={EMPTY_STR}
                                noResultsText={EMPTY_STR}
                                clearable={clearable}
                                className="document-selector slideFromBottom flex-container"
                                disabled={disableDocumentTypeSelect}
                                onChange={this.handleTypeChange}
                                options={
                                    documentTypes && field.value?.country
                                        ? documentTypes
                                              .filter(
                                                  (documentType) =>
                                                      documentType.id_country_code === field.value?.country,
                                              )
                                              .map(({ id_document_type, id_document_label }) => ({
                                                  value: id_document_type,
                                                  label: id_document_label,
                                              }))
                                        : []
                                }
                                searchable={false}
                                value={field.value?.type || defaultDocumentType}
                            />
                            <input
                                className="number-selector"
                                maxLength={25}
                                placeholder={
                                    hideDocumentPlaceholder
                                        ? EMPTY_STR
                                        : I18nUtil.get(`${idForm}.${field.name}.placeholder`)
                                }
                                ref={inputRef}
                                {...field}
                                onChange={this.handleDocumentChange}
                                value={field.value?.document}
                            />

                            <div>
                                <div className="tooltip">
                                    <span className="tooltip-text">
                                        {tooltip === "" ? (
                                            <I18n id="administration.users.invite.document.tooltip" />
                                        ) : (
                                            tooltip
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {hasError.documentTypes && <FieldError error={errors[field.name].type} />}
                        {hasError.document && <FieldError error={errors[field.name].document} />}
                        {hasError.countries && <FieldError error={errors[field.name].country} />}
                        {hasError.error && <FieldError error={errors[field.name]} />}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default WithFocus(Component);
