import { LOCATION_CHANGE } from "connected-react-router";

import { MODE } from "~/constants/form";
import { TYPE as TypesBackoffice } from "~/store/backoffice";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    const { lines, transaction, idFile } = action;

    switch (action.type) {
        case TYPE.FILE_UPLOAD_SUPPLIERS_SUCCESS:
            return {
                ...storeProp,
                hasErrors: false,
                suppliersList: lines,
                idFile,
            };
        case TYPE.FILE_UPLOAD_SUPPLIERS_FAILURE:
            return {
                ...PROP.defaults,
                hasErrors: true,
            };
        case TYPE.FILE_SUPPLIERS_REMOVED:
            return {
                ...PROP.defaults,
            };
        case TYPE.PREVIEW_FORM_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.PREVIEW_FORM_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                prevMode: storeProp.mode,
                mode: MODE.PREVIEW,
            };
        case TYPE.SEND_FORM_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
                isSubmitting: true,
            };
        case TYPE.SEND_FORM_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                isSubmitting: false,
                prevMode: storeProp.mode,
                transaction,
                mode: MODE.VIEW,
            };
        case TYPE.CLOSE_TRANSACTION_PREVIEW:
            return {
                ...storeProp,
                fetching: false,
                mode: storeProp.prevMode,
            };
        case TYPE.READ_TRANSACTION_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.READ_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                transaction,
            };
        case TYPE.PREVIEW_CANCEL_REQUEST: {
            return {
                ...storeProp,
                mode: MODE.EDIT,
            };
        }
        case LOCATION_CHANGE: {
            return {
                ...PROP.defaults,
            };
        }
        case TYPE.SIGN_TRANSACTION_PREVIEW_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.SIGN_TRANSACTION_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                suppliersList: lines,
                transaction,
                fetching: false,
                prevMode: storeProp.mode,
                mode: MODE.PREVIEW,
            };
        case TYPE.RESTART_FLOW:
            return PROP.defaults;
        case TypesBackoffice.BACKOFFICE_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                prevMode: storeProp.mode,
                transaction: action.transaction,
            };
        default:
            return {
                ...storeProp,
            };
    }
};
