import { call as Call, put as Put, select as Select, takeLatest as TakeLatest } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE, ZERO } from "~/constants";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { downloadPdf as DownloadPdf } from "~/util/download";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction } from "./_selectors";

export default [
    TakeLatest(TYPE.LIST_PAPERS_REQUEST, listPapers),
    TakeLatest(TYPE.PAPER_DETAILS_REQUEST, readPaperDetails),
    TakeLatest(TYPE.READ_PAPER_CHART_VALUES_REQUEST, getPaperChartValues),
    TakeLatest(TYPE.FAMILY_PRODUCT_DESCRIPTION_REQUEST, getFamilyProductDescription),
];

function* listPapers() {
    const { type, data } = yield Call(SelectorsMiddleware.listPapers);
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.requestListFundPaperFailure);
    } else {
        const activeEnvironment = yield Select(SelectorsStoreSession.getActiveEnvironment);
        const { papersList, familiesList, categoriesList } = data.data;
        const hasHighlightedPaper = papersList.map((paper) => paper.category).includes("DESTACADOS");

        let categoryFilter = "all";

        if (hasHighlightedPaper && activeEnvironment?.riskProfileCode !== ZERO) {
            categoryFilter = "DESTACADOS";
        }

        yield Put(
            SelectorsAction.requestListFundPaperSuccess({ papersList, familiesList, categoriesList, categoryFilter }),
        );
    }
}

function* readPaperDetails(props) {
    const { paperId } = props;
    const { data } = yield Call(SelectorsMiddleware.readPaperDetails, { paperId });
    const { paperDetail, chartPeriods } = data.data;
    yield Put(SelectorsAction.requestPaperDetailsSuccess({ paperDetail, chartPeriods }));
}

function* getPaperChartValues(props) {
    const { months, idPaper } = props;
    const intIdPaper = parseInt(idPaper, 10);
    const params = {
        idPaper: intIdPaper,
        period: months,
        prices: true,
        dateFrom: null,
        dateTo: null,
        generatePDF: false,
        firstPrices: false,
    };
    const response = yield Call(SelectorsMiddleware.readPaperChartValues, params);

    const { data, type } = response;
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.taskFinished());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.PAPERS_LIST],
            }),
        );
        yield Put(SelectorsAction.chartValuesFailure());
    } else {
        const { paperValues } = data.data.paper;
        yield Put(SelectorsAction.chartValuesSuccess({ paperValues }));
    }
}

function* getFamilyProductDescription(props) {
    const { familyName } = props;

    const response = yield Call(SelectorsMiddleware.downloadFamilyDescription, { familyName });

    const { data, type } = response;
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.taskFinished());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.PAPERS_LIST],
            }),
        );
    } else {
        const { content, fileName } = response.data.data;
        DownloadPdf(fileName, content);
    }
}
