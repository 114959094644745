import React from "react";

import { routerActions as ActionRouter } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";

import { FILTER } from "~/constants";
import Container from "~/containers/Internal/Form/List";
import { SelectorsAction as SelectorsActionAccount, SelectorsStore as SelectorsStoreAccount } from "~/store/account";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import {
    setTime as SetTime,
    setDate as SetDate,
    lastDaysOfTheMonth as LastDaysOfTheMonth,
    startOfWeek as StartOfWeek,
    getDateSixMonthsAgo,
} from "~/util/date";
import { generateId as GenerateId } from "~/util/general";

import Button from "~/components/Button";

import PeriodFilter from "~/pages/accounts/_PeriodFilterForm";

import Style from "./OperationsFilter.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        idAccount: PropTypes.string.isRequierd,
        isChangingEnvironment: PropTypes.bool,
    },
    defaults: {
        isChangingEnvironment: false,
    },
};

export function Component(props) {
    const { dispatch, idAccount } = props;

    const [personalized, setPersonalized] = React.useState(false);

    const filtersOptions = [
        {
            label: "accounts.movements.filters.today",
            onClick: () => handleClick(FILTER.TODAY),
        },
        {
            label: "accounts.movements.filters.currentweek",
            onClick: () => handleClick(FILTER.CURRENT_WEEK),
        },
        {
            label: "accounts.movements.filters.currentmonth",
            onClick: () => handleClick(FILTER.CURRENT_MONTH),
        },
        {
            label: "accounts.movements.filters.lastmonth",
            onClick: () => handleClick(FILTER.LAST_MONTH),
        },
        {
            label: "accounts.movements.filters.lastmonthsix",
            onClick: () => handleClick(FILTER.LAST_MONTH_SIX),
        },
        {
            label: "accounts.movements.filters.personalized",
            onClick: () => setPersonalized(!personalized),
        },
    ];

    return (
        <Container name={Style.NAME} head-onBack={handleBack} className={Style.CLASS} head-title="menu.operations">
            {filtersOptions.map(({ label, onClick }) => (
                <Button label={label} onClick={onClick} key={GenerateId()} />
            ))}
            {renderFilter()}
        </Container>
    );

    function renderFilter() {
        return <PeriodFilter dispatch={dispatch} productId={idAccount} personalized={personalized} />;
    }

    function handleClick(filter) {
        const filters = {
            dateFrom: SetTime(),
            dateTo: new Date(),
        };
        if (filter === FILTER.CURRENT_WEEK) {
            filters.dateFrom = StartOfWeek(filters.dateFrom);
        } else if (filter === FILTER.CURRENT_MONTH) {
            filters.dateFrom = SetDate(filters.dateFrom, 0, 0, 1);
        } else if (filter === FILTER.LAST_MONTH) {
            filters.dateFrom = SetDate(filters.dateFrom, 0, 1, 1);
            filters.dateTo = SetDate(filters.dateFrom, 0, 1, LastDaysOfTheMonth(SetDate(filters.dateFrom, 0, 1, 0)));
        } else if (filter === FILTER.LAST_MONTH_SIX) {
            filters.dateFrom = getDateSixMonthsAgo();
        }
        dispatch(SelectorsActionAccount.setMobileFilter({ filter, ...filters, filtersPersonalized: false }));
        dispatch(SelectorsActionAccount.cleanMovements());

        dispatch(ActionRouter.push(`/accounts/${idAccount}/filters/results`));
    }

    function handleBack() {
        dispatch(ActionRouter.goBack());
    }
}
Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

const mapStateToProps = (store, props) => ({
    idAccount: props.match.params.id,
    account: SelectorsStoreAccount.getSelectedAccount(store),
    channels: SelectorsStoreAccount.getChannelList(store),
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
});

export default Connect(mapStateToProps)(WithRouter(Component));
