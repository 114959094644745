import React from "react";

import PropTypes from "prop-types";
import { Modal, Col } from "react-bootstrap";
import { connect as Connect } from "react-redux";

import { SelectorsAction as SelectorsActionSession } from "~/store/session";
import { SelectorsStore as SelectorsStoreStatus } from "~/store/status";

import Button from "~/components/Button";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Container from "~/pages/_components/Container";

export const NAME = "SessionAboutToExpire";

export const PROP = {
    types: {
        status: PropTypes.shape({
            sessionAboutToExpire: PropTypes.bool.isRequired,
        }).isRequired,
        dispatch: PropTypes.func.isRequired,
        location: PropTypes.shape({ href: PropTypes.string }),
    },
    defaults: { location: null },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = { seconds: 0, totalSeconds: 0, initialTime: 0 };

    interval = null;

    static getDerivedStateFromProps(nextProps, prevState) {
        const { status } = nextProps;
        const { seconds } = prevState;
        if (status.sessionAboutToExpire && seconds === 0) {
            return {
                seconds: status.sessionSecondsToExpire,
                totalSeconds: status.sessionSecondsToExpire,
                initialTime: new Date(),
            };
        }

        if (!status.sessionAboutToExpire && seconds !== 0) {
            return { seconds: 0, totalSeconds: 0 };
        }

        return null;
    }

    componentDidUpdate() {
        const { status } = this.props;
        if (!this.interval && status.sessionAboutToExpire) {
            this.interval = setInterval(this.decreaseSeconds, 1000);
        }
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    decreaseSeconds = () => {
        const { totalSeconds, initialTime } = this.state;

        const { dispatch, location } = this.props;
        const now = new Date();
        const difference = Math.floor((now - initialTime) / 1000);
        if (difference < totalSeconds) {
            this.setState(() => ({ seconds: totalSeconds - difference }));
        } else {
            dispatch(SelectorsActionSession.expire({ lastHref: location.href }));
        }
    };

    handleExtendSession = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionSession.extend());
        clearInterval(this.interval);
        this.interval = null;
    };

    render() {
        const { status } = this.props;
        const { seconds } = this.state;

        return (
            <div className="modal-container">
                <Modal show={status.sessionAboutToExpire}>
                    <Modal.Body>
                        <Container
                            className="container--layout align-items-center flex-grow"
                            gridClassName="container-fluid">
                            <Col sm={12} md={12} lg={12} xl={12}>
                                <div className="media-object media-object--state-warning text-center">
                                    <figure>
                                        <Image src="alert-big.svg" className="svg-icon" />
                                    </figure>
                                </div>
                            </Col>

                            <Col sm={12} md={12} lg={12} xl={12}>
                                <p className="text-lead">
                                    <I18n id="session.expires.in" SECONDS={seconds} />
                                </p>
                            </Col>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Col sm={12} md={12} lg={12} xl={12} className="text-center">
                                <Button label="session.extend" bsStyle="primary" onClick={this.handleExtendSession} />
                            </Col>
                        </Container>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ status: SelectorsStoreStatus.getStatus(state) });

export default Connect(mapStateToProps)(Component);
