import React from "react";

import PropTypes from "prop-types";

import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import SelectorInput from "~/pages/_components/fields/SelectorInput";
import withFocus from "~/pages/_components/withFocus";

export const NAME = "FormikSelectorInput";

export const PROP = {
    types: {
        selectorSize: PropTypes.string,
        field: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired,
        form: PropTypes.shape({
            setFieldTouched: PropTypes.func.isRequired,
            touched: PropTypes.objectOf(PropTypes.bool).isRequired,
            errors: PropTypes.objectOf(PropTypes.string).isRequired,
        }).isRequired,
        isFocused: PropTypes.bool,
        label: PropTypes.string.isRequired,
        inputProps: PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.string,
            placeholder: PropTypes.string,
        }).isRequired,
        toggleIsFocused: PropTypes.func.isRequired,
        selectProps: PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            options: PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.string.isRequired,
                    value: PropTypes.string.isRequired,
                }),
            ),
        }).isRequired,
        onChange: PropTypes.func.isRequired,
    },
    defaults: { selectorSize: "medium", isFocused: false },
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    handleBlur = () => {
        const { field, form } = this.props;

        form.setFieldTouched(field.name);
    };

    render() {
        const {
            form,
            field,
            isFocused,
            label,
            inputProps,
            toggleIsFocused,
            selectProps,
            onChange,
            selectorSize,
        } = this.props;
        const { touched, errors } = form;
        const hasError = touched[field.name] && errors[field.name];

        return (
            <div
                className={`form-group form-group--composite selector-size--${selectorSize} ${
                    hasError ? "has-error" : ""
                } ${isFocused ? "has-focus" : ""}`}>
                <FieldLabel labelText={label} />
                <SelectorInput
                    selectProps={selectProps}
                    inputProps={inputProps}
                    onChange={onChange}
                    isFocused={isFocused}
                    toggleIsFocused={toggleIsFocused}
                    onBlur={this.handleBlur}
                />
                {hasError && <FieldError error={errors[field.name]} />}
            </div>
        );
    }
}

export default withFocus(Component);
