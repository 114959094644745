import { EMPTY_STR } from "~/constants";
import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    changeAddressesRequest: (props) => API.executeWithAccessToken(PATH.PREFERENCES_CHANGE_USERDATA_ADDRESS, props),
    changeEmailVerify: (props) => API.executeWithAccessToken(PATH.PREFERENCES_CHANGE_USER_DATA_EMAIL, props),
    changeLanguage: (props) => API.executeWithAccessToken(PATH.PREFERENCES_LANG_MODIFY, props),
    changeListCity: (props) => API.executeWithAccessToken(PATH.PREFERENCES_CHANGE_USER_DATA_LIST_CITY, props),
    changeListTown: (props) => API.executeWithAccessToken(PATH.PREFERENCES_CHANGE_USER_DATA_LIST_TOWN, props),
    changePassword: (props) => API.executeWithAccessToken(PATH.PREFERENCES_CHANGE_PASSWORD_SEND, props),
    changePersonalData: () => API.executeWithAccessToken(PATH.PREFERENCES_CHANGE_USER_DATA),
    changePersonalDataCMB: () => API.executeWithAccessToken(PATH.PREFERENCES_CHANGE_USER_DATA_CMB),
    changePersonalDataSecuritysealsRequest: () =>
        API.executeWithAccessToken(PATH.PREFERENCES_CHANGE_USER_DATA_SECURITYSEALS_LIST),
    changePreDateAccounts: (props) => API.executeWithAccessToken(PATH.PREFERENCES_CHANGE_USER_DATA_ACCOUNTS, props),
    communicationTypePre: (props) =>
        API.executeWithAccessToken(PATH.PREFERENCES_NOTIFICATIONS_CONFIGURATION_MODIFY_PRE, props),
    deleteFingerprint: (props) => API.executeWithAccessToken(PATH.PREFERENCES_FINGERPRINT_DELETE, props),
    faceAuthentication: (props) => API.executeAnonymous(PATH.PREFERENCES_AUTHENTICATE, props),
    getUserData: () => API.executeWithAccessToken(PATH.PREFERENCES_USER_DATA_MODIFY_PRE),
    listEmailAccounts: () => API.executeWithAccessToken(PATH.PREFERENCES_CHANGE_EMAIL_ACCOUNTS_DATA),
    listFingerprintConfiguredUserDevices: () => API.executeWithAccessToken(PATH.PREFERENCES_FINGERPRINT_PRE),
    listSecuritySeals: () => API.executeWithAccessToken(PATH.PREFERENCES_SECURITY_SEALS_LIST),
    modifyAccountsData: (props) => API.executeWithAccessToken(PATH.PREFERENCES_MODIFY_ACCOUNTS_DATA, props),
    modifyFormDataAccounts: () => API.executeWithAccessToken(PATH.PREFERENCES_CHANGE_USERDATA_MODIFY_ACCOUNTS),
    modifyNotificationConfigurations: (props) =>
        API.executeWithAccessToken(PATH.PREFERENCES_NOTIFICATIONS_CONFIGURATION_MODIFY, props),
    modifySecuritySeal: (props) => API.executeWithAccessToken(PATH.PREFERENCES_SECURITY_SEALS_MODIFY, props),
    modifyUserCMB: (props) => API.executeWithAccessToken(PATH.PREFERENCES_MODIFY_USER_CONFIGURATION_CMB, props),
    modifyUser: (props) => API.executeWithAccessToken(PATH.PREFERENCES_MODIFY_USER_CONFIGURATION, props),
    notificationsConfigurationPre: () => API.executeWithAccessToken(PATH.PREFERENCES_NOTIFICATIONS_CONFIGURATION_PRE),
    saveFingerprint: (props) => API.executeWithAccessToken(PATH.PREFERENCES_FINGERPRINT_SAVE, props),
    sendMailCode: (props) => API.executeWithAccessToken(PATH.PREFERENCES_USER_DATA_MAIL_SEND_CODE, props),
    sendMobilePhoneCode: (props) =>
        API.executeWithAccessToken(PATH.PREFERENCES_USER_DATA_MOBILE_PHONE_SEND_CODE, props),
    sendMobilePhoneCodeResending: (props) =>
        API.executeWithAccessToken(PATH.PREFERENCES_USER_DATA_MOBILE_PHONE_SEND_CODE_RESEND, props),
    updateMailAccounts: (props) => API.executeWithAccessToken(PATH.PREFERENCES_UPDATE_EMAIL_ACCOUNTS_DATA, props),
    updateMailCMB: (props) => API.executeWithAccessToken(PATH.PREFERENCES_USER_DATA_MAIL_UPDATE_CMB, props),
    updateMail: (props) => API.executeWithAccessToken(PATH.PREFERENCES_USER_DATA_MAIL_UPDATE, props),
    updateMobilePhone: (props) => API.executeWithAccessToken(PATH.PREFERENCES_USER_DATA_MOBILE_PHONE_UPDATE, props),
    updateMobilePhoneCMB: (props) =>
        API.executeWithAccessToken(PATH.PREFERENCES_USER_DATA_MOBILE_PHONE_UPDATE_CMB, props),
};

export const SelectorsAction = {
    addressesCityLegalSuccess: (props) => FactoryAction(TYPE.CHANGE_ADDRESSES_CITY_LEGAL_SUCCESS, props),
    addressesCityMailingsSuccess: (props) => FactoryAction(TYPE.CHANGE_ADDRESSES_CITY_MAILINGS_SUCCESS, props),
    addressesDepartamentLegalSuccess: (props) => FactoryAction(TYPE.CHANGE_ADDRESSES_DEPARTAMENT_LEGAL_SUCCESS, props),
    addressesDepartamentMailingsSuccess: (props) =>
        FactoryAction(TYPE.CHANGE_ADDRESSES_DEPARTAMENT_MAILINGS_SUCCESS, props),
    cancelTransactionCustom: (props) => FactoryAction(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, props),
    changeAddressesCityFailure: () => FactoryAction(TYPE.CHANGE_ADDRESSES_CITY_FAILURE),
    changeAddressesCityRequest: (props) => FactoryAction(TYPE.CHANGE_ADDRESSES_CITY_REQUEST, props),
    changeAddressesCitySuccess: (props) => FactoryAction(TYPE.CHANGE_ADDRESSES_CITY_SUCCESS, props),
    changeAddressesDataFormRequest: (props) => FactoryAction(TYPE.CHANGE_ADDRESSES_DATA_FORM_REQUEST, props),
    changeAddressesDepartamentAndCityRequest: (props) =>
        FactoryAction(TYPE.CHANGE_ADDRESSES_DEPARTAMENT_AND_CITY_REQUEST, props),
    changeAddressesDepartamentFailure: () => FactoryAction(TYPE.CHANGE_ADDRESSES_DEPARTAMENT_FAILURE),
    changeAddressesDepartamentRequest: (props) => FactoryAction(TYPE.CHANGE_ADDRESSES_DEPARTAMENT_REQUEST, props),
    changeAddressesDepartamentSuccess: (props) => FactoryAction(TYPE.CHANGE_ADDRESSES_DEPARTAMENT_SUCCESS, props),
    changeAddressesFailure: () => FactoryAction(TYPE.CHANGE_ADDRESSES_FAILURE),
    changeAddressesRequest: (props) => FactoryAction(TYPE.CHANGE_ADDRESSES_REQUEST, props),
    changeAddressesSubmit: (props) => FactoryAction(TYPE.CHANGE_ADDRESSES_SUBMIT, props),
    changeAddressesSuccess: (props) => FactoryAction(TYPE.CHANGE_ADDRESSES_SUCCESS, props),
    changeEmailVerify: (props) => FactoryAction(TYPE.CHANGE_EMAIL_VERIFY, props),
    changeFormLegal: () => FactoryAction(TYPE.CHANGE_FORM_LEGAL),
    changeLanguagePreError: () => FactoryAction(TYPE.CHANGE_LANGUAGE_PRE_ERROR),
    changeLanguagePreSuccess: () => FactoryAction(TYPE.CHANGE_LANGUAGE_PRE_SUCCESS),
    changeLanguage: (props) => FactoryAction(TYPE.CHANGE_LANGUAGE_PRE, props),
    changePassword: (props) => FactoryAction(TYPE.CHANGE_PASSWORD_REQUEST, props),
    changePersonalDataCMBSuccess: (props) => FactoryAction(TYPE.CHANGE_PERSONAL_DATA_CMB_SUCCESS, props),
    changePersonalData: (props) => FactoryAction(TYPE.CHANGE_PERSONAL_DATA, props),
    changePersonalDataSecuritysealsFailure: () => FactoryAction(TYPE.CHANGE_PERSONAL_DATA_SECURITYSEALS_FAILURE),
    changePersonalDataSecuritysealsRequest: () => FactoryAction(TYPE.CHANGE_PERSONAL_DATA_SECURITYSEALS_REQUEST),
    changePersonalDataSecuritysealsSuccess: (props) =>
        FactoryAction(TYPE.CHANGE_PERSONAL_DATA_SECURITYSEALS_SUCCESS, props),
    changePersonalDataSuccess: (props) => FactoryAction(TYPE.CHANGE_PERSONAL_DATA_SUCCESS, props),
    changeSecuritySealConfirmationBack: () => FactoryAction(TYPE.CHANGE_SECURITY_SEAL_CONFIRMATION_BACK),
    changeSecuritySealConfirmationError: (props) => FactoryAction(TYPE.CHANGE_SECURITY_SEAL_CONFIRMATION_ERROR, props),
    changeSecuritySealConfirmationPre: (props) => FactoryAction(TYPE.CHANGE_SECURITY_SEAL_CONFIRMATION_PRE, props),
    changeSecuritySealConfirmation: (props) => FactoryAction(TYPE.CHANGE_SECURITY_SEAL_CONFIRMATION, props),
    changeSecuritySealConfirmationSuccess: (props) =>
        FactoryAction(TYPE.CHANGE_SECURITY_SEAL_CONFIRMATION_SUCCESS, props),
    changeSecuritySealPre: () => FactoryAction(TYPE.CHANGE_SECURITY_SEAL_PRE),
    changeSecuritySealPreSuccess: (props) => FactoryAction(TYPE.CHANGE_SECURITY_SEAL_PRE_SUCCESS, props),
    changeSecuritySeal: (props) => FactoryAction(TYPE.CHANGE_SECURITYSEALS, props),
    cleanErrors: () => FactoryAction(TYPE.CLEAN_ERRORS),
    cleanPersonalInformation: () => FactoryAction(TYPE.PREFERENCES_CHANGE_USER_DATA_ACCOUNTS_CLEAN),
    closeConfirmation: () => FactoryAction(TYPE.CLOSE_TRANSACTION_PREVIEW),
    communicationTypePre: (props) => FactoryAction(TYPE.COMMUNICATION_TYPE_PRE, props),
    communicationTypePreSuccess: (props) => FactoryAction(TYPE.COMMUNICATION_TYPE_PRE_SUCCESS, props),
    getUserData: () => FactoryAction(TYPE.GET_USER_DATA),
    getUserDataSuccess: (props) => FactoryAction(TYPE.GET_USER_DATA_SUCCESS, props),
    hideSecuritySealModal: () => FactoryAction(TYPE.HIDE_MODAL_SECURITYSEALS),
    listEmailAccountsRequest: () => FactoryAction(TYPE.PREFERENCES_LIST_EMAIL_DATA_ACCOUNTS_REQUEST),
    listEmailAccountsSuccess: (props) => FactoryAction(TYPE.PREFERENCES_LIST_EMAIL_DATA_ACCOUNTS_SUCCESS, props),
    modifyDateAccountsFailure: () => FactoryAction(TYPE.PREFERENCES_CHANGE_USER_DATA_ACCOUNTS_FAILURE),
    modifyDateAccountsRequest: () => FactoryAction(TYPE.PREFERENCES_CHANGE_USER_DATA_ACCOUNTS_REQUEST),
    modifyDateAccountsSuccess: (props) => FactoryAction(TYPE.PREFERENCES_CHANGE_USER_DATA_ACCOUNTS_SUCCESS, props),
    modifyFormDataAccountsFailure: () => FactoryAction(TYPE.PREFERENCES_FORM_MODIFY_DATA_ACCOUNTS_FAILURE),
    modifyFormDataAccountsRequest: () => FactoryAction(TYPE.PREFERENCES_FORM_MODIFY_DATA_ACCOUNTS_REQUEST),
    modifyFormDataAccountsSuccess: (props) => FactoryAction(TYPE.PREFERENCES_FORM_MODIFY_DATA_ACCOUNTS_SUCCESS, props),
    modifyNotificationConfigurations: (props) => FactoryAction(TYPE.MODIFY_NOTIFICATION_CONFIGURATIONS, props),
    modifyUserCMBSuccess: () => FactoryAction(TYPE.PREFERENCES_MODIFY_USER_CONFIGURATION_CMB_SUCCESS),
    modifyUserFailure: () => FactoryAction(TYPE.PREFERENCES_MODIFY_USER_CONFIGURATION_FAILURE),
    modifyUserRequest: (props) => FactoryAction(TYPE.PREFERENCES_MODIFY_USER_CONFIGURATION_REQUEST, props),
    noChangeFormLegal: () => FactoryAction(TYPE.NO_CHANGE_FORM_LEGAL),
    notificationsConfigurationPre: () => FactoryAction(TYPE.NOTIFICATIONS_CONFIGURATION_PRE),
    notificationsConfigurationPreSuccess: (props) => FactoryAction(TYPE.NOTIFICATIONS_CONFIGURATION_PRE_SUCCESS, props),
    readChangeTransactionDataRequest: (props) => FactoryAction(TYPE.READ_TRANSACTION_REQUEST, props),
    readChangeTransactionDataSuccess: (props) => FactoryAction(TYPE.READ_TRANSACTION_SUCCESS, props),
    readTicketUserConfigurationRequest: (props) =>
        FactoryAction(TYPE.PREFERENCES_READ_TICKET_USER_CONFIGURATION_REQUEST, props),
    readTicketUserConfigurationSuccess: (props) =>
        FactoryAction(TYPE.PREFERENCES_READ_TICKET_USER_CONFIGURATION_SUCCESS, props),
    saveChangeData: (props) => FactoryAction(TYPE.PREFERENCES_MODIFY_USER_CONFIGURATION_SUCCESS, props),
    sendMailCode: (props) => FactoryAction(TYPE.MAIL_SEND_CODE, props),
    sendMailCodeFailure: () => FactoryAction(TYPE.MAIL_SEND_CODE_FAILURE),
    sendMailCodeSuccess: (props) => FactoryAction(TYPE.MAIL_SEND_CODE_SUCCESS, props),
    sendMobilePhoneCode: (props) => FactoryAction(TYPE.MOBILEPHONE_SEND_CODE, props),
    sendMobilePhoneCodeFailure: () => FactoryAction(TYPE.MOBILEPHONE_SEND_CODE_FAILURE),
    sendMobilePhoneCodeSuccess: (props) => FactoryAction(TYPE.MOBILEPHONE_SEND_CODE_SUCCESS, props),
    showSecuritySealModal: () => FactoryAction(TYPE.SHOW_MODAL_SECURITYSEALS),
    signTransactionFailure: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_FAILURE, props),
    signTransactionPreview: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_PREVIEW_REQUEST, props),
    signTransactionPreviewSuccess: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_PREVIEW_SUCCESS, props),
    signTransaction: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_REQUEST, props),
    updateMailAccounts: (props) => FactoryAction(TYPE.PREFERENCES_UPDATE_EMAIL_ACCOUNTS_REQUEST, props),
    updateMailCMBSuccess: () => FactoryAction(TYPE.MAIL_UPDATE_CMB_SUCCESS),
    updateMailFailure: () => FactoryAction(TYPE.MAIL_UPDATE_FAILURE),
    updateMailPreviewCancel: () => FactoryAction(TYPE.MAIL_SEND_CODE_CANCEL),
    updateMail: (props) => FactoryAction(TYPE.MAIL_UPDATE, props),
    updateMailSuccess: (props) => FactoryAction(TYPE.MAIL_UPDATE_SUCCESS, props),
    updateMobilePhone: (props) => FactoryAction(TYPE.MOBILEPHONE_UPDATE, props),
    updateMobilePhoneCancel: () => FactoryAction(TYPE.MOBILEPHONE_SEND_CODE_CANCEL),
    updateMobilePhoneFailure: (props) => FactoryAction(TYPE.MOBILEPHONE_UPDATE_FAILURE, props),
    updateMobilePhoneSuccess: (props) => FactoryAction(TYPE.MOBILEPHONE_UPDATE_SUCCESS, props),
    updateMobilePhonePreviewCancel: () => FactoryAction(TYPE.MOBILEPHONE_SEND_CODE_SUCCESS),
    userDataAccountsClean: () => FactoryAction(TYPE.PREFERENCES_USER_DATA_ACCOUNTS_CLEAN),
};

export const SelectorsStore = {
    getAddressesUser: (store) => store[NAME].addressesUser,
    getAdresses: (store) => store[NAME].adresses,
    getChangeAccountDataInProgress: (store) => store[NAME].changeAccountDataInProgress,
    getChangePersonalDataInProgress: (store) => store[NAME].changePersonalDataInProgress,
    getChangeTransactionData: (store) => store[NAME].changeTransactionData,
    getCityListLegal: (store) => store[NAME].cityListLegal,
    getCityListMailings: (store) => store[NAME].cityListMailings,
    getCityList: (store) => store[NAME].cityList,
    getCommunicationTransportsConfigurations: (store) => store[NAME].communicationTransportsConfigurations,
    getCommunicationTypes: (store) => store[NAME].communicationTypes,
    getCorrespondense: (store) => store[NAME].correspondense,
    getCountryList: (store) => store[NAME].countryList,
    getDocuments: (store) => store[NAME].documents,
    getEmailConfirm: (store) => store[NAME].confirmEmailCode,
    getEmail: (store) => (store[NAME].userData && store[NAME].userData.mail ? store[NAME].userData.mail : EMPTY_STR),
    getEmailBT: (store) =>
        store[NAME].userData && store[NAME].userData.emailBT ? store[NAME].userData.emailBT : EMPTY_STR,
    getErrors: (store) => store[NAME].errors,
    getFetching: (store) => store[NAME].fetching,
    getFetchingAccountData: (store) => store[NAME].fetchingAccountData,
    getFetchingForm: (store) => store[NAME].fetchingForm,
    getInfoUser: (store) => store[NAME].infoUser,
    getLegal: (store) => store[NAME].legal,
    getListEmail: (store) => store[NAME].listEmail,
    getLoadData: (store) => store[NAME].loadData,
    getMaritalStatusList: (store) => store[NAME].maritalStatusList,
    getMobilePhone: (store) =>
        store[NAME].userData && store[NAME].userData.mobilePhone ? store[NAME].userData.mobilePhone : EMPTY_STR,
    getMobilePhoneBT: (store) =>
        store[NAME].userData && store[NAME].userData.mobilePhoneBT ? store[NAME].userData.mobilePhoneBT : EMPTY_STR,
    getMobilePrefix: (store) =>
        store[NAME].userData && store[NAME].userData.mobilePrefix ? store[NAME].userData.mobilePrefix : EMPTY_STR,
    getMode: (store) => store[NAME].mode,
    getNewEmail: (store) => store[NAME].newEmail,
    getNewMobilePhone: (store) => store[NAME].newMobilePhone,
    getPersonalData: (store) => store[NAME].personalData,
    getPrevMode: (store) => store[NAME].prevMode,
    getProfessions: (store) => store[NAME].professions,
    getSecuritySealList: (store) => store[NAME].securitySealList,
    getSecuritySeals: (store) => store[NAME].securitySeals,
    getSecuritySeal: (store) => store[NAME].securitySeal,
    getSelectedSecuritySealId: (store) => store[NAME].selectedSecuritySealId,
    getSelectedSecuritySeal: (store) =>
        store[NAME].selectedSecuritySealId ? store[NAME].securitySeals[store[NAME].selectedSecuritySealId] : null,
    getSupportedLanguages: (store) => store.i18n.supportedLanguages,
    getTownListLegal: (store) => store[NAME].townListLegal,
    getTownListMailings: (store) => store[NAME].townListMailings,
    getTownList: (store) => store[NAME].townList,
    isChangeForm: (store) => store[NAME].isChangeFormLegal,
};
