import React from "react";

import { connect as Connect } from "react-redux";

import { EMPTY_STR, LEVEL, SCOPE } from "~/constants";
import { SelectorsStore as SelectorsStoreConfig, STORE } from "~/store/config";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsStore as SelectorsStoreWidget, SelectorsAction as SelectorsActionWidget } from "~/store/widget";
import UtilFlag from "~/util/flag";
import * as UtilsI18n from "~/util/i18n";

import DataNumber from "~/components/DataNumber";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";

import Style from "./PreferentialTradingPrice.rules.scss";
import Widget from "./_common/_Widget";

export const { TYPE, NAME } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const { activeEnvironment, data, dispatch, getConfig, getMessage, isFetching, store: widget, ...rest } = props;
    const { permissions } = activeEnvironment;
    const { preferentialTradingPrice = false } = permissions || {};

    React.useEffect(() => {
        dispatch(SelectorsActionWidget.listRequest({ widget }));
    }, [dispatch, widget]);

    return (
        <Widget
            {...rest}
            id={Style.ID}
            wait={isFetching}
            header-title={<Title />}
            header-icon="footer-preferential-trading-price.svg"
            header-href={preferentialTradingPrice && data.length ? "/preferentialTradingPrice" : EMPTY_STR}>
            <section role={(data.length && "list") || undefined}>
                {!data.length ? (
                    (() => {
                        const time =
                            getConfig({
                                key: "client.transactions.preferentialTradingPrice.send.availability",
                                type: STORE.TYPE_ARRAY,
                            }) || [];
                        return (
                            <I18n
                                id="desktop.widgets.preferentialTradingPrice.empty"
                                startingHour={time[0]}
                                closingHour={time[1]}
                            />
                        );
                    })()
                ) : (
                    <React.Fragment>
                        {data.map(({ currency, currency2, price, id }) => {
                            const to = preferentialTradingPrice
                                ? {
                                      pathname: "/preferentialTradingPrice",
                                      state: { isSelling: id === "selling " },
                                  }
                                : undefined;

                            const title = getMessage(`form.fx.operacion${id === "selling" ? "Venta" : "Compra"}`);

                            return (
                                <Link role="listitem" key={id} to={to} onClick={handleClick}>
                                    <h4>{title}</h4>
                                    <Image src={`${UtilFlag.getFlag(currency2)}.svg`} />
                                    <DataNumber value={price} prefix={currency} />
                                </Link>
                            );
                        })}
                        <small>{getMessage("widget.preferentialTradingPrice.info")}</small>
                    </React.Fragment>
                )}
            </section>
        </Widget>
    );

    function handleClick(event) {
        if (!preferentialTradingPrice) {
            dispatch(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("tranfer.permission"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.DESKTOP],
                }),
            );

            event.preventDefault();
        }
    }

    function Title() {
        return (
            <React.Fragment>
                <h3>{getMessage("menu.accounts.preferentialTradingPrice")}</h3>
                <ul>
                    <li>
                        <Image src="currencyFlags/USD.svg" />
                        <span>{getMessage("currency.label.2222")}</span>
                    </li>
                    <li>
                        <Image src="currencyFlags/UYU.svg" />
                        <span>{getMessage("currency.label.0")}</span>
                    </li>
                </ul>
            </React.Fragment>
        );
    }
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

function mapStateToProps(store, props) {
    const { store: widget } = props;
    const { isFetching, data = [] } = SelectorsStoreWidget.getWidget(store, widget) || {};

    return {
        activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store) || {},
        isFetching,
        data,
        getConfig: ({ key, type }) => SelectorsStoreConfig.getItem(store, { key, type }),
        getMessage: (key) => SelectorsStoreI18n.getMessage(store, key),
    };
}

export default Connect(mapStateToProps)(Component);
