import React from "react";

import { connect as Connect } from "react-redux";

import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { PROP as STORE_PROP, OPERATIONS as STORE_OPERATIONS } from "~/store/preferentialTradingPrice";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as UtilsI18n from "~/util/i18n";
import { numberToLocaleFormat as NumberToLocaleFormat } from "~/util/number";

import Button from "~/components/Button";
import I18n from "~/components/I18n";

import AccountSelectorsFX from "../components/AccountSelectorsFX";
import AmountFX from "../components/AmountFX";
import BuyingAndSelling from "../components/BuyingAndSelling";
import CurrencySelector from "../components/CurrencySelector";
import Disclaimer from "../components/Disclaimer";
import Bulletin from "../components/widgets/Bulletin";
import CurrencyExchangeRates from "../components/widgets/CurrencyExchangeRates";
import HistoricalRatesChart from "../components/widgets/HistoricalRatesChart";
import Style from "./FormFX.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...STORE_PROP.types.formData,
    },
    defaults: {
        ...STORE_PROP.defaultsFormData,
    },
};
export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {};

    noPermissionFX = (
        <div>
            <h3>
                <I18n id="preferentialTradingPrice.noPermission" />
            </h3>
        </div>
    );

    notTransactionAbleFormFX = (
        <div>
            <h3>
                <I18n id="preferentialTradingPrice.notTransactionAble" />
            </h3>
        </div>
    );

    cotization = (rate, currency1Label, currency2Label) => (
        <React.Fragment>
            ({rate} {currency1Label} {UtilsI18n.get("client.preferentialTradingPrice.by")} {currency2Label})
        </React.Fragment>
    );

    headerFXForm = (headerProps) => {
        const {
            currencyOptions,
            selectedCurrency,
            handleCurrencyChange,
            currencyLabel,
            currency1Label,
            lastTypedInput,
            lastTypedValue,
            buyingRateClient,
            sellingRateClient,
            handleBuying,
            handleSelling,
            operation,
            changeOperation,
        } = headerProps;

        return (
            <React.Fragment>
                <div className="form-section-title">
                    <h3 className="form-section-title-text">
                        <I18n id="preferentialTradingPrice.exchange" />
                    </h3>
                </div>
                <div>
                    <CurrencySelector
                        currencies={currencyOptions}
                        selectedCurrency={selectedCurrency}
                        handleCurrencyChange={handleCurrencyChange}
                    />
                </div>
                <div>
                    <BuyingAndSelling
                        currencyLabel={currencyLabel}
                        sellingCurrencyLabel={currency1Label}
                        lastTypedInput={lastTypedInput}
                        lastTypedValue={lastTypedValue}
                        buyingRateClient={buyingRateClient}
                        sellingRateClient={sellingRateClient}
                        handleBuying={handleBuying}
                        handleSelling={handleSelling}
                        operation={operation}
                        changeOperation={changeOperation}
                    />
                </div>
            </React.Fragment>
        );
    };

    labelAmount = (
        currency1Label,
        debitAmount,
        currency2Label,
        creditAmount,
        debitAccountLabel,
        debitAccountBalance,
        creditAccountLabel,
        creditAccountBalance,
        operation,
        buyingRateClient,
        sellingRateClient,
    ) => {
        const rate = operation === STORE_OPERATIONS.BUYING ? buyingRateClient : sellingRateClient;
        const rateCurrency1 = operation === STORE_OPERATIONS.BUYING ? currency1Label : currency2Label;
        const rateCurrency2 = operation === STORE_OPERATIONS.BUYING ? currency2Label : currency1Label;
        const { lang } = this.props;

        const debitAccountLabelStr = debitAccountBalance
            ? `${debitAccountLabel} (${UtilsI18n.get(
                  `currency.label.${debitAccountBalance.currency}`,
              )} ${NumberToLocaleFormat(debitAccountBalance.quantity, lang)})`
            : `${debitAccountLabel}`;
        const creditAccountLabelStr = creditAccountBalance
            ? `${creditAccountLabel} (${UtilsI18n.get(
                  `currency.label.${creditAccountBalance.currency}`,
              )} ${NumberToLocaleFormat(creditAccountBalance.quantity, lang)})`
            : `${creditAccountLabel}`;

        return (
            <div className="form-group-content">
                <I18n
                    id="preferentialTradingPrice.confirmation.label"
                    SOURCE_CURRENCY={currency1Label}
                    SOURCE_AMOUNT={NumberToLocaleFormat(debitAmount, lang)}
                    RATE={rate}
                    CURRENCY1={rateCurrency1}
                    CURRENCY2={rateCurrency2}
                    SOURCE_ACCOUNT={debitAccountLabelStr}
                    DESTINATION_CURRENCY={currency2Label}
                    DESTINATION_AMOUNT={NumberToLocaleFormat(creditAmount, lang)}
                    DESTINATION_ACCOUNT={creditAccountLabelStr}
                />
            </div>
        );
    };

    transactionAbleFormFX = (transactionAbleProps) => {
        const {
            handleChangeDebitAccount,
            handleChangeCreditAccount,
            newOptionsAccountDebit,
            newOptionsAccountCredit,
            debitValue,
            creditValue,
            handleChangeDebitAmount,
            handleChangeCreditAmount,
            thousandsSeparator,
            decimalSeparator,
            amountDecimals,
            currency1Label,
            currency2Label,
            debitAmount,
            creditAmount,
            handleBlurError,
            operation,
            buyingRateClient,
            sellingRateClient,
        } = transactionAbleProps;
        const { errors } = this.props;

        const { label: debitAccountLabel, id: debitAccountId, accountBalance: debitAccountBalance } = debitValue;
        const { label: creditAccountLabel, id: creditAccountId, accountBalance: creditAccountBalance } = creditValue;

        return (
            <React.Fragment>
                <section className="container--layout align-items-center flex-grow">
                    <div className="form-section-title">
                        <h3 className="form-section-title-text">
                            <I18n id="preferentialTradingPrice.debitAndCreditAccounts" />
                        </h3>
                    </div>
                    <AccountSelectorsFX
                        handleChangeAccount={handleChangeDebitAccount}
                        newOptionsAccount={newOptionsAccountDebit}
                        accountValue={debitValue}
                        handleBlurError={handleBlurError}
                        errors={errors}
                        message="form.fx.debitAccount"
                        fieldName="debitAccount"
                    />
                    <AccountSelectorsFX
                        handleChangeAccount={handleChangeCreditAccount}
                        newOptionsAccount={newOptionsAccountCredit}
                        accountValue={creditValue}
                        handleBlurError={handleBlurError}
                        errors={errors}
                        message="form.fx.creditAccount"
                        fieldName="creditAccount"
                    />
                    <hr />
                    <div className="form-section-title">
                        <h3 className="form-section-title-text">
                            <I18n id="preferentialTradingPrice.debitAndCreditAmounts" />
                        </h3>
                    </div>

                    <AmountFX
                        handleChangeAmount={handleChangeDebitAmount}
                        thousandsSeparator={thousandsSeparator}
                        decimalSeparator={decimalSeparator}
                        decimalToShow={amountDecimals}
                        currencyLabel={operation === STORE_OPERATIONS.BUYING ? currency1Label : currency2Label}
                        amount={debitAmount}
                        errors={errors}
                        message="form.fx.debitAmount"
                        fieldName="debitAmount"
                        handleBlurError={handleBlurError}
                    />
                    <AmountFX
                        handleChangeAmount={handleChangeCreditAmount}
                        thousandsSeparator={thousandsSeparator}
                        decimalSeparator={decimalSeparator}
                        decimalToShow={amountDecimals}
                        currencyLabel={operation === STORE_OPERATIONS.BUYING ? currency2Label : currency1Label}
                        amount={creditAmount}
                        errors={errors}
                        message="form.fx.creditAmount"
                        fieldName="creditAmount"
                        handleBlurError={handleBlurError}
                    />
                    <div />
                    <div>
                        {debitAmount !== 0 &&
                            debitAmount &&
                            creditAmount !== 0 &&
                            creditAmount &&
                            debitAccountId !== "_empty" &&
                            creditAccountId !== "_empty" &&
                            this.labelAmount(
                                operation === STORE_OPERATIONS.BUYING ? currency1Label : currency2Label,
                                debitAmount,
                                operation === STORE_OPERATIONS.BUYING ? currency2Label : currency1Label,
                                creditAmount,
                                debitAccountLabel,
                                debitAccountBalance,
                                creditAccountLabel,
                                creditAccountBalance,
                                operation,
                                buyingRateClient,
                                sellingRateClient,
                                amountDecimals,
                                decimalSeparator,
                                thousandsSeparator,
                            )}
                    </div>
                </section>
                <hr />
            </React.Fragment>
        );
    };

    renderFXBody = () => {
        const { areFXAccountSelectAble, hasFXPermission, errors } = this.props;

        if (hasFXPermission && areFXAccountSelectAble) {
            return this.transactionAbleFormFX(this.props, errors);
        }
        if (hasFXPermission && !areFXAccountSelectAble) {
            return this.notTransactionAbleFormFX;
        }
        return this.noPermissionFX;
    };

    render() {
        const { fetching, handlePreview, areFXAccountSelectAble, hasFXPermission, quotes } = this.props;

        const headerFX = this.headerFXForm(this.props);

        const accountRenderFX = this.renderFXBody();

        const button =
            areFXAccountSelectAble && hasFXPermission ? (
                <div className="flex-button">
                    <Button
                        loading={fetching}
                        id="branch"
                        className="btn"
                        label="form.fx.enviar"
                        onClick={handlePreview}
                        bsStyle="primary"
                        type="button"
                    />
                </div>
            ) : null;

        return (
            <form id={Style.ID}>
                <form className="col col-12 col-lg-6 col-md-9 col-sm-12">
                    {headerFX}
                    {accountRenderFX}
                    <Disclaimer />
                    {button}
                </form>
                <div className="widget-wrapper">
                    <CurrencyExchangeRates quotes={quotes} />
                    <HistoricalRatesChart />
                    <Bulletin />
                </div>
            </form>
        );
    }
}

export default Connect((store) => () => {
    const activeEnvironment = SelectorsStoreSession.getActiveEnvironment(store);
    const {
        permissions: { preferentialTradingPrice: hasFXPermission },
    } = activeEnvironment;
    return {
        lang: SelectorsStoreI18n.getLang(store),
        hasFXPermission,
    };
})(Component);
