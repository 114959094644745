import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR, FORMAT_DATE_SCHEDULER_FIELD } from "~/constants";
import { MODE, SCHEDULER_OPTIONS } from "~/constants/form";
import {
    specificDate as SpecificDate,
    isSameDay as IsSameDay,
    isDateLessThanToday as IsDateLessThanToday,
    addDay as AddDay,
} from "~/util/date";
import UtilLodash from "~/util/lodash";

import I18n from "~/components/I18n";
import RadioOption from "~/components/RadioOption";
import FieldError from "~/pages/_components/fields/FieldError";

import FutureFrequencySubOption from "~/pages/forms/_components/_fields/_scheduler/FutureFrequencySubOption";
import MonthlyFrequencySubOption from "~/pages/forms/_components/_fields/_scheduler/MonthlyFrequencySubOption";
import WeeklyFrequencySubOption from "~/pages/forms/_components/_fields/_scheduler/WeeklyFrequencySubOption";

export const NAME = "Scheduler";

const FORM_ID = "scheduler.execution";

export const PROP = {
    types: {
        data: PropTypes.shape({
            enabledWeekDays: PropTypes.arrayOf(PropTypes.bool).isRequired,
            firstWorkingDate: PropTypes.string.isRequired,
            maxDaysToSchedule: PropTypes.number.isRequired,
            nonWorkingDays: PropTypes.arrayOf(PropTypes.string).isRequired,
        }).isRequired,
        field: PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.any,
        }).isRequired,
        form: PropTypes.shape({
            setFieldValue: PropTypes.func.isRequired,
        }).isRequired,
    },
    defaults: {},
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        valueDate: null,
        weeklyProgram: null,
        monthlyProgram: null,
    };

    componentDidMount() {
        const { field, form } = this.props;

        let selectedOption = SCHEDULER_OPTIONS.FUTURE;
        const valueDate = new Date(this.getDefaultValueDate());

        if (IsSameDay(valueDate, new Date())) {
            selectedOption = SCHEDULER_OPTIONS.TODAY;
        }

        form.setFieldValue(field.name, {
            selectedOption,
            valueDate,
            editing: true,
        });
        if (field.value) {
            form.setFieldValue(field.name, { ...field.value, editing: true });
        }
    }

    componentDidUpdate() {
        const {
            field: { value },
            form,
            mode,
        } = this.props;

        if (mode === MODE.EDIT) {
            const { valueDate: state } = this.state;
            const { selectedOption, valueDate } = value;

            const firstWorkingDateValue = new Date(this.getDefaultValueDate());
            const isBefore = valueDate < firstWorkingDateValue;

            if (selectedOption === SCHEDULER_OPTIONS.TODAY && isBefore) {
                form.setFieldValue("scheduler", {
                    ...value,
                    selectedOption: SCHEDULER_OPTIONS.FUTURE,
                    valueDate: state,
                });
            } else if (!state && selectedOption === SCHEDULER_OPTIONS.FUTURE) {
                this.setState({ valueDate: value.valueDate });
            }
        }
    }

    getFrequencySubOptions = () => {
        const { data, field, idForm } = this.props;
        const { valueDate, program, selectedOption } = field.value;

        let element = null;

        switch (selectedOption) {
            case SCHEDULER_OPTIONS.FUTURE:
                element = (
                    <FutureFrequencySubOption
                        enabledWeekDays={data.enabledWeekDays}
                        firstWorkingDate={data.firstWorkingDate}
                        maxDaysToSchedule={data.maxDaysToSchedule}
                        nonWorkingDays={data.nonWorkingDays}
                        onChange={this.handleValueChange}
                        value={valueDate}
                        idForm={idForm}
                    />
                );

                break;
            case SCHEDULER_OPTIONS.WEEKLY:
                element = (
                    <WeeklyFrequencySubOption
                        enabledWeekDays={data.enabledWeekDays}
                        firstWorkingDate={data.firstWorkingDate}
                        nonWorkingDays={data.nonWorkingDays}
                        onChange={this.handleValueChange}
                        value={program}
                    />
                );

                break;
            case SCHEDULER_OPTIONS.MONTHLY:
                element = (
                    <MonthlyFrequencySubOption
                        enabledWeekDays={data.enabledWeekDays}
                        firstWorkingDate={data.firstWorkingDate}
                        nonWorkingDays={data.nonWorkingDays}
                        onChange={this.handleValueChange}
                        value={program}
                    />
                );

                break;
            default:
                break;
        }

        return element;
    };

    getOptions = () => {
        const {
            data: { programable, schedulable },
            field,
        } = this.props;
        const { selectedOption } = field.value;

        const firstWorkingDateValue = new Date(this.getDefaultValueDate());

        if (schedulable || programable) {
            return (
                <React.Fragment>
                    {schedulable && (
                        <React.Fragment>
                            {IsSameDay(firstWorkingDateValue) && (
                                <RadioOption
                                    checked={selectedOption === SCHEDULER_OPTIONS.TODAY}
                                    onChange={this.handleOptionChange}
                                    formId={FORM_ID}
                                    value={SCHEDULER_OPTIONS.TODAY}
                                />
                            )}
                            <RadioOption
                                checked={selectedOption === SCHEDULER_OPTIONS.FUTURE}
                                formId={FORM_ID}
                                onChange={this.handleOptionChange}
                                value={SCHEDULER_OPTIONS.FUTURE}
                            />
                        </React.Fragment>
                    )}
                    {programable && (
                        <React.Fragment>
                            <RadioOption
                                checked={selectedOption === SCHEDULER_OPTIONS.WEEKLY}
                                formId={FORM_ID}
                                onChange={this.handleOptionChange}
                                value={SCHEDULER_OPTIONS.WEEKLY}
                            />
                            <RadioOption
                                checked={selectedOption === SCHEDULER_OPTIONS.MONTHLY}
                                formId={FORM_ID}
                                onChange={this.handleOptionChange}
                                value={SCHEDULER_OPTIONS.MONTHLY}
                            />
                        </React.Fragment>
                    )}
                </React.Fragment>
            );
        }

        return null;
    };

    handleClick = () => {
        const { field, form } = this.props;

        form.setFieldValue(field.name, { ...field.value, editing: true });
    };

    handleOptionChange = (selectedOption) => {
        const { monthlyProgram, valueDate, weeklyProgram } = this.state;
        const {
            field: { name, value },
            form,
        } = this.props;
        let valueDateVal = null;

        const defaultDate = new Date(this.getDefaultValueDate());

        switch (selectedOption) {
            case SCHEDULER_OPTIONS.TODAY:
                valueDateVal = new Date(this.getDefaultValueDate());
                break;
            case SCHEDULER_OPTIONS.FUTURE:
                if (valueDate) {
                    valueDateVal = valueDate;
                } else {
                    valueDateVal = IsSameDay(defaultDate) ? AddDay(new Date(defaultDate), 1) : defaultDate;
                }

                break;
            default:
                break;
        }

        let programVal = null;

        switch (selectedOption) {
            case SCHEDULER_OPTIONS.WEEKLY:
                programVal = weeklyProgram || this.getDefaultWeeklyProgram();

                break;
            case SCHEDULER_OPTIONS.MONTHLY:
                programVal = monthlyProgram || this.getDefaultMonthlyProgram();

                break;
            default:
                break;
        }

        const values = {
            valueDate: valueDateVal,
            program: programVal,
        };

        switch (value.selectedOption) {
            case SCHEDULER_OPTIONS.FUTURE:
                this.setState({ valueDate: value.valueDate });

                break;
            case SCHEDULER_OPTIONS.WEEKLY:
                this.setState({ weeklyProgram: value.program });

                break;
            case SCHEDULER_OPTIONS.MONTHLY:
                this.setState({ monthlyProgram: value.program });

                break;
            default:
                break;
        }

        form.setFieldValue(name, { ...value, ...values, selectedOption });

        const { errors } = form;

        if (errors && errors[name]) {
            const newErrors = UtilLodash.omitElements(errors, name);

            form.setErrors(newErrors);
        }
    };

    handleValueChange = (data) => {
        const { field, form } = this.props;
        const { selectedOption } = field.value;

        if (selectedOption === SCHEDULER_OPTIONS.FUTURE) {
            form.setFieldValue(field.name, { ...field.value, valueDate: data });
            this.setState({ valueDate: data });
        } else {
            form.setFieldValue(field.name, { ...field.value, program: data });
        }

        const { errors } = form;

        if (errors && errors[field.name]) {
            const newErrors = UtilLodash.omitElements(errors, field.name);

            form.setErrors(newErrors);
        }
    };

    updatePastDate(value) {
        if (value && value.valueDate) {
            const currentDate = new Date();
            const valueDate = new Date(SpecificDate(value.valueDate, FORMAT_DATE_SCHEDULER_FIELD));
            const { form } = this.props;

            if (IsDateLessThanToday(valueDate) && !IsSameDay(currentDate, valueDate)) {
                form.values.scheduler.selectedOption = SCHEDULER_OPTIONS.TODAY;
                // Se obtiene el primer dia habil
                form.values.scheduler.valueDate = new Date(this.getDefaultValueDate());
            } else {
                form.values.scheduler.valueDate = valueDate; // Se formatea el valueDate
            }
        }
    }

    getDefaultMonthlyProgram() {
        const { field } = this.props;
        const { monthlyProgram } = field.value;

        if (monthlyProgram) {
            return monthlyProgram.program;
        }
        return {
            frequency: SCHEDULER_OPTIONS.MONTHLY,
            day: 1,
            lapse: "UNLIMITED",
        };
    }

    getDefaultValueDate() {
        const { data } = this.props;

        return SpecificDate(data.firstWorkingDate, FORMAT_DATE_SCHEDULER_FIELD);
    }

    getDefaultWeeklyProgram() {
        const { data, field } = this.props;
        const { weeklyProgram } = field.value;

        if (weeklyProgram) {
            return weeklyProgram.program;
        }
        let idx = 1;

        while (idx < data.enabledWeekDays.length && !data.enabledWeekDays[idx]) {
            idx += 1;
        }

        return {
            frequency: SCHEDULER_OPTIONS.WEEKLY,
            day: idx,
            lapse: "UNLIMITED",
        };
    }

    render() {
        const {
            data: { mode, programable, schedulable },
            field: { value, name },
            form: { errors, touched },
        } = this.props;
        const { editing } = value || false;

        const hasError = touched && touched[name] && errors && errors[name];

        this.updatePastDate(value);

        if (programable || schedulable) {
            if (mode === MODE.EDIT) {
                return (
                    <div className={`form-group form-group--scheduler ${hasError ? "has-error" : EMPTY_STR}`}>
                        {editing ? (
                            <React.Fragment>
                                <div className="form-group-text">
                                    <label className="control-label">
                                        <I18n id="scheduler.schedule" />
                                    </label>
                                </div>
                                <div className="check-list">{this.getOptions()}</div>
                                {this.getFrequencySubOptions()}
                                {hasError && (
                                    <div className="has-error">
                                        <FieldError error={errors[name].value || errors[name]} />
                                    </div>
                                )}
                            </React.Fragment>
                        ) : (
                            <div className="form-group-text">
                                <div>
                                    <a className="btn" onClick={this.handleClick}>
                                        <I18n id="scheduler.schedule" />
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                );
            }
        }

        return null;
    }
}

export default Component;
