import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { CDP_CURRENCY, EMPTY_STR, PIPE_SEPARATOR } from "~/constants";
import {
    SelectorsAction as SelectorsActionFactoring,
    SelectorsStore as SelectorsStoreFactoring,
} from "~/store/factoring/advancePayments";

import DataNumber from "~/components/DataNumber";
import I18n from "~/components/I18n";
import Ticket from "~/components/Ticket/Ticket";

import Style from "./Step3View.rules.scss";
import AdvancePaymentsLines from "./_advancePaymentLines";

export const { NAME } = Style;

export const PROP = {
    types: {
        transaction: PropTypes.shape({
            idTransaction: PropTypes.string,
            idTransactionStatus: PropTypes.string,
            data: PropTypes.shape({
                paymentList: PropTypes.array,
            }),
        }).isRequired,
        discountableUYU: PropTypes.shape({
            total: PropTypes.number,
            amount: PropTypes.number,
        }).isRequired,
        discountableUSD: PropTypes.shape({
            total: PropTypes.number,
            amount: PropTypes.number,
        }).isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const { dispatch, ticketConfirmation, transaction } = props;
    const { data, idTransaction } = transaction || {};
    const { paymentList } = data;

    const [discountableUYU, setTotalDiscountableUYU] = React.useState({ amount: 0, total: 0 });
    const [discountableUSD, setTotalDiscountableUSD] = React.useState({ amount: 0, total: 0 });

    const paymentListError = paymentList.filter((item) => item.returnCode !== 0);
    const paymentListOk = paymentList.filter((item) => item.returnCode === 0);

    React.useEffect(() => {
        if (paymentListOk) {
            let counterUYU = { amount: 0, total: 0 };
            let counterUSD = { amount: 0, total: 0 };

            paymentListOk.forEach(({ currency, netAmount }) => {
                if (`${currency}` === CDP_CURRENCY.UYU) {
                    counterUYU = {
                        amount: counterUYU.amount + netAmount,
                        total: counterUYU.total + 1,
                    };
                } else {
                    counterUSD = {
                        amount: counterUSD.amount + netAmount,
                        total: counterUSD.total + 1,
                    };
                }
                setTotalDiscountableUYU(counterUYU);
                setTotalDiscountableUSD(counterUSD);
            });
        }
    }, []);

    const renderUYU = discountableUYU.total > 0;
    const renderUSD = discountableUSD.total > 0;
    const renderBoth = renderUYU && renderUSD;

    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    handleReject={handleReject}
                    handleSign={handleSign}
                    hasMobileComponent
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}>
                    {/* SALDO */}
                    {paymentListOk?.length > 0 && (
                        <div className="data-wrapper__balance">
                            <span className="data-label">
                                <I18n id="client.factoring.advancePaymentOrders.transaction.step3.balance" />
                            </span>
                            <div className="amount">
                                {renderUSD && <DataNumber value={discountableUSD.amount} prefix={CDP_CURRENCY.USD} />}
                                <span>{renderBoth && PIPE_SEPARATOR}</span>
                                {renderUYU > 0 && (
                                    <DataNumber value={discountableUYU.amount} prefix={CDP_CURRENCY.UYU} />
                                )}
                            </div>
                        </div>
                    )}
                    {/* CANTIDAD DE ADELANTOS */}
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="client.factoring.advancePaymentOrders.transaction.step3.totalAdvances" />
                        </span>
                        <span className="data-date">{discountableUSD.total + discountableUYU.total}</span>
                    </div>
                    {/* ADELANTOS LISTADO ERROR */}
                    {paymentListError?.length > 0 && (
                        <div className="data-wrapper advance-payments-lines">
                            <h3 className="form-group-error data-title icon-error">
                                <I18n id="client.factoring.advancePaymentOrders.transaction.step3.advancesErrors" />
                            </h3>
                            <AdvancePaymentsLines list={paymentListError} />
                        </div>
                    )}
                    {/* ADELANTOS LISTADO OK */}
                    {paymentListOk?.length > 0 && (
                        <div className="data-wrapper advance-payments-lines">
                            <h3 className="data-title">
                                <I18n
                                    id={`client.factoring.advancePaymentOrders.transaction.step3.advances${
                                        paymentListError?.length > 0 ? "Ok" : EMPTY_STR
                                    }`}
                                />
                            </h3>
                            <AdvancePaymentsLines list={paymentListOk} />
                        </div>
                    )}
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        dispatch(
            SelectorsActionFactoring.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        dispatch(
            SelectorsActionFactoring.signTransactionPreview({
                currentDiscountableUSD: discountableUSD,
                currentDiscountableUYU: discountableUYU,
                transaction,
            }),
        );
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        currentDiscountableUSD: SelectorsStoreFactoring.getCurrentDiscountableUSD(store),
        currentDiscountableUYU: SelectorsStoreFactoring.getCurrentDiscountableUYU(store),
        transaction: SelectorsStoreFactoring.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
