import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { EMPTY_STR } from "~/constants";
import {
    SelectorsStore as SelectorsStoreUploadPaymentOrders,
    SelectorsAction as SelectorsActionUploadPaymentOrders,
} from "~/store/factoring/uploadPaymentOrders";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as UtilsI18n from "~/util/i18n";
import * as UtilsNumber from "~/util/number";

import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Token from "~/components/Token/Token";
import FormattedAmount from "~/pages/_components/FormattedAmount";

import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

const FORM_ID = "client.factoring.uploadPaymentOrders";
export const PROP = {
    types: {
        isSubmitting: PropTypes.bool,
        totalizers: PropTypes.array.isRequired,
    },
    defaults: {
        isSubmitting: false,
    },
};

/**
 * Formulario: Importar órdenes de pago.
 * Step2Preview: Mensaje de confirmacion de la operacion.
 */

export function Component(props) {
    const { isSubmitting, totalizers } = props;

    return (
        <section id={Style.ID}>
            {totalizers.map(({ currency, amount, quantity }) => {
                return (
                    <React.Fragment>
                        <section className="message">
                            <span>
                                <I18n id={`${FORM_ID}.step2.message.part1`} />
                            </span>
                            <span className="total-orders">
                                {quantity}
                                <I18n id={`${FORM_ID}.step2.message.part2.${quantity > 1 ? "multiple" : "single"}`} />
                            </span>
                            <span> {UtilsI18n.get(`${FORM_ID}.step2.message.part3.${currency}`)}</span>
                        </section>
                        <div className="total-amount">
                            <FormattedAmount
                                currency={`${currency}`}
                                quantity={`${UtilsNumber.formatNumber(amount, UtilsI18n.getLang())}`}
                            />
                        </div>
                    </React.Fragment>
                );
            })}
            <Form>
                <Token labelButton="global.send" isSubmitting={isSubmitting}>
                    <Button size="sm" variant="secondary" disabled={isSubmitting} onClick={handleCancel}>
                        <I18n id="global.cancel" />
                    </Button>
                </Token>
            </Form>
        </section>
    );

    function handleCancel() {
        const { dispatch, location } = props;
        const isFromTransactionsHistory = location.pathname.includes("/transaction/");

        if (isFromTransactionsHistory) {
            dispatch(GoBack());
        } else {
            dispatch(SelectorsActionUploadPaymentOrders.previewCancelRequest());
        }
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        isSubmitting: SelectorsStoreUploadPaymentOrders.getIsSubmitting(store),
        totalizers: SelectorsStoreUploadPaymentOrders.getTotalizers(store) || [],
        transaction: SelectorsStoreUploadPaymentOrders.getTransaction(store),
        user: SelectorsStoreSession.getUser(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithRouter,
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        validationSchema: (props) => {
            const { user } = props;

            return Yup.object().shape({
                otp: user.needsBiometric
                    ? Yup.string().nullable()
                    : Yup.string().required(UtilsI18n.get("form.credential.otp.required")),
            });
        },
        handleSubmit: (data, formikBag) => {
            const {
                dispatch,
                history,
                transaction: { idTransaction },
            } = formikBag.props;
            const { otp } = data;

            const isFromTransactionsHistory = history.location.pathname.includes("/transaction/");

            if (isFromTransactionsHistory) {
                dispatch(
                    SelectorsActionUploadPaymentOrders.signTransaction({
                        idForm: null,
                        idActivity: "client.factoring.paymentOrders.upload.execute",
                        idTransaction,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            } else {
                dispatch(SelectorsActionUploadPaymentOrders.uploadPaymentOrdersSendFormRequest({ otp, formikBag }));
            }
        },
    }),
)(Component);
