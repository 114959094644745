import React from "react";

import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

import { EMPTY_STR } from "~/constants";
import { Types as TypesHtmlElement, Defaults as DefaultsHtmlElement } from "~/util/prop/html-element";

import I18n from "~/components/I18n";

export const NAME = "Modal";

export const PROP = {
    types: {
        ...TypesHtmlElement,
        show: PropTypes.bool,
        closeButton: PropTypes.bool,
        onClose: PropTypes.func,
        children: PropTypes.array,
        labelKey: PropTypes.string,
        backdrop: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    },
    defaults: {
        ...DefaultsHtmlElement,
        show: false,
        closeButton: false,
        onClose: () => {},
        children: null,
        labelKey: EMPTY_STR,
        backdrop: true,
    },
};

export function Component(props) {
    const { show, children, closeButton, onClose, labelKey, backdrop } = props;
    return (
        <div className="modal-container">
            <Modal show={show} onHide={onClose} backdrop={backdrop}>
                {closeButton && (
                    <Modal.Header closeButton>
                        {labelKey && (
                            <section className="container--layout align-items-center">
                                <Modal.Title>
                                    <I18n id={labelKey} />
                                </Modal.Title>
                            </section>
                        )}
                    </Modal.Header>
                )}
                <Modal.Body>{children}</Modal.Body>
            </Modal>
        </div>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
