import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { Types as TypesHtmlElement, Defaults as DefaultsHtmlElement } from "~/util/prop/html-element";

export const NAME = "FrequentQuestionsItem";

export const PROP = {
    types: {
        ...TypesHtmlElement,
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
    },
    defaults: {
        ...DefaultsHtmlElement,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        active: false,
    };

    toggleClass = () => {
        const { active } = this.state;
        this.setState({ active: !active });
    };

    render() {
        const { active } = this.state;
        const { title, content } = this.props;
        return (
            <div
                className={`question-item ${active ? "expanded" : ""}`}
                onClick={this.toggleClass}
                onKeyDown={() => this.setState({ active: !active })}
                role="button"
                tabIndex={0}>
                <div className="question-item-header">
                    <span className="question-item-title">{title}</span>
                    <div className="question-item-arrow" />
                </div>
                <div className="question-item-content">
                    <span className="question-item-text">{content}</span>
                </div>
            </div>
        );
    }
}

export default Connect(null)(Component);
