import React from "react";

import PropTypes from "prop-types";
import { withRouter as WithRouter } from "react-router-dom";
import { compose as Compose } from "redux";

import { EMPTY_STR } from "~/constants";
import UtilsDevice from "~/util/device";
import { generateId as GenerateId } from "~/util/general";
import * as I18n from "~/util/i18n";

import HighOrder from "~/components/HighOrder";

export const NAME = "TransferHistory";

export const PROP = {
    members: {
        typesContent: {
            latestTransactionsIsSelected: PropTypes.bool.isRequired,
        },
    },
    types: {
        fetching: PropTypes.bool.isRequired,
        itemRef: PropTypes.func,
        latestTransactionsIsSelected: PropTypes.bool,
        list: PropTypes.array,
        onItemClicked: PropTypes.func,
        toggleSelected: PropTypes.func,
    },
    defaults: {
        itemRef: () => {},
        latestTransactionsIsSelected: false,
        list: [],
        onClick: null,
        onItemClicked: null,
    },
};

export function Component(props) {
    const { fetching, latestTransactionsIsSelected, list, onItemClicked, toggleSelected } = props;
    const getListItem = list.map((item) => <Component.Content onItemClicked={onItemClicked} item={item} />);

    const classNameRow = !latestTransactionsIsSelected ? "item-row templateList" : "item-row";

    return (
        <React.Fragment>
            <div className="transfer-setup-block transfer-header">
                <div className="transfer-header-block">
                    <div
                        id="latestTransactions"
                        className={`transfer-header-button${latestTransactionsIsSelected ? " selected" : EMPTY_STR}`}
                        onClick={toggleSelected}>
                        {I18n.get("transfers.latest")}
                    </div>
                    <div
                        id="latestTemplates"
                        className={`transfer-header-button${!latestTransactionsIsSelected ? " selected" : EMPTY_STR}`}
                        onClick={toggleSelected}>
                        {I18n.get("transfers.templates")}
                    </div>
                </div>
                <div className="transfer-header-clear" />
            </div>
            <section className="transfer-setup-block transfer-content transfer-history">
                {getListItem.length <= 0 && !fetching && (
                    <div className="transfer-history-list">
                        <div className="item-row">
                            {latestTransactionsIsSelected && <div>{I18n.get("transfers.no.history")}</div>}
                            {!latestTransactionsIsSelected && <div>{I18n.get("transfers.no.templates")}</div>}
                        </div>
                    </div>
                )}
                {getListItem.length > 0 && (
                    <div
                        className={`transfer-history-list ${
                            !latestTransactionsIsSelected ? "transfer-history-list-templates" : ""
                        }`}>
                        <div className="transfer-history-header">
                            <div className={classNameRow}>
                                {latestTransactionsIsSelected ? (
                                    UtilsDevice.isDisplayDesktop() && (
                                        <div className="item-cell item-cell-smaller">
                                            {I18n.get("transfers.transferType")}
                                        </div>
                                    )
                                ) : (
                                    <div className="item-cell">{I18n.get("transfers.template.name")}</div>
                                )}
                                {latestTransactionsIsSelected && (
                                    <div className="item-cell">{I18n.get("transfers.creditAccountLabel")}</div>
                                )}
                                {latestTransactionsIsSelected && (
                                    <div className="item-cell">{I18n.get("transfers.bank")}</div>
                                )}
                                {latestTransactionsIsSelected && (
                                    <div className="item-cell">{I18n.get("transfers.creditAccount")}</div>
                                )}
                                {!latestTransactionsIsSelected && (
                                    <div className="item-cell">{I18n.get("transfers.currency")}</div>
                                )}
                            </div>
                        </div>
                        <div className="transfer-history-content">
                            {list.map((item) => (
                                <Component.Content
                                    classNameRow={classNameRow}
                                    item={item}
                                    key={GenerateId()}
                                    latestTransactionsIsSelected={latestTransactionsIsSelected}
                                    onItemClicked={onItemClicked}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </section>
        </React.Fragment>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

Component.Content = function Content(props) {
    const { classNameRow, item, itemRef, latestTransactionsIsSelected, onItemClicked } = props;
    const { bankName, beneficiary, currencyName, destinationBank, destinationProduct, idForm, name } = item;

    if (latestTransactionsIsSelected) {
        return (
            <div
                ref={itemRef}
                className={classNameRow}
                onClick={(e) => onItemClicked(e, item, `/form/${idForm}`, false)}>
                {UtilsDevice.isDisplayDesktop() && <div className="item-cell item-cell-smaller">{name}</div>}
                <div className="item-cell">{beneficiary}</div>
                <div className="item-cell">{destinationBank}</div>
                <div className="item-cell">{destinationProduct}</div>
            </div>
        );
    }

    return (
        <div ref={itemRef} className={classNameRow} onClick={(e) => onItemClicked(e, item, `/form/${idForm}`, true)}>
            <div className="item-cell">{name}</div>
            <div className="item-cell">{bankName}</div>
            <div className="item-cell">{currencyName}</div>
        </div>
    );
};

Component.Content.displayName = `${NAME}children`;
Component.Content.propTypes = PROP.members.typesContent;
Component.Content.defaultProps = PROP.members.defaultsContent;

export default Compose(WithRouter, HighOrder.Resizable)(Component);
