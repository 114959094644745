import React from "react";

import { connect as Connect } from "react-redux";

import { EMPTY_STR, ID_FORM_COMEX, NO_DATA, SPACE_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";

import Box from "~/components/Box";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import FieldLabel from "~/pages/_components/fields/FieldLabel";

export const NAME = "OperationNumberDetail";

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const { othersFields, idForm } = props;

    const renderContent = () => {
        let content;

        switch (idForm) {
            case ID_FORM_COMEX.BILLING_CLAIM: {
                const { NROFACTURA, MONEDA, IMPORTE, GIRADO } = othersFields;
                content = (
                    <React.Fragment>
                        <Box flex>
                            <FieldLabel labelKey="comex.export.billingClaim.operationNumber.dialog.amount" useColon />
                            <Box className="py-3 pl-3" tag="span">
                                <FormattedAmount quantity={parseFloat(IMPORTE)} currency={MONEDA} />
                            </Box>
                        </Box>
                        <Box flex>
                            <FieldLabel
                                labelKey="comex.export.billingClaim.operationNumber.dialog.invoiceNumber"
                                useColon
                            />
                            <Box className="py-3 pl-3" tag="span">
                                {NROFACTURA}
                            </Box>
                        </Box>
                        <Box flex>
                            <FieldLabel
                                labelKey="comex.export.billingClaim.operationNumber.dialog.recipient"
                                useColon
                            />
                            <Box className="py-3 pl-3" tag="span">
                                {GIRADO}
                            </Box>
                        </Box>
                    </React.Fragment>
                );
                break;
            }
            case ID_FORM_COMEX.MODIFY_CREDIT_LETTER:
            case ID_FORM_COMEX.REQUEST_ENDORSEMENT: {
                const { BENEFICIARIO, MONEDA, IMPORTE } = othersFields;
                const beneficiary = BENEFICIARIO === EMPTY_STR || BENEFICIARIO === SPACE_STR ? NO_DATA : BENEFICIARIO;

                content = (
                    <React.Fragment>
                        <Box flex>
                            <FieldLabel
                                labelKey="comex.import.modifyCreditLetter.operationNumber.dialog.recipient"
                                useColon
                            />
                            <Box className="py-3 pl-3" tag="span">
                                {beneficiary}
                            </Box>
                        </Box>
                        <Box flex>
                            <FieldLabel
                                labelKey="comex.import.modifyCreditLetter.operationNumber.dialog.amount"
                                useColon
                            />
                            <Box className="py-3 pl-3" tag="span">
                                <FormattedAmount quantity={parseFloat(IMPORTE)} currency={MONEDA} />
                            </Box>
                        </Box>
                    </React.Fragment>
                );

                break;
            }
            case ID_FORM_COMEX.TRANSFER_CREDIT_LETTER: {
                const { EMISOR, FECHAVTO, IMPORTE, MONEDA, ORDENANTE, ULTFECHAEMB } = othersFields;
                const bank = EMISOR === EMPTY_STR || EMISOR === SPACE_STR ? NO_DATA : EMISOR;
                const dueDate = FECHAVTO && (FECHAVTO !== EMPTY_STR || FECHAVTO !== SPACE_STR) ? FECHAVTO : NO_DATA;
                const payer = ORDENANTE === EMPTY_STR || ORDENANTE === SPACE_STR ? NO_DATA : ORDENANTE;

                content = (
                    <React.Fragment>
                        <Box flex>
                            <FieldLabel
                                labelKey="comex.export.transferCreditLetter.operationNumber.dialog.payer"
                                useColon
                            />
                            <Box className="py-3 pl-3" tag="span">
                                {payer}
                            </Box>
                        </Box>
                        <Box flex>
                            <FieldLabel
                                labelKey="comex.export.transferCreditLetter.operationNumber.dialog.amount"
                                useColon
                            />
                            <Box className="py-3 pl-3" tag="span">
                                <FormattedAmount quantity={parseFloat(IMPORTE)} currency={MONEDA} />
                            </Box>
                        </Box>
                        <Box flex>
                            <FieldLabel
                                labelKey="comex.export.transferCreditLetter.operationNumber.dialog.bank"
                                useColon
                            />
                            <Box className="py-3 pl-3" tag="span">
                                {bank}
                            </Box>
                        </Box>
                        <Box flex>
                            <FieldLabel
                                labelKey="comex.export.transferCreditLetter.operationNumber.dialog.dueDate"
                                useColon
                            />
                            <Box className="py-3 pl-3" tag="span">
                                {dueDate}
                            </Box>
                        </Box>
                        <Box flex>
                            <FieldLabel
                                labelKey="comex.export.transferCreditLetter.operationNumber.dialog.lastShippingDate"
                                useColon
                            />
                            <Box className="py-3 pl-3" tag="span">
                                {ULTFECHAEMB}
                            </Box>
                        </Box>
                    </React.Fragment>
                );

                break;
            }
            case ID_FORM_COMEX.CANCELLATION_CREDIT_LETTER: {
                const { BENEFICIARIO, MONEDA, IMPORTE, SUBOPE } = othersFields;
                const beneficiary = BENEFICIARIO === EMPTY_STR || BENEFICIARIO === SPACE_STR ? "N/A" : BENEFICIARIO;
                const subOperation = !SUBOPE || SUBOPE === EMPTY_STR || SUBOPE === SPACE_STR ? "N/A" : SUBOPE;

                content = (
                    <React.Fragment>
                        <Box flex>
                            <FieldLabel
                                labelKey="comex.import.cancellationCreditLetter.operationNumber.dialog.suboperation"
                                useColon
                            />
                            <Box className="py-3 pl-3" tag="span">
                                {subOperation}
                            </Box>
                        </Box>
                        <Box flex>
                            <FieldLabel
                                labelKey="comex.import.cancellationCreditLetter.operationNumber.dialog.amount"
                                useColon
                            />
                            <Box className="py-3 pl-3" tag="span">
                                <FormattedAmount quantity={parseFloat(IMPORTE)} currency={MONEDA} />
                            </Box>
                        </Box>
                        <Box flex>
                            <FieldLabel
                                labelKey="comex.import.cancellationCreditLetter.operationNumber.dialog.beneficiary"
                                useColon
                            />
                            <Box className="py-3 pl-3" tag="span">
                                {beneficiary}
                            </Box>
                        </Box>
                    </React.Fragment>
                );
                break;
            }
            case ID_FORM_COMEX.BILLING_CANCELLATION:
                const { NROOPERACION, IMPORTE, MONEDA, GIRADOR } = othersFields;
                content = (
                    <React.Fragment>
                        <Box flex>
                            <FieldLabel
                                labelKey="comex.import.modifyCreditLetter.operationNumber.dialog.amount"
                                useColon
                            />
                            <Box className="py-3 pl-3" tag="span">
                                <FormattedAmount quantity={parseFloat(IMPORTE)} currency={MONEDA} />
                            </Box>
                        </Box>
                        <Box flex>
                            <FieldLabel labelKey="comex.import.cancellationCollection.dialog.drawer" useColon />
                            <Box className="py-3 pl-3" tag="span">
                                {GIRADOR}
                            </Box>
                        </Box>
                    </React.Fragment>
                );
                break;
            case ID_FORM_COMEX.COMEX_IMPORT_DOCUMENT_PRESENTATION:   
            case ID_FORM_COMEX.DISCREPANCY_DOCUMENT_AUTHORIZATION:
            case ID_FORM_COMEX.PRESENTATION_CREDIT_LETTER: {
                const { CARTADECREDITO, MONEDA, IMPORTE } = othersFields;
                content = (
                    <React.Fragment>
                        <Box flex>
                            <FieldLabel labelKey={`${idForm}.operationNumber.dialog.recipient`} useColon />
                            <Box className="py-3 pl-3" tag="span">
                                {CARTADECREDITO}
                            </Box>
                        </Box>
                        <Box flex>
                            <FieldLabel labelKey={`${idForm}.operationNumber.dialog.amount`} useColon />
                            <Box className="py-3 pl-3" tag="span">
                                <FormattedAmount quantity={parseFloat(IMPORTE)} currency={MONEDA} />
                            </Box>
                        </Box>
                    </React.Fragment>
                );
                break;
            }
            case ID_FORM_COMEX.CREDIT_LETTER_PURCHASE: {
                const { SUBOPE, CARTADECREDITO, MONEDA, IMPORTE, ORDENANTE } = othersFields;
                content = (
                    <React.Fragment>
                        <Box flex>
                            <FieldLabel labelKey={`${idForm}.operationNumber.dialog.sub.operation`} useColon />
                            <Box className="py-3 pl-3" tag="span">
                                {SUBOPE}
                            </Box>
                        </Box>
                        <Box flex>
                            <FieldLabel labelKey={`${idForm}.operationNumber.dialog.creditLetterNumber`} useColon />
                            <Box className="py-3 pl-3" tag="span">
                                {CARTADECREDITO}
                            </Box>
                        </Box>

                        <Box flex>
                            <FieldLabel labelKey={`${idForm}.operationNumber.dialog.amount`} useColon />
                            <Box className="py-3 pl-3" tag="span">
                                <FormattedAmount quantity={parseFloat(IMPORTE)} currency={MONEDA} />
                            </Box>
                        </Box>
                        <Box flex>
                            <FieldLabel labelKey={`${idForm}.operationNumber.dialog.payer`} useColon />
                            <Box className="py-3 pl-3" tag="span">
                                {ORDENANTE}
                            </Box>
                        </Box>
                    </React.Fragment>
                );
                break;
            }
            case ID_FORM_COMEX.LIFT_CREDIT_LETTER_DISCREPANCIES: {
                const { BENEFICIARIO, MONEDA, IMPORTE, SUBOPE } = othersFields;

                content = (
                    <React.Fragment>
                        <Box flex>
                            <FieldLabel
                                labelKey="comex.import.liftCreditLetterWithDiscrepancies.operationNumber.dialog.subope"
                                useColon
                            />
                            <Box className="py-3 pl-3" tag="span">
                                {SUBOPE}
                            </Box>
                        </Box>
                        <Box flex>
                            <FieldLabel
                                labelKey="comex.import.liftCreditLetterWithDiscrepancies.operationNumber.dialog.amount"
                                useColon
                            />
                            <Box className="py-3 pl-3" tag="span">
                                <FormattedAmount quantity={parseFloat(IMPORTE)} currency={MONEDA} />
                            </Box>
                        </Box>
                        <Box flex>
                            <FieldLabel
                                labelKey="comex.import.liftCreditLetterWithDiscrepancies.operationNumber.dialog.beneficiary"
                                useColon
                            />
                            <Box className="py-3 pl-3" tag="span">
                                {BENEFICIARIO}
                            </Box>
                        </Box>
                    </React.Fragment>
                );
                break;
            }
            case ID_FORM_COMEX.MODIFY_BONDS_AND_GUARANTEES: {
                const { BENEFICIARIO, MONEDA, IMPORTE } = othersFields;
                content = (
                    <React.Fragment>
                        <Box flex>
                            <FieldLabel labelKey={`${idForm}.operationNumber.dialog.amount`} useColon />
                            <Box className="py-3 pl-3" tag="span">
                                <FormattedAmount quantity={parseFloat(IMPORTE)} currency={MONEDA} />
                            </Box>
                        </Box>
                        <Box flex>
                            <FieldLabel labelKey={`${idForm}.operationNumber.dialog.beneficiary`} useColon />
                            <Box className="py-3 pl-3" tag="span">
                                {BENEFICIARIO}
                            </Box>
                        </Box>
                    </React.Fragment>
                );
                break;
            }

            case ID_FORM_COMEX.CESSION_CREDIT_LETTER: {
                const { CARTADECREDITO, IMPORTE, MONEDA, ORDENANTE } = othersFields;
                const payer = ORDENANTE === EMPTY_STR || ORDENANTE === SPACE_STR ? NO_DATA : ORDENANTE;

                content = (
                    <React.Fragment>
                        <Box flex>
                            <FieldLabel
                                labelKey="comex.export.cessionCreditLetter.operationNumber.dialog.creditLetterNumber"
                                useColon
                            />
                            <Box className="py-3 pl-3" tag="span">
                                {CARTADECREDITO}
                            </Box>
                        </Box>
                        <Box flex>
                            <FieldLabel
                                labelKey="comex.export.cessionCreditLetter.operationNumber.dialog.amount"
                                useColon
                            />
                            <Box className="py-3 pl-3" tag="span">
                                <FormattedAmount quantity={parseFloat(IMPORTE)} currency={MONEDA} />
                            </Box>
                        </Box>
                        <Box flex>
                            <FieldLabel
                                labelKey="comex.export.cessionCreditLetter.operationNumber.dialog.payer"
                                useColon
                            />
                            <Box className="py-3 pl-3" tag="span">
                                {payer}
                            </Box>
                        </Box>
                    </React.Fragment>
                );

                break;
            }
            default:
                break;
        }
        return content;
    };

    return (
        <Box className="form-group py-3">
            <Box className="paper p-3">{renderContent()}</Box>
        </Box>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    lang: SelectorsStoreI18n.getLang(store),
});

export default Connect(mapStateToProps)(Component);
