import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";

import I18n from "~/components/I18n";

export const NAME = "CustomLabel";

export const PROP = {
    types: {
        editing: PropTypes.bool,
        label: PropTypes.string,
        isRequired: PropTypes.bool,
        optionalMessage: PropTypes.string,
    },
    defaults: { editing: EMPTY_STR, label: EMPTY_STR, isRequired: false, optionalMessage: EMPTY_STR },
};
export function Component(props) {
    const { editing, label, isRequired, optionalMessage } = props;

    if (!editing) {
        return null;
    }
    return (
        <div className="form-group-text">
            <label className="control-label" htmlFor="currency">
                <I18n id="form.field.amount.currency" />
                {!isRequired && <small className="text-optional">{optionalMessage}</small>}
            </label>
            <label className="control-label" htmlFor="quantity">
                {label}
            </label>
        </div>
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
