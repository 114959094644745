import { takeLatest as TakeLatest, call as Call, put as Put, select as Select } from "redux-saga/effects";

import { CREDENTIAL_TYPE_OTP, LEVEL, RESPONSE_TYPE, SCOPE, SIGN_WITH_BIOMETRIC } from "~/constants";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import IsTrustedBiometric from "~/store/form/_sagas/_isTrustedBiometric";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { downloadPdf as downloadPDF } from "~/util/download";
import {
    credentialsWithUnderscore as CredentialsWithUnderscore,
    hasIncorrectCredentials as HasIncorrectCredentials,
    adjustIdFieldErrors as AdjustIdFieldErrors,
} from "~/util/form";
import * as UtilsI18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsStore, SelectorsMiddleware } from "./_selectors";

const sagas = [
    TakeLatest(TYPE.PREVIEW_FORM_REQUEST, uploadSuppliersPreviewFormRequest),
    TakeLatest(TYPE.SEND_FORM_REQUEST, uploadSuppliersSendFormRequest),
    TakeLatest(TYPE.READ_TRANSACTION_REQUEST, requestUploadSuppliersTransaction),
    TakeLatest(TYPE.EXPORT_PDF, uploadSuppliersExportPDF),
    TakeLatest(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, cancelTransactionCustom),
    TakeLatest(TYPE.SIGN_TRANSACTION_PREVIEW_REQUEST, signTransactionPreview),
    TakeLatest(TYPE.SIGN_TRANSACTION_REQUEST, signTransaction),
];

export default sagas;

function* uploadSuppliersPreviewFormRequest(props) {
    const { suppliersList } = props;
    yield Put(SelectorsAction.uploadSuppliersPreviewFormSuccess({ suppliersList }));
}

function* uploadSuppliersSendFormRequest(props) {
    const suppliersList = yield Select(SelectorsStore.getSuppliers);
    const { otp, formikBag } = props;
    const { type, data } = yield Call(SelectorsMiddleware.uploadSuppliersSend, { suppliersList, _otp: otp });
    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = Object.keys(data.data).some((key) => key === CREDENTIAL_TYPE_OTP);
        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
        }
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.FACTORING_UPLOAD_SUPPLIERS],
            }),
        );
        formikBag.setSubmitting(false);
    } else {
        const { idTransaction } = data;
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(SelectorsAction.uploadSuppliersSendFormSuccess({ transaction }));
    }
}

function* requestUploadSuppliersTransaction(props) {
    const { idTransaction } = props;
    const { type, data } = yield Call(SelectorsMiddlewareForm.readTransaction, {
        idTransactionToRead: idTransaction,
    });
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.FACTORING_UPLOAD_SUPPLIERS],
            }),
        );
    } else {
        yield Put(SelectorsAction.readUploadSuppliersTransactionSuccess({ transaction: data.data.transaction }));
    }
}

function* uploadSuppliersExportPDF(props) {
    const { invalidLinesData, uploadDetailList } = props;
    const { type, data } = yield Call(SelectorsMiddleware.uploadSuppliersExportPDF, {
        invalidLines: invalidLinesData,
        uploadDetailList,
    });
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.FACTORING_UPLOAD_SUPPLIERS],
            }),
        );
    } else {
        const { content, fileName } = data.data;
        downloadPDF(fileName, content);
    }
}

function* cancelTransactionCustom(props) {
    const { credentials, idTransaction, formikBag } = props;
    yield* handleCancelTransaction(credentials, idTransaction, formikBag);

    formikBag.setSubmitting(false);
}

function* handleCancelTransaction(credentials, idTransaction, formikBag) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);
    const {
        data: { data },
        type,
    } = yield Call(SelectorsMiddlewareForm.cancelTransaction, {
        idTransactionToCancel: idTransaction,
        ...credentialsWithUnderscore,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data);
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("forms.cancelTransaction.errorMessage"),
                    level: LEVEL.ERROR,
                    scopes: SCOPE.FACTORING_UPLOAD_SUPPLIERS,
                }),
            );
        }
    } else {
        yield* requestUploadSuppliersTransaction({ idTransaction });
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("forms.cancelTransaction.confirmationMessage"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.FACTORING_UPLOAD_SUPPLIERS, SCOPE.DESKTOP],
            }),
        );
    }
}

// Importar proveedores no tiene un preview.
// Solo hacemos esto para ir al paso 2 del formulario y dejar la misma estructura tipo de formularios dinamicos.
function* signTransactionPreview(props) {
    const { suppliersList, transaction } = props;
    yield Put(SelectorsAction.signTransactionPreviewSuccess({ lines: suppliersList, transaction }));
}

function* signTransaction(props) {
    const { idForm, idActivity, idTransaction, credentials, formikBag } = props;
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
    }

    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { data, type } = yield Call(
        SelectorsMiddlewareForm.sign,
        { idForm, idTransaction, ...credentialsWithUnderscore },
        idActivity,
    );

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(AdjustIdFieldErrors(data.data));
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.FACTORING_UPLOAD_SUPPLIERS],
                }),
            );
        }
        formikBag.setSubmitting(false);
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(
            SelectorsAction.uploadSuppliersSendFormSuccess({
                transaction,
                idTransaction,
            }),
        );
        formikBag.setSubmitting(false);
    }
}
