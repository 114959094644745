import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    listAgreementsRequest: (props) => API.executeWithAccessToken(PATH.AUTOMATIC_DEBIT_UPDATE_FORM, props),
    listAutomaticDebitsRequest: (props) => API.executeWithAccessToken(PATH.AUTOMATIC_DEBIT_LIST, props),
};

export const SelectorsAction = {
    clearList: (props) => FactoryAction(TYPE.CLEAR_LIST, props),
    listAgreementsRequest: (props) => FactoryAction(TYPE.LIST_AGREEMENTS_REQUEST, props),
    listAgreementsFailure: (props) => FactoryAction(TYPE.LIST_AGREEMENTS_FAILURE, props),
    listAgreementsSuccess: (props) => FactoryAction(TYPE.LIST_AGREEMENTS_SUCCESS, props),
    changeValue: (props) => FactoryAction(TYPE.CHANGE_VALUE, props),
    listAutomaticDebitsRequest: (props) => FactoryAction(TYPE.LIST_AUTOMATIC_DEBITS_REQUEST, props),
    listAutomaticDebitsFailure: (props) => FactoryAction(TYPE.LIST_AUTOMATIC_DEBITS_FAILURE, props),
    listAutomaticDebitsSuccess: (props) => FactoryAction(TYPE.LIST_AUTOMATIC_DEBITS_SUCCESS, props),
    changeNewsDate: (props) => FactoryAction(TYPE.CHANGE_NEWS_DATE, props),
};

export const SelectorsStore = {
    getFetching: (store) => store[NAME].isFetching,
    getListAgreements: (store) =>
        store[NAME].listAgreements
            ? store[NAME].listAgreements.map((option) => ({
                  id: option.id,
                  label: option.label,
                  agreementCurrency: option.agreementCurrency,
                  feesAllowed: option.feesAllowed,
                  maxReferenceLength: option.maxReferenceLength,
                  referenceLength: option.referenceLength,
                  newsDate: option.newsDate,
                  exampleIcon: option.exampleIcon,
                  exampleText: option.exampleText,
              }))
            : null,
    getListAutomaticDebits: (store) => store[NAME].listAutomaticDebits,
    getListBandredAutomaticDebits: (store) => store[NAME].listBanredAutomaticDebits,
    getChangetValue: (store) => store[NAME].changeValue,
    getNewsDate: (store) => store[NAME].newsDate,
};
