import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.RECOVER_PASS_CLEAN:
            return PROP.defaults;
        case TYPE.RECOVER_PASS_STEP1_REQUEST:
        case TYPE.RECOVER_PASS_STEP2_REQUEST:
        case TYPE.RECOVER_PASS_STEP3_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.RECOVER_PASS_STEP1_FAILURE:
        case TYPE.RECOVER_PASS_STEP2_FAILURE:
        case TYPE.RECOVER_PASS_STEP3_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.RECOVER_PASS_STEP1_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.RECOVER_PASS_STEP2_SUCCESS:
            return {
                ...storeProp,
                exchangeToken: action.exchangeToken,
                fetching: false,
                verificationCode: action.verificationCode,
            };
        case TYPE.RECOVER_PASS_STEP3_SUCCESS:
            return {
                ...PROP.defaults,
                blocked: action.blocked,
            };
        case TYPE.RECOVER_PASS_REMEMBER_USERNAME:
            return {
                ...storeProp,
                username: action.username,
            };
        default:
            return storeProp;
    }
};
