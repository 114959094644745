import { FactoryAction } from "~/util/redux";

import { NAME, TYPE } from "./_consts";

export const SelectorsAction = {
    setParams: (props) => FactoryAction(TYPE.SET_PARAMS, props),
    cleanParams: (props) => FactoryAction(TYPE.CLEAN_PARAMS, props),
    backUrlSistarBanc: (props) => FactoryAction(TYPE.BACK_URL_SISTARBANC, props),
    logOutBevsa: (props) => FactoryAction(TYPE.LOGOUT_BEVSA, props),
    backUrlSistarBancSuccess: (props) => FactoryAction(TYPE.BACK_URL_SISTARBANC_SUCCESS, props),
    backUrlSistarBancError: (props) => FactoryAction(TYPE.BACK_URL_SISTARBANC_ERROR, props),
    // TODO: cuando la redireccion este contemplada fijarse cuales serian los actions validos.
};

export const SelectorsStore = {
    getParams: (store) => ({
        isExternalLogin: store[NAME].isExternalLogin,
        isFromSistarbanc: store[NAME].isFromSistarbanc,
        isFromBevsa: store[NAME].isFromBevsa,
        queryString: store[NAME].queryString,
    }),
    getIsFromSistarbanc: (store) => store[NAME].isFromSistarbanc,
    getIsFromBevsa: (store) => store[NAME].isFromBevsa,
};
