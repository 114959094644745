import React from "react";

import { connect as Connect } from "react-redux";

import { SCOPE, LEVEL, EMPTY_STR } from "~/constants";
import { SelectorsAction as SelectorsActionUploadSuppliers } from "~/store/factoring/uploadSuppliers";
import { SelectorsAction as SelectorsActionMultilineFile } from "~/store/multilinefile";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as UtilsConfig from "~/util/config";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Image from "~/components/Image";
import FileUploader from "~/pages/_components/FileUploader";

export const NAME = "FileUploadSuppliers";

export const PROP = {
    types: {
        ...TypesRedux,
    },
    defaults: {
        ...DefaultsRedux,
    },
};

const FORM_ID = "forms.factoring.suppliers.upload";
const ACTIVITY_ID = "client.factoring.suppliers.upload.execute";
const FIELD_ID = "files";
const ACCEPTED_FILE_TYPES = ["text/plain"];
const MAX_FILE_SIZE_MB = 2;

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        fileHasErrors: false,
        invalidLinesData: null,
        uploadDetailList: null,
    };

    handleFileProcess = (response) => {
        const { dispatch } = this.props;
        const { data } = response;
        const { invalidLinesData, uploadDetailList, errorKey } = data;

        const maxImportLines = UtilsConfig.getInteger("client.factoring.suppliers.maxImportLines", 475);

        if (invalidLinesData) {
            this.setState({
                fileHasErrors: true,
                invalidLinesData,
                uploadDetailList,
            });
        } else if (errorKey) {
            dispatch(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get(errorKey, EMPTY_STR, { MAX_LINES: maxImportLines }),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.FACTORING_UPLOAD_SUPPLIERS],
                }),
            );
            dispatch(SelectorsActionMultilineFile.cleanPond({ forcePondCleanUp: true }));
        } else {
            dispatch(SelectorsActionUploadSuppliers.fileUploadSuppliersSuccess(data));
        }
    };

    handleExportPDF = () => {
        const { dispatch } = this.props;
        const { invalidLinesData, uploadDetailList } = this.state;
        dispatch(SelectorsActionUploadSuppliers.uploadSuppliersExportPDF({ invalidLinesData, uploadDetailList }));
    };

    render() {
        const { value, setValue, dispatch } = this.props;
        const { fileHasErrors } = this.state;

        return (
            <div className={fileHasErrors ? "file-has-error" : EMPTY_STR}>
                <FileUploader
                    name={FIELD_ID}
                    idActivity={ACTIVITY_ID}
                    idForm={FORM_ID}
                    idFormField={FIELD_ID}
                    files={value}
                    allowMultiple={false}
                    maxFileSize={`${MAX_FILE_SIZE_MB}mb`}
                    maxTotalFileSize={`${MAX_FILE_SIZE_MB}mb`}
                    maxFiles={1}
                    acceptedFileTypes={ACCEPTED_FILE_TYPES}
                    onFileProcess={this.handleFileProcess}
                    labelIdle={UtilsI18n.get("client.factoring.uploadSuppliers.fileUploader.label")}
                    onRemoveFile={() => {
                        dispatch(SelectorsActionUploadSuppliers.fileUploadSuppliersRemoved());
                        this.setState({
                            fileHasErrors: false,
                        });
                    }}
                    setValue={setValue}
                    ignoreLimbo
                />
                {fileHasErrors && (
                    <div className="file-error">
                        <Image src="form-error.svg" />
                        <a href="#" onClick={this.handleExportPDF}>
                            {UtilsI18n.get("client.factoring.uploadSuppliers.error.label")}
                        </a>
                    </div>
                )}
            </div>
        );
    }
}

export default Connect(null)(Component);
