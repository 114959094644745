import React from "react";

import { push as Push } from "connected-react-router";
import { Field, Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import { EMPTY_STR, EVENT_BACKBUTTON, INVITATION_CODE_GROUP_LENGTH, LEVEL, SCOPE } from "~/constants";
import Container from "~/containers/External/Dash";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import {
    SelectorsAction as SelectorsActionRecoverPassword,
    SelectorsStore as SelectorsStoreRecoverPassword,
} from "~/store/recoverypassword/noToken";
import UtilsScanner from "~/util/barcodeScanner";
import * as UtilsConfig from "~/util/config";
import UtilsDevice from "~/util/device";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import TextField from "~/pages/_components/fields/TextField";
import WithExchangeToken from "~/pages/_components/withExchangeToken";

import CancelConfirmationModal from "~/pages/enrollment/_CancelConfirmationModal";

import Style from "./RecoveryPass.scss";

export const { NAME, CLASS } = Style;

const FORM_ID = "recoveryPasswordNoToken.step2";

export const PROP = {
    types: {
        isFetching: PropTypes.bool,
    },
    defaults: {
        isFetching: false,
    },
};

export function Component(props) {
    const { isFetching, dispatch } = props;
    const groupLength = UtilsConfig.getInteger("validationCodes.id.groups.length");
    const separators = groupLength - 1;
    const codeLength = groupLength * INVITATION_CODE_GROUP_LENGTH + separators;
    const [modal, setModal] = React.useState(false);
    const [fetchState, setFetchState] = React.useState(false);

    const ignoreBack = () => {
        document.removeEventListener(EVENT_BACKBUTTON, ignoreBack);

        return false;
    };

    const showModal = () => {
        setModal(true);
    };

    const handleConfirmation = () => {
        dispatch(Push("/"));
    };

    const handleCancelation = () => {
        setModal(false);
    };

    const handleScanSuccess = (result) => {
        const { setFieldValue } = props;
        if (result.cancelled) {
            document.addEventListener(EVENT_BACKBUTTON, ignoreBack);
        }

        setFieldValue("invitationCode", result.text);

        setFetchState(false);
    };

    const handleScanError = () => {
        setFetchState(false);

        dispatch(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("recoveryPasswordNoToken.step2.scanQRCode.error"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.INVITATION_CODE],
            }),
        );
    };

    const handleClick = () => {
        setFetchState(true);
        UtilsScanner.scan(handleScanSuccess, handleScanError);
    };

    return (
        <Container
            name={NAME}
            head-onClose={showModal}
            wait={isFetching}
            scopeToShowNotification={SCOPE.RECOVERY_PASSWORD}>
            <div className={CLASS}>
                <div id={Style.NAME}>
                    <CancelConfirmationModal
                        show={modal}
                        handleCancelation={handleCancelation}
                        handleConfirmation={handleConfirmation}
                    />
                    <div className="login-step">
                        <div className="login-header">
                            <span className="login-header-text">
                                <I18n id={`${FORM_ID}.title`} />
                            </span>
                        </div>
                        <div className="login-row">
                            <div className="login-image">
                                <Image src="enrollment.svg" className="login-image-recovery" />
                            </div>
                        </div>
                        <div className="login-row">
                            <Form>
                                <div className="login-form mb">
                                    {UtilsDevice.isMobileNative() && (
                                        <div className="login-form-field">
                                            <Button
                                                image="qr-code.svg"
                                                className="login-form-button custom-button"
                                                type="button"
                                                onClick={handleClick}
                                                label={`${FORM_ID}.scanQRCode`}
                                                loading={fetchState}
                                            />
                                        </div>
                                    )}
                                    <div className="login-form-field hide-label">
                                        <div className="login-form-field-block">
                                            <div className="login-form-field-image">
                                                <Image
                                                    src="verification-code-field.svg"
                                                    className="login-form-field-icon"
                                                />
                                            </div>
                                            <Field
                                                className="login-form-input"
                                                component={TextField}
                                                id="invitationCode"
                                                hideLabel
                                                idForm={FORM_ID}
                                                maxLength={codeLength}
                                                name="invitationCode"
                                                type="text"
                                                autoComplete="off"
                                                labelKey="label"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Button
                                        className="login-form-button btn-margin"
                                        bsStyle="primary"
                                        label="global.continue"
                                        type="submit"
                                    />
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    isFetching: SelectorsStoreRecoverPassword.isFetching(store),
});

export default Compose(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            invitationCode: EMPTY_STR,
        }),
        validationSchema: () =>
            Yup.lazy(() => {
                return Yup.object().shape({
                    invitationCode: Yup.string().required(UtilsI18n.get(`${FORM_ID}.invitationCode.empty`)),
                });
            }),
        handleSubmit: (data, formikBag) => {
            const { dispatch } = formikBag.props;
            const { invitationCode } = data;

            dispatch(SelectorsActionRecoverPassword.recoverPassStep2Request({ formikBag, invitationCode }));
        },
    }),
    WithExchangeToken,
)(Component);
