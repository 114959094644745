import React from "react";

import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsAction as SelectorsActionSuppliers } from "~/store/factoring";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import * as I18nUtil from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import Select from "~/pages/_components/fields/Select";
import TextField from "~/pages/_components/fields/TextField";

import Style from "./_Filters.rules.scss";

export const { NAME, TYPE } = Style;

const FORM_ID = "list.suppliers.filters";

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const { i18n, submitForm } = props;
    const { RUT, CI, documentLabel } = i18n;
    const options = [
        { value: "IDE", label: CI },
        { value: "RUT", label: RUT },
    ];
    const [clean, setClean] = React.useState(false);

    React.useEffect(() => {
        if (clean) {
            submitForm();
            setClean(false);
        }
    }, [clean, submitForm]);

    const handleCleanFilters = () => {
        const { setValues } = props;
        setValues({
            name: null,
            documentType: null,
            documentNumber: EMPTY_STR,
        });
        setClean(true);
    };

    return (
        <Form data-name={NAME} className={Style.CLASS}>
            <div>
                <Field
                    autoFocus={false}
                    component={TextField}
                    idForm={FORM_ID}
                    hidePlaceholder
                    name="name"
                    type="text"
                />
                <Select
                    clearable={false}
                    labelKey="label"
                    options={options}
                    valueKey="value"
                    name="documentType"
                    label={documentLabel}
                />
                <Field
                    autoFocus={false}
                    component={TextField}
                    idForm={FORM_ID}
                    hidelabel
                    hidePlaceholder
                    name="documentNumber"
                    type="text"
                />
                <Box flex>
                    <Button variant="primary" type="submit" size="sm">
                        {I18nUtil.get("product.filters.filter")}
                    </Button>
                    <Button variant="secondary" onClick={handleCleanFilters}>
                        {I18nUtil.get("filters.clean")}
                    </Button>
                </Box>
            </div>
        </Form>
    );
}

const mapStateToProps = (store) => ({
    i18n: {
        CI: StoreI18n.getMessage(store, "client.factoring.suppliers.documentType.IDE"),
        RUT: StoreI18n.getMessage(store, "client.factoring.suppliers.documentType.RUT"),
        documentLabel: StoreI18n.getMessage(store, "list.suppliers.filters.documentType.label"),
    },
});

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        mapPropsToValues: () => ({
            name: null,
            documentType: null,
            documentNumber: null,
        }),
        handleSubmit: ({ ...filters }, formikBag) => {
            const { dispatch } = formikBag.props;
            dispatch(
                SelectorsActionSuppliers.loadListSuppliersRequest({
                    filters,
                    moreOrders: false,
                }),
            );
        },
    }),
)(Component);
