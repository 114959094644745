import React from "react";

import { push as Push } from "connected-react-router";
import { connect as Connect } from "react-redux";

import Container from "~/containers/External/Form";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";
import Image from "~/components/Image";

import Style from "./RecoveryPass.scss";

export const NAME = "RecoveryPassStep4";
const { SUCCESS } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const { dispatch } = props;

    const onHeaderClose = () => {
        dispatch(Push("/"));
    };

    const onLogin = () => {
        dispatch(Push("/login"));
    };

    return (
        <Container
            icon={<Image src="administration-title-icon.svg" />}
            id={NAME}
            head-title="recoveryPassword.step4.pageTitle"
            name={NAME}
            head-onClose={onHeaderClose}
            className={SUCCESS}>
            <div className="finished-image">
                <Image src="finished.svg" />
            </div>
            <section>
                <p>
                    <I18n id="recoveryPassword.step4.intro" />
                </p>
                <Button className="secondary" onClick={onLogin}>
                    {UtilsI18n.get("recoveryPassword.step4.login")}
                </Button>
            </section>
        </Container>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Connect()(Component);
