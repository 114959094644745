import React from "react";

import { Form, withFormik as WithFormik, Field } from "formik";
import { connect as Connect } from "react-redux";

import { CDP_CURRENCY, COMMA, EMPTY_STR, LANGUAGE, LEVEL, SCOPE, SPACE_STR } from "~/constants";
import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { SelectorsAction as SelectorsActionComex, SelectorsStore as SelectorsStoreComex } from "~/store/comex/";
import {
    SelectorsAction as SelectorsActionComexExport,
    SelectorsStore as SelectorsStoreComexExport,
    PROP as PropComex,
} from "~/store/comex/export/";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsAction as SelectorActionNotification } from "~/store/notification";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionTemplate } from "~/store/template";
import { getInteger } from "~/util/config";
import * as ConfigUtil from "~/util/config";
import { formatDate as FormatDate, toDate as ToDate } from "~/util/date";
import { isEmptyObj } from "~/util/general";
import * as i18nUtils from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import EmailList from "~/pages/_components/fields/EmailList";
import TextArea from "~/pages/_components/fields/TextArea";
import TextField from "~/pages/_components/fields/TextField";
import AmountField from "~/pages/_components/fields/formik/AmountField";
import Selector from "~/pages/_components/fields/formik/Selector";

import CreateTemplateModal from "~/pages/forms/Step1Edit/_ModalTemplateCreate";
import ListTemplatesModal from "~/pages/forms/Step1Edit/_ModalTemplateList";
import { Termsandconditions } from "~/pages/forms/_components/_fields";
import Scheduler from "~/pages/forms/_components/_fields/Scheduler";

import Style from "../../../Step1Edit.rules.scss";
import DocumentSection from "../../../_components/DocumentSection";
import FileUploader from "../../../_components/FileUploader";
import AddDocumentModal from "./AddDocumentModal";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...PropComex.types,
    },
    defaults: {
        ...PropComex.defaults,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        documentEdition: {},
        draftLoaded: false,
        showDocumentsModal: false,
    };

    componentDidMount() {
        const { dispatch, formData, isChangingEnvironment } = this.props;

        if (isEmptyObj(formData) && !isChangingEnvironment) {
            dispatch(SelectorsActionComexExport.presentBillingDocumentsPreRequest());
        }
    }

    componentDidUpdate() {
        const { dispatch, formData, preData, setFieldError, setFieldTouched, setValues, transaction } = this.props;
        const { draftLoaded } = this.state;

        if (transaction?.data && Object.keys(formData).length === 0 && preData.nextValidDate && !draftLoaded) {
            this.setState({
                draftLoaded: true,
            });

            const transactionData = transaction?.data;

            /* carga de errores */
            if (transactionData.backendFormErrors?.cdpErrorFields) {
                const { cdpErrorFields } = transactionData.backendFormErrors;

                cdpErrorFields.map((errorField) => {
                    if (errorField === "valueDate") {
                        setFieldError("scheduler", SPACE_STR);
                        setFieldTouched("scheduler", true);
                    } else {
                        setFieldError(errorField, SPACE_STR);
                        setFieldTouched(errorField, true);
                    }

                    return null;
                });
            }

            if (transactionData.backendFormErrors?.message) {
                const errorMessage = transactionData?.backendFormErrors?.message;

                dispatch(
                    SelectorActionNotification.showNotification({
                        message: errorMessage,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.COMEX_EXPORT_PRESENT_BILLING_DOCUMENTS],
                    }),
                );
            } // modificaciones para que agarren bien los fields

            /*  carga del draft */
            if (!transactionData.documents) {
                transactionData.documents = transactionData.documentList;
            }

            if (!transactionData.scheduler) {
                transactionData.scheduler = {
                    editing: true,
                    selectedOption: transactionData?.selectedOption,
                    valueDate: transactionData?.valueDate,
                };
            }

            if (transactionData.valueDate) {
                const valueDate = ToDate(transactionData.valueDate);

                transactionData.valueDate = valueDate;
            }

            let dataTransaction;

            if (transactionData) {
                dataTransaction = {
                    ...transactionData,
                    invoiceAmount: {
                        amount: transactionData?.invoiceAmount,
                        currency: transactionData?.invoiceAmountCurrency,
                    },
                };
            }

            setValues(dataTransaction || transactionData);
        }
    }

    handleChangeAmount = (value) => {
        const { dispatch, values } = this.props;
        const { amount } = values;

        const currency = amount && amount.currency ? amount.currency : CDP_CURRENCY.USD;

        if (value != null && value !== EMPTY_STR && value !== undefined) {
            dispatch(SelectorsActionComex.getAmountTextRequest({ currency, value }));
        }
    };

    handleCurrencyChange = (value) => {
        const { dispatch, values } = this.props;
        const { amount } = values;
        const amountValue = amount && amount.amount ? amount.amount : "0";

        dispatch(SelectorsActionComex.getAmountTextRequest({ currency: value, value: amountValue }));
    };

    editDocument = (doc) => {
        this.setState({
            documentEdition: doc,
            showDocumentsModal: true,
        });
    };

    addEditDocument = (doc) => {
        const { setFieldError, setFieldValue, values } = this.props;
        const { documentEdition } = this.state;

        let newDocuments;

        if (Object.entries(documentEdition).length > 0) {
            const index = values.documents.findIndex((d) => d === documentEdition);

            newDocuments = [...values.documents];
            newDocuments[index] = doc;
        } else {
            newDocuments = [...values.documents].concat(doc);

            setFieldError("documents", EMPTY_STR);
        }

        setFieldValue("documents", newDocuments);

        this.setState({
            documentEdition: {},
        });
    };

    getAvailableDocumentTypeList = () => {
        const {
            preData: { documentList },
            values: { documents },
        } = this.props;

        if (!documents) {
            return documentList;
        }

        const unavailableDocs = documents.filter((d) => d.type !== "OTROS" && d.type !== "FACTURA_COMERCIAL");

        return documentList.filter((d) => !unavailableDocs.find((d2) => d2.type === d));
    };

    handleCancel = () => {
        const { dispatch, setSubmitting, transaction } = this.props;

        dispatch(
            SelectorsActionForm.cancelTransactionCustom({
                credentials: {},
                idTransaction: transaction.idTransaction,
                formikBag: { setSubmitting },
            }),
        );
    };

    handleDraft = () => {
        const { dispatch, transaction, values } = this.props;

        dispatch(
            SelectorsActionForm.saveDraft({
                idActivityDraft: "comex.export.presentBillingDocuments.send",
                idTransactionToSave: transaction?.idTransaction,
                transactionData: values || {},
            }),
        );
    };

    handleTemplateSave = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionTemplate.createTemplate());
    };

    handleTemplateLoad = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionTemplate.toggleTemplateList());
    };

    handleSelectTemplate = (template) => {
        const { setErrors, setValues } = this.props;
        const templateToBeLoaded = template;

        if (templateToBeLoaded.valueDate) {
            const valueDate = ToDate(templateToBeLoaded.valueDate);

            templateToBeLoaded.valueDate = valueDate;
        }

        setValues(templateToBeLoaded);
        setErrors({});
    };

    handleFormKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            event.preventDefault();
        }
    };

    handleDocumentModal = (show) => {
        this.setState({
            showDocumentsModal: show,
        });
    };

    render() {
        const { documentEdition, showDocumentsModal } = this.state;
        const { emailValidationRegex, errors, idForm, preData, setFieldValue, transaction, values } = this.props;
        const { nextValidDate, unavailableDays } = preData;
        const termsAndConditions = `${idForm}.disclaimer`;
        const i18nMap = {
            termsAndConditions,
        };
        const cancelEnabled = transaction && transaction.idTransactionStatus === STATUS.DRAFT;

        return (
            <React.Fragment>
                <Form
                    className="col col-12 col-lg-6 col-md-9 col-sm-12"
                    id={Style.ID}
                    noValidate="novalidate"
                    onKeyDown={this.handleFormKeyDown}>
                    <div className="form-section loadTemplates showTemplate">
                        <Button
                            onClick={this.handleTemplateLoad}
                            label="forms.templates.load"
                            bsStyle="default"
                            image="template.svg"
                        />
                    </div>
                    <section className="fields container--layout align-items-center flex-grow">
                        <div className="form-section-title">
                            <h3 className="form-section-title-text">{i18nUtils.get(`${idForm}.operation`)} </h3>
                        </div>
                        <Box className="invoiceAmount-field">
                            <Field
                                clearable={false}
                                component={AmountField}
                                idForm={idForm}
                                maxLength={getInteger("amount.length")}
                                name="invoiceAmount"
                                onInputChange={this.handleChangeAmount}
                                onCurrencyChange={this.handleCurrencyChange}
                                data={{
                                    options: preData.currencyList.map((c) => {
                                        return { id: c, label: i18nUtils.get(`core.currency.label.${c}`) };
                                    }),
                                }}
                                tooltip={i18nUtils.get(`${idForm}.invoiceAmount.tooltip`)}
                                decimalPlaces={2}
                                fixedDecimalScale
                            />
                        </Box>
                        <Field
                            autoComplete="off"
                            component={TextField}
                            idForm={idForm}
                            name="invoiceNumber"
                            placeholder={EMPTY_STR}
                            label={`${idForm}.invoiceNumber`}
                            maxLength={80}
                        />
                        <Field
                            autoComplete="off"
                            component={TextField}
                            idForm={idForm}
                            name="courierName"
                            optional={i18nUtils.get("form.field.optional")}
                            tooltip={i18nUtils.get(`${idForm}.courierName.tooltip`)}
                            placeholder={EMPTY_STR}
                            label={`${idForm}.courierName`}
                            maxLength={140}
                        />
                        <Field
                            autoComplete="off"
                            component={TextField}
                            idForm={idForm}
                            name="accountNumber"
                            optional={i18nUtils.get("form.field.optional")}
                            tooltip={i18nUtils.get(`${idForm}.accountNumber.tooltip`)}
                            placeholder={EMPTY_STR}
                            label={`${idForm}.accountNumber`}
                            maxLength={20}
                        />
                        <Field
                            autoComplete="off"
                            component={TextArea}
                            idForm={idForm}
                            name="sendDocuments"
                            tooltip={i18nUtils.get(`${idForm}.sendDocuments.tooltip`)}
                            placeholder={EMPTY_STR}
                            label={`${idForm}.sendDocuments`}
                            maxLength={350}
                        />
                        <Field
                            autoComplete="off"
                            component={TextArea}
                            idForm={idForm}
                            name="turned"
                            tooltip={i18nUtils.get(`${idForm}.turned.tooltip`)}
                            placeholder={EMPTY_STR}
                            label={`${idForm}.turned`}
                            maxLength={350}
                        />

                        <Field
                            component={Selector}
                            idForm={idForm}
                            hidePlaceholder
                            name="documentsDelivery"
                            className="flex-container slideFromBottom selector-letter"
                            optionClassName="needsclick"
                            options={[
                                {
                                    label: i18nUtils.get(`${idForm}.documentsDelivery.PAGO`),
                                    value: "PAGO",
                                },
                                {
                                    label: i18nUtils.get(`${idForm}.documentsDelivery.ACEPTACIONDELETRA`),
                                    value: "ACEPTACIONDELETRA",
                                },
                            ]}
                            renderAs="combo"
                            creatable={false}
                            clearable={false}
                        />

                        {values.documentsDelivery === "ACEPTACIONDELETRA" && (
                            <Box className="delivery-days form-group">
                                <Field
                                    autoComplete="off"
                                    component={TextField}
                                    idForm={idForm}
                                    name="documentsDeliveryDays"
                                    placeholder={EMPTY_STR}
                                    numbersOnly
                                    maxLength={3}
                                    hidelabel
                                />
                                <div className="text">{i18nUtils.get(`${idForm}.documentsDelivery.daysOf`)}</div>
                                <Field
                                    autoComplete="off"
                                    component={TextField}
                                    idForm={idForm}
                                    name="documentsDeliveryDaysOf"
                                    placeholder={EMPTY_STR}
                                    maxLength={50}
                                    hidelabel
                                />
                            </Box>
                        )}
                        <Box className="form-group">
                            <DocumentSection
                                formId={idForm}
                                {...this.props}
                                hideTermsField
                                hideShippedByField
                                editDocument={this.editDocument}
                                showDocumentModal={this.handleDocumentModal}
                            />
                        </Box>
                        <Field
                            component={Selector}
                            idForm={idForm}
                            hidePlaceholder
                            name="foreignExpenses"
                            className="flex-container slideFromBottom selector-letter"
                            optionClassName="needsclick"
                            options={[
                                {
                                    label: i18nUtils.get(`${idForm}.foreignExpenses.ownAccount`),
                                    value: "PORNUESTRACUENTA",
                                },
                                {
                                    label: i18nUtils.get(`${idForm}.foreignExpenses.turnedAccount`),
                                    value: "PORCUENTADELGIRADO",
                                },
                            ]}
                            renderAs="combo"
                            creatable={false}
                            clearable={false}
                        />
                        <Field
                            component={Selector}
                            idForm={idForm}
                            hidePlaceholder
                            name="instructions"
                            className="flex-container slideFromBottom selector-letter"
                            optional={i18nUtils.get("form.field.optional")}
                            optionClassName="needsclick"
                            options={[
                                {
                                    label: i18nUtils.get(`${idForm}.letter.instructions.NOPAGONOACEPTACION`),
                                    value: "NOPAGONOACEPTACION",
                                },
                                {
                                    label: i18nUtils.get(`${idForm}.letter.instructions.NOPAGO`),
                                    value: "NOPAGO",
                                },
                                {
                                    label: i18nUtils.get(`${idForm}.letter.instructions.NOACEPTACION`),
                                    value: "NOACEPTACION",
                                },
                            ]}
                            renderAs="combo"
                            creatable={false}
                            clearable={false}
                        />

                        <Box className="form-group">
                            <Field
                                name="files"
                                idForm={idForm}
                                emptyMessageKey={`${idForm}.attachments.emptyMessage`}
                                maxFiles={5}
                                errors={errors}
                                component={FileUploader}
                                values={values}
                                setFieldValue={setFieldValue}
                            />
                        </Box>
                        <Field
                            autoComplete="off"
                            component={TextArea}
                            idForm={idForm}
                            name="observations"
                            optional={i18nUtils.get("form.field.optional")}
                            placeholder={EMPTY_STR}
                            label={`${idForm}.observations`}
                            maxLength={500}
                        />
                        <Field
                            component={EmailList}
                            idForm={idForm}
                            name="notificationEmails"
                            hideSwiftTip
                            optional={i18nUtils.get("form.field.optional")}
                            tooltip={i18nUtils.get(`${idForm}.notificationEmails.tooltip`)}
                            className="form-group email-field"
                            renderSuggestion={false}
                            data={{ emailValidationRegex, addMessage: EMPTY_STR }}
                            value={values.notificationEmails}
                        />

                        <hr />
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">
                                {i18nUtils.get(`${idForm}.section.showPreview`)}
                            </h3>
                        </Box>

                        <Field
                            component={Termsandconditions}
                            idForm={idForm}
                            name="disclaimer"
                            mode={MODE.EDIT}
                            className="form-control"
                            i18nMap={i18nMap}
                        />

                        {nextValidDate && (
                            <Field
                                component={Scheduler}
                                data={{
                                    firstWorkingDate: nextValidDate,
                                    maxDaysToSchedule: ConfigUtil.get("client.comex.valueDate.maxDaysToSchedule"),
                                    nonWorkingDays: unavailableDays,
                                    lang: LANGUAGE.ES,
                                    mode: MODE.EDIT,
                                    programable: false,
                                    schedulable: true,
                                }}
                                name="scheduler"
                                idForm={idForm}
                            />
                        )}
                    </section>
                    <footer>
                        <Button onClick={this.handleDraft} label="forms.saveDraft.link" />
                        <Button onClick={this.handleTemplateSave} label="forms.saveTemplate.link" />
                        {cancelEnabled && <Button onClick={this.handleCancel} label="forms.cancelTransaction.link" />}
                        <Button
                            key="primaryButton"
                            variant="primary"
                            bsStyle="primary"
                            label="comex.imports.creditLetter.form.attachments.next"
                            loading={false}
                            type="submit"
                        />
                    </footer>
                </Form>
                <AddDocumentModal
                    show={showDocumentsModal}
                    submit={this.addEditDocument}
                    handleCloseModal={() => {
                        this.setState({ showDocumentsModal: false, documentEdition: {} });
                    }}
                    document={documentEdition}
                    documentTypeList={this.getAvailableDocumentTypeList()}
                />

                <CreateTemplateModal
                    values={values}
                    idActivityTemplate="comex.export.presentBillingDocuments.send"
                    backdrop="static"
                />
                <ListTemplatesModal
                    idActivityTemplate="comex.export.presentBillingDocuments.send"
                    onSelect={this.handleSelectTemplate}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    fetching: SelectorsStoreComexExport.getFetching(store),
    // para guardar los values del form (borrador, template, o volver del paso 2 al 1)
    formData: SelectorsStoreComexExport.getPresentBillingDocumentsFormData(store),
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    idForm: SelectorsStoreComexExport.getPresentBillingDocumentsIdForm(store),
    preData: SelectorsStoreComexExport.getPresentBillingDocumentsPre(store),
    transaction: SelectorsStoreComex.getTransaction(store),
});

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: false,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ formData }) => ({
            accountNumber: formData?.accountNumber || EMPTY_STR,
            courierName: formData?.courierName || EMPTY_STR,
            documentsDeliveryDays: formData?.documentsDeliveryDays || EMPTY_STR,
            documentsDeliveryDaysOf: formData?.documentsDeliveryDaysOf || EMPTY_STR,
            documentsDelivery: formData?.documentsDelivery || "PAGO",
            documents: formData?.documentList || [],
            files:
                formData?.attachments && formData?.attachments !== EMPTY_STR ? formData?.attachments.split(COMMA) : [],
            foreignExpenses: formData?.foreignExpenses || "PORCUENTADELGIRADO",
            instructions: formData?.instructions || EMPTY_STR,
            invoiceAmount: {
                amount: formData?.invoiceAmount || EMPTY_STR,
                currency: formData?.invoiceAmountCurrency || CDP_CURRENCY.USD,
            },
            invoiceNumber: formData?.invoiceNumber || EMPTY_STR,
            notificationEmails: formData?.notificationEmails || [],
            observations: formData?.observations || EMPTY_STR,
            scheduler: formData?.valueDate
                ? { editing: true, valueDate: formData?.valueDate, selectedOption: formData?.selectedOption }
                : null,
            sendDocuments: formData?.sendDocuments || EMPTY_STR,
            turned: formData?.turned || EMPTY_STR,
            valueDate: formData?.valueDate || null,
        }),
        handleSubmit: (props, formikBag) => {
            const { dispatch } = formikBag.props;
            const {
                accountNumber,
                courierName,
                documents,
                documentsDelivery,
                documentsDeliveryDays,
                documentsDeliveryDaysOf,
                files,
                foreignExpenses,
                instructions,
                invoiceAmount,
                invoiceNumber,
                item,
                notificationEmails,
                observations,
                scheduler,
                sendDocuments,
                turned,
                ...rest
            } = props;
            const amount = invoiceAmount?.amount || 0;
            const invoiceAmountCurrency = invoiceAmount?.currency || EMPTY_STR;
            const valueDate = scheduler ? FormatDate(scheduler.valueDate) : EMPTY_STR;
            const attachments = files && files.length > 0 ? files.join(COMMA) : EMPTY_STR;
            const { selectedOption } = scheduler;
            const finalDocumentsDeliveryDays =
                documentsDelivery === "ACEPTACIONDELETRA" ? documentsDeliveryDays : EMPTY_STR;
            const finalDocumentsDeliveryDaysOf =
                documentsDelivery === "ACEPTACIONDELETRA" ? documentsDeliveryDaysOf : EMPTY_STR;

            const formData = {
                ...rest,
                accountNumber,
                attachments,
                courierName,
                documentList: documents,
                documentsDelivery,
                documentsDeliveryDays: finalDocumentsDeliveryDays,
                documentsDeliveryDaysOf: finalDocumentsDeliveryDaysOf,
                files,
                foreignExpenses,
                instructions,
                invoiceAmount: amount,
                invoiceAmountCurrency,
                invoiceNumber,
                notificationEmails,
                observations,
                scheduler,
                selectedOption,
                sendDocuments,
                turned,
                valueDate,
            };

            dispatch(
                SelectorsActionComexExport.presentBillingDocumentsPreviewRequest({
                    formData,
                    formikBag,
                }),
            );
        },
    }),
)(Component);
