import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, ZERO_NUMBER } from "~/constants";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { SelectorsAction as SelectorsActionWMBonds } from "~/store/wm/bonds";
import * as UtilsI18n from "~/util/i18n";
import * as UtilsNumber from "~/util/number";

import Box from "~/components/Box";
import FieldLabel from "~/pages/_components/fields/FieldLabel";

export const NAME = "PaperSell";

export const PROP = {
    types: {
        availableForSelling: PropTypes.number,
        isSelling: PropTypes.bool,
        processingSale: PropTypes.number,
    },
    defaults: {
        availableForSelling: ZERO_NUMBER,
        isSelling: false,
        processingSale: ZERO_NUMBER,
    },
};

export function Component(props) {
    const { othersFields, isSelling, lang } = props;

    const handleClick = () => {
        const { dispatch, routerActions } = props;
        const { LINK1, LINK2, LINK3, PAPEL, PDFOLINK, TIPOPRODUCTOORIGEN } = othersFields;
        let link;

        if (PDFOLINK === "LINK") {
            link = `${LINK1 || EMPTY_STR}${LINK2 || EMPTY_STR}${LINK3 || EMPTY_STR}`;

            if (link.toUpperCase().startsWith("HTTP://")) {
                routerActions.push(link);
            } else {
                dispatch(
                    SelectorsActionWMBonds.getPaper({
                        idPaper: PAPEL,
                        productType: TIPOPRODUCTOORIGEN,
                        detailType: "localLink",
                    }),
                );
            }
        } else {
            dispatch(
                SelectorsActionWMBonds.getPaper({
                    idPaper: PAPEL,
                    productType: TIPOPRODUCTOORIGEN,
                    detailType: "localPDF",
                }),
            );
        }
    };

    const renderValue = (value) => {
        return UtilsNumber.numberToLocaleFormat(parseFloat(value), lang);
    };

    const renderSelling = () => {
        const { availableForSelling, processingSale } = props;
        const { ISIN: isin, VALOREFECTIVO: effectiveValue, VALORNOMINAL: nominalValue } = othersFields;

        return (
            <Box className="form-group py-3">
                <Box className="grid-container paper  px-4 py-2">
                    <Box flex>
                        <FieldLabel labelKey="client.wm.bonds.sell.paper.isin" />
                        <Box className="py-3 pl-3" tag="span">
                            {isin}
                        </Box>
                    </Box>
                    <Box flex>
                        <FieldLabel labelKey="client.wm.bonds.sell.paper.effectiveValue" />
                        <Box className="py-3 pl-3" tag="span">
                            {renderValue(effectiveValue)}
                        </Box>
                    </Box>
                    <Box flex>
                        <FieldLabel labelKey="client.wm.bonds.sell.paper.nominalValue" />
                        <Box className="py-3 pl-3" tag="span">
                            {renderValue(nominalValue)}
                        </Box>
                    </Box>
                    {processingSale > 0 && (
                        <Box flex>
                            <FieldLabel useColon labelKey="client.wm.allInvestments.sell.processingSale" />
                            <Box className="py-3 pl-3" tag="span">
                                {renderValue(processingSale)}
                            </Box>
                        </Box>
                    )}

                    {processingSale > 0 && (
                        <Box flex>
                            <Box flex>
                                <FieldLabel useColon labelKey="client.wm.allInvestments.sell.availableForSelling" />
                                <Box className="py-3 pl-3" tag="span">
                                    {renderValue(availableForSelling)}
                                </Box>
                            </Box>
                        </Box>
                    )}
                    <Box flex justify="end">
                        <Box className="pr-3" tag="span">
                            <a onClick={handleClick}>{UtilsI18n.get("client.wm.viewDetail")}</a>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    };

    if (isSelling) {
        return renderSelling();
    }

    const { currency, description, expirationDate, productTypeOrigin, rate } = othersFields;

    const rateFixed = UtilsNumber.numberToLocaleFormat(rate, lang);

    return (
        <Box className="form-group py-3">
            <Box className="paper p-3">
                <Box flex>
                    <FieldLabel labelKey="client.wm.bonds.buy.paper.issuer" useColon />
                    <Box className="py-3 pl-3" tag="span">
                        {description}
                    </Box>
                </Box>
                <Box flex>
                    <Box flex>
                        <FieldLabel labelKey="client.wm.bonds.buy.paper.currency" useColon />
                        <Box className="py-3 pl-3" tag="span">
                            {UtilsI18n.get(`currency.label.${currency}`)}
                        </Box>
                    </Box>
                    <Box flex className="pl-4">
                        <FieldLabel labelKey="client.wm.bonds.buy.paper.expirationDate" useColon />
                        <Box className="py-3 pl-3" tag="span">
                            {expirationDate}
                        </Box>
                    </Box>
                </Box>
                <Box flex>
                    <Box flex>
                        <FieldLabel labelKey="client.wm.bonds.buy.paper.description" useColon />
                        <Box className="py-3 pl-3" tag="span">
                            {UtilsI18n.get(`client.wm.bonds.buy.paper.type.${productTypeOrigin}`)}
                        </Box>
                    </Box>
                    <Box flex className="pl-4">
                        <FieldLabel labelKey="client.wm.bonds.buy.paper.coupon" useColon />
                        <Box className="py-3 pl-3" tag="span">
                            {rateFixed}%
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    lang: SelectorsStoreI18n.getLang(store),
});

export default Connect(mapStateToProps)(Component);
