import { takeLatest as TakeLatest, call as Call, put as Put, select as Select } from "redux-saga/effects";

import { LEVEL, SCOPE, RESPONSE_TYPE, ERROR_FORM, SIGN_WITH_BIOMETRIC } from "~/constants";
import Store from "~/store";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import IsTrustedBiometric from "~/store/form/_sagas/_isTrustedBiometric";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as UtilDownload from "~/util/download";
import {
    credentialsWithUnderscore as CredentialsWithUnderscore,
    hasIncorrectCredentials as HasIncorrectCredentials,
    adjustIdFieldErrors as AdjustIdFieldErrors,
} from "~/util/form";
import * as UtilsI18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsMiddleware } from "./_selectors";

const sagas = [
    TakeLatest(TYPE.PRE_FORM_REQUEST, wealthManagementPreFormRequest),
    TakeLatest(TYPE.SEND_FORM_REQUEST, wealthManagementSendFormRequest),
    TakeLatest(TYPE.PREVIEW_FORM_REQUEST, wealthManagementPreviewFormRequest),
    TakeLatest(TYPE.READ_PAPER_INFO, wealthManagementReadPaperRequest),

    TakeLatest(TYPE.READ_TRANSACTION_REQUEST, requestWealthManagementTransaction),
    TakeLatest(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, cancelTransactionCustom),
    TakeLatest(TYPE.SIGN_TRANSACTION_PREVIEW_REQUEST, signTransactionPreview),
    TakeLatest(TYPE.SIGN_TRANSACTION_REQUEST, signTransaction),
    TakeLatest(TYPE.GET_PAPER, getPaperPDF),
];

export default sagas;

function* wealthManagementPreFormRequest(props) {
    const { type, data } = yield Call(SelectorsMiddleware.wealthManagementPreRequest, props);
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.previewCancelRequest());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.WEALTH_MANAGEMENT_SELL_FUNDS],
            }),
        );
    } else {
        yield Put(SelectorsAction.wealthManagementPreFormSuccess({ data: data.data }));
    }
}

function* wealthManagementPreviewFormRequest(props) {
    const { type, data } = yield Call(SelectorsMiddleware.wealthManagementPreviewRequest, props);
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.previewCancelRequest());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("forms.fieldsErrors"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.WEALTH_MANAGEMENT_SELL_FUNDS],
            }),
        );
        props.formikBag.setErrors(data.data);
    } else {
        yield Put(SelectorsAction.wealthManagementPreviewFormSuccess({ data: data.data }));
    }
}

function* wealthManagementSendFormRequest(props) {
    const { formikBag, ...rest } = props;
    const myProps = { ...props };
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
    }

    const { _otp } = myProps;

    const { webToken } = Store.getState().session;
    const tokenToUse = _otp || webToken;

    const { type, data } = yield Call(SelectorsMiddleware.wealthManagementSendRequest, { ...rest, _otp: tokenToUse });

    if (type === RESPONSE_TYPE.WARNING) {
        if (data.code === ERROR_FORM) {
            yield Put(SelectorsAction.previewCancelRequest());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.WEALTH_MANAGEMENT_SELL_FUNDS],
                }),
            );
        }
        formikBag.setErrors(data.data);
        formikBag.setSubmitting(false);
        yield Put(SelectorsAction.taskFinished());
    } else {
        const { idTransaction } = data;
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(SelectorsAction.wealthManagementSendFormSuccess({ transaction }));
    }
}

function* wealthManagementReadPaperRequest(props) {
    const { type, data } = yield Call(SelectorsMiddleware.wealthManagementReloadPaper, props);
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.taskFinished());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.WEALTH_MANAGEMENT_SELL_FUNDS],
            }),
        );
    } else {
        yield Put(SelectorsAction.wealthManagementReadPaperSuccess({ data: data.data }));
    }
}
function* requestWealthManagementTransaction(props) {
    const { idTransaction } = props;
    const { type, data } = yield Call(SelectorsMiddlewareForm.readTransaction, {
        idTransactionToRead: idTransaction,
    });
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.WEALTH_MANAGEMENT_SELL_FUNDS],
            }),
        );
    } else {
        yield Put(SelectorsAction.readWealthManagementTransactionSuccess({ transaction: data.data.transaction }));
    }
}

function* cancelTransactionCustom(props) {
    const { credentials, idTransaction, formikBag } = props;
    yield* handleCancelTransaction(credentials, idTransaction, formikBag);

    formikBag.setSubmitting(false);
}

function* handleCancelTransaction(credentials, idTransaction, formikBag) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);
    const {
        data: { data },
        type,
    } = yield Call(SelectorsMiddlewareForm.cancelTransaction, {
        idTransactionToCancel: idTransaction,
        ...credentialsWithUnderscore,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data);
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("forms.cancelTransaction.errorMessage"),
                    level: LEVEL.ERROR,
                    scopes: SCOPE.WEALTH_MANAGEMENT_SELL_FUNDS,
                }),
            );
        }
    } else {
        yield* requestWealthManagementTransaction({ idTransaction });
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("forms.cancelTransaction.confirmationMessage"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.WEALTH_MANAGEMENT_SELL_FUNDS, SCOPE.DESKTOP],
            }),
        );
    }
}

// Solo hacemos esto para ir al paso 2 del formulario y dejar la misma estructura tipo de formularios dinamicos.
function* signTransactionPreview(props) {
    const { transaction } = props;
    yield Put(SelectorsAction.signTransactionPreviewSuccess({ transaction }));
}

function* signTransaction(props) {
    const { idForm, idActivity, idTransaction, credentials, formikBag } = props;
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { data, type } = yield Call(
        SelectorsMiddlewareForm.sign,
        { idForm, idTransaction, ...credentialsWithUnderscore },
        idActivity,
    );

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(AdjustIdFieldErrors(data.data));
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.WEALTH_MANAGEMENT_SELL_FUNDS],
                }),
            );
        }
        formikBag.setSubmitting(false);
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(
            SelectorsAction.wealthManagementSendFormSuccess({
                transaction,
                idTransaction,
            }),
        );
        formikBag.setSubmitting(false);
    }
}

function* getPaperPDF(props) {
    const { type, data } = yield Call(SelectorsMiddleware.getPaper, props);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.WEALTH_MANAGEMENT_SELL_FUNDS],
            }),
        );
    } else {
        const { content, fileName } = data.data;
        UtilDownload.downloadPdf(fileName, content);
    }
    yield Put(SelectorsAction.taskFinished());
}
