import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "changeMyPhone";

export const PATH = {
    CHANGE_PHONE_STEP1: "/changeMyPhone.step1",
    CHANGE_PHONE_STEP2: "/changeMyPhone.step2",
    CHANGE_PHONE_STEP3: "/changeMyPhone.step3",
    REGISTER_BIOMETRIC: "/register.biometric",
};

export const TYPE = FactoryType(NAME, [
    "CHANGE_PHONE_STEP1_REQUEST",
    "CHANGE_PHONE_STEP1_ERROR",
    "CHANGE_PHONE_STEP1_SUCCESS",

    "CHANGE_PHONE_STEP2_REQUEST",
    "CHANGE_PHONE_STEP2_ERROR",
    "CHANGE_PHONE_STEP2_SUCCESS",

    "CHANGE_PHONE_STEP3_REQUEST",
    "CHANGE_PHONE_STEP3_ERROR",
    "CHANGE_PHONE_STEP3_SUCCESS",

    "CHANGE_MY_PHONE_REGISTER_BIOMETRIC_REQUEST",
    "CHANGE_MY_PHONE_REGISTER_BIOMETRIC_REQUEST_ERROR",
    "CHANGE_MY_PHONE_REGISTER_BIOMETRIC_REQUEST_SUCCESS",

    "CLEAN_UP",
]);

export const PROP = {};

PROP.types = {
    fetching: PropTypes.bool,
    isChangeMyPhone: PropTypes.bool,
    username: PropTypes.string,
    ...TypesRedux,
};

PROP.defaults = {
    fetching: false,
    isChangeMyPhone: false,
    username: null,
    ...DefaultsRedux,
};
