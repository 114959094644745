import PropTypes from "prop-types";

import { FactoryType } from "~/util/redux";

export const NAME = "administrationUsers";

export const PATH = {
    ADMINISTRATION_USER_LIST: "/administration.users.list",
    ADMINISTRATION_USER_EXPORT: "/administration.users.export",
    ADMINISTRATION_USER_CHANGE_STATUS_PREVIEW: "/administration.users.blockunblock.preview",
    ADMINISTRATION_USER_CHANGE_STATUS_CONFIRMATION: "/administration.users.blockunblock.send",
    ADMINISTRATION_USER_DELETE_PREVIEW: "/administration.users.delete.preview",
    ADMINISTRATION_USER_DELETE_CONFIRMATION: "/administration.users.delete.send",
    ADMINISTRATION_USER_UPDATE_DISPATCHER: "/administration.users.update",
    RESEND_INVITE_REQUEST: "/administration.users.invitationCode.resend",
};

export const TYPE = FactoryType(NAME, [
    "LOAD_LIST_REQUEST",
    "LOAD_LIST_FAILURE",
    "LOAD_LIST_SUCCESS",
    "LOAD_MORE_REQUEST",
    "LOAD_MORE_SUCCESS",
    "LOAD_SINGLE_SUCCESS",
    "UPDATE_DISPATCHER_REQUEST",
    "UPDATE_DISPATCHER_FAILURE",
    "UPDATE_DISPATCHER_SUCCESS",
    "EXPORT_LIST_REQUEST",
    "EXPORT_LIST_FAILURE",
    "EXPORT_LIST_SUCCESS",
    "CHANGE_USER_STATUS_PREVIEW",
    "CHANGE_USER_STATUS_PREVIEW_SUCCESS",
    "CHANGE_USER_STATUS_CONFIRMATION",
    "CHANGE_USER_STATUS_CONFIRMATION_SUCCESS",
    "RESEND_INVITE_REQUEST",
]);

export const PROP = {};

PROP.types = {
    list: PropTypes.array,
    usersInfoById: PropTypes.object,
    userAction: PropTypes.func,
    usersToApplyAction: PropTypes.array,
    usersNamesToApplyAction: PropTypes.array,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    hasMoreData: PropTypes.bool,
    isInviteEnabled: PropTypes.bool,
    fetching: PropTypes.bool,
    fetchingMoreUsers: PropTypes.bool,
    fetchingExport: PropTypes.bool,
    idTransaction: PropTypes.string,
    idActivity: PropTypes.string,
    credentialGroups: PropTypes.array,
    invitationCodes: PropTypes.array,
    cleanSelectionForUserActions: PropTypes.bool,
};

PROP.defaults = {
    list: [],
    usersInfoById: {},
    userAction: null,
    usersToApplyAction: [],
    usersNamesToApplyAction: [],
    currentPage: 1,
    totalPages: 0,
    hasMoreData: false,
    isInviteEnabled: false,
    fetching: true,
    fetchingMoreUsers: false,
    fetchingExport: false,
    idTransaction: null,
    idActivity: null,
    credentialGroups: [],
    invitationCodes: [],
    cleanSelectionForUserActions: false,
};
