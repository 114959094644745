import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import Container from "~/containers/External/Dash";
import { SelectorsAction as SelectorsActionBiometric } from "~/store/biometric";
import { SelectorsAction as SelectorsActionChangeMyPhone } from "~/store/changeMyPhone";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsAction as SelectorsActionEnrollment } from "~/store/enrollment";
import { SelectorsAction as SelectorsActionMigration } from "~/store/migration";
import { SelectorsAction as SelectorsActionSession } from "~/store/session";
import Device from "~/util/device";
import { Types as TypesHtmlElement, Defaults as DefaultsHtmlElement } from "~/util/prop/html-element";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";

import ActionsList from "./_ActionsList";

export const NAME = "ExternalDashboard";

export const PROP = {
    types: {
        ...TypesHtmlElement,
        ...TypesRedux,
        isTrusted: PropTypes.bool.isRequired,
    },
    defaults: {
        ...DefaultsHtmlElement,
        ...DefaultsRedux,
    },
};

export function Component(props) {
    const { dispatch, isTrusted } = props;
    const isMobileAppNative = Device.isMobileNative();

    React.useEffect(() => {
        dispatch(SelectorsActionChangeMyPhone.cleanUp());
        dispatch(SelectorsActionEnrollment.cleanUp());
        dispatch(SelectorsActionBiometric.cleanUp());
        dispatch(SelectorsActionMigration.cleanUp());
        dispatch(SelectorsActionSession.getDeviceLocationVU());

        if (isMobileAppNative) {
            window.common.hideSplashScreen();
        }
    });

    return (
        <React.Fragment>
            <Container name={NAME} scopeToShowNotification={SCOPE.EXTERNAL_DASHBOARD}>
                <div className="launcher-header">
                    <span className="launcher-header-text">
                        <I18n id="externalDashboard.welcome" defaultValue="Bienvenido" />
                    </span>
                </div>
                <div className="launcher-content">
                    <ActionsList classes="launcher-row main-row">
                        <Link to="/login">
                            <div className="launcher-item-wraper">
                                <div className="launcher-item">
                                    <div className="launcher-item-image">
                                        <Image src="login-ibanca.svg" className="launcher-item-icon" />
                                    </div>
                                    <span className="launcher-item-text">
                                        <I18n id="externalDashboard.ibanca.access" defaultValue="Acceda a iBanca" />
                                    </span>
                                </div>
                            </div>
                        </Link>
                        {isTrusted && (
                            <Link to="/OTP">
                                <div className="launcher-item-wraper">
                                    <div className="launcher-item">
                                        <div className="launcher-item-image">
                                            <Image src="web-token.svg" className="launcher-item-icon" />
                                        </div>
                                        <span className="launcher-item-text">
                                            <I18n id="externalDashboard.webToken" defaultValue="Token Web" />
                                        </span>
                                    </div>
                                </div>
                            </Link>
                        )}
                    </ActionsList>
                    <ActionsList classes="launcher-row simple-row">
                        <div className="launcher-item-wraper">
                            <Link to="/information" className="launcher-item">
                                <Image src="information.svg" className="launcher-item-icon" />
                                <span className="launcher-item-text">
                                    <I18n id="externalDashboard.information" defaultValue="Información" />
                                </span>
                            </Link>
                        </div>
                        {isMobileAppNative && (
                            <div className="launcher-item-wraper">
                                <Link to="/changePhoneStep1" className="launcher-item">
                                    <Image src="change-phone.svg" className="launcher-item-icon" />
                                    <span className="launcher-item-text">
                                        <I18n
                                            id="externalDashboard.mobilePhone.change"
                                            defaultValue="Cambié mi teléfono"
                                        />
                                    </span>
                                </Link>
                            </div>
                        )}
                        <div className="launcher-item-wraper">
                            <Link to="/invitationCode" className="launcher-item">
                                <Image src="enrollment.svg" className="launcher-item-icon" />
                                <span className="launcher-item-text">
                                    <I18n id="externalDashboard.register" defaultValue="Quiero registrarme" />
                                </span>
                            </Link>
                        </div>
                    </ActionsList>
                </div>
            </Container>
        </React.Fragment>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    isTrusted: SelectorsStoreConfig.getIsTrusted(store),
});

export default Connect(mapStateToProps)(Component);
