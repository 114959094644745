/* global window */
const VASCOSS = "1b4543f0bacad537da30517cd15dbb22";

export const isTrustedDevice = () => window.softoken.isTrustedDevice(VASCOSS);

export const generateOTP = () => window.softoken.generateOTP(VASCOSS);
export const activateOnline = (params) => {
    const { _commonName, seed } = params;
    return window.softoken.activateOnline(VASCOSS, _commonName, seed);
};

export const vascoHasPermission = () => window.softoken.vascoHasPermission();

export const error = {
    SECURE_STORAGE_INSTANCE_EXCEPTION: "TPV-1000",
    SECURE_STORAGE_EXCEPTION: "TPV-1001",
    ACTIVATION_DEVICE_EXCEPTION: "TPV-1002",
    TOKEN_GENERATION_EXCEPTION: "TPV-1003",
    ACTIVATION_STORAGE_EXCEPTION: "TPV-1004",
    DEVICE_BINDING_EXCEPTION: "TPV-1005",
    VASCOSS_DATA_MISSING: "TPV-1006",
    GENERIC_ERROR: "TPV-9999",
    NO_PERMISSION_GRANTED: "TPV-4001",
    NO_VALID_VASCOSS: "TPV-4002",
    NO_VASCOSS_FOUND: "TPV-4003",
    UNABLE_TO_ACTIVATE: "TPV-4004",
};

export const CODES = {
    "TPV-1000": "SECURE_STORAGE_INSTANCE_EXCEPTION",
    "TPV-1001": "SECURE_STORAGE_EXCEPTION",
    "TPV-1002": "ACTIVATION_DEVICE_EXCEPTION",
    "TPV-1003": "TOKEN_GENERATION_EXCEPTION",
    "TPV-1004": "ACTIVATION_STORAGE_EXCEPTION",
    "TPV-1005": "DEVICE_BINDING_EXCEPTION",
    "TPV-1006": "VASCOSS_DATA_MISSING",
    "TPV-9999": "GENERIC_ERROR",
    "TPV-4001": "NO_PERMISSION_GRANTED",
    "TPV-4002": "NO_VALID_VASCOSS",
    "TPV-4003": "NO_VASCOSS_FOUND",
    "TPV-4004": "UNABLE_TO_ACTIVATE",
};
export function ConvertError(code) {
    const value = CODES[code];
    if (!value) {
        return code;
    }
    return `${code}: ${value}`;
}
