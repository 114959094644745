import { TYPE as TYPE_PRODUCTS } from "~/store/products";
import { TYPE as TYPE_SESSION } from "~/store/session";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE_SESSION.LOGOUT_REQUEST:
            return {
                ...storeProp,
                ...PROP.defaults,
            };
        case TYPE.CLOSE_OPTIONS:
            return {
                ...storeProp,
                isOptionsVisible: false,
            };
        case TYPE.ACCOUNT_DETAILS_REQUEST:
            return {
                ...storeProp,
                fetchingDetails: true,
                filters: action.filters || storeProp.filters,
                clearFilter: action.clearFilter,
                movements: [],
                pageNumber: 1,
            };
        case TYPE.ACCOUNT_DETAILS_SUCCESS: {
            const { moreStatements, currency, totalLaw19210, statements, rates } = action;
            return {
                ...storeProp,
                fetchingDetails: false,
                moreMovements: moreStatements,
                currency,
                rates,
                totalLaw19210,
                movements: statements,
            };
        }
        case TYPE.ACCOUNT_READ_REQUEST: {
            const { idSelectedAccount } = action;
            return {
                ...storeProp,
                fetching: true,
                idSelectedAccount,
            };
        }
        case TYPE.ACCOUNT_READ_SUCCESS: {
            const { channelList, account } = action;
            return {
                ...storeProp,
                fetching: false,
                channelList,
                selectedAccount: account,
            };
        }
        case TYPE.ACCOUNT_DOWNLOAD_STATEMENT_REQUEST:
            return { ...storeProp, fetching: true };
        case TYPE.ACCOUNT_DOWNLOAD_STATEMENT_SUCCESS:
        case TYPE.ACCOUNT_DOWNLOAD_STATEMENT_FAILURE:
            return { ...storeProp, fetching: false };
        case TYPE.ACCOUNT_FETCH_MORE_MOVEMENTS_REQUEST:
            return {
                ...storeProp,
                fetchingMovements: true,
            };
        case TYPE.ACCOUNT_FETCH_MORE_MOVEMENTS_SUCCESS: {
            const { statements, moreStatements, pageNumber } = action;
            return {
                ...storeProp,
                fetchingMovements: false,
                movements: storeProp.movements ? storeProp.movements.concat(statements) : statements,
                moreMovements: moreStatements,
                pageNumber,
            };
        }
        case TYPE.ACCOUNT_MOVEMENT_DETAILS_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.ACCOUNT_MOVEMENT_DETAILS_SUCCESS: {
            const { statement } = action;
            return {
                ...storeProp,
                fetching: false,
                voucher: statement.voucher,
            }; // TODO: revisar si se usa voucher
        }
        case TYPE.LIST_ACCOUNT_STATEMENTS_REQUEST:
            return {
                ...storeProp,
                fetchingMovements: true,
            };
        case TYPE.LIST_ACCOUNT_STATEMENTS_SUCCESS: {
            const { statementLines } = action;
            return {
                ...storeProp,
                fetchingMovements: false,
                statements: statementLines,
            };
        }
        case TYPE.LIST_ACCOUNTS_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                movements: [],
                moreMovements: false,
                selectedMovement: null,
                pageNumber: 1,
            };
        case TYPE.LIST_ACCOUNTS_SUCCESS: {
            const { accounts, equivalentTotalBalance } = action;
            return {
                ...storeProp,
                accounts,
                equivalentTotalBalance,
                fetching: false,
                isOptionsVisible: false,
            };
        }
        case TYPE.RESET_FILTERS:
            return { ...storeProp, filters: PROP.defaults.filters };
        case TYPE.SET_FILTER: {
            return {
                ...storeProp,
                filters: action.filters || storeProp.filters,
                clearFilter: action.clearFilter,
                movements: [],
                moreMovements: false,
            };
        }
        case TYPE.SET_MOBILE_FILTER: {
            return {
                ...storeProp,
                mobileFilter: action,
            };
        }
        case TYPE.SET_SELECTED_MOVEMENT: {
            const { selectedMovement } = action;
            return {
                ...storeProp,
                selectedMovement,
            };
        }
        case TYPE.SET_UNIQUE_ACCOUNT: {
            const { accounts, equivalentTotalBalance } = action;
            return {
                ...storeProp,
                accounts,
                equivalentTotalBalance,
                selectedAccount: accounts[0],
            };
        }

        case TYPE.TOGGLE_OPTIONS: {
            return {
                ...storeProp,
                isOptionsVisible: !storeProp.isOptionsVisible,
            };
        }

        case TYPE_PRODUCTS.CHANGE_PRODUCT_ALIAS_SUCCESS: {
            const { alias } = action;
            return {
                ...storeProp,
                selectedAccount: {
                    ...storeProp.selectedAccount,
                    productAlias: alias,
                },
            };
        }
        case TYPE.FETCH_ACCOUNT:
            return {
                ...storeProp,
                account: {},
            };
        case TYPE.FETCH_ACCOUNT_SUCCESS:
            return {
                ...storeProp,
                account: action.account,
            };
        case TYPE.DOWNLOAD_MOVEMENTS_REQUEST:
            return {
                ...storeProp,
                fetchingDownload: true,
            };
        case TYPE.DOWNLOAD_MOVEMENTS_FAILURE:
        case TYPE.DOWNLOAD_MOVEMENTS_SUCCESS:
            return {
                ...storeProp,
                fetchingDownload: false,
            };

        case TYPE.ACCOUNT_FETCH_MOVEMENTS_REQUEST:
            return {
                ...storeProp,
                fetchingMovements: true,
            };
        case TYPE.ACCOUNT_FETCH_MOVEMENTS_SUCCESS:
            return {
                ...storeProp,
                fetchingMovements: false,
                movements: action.statements,
                moreMovements: action.moreStatements,
            };
        case TYPE.ACCOUNT_STATE_REQUEST:
            return { ...storeProp, fetching: true, stateAccount: storeProp.stateAccount };
        case TYPE.ACCOUNT_STATE_SUCCESS: {
            const { stateAccount } = action;
            return { ...storeProp, fetching: false, stateAccount };
        }
        case TYPE.PDF_STATE_REQUEST:
            return { ...storeProp, fetching: true };
        case TYPE.PDF_STATE_SUCCESS:
            return { ...storeProp, fetching: false };
        case TYPE_SESSION.CHANGE_ENVIRONMENT_SUCCESS:
            return { ...storeProp, selectedAccount: PROP.defaults.selectedAccount };
        case TYPE.SET_SHOW_NOTE_MODAL:
            return { ...storeProp, showNoteModal: action.showNoteModal };
        case TYPE.SET_SHOW_RATES_MODAL:
            return { ...storeProp, showRatesModal: action.showRatesModal };
        case TYPE.SET_SHOW_MULTICASH_MODAL:
            return { ...storeProp, showMulticashModal: action.showMulticashModal };
        case TYPE.EDIT_MOVEMENT_NOTE_SUCCESS:
            return { ...storeProp, showNoteModal: false, movements: action.movements };
        case TYPE.READ_MOVEMENT_NOTE_SUCCESS:
            return { ...storeProp, note: action.note, showNoteModal: true };
        case TYPE.LIST_BILLING_TICKET_REQUEST:
            return {
                ...storeProp,
                fetchingTickets: true,
                billingTicketsFilter: action.filters || storeProp.billingTicketsFilter,
                pageNumber: action.page || storeProp.pageNumber,
                firstTime: false,
                billingTickets: action.cleanList ? [] : storeProp.billingTickets,
            };
        case TYPE.LIST_BILLING_TICKET_ERROR:
            return { ...storeProp, fetchingTickets: false };
        case TYPE.LIST_BILLING_TICKET_SUCCESS: {
            const { tickets, next } = action;
            return {
                ...storeProp,
                fetchingTickets: false,
                billingTickets: storeProp.billingTickets ? storeProp.billingTickets.concat(tickets) : tickets,
                next: next === 1,
            };
        }
        case TYPE.DOWNLOAD_BILLING_TICKET_PDF_REQUEST:
            return { ...storeProp, fetching: true };
        case TYPE.DOWNLOAD_BILLING_TICKET_PDF_ERROR:
            return { ...storeProp, fetching: false };
        case TYPE.DOWNLOAD_BILLING_TICKET_PDF_SUCCESS:
            return { ...storeProp, fetching: false };
        case TYPE.CLEAN_MOVEMENTS:
            return {
                ...storeProp,
                movements: null,
                moreMovements: false,
                pageNumber: 1,
            };
        default:
            return storeProp;
    }
};
