import React from "react";

import { Form, withFormik as WithFormik, Field } from "formik";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, SPACE_STR, ZERO_NUMBER } from "~/constants";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import {
    SelectorsStore as SelectorsStoreWMSellFunds,
    SelectorsAction as SelectorsActionWMSellFunds,
} from "~/store/wm/funds/sell";
import * as UtilsConfig from "~/util/config";
import { addDay as AddDay } from "~/util/date";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import DateField from "~/pages/_components/fields/DateField";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import AmountField from "~/pages/_components/fields/formik/AmountField";

import Checkbox from "~/pages/forms/_components/_fields/_commons/Checkbox";
import SelectorType from "~/pages/settings/changePersonalInformation/field/SelectorType";
import Disclaimer from "~/pages/wm/funds/_component/Disclaimer";
import FormatNumber from "~/pages/wm/funds/_component/FormatNumber";
import PaperDetails from "~/pages/wm/funds/_component/PaperDetails";

import Style from "./Step1Edit.rules.scss";

export const FORM_ID = "wm.sell";

export const { NAME } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const {
        activeEnvironment,
        creditAccounts,
        dispatch,
        errors,
        funds,
        idPaper,
        isFetching,
        paper,
        processingSale,
        setFieldValue,
        timezoneOffset,
        touched,
        values,
    } = props;
    const { shares } = values;

    React.useEffect(() => {
        setFieldValue("paper", idPaper);
    }, [idPaper, setFieldValue]);

    const handleChangeFunds = React.useCallback(
        (val) => {
            if (val) {
                dispatch(SelectorsActionWMSellFunds.wealthManagementPreFormRequest({ idPaper: val }));
            }
        },
        [dispatch],
    );

    let totalAmount = 0;
    let currency = 222;

    if (paper?.othersFields) {
        const { amount } = shares;

        totalAmount = amount * Number(paper.othersFields.PRECIOMERCADO).toFixed(2);
        currency = paper?.othersFields.MONEDA;
    }

    const classText = totalAmount > 0 ? "text_amount" : "hidden";

    const availableForSelling =
        parseFloat(paper?.othersFields?.CANTIDADCUOTAPARTES || ZERO_NUMBER, 10) - processingSale;

    const handleCheckChange = (fieldName) => {
        setFieldValue(fieldName, !values[fieldName]);
    };

    const maxDaysToShow = UtilsConfig.getInteger("client.wm.allInvestments.orderValid.maxDaysToShow");
    const minDate = new Date();
    const maxDate = AddDay(minDate, maxDaysToShow);

    return (
        <Form id={Style.ID}>
            <div className="fields">
                <div className="form-section-title">
                    <div className="form-section-title-text">
                        <I18n id={`${FORM_ID}.title.details`} />
                    </div>

                    {/* Paper */}
                    <div className="form-section-paper">
                        <div className="form-section-select">
                            <SelectorType
                                idForm={FORM_ID}
                                label={UtilsI18n.get(`${FORM_ID}.selector.funds`)}
                                labelKey="name"
                                name="paper"
                                onChange={handleChangeFunds}
                                options={funds ?? []}
                                valueKey="idProduct"
                                {...props}
                            />
                        </div>

                        <PaperDetails
                            activeEnvironment={activeEnvironment}
                            availableForSelling={availableForSelling}
                            debitAccounts={creditAccounts}
                            extraInfo
                            hideName
                            hideWarning
                            hidelabel
                            paper={paper}
                            processingSale={processingSale}
                        />
                    </div>
                </div>

                <div className="form-section-title">
                    <div className="form-section-title-text">
                        <I18n id={`${FORM_ID}.subtitle.details`} />
                    </div>

                    <div className="form-section">
                        {/* Order valid until */}
                        <div className="form-group">
                            <FieldLabel
                                labelKey="wm.allInvestments.orderValidUntil.label"
                                classContainer={`${
                                    errors.orderCompleted && touched.orderCompleted ? "has-error" : EMPTY_STR
                                }`}
                            />

                            <div className="checkbox-mark">
                                <Field
                                    name="orderCompleted"
                                    label={UtilsI18n.get(`${FORM_ID}.orderCompleted.label`)}
                                    checked={values.orderCompleted}
                                    component={Checkbox}
                                    onChange={() => handleCheckChange("orderCompleted")}
                                />
                            </div>

                            {errors.orderCompleted && touched.orderCompleted && (
                                <FieldError error={errors.orderCompleted} />
                            )}
                        </div>

                        {!values.orderCompleted && (
                            <React.Fragment>
                                <div className="datepicker">
                                    <Field
                                        autoComplete="off"
                                        component={DateField}
                                        id="limitDate"
                                        idForm={FORM_ID}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        name="limitDate"
                                        timezoneOffset={timezoneOffset}
                                    />
                                    <div id="disclaimer-text">
                                        {UtilsI18n.get("wm.allInvestments.afterValidDate.disclaimer")}
                                    </div>
                                </div>
                            </React.Fragment>
                        )}

                        {/* Credit account */}
                        <SelectorType
                            idForm={FORM_ID}
                            label={UtilsI18n.get(`${FORM_ID}.selector.credit.account`)}
                            labelKey="label"
                            name="idCreditAccount"
                            options={creditAccounts ?? []}
                            valueKey="value"
                            {...props}
                        />

                        {/* Shares */}
                        <div>
                            <Field
                                clearable={false}
                                component={AmountField}
                                decimalPlaces={2}
                                fixedDecimalScale
                                hideCurrency
                                id="shares"
                                idForm={FORM_ID}
                                maxLength={UtilsConfig.getInteger("amount.length")}
                                name="shares"
                                placeholder={EMPTY_STR}
                                searchable={false}
                                value={EMPTY_STR}
                                inputZeroAllowed
                            />

                            <div className={classText}>
                                <p>
                                    {UtilsI18n.get("wm.funds.sell.amount.text.first")}
                                    {SPACE_STR}
                                    <b>
                                        <FormatNumber amount={parseFloat(totalAmount, 10)} currency={currency} />
                                    </b>
                                    {UtilsI18n.get("wm.funds.sell.amount.text.second")}
                                </p>
                            </div>
                        </div>

                        {/* Disclaimer */}
                        <Disclaimer
                            idForm={FORM_ID}
                            label="wm.funds.buy.disclaimer.title"
                            labelDisclaimer="wm.funds.sell.disclaimer"
                            name="disclaimer"
                            {...props}
                        />
                    </div>
                </div>
            </div>
            <footer>
                {!isFetching && (
                    <React.Fragment>
                        {/* Submit */}
                        <Button type="submit" variant="primary">
                            {UtilsI18n.get("global.send")}
                        </Button>
                    </React.Fragment>
                )}
            </footer>
        </Form>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
    creditAccounts: SelectorsStoreWMSellFunds.getCreditAccounts(store),
    disclaimer: SelectorsStoreWMSellFunds.getDisclaimer(store),
    funds: SelectorsStoreWMSellFunds.getFunds(store),
    idCreditAccount: SelectorsStoreWMSellFunds.getIdCreditAccounts(store),
    idPaper: SelectorsStoreWMSellFunds.getIdPaper(store),
    isFetching: SelectorsStoreWMSellFunds.isFetching(store),
    paper: SelectorsStoreWMSellFunds.getPaper(store),
    processingSale: SelectorsStoreWMSellFunds.getProcessingSale(store),
    shares: SelectorsStoreWMSellFunds.getShare(store),
    timezoneOffset: SelectorsStoreWMSellFunds.getTimezoneOffset(store),
});

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: false,
        validateOnBlur: false,
        validateOnChange: false,
        mapPropsToValues: ({ disclaimer, idCreditAccount, idPaper, orderCompleted, shares }) => {
            return {
                disclaimer,
                idCreditAccount,
                limitDate: EMPTY_STR,
                orderCompleted: orderCompleted !== false,
                paper: idPaper,
                shares: shares?.amount ?? EMPTY_STR,
            };
        },
        handleSubmit: (values, formikBag) => {
            const { disclaimer, idCreditAccount, limitDate, orderCompleted, paper, shares } = values;
            const { props } = formikBag;

            const nameFund = props.paper.othersFields?.DESCRIPCION;
            const request = {
                amount: shares?.amount * Number(props.paper.othersFields.PRECIOMERCADO).toFixed(2),
                disclaimer: disclaimer ?? false,
                funds: paper,
                idCreditAccount,
                idPaper: paper,
                limitDate: orderCompleted ? EMPTY_STR : limitDate,
                orderCompleted,
                paperName: nameFund ?? EMPTY_STR,
                shares: shares?.amount?.toString() ?? EMPTY_STR,
                sharesAmount: Number(props.paper.othersFields.PRECIOMERCADO).toFixed(2),
                valueDate: new Date(),
            };
            const { dispatch } = props;

            dispatch(SelectorsActionWMSellFunds.wealthManagementPreviewFormRequest({ ...request, formikBag }));
        },
    }),
)(Component);
