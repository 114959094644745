import React from "react";

import PropTypes from "prop-types";
import ReactSelect from "react-select";
import MaskedInput from "react-text-mask";

import { EMPTY_STR, REGEX_IGNORE_ACCENTS, SPACE_STR } from "~/constants";
import * as UtilsConfig from "~/util/config";
import UtilsDevice from "~/util/device";
import { get as Get } from "~/util/i18n";
import Lodash from "~/util/lodash";
import { GetMobileCountryOptions, MOBILE_COUNTRY_FILTER } from "~/util/phone";

import Image from "~/components/Image";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";

export const NAME = "CountryCellPhoneField";

export const PROP = {
    types: {
        hideLabel: PropTypes.bool,
        hidePlaceholder: PropTypes.bool,
    },
    defaults: {
        hideLabel: false,
        hidePlaceholder: false,
    },
};

export function Component(props) {
    const {
        field,
        filter,
        form: { errors },
        hideLabel,
        hidePlaceholder,
        idForm,
        labelKey,
        readOnly,
        tooltip,
        valueKey,
        withOutOptions,
    } = props;
    const { form } = props;
    const { value } = field;

    const [options, setOptions] = React.useState(null);
    const [mappedOptions, setMappedOptions] = React.useState(null);
    const [placeholder, setPlaceholder] = React.useState(EMPTY_STR);
    const [valueSelect, setValueSelect] = React.useState(Get(`mobilePhone.countryName.URY`));

    React.useEffect(() => {
        setOptions(GetMobileCountryOptions(filter || MOBILE_COUNTRY_FILTER.ALL));
    }, [filter]);

    const setFieldValue = React.useCallback(
        (valueField, name) => {
            form.setFieldValue(name, valueField);
            form.setFieldTouched(name);
        },
        [form],
    );

    const handleCountryChange = React.useCallback(
        (selected) => {
            if (selected) {
                const { country, prefix } = selected;
                const fieldValue = { country, mobilePhone: value.mobilePhone, prefix };

                setPlaceholder(UtilsConfig.get(`cellPhone.code.${country}.placeholder`));
                setFieldValue(fieldValue, field.name);
            }
        },
        [field.name, setFieldValue, value.mobilePhone],
    );

    React.useEffect(() => {
        setMappedOptions(
            options?.map((option) => ({
                country: option.country,
                countryName: option.countryName,
                label: (
                    <React.Fragment className="needsclick">
                        <Image src={`${option.flag}.svg`} className="svg-icon needsclick" />
                        <span className="control-label needsclick"> {option.value}</span>
                        {!withOutOptions && (
                            <span className="control-label label-country needsclick"> {option.countryName}</span>
                        )}
                    </React.Fragment>
                ),
                prefix: option.value,
            })),
        );
    }, [options, withOutOptions]);

    React.useEffect(() => {
        setValueSelect(Get(`mobilePhone.countryName.${value?.country}`));
    }, [value]);

    const customFilter = (option, searchText) => {
        const splitCountryName = option.countryName.toLowerCase().split(SPACE_STR)[1];
        const country = option.countryName.toLowerCase();
        const text = searchText.toLowerCase();

        if (
            country.normalize("NFD").replace(REGEX_IGNORE_ACCENTS, EMPTY_STR).includes(text) &&
            country.charAt(0).includes(text.charAt(0))
        ) {
            return true;
        }

        if (splitCountryName && splitCountryName.charAt(0).includes(text.charAt(0))) {
            return true;
        }

        return false;
    };

    const handlePhoneBlur = (e) => {
        const { handleOnBlur } = props;

        if (handleOnBlur) {
            handleOnBlur(e);
        }
    };

    const handlePhoneChange = (e) => {
        const { value: valueInput } = e.target;
        setFieldValue({ ...value, mobilePhone: valueInput }, field.name);
    };

    const hasError = errors && errors[field.name] && !Lodash.isEmpty(errors[field.name]);

    const classNames = [
        "form-group",
        "form-group--composite",
        "fileTooltip",
        (hasError && "hasError") || EMPTY_STR,
    ].join(SPACE_STR);

    const noOptionText = Get("selector.noOption.text");
    const isMobile = UtilsDevice.isDisplayMobile();

    const inputProps = { spellCheck: "false" };

    if (readOnly) {
        inputProps.disabled = true;
    }

    const firstOption = mappedOptions && mappedOptions[0]?.label;

    const buildMask = () => {
        const maxLength = Number(UtilsConfig.get("cellPhone.maxLength"));
        const items = Array.from({ length: maxLength }, (_value, index) => index);
        return items.map(() => /\d/);
    };

    return (
        <div className={classNames}>
            {!hideLabel && <FieldLabel labelKey={`${idForm}.${field.name}.label`} />}
            <div className={`input-group ${hasError && "error-field "}`}>
                {withOutOptions ? (
                    <span className="Select currency-selector only-option">{firstOption}</span>
                ) : (
                    <ReactSelect
                        autosize={false}
                        className="currency-selector slideFromBottom flex-container"
                        clearable={false}
                        filterOption={(option, searchText) => customFilter(option, searchText)}
                        inputProps={inputProps}
                        labelKey={labelKey}
                        name="currency"
                        noResultsText={noOptionText}
                        onChange={handleCountryChange}
                        options={mappedOptions}
                        searchable={!isMobile}
                        value={valueSelect}
                        valueKey={valueKey}
                    />
                )}
                <MaskedInput
                    className="login-form-input"
                    mask={buildMask}
                    placeholder={hidePlaceholder ? EMPTY_STR : placeholder}
                    name={field.name}
                    keepCharPositions
                    value={value?.mobilePhone}
                    onBlur={handlePhoneBlur}
                    onChange={handlePhoneChange}
                    guide={false}
                    readOnly={readOnly || false}
                    disabled={inputProps.disabled}
                    type="tel"
                />
            </div>
            {hasError && (
                <div className="form-group has-error">
                    <FieldError
                        error={errors[field.name].mobilePhone || errors[field.name].prefix || errors[field.name]}
                    />
                </div>
            )}
            <div>
                {tooltip && (
                    <div className="tooltip">
                        <span className="tooltip-text"> {tooltip}</span>
                    </div>
                )}
            </div>
        </div>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
