import React from "react";

import { goBack as GoBack } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import { SelectorsAction as SelectorsActionComex, SelectorsStore as SelectorsStoreComex } from "~/store/comex";
import { SelectorsStore as SelectorsStoreComexImport } from "~/store/comex/import";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as UtilsI18n from "~/util/i18n";

import Style from "./CreditLetter.rules.scss";
import Step1EditLetter from "./Step1Edit/Step1EditLetter";
import FormPreview from "./Step2Preview/Step2Preview";
import FormView from "./Step3View/Step3View";

export const { NAME } = Style;
export const PROP = {
    types: {
        isChangingEnvironment: PropTypes.bool,
        transaction: PropTypes.shape({
            idTransaction: PropTypes.string,
            idTransactionStatus: PropTypes.string,
            valueDateAsString: PropTypes.string,
            executing: PropTypes.bool,
        }).isRequired,
    },
    defaults: {
        isChangingEnvironment: false,
    },
};

export function Component(props) {
    const { dispatch, fetching, isChangingEnvironment, match, mode, prevMode, transaction } = props;
    const { id } = match.params;

    React.useEffect(() => {
        if (!isChangingEnvironment) {
            dispatch(SelectorsActionComex.readTransactionRequest({ idTransaction: id }));
        }
    }, [dispatch, id, isChangingEnvironment]);

    return (
        <Container
            name={NAME}
            wait={fetching}
            step={mode}
            className="CreditLetter"
            head-title={UtilsI18n.get("activities.comex.imports.creditLetterOpen.send")}
            head-onBack={handleBack}
            head-onBackWeb={mode === MODE.EDIT && handleBack}
            scopeToShowNotification={SCOPE.COMEX_IMPORT_CREDIT_LETTER_OPEN}>
            {mode === MODE.EDIT && <Step1EditLetter />}
            {mode === MODE.PREVIEW && <FormPreview />}
            {mode === MODE.VIEW && <FormView transaction={transaction} />}
        </Container>
    );

    function handleBack() {
        if (prevMode && prevMode !== mode && mode !== MODE.VIEW) {
            dispatch(SelectorsActionComex.closeConfirmation());
        } else {
            dispatch(GoBack());
        }
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        fetching: SelectorsStoreComexImport.getFetching(store),
        isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
        mode: SelectorsStoreComexImport.getMode(store),
        prevMode: SelectorsStoreComexImport.getPrevMode(store),
        transaction: SelectorsStoreComex.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
