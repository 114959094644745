import React from "react";

import classnames from "classnames";
import { bool, func, string } from "prop-types";

import { MODE } from "~/constants/form";
import { capitalizeFirstLetter } from "~/util/string";

import Element from "~/components/Component";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Style from "~/components/RadioOption/RadioOption.scss";

export const { CLASS, NAME, TYPE, TAG } = Style;

export const PROP = {
    types: {
        checked: bool.isRequired,
        className: string,
        group: string,
        mode: string,
        onChange: func.isRequired,
        formId: string,
        value: string.isRequired,
    },
    defaults: {
        mode: MODE.EDIT,
    },
};

export function Component({ checked, className, group, formId, mode, onChange, value }) {
    const id = `${value.toLowerCase()}Option`;

    return (
        <Element
            className={classnames("c-control", "c-control--has-icon", "c-control--radio", CLASS, className)}
            name={NAME}
            tag={TAG}
            type={TYPE}>
            <input
                className="c-control-input"
                id={id}
                name={group}
                type="radio"
                value={value}
                checked={checked}
                onChange={(event) => onChange(event.target.value, event.target.name)}
                disabled={mode === MODE.VIEW}
            />
            <label htmlFor={id} className="c-control-label">
                <div className="c-control-icons">
                    <div className="c-control-mark">
                        <Image src="check.svg" className="svg-icon svg-caret" />
                    </div>
                </div>
                <I18n id={`${formId}${capitalizeFirstLetter(value.toLowerCase())}`} />
            </label>
        </Element>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
