import React from "react";

import { push as Push } from "connected-react-router";
import { Field, Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import {
    CREDENTIAL_TYPE_VERIFICATION_CODE,
    EMPTY_STR,
    RESEND_VERIFICATION_CODE_TIMER,
    LEVEL,
    SCOPE,
} from "~/constants";
import Container from "~/containers/External/Dash";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import {
    SelectorsAction as SelectorsActionRecoverPassword,
    SelectorsStore as SelectorsStoreRecoverPassword,
} from "~/store/recoverypassword/noToken";
import * as I18nUtils from "~/util/i18n";
import { Types as TypesHtmlElement, Defaults as DefaultsHtmlElement } from "~/util/prop/html-element";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Credential from "~/pages/_components/fields/credentials/Credential";
import WithExchangeToken from "~/pages/_components/withExchangeToken";

import CancelConfirmationModal from "~/pages/enrollment/_CancelConfirmationModal";
import Timer from "~/pages/preferentialTradingPrice/components/Timer";

import Style from "./RecoveryPass.scss";

export const { NAME, CLASS } = Style;

const FORM_ID = "recoveryPasswordNoToken.step3";

export const PROP = {
    types: {
        ...TypesHtmlElement,
        ...TypesRedux,
        mobileNumber: PropTypes.string,
    },
    defaults: {
        mobileNumber: EMPTY_STR,
        ...DefaultsHtmlElement,
        ...DefaultsRedux,
    },
};

export class Component extends React.Component {
    state = {
        show: false,
        resendSMS: false,
    };

    componentDidMount() {
        this.timer = setTimeout(this.interval, RESEND_VERIFICATION_CODE_TIMER);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    handleConfirmation = () => {
        const { dispatch } = this.props;
        dispatch(Push("/"));
    };

    handleCancelation = () => {
        this.setState({ show: false });
    };

    showModal = () => {
        this.setState({ show: true });
    };

    resendSMS = () => {
        const { dispatch } = this.props;
        this.setState((prevState) => ({ ...prevState, resendSMS: false }));
        this.timer = setTimeout(this.interval, RESEND_VERIFICATION_CODE_TIMER);

        dispatch(SelectorsActionRecoverPassword.recoverPassResendRequest());
    };

    showNotification = () => {
        const { dispatch } = this.props;
        dispatch(
            SelectorsActionNotification.showNotification({
                message: I18nUtils.get(`${FORM_ID}.incorrectNumber.message`),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.VERIFICATION_CODE],
            }),
        );
    };

    interval = () => {
        clearTimeout(this.timer);
        this.setState((prevState) => ({ ...prevState, resendSMS: true }));
    };

    renderResendButton = (resendSMS) => {
        const seconds = RESEND_VERIFICATION_CODE_TIMER / 1000;
        if (resendSMS) {
            return (
                <Button
                    className="login-form-button simple-button"
                    label={`${FORM_ID}.resendButton`}
                    onClick={this.resendSMS}
                    disabled={!resendSMS}
                />
            );
        }
        return (
            <div className="login-form-button simple-button resend-button">
                <I18n id={`${FORM_ID}.waitResendMessage`} />
                <Timer second={seconds} />
            </div>
        );
    };

    render() {
        const { resendSMS, show } = this.state;
        const { mobileNumber, fetching } = this.props;

        const resendButton = this.renderResendButton(resendSMS);

        return (
            <Container
                name={NAME}
                head-onClose={this.showModal}
                wait={fetching}
                scopeToShowNotification={SCOPE.VERIFICATION_CODE}>
                <div className={CLASS}>
                    <div id={Style.NAME}>
                        <CancelConfirmationModal
                            show={show}
                            handleCancelation={this.handleCancelation}
                            handleConfirmation={this.handleConfirmation}
                        />
                        <div className="login-step">
                            <div className="login-header">
                                <span className="login-header-text">
                                    <I18n id={`${FORM_ID}.title`} />
                                </span>
                            </div>
                            <div className="login-content">
                                <div className="login-row simple-row container-message">
                                    <span className="login-message">
                                        <I18n id={`${FORM_ID}.sms`} />
                                    </span>
                                    <span className="login-message number-message">{mobileNumber}</span>
                                </div>
                                <div className="login-row main-row">
                                    <Form className="login-form">
                                        <div className="login-form-field">
                                            <div className="login-form-field-block">
                                                <div className="login-form-field-image">
                                                    <Image
                                                        src="sms-code-verification.svg"
                                                        className="login-form-field-icon aster-icon"
                                                    />
                                                </div>
                                                <Field
                                                    idForm={FORM_ID}
                                                    name="verificationCode"
                                                    className="login-form-input"
                                                    component={Credential}
                                                    type={CREDENTIAL_TYPE_VERIFICATION_CODE}
                                                    autoFocus={false}
                                                    hidelabel
                                                />
                                            </div>
                                            <span className="login-form-field-validation" />
                                        </div>
                                        <div className="login-form-field">
                                            <Button
                                                className="login-form-button main-button btn-margin"
                                                bsStyle="primary"
                                                label="global.continue"
                                                loading={false}
                                                type="submit"
                                            />
                                        </div>
                                        <div className="login-form-field">{resendButton}</div>
                                        <span
                                            onKeyDown={this.showNotification}
                                            role="button"
                                            tabIndex={0}
                                            className="login-form-link"
                                            onClick={this.showNotification}>
                                            <I18n id={`${FORM_ID}.incorrectNumber`} />
                                        </span>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    mobileNumber: SelectorsStoreRecoverPassword.getMobileNumber(store),
    exchangeToken: SelectorsStoreRecoverPassword.getExchangeToken(store),
    fetching: SelectorsStoreRecoverPassword.isFetching(store),
});

export default Compose(
    Connect(mapStateToProps),
    WithFormik({
        mapPropsToValues: () => ({
            verificationCode: EMPTY_STR,
        }),
        handleSubmit: (data, formikBag) => {
            const { dispatch } = formikBag.props;
            const { verificationCode } = data;
            dispatch(SelectorsActionRecoverPassword.recoverPassStep3Request({ verificationCode, formikBag }));
        },
        validationSchema: () =>
            Yup.object().shape({
                verificationCode: Yup.number().required(I18nUtils.get(`${FORM_ID}.invitationCode.empty`)),
            }),
    }),
    WithExchangeToken,
)(Component);
