import { put as Put, select as Select, take as Take } from "redux-saga/effects";

import { LEVEL, SCOPE } from "~/constants";
import { SelectorsAction, SelectorsStore, TYPE, TYPE_VERIFY_BIOMETRIC } from "~/store/biometric";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as UtilsI18n from "~/util/i18n";

import { TYPE as FormTypes } from "../_consts";
import { SelectorsAction as SelectorsActionForm } from "../_selectors";

export default function* isTrustedBiometric(formikBag, pathFrom) {
    yield Put(SelectorsAction.verifyBiometric({ liveness: true, pathFrom }));
    yield Take([
        TYPE.VERIFY_BIOMETRIC_ERROR,
        TYPE.VERIFY_BIOMETRIC_SUCCESS,
        TYPE.VERIFY_BIOMETRIC_CANCEL,
        TYPE.NO_ENROLLED_FINGERPRINT,
    ]);
    const statusVerifyBiometric = yield Select(SelectorsStore.statusVerifyBiometric);

    switch (statusVerifyBiometric) {
        case TYPE_VERIFY_BIOMETRIC.ERROR:
            yield Put({
                type: FormTypes.SEND_FORM_DATA_FAILURE,
            });
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("verify.biometric.error"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.FORM],
                }),
            );
            formikBag.setSubmitting(false);
            return undefined;
        case TYPE_VERIFY_BIOMETRIC.CANCEL:
        case TYPE_VERIFY_BIOMETRIC.NO_ENROLLED_FINGERPRINT:
            yield Put(SelectorsActionForm.sendFormCredentialFailure());
            formikBag.setSubmitting(false);
            return undefined;
        default:
            return undefined;
    }
}
