import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { DC_TYPES, CC_TYPES, DATA_NOT_AVAILABLE, EMPTY_STR } from "~/constants";
import Container from "~/containers/Internal/Form/Cards";
import {
    SelectorsAction as ActionCC,
    SelectorsStore as StoreCC,
    PROP as PROP_CC,
} from "~/store/creditCards/creditCard";
import { SelectorsAction as ActionDC, SelectorsStore as StoreDC } from "~/store/debitCards";
import * as ConfigUtil from "~/util/config";
import * as UtilDate from "~/util/date";
import DeviceUtils from "~/util/device";

import DataDate from "~/components/DataDate";
import DataNumber from "~/components/DataNumber";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";

import Style from "./List.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        isFetching: PropTypes.bool,
        creditCards: PropTypes.arrayOf(PROP_CC.types.detail),
    },
    defaults: {
        isFetching: false,
        creditCards: [],
    },
};

export function Component(props) {
    const { dispatch, isFetching, creditCards, debitCards } = props;
    const brandList = ConfigUtil.getArray("client.productTypes.creditCardWithAvailableBalance.list");

    React.useEffect(() => {
        dispatch(ActionDC.listRequest({ path: "/debitCards" }));
        dispatch(ActionCC.listRequest({ path: "/creditCards" }));
    }, [dispatch]);

    const creditIsEmpty = !isFetching && !creditCards.length;
    const debitIsEmpty = !isFetching && !debitCards.length;

    return (
        <React.Fragment>
            <Container
                name={NAME}
                head-title={DeviceUtils.isMobileNative() ? "menu.cards.title" : false}
                wait={isFetching}
                className={`${Style.CLASS}`}>
                <I18n id="menu.cards.title.myCreditcards" className="card-title" />
                {isFetching && <I18n id="global.loading" />}
                {creditIsEmpty && <I18n id="creditCards.list.empty" />}
                {!creditIsEmpty &&
                    creditCards.map((creditCard) => {
                        const {
                            availableBalance,
                            availableCurrency,
                            brand,
                            creditLimit,
                            creditLimitCurrency,
                            dueDate,
                            idProduct,
                            label,
                            number,
                            productAlias,
                        } = creditCard;

                        const logo = CC_TYPES[brand];
                        const clsExpired = (UtilDate.isDateLessThanToday(dueDate) && "expired") || EMPTY_STR;
                        const cardHasAvailableBalance = brandList.includes(brand);

                        return (
                            <Link
                                to={`/creditCards/${idProduct}`}
                                key={idProduct}
                                className={classnames({ clsExpired }, "border-credit", {
                                    "last-item": creditCards.includes(creditCard, -1),
                                })}>
                                <h4>
                                    <span>{productAlias || label}</span>
                                    {logo && <Image src={`cc-types/${logo}.svg`} />}
                                </h4>
                                <ul>
                                    <li className="label">
                                        <I18n id="creditCard.label" />
                                        <span>{`${number.slice(-9)}`}</span>
                                    </li>
                                    {dueDate && (
                                        <li className="duedate">
                                            <I18n id="creditCards.list.item.expiration" />
                                            <DataDate value={dueDate} />
                                        </li>
                                    )}
                                    <div className="container-data">
                                        <li className="granted">
                                            <I18n id="creditCard.limit.granted" />
                                            <DataNumber prefix={creditLimitCurrency} value={creditLimit} />
                                        </li>
                                        {cardHasAvailableBalance && (
                                            <li className="granted balance">
                                                <I18n id="creditCard.availableBalance" />
                                                {availableBalance ? (
                                                    <DataNumber prefix={availableCurrency} value={availableBalance} />
                                                ) : (
                                                    DATA_NOT_AVAILABLE
                                                )}
                                            </li>
                                        )}
                                    </div>
                                </ul>
                            </Link>
                        );
                    })}

                <I18n id="menu.cards.title.myDebitcards" className="card-title mt-debit" />
                {isFetching && <I18n id="global.loading" />}
                {debitIsEmpty && <I18n id="creditCards.list.empty" />}
                {!debitIsEmpty &&
                    debitCards.map((debitCard) => {
                        const { cardHolder, idProduct, productAlias, number, label, dueDate, brand } = debitCard;
                        const logo = DC_TYPES[brand];
                        const clsExpired = (UtilDate.isDateLessThanToday(dueDate) && "expired") || EMPTY_STR;
                        return (
                            <Link
                                to={`/debitCard/${idProduct}`}
                                key={idProduct}
                                className={`${clsExpired} border-debit`}>
                                <h4>
                                    <span>{productAlias || label}</span>
                                    {logo && <Image src={`dc-types/${logo}.svg`} />}
                                </h4>
                                <ul>
                                    <li className="label">
                                        <I18n id="creditCard.label" />
                                        <span>{`${number.slice(-9)}`}</span>
                                    </li>
                                    {dueDate && (
                                        <li className="duedate">
                                            <I18n id="creditCards.list.item.expiration" />
                                            <DataDate value={dueDate} />
                                        </li>
                                    )}
                                    <div className={classnames("container-data", { "not-date": !dueDate })}>
                                        <li className="name-card">
                                            <span>{cardHolder}</span>
                                        </li>
                                    </div>
                                </ul>
                            </Link>
                        );
                    })}
            </Container>
        </React.Fragment>
    );
}

Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

const mapStateToProps = (state) => ({
    isFetching: StoreCC.getFetchingCards(state),
    creditCards: StoreCC.getList(state),
    debitCards: StoreDC.getList(state),
});

export default Connect(mapStateToProps)(Component);
