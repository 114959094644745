import React from "react";

import PropTypes from "prop-types";

import { ALL } from "~/constants";
import { STATUS } from "~/constants/transaction";
import * as UtilsI18n from "~/util/i18n";

import HighOrder from "~/components/HighOrder";

import { NOTIFICATION_OPTIONS } from "~/pages/creditCards/purchaseNotification/Step1Edit/Step1Edit";

export const NAME = "_cardsNotificationsList";

export const PROP = {
    types: {
        cardAccountsNotificationsUpdated: PropTypes.array.isRequired,
        isTicket: PropTypes.bool,
        selectedCardAccount: PropTypes.string.isRequired,
    },
    defaults: {
        isTicket: false,
    },
};

export function Component(props) {
    const { isTicket } = props;

    const buildNotificationData = (notificationData, notificationMobilePhonePrefix, notificationType) => {
        return (
            <React.Fragment>
                <div className="notice-data notification-type">
                    <span className="text-lead">
                        {`${UtilsI18n.get("client.creditCards.purchaseNotice.selectedNotification")} `}
                    </span>
                    {UtilsI18n.get(`creditCards.purchaseNotification.notificationType.option.${notificationType}`)}
                    {NOTIFICATION_OPTIONS.SMS === notificationType && ` +${notificationMobilePhonePrefix}`}
                    {NOTIFICATION_OPTIONS.INACTIVE !== notificationType && ` ${notificationData}`}
                </div>
            </React.Fragment>
        );
    };

    const buildCardList = () => {
        const { cardAccountsNotificationsUpdated, selectedCardAccount, transaction } = props;

        let result;
        const cardListOK = [];
        const cardListWithErrors = [];
        let firstCardOKIndex = 0;
        let firstCardWithErrorIndex = 0;

        cardAccountsNotificationsUpdated.forEach((cardAccount) =>
            cardAccount.creditCardList.forEach((card) =>
                card.purchaseNotificationChangeError ? cardListWithErrors.push(card) : cardListOK.push(card),
            ),
        );

        if (isTicket && cardListOK.length > 0 && transaction.idTransactionStatus !== STATUS.CANCELLED) {
            firstCardOKIndex = 1;

            cardListOK.unshift(UtilsI18n.get("client.creditCards.purchaseNotice.ticket.cardListOK"));
        }

        if (isTicket && cardListWithErrors.length > 0 && transaction.idTransactionStatus !== STATUS.CANCELLED) {
            firstCardWithErrorIndex = 1;

            cardListWithErrors.unshift(UtilsI18n.get("client.creditCards.purchaseNotice.ticket.cardListWithError"));
        }

        if (selectedCardAccount !== ALL) {
            const cardsOKResult = cardListOK.map((card) => {
                if (typeof card === "string") {
                    return <div className="notice-data text-lead">{card}</div>;
                }

                return (
                    <React.Fragment>
                        <div className="item-container">
                            <div className="notice-data card-wrapper">
                                <span className="text-lead">{`${UtilsI18n.get("creditCard.label")}: `}</span>
                                {UtilsI18n.get(`client.creditcard.type.label.${card.bin}`)}
                                {` (${card.number})`}
                                {` ${card.owner}`}
                            </div>
                            {buildNotificationData(
                                card.notificationData,
                                card.notificationMobilePhonePrefix,
                                card.notificationType,
                            )}
                        </div>
                    </React.Fragment>
                );
            });

            const cardListWithErrorsResult = cardListWithErrors.map((card) => {
                if (typeof card === "string") {
                    return <div className="notice-data text-lead text-error">{card}</div>;
                }

                return (
                    <React.Fragment>
                        <div className="notice-data">
                            <span className="text-lead">{`${UtilsI18n.get("creditCard.label")}: `}</span>
                            {UtilsI18n.get(`client.creditcard.type.label.${card.bin}`)}
                            {` (${card.number})`}
                            {` ${card.owner}`}
                        </div>
                        {buildNotificationData(
                            card.notificationData,
                            card.notificationMobilePhonePrefix,
                            card.notificationType,
                        )}
                    </React.Fragment>
                );
            });

            result = (
                <React.Fragment>
                    {cardsOKResult}
                    {cardListWithErrorsResult}
                </React.Fragment>
            );
        } else {
            const firstCard =
                cardListOK.length > 0 ? cardListOK[firstCardOKIndex] : cardListWithErrors[firstCardWithErrorIndex];

            const allCardsListOK = cardListOK.map((card) => {
                if (typeof card === "string") {
                    return <div className="notice-data text-lead">{card}</div>;
                }

                return (
                    <React.Fragment>
                        <div className="notice-data">
                            <span className="text-lead">{`${UtilsI18n.get("creditCard.label")}: `}</span>
                            {UtilsI18n.get(`client.creditcard.type.label.${card.bin}`)}
                            {` (${card.number})`}
                            {` ${card.owner}`}
                        </div>
                    </React.Fragment>
                );
            });

            const allCardsWithError = cardListWithErrors.map((card) => {
                if (typeof card === "string") {
                    return <div className="notice-data text-lead text-error">{card}</div>;
                }

                return (
                    <React.Fragment>
                        <div className="notice-data">
                            <span className="text-lead">{`${UtilsI18n.get("creditCard.label")}: `}</span>
                            {UtilsI18n.get(`client.creditcard.type.label.${card.bin}`)}
                            {` (${card.number})`}
                            {` ${card.owner}`}
                        </div>
                    </React.Fragment>
                );
            });

            result = (
                <React.Fragment>
                    {allCardsListOK}
                    {allCardsListOK.length > 0 &&
                        buildNotificationData(
                            firstCard.notificationData,
                            firstCard.notificationMobilePhonePrefix,
                            firstCard.notificationType,
                        )}
                    {isTicket && allCardsWithError.length > 0 && (
                        <React.Fragment>
                            {allCardsWithError}
                            {buildNotificationData(
                                firstCard.notificationData,
                                firstCard.notificationMobilePhonePrefix,
                                firstCard.notificationType,
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            );
        }

        return result;
    };

    return <div>{buildCardList()}</div>;
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default HighOrder()(Component);
