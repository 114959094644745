import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    bulletinPdfDownload: (props) => API.executeWithAccessToken(PATH.BULLETIN_DOWNLOAD_PDF, props),
    getBulletinList: () => API.executeWithAccessToken(PATH.BULLETIN_LIST),
    getHistoricalRate: (props) => API.executeWithAccessToken(PATH.PREFERENTIAL_TRADING_PRICE_HISTORICAL_RATE, props),
    getPreferentialTradingPriceDisclaimer: (props) =>
        API.executeWithAccessToken(PATH.PREFERENTIAL_TRADING_PRICE_DISCLAIMER, props),
    getPreferentialTradingPricePreview: (props) =>
        API.executeWithAccessToken(PATH.PREFERENTIAL_TRADING_PRICE_PREVIEW, props),
    getPreferentialTradingPriceRate: (props) => API.executeWithAccessToken(PATH.PREFERENTIAL_RATE, props),
    getPreferentialTradingPriceSend: (props) => API.executeWithWebToken(PATH.PREFERENTIAL_TRADING_PRICE_SEND, props),
    getPreferentialTradingPriceTicket: (props) =>
        API.executeWithAccessToken(PATH.PREFERENTIAL_TRADING_PRICE_TICKET, props),
    preferentialTradingPrice: (props) => API.executeWithAccessToken(PATH.PREFERENTIAL_TRADING_PRICE, props),
};

export const SelectorsAction = {
    bulletinListFailure: () => FactoryAction(TYPE.BULLETIN_LIST_FAILURE),
    bulletinListRequest: () => FactoryAction(TYPE.BULLETIN_LIST_REQUEST),
    bulletinListSuccess: (props) => FactoryAction(TYPE.BULLETIN_LIST_SUCCESS, props),
    bulletinPdfDownloadFailure: () => FactoryAction(TYPE.BULLETIN_PDF_DOWNLOAD_FAILURE),
    bulletinPdfDownloadRequest: (props) => FactoryAction(TYPE.BULLETIN_PDF_DOWNLOAD_REQUEST, props),
    bulletinPdfDownloadSuccess: () => FactoryAction(TYPE.BULLETIN_PDF_DOWNLOAD_SUCCESS),
    changeAmount: (props) => FactoryAction(TYPE.CHANGE_AMOUNT, props),
    changeCreditAccount: (props) => FactoryAction(TYPE.CHANGE_CREDIT_ACCOUNT, props),
    changeCurrency: (props) => FactoryAction(TYPE.CHANGE_CURRENCY, props),
    changeDebitAccount: (props) => FactoryAction(TYPE.CHANGE_DEBIT_ACCOUNT, props),
    changeOperation: (props) => FactoryAction(TYPE.CHANGE_OPERATION, props),
    changeOperationBuying: (props) => FactoryAction(TYPE.CHANGE_OPERATION_BUYING, props),
    changeOperationSelling: (props) => FactoryAction(TYPE.CHANGE_OPERATION_SELLING, props),
    cleanUp: (props) => FactoryAction(TYPE.CLEAN_UP, props),
    closePage: () => FactoryAction(TYPE.CLOSE_PAGE),
    disclaimer: (props) => FactoryAction(TYPE.DISCLAIMER, props),
    errorPdf: () => FactoryAction(TYPE.ERROR_PDF),
    errorPreview: (props) => FactoryAction(TYPE.ERROR_PREVIEW_FX, props),
    errorSend: (props) => FactoryAction(TYPE.ERROR_SEND_FX, props),
    historicalRatesFailure: (props) => FactoryAction(TYPE.HISTORICAL_RATES_FAILURE, props),
    historicalRatesRequest: (props) => FactoryAction(TYPE.HISTORICAL_RATES_REQUEST, props),
    historicalRatesSuccess: (props) => FactoryAction(TYPE.HISTORICAL_RATES_SUCCESS, props),
    isTouched: (props) => FactoryAction(TYPE.IS_TOUCHED, props),
    loading: (props) => FactoryAction(TYPE.LOADING_SCREEN, props),
    readFxTicketRequest: (props) => FactoryAction(TYPE.READ_FX_TICKET_REQUEST, props),
    readFxTicketSuccess: (props) => FactoryAction(TYPE.READ_FX_TICKET_SUCCESS, props),
    renderFormWaiter: () => FactoryAction(TYPE.RENDER_FORM_WAITER),
    requestCotizacion: (props) => FactoryAction(TYPE.REQUEST_COTIZACION, props),
    requestFX: (props) => FactoryAction(TYPE.REQUEST_FX, props),
    requestPreview: (props) => FactoryAction(TYPE.REQUEST_PREVIEW_FX, props),
    requestSend: (props) => FactoryAction(TYPE.REQUEST_SEND_FX, props),
    returnToForm: (props) => FactoryAction(TYPE.RETURN_TO_FORM, props),
    setShowBulletinModal: (props) => FactoryAction(TYPE.SET_SHOW_BULLETIN_MODAL, props),
    stopPollCotization: (props) => FactoryAction(TYPE.STOP_POLL_COTIZATION, props),
    successCotizacion: (props) => FactoryAction(TYPE.SUCCESS_COTIZACION, props),
    successFX: (props) => FactoryAction(TYPE.SUCCESS_FX, props),
    successPdf: () => FactoryAction(TYPE.SUCCESS_PDF),
    successPreview: (props) => FactoryAction(TYPE.SUCCESS_PREVIEW_FX, props),
    successSend: (props) => FactoryAction(TYPE.SUCCESS_SEND_FX, props),
};

export const SelectorsStore = {
    currencyChanged: (store) => store[NAME].currencyChanged,
    errors: (store) => store[NAME].errors,
    exchangeValueChanged: (store) => store[NAME].exchangeValueChanged,
    fetching: (store) => store[NAME].fetching,
    formData: (store) => store[NAME].formData,
    fxState: (store) => store[NAME].fxState,
    getBulletinList: (store) => store[NAME].bulletinList,
    getFetchingHistoricalRates: (store) => store[NAME].fetchingHistoricalRates,
    getHistoricalRates: (store) => store[NAME].historicalRates,
    getShowBulletinModal: (store) => store[NAME].showBulletinModal,
    getTransaction: (store) => store[NAME].transactionData,
    getProcessingTransactionsList: (store) => store[NAME].processingTransactionsList,
    isFetchingBulletin: (store) => store[NAME].isFetchingBulletin,
    operation: (store) => store[NAME].operation,
    pollCotizacion: (store) => store[NAME].pollCotizacion,
    previewData: (store) => store[NAME].previewData,
    quotation: (store) => store[NAME].quotation,
    rateChange: (store) => store[NAME].rateChange,
    renderFormWaiter: (store) => store[NAME].renderFormWaiter,
    requestRate: (store) => store[NAME].requestRate,
    sendData: (store) => store[NAME].sendData,
    stepTransaction: (store) => store[NAME].stepTransaction,
};
