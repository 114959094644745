/* eslint-disable */ // NOTE: Disabled because issues with storybook and our custom rules.
import React from "react";

import PropTypes from "prop-types";

import { TYPE_SECTION, TYPE_MAIN, TYPE_PAGE, TYPE_ELEMENT } from "~/styles/component.scss";

export const NAME = "Component";
export const PROP = {
    types: {
        /** The contents of the component  */
        children: PropTypes.node,
        /** How to uniquely identify this component across the App   */
        name: PropTypes.string.isRequired,
        /** In most cases, it should be a Section, Main, Page or Element, but a custom one can be set. */
        type: PropTypes.string,
        /** The HTML tag to use when rendering. default: <div /> */
        tag: PropTypes.oneOfType(PropTypes.string, PropTypes.func),
    },
    defaults: {
        tag: "div",
        name: undefined,
        type: undefined,
    },
};

export function Component(props) {
    const { tag: Tag, name, type, children, ...rest } = props;
    return (
        <Tag {...rest} data-name={name} data-type={type}>
            {children}
        </Tag>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export function Page(props) {
    return <Component {...props} tag="article" type={TYPE_PAGE} />;
}

export function Section(props) {
    const { tag, ...rest } = props;
    return <Component {...rest} tag={tag || "section"} type={TYPE_SECTION} />;
}

export function Element(props) {
    const { tag, ...rest } = props;
    return <Component {...rest} tag={tag || "div"} type={TYPE_ELEMENT} />;
}

export default Component;
