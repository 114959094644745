import Store from "~/store";

const Prefix = "currency.label";

let List = null;

// TODO: Find a way to return and map, CDP currencies. Is there even a way to get them?

/**
 * Returns a dictionary with all currencies available in Nazca.
 *
 * @returns {Object}
 */
export default function Currency() {
    if (List) {
        return List;
    }
    List = Object.entries(Store.getState().i18n.items)
        .filter(([key]) => key.match(new RegExp(`^${Prefix}`)))
        .map((elem) => {
            const array = [elem[0].replace(`${Prefix}.`, ""), elem[1]];
            return array;
        });
    // List.reduce((acc, [key, val]) => ({ ...acc, [val]: key.replace(`${Prefix}.`, "") }), {});
    return List;
}

/**
 * Returns a currency key using a value.
 *
 * @param {string} value - The Nazca currency value.
 * @returns {string}
 */
Currency.fromValue = (value) =>
    Object.entries(Currency())
        .filter((entry) => value === entry[1])
        .map((entry) => entry[0])
        .shift();

/**
 * Returns a currency value using a key.
 *
 * @param {string} value - The Nazca currency key.
 * @returns {string}
 */
Currency.fromKey = (key) =>
    Object.entries(Currency())
        .filter((entry) => key === entry[0])
        .map((entry) => entry[1])
        .shift();
