import React from "react";

import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { EMPTY_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import {
    SelectorsStore as SelectorsStoreTransfers,
    SelectorsAction as SelectorsActionTransfers,
} from "~/store/transfers";
import * as UtilsI18n from "~/util/i18n";

import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Token from "~/components/Token/Token";
import TicketPrintInformation from "~/pages/_components/TicketPrintInformation";

import getAlias from "../../util/aliasEnrollmentUtil";
import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        isSubmitting: PropTypes.bool,
    },
    defaults: {
        isSubmitting: false,
    },
};

export function Component(props) {
    const { isSubmitting, formData } = props;
    const { aliasData, productGroupId } = formData;
    const alias = getAlias(aliasData);

    return (
        <Form id={Style.ID}>
            <TicketPrintInformation />
            <React.Fragment>
                <section className="justify-content-center">
                    <I18n className="text-lead" id="forms.transferInternal.confirmation.title" />
                    <div className="alias-enrollment-block">
                        <div className="alias-enrollment alias-enrollment-highlight">
                            <I18n className="data-label" id="transfers.alias.enrollment.step2.title" />
                        </div>
                        <div className="alias-enrollment alias-enrollment-flow">
                            <div className="data-wrapper align-end">
                                <span className="data-label">
                                    <I18n
                                        className="data-label"
                                        id="transfers.alias.enrollment.step2.selectedAccount"
                                    />
                                </span>
                                <div className="data-name">{productGroupId}</div>
                            </div>
                            <Image src="arrowRight.svg" className="svg-icon" />
                            <div className="data-wrapper align-start">
                                <div className="data-label">
                                    <div>
                                        <I18n id="transfers.alias.enrollment.step2.phoneNumber" />
                                    </div>
                                </div>
                                <div className="data-name">{alias}</div>
                            </div>
                        </div>
                    </div>
                </section>
                <Token labelButton="global.send" isSubmitting={isSubmitting} />
            </React.Fragment>
        </Form>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        formData: SelectorsStoreTransfers.getFormData(store),
        user: SelectorsStoreSession.getUser(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithRouter,
    WithFormik({
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        validationSchema: (props) => {
            const { user } = props;

            return Yup.object().shape({
                otp: user.needsBiometric
                    ? Yup.string().nullable()
                    : Yup.string().required(UtilsI18n.get("form.credential.otp.required")),
            });
        },

        handleSubmit: (props, formikBag) => {
            const { otp } = props;
            const { dispatch, formData, history, transaction } = formikBag.props;
            const { idActivity, idTransaction } = transaction || EMPTY_STR;
            const isFromTransactionsHistory = history.location.pathname.includes("/ticket");

            if (isFromTransactionsHistory) {
                dispatch(
                    SelectorsActionTransfers.signTransaction({
                        idForm: null,
                        idActivity,
                        idTransaction,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            } else {
                dispatch(
                    SelectorsActionTransfers.aliasEnrollmentSendRequest({
                        formData,
                        otp,
                        formikBag,
                    }),
                );
            }
        },
    }),
)(Component);
