import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "desktop";

export const PATH = { DESKTOP_LOAD_LAYOUT: "/desktop.loadLayout", DESKTOP_SAVE_LAYOUT: "/desktop.saveLayout" };

export const TYPE = FactoryType(NAME, [
    "LOAD_LAYOUT_REQUEST",
    "LOAD_LAYOUT_FAILURE",
    "LOAD_LAYOUT_SUCCESS",
    "SAVE_LAYOUT_REQUEST",
    "SAVE_LAYOUT_FAILURE",
    "SAVE_LAYOUT_SUCCESS",
    "TOGGLE_IS_EDITABLE",
    "DELETE_WIDGET",
    "ADD_WIDGET",
    "SET_LAYOUT",
    "ADD_AVAILABLE_WIDGET",
    "REMOVE_AVAILABLE_WIDGET",
    "TOGGLE_ENVIRONMENT_MODAL",
]);

export const PROP = {};

PROP.types = {
    widgets: PropTypes.array,
    layout: PropTypes.array,
    isEditable: PropTypes.bool,
    fetching: PropTypes.bool,
    showEnvironmentModal: PropTypes.bool,
    ...TypesRedux,
};

PROP.defaults = {
    widgets: [],
    layout: [],
    isEditable: false,
    fetching: true,
    showEnvironmentModal: false,
    ...DefaultsRedux,
};
