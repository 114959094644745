import { replace as Replace, go as Go } from "connected-react-router";
import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import {
    RESPONSE_TYPE,
    LEVEL,
    SCOPE,
    SCREENS_BEFORE,
    INSUFFICIENT_SIGNATURES,
    GLOBAL_UNEXPECTED_ERROR_KEY,
} from "~/constants";
import {
    SelectorsActionPermissionsAdvanced,
    SelectorsMiddleware as SelectorsMiddlewareAdvanced,
} from "~/store/administration/advanced";
import { SelectorsMiddleware as SelectorsMiddlewareGroups } from "~/store/administration/advanced/group";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionSession } from "~/store/session";
import * as UtilArray from "~/util/array";
import { credentialsToUnderscoreFormat } from "~/util/form";
import * as UtilI18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsAction } from "./_selectors";

const sagas = [
    TakeLatest(TYPE.LOAD_GROUP_REQUEST, loadGroupRequest),
    TakeLatest(TYPE.LOAD_GROUP_FORM_DATA_REQUEST, loadGroupFormDataRequest),
    TakeLatest(TYPE.SUBMIT_GROUP_FORM_PREVIEW_REQUEST, submitGroupFormPreviewRequest),
    TakeLatest(TYPE.SUBMIT_GROUP_FORM_REQUEST, submitGroupFormRequest),
    TakeLatest(TYPE.LOAD_GROUPS_REQUEST, loadGroupsOfUserRequest),
    TakeLatest(TYPE.UPDATE_GROUPS_OF_USER_PREVIEW, updateGroupsOfUserPreview),
    TakeLatest(TYPE.UPDATE_GROUPS_OF_USER_CONFIRM, updateGroupsOfUserConfirm),
    TakeLatest(TYPE.LOAD_GROUPS_OF_USER_TICKET_REQUEST, loadGroupsOfUserTicketRequest),
];

export default sagas;

const errorsKey = {
    noField: "NO_FIELD",
};

function* loadGroupRequest(props) {
    const response = yield Call(SelectorsMiddlewareAdvanced.loadGroupRequest, props);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.loadGroupFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADMINISTRATION],
            }),
        );
    } else {
        yield Put(SelectorsAction.loadGroupSuccess({ data: response.data.data }));
        yield Put(
            SelectorsActionPermissionsAdvanced.loadPermissionsSuccess({
                data: {
                    ...response.data.data,
                    // TODO rename groups on every reducer to uiPermissions
                    groups: response.data.data.uiPermissions,
                    permissions: response.data.data.permissions,
                },
            }),
        );
    }
}

function* loadGroupFormDataRequest({ id }) {
    const response = yield Call(SelectorsMiddlewareGroups.loadGroupFormDataRequest, id);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.loadGroupFormDataFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADMINISTRATION],
            }),
        );
    } else {
        const { group, permissions, isAdminGroup, availableUsers, adminUsers } = response.data.data;

        yield Put(
            SelectorsAction.loadGroupFormDataSuccess({
                data: {
                    group,
                    permissions,
                    adminUsers,
                    adminGroup: isAdminGroup || false,
                    availableUsers: UtilArray.mapItemsIds(availableUsers, "idUser"),
                },
            }),
        );
        yield Put(SelectorsActionPermissionsAdvanced.loadPermissionsSuccess({ data: response.data.data }));
    }
}

function* submitGroupFormPreviewRequest(props) {
    const { formData, formikBag, idGroup: id } = props;
    const response = yield Call(SelectorsMiddlewareGroups.submitGroupFormPreviewRequest, formData, id);

    if (formikBag) {
        formikBag.setSubmitting(false);
    }

    if (response.type === RESPONSE_TYPE.WARNING) {
        const errorMessage = response.data.data[errorsKey.noField] || UtilI18n.get("forms.fieldsErrors");
        if (formikBag) {
            formikBag.setErrors(response.data.data);
            formikBag.setTouched({ name: true });
        }
        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADMINISTRATION],
            }),
        );
    } else {
        const responseCredentials = yield Call(SelectorsMiddlewareForm.listCredentialsGroups, {
            idForm: null,
            idActivityToRead: "administration.advanced.group.create.send",
        });
        const credentialGroups = responseCredentials.data.data.groups;
        yield Put(SelectorsAction.submitGroupFormPreviewSuccess({ data: { credentialGroups, mode: "view" } }));
    }
}

function* submitGroupFormRequest(props) {
    const { formData, formikBag, idGroup: id } = props;
    const { name, description, permissions, users, status, ...credentials } = formData;
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    const response = yield Call(
        SelectorsMiddlewareGroups.submitGroupFormRequest,
        {
            name,
            description,
            permissions,
            users,
            status,
            ...credentialsWithUnderscore,
        },
        id,
    );

    formikBag.setSubmitting(false);

    if (response.type === RESPONSE_TYPE.WARNING) {
        const errorMessage = response.data.data[errorsKey.noField] || UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY);

        formikBag.setErrors(response.data.data);
        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADMINISTRATION],
            }),
        );
    } else {
        const successMesage = response.data.message;
        if (response.data.code === INSUFFICIENT_SIGNATURES) {
            yield Put(Replace("/transactions/list"));
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: successMesage,
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.TRANSACTIONS],
                }),
            );
        } else {
            yield Put(Replace(`/administration/advanced/groups`));
            yield Put(SelectorsActionSession.readPermissionsSuccess(response.data.data));
            yield Put(SelectorsAction.submitGroupFormSuccess());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: successMesage,
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.ADMINISTRATION],
                }),
            );
        }
    }
}

function* loadGroupsOfUserRequest(props) {
    const response = yield Call(SelectorsMiddlewareAdvanced.loadDetailsRequest, props);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.loadGroupsFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADMINISTRATION],
            }),
        );
    } else {
        yield Put(SelectorsAction.loadGroupsSuccess({ data: response.data.data }));
    }
}

function* updateGroupsOfUserPreview({ data, formikBag }) {
    formikBag.setSubmitting(false);
    const response = yield Call(SelectorsMiddlewareAdvanced.updateGroupsOfUserPreview, data);

    if (response.type === RESPONSE_TYPE.WARNING) {
        formikBag.setErrors(response.data.data);
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADMINISTRATION_GROUPS_OF_USER],
            }),
        );
    } else {
        const responseCredentials = yield Call(SelectorsMiddlewareForm.listCredentialsGroups, {
            idForm: null,
            idActivity: "administration.user.detail.groups.modify.send",
        });
        const credentialGroups = responseCredentials.data.data.groups;
        yield Put(SelectorsAction.onSuccess(data.idUser));
        yield Put(
            SelectorsAction.updateGroupsOfUserPreviewSuccess({
                selectedGroupsIds: data.groups,
                credentialGroups,
            }),
        );
    }
}

function* updateGroupsOfUserConfirm(props) {
    const { data, formikBag } = props;
    formikBag.setSubmitting(false);
    const groups = data.groupsToSave.map((group) => group.idGroup);
    const params = {
        idUser: data.idUser,
        groups,
    };

    const credentialsWithUnderscore = credentialsToUnderscoreFormat(data.credentials);
    const response = yield Call(SelectorsMiddlewareAdvanced.updateGroupsOfUser, {
        data: { ...params, ...credentialsWithUnderscore },
    });

    if (response.type === RESPONSE_TYPE.WARNING) {
        formikBag.setErrors(response.data.data);
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADMINISTRATION_GROUPS_OF_USER],
            }),
        );
    } else {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.message,
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.ADMINISTRATION],
            }),
        );
        yield Put(Go(SCREENS_BEFORE.TWO_LESS));
    }
}

function* loadGroupsOfUserTicketRequest(props) {
    // idTransaction
    const transactionResponse = yield Call(SelectorsMiddlewareForm.readTransaction, props);

    if (transactionResponse.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.loadGroupsOfUserTicketFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADMINISTRATION],
            }),
        );
    } else {
        const responseDetail = yield Call(SelectorsMiddlewareAdvanced.loadDetailsRequest, {
            id: transactionResponse.data.data.transaction.data.idUser,
        });
        const { availableGroups } = responseDetail.data.data;
        const transactionGroups = transactionResponse.data.data.transaction.data.groups;
        const { user } = responseDetail.data.data;
        const groups = availableGroups.filter((group) => transactionGroups.indexOf(group.idGroup) > -1);

        if (responseDetail.type === RESPONSE_TYPE.WARNING) {
            yield Put(SelectorsAction.loadGroupsOfUserTicketFailure());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION],
                }),
            );
        } else {
            yield Put(SelectorsAction.loadGroupsOfUserTickeSuccess({ data: { groups, user } }));
        }
    }
}
