import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import ClassNames from "classnames";

import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { Categorize } from "~/util/prop";

import AnimWait from "~/components/AnimWait";
import Container from "~/components/Component";
import Link from "~/components/Link";
import WidgetImage from "~/components/WidgetImage";

import Style from "./_Widget.rules.scss";

export const { NAME, TYPE } = Style;

export const PROP = {
    types: {
        "header-href": PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        "header-icon": PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        "header-iconRight": PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        "header-title": PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        name: PropTypes.string,
    },
    defaults: {
        name: NAME,
    },
};

export function Component(props) {
    const { children, getMessage, header = {}, hrefFunction, name, wait, widgetAlternative, withoutArrow, ...rest } = Categorize(props);

    return (
        <Container {...rest} name={name} type={TYPE} role="group">
            <Header>
                {typeof header.icon !== "string" ? header.icon : <WidgetImage src={header.icon} />}
                {typeof header.title !== "string" ? header.title : <h3>{getMessage(header.title) || header.title}</h3>}
                {header.iconRight && <div className="separator"/>}
                {typeof header.iconRight !== "string" ? header.iconRight : <div className="icon-right"><WidgetImage src={header.iconRight} /></div>}
                {!header.iconRight && !withoutArrow && <WidgetImage src="widget-arrow-without-color.svg" />}
            </Header>
            {wait && <AnimWait />}
            {!wait && children}
        </Container>
    );

    function Header({ children: ch }) {
        const className2 = (header.href && Style.CLASS_HASLINK) || undefined;
        return (
            <header className={ClassNames(className2, {"alternative": widgetAlternative, "withoutArrow": withoutArrow })}>
                <HLink />
            </header>
        );
        function handleClick() {
            if (hrefFunction) {
                hrefFunction();
            }
        }

        function HLink() {
            // no href? just the contents.
            if (!header.href) {
                return ch;
            }
            // string href? a Simple Link wrapper.
            if (typeof header.href === "string") {
                return (
                    <Link onClick={handleClick} to={header.href}>
                        {ch}
                    </Link>
                );
            }
            // An element, inject the contents of the header as children.
            return React.cloneElement(header.href, { children: ch });
        }
    }
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

function mapStateToProps(store) {
    return {
        getMessage: (key) => StoreI18n.getMessage(store, key),
    };
}

export default Connect(mapStateToProps)(Component);
