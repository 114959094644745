import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { connect as Connect } from "react-redux";

import {
    SelectorsStore as SelectorsStoreGroup,
    SelectorsAction as SelectorsActionGroup,
} from "~/store/administration/advanced/group";
import { SelectorsStore as SelectorsStoreTicket } from "~/store/administration/common/administrationTicket";
import { SelectorsStore as SelectorsStorePermissions } from "~/store/administration/common/permissions";
import { generateId as GenerateId } from "~/util/general";

import I18n from "~/components/I18n";
import Container from "~/pages/_components/Container";

import PermissionsList from "~/pages/administration/_components/PermissionsList";

import Style from "./Ticket.rules.scss";

export const NAME = "GroupFormDataTicketContent";

export const PROP = {
    types: {
        isFromBackoffice: PropTypes.bool,
        transactionData: PropTypes.shape({
            data: PropTypes.shape({
                description: PropTypes.string,
                name: PropTypes.string,
                status: PropTypes.string,
            }),
        }).isRequired,
    },
    defaults: {
        isFromBackoffice: false,
    },
};

export function Component(props) {
    const {
        isFromBackoffice,
        transactionData: { data = {} },
    } = props;

    const { availableUsers, dispatch, match } = props;
    const { id: idTicket, permissions, users } = data || {};
    const { id } = !isFromBackoffice ? match.params : {};

    React.useEffect(() => {
        if (!isFromBackoffice) {
            dispatch(SelectorsActionGroup.loadGroupFormDataRequest({ id: id || idTicket }));
        }
    }, [dispatch, id, idTicket, isFromBackoffice]);

    const permissionsList = () => (
        <div>
            <PermissionsList permissions={permissions}>
                {(list) =>
                    list ? (
                        <React.Fragment>
                            <h4 className="form-title">
                                <I18n id="administration.permissions.permissionsApplied" />
                            </h4>
                            {list}
                        </React.Fragment>
                    ) : (
                        <I18n id="administration.permissions.empty" />
                    )
                }
            </PermissionsList>
        </div>
    );

    const userList = () => {
        const userOptions = [];

        if (users) {
            users.forEach((user) => {
                return userOptions.push(
                    <div key={GenerateId()} className="data-desc">
                        {availableUsers.byId[user].fullName}
                    </div>,
                );
            });
        }

        return userOptions;
    };

    return (
        <Container className="container--layout align-items-center flex-grow" gridClassName="form-content">
            <Col className="col-12 col-xl-6" lg={6} md={9} sm={12}>
                <div className="transfer-block">
                    <div id={isFromBackoffice && Style.ID} className="transfer-data transfer-data-flow">
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="administration.advanced.group.create.name.label" />
                            </div>
                            <div className="data-desc"> {data?.name}</div>
                        </div>
                        {data?.description && (
                            <div className="data-wrapper">
                                <div className="data-label">
                                    <I18n id="administration.advanced.group.create.description.label" />
                                </div>
                                <div className="data-desc">{data?.description}</div>
                            </div>
                        )}
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="administration.advanced.group.create.status.label" />
                            </div>
                            <div className="data-desc">
                                <I18n id={`administration.advanced.group.create.status.${data?.status}.label`} />
                            </div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="administration.groups.permissions" />
                            </div>
                            {permissionsList()}
                        </div>
                        {availableUsers && availableUsers.byId && (
                            <div className="data-wrapper">
                                <div className="data-label">
                                    <I18n id="administration.members" />
                                </div>
                                <ul className="">{userList()}</ul>
                            </div>
                        )}
                    </div>
                </div>
            </Col>
        </Container>
    );
}

const mapStateToProps = (store) => ({
    availableUsers: SelectorsStoreGroup.getAvailableUsers(store),
    products: SelectorsStorePermissions.getProducts(store),
    transactionData: SelectorsStoreTicket.getData(store),
});

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Connect(mapStateToProps)(Component);
