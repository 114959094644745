import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "recoveryPassword";

export const PATH = {
    SESSION_RECOVER_PASSWORD_STEP1: "/session.recoverPassword.step1",
    SESSION_RECOVER_PASSWORD_STEP2: "/session.recoverPassword.step2",
    SESSION_RECOVER_PASSWORD_STEP3: "/session.recoverPassword.step3",
};

export const TYPE = FactoryType(NAME, [
    "RECOVER_PASS_CLEAN",
    "RECOVER_PASS_REMEMBER_USERNAME",
    "RECOVER_PASS_STEP1_REQUEST",
    "RECOVER_PASS_STEP1_FAILURE",
    "RECOVER_PASS_STEP1_SUCCESS",
    "RECOVER_PASS_STEP2_REQUEST",
    "RECOVER_PASS_STEP2_FAILURE",
    "RECOVER_PASS_STEP2_SUCCESS",
    "RECOVER_PASS_STEP3_REQUEST",
    "RECOVER_PASS_STEP3_FAILURE",
    "RECOVER_PASS_STEP3_SUCCESS",
]);

export const PROP = {};

PROP.types = {
    blocked: PropTypes.bool,
    exchangeToken: PropTypes.string,
    fetching: PropTypes.bool,
    verificationCode: PropTypes.string,
    ...TypesRedux,
};

PROP.defaults = { blocked: false, exchangeToken: null, fetching: false, verificationCode: null, ...DefaultsRedux };
