import React from "react";

import { routerActions as RouterActions } from "connected-react-router";
import { connect as Connect } from "react-redux";
import { bindActionCreators } from "redux";

import { SelectorsStore as SelectorsStoreTicket } from "~/store/administration/common/administrationTicket";
import { SelectorsActionSignaturesMedium } from "~/store/administration/medium";

import AdministrationTicket from "~/pages/administration/_components/tickets/AdministrationTicket";
import SignaturesSchemeTicket from "~/pages/administration/_components/tickets/SignaturesSchemeMediumTicket";

export const NAME = "SignaturesSchemeTicketContent";

export const PROP = {
    types: {},
    defaults: {},
};
export function Component(props) {
    return (
        <AdministrationTicket {...props}>
            <SignaturesSchemeTicket {...props} />
        </AdministrationTicket>
    );
}

const mapStateToProps = (store) => ({
    fetching: SelectorsStoreTicket.isFetching(store),
    transaction: SelectorsStoreTicket.getData(store),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(SelectorsActionSignaturesMedium, dispatch),
    routerActions: bindActionCreators(RouterActions, dispatch),
});

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Connect(mapStateToProps, mapDispatchToProps)(Component);
