import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import {
    SelectorsStore as SelectorsStoreWMBuyFunds,
    SelectorsAction as SelectorsActionWMBuyFunds,
} from "~/store/wm/funds/buy";
import * as UtilsI18n from "~/util/i18n";

import DataDate from "~/components/DataDate";
import I18n from "~/components/I18n";
import Ticket from "~/components/Ticket/Ticket";

import FormatNumber from "~/pages/wm/funds/_component/FormatNumber";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        transaction: PropTypes.shape({
            idTransaction: PropTypes.string,
            idTransactionStatus: PropTypes.string,
            valueDateAsString: PropTypes.string,
            executing: PropTypes.bool,
        }).isRequired,
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export const FORM_ID = "wm.buy";

export function Component(props) {
    const { ticketConfirmation, transaction } = props;
    const { data } = transaction;
    const { limitDate, orderCompleted } = data;

    const orderValid = orderCompleted ? (
        UtilsI18n.get(`${FORM_ID}.orderCompleted.label`)
    ) : (
        <DataDate value={limitDate} />
    );

    return (
        <section id={Style.ID}>
            <Ticket
                hasMobileComponent
                ticketConfirmation={ticketConfirmation}
                transaction={transaction}
                handleReject={handleReject}
                handleSign={handleSign}>
                <div className="data-section">
                    <div className="data-title">
                        <I18n id="wm.buy.data.transaction" />
                    </div>
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="wm.buy.label" />
                        </span>
                        <span className="data-name">{data?.paperName ?? EMPTY_STR} </span>
                    </div>
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="wm.buy.accounts.ticket" />
                        </span>

                        <span className="data-name">{data?.debitAccountAlias ?? EMPTY_STR} </span>
                    </div>
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="wm.buy.amount.ticket" />
                        </span>
                        <span className="data-name amount-number">
                            <FormatNumber amount={parseFloat(data.amount, 10)} currency={data?.currency} />
                        </span>
                    </div>
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="wm.allInvestments.orderValidUntil.label" />
                        </span>
                        <span className="data-name">{orderValid}</span>
                    </div>
                </div>
            </Ticket>
        </section>
    );

    function handleReject(formikBag) {
        const { dispatch } = props;
        const { idTransaction } = transaction;

        dispatch(
            SelectorsActionWMBuyFunds.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        const { dispatch } = props;
        const suppliersList = transaction && transaction.data ? transaction.data.suppliersList : null;

        dispatch(SelectorsActionWMBuyFunds.signTransactionPreview({ suppliersList, transaction }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreWMBuyFunds.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
