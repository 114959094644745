import { push as Push, goBack as GoBack } from "connected-react-router";
import { call as Call, put as Put, select as Select, takeLatest as TakeLatest } from "redux-saga/effects";

import {
    EMPTY_STR,
    LEVEL,
    QR_MODO_STATUS_REPONSE,
    QR_MODO_TYPE_ACCOUNT,
    REACT_APP_PINNING_DISABLED,
    REACT_APP_VERSION,
    RESPONSE_TYPE,
    SCOPE,
    SIGN_WITH_BIOMETRIC,
    VALIDATION_ERROR,
} from "~/constants";
import Store from "~/store";
import { SelectorsStore as SelectorsStoreAccount } from "~/store/account";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import IsTrustedBiometric from "~/store/form/_sagas/_isTrustedBiometric";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsStore as SelectorsStoreSession, SelectorsAction as SelectorsActionSession } from "~/store/session";
import * as UtilsConfig from "~/util/config";
import {
    adjustIdFieldErrors as AdjustIdFieldErrors,
    credentialsWithUnderscore as CredentialsWithUnderscore,
    hasIncorrectCredentials as HasIncorrectCredentials,
} from "~/util/form";
import * as UtilsI18n from "~/util/i18n";
import UtilLodash from "~/util/lodash";
import * as UtilsSdkModo from "~/util/sdkModo";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction } from "./_selectors";

export default [
    TakeLatest(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, cancelTransactionCustom),
    TakeLatest(TYPE.FETCH_SERVICE_DATA_REQUEST, fetchServiceData),
    TakeLatest(TYPE.LAUNCH_SDK_MODO_REQUEST, launchSdkModo),
    TakeLatest(TYPE.READ_TRANSACTION_REQUEST, readTransactionRequest),
    TakeLatest(TYPE.SERVICE_PAYMENT_PRE_REQUEST, servicePaymentPreRequest),
    TakeLatest(TYPE.SERVICE_PAYMENT_PREVIEW_REQUEST, servicePaymentPreviewRequest),
    TakeLatest(TYPE.SERVICE_PAYMENT_SEND_REQUEST, servicePaymentSendRequest),
    TakeLatest(TYPE.SIGN_TRANSACTION_REQUEST, signTransaction),
];

function* cancelTransactionCustom(props) {
    const { credentials, idTransaction, formikBag } = props;
    yield* handleCancelTransaction(credentials, idTransaction, formikBag);

    formikBag.setSubmitting(false);
}

function* fetchServiceData(props) {
    const { type, data } = yield Call(SelectorsMiddleware.fetchServiceData, props);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.fetchServiceDataFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.SERVICE_PAYMENT],
            }),
        );
    } else {
        yield Put(SelectorsAction.fetchServiceDataSuccess({ ...data.data }));
    }
}

function* handleCancelTransaction(credentials, idTransaction, formikBag) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const {
        data: { data },
        type,
    } = yield Call(SelectorsMiddlewareForm.cancelTransaction, {
        idTransactionToCancel: idTransaction,
        ...credentialsWithUnderscore,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data);
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("forms.cancelTransaction.errorMessage"),
                    level: LEVEL.ERROR,
                    scopes: SCOPE.SERVICE_PAYMENT,
                }),
            );
        }
    } else {
        yield* readTransactionRequest({ idTransaction });

        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("forms.cancelTransaction.confirmationMessage"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.TRANSACTIONS],
            }),
        );
    }
}

function* launchSdkModo({ formikBag, otp }) {
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
    }

    const { webToken } = Store.getState().session;

    const tokenToUse = otp || webToken;

    if (UtilLodash.isEmpty(tokenToUse)) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get(`returnCode.${VALIDATION_ERROR}`),
                level: LEVEL.ERROR,
                scopes: [SCOPE.QR_MODO],
            }),
        );
    } else {
        const accounts = yield Select(SelectorsStoreAccount.getAccounts);
        const user = yield Select(SelectorsStoreSession.getUser);

        try {
            const validCurrencies = UtilsConfig.getArray("client.qrModo.enabledCurrencies");
            const mappedAccounts = accounts // CUI accounts are removed
                .filter((account) => Object.keys(QR_MODO_TYPE_ACCOUNT).includes(account.productType))
                .filter((account) => validCurrencies.includes(account.currency))
                .map((account) => {
                    return UtilsSdkModo.mapAccountsToModo(account);
                });

            const configInitialize = {
                baseUrl: window.API_URL.replace("execute", "qrmodo"),
                dataSourceType: UtilsConfig.get("client.qrModo.dataSourceType"),
                headers: {
                    _otp: tokenToUse,
                    "x-app-version": REACT_APP_VERSION,
                    "cdp-Authorization": `Bearer ${user.accessToken}`,
                    "cdp-User-Agent": user.qrModoUserAgent,
                },
                maxAttempts: UtilsConfig.getInteger("client.qrModo.maxAttempts"),
                mode: UtilsConfig.get("client.qrModo.mode"),
                timeInterval: UtilsConfig.getInteger("client.qrModo.timeInterval"),
            };

            if (REACT_APP_PINNING_DISABLED === "false") {
                configInitialize.hashes = UtilsConfig.getArray("client.qrModo.pinning.hashList");
                // eslint-disable-next-line prefer-destructuring
                configInitialize.hostName = window.API_URL.match("^(?:https?://)?(?:[^@/\n]+@)?(?:www.)?([^:/\n]+)")[1];
            }

            formikBag.resetForm({ otp: EMPTY_STR });

            yield Call(UtilsSdkModo.initialize, configInitialize);

            const sdkResponse = yield Call(UtilsSdkModo.launchSdk, {
                accounts: mappedAccounts,
            });

            formikBag.setSubmitting(false);

            if (sdkResponse.status === QR_MODO_STATUS_REPONSE.INCOMPLETE) {
                yield Put(GoBack());
            } else if (sdkResponse.status === QR_MODO_STATUS_REPONSE.TIMEOUT) {
                yield Put(Push("/servicePayment/qrModo/list"));
            } else {
                const {
                    data: { data },
                } = yield Call(SelectorsMiddleware.qrModoReadTransaction, {
                    wallet_payment_id: sdkResponse.bankPaymentId,
                });

                const qrName = {
                    en: data["qrName-en"],
                    es: data["qrName-es"],
                    pt: data["qrName-pt"],
                };

                const qrData = {
                    qrCurrentInstallment: data.qrCurrentInstallment,
                    qrDueDate: data.qrDueDate,
                    qrEnabled: data.qrEnabled,
                    qrLimitAmount: data.qrLimitAmount,
                    qrLimitCurrency: data.qrLimitCurrency,
                    qrMaxLimitAmount: data.qrMaxLimitAmount,
                    qrMaxLimitCurrency: data.qrMaxLimitCurrency,
                    qrName,
                    qrTypeDiscount: data.qrTypeDiscount,
                };

                yield Put(SelectorsActionSession.saveQrData({ qrData }));

                yield Put(SelectorsAction.qrModoReadTransactionSuccess({ transaction: data.transaction }));

                yield Put(Push(`/servicePayment/qrModo/transaction/${data.transaction.idTransaction}/ticket`));
            }
        } catch (exception) {
            // eslint-disable-next-line no-console
            console.error("launchSdkModo exception: ", exception);
        }
    }
}

function* readTransactionRequest(props) {
    const { idTransaction } = props;
    const { type, data } = yield Call(SelectorsMiddlewareForm.readTransaction, {
        idTransactionToRead: idTransaction,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.SERVICE_PAYMENT],
            }),
        );
    } else {
        yield Put(SelectorsAction.readTransactionSuccess({ transaction: data.data.transaction }));
    }
}

function* servicePaymentPreRequest() {
    const { type, data } = yield Call(SelectorsMiddleware.servicePaymentPre);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.servicePaymentPreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.SERVICE_PAYMENT],
            }),
        );
    } else {
        yield Put(SelectorsAction.servicePaymentPreSuccess({ ...data.data }));
    }
}

function* servicePaymentPreviewRequest({ formData, formikBag, values }) {
    const { type, data } = yield Call(SelectorsMiddleware.servicePaymentPreview, formData);

    if (type === RESPONSE_TYPE.WARNING) {
        const message = data.code === VALIDATION_ERROR ? UtilsI18n.get("forms.fieldsErrors") : data.message;

        Object.keys(values).forEach((field) => formikBag.setFieldTouched(field, true));
        formikBag.setErrors(AdjustIdFieldErrors(data.data));

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.SERVICE_PAYMENT],
            }),
        );

        yield Put(SelectorsAction.servicePaymentPreviewFailure());
    } else {
        yield Put(SelectorsAction.servicePaymentPreviewSuccess({ ...data.data }));
    }

    formikBag.setSubmitting(false);
}

function* servicePaymentSendRequest({ formData, formikBag, otp }) {
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
    }

    const { webToken } = Store.getState().session;

    const tokenToUse = otp || webToken;

    let response;

    if (UtilLodash.isEmpty(tokenToUse)) {
        response = {
            data: {
                code: VALIDATION_ERROR,
                message: UtilsI18n.get(`returnCode.${VALIDATION_ERROR}`),
                data: {
                    otp: UtilsI18n.get("returnCode.COR027W"),
                },
            },
            status: 200,
            type: RESPONSE_TYPE.WARNING,
        };
    } else {
        response = yield Call(SelectorsMiddleware.servicePaymentSend, {
            ...formData,
            _otp: tokenToUse,
        });
    }

    if (response.type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = Object.keys(response.data.data).some((key) => key === "otp");

        let message = UtilsI18n.get("global.unexpectedError");

        if (hasIncorrectCredentials) {
            formikBag.setErrors(response.data.data);
        } else if (VALIDATION_ERROR === response.data.code) {
            message = response.data.data.message;
        }

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.SERVICE_PAYMENT],
            }),
        );

        formikBag.setSubmitting(false);

        yield Put(SelectorsAction.servicePaymentSendFailure());
    } else {
        const { idTransaction } = response.data;
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });

        yield Put(SelectorsAction.servicePaymentSendSuccess({ transaction }));
    }
}

function* signTransaction(props) {
    const { credentials, idActivity, idForm, idTransaction, formikBag } = props;
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
    }

    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { data, type } = yield Call(
        SelectorsMiddlewareForm.sign,
        { idForm, idTransaction, ...credentialsWithUnderscore },
        idActivity,
    );

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(AdjustIdFieldErrors(data.data));
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.SERVICE_PAYMENT],
                }),
            );
        }

        yield Put(SelectorsAction.signTransactionFailure());

        formikBag.setSubmitting(false);
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, { idTransactionToRead: idTransaction });

        yield Put(SelectorsAction.signTransactionSuccess({ transaction }));

        formikBag.setSubmitting(false);
    }
}
