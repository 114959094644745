import React from "react";

import ClassNames from "classnames";
import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";

import Namespace from "~/components/Box/Box.scss";
import Element from "~/components/Component";

export const { NAME, TYPE, TAG } = Namespace;

const POS = {
    start: "start",
    center: "center",
    end: "end",
    stretch: "stretch",
    between: "between",
    around: "around",
    evenly: "evenly",
};

export const PROP = {
    types: {
        align: PropTypes.oneOf([POS.center, POS.end, POS.start, POS.stretch, EMPTY_STR]),
        children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
        directionColumn: PropTypes.bool,
        flex: PropTypes.bool,
        grow: PropTypes.bool,
        inlineFlex: PropTypes.bool,
        justify: PropTypes.oneOf([POS.around, POS.between, POS.center, POS.end, POS.evenly, POS.start, EMPTY_STR]),
        nowrap: PropTypes.bool,
        onClick: PropTypes.func,
        self: PropTypes.oneOf([POS.center, POS.end, POS.start, POS.stretch, EMPTY_STR]),
        style: PropTypes.shape({}),
        tag: PropTypes.string,
        wrap: PropTypes.bool,
    },
    defaults: {
        align: EMPTY_STR,
        children: null,
        directionColumn: false,
        flex: false,
        grow: false,
        inlineFlex: false,
        justify: EMPTY_STR,
        nowrap: false,
        onClick: null,
        role: EMPTY_STR,
        self: EMPTY_STR,
        style: null,
        tag: TAG,
        wrap: false,
    },
};

export function Component({
    align,
    children,
    className,
    directionColumn,
    flex,
    grow,
    inlineFlex,
    justify,
    nowrap,
    onClick,
    role,
    self,
    style,
    tag,
    wrap,
}) {
    return (
        <Element
            className={ClassNames(
                className,
                { "align-center": align === POS.center },
                { "align-end": align === POS.end },
                { "align-start": align === POS.start },
                { "align-stretch": align === POS.stretch },
                { "direction-column": directionColumn },
                { flex },
                { grow },
                { "inline-flex": inlineFlex },
                { "justify-around": justify === POS.around },
                { "justify-between": justify === POS.between },
                { "justify-center": justify === POS.center },
                { "justify-end": justify === POS.end },
                { "justify-evenly": justify === POS.evenly },
                { "justify-start": justify === POS.start },
                { nowrap },
                { "self-center": self === POS.center },
                { "self-end": self === POS.end },
                { "self-start": self === POS.start },
                { "self-stretch": self === POS.stretch },
                { wrap },
            )}
            name={NAME}
            onClick={onClick}
            style={style}
            tag={tag}
            type={TYPE}
            role={role}>
            {children}
        </Element>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
