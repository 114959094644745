import React from "react";

import { push as Push } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import Container from "~/containers/External/Form";
import { SelectorsAction as SelectorsActionSession } from "~/store/session";

import Box from "~/components/Box";
import Button from "~/components/Button";
import I18n from "~/components/I18n";
import Image from "~/components/Image";

import Style from "./LoginStep4.rules.scss";

export const NAME = "VersionWarningAndLogout";

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
    },
    defaults: {},
};
export function Component(props) {
    const onClose = () => {
        const { dispatch } = props;

        dispatch(SelectorsActionSession.logout());

        dispatch(Push("/"));
    };

    return (
        <React.Fragment>
            <Container
                className={Style.CLASS}
                head-onClose={onClose}
                head-title="login.versionWarningAndLogout.title"
                head-hideHeaderAndFooter
                name={NAME}
                scopeToShowNotification={SCOPE.LOGIN}>
                <a onClick={onClose}>
                    <Image className="cross" src="cross.svg" />
                </a>
                <Box className="above-the-fold">
                    <Box>
                        <div className="input-group input-group-text-area">
                            <div className="form-control">
                                <I18n id="login.versionWarningAndLogout.description" tag="p" />
                            </div>
                        </div>
                    </Box>
                    <Box className="conditions-buttons">
                        <Button bsStyle="primary" className="button-step" label="global.close" onClick={onClose} />
                    </Box>
                </Box>
            </Container>
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Connect()(Component);
