/* global document window */
/* eslint-disable react/jsx-filename-extension */
import "react-app-polyfill/stable";
import "intersection-observer";
import React from "react";

import { ConnectedRouter } from "connected-react-router";
import ReactDOM from "react-dom";

import { NODE_ENV } from "~/constants";
import Provider from "~/store/Provider";
import Device from "~/util/device";
import History from "~/util/history";
import Logger from "~/util/logger";

import ErrorBoundary from "~/components/ErrorBoundary";

import App from "./App";

window.startApp = () => {
    ReactDOM.render(
        <Provider loading={null}>
            <ConnectedRouter history={History}>
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            </ConnectedRouter>
        </Provider>,
        document.getElementById("root"),
    );
};

// Phonegap app start in ondeviceready inside index.js
if (!Device.isMobileNative()) {
    window.startApp();
}

Logger.info("NODE_ENV: %o", NODE_ENV);
