import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { SelectorsStore as SelectorsStoreForm } from "~/store/form";

import I18n from "~/components/I18n";
import FormattedAmount from "~/pages/_components/FormattedAmount";

export const NAME = "PayThirdPartiesCreditCard";

export const PROP = {
    types: {
        previewData: PropTypes.shape({
            debitAmount: PropTypes.shape({
                currency: PropTypes.string.isRequired,
                quantity: PropTypes.number.isRequired,
            }).isRequired,
            amount: PropTypes.shape({
                currency: PropTypes.string.isRequired,
                quantity: PropTypes.number.isRequired,
            }).isRequired,
            creditCardNumber: PropTypes.string.isRequired,
            debitAccountAlias: PropTypes.string.isRequired,
            creditCardMark: PropTypes.string.isRequired,
            notificationEmails: PropTypes.arrayOf(PropTypes.string),
            notificationBody: PropTypes.string,
        }).isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const { previewData } = props;
    const {
        debitAmount,
        amount,
        creditCardNumber,
        debitAccountAlias,
        creditCardMark,
        notificationEmails,
        notificationBody,
    } = previewData;

    const showDebitAmount = debitAmount.currency !== amount.currency;
    return (
        <React.Fragment>
            <p className="text-lead">
                <I18n id="forms.payThirdPartiesCreditCard.confirmation.title" />
            </p>

            <div className="data-wrapper">
                <span className="data-label">
                    <I18n id="forms.payThirdPartiesCreditCard.confirmation.creditCard" />{" "}
                </span>
                <span className="data-text">
                    {creditCardMark} {creditCardNumber}
                </span>
            </div>

            <div className="transfer-block">
                <div className="transfer-data transfer-data-highlight">
                    <span className="data-label">
                        <I18n id="forms.payThirdPartiesCreditCard.confirmation.amount" />{" "}
                    </span>
                    <FormattedAmount currency={amount.currency} quantity={amount.quantity} />
                </div>

                {showDebitAmount && (
                    <div className="transfer-data data-wrapper">
                        <span className="data-label">
                            <I18n id="forms.payThirdPartiesCreditCard.confirmation.debitAmount" />{" "}
                        </span>
                        <FormattedAmount currency={debitAmount.currency} quantity={debitAmount.quantity} />
                    </div>
                )}
            </div>

            <div className="data-wrapper">
                <span className="data-label">
                    <I18n id="forms.payThirdPartiesCreditCard.confirmation.debitAccount" />{" "}
                </span>
                <span className="data-name">{debitAccountAlias}</span>
            </div>

            {notificationEmails && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.payThirdPartiesCreditCard.confirmation.notificationEmails" />{" "}
                    </span>
                    <span className="data-text">{notificationEmails.join(", ")}</span>
                </div>
            )}
            {notificationBody && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.payThirdPartiesCreditCard.confirmation.notificationBody" />{" "}
                    </span>
                    <span className="data-text">{notificationBody}</span>
                </div>
            )}
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (state) => ({
    name: SelectorsStoreForm.getName(state),
    previewData: SelectorsStoreForm.getPreviewData(state),
});

export default connect(mapStateToProps)(Component);
