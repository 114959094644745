import React from "react";

import * as UtilsConfig from "~/util/config";
import { Types as TypesHtmlElement, Defaults as DefaultsHtmlElement } from "~/util/prop/html-element";

import Image from "~/components/Image";

export const NAME = "ExternalFooterMobile";

export const PROP = {
    types: {
        ...TypesHtmlElement,
    },
    defaults: {
        ...DefaultsHtmlElement,
    },
};

export function Component() {
    // TODO: Fix based on the new styles
    return (
        <div className="enrollment-footer">
            <div className="enrollment-footer-block">
                <Image src="telephone.svg" />
                <span className="enrollment-footer-text">{UtilsConfig.get("core.contactNumber.label")}</span>
            </div>
            <div className="enrollment-footer-block">
                <span className="enrollment-footer-text">{UtilsConfig.get("core.portal.url.label")}</span>
            </div>
            <div className="enrollment-footer-block">
                <Image src="whatsapp-external-footer.svg" />
                <span className="enrollment-footer-text">{UtilsConfig.get("core.mobile.contactNumber.label")}</span>
            </div>
        </div>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
