import React from "react";

import { EMPTY_STR, SPACE_STR } from "~/constants/index";
import { PROP as STORE_PROP, OPERATION_TYPE } from "~/store/preferentialTradingPrice";
import { generateId as GenID } from "~/util/general";
import { toNumber as ToNumber } from "~/util/number";

import DataNumber from "~/components/DataNumber";
import GridLayout from "~/components/GridLayout";
import I18n from "~/components/I18n";

import Style from "./TransactionItem.rules.scss";

export const NAME = "TransactionItem";

export const PROP = {
    types: {
        ...STORE_PROP.types,
    },
    defaults: {
        ...STORE_PROP.defaults,
    },
};

const headerOne = "preferentialTradingPrice.transactions.header.one";
const headerOneMobile = "preferentialTradingPrice.transactions.header.one.mobile";
const headerTwo = "preferentialTradingPrice.transactions.header.two";
const headerThree = "preferentialTradingPrice.transactions.header.three";
const headerFour = "preferentialTradingPrice.transactions.header.four";
const headerFive = "preferentialTradingPrice.transactions.header.five";
const headerSix = "preferentialTradingPrice.transactions.header.six";

const HEADERS = [headerOne, headerTwo, headerThree, headerFour, headerFive, headerSix];

const MAX_ROWS = 5;

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {};

    render() {
        const { transactions, lang } = this.props;
        const items = transactions || [];

        return (
            <section id={Style.ID}>
                <div className="transactionItem">
                    <div className="header">
                        <GridLayout>
                            {HEADERS.map((heading) => (
                                <div key={GenID()}>
                                    <I18n id={heading} />
                                </div>
                            ))}
                        </GridLayout>
                    </div>
                    <div className={items.length > MAX_ROWS ? "table-body scrollable" : "table-body"}>
                        {items.map(({ fullCreationDateTimeAsString, idTransaction, data }) => {
                            const { creditAmount, operationType, debitAmount, quotation } = data;
                            const creationDateTime = fullCreationDateTimeAsString.split(SPACE_STR);
                            const [date, time] = creationDateTime;

                            return (
                                <GridLayout key={GenID()} className="body position-clickable">
                                    <div className="only-web">{date || EMPTY_STR}</div>
                                    <div className="only-mobile">
                                        <I18n className="header-mobile" id={headerOneMobile} />
                                        {fullCreationDateTimeAsString || EMPTY_STR}
                                    </div>
                                    <div className="only-web">
                                        <I18n className="header-mobile" id={headerTwo} /> {time || EMPTY_STR}
                                    </div>
                                    <div>
                                        <I18n className="header-mobile" id={headerThree} />
                                        <DataNumber value={creditAmount.quantity} prefix={creditAmount.currency} />
                                    </div>
                                    <div>
                                        <I18n className="header-mobile" id={headerFour} />
                                        <DataNumber value={debitAmount.quantity} prefix={debitAmount.currency} />
                                    </div>
                                    <div>
                                        <I18n className="header-mobile" id={headerFive} />
                                        <DataNumber
                                            value={ToNumber(quotation, lang)}
                                            prefix={
                                                operationType === OPERATION_TYPE.BANK_IS_BUYING
                                                    ? creditAmount?.currency
                                                    : debitAmount?.currency
                                            }
                                        />
                                    </div>
                                    <div>
                                        <I18n className="header-mobile" id={headerSix} /> {idTransaction || EMPTY_STR}
                                    </div>
                                </GridLayout>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}
export default Component;
