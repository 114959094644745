import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { goBack as GoBack } from "connected-react-router";

import { SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import { SelectorsStore as SelectorsStoreComex, SelectorsAction as SelectorsActionComex } from "~/store/comex";
import { SelectorsStore as SelectorsStoreComexExport } from "~/store/comex/export";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as UtilsI18n from "~/util/i18n";

import Notification from "~/pages/_components/Notification";

import FormEdit from "./step1Edit/Step1Edit";
import FormPreview from "./step2Preview/Step2Preview";
import FormView from "./step3View/Step3View";

export const NAME = "PresentationDocumentsCreditLetterTicket";

export const PROP = {
    types: {
        isChangingEnvironment: PropTypes.bool,
    },
    defaults: {
        isChangingEnvironment: false,
    },
};

export function Component(props) {
    const { dispatch, fetching, isChangingEnvironment, match, mode, transaction, prevMode } = props;
    const { id } = match.params;

    React.useEffect(() => {
        if (!isChangingEnvironment) {
            dispatch(SelectorsActionComex.readTransactionRequest({ idTransaction: id }));
        }
    }, [dispatch, id, isChangingEnvironment]);

    return (
        <React.Fragment>
            <Notification scopeToShow={SCOPE.COMEX} />
            <Notification scopeToShow={SCOPE.TRANSACTIONS} />
            <Container
                name={NAME}
                wait={fetching}
                step={mode}
                head-title={UtilsI18n.get("comex.presentationDocumentsCreditLetter.title")}
                head-onBack={handleBack}
                head-onBackWeb={handleBack}>
                {transaction.data && mode === MODE.EDIT && <FormEdit />}
                {mode === MODE.PREVIEW && <FormPreview />}
                {mode === MODE.VIEW && <FormView transaction={transaction} />}
            </Container>
        </React.Fragment>
    );

    function handleBack() {
        if (prevMode && prevMode !== mode && mode !== MODE.VIEW) {
            dispatch(SelectorsActionComex.closeConfirmation());
        } else {
            dispatch(GoBack());
        }
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        fetching: SelectorsStoreComexExport.getFetching(store),
        isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
        mode: SelectorsStoreComexExport.getMode(store),
        prevMode: SelectorsStoreComexExport.getPrevMode(store),
        transaction: SelectorsStoreComex.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
