import React from "react";

import { goBack as GoBack, push as Push } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";

import { STATUS } from "~/constants/transaction";
import ContainerExternal from "~/containers/External/Payment";
import ContainerAdministration from "~/containers/Internal/Administration/Simple";
import { SelectorsStore as SelectorsStoreSettings } from "~/store/settings";
import UtilsLodash from "~/util/lodash";

import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";

import FormSignatures from "~/pages/forms/_components/FormSignatures";
import TransactionStatus from "~/pages/transactions/_components/TransactionStatus";

import Information from "./Information";
import Style from "./PersonalInformationTicket.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        transaction: PropTypes.shape({
            executing: PropTypes.bool,
            idTransaction: PropTypes.string,
            idTransactionStatus: PropTypes.string,
            isFromBackoffice: PropTypes.bool,
            transactionData: PropTypes.object,
            valueDateAsString: PropTypes.string,
        }),
    },
    defaults: {
        transaction: {
            executing: false,
            idTransaction: PropTypes.string,
            idTransactionStatus: STATUS.FINISHED,
            isFromBackoffice: false,
            valueDateAsString: new Date().toString(),
        },
    },
};

export function Component(props) {
    const {
        dispatch,
        fetching,
        isFromBackoffice,
        match: { params },
        transaction,
    } = props;

    const handleBack = React.useCallback(() => {
        if (UtilsLodash.isEmpty(params)) {
            dispatch(Push("/desktop"));
        } else {
            dispatch(GoBack());
        }
    }, [dispatch, params]);

    if (!transaction) {
        return null;
    }

    const { data, executing, idTransaction, idTransactionStatus, valueDateAsString } = transaction;

    const Container = isFromBackoffice ? ContainerExternal : ContainerAdministration;

    return (
        <Container
            name={NAME}
            head-onBack={handleBack}
            head-title="change.personal.data.title"
            hideSteps
            wait={fetching}>
            <div id={Style.ID}>
                <section>
                    <figure>
                        <TransactionStatus idTransactionStatus={idTransactionStatus} />
                    </figure>
                    <div>
                        <p className="text-lead">
                            {idTransactionStatus === STATUS.PENDING && executing ? (
                                <I18n id="transactionDelay.notification" />
                            ) : (
                                <I18n id={`forms.transaction.ticket.status.${idTransactionStatus}`} />
                            )}
                        </p>
                    </div>
                </section>
                <section>
                    <div>
                        <h3 className="data-title">
                            <I18n id="forms.transaction.ticket" />
                        </h3>
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="forms.transaction.ticket.idTransaction" />
                            </span>
                            <span className="data-name">{idTransaction}</span>
                        </div>
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="forms.transaction.ticket.date" />
                            </span>
                            <span className="data-date">{valueDateAsString}</span>
                        </div>
                    </div>
                    <Information data={data?.infoUserModify || {}} />
                    <FormSignatures transaction={transaction} />
                </section>
            </div>
        </Container>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        fetching: SelectorsStoreSettings.getFetching(store),
        transaction: SelectorsStoreSettings.getChangeTransactionData(store),
    };
};

export default HighOrder(WithRouter, Connect(mapStateToProps))(Component);
