import React from "react";

import { push as Push, replace as Replace } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/Simple";
import {
    SelectorsAction as SelectorsActionCommunication,
    SelectorsStore as SelectorsStoreCommunication,
    TRAYS,
} from "~/store/communications";

import Button from "~/components/Button";

import Style from "~/pages/communications/CommunicationMain.rules.scss";
import List from "~/pages/communications/_components/List";

export const { NAME } = Style;

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        isDesktop: PropTypes.bool.isRequired,
        detail: PropTypes.objectOf(PropTypes.any),
        fetching: PropTypes.bool,
    },
    defaults: { detail: null, fetching: false },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        const { activeTray, dispatch } = this.props;
        dispatch(SelectorsActionCommunication.listTypesRequest());
        dispatch(
            SelectorsActionCommunication.listRequest({
                direction: activeTray === TRAYS.SENT ? "CUSTOMER_TO_BANK" : "BANK_TO_CUSTOMER",
                ...(activeTray !== TRAYS.ALL && { onlyUnread: activeTray === TRAYS.UNREAD }),
                activeTray,
            }),
        );
    }

    componentWillUnmount() {
        const { dispatch } = this.props;

        dispatch(SelectorsActionCommunication.addMessageSucces());
    }

    handleComposeClick = (isReply) => {
        const { isDesktop, dispatch } = this.props;

        if (isDesktop) {
            if (isReply) {
                dispatch(SelectorsActionCommunication.showReplyPanel());
            } else {
                dispatch(SelectorsActionCommunication.showComposePanel());
            }
        } else {
            dispatch(Push("/communications/compose"));
        }
    };

    handleComposeCloseClick = () => {
        const { detail, dispatch } = this.props;

        if (detail) {
            dispatch(SelectorsActionCommunication.showReadPanel());
        } else {
            dispatch(SelectorsActionCommunication.hideAllPanels());
        }
    };

    handleBackClick = () => {
        const { dispatch } = this.props;
        dispatch(Push("/desktop"));
    };

    handleClose = () => {
        const { dispatch } = this.props;
        dispatch(Replace(`/desktop`));
    };

    handleClick = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionCommunication.addMessageFech());
        dispatch(Push("/communications/compose"));
    };

    render() {
        const { isDesktop, fetching } = this.props;
        return (
            <React.Fragment>
                <Container
                    name={Style.NAME}
                    className={Style.CLASS}
                    head-title="communications.title"
                    wait={fetching}
                    head-onClose={this.handleClose}
                    head-onBackWeb={this.handleBackClick}
                    scopeToShowNotification={SCOPE.COMMUNICATIONS}>
                    <div className="mailing">
                        <Button label="communications.compose.message.new" onClick={this.handleClick} />
                        <List isDesktop={isDesktop} />
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    activeTray: SelectorsStoreCommunication.getActiveTray(store),
    types: SelectorsStoreCommunication.listTypes(store),
    detail: SelectorsStoreCommunication.detail(store),
    fetching: SelectorsStoreCommunication.isFetching(store),
});

export default Connect(mapStateToProps)(Component);
