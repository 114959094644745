import React from "react";

import { push as Push } from "connected-react-router";
import { Field, Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import { CREDENTIAL_TYPE_PASSWORD, EMPTY_STR, SCOPE } from "~/constants";
import Container from "~/containers/External/Dash";
import {
    SelectorsAction as SelectorsActionRecoverPassword,
    SelectorsStore as SelectorsStoreRecoverPassword,
} from "~/store/recoverypassword/noToken";
import * as ConfigUtils from "~/util/config";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Credential from "~/pages/_components/fields/credentials/Credential";
import WithExchangeToken from "~/pages/_components/withExchangeToken";

import CancelConfirmationModal from "~/pages/enrollment/_CancelConfirmationModal";

import Style from "./RecoveryPass.scss";

export const { NAME, CLASS, RECOVERYPASS } = Style;

const FORM_ID = "recoveryPasswordNoToken.step4";

export const PROP = {
    types: {
        isFetching: PropTypes.bool,
    },
    defaults: {
        isFetching: false,
    },
};

export function Component(props) {
    const { dispatch, isFetching } = props;
    const [modal, setModal] = React.useState(false);

    const showModal = () => {
        setModal(true);
    };

    const handleConfirmation = () => {
        dispatch(Push("/"));
    };

    const handleCancelation = () => {
        setModal(false);
    };

    return (
        <Container
            name={NAME}
            head-onClose={showModal}
            wait={isFetching}
            scopeToShowNotification={SCOPE.RECOVERY_PASSWORD}>
            <div className={CLASS}>
                <div id={Style.NAME}>
                    <CancelConfirmationModal
                        show={modal}
                        handleCancelation={handleCancelation}
                        handleConfirmation={handleConfirmation}
                    />
                    <div className="login-step">
                        <div className="login-header">
                            <span className="login-header-text">
                                <I18n id={`${FORM_ID}.title`} />
                            </span>
                        </div>
                        <div className="login-row">
                            <div className="login-image">
                                <Image
                                    src="administration-title-icon.svg"
                                    className="login-image-recovery administration-icon"
                                />
                            </div>
                        </div>
                        <div className="login-row">
                            <Form className={Style.RECOVERYPASS}>
                                <div className="login-form ">
                                    <Field
                                        component={Credential}
                                        idForm={FORM_ID}
                                        name="password"
                                        placeholder={EMPTY_STR}
                                        showStrength
                                        type={CREDENTIAL_TYPE_PASSWORD}
                                    />
                                    <Field
                                        component={Credential}
                                        idForm={FORM_ID}
                                        name="passwordConfirmation"
                                        placeholder={EMPTY_STR}
                                        type={CREDENTIAL_TYPE_PASSWORD}
                                    />
                                    <section>
                                        <p className="help">
                                            <I18n
                                                id="global.passwordRules.rule1"
                                                MIN_LENGTH={ConfigUtils.get("core.password.minLength")}
                                            />
                                        </p>
                                        <p className="help">
                                            <I18n id="global.passwordRules.rule2" />
                                        </p>
                                        <p className="help">
                                            <I18n id="global.passwordRules.rule3" />
                                        </p>
                                    </section>
                                    <div>
                                        <Button
                                            className="login-form-button"
                                            bsStyle="primary"
                                            label="global.continue"
                                            type="submit">
                                            {UtilsI18n.get("global.save")}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    isFetching: SelectorsStoreRecoverPassword.isFetching(store),
});

export default Compose(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            password: EMPTY_STR,
            passwordConfirmation: EMPTY_STR,
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    password: Yup.string()
                        .required(UtilsI18n.get("global.newPassword.required"))
                        .min(
                            ConfigUtils.get("core.password.minLength"),
                            UtilsI18n.get("global.password.minLengthTooShort"),
                        ),
                    passwordConfirmation: Yup.string()
                        .required(UtilsI18n.get("global.newPasswordConfirmation.required"))
                        .oneOf([values.password], UtilsI18n.get("global.newPasswordConfirmation.notEquals")),
                }),
            ),
        handleSubmit: (data, formikBag) => {
            const { dispatch } = formikBag.props;
            const { password, passwordConfirmation } = data;

            dispatch(
                SelectorsActionRecoverPassword.recoverPassStep4Request({ formikBag, password, passwordConfirmation }),
            );
        },
    }),
    WithExchangeToken,
)(Component);
