import { LOCATION_CHANGE } from "connected-react-router";

import { TYPE, PROP, TRAYS } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return {
                ...storeProp,
                selectedAttachments: null,
                ...(action.payload.location.pathname.indexOf("communications") === -1 && { activeTray: TRAYS.ALL }),
            };
        case TYPE.DETAIL_REQUEST:
        case TYPE.SEND_REQUEST:
        case TYPE.DOWNLOAD_ATTACHMENT_REQUEST:
        case TYPE.REPLY_REQUEST:
        case TYPE.FETCH_MORE_REQUEST:
        case TYPE.LOADING:
        case TYPE.MARK_LIST_AS_READ_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.SEND_FAILURE:
        case TYPE.SEND_SUCCESS:
        case TYPE.DOWNLOAD_ATTACHMENT_FAILURE:
        case TYPE.DOWNLOAD_ATTACHMENT_SUCCESS:
        case TYPE.REPLY_FAILURE:
        case TYPE.REPLY_SUCCESS:
        case TYPE.MARK_AS_READ_FAILURE:
        case TYPE.MARK_LIST_AS_READ_FAILURE:
        case TYPE.MARK_AS_UNREAD_FAILURE:
        case TYPE.DETAIL_FAILURE:
        case TYPE.LIST_TYPES_FAILURE:
        case TYPE.LIST_FAILURE:
        case TYPE.FETCH_MORE_FAILURE:
        case TYPE.COMMUNICATION_TRAYS_LIST_REQUEST:
        case TYPE.COMMUNICATION_TRAYS_LIST_FAILURE:
        case TYPE.MARK_AS_READ_SUCCESS:
        case TYPE.MARK_AS_UNREAD_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
            };
        case TYPE.DATA_TO_NEW_MESSAGE:
            return {
                ...storeProp,
                dataToNewMessage: {
                    subject: action.subject,
                    trayLabel: action.trayLabel,
                    message: action.message,
                },
            };
        case TYPE.RESET_DATA_TO_NEW_MESSAGE:
            return {
                ...storeProp,
                dataToNewMessage: null,
            };
        case TYPE.DETAIL_SUCCESS:
            return {
                ...storeProp,
                detail: action.communication,
                attachments: action.attachments,
                isFetching: false,
            };
        case TYPE.SELECT_ATTACHMENT:
            return {
                ...storeProp,
            };
        case TYPE.SET_ATTACHMENT: {
            const { selectedAttachments } = storeProp;
            return {
                ...storeProp,
                selectedAttachments: selectedAttachments ? [...selectedAttachments, action.file] : [action.file],
            };
        }
        case TYPE.REMOVE_ATTACHMENT:
            return {
                ...storeProp,
                selectedAttachments: [
                    ...storeProp.selectedAttachments.slice(0, action.index),
                    ...storeProp.selectedAttachments.slice(action.index + 1, storeProp.selectedAttachments.length),
                ],
            };
        case TYPE.PRELOADED_MESSAGE:
            return {
                ...storeProp,
                preloadedMessage: action.preloadedMessage,
            };
        case TYPE.LATEST_COMMUNICATION_SUCCESS:
            return {
                ...storeProp,
                unreadCommunications: action.unreadCommunications,
            };
        case TYPE.LIST_TYPES_REQUEST:
            return {
                ...storeProp,
                types: [],
                isFetching: true,
            };
        case TYPE.LIST_TYPES_SUCCESS:
            return {
                ...storeProp,
                types: action.types,
            };
        case TYPE.LIST_REQUEST:
            return {
                ...storeProp,
                list: [],
                isFetching: true,
                activeTray: action.activeTray || storeProp.activeTray,
            };
        case TYPE.LIST_SUCCESS:
            return {
                ...storeProp,
                list: action.list,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
                isFetching: false,
            };
        case TYPE.TOGGLE_MESSAGE_STATUS:
            /* eslint-disable no-case-declarations */
            let { unreadCommunications } = storeProp;
            if (!storeProp.list[action.index].userRead) {
                unreadCommunications = unreadCommunications > 0 ? unreadCommunications - 1 : 0;
            } else {
                unreadCommunications += 1;
            }
            return {
                ...storeProp,
                /* Based on the index, it returns a list with the updated
                message and leaves the rest as they were in storeProps.  */
                list: [
                    ...storeProp.list.slice(0, action.index),
                    { ...storeProp.list[action.index], userRead: !storeProp.list[action.index].userRead },
                    ...storeProp.list.slice(action.index + 1, storeProp.list.length),
                ],
                unreadCommunications,
            };
        case TYPE.REMOVE_FROM_TRAY:
            return {
                ...storeProp,
                list: [
                    ...storeProp.list.slice(0, action.index),
                    ...storeProp.list.slice(action.index + 1, storeProp.list.length),
                ],
                unreadCommunications: storeProp.unreadCommunications > 0 ? storeProp.unreadCommunications - 1 : 0,
            };
        case TYPE.LEFT_PANEL_COMPOSE:
            return {
                ...storeProp,
                leftPanel: "compose",
                subject: action.subject,
            };
        case TYPE.LEFT_PANEL_REPLY:
            return {
                ...storeProp,
                leftPanel: "reply",
            };
        case TYPE.LEFT_PANEL_READ:
            return {
                ...storeProp,
                leftPanel: "read",
            };
        case TYPE.LEFT_PANEL_HIDE:
            return {
                ...storeProp,
                leftPanel: null,
            };
        case TYPE.FETCH_MORE_SUCCESS:
            return {
                ...storeProp,
                list: [...storeProp.list, ...action.list],
                currentPage: action.currentPage,
                totalPages: action.totalPages,
                isFetching: false,
            };
        case TYPE.LIST_FILTERS:
            return {
                ...storeProp,
                listFilters: action.filters,
            };
        case TYPE.COMMUNICATION_TRAYS_LIST_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                communicationTraysList: action.communicationTraysList,
            };
        case TYPE.IS_FETCHING_ADD_FECHT:
            return {
                ...storeProp,
                isFetchingAdd: true,
            };
        case TYPE.IS_FETCHING_ADD_SUCCES:
            return {
                ...storeProp,
                isFetchingAdd: false,
            };
        case TYPE.SET_ACTIVE_TRAY:
            return {
                ...storeProp,
                activeTray: action.activeTray,
            };
        case TYPE.MARK_LIST_AS_READ_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                unreadCommunications: 0,
                list: action.nextList,
            };
        default:
            return {
                ...storeProp,
            };
    }
};
