import { useState, useRef, useEffect } from "react";

function useOpenElement(initialState) {
    const [isOpen, setOpen] = useState(initialState);
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [isOpen]);

    return { ref, isOpen, setOpen };
}

export default useOpenElement;
