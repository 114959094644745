import React from "react";

import classnames from "classnames";
import queryString from "query-string";
import { connect as Connect } from "react-redux";

import { ALL, ENVIRONMENT_TYPE } from "~/constants";
import { ID_ACTIVITY, ID_FORM } from "~/constants/form";
import { DEFAULT_STATUS, STATUS } from "~/constants/transaction";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import {
    SelectorsAction as SelectorsActionTransactions,
    SelectorsStore as SelectorsStoreTransactions,
} from "~/store/transactions";
import * as UtilsConfig from "~/util/config";
import * as UtilsDate from "~/util/date";
import { formatDate as FormatDate } from "~/util/date";
import { generateId as GenerateId } from "~/util/general";

import GridLayout from "~/components/GridLayout";
import I18n from "~/components/I18n";
import Image from "~/components/Image";

import TransactionItem from "~/pages/transactions/_components/TransactionItem";

export const NAME = "List";

export const PROP = {
    types: {},
    defaults: {},
};

const CAMPAIGN_PARAM = "campaignParam";

const DATE_FROM_DEFAULT_BACK = UtilsConfig.getInteger("client.transactions.filters.dateFrom.yearsBack.default", 1);

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {
        defaultFilters: null,
    };

    componentDidMount() {
        const { currentFilter, dispatch, environmentType, filters, isTicketSelected, location } = this.props;

        if (!isTicketSelected) {
            if (filters === null) {
                let dateFrom = UtilsDate.substractYears(UtilsDate.setTime(), DATE_FROM_DEFAULT_BACK);
                dateFrom = FormatDate(dateFrom);

                const dateTo = FormatDate(new Date());

                const defaultFilters = {
                    dateFrom,
                    dateTo,
                    filter: "last",
                    pageNumber: 1,
                    status: environmentType !== ENVIRONMENT_TYPE.CORPORATE ? ALL : currentFilter || DEFAULT_STATUS,
                };

                if (location.search) {
                    const queryObject = queryString.parse(location.search);
                    const campaignParam = queryObject[CAMPAIGN_PARAM];

                    const idForm = Object.entries(ID_FORM).find((item) => item[1] === campaignParam);

                    defaultFilters.transaction = ID_ACTIVITY[idForm[0]];
                }

                this.setState({ defaultFilters });

                dispatch(SelectorsActionTransactions.loadListRequest({ ...defaultFilters }));
            } else if (filters.status !== currentFilter) {
                let { dateFrom, dateTo } = filters;

                dateFrom = dateFrom ? FormatDate(dateFrom) : null;
                dateTo = dateTo ? FormatDate(dateTo) : null;

                dispatch(
                    SelectorsActionTransactions.loadListRequest({
                        ...filters,
                        dateFrom,
                        dateTo,
                        status: environmentType !== ENVIRONMENT_TYPE.CORPORATE ? ALL : currentFilter,
                    }),
                );
            } else {
                let { dateFrom, dateTo } = filters;

                dateFrom = dateFrom ? FormatDate(dateFrom) : null;
                dateTo = dateTo ? FormatDate(dateTo) : null;

                dispatch(SelectorsActionTransactions.loadListRequest({ ...filters, dateFrom, dateTo }));
            }
        }
    }

    componentDidUpdate(prevProps) {
        const pendingTransactions = [];
        const { allPendingSelected, currentFilter, isMobile, handleCheckTransactionList, hasMoreData, transactions } =
            this.props;

        if (allPendingSelected !== prevProps.allPendingSelected || transactions !== prevProps.transactions) {
            if (allPendingSelected) {
                if (hasMoreData) {
                    this.fetchAllTransactions();
                }

                transactions.forEach((transaction) => {
                    pendingTransactions.push(transaction.transaction.idTransaction);
                });

                handleCheckTransactionList(pendingTransactions);
            }
        }

        if (prevProps.currentFilter !== currentFilter) {
            const list = document.getElementById("transactionsList");

            if (list) {
                list.parentNode.scrollTo(0, 0);
            }

            if (!isMobile) {
                window.scrollTo(0, 0);
            }
        }
    }

    fetchAllTransactions = () => {
        const { currentFilter, dispatch, environmentType } = this.props;
        let { defaultFilters } = this.state;
        let { filters } = this.props;

        // It is fixed this way because, according to what filter is selected (in addition to going back and forth),
        // "filters" gets null and "defaultFilters" is never assigned a value to,
        // producing the collateral effect of selecting all the transactions instead of pending ones,
        // since "status" in the dispatch below has never got a value.
        if (!defaultFilters) {
            let dateFrom = UtilsDate.substractYears(UtilsDate.setTime(), DATE_FROM_DEFAULT_BACK);
            dateFrom = FormatDate(dateFrom);

            const dateTo = FormatDate(new Date());

            defaultFilters = {
                dateFrom,
                dateTo,
                filter: "last",
                pageNumber: 1,
                status: environmentType !== ENVIRONMENT_TYPE.CORPORATE ? ALL : currentFilter || DEFAULT_STATUS,
            };
        }

        filters = filters ? { ...filters } : { ...defaultFilters };

        const pageNumber = 0;
        const { channel, dateFrom, dateTo, status } = filters;

        dispatch(
            SelectorsActionTransactions.loadListRequest({
                channel,
                dateFrom,
                dateTo,
                pageNumber,
                status,
            }),
        );
    };

    fetchMoreTransactions = () => {
        const { dispatch, pageNumber } = this.props;
        const { defaultFilters } = this.state;
        let { filters } = this.props;

        filters = filters ? { ...filters, pageNumber } : { ...defaultFilters, pageNumber };

        const { dateFrom, dateTo } = filters;
        const { channel, maxAmount, minAmount, status, transaction } = filters;

        dispatch(
            SelectorsActionTransactions.loadMoreTransactionsRequest({
                channel,
                dateFrom,
                dateTo,
                maxAmount,
                minAmount,
                pageNumber,
                status,
                transaction,
            }),
        );
    };

    getTransactions = () => {
        const { idTransactionList, isMobile, transactions } = this.props;
        const transactionsComponents = [];

        transactions.forEach((transaction) => {
            const approve = idTransactionList.indexOf(transaction.transaction.idTransaction) !== -1;

            transactionsComponents.push(
                <TransactionItem
                    approve={approve}
                    isMobile={isMobile}
                    key={GenerateId()}
                    onClickApproval={this.handleCheckTransaction}
                    transaction={transaction}
                />,
            );
        });

        return transactionsComponents;
    };

    onPullForMoreInfo = () => {
        const { dispatch, currentFilter } = this.props;
        const { defaultFilters } = this.state;
        const filters = { ...defaultFilters, status: currentFilter };

        dispatch(SelectorsActionTransactions.loadListRequest({ ...filters }));
    };

    handleCheckTransaction = (idTransaction) => {
        const {
            allPendingSelected,
            handleCheckChange,
            handleCheckTransactionList,
            hasMoreData,
            idTransactionList,
            transactions,
        } = this.props;

        let idApprovalsArray;

        if (idTransactionList.indexOf(idTransaction) === -1) {
            idApprovalsArray = [...idTransactionList, idTransaction];

            if (transactions.length === idApprovalsArray.length && !hasMoreData) {
                handleCheckChange();
            }
        } else {
            idApprovalsArray = idTransactionList.filter((item) => item !== idTransaction);

            if (allPendingSelected) {
                handleCheckChange();
            }
        }

        handleCheckTransactionList(idApprovalsArray);
    };

    handleTransactionsPreview = (approve) => {
        const { dispatch, idTransactionList } = this.props;

        if (approve) {
            dispatch(SelectorsActionTransactions.approveTransactionsPreviewRequest({ idTransactionList }));
        } else {
            dispatch(SelectorsActionTransactions.cancelTransactionsPreviewRequest({ idTransactionList }));
        }
    };

    handleTracking = () => {
        const { productGroupId } = this.props;
        const availableEnvironments = UtilsConfig.getArray("client.gpi.tracking.enabled.environments");

        return availableEnvironments.length === 0 || availableEnvironments.includes(productGroupId);
    };

    render() {
        const { currentFilter, hasMoreData, idTransactionList, isMobile, moreFilters, transactions } = this.props;

        const showApprovalButtons =
            idTransactionList.length > 0 &&
            (currentFilter === DEFAULT_STATUS || currentFilter === STATUS.PENDING || currentFilter === ALL);

        if (!transactions) {
            return null;
        }

        if (transactions.length === 0) {
            return (
                <span className="no-more-movements">
                    <I18n id="transactions.list.none" />
                </span>
            );
        }

        return (
            <div
                className={classnames(
                    `filter-list filter-${currentFilter}`,
                    { "items-selected": isMobile && showApprovalButtons },
                    { "more-filters-open": isMobile && moreFilters },
                    { "both-opened": isMobile && showApprovalButtons && moreFilters },
                )}>
                {(
                    <div id="transactionsList" className="containerDetails container-fluid container-transaction-list">
                        {!isMobile && (
                            <GridLayout className={this.handleTracking() ? "header tracking" : "header"}>
                                <I18n id="gnb.transactions.history.transaction" />
                                <I18n id="gnb.transactions.history.startDate" />
                                <I18n id="gnb.transactions.history.amount" className="align-right" />
                                <I18n id="gnb.transactions.history.status" />
                                {this.handleTracking() && (
                                    <I18n id="client.transactions.history.tracking" className="align-center" />
                                )}
                            </GridLayout>
                        )}
                        {this.getTransactions()}
                        {hasMoreData && (
                            <div className="see-more" onClick={() => this.fetchMoreTransactions()}>
                                <I18n id="gnb.transactions.history.more" />
                                <Image src="form-arrow-down.svg" />
                            </div>
                        )}
                    </div>
                ) || <I18n id="transactions.list.none" />}
            </div>
        );
    }
}

const mapStateToProps = (store) => ({
    environmentType: SelectorsStoreSession.getActiveEnvironmentType(store),
    errors: SelectorsStoreTransactions.getErrors(store),
    fetching: SelectorsStoreTransactions.getFetching(store),
    filters: SelectorsStoreTransactions.getFilters(store),
    hasMoreData: SelectorsStoreTransactions.getHasMoreData(store),
    isTicketSelected: SelectorsStoreTransactions.isTicketSelected(store),
    pageNumber: SelectorsStoreTransactions.getPageNumber(store),
    productGroupId: SelectorsStoreSession.getProductGroupId(store),
    totalTransactions: SelectorsStoreTransactions.getTotalTransactions(store),
    transactions: SelectorsStoreTransactions.getTransactions(store),
});

export default Connect(mapStateToProps)(Component);
