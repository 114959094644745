import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import { STATUS_FINAL, STATUS } from "~/constants/transaction";
import Container from "~/containers/Internal/Form/Steps";
import {
    SelectorsAction as SelectorsActionTicket,
    SelectorsStore as SelectorsStoreTicket,
} from "~/store/administration/common/administrationTicket";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import DeviceUtils from "~/util/device";

import Button from "~/components/Button";
import I18n from "~/components/I18n";
import TicketPrintInformation from "~/pages/_components/TicketPrintInformation";

import FormSignatures from "~/pages/forms/_components/FormSignatures";
import TransactionStatus from "~/pages/transactions/_components/TransactionStatus";

import FormPreview from "./Step2Preview/Step2Preview";
import Style from "./Ticket.rules.scss";

export const { ID } = Style;

export const NAME = "AdministrationTicket";

export const PROP = {
    types: {
        children: PropTypes.node.isRequired,
        dispatch: PropTypes.func.isRequired,
        externalFetching: PropTypes.bool,
        fetching: PropTypes.bool.isRequired,
        hideSteps: PropTypes.bool,
        history: PropTypes.shape({
            goBack: PropTypes.func,
        }).isRequired,
        isChangingEnvironment: PropTypes.bool,
        match: PropTypes.shape({
            params: PropTypes.shape({ id: PropTypes.string.isRequired }),
            url: PropTypes.string.isRequired,
        }).isRequired,
        transactionData: PropTypes.shape({
            data: PropTypes.objectOf(PropTypes.string),
            idActivity: PropTypes.string,
            idTransaction: PropTypes.string,
        }).isRequired,
    },
    defaults: {
        hideSteps: false,
        isChangingEnvironment: false,
    },
};

export function Component(props) {
    const {
        children,
        dispatch,
        externalFetching,
        fetching,
        hideSteps,
        isChangingEnvironment,
        match,
        mode,
        transactionData,
    } = props;
    const { idTransaction } = match.params;

    React.useLayoutEffect(() => {
        if (!isChangingEnvironment && idTransaction) {
            dispatch(SelectorsActionTicket.loadAdministrationTicketRequest({ idTransaction }));
        }
    }, [dispatch, idTransaction, isChangingEnvironment, transactionData.idTransaction]);

    function handleSign() {
        dispatch(SelectorsActionTicket.signAdministrativeTransactionPreview({ transaction: transactionData }));
    }

    function handleReject() {
        const { idTransaction: idTransactionData } = transactionData;

        dispatch(
            SelectorsActionTicket.cancelAdministrativeTransaction({
                idTransaction: idTransactionData,
            }),
        );
    }

    const handleClose = () => {
        const { history } = props;

        history.goBack();
    };

    const getViewButtons = () => {
        if (transactionData) {
            const { idTransactionStatus, signatures } = transactionData;
            const { userId } = props;
            const userHasSigned = signatures?.some((signature) => signature.idUser === userId);

            if (idTransactionStatus === STATUS.PENDING || idTransactionStatus === STATUS.SCHEDULED) {
                return (
                    <div className="transactionTicketConfirmation">
                        {idTransactionStatus === STATUS.PENDING && !userHasSigned && (
                            <div className="buttonsTicketConfirmation">
                                <Button
                                    label="forms.signTransaction.link"
                                    bsStyle="primary"
                                    loading={false}
                                    onClick={handleSign}
                                />
                            </div>
                        )}

                        <div className="buttonsTicketConfirmation">
                            <Button
                                label="forms.cancelTransaction.link"
                                bsStyle="secondary"
                                loading={false}
                                onClick={handleReject}
                            />
                        </div>
                    </div>
                );
            }
        }

        return null;
    };

    const viewButtons = getViewButtons();

    const { idTransaction: idTrans, idTransactionStatus, valueDateAsString } = transactionData;

    const isFinal = STATUS_FINAL.includes(idTransactionStatus);

    return (
        <Container
            name={NAME}
            wait={fetching || externalFetching}
            step={mode}
            head-title={transactionData.activityName}
            hideSteps={hideSteps}
            head-onBack={handleClose}
            scopeToShowNotification={SCOPE.ADMINISTRATION}>
            {mode === MODE.PREVIEW && <FormPreview {...props} />}
            {(mode === MODE.EDIT || mode === MODE.VIEW) && (
                <div id={Style.ID}>
                    <section>
                        <div className="justify-content-center">
                            <TicketPrintInformation />
                            <figure>
                                <TransactionStatus iconFidelity="ticket" idTransactionStatus={idTransactionStatus} />
                            </figure>
                            <div className="media-object--text">
                                <p className="text-lead">
                                    <I18n id={`forms.transaction.ticket.status.${idTransactionStatus}`} />
                                </p>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div>
                            <h3 className="data-title">
                                <I18n id="forms.transaction.ticket" />
                            </h3>
                            <div>
                                <div className="data-wrapper">
                                    <div className="data-label">
                                        <I18n id="forms.transaction.ticket.idTransaction" />
                                    </div>
                                    <span className="data-numeric">{idTrans}</span>
                                </div>
                            </div>
                            <div className="data-wrapper">
                                <div className="data-label">
                                    <I18n id="forms.transaction.ticket.date" />
                                </div>
                                <span className="data-date">{valueDateAsString}</span>
                            </div>
                        </div>
                    </section>
                    {children}
                    <section>
                        <FormSignatures transaction={transactionData} />

                        {!DeviceUtils.isMobileNative() && isFinal && (
                            <div>
                                <div className="data-wrapper">
                                    <div className="buttonsTicketConfirmation">
                                        <a onClick={() => printPage()}>
                                            <I18n id="forms.ticket.print" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )}
                    </section>

                    <section>
                        <div>
                            <div>{viewButtons}</div>
                        </div>
                    </section>
                </div>
            )}
        </Container>
    );
}

function printPage() {
    window.print();
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    fetching: SelectorsStoreTicket.isFetching(store),
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    mode: SelectorsStoreTicket.getMode(store),
    transactionData: SelectorsStoreTicket.getData(store),
    userId: SelectorsStoreSession.getUserId(store),
});

export default Connect(mapStateToProps)(Component);
