import React from "react";

import PropTypes from "prop-types";

import { generateId as GenerateID } from "~/util/general";

export const NAME = "PasswordRules";

export const PROP = {
    types: { children: PropTypes.arrayOf(PropTypes.element) },
    defaults: { children: null },
};

export function Component(props) {
    const { children } = props;
    return (
        <ul>
            {React.Children.map(children, (child) => (
                <li key={GenerateID()}>{child}</li>
            ))}
        </ul>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
