import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { MODE } from "~/constants/form";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "factoringAdvancePayments";

export const PATH = {
    ADVANCE_PAYMENTS_LIST: "/client.factoring.advancePayments.list",
    ADVANCE_PAYMENTS_PREVIEW: "/client.factoring.advancePayments.preview",
    ADVANCE_PAYMENTS_SEND: "/client.factoring.advancePayments.send",
};

export const TYPE = FactoryType(NAME, [
    "ADVANCE_PAYMENTS_TRANSACTION_PREVIEW_REQUEST",
    "ADVANCE_PAYMENTS_TRANSACTION_PREVIEW_FAILURE",
    "ADVANCE_PAYMENTS_TRANSACTION_PREVIEW_SUCCESS",
    "ADVANCE_PAYMENTS_TRANSACTION_SEND_REQUEST",
    "ADVANCE_PAYMENTS_TRANSACTION_SEND_FAILURE",
    "ADVANCE_PAYMENTS_TRANSACTION_SEND_SUCCESS",
    "CANCEL_CUSTOM_TRANSACTION_REQUEST",
    "CHANGE_TO_EDIT_MODE",
    "CLEAN_ORDERS_TO_DISCOUNT",
    "LIST_ADVANCE_PAYMENTS_REQUEST",
    "LIST_ADVANCE_PAYMENTS_FAILURE",
    "LIST_ADVANCE_PAYMENTS_SUCCESS",
    "PREPARE_ADVANCE_PAYMENTS_LIST",
    "READ_TRANSACTION_REQUEST",
    "READ_TRANSACTION_FAILURE",
    "READ_TRANSACTION_SUCCESS",
    "RESET_FILTERS",
    "RESTART_FLOW",
    "SIGN_TRANSACTION_REQUEST",
    "SIGN_TRANSACTION_PREVIEW_REQUEST",
    "SIGN_TRANSACTION_PREVIEW_SUCCESS",
]);

export const PROP = {};

PROP.members = {
    filtersAdvancePaymentsList: {
        status: PropTypes.string,
        currency: PropTypes.number,
        paymentOrder: PropTypes.string,
        companyName: PropTypes.string,
    },
    totalDiscountableOrders: {
        amount: PropTypes.number,
        total: PropTypes.number,
    },
};

PROP.members.defaults = {
    totalDiscountableOrders: {
        amount: 0,
        total: 0,
    },
};

PROP.types = {
    list: PropTypes.array,
    ordersToDiscount: PropTypes.array,
    totalAdvancePaymentAmountUSD: PropTypes.number,
    totalAdvancePaymentAmountUYU: PropTypes.number,
    totalAdvancePaymentBalanceAmountUSD: PropTypes.number,
    totalAdvancePaymentBalanceAmountUYU: PropTypes.number,
    totalDiscountableUYU: PropTypes.shape(PROP.members.totalDiscountableOrders),
    totalDiscountableUSD: PropTypes.shape(PROP.members.totalDiscountableOrders),
    currentDiscountableUYU: PropTypes.shape(PROP.members.totalDiscountableOrders),
    currentDiscountableUSD: PropTypes.shape(PROP.members.totalDiscountableOrders),
    isFetching: PropTypes.bool,
    offset: PropTypes.number,
    pageNumber: PropTypes.number,
    moreLines: PropTypes.bool,
    moreOrders: PropTypes.bool,
    filtersAdvancePaymentsList: PropTypes.shape(PROP.members.filtersAdvancePaymentsList),
    mode: PropTypes.string,
    transaction: PropTypes.object,
    ...TypesRedux,
};

PROP.defaults = {
    list: [],
    ordersToDiscount: [],
    totalAdvancePaymentBalanceAmountUSD: 0,
    totalAdvancePaymentBalanceAmountUYU: 0,
    totalDiscountableUYU: PROP.members.defaults.totalDiscountableOrders,
    totalDiscountableUSD: PROP.members.defaults.totalDiscountableOrders,
    currentDiscountableUYU: PROP.members.defaults.totalDiscountableOrders,
    currentDiscountableUSD: PROP.members.defaults.totalDiscountableOrders,
    totalAdvancePaymentAmountUSD: 0,
    totalAdvancePaymentAmountUYU: 0,
    isFetching: false,
    moreOrders: false,
    offset: 0,
    pageNumber: 1,
    moreLines: false,
    filtersAdvancePaymentsList: {
        paymentOrder: EMPTY_STR,
        status: "PEN",
        currency: 9999,
        companyName: EMPTY_STR,
    },
    mode: MODE.EDIT,
    transaction: {
        data: { paymentList: [] },
    },
    ...DefaultsRedux,
};
