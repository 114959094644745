import React from "react";

import { connect as Connect } from "react-redux";

import {
    SelectorsAction as SelectorsActionTransactions,
    SelectorsStore as SelectorsStoreTransactions,
} from "~/store/transactionsHistory";
import { generateId as GenerateId } from "~/util/general";

import Button from "~/components/Button";
import GridLayout from "~/components/GridLayout";
import I18n from "~/components/I18n";
import Image from "~/components/Image";

import TransactionItemHistory from "~/pages/transactionsHistory/_components/TransactionItemHistory";

export const NAME = "List";

export const PROP = {
    types: {},
    defaults: {},
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(SelectorsActionTransactions.loadListRequest());
    }

    componentDidUpdate(prevProps) {
        const pendingTransactions = [];
        const { allPendingSelected, transactions, hasMoreData, handleCheckTransactionList } = this.props;
        if (allPendingSelected !== prevProps.allPendingSelected || transactions !== prevProps.transactions) {
            if (allPendingSelected) {
                if (hasMoreData) {
                    this.fetchAllTransactions();
                }
                transactions.forEach((transaction) => {
                    pendingTransactions.push(transaction.transaction.idTransaction);
                });
                handleCheckTransactionList(pendingTransactions);
            }
        }
    }

    fetchAllTransactions = () => {
        const { dispatch } = this.props;
        const pageNumber = 0;
        dispatch(SelectorsActionTransactions.loadListRequest({ pageNumber }));
    };

    fetchMoreTransactions = () => {
        const { pageNumber, dispatch } = this.props;
        dispatch(SelectorsActionTransactions.loadMoreTransactionsRequest({ pageNumber }));
    };

    getTransactions = () => {
        const { transactions, isMobile, idTransactionList } = this.props;
        const transactionsComponents = [];
        transactions.forEach((transaction) => {
            const approve = idTransactionList.indexOf(transaction.transaction.idTransaction) !== -1;
            transactionsComponents.push(
                <TransactionItemHistory
                    key={GenerateId()}
                    transaction={transaction}
                    approve={approve}
                    onClickApproval={this.handleCheckTransaction}
                    isMobile={isMobile}
                />,
            );
        });

        return transactionsComponents;
    };

    onPullForMoreInfo = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionTransactions.loadListRequest());
    };

    handleCheckTransaction = (idTransaction) => {
        const {
            handleCheckChange,
            allPendingSelected,
            handleCheckTransactionList,
            transactions,
            idTransactionList,
        } = this.props;
        let idApprovalsArray;
        if (idTransactionList.indexOf(idTransaction) === -1) {
            idApprovalsArray = [...idTransactionList, idTransaction];
            if (transactions.length === idApprovalsArray.length) {
                handleCheckChange();
            }
        } else {
            idApprovalsArray = idTransactionList.filter((item) => item !== idTransaction);
            if (allPendingSelected) {
                handleCheckChange();
            }
        }
        handleCheckTransactionList(idApprovalsArray);
    };

    handleTransactionsPreview = (approve) => {
        const { dispatch, idTransactionList } = this.props;
        if (approve) {
            dispatch(SelectorsActionTransactions.approveTransactionsPreviewRequest({ idTransactionList }));
        } else {
            dispatch(SelectorsActionTransactions.cancelTransactionsPreviewRequest({ idTransactionList }));
        }
    };

    render() {
        const { transactions, hasMoreData, isMobile } = this.props;

        if (!transactions) {
            return null;
        }

        if (transactions.length === 0) {
            return (
                <span className="no-more-movements">
                    <I18n id="transactions.list.none" />
                </span>
            );
        }

        return (
            <div className="filter-list">
                {(
                    <div className="containerDetails container-fluid container-transaction-list">
                        <div className="">
                            {!isMobile && (
                                <GridLayout className="header">
                                    <I18n id="gnb.transactions.history.transaction" />
                                    <I18n id="gnb.transactions.history.startDate" />
                                    <I18n id="gnb.transactions.history.amount" className="align-right" />
                                    <I18n id="gnb.transactions.history.status" />
                                </GridLayout>
                            )}
                            {this.getTransactions()}
                            {hasMoreData && (
                                <div className="see-more" onClick={() => this.fetchMoreTransactions()}>
                                    <I18n id="gnb.transactions.history.more" />
                                    <Image src="form-arrow-down.svg" />
                                </div>
                            )}
                        </div>
                    </div>
                ) || <I18n id="transactions.list.none" />}
            </div>
        );
    }
}
const mapStateToProps = (store) => ({
    totalTransactions: SelectorsStoreTransactions.getTotalTransactions(store),
    transactions: SelectorsStoreTransactions.getTransactions(store),
    hasMoreData: SelectorsStoreTransactions.getHasMoreData(store),
    fetching: SelectorsStoreTransactions.getFetching(store),
    pageNumber: SelectorsStoreTransactions.getPageNumber(store),
    errors: SelectorsStoreTransactions.getErrors(store),
});
export default Connect(mapStateToProps)(Component);
