import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import { SelectorsStore as SelectorsStoreComexImport } from "~/store/comex/import";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import * as UtilsI18n from "~/util/i18n";
import GridLayout from "~/components/GridLayout";

import Box from "~/components/Box";
import I18n from "~/components/I18n";
import Ticket from "~/components/Ticket/Ticket";
import FormattedAmount from "~/pages/_components/FormattedAmount";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const { transaction, ticketConfirmation, dispatch } = props;
    const { data } = transaction && transaction;

    const {
        cancellationType,
        operationNumber,
        oldAmount,
        utilizationAmount,
        utilizationAmountCurrency,
        invoiceNumber,
        scheduler,
        documents,
        discrepancies
    } = data;

    const shareTicket = (idTransactionToRead) => {
        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    };

    const COLUMN_HEADINGS = [
        UtilsI18n.get("comex.imports.creditLetter.form.columnheaders.document"),
        UtilsI18n.get("comex.imports.creditLetter.form.columnheaders.description"),
        UtilsI18n.get("comex.imports.creditLetter.form.columnheaders.original"),
        UtilsI18n.get("comex.imports.creditLetter.form.columnheaders.copies"),
    ];

    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}
                    handleModify={handleModify}
                    displayDownloadPDF
                    handleDownloadPDF={() => shareTicket(transaction.idTransaction)}
                    hasMobileComponent>
                    <Box flex directionColumn align="flex-start" className="mb-3">
                        <div>
                            <I18n id="client.import.billingCancellation.step2.opening"
                                tag="p"
                                className="mt-3"
                            />
                        </div>

                        <Box flex className="info-label">
                            <h4 className="bold">{UtilsI18n.get("client.import.billingCancellation.step2.operationNumber")}</h4>
                            <span>{operationNumber}</span>
                        </Box>
                        <Box flex className="info-label">
                            <h4 className="bold">{UtilsI18n.get("client.import.billingCancellation.step2.amount")}</h4>
                            <FormattedAmount currency={utilizationAmountCurrency} quantity={parseFloat(oldAmount)} />
                        </Box>
                        <Box flex className="info-label">
                            <h4 className="bold">
                                {UtilsI18n.get("comex.import.presentationLDDomesticCredit.step2.invoiceNumber")}</h4>
                            <span>{invoiceNumber}</span>
                        </Box>
                        <Box flex className="info-label">
                            <h4 className="bold">{UtilsI18n.get("comex.import.presentationLDDomesticCredit.step2.utilizationAmount")}</h4>
                            <FormattedAmount currency={utilizationAmountCurrency} quantity={parseFloat(utilizationAmount)} />
                        </Box>

                        <div className="info-label">
                            <I18n
                                id="comex.import.presentationLDDomesticCredit.step2.closing"
                                tag="p"
                                className="mt-3"
                            />
                        </div>

                        <GridLayout className="header ticket-table-section">
                            {COLUMN_HEADINGS.map((heading) => {
                                return <div>{heading}</div>;
                            })}
                        </GridLayout>
                        {documents &&
                            documents.map((document) => (
                                <GridLayout className="item ticket-table-section">
                                    <div>
                                        {UtilsI18n.get(`comex.creditLetter.modal.documentTypes.${document.type}`)}
                                    </div>
                                    <div>{document.description}</div>
                                    <div>{document.originals}</div>
                                    <div>{document.copies}</div>
                                </GridLayout>
                            ))}

                        {discrepancies &&
                            <div className="info-label">
                                <I18n
                                    id="comex.import.presentationLDDomesticCredit.step2.discrepancies"
                                    tag="p"
                                    className="mt-3"
                                />
                            </div>
                        }

                    </Box>
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { dispatch } = props;
        const { idTransaction } = transaction;
        dispatch(
            SelectorsActionComex.cancelTransactionCustom({
                credentials: { otp: "" },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        const { dispatch } = props;
        dispatch(SelectorsActionComex.signTransactionPreview({ transaction }));
    }
   
    function handleModify() {
        const { idTransaction } = transaction;
        dispatch(SelectorsActionForm.modifyTransaction({ idTransactionToMove: idTransaction }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreComexImport.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
