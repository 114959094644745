import React from "react";

import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { ALL, EMPTY_STR } from "~/constants";
import {
    SelectorsAction as SelectorsActionCreditCards,
    SelectorsStore as SelectorsStoreCreditCards,
} from "~/store/creditCards/creditCard";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as UtilsI18n from "~/util/i18n";

import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Token from "~/components/Token/Token";
import TicketPrintInformation from "~/pages/_components/TicketPrintInformation";

import CardsNotificationsList from "~/pages/creditCards/purchaseNotification/_components/_cardsNotificationsList";

import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        isSubmitting: PropTypes.bool,
    },
    defaults: {
        isSubmitting: false,
    },
};

export function Component(props) {
    const { isSubmitting, purchaseData, transaction } = props;
    const { cardAccountsNotificationsUpdated, selectedCardAccount } = purchaseData || transaction.data;

    let cardAccountListToWorkWith = [];

    if (selectedCardAccount === ALL) {
        cardAccountListToWorkWith = cardAccountsNotificationsUpdated;
    } else {
        const cardAccountToWorkWith = cardAccountsNotificationsUpdated.find(
            (cardAccount) => cardAccount.visaMasterAccount === selectedCardAccount,
        );

        cardAccountListToWorkWith.push(cardAccountToWorkWith);
    }

    return (
        <Form id={Style.ID}>
            <TicketPrintInformation />
            <React.Fragment>
                <section className="justify-content-center">
                    <I18n className="text-lead" id="forms.transferInternal.confirmation.title" />
                    <div className="notice-block">
                        <div className="notice-data notice-data-flow">
                            <div>
                                <div>
                                    <span className="data-label">
                                        <I18n
                                            className="data-label"
                                            id="creditCards.purchaseNotification.step2.selectedCards"
                                        />
                                    </span>
                                </div>
                                <div className="container-cardList data-flex-column">
                                    <CardsNotificationsList
                                        cardAccountsNotificationsUpdated={cardAccountListToWorkWith}
                                        selectedCardAccount={selectedCardAccount}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Token labelButton="global.send" isSubmitting={isSubmitting} />
            </React.Fragment>
        </Form>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        purchaseData: SelectorsStoreCreditCards.getPurchaseNotificationFormData(store),
        transaction: SelectorsStoreCreditCards.getTransaction(store),
        user: SelectorsStoreSession.getUser(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithRouter,
    WithFormik({
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        validationSchema: (props) => {
            const { user } = props;

            return Yup.object().shape({
                otp: user.needsBiometric
                    ? Yup.string().nullable()
                    : Yup.string().required(UtilsI18n.get("form.credential.otp.required")),
            });
        },
        handleSubmit: (props, formikBag) => {
            const { dispatch, history, purchaseData, transaction } = formikBag.props;
            const { idActivity, idTransaction } = transaction || EMPTY_STR;
            const isFromTransactionsHistory = history.location.pathname.includes("/ticket");
            const { otp } = props;

            if (isFromTransactionsHistory) {
                dispatch(
                    SelectorsActionCreditCards.signTransaction({
                        idForm: null,
                        idActivity,
                        idTransaction,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            } else {
                const { cardAccountsNotificationsUpdated, notifications, selectedCardAccount } = purchaseData;

                const idCreditCard =
                    ALL === selectedCardAccount
                        ? cardAccountsNotificationsUpdated[0].idProduct
                        : cardAccountsNotificationsUpdated.find(
                              (cardAccount) => selectedCardAccount === cardAccount.visaMasterAccount,
                          ).idProduct;

                const data = {
                    accountVisaMaster: selectedCardAccount,
                    cardAccountsNotificationsUpdated,
                    idCreditCard,
                    notifications,
                    selectedCardAccount,
                };

                dispatch(
                    SelectorsActionCreditCards.purchaseNotificationSendRequest({
                        formData: data,
                        otp,
                        formikBag,
                    }),
                );
            }
        },
    }),
)(Component);
