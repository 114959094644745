import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "mapLocations";

export const PATH = {
    LOCATIONS: "/map.locations",
};

export const TYPE = FactoryType(NAME, ["LOCATIONS_LIST_REQUEST", "LOCATIONS_LIST_SUCCESS"]);

export const PROP = {};

PROP.members = {
    location: {
        lat: PropTypes.number,
        long: PropTypes.number,
        type: PropTypes.string,
        name: PropTypes.string,
        address: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
    },
};

PROP.types = {
    ...TypesRedux,
    locationList: PropTypes.arrayOf(PropTypes.shape(PROP.members.location)),
    fetching: PropTypes.bool,
    searchType: PropTypes.string,
};

PROP.defaults = {
    ...DefaultsRedux,
    locationList: [],
    fetching: false,
    searchType: "1",
};
