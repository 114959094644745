import PropTypes from "prop-types";

import { FactoryType } from "~/util/redux";

export const NAME = "campaigns";

export const PATH = {
    READ_RANDOM_CAMPAIGN_BY_SECTION: "/campaigns.readRandomCampaignBySection",
    READ_CAMPAIGN_IMAGE: "/campaigns.readCampaignImage",
};

export const TYPE = FactoryType(NAME, [
    "LOAD_CAMPAIGN_REQUEST",
    "LOAD_CAMPAIGN_SUCCESS",
    "LOAD_CAMPAIGN_FAILURE",
    "LOAD_CAMPAIGN_IMAGE",
    "REMOVE_CAMPAIGNS",
]);

export const PROP = {};

PROP.types = { fetching: PropTypes.bool, items: PropTypes.array };

PROP.defaults = { fetching: false, items: [] };
