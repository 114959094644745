import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import { SelectorsStore as SelectorsStoreComexImport } from "~/store/comex/import";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import * as UtilsI18n from "~/util/i18n";
import { formatNumber } from "~/util/number";

import Box from "~/components/Box";
import I18n from "~/components/I18n";
import Ticket from "~/components/Ticket/Ticket";
import FormattedAmount from "~/pages/_components/FormattedAmount";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const { transaction, ticketConfirmation, lang, dispatch } = props;
    const { data } = transaction;
    const {
        operationNumber,
        subOperationNumber,
        name,
        authorizeDebit,
        currency,
        invoiceAmount,
        documentTypeLabel,
        documentNumber,
        beneficiary,
        productGroupId,
    } = data;
    const shareTicket = (idTransactionToRead) => {
        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    };
    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}
                    hasMobileComponent
                    displayDownloadPDF
                    handleDownloadPDF={() => shareTicket(transaction.idTransaction)}>
                    <Box flex directionColumn align="flex-start" className="mb-3">
                        <div className="opening">
                            <I18n
                                id="comex.import.liftCreditLetterWithDiscrepancies.step2.intro"
                                tag="p"
                                className="mt-3"
                            />
                        </div>
                        <div className="info-label">
                            <I18n id="comex.import.liftCreditLetterWithDiscrepancies.step2.operationNumber" />
                            <span className="bold">{`${operationNumber}.${subOperationNumber}`}</span>
                        </div>
                        <div className="info-label">
                            <I18n id="comex.import.liftCreditLetterWithDiscrepancies.step2.amount" />
                            <FormattedAmount className="bold" currency={currency} quantity={parseFloat(invoiceAmount)} />
                        </div>
                        <div className="info-label">
                            <I18n id="comex.import.liftCreditLetterWithDiscrepancies.step2.beneficiary" />
                            <span className="bold">{beneficiary}</span>
                        </div>

                        <div className="opening">
                            <I18n
                                id="comex.import.liftCreditLetterWithDiscrepancies.step2.content"
                                tag="p"
                                className="mt-3"
                            />
                        </div>
                        {authorizeDebit && (
                            <div className="info-label">
                                <I18n
                                    id="comex.import.liftCreditLetterWithDiscrepancies.step2.accountNumber"
                                    tag="p"
                                    ACCOUNT={productGroupId}
                                    AMOUNT={`${UtilsI18n.get(`currency.label.${currency}`)} ${formatNumber(
                                        invoiceAmount,
                                        lang,
                                    )}`}
                                />
                            </div>
                        )}
                        {documentNumber && name && (
                            <div className="info-label">
                                <I18n
                                    id="comex.import.liftCreditLetterWithDiscrepancies.step2.authorizedDocumentName"
                                    tag="p"
                                    NAME={name}
                                    DOCUMENT={`${documentTypeLabel} ${documentNumber}`}
                                />
                            </div>
                        )}

                        <I18n
                            id="comex.import.liftCreditLetterWithDiscrepancies.step2.ending"
                            tag="p"
                            className="mt-3 mb-3"
                        />
                    </Box>
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { idTransaction } = transaction;
        dispatch(
            SelectorsActionComex.cancelTransaction({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        dispatch(SelectorsActionComex.signTransactionPreview({ ...data }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreComexImport.getTransaction(store),
        lang: StoreI18n.getLang(store) || window.navigator.language.slice(0, 2),
    };
};

export default Connect(mapStateToProps)(Component);
