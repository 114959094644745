import React from "react";

import { Field, Form, Formik } from "formik";
import { Modal } from "react-bootstrap";

import * as i18nUtils from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import GridLayout from "~/components/GridLayout";
import Image from "~/components/Image";
import FieldError from "~/pages/_components/fields/FieldError";
import TextArea from "~/pages/_components/fields/TextArea";
import TextField from "~/pages/_components/fields/TextField";

import Style from "~/pages/comEx/_components/AddDocumentModal.rules.scss";

export const { NAME, NAME_MESSAGES } = Style;
export const PROP = {
    types: {},
    defaults: {},
};

const FORM_ID = "comex.creditLetter.modal.documentTypes";

export function Component({ show, handleCloseModal, documentTypeList = [], submit, document }) {
    const [state, setState] = React.useState({
        step: 1,
        title: "Seleccione el documento a agregar",
        otherFields: false,
        transportFields: false,
        commonFields: false,
        insuranceFields: false,
    });

    const handleCheckClick = (type, setFieldValue) => {
        const otherFields = false;
        const transportFields = false;
        const commonFields = true;
        const insuranceFields = false;

        setState({
            type,
            step: 2,
            otherFields,
            transportFields,
            commonFields,
            insuranceFields,
        });
    };

    if (Object.entries(document).length > 0 && state.step === 1) {
        handleCheckClick(document.type, null);
    }

    const reset = () => {
        setState({
            type: "",
            step: 1,
            otherFields: false,
            transportFields: false,
            commonFields: false,
            insuranceFields: false,
        });
    };

    const closeModal = () => {
        handleCloseModal();
        setTimeout(reset, 500);
    };

    const getDescription = (doc) => {
        return doc.description;
    };

    const onSubmit = (values) => {
        const { type } = state;
        const description = getDescription({ ...values, type });
        submit({ ...values, type, description });
        closeModal();
    };

    const { step, type, otherFields, insuranceFields } = state;
    const documentList = [...documentTypeList];

    const isEmptyDocument = Object.entries(document).length > 0 ? `${FORM_ID}.${document.type}` : `${FORM_ID}.${type}`;

    return (
        <Modal show={show}>
            <Modal.Body>
                <Formik
                    enableReinitialize
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={{
                        name: document.name || "",
                        description: document.description || "",
                        originals: document.originals || "",
                        copies: document.copies || "",
                        issuedTo: document.issuedTo || "",
                        notifyTo: document.notifyTo || "",
                        percentage: document.percentage || "",
                        daysInCustoms: document.daysInCustoms || "",
                    }}
                    onSubmit={onSubmit}
                    validate={(field) => {
                        const { copies, originals } = field;
                        const errors = {};

                        if (!parseInt(originals, 10) && !parseInt(copies, 10)) {
                            errors.originals = i18nUtils.get(`${FORM_ID}.oneField.required`);
                            errors.copies = i18nUtils.get(`${FORM_ID}.oneField.required`);
                        }

                        return errors;
                    }}>
                    {({ setFieldValue, values, errors, isSubmitting }) => (
                        <Form id={Style.ID} noValidate>
                            <div className="component-modal">
                                <div className="closed clickable" onClick={closeModal} role="button">
                                    <Image src="close.svg" />
                                </div>
                                <h2 className="title">
                                    {i18nUtils.get(step === 1 ? `${FORM_ID}.title` : isEmptyDocument)}
                                </h2>
                                {step === 1 &&
                                    documentList.map((t) => (
                                        <div
                                            onClick={() => handleCheckClick(t, setFieldValue)}
                                            className="documentTypeRow"
                                            key={`documentType-row-${t}`}>
                                            <Box>{i18nUtils.get(`comex.creditLetter.modal.documentTypes.${t}`)}</Box>
                                        </div>
                                    ))}

                                {step === 2 && (
                                    <React.Fragment>
                                        {otherFields && (
                                            <GridLayout className="position-clickable" flexDiretion="row">
                                                <div className="vertical-direction">
                                                    <Field
                                                        idForm={FORM_ID}
                                                        name="name"
                                                        maxLength={100}
                                                        type="text"
                                                        placeholder=""
                                                        component={TextField}
                                                    />
                                                </div>
                                            </GridLayout>
                                        )}

                                        <div className="vertical-direction">
                                            <Field
                                                idForm={FORM_ID}
                                                name="description"
                                                type="text"
                                                maxLength={160}
                                                placeholder=""
                                                component={TextArea}
                                            />
                                        </div>

                                        <div className="vertical-direction amount">
                                            <Field
                                                component={TextField}
                                                autoComplete="off"
                                                pattern="[0-9]{0,2}"
                                                type="tel"
                                                idForm={FORM_ID}
                                                name="originals"
                                                placeholder=""
                                                hideError
                                            />
                                        </div>
                                        <div className="vertical-direction amount">
                                            <Field
                                                component={TextField}
                                                autoComplete="off"
                                                pattern="[0-9]{0,2}"
                                                type="tel"
                                                idForm={FORM_ID}
                                                name="copies"
                                                placeholder=""
                                                hideError
                                            />
                                        </div>
                                        <div
                                            className={`form-group ${
                                                errors.copies || errors.originals ? "has-error" : ""
                                            }`}>
                                            <FieldError error={errors.copies || errors.originals} />
                                        </div>
                                        <div className="container-button-center">
                                            <Button
                                                key="primaryButton"
                                                variant="secondary"
                                                bsStyle="secondary"
                                                label="global.cancel"
                                                loading={isSubmitting}
                                                type="button"
                                                onClick={closeModal}
                                            />
                                            <Button
                                                idForm={FORM_ID}
                                                key="primaryButton"
                                                variant="primary"
                                                bsStyle="primary"
                                                label="comex.imports.creditLetter.form.attachments.add"
                                                loading={isSubmitting}
                                                type="submit"
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
}

export default Component;
