import React from "react";

import { Modal } from "react-bootstrap";

import Button from "~/components/Button";
import Image from "~/components/Image";
import * as i18n from "~/util/i18n";

import Style from "./ConfirmModal.rules.scss";

export const { NAME, NAME_MESSAGES } = Style;
export const PROP = {
    types: {},
    defaults: {},
};

export class Component extends React.Component {
    handleAccept = () => {
        const { onSubmit } = this.props;
        onSubmit();
    };

    render() {
        const { show, handleCloseModal } = this.props;
        return (
            <Modal show={show} id={Style.ID}>
                <Modal.Body>
                    <div className="component-modal">
                        <div className="closed clickable" onClick={handleCloseModal} role="button">
                            <Image src="close.svg" />
                        </div>
                        <h2>{i18n.get("form.confirmModal.external.link.title")}</h2>
                        <p className="description">{i18n.get("form.confirmModal.external.link.text1")}</p>
                        <p className="confirmation">{i18n.get("form.confirmModal.external.link.text2")}</p>
                        <div className="button-container">
                            <Button
                                label="global.accept"
                                bsStyle="primary"
                                loading={false}
                                onClick={this.handleAccept}
                            />
                            <Button label="global.cancel" loading={false} onClick={handleCloseModal} />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
export default Component;
