import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE } from "~/constants";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction } from "./_selectors";

export default [
    TakeLatest(TYPE.BACKOFFICE_TRANSACTION_REQUEST, readBackofficeTransaction),
    TakeLatest(TYPE.READ_ENVIRONMENT_DATA_REQUEST, readEnvironmentData),
    TakeLatest(TYPE.READ_USER_DATA_REQUEST, readUserData),
];

function* readBackofficeTransaction(props) {
    const { exchangeToken } = props;

    const {
        type,
        data: { message, data },
    } = yield Call(SelectorsMiddleware.readBackofficeTransaction, { _backofficeExchangeToken: exchangeToken });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.backofficeTransactionFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.BACKOFFICE_TRANSACTION],
            }),
        );
    } else {
        const { form, transaction, transactionData } = data;

        yield Put(SelectorsAction.backofficeTransactionSuccess({ form, transaction, transactionData }));
    }
}

function* readEnvironmentData(props) {
    const {
        type,
        data: { message, data },
    } = yield Call(SelectorsMiddleware.readEnvironmentData, props);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.readEnvironmentDataFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.BACKOFFICE_TRANSACTION],
            }),
        );
    } else {
        const { environmentData } = data;

        yield Put(SelectorsAction.readEnvironmentDataSuccess({ environmentData }));
    }
}

function* readUserData(props) {
    const {
        type,
        data: { message, data },
    } = yield Call(SelectorsMiddleware.readUserData, props);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.readUserDataFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.BACKOFFICE_TRANSACTION],
            }),
        );
    } else {
        const { userData } = data;

        yield Put(SelectorsAction.readUserDataSuccess({ userData }));
    }
}
