import UtilsDevice from "~/util/device";

export const getDeviceLocationVU = () => {
    if (UtilsDevice.isMobileNative()) {
        const promiseLocation = new Promise((resolve, reject) => {
            // This method waits forever if the GPS is not active, that's why the Promise.race below
            window.vuFingerprint.getDeviceLocation(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    reject(error);
                },
            );
        });

        return Promise.race([
            promiseLocation,
            new Promise((resolve) => {
                setTimeout(() => resolve({ latitude: null, longitude: null }), 500);
            }),
        ]);
    }

    // Browser
    if (navigator.geolocation) {
        return new Promise((resolve) => {
            navigator.geolocation.getCurrentPosition(
                (response) => {
                    resolve({ latitude: response.coords.latitude, longitude: response.coords.longitude });
                },
                () => {
                    resolve({ latitude: null, longitude: null });
                },
            );
        });
    }

    return undefined;
};

export const getFingerprintDeviceInfoVU = () => {
    if (UtilsDevice.isMobileNative()) {
        return new Promise((resolve) => {
            window.vuFingerprint.getFingerprintInfo(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    resolve(null);
                },
            );
        });
    }

    return undefined;
};

export const getFingerprintVU = () => {
    if (UtilsDevice.isMobileNative()) {
        return new Promise((resolve) => {
            window.vuFingerprint.getHash(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    resolve(null);
                },
            );
        });
    }

    return new Promise((resolve) => {
        window.vuFp.getFingerprintInfo((info) => {
            const result = info.fingerprint;
            resolve(result);
        });
    });
};
