import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { MODE } from "~/constants/form";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "transfers";

export const PATH = {
    ALIAS_DELETION_PREVIEW: "/transfers.alias.administration.aliasDeletionPreview",
    ALIAS_DELETION_SEND: "/transfers.alias.administration.aliasDeletionSend",
    ALIAS_ENROLLMENT_PRE: "/transfers.alias.administration.aliasEnrollmentPre",
    ALIAS_ENROLLMENT_PREVIEW: "/transfers.alias.administration.aliasEnrollmentPreview",
    ALIAS_ENROLLMENT_SEND: "/transfers.alias.administration.aliasEnrollmentSend",
    GET_ACCOUNTS_BY_ALIAS: "/transfers.getAccountsByAlias",
    LATEST_TRANSACTIONS: "/frequentTransfers.listFrequentTransfers",
    TEMPLATES: "/latest.templates.list",
};

export const TYPE = FactoryType(NAME, [
    "ALIAS_DELETION_PREVIEW_REQUEST",
    "ALIAS_DELETION_PREVIEW_FAILURE",
    "ALIAS_DELETION_PREVIEW_SUCCESS",
    "ALIAS_DELETION_SEND_REQUEST",
    "ALIAS_DELETION_SEND_FAILURE",
    "ALIAS_DELETION_SEND_SUCCESS",
    "ALIAS_ENROLLMENT_PRE_REQUEST",
    "ALIAS_ENROLLMENT_PRE_FAILURE",
    "ALIAS_ENROLLMENT_PRE_SUCCESS",
    "ALIAS_ENROLLMENT_PREVIEW_REQUEST",
    "ALIAS_ENROLLMENT_PREVIEW_FAILURE",
    "ALIAS_ENROLLMENT_PREVIEW_SUCCESS",
    "ALIAS_ENROLLMENT_RESET",
    "ALIAS_ENROLLMENT_SEND_REQUEST",
    "ALIAS_ENROLLMENT_SEND_FAILURE",
    "ALIAS_ENROLLMENT_SEND_SUCCESS",
    "CANCEL_CUSTOM_TRANSACTION_REQUEST",
    "CLEAR_ACCOUNTS_BY_ALIAS_LIST",
    "FORM_TO_STEP_1",
    "GET_ACCOUNTS_BY_ALIAS_FAILURE",
    "GET_ACCOUNTS_BY_ALIAS_REQUEST",
    "GET_ACCOUNTS_BY_ALIAS_SUCCESS",
    "LATEST_TRANSACTIONS_LIST_REQUEST",
    "LATEST_TRANSACTIONS_LIST_SUCCESS",
    "PASS_QUERIED_ACCOUNTS_BY_ALIAS",
    "READ_TRANSACTION_REQUEST",
    "READ_TRANSACTION_FAILURE",
    "READ_TRANSACTION_SUCCESS",
    "SIGN_TRANSACTION_FAILURE",
    "SIGN_TRANSACTION_PREVIEW",
    "SIGN_TRANSACTION_REQUEST",
    "SIGN_TRANSACTION_SUCCESS",
    "TEMPLATES_LIST_REQUEST",
    "TEMPLATES_LIST_SUCCESS",
    "TOGGLE_SHOW_MODAL",
    "TOGGLE_TEMPLATE_HAS_BEEN_LOADED_FALSE",
    "UPDATE_CURRENCY",
]);

export const PROP = {};

PROP.members = {
    accountsByAlias: PropTypes.array,
    accountsByAliasFetching: PropTypes.bool,
    accountsByAliasSearchRequested: PropTypes.bool,
    currency: PropTypes.string,
    formData: PropTypes.object,
    existsAccountsHSBC: PropTypes.bool,
    localBanks: PropTypes.array,
    mode: PropTypes.string,
    showModal: PropTypes.bool,
    templates: {
        bankName: PropTypes.string,
        currencyName: PropTypes.string,
        name: PropTypes.string,
    },
    templateHasBeenLoaded: PropTypes.bool,
    transaction: PropTypes.object,
    transactions: {
        bankName: PropTypes.string,
        currencyName: PropTypes.string,
        name: PropTypes.string,
    },
    userAlias: PropTypes.object,
    userAliasFetched: PropTypes.bool,
};

PROP.types = {
    ...TypesRedux,
    fetching: PropTypes.bool,
    latestTransactionsList: PropTypes.arrayOf(PropTypes.shape(PROP.members.transactions)),
    templatesList: PropTypes.arrayOf(PropTypes.shape(PROP.members.templates)),
};

PROP.defaults = {
    ...DefaultsRedux,
    accountsByAlias: [],
    accountsByAliasFetching: false,
    accountsByAliasSearchRequested: false,
    currency: null,
    fetching: false,
    formData: null,
    existsAccountsHSBC: false,
    localBanks: [],
    latestTransactionsList: [],
    mode: MODE.EDIT,
    showModal: false,
    templateHasBeenLoaded: false,
    templatesList: [],
    transaction: null,
    userAlias: {
        aliasData: EMPTY_STR,
        aliasType: EMPTY_STR,
    },
    userAliasHasBeenFetched: false,
};
