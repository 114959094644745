import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "status";

export const TYPE = FactoryType(NAME, [
    "SHOW_SESSION_EXPIRATION_MODAL",
    "HIDE_SESSION_EXPIRATION_MODAL",
    "SAVE_LAST_HREF",
    "DELETE_LAST_HREF",
    "RESET_COME_FROM_LOGIN",
]);

export const PROP = {};

PROP.types = {
    sessionAboutToExpire: PropTypes.bool,
    sessionSecondsToExpire: PropTypes.number,
    sessionExtendedCount: PropTypes.number,
    lastHref: PropTypes.object,
    comeFromLogin: PropTypes.bool,
    ...TypesRedux,
};

PROP.defaults = {
    sessionAboutToExpire: false,
    sessionSecondsToExpire: 0,
    sessionExtendedCount: 0,
    lastHref: null,
    comeFromLogin: false,
    ...DefaultsRedux,
};
