import React from "react";

import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/Filter";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import {
    SelectorsAction as SelectorsActionSwiftMessages,
    SelectorsStore as SelectorsStoreSwiftMessages,
} from "~/store/swift";
import { SelectorsStore as SelectorsStoreTransactions } from "~/store/transactions";

import Style from "./List.rules.scss";
import Filters from "./_Filters";
import Messages from "./_Messages";

export const { NAME, NAME_MESSAGES } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const { i18n, dispatch, isFetching, keepFilters } = props;
    const { headTitle } = i18n;

    React.useEffect(() => {
        if (!keepFilters) {
            dispatch(SelectorsActionSwiftMessages.loadListPreSwiftMessagesRequest());
            dispatch(SelectorsActionSwiftMessages.loadListSwiftMessagesRequest());
        }
    }, [dispatch, keepFilters]);

    const handleBack = () => {
        const { history } = props;
        history.goBack();
    };

    return (
        <React.Fragment>
            <Container
                wait={isFetching}
                name={NAME}
                head-onBack={handleBack}
                head-title={headTitle}
                image="swift.svg"
                scopeToShowNotification={SCOPE.SWIFT}>
                <section>
                    <Filters />
                    {!isFetching && <Messages container-role="list" name={NAME_MESSAGES} />}
                </section>
            </Container>
        </React.Fragment>
    );
}

const mapStateToProps = (store) => ({
    isFetching: SelectorsStoreSwiftMessages.isFetching(store) || SelectorsStoreTransactions.getFetching(store),
    keepFilters: SelectorsStoreSwiftMessages.keepFilters(store),
    i18n: {
        headTitle: StoreI18n.getMessage(store, "swift.list.headTitle"),
    },
});

export default Connect(mapStateToProps)(Component);
