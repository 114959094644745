import { COMMA, DOT, EMPTY_STR, LANGUAGE } from "~/constants";
import { getInteger } from "~/util/config";
import UtilLodash from "~/util/lodash";

const NUMBER_FOR_SEPARATORS = 12345.6;

const decimalPointNumberFormat = { decimalSeparator: DOT, thousandSeparator: COMMA };
const decimalCommaNumberFormat = { decimalSeparator: COMMA, thousandSeparator: DOT };

export const countDecimalPlaces = (number, decimalSeparator = COMMA) => {
    let value = number;

    if (typeof number === "string") {
        const INPUT_REGEX_REPLACE = new RegExp(`[^0-9${decimalSeparator}]`, "g");
        value = value.replace(INPUT_REGEX_REPLACE, EMPTY_STR);
    } else {
        value = `${value}`.replace(DOT, decimalSeparator);
    }

    return value && value % 1 !== 0 ? `${value}`.split(decimalSeparator)[1].length : 0;
};

export const numberFormat = (lang) => {
    // discussion by lang to prevent decimal problem with Chrome v88.0.4324.93
    if (lang.toLowerCase() === LANGUAGE.EN) {
        return decimalPointNumberFormat;
    }

    if (lang.toLowerCase() === LANGUAGE.ES || lang.toLowerCase() === LANGUAGE.PT) {
        return decimalCommaNumberFormat;
    }

    // default:
    const localeString = NUMBER_FOR_SEPARATORS.toLocaleString(lang || undefined);
    const decimalSeparatorIndex = Math.max(localeString.lastIndexOf(DOT), localeString.lastIndexOf(COMMA));
    const thousandSeparatorIndex = Math.min(localeString.lastIndexOf(DOT), localeString.lastIndexOf(COMMA));

    return {
        decimalSeparator: localeString[decimalSeparatorIndex],
        thousandSeparator: localeString[thousandSeparatorIndex],
    };
};

export const toNumber = (str, decimalSeparator = COMMA, precision = 2) => {
    const INPUT_REGEX_REPLACE = new RegExp(`[^0-9${decimalSeparator}]`, "g");
    const rep = str.replace(INPUT_REGEX_REPLACE, EMPTY_STR).replace(decimalSeparator, DOT);

    return str && str !== EMPTY_STR && str !== null ? parseFloat(parseFloat(rep).toFixed(precision)) : str;
};

export const toNumberFormat = (str, decimalSeparator = COMMA) => {
    return `${str}`.replace(DOT, decimalSeparator);
};

export const getAsNumber = (valueStr, lang) => {
    if (UtilLodash.isEmpty(valueStr)) {
        return valueStr;
    }

    const { decimalSeparator, thousandSeparator } = numberFormat(lang);
    let val = valueStr.replace(thousandSeparator, EMPTY_STR);

    const precision = countDecimalPlaces(val, decimalSeparator);

    val = parseFloat(val).toFixed(precision);

    return parseFloat(val);
};

export const numberToLocaleFormat = (str, lang) => {
    let numberToLocale = str ? str.toLocaleString(lang || undefined, { minimumFractionDigits: 2 }) : EMPTY_STR;

    if (lang === "es" && Math.trunc(str).toString().length === 4) {
        // Fix a javascript error when lang is es
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(numberToLocale)) {
            numberToLocale = numberToLocale.replace(pattern, "$1.$2");
        }
    }

    return numberToLocale;
};

export const formatNumber = (value, lang) => {
    const { decimalSeparator, thousandSeparator } = numberFormat(lang);
    const decimalPlaces = countDecimalPlaces(value, decimalSeparator);
    const maximumDecimals = getInteger("defaultDecimal.maximum");
    const minimumDecimals = getInteger("defaultDecimal.minimum");
    const decimalScale = Math.max(Math.min(decimalPlaces, maximumDecimals), minimumDecimals);
    const numParts = Number.parseFloat(value)
        .toFixed(decimalScale)
        .replace(DOT, decimalSeparator)
        .split(decimalSeparator);
    let l = numParts[0].length - 3;

    while (l > 0) {
        numParts[0] = `${numParts[0].slice(0, l)}${thousandSeparator}${numParts[0].slice(l)}`;
        l -= 3;
    }

    return `${numParts[0]}${decimalSeparator}${numParts[1]}`;
};

export const fixPrecision = (number, precision = 2) =>
    number && number !== EMPTY_STR ? parseFloat(number).toFixed(precision) : number;

export const fixNumberAsStringPrecision = (numberAsStr = 0, precision = 2) =>
    numberAsStr.slice(0, numberAsStr.length - (countDecimalPlaces(numberAsStr) - precision));

export const clamp = (n, min, max) => Math.max(Math.min(n, max), min);

export const stripNumber = (number, decimalSeparator) => {
    return number.substring(0, number.indexOf(decimalSeparator)).replace(/[^0-9]/g, EMPTY_STR);
};

export const roundToTwoDecimals = (number) => {
    return +`${Math.round(`${number}e+2`)}e-2`;
};
