import PropTypes from "prop-types";

import { MODE } from "~/constants/form";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "uploadPaymentOrders";

export const PATH = {
    EXPORT_PDF: "/client.factoring.paymentOrders.upload.export.pdf",
    UPLOAD_PAYMENT_ORDERS_SEND: "/client.factoring.paymentOrders.upload.execute",
    DOWNLOAD_PAYMENT_ORDERS: "/client.factoring.paymentOrders.download",
};

export const TYPE = FactoryType(NAME, [
    "CANCEL_CUSTOM_TRANSACTION_REQUEST",
    "CLOSE_TRANSACTION_PREVIEW",
    "DOWNLOAD_PAYMENT_ORDERS_REQUEST",
    "DOWNLOAD_PAYMENT_ORDERS_FAILURE",
    "DOWNLOAD_PAYMENT_ORDERS_SUCCESS",
    "EXPORT_PDF",
    "FILE_PAYMENT_ORDERS_REMOVED",
    "FILE_UPLOAD_PAYMENT_ORDERS_FAILURE",
    "FILE_UPLOAD_PAYMENT_ORDERS_SUCCESS",
    "PREVIEW_CANCEL_REQUEST",
    "PREVIEW_FORM_REQUEST",
    "PREVIEW_FORM_SUCCESS",
    "READ_TRANSACTION_REQUEST",
    "READ_TRANSACTION_SUCCESS",
    "RESTART_FLOW",
    "SEND_FORM_REQUEST",
    "SEND_FORM_FAILURE",
    "SEND_FORM_SUCCESS",
    "SIGN_TRANSACTION_PREVIEW_REQUEST",
    "SIGN_TRANSACTION_PREVIEW_SUCCESS",
    "SIGN_TRANSACTION_REQUEST",
]);

export const PROP = {};

PROP.types = {
    hasErrors: PropTypes.bool,
    idFile: PropTypes.number,
    isFetching: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    mode: PropTypes.string,
    paymentOrdersList: PropTypes.array,
    prevMode: PropTypes.string,
    totalizers: PropTypes.array,
    transaction: PropTypes.object,
    ...TypesRedux,
};

PROP.defaults = {
    hasErrors: false,
    idFile: null,
    isFetching: false,
    isSubmitting: false,
    mode: MODE.EDIT,
    paymentOrdersList: [],
    prevMode: null,
    totalizers: [],
    transaction: {},
    ...DefaultsRedux,
};
