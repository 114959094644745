import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "position";

export const PATH = {
    GET_POSITION: "/products.list",
    GET_POSITION_PDF: "/products.getPositionPDF",
};

export const TYPE = FactoryType(NAME, [
    "CHANGE_ALL_COLLAPSED_ELEMENTS_COUNT",
    "CHANGE_COLLAPSED_ELEMENTS_COUNT",
    "DELETE_POSITION",
    "GET_POSITION_PDF",
    "GET_POSITION_PDF_SUCCESS",
    "READ_POSITION_REQUEST",
    "READ_POSITION_SUCCESS",
    "SHOW_DOWNLOAD_DATE_MODAL",
]);

export const PROP = {};

PROP.types = {
    collapsedElementsCount: PropTypes.number,
    order: PropTypes.string,
    position: PropTypes.array,
    showDownloadDateModal: PropTypes.bool,
    ...TypesRedux,
};

PROP.defaults = {
    collapsedElementsCount: 0,
    order: null,
    position: [],
    showDownloadDateModal: false,
    ...DefaultsRedux,
};
