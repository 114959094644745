import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    locationList: (props) => API.executeWithAccessToken(PATH.LOCATIONS, props),
    locationListExternal: (props) => API.executeAnonymous(PATH.LOCATIONS, props),
};

export const SelectorsAction = {
    locationListRequest: (props) => FactoryAction(TYPE.LOCATIONS_LIST_REQUEST, props),
    locationListSuccess: (props) => FactoryAction(TYPE.LOCATIONS_LIST_SUCCESS, props),
};

export const SelectorsStore = {
    locationList: (store) => store[NAME].locationList,
    fetching: (store) => store[NAME].fetching,
};
