import React from "react";

import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { COMMA, EMPTY_STR, SPACE_STR } from "~/constants";
import { STATUS } from "~/constants/transaction";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import {
    SelectorsStore as SelectorsStoreComexExport,
    SelectorsAction as SelectorsActionComexExport,
} from "~/store/comex/export";
import { SelectorsAction as SelectorsActionFile } from "~/store/files";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import DeviceUtils from "~/util/device";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import DataDate from "~/components/DataDate";
import HighOrder from "~/components/HighOrder";
import Token from "~/components/Token/Token";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import TicketPrintInformation from "~/pages/_components/TicketPrintInformation";

import Style from "./Step2Preview.rules.scss";

const FORM_ID = "comex.export.transferCreditLetter";

export const { NAME } = Style;

export const PROP = {
    types: {
        isSubmitting: PropTypes.bool,
    },
    defaults: {
        isSubmitting: false,
    },
};

const printForm = () => {
    const elements = window.document.getElementsByClassName("no_print");

    for (let i = 0; i < elements.length; i += 1) {
        elements[i].style.display = "none";
    }

    window.print();

    for (let i = 0; i < elements.length; i += 1) {
        elements[i].style.display = EMPTY_STR;
    }

    return true;
};

export function Component(props) {
    const { dispatch, isSubmitting, prevData } = props;

    const {
        address,
        amount,
        attachments,
        attachmentsNames,
        confirmationExpenses,
        contact,
        deadlineDocumentSubmission,
        issuerBankExpenses,
        merchandiseQuantity,
        newDueDate,
        newShippingDate,
        operationNumber,
        otherBankExpenses,
        otherExpenses,
        phone,
        secondBank,
        secondBankAccount,
        secondBankAddress,
        secondBankName,
        secondBeneficiary,
        specialInstructions,
        transferExpenses,
    } = prevData;

    const fileIds = attachments ? attachments.split(COMMA) : EMPTY_STR;

    const downloadFile = (idFile, fileName) => {
        dispatch(SelectorsActionFile.downloadFileRequest({ idFile, fileName }));
    };

    const shareTicket = () => {
        dispatch(
            SelectorsActionTransactions.shareTicketRequest({
                transactionData: prevData,
                idActivity: `${FORM_ID}.send`,
            }),
        );
    };

    return (
        <Form id={Style.ID}>
            <TicketPrintInformation />

            <Box flex directionColumn align="flex-start" className="mb-3">
                {operationNumber && (
                    <div className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.operationNumber.label`)}</h4>
                        <span>{operationNumber}</span>
                    </div>
                )}
                {secondBeneficiary && (
                    <div className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.secondBeneficiary.label`)}</h4>
                        <span>{secondBeneficiary}</span>
                    </div>
                )}
                {address && (
                    <div className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.address.label`)}</h4>
                        <span>{address}</span>
                    </div>
                )}
                {contact && (
                    <div className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.contact.label`)}</h4>
                        <span>{contact}</span>
                    </div>
                )}
                {phone && (
                    <div className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.phone.label`)}</h4>
                        <span>{phone}</span>
                    </div>
                )}
                {secondBank && (
                    <div className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.secondBank.label`)}</h4>
                        <span>{secondBank}</span>
                    </div>
                )}
                {secondBankName && (
                    <div className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.secondBankName.label`)}</h4>
                        <span>{secondBankName}</span>
                    </div>
                )}
                {secondBankAddress && (
                    <div className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.secondBankAddress.label`)}</h4>
                        <span>{secondBankAddress}</span>
                    </div>
                )}
                {secondBankAccount && (
                    <div className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.secondBankAccount.label`)}</h4>
                        <span>{secondBankAccount}</span>
                    </div>
                )}
                {amount && (
                    <div className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.amount.label`)}</h4>
                        <span>
                            <FormattedAmount currency={amount.currency} quantity={amount.quantity} />
                        </span>
                    </div>
                )}
                {newDueDate && (
                    <div className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.newDueDate.label`)}</h4>
                        <span>
                            <DataDate value={newDueDate} />
                        </span>
                    </div>
                )}
                {newShippingDate && (
                    <div className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.newShippingDate.label`)}</h4>
                        <span>
                            <DataDate value={newShippingDate} />
                        </span>
                    </div>
                )}
                {deadlineDocumentSubmission && (
                    <div className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.deadlineDocumentSubmission.label`)}</h4>
                        <span>
                            {deadlineDocumentSubmission}
                            {SPACE_STR}
                            {UtilsI18n.get(`${FORM_ID}.deadlineDocumentSubmission.description`)}
                        </span>
                    </div>
                )}
                {merchandiseQuantity && (
                    <div className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.merchandiseQuantity.label`)}</h4>
                        <span>{merchandiseQuantity}</span>
                    </div>
                )}
                {attachments && (
                    <div className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.files.label`)}</h4>
                        <span>
                            {fileIds.map((fileId, index) => (
                                <div className="data-wrapper">
                                    <span className="data-label">
                                        <a onClick={() => downloadFile(fileId, attachmentsNames[index])}>
                                            {attachmentsNames[index]}
                                        </a>
                                    </span>
                                </div>
                            ))}
                        </span>
                    </div>
                )}
                {transferExpenses && (
                    <div className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.transferExpenses.label`)}</h4>
                        <span>{UtilsI18n.get(`${FORM_ID}.option.${transferExpenses}`)}</span>
                    </div>
                )}
                {confirmationExpenses && (
                    <div className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.confirmationExpenses.label`)}</h4>
                        <span>{UtilsI18n.get(`${FORM_ID}.option.${confirmationExpenses}`)}</span>
                    </div>
                )}
                {otherExpenses && (
                    <div className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.otherExpenses.label`)}</h4>
                        <span>{UtilsI18n.get(`${FORM_ID}.option.${otherExpenses}`)}</span>
                    </div>
                )}
                {issuerBankExpenses && (
                    <div className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.issuerBankExpenses.label`)}</h4>
                        <span>{UtilsI18n.get(`${FORM_ID}.option.${issuerBankExpenses}`)}</span>
                    </div>
                )}
                {otherBankExpenses && (
                    <div className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.otherBankExpenses.label`)}</h4>
                        <span>{UtilsI18n.get(`${FORM_ID}.option.${otherBankExpenses}`)}</span>
                    </div>
                )}
                {specialInstructions && (
                    <div className="info-label">
                        <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.specialInstructions.label`)}</h4>
                        <span>{specialInstructions}</span>
                    </div>
                )}
            </Box>

            <div className="no_print">
                <Token labelButton="global.send" isSubmitting={isSubmitting} />
            </div>
            {!DeviceUtils.isMobileNative() && (
                <div className="buttonsTicketConfirmation">
                    <Button
                        block={false}
                        bsStyle="link"
                        label="comex.preview.print.label"
                        onClick={() => printForm()}
                    />
                </div>
            )}
            <div className="buttonsTicketConfirmation">
                <Button block={false} bsStyle="link" label="forms.ticket.printPDF" onClick={() => shareTicket()} />
            </div>
        </Form>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        prevData: SelectorsStoreComexExport.getComexFormPrevData(store),
        transaction: SelectorsStoreComexExport.getTransaction(store),
        user: SelectorsStoreSession.getUser(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithRouter,
    WithFormik({
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        validationSchema: (props) => {
            const { user } = props;

            return Yup.object().shape({
                otp: user.needsBiometric
                    ? Yup.string().nullable()
                    : Yup.string().required(UtilsI18n.get("comex.export.transferCreditLetter.validation.otp.required")),
            });
        },
        handleSubmit: ({ otp }, formikBag) => {
            const {
                dispatch,
                history,
                prevData,
                transaction: { idTransaction, idActivity, idTransactionStatus },
            } = formikBag.props;

            const isFromTransactionsHistory = history.location.pathname.includes("/ticket");

            if (isFromTransactionsHistory && idTransactionStatus !== STATUS.DRAFT) {
                dispatch(
                    SelectorsActionComex.signTransaction({
                        idForm: null,
                        idActivity,
                        idTransaction,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            } else {
                dispatch(
                    SelectorsActionComexExport.transferCreditLetterSendRequest({
                        prevData,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            }
        },
    }),
)(Component);
