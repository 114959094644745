import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SPACE_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreTicket } from "~/store/administration/common/administrationTicket";
import * as UtilI18n from "~/util/i18n";
import { numberFormat as NumberFormatUtil } from "~/util/number";

import I18n from "~/components/I18n";
import NumberFormatInput from "~/components/NumberFormatInput";

import PermissionsList from "~/pages/administration/_components/PermissionsList";

import Style from "./Ticket.rules.scss";

export const NAME = "PermissionsTicket";

export const PROP = {
    types: {
        isFromBackoffice: PropTypes.bool,
        isMedium: PropTypes.bool,
        userData: PropTypes.object,
    },
    defaults: {
        isFromBackoffice: false,
        isMedium: false,
        userData: null,
    },
};

export function Component(props) {
    const {
        actions,
        fetchingPermissions,
        isFromBackoffice,
        isMedium,
        lang,
        match,
        transactionData: { data = {} },
        user,
        userData,
    } = props;
    const { firstName, lastName } = user || userData || {};
    const { idTransaction } = !isFromBackoffice ? match.params : {};
    const { decimalSeparator, thousandSeparator } = NumberFormatUtil(lang || UtilI18n.getLang());

    React.useLayoutEffect(() => {
        if (!isFromBackoffice && idTransaction) {
            actions.loadPermissionsTicketRequest({ idTransactionToRead: idTransaction });
        }
    }, [actions, idTransaction, isFromBackoffice]);

    const { permissions, maxAmount, capFrequencies } = data || {};

    return (
        <section id={isFromBackoffice && Style.ID}>
            {!fetchingPermissions && (
                <div>
                    <div>
                        <span className="data-label">{UtilI18n.get("administration.permissions.user")}</span>
                        <span className="transfer-data">{`${firstName} ${lastName}`}</span>
                    </div>
                    <div>
                        <PermissionsList permissions={permissions}>
                            {(list) =>
                                list ? (
                                    <React.Fragment>
                                        <h4 className="form-title">
                                            <I18n id="administration.permissions.permissionsApplied" />
                                        </h4>
                                        {list}
                                    </React.Fragment>
                                ) : (
                                    <I18n id="administration.permissions.empty" />
                                )
                            }
                        </PermissionsList>
                    </div>
                </div>
            )}
            {isMedium && (
                <div className="channel-maxAmount">
                    <span className="data-label">{UtilI18n.get("administration.channels.topAmount")}</span>
                    <span>
                        {UtilI18n.get("administration.channels.information.frequency")}
                        {SPACE_STR}
                        {
                            <NumberFormatInput
                                displayType="text"
                                thousandSeparator={thousandSeparator}
                                decimalSeparator={decimalSeparator}
                                value={maxAmount}
                                decimalScale={2}
                                fixedDecimalScale
                            />
                        }
                        <span> {UtilI18n.get("administration.channels.topAmount.frequency")}</span>
                        <span> {UtilI18n.get(`administration.channels.${capFrequencies}`)}</span>
                    </span>
                </div>
            )}
        </section>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    transactionData: SelectorsStoreTicket.getData(store),
    userData: SelectorsStoreTicket.getUser(store),
});

export default Connect(mapStateToProps)(Component);
