import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    purchaseNotificationGetPhoneAndActive: (props) => API.executeWithAccessToken(PATH.GET_PHONE_AND_ACTIVE, props),
};

export const SelectorsAction = {
    requestPurchaseNotificationGetPhoneAndActive: (props) => FactoryAction(TYPE.GET_PHONE_AND_ACTIVE_REQUEST, props),
    errorPurchaseNotificationGetPhoneAndActive: (props) => FactoryAction(TYPE.GET_PHONE_AND_ACTIVE_ERROR, props),
    successPurchaseNotificationGetPhoneAndActive: (props) => FactoryAction(TYPE.GET_PHONE_AND_ACTIVE_SUCCESS, props),
    cleanUp: (props) => FactoryAction(TYPE.CLEAN_UP, props),
    changeCreditcard: (props) => FactoryAction(TYPE.CHANGE, props),
    notChangeCreditcard: (props) => FactoryAction(TYPE.NOT_CHANGE, props),
};

export const SelectorsStore = {
    getFetching: (store) => store[NAME].fetching,
    getPhone: (store) => store[NAME].phone,
    getActive: (store) => store[NAME].active,
    getChangeCreditcard: (store) => store[NAME].change,
};
