import { NODE_ENV, NODE_ENV_PRODUCTION, REACT_APP_PINNING_DISABLED } from "~/constants";
import { setHeaders as ApiMobileSetHeaders } from "~/store/apiMobile";

const SERVER_TRUST_MODE_DONOTCHECK = "nocheck";
const SERVER_TRUST_MODE_PINNED = "pinned";

export const configureApi = async () => {
    if (window.cordovaHTTP) {
        // Calling without parameters makes use of default values (setHeaders implementation)
        ApiMobileSetHeaders();

        let serverTrustMode = SERVER_TRUST_MODE_DONOTCHECK;

        if (NODE_ENV === NODE_ENV_PRODUCTION && REACT_APP_PINNING_DISABLED === "false") {
            serverTrustMode = SERVER_TRUST_MODE_PINNED;
        }

        const responseSSLPinning = await window.cordovaHTTP.setServerTrustMode(serverTrustMode);

        return responseSSLPinning;
    }

    return undefined;
};

export default configureApi;
