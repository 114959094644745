import { call as Call, put as Put, takeLatest as TakeLatest, select as Select } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE } from "~/constants";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionWidget } from "~/store/widget";
import * as UtilsArray from "~/util/array";
import * as I18nUtils from "~/util/i18n";
import * as UtilsString from "~/util/string";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsMiddleware, SelectorsStore } from "./_selectors";

const sagas = [
    TakeLatest([TYPE.LOAD_LAYOUT_REQUEST], loadLayoutRequest),
    TakeLatest(TYPE.SAVE_LAYOUT_REQUEST, saveLayoutRequest),
    TakeLatest(TYPE.DELETE_WIDGET, deleteWidget),
    TakeLatest(TYPE.ADD_WIDGET, addWidget),
];

export default sagas;

function* loadLayoutRequest() {
    const response = yield Call(SelectorsMiddleware.loadLayoutRequest);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.loadLayoutFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: I18nUtils.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.DESKTOP],
            }),
        );
    } else {
        const layout = response.data.data.widgets.reduce(
            (acc, item) => ({
                ...acc,
                // Append item if the column array is already defined in the resulting object, initialize it otherwise.
                [item.column]: Array.isArray(acc[item.column]) ? [...acc[item.column], item.id] : [item.id],
            }),
            {},
        );
        const widgets = response.data.data.widgets.map(({ id, uri }) => ({
            id: UtilsString.capitalizeFirstLetter(id), // TODO: casing shouldn't be forced.
            key: id,
            uri,
        }));
        yield Put(SelectorsAction.loadLayoutSuccess({ layout, widgets }));
    }
}

function* saveLayoutRequest() {
    const layout = yield Select(SelectorsStore.getLayout);
    const response = yield Call(SelectorsMiddleware.saveLayoutRequest, { widget: layout });

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.saveLayoutFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: I18nUtils.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.DESKTOP],
            }),
        );
    } else {
        yield Put(SelectorsAction.saveLayoutSuccess({ layout }));
    }
}

function* deleteWidget(props) {
    const { index } = props;
    const layout = yield Select(SelectorsStore.getLayout);
    const layoutWithoutWidget = UtilsArray.removeElement(layout, index);

    yield Put(SelectorsAction.setLayout({ layout: layoutWithoutWidget }));
    yield Put(SelectorsAction.saveLayoutRequest());
    yield Put(SelectorsAction.addAvailableWidget({ widget: layout[index] }));
    yield Put(SelectorsActionWidget.deleteWidget({ widget: layout[index] }));
}

function* addWidget(props) {
    const { index } = props;

    let layout = yield Select(SelectorsStore.getLayout);
    const widgets = yield Select(SelectorsStore.getWidgets);

    layout = layout.map((widget) => ({ ...widget, row: widget.row + 1 }));

    yield Put(SelectorsAction.setLayout({ layout: [{ ...widgets[index], column: 1 }, ...layout] }));
    yield Put(SelectorsAction.saveLayoutRequest());
    yield Put(SelectorsAction.removeAvailableWidget({ index }));
}
