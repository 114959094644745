import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    verifyInvitationCode: (props) => API.executeAnonymous(PATH.ENROLLMENT_INVITATION_CODE_VERIFY, props),

    verifyInvitationCodeWithExchangeToken: (props) => {
        const { _exchangeToken } = props;
        return API.executeWithExchangeToken(PATH.ENROLLMENT_INVITATION_CODE_VERIFY, props, _exchangeToken);
    },

    verifyVerificationCode: (props) => {
        const { _exchangeToken } = props;
        return API.executeWithExchangeToken(PATH.ENROLLMENT_VERIFICATION_CODE_VERIFY, props, _exchangeToken);
    },

    resendVerificationCode: (props) => {
        const { _exchangeToken } = props;
        return API.executeWithExchangeToken(PATH.ENROLLMENT_RESEND_VERIFICATION_CODE_VERIFY, props, _exchangeToken);
    },
    registerPersonalDataPre: (props) => {
        const { _exchangeToken } = props;
        return API.executeWithExchangeToken(PATH.ENROLLMENT_REGISTER_PERSONAL_DATA_PRE, props, _exchangeToken);
    },
    requestSecuritySeals: (props) => {
        const { _exchangeToken } = props;
        return API.executeWithExchangeToken(PATH.ENROLLMENT_LIST_SECURITY_SEALS, props, _exchangeToken);
    },
    registerPersonalData: (props) => {
        const { _exchangeToken } = props;
        return API.executeWithExchangeToken(PATH.ENROLLMENT_REGISTER_PERSONAL_DATA, props, _exchangeToken);
    },
    finishEnrollmentHard: (props) => {
        const { _exchangeToken } = props;
        return API.executeWithExchangeToken(PATH.ENROLLMENT_FINISH_HARD, props, _exchangeToken);
    },

    securityTips: (props) => {
        const { _exchangeToken } = props;
        return API.executeWithExchangeToken(PATH.ENROLLMENT_SECURITY_TIPS, props, _exchangeToken);
    },

    addSoftOTPWithBiometric: (props) => {
        const { _exchangeToken } = props;
        return API.executeWithExchangeToken(PATH.ENROLLMENT_ADD_SOFT_OTP, props, _exchangeToken);
    },

    revokeSoftOtp: (props) => {
        const { _exchangeToken } = props;
        return API.executeWithExchangeToken(PATH.ENROLLMENT_REVOKE_SOFT_OTP, props, _exchangeToken);
    },

    finishEnrollmentSoft: (props) => {
        const { _exchangeToken } = props;
        return API.executeWithExchangeToken(PATH.ENROLLMENT_FINISH_SOFT, props, _exchangeToken);
    },
};

export const SelectorsAction = {
    verifyInvitationCode: (props) => FactoryAction(TYPE.VERIFY_INVITATION_CODE_REQUEST, props),
    errorVerifyInvitationCode: (props) => FactoryAction(TYPE.VERIFY_INVITATION_CODE_ERROR, props),
    successVerifyInvitationCode: (props) => FactoryAction(TYPE.VERIFY_INVITATION_CODE_SUCCESS, props),

    verifyVerificationCode: (props) => FactoryAction(TYPE.VERIFY_VERIFICATION_CODE_REQUEST, props),
    errorVerificationCode: (props) => FactoryAction(TYPE.VERIFY_VERIFICATION_CODE_ERROR, props),
    successVerificationCode: (props) => FactoryAction(TYPE.VERIFY_VERIFICATION_CODE_SUCCESS, props),

    resendVerificationCode: (props) => FactoryAction(TYPE.RESEND_VERIFICATION_CODE_REQUEST, props),
    errorResendIVerificationCode: (props) => FactoryAction(TYPE.RESEND_VERIFICATION_CODE_ERROR, props),
    successResendVerificationCode: (props) => FactoryAction(TYPE.RESEND_VERIFICATION_CODE_SUCCESS, props),

    /**
     * Register Personal Data
     * ---------------------------------------------------------------------------------------------------------------
     */
    registerPersonalDataPre: (props) => FactoryAction(TYPE.REGISTER_PERSONAL_DATA_PRE_REQUEST, props),
    registerPersonalDataPreSuccess: (props) => FactoryAction(TYPE.REGISTER_PERSONAL_DATA_PRE_REQUEST_SUCCESS, props),
    registerPersonalDataPreError: (props) => FactoryAction(TYPE.REGISTER_PERSONAL_DATA_PRE_REQUEST_ERROR, props),

    requestSecuritySeals: (props) => FactoryAction(TYPE.LIST_SECURITY_SEALS_REQUEST, props),
    requestSecuritySealsSuccess: (props) => FactoryAction(TYPE.LIST_SECURITY_SEALS_REQUEST_SUCCESS, props),

    saveSelectedSecuritySeal: (props) => FactoryAction(TYPE.SAVE_SELECTED_SECURITY_SEAL, props),

    registerPersonalData: (props) => FactoryAction(TYPE.REGISTER_PERSONAL_DATA_REQUEST, props),
    registerPersonalDataSuccess: (props) => FactoryAction(TYPE.REGISTER_PERSONAL_DATA_REQUEST_SUCCESS, props),
    registerPersonalDataError: (props) => FactoryAction(TYPE.REGISTER_PERSONAL_DATA_REQUEST_ERROR, props),

    showSecuritySealModal: (props) => FactoryAction(TYPE.SHOW_SECURITY_SEALS_MODAL, props),
    hideSecuritySealModal: (props) => FactoryAction(TYPE.HIDE_SECURITY_SEALS_MODAL, props),
    showSecuritySealConfirmationModal: (props) => FactoryAction(TYPE.SHOW_SECURITY_SEALS_CONFIRMATION_MODAL, props),
    hideSecuritySealConfirmationModal: (props) => FactoryAction(TYPE.HIDE_SECURITY_SEALS_CONFIRMATION_MODAL, props),
    /**
     *---------------------------------------------------------------------------------------------------------------
     */

    securityTips: (props) => FactoryAction(TYPE.ENROLLMENT_SECURITY_TIPS_REQUEST, props),
    securityTipsSuccess: (props) => FactoryAction(TYPE.ENROLLMENT_SECURITY_TIPS_SUCCESS, props),
    securityTipsError: (props) => FactoryAction(TYPE.ENROLLMENT_SECURITY_TIPS_ERROR, props),

    addSoftOTPWithBiometric: (props) => FactoryAction(TYPE.ENROLLMENT_ADD_SOFT_OTP_REQUEST, props),
    addSoftOTPWithBiometricSuccess: (props) => FactoryAction(TYPE.ENROLLMENT_ADD_SOFT_OTP_SUCCESS, props),
    addSoftOTPWithBiometricError: (props) => FactoryAction(TYPE.ENROLLMENT_ADD_SOFT_OTP_ERROR, props),

    finishEnrollmentSoft: (props) => FactoryAction(TYPE.ENROLLMENT_FINISH_SOFT_REQUEST, props),
    finishEnrollmentSoftSuccess: (props) => FactoryAction(TYPE.ENROLLMENT_FINISH_SOFT_SUCCESS, props),
    finishEnrollmentSoftError: (props) => FactoryAction(TYPE.ENROLLMENT_FINISH_SOFT_ERROR, props),

    finishEnrollmentHard: (props) => FactoryAction(TYPE.FINISH_ENROLLMENT_HARD_REQUEST, props),
    finishEnrollmentHardSuccess: (props) => FactoryAction(TYPE.FINISH_ENROLLMENT_HARD_SUCCESS, props),
    finishEnrollmentHardError: (props) => FactoryAction(TYPE.FINISH_ENROLLMENT_HARD_ERROR, props),
    cleanUp: (props) => FactoryAction(TYPE.CLEAN_UP, props),

    finishChangeMyPhone: (props) => FactoryAction(TYPE.FINISH_CHANGE_MY_PHONE, props),
};

export const SelectorsStore = {
    property: (store) => store[NAME].property,
    exchangeToken: (store) => store[NAME].exchangeToken,
    invitationCode: (store) => store[NAME].invitationCode, // TODO: ver si es necesario
    accountList: (store) => store[NAME].accountList,
    client: (store) => store[NAME].client,
    fetching: (store) => store[NAME].fetching,
    fetchingSecuritySeals: (store) => store[NAME].fetchingSecuritySeals,
    seal: (store) => store[NAME].seal,
    showSealModal: (store) => store[NAME].showSealModal,
    showSealConfirmationModal: (store) => store[NAME].showSealConfirmationModal,
    secondFactorAuthType: (store) => store[NAME].secondFactorAuthType,
    securitySeal: (store) => store[NAME].securitySeal,
    idUser: (store) => store[NAME].idUser,
    securitySealList: (store) => store[NAME].securitySealList,
    selectedSecuritySeal: (store) => store[NAME].selectedSecuritySeal,
    updateSecuritySeal: (store) => store[NAME].updateSecuritySeal,
    finishState: (store) => store[NAME].finishState,
    mobileNumber: (store) => store[NAME].mobileNumber,
    needsBiometric: (store) => store[NAME].needsBiometric,
    username: (store) => store[NAME].username,
    getIsEnrollment: (store) => store[NAME].isEnrollment,
};
