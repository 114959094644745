import { BANK_FIELDS } from "~/constants";
import { SelectorsAction as SelectorsActionBankSelector } from "~/store/bankSelector";
import UtilLodash from "~/util/lodash";

export default Object.create({
    handleSearchBank(template, dispatch) {
        const fields = Object.values(BANK_FIELDS);
        fields.forEach((idField) => {
            if (UtilLodash.isObject(template[idField]) && template[idField].code) {
                const { code, type } = template[idField];
                const filters = { code, type, name: "", country: 0 };
                dispatch(SelectorsActionBankSelector.searchBankRequest({ filters, idField, fromTemplate: true }));
            }
        });
    },
});
