import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    downloadPaymentOrders: (props) => API.executeWithAccessToken(PATH.DOWNLOAD_PAYMENT_ORDERS, props),
    uploadPaymentOrdersExportPDF: (props) => API.executeWithAccessToken(PATH.EXPORT_PDF, props),
    uploadPaymentOrdersSend: (props) => API.executeWithAccessToken(PATH.UPLOAD_PAYMENT_ORDERS_SEND, props),
};

export const SelectorsAction = {
    cancelTransactionCustom: (props) => FactoryAction(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, props),
    closeConfirmation: () => FactoryAction(TYPE.CLOSE_TRANSACTION_PREVIEW),
    downloadPaymentOrdersRequest: (props) => FactoryAction(TYPE.DOWNLOAD_PAYMENT_ORDERS_REQUEST, props),
    downloadPaymentOrdersFailure: (props) => FactoryAction(TYPE.DOWNLOAD_PAYMENT_ORDERS_FAILURE, props),
    downloadPaymentOrdersSuccess: (props) => FactoryAction(TYPE.DOWNLOAD_PAYMENT_ORDERS_SUCCESS, props),
    fileUploadPaymentOrdersFailure: (props) => FactoryAction(TYPE.FILE_UPLOAD_PAYMENT_ORDERS_FAILURE, props),
    fileUploadPaymentOrdersRemoved: (props) => FactoryAction(TYPE.FILE_PAYMENT_ORDERS_REMOVED, props),
    fileUploadPaymentOrdersSuccess: (props) => FactoryAction(TYPE.FILE_UPLOAD_PAYMENT_ORDERS_SUCCESS, props),
    previewCancelRequest: () => FactoryAction(TYPE.PREVIEW_CANCEL_REQUEST),
    readUploadPaymentOrdersTransactionRequest: (props) => FactoryAction(TYPE.READ_TRANSACTION_REQUEST, props),
    readUploadPaymentOrdersTransactionSuccess: (props) => FactoryAction(TYPE.READ_TRANSACTION_SUCCESS, props),
    restartFlow: () => FactoryAction(TYPE.RESTART_FLOW),
    signTransactionPreview: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_PREVIEW_REQUEST, props),
    signTransactionPreviewSuccess: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_PREVIEW_SUCCESS, props),
    signTransaction: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_REQUEST, props),
    uploadPaymentOrdersExportPDF: (props) => FactoryAction(TYPE.EXPORT_PDF, props),
    uploadPaymentOrdersPreviewFormRequest: (props) => FactoryAction(TYPE.PREVIEW_FORM_REQUEST, props),
    uploadPaymentOrdersPreviewFormSuccess: (props) => FactoryAction(TYPE.PREVIEW_FORM_SUCCESS, props),
    uploadPaymentOrdersSendFormRequest: (props) => FactoryAction(TYPE.SEND_FORM_REQUEST, props),
    uploadPaymentOrdersSendFormFailure: (props) => FactoryAction(TYPE.SEND_FORM_FAILURE, props),
    uploadPaymentOrdersSendFormSuccess: (props) => FactoryAction(TYPE.SEND_FORM_SUCCESS, props),
};

export const SelectorsStore = {
    getIdFile: (store) => store[NAME].idFile,
    getIsSubmitting: (store) => store[NAME].isSubmitting,
    getMode: (store) => store[NAME].mode,
    getPaymentOrders: (store) => store[NAME].paymentOrdersList,
    getPrevMode: (store) => store[NAME].prevMode,
    getTotalizers: (store) => store[NAME].totalizers,
    getTransaction: (store) => store[NAME].transaction,
    isFetching: (store) => store[NAME].isFetching,
};
