import { push as Push } from "connected-react-router";
import { takeLatest as TakeLatest, call as Call, put as Put, select as Select } from "redux-saga/effects";

import {
    INVALID_EMAIL,
    LEVEL,
    SCOPE,
    RESPONSE_TYPE,
    VALIDATION_ERROR,
    API_INVALID_USER_OR_SECOND_FACTOR,
} from "~/constants";
import { SelectorsAction as ActionsLogin } from "~/store/login";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import {
    SelectorsAction as SelectorsActionsRecoverPassword,
    SelectorsStore as SelectorsStoreRecoverPassword,
} from "~/store/recoverypassword/noToken";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import UtilsDevice, { DEVICE_MOBILE } from "~/util/device";
import { adjustIdFieldErrors as AdjustIdFieldErrors } from "~/util/form";
import * as UtilsVUFingerprint from "~/util/vuFingerprint";

import { TYPE } from "./_consts";
import { SelectorsMiddleware } from "./_selectors";

const sagas = [
    TakeLatest(TYPE.RECOVER_PASS_CLEAN, handleClean),
    TakeLatest(TYPE.RECOVER_PASS_NO_TOKEN_STEP1_REQUEST, handleRecoveryPassNoTokenStep1),
    TakeLatest(TYPE.RECOVER_PASS_NO_TOKEN_STEP2_REQUEST, handleRecoveryPassNoTokenStep2),
    TakeLatest(TYPE.RECOVER_PASS_NO_TOKEN_STEP3_REQUEST, handleRecoveryPassNoTokenStep3),
    TakeLatest(TYPE.RECOVER_PASS_NO_TOKEN_STEP4_REQUEST, handleRecoveryPassNoTokenStep4),
    TakeLatest(TYPE.RESEND_VERIFICATION_CODE_REQUEST, handleResend),
];

export default sagas;

function* handleClean() {
    yield Put(ActionsLogin.initLoginFlow());
    yield Put(Push("/"));
}

function* handleRecoveryPassNoTokenStep1(props) {
    const myProps = { ...props };
    delete myProps.type;

    const { formikBag, ...rest } = myProps;
    const { username, email, mobilePhone } = rest;
    const params = {
        _user: username,
        _email: email,
        _mobilePhone: mobilePhone,
    };

    const response = yield Call(SelectorsMiddleware.recoveryPassNoTokenStep1, params);
    if (response.type === RESPONSE_TYPE.WARNING) {
        if (response.data.data.NO_FIELD) {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.data.NO_FIELD,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.RECOVERY_PASSWORD],
                }),
            );
            yield Put(SelectorsActionsRecoverPassword.recoverPassStep1Failure());
        } else if (
            response.data.code === VALIDATION_ERROR ||
            response.data.code === INVALID_EMAIL ||
            response.data.code === API_INVALID_USER_OR_SECOND_FACTOR
        ) {
            formikBag.setErrors(AdjustIdFieldErrors(response.data.data));
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.RECOVERY_PASSWORD],
                }),
            );
            yield Put(SelectorsActionsRecoverPassword.recoverPassStep1Failure());
        } else {
            yield Put(SelectorsActionsRecoverPassword.recoverPassCleanUp());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.EXTERNAL_DASHBOARD],
                }),
            );
        }
    } else {
        const { _exchangeToken } = response.data.data;

        yield Put(
            SelectorsActionsRecoverPassword.recoverPassStep1Success({
                exchangeToken: _exchangeToken,
            }),
        );
        yield Put(Push("/recoveryPassNoToken/step2"));
    }

    formikBag.setSubmitting(false);

    return undefined;
}

function* handleRecoveryPassNoTokenStep2(props) {
    const myProps = { ...props };
    delete myProps.type;
    const exchangeToken = yield Select(SelectorsStoreRecoverPassword.getExchangeToken);

    const { formikBag, ...rest } = myProps;
    const { invitationCode } = rest;

    const params = {
        _code: invitationCode,
    };

    const response = yield Call(SelectorsMiddleware.recoveryPassNoTokenStep2, params, exchangeToken);

    if (response.type === RESPONSE_TYPE.WARNING) {
        if (response.data.code === VALIDATION_ERROR) {
            formikBag.setErrors(AdjustIdFieldErrors(response.data.data));

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.RECOVERY_PASSWORD],
                }),
            );
            yield Put(SelectorsActionsRecoverPassword.recoverPassStep2Failure());
        } else {
            yield Put(SelectorsActionsRecoverPassword.recoverPassCleanUp());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.EXTERNAL_DASHBOARD],
                }),
            );
        }
    } else {
        const { _exchangeToken, mobileNumber } = response.data.data;
        yield Put(
            SelectorsActionsRecoverPassword.recoverPassStep2Success({
                exchangeToken: _exchangeToken,
                mobileNumber,
            }),
        );
        yield Put(Push("/recoveryPassNoToken/step3"));
    }

    formikBag.setSubmitting(false);
    return undefined;
}

function* handleRecoveryPassNoTokenStep3(props) {
    const myProps = { ...props };
    delete myProps.type;

    const exchangeToken = yield Select(SelectorsStoreRecoverPassword.getExchangeToken);

    const { formikBag, ...rest } = myProps;
    const { verificationCode } = rest;

    const params = {
        _verificationCode: verificationCode,
    };

    const response = yield Call(SelectorsMiddleware.recoveryPassNoTokenStep3, params, exchangeToken);

    if (response.type === RESPONSE_TYPE.WARNING) {
        if (response.data.code === VALIDATION_ERROR) {
            formikBag.setErrors(AdjustIdFieldErrors(response.data.data));

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.RECOVERY_PASSWORD],
                }),
            );
            yield Put(SelectorsActionsRecoverPassword.recoverPassStep3Failure());
        } else {
            yield Put(SelectorsActionsRecoverPassword.recoverPassCleanUp());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.EXTERNAL_DASHBOARD],
                }),
            );
        }
    } else {
        const { _exchangeToken } = response.data.data;
        yield Put(SelectorsActionsRecoverPassword.recoverPassStep3Success({ exchangeToken: _exchangeToken }));
        yield Put(Push("/recoveryPassNoToken/step4"));
    }

    formikBag.setSubmitting(false);
    return undefined;
}

function* handleRecoveryPassNoTokenStep4(props) {
    const myProps = { ...props };
    delete myProps.type;
    const exchangeToken = yield Select(SelectorsStoreRecoverPassword.getExchangeToken);

    const { formikBag, ...rest } = myProps;
    const { password, passwordConfirmation } = rest;

    let deviceLocationVU = yield Select(SelectorsStoreSession.getDeviceLocationVU);

    if (!deviceLocationVU || (deviceLocationVU.latitude === null && deviceLocationVU.longitude === null)) {
        deviceLocationVU = yield Call(UtilsVUFingerprint.getDeviceLocationVU);
    }

    const fingerprint = yield Call(UtilsVUFingerprint.getFingerprintVU);
    let fingerprintData = yield Call(UtilsVUFingerprint.getFingerprintDeviceInfoVU);

    if (UtilsDevice.mobileOS() === DEVICE_MOBILE.IOS) {
        fingerprintData = JSON.stringify(fingerprintData);
    }

    const params = {
        _deviceFingerprintData: fingerprintData,
        _deviceLocationVU: deviceLocationVU,
        _fingerprint: fingerprint,
        _password: password,
        _passwordConfirmation: passwordConfirmation,
    };

    const response = yield Call(SelectorsMiddleware.recoveryPassNoTokenStep4, params, exchangeToken);

    if (response.type === RESPONSE_TYPE.WARNING) {
        if (response.data.code === VALIDATION_ERROR) {
            formikBag.setErrors(AdjustIdFieldErrors(response.data.data));

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.RECOVERY_PASSWORD],
                }),
            );
            yield Put(SelectorsActionsRecoverPassword.recoverPassStep4Failure());
        } else {
            yield Put(SelectorsActionsRecoverPassword.recoverPassCleanUp());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.EXTERNAL_DASHBOARD],
                }),
            );
        }
    } else {
        yield Put(SelectorsActionsRecoverPassword.recoverPassStep4Success());
        yield Put(Push("/recoveryPassNoToken/step5"));
    }

    formikBag.setSubmitting(false);
    return undefined;
}

function* handleResend(props) {
    const exchangeToken = yield Select(SelectorsStoreRecoverPassword.getExchangeToken);
    const response = yield Call(SelectorsMiddleware.resendVerificationCode, props, exchangeToken);

    if (response) {
        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(SelectorsActionsRecoverPassword.recoverPassResendFailure());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.RECOVERY_PASSWORD],
                }),
            );
        } else if (response.status === RESPONSE_TYPE.UNAUTHORIZED) {
            yield Put(Push("/"));
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.EXTERNAL_DASHBOARD],
                }),
            );
            yield Put(SelectorsActionsRecoverPassword.recoverPassResendFailure());
        } else {
            const { _exchangeToken } = response.data.data;
            yield Put(SelectorsActionsRecoverPassword.recoverPassResendSuccess({ exchangeToken: _exchangeToken }));
        }
    }
}
