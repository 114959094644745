import React from "react";

import { push as Push } from "connected-react-router";
import { Field, Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import * as Yup from "yup";

import { CREDENTIAL_TYPE_PASSWORD, EMPTY_STR, SCOPE } from "~/constants";
import Container from "~/containers/External/Dash";
import { SelectorsStore as SelectorsStoreBiometric } from "~/store/biometric";
import { SelectorsAction, SelectorsStore } from "~/store/changeMyPhone";
import { SelectorsStore as SelectorsStoreEnrollment } from "~/store/enrollment";
import { SelectorsAction as SelectorsActionSession } from "~/store/session";
import DeviceUtils from "~/util/device";
import * as UtilI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import LinkButton from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import TextField from "~/pages/_components/fields/TextField";
import Credential from "~/pages/_components/fields/credentials/Credential";
import WithExchangeToken from "~/pages/_components/withExchangeToken";

import CancelConfirmationModal from "~/pages/enrollment/_CancelConfirmationModal";

import Style from "./ChangePhoneStep1.rules.scss";

export const { NAME } = Style;

const FORM_ID = "changePhone.step1";

export const PROP = {
    types: {
        fetching: PropTypes.bool,
        fetchingBiometric: PropTypes.bool,
        fetchingEnrollment: PropTypes.bool,
        isSubmitting: PropTypes.bool,
        ...TypesRedux,
    },
    defaults: {
        fetching: false,
        fetchingBiometric: false,
        fetchingEnrollment: false,
        isSubmitting: false,
        ...DefaultsRedux,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {
        show: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;

        if (DeviceUtils.isMobileNative()) {
            dispatch(SelectorsActionSession.acceptMobileRequiredPermissions());

            navigator.splashscreen.hide();
        }
    }

    handleConfirmation = () => {
        const { dispatch } = this.props;
        dispatch(Push("/"));
    };

    handleCancelation = () => {
        this.setState({ show: false });
    };

    handleConfirmation = () => {
        const { dispatch } = this.props;
        dispatch(Push("/"));
    };

    handleRecoverPassword = () => {
        const { dispatch } = this.props;

        dispatch(Push("/recoveryPassNoToken"));
    };

    showModal = () => {
        this.setState({ show: true });
    };

    render() {
        const { fetching, fetchingBiometric, fetchingEnrollment, isSubmitting } = this.props;
        const { show } = this.state;
        const fetchingPage = fetchingBiometric || fetching || fetchingEnrollment;

        return (
            <div id={Style.NAME} className="enrollment page-login">
                <CancelConfirmationModal
                    show={show}
                    handleCancelation={this.handleCancelation}
                    handleConfirmation={this.handleConfirmation}
                />
                <Container
                    name={NAME}
                    head-onClose={this.showModal}
                    wait={fetchingPage}
                    scopeToShowNotification={SCOPE.CHANGE_PHONE}>
                    <div className="login-step step-1">
                        <div className="login-header">
                            <span className="login-header-text">
                                <I18n id={`${FORM_ID}.title`} />
                            </span>
                        </div>
                        <div className="login-content">
                            <div className="login-row simple-row">
                                <div className="login-image">
                                    <Image src="login-ibanca.svg" className="login-image-icon" />
                                </div>
                            </div>
                            <div className="login-row main-row">
                                <Form className="login-form">
                                    <div className="login-form-field">
                                        <div className="login-form-field-block">
                                            <div className="login-form-field-image">
                                                <Image
                                                    src="login-ibanca.svg"
                                                    className="login-form-field-icon user-icon"
                                                />
                                            </div>
                                            <Field
                                                idForm={FORM_ID}
                                                name="username"
                                                type="text"
                                                component={TextField}
                                                className="login-form-input"
                                                autoFocus={DeviceUtils.isDisplayDesktop()}
                                                hidelabel
                                            />
                                        </div>
                                        <span className="login-form-field-validation" />
                                    </div>
                                    <div className="login-form-field">
                                        <div className="login-form-field-block">
                                            <div className="login-form-field-image">
                                                <Image
                                                    src="lock-icon.svg"
                                                    className="login-form-field-icon lock-icon"
                                                />
                                            </div>
                                            <Field
                                                idForm={FORM_ID}
                                                name="password"
                                                className="login-form-input input-icon"
                                                hidelabel
                                                component={Credential}
                                                autoFocus={false}
                                                value={EMPTY_STR}
                                                type={CREDENTIAL_TYPE_PASSWORD}
                                            />
                                        </div>
                                        <span className="login-form-field-validation" />
                                    </div>
                                    <div className="login-form-field button-field">
                                        <Button
                                            className="login-form-button main-button"
                                            bsStyle="primary"
                                            label="global.continue"
                                            loading={isSubmitting}
                                            type="submit"
                                        />
                                    </div>
                                    {DeviceUtils.isMobileNative() && (
                                        <div className="link">
                                            <LinkButton onClick={() => this.handleRecoverPassword()}>
                                                <I18n id="login.step2.forgotPassword" />
                                            </LinkButton>
                                        </div>
                                    )}
                                </Form>
                            </div>
                            <div className="enrollment-content-dialog">
                                <span className="enrollment-content-dialog-text" />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default HighOrder(
    Connect((store) => ({
        fetching: SelectorsStore.fetching(store),
        fetchingBiometric: SelectorsStoreBiometric.fetching(store),
        fetchingEnrollment: SelectorsStoreEnrollment.fetching(store),
    })),
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            username: EMPTY_STR,
            password: EMPTY_STR,
        }),
        validationSchema: () =>
            Yup.object().shape({
                username: Yup.string().required(UtilI18n.get(`${FORM_ID}.username.required`)),
                password: Yup.string().required(UtilI18n.get(`${FORM_ID}.password.required`)),
            }),
        handleSubmit: (data, formikBag) => {
            const { dispatch } = formikBag.props;
            const { username, password } = data;
            dispatch(SelectorsAction.changePhoneStep1({ username, password, formikBag }));
        },
    }),
    WithExchangeToken,
)(Component);
