import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { EMPTY_STR, INVALID_TOKEN } from "~/constants";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import {
    SelectorsStore as SelectorsStoreWMBuyFunds,
    SelectorsAction as SelectorsActionWMBuyFunds,
} from "~/store/wm/funds/buy";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Token from "~/components/Token/Token";

import FormatNumber from "~/pages/wm/funds/_component/FormatNumber";

import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        isSubmitting: PropTypes.bool,
    },
    defaults: {
        isSubmitting: false,
    },
};

export function Component(props) {
    const { isSubmitting, location, debitAccountAlias, paperName, currency, amount } = props;
    const isFromTransactionsHistory = location.pathname.includes("/transaction/");

    return (
        <section id={Style.ID}>
            <Form>
                <div className="transfer-block">
                    <div className="transfer-data transfer-data-highlight">
                        <div className="data-label">
                            <I18n id="wm.buy.label" />
                        </div>
                        <span className="data-amount">
                            <span>{paperName}</span>
                        </span>
                    </div>

                    <div className="transfer-data transfer-data-highlight">
                        <div className="data-label">
                            <I18n id="wm.buy.accounts.ticket" />
                        </div>
                        <span className="data-amount">
                            <span>{debitAccountAlias}</span>
                        </span>
                    </div>
                    <div className="transfer-data transfer-data-highlight">
                        <div className="data-label">
                            <I18n id="wm.buy.debitAccount.label" />
                        </div>
                        <span className="data-amount">
                            <FormatNumber amount={parseFloat(amount, 10)} currency={currency} />
                        </span>
                    </div>
                </div>
                <Token labelButton="global.send" isSubmitting={isSubmitting}>
                    <Button size="sm" variant="secondary" disabled={isSubmitting} onClick={handleCancel}>
                        <I18n id="global.cancel" />
                    </Button>
                </Token>
            </Form>
        </section>
    );

    function handleCancel() {
        const { dispatch } = props;
        if (isFromTransactionsHistory) {
            dispatch(GoBack());
        } else {
            dispatch(SelectorsActionWMBuyFunds.previewCancelRequest());
        }
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    amount: SelectorsStoreWMBuyFunds.getAmount(store),
    currency: SelectorsStoreWMBuyFunds.getCurrency(store),
    debitAccountAlias: SelectorsStoreWMBuyFunds.getDebitAccountAlias(store),
    disclaimer: SelectorsStoreWMBuyFunds.getDisclaimer(store),
    idDebitAccount: SelectorsStoreWMBuyFunds.getIdDebitAccount(store),
    idPaper: SelectorsStoreWMBuyFunds.getIdPaper(store),
    isSubmitting: SelectorsStoreWMBuyFunds.getIsSubmitting(store),
    limitDate: SelectorsStoreWMBuyFunds.getlimitDate(store),
    orderCompleted: SelectorsStoreWMBuyFunds.getOrderCompleted(store),
    paperName: SelectorsStoreWMBuyFunds.getPaperName(store),
    transaction: SelectorsStoreWMBuyFunds.getTransaction(store),
    user: SelectorsStoreSession.getUser(store),
});

export default HighOrder(
    Connect(mapStateToProps),
    WithRouter,
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        validationSchema: (props) => {
            const { user } = props;
            return Yup.object().shape({
                otp: user.needsBiometric
                    ? Yup.string().nullable()
                    : Yup.string()
                          .trim()
                          .required(UtilsI18n.get("client.wm.funds.sell.step2.otp.required"))
                          .min(6, UtilsI18n.get(`returnCode.${INVALID_TOKEN}`)),
            });
        },
        handleSubmit: (data, formikBag) => {
            const {
                amount,
                currency,
                debitAccountAlias,
                disclaimer,
                dispatch,
                history,
                idDebitAccount,
                idPaper,
                limitDate,
                orderCompleted,
                paperName,
                transaction: { idTransaction },
            } = formikBag.props;

            const { otp } = data;

            const isFromTransactionsHistory = history.location.pathname.includes("/transaction/");
            if (isFromTransactionsHistory) {
                dispatch(
                    SelectorsActionWMBuyFunds.signTransaction({
                        idForm: null,
                        idActivity: "client.wm.funds.buy.send",
                        idTransaction,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            } else {
                dispatch(
                    SelectorsActionWMBuyFunds.paperSendFormRequest({
                        _otp: otp,
                        amount,
                        currency,
                        debitAccountAlias,
                        disclaimer,
                        idDebitAccount,
                        idPaper,
                        limitDate,
                        orderCompleted,
                        paperName,
                        valueDate: new Date(),
                    }),
                );
            }
        },
    }),
)(Component);
