import React from "react";

import Image from "~/components/Image";

import Style from "./ServicePayments.rules.scss";
import Widget from "./_common/_Widget";

export const { TYPE, NAME } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    return (
        <Widget
            {...props}
            id={Style.ID}
            header-title="desktop.widgets.servicePayments.title"
            header-href="/servicePayment"
            withoutArrow
            widgetAlternative
        />
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
