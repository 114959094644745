import React from "react";

import { Categorize, Prefix } from "~/util/prop";

import Page from "../_Page";
import Style from "./index.rules.scss";

import Internal, { PROP as PROP_INTERNAL } from "..";

export const { NAME, TYPE } = Style;
export const PROP = {
    types: {
        ...PROP_INTERNAL.types,
    },
    defaults: {
        ...PROP_INTERNAL.defaults,
    },
};

/** This is  a description. */
export function Component(props) {
    const { name, children, header, head, wait, scopeToShowNotification, ...rest } = Categorize(props);
    return (
        <Internal
            {...Prefix("head", head)}
            id={Style.ID}
            name={NAME}
            wait={wait}
            scopeToShowNotification={scopeToShowNotification}>
            <Page {...rest} name={name} header={header}>
                {children}
            </Page>
        </Internal>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
