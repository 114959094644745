import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import {
    SelectorsStore as SelectorsStoreWMShares,
    SelectorsAction as SelectorsActionWMShares,
} from "~/store/wm/shares";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import HighOrder from "~/components/HighOrder";

import SearchShares from "~/pages/wm/otherInvestments/shares/_components/SearchShares";

import Style from "./BuyShares.rules.scss";
import FormEdit from "./Step1Edit/Step1Edit";
import FormPreview from "./Step2Preview/Step2Preview";
import FormView from "./Step3View/Step3View";

export const { CLASS, NAME } = Style;

export const PROP = {
    types: {
        ...TypesRedux,
        dispatch: PropTypes.func.isRequired,
        fetching: PropTypes.bool,
        mode: PropTypes.string.isRequired,
    },

    defaults: {
        ...DefaultsRedux,
        fetching: false,
    },
};

export function Component(props) {
    const { dispatch, fetching, mode } = props;

    return (
        <Container
            className={CLASS}
            name={NAME}
            wait={fetching}
            step={mode === MODE.COMPONENT ? MODE.EDIT : mode}
            head-onBack={handleBack}
            head-onBackWeb={handleBack}
            head-title={UtilsI18n.get(
                `${mode !== MODE.COMPONENT ? `client.wm.shares.buy.title` : `wm.shares.buy.search.title`}`,
            )}
            scopeToShowNotification={SCOPE.BUY_SHARES}>
            <React.Fragment>
                {mode === MODE.EDIT && <FormEdit />}
                {mode === MODE.COMPONENT && <SearchShares />}
                {mode === MODE.PREVIEW && <FormPreview />}
                {mode === MODE.VIEW && <FormView ticketConfirmation />}
            </React.Fragment>
        </Container>
    );

    function handleBack() {
        const { history } = props;

        if (mode === MODE.VIEW) {
            dispatch(SelectorsActionWMShares.restartFlow());
        } else if (mode === MODE.EDIT) {
            history.goBack();
        } else {
            dispatch(SelectorsActionWMShares.goStepBack());
        }
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        fetching: SelectorsStoreWMShares.getIsFetching(store),
        mode: SelectorsStoreWMShares.getMode(store),
    };
};

export default HighOrder(Connect(mapStateToProps))(Component);
