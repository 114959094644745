// TODO: replace this with FormattedDate? or the other way around.
import React from "react";

import { parseISO as Parse, isDate as DateDetect, isValid as DateValidate } from "date-fns";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { Categorize } from "~/util/prop";

import Element from "./Component";
import Namespace from "./DataDate.scss";

export const { NAME, TYPE, TAG, CLASS } = Namespace;
export const PROP = {
    types: {
        value: PropTypes.string.isRequired,
        lang: PropTypes.string,
        raw: PropTypes.bool,
        /**
         * The formatting style for the date
         * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
         */
        "type-timeZoneName": PropTypes.oneOf(["long", "short"]),
        "type-year": PropTypes.oneOf([false, "numeric", "2-digit"]),
        "type-month": PropTypes.oneOf([false, "numeric", "2-digit", "long", "short", "narrow"]),
        "type-day": PropTypes.oneOf([false, "numeric", "2-digit"]),
        "type-hour": PropTypes.oneOf([false, "numeric", "2-digit"]),
        "type-hour12": PropTypes.bool,
        "type-timeZone": PropTypes.string,
        "type-minute": PropTypes.oneOf([false, "numeric", "2-digit"]),
        "type-second": PropTypes.oneOf([false, "numeric", "2-digit"]),
    },
    defaults: {
        lang: undefined,
        raw: false,
        "type-hour12": false,
        "type-year": "numeric",
        "type-month": "2-digit",
        "type-day": "2-digit",
        // Don't show time by default.
        "type-timeZone": "UTC",
        "type-timeZoneName": undefined,
        "type-hour": undefined,
        "type-minute": undefined,
        "type-second": undefined,
    },
};

// TODO: This should be in constants.
export const LANG = Object.freeze({
    en: "en-US",
    es: "es-UY",
    pt: "pt-BR",
});

export function Component(props) {
    const { value, format, lang, type, notAvailable, raw, ...rest } = Categorize(props);
    const code = LANG[lang];
    if (!code) {
        throw new Error(`Language "${lang}" is not in the supported list.`);
    }
    // if falsy values are sent, ignore them.
    const opt = Object.entries(type).reduce((acc, [key, val]) => (!val ? acc : { ...acc, [key]: val }), {});
    const pre = DateDetect(value) ? value : Parse(value);
    const date = DateValidate(pre) ? new Intl.DateTimeFormat(code, opt).format(pre) : notAvailable;
    if (raw) {
        return date;
    }
    return (
        <Element {...rest} value={value} tag={TAG} name={NAME} type={TYPE}>
            <span>{date}</span>
        </Element>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store, props) => {
    const { lang } = props;
    return {
        notAvailable: StoreI18n.getMessage(store, "data.notAvailable.short"),
        lang: lang || StoreI18n.getLang(store) || window.navigator.language.slice(0, 2),
    };
};

export default Connect(mapStateToProps)(Component);
