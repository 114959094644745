import React from "react";

import { goBack as GoBack } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";

import { SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import {
    SelectorsStore as SelectorsStoreTransactionsHistory,
    SelectorsAction as SelectorsActionTransactionsHistory,
} from "~/store/transactionsHistory";
import FormView from "./Step3View/Step3ViewHistory";

export const NAME = "Transaction";

export const PROP = {
    types: {
        isFetching: PropTypes.bool,
    },
    defaults: {
        isFetching: false,
    },
};

export function Component(props) {
    const { isFetching, mode, transaction } = props;
    return (
        <React.Fragment>
            <Container
                name={NAME}
                wait={isFetching}
                step={mode}
                head-onBack={handleBack}
                head-title={transaction?.activityName}
                scopeToShowNotification={SCOPE.FORM}>
                {mode === MODE.VIEW && <FormView transaction={transaction} />}
            </Container>
        </React.Fragment>
    );
    function handleBack() {
        const { dispatch } = props;
        dispatch(GoBack());
    }
}

const mapStateToProps = (store) => ({
    isFetching: SelectorsStoreTransactionsHistory.getFetching(store),
    transaction: SelectorsStoreTransactionsHistory.getTransaction(store),
    mode: SelectorsStoreTransactionsHistory.getMode(store),
});

export default Compose(Connect(mapStateToProps))(Component);
