import React from "react";

import { push as Push } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { LEVEL, SOFT_TOKEN_TIMER, BIOMETRIC_ID_USER, EVENT_BACKBUTTON, SOFT_TOKEN_BIOMETRIC, SCOPE } from "~/constants";
import Container from "~/containers/External/Dash";
import { SelectorsAction, SelectorsStore, TYPE_VERIFY_BIOMETRIC } from "~/store/biometric";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import UtilsDevice from "~/util/device";
import * as UtilI18n from "~/util/i18n";
import { Types as TypesHtmlElement, Defaults as DefaultsHtmlElement } from "~/util/prop/html-element";
import * as UtilStorage from "~/util/secureStorage";

import I18n from "~/components/I18n";
import TokenAnimation from "~/components/TokenAnimation";
import PageLoading from "~/pages/_components/PageLoading";
import PasswordModal from "~/pages/_components/PasswordModal";

import Style from "./SoftOTP.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...TypesHtmlElement,
        fetching: PropTypes.bool,
        statusVerifyBiometric: PropTypes.string,
        isTrusted: PropTypes.bool,
        webToken: PropTypes.number,
    },
    defaults: {
        ...DefaultsHtmlElement,
    },
};

export const STATE = {
    /** Whether the TokenAnimation is enabled or not */
    enabled: false,
    showFingerPrint: true,
    passwordModalUser: null,
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    // TODO: this should be set on constants, and imported only once instead of run everytime, unless  really needed to.
    static IS_MOBILE_NATIVE = UtilsDevice.isMobileNative();

    /** Amount of time in milliseconds to wait after the notification from biometry hides before the animation */
    static NOTIFY_WAIT = 500;

    // TODO: shouldn't this come from constants?
    static FORM_ID = "soft.otp";

    static ROUTE_BACK = "/";

    state = STATE;

    listenerAndroidBack = null;

    componentDidMount() {
        const { dispatch, isTrusted } = this.props;
        this.listenerAndroidBack = this.listenerAndroidBackRegister(Component.ROUTE_BACK);
        // User is not enrolled or not in mobile device.
        if (!isTrusted || !Component.IS_MOBILE_NATIVE) {
            // Este caso no deberia pasar ya que se controla en el externalDashboard
            dispatch(Push(Component.ROUTE_BACK)); // Si falla el plugin vuelvo al externalDahsboard
            // TODO: Generar un DBVS para este mensaje.
            dispatch(
                SelectorsActionNotification.showNotification("Is not Trusted", LEVEL.ERROR, ["externalDashboard"]),
            );
            return null;
        }
        this.handlePasswordModalGetUser();
        this.handleBiometricInit();
        return true;
    }

    componentDidUpdate(prevProps) {
        const { statusVerifyBiometric } = this.props;
        // Do nothing if there's still no response from the biometric
        return statusVerifyBiometric !== prevProps.statusVerifyBiometric && this.handleBiometryUpdate();
    }

    componentWillUnmount() {
        this.listenerAndroidBackUnregister(this.listenerAndroidBack);
        clearTimeout(this.timer);
    }

    // -----------------------------------------------------------------------------------------------------------------

    // TODO: Make this block part of device utilities.
    listenerAndroidBackRegister = (route) => {
        // TODO: this validation should only consider Android
        if (!Component.IS_MOBILE_NATIVE) {
            return null;
        }
        const { dispatch } = this.props;
        const listener = () => dispatch(Push(route));
        document.addEventListener(EVENT_BACKBUTTON, listener);
        return listener;
    };

    // TODO: Make this block part of device utilities.
    listenerAndroidBackUnregister = (listener) => document.removeEventListener(EVENT_BACKBUTTON, listener);

    handlePasswordModalGetUser = async () => {
        const passwordModalUser = await UtilStorage.get(BIOMETRIC_ID_USER);
        return this.setState({ passwordModalUser });
    };

    handleBiometricInit = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsAction.verifyBiometric({ liveness: true, pathFrom: SOFT_TOKEN_BIOMETRIC }));
    };

    handleBiometryUpdate = () => {
        const { dispatch, statusVerifyBiometric: status } = this.props;
        const { SUCCESS, ERROR, CANCEL } = TYPE_VERIFY_BIOMETRIC;
        // User canceled or there was an error, redirect back.
        if (status === CANCEL || status === ERROR) {
            if (status === ERROR) {
                const message = UtilI18n.get("verify.biometric.error");
                // TODO: @ssosa, what's the ["externalDashboard"] about? shouldn't it be a constant as well?
                dispatch(SelectorsActionNotification.showNotification(message, LEVEL.ERROR, ["externalDashboard"]));
            }
            return dispatch(Push(Component.ROUTE_BACK));
        }
        if (status === SUCCESS) {
            // TODO: Find a way to wait for the FaceID toast to finish and then run the generate.
            return setTimeout(this.handleBiometryGenerate, Component.NOTIFY_WAIT);
        }
        return null;
    };

    handleBiometryGenerate = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsAction.generateWebToken());
    };

    backButtonAction = () => {
        const { dispatch } = this.props;
        dispatch(Push("/"));
    };

    render() {
        const { passwordModalUser } = this.state;
        const { webToken, askPassword } = this.props;
        const className = `login ${Style.CLASS}`;

        return (
            <PageLoading loading={!webToken && !askPassword}>
                <PasswordModal isNotLogged username={passwordModalUser} />
                <Container name={NAME} head-onClose={this.backButtonAction} scopeToShowNotification={SCOPE.SOFT_OTP}>
                    <div className={className}>
                        {webToken && (
                            <div className="otp">
                                <div className="otp-header">
                                    <span className="otp-header-text">
                                        <I18n id={`${Component.FORM_ID}.title`} />
                                    </span>
                                </div>
                                <div className="otp-content">
                                    <div className="otp-row simple-row">
                                        <div className="otp-image">
                                            <svg className="otp-image-icon" />
                                        </div>
                                    </div>
                                </div>
                                <TokenAnimation
                                    enabled={!!webToken}
                                    duration={SOFT_TOKEN_TIMER}
                                    onComplete={this.handleBiometryGenerate}
                                    token={webToken}
                                />
                            </div>
                        )}
                    </div>
                    <div className="enrollment-content-dialog">
                        <span className="enrollment-content-dialog-text" />
                    </div>
                </Container>
            </PageLoading>
        );
    }
}

const mapStateToProps = (store) => ({
    fetching: SelectorsStore.fetching(store),
    askPassword: SelectorsStore.askPassword(store),
    statusVerifyBiometric: SelectorsStore.statusVerifyBiometric(store),
    isTrusted: SelectorsStoreConfig.getIsTrusted(store),
    webToken: SelectorsStoreSession.getWebToken(store),
});

export default Connect(mapStateToProps)(Component);
