import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import {
    SelectorsStore as SelectorsStoreUploadPaymentOrders,
    SelectorsAction as SelectorsActionUploadPaymentOrders,
} from "~/store/factoring/uploadPaymentOrders";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";
import Ticket from "~/components/Ticket/Ticket";

import UploadPaymentOrdersDisplayList from "../_components/UploadPaymentOrdersDisplayList";
import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        isFromBackoffice: PropTypes.bool,
        transaction: PropTypes.shape({
            executing: PropTypes.bool,
            idTransaction: PropTypes.string,
            idTransactionStatus: PropTypes.string,
            transactionData: PropTypes.object,
            valueDateAsString: PropTypes.string,
        }),
        paymentOrdersList: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string,
                number: PropTypes.string,
                supplier: PropTypes.string,
                issueDate: PropTypes.string,
                dueDate: PropTypes.string,
                currency: PropTypes.string,
                amount: PropTypes.string,
            }),
        ),
    },
    defaults: {
        isFromBackoffice: false,
    },
};

export function Component(props) {
    const { isFromBackoffice, ticketConfirmation, transaction, dispatch } = props;
    const paymentOrdersList = transaction && transaction.data ? transaction.data.paymentOrdersList : null;
    const totalizers = transaction && transaction.data ? transaction.data.totalizersList : null;

    const handleDownloadClick = (e, format) => {
        e.preventDefault();
        dispatch(
            SelectorsActionUploadPaymentOrders.downloadPaymentOrdersRequest({
                format,
                paymentOrdersList,
                totalizersList: totalizers,
            }),
        );
    };

    return (
        <section id={Style.ID}>
            <Ticket
                ticketConfirmation={ticketConfirmation}
                transaction={transaction}
                hasMobileComponent
                handleReject={handleReject}
                handleSign={handleSign}>
                <div className="data-wrapper">
                    <div className="container-header">
                        <span className="data-label">
                            <I18n id="client.factoring.uploadSuppliers.transaction.step3.extraData" />
                        </span>
                        {paymentOrdersList && (
                            <Button id="export" variant="link" onClick={(e) => handleDownloadClick(e, "xls")}>
                                {UtilsI18n.get("client.factoring.paymentOrders.export.label")}
                            </Button>
                        )}
                    </div>
                    <UploadPaymentOrdersDisplayList isFromBackoffice={isFromBackoffice} />
                </div>
            </Ticket>
        </section>
    );

    function handleReject(formikBag) {
        const { idTransaction } = transaction;

        dispatch(
            SelectorsActionUploadPaymentOrders.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        dispatch(
            SelectorsActionUploadPaymentOrders.signTransactionPreview({ paymentOrdersList, totalizers, transaction }),
        );
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        paymentOrdersList: SelectorsStoreUploadPaymentOrders.getPaymentOrders(store),
        transaction: SelectorsStoreUploadPaymentOrders.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
