import { LOCATION_CHANGE } from "connected-react-router";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.LOAD_LIST_PAYMENT_ORDERS_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
                firstTime: action.firstTime,
                moreOrders: action.moreOrders,
                filters: action.filters || storeProp.filters,
            };
        case TYPE.LOAD_LIST_PAYMENT_ORDERS_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
            };
        case TYPE.LOAD_LIST_PAYMENT_ORDERS_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                paymentOrderList: action.paymentOrderList,
                offset: action.nextOffset,
                moreLines: action.moreLines,
            };
        case TYPE.LOAD_LIST_SUPPLIERS_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
                firstTime: action.firstTime,
                moreOrders: action.moreOrders,
                filtersSuppliers: action.filters || storeProp.filtersSuppliers,
            };
        case TYPE.LOAD_LIST_SUPPLIERS_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
            };
        case TYPE.LOAD_LIST_SUPPLIERS_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                suppliersList: storeProp.moreOrders
                    ? storeProp.suppliersList.concat(action.suppliers)
                    : action.suppliers,
                pageNumber: storeProp.moreOrders ? action.pageNumber : 1,
                moreLines: action.moreLines,
            };
        case TYPE.EXPORT_PAYMENT_ORDERS_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.EXPORT_PAYMENT_ORDERS_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
            };
        case TYPE.EXPORT_PAYMENT_ORDERS_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
            };
        case LOCATION_CHANGE: {
            return {
                ...PROP.defaults,
            };
        }
        case TYPE.RESET_FILTERS:
            return { ...storeProp, filters: PROP.defaults.filters, filtersSuppliers: PROP.defaults.filtersSuppliers };
        default:
            return {
                ...storeProp,
            };
    }
};
