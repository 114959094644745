import React from "react";

import { connect as Connect } from "react-redux";

import { SelectorsStore as SelectorsStoreForm, PROP as PropForm } from "~/store/form";
import { ToRequired } from "~/util/prop";

import I18n from "~/components/I18n";
import FormattedAmount from "~/pages/_components/FormattedAmount";

export const NAME = "RequestLoan";

export const PROP = {
    types: {
        ...ToRequired(PropForm.types, ["previewData"]),
    },
    defaults: {
        ...PropForm.defaults,
    },
};

export function Component(props) {
    const {
        previewData: {
            loan,
            amountResponse,
            term,
            destination,
            otherDestination,
            contactForm,
            contactPhone,
            contactEmail,
            contactTime,
        },
    } = props;

    const renderAmount = (className, label, amountToRender) => (
        <div className={className}>
            <span className="data-label">
                <I18n id="forms.requestLoan.confirmation.amount" />{" "}
            </span>
            <FormattedAmount currency={amountToRender.currency} quantity={amountToRender.quantity} />
        </div>
    );

    return (
        <React.Fragment>
            <p className="text-lead">
                <I18n id="forms.requestLoan.confirmation.title" />
            </p>
            <div className="transfer-block">
                <div className="transfer-data transfer-data-flow">
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="forms.requestLoan.confirmation.loan" />{" "}
                        </span>
                        <span className="data-name">{loan}</span>
                    </div>
                </div>
            </div>
            {renderAmount("data-wrapper", "amount", amountResponse)}
            {term && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.requestLoan.confirmation.term" />{" "}
                    </span>
                    <span className="data-text">{term}</span>
                </div>
            )}
            {destination && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.requestLoan.confirmation.destination" />{" "}
                    </span>
                    <span className="data-text">{destination}</span>
                </div>
            )}
            {otherDestination && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.requestLoan.confirmation.otherDestination" />{" "}
                    </span>
                    <span className="data-text">{otherDestination}</span>
                </div>
            )}
            {contactForm && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.requestLoan.confirmation.contactForm" />{" "}
                    </span>
                    <span className="data-text">{contactForm}</span>
                </div>
            )}
            {contactPhone && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.requestLoan.confirmation.contactPhone" />{" "}
                    </span>
                    <span className="data-text">{contactPhone}</span>
                </div>
            )}
            {contactEmail && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.requestLoan.confirmation.contactEmail" />{" "}
                    </span>
                    <span className="data-text">{contactEmail}</span>
                </div>
            )}
            {contactTime && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.requestLoan.confirmation.contactTime" />{" "}
                    </span>
                    <span className="data-text">{contactTime}</span>
                </div>
            )}
        </React.Fragment>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (state) => ({
    name: SelectorsStoreForm.getName(state),
    previewData: SelectorsStoreForm.getPreviewData(state),
});

export default Connect(mapStateToProps)(Component);
