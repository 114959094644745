import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import {
    SelectorsStore as SelectorsStoreUploadPaymentOrders,
    SelectorsAction as SelectorsActionUploadPaymentOrders,
} from "~/store/factoring/uploadPaymentOrders";
import { SelectorsAction as SelectorsActionMultilineFile } from "~/store/multilinefile";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import HighOrder from "~/components/HighOrder";

import FormEdit from "./Step1Edit/Step1Edit";
import FormPreview from "./Step2Preview/Step2Preview";
import FormView from "./Step3View/Step3View";

export const NAME = "UploadPaymentOrders";

export const PROP = {
    types: {
        isFetching: PropTypes.bool,
        mode: PropTypes.string.isRequired,
        ...TypesRedux,
    },
    defaults: {
        isFetching: false,
        ...DefaultsRedux,
    },
};

/**
 * Formulario: Importar órdenes de pago.
 * Tipo de formulario: No dinamico.
 *
 * Se usa la misma estructura que formularios dinamicos para mantener la consistencia en la aplicacion.
 *
 * Este componente deja disponible la estructura basica para un formulario:
 * - Container.
 * - Steps.
 * Se permite implementar:
 * - handleBack.
 */

export function Component(props) {
    const { isFetching, mode } = props;

    return (
        <Container
            head-onBack={(mode !== MODE.EDIT && handleBack) || undefined}
            head-onBackWeb={mode === MODE.EDIT && handleBack}
            head-title={UtilsI18n.get("client.factoring.uploadPaymentOrders.title")}
            name={NAME}
            scopeToShowNotification={SCOPE.FACTORING_UPLOAD_PAYMENT_ORDERS}
            step={mode}
            wait={isFetching}>
            {mode === MODE.EDIT && <FormEdit />}
            {mode === MODE.PREVIEW && <FormPreview />}
            {mode === MODE.VIEW && <FormView ticketConfirmation />}
        </Container>
    );

    function handleBack() {
        const { dispatch, history, idFile } = props;

        if (mode === MODE.VIEW) {
            dispatch(SelectorsActionUploadPaymentOrders.restartFlow());
        } else if (mode === MODE.EDIT) {
            if (idFile) {
                dispatch(SelectorsActionMultilineFile.deleteFileRequest({ idFile }));
            }

            history.goBack();
        } else {
            dispatch(SelectorsActionUploadPaymentOrders.closeConfirmation());
        }
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        idFile: SelectorsStoreUploadPaymentOrders.getIdFile(store),
        isFetching: SelectorsStoreUploadPaymentOrders.isFetching(store),
        mode: SelectorsStoreUploadPaymentOrders.getMode(store),
    };
};

export default HighOrder(Connect(mapStateToProps))(Component);
