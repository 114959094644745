import PropTypes from "prop-types";

import { EMPTY_STR, ZERO_NUMBER } from "~/constants";
import { MODE } from "~/constants/form";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "wm.bonds";

export const PATH = {
    SEARCH_BY_ISIN: "/client.wm.searchByIsinTicker",

    BUY_BONDS_PRE_REQUEST: "/client.wm.bonds.buy.pre",
    BUY_BONDS_PREVIEW_REQUEST: "/client.wm.bonds.buy.preview",
    BUY_BONDS_REQUEST: "/client.wm.bonds.buy.send",

    SELL_BONDS_PRE_REQUEST: "/client.wm.bonds.sell.pre",
    SELL_BONDS_PREVIEW_REQUEST: "/client.wm.bonds.sell.preview",
    SELL_BONDS_REQUEST: "/client.wm.bonds.sell.send",

    GET_PAPER: "/client.wm.getPaper",
};

export const TYPE = FactoryType(NAME, [
    /* SEARCH BY ISIN */
    "SEARCH_BY_ISIN_REQUEST",
    "SEARCH_BY_ISIN_SUCCESS",
    "SEARCH_BY_ISIN_FAILURE",
    /* BUY BONDS */
    "BUY_BONDS_PRE_REQUEST",
    "BUY_BONDS_PRE_SUCCESS",
    "BUY_BONDS_PRE_FAILURE",
    "BUY_BONDS_PREVIEW_REQUEST",
    "BUY_BONDS_PREVIEW_SUCCESS",
    "BUY_BONDS_PREVIEW_FAILURE",
    "BUY_BONDS_SEND_FORM",
    "BUY_BONDS_SEND_FORM_FAILURE",
    "BUY_BONDS_SEND_FORM_SUCCESS",
    /* SELL BONDS */
    "SELL_BONDS_PRE_REQUEST",
    "SELL_BONDS_PRE_SUCCESS",
    "SELL_BONDS_PRE_FAILURE",
    "SELL_BONDS_PREVIEW_REQUEST",
    "SELL_BONDS_PREVIEW_SUCCESS",
    "SELL_BONDS_PREVIEW_FAILURE",
    "SELL_BONDS_SEND_FORM",
    "SELL_BONDS_SEND_FORM_FAILURE",
    "SELL_BONDS_SEND_FORM_SUCCESS",

    "CANCEL_TRANSACTION_REQUEST",
    "CLEAR_FORM",
    "CLEAR_SEARCH_RESULTS",
    "CLOSE_TRANSACTION_PREVIEW",
    "DOWLOAD_FILE_REQUEST",
    "GET_PAPER",
    "GO_STEP_BACK",
    "PAPER_ID_SELECTED",
    "READ_TRANSACTION_REQUEST",
    "READ_TRANSACTION_SUCCESS",
    "RESTART_FLOW",
    "SIGN_TRANSACTION_PREVIEW",
    "SIGN_TRANSACTION_REQUEST",
]);

export const PROP = {};

PROP.types = {
    ...TypesRedux,
    bonds: PropTypes.array,
    creditAccountList: PropTypes.array,
    currencyList: PropTypes.array,
    debitAccountsList: PropTypes.array,
    expirationYearsAhead: PropTypes.array,
    formData: PropTypes.object,
    idPaper: PropTypes.string,
    infoData: PropTypes.object,
    isFetching: PropTypes.bool,
    mode: PropTypes.string,
    paper: PropTypes.object,
    papersList: PropTypes.array,
    prevMode: PropTypes.string,
    previewData: PropTypes.object,
    processingSale: PropTypes.number,
    timezoneOffset: PropTypes.number,
    transaction: PropTypes.object,
};

PROP.defaults = {
    ...DefaultsRedux,
    bonds: [],
    creditAccountList: [],
    currencyList: [],
    debitAccountsList: [],
    expirationYearsAhead: [],
    formData: {},
    idPaper: EMPTY_STR,
    infoData: {},
    isFetching: false,
    mode: MODE.EDIT,
    paper: {},
    papersList: null,
    prevMode: EMPTY_STR,
    previewData: {},
    processingSale: ZERO_NUMBER,
    timezoneOffset: ZERO_NUMBER,
    transaction: {},
};
