import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR, ONLY_NUMBER, SPACE_STR } from "~/constants";
import * as UtilsConfig from "~/util/config";
import * as UtilsI18n from "~/util/i18n";
import { GetMobileCountryOptions, ValidateInternationalPhoneNumber } from "~/util/phone";

import Image from "~/components/Image";
import Select from "~/components/Select";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";

export const NAME = "CountryCellPhoneField";

export const PROP = {
    types: {
        hidePlaceholder: PropTypes.bool,
        hideLabel: PropTypes.bool,
    },
    defaults: {
        hidePlaceholder: false,
        hideLabel: false,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        options: null,
        mappedOptions: null,
        placeholder: EMPTY_STR,
        prefix: EMPTY_STR,
        value: EMPTY_STR,
        country: EMPTY_STR,
    };

    componentDidMount() {
        const options = GetMobileCountryOptions();

        const mappedOptions = options.map((option) => ({
            prefix: option.value,
            label: (
                <React.Fragment className="needsclick">
                    <Image src={`${option.flag}.svg`} className="svg-icon needsclick" />
                    <span className="control-label needsclick"> {option.value}</span>
                    <span className="control-label needsclick"> {option.countryName}</span>
                </React.Fragment>
            ),
            value: option.country,
        }));

        this.setState(
            (prevState) => ({ ...prevState, mappedOptions }),
            () => {
                // emulating a click to the selector to preselect the first option.
                this.handleCountryChange(mappedOptions[0]);
            },
        );
    }

    setFieldValue = (value) => {
        const { field, form } = this.props;
        form.setFieldValue(field.name, value);
        form.setFieldTouched(field.name);
    };

    handleCountryChange = (selected) => {
        const { prefix, value: country } = selected;
        const placeholder = UtilsConfig.get(`cellPhone.code.${country}.placeholder`);
        const fieldValue = { prefix, country, value: EMPTY_STR };

        return this.setState(
            (prevState) => ({ ...prevState, ...fieldValue, placeholder }),
            () => {
                this.setFieldValue(fieldValue);
            },
        );
    };

    handlePhoneChange = (e) => {
        const { form } = this.props;
        const { prefix } = this.state;
        const { value } = e.target;

        if (value === EMPTY_STR) {
            form.setErrors({});
        }

        return this.setState(
            (prevState) => ({ ...prevState, value }),
            () => {
                const { country } = this.state;

                return this.setFieldValue({ prefix, country, value });
            },
        );
    };

    handleOnBlur = (e) => {
        const { country, prefix, value } = this.state;
        const { field, form, idForm } = this.props;

        if (!country) {
            return e.preventDefault();
        }

        if (ValidateInternationalPhoneNumber({ country, value })) {
            return form.setErrors({});
        }

        // The field value is set to initial state due to validation errors, form's handleSubmit is not executed
        this.setFieldValue({ prefix, country, value: EMPTY_STR });

        return form.setErrors({
            ...form.errors,
            [field.name]: { value: UtilsI18n.get(`${idForm}.mobilePhone.mustBeAPhoneNumber`) },
        });
    };

    render() {
        const { country, value } = this.state;
        const {
            field,
            form: { errors },
            hideLabel,
            hidePlaceholder,
            idForm,
            tooltip,
        } = this.props;

        const { placeholder, mappedOptions } = this.state;

        const hasError = errors && errors[field.name];
        const classNames = [
            "form-group",
            "form-group--composite",
            "fileTooltip",
            (hasError && "hasError") || EMPTY_STR,
        ].join(SPACE_STR);

        return (
            <div className={classNames}>
                {!hideLabel && <FieldLabel labelKey={`${idForm}.${field.name}.label`} />}
                <div className="input-group">
                    <Select
                        className="currency-selector slideFromBottom flex-container"
                        name="currency"
                        searchable={false}
                        onChange={this.handleCountryChange}
                        options={mappedOptions}
                        value={country}
                        clearable={false}
                    />
                    <input
                        className="login-form-input"
                        placeholder={hidePlaceholder ? EMPTY_STR : placeholder}
                        name={field.name}
                        type="tel"
                        value={value}
                        onChange={this.handlePhoneChange}
                        pattern={ONLY_NUMBER}
                        onBlur={this.handleOnBlur}
                        maxLength={UtilsConfig.get("cellPhone.maxLength")}
                    />
                </div>
                {hasError && (
                    <div className="form-group has-error">
                        <FieldError error={errors[field.name].value || errors[field.name]} />
                    </div>
                )}
                <div>
                    {tooltip && (
                        <div className="tooltip">
                            <span className="tooltip-text"> {tooltip}</span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default Component;
