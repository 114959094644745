import { goBack as GoBack } from "connected-react-router";
import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE } from "~/constants";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction } from "./_selectors";

const sagas = [TakeLatest([TYPE.CHANGE_PRODUCT_ALIAS], changeProductAlias)];

export default sagas;

function* changeProductAlias(props) {
    const { alias, idProduct, backAfterCall, formikBag } = props;
    const response = yield Call(SelectorsMiddleware.changeProductAlias, { alias, idProduct });
    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.data.alias,
                level: LEVEL.ERROR,
                scopes: [SCOPE.ACCOUNT_DETAILS, SCOPE.CREDIT_CARD_DETAILS, SCOPE.LOANS_DETAILS],
            }),
        );
    } else if (response.status === 200) {
        const { alias: aliasSuccess } = response.data.data;
        yield Put(SelectorsAction.changeProductAliasSuccess({ alias: aliasSuccess }));
    }
    if (backAfterCall) {
        yield Put(GoBack());
    }
    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}
