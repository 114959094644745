import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SelectorsStore as StoreI18n } from "~/store/i18n";

import Namespace from "./_Title.scss";

export const { NAME, TYPE } = Namespace;
export const PROP = {
    types: {
        value: PropTypes.string.isRequired,
        icon: PropTypes.node,
    },
    defaults: {
        value: undefined,
        icon: null,
    },
};

export function Component(props) {
    const { dispatch, value, icon, getMessage, ...rest } = props;
    const text = getMessage(value) || value;
    return (
        <div {...rest} data-name={NAME} data-type={TYPE}>
            {icon}
            <span>{text}</span>
        </div>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

function mapStateToProps(store) {
    return {
        getMessage: (key) => StoreI18n.getMessage(store, key),
    };
}

export default Connect(mapStateToProps)(Component);
