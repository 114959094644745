import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "loansRequest";

export const PATH = { REQUEST_LOAN_COMBOS_LIST: "/request.loan.combos.list" };

export const TYPE = FactoryType(NAME, [
    "CLEAR_LIST",
    "LIST_REQUEST",
    "LIST_FAILURE",
    "LIST_SUCCESS",
    "CHANGE_VALUE",
    "CHANGE_VALUE_NOT",
]);

export const PROP = {};

PROP.types = {
    isFetching: PropTypes.bool,
    comboListTerm: PropTypes.array,
    comboListDestination: PropTypes.array,
    changeValue: PropTypes.bool,
    ...TypesRedux,
};

PROP.defaults = { isFetching: true, comboListTerm: [], comboListDestination: [], changeValue: false, ...DefaultsRedux };
