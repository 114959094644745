import { call as Call, put as Put, takeLatest as TakeLatest, select as Select } from "redux-saga/effects";

import { EMPTY_STR } from "~/constants";
import { TYPE as TYPES_APP } from "~/store/app";
import { TYPE as LoginTypes } from "~/store/login";
import {
    TYPE as TYPE_SESSION,
    SelectorsStore as SelectorsStoreSession,
    SelectorsAction as SelectorsActionSession,
    SelectorsMiddleware as SelectorsMiddlewareSession,
} from "~/store/session";
import { getTimeInMillis as GetTimeInMillis, delay as Delay, get as Get } from "~/util/config";
import Logger from "~/util/logger";

import { SelectorsAction, SelectorsStore } from "./_selectors";

const sagas = [
    TakeLatest([TYPES_APP.INIT, LoginTypes.LOGIN_SUCCESS, TYPE_SESSION.EXTEND_SUCCESS], sessionExpirationCheck),
];

export default sagas;

function* sessionExpirationCheck() {
    const status = yield Select(SelectorsStore.getStatus);
    const isLoggedIn = yield Select(SelectorsStoreSession.isLoggedIn);

    if (isLoggedIn) {
        const secondsBeforeExpirationNotification = Math.floor(
            GetTimeInMillis("session.timeBeforeTimeoutNotification", 30000) / 1000,
        );

        let secondsToExpire;

        while (true) {
            try {
                const { data } = yield Call(SelectorsMiddlewareSession.check);
                const { secondsToExpire: secondsToExpireResponse } = data.data;

                secondsToExpire = secondsToExpireResponse;

                yield Put(
                    SelectorsActionSession.notifyUser({
                        personalDataNotify: data.data.personalDataNotify,
                        personalDataForceUpdate: data.data.personalDataForceUpdate,
                        accountDataNotify: data.data.accountDataNotify,
                        accountDataForceUpdate: data.data.accountDataForceUpdate,
                    }),
                );

                yield Put(
                    SelectorsActionSession.suggestAliasEnrollment({
                        suggestAliasEnrollment: data.data.suggestAliasEnrollment,
                    }),
                );

                yield Put(
                    SelectorsActionSession.getUserEmailForceSync({
                        userEmailForceSync: data.data.userEmailForceSync,
                    }),
                );

                yield Put(
                    SelectorsActionSession.getUserMobilePhoneForceSync({
                        userMobilePhoneForceSync: data.data.userMobilePhoneForceSync,
                    }),
                );

                yield Put(
                    SelectorsActionSession.getEmailForceUpdate({
                        emailForceUpdate: data.data.emailForceUpdate,
                    }),
                );

                yield Put(
                    SelectorsActionSession.mobilePhoneForceUpdate({
                        mobilePhoneForceUpdate: data.data.mobilePhoneForceUpdate,
                    }),
                );

                const qrName = {
                    en: data.data["qrName-en"],
                    es: data.data["qrName-es"],
                    pt: data.data["qrName-pt"],
                };

                const qrData = {
                    qrCurrentInstallment: data.data.qrCurrentInstallment,
                    qrDueDate: data.data.qrDueDate,
                    qrEnabled: data.data.qrEnabled,
                    qrLimitAmount: data.data.qrLimitAmount,
                    qrLimitCurrency: data.data.qrLimitCurrency,
                    qrMaxLimitAmount: data.data.qrMaxLimitAmount,
                    qrMaxLimitCurrency: data.data.qrMaxLimitCurrency,
                    qrName,
                    qrTypeDiscount: data.data.qrTypeDiscount,
                };

                yield Put(SelectorsActionSession.saveQrData({ qrData }));
            } catch (err) {
                Logger.error(err, EMPTY_STR);
                if (
                    (err.response && (err.response.status === 401 || err.response.status === 403)) ||
                    err.status === 401 ||
                    err.status === 403
                ) {
                    break;
                }
                yield Call(Delay, secondsBeforeExpirationNotification * 1000);
            }
            // Si el valor es -1 se puede extender infinitas veces
            const sessionExtendedCount = Get("session.extended.count", -1);
            if (status.sessionExtendedCount === sessionExtendedCount) {
                yield Call(Delay, secondsToExpire * 1000);
                yield Put({ type: TYPE_SESSION.EXPIRE });
                break;
            }
            if (secondsToExpire <= secondsBeforeExpirationNotification) {
                yield Put(SelectorsAction.showSessionExpirationModal({ secondsToExpire }));
                break;
            }
            yield Call(Delay, (secondsToExpire - secondsBeforeExpirationNotification) * 1000);
        }
    }
}
