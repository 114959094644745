import React from "react";

import PropTypes from "prop-types";

import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";

export const NAME = "SettingsOption";

export const PROP = {
    types: {
        linkTo: PropTypes.string.isRequired,
        messageKey: PropTypes.string.isRequired,
    },
    defaults: {},
};
export function Component(props) {
    const { linkTo, messageKey, onClick } = props;
    return (
        <li className="navigational-list-item">
            <Link to={linkTo} onClick={onClick}>
                <I18n id={messageKey} />
                <Image src="chevromRight.svg" className="svg-icon svg-caret" />
            </Link>
        </li>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
