import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { DATA_NOT_AVAILABLE, EMPTY_STR, SPACE_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import * as I18nUtil from "~/util/i18n";
import { countDecimalPlaces as CountDecimalPlaces, numberFormat as NumberFormatUtil } from "~/util/number";

import I18n from "~/components/I18n";
import NumberFormatInput from "~/components/NumberFormatInput";

const MILLION = 1000000;

export const NAME = "FormattedAmount";

export const PROP = {
    types: {
        lang: PropTypes.string.isRequired,
        className: PropTypes.string,
        currency: PropTypes.string,
        quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        showAbbreviature: PropTypes.bool,
        frequency: PropTypes.string,
        maximumDecimals: PropTypes.number,
        minimumDecimals: PropTypes.number,
        quatityNote: PropTypes.string,
        noStyle: PropTypes.bool,
    },
    defaults: {
        className: "data-amount",
        showAbbreviature: false,
        maximumDecimals: 0,
        minimumDecimals: 0,
        frequency: null,
        quantity: null,
        currency: null,
        quatityNote: null,
        noStyle: false,
    },
};

export function Component(props) {
    const {
        className,
        currency,
        frequency,
        quantity,
        maximumDecimals,
        minimumDecimals,
        lang,
        showAbbreviature,
        quatityNote,
        noStyle,
    } = props;

    const { decimalSeparator, thousandSeparator } = NumberFormatUtil(lang);

    const floatQuantity = parseFloat(quantity);
    if (!currency || Number.isNaN(floatQuantity)) {
        return null;
    }

    if (currency === DATA_NOT_AVAILABLE || quantity === DATA_NOT_AVAILABLE) {
        return <I18n id="data.notAvailable.short" />;
    }

    const amount =
        showAbbreviature && quantity >= MILLION
            ? {
                  quantity: quantity / MILLION,
                  abbreviature: I18nUtil.get("formattedAmount.millionAbbreviature"),
              }
            : {
                  quantity,
                  abbreviature: EMPTY_STR,
              };

    let decimalPlaces;
    let decimalScale;

    if (amount.abbreviature.length > 0) {
        decimalScale = 1;
    } else {
        decimalPlaces = CountDecimalPlaces(quantity, decimalSeparator);
        decimalScale = Math.max(Math.min(decimalPlaces, maximumDecimals), minimumDecimals);
    }

    const renderText = (value) => {
        // TODO: sg REVISAR, la idea es que muestre bien el N/D en la moneda
        const currencyAux = currency !== DATA_NOT_AVAILABLE ? `currency.label.${currency}` : "data.notAvailable.short";

        if (noStyle) {
            return value;
        }

        return (
            <span className={className}>
                <span className="data-amount-currency">
                    <I18n id={currencyAux} />
                    {SPACE_STR}
                </span>
                {value} {frequency} {quatityNote && <small>{` ${quatityNote}`}</small>}
            </span>
        );
    };
    return (
        <NumberFormatInput
            decimalSeparator={decimalSeparator}
            thousandSeparator={thousandSeparator}
            decimalScale={decimalScale}
            value={amount.quantity}
            displayType="text"
            renderText={renderText}
            suffix={amount.abbreviature}
            fixedDecimalScale
        />
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    maximumDecimals: Number(SelectorsStoreConfig.getConfig(store)["defaultDecimal.maximum"] || 0),
    minimumDecimals: Number(SelectorsStoreConfig.getConfig(store)["defaultDecimal.minimum"] || 0),
    lang: SelectorsStoreI18n.getLang(store),
});

export default Connect(mapStateToProps)(Component);
