import React from "react";

import { push as Push } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE, EMPTY_STR, ADMINISTRATION_USER_ACTIONS, TYPE_ADMINISTRATION, LEVEL } from "~/constants";
import Container from "~/containers/Internal/Administration/Simple";
import {
    SelectorsStore as SelectorsStoreAdministration,
    SelectorsAction as SelectorsActionAdministration,
} from "~/store/administration/users/users";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsStore as StoreSession } from "~/store/session";
import { generateId as GenerateId } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Box from "~/components/Box";
import Button from "~/components/Button";
import GridLayout from "~/components/GridLayout";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Note from "~/components/Note";
import IndeterminateCheckbox from "~/pages/_components/IndeterminateCheckbox";

import Style from "./Users.rules.scss";
import UserActionConfirmation from "./_components/UserActionConfirmation";

export const { NAME } = Style;

export const PROP = {
    types: {
        activeEnvironment: PropTypes.shape({ administrationScheme: PropTypes.string }).isRequired,
        thisUserId: PropTypes.string,
        users: PropTypes.arrayOf(
            PropTypes.shape({
                idUser: PropTypes.string,
                fullName: PropTypes.string,
                email: PropTypes.string,
                lastLoginAsMediumString: PropTypes.string,
                idUserStatus: PropTypes.string,
            }),
        ).isRequired,
        usersInfo: PropTypes.objectOf(
            PropTypes.shape({
                status: PropTypes.string.isRequired,
                signatureLevel: PropTypes.string,
            }),
        ),
        fetching: PropTypes.bool,
        fetchingMoreUsers: PropTypes.bool,
        hasMoreData: PropTypes.bool,
        totalPages: PropTypes.number,
        i18n: PropTypes.shape({
            fullName: PropTypes.string,
            email: PropTypes.string,
            lastLoginAsMediumString: PropTypes.string,
        }),
        currentPage: PropTypes.number,
        isChangingEnvironment: PropTypes.bool,
        ...TypesRedux,
    },
    defaults: {
        fetching: false,
        fetchingMoreUsers: false,
        hasMoreData: false,
        totalPages: 0,
        usersInfo: null,
        currentPage: 0,
        isChangingEnvironment: false,
        ...DefaultsRedux,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {
        selectAllChecked: false,
        selectedUsers: new Map(),
        blockedQuantity: 0,
        unblockedQuantity: 0,
        showModal: false,
        action: null,
    };

    componentDidMount() {
        const { dispatch, isChangingEnvironment, level, message, notificacion, scopes } = this.props;

        if (!isChangingEnvironment) {
            dispatch(SelectorsActionAdministration.loadListRequest());
        }

        if (notificacion) {
            dispatch(
                SelectorsActionNotification.showNotification({
                    message,
                    level,
                    scopes,
                }),
            );
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { cleanSelectionForUserActions } = nextProps;
        if (cleanSelectionForUserActions === true) {
            return {
                ...prevState,
                selectedUsers: new Map(),
                selectAllChecked: false,
                blockedQuantity: 0,
                unblockedQuantity: 0,
            };
        }
        return null;
    }

    showModal = (action) => {
        this.setState({ showModal: true, action });
    };

    handleConfirmAction = () => {
        const { dispatch } = this.props;
        const { selectedUsers, action } = this.state;
        dispatch(
            SelectorsActionAdministration.changeUserStatusPreview({
                userList: [...selectedUsers.keys()],
                userNameList: [...selectedUsers.values()],
                userAction: action,
            }),
        );
        this.setState({
            showModal: false,
        });
    };

    handleCancelAction = () => {
        this.setState({ showModal: false });
    };

    handleBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    handleUserClick = (idUser) => {
        const {
            dispatch,
            activeEnvironment: { administrationScheme },
        } = this.props;

        const path = {
            simple: "simple/permissions",
            medium: "medium/Permissions",
            advanced: "advanced/details",
        };

        if (path[administrationScheme] === TYPE_ADMINISTRATION.ADVANCED) {
            dispatch(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("administration.feature.unavailable"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION],
                }),
            );
        } else {
            dispatch(Push(`/administration/${path[administrationScheme]}/${idUser}`));
        }
    };

    handleSelectAll = () => {
        const { selectAllChecked } = this.state;
        const { users, usersInfo, thisUserId } = this.props;
        const newSelectedUsers = new Map();
        let blockedQuantity = 0;
        let unblockedQuantity = 0;
        if (!selectAllChecked) {
            users.forEach((user) => {
                if (user.idUser !== thisUserId) {
                    newSelectedUsers.set(user.idUser, user.fullName);
                    if (usersInfo[user.idUser].status === "blocked") {
                        blockedQuantity += 1;
                    } else {
                        unblockedQuantity += 1;
                    }
                }
            });
        }

        this.setState((prevState) => ({
            selectAllChecked: !prevState.selectAllChecked,
            selectedUsers: newSelectedUsers,
            blockedQuantity,
            unblockedQuantity,
        }));
    };

    handleCheckClick = (idUserChecked, nameUserChecked, massiveDissabledQuantity) => {
        const { users, usersInfo } = this.props;
        const { selectedUsers, selectAllChecked } = this.state;
        let { blockedQuantity, unblockedQuantity } = this.state;

        if (!selectedUsers.has(idUserChecked)) {
            selectedUsers.set(idUserChecked, nameUserChecked);
            if (usersInfo[idUserChecked].status === "blocked") {
                blockedQuantity += 1;
            } else {
                unblockedQuantity += 1;
            }

            if (selectedUsers.size === users.length - massiveDissabledQuantity) {
                this.setState({
                    selectAllChecked: true,
                });
            }
        } else {
            selectedUsers.delete(idUserChecked);
            if (usersInfo[idUserChecked].status === "blocked") {
                blockedQuantity -= 1;
            } else {
                unblockedQuantity -= 1;
            }
            if (selectAllChecked) {
                this.setState({ selectAllChecked: false });
            }
        }
        this.setState({
            selectedUsers,
            blockedQuantity,
            unblockedQuantity,
        });
    };

    handleMoreDataClick = () => {
        const { dispatch, currentPage } = this.props;

        this.setState({ selectAllChecked: false });
        dispatch(
            SelectorsActionAdministration.loadMoreRequest({ pageNumber: currentPage + 1, orderBy: "last_login DESC" }),
        );
    };

    renderActionButtons = () => {
        const {
            activeEnvironment: { administrationScheme },
        } = this.props;
        const { selectedUsers, blockedQuantity, unblockedQuantity } = this.state;
        const userQuantity =
            selectedUsers.size !== 1
                ? `${selectedUsers.size} ${UtilsI18n.get("administration.users.list.selected.quantity")}`
                : `${selectedUsers.size} ${UtilsI18n.get("administration.users.list.selected.singleUser")}`;

        return (
            <section className="section-actions">
                <div>
                    <div className="section-actions-title">
                        {selectedUsers.size === 0
                            ? UtilsI18n.get("administration.users.list.selected.none")
                            : userQuantity}
                    </div>
                    {unblockedQuantity > 0 && (
                        <Button
                            block={false}
                            bsStyle="link actions"
                            key="block"
                            label="administration.block"
                            onClick={() => this.showModal("block")}
                        />
                    )}
                    {blockedQuantity > 0 && (
                        <Button
                            block={false}
                            bsStyle="link actions"
                            key="unblock"
                            label="administration.unblock"
                            onClick={() => this.showModal("unblock")}
                        />
                    )}
                    {selectedUsers.size > 0 && (
                        <Button
                            block={false}
                            bsStyle="link actions"
                            key="delete"
                            label="administration.delete"
                            onClick={() => this.showModal("delete")}
                        />
                    )}
                </div>
                <div>
                    {administrationScheme !== TYPE_ADMINISTRATION.SIMPLE && (
                        <Button
                            block={false}
                            key="unblock"
                            label="administration.users.list.addUser"
                            onClick={this.handleUserInvite}
                        />
                    )}
                </div>
            </section>
        );
    };

    getMessageKeyUserActionConfirmation = () => {
        const { selectedUsers, action } = this.state;
        let key = EMPTY_STR;

        if (action === ADMINISTRATION_USER_ACTIONS.BLOCK || action === ADMINISTRATION_USER_ACTIONS.UNBLOCK) {
            key = "administration.users.blockunblock.confirmation";
        } else {
            key = "administration.users.delete.confirmation";
        }
        if (selectedUsers.size > 1) {
            key = `${key}.plural`;
        }
        return key;
    };

    handleUserInvite = () => {
        const {
            dispatch,
            activeEnvironment: { administrationScheme },
        } = this.props;
        if (administrationScheme === TYPE_ADMINISTRATION.SIMPLE) {
            dispatch(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("administration.feature.unavailable"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION],
                }),
            );
        } else {
            dispatch(Push("/administration/users/userInvite"));
        }
    };

    renderInvitationCodes = () => {
        const { invitationCodes } = this.props;
        return (
            <React.Fragment>
                {invitationCodes &&
                    invitationCodes.map(({ fullName, email, invitationCodeId }) => (
                        <GridLayout key={`invitation-code-${GenerateId()}`} className="item invitation-code">
                            <div />
                            <div>{fullName}</div>
                            <div>{email}</div>
                            <div>
                                <a
                                    href="#"
                                    onClick={() => {
                                        this.handleResendInvite(invitationCodeId);
                                    }}>
                                    {UtilsI18n.get("users.resendInvite")}
                                </a>
                            </div>
                            <div>
                                <span className="status status-pending">{UtilsI18n.get("users.status.pending")}</span>
                            </div>
                        </GridLayout>
                    ))}
            </React.Fragment>
        );
    };

    handleResendInvite = (invitationCodeId) => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionAdministration.resendInviteRequest({ invitationCodeId }));
    };

    render() {
        const { i18n, fetching, users, usersInfo, totalPages, hasMoreData, thisUserId } = this.props;
        const { selectAllChecked, selectedUsers, showModal } = this.state;

        const massiveDissabledQuantity = Object.values(users).filter((user) => user.idUser === thisUserId).length;
        const COLUMN_HEADINGS = [i18n.fullName, i18n.email, i18n.lastLoginAsMediumString, i18n.state];
        return (
            <Container
                name={NAME}
                wait={fetching}
                head-title="administration.users.list.title"
                head-onBack={this.handleBack}
                image="administration-title-icon.svg"
                scopeToShowNotification={SCOPE.ADMINISTRATION_USERS}>
                <UserActionConfirmation
                    show={showModal}
                    handleCancelAction={this.handleCancelAction}
                    handleConfirmAction={this.handleConfirmAction}
                    messageKey={this.getMessageKeyUserActionConfirmation()}
                />
                <div id={Style.ID}>
                    <div className="title">
                        <section>
                            <h3>{UtilsI18n.get("administration.users.index.title")}</h3>
                        </section>
                        <span className="description">{UtilsI18n.get("administration.users.index.description")}</span>
                        <span className="descriptionMonetary">
                            <Image src="dinero.svg" className="svg-money-icon" />
                            <I18n id="administration.users.index.description.monetaryTransactions" />
                        </span>
                        <div>{this.renderActionButtons()}</div>
                    </div>

                    <div className="select-all-no-headers">
                        <div className="select-all-checkbox">
                            <IndeterminateCheckbox
                                id="selectAllUsers"
                                onCheckClick={this.handleSelectAll}
                                selectedOptionsAmount={
                                    selectAllChecked ||
                                    (users.length === selectedUsers.size + massiveDissabledQuantity &&
                                        selectedUsers.size !== 0)
                                }
                                value={1}
                            />
                        </div>
                        <div className="checkbox-description">
                            {UtilsI18n.get("administration.users.list.selected.selectAll")}
                        </div>
                    </div>
                    <GridLayout className="header">
                        <div className="listHeaders">
                            <IndeterminateCheckbox
                                id="selectAllUsers"
                                onCheckClick={this.handleSelectAll}
                                selectedOptionsAmount={
                                    selectAllChecked ||
                                    (users.length === selectedUsers.size + massiveDissabledQuantity &&
                                        selectedUsers.size !== 0)
                                }
                                value={1}
                            />
                        </div>
                        {COLUMN_HEADINGS.map((heading, index) => (
                            <div key={`header-${GenerateId()}`} id={`header-${index}`} className="listHeaders">
                                {heading}
                            </div>
                        ))}
                    </GridLayout>
                    {users.map(({ idUser, fullName, email, lastLoginAsMediumString }) => (
                        <GridLayout
                            key={`user-${GenerateId()}`}
                            className="item"
                            onClick={() => this.handleUserClick(idUser)}>
                            <div onClick={(event) => event.stopPropagation()}>
                                <IndeterminateCheckbox
                                    id={idUser}
                                    onCheckClick={() =>
                                        this.handleCheckClick(idUser, fullName, massiveDissabledQuantity)
                                    }
                                    selectedOptionsAmount={
                                        idUser !== thisUserId && (selectAllChecked || selectedUsers.has(idUser))
                                    }
                                    disabled={idUser === thisUserId}
                                />
                            </div>
                            <div>
                                <div className="userName">
                                    <p>{fullName}</p>
                                </div>
                                <div>
                                    {usersInfo[idUser].canMakeMonetaryTransactions && (
                                        <Image src="dinero.svg" className="svg-money-icon" />
                                    )}
                                </div>
                            </div>
                            <div>{email}</div>
                            <div>{lastLoginAsMediumString}</div>
                            <div>
                                <span
                                    className={`status ${
                                        usersInfo[idUser].status === "blocked" ? "status-blocked" : EMPTY_STR
                                    }`}>
                                    {UtilsI18n.get(`user.status.${usersInfo[idUser].status}`)}
                                </span>
                            </div>
                        </GridLayout>
                    ))}
                    {this.renderInvitationCodes()}

                    {totalPages && (
                        <Box flex justify="center">
                            <Note onClick={this.handleMoreDataClick} noMoreData={!hasMoreData}>
                                <I18n id={`administration.users.list.more${hasMoreData ? "" : ".noMoreData"}`} />
                            </Note>
                        </Box>
                    )}
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    thisUserId: StoreSession.getUserId(store),
    users: SelectorsStoreAdministration.getUsers(store),
    invitationCodes: SelectorsStoreAdministration.getInvitationCodes(store),
    usersInfo: SelectorsStoreAdministration.getUsersInfo(store),
    fetching: SelectorsStoreAdministration.isFetching(store),
    totalPages: SelectorsStoreAdministration.getTotalPages(store),
    fetchingMoreUsers: SelectorsStoreAdministration.isFetchingMoreUsers(store),
    hasMoreData: SelectorsStoreAdministration.getHasMoreData(store),
    currentPage: SelectorsStoreAdministration.getCurrentPage(store),
    activeEnvironment: StoreSession.getActiveEnvironment(store),
    i18n: {
        fullName: StoreI18n.getMessage(store, "administration.users.list.username"),
        email: StoreI18n.getMessage(store, "administration.users.list.email"),
        lastLoginAsMediumString: StoreI18n.getMessage(store, "administration.users.list.lastLogin"),
        state: StoreI18n.getMessage(store, "administration.users.list.status"),
    },
    notificacion: SelectorsStoreAdministration.notification(store),
    message: SelectorsStoreAdministration.getMessage(store),
    level: SelectorsStoreAdministration.getLevel(store),
    scopes: SelectorsStoreAdministration.getScopes(store),
    isChangingEnvironment: StoreSession.isChangingEnvironment(store),
    cleanSelectionForUserActions: SelectorsStoreAdministration.getCleanSelectionForUserActions(store),
});

export default Connect(mapStateToProps)(Component);
