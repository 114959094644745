import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    requestBondsAndGuaranteesPreRequest: () =>
        API.executeWithAccessToken(PATH.REQUEST_BONDS_AND_GUARANTEES_PRE_REQUEST),
    requestBondsAndGuaranteesPreviewRequest: (props) =>
        API.executeWithAccessToken(PATH.REQUEST_BONDS_AND_GUARANTEES_PREVIEW_REQUEST, props),
    requestBondsAndGuaranteesRequest: (props) =>
        API.executeWithAccessToken(PATH.REQUEST_BONDS_AND_GUARANTEES_REQUEST, props),

    modifyBondsAndGuaranteesPreRequest: () => API.executeWithAccessToken(PATH.MODIFY_BONDS_AND_GUARANTEES_PRE_REQUEST),
    modifyBondsAndGuaranteesPreviewRequest: (props) =>
        API.executeWithAccessToken(PATH.MODIFY_BONDS_AND_GUARANTEES_PREVIEW_REQUEST, props),
    modifyBondsAndGuaranteesRequest: (props) =>
        API.executeWithAccessToken(PATH.MODIFY_BONDS_AND_GUARANTEES_REQUEST, props),
};

export const SelectorsAction = {
    drafLoaded: () => FactoryAction(TYPE.DRAFT_LOADED),

    requestBondsAndGuaranteesPreRequest: () => FactoryAction(TYPE.REQUEST_BONDS_AND_GUARANTEES_PRE_REQUEST),
    requestBondsAndGuaranteesPreSuccess: (props) => FactoryAction(TYPE.REQUEST_BONDS_AND_GUARANTEES_PRE_SUCCESS, props),
    requestBondsAndGuaranteesPreFailure: () => FactoryAction(TYPE.REQUEST_BONDS_AND_GUARANTEES_PRE_FAILURE),

    requestBondsAndGuaranteesPreviewRequest: (props) =>
        FactoryAction(TYPE.REQUEST_BONDS_AND_GUARANTEES_PREVIEW_REQUEST, props),
    requestBondsAndGuaranteesPreviewSuccess: (props) =>
        FactoryAction(TYPE.REQUEST_BONDS_AND_GUARANTEES_PREVIEW_SUCCESS, props),
    requestBondsAndGuaranteesPreviewFailure: () => FactoryAction(TYPE.REQUEST_BONDS_AND_GUARANTEES_PREVIEW_FAILURE),

    requestBondsAndGuaranteesSendRequest: (props) =>
        FactoryAction(TYPE.REQUEST_BONDS_AND_GUARANTEES_SEND_REQUEST, props),
    requestBondsAndGuaranteesSendSuccess: (props) =>
        FactoryAction(TYPE.REQUEST_BONDS_AND_GUARANTEES_SEND_SUCCESS, props),
    requestBondsAndGuaranteesSendFailure: () => FactoryAction(TYPE.REQUEST_BONDS_AND_GUARANTEES_SEND_FAILURE),

    // Modify bonds and guarantees
    modifyBondsAndGuaranteesPreRequest: () => FactoryAction(TYPE.MODIFY_BONDS_AND_GUARANTEES_PRE_REQUEST),
    modifyBondsAndGuaranteesPreSuccess: (props) => FactoryAction(TYPE.MODIFY_BONDS_AND_GUARANTEES_PRE_SUCCESS, props),
    modifyBondsAndGuaranteesPreFailure: () => FactoryAction(TYPE.MODIFY_BONDS_AND_GUARANTEES_PRE_FAILURE),

    modifyBondsAndGuaranteesPreviewRequest: (props) =>
        FactoryAction(TYPE.MODIFY_BONDS_AND_GUARANTEES_PREVIEW_REQUEST, props),
    modifyBondsAndGuaranteesPreviewSuccess: (props) =>
        FactoryAction(TYPE.MODIFY_BONDS_AND_GUARANTEES_PREVIEW_SUCCESS, props),
    modifyBondsAndGuaranteesPreviewFailure: () => FactoryAction(TYPE.MODIFY_BONDS_AND_GUARANTEES_PREVIEW_FAILURE),

    modifyBondsAndGuaranteesSendRequest: (props) => FactoryAction(TYPE.MODIFY_BONDS_AND_GUARANTEES_SEND_REQUEST, props),
    modifyBondsAndGuaranteesSendSuccess: (props) => FactoryAction(TYPE.MODIFY_BONDS_AND_GUARANTEES_SEND_SUCCESS, props),
    modifyBondsAndGuaranteesSendFailure: () => FactoryAction(TYPE.MODIFY_BONDS_AND_GUARANTEES_SEND_FAILURE),

    comexFormSendDataFailure: () => FactoryAction(TYPE.COMEX_FORM_SEND_DATA_FAILURE),

    // Restart flow
    restartFlow: () => FactoryAction(TYPE.RESTART_FLOW),
};

export const SelectorsStore = {
    getTransactionList: (store) => store[NAME].transactionList,
    getFetching: (store) => store[NAME].fetching,

    getRequestBondsAndGuaranteesOpenPre: (store) => store[NAME].requestBondsAndGuarantees.preData,
    getRequestBondsAndGuaranteesOpenFormData: (store) => store[NAME].requestBondsAndGuarantees.formData,
    getRequestBondsAndGuaranteesOpenPrevData: (store) => store[NAME].requestBondsAndGuarantees.prevData,

    getmodifyBondsAndGuaranteesOpenPre: (store) => store[NAME].modifyBondsAndGuarantees.preData,
    getmodifyBondsAndGuaranteesOpenFormData: (store) => store[NAME].modifyBondsAndGuarantees.formData,
    getmodifyBondsAndGuaranteesOpenPrevData: (store) => store[NAME].modifyBondsAndGuarantees.prevData,

    getMode: (store) => store[NAME].mode,
    getTransaction: (store) => store[NAME].transaction,
    getDraftLoaded: (store) => store[NAME].draftLoaded,
    getPrevMode: (store) => store[NAME].prevMode,
};
