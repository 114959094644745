import React from "react";

import { connect as Connect } from "react-redux";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";

import {
    ENVIRONMENT_TYPE,
    LEVEL,
    mobileUrls,
    PATH_TO,
    PERMISSIONS,
    SCOPE,
    SLASH,
    TYPE_ADMINISTRATION,
} from "~/constants";
import { NAME as NAME_STORE } from "~/store/app";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsAction as SelectorsActionLogin } from "~/store/login";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsStore as StoreSession } from "~/store/session";
import { SelectorsAction as ActionStatus } from "~/store/status";
import DeviceUtils from "~/util/device";
import * as UtilsI18n from "~/util/i18n";

import I18n from "~/components/I18n";
import Link from "~/components/Link";
import BackButtonListener from "~/pages/_components/BackButtonListener";
import OldVersion from "~/pages/_components/OldVersion";
import PasswordModal from "~/pages/_components/PasswordModal";

import AboutIbanca from "~/pages/aboutIbanca/askForExternalLink";
import AccountDetail from "~/pages/accounts/Details";
import AccountsFilterResults from "~/pages/accounts/FiltersResult";
import Accounts from "~/pages/accounts/List";
import AccountsOperations from "~/pages/accounts/Operations";
import AccountsOperationsAlias from "~/pages/accounts/OperationsAlias";
import Filters from "~/pages/accounts/OperationsFilter";
import AccountOperationsStatements from "~/pages/accounts/OperationsStatements";
import UserInvite from "~/pages/administration/UserInvite/UserInvite";
import UserInviteStep2 from "~/pages/administration/UserInvite/UserInviteStep2";
import UserInviteTicket from "~/pages/administration/UserInvite/UserInviteTicket";
import Users from "~/pages/administration/Users";
import AdministrationGroupFormBlockTicket from "~/pages/administration/_components/tickets/GroupFormBlockTicketConfirmation";
import AdministrationMediumPermissionsTicket from "~/pages/administration/_components/tickets/PermissionsMediumTicketContent";
import AdministrationSimplePermissionsTicket from "~/pages/administration/_components/tickets/PermissionsSimpleTicketContent";
import AdministrationAdvancedSignaturesSchemeTicket from "~/pages/administration/_components/tickets/SignaturesSchemeAdvanced";
import AdministrationMediumSignaturesSchemeTicket from "~/pages/administration/_components/tickets/SignaturesSchemeMedium";
import AdministrationAdvancedSignatureTicket from "~/pages/administration/_components/tickets/userConfig/SignatureTicket";
import AdministrationAdvancedSignaturesSchemes from "~/pages/administration/advanced/AdministrationAdvancedSignaturesSchemes";
import AdministrationAdvancedSignaturesSchemeCreate from "~/pages/administration/advanced/SignatureSchemeCreate";
import AdministrationAdvancedSignaturesSchemeModify from "~/pages/administration/advanced/SignatureSchemeModify";
import GroupActionConfirmation from "~/pages/administration/advanced/groups/ActionConfirmation";
import AdministrationAdvancedGroupForm from "~/pages/administration/advanced/groups/Detail";
import Groups from "~/pages/administration/advanced/groups/List";
import AdministrationAdvancedGroupFormDataTicket from "~/pages/administration/advanced/tickets/GroupFormDataTicket";
import AdministrationAdvancedSignatureConfirm from "~/pages/administration/advanced/userConfig/UserConfigAdminForm";
import AdministrationMediumPermissions from "~/pages/administration/medium/Permissions";
import AdministrationMediumSignaturesSchemeModify from "~/pages/administration/medium/SignaturesSchemeModify";
import AdministrationMediumSignaturesSchemes from "~/pages/administration/medium/SignaturesSchemes";
import AdministrationSimplePermissions from "~/pages/administration/simple/Permissions";
import AutomaticDebitsList from "~/pages/automaticDebits/List";
import BackofficeTransaction from "~/pages/backoffice/BackofficeTransaction";
import BiometricTypeChoice from "~/pages/biometricTypeChoice/BiometricTypeChoice";
import ChangePhoneStep1 from "~/pages/changePhone/ChangePhoneStep1";
import ChangePhoneStep2 from "~/pages/changePhone/ChangePhoneStep2";
import ChangePhoneStep3 from "~/pages/changePhone/ChangePhoneStep3";
import ComEx from "~/pages/comEx/ComEx";
import ModifyBondsAndGuarantees from "~/pages/comEx/bondsAndGuarantees/modify/ModifyBondsAndGuarantees";
import ModifyBondsAndGuaranteesTicket from "~/pages/comEx/bondsAndGuarantees/modify/ModifyBondsAndGuaranteesTicket";
import RequestBondsAndGuarantees from "~/pages/comEx/bondsAndGuarantees/request/RequestBondsAndGuarantees";
import RequestBondsAndGuaranteesTicket from "~/pages/comEx/bondsAndGuarantees/request/RequestBondsAndGuaranteesTicket";
import AuthorizationDDiscrepancies from "~/pages/comEx/export/authorizationDDiscrepancies/AuthorizationDDiscrepancies";
import AuthorizationDDiscrepanciesTicket from "~/pages/comEx/export/authorizationDDiscrepancies/AuthorizationDDiscrepanciesTicket";
import BillingClaim from "~/pages/comEx/export/billingClaim/BillingClaim";
import BillingClaimTicket from "~/pages/comEx/export/billingClaim/BillingClaimTicket";
import CessionCreditLetter from "~/pages/comEx/export/cessionCreditLetter/CessionCreditLetter";
import CessionCreditLetterTicket from "~/pages/comEx/export/cessionCreditLetter/CessionCreditLetterTicket";
import PresentBillingDocuments from "~/pages/comEx/export/presentBillingDocuments/PresentBillingDocuments";
import PresentBillingDocumentsTicket from "~/pages/comEx/export/presentBillingDocuments/PresentBillingDocumentsTicket";
import PresentationDocumentsCreditLetter from "~/pages/comEx/export/presentationDocumentsCreditLetter/PresentationDocumentsCreditLetter";
import PresentationDocumentsCreditLetterTicket from "~/pages/comEx/export/presentationDocumentsCreditLetter/PresentationDocumentsCreditLetterTicket";
import PurchaseDocuments from "~/pages/comEx/export/purchaseDocuments/PurchaseDocuments";
import PurchaseDocumentsTicket from "~/pages/comEx/export/purchaseDocuments/PurchaseDocumentsTicket";
import TransferCreditLetter from "~/pages/comEx/export/transferCreditLetter/TransferCreditLetter";
import TransferCreditLetterTicket from "~/pages/comEx/export/transferCreditLetter/TransferCreditLetterTicket";
import BillingCancellation from "~/pages/comEx/import/billingCancellation/BillingCancellation";
import BillingCancellationTicket from "~/pages/comEx/import/billingCancellation/BillingCancellationTicket";
import CancellationLetterCredit from "~/pages/comEx/import/cancellationLetterCredit/CancellationLetterCredit";
import CancellationLetterCreditTicket from "~/pages/comEx/import/cancellationLetterCredit/CancellationLetterCreditTicket";
import CreditLetter from "~/pages/comEx/import/creditLetterOpen/CreditLetter";
import ImportCreditLetterOpenTicket from "~/pages/comEx/import/creditLetterOpen/CreditLetterTicket";
import LiftCreditLetterWithDiscrepancies from "~/pages/comEx/import/liftCreditLetterWithDiscrepancies/LiftCreditLetterWithDiscrepancies";
import LiftCreditLetterWithDiscrepanciesTicket from "~/pages/comEx/import/liftCreditLetterWithDiscrepancies/LiftCreditLetterWithDiscrepanciesTicket";
import ModifyCreditLetter from "~/pages/comEx/import/modifyCreditLetter/ModifyCreditLetter";
import ModifyCreditLetterTicket from "~/pages/comEx/import/modifyCreditLetter/ModifyCreditLetterTicket";
import PresentationLDDomesticCredit from "~/pages/comEx/import/presentationLDDomesticCredit/PresentationLDDomesticCredit";
import PresentationLDDomesticCreditTicket from "~/pages/comEx/import/presentationLDDomesticCredit/PresentationLDDomesticCreditTicket";
import RequestEndorsement from "~/pages/comEx/import/requestEndorsement/RequestEndorsement";
import RequestEndorsementTicket from "~/pages/comEx/import/requestEndorsement/RequestEndorsementTicket";
import OtherProcedures from "~/pages/comEx/other/otherProcedures/OtherProcedures";
import OtherProceduresTicket from "~/pages/comEx/other/otherProcedures/OtherProceduresTicket";
import CommunicationMain from "~/pages/communications/Main";
import NewMessage from "~/pages/communications/NewMessage";
import ReadMessage from "~/pages/communications/ReadMessage";
import ReplyMessage from "~/pages/communications/ReplyMessage";
import ConnectedPartiesForm from "~/pages/connectedParties/ConnectedParties";
import ConnectedPartiesReadTicket from "~/pages/connectedParties/ConnectedPartiesReadTicket";
import CCDetails from "~/pages/creditCards/Details";
import CCList from "~/pages/creditCards/List";
import CCOperations from "~/pages/creditCards/Operations";
import CCOperationsAlias from "~/pages/creditCards/OperationsAlias";
import CCOperationsStatements from "~/pages/creditCards/OperationsStatements";
import CCLimitIncrease from "~/pages/creditCards/creditCardLimitIncrease/LimitIncrease";
import CCLimitIncreaseTicket from "~/pages/creditCards/creditCardLimitIncrease/LimitIncreaseTicket";
import CreditCardsPaymentList from "~/pages/creditCards/creditCardsPayment/List";
import PurchaseNotification from "~/pages/creditCards/purchaseNotification/PurchaseNotification";
import PurchaseNotificationTicket from "~/pages/creditCards/purchaseNotification/PurchaseNotificationTicket";
import TripOrOnlinePurchaseNotification from "~/pages/creditCards/tripOrOnlinePurchaseNotification/TripOrOnlinePurchaseNotification";
import TripOrOnlinePurchaseNotificationTicket from "~/pages/creditCards/tripOrOnlinePurchaseNotification/TripOrOnlinePurchaseNotificationTicket";
import CustomsPayment from "~/pages/customsPayment/CustomsPayment";
import CustomsPaymentReadTicket from "~/pages/customsPayment/CustomsPaymentReadTicket";
import DebitCardDetails from "~/pages/debitCard/Details";
import DepositGuarantee from "~/pages/depositGuarantee/conditions";
import Desktop from "~/pages/desktop";
import ChangePhoneConfirmation from "~/pages/desktop/ChangePhoneConfirmation";
import CheckMobilePhone from "~/pages/desktop/CheckMobilePhone";
import FinishMigration from "~/pages/desktop/FinishMigration";
import MigrationSuggest from "~/pages/desktop/MigrationSuggest";
import EnrollmentBiometric from "~/pages/enrollment/EnrollmentBiometric";
import FinishEnrollment from "~/pages/enrollment/FinishEnrollment";
import InvitationCode from "~/pages/enrollment/InvitationCode";
import RegisterBiometric from "~/pages/enrollment/RegisterBiometric";
import RegisterPersonalData from "~/pages/enrollment/RegisterPersonalData";
import SecurityTips from "~/pages/enrollment/SecurityTips";
import VerificationCode from "~/pages/enrollment/VerificationCode";
import PageError from "~/pages/error/Error";
import ServerError from "~/pages/error/ServerError";
import Unavailable from "~/pages/error/Unavailable";
import ExternalDashboard from "~/pages/externalDashboard/ExternalDashboard";
import FrequentQuestions from "~/pages/externalDashboard/Information/_FrequentQuestions/index";
import GeneralConditions from "~/pages/externalDashboard/Information/_GeneralConditions";
import InformationQuery from "~/pages/externalDashboard/Information/_Query";
import Security from "~/pages/externalDashboard/Information/_Security";
import Information from "~/pages/externalDashboard/Information/index";
import SoftOTP from "~/pages/externalDashboard/SoftOTP";
import UploadPaymentOrders from "~/pages/factoring/UploadPaymentOrders/UploadPaymentOrders";
import UploadPaymentOrdersTransaction from "~/pages/factoring/UploadPaymentOrders/UploadPaymentOrdersTransaction";
import UploadSuppliers from "~/pages/factoring/UploadSuppliers/UploadSuppliers";
import UploadSuppliersTransaction from "~/pages/factoring/UploadSuppliers/UploadSuppliersTransaction";
import Factoring from "~/pages/factoring/_menu/Factoring";
import ListAdvancePayments from "~/pages/factoring/advancePayments/List";
import ListAdvancePaymentsTransaction from "~/pages/factoring/advancePaymentsTransaction/AdvancePaymentTransaction";
import AdvancePaymentsTransactionTicket from "~/pages/factoring/advancePaymentsTransaction/AdvancePaymentTransactionTicket";
import ListPaymentOrders from "~/pages/factoring/paymentOrders/List";
import ListSuppliers from "~/pages/factoring/suppliers/List";
import ListFilesDownloads from "~/pages/filesDownload/List";
import Financing from "~/pages/financing/Financing";
import Form from "~/pages/forms/Form";
import ResumeTransaction from "~/pages/forms/ResumeTransaction";
import Transaction from "~/pages/forms/Transaction";
import TransactionHistory from "~/pages/forms/TransactionHistory";
import BankSearch from "~/pages/forms/_components/_fields/_bankselector/bankSearch/BankSearch";
import FormPaymentLines from "~/pages/forms/_components/_fields/_transactionlines/FormPaymentLines";
import Investment from "~/pages/investment/Investment";
import TermDeposit from "~/pages/investment/TermDeposit";
import TermDepositTicket from "~/pages/investment/TermDepositTicket";
import InvestmentRequestInfo from "~/pages/investment/requestInfo/RequestInfo";
import RiskProfile from "~/pages/investment/riskProfile/RiskProfile";
import RiskProfileQuiz from "~/pages/investment/riskProfile/RiskProfileQuiz";
import RiskProfileResume from "~/pages/investment/riskProfile/RiskProfileResume";
import RiskProfileStep2 from "~/pages/investment/riskProfile/RiskProfileStep2";
import RiskProfileTicket from "~/pages/investment/riskProfile/RiskProfileTicket";
import LoanDetails from "~/pages/loans/Details";
import LList from "~/pages/loans/List";
import LoanMobileFilters from "~/pages/loans/_components/MobileFilters";
import MobileLoanOperations from "~/pages/loans/_components/MobileLoanOperations";
import LoanSetAlias from "~/pages/loans/_components/OperationsAlias";
import LoanStatementDetail from "~/pages/loans/_components/StatementDetail";
import Locations from "~/pages/locations/Locations";
import GeneralConditionsLogin from "~/pages/login/GeneralConditionsLogin";
import LoginStep1 from "~/pages/login/LoginStep1";
import LoginStep2 from "~/pages/login/LoginStep2";
import LoginStep4 from "~/pages/login/LoginStep4";
import VersionWarningAndLogout from "~/pages/login/VersionWarningAndLogout";
import QrModoList from "~/pages/payments/qrModo/List";
import OtpRequest from "~/pages/payments/qrModo/OtpRequest";
import QrModoTicket from "~/pages/payments/qrModo/qrModoTransaction/QRModoTicket";
import ServicePayment from "~/pages/payments/servicePayments/ServicePayment";
import ServicePaymentTicket from "~/pages/payments/servicePayments/ServicePaymentTicket";
import Position from "~/pages/position/Position";
import PreferentialTradingPrice from "~/pages/preferentialTradingPrice/PreferentialTradingPrice";
import PreferentialTradingPriceReadTicket from "~/pages/preferentialTradingPrice/PreferentialTradingPriceReadTicket";
import RecoveryPassStep1External from "~/pages/recoveryPassword/External/RecoveryPassStep1";
import RecoveryPassStep2External from "~/pages/recoveryPassword/External/RecoveryPassStep2";
import RecoveryPassStep3External from "~/pages/recoveryPassword/External/RecoveryPassStep3";
import RecoveryPassStep4External from "~/pages/recoveryPassword/External/RecoveryPassStep4";
import RecoveryPassStep1NoToken from "~/pages/recoveryPassword/noToken/RecoveryPassStep1";
import RecoveryPassStep2NoToken from "~/pages/recoveryPassword/noToken/RecoveryPassStep2";
import RecoveryPassStep3NoToken from "~/pages/recoveryPassword/noToken/RecoveryPassStep3";
import RecoveryPassStep4NoToken from "~/pages/recoveryPassword/noToken/RecoveryPassStep4";
import RecoveryPassStep5NoToken from "~/pages/recoveryPassword/noToken/RecoveryPassStep5";
import ChangeEnvironment from "~/pages/settings/ChangeEnvironment";
import ChangeLanguage from "~/pages/settings/ChangeLanguage";
import ChangePassword from "~/pages/settings/ChangePassword";
import ChangePhone from "~/pages/settings/ChangePhone";
import ChangeSecuritySeal from "~/pages/settings/ChangeSecuritySeal";
import ChangeSecuritySealConfirmation from "~/pages/settings/ChangeSecuritySealConfirmation";
import FingerprintConfiguration from "~/pages/settings/FingerprintConfiguration";
import FingerprintDeleteConfirmation from "~/pages/settings/FingerprintDeleteConfirmation";
import LastLogin from "~/pages/settings/LastLogin";
import NotifConfig from "~/pages/settings/NotificationsConfiguration";
import NotifConfigTransports from "~/pages/settings/NotificationsConfigurationTransports";
import Settings from "~/pages/settings/Settings";
import ChangeDataAccountsForm from "~/pages/settings/changeDataAccounts/ChangeDataAccountsForm";
import ChangeEmailDataAccounts from "~/pages/settings/changeDataAccounts/ChangeEmailDataAccounts";
import ChangeEmail from "~/pages/settings/changeEmail/ChangePersonalEmail";
import ChangeMobilePhone from "~/pages/settings/changeMobilePhone/ChangePersonalMobilePhone";
import PersonalInformationForm from "~/pages/settings/changePersonalInformation/form/PersonalInformationForm";
import PersonalInformationFormCMB from "~/pages/settings/changePersonalInformation/form/PersonalInformationFormCMB";
import PersonalInformationReadTicket from "~/pages/settings/changePersonalInformation/ticket/PersonalInformationReadTicket";
import PersonalInformationTicket from "~/pages/settings/changePersonalInformation/ticket/PersonalInformationTicket";
import SyncUserEmail from "~/pages/settings/syncUserEmail/SyncUserEmail";
import SyncUserMobilePhone from "~/pages/settings/syncUserMobilePhone/SyncUserMobilePhone";
import ChangeDataAccountsTicket from "~/pages/settings/ticket/ChangeDataAccountsTicket";
import ChangeEmailTransaction from "~/pages/settings/ticket/ChangeEmailTransaction";
import ChangeMobilePhoneTransaction from "~/pages/settings/ticket/ChangeMobilePhoneTransaction";
import Share from "~/pages/share/Share";
import SessionAboutToExpire from "~/pages/status/SessionAboutToExpire";
import SwiftDetail from "~/pages/swift/Detail";
import SwiftList from "~/pages/swift/List";
import TransactionsList from "~/pages/transactions/TransactionsList";
import DelayedModal from "~/pages/transactions/_components/DelayedModal";
import TransactionsListHistory from "~/pages/transactionsHistory/TransactionsListHistory";
import Transfers from "~/pages/transfers/Transfers";
import AliasAdministration from "~/pages/transfers/alias/AliasAdministration";
import AliasDeletion from "~/pages/transfers/alias/deletion/AliasDeletion";
import AliasDeletionTicket from "~/pages/transfers/alias/deletion/AliasDeletionTicket";
import AliasEnrollment from "~/pages/transfers/alias/enrollment/AliasEnrollment";
import AliasEnrollmentTicket from "~/pages/transfers/alias/enrollment/AliasEnrollmentTicket";
import FormBuy from "~/pages/wm/funds/buy";
import BuyFundsTransaction from "~/pages/wm/funds/buy/BuyFundsTransaction";
import ListPapers from "~/pages/wm/funds/listPapers/ListPapers";
import SellFunds from "~/pages/wm/funds/sell/SellFunds";
import SellFundsTransactionTicket from "~/pages/wm/funds/sell/SellFundsTransaction";
import SwitchFunds from "~/pages/wm/funds/switch/SwitchFunds";
import SwitchFundsTransactionTicket from "~/pages/wm/funds/switch/SwitchFundsTransaction";
import SearchBonds from "~/pages/wm/otherInvestments/bonds/_components/SearchBonds";
import BuyBonds from "~/pages/wm/otherInvestments/bonds/buy/BuyBonds";
import BuyBondsTicket from "~/pages/wm/otherInvestments/bonds/buy/BuyBondsTicket";
import SellBonds from "~/pages/wm/otherInvestments/bonds/sell/SellBonds";
import SellBondsTicket from "~/pages/wm/otherInvestments/bonds/sell/SellBondsTicket";
import BuyShares from "~/pages/wm/otherInvestments/shares/buy/BuyShares";
import BuySharesTicket from "~/pages/wm/otherInvestments/shares/buy/BuySharesTicket";
import SellShares from "~/pages/wm/otherInvestments/shares/sell/SellShares";
import SellSharesTicket from "~/pages/wm/otherInvestments/shares/sell/SellSharesTicket";

import RouteAdministration from "./components/Route/RouteAdministration";
import RouteValidation from "./components/Route/RouteValidation";

import "./App.rules.scss";

export const NAME = NAME_STORE;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const {
        accountDataForceUpdate,
        accountDataNotify,
        dispatch,
        emailForceUpdate,
        environmentType,
        isAdministrator,
        isLoggedIn,
        lang,
        mobilePhoneForceUpdate,
        personalDataForceUpdate,
        personalDataNotify,
        suggestAliasEnrollment,
        userEmailForceSync,
        userMobilePhoneForceSync,
    } = props;
    const {
        location: { pathname },
    } = useHistory();
    const [isLandscapeMobile, setLandscapeMobile] = React.useState(false);
    const isMobile = DeviceUtils.isMobileNative();

    React.useEffect(() => {
        setLandscapeMobile(DeviceUtils.isLandscapeMobile());
    }, []);

    React.useEffect(() => {
        if (!isMobile) {
            window.scrollTo(0, 0);
        }
    }, [isMobile, pathname]);

    window.addEventListener(
        "orientationchange",
        () => {
            setLandscapeMobile(DeviceUtils.isLandscapeMobile());
        },
        false,
    );

    window.addEventListener(
        "resize",
        () => {
            setLandscapeMobile(DeviceUtils.isLandscapeMobile());
        },
        false,
    );

    window.addEventListener(
        "touchmove",
        (event) => {
            const {
                target: { className },
            } = event;
            if (
                !className ||
                (className && typeof className.includes !== "undefined" && !className.includes("Select"))
            ) {
                document.activeElement.blur();
            }
        },
        false,
    );

    if (pathname === SLASH) {
        dispatch(SelectorsActionLogin.reset());
    }
    if (mobileUrls.indexOf(pathname) > -1 && !isMobile) {
        return <Redirect to={SLASH} />;
    }
    const notificationWithLink = (path) => {
        let body = null;
        if (path === PATH_TO.PERSONAL_DATA_FORM) {
            body = UtilsI18n.get("client.postLoginChecks.updatePersonalDataCheck.body");
        } else if (path === PATH_TO.ACCOUNT_DATA_FORM) {
            body = UtilsI18n.get("client.postLoginChecks.updateAccountDataCheck.body");
        }
        return (
            <div>
                <p>
                    {`${body} `}
                    <Link to={path}>
                        <I18n id="client.postLoginChecks.updatePersonalDataCheck.button" />
                    </Link>
                </p>
            </div>
        );
    };

    return (
        <React.Fragment>
            {isLandscapeMobile ? (
                <Unavailable label="global.resolution.unavailable" lang={lang} />
            ) : (
                <Switch>
                    {/* MODAL */}
                    <Route exact path="/login" component={LoginStep1} />
                    <Route exact path="/error" component={PageError} />
                    <Route exact path="/serverError" component={ServerError} />
                    <Route exact path="/oldVersion" component={OldVersion} />
                    <Route exact path="/mapLocations" component={Locations} />

                    {/* INFORMATION */}
                    <Route exact path="/information" component={Information} />
                    <Route exact path="/frequentQuestions" component={FrequentQuestions} />
                    <Route exact path="/generalConditions" component={GeneralConditions} />
                    <Route exact path="/security" component={Security} />
                    <Route exact path="/informationQuery" component={InformationQuery} />
                    <Route exact path="/depositGuarantee" component={DepositGuarantee} />
                    <Route exact path="/loginStep2" component={LoginStep2} />
                    <Route exact path="/loginStep4" component={LoginStep4} />
                    <Route exact path="/OTP" component={SoftOTP} />
                    <Route exact path="/invitationCode" component={InvitationCode} />

                    {/* CHANGE PHONE */}
                    <Route exact path="/changePhoneStep1" component={ChangePhoneStep1} />
                    <Route exact path="/changeMyPhone/changePhoneStep2" component={ChangePhoneStep2} />
                    <Route exact path="/changeMyPhone/changePhoneStep3" component={ChangePhoneStep3} />
                    <Route exact path="/changeMyPhone/invitationCode" component={InvitationCode} />
                    <Route exact path="/changeMyPhone/verificationCode" component={VerificationCode} />
                    <Route exact path="/changeMyPhone/registerBiometric" component={RegisterBiometric} />
                    <Route exact path="/changeMyPhone/finishEnrollment" component={FinishEnrollment} />
                    <Route exact path="/biometricTypeChoice" component={BiometricTypeChoice} />

                    {/* ENROLLMENT */}
                    <Route exact path="/enrollment/verificationCode" component={VerificationCode} />
                    <Route exact path="/enrollment/registerPersonalData" component={RegisterPersonalData} />
                    <Route exact path="/enrollment/securityTips" component={SecurityTips} />
                    <Route exact path="/enrollment/registerBiometric" component={RegisterBiometric} />
                    <Route exact path="/enrollment/finishEnrollment" component={FinishEnrollment} />
                    <Route exact path="/enrollmentBiometric" component={EnrollmentBiometric} />

                    {/* EXTERNAL PAYMENTS */}
                    <Route exact path="/sistarbanc" component={LoginStep1} />
                    <Route exact path="/bevsa" component={LoginStep1} />

                    {/* RECOVERY PASSWORD */}
                    <Route exact path="/recoveryPass" component={RecoveryPassStep1External} />
                    <Route exact path="/recoveryPass/step2" component={RecoveryPassStep2External} />
                    <Route exact path="/recoveryPass/step3" component={RecoveryPassStep3External} />
                    <Route exact path="/recoveryPass/step4" component={RecoveryPassStep4External} />

                    {/* PASSWORD RECOVERY WITHOUT TOKEN */}
                    <Route exact path="/recoveryPassNoToken" component={RecoveryPassStep1NoToken} />
                    <Route exact path="/recoveryPassNoToken/step2" component={RecoveryPassStep2NoToken} />
                    <Route exact path="/recoveryPassNoToken/step3" component={RecoveryPassStep3NoToken} />
                    <Route exact path="/recoveryPassNoToken/step4" component={RecoveryPassStep4NoToken} />
                    <Route exact path="/recoveryPassNoToken/step5" component={RecoveryPassStep5NoToken} />

                    {/* BACKOFFICE TRANSACTION */}
                    <Route exact path="/forms/backoffice/ticket" component={BackofficeTransaction} />
                    <Route
                        path="/form/:idForm/processDetail/backoffice"
                        render={(propsParam) => (
                            <FormPaymentLines {...propsParam} isConfirmation isFromBackoffice isTicket />
                        )}
                    />

                    {!isLoggedIn ? (
                        <React.Fragment>
                            <Route exact path="/" component={ExternalDashboard} />
                            <Route exact path="/login" component={LoginStep1} />
                            {/* from here below a valid session must be active */}
                            <Redirect to="/" />
                        </React.Fragment>
                    ) : (
                        <Private />
                    )}

                    <Route path="/" component={PasswordModal} />
                </Switch>
            )}
        </React.Fragment>
    );

    function Private() {
        React.useEffect(() => {
            dispatch(ActionStatus.saveLastHref({ lastHref: pathname }));

            if (personalDataForceUpdate) {
                dispatch(
                    SelectorsActionNotification.showNotification({
                        message: UtilsI18n.get("client.userconfiguration.changePersonalData.warning"),
                        level: LEVEL.SUCCESS,
                        scopes: [SCOPE.PERSONAL_INFORMATION],
                    }),
                );
            } else if (accountDataForceUpdate) {
                dispatch(
                    SelectorsActionNotification.showNotification({
                        message: UtilsI18n.get("client.envconfiguration.changeAccountData.warning"),
                        level: LEVEL.SUCCESS,
                        scopes: [SCOPE.PERSONAL_INFORMATION],
                    }),
                );
            } else if (personalDataNotify) {
                dispatch(
                    SelectorsActionNotification.showNotification({
                        children: notificationWithLink(PATH_TO.PERSONAL_DATA_FORM),
                        level: LEVEL.SUCCESS,
                        scopes: [SCOPE.DESKTOP],
                    }),
                );
            } else if (accountDataNotify) {
                dispatch(
                    SelectorsActionNotification.showNotification({
                        children: notificationWithLink(PATH_TO.ACCOUNT_DATA_FORM),
                        level: LEVEL.SUCCESS,
                        scopes: [SCOPE.DESKTOP],
                    }),
                );
            }
        }, []);
        return (
            <React.Fragment>
                <Route exact path="/" component={Desktop} />
                <Route exact path="/desktop" component={Desktop} />
                <Route exact path="/transfers" component={Transfers} />
                <Route exact path="/share" component={Share} />

                <RouteValidation
                    exact
                    path="/factoring"
                    component={Factoring}
                    hasPermission={PERMISSIONS.HAS_ANY_FACTORING_PERMISSION}
                />

                {/* CARDS */}
                <Route exact path="/creditCards" component={CCList} />
                <Route exact path="/creditCards/:id" component={CCDetails} />
                <Route exact path="/creditCards/:id/operations" component={CCOperations} />
                <Route exact path="/creditCards/:id/setAlias" component={CCOperationsAlias} />
                <Route exact path="/creditCards/:id/operations/statements" component={CCOperationsStatements} />
                <Route exact path="/creditCardsPayment/list" component={CreditCardsPaymentList} />
                <Route exact path="/creditCardsLimitIncrease/" component={CCLimitIncrease} />
                <Route exact path="/creditCardsLimitIncrease/:id/ticket" component={CCLimitIncreaseTicket} />
                <Route exact path="/debitCard/:id" component={DebitCardDetails} />
                <Route exact path="/purchaseNotification" component={PurchaseNotification} />
                <Route exact path="/purchaseNotification/:id/ticket" component={PurchaseNotificationTicket} />
                <Route exact path="/tripOrOnlinePurchaseNotification" component={TripOrOnlinePurchaseNotification} />
                <Route
                    exact
                    path="/tripOrOnlinePurchaseNotification/:id/ticket"
                    component={TripOrOnlinePurchaseNotificationTicket}
                />
                {/* ACCOUNTS */}
                <RouteValidation exact path="/accounts" component={Accounts} hasPermission={PERMISSIONS.ACCOUNTS} />
                <Route exact path="/accounts/:id" component={AccountDetail} />
                <Route exact path="/accounts/:id/setAlias" component={AccountsOperationsAlias} />
                <Route exact path="/accounts/:id/filters" component={Filters} />
                <Route exact path="/accounts/:id/filters/results" component={AccountsFilterResults} />
                <Route exact path="/accounts/:id/operations" component={AccountsOperations} />
                <Route exact path="/accounts/:id/stateaccount" component={AccountOperationsStatements} />
                {/* POSITION   */}
                <RouteValidation exact path="/position" component={Position} hasPermission={PERMISSIONS.POSITION} />
                {/* FINANCING   */}
                <Route exact path="/financing" component={Financing} />
                {/* SETTINGS */}
                <Route exact path="/settings" component={Settings} />
                <Route exact path="/settings/changePassword" component={ChangePassword} />
                <Route exact path="/settings/changeSecuritySeal" component={ChangeSecuritySeal} />
                <Route
                    exact
                    path="/settings/changeSecuritySeal/confirmation"
                    component={ChangeSecuritySealConfirmation}
                />
                <Route exact path="/settings/notificationsConfiguration" component={NotifConfig} />
                <Route
                    path="/settings/notificationsConfiguration/:communicationType"
                    component={NotifConfigTransports}
                />
                <Route exact path="/settings/fingerprintConfiguration" component={FingerprintConfiguration} />
                <Route
                    exact
                    path="/settings/fingerprintConfiguration/deleteConfirmation"
                    component={FingerprintDeleteConfirmation}
                />
                <Route exact path="/settings/changeEnvironment" component={ChangeEnvironment} />
                <Route exact path="/settings/changeLanguage" component={ChangeLanguage} />
                <Route exact path="/settings/changeEmail" component={ChangeEmail} />
                <Route exact path="/settings/changeMobilePhone" component={ChangeMobilePhone} />
                <Route
                    exact
                    path="/settings/changeEmail/transaction/:idTransaction/ticket"
                    component={ChangeEmailTransaction}
                />
                <Route
                    exact
                    path="/settings/changeMobilePhone/transaction/:idTransaction/ticket"
                    component={ChangeMobilePhoneTransaction}
                />
                <Route exact path="/settings/changePhone" component={ChangePhone} />
                <Route exact path="/settings/changePhone/confirmation" component={ChangePhoneConfirmation} />
                <Route exact path="/settings/LastLogin" component={LastLogin} />
                <Route
                    exact
                    path="/settings/changePersonalInformation/form"
                    component={
                        environmentType !== ENVIRONMENT_TYPE.CORPORATE
                            ? PersonalInformationForm
                            : PersonalInformationFormCMB
                    }
                />
                <Route exact path="/settings/syncUserEmail/form" component={SyncUserEmail} />
                <Route exact path="/settings/syncUserMobilePhone/form" component={SyncUserMobilePhone} />
                <Route exact path="/connectedParties" component={ConnectedPartiesForm} />
                <Route exact path="/connectedParties/:id/ticket" component={ConnectedPartiesReadTicket} />
                <Route exact path="/settings/changePersonalInformation/ticket" component={PersonalInformationTicket} />
                <Route
                    exact
                    path="/settings/changePersonalInformation/ticket/:id"
                    component={PersonalInformationReadTicket}
                />
                <Route exact path="/settings/changeDataAccounts/form" component={ChangeDataAccountsForm} />
                <Route exact path="/settings/changeDataAccounts/changeEmail" component={ChangeEmailDataAccounts} />
                <Route
                    exact
                    path="/settings/changeDataAccounts/changeEmail/transaction/:idTransaction/ticket"
                    component={ChangeEmailTransaction}
                />
                <Route
                    exact
                    path="/settings/changeDataAccounts/:idTransaction/ticket"
                    component={ChangeDataAccountsTicket}
                />
                {/* LOANS */}
                <Route exact path="/loans" component={LList} />
                <Route exact path="/loans/:id/" component={LoanDetails} />
                <Route exact path="/loans/:id/setAlias" component={LoanSetAlias} />
                <Route exact path="/loans/:id/statement" component={LoanStatementDetail} />
                <Route exact path="/loans/:id/filters" component={LoanMobileFilters} />
                <Route exact path="/loans/:id/operations" component={MobileLoanOperations} />
                {/* FILES DOWNLOAD */}
                <Route exact path="/filesDownload" component={ListFilesDownloads} />
                {/* FORMS */}
                <Route exact path="/form/:idForm" component={Form} />
                <Route path="/form/:idForm/manual" component={FormPaymentLines} />
                <Route
                    path="/form/:idForm/processDetail"
                    render={(propsParam) => <FormPaymentLines {...propsParam} isConfirmation isTicket />}
                />
                <Route path="/form/:formId/bankSearch/:fieldId/:debitAccount" component={BankSearch} />
                {/* COMMUNICATIONS */}
                <Route exact path="/communications" component={CommunicationMain} />
                <Route exact path="/communications/compose" component={NewMessage} />
                <Route exact path="/communications/reply/:idThread/:idCommunication" component={ReplyMessage} />
                <Route exact path="/communications/read/:idThread/:idCommunication" component={ReadMessage} />
                {/* TRANSACTIONS */}
                <Route exact path="/transactions/list" component={TransactionsList} />
                <Route exact path="/transactions/history/list" component={TransactionsListHistory} />
                <Route exact path="/transaction/:idTransaction" component={Transaction} />
                <Route exact path="/transactionHistory/:idTransaction" component={TransactionHistory} />
                <Route path="/transaction/:idTransaction/bankSearch/:fieldId" component={BankSearch} />
                <Route path="/resumeTransaction" component={ResumeTransaction} />
                {/* FX */}
                <RouteValidation
                    exact
                    path="/preferentialTradingPrice"
                    component={PreferentialTradingPrice}
                    hasPermission={PERMISSIONS.PREFERENTIAL_TRADING_PRICE}
                />
                <Route
                    exact
                    path="/preferentialTradingPrice/:id/ticket"
                    component={PreferentialTradingPriceReadTicket}
                />
                {/* Migration Hard to Soft */}
                <Route path="/checkPhone" component={CheckMobilePhone} />
                <Route path="/migrationSuggest" component={MigrationSuggest} />
                <Route path="/changePhone/confirmation" component={ChangePhoneConfirmation} />
                {/* MODAL */}
                <BackButtonListener />
                <Route path="/" component={PasswordModal} />
                <Route path="/" component={SessionAboutToExpire} />
                <Route path="/" component={DelayedModal} />
                <Route path="/" component={AboutIbanca} />
                <Route path="/finishMigration" component={FinishMigration} />
                <Route path="/securityTips" component={SecurityTips} />
                <Route path="/registerBiometric" component={RegisterBiometric} />
                <Route path="/generalConditionsLogin" component={GeneralConditionsLogin} />
                <Route exact path="/versionWarningAndLogout" component={VersionWarningAndLogout} />
                {/* FACTORING */}
                <Route exact path="/paymentorders" component={ListPaymentOrders} />
                <Route exact path="/uploadPaymentOrders" component={UploadPaymentOrders} />
                <Route
                    exact
                    path="/uploadPaymentOrders/transaction/:id/ticket"
                    component={UploadPaymentOrdersTransaction}
                />
                <Route exact path="/advancePayments/transaction" component={ListAdvancePaymentsTransaction} />
                <Route
                    exact
                    path="/advancePayments/transaction/:id/ticket"
                    component={AdvancePaymentsTransactionTicket}
                />
                <Route exact path="/suppliers" component={ListSuppliers} />
                <Route exact path="/uploadSuppliers/transaction/:id/ticket" component={UploadSuppliersTransaction} />
                <Route exact path="/uploadSuppliers" component={UploadSuppliers} />
                <Route exact path="/listAdvancePayments" component={ListAdvancePayments} />
                {/* AUTOMATIC DEBITS */}
                <RouteValidation
                    exact
                    path="/automaticDebits"
                    component={AutomaticDebitsList}
                    hasPermission={PERMISSIONS.AUTOMATIC_DEBITS}
                />
                {/* CUSTOMS PAYMENT */}
                <Route exact path="/customsPayment/:idTransaction/ticket" component={CustomsPaymentReadTicket} />
                <RouteValidation
                    exact
                    path="/customsPayment"
                    component={CustomsPayment}
                    hasPermission={PERMISSIONS.CUSTOMS_PAYMENT}
                />
                {/* SERVICE PAYMENTS */}
                <RouteValidation
                    exact
                    path="/servicePayment"
                    component={ServicePayment}
                    hasPermission={PERMISSIONS.SERVICE_PAYMENT}
                />
                <Route exact path="/servicePayment/:id/ticket" component={ServicePaymentTicket} />
                <RouteValidation
                    exact
                    path="/servicePayment/qrModo/otpRequest"
                    component={OtpRequest}
                    hasPermission={PERMISSIONS.SERVICE_PAYMENT}
                />
                <Route exact path="/servicePayment/qrModo/list" component={QrModoList} />
                <Route exact path="/servicePayment/qrModo/transaction/:id/ticket" component={QrModoTicket} />
                {/* SWIFT */}
                <RouteValidation exact path="/swift" component={SwiftList} hasPermission={PERMISSIONS.SWIFT} />
                <Route exact path="/swift/detail/:swiftKey+" component={SwiftDetail} />
                {/* ADMINISTRATION */}
                <Route exact path="/administration/users" component={Users} />
                <Route exact path="/administration/users/userInvite" component={UserInvite} />
                <Route exact path="/administration/users/inviteStep2" component={UserInviteStep2} />
                <Route
                    exact
                    path="/administration/medium/userInvite/:idTransaction/ticket"
                    component={UserInviteTicket}
                />
                <Route
                    exact
                    path="/administration/groups/actions/:idTransaction/ticket"
                    component={AdministrationGroupFormBlockTicket}
                />
                {/* ADMINISTRATION  */}
                {isAdministrator && (
                    <React.Fragment>
                        <RouteAdministration
                            exact
                            path="/administration/simple/permissions/:id"
                            render={(prop) => <AdministrationSimplePermissions {...prop} />}
                            typeAdmin={TYPE_ADMINISTRATION.SIMPLE}
                        />
                        <Route
                            exact
                            path="/administration/simple/permissions/:idTransaction/ticket"
                            component={AdministrationSimplePermissionsTicket}
                        />
                        <RouteAdministration
                            exact
                            path="/administration/medium/permissions/:id"
                            render={(prop) => <AdministrationMediumPermissions {...prop} />}
                            typeAdmin={TYPE_ADMINISTRATION.MEDIUM}
                        />
                        <Route
                            exact
                            path="/administration/medium/permissions/:idTransaction/ticket"
                            component={AdministrationMediumPermissionsTicket}
                        />
                        <RouteAdministration
                            exact
                            path="/administration/medium/signaturesSchemes"
                            component={AdministrationMediumSignaturesSchemes}
                            typeAdmin={TYPE_ADMINISTRATION.MEDIUM}
                        />
                        <RouteAdministration
                            exact
                            path="/administration/medium/signaturesSchemes/modify"
                            component={AdministrationMediumSignaturesSchemeModify}
                            typeAdmin={TYPE_ADMINISTRATION.MEDIUM}
                        />
                        <RouteAdministration
                            exact
                            path="/administration/advanced/signaturesSchemes/create"
                            component={AdministrationAdvancedSignaturesSchemeCreate}
                            typeAdmin={TYPE_ADMINISTRATION.ADVANCED}
                        />
                        <RouteAdministration
                            exact
                            path="/administration/advanced/signaturesSchemes/modify/:id"
                            component={AdministrationAdvancedSignaturesSchemeModify}
                            typeAdmin={TYPE_ADMINISTRATION.ADVANCED}
                        />
                        <Route
                            exact
                            path="/administration/advanced/signaturesSchemes/:idTransaction/ticket"
                            component={AdministrationAdvancedSignaturesSchemeTicket}
                        />
                        <Route
                            exact
                            path="/administration/medium/signature/:idTransaction/ticket"
                            component={AdministrationMediumSignaturesSchemeTicket}
                        />
                        <RouteAdministration
                            exact
                            path="/administration/advanced/groups"
                            component={Groups}
                            typeAdmin={TYPE_ADMINISTRATION.ADVANCED}
                        />
                        <RouteAdministration
                            path="/administration/advanced/createGroup"
                            component={AdministrationAdvancedGroupForm}
                            typeAdmin={TYPE_ADMINISTRATION.ADVANCED}
                        />
                        <RouteAdministration
                            path="/administration/advanced/modifyGroup/:id"
                            component={AdministrationAdvancedGroupForm}
                            typeAdmin={TYPE_ADMINISTRATION.ADVANCED}
                        />
                        <Route path="/investment/transaction/riskProfile/:id/ticket" component={RiskProfileTicket} />
                        <RouteAdministration
                            exact
                            path="/administration/advanced/confirmGroupAction"
                            component={GroupActionConfirmation}
                            typeAdmin={TYPE_ADMINISTRATION.ADVANCED}
                        />
                        <Route
                            exact
                            path="/administration/advanced/groupFormData/:idTransaction/ticket"
                            component={AdministrationAdvancedGroupFormDataTicket}
                        />
                        <RouteAdministration
                            exact
                            path="/administration/advanced/details/:id"
                            component={AdministrationAdvancedSignatureConfirm}
                            typeAdmin={TYPE_ADMINISTRATION.ADVANCED}
                        />
                        <RouteAdministration
                            exact
                            path="/administration/advanced/signaturesSchemes"
                            component={AdministrationAdvancedSignaturesSchemes}
                            typeAdmin={TYPE_ADMINISTRATION.ADVANCED}
                        />
                        <Route
                            exact
                            path="/administration/advanced/:idTransaction/ticket"
                            component={AdministrationAdvancedSignatureTicket}
                        />
                    </React.Fragment>
                )}
                {/* INVESTMENT */}
                <Route exact path="/investment" component={Investment} />
                <Route exact path="/investment/termdeposit/" component={TermDeposit} />
                <Route exact path="/investment/transaction/termDeposit/:id/ticket" component={TermDepositTicket} />
                <Route exact path="/investment/requestInfo" component={InvestmentRequestInfo} />
                <Route exact path="/investment/riskProfile" component={RiskProfile} />
                <Route exact path="/investment/riskProfile2" component={RiskProfileStep2} />
                <Route exact path="/investment/riskProfileQuiz" component={RiskProfileQuiz} />
                <Route exact path="/investment/riskProfileResume" component={RiskProfileResume} />
                {/* WM */}
                <Route exact path="/wm/bonds/buy" component={BuyBonds} />
                <Route exact path="/wm/bonds/buy/:id/ticket" component={BuyBondsTicket} />
                <Route exact path="/wm/bonds/buy/bondSearch/:isin?" component={SearchBonds} />
                <Route exact path="/wm/bonds/sell/:idPaper" component={SellBonds} />
                <Route exact path="/wm/bonds/sell/:id/ticket" component={SellBondsTicket} />
                <Route exact path="/wm/shares/buy" component={BuyShares} />
                <Route exact path="/wm/shares/buy/:id/ticket" component={BuySharesTicket} />
                <Route exact path="/wm/shares/sell/:idPaper" component={SellShares} />
                <Route exact path="/wm/shares/sell/:id/ticket" component={SellSharesTicket} />
                <Route exact path="/wm/funds/list" component={ListPapers} />
                <Route exact path="/wm/funds/switch" component={SwitchFunds} />
                <Route exact path="/wm/funds/switch/:id/ticket" component={SwitchFundsTransactionTicket} />
                <Route exact path="/wm/funds/buy" component={FormBuy} />
                <Route exact path="/wm/funds/buy/:id/ticket" component={BuyFundsTransaction} />
                <Route exact path="/wm/funds/sell" component={SellFunds} />
                <Route exact path="/wm/funds/sell/:id/ticket" component={SellFundsTransactionTicket} />
                {/* COM-EX */}
                <Route exact path="/comex" component={ComEx} />
                <RouteValidation
                    exact
                    path="/comex/import/creditLetter"
                    component={CreditLetter}
                    hasPermission={PERMISSIONS.COMEX_IMPORT_CREDIT_LETTER}
                />
                <Route
                    exact
                    path="/comex/import/creditLetterOpen/:id/ticket"
                    component={ImportCreditLetterOpenTicket}
                />
                <RouteValidation
                    exact
                    path="/comex/export/transferCreditLetter/:operationNumber?"
                    component={TransferCreditLetter}
                    hasPermission={PERMISSIONS.COMEX_TRANSFER_CREDIT_LETTER}
                />
                <Route
                    exact
                    path="/comex/export/transferCreditLetter/:id/ticket"
                    component={TransferCreditLetterTicket}
                />
                <RouteValidation
                    exact
                    path="/comex/import/modifyCreditLetter/:operationNumber?"
                    component={ModifyCreditLetter}
                    hasPermission={PERMISSIONS.COMEX_MODIFY_CREDIT_LETTER}
                />
                <Route exact path="/comex/import/modifyCreditLetter/:id/ticket" component={ModifyCreditLetterTicket} />
                <RouteValidation
                    exact
                    path="/comex/import/cancelaciondecartadecredito/:operationNumber?"
                    component={CancellationLetterCredit}
                    hasPermission={PERMISSIONS.COMEX_IMPORT_CANCELLATION_CREDIT_LETTER}
                />
                <Route
                    exact
                    path="/comex/import/cancellationCreditLetter/:id/ticket"
                    component={CancellationLetterCreditTicket}
                />
                <RouteValidation
                    exact
                    path="/comex/import/billingcancellation/:operationNumber?"
                    component={BillingCancellation}
                    hasPermission={PERMISSIONS.COMEX_IMPORT_BILLING_CANCELLATION}
                />
                <Route
                    exact
                    path="/comex/import/billingcancellation/:id/ticket"
                    component={BillingCancellationTicket}
                />
                <RouteValidation
                    exact
                    path="/comex/import/documentPresentation/:operationNumber?"
                    component={PresentationLDDomesticCredit}
                    hasPermission={PERMISSIONS.COMEX_IMPORT_DOCUMENT_PRESENTATION}
                />
                <Route
                    exact
                    path="/comex/import/documentPresentation/:id/ticket"
                    component={PresentationLDDomesticCreditTicket}
                />
                <RouteValidation
                    exact
                    path="/comex/import/liftCreditLetterDiscrepancies"
                    component={LiftCreditLetterWithDiscrepancies}
                    hasPermission={PERMISSIONS.COMEX_IMPORT_LIFT_CREDIT_LETTER_DISCREPANCIES}
                />
                <Route
                    exact
                    path="/comex/import/liftCreditLetterDiscrepancies/:id/ticket"
                    component={LiftCreditLetterWithDiscrepanciesTicket}
                />

                <RouteValidation
                    exact
                    path="/comex/import/requestEndorsement/:operationNumber?"
                    component={RequestEndorsement}
                    hasPermission={PERMISSIONS.COMEX_IMPORT_REQUEST_ENDORSEMENT}
                />
                <Route exact path="/comex/import/requestEndorsement/:id/ticket" component={RequestEndorsementTicket} />
                <RouteValidation
                    exact
                    path="/comex/export/compracartacredito"
                    component={PurchaseDocuments}
                    hasPermission={PERMISSIONS.COMEX_CREDIT_LETTER_PURCHASE}
                />
                <Route exact path="/comex/export/compracartacredito/:id/ticket" component={PurchaseDocumentsTicket} />
                <RouteValidation
                    exact
                    path="/comex/export/discrepancyDocumentsAuthorization/:operationNumber?"
                    component={AuthorizationDDiscrepancies}
                    hasPermission={PERMISSIONS.COMEX_EXPORT_AUTHORIZATION_DDISCREPANCIES}
                />
                <Route
                    exact
                    path="/comex/export/discrepancyDocumentsAuthorization/:id/ticket"
                    component={AuthorizationDDiscrepanciesTicket}
                />
                <RouteValidation
                    exact
                    path="/comex/other/formulariogenerico"
                    component={OtherProcedures}
                    hasPermission={PERMISSIONS.COMEX_OTHER_PROCEDURES}
                />
                <Route exact path="/comex/other/formulariogenerico/:id/ticket" component={OtherProceduresTicket} />
                <RouteValidation
                    exact
                    path="/comex/export/presentationDocumentsCreditLetter/:operationNumber?"
                    component={PresentationDocumentsCreditLetter}
                    hasPermission={PERMISSIONS.COMEX_EXPORT_CREDIT_LETTER_DOCUMENT_PRESENTATION}
                />
                <Route
                    exact
                    path="/comex/export/presentationDocumentsCreditLetter/:id/ticket"
                    component={PresentationDocumentsCreditLetterTicket}
                />
                <RouteValidation
                    exact
                    path="/comex/bondsAndGuarantees/requestBondsAndGuarantees"
                    component={RequestBondsAndGuarantees}
                    hasPermission={PERMISSIONS.COMEX_GUARANTEES_READ}
                />
                <Route
                    exact
                    path="/comex/bondsAndGuarantees/requestBondsAndGuarantees/:id/ticket"
                    component={RequestBondsAndGuaranteesTicket}
                />
                <RouteValidation
                    exact
                    path="/comex/export/cessionCreditLetter/:operationNumber?"
                    component={CessionCreditLetter}
                    hasPermission={PERMISSIONS.COMEX_CESSION_CREDIT_LETTER}
                />
                <Route
                    exact
                    path="/comex/export/cessionCreditLetter/:id/ticket"
                    component={CessionCreditLetterTicket}
                />
                <RouteValidation
                    exact
                    path="/comex/export/presentBillingDocuments"
                    component={PresentBillingDocuments}
                    hasPermission={PERMISSIONS.COMEX_EXPORT_PRESENT_BILLING_DOCUMENTS}
                />
                <Route
                    exact
                    path="/comex/export/presentBillingDocuments/:id/ticket"
                    component={PresentBillingDocumentsTicket}
                />
                <RouteValidation
                    exact
                    path="/comex/bondsAndGuarantees/modifyBondsAndGuarantees/:operationNumber?"
                    component={ModifyBondsAndGuarantees}
                    hasPermission={PERMISSIONS.COMEX_MODIFY_BONDS_AND_GUARANTEES}
                />
                <Route
                    exact
                    path="/comex/bondsAndGuarantees/modifyBondsAndGuarantees/:id/ticket"
                    component={ModifyBondsAndGuaranteesTicket}
                />
                <RouteValidation
                    exact
                    path="/comex/export/billingClaim/:operationNumber?"
                    component={BillingClaim}
                    hasPermission={PERMISSIONS.COMEX_EXPORT_BILLING_CLAIM}
                />
                <Route exact path="/comex/export/billingClaim/:id/ticket" component={BillingClaimTicket} />

                {/* ALIAS */}
                <Route exact path="/transfers/aliasAdministration" component={AliasAdministration} />
                <Route exact path="/transfers/aliasDeletion" component={AliasDeletion} />
                <Route exact path="/aliasDeletion/:id/ticket" component={AliasDeletionTicket} />
                <Route exact path="/transfers/aliasEnrollment" component={AliasEnrollment} />
                <Route exact path="/aliasEnrollment/:id/ticket" component={AliasEnrollmentTicket} />

                {/* REDIRECTIONS */}
                {personalDataForceUpdate && pathname !== PATH_TO.PERSONAL_DATA_FORM && (
                    <Redirect to={PATH_TO.PERSONAL_DATA_FORM} />
                )}
                {accountDataForceUpdate && !personalDataForceUpdate && pathname !== PATH_TO.ACCOUNT_DATA_FORM && (
                    <Redirect to={PATH_TO.ACCOUNT_DATA_FORM} />
                )}
                {suggestAliasEnrollment && pathname !== PATH_TO.ALIAS_ENROLLMENT_FORM && (
                    <Redirect to={PATH_TO.ALIAS_ENROLLMENT_FORM} />
                )}

                {userEmailForceSync &&
                    !emailForceUpdate &&
                    pathname !== PATH_TO.CHANGE_PERSONAL_DATA_TICKET &&
                    pathname !== PATH_TO.PERSONAL_DATA_FORM &&
                    pathname !== PATH_TO.SYNC_USER_EMAIL_FORM && <Redirect to={PATH_TO.SYNC_USER_EMAIL_FORM} />}

                {emailForceUpdate &&
                    !userEmailForceSync &&
                    pathname !== PATH_TO.CHANGE_PERSONAL_DATA_TICKET &&
                    pathname !== PATH_TO.PERSONAL_DATA_FORM &&
                    pathname !== PATH_TO.SYNC_USER_EMAIL_FORM &&
                    pathname !== PATH_TO.CHANGE_EMAIL && <Redirect to={PATH_TO.CHANGE_EMAIL} />}

                {userMobilePhoneForceSync &&
                    !userEmailForceSync &&
                    !emailForceUpdate &&
                    pathname !== PATH_TO.CHANGE_PERSONAL_DATA_TICKET &&
                    pathname !== PATH_TO.PERSONAL_DATA_FORM &&
                    pathname !== PATH_TO.SYNC_USER_MOBILE_PHONE_FORM &&
                    pathname !== PATH_TO.SYNC_USER_EMAIL_FORM &&
                    pathname !== PATH_TO.CHANGE_EMAIL && <Redirect to={PATH_TO.SYNC_USER_MOBILE_PHONE_FORM} />}

                {mobilePhoneForceUpdate &&
                    !emailForceUpdate &&
                    !userEmailForceSync &&
                    pathname !== PATH_TO.SYNC_USER_EMAIL_FORM &&
                    pathname !== PATH_TO.CHANGE_PERSONAL_DATA_TICKET &&
                    pathname !== PATH_TO.PERSONAL_DATA_FORM &&
                    pathname !== PATH_TO.CHANGE_EMAIL &&
                    pathname !== PATH_TO.CHANGE_MOBILE_PHONE && <Redirect to={PATH_TO.CHANGE_MOBILE_PHONE} />}
            </React.Fragment>
        );
    }
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => {
    // used by environment change to refrsh when selecting an environment.
    const { id: environment, type: environmentType } = StoreSession.getActiveEnvironment(store) || {};

    const accountDataForceUpdate = StoreSession.getAccountDataForceUpdate(store);
    const accountDataNotify = StoreSession.getAccountDataNotify(store);
    const emailForceUpdate = StoreSession.getEmailForceUpdate(store);
    const mobilePhoneForceUpdate = StoreSession.getMobilePhoneForceUpdate(store);
    const personalDataForceUpdate = StoreSession.getPersonalDataForceUpdate(store);
    const personalDataNotify = StoreSession.getPersonalDataNotify(store);
    const suggestAliasEnrollment = StoreSession.getSuggestAliasEnrollment(store);
    const userEmailForceSync = StoreSession.getUserEmailForceSync(store);
    const userMobilePhoneForceSync = StoreSession.getUserMobilePhoneForceSync(store);

    return {
        accountDataForceUpdate,
        accountDataNotify,
        emailForceUpdate,
        environment,
        environmentType,
        isAdministrator: StoreSession.isAdministrator(store),
        isLoggedIn: StoreSession.isLoggedIn(store),
        lang: StoreI18n.getLang(store),
        mobilePhoneForceUpdate,
        personalDataForceUpdate,
        personalDataNotify,
        suggestAliasEnrollment,
        userEmailForceSync,
        userMobilePhoneForceSync,
    };
};

export default Connect(mapStateToProps)(Component);
