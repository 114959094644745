import React from "react";

import { connect as Connect } from "react-redux";

import ContainerExternal from "~/containers/External/Form";
import ContainerInternal from "~/containers/Internal/Form/Simple";

import { PROP as STORE_PROP } from "~/store/locations";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";

import GoogleMaps from "./GoogleMaps";

import Style from "./Locations.rules.scss";

export const { NAME, TYPE } = Style;

export const PROP = {
    types: {
        ...STORE_PROP.types,
    },
    defaults: {
        ...STORE_PROP.defaults,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    handleBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    render() {
        const { locationList, fetching, isLoggedIn } = this.props;

        const Container = ({ ...rest }) =>
            isLoggedIn ? <ContainerInternal {...rest} /> : <ContainerExternal {...rest} head-id="locations" />;
        Container.displayName = `${NAME}.Container`;

        return (
            <Container
                head-title="branchoffice.and.cashier"
                head-darkTheme
                name={NAME}
                wait={false}
                head-onBack={this.handleBack}
                className={Style.CLASS}>
                <GoogleMaps />
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    isLoggedIn: SelectorsStoreSession.isLoggedIn(store),
});

export default Connect(mapStateToProps)(Component);
