import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { SelectorsStore as SelectorsStoreForm } from "~/store/form";

import I18n from "~/components/I18n";
import Image from "~/components/Image";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import FormattedRate from "~/pages/_components/FormattedRate";

export const NAME = "TransferThirdParties";

export const PROP = {
    types: {
        previewData: PropTypes.shape({
            amount: PropTypes.shape({
                currency: PropTypes.string.isRequired,
                quantity: PropTypes.number.isRequired,
            }).isRequired,
            creditAccount: PropTypes.string.isRequired,
            creditAmount: PropTypes.shape({
                quantity: PropTypes.number.isRequired,
            }).isRequired,
            debitAccountAlias: PropTypes.string.isRequired,
            debitAmount: PropTypes.shape({
                currency: PropTypes.string.isRequired,
                quantity: PropTypes.number.isRequired,
            }).isRequired,
            manualRevisionWarning: PropTypes.bool.isRequired,
            notificationBody: PropTypes.string,
            notificationMails: PropTypes.arrayOf(PropTypes.string),
            rate: PropTypes.number,
            rateFirstCurrency: PropTypes.string,
            rateSecondCurrency: PropTypes.string,
            reference: PropTypes.string.isRequired,
            transferWithoutFundsWarning: PropTypes.bool,
        }).isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const { previewData } = props;
    const {
        amount,
        creditAccount,
        creditAccountOwner,
        creditAmount,
        debitAccountAlias,
        debitAmount,
        manualRevisionWarning,
        notificationBody,
        notificationMails,
        rate,
        rateFirstCurrency,
        rateSecondCurrency,
        transferWithoutFundsWarning,
    } = previewData;

    const showDebitAmount = debitAmount.currency !== amount.currency;
    const showCreditAmount = creditAmount.currency !== amount.currency;

    const renderRate = (className, rateToRender, firstCurrency, secondCurrency) => (
        <div className={`transfer-data ${className}`}>
            <span className="data-label">
                <I18n id="forms.confirmation.rate" />{" "}
            </span>
            <FormattedRate firstCurrency={firstCurrency} secondCurrency={secondCurrency} quantity={rateToRender} />
        </div>
    );

    return (
        <React.Fragment>
            <p className="text-lead">
                <I18n id="forms.transferThirdParties.confirmation.title" />
            </p>

            <div className="transfer-block">
                <div className="transfer-data transfer-data-highlight">
                    <span className="data-label">
                        <I18n id="forms.transferThirdParties.confirmation.amount" />{" "}
                    </span>
                    <FormattedAmount currency={amount.currency} quantity={amount.quantity} />
                </div>

                {showCreditAmount && (
                    <React.Fragment>
                        <div className="transfer-data data-wrapper">
                            <span className="data-label">
                                <I18n id="forms.transferThirdParties.confirmation.creditAmount" />{" "}
                            </span>
                            <FormattedAmount currency={creditAmount.currency} quantity={creditAmount.quantity} />
                        </div>
                        {renderRate("data-wrapper", rate, rateFirstCurrency, rateSecondCurrency)}
                    </React.Fragment>
                )}

                {showDebitAmount && (
                    <React.Fragment>
                        <div className="transfer-data data-wrapper">
                            <span className="data-label">
                                <I18n id="forms.transferThirdParties.confirmation.debitAmount" />{" "}
                            </span>
                            <FormattedAmount currency={debitAmount.currency} quantity={debitAmount.quantity} />
                        </div>
                        {renderRate("data-wrapper", rate, rateFirstCurrency, rateSecondCurrency)}
                    </React.Fragment>
                )}

                {manualRevisionWarning && (
                    <span className="data-warning">
                        <I18n id="client.transaction.manualRevisionWarning" />
                    </span>
                )}

                {transferWithoutFundsWarning && (
                    <span className="data-warning">
                        <I18n id="client.transaction.transferWithoutFundsWarning" />
                    </span>
                )}

                <div className="transfer-data transfer-data-flow">
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="forms.transferThirdParties.confirmation.debitAccount" />{" "}
                        </span>
                        <span className="data-name">{debitAccountAlias}</span>
                    </div>
                    <Image src="arrowRight.svg" className="svg-icon" />
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="forms.transferThirdParties.confirmation.creditAccount" />{" "}
                        </span>
                        <span className="data-name">{creditAccount}</span>
                        <span className="data-name">{creditAccountOwner}</span>
                    </div>
                </div>
            </div>

            {notificationMails && notificationMails.length > 0 && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.transferThirdParties.confirmation.notificationMails" />{" "}
                    </span>
                    <span className="data-text">{notificationMails.join(", ")}</span>
                </div>
            )}
            {notificationBody && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.transferThirdParties.confirmation.notificationBody" />{" "}
                    </span>
                    <span className="data-text">{notificationBody}</span>
                </div>
            )}
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (state) => ({
    name: SelectorsStoreForm.getName(state),
    previewData: SelectorsStoreForm.getPreviewData(state),
});

export default connect(mapStateToProps)(Component);
