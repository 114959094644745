import React from "react";

import { Field } from "formik";
import PropTypes from "prop-types";

import { EMPTY_STR, ZERO } from "~/constants";
import * as i18nUtils from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Box from "~/components/Box";
import GridLayout from "~/components/GridLayout";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TextField from "~/pages/_components/fields/TextField";
import Selector from "~/pages/_components/fields/formik/Selector";

import Checkbox from "~/pages/forms/_components/_fields/_commons/Checkbox";

import Style from "./DocumentSection.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...TypesRedux,
        hideShippedByField: PropTypes.bool,
        hideTermsField: PropTypes.bool,
    },
    defaults: {
        ...DefaultsRedux,
        i18n: {},
        hideShippedByField: false,
        hideTermsField: false,
    },
};

const TERMS = {
    AEREO: ["EXW", "FCA", "CPT", "CIP", "DAP", "DAT", "DDP", "OTR"],
    MARITIMO: ["EXW", "FCA", "FAS", "FOB", "CFR", "CIF", "CPT", "CIP", "DAP", "DAT", "DDP", "OTR"],
    TERRESTRE: ["EXW", "FCA", "CPT", "CIP", "DAP", "DAT", "DDP", "OTR"],
    COMBINADO: ["EXW", "FCA", "FAS", "FOB", "CFR", "CIF", "CPT", "CIP", "DAP", "DAT", "DDP", "OTR"],
};

export class Component extends React.Component {
    removeDocument = (event, index) => {
        event.stopPropagation();

        const { values, setFieldValue } = this.props;
        const documents = [...values.documents];

        documents.splice(index, 1);

        setFieldValue("documents", documents);
    };

    buildShippedByOptions = () => {
        const {
            values: { domestic },
        } = this.props;

        let options = [
            {
                value: "AEREO",
                label: i18nUtils.get("comex.imports.creditLetter.transport.AEREO.shippedBy"),
            },
            {
                value: "MARITIMO",
                label: i18nUtils.get("comex.imports.creditLetter.transport.MARITIMO.shippedBy"),
            },
            {
                value: "TERRESTRE",
                label: i18nUtils.get("comex.imports.creditLetter.transport.TERRESTRE.shippedBy"),
            },
            {
                value: "COMBINADO",
                label: i18nUtils.get("comex.imports.creditLetter.transport.COMBINADO.shippedBy"),
            },
        ];

        if (domestic) {
            options = [{ value: "", label: "\u00A0" }, ...options];
        }

        return options;
    };

    refreshDocuments = (e, fieldName) => {
        const {
            values: { documents, terms, shippedBy },
            setFieldValue,
            preData: { documentTypeList },
        } = this.props;

        let availableDocumentTypeList = [...documentTypeList];

        if (fieldName === "terms" && !e.value.includes("I")) {
            availableDocumentTypeList = availableDocumentTypeList.filter((d) => d !== "POLIZA_DE_SEGURO");
        }

        if (fieldName === "shippedBy") {
            if (e.value === "AEREO") {
                availableDocumentTypeList = availableDocumentTypeList.filter(
                    (d) => d !== "CONOCIMIENTO_DE_EMBARQUE_MARITIMO" && d !== "DOCUMENTO_DE_TRANSPORTE_TERRESTRE",
                );
            } else if (e.value === "MARITIMO") {
                availableDocumentTypeList = availableDocumentTypeList.filter(
                    (d) => d !== "DOCUMENTO_DE_TRANSPORTE_AEREO" && d !== "DOCUMENTO_DE_TRANSPORTE_TERRESTRE",
                );
            } else if (e.value === "TERRESTRE") {
                availableDocumentTypeList = availableDocumentTypeList.filter(
                    (d) => d !== "DOCUMENTO_DE_TRANSPORTE_AEREO" && d !== "CONOCIMIENTO_DE_EMBARQUE_MARITIMO",
                );
            }

            if (!TERMS[e.value]?.includes(terms) && shippedBy) {
                setFieldValue("terms", "");
            } else {
                setFieldValue("terms", "EXW");
            }
        }

        const newDocuments = documents.filter((d) => availableDocumentTypeList.includes(d.type));

        setFieldValue("documents", newDocuments);
    };

    render() {
        const {
            editDocument,
            errors,
            formId,
            hideShippedByField,
            hideTermsField,
            setFieldValue,
            showDocumentModal,
            touched,
            values,
        } = this.props;
        const hasDocumentsError = errors.documents && touched.documents;
        const hasDocuments = values.documents && values.documents.length > 0;

        return (
            <div id={Style.ID}>
                {!hideShippedByField && (
                    <Field
                        component={Selector}
                        idForm={formId}
                        hidePlaceholder
                        onChange={(e) => {
                            if (e) {
                                this.refreshDocuments(e, "shippedBy");
                            }
                        }}
                        name="shippedBy"
                        className="flex-container slideFromBottom selector-letter"
                        optionClassName="needsclick"
                        options={this.buildShippedByOptions()}
                        renderAs="combo"
                        creatable={false}
                        clearable={false}
                    />
                )}
                {!hideTermsField && values.shippedBy && (
                    <React.Fragment>
                        <FieldLabel labelKey={`${formId}.terms`} />
                        <div className="terms">
                            <Field
                                component={Selector}
                                idForm={formId}
                                hidePlaceholder
                                name="terms"
                                onChange={(e) => {
                                    if (e) {
                                        this.refreshDocuments(e, "terms");
                                    }
                                }}
                                className="flex-container slideFromBottom selector-letter"
                                optionClassName="needsclick"
                                options={TERMS[values.shippedBy].map((t) => {
                                    return { value: t, label: i18nUtils.get(`incoterms.${t}`) };
                                })}
                                hideLabel
                            />
                            <Field
                                autoComplete="off"
                                component={TextField}
                                hidelabel
                                idForm={formId}
                                maxLength={65}
                                name="termsDetail"
                                placeholder={EMPTY_STR}
                            />
                        </div>
                    </React.Fragment>
                )}
                <FieldLabel labelKey={`${formId}.documents`} />
                {!hasDocuments && (
                    <span className={hasDocumentsError && "documents-error"}>
                        {i18nUtils.get(`${formId}.documents.description`)}
                    </span>
                )}
                <Field idForm={formId} name="documents" style={{ display: "none" }} />
                {hasDocumentsError && !hasDocuments && (
                    <React.Fragment>
                        <div />
                        <FieldError error={errors.documents} />
                    </React.Fragment>
                )}
                {hasDocuments && (
                    <div className="documentsTable">
                        {values.documents.length > 0 && (
                            <GridLayout className={hasDocumentsError ? "documentsTableRowError" : "documentsTableRow"}>
                                <Box key="doc-header-document" className="web-only header">
                                    {i18nUtils.get("comex.creditLetter.modal.documentTypes.table.header.document")}
                                </Box>
                                <Box key="doc-header-other" className="web-only header" />
                                <Box key="doc-header-original" className="web-only header">
                                    {i18nUtils.get("comex.creditLetter.modal.documentTypes.table.header.original")}
                                </Box>
                                <Box key="doc-header-copies" className="web-only header">
                                    {i18nUtils.get("comex.creditLetter.modal.documentTypes.table.header.copies")}
                                </Box>
                            </GridLayout>
                        )}
                        {values.documents.map((d, index) => {
                            return (
                                <GridLayout
                                    className={hasDocumentsError ? "documentsTableRowError" : "documentsTableRow"}
                                    onClick={() => editDocument(d)}>
                                    <Box key={`doc-row-${d.type}-document`} className="web-only row-data">
                                        {i18nUtils.get(`comex.creditLetter.modal.documentTypes.${d.type}`)}
                                    </Box>
                                    <Box
                                        key={`doc-row-${d.type}-description`}
                                        className={`web-only row-data-description ${
                                            hasDocumentsError ? "red-color" : "black-color"
                                        } tooltip`}>
                                        {d.description && d.description.length > 14
                                            ? `${d.description.substr(0, 19)}...`
                                            : d.description}
                                        {d.description && <span className="tooltip-text">{d.description}</span>}
                                    </Box>
                                    <Box key={`doc-row-${d.type}-original`} className="web-only row-data-cross">
                                        {!d.originals ? ZERO : d.originals}
                                    </Box>
                                    <Box key={`doc-row-${d.type}-copies`} className="web-only row-data-cross">
                                        {!d.copies ? ZERO : d.copies}
                                    </Box>
                                    <Box key={`doc-row-${d.type}-closed`} className="web-only row-data-cross">
                                        <div
                                            className="closed clickable"
                                            onClick={(e) => {
                                                this.removeDocument(e, index);
                                            }}
                                            role="button">
                                            <Image className="rowCross" src="close.svg" />
                                        </div>
                                    </Box>
                                </GridLayout>
                            );
                        })}
                        {hasDocumentsError && hasDocuments && (
                            <GridLayout className="documentsTableErrorMessage">
                                <FieldError error={errors.documents} />
                            </GridLayout>
                        )}
                    </div>
                )}
                <FieldLabel labelKey="" />
                <Box flex justify="center" align="center">
                    <Link
                        to="#"
                        onClick={(e) => {
                            e.preventDefault();
                            showDocumentModal(true);
                        }}>
                        <I18n id={`${formId}.documents.add`} />
                    </Link>
                </Box>
                {values.shippedBy === "TERRESTRE" && (
                    <div className="beneficiary-label">
                        <Field
                            name="authorizeDisclaimer"
                            label={i18nUtils.get("comex.imports.creditLetter.form.TERRESTRE.legend.one")}
                            checked={values.authorizeDisclaimer}
                            component={Checkbox}
                            onChange={() => {
                                setFieldValue("authorizeDisclaimer", !values.authorizeDisclaimer);
                            }}
                        />
                        <Field
                            component={Selector}
                            idForm={formId}
                            hidePlaceholder
                            name="authorizeCopies"
                            className="flex-container slideFromBottom selector-letter"
                            optionClassName="needsclick"
                            options={[
                                {
                                    value: "BENEFICIARIO",
                                    label: "del Beneficiario",
                                },
                                {
                                    value: "COMPANIA_DE_TRANSPORTE",
                                    label: "de la Compañia de Transporte",
                                },
                            ]}
                            renderAs="combo"
                            searchable={false}
                            creatable={false}
                            clearable={false}
                            hideLabel
                        />
                        <span>{i18nUtils.get("comex.imports.creditLetter.form.TERRESTRE.legend.two")}</span>
                    </div>
                )}
            </div>
        );
    }
}

export default Component;
