import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SPACE_STR, EMPTY_STR } from "~/constants";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import { SelectorsStore as SelectorsStoreComexExport } from "~/store/comex/export";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import DataDate from "~/components/DataDate";
import I18n from "~/components/I18n";
import Ticket from "~/components/Ticket/Ticket";
import FormattedAmount from "~/pages/_components/FormattedAmount";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const { transaction, ticketConfirmation, dispatch } = props;
    const { data } = transaction;

    const {
        operationNumber,
        dueDate,
        boardingDate,
        invoiceNumber,
        invoiceAmount,
        creditLetterNumber,
        otherModifications,
        invoiceAmountCurrency,
        submissionType,
        discrepancies,
    } = data;

    const shareTicket = (idTransactionToRead) => {
        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    };
    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}
                    hasMobileComponent
                    displayDownloadPDF
                    handleDownloadPDF={() => shareTicket(transaction.idTransaction)}>
                    <Box flex directionColumn align="flex-start" className="mb-3">
                        <div className="opening">
                            <I18n
                                id="client.import.authorizationDDiscrepancies.step2.opening"
                                tag="p"
                                className="mt-3"
                            />
                        </div>
                        {operationNumber && (
                            <div className="info-label">
                                <h4 className="bold">
                                    {UtilsI18n.get("client.import.authorizationDDiscrepancies.step2.operationNumber")}
                                </h4>
                                <span>{operationNumber}</span>
                            </div>
                        )}
                        {creditLetterNumber && (
                            <div className="info-label">
                                <h4 className="bold">
                                    {UtilsI18n.get(
                                        "client.import.authorizationDDiscrepancies.step2.creditLetterNumber",
                                    )}
                                </h4>
                                <span>{creditLetterNumber}</span>
                            </div>
                        )}
                        {invoiceNumber && (
                            <div className="info-label">
                                <h4 className="bold">
                                    {UtilsI18n.get("client.import.authorizationDDiscrepancies.step2.invoiceNumber")}
                                </h4>
                                <span>{invoiceNumber}</span>
                            </div>
                        )}
                        {invoiceAmount && (
                            <div className="info-label">
                                <h4 className="bold">
                                    {UtilsI18n.get("client.import.authorizationDDiscrepancies.step2.invoiceAmount")}
                                </h4>
                                <FormattedAmount currency={invoiceAmountCurrency} quantity={parseFloat(invoiceAmount)} />
                            </div>
                        )}
                        {dueDate && (
                            <div className="info-label">
                                <I18n id="client.import.authorizationDDiscrepancies.step2.dueDate" tag="p" />
                                <DataDate value={dueDate} />
                            </div>
                        )}
                        {boardingDate && (
                            <div className="info-label">
                                <I18n id="client.import.authorizationDDiscrepancies.step2.boardingDate" tag="p" />
                                {SPACE_STR}
                                <DataDate value={boardingDate} />
                            </div>
                        )}
                        {otherModifications && (
                            <div className="otherModifications">
                                <span>{otherModifications}</span>
                            </div>
                        )}

                        <div className="opening content">
                            <I18n
                                id={`client.import.authorizationDDiscrepancies.step2.content.${submissionType}`}
                                tag="p"
                            />
                            {discrepancies && <p className="data-date">{discrepancies}</p>}
                        </div>
                        <I18n
                            id="client.import.authorizationDDiscrepancies.step2.terms"
                            tag="p"
                            className="mt-3 mb-3"
                        />
                    </Box>
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { idTransaction } = transaction;
        dispatch(
            SelectorsActionComex.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        dispatch(SelectorsActionComex.signTransactionPreview({ ...data }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreComexExport.getTransaction(store),
        formData: SelectorsStoreComexExport.getAuthorizationDocumentsDiscrepanciesFormData(store),
    };
};

export default Connect(mapStateToProps)(Component);
