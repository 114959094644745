import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.LIST_REQUEST:
            return {
                ...storeProp,
                [action.widget]: {
                    ...storeProp[action.widget],
                    isFetching: true,
                },
            };
        case TYPE.LIST_FAILURE:
            return {
                ...storeProp,
                [action.widget]: {
                    ...storeProp[action.widget],
                    isFetching: false,
                },
            };
        case TYPE.LIST_SUCCESS:
            return {
                ...storeProp,
                [action.widget]: {
                    ...storeProp[action.widget],
                    data: action.data,
                    isFetching: false,
                },
            };
        case TYPE.DELETE_WIDGET:
            return {
                ...storeProp,
                [action.widget.id]: {
                    ...PROP.defaults[action.widget.id],
                },
            };
        case TYPE.DOWNLOAD_POSITION:
            return {
                ...storeProp,
                [action.widget]: {
                    ...storeProp[action.widget],
                    fetching: true,
                },
            };
        case TYPE.DOWNLOAD_POSITION_FAILURE:
        case TYPE.DOWNLOAD_SUCCESS:
            return {
                ...storeProp,
                [action.widget]: {
                    ...storeProp[action.widget],
                    fetching: false,
                },
            };

        default:
            return storeProp;
    }
};
