import { call as Call, put as Put, takeLatest as TakeLatest, select as Select } from "redux-saga/effects";

import { SelectorsStore as SelectorsStoreSession } from "~/store/session";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsMiddleware } from "./_selectors";

export default [
    /** Request LocationList
     *  Fetches a location list and triggers the corresponding action when done.
     */
    TakeLatest(TYPE.LOCATIONS_LIST_REQUEST, function* locationListRequest(action) {
        const { searchType } = action;
        let response;
        const isLoggedIn = yield Select(SelectorsStoreSession.isLoggedIn);

        if (isLoggedIn) {
            response = yield Call(SelectorsMiddleware.locationList, { searchType });
        } else {
            response = yield Call(SelectorsMiddleware.locationListExternal, { searchType });
        }
        if (response && response.status === 200) {
            yield Put(SelectorsAction.locationListSuccess(response.data.data));
        }
    }),
];
