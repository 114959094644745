import { FactoryAction } from "~/util/redux";

import { NAME, TYPE } from "./_consts";

export const SelectorsAction = {
    reset: () => FactoryAction(TYPE.RESET),
    initLoginFlow: () => FactoryAction(TYPE.INIT_LOGIN_FLOW),

    loginStep1: (props) => FactoryAction(TYPE.LOGIN_STEP_1_REQUEST, props),
    loginStep1Success: (props) => FactoryAction(TYPE.LOGIN_STEP_1_SUCCESS, props),
    loginStep2: (props) => FactoryAction(TYPE.LOGIN_STEP_2_REQUEST, props),
    loginStep3: (props) => FactoryAction(TYPE.LOGIN_STEP_3_REQUEST, props),
    loginStep3Success: (props) => FactoryAction(TYPE.LOGIN_STEP_3_SUCCESS, props),
    loginStep4: (props) => FactoryAction(TYPE.LOGIN_STEP_4_REQUEST, props),

    loginFailure: () => FactoryAction(TYPE.LOGIN_FAILURE),
    loginFailureRequireCaptcha: () => FactoryAction(TYPE.LOGIN_FAILURE_REQUIRE_CAPTCHA),
    loginFailureLoader: () => FactoryAction(TYPE.LOGIN_FAILURE_LOADER),
    cleanUp: () => FactoryAction(TYPE.CLEAN_UP),

    fingerprintLoginPre: () => FactoryAction(TYPE.FINGERPRINT_LOGIN_PRE),

    setRememberedUser: (props) => FactoryAction(TYPE.SET_REMEMBERED_USER, props),
    removeRememberedUser: () => FactoryAction(TYPE.REMOVE_REMEMBERED_USER),
    discardFingerprintLogin: () => FactoryAction(TYPE.LOGIN_FINGERPRINT_DISCARD),
    setFingerprintFailure: () => FactoryAction(TYPE.LOGIN_FINGERPRINT_FAILURE),
    setLoginFingerprintSubmit: () => FactoryAction(TYPE.FINGERPRINT_LOGIN_SUBMIT),
};

export const SelectorsStore = {
    getRememberedUser: (store) => store[NAME].rememberedUser,
    getUserFullName: (store) => store[NAME].userFullName,
    getSecuritySeal: (store) => store[NAME].securitySeal,
    getShowCaptcha: (store) => store[NAME].showCaptcha,
    getFetching: (store) => store[NAME].fetching,
    getNotification: (store) => store[NAME].notification,
    getTermsAndConditions: (store) => store[NAME].termsAndConditions,
    getExchangeToken: (store) => store[NAME].exchangeToken,
    getFingerprintSubmiting: (store) => store[NAME].fingerprintSubmiting,
    getFingerprintLoginFail: (store) => store[NAME].fingerprintLoginFail,
    getEnvironments: (store) => store[NAME].environments,
    getIsInFlow: (store) => !!store[NAME].exchangeToken,
    isDisabledButton: (store) => store[NAME].disabledButton,
    getSubmit: (store) => store[NAME].submit,
};
