import React from "react";

import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsStore, SelectorsAction } from "~/store/filesDownload";
import { generateId as GenID } from "~/util/general";
import * as i18nUtils from "~/util/i18n";

import Box from "~/components/Box";
import Element from "~/components/Component";
import GridLayout from "~/components/GridLayout";
import IntersectionObserver from "~/components/IntersectionObserver";
import RoundButton from "~/components/RoundButton";

import Style from "./_Files.rules.scss";

export const { NAME, CLASS, NAME_NOTE, TYPE_NOTE } = Style;

export const PROP = {
    types: {},
    defaults: {
        files: [],
    },
};

const COLUMN_HEADINGS = ["detail", 
                        "category", 
                        EMPTY_STR];

export function Component(props) {
    const { dispatch, downloadsList, lang, moreLines, pageNumber, fetching } = props;

    function handleMovementsFetch(event) {
        const { isIntersecting } = event;
        if (!isIntersecting) {
            return;
        }
        dispatch(SelectorsAction.getFileDownloadsListRequest({ pageNumber }));
    }

    const capitalize = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const handleClick = (downloadId) => {
        dispatch(SelectorsAction.downloadFileRequest({ downloadId }));
    };

    return (
        <IntersectionObserver onIntersect={handleMovementsFetch}>
            <Box className={CLASS}>
                <GridLayout className="header">
                    {COLUMN_HEADINGS.map((heading) => (
                        <Box>{heading ? i18nUtils.get(`list.documents.table.header.${heading}`) : EMPTY_STR}</Box>
                    ))}
                </GridLayout>
                {downloadsList &&
                    downloadsList.map(
                        ({
                            categoryLabel,
                            name,
                            [`description${capitalize(lang)}`]: description,
                            downloadId,
                            subcategoryLabel,
                        }) => {
                            return (
                                <GridLayout key={`files-${GenID()}`}>
                                    <Box flex directionColumn>
                                        <Box>{name}</Box>
                                        <Box>{description}</Box>
                                    </Box>
                                    <Box>
                                        {categoryLabel} → {subcategoryLabel}
                                    </Box>
                                    <Box>
                                        <RoundButton
                                            image="header-download-document"
                                            onClick={() => handleClick(downloadId)}
                                        />
                                    </Box>
                                </GridLayout>
                            );
                        },
                    )}
                {!fetching && moreLines && (
                    <Note
                        onClick={() => handleMovementsFetch({ isIntersecting: true })}
                        intersection-trigger="true"
                        key="more"
                        value={i18nUtils.get("files.moreFiles")}
                    />
                )}
                {fetching && <Note value={i18nUtils.get("global.loading")} />}
                {!fetching && !moreLines && (
                    <Note
                        value={i18nUtils.get("files.noMoreFiles")}
                        onClick={() => handleMovementsFetch({ isIntersecting: false })}
                    />
                )}
            </Box>
        </IntersectionObserver>
    );

    function Note({ value, ...rest }) {
        return (
            <Element type={TYPE_NOTE} name={NAME_NOTE} tag="span" {...rest}>
                {value}
            </Element>
        );
    }
}
const mapStateToProps = (store) => ({
    downloadsList: SelectorsStore.getFileDownloadsList(store),
    moreLines: SelectorsStore.getMoreLines(store),
    pageNumber: SelectorsStore.getPageNumber(store),
});
export default Connect(mapStateToProps)(Component);
