import React from "react";

import { Modal } from "react-bootstrap";
import { connect as Connect } from "react-redux";

import { NO_DATA, CDP_CURRENCY } from "~/constants";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsStore as SelectorsStoreWM } from "~/store/wm/listPapers";

import Button from "~/components/Button";
import DataNumber from "~/components/DataNumber";
import Image from "~/components/Image";
import HistoricalRatesChart from "./HistoricalRatesChart";

import Style from "./PopUp.rules.scss";

export const { NAME, NAME_MESSAGES } = Style;
export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const {
        show,
        handleCloseModal,
        fields: { minimumPurchase, currency, link, ...rest },
        currentLink,
        handleBuy,
        downloadDetail,
        i18n,
        chartPeriods,
    } = props;
    return (
        <Modal show={show}>
            <Modal.Body>
                <div className="component-modal">
                    <div className="closed clickable" onClick={handleCloseModal} role="button">
                        <Image src="close.svg" />
                    </div>
                    <h2>{i18n.modalTitle}</h2>
                    {rest &&
                        Object.entries(rest).map(([key, value]) => {
                            return (
                                <p>
                                    <label>{`${i18n[key]}:`} </label>
                                    <span>{value || NO_DATA}</span>
                                </p>
                            );
                        })}
                    {(currency || minimumPurchase) && (
                        <p>
                            <label>{`${i18n.minimumPurchase}:`}</label>
                            <DataNumber
                                value={minimumPurchase}
                                prefix={currency || CDP_CURRENCY.USD}
                                className="mobile-only"
                            />
                        </p>
                    )}
                    {chartPeriods && Object.keys(chartPeriods).length > 0 && <HistoricalRatesChart {...props} />}
                </div>
                <div className="disclaimer">{i18n.disclaimer}</div>
                <div className="button-container">
                    <Button
                        label="wm.investment.fund.item.option.buy"
                        bsStyle="primary"
                        loading={false}
                        onClick={handleBuy}
                    />
                    {currentLink &&
                        <Button label="client.wm.product.actions.downloadDetail" loading={false} onClick={downloadDetail} />
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
}

const mapStateToProps = (store) => ({
    fields: SelectorsStoreWM.getPaperDetail(store),
    chartPeriods: SelectorsStoreWM.getChartPeriods(store),
    fetchingChart: SelectorsStoreWM.getFetchingChartValues(store),
    i18n: {
        modalTitle: StoreI18n.getMessage(store, "wm.funds.list.evolution.modal.title"),
        description: StoreI18n.getMessage(store, "wm.funds.list.evolution.description"),
        ISIN: StoreI18n.getMessage(store, "wm.funds.list.evolution.ISIN"),
        marketPrice: StoreI18n.getMessage(store, "wm.funds.list.evolution.marketPrice"),
        HSBCprofile: StoreI18n.getMessage(store, "wm.funds.list.evolution.HSBCprofile"),
        currency: StoreI18n.getMessage(store, "wm.funds.list.evolution.currency"),
        minimumPurchase: StoreI18n.getMessage(store, "wm.funds.list.evolution.minimunPurchase"),
        disclaimer: StoreI18n.getMessage(store, "wm.funds.price.disclaimer"),
    },
});

export default Connect(mapStateToProps)(Component);
