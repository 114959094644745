import React from "react";

import PropTypes from "prop-types";

import formField from "~/pages/forms/_components/_fields/_commons/formField";

export const NAME = "Horizontalrule";

export const PROP = {
    types: { editing: PropTypes.bool.isRequired },
    defaults: {},
};

export function Component(props) {
    const { editing } = props;

    if (editing) {
        return <hr />;
    }

    return null;
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default formField({ pureRender: true })(Component);
