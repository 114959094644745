import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.LOCATIONS_LIST_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                locationList: action.data,
            };
        case TYPE.LOCATIONS_LIST_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                locationList: action.data,
            };
        default:
            return storeProp;
    }
};
