import React from "react";

import { Field } from "formik";
import PropTypes from "prop-types";
import ReactSelect from "react-select";

import { EMPTY_STR } from "~/constants";

import UtilsDevice from "~/util/device";
import * as I18nUtils from "~/util/i18n";

import FieldError from "~/pages/_components/fields/FieldError";

import Style from "./Selector.rules.scss";

export const NAME = "SelectorType";

export const PROP = {
    types: {
        name: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string,
                label: PropTypes.string,
            }),
        ).isRequired,
        className: PropTypes.string,
        label: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.bool,
        title: PropTypes.string,
        noOption: PropTypes.string,
        customFilter: PropTypes.func,
    },
    defaults: {
        className: "form-group",
        label: EMPTY_STR,
        options: [],
        noOption: EMPTY_STR,
        onChange: null,
        placeholder: null,
        title: EMPTY_STR,
        customFilter: null,
    },
};

export function Component(props) {
    const {
        name,
        className,
        label,
        onChange,
        options,
        placeholder,
        title,
        classNameContent,
        valueKey,
        customFilter,
        ...rest
    } = props;
    let hasError = false;
    let classError = EMPTY_STR;
    if (props?.errors && props?.values) {
        hasError = props?.errors[name] && !props?.values[name];
        classError = hasError ? "error-field" : EMPTY_STR;
    }
    const noOptionText = I18nUtils.get("selector.noOption.text")
    const isMobile = UtilsDevice.isDisplayMobile();

    return (
        <div id={Style.ID} className={classNameContent || EMPTY_STR}>
            <Field name={name}>
                {({ field, form }) => (
                    <div title={title || EMPTY_STR} className={className}>
                        {label && (
                            <div className="form-group-text">
                                <label className="control-label">{label}</label>
                            </div>
                        )}
                        <div className={`input-group ${classError}`}>
                            <ReactSelect
                                {...field}
                                {...rest}
                                valueKey={valueKey}
                                options={options}
                                clearable={false}
                                searchable={!isMobile}
                                inputProps={{spellCheck: "false"}}
                                noResultsText={noOptionText}
                                autosize={false}
                                onChange={(val) => {
                                    if(val){
                                        const value = val?.value || val?.id || val[valueKey];
                                        if (onChange) {
                                            onChange(value);
                                        }
                                        form.setFieldValue(field.name, value);
                                    }
                                }}
                                optionClassName="needsclick"
                                placeholder={placeholder || EMPTY_STR}
                                filterOption={customFilter}
                            />
                        </div>
                        {hasError && <FieldError error={form?.errors[name]} />}
                    </div>
                )}
            </Field>
        </div>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
