import React from "react";

import DatePicker from "react-datepicker";

import Store from "~/store";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { locales } from "~/util/date";

export const NAME = "GenericDate";

export const PROP = {
    types: {},
    defaults: {},
};

/**
 * Wrapper component for Date components.
 * OBS: If we need to change the Date library, do it here.
 * Only add elements that are generic for every DatePicker.
 * @param {props} props Any
 */

export function Component(props) {
    const {
        id,
        selected,
        dateFormat,
        defaultValue,
        maxDate = null,
        minDate = null,
        onChange,
        onChangeRaw,
        placeholderText,
        startDate,
        endDate,
        selectsEnd = false,
        showDisabledMonthNavigation = false,
        showMonthYearDropdown = false,
        fixedHeight = true,
        excludeDates,
        showYearDropdown,
        dropdownMode,
        customInput,
        subType,
    } = props;
    let value = selected;
    if (defaultValue && !value && subType !== "emptyDate") {
        value = new Date();
    }

    // FIX https://technisys.atlassian.net/browse/UYHSBCCDP-5409
    // it's a bug from DatePicker, evaluate update version
    // Temp solution: https://github.com/Hacker0x01/react-datepicker/issues/2023
    // showMonthYearDropdown option will crash if you leave out the minDate and maxDate parameters.
    let showMonthYearDropdownAux = showMonthYearDropdown;
    if (showMonthYearDropdown) {
        if (minDate === null || maxDate === null) {
            showMonthYearDropdownAux = false;
        }
    }

    return (
        <div className="input-group">
            <div className="form-control">
                <DatePicker
                    customInput={customInput}
                    locale={locales[SelectorsStoreI18n.getLang(Store.getState())]}
                    id={id}
                    selected={value}
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={maxDate}
                    minDate={minDate}
                    placeholderText={placeholderText}
                    dateFormat={dateFormat}
                    onChange={onChange}
                    onChangeRaw={onChangeRaw}
                    selectsEnd={selectsEnd}
                    showDisabledMonthNavigation={showDisabledMonthNavigation}
                    showMonthYearDropdown={showMonthYearDropdownAux}
                    showYearDropdown={showYearDropdown}
                    dropdownMode={dropdownMode}
                    excludeDates={excludeDates ? excludeDates.map((e) => new Date(e)) : null}
                    fixedHeight={fixedHeight}
                    disabledKeyboardNavigation
                />
                <label className="calendar" htmlFor={id} />
            </div>
        </div>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
