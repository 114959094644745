import React from "react";

import Element from "~/components/Component";
import WidgetImage from "~/components/WidgetImage";

import Namespace from "./RoundButton.scss";

export const { NAME, TAG, TYPE } = Namespace;

export const PROP = {
    types: {},
    defaults: {
        tag: TAG,
    },
};

export function Component({ image, onClick, tag }) {
    return (
        <Element name={NAME} onClick={onClick} tag={tag} type={TYPE}>
            <WidgetImage src={`${image}.svg`} />
        </Element>
    );
}

Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

export default Component;
