import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "bankSelector";

export const PATH = { BANK_LIST: "/banks.list" };

export const TYPE = FactoryType(NAME, [
    "BANK_SELECTED",
    "CLEAR_SELECTED_BANK",
    "LOAD_LIST_REQUEST",
    "LOAD_LIST_FAILURE",
    "LOAD_LIST_SUCCESS",
    "RESET_SELECTED_BANK",
    "RESET_SELECTED_BANKS",
    "SEARCH_BANK_REQUEST",
    "SEARCH_BANK_FAILURE",
    "SEARCH_BANK_SUCCESS",
    "SEARCH_BANK_SUCCESS_TEMPLATES",
    "SET_NETWORK_TYPE",
]);

export const PROP = {};

PROP.types = {
    banksResults: PropTypes.array,
    errorSelectingBank: PropTypes.object,
    hasMoreResults: PropTypes.bool,
    selectedBanks: PropTypes.object,
    searchBankForm: PropTypes.bool,
    networkType: PropTypes.string,
    ...TypesRedux,
};

PROP.defaults = {
    banksResults: [],
    errorSelectingBank: {},
    hasMoreResults: false,
    selectedBanks: {},
    searchBankForm: false,
    networkType: EMPTY_STR,
    ...DefaultsRedux,
};
