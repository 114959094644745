import { FactoryType } from "~/util/redux";

export const NAME = "products";

export const PATH = {
    CHANGE_ALIAS: "/core.product.changeAlias",
};

export const TYPE = FactoryType(NAME, ["CHANGE_PRODUCT_ALIAS", "CHANGE_PRODUCT_ALIAS_SUCCESS"]);

export const PROP = {};

PROP.types = {};

PROP.defaults = {};
