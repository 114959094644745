import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE } from "~/constants";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as i18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsMiddleware } from "./_selectors";

const sagas = [TakeLatest(TYPE.LIST_REQUEST, listRequest)];

export default sagas;

function* listRequest(props) {
    const myProps = { ...props };
    delete myProps.type;
    const { type, data } = yield Call(SelectorsMiddleware.listRequest, myProps);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.listFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: i18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMBOS_LIST],
            }),
        );
    } else {
        yield Put(SelectorsAction.listSuccess({ data: data.data }));
    }
}
