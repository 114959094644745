import React from "react";

import PropTypes from "prop-types";

import Image from "~/components/Image";

import Style from "./RadioButton.rules.scss";

export const { NAME, ID } = Style;

export const PROP = {
    types: {
        checked: PropTypes.bool.isRequired,
        controlStyle: PropTypes.string,
        extraText: PropTypes.string,
        inLineControl: PropTypes.bool,
        label: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.string.isRequired,
        customCheckImageName: PropTypes.string,
    },
    defaults: { controlStyle: "checkbox", inLineControl: false, customCheckImageName: "check.svg" },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    radioButtonRef = React.createRef();

    handleClick = () => {
        const { onChange, value } = this.props;

        if (!this.radioButtonRef.current.disabled) {
            onChange(value);
        }
    };

    render() {
        const { checked, controlStyle, disabled, extraText, inLineControl, label, value, customCheckImageName } =
            this.props;

        return (
            <div
                id={Style.ID}
                onClick={this.handleClick}
                className={`c-control c-control--has-icon c-control--radio ${!inLineControl ? "c-control-block" : ""}`}>
                <input
                    checked={checked}
                    className="c-control-input"
                    disabled={disabled}
                    // readOnly
                    ref={this.radioButtonRef}
                    type="radio"
                    value={value}
                />
                <label className="c-control-label">
                    {controlStyle === ("checkbox" || "radio") && (
                        <div className="c-control-icons">
                            <div className="c-control-mark">
                                <Image src={customCheckImageName} className="svg-icon svg-caret" />
                            </div>
                        </div>
                    )}

                    {label}
                    {extraText && <div className="extra-text">{extraText}</div>}
                </label>
            </div>
        );
    }
}

export default Component;
