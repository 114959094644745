/* global document window navigator HTMLAnchorElement Blob */

import * as b64toBlob from "b64-to-blob";

const fakeClick = (element) => {
    const event = document.createEvent("MouseEvents");
    event.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    element.dispatchEvent(event);
};

export const downloadPdf = (name, data) => {
    const urlObject = window.URL || window.webkitURL || window;
    const exportBlob = b64toBlob(data, "application/pdf");

    if ("msSaveBlob" in navigator) {
        /**
         * Prefer msSaveBlob if available - Edge supports a[download]
         * but ignores the filename provided, using the blob UUID instead
         * msSaveBlob will respect the provided filename
         */
        navigator.msSaveBlob(exportBlob, name);
    } else if ("download" in HTMLAnchorElement.prototype) {
        const saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");

        saveLink.href = urlObject.createObjectURL(exportBlob);
        saveLink.download = name;
        fakeClick(saveLink);
    } else {
        throw new Error("Neither a[download] nor msSaveBlob is available");
    }
};

export const downloadXls = (name, data) => {
    const saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");

    saveLink.href = `data:application/vnd.ms-excel;base64,${window.atob(data)}`;
    saveLink.download = name;
    fakeClick(saveLink);
};

export const downloadZip = (name, data) => {
    const saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");

    saveLink.href = `data:application/octet-stream;base64,${window.atob(data)}`;
    saveLink.download = name;
    fakeClick(saveLink);
};

const downloadBlob = (blob, name) => {
    const url = window.URL.createObjectURL(blob);
    const saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
    saveLink.href = url;
    saveLink.download = name;
    fakeClick(saveLink);
};

export const downloadBase64 = (name, data) => {
    const blob = b64toBlob(data, "octet/stream");
    downloadBlob(blob, name);
};

export const download = (name, data) => {
    const blob = new Blob([window.atob(data)], { type: "octet/stream" });
    downloadBlob(blob, name);
};

export const getBlobContent = (blob) => {
    const url = window.URL.createObjectURL(blob);

    const request = new XMLHttpRequest();
    request.open("GET", url, false);
    request.send();

    window.URL.revokeObjectURL(url);

    return request.responseText;
};
