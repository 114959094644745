import React from "react";

import PropTypes from "prop-types";
import { withRouter as WithRouter } from "react-router-dom";

import { EMPTY_STR } from "~/constants";

import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";

export const NAME = "MenuItem";

export const PROP = {};
PROP.members = {
    /** propTypes for ContentTitle sub-component */
    typesContent: {
        /** Either an I18n identifier, or an instance of a Component to be rendered as title */
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
        /** Either a src for an existing image, or an instance of a Component */
        image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    },
    defaultsContent: {
        image: null,
    },
};

PROP.types = {
    /** The address where the Item will link when clicked */
    href: PropTypes.string,
    /**  An event handler for Item clicks */
    onClick: PropTypes.func,
    /**  Boolean prop to change style whether the item is selected or not */
    isSelected: PropTypes.bool,
    /**  In case you want to show a different style */
    className: PropTypes.string,
    ...PROP.members.typesContent,
};
PROP.defaults = {
    onClick: null,
    href: null,
    isSelected: false,
    className: "menu-list-item",
    ...PROP.members.defaultsContent,
};

export function Component(props) {
    const { onClick, isSelected, href, className, ...propsContent } = props;

    const handleClick = (...args) => onClick && onClick.apply(this, args);

    return (
        <li className={`${className.concat(isSelected ? " selected" : EMPTY_STR)}`}>
            {href && (
                <Link to={href} onClick={handleClick}>
                    <Component.Content {...propsContent} />
                </Link>
            )}
            {!href && (
                <a onClick={handleClick}>
                    <Component.Content {...propsContent} />
                </a>
            )}
        </li>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

Component.Content = function Content(props) {
    const { image, title } = props;
    return (
        <React.Fragment>
            {React.isValidElement(image) ? image : image && <Image src={image} />}
            {React.isValidElement(title) ? title : <I18n id={title} />}
        </React.Fragment>
    );
};
Component.Content.propTypes = PROP.members.typesContent;
Component.Content.defaultProps = PROP.members.defaultsContent;

export default WithRouter(Component);
