import React from "react";

import ClassNames from "classnames";

import Box from "~/components/Box";

import Style from "./SearchShares.rules.scss";
import Form from "./_Form";
import SearchItemsList from "./_SearchItemsList";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component() {
    return (
        <Box className={ClassNames(CLASS)}>
            <Form />
            <SearchItemsList />
        </Box>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
