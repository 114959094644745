import { TYPE as TYPE_CHANNELS } from "~/store/administration/common/channels";
import { TYPE as TYPE_DETAILS } from "~/store/administration/common/details";
import { TYPE as TYPE_PERMISSIONS } from "~/store/administration/common/permissions";
import { TYPE as TYPE_SIGNATURES } from "~/store/administration/common/signatures";

export const NAME = "advanced";

export const PATH = {
    ADMINISTRATION_MEDIUM_CHANNELS_READ: "/administration.medium.read.channels",
    ADMINISTRATION_MEDIUM_CHANNELS_MODIFY_PREVIEW: "/administration.medium.modify.channels.preview",
    ADMINISTRATION_MEDIUM_CHANNELS_MODIFY_SEND: "/administration.medium.modify.channels.send",
    ADMINISTRATION_MEDIUM_SIGNATURE_MODIFY_SEND: "/administration.advanced.send.user.data",
    ADMINISTRATION_ADVANCED_GROUP_CREATE_PRE: "/administration.advanced.group.create.pre",
    ADMINISTRATION_ADVANCED_GROUP_MODIFY_PRE: "/administration.advanced.group.modify.pre",
    ADMINISTRATION_ADVANCED_GROUP_READ: "/administration.advanced.group.read",
    ADMINISTRATION_SIGNATURES_CREATE_PRE: "/administration.signatures.create.pre",
    ADMINISTRATION_SIGNATURES_CREATE_SEND: "/administration.signatures.create.send",
    ADMINISTRATION_SIGNATURES_DELETE_PRE: "/administration.signatures.delete.pre",
    ADMINISTRATION_SIGNATURES_DELETE_SEND: "/administration.signatures.delete.send",
    ADMINISTRATION_SIGNATURES_MODIFY_PRE: "/administration.signatures.modify.pre",
    ADMINISTRATION_SIGNATURES_MODIFY_SEND: "/administration.signatures.modify.send",
    ADMINISTRATION_SIGNATURES_LIST: "/administration.signatures.list",
    ADMINISTRATION_SIGNATURES_EXPORT: "/administration.signatures.export",
    ADMINISTRATION_ADVANCED_GROUP_CREATE_PREVIEW: "/administration.advanced.group.create.preview",
    ADMINISTRATION_ADVANCED_GROUP_CREATE_SEND: "/administration.advanced.group.create.send",
    ADMINISTRATION_ADVANCED_GROUP_MODIFY_PREVIEW: "/administration.advanced.group.modify.preview",
    ADMINISTRATION_ADVANCED_GROUP_MODIFY_SEND: "/administration.advanced.group.modify.send",
    ADMINISTRATION_ADVANCED_USER_DETAILS_READ: "/administration.advanced.user.details.read",
    ADMINISTRATION_USER_DETAIL_GROUPS_MODIFY_PREVIEW: "/administration.user.detail.groups.modify.preview",
    ADMINISTRATION_USER_DETAIL_GROUPS_MODIFY_SEND: "/administration.user.detail.groups.modify.send",
    ADMINISTRATION_ADVANCED_USER_FORM_READ: "administration.advanced.user.form.read",
};

// Exportamos los TYPE con el prefijo del nivel de administracion que corresponda.

export const TYPE_ADMINISTRATION_LEVEL_ADVANCED_CHANNELS = TYPE_CHANNELS(NAME);
export const TYPE_ADMINISTRATION_LEVEL_ADVANCED_DETAILS = TYPE_DETAILS(NAME);
export const TYPE_ADMINISTRATION_LEVEL_ADVANCED_PERMISSION = TYPE_PERMISSIONS(NAME);
export const TYPE_ADMINISTRATION_LEVEL_ADVANCED_SIGNATURE = TYPE_SIGNATURES(NAME);
