import React from "react";

import PropTypes from "prop-types";

import Select from "~/components/Select";

import formField from "~/pages/forms/_components/_fields/_commons/formField";
import { REGEX_IGNORE_ACCENTS } from "~/constants";
export const NAME = "Countrylist";

export const PROP = {
    types: {
        data: PropTypes.shape({
            countries: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })).isRequired,
        }).isRequired,
        setValue: PropTypes.func.isRequired,
        editing: PropTypes.bool,
        value: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
    },
    defaults: { placeholder: "", editing: false },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    handleChange = ({ id }) => {
        const { setValue } = this.props;
        setValue(id);
    };
    
    customFilter (option, searchText){
        const optionLowerCase = option.label ? option.label.toLowerCase() : option.name.toLowerCase();
        const text = searchText.toLowerCase();

        if(optionLowerCase.normalize("NFD").replace(REGEX_IGNORE_ACCENTS, "").includes(text) && optionLowerCase.charAt(0).includes(text.charAt(0))){
            return true;
        }
        else {
            return false
        }
    }

    render() {
        const {
            editing,
            value,
            placeholder,
            data: { countries },
        } = this.props;
        
        if (!editing) {
            const { name } = countries.find(({ id }) => id === value) || {};
            return name || null;
        }
      
        return (
            <div className="input-group">
                <div style={{ flex: 1 }}>
                    <Select
                        placeholder={placeholder}
                        value={value}
                        clearable={false}
                        onChange={this.handleChange}
                        valueKey="id"
                        labelKey="name"
                        options={countries}
                        className="flex-container slideFromBottom"
                        optionFilter={this.customFilter}
                    />
                </div>
            </div>
        );
    }
}

export default formField({
    formClass: "form-group--select",
})(Component);
