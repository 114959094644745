import React from "react";

import classnames from "classnames";
import { arrayOf, node, oneOfType, bool } from "prop-types";

import Element from "~/components/Component";
import Namespace from "~/components/ExpandElement/ExpandElement.scss";

export const { CLASS, NAME, TYPE, TAG } = Namespace;

export const PROP = {
    types: {
        children: oneOfType([arrayOf(node), node]).isRequired,
        disableArrow: bool,
        openItem: bool,
        openInit: bool,
    },
    defaults: {
        disableArrow: false,
        children: null,
        openItem: false,
        openInit: false,
    },
};

export function Component({ children, className, disableArrow, openItem, openInit }) {
    const [open, setOpen] = React.useState(openInit);

    React.useEffect(() => {
        if (openItem) {
            setOpen(openItem);
        }
    }, [openItem]);
    return (
        <Element
            className={classnames(className, { "is-open": open || openItem }, { "disable-arrow": disableArrow }, CLASS)}
            name={NAME}
            type={TYPE}
            role="listitem"
            onClick={() => {
                if (!openItem) {
                    setOpen(!open);
                }
            }}>
            {children}
        </Element>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
