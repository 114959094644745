import React from "react";

import PropTypes from "prop-types";

import { generateId } from "~/util/general";

import I18n from "~/components/I18n";
import Image from "~/components/Image";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import FormattedDate from "~/pages/_components/FormattedDate";

import formField from "~/pages/forms/_components/_fields/_commons/formField";

export const NAME = "LoanPaymentAmount";

export const PROP = {
    types: {
        setValue: PropTypes.func.isRequired,
        editing: PropTypes.bool,
        value: PropTypes.shape({
            currency: PropTypes.string,
            quantity: PropTypes.number,
        }),
        i18n: PropTypes.func.isRequired,
    },
    defaults: { editing: false, value: null },
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        statementsLoan: [],
        loanCurrency: "",
        statementsSelected: 0,
    };

    feeRefs = {};

    quantityRef = null;

    static getDerivedStateFromProps(nextProps, prevState) {
        const { dependencyField, dependencyValue, value } = nextProps;

        const {
            data: { options },
        } = dependencyField;
        const loan = options.find(({ id }) => id === dependencyValue.value) || {};

        if (loan && loan.statementsLoan) {
            const { statementsLoan, currency } = loan;

            let statementsSelected = 0;
            let totalAmount = value && value.quantity ? value.quantity : 0;

            statementsLoan.map((statement) => {
                if (totalAmount - statement.importAmount >= 0) {
                    totalAmount -= statement.importAmount;
                    statementsSelected += 1;
                }
                return null;
            });

            return {
                ...prevState,
                statementsLoan,
                loanCurrency: currency,
                statementsSelected,
            };
        }
        return {
            ...prevState,
            statementsLoan: [],
            loanCurrency: "",
            statementsSelected: 0,
        };
    }

    handleOnChange = ({ target: { value, checked } }) => {
        const { setValue } = this.props;
        const { statementsLoan, loanCurrency } = this.state;

        const feeNumber = value;
        let total = 0;

        if (checked) {
            statementsLoan.map((statement) => {
                if (statement.feeNumber <= feeNumber) {
                    this.feeRefs[statement.feeNumber].checked = true;
                    total += statement.importAmount;
                }
                return null;
            });
        } else {
            statementsLoan.map((statement) => {
                if (statement.feeNumber >= feeNumber) {
                    this.feeRefs[statement.feeNumber].checked = false;
                } else {
                    total += statement.importAmount;
                }
                return null;
            });
        }

        const amount = {
            currency: loanCurrency,
            quantity: total,
        };

        setValue(amount);
    };

    render() {
        const { editing, value, i18n } = this.props;

        const { statementsSelected, loanCurrency, statementsLoan } = this.state;

        const currency = value ? value.currency : "";
        const quantity = value ? value.quantity : 0;

        if (editing) {
            if (statementsLoan.length === 0) {
                return (
                    <p>
                        <I18n id="loanPaymentAmount.noStatements" />
                    </p>
                );
            }
            return (
                <React.Fragment>
                    <div className="table">
                        <div className="table-body c-control--table">
                            {statementsLoan &&
                                statementsLoan.map((statement, idx) => (
                                    <React.Fragment key={generateId()}>
                                        <input
                                            id={statement.feeNumber}
                                            type="checkbox"
                                            className="c-control-input"
                                            value={statement.feeNumber}
                                            checked={idx < statementsSelected}
                                            onChange={this.handleOnChange}
                                            ref={(ref) => {
                                                this.feeRefs[statement.feeNumber] = ref;
                                            }}
                                        />
                                        <label htmlFor={statement.feeNumber} className="c-control-label table-row">
                                            <div className="c-control-icons">
                                                <div className="c-control-mark">
                                                    <Image src="check.svg" className="svg-icon svg-caret" />
                                                </div>
                                            </div>
                                            <div className="table-data">
                                                <span className="data-number">{statement.feeNumber}</span>
                                            </div>
                                            <div className="table-data">
                                                <span className="data-date">
                                                    <FormattedDate date={statement.dueDate} />
                                                </span>
                                            </div>
                                            <div className="table-data">
                                                <FormattedAmount
                                                    currency={loanCurrency}
                                                    quantity={statement.importAmount}
                                                />
                                            </div>
                                        </label>
                                    </React.Fragment>
                                ))}
                        </div>
                    </div>

                    <div
                        className="data-wrapper data-wrapper-inline"
                        style={{
                            justifyContent: "space-between",
                            display: "flex",
                            alignItems: "baseline",
                        }}>
                        <span className="data-label">{i18n("labelTotalPay")}</span>
                        <FormattedAmount currency={currency} quantity={quantity} />
                    </div>
                </React.Fragment>
            );
        }
        return <FormattedAmount currency={value.currency} quantity={value.quantity} />;
    }
}

export default formField({
    formClass: "form-group--loanPayments",
})(Component);
