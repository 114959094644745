import React from "react";

import Classnames from "classnames";
import { replace as Replace } from "connected-react-router";
import { Form, Field, Formik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { ALL, EMPTY_STR, QR_MODO_COUPON_ENABLED, QR_MODO_TYPE_DISCOUNT, SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/Filter";
import { SelectorsStore as SelectorsStoreBiometric } from "~/store/biometric";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import {
    SelectorsAction as SelectorsActionTransactions,
    SelectorsStore as SelectorsStoreTransactions,
} from "~/store/transactions";
import * as UtilsDate from "~/util/date";
import * as UtilsI18n from "~/util/i18n";

import ButtonFilter from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Select from "~/components/Select";

import Style from "./List.rules.scss";
import Coupon from "./_components/Coupon";
import List from "./_components/List";

export const { NAME } = Style;

export const PROP = {
    types: {
        askPassword: PropTypes.bool,
        delayedTransactions: PropTypes.bool,
        dispatch: PropTypes.func.isRequired,
        environmentType: PropTypes.string,
        fetching: PropTypes.bool.isRequired,
        filter: PropTypes.shape({ status: PropTypes.string.isRequired }),
        previousLocation: PropTypes.string,
        qrData: PropTypes.object,
        totalTransactions: PropTypes.arrayOf(PropTypes.object),
    },
    defaults: {
        askPassword: false,
        delayedTransactions: null,
        environmentType: null,
        fetching: false,
        filter: null,
        i18n: {
            allStatus: "Todos los estados",
            cancelled: "Rechazadas",
            currentMonth: "Último mes",
            finished: "Procesadas",
            lastSixMonths: "Últimos 6 meses",
            lastYear: "Último año",
        },
        previousLocation: null,
        qrData: null,
        totalTransactions: null,
    },
};

export const PERIOD_OPTIONS = {
    CURRENT_MONTH: "CURRENT_MONTH",
    LAST_SIX_MONTHS: "LAST_SIX_MONTHS",
    LAST_YEAR: "LAST_YEAR",
};

export const STATUS_OPTIONS = {
    ALL_STATUS: "ALL",
    FINISHED: "FINISHED",
    FAILED: "FAILED",
};

export const ACTIVITY = "payments.qrModo.send";

export function Component(props) {
    const {
        askPassword,
        dispatch,
        fetching,
        i18n,
        idTransactionList,
        isChangingEnvironment,
        isMobile,
        pageNumber,
        qrData,
    } = props;

    const DATE_RANGE = {
        [PERIOD_OPTIONS.CURRENT_MONTH]: UtilsDate.getCurrentPeriod(),
        [PERIOD_OPTIONS.LAST_SIX_MONTHS]: UtilsDate.getLastSixMonths(),
        [PERIOD_OPTIONS.LAST_YEAR]: UtilsDate.getLastYear(),
    };

    const [currentPeriodFilter, setCurrentPeriodFilter] = React.useState(PERIOD_OPTIONS.CURRENT_MONTH);
    const [currentStatusFilter, setCurrentStatusFilter] = React.useState(STATUS_OPTIONS.ALL_STATUS);
    const [showFilters, setShowFilters] = React.useState(false);

    React.useEffect(() => {
        const [dateFrom, dateTo] = DATE_RANGE[currentPeriodFilter];

        const defaultFilter = {
            dateFrom: UtilsDate.formatDate(dateFrom),
            dateTo: UtilsDate.formatDate(dateTo),
            pageNumber: 1,
            status: ALL,
            transaction: ACTIVITY,
        };

        dispatch(SelectorsActionTransactions.loadListRequest(defaultFilter));

        if (!isChangingEnvironment) {
            dispatch(SelectorsActionTransactions.checkIfThereAreQRModoRunningTransactions({ defaultFilter }));
        }

        return () => {
            dispatch(SelectorsActionTransactions.leaveQRModoPaymentsPage());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchMoreTransactions = () => {
        const [dateFrom, dateTo] = DATE_RANGE[currentPeriodFilter];

        dispatch(
            SelectorsActionTransactions.loadMoreTransactionsRequest({
                dateFrom,
                dateTo,
                pageNumber,
                status: currentStatusFilter,
                transaction: ACTIVITY,
            }),
        );
    };

    const handleClose = () => {
        const pathname = "/desktop";

        dispatch(
            Replace({
                pathname,
                state: { transition: "transition-flow-close" },
            }),
        );
    };

    const handleShowFilters = () => {
        setShowFilters(!showFilters);
    };

    const renderList = () => {
        return (
            <List
                fetchMoreTransactions={fetchMoreTransactions}
                idTransactionList={idTransactionList}
                isMobile={isMobile}
                showFilters={showFilters}
            />
        );
    };

    const renderSelectorFilter = () => {
        const periodOptions = [
            { value: PERIOD_OPTIONS.CURRENT_MONTH, label: i18n.currentMonth },
            { value: PERIOD_OPTIONS.LAST_SIX_MONTHS, label: i18n.lastSixMonths },
            { value: PERIOD_OPTIONS.LAST_YEAR, label: i18n.lastYear },
        ];

        const statusOptions = [
            { value: STATUS_OPTIONS.ALL_STATUS, label: i18n.allStatus },
            { value: STATUS_OPTIONS.FINISHED, label: i18n.finished },
            { value: STATUS_OPTIONS.FAILED, label: i18n.cancelled },
        ];

        const handleFilter = () => {
            const [dateFrom, dateTo] = DATE_RANGE[currentPeriodFilter];

            dispatch(
                SelectorsActionTransactions.loadListRequest({
                    dateFrom: UtilsDate.formatDate(dateFrom),
                    dateTo: UtilsDate.formatDate(dateTo),
                    status: currentStatusFilter,
                    transaction: ACTIVITY,
                }),
            );
        };

        const handlePeriodFilterChange = (e) => {
            setCurrentPeriodFilter(e.value);
        };

        const handleStatusFilterChange = (e) => {
            setCurrentStatusFilter(e.value);
        };

        const resetFilter = () => {
            setCurrentPeriodFilter(PERIOD_OPTIONS.CURRENT_MONTH);
            setCurrentStatusFilter(STATUS_OPTIONS.ALL_STATUS);

            const [dateFrom, dateTo] = DATE_RANGE[PERIOD_OPTIONS.CURRENT_MONTH];

            dispatch(
                SelectorsActionTransactions.loadListRequest({
                    dateFrom: UtilsDate.formatDate(dateFrom),
                    dateTo: UtilsDate.formatDate(dateTo),
                    status: STATUS_OPTIONS.ALL_STATUS,
                    transaction: ACTIVITY,
                }),
            );
        };

        return (
            <div className="filter-selector-content">
                <Formik onSubmit={handleFilter}>
                    {() => (
                        <Form>
                            <div className="filter-group">
                                <div className="transaction-filter">
                                    <span className="filter-label">
                                        <I18n id="servicePayment.qrModo.list.filter.periodLabel" />
                                    </span>
                                    <Field
                                        className="slideFromBottom flex-container"
                                        clearable={false}
                                        component={Select}
                                        labelKey="label"
                                        name="periodFilter"
                                        onChange={handlePeriodFilterChange}
                                        optionClassName="needsclick"
                                        options={periodOptions}
                                        placeholder={EMPTY_STR}
                                        value={currentPeriodFilter}
                                        valueKey="value"
                                    />
                                </div>
                                <div className="transaction-filter">
                                    <span className="filter-label">
                                        <I18n id="servicePayment.qrModo.list.filter.statusLabel" />
                                    </span>
                                    <Field
                                        className="slideFromBottom flex-container"
                                        clearable={false}
                                        component={Select}
                                        labelKey="label"
                                        name="statusFilter"
                                        onChange={handleStatusFilterChange}
                                        optionClassName="needsclick"
                                        options={statusOptions}
                                        placeholder={EMPTY_STR}
                                        value={currentStatusFilter}
                                        valueKey="value"
                                    />
                                </div>
                                {isMobile ? (
                                    <div className="btn-container">
                                        <ButtonFilter
                                            className="btn btn-secondary btn-filter btn-outline"
                                            onClick={resetFilter}>
                                            {UtilsI18n.get("transactions.filters.clean")}
                                        </ButtonFilter>
                                        <ButtonFilter
                                            className="btn btn-primary btn-filter btn-filled"
                                            onClick={handleFilter}>
                                            {UtilsI18n.get("transactions.filters.filter")}
                                        </ButtonFilter>
                                    </div>
                                ) : (
                                    <div className="btn-container">
                                        <ButtonFilter
                                            className="btn btn-primary btn-filter btn-filled"
                                            onClick={handleFilter}>
                                            {UtilsI18n.get("transactions.filters.filter")}
                                        </ButtonFilter>
                                        <ButtonFilter
                                            className="btn btn-secondary btn-filter btn-outline"
                                            onClick={resetFilter}>
                                            {UtilsI18n.get("transactions.filters.clean")}
                                        </ButtonFilter>
                                    </div>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    };

    const renderFilters = () => (
        <div className={`filter-selector filter}`}>
            {!isMobile ? (
                <div>
                    <span className="filter-label">
                        <I18n id="transactions.filter.title" />
                    </span>
                    {renderSelectorFilter()}
                </div>
            ) : (
                <React.Fragment>
                    <div className="filter-label-mobile-container">
                        <span className="filter-label-mobile">
                            <I18n id="servicePayment.qrModo.list.title" />
                        </span>
                        <div className={Classnames("btn-filter", { "is-open": showFilters })}>
                            <Image
                                className="filter-label-mobile-icon"
                                onClick={handleShowFilters}
                                src="filter-icon.svg"
                            />
                        </div>
                    </div>
                    {showFilters && renderSelectorFilter()}
                </React.Fragment>
            )}
        </div>
    );

    const qrEnabled = () => {
        return (
            qrData.qrEnabled &&
            qrData.qrTypeDiscount !== EMPTY_STR &&
            qrData.qrTypeDiscount !== QR_MODO_TYPE_DISCOUNT.NONE
        );
    };

    return (
        <Container
            className={!isMobile && "list-transaction list-web-transaction"}
            head-onClose={handleClose}
            head-title={UtilsI18n.get("servicePayment.qrModo.list.title")}
            image="qr-code.svg"
            name={NAME}
            scopeToShowNotification={SCOPE.TRANSACTIONS}
            wait={fetching && !askPassword}>
            <div
                className={Classnames({
                    "approve-screen": idTransactionList.length > 0,
                    "list-screen": idTransactionList.length === 0,
                    "coupon-enabled": qrEnabled() && idTransactionList.length === 0,
                })}>
                <div className={qrEnabled() ? "coupon-filters-container" : EMPTY_STR}>
                    {qrEnabled() && (
                        <Coupon data={qrData} buttonEnabled={qrData.qrCurrentInstallment === QR_MODO_COUPON_ENABLED} />
                    )}
                    {idTransactionList.length === 0 && renderFilters()}
                </div>
                {renderList()}
            </div>
        </Container>
    );
}

const mapStateToProps = (store) => ({
    askPassword: SelectorsStoreBiometric.askPassword(store),
    delayedTransactions: SelectorsStoreTransactions.getDelayedTransactions(store),
    environmentType: SelectorsStoreSession.getActiveEnvironmentType(store),
    fetching: SelectorsStoreTransactions.getFetching(store),
    filter: SelectorsStoreTransactions.getFilters(store),
    i18n: {
        allStatus: UtilsI18n.get("servicePayment.qrModo.list.filter.status.allStatus"),
        cancelled: UtilsI18n.get("servicePayment.qrModo.list.filter.status.cancelled"),
        currentMonth: UtilsI18n.get("servicePayment.qrModo.list.filter.period.currentMonth"),
        finished: UtilsI18n.get("servicePayment.qrModo.list.filter.status.finished"),
        lastSixMonths: UtilsI18n.get("servicePayment.qrModo.list.filter.period.lastSixMonths"),
        lastYear: UtilsI18n.get("servicePayment.qrModo.list.filter.period.lastYear"),
    },
    idTransactionList: SelectorsStoreTransactions.getidApprovals(store),
    isApproving: SelectorsStoreTransactions.getSignAction(store),
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    pageNumber: SelectorsStoreTransactions.getPageNumber(store),
    previousLocation: SelectorsStoreTransactions.getPreviousLocation(store),
    qrData: SelectorsStoreSession.getQrData(store),
});

export default Connect(mapStateToProps)(HighOrder.Resizable(Component));
