import React from "react";

import { routerActions as ActionsRouter } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import { compose as Compose } from "redux";

import { EMPTY_STR, SPACE_STR } from "~/constants";
import { ID_FORM, MODE, SCHEDULER_OPTIONS } from "~/constants/form";
import { SelectorsAction as SelectorsActionFile } from "~/store/files";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import {
    SelectorsAction as SelectorsActionMultilineFile,
    SelectorsStore as SelectorsStoreMultilineFile,
} from "~/store/multilinefile";
import {
    SelectorsAction as SelectorsActionTransactionLines,
    SelectorsStore as SelectorsStoreTransactionLines,
} from "~/store/transactionLines";
import UtilLodash from "~/util/lodash";

import Button from "~/components/Button";
import { Resizable } from "~/components/HighOrder";
import I18n from "~/components/I18n";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import DetailBox from "~/pages/_components/detailBox/DetailBox";

import FormField from "~/pages/forms/_components/_fields/_commons/formField";
import FilePayment from "~/pages/forms/_components/_fields/_multilinefile/FilePayment";

export const NAME = "Multilinefile";

export const PROP = {
    types: {
        acceptedFileTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
        editing: PropTypes.bool.isRequired,
        formTitle: PropTypes.string.isRequired,
        idForm: PropTypes.string.isRequired,
        idField: PropTypes.string.isRequired,
        isFromBackoffice: PropTypes.bool,
        label: PropTypes.string.isRequired,
        localBanks: PropTypes.array,
        maxFileSizeMB: PropTypes.number.isRequired,
        mode: PropTypes.oneOf([MODE.EDIT, MODE.VIEW]).isRequired,
        setValue: PropTypes.func.isRequired,
        value: PropTypes.arrayOf(
            PropTypes.shape({
                fileId: PropTypes.number,
                fileSize: PropTypes.number.isRequired,
                fileName: PropTypes.string.isRequired,
                fileType: PropTypes.string.isRequired,
            }),
        ),
    },
    defaults: {
        isFromBackoffice: false,
        localBanks: [],
        value: null,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        dependeeFields: null,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const { dependeeFields, dependencies, dispatch, form, hasFile, setValue } = nextProps;
        const { dependeeFields: dependeeFieldsState } = prevState;

        const dependeeValue = dependencies && dependeeFields && dependeeFields[dependencies[0]];
        const dependeeValueState = dependencies && dependeeFieldsState && dependeeFieldsState[dependencies[0]];

        const changeValue =
            (!dependeeValueState && dependeeValue) ||
            (dependeeValueState && dependeeValue && dependeeValueState.id !== dependeeValue.id);

        if (changeValue) {
            const { setValues, values } = form;
            const { value: idDebitAccount } = form.values.account || {};
            const debitCurrency = form.values.currencySelector && form.values.currencySelector[0];

            const extraData = {
                debitCurrency,
                idDebitAccount,
            };

            if (hasFile) {
                setValues(
                    {
                        ...values,
                        debitReference: EMPTY_STR,
                        scheduler: {
                            editing: true,
                            selectedOption: SCHEDULER_OPTIONS.TODAY,
                        },
                    },
                    false,
                );
            }

            dispatch(SelectorsActionMultilineFile.onFileRemoved());
            dispatch(SelectorsActionMultilineFile.cleanPond({ forcePondCleanUp: true }));
            dispatch(SelectorsActionMultilineFile.setExtraData({ extraData }));

            setValue([]);

            return {
                dependeeFields,
            };
        }

        return null;
    }

    componentDidMount() {
        const { dispatch, form } = this.props;

        const { value: idDebitAccount } = form.values.account || {};
        const debitCurrency = form.values.currencySelector && form.values.currencySelector[0];

        const extraData = {
            debitCurrency,
            idDebitAccount,
        };

        dispatch(SelectorsActionMultilineFile.onFileRemoved());
        dispatch(SelectorsActionMultilineFile.setExtraData({ extraData }));
    }

    componentDidUpdate() {
        const { hasFile, idForm, setValue, transactionLines, value, mode } = this.props;

        if (hasFile && (idForm === ID_FORM.SALARY_PAYMENT || idForm === ID_FORM.SUPPLIERS_PAYMENT)) {
            const {
                form: { values },
            } = this.props;

            let extraData;
            if (idForm === ID_FORM.SALARY_PAYMENT) {
                const {
                    account: { value: idDebitAccount },
                } = values;

                extraData = { idDebitAccount };
            } else {
                const { currencySelector } = values;

                extraData = { debitCurrency: currencySelector[0] };
            }

            // update file value to transactionLines
            if (transactionLines) {
                const lines = transactionLines.map((line, index) => ({ ...line, lineNumber: index + 1 }));
                if (value && value.length > 0 && !UtilLodash.isEqual(value[0].list, lines)) {
                    const updatedValue = { ...value[0], list: lines, ...extraData };
                    setValue([updatedValue]);
                }
            }
        } else if (!hasFile && value && value.length > 0 && mode !== MODE.VIEW) {
            // file has been removed, so value should be emptied
            setValue([]);
        }
    }

    isReadOnly = () => {
        const { mode } = this.props;

        return mode === MODE.VIEW || mode === MODE.PREVIEW;
    };

    handleDetailsClick = () => {
        const { dispatch, idForm, isFromBackoffice } = this.props;

        if (this.isReadOnly()) {
            let detailsUrl = `/form/${idForm}/processDetail`;

            const {
                data: { list: linesFromTransactionData },
            } = this.props;

            if (linesFromTransactionData) {
                dispatch(SelectorsActionTransactionLines.setLinesFromTransactionData({ linesFromTransactionData }));
            }

            if (isFromBackoffice) {
                detailsUrl += "/backoffice";
            }

            dispatch(ActionsRouter.push(detailsUrl));
        } else {
            const { form } = this.props;

            dispatch(ActionsRouter.push(`/form/${idForm}/processDetail`, { shouldLoadForm: false }));
            dispatch(SelectorsActionForm.setData(form.values));
        }
    };

    handleDownloadErrorsClick = () => {
        const { dispatch, idRelatedFile, nameRelatedFile } = this.props;
        dispatch(SelectorsActionFile.downloadFileRequest({ idFile: idRelatedFile, fileName: nameRelatedFile }));
    };

    renderLinesInfo = (downloadFile) => {
        const { processedFileData } = this.props;
        const { invalidLines, validLines } = processedFileData;

        if (!invalidLines) {
            return validLines;
        }

        return (
            <React.Fragment>
                <div>
                    {validLines + invalidLines} <I18n id="forms.inputFile.massivePayments.total.lines" />
                </div>
                <div>
                    <I18n id="forms.inputFile.massivePayments.invalid.lines" /> {invalidLines} / {validLines}{" "}
                    <I18n id="forms.inputFile.massivePayments.valid.lines" />
                </div>
                <button
                    type="button"
                    className="btn btn-asLink btn-dl-error"
                    onClick={() => downloadFile({ isDownloadingRelatedFile: true })}>
                    <I18n id="forms.inputFile.massivePayments.download.errors" />
                </button>
                <button
                    type="button"
                    className="btn btn-asLink btn-dl-error"
                    onClick={() => downloadFile({ isDownloadingRelatedFile: true })}>
                    <I18n id="forms.inputFile.massivePayments.download.correct" />
                </button>
            </React.Fragment>
        );
    };

    renderAmount = () => {
        const { processedFileData } = this.props;
        const { invalidLines, totalAmount } = processedFileData;
        const { currency, quantity } = totalAmount;

        if (!invalidLines) {
            return <FormattedAmount currency={currency} quantity={quantity} />;
        }

        return (
            <React.Fragment>
                <FormattedAmount currency={currency} quantity={quantity} />
                <div className="detailBox-data-aux">
                    <I18n id="forms.inputFile.massivePayments.calculated.amount.info" />
                </div>
            </React.Fragment>
        );
    };

    render() {
        const { data, mode, processedFileData, pendingLines, isMobile } = this.props;
        let processingData;

        if (mode === MODE.VIEW) {
            processingData = data.totalAmount ? data : processedFileData;
        } else {
            processingData = processedFileData;
        }

        const {
            invalidLines,
            linesWithNoAmount,
            totalAmount: { currency, quantity },
            validLines,
        } = processingData;

        switch (mode) {
            case MODE.EDIT: {
                return <FilePayment {...this.props} />;
            }
            case MODE.PREVIEW: {
                return (
                    <DetailBox>
                        {!!linesWithNoAmount && (
                            <I18n
                                component="div"
                                componentProps={{ className: "alert alert-warning mTop", role: "alert" }}
                                LINE_COUNT={linesWithNoAmount}
                                id="massive.payments.warning.noAmount"
                            />
                        )}
                        <DetailBox.Data label="forms.inputFile.massivePayments.lines">
                            <div>
                                {validLines + invalidLines} <I18n id="forms.inputFile.massivePayments.total.lines" />
                            </div>
                            {invalidLines > 0 && (
                                <React.Fragment>
                                    <div>
                                        <I18n id="forms.inputFile.massivePayments.invalid.lines" />
                                        {` ${invalidLines} / ${validLines} `}
                                        <I18n id="forms.inputFile.massivePayments.valid.lines" />
                                    </div>
                                    <button
                                        type="button"
                                        className="btn btn-asLink btn-dl-error"
                                        onClick={this.handleDownloadErrorsClick}>
                                        <I18n id="forms.inputFile.massivePayments.download.errors" />
                                    </button>
                                </React.Fragment>
                            )}
                        </DetailBox.Data>
                        <DetailBox.Data label="forms.inputFile.massivePayments.totalAmount">
                            <FormattedAmount currency={`${currency}`} quantity={`${quantity}`} />
                        </DetailBox.Data>
                        <div className="detailBox-row">
                            <Button
                                bsStyle="primary"
                                className="btn-small"
                                onClick={this.handleDetailsClick}
                                label="forms.inputFile.massivePayments.transaction.detail"
                            />
                        </div>
                    </DetailBox>
                );
            }
            case MODE.VIEW: {
                return (
                    <DetailBox>
                        <DetailBox.Data label="forms.inputFile.massivePayments.lines">{validLines}</DetailBox.Data>
                        <DetailBox.Data label="forms.inputFile.massivePayments.totalAmount">
                            <FormattedAmount currency={currency} quantity={quantity} />
                        </DetailBox.Data>
                        {!isMobile && !pendingLines && (
                            <div className="detailBox-row">
                                <Button
                                    bsStyle="primary"
                                    className="btn-small"
                                    onClick={this.handleDetailsClick}
                                    label="forms.inputFile.massivePayments.transaction.detail"
                                />
                            </div>
                        )}
                    </DetailBox>
                );
            }
            default: {
                return null;
            }
        }
    }
}
const mapStateToProps = (store) => ({
    idRelatedFile: SelectorsStoreMultilineFile.getIdRelatedFile(store),
    hasFile: SelectorsStoreMultilineFile.hasFile(store),
    localBanks: SelectorsStoreTransactionLines.getLocalBanks(store),
    nameRelatedFile: SelectorsStoreMultilineFile.getNameRelatedFile(store),
    pendingLines: SelectorsStoreMultilineFile.getPendingLines(store),
    processedFileData: SelectorsStoreMultilineFile.getProcessedFileData(store),
    transactionLines: SelectorsStoreTransactionLines.getTransactionLines(store),
});

export default Compose(
    Resizable,
    WithRouter,
    Connect(mapStateToProps),
    FormField({
        isValidValue: (_, { processedFileData }) => {
            const { invalidHeader, invalidFile } = processedFileData;
            return !invalidHeader && !invalidFile;
        },
        customLabel: () => SPACE_STR,
    }),
)(Component);
