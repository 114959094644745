import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import Container from "~/containers/Internal/Info";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import {
    SelectorsAction as SelectorsActionSwiftMessages,
    SelectorsStore as SelectorsStoreSwiftMessages,
} from "~/store/swift";
import { generateId as GenID } from "~/util/general";

import Button from "~/components/Button";
import Modal from "~/components/Modal";

import Style from "./Detail.rules.scss";
import DetailSendMail from "./DetailSendMail";

export const { NAME, TYPE, CLASS } = Style;

export const PROP = {
    types: { isChangingEnvironment: PropTypes.bool },
    defaults: { isChangingEnvironment: false },
};

export function Component(props) {
    const { match, dispatch, isChangingEnvironment, isFetching, messageDetail, i18n, showSendMailModal } = props;
    const {
        orderingAccount,
        swiftType,
        orderingBankCode,
        orderingBankName,
        orderingBankCountry,
        beneficiaryBankCode,
        beneficiaryBankName,
        beneficiaryBankCountry,
        amount,
        currency,
        valueDate,
        messageDate,
        messageTime,
        messageNumber,
        fieldList,
    } = messageDetail;

    const { swiftKey } = match.params;

    React.useEffect(() => {
        if (!isChangingEnvironment) {
            dispatch(SelectorsActionSwiftMessages.swiftMessagesDetailRequest({ swiftKey }));
        }
    }, [dispatch, isChangingEnvironment, swiftKey]);

    const handleBack = () => {
        const { history } = props;
        dispatch(SelectorsActionSwiftMessages.keepFilters({ keepFilters: true }));

        history.goBack();
    };
    const dowloadPDF = () => {
        dispatch(SelectorsActionSwiftMessages.pdfSwiftMessagesDetailRequest({ swiftKey }));
    };

    const slides = [];

    const wait = isFetching || Object.entries(messageDetail).length === 0;

    if (!wait) {
        slides.push(
            [
                {
                    label: "swift.detail.header.orderingAccount",
                    columnView: true,
                    props: {
                        orderingAccount,
                    },
                },
                {
                    label: "swift.detail.header.swiftType",
                    columnView: true,
                    props: {
                        swiftType,
                    },
                },
                {
                    label: "swift.detail.header.orderingBank",
                    columnView: true,
                    props: {
                        bankCode: orderingBankCode,
                        bank: orderingBankName,
                        country: orderingBankCountry,
                    },
                },
                {
                    label: "swift.detail.header.beneficiaryBank",
                    columnView: true,
                    props: {
                        bankCode: beneficiaryBankCode,
                        bank: beneficiaryBankName,
                        country: beneficiaryBankCountry,
                    },
                },
            ],
            [
                {
                    label: "swift.detail.header.amount",
                    columnView: true,
                    type: "Number",
                    props: {
                        value: amount,
                    },
                },
                {
                    label: "swift.detail.header.currency",
                    columnView: true,
                    props: {
                        currency,
                    },
                },
                {
                    label: "swift.detail.header.valueDate",
                    columnView: true,
                    type: "Date",
                    props: {
                        value: valueDate,
                    },
                },
                {
                    label: "swift.detail.header.messageDate",
                    columnView: true,
                    type: "Date",
                    props: {
                        value: messageDate,
                    },
                },
                {
                    label: "swift.detail.header.messageTime",
                    columnView: true,
                    props: {
                        messageTime,
                    },
                },
                {
                    label: "swift.detail.header.messageNumber",
                    columnView: true,
                    props: {
                        messageNumber,
                    },
                },
            ],
        );
    }

    return (
        <React.Fragment>
            <Container
                name={NAME}
                className={CLASS}
                wait={wait}
                head-onBack={handleBack}
                head-navigationText={[i18n.navigationPrevLabel, i18n.navigationActualLabel]}
                info-title-label="swift.detail.header.mobile.title"
                info-data={slides}
                scopeToShowNotification={SCOPE.SWIFT_MESSAGE_DETAIL}>
                {!wait && (
                    <React.Fragment>
                        <div data-name="title">
                            <span>{i18n.fieldSetTitle}</span>
                            <Button
                                bsStyle="link"
                                block={false}
                                label="client.swift.print.label"
                                onClick={() => printPage()}
                            />
                            <Button
                                id="export"
                                bsStyle="link"
                                block={false}
                                label="client.swift.pdf.dowload.label"
                                onClick={dowloadPDF}
                            />
                            <Button
                                label="client.swift.sendMail.label"
                                className="btn btn-link "
                                onClick={() => handleShowModal(true)}
                            />

                            <Modal
                                show={showSendMailModal}
                                closeButton
                                onClose={() => handleShowModal(false)}
                                labelKey="client.swift.sendMail.modal.title"
                                backdrop="static">
                                <DetailSendMail messageDetail={messageDetail} />
                            </Modal>
                        </div>

                        {fieldList && <MessageText />}
                    </React.Fragment>
                )}
            </Container>
        </React.Fragment>
    );

    function MessageText() {
        return fieldList.map(({ code, format, name, data }) => (
            <li key={`fieldItem-${GenID()}`}>
                <div>
                    <span>{`${code}${format}.`}</span>
                    <span>{name}</span>
                </div>
                {data.map((text) => (
                    <span key={`itemText-${GenID()}`}>{text}</span>
                ))}
            </li>
        ));
    }

    function handleShowModal(value) {
        dispatch(SelectorsActionSwiftMessages.setShowSendMailModal({ showSendMailModal: value }));
    }

    function printPage() {
        window.print();
    }
}

const mapStateToProps = (store) => ({
    i18n: {
        navigationPrevLabel: StoreI18n.getMessage(store, "swift.detail.navigation.description.prev"),
        navigationActualLabel: StoreI18n.getMessage(store, "swift.detail.navigation.description.actual"),
        fieldSetTitle: StoreI18n.getMessage(store, "swift.detail.fieldSet.title"),
    },
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    isFetching: SelectorsStoreSwiftMessages.isFetching(store),
    messageDetail: SelectorsStoreSwiftMessages.getMessageDetail(store),
    showSendMailModal: SelectorsStoreSwiftMessages.getShowSendMailModal(store),
});

export default Connect(mapStateToProps)(Component);
