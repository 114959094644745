import React from "react";

import PropTypes from "prop-types";
import { Col, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";

import { SelectorsStore as SelectorsStoreLoan } from "~/store/loan";

import I18n from "~/components/I18n";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import FormattedDate from "~/pages/_components/FormattedDate";
import Head from "~/pages/_components/Head";
import MainContainer from "~/pages/_components/MainContainer";

export const NAME = "StatementDetail";

export const PROP = {
    types: {
        // TODO: ver como manejo lo N/D
        loan: PropTypes.shape({
            totalAmount: PropTypes.number,
            paidAmount: PropTypes.number,
            interestRate: PropTypes.number,
        }).isRequired,
        statement: PropTypes.shape({
            dueDate: PropTypes.string,
            paidDate: PropTypes.string,
            importAmount: PropTypes.number,
            concept: PropTypes.string,
        }),
        history: PropTypes.shape({
            goBack: PropTypes.func.isRequired,
        }).isRequired,
    },
    defaults: { statement: null },
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    handleChange = () => {
        this.setState({}); // TODO: no hay que definir el state??
    };

    render() {
        const { loan, statement, history } = this.props;
        if (!statement) {
            return null;
        }
        return (
            <React.Fragment>
                <Head onClose={history.goBack} title="loans.statements.detail.payment" />
                <MainContainer>
                    <div className="above-the-fold">
                        <section className="container--layout align-items-center section-content-heading">
                            <Grid fluid>
                                <Row className="justify-content-center">
                                    <Col className="col">
                                        <ul className="data-list">
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="loans.details.statements.paidDate" />
                                                </span>{" "}
                                                <span className="data-date">
                                                    {statement.paidDate !== null ? (
                                                        <FormattedDate date={statement.paidDate} />
                                                    ) : (
                                                        ""
                                                    )}
                                                </span>
                                            </li>
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="loans.nextDueDate" />
                                                </span>{" "}
                                                <span className="data-date">
                                                    <FormattedDate date={statement.dueDate} />
                                                </span>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                        <section className="container--layout flex-grow align-items-center">
                            <Grid fluid>
                                <Row className="justify-content-center">
                                    <Col className="col">
                                        <ul className="data-list">
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="accounts.movement.detail.amount" />
                                                </span>{" "}
                                                <span className="data-strong">
                                                    <FormattedAmount
                                                        currency={loan.currency}
                                                        quantity={statement.importAmount}
                                                    />
                                                </span>
                                            </li>
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="loans.details.information" />
                                                </span>{" "}
                                                <span className="data-desc">{statement.concept}</span>
                                            </li>
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="loans.details.statements.pendingTotal" />
                                                </span>{" "}
                                                <FormattedAmount
                                                    currency={loan.currency}
                                                    quantity={loan.totalAmount - loan.paidAmount}
                                                />
                                            </li>
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="loans.information.interestRate" />
                                                </span>{" "}
                                                <span className="data-desc">{`${loan.interestRate} %`}</span>
                                            </li>
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="loans.information.interest" />
                                                </span>{" "}
                                                <FormattedAmount currency={loan.currency} quantity={loan.interests} />
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </div>
                </MainContainer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    loan: SelectorsStoreLoan.getSelectedLoan(state),
    statement: SelectorsStoreLoan.getSelectedStatement(state),
});

export default connect(mapStateToProps)(Component);
