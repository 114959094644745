import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { MODE } from "~/constants/form";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "comex";

export const PATH = {
    COMEX_DESKTOP_PRE_REQUEST: "/comex.pre",
    GET_AMOUNT_TEXT_REQUEST: "/misc.translateCurrency",
    LOAD_BANK_LIST_REQUEST: "/banks.list",
    OTHER_PROCEDURES_PRE_REQUEST: "/comex.import.genericForm.pre",
    OTHER_PROCEDURES_PREVIEW_REQUEST: "/comex.import.genericForm.preview",
    OTHER_PROCEDURES_SEND_REQUEST: "/comex.import.genericForm.send",
};

export const TYPE = FactoryType(NAME, [
    "CANCEL_CUSTOM_TRANSACTION_REQUEST",
    "CANCEL_TRANSACTION_REQUEST",
    "CHANGE_ALL_COLLAPSED_ELEMENTS_COUNT",
    "CHANGE_COLLAPSED_ELEMENTS_COUNT",
    "CLOSE_TRANSACTION_PREVIEW",
    "COMEX_DESKTOP_PRE_FAILURE",
    "COMEX_DESKTOP_PRE_REQUEST",
    "COMEX_DESKTOP_PRE_SUCCESS",
    "COMEX_FORM_SEND_DATA_FAILURE",
    "COMEX_FORM_TO_STEP_1",
    "COMEX_FORM_TO_STEP_3",
    "GET_AMOUNT_TEXT_FAILURE",
    "GET_AMOUNT_TEXT_REQUEST",
    "GET_AMOUNT_TEXT_SUCCESS",
    "LOAD_BANK_LIST_FAILURE",
    "LOAD_BANK_LIST_REQUEST",
    "LOAD_BANK_LIST_SUCCESS",
    "OTHER_PROCEDURES_PRE_FAILURE",
    "OTHER_PROCEDURES_PRE_REQUEST",
    "OTHER_PROCEDURES_PRE_SUCCESS",
    "OTHER_PROCEDURES_PREVIEW_FAILURE",
    "OTHER_PROCEDURES_PREVIEW_REQUEST",
    "OTHER_PROCEDURES_PREVIEW_SUCCESS",
    "OTHER_PROCEDURES_SEND_FAILURE",
    "OTHER_PROCEDURES_SEND_REQUEST",
    "OTHER_PROCEDURES_SEND_SUCCESS",
    "READ_TRANSACTION_FAILURE",
    "READ_TRANSACTION_REQUEST",
    "READ_TRANSACTION_SUCCESS",
    "RESTART_FLOW",
    "SEARCH_BANK_FAILURE",
    "SEARCH_BANK_REQUEST",
    "SEARCH_BANK_SUCCESS",
    "SET_BANK_SEARCH_INFO",
    "SET_SELECTED_BANK",
    "SIGN_TRANSACTION_PREVIEW",
    "SIGN_TRANSACTION_REQUEST",
    "SIGN_TRANSACTION_SUCCESS",
    "SIGN_TRANSACTION_FAILURE",
    "TOGGLE_CREDIT_LETTER",
    "TOGGLE_MODAL_BACK",
]);

export const PROP = {};

PROP.members = {};

PROP.types = {
    ...TypesRedux,
    collapsedElementsCount: PropTypes.number,
    fetching: PropTypes.bool,
    formData: PropTypes.object,
    mode: PropTypes.string,
    operationNumberModalVisible: PropTypes.bool,
    preData: PropTypes.object,
    transaction: PropTypes.object,
    transactionList: PropTypes.array,
};

PROP.defaults = {
    ...DefaultsRedux,
    amountString: "son d\u00f3lares americanos CERO",
    banks: [],
    collapsedElementsCount: 0,
    creditLetterOpen: {
        preData: {
            documentTypeList: [],
            currencyList: [],
        },
        formData: undefined,
    },
    fetching: false,
    mode: MODE.EDIT,
    modifyCreditLetter: {
        preData: {
            nextValidDate: EMPTY_STR,
            availableOperationList: [],
            maxDaysToSchedule: 0,
            unavailableDays: [],
            currencyList: [],
            hasContract: false,
        },
        formData: {},
        prevData: {},
    },
    operationNumberModalVisible: false,
    otherProcedure: {
        preData: {
            nextValidDate: EMPTY_STR,
            maxDaysToSchedule: 0,
            unavailableDays: [],
        },
        formData: {},
        prevData: {},
    },
    positions: {
        positionImport: [],
        positionExport: [],
        positionPrefinancing: [],
        positionGuarantee: [],
        positionImportTotal: 0,
        positionExportTotal: 0,
        positionGuaranteeTotal: 0,
    },
    prevMode: EMPTY_STR,
    selectedBank: {},
    showBankSearch: false,
    transaction: {},
    transactionList: false,
};
