import React from "react";

import ClassNames from "classnames";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import { compose as Compose } from "redux";

import { EMPTY_STR, LEVEL, SCOPE, ZERO } from "~/constants";
import { STATUS } from "~/constants/transaction";
import RouteTicket from "~/store/form/_sagas/_routeTicket";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import * as UtilI18n from "~/util/i18n";
import { Types as TypesRedux } from "~/util/prop/redux";

import DataNumber from "~/components/DataNumber";
import GridLayout from "~/components/GridLayout";
import Image from "~/components/Image";
import Link from "~/components/Link";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import ChevromRight from "~/pages/_components/listItem/ChevromRight";

import Checkbox from "~/pages/forms/_components/_fields/_commons/Checkbox";

export const NAME = "TransactionsItem";

export const PROP = {
    types: {
        ...TypesRedux,
        approve: PropTypes.bool,
        isDeletingDraft: PropTypes.bool,
        itemRef: PropTypes.func,
        onClickApproval: PropTypes.func,
        transaction: PropTypes.shape({
            cancelEnabled: PropTypes.bool.isRequired,
            editableInMobile: PropTypes.bool.isRequired,
            transaction: PropTypes.shape({
                activityName: PropTypes.string.isRequired,
                fullCreationDateTimeAsString: PropTypes.string.isRequired,
                idActivity: PropTypes.string.isRequired,
                idTransactionStatus: PropTypes.string.isRequired,
                shortDescriptionForMobile: PropTypes.string.isRequired,
                signaturesLabel: PropTypes.string.isRequired,
                statusLabel: PropTypes.string.isRequired,
                valueDateAsString: PropTypes.string.isRequired,
                valueDateLabel: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    },

    defaults: {
        approve: false,
        isDeletingDraft: false,
        itemRef: () => {},
        onClickApproval: () => {},
    },
};

export function Component(props) {
    const { administrationScheme, dispatch, isMobile, itemRef, location, transaction: transactionReceived } = props;
    const { editableInMobile, transaction } = transactionReceived;
    const {
        data,
        executing,
        fullCreationDateTimeAsString,
        idActivity,
        idTransaction,
        idTransactionStatus,
        idForm,
        statusLabel,
        signaturesLabel,
        valueDateAsString,
    } = transaction;
    const { couponDiscount } = data || {};

    const [qrDataResponse, setQrDataResponse] = React.useState({});

    React.useEffect(() => {
        if (data) {
            if (data.qrPaymentStatusResponse) {
                setQrDataResponse({
                    currency: data.qrPaymentStatusResponse.data.currency,
                    merchantName: data.qrPaymentStatusResponse.data.merchant_name,
                    totalAmount: data.qrPaymentStatusResponse.data.total_amount,
                });
            } else {
                setQrDataResponse({
                    currency: data.currency || EMPTY_STR,
                    merchantName: data.merchantName || EMPTY_STR,
                    totalAmount: data.total_amount || data.rawAmount || EMPTY_STR,
                });
            }
        }
    }, [data]);

    const [isChecked] = React.useState(false);

    let to = EMPTY_STR;

    const isNotEditable =
        isMobile &&
        !editableInMobile &&
        (idTransactionStatus === STATUS.DRAFT || idTransactionStatus === STATUS.RETURNED);

    if (idForm) {
        to = isNotEditable ? undefined : `/transaction/${idTransaction}`;
    } else {
        to = isNotEditable ? undefined : RouteTicket[idActivity](idTransaction, administrationScheme);
    }

    const getImage = (transactionStatus) => {
        let imgName;

        switch (transactionStatus) {
            case STATUS.FINISHED:
                imgName = "ok";

                break;
            case STATUS.CANCELLED:
            case STATUS.FAILED:
                imgName = "alertIconError";

                break;
            default:
                imgName = "alertIconMedium";

                break;
        }

        return `${imgName}.svg`;
    };

    const onClick = (event) => {
        if (isNotEditable) {
            dispatch(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get("transactions.trasactionItem.isNotEditable"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.TRANSACTIONS],
                }),
            );

            event.preventDefault();
        } else {
            dispatch(SelectorsActionTransactions.selectedTransaction());
        }
    };

    const getStatus = (itemStatusLabel, itemSignaturesLabel, transactionStatus) => {
        return (
            <React.Fragment>
                <div className="status transaction-status-display">
                    <Image src={getImage(transactionStatus)} className="transaction-status" />
                    <span>{itemStatusLabel}</span>
                </div>
                {!isMobile && itemSignaturesLabel && (
                    <React.Fragment>
                        <div>{itemSignaturesLabel}</div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    };

    const getContentMobile = () => {
        return (
            <div className="transaction-item-container">
                <div className="transaction-item-info-container">
                    <div>
                        <div className="table-data">
                            <span className="data-text">{qrDataResponse.merchantName}</span>
                        </div>
                        <div className="table-data data-status">
                            {getStatus(statusLabel, signaturesLabel, idTransactionStatus)}
                        </div>
                    </div>

                    <div className="flex-end">
                        <div className="table-data table-data-date">
                            {qrDataResponse.totalAmount && (
                                <FormattedAmount
                                    currency={qrDataResponse.currency}
                                    quantity={qrDataResponse.totalAmount}
                                />
                            )}
                        </div>
                        <div className="table-data table-data-date">{valueDateAsString}</div>
                    </div>
                </div>
                <div className="transaction-item-icons-container">
                    <ChevromRight />
                    {couponDiscount && couponDiscount > ZERO && (
                        <Image classNameOuter="coupon-icon-list" src="cupon-small.svg" />
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="flex-display">
            {idTransactionStatus === STATUS.PENDING && !executing && (
                <div className={ClassNames("table-data", { "checkbox-item": isMobile })}>
                    <Checkbox checked={isChecked} onChange={() => {}} />
                </div>
            )}
            <Link
                to={{ pathname: to, state: { from: location.pathname } }}
                className="table-row"
                innerRef={itemRef}
                onClick={onClick}>
                {isMobile ? (
                    getContentMobile()
                ) : (
                    <GridLayout className="body">
                        <div className="transaction-list-data">
                            <div className="title">{qrDataResponse.merchantName}</div>
                        </div>
                        <div className="transaction-list-data">
                            <div className="date">{fullCreationDateTimeAsString}</div>
                        </div>
                        <div className="transaction-list-data">
                            {qrDataResponse.totalAmount && (
                                <DataNumber value={qrDataResponse.totalAmount} prefix={qrDataResponse.currency} />
                            )}
                        </div>
                        <div className="transaction-list-data transaction-list-data-status">
                            {getStatus(statusLabel, signaturesLabel, idTransactionStatus)}
                        </div>
                        <div className="coupon-icon-list-container">
                            {couponDiscount && couponDiscount > ZERO && (
                                <Image className="coupon-icon-list" src="cupon-small.svg" />
                            )}
                            <ChevromRight />
                        </div>
                    </GridLayout>
                )}
            </Link>
        </div>
    );
}

const mapStateToProps = (store) => ({ administrationScheme: SelectorsStoreSession.getAdministrationScheme(store) });

export default Compose(WithRouter, Connect(mapStateToProps))(Component);
