import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR, MASSIVE_PAYMENTS_LOADING_METHOD, NO_SIGNATURE } from "~/constants";

import ConfirmationModal from "~/pages/_components/ConfirmationModal";

import RadioButton from "~/pages/forms/_components/_fields/_commons/RadioButton";

export const NAME = "RadioButtonGroup";

export const PROP = {
    types: {
        extraTextKey: PropTypes.string,
        idConfirmationLabel: PropTypes.string,
        inLineControl: PropTypes.bool,
        labelKey: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        /* Es un map */
        options: PropTypes.objectOf(PropTypes.object).isRequired,
        showConfirmationModal: PropTypes.bool.isRequired,
        value: PropTypes.string,
        valueKey: PropTypes.string,
    },
    defaults: {
        extraTextKey: "extraText",
        idConfirmationLabel: "salaryPayment.manual.importList.confirmation",
        inLineControl: false,
        labelKey: "label",
        showConfirmationModal: false,
        value: EMPTY_STR,
        valueKey: "id",
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = { askConfirmation: false, pendingOnChangeValue: null };

    handleRadioChange = (selectedValue) => {
        const { onChange, showConfirmationModal, value } = this.props;

        if (
            showConfirmationModal &&
            value !== selectedValue &&
            selectedValue !== MASSIVE_PAYMENTS_LOADING_METHOD.MANUAL
        ) {
            this.setState({ askConfirmation: true, pendingOnChangeValue: selectedValue });
        } else {
            onChange(selectedValue);
        }
    };

    handleAccept = () => {
        const { onChange } = this.props;
        const { pendingOnChangeValue } = this.state;

        if (pendingOnChangeValue !== null) {
            onChange(pendingOnChangeValue);
        }

        this.setState({ askConfirmation: false, pendingOnChangeValue: null });
    };

    handleCancel = () => {
        this.setState({ askConfirmation: false, pendingOnChangeValue: null });
    };

    render() {
        const {
            disabled,
            extraTextKey,
            idConfirmationLabel,
            inLineControl,
            labelKey,
            options,
            value,
            valueKey,
            customCheckImageName,
        } = this.props;
        const { askConfirmation } = this.state;

        return (
            <div className="form-group-control-list">
                {options.map((option) => (
                    <RadioButton
                        checked={option[valueKey] === value}
                        extraText={option[extraTextKey]}
                        disabled={option.disabled || (disabled && option?.id === NO_SIGNATURE)}
                        inLineControl={inLineControl}
                        key={option[valueKey]}
                        customCheckImageName={customCheckImageName}
                        label={option[labelKey]}
                        onChange={this.handleRadioChange}
                        value={option[valueKey]}
                    />
                ))}
                <ConfirmationModal
                    handleAcept={this.handleAccept}
                    handleCancel={this.handleCancel}
                    idLabel={idConfirmationLabel}
                    show={askConfirmation}
                />
            </div>
        );
    }
}

export default Component;
