import { replace as Replace } from "connected-react-router";
import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE, GLOBAL_UNEXPECTED_ERROR_KEY } from "~/constants";
import { SelectorsMiddleware as SelectorsMiddlewareForm, TYPE as TYPE_FORM } from "~/store/form";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as UtilI18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsAction } from "./_selectors";

const sagas = [
    TakeLatest(TYPE.LOAD_ADMINISTRATION_TICKET_REQUEST, readAdministrationTransaction),
    TakeLatest(TYPE_FORM.SIGN_TRANSACTION_PREVIEW_SUCCESS, signAdministrativeTransaction),
    TakeLatest(TYPE_FORM.SEND_FORM_SUCCESS, signAdministrativeTransactionSuccess),

    TakeLatest(TYPE.SIGN_ADMINISTRATIVE_TRANSACTION, signAdministrativeTransaction),

    TakeLatest(TYPE.CANCEL_ADMINISTRATIVE_TRANSACTION, cancelAdministrativeTransaction),
];

export default sagas;

function* readAdministrationTransaction(props) {
    const { idTransaction } = props;
    const transactionResponse = yield Call(SelectorsMiddlewareForm.readTransaction, {
        idTransactionToRead: idTransaction,
    });

    if (transactionResponse.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.loadAdministrationTicketFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADMINISTRATION],
            }),
        );
    } else {
        yield Put(SelectorsAction.loadAdministrationTicketSuccess({ data: transactionResponse.data.data.transaction }));
    }
}

function* signAdministrativeTransaction(props) {
    if (!props.idForm) {
        const { idActivity, idTransaction } = props;

        const { data, type } = yield Call(SelectorsMiddlewareForm.sign, { idTransaction }, idActivity);
        if (type === "W") {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get("forms.sign.error"),
                    level: LEVEL.ERROR,
                    scopes: SCOPE.ADMINISTRATION,
                }),
            );
        } else {
            yield Put(SelectorsAction.signAdministrativeTransactionSuccess());
            yield Put(SelectorsAction.loadAdministrationTicketRequest({ idTransaction }));
        }
    }
}

function* signAdministrativeTransactionSuccess(props) {
    const { transaction } = props;

    if (transaction.idForm === null) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("transaction.sign.success"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.TRANSACTIONS],
            }),
        );
        yield Put(Replace(`/transactions/list`));
    }
}

function* cancelAdministrativeTransaction(props) {
    const { idTransaction } = props;

    const { type } = yield Call(SelectorsMiddlewareForm.cancelTransaction, {
        idTransactionToCancel: idTransaction,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("forms.cancelTransaction.errorMessage"),
                level: LEVEL.ERROR,
                scopes: SCOPE.ADMINISTRATION,
            }),
        );
    } else {
        yield Put(SelectorsAction.loadAdministrationTicketRequest({ idTransaction }));
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("forms.cancelTransaction.confirmationMessage"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.ADMINISTRATION],
            }),
        );
    }
}
