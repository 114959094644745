import React from "react";

import { push as Push, routerActions } from "connected-react-router";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/Filter";
import { SelectorsStore as StoreI18n, SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import {
    SelectorsAction as SelectorsActionInvestment,
    SelectorsStore as SelectorsStoreInvestment,
} from "~/store/investment";
import { SelectorsAction as SelectorsActionPosition, SelectorsStore as SelectorsStorePosition } from "~/store/position";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionWMBonds } from "~/store/wm/bonds";
import { SelectorsAction as SelectorsActionWMBuy } from "~/store/wm/funds/buy";
import { SelectorsAction as SelectorsActionWMSell } from "~/store/wm/funds/sell";
import { SelectorsAction as SelectorsActionWMSwitch } from "~/store/wm/funds/switch";
import { SelectorsAction as SelectorsActionWMShares } from "~/store/wm/shares";
import * as i18nUtils from "~/util/i18n";
import UtilsLodash from "~/util/lodash";
import { numberFormat } from "~/util/number";

import Box from "~/components/Box";
import Image from "~/components/Image";
import Link from "~/components/Link";
import SelectButton from "~/components/SelectButton";

import AccordionDetail from "./AccordionDetail";
import AccountItem from "./AccountItem";
import AvailableForInvestmentModal from "./AvailableForInvestmentModal";
import ConfirmModal from "./ConfirmModal";
import Style from "./Investment.rules.scss";
import LinkOptions from "./LinkOptions";
import NotificationItem from "./NotificationItem";
import PieChartSection from "./PieChartSection";
import PopUp from "./PopUp";
import TransactionItem from "./TransactionItem";
import { groupByCurrencyProductType } from "./constants";

export const { NAME, NAME_MESSAGES, TYPE_NOTE, NAME_NOTE } = Style;

export const PROP = {
    types: { isChangingEnvironment: PropTypes.bool },
    defaults: { isChangingEnvironment: false },
};

export class Component extends React.Component {
    state = {
        forceMode: "opened",
        showModal: false,
        showDetailsModal: false,
        showConfirmModal: false,
        link: EMPTY_STR,
    };

    componentDidMount() {
        const { dispatch, isChangingEnvironment } = this.props;

        if (!isChangingEnvironment) {
            dispatch(SelectorsActionInvestment.myBriefcasePreRequest());
            dispatch(SelectorsActionPosition.deletePosition());
            dispatch(
                SelectorsActionPosition.readPositionRequest({
                    productTypes: [10, 11, 13, 14, 20, 21, 22, 23, 24, 25, 26],
                }),
            );
        }
    }

    handleBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    getPositionPDF = (order) => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionPosition.getPositionPDF({ order }));
    };

    toggleAccordion = () => {
        const { forceMode } = this.state;
        if (forceMode === "closed") {
            this.setState({ forceMode: "opened" });
        } else {
            this.setState({ forceMode: "closed" });
        }
    };

    handleOpenModal = (fields, data, currency, title) => {
        this.setState({ showModal: true, fields, data, currency, title });
    };

    getProductActions = (product, productType) => {
        const {
            fundPapers,
            hasW8Signed,
            hasRiskProfile,
            hasInvestmentsModule,
            dispatch,
            activeEnvironment: {
                permissions: { funds, otherInvestments },
            },
        } = this.props;

        const handleBuyClick = (event) => {
            event.preventDefault();
            const paper = product.othersFields.PAPEL;

            let route;
            if (productType === 23 || productType === 24) {
                dispatch(SelectorsActionWMShares.buySharesPre({ idPaper: paper }));
                route = `/wm/shares/buy/`;
            } else if (productType === 21 || productType === 22) {
                dispatch(SelectorsActionWMBonds.buyBondsPre({ idPaper: paper }));
                route = `/wm/bonds/buy/`;
            }
            dispatch(routerActions.push(route));
        };

        const handleSellClick = (e, p, type) => {
            e.preventDefault();
            const paper = p.othersFields.PAPEL;
            let route;
            if (type === 23 || type === 24) {
                route = `/wm/shares/sell/${paper}`;
            } else if (type === 21 || type === 22) {
                route = `/wm/bonds/sell/${paper}`;
            }
            dispatch(routerActions.push(route));
        };

        const downloadDetail = (e, p) => {
            e.preventDefault();
            const { PAPEL } = p?.othersFields;
            if (p?.othersFields?.PDFOLINK === "LINK") {
                const { LINK1, LINK2, LINK3 } = p.othersFields;
                const link = `${LINK1 || EMPTY_STR}${LINK2 || EMPTY_STR}${LINK3 || EMPTY_STR}`;
                if (link.toUpperCase().startsWith("HTTP://")) {
                    // open modal confirmación
                    this.setState({
                        showConfirmModal: true,
                        link,
                    });
                } else {
                    dispatch(
                        SelectorsActionWMBonds.getPaper({
                            idPaper: PAPEL,
                            productType,
                            detailType: "localLink",
                        }),
                    );
                }
            } else {
                dispatch(
                    SelectorsActionWMBonds.getPaper({
                        idPaper: PAPEL,
                        productType,
                        detailType: "localPDF",
                    }),
                );
            }
        };

        if (productType === 25 && funds) {
            const permissionCondition = hasW8Signed && hasRiskProfile && hasInvestmentsModule;
            return (
                <Box justify="end">
                    <SelectButton>
                        <Link onClick={(e) => downloadDetail(e, product)}>
                            {i18nUtils.get("client.wm.product.actions.downloadDetail")}
                        </Link>
                        {permissionCondition && fundPapers.includes(product.othersFields.PAPEL) && (
                            <Link
                                onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(
                                        SelectorsActionWMBuy.selectedIdPaper({ idPaper: product.othersFields.PAPEL }),
                                    );
                                    dispatch(Push(`/wm/funds/buy`));
                                }}>
                                {i18nUtils.get("client.wm.product.actions.buy")}
                            </Link>
                        )}
                        {permissionCondition && product.othersFields.AFECTADOENGARANTIA === "(G)" && (
                            <Link onClick={() => "TO DO"}>{i18nUtils.get("client.wm.product.actions.guarantee")}</Link>
                        )}
                        {permissionCondition && product.othersFields.AFECTADOENGARANTIA !== "(G)" && (
                            <Link
                                onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(
                                        SelectorsActionWMSell.selectedIdPaper({ idPaper: product.othersFields.PAPEL }),
                                    );
                                    dispatch(Push(`/wm/funds/sell`));
                                }}>
                                {i18nUtils.get("client.wm.product.actions.sell")}
                            </Link>
                        )}
                        {permissionCondition && product.othersFields.AFECTADOENGARANTIA !== "(G)" && (
                            <Link
                                onClick={() => {
                                    dispatch(
                                        SelectorsActionWMSwitch.selectedIdPaper({
                                            idPaper: product.othersFields.PAPEL,
                                        }),
                                    );
                                    dispatch(Push(`/wm/funds/switch`));
                                }}>
                                {i18nUtils.get("client.wm.product.actions.switch")}
                            </Link>
                        )}
                    </SelectButton>
                </Box>
            );
        }

        if (
            (productType === 21 || productType === 22 || productType === 23 || productType === 24) &&
            otherInvestments
        ) {
            const permissionCondition = hasW8Signed && hasRiskProfile && hasInvestmentsModule;
            return (
                <Box justify="end">
                    <SelectButton>
                        <Link onClick={(e) => downloadDetail(e, product)}>
                            {i18nUtils.get("client.wm.product.actions.downloadDetail")}
                        </Link>
                        {permissionCondition && (
                            <Link onClick={handleBuyClick}>{i18nUtils.get("client.wm.product.actions.buy")}</Link>
                        )}
                        {permissionCondition && product.othersFields.AFECTADOENGARANTIA === "(G)" && (
                            <Link onClick={() => {}}>{i18nUtils.get("client.wm.product.actions.guarantee")}</Link>
                        )}
                        {permissionCondition && product.othersFields.AFECTADOENGARANTIA !== "(G)" && (
                            <Link onClick={(e) => handleSellClick(e, product, productType)}>
                                {i18nUtils.get("client.wm.product.actions.sell")}
                            </Link>
                        )}
                    </SelectButton>
                </Box>
            );
        }
        return null;
    };

    render() {
        const {
            fetching,
            positions,
            i18n,
            transactionList,
            availableForInvesting,
            availableForInvestChart,
            myPortfolioTotal,
            notifications: { productsAboutToExpire, documentsExpiredOrAboutToExpire, notificationsQuantity },
            activeEnvironment: {
                permissions: { funds, otherInvestments },
                environmentData: { hasW8Signed, hasRiskProfile },
            },
            hasProducts,
            dispatch,
            lang,
        } = this.props;

        const { headTitle, closeAll, openAll } = i18n;
        const {
            forceMode,
            showModal,
            title,
            fields,
            data,
            showConfirmModal,
            currency,
            link,
            showDetailsModal,
        } = this.state;

        const { decimalSeparator, thousandSeparator } = numberFormat(lang);
        return (
            <React.Fragment>
                <Container
                    wait={fetching}
                    name={NAME}
                    head-onBack={this.handleBack}
                    head-title={headTitle}
                    className="position"
                    image="businessPortfolio.svg"
                    scopeToShowNotification={SCOPE.INVESTMENT}>
                    {((hasW8Signed && hasRiskProfile && (funds || otherInvestments)) || hasProducts) && (
                        <div className="links header-content">
                            {hasW8Signed && hasRiskProfile && (otherInvestments || funds) && (
                                <React.Fragment>
                                    <Box flex justify="end" className="box-button-content">
                                        <SelectButton label={i18nUtils.get("wm.newInvestment")}>
                                            {otherInvestments && (
                                                <Link to="/wm/bonds/buy">{i18nUtils.get("wm.bonds")}</Link>
                                            )}
                                            {funds && <Link to="/wm/funds/list">{i18nUtils.get("wm.funds")}</Link>}
                                            {otherInvestments && (
                                                <Link to="/wm/shares/buy">{i18nUtils.get("wm.shares")}</Link>
                                            )}
                                            {otherInvestments && (
                                                <Link to="/investment/termdeposit/">
                                                    {i18nUtils.get("wm.termdeposit")}
                                                </Link>
                                            )}
                                        </SelectButton>
                                    </Box>
                                </React.Fragment>
                            )}
                            <div className="text-left">
                                {!UtilsLodash.isEmpty(availableForInvesting) && (
                                    <NumberFormat
                                        prefix={`${i18nUtils.get(
                                            `core.currency.label.${availableForInvesting.availableAmountCurrency}`,
                                        )}  `}
                                        className="form-control amount"
                                        fixedDecimalScale
                                        decimalScale="2"
                                        decimalSeparator={decimalSeparator}
                                        thousandSeparator={thousandSeparator}
                                        displayType="text"
                                        value={availableForInvesting.availableAmount}
                                    />
                                )}
                                {i18nUtils.get("client.investment.myBriefcase.header.left.text")}
                                <LinkOptions
                                    handleClick={() => {
                                        this.setState({ showDetailsModal: true });
                                    }}
                                    text={i18nUtils.get("wm.view.details")}
                                />
                            </div>

                            <LinkOptions
                                image="arrow-top.svg"
                                handleClick={() => this.toggleAccordion()}
                                text={forceMode === "opened" ? closeAll : openAll}
                                className={forceMode === "opened" && "invert"}
                            />
                        </div>
                    )}
                    {notificationsQuantity > 0 && (
                        <AccordionDetail
                            key="notifications"
                            id="notifications"
                            title={
                                notificationsQuantity === 1
                                    ? i18nUtils.get("wm.notifications.message", null, { param1: notificationsQuantity })
                                    : i18nUtils.get("wm.notifications.message.plural", null, {
                                          param1: notificationsQuantity,
                                      })
                            }
                            className="accordion-notifications"
                            forceMode={forceMode}
                            isActive={false}>
                            <NotificationItem
                                productItems={productsAboutToExpire}
                                documentsItems={documentsExpiredOrAboutToExpire}
                                fundsPermission={funds}
                                otherInvestmentsPermission={otherInvestments}
                            />
                        </AccordionDetail>
                    )}

                    {hasProducts && (
                        <AccordionDetail
                            key="transactions"
                            id="transactions"
                            title={
                                transactionList && transactionList.length > 0
                                    ? i18nUtils.replaceParams(
                                          i18nUtils.get(`investment.myBriefcase.transactions.headerInfo`),
                                          {
                                              quantity: transactionList.length,
                                          },
                                      )
                                    : i18nUtils.get("investment.myBriefcase.transactions.headerInfo.empty")
                            }
                            className="container-accordion-detail"
                            forbidOpening={!transactionList || transactionList.length === 0}
                            forceMode={transactionList && transactionList.length > 0 ? forceMode : "closed"}>
                            {transactionList && transactionList.length > 0 && (
                                <TransactionItem transactions={transactionList} dispatch={dispatch} />
                            )}
                        </AccordionDetail>
                    )}

                    {hasProducts && availableForInvestChart.length > 0 && (
                        <PieChartSection data={availableForInvestChart} myPortfolioTotal={myPortfolioTotal} />
                    )}

                    {hasProducts &&
                        positions.map((position) => {
                            return (
                                <AccordionDetail
                                    key={position.productType}
                                    id={position.productType}
                                    title={i18nUtils.get(`client.position.productType.${position.productType}`)}
                                    total={position.total}
                                    className={`container-accordion-detail position_${position.productType}`}
                                    forceMode={forceMode}>
                                    <AccountItem
                                        name={i18nUtils.get(`client.position.productType.${position.productType}`)}
                                        productType={position.productType}
                                        total={position.total}
                                        lang={lang}
                                        totalByCurrency={position.totalByCurrencyMap}
                                        productsByCurrency={position.productsByCurrency}
                                        innerAccordionForceMode={forceMode}
                                        getProductActions={this.getProductActions}
                                        handleOpenModal={this.handleOpenModal}
                                        groupByCurrency={groupByCurrencyProductType.includes(position.productType)}
                                        productsToExpire={productsAboutToExpire}
                                    />
                                </AccordionDetail>
                            );
                        })}
                    <PopUp
                        show={showModal}
                        title={title}
                        fields={fields}
                        data={data}
                        handleCloseModal={() => {
                            this.setState({ showModal: false });
                        }}
                        currency={currency}
                    />
                    <ConfirmModal
                        show={showConfirmModal}
                        handleCloseModal={() => {
                            this.setState({ showConfirmModal: false });
                        }}
                        onSubmit={() => {
                            const win = window.common.openLink(link);
                            if(win){
                                win.focus();
                            }
                            this.setState({ showConfirmModal: false });
                        }}
                    />
                    <AvailableForInvestmentModal
                        show={showDetailsModal}
                        data={availableForInvesting}
                        handleCloseModal={() => {
                            this.setState({ showDetailsModal: false });
                        }}
                    />
                    {!hasProducts && (
                        <div>
                            <p>
                                {i18nUtils.get("investments.noProducts.text1")}
                                <b> {i18nUtils.get("investments.noProducts.text2")} </b>
                                {i18nUtils.get("investments.noProducts.text3")}
                            </p>
                            <Link className="banner-container" to="/investment/requestInfo">
                                <Image src="banner_inversiones.png" />
                            </Link>
                        </div>
                    )}
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store) || {},
    availableForInvestChart: SelectorsStoreInvestment.getAvailableForInvestChart(store),
    availableForInvesting: SelectorsStoreInvestment.getAvailableForInvesting(store),
    fetching: SelectorsStoreInvestment.getFetching(store),
    fundPapers: SelectorsStoreInvestment.getFundPapers(store),
    hasDGI: SelectorsStoreInvestment.getHasDGI(store),
    hasInvestmentsModule: SelectorsStoreInvestment.getHasInvestmentsModule(store),
    hasProducts: SelectorsStoreInvestment.getHasProducts(store),
    hasRiskProfile: SelectorsStoreInvestment.getHasRiskProfile(store),
    hasW8Signed: SelectorsStoreInvestment.getHasW8Signed(store),
    i18n: {
        closeAll: StoreI18n.getMessage(store, "position.accordions.closeAll"),
        headTitle: StoreI18n.getMessage(store, "investment.title"),
        openAll: StoreI18n.getMessage(store, "position.accordions.openAll"),
    },
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    lang: SelectorsStoreI18n.getLang(store),
    myPortfolioTotal: SelectorsStoreInvestment.getMyPortafolioTotal(store),
    notifications: SelectorsStoreInvestment.getNotifications(store),
    positions: SelectorsStorePosition.getPositions(store),
    resident: SelectorsStoreInvestment.getResident(store),
    transactionList: SelectorsStoreInvestment.getTransactionList(store),
});

export default Connect(mapStateToProps)(Component);
