import React from "react";

import PropTypes from "prop-types";
import { Navbar } from "react-bootstrap";

export const NAME = "Header";

export const PROP = {
    types: { children: PropTypes.arrayOf(PropTypes.element) },
    defaults: { children: null },
};

export function Component(props) {
    const { children } = props;
    return (
        <header className="view-header">
            <Navbar collapseOnSelect fluid>
                <Navbar.Header>
                    <div className="toolbar">{children}</div>
                </Navbar.Header>
            </Navbar>
        </header>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
