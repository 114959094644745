import React from "react";

import { goBack as GoBack } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import {
    SelectorsStore as SelectorsStoreUploadPaymentOrders,
    SelectorsAction as SelectorsActionUploadPaymentOrders,
} from "~/store/factoring/uploadPaymentOrders";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as UtilsI18n from "~/util/i18n";

import FormPreview from "./Step2Preview/Step2Preview";
import FormView from "./Step3View/Step3View";

export const NAME = "UploadPaymentOrdersTransaction";

export const PROP = {
    types: {
        isChangingEnvironment: PropTypes.bool,
        transaction: PropTypes.shape({
            idTransaction: PropTypes.string,
            idTransactionStatus: PropTypes.string,
            valueDateAsString: PropTypes.string,
            executing: PropTypes.bool,
        }).isRequired,
    },
    defaults: {
        isChangingEnvironment: false,
    },
};

export function Component(props) {
    const { dispatch, fetching, idTransaction, isChangingEnvironment, mode, prevMode, transaction } = props;

    React.useEffect(() => {
        if (!isChangingEnvironment) {
            dispatch(SelectorsActionUploadPaymentOrders.readUploadPaymentOrdersTransactionRequest({ idTransaction }));
        }
    }, [dispatch, idTransaction, isChangingEnvironment]);

    return (
        <Container
            name={NAME}
            wait={fetching}
            step={mode}
            head-title={UtilsI18n.get("client.factoring.uploadPaymentOrders.title")}
            head-onBack={(mode !== MODE.VIEW && mode !== MODE.EDIT && handleBack) || undefined}
            head-onBackWeb={mode === MODE.EDIT && handleBack}
            scopeToShowNotification={SCOPE.FACTORING_UPLOAD_PAYMENT_ORDERS}>
            {mode === MODE.PREVIEW && <FormPreview />}
            {(mode === MODE.EDIT || mode === MODE.VIEW) && <FormView transaction={transaction} />}
        </Container>
    );

    function handleBack() {
        if (prevMode && prevMode !== mode && mode !== MODE.VIEW) {
            dispatch(SelectorsActionUploadPaymentOrders.closeConfirmation());
        } else {
            dispatch(GoBack());
        }
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store, props) => {
    return {
        fetching: SelectorsStoreUploadPaymentOrders.isFetching(store),
        idTransaction: props.match.params.id,
        isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
        mode: SelectorsStoreUploadPaymentOrders.getMode(store),
        prevMode: SelectorsStoreUploadPaymentOrders.getPrevMode(store),
        transaction: SelectorsStoreUploadPaymentOrders.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
