import React from "react";

import PropTypes from "prop-types";
import { Nav } from "react-bootstrap";
import { connect as Connect } from "react-redux";

import { ENVIRONMENT_TYPE } from "~/constants";
import { RELEASE_MOD, TRIMMED_MENU_ITEMS, TRIMMED } from "~/constants/release-conf";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import { SelectorsStore as SelectorsStoreWidget } from "~/store/widget";

import Menu from "~/components/Menu";

export const NAME = "Menu";

export const PROP = {};
// TODO: Members coming from the store, should be assambled from imported declarations.
PROP.members = {
    /** An internal form description, used for render links to automatically generated forms. */
    activeEnvironmentForm: {
        idForm: PropTypes.string.isRequired,
    },
    /** An internal permission scheme, used to determine which menu items should be available */
    activeEnvironmentPermissions: {
        accounts: PropTypes.bool,
        transferInternal: PropTypes.bool.isRequired,
        transferThirdParties: PropTypes.bool.isRequired,
        transferLocal: PropTypes.bool.isRequired,
        transferForeign: PropTypes.bool.isRequired,
        creditcards: PropTypes.bool.isRequired,
        payCreditCard: PropTypes.bool.isRequired,
        preferentialTradingPrice: PropTypes.bool.isRequired,
        requestCheckbook: PropTypes.bool.isRequired,
        position: PropTypes.bool.isRequired,
        loans: PropTypes.bool.isRequired,
    },
};
PROP.members.activeEnvironment = {
    // TODO: Determine the correct keys for admnistrationSchmes
    administrationScheme: PropTypes.oneOf(["simple", "intermediate", "advanced"]),
    forms: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape(PROP.members.activeEnvironmentForm).isRequired)),
    permissions: PropTypes.shape(PROP.members.activeEnvironmentPermissions).isRequired,
};
PROP.types = {
    /** Provided by Redux, allows dispatching actions on the store */
    dispatch: PropTypes.func.isRequired,
    /** Whether the application is running on a mobile device */
    isMobile: PropTypes.bool,
    /** Obtained from the Store, whether the current user is an Admnistrator */
    isAdministrator: PropTypes.bool,
    /** Obtained from the Store, information about curren'ts user environment */
    activeEnvironment: PropTypes.shape(PROP.members.activeEnvironment),
};
PROP.defaults = {
    isMobile: false,
    isAdministrator: false,
};

/**
 * MenuComponent
 *
 * Renders the navigation menu used in both Mobile and Desktop.
 * @param {PROP.types} props
 */
export function Component(props) {
    const {
        path,
        isMobile,
        activeEnvironment: {
            forms,
            permissions: {
                accounts,
                creditcards,
                creditcardsPurchaseNotification,
                tripNotice,
                payCreditCard,
                preferentialTradingPrice,
                requestCheckbook,
                position,
                loans,
            },
            type,
            isPremierException,
        },
        accountsCC,
        dispatch,
    } = props;

    const showPosition = !isMobile && position;
    const showRequestCheckbook = requestCheckbook || (requestCheckbook && accounts && accountsCC);

    // Convert forms into a valid Menu.Item's array props.
    const items = Object.entries(forms).reduce(
        (acc, [key, arr]) => ({
            ...acc,
            [key]: arr.map((form) => ({ title: form.idForm, href: `/form/${form.idForm}` })),
        }),
        {},
    );

    const creditcardsForms = []
        // Independently of permissions always include links to these forms
        // TODO: Haven't had the opportunity to test this.
        .concat(
            !creditcards &&
                items.creditcards &&
                items.creditcards.filter(
                    (item) => !["menu.additionalCreditCardRequest", "menu.lostOrStolenCreditCard"].includes(item.title),
                ),
        )
        // Otherwise append all link to forms
        .concat(creditcards && items.creditcards)
        // Filter out falsy values
        .filter(Boolean);

    // Store each section into an Object's propery, so the order and visibility can be changed easily.
    // TODO: This should be done programatically, so the backend decided what to show and how to show it.
    const sections = {
        desktop: (
            <Menu.Item
                title="menu.desktop"
                href="/desktop"
                image="menu-home.svg"
                isSelected={path && path === "/desktop"}
            />
        ),
        accounts: (
            <Menu.Section
                title="menu.accounts"
                image="menu-accounts.svg"
                items={items.accounts}
                isSelected={path && path === "/accounts"}>
                {accounts && (
                    <Menu.Item title="menu.accounts" href="/accounts" isSelected={path && path === "/accounts"} />
                )}
                {accounts && preferentialTradingPrice && (
                    <Menu.Item title="menu.preferentialTradingPrice" href="/preferentialTradingPrice" />
                )}
                {showRequestCheckbook && <Menu.Item title="menu.requestCheckbook" href="/form/requestCheckbook" />}
            </Menu.Section>
        ),
        transactions: (
            <Menu.Item
                title="menu.transactions"
                href="/transactions/list"
                image="transactions-step.svg"
                isSelected={path && path === "/transactions/list"}
                onClick={() => {
                    dispatch(SelectorsActionTransactions.resetFilter());
                }}
            />
        ),
        transfers: (
            <Menu.Item
                title="menu.transfers"
                administration-title-icon
                href="/transfers"
                image="menu-transfers.svg"
                isSelected={path && path === "/transfers"}
            />
        ),
        loans: (type === ENVIRONMENT_TYPE.RETAIL || type === ENVIRONMENT_TYPE.PREMIER || isPremierException) && (
            <Menu.Section
                title="menu.loans"
                image="prestamo.svg"
                items={items.loans}
                isSelected={path && path === "/loans"}>
                {loans && <Menu.Item title="menu.loans" href="/loans" isSelected={path && path === "/loans"} />}
                <Menu.Item title="menu.loans.requestLoan" href="/form/requestLoan" />
            </Menu.Section>
        ),
        creditcards: creditcards && (
            <Menu.Section
                title="menu.creditcards"
                image="menu-cards.svg"
                items={creditcardsForms}
                isSelected={
                    path && (path === "/creditCards" || path === "/form/tripNotice" || path === "/purchaseNotification")
                }>
                <Menu.Item title="menu.cards.title" href="/creditCards" isSelected={path && path === "/creditCards"} />
                {tripNotice && (
                    <Menu.Item
                        title="menu.creditcards.tripNotice"
                        href="/form/tripNotice"
                        isSelected={path && path === "/form/tripNotice"}
                    />
                )}
                {creditcardsPurchaseNotification && (
                    <Menu.Item
                        title="menu.creditcards.purchaseNotificacion"
                        href="/purchaseNotification"
                        isSelected={path && path === "/purchaseNotification"}
                    />
                )}
                <Menu.Item
                    title="menu.creditcards.requestCreditCard"
                    href="/form/requestCreditCard"
                    isSelected={path && path === "/form/requestCreditCard"}
                />
            </Menu.Section>
        ),
        payments: accounts && (
            <Menu.Section
                title="menu.payments"
                image="menu-payments.svg"
                items={items.payments}
                isSelected={path && path.search("Payment") !== -1}>
                {payCreditCard && (
                    <Menu.Item
                        title="menu.payments.creditCard"
                        href="/creditCardsPayment/list"
                        isSelected={path && path === "/creditCardsPayment/list"}
                    />
                )}
            </Menu.Section>
        ),
        position: showPosition && (
            <Menu.Item
                title="menu.position"
                href="/position"
                image="menu-position.svg"
                isSelected={path && path === "/position"}
            />
        ),
        settings: isMobile && (
            <Menu.Item
                title="menu.settings"
                href="/settings"
                image="menu-settings.svg"
                isSelected={path && path === "/settings"}
            />
        ),
        atms: isMobile && (
            <Menu.Item
                title="menu.atms"
                href="/externalMapLocations"
                image="sucursales-cajeros-menu.svg"
                isSelected={path && path === "/externalMapLocations"} // TODO:UYHSBCCDP-3312  este cambio es provisorio hasta que se integre Web , ahi se unifican ambos componentes
            />
        ),
    };

    let itemsAux = null;

    if (RELEASE_MOD === TRIMMED) {
        itemsAux = TRIMMED_MENU_ITEMS;

        return (
            <Nav className="menu-list">
                {itemsAux.map((obj) => {
                    if (!obj.value) {
                        return <React.Fragment key={obj.key}>{sections[obj.key]}</React.Fragment>;
                    }
                    return null;
                })}
            </Nav>
        );
    }
    itemsAux = [
        "desktop",
        "transactions",
        "accounts",
        "transfers",
        "preferentialTradingPrice",
        "checkbooks",
        "loans",
        "creditcards",
        "position",
        "admin",
        "settings",
        "atms",
    ];

    return (
        <Nav className="menu-list">
            {itemsAux.map((key) => (
                <React.Fragment key={key}>{sections[key]}</React.Fragment>
            ))}
        </Nav>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Connect((store) => ({
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
    isAdministrator: SelectorsStoreSession.isAdministrator(store),
    accountsCC: SelectorsStoreWidget.getWidgetAccountsCC(store),
}))(Component);
