import React from "react";

import { Field } from "formik";
import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";

import I18n from "~/components/I18n";

import Style from "./RadioButton.rules.scss";

export const NAME = "RadioButton";

export const PROP = {
    types: {
        value: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        hideLabel: PropTypes.bool,
        keyprefix: PropTypes.string,
    },
    defaults: {
        hideLabel: false,
        keyprefix: EMPTY_STR,
    },
};
export function Component({ value, name, hideLabel, keyprefix }) {
    return (
        <div id={Style.ID}>
            <label>
                <Field type="radio" name={name} value={value} />
                {!hideLabel && (
                    <div className="form-group-text">
                        <I18n id={`${keyprefix}.label`} />
                    </div>
                )}
            </label>
        </div>
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;
export default Component;
