import {
    call as Call,
    put as Put,
    select as Select,
    takeEvery as TakeEvery,
    takeLatest as TakeLatest,
} from "redux-saga/effects";

import { FORM_FIELD_ERRORS, LEVEL, RESPONSE_TYPE, SIGN_WITH_BIOMETRIC, SCOPE, VALIDATION_ERROR } from "~/constants";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import IsTrustedBiometric from "~/store/form/_sagas/_isTrustedBiometric";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import {
    credentialsWithUnderscore as CredentialsWithUnderscore,
    hasIncorrectCredentials as HasIncorrectCredentials,
    adjustIdFieldErrors as AdjustIdFieldErrors,
} from "~/util/form";
import * as UtilI18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction } from "./_selectors";

export default [
    TakeLatest(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, cancelTransactionCustom),
    TakeLatest(TYPE.COMEX_DESKTOP_PRE_REQUEST, comexDesktopPreRequest),
    TakeLatest(TYPE.GET_AMOUNT_TEXT_REQUEST, getAmountText),
    TakeLatest(TYPE.LOAD_BANK_LIST_REQUEST, loadBankListRequest),
    TakeLatest(TYPE.READ_TRANSACTION_REQUEST, readTransactionRequest),
    TakeLatest(TYPE.SIGN_TRANSACTION_REQUEST, signTransaction),
    TakeEvery(TYPE.SEARCH_BANK_REQUEST, searchBankRequest),

    TakeLatest(TYPE.OTHER_PROCEDURES_PRE_REQUEST, otherProcedurePre),
    TakeLatest(TYPE.OTHER_PROCEDURES_PREVIEW_REQUEST, otherProcedurePreview),
    TakeLatest(TYPE.OTHER_PROCEDURES_SEND_REQUEST, otherProcedure),
];

function* cancelTransactionCustom(props) {
    const { credentials, idTransaction, formikBag } = props;
    yield* handleCancelTransaction(credentials, idTransaction, formikBag);

    formikBag.setSubmitting(false);
}

function* comexDesktopPreRequest() {
    const { type, data } = yield Call(SelectorsMiddleware.comexDesktopPreRequest);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.comexDesktopPreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX],
            }),
        );
    } else {
        yield Put(SelectorsAction.comexDesktopPreSuccess({ ...data.data }));
    }
}

function* getAmountText(props) {
    const { type, data } = yield Call(SelectorsMiddleware.getAmountText, props);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.getAmountTextFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX],
            }),
        );
    } else {
        yield Put(SelectorsAction.getAmountTextSuccess({ ...data.data }));
    }
}

function* handleCancelTransaction(credentials, idTransaction, formikBag) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);
    const {
        data: { data },
        type,
    } = yield Call(SelectorsMiddlewareForm.cancelTransaction, {
        idTransactionToCancel: idTransaction,
        ...credentialsWithUnderscore,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data);
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get("forms.cancelTransaction.errorMessage"),
                    level: LEVEL.ERROR,
                    scopes: SCOPE.INVESTMENT_OTHER_INVESTMENTS,
                }),
            );
        }
    } else {
        yield* readTransactionRequest({ idTransaction });
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("forms.cancelTransaction.confirmationMessage"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.TRANSACTIONS],
            }),
        );
    }
}

function* loadBankListRequest(props) {
    const { filters, scope, setErrors, setSubmitting } = props;
    const response = yield Call(SelectorsMiddleware.loadBankListRequest, filters);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [scope],
            }),
        );

        setErrors(AdjustIdFieldErrors(response.data.data));
        setSubmitting(false);

        yield Put(SelectorsAction.loadBankListFailure());
    } else if (!response.data.data.banks.length) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("forms.bankselector.noRecordsFound"),
                level: LEVEL.ERROR,
                scopes: [scope],
            }),
        );

        setSubmitting(false);

        yield Put(SelectorsAction.loadBankListFailure());
    } else {
        const { data } = response.data;

        yield Put(SelectorsAction.loadBankListSuccess(data));
    }
}

function* readTransactionRequest(props) {
    const { idTransaction } = props;
    const { type, data } = yield Call(SelectorsMiddlewareForm.readTransaction, {
        idTransactionToRead: idTransaction,
    });
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.INVESTMENT_OTHER_INVESTMENTS],
            }),
        );
        yield Put(SelectorsAction.readTransactionFailure());
    } else {
        yield Put(SelectorsAction.readTransactionSuccess({ transaction: data.data.transaction }));
    }
}

function* searchBankRequest(props) {
    const { filters, setErrors, idField, fromTemplate } = props;
    const { code } = filters;
    if (code) {
        const {
            type: typeResp,
            data: {
                data: { code: bankCode, banks },
            },
        } = yield Call(SelectorsMiddleware.loadBankListRequest, filters);
        if (typeResp === RESPONSE_TYPE.WARNING) {
            if (!fromTemplate) {
                if (bankCode) {
                    setErrors({ [idField]: bankCode });
                } else {
                    yield Put(
                        SelectorsActionNotification.showNotification({
                            message: UtilI18n.get("global.unexpectedError"),
                            level: LEVEL.ERROR,
                            scopes: [SCOPE.COMEX_IMPORT_CREDIT_LETTER_OPEN],
                        }),
                    );
                }
            }

            yield Put(SelectorsAction.searchBankFailure({ idField }));
            // If the form is being open from a template we dont want to show errors
            return undefined;
        }
        if (!banks.length || banks[0].code !== code) {
            if (!fromTemplate) {
                setErrors({ [idField]: UtilI18n.get("forms.bankselector.noRecordsFound") });
            }
            yield Put(SelectorsAction.searchBankFailure({ idField }));
        } else {
            const bank = banks[0];
            yield Put(SelectorsAction.searchBankSuccess({ bank }));
        }
    } else {
        yield Put(SelectorsAction.searchBankFailure({ idField }));
    }
    return undefined;
}

function* signTransaction(props) {
    const { idForm, idActivity, idTransaction, credentials, formikBag } = props;
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
    }

    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { data, type } = yield Call(
        SelectorsMiddlewareForm.sign,
        { idForm, idTransaction, ...credentialsWithUnderscore },
        idActivity,
    );

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(AdjustIdFieldErrors(data.data));
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.message,
                    level: LEVEL.ERROR,
                    scopes: [
                        SCOPE.COMEX_BILLING_CLAIM, 
                        SCOPE.COMEX_BONDS_AND_GUARANTEES, 
                        SCOPE.COMEX_BONDS_AND_GUARANTEES_MODIFY, 
                        SCOPE.COMEX,
                        SCOPE.COMEX_DISCREPANCY_DOCUMENT_AUTHORIZATION, 
                        SCOPE.COMEX_EXPORT_CESSION_CREDIT_LETTER, 
                        SCOPE.COMEX_EXPORT_CREDIT_LETTER_TRANSFER, 
                        SCOPE.COMEX_EXPORT_PRESENT_BILLING_DOCUMENTS, 
                        SCOPE.COMEX_IMPORT_CANCELLATION_CREDIT_LETTER, 
                        SCOPE.COMEX_IMPORT_CREDIT_LETTER_MODIFY, 
                        SCOPE.COMEX_IMPORT_CREDIT_LETTER_OPEN, 
                        SCOPE.COMEX_IMPORT_DOCUMENT_PRESENTATION, 
                        SCOPE.COMEX_IMPORT_LIFT_CREDIT_LETTER_DISCREPANCIES, 
                        SCOPE.COMEX_IMPORT_REQUEST_ENDORSEMENT, 
                        SCOPE.COMEX_OTHERS
                    ],
                }),
            );
        }
        yield Put(SelectorsAction.signTransactionFailure());
        formikBag.setSubmitting(false);
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(
            SelectorsAction.signTransactionSuccess({
                transaction,
            }),
        );
        formikBag.setSubmitting(false);
    }
}

function* otherProcedurePre() {
    const { type, data } = yield Call(SelectorsMiddleware.otherProcedurePreRequest);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.otherProcedurePreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_OTHERS],
            }),
        );
    } else {
        yield Put(SelectorsAction.otherProcedurePreSuccess({ ...data.data }));
    }
}

function* otherProcedurePreview({ formData, formikBag }) {
    const { type, data } = yield Call(SelectorsMiddleware.otherProcedurePreviewRequest, formData);
    formikBag.setSubmitting(false);
    const errorMessage =
        data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.otherProcedurePreviewFailure());
        formikBag.setErrors(data.data);
        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_OTHERS],
            }),
        );
    } else {
        yield Put(SelectorsAction.otherProcedurePreviewSuccess({ ...data.data }));
    }
}

function* otherProcedure({ formData, credentials, formikBag }) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { type, data } = yield Call(SelectorsMiddleware.otherProcedureSendRequest, {
        ...formData,
        ...credentialsWithUnderscore,
    });
    const { idTransaction } = data;
    formikBag.setSubmitting(false);

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
            yield Put(SelectorsAction.otherProcedureSendFailure());
        } else {
            yield Put(SelectorsAction.comexFormSendDataFailure());
            const errorMessage = data.data.NO_FIELD || data.message;
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.COMEX_OTHERS],
                }),
            );
        }
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(SelectorsAction.otherProcedureSendSuccess({ transaction }));
    }
}
