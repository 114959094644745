import React from "react";

import { EMPTY_STR } from "~/constants";
import { get as getMessage } from "~/util/i18n";

import Image from "~/components/Image";

import Style from "./Unavailable.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const { label } = props;
    const message = label ? getMessage(label) || label : EMPTY_STR;

    return (
        <div className={Style.CLASS}>
            <Image src="no-mobile.svg" />
            <p>{message}</p>
        </div>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
