import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import { SelectorsAction as SelectorsActionComex, SelectorsStore as SelectorsStoreComex } from "~/store/comex";
import {
    SelectorsAction as SelectorsActionComexExport,
    SelectorsStore as SelectorsStoreComexExport,
} from "~/store/comex/export";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import HighOrder from "~/components/HighOrder";

import FormEdit from "./Step1Edit/Step1Edit";
import FormPreview from "./Step2Preview/Step2Preview";
import FormView from "./Step3View/Step3View";
import Style from "./TransferCreditLetter.rules.scss";

const FORM_ID = "comex.export.transferCreditLetter";

export const { NAME } = Style;

export const PROP = {
    types: {
        fetching: PropTypes.bool.isRequired,
        setFieldValue: PropTypes.func.isRequired,
        validateForm: PropTypes.func.isRequired,
        ...TypesRedux,
    },
    defaults: {
        ...DefaultsRedux,
    },
};
export function Component(props) {
    const { banks, dispatch, fetching, mode } = props;

    const handleBack = () => {
        const { history, showBankSearch } = props;

        if (mode === MODE.VIEW) {
            dispatch(SelectorsActionComex.restartFlow());
            dispatch(SelectorsActionComexExport.restartFlow());
        } else if (mode === MODE.PREVIEW) {
            dispatch(SelectorsActionComex.toStepOne());
        } else if (showBankSearch) {
            if (banks.length > 0) {
                dispatch(SelectorsActionComex.setBankSearchInfo({ banks: [] }));
            } else {
                dispatch(SelectorsActionComex.setBankSearchInfo({ showBankSearch: false }));
            }
        } else {
            history.goBack();
        }
    };

    return (
        <Container
            className={banks && banks.length > 0 ? "result-bank" : EMPTY_STR}
            head-onBack={handleBack}
            head-title={`${FORM_ID}.title`}
            image="businessPortfolio.svg"
            name={NAME}
            scopeToShowNotification={SCOPE.COMEX_EXPORT_CREDIT_LETTER_TRANSFER}
            step={mode}
            wait={fetching}>
            {mode === MODE.EDIT && <FormEdit />}
            {mode === MODE.PREVIEW && <FormPreview />}
            {mode === MODE.VIEW && <FormView ticketConfirmation />}
        </Container>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        banks: SelectorsStoreComex.getBanks(store),
        fetching: SelectorsStoreComexExport.getFetching(store),
        mode: SelectorsStoreComexExport.getMode(store),
        showBankSearch: SelectorsStoreComex.getShowBankSearch(store),
    };
};

export default HighOrder(Connect(mapStateToProps))(Component);
