import React from "react";

import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

import { SPACE_STR } from "~/constants";
import { generateId as Id } from "~/util/general";

import I18n from "~/components/I18n";
import FormattedDate from "~/pages/_components/FormattedDate";

export const NAME = "FormSignatures";

export const PROP = {
    types: {
        transaction: PropTypes.shape({
            creationDateTime: PropTypes.string,
            modificationDateTime: PropTypes.string,
        }).isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const { transaction } = props;
    const { signatures } = transaction;
    const isModifiedTicket = transaction.creationDateTime !== transaction.modificationDateTime;

    const dateTimeFormat = "DD/MM/YYYY HH:mm";
    return (
        <Col sm={12} md={9} lg={6} xl={6} className="col col-12 data-section signatures-container">
            <h3 className="data-title">
                <I18n id="forms.transaction.ticket.title" />
            </h3>
            {isModifiedTicket && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.transaction.ticket.modifiedBy" />
                    </span>
                    <span className="data-name">
                        {transaction.userEditorLastName}, {transaction.userEditorFirstName} - {SPACE_STR}
                        <FormattedDate date={transaction.modificationDateTime} customFormat={dateTimeFormat} />
                    </span>
                </div>
            )}
            {signatures && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.transaction.ticket.participants" />
                    </span>
                    {signatures.map((sig) => (
                        <span className="data-name" key={Id()}>
                            {sig.userFullName} - {SPACE_STR}
                            <FormattedDate date={sig.creationDateTime} customFormat={dateTimeFormat} /> <br />
                        </span>
                    ))}
                </div>
            )}
        </Col>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
