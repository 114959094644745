import React from "react";

import { replace as Replace } from "connected-react-router";
import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import * as Yup from "yup";

import {
    COMMA,
    DEFAULT_CITY,
    DEFAULT_COUNTRY,
    DEFAULT_MARITAL_STATUS,
    EMPTY_STR,
    IS_COUNTRY_ACTIVE,
    NO_SPOUSE,
    SCOPE,
    ZERO,
    ZERO_NUMBER,
} from "~/constants";
import Container from "~/containers/Internal/Form";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsStore as SelectorStoreCP, SelectorsAction as SelectorsActionCP } from "~/store/connectedParties";
import { SelectorsStore as SelectorsStoreSession, SelectorsAction as ActionSession } from "~/store/session";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";

import Style from "./ConnectedParties.rules.scss";
import ConnectedPartiesForm from "./ConnectedPartiesForm";

const FORM_ID = "connectedParties";

export const NAME = "ConnectedPartiesForm";

export const PROP = {
    types: {
        ...TypesRedux,
        dispatch: PropTypes.func.isRequired,
        fetching: PropTypes.bool,
        isSubmitting: PropTypes.bool.isRequired,
        securitySeal: PropTypes.shape({
            id: PropTypes.string,
            image: PropTypes.string,
        }).isRequired,
        setFieldValue: PropTypes.func.isRequired,
        values: PropTypes.object.isRequired,
    },
    defaults: {
        ...DefaultsRedux,
        fetching: false,
    },
};
export function Component({
    countryList,
    dispatch,
    fetching,
    fetchingForm,
    infoUser,
    isMobile,
    isSubmitting,
    securitySeal,
    setFieldValue,
    values,
    ...rest
}) {
    React.useLayoutEffect(() => {
        dispatch(
            SelectorsActionCP.registerBantotalPre({
                idCountry: DEFAULT_COUNTRY,
                idCity: DEFAULT_CITY,
            }),
        );
        return () => {};
    }, [dispatch]);

    const handleBack = React.useCallback(() => {
        if (isMobile) {
            dispatch(ActionSession.logout());
        } else {
            dispatch(Replace("/desktop"));
        }
    }, [dispatch, isMobile]);

    return (
        <Container
            name={NAME}
            head-onBack={handleBack}
            head-title="connectedParties.title"
            wait={fetchingForm || fetching}
            scopeToShowNotification={SCOPE.PERSONAL_INFORMATION}>
            <div id={Style.ID}>
                <Form>
                    <ConnectedPartiesForm
                        infoUser={infoUser}
                        values={values}
                        setFieldValue={setFieldValue}
                        isCorporate={false}
                        {...rest}
                    />

                    <div className="form--personal_information form--personal_information--bottom personal_info-cmb">
                        <div />
                        <div className="buttons-container">
                            <Button type="submit" size="md" variant="primary" disabled={isSubmitting}>
                                <I18n id="connectedParties.updateData" />
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        </Container>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default HighOrder(
    Connect((store) => ({
        cityList: SelectorStoreCP.getCityList(store),
        cmbOnly: SelectorStoreCP.getCMBOnly(store),
        countryList: SelectorStoreCP.getCountryList(store),
        departmentsOfBirthList: SelectorStoreCP.getDepartmentsOfBirthList(store),
        documents: SelectorStoreCP.getDocuments(store),
        fetching: SelectorStoreCP.getFetching(store),
        fetchingForm: SelectorStoreCP.getFetchingForm(store),
        idUser: SelectorsStoreSession.getUserId(store),
        infoUser: SelectorStoreCP.getInfoUser(store),
        minIncome: SelectorsStoreConfig.getConfig(store)["client.userconfiguration.professions.minIncome"],
        productGroupIds: SelectorStoreCP.getProductGroupIds(store),
        professions: SelectorStoreCP.getProfessions(store),
        townList: SelectorStoreCP.getTownList(store),
    })),
    HighOrder.Resizable,
    WithFormik({
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        mapPropsToValues: ({ infoUser, productGroupIds, cmbOnly }) => {
            let providedFoundFields = {};

            productGroupIds.forEach((_, index) => {
                providedFoundFields = { ...providedFoundFields, ["provideFoundTo_".concat(index)]: false };
            });

            return {
                activityCode: EMPTY_STR,
                americanCitizen: false,
                annualIncome: ZERO,
                antiquity: EMPTY_STR,
                apto: EMPTY_STR,
                birthdate: EMPTY_STR,
                city: 1,
                companyAddress: EMPTY_STR,
                companyName: EMPTY_STR,
                companyPhone: EMPTY_STR,
                country: DEFAULT_COUNTRY,
                countryOfCitizenship2: ZERO,
                countryOfCitizenship3: ZERO,
                countryOfCitizenship: DEFAULT_COUNTRY,
                countryOfFiscalResidence2: ZERO,
                countryOfFiscalResidence3: ZERO,
                countryOfFiscalResidence: DEFAULT_COUNTRY,
                countryofbirth: DEFAULT_COUNTRY,
                departament: 1,
                departamentOfBirth: 1,
                doorNumber: EMPTY_STR,
                email: infoUser?.email || EMPTY_STR,
                employerName: EMPTY_STR,
                employmentSituation: cmbOnly ? 2 : EMPTY_STR,
                files: [],
                firstName: infoUser?.firstName,
                fiscalId2: ZERO,
                fiscalId3: ZERO,
                fiscalId: infoUser?.document,
                gender: EMPTY_STR,
                lastName: infoUser?.lastName,
                maritalStatus: DEFAULT_MARITAL_STATUS,
                middleName: EMPTY_STR,
                mobilePhone: infoUser?.mobilePhone || { country: "URY", prefix: "+598", value: EMPTY_STR },
                otherCity: EMPTY_STR,
                otherDepartament: EMPTY_STR,
                otherDepartamentOfBirth: EMPTY_STR,
                otherProfession: EMPTY_STR,
                phone: EMPTY_STR,
                profession: cmbOnly ? 16 : EMPTY_STR,
                secondLastName: EMPTY_STR,
                spouseDocument: {
                    country: EMPTY_STR,
                    document: EMPTY_STR,
                    type: EMPTY_STR,
                },
                spouseLastname: EMPTY_STR,
                spouseName: EMPTY_STR,
                street: EMPTY_STR,
                verificationMobilePhone: { country: "URY", prefix: "+598", value: EMPTY_STR },
                workRole: EMPTY_STR,
                zipCode: EMPTY_STR,
                ...providedFoundFields,
            };
        },
        validationSchema: ({ cmbOnly }) => {
            return Yup.lazy((values) => {
                return Yup.object().shape({
                    activityCode: cmbOnly
                        ? Yup.string().notRequired()
                        : Yup.string()
                              .trim()
                              .required(UtilsI18n.get(`${FORM_ID}.validations.emptyActivityCode`)),
                    annualIncome: values.providesAnyFounds
                        ? Yup.string()
                              .test(
                                  "annualIncome",
                                  UtilsI18n.get(`${FORM_ID}.validations.emptyAnnualIncome`),
                                  (val) => Number(val) !== ZERO_NUMBER,
                              )
                              .required(UtilsI18n.get(`${FORM_ID}.validations.emptyAnnualIncome`))
                        : Yup.string().notRequired(),
                    birthdate: Yup.string()
                        .trim()
                        .required(UtilsI18n.get(`${FORM_ID}.validations.emptyBirthDate`)),
                    city:
                        IS_COUNTRY_ACTIVE.includes(values?.country) && values?.city !== ZERO_NUMBER
                            ? Yup.string()
                                  .trim()
                                  .required(UtilsI18n.get(`${FORM_ID}.validations.emptyTown`))
                            : Yup.string().notRequired(),
                    companyAddress: cmbOnly
                        ? Yup.string().notRequired()
                        : Yup.string()
                              .trim()
                              .required(UtilsI18n.get(`${FORM_ID}.validations.emptyCompanyAddress`)),
                    companyName: cmbOnly
                        ? Yup.string().notRequired()
                        : Yup.string()
                              .trim()
                              .required(UtilsI18n.get(`${FORM_ID}.validations.emptyCompanyName`)),
                    companyPhone: cmbOnly
                        ? Yup.string().notRequired()
                        : Yup.string()
                              .trim()
                              .required(UtilsI18n.get(`${FORM_ID}.validations.companyPhoneEmpty`)),
                    country: Yup.string()
                        .trim()
                        .required(UtilsI18n.get(`${FORM_ID}.validations.emptyCountry`)),
                    countryOfCitizenship2: !values.countryOfCitizenship2
                        ? Yup.string()
                              .trim()
                              .required(UtilsI18n.get(`${FORM_ID}.validations.emptyCountryOfCitizenship`))
                        : Yup.string().notRequired(),
                    countryOfCitizenship3: !values.countryOfCitizenship3
                        ? Yup.string()
                              .trim()
                              .required(UtilsI18n.get(`${FORM_ID}.validations.emptyCountryOfCitizenship`))
                        : Yup.string().notRequired(),
                    countryOfFiscalResidence2: !values.countryOfFiscalResidence2
                        ? Yup.string()
                              .trim()
                              .required(UtilsI18n.get(`${FORM_ID}.validations.empty.countryOfFiscalResidence`))
                        : Yup.string().notRequired(),
                    countryOfFiscalResidence3: !values.countryOfFiscalResidence3
                        ? Yup.string()
                              .trim()
                              .required(UtilsI18n.get(`${FORM_ID}.validations.empty.countryOfFiscalResidence`))
                        : Yup.string().notRequired(),
                    departament:
                        IS_COUNTRY_ACTIVE.includes(values?.country) && values?.departament !== ZERO_NUMBER
                            ? Yup.string()
                                  .trim()
                                  .required(UtilsI18n.get(`${FORM_ID}.validations.emptyCity`))
                            : Yup.string().notRequired(),
                    departamentOfBirth:
                        IS_COUNTRY_ACTIVE.includes(values?.countryofbirth) && values?.departamentOfBirth !== ZERO_NUMBER
                            ? Yup.string()
                                  .trim()
                                  .required(UtilsI18n.get(`${FORM_ID}.validations.emptyCityOfBirth`))
                            : Yup.string().notRequired(),
                    doorNumber: Yup.string()
                        .trim()
                        .required(UtilsI18n.get(`${FORM_ID}.validations.emptyNumber`)),
                    email: Yup.string()
                        .trim()
                        .email(UtilsI18n.get("global.userconfiguration.emailIncorrectFormat"))
                        .required(UtilsI18n.get(`${FORM_ID}.validations.emptyEmail`)),
                    employerName: cmbOnly
                        ? Yup.string().notRequired()
                        : Yup.string()
                              .trim()
                              .required(UtilsI18n.get(`${FORM_ID}.validations.emptyEmployerName`)),
                    employmentSituation: cmbOnly
                        ? Yup.string().notRequired()
                        : Yup.string()
                              .trim()
                              .required(UtilsI18n.get(`${FORM_ID}.validations.emptyEmploymentSituation`)),
                    files: Yup.array().required(UtilsI18n.get(`${FORM_ID}.validations.emptyFiles`)),
                    firstName: Yup.string()
                        .trim()
                        .required(UtilsI18n.get(`${FORM_ID}.validations.emptyFirstName`)),
                    fiscalId: Yup.string()
                        .trim()
                        .required(UtilsI18n.get(`${FORM_ID}.validations.emptyFiscalId`)),
                    fiscalId2: !values.fiscalId2
                        ? Yup.string()
                              .trim()
                              .required(UtilsI18n.get(`${FORM_ID}.validations.emptyFiscalId`))
                        : Yup.string().notRequired(),
                    fiscalId3: !values.fiscalId3
                        ? Yup.string()
                              .trim()
                              .required(UtilsI18n.get(`${FORM_ID}.validations.emptyFiscalId`))
                        : Yup.string().notRequired(),
                    gender: Yup.string()
                        .trim()
                        .required(UtilsI18n.get(`${FORM_ID}.validations.emptyGender`)),
                    lastName: Yup.string()
                        .trim()
                        .required(UtilsI18n.get(`${FORM_ID}.validations.emptyLastName`)),
                    maritalStatus: Yup.string()
                        .trim()
                        .required(UtilsI18n.get(`${FORM_ID}.validations.emptyMaritalStatus`)),
                    mobilePhone:
                        typeof values.mobilePhone === "string"
                            ? Yup.string().notRequired()
                            : Yup.object()
                                  .shape({
                                      prefix: Yup.string().required(
                                          UtilsI18n.get(`${FORM_ID}.validations.mobilePhone.mustBeAPhoneNumber`),
                                      ),
                                      value: Yup.string().required(
                                          UtilsI18n.get(`${FORM_ID}.validations.mobilePhone.mustBeAPhoneNumber`),
                                      ),
                                      country: Yup.string(),
                                  })
                                  .test(
                                      "mobilePhonesDontMatch",
                                      UtilsI18n.get(`${FORM_ID}.validations.mobilePhone.mobilePhonesDontMatch`),
                                      () =>
                                          values.mobilePhone &&
                                          values.verificationMobilePhone &&
                                          values.mobilePhone.prefix + values.mobilePhone.value ===
                                              values.verificationMobilePhone.prefix +
                                                  values.verificationMobilePhone.value,
                                  ),
                    otherCity:
                        !IS_COUNTRY_ACTIVE.includes(values.country) || values.city === ZERO_NUMBER
                            ? Yup.string()
                                  .trim()
                                  .required(UtilsI18n.get(`${FORM_ID}.validations.emptyOtherTown`))
                            : Yup.string().notRequired(),
                    otherDepartament:
                        !IS_COUNTRY_ACTIVE.includes(values.country) || values?.departament === ZERO_NUMBER
                            ? Yup.string()
                                  .trim()
                                  .required(UtilsI18n.get(`${FORM_ID}.validations.emptyOtherCity`))
                            : Yup.string().notRequired(),
                    otherDepartamentOfBirth:
                        !IS_COUNTRY_ACTIVE.includes(values.countryofbirth) || values?.departamentOfBirth === ZERO_NUMBER
                            ? Yup.string()
                                  .trim()
                                  .required(UtilsI18n.get(`${FORM_ID}.validations.emptyOtherCityOfBirth`))
                            : Yup.string().notRequired(),
                    otherProfession:
                        values.profession === ZERO_NUMBER
                            ? Yup.string()
                                  .trim()
                                  .required(UtilsI18n.get(`${FORM_ID}.validations.emptyProfession`))
                            : Yup.string().notRequired(),
                    phone: Yup.string()
                        .trim()
                        .required(UtilsI18n.get(`${FORM_ID}.validations.emptyPhone`)),
                    profession: Yup.string()
                        .trim()
                        .required(UtilsI18n.get(`${FORM_ID}.validations.emptyProfession`)),
                    spouseDocument: !NO_SPOUSE.includes(values.maritalStatus)
                        ? Yup.object().shape({
                              country: Yup.string().required(
                                  UtilsI18n.get(`${FORM_ID}.validations.emptyPartnerDocCountry`),
                              ),
                              document: Yup.string().required(
                                  UtilsI18n.get(`${FORM_ID}.validations.emptyPartnerDocNumber`),
                              ),
                              type: Yup.string().required(UtilsI18n.get(`${FORM_ID}.validations.emptyPartnerDocType`)),
                          })
                        : Yup.object().notRequired(),
                    spouseLastname: !NO_SPOUSE.includes(values.maritalStatus)
                        ? Yup.string()
                              .trim()
                              .required(UtilsI18n.get(`${FORM_ID}.validations.emptyPartnerLastName`))
                        : Yup.string().notRequired(),
                    spouseName: !NO_SPOUSE.includes(values.maritalStatus)
                        ? Yup.string()
                              .trim()
                              .required(UtilsI18n.get(`${FORM_ID}.validations.emptyPartnerFirstName`))
                        : Yup.string().notRequired(),
                    street: Yup.string()
                        .trim()
                        .required(UtilsI18n.get(`${FORM_ID}.validations.emptyStreet`)),
                    verificationMobilePhone:
                        typeof values.mobilePhone === "string"
                            ? Yup.object().notRequired()
                            : Yup.object()
                                  .shape({
                                      prefix: Yup.string().required(
                                          UtilsI18n.get(`${FORM_ID}.validations.mobilePhone.mustBeAPhoneNumber`),
                                      ),
                                      value: Yup.string().required(
                                          UtilsI18n.get(`${FORM_ID}.validations.mobilePhone.mustBeAPhoneNumber`),
                                      ),
                                      country: Yup.string(),
                                  })
                                  .test(
                                      "mobilePhonesDontMatch",
                                      UtilsI18n.get(`${FORM_ID}.validations.mobilePhone.mobilePhonesDontMatch`),
                                      () =>
                                          values.mobilePhone &&
                                          values.verificationMobilePhone &&
                                          values.mobilePhone.prefix + values.mobilePhone.value ===
                                              values.verificationMobilePhone.prefix +
                                                  values.verificationMobilePhone.value,
                                  ),
                    workRole: cmbOnly
                        ? Yup.string().notRequired()
                        : Yup.string()
                              .trim()
                              .required(UtilsI18n.get(`${FORM_ID}.validations.emptyWorkRole`)),
                });
            });
        },
        handleSubmit: (data, formikBag) => {
            const { productGroupIds, dispatch } = formikBag.props;

            formikBag.setSubmitting(false);

            const {
                antiquity,
                city,
                countryOfCitizenship2,
                countryOfCitizenship3,
                countryOfFiscalResidence2,
                countryOfFiscalResidence3,
                departament,
                departamentOfBirth,
                maritalStatus,
                profession,
                provideFoundTo,
                spouseDocument,
            } = data;

            const accountsProvidesFunds = [];
            const accountsNotProvidesFunds = [];

            // eslint-disable-next-line no-restricted-syntax
            for (const i in productGroupIds) {
                if (provideFoundTo && provideFoundTo[i]) {
                    accountsProvidesFunds.push(productGroupIds[i]);
                } else {
                    accountsNotProvidesFunds.push(productGroupIds[i]);
                }
            }

            const maritalStatusFlags = !NO_SPOUSE.includes(maritalStatus);
            const dataParsed = {
                ...data,
                accountsNotProvidesFunds: accountsNotProvidesFunds.join(COMMA),
                accountsProvidesFunds: accountsProvidesFunds.join(COMMA),
                antiquity: antiquity || ZERO_NUMBER,
                birthDateAsString: data.birthdate.toLocaleDateString(),
                city: city || ZERO_NUMBER,
                countryOfCitizenship2: countryOfCitizenship2 || ZERO,
                countryOfCitizenship3: countryOfCitizenship3 || ZERO,
                countryOfFiscalResidence2: countryOfFiscalResidence2 || ZERO_NUMBER,
                countryOfFiscalResidence3: countryOfFiscalResidence3 || ZERO_NUMBER,
                departament: departament || ZERO_NUMBER,
                departamentOfBirth: departamentOfBirth || ZERO_NUMBER,
                files: data.files.join(COMMA),
                profession: profession || ZERO_NUMBER,
                spouseDocCountry: maritalStatusFlags ? spouseDocument?.country : ZERO,
                spouseDocNumber: maritalStatusFlags ? spouseDocument?.document : EMPTY_STR,
                spouseDocType: maritalStatusFlags ? spouseDocument?.type : ZERO,
            };

            dispatch(SelectorsActionCP.submitFormConnectedParties({ data: dataParsed, formikBag }));
        },
    }),
)(React.memo(Component));
