import React from "react";

import classNames from "classnames";
import { Form, Field, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import {
    ALL,
    COMMA_PLUS_SPACE,
    CREDIT_CARD_OWNER,
    EMPTY_STR,
    FORMAT_SHORT,
    ONLY_NUMBER,
    UNDERSCORE,
    URUGUAY_PHONE_PREFIX,
    ZERO,
} from "~/constants";
import { MODE } from "~/constants/form";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import {
    PROP as PropCreditCards,
    SelectorsAction as SelectorsActionCreditCards,
    SelectorsStore as SelectorsStoreCreditCards,
} from "~/store/creditCards/creditCard";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import * as UtilsConfig from "~/util/config";
import * as UtilsI18n from "~/util/i18n";
import { GetMobileCountryOptions, MOBILE_COUNTRY_FILTER, ValidateInternationalPhoneNumber } from "~/util/phone";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TextField from "~/pages/_components/fields/TextField";
import CountryCellPhoneField from "~/pages/_components/fields/formik/CountryCellPhoneField";
import Selector from "~/pages/_components/fields/formik/Selector";

import StyleField from "~/pages/settings/changePersonalInformation/field/MobilePhoneField.rules.scss";

import Style from "./Step1Edit.rules.scss";

const FORM_ID = "creditCards.purchaseNotification";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...PropCreditCards.types,
        isMobile: PropTypes.bool,
    },
    defaults: {
        ...PropCreditCards.defaults,
        isMobile: false,
    },
};

export const NOTIFICATION_OPTIONS = {
    EMAIL: "EMAIL",
    SMS: "SMS",
    INACTIVE: "DESAC",
};

const MOBILE_PHONE_OPTION_FILTER = MOBILE_COUNTRY_FILTER.URUGUAY_ONLY;

function getCleanPhoneNumber(number, prefix) {
    if (URUGUAY_PHONE_PREFIX === prefix && number.length >= 9 && number.charAt(0) === ZERO) {
        return number.substring(1);
    }

    return number;
}

export class Component extends React.Component {
    state = {
        allVisaMasterAccounts: [],
        selectedAccount: {},
    };

    static getDerivedStateFromProps(prevProps, prevState) {
        const { preData, purchaseNotificationData } = prevProps;
        const { allVisaMasterAccounts } = prevState || {};

        if (!allVisaMasterAccounts?.length && preData) {
            let selectedAccount;

            if (purchaseNotificationData) {
                selectedAccount = preData.find(
                    (visaMasterAccount) =>
                        visaMasterAccount.visaMasterAccount === purchaseNotificationData.selectedCardAccount,
                );
            }

            return {
                ...prevState,
                allVisaMasterAccounts: preData,
                selectedAccount,
            };
        }

        return { ...prevState };
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(SelectorsActionCreditCards.purchaseNotificationPreRequest());
    }

    buildCardListAndNotifications = (accountsToWorkWith) => {
        const { values } = this.props;

        let result;

        if (values.accountVisaMaster !== ALL) {
            result = accountsToWorkWith.creditCardList.map((creditCard, i) => {
                return (
                    <React.Fragment>
                        <span
                            className={classNames("container-product", {
                                mt2: i !== 0,
                            })}>
                            {UtilsI18n.get(`client.creditcard.type.label.${creditCard.bin}`)}
                            {` (${creditCard.number})`}
                            {` ${creditCard.owner}`}
                        </span>
                        {this.buildNotificationOptionsAndData(accountsToWorkWith, i)}
                    </React.Fragment>
                );
            });
        } else {
            const cardList = accountsToWorkWith.creditCardList.map((creditCard) => {
                return (
                    <React.Fragment>
                        <span className="container-product">
                            {UtilsI18n.get(`client.creditcard.type.label.${creditCard.bin}`)}
                            {` (${creditCard.number})`}
                            {` ${creditCard.owner}`}
                        </span>
                    </React.Fragment>
                );
            });

            result = (
                <React.Fragment>
                    {cardList}
                    {this.buildNotificationOptionsAndData(accountsToWorkWith, ALL)}
                </React.Fragment>
            );
        }

        return result;
    };

    buildNotificationOptionsAndData = (accountsToWorkWith, i) => {
        const { values, isMobile } = this.props;
        const visaMasterAccount = i === ALL ? ALL : accountsToWorkWith.visaMasterAccount;
        const optionsName = `${visaMasterAccount}${UNDERSCORE}${i}`;
        const optionsValue = values[optionsName];

        return (
            <React.Fragment>
                <Field
                    component={Selector}
                    idForm={FORM_ID}
                    name={optionsName}
                    options={this.buildNotificationOptions()}
                    renderAs="radio"
                />
                {optionsValue && optionsValue === NOTIFICATION_OPTIONS.SMS && (
                    <Box
                        flex
                        directionColumn={isMobile}
                        align={!isMobile && "start"}
                        className="container-data phone-field-wrapper">
                        <Box grow className="field-mobilephone" id={StyleField.ID}>
                            <FieldLabel labelKey={`${FORM_ID}.mobilePhone.label`} />
                            <Field
                                autoFocus={false}
                                className="login-form-input"
                                component={CountryCellPhoneField}
                                filter={MOBILE_PHONE_OPTION_FILTER}
                                handleOnBlur={this.handleOnBlur}
                                hideLabel
                                hidePlaceholder={false}
                                idForm={FORM_ID}
                                labelKey="label"
                                name={`mobilePhone${visaMasterAccount}${UNDERSCORE}${i}`}
                                pattern={ONLY_NUMBER}
                                type="text"
                                valueKey="countryName"
                                withOutOptions
                            />
                        </Box>
                        <Box grow className="field-mobilephone">
                            <FieldLabel labelKey={`${FORM_ID}.mobilePhoneConfirmation.label`} />
                            <Field
                                autoFocus={false}
                                className="form-control"
                                component={CountryCellPhoneField}
                                filter={MOBILE_PHONE_OPTION_FILTER}
                                handleOnBlur={this.handleOnBlur}
                                hideLabel
                                hidePlaceholder={false}
                                idForm={FORM_ID}
                                labelKey="label"
                                name={`mobilePhoneConfirmation${visaMasterAccount}${UNDERSCORE}${i}`}
                                pattern={ONLY_NUMBER}
                                type="text"
                                valueKey="countryName"
                                withOutOptions
                            />
                        </Box>
                    </Box>
                )}
                {optionsValue && optionsValue === NOTIFICATION_OPTIONS.EMAIL && (
                    <Box flex directionColumn={isMobile} align={!isMobile && "start"} className="container-data">
                        <Box grow>
                            <FieldLabel labelKey={`${FORM_ID}.email.label`} />
                            <Field
                                autoComplete="off"
                                component={TextField}
                                hidelabel
                                hidePlaceholder
                                idForm={FORM_ID}
                                mode={MODE.EDIT}
                                name={`email${visaMasterAccount}${UNDERSCORE}${i}`}
                                type="text"
                            />
                        </Box>
                        <Box grow>
                            <FieldLabel labelKey={`${FORM_ID}.emailConfirmation.label`} />
                            <Field
                                autoComplete="off"
                                component={TextField}
                                hidelabel
                                hidePlaceholder
                                idForm={FORM_ID}
                                mode={MODE.EDIT}
                                name={`emailConfirmation${visaMasterAccount}${UNDERSCORE}${i}`}
                                type="text"
                            />
                        </Box>
                    </Box>
                )}
            </React.Fragment>
        );
    };

    buildOptions = () => {
        const { allVisaMasterAccounts } = this.state;
        const enabledCardTypes = UtilsConfig.getArray("client.creditCards.purchaseNotification.enabledCards");

        const optionArray = allVisaMasterAccounts.map((account) => {
            const { creditCardList } = account || {};
            const creditCardOwner =
                creditCardList && creditCardList.find((creditCard) => creditCard.ownershipType === CREDIT_CARD_OWNER);

            if (creditCardOwner) {
                return {
                    error: account.incrementErrorCode,
                    value: account.visaMasterAccount,
                    label: (
                        <div className="select-card-option needsclick">
                            <span className="needsclick">{`${account.alias}`}</span>
                            <span className="needsclick">{`(${creditCardOwner.number})`}</span>
                        </div>
                    ),
                };
            }

            return {
                error: account.incrementErrorCode,
                value: account.visaMasterAccount,
                label: (
                    <div className="select-card-option needsclick">
                        <span className="needsclick">{`${account.alias}`}</span>
                    </div>
                ),
            };
        });

        const params = {
            cardTypes: enabledCardTypes
                .map((cardType) => UtilsI18n.get(`${FORM_ID}.accountCards.option.all.${cardType}`))
                .join(COMMA_PLUS_SPACE),
        };

        optionArray.unshift({
            error: EMPTY_STR,
            value: ALL,
            label: (
                <div className="select-card-option needsclick">
                    <span className="needsclick">
                        {UtilsI18n.get(`${FORM_ID}.accountCards.option.all`, null, params)}
                    </span>
                </div>
            ),
        });

        optionArray.unshift({
            error: EMPTY_STR,
            value: EMPTY_STR,
            label: (
                <div className="select-card-option needsclick">
                    <span className="needsclick">
                        {UtilsI18n.get(`${FORM_ID}.accountCards.option.selectAnOption`, null, params)}
                    </span>
                </div>
            ),
        });

        return optionArray;
    };

    buildNotificationOptions = () => {
        const options = [
            { id: NOTIFICATION_OPTIONS.SMS, label: UtilsI18n.get(`${FORM_ID}.notificationType.option.SMS`) },
            { id: NOTIFICATION_OPTIONS.EMAIL, label: UtilsI18n.get(`${FORM_ID}.notificationType.option.EMAIL`) },
            { id: NOTIFICATION_OPTIONS.INACTIVE, label: UtilsI18n.get(`${FORM_ID}.notificationType.option.DESAC`) },
        ];

        return options;
    };

    handleAccountChange = (item) => {
        const {
            setErrors,
            setFieldValue,
            values: { accountVisaMaster: previousItem },
        } = this.props;
        const { allVisaMasterAccounts } = this.state;
        const selectedAccount = allVisaMasterAccounts.find((account) => account.visaMasterAccount === item);

        if (previousItem !== EMPTY_STR && item !== previousItem) {
            const mobilePhoneOptions = GetMobileCountryOptions(MOBILE_PHONE_OPTION_FILTER);

            const notificationsOptionList = [];

            if (previousItem === ALL) {
                const eachNotificationEvery = [];

                const allNotificationData =
                    (allVisaMasterAccounts[0]?.creditCardList?.length > 0 &&
                        allVisaMasterAccounts[0].creditCardList[0].notificationData) ||
                    EMPTY_STR;
                const allNotificationMobilePhonePrefix =
                    (allVisaMasterAccounts[0]?.creditCardList?.length > 0 &&
                        allVisaMasterAccounts[0].creditCardList[0].notificationMobilePhonePrefix) ||
                    EMPTY_STR;
                const allNotificationType =
                    (allVisaMasterAccounts[0]?.creditCardList?.length > 0 &&
                        allVisaMasterAccounts[0].creditCardList[0].notificationType) ||
                    EMPTY_STR;

                allVisaMasterAccounts.forEach((visaMasterAccount) => {
                    eachNotificationEvery.push(
                        visaMasterAccount?.creditCardList.every(
                            (card) =>
                                card.notificationData === allNotificationData &&
                                card.notificationMobilePhonePrefix === allNotificationMobilePhonePrefix &&
                                card.notificationType === allNotificationType,
                        ) || false,
                    );
                });

                let cardToUse;

                if (eachNotificationEvery.every((eachData) => eachData === true)) {
                    cardToUse = allVisaMasterAccounts.length > 0 && allVisaMasterAccounts[0]?.creditCardList[0];
                } else {
                    cardToUse = {
                        notificationData: EMPTY_STR,
                        notificationMobilePhonePrefix: EMPTY_STR,
                        notificationType: NOTIFICATION_OPTIONS.INACTIVE,
                    };
                }

                notificationsOptionList.push({
                    key: `${ALL}${UNDERSCORE}${ALL}`,
                    notificationData: cardToUse.notificationData,
                    notificationMobilePhonePrefix: cardToUse.notificationMobilePhonePrefix,
                    notificationType: cardToUse.notificationType,
                });
            } else {
                const previousSelectedAccount = allVisaMasterAccounts.find(
                    (account) => account.visaMasterAccount === previousItem,
                );

                previousSelectedAccount.creditCardList.forEach((card, i) =>
                    notificationsOptionList.push({
                        key: `${previousSelectedAccount.visaMasterAccount}${UNDERSCORE}${i}`,
                        notificationData: card.notificationData,
                        notificationMobilePhonePrefix: card.notificationMobilePhonePrefix,
                        notificationType: card.notificationType,
                    }),
                );
            }

            notificationsOptionList.forEach((eachNotification) => {
                setFieldValue(eachNotification.key, eachNotification.notificationType);

                if (NOTIFICATION_OPTIONS.EMAIL === eachNotification.notificationType) {
                    setFieldValue(`email${eachNotification.key}`, eachNotification.notificationData);
                    setFieldValue(`emailConfirmation${eachNotification.key}`, eachNotification.notificationData);
                } else if (NOTIFICATION_OPTIONS.SMS === eachNotification.notificationType) {
                    const mobilePhone = getCleanPhoneNumber(
                        eachNotification.notificationData,
                        eachNotification.notificationMobilePhonePrefix,
                    );

                    const mobilePhoneObject = {
                        country: mobilePhoneOptions.find(
                            (element) => element?.value === `+${eachNotification.notificationMobilePhonePrefix}`,
                        )?.country,
                        mobilePhone,
                        prefix: `+${eachNotification.notificationMobilePhonePrefix}`,
                    };

                    setFieldValue(`mobilePhone${eachNotification.key}`, mobilePhoneObject);
                    setFieldValue(`mobilePhoneConfirmation${eachNotification.key}`, mobilePhoneObject);
                }
            });
        }

        setFieldValue("accountVisaMaster", item);

        this.setState({
            selectedAccount,
        });

        setErrors({});
    };

    handleFormKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            event.preventDefault();
        }
    };

    handleOnBlur = (e) => {
        const { setFieldError, values } = this.props;
        const { country, mobilePhone } = values[e.currentTarget.name];

        if (!country) {
            e.preventDefault();
        } else {
            setFieldError(
                e.currentTarget.name,
                !ValidateInternationalPhoneNumber({ country, value: mobilePhone })
                    ? UtilsI18n.get(`${FORM_ID}.mobilePhone.mustBeAPhoneNumber`)
                    : null,
            );
        }
    };

    render() {
        const { values } = this.props;
        const { allVisaMasterAccounts, selectedAccount } = this.state;

        const options = this.buildOptions();

        let accountsToWorkWith;

        if (values.accountVisaMaster !== ALL) {
            accountsToWorkWith = selectedAccount;
        } else {
            const cardList = [];

            allVisaMasterAccounts.forEach((visaMasterAccount) =>
                visaMasterAccount.creditCardList.forEach((card) => cardList.push(card)),
            );

            accountsToWorkWith = {
                alias: ALL,
                creditCardList: cardList,
                idProduct: ALL,
                visaMasterAccount: ALL,
                visaMasterBrand: ALL,
            };
        }

        return (
            <React.Fragment>
                <Form id={Style.ID} noValidate="novalidate" onKeyDown={this.handleFormKeyDown}>
                    <section>
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">
                                <I18n id={`${FORM_ID}.section`} />
                            </h3>
                        </Box>
                        <Box className="form-group form-data">
                            <Box className="form-data">
                                <Field
                                    className="flex-container slideFromBottom card-select-container"
                                    clearable={false}
                                    component={Selector}
                                    hideLabel
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    labelKey="label"
                                    name="accountVisaMaster"
                                    onChange={(item) => {
                                        if (item) {
                                            this.handleAccountChange(item.value);
                                        }
                                    }}
                                    optionClassName="needsclick"
                                    options={options}
                                    placeholder={EMPTY_STR}
                                    renderAs="combo"
                                    value={values.accountVisaMaster}
                                    valueKey="id"
                                />
                                {accountsToWorkWith && accountsToWorkWith.creditCardList
                                    ? this.buildCardListAndNotifications(accountsToWorkWith)
                                    : accountsToWorkWith && <I18n id={`${FORM_ID}.noCards.label`} />}
                            </Box>
                        </Box>
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">
                                <I18n id={`${FORM_ID}.finish`} />
                            </h3>
                        </Box>
                        <Box className="disclaimer">
                            <div className="form-group disclaimer-container">
                                <div className="disclaimer-text">
                                    <I18n id={`${FORM_ID}.disclaimer.first`} />
                                    <I18n id={`${FORM_ID}.disclaimer.second`} />
                                </div>
                            </div>
                        </Box>
                        <footer className="footer">
                            <Button
                                bsStyle="primary"
                                key="primaryButton"
                                label="global.send"
                                loading={false}
                                type="submit"
                                variant="primary"
                            />
                        </footer>
                    </section>
                </Form>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        dateFormat: SelectorsStoreConfig.getConfig(store)["frontend.shortDateFormat"]
            ? SelectorsStoreConfig.getConfig(store)["frontend.shortDateFormat"].toUpperCase()
            : FORMAT_SHORT,
        fetching: SelectorsStoreCreditCards.getFetching(store),
        lang: SelectorsStoreI18n.getLang(store),
        preData: SelectorsStoreCreditCards.getVisaMasterAccounts(store),
        purchaseNotificationData: SelectorsStoreCreditCards.getPurchaseNotificationFormData(store),
    };
};

export default HighOrder(
    HighOrder.Resizable,
    WithRouter,
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        mapPropsToValues: ({ preData, purchaseNotificationData }) => {
            const mobilePhoneOptions = GetMobileCountryOptions(MOBILE_PHONE_OPTION_FILTER);

            const defaultMobilePhoneObject = {
                country: mobilePhoneOptions[0]?.country,
                mobilePhone: EMPTY_STR,
                prefix: mobilePhoneOptions[0]?.value,
            };

            const values = {
                accountVisaMaster:
                    (purchaseNotificationData && purchaseNotificationData.selectedCardAccount) || EMPTY_STR,
                [`${ALL}${UNDERSCORE}${ALL}`]: NOTIFICATION_OPTIONS.INACTIVE,
                [`email${ALL}${UNDERSCORE}${ALL}`]: EMPTY_STR,
                [`emailConfirmation${ALL}${UNDERSCORE}${ALL}`]: EMPTY_STR,
                [`mobilePhone${ALL}${UNDERSCORE}${ALL}`]: defaultMobilePhoneObject,
                [`mobilePhoneConfirmation${ALL}${UNDERSCORE}${ALL}`]: defaultMobilePhoneObject,
            };

            if (purchaseNotificationData || preData) {
                let allNotificationData = EMPTY_STR;
                let allNotificationMobilePhonePrefix = EMPTY_STR;
                let allNotificationType = EMPTY_STR;
                const allNotificationDataPre =
                    (preData[0]?.creditCardList?.length > 0 && preData[0].creditCardList[0].notificationData) ||
                    EMPTY_STR;
                const allNotificationMobilePhonePrefixPre =
                    (preData[0]?.creditCardList?.length > 0 &&
                        preData[0].creditCardList[0].notificationMobilePhonePrefix) ||
                    EMPTY_STR;
                const allNotificationTypePre =
                    (preData[0]?.creditCardList?.length > 0 && preData[0].creditCardList[0].notificationType) ||
                    EMPTY_STR;
                const allNotificationDataPurchaseNotification =
                    (purchaseNotificationData?.cardAccountsNotificationsUpdated[0]?.creditCardList?.length > 0 &&
                        purchaseNotificationData?.cardAccountsNotificationsUpdated[0].creditCardList[0]
                            .notificationData) ||
                    EMPTY_STR;
                const allNotificationMobilePhonePrefixPurchaseNotification =
                    (purchaseNotificationData?.cardAccountsNotificationsUpdated[0]?.creditCardList?.length > 0 &&
                        purchaseNotificationData?.cardAccountsNotificationsUpdated[0].creditCardList[0]
                            .notificationMobilePhonePrefix) ||
                    EMPTY_STR;
                const allNotificationTypePurchaseNotification =
                    (purchaseNotificationData?.cardAccountsNotificationsUpdated[0]?.creditCardList?.length > 0 &&
                        purchaseNotificationData?.cardAccountsNotificationsUpdated[0].creditCardList[0]
                            .notificationType) ||
                    EMPTY_STR;

                let eachDataEvery = [];
                const eachPreDataEvery = [];
                const eachPurchaseNotificationEvery = [];
                let notificationsOptionList = [];
                const notificationsOptionListPre = [];
                const notificationsOptionListPurchaseNotification = [];
                let notificationsValueList = [];
                const notificationsValueListPre = [];
                const notificationsValueListPurchaseNotification = [];

                preData.forEach((visaMasterAccount) => {
                    eachPreDataEvery.push(
                        visaMasterAccount?.creditCardList.every(
                            (card) =>
                                card.notificationData === allNotificationDataPre &&
                                card.notificationMobilePhonePrefix === allNotificationMobilePhonePrefixPre &&
                                card.notificationType === allNotificationTypePre,
                        ) || false,
                    );

                    visaMasterAccount.creditCardList.forEach((card, i) => {
                        notificationsOptionListPre.push(`${visaMasterAccount.visaMasterAccount}${UNDERSCORE}${i}`);
                        notificationsValueListPre.push({
                            data: card.notificationData,
                            mobilePhonePrefix: card.notificationMobilePhonePrefix,
                            type: card.notificationType,
                        });
                    });
                });

                if (purchaseNotificationData) {
                    purchaseNotificationData.cardAccountsNotificationsUpdated.forEach((visaMasterAccount) => {
                        eachPurchaseNotificationEvery.push(
                            visaMasterAccount?.creditCardList.every(
                                (card) =>
                                    card.notificationData === allNotificationDataPurchaseNotification &&
                                    card.notificationMobilePhonePrefix ===
                                        allNotificationMobilePhonePrefixPurchaseNotification &&
                                    card.notificationType === allNotificationTypePurchaseNotification,
                            ) || false,
                        );

                        visaMasterAccount.creditCardList.forEach((card, i) => {
                            notificationsOptionListPurchaseNotification.push(
                                `${visaMasterAccount.visaMasterAccount}${UNDERSCORE}${i}`,
                            );
                            notificationsValueListPurchaseNotification.push({
                                data: card.notificationData,
                                mobilePhonePrefix: card.notificationMobilePhonePrefix,
                                type: card.notificationType,
                            });
                        });
                    });

                    if (ALL === purchaseNotificationData.selectedCardAccount) {
                        allNotificationData = allNotificationDataPurchaseNotification;
                        allNotificationMobilePhonePrefix = allNotificationMobilePhonePrefixPurchaseNotification;
                        allNotificationType = allNotificationTypePurchaseNotification;
                        eachDataEvery = eachPurchaseNotificationEvery;
                        notificationsOptionList = notificationsOptionListPre;
                        notificationsValueList = notificationsValueListPre;
                    } else {
                        allNotificationData = allNotificationDataPre;
                        allNotificationMobilePhonePrefix = allNotificationMobilePhonePrefixPre;
                        allNotificationType = allNotificationTypePre;
                        eachDataEvery = eachPreDataEvery;
                        notificationsOptionList = notificationsOptionListPurchaseNotification;
                        notificationsValueList = notificationsValueListPurchaseNotification;
                    }
                } else {
                    allNotificationData = allNotificationDataPre;
                    allNotificationMobilePhonePrefix = allNotificationMobilePhonePrefixPre;
                    allNotificationType = allNotificationTypePre;
                    eachDataEvery = eachPreDataEvery;
                    notificationsOptionList = notificationsOptionListPre;
                    notificationsValueList = notificationsValueListPre;
                }

                // VALUES for the ALL option are prefilled
                if (eachDataEvery.every((eachData) => eachData === true)) {
                    values[`${ALL}${UNDERSCORE}${ALL}`] = allNotificationType;

                    if (allNotificationType === NOTIFICATION_OPTIONS.EMAIL) {
                        values[`email${ALL}${UNDERSCORE}${ALL}`] = allNotificationData;
                        values[`emailConfirmation${ALL}${UNDERSCORE}${ALL}`] = allNotificationData;
                    } else if (allNotificationType === NOTIFICATION_OPTIONS.SMS) {
                        const allMobilePhone = getCleanPhoneNumber(
                            allNotificationData,
                            allNotificationMobilePhonePrefix,
                        );

                        const allMobilePhoneObject = {
                            country: mobilePhoneOptions.find(
                                (element) => element?.value === `+${allNotificationMobilePhonePrefix}`,
                            )?.country,
                            mobilePhone: allMobilePhone,
                            prefix: `+${allNotificationMobilePhonePrefix}`,
                        };

                        values[`mobilePhone${ALL}${UNDERSCORE}${ALL}`] = allMobilePhoneObject;
                        values[`mobilePhoneConfirmation${ALL}${UNDERSCORE}${ALL}`] = allMobilePhoneObject;
                    }
                }

                // VALUES for other option THAN ALL are prefilled
                for (let i = 0; i < notificationsOptionList.length; i += 1) {
                    const splitOption = notificationsOptionList[i].split(UNDERSCORE);

                    values[notificationsOptionList[i]] = notificationsValueList[i].type;
                    values[`email${splitOption[0]}${UNDERSCORE}${splitOption[1]}`] = EMPTY_STR;
                    values[`emailConfirmation${splitOption[0]}${UNDERSCORE}${splitOption[1]}`] = EMPTY_STR;
                    values[`mobilePhone${splitOption[0]}${UNDERSCORE}${splitOption[1]}`] = defaultMobilePhoneObject;
                    values[`mobilePhoneConfirmation${splitOption[0]}${UNDERSCORE}${splitOption[1]}`] =
                        defaultMobilePhoneObject;

                    if (notificationsValueList[i].type === NOTIFICATION_OPTIONS.EMAIL) {
                        values[`email${splitOption[0]}${UNDERSCORE}${splitOption[1]}`] = notificationsValueList[i].data;
                        values[`emailConfirmation${splitOption[0]}${UNDERSCORE}${splitOption[1]}`] =
                            notificationsValueList[i].data;
                    } else if (notificationsValueList[i].type === NOTIFICATION_OPTIONS.SMS) {
                        const mobilePhone = getCleanPhoneNumber(
                            notificationsValueList[i].data,
                            notificationsValueList[i].mobilePhonePrefix,
                        );

                        const eachMobilePhoneObject = {
                            country: mobilePhoneOptions.find(
                                (element) => element?.value === `+${notificationsValueList[i].mobilePhonePrefix}`,
                            )?.country,
                            mobilePhone,
                            prefix: `+${notificationsValueList[i].mobilePhonePrefix}`,
                        };

                        values[`mobilePhone${splitOption[0]}${UNDERSCORE}${splitOption[1]}`] = eachMobilePhoneObject;
                        values[`mobilePhoneConfirmation${splitOption[0]}${UNDERSCORE}${splitOption[1]}`] =
                            eachMobilePhoneObject;
                    }
                }
            }

            return values;
        },
        validationSchema: ({ preData }) => {
            return Yup.lazy((values) => {
                const optionList = [];
                const { accountVisaMaster } = values;

                if (accountVisaMaster === ALL) {
                    optionList.push(`${ALL}${UNDERSCORE}${ALL}`);
                } else if (accountVisaMaster && accountVisaMaster !== EMPTY_STR) {
                    const cardListToWorkWith = preData.find(
                        (visaMasterAccount) => visaMasterAccount.visaMasterAccount === accountVisaMaster,
                    ).creditCardList;

                    cardListToWorkWith.forEach((card, i) => optionList.push(`${accountVisaMaster}${UNDERSCORE}${i}`));
                }

                const shape = {
                    accountVisaMaster: Yup.string()
                        .trim()
                        .required(UtilsI18n.get(`${FORM_ID}.accountVisaMaster.required`)),
                };

                for (let i = 0; i < optionList.length; i += 1) {
                    const splitOption = optionList[i].split(UNDERSCORE);

                    shape[optionList[i]] = Yup.string().required(
                        UtilsI18n.get(`${FORM_ID}.notificationOption.required`),
                    );

                    if (values[optionList[i]] === NOTIFICATION_OPTIONS.SMS) {
                        shape[`mobilePhone${accountVisaMaster}${UNDERSCORE}${splitOption[1]}`] = Yup.object().shape({
                            prefix: Yup.string().required(UtilsI18n.get(`${FORM_ID}.mobilePhone.mustBeAPhoneNumber`)),
                            mobilePhone: Yup.string()
                                .trim()
                                .required(UtilsI18n.get(`${FORM_ID}.mobilePhone.required`))
                                .test(
                                    "validMobilePhone",
                                    UtilsI18n.get(`${FORM_ID}.mobilePhone.mustBeAPhoneNumber`),
                                    () => {
                                        const { country, mobilePhone } =
                                            values[`mobilePhone${accountVisaMaster}${UNDERSCORE}${splitOption[1]}`];

                                        return ValidateInternationalPhoneNumber({ country, value: mobilePhone });
                                    },
                                )
                                .oneOf(
                                    [
                                        values[
                                            `mobilePhoneConfirmation${accountVisaMaster}${UNDERSCORE}${splitOption[1]}`
                                        ].mobilePhone,
                                    ],
                                    UtilsI18n.get(`${FORM_ID}.mobilePhones.dontMatch`),
                                ),
                            country: Yup.string(),
                        });
                        shape[`mobilePhoneConfirmation${accountVisaMaster}${UNDERSCORE}${splitOption[1]}`] =
                            Yup.object().shape({
                                prefix: Yup.string().required(
                                    UtilsI18n.get(`${FORM_ID}.mobilePhone.mustBeAPhoneNumber`),
                                ),
                                mobilePhone: Yup.string()
                                    .trim()
                                    .required(UtilsI18n.get(`${FORM_ID}.mobilePhoneConfirmation.required`))
                                    .test(
                                        "validMobilePhoneConfirmation",
                                        UtilsI18n.get(`${FORM_ID}.mobilePhone.mustBeAPhoneNumber`),
                                        () => {
                                            const { country, mobilePhone } =
                                                values[
                                                    `mobilePhoneConfirmation${accountVisaMaster}${UNDERSCORE}${splitOption[1]}`
                                                ];

                                            return ValidateInternationalPhoneNumber({ country, value: mobilePhone });
                                        },
                                    )
                                    .oneOf(
                                        [
                                            values[`mobilePhone${accountVisaMaster}${UNDERSCORE}${splitOption[1]}`]
                                                .mobilePhone,
                                        ],
                                        UtilsI18n.get(`${FORM_ID}.mobilePhones.dontMatch`),
                                    ),
                                country: Yup.string(),
                            });
                    } else if (values[optionList[i]] === NOTIFICATION_OPTIONS.EMAIL) {
                        shape[`email${accountVisaMaster}${UNDERSCORE}${splitOption[1]}`] = Yup.string()
                            .trim()
                            .email(UtilsI18n.get("global.userconfiguration.emailIncorrectFormat"))
                            .required(UtilsI18n.get(`${FORM_ID}.email.required`))
                            .oneOf(
                                [values[`emailConfirmation${accountVisaMaster}${UNDERSCORE}${splitOption[1]}`]],
                                UtilsI18n.get(`${FORM_ID}.emails.dontMatch`),
                            );
                        shape[`emailConfirmation${accountVisaMaster}${UNDERSCORE}${splitOption[1]}`] = Yup.string()
                            .trim()
                            .email(UtilsI18n.get("global.userconfiguration.emailIncorrectFormat"))
                            .required(UtilsI18n.get(`${FORM_ID}.emailConfirmation.required`))
                            .oneOf(
                                [values[`email${accountVisaMaster}${UNDERSCORE}${splitOption[1]}`]],
                                UtilsI18n.get(`${FORM_ID}.emails.dontMatch`),
                            );
                    }
                }

                return Yup.object().shape(shape);
            });
        },
        handleSubmit: (props, formikBag) => {
            const { dispatch, preData } = formikBag.props;
            const { accountVisaMaster } = props;

            const cardsToWorkWith = [];
            const notifications = [];

            if (accountVisaMaster === ALL) {
                preData.forEach((eachCardAccount) =>
                    eachCardAccount.creditCardList.forEach((card, i) =>
                        cardsToWorkWith.push(`${eachCardAccount.visaMasterAccount}${UNDERSCORE}${i}`),
                    ),
                );
            } else {
                const specificCardAccount = preData.find(
                    (visaMasterAccount) => visaMasterAccount.visaMasterAccount === accountVisaMaster,
                );

                specificCardAccount.creditCardList.forEach((card, i) =>
                    cardsToWorkWith.push(`${specificCardAccount.visaMasterAccount}${UNDERSCORE}${i}`),
                );
            }

            cardsToWorkWith.forEach((eachCard) => {
                const fieldPattern = accountVisaMaster === ALL ? `${ALL}${UNDERSCORE}${ALL}` : eachCard;

                const eachNotification = {
                    card: eachCard,
                    notificationData: undefined,
                    notificationType: props[fieldPattern],
                };

                if (props[fieldPattern] === NOTIFICATION_OPTIONS.EMAIL) {
                    eachNotification.notificationData = props[`email${fieldPattern}`];
                } else if (props[fieldPattern] === NOTIFICATION_OPTIONS.SMS) {
                    const mobilePhone = props[`mobilePhone${fieldPattern}`];
                    const prefixWithoutPlus = mobilePhone.prefix.substring(1);

                    mobilePhone.mobilePhone = getCleanPhoneNumber(mobilePhone.mobilePhone, prefixWithoutPlus);

                    eachNotification.notificationData = mobilePhone;
                }

                notifications.push(eachNotification);
            });

            const formData = {
                accountVisaMaster,
                notifications,
            };

            dispatch(SelectorsActionCreditCards.purchaseNotificationPreviewRequest(formData));
        },
    }),
)(Component);
