/* eslint-disable react/no-array-index-key */
import React from "react";

import ClassNames from "classnames";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router";
import { Link } from "react-router-dom";

import { CC_BRAND_VISA, CDP_CURRENCY, CREDIT_CARD_OWNER, ZERO } from "~/constants";
import { PROP as PROP_CC } from "~/store/creditCards/creditCard";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import * as ConfigUtil from "~/util/config";

import Button from "~/components/Button";
import DataDate from "~/components/DataDate";
import DataNumber from "~/components/DataNumber";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Slides from "~/components/Slides";
import SwitchField from "~/pages/_components/fields/SwitchField";

import Style from "./CreditCardHeader.rules.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {
        creditCard: PROP_CC.types.detail,
        i18n: PropTypes.object,
    },
    defaults: {
        creditCard: PROP_CC.defaults.detail,
    },
};

export function Component(props) {
    const {
        creditCard,
        creditCardSelected,
        handleChangeSlide,
        handleModalSwitchStatus,
        handleShowMovements,
        id,
        i18n,
        isMobile,
        setShowMovements,
        showMovements,
    } = props;

    const {
        availableBalance,
        availableCurrency,
        brand,
        creditLimit,
        creditLimitCurrency,
        minimunPaymentUSD,
        minimunPaymentUYU,
        nextClosingDate,
        nextDueDate,
        paymentUSD,
        paymentUYU,
        permissions,
        productAlias,
    } = creditCard;

    const { payCreditCard } = permissions || {};

    const brandList = ConfigUtil.getArray("client.productTypes.creditCardWithAvailableBalance.list");
    const cardHasAvailableBalance = brandList.includes(brand) && availableBalance;

    const configCreditPrefix = "client.creditcard.type.";
    const CREDIT_CARD_STATUS = { ACT: true, BLQ: false, ING: false, INA: false };

    const CREDIT_CARD_ACT = "ACT";
    const crediCardPrev = React.useRef();

    React.useEffect(() => {
        if (creditCardSelected) {
            if (!crediCardPrev.current) {
                crediCardPrev.current = creditCardSelected;
            }
            if (crediCardPrev.current.number !== creditCardSelected.number) {
                setShowMovements(false);
            }
        }
    }, [creditCardSelected, setShowMovements]);

    return (
        creditCard.creditCardList.length > 0 && (
            <div id={Style.ID}>
                <Slides handleChangeSlide={handleChangeSlide}>
                    {creditCard.creditCardList.map((card, index) => {
                        const { bin, number, owner, ownershipType, status } = card;
                        const typeCodeCreditCard = bin !== ZERO ? bin : `${ZERO}.${brand}`;
                        const creditCardImageCode = ConfigUtil.get(`${configCreditPrefix}${typeCodeCreditCard}`);
                        const image = `cards/${creditCardImageCode}.png`;

                        return (
                            <div
                                className={ClassNames(
                                    "card-header",
                                    {
                                        "is-active": status === CREDIT_CARD_ACT,
                                    },
                                    {
                                        isOwner: ownershipType === CREDIT_CARD_OWNER,
                                    },
                                )}
                                key={index}>
                                <div className="data-header">
                                    {!isMobile && <Image src={image} />}
                                    {isMobile && ownershipType !== CREDIT_CARD_OWNER && <Image src={image} />}
                                    <div className="info-card">
                                        <div className="info-data">
                                            <span>
                                                {productAlias} {number}
                                            </span>
                                            {isMobile && <span>{owner}</span>}
                                            {isMobile && <span>{ownershipType}</span>}
                                        </div>
                                        {isMobile && brand === CC_BRAND_VISA && (
                                            <div className="switch-status">
                                                <SwitchField
                                                    name={`credit-card-${index}`}
                                                    key={`credit-card-${index}`}
                                                    label={status === CREDIT_CARD_ACT ? i18n.enabled : i18n.disabled}
                                                    value={CREDIT_CARD_STATUS[status]}
                                                    onChange={() => handleModalSwitchStatus(card, index)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="operation-buttons">
                                        <Button
                                            className={ClassNames("btn-show-movements", {
                                                "is-active": showMovements,
                                            })}
                                            label="creditCards.detail.actions.button.movements"
                                            onClick={() => handleShowMovements(card)}
                                        />

                                        {payCreditCard && ownershipType === CREDIT_CARD_OWNER && (
                                            <Link key="creditCard.pay" to={`/form/payCreditCard?creditCard=${id}`}>
                                                <I18n id="creditCard.pay" />
                                            </Link>
                                        )}
                                    </div>
                                    {!isMobile && (
                                        <div className="owner-name">
                                            <span>{owner}</span>
                                            <span>{ownershipType}</span>
                                            <div className="switch-status">
                                                {brand === CC_BRAND_VISA && (
                                                    <SwitchField
                                                        name={`credit-card-${index}`}
                                                        key={`credit-card-${index}`}
                                                        label={
                                                            status === CREDIT_CARD_ACT ? i18n.enabled : i18n.disabled
                                                        }
                                                        value={CREDIT_CARD_STATUS[status]}
                                                        onChange={() => handleModalSwitchStatus(card, index)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {ownershipType === CREDIT_CARD_OWNER && (
                                    <div
                                        className={ClassNames("data-amounts", {
                                            "is-master": brand !== CC_BRAND_VISA,
                                        })}>
                                        <div
                                            className={ClassNames("row auto-debit", {
                                                "is-master": brand !== CC_BRAND_VISA,
                                            })}>
                                            <h4>{i18n.labelAutoDebit}</h4>
                                            <span>{creditCard.automaticDebitTypeLabel}</span>
                                        </div>
                                        {brand === CC_BRAND_VISA && (
                                            <div className="row">
                                                <I18n id="creditCard.limit.granted" className="title" />
                                                <DataNumber value={creditLimit} prefix={creditLimitCurrency} />
                                            </div>
                                        )}
                                        <div className="row">
                                            <I18n id="creditCard.dueDate.next" className="title" />
                                            <DataDate value={nextDueDate} />
                                        </div>
                                        {!isMobile && (
                                            <div className="row">
                                                <I18n id="creditCard.payment.UYU" className="title" />
                                                <DataNumber value={paymentUYU} prefix={CDP_CURRENCY.UYU} />
                                            </div>
                                        )}
                                        {!isMobile && (
                                            <div className="row">
                                                <I18n id="creditCard.payment.USD" className="title" />
                                                <DataNumber value={paymentUSD} prefix={CDP_CURRENCY.USD} />
                                            </div>
                                        )}
                                        {cardHasAvailableBalance && (
                                            <div className="row">
                                                <I18n id="creditCard.availableBalance" className="title" />
                                                <DataNumber value={availableBalance} prefix={availableCurrency} />
                                            </div>
                                        )}
                                        <div className="row">
                                            <I18n id="creditCard.closingDate.next" className="title" />
                                            <DataDate value={nextClosingDate} />
                                        </div>
                                        {!isMobile ? (
                                            <div className="row">
                                                <I18n id="creditCard.payment.Min" className="title" />
                                                <DataNumber value={minimunPaymentUYU} prefix={CDP_CURRENCY.UYU} />
                                            </div>
                                        ) : (
                                            <div className="row payment-uyu">
                                                <I18n id="creditCard.payment.UYU" className="title" />
                                                <DataNumber value={paymentUYU} prefix={CDP_CURRENCY.UYU} />
                                                <I18n id="creditCard.payment.Min" />
                                                <DataNumber value={minimunPaymentUYU} prefix={CDP_CURRENCY.UYU} />
                                            </div>
                                        )}
                                        {!isMobile ? (
                                            <div className="row">
                                                <I18n id="creditCard.payment.Min" className="title" />
                                                <DataNumber value={minimunPaymentUSD} prefix={CDP_CURRENCY.USD} />
                                            </div>
                                        ) : (
                                            <div className="row payment-usd">
                                                <I18n id="creditCard.payment.USD" className="title" />
                                                <DataNumber value={paymentUSD} prefix={CDP_CURRENCY.USD} />
                                                <I18n id="creditCard.payment.Min" />
                                                <DataNumber value={minimunPaymentUSD} prefix={CDP_CURRENCY.USD} />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </Slides>
            </div>
        )
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    const i18n = {
        disabled: StoreI18n.getMessage(store, "creditCard.details.disabled"),
        enabled: StoreI18n.getMessage(store, "creditCard.details.enabled"),
        labelAutoDebit: StoreI18n.getMessage(store, "creditCard.automaticDebit"),
    };

    return {
        i18n,
    };
};
export default Connect(mapStateToProps)(WithRouter(HighOrder.Resizable(Component)));
