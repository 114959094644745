import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    myBriefcasePreRequest: () => API.executeWithAccessToken(PATH.MY_BRIEFCASE_PRE_REQUEST),
    requestDownloadPDF: (props) => API.executeWithAccessToken(PATH.RISK_PROFILE_DOWNLOAD_PDF_REQUEST, props),
    requestInfoPreRequest: () => API.executeWithAccessToken(PATH.REQUEST_INFO_PRE_REQUEST),
    requestInfoPreviewRequest: (props) => API.executeWithAccessToken(PATH.REQUEST_INFO_PREVIEW_REQUEST, props),
    requestInfoRequest: (props) => API.executeWithAccessToken(PATH.REQUEST_INFO_REQUEST, props),
    requestReadLastRiskProfile: (props) => API.executeWithAccessToken(PATH.RISK_PROFILE_READ_LAST, props),
    requestSendQuestions: (props) => API.executeWithAccessToken(PATH.RISK_PROFILE_SEND_QUESTIONS_REQUEST, props),
    termDepositPreRequest: () => API.executeWithAccessToken(PATH.TERM_DEPOSIT_PRE_REQUEST),
    termDepositPreviewRequest: (props) => API.executeWithAccessToken(PATH.TERM_DEPOSIT_PREVIEW_REQUEST, props),
    termDepositSendRequest: (props) => API.executeWithAccessToken(PATH.TERM_DEPOSIT_SEND_REQUEST, props),
    termDepositCMBPreviewRequest: (props) => API.executeWithAccessToken(PATH.TERM_DEPOSIT_CMB_PREVIEW_REQUEST, props),
    termDepositCMBSendRequest: (props) => API.executeWithAccessToken(PATH.TERM_DEPOSIT_CMB_SEND_REQUEST, props),
    termDepositGetCustomRate: (props) => API.executeWithAccessToken(PATH.TERM_DEPOSIT_GET_CUSTOM_RATE, props),
};

export const SelectorsAction = {
    cancelTransactionCustom: (props) => FactoryAction(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, props),
    closeChangeDateModal: () => FactoryAction(TYPE.CLOSE_CHANGE_DATE_MODAL),
    closeConfirmation: () => FactoryAction(TYPE.CLOSE_TRANSACTION_PREVIEW),
    closePreview: () => FactoryAction(TYPE.REQUEST_INFO_CLOSE_PREVIEW),
    currencyHasChangedRequest: () => FactoryAction(TYPE.CURRENCY_HAS_CHANGED_REQUEST),
    myBriefcasePreFailure: (props) => FactoryAction(TYPE.MY_BRIEFCASE_PRE_FAILURE, props),
    myBriefcasePreRequest: () => FactoryAction(TYPE.MY_BRIEFCASE_PRE_REQUEST),
    myBriefcasePreSuccess: (props) => FactoryAction(TYPE.MY_BRIEFCASE_PRE_SUCCESS, props),
    openChangeDateModal: () => FactoryAction(TYPE.OPEN_CHANGE_DATE_MODAL),
    readTransactionRequest: (props) => FactoryAction(TYPE.READ_TRANSACTION_REQUEST, props),
    readTransactionSuccess: (props) => FactoryAction(TYPE.READ_TRANSACTION_SUCCESS, props),
    requestInfoPreFailure: (props) => FactoryAction(TYPE.REQUEST_INFO_PRE_FAILURE, props),
    requestInfoPreRequest: () => FactoryAction(TYPE.REQUEST_INFO_PRE_REQUEST),
    requestInfoPreSuccess: (props) => FactoryAction(TYPE.REQUEST_INFO_PRE_SUCCESS, props),
    requestInfoPreview: (props) => FactoryAction(TYPE.REQUEST_INFO_PREVIEW_REQUEST, props),
    requestInfoPreviewFailure: (props) => FactoryAction(TYPE.REQUEST_INFO_PREVIEW_FAILURE, props),
    requestInfoPreviewSuccess: () => FactoryAction(TYPE.REQUEST_INFO_PREVIEW_SUCCESS),
    requestInfoSendForm: (props) => FactoryAction(TYPE.REQUEST_INFO_SEND_FORM, props),
    requestInfoSendFormFailure: (props) => FactoryAction(TYPE.REQUEST_INFO_SEND_FORM_FAILURE, props),
    requestInfoSendFormSuccess: () => FactoryAction(TYPE.REQUEST_INFO_SEND_FORM_SUCCESS),
    restartFlow: () => FactoryAction(TYPE.RESTART_FLOW),
    riskProfileDownloadPDF: (props) => FactoryAction(TYPE.RISK_PROFILE_DOWNLOAD_PDF_REQUEST, props),
    riskProfileDownloadPDFFailure: (props) => FactoryAction(TYPE.RISK_PROFILE_DOWNLOAD_PDF_FAILURE, props),
    riskProfileDownloadPDFSuccess: (props) => FactoryAction(TYPE.RISK_PROFILE_DOWNLOAD_PDF_SUCCESS, props),
    riskProfileReadLast: (props) => FactoryAction(TYPE.RISK_PROFILE_READ_LAST, props),
    riskProfileReadLastFailure: (props) => FactoryAction(TYPE.RISK_PROFILE_READ_LAST_FAILURE, props),
    riskProfileReadLastSuccess: (props) => FactoryAction(TYPE.RISK_PROFILE_READ_LAST_SUCCESS, props),
    riskProfileSendQuestions: (props) => FactoryAction(TYPE.RISK_PROFILE_SEND_QUESTIONS_REQUEST, props),
    riskProfileSendQuestionsFailure: (props) => FactoryAction(TYPE.RISK_PROFILE_SEND_QUESTIONS_FAILURE, props),
    riskProfileSendQuestionsSuccess: (props) => FactoryAction(TYPE.RISK_PROFILE_SEND_QUESTIONS_SUCCESS, props),
    setFromRiskProfileQuiz: (props) => FactoryAction(TYPE.FROM_RISK_PROFILE_QUIZ, props),
    signTransaction: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_REQUEST, props),
    signTransactionFailure: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_FAILURE, props),
    signTransactionPreview: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_PREVIEW, props),
    termDepositPreFailure: (props) => FactoryAction(TYPE.TERM_DEPOSIT_PRE_FAILURE, props),
    termDepositPreRequest: () => FactoryAction(TYPE.TERM_DEPOSIT_PRE_REQUEST),
    termDepositPreSuccess: (props) => FactoryAction(TYPE.TERM_DEPOSIT_PRE_SUCCESS, props),
    termDepositPreviewFailure: (props) => FactoryAction(TYPE.TERM_DEPOSIT_PREVIEW_FAILURE, props),
    termDepositPreviewRequest: (props) => FactoryAction(TYPE.TERM_DEPOSIT_PREVIEW_REQUEST, props),
    termDepositPreviewSuccess: (props) => FactoryAction(TYPE.TERM_DEPOSIT_PREVIEW_SUCCESS, props),
    termDepositSendFailure: (props) => FactoryAction(TYPE.TERM_DEPOSIT_SEND_FAILURE, props),
    termDepositSendRequest: (props) => FactoryAction(TYPE.TERM_DEPOSIT_SEND_REQUEST, props),
    termDepositSendSuccess: (props) => FactoryAction(TYPE.TERM_DEPOSIT_SEND_SUCCESS, props),
    termDepositGetCustomRateFailure: (props) => FactoryAction(TYPE.TERM_DEPOSIT_CUSTOM_RATE_FAILURE, props),
    termDepositGetCustomRateRequest: (props) => FactoryAction(TYPE.TERM_DEPOSIT_CUSTOM_RATE_REQUEST, props),
    termDepositGetCustomRateSuccess: (props) => FactoryAction(TYPE.TERM_DEPOSIT_CUSTOM_RATE_SUCCESS, props),
    termDepositToStepOne: () => FactoryAction(TYPE.TERM_DEPOSIT_TO_STEP_ONE),
};

export const SelectorsStore = {
    getAvailableForInvestChart: (store) => store[NAME].availableForInvestChart,
    getAvailableForInvesting: (store) => store[NAME].availableForInvesting,
    getFetching: (store) => store[NAME].fetching,
    getFundPapers: (store) => store[NAME].fundPapers,
    getHasDGI: (store) => store[NAME].hasDGI,
    getHasInvestmentsModule: (store) => store[NAME].hasInvestmentsModule,
    getHasProducts: (store) => store[NAME].hasProducts,
    getHasRiskProfile: (store) => store[NAME].hasRiskProfile,
    getHasW8Signed: (store) => store[NAME].hasW8Signed,
    getLastRiskProfile: (store) => store[NAME].riskProfile.data,
    getMyPortafolioTotal: (store) => store[NAME].myPortfolioTotal,
    getNotifications: (store) => store[NAME].notifications,
    getRequestInfoData: (store) => store[NAME].requestInfoData,
    getResident: (store) => store[NAME].resident,
    getShowChangeDateModal: (store) => store[NAME].showChangeDateModal,
    getTermDeposit: (store) => store[NAME].termDeposit,
    getTempFormData: (store) => store[NAME].tempFormData,
    getTransaction: (store) => store[NAME].transaction,
    getTransactionList: (store) => store[NAME].transactionList,
    isCurrencyHasChanged: (store) => store[NAME].currencyHasChanged,
    isFromRiskProfileQuiz: (store) => store[NAME].isFromRiskProfileQuiz,
};
