import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    getPaper: (props) => API.executeWithAccessToken(PATH.GET_PAPER, props),
    wealthManagementPreRequest: (props) => API.executeWithAccessToken(PATH.WEALTH_MANAGEMENT_SELL_FUNDS_PRE, props),
    wealthManagementPreviewRequest: (props) =>
        API.executeWithAccessToken(PATH.WEALTH_MANAGEMENT_SELL_FUNDS_PREVIEW, props),
    wealthManagementReloadPaper: (props) =>
        API.executeWithAccessToken(PATH.WEALTH_MANAGEMENT_SELL_FUNDS_RELOAD_PAPER, props),
    wealthManagementSendRequest: (props) => API.executeWithAccessToken(PATH.WEALTH_MANAGEMENT_SELL_FUNDS_SEND, props),
};

export const SelectorsAction = {
    cancelTransactionCustom: (props) => FactoryAction(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, props),
    closeConfirmation: () => FactoryAction(TYPE.CLOSE_TRANSACTION_PREVIEW),
    getPaperPDF: (props) => FactoryAction(TYPE.GET_PAPER, props),
    previewCancelRequest: () => FactoryAction(TYPE.PREVIEW_CANCEL_REQUEST),
    readWealthManagementTransactionRequest: (props) => FactoryAction(TYPE.READ_TRANSACTION_REQUEST, props),
    readWealthManagementTransactionSuccess: (props) => FactoryAction(TYPE.READ_TRANSACTION_SUCCESS, props),
    resetForm: (props) => FactoryAction(TYPE.RESET_FORM, props),
    restartFlow: () => FactoryAction(TYPE.RESTART_FLOW),
    selectedIdPaper: (props) => FactoryAction(TYPE.SELECTED_ID_PAPER, props),
    signTransaction: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_REQUEST, props),
    signTransactionPreview: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_PREVIEW_REQUEST, props),
    signTransactionPreviewSuccess: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_PREVIEW_SUCCESS, props),
    taskFinished: () => FactoryAction(TYPE.TASK_FINISHED),
    wealthManagementPreFormRequest: (props) => FactoryAction(TYPE.PRE_FORM_REQUEST, props),
    wealthManagementPreFormSuccess: (props) => FactoryAction(TYPE.PRE_FORM_SUCCESS, props),
    wealthManagementPreviewFormRequest: (props) => FactoryAction(TYPE.PREVIEW_FORM_REQUEST, props),
    wealthManagementPreviewFormSuccess: (props) => FactoryAction(TYPE.PREVIEW_FORM_SUCCESS, props),
    wealthManagementReadPaperRequest: (props) => FactoryAction(TYPE.READ_PAPER_INFO, props),
    wealthManagementReadPaperSuccess: (props) => FactoryAction(TYPE.READ_PAPER_SUCCESS, props),
    wealthManagementSendFormRequest: (props) => FactoryAction(TYPE.SEND_FORM_REQUEST, props),
    wealthManagementSendFormSuccess: (props) => FactoryAction(TYPE.SEND_FORM_SUCCESS, props),
};

export const SelectorsStore = {
    getAmount: (store) => store[NAME].amount,
    getCreditAccountAlias: (store) => store[NAME].creditAccountAlias,
    getCreditAccounts: (store) => store[NAME].creditAccounts,
    getCurrency: (store) => store[NAME].currency,
    getDisclaimer: (store) => store[NAME].disclaimer,
    getFunds: (store) => store[NAME].funds,
    getIdCreditAccounts: (store) => store[NAME].idCreditAccount,
    getIdPaper: (store) => store[NAME].idPaper,
    getIsSubmitting: (store) => store[NAME].isSubmitting,
    getlimitDate: (store) => store[NAME].limitDate,
    getMode: (store) => store[NAME].mode,
    getOrderCompleted: (store) => store[NAME].orderCompleted,
    getPaper: (store) => store[NAME].paper,
    getPaperName: (store) => store[NAME].paperName,
    getPrevMode: (store) => store[NAME].prevMode,
    getProcessingSale: (store) => store[NAME].processingSale,
    getShare: (store) => store[NAME].share,
    getTimezoneOffset: (store) => store[NAME].timezoneOffset,
    getTransaction: (store) => store[NAME].transaction,
    isFetching: (store) => store[NAME].isFetching,
};
