import React from "react";

import ClassNames from "classnames";

import Namespaces from "./Note.scss";

export const { CLASS, NAME, TAG: Note } = Namespaces;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component({ children, className, noMoreData, onClick }) {
    return (
        <Note className={ClassNames(CLASS, className, { "no-more": noMoreData })} onClick={onClick}>
            {children}
        </Note>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
