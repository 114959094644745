import React from "react";

import PropTypes from "prop-types";

import formField from "~/pages/forms/_components/_fields/_commons/formField";

export const NAME = "Sectiontitle";

export const PROP = {
    types: { label: PropTypes.string.isRequired },
    defaults: {},
};

export function Component(props) {
    const { label } = props;
    return (
        <React.Fragment>
            <hr />
            <div className="form-section-title">
                <h3 className="form-section-title-text">{label}</h3>
            </div>
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default formField({ pureRender: true })(Component);
