import React from "react";

import Image from "~/components/Image";

import Style from "./LinkOptions.rules.scss";

export const { NAME, NAME_MESSAGES } = Style;
export const PROP = {
    types: {},
    defaults: {},
};

export class Component extends React.Component {
    state = {
        showOptions: false,
    };

    handleClick = (evt) => {
        evt.preventDefault();
        const { handleClick } = this.props;
        if (handleClick) {
            handleClick();
        } else {
            const { showOptions } = this.state;
            this.setState({ showOptions: !showOptions });
        }
    };

    render() {
        const { text, image, className, handleClick } = this.props;
        const { showOptions } = this.state;
        return (
            <div>
                <a
                    href="#"
                    className={`link ${className && className} ${handleClick && "dropdown"}`}
                    onClick={(evt) => this.handleClick(evt)}>
                    {text}
                    {image && <Image src={image} className="link-option" />}
                </a>
                {showOptions && <div className="options-dropdown">{this.props.children}</div>}
            </div>
        );
    }
}
export default Component;
