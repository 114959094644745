import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import { SelectorsStore as SelectorsStoreComexBondsAndGuarantees } from "~/store/comex/bondsAndGuarantees";
import { SelectorsAction as SelectorsActionFile } from "~/store/files";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import DataDate from "~/components/DataDate";
import DataNumber from "~/components/DataNumber";
import Ticket from "~/components/Ticket/Ticket";
import FormattedAmount from "~/pages/_components/FormattedAmount";

import FormatNumber from "~/pages/wm/funds/_component/FormatNumber";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const { transaction, ticketConfirmation, dispatch } = props;
    const { data } = transaction;

    const {
        formatType,
        application,
        issued,
        internationalIssued,
        informantBank,
        expenditure,
        expenditureOther,
        payerName,
        payerAddress,
        payerContact,
        beneficiaryDocument,
        beneficiaryName,
        beneficiaryAddress,
        beneficiaryCountryId,
        guaranteeObligations,
        guaranteedDocument,
        guaranteedName,
        guaranteedAddress,
        linkWithCompany,
        guaranteeAmount,
        guaranteeAmountCurrency,
        validity,
        dueDate,
        automaticRenewal,
        daysOf,
        notificationDays,
        finalDueDate,
        partialPayments,
        guaranteeType,
        guaranteeTypeOther,
        subjectGuarantee,
        notarialCertification,
        agreedCommission,
        minimunAmount,
        authorized1Document,
        authorized1Name,
        authorized2Document,
        authorized2Name,
        attachments,
        attachmentsNames,
        observations,
    } = data;
    const prefix = "comex.bondsAndGuarantees.requestBondsAndGuarantees";
    const showComission = agreedCommission !== EMPTY_STR;
    const showAmount = minimunAmount !== EMPTY_STR;

    const shareTicket = (idTransactionToRead) => {
        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    };

    const downloadFile = (idFile, fileName) => {
        dispatch(SelectorsActionFile.downloadFileRequest({ idFile, fileName }));
    };

    const fileIds = attachments ? attachments.split(",") : "";

    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}
                    handleModify={handleModify}
                    hasMobileComponent
                    displayDownloadPDF
                    handleDownloadPDF={() => shareTicket(transaction.idTransaction)}>
                    <Box directionColumn align="flex-start" className="mb-3">
                        {formatType && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.formatType.label`)}</h4>
                                <span>{UtilsI18n.get(`${prefix}.formatType.${formatType}`)}</span>
                            </Box>
                        )}
                        {application && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.application.label`)}</h4>
                                <span>{UtilsI18n.get(`${prefix}.application.${application}`)}</span>
                            </Box>
                        )}
                        {issued && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.issued.label`)}</h4>
                                <span>{UtilsI18n.get(`${prefix}.issued.${issued}`)}</span>
                            </Box>
                        )}
                        {issued === "INTERNACIONAL" && (
                            <Box>
                                {internationalIssued && (
                                    <Box className="info-label">
                                        <span />
                                        <span>
                                            {UtilsI18n.get(`${prefix}.internationalIssued.${internationalIssued}`)}
                                        </span>
                                    </Box>
                                )}
                                {informantBank && (
                                    <Box className="info-label">
                                        <h4 className="bold">{UtilsI18n.get(`${prefix}.informantBank.label`)}</h4>
                                        <span>{informantBank}</span>
                                    </Box>
                                )}
                                {expenditure && !expenditureOther && (
                                    <Box className="info-label">
                                        <h4 className="bold">{UtilsI18n.get(`${prefix}.expenditure.label`)}</h4>
                                        <span>{UtilsI18n.get(`${prefix}.expenditure.${expenditure}`)}</span>
                                    </Box>
                                )}
                                {expenditureOther && (
                                    <Box className="info-label">
                                        <h4 className="bold">{UtilsI18n.get(`${prefix}.expenditure.label`)}</h4>
                                        <span>{expenditureOther}</span>
                                    </Box>
                                )}
                            </Box>
                        )}

                        {payerName && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.payerName.label`)}</h4>
                                <span>{payerName}</span>
                            </Box>
                        )}
                        {payerAddress && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.payerAddress.label`)}</h4>
                                <span>{payerAddress}</span>
                            </Box>
                        )}
                        {payerContact && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.payerContact.label`)}</h4>
                                <span>{payerContact}</span>
                            </Box>
                        )}
                        {beneficiaryDocument && beneficiaryDocument.document && (
                            <Box className="info-label">
                                <h4 className="bold">
                                    {UtilsI18n.get(`${prefix}.beneficiaryDocument.documentNumber`)}
                                </h4>
                                <span>{beneficiaryDocument.document}</span>
                            </Box>
                        )}
                        {beneficiaryName && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.beneficiaryName.label`)}</h4>
                                <span>{beneficiaryName}</span>
                            </Box>
                        )}
                        {beneficiaryAddress && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.beneficiaryAddress.label`)}</h4>
                                <span>{beneficiaryAddress}</span>
                            </Box>
                        )}
                        {beneficiaryCountryId && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.beneficiaryCountry`)}</h4>
                                <span>{UtilsI18n.get(`country.name.${beneficiaryCountryId}`)}</span>
                            </Box>
                        )}
                        {guaranteeObligations && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.guaranteeObligations.label`)}</h4>
                                <span>{UtilsI18n.get(`${prefix}.guaranteeObligations.${guaranteeObligations}`)}</span>
                            </Box>
                        )}
                        {guaranteedDocument && guaranteedDocument.document && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.guaranteed.documentNumber`)}</h4>
                                {guaranteedDocument.document}
                            </Box>
                        )}

                        {guaranteedName && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.guaranteedName.label`)}</h4>
                                <span>{guaranteedName}</span>
                            </Box>
                        )}
                        {guaranteedAddress && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.guaranteedAddress.label`)}</h4>
                                <span>{guaranteedAddress}</span>
                            </Box>
                        )}
                        {linkWithCompany && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.linkWithCompany.label`)}</h4>
                                <span>{linkWithCompany}</span>
                            </Box>
                        )}
                        {guaranteeAmount && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.guaranteeAmount.label`)}</h4>
                                <span>
                                    <DataNumber
                                        value={guaranteeAmount}
                                        prefix={UtilsI18n.get(`core.currency.label.${guaranteeAmountCurrency}`)}
                                    />
                                </span>
                            </Box>
                        )}
                        {validity && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.validity.label`)}</h4>
                                <span>{UtilsI18n.get(`${prefix}.validity.${validity}`)}</span>
                            </Box>
                        )}
                        {dueDate && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.dueDate.label`)}</h4>
                                <span>
                                    <DataDate value={dueDate} />
                                </span>
                            </Box>
                        )}
                        <Box className="info-label">
                            <h4 className="bold">{UtilsI18n.get(`${prefix}.automaticRenewal`)}</h4>

                            {automaticRenewal ? (
                                <Box>
                                    <span className="bold">{UtilsI18n.get(`${prefix}.automaticRenewal.yes`)}.</span>
                                    <span>{UtilsI18n.get(`${prefix}.daysOf.except`)}</span>
                                    {daysOf} <span>{UtilsI18n.get(`${prefix}.daysOf.notify`)}</span>
                                </Box>
                            ) : (
                                <span className="bold">{UtilsI18n.get(`${prefix}.automaticRenewal.no`)}</span>
                            )}
                        </Box>

                        {automaticRenewal && notificationDays && (
                            <Box>
                                <Box className="info-label">
                                    <h4 className="bold">{UtilsI18n.get(`${prefix}.notificationDays.label`)}</h4>
                                    <span className="bold">{notificationDays}</span>
                                </Box>
                                <span className="disclaimer-text">
                                    {UtilsI18n.get(`${prefix}.notificationDays.disclaimer`)}
                                </span>
                            </Box>
                        )}
                        {finalDueDate && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.finalDueDate.label`)}</h4>
                                <span>
                                    <DataDate value={finalDueDate} />
                                </span>
                            </Box>
                        )}
                        {partialPayments && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.partialPayments.label`)}</h4>
                                <span>{UtilsI18n.get(`${prefix}.partialPayments.${partialPayments}`)}</span>
                            </Box>
                        )}
                        {guaranteeType && !guaranteeTypeOther && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.guaranteeType.label`)}</h4>
                                <span>{UtilsI18n.get(`${prefix}.guaranteeType.${guaranteeType}`)}</span>
                            </Box>
                        )}
                        {guaranteeTypeOther && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.guaranteeType.label`)}</h4>
                                {guaranteeTypeOther}
                            </Box>
                        )}
                        {subjectGuarantee && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.subjectGuarantee.label`)}</h4>
                                <span>{subjectGuarantee}</span>
                            </Box>
                        )}
                        <Box className="info-label">
                            <h4 className="bold">{UtilsI18n.get(`${prefix}.notarialCertification`)}</h4>

                            {notarialCertification ? (
                                <span>{UtilsI18n.get(`${prefix}.notarialCertification.yes`)}</span>
                            ) : (
                                <span>{UtilsI18n.get(`${prefix}.notarialCertification.no`)}</span>
                            )}
                        </Box>
                        {showComission && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.agreedCommission.label`)}</h4>
                                <span>
                                    <FormatNumber suffix=" %" amount={parseFloat(agreedCommission)} />
                                </span>
                            </Box>
                        )}

                        {showAmount && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.minimunAmount.label`)}</h4>
                                <span>
                                    <FormattedAmount currency="2222" quantity={parseFloat(minimunAmount)} />
                                </span>
                            </Box>
                        )}

                        {authorized1Document && authorized1Document.document && authorized1Document.type !== -1 && (
                            <Box>
                                <Box className="info-label">
                                    <h4 className="bold">{UtilsI18n.get(`${prefix}.authorized1.documentNumber`)}</h4>
                                    <span>{authorized1Document.document}</span>
                                </Box>

                                {authorized1Name && (
                                    <Box className="info-label">
                                        <h4 className="bold">{UtilsI18n.get(`${prefix}.authorized1Name.label`)}</h4>
                                        <span>{authorized1Name}</span>
                                    </Box>
                                )}
                            </Box>
                        )}
                        {authorized2Document && authorized2Document.document && authorized2Document.type !== -1 && (
                            <Box>
                                <Box className="info-label">
                                    <h4 className="bold">{UtilsI18n.get(`${prefix}.authorized2.documentNumber`)}</h4>
                                    <span>{authorized2Document.document}</span>
                                </Box>
                                {authorized2Name && (
                                    <Box className="info-label">
                                        <h4 className="bold">{UtilsI18n.get(`${prefix}.authorized2Name.label`)}</h4>
                                        <span>{authorized2Name}</span>
                                    </Box>
                                )}
                            </Box>
                        )}

                        {attachments && (
                            <Box className="info-label">
                                <Box className="bold"> {UtilsI18n.get(`${prefix}.attachments.heading1`)}</Box>
                                {fileIds.map((fileId, index) => (
                                    <Box className="data-wrapper">
                                        <span className="data-label">
                                            <a onClick={() => downloadFile(fileId, attachmentsNames[index])}>
                                                {attachmentsNames[index]}
                                            </a>
                                        </span>
                                    </Box>
                                ))}
                            </Box>
                        )}

                        {observations && (
                            <Box className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${prefix}.observations.label`)}</h4>
                                <span className="data-date">{observations}</span>
                            </Box>
                        )}
                    </Box>
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { idTransaction } = transaction;
        dispatch(
            SelectorsActionComex.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        dispatch(SelectorsActionComex.signTransactionPreview({ data }));
    }

    function handleModify() {
        const { idTransaction } = transaction;
        dispatch(SelectorsActionForm.modifyTransaction({ idTransactionToMove: idTransaction }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreComexBondsAndGuarantees.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
