import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { TYPE_ADMINISTRATION, NO_SIGNATURE } from "~/constants";
import { SelectorsStore as SelectorsStoreTicket } from "~/store/administration/common/administrationTicket";
import { SelectorsStore as StoreSession } from "~/store/session";

import I18n from "~/components/I18n";

import Style from "./Ticket.rules.scss";

export const NAME = "UserInviteTicket";

export const PROP = {
    types: {
        isFromBackoffice: PropTypes.bool,
        transactionData: PropTypes.shape({
            data: PropTypes.shape({
                email: PropTypes.string,
                firstName: PropTypes.string,
                lastName: PropTypes.string,
                mobilePhone: PropTypes.string,
                role: PropTypes.string,
                signatureLevel: PropTypes.string,
            }),
        }),
    },
    defaults: {
        isFromBackoffice: false,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    renderMediumSchemeConfiguration = () => {
        const {
            transactionData: { data = {} },
        } = this.props;

        const { signatureLevel, roleGroup } = data || {};

        let signatureKeyLabel = "administration.users.invite.transaction.signature.yes";

        if (signatureLevel === NO_SIGNATURE) {
            signatureKeyLabel = "administration.users.invite.transaction.signature.no";
        }

        return (
            <React.Fragment>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="administration.users.invite.transaction.signature" />
                    </div>
                    <div>
                        <I18n id={signatureKeyLabel} />
                    </div>
                </div>
                {roleGroup && roleGroup.length > 0 && (
                    <div className="data-wrapper">
                        <div className="data-label">
                            <I18n id="administration.users.invite.roles.label" />
                        </div>
                        {roleGroup.map((id) => (
                            <div>
                                <I18n id={`invitation.permissions.role.${id}`} />
                            </div>
                        ))}
                    </div>
                )}
            </React.Fragment>
        );
    };

    render() {
        const {
            isFromBackoffice,
            activeEnvironment,
            transactionData: { data = {} },
        } = this.props;
        const { administrationScheme } = activeEnvironment || {};
        const { email, firstName, lastName } = data || {};

        return (
            <section>
                <div id={isFromBackoffice && Style.ID}>
                    <div className="data-wrapper">
                        <div className="data-label">
                            <I18n id="administration.users.invite.firstName.label" />
                        </div>
                        <div>{firstName}</div>
                    </div>
                    <div className="data-wrapper">
                        <div className="data-label">
                            <I18n id="administration.users.invite.lastName.label" />
                        </div>
                        <div>{lastName}</div>
                    </div>
                    <div className="data-wrapper">
                        <div className="data-label">
                            <I18n id="administration.users.invite.email.label" />
                        </div>
                        <div>{email}</div>
                    </div>
                    {administrationScheme === TYPE_ADMINISTRATION.MEDIUM && this.renderMediumSchemeConfiguration()}
                </div>
            </section>
        );
    }
}

const mapStateToProps = (store) => ({
    activeEnvironment: StoreSession.getActiveEnvironment(store),
    transactionData: SelectorsStoreTicket.getData(store),
});

export default Connect(mapStateToProps)(Component);
