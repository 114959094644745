import React from "react";

export const NAME = "AccordionItemContent";

export const PROP = {
    types: {},
    defaults: {},
};
export function Component(props) {
    const { isActive, children } = props;

    return (
        <div className={`form-group ${isActive ? "" : "hide"}`}>
            <div className="form-group-control-list">{children}</div>
        </div>
    );
}

export default Component;
