import React from "react";

import { push as Push } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/Filter";
import { SelectorsAction } from "~/store/investment";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";

import Style from "./RiskProfile.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...TypesRedux,
        fetching: PropTypes.bool,
        dispatch: PropTypes.func.isRequired,
        values: PropTypes.object.isRequired,
        setFieldValue: PropTypes.func.isRequired,
    },
    defaults: {
        ...DefaultsRedux,
        fetching: false,
    },
};
export class Component extends React.Component {
    componentDidMount() {
        const { dispatch, activeEnvironment } = this.props;
        if (activeEnvironment.environmentData.hasRiskProfile) {
            dispatch(Push("/investment/riskProfileResume"));
        }
    }

    handleClick = () => {
        const { dispatch } = this.props;
        dispatch(Push("/investment/riskProfile2"));
    };

    handleBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    handleDownload = (e) => {
        e.preventDefault();
        const { dispatch } = this.props;

        dispatch(SelectorsAction.riskProfileDownloadPDF());
    };

    render() {
        return (
            <React.Fragment>
                <Container
                    name={NAME}
                    head-onBack={this.handleBack}
                    head-title="wm.riskProfile.title"
                    image="businessPortfolio.svg"
                    className="position"
                    scopeToShowNotification={SCOPE.RISK_PROFILE_STEP_1}>
                    <section id={Style.ID}>
                        <Box className="container-image only-mobile">
                            <Image src="riskProfile/wmRiskProfile2.jpg" />
                        </Box>
                        <Box className="main-information-risk">
                            <I18n className="text-risk" id="wm.riskProfile.information" />
                            <Box className="risk-profile-button">
                                <Button type="button" size="md" variant="primary" onClick={this.handleClick}>
                                    <I18n id="wm.riskProfile.submit" />
                                </Button>
                            </Box>
                        </Box>
                        <Box className="form--personal_information">
                            <Box className="container-image only-web">
                                <Image src="riskProfile/wmRiskProfile2.jpg" />
                            </Box>
                            <Box className="container--data">
                                <I18n className="list-risk-title" id="wm.riskProfile.infoTitle" />
                                <ul>
                                    <li className="list-risk">
                                        <I18n id="wm.riskProfile.list.item.1" />
                                    </li>
                                    <li className="list-risk">
                                        <I18n id="wm.riskProfile.list.item.2" />
                                    </li>
                                    <li className="list-risk">
                                        <I18n id="wm.riskProfile.list.item.3" />
                                    </li>
                                    <li className="list-risk">
                                        <I18n id="wm.riskProfile.list.item.4" />
                                    </li>
                                    <li className="list-risk">
                                        <I18n id="wm.riskProfile.list.item.5" />
                                    </li>
                                    <li className="list-risk">
                                        <I18n id="wm.riskProfile.list.item.6" />
                                    </li>
                                    <li className="list-risk">
                                        <I18n id="wm.riskProfile.list.item.7" />
                                    </li>
                                    <li className="list-risk">
                                        <I18n id="wm.riskProfile.list.item.8" />
                                    </li>
                                    <li className="list-risk">
                                        <I18n id="wm.riskProfile.list.item.9" />
                                    </li>
                                    <li className="list-risk">
                                        <I18n id="wm.riskProfile.list.item.10" />
                                    </li>
                                    <li className="list-risk">
                                        <I18n id="wm.riskProfile.list.item.11" />
                                    </li>
                                    <li className="list-risk">
                                        <I18n id="wm.riskProfile.list.item.12" />
                                    </li>
                                </ul>
                            </Box>
                        </Box>
                        <Box className="link-risk-pdf">
                            <Link onClick={(e) => this.handleDownload(e)} to="#">
                                <I18n id="wm.riskProfile.link" />
                            </Link>
                        </Box>
                    </section>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
});

export default Connect(mapStateToProps)(Component);
