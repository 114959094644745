import React from "react";

import { Types as TypesHtmlElement, Defaults as DefaultsHtmlElement } from "~/util/prop/html-element";

import I18n from "~/components/I18n";
import Image from "~/components/Image";

export const NAME = "DefaultContent";

export const PROP = {
    types: { ...TypesHtmlElement },
    defaults: { ...DefaultsHtmlElement },
};
/**
 * Renders the default content for the external header mobile, Logo and Bank name.
 */

export function Component() {
    return (
        <div className="enrollment-header-logo">
            <Image src="logo-hsbc.svg" className="enrollment-header-logo-icon" />
            <span className="enrollment-header-logo-text">
                <I18n id="global.bank.name" defaultValue="HSBC" />
            </span>
        </div>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
