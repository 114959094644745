import React from "react";

import { goBack as GoBack } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";

import { ID_FORM, SCOPE } from "~/constants";
import { MODE, GENERIC } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import Container from "~/containers/Internal/Form/Steps";
import { SelectorsStore as SelectorsStoreForm, SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsStore as SelectorsStoreTransfers } from "~/store/transfers";
import * as I18nUtils from "~/util/i18n";

import FormWaiter from "~/pages/forms/_components/FormWaiter";

import FormEdit from "./Step1Edit/Step1Edit";
import FormPreview from "./Step2Preview/Step2Preview";
import FormView from "./Step3View/Step3View";

export const NAME = "Transaction";

export const PROP = {
    types: {
        isFetching: PropTypes.bool,
    },
    defaults: {
        isFetching: false,
    },
};

export function Component(props) {
    const {
        isFetching,
        mode,
        transaction: { idTransactionStatus },
        prevMode,
        isWaitingBiometry,
    } = props;
    const hideSteps =
        mode === MODE.VIEW && (idTransactionStatus === STATUS.PENDING || idTransactionStatus === STATUS.SCHEDULED);

    return (
        <React.Fragment>
            <Container
                name={NAME}
                wait={isFetching}
                step={mode}
                head-onBack={handleBack}
                head-title={getTitle()}
                hideSteps={hideSteps}
                scopeToShowNotification={SCOPE.FORM}>
                {isWaitingBiometry && <FormWaiter />}
                {!isWaitingBiometry && (
                    <React.Fragment>
                        {mode === MODE.EDIT && <FormEdit />}
                        {mode === MODE.PREVIEW && <FormPreview />}
                        {mode === MODE.VIEW && <FormView />}
                    </React.Fragment>
                )}
            </Container>
        </React.Fragment>
    );
    function handleBack() {
        const { dispatch } = props;
        if (prevMode && prevMode !== mode && mode !== MODE.VIEW) {
            dispatch(SelectorsActionForm.closeConfirmation());
        } else {
            dispatch(GoBack());
        }
    }

    function getTitle() {
        const { transaction, currentLang, id, values = {} } = props;
        const { formNameMap } = props.metadata;
        let title = formNameMap[currentLang];
        if (id === ID_FORM.TRANSFER_LOCAL) {
            const { checkboxExpress } = values;
            if (checkboxExpress && checkboxExpress.length > 1) {
                title = I18nUtils.get("transfer.express.labelExpress");
            } else {
                title = I18nUtils.get("forms.transferLocal.formName");
            }
        } else if (id === GENERIC) {
            const { activityName } = transaction;
            title = activityName;
        }
        return title;
    }
}

const mapStateToProps = (store) => {
    const { needsBiometric } = SelectorsStoreSession.getUser(store) || {};
    const isWaitingBiometry = needsBiometric && SelectorsStoreForm.getWaitingForResponse(store);
    return {
        isWaitingBiometry,
        isFetching: SelectorsStoreForm.getFetching(store) || SelectorsStoreTransfers.fetching(store),
        mode: SelectorsStoreForm.getMode(store),
        prevMode: SelectorsStoreForm.getPrevMode(store),
        transaction: SelectorsStoreForm.getTransaction(store),
        metadata: SelectorsStoreForm.getMetadata(store),
        currentLang: SelectorsStoreI18n.getLang(store),
    };
};

export default Compose(Connect(mapStateToProps))(Component);
