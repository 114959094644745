/** The character used to separate props when used in components */
export const SEP = "-";

/**
 * Makes all properties on given propTypes required via adding the "isRequired" to its chain.
 *
 * @param {Object} propTypes - The propTypes which will have its properties converted to isRequired
 * @param {array} only - An array of keys to only validate against.
 */
export const ToRequired = (propTypes, only = []) =>
    Object.entries(propTypes)
        // If an "only" array is sent, validate its key to make sure they're on the propTypes object
        .filter(([key]) => !Array.isArray(only) || !only.length || only.indexOf(key) !== -1)
        // for every propType marked as modifiable, inject the "isRequired" chainabale method.
        .reduce((acc, [key, val]) => ({ ...acc, [key]: val.isRequired }), propTypes);

/**
 * Obtains values from a set of other object's properties.
 *
 * @param {Object} from - The object to extract keys from.
 * @param {Object} props - The props to extract values from.
 * @returns {Object} The matching object.
 */
export const FromKeys = (from, props) => Object.keys(from).reduce((acc, key) => ({ ...acc, [key]: props[key] }), {});

/**
 * Categorizes properties that share the same prefix.
 *
 * @param {Object} props -  An props object.
 * @param {string?} SEP - The seprarator used to categorize.
 *
 * @return {Object} A new prop object with prefixed members.
 */
export function Categorize(props, sep = SEP) {
    return Object.entries(props).reduce((acc, [key, val]) => {
        if (key.indexOf(sep) === -1) {
            return { ...acc, [key]: val };
        }
        const [target, ...parts] = key.split(sep);
        return {
            ...acc,
            [target]: {
                ...(acc[target] || {}),
                [parts.join(sep)]: val,
            },
        };
    }, {});
}

/**
 * Adds a prefix to all the keys in a Props object.
 * @param {string} prefix - The prefix to prepend to each property key name.
 * @param {Object} props - The target props to prefix.
 * @param {Array<string>?} filter - props that won't be included on resulting object.
 *
 * @return {Object} The target object with each key prefixed.
 */
export function Prefix(prefix, props = {}, filter = [], sep = SEP) {
    return Object.entries(props).reduce((acc, [key, val]) => {
        if (filter.length) {
            const found = filter.some((rx) => new RegExp(rx).test(key));
            if (found) {
                return acc;
            }
        }
        if (filter.indexOf(key) !== -1) {
            return acc;
        }
        const target = [prefix, key].join(sep);
        return {
            ...acc,
            [target]: val,
        };
    }, {});
}

/**
 *  Returns a consistent Error when validating props.
 *
 * @param {string} message - The message to show to the user.
 * @param {array} params - The parameters received from the prop validator.
 */
export const PropError = (message, params) => {
    const [propName, componentName] = params.slice(1);
    const error = `Invalid prop '${propName}' supplied to '${componentName}'. ${message}`;
    return new Error(error);
};

export default {
    toRequired: ToRequired,
    fromKeys: FromKeys,
};
