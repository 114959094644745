import React from "react";

import { withFormik, Form, Field } from "formik";
import PropTypes from "prop-types";
import { Grid, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import * as Yup from "yup";

import { SelectorsAction as SelectorsActionSettings, SelectorsStore as SelectorsStoreSettings } from "~/store/settings";
import * as i18n from "~/util/i18n";

import I18n from "~/components/I18n";
import Head from "~/pages/_components/Head";
import MainContainer from "~/pages/_components/MainContainer";
import Credential from "~/pages/_components/fields/credentials/Credential";

export const NAME = "ChangeSecuritySealConfirmation";

export const PROP = {
    types: {
        selectedSecuritySeal: PropTypes.shape({
            id: PropTypes.string.isRequired,
            securitySeal: PropTypes.string.isRequired,
        }).isRequired,
        dispatch: PropTypes.func.isRequired,
    },
    defaults: {},
};

const FORM_ID = "settings.changeSecuritySeal";
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    back = (event) => {
        event.preventDefault();

        const { dispatch } = this.props;
        dispatch(SelectorsActionSettings.changeSecuritySealConfirmationBack());
    };

    render() {
        const { selectedSecuritySeal } = this.props;

        return (
            <React.Fragment>
                <Head title="settings.changeSecuritySeal.confirmation" closeLinkTo="/settings" />
                <MainContainer>
                    <Form className="above-the-fold">
                        <section className="container--layout flex-grow align-items-center">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                        <div className="security-image">
                                            <div className="image">
                                                <figure>
                                                    <img
                                                        src={selectedSecuritySeal.securitySeal}
                                                        alt={selectedSecuritySeal.id}
                                                    />
                                                </figure>
                                            </div>
                                            <div>
                                                <p className="text-lead">
                                                    <I18n id="settings.changeSecuritySeal.confirmation.title" />
                                                </p>
                                                <Button className="btn-outline" block onClick={this.back}>
                                                    <I18n id="settings.changeSecuritySeal.confirmation.back" />
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                        <section className="container--layout align-items-center">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                        <hr />
                                        <h4>
                                            <I18n id="settings.changeSecuritySeal.verifyWithCredential" />
                                        </h4>
                                        <Field name="pin" type="pin" idForm={FORM_ID} component={Credential} />
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                        <section className="container--layout">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                        <Button type="submit" bsStyle="primary" block onClick={this.send}>
                                            <I18n id="global.modify" />
                                        </Button>
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </Form>
                </MainContainer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    errors: SelectorsStoreSettings.getErrors(state),
    selectedSecuritySeal: {
        id: SelectorsStoreSettings.getSelectedSecuritySealId(state),
        securitySeal: SelectorsStoreSettings.getSelectedSecuritySeal(state),
    },
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: (props) => ({
            pin: "",
            selectedSecuritySeal: props.selectedSecuritySeal,
        }),
        validationSchema: () =>
            Yup.object().shape({
                pin: Yup.string().required(i18n.get(`${FORM_ID}.pin.required`)),
            }),
        handleSubmit: ({ pin, selectedSecuritySeal }, formikBag) => {
            formikBag.props.dispatch(
                SelectorsActionSettings.changeSecuritySealConfirmation({
                    securitySeal: selectedSecuritySeal.id,
                    pin,
                    formikBag,
                }),
            );
        },
    }),
)(Component);
