import React from "react";

import { push as Push, goBack as GoBack } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import ContainerSteps from "~/containers/Internal/Form/Steps";
import { SelectorsStore as SelectorsStoreTransfers } from "~/store/transfers";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import HighOrder from "~/components/HighOrder";

import FormPreview from "./Step2Preview/Step2Preview";
import FormView from "./Step3View/Step3View";

export const NAME = "AliasEnrollment";

export const PROP = {
    types: {
        isFetching: PropTypes.bool,
        mode: PropTypes.string.isRequired,
        ...TypesRedux,
    },
    defaults: {
        isFetching: false,
        ...DefaultsRedux,
    },
};

export function Component({ dispatch, isFetching, mode }) {
    React.useEffect(() => {
        if (mode === MODE.EDIT) {
            dispatch(Push("/transfers/aliasAdministration"));
        }
    }, [dispatch, mode]);

    function handleBack() {
        if (mode === MODE.PREVIEW) {
            dispatch(Push("/transfers/aliasAdministration"));
        } else {
            dispatch(GoBack());
        }
    }

    return (
        <ContainerSteps
            name={NAME}
            wait={isFetching}
            step={mode}
            head-onBack={(mode !== MODE.PREVIEW && mode !== MODE.PREVIEW && handleBack) || undefined}
            head-onBackWeb={mode === MODE.PREVIEW && handleBack}
            head-title={UtilsI18n.get("transfers.alias.deletion.title")}
            scopeToShowNotification={SCOPE.TRANSFERS_ALIAS_ADMINISTRATION}>
            <React.Fragment>
                {mode === MODE.PREVIEW && <FormPreview />}
                {mode === MODE.VIEW && <FormView />}
            </React.Fragment>
        </ContainerSteps>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    isFetching: SelectorsStoreTransfers.fetching(store),
    mode: SelectorsStoreTransfers.getMode(store),
});

export default HighOrder(Connect(mapStateToProps))(Component);
