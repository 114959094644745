import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, AMOUNT_USD, OPTION_PAY_CREDIT_CARD } from "~/constants";
import { SelectorsStore, SelectorsAction } from "~/store/creditCards/purchaseNotification";
import * as I18nUtils from "~/util/i18n";
import UtilLodash from "~/util/lodash";
import * as numberUtils from "~/util/number";

import HighOrder from "~/components/HighOrder";
import NumberFormatInput from "~/components/NumberFormatInput";
import Select from "~/components/Select";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import WithFocus from "~/pages/_components/withFocus";

import FormField from "~/pages/forms/_components/_fields/_commons/formField";

import CustomLabel from "./CustomLabel";

const INPUT_REGEX_REPLACE = /[^0-9.,]/g;

const members = {
    data: {
        options: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })).isRequired,
        decimalSeparator: PropTypes.string.isRequired,
        precision: PropTypes.number.isRequired,
        thousandsSeparator: PropTypes.string.isRequired,
    },
};
export const NAME = "PayCreditCardAmount";

export const PROP = {
    types: {
        editing: PropTypes.bool,
        data: PropTypes.shape(members.data).isRequired,
        focus: PropTypes.bool.isRequired,
        idCreditCardSelector: PropTypes.string.isRequired,
        label: PropTypes.string,
        optionalMessage: PropTypes.string,
        isRequired: PropTypes.bool,
        dispatch: PropTypes.func,
    },
    defaults: { editing: false, label: EMPTY_STR, optionalMessage: EMPTY_STR, isRequired: false, dispatch: () => {} },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    quantityRef = null;

    state = {
        specificAmount: false,
        selectorValue: null,
        loadData: true,
    };

    componentDidMount() {
        const { editing, data, focus, dispatch } = this.props;
        if (editing) {
            this.handleCurrencyChange(data.options[0]);
        } else {
            const [firstOption] = this.paymentOptions();
            this.setState({ selectorValue: firstOption });
        }

        // the first field of the form is usually passed focus on true
        if (this.quantityRef && focus) {
            this.quantityRef.focus();
        }
        dispatch(SelectorsAction.notChangeCreditcard());
    }

    componentDidUpdate() {
        const { setValue, value } = this.props;
        const { selectorValue } = this.state;
        if (!selectorValue) {
            if (value && value.quantity !== undefined) {
                const paymentOptions = this.paymentOptions();
                const selectedPayment = paymentOptions.find(
                    ({ value: valuePayment }) => valuePayment.selectedPaymentOption === value.selectedPaymentOption,
                );
                this.setState({ selectorValue: { value, label: selectedPayment.label } });
            } else {
                const [firstOption] = this.paymentOptions();
                this.setState({ selectorValue: firstOption });
                setValue(firstOption.value);
            }
        }
    }

    getData = () => {
        const { idCreditCardSelector, form, fieldList } = this.props;
        const selectedCreditCard = form.values[idCreditCardSelector].value || form.values[idCreditCardSelector];

        const {
            data: { options },
        } = fieldList.find(({ idField }) => idField === idCreditCardSelector) || {};

        return options.find(({ id }) => id === selectedCreditCard) || {};
    };

    handleCurrencyChange = ({ id }) => {
        const {
            setValue,
            value,
            data: { creditCardList },
        } = this.props;
        const { id: idCred } = this.getData();
        const amount = creditCardList.find(({ idCreditCard }) => idCreditCard === idCred) || {};

        const { paymentUYU, minimunPaymentUYU, paymentUSD, minimunPaymentUSD } = amount;

        const minimumPayment = minimunPaymentUSD !== undefined ? minimunPaymentUSD : minimunPaymentUYU;
        const totalPayment = paymentUSD !== undefined ? paymentUSD : paymentUYU;

        let quantity;
        if (value) {
            if (value.selectedPaymentOption === OPTION_PAY_CREDIT_CARD.SPE) {
                quantity = value.quantity;
            } else if (value.selectedPaymentOption === OPTION_PAY_CREDIT_CARD.TOTAL) {
                quantity = totalPayment;
            } else if (value.selectedPaymentOption === OPTION_PAY_CREDIT_CARD.MIN) {
                quantity = minimumPayment;
            } else {
                quantity = 0;
            }
        } else {
            quantity = minimumPayment;
        }
        const selectedPaymentOption = value ? value.selectedPaymentOption : OPTION_PAY_CREDIT_CARD.MIN;

        setValue({
            currency: id,
            quantity,
            selectedPaymentOption,
        });
    };

    handleQuantityChange = () => {
        const {
            data: { decimalSeparator, precision },
            value,
            setValue,
        } = this.props;

        let quantity = this.quantityRef.value.replace(INPUT_REGEX_REPLACE, EMPTY_STR);
        quantity = numberUtils.toNumber(quantity, decimalSeparator, precision);
        const selectedPaymentOption = OPTION_PAY_CREDIT_CARD.SPE;

        const amount = {
            currency: value.currency,
            quantity,
            selectedPaymentOption,
        };
        this.setState({ selectorValue: { value: amount, label: I18nUtils.get("paycreditcardamount.specificAmount") } });
        setValue(amount);
    };

    paymentOptions = () => {
        const {
            data: { creditCardList, options },
        } = this.props;
        const { id } = this.getData();

        const amount = creditCardList.find(({ idCreditCard }) => idCreditCard === id) || {};

        const { paymentUYU, minimunPaymentUYU, paymentUSD, minimunPaymentUSD } = amount;
        const currency = options[0] ? options[0].id : 0;

        const minPayment = minimunPaymentUSD !== undefined ? minimunPaymentUSD : minimunPaymentUYU;
        const payment = paymentUSD !== undefined ? paymentUSD : paymentUYU;
        const { lang } = this.props;

        const minPaymentQty =
            numberUtils.numberToLocaleFormat(minPayment, lang) !== EMPTY_STR
                ? numberUtils.numberToLocaleFormat(minPayment, lang)
                : "0";
        const totalPaymentQty =
            numberUtils.numberToLocaleFormat(payment, lang) !== EMPTY_STR
                ? numberUtils.numberToLocaleFormat(payment, lang)
                : "0";
        const label = options[0] ? options[0].label : EMPTY_STR;
        const minimunPayment = `${I18nUtils.get(
            "paycreditcardamount.minimumPaymentLabel",
        )} (${label} ${minPaymentQty})`;
        const totalPayment = `${I18nUtils.get("paycreditcardamount.totalPaymentLabel")} (${label} ${totalPaymentQty})`;
        return [
            {
                value: {
                    currency,
                    quantity: minPayment,
                    selectedPaymentOption: OPTION_PAY_CREDIT_CARD.MIN,
                },
                label: minimunPayment,
            },
            {
                value: {
                    currency,
                    quantity: payment,
                    selectedPaymentOption: OPTION_PAY_CREDIT_CARD.TOTAL,
                },
                label: totalPayment,
            },
            {
                value: {
                    currency,
                    quantity: 0,
                    selectedPaymentOption: OPTION_PAY_CREDIT_CARD.NO,
                },
                label: I18nUtils.get("paycreditcardamount.noLabel"),
            },
            {
                value: {
                    currency,
                    quantity: 0,
                    selectedPaymentOption: OPTION_PAY_CREDIT_CARD.SPE,
                },
                label: I18nUtils.get("paycreditcardamount.specificAmount"),
            },
        ];
    };

    handlePaymentOptionChange = (option) => {
        if(option){
            this.setState({
                specificAmount: option.value.selectedPaymentOption === OPTION_PAY_CREDIT_CARD.SPE,
                selectorValue: option,
            });
            const {
                value: { currency, quantity, selectedPaymentOption },
            } = option;
    
            const { setValue } = this.props;
    
            setValue({
                currency,
                quantity,
                selectedPaymentOption,
            });
        }
    };

    getSelectedPayment = (paymentOptions, selectorValue) => {
        return (
            paymentOptions.find(
                ({ value: valuePayment }) =>
                    selectorValue && valuePayment.selectedPaymentOption === selectorValue.value.selectedPaymentOption,
            ) || paymentOptions[0]
        );
    };

    changePayments = (selectedPayment) => {
        const { setValue, dispatch, idField } = this.props;
        this.setState({
            selectorValue: selectedPayment,
            specificAmount: selectedPayment.value.selectedPaymentOption === "specify",
        });
        setValue(selectedPayment.value);
        if (idField === AMOUNT_USD) {
            dispatch(SelectorsAction.notChangeCreditcard());
        }
    };

    setInitialValue = (selectedPaymentOption) => {
        let selectorValue = null;
        let valueAux = null;
        this.setState({ loadData: false });
        switch (selectedPaymentOption) {
            case OPTION_PAY_CREDIT_CARD.MIN:
                selectorValue = this.paymentOptions()[0];
                valueAux = selectorValue;
                this.setState({ selectorValue, specificAmount: false });
                break;
            case OPTION_PAY_CREDIT_CARD.TOTAL:
                selectorValue = this.paymentOptions()[1];
                valueAux = selectorValue;
                this.setState({ selectorValue });
                break;
            case OPTION_PAY_CREDIT_CARD.NO:
                selectorValue = this.paymentOptions()[2];
                valueAux = selectorValue;
                this.setState({ selectorValue });
                break;
            case OPTION_PAY_CREDIT_CARD.SPE:
                selectorValue = this.paymentOptions()[3];
                valueAux = selectorValue;
                this.setState({ selectorValue, specificAmount: true });
                break;
            default:
                break;
        }
        return valueAux;
    };

    render() {
        const {
            editing,
            value,
            placeholder,
            data: { options, decimalSeparator, precision, thousandsSeparator },
            onBlur,
            changeCreditCard,
        } = this.props;
        if (!editing) {
            return <FormattedAmount {...value} />;
        }

        const selectedCurrency = value ? value.currency : EMPTY_STR;
        const selectedQuantity = value && value.quantity ? value.quantity : 0;
        const selectedPaymentOption = value ? value.selectedPaymentOption : null; // Si viene con el valor desde un borrador o una plantilla
        const { specificAmount, loadData } = this.state;
        const { selectorValue } = this.state;
        let valueAux = null;
        const paymentOptions = this.paymentOptions();
        const selectedPayment = this.getSelectedPayment(paymentOptions);

        if (changeCreditCard && !UtilLodash.isEqual(selectorValue, selectedPayment)) {
            this.changePayments(selectedPayment);
            valueAux = selectedPayment;
        } else {
            if (value && loadData && selectedPaymentOption !== OPTION_PAY_CREDIT_CARD.MIN) {
                // solamente lo hago una vez y si no es el valor inicial
                valueAux = this.setInitialValue(selectedPaymentOption);
            } else if (
                value &&
                !loadData &&
                selectorValue.value.selectedPaymentOption !== value.selectedPaymentOption
            ) {
                valueAux = this.setInitialValue(value.selectedPaymentOption);
            }
            valueAux = selectorValue;
        }
        if (editing) {
            return (
                <React.Fragment>
                    <div className="input-group">
                        <Select
                            className="slideFromBottom flex-container"
                            name="currency"
                            onChange={this.handlePaymentOptionChange}
                            value={valueAux}
                            labelKey="label"
                            options={this.paymentOptions()}
                            clearable={false}
                        />
                    </div>

                    {specificAmount && (
                        <div className="input-group input-group-paycreditcardamount">
                            {options.length === 1 ? (
                                <span className="currency">{options[0].label}</span>
                            ) : (
                                <Select
                                    className="currency-selector slideFromBottom flex-container"
                                    name="currency"
                                    searchable={false}
                                    onChange={this.handleCurrencyChange}
                                    value={selectedCurrency}
                                    valueKey="id"
                                    labelKey="label"
                                    options={options}
                                    clearable={false}
                                />
                            )}
                            <NumberFormatInput
                                name="quantity"
                                className="form-control text-right"
                                type="tel"
                                onBlur={onBlur}
                                onChange={this.handleQuantityChange}
                                maxLength={20}
                                value={selectedQuantity}
                                placeholder={placeholder}
                                getInputRef={(ref) => {
                                    this.quantityRef = ref;
                                }}
                                onFocus={(e) => e.target.select()}
                                decimalScale={precision}
                                thousandSeparator={thousandsSeparator}
                                decimalSeparator={decimalSeparator}
                                fixedDecimalScale
                            />
                        </div>
                    )}
                </React.Fragment>
            );
        }

        // fallback
        return null;
    }
}

const mapStateToProps = (store) => ({
    changeCreditCard: SelectorsStore.getChangeCreditcard(store),
});

export default HighOrder(
    WithFocus,
    Connect(mapStateToProps),
    FormField({ formClass: "form-group--composite", CustomLabel }),
)(Component);
