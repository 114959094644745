import { call as Call, delay as Delay, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import { EMPTY_STR, RESPONSE_TYPE } from "~/constants";
import Store from "~/store";
import { E_SSL_HANDSHAKE } from "~/store/apiMobile";
import { TYPE as TYPES_APP } from "~/store/app";
import * as UtilsConfig from "~/util/config";
import Logger from "~/util/logger";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsMiddleware, SelectorsStore } from "./_selectors";

const sagas = [
    TakeLatest(TYPE.SET_INIT_LANG, update),
    TakeLatest(TYPE.SET_LANG, changeLanguage),
    TakeLatest(TYPES_APP.INIT, checkInitialLang),
];

export default sagas;

function* checkInitialLang() {
    const initialLang = SelectorsStore.getLang(Store.getState());

    if (initialLang) {
        yield Call(update, { lang: initialLang });
    } else {
        const defaultLang = UtilsConfig.get("rubicon.defaultLanguage");

        yield Put(SelectorsAction.setInitLang({ lang: defaultLang }));
    }
}

function* changeLanguage({ lang, etag }) {
    try {
        yield Put(SelectorsAction.updateRequest());

        const response = yield Call(SelectorsMiddleware.listMessages, lang, etag);

        if (response.status !== RESPONSE_TYPE.NOT_MODIFIED) {
            const { data } = response.data;
            const etagResponse = response.headers.etag;

            yield Put(
                SelectorsAction.updateSuccess({
                    etagResponse,
                    items: data,
                    lang,
                }),
            );
        }
    } catch (err) {
        // SSL_PINNING error is detected and thrown to be caught by the generic saga's error handler
        if (err.request && err.code === E_SSL_HANDSHAKE) {
            throw err;
        }

        yield Put(SelectorsAction.updateFailure());
    }
}

function* update({ lang, etag }) {
    while (true) {
        try {
            yield Put(SelectorsAction.updateRequest());

            const response = yield Call(SelectorsMiddleware.listMessages, lang, etag);

            if (response.status !== RESPONSE_TYPE.NOT_MODIFIED) {
                const { data } = response.data;
                const etagResponse = response.headers.etag;

                yield Put(
                    SelectorsAction.updateSuccess({
                        etagResponse,
                        items: data,
                        lang,
                    }),
                );
            }

            yield Delay(UtilsConfig.getTimeInMillis("frontend.i18n.refresh.interval", 2000));
        } catch (err) {
            Logger.error(err, EMPTY_STR);

            yield Put(SelectorsAction.updateFailure());

            yield Delay(2000);
        }
    }
}
