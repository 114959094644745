import React from "react";

import Container from "~/containers/External/Form";
import { Types as TypesHtmlElement, Defaults as DefaultsHtmlElement } from "~/util/prop/html-element";

import I18n from "~/components/I18n";

export const NAME = "DepositGuarrantee";

export const PROP = {
    types: {
        ...TypesHtmlElement,
    },
    defaults: {
        ...DefaultsHtmlElement,
    },
};

/**
 * Renders deposit guarantee.
 * @param {PROP.types} props
 */

export function Component(props) {
    const onHeaderClose = () => {
        const { history } = props;
        history.goBack();
    };

    return (
        <Container name={NAME} head-onBack={onHeaderClose} head-title="depositGuarantee.title">
            <div className="enrollment-content">
                <div className="enrollment-content-background" />
                <div className="terms">
                    <div className="terms-block">
                        <div className="contract-block-content">
                            <I18n id="depositGuarantee.body" />
                        </div>
                    </div>
                </div>
                <div className="enrollment-content-dialog">
                    <span className="enrollment-content-dialog-text" />
                </div>
            </div>
        </Container>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
