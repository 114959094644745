import React from "react";

import { Field, Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import queryString from "query-string";
import { connect as Connect } from "react-redux";
import * as Yup from "yup";

import { CREDENTIAL_TYPE_OTP, EMPTY_STR, EXTERNAL_PAYMENTS } from "~/constants";
import { SelectorsAction as SelectorsActionCreditCards } from "~/store/creditCards/creditCard";
import { SelectorsAction as ExternalPaymentsActions } from "~/store/externalPayments";
import {
    SelectorsAction as SelectorsActionFingerPrint,
    SelectorsStore as SelectorsStoreFingerPrint,
} from "~/store/fingerprint";
import { SelectorsAction as LoginActions, SelectorsStore as LoginSelectors } from "~/store/login";
import DeviceUtils from "~/util/device";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import TextField from "~/pages/_components/fields/TextField";
import Captcha from "~/pages/_components/fields/credentials/Captcha";
import Credential from "~/pages/_components/fields/credentials/Credential";

const FORM_ID = "login.step1";

export const NAME = "Step1Content";

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        fingerprintAvailable: PropTypes.shape({
            isHardwareDetected: PropTypes.bool,
            hasEnrolledFingerprints: PropTypes.bool,
            isAvailable: PropTypes.bool,
        }),
        isSubmitting: PropTypes.bool,
        className: PropTypes.string,
        showCaptcha: PropTypes.bool,
        isTrusted: PropTypes.bool.isRequired,
        userPersonalName: PropTypes.string,
        footer: PropTypes.node,
        disabledButton: PropTypes.bool,
    },
    defaults: {
        fingerprintAvailable: null,
        isSubmitting: false,
        className: EMPTY_STR,
        showCaptcha: false,
        userPersonalName: null,
        footer: null,
        disabledButton: true,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        fingerprintInitialized: false,
    };

    componentDidMount() {
        const { dispatch, location, setFieldValue } = this.props;
        const { fingerprintInitialized } = this.state;

        const isMobileNative = DeviceUtils.isMobileNative();

        const queryObject = queryString.parse(location.search);

        const query = location.search;
        const { pathname } = location;

        let params = {
            ...queryObject,
            isExternalLogin: true,
            queryString: query,
        };

        if (pathname.match(EXTERNAL_PAYMENTS.SISTARBANC)) {
            params = {
                ...params,
                isFromSistarbanc: true,
            };
            dispatch(ExternalPaymentsActions.setParams(params));
        } else if (pathname.match(EXTERNAL_PAYMENTS.BEVSA)) {
            params = {
                ...params,
                isFromBevsa: true,
            };
            dispatch(ExternalPaymentsActions.setParams(params));
        } else {
            dispatch(ExternalPaymentsActions.cleanParams(params));
        }

        dispatch(LoginActions.cleanUp());
        dispatch(SelectorsActionCreditCards.cleanUp());

        if (isMobileNative) {
            dispatch(SelectorsActionFingerPrint.fingerprintAvailability());
        } else if (params.username) {
            setFieldValue("username", params.username);
            document.getElementById("otp").focus();
        }

        if (isMobileNative && !fingerprintInitialized) {
            this.setState({ fingerprintInitialized: true }, function fingerprintLoginPre() {
                dispatch(LoginActions.fingerprintLoginPre());
            });
        }

        if (isMobileNative) {
            navigator.splashscreen.hide();
        }
    }

    render() {
        const { isSubmitting, showCaptcha, className, userPersonalName, isTrusted, disabledButton, location } =
            this.props;
        const isMobileNative = DeviceUtils.isMobileNative();

        return (
            <React.Fragment>
                <div className="login-header">
                    <span className="login-header-text">
                        <I18n id="login.step1.title" />
                    </span>
                </div>
                {!isMobileNative && queryString.parse(location.search).username && location.pathname.match("login") && (
                    <div className="login-header subtitle">
                        <h2 className="title">
                            <I18n id="login.step1.subtitle.title" />
                        </h2>
                        <p className="login-header-subtitle">
                            <I18n id="login.step1.subtitle" />
                        </p>
                    </div>
                )}
                <div className="login-row simple-row">
                    <div className="login-image">
                        <Image src="login-ibanca.svg" className="login-image-icon" />
                    </div>
                </div>
                <div className="login-row main-row">
                    <Form className={className} noValidate="novalidate">
                        {userPersonalName && (
                            <div className="login-form-field">
                                <div className="text-lead">
                                    <h4>
                                        <I18n id="login.step1.welcome" />
                                    </h4>
                                    <h3>{userPersonalName}</h3>
                                </div>
                            </div>
                        )}

                        {!isTrusted && (
                            <React.Fragment>
                                <div className="login-form-field">
                                    <div className="login-form-field-block">
                                        <div className="login-form-field-image">
                                            <Image src="login-ibanca.svg" className="login-form-field-icon user-icon" />
                                        </div>
                                        <Field
                                            idForm={FORM_ID}
                                            name="username"
                                            type="text"
                                            component={TextField}
                                            className="login-form-input"
                                            autoFocus
                                            hidelabel
                                        />
                                    </div>
                                    <span className="login-form-field-validation" />
                                </div>
                                <div className="login-form-field">
                                    <div className="login-form-field-block">
                                        <div className="login-form-field-image">
                                            <Image src="lock-icon.svg" className="login-form-field-icon lock-icon" />
                                        </div>
                                        <Field
                                            idForm={FORM_ID}
                                            name="secondFactor"
                                            component={Credential}
                                            type={CREDENTIAL_TYPE_OTP}
                                            autoFocus={false}
                                            className="login-form-input"
                                            hidelabel
                                        />
                                    </div>
                                    <span className="login-form-field-validation" />
                                </div>
                            </React.Fragment>
                        )}
                        {showCaptcha && !isMobileNative && (
                            <Field name="captcha" idForm={FORM_ID} component={Captcha} />
                        )}

                        <div className="login-form-field button-field">
                            <Button
                                className="login-form-button main-button"
                                bsStyle="primary"
                                label="global.continue"
                                loading={isSubmitting}
                                type="submit"
                                disabled={isMobileNative && disabledButton}
                            />
                        </div>
                    </Form>
                </div>

                <div className="enrollment-content-dialog">
                    <span className="enrollment-content-dialog-text" />
                </div>
            </React.Fragment>
        );
    }
}

export default HighOrder(
    Connect((store) => ({
        ...LoginSelectors.getRememberedUser(store),
        fingerprintAvailable: SelectorsStoreFingerPrint.getAvailability(store),
        disabledButton: LoginSelectors.isDisabledButton(store),
    })),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            username: props.username || props.idUserBiometric || EMPTY_STR,
            secondFactor: EMPTY_STR,
            captcha: EMPTY_STR,
        }),
        validationSchema: ({ userPersonalName, isMobileNative, showCaptcha, isTrusted }) =>
            Yup.object().shape({
                username: !userPersonalName
                    ? Yup.string().required(UtilsI18n.get(`${FORM_ID}.username.required`))
                    : Yup.string(),
                secondFactor: !isTrusted
                    ? Yup.string().required(UtilsI18n.get(`${FORM_ID}.secondFactor.required`))
                    : Yup.string(),
                captcha:
                    !isMobileNative && showCaptcha
                        ? Yup.string().required(UtilsI18n.get(`${FORM_ID}.captcha.required`))
                        : Yup.string(),
            }),
        handleSubmit: (data, formikBag) => {
            const { username, secondFactor, captcha } = data;
            const { dispatch } = formikBag.props;
            dispatch(LoginActions.loginStep1({ username, secondFactor, captcha, formikBag }));
        },
    }),
)(Component);
