import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "creditCardsPurchaseNotification";

export const PATH = {
    GET_PHONE_AND_ACTIVE: "/creditCards.purchaseNotification.getPhoneAndActive",
};

export const TYPE = FactoryType(NAME, [
    "GET_PHONE_AND_ACTIVE_REQUEST",
    "GET_PHONE_AND_ACTIVE_ERROR",
    "GET_PHONE_AND_ACTIVE_SUCCESS",
    "CLEAN_UP",
    "CHANGE",
    "NOT_CHANGE",
]);

export const PROP = {};

PROP.types = {
    fetching: PropTypes.bool,
    active: PropTypes.string,
    phone: PropTypes.string,
    change: PropTypes.bool,
    ...TypesRedux,
};

PROP.defaults = {
    fetching: false,
    active: EMPTY_STR,
    phone: null,
    change: false,
    ...DefaultsRedux,
};
