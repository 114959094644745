import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, NO_DATA } from "~/constants";
import { SelectorsStore as StoreI18n } from "~/store/i18n";

export const NAME = "ExtraData";

export const PROP = {
    types: {
        detail: PropTypes.arrayOf({
            documentType: PropTypes.string,
            documentNumber: PropTypes.string,
        }).isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const {
        detail: { supplierDocType, supplierDocNumber, supplier, supplierAccount, details },
        i18n,
    } = props;
    const emptyValues = [null, undefined];
    let supplierDocString = EMPTY_STR;

    if (!emptyValues.includes(supplierDocType) && !emptyValues.includes(supplierDocNumber)) {
        supplierDocString = `${supplierDocType} ${supplierDocNumber}`;
    }

    return (
        <React.Fragment>
            {// si falló el servicio de listar proveedores y faltan datos del proveedor, muestro solo el detalle
            (supplierDocString !== EMPTY_STR || supplierAccount) && (
                <React.Fragment>
                    <div>
                        <label>{i18n.supplier}</label>
                        <div>{supplier} </div>
                    </div>
                    <div>
                        <label>{i18n.supplierAccount}</label>
                        <div>{supplierAccount || NO_DATA} </div>
                    </div>
                    <div>
                        <label>{i18n.supplierDocument}</label>
                        <div>{supplierDocString || NO_DATA} </div>
                    </div>
                </React.Fragment>
            )}
                <div>
                    <label>{i18n.details}</label>
                    <div>{details !== EMPTY_STR ? details : NO_DATA} </div>
                </div>
        </React.Fragment>
    )
};

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        i18n: {
            supplier: StoreI18n.getMessage(store, "client.factoring.uploadPaymentOrders.extraData.supplierCode"),
            supplierDocument: StoreI18n.getMessage(
                store,
                "client.factoring.uploadPaymentOrders.extraData.supplierDocument",
            ),
            supplierAccount: StoreI18n.getMessage(
                store,
                "client.factoring.uploadPaymentOrders.extraData.supplierAccount",
            ),
            details: StoreI18n.getMessage(store, "client.factoring.uploadPaymentOrders.extraData.details"),
        },
    };
};

export default Connect(mapStateToProps)(Component);
