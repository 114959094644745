import React from "react";

import { connect as Connect } from "react-redux";

import { DATA_NOT_AVAILABLE, EMPTY_STR } from "~/constants";
import {
    SelectorsStore as SelectorsStorePaymentOrders,
    SelectorsAction as SelectorsActionPaymentOrders,
} from "~/store/factoring";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { generateId as GenID } from "~/util/general";
import * as i18nUtils from "~/util/i18n";

import Box from "~/components/Box";
import Element from "~/components/Component";
import DataDate from "~/components/DataDate";
import DataNumber from "~/components/DataNumber";
import GridLayout from "~/components/GridLayout";
import IntersectionObserver from "~/components/IntersectionObserver";

import { NAME_NOTE, TYPE_NOTE } from "../_index.scss";
import Style from "./_PaymentOrders.rules.scss";

export const { NAME, TYPE } = Style;

export const PROP = {
    types: {},
    defaults: {
        i18n: {
            loading: "Cargando",
            notAvailable: DATA_NOT_AVAILABLE,
            labelMore: "Más movimientos",
            labelNoMore: "No hay más movimientos",
        },
    },
};

export function Component(props) {
    const { dispatch, paymentOrders, moreLines, fetching, i18n, isMobile } = props;
    const {
        loading,
        labelMore,
        labelNoMore,
        typeLabel,
        numberLabel,
        supplierLabel,
        issueDateLabel,
        dueDateLabel,
        amountLabel,
    } = i18n;
    const COLUMN_HEADINGS = [typeLabel, numberLabel, supplierLabel, issueDateLabel, dueDateLabel, amountLabel];

    const render = () => (
        <React.Fragment>
            <GridLayout className="header">
                {COLUMN_HEADINGS.map((heading, index) => (
                    <Box key={GenID()} justify={index === 5 ? "end" : EMPTY_STR}>
                        {heading}
                    </Box>
                ))}
            </GridLayout>
            {paymentOrders.map(({ paymentType, paymentOrder, supplierName, issueDate, dueDate, amount, currency }) => (
                <GridLayout key={`paymentOrders-${GenID()}`}>
                    <div>{i18nUtils.get(`client.factoring.paymentOrders.paymentType.${paymentType}`)}</div>
                    <div>{paymentOrder}</div>
                    <div>{supplierName}</div>
                    <div>
                        <DataDate value={issueDate} />
                    </div>
                    <div>
                        <DataDate value={dueDate} />
                    </div>
                    <Box justify="end">
                        <DataNumber value={amount} prefix={currency} />
                    </Box>
                </GridLayout>
            ))}
            {fetching && <Note value={loading} />}
            {!fetching && moreLines && (
                <Note
                    onClick={() => handleMovementsFetch({ isIntersecting: true })}
                    intersection-trigger="true"
                    key="more"
                    value={labelMore}
                />
            )}

            {!fetching && !moreLines && (
                <Note
                    value={labelNoMore}
                    // onClick={() => handleMovementsFetch({ isIntersecting: false })}
                    className="no-more-movements"
                />
            )}
        </React.Fragment>
    );

    return isMobile ? (
        <IntersectionObserver onIntersect={handleMovementsFetch}>{render()}</IntersectionObserver>
    ) : (
        render()
    );

    function handleMovementsFetch(ev) {
        const { isIntersecting } = ev;
        if (!isIntersecting) {
            return;
        }
        dispatch(SelectorsActionPaymentOrders.loadListPaymentOrdersRequest({ firsTime: false, moreOrders: true }));
    }

    function Note({ value, ...rest }) {
        return (
            <Element type={TYPE_NOTE} name={NAME_NOTE} tag="span" {...rest}>
                {value}
            </Element>
        );
    }
}
const mapStateToProps = (store) => ({
    paymentOrders: SelectorsStorePaymentOrders.getPaymentOrders(store),
    fetching: SelectorsStorePaymentOrders.isFetching(store),
    moreLines: SelectorsStorePaymentOrders.getMoreLines(store),
    i18n: {
        notAvailable: StoreI18n.getMessage(store, "data.notAvailable.short"),
        loading: StoreI18n.getMessage(store, "global.loading"),
        labelMore: StoreI18n.getMessage(store, "client.factoring.paymentOrders.list.moreOrders"),
        labelNoMore: StoreI18n.getMessage(store, "client.factoring.paymentOrders.list.noMoreOrders"),
        typeLabel: StoreI18n.getMessage(store, "client.factoring.paymentOrders.list.type"),
        numberLabel: StoreI18n.getMessage(store, "client.factoring.paymentOrders.list.number"),
        supplierLabel: StoreI18n.getMessage(store, "client.factoring.paymentOrders.list.supplier"),
        issueDateLabel: StoreI18n.getMessage(store, "client.factoring.paymentOrders.list.issueDate"),
        dueDateLabel: StoreI18n.getMessage(store, "client.factoring.paymentOrders.list.dueDate"),
        amountLabel: StoreI18n.getMessage(store, "client.factoring.paymentOrders.list.amount"),
    },
});

export default Connect(mapStateToProps)(Component);
