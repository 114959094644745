export const FORM_ID = "comex.export.presentationDocumentsCreditLetter";
export const FORM_STEP_2 = "comex.export.presentationDocumentsCreditLetter.step2";

export const FIELD_NAME = {
    CREDIT_LETTER: "creditLetter",
    OPERATION_NUMBER: "operationNumber",
    CREDIT_LETTER_NUMBER: "creditLetterNumber",
    INVOICE_AMOUNT: "invoiceAmount",
    INVOICE_NUMBER: "invoiceNumber",
    UTILIZATION_AMOUNT: "utilizationAmount",
    COURIER_NAME: "courierName",
    COURIER_ACCOUNT_NUMBER: "courierAccountNumber",
    FILES: "files",
    DISCREPANCIES: "discrepancies",
    OBSERVATIONS: "observations",
    NOTIFICATION_EMAILS: "notificationEmails",
    DISCLAIMER: "disclaimer",
    SCHEDULER: "scheduler",
    DOCUMENTS: "documents",
    ITEM: "item",
};

export const ID_ACTIVITY_SEND = `${FORM_ID}.send`;

export const i18nMap = {
    termsAndConditions: `${FORM_ID}.disclaimer`,
};

export const COLUMN_HEADINGS = ["document", "description", "original", "copies"];

export default {
    FIELD_NAME,
    FORM_ID,
    ID_ACTIVITY_SEND,
    i18nMap,
    COLUMN_HEADINGS,
};
