import React from "react";

import { goBack as GoBack } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import {
    SelectorsAction as CustomsPaymentActions,
    SelectorsStore as CustomsPaymentSelectors,
} from "~/store/customsPayment";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Box from "~/components/Box";
import Ticket from "~/components/Ticket/Ticket";
import FormattedAmount from "~/pages/_components/FormattedAmount";

import PaymentList from "../_components/PaymentList";
import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        fetching: PropTypes.bool.isRequired,
        ...TypesRedux,
    },
    defaults: {
        ...DefaultsRedux,
    },
};

const FORM_ID = "payments.customsPayment.step3";

export class Component extends React.Component {
    HandleBack = () => {
        const { dispatch } = this.props;
        dispatch(GoBack());
    };

    handleReject = (formikBag) => {
        const { transaction, dispatch } = this.props;
        const { idTransaction } = transaction;
        dispatch(
            CustomsPaymentActions.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    };

    handleSign = () => {
        const {
            dispatch,
            transaction: { data },
        } = this.props;
        dispatch(CustomsPaymentActions.signTransactionPreview({ ...data }));
    };

    render() {
        const {
            currency,
            fetching,
            mode,
            paymentItems,
            referenceCode,
            status,
            ticketConfirmation,
            totalAmount,
            totalPayments,
            transaction,
        } = this.props;

        const data = {
            currency: transaction && transaction.data ? transaction.data.totalAmount.currency : currency,
            paymentItems: transaction && transaction.data ? transaction.data.selectedPayments : paymentItems,
            referenceCode: transaction ? transaction.idTransaction : referenceCode,
            totalAmount: transaction && transaction.data ? transaction.data.totalAmount.quantity : totalAmount,
            totalPayments: transaction && transaction.data ? transaction.data.selectedPayments.length : totalPayments,
        };

        return (
            data && (
                <Ticket
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={this.handleReject}
                    handleSign={this.handleSign}
                    hasMobileComponent>
                    <Box flex directionColumn align="flex-start" className="mb-3">
                        <div className="data-label">
                            <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.cost.label`)}</h4>
                        </div>
                        <FormattedAmount currency={data.currency} quantity={data.totalAmount} />
                        <div className="data-label">
                            <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.totalPayments.label`)}</h4>
                        </div>
                        <span>{data.totalPayments}</span>
                        <div className="step3-table" id={Style.ID}>
                            <PaymentList
                                formId={FORM_ID}
                                fetched={status && status.fetched && !fetching}
                                paymentItems={data.paymentItems}
                                selectPayment={this.selectPayment}
                                totalAmount={data.totalAmount}
                                currency={data.currency}
                                mode={mode}
                            />
                        </div>
                    </Box>
                </Ticket>
            )
        );
    }
}

const mapStateToProps = (store) => ({
    currency: CustomsPaymentSelectors.getCurrency(store),
    fetching: CustomsPaymentSelectors.getFetching(store),
    mode: CustomsPaymentSelectors.getMode(store),
    paymentItems: CustomsPaymentSelectors.getSelectedPayments(store),
    referenceCode: CustomsPaymentSelectors.getReferenceCode(store),
    totalAmount: CustomsPaymentSelectors.getTotalAmount(store),
    totalPayments: CustomsPaymentSelectors.getTotalPayments(store),
    transaction: CustomsPaymentSelectors.getTransaction(store),
    valueDate: CustomsPaymentSelectors.getValueDate(store),
});

export default Connect(mapStateToProps)(Component);
