import React from "react";

import PropTypes from "prop-types";

import { BADGE_MAX } from "~/constants";

export const NAME = "Badge";

export const PROP = {
    types: { count: PropTypes.number.isRequired, className: PropTypes.string },
    defaults: { className: null },
};

export function Component(props) {
    const { count, className } = props;

    if (count === 0) {
        return null;
    }

    return (
        <div className={`badge ${className}`}>
            <span>{count > 99 ? BADGE_MAX : count}</span>
        </div>
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
