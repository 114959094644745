import React from "react";

import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";

import { MODE } from "~/constants/form";
import { SelectorsAction as SelectorsActionAccount, SelectorsStore as SelectorsStoreAccount } from "~/store/account";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import TextArea from "~/pages/_components/fields/TextArea";

import Style from "./EditStatementNote.rules.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

const FORM_ID = "accounts.movements.editNote";

export function Component(props) {
    const { dispatch } = props;

    function handleCloseModal() {
        dispatch(SelectorsActionAccount.setShowNoteModal({ showSendMailModal: false }));
    }

    return (
        <Form className={CLASS}>
            <Field
                component={TextArea}
                idForm={FORM_ID}
                name="note"
                mode={MODE.EDIT}
                className="textarea"
                maxLength={200}
            />
            <footer>
                <Button label="global.cancel" onClick={handleCloseModal} block={false} />
                <Button label="global.accept" className="btn-primary" block={false} type="submit" />
            </footer>
        </Form>
    );
}

const mapStateToProps = (store) => ({
    selectedAccount: SelectorsStoreAccount.getSelectedAccount(store),
    selectedMovement: SelectorsStoreAccount.getSelectedMovement(store),
    note: SelectorsStoreAccount.getNote(store),
});

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        mapPropsToValues: (props) => ({
            note: props.note,
        }),
        handleSubmit: ({ note }, formikBag) => {
            const { dispatch, selectedAccount, selectedMovement } = formikBag.props;
            const idProduct = selectedAccount && selectedAccount.idProduct ? selectedAccount.idProduct : null;
            const idStatement = selectedMovement && selectedMovement.idStatement ? selectedMovement.idStatement : null;

            dispatch(SelectorsActionAccount.editMovementNote({ idProduct, idStatement, note }));
        },
    }),
)(Component);
