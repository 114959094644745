import React from "react";

import { goBack } from "connected-react-router";
import { Form, withFormik as WithFormik, Field } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { bindActionCreators } from "redux";

import { EMPTY_STR, PAPER } from "~/constants";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsStore as SelectorsStoreWMBuy, SelectorsAction as SelectorsActionWMBuy } from "~/store/wm/funds/buy";
import * as UtilsConfig from "~/util/config";
import { addDay as AddDay } from "~/util/date";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import DateField from "~/pages/_components/fields/DateField";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";

import Checkbox from "~/pages/forms/_components/_fields/_commons/Checkbox";
import SelectorType from "~/pages/settings/changePersonalInformation/field/SelectorType";
import Amount from "~/pages/wm/funds/_component/Amount";
import Disclaimer from "~/pages/wm/funds/_component/Disclaimer";
import PaperDetails from "~/pages/wm/funds/_component/PaperDetails";

import Style from "./Step1Edit.rules.scss";

export const NAME = "Form";

export const FORM_ID = "wm.buy";

export const PROP = {
    types: {
        isFetching: PropTypes.bool,
    },
    defaults: {
        isFetching: false,
        paymentOrdersList: [],
        totalizers: [],
    },
};

export function Component(props) {
    const {
        GoBack,
        activeEnvironment,
        debitAccounts,
        errors,
        isFetching,
        paper,
        setErrors,
        setFieldValue,
        timezoneOffset,
        touched,
        values,
    } = props;

    const handleCancel = React.useCallback(() => {
        GoBack();
    }, [GoBack]);

    const handleChange = React.useCallback(
        (value) => {
            setFieldValue("amount", value);
            setErrors({ ...errors, amount: EMPTY_STR });
        },
        [errors, setErrors, setFieldValue],
    );

    const { othersFields } = paper || {};
    let currency = EMPTY_STR;
    let minValue;

    if (othersFields) {
        currency = UtilsI18n.get(`currency.label.${othersFields[PAPER.CURRENCY]}`);
        minValue = othersFields.COMPRAMINIMO;
    }

    const handleCheckChange = (fieldName) => {
        setFieldValue(fieldName, !values[fieldName]);
    };

    const maxDaysToShow = UtilsConfig.getInteger("client.wm.allInvestments.orderValid.maxDaysToShow");
    const minDate = new Date();
    const maxDate = AddDay(minDate, maxDaysToShow);

    return (
        <Form id={Style.ID}>
            <div className="fields">
                <div className="form-section-title">
                    <div className="form-section-title-text">
                        <I18n id={`${FORM_ID}.title.details`} />
                    </div>

                    <PaperDetails
                        activeEnvironment={activeEnvironment}
                        debitAccounts={debitAccounts}
                        label={`${FORM_ID}.label`}
                        paper={paper}
                    />
                </div>
                <div className="form-section-title">
                    <div className="form-section-title-text">
                        <I18n id={`${FORM_ID}.subtitle.details.buy`} />
                    </div>
                    <div className="form-section">
                        {/* Order valid until */}
                        <div className="form-group">
                            <FieldLabel
                                labelKey="wm.allInvestments.orderValidUntil.label"
                                classContainer={`${
                                    errors.orderCompleted && touched.orderCompleted ? "has-error" : EMPTY_STR
                                }`}
                            />

                            <div className="checkbox-mark">
                                <Field
                                    name="orderCompleted"
                                    label={UtilsI18n.get(`${FORM_ID}.orderCompleted.label`)}
                                    checked={values.orderCompleted}
                                    component={Checkbox}
                                    onChange={() => handleCheckChange("orderCompleted")}
                                />
                            </div>

                            {errors.orderCompleted && touched.orderCompleted && (
                                <FieldError error={errors.orderCompleted} />
                            )}
                        </div>

                        {!values.orderCompleted && (
                            <React.Fragment>
                                <div className="datepicker">
                                    <Field
                                        autoComplete="off"
                                        component={DateField}
                                        id="limitDate"
                                        idForm={FORM_ID}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        name="limitDate"
                                        timezoneOffset={timezoneOffset}
                                    />
                                    <div id="disclaimer-text">
                                        {UtilsI18n.get("wm.allInvestments.afterValidDate.disclaimer")}
                                    </div>
                                </div>
                            </React.Fragment>
                        )}

                        {/* Debit account */}
                        <SelectorType
                            idForm={FORM_ID}
                            label={UtilsI18n.get(`${FORM_ID}.selector.account`)}
                            labelKey="productAlias"
                            name="idDebitAccount"
                            options={debitAccounts ?? []}
                            valueKey="idProduct"
                            {...props}
                        />

                        {/* Amount */}
                        <Amount
                            className="EditableDataNumber"
                            currency={currency}
                            errors={errors}
                            handleBlur={() => {}}
                            handleChange={handleChange}
                            id="amount"
                            idForm={FORM_ID}
                            name="amount"
                            paper={paper}
                            textHelp={minValue}
                            touched={touched}
                            values={values}
                        />

                        {/* Disclaimer */}
                        <Disclaimer
                            idForm={FORM_ID}
                            label="wm.funds.buy.disclaimer.title"
                            labelDisclaimer="wm.funds.buy.disclaimer"
                            name="disclaimer"
                            {...props}
                        />
                    </div>
                </div>
            </div>
            <footer>
                {/* Buttons */}
                {!isFetching && (
                    <React.Fragment>
                        <Button size="sm" variant="secondary" onClick={handleCancel}>
                            {UtilsI18n.get("global.cancel")}
                        </Button>
                        <Button type="submit" variant="primary">
                            {UtilsI18n.get("global.send")}
                        </Button>
                    </React.Fragment>
                )}
            </footer>
        </Form>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
    debitAccounts: SelectorsStoreWMBuy.getDebitAccounts(store),
    isFetching: false,
    paper: SelectorsStoreWMBuy.getPaper(store),
    timezoneOffset: SelectorsStoreWMBuy.getTimezoneOffset(store),
});

const mapDispatchToProps = (dispatch) => ({
    ActionWMBuy: bindActionCreators(SelectorsActionWMBuy, dispatch),
    GoBack: bindActionCreators(goBack, dispatch),
});

export default HighOrder(
    Connect(mapStateToProps, mapDispatchToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        mapPropsToValues: ({ orderCompleted }) => {
            return {
                limitDate: EMPTY_STR,
                orderCompleted: orderCompleted !== false,
            };
        },
        handleSubmit: (values, formikBag) => {
            const { amount, disclaimer, idDebitAccount, limitDate, orderCompleted } = values;
            const { ActionWMBuy, paper } = formikBag.props;

            const request = {
                amount,
                disclaimer: disclaimer ?? false,
                idDebitAccount,
                idPaper: paper.othersFields?.PAPEL,
                limitDate: orderCompleted ? EMPTY_STR : limitDate,
                orderCompleted,
                paperName: paper.othersFields?.DESCRIPCION,
                valueDate: new Date(),
            };

            ActionWMBuy.previewPaperRequest({ ...request, formikBag });
        },
    }),
)(Component);
