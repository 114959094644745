import React from "react";

import ClassNames from "classnames";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SPACE_STR } from "~/constants";
import * as ConfigUtil from "~/util/config";

import Namespaces from "./EditableText.scss";

export const { CLASS, NAME, TAG: EditableText } = Namespaces;

const DISPLAY_TYPE = { text: "text", input: "input" };

const EVENT_KEY = {
    enter: "Enter",
    spacebar: "Spacebar",
    space: SPACE_STR,
};

export const PROP = {
    types: {
        /** Defines onBlur event callback */
        onBlur: PropTypes.func,
        /** Defines onChange event callback */
        onChange: PropTypes.func,
        /** Adds a prefix to the input */
        readOnly: PropTypes.bool,
        /** Defines input value */
        value: PropTypes.string,
        /** Defines a placeholder's text */
        placeholder: PropTypes.string,
    },
    defaults: {
        onBlur: null,
        onChange: null,
        readOnly: false,
        value: null,
        placeholder: null,
    },
};

export function Component({ value, readOnly, onBlur, onChange, placeholder, ...props }) {
    const [displayType, setDisplayType] = React.useState(DISPLAY_TYPE.text);

    const handleBlur = (event) => {
        setDisplayType(DISPLAY_TYPE.text);
        if (onBlur) {
            onBlur(event);
        }
    };

    const handleClick = () => {
        if (!readOnly) {
            setDisplayType(DISPLAY_TYPE.input);
        }
    };

    const handleKeyDown = (event) => {
        const { target } = event;
        if (!readOnly && event.key === EVENT_KEY.enter) {
            if (target.tagName === "INPUT") {
                onBlur(event);
            } else {
                handleClick();
            }
        }
    };

    return (
        <EditableText
            {...props}
            name={NAME}
            className={ClassNames(
                CLASS,
                { "is-input": displayType === DISPLAY_TYPE.input },
                { "is-read-only": readOnly },
            )}
            onClick={handleClick}
            onKeyDown={handleKeyDown}>
            {!readOnly ? (
                <div onBlur={handleBlur}>
                    <input
                        maxLength={ConfigUtil.getInteger("suppliersPayment.manual.maxLength.reference")}
                        displayType={displayType}
                        value={value}
                        onChange={onChange}
                        onBlur={handleBlur}
                        placeholder={placeholder}
                    />
                </div>
            ) : (
                <div>
                    <span>{value}</span>
                </div>
            )}
        </EditableText>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Connect()(Component);
