import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, PRICE_CLASSIFICATION } from "~/constants";
import {
    SelectorsStore as SelectorsStoreWMShares,
    SelectorsAction as SelectorsActionWMShares,
} from "~/store/wm/shares";
import * as UtilsI18n from "~/util/i18n";

import DataDate from "~/components/DataDate";
import DataNumber from "~/components/DataNumber";
import I18n from "~/components/I18n";
import Ticket from "~/components/Ticket/Ticket";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

const FORM_ID = "wm.shares.sell.send";

export function Component(props) {
    const { ticketConfirmation, transaction } = props;
    const { data } = transaction;

    const {
        creditAccountAlias,
        currency,
        limitDate,
        limitFrom,
        orderCompleted,
        paperName,
        price,
        sellingType,
        sharesQuantity,
    } = data;

    const orderValid = orderCompleted ? (
        UtilsI18n.get(`${FORM_ID}.orderCompleted.label`)
    ) : (
        <DataDate value={limitDate} />
    );

    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    hasMobileComponent
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}>
                    <div className="data-section">
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="client.wm.shares.sell.step3.paperName" />
                            </span>
                            <span className="data-text">{paperName}</span>
                        </div>
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="client.wm.shares.sell.step3.sellingType" />
                            </span>
                            <span className="data-text">
                                {UtilsI18n.get(`client.wm.shares.sell.step3.sellingType.${sellingType}`)}
                            </span>
                        </div>
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="client.wm.shares.sell.step3.price" />
                            </span>
                            <span className="data-text">
                                {UtilsI18n.get(`client.wm.shares.sell.step3.price.${price}`)}
                            </span>
                        </div>
                        {price === PRICE_CLASSIFICATION.LIMIT && (
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="client.wm.shares.sell.step3.limitFrom" />
                                </span>
                                <span className="data-name amount-number">
                                    <DataNumber value={limitFrom} prefix={currency} className="format-currency" />
                                </span>
                            </div>
                        )}
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="client.wm.shares.sell.step3.sharesQuantity" />
                            </span>
                            <span className="data-name">{sharesQuantity}</span>
                        </div>
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="wm.shares.sell.send.creditAccount.label" />
                            </span>
                            <span className="data-name">{creditAccountAlias}</span>
                        </div>
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="wm.allInvestments.orderValidUntil.label" />
                            </span>
                            <span className="data-name">{orderValid}</span>
                        </div>
                    </div>
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { dispatch } = props;
        const { idTransaction } = transaction;

        dispatch(
            SelectorsActionWMShares.cancelTransaction({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        const { dispatch } = props;

        dispatch(SelectorsActionWMShares.signTransactionPreview({ ...data }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreWMShares.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
