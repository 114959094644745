import React from "react";

import ClassNames from "classnames";
import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";

import I18n from "~/components/I18n";

export const NAME = "DetailBoxData";

export const PROP = {
    types: {
        error: PropTypes.bool,
        label: PropTypes.string.isRequired,
        children: PropTypes.node.isRequired,
    },
    defaults: {
        error: false,
        label: EMPTY_STR,
        children: null,
    },
};

export function Component(props) {
    const { error, label, children } = props;

    return (
        <div className="detailBox-row">
            <div className={ClassNames("detailBox-data", { "has-errors": error })}>
                <I18n id={label} />
            </div>
            <div className="text-right">
                <div>{children}</div>
            </div>
        </div>
    );
}

Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

export default Component;
