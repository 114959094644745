import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "wm.listPapers";

export const PATH = {
    LIST_PAPERS: "/client.wm.funds.listPapers",
    READ_PAPER_DETAILS: "/client.wm.funds.readPaperDetail",
    READ_PAPER_CHART_VALUES: "/client.wm.funds.readPaper",
    DOWNLOAD_FAMILY_DESCRIPTON: "/wm.families.download",
};

export const TYPE = FactoryType(NAME, [
    "LIST_PAPERS_REQUEST",
    "LIST_PAPERS_SUCCESS",
    "LIST_PAPERS_FAILURE",
    "UPDATE_FILTERS",
    "RESET_FILTERS",
    "PAPER_DETAILS_REQUEST",
    "PAPER_DETAILS_SUCCESS",
    "READ_PAPER_CHART_VALUES_REQUEST",
    "READ_PAPER_CHART_VALUES_FAILURE",
    "READ_PAPER_CHART_VALUES_SUCCESS",
    "RESET_CHART_VALUES",

    "FAMILY_PRODUCT_DESCRIPTION_REQUEST",
]);

export const PROP = {};

PROP.members = {};

PROP.types = {
    ...TypesRedux,
    filters: PropTypes.shape({
        family: PropTypes.string,
        category: PropTypes.string,
        year: PropTypes.string,
    }),
    fetching: PropTypes.bool,
    fundPapers: PropTypes.array,
    familiesList: PropTypes.array,
    categoriesList: PropTypes.array,
    paper: PropTypes.object,
};

PROP.defaults = {
    filters: {
        family: "all",
        category: "DESTACADOS",
        year: "",
    },
    fetching: false,
    fundPapers: [],
    familiesList: [],
    categoriesList: [],
    paperDetail: {},
    chartPeriods: {},
    chartValues: [],
    fetchingChartValues: false,
    ...DefaultsRedux,
};
