import React from "react";

import { goBack as GoBack, replace as Replace } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import {
    SelectorsAction as SelectorsActionPreferentialTradingPrice,
    SelectorsStore as SelectorsStoreSelectorsActionPreferentialTradingPrice,
} from "~/store/preferentialTradingPrice";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";

import { CLASS } from "./PreferentialTradingPrice.rules.scss";
import FormView from "./views/Step3View";

export const NAME = "PreferentialTradingPrice";

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        fetching: PropTypes.bool,
        isChangingEnvironment: PropTypes.bool,
    },
    defaults: {
        fetching: false,
        isChangingEnvironment: false,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    componentDidMount() {
        const { dispatch, isChangingEnvironment, match } = this.props;
        const { id } = match.params;

        if (!isChangingEnvironment) {
            dispatch(SelectorsActionPreferentialTradingPrice.readFxTicketRequest({ idTransaction: id }));
        }
    }

    handleBack = () => {
        const {
            dispatch,
            history: {
                location: { state },
            },
        } = this.props;

        if (state && state.from === "/transactions/list") {
            dispatch(GoBack());
        } else {
            dispatch(Replace("/preferentialTradingPrice"));
        }
    };

    render() {
        const { fetching, isFromBackoffice } = this.props;

        return (
            <Container
                className={CLASS}
                head-onBack={this.handleBack}
                head-onBackWeb={this.handleBack}
                head-onClose={this.close}
                head-title="preferentialTradingPrice.title"
                name={NAME}
                step={MODE.VIEW}
                wait={fetching}>
                <FormView isFromBackoffice={isFromBackoffice} />
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    fetching: SelectorsStoreSelectorsActionPreferentialTradingPrice.fetching(store),
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
});

export default Connect(mapStateToProps)(Component);
