import * as StoreRouter from "connected-react-router";
import QueryString from "query-string";

import { EMPTY_STR, LEVEL, ID_FORM, SCOPE, ID_FIELD, FORM_RENDER } from "~/constants";
import { FIELD } from "~/constants/form";
import Store from "~/store";
import { SelectorsAction as SelectorsActionForm, SelectorsStore as SelectorsStoreForm } from "~/store/form";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { isVisible as IsVisible, isRequired as IsRequired } from "~/util/form";
import * as I18nUtils from "~/util/i18n";
import UtilLodash from "~/util/lodash";
import * as StringUtils from "~/util/string";

export default {
    mapPropsToValues,
    validate,
    handleSubmit,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
};

function mapPropsToValues(props) {
    const store = Store.getState();

    const { metadata } = props;
    const location = StoreRouter.getLocation(store);
    const data = SelectorsStoreForm.getData(store);

    const { query } = QueryString.parseUrl(location.search);
    return {
        ...metadata.fieldList.reduce((values, field) => {
            const { idField, data: currencys } = field;
            let value = data[idField] || query[idField] || EMPTY_STR;
            let val = null;
            if (idField === ID_FIELD.AMOUNT) {
                const { options } = currencys;

                const arrayTemp = metadata.fieldList.filter(
                    (fieldValue) => fieldValue.idField === FORM_RENDER.DEBIT_ACCOUNT,
                );
                if (query[FORM_RENDER.DEBIT_ACCOUNT]) {
                    const account = arrayTemp[0].data.options.filter(
                        (fieldValue) => fieldValue.id === query[FORM_RENDER.DEBIT_ACCOUNT],
                    );
                    if (account.length > 0) {
                        val = options.some((amountInput) => amountInput.id === String(account[0].currency))
                            ? { currency: String(account[0].currency), quantity: EMPTY_STR }
                            : EMPTY_STR;
                    }
                }
                value = data[idField] || val || EMPTY_STR;
            }
            return {
                ...values,
                [idField]: value,
            };
        }, {}),
        scheduler: data.scheduler
            ? {
                  ...data.scheduler,
                  valueDate: data.scheduler.valueDate ? data.scheduler.valueDate : null,
              }
            : null,
    };
}

function validate(values, props) {
    const { metadata, currentLang, dispatch } = props;
    const { errors } = metadata.fieldList.reduce(
        (acc, value) => {
            const { idField, requiredErrorMap } = value;

            const isFieldVisible = IsVisible(idField, metadata.fieldList, acc.insideValues);
            if (!isFieldVisible) {
                acc.insideValues[idField] = null;
            }
            //  TODO: refactor para agrupar la validacion
            const oldValidation =
                IsRequired(idField, metadata.fieldList, acc.insideValues) &&
                isFieldVisible &&
                (StringUtils.isEmpty(acc.insideValues[idField]) ||
                    acc.insideValues[idField].length < 1 ||
                    (idField === FIELD.AMOUNT && acc.insideValues[idField].quantity === EMPTY_STR));

            //  Validacion en pago de tarjetas para cuando no se ingresa un importe en monto especifico.
            const sndValidation =
                (idField === ID_FIELD.AMOUNT_UYU || idField === ID_FIELD.AMOUNT_USD) &&
                acc.insideValues[idField].quantity === EMPTY_STR;

            let validateBankCode = false;
            if (idField === ID_FIELD.CREDIT_BANK_CODE) {
                validateBankCode = !Object.prototype.hasOwnProperty.call(acc.insideValues[idField], "code");
                const hasBank = Object.prototype.hasOwnProperty.call(acc.insideValues[idField], "bank");
                if (!hasBank && !validateBankCode) {
                    return {
                        errors: {
                            ...acc.errors,
                            [idField]: I18nUtils.get("forms.bankselector.noRecordsFound"),
                        },
                        insideValues: acc.insideValues,
                    };
                }
            }

            if (oldValidation || sndValidation || validateBankCode) {
                return {
                    errors: {
                        ...acc.errors,
                        [idField]: requiredErrorMap[currentLang],
                    },
                    insideValues: acc.insideValues,
                };
            }

            return { errors: acc.errors, insideValues: acc.insideValues };
        },
        { errors: {}, insideValues: UtilLodash.cloneDeep(values) },
    );

    if (Object.keys(errors).length !== 0) {
        dispatch(
            SelectorsActionNotification.showNotification({
                message: I18nUtils.get("forms.fieldsErrors"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.FORM],
            }),
        );
    }

    return errors;
}

function handleSubmit(values, formikBag) {
    const sumbitValues = { ...values };
    const { dispatch } = formikBag.props;
    if (sumbitValues.creditCard) {
        //  TODO: investigar porque muta el objeto perdiendo las properties 'value' y 'isFrequentDestination'
        if (!Object.prototype.hasOwnProperty.call(values.creditCard, "value")) {
            sumbitValues.creditCard = { value: sumbitValues.creditCard, isFrequentDestination: false };
        }
    }
    const filteredValues = Object.entries(sumbitValues).reduce((accumulator, [key, value]) => {
        if (value === EMPTY_STR) {
            return accumulator;
        }
        return {
            ...accumulator,
            [key]: StringUtils.trim(value),
        };
    }, {});

    //* UYHSBCCDP-367
    //*  TODO: Este trozo de codigo se encuentra desligado de producto y lo unico que hace
    //*  es cambiar el titulo del form de transferencia a plaza a el de transferencia express
    const { id, metadata, currentLang } = formikBag.props;
    if (id === ID_FORM.TRANSFER_LOCAL) {
        const { checkboxExpress } = values;
        if (checkboxExpress && checkboxExpress.length > 1) {
            metadata.formNameMap[currentLang] = I18nUtils.get("transfer.express.labelExpress");
        } else {
            metadata.formNameMap[currentLang] = I18nUtils.get("menu.transfers.local");
        }
    }
    //* fin UYHSBCCDP-367

    dispatch(
        SelectorsActionForm.previewForm({
            idForm: formikBag.props.id,
            idActivity: formikBag.props.metadata.idActivity,
            idTransaction: formikBag.props.transaction ? formikBag.props.transaction.idTransaction : null,
            values: filteredValues,
            formikBag,
        }),
    );
}
