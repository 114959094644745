import React from "react";

import ClassNames from "classnames";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SelectorsAction as ActionWM } from "~/store/wm";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import DataNumber from "~/components/DataNumber";
import FieldLabel from "~/pages/_components/fields/FieldLabel";

import Style from "./PaperBuy.rules.scss";

export const { CLASS, NAME } = Style;

export const PROP = {
    types: {
        isSelling: PropTypes.bool,
        paper: PropTypes.shape({
            idPaper: PropTypes.string,
            currency: PropTypes.string,
            description: PropTypes.string,
            marketPrice: PropTypes.string,
            productTypeOrigin: PropTypes.string,
            riskLevel: PropTypes.string,
            ticker: PropTypes.string,
            detailType: PropTypes.string,
            detailLink: PropTypes.string,
        }),
        dispatch: PropTypes.func.isRequired,
    },
    defaults: {
        isSelling: false,
        paper: null,
    },
};
export function Component(props) {
    const {
        paper: { currency, description, marketPrice },
    } = props;

    const marketPriceNum = parseFloat(marketPrice, 10);

    function handleClick() {
        const {
            dispatch,
            paper: { detailType, detailLink, idPaper, productTypeOrigin },
        } = props;

        const link = detailType === "external" ? detailLink : null;
        if (!link) {
            dispatch(ActionWM.paperDetailRequest({ idPaper, productType: productTypeOrigin, detailType }));
        } else {
            const win = window.common.openLink(link);
            if (win) {
                win.focus();
                win.opener = null;
            }
        }
    }

    return (
        <Box className={ClassNames(CLASS, "form-group py-3")}>
            <Box className="grid-container paper px-4 py-2">
                <Box flex className="description-label">
                    <FieldLabel labelKey="client.wm.shares.buy.paper.issuer" useColon />
                    <Box className="py-3 pl-3" tag="span">
                        {description}
                    </Box>
                </Box>
                <Box flex>
                    <FieldLabel labelKey="client.wm.shares.buy.paper.currency" useColon />
                    <Box className="py-3 pl-3" tag="span">
                        {UtilsI18n.get(`currency.label.${currency}`)}
                    </Box>
                    {marketPriceNum > 0 && (
                        <Box flex className="pl-4 tooltip-wrapper">
                            <FieldLabel labelKey="client.wm.shares.buy.paper.marketPrice" useColon />
                            <Box className="py-3 pl-3" tag="span">
                                <DataNumber value={marketPriceNum} />
                            </Box>
                            <div className="tooltip">
                                <span className="tooltip-text">
                                    {UtilsI18n.get("client.wm.shares.buy.paper.marketPrice.tooltip")}
                                </span>
                            </div>
                        </Box>
                    )}
                </Box>
                <Box flex justify="end" className="details-btn">
                    <Box flex tag="span">
                        <a onClick={() => handleClick()}>{UtilsI18n.get("wm.view.details")}</a>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Connect()(Component);
