import { takeLatest as TakeLatest, call as Call, put as Put } from "redux-saga/effects";

import {
    LEVEL,
    RESPONSE_TYPE,
    SCOPE,
    WM_OTHER_INVESTMENTS_TYPES,
    SHARE_CLASSIFICATION,
    NO_W8_AVAILABLE,
    NO_RISK_PROFILE_AVAILABLE,
    FORM_FIELD_ERRORS,
} from "~/constants";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as UtilDownload from "~/util/download";
import {
    credentialsWithUnderscore as CredentialsWithUnderscore,
    hasIncorrectCredentials as HasIncorrectCredentials,
    adjustIdFieldErrors as AdjustIdFieldErrors,
    getTouchedFields as GetTouchedFields,
    getResponseMessage as GetResponseMessage,
} from "~/util/form";
import * as UtilI18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsMiddleware } from "./_selectors";

export default [
    TakeLatest(TYPE.SEARCH_SHARES_REQUEST, searchShares),
    TakeLatest(TYPE.SELL_SHARES_PRE_REQUEST, sellSharesPre),
    TakeLatest(TYPE.SELL_SHARES_PREVIEW_REQUEST, sellSharesPreview),
    TakeLatest(TYPE.SELL_SHARES_SEND_REQUEST, sellSharesSend),
    TakeLatest(TYPE.READ_TRANSACTION_REQUEST, readTransactionRequest),
    TakeLatest(TYPE.CANCEL_TRANSACTION_REQUEST, cancelTransaction),
    TakeLatest(TYPE.SIGN_TRANSACTION_REQUEST, signTransaction),
    TakeLatest(TYPE.GET_PAPER, getPaper),
    TakeLatest(TYPE.BUY_SHARES_PRE_REQUEST, buySharesPreRequest),
    TakeLatest(TYPE.BUY_SHARES_PREVIEW_REQUEST, buySharesPreviewRequest),
    TakeLatest(TYPE.BUY_SHARES_SEND_FORM, buySharesSendFormRequest),
];

function* searchShares({ previewData, formikBag }) {
    const { ticker1, ticker2, ...otherFields } = previewData;

    const requestProps = {
        type: WM_OTHER_INVESTMENTS_TYPES.ACCIONES,
        ...otherFields,
        ticker_1: ticker1,
        ticker_2: ticker2,
    };

    const { type, data } = yield Call(SelectorsMiddleware.searchSharesRequest, requestProps);

    if (type === RESPONSE_TYPE.WARNING) {
        formikBag.setErrors(AdjustIdFieldErrors(data.data));
        formikBag.setTouched(GetTouchedFields(data.data));

        yield Put(SelectorsAction.searchSharesFailure());

        const message = GetResponseMessage(data);

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.BUY_SHARES],
            }),
        );
    } else {
        const { moreLines, papersList } = data.data;

        yield Put(SelectorsAction.clearForm());
        yield Put(SelectorsAction.searchSharesSuccess({ papersList, moreLines }));

        if (previewData.classification === SHARE_CLASSIFICATION.PREFERRED) {
            if (papersList[0]) {
                if (formikBag.shouldCallPre) {
                    const { idPaper } = papersList[0];
                    yield Put(SelectorsAction.buySharesPre({ idPaper }));
                }
            } else if (formikBag.shouldDisplayIsinError) {
                formikBag.setErrors({ isin: UtilI18n.get("wm.shares.buy.search.results.empty") });
                formikBag.setTouched({ isin: true });
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: UtilI18n.get("wm.shares.buy.search.results.empty"),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.BUY_SHARES],
                    }),
                );
            }
        } else if (formikBag.shouldRedirectToSearchForm) {
            yield Put(SelectorsAction.goToComponent());
        }
    }
}

function* buySharesPreRequest(props) {
    const { type: action, ...formData } = props;

    const { type, data } = yield Call(SelectorsMiddleware.buySharesPreRequest, formData);
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.buySharesPreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.BUY_SHARES],
            }),
        );
    } else {
        yield Put(SelectorsAction.buySharesPreSuccess({ ...data.data }));
    }
}

function* buySharesPreviewRequest({ previewData, formikBag }) {
    const { description, ...otherData } = previewData;
    const requestData = {
        ...otherData,
        paperName: description,
    };

    const { type, data } = yield Call(SelectorsMiddleware.buySharesPreviewRequest, requestData);

    if (type === RESPONSE_TYPE.WARNING) {
        formikBag.setErrors(data.data);
        yield Put(SelectorsAction.buySharesPreviewFailure());

        const { code } = data;

        if (NO_W8_AVAILABLE === code || NO_RISK_PROFILE_AVAILABLE === code) {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.BUY_SHARES],
                }),
            );
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get("forms.fieldsErrors"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.BUY_SHARES],
                }),
            );
        }
    } else {
        yield Put(SelectorsAction.buySharesPreviewSuccess({ ...data.data }));
    }
}

function* buySharesSendFormRequest({ submitData, credentials, formikBag }) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { type, data } = yield Call(SelectorsMiddleware.buySharesRequest, {
        ...submitData,
        ...credentialsWithUnderscore,
    });
    const { idTransaction } = data;
    formikBag.setSubmitting(false);
    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);
        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get("forms.cancelTransaction.errorMessage"),
                    level: LEVEL.ERROR,
                    scopes: SCOPE.BUY_SHARES,
                }),
            );
        }
        yield Put(SelectorsAction.buySharesSendFormFailure());
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(SelectorsAction.buySharesSendFormSuccess({ transaction }));
    }
}

function* sellSharesPre(props) {
    const { type, data } = yield Call(SelectorsMiddleware.sellSharesPre, props);
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.sellSharesPreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.SELL_SHARES],
            }),
        );
    } else {
        yield Put(SelectorsAction.sellSharesPreSuccess({ ...data.data }));
    }
}

function* sellSharesPreview({ formData, formikBag }) {
    const { type, data } = yield Call(SelectorsMiddleware.sellSharesPreview, { ...formData });
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.sellSharesPreviewFailure());
        const errorMessage = data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS);

        formikBag.setErrors(AdjustIdFieldErrors(data.data));
        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.SELL_SHARES],
            }),
        );
    } else {
        yield Put(SelectorsAction.sellSharesPreviewSuccess({ ...data.data }));
    }
}

function* sellSharesSend({ formData, credentials, formikBag }) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);
    const { type, data } = yield Call(SelectorsMiddleware.sellSharesSend, {
        ...formData,
        ...credentialsWithUnderscore,
    });
    const { idTransaction } = data;
    formikBag.setSubmitting(false);
    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);
        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get("global.unexpectedError"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.SELL_SHARES],
                }),
            );
        }
        yield Put(SelectorsAction.sellSharesSendFailure());
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(SelectorsAction.sellSharesSendSuccess({ transaction }));
    }
}

function* readTransactionRequest(props) {
    const { idTransaction } = props;
    const { type, data } = yield Call(SelectorsMiddlewareForm.readTransaction, {
        idTransactionToRead: idTransaction,
    });
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.SELL_SHARES],
            }),
        );
    } else {
        yield Put(SelectorsAction.readTransactionSuccess({ transaction: data.data.transaction }));
    }
}

function* cancelTransaction({ credentials, idTransaction, formikBag }) {
    formikBag.setSubmitting(false);

    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);
    const {
        data: { data },
        type,
    } = yield Call(SelectorsMiddlewareForm.cancelTransaction, {
        idTransactionToCancel: idTransaction,
        ...credentialsWithUnderscore,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data);
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get("forms.cancelTransaction.errorMessage"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.SELL_SHARES, SCOPE.BUY_SHARES],
                }),
            );
        }
    } else {
        yield* readTransactionRequest({ idTransaction });
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("forms.cancelTransaction.confirmationMessage"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.SELL_SHARES, SCOPE.DESKTOP],
            }),
        );
    }
}

function* signTransaction(props) {
    const { idForm, idActivity, idTransaction, credentials, formikBag } = props;
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { data, type } = yield Call(
        SelectorsMiddlewareForm.sign,
        { idForm, idTransaction, ...credentialsWithUnderscore },
        idActivity,
    );
    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);
        if (hasIncorrectCredentials) {
            formikBag.setErrors(AdjustIdFieldErrors(data.data));
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.SELL_SHARES, SCOPE.BUY_SHARES],
                }),
            );
        }
        formikBag.setSubmitting(false);
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        if (idActivity === "client.wm.shares.buy.send") {
            yield Put(SelectorsAction.buySharesSendFormSuccess({ transaction }));
        } else {
            yield Put(SelectorsAction.sellSharesSendSuccess({ transaction }));
        }

        formikBag.setSubmitting(false);
    }
}

function* getPaper(props) {
    const { type, data } = yield Call(SelectorsMiddleware.getPaper, props);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.SELL_SHARES],
            }),
        );
    } else {
        const { content, fileName } = data.data;
        UtilDownload.downloadPdf(fileName, content);
    }
}
