import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    billingCancellationPreRequest: () => API.executeWithAccessToken(PATH.BILLING_CANCELATION_PRE_REQUEST),
    billingCancellationPreviewRequest: (props) =>
        API.executeWithAccessToken(PATH.BILLING_CANCELATION_PREVIEW_REQUEST, props),
    billingCancellationRequest: (props) => API.executeWithAccessToken(PATH.BILLING_CANCELATION_REQUEST, props),
    importCreditLetterOpenPreRequest: () => API.executeWithAccessToken(PATH.IMPORT_CREDIT_LETTER_PRE_REQUEST),
    importCreditLetterOpenPreviewRequest: (props) =>
        API.executeWithAccessToken(PATH.IMPORT_CREDIT_LETTER_PREVIEW_REQUEST, props),
    importCreditLetterOpenSendRequest: (props) =>
        API.executeWithAccessToken(PATH.IMPORT_CREDIT_LETTER_SEND_REQUEST, props),
    modifyCreditLetterPreRequest: () => API.executeWithAccessToken(PATH.MODIFY_CREDIT_LETTER_PRE_REQUEST),
    modifyCreditLetterPreviewRequest: (props) =>
        API.executeWithAccessToken(PATH.MODIFY_CREDIT_LETTER_PREVIEW_REQUEST, props),
    modifyCreditLetterRequest: (props) => API.executeWithAccessToken(PATH.MODIFY_CREDIT_LETTER_REQUEST, props),

    cancellationCreditLetterPreRequest: () => API.executeWithAccessToken(PATH.CANCELLATION_CREDIT_LETTER_PRE_REQUEST),
    cancellationCreditLetterPreviewRequest: (props) =>
        API.executeWithAccessToken(PATH.CANCELLATION_CREDIT_LETTER_PREVIEW_REQUEST, props),
    cancellationCreditLetterRequest: (props) =>
        API.executeWithAccessToken(PATH.CANCELLATION_CREDIT_LETTER_REQUEST, props),
    requestEndorsementPreRequest: () => API.executeWithAccessToken(PATH.REQUEST_ENDORSEMENT_PRE_REQUEST),
    requestEndorsementPreviewRequest: (props) =>
        API.executeWithAccessToken(PATH.REQUEST_ENDORSEMENT_PREVIEW_REQUEST, props),
    requestEndorsementRequest: (props) => API.executeWithAccessToken(PATH.REQUEST_ENDORSEMENT_REQUEST, props),
    liftCreditLetterDiscrepanciesPreRequest: () =>
        API.executeWithAccessToken(PATH.LIFT_CREDIT_LETTER_DISCREPANCIES_PRE_REQUEST),
    liftCreditLetterDiscrepanciesPreviewRequest: (props) =>
        API.executeWithAccessToken(PATH.LIFT_CREDIT_LETTER_DISCREPANCIES_PREVIEW_REQUEST, props),
    liftCreditLetterWithDiscrepanciesRequest: (props) =>
        API.executeWithAccessToken(PATH.LIFT_CREDIT_LETTER_DISCREPANCIES_SEND_REQUEST, props),

    documentPresentationPreRequest: () => API.executeWithAccessToken(PATH.DOCUMENT_PRESENTATION_PRE_REQUEST),
    documentPresentationPreviewRequest: (props) =>
        API.executeWithAccessToken(PATH.DOCUMENT_PRESENTATION_PREVIEW_REQUEST, props),
    documentPresentationRequest: (props) => API.executeWithAccessToken(PATH.DOCUMENT_PRESENTATION_REQUEST, props),
};

export const SelectorsAction = {
    // billing Cancelation
    billingCancellationPreRequest: () => FactoryAction(TYPE.BILLING_CANCELATION_PRE_REQUEST),
    billingCancellationPreSuccess: (props) => FactoryAction(TYPE.BILLING_CANCELATION_PRE_SUCCESS, props),
    billingCancellationPreFailure: () => FactoryAction(TYPE.BILLING_CANCELATION_PRE_FAILURE),

    billingCancellationPreviewRequest: (props) => FactoryAction(TYPE.BILLING_CANCELATION_PREVIEW_REQUEST, props),
    billingCancellationPreviewSuccess: (props) => FactoryAction(TYPE.BILLING_CANCELATION_PREVIEW_SUCCESS, props),
    billingCancellationPreviewFailure: () => FactoryAction(TYPE.BILLING_CANCELATION_PREVIEW_FAILURE),

    billingCancellationSendRequest: (props) => FactoryAction(TYPE.BILLING_CANCELATION_SEND_FORM, props),
    billingCancellationSendSuccess: (props) => FactoryAction(TYPE.BILLING_CANCELATION_SEND_FORM_SUCCESS, props),
    billingCancellationSendFailure: () => FactoryAction(TYPE.BILLING_CANCELATION_SEND_FORM_FAILURE),
    // FIN billing Cancelation

    importCreditLetterOpenPreRequest: () => FactoryAction(TYPE.IMPORT_CREDIT_LETTER_PRE_REQUEST),
    importCreditLetterOpenPreSuccess: (props) => FactoryAction(TYPE.IMPORT_CREDIT_LETTER_PRE_SUCCESS, props),
    importCreditLetterOpenPreFailure: () => FactoryAction(TYPE.IMPORT_CREDIT_LETTER_PRE_FAILURE),

    importCreditLetterOpenPreviewRequest: (props) => FactoryAction(TYPE.IMPORT_CREDIT_LETTER_PREVIEW_REQUEST, props),
    importCreditLetterOpenPreviewSuccess: (props) => FactoryAction(TYPE.IMPORT_CREDIT_LETTER_PREVIEW_SUCCESS, props),
    importCreditLetterOpenPreviewFailure: (props) => FactoryAction(TYPE.IMPORT_CREDIT_LETTER_PREVIEW_FAILURE, props),

    importCreditLetterOpenSendRequest: (props) => FactoryAction(TYPE.IMPORT_CREDIT_LETTER_SEND_REQUEST, props),
    importCreditLetterOpenSendSuccess: (props) => FactoryAction(TYPE.IMPORT_CREDIT_LETTER_SEND_SUCCESS, props),
    importCreditLetterOpenSendFailure: (props) => FactoryAction(TYPE.IMPORT_CREDIT_LETTER_SEND_FAILURE, props),

    getCancellationCreditLetterPreRequest: () => FactoryAction(TYPE.COMEX_CANCELLATION_CREDIT_LETTER_PRE_REQUEST),
    getCancellationCreditLetterPreSuccess: (props) =>
        FactoryAction(TYPE.COMEX_CANCELLATION_CREDIT_LETTER_PRE_SUCCESS, props),
    getCancellationCreditLetterPreFailure: (props) =>
        FactoryAction(TYPE.COMEX_CANCELLATION_CREDIT_LETTER_PRE_FAILURE, props),

    cancellationCreditLetterPreviewRequest: (props) =>
        FactoryAction(TYPE.CANCELLATION_CREDIT_LETTER_PREVIEW_REQUEST, props),
    cancellationCreditLetterPreviewSuccess: (props) =>
        FactoryAction(TYPE.CANCELLATION_CREDIT_LETTER_PREVIEW_SUCCESS, props),
    cancellationCreditLetterPreviewFailure: () => FactoryAction(TYPE.CANCELLATION_CREDIT_LETTER_PREVIEW_FAILURE),

    cancellationCreditLetterSendRequest: (props) => FactoryAction(TYPE.CANCELLATION_CREDIT_LETTER_SEND_REQUEST, props),
    cancellationCreditLetterSendSuccess: (props) => FactoryAction(TYPE.CANCELLATION_CREDIT_LETTER_SEND_SUCCESS, props),
    cancellationCreditLetterSendFailure: () => FactoryAction(TYPE.CANCELLATION_CREDIT_LETTER_SEND_FAILURE),

    modifyCreditLetterPreRequest: () => FactoryAction(TYPE.MODIFY_CREDIT_LETTER_PRE_REQUEST),
    modifyCreditLetterPreSuccess: (props) => FactoryAction(TYPE.MODIFY_CREDIT_LETTER_PRE_SUCCESS, props),
    modifyCreditLetterPreFailure: () => FactoryAction(TYPE.MODIFY_CREDIT_LETTER_PRE_FAILURE),

    modifyCreditLetterPreviewRequest: (props) => FactoryAction(TYPE.MODIFY_CREDIT_LETTER_PREVIEW_REQUEST, props),
    modifyCreditLetterPreviewSuccess: (props) => FactoryAction(TYPE.MODIFY_CREDIT_LETTER_PREVIEW_SUCCESS, props),
    modifyCreditLetterPreviewFailure: () => FactoryAction(TYPE.MODIFY_CREDIT_LETTER_PREVIEW_FAILURE),

    modifyCreditLetterSendRequest: (props) => FactoryAction(TYPE.MODIFY_CREDIT_LETTER_SEND_FORM, props),
    modifyCreditLetterSendSuccess: (props) => FactoryAction(TYPE.MODIFY_CREDIT_LETTER_SEND_FORM_SUCCESS, props),
    modifyCreditLetterSendFailure: () => FactoryAction(TYPE.MODIFY_CREDIT_LETTER_SEND_FORM_FAILURE),

    liftCreditLetterDiscrepanciesPreRequest: () => FactoryAction(TYPE.LIFT_CREDIT_LETTER_DISCREPANCIES_PRE_REQUEST),
    liftCreditLetterDiscrepanciesPreSuccess: (props) =>
        FactoryAction(TYPE.LIFT_CREDIT_LETTER_DISCREPANCIES_PRE_SUCCESS, props),
    liftCreditLetterDiscrepanciesPreFailure: () => FactoryAction(TYPE.LIFT_CREDIT_LETTER_DISCREPANCIES_PRE_FAILURE),

    liftCreditLetterDiscrepanciesPreviewRequest: (props) =>
        FactoryAction(TYPE.LIFT_CREDIT_LETTER_DISCREPANCIES_PREVIEW_REQUEST, props),
    liftCreditLetterDiscrepanciesPreviewSuccess: (props) =>
        FactoryAction(TYPE.LIFT_CREDIT_LETTER_DISCREPANCIES_PREVIEW_SUCCESS, props),
    liftCreditLetterDiscrepanciesPreviewFailure: () =>
        FactoryAction(TYPE.LIFT_CREDIT_LETTER_DISCREPANCIES_PREVIEW_FAILURE),

    liftCreditLetterDiscrepanciesSendRequest: (props) =>
        FactoryAction(TYPE.LIFT_CREDIT_LETTER_DISCREPANCIES_SEND_FORM, props),
    liftCreditLetterDiscrepanciesSendSuccess: (props) =>
        FactoryAction(TYPE.LIFT_CREDIT_LETTER_DISCREPANCIES_SEND_FORM_SUCCESS, props),
    liftCreditLetterDiscrepanciesSendFailure: () =>
        FactoryAction(TYPE.LIFT_CREDIT_LETTER_DISCREPANCIES_SEND_FORM_FAILURE),

    requestEndorsementPreRequest: (props) => FactoryAction(TYPE.REQUEST_ENDORSEMENT_PRE_REQUEST, props),
    requestEndorsementPreSuccess: (props) => FactoryAction(TYPE.REQUEST_ENDORSEMENT_PRE_SUCCESS, props),
    requestEndorsementPreFailure: () => FactoryAction(TYPE.REQUEST_ENDORSEMENT_PRE_FAILURE),

    requestEndorsementPreviewRequest: (props) => FactoryAction(TYPE.REQUEST_ENDORSEMENT_PREVIEW_REQUEST, props),
    requestEndorsementPreviewSuccess: (props) => FactoryAction(TYPE.REQUEST_ENDORSEMENT_PREVIEW_SUCCESS, props),
    requestEndorsementPreviewFailure: () => FactoryAction(TYPE.REQUEST_ENDORSEMENT_PREVIEW_FAILURE),

    requestEndorsementSendRequest: (props) => FactoryAction(TYPE.REQUEST_ENDORSEMENT_SEND_REQUEST, props),
    requestEndorsementSendSuccess: (props) => FactoryAction(TYPE.REQUEST_ENDORSEMENT_SEND_SUCCESS, props),
    requestEndorsementSendFailure: () => FactoryAction(TYPE.REQUEST_ENDORSEMENT_SEND_FAILURE),

    // document Presentation Import
    documentPresentationPreRequest: () => FactoryAction(TYPE.DOCUMENT_PRESENTATION_PRE_REQUEST),
    documentPresentationPreSuccess: (props) => FactoryAction(TYPE.DOCUMENT_PRESENTATION_PRE_SUCCESS, props),
    documentPresentationPreFailure: () => FactoryAction(TYPE.DOCUMENT_PRESENTATION_PRE_FAILURE),

    documentPresentationPreviewRequest: (props) => FactoryAction(TYPE.DOCUMENT_PRESENTATION_PREVIEW_REQUEST, props),
    documentPresentationPreviewSuccess: (props) => FactoryAction(TYPE.DOCUMENT_PRESENTATION_PREVIEW_SUCCESS, props),
    documentPresentationPreviewFailure: () => FactoryAction(TYPE.DOCUMENT_PRESENTATION_PREVIEW_FAILURE),

    documentPresentationSendRequest: (props) => FactoryAction(TYPE.DOCUMENT_PRESENTATION_SEND_FORM, props),
    documentPresentationSendSuccess: (props) => FactoryAction(TYPE.DOCUMENT_PRESENTATION_SEND_FORM_SUCCESS, props),
    documentPresentationSendFailure: () => FactoryAction(TYPE.DOCUMENT_PRESENTATION_SEND_FORM_FAILURE),

    comexFormSendDataFailure: () => FactoryAction(TYPE.COMEX_FORM_SEND_DATA_FAILURE),

    // Restart flow
    restartFlow: () => FactoryAction(TYPE.RESTART_FLOW),
};

export const SelectorsStore = {
    getFetching: (store) => store[NAME].fetching,
    getMode: (store) => store[NAME].mode,
    getPrevMode: (store) => store[NAME].prevMode,
    getPositions: (store) => store[NAME].positions,
    getTransaction: (store) => store[NAME].transaction,
    getTransactionList: (store) => store[NAME].transactionList,

    getBillingCancellationOpenPre: (store) => store[NAME].billingCancelation.preData,
    getBillingCancellationFormData: (store) => store[NAME].billingCancelation.formData,

    getImportCreditLetterOpenPre: (store) => store[NAME].creditLetterOpen.preData,
    getImportCreditLetterOpenFormData: (store) => store[NAME].creditLetterOpen.formData,

    getModifyCreditLetterOpenPre: (store) => store[NAME].modifyCreditLetter.preData,
    getModifyCreditLetterOpenFormData: (store) => store[NAME].modifyCreditLetter.formData,
    getModifyCreditLetterOpenPrevData: (store) => store[NAME].modifyCreditLetter.prevData,

    getCancellationCreditLetterPre: (store) => store[NAME].cancellationCreditLetter.preData,
    getCancellationCreditLetterFormData: (store) => store[NAME].cancellationCreditLetter.formData,

    getLiftCreditLetterDiscrepanciesPre: (store) => store[NAME].liftCreditLetterDiscrepancies.preData,
    getLiftCreditLetterDiscrepanciesFormData: (store) => store[NAME].liftCreditLetterDiscrepancies.formData,
    getLiftCreditLetterDiscrepanciesPrevData: (store) => store[NAME].liftCreditLetterDiscrepancies.prevData,

    getRequestEndorsementFromData: (store) => store[NAME].requestEndorsement.formData,
    getRequestEndorsementPre: (store) => store[NAME].requestEndorsement.preData,

    getDocumentPresentationPre: (store) => store[NAME].documentPresentation.preData,
    getDocumentPresentationFormData: (store) => store[NAME].documentPresentation.formData,
};
