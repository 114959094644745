import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    recoveryPassNoTokenStep1: (props) => API.executeAnonymous(PATH.SESSION_RECOVER_PASSWORD_NO_TOKEN_STEP1, props),
    recoveryPassNoTokenStep2: (props, exchangeToken) =>
        API.executeWithExchangeToken(PATH.SESSION_RECOVER_PASSWORD_NO_TOKEN_STEP2, props, exchangeToken),
    recoveryPassNoTokenStep3: (props, exchangeToken) =>
        API.executeWithExchangeToken(PATH.SESSION_RECOVER_PASSWORD_NO_TOKEN_STEP3, props, exchangeToken),
    recoveryPassNoTokenStep4: (props, exchangeToken) =>
        API.executeWithExchangeToken(PATH.SESSION_RECOVER_PASSWORD_NO_TOKEN_STEP4, props, exchangeToken),
    resendVerificationCode: (props, exchangeToken) =>
        API.executeWithExchangeToken(PATH.SESSION_RECOVER_PASSWORD_NO_TOKEN_RESEND_SMS, props, exchangeToken),
};

export const SelectorsAction = {
    recoverPassCleanUp: () => FactoryAction(TYPE.RECOVER_PASS_CLEAN),
    recoverPassStep1Request: (props) => FactoryAction(TYPE.RECOVER_PASS_NO_TOKEN_STEP1_REQUEST, props),
    recoverPassStep1Failure: (props) => FactoryAction(TYPE.RECOVER_PASS_NO_TOKEN_STEP1_FAILURE, props),
    recoverPassStep1Success: (props) => FactoryAction(TYPE.RECOVER_PASS_NO_TOKEN_STEP1_SUCCESS, props),
    recoverPassStep2Request: (props) => FactoryAction(TYPE.RECOVER_PASS_NO_TOKEN_STEP2_REQUEST, props),
    recoverPassStep2Failure: (props) => FactoryAction(TYPE.RECOVER_PASS_NO_TOKEN_STEP2_FAILURE, props),
    recoverPassStep2Success: (props) => FactoryAction(TYPE.RECOVER_PASS_NO_TOKEN_STEP2_SUCCESS, props),
    recoverPassStep3Request: (props) => FactoryAction(TYPE.RECOVER_PASS_NO_TOKEN_STEP3_REQUEST, props),
    recoverPassStep3Failure: (props) => FactoryAction(TYPE.RECOVER_PASS_NO_TOKEN_STEP3_FAILURE, props),
    recoverPassStep3Success: (props) => FactoryAction(TYPE.RECOVER_PASS_NO_TOKEN_STEP3_SUCCESS, props),
    recoverPassStep4Request: (props) => FactoryAction(TYPE.RECOVER_PASS_NO_TOKEN_STEP4_REQUEST, props),
    recoverPassStep4Failure: (props) => FactoryAction(TYPE.RECOVER_PASS_NO_TOKEN_STEP4_FAILURE, props),
    recoverPassStep4Success: (props) => FactoryAction(TYPE.RECOVER_PASS_NO_TOKEN_STEP4_SUCCESS, props),
    recoverPassResendRequest: (props) => FactoryAction(TYPE.RESEND_VERIFICATION_CODE_REQUEST, props),
    recoverPassResendFailure: (props) => FactoryAction(TYPE.RESEND_VERIFICATION_CODE_FAILURE, props),
    recoverPassResendSuccess: (props) => FactoryAction(TYPE.RESEND_VERIFICATION_CODE_SUCCESS, props),
    rememberUsername: (props) => FactoryAction(TYPE.RECOVER_PASS_REMEMBER_USERNAME, props),
};

export const SelectorsStore = {
    getMobileNumber: (store) => store[NAME].mobileNumber,
    getExchangeToken: (store) => store[NAME].exchangeToken,
    getVerificationCode: (store) => store[NAME].verificationCode,
    isFetching: (store) => store[NAME].fetching,
};
