import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "config";

export const PATH = {
    CONFIGURATION_LIST_CONFIGURATION: "/configuration.listConfiguration",
    CONFIGURATION_MIN_VERSION_APP: "/configuration.minVersionApp",
};

export const TYPE = FactoryType(NAME, ["UPDATE_REQUEST", "UPDATE_SUCCESS", "UPDATE_FAILURE", "IS_TRUSTED"]);

export const STORE = {
    SEP_ARRAY: "|",
    TYPE_ARRAY: "Array",
    TYPE_NUMBER: "Number",
    TYPE_BOOLEAN: "Boolean",
};

export const PROP = {};

PROP.types = {
    fetching: PropTypes.bool,
    languages: PropTypes.array,
    isTrusted: PropTypes.bool,
    items: PropTypes.object,
    ...TypesRedux,
};

PROP.defaults = {
    fetching: true,
    languages: [],
    isTrusted: false,
    items: {},
    ...DefaultsRedux,
};
