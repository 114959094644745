import PropTypes from "prop-types";

import { ToRequired } from "~/util/prop";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "preferentialTradingPrice";

export const PATH = {
    PREFERENTIAL_RATE: "/preferentialRate",
    BULLETIN_LIST: "/preferentialRateBulletin.execute",
    BULLETIN_DOWNLOAD_PDF: "preferentialRateBulletin.download",
    PREFERENTIAL_TRADING_PRICE_DISCLAIMER: "/preferentialTradingPrice.disclaimer",
    PREFERENTIAL_TRADING_PRICE_HISTORICAL_RATE: "/preferentialTradingPrice.historicalRate",
    PREFERENTIAL_TRADING_PRICE: "/preferentialTradingPrice.pre",
    PREFERENTIAL_TRADING_PRICE_PREVIEW: "/preferentialTradingPrice.preview",
    PREFERENTIAL_TRADING_PRICE_SEND: "/preferentialTradingPrice.send",
    PREFERENTIAL_TRADING_PRICE_TICKET: "/preferentialTradingPrice.ticket",
};

export const OPERATIONS = {
    BUYING: "BUYING",
    SELLING: "SELLING",
};

export const OPERATION_TYPE = {
    BANK_IS_BUYING: "bankIsBuying",
    BANK_IS_SELLING: "bankIsSelling",
};

export const FXSTATE = {
    INITIALOADING: "INITIALOADING",
    LOADING: "LOADING",
    FORM: "FORM",
    PREVIEW: "PREVIEW",
    SEND: "SEND",
};

export const LAST_CHANGED_INPUT = {
    DEBIT: "D",
    CREDIT: "C",
};

export const TYPE = FactoryType(NAME, [
    "REQUEST_FX",
    "SUCCESS_FX",
    "REQUEST_PREVIEW_FX",
    "SUCCESS_PREVIEW_FX",
    "HISTORICAL_RATES_REQUEST",
    "HISTORICAL_RATES_SUCCESS",
    "HISTORICAL_RATES_FAILURE",
    "REQUEST_SEND_FX",
    "SUCCESS_SEND_FX",
    "ERROR_PREVIEW_FX",
    "RETURN_TO_FORM",
    "ERROR_SEND_FX",
    "CHANGE_OPERATION",
    "CHANGE_DEBIT_ACCOUNT",
    "CHANGE_CREDIT_ACCOUNT",
    "CHANGE_AMOUNT",
    "CHANGE_CURRENCY",
    "CHANGE_OPERATION_SELLING",
    "CHANGE_OPERATION_BUYING",
    "REQUEST_COTIZACION",
    "SUCCESS_COTIZACION",
    "STOP_POLL_COTIZATION",
    "IS_TOUCHED",
    "CLOSE_PAGE",
    "DISCLAIMER",
    "SUCCESS_PDF",
    "ERROR_PDF",
    "RENDER_FORM_WAITER",
    "READ_FX_TICKET_REQUEST",
    "READ_FX_TICKET_SUCCESS",
    "CLEAN_UP",
    "SET_SHOW_BULLETIN_MODAL",
    "BULLETIN_LIST_REQUEST",
    "BULLETIN_LIST_FAILURE",
    "BULLETIN_LIST_SUCCESS",
    "BULLETIN_PDF_DOWNLOAD_REQUEST",
    "BULLETIN_PDF_DOWNLOAD_FAILURE",
    "BULLETIN_PDF_DOWNLOAD_SUCCESS",
]);

export const PROP = {};

const quotesPropType = {
    currency1: PropTypes.string,
    currency2: PropTypes.string,
    currency1Label: PropTypes.string,
    currency2Label: PropTypes.string,
    buyingRate: PropTypes.number,
    sellingRate: PropTypes.number,
    buyingRateClient: PropTypes.string,
    sellingRateClient: PropTypes.string,
    decimalsToShow: PropTypes.number,
    amountDecimals: PropTypes.number,
    trend: PropTypes.string,
    decimalSeparator: PropTypes.string,
    thousandsSeparator: PropTypes.string,
};

const accountPropType = {
    value: PropTypes.string,
    isFrequestDestination: PropTypes.bool,
};

const currencyOptionsPropType = {
    value: PropTypes.shape(quotesPropType),
    currency1: PropTypes.string,
    currency2: PropTypes.string,
    label: PropTypes.string,
    label2: PropTypes.string,
};

const previewDataPropType = {
    quotationCurrency1: PropTypes.string,
    quotationCurrency2: PropTypes.string,
    sourceCurrency: PropTypes.string,
    sourceAmount: PropTypes.string,
    quotation: PropTypes.string,
    destinationCurrency: PropTypes.string,
    sourceAccount: PropTypes.string,
    destinationAmount: PropTypes.string,
    destinationAccount: PropTypes.string,
};

const formDataPropType = {
    ...TypesRedux,
    currencyOptions: PropTypes.arrayOf(PropTypes.shape(currencyOptionsPropType)),
    selectedCurrency: PropTypes.shape(currencyOptionsPropType),
    areFXAccountSelectAble: PropTypes.bool,
    newOptionsAccountDebit: PropTypes.array,
    newOptionsAccountCredit: PropTypes.array,
    debitValue: PropTypes.oneOfType([PropTypes.shape(accountPropType)]),
    creditValue: PropTypes.oneOfType([PropTypes.shape(accountPropType)]),
    handleChangeDebitAmount: PropTypes.func,
    handleChangeCreditAmount: PropTypes.func,
    thousandsSeparator: PropTypes.string,
    decimalSeparator: PropTypes.string,
    amountDecimals: PropTypes.number,
    currency1Label: PropTypes.string,
    currency2Label: PropTypes.string,
    debitAmount: PropTypes.number,
    creditAmount: PropTypes.number,
    currencyLabel: PropTypes.string,
    lastTypedInput: PropTypes.string,
    lastTypedValue: PropTypes.number,
    buyingRate: PropTypes.number,
    sellingRate: PropTypes.number,
    buyingRateClient: PropTypes.string,
    sellingRateClient: PropTypes.string,
    operation: PropTypes.string,
    handleBlurError: PropTypes.func,
    currency1: PropTypes.string,
    currency2: PropTypes.string,
    accountList: PropTypes.object,
    quotes: PropTypes.arrayOf(PropTypes.shape(quotesPropType)),
    requestRate: PropTypes.bool,
    currencyChanged: PropTypes.bool,
    activeEnvironment: PropTypes.oneOfType([PropTypes.object]),
};

const sendDataPropType = {
    previewData: PropTypes.shape(previewDataPropType),
    user: PropTypes.string,
    date: PropTypes.string,
    transactionStatus: PropTypes.string,
    idTransaction: PropTypes.string,
    transaction: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), // TODO: ver si se puede definir mejor
    token: PropTypes.string,
};

PROP.members = {
    quotes: quotesPropType,
    account: accountPropType,
    currencyOptions: currencyOptionsPropType,
    previewData: previewDataPropType,
    formData: formDataPropType,
    sendData: sendDataPropType,
};

PROP.types = {
    ...ToRequired(TypesRedux, ["dispatch"]),
    previewData: PropTypes.shape(PROP.members.previewData),
    sendData: PropTypes.shape(PROP.members.sendData),
    formData: PropTypes.shape(PROP.members.formData),
    rates: PropTypes.arrayOf(PropTypes.shape(PROP.members.quotes)),
    historicalRates: PropTypes.array,
    fetchingHistoricalRates: PropTypes.bool,
    fetching: PropTypes.bool,
    renderFormWaiter: PropTypes.bool,
    operation: PropTypes.string,
    fxState: PropTypes.string,
    pollCotizacion: PropTypes.bool,
    quotation: PropTypes.string,
    errors: PropTypes.array,
    transactionData: PropTypes.object,
    showBulletinModal: PropTypes.bool,
    isFetchingBulletin: PropTypes.bool,
    bulletinList: PropTypes.array,
    stepTransaction: PropTypes.bool,
    processingTransactionsList: PropTypes.array,
};

PROP.accountSelectors = {
    accountValue: PropTypes.oneOfType([PropTypes.shape(PROP.members.account)]),
    fieldName: PropTypes.string,
    errors: PropTypes.array,
    message: PropTypes.string,
    newOptionsAccount: PropTypes.arrayOf(PropTypes.shape(PROP.members.account)),
};

PROP.defaultsFormData = {
    DefaultsRedux,
    newOptionsAccountDebit: [],
    newOptionsAccountCredit: [],
    buyingRate: null,
    sellingRate: null,
    buyingRateClient: "",
    sellingRateClient: "",
    operation: OPERATIONS.BUYING,
    debitAccounts: null,
    creditAccounts: null,
    currency1: "",
    currency2: "",
    debitAmount: null,
    creditAmount: null,
    lastTypedInput: null,
    areFXAccountSelectAble: false,
    rates: null,
    quotation: null,
    debitValue: null,
    creditValue: null,
    accountList: null,
    quotes: null,
    currencyOptions: null,
    selectedCurrency: null,
    amountDecimals: 2,
    thousandsSeparator: ".",
    decimalSeparator: ",",
    requestRate: false,
    currencyChanged: false,
};

PROP.defaultsAccountsSelectors = {
    accountValue: null,
    newOptionsAccount: null,
};

PROP.amountFx = {
    fieldName: PropTypes.string,
    thousandsSeparator: PropTypes.string,
    decimalSeparator: PropTypes.string,
    precision: PropTypes.number,
    currencyLabel: PropTypes.string,
    amount: PropTypes.number,
    errors: PropTypes.array,
    message: PropTypes.string,
};

PROP.defaultAmountFx = {
    fieldName: "",
    thousandsSeparator: "",
    decimalSeparator: "",
    precision: null,
    currencyLabel: "",
    amount: 0,
    errors: [],
    message: "",
};

PROP.buyingAndSelling = {
    buyingRateClient: PropTypes.string,
    sellingRateClient: PropTypes.string,
    currencyLabel: PropTypes.string,
    operation: PropTypes.string,
};
PROP.defaultBuyingAndSelling = {
    buyingRateClient: "",
    sellingRateClient: "",
    currencyLabel: "",
    operation: "",
};

PROP.password = {
    autoComplete: PropTypes.string,
    maxLength: PropTypes.number,
    autoFocus: PropTypes.bool,
    showStrength: PropTypes.bool,
};
PROP.defaultPassword = {
    autoComplete: "on",
    maxLength: 50,
    autoFocus: false,
    showStrength: false,
};
PROP.textField = {
    type: PropTypes.string,
    autoComplete: PropTypes.string,
    maxLength: PropTypes.number,
    pattern: PropTypes.string,
    autoFocus: PropTypes.bool,
    inputFunctions: PropTypes.element,
    inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
    hidelabel: PropTypes.bool,
    handleOnChange: PropTypes.func,
    isDesktop: PropTypes.bool,
    isMobile: PropTypes.bool,
    isMobileNative: PropTypes.bool,
    mobileOS: PropTypes.string,
    renderAs: PropTypes.string,
    autoCapitalize: PropTypes.string,
    mode: PropTypes.string,
    fieldName: PropTypes.string,
    token: PropTypes.string,
    errors: PropTypes.array,
    message: PropTypes.string,
};

PROP.defaultTextField = {
    type: "text",
    mode: "edit",
    autoComplete: "on",
    maxLength: 50,
    autoFocus: false,
    inputFunctions: null,
    pattern: null,
    hidelabel: false,
    renderAs: "input",
    autoCapitalize: "sentences",
    isMobile: false,
    isMobileNative: false,
    handleOnChange: null,
    mobileOS: "",
    isDesktop: false,
    fieldName: "",
    errors: null,
};

PROP.timer = {
    second: PropTypes.string,
    handleInterval: PropTypes.func,
    handleTimeOut: PropTypes.func,
    customTimeRender: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};
PROP.defaultTimer = { second: 0, handleInterval: () => {}, handleTimeOut: () => {}, customTimeRender: {} };

PROP.defaultsPreviewData = {
    DefaultsRedux,
    quotationCurrency1: "",
    quotationCurrency2: "",
    sourceCurrency: "",
    sourceAmount: "",
    quotation: "",
    destinationCurrency: "",
    sourceAccount: "",
    destinationAmount: "",
    destinationAccount: "",
};

PROP.defaultSendData = {
    DefaultsRedux,
    transaction: null,
    token: null,
};

PROP.defaults = {
    DefaultsRedux,
    fetching: false,
    renderFormWaiter: false,
    fxState: FXSTATE.INITIALOADING,
    operation: OPERATIONS.BUYING,
    pollCotizacion: false,
    exchangeValueChanged: false,
    rateChange: false,
    rates: null,
    historicalRates: [],
    fetchingHistoricalRates: false,
    quotation: null,
    errors: null,
    formData: PROP.defaultsFormData,
    previewData: PROP.defaultsPreviewData,
    sendData: PROP.defaultSendData,
    bulletinList: [],
    isFetchingBulletin: false,
    showBulletinModal: false,
    transactionData: {},
    stepTransaction: false,
    processingTransactionsList: [],
};
