import React from "react";

import ClassNames from "classnames";
import { connect as Connect } from "react-redux";

import { CDP_CURRENCY, DATA_NOT_AVAILABLE, ZERO } from "~/constants";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsStore as StoreWidget, SelectorsAction as ActionWidget } from "~/store/widget";
import * as ConfigUtil from "~/util/config";

import DataDate from "~/components/DataDate";
import DataNumber from "~/components/DataNumber";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";
import Slides from "~/components/Slides";

import Style from "./CreditCards.rules.scss";
import WidgetCard from "./_common/WidgetCard";
import Widget from "./_common/_Widget";

export const { TYPE, NAME } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const { cards, debitCards, dispatch, isFetching, store: widget, ...rest } = props;
    const brandList = ConfigUtil.getArray("client.productTypes.creditCardWithAvailableBalance.list");
    const configCreditPrefix = "client.creditcard.type.";
    const configDebitPrefix = "client.debitcard.type.";

    React.useEffect(() => {
        dispatch(ActionWidget.listRequest({ widget }));
        dispatch(ActionWidget.listRequest({ widget: "debitCards" }));
    }, [dispatch, widget]);

    const [selectedTab, setSelectedTab] = React.useState("credit");

    const hasCreditCard = cards.length > 0;
    const hasDebitCard = debitCards.length > 0;

    React.useEffect(() => {
        if (!isFetching) {
            if (hasCreditCard) {
                setSelectedTab("credit");
            } else if (hasDebitCard) {
                setSelectedTab("debit");
            } else {
                setSelectedTab("credit");
            }
        }
    }, [hasCreditCard, hasDebitCard, isFetching]);

    const handleChange = (e) => {
        setSelectedTab(e.target.value);
    };

    return (
        <Widget
            {...rest}
            id={Style.ID}
            wait={isFetching}
            header-title="menu.creditcards"
            header-icon="menu-cards.svg"
            header-href={!cards.length ? "/form/requestCreditCard" : "/creditCards"}>
            {/* Credit Cards */}
            <div className="container-tab needsclick">
                <input
                    type="radio"
                    id="credit"
                    className={ClassNames("needsclick", { "only-credit": !hasDebitCard })}
                    value="credit"
                    name="card-tab"
                    onChange={handleChange}
                    checked={selectedTab === "credit"}
                />
                <label
                    htmlFor="credit"
                    className={ClassNames("credit", "needsclick", { "only-credit": !hasDebitCard })}>
                    <I18n className="needsclick" id="desktop.widgets.creditCards.title" />
                </label>
                <div className="tab">
                    {hasCreditCard ? (
                        <Slides>
                            {cards.map((card) => {
                                const {
                                    availableBalance,
                                    availableCurrency,
                                    brand,
                                    dueDate,
                                    idProduct,
                                    isExpired,
                                    number,
                                    productAlias,
                                    paymentUSD,
                                    paymentUYU,
                                    typeCode,
                                } = card;

                                const cardHasAvailableBalance = brandList.includes(brand);
                                const typeCodeCreditCard = typeCode !== ZERO ? typeCode : `${ZERO}.${brand}`;
                                const creditCardImageCode = ConfigUtil.get(
                                    `${configCreditPrefix}${typeCodeCreditCard}`,
                                );
                                const image = `cards/${creditCardImageCode}.png`;

                                return (
                                    <WidgetCard
                                        key={idProduct}
                                        href={`/creditCards/${idProduct}`}
                                        image={image}
                                        title={`${productAlias} ${number}`}
                                        data-isExpired={isExpired || undefined}>
                                        {cardHasAvailableBalance
                                            ? [
                                                  <DataDate key="creditCards.list.item.expiration" value={dueDate} />,
                                                  <div key="creditCard.availableBalance">
                                                      {availableBalance ? (
                                                          <DataNumber
                                                              prefix={availableCurrency}
                                                              value={availableBalance}
                                                          />
                                                      ) : (
                                                          DATA_NOT_AVAILABLE
                                                      )}
                                                  </div>,
                                                  <div key="widget.creditCard.totalToPay">
                                                      <DataNumber prefix={CDP_CURRENCY.UYU} value={paymentUYU} />
                                                      <DataNumber prefix={CDP_CURRENCY.USD} value={paymentUSD} />
                                                  </div>,
                                              ]
                                            : [
                                                  <DataDate key="creditCards.list.item.expiration" value={dueDate} />,
                                                  <React.Fragment key="widget.creditCard.totalToPay">
                                                      <DataNumber prefix={CDP_CURRENCY.UYU} value={paymentUYU} />
                                                      <DataNumber prefix={CDP_CURRENCY.USD} value={paymentUSD} />
                                                  </React.Fragment>,
                                              ]}
                                    </WidgetCard>
                                );
                            })}
                        </Slides>
                    ) : (
                        <Link to="/form/requestCreditCard" role="banner" className="container-request-card">
                            <div className="container-card">
                                <Image src="cards/master-gold.png" />
                                <Image src="cards/visa-regional.png" />
                                <Image src="cards/master-premier.png" />
                            </div>
                            <div className="button">
                                <I18n id="menu.creditcards.requestCreditCard" />
                            </div>
                        </Link>
                    )}
                </div>
                {/* Debit Cards */}
                <input
                    type="radio"
                    id="debit"
                    className={ClassNames("needsclick", { hide: !hasDebitCard })}
                    value="debit"
                    name="card-tab"
                    onChange={handleChange}
                    checked={selectedTab === "debit"}
                />
                <label htmlFor="debit" className={ClassNames("debit needsclick", { hide: !hasDebitCard })}>
                    <I18n className="needsclick" id="desktop.widgets.debitCards.title" />
                </label>
                <div className="tab">
                    {hasDebitCard && (
                        <Slides>
                            {debitCards.map((card) => {
                                const {
                                    brand,
                                    cardHolder,
                                    dueDate,
                                    idProduct,
                                    isExpired,
                                    number,
                                    productAlias,
                                    typeCode,
                                } = card;

                                const typeCodeDebitCard = typeCode !== ZERO ? typeCode : `${ZERO}.${brand}`;
                                const debitCardImageCode = ConfigUtil.get(`${configDebitPrefix}${typeCodeDebitCard}`);
                                const image = `cards/${debitCardImageCode}.png`;

                                return (
                                    <WidgetCard
                                        containerStyle={!dueDate}
                                        key={idProduct}
                                        href={`/debitcard/${idProduct}`}
                                        image={image}
                                        title={`${productAlias} ${number}`}
                                        nameCard={cardHolder}
                                        data-isExpired={isExpired || undefined}>
                                        {dueDate
                                            ? [<DataDate key="creditCards.list.item.expiration" value={dueDate} />]
                                            : []}
                                    </WidgetCard>
                                );
                            })}
                        </Slides>
                    )}
                </div>
            </div>
        </Widget>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

function mapStateToProps(store, props) {
    const { store: widget } = props;
    const { isFetching: isFetchingCredit, data: dataCredit = [] } = StoreWidget.getWidget(store, widget) || {};
    const { isFetching: isFetchingDebit, data: dataDebit = [] } = StoreWidget.getWidget(store, "debitCards") || {};

    return {
        isFetching: isFetchingCredit || isFetchingDebit,
        cards: dataCredit,
        debitCards: dataDebit,
        getMessage: (key) => StoreI18n.getMessage(store, key), // forces i18n re render
    };
}

export default Connect(mapStateToProps)(Component);
