import React from "react";

import { PROP as STORE_PROP } from "~/store/preferentialTradingPrice";
import Flag from "~/util/flag";

import Image from "~/components/Image";
import Select from "~/components/Select";

export const NAME = "CurrencySelector";

export const PROP = {
    types: {
        ...STORE_PROP.types,
    },
    defaults: {
        ...STORE_PROP.defaults,
        dispatch: () => null,
        path: "",
    },
};
export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {};

    constructor(props) {
        super(props);
        this.handleSelectedChange = this.handleSelectedChange.bind(this);
        this.fxRenderer = this.fxRenderer.bind(this);
    }

    /* En este momento tenemos diferentes codigos de monedas en Nazca y en CDP.
       Con este metodo podemos utlizar los codigos correctos para las monedas habilitadas para FX.
    */
    currencyFlag = (currencyCode) => {
        const image = Flag.getFlag(currencyCode);

        return <Image src={`${image}.svg`} className="svg-icon svg-logo" />;
    };

    handleSelectedChange(selectedCurrency) {
        const { handleCurrencyChange } = this.props;
        //  Reservado para hacer cosas antes del callback
        handleCurrencyChange(selectedCurrency);
    }

    fxRenderer(option) {
        const { currency1, currency2, label, label2 } = option;
        return (
            <span className="control-label">
                {this.currencyFlag(currency2)} {label2} : {label} {this.currencyFlag(currency1)}
            </span>
        );
    }

    render() {
        const { currencies, selectedCurrency } = this.props;

        return (
            <React.Fragment>
                <div className="form-group">
                    <div className="input-group">
                        <Select
                            options={currencies}
                            className="flex-container slideFromBottom"
                            searchable={false}
                            value={selectedCurrency}
                            onChange={this.handleSelectedChange}
                            creatable={false}
                            valueRenderer={this.fxRenderer}
                            optionRenderer={this.fxRenderer}
                            clearable={false}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Component;
