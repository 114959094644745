import React from "react";

import { CDP_CURRENCY, EMPTY_STR, FORM_PATH, HSBC, ID_FORM, SAVINGS_ACCOUNT_BT_VALUE } from "~/constants";
import { MODE } from "~/constants/form";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsAction as SelectorsActionTransfers } from "~/store/transfers";
import * as UtilsConfig from "~/util/config";
import UtilsContactX from "~/util/contactX";
import UtilsDevice from "~/util/device";
import * as UtilsI18n from "~/util/i18n";
import UtilsLodash from "~/util/lodash";
import Logger from "~/util/logger";
import { GetPrefixAndNumber } from "~/util/phone";

import FieldError from "~/pages/_components/fields/FieldError";

import ModalTransferRedirect from "~/pages/forms/_components/_fields/_aliasComponents/_ModalTransferRedirect";
import RadioButtonGroup from "~/pages/forms/_components/_fields/_commons/RadioButtonGroup";

export const NAME = "AliasOuterComponents";

export const PROP = {
    types: {},
    defaults: {},
};

function filterAccountsByAliasList(accountsByAlias, idForm, currency) {
    return ID_FORM.TRANSFER_LOCAL === idForm
        ? accountsByAlias.filter((account) => HSBC !== account.bcuCode && account.currency === currency)
        : accountsByAlias.filter((account) => HSBC === account.bcuCode);
}

export function Component(AliasComponent) {
    return class _Component extends React.Component {
        static displayName = NAME;

        static defaultProps = PROP.defaults;

        static propTypes = PROP.types;

        state = {
            accountsByAliasState: [],
            accountsToWorkWithState: [],
            currencyState: EMPTY_STR,
            redirectionPerformedState: false,
            selectedOption: EMPTY_STR,
            showModalState: false,
            showNoContactsPermissionError: false,
        };

        static getDerivedStateFromProps(nextProps, prevState) {
            const { accountsByAlias, accountsByAliasSearchRequested, dependencies, form, idForm } = nextProps;
            const { accountsByAliasState, accountsToWorkWithState, currencyState, selectedOption } = prevState;

            const currency = dependencies && form.values[dependencies[0]]?.currency;

            const accountsToWorkWith = filterAccountsByAliasList(accountsByAlias, idForm, currency);

            if (accountsByAliasSearchRequested && selectedOption !== EMPTY_STR) {
                return {
                    currencyState: currency && currencyState !== currency ? currency : currencyState,
                    selectedOption: EMPTY_STR,
                };
            }

            if (
                !UtilsLodash.isEqual(accountsToWorkWith, accountsToWorkWithState) ||
                !UtilsLodash.isEqual(accountsByAlias, accountsByAliasState)
            ) {
                return {
                    accountsByAliasState: accountsByAlias,
                    accountsToWorkWithState: accountsToWorkWith,
                    currencyState: currency && currencyState !== currency ? currency : currencyState,
                    showModalState:
                        ID_FORM.TRANSFER_LOCAL === idForm &&
                        accountsByAlias.some((account) => HSBC === account.bcuCode),
                    showNoContactsPermissionError: false,
                };
            }

            if (currency && currencyState !== currency) {
                return {
                    currencyState: currency,
                };
            }

            return null;
        }

        componentDidUpdate(prevProps) {
            const { accountsByAlias, dependencies: prevDependencies, form: prevForm } = prevProps;
            const { dependencies, dispatch, form, formMode, idField, idForm, isFetching, location, value } = this.props;
            const { aliasData, aliasType } = value || {};

            if (!isFetching && formMode === MODE.EDIT) {
                const {
                    accountsByAliasState,
                    accountsToWorkWithState,
                    currencyState,
                    redirectionPerformedState,
                    showModalState,
                } = this.state;

                const currency = dependencies && form.values[dependencies[0]]?.currency;
                const prevCurrency = prevDependencies && prevForm.values[prevDependencies[0]]?.currency;

                const accountsToWorkWith = filterAccountsByAliasList(accountsByAlias, idForm, currency);

                if (
                    (!UtilsLodash.isEqual(accountsToWorkWith, accountsToWorkWithState) ||
                        !UtilsLodash.isEqual(accountsByAlias, accountsByAliasState)) &&
                    showModalState
                ) {
                    dispatch(SelectorsActionTransfers.toggleShowModal({ showModal: true }));
                }

                if (
                    !UtilsLodash.isEqual(accountsToWorkWith, accountsToWorkWithState) ||
                    !UtilsLodash.isEqual(accountsByAlias, accountsByAliasState) ||
                    (ID_FORM.TRANSFER_LOCAL === idForm && currencyState !== EMPTY_STR && currencyState !== prevCurrency)
                ) {
                    if (accountsToWorkWithState.length === 1) {
                        this.handleChangeAccount(0);
                    } else {
                        const {
                            formData: { redirectionPerformed },
                        } = this.props;

                        const newValue = {
                            value: {
                                accountName: EMPTY_STR,
                                accountNumber: EMPTY_STR,
                                accountType: SAVINGS_ACCOUNT_BT_VALUE,
                                aliasData,
                                aliasType,
                                bcuCode: EMPTY_STR,
                                currency: CDP_CURRENCY.UYU,
                            },
                        };

                        if (
                            redirectionPerformed &&
                            redirectionPerformed !== redirectionPerformedState &&
                            idForm === ID_FORM.TRANSFER_THIRD_PARTIES
                        ) {
                            const {
                                formData: {
                                    otherAccountNumber,
                                    otherAccountType,
                                    otherAccountCurrency,
                                    selectedOption,
                                },
                            } = this.props;

                            newValue.value.accountNumber = Array.isArray(otherAccountNumber)
                                ? otherAccountNumber[0]
                                : otherAccountNumber;
                            newValue.value.accountType = Array.isArray(otherAccountType)
                                ? otherAccountType[0]
                                : otherAccountType;
                            newValue.value.currency = Array.isArray(otherAccountCurrency)
                                ? otherAccountCurrency[0]
                                : otherAccountCurrency;

                            this.setState({
                                redirectionPerformedState: redirectionPerformed,
                                selectedOption:
                                    selectedOption ||
                                    accountsToWorkWithState.findIndex(
                                        (account) =>
                                            account.bcuCode === HSBC &&
                                            account.accountNumber === newValue.value.accountNumber &&
                                            account.accountType === newValue.value.accountType &&
                                            account.currency === newValue.value.currency,
                                    ),
                            });
                        }

                        if (location.pathname.includes(FORM_PATH)) {
                            dispatch(SelectorsActionForm.dependeeChanged({ idField, value: newValue }));
                        }

                        if (currencyState !== EMPTY_STR && currencyState !== prevCurrency) {
                            this.setState({
                                selectedOption: EMPTY_STR,
                            });
                        }
                    }
                }
            }
        }

        handleChangeAccount = (option) => {
            const {
                accountsByAlias,
                dependencies,
                dispatch,
                field: { value },
                form,
                idField,
                idForm,
            } = this.props;
            const { setFieldValue, values } = form;
            const { searchedAlias } = values;

            const currency = dependencies && form.values[dependencies[0]]?.currency;

            const accountsToWorkWith = filterAccountsByAliasList(accountsByAlias, idForm, currency);

            const newValue = {
                value: {
                    ...value,
                    ...accountsToWorkWith[option],
                },
            };

            let newSearchedAlias = { selectedOption: accountsToWorkWith[option] };

            if (searchedAlias) {
                newSearchedAlias = {
                    ...newSearchedAlias,
                    ...searchedAlias,
                };
            }

            setFieldValue("searchedAlias", newSearchedAlias);

            dispatch(SelectorsActionForm.dependeeChanged({ idField, value: newValue }));

            this.setState({
                selectedOption: option,
            });
        };

        handleClickContacts = () => {
            const {
                dispatch,
                form: { setFieldValue },
                idField,
            } = this.props;

            UtilsContactX.getContact(
                (result) => {
                    this.setState({
                        showNoContactsPermissionError: false,
                    });

                    const { phoneNumbers } = result || {};

                    if (phoneNumbers && phoneNumbers.length > 0) {
                        const prefixUruguay = UtilsConfig.get("cellPhone.code.URY");

                        const parsedPhoneNumbers = phoneNumbers.map((phoneNumber) =>
                            GetPrefixAndNumber(phoneNumber.value),
                        );

                        let phoneToUseAsAlias = parsedPhoneNumbers.find(
                            (parsedPhoneNumber) => parsedPhoneNumber.prefix === prefixUruguay,
                        );

                        if (!phoneToUseAsAlias) {
                            // eslint-disable-next-line prefer-destructuring
                            phoneToUseAsAlias = parsedPhoneNumbers[0];
                        }

                        setFieldValue(idField, {
                            aliasData: phoneToUseAsAlias.number || EMPTY_STR,
                            aliasType: phoneToUseAsAlias.prefix || prefixUruguay,
                        });

                        dispatch(SelectorsActionTransfers.clearAccountsByAliasList());
                    }
                },
                (errorGettingContact) => {
                    this.setState({
                        showNoContactsPermissionError: true,
                    });

                    Logger.error(errorGettingContact, EMPTY_STR);

                    UtilsContactX.requestPermission(
                        (resultRequestingPermission) => {
                            this.setState({
                                showNoContactsPermissionError: !resultRequestingPermission.read,
                            });
                        },
                        (errorRequestingPermission) => {
                            this.setState({
                                showNoContactsPermissionError: true,
                            });

                            Logger.error(errorRequestingPermission, EMPTY_STR);
                        },
                    );
                },
            );
        };

        makeOptionList = (accountList) => {
            const { idForm, localBanks, showModal } = this.props;

            return accountList.map((account, i) => {
                return {
                    id: i,
                    label: `${localBanks.find((localBank) => localBank.value === account.bcuCode).label} - ${
                        account.accountNumber
                    }${
                        ID_FORM.TRANSFER_LOCAL === idForm && !showModal
                            ? EMPTY_STR
                            : ` - ${account.accountName} - ${UtilsI18n.get(`currency.label.${account.currency}`)}`
                    }`,
                    textMap: null,
                };
            });
        };

        render() {
            const {
                accountsByAlias,
                dependencies,
                field: { value },
                form,
                formMode,
                idForm,
                showModal,
            } = this.props;
            const { selectedOption, showNoContactsPermissionError } = this.state;

            const currency = dependencies && form.values[dependencies]?.currency;

            const accountsToWorkWith = filterAccountsByAliasList(accountsByAlias, idForm, currency);
            const accountsNotToWorkWith = filterAccountsByAliasList(
                accountsByAlias,
                ID_FORM.TRANSFER_THIRD_PARTIES,
                currency,
            );

            const optionList = this.makeOptionList(accountsToWorkWith);
            const modalOptionList = this.makeOptionList(accountsNotToWorkWith);

            return (
                <React.Fragment>
                    <AliasComponent {...this.props} />
                    {UtilsDevice.isMobileNative() && formMode === MODE.EDIT && (
                        <div className="form-group">
                            <a onClick={this.handleClickContacts}>{UtilsI18n.get("client.transfer.alias.contacts")}</a>
                        </div>
                    )}
                    {formMode === MODE.EDIT && accountsToWorkWith && accountsToWorkWith.length > 0 && (
                        <React.Fragment>
                            <div className="form-group form-group-text">
                                <label className="control-label">
                                    {UtilsI18n.get("client.transfer.alias.accountsByAlias.selectText")}
                                </label>
                            </div>
                            <div className="form-group form-group-text">
                                <label>{UtilsI18n.get("client.transfer.alias.accountsByAlias")}</label>
                            </div>
                            <div className="form-group form-group--select">
                                <RadioButtonGroup
                                    onChange={this.handleChangeAccount}
                                    options={optionList}
                                    value={selectedOption}
                                />
                            </div>
                        </React.Fragment>
                    )}
                    {showNoContactsPermissionError && UtilsDevice.isMobileNative() && formMode === MODE.EDIT && (
                        <div className="form-group has-error">
                            <FieldError
                                error={UtilsI18n.get(
                                    "client.transfer.alias.accountsByAlias.noContactsPermissionGranted",
                                )}
                            />
                        </div>
                    )}
                    {formMode === MODE.EDIT &&
                        ID_FORM.TRANSFER_LOCAL === idForm &&
                        accountsByAlias &&
                        accountsByAlias.length > 0 &&
                        accountsToWorkWith &&
                        accountsToWorkWith.length === 0 && (
                            <div className="form-group has-error">
                                <FieldError
                                    error={UtilsI18n.get("client.transfer.alias.accountsByAlias.noRecords.currency")}
                                />
                            </div>
                        )}
                    {formMode === MODE.EDIT && ID_FORM.TRANSFER_LOCAL === idForm && (
                        <ModalTransferRedirect
                            form={form}
                            hsbcAccounts={accountsNotToWorkWith}
                            optionList={modalOptionList}
                            showModal={showModal}
                            transferAlias={value}
                        />
                    )}
                </React.Fragment>
            );
        }
    };
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
