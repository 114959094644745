import React from "react";

import { push as Push } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { ADMINISTRATION_USER_ACTIONS, EMPTY_STR, LEVEL, SCOPE } from "~/constants";
import Container from "~/containers/Internal/Administration/Simple";
import {
    SelectorsStore as SelectorsStoreGroup,
    SelectorsAction as SelectorsActionGroup,
} from "~/store/administration/groups";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { generateId as GenerateId } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import GridLayout from "~/components/GridLayout";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Note from "~/components/Note";
import IndeterminateCheckbox from "~/pages/_components/IndeterminateCheckbox";

import ActionConfirmation from "./ActionConfirmation";
import Style from "./List.rules.scss";
import Operations from "./Operations";

export const { NAME } = Style;

export const PROP = {
    types: {
        activeEnvironment: PropTypes.shape({ administrationScheme: PropTypes.string }).isRequired,
        currentPage: PropTypes.number,
        fetching: PropTypes.bool,
        fetchingMoreGroups: PropTypes.bool,
        groups: PropTypes.arrayOf(
            PropTypes.shape({
                idGroup: PropTypes.string,
                status: PropTypes.string,
            }),
        ).isRequired,
        groupsExtendedInfo: PropTypes.objectOf(
            PropTypes.shape({
                signatureLevel: PropTypes.string,
                status: PropTypes.string.isRequired,
            }),
        ),
        hasMoreData: PropTypes.bool,
        i18n: PropTypes.shape({
            status: PropTypes.string,
            usersInGroup: PropTypes.string,
        }),
        isChangingEnvironment: PropTypes.bool,
        totalPages: PropTypes.number,
        userId: PropTypes.string,
        ...TypesRedux,
    },
    defaults: {
        currentPage: 0,
        fetching: false,
        fetchingMoreGroups: false,
        groupsExtendedInfo: null,
        hasMoreData: false,
        isChangingEnvironment: false,
        totalPages: 0,
        userId: EMPTY_STR,
        ...DefaultsRedux,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {
        selectedGroups: new Map(),
        blockedQuantity: 0,
        unblockedQuantity: 0,
        groupAction: EMPTY_STR,
    };

    componentDidMount() {
        const { dispatch, isChangingEnvironment } = this.props;

        if (!isChangingEnvironment) {
            dispatch(SelectorsActionGroup.loadListRequest());
        }
    }

    handleClickDownload = (format) => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionGroup.exportListRequest({ format }));
    };

    handleBack = () => {
        const { history } = this.props;

        history.goBack();
    };

    handleGroupClick = (idGroup) => {
        const { dispatch } = this.props;

        dispatch(Push(`/administration/advanced/modifyGroup/${idGroup}`));
    };

    handleSelectAll = () => {
        const { selectAllChecked } = this.state;
        const { groups } = this.props;
        const newSelectedGroups = new Map();
        let blockedQuantity = 0;
        let unblockedQuantity = 0;

        if (!selectAllChecked) {
            groups.forEach((group) => {
                newSelectedGroups.set(group.idGroup, group.name);

                if (group.blocked) {
                    blockedQuantity += 1;
                } else {
                    unblockedQuantity += 1;
                }
            });
        }

        this.setState((prevState) => ({
            blockedQuantity,
            selectAllChecked: !prevState.selectAllChecked,
            selectedGroups: newSelectedGroups,
            unblockedQuantity,
        }));
    };

    handleCheckClick = (idGroupChecked, nameGroupChecked) => {
        const { groups } = this.props;
        const { selectAllChecked, selectedGroups } = this.state;
        let { blockedQuantity, unblockedQuantity } = this.state;
        const groupChecked = groups.filter((group) => group.idGroup === idGroupChecked);

        if (!selectedGroups.has(idGroupChecked)) {
            selectedGroups.set(idGroupChecked, nameGroupChecked);

            if (groupChecked[0].blocked) {
                blockedQuantity += 1;
            } else {
                unblockedQuantity += 1;
            }

            if (selectedGroups.size === groups.length) {
                this.setState({
                    selectAllChecked: true,
                });
            }
        } else {
            selectedGroups.delete(idGroupChecked);

            if (groupChecked[0].blocked) {
                blockedQuantity -= 1;
            } else {
                unblockedQuantity -= 1;
            }

            if (selectAllChecked) {
                this.setState({ selectAllChecked: false });
            }
        }

        this.setState({
            blockedQuantity,
            selectedGroups,
            unblockedQuantity,
        });
    };

    getMessageKeyGroupActionConfirmation = () => {
        const { groupAction, selectedGroups } = this.state;
        let key = EMPTY_STR;

        if (groupAction === ADMINISTRATION_USER_ACTIONS.BLOCK || groupAction === ADMINISTRATION_USER_ACTIONS.UNBLOCK) {
            key = "administration.groups.blockunblock.confirmation";
        } else {
            key = "administration.groups.delete.confirmation";
        }

        if (selectedGroups.size > 1) {
            key = `${key}.plural`;
        }

        return key;
    };

    handleMoreDataClick = () => {
        const { currentPage, dispatch } = this.props;

        this.setState({ selectAllChecked: false });

        dispatch(SelectorsActionGroup.loadMoreRequest({ pageNumber: currentPage + 1 }));
    };

    renderActionButtons = () => {
        const { blockedQuantity, selectedGroups, unblockedQuantity } = this.state;

        return (
            <div className="sectionActions">
                <div className="section-actions-title">
                    {selectedGroups.size} <I18n id="administration.groups.list.selected.quantity" />
                </div>
                {unblockedQuantity > 0 && (
                    <Button
                        variant="link-underline"
                        onClick={() => this.handleActionClick(ADMINISTRATION_USER_ACTIONS.BLOCK)}>
                        {UtilsI18n.get("administration.block")}
                    </Button>
                )}
                {blockedQuantity > 0 && (
                    <Button
                        variant="link-underline"
                        onClick={() => this.handleActionClick(ADMINISTRATION_USER_ACTIONS.UNBLOCK)}>
                        {UtilsI18n.get("administration.unblock")}
                    </Button>
                )}
                {selectedGroups.size > 0 && (
                    <Button
                        variant="link-underline"
                        onClick={() => this.handleActionClick(ADMINISTRATION_USER_ACTIONS.DELETE)}>
                        {UtilsI18n.get("administration.delete")}
                    </Button>
                )}
            </div>
        );
    };

    handleCancelAction = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionGroup.hideModal());
    };

    handleActionClick = (groupAction) => {
        let performAction = true;

        if (groupAction === ADMINISTRATION_USER_ACTIONS.DELETE) {
            const { groups, userId } = this.props;
            const { selectedGroups: selectedGroupsState } = this.state;
            const groupIdList = [...selectedGroupsState.keys()];
            const filteredSelectedGroups = groups.filter((group) =>
                groupIdList.some((idGroup) => idGroup === group.idGroup),
            );

            // If any group contains current user
            performAction = !filteredSelectedGroups.some((group) =>
                group.userIdList.some((idUser) => idUser === userId),
            );
        }

        const { dispatch } = this.props;
        const { selectedGroups } = this.state;
        const groupNameList = [...selectedGroups.values()];
        const groupIdList = [...selectedGroups.keys()];

        this.setState({ groupAction });

        if (performAction) {
            dispatch(
                SelectorsActionGroup.changeGroupStatusPreview({
                    groupNameList,
                    groupIdList,
                    groupAction,
                }),
            );
        } else {
            dispatch(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("administration.groups.delete.error"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION],
                }),
            );
        }
    };

    handleConfirmAction = () => {
        const { credentials = {}, dispatch, formikBag, idActivity, idTransaction } = this.props;
        const { groupAction, selectedGroups } = this.state;

        const groupNameList = [...selectedGroups.values()];
        const groupIdList = [...selectedGroups.keys()];

        if (!idTransaction) {
            dispatch(
                SelectorsActionGroup.changeGroupsStatusConfirmation({
                    groupIdList,
                    groupNameList,
                    groupAction,
                    credentials,
                    formikBag,
                }),
            );
        } else {
            const paramsSign = { idActivity, idForm: null, idTransaction };

            dispatch(SelectorsActionForm.signTransaction({ ...paramsSign, credentials, formikBag }));
        }

        this.setState(() => ({
            blockedQuantity: 0,
            groupAction: EMPTY_STR,
            selectedGroups: new Map(),
            unblockedQuantity: 0,
        }));
    };

    render() {
        const { fetching, groups, groupsExtendedInfo, hasMoreData, i18n, showModal, totalPages } = this.props;
        const { selectAllChecked, selectedGroups } = this.state;

        const COLUMN_HEADINGS = [i18n.group, i18n.usersInGroup, i18n.status];

        return (
            <Container
                name={NAME}
                wait={fetching}
                head-title="administration.users.list.title"
                head-onBack={this.handleBack}
                image="administration-title-icon.svg"
                scopeToShowNotification={SCOPE.ADMINISTRATION}>
                <ActionConfirmation
                    show={showModal}
                    handleCancelAction={this.handleCancelAction}
                    handleConfirmAction={this.handleConfirmAction}
                    messageKey={this.getMessageKeyGroupActionConfirmation()}
                />

                <div id={Style.ID}>
                    <div className="mb-4">
                        <I18n tag="h3" id="administration.groups.list.title" />
                        <I18n tag="p" id="administration.advanced.group.list.header" />
                        <I18n tag="p" id="administration.advanced.group.list.subheader" URL="signaturesSchemes" />
                        <Box flex className="ic-prefix">
                            <Image src="dinero.svg" />
                            <I18n id="administration.advanced.group.list.footer" />
                        </Box>
                    </div>
                    <Operations className="box-button-content" />
                    <Box className="my-2">{this.renderActionButtons()}</Box>
                    <Box className="select-all-no-headers">
                        <Box className="select-all-checkbox">
                            <IndeterminateCheckbox
                                id="selectAllGroups"
                                onCheckClick={this.handleSelectAll}
                                selectedOptionsAmount={
                                    selectAllChecked ||
                                    (groups.length === selectedGroups.size && selectedGroups.size !== 0)
                                }
                                value={1}
                            />
                        </Box>
                    </Box>
                    <GridLayout className="header">
                        <div className="listHeaders">
                            <IndeterminateCheckbox
                                id="selectAllGroups"
                                onCheckClick={this.handleSelectAll}
                                selectedOptionsAmount={
                                    selectAllChecked ||
                                    (groups.length === selectedGroups.size && selectedGroups.size !== 0)
                                }
                                value={1}
                            />
                        </div>
                        {COLUMN_HEADINGS.map((heading, index) => (
                            <div key={`header-${GenerateId()}`} id={`header-${index}`} className="listHeaders">
                                {heading}
                            </div>
                        ))}
                    </GridLayout>
                    {groups.map(({ idGroup, name, usersInGroup, blocked, status }) => (
                        <GridLayout
                            key={`group-${GenerateId()}`}
                            className="item"
                            onClick={() => this.handleGroupClick(idGroup)}>
                            <div onClick={(event) => event.stopPropagation()}>
                                <IndeterminateCheckbox
                                    id={idGroup}
                                    onCheckClick={() => this.handleCheckClick(idGroup, name)}
                                    selectedOptionsAmount={selectAllChecked || selectedGroups.has(idGroup)}
                                />
                            </div>
                            <div>
                                <div className="userName">
                                    <p>{name}</p>
                                </div>
                                {groupsExtendedInfo[idGroup].canMakeTransfer && (
                                    <Image src="dinero.svg" className="ic-money" />
                                )}
                            </div>
                            <div>
                                <p>{usersInGroup}</p>
                            </div>
                            <div>
                                <span className={`status ${blocked ? "status-blocked" : EMPTY_STR}`}>{status}</span>
                            </div>
                        </GridLayout>
                    ))}

                    {totalPages && (
                        <Box flex justify="center">
                            <Note onClick={this.handleMoreDataClick} noMoreData={!hasMoreData}>
                                <I18n id={`administration.groups.list.more${hasMoreData ? "" : ".noMoreData"}`} />
                            </Note>
                        </Box>
                    )}
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
    credentialGroups: SelectorsStoreGroup.getCredentialGroups(store),
    currentPage: SelectorsStoreGroup.getCurrentPage(store),
    fetchingExport: SelectorsStoreGroup.isFetchingExport(store),
    fetchingMoreGroups: SelectorsStoreGroup.isFetchingMoreGroups(store),
    fetching: SelectorsStoreGroup.isFetching(store),
    groupsExtendedInfo: SelectorsStoreGroup.getGroupsExtendedInfo(store),
    groups: SelectorsStoreGroup.getGroups(store),
    hasMoreData: SelectorsStoreGroup.getHasMoreData(store),
    i18n: {
        group: SelectorsStoreI18n.getMessage(store, "administration.groups.list.name"),
        status: SelectorsStoreI18n.getMessage(store, "administration.groups.list.status"),
        usersInGroup: SelectorsStoreI18n.getMessage(store, "administration.groups.list.usersInGroup"),
    },
    idActivity: SelectorsStoreGroup.getIdActivity(store),
    idTransaction: SelectorsStoreGroup.getIdTransaction(store),
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    showModal: SelectorsStoreGroup.showModal(store),
    totalPages: SelectorsStoreGroup.getTotalPages(store),
    userId: SelectorsStoreSession.getUserId(store),
});

export default Connect(mapStateToProps)(Component);
