import { SelectorsStore as SelectorsStoreBackoffice } from "~/store/backoffice";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";

export const getScheme = (store) =>
    store.administration[
        SelectorsStoreBackoffice.getEnvironmentData(store)?.administrationScheme ||
            SelectorsStoreSession.getAdministrationScheme(store)
    ];

export const getUser = (store) => store.administration.user;

export const signatuleLevelsCountToInt = (object) =>
    Object.entries(object).reduce((values, [key, value]) => ({ ...values, [key]: parseInt(value, 10) }), {});
