import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { get as Get } from "~/util/config";
import * as I18nUtil from "~/util/i18n";

import GenericDate from "~/components/GenericDate";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";

import Style from "./DateField.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        field: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired,
        form: PropTypes.shape({
            setFieldValue: PropTypes.func.isRequired,
        }).isRequired,
        handleOnChange: PropTypes.func,
        hideLabel: PropTypes.bool,
        hidePlaceholder: PropTypes.bool,
        maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
        minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
        selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
        optional: PropTypes.string,
    },
    defaults: {
        hideLabel: false,
        hidePlaceholder: false,
        maxDate: new Date(),
        minDate: null,
        handleOnChange: null,
        selectedDate: null,
        optional: null,
    },
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        isFocused: false,
    };

    handleChange = (selectedDate) => {
        const { field, form, handleChange, timezoneOffset } = this.props;

        if (handleChange) {
            handleChange(selectedDate);
        }

        // Hack for allowing to select the same date as today and avoid the unwanted behaivour
        // produced by Axios' conversion to UTC
        if (timezoneOffset) {
            selectedDate.setMinutes(timezoneOffset - selectedDate.getTimezoneOffset());
        }

        form.setFieldValue(field.name, selectedDate);
    };

    handleChangeRaw = (event) => {
        event.preventDefault();
    };

    render() {
        const { isFocused } = this.state;
        const {
            id,
            endDate,
            field,
            form: { touched, errors },
            hasPlaceholder,
            hideLabel,
            hidePlaceholder,
            idForm,
            maxDate,
            minDate,
            showDisabledMonthNavigation,
            startDate,
            className,
            tooltip,
            optional,
            ...rest
        } = this.props;

        const CustomInput = React.forwardRef((props, ref) => (
            <input
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly
                placeholder={!hidePlaceholder && hasPlaceholder && I18nUtil.get(`${idForm}.${field.name}.placeholder`)}
                ref={ref}
            />
        ));
        const hasError = touched && touched[field.name] && errors && errors[field.name];
        return (
            <div
                className={classnames(
                    "form-group",
                    "form-group--datepicker",
                    { "has-error": hasError },
                    { "has-focus": isFocused },
                    `${Style.CLASS}`,
                    className,
                )}>
                {!hideLabel && <FieldLabel optional={optional} labelKey={`${idForm}.${field.name}.label`} />}

                <GenericDate
                    customInput={<CustomInput />}
                    dateFormat={Get("frontend.shortDateFormat")}
                    id={id}
                    endDate={endDate}
                    maxDate={maxDate}
                    minDate={minDate}
                    placeholderText={
                        hidePlaceholder || !hasPlaceholder
                            ? EMPTY_STR
                            : I18nUtil.get(`${idForm}.${field.name}.placeholder`)
                    }
                    selected={field.value}
                    showDisabledMonthNavigation={showDisabledMonthNavigation}
                    startDate={startDate}
                    onChange={this.handleChange}
                    onChangeRaw={this.handleChangeRaw}
                    {...rest}
                />
                {hasError && <FieldError error={errors[field.name]} />}
                {tooltip && (
                    <div className="tooltip">
                        <span className="tooltip-text"> {tooltip}</span>
                    </div>
                )}
            </div>
        );
    }
}

export default Component;
