import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { DATA_NOT_AVAILABLE, EMPTY_STR, LANGUAGE, FORMAT_SHORT } from "~/constants";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { i18nDate as I18nDate } from "~/util/date";
import * as I18nUtil from "~/util/i18n";

export const NAME = "FormattedDate";

export const PROP = {
    types: {
        date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.number]),
        dateFormat: PropTypes.string,
        customFormat: PropTypes.string,
        language: PropTypes.string,
    },
    defaults: {
        dateFormat: EMPTY_STR,
        customFormat: null,
        date: new Date(),
        language: LANGUAGE.ES,
    },
};

export function Component(props) {
    const { date, dateFormat, customFormat } = props;

    const dateFormatStr = customFormat !== null ? customFormat : dateFormat;
    const className = `component-${NAME.toLowerCase()}`;
    const value = date === DATA_NOT_AVAILABLE ? I18nUtil.get("data.notAvailable.short") : I18nDate(date, dateFormatStr);
    return <span className={className}>{value.charAt(0).toUpperCase() + value.substr(1)}</span>;
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    dateFormat: SelectorsStoreConfig.getConfig(store)["frontend.shortDateFormat"]
        ? SelectorsStoreConfig.getConfig(store)["frontend.shortDateFormat"].toUpperCase()
        : FORMAT_SHORT,
});

export default Connect(mapStateToProps)(Component);
