import React from "react";

import PropTypes from "prop-types";

import { Section } from "~/components/Component";

import Button from "./_Button";
import Logo from "./_Logo";
import Title from "./_Title";
import Namespace from "./_index.scss";

export const { NAME, TYPE } = Namespace;
export const PROP = {
    types: {
        children: PropTypes.node.isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const { children, ...rest } = props;
    return (
        <Section name={NAME} {...rest} tag="header">
            {children}
        </Section>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

Component.Logo = Logo;
Component.Button = Button;
Component.Title = Title;

export default Component;
