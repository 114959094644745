import React from "react";

import { PROP as STORE_PROP } from "~/store/preferentialTradingPrice";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";

import AccordionDetail from "./AccordionDetail";
import TransactionItem from "./TransactionItem";
import Style from "./TransactionsList.rules.scss";

export const { NAME_MESSAGES } = Style;
export const NAME = "Transactions";

export const PROP = {
    types: {
        ...STORE_PROP.types,
    },
    defaults: {
        ...STORE_PROP.defaults,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {};

    render() {
        const {
            processingTransactionsList: { elementList: processingTransactionsListSorted },
        } = this.props;

        return (
            <form id={Style.ID}>
                <div className="col col-12 col-lg-6 col-md-9 col-sm-12">
                    <AccordionDetail
                        key="transactions"
                        title={
                            processingTransactionsListSorted && processingTransactionsListSorted.length > 0
                                ? UtilsI18n.replaceParams(
                                      UtilsI18n.get(`preferentialTradingPrice.transactions.headerInfo`),
                                      {
                                          quantity: processingTransactionsListSorted.length,
                                      },
                                  )
                                : UtilsI18n.get("preferentialTradingPrice.transactions.headerInfo.empty")
                        }
                        forbidOpening={
                            !processingTransactionsListSorted || processingTransactionsListSorted.length === 0
                        }>
                        {processingTransactionsListSorted && processingTransactionsListSorted.length > 0 && (
                            <TransactionItem transactions={processingTransactionsListSorted} />
                        )}
                    </AccordionDetail>
                    <Box className="transaction-disclaimer">
                        {UtilsI18n.get("preferentialTradingPrice.transactions.headerInfo.disclaimer")}
                    </Box>
                    <div />
                </div>
            </form>
        );
    }
}
export default Component;
