import React from "react";

import { Field } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SelectorsStore as SelectorsStorePermissions } from "~/store/administration/common/permissions";
import * as AdministrationUtils from "~/util/administration";
import { get as Get } from "~/util/i18n";

import Image from "~/components/Image";
import Link from "~/components/Link";
import Accordion from "~/pages/_components/Accordion";
import AccordionItem from "~/pages/_components/AccordionItem";
import IndeterminateCheckbox from "~/pages/_components/IndeterminateCheckbox";
import StyleCheckbox from "~/pages/_components/IndeterminateCheckbox.rules.scss";
import ProductLabelIcon from "~/pages/_components/ProductLabelIcon";

import PermissionsAmount from "~/pages/administration/_components/PermissionsAmount";

export const NAME = "PermissionsForm";

const permissionsWithDescription = ["payments", "position", "client.swift.read.all", "checkbooks", "client.signature"];
const replaceLabelSinglePermission = ["client.swift.read.all", "position", "client.signature"];
const ID_FORM = "administration.permissions";

export const PROP = {
    types: {
        setValues: PropTypes.func.isRequired,
        groups: AdministrationUtils.groupsPropType.isRequired,
        values: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
        products: PropTypes.arrayOf(
            PropTypes.shape({
                productType: PropTypes.string,
            }),
        ).isRequired,
    },
    defaults: { values: {} },
};
export function Component(props) {
    const dependsFromFields = ({ childrenList }, idItemToFind) =>
        childrenList.reduce((flattenedOptions, option) => {
            if (!option.childrenList.length) {
                if (option.dependsOn === idItemToFind) {
                    return [...flattenedOptions, option.idItem];
                }

                return flattenedOptions;
            }

            return [...flattenedOptions, ...dependsFromFields(option, idItemToFind)];
        }, []);

    const setValue = ({ nextValue, setValues, dependsOn, values, idItem, parentOption }) => {
        let value = { [idItem]: nextValue };

        if (!nextValue.length && dependsOn) {
            value = { [idItem]: nextValue, [dependsOn]: nextValue };
        } else if (nextValue.length && !dependsOn) {
            value = [idItem, ...dependsFromFields(parentOption, idItem)].reduce(
                (acc, id) => ({ ...acc, [id]: nextValue }),
                {},
            );
        }

        setValues({ ...values, ...value });
    };

    const permissionValue = (permissionList) => {
        if (permissionList && permissionList[0].productTypes) {
            return permissionList[0].productTypes.split(",").map((type) => `ALL_${type}`);
        }

        return ["NONE"];
    };

    const fillValues = ({ permissionList, childrenList, idItem }) => {
        if (permissionList.length && permissionList[0].simpleAllowProductSelection) {
            const { products } = props;

            return { [idItem]: products.map(({ idProduct }) => idProduct) };
        }

        if (childrenList.length) {
            return childrenList.reduce(
                (acc, option) => ({
                    ...acc,
                    ...fillValues(option),
                }),
                {},
            );
        }

        return { [idItem]: permissionValue(permissionList) };
    };

    const handleCheckClick = (values, option, setValues, selectedOptionsAmount) => {
        const filled = fillValues(option);

        if (selectedOptionsAmount === 0) {
            setValues({
                ...values,
                ...filled,
            });
        } else {
            const keysToRemove = Object.keys(filled);

            setValues(
                Object.entries(values).reduce(
                    (filteredValues, [key, value]) => ({
                        ...filteredValues,
                        [key]: keysToRemove.includes(key) ? [] : value,
                    }),
                    {},
                ),
            );
        }
    };

    const renderProductInput = ({ idProduct, selectedOptions = [], setValues, values, idItem }) => (
        <input
            name={idProduct}
            value={idProduct}
            checked={selectedOptions?.includes(idProduct)}
            onChange={() =>
                setValues({
                    ...values,
                    [idItem]: selectedOptions?.includes(idProduct)
                        ? selectedOptions.filter((value) => value !== idProduct)
                        : selectedOptions.concat(idProduct),
                })
            }
            type="checkbox"
            id={idProduct}
            className="c-control-input"
        />
    );

    const renderInput = ({ permissionList, selectedOptions = [], ...rest }) => (
        <input
            type="checkbox"
            id={rest.idItem}
            onChange={() =>
                setValue({
                    ...rest,
                    nextValue: selectedOptions.length ? [] : permissionValue(permissionList),
                })
            }
            className="c-control-input"
            checked={selectedOptions?.length}
        />
    );
    const renderField = ({ idItem, label, idProduct, number, productAlias, extraInfo, ...option }) => (
        <Field name={idItem} key={idProduct || idItem}>
            {({ form }) => {
                const selectedOptions = form.values && form.values[idItem];

                return (
                    <div className="permission-item" id={StyleCheckbox.ID}>
                        {!idProduct
                            ? renderInput({ ...option, ...form, idItem, selectedOptions })
                            : renderProductInput({ ...option, ...form, idItem, idProduct, selectedOptions })}
                        <label className="c-control-label" htmlFor={idProduct || idItem}>
                            <div className="c-control-icons">
                                <div className="c-control-mark">
                                    <Image src="check.svg" className="svg-wrapper" />
                                </div>
                            </div>
                            {!idProduct ? (
                                <div>
                                    {replaceLabelSinglePermission.includes(idItem)
                                        ? Get(`${ID_FORM}.${idItem}.label`)
                                        : label}
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: "inline-flex",
                                    }}
                                    className="product-label">
                                    <ProductLabelIcon number={number} />
                                    <div className="product-label-text">
                                        <div className="product-label-name">{productAlias || label}</div>
                                    </div>
                                </div>
                            )}
                        </label>
                    </div>
                );
            }}
        </Field>
    );

    const renderOptions = (option, isFirstLevel) => {
        if (option.childrenList && option.childrenList.length) {
            return (
                <React.Fragment key={option.idItem}>
                    {!isFirstLevel && <div className="navigational-list-subtitle">{option.label}</div>}

                    {option.childrenList.map((subOption) => renderOptions({ ...subOption, parentOption: option }))}
                </React.Fragment>
            );
        }
        if (option.permissionList && option.permissionList[0]?.simpleAllowProductSelection) {
            const { products } = props;

            return products.reduce((availableProducts, product) => {
                if (option.permissionList[0].productTypes.indexOf(product.productType) === -1) {
                    return availableProducts;
                }

                return [...availableProducts, renderField({ ...product, idItem: option.idItem })];
            }, []);
        }

        return renderField(option);
    };

    const { values, setValues, groups } = props;

    return (
        <PermissionsAmount permissions={values}>
            {(amountsById, totalAmountsById) => {
                return (
                    <Accordion className="list--permissions">
                        {groups.map((group, index) => {
                            const { idItem, ordinal } = group;
                            const selectedOptionsAmount = amountsById[idItem] || 0;
                            const optionsAmount = totalAmountsById[idItem];
                            const options = renderOptions({ ...group, parentOption: group }, true);
                            const quantityText = `${selectedOptionsAmount} / ${optionsAmount}`;

                            if (
                                options.props &&
                                typeof options.props.children === "function" &&
                                !permissionsWithDescription.includes(idItem)
                            ) {
                                return (
                                    <li key={idItem} className="list--permissions-item-one-option">
                                        {options}
                                    </li>
                                );
                            }

                            return (
                                <AccordionItem
                                    key={idItem}
                                    id={idItem}
                                    number={index}
                                    item={
                                        <React.Fragment>
                                            <IndeterminateCheckbox
                                                id={`${idItem}${ordinal}`}
                                                selectedOptionsAmount={selectedOptionsAmount}
                                                optionsAmount={optionsAmount}
                                                onCheckClick={() =>
                                                    handleCheckClick(values, group, setValues, selectedOptionsAmount)
                                                }
                                            />
                                            <span>
                                                <span>{Get(`${ID_FORM}.${idItem}`)}</span>
                                                <span className="list-item-hint">{quantityText}</span>
                                            </span>
                                            <div className="arrow-class" />
                                        </React.Fragment>
                                    }>
                                    {permissionsWithDescription.includes(idItem) && (
                                        <div className="permission-description">
                                            {Get(`${ID_FORM}.${idItem}.description`)}{" "}
                                            {idItem === "client.signature" && (
                                                <Link to="/administration/medium/signaturesSchemes/modify">
                                                    {Get(`${ID_FORM}.${idItem}.description.link`)}
                                                </Link>
                                            )}
                                        </div>
                                    )}
                                    {options}
                                </AccordionItem>
                            );
                        })}
                    </Accordion>
                );
            }}
        </PermissionsAmount>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    groups: SelectorsStorePermissions.getGroups(store),
    products: SelectorsStorePermissions.getProducts(store),
});

export default Connect(mapStateToProps)(Component);
