import { EMPTY_STR, PIPE_SEPARATOR } from "~/constants";

const getAlias = (aliasData) => {
    const phoneNumberSplit = aliasData.split(PIPE_SEPARATOR);

    const phoneCode = phoneNumberSplit[0] ? `+${phoneNumberSplit[0]}` : EMPTY_STR;
    const phoneNumber = phoneNumberSplit[1] ? phoneNumberSplit[1] : EMPTY_STR;
    return `${phoneCode} ${phoneNumber}`;
};

export default getAlias;
