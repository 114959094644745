import { LOCATION_CHANGE } from "connected-react-router";

import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { TYPE as TypesBackoffice } from "~/store/backoffice";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            return {
                ...PROP.defaults,
            };
        }
        case TYPE.RESTART_FLOW:
            return PROP.defaults;
        case TYPE.CHANGE_ALL_COLLAPSED_ELEMENTS_COUNT: {
            const { positionExport, positionGuarantee, positionImport } = storeProp.positions || {};
            const multiplier = action.collapseAll ? 1 : 0;

            let positionLength = 0;

            positionLength += positionExport ? positionExport.length : 0;
            positionLength += positionGuarantee ? positionGuarantee.length : 0;
            positionLength += positionImport ? positionImport.length : 0;

            return {
                ...storeProp,
                collapsedElementsCount: positionLength * multiplier,
            };
        }
        case TYPE.CHANGE_COLLAPSED_ELEMENTS_COUNT: {
            const { positionExport, positionGuarantee, positionImport } = storeProp.positions || {};
            const newCount = storeProp.collapsedElementsCount + action.increment;

            let positionLength = 0;

            positionLength += positionExport ? positionExport.length : 0;
            positionLength += positionGuarantee ? positionGuarantee.length : 0;
            positionLength += positionImport ? positionImport.length : 0;

            return {
                ...storeProp,
                collapsedElementsCount:
                    newCount >= 0 || newCount < positionLength ? newCount : storeProp.collapsedElementsCount,
            };
        }
        case TYPE.CLOSE_TRANSACTION_PREVIEW:
            return {
                ...storeProp,
                fetching: false,
                mode: storeProp.prevMode,
            };
        case TYPE.COMEX_DESKTOP_PRE_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.COMEX_DESKTOP_PRE_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.COMEX_DESKTOP_PRE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                transactionList: action.transactionList,
                positions: action.position,
                collapsedElementsCount: 0,
            };
        case TYPE.COMEX_FORM_TO_STEP_1:
            return {
                ...storeProp,
                selectedBank: {},
                mode: MODE.EDIT,
            };
        case TYPE.GET_AMOUNT_TEXT_SUCCESS:
            return {
                ...storeProp,
                amountString: action.amountString,
            };
        case TYPE.LOAD_BANK_LIST_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.LOAD_BANK_LIST_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                banks: action.banks,
            };
        case TYPE.READ_TRANSACTION_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
                fetching: true,
            };
        case TYPE.READ_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                fetching: false,
                transaction: action.transaction,
                mode: action.transaction.idTransactionStatus === STATUS.DRAFT ? MODE.EDIT : MODE.VIEW,
            };
        case TYPE.SEARCH_BANK_FAILURE:
            return {
                ...storeProp,
                errorSelectingBank: {
                    ...storeProp.errorSelectingBank,
                    [action.idField]: false,
                },
                fetching: false,
                searchBankForm: false,
                selectedBank: {},
            };
        case TYPE.SEARCH_BANK_REQUEST:
            return {
                ...storeProp,
                searchBankForm: true,
                fetching: true,
            };
        case TYPE.SEARCH_BANK_SUCCESS:
            return {
                ...storeProp,
                errorSelectingBank: {
                    ...storeProp.errorSelectingBank,
                    [action.idField]: true,
                },
                fetching: false,
                searchBankForm: false,
                selectedBank: action.bank,
            };
        case TYPE.SET_BANK_SEARCH_INFO:
            return {
                ...storeProp,
                showBankSearch: Object.prototype.hasOwnProperty.call(action, "showBankSearch")
                    ? action.showBankSearch
                    : storeProp.showBankSearch,
                banks: Object.prototype.hasOwnProperty.call(action, "banks") ? action.banks : storeProp.banks,
            };
        case TYPE.SET_SELECTED_BANK:
            return {
                ...storeProp,
                selectedBank: action.bank,
                banks: [],
                showBankSearch: false,
            };
        case TYPE.SIGN_TRANSACTION_PREVIEW:
            return {
                ...storeProp,
                prevMode: storeProp.mode,
                mode: MODE.PREVIEW,
            };
        case TYPE.SIGN_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                prevMode: storeProp.mode,
                transaction: action.transaction,
            };
        case TYPE.TOGGLE_CREDIT_LETTER:
            return {
                ...storeProp,
                operationNumberModalVisible: !storeProp.operationNumberModalVisible,
            };
        case TYPE.OTHER_PROCEDURES_PRE_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.OTHER_PROCEDURES_PRE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                otherProcedure: {
                    ...storeProp.otherProcedure,
                    preData: action,
                },
            };
        case TYPE.OTHER_PROCEDURES_PREVIEW_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                otherProcedure: {
                    ...storeProp.otherProcedure,
                    prevData: action.formData,
                },
            };
        case TYPE.COMEX_FORM_SEND_DATA_FAILURE:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.EDIT,
            };
        case TYPE.OTHER_PROCEDURES_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                mode: MODE.PREVIEW,
                prevMode: storeProp.mode,
                fetching: false,
                otherProcedure: {
                    ...storeProp.otherProcedure,
                    formData: storeProp.otherProcedure.prevData,
                },
            };
        case TYPE.OTHER_PROCEDURES_SEND_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.READ_TRANSACTION_FAILURE:
        case TYPE.SIGN_TRANSACTION_FAILURE:
            return {
                ...storeProp,
                fetching: false,
                isFetching: false,
            };
        case TYPE.LOAD_BANK_LIST_FAILURE:
        case TYPE.OTHER_PROCEDURES_PRE_FAILURE:
        case TYPE.OTHER_PROCEDURES_PREVIEW_FAILURE:
        case TYPE.OTHER_PROCEDURES_SEND_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.OTHER_PROCEDURES_SEND_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                prevMode: storeProp.mode,
                transaction: action.transaction,
            };
        case TypesBackoffice.BACKOFFICE_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                prevMode: storeProp.mode,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };
        default:
            return storeProp;
    }
};
