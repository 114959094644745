import React from "react";

import { goBack as GoBack } from "connected-react-router";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { connect as Connect } from "react-redux";

import { SPACE_STR } from "~/constants";
import Container from "~/containers/Internal/Form/Simple";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsStore as SelectorsStoreSettings } from "~/store/settings";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { getLastLoginDate as GetLastLoginDate, getLastLoginPlace as GetLastLoginPlace } from "~/util/settings";

import I18n from "~/components/I18n";
import Wrap from "~/pages/_components/Container";

export const NAME = "LastLogin";

export const PROP = {
    types: {
        ...TypesRedux,
        previousLoginInfo: PropTypes.arrayOf(
            PropTypes.shape({
                city: PropTypes.string,
                country: PropTypes.string,
                idUser: PropTypes.string,
                ip: PropTypes.string,
                latitude: PropTypes.number,
                longitude: PropTypes.number,
            }),
        ),
    },
    defaults: {
        ...DefaultsRedux,
        previousLoginInfo: [],
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    /*     handleReportClick = (event) => {
        const { dispatch } = this.props;

        if (Device.isMobileNative()) {
            event.preventDefault();
            window.open("tel:+9999999999", "_system", "location=yes");
        } else {
            // TODO: hay que definir el comportamiento para web mobile y revisar xq no funciona el communications
            event.preventDefault();
            dispatch(
                CommunicationActions.preloadedMessage({
                    subject: i18n.get("communications.suspectActivity.defaultSubject"),
                }),
            );
            dispatch(CommunicationsActions.showComposePanel());
            dispatch(Push("/communications"));
        }
    }; */

    handleHeaderBack = () => {
        const { dispatch } = this.props;
        dispatch(GoBack());
    };

    render() {
        const { previousLoginInfo } = this.props;

        return (
            <Container
                name={NAME}
                head-title="settings.lastLogin.head"
                head-onBackWeb={this.handleHeaderBack}
                head-onClose={this.handleHeaderBack}>
                <Wrap className="align-items-center container--layout">
                    <Col sm={12} md={9} lg={6} xl={6} className="col">
                        <ul className="data-list">
                            <li className="data-wrapper data-wrapper-inline">
                                <span className="data-label">
                                    <I18n id="settings.lastLogin.date" />
                                </span>
                                <span className="data-date">{GetLastLoginDate(previousLoginInfo[0])}</span>
                            </li>
                            <li className="data-wrapper data-wrapper-inline">
                                <span className="data-label">
                                    <I18n id="settings.lastLogin.from" />
                                    {SPACE_STR}
                                </span>
                                <span className="data-">{GetLastLoginPlace(previousLoginInfo[0])}</span>
                            </li>
                        </ul>
                    </Col>
                </Wrap>
                {/* TODO: queda comentado hasta revisar si va o no */}
                {/* <Container className="align-items-center container--layout">
                <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                    <a
                        href="tel:+9999999999"
                        onClick={this.handleReportClick}
                        className="btn btn-danger btn-block"
                        style={{ color: "white" }}>
                        {" "}
                        <I18n id="settings.lastLogin.report" />
                    </a>
                </Col>
            </Container> */}
            </Container>
        );
    }
}

export default Connect((state) => ({
    ...SelectorsStoreSession.getUser(state),
    errors: SelectorsStoreSettings.getErrors(state),
}))(Component);
