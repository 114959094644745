import React from "react";

import { push as Push } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { LEVEL, SCOPE, EMPTY_STR, PAYMENT_TYPE } from "~/constants";
import { SelectorsStore as SelectorsStoreAutomaticDebits } from "~/store/automaticDebits";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { generateId as GenID } from "~/util/general";
import * as I18nUtils from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Box from "~/components/Box";
import DataDate from "~/components/DataDate";
import GridLayout from "~/components/GridLayout";
import Image from "~/components/Image";

import Style from "./_AutomaticDebits.rules.scss";

export const { NAME, TYPE } = Style;

export const PROP = {
    types: {
        automaticDebits: PropTypes.array,
        banredAutomaticDebits: PropTypes.array,
        i18n: PropTypes.object,
        ...TypesRedux,
    },
    defaults: {
        automaticDebits: [],
        banredAutomaticDebits: [],
        i18n: {
            agreementDescriptionLabel: "Empresa/Servicio",
            description: "Descripción",
            referenceAndAccount: "Referencia/Cuenta",
            starteDateLabel: "Fecha Inicio",
        },
        ...DefaultsRedux,
    },
};

export function Component(props) {
    const { dispatch, automaticDebits, banredAutomaticDebits, i18n } = props;
    const { agreementDescriptionLabel, description, referenceAndAccount, starteDateLabel } = i18n;
    const COLUMN_HEADINGS = [agreementDescriptionLabel, description, referenceAndAccount, starteDateLabel, EMPTY_STR];

    return (
        <React.Fragment>
            <GridLayout className="header">
                {COLUMN_HEADINGS.map((heading) => (
                    <div key={GenID()}>{heading}</div>
                ))}
            </GridLayout>
            {automaticDebits &&
                automaticDebits.map((elem) => {
                    const { agreementDescription, affiliationDescription, reference, account, date } = elem;
                    return (
                        <GridLayout key={`automaticDebits-${GenID()}`} onClick={() => handleAgreementClick(elem)}>
                            <div>{agreementDescription}</div>
                            <div>{affiliationDescription}</div>
                            <div>{`${reference}/${account}`}</div>
                            <div>
                                <DataDate value={date} />
                            </div>
                            <Box justify="end" onClick={handleDeleteClick}>
                                <Image src="trash.svg" />
                            </Box>
                        </GridLayout>
                    );
                })}
            {banredAutomaticDebits &&
                banredAutomaticDebits.map(({ companyName, affiliationDescription, account, date }) => (
                    <GridLayout key={`banredAutomaticDebits-${GenID()}`} onClick={handleBanredAgreementClick}>
                        <div>{companyName}</div>
                        <div>{affiliationDescription}</div>
                        <div>{account}</div>
                        <div>
                            <DataDate value={date} />
                        </div>
                        <Box justify="end" onClick={handleDeleteClick}>
                            <Image src="trash.svg" />
                        </Box>
                    </GridLayout>
                ))}
        </React.Fragment>
    );

    function handleDeleteClick(event) {
        event.stopPropagation();
        dispatch(
            SelectorsActionNotification.showNotification({
                message: I18nUtils.get("client.payments.automaticDebits.list.deleteAutomaticDebit"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.AUTOMATIC_DEBITS],
            }),
        );
    }

    function handleBanredAgreementClick(event) {
        event.stopPropagation();
        dispatch(
            SelectorsActionNotification.showNotification({
                message: I18nUtils.get("client.payments.automaticDebits.list.banredAgreement"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.AUTOMATIC_DEBITS],
            }),
        );
    }

    function handleAgreementClick(item) {
        const {
            agreementDescription,
            agreementCurrency,
            reference,
            debitAcount,
            companyName,
            fees,
            affiliationId,
            affiliationDescription,
            notification,
        } = item;
        const paymentType = fees === 0 ? PAYMENT_TYPE.CASH : PAYMENT_TYPE.FEES;
        const notificationOption = notification === EMPTY_STR ? "N" : notification.toUpperCase();
        dispatch(
            Push(
                `/form/modifyAutomaticDebit?companyName=${companyName}&agreement=${agreementDescription}&reference=${reference}&account=${debitAcount}&paymentType=${paymentType}&idAffiliation=${affiliationId}&description=${affiliationDescription}&notification=${notificationOption}`,
                { agreementCurrency },
            ),
        );
    }
}
const mapStateToProps = (store) => ({
    automaticDebits: SelectorsStoreAutomaticDebits.getListAutomaticDebits(store),
    banredAutomaticDebits: SelectorsStoreAutomaticDebits.getListBandredAutomaticDebits(store),
    i18n: {
        agreementDescriptionLabel: StoreI18n.getMessage(
            store,
            "client.payments.automaticDebits.list.agreementDescription",
        ),
        description: StoreI18n.getMessage(store, "client.payments.automaticDebits.list.description"),
        referenceAndAccount: StoreI18n.getMessage(store, "client.payments.automaticDebits.list.referenceAndAccount"),
        starteDateLabel: StoreI18n.getMessage(store, "client.payments.automaticDebits.list.starteDateLabel"),
    },
});

export default Connect(mapStateToProps)(Component);
