import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "files";

export const PATH = {
    DELETE_FILE: "/files.delete",
    DOWNLOAD_FILE_LINK: "/core.forms.filelink.download",
    DOWNLOAD_FILE_STREAM: "/files.downloadStream",
    DOWNLOAD_FILE: "/files.download.execute",
    GET_FILE_TRANSACTION_LINES: "/file.transactionLines",
};

export const TYPE = FactoryType(NAME, [
    "DOWNLOAD_FILE_REQUEST",
    "DOWNLOAD_FILE_FAILURE",
    "DOWNLOAD_FILE_SUCCESS",
    "DOWNLOAD_FILE_LINK_REQUEST",
    "DOWNLOAD_FILE_LINK_FAILURE",
    "DOWNLOAD_FILE_LINK_SUCCESS",
    "GET_FILE_CONTENTS_REQUEST",
    "GET_FILE_CONTENTS_FAILURE",
    "GET_FILE_CONTENTS_SUCCESS",
    "GET_FILE_TRANSACTION_LINES_REQUEST",
    "GET_FILE_TRANSACTION_LINES_FAILURE",
    "GET_FILE_TRANSACTION_LINES_SUCCESS",
]);

export const PROP = {};

PROP.types = {
    fetchingDownload: PropTypes.bool,
    ...TypesRedux,
};

PROP.defaults = {
    fetchingDownload: false,
    ...DefaultsRedux,
};

PROP.listItemProps = {
    creditAccountName: PropTypes.string,
    creditAccountNumber: PropTypes.string,
    creditAmountCurrency: PropTypes.string,
    creditAmountQuantity: PropTypes.number,
    bankIdentifier: PropTypes.string,
    status: PropTypes.string.isRequired,
    errorCode: PropTypes.string,
    errorCodeMsg: PropTypes.string,
    lineNumber: PropTypes.number.isRequired,
    isTicket: PropTypes.bool,
    isConfirmation: PropTypes.bool,
    isReadOnly: PropTypes.bool.isRequired,
};
