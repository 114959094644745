import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "enrollment";

export const PATH = {
    ENROLLMENT_INVITATION_CODE_VERIFY: "/enrollment.invitationCode.verify",
    ENROLLMENT_VERIFICATION_CODE_VERIFY: "/enrollment.verificationCode.execute",
    ENROLLMENT_RESEND_VERIFICATION_CODE_VERIFY: "/enrollment.verificationCode.resend",
    ENROLLMENT_REGISTER_PERSONAL_DATA_PRE: "/enrollment.readClientInformation",
    ENROLLMENT_REGISTER_PERSONAL_DATA: "/enrollment.registerPersonalData",
    ENROLLMENT_LIST_SECURITY_SEALS: "/enrollment.securitySeals.list",
    ENROLLMENT_SECURITY_TIPS: "/enrollment.security.tips",
    ENROLLMENT_FINISH_HARD: "/enrollment.finishHard",
    ENROLLMENT_ADD_SOFT_OTP: "/enrollment.add.soft.otp.with.biometric",
    ENROLLMENT_REVOKE_SOFT_OTP: "/enrollment.revoke.soft.otp",
    ENROLLMENT_FINISH_SOFT: "/enrollment.finish.soft.otp",
};

export const TYPE = FactoryType(NAME, [
    "VERIFY_INVITATION_CODE_REQUEST",
    "VERIFY_INVITATION_CODE_SUCCESS",
    "VERIFY_INVITATION_CODE_ERROR",
    "VERIFY_VERIFICATION_CODE_REQUEST",
    "VERIFY_VERIFICATION_CODE_SUCCESS",
    "VERIFY_VERIFICATION_CODE_ERROR",
    "RESEND_VERIFICATION_CODE_REQUEST",
    "RESEND_VERIFICATION_CODE_SUCCESS",
    "RESEND_VERIFICATION_CODE_ERROR",
    "ENROLLMENT_SECURITY_TIPS_REQUEST",
    "ENROLLMENT_SECURITY_TIPS_SUCCESS",
    "ENROLLMENT_SECURITY_TIPS_ERROR",
    "REGISTER_PERSONAL_DATA_PRE_REQUEST",
    "REGISTER_PERSONAL_DATA_PRE_REQUEST_SUCCESS",
    "REGISTER_PERSONAL_DATA_PRE_REQUEST_ERROR",
    "SHOW_SECURITY_SEALS_MODAL",
    "HIDE_SECURITY_SEALS_MODAL",
    "SHOW_SECURITY_SEALS_CONFIRMATION_MODAL",
    "HIDE_SECURITY_SEALS_CONFIRMATION_MODAL",
    "LIST_SECURITY_SEALS_REQUEST",
    "LIST_SECURITY_SEALS_REQUEST_SUCCESS",
    "LIST_SECURITY_SEALS_REQUEST_ERROR",
    "REGISTER_PERSONAL_DATA_REQUEST",
    "REGISTER_PERSONAL_DATA_REQUEST_SUCCESS",
    "REGISTER_PERSONAL_DATA_REQUEST_ERROR",
    "SAVE_SELECTED_SECURITY_SEAL",
    "CHANGE_SECURITY_SEAL",
    "FINISH_ENROLLMENT_HARD_REQUEST",
    "FINISH_ENROLLMENT_HARD_SUCCESS",
    "FINISH_ENROLLMENT_HARD_ERROR",
    "ENROLLMENT_ADD_SOFT_OTP_REQUEST",
    "ENROLLMENT_ADD_SOFT_OTP_SUCCESS",
    "ENROLLMENT_ADD_SOFT_OTP_ERROR",
    "ENROLLMENT_FINISH_SOFT_REQUEST",
    "ENROLLMENT_FINISH_SOFT_SUCCESS",
    "ENROLLMENT_FINISH_SOFT_ERROR",
    "CLEAN_UP",
    "FINISH_CHANGE_MY_PHONE",
]);

export const PROP = {};

PROP.members = {
    client: {
        names: PropTypes.string,
        lastNames: PropTypes.string,
        documentNumber: PropTypes.string,
        documentType: PropTypes.string,
        documentCountry: PropTypes.string,
        idUser: PropTypes.string,
        namesLastNamesEditable: PropTypes.bool,
        idUserEditable: PropTypes.bool,
        otpType: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        document: PropTypes.string,
        documentTypeId: PropTypes.string,
        documentCountryId: PropTypes.string,
    },
    currentSecuritySeal: {
        id: PropTypes.string,
        image: PropTypes.string,
    },
};

PROP.types = {
    exchangeToken: PropTypes.string,
    invitationCode: PropTypes.string,
    fetching: PropTypes.bool,
    accountList: PropTypes.arrayOf(PropTypes.string),
    client: PropTypes.shape(PROP.members.client),
    currentSecuritySeal: PropTypes.shape(PROP.members.currentSecuritySeal),
    securitySealList: PropTypes.arrayOf(PropTypes.shape(PROP.members.securitySeal)),
    selectedSecuritySeal: PropTypes.shape(PROP.members.currentSecuritySeal),
    idUser: PropTypes.string, // TODO: revisar si va
    typeOTP: PropTypes.string,
    finishState: PropTypes.string,
    mobileNumber: PropTypes.string,
    username: PropTypes.string,
    needsBiometric: PropTypes.bool,
    ...TypesRedux,
};

PROP.defaults = {
    fetchingSecuritySeals: false,
    fetching: false,
    seal: null,
    showSealModal: false,
    showSealConfirmationModal: false,
    accountList: [],
    client: null,
    exchangeToken: null,
    isEnrollment: true,
    invitationCode: null,
    currentSecuritySeal: {
        id: EMPTY_STR,
        image: undefined,
    },
    updateSecuritySeal: false,
    securitySealList: [],
    securitySeal: null,
    finishState: null,
    mobileNumber: null,
    username: null,
    needsBiometric: false,
    ...DefaultsRedux,
};
