import Store from "../store";

export const replaceParams = (message, params) =>
    Object.keys(params).reduce((str, key) => {
        const regex = new RegExp(`[#$]{${key}}`, "gi");
        return str.replace(regex, params[key]);
    }, message);

export const get = (id, defaultValue = null, params = {}) => {
    const { items } = Store.getState().i18n || {};
    if (!items || !items[id]) {
        return defaultValue ? String(defaultValue) : `*${id}*`;
    }
    return replaceParams(items[id], params);
};

export const getArray = (id, defaultValue = null, params = {}) => get(id, defaultValue, params).split("|");

export const getLang = () => Store.getState().i18n.lang;
