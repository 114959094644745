import React from "react";

import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { EMPTY_STR } from "~/constants";
import {
    SelectorsAction as SelectorsActionServicePayments,
    SelectorsStore as SelectorsStoreServicePayment,
} from "~/store/servicePayments";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as UtilsDate from "~/util/date";
import * as UtilsI18n from "~/util/i18n";

import DataNumber from "~/components/DataNumber";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Token from "~/components/Token/Token";
import FormattedRate from "~/pages/_components/FormattedRate";
import TicketPrintInformation from "~/pages/_components/TicketPrintInformation";

import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        isSubmitting: PropTypes.bool,
    },
    defaults: {
        isSubmitting: false,
    },
};

export function Component(props) {
    const {
        debitAccountAlias,
        debitAmount: debitAmountFlow,
        formData,
        isSameCurrency,
        isSubmitting,
        rate,
        rateFirstCurrency,
        rateSecondCurrency,
        sistarbancValidateResult,
        transaction,
    } = props;
    const { billsToPay, checkboxes, organismDescription, serviceTypeDescription } =
        formData || transaction?.data.formData || {};
    const { amount, currency, vatRefundAmount } =
        sistarbancValidateResult || transaction?.data.sistarbancValidateResult || {};

    const debitAmount = debitAmountFlow || transaction?.data.amount;

    return (
        <Form id={Style.ID}>
            <TicketPrintInformation />
            <React.Fragment>
                <section className="justify-content-center">
                    <I18n className="text-lead" id="forms.transferInternal.confirmation.title" />
                    <div className="increase-block">
                        <div className="increase-data increase-data-highlight">
                            <I18n className="data-label" id="service.payment.step2.title.amountToPay" />
                            <span className="data-amount">
                                <DataNumber value={amount} prefix={currency} />
                            </span>
                            {vatRefundAmount > 0 && (
                                <React.Fragment>
                                    <I18n className="data-label" id="service.payment.step2.discounts" />
                                    <DataNumber value={vatRefundAmount} prefix={currency} />
                                </React.Fragment>
                            )}
                            {!isSameCurrency && (
                                <React.Fragment>
                                    <I18n className="data-label" id="forms.paySistarbanc.confirmation.debitAmount" />
                                    <span className="data-amount">
                                        <DataNumber value={debitAmount.quantity} prefix={debitAmount.currency} />
                                    </span>
                                    <I18n className="data-label" id="forms.confirmation.rate" />
                                    <span className="data-amount">
                                        <FormattedRate
                                            firstCurrency={rateFirstCurrency}
                                            secondCurrency={rateSecondCurrency}
                                            quantity={rate}
                                        />
                                    </span>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="increase-data increase-data-flow">
                            <div className="data-wrapper align-end">
                                <I18n className="data-label" id="service.payment.step2.originAccount" />
                                <span>{debitAccountAlias}</span>
                            </div>
                            <Image src="arrowRight.svg" className="svg-icon" />
                            <div className="data-wrapper align-start">
                                <div className="services">
                                    <div className="data-service">
                                        <I18n className="data-label" id="service.payment.step2.service" />
                                        <span>{`${organismDescription} - ${serviceTypeDescription}`}</span>
                                    </div>

                                    <div className="data-service">
                                        <I18n className="data-label" id="service.payment.step2.reference" />
                                        {checkboxes.map((checkbox) => (
                                            <span>
                                                {`${billsToPay[checkbox].description} - ${UtilsDate.specificDate(
                                                    billsToPay[checkbox].dueDate,
                                                )} - `}
                                                <DataNumber
                                                    value={billsToPay[checkbox].amount}
                                                    prefix={billsToPay[checkbox].currency}
                                                />
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Token labelButton="global.send" isSubmitting={isSubmitting} />
            </React.Fragment>
        </Form>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        debitAccountAlias: SelectorsStoreServicePayment.getDebitAccountAlias(store),
        debitAmount: SelectorsStoreServicePayment.getDebitAmount(store),
        formData: SelectorsStoreServicePayment.getFormData(store),
        isSameCurrency: SelectorsStoreServicePayment.isSameCurrency(store),
        rate: SelectorsStoreServicePayment.getRate(store),
        rateFirstCurrency: SelectorsStoreServicePayment.getRateFirstCurrency(store),
        rateSecondCurrency: SelectorsStoreServicePayment.getRateSecondCurrency(store),
        sistarbancValidateResult: SelectorsStoreServicePayment.getSistarbancValidateResult(store),
        transaction: SelectorsStoreServicePayment.getTransaction(store),
        transactionDate: SelectorsStoreServicePayment.getTransactionDate(store),
        transactionHour: SelectorsStoreServicePayment.getTransactionHour(store),
        user: SelectorsStoreSession.getUser(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithRouter,
    WithFormik({
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        validationSchema: (props) => {
            const { user } = props;

            return Yup.object().shape({
                otp: user.needsBiometric
                    ? Yup.string().nullable()
                    : Yup.string().required(UtilsI18n.get("form.credential.otp.required")),
            });
        },
        handleSubmit: (props, formikBag) => {
            const {
                debitAccountAlias,
                debitAmount,
                dispatch,
                history,
                isSameCurrency,
                formData,
                sistarbancValidateResult,
                rate,
                rateFirstCurrency,
                rateSecondCurrency,
                transaction,
                transactionDate,
                transactionHour,
            } = formikBag.props;
            const { idActivity, idTransaction } = transaction || EMPTY_STR;
            const isFromTransactionsHistory = history.location.pathname.includes("/ticket");
            const { otp } = props;

            if (isFromTransactionsHistory) {
                dispatch(
                    SelectorsActionServicePayments.signTransaction({
                        idForm: null,
                        idActivity,
                        idTransaction,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            } else {
                // amount, debitAccount, idOrganism and serviceType are built like this
                // in order to meet VU annotation format regarding certain fields
                const data = {
                    amount: !isSameCurrency
                        ? debitAmount
                        : {
                              currency: sistarbancValidateResult.currency.toString(),
                              quantity: sistarbancValidateResult.amount,
                          },
                    debitAccount: formData.debitAccount,
                    debitAccountAlias,
                    formData,
                    idOrganism: formData.idOrganism,
                    isSameCurrency,
                    organismDescription: formData.organismDescription,
                    serviceType: formData.serviceType,
                    serviceTypeDescription: formData.serviceTypeDescription,
                    sistarbancValidateResult,
                    rate,
                    rateFirstCurrency,
                    rateSecondCurrency,
                    transactionDate,
                    transactionHour,
                };

                dispatch(
                    SelectorsActionServicePayments.servicePaymentSendRequest({
                        formData: data,
                        otp,
                        formikBag,
                    }),
                );
            }
        },
    }),
)(Component);
