import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { Categorize } from "~/util/prop";

import Header from "../../_Header";
import Page from "../_Page";
import Style from "./index.rules.scss";

import Internal, { PROP as PROP_INTERNAL } from "..";

export const { NAME, TYPE } = Style;
export const PROP = {
    types: {
        "head-onBack": PropTypes.func,
        ...PROP_INTERNAL.types,
    },
    defaults: {
        "head-onBack": null,
        ...PROP_INTERNAL.defaults,
    },
};

/** This is  a description. */
export function Component(props) {
    const { name, children, head, wait, scopeToShowNotification, ...rest } = Categorize(props);
    const { onBack, onBackWeb } = head;
    const hasBackClass = onBack || onBackWeb ? "hasBack" : EMPTY_STR;

    return (
        <Internal id={Style.ID} name={NAME} wait={wait} scopeToShowNotification={scopeToShowNotification}>
            <Page {...rest} name={name}>
                <Header className={`${hasBackClass}`}>
                    {hasBackClass === "hasBack" && (
                        <Header.Button
                            aria-label="Back"
                            role="navigation"
                            image="left-arrow.svg"
                            onClick={onBack || onBackWeb}
                        />
                    )}
                </Header>
                {children}
            </Page>
        </Internal>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
