import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR, ENVIRONMENT_TYPE, NO_DATA, NO_SPOUSE } from "~/constants";
import * as UtilsI18n from "~/util/i18n";

import FieldReadOnly from "~/pages/_components/fields/FieldReadOnly";

import SectionTitle from "../SectionTitle";

export const NAME = "Information";

export const PROP = {
    types: {
        onClick: PropTypes.func.isRequired,
        data: PropTypes.shape({
            maritalStatus: PropTypes.string,
            monthlyIncome: PropTypes.string,
            phone: PropTypes.string,
            profession: PropTypes.string,
        }).isRequired,
        environmentType: PropTypes.string,
    },
    defaults: {
        data: {},
        environmentType: EMPTY_STR,
    },
};
export function Component({ data, environmentType }) {
    const {
        fullName,
        country,
        cityName,
        townName,
        street,
        doorNumber,
        apto,
        zipCode,
        addressObservations,
        phone,
        professionDescription,
        monthlyIncome,
        maritalStatus,
        spouseFullName,
        spouseDocumenFull,
    } = data;

    const info = {
        country: UtilsI18n.get(`country.name.${country}`),
        departament: cityName,
        city: townName,
        street,
        doorNumber,
        apto,
        zipCode,
        observations: addressObservations,
    };

    const income = `${UtilsI18n.get("core.currency.label.2222")} ${monthlyIncome} `;

    let information = {
        fullName,
        profession: professionDescription,
        maritalStatus: UtilsI18n.get(`client.userconfiguration.maritalStatus.${maritalStatus}`),
        phone,
        monthlyIncome: income,
    };

    if (!NO_SPOUSE.includes(maritalStatus)) {
        information = { ...information, spouse: spouseFullName, spouseDocument: spouseDocumenFull };
    }

    let filteredData;

    if (environmentType === ENVIRONMENT_TYPE.CORPORATE) {
        const CMBKeys = ["phone", "mobilePhone"];

        filteredData = Object.keys(information)
            .filter((key) => CMBKeys.includes(key))
            .reduce((res, key) => {
                res[key] = data[key];
                return res;
            }, {});
    }

    return (
        <div>
            <SectionTitle title="change.personal.date.title" />
            <div className="containerdata--input">
                {Object.keys(filteredData || information).map((key) => (
                    <FieldReadOnly
                        value={information[key] || NO_DATA}
                        className={`containerdata--input__${key}`}
                        label={`change.PersonalData.${key}.label`}
                    />
                ))}
            </div>
            <div>
                <SectionTitle title="change.personal.addresses.title" />
                <div className="container--data--addresses">
                    {Object.keys(info).map((key) => (
                        <FieldReadOnly value={info[key] || NO_DATA} label={`change.PersonalData.${key}.label`} />
                    ))}
                </div>
            </div>
        </div>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default React.memo(Component);
