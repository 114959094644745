import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/Filter";
import {
    SelectorsAction as SelectorsActionPaymentOrders,
    SelectorsStore as SelectorsStorePaymentOrders,
} from "~/store/factoring";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Style from "./List.rules.scss";
import Chart from "./_Chart";
import Filters from "./_Filters";
import PaymentOrders from "./_PaymentOrders";

export const { NAME, NAME_PAYMENT_ORDERS } = Style;

export const PROP = {
    types: {
        fetching: PropTypes.bool.isRequired,
        ...TypesRedux,
    },
    defaults: {
        i18n: {
            listPaymentOrdersTitle: "Listado de órdenes de pago",
            listPaymentOrdersSubTitle: "Órdenes de pago",
            listPaymentOrdersExport: "Exportar",
        },
        ...DefaultsRedux,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        const { dispatch, firstTime } = this.props;
        if (!firstTime) {
            dispatch(SelectorsActionPaymentOrders.loadListPaymentOrdersRequest({ firsTime: true }));
        }
    }

    handleBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    downloadFile = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionPaymentOrders.exportPaymentOrdersRequest());
    };

    render() {
        const { fetching, i18n, filters } = this.props;
        const { status } = filters;
        const { listPaymentOrdersSubTitle } = i18n;
        return (
            <React.Fragment>
                <Container
                    wait={fetching}
                    name={NAME_PAYMENT_ORDERS}
                    head-onBack={this.handleBack}
                    head-title="menu.factoring.paymentOrders"
                    image="paymentOrders.svg"
                    scopeToShowNotification={SCOPE.PAYMENT_ORDERS}>
                    <section>
                        {status === "PEN" && <Chart />}
                        <Filters />
                        <PaymentOrders
                            container-title={listPaymentOrdersSubTitle}
                            container-role="list"
                            name={NAME_PAYMENT_ORDERS}
                        />
                    </section>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    fetching: SelectorsStorePaymentOrders.isFetching(store),
    firstTime: SelectorsStorePaymentOrders.getFirstTime(store),
    filters: SelectorsStorePaymentOrders.getFilters(store),
    i18n: {
        listPaymentOrdersSubTitle: StoreI18n.getMessage(store, "client.factoring.paymentOrders.list.subTitle"),
        listPaymentOrdersExport: StoreI18n.getMessage(store, "client.factoring.paymentOrders.export.label"),
    },
});

export default Connect(mapStateToProps)(Component);
