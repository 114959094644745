import { replace as Replace } from "connected-react-router";
import queryString from "query-string";
import { call as Call, put as Put, select as Select, takeLatest as TakeLatest } from "redux-saga/effects";

import { LEVEL, SCOPE, RESPONSE_TYPE, SIGN_WITH_BIOMETRIC, VALIDATION_ERROR, CREDIT_CARD_OWNER } from "~/constants";
import Store from "~/store";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import IsTrustedBiometric from "~/store/form/_sagas/_isTrustedBiometric";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as ConfigUtils from "~/util/config";
import DeviceUtils from "~/util/device";
import { downloadPdf } from "~/util/download";
import {
    adjustIdFieldErrors as AdjustIdFieldErrors,
    credentialsWithUnderscore as CredentialsWithUnderscore,
    hasIncorrectCredentials as HasIncorrectCredentials,
} from "~/util/form";
import * as UtilsI18n from "~/util/i18n";
import UtilLodash from "~/util/lodash";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsMiddleware, SelectorsStore } from "./_selectors";

export default [
    TakeLatest(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, cancelTransactionCustom),
    TakeLatest(TYPE.CREDIT_CARD_MOVEMENTS_REQUEST, creditCardMovementsRequest),
    TakeLatest(TYPE.CREDIT_CARD_STATEMENT_DOWNLOAD_REQUEST, creditCardStatementDownloadRequest),
    TakeLatest(TYPE.CREDIT_CARD_STATEMENTS_REQUEST, creditCardStatementsRequest),
    TakeLatest(TYPE.DETAILS_REQUEST_MOVEMENT, detailRequest),
    TakeLatest(TYPE.DETAILS_REQUEST, detailsRequest),
    TakeLatest(TYPE.FETCH_MORE_MOVEMENTS_REQUEST, fetchMoreMovementsRequest),
    TakeLatest(TYPE.LIMIT_INCREASE_PRE_REQUEST, limitIncreasePreRequest),
    TakeLatest(TYPE.LIMIT_INCREASE_PREVIEW_REQUEST, limitIncreasePreviewRequest),
    TakeLatest(TYPE.LIMIT_INCREASE_SEND_REQUEST, limitIncreaseSendRequest),
    TakeLatest(TYPE.LIST_REQUEST, listRequest),
    TakeLatest(TYPE.MOVEMENTS_REQUEST, movementsRequest),
    TakeLatest(TYPE.READ_CREDIT_CARD_REQUEST, readCreditCardRequest),
    TakeLatest(TYPE.READ_TRANSACTION_REQUEST, readTransactionRequest),
    TakeLatest(TYPE.SIGN_TRANSACTION_REQUEST, signTransaction),
    TakeLatest(TYPE.PURCHASE_NOTIFICATION_PRE_REQUEST, purchaseNotificationPreRequest),
    TakeLatest(TYPE.PURCHASE_NOTIFICATION_PREVIEW_REQUEST, purchaseNotificationPreviewRequest),
    TakeLatest(TYPE.PURCHASE_NOTIFICATION_SEND_REQUEST, purchaseNotificationSendRequest),
    TakeLatest(TYPE.TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_PRE_REQUEST, tripOrOnlinePurchaseNotificationPreRequest),
    TakeLatest(
        TYPE.TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_PREVIEW_REQUEST,
        tripOrOnlinePurchaseNotificationPreviewRequest,
    ),
    TakeLatest(TYPE.TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_SEND_REQUEST, tripOrOnlinePurchaseNotificationSendRequest),
    TakeLatest(TYPE.UPDATE_NOTE_REQUEST, updateNoteRequest),
    TakeLatest(TYPE.CHANGE_STATUS_REQUEST, changeCreditCardStatusRequest),
];

function* cancelTransactionCustom(props) {
    const { credentials, idTransaction, formikBag } = props;
    yield* handleCancelTransaction(credentials, idTransaction, formikBag);

    formikBag.setSubmitting(false);
}

function* creditCardStatementDownloadRequest(props) {
    const { id, month, year } = props;
    const params = { idCreditCard: id, month, year };
    const creditCardStatementDownloadResponse = yield Call(SelectorsMiddleware.statementDownloadRequest, params);

    if (creditCardStatementDownloadResponse.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.creditCardStatementDownloadFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: creditCardStatementDownloadResponse.data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.CREDIT_CARD_STATEMENT_DOWNLOAD, SCOPE.CREDIT_CARD_DETAILS],
            }),
        );
    } else {
        const { content, fileName } = creditCardStatementDownloadResponse.data.data;

        if (DeviceUtils.isMobileNative()) {
            try {
                yield Put(SelectorsAction.creditCardStatementDownloadSuccess());

                yield Call(window.pdf.save, creditCardStatementDownloadResponse.data.data);
            } catch (error) {
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: creditCardStatementDownloadResponse.data.message,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.CREDIT_CARD_STATEMENT_DOWNLOAD],
                    }),
                );
            }
        } else {
            downloadPdf(fileName, content);
            yield Put(SelectorsAction.creditCardStatementDownloadSuccess());
        }
    }
}

function* creditCardStatementsRequest(props) {
    const { id } = props;
    const idCreditCard = { idCreditCard: id };
    const creditCardStatementsResponse = yield Call(SelectorsMiddleware.statementsRequest, idCreditCard);

    if (creditCardStatementsResponse.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.creditCardStatementsFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.CREDIT_CARD_DETAILS],
            }),
        );
    } else {
        const { statements } = creditCardStatementsResponse.data.data;

        yield Put(SelectorsAction.creditCardStatementsSuccess({ statements, id }));
    }
}

function* detailRequest(props) {
    const myProps = { ...props };

    delete myProps.type;

    const response = yield Call(SelectorsMiddleware.detailRequest, myProps);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.detailFailureMovement());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.CREDIT_CARD_MOVEMENT_DETAIL],
            }),
        );
    } else {
        const { statement } = response.data.data;
        const movement = { movement: statement };

        yield Put(SelectorsAction.detailSuccessMovement(movement));
    }
}

function* detailsRequest(props) {
    const { idCreditCard } = props;
    const myProps = { ...props };

    delete myProps.type;

    const detailResponse = yield Call(SelectorsMiddleware.detailsRequest, { idCreditCard });
    const movementsResponse = yield Call(SelectorsMiddleware.movementsRequest, myProps);

    if (detailResponse.type === RESPONSE_TYPE.WARNING || movementsResponse.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.detailFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.CREDIT_CARD_DETAILS],
            }),
        );
    } else {
        const { creditCard } = detailResponse.data.data;
        const { moreStatements, pageNumber, statements } = movementsResponse.data.data;
        const params = {
            detail: creditCard,
            hasMoreMovements: moreStatements,
            movements: statements,
            pageNumber,
        };

        yield Put(SelectorsAction.detailSuccess(params));
    }
}

function* fetchMoreMovementsRequest(props) {
    const { filters, formikBag, idCreditCard } = props;
    const { pageNumber } = filters;
    const middlewareParams = { idCreditCard, pageNumber };

    const response = yield Call(SelectorsMiddleware.movementsRequest, middlewareParams);

    if (response.type === RESPONSE_TYPE.WARNING && formikBag) {
        formikBag.setError(AdjustIdFieldErrors(response.data.data));
    } else if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.fetchMoreMovementsFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.CREDIT_CARD_DETAILS],
            }),
        );
    } else {
        const { moreStatements, statements } = response.data.data;
        const params = {
            hasMoreMovements: moreStatements,
            movements: statements,
            pageNumber,
        };

        yield Put(SelectorsAction.fetchMoreMovementsSuccess(params));
    }

    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* handleCancelTransaction(credentials, idTransaction, formikBag) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);
    const {
        data: { data },
        type,
    } = yield Call(SelectorsMiddlewareForm.cancelTransaction, {
        idTransactionToCancel: idTransaction,
        ...credentialsWithUnderscore,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data);
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("forms.cancelTransaction.errorMessage"),
                    level: LEVEL.ERROR,
                    scopes: SCOPE.CREDIT_CARDS_LIMIT_INCREASE,
                }),
            );
        }
    } else {
        yield* readTransactionRequest({ idTransaction });
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("forms.cancelTransaction.confirmationMessage"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.TRANSACTIONS],
            }),
        );
    }
}

function* limitIncreasePreRequest() {
    const response = yield Call(SelectorsMiddleware.limitIncreasePreRequest);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.limitIncreasePreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.CREDIT_CARDS_LIMIT_INCREASE],
            }),
        );
    } else {
        yield Put(SelectorsAction.limitIncreasePreSuccess(response.data.data));
    }
}

function* limitIncreasePreviewRequest({ accountVisaMaster, amount, beginDate, endDate }) {
    const { type, data } = yield Call(SelectorsMiddleware.limitIncreasePreviewRequest, {
        accountVisaMaster,
        amount,
        beginDate,
        endDate,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.limitIncreasePreviewFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.CREDIT_CARDS_LIMIT_INCREASE],
            }),
        );
    } else {
        yield Put(SelectorsAction.limitIncreasePreviewSuccess(data.data));
    }
}

function* limitIncreaseSendRequest({ formData, formikBag, otp }) {
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
    }

    const { webToken } = Store.getState().session;

    const tokenToUse = otp || webToken;

    let response;

    if (UtilLodash.isEmpty(tokenToUse)) {
        response = {
            data: {
                code: VALIDATION_ERROR,
                message: UtilsI18n.get(`returnCode.${VALIDATION_ERROR}`),
                data: {
                    otp: UtilsI18n.get("returnCode.COR027W"),
                },
            },
            status: 200,
            type: RESPONSE_TYPE.WARNING,
        };
    } else {
        response = yield Call(SelectorsMiddleware.limitIncreaseSendRequest, {
            ...formData,
            _otp: tokenToUse,
        });
    }

    if (response.type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = Object.keys(response.data.data).some((key) => key === "otp");

        let message = UtilsI18n.get("global.unexpectedError");

        if (hasIncorrectCredentials) {
            formikBag.setErrors(response.data.data);
        } else if (VALIDATION_ERROR === response.data.code) {
            message = response.data.data.message;
        }

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.CREDIT_CARDS_LIMIT_INCREASE],
            }),
        );

        formikBag.setSubmitting(false);

        yield Put(SelectorsAction.limitIncreaseSendFailure());
    } else {
        const { idTransaction } = response.data;
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(SelectorsAction.limitIncreaseSendSuccess({ transaction }));
    }
}

function* listRequest(props) {
    const response = yield Call(SelectorsMiddleware.listRequest);
    const { path } = props;

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.listFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.CREDIT_CARDS_PAYMENT, SCOPE.CREDIT_CARDS_LIST],
            }),
        );
    } else {
        const { creditCards } = response.data.data;

        if (creditCards.length === 1 && path && path.startsWith("/form")) {
            const { idProduct } = creditCards[0];

            // listRequest method is reused on credit cards payment which should
            // redirect to a dynamic form instead of credit card detail
            yield Put(
                Replace({
                    pathname: path,
                    search: queryString.stringify({ creditCard: idProduct }),
                }),
            );
        } else {
            const params = { list: creditCards };

            yield Put(SelectorsAction.listSuccess(params));
        }
    }
}

function* movementsRequest(props) {
    const { filters, formikBag, id } = props;
    const response = yield Call(SelectorsMiddleware.movementsRequest, { filters, id });

    if (response.type === RESPONSE_TYPE.WARNING && formikBag) {
        formikBag.setError(AdjustIdFieldErrors(response.data.data));
    } else if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.movementsFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.CREDIT_CARD_DETAILS],
            }),
        );
    } else {
        const { moreStatements, statements } = response.data.data;
        const params = {
            hasMoreMovements: moreStatements,
            movements: statements,
        };

        yield Put(SelectorsAction.movementsSuccess(params));
    }

    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* purchaseNotificationPreRequest() {
    const response = yield Call(SelectorsMiddleware.purchaseNotificationPreRequest);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.purchaseNotificationPreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.CREDIT_CARDS_PURCHASE_NOTIFICATION],
            }),
        );
    } else {
        yield Put(SelectorsAction.purchaseNotificationPreSuccess(response.data.data));
    }
}

function* purchaseNotificationPreviewRequest(formData) {
    const { type, data } = yield Call(SelectorsMiddleware.purchaseNotificationPreviewRequest, formData);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.purchaseNotificationPreviewFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.CREDIT_CARDS_PURCHASE_NOTIFICATION],
            }),
        );
    } else {
        const previewSuccessData = {
            ...data.data,
            notifications: formData.notifications,
        };

        yield Put(SelectorsAction.purchaseNotificationPreviewSuccess(previewSuccessData));
    }
}

function* purchaseNotificationSendRequest({ formData, formikBag, otp }) {
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
    }

    const { webToken } = Store.getState().session;

    const tokenToUse = otp || webToken;

    let response;

    if (UtilLodash.isEmpty(tokenToUse)) {
        response = {
            data: {
                code: VALIDATION_ERROR,
                message: UtilsI18n.get(`returnCode.${VALIDATION_ERROR}`),
                data: {
                    otp: UtilsI18n.get("returnCode.COR027W"),
                },
            },
            status: 200,
            type: RESPONSE_TYPE.WARNING,
        };
    } else {
        response = yield Call(SelectorsMiddleware.purchaseNotificationSendRequest, {
            ...formData,
            _otp: tokenToUse,
        });
    }

    if (response.type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = Object.keys(response.data.data).some((key) => key === "otp");

        let message = UtilsI18n.get("global.unexpectedError");

        if (hasIncorrectCredentials) {
            formikBag.setErrors(response.data.data);
        } else if (VALIDATION_ERROR === response.data.code) {
            message = response.data.data.message;
        }

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.CREDIT_CARDS_PURCHASE_NOTIFICATION],
            }),
        );

        formikBag.setSubmitting(false);

        yield Put(SelectorsAction.purchaseNotificationSendFailure());
    } else {
        const { idTransaction } = response.data;
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(SelectorsAction.purchaseNotificationSendSuccess({ transaction }));
    }
}

function* readCreditCardRequest(props) {
    const myProps = { ...props };

    delete myProps.type;

    const readCreditCardResponse = yield Call(SelectorsMiddleware.detailsRequest, myProps);

    if (readCreditCardResponse.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.readCreditCardFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.CREDIT_CARD_DETAILS],
            }),
        );
    } else {
        const { creditCard } = readCreditCardResponse.data.data;

        yield Put(SelectorsAction.readCreditCardSuccess({ creditCard }));
    }
}

function* readTransactionRequest(props) {
    const { idTransaction } = props;
    const { type, data } = yield Call(SelectorsMiddlewareForm.readTransaction, {
        idTransactionToRead: idTransaction,
    });
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.CREDIT_CARDS_LIMIT_INCREASE],
            }),
        );
    } else {
        yield Put(SelectorsAction.readTransactionSuccess({ transaction: data.data.transaction }));
    }
}

function* signTransaction(props) {
    const { credentials, idActivity, idForm, idTransaction, formikBag } = props;
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
    }

    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { data, type } = yield Call(
        SelectorsMiddlewareForm.sign,
        { idForm, idTransaction, ...credentialsWithUnderscore },
        idActivity,
    );

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(AdjustIdFieldErrors(data.data));
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.message,
                    level: LEVEL.ERROR,
                    scopes: [
                        SCOPE.CREDIT_CARDS_LIMIT_INCREASE,
                        SCOPE.CREDIT_CARDS_TRIP_OR_ONLINE_PURCHASE_NOTIFICATION,
                    ],
                }),
            );
        }

        yield Put(SelectorsAction.signTransactionFailure());

        formikBag.setSubmitting(false);
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, { idTransactionToRead: idTransaction });

        yield Put(SelectorsAction.signTransactionSuccess({ transaction }));

        formikBag.setSubmitting(false);
    }
}

function* tripOrOnlinePurchaseNotificationPreRequest() {
    const response = yield Call(SelectorsMiddleware.tripOrOnlinePurchaseNotificationPreRequest);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.tripOrOnlinePurchaseNotificationPreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.CREDIT_CARDS_TRIP_OR_ONLINE_PURCHASE_NOTIFICATION],
            }),
        );
    } else {
        yield Put(SelectorsAction.tripOrOnlinePurchaseNotificationPreSuccess(response.data.data));
    }
}

function* tripOrOnlinePurchaseNotificationPreviewRequest(formData) {
    const { type, data } = yield Call(SelectorsMiddleware.tripOrOnlinePurchaseNotificationPreviewRequest, formData);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.tripOrOnlinePurchaseNotificationPreviewFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.CREDIT_CARDS_TRIP_OR_ONLINE_PURCHASE_NOTIFICATION],
            }),
        );
    } else {
        yield Put(SelectorsAction.tripOrOnlinePurchaseNotificationPreviewSuccess(data.data));
    }
}

function* tripOrOnlinePurchaseNotificationSendRequest({ formData, formikBag, otp }) {
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
    }

    const { webToken } = Store.getState().session;

    const tokenToUse = otp || webToken;

    let response;

    if (UtilLodash.isEmpty(tokenToUse)) {
        response = {
            data: {
                code: VALIDATION_ERROR,
                message: UtilsI18n.get(`returnCode.${VALIDATION_ERROR}`),
                data: {
                    otp: UtilsI18n.get("returnCode.COR027W"),
                },
            },
            status: 200,
            type: RESPONSE_TYPE.WARNING,
        };
    } else {
        response = yield Call(SelectorsMiddleware.tripOrOnlinePurchaseNotificationSendRequest, {
            ...formData,
            _otp: tokenToUse,
        });
    }

    if (response.type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = Object.keys(response.data.data).some((key) => key === "otp");

        let message = UtilsI18n.get("global.unexpectedError");

        if (hasIncorrectCredentials) {
            formikBag.setErrors(response.data.data);
        } else if (VALIDATION_ERROR === response.data.code) {
            message = response.data.data.message;
        }

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.CREDIT_CARDS_TRIP_OR_ONLINE_PURCHASE_NOTIFICATION],
            }),
        );

        formikBag.setSubmitting(false);

        yield Put(SelectorsAction.tripOrOnlinePurchaseNotificationSendFailure());
    } else {
        const { idTransaction } = response.data;
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(SelectorsAction.tripOrOnlinePurchaseNotificationSendSuccess({ transaction }));
    }
}

function* updateNoteRequest(props) {
    const myProps = { ...props };

    delete myProps.type;

    const response = yield Call(SelectorsMiddleware.updateNoteRequest, myProps);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.updateNoteFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.CREDIT_CARD_MOVEMENT_DETAIL],
            }),
        );
    } else {
        const { note } = props;
        const params = { note };

        yield Put(SelectorsAction.updateNoteSuccess(params));
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("creditCards.movement.detail.noteSaved"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.CREDIT_CARD_MOVEMENT_DETAIL],
            }),
        );
    }
}

function* changeCreditCardStatusRequest(props) {
    const myProps = { ...props };
    delete myProps.type;
    const config = ConfigUtils.get(`client.creditCard.changeStatus.label.${myProps.order}`);

    const response = yield Call(SelectorsMiddleware.changeStatusRequest, myProps);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.changeStatusFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.CREDIT_CARD_DETAILS],
            }),
        );
    } else {
        const { creditCardPosition } = myProps;

        yield Put(SelectorsAction.changeStatusSuccess({ creditCardstatus: config, creditCardPosition }));

        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.data.message,
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.CREDIT_CARD_DETAILS],
            }),
        );
    }
}

function* creditCardMovementsRequest(props) {
    const { creditCard, idCreditCard, pageNumber, pageNumberAux } = props;
    const { number, ownershipType } = creditCard;

    const items = yield Select(SelectorsStore.getMovements);
    const hasMoreMovements = yield Select(SelectorsStore.isHasMoreMovements);
    const page = yield Select(SelectorsStore.getPageNumber);

    const perPage = ConfigUtils.get("creditCard.statementsPerPage", 10);
    const limit = pageNumber * perPage;

    const movements =
        ownershipType === CREDIT_CARD_OWNER
            ? items.slice(0, limit)
            : items
                  .filter(
                      ({ creditCardNumber }) =>
                          creditCardNumber.length > 4 &&
                          creditCardNumber.substr(creditCardNumber.length - 4) === number.substr(number.length - 4),
                  )
                  .slice(0, limit);

    const numberOfElementsVisited = pageNumberAux * perPage;

    if (limit > movements.length && (hasMoreMovements || numberOfElementsVisited <= items.length)) {
        if (numberOfElementsVisited >= items.length && hasMoreMovements) {
            yield Call(fetchMovementsByIdCreditCard, {
                creditCard,
                idCreditCard,
                filters: { page: page + 1 },
                hasMoreMovements,
                pageNumber,
                pageNumberAux,
            });
        } else {
            yield Call(creditCardMovementsRequest, {
                creditCard,
                idCreditCard,
                pageNumberAux: pageNumberAux + 1,
                pageNumber,
            });
        }
    } else {
        yield Put(
            SelectorsAction.creditCardMovementsSuccess({
                pageNumber,
                creditCard: {
                    pageNumber: pageNumber || 1,
                    creditCardNumber: number,
                    movements,
                    pageNumberAux,
                    hasMoreMovements: hasMoreMovements || numberOfElementsVisited <= items.length,
                },
            }),
        );
    }
}

function* fetchMovementsByIdCreditCard(props) {
    const { creditCard, idCreditCard, filters, pageNumber, pageNumberAux } = props;
    const { page } = filters;

    const middlewareParams = { idCreditCard, pageNumber: page };

    const response = yield Call(SelectorsMiddleware.movementsRequest, middlewareParams);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.fetchMoreMovementsFailure());
    } else {
        const { moreStatements, statements } = response.data.data;

        const params = {
            hasMoreMovements: moreStatements,
            movements: statements,
            pageNumber: page,
        };

        yield Put(SelectorsAction.fetchMoreMovementsSuccess(params));

        yield Call(creditCardMovementsRequest, {
            creditCard,
            idCreditCard,
            pageNumber,
            pageNumberAux,
        });
    }
}
