import { call as Call, put as Put, takeEvery as TakeEvery } from "redux-saga/effects";

import { LEVEL, SCOPE, RESPONSE_TYPE } from "~/constants";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import UtilsDevice from "~/util/device";
import { downloadPdf as UtilsDownloadPdf } from "~/util/download";
import * as UtilsI18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsMiddleware } from "./_selectors";

export default [TakeEvery(TYPE.LIST_REQUEST, listRequest), TakeEvery(TYPE.DOWNLOAD_POSITION, pdfPosition)];

function* listRequest(props) {
    const myProps = { ...props };
    delete myProps.type;
    const response = yield Call(SelectorsMiddleware.listRequest, myProps);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.listFailure(props));
        const errorMsg = UtilsI18n.get("global.unexpectedError");
        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMsg,
                level: LEVEL.ERROR,
                scopes: [SCOPE.DESKTOP],
            }),
        );
    } else {
        yield Put(SelectorsAction.listSuccess({ widget: props.widget, data: response.data.data }));
    }
}

function* pdfPosition(props) {
    const {
        type,
        data: { message, data },
    } = yield Call(SelectorsMiddleware.pdfPosition);
    const myProps = { ...props };
    delete myProps.type;
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.pdfFailure(myProps));
        yield Put(
            SelectorsActionNotification.showNotification({ message, level: LEVEL.ERROR, scopes: [SCOPE.DESKTOP] }),
        );
    } else {
        const { content, filename } = data;
        if (UtilsDevice.isMobileNative()) {
            try {
                yield Put(SelectorsAction.pdfSuccess(myProps));
                yield Call(window.pdf.save, data);
            } catch (error) {
                yield Put(SelectorsAction.pdfFailure(myProps));
                const errorMsg = UtilsI18n.get("position.pdf.download.error");
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: errorMsg,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.DESKTOP],
                    }),
                );
            }
        } else {
            UtilsDownloadPdf(filename, content);
            yield Put(SelectorsAction.pdfSuccess(myProps));
        }
    }
}
