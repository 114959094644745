import { push as Push } from "connected-react-router";
import { call as Call, put as Put, select as Select, takeLatest as TakeLatest } from "redux-saga/effects";

import {
    API_ENROLLMENT_INVALID_CHANNEL,
    APIErrorCodes,
    BIOMETRIC_ID_USER,
    BIOMETRIC_TYPE,
    BIOMETRIC_TYPES,
    EMPTY_STR,
    FINGERPRINT_AUTH_TOKEN,
    HARD_OTP,
    INVITATION_CODE_IN_USE,
    LEVEL,
    MSG_RETURN_CODE_PREFIX,
    NOTIFICATION_DURATION,
    RESPONSE_TYPE,
    SCOPE,
    SOFT_OTP,
    USER_PERSONAL_NAME,
} from "~/constants";
import {
    SelectorsAction as SelectorsActionChangeMyPhone,
    SelectorsStore as SelectorsStoreChangeMyPhone,
} from "~/store/changeMyPhone";
import {
    SelectorsStore as SelectorsStoreConfig,
    SelectorsAction as SelectorsActionConfig,
    TYPE as TYPES_CONFIG,
} from "~/store/config";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import UtilsDevice from "~/util/device";
import { adjustIdFieldErrors } from "~/util/form";
import { get as Get } from "~/util/i18n";
import Logger from "~/util/logger";
import * as UtilsSecureStorage from "~/util/secureStorage";
import * as UtilsSoftToken from "~/util/softToken";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsMiddleware } from "./_selectors";

export default [
    TakeLatest(TYPE.VERIFY_INVITATION_CODE_REQUEST, verifyInvitationCode),
    TakeLatest(TYPE.VERIFY_VERIFICATION_CODE_REQUEST, verifyVerificationCode),
    TakeLatest(TYPE.RESEND_VERIFICATION_CODE_REQUEST, resendVerificationCode),
    TakeLatest(TYPE.ENROLLMENT_SECURITY_TIPS_REQUEST, securityTipsRequest),
    TakeLatest(TYPE.REGISTER_PERSONAL_DATA_PRE_REQUEST, registerPersonalDataPre),
    TakeLatest(TYPE.LIST_SECURITY_SEALS_REQUEST, requestSecuritySeals),
    TakeLatest(TYPE.REGISTER_PERSONAL_DATA_REQUEST, registerPersonalData),
    TakeLatest(TYPE.ENROLLMENT_ADD_SOFT_OTP_REQUEST, addSoftOTPWithBiometric),
    TakeLatest(TYPE.ENROLLMENT_FINISH_SOFT_REQUEST, finishEnrollmentSoft),
    TakeLatest(TYPE.FINISH_ENROLLMENT_HARD_REQUEST, finishEnrollmentHard),
];

function* verifyInvitationCode(props) {
    const isChangeMyPhone = yield Select(SelectorsStoreChangeMyPhone.isChangeMyPhone);
    const { formikBag, invitationCode } = props;
    let response;

    if (isChangeMyPhone) {
        const exchangeToken = yield Select(SelectorsStoreChangeMyPhone.exchangeToken);

        response = yield Call(SelectorsMiddleware.verifyInvitationCodeWithExchangeToken, {
            _code: invitationCode,
            _exchangeToken: exchangeToken,
            isChangeMyPhone,
        });
    } else {
        let deviceId = EMPTY_STR;
        let deviceModel = EMPTY_STR;

        if (UtilsDevice.isMobileNative()) {
            deviceId = UtilsDevice.id();
            deviceModel = UtilsDevice.model();
        }

        response = yield Call(SelectorsMiddleware.verifyInvitationCode, {
            _code: invitationCode,
            deviceId, // only for logging purpose
            deviceModel, // only for logging purpose
            isChangeMyPhone,
        });
    }

    if (formikBag) {
        formikBag.setSubmitting(false);
    }

    if (response) {
        if (response.type === RESPONSE_TYPE.WARNING) {
            const { code } = response.data;
            let error = null;

            switch (code) {
                case API_ENROLLMENT_INVALID_CHANNEL:
                case INVITATION_CODE_IN_USE:
                    error = `${MSG_RETURN_CODE_PREFIX}.${code}`;

                    break;
                default:
                    if (code.includes("API")) {
                        error = `enrollment.index.invitationCode.${APIErrorCodes[code]}`;
                    } else {
                        formikBag.setErrors(adjustIdFieldErrors(response.data.data, false));
                    }

                    break;
            }

            yield Put(SelectorsAction.errorVerifyInvitationCode(response.data));

            if (error !== null) {
                const msg = Get(error);

                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: msg,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.INVITATION_CODE],
                        autoDismiss: NOTIFICATION_DURATION.MED,
                    }),
                );
            }
        } else if (response.status === RESPONSE_TYPE.UNAUTHORIZED) {
            yield Put(Push("/"));

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.EXTERNAL_DASHBOARD],
                }),
            );

            yield Put(SelectorsAction.errorVerifyInvitationCode(response.data));
        } else {
            const { _exchangeToken, mobileNumber, otpType } = response.data.data;
            const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);
            const needsBiometric = isTrusted && otpType.indexOf(SOFT_OTP) !== -1;

            yield Put(
                SelectorsAction.successVerifyInvitationCode({
                    exchangeToken: _exchangeToken,
                    mobileNumber,
                    needsBiometric,
                    otpType,
                }),
            );

            let location = EMPTY_STR;

            if (isChangeMyPhone) {
                location = "changeMyPhone/verificationCode";
            } else {
                location = otpType === HARD_OTP ? "enrollment/registerPersonalData" : "enrollment/verificationCode";
            }

            yield Put(Push(`/${location}`));
        }
    }
}

function* verifyVerificationCode(props) {
    const { formikBag, verificationCode } = props;
    let { exchangeToken } = formikBag.props;
    const isChangeMyPhone = yield Select(SelectorsStoreChangeMyPhone.isChangeMyPhone);

    if (isChangeMyPhone) {
        exchangeToken = yield Select(SelectorsStoreChangeMyPhone.exchangeToken);
    }

    const response = yield Call(SelectorsMiddleware.verifyVerificationCode, {
        _exchangeToken: exchangeToken,
        _verificationCode: verificationCode,
        isChangeMyPhone,
    });

    if (formikBag) {
        formikBag.setSubmitting(false);
    }

    if (response) {
        const { data, status, type } = response;

        if (type === RESPONSE_TYPE.WARNING) {
            yield Put(SelectorsAction.errorVerificationCode());

            if (data.data.NO_FIELD) {
                yield Put(Push("/"));

                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: data.data.NO_FIELD,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.EXTERNAL_DASHBOARD],
                    }),
                );
            } else {
                formikBag.setErrors(adjustIdFieldErrors(data.data, false));
                formikBag.setSubmitting(false);
            }
        } else if (status === RESPONSE_TYPE.UNAUTHORIZED) {
            yield Put(SelectorsAction.errorVerificationCode());

            yield Put(Push("/"));

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: Get(data.message),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.EXTERNAL_DASHBOARD],
                }),
            );
        } else {
            const { _exchangeToken } = data.data;

            yield Put(SelectorsAction.successVerificationCode({ exchangeToken: _exchangeToken }));

            if (isChangeMyPhone) {
                yield Put(Push("/biometricTypeChoice"));
            } else {
                yield Put(Push("/enrollment/registerPersonalData"));
            }
        }
    }
}

function* resendVerificationCode(props) {
    const { exchangeToken } = props;

    const response = yield Call(SelectorsMiddleware.resendVerificationCode, {
        _exchangeToken: exchangeToken,
    });

    if (response) {
        if (response.type === RESPONSE_TYPE.WARNING) {
            const error = response.data;

            yield Put(SelectorsAction.errorResendIVerificationCode());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: Get(error.message),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.VERIFICATION_CODE],
                }),
            );
        } else if (response.status === RESPONSE_TYPE.UNAUTHORIZED) {
            yield Put(Push("/"));

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: Get(response.data.message),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.EXTERNAL_DASHBOARD],
                }),
            );

            yield Put(SelectorsAction.errorResendIVerificationCode());
        } else {
            const { _exchangeToken } = response.data.data;

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: Get("enrollment.step1.verificationCode.incorrectNumber.message"),
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.VERIFICATION_CODE],
                }),
            );

            yield Put(SelectorsAction.successResendVerificationCode({ exchangeToken: _exchangeToken }));
        }
    }
}

function* securityTipsRequest(props) {
    const { formikBag } = props;
    const biometricType = BIOMETRIC_TYPES.fingerPrint;
    let deviceId = "deviceId";
    let dataDevice = "dataDevice";

    if (UtilsDevice.isMobileNative()) {
        deviceId = UtilsDevice.id();
        dataDevice = UtilsDevice.model();
    }

    const { client, exchangeToken } = formikBag.props;
    const { idUser } = client;

    const response = yield Call(SelectorsMiddleware.securityTips, {
        _exchangeToken: exchangeToken,
        biometricType,
        dataDevice,
        deviceId,
        idUser,
    });

    if (response) {
        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(SelectorsAction.securityTipsError());

            yield Put(Push("/"));

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.EXTERNAL_DASHBOARD],
                }),
            );
        } else if (response.status === RESPONSE_TYPE.UNAUTHORIZED) {
            yield Put(Push("/"));

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.EXTERNAL_DASHBOARD],
                }),
            );

            yield Put(SelectorsAction.securityTipsError());
        } else {
            const { ...rest } = response.data.data;

            yield Put(
                SelectorsAction.securityTipsSuccess({
                    ...rest,
                }),
            );

            yield Put(Push("/biometricTypeChoice"));
        }
    }
}

function* registerPersonalDataPre(props) {
    const { exchangeToken } = props;

    const response = yield Call(SelectorsMiddleware.registerPersonalDataPre, {
        _exchangeToken: exchangeToken,
    });

    if (response) {
        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(SelectorsAction.registerPersonalDataPreError());

            yield Put(Push("/"));

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.EXTERNAL_DASHBOARD],
                }),
            );
        } else {
            const { _exchangeToken, _securitySeal, otpType, ...rest } = response.data.data;

            yield Put(
                SelectorsAction.registerPersonalDataPreSuccess({
                    exchangeToken: _exchangeToken,
                    otpType,
                    securitySeal: _securitySeal,
                    ...rest,
                }),
            );
        }
    }
}

function* requestSecuritySeals(props) {
    const { exchangeToken } = props;

    const response = yield Call(SelectorsMiddleware.requestSecuritySeals, {
        _exchangeToken: exchangeToken,
    });

    if (response) {
        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(SelectorsAction.requestSecuritySealsError());
        } else {
            const { _exchangeToken, _securitySealList, ...rest } = response.data.data;

            yield Put(
                SelectorsAction.requestSecuritySealsSuccess({
                    exchangeToken: _exchangeToken,
                    securitySealList: _securitySealList,
                    ...rest,
                }),
            );
        }
    }
}

function* registerPersonalData(props) {
    const { formikBag, idSecuritySeal, lastNames, names, otpSerialNumber, password, passwordConfirmation, username } =
        props;

    const { exchangeToken } = formikBag.props;

    const response = yield Call(SelectorsMiddleware.registerPersonalData, {
        _exchangeToken: exchangeToken,
        _password: password,
        _passwordConfirmation: passwordConfirmation,
        idSecuritySeal,
        lastNames,
        names,
        otpSerialNumber,
        username,
    });

    if (formikBag) {
        formikBag.setSubmitting(false);
    }

    if (response) {
        const { code } = response.data;

        if (response.type === RESPONSE_TYPE.WARNING) {
            if (APIErrorCodes[code]) {
                const error = null || (APIErrorCodes[code] && `enrollment.index.invitationCode.${APIErrorCodes[code]}`);

                yield Put({
                    type: TYPE.REGISTER_PERSONAL_DATA_REQUEST_ERROR,
                    error,
                });

                yield Put(Push("/"));

                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: response.data.message,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.EXTERNAL_DASHBOARD],
                    }),
                );
            } else {
                formikBag.setErrors(adjustIdFieldErrors(response.data.data, false));

                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: response.data.message,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.REGISTER_PERSONAL_DATA],
                    }),
                );
            }

            yield Put(SelectorsAction.registerPersonalDataError(response.data));
        } else if (response.status === RESPONSE_TYPE.UNAUTHORIZED) {
            yield Put(Push("/"));

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.EXTERNAL_DASHBOARD],
                }),
            );

            yield Put(SelectorsAction.registerPersonalDataError(response.data));
        } else {
            const { _exchangeToken, finishState, ...rest } = response.data.data;

            yield Put(
                SelectorsAction.registerPersonalDataSuccess({
                    exchangeToken: _exchangeToken,
                    finishState,
                    username,
                    ...rest,
                }),
            );

            yield Put(Push("/enrollment/securityTips"));
        }
    }
}

function* addSoftOTPWithBiometric(props) {
    const { exchangeToken, biometricType } = props;
    const isChangeMyPhone = yield Select(SelectorsStoreChangeMyPhone.isChangeMyPhone);
    const { username } = props;

    let deviceId = "deviceId";
    let deviceModel = "dataModel";

    if (UtilsDevice.isMobileNative()) {
        deviceId = UtilsDevice.id();
        deviceModel = UtilsDevice.model();
    }

    let hasPermission;

    try {
        hasPermission = yield Call(UtilsSoftToken.vascoHasPermission);
    } catch {
        hasPermission = false;
    }

    if (hasPermission) {
        const response = yield Call(SelectorsMiddleware.addSoftOTPWithBiometric, {
            _exchangeToken: exchangeToken,
            biometricType,
            deviceId,
            deviceModel,
            isChangeMyPhone,
            username,
        });

        if (response) {
            if (response.type === RESPONSE_TYPE.WARNING) {
                yield Put(SelectorsAction.addSoftOTPWithBiometricError());

                yield Put(Push("/"));

                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: response.data.message,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.EXTERNAL_DASHBOARD],
                    }),
                );
            } else if (response.status === RESPONSE_TYPE.UNAUTHORIZED) {
                yield Put(Push("/"));

                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: response.data.message,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.EXTERNAL_DASHBOARD],
                    }),
                );

                yield Put(SelectorsAction.addSoftOTPWithBiometricError());
            } else {
                const { _commonName, personalName, seed, ...rest } = response.data.data;
                let activated = false;
                let errorActivation;

                try {
                    activated = yield Call(UtilsSoftToken.activateOnline, { _commonName, seed });
                } catch (erorPlugin) {
                    errorActivation = erorPlugin;
                }

                if (activated) {
                    yield Put(
                        SelectorsAction.addSoftOTPWithBiometricSuccess({
                            ...rest,
                        }),
                    );

                    yield Call(UtilsSecureStorage.set, BIOMETRIC_ID_USER, username);
                    yield Call(UtilsSecureStorage.set, BIOMETRIC_TYPE, biometricType);
                    yield Call(UtilsSecureStorage.set, USER_PERSONAL_NAME, personalName);

                    if (isChangeMyPhone) {
                        yield Put(SelectorsActionChangeMyPhone.registerBiometric({ username, biometricType }));
                    } else {
                        yield Put(SelectorsAction.finishEnrollmentSoft({ props }));
                    }

                    const isTrusted = true;

                    yield Put({ type: TYPES_CONFIG.IS_TRUSTED, isTrusted });
                } else {
                    yield Put(Push("/"));

                    yield Put(
                        SelectorsAction.addSoftOTPWithBiometricError({
                            ...rest,
                        }),
                    );

                    Logger.error("El plugin de vasco fallo por el siguiente motivo: ", errorActivation);

                    yield Put(
                        SelectorsActionNotification.showNotification({
                            message: Get("addSoftOtp.error"),
                            level: LEVEL.ERROR,
                            scopes: [SCOPE.EXTERNAL_DASHBOARD],
                        }),
                    );

                    yield Call(SelectorsMiddleware.revokeSoftOtp, {
                        _exchangeToken: exchangeToken,
                        isChangeMyPhone,
                        username,
                    });
                }
            }
        }
    } else {
        yield Put(SelectorsAction.addSoftOTPWithBiometricError());

        yield Put(Push("/"));

        yield Put(
            SelectorsActionNotification.showNotification({
                message: Get("vascoPermisson.error"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.EXTERNAL_DASHBOARD],
            }),
        );
    }
}

function* finishEnrollmentSoft(propsFunction) {
    const { props } = propsFunction;
    const { exchangeToken } = props;

    const response = yield Call(SelectorsMiddleware.finishEnrollmentSoft, {
        _exchangeToken: exchangeToken,
    });

    if (response) {
        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(SelectorsAction.finishEnrollmentSoftError());

            yield Put(Push("/"));

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.EXTERNAL_DASHBOARD],
                }),
            );
        } else if (response.status === RESPONSE_TYPE.UNAUTHORIZED) {
            yield Put(Push("/"));

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.EXTERNAL_DASHBOARD],
                }),
            );

            yield Put(SelectorsAction.finishEnrollmentSoftError());
        } else {
            const { accessToken, finishState, ...rest } = response.data.data;
            yield Put(
                SelectorsAction.finishEnrollmentSoftSuccess({
                    finishState,
                    ...rest,
                }),
            );

            const resultFingerPrint = yield Call(UtilsSecureStorage.set, FINGERPRINT_AUTH_TOKEN, accessToken);

            if (resultFingerPrint) {
                sessionStorage.setItem("widget.biometric.hide", true);
            }

            yield Put(SelectorsActionConfig.isTrusted({ isTrusted: true }));

            yield Put(Push("/enrollment/finishEnrollment"));
        }
    }
}

function* finishEnrollmentHard(props) {
    Logger.info("_sagas: FINISH_ENROLLMENT_HARD_REQUEST");
    const { formikBag, idSecuritySeal, lastNames, names, otpSerialNumber, password, passwordConfirmation, username } =
        props;

    const { exchangeToken } = formikBag.props;

    const response = yield Call(SelectorsMiddleware.finishEnrollmentHard, {
        _exchangeToken: exchangeToken,
        _password: password,
        _passwordConfirmation: passwordConfirmation,
        idSecuritySeal,
        lastNames,
        names,
        otpSerialNumber,
        username,
    });

    if (formikBag) {
        formikBag.setSubmitting(false);
    }

    if (response) {
        const { code } = response.data;

        if (response.type === RESPONSE_TYPE.WARNING) {
            if (APIErrorCodes[code]) {
                const error = null || (APIErrorCodes[code] && `enrollment.finish.${APIErrorCodes[code]}`);

                yield Put({
                    type: TYPE.REGISTER_PERSONAL_DATA_REQUEST_ERROR,
                    error,
                });

                yield Put(Push("/"));

                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: response.data.message,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.EXTERNAL_DASHBOARD],
                    }),
                );
            } else {
                formikBag.setErrors(adjustIdFieldErrors(response.data.data, false));

                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: response.data.message,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.REGISTER_PERSONAL_DATA],
                    }),
                );
            }

            yield Put(SelectorsAction.finishEnrollmentHardError());
        } else if (response.status === RESPONSE_TYPE.UNAUTHORIZED) {
            yield Put(Push("/"));

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.EXTERNAL_DASHBOARD],
                }),
            );
        } else {
            const { _exchangeToken, finishState, ...rest } = response.data.data;

            yield Put(
                SelectorsAction.finishEnrollmentHardSuccess({
                    exchangeToken: _exchangeToken,
                    finishState,
                    ...rest,
                }),
            );

            yield Put(Push("/enrollment/finishEnrollment"));
        }
    }
}
