import React from "react";

import { ResponsiveLine } from "@nivo/line";
import ClassNames from "classnames";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SelectorsStore as SelectorsStoreWM, SelectorsAction as SelectorsActionWM } from "~/store/wm/listPapers";

import * as UtilDate from "~/util/date";
import { generateId as GenID } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";

import AnimWait from "~/components/AnimWait";

import Style from "./HistoricalRatesChart.scss";

export const { NAME, CLASS, COLOR } = Style;

export const PROP = {
    types: {
        chartValues: PropTypes.array,
        formData: PropTypes.array,
    },
    defaults: {},
};

export function Component(props) {
    const { dispatch, chartValues, idPaper, isFetching, chartPeriods } = props;
    const chartData = {};
    const chartPeriodsLength = Object.keys(chartPeriods).length;
    const selected = chartPeriodsLength === 5 ? 2 : chartPeriodsLength >= 2 ? chartPeriodsLength - 2 : 0;
    const [selectedPeriod, setSelectedPeriod] = React.useState(Object.keys(chartPeriods)[selected]);
    React.useEffect(() => {
        const months = chartPeriods[selectedPeriod];
        dispatch(SelectorsActionWM.chartValuesRequest({ months, idPaper }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, selectedPeriod, idPaper]);

    if (!isFetching && chartValues && chartValues.length > 0) {
        chartData.data = [];

        chartValues.forEach((val) => {
            const date = UtilDate.toDate(val.date);
            const mmYyyy = UtilDate.applyFormat(date, "yyyy-MM-dd");
            chartData.data.push({ x: mmYyyy, y: val.value.toFixed(2) });
        });
        chartData.data.reverse();
    }

    return (
        <section className={CLASS}>
            <div className="chart-header">
                <h3>{UtilsI18n.get("preferentialTradingPrice.historicalChart.title")}</h3>
                <PeriodSelector />
            </div>
            {isFetching && <AnimWait />}
            {!isFetching && (
                <ResponsiveLine
                    data={[chartData]}
                    margin={{ top: 20, bottom: 40, left: 50 }}
                    enableSlices="x"
                    xScale={{ type: "time", format: "%Y-%m-%d" }}
                    xFormat="time:%Y-%m-%d"
                    yScale={{ type: "linear", min: "auto", max: "auto", stacked: true, reverse: false }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        format: (e) =>
                            UtilDate.i18nDate(e, "MMM")
                                .split(" ")
                                .map((date) => date.charAt(0).toUpperCase() + date.slice(1))
                                .join(" "),
                        tickValues: 4,
                    }}
                    axisLeft={{
                        tickValues: 5,
                        tickSize: 0,
                    }}
                    colors={COLOR}
                    sliceTooltip={({ slice }) => {
                        const { data } = slice.points[0];
                        const day = UtilDate.applyFormat(data.x, "dd.MM.yyyy");
                        return (
                            <div className="chartTooltip">
                                <strong>{day}</strong>
                                <div>
                                    <span>{data.y}</span>
                                </div>
                            </div>
                        );
                    }}
                    enablePoints={false}
                    enableCrosshair
                />
            )}
        </section>
    );

    function PeriodSelector() {
        return (
            <div className="period-selector">
                {Object.keys(chartPeriods).map((period) => (
                    <span
                        key={GenID()}
                        className={ClassNames({ selected: period === selectedPeriod })}
                        onClick={() => {
                            setSelectedPeriod(period);
                        }}>
                        {UtilsI18n.get(`wm.funds.list.evolution.${period}`)}
                    </span>
                ))}
            </div>
        );
    }
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    chartValues: SelectorsStoreWM.getChartValues(store),
    isFetching: SelectorsStoreWM.getFetchingChartValues(store),
    formData: SelectorsStoreWM.getFormData(store),
    chartPeriods: SelectorsStoreWM.getChartPeriods(store),
});

export default Connect(mapStateToProps)(Component);
