import React from "react";

import { Field, Formik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { BANKTYPE, EMPTY_STR, REGEX_IGNORE_ACCENTS } from "~/constants";
import { SelectorsAction as SelectorsActionComex, SelectorsStore as SelectorsStoreComex } from "~/store/comex";
import * as I18nUtils from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import Container from "~/pages/_components/Container";
import TextField from "~/pages/_components/fields/TextField";
import Selector from "~/pages/_components/fields/formik/Selector";

import BankSearchList from "~/pages/forms/_components/_fields/_bankselector/bankSearch/List";

import Style from "./_BankSearch.rules.scss";

const FORM_ID = "forms.bankselector";

export const NAME = "BankSearchForm";

export const PROP = {
    types: {
        bank: PropTypes.string,
        bankCode: PropTypes.string,
        codes: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
            }),
        ),
        countries: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
            }),
        ),
        country: PropTypes.string,
        loadListRequest: PropTypes.func.isRequired,
        match: PropTypes.shape({
            url: PropTypes.string.isRequired,
        }).isRequired,
        ...TypesRedux,
    },
    defaults: {
        ...DefaultsRedux,
        bank: EMPTY_STR,
        bankCode: EMPTY_STR,
        codes: [],
        countries: [],
        country: EMPTY_STR,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        stateIsSubmitting: false,
    };

    validateSearch = (obj) => {
        const { bankCode, bank } = obj;
        const errors = {};

        if (bankCode && bankCode.trim().length < 3) {
            errors.bankCode = I18nUtils.get("forms.bankselector.codeToShort");
        }

        if (bank && bank.trim().length < 3) {
            errors.bank = I18nUtils.get("forms.bankselector.nameToShort");
        }
        return errors;
    };

    customFilter(option, searchText) {
        const optionLowerCase = option.label.toLowerCase();
        const text = searchText.toLowerCase();

        if(optionLowerCase.normalize("NFD").replace(REGEX_IGNORE_ACCENTS, "").includes(text) && optionLowerCase.charAt(0).includes(text.charAt(0))){
            return true;
        }
        else {
            return false
        }
    }

    handleSubmit = (values, formikbag) => {
        const { bank, bankCode, country } = values;
        const { dispatch, isSubmitting, scope } = this.props;
        const { setSubmitting, setErrors } = formikbag;

        const request = {
            filters: { name: bank.trim(), country, code: bankCode.trim(), type: BANKTYPE.SWIFT },
            scope,
            setErrors,
            setSubmitting,
        };
        dispatch(SelectorsActionComex.loadBankListRequest(request));

        if (isSubmitting) {
            this.setState({ stateIsSubmitting: true });
        }
    };

    renderForm = ({ handleSubmit }) => {
        const { stateIsSubmitting } = this.state;
        const { countries } = this.props;
        const noOptionText = I18nUtils.get("selector.noOption.text")

        return (
            <React.Fragment>
                <Container className="bank-search-container">
                    <div id={Style.ID}>
                        <div className="container--layout--input-search-bank">
                            <Field
                                autoComplete="off"
                                component={TextField}
                                idForm={FORM_ID}
                                name="bankCode"
                                label="forms.bankselector.bankCode"
                                maxLength={11}
                            />
                            <Field idForm={FORM_ID} name="bank" component={TextField} />
                            <Field
                                idForm={FORM_ID}
                                name="country"
                                component={Selector}
                                inputProps={{spellCheck: "false"}}
                                searchable
                                noOptionsText={noOptionText}
                                options={countries.slice(1, countries.length).map(({ id, label }) => ({
                                    value: id,
                                    label,
                                }))}
                                customFilter={this.customFilter}
                            />
                        </div>
                        <div className="container--layout-button-search">
                            <Button
                                bsStyle="primary"
                                label="forms.bankselector.search"
                                onClick={handleSubmit}
                                disable={stateIsSubmitting}
                            />
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    };

    handleSelect = (bank) => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionComex.setSelectedBank({ bank }));
    };

    renderList = () => {
        const { banks } = this.props;
        return <BankSearchList onSelect={this.handleSelect} items={banks} />;
    };

    render() {
        const { bank, country, banks } = this.props;
        return (
            <Formik
                initialValues={{
                    bankCode: EMPTY_STR,
                    bank,
                    country,
                }}
                validate={this.validateSearch}
                onSubmit={this.handleSubmit}>
                {banks.length > 0 ? this.renderList : this.renderForm}
            </Formik>
        );
    }
}

const mapStateToProps = (store) => ({
    banks: SelectorsStoreComex.getBanks(store),
});

export default Connect(mapStateToProps)(Component);
