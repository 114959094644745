import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { MODE } from "~/constants/form";
import { NAME as NAME_ADVANCED } from "~/store/administration/advanced";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "group";

export const PATH = {};

export const TYPE = FactoryType(NAME_ADVANCED, [
    "LOAD_GROUP_REQUEST",
    "LOAD_GROUP_FAILURE",
    "LOAD_GROUP_SUCCESS",
    "LOAD_GROUP_FORM_DATA_REQUEST",
    "LOAD_GROUP_FORM_DATA_FAILURE",
    "LOAD_GROUP_FORM_DATA_SUCCESS",
    "SUBMIT_GROUP_FORM_PREVIEW_REQUEST",
    "SUBMIT_GROUP_FORM_PREVIEW_SUCCESS",
    "SUBMIT_GROUP_FORM_REQUEST",
    "SUBMIT_GROUP_FORM_SUCCESS",
    "LOAD_GROUPS_REQUEST",
    "LOAD_GROUPS_FAILURE",
    "LOAD_GROUPS_SUCCESS",
    "UPDATE_GROUPS_OF_USER_PREVIEW",
    "UPDATE_GROUPS_OF_USER_PREVIEW_SUCCESS",
    "UPDATE_GROUPS_OF_USER_CONFIRM",
    "LOAD_GROUPS_OF_USER_TICKET_REQUEST",
    "LOAD_GROUPS_OF_USER_FAILURE",
    "LOAD_GROUPS_OF_USER_SUCCESS",
    "UPDATE_HAS_BACK",
    "UPDATE_HAS_CLOSE",
    "RESET",
    "LOAD_FORM_SUCCESS",
    "STEP_BACK_REQUEST",
]);

export const PROP = {};

PROP.members = {};

PROP.types = {
    name: PropTypes.string,
    description: PropTypes.string,
    adminUsers: PropTypes.array,
    groupStatus: PropTypes.string,
    members: PropTypes.array,
    mode: PropTypes.string,
    fetching: PropTypes.bool,
    adminGroup: PropTypes.bool,

    // groupFormData
    availableUsers: PropTypes.array,
    formValues: PropTypes.object,
    credentialGroups: PropTypes.array,
    idTransaction: PropTypes.any,
    idActivity: PropTypes.any,

    // groups
    availableGroups: PropTypes.array,
    selectedGroups: PropTypes.array,
    groupsToSave: PropTypes.array,

    // ticket
    groups: PropTypes.array,
    user: PropTypes.object,

    // navigation

    hasBack: PropTypes.bool,
    hasClose: PropTypes.bool,

    ...TypesRedux,
};

PROP.defaults = {
    name: EMPTY_STR,
    description: EMPTY_STR,
    adminUsers: [],
    groupStatus: EMPTY_STR,
    members: [],
    mode: MODE.EDIT,
    fetching: true,
    adminGroup: false,

    // groupFormData
    availableUsers: [],
    formValues: {
        name: EMPTY_STR,
        description: EMPTY_STR,
        status: "active",
        permissions: {},
        users: [],
    },
    credentialGroups: [],
    idTransaction: null,
    idActivity: null,

    // groups
    availableGroups: [],
    selectedGroups: [],
    groupsToSave: [],

    // ticket
    groups: [],
    user: {},

    // navigation

    hasBack: false,
    hasClose: false,

    ...DefaultsRedux,
};
