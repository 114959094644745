import React from "react";

import PropTypes from "prop-types";
import queryString from "query-string";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import Container from "~/containers/External/Payment";
import {
    SelectorsAction as SelectorsActionBackoffice,
    SelectorsStore as SelectorsStoreBackoffice,
} from "~/store/backoffice";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import DynamicFormTicket from "~/pages/forms/Step3View/Step3View";

import * as TicketIndex from "./_components/_ticketIndex";
import TicketMap from "./_components/_ticketMap";

export const NAME = "BackofficeTransaction";

export const PROP = {
    types: {
        ...TypesRedux,
        fetching: PropTypes.bool,
        idForm: PropTypes.string,
        location: PropTypes.object,
        transaction: PropTypes.object,
    },
    defaults: {
        ...DefaultsRedux,
        fetching: false,
        idForm: null,
        location: null,
        transaction: null,
    },
};

export function Component(props) {
    const {
        dispatch,
        fetching,
        idForm,
        location: { search },
        transaction,
    } = props;

    const {
        query: { _exchangeToken },
    } = queryString.parseUrl(search);

    React.useEffect(() => {
        if (_exchangeToken) {
            dispatch(SelectorsActionBackoffice.backofficeTransactionRequest({ exchangeToken: _exchangeToken }));
        }
    }, [_exchangeToken, dispatch]);

    React.useEffect(() => {
        if (transaction) {
            const { idActivity } = transaction;

            dispatch(
                SelectorsActionBackoffice.readEnvironmentDataRequest({ idEnvironment: transaction.idEnvironment }),
            );

            if (idActivity.includes("administration")) {
                dispatch(
                    SelectorsActionBackoffice.readUserDataRequest({
                        _idUserCreator: transaction.idUserCreator,
                        _idEnvirnoment: transaction.idEnvironment,
                    }),
                );
            }
        }
    }, [dispatch, transaction]);

    return (
        <Container name={NAME} scopeToShowNotification={SCOPE.BACKOFFICE_TRANSACTION} wait={fetching}>
            {transaction && renderTicket()}
            {!transaction && "NO transaction"}
        </Container>
    );

    function renderTicket() {
        const { idActivity } = transaction;
        const isFromBackoffice = true;

        if (idForm) {
            return <DynamicFormTicket isFromBackoffice />;
        }

        return React.createElement(TicketIndex[TicketMap[idActivity]], {
            isFromBackoffice,
            isMedium: idActivity.includes("administration.medium"),
        });
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    fetching: SelectorsStoreBackoffice.getFetching(store),
    idForm: SelectorsStoreBackoffice.getIdForm(store),
    transaction: SelectorsStoreBackoffice.getTransaction(store),
});

export default Connect(mapStateToProps)(Component);
