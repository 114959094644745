import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";

import { EMPTY_STR, ONLY_NUMBER, SPACE_STR, LEVEL, SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { SelectorsAction as SelectorsActionComex, SelectorsStore as SelectorsStoreComex } from "~/store/comex";
import {
    SelectorsAction as SelectorsActionComexImport,
    PROP as PropComex,
    SelectorsStore as SelectorsStoreComexImport,
} from "~/store/comex/import";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsAction as SelectorActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionTemplate } from "~/store/template";
import * as ConfigUtil from "~/util/config";
import { formatDate as FormatDate, toDate as ToDate } from "~/util/date";
import { isEmptyObj } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Select from "~/components/Select";
import DateField from "~/pages/_components/fields/DateField";
import EmailList from "~/pages/_components/fields/EmailList";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TextArea from "~/pages/_components/fields/TextArea";
import TextField from "~/pages/_components/fields/TextField";
import AmountField from "~/pages/_components/fields/formik/AmountField";

import FileUploader from "~/pages/comEx/_components/FileUploader";
import OperationNumber from "~/pages/comEx/_components/OperationNumber";
import OperationNumberDetail from "~/pages/comEx/_components/OperationNumberDetail";
import CreateTemplateModal from "~/pages/forms/Step1Edit/_ModalTemplateCreate";
import ListTemplatesModal from "~/pages/forms/Step1Edit/_ModalTemplateList";
import { Termsandconditions } from "~/pages/forms/_components/_fields";
import Scheduler from "~/pages/forms/_components/_fields/Scheduler";

import Style from "../../../Step1Edit.rules.scss";

const FORM_ID = "comex.import.modifyCreditLetter";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...PropComex.types,
    },
    defaults: {
        ...PropComex.defaults,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        draftLoaded: false,
        loadOperationNumber: false,
    };

    componentDidMount() {
        const { dispatch, formData } = this.props;
        if (isEmptyObj(formData)) {
            dispatch(SelectorsActionComexImport.modifyCreditLetterPreRequest());
        }
    }

    componentDidUpdate() {
        const {
            dispatch,
            formData,
            setFieldError,
            setFieldValue,
            setFieldTouched,
            setValues,
            transaction,
            values,
            match,
            preData,
        } = this.props;
        const { operationNumber } = match.params;
        const { availableOperationList } = preData;
        const { draftLoaded, loadOperationNumber } = this.state;

        if (!loadOperationNumber && availableOperationList.length > 0 && !values.operationNumber && operationNumber) {
            const operationSelected = availableOperationList.filter(
                (item) => item.othersFields.NROOPERACION === operationNumber,
            );
            this.setState({
                loadOperationNumber: true,
            });

            if (operationSelected.length > 0) {
                setFieldValue("item", operationSelected[0]);
                setFieldValue("operationNumber", operationNumber);
            }
        }
        if (transaction?.data && Object.keys(formData).length === 0 && preData.nextValidDate && !draftLoaded) {
            this.setState({
                draftLoaded: true,
            });
            const transactionData = transaction?.data;
            /* carga de errores */
            if (transactionData.backendFormErrors?.cdpErrorFields) {
                const { cdpErrorFields } = transactionData.backendFormErrors;
                cdpErrorFields.map((errorField) => {
                    if (errorField === "valueDate") {
                        setFieldError("scheduler", SPACE_STR);
                        setFieldTouched("scheduler", true);
                    } else {
                        setFieldError(errorField, SPACE_STR);
                        setFieldTouched(errorField, true);
                    }

                    return null;
                });
            }
            if (transactionData.backendFormErrors?.message) {
                const errorMessage = transactionData?.backendFormErrors?.message;
                dispatch(
                    SelectorActionNotification.showNotification({
                        message: errorMessage,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.COMEX_IMPORT_CREDIT_LETTER_MODIFY],
                    }),
                );
            } // modificaciones para que agarren bien los fields
            if (!transactionData.scheduler) {
                transactionData.scheduler = {
                    editing: true,
                    valueDate: transactionData?.valueDate,
                    selectedOption: transactionData?.selectedOption,
                };
            }

            if (transactionData.valueDate) {
                const valueDate = ToDate(transactionData.valueDate);
                transactionData.valueDate = valueDate;
            }
            if (transactionData.dueDate) {
                const dueDate = ToDate(transactionData.dueDate);
                transactionData.dueDate = dueDate;
            }
            if (transactionData.boardingDate) {
                const boardingDate = ToDate(transactionData.boardingDate);
                transactionData.boardingDate = boardingDate;
            }

            let dataTransaction;
            if (transactionData) {
                dataTransaction = {
                    ...transactionData,
                    invoiceAmount: {
                        currency: transactionData?.invoiceAmountCurrency,
                        amount: transactionData?.invoiceAmount,
                    },
                };
            }

            setValues(dataTransaction || transactionData);
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(GoBack());
    };

    buildOptions = () => {
        return [
            { id: EMPTY_STR, label: EMPTY_STR },
            {
                id: "INCREMENTO",
                label: UtilsI18n.get(`${FORM_ID}.incremento`),
            },
            {
                id: "DISMINUCION",
                label: UtilsI18n.get(`${FORM_ID}.disminucion`),
            },
        ];
    };

    handleFormKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            event.preventDefault();
        }
    };

    handleSelectChange = (id) => {
        const { setFieldValue } = this.props;
        setFieldValue("increment", id);
    };

    handleItemSelect = (item) => {
        const { setFieldValue, setFieldError } = this.props;

        const { othersFields } = item;
        if (othersFields) {
            setFieldValue("operationNumber", othersFields.NROOPERACION);
            setFieldValue("item", item);
            setFieldError("operationNumber", null);
        } else {
            setFieldValue("item", null);
            setFieldValue("operationNumber", EMPTY_STR);
        }
    };

    handleModalLoad = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionComex.toggleCreditLetterList());
    };

    handleBlur = () => {
        const { values, preData, setFieldValue } = this.props;
        const { availableOperationList } = preData;
        if (values.operationNumber) {
            const operationSelected = availableOperationList.filter(
                (item) => item.othersFields.NROOPERACION === values.operationNumber,
            );
            if (operationSelected.length > 0) {
                setFieldValue("item", operationSelected[0]);
            } else {
                setFieldValue("item", null);
                setFieldValue("operationNumber", EMPTY_STR);
            }
        } else {
            setFieldValue("item", null);
            setFieldValue("operationNumber", EMPTY_STR);
        }
    };

    // TEMPLATE AND DRAFT
    handleCancel = () => {
        const { dispatch, setSubmitting, transaction } = this.props;

        dispatch(
            SelectorsActionForm.cancelTransactionCustom({
                credentials: {},
                idTransaction: transaction.idTransaction,
                formikBag: { setSubmitting },
            }),
        );
    };

    handleDraft = () => {
        const { dispatch, values, transaction } = this.props;

        dispatch(
            SelectorsActionForm.saveDraft({
                idActivityDraft: "comex.import.modifyCreditLetter.send",
                idTransactionToSave: transaction?.idTransaction,
                transactionData: values || {},
            }),
        );
    };

    handleTemplateSave = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionTemplate.createTemplate());
    };

    handleTemplateLoad = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionTemplate.toggleTemplateList());
    };

    handleSelectTemplate = (template) => {
        const { setValues, setErrors } = this.props;
        const templateToBeLoaded = template;
        if (templateToBeLoaded.scheduler.valueDate) {
            const valueDate = ToDate(templateToBeLoaded.scheduler.valueDate);
            templateToBeLoaded.scheduler.valueDate = valueDate;
        }

        if (templateToBeLoaded.dueDate) {
            const dueDate = ToDate(templateToBeLoaded.dueDate);
            templateToBeLoaded.dueDate = dueDate;
        }

        if (templateToBeLoaded.boardingDate) {
            const boardingDate = ToDate(templateToBeLoaded.boardingDate);
            templateToBeLoaded.boardingDate = boardingDate;
        }

        setValues(templateToBeLoaded);
        setErrors({});
    };

    render() {
        const { values, emailValidationRegex, errors, preData, setFieldValue, transaction } = this.props;
        const options = this.buildOptions();
        const { nextValidDate, unavailableDays, availableOperationList } = preData;
        const termsAndConditions = "comex.import.modifyCreditLetter.disclaimer";
        const i18nMap = {
            termsAndConditions,
        };
        const cancelEnabled = transaction && transaction.idTransactionStatus === STATUS.DRAFT;
        const othersFields = values.item ? values.item.othersFields : null;

        const currency = othersFields ? othersFields.MONEDA : EMPTY_STR;
        const data = {
            options: [
                {
                    id: currency,
                    label: UtilsI18n.get(`currency.label.${currency}`),
                },
            ],
        };
        const valueDate = values.scheduler?.valueDate ? values.scheduler.valueDate : new Date();
        return (
            <React.Fragment>
                <Form
                    className="col col-12 col-lg-6 col-md-9 col-sm-12"
                    id={Style.ID}
                    noValidate="novalidate"
                    onKeyDown={this.handleFormKeyDown}>
                    <div className="form-section loadTemplates">
                        <Button
                            onClick={this.handleTemplateLoad}
                            label="forms.templates.load"
                            bsStyle="default"
                            image="template.svg"
                        />
                    </div>
                    <section className="fields container--layout align-items-center flex-grow">
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.operation`)}</h3>
                        </Box>

                        <Box flex align="end" className="form-group search-field">
                            <Field
                                idForm={FORM_ID}
                                name="operationNumber"
                                hidePlaceholder
                                pattern={ONLY_NUMBER}
                                type="text"
                                component={TextField}
                                onBlur={this.handleBlur}
                                tooltip={UtilsI18n.get("comex.import.modifyCreditLetter.operationNumber.tooltip")}
                            />

                            <Box className="ml-3 mb-2">
                                <Button
                                    onClick={this.handleModalLoad}
                                    label="global.search"
                                    bsStyle="primary"
                                    className="search"
                                />
                            </Box>
                        </Box>
                        {values.item && <OperationNumberDetail othersFields={othersFields} idForm={FORM_ID} />}
                        <hr />
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.modifyData`)}</h3>
                        </Box>

                        <Box className="form-group ">
                            <I18n id="comex.import.modifyCreditLetter.note" />
                        </Box>

                        <Box className="form-group">
                            <Field
                                idForm={FORM_ID}
                                name="dueDate"
                                id="dueDate"
                                component={DateField}
                                minDate={valueDate}
                                maxDate={null}
                                hidePlaceholder
                                autoComplete="off"
                            />
                        </Box>

                        <Box className="form-group">
                            <Field
                                idForm={FORM_ID}
                                name="boardingDate"
                                id="boardingDate"
                                component={DateField}
                                minDate={valueDate}
                                maxDate={values.dueDate}
                                hidePlaceholder
                                autoComplete="off"
                            />
                        </Box>

                        <Box className="form-group">
                            <FieldLabel labelKey={`${FORM_ID}.incrementoDisminucion.label`} />

                            <Box className="input-group">
                                <Field
                                    component={Select}
                                    placeholder={EMPTY_STR}
                                    value={values.increment}
                                    clearable={false}
                                    searchable={false}
                                    onChange={(item) => this.handleSelectChange(item.id)}
                                    valueKey="id"
                                    labelKey="label"
                                    options={options}
                                    className="flex-container slideFromBottom"
                                    optionClassName="needsclick"
                                    noResultsText={EMPTY_STR}
                                    idForm={FORM_ID}
                                />
                            </Box>
                        </Box>

                        {values.increment !== EMPTY_STR && (
                            <React.Fragment>
                                <Field
                                    clearable={false}
                                    component={AmountField}
                                    data={data}
                                    idForm={FORM_ID}
                                    name="invoiceAmount"
                                    placeholder={EMPTY_STR}
                                    searchable={false}
                                    decimalPlaces={2}
                                    fixedDecimalScale
                                    hideCurrency={!currency}
                                    maxLength={ConfigUtil.getInteger("amount.length")}
                                />
                            </React.Fragment>
                        )}

                        <Field
                            component={TextArea}
                            idForm={FORM_ID}
                            name="otherModifications"
                            mode={MODE.EDIT}
                            className="form-control"
                            maxLength={1500}
                            hidePlaceholder
                        />
                        <Box className="form-group">
                            <Field
                                name="files"
                                idForm={FORM_ID}
                                emptyMessageKey={`${FORM_ID}.attachments.emptyMessage`}
                                maxFiles={5}
                                errors={errors}
                                component={FileUploader}
                                values={values}
                                setFieldValue={setFieldValue}
                            />
                        </Box>

                        <Field
                            component={TextArea}
                            idForm={FORM_ID}
                            name="observations"
                            mode={MODE.EDIT}
                            className="form-control"
                            maxLength={500}
                            hidePlaceholder
                        />

                        <Field
                            component={EmailList}
                            idForm={FORM_ID}
                            name="notificationEmails"
                            hideSwiftTip
                            className="form-group email-field"
                            renderSuggestion={false}
                            data={{ emailValidationRegex, addMessage: EMPTY_STR }}
                            value={values.notificationEmails}
                            tooltip={UtilsI18n.get(`${FORM_ID}.notificationEmails.tooltip`)}
                        />

                        <hr />
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">
                                {UtilsI18n.get(`${FORM_ID}.section.showPreview`)}
                            </h3>
                        </Box>

                        <Field
                            component={Termsandconditions}
                            idForm={FORM_ID}
                            name="disclaimer"
                            mode={MODE.EDIT}
                            className="form-control"
                            i18nMap={i18nMap}
                        />
                        {nextValidDate && (
                            <Field
                                component={Scheduler}
                                data={{
                                    firstWorkingDate: nextValidDate,
                                    maxDaysToSchedule: ConfigUtil.get("client.comex.valueDate.maxDaysToSchedule"),
                                    nonWorkingDays: unavailableDays,
                                    lang: "es",
                                    mode: "edit",
                                    programable: false,
                                    schedulable: true,
                                }}
                                name="scheduler"
                                idForm={FORM_ID}
                            />
                        )}
                    </section>
                    <footer>
                        <Button onClick={this.handleDraft} label="forms.saveDraft.link" />
                        <Button
                            onClick={this.handleTemplateSave}
                            className="templateSave"
                            label="forms.saveTemplate.link"
                        />
                        {cancelEnabled && <Button onClick={this.handleCancel} label="forms.cancelTransaction.link" />}
                        <Button
                            key="primaryButton"
                            variant="primary"
                            bsStyle="primary"
                            label="global.send"
                            loading={false}
                            type="submit"
                        />
                    </footer>
                </Form>
                <ListTemplatesModal
                    idActivityTemplate="comex.import.modifyCreditLetter.send"
                    onSelect={this.handleSelectTemplate}
                />
                <CreateTemplateModal
                    values={values}
                    idActivityTemplate="comex.import.modifyCreditLetter.send"
                    backdrop="static"
                />
                <OperationNumber
                    list={availableOperationList}
                    idForm={FORM_ID}
                    handleItemClick={this.handleItemSelect}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        preData: SelectorsStoreComexImport.getModifyCreditLetterOpenPre(store),
        formData: SelectorsStoreComexImport.getModifyCreditLetterOpenFormData(store),
        transaction: SelectorsStoreComex.getTransaction(store),
        // emailValidationRegex: SelectorsStoreConfig.getConfig(store)["email.validationFormat"],
    };
};

export default HighOrder(
    WithRouter,
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,

        mapPropsToValues: ({ formData }) => {
            const attachments = formData?.attachments;
            const hasAttachments = attachments && attachments !== EMPTY_STR;
            return {
                scheduler: formData?.valueDate
                    ? {
                          editing: true,
                          valueDate: formData?.valueDate,
                          selectedOption: formData?.selectedOption,
                      }
                    : EMPTY_STR,
                operationNumber: formData?.operationNumber || EMPTY_STR,
                dueDate: formData.dueDate ? ToDate(formData.dueDate) : EMPTY_STR,
                boardingDate: formData.boardingDate ? ToDate(formData.boardingDate) : EMPTY_STR,
                increment: formData?.increment || EMPTY_STR,
                invoiceAmount: { amount: formData?.invoiceAmount, currency: formData?.invoiceAmountCurrency },
                otherModifications: formData?.otherModifications || EMPTY_STR,
                observations: formData?.observations || EMPTY_STR,
                notificationEmails: formData?.notificationEmails,
                item: formData?.item || null,
                files: hasAttachments ? attachments?.split(",") : [],
                attachmentsNames: formData?.attachmentsNames || [],
            };
        },
        handleSubmit: (props, formikBag) => {
            const { dispatch, preData, transaction } = formikBag.props;
            const { hasContract } = preData;
            const { item, files, invoiceAmount, scheduler, dueDate, boardingDate, ...rest } = props;
            const originalDueDate = item?.othersFields ? item.othersFields.FECHAVTO : EMPTY_STR;
            const invAmount = invoiceAmount?.amount;
            const hasInvoiceAmount = invAmount && invAmount !== EMPTY_STR;
            const oldAmount = item?.othersFields ? item.othersFields.IMPORTE : EMPTY_STR;
            const beneficiary =
                item?.othersFields?.BENEFICIARIO === EMPTY_STR || item?.othersFields?.BENEFICIARIO === SPACE_STR
                    ? "N/A"
                    : item?.othersFields?.BENEFICIARIO;
            const attachmentsFiles = files ? files.join(",") : EMPTY_STR;
            const amount = hasInvoiceAmount ? invAmount : null;
            const valueDateString = scheduler ? FormatDate(scheduler.valueDate) : EMPTY_STR;
            const dueDateString = dueDate ? FormatDate(new Date(dueDate)) : EMPTY_STR;
            const boardingDateString = boardingDate ? FormatDate(new Date(boardingDate)) : EMPTY_STR;
            const invoiceAmountCurrency = item?.othersFields ? item.othersFields.MONEDA : EMPTY_STR;
            const operationNumberString = rest.operationNumber ? rest.operationNumber : EMPTY_STR;
            const formData = {
                ...rest,
                operationNumber: operationNumberString,
                valueDate: valueDateString,
                dueDate: dueDateString,
                boardingDate: boardingDateString,
                originalDueDate,
                invoiceAmount: amount,
                oldAmount,
                beneficiary,
                attachments: attachmentsFiles,
                invoiceAmountCurrency,
                hasContract,
                selectedOption: scheduler?.selectedOption,
                item,
                idTransaction: transaction?.idTransaction,
                scheduler,
            };

            dispatch(
                SelectorsActionComexImport.modifyCreditLetterPreviewRequest({
                    formData,
                    formikBag,
                }),
            );
        },
    }),
)(Component);
