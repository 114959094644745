import React from "react";

import { push as Push } from "connected-react-router";
import { Field, Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { Row } from "react-bootstrap";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import {
    CREDENTIAL_TYPE_PASSWORD,
    EMPTY_STR,
    HARD_OTP,
    LEVEL,
    SCOPE,
    MSG_RETURN_CODE_PREFIX,
    EVENT_BACKBUTTON,
} from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/External/Form";
import { SelectorsAction, SelectorsStore } from "~/store/enrollment";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as UtilsConfig from "~/util/config";
import UtilsDevice from "~/util/device";
import { generateId as GenerateId } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesHtmlElement, Defaults as DefaultsHtmlElement } from "~/util/prop/html-element";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import I18n from "~/components/I18n";
import PasswordRules from "~/pages/_components/PasswordRules";
import SecuritySealField from "~/pages/_components/fields/SecuritySealField";
import TextField from "~/pages/_components/fields/TextField";
import Credential from "~/pages/_components/fields/credentials/Credential";
import WithExchangeToken from "~/pages/_components/withExchangeToken";

import SecuritySealConfirmation from "~/pages/enrollment/SecuritySealConfirmation";
import SecuritySealSelector from "~/pages/enrollment/SecuritySealSelector";
import CancelConfirmationModal from "~/pages/enrollment/_CancelConfirmationModal";

import Style from "./RegisterPersonalData.rules.scss";

const FORM_ID = "enrollment.registerPersonalData";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...TypesRedux,
        ...TypesHtmlElement,
        exchangeToken: PropTypes.string.isRequired,
        finishState: PropTypes.string,
        updateSecuritySeal: PropTypes.bool,
        accountList: PropTypes.arrayOf(PropTypes.string).isRequired,
        client: PropTypes.shape({
            names: PropTypes.string,
            lastNames: PropTypes.string,
            documentCountry: PropTypes.string.isRequired,
            documentType: PropTypes.string.isRequired,
            documentNumber: PropTypes.string.isRequired,
            idUser: PropTypes.string,
            namesLastNamesEditable: PropTypes.bool.isRequired,
            idUserEditable: PropTypes.bool.isRequired,
            otpType: PropTypes.string,
        }),
        securitySeal: PropTypes.shape({
            id: PropTypes.string,
            image: PropTypes.string,
        }),
        isSubmitting: PropTypes.bool,
        fetching: PropTypes.bool,
    },
    defaults: {
        ...DefaultsRedux,
        ...DefaultsHtmlElement,
        isSubmitting: false,
        fetching: false,
        finishState: null,
        updateSecuritySeal: false,
        client: PropTypes.shape({
            names: "",
            lastNames: "",
            documentCountry: "",
            documentType: "",
            documentNumber: "",
            idUser: "",
            namesLastNamesEditable: true,
            idUserEditable: true,
            otpType: "",
        }),
        securitySeal: PropTypes.shape({
            id: "",
            image: "",
        }),
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {
        show: false,
    };

    componentDidMount() {
        const { dispatch, exchangeToken, updateSecuritySeal, finishState } = this.props;

        if (exchangeToken) {
            if (finishState) {
                // TODO: ver xq hay que hacer esto
                return;
            }
            if (!updateSecuritySeal) {
                dispatch(SelectorsAction.registerPersonalDataPre({ exchangeToken }));
            }

            if (UtilsDevice.isMobileNative()) {
                document.addEventListener(EVENT_BACKBUTTON, this.showModal);
            }
        } else {
            // TODO: Revisar
            dispatch(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get(`${MSG_RETURN_CODE_PREFIX}.API006E`),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.INVITATION_CODE],
                }),
            );
            dispatch(Push("/invitationCode"));
        }
    }

    componentWillUnmount() {
        if (UtilsDevice.isMobileNative()) {
            document.removeEventListener(EVENT_BACKBUTTON, this.showModal);
        }
    }

    handleConfirmation = () => {
        const { dispatch } = this.props;
        dispatch(Push("/"));
    };

    handleCancelation = () => {
        this.setState({ show: false });
    };

    showModal = () => {
        const { showSealModal, dispatch } = this.props;
        if (showSealModal) {
            dispatch(SelectorsAction.hideSecuritySealModal());
        } else {
            this.setState({ show: true });
        }
    };

    handleShowSealSelector = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsAction.showSecuritySealModal());
    };

    render() {
        const { accountList, client, securitySeal, isSubmitting, fetching } = this.props;
        const { show } = this.state;

        const clientDocument = client
            ? `${client.documentType} (${client.documentCountry}) Nº ${client.documentNumber}`
            : EMPTY_STR;
        return (
            <React.Fragment>
                <SecuritySealSelector />
                <SecuritySealConfirmation />
                <CancelConfirmationModal
                    show={show}
                    handleCancelation={this.handleCancelation}
                    handleConfirmation={this.handleConfirmation}
                />
                <Container
                    className={NAME}
                    name={NAME}
                    head-title={`${FORM_ID}.title`}
                    head-onClose={this.showModal}
                    wait={fetching}
                    scopeToShowNotification={SCOPE.REGISTER_PERSONAL_DATA}>
                    <div className="enrollment page-questions">
                        <div className="form-container-modal">
                            {client && (
                                <Form className="above-the-fold">
                                    <div className="form-group">
                                        <div className="form-group-text ">
                                            <span className="data-label">
                                                <I18n id={`${FORM_ID}.accounts.label`} />
                                            </span>
                                        </div>
                                        <div>
                                            {accountList && (
                                                <ul>
                                                    {accountList.map((account) => (
                                                        <li key={GenerateId()}>{account}</li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                    <Field
                                        idForm={FORM_ID}
                                        name="names"
                                        component={TextField}
                                        autoFocus={client.namesLastNamesEditable}
                                        mode={MODE.EDIT}
                                        hidePlaceholder
                                        readOnly={!client.namesLastNamesEditable}
                                    />
                                    <Field
                                        idForm={FORM_ID}
                                        name="lastNames"
                                        component={TextField}
                                        autoFocus={false}
                                        mode={MODE.EDIT}
                                        hidePlaceholder
                                        readOnly={!client.namesLastNamesEditable}
                                    />
                                    <Field
                                        idForm={FORM_ID}
                                        name="document"
                                        type="text"
                                        defaultValue={clientDocument}
                                        component={TextField}
                                        hidePlaceholder
                                        readOnly
                                    />
                                    <Field
                                        idForm={FORM_ID}
                                        name="username"
                                        component={TextField}
                                        autoFocus={false}
                                        mode={MODE.EDIT}
                                        hidePlaceholder
                                        autoComplete="off"
                                        readOnly={!client.idUserEditable}
                                    />
                                    <Field
                                        idForm={FORM_ID}
                                        name="password"
                                        component={Credential}
                                        type={CREDENTIAL_TYPE_PASSWORD}
                                        autoFocus={false}
                                        autoComplete="off"
                                        hidePlaceholder
                                        showStrength
                                    />
                                    <Field
                                        idForm={FORM_ID}
                                        name="passwordConfirmation"
                                        component={Credential}
                                        type={CREDENTIAL_TYPE_PASSWORD}
                                        autoFocus={false} // TODO: revisar
                                        autoComplete="off"
                                        mode={MODE.EDIT}
                                        hidePlaceholder
                                    />
                                    <PasswordRules>
                                        <I18n
                                            id="global.passwordRules.rule1"
                                            MIN_LENGTH={UtilsConfig.get("core.password.minLength")}
                                        />
                                        <I18n id="global.passwordRules.rule2" />
                                        <I18n id="global.passwordRules.rule3" />
                                    </PasswordRules>
                                    {client.otpType === HARD_OTP && (
                                        <Field
                                            idForm={FORM_ID}
                                            name="otpSerialNumber"
                                            component={TextField}
                                            autoFocus={false} // TODO: revisar
                                            hidePlaceholder
                                        />
                                    )}
                                    <Row>
                                        <Field
                                            idForm={FORM_ID}
                                            name="securitySeal"
                                            component={SecuritySealField}
                                            imageSrc={securitySeal.image}
                                            onClick={this.handleShowSealSelector}
                                        />
                                        <Button
                                            className="btn btn-primary btn-block"
                                            label="global.continue"
                                            loading={isSubmitting}
                                            type="submit"
                                        />
                                    </Row>
                                </Form>
                            )}
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

export default Compose(
    Connect((store) => ({
        exchangeToken: SelectorsStore.exchangeToken(store),
        client: SelectorsStore.client(store),
        accountList: SelectorsStore.accountList(store),
        securitySeal: SelectorsStore.securitySeal(store),
        updateSecuritySeal: SelectorsStore.updateSecuritySeal(store),
        fetching: SelectorsStore.fetching(store),
        finishState: SelectorsStore.finishState(store),
        showSealModal: SelectorsStore.showSealModal(store),
    })),
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: ({ client }) => {
            const fields = [];
            let obj = {};

            if (client) {
                fields.push({ id: "names", val: client.names });
                fields.push({ id: "lastNames", val: client.lastNames });
                fields.push({ id: "username", val: client.idUser ? client.idUser : EMPTY_STR });
                fields.push({ id: "password", val: EMPTY_STR });
                fields.push({ id: "passwordConfirmation", val: EMPTY_STR });

                if (client.otpType === HARD_OTP) {
                    fields.push({ id: "otpSerialNumber", val: EMPTY_STR });
                }

                obj = fields.reduce((acc, field) => ({ ...acc, [field.id]: field.val }), {});
            }

            return obj;
        },
        handleSubmit: (data, formikBag) => {
            const { dispatch, client, securitySeal } = formikBag.props;

            const {
                names = EMPTY_STR,
                lastNames = EMPTY_STR,
                username = EMPTY_STR,
                password,
                passwordConfirmation,
                otpSerialNumber,
            } = data;

            const params = {
                names,
                lastNames,
                username,
                password,
                passwordConfirmation,
                otpSerialNumber,
                idSecuritySeal: securitySeal.id,
                formikBag,
            };

            dispatch(
                client.otpType === HARD_OTP
                    ? SelectorsAction.finishEnrollmentHard(params)
                    : SelectorsAction.registerPersonalData(params),
            );
        },
        validationSchema: ({ client }) =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    names: client.namesLastNamesEditable
                        ? Yup.string()
                              .trim()
                              .required(UtilsI18n.get(`${FORM_ID}.names.required`))
                              .max(
                                  UtilsConfig.get(`${FORM_ID}.names.maxLength`),
                                  UtilsI18n.get(`${FORM_ID}.names.maxLengthExceeded`),
                              )
                        : Yup.string().notRequired(),
                    lastNames: client.namesLastNamesEditable
                        ? Yup.string()
                              .trim()
                              .required(UtilsI18n.get(`${FORM_ID}.lastNames.required`))
                              .max(
                                  UtilsConfig.get(`${FORM_ID}.lastNames.maxLength`),
                                  UtilsI18n.get(`${FORM_ID}.lastNames.maxLengthExceeded`),
                              )
                        : Yup.string().notRequired(),
                    username: client.idUserEditable
                        ? Yup.string()
                              .trim()
                              .required(UtilsI18n.get(`${FORM_ID}.username.required`))
                              .min(
                                  UtilsConfig.get(`${FORM_ID}.username.minLength`),
                                  UtilsI18n.get(`${FORM_ID}.username.minLengthTooShort`),
                              )
                        : Yup.string().notRequired(),
                    password: Yup.string()
                        .trim()
                        .required(UtilsI18n.get("global.password.required"))
                        .min(
                            UtilsConfig.get("core.password.minLength"),
                            UtilsI18n.get("global.password.unfulfilledRules"),
                        ),
                    passwordConfirmation: Yup.string()
                        .trim()
                        .required(UtilsI18n.get("global.passwordConfirmation.required"))
                        .oneOf([values.password], UtilsI18n.get("global.passwordConfirmation.notEquals")),
                    otpSerialNumber:
                        client.otpType === HARD_OTP
                            ? Yup.string()
                                  .trim()
                                  .required(UtilsI18n.get(`${FORM_ID}.otpSerialNumber.required`))
                            : Yup.string().notRequired(),
                }),
            ),
    }),
)(WithExchangeToken(Component));
