import React from "react";

import { goBack as GoBack } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import {
    SelectorsStore as SelectorsStoreWMShares,
    SelectorsAction as SelectorsActionWMShares,
} from "~/store/wm/shares";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import HighOrder from "~/components/HighOrder";

import FormPreview from "./Step2Preview/Step2Preview";
import FormView from "./Step3View/Step3View";

export const NAME = "BuySharesTicket";

export const PROP = {
    types: {
        fetching: PropTypes.bool,
        mode: PropTypes.string.isRequired,
        ...TypesRedux,
    },

    defaults: {
        fetching: false,
        ...DefaultsRedux,
    },
};

export function Component(props) {
    const { dispatch, match, transaction, fetching, mode } = props;
    const { id } = match.params;

    React.useEffect(() => {
        dispatch(SelectorsActionWMShares.readTransactionRequest({ idTransaction: id }));
    }, [dispatch, id]);

    const hasData = Object.entries(transaction.data).length > 0;
    const wait = fetching || !hasData;

    return (
        <Container
            name={NAME}
            wait={wait}
            step={mode}
            head-onBack={handleBack}
            head-onBackWeb={handleBack}
            head-title={UtilsI18n.get("client.wm.shares.buy.send.title")}
            scopeToShowNotification={SCOPE.BUY_SHARES}>
            {mode === MODE.PREVIEW && <FormPreview />}
            {hasData && (mode === MODE.EDIT || mode === MODE.VIEW) && <FormView transaction={transaction} />}
        </Container>
    );

    function handleBack() {
        dispatch(GoBack());
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        ...PROP.defaults,
        fetching: SelectorsStoreWMShares.getIsFetching(store),
        transaction: SelectorsStoreWMShares.getTransaction(store),
        mode: SelectorsStoreWMShares.getMode(store),
    };
};

export default HighOrder(Connect(mapStateToProps))(Component);
