import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import {
    SelectorsStore as SelectorsStoreWMShares,
    SelectorsAction as SelectorsActionWMShares,
} from "~/store/wm/shares";
import * as UtilsI18n from "~/util/i18n";
import UtilLodash from "~/util/lodash";

import DataDate from "~/components/DataDate";
import DataNumber from "~/components/DataNumber";
import I18n from "~/components/I18n";
import Ticket from "~/components/Ticket/Ticket";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        transaction: PropTypes.shape({
            idTransaction: PropTypes.string,
            idTransactionStatus: PropTypes.string,
            valueDateAsString: PropTypes.string,
            executing: PropTypes.bool,
            data: PropTypes.shape({
                alias: PropTypes.string,
                isin: PropTypes.string,
                amountToInvest: PropTypes.number,
                currency: PropTypes.string,
                limitDate: PropTypes.string,
                limitTo: PropTypes.number,
                observations: PropTypes.string,
                orderCompleted: PropTypes.bool,
                paperName: PropTypes.string,
                price: PropTypes.string,
                ticker: PropTypes.string,
            }),
        }).isRequired,
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
        data: {
            isin: EMPTY_STR,
            ticker: EMPTY_STR,
            limitDate: EMPTY_STR,
            limitTo: null,
            orderCompleted: false,
        },
    },
};

const FORM_ID = "client.wm.shares.buy.send";

export function Component(props) {
    const { transaction, ticketConfirmation } = props;
    const { data } = transaction;

    const {
        alias: debitAccount,
        isin,
        amountToInvest,
        currency,
        limitDate,
        limitTo,
        observations,
        orderCompleted,
        paperName,
        price,
        ticker,
    } = data;

    const orderValid = orderCompleted ? (
        UtilsI18n.get(`${FORM_ID}.orderCompleted.label`)
    ) : (
        <DataDate value={limitDate} />
    );

    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    hasMobileComponent
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}>
                    <div className="data-section">
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="client.wm.shares.buy.step3.shareToBuy" />
                            </span>
                            <span className="data-text">{paperName}</span>
                        </div>
                        {isin ? (
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="client.wm.shares.buy.step3.isin" />
                                </span>
                                <span className="data-text">{isin}</span>
                            </div>
                        ) : (
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="client.wm.shares.buy.step3.ticker" />
                                </span>
                                <span className="data-text">{UtilLodash.isObject(ticker) ? EMPTY_STR : ticker}</span>{" "}
                                {/* Fix for existings transactions only for dev */}
                            </div>
                        )}
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="client.wm.shares.buy.step3.amountToInvest" />
                            </span>
                            <span className="data-name amount-number">
                                <DataNumber value={amountToInvest} prefix={currency} className="format-currency" />
                            </span>
                        </div>

                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="client.wm.shares.buy.step3.price" />
                            </span>
                            <span className="data-text">
                                {UtilsI18n.get(`client.wm.shares.buy.step3.price.${price}`)}
                            </span>
                        </div>
                        {!!limitTo && (
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="client.wm.shares.buy.step3.limiTo" />
                                </span>
                                <span className="data-name  amount-number">
                                    <DataNumber value={limitTo} prefix={currency} className="format-currency" />
                                </span>
                            </div>
                        )}
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="client.wm.shares.buy.step3.debitAccount" />
                            </span>
                            <span className="data-text">{debitAccount}</span>
                        </div>
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="wm.allInvestments.orderValidUntil.label" />
                            </span>
                            <span className="data-text">{orderValid}</span>
                        </div>
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="client.wm.shares.buy.step3.observations" />
                            </span>
                            <span className="data-text">{observations}</span>
                        </div>
                    </div>
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { dispatch } = props;
        const { idTransaction } = transaction;

        dispatch(
            SelectorsActionWMShares.cancelTransaction({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        const { dispatch } = props;
        dispatch(SelectorsActionWMShares.signTransactionPreview({ ...data }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreWMShares.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
