import React from "react";

import { push as Push } from "connected-react-router";
import { withFormik as WithFormik, Form, Field } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import * as Yup from "yup";

import { EMPTY_STR, EVENT_BACKBUTTON } from "~/constants";
import { SelectorsAction, SelectorsStore } from "~/store/biometric";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import UtilsDevice from "~/util/device";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button";
import LinkButton from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Modal from "~/components/Modal";
import Credential from "~/pages/_components/fields/credentials/Credential";

import Style from "./PasswordModal.rules.scss";

export const { NAME, CLASS } = Style;

const FORM_ID = "fingerprint.askPassword";

export const PROP = {
    types: {
        askPassword: PropTypes.bool.isRequired,
        dispatch: PropTypes.func.isRequired,
        isSubmitting: PropTypes.bool,
        isNotLogged: PropTypes.bool,
    },
    defaults: {
        isSubmitting: false,
        isNotLogged: false,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        if (UtilsDevice.isMobileNative()) {
            document.addEventListener(EVENT_BACKBUTTON, this.handleCancel);
        }
    }

    componentWillUnmount() {
        if (UtilsDevice.isMobileNative()) {
            document.removeEventListener(EVENT_BACKBUTTON, this.handleCancel);
        }
    }

    handleCancel = () => {
        const { dispatch, setSubmitting, askPassword, resetForm } = this.props;
        if (askPassword) {
            setSubmitting(false);
            dispatch(SelectorsAction.hidePasswordModal());
            dispatch(SelectorsActionForm.closeConfirmation());

            resetForm();
            dispatch(SelectorsAction.cancelVerifyBiometric());
        }
    };

    handleRecoverPasswordMobile = () => {
        const { dispatch } = this.props;

        dispatch(Push("/recoveryPassnoToken"));
    };

    render() {
        const { askPassword, isSubmitting } = this.props;
        const msgKeyPre = "settings.fingerprintConfiguration.dialog";

        const msgKey1 = `${msgKeyPre}.error_1`;
        const msgKey2 = `${msgKeyPre}.error_2`;

        const mess = `${UtilsI18n.get(msgKey1)}\n${UtilsI18n.get(msgKey2)}`;
        return (
            <Modal show={askPassword}>
                <Form id={`modal-${NAME.toLowerCase()}`} className={`login-form ${CLASS}`}>
                    <div className="modal-text">
                        <p className="text-lead">{mess}</p>
                    </div>
                    <div className="login-form-field container-password">
                        <div className="login-form-field-block">
                            <Field
                                idForm={FORM_ID}
                                name="password"
                                className="login-form-input input-icon"
                                component={Credential}
                                hidePlaceholder
                                autoFocus={false}
                                value=""
                            />
                        </div>
                        <span className="login-form-field-validation" />
                        {UtilsDevice.isMobileNative() && (
                            <div className="link">
                                <LinkButton onClick={() => this.handleRecoverPasswordMobile()}>
                                    <I18n id="login.step2.forgotPassword" />
                                </LinkButton>
                            </div>
                        )}
                    </div>
                    <div className="modal-buttons">
                        <div className="login-form-field button-field">
                            <Button bsStyle="primary" label="login.comeIn" loading={isSubmitting} type="submit" />
                        </div>
                        <div className="login-form-field button-field">
                            <Button
                                bsStyle="secondary"
                                label="global.cancel"
                                loading={isSubmitting}
                                type="submit"
                                onClick={this.handleCancel}
                            />
                        </div>
                    </div>
                </Form>
            </Modal>
        );
    }
}

export default HighOrder(
    Connect((store) => ({
        askPassword: SelectorsStore.askPassword(store),
    })),
    HighOrder.Resizable,
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            password: EMPTY_STR,
        }),
        validationSchema: () =>
            Yup.object().shape({
                password: Yup.string().required(UtilsI18n.get("login.step2.password.required")),
            }),
        handleSubmit: ({ password }, { props, ...formikBag }) => {
            const { isNotLogged, username } = props;
            props.dispatch(SelectorsAction.validatePassword({ password, username, formikBag, isNotLogged }));
        },
    }),
)(Component);
