import { TYPE as TYPES_APP } from "~/store/app";
import { TYPE as LoginTypes } from "~/store/login";
import { TYPE as SessionTypes } from "~/store/session";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.SHOW_SESSION_EXPIRATION_MODAL:
            return {
                ...storeProp,
                sessionAboutToExpire: true,
                sessionSecondsToExpire: action.secondsToExpire,
            };

        case TYPE.SAVE_LAST_HREF:
            return {
                ...storeProp,
                lastHref: action.lastHref,
            };

        case TYPE.DELETE_LAST_HREF:
            return {
                ...storeProp,
                lastHref: null,
            };

        case LoginTypes.LOGIN_STEP_2_REQUEST:
            return {
                ...storeProp,
                comeFromLogin: true,
            };

        case TYPE.RESET_COME_FROM_LOGIN:
            return {
                ...storeProp,
                comeFromLogin: false,
            };

        case SessionTypes.EXTEND_SUCCESS:
            return {
                ...storeProp,
                sessionAboutToExpire: false,
                sessionSecondsToExpire: 0,
                sessionExtendedCount: storeProp.sessionExtendedCount + 1,
            };

        case TYPES_APP.CLEAN_UP:
            return PROP.defaults;

        default:
            return storeProp;
    }
};
