import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    uploadSuppliersExportPDF: (props) => API.executeWithAccessToken(PATH.EXPORT_PDF, props),
    uploadSuppliersSend: (props) => API.executeWithAccessToken(PATH.UPLOAD_SUPPLIERS_SEND, props),
};

export const SelectorsAction = {
    cancelTransactionCustom: (props) => FactoryAction(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, props),
    closeConfirmation: () => FactoryAction(TYPE.CLOSE_TRANSACTION_PREVIEW),
    fileUploadSuppliersFailure: (props) => FactoryAction(TYPE.FILE_UPLOAD_SUPPLIERS_FAILURE, props),
    fileUploadSuppliersRemoved: (props) => FactoryAction(TYPE.FILE_SUPPLIERS_REMOVED, props),
    fileUploadSuppliersSuccess: (props) => FactoryAction(TYPE.FILE_UPLOAD_SUPPLIERS_SUCCESS, props),
    previewCancelRequest: () => FactoryAction(TYPE.PREVIEW_CANCEL_REQUEST),
    readUploadSuppliersTransactionRequest: (props) => FactoryAction(TYPE.READ_TRANSACTION_REQUEST, props),
    readUploadSuppliersTransactionSuccess: (props) => FactoryAction(TYPE.READ_TRANSACTION_SUCCESS, props),
    restartFlow: () => FactoryAction(TYPE.RESTART_FLOW),
    signTransactionPreview: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_PREVIEW_REQUEST, props),
    signTransactionPreviewSuccess: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_PREVIEW_SUCCESS, props),
    signTransaction: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_REQUEST, props),
    uploadSuppliersExportPDF: (props) => FactoryAction(TYPE.EXPORT_PDF, props),
    uploadSuppliersPreviewFormRequest: (props) => FactoryAction(TYPE.PREVIEW_FORM_REQUEST, props),
    uploadSuppliersPreviewFormSuccess: (props) => FactoryAction(TYPE.PREVIEW_FORM_SUCCESS, props),
    uploadSuppliersSendFormRequest: (props) => FactoryAction(TYPE.SEND_FORM_REQUEST, props),
    uploadSuppliersSendFormSuccess: (props) => FactoryAction(TYPE.SEND_FORM_SUCCESS, props),
};

export const SelectorsStore = {
    getIdFile: (store) => store[NAME].idFile,
    getIsSubmitting: (store) => store[NAME].isSubmitting,
    getMode: (store) => store[NAME].mode,
    getPrevMode: (store) => store[NAME].prevMode,
    getSuppliers: (store) => store[NAME].suppliersList,
    getTransaction: (store) => store[NAME].transaction,
    isFetching: (store) => store[NAME].isFetching,
};
