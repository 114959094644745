import React from "react";

import { routerActions as ActionsRouter } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import { compose as Compose } from "redux";

import { MASSIVE_PAYMENTS_LOADING_METHOD, TRANSACTION_PATH, EMPTY_STR } from "~/constants";
import { ID_FORM, MODE } from "~/constants/form";
import { SelectorsAction as SelectorsActionForm, SelectorsStore as SelectorsStoreForm } from "~/store/form";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import {
    SelectorsAction as SelectorsActionTransactionLines,
    SelectorsStore as SelectorsStoreTransactionLines,
} from "~/store/transactionLines";
import * as UtilsI18n from "~/util/i18n";
import * as UtilsNumber from "~/util/number";

import Button from "~/components/Button";
import { Resizable } from "~/components/HighOrder";
import I18n from "~/components/I18n";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import DetailBox from "~/pages/_components/detailBox/DetailBox";

import FormField from "~/pages/forms/_components/_fields/_commons/formField";
import ManualPayment from "~/pages/forms/_components/_fields/_multilinefile/ManualPayment";

export const NAME = "Transactionlines";

export const PROP = {
    types: {
        idForm: PropTypes.string.isRequired,
        isFromBackoffice: PropTypes.bool,
        localBanks: PropTypes.array,
        mode: PropTypes.oneOf([MODE.EDIT, MODE.VIEW]).isRequired,
        payrollLines: PropTypes.array,
        setValue: PropTypes.func.isRequired,
    },
    defaults: {
        idForm: EMPTY_STR,
        isFromBackoffice: false,
        localBanks: [],
        mode: MODE.EDIT,
        payrollLines: [],
        setValue: null,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        const { dispatch, idForm, localBanks, mode, payrollLines, transactionLines, setValue } = this.props;

        if (!this.isReadOnly() && localBanks.length === 0) {
            dispatch(SelectorsActionTransactionLines.listLocalBanksRequest());
        }

        if (!this.isReadOnly() && transactionLines) {
            setValue(transactionLines);
        }

        if (mode === MODE.EDIT && idForm === ID_FORM.SALARY_PAYMENT && payrollLines.length === 0) {
            const creditAccountNameMask = UtilsI18n.get("salaryPayment.manual.creditAccountName.mask");

            dispatch(SelectorsActionTransactionLines.importPayrollRequest({ creditAccountNameMask }));
        }
    }

    componentDidUpdate(prevProps) {
        const { transactionLines, setValue } = this.props;
        // update value if transactionlines changes with set_currency event
        if (prevProps.transactionLines !== transactionLines) {
            setValue(transactionLines);
        }
    }

    handleDetailsClick = () => {
        const { dispatch, idForm, isFromBackoffice, match } = this.props;

        if (this.isReadOnly()) {
            let detailsUrl = `/form/${idForm}/processDetail`;

            if (match.path === TRANSACTION_PATH || isFromBackoffice) {
                const {
                    transaction: {
                        data: { file, loadingMethod, manualInput },
                    },
                } = this.props;

                let linesFromTransactionData;

                if (loadingMethod[0] === MASSIVE_PAYMENTS_LOADING_METHOD.MANUAL) {
                    linesFromTransactionData = manualInput;
                } else {
                    linesFromTransactionData = file;
                }

                dispatch(SelectorsActionTransactionLines.setLinesFromTransactionData({ linesFromTransactionData }));
            }

            if (isFromBackoffice) {
                detailsUrl += "/backoffice";
            }

            dispatch(ActionsRouter.push(detailsUrl));
        } else {
            const { form } = this.props;

            dispatch(ActionsRouter.push(`/form/${idForm}/processDetail`, { shouldLoadForm: false }));
            dispatch(SelectorsActionForm.setData(form.values));
        }
    };

    isReadOnly = () => {
        const { mode } = this.props;

        return mode === MODE.VIEW || mode === MODE.PREVIEW;
    };

    renderEdit = () => {
        return <ManualPayment {...this.props} />;
    };

    renderPreview = () => {
        const { transactionLines, totalAmount, linesWithNoAmount } = this.props;
        const { currency, quantity } = totalAmount;

        return (
            <DetailBox>
                {!!linesWithNoAmount && (
                    <I18n
                        component="div"
                        componentProps={{ className: "alert alert-warning mTop", role: "alert" }}
                        LINE_COUNT={linesWithNoAmount}
                        id="massive.payments.warning.noAmount"
                    />
                )}
                <DetailBox.Data label="forms.inputFile.massivePayments.lines">
                    <div>
                        {transactionLines.length} <I18n id="forms.inputFile.massivePayments.total.lines" />
                    </div>
                </DetailBox.Data>
                <DetailBox.Data label="forms.inputFile.massivePayments.totalAmount">
                    <FormattedAmount currency={currency} quantity={quantity} />
                </DetailBox.Data>
                <div className="detailBox-row">
                    <Button
                        bsStyle="primary"
                        className="btn-small"
                        onClick={this.handleDetailsClick}
                        label="forms.inputFile.massivePayments.transaction.detail"
                    />
                </div>
            </DetailBox>
        );
    };

    renderView = () => {
        const { isMobile, lang, value } = this.props;

        const amount = value.reduce(
            (result, line) => ({
                quantity: result.quantity + line.creditAmountQuantity,
                currency: line.creditAmountCurrency,
            }),
            { quantity: 0, currency: null },
        );

        const { currency: amountCurrency, quantity: amountQuantity } = amount;

        return (
            <DetailBox>
                <DetailBox.Data label="forms.inputFile.massivePayments.currency">
                    {UtilsI18n.get(`currency.label.${amountCurrency}`)}
                </DetailBox.Data>
                <DetailBox.Data label="forms.inputFile.massivePayments.lines">{value.length}</DetailBox.Data>
                <DetailBox.Data label="forms.inputFile.massivePayments.totalAmount">
                    <FormattedAmount
                        currency={`${amountCurrency}`}
                        quantity={`${UtilsNumber.formatNumber(amountQuantity, lang)}`}
                    />
                </DetailBox.Data>
                {!isMobile && (
                    <div className="detailBox-row">
                        <Button
                            bsStyle="primary"
                            className="btn-small"
                            onClick={this.handleDetailsClick}
                            label="forms.inputFile.massivePayments.transaction.detail"
                        />
                    </div>
                )}
            </DetailBox>
        );
    };

    render() {
        const { mode } = this.props;

        return (
            <React.Fragment>
                {mode === MODE.EDIT && this.renderEdit()}
                {mode === MODE.PREVIEW && this.renderPreview()}
                {mode === MODE.VIEW && this.renderView()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    lang: SelectorsStoreI18n.getLang(store),
    linesWithNoAmount: SelectorsStoreTransactionLines.getLinesWithNoAmount(store).length,
    localBanks: SelectorsStoreTransactionLines.getLocalBanks(store),
    payrollLines: SelectorsStoreTransactionLines.getPayrollLines(store),
    totalAmount: SelectorsStoreTransactionLines.getTotalAmount(store),
    transaction: SelectorsStoreForm.getTransaction(store),
    transactionLines: SelectorsStoreTransactionLines.getTransactionLines(store),
});

export default Compose(
    WithRouter,
    Connect(mapStateToProps),
    Resizable,
    FormField({
        isValidValue: () => true,
    }),
)(Component);
