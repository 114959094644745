import React from "react";

import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";

import { EMPTY_STR } from "~/constants";
import * as ConfigUtil from "~/util/config";

import FieldError from "~/pages/_components/fields/FieldError";

export const NAME = "Captcha";

export const PROP = {
    types: {
        form: PropTypes.shape({
            isSubmitting: PropTypes.bool.isRequired,
            setFieldValue: PropTypes.func.isRequired,
        }).isRequired,
        field: PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        }).isRequired,
    },
    defaults: {},
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    captchaRef = React.createRef();

    componentDidUpdate(prevProps) {
        const { form, field } = this.props;
        if (field.value !== EMPTY_STR && prevProps.form.isSubmitting && !form.isSubmitting) {
            this.captchaRef.current.reset();
        }
    }

    handleChange = (value) => {
        const { form, field } = this.props;

        form.setFieldValue(field.name, value);
    };

    handleExpired = () => {
        const { form, field } = this.props;

        form.setFieldValue(field.name, EMPTY_STR);
    };

    render() {
        const { field, form } = this.props;

        return (
            <div className={`form-group ${form.touched[field.name] && form.errors[field.name] ? "has-error" : ""}`}>
                <ReCAPTCHA
                    sitekey={ConfigUtil.get("core.auth.captcha.google.siteKey")}
                    onChange={this.handleChange}
                    ref={this.captchaRef}
                    onExpired={this.handleExpired}
                />
                {form.touched[field.name] && form.errors[field.name] && <FieldError error={form.errors[field.name]} />}
            </div>
        );
    }
}

export default Component;
