import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";

// eslint-disable-next-line import/no-cycle
import { AccordionContext } from "~/pages/_components/Accordion";
import AccordionItemContent from "~/pages/_components/AccordionItemContent";

export const NAME = "AccordionItem";

export const PROP = {
    types: {
        item: PropTypes.node.isRequired,
        children: PropTypes.node.isRequired,
        number: PropTypes.number.isRequired,
    },
    defaults: {},
};
export function Component(props) {
    const { item, number, children } = props;

    return (
        <AccordionContext.Consumer>
            {({ itemOpen, onClick }) => {
                const isActive = itemOpen === number;

                return (
                    <li className="list--permissions-item">
                        <button
                            type="button"
                            onClick={() => onClick(number)}
                            className={isActive ? "isActive" : EMPTY_STR}>
                            {item}
                        </button>
                        <AccordionItemContent isActive={isActive}>{children}</AccordionItemContent>
                    </li>
                );
            }}
        </AccordionContext.Consumer>
    );
}

export default Component;
