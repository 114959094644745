import React from "react";

import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { EMPTY_STR } from "~/constants";
import { SelectorsAction as SelectorsActionSettings, SelectorsStore as SelectorsStoreSettings } from "~/store/settings";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Token from "~/components/Token/Token";

import Style from "../changeEmail/ChangeEmail.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        isSubmitting: PropTypes.bool,
        transaction: PropTypes.shape({
            idTransaction: PropTypes.string,
            idTransactionStatus: PropTypes.string,
            valueDateAsString: PropTypes.string,
            executing: PropTypes.bool,
        }).isRequired,
    },
    defaults: {
        transaction: null,
        isSubmitting: false,
    },
};

export function Component({ dispatch, isSubmitting }) {
    function handleCancel() {
        dispatch(SelectorsActionSettings.updateMailPreviewCancel());
    }

    return (
        <section id={Style.ID}>
            <div className="transaction-confirmation-container">
                <Form>
                    <Token labelButton="global.send" isSubmitting={isSubmitting}>
                        <Button size="sm" variant="secondary" disabled={isSubmitting} onClick={handleCancel}>
                            <I18n id="global.cancel" />
                        </Button>
                    </Token>
                </Form>
            </div>
        </section>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    isSubmitting: SelectorsStoreSettings.getFetching(store),
    transaction: SelectorsStoreSettings.getChangeTransactionData(store),
});

export default HighOrder(
    WithRouter,
    Connect(mapStateToProps),
    HighOrder.Resizable,
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(UtilsI18n.get("form.credential.otp.required")),
            }),
        handleSubmit: (data, formikBag) => {
            const {
                dispatch,
                transaction: { idTransaction, idActivity },
            } = formikBag.props;
            const { otp } = data;

            dispatch(
                SelectorsActionSettings.signTransaction({
                    idForm: null,
                    idActivity,
                    idTransaction,
                    credentials: { otp },
                    formikBag,
                }),
            );
        },
    }),
)(Component);
