import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import * as UtilsI18n from "~/util/i18n";
import UtilsLodash from "~/util/lodash";

import Select from "~/components/Select";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import WithFocus from "~/pages/_components/withFocus";

import RadioButtonGroup from "~/pages/forms/_components/_fields/_commons/RadioButtonGroup";

export const NAME = "Selector";

export const PROP = {
    types: {
        customFilter: PropTypes.func,
        field: PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.any,
        }).isRequired,
        form: PropTypes.shape({
            errors: PropTypes.objectOf(PropTypes.string).isRequired,
            setFieldValue: PropTypes.func.isRequired,
            touched: PropTypes.objectOf(PropTypes.bool).isRequired,
        }).isRequired,
        hideLabel: PropTypes.bool,
        idForm: PropTypes.string.isRequired,
        inLineControl: PropTypes.bool,
        isDisabled: PropTypes.bool,
        isFocused: PropTypes.bool,
        options: PropTypes.arrayOf(PropTypes.object).isRequired,
        renderAs: PropTypes.string,
        settedValue: PropTypes.oneOfType([
            PropTypes.shape({ name: PropTypes.string.isRequired, value: PropTypes.any }),
            PropTypes.string,
        ]),
        toggleIsFocused: PropTypes.func.isRequired,
    },
    defaults: {
        customFilter: null,
        hideLabel: false,
        inLineControl: false,
        isDisabled: false,
        isFocused: false,
        renderAs: "combo",
        settedValue: {
            name: EMPTY_STR,
            value: EMPTY_STR,
        },
    },
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        isOpen: false,
    };

    componentDidUpdate(prevProps) {
        const { isOpenCallback } = this.props;
        const { isOpen } = this.state;

        if (isOpen !== prevProps.isOpen && isOpenCallback) {
            this.isOpenCallback = (childProp) => {
                if (childProp !== isOpen) {
                    isOpenCallback(childProp);
                    this.setState({
                        isOpen: childProp,
                    });
                }
            };
        }
    }

    handleChange = (param) => {
        const { field, form, onChange, renderAs } = this.props;
        const { errors } = form;

        let value = param;

        if (param) {
            if (renderAs === "combo") {
                value = param.value;
            }

            const newErrors = UtilsLodash.omitElements(errors, field.name);

            form.setFieldValue(field.name, value);
            form.setErrors(newErrors);

            if (onChange) {
                onChange(param);
            }
        }
    };

    handleIsOpenCallBack = (childProp) => {
        const { isOpenCallback } = this.props;

        if (isOpenCallback) {
            isOpenCallback(childProp);
            this.setState({
                isOpen: childProp,
            });
        }
    };

    renderAsRadio() {
        const { disabled, field, form, inLineControl, isFocused, options, tooltip } = this.props;
        const { errors, touched } = form;
        const hasError = touched[field.name] && errors[field.name];

        return (
            <div
                className={`form-group ${touched[field.name] && errors[field.name] ? "has-error" : EMPTY_STR} ${
                    isFocused ? "has-focus" : EMPTY_STR
                }`}>
                <RadioButtonGroup
                    disabled={disabled}
                    inLineControl={inLineControl}
                    onChange={this.handleChange}
                    optionClassName="needsclick"
                    options={options}
                    value={field.value}
                />
                {tooltip && (
                    <div className="tooltip">
                        <span className="tooltip-text"> {tooltip}</span>
                    </div>
                )}
                {hasError && <FieldError error={errors[field.name]} />}
            </div>
        );
    }

    renderAsCombo() {
        const {
            customFilter,
            field,
            form,
            hideLabel,
            hidePlaceholder = false,
            idForm,
            isDisabled,
            isFocused,
            isSearchableOnMobile = false,
            optional,
            options,
            settedValue,
            toggleIsFocused,
            tooltip,
        } = this.props;
        const { touched, errors } = form;
        const { name, value } = field;
        const hasError = touched[field.name] && errors[field.name];

        if (isDisabled && !value) {
            this.handleChange(settedValue);
        }

        return (
            <div
                className={`form-group ${touched[name] && errors[name] ? "has-error" : EMPTY_STR} ${
                    isFocused ? "has-focus" : EMPTY_STR
                }`}>
                {!hideLabel && <FieldLabel optional={optional} labelKey={`${idForm}.${field.name}.label`} />}
                <div className="input-group">
                    <Select
                        className="slideFromBottom flex-container"
                        clearable={false}
                        disabled={isDisabled}
                        filterOption={customFilter}
                        isSearchableOnMobile={isSearchableOnMobile}
                        name={name}
                        onBlur={toggleIsFocused}
                        onChange={this.handleChange}
                        onFocus={toggleIsFocused}
                        options={options}
                        placeholder={!hidePlaceholder && UtilsI18n.get(`${idForm}.${field.name}.placeholder`)}
                        value={value}
                        isOpenCallback={this.handleIsOpenCallBack}
                        isOpened={this.state.isOpen}
                    />
                    {tooltip && (
                        <div className="tooltip">
                            <span className="tooltip-text"> {tooltip}</span>
                        </div>
                    )}
                </div>
                {hasError && <FieldError error={errors[field.name]} />}
            </div>
        );
    }

    render() {
        const { renderAs } = this.props;

        let element = null;

        if (renderAs === "combo") {
            element = this.renderAsCombo();
        } else if (renderAs === "radio") {
            element = this.renderAsRadio();
        }

        return element;
    }
}

export default WithFocus(Component);
