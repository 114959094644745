import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "factoring";

export const PATH = {
    PAYMENT_ORDERS_LIST: "/client.factoring.paymentOrders.list",
    SUPPLIERS_LIST: "/client.factoring.suppliers.list",
    EXPORT_PAYMENT_ORDERS_LIST: "/client.factoring.paymentOrders.export",
    ADVANCE_PAYMENTS_LIST: "/client.factoring.advancePayments.list",
};

export const TYPE = FactoryType(NAME, [
    "LOAD_LIST_PAYMENT_ORDERS_REQUEST",
    "LOAD_LIST_PAYMENT_ORDERS_FAILURE",
    "LOAD_LIST_PAYMENT_ORDERS_SUCCESS",
    "RESET_FILTERS",
    "LOAD_LIST_SUPPLIERS_REQUEST",
    "LOAD_LIST_SUPPLIERS_FAILURE",
    "LOAD_LIST_SUPPLIERS_SUCCESS",
    "EXPORT_PAYMENT_ORDERS_REQUEST",
    "EXPORT_PAYMENT_ORDERS_FAILURE",
    "EXPORT_PAYMENT_ORDERS_SUCCESS",
    "PREPARE_ADVANCE_PAYMENTS_LIST",
]);

export const PROP = {};

PROP.members = {
    filters: {
        status: PropTypes.string,
        currency: PropTypes.number,
        paymentOrder: PropTypes.string,
    },
    filtersSuppliers: {
        status: PropTypes.string,
        currency: PropTypes.number,
        paymentOrder: PropTypes.string,
    },
};

PROP.types = {
    isFetching: PropTypes.bool,
    paymentOrderList: PropTypes.array,
    suppliersList: PropTypes.array,
    offset: PropTypes.number,
    pageNumber: PropTypes.number,
    moreLines: PropTypes.bool,
    firstTime: PropTypes.bool,
    moreOrders: PropTypes.bool,
    filters: PropTypes.shape(PROP.members.filters),
    filtersSuppliers: PropTypes.shape(PROP.members.filtersSuppliers),
    ...TypesRedux,
};

PROP.defaults = {
    isFetching: false,
    paymentOrderList: [],
    moreOrders: false,
    suppliersList: [],
    offset: 0,
    pageNumber: 1,
    moreLines: false,
    firstTime: false,
    filters: {
        paymentOrder: EMPTY_STR,
        currency: 9999,
        status: "PEN",
    },
    filtersSuppliers: {
        name: EMPTY_STR,
        documentType: EMPTY_STR,
        documentNumber: EMPTY_STR,
    },
    ...DefaultsRedux,
};
