import React from "react";

import { replace as Replace } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { STATUS, STATUS_ICON } from "~/constants/transaction";
import Container from "~/containers/Internal/Form/Simple";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import {
    SelectorsAction as SelectorsActionTransactions,
    SelectorsStore as SelectorsStoreTransactions,
} from "~/store/transactions";
import DeviceUtils from "~/util/device";
import * as I18nUtils from "~/util/i18n";

import Box from "~/components/Box";
import ExpandElement from "~/components/ExpandElement";
import GridLayout from "~/components/GridLayout";
import I18n from "~/components/I18n";
import Image from "~/components/Image";

import Style from "./ResumeTransaction.rules.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {
        resumeTransaction: PropTypes.shape({
            correctLinesDetails: PropTypes.arrayOf(PropTypes.object).isRequired,
            failedLinesDetails: PropTypes.arrayOf(PropTypes.object).isRequired,
            notExecutedLinesDetails: PropTypes.arrayOf(PropTypes.object).isRequired,
        }),
        dispatch: PropTypes.func.isRequired,
        isApproving: PropTypes.bool.isRequired,
    },
    defaults: {
        resumeTransaction: { correctLinesDetails: null, failedLinesDetails: null, notExecutedLinesDetails: null },
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(SelectorsActionTransactions.setApproving(), { isApproving: null });
    }

    handleClick = () => {
        this.setState(({ showDetails }) => ({ showDetails: !showDetails }));
    };

    handleClose = () => {
        const { dispatch } = this.props;
        const pathname = "/transactions/list";

        dispatch(
            Replace({
                pathname,
                state: { transition: "transition-flow-close" },
            }),
        );
    };

    renderTransactionDetails = (resumeTransaction) => {
        if (!resumeTransaction) {
            return null;
        }
        const {
            totalTransactions,
            correctLinesDetails,
            failedLinesDetails,
            notExecutedLinesDetails,
        } = resumeTransaction;
        const isMobile = DeviceUtils.isDisplayMobile();

        const transactions = [];
        if (correctLinesDetails.length > 0) {
            transactions.push(correctLinesDetails);
        }
        if (notExecutedLinesDetails.length > 0) {
            transactions.push(notExecutedLinesDetails);
        }
        if (failedLinesDetails.length > 0) {
            transactions.push(failedLinesDetails);
        }
        return (
            <div id={Style.ID}>
                <div className="transaction-resume-container">
                    <div className="resume-header">
                        <I18n id="transaction.resume.totalTransaction" className="resume-header-label" />
                        <span className="resume-header-value">{` ${totalTransactions}`}</span>
                    </div>
                    {isMobile ? this.getTransactions(transactions) : this.getExpandElement(resumeTransaction)}
                </div>
            </div>
        );
    };

    renderItems = (transaction) =>
        transaction.map((element) => {
            const {
                idTransaction,
                activityName,
                transactionAmountDescription,
                transactionStatus,
                errorDescription,
            } = element;
            /** If in this screen the status is pending, it must had been already signed by the current user. */

            return (
                <div key={idTransaction} className="resume-row">
                    <div className="resume-row-data">
                        <I18n id="transaction.resume.idTransaction" className="data-label" />
                        <span className="data-value">{idTransaction}</span>
                    </div>
                    <div className="resume-row-data">
                        <I18n id="transaction.resume.activity" className="data-label" />
                        <span className="data-value">{activityName}</span>
                    </div>
                    <div className="resume-row-data">
                        <I18n id="transaction.resume.amount" className="data-label" />
                        <span className="data-value">{transactionAmountDescription}</span>
                    </div>
                    <div className="resume-row-data">
                        <I18n id="transaction.resume.status" className="data-label" />
                        <div className="resume-status">
                            <Image src={`${STATUS_ICON[transactionStatus]}.svg`} />
                            <span className="data-value">{I18nUtils.get(`transaction.status.${transactionStatus}`)}</span>
                        </div>
                    </div>
                    <div className="resume-row-data">
                        <span className="data-value" dangerouslySetInnerHTML={{ __html: errorDescription }} />
                    </div>
                </div>
            );
        });

    renderColumns = (transaction) => {
        const {
            idTransaction,
            activityName,
            transactionAmountDescription,
            transactionStatus,
            errorDescription,
        } = transaction;
        return (
            <GridLayout className="body">
                <Box>{idTransaction}</Box>
                <Box>{activityName}</Box>
                <Box justify="end">{transactionAmountDescription}</Box>
                <Box justify="end" className="container-tooltip">
                    {I18nUtils.get(`transaction.status.${transactionStatus}`)}
                    {errorDescription && (
                        <div className="tooltip">
                            <span className="tooltip-text">
                                <span>{errorDescription}</span>
                            </span>
                        </div>
                    )}
                    <Image src={`${STATUS_ICON[transactionStatus]}.svg`} />
                </Box>
            </GridLayout>
        );
    };

    renderExpandElement = (transactions, status) => {
        const { i18n } = this.props;
        const COLUMN_HEADINGS = [i18n.transactionId, i18n.activity, i18n.amount, i18n.transactionStatus];
        return (
            <ExpandElement openInit>
                <GridLayout className="expandHeader">
                    <h1>{I18nUtils.get(`transactions.web.table.${status}`)}</h1>
                </GridLayout>
                <div className="transaction-list">
                    <GridLayout className="header">
                        {COLUMN_HEADINGS.map((heading, index) => (
                            <Box justify={(index === 2 || index === 3) && "end"}>{heading}</Box>
                        ))}
                    </GridLayout>
                    {transactions.map((transaction) => this.renderColumns(transaction))}
                </div>
            </ExpandElement>
        );
    };

    getExpandElement = (resumeTransaction) => {
        const { correctLinesDetails, failedLinesDetails } = resumeTransaction;
        return (
            <div>
                {failedLinesDetails.length > 0 && this.renderExpandElement(failedLinesDetails, "failed")}
                {correctLinesDetails.length > 0 && this.renderExpandElement(correctLinesDetails, "correct")}
            </div>
        );
    };

    getTransactions = (transactions) =>
        transactions.map((transaction) => (
            <div key={`rc-${transaction.idTransaction}`} className="resume-content">
                {this.renderItems(transaction)}
            </div>
        ));

    render() {
        const { resumeTransaction, isApproving } = this.props;
        const title = isApproving ? "transaction.resume.title" : "transactions.masiveCancelationHeader";
        return (
            <Container
                name={NAME}
                head-title={title}
                head-onClose={this.handleClose}
                head-onBackWeb={this.handleClose}
                image="transactions-step.svg"
                className={CLASS}>
                {this.renderTransactionDetails(resumeTransaction)}
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    resumeTransaction: SelectorsStoreTransactions.getResumeTransaction(store),
    isApproving: SelectorsStoreTransactions.getSignAction(store),
    i18n: {
        transactionId: StoreI18n.getMessage(store, "transactions.web.table.header.transactionId"),
        activity: StoreI18n.getMessage(store, "transactions.web.table.header.activity"),
        amount: StoreI18n.getMessage(store, "transactions.web.table.header.amount"),
        transactionStatus: StoreI18n.getMessage(store, "transactions.web.table.header.transactionStatus"),
    },
});

export default Connect(mapStateToProps)(Component);
