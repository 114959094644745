import PropTypes from "prop-types";

import { CDP_CURRENCY } from "~/constants";
import * as UtilDate from "~/util/date";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "accounts";

export const PATH = {
    ACCOUNTS_DOWNLOAD_MOVEMENTS: "/accounts.downloadMovements",
    ACCOUNTS_DOWNLOAD_STATEMENT_LINE: "/accounts.downloadStatementLine",
    ACCOUNTS_LIST: "/accounts.list",
    ACCOUNTS_LIST_STATEMENT_DETAILS: "/accounts.listStatementDetails",
    ACCOUNTS_LISTSTATEMENT_LINES: "/accounts.listStatementLines",
    ACCOUNTS_LIST_STATEMENTS: "/accounts.listStatements",
    ACCOUNTS_PDF_STATE: "/accounts.pdf.state",
    ACCOUNTS_READ: "/accounts.read",
    ACCOUNTS_STATE: "/accounts.state",
    BILLING_TICKET_PDF: "/accounts.getBillingTicketPDF",
    BILLING_TICKETS: "/accounts.listEBillingTickets",
    NOTES_EDIT_STATEMENT_NOTE: "/notes.editStatementNote",
    NOTES_READ_STATEMENT_NOTE: "/notes.readStatementNote",
};

export const TYPE = FactoryType(NAME, [
    "ACCOUNT_DETAILS_REQUEST",
    "ACCOUNT_DETAILS_SUCCESS",
    "ACCOUNT_DOWNLOAD_STATEMENT_FAILURE",
    "ACCOUNT_DOWNLOAD_STATEMENT_REQUEST",
    "ACCOUNT_DOWNLOAD_STATEMENT_SUCCESS",
    "ACCOUNT_FETCH_MORE_MOVEMENTS_REQUEST",
    "ACCOUNT_FETCH_MORE_MOVEMENTS_SUCCESS",
    "ACCOUNT_FETCH_MOVEMENTS_REQUEST",
    "ACCOUNT_FETCH_MOVEMENTS_SUCCESS",
    "ACCOUNT_MOVEMENT_DETAILS_REQUEST",
    "ACCOUNT_MOVEMENT_DETAILS_SUCCESS",
    "ACCOUNT_READ_REQUEST",
    "ACCOUNT_READ_SUCCESS",
    "ACCOUNT_STATE_REQUEST",
    "ACCOUNT_STATE_SUCCESS",
    "CLEAN_MOVEMENTS",
    "CLOSE_OPTIONS",
    "DOWNLOAD_BILLING_TICKET_PDF_ERROR",
    "DOWNLOAD_BILLING_TICKET_PDF_REQUEST",
    "DOWNLOAD_BILLING_TICKET_PDF_SUCCESS",
    "DOWNLOAD_MOVEMENTS_FAILURE",
    "DOWNLOAD_MOVEMENTS_REQUEST",
    "DOWNLOAD_MOVEMENTS_SUCCESS",
    "EDIT_MOVEMENT_NOTE_REQUEST",
    "EDIT_MOVEMENT_NOTE_SUCCESS",
    "FETCH_ACCOUNT",
    "FETCH_ACCOUNT_FAILURE",
    "FETCH_ACCOUNT_SUCCESS",
    "LIST_ACCOUNTS_REQUEST",
    "LIST_ACCOUNTS_SUCCESS",
    "LIST_ACCOUNT_STATEMENTS_REQUEST",
    "LIST_ACCOUNT_STATEMENTS_SUCCESS",
    "LIST_BILLING_TICKET_ERROR",
    "LIST_BILLING_TICKET_REQUEST",
    "LIST_BILLING_TICKET_SUCCESS",
    "PDF_STATE_REQUEST",
    "PDF_STATE_SUCCESS",
    "READ_MOVEMENT_NOTE_REQUEST",
    "READ_MOVEMENT_NOTE_SUCCESS",
    "RESET_FILTERS",
    "SET_FILTER",
    "SET_MOBILE_FILTER",
    "SET_SELECTED_ACCOUNT",
    "SET_SELECTED_MOVEMENT",
    "SET_SHOW_MULTICASH_MODAL",
    "SET_SHOW_NOTE_MODAL",
    "SET_SHOW_RATES_MODAL",
    "SET_UNIQUE_ACCOUNT",
    "TOGGLE_OPTIONS",
]);

export const OPTIONS = {
    CURRENT_MONTH: "CURRENT_MONTH",
    CURRENT_WEEK: "CURRENT_WEEK",
    LAST_MONTH: "LAST_MONTH",
    LAST_SIX_MONTHS: "LAST_SIX_MONTHS",
    LATEST_MOVS: "LATEST_MOVS",
    PERIOD: "PERIOD",
    TODAY: "TODAY",
};

export const PROP = {};

PROP.members = {
    filters: {
        channels: PropTypes.array,
        check: PropTypes.string,
        dateFrom: PropTypes.string,
        dateTo: PropTypes.string,
        maxAmount: PropTypes.string,
        minAmount: PropTypes.string,
        reference: PropTypes.string,
    },
    eBillingTicket: {
        amount: PropTypes.number,
        currency: PropTypes.string,
        date: PropTypes.date,
        docTypeNumber: PropTypes.number,
        docType: PropTypes.string,
        number: PropTypes.number,
        serie: PropTypes.string,
    },
    billingTicketsFilter: {
        currency: PropTypes.string,
        month: PropTypes.string,
        year: PropTypes.string,
    },
};

PROP.types = {
    account: {},
    accounts: PropTypes.array,
    billingTicketPDFPath: PropTypes.string,
    billingTicketsFilter: PropTypes.shape(PROP.members.billingTicketsFilter),
    billingTickets: PropTypes.arrayOf(PropTypes.shape(PROP.members.eBillingTicket)),
    channelList: PropTypes.array,
    currency: PropTypes.string,
    equivalentTotalBalance: PropTypes.string,
    fetchingDetails: PropTypes.bool,
    fetchingDownload: PropTypes.bool,
    fetchingMovements: PropTypes.bool,
    fetching: PropTypes.bool,
    fetchingTickets: PropTypes.bool,
    filters: PropTypes.shape(PROP.members.filters),
    firstTime: PropTypes.bool,
    idSelectedAccount: PropTypes.string,
    isOptionsVisible: PropTypes.bool,
    mobileFilter: PropTypes.string,
    moreMovements: PropTypes.bool,
    movements: PropTypes.array,
    next: PropTypes.bool,
    note: PropTypes.string,
    pageNumber: 1,
    rates: PropTypes.array,
    selectedAccount: PropTypes.object,
    selectedFilter: PropTypes.string,
    selectedMovement: PropTypes.string,
    showMulticashModal: PropTypes.bool,
    showNoteModal: PropTypes.bool,
    showRatesModal: PropTypes.bool,
    stateAccount: PropTypes.array,
    statements: PropTypes.string,
    totalLaw19210: PropTypes.string,
    ...TypesRedux,
};

PROP.defaults = {
    account: {},
    accounts: [],
    billingTicketPDFPath: null,
    billingTickets: [],
    billingTicketsFilter: {
        currency: Number(CDP_CURRENCY.USD),
        month: UtilDate.getMonth() + 1,
        year: UtilDate.getYear(),
    },
    channelList: [],
    currency: null,
    equivalentTotalBalance: null,
    fetchingDetails: false,
    fetchingDownload: false,
    fetching: false,
    fetchingMovements: false,
    fetchingTickets: false,
    filters: {
        channels: [],
        check: null,
        dateFrom: null,
        dateTo: null,
        minAmount: null,
        maxAmount: null,
        reference: null,
    },
    firstTime: true,
    idSelectedAccount: null,
    isOptionsVisible: false,
    mobileFilter: null,
    moreMovements: false,
    movements: [],
    next: false,
    note: null,
    pageNumber: 1,
    rates: null,
    selectedAccount: null,
    selectedFilter: null,
    selectedMovement: null,
    showMulticashModal: false,
    showNoteModal: false,
    showRatesModal: false,
    stateAccount: [],
    statements: null,
    totalLaw19210: null,
    ...DefaultsRedux,
};
