import React from "react";

import { connect as Connect } from "react-redux";

import { CDP_CURRENCY } from "~/constants";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsStore as StoreWidget, SelectorsAction as ActionWidget } from "~/store/widget";
import Flag from "~/util/flag";

import Image from "~/components/Image";

import Style from "./ExchangeRates.rules.scss";
import Widget from "./_common/_Widget";

export const { TYPE, NAME } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const { dispatch, store: widget, getMessage, isFetching, currencies, ...rest } = props;

    React.useEffect(() => {
        dispatch(ActionWidget.listRequest({ widget }));
    }, [dispatch, widget]);

    const buyItems = currencies.map((currency) => {
        const { baseCurrencyCode, purchase } = currency;

        return (
            <div className="list-item" key={purchase}>
                {baseCurrencyCode !== CDP_CURRENCY.UI ? <h4>{purchase}</h4> : <div />}
            </div>
        );
    });

    const currencyItems = currencies.map((currency) => {
        const { targetCurrencyCode } = currency;

        return (
            <div className="list-item" key={targetCurrencyCode}>
                <figure>
                    <Image src={`${Flag.getFlag(targetCurrencyCode)}.svg`} />
                    <figcaption>{getMessage(`currency.label.${targetCurrencyCode}`)}</figcaption>
                </figure>
            </div>
        );
    });

    const sellItems = currencies.map((currency) => {
        const { sale } = currency;

        return (
            <div className="list-item" key={sale}>
                <h4>{sale}</h4>
            </div>
        );
    });

    return (
        <Widget
            {...rest}
            id={Style.ID}
            wait={isFetching}
            header-title="menu.exchangeRates"
            header-icon="exchangerates.svg">
            <div className="wrapper">
                <ul className="list">
                    <li className="list-element">
                        <h4>&nbsp;</h4>
                        {currencyItems}
                    </li>
                    <li className="list-element buy">
                        <h4 className="header-table">{getMessage(`menu.exchangeRates.buy`)}</h4>
                        {buyItems}
                    </li>
                    <li className="list-element sell">
                        <h4 className="header-table">{getMessage(`menu.exchangeRates.sell`)}</h4>
                        {sellItems}
                    </li>
                </ul>
                <small>{getMessage("widget.exchangeRates.info")}</small>
            </div>
        </Widget>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

function mapStateToProps(store, props) {
    const { store: widget } = props;
    const { data = [], isFetching } = StoreWidget.getWidget(store, widget) || {};

    return {
        currencies: (data.length && data[0].currencies) || [],
        isFetching,
        getMessage(key) {
            return StoreI18n.getMessage(store, key);
        },
    };
}

export default Connect(mapStateToProps)(Component);
