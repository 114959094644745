import { LOCATION_CHANGE } from "connected-react-router";

import { EMPTY_STR, SPACE_STR } from "~/constants";
import { MODE } from "~/constants/form";
import { TYPE as TypesBackoffice } from "~/store/backoffice";
import { shouldKeepStateInUrlScope as ShouldKeepStateInUrlScope } from "~/util/form";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            const shouldKeepStateInUrlScope = ShouldKeepStateInUrlScope(action.payload.location.pathname);

            if (shouldKeepStateInUrlScope) {
                return {
                    ...storeProp,
                    prevRoute: action.payload.location,
                    requestInfoData: PROP.defaults.requestInfoData,
                };
            }
            return {
                ...PROP.defaults,
                prevRoute: action.payload.location,
            };
        }
        case TYPE.CLEAR_FORM:
            return {
                ...storeProp,
                idPaper: EMPTY_STR,
                paper: {},
                papersList: null,
            };
        case TYPE.CLEAR_SEARCH_RESULTS:
            return {
                ...storeProp,
                papersList: null,
            };
        case TYPE.CLEAR_ON_CLASSIFICATION_CHANGE:
            return {
                ...storeProp,
                idPaper: EMPTY_STR,
                paper: {},
                papersList: null,
                debitAccountsList: [],
                previewData: {
                    ...action.previewData,
                },
            };
        case TYPE.GO_STEP_BACK:
            return {
                ...storeProp,
                mode: (storeProp.mode === MODE.PREVIEW || storeProp.mode === MODE.COMPONENT) && MODE.EDIT,
                paper: Object.keys(storeProp.paper).length ? storeProp.paper : storeProp.previewData?.initialPaper,
                prevMode: storeProp.mode === MODE.PREVIEW && MODE.PREVIEW,
            };
        case TYPE.GO_TO_COMPONENT:
            return {
                ...storeProp,
                mode: MODE.COMPONENT,
            };
        case TYPE.SEARCH_SHARES_REQUEST:
        case TYPE.BUY_SHARES_PREVIEW_REQUEST: {
            const { ticker1, ticker2, ...others } = action.previewData;
            return {
                ...storeProp,
                isFetching: true,
                previewData: {
                    ...storeProp.previewData,
                    ...others,
                    ticker: {
                        ticker1,
                        ticker2,
                    },
                },
            };
        }
        case TYPE.BUY_SHARES_PRE_REQUEST: {
            return {
                ...storeProp,
                isFetching: true,
                previewData: {
                    ...storeProp.previewData,
                    ticker: null,
                },
            };
        }
        case TYPE.BUY_SHARES_PRE_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
                papersList: action.papersList,
            };
        case TYPE.SEARCH_SHARES_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                papersList: action.papersList,
                moreLines: action.moreLines,
            };
        case TYPE.SEARCH_SHARES_FAILURE: {
            return {
                ...storeProp,
                papersList: null,
                isFetching: false,
            };
        }
        case TYPE.PAPER_ID_SELECTED:
            return {
                ...storeProp,
                fetching: false,
                idPaper: action.paper,
            };
        case TYPE.BUY_SHARES_PRE_SUCCESS: {
            const { ticker, isin } = action.paper;
            return {
                ...storeProp,
                currencyList: action.currencyList,
                debitAccountsList: action.debitAccountsList,
                expirationYearsAhead: action.expirationYearsAhead,
                isFetching: false,
                paper: action.paper,
                previewData: {
                    ...storeProp.previewData,
                    ticker: {
                        ticker1: ticker && ticker.split(SPACE_STR)[0],
                        ticker2: ticker && ticker.split(SPACE_STR)[1],
                    },
                    isin,
                },
                selectedType: storeProp.previewData.classification,
                timezoneOffset: action.timezoneOffset,
            };
        }
        case TYPE.SELL_SHARES_PRE_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.SELL_SHARES_PRE_FAILURE:
        case TYPE.SELL_SHARES_PREVIEW_FAILURE:
        case TYPE.BUY_SHARES_SEND_FORM_FAILURE:
        case TYPE.SELL_SHARES_SEND_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
            };
        case TYPE.SELL_SHARES_PRE_SUCCESS:
            return {
                ...storeProp,
                creditAccountList: action.creditAccountList,
                isFetching: false,
                paper: action.paper,
                processingSale: action.processingSale,
                sharesList: action.shares,
                timezoneOffset: action.timezoneOffset,
            };
        case TYPE.SELL_SHARES_PREVIEW_REQUEST:
            return {
                ...storeProp,
                formData: action.formData,
                isFetching: true,
                prevMode: MODE.EDIT,
            };
        case TYPE.BUY_SHARES_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                mode: MODE.PREVIEW,
                previewData: {
                    ...storeProp.previewData,
                    alias: action.alias,
                    amountToInvest: action.amountToInvest,
                    currency: action.currency,
                    limitDate: action.limitDate,
                    observations: action.observations,
                    orderCompleted: action.orderCompleted,
                    paperName: action.paperName,
                    price: action.price,
                    classification: action.classification,
                    ticker: action.ticker,
                    isin: action.isin,
                },
            };
        case TYPE.BUY_SHARES_SEND_FORM_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };
        case TYPE.SELL_SHARES_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                mode: MODE.PREVIEW,
                formData: {
                    ...storeProp.formData,
                    creditAccountAlias: action.details.creditAccountAlias,
                    currency: action.details.currency,
                    limitDate: action.details.limitDate,
                    orderCompleted: action.details.orderCompleted,
                },
            };
        case TYPE.BUY_SHARES_SEND_FORM:
        case TYPE.SELL_SHARES_SEND_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.SELL_SHARES_SEND_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };
        case TYPE.READ_TRANSACTION_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.READ_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                transaction: action.transaction,
            };
        case TYPE.SIGN_TRANSACTION_PREVIEW:
            return {
                ...storeProp,
                isFetching: false,
                mode: MODE.PREVIEW,
                formData: {
                    ...storeProp.transaction.data,
                },
            };
        case TYPE.RESTART_FLOW:
            return PROP.defaults;
        case TypesBackoffice.BACKOFFICE_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };
        default:
            return {
                ...storeProp,
            };
    }
};
