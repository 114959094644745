import React from "react";

import PropTypes from "prop-types";

import { generateId as GenID } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";
import * as UtilsNumber from "~/util/number";
import DeviceUtils from "~/util/device";


import Box from "~/components/Box";
import DataDate from "~/components/DataDate";
import DataNumber from "~/components/DataNumber";
import ExpandElement from "~/components/ExpandElement";
import GridLayout from "~/components/GridLayout";
import IndeterminateCheckbox from "~/pages/_components/IndeterminateCheckbox";

import Style from "./_PaymentItem.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        payment: PropTypes.shape({
            paymentOrder: PropTypes.string,
            netAmount: PropTypes.number,
            issueDate: PropTypes.string,
            dueDate: PropTypes.string,
            amount: PropTypes.number,
            currency: PropTypes.string,
            discountable: PropTypes.bool,
        }).isRequired,
        handleAddPayment: PropTypes.func.isRequired,
        handleRemovePayment: PropTypes.func.isRequired,
        allSelected: PropTypes.bool.isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const { payment, handleAddPayment, handleRemovePayment, isSelected } = props;
    const {
        paymentOrder,
        netAmount,
        issueDate,
        dueDate,
        amount,
        currency,
        discountable,
        rate,
        discountableAmount,
        interests,
        term,
        id,
    } = payment;

    const [checked, setChecked] = React.useState(false);
    React.useEffect(() => {
        if (discountable) {
            setChecked(isSelected);
        }
    }, [discountable, isSelected]);

    const isDesktop = DeviceUtils.isDisplayDesktop();

    return (
        <div id={Style.ID}>
            <IndeterminateCheckbox
                id={id}
                onCheckClick={handleChange}
                disabled={!discountable}
                selectedOptionsAmount={checked && discountable ? 1 : 0}
                tooltip={
                    !discountable && isDesktop && UtilsI18n.get("client.factoring.advancePaymentOrders.list.tooltip.notDiscountable")
                }
            />

            <ExpandElement>
                <GridLayout className="paymentItem" key={`paymentLines-${GenID()}`}>
                    {isDesktop && (<div>{paymentOrder}</div>)}
                    <div>
                        <DataDate value={dueDate} />
                    </div>
                    {isDesktop && (<div><DataDate value={issueDate} /></div>) }
                    {isDesktop ?
                        <Box justify="end">
                            {checked && discountable && <DataNumber value={netAmount} prefix={currency}/>}
                        </Box>
                        :
                        <Box justify="end">
                            {checked && discountable && <DataNumber value={netAmount}/>}
                        </Box>
                    }
                    {isDesktop ? 
                        <Box justify="end">
                            <DataNumber value={amount} prefix={currency}/>
                        </Box>
                        :
                        <Box justify="end">
                            <DataNumber value={amount}/>
                        </Box>
                    }
                </GridLayout>
                {discountable && (
                    <GridLayout className="detail">
                        {!isDesktop ?
                            (<div className="info-column">
                                <div>
                                    <label>{UtilsI18n.get("client.factoring.paymentOrders.list.order")}</label>
                                    {paymentOrder}
                                </div>
                                <div>
                                    <label>{UtilsI18n.get("client.factoring.paymentOrders.list.issueDate")}</label>
                                    <DataDate value={issueDate} />
                                </div>
                                <div>
                                    <label>{UtilsI18n.get("listAdvancePayments.list.detail.rate")}</label>
                                    {UtilsNumber.numberToLocaleFormat(rate, UtilsI18n.getLang())}%
                                </div>
                                <div>
                                    <label>{UtilsI18n.get("client.factoring.advancePaymentOrders.list.interests")}</label>-
                                    {interests}
                                </div>
                                <div>
                                    <label>{UtilsI18n.get("listAdvancePayments.list.detail.term")}</label>
                                    {`${term} ${UtilsI18n.get("client.factoring.advancePaymentOrders.list.days")}`}
                                </div>
                            </div>)
                        :
                            (<React.Fragment>
                                <div className="info-column">
                                    <div>
                                        <label>{UtilsI18n.get("client.factoring.advancePaymentOrders.list.date")}</label>
                                        <DataDate value={issueDate} />
                                    </div>
                                    <div>
                                        <label>{UtilsI18n.get("listAdvancePayments.list.detail.rate")}</label>
                                        {UtilsNumber.numberToLocaleFormat(rate, UtilsI18n.getLang())}%
                                    </div>
                                    <div>
                                        <label>
                                            {UtilsI18n.get("client.factoring.advancePaymentOrders.list.discoutableAmount")}
                                        </label>
                                        <DataNumber value={discountableAmount} prefix={currency} />
                                    </div>
                                    <div>
                                        <label>{UtilsI18n.get("client.factoring.advancePaymentOrders.list.advance")}</label>
                                        <DataNumber value={netAmount} prefix={currency} />
                                    </div>
                                </div>
                                <div className="info-column">
                                    <div>
                                        <label>{UtilsI18n.get("client.factoring.advancePaymentOrders.list.orderAmount")}</label>
                                        <DataNumber value={amount} prefix={currency} />
                                    </div>
                                    <div>
                                        <label>{UtilsI18n.get("client.factoring.advancePaymentOrders.list.interests")}</label>-
                                        {interests}
                                    </div>
                                    <div>
                                        <label>{UtilsI18n.get("listAdvancePayments.list.detail.term")}</label>
                                        {`${term} ${UtilsI18n.get("client.factoring.advancePaymentOrders.list.days")}`}
                                    </div>
                                </div>
                            </React.Fragment>)
                        }
                    </GridLayout>
                )}
            </ExpandElement>
        </div>
    );

    function handleChange() {
        if (discountable) {
            if (checked) {
                handleRemovePayment(payment);
            } else {
                handleAddPayment(payment);
            }
            setChecked(!checked);
        }
    }
}

Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

export default Component;
