import React from "react";

import ClassNames from "classnames";

import { Page } from "~/components/Component";

import Style from "./_Page.rules.scss";
import Namespace from "./_Page.scss";

export const { NAME, TYPE } = Namespace;
export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const { children, name, header, hasTabs, className, ...rest } = props;
    return (
        <Page {...rest} id={Style.ID} name={name} className={ClassNames(className, "page-container")}>
            {header}
            <div
                role="region"
                className={hasTabs ? "hasTabs" : null}
                data-name={Namespace.NAME_CONTENT}
                data-type={Namespace.TYPE_CONTENT}>
                {children}
            </div>
        </Page>
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
