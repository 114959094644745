import PropTypes from "prop-types";

export const Members = {};

/** Redux propTypes */
export const Types = {
    /** A function in charge of dispatching actions */
    dispatch: PropTypes.func,
};

/** Redux defaultProps */
export const Defaults = {
    dispatch: null,
};

export default {
    members: Members,
    types: Types,
    defaults: Defaults,
};
