import PropTypes from "prop-types";

import { FactoryType } from "~/util/redux";

export const NAME = "generalConditions";

export const PATH = {
    GENERAL_CONDITIONS_PRE: "/general.conditions.pre",
    GENERAL_CONDITIONS_SIGN: "/general.conditions.sign",
};

export const TYPE = FactoryType(NAME, [
    "GENERAL_CONDITIONS_PRE_REQUEST",
    "GENERAL_CONDITIONS_PRE_ERROR",
    "GENERAL_CONDITIONS_PRE_SUCCESS",

    "GENERAL_CONDITIONS_SIGN_REQUEST",
    "GENERAL_CONDITIONS_SIGN_ERROR",
    "GENERAL_CONDITIONS_SIGN_SUCCESS",

    "GENERAL_CONDITIONS_FROM_ENVIRONMENT",
    "GENERAL_CONDITIONS_FROM_LOGIN",
    "GENERAL_CONDITIONS_FETCHING",
]);

export const PROP = {};

PROP.types = {
    fetching: PropTypes.bool,
    isEnvironmentChanging: PropTypes.bool,
    body: PropTypes.string,
    latestNews: PropTypes.string,
    environment: PropTypes.int,
    rememberEnvironment: PropTypes.bool,
    suggestMigration: PropTypes.bool,
};

PROP.defaults = {
    fetching: false,
    isEnvironmentChanging: false,
    body: null,
    latestNews: null,
    environment: null,
    rememberEnvironment: null,
    suggestMigration: false,
};
