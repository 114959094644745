import React from "react";

import { connect as Connect } from "react-redux";

import { SelectorsAction as SelectorsActionSettings } from "~/store/settings";

import PersonalInformationTicket from "./PersonalInformationTicket";

export const NAME = "PersonalInformationReadTicket";

export const PROP = {
    types: {},
    defaults: {},
};
export function Component({ dispatch, match, fetching, ...rest }) {
    const { id } = match.params;
    React.useLayoutEffect(() => {
        if (id) {
            dispatch(SelectorsActionSettings.readTicketUserConfigurationRequest({ idTransaction: id }));
        }
    }, [dispatch, id, match, rest]);

    return <React.Fragment>{id && !fetching && <PersonalInformationTicket />}</React.Fragment>;
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Connect()(Component);
