import { ID_FORM, MODE } from "~/constants/form";
import { TYPE as TYPE_FORM } from "~/store/form";
import { TYPE as TYPE_TEMPLATE } from "~/store/template";
import { TYPE as TYPE_TRANSACTIONLINES } from "~/store/transactionLines";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.CLEAR_POND:
            return { ...storeProp, forcePondCleanUp: action.forcePondCleanUp };
        case TYPE.ON_FILE_PROCESS: {
            const listWithBanksName = action.data.lines.map((line) => ({
                ...line,
                creditAccountBankShortLabel: storeProp.localBanks.find((bank) => bank.value === line.creditAccountBank)
                    ?.shortLabel,
            }));

            return { ...storeProp, ...action.data, lines: listWithBanksName };
        }
        case TYPE.ON_FILE_REMOVED: {
            if (storeProp.mode === MODE.VIEW) {
                return storeProp;
            }

            return { ...PROP.defaults, localBanks: storeProp.localBanks, extraData: action.extraData };
        }
        case TYPE.ON_PAYMENT_METHOD_CHANGE:
            return PROP.defaults;
        case TYPE.SET_EXTRA_DATA:
            return { ...storeProp, extraData: action.extraData };
        case TYPE.SET_METADATA:
            return { ...storeProp, filesMetadata: action.fileMetadata };
        case TYPE_FORM.READ_TRANSACTION_SUCCESS:
        case TYPE_TEMPLATE.SET_SELECTED_TEMPLATE:
            if (action.idForm !== ID_FORM.SALARY_PAYMENT && action.idForm !== ID_FORM.SUPPLIERS_PAYMENT) {
                return {
                    ...storeProp,
                };
            }
            return PROP.defaults;
        case TYPE_FORM.SEND_FORM_SUCCESS:
            return {
                ...storeProp,
                mode: MODE.VIEW,
            };
        case TYPE.SEND_FORM_DATA_FAILURE:
            return {
                ...storeProp,
                mode: MODE.EDIT,
            };
        case TYPE_TRANSACTIONLINES.LIST_LOCAL_BANKS_SUCCESS:
            return {
                ...storeProp,
                localBanks: action.banks,
            };
        case TYPE_TRANSACTIONLINES.LIST_TRANSACTION_LINES_SUCCESS:
            return {
                ...storeProp,
                processedLines: action.totalLines,
            };
        case TYPE_FORM.CLEAN_STEPS:
        case TYPE_TRANSACTIONLINES.CLEAN_ALL:
            return PROP.defaults;
        default:
            return storeProp;
    }
};
