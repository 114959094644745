import { LOCATION_CHANGE } from "connected-react-router";

import { EMPTY_STR } from "~/constants";
import { TYPE as TypesBackoffice } from "~/store/backoffice";
import UtilLodash from "~/util/lodash";
import { roundToTwoDecimals as RoundToTwoDecimals } from "~/util/number";

import { TYPE, FXSTATE as STORE_FXSTATE, PROP, OPERATIONS as STORE_OPERATIONS, LAST_CHANGED_INPUT } from "./_consts";

const exchangeValueChanged = (selectedCurrency, ratesProp) => {
    const rates = ratesProp.map((quote) => ({
        value: quote,
        label: quote.currency1Label,
        label2: quote.currency2Label,
        currency1: quote.currency1,
        currency2: quote.currency2,
    }));
    let i;
    let result = true;
    for (i = 0; i < rates.length; i += 1) {
        // Me fijo si cambio la cotizacion que tengo seleccionada
        if (UtilLodash.isEqual(rates[i], selectedCurrency)) {
            // Si encuentro una igual devuelvo false porque no cambio
            result = false;
            break;
        }
    }
    return result;
};

const hasError = (errors) => {
    const newErrors = [];
    let newValue = EMPTY_STR;
    Object.keys(errors).forEach((key) => {
        const keyPlain = key.replace(/^_/, EMPTY_STR);
        newValue = keyPlain.concat("Touched");
        newErrors[keyPlain] = errors[key];
        newErrors[newValue] = false;
    });

    return newErrors;
};

export default (storeProp = PROP.defaults, action) => {
    let debitAccountList = [];
    let creditAccountList = [];
    let areAccountsSelectable;
    let updatedRate;
    let currencyRate;
    let debitAmount;
    let creditAmount;
    let debitAccounts;
    let creditAccounts;
    let currency1Label;
    let currency2Label;
    let debitValue;
    let creditValue;
    let lastTypedInput;
    let isExchangeValueChanged;
    let processingTransactionsList;

    switch (action.type) {
        case TYPE.SUCCESS_FX:
        case TYPE.CHANGE_CURRENCY:
            if (action.type === TYPE.CHANGE_CURRENCY) {
                // En caso de que cuando seleccionemos una moneda de operacion de la cual no poseamos cuenta.
                if (action.selectedCurrency) {
                    const { currency1, currency2 } = action.selectedCurrency.value;

                    let debitCurrency;
                    let creditCurrency;

                    if (storeProp.operation === STORE_OPERATIONS.BUYING) {
                        debitCurrency = currency1;
                        creditCurrency = currency2;
                    } else {
                        debitCurrency = currency2;
                        creditCurrency = currency1;
                    }

                    debitAccountList = storeProp.formData.accountList[debitCurrency];
                    creditAccountList = storeProp.formData.accountList[creditCurrency];
                }
            } else if (action.type === TYPE.SUCCESS_FX) {
                // En caso no tengamos cuentas en las cuales operar
                debitAccountList = action.accountList[action.quotes[0].currency1];
                creditAccountList = action.accountList[action.quotes[0].currency2];
                processingTransactionsList = action.processingTransactionsList;
            }

            if (debitAccountList && debitAccountList.length && creditAccountList && creditAccountList.length) {
                areAccountsSelectable = true;
            } else {
                areAccountsSelectable = false;
            }
            break;
        case TYPE.SUCCESS_COTIZACION:
            if (storeProp.formData.selectedCurrency) {
                const { currency1, currency2 } = storeProp.formData.selectedCurrency;
                const filteredRates = action.rates.filter(
                    (rate) => rate.currency1 === currency1 && rate.currency2 === currency2,
                );
                updatedRate = filteredRates.length > 0 ? filteredRates[0] : null;
                isExchangeValueChanged =
                    storeProp.formData.quotes !== null && action.rates !== null
                        ? exchangeValueChanged(storeProp.formData.selectedCurrency, action.rates)
                        : false;
            }
            break;
        case TYPE.CHANGE_AMOUNT: {
            const precision = storeProp.formData.amountDecimals;

            if (storeProp.operation === STORE_OPERATIONS.BUYING) {
                currencyRate = storeProp.formData.buyingRate;
                if (action.lastTypedInput === LAST_CHANGED_INPUT.DEBIT) {
                    debitAmount = action.lastTypedValue;
                    creditAmount = action.lastTypedValue ? action.lastTypedValue / currencyRate : null;
                } else {
                    debitAmount = action.lastTypedValue ? action.lastTypedValue * currencyRate : null;
                    creditAmount = action.lastTypedValue;
                }
            } else {
                currencyRate = storeProp.formData.sellingRate;

                if (action.lastTypedInput === LAST_CHANGED_INPUT.DEBIT) {
                    debitAmount = action.lastTypedValue;
                    creditAmount = action.lastTypedValue ? action.lastTypedValue * currencyRate : null;
                } else {
                    debitAmount = action.lastTypedValue ? action.lastTypedValue / currencyRate : null;
                    creditAmount = action.lastTypedValue;
                }
            }
            creditAmount = creditAmount ? parseFloat(RoundToTwoDecimals(creditAmount, precision)) : creditAmount;
            debitAmount = debitAmount ? parseFloat(RoundToTwoDecimals(debitAmount, precision)) : debitAmount;
            break;
        }

        case TYPE.CHANGE_OPERATION:
            if (storeProp.operation !== action.operation) {
                const {
                    amountDecimals,
                    creditValue: storeCreditValue,
                    debitValue: storeDebitValue,
                    lastTypedValue,
                    lastTypedInput: storeLastTypedInput,
                    buyingRate,
                    sellingRate,
                    accountList,
                    currency1,
                    currency2,
                } = storeProp.formData;
                const precision = amountDecimals;
                currency1Label = storeProp.formData.selectedCurrency.value.currency1Label;
                currency2Label = storeProp.formData.selectedCurrency.value.currency2Label;
                if (action.operation === STORE_OPERATIONS.BUYING) {
                    currencyRate = buyingRate;

                    debitAccounts = accountList[currency1];
                    creditAccounts = accountList[currency2];

                    debitValue = storeProp.operation === STORE_OPERATIONS.BUYING ? storeDebitValue : storeCreditValue;
                    creditValue = storeProp.operation === STORE_OPERATIONS.BUYING ? storeCreditValue : storeDebitValue;

                    if (storeLastTypedInput === LAST_CHANGED_INPUT.DEBIT) {
                        debitAmount = lastTypedValue
                            ? parseFloat(RoundToTwoDecimals(lastTypedValue * currencyRate, precision))
                            : null;
                        creditAmount = lastTypedValue;
                        lastTypedInput = LAST_CHANGED_INPUT.CREDIT;
                    }

                    if (storeLastTypedInput === LAST_CHANGED_INPUT.CREDIT) {
                        debitAmount = lastTypedValue;
                        creditAmount = lastTypedValue
                            ? parseFloat(RoundToTwoDecimals(lastTypedValue / currencyRate, precision))
                            : null;
                        lastTypedInput = LAST_CHANGED_INPUT.DEBIT;
                    }
                } else {
                    currencyRate = sellingRate;
                    debitAccounts = accountList[currency2];
                    creditAccounts = accountList[currency1];
                    debitValue = storeProp.operation === STORE_OPERATIONS.SELLING ? storeDebitValue : storeCreditValue;
                    creditValue = storeProp.operation === STORE_OPERATIONS.SELLING ? storeCreditValue : storeDebitValue;

                    if (storeLastTypedInput === LAST_CHANGED_INPUT.DEBIT) {
                        debitAmount = lastTypedValue
                            ? parseFloat(RoundToTwoDecimals(lastTypedValue / currencyRate, precision))
                            : null;
                        creditAmount = lastTypedValue;
                        lastTypedInput = LAST_CHANGED_INPUT.CREDIT;
                    }

                    if (storeLastTypedInput === LAST_CHANGED_INPUT.CREDIT) {
                        debitAmount = lastTypedValue;
                        creditAmount = lastTypedValue
                            ? parseFloat(RoundToTwoDecimals(lastTypedValue * currencyRate, precision))
                            : null;
                        lastTypedInput = LAST_CHANGED_INPUT.DEBIT;
                    }
                }

                return {
                    ...storeProp,
                    formData: {
                        ...storeProp.formData,
                        debitAccounts,
                        creditAccounts,
                        debitValue,
                        creditValue,
                        currency1Label,
                        currency2Label,
                        debitAmount,
                        creditAmount,
                        lastTypedInput,
                    },
                    operation: action.operation,
                    quotation:
                        action.operation === STORE_OPERATIONS.BUYING
                            ? storeProp.formData.buyingRateClient
                            : storeProp.formData.sellingRateClient,
                };
            }
            // Si no hubo cambios, retorno el store tal cual como esta
            return {
                ...storeProp,
            };
        default:
            break;
    }

    switch (action.type) {
        case TYPE.REQUEST_FX:
            return {
                ...storeProp,
                pollCotizacion: true,
                fetching: true,
                fxState: STORE_FXSTATE.INITIALOADING,
                exchangeValueChanged: false,
                rateChange: false, // se inicializan los valores en false
                sendData: { ...PROP.defaultSendData },
                previewData: {
                    ...PROP.defaultsPreviewData,
                    token: EMPTY_STR,
                },
            };
        case TYPE.REQUEST_PREVIEW_FX:
            return {
                ...storeProp,
                fetching: true,
                exchangeValueChanged: false,
                rateChange: false, // se inicializan los valores en false
                sendData: { ...PROP.defaultSendData },
                previewData: {
                    ...PROP.defaultsPreviewData,
                    token: EMPTY_STR,
                },
                currencyChanged: false,
            };
        case TYPE.ERROR_SEND_FX:
            return {
                ...storeProp,
                fetching: false,
                errors: hasError(action.data),
                renderFormWaiter: false,
            };
        case TYPE.ERROR_PREVIEW_FX:
            return {
                ...storeProp,
                errors: hasError(action.data),
                // se fija si el error que devolvio el preview es que
                // la cotizacion ha cambiado y asi borrar los montos.
                currencyChanged: hasError(action.data).currencyChanged,
                fetching: false,
                fxState: STORE_FXSTATE.FORM,
                sendData: { ...PROP.defaultSendData },
                previewData: {
                    ...PROP.defaultsPreviewData,
                    token: EMPTY_STR,
                },
                requestRate: true,
                pollCotizacion: true,
            };

        case TYPE.REQUEST_SEND_FX:
            return {
                ...storeProp,
                fetching: true,
                exchangeValueChanged: false,
                rateChange: false, // se inicializan los valores en false
                sendData: { ...PROP.defaultSendData },
                previewData: {
                    ...storeProp.previewData,
                    token: EMPTY_STR,
                },
                renderFormWaiter: false,
            };
        case TYPE.HISTORICAL_RATES_REQUEST:
            return {
                ...storeProp,
                fetchingHistoricalRates: true,
            };
        case TYPE.HISTORICAL_RATES_FAILURE:
            return {
                ...storeProp,
                fetchingHistoricalRates: false,
            };
        case TYPE.HISTORICAL_RATES_SUCCESS:
            return {
                ...storeProp,
                historicalRates: action.historicalRates,
                fetchingHistoricalRates: false,
            };

        case TYPE.IS_TOUCHED: // actualizo si se "toco" algun field con error
            return {
                ...storeProp,
                errors: action.errors,
            };
        case TYPE.RETURN_TO_FORM:
            return {
                ...storeProp,
                fxState: STORE_FXSTATE.FORM,
                requestRate: true,
                fetching: false,
                pollCotizacion: true,
            };
        case TYPE.SUCCESS_PREVIEW_FX:
            return {
                ...storeProp,
                fxState: STORE_FXSTATE.PREVIEW,
                sendData: { ...PROP.defaultSendData },
                previewData: {
                    ...action.data,
                    token: EMPTY_STR,
                },
                fetching: false,
                errors: null,
            };
        case TYPE.SUCCESS_SEND_FX:
            return {
                ...storeProp,
                sendData: {
                    transaction: action.transaction,
                    previewData: storeProp.previewData, // TODO: ver si es necesario
                },
                previewData: {
                    ...storeProp.previewData,
                    token: EMPTY_STR,
                },
                fxState: STORE_FXSTATE.SEND,
                fetching: false,
                errors: null,
                renderFormWaiter: false,
            };
        case TYPE.SUCCESS_FX: {
            const firstQuote = action.quotes[0];

            let debValue = null;
            let debLabel = null;
            let debAlias = null;
            let debBalance = null;
            if (debitAccountList && debitAccountList.length > 0) {
                debValue = debitAccountList[0].value;
                debLabel = debitAccountList[0].label;
                debAlias = debitAccountList[0].alias;
                debBalance = debitAccountList[0].balance;
            }

            let credValue = null;
            let credLabel = null;
            let credAlias = null;
            let credBalance = null;

            if (creditAccountList && creditAccountList.length > 0) {
                credValue = creditAccountList[0].value;
                credLabel = creditAccountList[0].label;
                credAlias = creditAccountList[0].alias;
                credBalance = creditAccountList[0].balance;
            }

            return {
                ...storeProp,
                formData: {
                    accountList: action.accountList,
                    quotes: action.quotes,
                    currencyOptions: action.quotes.map((quote) => ({
                        value: quote,
                        label: quote.currency1Label,
                        label2: quote.currency2Label,
                        currency1: quote.currency1,
                        currency2: quote.currency2,
                    })),
                    selectedCurrency: action.quotes.map((quote) => ({
                        value: quote,
                        label: quote.currency1Label,
                        label2: quote.currency2Label,
                        currency1: quote.currency1,
                        currency2: quote.currency2,
                    }))[0],
                    areFXAccountSelectAble: areAccountsSelectable,
                    buyingRate: firstQuote.buyingRate,
                    sellingRate: firstQuote.sellingRate,
                    buyingRateClient: firstQuote.buyingRateClient,
                    sellingRateClient: firstQuote.sellingRateClient,
                    currency1Label: firstQuote.currency1Label,
                    currency2Label: firstQuote.currency2Label,
                    debitAccounts: action.accountList[firstQuote.currency1],
                    creditAccounts: action.accountList[firstQuote.currency2],
                    debitValue: {
                        value: debValue,
                        label: debLabel,
                        accountAlias: debAlias,
                        accountBalance: debBalance,
                        isFrequentDestination: false,
                    },
                    creditValue: {
                        value: credValue,
                        label: credLabel,
                        accountAlias: credAlias,
                        accountBalance: credBalance,
                        isFrequentDestination: false,
                    },
                    currency1: firstQuote.currency1,
                    currency2: firstQuote.currency2,
                    // TODO: esto deberia ir fuera del quote, hay que cambiar el response del api
                    decimalSeparator: firstQuote.decimalSeparator,
                    // TODO: esto deberia ir fuera del quote, hay que cambiar el response del api
                    thousandsSeparator: firstQuote.thousandsSeparator,
                    amountDecimals: firstQuote.amountDecimals,
                    currencyLabel: firstQuote.currency2Label,
                    debitAmount: null,
                    creditAmount: null,
                    lastTypedValue: null,
                    lastTypedInput: null,
                },
                processingTransactionsList: action.processingTransactionsList,
                operation: STORE_OPERATIONS.BUYING,
                quotation: firstQuote.buyingRateClient,
                fxState: STORE_FXSTATE.FORM,
                sendData: { ...PROP.defaultSendData },
                previewData: {
                    ...PROP.defaultPreviewData,
                    token: "",
                },
                fetching: false,
                errors: null,
            };
        }
        case TYPE.CHANGE_OPERATION_SELLING:
            return {
                ...storeProp,
                formData: {
                    ...storeProp.formData,
                    debitAccounts: storeProp.formData.accountList[storeProp.formData.currency2],
                    creditAccounts: storeProp.formData.accountList[storeProp.formData.currency1],
                    debitValue:
                        storeProp.operation === STORE_OPERATIONS.SELLING
                            ? storeProp.formData.debitValue
                            : storeProp.formData.creditValue, // Chequeo si se llama varias veces al Yo Vendo
                    creditValue:
                        storeProp.operation === STORE_OPERATIONS.SELLING
                            ? storeProp.formData.creditValue
                            : storeProp.formData.debitValue,
                    decimalSeparator: storeProp.formData.selectedCurrency.value.decimalSeparator,
                    thousandsSeparator: storeProp.formData.selectedCurrency.value.thousandsSeparator,
                    amountDecimals: storeProp.formData.selectedCurrency.value.amountDecimals,
                    currency1Label: storeProp.formData.selectedCurrency.value.currency2Label,
                    currency2Label: storeProp.formData.selectedCurrency.value.currency1Label,
                    debitAmount: action.debitAmount,
                    creditAmount: action.creditAmount,
                },
                operation: action.operation,
                quotation:
                    action.operation === STORE_OPERATIONS.BUYING
                        ? storeProp.formData.buyingRateClient
                        : storeProp.formData.sellingRateClient,
            };
        case TYPE.CHANGE_OPERATION_BUYING:
            return {
                ...storeProp,
                formData: {
                    ...storeProp.formData,
                    debitAccounts: storeProp.formData.accountList[storeProp.formData.currency1],
                    creditAccounts: storeProp.formData.accountList[storeProp.formData.currency2],
                    debitValue:
                        storeProp.operation === STORE_OPERATIONS.BUYING
                            ? storeProp.formData.debitValue
                            : storeProp.formData.creditValue, // Chequeo si se llama varias veces al Yo Compro
                    creditValue:
                        storeProp.operation === STORE_OPERATIONS.BUYING
                            ? storeProp.formData.creditValue
                            : storeProp.formData.debitValue,
                    decimalSeparator: storeProp.formData.selectedCurrency.value.decimalSeparator,
                    thousandsSeparator: storeProp.formData.selectedCurrency.value.thousandsSeparator,
                    amountDecimals: storeProp.formData.selectedCurrency.value.amountDecimals,
                    currency1Label: storeProp.formData.selectedCurrency.value.currency1Label,
                    currency2Label: storeProp.formData.selectedCurrency.value.currency2Label,
                    debitAmount: action.debitAmount,
                    creditAmount: action.creditAmount,
                },
                operation: action.operation,
                quotation:
                    action.operation === STORE_OPERATIONS.BUYING
                        ? storeProp.formData.buyingRateClient
                        : storeProp.formData.sellingRateClient,
            };
        case TYPE.CHANGE_DEBIT_ACCOUNT:
            return {
                ...storeProp,
                formData: {
                    ...storeProp.formData,
                    debitValue: {
                        value: action.value,
                        accountAlias: action.accountAlias,
                        accountBalance: action.accountBalance,
                        label: action.accountAlias,
                        isFrequentDestination: false,
                    },
                },
            };

        case TYPE.CHANGE_CREDIT_ACCOUNT:
            return {
                ...storeProp,
                formData: {
                    ...storeProp.formData,
                    creditValue: {
                        value: action.value,
                        accountAlias: action.accountAlias,
                        accountBalance: action.accountBalance,
                        label: action.accountAlias,
                        isFrequentDestination: false,
                    },
                },
            };
        case TYPE.CHANGE_AMOUNT:
            return {
                ...storeProp,
                formData: {
                    ...storeProp.formData,
                    debitAmount,
                    creditAmount,
                    lastTypedInput: action.lastTypedInput,
                    lastTypedValue: action.lastTypedValue,
                },
                exchangeValueChanged: false,
                currencyChanged: false,
            };
        case TYPE.CHANGE_CURRENCY:
            return {
                ...storeProp,
                formData: {
                    ...storeProp.formData,
                    selectedCurrency: action.selectedCurrency,
                    buyingRate: action.selectedCurrency.value.buyingRate,
                    sellingRate: action.selectedCurrency.value.sellingRate,
                    buyingRateClient: action.selectedCurrency.value.buyingRateClient,
                    sellingRateClient: action.selectedCurrency.value.sellingRateClient,
                    currency1Label: action.selectedCurrency.value.currency1Label,
                    currency2Label: action.selectedCurrency.value.currency2Label,
                    debitAccounts:
                        storeProp.operation === STORE_OPERATIONS.BUYING
                            ? storeProp.formData.accountList[action.selectedCurrency.value.currency1]
                            : storeProp.formData.accountList[action.selectedCurrency.value.currency2],
                    creditAccounts:
                        storeProp.operation === STORE_OPERATIONS.BUYING
                            ? storeProp.formData.accountList[action.selectedCurrency.value.currency2]
                            : storeProp.formData.accountList[action.selectedCurrency.value.currency1],
                    debitValue: {
                        value: debitAccountList && debitAccountList.length > 0 ? debitAccountList[0].value : null,
                        accountAlias:
                            debitAccountList && debitAccountList.length > 0 ? debitAccountList[0].alias : null,
                        label: debitAccountList && debitAccountList.length > 0 ? debitAccountList[0].label : null,
                        isFrequentDestination: false,
                        accountBalance:
                            debitAccountList && debitAccountList.length > 0 ? debitAccountList[0].balance : null,
                    },
                    creditValue: {
                        value: creditAccountList && creditAccountList.length > 0 ? creditAccountList[0].value : null,
                        accountAlias:
                            creditAccountList && creditAccountList.length > 0 ? creditAccountList[0].alias : null,
                        label: creditAccountList && creditAccountList.length > 0 ? creditAccountList[0].label : null,
                        accountBalance:
                            creditAccountList && creditAccountList.length > 0 ? creditAccountList[0].balance : null,
                        isFrequentDestination: false,
                    },
                    areFXAccountSelectAble: areAccountsSelectable,
                    currency1: action.selectedCurrency.value.currency1,
                    currency2: action.selectedCurrency.value.currency2,
                    decimalSeparator: action.selectedCurrency.value.decimalSeparator,
                    thousandsSeparator: action.selectedCurrency.value.thousandsSeparator,
                    amountDecimals: action.selectedCurrency.value.amountDecimals,
                    currencyLabel: action.selectedCurrency.value.currency2Label,
                    debitAmount: null,
                    creditAmount: null,
                    lastTypedValue: null,
                    lastTypedInput: null,
                },
            };
        case TYPE.REQUEST_COTIZACION:
            return {
                ...storeProp,
                fetching: true,
                requestRate: false,
            };

        case TYPE.SUCCESS_COTIZACION:
            return {
                ...storeProp,
                rates: action.rates,
                // Si cambio la cotizaciones
                exchangeValueChanged: isExchangeValueChanged,
                // Si se agrego o se quito alguna cotizacion
                rateChange:
                    storeProp.formData.quotes !== null && action.rates !== null && action.rates
                        ? storeProp.formData.quotes.length !== action.rates.length
                        : false,
                fetching: false,
                formData: {
                    ...storeProp.formData,
                    quotes: action.rates,
                    buyingRate: updatedRate && updatedRate.buyingRate ? updatedRate.buyingRate : null,
                    buyingRateClient: updatedRate && updatedRate.buyingRateClient ? updatedRate.buyingRateClient : null,
                    currency1: updatedRate && updatedRate.currency1 ? updatedRate.currency1 : null,
                    currency1Label: updatedRate && updatedRate.currency1Label ? updatedRate.currency1Label : null,
                    currency2: updatedRate && updatedRate.currency2 ? updatedRate.currency2 : null,
                    currency2Label: updatedRate && updatedRate.currency2Label ? updatedRate.currency2Label : null,
                    decimalSeparator: updatedRate && updatedRate.decimalSeparator ? updatedRate.decimalSeparator : null,
                    amountDecimals: updatedRate && updatedRate.amountDecimals ? updatedRate.amountDecimals : null,
                    decimalsToShow: updatedRate && updatedRate.decimalsToShow ? updatedRate.decimalsToShow : null,
                    sellingRate: updatedRate && updatedRate.sellingRate ? updatedRate.sellingRate : null,
                    sellingRateClient:
                        updatedRate && updatedRate.sellingRateClient ? updatedRate.sellingRateClient : null,
                    thousandsSeparator:
                        updatedRate && updatedRate.thousandsSeparator ? updatedRate.thousandsSeparator : null,
                    selectedCurrency: isExchangeValueChanged
                        ? {
                              value: updatedRate,
                              label: updatedRate.currency1Label,
                              label2: updatedRate.currency2Label,
                              currency1: updatedRate.currency1,
                              currency2: updatedRate.currency2,
                          }
                        : storeProp.formData.selectedCurrency,
                },
                quotation:
                    action.operation === STORE_OPERATIONS.BUYING
                        ? storeProp.formData.buyingRateClient
                        : storeProp.formData.sellingRateClient,
            };
        case TYPE.STOP_POLL_COTIZATION:
        case LOCATION_CHANGE:
            // Cada vez que se cambia de location se deshabilita el polleo,
            // sino quedaba infinitamente
            return {
                ...storeProp,
                pollCotizacion: false,
            };
        case TYPE.CLOSE_PAGE:
            return {
                ...PROP.defaults,
                pollCotizacion: false,
            };
        case TYPE.ERROR_PDF:
        case TYPE.SUCCESS_PDF:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.DISCLAIMER:
        case TYPE.READ_FX_TICKET_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.RENDER_FORM_WAITER:
            return {
                ...storeProp,
                renderFormWaiter: true,
            };
        case TYPE.READ_FX_TICKET_SUCCESS:
            return {
                ...storeProp,
                transactionData: action.transaction,
                fetching: false,
            };
        case TYPE.CLEAN_UP:
            return {
                ...PROP.defaults,
                stepTransaction: storeProp.stepTransaction,
            };

        case TYPE.SET_SHOW_BULLETIN_MODAL:
            return { ...storeProp, showBulletinModal: action.showBulletinModal };
        case TYPE.BULLETIN_LIST_REQUEST:
            return { ...storeProp, isFetchingBulletin: true, stepTransaction: true };
        case TYPE.BULLETIN_LIST_FAILURE:
            return { ...storeProp, isFetchingBulletin: false };
        case TYPE.BULLETIN_LIST_SUCCESS:
            return {
                ...storeProp,
                isFetchingBulletin: false,
                bulletinList: action.bulletins,
            };
        case TypesBackoffice.BACKOFFICE_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                transactionData: action.transaction,
            };
        default:
            return storeProp;
    }
};
