import React from "react";

import { push as Push } from "connected-react-router";
import { connect as Connect } from "react-redux";
import { Route, useHistory, matchPath } from "react-router-dom";

import { SelectorsStore as StoreSession } from "~/store/session";

export const NAME = "RouteValidation";

export const PROP = {
    types: {},
    defaults: {},
};
export function Component(props) {
    const { activeEnvironment, hasPermission, path, dispatch } = props;
    const {
        location: { pathname },
    } = useHistory();

    const { permissions = {} } = activeEnvironment;

    const matchPaths = matchPath(pathname, {
        path,
    });
    if (matchPaths) {
        if (permissions[hasPermission]) {
            return <Route {...props} />;
        }
        dispatch(Push("/desktop"));
    }

    return null;
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;
const mapStateToProps = (store) => ({
    activeEnvironment: StoreSession.getActiveEnvironment(store) || {},
});
export default Connect(mapStateToProps)(Component);
