import React from "react";

import PropTypes from "prop-types";
import { Col, Grid, Row } from "react-bootstrap";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/Simple";
import {
    SelectorsAction as SelectorsActionFingerPrint,
    SelectorsStore as SelectorsStoreFingerPrint,
} from "~/store/fingerprint";

import Button from "~/components/Button";
import I18n from "~/components/I18n";

export const NAME = "FingerprintDeleteConfirmation";

export const PROP = {
    types: {
        device: PropTypes.shape({
            deviceModel: PropTypes.string,
            idSession: PropTypes.string,
        }).isRequired,
        dispatch: PropTypes.func.isRequired,
        fetching: PropTypes.bool.isRequired,
    },
    defaults: {},
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    deleteDevice = (idSession) => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionFingerPrint.fingerprintDeleteDevice({ idSession }));
    };

    renderContent = () => {
        const { device } = this.props;

        return (
            <form className="above-the-fold">
                <section className="container--layout flex-grow align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-lead">
                                    <strong>
                                        <I18n id="settings.fingerprintConfiguration.deleteConfirmation.action" />
                                    </strong>
                                </p>
                            </Col>
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-lead">
                                    <I18n
                                        id={`devices.apple.identifier.${device.deviceModel}`}
                                        defaultValue={device.deviceModel}
                                    />
                                </p>
                            </Col>
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-lead">
                                    <I18n id="settings.fingerprintConfiguration.deleteConfirmation.confirmWarning" />
                                </p>
                            </Col>
                            <Row>
                                <Col sm={12} className="col col-12">
                                    <Button
                                        label="settings.fingerprintConfiguration.deleteConfirmation.confirm"
                                        bsStyle="primary"
                                        onClick={() => this.deleteDevice(device.idSession)}
                                    />
                                </Col>
                            </Row>
                        </Row>
                    </Grid>
                </section>
            </form>
        );
    };

    render() {
        const { fetching } = this.props;

        return (
            <React.Fragment>
                <Container
                    name={NAME}
                    head-title="settings.biometrics"
                    wait={fetching}
                    scopeToShowNotification={SCOPE.FINGER_PRINT_CONFIGURATION}>
                    {this.renderContent()}
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: SelectorsStoreFingerPrint.getFetching(state),
    device: SelectorsStoreFingerPrint.getDevice(state),
});

export default Connect(mapStateToProps)(Component);
