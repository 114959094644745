import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE, HSBC } from "~/constants";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction } from "./_selectors";
import { downloadPdf as DownloadPdf } from "~/util/download";

export default [
    TakeLatest(TYPE.DOWNLOAD_LINE_PDF, downloadLinePDF),
    TakeLatest(TYPE.IMPORT_PAYROLL_REQUEST, importPayrollRequest),
    TakeLatest(TYPE.LIST_LOCAL_BANKS_REQUEST, listLocalBanksRequest),
    TakeLatest(TYPE.LIST_TRANSACTION_LINES_REQUEST, listTransactionLinesRequest),
    TakeLatest(TYPE.LIST_TRANSACTION_LINES_STATUS_REQUEST, listTransactionLinesStatusRequest),
];

function* importPayrollRequest(props) {
    const {
        type,
        data: { message, data: transactionLinesData },
    } = yield Call(SelectorsMiddleware.importPayroll, { props });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.FORM],
            }),
        );
        yield Put(SelectorsAction.importPayrollFailure());
    } else {
        if (transactionLinesData.lines.length) {
            let initialLineNumber = 0;
            transactionLinesData.lines = Array.from(transactionLinesData.lines, (line) => {
                initialLineNumber += 1;
                return {
                    ...line,
                    creditAccountBank: line.creditAccountBank || HSBC,
                    lineNumber: initialLineNumber,
                    payroll: 1,
                    type: line.type || "INTERNAL_TRANSFER",
                };
            });
        }
        yield Put(SelectorsAction.importPayrollSuccess(transactionLinesData));
    }
}

function* listLocalBanksRequest(props) {
    const {
        type,
        data: { message, data: banks },
    } = yield Call(SelectorsMiddleware.listLocalBanks, props);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.FORM],
            }),
        );
        yield Put(SelectorsAction.listLocalBanksFailure());
    } else {
        yield Put(SelectorsAction.listLocalBanksSuccess(banks));
    }
}

function* listTransactionLinesRequest(props) {
    const { filters, data } = props;

    const {
        type,
        data: { message, data: transactionLinesData },
    } = yield Call(SelectorsMiddleware.listTransactionLines, { filters, ...data });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.FORM],
            }),
        );
        yield Put(SelectorsAction.listTransactionLinesFailure());
    } else {
        yield Put(SelectorsAction.listTransactionLinesSuccess(transactionLinesData));
    }
}

function* listTransactionLinesStatusRequest(props) {
    const {
        type,
        data: { message, data: transactionLinesStatus },
    } = yield Call(SelectorsMiddleware.listTransactionLinesStatus, props);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.FORM],
            }),
        );
        yield Put(SelectorsAction.listTransactionLinesStatusFailure());
    } else {
        const { transactionLinesStatus: result } = transactionLinesStatus;

        // This control is meant for including scheduled transaction as well as sign-pending and finished ones
        yield Put(
            SelectorsAction.listTransactionLinesStatusSuccess(
                !result || (result && result.length === 0) ? undefined : transactionLinesStatus,
            ),
        );
    }
}

function* downloadLinePDF(props) {
    const { lineNumber, idTransactionToRead } = props;

    const { type, data } = yield Call(SelectorsMiddleware.downloadLinePDF, {
        lineNumber,
        idTransactionToRead,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.FORM],
            }),
        );
    } else {
        const { content, fileName } = data.data;
        DownloadPdf(fileName, content);
    }
}
