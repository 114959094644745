import React from "react";

import { push as Push } from "connected-react-router";

import { NO_DATA } from "~/constants";

import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";
import FieldReadOnly from "~/pages/_components/fields/FieldReadOnly";

export const NAME = "InfoUser";

export const PROP = {
    types: {},
    defaults: {},
};

export function Component({ data, mobilePhone, dispatch, wait }) {
    const { name, document, gender, birthDate, email, documentType, documentCountry } = data;
    return (
        <section className="containerinformation">
            {!wait && (
                <React.Fragment>
                    <div className="containerinformation--data">
                        <div className="containerinformation--data__name  containerinformation--data__spacing">
                            <span>{name}</span>
                        </div>
                        <div className="content--document containerinformation--data__spacing">
                            <span className="data-label">
                                <I18n id="change.PersonalData.document.label" />
                            </span>
                            <div>
                                <span>{documentType}</span>
                                <span>{`(${documentCountry}) N° `}</span>
                            </div>
                            <div>
                                <span>{document}</span>
                            </div>

                            <div className="document--information">
                                <span className="data-info ">
                                    <I18n id="change.PersonalData.document.infromation.label" />
                                </span>
                            </div>
                        </div>

                        <FieldReadOnly
                            value={gender || NO_DATA}
                            label="change.PersonalData.gender.label"
                            className="containerinformation--data__spacing"
                        />

                        <FieldReadOnly
                            value={birthDate || NO_DATA}
                            label="change.PersonalData.birthdate.label"
                            className="containerinformation--data__spacing"
                        />

                        <div className="containerinformation--data__email containerinformation--data__spacing no-pb">
                            <FieldReadOnly value={email || NO_DATA} label="change.PersonalData.email.label" />

                            <Button type="button" size="sm" onClick={() => dispatch(Push("/settings/changeEmail"))}>
                                <I18n id="change.PersonalData.email.label.button" />
                            </Button>
                        </div>

                        <div className="containerinformation--data__phone containerinformation--data__spacing ">
                            <FieldReadOnly
                                value={mobilePhone || NO_DATA}
                                label="change.PersonalData.mobilePhone.label"
                            />

                            <Button
                                type="button"
                                size="sm"
                                onClick={() => dispatch(Push("/settings/changeMobilePhone"))}>
                                <I18n id="change.PersonalData.mobilePhone.button" />
                            </Button>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </section>
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;
export default React.memo(Component);
