import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { DATA_NOT_AVAILABLE, EMPTY_STR, MILLION, SPACE_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import * as I18nUtil from "~/util/i18n";
import { countDecimalPlaces as CountDecimalPlaces, numberFormat as NumberFormatUtil } from "~/util/number";

import I18n from "~/components/I18n";
import NumberFormatInput from "~/components/NumberFormatInput";

export const NAME = "FormattedRate";

export const PROP = {
    types: {
        lang: PropTypes.string.isRequired,
        className: PropTypes.string,
        firstCurrency: PropTypes.string,
        secondCurrency: PropTypes.string,
        quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        showAbbreviature: PropTypes.bool,
        frequency: PropTypes.string,
        maximumDecimals: PropTypes.number,
        minimumDecimals: PropTypes.number,
        quatityNote: PropTypes.string,
        noStyle: PropTypes.bool,
    },
    defaults: {
        className: "data-amount",
        showAbbreviature: false,
        maximumDecimals: 0,
        minimumDecimals: 0,
        frequency: null,
        quantity: null,
        firstCurrency: null,
        secondCurrency: null,
        quatityNote: null,
        noStyle: false,
    },
};

export function Component(props) {
    const {
        className,
        decimalUI,
        firstCurrency,
        secondCurrency,
        frequency,
        quantity,
        maximumDecimals,
        minimumDecimals,
        lang,
        showAbbreviature,
        quatityNote,
        noStyle,
    } = props;

    const { decimalSeparator, thousandSeparator } = NumberFormatUtil(lang);

    const floatQuantity = parseFloat(quantity);
    if (!firstCurrency || !secondCurrency || Number.isNaN(floatQuantity)) {
        return null;
    }

    if (
        firstCurrency === DATA_NOT_AVAILABLE ||
        secondCurrency === DATA_NOT_AVAILABLE ||
        quantity === DATA_NOT_AVAILABLE
    ) {
        return <I18n id="data.notAvailable.short" />;
    }

    const amount =
        showAbbreviature && quantity >= MILLION
            ? {
                  quantity: quantity / MILLION,
                  abbreviature: I18nUtil.get("formattedAmount.millionAbbreviature"),
              }
            : {
                  quantity,
                  abbreviature: EMPTY_STR,
              };

    let decimalPlaces;
    let decimalScale;
    if (amount.abbreviature.length > 0) {
        decimalScale = 1;
    } else {
        decimalPlaces = CountDecimalPlaces(quantity, decimalSeparator);
        decimalScale = Math.max(Math.min(decimalPlaces, decimalUI || maximumDecimals), minimumDecimals);
    }

    const renderText = (value) => {
        const firstCurrencyAux =
            firstCurrency !== DATA_NOT_AVAILABLE ? `currency.label.${firstCurrency}` : "data.notAvailable.short";
        const secondCurrencyAux =
            secondCurrency !== DATA_NOT_AVAILABLE ? `currency.label.${secondCurrency}` : "data.notAvailable.short";

        if (noStyle) {
            return <React.Fragment>{value}</React.Fragment>;
        }

        return (
            <span className={className}>
                <span className="data-amount-currency">
                    <I18n id={firstCurrencyAux} />
                </span>
                {SPACE_STR}
                {value} {frequency} {quatityNote && <small>{` ${quatityNote}`}</small>}
                <I18n id="differentCurrency.rateText" />
                {SPACE_STR}
                <span className="data-amount-currency">
                    <I18n id={secondCurrencyAux} />
                </span>
            </span>
        );
    };
    return (
        <NumberFormatInput
            decimalSeparator={decimalSeparator}
            thousandSeparator={thousandSeparator}
            decimalScale={decimalScale}
            value={amount.quantity}
            displayType="text"
            renderText={renderText}
            suffix={amount.abbreviature}
            fixedDecimalScale
        />
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    maximumDecimals: Number(SelectorsStoreConfig.getConfig(store)["defaultDecimal.maximum"] || 0),
    minimumDecimals: Number(SelectorsStoreConfig.getConfig(store)["defaultDecimal.minimum"] || 0),
    lang: SelectorsStoreI18n.getLang(store),
});

export default Connect(mapStateToProps)(Component);
