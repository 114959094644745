import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
// import Container from "~/containers/Internal/Form/Simple";

import I18n from "~/components/I18n";
import FieldError from "~/pages/_components/fields/FieldError";

import Style from "./SwitchField.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        name: PropTypes.string,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        error: PropTypes.string,
        label: PropTypes.string,
        hideLabel: PropTypes.bool,
        idForm: PropTypes.string,
        readOnly: PropTypes.bool,
        value: PropTypes.bool,
    },
    defaults: {
        name: EMPTY_STR,
        error: EMPTY_STR,
        label: EMPTY_STR,
        hideLabel: false,
        idForm: EMPTY_STR,
        readOnly: false,
        value: false,
        onChange: () => {},
        onBlur: null,
    },
};

export function Component(props) {
    function handleChange({ target, ...event }) {
        const { onChange } = props;
        onChange({
            ...event,
            target: {
                ...target,
                value: target.value === "true",
            },
        });
    }

    const { idForm, error, hideLabel, label, value, formGroup, ...otherProps } = props;

    return (
        <div name={Style.NAME} className={Style.CLASS}>
            <div className="c-control c-control-block c-control--switch">
                <input
                    className="c-control-input"
                    id={otherProps.name}
                    type="checkbox"
                    {...otherProps}
                    onChange={handleChange}
                    value={!value}
                    checked={value}
                    readOnly
                />

                <label className="c-control-label" htmlFor={otherProps.name}>
                    {!hideLabel && (
                        <div className="form-group-text">
                            {label || <I18n id={`${idForm}.${otherProps.name}.label`} />}
                        </div>
                    )}
                </label>
                {error && <FieldError error={error} />}
            </div>
        </div>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
