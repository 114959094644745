// TODO: Add the polyfill for intersectionobserver to work on Safari 11
//      https://github.com/w3c/IntersectionObserver/tree/master/polyfill
import React from "react";

import PropTypes from "prop-types";

import { Categorize } from "~/util/prop";

export const NAME = "IntersectionObserver";
export const PROP = {
    types: {
        children: PropTypes.node.isRequired,
        onIntersect: PropTypes.func.isRequired,
    },
    defaults: {},
};

const TRIGGER = "intersection-trigger";

/** @see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API */

export function Component(props) {
    const { children, onIntersect, settings = {} } = Categorize(props);
    const ref = React.useRef({});

    React.useEffect(() => {
        Object.entries(ref.current).forEach(([key, el]) => {
            /* eslint-disable no-param-reassign */
            el.observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        entry.key = key;
                        onIntersect(entry);
                    });
                },
                {
                    root: el.parentElement,
                    ...settings,
                },
            );
            el.observer.observe(el);
            /* eslint-enable no-param-reassign */
        });
        return () => {
            Object.values(ref.current).forEach((el) => {
                el.observer.unobserve(el);
            });
        };
    }, [onIntersect, settings]);

    return React.Children.map(children, (child) => {
        if (!child || !child.props || !child.props[TRIGGER]) {
            return child;
        }
        const { key } = child;
        if (!key) {
            throw new Error(`Elements containing prop "${TRIGGER}" must have a key also`);
        }
        const newProps = {
            ...child.props,
            [TRIGGER]: undefined,
            ref: (el) => {
                ref.current[key] = el;
            },
        };
        return React.cloneElement(child, newProps);
    });
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
