import React from "react";

import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";

import {
    BANKTYPE,
    CDP_CURRENCY,
    CHANNEL,
    COMMA,
    EMPTY_STR,
    LANGUAGE,
    LEVEL,
    ONLY_NUMBER,
    SCOPE,
    SPACE_STR,
} from "~/constants";
import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import {
    SelectorsAction as SelectorsActionComex,
    PROP as PropComex,
    SelectorsStore as SelectorsStoreComex,
} from "~/store/comex";
import {
    SelectorsAction as SelectorsActionComexExport,
    SelectorsStore as SelectorsStoreComexExport,
} from "~/store/comex/export";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsAction as SelectorActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionTemplate } from "~/store/template";
import * as ConfigUtils from "~/util/config";
import { formatDate as FormatDate, toDate as ToDate } from "~/util/date";
import { isNumeric } from "~/util/form";
import { isEmptyObj } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import DateField from "~/pages/_components/fields/DateField";
import EmailList from "~/pages/_components/fields/EmailList";
import TextArea from "~/pages/_components/fields/TextArea";
import TextField from "~/pages/_components/fields/TextField";
import AmountField from "~/pages/_components/fields/formik/AmountField";
import Selector from "~/pages/_components/fields/formik/Selector";

import Style from "~/pages/comEx/Step1Edit.rules.scss";
import BankSearchForm from "~/pages/comEx/_components/BankSearch";
import FileUploader from "~/pages/comEx/_components/FileUploader";
import LinkOptions from "~/pages/comEx/_components/LinkOptions";
import OperationNumber from "~/pages/comEx/_components/OperationNumber";
import OperationNumberDetail from "~/pages/comEx/_components/OperationNumberDetail";
import CreateTemplateModal from "~/pages/forms/Step1Edit/_ModalTemplateCreate";
import ListTemplatesModal from "~/pages/forms/Step1Edit/_ModalTemplateList";
import { Termsandconditions } from "~/pages/forms/_components/_fields";
import Scheduler from "~/pages/forms/_components/_fields/Scheduler";

const FORM_ID = "comex.export.transferCreditLetter";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...PropComex.types,
    },
    defaults: {
        ...PropComex.defaults,
    },
};

const BENEFICIARY_OPTION = "beneficiary";
const SECOND_BENEFICIARY_OPTION = "secondBeneficiary";
const OTHER_OPTION = "other";
const PAYER_OPTION = "payer";

function equalBanks(bank1, bank2) {
    if (bank1 && bank2) {
        if (Object.keys(bank1).length !== Object.keys(bank2).length) {
            return false;
        }

        const differences = Object.keys(bank1).filter((k) => bank2[k] !== bank1[k]);

        return differences.length === 0;
    }

    return !bank1 && !bank2;
}

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        draftLoaded: false,
        loadOperationNumber: false,
        selectedBank: {},
    };

    static getDerivedStateFromProps(props, state) {
        const { errors, selectedBank, setFieldValue, setErrors, values } = props;

        if (state && !equalBanks(selectedBank, state.selectedBank) && setFieldValue) {
            setFieldValue("secondBank", selectedBank.code || values.secondBank.toUpperCase());
            setFieldValue("secondBankName", selectedBank.bankName || EMPTY_STR);
            setFieldValue("secondBankAddress", selectedBank.bankAddress ? selectedBank.bankAddress.trim() : EMPTY_STR);

            if (setErrors && selectedBank.code) {
                setErrors(delete { ...errors }.selectedBank);
            }

            return { ...state, selectedBank };
        }

        return { ...state };
    }

    componentDidMount() {
        const { dispatch, formData } = this.props;

        if (isEmptyObj(formData)) {
            dispatch(SelectorsActionComexExport.transferCreditLetterPreRequest());
        }
    }

    componentDidUpdate() {
        const {
            dispatch,
            match,
            preData,
            setFieldError,
            setFieldTouched,
            setFieldValue,
            setValues,
            transaction,
            values,
        } = this.props;
        const { availableOperationList } = preData;
        const { operationNumber } = match.params;
        const { draftLoaded, loadOperationNumber } = this.state;

        if (!loadOperationNumber && availableOperationList.length > 0 && !values.operationNumber && operationNumber) {
            const operationSelected = availableOperationList.filter(
                (item) => item.othersFields.NROOPERACION === operationNumber,
            );

            this.setState({
                loadOperationNumber: true,
            });

            if (operationSelected.length > 0) {
                setFieldValue("item", operationSelected[0]);
                setFieldValue("operationNumber", operationNumber);
            }
        }

        if (transaction?.data && !draftLoaded) {
            this.setState({
                draftLoaded: true,
            });

            const transactionData = transaction?.data;

            /* carga de errores */
            if (transactionData.backendFormErrors?.cdpErrorFields) {
                const { cdpErrorFields } = transactionData.backendFormErrors;

                cdpErrorFields.map((errorField) => {
                    if (errorField === "valueDate") {
                        setFieldError("scheduler", SPACE_STR);
                        setFieldTouched("scheduler", true);

                        transactionData.scheduler = {
                            editing: true,
                            valueDate: transactionData?.valueDate,
                            selectedOption: transactionData?.selectedOption,
                        };
                    } else {
                        setFieldError(errorField, SPACE_STR);
                        setFieldTouched(errorField, true);
                    }

                    return null;
                });
            }

            if (transactionData.backendFormErrors?.message) {
                const errorMessage = transactionData?.backendFormErrors?.message;

                dispatch(
                    SelectorActionNotification.showNotification({
                        message: errorMessage,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.COMEX_EXPORT_CREDIT_LETTER_TRANSFER],
                    }),
                );
            } // modificaciones para que agarren bien los fields
            /*  carga del draft */

            if (transactionData.valueDate) {
                const valueDate = ToDate(transactionData.valueDate);

                transactionData.valueDate = valueDate;
            }

            if (transactionData.newDueDate) {
                const newDueDate = ToDate(transactionData.newDueDate);

                transactionData.newDueDate = newDueDate;
            }

            if (transactionData.newShippingDate) {
                const newShippingDate = ToDate(transactionData.newShippingDate);

                transactionData.newShippingDate = newShippingDate;
            }

            setValues(transaction.data);
        }
    }

    bankSearchForm = () => {
        const {
            preData: { bankSearchCountryList },
        } = this.props;

        return (
            <BankSearchForm
                countries={bankSearchCountryList}
                handleSelect={this.handleSelectBank}
                scope={SCOPE.COMEX_EXPORT_CREDIT_LETTER_TRANSFER}
            />
        );
    };

    buildOptions = (payer) => {
        const options = [
            { value: BENEFICIARY_OPTION, label: UtilsI18n.get(`${FORM_ID}.option.${BENEFICIARY_OPTION}`) },
            {
                value: SECOND_BENEFICIARY_OPTION,
                label: UtilsI18n.get(`${FORM_ID}.option.${SECOND_BENEFICIARY_OPTION}`),
            },
            { value: OTHER_OPTION, label: UtilsI18n.get(`${FORM_ID}.option.${OTHER_OPTION}`) },
        ];

        if (payer) {
            options.push({ value: PAYER_OPTION, label: UtilsI18n.get(`${FORM_ID}.option.${PAYER_OPTION}`) });
        }

        return options;
    };

    clearBankErrors = (e, fieldName) => {
        const { errors, setErrors, setFieldValue } = this.props;

        if (Object.keys(errors).includes("secondBankName")) {
            setErrors(delete { ...errors }.secondBankName);
        }

        if (Object.keys(errors).includes("secondBank")) {
            setErrors(delete { ...errors }.secondBank);
        }

        setFieldValue(fieldName, e.target.value.toUpperCase());
    };

    handleBlur = () => {
        const { values, preData, setFieldValue } = this.props;
        const { availableOperationList } = preData;

        if (values.operationNumber) {
            const operationSelected = availableOperationList.filter(
                (item) => item.othersFields.NROOPERACION === values.operationNumber,
            );

            if (operationSelected.length > 0) {
                setFieldValue("item", operationSelected[0]);
            } else {
                setFieldValue("item", null);
                setFieldValue("operationNumber", EMPTY_STR);
            }
        }
    };

    handleCancel = () => {
        const { dispatch, setSubmitting, transaction } = this.props;

        dispatch(
            SelectorsActionForm.cancelTransactionCustom({
                credentials: {},
                idTransaction: transaction.idTransaction,
                formikBag: { setSubmitting },
            }),
        );
    };

    handleDraft = () => {
        const { dispatch, values, transaction } = this.props;

        dispatch(
            SelectorsActionForm.saveDraft({
                idActivityDraft: "comex.export.transferCreditLetter.send",
                idTransactionToSave: transaction?.idTransaction,
                transactionData: values || {},
            }),
        );
    };

    handleItemSelect = (item) => {
        const { setFieldError, setFieldValue } = this.props;
        const { othersFields } = item;

        if (othersFields) {
            setFieldValue("operationNumber", othersFields.NROOPERACION);
            setFieldValue("item", item);

            setFieldError("operationNumber", null);
        } else {
            setFieldValue("item", null);
            setFieldValue("operationNumber", EMPTY_STR);
        }
    };

    handleModalLoad = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionComex.toggleCreditLetterList());
    };

    handleSelectTemplate = (template) => {
        const { setErrors, setValues } = this.props;
        const templateToBeLoaded = template;

        if (templateToBeLoaded.scheduler.valueDate) {
            const valueDate = ToDate(templateToBeLoaded.scheduler.valueDate);
            templateToBeLoaded.scheduler.valueDate = valueDate;
        }

        if (templateToBeLoaded.newDueDate) {
            const newDueDate = ToDate(templateToBeLoaded.newDueDate);
            templateToBeLoaded.newDueDate = newDueDate;
        }

        if (templateToBeLoaded.newShippingDate) {
            const newShippingDate = ToDate(templateToBeLoaded.newShippingDate);
            templateToBeLoaded.newShippingDate = newShippingDate;
        }

        setValues(templateToBeLoaded);
        setErrors({});
    };

    handleTemplateLoad = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionTemplate.toggleTemplateList());
    };

    handleTemplateSave = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionTemplate.createTemplate());
    };

    searchBank = (e) => {
        const { dispatch, setSubmitting, setErrors } = this.props;

        const request = {
            filters: {
                bank: {},
                channel: CHANNEL.FRONTEND,
                code: e.target.value.toUpperCase(),
                country: EMPTY_STR,
                name: EMPTY_STR,
                type: BANKTYPE.SWIFT,
            },
            idField: "secondBank",
            setErrors,
            setSubmitting,
        };

        dispatch(SelectorsActionComex.searchBankRequest(request));
    };

    step1Form = () => {
        const { emailValidationRegex, errors, preData, setFieldValue, transaction, values } = this.props;
        const { nextValidDate, unavailableDays } = preData;
        const termsAndConditions = "comex.export.transferCreditLetter.disclaimer";
        const i18nMap = {
            termsAndConditions,
        };

        const cancelEnabled = transaction && transaction.idTransactionStatus === STATUS.DRAFT;

        const othersFields = values.item ? values.item.othersFields : null;

        const currency = othersFields ? othersFields.MONEDA : EMPTY_STR;

        const amountData = {
            options: [
                {
                    id: currency,
                    label: UtilsI18n.get(`currency.label.${currency}`),
                },
            ],
        };

        return (
            <React.Fragment>
                <div className="form-section loadTemplates showTemplate">
                    <Button
                        onClick={this.handleTemplateLoad}
                        label="forms.templates.load"
                        bsStyle="default"
                        image="template.svg"
                    />
                </div>
                <section className="fields container--layout align-items-center flex-grow">
                    <Box className="form-section-title">
                        <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.operation`)}</h3>
                    </Box>

                    {/* Operation Number */}
                    <Box flex align="end" className="form-group search-field">
                        <Field
                            autoComplete="off"
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            name="operationNumber"
                            onBlur={this.handleBlur}
                            pattern={ONLY_NUMBER}
                            tooltip={UtilsI18n.get("comex.export.transferCreditLetter.operationNumber.tooltip")}
                            type="text"
                        />

                        <Box className="ml-3 mb-2">
                            <Button
                                onClick={this.handleModalLoad}
                                label="global.search"
                                bsStyle="primary"
                                className="search"
                            />
                        </Box>
                    </Box>
                    {values.item && <OperationNumberDetail othersFields={othersFields} idForm={FORM_ID} />}
                    {/* Second Beneficiary */}
                    <Field
                        autoComplete="off"
                        className="form-control"
                        component={TextField}
                        hidePlaceholder
                        idForm={FORM_ID}
                        maxLength={60}
                        mode={MODE.EDIT}
                        name="secondBeneficiary"
                        tooltip={UtilsI18n.get("comex.export.transferCreditLetter.secondBeneficiary.tooltip")}
                        type="text"
                    />
                    {/* Address */}
                    <Field
                        className="form-control"
                        component={TextArea}
                        hidePlaceholder
                        idForm={FORM_ID}
                        maxLength={150}
                        mode={MODE.EDIT}
                        name="address"
                    />
                    {/* Contact */}
                    <Field
                        autoComplete="off"
                        className="form-control"
                        component={TextField}
                        hidePlaceholder
                        idForm={FORM_ID}
                        maxLength={35}
                        mode={MODE.EDIT}
                        name="contact"
                        optional={UtilsI18n.get("form.field.optional")}
                        type="text"
                    />
                    {/* Phone */}
                    <Field
                        autoComplete="off"
                        className="form-control"
                        component={TextField}
                        hidePlaceholder
                        idForm={FORM_ID}
                        maxLength={35}
                        mode={MODE.EDIT}
                        name="phone"
                        optional={UtilsI18n.get("form.field.optional")}
                        pattern={ONLY_NUMBER}
                        type="text"
                    />
                    {/* Second Bank */}
                    <Box className="form-group search-field search-bank-field">
                        <Field
                            autoComplete="off"
                            component={TextField}
                            handleOnChange={(e) => this.clearBankErrors(e, "secondBank")}
                            handleOnChangeAtLast
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={11}
                            name="secondBank"
                            onBlur={(e) => this.searchBank(e)}
                            showEmptyError
                            style={{ textTransform: "uppercase" }}
                            value={values.secondBank.toUpperCase()}
                        />
                        <LinkOptions
                            handleClick={() => {
                                const { dispatch } = this.props;

                                dispatch(SelectorsActionComex.setBankSearchInfo({ showBankSearch: true }));
                            }}
                            text={UtilsI18n.get("global.search")}
                        />
                    </Box>
                    {/* Second Bank Name */}
                    <Field
                        autoComplete="off"
                        className="form-control"
                        component={TextField}
                        handleOnChange={(e) => this.clearBankErrors(e, "secondBankName")}
                        handleOnChangeAtLast
                        hidePlaceholder
                        idForm={FORM_ID}
                        maxLength={160}
                        mode={MODE.EDIT}
                        name="secondBankName"
                        style={{ textTransform: "uppercase" }}
                        type="text"
                    />
                    {/* Second Bank Address */}
                    <Field
                        autoComplete="off"
                        className="form-control"
                        component={TextArea}
                        handleOnChange={(e) => setFieldValue("secondBankAddress", e.target.value.toUpperCase())}
                        handleOnChangeAtLast
                        hidePlaceholder
                        idForm={FORM_ID}
                        maxLength={150}
                        mode={MODE.EDIT}
                        name="secondBankAddress"
                        style={{ textTransform: "uppercase" }}
                    />
                    {/* Second Bank Account */}
                    <Field
                        autoComplete="off"
                        className="form-control"
                        component={TextField}
                        hidePlaceholder
                        idForm={FORM_ID}
                        maxLength={60}
                        mode={MODE.EDIT}
                        name="secondBankAccount"
                        type="text"
                    />
                    {/* Transfer Amount */}
                    <Field
                        className="form-control"
                        clearable={false}
                        component={AmountField}
                        data={amountData}
                        decimalPlaces={2}
                        fixedDecimalScale
                        idForm={FORM_ID}
                        maxLength={ConfigUtils.getInteger("amount.length")}
                        name="amount"
                        placeholder={EMPTY_STR}
                        searchable={false}
                        hideCurrency={!currency}
                    />
                    {/* New Due Date */}
                    <Box className="form-group">
                        <Field
                            autoComplete="off"
                            component={DateField}
                            hidePlaceholder
                            id="newDueDate"
                            idForm={FORM_ID}
                            maxDate={null}
                            name="newDueDate"
                            showMonthYearDropdown
                        />
                    </Box>
                    {/* New Shipping Date */}
                    <Box className="form-group">
                        <Field
                            autoComplete="off"
                            component={DateField}
                            hidePlaceholder
                            id="newShippingDate"
                            idForm={FORM_ID}
                            maxDate={null}
                            name="newShippingDate"
                            showMonthYearDropdown
                        />
                    </Box>
                    {/* Deadline Document Submission */}
                    <Box className="form-group small-field-form-group">
                        <Field
                            autoComplete="off"
                            className="form-control"
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={2}
                            mode={MODE.EDIT}
                            name="deadlineDocumentSubmission"
                            pattern={ONLY_NUMBER}
                            type="text"
                        />
                        <div className="text-side">
                            <I18n id="comex.export.transferCreditLetter.deadlineDocumentSubmission.description" />
                        </div>
                    </Box>
                    {/* Merchandise Quantity */}
                    <Field
                        className="form-control"
                        component={TextArea}
                        hidePlaceholder
                        idForm={FORM_ID}
                        maxLength={500}
                        mode={MODE.EDIT}
                        name="merchandiseQuantity"
                        optional={UtilsI18n.get("form.field.optional")}
                        tooltip={UtilsI18n.get("comex.export.transferCreditLetter.merchandiseQuantity.tooltip")}
                    />
                    {/* Files */}
                    <Box className="form-group">
                        <Field
                            component={FileUploader}
                            emptyMessageKey={`${FORM_ID}.files.emptyMessage`}
                            errors={errors}
                            idForm={FORM_ID}
                            maxFiles={5}
                            name="files"
                            optional={UtilsI18n.get("form.field.optional")}
                            setFieldValue={setFieldValue}
                            values={values}
                        />
                    </Box>
                    {/* Transfer Expenses */}
                    <Field
                        className="flex-container slideFromBottom"
                        clearable={false}
                        component={Selector}
                        creatable={false}
                        hidePlaceholder
                        idForm={FORM_ID}
                        name="transferExpenses"
                        options={this.buildOptions()}
                        optionClassName="needsclick"
                        renderAs="combo"
                        tooltip={UtilsI18n.get("comex.export.transferCreditLetter.specialInstructions.tooltip")}
                        value={values.transferExpenses}
                    />
                    {/* Confirmation Expenses */}
                    <Field
                        className="flex-container slideFromBottom"
                        clearable={false}
                        component={Selector}
                        creatable={false}
                        hidePlaceholder
                        idForm={FORM_ID}
                        name="confirmationExpenses"
                        options={this.buildOptions(true)}
                        optionClassName="needsclick"
                        renderAs="combo"
                        tooltip={UtilsI18n.get("comex.export.transferCreditLetter.specialInstructions.tooltip")}
                        value={values.confirmationExpenses}
                    />
                    {/* Other Expenses */}
                    <Field
                        className="flex-container slideFromBottom"
                        clearable={false}
                        component={Selector}
                        creatable={false}
                        hidePlaceholder
                        idForm={FORM_ID}
                        name="otherExpenses"
                        options={this.buildOptions()}
                        optionClassName="needsclick"
                        renderAs="combo"
                        tooltip={UtilsI18n.get("comex.export.transferCreditLetter.specialInstructions.tooltip")}
                        value={values.otherExpenses}
                    />
                    {/* Issuer Bank Expenses */}
                    <Field
                        className="flex-container slideFromBottom"
                        clearable={false}
                        component={Selector}
                        creatable={false}
                        hidePlaceholder
                        idForm={FORM_ID}
                        name="issuerBankExpenses"
                        options={this.buildOptions(true)}
                        optionClassName="needsclick"
                        renderAs="combo"
                        tooltip={UtilsI18n.get("comex.export.transferCreditLetter.specialInstructions.tooltip")}
                        value={values.issuerBankExpenses}
                    />
                    {/* Other Bank Expenses */}
                    <Field
                        className="flex-container slideFromBottom"
                        clearable={false}
                        component={Selector}
                        creatable={false}
                        hidePlaceholder
                        idForm={FORM_ID}
                        name="otherBankExpenses"
                        options={this.buildOptions()}
                        optionClassName="needsclick"
                        renderAs="combo"
                        tooltip={UtilsI18n.get("comex.export.transferCreditLetter.specialInstructions.tooltip")}
                        value={values.otherBankExpenses}
                    />
                    {/* Special Instructions */}
                    <Field
                        className="form-control"
                        component={TextArea}
                        hidePlaceholder
                        idForm={FORM_ID}
                        maxLength={500}
                        mode={MODE.EDIT}
                        name="specialInstructions"
                        optional={UtilsI18n.get("form.field.optional")}
                    />
                    {/* Notification Emails */}
                    <Field
                        className="form-group email-field"
                        component={EmailList}
                        data={{ emailValidationRegex, addMessage: EMPTY_STR }}
                        idForm={FORM_ID}
                        name="notificationEmails"
                        hideSwiftTip
                        optional={UtilsI18n.get("form.field.optional")}
                        renderSuggestion={false}
                        tooltip={UtilsI18n.get(`${FORM_ID}.notificationEmails.tooltip`)}
                        value={values.notificationEmails}
                    />
                    {/* Disclaimer */}
                    <hr />
                    <Box className="form-section-title">
                        <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.section.showPreview`)}</h3>
                    </Box>

                    <Field
                        className="form-control"
                        component={Termsandconditions}
                        i18nMap={i18nMap}
                        idForm={FORM_ID}
                        mode={MODE.EDIT}
                        name="disclaimer"
                    />
                    {/* Scheduler */}
                    {nextValidDate && (
                        <Field
                            component={Scheduler}
                            data={{
                                firstWorkingDate: nextValidDate,
                                lang: LANGUAGE.ES,
                                maxDaysToSchedule: ConfigUtils.get("client.comex.valueDate.maxDaysToSchedule"),
                                mode: MODE.EDIT,
                                nonWorkingDays: unavailableDays,
                                programable: false,
                                schedulable: true,
                            }}
                            idForm={FORM_ID}
                            name="scheduler"
                        />
                    )}
                </section>
                <footer>
                    <Button onClick={this.handleDraft} label="forms.saveDraft.link" />
                    <Button onClick={this.handleTemplateSave} label="forms.saveTemplate.link" />
                    {cancelEnabled && <Button onClick={this.handleCancel} label="forms.cancelTransaction.link" />}
                    <Button
                        key="primaryButton"
                        variant="primary"
                        bsStyle="primary"
                        label="global.next"
                        loading={false}
                        type="submit"
                    />
                </footer>
            </React.Fragment>
        );
    };

    render() {
        const { preData, showBankSearch, values } = this.props;
        const { availableOperationList } = preData;

        return (
            <React.Fragment>
                <Form id={Style.ID} className="col col-12 col-lg-6 col-md-9 col-sm-12 creditLetter">
                    {!showBankSearch && this.step1Form()}
                    {showBankSearch && this.bankSearchForm()}
                </Form>

                <ListTemplatesModal
                    idActivityTemplate="comex.export.transferCreditLetter.send"
                    onSelect={this.handleSelectTemplate}
                />
                <CreateTemplateModal
                    values={values}
                    idActivityTemplate="comex.export.transferCreditLetter.send"
                    backdrop="static"
                />
                <OperationNumber
                    list={availableOperationList}
                    idForm={FORM_ID}
                    handleItemClick={this.handleItemSelect}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        emailValidationRegex: SelectorsStoreConfig.getConfig(store)["email.validationFormat"],
        formData: SelectorsStoreComexExport.getComexFormData(store),
        preData: SelectorsStoreComexExport.getComexFormPre(store),
        selectedBank: SelectorsStoreComex.getSelectedBank(store),
        showBankSearch: SelectorsStoreComex.getShowBankSearch(store),
        transaction: SelectorsStoreComex.getTransaction(store),
    };
};

export default HighOrder(
    WithRouter,
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ formData }) => {
            return {
                address: formData?.address || EMPTY_STR,
                attachmentsNames: formData?.attachmentsNames || [],
                confirmationExpenses: formData?.confirmationExpenses || BENEFICIARY_OPTION,
                contact: formData?.contact || EMPTY_STR,
                deadlineDocumentSubmission: formData?.deadlineDocumentSubmission || EMPTY_STR,
                files:
                    formData?.attachments && formData?.attachments !== EMPTY_STR
                        ? formData?.attachments.split(COMMA)
                        : [],
                issuerBankExpenses: formData?.issuerBankExpenses || PAYER_OPTION,
                item: formData?.item || null,
                merchandiseQuantity: formData?.merchandiseQuantity || EMPTY_STR,
                newDueDate: formData.newDueDate ? ToDate(formData.newDueDate) : EMPTY_STR,
                newShippingDate: formData.newShippingDate ? ToDate(formData.newShippingDate) : EMPTY_STR,
                notificationEmails: formData?.notificationEmails || [],
                operationNumber: formData?.operationNumber || EMPTY_STR,
                otherBankExpenses: formData?.otherBankExpenses || SECOND_BENEFICIARY_OPTION,
                otherExpenses: formData?.otherExpenses || BENEFICIARY_OPTION,
                phone: formData?.phone || EMPTY_STR,
                secondBank: formData?.secondBank || EMPTY_STR,
                secondBankAccount: formData?.secondBankAccount || EMPTY_STR,
                secondBankAddress: formData?.secondBankAddress || EMPTY_STR,
                secondBankName: formData?.secondBankName || EMPTY_STR,
                secondBeneficiary: formData?.secondBeneficiary || EMPTY_STR,
                specialInstructions: formData?.specialInstructions || EMPTY_STR,
                scheduler: formData?.scheduler,
                amount: {
                    amount: formData?.amount?.quantity || formData?.amount?.amount || null,
                    currency: formData?.amount?.currency || CDP_CURRENCY.USD,
                },
                transferExpenses: formData?.transferExpenses || BENEFICIARY_OPTION,
            };
        },
        handleSubmit: (props, formikBag) => {
            const { dispatch } = formikBag.props;
            const { files, newDueDate, newShippingDate, scheduler, amount, ...rest } = props;

            const attachmentsFiles = files ? files.join(COMMA) : EMPTY_STR;
            const newDueDateString = newDueDate ? FormatDate(new Date(newDueDate)) : EMPTY_STR;
            const newShippingDateString = newShippingDate ? FormatDate(new Date(newShippingDate)) : EMPTY_STR;
            const valueDateString = scheduler ? FormatDate(scheduler.valueDate) : EMPTY_STR;

            const formData = {
                ...rest,
                attachments: attachmentsFiles,
                newDueDate: newDueDateString,
                newShippingDate: newShippingDateString,
                amount: {
                    quantity: isNumeric(amount.amount) ? amount.amount : null,
                    currency: amount.currency,
                },
                scheduler,
                valueDate: valueDateString,
            };
            dispatch(
                SelectorsActionComexExport.transferCreditLetterPreviewRequest({
                    formData,
                    formikBag,
                }),
            );
        },
    }),
)(Component);
