import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { MODE } from "~/constants/form";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "customsPayment";

export const PATH = {
    LIST_PAYMENTS_PRE_REQUEST: "/pay.customs.listPaymentsPre",
    LIST_PAYMENTS_REQUEST: "/pay.customs.listPayments",
    PAY_CUSTOMS_PREVIEW_REQUEST: "/pay.customs.payCustomsPreview",
    PAY_CUSTOMS_REQUEST: "/pay.customs.payCustoms",
};

export const TYPE = FactoryType(NAME, [
    "CANCEL_CUSTOM_TRANSACTION_REQUEST",
    "FINISH_STEP_1",
    "LIST_PAYMENTS_FAILURE",
    "LIST_PAYMENTS_PRE_FAILURE",
    "LIST_PAYMENTS_PRE_REQUEST",
    "LIST_PAYMENTS_PRE_SUCCESS",
    "LIST_PAYMENTS_REQUEST",
    "LIST_PAYMENTS_SUCCESS",
    "PAY_CUSTOMS_FAILURE",
    "PAY_CUSTOMS_GO_TO_STEP_1",
    "PAY_CUSTOMS_LOAD_TICKET_FAILURE",
    "PAY_CUSTOMS_LOAD_TICKET_REQUEST",
    "PAY_CUSTOMS_LOAD_TICKET_SUCCESS",
    "PAY_CUSTOMS_PREVIEW_FAILURE",
    "PAY_CUSTOMS_PREVIEW_REQUEST",
    "PAY_CUSTOMS_PREVIEW_SUCCESS",
    "PAY_CUSTOMS_REQUEST_REQUEST",
    "PAY_CUSTOMS_SIGNATURE_REQUIRED",
    "PAY_CUSTOMS_SIGN_TRANSACTION_FAILURE",
    "PAY_CUSTOMS_SIGN_TRANSACTION_PREVIEW",
    "PAY_CUSTOMS_SIGN_TRANSACTION_REQUEST",
    "PAY_CUSTOMS_SIGN_TRANSACTION_SUCCESS",
    "PAY_CUSTOMS_SUCCESS",
    "RESTART_FLOW",
    "SIGN_TRANSACTION_PREVIEW",
    "SIGN_TRANSACTION_SUCCESS",
]);

export const PROP = {};

PROP.types = {
    debitAccountList: PropTypes.array,
    isFetching: PropTypes.bool,
    mode: PropTypes.string,
    payments: PropTypes.array,
    selectedPayments: PropTypes.array,
    referenceCode: PropTypes.string,
    totalAmount: PropTypes.number,
    transaction: PropTypes.shape({}),
    ...TypesRedux,
};

PROP.defaults = {
    debitAccountList: [],
    isFetching: true,
    mode: MODE.EDIT,
    payments: [],
    selectedPayments: [],
    referenceCode: EMPTY_STR,
    totalAmount: 0,
    transaction: {
        data: {
            debitAmount: {},
            totalAmount: {},
            selectedPayments: [],
        },
    },
    ...DefaultsRedux,
};
