import React from "react";

import PropTypes from "prop-types";
import { ProgressBar } from "react-bootstrap";

import { CREDENTIAL_TYPE_PASSWORD } from "~/constants";
import { calculateScoreStrengthPassword } from "~/util/settings";

import Image from "~/components/Image";
import TextField from "~/pages/_components/fields/TextField";

export const NAME = "Password";

export const PROP = {
    types: {
        form: PropTypes.shape({
            isSubmitting: PropTypes.bool.isRequired,
            setFieldValue: PropTypes.func.isRequired,
        }).isRequired,
        autoComplete: PropTypes.string,
        autoFocus: PropTypes.bool,
        showStrength: PropTypes.bool,
        type: PropTypes.string,
    },
    defaults: { autoComplete: "off", autoFocus: false, showStrength: false, type: CREDENTIAL_TYPE_PASSWORD },
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        isVisible: false,
    };

    inputRef = React.createRef();

    componentDidUpdate(prevProps, prevState) {
        const { isVisible } = this.state;
        if (isVisible !== prevState.isVisible) {
            this.inputRef.current.focus();
        }
    }

    UNSAFE_componentWillMount() {
        const { type } = this.props;
        this.setState({ isVisible: type !== CREDENTIAL_TYPE_PASSWORD });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { type } = this.props;
        if (type !== nextProps.type) {
            this.setState({ isVisible: nextProps.type !== CREDENTIAL_TYPE_PASSWORD });
        }
    }

    handleOnChange = (event) => {
        this.setState({
            scoreStrengthPass: calculateScoreStrengthPassword(event.target.value),
        });
    };

    toggleIsVisible = () => {
        const { type } = this.props;
        if (type === CREDENTIAL_TYPE_PASSWORD) {
            this.setState((prevState) => ({ isVisible: !prevState.isVisible }));
        }
    };

    revealFunction = () => {
        const { isVisible } = this.state;
        const { type } = this.props;
        return (
            <div className="c-control c-control--icon-toggle" onClick={this.toggleIsVisible}>
                <label className="c-control-label">
                    <div className="c-control-off">
                        {type === CREDENTIAL_TYPE_PASSWORD && <Image src={isVisible ? "show.svg" : "hide.svg"} />}
                    </div>
                </label>
            </div>
        );
    };

    render() {
        const { isVisible, scoreStrengthPass } = this.state;
        const { showStrength } = this.props;

        return (
            <div className="form-group">
                <TextField
                    {...this.props}
                    inputRef={this.inputRef}
                    type={isVisible ? "text" : "password"}
                    inputFunctions={this.revealFunction()}
                    handleOnChange={this.handleOnChange}
                    autoCapitalize="none"
                />
                {showStrength && <ProgressBar now={scoreStrengthPass} />}
            </div>
        );
    }
}

export default Component;
