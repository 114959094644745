import { push as Push } from "connected-react-router";
import { takeLatest as TakeLatest, call as Call, put as Put, select as Select } from "redux-saga/effects";

import {
    INVALID_EMAIL,
    LEVEL,
    SCOPE,
    RESPONSE_TYPE,
    VALIDATION_ERROR,
    API_INVALID_USER_OR_SECOND_FACTOR,
} from "~/constants";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsAction as ActionsLogin } from "~/store/login";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import {
    SelectorsAction as SelectorsActionsRecoverPassword,
    SelectorsStore as SelectorsStoreRecoverPassword,
} from "~/store/recoverypassword";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import UtilsDevice, { DEVICE_MOBILE } from "~/util/device";
import { adjustIdFieldErrors as AdjustIdFieldErrors } from "~/util/form";
import * as I18nUtils from "~/util/i18n";
import * as SoftToken from "~/util/softToken";
import * as UtilsVUFingerprint from "~/util/vuFingerprint";

import { TYPE } from "./_consts";
import { SelectorsMiddleware } from "./_selectors";

const sagas = [
    TakeLatest(TYPE.RECOVER_PASS_CLEAN, handleClean),
    TakeLatest(TYPE.RECOVER_PASS_STEP1_REQUEST, handleRecoveryPassStep1),
    TakeLatest(TYPE.RECOVER_PASS_STEP2_REQUEST, handleRecoveryPassStep2),
    TakeLatest(TYPE.RECOVER_PASS_STEP3_REQUEST, handleRecoveryPassStep3),
];

export default sagas;

function* handleClean() {
    yield Put(ActionsLogin.initLoginFlow());
    yield Put(Push("/"));
}

function* handleRecoveryPassStep1(props) {
    let webOtp = null;
    let myProps = { ...props };
    delete myProps.type;

    if (UtilsDevice.isMobileNative()) {
        const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

        if (isTrusted) {
            try {
                webOtp = yield Call(SoftToken.generateOTP);
                myProps = { ...myProps, _otp: webOtp };
            } catch (error) {
                if (error === SoftToken.error.NO_PERMISSION_GRANTED) {
                    // En el caso de que no se den permisos o que falle isTrusted se guarda isTrusted en false
                    // Esta action redirige al externalDashboard
                    yield Put(SelectorsActionsRecoverPassword.recoverPassCleanUp());
                    yield Put(
                        SelectorsActionNotification.showNotification({
                            message: I18nUtils.get("vascoPermisson.error"),
                            level: LEVEL.ERROR,
                            scopes: [SCOPE.EXTERNAL_DASHBOARD],
                        }),
                    );

                    return undefined;
                }
            }
        } else {
            yield Put(SelectorsActionsRecoverPassword.recoverPassCleanUp());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: "recoveryPassword.error",
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.EXTERNAL_DASHBOARD],
                }),
            );
        }
    }

    const { formikBag, ...rest } = myProps;
    const { username, email, otp } = rest;
    const params = {
        _username: username,
        _email: email,
        _otp: otp,
    };

    const response = yield Call(SelectorsMiddleware.recoveryPassStep1, params);
    if (response.type === RESPONSE_TYPE.WARNING) {
        if (
            response.data.code === VALIDATION_ERROR ||
            response.data.code === INVALID_EMAIL ||
            response.data.code === API_INVALID_USER_OR_SECOND_FACTOR
        ) {
            formikBag.setErrors(AdjustIdFieldErrors(response.data.data));

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.RECOVERY_PASSWORD],
                }),
            );
            yield Put(SelectorsActionsRecoverPassword.recoverPassStep1Failure());
        } else {
            yield Put(SelectorsActionsRecoverPassword.recoverPassCleanUp());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.EXTERNAL_DASHBOARD],
                }),
            );
        }
    } else {
        yield Put(SelectorsActionsRecoverPassword.recoverPassStep1Success());
        yield Put(Push("/recoveryPass/step2"));
    }

    formikBag.setSubmitting(false);

    return undefined;
}

function* handleRecoveryPassStep2(props) {
    let myProps = { ...props };
    delete myProps.type;
    let webOtp = null;

    if (UtilsDevice.isMobileNative()) {
        const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

        if (isTrusted) {
            try {
                webOtp = yield Call(SoftToken.generateOTP);
            } catch (error) {
                if (error === SoftToken.error.NO_PERMISSION_GRANTED) {
                    // En el caso de que no se den permisos o que falle isTrusted se guarda isTrusted en false
                    yield Put(SelectorsActionsRecoverPassword.recoverPassCleanUp());
                    yield Put(
                        SelectorsActionNotification.showNotification({
                            message: I18nUtils.get("vascoPermisson.error"),
                            level: LEVEL.ERROR,
                            scopes: [SCOPE.EXTERNAL_DASHBOARD],
                        }),
                    );
                    return undefined;
                }
            }
        } else {
            yield Put(SelectorsActionsRecoverPassword.recoverPassCleanUp());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: "recoveryPassword.error",
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.EXTERNAL_DASHBOARD],
                }),
            );
        }
    }

    myProps = { ...myProps, _otp: webOtp };

    const { formikBag, ...rest } = myProps;
    const { verificationCode } = rest;
    const params = {
        _verificationCode: verificationCode,
    };

    const response = yield Call(SelectorsMiddleware.recoveryPassStep2, params);

    if (response.type === RESPONSE_TYPE.WARNING) {
        if (response.data.code === VALIDATION_ERROR) {
            formikBag.setErrors(AdjustIdFieldErrors(response.data.data));

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.RECOVERY_PASSWORD],
                }),
            );
            yield Put(SelectorsActionsRecoverPassword.recoverPassStep2Failure());
        } else {
            yield Put(SelectorsActionsRecoverPassword.recoverPassCleanUp());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.EXTERNAL_DASHBOARD],
                }),
            );
        }
    } else {
        const { _exchangeToken, _verificationCode } = response.data.data;

        yield Put(
            SelectorsActionsRecoverPassword.recoverPassStep2Success({
                exchangeToken: _exchangeToken,
                verificationCode: _verificationCode,
            }),
        );
        yield Put(Push("/recoveryPass/step3"));
    }

    formikBag.setSubmitting(false);
    return undefined;
}

function* handleRecoveryPassStep3(props) {
    let myProps = { ...props };
    delete myProps.type;
    let webOtp = null;

    if (UtilsDevice.isMobileNative()) {
        const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

        if (isTrusted) {
            try {
                webOtp = yield Call(SoftToken.generateOTP);
            } catch (error) {
                if (error === SoftToken.error.NO_PERMISSION_GRANTED) {
                    // En el caso de que no se den permisos o que falle isTrusted se guarda isTrusted en false
                    yield Put(SelectorsActionsRecoverPassword.recoverPassCleanUp());
                    yield Put(
                        SelectorsActionNotification.showNotification({
                            message: I18nUtils.get("vascoPermisson.error"),
                            level: LEVEL.ERROR,
                            scopes: [SCOPE.EXTERNAL_DASHBOARD],
                        }),
                    );
                    return undefined;
                }
            }
        } else {
            yield Put(SelectorsActionsRecoverPassword.recoverPassCleanUp());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: "recoveryPassword.error",
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.EXTERNAL_DASHBOARD],
                }),
            );
        }
    }

    const exchangeToken = yield Select(SelectorsStoreRecoverPassword.getExchangeToken);
    const verificationCode = yield Select(SelectorsStoreRecoverPassword.getVerificationCode);

    myProps = { ...myProps, _otp: webOtp };

    const { formikBag, ...rest } = myProps;
    const { password, passwordConfirmation } = rest;

    let deviceLocationVU = yield Select(SelectorsStoreSession.getDeviceLocationVU);

    if (!deviceLocationVU || (deviceLocationVU.latitude === null && deviceLocationVU.longitude === null)) {
        deviceLocationVU = yield Call(UtilsVUFingerprint.getDeviceLocationVU);
    }

    const fingerprint = yield Call(UtilsVUFingerprint.getFingerprintVU);
    let fingerprintData = yield Call(UtilsVUFingerprint.getFingerprintDeviceInfoVU);

    if (UtilsDevice.mobileOS() === DEVICE_MOBILE.IOS) {
        fingerprintData = JSON.stringify(fingerprintData);
    }

    const params = {
        _deviceFingerprintData: fingerprintData,
        _deviceLocationVU: deviceLocationVU,
        _fingerprint: fingerprint,
        _password: password,
        _passwordConfirmation: passwordConfirmation,
        _verificationCode: verificationCode,
    };

    const response = yield Call(SelectorsMiddleware.recoveryPassStep3, params, exchangeToken);

    if (response.type === RESPONSE_TYPE.WARNING) {
        if (response.data.code === VALIDATION_ERROR) {
            formikBag.setErrors(AdjustIdFieldErrors(response.data.data));

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.RECOVERY_PASSWORD],
                }),
            );
            yield Put(SelectorsActionsRecoverPassword.recoverPassStep3Failure());
        } else {
            yield Put(SelectorsActionsRecoverPassword.recoverPassCleanUp());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.EXTERNAL_DASHBOARD],
                }),
            );
        }
    } else {
        const { blocked } = response.data.data;

        yield Put(SelectorsActionsRecoverPassword.recoverPassStep3Success({ blocked }));
        yield Put(Push("/recoveryPass/step4"));
    }

    formikBag.setSubmitting(false);
    return undefined;
}
