import React from "react";

import { goBack as GoBack } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";

import { PRODUCT_TYPE } from "~/constants";
import Container from "~/containers/Internal/Form/List";
import { SelectorsStore as SelectorsStoreAccount } from "~/store/account";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import UtilLodash from "~/util/lodash";

import I18n from "~/components/I18n";
import Link from "~/components/Link";

export const NAME = "AccountsOperations";

export const PROP = {
    types: {
        isChangingEnvironment: PropTypes.bool,
        permissions: PropTypes.object,
    },
    defaults: {
        isChangingEnvironment: false,
        permissions: null,
    },
};

export function Component(props) {
    const { bankStatementsAccounts, dispatch, idAccount, isFetching, permissions, productType } = props;

    const wait = isFetching || !permissions;

    if (wait) {
        return <Container name={NAME} head-title="menu.operations" wait />;
    }

    const { transferInternal, transferForeign, transferLocal, transferThirdParties, requestCheckbook } =
        permissions || {};
    const hasStatements = !UtilLodash.isEmpty(bankStatementsAccounts);

    const accountOperations = [
        transferInternal && {
            key: "menu.options.transfers.internal",
            to: `/form/transferInternal?debitAccount=${idAccount}`,
        },
        transferThirdParties && {
            key: "menu.options.transfers.thirdParties",
            to: `/form/transferThirdParties?debitAccount=${idAccount}`,
        },
        transferLocal && {
            key: "menu.options.transfers.local",
            to: `/form/transferLocal?debitAccount=${idAccount}`,
        },
        transferForeign && {
            key: "menu.options.transfers.foreign",
            to: `/form/transferForeign?debitAccount=${idAccount}`,
        },
        {
            key: "accounts.searchMovements",
            to: `/accounts/${idAccount}/filters`,
        },
        requestCheckbook &&
            productType === PRODUCT_TYPE.CHECKING_ACCOUNT && {
                key: "menu.accounts.requestCheckbook",
                to: `/form/requestCheckbook?idAccount=${idAccount}`,
            },
        hasStatements && {
            key: "accounts.stateOfAccount",
            to: `/accounts/${idAccount}/stateaccount`,
        },
    ].filter(Boolean);

    if (productType !== PRODUCT_TYPE.CUI) {
        accountOperations.push({
            key: "accounts.change.of.alias",
            to: `/accounts/${idAccount}/setAlias`,
        });
    }

    return (
        <Container name={NAME} head-onBack={handleBack} head-title="menu.operations">
            {accountOperations.map(({ key, to }) => (
                <Link key={key} to={to}>
                    <I18n id={key} />
                </Link>
            ))}
        </Container>
    );

    function handleBack() {
        dispatch(GoBack());
    }
}
Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

const mapStateToProps = (store, props) => {
    const account = SelectorsStoreAccount.getSelectedAccount(store);

    return {
        bankStatementsAccounts: SelectorsStoreAccount.getStateOfAccount(store),
        idAccount: props.match.params.id,
        isFetching: SelectorsStoreAccount.getFetching(store),
        isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
        permissions: account && account.permissions,
        productType: account && account.productType,
    };
};

export default Connect(mapStateToProps)(WithRouter(Component));
