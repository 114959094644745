import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { Categorize, Prefix } from "~/util/prop";

import HighOrder from "~/components/HighOrder";

import Unavailable from "~/pages/error/Unavailable";

import Page from "../_Page";
import Style from "./index.rules.scss";

import Internal, { PROP as PROP_INTERNAL } from "..";

export const { NAME, TYPE } = Style;
export const PROP = {
    types: {
        children: PropTypes.node.isRequired,
        name: PropTypes.string,
        isDesktop: PropTypes.bool,
        ...PROP_INTERNAL.types,
    },
    defaults: {
        name: EMPTY_STR,
        isDesktop: false,
        ...PROP_INTERNAL.defaults,
    },
};

export function Component(props) {
    const { name, children, head, header, wait, isDesktop, scopeToShowNotification, ...rest } = Categorize(props);
    const isAdminTicket = name === "AdministrationTicket";
    const isConnectedPartiesForm = name === "ConnectedPartiesForm";
    const isListPapers = name === "ListPapers";
    const isChangeAccountsDataForm = name === "ChangeDataAccountsForm";
    const isChangePersonalDataForm = name === "PersonalInformationForm";
    const isPersonalInformationTicket = name === "PersonalInformationTicket";

    return (
        <Internal
            {...Prefix("head", head)}
            id={Style.ID}
            name={NAME}
            wait={wait}
            scopeToShowNotification={scopeToShowNotification}>
            <Page {...rest} header={header} name={name}>
                {isChangeAccountsDataForm ||
                isChangePersonalDataForm ||
                isPersonalInformationTicket ||
                isDesktop ||
                isAdminTicket ||
                isConnectedPartiesForm ||
                isListPapers ? (
                    children
                ) : (
                    <Unavailable label="global.feature.resolution.unavailable" />
                )}
            </Page>
        </Internal>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default HighOrder(HighOrder.Resizable)(Component);
