import React from "react";

import { Col, Grid, Row } from "react-bootstrap";
import { connect as Connect } from "react-redux";

import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";

import TransactionStatus from "~/pages/transactions/_components/TransactionStatus";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component() {
    return (
        <div id={Style.ID}>
            <section className="container--layout align-items-center flex-grows">
                <Grid className="form-content">
                    <Row className="justify-content-center">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <div className="media-object ">
                                <figure>
                                    <TransactionStatus idTransactionStatus="FINISHED" />
                                </figure>
                                <div className="media-object--text">
                                    <p className="text-lead">
                                        <I18n id="forms.transaction.ticket.status.FINISHED" />;
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </section>

            {renderConfirmationWithoutTicket()}
        </div>
    );

    function renderConfirmationWithoutTicket() {
        return (
            <section className="container--layout align-items-center">
                <Grid className="form-content">
                    <Row className="justify-content-center">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <p className="text-lead">
                                <strong>
                                    <I18n id="forms.transactionsWithoutTicket.step3.disclaimer" />
                                </strong>
                            </p>
                        </Col>
                    </Row>
                </Grid>
            </section>
        );
    }
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

function mapStateToProps() {
    return {};
}

export default HighOrder(Connect(mapStateToProps))(Component);
