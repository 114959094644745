import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsAction as SelectorsActionsFiles } from "~/store/files";
import {
    SelectorsAction as SelectorsActionsMultilineFile,
    SelectorsStore as SelectorsStoreMultilineFile,
} from "~/store/multilinefile";

export const NAME = "FileActions";

export const PROP = {
    types: {
        value: PropTypes.arrayOf(
            PropTypes.shape({
                fileId: PropTypes.number,
                fileSize: PropTypes.number.isRequired,
                fileName: PropTypes.string.isRequired,
                fileType: PropTypes.string.isRequired,
            }),
        ),
        idRelatedFile: PropTypes.number,
        nameRelatedFile: PropTypes.string,
        setValue: PropTypes.func.isRequired,
        render: PropTypes.func.isRequired,
        renderPreview: PropTypes.func,
        editing: PropTypes.bool.isRequired,
    },
    defaults: {
        value: null,
        renderPreview: null,
        idRelatedFile: null,
        nameRelatedFile: null,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        const { value, setValue } = this.props;

        if (value === null) {
            setValue([]);
        }
    }

    onAddFile = (file) => {
        const { value, setValue, dispatch } = this.props;
        const nextValue = [...value, file];

        dispatch(SelectorsActionsMultilineFile.setMetadata({ fileMetadata: file }));
        setValue(nextValue);
    };

    onRemoveFile = (fileId) => {
        const { value, setValue } = this.props;

        setValue(value.filter((file) => file.fileId !== fileId));
    };

    downloadFile = (...params) => {
        const { dispatch, value, idRelatedFile, nameRelatedFile } = this.props;

        let idFile = EMPTY_STR;
        let fileName = EMPTY_STR;
        if (params.length && params[0].isDownloadingRelatedFile) {
            idFile = idRelatedFile;
            fileName = nameRelatedFile;
        } else {
            [idFile, fileName] = params.length ? params : [value[0].fileId, value[0].fileName];
        }

        dispatch(SelectorsActionsFiles.downloadFileRequest({ idFile, fileName }));
    };

    render() {
        const { render, renderPreview, value, editing } = this.props;

        if (editing) {
            return render({
                onAddFile: this.onAddFile,
                onRemoveFile: this.onRemoveFile,
                downloadFile: this.downloadFile,
            });
        }

        if (value && value.length > 0) {
            return renderPreview
                ? renderPreview({ downloadFile: this.downloadFile, value: value || [] })
                : value.map((file) => (
                      <button
                          type="button"
                          className="btn-asLink"
                          key={file.fileId}
                          onClick={() => this.downloadFile(file.fileId, file.fileName)}>
                          {file.fileName}
                      </button>
                  ));
        }

        return null;
    }
}

const mapStateToProps = (store) => ({
    idRelatedFile: SelectorsStoreMultilineFile.getIdRelatedFile(store),
    nameRelatedFile: SelectorsStoreMultilineFile.getNameRelatedFile(store),
});

export default Connect(mapStateToProps)(Component);
