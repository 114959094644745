import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "session";

export const PATH = {
    FINGERPRINT_SESSION_EXIST: "/fingerprint.sessionExist",
    LOGIN_BIOMETRIC: "/login.biometric",
    PERMISSIONS_READ: "/permissions.read",
    SESSION_CHANGE_ENVIRONMENT: "/session.changeEnvironment",
    SESSION_EXTEND: "/session.extend",
    SESSION_GET: "/session.get",
    SESSION_LOGIN_STEP1: "/session.login.step1",
    SESSION_LOGIN_STEP2: "/session.login.step2",
    SESSION_LOGIN_STEP3: "/session.login.step3",
    SESSION_LOGIN_STEP4: "/session.login.step4",
    SESSION_LOGOUT: "/session.logout",
};

export const TYPE = FactoryType(NAME, [
    "ACCEPT_MOBILE_REQUIRED_PERMISSIONS",
    "CANCEL_USER_EMAIL_FORCED_SYNC",
    "CANCEL_USER_MOBILE_PHONE_FORCED_SYNC",
    "CHANGE_ENVIRONMENT_ERROR",
    "CHANGE_ENVIRONMENT_REQUEST",
    "CHANGE_ENVIRONMENT_SUCCESS",
    "CHANGE_RISK_PROFILE_DATA",
    "EMAIL_FORCE_UPDATE_CANCEL",
    "EMAIL_FORCE_UPDATE_SUCCESS",
    "EMAIL_FORCE_UPDATE",
    "ERROR",
    "EXPIRE",
    "EXTEND_SUCCESS",
    "EXTEND",
    "FINISH_USER_EMAIL_FORCE_SYNC",
    "FINISH_USER_MOBILE_PHONE_FORCE_SYNC",
    "GET_DEVICE_LOCATION_VU",
    "GET_USER_EMAIL_FORCE_SYNC",
    "GET_USER_MOBILE_PHONE_FORCE_SYNC",
    "LOGOUT_REQUEST",
    "MOBILE_PHONE_FORCE_UPDATE_CANCEL",
    "MOBILE_PHONE_FORCE_UPDATE_SUCCESS",
    "MOBILE_PHONE_FORCE_UPDATE",
    "NEEDS_BIOMETRIC",
    "NOTIFY_USER",
    "READ_PERMISSIONS_REQUEST",
    "READ_PERMISSIONS_SUCCESS",
    "RESET_EMAIL_FORCE_UPDATE",
    "RESET_FETCHING",
    "RESET_MOBILE_PHONE_FORCE_UPDATE",
    "RESET_SUGGEST_ALIAS_ENROLLMENT_SHOW_HEADER",
    "RESET_SUGGEST_ALIAS_ENROLLMENT",
    "RESET_USER_EMAIL_FORCE_SYNC",
    "RESET_USER_MOBILE_PHONE_FORCE_SYNC",
    "SAVE_DEVICE_LOCATION_VU",
    "SAVE_QR_DATA",
    "SAVE_WEB_TOKEN",
    "SELECT_USER_EMAIL_TO_SYNC",
    "SELECT_USER_MOBILE_PHONE_TO_SYNC",
    "SUGGEST_ALIAS_ENROLLMENT",
]);

export const PROP = {};

PROP.types = {
    accountDataForceUpdate: PropTypes.bool,
    accountDataNotify: PropTypes.bool,
    activeEnvironment: PropTypes.object,
    changingEnvironment: PropTypes.bool,
    deviceLocationVU: PropTypes.object,
    emailForceUpdate: PropTypes.bool,
    emailForceUpdateFlow: PropTypes.bool,
    environments: PropTypes.arrayOf(PropTypes.object),
    fetching: PropTypes.bool,
    isAdministrator: PropTypes.bool,
    mobilePhoneForceUpdate: PropTypes.bool,
    mobilePhoneForceUpdateFlow: PropTypes.bool,
    personalDataForceUpdate: PropTypes.bool,
    personalDataNotify: PropTypes.bool,
    productGroupId: PropTypes.string,
    qrData: PropTypes.object,
    suggestAliasEnrollment: PropTypes.bool,
    suggestAliasEnrollmentShowHeader: PropTypes.bool,
    user: PropTypes.object,
    userEmailForceSync: PropTypes.bool,
    userEmailSelected: PropTypes.string,
    userMobilePhoneForceSync: PropTypes.bool,
    userMobilePhoneSelected: PropTypes.string,
    webToken: PropTypes.object,
    ...TypesRedux,
};

PROP.defaults = {
    accountDataForceUpdate: false,
    accountDataNotify: false,
    activeEnvironment: null,
    changingEnvironment: false,
    deviceLocationVU: null,
    emailForceUpdate: false,
    emailForceUpdateFlow: false,
    environments: [],
    fetching: false,
    isAdministrator: false,
    mobilePhoneForceUpdate: false,
    mobilePhoneForceUpdateFlow: false,
    personalDataForceUpdate: false,
    personalDataNotify: false,
    productGroupId: null,
    qrData: null,
    suggestAliasEnrollment: false,
    suggestAliasEnrollmentShowHeader: false,
    user: null,
    userEmailForceSync: false,
    userEmailSelected: null,
    userMobilePhoneForceSync: false,
    userMobilePhoneSelected: null,
    webToken: null,
    ...DefaultsRedux,
};
