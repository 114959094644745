import React from "react";

import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { EMPTY_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreWMBonds, SelectorsAction as SelectorsActionWMBonds } from "~/store/wm/bonds";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import DataNumber from "~/components/DataNumber";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Token from "~/components/Token/Token";

import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        isSubmitting: PropTypes.bool,
    },
    defaults: {
        isSubmitting: false,
    },
};

export function Component(props) {
    const {
        isSubmitting,
        infoData: { currency, debitAccountAlias, nominalValue, paperName, price },
    } = props;

    return (
        <Form id={Style.ID}>
            <Box flex directionColumn align="center" className="mb-3">
                <I18n id="forms.default.confirmation.title" tag="p" />

                <I18n id="client.wm.bonds.buy.step2.paperName" tag="p" className="mt-3" />
                <h3 className="bold">{paperName}</h3>

                <I18n id="client.wm.bonds.buy.step2.nominalValue" tag="p" className="mt-3" />
                <h3>
                    <DataNumber value={nominalValue} prefix={currency} />
                </h3>

                <I18n id="client.wm.bonds.buy.step2.price" tag="p" className="mt-3" />
                <I18n id={`client.wm.bonds.buy.step2.price.${price}`} tag="h3" />

                <I18n id="client.wm.bonds.buy.step2.account" tag="p" className="mt-3" />
                <h3>{debitAccountAlias}</h3>
            </Box>
            <Token labelButton="global.send" isSubmitting={isSubmitting} />
        </Form>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        infoData: SelectorsStoreWMBonds.getInfoData(store),
        previewData: SelectorsStoreWMBonds.getPreviewData(store),
        transaction: SelectorsStoreWMBonds.getTransaction(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithRouter,
    WithFormik({
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(UtilsI18n.get("client.wm.bonds.step2.otp.required")),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const {
                dispatch,
                history,
                previewData,
                transaction: { idTransaction, idActivity },
                infoData: { debitAccountAlias },
            } = formikBag.props;

            const isFromTransactionsHistory = history.location.pathname.includes("/ticket");
            if (isFromTransactionsHistory) {
                dispatch(
                    SelectorsActionWMBonds.signTransaction({
                        idForm: null,
                        idActivity,
                        idTransaction,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            } else {
                dispatch(
                    SelectorsActionWMBonds.buyBondsSendForm({
                        submitData: { ...previewData, debitAccountAlias },
                        credentials: { otp },
                        formikBag,
                    }),
                );
            }
        },
    }),
)(Component);
