import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "multilinefile";

export const PATH = {
    DELETE_FILE: "/files.delete",
};

export const TYPE = FactoryType(NAME, [
    "CLEAR_POND",
    "DELETE_FILE_REQUEST",
    "DELETE_FILE_FAILURE",
    "DELETE_FILE_SUCCESS",
    "ON_FILE_PROCESS",
    "ON_FILE_REMOVED",
    "ON_PAYMENT_METHOD_CHANGE",
    "SET_EXTRA_DATA",
    "SET_METADATA",
]);

export const PROP = {};

PROP.types = {
    extraData: PropTypes.shape({
        debitCurrency: PropTypes.string,
        idDebitAccount: PropTypes.string,
    }),
    fileIdentifier: PropTypes.string,
    forcePondCleanUp: PropTypes.bool,
    hasFile: PropTypes.bool,
    idRelatedFile: PropTypes.number,
    invalidLines: PropTypes.number,
    localBanks: PropTypes.array,
    nameRelatedFile: PropTypes.string,
    totalAmount: PropTypes.shape({
        quantity: PropTypes.number,
        currency: PropTypes.number,
    }),
    validLines: PropTypes.number,
    ...TypesRedux,
};

PROP.defaults = {
    extraData: {
        debitCurrency: EMPTY_STR,
        idDebitAccount: EMPTY_STR,
    },
    fileIdentifier: EMPTY_STR,
    forcePondCleanUp: false,
    hasFile: false,
    idRelatedFile: null,
    invalidLines: null,
    localBanks: [],
    nameRelatedFile: null,
    totalAmount: {
        quantity: 0,
        currency: EMPTY_STR,
    },
    validLines: 0,
    ...DefaultsRedux,
};
