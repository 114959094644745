/* global window navigator */

export const DISPLAY = {
    DESKTOP: "desktop",
    MOBILE: "mobile",
};

export const DEVICE_MOBILE = {
    WINDOWS_PHONE: "Windows Phone",
    ANDROID: "Android",
    IOS: "iOS",
    UNKNOWN: "unknown",
};

/**
 * Device Utilities.
 *
 * Returns information about the current device.
 * @note This completely depends on Cordova runing as wrapper, don't use this without checking fow isMobileNative first.
 */
export default Object.create({
    /**
     * @returns {boolean} - Whether the current device is running in NativeMode (Cordova)
     */
    isMobileNative() {
        return !!window.cordova;
    },

    /**
     * @returns {boolean} - Whether the current device has a display corresponding of a desktop.
     */
    isDisplayDesktop() {
        return this.display() === DISPLAY.DESKTOP;
    },

    /**
     * @returns {boolean} - Whether the current device has a display corresponding of a desktop.
     */
    isDisplayMobile() {
        return this.display() === DISPLAY.MOBILE;
    },

    /**
     * @returns {boolean} - Whether the current device has a display corresponding of a small desktop.
     * FOR CAMPAIGNS USE ONLY!
     */
    isIntermediateResolution() {
        return window.innerWidth >= 768 && window.innerWidth < 1024;
    },

    /**
     * @returns  {string} - The device identifier asigned by Cordova.
     */
    id() {
        return window.deviceInfo.getId();
    },

    /**
     * @returns {string} - The platform identifier assigned by Cordova.
     */
    platform() {
        return window.deviceInfo.getPlatform();
    },

    /**
     * @returns {string} - The manufacturer identifier assigned by Cordova.
     */
    manufacturer() {
        return window.deviceInfo.getManufacturer();
    },

    /**
     * @returns {string} - The model identifier assigned by Cordova.
     */
    model() {
        return window.deviceInfo.getModel();
    },

    /**
     * @returns {string} - A DISPLAY identifier according to current window.innerWidth.
     */
    display() {
        return window.innerWidth >= 768 ? DISPLAY.DESKTOP : DISPLAY.MOBILE;
    },

    /**
     * @returns {string} - The MOBILE_DEVICE identifier corresponding to current device.
     */
    mobileOS() {
        // The device is running natively.
        if (this.isMobileNative()) {
            // TODO: Are the results of this.platform compatible with the DEVICE_MOBILE constant?
            return this.platform();
        }
        // This was called on a "desktop-like" environment, don't bother determining the user agent.
        // TODO: What about tablets? the way this.display works, it's completely unreliable.
        if (this.display() !== DISPLAY.MOBILE) {
            return DEVICE_MOBILE.UNKNOWN;
        }
        // Calculate the corresponding device using the userAgent
        // TODO: Are we going to live with UA Spoofing? shouldn't we feature detect instead?
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/windows phone/i.test(userAgent)) {
            return DEVICE_MOBILE.WINDOWS_PHONE;
        }
        if (/android/i.test(userAgent)) {
            return DEVICE_MOBILE.ANDROID;
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return DEVICE_MOBILE.IOS;
        }
        return DEVICE_MOBILE.UNKNOWN;
    },

    isLandscapeMobile() {
        const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

        return toMatch.some((toMatchItem) => {
            let result = false;
            if (navigator.userAgent.match(toMatchItem)) {
                if (window.screen.availHeight < window.screen.availWidth) {
                    result = true;
                }
            }
            return result;
        });
    },

    forcedAppExit() {
        if (this.isMobileNative()) {
            window.cordovaPluginExit.exit();
        }
    },
});
