import React from "react";

import { Field } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, DEFAULT_COUNTRY, REGEX_IGNORE_ACCENTS } from "~/constants";
import { MODE } from "~/constants/form";
import { SelectorsAction, SelectorsStore } from "~/store/settings";
import * as UtilI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import I18n from "~/components/I18n";
import TextArea from "~/pages/_components/fields/TextArea";

import TextField from "../field/InputText";
import Select from "../field/Selector";
import SelectorOrInput from "../field/SelectorOrInput";
import SelectorType from "../field/SelectorType";
import Style from "./FormAddresses.rules.scss";

const FORM_ID = "change.PersonalData";

export const NAME = "FormAddresses";

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        legal: PropTypes.object.isRequired,
        correspondense: PropTypes.object.isRequired,
        values: PropTypes.object.isRequired,
        setFieldValue: PropTypes.func.isRequired,
        addresses: PropTypes.object,
        countryList: PropTypes.array,
        cityList: PropTypes.array,
        townList: PropTypes.array,
        loadData: PropTypes.array,
        ...TypesRedux,
    },
    defaults: {
        ...DefaultsRedux,
        addresses: {},
        countryList: [{ id: EMPTY_STR, name: EMPTY_STR }],
        cityList: [{ id: EMPTY_STR, name: EMPTY_STR }],
        townList: [{ id: EMPTY_STR, name: EMPTY_STR }],
        loadData: [{ id: EMPTY_STR, name: EMPTY_STR }],
    },
};
export function Component({
    legal,
    correspondense,
    dispatch,
    values,
    setFieldValue,
    addresses: { idCountry },
    countryList,
    cityList,
    townList,
    loadData,
    ...rest
}) {
    const [idContryTemp, setIdContryTemp] = React.useState(idCountry || DEFAULT_COUNTRY);

    React.useLayoutEffect(() => {
        dispatch(SelectorsAction.changeAddressesRequest({}));
    }, [dispatch]);

    const extra = { legal, mailings: correspondense };

    const handleChangeCountry = (id) => {
        if(id){
            setIdContryTemp(id);
            dispatch(SelectorsAction.changeAddressesDepartamentRequest({ idCountry: id }));
        }
    };

    const handleChangeDepartament = (id) => {
        if (id !== 0) {
            dispatch(SelectorsAction.changeAddressesCityRequest({ idCountry: idContryTemp, idCity: id }));
        }
    };

    const customFilter = (option, searchText)=>{
        const optionLowerCase = option.name.toLowerCase();
        const text = searchText.toLowerCase();

        if(optionLowerCase.normalize("NFD").replace(REGEX_IGNORE_ACCENTS, "").includes(text) && optionLowerCase.charAt(0).includes(text.charAt(0))){
            return true;
        }
        else {
            return false
        }
    }

    const onChangeValue = (val) => {
        dispatch(
            SelectorsAction.changeAddressesRequest({
                idCountry: extra[val].idCountry,
                idCity: extra[val].idDepartament,
            }),
        );
        setFieldValue("country", extra[val].idCountry);
        setFieldValue("departament", extra[val].idDepartament);
        setFieldValue("city", extra[val].idCity);
        setFieldValue("street", extra[val].street);
        setFieldValue("doorNumber", extra[val].doorNumber);
        setFieldValue("apto", extra[val].apto);
        setFieldValue("zipCode", extra[val].zipCode);
        setFieldValue("observations", extra[val].observations);
    };

    const textOpcinal = UtilI18n.get("form.field.optional");
    const renderCity = values?.departament?.toString() !== "0";
    const noOptionText = UtilI18n.get("selector.noOption.text")

    return (
        <div id={Style.ID} className="container--data">
            <React.Fragment>
                <div className=" field-spacing">
                    <div className=" field-spacing">
                        <SelectorType
                            idForm={FORM_ID}
                            name="loadData"
                            options={loadData}
                            label={UtilI18n.get("change.PersonalData.loadData.label")}
                            valueKey="id"
                            labelKey="name"
                            noOption={noOptionText}
                            searchable
                            onChange={onChangeValue}
                            customFilter={(option, searchText)=> customFilter(option, searchText)}
                        />
                    </div>

                    <Select
                        idForm={FORM_ID}
                        name="country"
                        options={countryList}
                        label={UtilI18n.get("change.PersonalData.country.label")}
                        nameType="idCountry"
                        valueKey="id"
                        labelKey="name"
                        searchable
                        onChange={handleChangeCountry}
                        setFieldValue={setFieldValue}
                        noOption={noOptionText}
                        inputProps={{spellCheck: "false"}}
                        customFilter={(option, searchText)=> customFilter(option, searchText)}
                        {...rest}
                    />
                </div>

                <div className=" field-spacing">
                    <SelectorOrInput
                        name="departament"
                        nameOther="otherDepartament"
                        nameType="idDepartament"
                        idForm={FORM_ID}
                        valueKey="id"
                        options={cityList}
                        labelKey="name"
                        label="departament"
                        onChange={handleChangeDepartament}
                        defaultsValue=""
                        dependencyField={{ country: values?.country, fieldMe: values?.departament }}
                        setFieldValue={setFieldValue}
                        lengthOther={20}
                        searchable
                        noOption={noOptionText}
                        inputProps={{spellCheck: "false"}}
                        customFilter={(option, searchText)=> customFilter(option, searchText)}
                        {...rest}
                    />
                </div>

                <div className=" field-spacing">
                    <SelectorOrInput
                        name="city"
                        nameOther="otherCity"
                        lengthOther={30}
                        nameType="idCity"
                        idForm={FORM_ID}
                        options={townList}
                        valueKey="id"
                        labelKey="name"
                        label="city"
                        searchable
                        dependencyField={{ country: values?.country, fieldMe: values?.city }}
                        renderSelector={renderCity}
                        setFieldValue={setFieldValue}
                        noOption={noOptionText}
                        inputProps={{spellCheck: "false"}}
                        customFilter={(option, searchText)=> customFilter(option, searchText)}
                        {...rest}
                    />
                </div>

                <div className=" field-spacing">
                    <TextField
                        idForm={FORM_ID}
                        nameType="street"
                        name="street"
                        autoFocus={false}
                        mode={MODE.EDIT}
                        hidePlaceholder
                        maxLength={35}
                        setFieldValue={setFieldValue}
                    />
                </div>

                <div className=" field-spacing">
                    <TextField
                        idForm={FORM_ID}
                        nameType="doorNumber"
                        name="doorNumber"
                        autoFocus={false}
                        mode={MODE.EDIT}
                        hidePlaceholder
                        maxLength={5}
                        setFieldValue={setFieldValue}
                    />
                </div>

                <div className=" field-spacing">
                    <TextField
                        idForm={FORM_ID}
                        nameType="apto"
                        name="apto"
                        autoFocus={false}
                        mode={MODE.EDIT}
                        hidePlaceholder
                        maxLength={6}
                        optional={textOpcinal}
                        setFieldValue={setFieldValue}
                    />
                </div>

                <div className=" field-spacing">
                    <TextField
                        idForm={FORM_ID}
                        nameType="zipCode"
                        name="zipCode"
                        autoFocus={false}
                        mode={MODE.EDIT}
                        hidePlaceholder
                        maxLength={8}
                        setFieldValue={setFieldValue}
                        optional={textOpcinal}
                    />
                </div>

                <div className="field-observations field-spacing">
                    <Field
                        idForm={FORM_ID}
                        name="observations"
                        component={TextArea}
                        autoFocus={false}
                        mode={MODE.EDIT}
                        hidePlaceholder
                        maxLength={75}
                        optional={textOpcinal}
                        tooltip={
                            <div className="field-observations--information">
                                <I18n id="change.PersonalData.observations.informative" />
                            </div>
                        }
                    />
                </div>

                <div className="form--personal_extrainformation">
                    <I18n id="change.PersonalData.extrainformation" />
                </div>
            </React.Fragment>
        </div>
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    countryList: SelectorsStore.getCountryList(store),
    cityList: SelectorsStore.getCityList(store),
    townList: SelectorsStore.getTownList(store),
    legal: SelectorsStore.getLegal(store),
    correspondense: SelectorsStore.getCorrespondense(store),
    addresses: SelectorsStore.getAdresses(store),
    loadData: SelectorsStore.getLoadData(store),
});
export default Connect(mapStateToProps)(Component);
