import React from "react";

import PropTypes from "prop-types";

export const NAME = "ActionsList";

export const PROP = {};

PROP.members = {};
PROP.types = {
    className: PropTypes.string,
};
PROP.defaults = {
    classes: "",
};

/**
 * Renders a list of actions availables.
 * @param {PROP.types} props
 */

export function Component(props) {
    const { children, classes } = props;
    return <div className={classes}>{children}</div>;
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
