import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, CDP_CURRENCY } from "~/constants";
import { SelectorsStore, SelectorsAction } from "~/store/investment";
import * as UtilsI18n from "~/util/i18n";

import DataNumber from "~/components/DataNumber";
import I18n from "~/components/I18n";
import Ticket from "~/components/Ticket/Ticket";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
        transaction: PropTypes.shape({
            executing: PropTypes.bool,
            idTransaction: PropTypes.string,
            idTransactionStatus: PropTypes.string,
            valueDateAsString: PropTypes.string,
        }).isRequired,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

const FORM_ID = "investment.termDeposit.form";

export function Component(props) {
    const { dispatch, ticketConfirmation, transaction } = props;
    const { data } = transaction || {};
    const {
        amount,
        creditProductLabel,
        customRate,
        customTerm,
        debitProductLabel,
        expirationInstruction,
        interest,
        rate,
        rateType,
        term,
    } = data || {};

    const moreOptionsLabel = UtilsI18n.get(`${FORM_ID}.term.option.moreOptions`);
    const isMoreOptions = moreOptionsLabel === term;

    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}
                    hasMobileComponent>
                    <div className="data-wrapper">
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="wm.termdeposit.ticket.labels.currency" />
                            </span>
                            <span className="data-name">{UtilsI18n.get(`core.currency.label.${amount.currency}`)}</span>
                        </div>

                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="wm.termdeposit.ticket.labels.quantity" />
                            </span>
                            <span className="data-name">
                                <DataNumber
                                    value={amount.quantity}
                                    prefix={amount.currency === CDP_CURRENCY.UI ? CDP_CURRENCY.UYU : amount.currency}
                                    className="mobile-only amount-data"
                                />
                            </span>
                        </div>

                        {interest && (
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="wm.termdeposit.ticket.labels.interest" />
                                </span>
                                <span className="data-name">
                                    <DataNumber
                                        value={interest}
                                        prefix={
                                            amount.currency === CDP_CURRENCY.UI ? CDP_CURRENCY.UYU : amount.currency
                                        }
                                        className="mobile-only amount-data"
                                    />
                                </span>
                            </div>
                        )}

                        {(rate || customRate) && (
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="wm.termdeposit.ticket.labels.rate" />
                                </span>
                                <span className="data-name">
                                    <DataNumber
                                        value={isMoreOptions ? customRate : rate}
                                        suffix={`% ${UtilsI18n.get(`wm.termDeposit.rate.${rateType}`)}`}
                                        className="mobile-only amount-data"
                                    />
                                </span>
                            </div>
                        )}

                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="wm.termdeposit.ticket.labels.term" />
                            </span>
                            <span className="data-name">
                                {`${isMoreOptions ? customTerm : term} ${UtilsI18n.get(
                                    "wm.termdeposit.ticket.labels.termDays",
                                )}`}
                            </span>
                        </div>

                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="wm.termdeposit.ticket.labels.debitAccount" />
                            </span>
                            <span className="data-name">{debitProductLabel}</span>
                        </div>

                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="wm.termdeposit.ticket.labels.intruction" />
                            </span>
                            <span className="data-name">
                                {UtilsI18n.get(`termDeposit.instruction.${expirationInstruction}`)}
                            </span>
                        </div>
                        {expirationInstruction === 2 && (
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="wm.termdeposit.ticket.labels.creditAccount" />
                                </span>
                                <span className="data-name">{creditProductLabel}</span>
                            </div>
                        )}
                    </div>
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { idTransaction } = transaction;

        dispatch(
            SelectorsAction.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                formikBag,
                idTransaction,
            }),
        );
    }

    function handleSign() {
        dispatch(SelectorsAction.signTransactionPreview({ data }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStore.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
