import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { Categorize } from "~/util/prop";

import Image from "~/components/Image";

import Header from "../../_Header";
import Style from "./Simple.rules.scss";
import Form from "./index";

export const { NAME, TYPE } = Style;
export const PROP = {
    types: {
        "head-title": PropTypes.string,
        "head-onBack": PropTypes.func,
        "head-darkTheme": PropTypes.bool,
    },
    defaults: {
        "head-title": EMPTY_STR,
        "head-onBack": null,
        "head-darkTheme": false,
    },
};

export function Component(props) {
    const { children, head, image } = Categorize(props);
    const { onBack, title, onBackWeb, darkTheme } = head;

    const headerTheme = darkTheme ? "headerDark" : "headerLight";
    const hasBackClass = onBack || onBackWeb ? "hasBack" : EMPTY_STR;

    const className = props.className ? `${props.className} ${Style.ID}` : Style.ID;
    const finalHeader = (
        <Header className={`${hasBackClass} ${headerTheme}`}>
            {hasBackClass === "hasBack" && (
                <Header.Button
                    aria-label="Back"
                    role="navigation"
                    image="left-arrow.svg"
                    onClick={onBack || onBackWeb}
                />
            )}
            {image && <Image src={image} classNameOuter="icon" />}
            {title && <Header.Title value={title} />}
        </Header>
    );

    return (
        <Form {...props} header={finalHeader} className={className}>
            {children}
        </Form>
    );
}
Component.displayName = NAME;
Component.proptTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
