import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import I18n from "~/components/I18n";

import Style from "./FieldReadOnly.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...TypesRedux,

        value: PropTypes.string.isRequired,
        label: PropTypes.string,
        className: PropTypes.string,
    },
    defaults: {
        ...DefaultsRedux,

        label: EMPTY_STR,
        className: EMPTY_STR,
    },
};
export function Component({ value, label, className }) {
    return (
        <div className={classnames(Style.CLASS, className)}>
            {label && (
                <span className="data-label">
                    <I18n id={label} />:
                </span>
            )}
            <span className="data-value">{value}</span>
        </div>
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
