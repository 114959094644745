import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "details";

export const TYPE = (name) =>
    FactoryType(name, [
        "LOAD_DETAILS_REQUEST",
        "LOAD_DETAILS_FAILURE",
        "LOAD_DETAILS_SUCCESS",
        "UPDATE_SIGNATURE_REQUEST",
        "UPDATE_SIGNATURE_REQUEST_FAILURE",
        "UPDATE_SIGNATURE_REQUEST_SUCCESS",
        "LOAD_DATA_FOR_SIGN_UPDATE_SIGNATURE",
        "LOAD_FORM_REQUEST",
        "LOAD_FORM_FAILURE",
        "LOAD_FORM_SUCCESS",
    ]);

export const PROP = {};

PROP.members = {};

PROP.types = {
    credentialGroups: PropTypes.array,
    idTransaction: PropTypes.any,
    idActivity: PropTypes.any,
    hasSignatureEnabled: PropTypes.bool,
    fetching: PropTypes.bool,
    adminGroupsIds: PropTypes.array,
    ...TypesRedux,
};

PROP.defaults = {
    credentialGroups: [],
    idTransaction: null,
    idActivity: null,
    hasSignatureEnabled: false,
    fetching: false,
    adminGroupsIds: [],
    ...DefaultsRedux,
};
