import React from "react";

import PropTypes from "prop-types";

import { Types as TypesHtmlElement, Defaults as DefaultsHtmlElement } from "~/util/prop/html-element";

import Button from "~/components/Button";
import I18n from "~/components/I18n";
import Modal from "~/components/Modal";

export const NAME = "CancelConfirmationModal";

export const PROP = {
    types: {
        ...TypesHtmlElement,
        show: PropTypes.bool,
        handleCancelation: PropTypes.func,
        handleConfirmation: PropTypes.func,
    },
    defaults: {
        ...DefaultsHtmlElement,
        show: false,
        handleCancelation: null,
        handleConfirmation: null,
    },
};

export function Component(props) {
    const { show, handleCancelation, handleConfirmation } = props;
    return (
        <Modal show={show}>
            <div className="modal-text">
                <I18n id="global.modal.confirmation.message" />
            </div>
            <div className="modal-buttons">
                <div className="justify-content-center row">
                    <Button className="btn-primary" label="global.accept" onClick={handleConfirmation} />
                    <Button label="global.cancel" onClick={handleCancelation} />
                </div>
            </div>
        </Modal>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
