import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    searchSharesRequest: (props) => API.executeWithAccessToken(PATH.SEARCH_SHARES, props),

    /* BUY SHARES */
    buySharesPreRequest: (props) => API.executeWithAccessToken(PATH.BUY_SHARES_PRE_REQUEST, props),
    buySharesPreviewRequest: (props) => API.executeWithAccessToken(PATH.BUY_SHARES_PREVIEW_REQUEST, props),
    buySharesRequest: (props) => API.executeWithAccessToken(PATH.BUY_SHARES_REQUEST, props),

    /** SELL SHARES */
    sellSharesPre: (props) => API.executeWithAccessToken(PATH.SELL_SHARES_PRE_REQUEST, props),
    sellSharesPreview: (props) => API.executeWithAccessToken(PATH.SELL_SHARES_PREVIEW_REQUEST, props),
    sellSharesSend: (props) => API.executeWithAccessToken(PATH.SELL_SHARES_SEND_REQUEST, props),

    getPaper: (props) => API.executeWithAccessToken(PATH.GET_PAPER, props),
};
export const SelectorsAction = {
    /* SEARCH SHARES */
    searchSharesRequest: (props) => FactoryAction(TYPE.SEARCH_SHARES_REQUEST, props),
    searchSharesSuccess: (props) => FactoryAction(TYPE.SEARCH_SHARES_SUCCESS, props),
    searchSharesFailure: () => FactoryAction(TYPE.SEARCH_SHARES_FAILURE),

    /* BUY SHARES */
    buySharesPre: (props) => FactoryAction(TYPE.BUY_SHARES_PRE_REQUEST, props),
    buySharesPreSuccess: (props) => FactoryAction(TYPE.BUY_SHARES_PRE_SUCCESS, props),
    buySharesPreFailure: () => FactoryAction(TYPE.BUY_SHARES_PRE_FAILURE),
    buySharesPreview: (props) => FactoryAction(TYPE.BUY_SHARES_PREVIEW_REQUEST, props),
    buySharesPreviewSuccess: (props) => FactoryAction(TYPE.BUY_SHARES_PREVIEW_SUCCESS, props),
    buySharesPreviewFailure: () => FactoryAction(TYPE.BUY_SHARES_PREVIEW_FAILURE),
    buySharesSendForm: (props) => FactoryAction(TYPE.BUY_SHARES_SEND_FORM, props),
    buySharesSendFormSuccess: (props) => FactoryAction(TYPE.BUY_SHARES_SEND_FORM_SUCCESS, props),
    buySharesSendFormFailure: () => FactoryAction(TYPE.BUY_SHARES_SEND_FORM_FAILURE),

    /* SELL SHARES */
    sellSharesPre: (props) => FactoryAction(TYPE.SELL_SHARES_PRE_REQUEST, props),
    sellSharesPreSuccess: (props) => FactoryAction(TYPE.SELL_SHARES_PRE_SUCCESS, props),
    sellSharesPreFailure: (props) => FactoryAction(TYPE.SELL_SHARES_PRE_FAILURE, props),
    sellSharesPreview: (props) => FactoryAction(TYPE.SELL_SHARES_PREVIEW_REQUEST, props),
    sellSharesPreviewSuccess: (props) => FactoryAction(TYPE.SELL_SHARES_PREVIEW_SUCCESS, props),
    sellSharesPreviewFailure: (props) => FactoryAction(TYPE.SELL_SHARES_PREVIEW_FAILURE, props),
    sellSharesSend: (props) => FactoryAction(TYPE.SELL_SHARES_SEND_REQUEST, props),
    sellSharesSendSuccess: (props) => FactoryAction(TYPE.SELL_SHARES_SEND_SUCCESS, props),
    sellSharesSendFailure: (props) => FactoryAction(TYPE.SELL_SHARES_SEND_FAILURE, props),

    cancelTransaction: (props) => FactoryAction(TYPE.CANCEL_TRANSACTION_REQUEST, props),
    clearForm: () => FactoryAction(TYPE.CLEAR_FORM),
    clearOnClassificationChange: (props) => FactoryAction(TYPE.CLEAR_ON_CLASSIFICATION_CHANGE, props),
    clearSearchResults: () => FactoryAction(TYPE.CLEAR_SEARCH_RESULTS),
    getPaper: (props) => FactoryAction(TYPE.GET_PAPER, props),
    goStepBack: () => FactoryAction(TYPE.GO_STEP_BACK),
    goToComponent: () => FactoryAction(TYPE.GO_TO_COMPONENT),
    handleIdPaperSelected: (props) => FactoryAction(TYPE.PAPER_ID_SELECTED, props),
    readTransactionRequest: (props) => FactoryAction(TYPE.READ_TRANSACTION_REQUEST, props),
    readTransactionSuccess: (props) => FactoryAction(TYPE.READ_TRANSACTION_SUCCESS, props),
    restartFlow: () => FactoryAction(TYPE.RESTART_FLOW),
    signTransaction: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_REQUEST, props),
    signTransactionPreview: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_PREVIEW, props),
};

export const SelectorsStore = {
    getCreditAccountList: (store) => store[NAME].creditAccountList,
    getDebitAccountsList: (store) => store[NAME].debitAccountsList,
    getFormData: (store) => store[NAME].formData,
    getIdPaper: (store) => store[NAME].idPaper,
    getIsFetching: (store) => store[NAME].isFetching,
    getMode: (store) => store[NAME].mode,
    getMoreLines: (store) => store[NAME].moreLines,
    getPaper: (store) => store[NAME].paper,
    getPapersList: (store) => store[NAME].papersList,
    getPrevMode: (store) => store[NAME].prevMode,
    getPreviewData: (store) => store[NAME].previewData,
    getProcessingSale: (store) => store[NAME].processingSale,
    getSelectedType: (store) => store[NAME].selectedType,
    getSharesList: (store) => store[NAME].sharesList,
    getTimezoneOffset: (store) => store[NAME].timezoneOffset,
    getTransaction: (store) => store[NAME].transaction,
};
