import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE } from "~/constants";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction } from "./_selectors";

export default [TakeLatest(TYPE.DELETE_FILE_REQUEST, deleteFile)];

function* deleteFile(props) {
    const { idFile } = props;

    const {
        type,
        data: { message },
    } = yield Call(SelectorsMiddleware.deleteFile, { idFile });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.deleteFileFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.FORM],
            }),
        );
    } else {
        yield Put(SelectorsAction.deleteFileSuccess());
    }
}
