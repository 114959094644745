import React from "react";

import ClassNames from "classnames";
import { connect as Connect } from "react-redux";

import { SelectorsStore as SelectorsStoreTransactions } from "~/store/transactions";
import { generateId as GenerateId } from "~/util/general";

import GridLayout from "~/components/GridLayout";
import I18n from "~/components/I18n";
import Image from "~/components/Image";

import TransactionItem from "./TransactionItem";

export const { NAME } = "List";

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const {
        currentFilter,
        fetchMoreTransactions,
        hasMoreData,
        idTransactionList,
        isMobile,
        showFilters,
        transactions,
    } = props;

    const getTransactions = () => {
        const transactionsComponents = [];

        transactions.forEach((transaction) => {
            const approve = idTransactionList.indexOf(transaction.transaction.idTransaction) !== -1;

            transactionsComponents.push(
                <TransactionItem
                    approve={approve}
                    isMobile={isMobile}
                    key={GenerateId()}
                    onClickApproval={() => {}}
                    transaction={transaction}
                />,
            );
        });

        return transactionsComponents;
    };

    return (
        <div className={ClassNames(`filter-list qr-list filter-${currentFilter}`, { "filter-open": showFilters })}>
            {transactions && transactions.length > 0 ? (
                <div id="transactionsList" className="containerDetails container-fluid container-transaction-list">
                    {!isMobile && (
                        <GridLayout className="header">
                            <I18n id="servicePayment.qrModo.list.tableHead.store" />
                            <I18n id="servicePayment.qrModo.list.tableHead.date" />
                            <I18n id="servicePayment.qrModo.list.tableHead.amount" />
                            <I18n id="servicePayment.qrModo.list.tableHead.status" />
                        </GridLayout>
                    )}
                    {getTransactions()}
                    {hasMoreData && (
                        <div className="see-more" onClick={fetchMoreTransactions}>
                            <I18n id="gnb.transactions.history.more" />
                            <Image src="form-arrow-down.svg" />
                        </div>
                    )}
                </div>
            ) : (
                <div className="no-more">
                    <I18n id="transactions.list.none" />
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (store) => ({
    hasMoreData: SelectorsStoreTransactions.getHasMoreData(store),
    transactions: SelectorsStoreTransactions.getTransactions(store),
});

export default Connect(mapStateToProps)(Component);
