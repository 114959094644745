import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, SPACE_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreTicket } from "~/store/administration/common/administrationTicket";
import * as UtilsConfig from "~/util/config";
import * as UtilsI18n from "~/util/i18n";

import I18n from "~/components/I18n";
import FormattedAmount from "~/pages/_components/FormattedAmount";

import Style_SchemeMediumTicket from "./SignaturesSchemeMediumTicket.rules.scss";
import Style_Ticket from "./Ticket.rules.scss";

export const NAME = "SignaturesScheme";

export const PROP = {
    types: {
        isFromBackoffice: PropTypes.bool,
        transaction: PropTypes.object.isRequired,
    },
    defaults: {
        isFromBackoffice: false,
    },
};

export function Component({ transaction, isFromBackoffice }) {
    const getSigners = (groupsMap) => {
        if (groupsMap) {
            return Object.keys(groupsMap).reduce(
                (result, key) => result + key.toString().repeat(groupsMap[key]),
                EMPTY_STR,
            );
        }

        return null;
    };

    const groupsMap = transaction?.data?.signatureLevelsCounts;
    const signatureType = transaction?.data?.signatureType;
    const topAmount = transaction?.data?.topAmount;

    const getData = (idActivity) => {
        if (idActivity === "administration.signatures.delete.send") {
            /* Signature deletion */
            return (
                <React.Fragment>
                    {/* Scheme */}
                    <div className="form--signature--modify--field">
                        <div className="control-label form-group-text data-label">
                            <I18n id="administration.signatures.delete.ticket.signatureGroup" />
                        </div>
                        <span>{transaction.data.groupsMap}</span>
                    </div>
                </React.Fragment>
            );
        }

        /* Signature Creation and Modification */
        return (
            <React.Fragment>
                {/* Scheme */}
                <div className="form--signature--modify--field">
                    <div className="control-label form-group-text data-label">
                        <I18n id="administration.signatures.ticket.signatureGroup" />
                    </div>
                    <span>{getSigners(groupsMap)}</span>
                </div>
                {/* SignatureType */}
                <div className="form--signature--modify--field">
                    <div className="control-label form-group-text data-label">
                        <I18n id="administration.signatures.ticket.signatureType" />
                    </div>
                    <span>{UtilsI18n.get(`signatures.type.${signatureType}`)}</span>
                </div>
                {/* Max Amount */}
                {signatureType === "AMOUNT" && (
                    <div className="form--signature--modify--field">
                        <div className="control-label form-group-text data-label">
                            <I18n id="administration.signatures.ticket.maxAmount" />
                        </div>
                        <FormattedAmount
                            className="data-amount"
                            frequencyInSpan={false}
                            currency={UtilsConfig.get("core.masterCurrency")}
                            quantity={topAmount.amount}
                            frequency={UtilsI18n.get("administration.signatures.advanced.by")
                                .concat(SPACE_STR)
                                .concat(
                                    UtilsI18n.get(
                                        `administration.signatures.transactions.capFrequency.${topAmount.period}`,
                                    ),
                                )}
                        />
                    </div>
                )}
            </React.Fragment>
        );
    };

    return (
        <div>
            <div id={isFromBackoffice ? Style_Ticket.ID : Style_SchemeMediumTicket.ID}>
                {getData(transaction.idActivity)}
            </div>
        </div>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    transaction: SelectorsStoreTicket.getData(store),
});

export default Connect(mapStateToProps)(Component);
