import React from "react";

import { withFormik as WithFormik, Form, Field } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import { EMPTY_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreSession, SelectorsAction as SelectorsActionSession } from "~/store/session";
import { SelectorsAction as SelectorsActionSettings, SelectorsStore as SelectorsStoreSettings } from "~/store/settings";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Token from "~/components/Token/Token";
import TextField from "~/pages/_components/fields/TextField";

import Style from "./ChangeEmail.rules.scss";

const FORM_ID = "settings.changeEmail";

export const { NAME } = Style;

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        isDesktop: PropTypes.bool.isRequired,
        isSubmitting: PropTypes.bool.isRequired,
        onCancel: PropTypes.func.isRequired,
    },
    defaults: {
        isDesktop: false,
        isSubmitting: false,
        onCancel: null,
    },
};

export function Component({ dispatch, isDesktop, isSubmitting, onCancel }) {
    React.useEffect(() => {
        dispatch(SelectorsActionSession.resetEmailForceUpdate());
    }, [dispatch]);

    return (
        <React.Fragment>
            <div id={Style.ID}>
                <Form noValidate="novalidate">
                    <div className="form--change_email">
                        <div className="form--change_email--title">
                            <I18n id="change.email.label" />
                        </div>
                        <div className="container--data">
                            <p className="text-description">
                                <I18n id="change.email.description" />
                            </p>
                            <div className="access-code">
                                <Field
                                    idForm={FORM_ID}
                                    name="mail"
                                    type="email"
                                    component={TextField}
                                    autoFocus={isDesktop}
                                    hidePlaceholder
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form--change_email otp">
                        <div className="form--change_email--title">
                            <I18n id="title.form.token" />
                        </div>
                        <Token labelButton="global.send" isSubmitting={isSubmitting}>
                            <Button size="sm" variant="secondary" disabled={isSubmitting} onClick={onCancel}>
                                <I18n id="global.cancel" />
                            </Button>
                        </Token>
                    </div>
                </Form>
            </div>
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    currentEmail: SelectorsStoreSettings.getEmail(store),
    newEmail: SelectorsStoreSettings.getNewEmail(store),
    user: SelectorsStoreSession.getUser(store),
    userEmailSelected: SelectorsStoreSession.getUserEmailSelected(store),
});

export default Compose(
    WithRouter,
    Connect(mapStateToProps),
    HighOrder.Resizable,
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            mail: props.userEmailSelected || props.newEmail || props.currentEmail || EMPTY_STR,
            otp: EMPTY_STR,
        }),
        validationSchema: ({ currentEmail, user, userEmailSelected }) =>
            Yup.object().shape({
                mail: userEmailSelected
                    ? Yup.string()
                          .trim()
                          .email(UtilsI18n.get("global.userconfiguration.emailIncorrectFormat"))
                          .required(UtilsI18n.get("global.userconfiguration.emailEmpty"))
                    : Yup.string()
                          .trim()
                          .email(UtilsI18n.get("global.userconfiguration.emailIncorrectFormat"))
                          .required(UtilsI18n.get("global.userconfiguration.emailEmpty"))
                          .notOneOf([currentEmail], UtilsI18n.get("global.userconfiguration.notEquals.email")),
                otp: user.needsBiometric
                    ? Yup.string().nullable()
                    : Yup.string().required(UtilsI18n.get("form.credential.otp.required")),
            }),
        handleSubmit: ({ mail, otp }, formikBag) => {
            const { userEmailSelected } = formikBag.props;

            formikBag.props.dispatch(
                SelectorsActionSettings.sendMailCode({
                    mail,
                    otp,
                    formikBag,
                    forceNotMatchUserEmail: !!userEmailSelected,
                }),
            );
        },
    }),
)(Component);
