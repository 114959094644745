import React from "react";

import { Field } from "formik";
import PropTypes from "prop-types";
import Panel from "react-bootstrap/lib/Panel";
import { connect } from "react-redux";

import { PRODUCT_TYPE } from "~/constants";
import { SelectorsStore as SelectorsStorePermissions } from "~/store/administration/common/permissions";
import * as arrayUtils from "~/util/array";

import I18n from "~/components/I18n";
import MultiSelect from "~/pages/_components/fields/MultiSelect";

import SmartGroups from "~/pages/administration/_components/advancedPermissionsForm/SmartGroups";

import Style from "./PermissionsPanel.rules.scss";

export const PermissionsPanelContext = React.createContext();

export const NAME = "PermissionsPanel";

export const PROP = {
    types: {
        render: PropTypes.func.isRequired,
        products: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.string,
            }),
        ).isRequired,
        mode: PropTypes.oneOf(["view", "edit"]).isRequired,
    },
    defaults: {},
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        option: null,
    };

    handleClick = (option) => {
        this.setState({ option });
    };

    handleBlur = () => {
        this.setState({ option: null });
    };

    removePermission = (form, name, selectedValue) => {
        const { setValues, values } = form;
        const value = values.permissions[name] || [];

        setValues({
            ...values,
            permissions: {
                ...values.permissions,
                [name]: value.filter((activePermission) => activePermission !== selectedValue),
            },
        });
    };

    addPermission = (form, name, selectedValue) => {
        const { setValues, values } = form;
        const value = values.permissions[name] || [];

        setValues({
            ...values,
            permissions: {
                ...values.permissions,
                [name]: [...value, selectedValue],
            },
        });
    };

    renderPanel = () => {
        const { option } = this.state;
        const { mode, products } = this.props;

        if (option) {
            const { permissionList, idItem, label } = option;
            const [permission] = permissionList;
            const productTypes = permission.productTypes.split(",");

            const productOptions = arrayUtils.mapItemsIds(
                products.filter(({ productType }) => productTypes.includes(productType)),
                "value",
            );

            if (mode !== "edit") {
                return (
                    <div id={Style.ID}>
                        <Panel>
                            <Panel.Heading>
                                <Panel.Title componentClass="h3">{label}</Panel.Title>
                            </Panel.Heading>
                            <Field
                                name={idItem}
                                render={({ form, field }) => {
                                    const { values = {} } = form;
                                    const { name } = field;
                                    const value = values.permissions[name] || [];

                                    const { smartGroups, productsAdded } = value.reduce(
                                        (acc, val) => {
                                            if (val.indexOf("ALL") !== -1) {
                                                return { ...acc, smartGroups: [...acc.smartGroups, val] };
                                            }

                                            return { ...acc, productsAdded: [...acc.productsAdded, val] };
                                        },
                                        { smartGroups: [], productsAdded: [] },
                                    );

                                    return (
                                        <Panel.Body>
                                            {smartGroups.length > 0 && (
                                                <div className="form-group">
                                                    <div className="form-group-text">
                                                        <I18n id="administration.permissions.advanced.smart.groups" />
                                                    </div>
                                                    <ul className="form-group-control-list list">
                                                        {smartGroups.map((smartGroup) => (
                                                            <li key={smartGroup} className="list-item">
                                                                <I18n id={`productType.${smartGroup.split("_")[1]}`} />
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                            {productsAdded.length > 0 && (
                                                <div className="form-group">
                                                    <div className="form-group-text">
                                                        <I18n id="administration.permissions.advanced.products" />
                                                    </div>
                                                    <ul className="form-group-control-list list">
                                                        {productsAdded.map((productId) => (
                                                            <li key={productId} className="list-item">
                                                                {productOptions.byId[productId].productAlias}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </Panel.Body>
                                    );
                                }}
                            />
                        </Panel>
                    </div>
                );
            }

            return (
                <div id={Style.ID}>
                    <Panel>
                        <Panel.Heading>
                            <Panel.Title componentClass="h3">{label}</Panel.Title>
                        </Panel.Heading>
                        <Field
                            name={idItem}
                            render={({ form, field }) => {
                                const { values } = form;
                                const { name } = field;
                                const value = values.permissions[name] || [];
                                const multiValues = value.filter((element) => {
                                    return productOptions.ids.includes(element);
                                });
                                return (
                                    <Panel.Body>
                                        <p>
                                            <I18n id="administration.permissions.select.products.types" />
                                        </p>
                                        <SmartGroups
                                            name={name}
                                            productTypes={productTypes.filter((item) => item !== PRODUCT_TYPE.CUI)}
                                            value={value}
                                            onChange={({ target }) => {
                                                if (value.includes(target.value)) {
                                                    this.removePermission(form, name, target.value);
                                                } else {
                                                    this.addPermission(form, name, target.value);
                                                }
                                            }}
                                            mode={mode}
                                        />

                                        <div className="separator">
                                            <hr />
                                            <I18n id="global.or.tick" />
                                        </div>

                                        <p>
                                            <I18n id="administration.permissions.select.specific.products" />
                                        </p>
                                        <MultiSelect
                                            name={`permissions.${name}`}
                                            labelKey="productAlias"
                                            valueKey="value"
                                            options={productOptions}
                                            placeholder="administration.permissions.products.placeholder"
                                            values={multiValues}
                                            onSelect={(selectedOption) =>
                                                this.addPermission(form, name, selectedOption.value)
                                            }
                                            onDelete={(selectedOption) =>
                                                this.removePermission(form, name, selectedOption.value)
                                            }
                                            mode={mode}>
                                            {(product) => (
                                                <span className="data-desc">{product ? product.productAlias : ""}</span>
                                            )}
                                        </MultiSelect>
                                    </Panel.Body>
                                );
                            }}
                        />
                    </Panel>
                </div>
            );
        }

        return null;
    };

    render() {
        const { render } = this.props;
        const { option } = this.state;
        return (
            <PermissionsPanelContext.Provider
                value={{
                    onClick: this.handleClick,
                    onBlur: this.handleBlur,
                    activeIdItem: option && option.idItem,
                }}>
                {render(this.renderPanel())}
            </PermissionsPanelContext.Provider>
        );
    }
}

const mapStateToProps = (state) => ({
    products: SelectorsStorePermissions.getMappedProducts(state),
});

export default connect(mapStateToProps)(Component);
