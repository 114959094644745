import React from "react";

import { connect as Connect } from "react-redux";

import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import { SelectorsStore as StoreWidget, SelectorsAction as ActionWidget } from "~/store/widget";

import Image from "~/components/Image";

import Style from "./PendingTransactions.rules.scss";
import Widget from "./_common/_Widget";

export const { TYPE, NAME } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const { dispatch, store: widget, count, ...rest } = props;

    React.useEffect(() => {
        dispatch(ActionWidget.listRequest({ widget }));
    }, [dispatch, widget]);

    const clearFilters = () => {
        dispatch(SelectorsActionTransactions.resetFilter());
    };

    return (
        <Widget
            {...rest}
            id={Style.ID}
            header-title="desktop.widgets.pendingTransactions.title"
            header-icon={<Image src="transactions-step.svg" data-badge={count || undefined} />}
            header-href="/transactions/list"
            hrefFunction={clearFilters}
        />
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

function mapStateToProps(store, props) {
    const { store: widget } = props;
    const { data = [] } = StoreWidget.getWidget(store, widget) || {};
    return {
        count: data.length && data[0],
    };
}

export default Connect(mapStateToProps)(Component);
