import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, FIELD_SUBTYPE } from "~/constants";
import { SelectorsStore as SelectorsStoreAutomaticDebits } from "~/store/automaticDebits";

import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";

import Checkbox from "~/pages/forms/_components/_fields/_commons/Checkbox";
import FormField from "~/pages/forms/_components/_fields/_commons/formField";

import Style from "./TermsAndConditions.rules.scss";

export const NAME = "TermsAndConditions";

export const PROP = {
    types: {
        value: PropTypes.bool.isRequired,
        showAcceptOption: PropTypes.bool,
        editing: PropTypes.bool,
        name: PropTypes.string.isRequired,
        setValue: PropTypes.func.isRequired,
        i18n: PropTypes.func.isRequired,
        subType: PropTypes.string.isRequired,
    },
    defaults: {
        editing: false,
        showAcceptOption: false,
        subType: EMPTY_STR,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    componentDidMount() {
        const { showAcceptOption, setValue } = this.props;
        if (!showAcceptOption) {
            setValue(true);
        }
    }

    handleChange = () => {
        const { value, setValue } = this.props;
        setValue(!value);
    };

    render() {
        const { editing, i18n, name, value, showAcceptOption, subType, newsDate, i18nMap } = this.props;
        if (!editing) {
            return null;
        }

        if (subType === FIELD_SUBTYPE.INFO) {
            return <div id={Style.ID} dangerouslySetInnerHTML={{ __html: i18n("termsAndConditions") }} />;
        }

        if (subType === FIELD_SUBTYPE.CONDITION_WITH_DATE) {
            return (
                <div id={Style.ID} >
                    <I18n id="pay.automaticDebit.termsAndCondition" NEWS_DATE={newsDate} />
                </div>
            );
        }

        if (i18nMap) {
            return (
                <React.Fragment>
                    {showAcceptOption && (
                        <Checkbox
                            value={name}
                            label={i18nMap.showAcceptOptionText}
                            checked={!!value}
                            onChange={this.handleChange}
                        />
                    )}
                    <div id={Style.ID}>
                        <div className="scrollable">
                            <I18n id={i18nMap.termsAndConditions} />
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <I18n id={i18n("termsAndConditions")} />

                {showAcceptOption && (
                    <Checkbox
                        value={name}
                        label={i18n("showAcceptOptionText")}
                        checked={!!value}
                        onChange={this.handleChange}
                    />
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    newsDate: SelectorsStoreAutomaticDebits.getNewsDate(store),
});

export default HighOrder(FormField(), Connect(mapStateToProps))(Component);
