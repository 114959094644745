import { FactoryAction } from "~/util/redux";

import { NAME, TYPE } from "./_consts";

export const SelectorsAction = {
    init: () => FactoryAction(TYPE.INIT),
};

export const SelectorsStore = {
    isLoading: (store) => store[NAME].isLoading,
};

export default SelectorsAction;
