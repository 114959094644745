import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { connect as Connect } from "react-redux";

import { COMMA_PLUS_SPACE, EMPTY_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreTicket } from "~/store/administration/common/administrationTicket";

import I18n from "~/components/I18n";
import Container from "~/pages/_components/Container";

import Style from "./Ticket.rules.scss";

export const NAME = "GroupFormBlockTicketContent";

export const PROP = {
    types: {
        isFromBackoffice: PropTypes.bool,
        transactionData: PropTypes.shape({
            data: PropTypes.shape({
                groupNameList: PropTypes.array,
            }),
        }).isRequired,
    },
    defaults: {
        isFromBackoffice: false,
    },
};

export function Component(props) {
    const {
        isFromBackoffice,
        transactionData: { data = {} },
    } = props;

    return (
        <Container className="container--layout align-items-center flex-grow" gridClassName="form-content">
            <Col className="col-12 col-xl-6" lg={6} md={9} sm={12}>
                <div className="transfer-block">
                    <div className="transfer-data transfer-data-flow">
                        <div id={isFromBackoffice && Style.ID} className="data-wrapper">
                            <div className="data-label">
                                <I18n id="transaction.ticket.groupLabel" />
                            </div>
                            <div className="data-desc">
                                <span className="data-name">
                                    {data.groupNameList ? data.groupNameList.join(COMMA_PLUS_SPACE) : EMPTY_STR}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Container>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    transactionData: SelectorsStoreTicket.getData(store),
});

export default Connect(mapStateToProps)(Component);
