import React from "react";

import { push as Push } from "connected-react-router";
import { Field, Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import * as Yup from "yup";

import { EMPTY_STR, SCOPE, LEVEL, ONLY_NUMBER, VALIDATION_ERROR } from "~/constants";
import Container from "~/containers/External/Dash";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import {
    SelectorsAction as SelectorsActionRecoverPassword,
    SelectorsStore as SelectorsStoreRecoverPassword,
} from "~/store/recoverypassword/noToken";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import CountryCellPhoneField from "~/pages/_components/fields/CountryCellPhoneField";
import TextField from "~/pages/_components/fields/TextField";

import CancelConfirmationModal from "~/pages/enrollment/_CancelConfirmationModal";

import Style from "./RecoveryPass.scss";

export const { NAME, CLASS } = Style;

const FORM_ID = "recoveryPasswordNoToken.step1";

export const PROP = {
    types: {
        isFetching: PropTypes.bool,
    },
    defaults: {
        isFetching: false,
    },
};

export function Component(props) {
    const { isFetching, dispatch, errors } = props;
    const [modal, setModal] = React.useState(false);

    const showModal = () => {
        setModal(true);
    };

    const handleConfirmation = () => {
        dispatch(Push("/"));
    };

    const handleCancelation = () => {
        setModal(false);
    };

    React.useEffect(() => {
        if (Object.keys(errors).length > 0) {
            dispatch(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get(`returnCode.${VALIDATION_ERROR}`),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.RECOVERY_PASSWORD],
                }),
            );
        }
    }, [dispatch, errors]);

    return (
        <Container
            name={NAME}
            head-onClose={showModal}
            wait={isFetching}
            scopeToShowNotification={SCOPE.RECOVERY_PASSWORD}>
            <div className={CLASS}>
                <div id={Style.NAME}>
                    <CancelConfirmationModal
                        show={modal}
                        handleCancelation={handleCancelation}
                        handleConfirmation={handleConfirmation}
                    />
                    <div className="login-step">
                        <div className="login-header">
                            <span className="login-header-text">
                                <I18n id={`${FORM_ID}.title`} />
                            </span>
                        </div>
                        <div className="login-row">
                            <div className="login-image">
                                <Image src="login-ibanca.svg" className="login-image-icon" />
                            </div>
                        </div>
                        <div className="login-row">
                            <Form>
                                <div className="login-form">
                                    <div className="login-form-field field-nomt">
                                        <div className="field-information field-nomt">
                                            <I18n id={`${FORM_ID}.intro.one`} />
                                            <I18n id={`${FORM_ID}.intro.two`} />
                                        </div>
                                    </div>
                                    <div className="login-form-field">
                                        <div className="login-form-field-block">
                                            <div className="login-form-field-image">
                                                <Image
                                                    src="login-ibanca.svg"
                                                    className="login-form-field-icon login-image-recovery"
                                                />
                                            </div>
                                            <Field
                                                autoComplete="off"
                                                autoFocus
                                                className="login-form-input"
                                                component={TextField}
                                                id="username"
                                                idForm={FORM_ID}
                                                name="username"
                                                placeholder={UtilsI18n.get(`${FORM_ID}.username.label`)}
                                                hidelabel
                                            />
                                        </div>
                                    </div>
                                    <div className="login-form-field hide-label">
                                        <div className="login-form-field-block">
                                            <div className="login-form-field-image">
                                                <Image
                                                    src="email-icon.svg"
                                                    className="login-form-field-icon email-icon"
                                                />
                                            </div>
                                            <Field
                                                autoComplete="off"
                                                className="login-form-input"
                                                component={TextField}
                                                hideLabel
                                                id="email"
                                                idForm={FORM_ID}
                                                name="email"
                                                placeholder={UtilsI18n.get(`${FORM_ID}.email.label`)}
                                            />
                                        </div>
                                    </div>
                                    <div className="login-form-field">
                                        <div className="login-form-field-block phone">
                                            <Field
                                                idForm={FORM_ID}
                                                name="mobilePhone"
                                                type="text"
                                                className="login-form-input"
                                                component={CountryCellPhoneField}
                                                hideLabel
                                                pattern={ONLY_NUMBER}
                                                hidePlaceholder={false}
                                                autoFocus={false}
                                                value={EMPTY_STR}
                                                labelKey="label"
                                            />
                                        </div>
                                        <div className="field-information">
                                            <I18n id={`${FORM_ID}.phone.information.one`} />
                                            <I18n id={`${FORM_ID}.phone.information.two`} />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Button
                                        className="login-form-button"
                                        bsStyle="primary"
                                        label="global.continue"
                                        type="submit"
                                    />
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    isFetching: SelectorsStoreRecoverPassword.isFetching(store),
});

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            username: EMPTY_STR,
            email: EMPTY_STR,
            mobilePhone: EMPTY_STR,
        }),
        validationSchema: () =>
            Yup.lazy(() => {
                return Yup.object().shape({
                    username: Yup.string().required(UtilsI18n.get(`${FORM_ID}.username.required`)),
                    email: Yup.string()
                        .trim()
                        .email(UtilsI18n.get("recoveryPasswordNoToken.step1.emailIncorrectFormat"))
                        .required(UtilsI18n.get("recoveryPasswordNoToken.step1.emailEmpty")),
                    mobilePhone: Yup.object().shape({
                        prefix: Yup.string().required(UtilsI18n.get(`${FORM_ID}.mobilePhone.mustBeAPhoneNumber`)),
                        value: Yup.string().required(UtilsI18n.get(`${FORM_ID}.mobilePhone.empty.mobilePhone`)),
                        country: Yup.string(),
                    }),
                });
            }),
        handleSubmit: (data, formikBag) => {
            const { dispatch } = formikBag.props;
            const { email, mobilePhone, username } = data;

            dispatch(
                SelectorsActionRecoverPassword.recoverPassStep1Request({ formikBag, username, email, mobilePhone }),
            );
        },
    }),
)(Component);
