import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { ALL, EMPTY_STR } from "~/constants";
import {
    SelectorsAction as SelectorsActionCreditCards,
    SelectorsStore as SelectorsStoreCreditCards,
} from "~/store/creditCards/creditCard";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";

import Box from "~/components/Box";
import Ticket from "~/components/Ticket/Ticket";

import CardsNotificationsList from "~/pages/creditCards/purchaseNotification/_components/_cardsNotificationsList";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const { dispatch, ticketConfirmation, transaction } = props;
    const { data } = transaction || {};
    const { cardAccountsNotificationsUpdated, selectedCardAccount } = data;

    const shareTicket = (idTransactionToRead) => {
        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    };

    let cardAccountListToWorkWith = [];

    if (selectedCardAccount === ALL) {
        cardAccountListToWorkWith = cardAccountsNotificationsUpdated;
    } else {
        const cardAccountToWorkWith = cardAccountsNotificationsUpdated.find(
            (cardAccount) => cardAccount.visaMasterAccount === selectedCardAccount,
        );

        cardAccountListToWorkWith.push(cardAccountToWorkWith);
    }

    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}
                    hasMobileComponent
                    displayDownloadPDF
                    handleDownloadPDF={() => shareTicket(transaction.idTransaction)}>
                    <Box directionColumn align="flex-start" className="mb-3 data-ticket">
                        <Box className="data-wrapper">
                            <CardsNotificationsList
                                cardAccountsNotificationsUpdated={cardAccountListToWorkWith}
                                isTicket
                                selectedCardAccount={selectedCardAccount}
                                transaction={transaction}
                            />
                        </Box>
                    </Box>
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { idTransaction } = transaction;

        dispatch(
            SelectorsActionCreditCards.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                formikBag,
                idTransaction,
            }),
        );
    }

    function handleSign() {
        dispatch(SelectorsActionCreditCards.signTransactionPreview({ data }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreCreditCards.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
