import React from "react";

import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { numberFormat } from "~/util/number";

import Element from "./Component";
import Namespace from "./DataNumber.scss";

export const { NAME, TYPE, TAG, CLASS } = Namespace;
export const PROP = {
    types: {
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.Object]),
        lang: PropTypes.string,
        short: PropTypes.bool,
        decimals: PropTypes.number,
        isDebit: PropTypes.bool,
        decimalScale: PropTypes.number,
        fixedDecimalScale: PropTypes.bool,
    },
    defaults: {
        prefix: EMPTY_STR,
        suffix: EMPTY_STR,
        lang: null,
        short: false,
        decimals: 2,
        isDebit: false,
        decimalScale: 2,
        fixedDecimalScale: false,
    },
};

const NUM = Object.freeze({
    K: 1000,
    M: 1000000,
});

const LANG = Object.freeze({
    // It seems that the RAE made a change in the way a number is written. It applies to all "es-" locales.
    // Current format doesn't include a dot as a decimal separator when the number has just 4 digits.
    // That's why "pt-BR" is used as the "es" locale.
    en: "en-US",
    es: "pt-BR",
    pt: "pt-BR",
});

export function Component(props) {
    const {
        prefix,
        value,
        decimals,
        isDebit,
        lang,
        short,
        suffix: suffix_,
        decimalScale,
        fixedDecimalScale,
        ...rest
    } = props;

    const code = LANG[lang];
    if (!code) {
        throw new Error(`Language "${lang}" is not in the supported list.`);
    }

    let number = parseFloat(value);
    let suffix = suffix_;
    if (short && value >= NUM.K && value < NUM.M) {
        suffix = `K ${suffix}`;
        number /= NUM.K;
    } else if (short && value >= NUM.M) {
        suffix = `M ${suffix}`;
        number /= NUM.M;
    }

    const num = isDebit ? -Math.abs(number) : number;

    const { decimalSeparator, thousandSeparator } = numberFormat(lang);

    return (
        <Element {...rest} value={value} tag={TAG} name={NAME} type={TYPE}>
            {prefix && <small>{prefix} </small>}
            <span>
                {" "}
                <NumberFormat
                    displayType="text"
                    decimalSeparator= {decimalSeparator}
                    thousandSeparator={thousandSeparator}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={num}
                />{" "}
            </span>
            {suffix && <sub> {suffix}</sub>}
        </Element>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store, props) => {
    const { lang, prefix } = props; // eslint-disable-line
    return {
        // Try to resolve the prefix to a currency label, or fallback to the original.
        prefix: StoreI18n.getMessage(store, `currency.label.${prefix}`) || prefix,
        lang: lang || StoreI18n.getLang(store) || window.navigator.language.slice(0, 2),
    };
};

export default Connect(mapStateToProps)(Component);
