import React from "react";

import { Modal } from "react-bootstrap";

import * as i18n from "~/util/i18n";

import DataNumber from "~/components/DataNumber";
import Image from "~/components/Image";

import Style from "./PopUp.rules.scss";

export const { NAME, NAME_MESSAGES } = Style;
export const PROP = {
    types: {},
    defaults: {},
};

export class Component extends React.Component {
    renderFieldValue = (data, field, currency) => {
        if (field.type === "amount") {
            return (
                <DataNumber
                    value={data[field.id]}
                    prefix={field.forceCurrency ? field.forceCurrency : currency}
                    className="mobile-only"
                />
            );
        }
        if (data[field.id]) {
            return <span>{data[field.id]}</span>;
        }
        return <span>N/A</span>;
    };

    render() {
        const { show, title, handleCloseModal, fields, data, currency } = this.props;
        return (
            <Modal show={show}>
                <Modal.Body>
                    <div className="component-modal">
                        <div className="closed clickable" onClick={handleCloseModal} role="button">
                            <Image src="close.svg" />
                        </div>
                        <h2>{title}</h2>
                        {fields &&
                            fields.map((field) => {
                                return (
                                    <p>
                                        <label>{i18n.get("position.tables.headers.".concat(field.id))}: </label>
                                        {this.renderFieldValue(data, field, currency)}
                                    </p>
                                );
                            })}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
export default Component;
