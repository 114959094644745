import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import {
    GLOBAL_UNEXPECTED_ERROR_KEY,
    LEVEL,
    RESPONSE_TYPE,
    SCOPE,
    VALIDATION_ERROR,
    FORM_FIELD_ERRORS,
} from "~/constants";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import {
    credentialsWithUnderscore as CredentialsWithUnderscore,
    hasIncorrectCredentials as HasIncorrectCredentials,
} from "~/util/form";
import * as UtilI18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction } from "./_selectors";

export default [
    // Transfer Credit Letter
    TakeLatest(TYPE.TRANSFER_CREDIT_LETTER_PRE_REQUEST, transferCreditLetterPre),
    TakeLatest(TYPE.TRANSFER_CREDIT_LETTER_PREVIEW_REQUEST, transferCreditLetterPreview),
    TakeLatest(TYPE.TRANSFER_CREDIT_LETTER_SEND_FORM, transferCreditLetter),
    TakeLatest(TYPE.PRESENTATION_CREDIT_LETTER_PRE_REQUEST, presentationCreditLetterPre),
    TakeLatest(TYPE.PRESENTATION_CREDIT_LETTER_PREVIEW_REQUEST, presentationCreditLetterPreview),
    TakeLatest(TYPE.PRESENTATION_CREDIT_LETTER_SEND_FORM, presentationCreditLetter),
    /** PRESENT BILLING DOCUMENTS */
    TakeLatest(TYPE.PRESENT_BILLING_DOCUMENTS_PRE_REQUEST, presentBillingDocumentsPreRequest),
    TakeLatest(TYPE.PRESENT_BILLING_DOCUMENTS_PREVIEW_REQUEST, presentBillingDocumentsPreviewRequest),
    TakeLatest(TYPE.PRESENT_BILLING_DOCUMENTS_SEND_REQUEST, presentBillingDocumentsSendRequest),
    /** PURCHASE DOCUMENTS */
    TakeLatest(TYPE.PURCHASE_DOCUMENTS_PRE_REQUEST, purchaseDocumentsPreRequest),
    TakeLatest(TYPE.PURCHASE_DOCUMENTS_PREVIEW_REQUEST, purchaseDocumentsPreviewRequest),
    TakeLatest(TYPE.PURCHASE_DOCUMENTS_SEND_FORM, purchaseDocumentsSendRequest),
    TakeLatest(TYPE.BILLING_CLAIM_SEND_REQUEST, billingClaimSendRequest),
    TakeLatest(TYPE.BILLING_CLAIM_PREVIEW_REQUEST, billingClaimPreviewRequest),
    TakeLatest(TYPE.BILLING_CLAIM_PRE_REQUEST, billingClaimPreRequest),
    /** AUTHORIZATION DOCUMENTS DISCREPANCIES */
    TakeLatest(TYPE.AUTHORIZATION_DOCUMENTS_DISCREPANCIES_PRE_REQUEST, authorizationDocumentsDiscrepanciesPreRequest),
    TakeLatest(
        TYPE.AUTHORIZATION_DOCUMENTS_DISCREPANCIES_PREVIEW_REQUEST,
        authorizationDocumentsDiscrepanciesPreviewRequest,
    ),
    TakeLatest(TYPE.AUTHORIZATION_DOCUMENTS_DISCREPANCIES_SEND_REQUEST, authorizationDocumentsDiscrepanciesSendRequest),
    // Cession Credit Letter
    TakeLatest(TYPE.CESSION_CREDIT_LETTER_PRE_REQUEST, cessionCreditLetterPre),
    TakeLatest(TYPE.CESSION_CREDIT_LETTER_PREVIEW_REQUEST, cessionCreditLetterPreview),
    TakeLatest(TYPE.CESSION_CREDIT_LETTER_SEND_REQUEST, cessionCreditLetter),
];

// Transfer Credit Letter
function* transferCreditLetterPre() {
    const { type, data } = yield Call(SelectorsMiddleware.transferCreditLetterPreRequest);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.transferCreditLetterPreFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_EXPORT_CREDIT_LETTER_TRANSFER],
            }),
        );
    } else {
        yield Put(SelectorsAction.transferCreditLetterPreSuccess({ ...data.data }));
    }
}

function* transferCreditLetterPreview({ formData, formikBag }) {
    const { type, data } = yield Call(SelectorsMiddleware.transferCreditLetterPreviewRequest, formData);
    const errorMessage =
        data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;

    formikBag.setSubmitting(false);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.transferCreditLetterPreviewFailure());

        formikBag.setErrors(data.data);

        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_EXPORT_CREDIT_LETTER_TRANSFER],
            }),
        );
    } else {
        yield Put(SelectorsAction.transferCreditLetterPreviewSuccess({ ...data.data }));
    }
}

function* transferCreditLetter({ prevData, credentials, formikBag }) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { type, data } = yield Call(SelectorsMiddleware.transferCreditLetterRequest, {
        ...prevData,
        ...credentialsWithUnderscore,
    });

    const { idTransaction } = data;

    formikBag.setSubmitting(false);

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
            yield Put(SelectorsAction.transferCreditLetterSendFailure());
        } else {
            yield Put(SelectorsAction.comexFormSendDataFailure());
            const errorMessage =
                data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.COMEX_EXPORT_CREDIT_LETTER_TRANSFER],
                }),
            );
        }
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });

        yield Put(SelectorsAction.transferCreditLetterSendSuccess({ transaction }));
    }
}

/** PRESENTATION CREDIT LETTER */

function* presentationCreditLetterPre() {
    const { type, data } = yield Call(SelectorsMiddleware.presentationCreditLetterPreRequest);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.presentationCreditLetterPreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX],
            }),
        );
    } else {
        yield Put(SelectorsAction.presentationCreditLetterPreSuccess({ ...data.data }));
    }
}
function* presentationCreditLetterPreview({ formData, formikBag }) {
    const { type, data } = yield Call(SelectorsMiddleware.presentationCreditLetterPreviewRequest, formData);
    formikBag.setSubmitting(false);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.presentationCreditLetterPreviewFailure());
        formikBag.setErrors(data.data);
        const errorMessage =
            data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;
        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX],
            }),
        );
    } else {
        yield Put(SelectorsAction.presentationCreditLetterPreviewSuccess({ ...data.data }));
    }
}
function* presentationCreditLetter({ formData, credentials, formikBag, idTransaction }) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { type, data } = yield Call(SelectorsMiddleware.presentationCreditLetterRequest, {
        ...formData,
        idTransaction,
        ...credentialsWithUnderscore,
    });
    formikBag.setSubmitting(false);

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);
        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
            yield Put(SelectorsAction.presentationCreditLetterSendFailure());
        } else {
            yield Put(SelectorsAction.comexFormSendDataFailure());
            const errorMessage =
                data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.COMEX],
                }),
            );
        }
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: data.idTransaction,
        });
        yield Put(SelectorsAction.presentationCreditLetterSendSuccess({ transaction }));
    }
}
// Present Billing Documents

function* presentBillingDocumentsPreRequest() {
    const { type, data } = yield Call(SelectorsMiddleware.presentBillingDocumentsPreRequest);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.presentBillingDocumentsPreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX],
            }),
        );
    } else {
        yield Put(SelectorsAction.presentBillingDocumentsPreSuccess({ ...data.data }));
    }
}

function* presentBillingDocumentsPreviewRequest({ formData, formikBag }) {
    const { type, data } = yield Call(SelectorsMiddleware.presentBillingDocumentsPreviewRequest, formData);
    if (type === RESPONSE_TYPE.WARNING) {
        formikBag.setErrors(data.data);
        const touchedFields = {};
        const fieldsWithErrors = Object.keys(data.data);
        for (let index = 0; index < fieldsWithErrors.length; index += 1) {
            const field = fieldsWithErrors[index];
            touchedFields[field] = true;
        }
        formikBag.setTouched(touchedFields);

        yield Put(SelectorsAction.presentBillingDocumentsPreviewFailure());
        const errorMessage =
            data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;
        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_EXPORT_PRESENT_BILLING_DOCUMENTS],
            }),
        );
    } else {
        yield Put(SelectorsAction.presentBillingDocumentsPreviewSuccess({ ...data.data }));
    }
    formikBag.setSubmitting(false);
}

function* presentBillingDocumentsSendRequest({ formData, credentials, idTransaction, formikBag }) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { type, data } = yield Call(SelectorsMiddleware.presentBillingDocumentsSendRequest, {
        ...formData,
        idTransaction,
        ...credentialsWithUnderscore,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);
        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
            yield Put(SelectorsAction.presentBillingDocumentsSendFailure());
        } else {
            yield Put(SelectorsAction.comexFormSendDataFailure());
            const errorMessage =
                data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.COMEX_EXPORT_PRESENT_BILLING_DOCUMENTS],
                }),
            );
        }
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: data.idTransaction,
        });
        yield Put(SelectorsAction.presentBillingDocumentsSendSuccess({ transaction }));
    }
}

/** PURCHASE DOCUMENTS */
function* purchaseDocumentsPreRequest() {
    const { type, data } = yield Call(SelectorsMiddleware.purchaseDocumentsPreRequest);
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.purchaseDocumentsPreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX],
            }),
        );
    } else {
        yield Put(SelectorsAction.purchaseDocumentsPreSuccess({ ...data.data }));
    }
}

function* purchaseDocumentsPreviewRequest({ formData, formikBag }) {
    const { type, data } = yield Call(SelectorsMiddleware.purchaseDocumentsPreviewRequest, formData);
    if (type === RESPONSE_TYPE.WARNING) {
        formikBag.setErrors(data.data);
        const touchedFields = {};
        const fieldsWithErrors = Object.keys(data.data);
        for (let index = 0; index < fieldsWithErrors.length; index += 1) {
            const field = fieldsWithErrors[index];
            touchedFields[field] = true;
        }
        formikBag.setTouched(touchedFields);

        yield Put(SelectorsAction.purchaseDocumentsPreviewFailure());
        const errorMessage =
            data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;
        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX],
            }),
        );
    } else {
        yield Put(SelectorsAction.purchaseDocumentsPreviewSuccess({ data: data.data.data }));
    }
    formikBag.setSubmitting(false);
}

function* purchaseDocumentsSendRequest({ formData, credentials, formikBag, idTransaction }) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { type, data } = yield Call(SelectorsMiddleware.purchaseDocumentsSendRequest, {
        ...formData,
        idTransaction,
        ...credentialsWithUnderscore,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);
        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
            yield Put(SelectorsAction.purchaseDocumentsSendFailure({ errors: data.data }));
        } else {
            yield Put(SelectorsAction.comexFormSendDataFailure());
            const errorMessage =
                data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.COMEX],
                }),
            );
        }
        formikBag.setSubmitting(false);
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: data.idTransaction,
        });
        yield Put(SelectorsAction.purchaseDocumentsSendSuccess({ transaction }));
    }
}

// Billing Claim

function* billingClaimPreRequest() {
    const { type, data } = yield Call(SelectorsMiddleware.billingClaimPreRequest);
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.billingClaimPreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_BILLING_CLAIM],
            }),
        );
    } else {
        yield Put(SelectorsAction.billingClaimPreSuccess({ ...data.data }));
    }
}

function* billingClaimPreviewRequest({ formData, formikBag }) {
    const { type, data } = yield Call(SelectorsMiddleware.billingClaimPreviewRequest, formData);
    if (type === RESPONSE_TYPE.WARNING) {
        formikBag.setErrors(data.data);
        const touchedFields = {};
        const fieldsWithErrors = Object.keys(data.data);
        for (let index = 0; index < fieldsWithErrors.length; index += 1) {
            const field = fieldsWithErrors[index];
            touchedFields[field] = true;
        }
        formikBag.setTouched(touchedFields);
        yield Put(SelectorsAction.billingClaimPreviewFailure());
        const errorMessage =
            data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;
        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_BILLING_CLAIM],
            }),
        );
    } else {
        yield Put(SelectorsAction.billingClaimPreviewSuccess({ ...data.data }));
    }
    formikBag.setSubmitting(false);
}

function* billingClaimSendRequest({ formData, credentials, formikBag }) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { type, data } = yield Call(SelectorsMiddleware.billingClaimSendRequest, {
        ...formData,
        ...credentialsWithUnderscore,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);
        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
            yield Put(SelectorsAction.billingClaimSendFailure());
        } else {
            yield Put(SelectorsAction.comexFormSendDataFailure());
            const errorMessage =
                data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.COMEX_BILLING_CLAIM],
                }),
            );
        }
    } else {
        const { idTransaction } = data;
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(SelectorsAction.billingClaimSendSuccess({ transaction }));
    }
}

/** AUTHORIZATION DOCUMENTS DISCREPANCIES */
function* authorizationDocumentsDiscrepanciesPreRequest() {
    const { type, data } = yield Call(SelectorsMiddleware.authorizationDocumentsDiscrepanciesPreRequest);
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.authorizationDocumentsDiscrepanciesPreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_DISCREPANCY_DOCUMENT_AUTHORIZATION],
            }),
        );
    } else {
        yield Put(SelectorsAction.authorizationDocumentsDiscrepanciesPreSuccess({ ...data }));
    }
}

function* authorizationDocumentsDiscrepanciesPreviewRequest({ formData, formikBag }) {
    const { type, data } = yield Call(SelectorsMiddleware.authorizationDocumentsDiscrepanciesPreviewRequest, formData);
    if (type === RESPONSE_TYPE.WARNING) {
        formikBag.setErrors(data.data);

        const touchedFields = {};
        const fieldsWithErrors = Object.keys(data.data);
        for (let index = 0; index < fieldsWithErrors.length; index += 1) {
            const field = fieldsWithErrors[index];
            touchedFields[field] = true;
        }
        formikBag.setTouched(touchedFields);
        yield Put(SelectorsAction.authorizationDocumentsDiscrepanciesPreviewFailure());
        const errorMessage =
            data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;
        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_DISCREPANCY_DOCUMENT_AUTHORIZATION],
            }),
        );
    } else {
        yield Put(SelectorsAction.authorizationDocumentsDiscrepanciesPreviewSuccess({ ...data }));
    }
}

function* authorizationDocumentsDiscrepanciesSendRequest({ formData, credentials, formikBag, idTransaction }) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { type, data } = yield Call(SelectorsMiddleware.authorizationDocumentsDiscrepanciesSendRequest, {
        ...formData,
        idTransaction,
        ...credentialsWithUnderscore,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);
        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
            yield Put(SelectorsAction.authorizationDocumentsDiscrepanciesSendFailure());
        } else {
            yield Put(SelectorsAction.comexFormSendDataFailure());
            const errorMessage =
                data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.COMEX_DISCREPANCY_DOCUMENT_AUTHORIZATION],
                }),
            );
        }
    } else {
        const { idTransaction: idTransactionResponse } = data;
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransactionResponse,
        });
        yield Put(SelectorsAction.authorizationDocumentsDiscrepanciesSendSuccess({ transaction }));
    }
    formikBag.setSubmitting(false);
}

// Cession Credit Letter
function* cessionCreditLetterPre() {
    const { type, data } = yield Call(SelectorsMiddleware.cessionCreditLetterPreRequest);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.cessionCreditLetterPreFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_EXPORT_CESSION_CREDIT_LETTER],
            }),
        );
    } else {
        yield Put(SelectorsAction.cessionCreditLetterPreSuccess({ ...data.data }));
    }
}

function* cessionCreditLetterPreview({ formData, formikBag }) {
    const { type, data } = yield Call(SelectorsMiddleware.cessionCreditLetterPreviewRequest, formData);
    if (type === RESPONSE_TYPE.WARNING) {
        formikBag.setErrors(data.data);
        const touchedFields = {};
        const fieldsWithErrors = Object.keys(data.data);
        for (let index = 0; index < fieldsWithErrors.length; index += 1) {
            const field = fieldsWithErrors[index];
            touchedFields[field] = true;
        }
        formikBag.setTouched(touchedFields);

        yield Put(SelectorsAction.cessionCreditLetterPreviewFailure());
        const errorMessage =
            data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;
        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_EXPORT_CESSION_CREDIT_LETTER],
            }),
        );
    } else {
        yield Put(SelectorsAction.cessionCreditLetterPreviewSuccess({ ...data.data }));
    }
    formikBag.setSubmitting(false);
}

function* cessionCreditLetter({ formData, credentials, formikBag, idTransaction }) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);
    const { type, data } = yield Call(SelectorsMiddleware.cessionCreditLetterSendRequest, {
        ...formData,
        idTransaction,
        ...credentialsWithUnderscore,
    });
    formikBag.setSubmitting(false);

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);
        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
            yield Put(SelectorsAction.cessionCreditLetterSendFailure());
        } else {
            yield Put(SelectorsAction.comexFormSendDataFailure());
            const errorMessage =
                data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.COMEX_EXPORT_CESSION_CREDIT_LETTER],
                }),
            );
        }
    } else {
        const { idTransaction: idTransactionResponse } = data;
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransactionResponse,
        });
        yield Put(SelectorsAction.cessionCreditLetterSendSuccess({ transaction }));
    }
}
