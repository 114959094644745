import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/Simple";
import { SelectorsAction as SelectorsActionAccount, SelectorsStore as SelectorsStoreAccount } from "~/store/account";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as i18n from "~/util/i18n";

import Style from "./FiltersResult.rules.scss";
import Movements from "./_Movements";

export const { NAME } = Style;

export const PROP = {
    types: {
        account: PropTypes.shape({
            currency: PropTypes.string,
        }).isRequired,
        filters: PropTypes.shape({
            dateFrom: PropTypes.string,
            dateTo: PropTypes.string,
        }).isRequired,
        history: PropTypes.shape({
            goBack: PropTypes.func.isRequired,
        }).isRequired,
        isChangingEnvironment: PropTypes.bool,
        moreMovements: PropTypes.bool.isRequired,
        movements: PropTypes.arrayOf.isRequired,
    },
    defaults: {
        isChangingEnvironment: false,
    },
};

export function Component(props) {
    const {
        currency,
        dispatch,
        fetching,
        fetchingDetails,
        filters,
        idAccount,
        movements,
        isChangingEnvironment,
    } = props;
    const filtersWithInfo = ["period", "amount", "reference", "channels", "check"];

    // ComponentDidMount
    React.useEffect(() => {
        if (!isChangingEnvironment) {
            dispatch(SelectorsActionAccount.details({ idAccount, filters }));
        }
    }, [dispatch, filters, idAccount, isChangingEnvironment]);

    const { dateFrom, filter } = filters;
    const wait = fetching || fetchingDetails;
    const movementsReady = movements !== null; // cleanMovements

    return (
        <React.Fragment>
            <Container
                name={Style.NAME_MOVS}
                className={Style.CLASS}
                head-title={`accounts.movements.filters.${
                    !filtersWithInfo.includes(filter) ? filter : `searchBy.${filter}`
                }`}
                replace={{
                    SECOND_LAST_MONTH:
                        filter === "secondLastMonth" &&
                        `${i18n.getArray("global.months")[dateFrom.getMonth()]} ${dateFrom.getFullYear().toString()}`,
                }}
                head-onClose={handleBack}
                head-onBackWeb={handleBack}
                wait={wait}
                scopeToShowNotification={SCOPE.MOVEMENT_DETAIL}>
                {!wait && currency && movementsReady && (
                    <Movements
                        container-title="Movimientos"
                        container-role="list"
                        name={Style.NAME_MOVS}
                        idAccount={idAccount}
                        accountCurrency={currency}
                    />
                )}
            </Container>
        </React.Fragment>
    );

    function handleBack() {
        const { history } = props;
        history.goBack();
    }
}

const mapStateToProps = (store, props) => {
    const account = SelectorsStoreAccount.getSelectedAccount(store);

    return {
        idAccount: props.match.params.id,
        currency: account && account.currency,
        filters: SelectorsStoreAccount.getMobileFilter(store),
        isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
        moreMovements: SelectorsStoreAccount.getMoreMovements(store),
        movements: SelectorsStoreAccount.getMovements(store),
        fetchingDetails: SelectorsStoreAccount.getFetchingDetails(store),
    };
};

export default Connect(mapStateToProps)(Component);
