import { combineReducers } from "redux";

import { Reducer as ReducerAdvanced, NAME as NAME_ADVANCED } from "~/store/administration/advanced";
import { Reducer as ReducerMedium, NAME as NAME_MEDIUM } from "~/store/administration/medium";
import { Reducer as ReducerSimple, NAME as NAME_SIMPLE } from "~/store/administration/simple";

export const NAME = "administration";

const administrationReducer = combineReducers({
    [NAME_SIMPLE]: ReducerSimple,
    [NAME_MEDIUM]: ReducerMedium,
    [NAME_ADVANCED]: ReducerAdvanced,
    user: (state = {}, { data = {} }) => data.user || state,
});

export const Reducer = (state, action) => {
    if (action.id && action.id !== state.user.idUser) {
        return administrationReducer(undefined, action);
    }

    return administrationReducer(state, action);
};
