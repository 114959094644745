import { call as Call, put as Put, select as Select, takeLatest as TakeLatest } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE, SIGN_WITH_BIOMETRIC, VALIDATION_ERROR } from "~/constants";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import IsTrustedBiometric from "~/store/form/_sagas/_isTrustedBiometric";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as i18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsMiddleware } from "./_selectors";

const sagas = [
    TakeLatest(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, cancelTransactionCustom),
    TakeLatest(TYPE.LIST_PAYMENTS_PRE_REQUEST, listPaymentsPreRequest),
    TakeLatest(TYPE.LIST_PAYMENTS_REQUEST, listPaymentsRequest),
    TakeLatest(TYPE.PAY_CUSTOMS_PREVIEW_REQUEST, payCustomsPreviewRequest),
    TakeLatest(TYPE.PAY_CUSTOMS_LOAD_TICKET_REQUEST, readTransaction),
    TakeLatest(TYPE.PAY_CUSTOMS_SIGN_TRANSACTION_REQUEST, signTransaction),
];

export default sagas;

export const CredentialsWithUnderscore = (credentials) =>
    Object.entries(credentials).reduce((pairs, [key, value]) => ({ ...pairs, [`_${key}`]: value }), {});

export const HasIncorrectCredentials = (credentials, data) => Object.keys(credentials).some((key) => data.data[key]);

function* listPaymentsPreRequest() {
    const { type, data } = yield Call(SelectorsMiddleware.listPaymentsPreRequest);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.listPaymentsPreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: i18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.CUSTOMS_PAYMENT],
            }),
        );
    } else {
        yield Put(SelectorsAction.listPaymentsPreSuccess({ data: data.data }));
    }
}

function* listPaymentsRequest(props) {
    const { data: myProps, formikBag } = props;
    delete myProps.type;
    delete myProps.formikBag;
    const { type, data } = yield Call(SelectorsMiddleware.listPaymentsRequest, myProps);

    if (type === RESPONSE_TYPE.WARNING) {
        if (data.code === VALIDATION_ERROR) {
            formikBag.setErrors(data.data);
            yield Put(SelectorsAction.listPaymentsFailure());
        } else {
            yield Put(SelectorsAction.listPaymentsFailure());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: i18n.get("global.unexpectedError"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.CUSTOMS_PAYMENT],
                }),
            );
        }
    } else {
        yield Put(SelectorsAction.listPaymentsSuccess({ data: data.data }));
    }
}

function* payCustomsPreviewRequest(props) {
    const myProps = { ...props };
    myProps.idTransactionList = myProps.selectedPayments.map((p) => p.transactionId);
    delete myProps.type;
    delete myProps.currency;

    const { type: typePreview, data: dataPreview } = yield Call(SelectorsMiddleware.payCustomsPreviewRequest, myProps);

    const { formikBag, credentials } = props;
    if (typePreview === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.payCustomsFailure());
        const errorMessage = dataPreview.message || i18n.get("global.unexpectedError");
        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.CUSTOMS_PAYMENT],
            }),
        );
    } else {
        dataPreview.data.debitAccount = props.debitAccount;
        myProps.idTransactionList = dataPreview.data.idTransactionList;
        myProps.totalAmount = dataPreview.data.totalAmount;
        const { type, data } = yield Call(SelectorsMiddleware.payCustomsRequest, myProps);

        if (type === RESPONSE_TYPE.WARNING) {
            const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);
            if (hasIncorrectCredentials) {
                formikBag.setErrors(data.data);
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: data.message,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.CUSTOMS_PAYMENT],
                    }),
                );
                yield Put(SelectorsAction.payCustomsPreviewFailure());
            } else {
                yield Put(SelectorsAction.payCustomsPreviewFailure());
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: data.message,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.CUSTOMS_PAYMENT],
                    }),
                );
            }
        } else {
            const { idTransaction } = data;
            const {
                data: {
                    data: { transaction },
                },
            } = yield Call(SelectorsMiddlewareForm.readTransaction, {
                idTransactionToRead: idTransaction,
            });
            yield Put(SelectorsAction.payCustomsSuccess({ data: { ...transaction } }));
        }
    }
}

function* readTransaction(props) {
    const { idTransaction } = props;
    const transactionResponse = yield Call(SelectorsMiddlewareForm.readTransaction, {
        idTransactionToRead: idTransaction,
    });

    if (transactionResponse.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.loadTicketFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: i18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.CUSTOMS_PAYMENT],
            }),
        );
    } else {
        yield Put(SelectorsAction.loadTicketSuccess({ data: transactionResponse.data.data.transaction }));
    }
}

function* signTransaction(props) {
    const { credentials, formikBag, idActivity, idForm, idTransaction } = props;
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
    }

    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { data, type } = yield Call(
        SelectorsMiddlewareForm.sign,
        { idForm, idTransaction, ...credentialsWithUnderscore },
        idActivity,
    );

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.CUSTOMS_PAYMENT],
                }),
            );
        }

        formikBag.setSubmitting(false);

        yield Put(SelectorsAction.signTransactionFailure());
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });

        yield Put(
            SelectorsAction.signTransactionSuccess({
                transaction,
            }),
        );

        formikBag.setSubmitting(false);
    }
}

function* cancelTransactionCustom(props) {
    const { credentials, idTransaction, formikBag } = props;
    yield* handleCancelTransaction(credentials, idTransaction, formikBag);

    formikBag.setSubmitting(false);
}

function* handleCancelTransaction(credentials, idTransaction, formikBag) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);
    const {
        data: { data },
        type,
    } = yield Call(SelectorsMiddlewareForm.cancelTransaction, {
        idTransactionToCancel: idTransaction,
        ...credentialsWithUnderscore,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data);
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: i18n.get("forms.cancelTransaction.errorMessage"),
                    level: LEVEL.ERROR,
                    scopes: SCOPE.CUSTOMS_PAYMENT,
                }),
            );
        }
    } else {
        yield* readTransaction({ idTransaction });
        yield Put(
            SelectorsActionNotification.showNotification({
                message: i18n.get("forms.cancelTransaction.confirmationMessage"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.TRANSACTIONS],
            }),
        );
    }
}
