import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import {
    SelectorsStore as SelectorsStoreAboutIbanca,
    SelectorsAction as SelectorsActionAboutIbanca,
} from "~/store/aboutIbanca";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsStore as StoreI18n } from "~/store/i18n";

import Button from "~/components/Button";
import Modal from "~/components/Modal";

import Style from "./askForExternalLink.rules.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {
        showModal: PropTypes.bool.isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const { showModal, i18n, link } = props;

    function handleCloseAboutIbancaModal() {
        const { dispatch } = props;
        dispatch(SelectorsActionAboutIbanca.setShowAboutIbancaModal({ showAboutIbancaModal: false }));
    }

    function handleOpenTab() {
        window.open(link, "_blank");
        handleCloseAboutIbancaModal();
    }
    return (
        <Modal
            show={showModal}
            onClose={handleCloseAboutIbancaModal}
            closeButton
            backdrop="static"
            labelKey="aboutIbanca.modal.title">
            <div className={CLASS}>
                <p>{i18n.body}</p>
                <p>{i18n.confirmation}</p>
                <footer>
                    <Button label="global.cancel" onClick={handleCloseAboutIbancaModal} block={false} />
                    <Button label="global.accept" className="btn-primary" block={false} onClick={handleOpenTab} />
                </footer>
            </div>
        </Modal>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    showModal: SelectorsStoreAboutIbanca.getShowAboutIbancaModal(store),
    link: SelectorsStoreConfig.getConfig(store)["client.demoiBanca.url"],
    i18n: {
        body: StoreI18n.getMessage(store, "aboutIbanca.modal.body"),
        confirmation: StoreI18n.getMessage(store, "aboutIbanca.modal.confirmation"),
    },
});

export default Connect(mapStateToProps)(Component);
