import React from "react";

import { SVGInjector } from "@tanem/svg-injector";
import PropTypes from "prop-types";

import { DOT, EMPTY_STR } from "~/constants";
import UtilDevice from "~/util/device";

import Namespace from "./Image.scss";

export const { NAME, TYPE } = Namespace;
export const PROP = {
    types: {
        /** The path for the Image resource */
        src: PropTypes.string.isRequired,
        /** Whether to run script elements declared inside the SVG element. "always|once|never" */
        evalScripts: PropTypes.string,
        /**
         * Whether IRI addressable elements inside the SVG should be remunerated.
         * @see https://www.w3.org/TR/SVG11/linking.html#IRIandURI
         */
        renumerateIRIElements: PropTypes.bool,
        /** Function to run after SVG injection has finished. */
        onInject: PropTypes.func,
    },
    defaults: {
        // TODO: haven't documented these, because of todos below.
        className: "",
        classNameOuter: "svg-wrapper",
        classNameInner: "svg-image",
        evalScripts: "never",
        renumerateIRIElements: true,
        onInjection: (svg) => svg,
    },
};

const EXT_SVG = "svg";

export function Component(props) {
    const {
        src,
        evalScripts,
        renumerateIRIElements,
        className,
        classNameInner,
        classNameOuter,
        onInjection,
        alt = EMPTY_STR,
        draggable = false,
        ...rest
    } = props;

    const ref = React.useRef(null);
    const isSVG = src.split(DOT).slice(-1).shift() === EXT_SVG;
    const isURL = new RegExp("^(?:[a-z]+:)?//", "i");

    React.useEffect(() => {
        const { current } = ref;
        current.dataset.injected = false;
        current.dataset.loading = true;
        (async () => {
            try {
                let { default: path } = await import(`~/assets/${src}`);
                // If in cordova, remove the leading slash, so it loads locally.
                if (UtilDevice.isMobileNative() && path.indexOf("/") === 0) {
                    path = path.slice(1);
                }
                if (isSVG) {
                    current.firstChild.firstChild.dataset.src = path;
                    SVGInjector(current.firstChild.firstChild, {
                        evalScripts,
                        renumerateIRIElements,
                        afterEach(error, svg) {
                            if (error) {
                                current.dataset.error = error.message;
                            }
                            onInjection(svg);
                            current.dataset.injected = true;
                            current.dataset.loading = false;
                        },
                    });
                } else {
                    current.dataset.loading = false;
                    current.src = path;
                }
            } catch (err) {
                if (!isURL) {
                    current.dataset.error = err.message;
                    current.dataset.loading = false;
                }
            }
        })();
    }, [evalScripts, isSVG, isURL, onInjection, renumerateIRIElements, src]);

    if (!isSVG) {
        return <img {...rest} src={src} draggable={draggable} ref={ref} alt={alt} data-type={TYPE} data-name={NAME} />;
    }

    return (
        <div data-type={TYPE} data-name={NAME} ref={ref} className={classNameOuter} {...rest}>
            {/* TODO:
             * https://github.com/tanem/react-svg#faq
             * We don' do drastic stuff here, maybe we could remove this second wrapper?
             */}
            <div className={classNameInner}>
                {/* TODO: `className` shouldn't be necessary, for now we keep it since pages rely on it. */}
                <svg className={`injected-svg ${className}`} />
            </div>
        </div>
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
