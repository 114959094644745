import React from "react";

import { replace as Replace } from "connected-react-router";
import { connect as Connect } from "react-redux";

import { MAXIMUM_FRACTION_DIGITS_RATE_FX, EMPTY_STR, SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import {
    PROP as STORE_PROP,
    FXSTATE as STORE_FXSTATE,
    OPERATIONS as STORE_OPERATIONS,
    SelectorsStore,
    SelectorsAction,
    LAST_CHANGED_INPUT,
} from "~/store/preferentialTradingPrice";
import * as I18n from "~/util/i18n";
import * as UtilsNumber from "~/util/number";

import FormattedAmount from "~/pages/_components/FormattedAmount";

import Style from "./PreferentialTradingPrice.rules.scss";
import ModalFX from "./components/ModalFX";
import TransactionsList from "./components/TransactionsList";
import FormFX from "./views/FormFX";
import PreviewFX from "./views/PreviewFX";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {
        ...STORE_PROP.types,
    },
    defaults: {
        ...STORE_PROP.defaults,
    },
};

// Mapeamos los pasos del formulario dinamico con los estados que maneja el form de FX.
const STEPS = {
    INITIALOADING: MODE.EDIT,
    LOADING: MODE.EDIT,
    FORM: MODE.EDIT,
    PREVIEW: MODE.PREVIEW,
    SEND: MODE.VIEW,
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {
        show: false,
        messageTitle: EMPTY_STR,
        messageBody: EMPTY_STR,
    };

    static getDerivedStateFromProps(props) {
        const { dispatch, fxState, exchangeValueChanged, rateChange, requestRate, currencyChanged } = props;
        const { debitAmount, creditAmount } = props.formData;

        if (requestRate) {
            dispatch(SelectorsAction.requestCotizacion());
            return null;
        }

        if (fxState === STORE_FXSTATE.FORM) {
            if (exchangeValueChanged || currencyChanged) {
                if (debitAmount !== null || creditAmount !== null) {
                    // Si se ingreso alguno de los montos se le avisa al usuario que cambio la cotizacion,
                    // sino simplemente cambia
                    dispatch(
                        SelectorsAction.changeAmount({
                            debitAmount: null,
                            creditAmount: null,
                            lastTypedValue: null,
                            lastTypedInput: null,
                        }),
                    );
                    if (exchangeValueChanged) {
                        return {
                            show: true,
                            messageTitle: I18n.get("client.preferentialTradingPrice.exchangeValueChanged.title"),
                            messageBody: I18n.get("client.preferentialTradingPrice.exchangeValueChanged"),
                        };
                    }
                    return null;
                }
            }

            if (rateChange) {
                // si se saco o se agrego alguna cotizacion, se recargan la pagina.
                dispatch(SelectorsAction.requestFX());
                return {
                    show: true,
                    messageTitle: I18n.get("client.preferentialTradingPrice.rateChanged.title"),
                    messageBody: I18n.get("client.preferentialTradingPrice.rateChanged"),
                };
            }
        }
        return null;
    }

    renderHeader = () => {
        const { fxState } = this.props;

        switch (fxState) {
            case STORE_FXSTATE.FORM:
                return { "head-title": "preferentialTradingPrice.title", "head-onClose": this.close };
            case STORE_FXSTATE.PREVIEW:
                return {
                    "head-title": "preferentialTradingPrice.title",
                    "head-onClose": this.close,
                    "head-onBack": this.returnToForm,
                };
            case STORE_FXSTATE.SEND:
                return { "head-title": "preferentialTradingPrice.title", "head-onClose": this.close };
            default:
                return null;
        }
    };

    renderForm = () => {
        const {
            formData,
            fetching,
            errors,
            operation,
            location: { state },
            processingTransactionsList,
        } = this.props;
        const newOptionsAccountDebit = formData.debitAccounts
            ? this.buildProductOptions(formData.debitAccounts)
            : undefined;
        const newOptionsAccountCredit = formData.creditAccounts
            ? this.buildProductOptions(formData.creditAccounts)
            : undefined;

        return (
            <React.Fragment>
                {processingTransactionsList?.totalRows > 0 && (
                    <TransactionsList processingTransactionsList={processingTransactionsList} />
                )}
                <FormFX
                    {...formData}
                    operation={operation}
                    errors={errors}
                    fetching={fetching}
                    newOptionsAccountDebit={newOptionsAccountDebit}
                    newOptionsAccountCredit={newOptionsAccountCredit}
                    handleCurrencyChange={this.handleCurrencyChange}
                    handleClose={this.close}
                    handlePreview={this.sendPreview}
                    handleSelling={this.handleSelling}
                    handleBuying={this.handleBuying}
                    handleChangeDebitAccount={this.handleChangeDebitAccount}
                    handleChangeCreditAccount={this.handleChangeCreditAccount}
                    handleChangeDebitAmount={this.handleChangeDebitAmount}
                    handleChangeCreditAmount={this.handleChangeCreditAmount}
                    handleBlurError={this.handleBlurError}
                    changeOperation={state && state.isSelling}
                />
            </React.Fragment>
        );
    };

    returnToForm = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsAction.returnToForm());
    };

    renderPreview = () => {
        const { errors, fetching, previewData, renderFormWaiter } = this.props;
        return (
            <PreviewFX
                {...previewData}
                handleClose={this.close}
                handleSend={this.sendFX}
                handleReturn={this.returnToForm}
                fetching={fetching}
                errors={errors}
                renderFormWaiter={renderFormWaiter}
            />
        );
    };

    renderConfirmation = () => {
        const { sendData, dispatch } = this.props;
        const {
            transaction: { idTransaction },
        } = sendData;

        dispatch(
            Replace({
                pathname: `preferentialTradingPrice/${idTransaction}/ticket`,
            }),
        );
    };

    // Renderizador de pantalla
    renderFX = () => {
        const { fxState } = this.props;
        switch (fxState) {
            case STORE_FXSTATE.FORM:
                return this.renderForm();
            case STORE_FXSTATE.PREVIEW:
                return this.renderPreview();
            case STORE_FXSTATE.SEND:
                return this.renderConfirmation();
            default:
                return null;
        }
    };

    showModal = () => {
        this.setState({ show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    sendPreview = () => {
        const { dispatch, operation } = this.props;
        const { formData } = this.props;
        const {
            selectedCurrency,
            lastTypedInput,
            debitValue,
            creditValue,
            debitAmount,
            creditAmount,
            sellingRate,
            buyingRate,
        } = formData;
        clearInterval(this.interval);
        this.interval = null;

        let currency;
        let currency2;
        let operationType;
        let quotation;
        if (operation === STORE_OPERATIONS.SELLING) {
            currency = selectedCurrency.value.currency2;
            currency2 = selectedCurrency.value.currency1;
            operationType = "bankIsBuying";
            quotation = sellingRate.toLocaleString(I18n.getLang(), {
                maximumFractionDigits: MAXIMUM_FRACTION_DIGITS_RATE_FX,
            });
        } else {
            currency = selectedCurrency.value.currency1;
            currency2 = selectedCurrency.value.currency2;
            operationType = "bankIsSelling";
            quotation = buyingRate.toLocaleString(I18n.getLang(), {
                maximumFractionDigits: MAXIMUM_FRACTION_DIGITS_RATE_FX, // El maximo por defecto es 3
            });
        }

        const debitAmountP = { currency, quantity: debitAmount !== null ? debitAmount : 0 };
        const creditAmountP = { currency: currency2, quantity: creditAmount !== null ? creditAmount : 0 };

        dispatch(
            SelectorsAction.requestPreview({
                debitAccount: debitValue.value,
                creditAccount: creditValue.value,
                quotation,
                operationType,
                debitAmount: debitAmountP,
                creditAmount: creditAmountP,
                userChosenInput: lastTypedInput,
            }),
        );
        dispatch(SelectorsAction.stopPollCotization());
    };

    sendFX = (_credentials, formikBag) => {
        const { dispatch, operation } = this.props;
        const { formData } = this.props;
        const { otp: token } = _credentials;
        const {
            selectedCurrency,
            debitValue,
            creditValue,
            debitAmount,
            creditAmount,
            lastTypedInput,
            sellingRate,
            buyingRate,
        } = formData;
        clearInterval(this.interval);
        this.interval = null;

        let currency;
        let currency2;
        let operationType;
        let quotation;

        if (operation === STORE_OPERATIONS.SELLING) {
            currency = selectedCurrency.value.currency2;
            currency2 = selectedCurrency.value.currency1;
            operationType = "bankIsBuying";
            quotation = sellingRate.toLocaleString(I18n.getLang(), {
                maximumFractionDigits: MAXIMUM_FRACTION_DIGITS_RATE_FX,
            });
        } else {
            currency = selectedCurrency.value.currency1;
            currency2 = selectedCurrency.value.currency2;
            operationType = "bankIsSelling";
            quotation = buyingRate.toLocaleString(I18n.getLang(), {
                maximumFractionDigits: MAXIMUM_FRACTION_DIGITS_RATE_FX, // El maximo por defecto es 3
            });
        }

        const debitAmountP = { currency, quantity: debitAmount !== null ? debitAmount : 0 };
        const creditAmountP = { currency: currency2, quantity: creditAmount !== null ? creditAmount : 0 };

        dispatch(
            SelectorsAction.requestSend({
                debitAccount: debitValue.value,
                debitAccountLabel: debitValue.label,
                creditAccount: creditValue.value,
                creditAccountLabel: creditValue.label,
                quotation,
                operationType,
                debitAmount: debitAmountP,
                creditAmount: creditAmountP,
                userChosenInput: lastTypedInput,
                token,
                formikBag,
            }),
        );
        dispatch(SelectorsAction.stopPollCotization());
    };

    handleCurrencyChange = (selectedOption) => {
        const { dispatch } = this.props;
        dispatch(SelectorsAction.changeCurrency({ selectedCurrency: selectedOption }));
    };

    handleChangeDebitAccount = (debitAccount) => {
        const { value, accountAlias, accountBalance } = debitAccount;
        const { dispatch } = this.props;
        dispatch(SelectorsAction.changeDebitAccount({ value, accountAlias, accountBalance }));
    };

    handleChangeCreditAccount = (creditAccount) => {
        const { value, accountAlias, accountBalance } = creditAccount;
        const { dispatch } = this.props;
        dispatch(SelectorsAction.changeCreditAccount({ value, accountAlias, accountBalance }));
    };

    buildProductOptions = (originalOptions) => {
        // \u00A0 es un character especial para forzar un espacio en blanco
        const options = [...originalOptions];

        return options.map((elem) => {
            if (elem.balance) {
                return {
                    value: elem.value,
                    accountAlias: elem.label,
                    label: (
                        <React.Fragment>
                            <span className="control-label">{elem.label}</span>
                            <FormattedAmount quantity={elem.balance.quantity} currency={elem.balance.currency} />
                        </React.Fragment>
                    ),
                    accountBalance: elem.balance,
                    isFrequentDestination: false,
                };
            }
            return {
                value: elem.value,
                isFrequentDestination: false,
                label: elem.label,
                accountAlias: elem.label,
            };
        });
    };

    handleChangeDebitAmount = (debitQuantityParam) => {
        const { formData } = this.props;
        const { decimalSeparator, amountDecimals } = formData;
        const { dispatch, errors } = this.props;
        const debitQuantity =
            debitQuantityParam !== EMPTY_STR
                ? UtilsNumber.toNumber(debitQuantityParam, decimalSeparator, amountDecimals)
                : null;

        dispatch(
            SelectorsAction.changeAmount({
                lastTypedValue: debitQuantity,
                lastTypedInput: LAST_CHANGED_INPUT.DEBIT,
            }),
        );
        if (errors !== null) {
            errors.debitAmountTouched = true;
            errors.creditAmountTouched = true;

            dispatch(
                SelectorsAction.isTouched({
                    errors,
                }),
            );
        }
    };

    handleChangeCreditAmount = (creditQuantityParam) => {
        const { formData } = this.props;
        const { decimalSeparator, amountDecimals } = formData;
        const { dispatch, errors } = this.props;
        const creditQuantity =
            creditQuantityParam !== EMPTY_STR
                ? UtilsNumber.toNumber(creditQuantityParam, decimalSeparator, amountDecimals)
                : null;

        dispatch(
            SelectorsAction.changeAmount({
                lastTypedValue: creditQuantity,
                lastTypedInput: LAST_CHANGED_INPUT.CREDIT,
            }),
        );
        if (errors !== null) {
            errors.debitAmountTouched = true;
            errors.creditAmountTouched = true;

            dispatch(
                SelectorsAction.isTouched({
                    errors,
                }),
            );
        }
    };

    handleSelling = () => {
        const { dispatch } = this.props;
        dispatch(
            SelectorsAction.changeOperation({
                operation: STORE_OPERATIONS.SELLING,
            }),
        );
    };

    handleBuying = () => {
        const { dispatch } = this.props;
        dispatch(
            SelectorsAction.changeOperation({
                operation: STORE_OPERATIONS.BUYING,
            }),
        );
    };

    handleBlurError = (fieldName) => {
        const { dispatch, errors } = this.props;
        if (errors !== null) {
            errors[fieldName.concat("Touched")] = true;
            dispatch(
                SelectorsAction.isTouched({
                    errors,
                }),
            );
        }
    };

    close = () => {
        const { dispatch } = this.props;
        clearInterval(this.interval);
        this.interval = null;
        dispatch(SelectorsAction.closePage());
        dispatch(
            Replace({
                pathname: "/desktop",
                state: { transition: "transition-flow-close" },
            }),
        );
    };

    handleBackWeb = () => {
        const { history } = this.props;
        history.goBack();
    };

    render() {
        const { fetching, renderFormWaiter, fxState } = this.props;
        const { show, messageTitle, messageBody } = this.state;

        const showLoading = (fetching && !renderFormWaiter) || fxState === STORE_FXSTATE.INITIALOADING;
        // If the user needs Biometric and the state is Preview the loader should
        // not be shown because the form waiter is showing

        return (
            <Container
                name={NAME}
                className={CLASS}
                wait={showLoading}
                step={STEPS[fxState]}
                head-onClose={this.close}
                head-onBackWeb={
                    fxState !== STORE_FXSTATE.INITIALOADING && fxState !== STORE_FXSTATE.SEND && this.handleBackWeb
                }
                head-onBack={
                    fxState !== STORE_FXSTATE.INITIALOADING &&
                    fxState !== STORE_FXSTATE.FORM &&
                    fxState !== STORE_FXSTATE.SEND &&
                    this.returnToForm
                }
                head-title="preferentialTradingPrice.title"
                scopeToShowNotification={SCOPE.PREFERENTIAL_TRADING_PRICE}>
                <ModalFX
                    show={show}
                    handleClose={this.hideModal}
                    messageTitle={messageTitle}
                    messageBody={messageBody}
                />
                {this.renderFX()}
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    formData: SelectorsStore.formData(store),
    fxState: SelectorsStore.fxState(store),
    pollCotizacion: SelectorsStore.pollCotizacion(store),
    exchangeValueChanged: SelectorsStore.exchangeValueChanged(store),
    rateChange: SelectorsStore.rateChange(store),
    fetching: SelectorsStore.fetching(store),
    operation: SelectorsStore.operation(store),
    quotation: SelectorsStore.quotation(store),
    previewData: SelectorsStore.previewData(store),
    sendData: SelectorsStore.sendData(store),
    errors: SelectorsStore.errors(store),
    requestRate: SelectorsStore.requestRate(store),
    currencyChanged: SelectorsStore.currencyChanged(store),
    renderFormWaiter: SelectorsStore.renderFormWaiter(store),
    processingTransactionsList: SelectorsStore.getProcessingTransactionsList(store),
});

export default Connect(mapStateToProps)(Component);
