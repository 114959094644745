import React from "react";

import PropTypes from "prop-types";

import { SPACE_STR } from "~/constants";
import * as UtilsI18n from "~/util/i18n";
import * as UtilsNumber from "~/util/number";

import I18n from "~/components/I18n";

export const NAME = "NominalValue";

export const PROP = {
    types: { isSelling: PropTypes.bool },
    defaults: {
        isSelling: false,
    },
};

export function Component(props) {
    const { minimum, multiple } = props;

    const lang = UtilsI18n.getLang();
    return (
        <div className="form-group">
            <small className="text-optional">
                <I18n id="client.wm.bonds.buy.minimumValueAlert" />
                {SPACE_STR}
                <span className="nominalValue">
                    {parseInt(minimum, 10) !== 0 ? UtilsNumber.numberToLocaleFormat(parseFloat(minimum), lang) : "0,00"}
                </span>
                {SPACE_STR}
                <I18n id="client.wm.bonds.buy.multipleValueAlert" />
                {SPACE_STR}
                <span className="nominalValue">
                    {parseInt(multiple, 10) !== 0
                        ? UtilsNumber.numberToLocaleFormat(parseFloat(multiple), lang)
                        : "0,00"}
                </span>
            </small>
        </div>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
