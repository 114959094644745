import { SelectorsAction as SelectorsActionChannels } from "~/store/administration/common/channels";
import { SelectorsAction as SelectorsActionDetails } from "~/store/administration/common/details";
import { SelectorsAction as SelectorsActionPermissions } from "~/store/administration/common/permissions";
import { SelectorsAction as SelectorsActionSignatures } from "~/store/administration/common/signatures";
import * as API from "~/store/api";

import {
    TYPE_ADMINISTRATION_LEVEL_ADVANCED_CHANNELS,
    TYPE_ADMINISTRATION_LEVEL_ADVANCED_DETAILS,
    TYPE_ADMINISTRATION_LEVEL_ADVANCED_PERMISSION,
    TYPE_ADMINISTRATION_LEVEL_ADVANCED_SIGNATURE,
    PATH,
} from "./_consts";

export const SelectorsMiddleware = {
    loadDetailsRequest: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_ADVANCED_USER_DETAILS_READ, props),

    loadChannelsRequest: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_MEDIUM_CHANNELS_READ, props),

    updateChannelsPreview: (props) =>
        API.executeWithAccessToken(PATH.ADMINISTRATION_MEDIUM_CHANNELS_MODIFY_PREVIEW, props),

    updateChannelsRequest: (props) =>
        API.executeWithAccessToken(PATH.ADMINISTRATION_MEDIUM_CHANNELS_MODIFY_SEND, props),

    updateSignatureRequest: (props) =>
        API.executeWithAccessToken(PATH.ADMINISTRATION_MEDIUM_SIGNATURE_MODIFY_SEND, props),
    updateGroupsOfUserPreview: (props) =>
        API.executeWithAccessToken(PATH.ADMINISTRATION_USER_DETAIL_GROUPS_MODIFY_PREVIEW, props),

    updateGroupsOfUser: (props) =>
        API.executeWithAccessToken(PATH.ADMINISTRATION_USER_DETAIL_GROUPS_MODIFY_SEND, props),

    loadGroupRequest: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_ADVANCED_GROUP_READ, props),

    // TODO: Se separo en 2 funciones 'loadGroupFormpropsRequest'. Hay que actualizar el lugar donde se llama.
    loadGroupFormpropsRequestCreate: (props) =>
        API.executeWithAccessToken(PATH.ADMINISTRATION_ADVANCED_GROUP_CREATE_PRE, props),

    loadGroupFormpropsRequestModify: (props) =>
        API.executeWithAccessToken(PATH.ADMINISTRATION_ADVANCED_GROUP_MODIFY_PRE, props),

    createSignaturesScheme: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_SIGNATURES_CREATE_SEND, props),

    createSignaturesSchemePre: () => API.executeWithAccessToken(PATH.ADMINISTRATION_SIGNATURES_CREATE_PRE),

    listSignaturesSchemes: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_SIGNATURES_LIST, props),

    deleteSignaturesScheme: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_SIGNATURES_DELETE_SEND, props),

    deleteSignaturesSchemePre: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_SIGNATURES_DELETE_PRE, props),

    modifySignaturesScheme: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_SIGNATURES_MODIFY_SEND, props),

    modifySignaturesSchemePre: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_SIGNATURES_MODIFY_PRE, props),

    // TODO: Se separo en 2 funciones. Hay que actualizar el lugar donde se llama.
    submitGroupFormPreviewRequestCreate: (props) =>
        API.executeWithAccessToken(PATH.ADMINISTRATION_ADVANCED_GROUP_CREATE_PREVIEW, props),

    submitGroupFormPreviewRequestModify: (props) =>
        API.executeWithAccessToken(PATH.ADMINISTRATION_ADVANCED_GROUP_MODIFY_PREVIEW, props),

    // TODO: Se separo en 2 funciones. Hay que actualizar el lugar donde se llama.
    submitGroupFormRequestCreate: (props) =>
        API.executeWithAccessToken(PATH.ADMINISTRATION_ADVANCED_GROUP_CREATE_SEND, props),

    submitGroupFormRequestModify: (props) =>
        API.executeWithAccessToken(PATH.ADMINISTRATION_ADVANCED_GROUP_MODIFY_SEND, props),

    exportSignatureSchemes: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_SIGNATURES_EXPORT, props),

    loadFormRequest: (props) => API.executeWithAccessToken(PATH.ADMINISTRATION_ADVANCED_USER_FORM_READ, props),
};

export const SelectorsActionDetailsAdvanced = { ...SelectorsActionDetails(TYPE_ADMINISTRATION_LEVEL_ADVANCED_DETAILS) };
export const SelectorsActionPermissionsAdvanced = {
    ...SelectorsActionPermissions(TYPE_ADMINISTRATION_LEVEL_ADVANCED_PERMISSION),
};
export const SelectorsActionChannelsAdvanced = {
    ...SelectorsActionChannels(TYPE_ADMINISTRATION_LEVEL_ADVANCED_CHANNELS),
};
export const SelectorsActionSignaturesAdvanced = {
    ...SelectorsActionSignatures(TYPE_ADMINISTRATION_LEVEL_ADVANCED_SIGNATURE),
};
