import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import Device, { DEVICE_MOBILE } from "~/util/device";
import { downloadPdf as DownloadPdf } from "~/util/download";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction } from "./_selectors";

import * as UtilI18n from "~/util/i18n";

export default [
    TakeLatest(TYPE.SHARE_LOCAL_REQUEST, function* shareLocal(props) {
        const response = yield Call(SelectorsMiddleware.shareLocal, props);

        if (Device.isMobileNative()) {
            const { localAccountDescription } = response.data.data;
            const infTitle = UtilI18n.get("share.local.pdf.pdfName");

            const options = {
                subject: infTitle,
                message: localAccountDescription,
            };

            window.plugins.socialsharing.shareWithOptions(
                options,
                yield Put(SelectorsAction.successShareLocal()),
                null,
            );
        } else {
            const { file } = response.data.data;
            const { fileTitle } = response.data.data;
            DownloadPdf(fileTitle, file);
            yield Put(SelectorsAction.successShareLocal());
        }
    }),
    TakeLatest(TYPE.SHARE_FOREIGN_REQUEST, function* shareForeign(props) {
        const response = yield Call(SelectorsMiddleware.shareLocal, props);

        const { file } = response.data.data;
        const { fileTitle } = response.data.data;

        if (Device.isMobileNative()) {
            let pdfAsDataUri = `data:application/pdf;base64,${file}`;

            if (Device.mobileOS() === DEVICE_MOBILE.IOS) {
                pdfAsDataUri = `data:application/pdf:${fileTitle}.pdf;base64,${file}`;
            }

            const options = {
                subject: fileTitle,
                files: [pdfAsDataUri],
            };

            window.plugins.socialsharing.shareWithOptions(
                options,
                yield Put(SelectorsAction.successShareLocal()),
                null,
            );
        } else {
            DownloadPdf(fileTitle, file);
            yield Put(SelectorsAction.successShareLocal());
        }
    }),
];
