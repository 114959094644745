import React from "react";

import { routerActions as ActionRouter } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";

import { STATE_NUMBER, SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/List";
import { SelectorsAction as ActionCC, SelectorsStore as StoreCC } from "~/store/creditCards/creditCard";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";

import DataDate from "~/components/DataDate";

export const NAME = "Statements";

export const PROP = {
    types: {
        creditCard: PropTypes.shape({
            idProduct: PropTypes.string.isRequired,
        }),
        isChangingEnvironment: PropTypes.bool,
        statements: PropTypes.arrayOf(PropTypes.object),
    },
    defaults: {
        isChangingEnvironment: false,
        statements: [],
    },
};

export function Component(props) {
    const { statements, id, isChangingEnvironment, dispatch, wait, i18n } = props;

    const statementList = statements[id];
    React.useEffect(() => {
        if (!isChangingEnvironment) {
            dispatch(ActionCC.creditCardStatementsRequest({ id }));
        }
    }, [dispatch, id, isChangingEnvironment]);

    if (statementList && statementList.length > STATE_NUMBER) {
        //  Show just last 12 months
        statementList.splice(STATE_NUMBER - 1, statementList.length - STATE_NUMBER);
    }

    return (
        <Container
            name={NAME}
            head-onBack={handleBack}
            head-title="creditCard.accountStatements"
            wait={wait}
            scopeToShowNotification={SCOPE.CREDIT_CARD_STATEMENT_DOWNLOAD}>
            {!statementList && <span>{i18n.loading}</span>}
            {statementList &&
                statementList.map(({ date }) => (
                    <button key={date} type="button" onClick={handleClick.bind(null, date)}>
                        <DataDate raw value={date} type-year={false} type-month="long" type-day={false} />{" "}
                        <DataDate raw value={date} type-year="numeric" type-month={false} type-day={false} />
                    </button>
                ))}
        </Container>
    );

    function handleBack() {
        dispatch(ActionRouter.goBack());
    }

    function handleClick(date) {
        const year = date.substr(0, 4);
        const month = date.substr(5, 2);
        dispatch(ActionCC.creditCardStatementDownloadRequest({ id, year, month }));
    }
}
Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

const mapStateToProps = (store, props) => {
    const isFetching = StoreCC.getFetching(store);
    const isDownloading = StoreCC.getDownloading(store);
    return {
        // TODO: shouldn't use withRouter, connected-react-router has support for this,
        //       but it needs to have the route definition centralized.
        id: props.match.params.id,
        statements: StoreCC.getCreditCardStatements(store),
        wait: isFetching || isDownloading,
        i18n: {
            loading: StoreI18n.getMessage(store, "global.loading"),
        },
        isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    };
};

export default Connect(mapStateToProps)(WithRouter(Component));
