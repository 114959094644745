import { call as Call } from "redux-saga/effects";

import { EMPTY_STR, RESPONSE_TYPE } from "~/constants";
import { SelectorsMiddleware as SelectorsMiddlewareBankSelector } from "~/store/bankSelector";

export default function* bankSelected(values) {
    const newValues = values;
    if (values.creditBankCode && !values.creditBankCode.bank?.bankName) {
        const {
            type: responseType,
            data: { data },
        } = yield Call(SelectorsMiddlewareBankSelector.loadListRequest, {
            country: EMPTY_STR,
            name: EMPTY_STR,
            ...values.creditBankCode,
        });
        if (responseType !== RESPONSE_TYPE.WARNING && data.banks.length > 0) {
            const { type, code } = values.creditBankCode;
            const bank = data.banks[0];
            const { bankCountryLabel, bankName } = bank;
            newValues.creditBankCode = { type, code, bank: { bankCountryLabel, bankName } };
        }
    }
    if (
        values.intermediaryBankCheckbox &&
        values.intermediaryBankCheckbox.length > 0 &&
        values.intermediaryBankCode &&
        (!values.intermediaryBankCode.bank || !values.intermediaryBankCode.bank.bankName)
    ) {
        const filter = {
            country: EMPTY_STR,
            name: EMPTY_STR,
            code: values.intermediaryBankCode.code ? values.intermediaryBankCode.code : EMPTY_STR,
            ...values.intermediaryBankCode,
        };
        const {
            type: responseType,
            data: { data },
        } = yield Call(SelectorsMiddlewareBankSelector.loadListRequest, filter);
        if (responseType !== RESPONSE_TYPE.WARNING && data.banks.length > 0) {
            const { type } = values.intermediaryBankCode;
            const code = values.intermediaryBankCode.code ? values.intermediaryBankCode.code : EMPTY_STR;
            const bank = data.banks[0];
            const { bankCountryLabel, bankName } = bank;
            newValues.intermediaryBankCode = { type, code, bank: { bankCountryLabel, bankName } };
        }
    }

    return newValues;
}
