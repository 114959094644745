import { LOCATION_CHANGE } from "connected-react-router";

import { MODE } from "~/constants/form";
import { TYPE as TypesBackoffice } from "~/store/backoffice";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            return {
                ...PROP.defaults,
            };
        }
        case TYPE.CLEAN_UP:
            return {
                ...PROP.defaults,
            };
        case TYPE.CLEAN_BILLS:
            return {
                ...storeProp,
                documentsToPayList: PROP.defaults.documentsToPayList,
            };
        case TYPE.SERVICE_PAYMENT_PREVIEW_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                formData: action.formData,
            };
        case TYPE.FETCH_SERVICE_DATA_REQUEST:
        case TYPE.SERVICE_PAYMENT_PRE_REQUEST:
        case TYPE.SERVICE_PAYMENT_SEND_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.FETCH_SERVICE_DATA_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                documentsToPayList: action.documentsToPayList,
            };
        case TYPE.SERVICE_PAYMENT_PRE_SUCCESS:
            return {
                ...storeProp,
                debitAccounts: action.debitAccounts,
                fetching: false,
                sistarbancAvailableServices: action.sistarbancAvailableServices,
            };
        case TYPE.SERVICE_PAYMENT_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                debitAccountAlias: action.debitAccountAlias,
                debitAmount: action.debitAmount,
                fetching: false,
                isSameCurrency: action.isSameCurrency,
                mode: MODE.PREVIEW,
                prevMode: storeProp.mode,
                rate: action.rate,
                rateFirstCurrency: action.rateFirstCurrency,
                rateSecondCurrency: action.rateSecondCurrency,
                sistarbancValidateResult: action.sistarbancValidateResult,
                transactionDate: action.transactionDate,
                transactionHour: action.transactionHour,
            };
        case TYPE.SERVICE_PAYMENT_SEND_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                prevMode: storeProp.mode,
                transaction: action.transaction,
                mode: MODE.VIEW,
            };
        case TYPE.FORM_TO_STEP_1:
            return {
                ...storeProp,
                mode: MODE.EDIT,
            };
        case TYPE.SERVICE_PAYMENT_PRE_FAILURE:
        case TYPE.SERVICE_PAYMENT_PREVIEW_FAILURE:
        case TYPE.SERVICE_PAYMENT_SEND_FAILURE:
        case TYPE.FETCH_SERVICE_DATA_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.SIGN_TRANSACTION_PREVIEW:
            return {
                ...storeProp,
                mode: MODE.PREVIEW,
                prevMode: storeProp.mode,
            };
        case TYPE.SIGN_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                prevMode: storeProp.mode,
                transaction: action.transaction,
            };
        case TYPE.READ_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                transaction: action.transaction,
                mode: MODE.VIEW,
            };
        case TYPE.QR_MODO_READ_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                transaction: action.transaction,
                mode: MODE.VIEW,
            };
        case TYPE.HANDLE_FETCHING:
            return {
                ...storeProp,
                fetching: action.fetching,
            };
        case TypesBackoffice.BACKOFFICE_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                prevMode: storeProp.mode,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };
        default:
            return { ...storeProp };
    }
};
