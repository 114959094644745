import React from "react";

import { push as Push } from "connected-react-router";
import { connect as Connect } from "react-redux";

import { CDP_CURRENCY, EMPTY_STR, FACTORING_TYPES, LEVEL, PIPE_SEPARATOR, SCOPE, SPACE_STR } from "~/constants";
import { PROP as PROP_FACTORING_ADVANCEPAYMENTS } from "~/store/factoring/advancePayments";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as ActionWidget, SelectorsStore as StoreWidget } from "~/store/widget";
import * as UtilsI18n from "~/util/i18n";

import DataNumber from "~/components/DataNumber";
import HighOrder from "~/components/HighOrder";

import Style from "./Factoring.rules.scss";
import Widget from "./_common/_Widget";

import { ReactComponent as AdvancePayments } from "~/assets/advancePayments.svg";
import { ReactComponent as ListAdvancePayments } from "~/assets/listAdvancePayments.svg";
import { ReactComponent as PaymentOrders } from "~/assets/paymentOrders.svg";
import { ReactComponent as Suppliers } from "~/assets/suppliers.svg";
import { ReactComponent as UploadPaymentOrders } from "~/assets/upload-paymentOrders.svg";
import { ReactComponent as UploadSuppliers } from "~/assets/upload-suppliers.svg";

export const { NAME, TYPE } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const {
        accounts,
        activeEnvironment: {
            factoring,
            permissions: {
                advancePayments = false,
                listAdvancePayments = false,
                listPaymentOrders = false,
                listSuppliers = false,
                uploadPaymentOrders = false,
                uploadSuppliers = false,
            },
        },
        data: { totalDiscountableUSD, totalDiscountableUYU },
        dispatch,
        fetching,
        isFetching,
        isMobile,
        store: widget,
        ...rest
    } = props;

    React.useEffect(() => {
        dispatch(
            ActionWidget.listRequest({
                widget,
                ...PROP_FACTORING_ADVANCEPAYMENTS.defaults.filtersAdvancePaymentsList,
            }),
        );
    }, [dispatch, isMobile, widget]);

    const handleClick = (hasPermission, path) => {
        if (hasPermission) {
            dispatch(Push(path));
        } else {
            dispatch(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("tranfer.permission"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.DESKTOP],
                }),
            );
        }
    };

    const showAdvance = totalDiscountableUSD > 0 || totalDiscountableUYU > 0;
    const separator = totalDiscountableUSD > 0 && totalDiscountableUYU > 0;
    const showWidget =
        !isMobile || (isMobile && (factoring === FACTORING_TYPES.PAYEE || factoring === FACTORING_TYPES.BOTH));

    const isPayer = factoring === FACTORING_TYPES.PAYER || factoring === FACTORING_TYPES.BOTH;
    const isPayee = factoring === FACTORING_TYPES.PAYEE || factoring === FACTORING_TYPES.BOTH;

    let headerHref = "/factoring";

    if (isMobile) {
        if (advancePayments) {
            headerHref = "/advancePayments/transaction";
        } else {
            headerHref = EMPTY_STR;
        }
    }

    return (
        showWidget && (
            <Widget
                {...rest}
                header-href={headerHref}
                header-icon="menu-factoring.svg"
                header-title="menu.factoring"
                id={Style.ID}
                wait={isFetching}>
                <div className="container__fac">
                    <div className="factoring-container">
                        {isPayee && (
                            <React.Fragment>
                                <div className="notification">
                                    {!showAdvance && (
                                        <div className="notificaton__text">
                                            {UtilsI18n.get("desktop.widgets.factoring.noAdvance")}
                                        </div>
                                    )}
                                    {showAdvance && (
                                        <React.Fragment>
                                            <div className="notification__text">
                                                {UtilsI18n.get("desktop.widgets.factoring.advanceAvailable")}
                                            </div>
                                            <div>
                                                {totalDiscountableUYU > 0 && (
                                                    <DataNumber
                                                        value={totalDiscountableUYU}
                                                        prefix={CDP_CURRENCY.UYU}
                                                    />
                                                )}
                                                <span className="notification__text">
                                                    {separator && PIPE_SEPARATOR}
                                                    {SPACE_STR}
                                                </span>
                                                {totalDiscountableUSD > 0 && (
                                                    <DataNumber
                                                        value={totalDiscountableUSD}
                                                        prefix={CDP_CURRENCY.USD}
                                                    />
                                                )}
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>
                            </React.Fragment>
                        )}
                        <div className="factoring-container__boxes">
                            {!isMobile && isPayer && (
                                <React.Fragment>
                                    <div className="factoring-container__boxes-box-two">
                                        <div className="factoring-container__items">
                                            <div
                                                className="factoring-item"
                                                onClick={() => handleClick(listSuppliers, "suppliers")}>
                                                <div className="factoring-container__items-svg">
                                                    <Suppliers />
                                                    <p>{UtilsI18n.get("menu.factoring.suppliers")}</p>
                                                </div>
                                            </div>
                                            <div
                                                className="factoring-item"
                                                onClick={() => handleClick(uploadSuppliers, "uploadSuppliers")}>
                                                <div className="factoring-container__items-svg">
                                                    <UploadSuppliers />
                                                    <p>{UtilsI18n.get("menu.factoring.uploadSuppliers")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="factoring-container__boxes-box-two">
                                        <div className="factoring-container__items">
                                            <div
                                                className="factoring-item"
                                                onClick={() => handleClick(listPaymentOrders, "paymentOrders")}>
                                                <div className="factoring-container__items-svg">
                                                    <PaymentOrders />
                                                    <p>{UtilsI18n.get("menu.factoring.paymentOrders")}</p>
                                                </div>
                                            </div>
                                            <div
                                                className="factoring-item"
                                                onClick={() => handleClick(uploadPaymentOrders, "uploadPaymentOrders")}>
                                                <div className="factoring-container__items-svg">
                                                    <UploadPaymentOrders />
                                                    <p>{UtilsI18n.get("menu.factoring.uploadPaymentOrders")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                            {isPayee && (
                                <React.Fragment>
                                    <div className="factoring-container__boxes-box-two">
                                        <div className="factoring-container__items">
                                            <div
                                                className="factoring-item"
                                                onClick={() =>
                                                    handleClick(advancePayments, "advancePayments/transaction")
                                                }>
                                                <div className="factoring-container__items-svg">
                                                    <AdvancePayments />
                                                    <p>{UtilsI18n.get("menu.factoring.advancePayments")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {!isMobile && (
                                        <div className="factoring-container__boxes-box-two">
                                            <div className="factoring-container__items">
                                                <div
                                                    className="factoring-item"
                                                    onClick={() =>
                                                        handleClick(listAdvancePayments, "listAdvancePayments")
                                                    }>
                                                    <div className="factoring-container__items-svg">
                                                        <ListAdvancePayments />
                                                        <p>{UtilsI18n.get("menu.factoring.listAdvancePayments")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </Widget>
        )
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

function mapStateToProps(store, props) {
    const { store: widget } = props;
    const { isFetching, data = [] } = StoreWidget.getWidget(store, widget) || {};
    const activeEnvironment = SelectorsStoreSession.getActiveEnvironment(store);

    return { activeEnvironment, data, isFetching };
}

export default HighOrder(Connect(mapStateToProps), HighOrder.Resizable)(Component);
