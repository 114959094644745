import React from "react";

import ClassNames from "classnames";
import PropTypes from "prop-types";

import * as Utils from "~/util/general";

import I18n from "~/components/I18n";
import Image from "~/components/Image";
import WidgetImage from "~/components/WidgetImage";

import Style from "./TransactionStatus.rules.scss";

export const { CLASS, NAME } = Style;

export const PROP = {
    types: {
        expressAlreadyRendered: PropTypes.bool,
        idTransactionStatus: PropTypes.string.isRequired,
        showLabel: PropTypes.bool,
    },
    defaults: { expressAlreadyRendered: false, showLabel: false },
};

export function Component(props) {
    const { expressAlreadyRendered, idTransactionStatus, isExpressTransfer, showLabel } = props;

    return (
        <div className={ClassNames(CLASS, "bubble-wrapper")}>
            <div>
                {isExpressTransfer ? (
                    <Image
                        src="transferExpress.svg"
                        classNameOuter={ClassNames({
                            "express-transfer": !expressAlreadyRendered,
                            "without-animation": expressAlreadyRendered,
                        })}
                    />
                ) : (
                    <WidgetImage
                        src={`${Utils.getTransactionStatusIcon(idTransactionStatus)}.svg`}
                        className="svg-icon"
                    />
                )}
            </div>
            {showLabel && (
                <span className="data-aux">
                    <I18n id={`transaction.status.${idTransactionStatus}`} />
                </span>
            )}
        </div>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
