/* global google */
import React from "react";

import { Map, Marker, InfoWindow, GoogleApiWrapper } from "google-maps-react";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";

import { PROP as STORE_PROP, SelectorsAction, SelectorsStore } from "~/store/locations";
import * as ConfigUtils from "~/util/config";
import UtilsDevice, { DEVICE_MOBILE } from "~/util/device";
import { generateId } from "~/util/general";
import * as UtilI18n from "~/util/i18n";

import Button from "~/components/Button";

export const NAME = "GoogleMaps";

export const PROP = {
    types: {
        ...STORE_PROP.types,
        center: PropTypes.shape({
            lat: PropTypes.number,
            lng: PropTypes.number,
        }),
    },
    defaults: {
        ...STORE_PROP.defaults,
        center: {
            lat: -34.8853897,
            lng: -56.1422028,
        },
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {
        showingInfoWindow: false,
        activeMarker: null,
        selectedPlace: {},
        center: PROP.defaults.center,
    };

    componentDidMount() {
        const { dispatch } = this.props;

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState(() => ({
                    center: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    },
                }));
            });
        }

        dispatch(SelectorsAction.locationListRequest({ searchType: PROP.defaults.searchType }));
    }

    handleMapClicked = () => {
        const { showingInfoWindow } = this.state;
        if (showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null,
            });
        }
    };

    handleMarkerClick = (props, marker) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
        });
    };

    handleToggleMarkers = (event) => {
        const { dispatch } = this.props;
        dispatch(SelectorsAction.locationListRequest({ searchType: event.currentTarget.id }));
    };

    renderOpenMapsAppLink = (data) => {
        if (UtilsDevice.mobileOS() === DEVICE_MOBILE.IOS) {
            return (
                <a
                    href={`maps://?q="${data.lat},${data.long}`}
                    onClick={() => {
                        window.open(`maps://?q="${data.lat},${data.long}`, "_system");
                    }}>
                    {UtilI18n.get("linkToGoogleMapApp")}
                </a>
            );
        }
        if (UtilsDevice.mobileOS() === DEVICE_MOBILE.ANDROID) {
            return (
                <a
                    href={`https://maps.google.com/?q=${data.lat},${data.long}`}
                    onClick={() => {
                        window.open(`https://maps.google.com/?q=${data.lat},${data.long}`, "_system");
                    }}>
                    {UtilI18n.get("linkToGoogleMapApp")}
                </a>
            );
        }
        return (
            <a
                href={`https://maps.google.com/?q=${data.lat},${data.long}`}
                onClick={() => {
                    window.open(`https://maps.google.com/?q=${data.lat},${data.long}`, "_system");
                }}
                target="_blank"
                rel="noopener noreferrer">
                {UtilI18n.get("linkToGoogleMapApp")}
            </a>
        );
    };

    getPath = (iconName) => {
        /* eslint-disable-next-line import/no-dynamic-require, global-require, no-undef */
        let path = require(`~/assets/${iconName}.svg`);
        if (UtilsDevice.isMobileNative() && path.indexOf("/") === 0) {
            path = path.slice(1);
        }
        return path;
    };

    render() {
        const { locationList, fetching } = this.props;
        const { activeMarker, showingInfoWindow, selectedPlace, center } = this.state;
        const { data = {} } = selectedPlace;
        return (
            <React.Fragment>
                <div className="content">
                    <Button
                        id="1"
                        className="location-type-selection"
                        label="geolocalization.branches"
                        onClick={this.handleToggleMarkers}
                        disabled={fetching}
                        title={UtilI18n.get("geolocalization.branches")}
                    />
                    <Button
                        id="2"
                        className="location-type-selection"
                        label="geolocalization.atms"
                        onClick={this.handleToggleMarkers}
                        disabled={fetching}
                        title={UtilI18n.get("geolocalization.atms")}
                    />
                    <Button
                        id="3"
                        className="location-type-selection"
                        label="geolocalization.selfHelp"
                        onClick={this.handleToggleMarkers}
                        disabled={fetching}
                        title={UtilI18n.get("geolocalization.selfHelp")}
                    />
                </div>
                <Map google={window.google} zoom={12} initialCenter={center} onClick={this.handleMapClicked}>
                    {locationList.map((locationInfo) => (
                        <Marker
                            key={generateId()}
                            title={locationInfo.name}
                            name={locationInfo.name}
                            position={{ lat: locationInfo.lat, lng: locationInfo.long }}
                            data={locationInfo}
                            icon={{
                                url: this.getPath(locationInfo.type),
                                scaledSize: new google.maps.Size(64, 64),
                            }}
                            onClick={this.handleMarkerClick}
                        />
                    ))}
                    <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
                        {data && (
                            <div>
                                <h4>{data.name}</h4>
                                <div>{data.address}</div>
                                <div>
                                    <span>{data.city}</span>
                                    <span>{data.country}</span>
                                </div>
                                <div>
                                    {/* OBS: onClick event added because href doesn't work in PhoneGap */}
                                    {this.renderOpenMapsAppLink(data)}
                                </div>
                            </div>
                        )}
                    </InfoWindow>
                </Map>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    locationList: SelectorsStore.locationList(store),
    fetching: SelectorsStore.fetching(store),
});

export default Compose(
    Connect(mapStateToProps),
    GoogleApiWrapper({
        apiKey: ConfigUtils.get("geolocalization.googleClientID"),
    }),
)(Component);
