/* eslint-disable eqeqeq */
import React from "react";

import classnames from "classnames";
import { Field, Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import Container from "~/containers/Internal/Form/Filter";
import {
    SelectorsAction as SelectorsActionInvestment,
    SelectorsStore as SelectorsStoreInvestment,
} from "~/store/investment";
import * as UtilsConfig from "~/util/config";
import { generateId as GenerateId } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Selector from "~/pages/_components/fields/formik/Selector";

import Style from "./RiskProfile.rules.scss";

const FORM_ID = "wm.riskProfile.step3";

export const NAME = "InvestmentRiskProfile";

export const PROP = {
    types: {
        ...TypesRedux,
        fetching: PropTypes.bool,
        setFieldValue: PropTypes.func.isRequired,
        values: PropTypes.object.isRequired,
    },
    defaults: {
        ...DefaultsRedux,
        fetching: false,
    },
};

const options = { 1: "a", 2: "b", 3: "c", 4: "d", 5: "e" };
const questionnaireVersion = "v2";

export class Component extends React.Component {
    state = {
        answersErrors: {
            answer1: 0,
            answer2: 0,
            answer3: 0,
            answer4: 0,
            answer5: 0,
            answer6: 0,
        },
        answersMap: {
            answer1: 0,
            answer2: 0,
            answer3: 0,
            answer4: 0,
            answer5: 0,
            answer6: 0,
        },
        finalized: false,
        hasError: false,
        numberOfQuestions: UtilsConfig.getInteger(`client.wm.riskProfile.numberOfQuestions.${questionnaireVersion}`),
        percentageComplete: 0,
        questionActive: 1,
    };

    componentDidUpdate(prevProps, prevState) {
        const { percentageComplete } = this.state;

        if (percentageComplete == 100 && percentageComplete != prevState.percentageComplete) {
            const finishButton = document.getElementById("finish");

            finishButton.focus();
        }
    }

    calcActiveInactive = (questionActive, number) => {
        const { percentageComplete } = this.state;

        return questionActive === number && percentageComplete !== 100 ? "active" : "inactive";
    };

    calcErrorAnswered = (answersErrors, number) => {
        return answersErrors[`answer${number}`] !== 0 ? "error" : "answered";
    };

    getQuestions = (index) => {
        switch (index) {
            case 1:
                return [
                    { id: "1", label: UtilsI18n.get("client.wm.riskProfile.questionnaire.v2.question.1.option.a") },
                    { id: "2", label: UtilsI18n.get("client.wm.riskProfile.questionnaire.v2.question.1.option.b") },
                    { id: "3", label: UtilsI18n.get("client.wm.riskProfile.questionnaire.v2.question.1.option.c") },
                    { id: "4", label: UtilsI18n.get("client.wm.riskProfile.questionnaire.v2.question.1.option.d") },
                ];
            case 2:
                return [
                    {
                        id: "1",
                        label: (
                            <I18n
                                className="container-table"
                                id="client.wm.riskProfile.questionnaire.v2.question.2.option.a"
                            />
                        ),
                    },
                    {
                        id: "2",
                        label: (
                            <I18n
                                className="container-table"
                                id="client.wm.riskProfile.questionnaire.v2.question.2.option.b"
                            />
                        ),
                    },
                    {
                        id: "3",
                        label: (
                            <I18n
                                className="container-table"
                                id="client.wm.riskProfile.questionnaire.v2.question.2.option.c"
                            />
                        ),
                    },
                    {
                        id: "4",
                        label: (
                            <I18n
                                className="container-table"
                                id="client.wm.riskProfile.questionnaire.v2.question.2.option.d"
                            />
                        ),
                    },
                ];
            case 3:
                return [
                    { id: "1", label: UtilsI18n.get("client.wm.riskProfile.questionnaire.v2.question.3.option.a") },
                    { id: "2", label: UtilsI18n.get("client.wm.riskProfile.questionnaire.v2.question.3.option.b") },
                    { id: "3", label: UtilsI18n.get("client.wm.riskProfile.questionnaire.v2.question.3.option.c") },
                    { id: "4", label: UtilsI18n.get("client.wm.riskProfile.questionnaire.v2.question.3.option.d") },
                    { id: "5", label: UtilsI18n.get("client.wm.riskProfile.questionnaire.v2.question.3.option.e") },
                ];
            case 4:
                return [
                    { id: "1", label: UtilsI18n.get("client.wm.riskProfile.questionnaire.v2.question.4.option.a") },
                    { id: "2", label: UtilsI18n.get("client.wm.riskProfile.questionnaire.v2.question.4.option.b") },
                    { id: "3", label: UtilsI18n.get("client.wm.riskProfile.questionnaire.v2.question.4.option.c") },
                ];
            case 5:
                return [
                    { id: "1", label: UtilsI18n.get("client.wm.riskProfile.questionnaire.v2.question.5.option.a") },
                    { id: "2", label: UtilsI18n.get("client.wm.riskProfile.questionnaire.v2.question.5.option.b") },
                    { id: "3", label: UtilsI18n.get("client.wm.riskProfile.questionnaire.v2.question.5.option.c") },
                ];
            case 6:
                return [
                    { id: "1", label: UtilsI18n.get("client.wm.riskProfile.questionnaire.v2.question.6.option.a") },
                    { id: "2", label: UtilsI18n.get("client.wm.riskProfile.questionnaire.v2.question.6.option.b") },
                    { id: "3", label: UtilsI18n.get("client.wm.riskProfile.questionnaire.v2.question.6.option.c") },
                ];
            default:
                return [];
        }
    };

    getSteps = (answersMap, answersErrors, questionActive) => {
        const { numberOfQuestions } = this.state;
        const steps = [];
        let hasAnswer;
        let clazz;
        let activeInActive;

        for (let index = 1; index <= numberOfQuestions; index += 1) {
            hasAnswer = answersMap[`answer${index}`] !== 0;
            clazz = hasAnswer ? this.calcErrorAnswered(answersErrors, index) : EMPTY_STR;
            activeInActive = this.calcActiveInactive(questionActive, index);

            steps.push(
                <li key={GenerateId()}>
                    <a
                        id={`indicator${index}`}
                        className={`${clazz} indicator ${activeInActive}`}
                        onClick={() => this.goToQuestion(index)}>
                        {index}
                    </a>
                </li>,
            );
        }

        return steps;
    };

    getImage = (index) => {
        switch (index) {
            case 3:
                return [{ id: "1", label: "riskProfile/3rd-question.png" }];
            case 4:
                return [
                    { id: "1", label: "riskProfile/4th-question-A.png" },
                    { id: "2", label: "riskProfile/4th-question-B.png" },
                    { id: "3", label: "riskProfile/4th-question-C.png" },
                ];
            case 5:
                return [{ id: "1", label: "riskProfile/5th-question.png" }];
            default:
                return [];
        }
    };

    goToQuestion = (question) => {
        this.setState({ questionActive: question });
    };

    handleBack = () => {
        const { history } = this.props;

        history.goBack();
    };

    handleChange = (e, index) => {
        const { answersMap, numberOfQuestions, percentageComplete } = this.state;
        const percentageTotal = parseFloat(percentageComplete) + 100 / numberOfQuestions;

        const answersMapAux = JSON.parse(JSON.stringify(answersMap));
        answersMapAux[`answer${index}`] = options[e];

        if (answersMap[`answer${index}`] === 0) {
            this.setState({ percentageComplete: percentageTotal, answersMap: answersMapAux, hasError: false });
        } else {
            this.setState({ answersMap: answersMapAux, hasError: false });
        }

        if (percentageTotal != 100) {
            this.nextQuestion();
        }
    };

    handleFinalize = () => {
        this.setState({ finalized: true });
    };

    handleSend = () => {
        const { dispatch } = this.props;
        const { answersMap } = this.state;

        const params = {
            ...answersMap,
            questionnaireVersion,
        };

        dispatch(SelectorsActionInvestment.riskProfileSendQuestions(params));
    };

    nextQuestion = () => {
        const { numberOfQuestions, questionActive } = this.state;

        if (questionActive < numberOfQuestions) {
            this.goToQuestion(questionActive + 1);
        }
    };

    previousQuestion = () => {
        const { questionActive } = this.state;

        if (questionActive > 1) {
            this.goToQuestion(questionActive - 1);
        }
    };

    render() {
        const { fetching } = this.props;
        const {
            answersErrors,
            answersMap,
            finalized,
            errorMessage,
            hasError,
            numberOfQuestions,
            percentageComplete,
            questionActive,
        } = this.state;
        const percentage = parseFloat(percentageComplete).toFixed(0);

        return (
            <Container
                wait={fetching}
                name={NAME}
                head-onBack={() => this.handleBack()}
                head-title="wm.riskProfile.step2.title"
                className="position"
                image="businessPortfolio.svg">
                <Form id={Style.ID} noValidate="novalidate" className="col col-12 col-lg-6 col-md-9 col-sm-12">
                    <Box className="form-risk-profile">
                        <Box className={`advance-profile ${percentage == 100 ? "complete" : EMPTY_STR}`}>
                            <I18n
                                className="percent"
                                id="client.wm.riskProfile.questionnaire.percent"
                                percent={percentage}
                            />
                        </Box>
                        <Box className="q-numbers">
                            <ul className="qn">
                                <li>
                                    <a onClick={() => this.previousQuestion()} className="arrow" id="previousButton">
                                        &#60;
                                    </a>
                                </li>

                                {this.getSteps(answersMap, answersErrors, questionActive)}

                                <li>
                                    <a onClick={() => this.nextQuestion()} className="arrow" id="nextButton">
                                        &#62;
                                    </a>
                                </li>
                            </ul>
                        </Box>
                        {[...new Array(numberOfQuestions + 1)].map(
                            (e, i) =>
                                questionActive === i && (
                                    <Box className="q-contents" key={GenerateId()}>
                                        <Box className="content-title">
                                            <h4 className="item-pregunta">
                                                <span className="number">{i}</span>
                                                <I18n id={`client.wm.riskProfile.questionnaire.v2.question.${i}`} />
                                            </h4>
                                        </Box>
                                        <Box className="questionnaire-img">
                                            {this.getImage(i).map(
                                                (elem) => elem.label && <Image draggable="false" src={elem.label} />,
                                            )}
                                        </Box>
                                        <Box className={classnames({ "no-mt": i === 2 })}>
                                            {i === 2 && (
                                                <Box className="title-table">
                                                    <I18n
                                                        className="title-label"
                                                        id="client.wm.riskProfile.questionnaire.v2.question.2.table.title"
                                                    />
                                                </Box>
                                            )}
                                            <Field
                                                idForm={FORM_ID}
                                                component={Selector}
                                                options={this.getQuestions(i)}
                                                onChange={(el) => this.handleChange(el, i)}
                                                name={`questions_${i}`}
                                                renderAs="radio"
                                                inLineControl
                                            />
                                        </Box>
                                    </Box>
                                ),
                        )}
                    </Box>
                    {finalized && (
                        <Box className="form-risk-profile">
                            <Box className="container--data">
                                <h3 className="risk-header">
                                    <I18n id="client.wm.riskProfile.questionnaire.confirmation.title" />
                                </h3>
                                <p className="text-risk">
                                    <I18n id="client.wm.riskProfile.questionnaire.confirmation.body" />
                                </p>
                            </Box>
                        </Box>
                    )}
                    {hasError && <Box className="error-message" dangerouslySetInnerHTML={{ __html: errorMessage }} />}
                    <Box>
                        <Box className={percentage !== "100" ? "risk-button-disabled" : "risk-button"}>
                            {finalized !== true ? (
                                <Button
                                    disabled={percentage !== "100"}
                                    type="button"
                                    size="md"
                                    variant="primary"
                                    id="finish"
                                    onClick={this.handleFinalize}>
                                    <I18n id="global.finalize" />
                                </Button>
                            ) : (
                                <Button type="button" size="md" variant="primary" onClick={this.handleSend}>
                                    <I18n id="client.wm.riskProfile.send" />
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Form>
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    fetching: SelectorsStoreInvestment.getFetching(store),
});

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        mapPropsToValues: () => ({}),
        handleSubmit: () => {},
    }),
)(Component);
