import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { FactoryType } from "~/util/redux";

export const NAME = "communication";

export const TRAYS = {
    ALL: "all",
    READ: "read",
    SENT: "sent",
    UNREAD: "unread",
};

export const DEFAULT_TRAY = 10;

export const PATH = {
    COMMUNICATIONS_LIST_PRE: "/communications.listPre",
    COMMUNICATIONS_LIST: "/communications.list",
    COMMUNICATIONS_MARK_LIST_READ: "/communications.mark.list.read",
    COMMUNICATIONS_MARK_READ: "/communications.mark.read",
    COMMUNICATIONS_MARK_UNREAD: "/communications.mark.unread",
    COMMUNICATIONS_READ: "/communications.read",
    COMMUNICATIONS_REPLY: "/communications.reply",
    COMMUNICATIONS_SEND_PRE: "/communications.sendPre",
    COMMUNICATIONS_SEND: "/communications.send",
    LATEST_COMMUNICATIONS: "/communications.latestCommunications",
};

export const TYPE = FactoryType(NAME, [
    "COMMUNICATION_TRAYS_LIST_FAILURE",
    "COMMUNICATION_TRAYS_LIST_REQUEST",
    "COMMUNICATION_TRAYS_LIST_SUCCESS",
    "DATA_TO_NEW_MESSAGE",
    "DETAIL_FAILURE",
    "DETAIL_REQUEST",
    "DETAIL_SUCCESS",
    "DOWNLOAD_ATTACHMENT_FAILURE",
    "DOWNLOAD_ATTACHMENT_REQUEST",
    "DOWNLOAD_ATTACHMENT_SUCCESS",
    "FETCH_MORE_FAILURE",
    "FETCH_MORE_REQUEST",
    "FETCH_MORE_SUCCESS",
    "IS_FETCHING_ADD_FECHT",
    "IS_FETCHING_ADD_SUCCES",
    "LATEST_COMMUNICATION_SUCCESS",
    "LEFT_PANEL_COMPOSE",
    "LEFT_PANEL_HIDE",
    "LEFT_PANEL_READ",
    "LEFT_PANEL_REPLY",
    "LIST_FAILURE",
    "LIST_FILTERS",
    "LIST_REQUEST",
    "LIST_SUCCESS",
    "LIST_TYPES_FAILURE",
    "LIST_TYPES_REQUEST",
    "LIST_TYPES_SUCCESS",
    "LOADING",
    "MARK_AS_READ_FAILURE",
    "MARK_AS_READ_REQUEST",
    "MARK_AS_READ_SUCCESS",
    "MARK_AS_UNREAD_FAILURE",
    "MARK_AS_UNREAD_REQUEST",
    "MARK_AS_UNREAD_SUCCESS",
    "MARK_LIST_AS_READ_FAILURE",
    "MARK_LIST_AS_READ_REQUEST",
    "MARK_LIST_AS_READ_SUCCESS",
    "PRELOADED_MESSAGE",
    "REMOVE_ATTACHMENT",
    "REMOVE_FROM_TRAY",
    "REPLY_FAILURE",
    "REPLY_REQUEST",
    "REPLY_SUCCESS",
    "RESET_DATA_TO_NEW_MESSAGE",
    "SELECT_ATTACHMENT",
    "SEND_FAILURE",
    "SEND_REQUEST",
    "SEND_SUCCESS",
    "SET_ACTIVE_TRAY",
    "SET_ATTACHMENT",
    "TOGGLE_MESSAGE_STATUS",
]);

export const PROP = {};

PROP.types = {
    activeTray: PropTypes.string,
    attachments: PropTypes.array,
    communicationTraysList: PropTypes.array,
    currentPage: PropTypes.number,
    dataToNewMessage: PropTypes.object,
    detail: PropTypes.object, // TODO: Define
    isFetching: PropTypes.bool,
    isFetchingAdd: PropTypes.bool,
    leftPanel: PropTypes.object,
    list: PropTypes.array,
    listFilters: PropTypes.object,
    preloadedMessage: PropTypes.string, // TODO: Define
    selectedAttachments: PropTypes.array,
    subject: PropTypes.string,
    totalPages: PropTypes.number,
    types: PropTypes.array,
    unreadCommunications: PropTypes.number,
};

PROP.defaults = {
    activeTray: TRAYS.ALL,
    attachments: [],
    communicationTraysList: [],
    currentPage: 1,
    dataToNewMessage: null,
    detail: null,
    isFetching: false,
    isFetchingAdd: false,
    leftPanel: null,
    list: [],
    listFilters: {},
    preloadedMessage: null,
    selectedAttachments: null,
    subject: EMPTY_STR,
    totalPages: 0,
    types: [],
    unreadCommunications: 0,
};
