import React from "react";

import { routerActions as ActionsRouter } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { ID_FORM, MODE } from "~/constants/form";
import ContainerBackOffice from "~/containers/External/Payment";
import ContainerInternal from "~/containers/Internal/Form/Filter";
import { PROP as FILES_PROP } from "~/store/files";
import { SelectorsStore as SelectorsStoreForm } from "~/store/form";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import {
    SelectorsAction as SelectorsActionTransactionLines,
    SelectorsStore as SelectorsStoreTransactionLines,
} from "~/store/transactionLines";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Modal from "~/components/Modal";
import ConfirmationModal from "~/pages/_components/ConfirmationModal";

import Header from "~/pages/forms/_components/_fields/_transactionlines/Header";
import TransactionLinesList from "~/pages/forms/_components/_fields/_transactionlines/List";
import SalaryPaymentLinesForm from "~/pages/forms/_components/_fields/_transactionlines/forms/SalaryPaymentForm";
import SuppliersPaymentLinesForm from "~/pages/forms/_components/_fields/_transactionlines/forms/SuppliersPaymentForm";

import Style from "./PaymentLines.rules.scss";

export const NAME = "PaymentLines";

export const PROP = {
    types: {
        ...TypesRedux,
        isConfirmation: PropTypes.bool,
        isEditingPayment: PropTypes.bool.isRequired,
        isFetching: PropTypes.bool.isRequired,
        isFromBackoffice: PropTypes.bool,
        renderMoreDetails: PropTypes.func,
        transaction: PropTypes.object,
        transactionLines: PropTypes.arrayOf(PropTypes.shape(FILES_PROP.listItemProps)),
    },
    defaults: {
        ...DefaultsRedux,
        isConfirmation: false,
        isFromBackoffice: false,
        renderMoreDetails: null,
        transaction: {},
        transactionLines: [],
    },
};

const backUrl = (match) => match.url.split("/").slice(0, -1).join("/");

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        filter: undefined,
        filterData: null,
    };

    componentDidMount() {
        const { dispatch, isFromBackoffice, localBanks, mode } = this.props;

        dispatch(SelectorsActionTransactionLines.setPageNumber({ pageNumber: 0 }));

        if (mode === MODE.EDIT && localBanks.length === 0) {
            dispatch(SelectorsActionTransactionLines.listLocalBanksRequest());
        }

        if (!isFromBackoffice && mode === MODE.VIEW) {
            const {
                transaction: { idTransaction },
            } = this.props;

            dispatch(
                SelectorsActionTransactionLines.listTransactionLinesStatusRequest({
                    linesIdTransaction: idTransaction,
                }),
            );
        }
    }

    handleCloseModal = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionTransactionLines.setIsEditingPayment({ isEditingPayment: false }));
    };

    handleCloseConfirmationModal = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionTransactionLines.importPayrollConfirmationRequest({ confirmationRequest: false }));
    };

    handleAcceptImportPayroll = () => {
        const { dispatch } = this.props;

        this.handleCloseConfirmationModal();
        dispatch(SelectorsActionTransactionLines.showImportedPayroll());
    };

    handleDownloadClick = (format) => {
        const { onDownloadClick } = this.props;
        const { filter, filterData } = this.state;

        onDownloadClick({ filter, filterData }, format);
    };

    handleAdd = (values) => {
        const { dispatch, formMetadata } = this.props;
        const { idForm } = formMetadata;

        const param = { ...values, idForm };
        dispatch(SelectorsActionTransactionLines.addTransactionLine(param));

        this.handleCloseModal();
    };

    handleBackClick = () => {
        const { dispatch, history, match, mode, transaction } = this.props;
        if (mode === MODE.VIEW) {
            history.goBack();
        } else if (mode === MODE.PREVIEW && transaction.idTransaction) {
            dispatch(
                ActionsRouter.replace({
                    pathname: `/transaction/${transaction.idTransaction}`,
                    state: { shouldLoadForm: false },
                }),
            );
        } else {
            dispatch(ActionsRouter.replace({ pathname: backUrl(match), state: { shouldLoadForm: false } }));
        }
    };

    handleEdit = (values) => {
        const { dispatch, formMetadata } = this.props;
        const { idForm } = formMetadata;

        const param = { ...values, idForm };
        dispatch(SelectorsActionTransactionLines.editTransactionLine(param));

        this.handleCloseModal();
    };

    render() {
        const {
            confirmationRequest,
            formMetadata,
            isConfirmation,
            isEditingPayment,
            isFetching,
            isFromBackoffice,
            isLastPage,
            isTicket,
            lang,
            lineNumber,
            match,
            mode,
            paymentCurrency,
            transactionLines,
        } = this.props;

        const Container = isFromBackoffice ? ContainerBackOffice : ContainerInternal;

        const { formNameMap, idForm } = formMetadata;

        const initialValues = {
            address: EMPTY_STR,
            credit: {
                currency: paymentCurrency,
                amount: EMPTY_STR,
            },
            creditAccountBank: null,
            creditAccountName: EMPTY_STR,
            creditAccountNumber: EMPTY_STR,
            reference: EMPTY_STR,
            email: EMPTY_STR,
        };

        const isEditingLine = lineNumber !== 0;

        let modalLabelKey =
            idForm === ID_FORM.SUPPLIERS_PAYMENT
                ? "suppliersPayment.manual.addPayment.title"
                : "salaryPayment.manual.addPayment.title";

        if (isEditingLine) {
            modalLabelKey =
                idForm === ID_FORM.SUPPLIERS_PAYMENT
                    ? "suppliersPayment.manual.editPayment.title"
                    : "salaryPayment.manual.editPayment.title";
        }

        return (
            <Container
                name={NAME}
                className={Style.CLASS}
                head-title={formNameMap[lang]}
                image="menu-payments.svg"
                wait={isFetching}
                head-onBack={!isFromBackoffice && this.handleBackClick}>
                <div>
                    {!(isConfirmation || isTicket) && (
                        <Header
                            idForm={idForm}
                            isEditingPayment={isEditingPayment}
                            backUrl={backUrl(match)}
                            title="salaryPayment.manual.list.title"
                        />
                    )}

                    <TransactionLinesList
                        handleBackClick={this.handleBackClick}
                        isEditingPayment={isEditingPayment}
                        idForm={idForm}
                        isLastPage={isLastPage}
                        isReadOnly={isConfirmation || isTicket}
                        lines={transactionLines}
                        loadMoreText="massive.payments.line.more.results"
                        mode={mode}
                        noDataImage="images/coloredIcons/no-more-transactions.svg"
                        noDataText="transaction.process.none"
                        noMoreDataText="transaction.process.noMoreTransactions"
                        paymentCurrency={paymentCurrency}
                    />

                    {confirmationRequest && (
                        <ConfirmationModal
                            show={confirmationRequest}
                            handleAcept={this.handleAcceptImportPayroll}
                            handleCancel={this.handleCloseConfirmationModal}
                            idLabel="salaryPayment.manual.import.payroll.confirmation"
                        />
                    )}

                    {!isConfirmation && !isFetching && (
                        <Modal
                            backdrop="static"
                            show={isEditingPayment}
                            closeButton
                            onClose={this.handleCloseModal}
                            labelKey={modalLabelKey}>
                            {idForm === ID_FORM.SALARY_PAYMENT && (
                                <SalaryPaymentLinesForm
                                    initialValues={initialValues}
                                    submitButtonLabel={
                                        !isEditingLine
                                            ? "salaryPayment.manual.addPayment.addAndContinue"
                                            : "salaryPayment.manual.addPayment.modifyAndContinue"
                                    }
                                    onAdd={this.handleAdd}
                                    onEdit={this.handleEdit}
                                />
                            )}
                            {idForm === ID_FORM.SUPPLIERS_PAYMENT && (
                                <SuppliersPaymentLinesForm
                                    initialValues={initialValues}
                                    submitButtonLabel={
                                        !isEditingLine
                                            ? "salaryPayment.manual.addPayment.addAndContinue"
                                            : "suppliersPayment.manual.addPayment.modifyAndContinue"
                                    }
                                    onAdd={this.handleAdd}
                                    onEdit={this.handleEdit}
                                />
                            )}
                        </Modal>
                    )}
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (store, { lang }) => {
    return {
        confirmationRequest: SelectorsStoreTransactionLines.confirmationRequest(store),
        formMetadata: SelectorsStoreForm.getTransactionFormMetadata(store),
        isFetching: SelectorsStoreTransactionLines.getFetching(store),
        lang: lang || StoreI18n.getLang(store) || window.navigator.language.slice(0, 2),
        lineNumber: SelectorsStoreTransactionLines.getSelectedLine(store),
        localBanks: SelectorsStoreTransactionLines.getLocalBanks(store),
        mode: SelectorsStoreForm.getMode(store),
        transaction: SelectorsStoreForm.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
