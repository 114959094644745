import React from "react";

import { Form, Field, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { Modal, Grid, Row } from "react-bootstrap";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import { EMPTY_STR } from "~/constants";
import { ID_FORM } from "~/constants/form";
import { SelectorsAction as SelectorsActionTemplate, SelectorsStore as SelectorsStoreTemplate } from "~/store/template";
import * as I18nUtil from "~/util/i18n";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TextField from "~/pages/_components/fields/TextField";

const FORM_ID = "forms.template";

export const NAME = "CreateTemplateModal";

export const PROP = {
    types: {
        backdrop: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        dispatch: PropTypes.func.isRequired,
        handleReset: PropTypes.func,
        isDesktop: PropTypes.bool,
        isSubmitting: PropTypes.bool,
        isVisible: PropTypes.bool,
        loadedTemplateName: PropTypes.string,
        showConfirmationModal: PropTypes.bool,
    },
    defaults: {
        backdrop: true,
        handleReset: () => {},
        isDesktop: false,
        isSubmitting: false,
        isVisible: false,
        loadedTemplateName: EMPTY_STR,
        showConfirmationModal: false,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentWillUnmount() {
        this.resetModal();
    }

    handleHide = () => {
        this.resetModal();
    };

    resetModal = () => {
        const { dispatch, handleReset } = this.props;

        dispatch(SelectorsActionTemplate.closeCreateModal());
        handleReset();
    };

    toggleShowConfirmationModal = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionTemplate.toggleShowConfirmationModal());
    };

    showConfirmationModal = () => {
        const { backdrop, isSubmitting, submitForm } = this.props;

        return (
            <Modal show onHide={this.toggleShowConfirmationModal} backdrop={backdrop}>
                <Form className="modal-container">
                    <Modal.Header closeButton>
                        <section className="container--layout align-items-center">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Modal.Title>
                                        <I18n id={`${FORM_ID}.title`} />
                                    </Modal.Title>
                                </Row>
                            </Grid>
                        </section>
                    </Modal.Header>
                    <Modal.Body>
                        <section className="container--layout align-items-center">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Modal.Title>
                                        <FieldLabel labelKey={`${FORM_ID}s.overwrite`} />
                                    </Modal.Title>
                                </Row>
                            </Grid>
                        </section>
                    </Modal.Body>
                    <Modal.Footer>
                        <section className="container--layout align-items-center">
                            <Grid className="form-content">
                                <Button
                                    onClick={() => submitForm()}
                                    label="global.accept"
                                    bsStyle="primary"
                                    loading={isSubmitting}
                                />
                                <Button
                                    onClick={this.toggleShowConfirmationModal}
                                    label="global.cancel"
                                    loading={isSubmitting}
                                />
                            </Grid>
                        </section>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    };

    showModal = () => {
        const { backdrop, isDesktop, isSubmitting } = this.props;

        return (
            <Modal show onHide={this.handleHide} backdrop={backdrop}>
                <Form className="modal-container">
                    <Modal.Header closeButton>
                        <section className="container--layout align-items-center">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Modal.Title>
                                        <I18n id={`${FORM_ID}.title`} />
                                    </Modal.Title>
                                </Row>
                            </Grid>
                        </section>
                    </Modal.Header>
                    <Modal.Body>
                        <section className="container--layout align-items-center flex-grow">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Field
                                        idForm={FORM_ID}
                                        name="templateName"
                                        type="text"
                                        maxLength={100}
                                        pattern="^[a-zA-Z0-9 ]*$"
                                        component={TextField}
                                        autoFocus={isDesktop}
                                        hidePlaceholder
                                    />
                                </Row>
                            </Grid>
                        </section>
                    </Modal.Body>
                    <Modal.Footer>
                        <section className="container--layout align-items-center">
                            <Grid className="form-content">
                                <Button type="submit" label="global.accept" bsStyle="primary" loading={isSubmitting} />
                            </Grid>
                        </section>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    };

    render() {
        const { isVisible, showConfirmationModal } = this.props;

        return (
            <React.Fragment>
                {showConfirmationModal && this.showConfirmationModal()}
                {isVisible && this.showModal()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    isVisible: SelectorsStoreTemplate.isCreateModalVisible(store),
    list: SelectorsStoreTemplate.getTemplateList(store),
    loadedTemplateName: SelectorsStoreTemplate.getLoadedTemplateName(store),
    showConfirmationModal: SelectorsStoreTemplate.getShowConfirmationModal(store),
});

export default Compose(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        mapPropsToValues: ({ loadedTemplateName, values }) => ({
            templateName: loadedTemplateName || EMPTY_STR,
            values,
        }),
        validationSchema: () =>
            Yup.object().shape({
                templateName: Yup.string().required(I18nUtil.get(`${FORM_ID}.templateName.required`)),
            }),
        handleSubmit: (data, formikBag) => {
            const { templateName } = data;
            const { idForm, values } = formikBag.props;

            const param = { ...values };
            if (idForm === ID_FORM.SALARY_PAYMENT || idForm === ID_FORM.SUPPLIERS_PAYMENT) {
                param.idForm = idForm;
            }
            //  FIX: UYHSBCCDP-3475 Workaround to fix issue
            //  TODO: Figure out why this trigger a rerender who overwrites user input
            formikBag.props.dispatch(
                SelectorsActionTemplate.saveTemplate({
                    idForm,
                    transactionData: param,
                    templateName: templateName.trim(),
                    formikBag,
                    idActivityTemplate: formikBag.props.idActivityTemplate,
                }),
            );
        },
    }),
    HighOrder.Resizable,
)(Component);
