import React from "react";

import { connect as Connect } from "react-redux";

import { SCOPE, LEVEL, EMPTY_STR } from "~/constants";
import { SelectorsAction as SelectorsActionUploadPaymentOrders } from "~/store/factoring/uploadPaymentOrders";
import { SelectorsAction as SelectorsActionMultilineFile } from "~/store/multilinefile";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as UtilsConfig from "~/util/config";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import FileUploader from "~/pages/_components/FileUploader";

export const NAME = "FileUploadPaymentOrders";

export const PROP = {
    types: {
        ...TypesRedux,
    },
    defaults: {
        ...DefaultsRedux,
    },
};

const FORM_ID = "forms.factoring.paymentOrders.upload";
const ACTIVITY_ID = "client.factoring.paymentOrders.upload.execute";
const FIELD_ID = "files";
const ACCEPTED_FILE_TYPES = [UtilsConfig.get("form.multilinefile.acceptedFileTypes", "text/plain")];
const MAX_FILE_SIZE_MB = 2;

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        fileHasErrors: false,
        invalidLinesData: null,
        uploadDetailList: null,
    };

    handleFileProcess = (response) => {
        const { dispatch } = this.props;
        const { data } = response;
        const { invalidLinesData, uploadDetailList, errorKey } = data;
        const maxImportLines = UtilsConfig.getInteger("client.factoring.paymentOrders.maxImportLines", 620);

        if (invalidLinesData) {
            this.setState({
                fileHasErrors: true,
                invalidLinesData,
                uploadDetailList,
            });
        } else if (errorKey) {
            dispatch(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get(errorKey, EMPTY_STR, { MAX_LINES: maxImportLines }),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.FACTORING_UPLOAD_PAYMENT_ORDERS],
                }),
            );
            dispatch(SelectorsActionMultilineFile.cleanPond({ forcePondCleanUp: true }));
        } else {
            const { paymentOrdersList, totalizersList, idFile } = data;
            const totalizers = totalizersList.map(function(totalizer) {
                const quantity = paymentOrdersList.filter((order) => order.currency === totalizer.currency).length;
                return { ...totalizer, quantity };
            });
            dispatch(
                SelectorsActionUploadPaymentOrders.fileUploadPaymentOrdersSuccess({
                    paymentOrdersList,
                    totalizers,
                    idFile,
                }),
            );
        }
    };

    handleExportPDF = () => {
        const { dispatch } = this.props;
        const { invalidLinesData, uploadDetailList } = this.state;

        dispatch(
            SelectorsActionUploadPaymentOrders.uploadPaymentOrdersExportPDF({ invalidLinesData, uploadDetailList }),
        );
    };

    render() {
        const { value, setValue, dispatch } = this.props;
        const { fileHasErrors } = this.state;

        return (
            <div className={fileHasErrors ? "file-has-error" : EMPTY_STR}>
                <FileUploader
                    name={FIELD_ID}
                    idActivity={ACTIVITY_ID}
                    idForm={FORM_ID}
                    idFormField={FIELD_ID}
                    files={value}
                    allowMultiple={false}
                    maxFileSize={`${MAX_FILE_SIZE_MB}mb`}
                    maxTotalFileSize={`${MAX_FILE_SIZE_MB}mb`}
                    maxFiles={1}
                    acceptedFileTypes={ACCEPTED_FILE_TYPES}
                    onFileProcess={this.handleFileProcess}
                    labelIdle={UtilsI18n.get("client.factoring.uploadPaymentOrders.fileUploader.label")}
                    onRemoveFile={() => {
                        dispatch(SelectorsActionUploadPaymentOrders.fileUploadPaymentOrdersRemoved());
                        this.setState({
                            fileHasErrors: false,
                        });
                    }}
                    setValue={setValue}
                    ignoreLimbo
                />

                {fileHasErrors && (
                    <div className="file-error">
                        <a href="#" onClick={this.handleExportPDF}>
                            {UtilsI18n.get("client.factoring.uploadPaymentOrders.error.label")}{" "}
                        </a>
                    </div>
                )}
            </div>
        );
    }
}

export default Connect(null)(Component);
