import React from "react";

import { Form, withFormik as WithFormik, Field } from "formik";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { connect as Connect } from "react-redux";
import * as Yup from "yup";

import {
    CDP_CURRENCY,
    EMPTY_STR,
    ENVIRONMENT_TYPE,
    FORMAT_DATE_SCHEDULER_FIELD,
    ONLY_NUMBER,
    PIPE_SEPARATOR,
    ZERO_NUMBER,
} from "~/constants";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import {
    SelectorsAction as SelectorsActionInvestment,
    SelectorsStore as SelectorsStoreInvestment,
} from "~/store/investment";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as UtilsConfig from "~/util/config";
import * as UtilsDate from "~/util/date";
import * as UtilsI18n from "~/util/i18n";
import UtilsLodash from "~/util/lodash";
import * as UtilsNumber from "~/util/number";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import ConfirmationModal from "~/pages/_components/ConfirmationModal";
import DateField from "~/pages/_components/fields/DateField";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TextField from "~/pages/_components/fields/TextField";
import Selector from "~/pages/_components/fields/formik/Selector";

import Checkbox from "~/pages/forms/_components/_fields/_commons/Checkbox";

import LinkOptions from "../LinkOptions";
import TermDepositModal from "../TermDepositModal";
import Style from "./Step1Edit.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        fetching: PropTypes.bool,
    },
    defaults: {
        fetching: false,
    },
};

const FORM_ID = "investment.termDeposit.form";

const calcInterest = (amount, idTerm, rate, rateType, base) => {
    let result = 0;

    if (rateType === "TEA") {
        // eslint-disable-next-line no-restricted-properties
        result = amount * (Math.pow(1 + rate / 100, idTerm / base) - 1);
    } else if (rateType === "TLA") {
        result = (((amount * rate) / 100) * idTerm) / base;
    }

    return result;
};

const getDefaultAccount = (accountList, selectedCurrency) => {
    const defaultCurrencyAccountList = accountList
        .filter((account) => Number(account.extraInfo.split(PIPE_SEPARATOR)[4]) === Number(selectedCurrency))
        .map((a) => {
            return a.idProduct;
        });

    return defaultCurrencyAccountList[0] || EMPTY_STR;
};

const getDefaultTerm = (selectedCurrency, segment) => {
    const currencyDefaultTerm = UtilsConfig.getInteger(
        `client.investment.termDeposit${segment}.defaultTerm.${selectedCurrency.currency}`,
    );

    const days = selectedCurrency.terms.map((c) => c.days);

    return Number(days.includes(currencyDefaultTerm) ? currencyDefaultTerm : selectedCurrency?.terms[0].days);
};

const getExcludedDates = (unavailableDaysUSD, unavailableDaysUYU, selectedCurrency) => {
    const excludeDates = [];

    if (unavailableDaysUYU && selectedCurrency.currency === Number(CDP_CURRENCY.UYU)) {
        unavailableDaysUYU.forEach((date) => {
            excludeDates.push(UtilsDate.specificDate(date, FORMAT_DATE_SCHEDULER_FIELD));
        });
    } else if (unavailableDaysUSD && selectedCurrency.currency === Number(CDP_CURRENCY.USD)) {
        unavailableDaysUYU.forEach((date) => {
            excludeDates.push(UtilsDate.specificDate(date, FORMAT_DATE_SCHEDULER_FIELD));
        });
    }

    return excludeDates;
};

const getTerm = (term, customTerm) => {
    const moreOptionsLabel = UtilsI18n.get(`${FORM_ID}.term.option.moreOptions`);
    const isMoreOptions = term === moreOptionsLabel;
    return Number(isMoreOptions ? customTerm : term);
};

const currencyMap = {
    2222: "USD",
    0: "UYU",
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        days: [],
        // eslint-disable-next-line react/destructuring-assignment
        isCorporate: this.props.activeEnvironment.type === ENVIRONMENT_TYPE.CORPORATE,
        modalData: [],
        selectedCurrency: [],
        selectedTerm: -1,
        showChangingDateModal: false,
        showDetailsModal: false,
        showRatesToolTip: false,
        showUItip: false,
        termToChange: -1,
    };

    static getDerivedStateFromProps(props, state) {
        const {
            lang,
            termDeposit: { currencies, customRate, formData },
            values,
        } = props;
        const { cost, currency, customTerm, debitAccount, term } = values || {};

        if (state && state.days.length === 0 && currencies.length > 0) {
            let selectedCurrency = currencies[0];

            if (formData.currency) {
                selectedCurrency = currencies.find((c) => c.currency === formData.currency);
            }

            return {
                ...state,
                days: selectedCurrency.terms.map((c) => c.days),
                modalData: selectedCurrency.terms,
                selectedCurrency,
                selectedTerm: selectedCurrency.terms.length > 0 ? selectedCurrency.terms[0].days : -1,
            };
        }

        if (currencies.length > 0) {
            const termToUse = getTerm(term, customTerm);
            const selectedCurrencyInfo = currencies.find((c) => c.currency === currency);

            if (selectedCurrencyInfo) {
                const selectedTerm = selectedCurrencyInfo.terms?.find((term2) => term2.days === termToUse);

                const selectedRate =
                    selectedTerm?.rates.length > 1
                        ? selectedTerm?.rates.reduce((properRate, rate) =>
                              rate.amountFrom <= cost && cost <= rate.amountTo + 0.01 ? rate : properRate,
                          )
                        : selectedTerm?.rates[0];

                const rateToUse = selectedRate?.rate || customRate;

                const rateTypeLabel = UtilsI18n.get(`wm.termDeposit.rate.${selectedCurrencyInfo.rateType}`);
                let rateMessage;

                if (selectedRate?.rate) {
                    rateMessage = `${UtilsNumber.numberToLocaleFormat(selectedRate.rate, lang)}% ${rateTypeLabel}`;
                } else {
                    rateMessage = `${UtilsNumber.numberToLocaleFormat(customRate, lang)}% ${rateTypeLabel}`;
                }

                const interest = calcInterest(
                    cost,
                    termToUse,
                    rateToUse,
                    selectedCurrencyInfo.rateType,
                    selectedCurrencyInfo.base,
                );

                if (cost && debitAccount && termToUse && rateToUse) {
                    return {
                        ...state,
                        interest,
                        rateMessage,
                        showRatesToolTip: true,
                    };
                }

                return {
                    ...state,
                    showRatesToolTip: false,
                };
            }
        }

        return state;
    }

    componentDidMount() {
        const { dispatch, termDeposit } = this.props;

        if (termDeposit.currencies.length === 0) {
            dispatch(SelectorsActionInvestment.termDepositPreRequest());
        }
    }

    componentDidUpdate(prevProps) {
        const { fetching, values } = this.props;
        const { fetching: prevFetching } = prevProps;
        const { cost, currency, customTerm, debitAccount, term } = values || {};

        const moreOptionsLabel = UtilsI18n.get(`${FORM_ID}.term.option.moreOptions`);
        const isMoreOptions = term === moreOptionsLabel;

        if (isMoreOptions && !fetching && fetching !== prevFetching && cost && customTerm && debitAccount) {
            this.validateAmount(currency, cost, term);
        }
    }

    amountBlur = (e) => {
        const {
            errors: { cost: errorCost },
            lang,
            termDeposit: { currencies, marketRate },
            values: { currency, customTerm, debitAccount, term },
        } = this.props;

        const moreOptionsLabel = UtilsI18n.get(`${FORM_ID}.term.option.moreOptions`);
        const isMoreOptions = term === moreOptionsLabel;

        if (currency === 9999 && e.target.value) {
            this.setState({
                showUItip: true,
            });
        }

        const val = UtilsNumber.toNumber(e.target.value);

        this.validateAmount(currency, val, term);

        const selectedCurrencyInfo = currencies.find((c) => c.currency === currency);

        if (isMoreOptions && customTerm && val && debitAccount) {
            this.getCustomInterest(val, debitAccount, term);
        }

        if (selectedCurrencyInfo) {
            const selectedTerm = selectedCurrencyInfo.terms.find((term2) => term2.days === term);

            if (selectedTerm) {
                const selectedRate =
                    selectedTerm.rates.length > 1
                        ? selectedTerm.rates.reduce(
                              (properRate, rate) =>
                                  rate.amountFrom <= val && val <= rate.amountTo + 0.01 ? rate : properRate,
                              {},
                          )
                        : selectedTerm.rates[0];

                if (selectedRate) {
                    const rateTypeLabel = UtilsI18n.get(`wm.termDeposit.rate.${selectedCurrencyInfo.rateType}`);
                    const rateMessage = `${UtilsNumber.numberToLocaleFormat(
                        selectedRate.rate,
                        lang,
                    )}% ${rateTypeLabel}`;

                    let amount;

                    if (selectedCurrencyInfo.currency === CDP_CURRENCY.UI) {
                        const UIValue = marketRate.buyArbitrated;

                        amount = val / UIValue;
                    } else {
                        amount = val;
                    }

                    const interest = calcInterest(
                        amount,
                        getTerm(term, customTerm),
                        selectedRate.rate,
                        selectedCurrencyInfo.rateType,
                        selectedCurrencyInfo.base,
                    );

                    setTimeout(() => {
                        this.setState({
                            interest,
                            rateMessage,
                            showRatesToolTip: term && val && !errorCost,
                        });
                    }, 100);
                }
            }
        }
    };

    changeCustomTerm = (e) => {
        const {
            setFieldValue,
            termDeposit: { unavailableDaysUSD, unavailableDaysUYU },
            values,
        } = this.props;
        const { cost, debitAccount, term } = values;
        const { selectedCurrency } = this.state;
        const customTerm = Number(e.currentTarget.value);

        const datePickerDate = UtilsDate.addDay(new Date(), customTerm);

        setFieldValue("customTerm", customTerm);
        setFieldValue("endDate", UtilsDate.setTime(datePickerDate));

        if (
            getExcludedDates(unavailableDaysUSD, unavailableDaysUYU, selectedCurrency).includes(
                UtilsDate.specificDate(datePickerDate, FORMAT_DATE_SCHEDULER_FIELD),
            )
        ) {
            this.setState({
                showChangingDateModal: true,
                termToChange: customTerm,
            });
        }

        if (customTerm && cost && debitAccount) {
            setTimeout(() => this.getCustomInterest(cost, debitAccount, term), 100);
        }
    };

    changeDebitAccount = (e) => {
        const {
            values: { cost, customTerm, term },
        } = this.props;
        const moreOptionsLabel = UtilsI18n.get(`${FORM_ID}.term.option.moreOptions`);
        const isMoreOptions = term === moreOptionsLabel;

        const debitAccount = e.value;

        if (cost && debitAccount && isMoreOptions && customTerm) {
            this.getCustomInterest(cost, debitAccount, term);
        }
    };

    currencyChange = (e) => {
        const {
            activeEnvironment,
            dispatch,
            setFieldValue,
            termDeposit: { accountList, currencies },
            values: { cost, currency, expirationInstruction },
        } = this.props;

        const segment = activeEnvironment.type === ENVIRONMENT_TYPE.CORPORATE ? "CMB" : EMPTY_STR;

        const selectedCurrency = currencies.find((c) => c.currency === e.value);
        const defaultAccount = getDefaultAccount(accountList, e.value);
        const defaultTerm = getDefaultTerm(selectedCurrency, segment);

        if (currency !== selectedCurrency.currency) {
            dispatch(SelectorsActionInvestment.currencyHasChangedRequest());
        }

        this.setState({
            days: selectedCurrency.terms.map((c) => c.days),
            modalData: selectedCurrency.terms,
            selectedCurrency,
            showRatesToolTip: false,
            showUItip: e.value === 9999 && cost,
        });

        setFieldValue("customTerm", 0);
        setFieldValue("debitAccount", defaultAccount);
        setFieldValue("term", defaultTerm);

        if (expirationInstruction === 2) {
            setFieldValue("creditAccount", defaultAccount);
        }
    };

    datePickerChange = (e) => {
        const {
            setFieldValue,
            values: { cost, debitAccount, term },
        } = this.props;

        const customTerm = Number(UtilsDate.differenceInCalendarDays(e, new Date()));

        setFieldValue("customTerm", customTerm);
        setFieldValue("endDate", UtilsDate.setTime(e));

        if (customTerm && cost && debitAccount) {
            setTimeout(() => this.getCustomInterest(cost, debitAccount, term), 100);
        }
    };

    getCustomInterest = (cost, debitAccount, term) => {
        const { dispatch, tempFormData, values } = this.props;
        const { currency, customTerm } = values;
        const termToUse = getTerm(term, customTerm);

        const tempFormDataWithoutTerm = { ...tempFormData };
        const valuesWithoutTerm = { ...values };

        delete tempFormDataWithoutTerm.term;
        delete valuesWithoutTerm.term;

        if (!UtilsLodash.isEqual(tempFormDataWithoutTerm, valuesWithoutTerm)) {
            const valuesWithTempCustomTerm = { ...values };

            valuesWithTempCustomTerm.customTerm = tempFormData.customTerm;

            const params = {
                amount: { quantity: cost, currency: currency.toString() },
                debitAccount,
                term: termToUse,
                tempFormData: values.customTerm > 0 ? values : valuesWithTempCustomTerm,
            };

            dispatch(SelectorsActionInvestment.termDepositGetCustomRateRequest(params));
        }
    };

    getMaxDate = () => {
        const { activeEnvironment } = this.props;
        const { selectedCurrency } = this.state;

        const segment = activeEnvironment.type === ENVIRONMENT_TYPE.CORPORATE ? "CMB" : EMPTY_STR;

        const maxDaysToShow = UtilsConfig.getInteger(
            `client.investment.termDeposit${segment}.maximumTerm.${currencyMap[selectedCurrency.currency]}`,
        );
        const minDate = new Date();
        const maxDate = UtilsDate.addDay(minDate, maxDaysToShow);

        return UtilsDate.setTime(maxDate);
    };

    getRateTooltip = (thousandSeparator, decimalSeparator, decimalScale, showTooltip) => {
        const { values } = this.props;
        const { interest, isCorporate, rateMessage } = this.state;
        const showExchangeHelpCMB = UtilsConfig.getBoolean(`client.investment.termDepositCMB.showExchangeHelp`);
        let exchangeHelpMessageToShow;

        if (isCorporate) {
            exchangeHelpMessageToShow = showExchangeHelpCMB && UtilsI18n.get(`${FORM_ID}.exchangeHelp.bottom`);
        } else {
            exchangeHelpMessageToShow = UtilsI18n.get(`${FORM_ID}.exchangeHelp.bottom`);
        }

        if (showTooltip) {
            return (
                <Box>
                    <div />
                    <div className="exchangeTip rate-tooltip">
                        <Box flex>
                            <p className="exchangeTipTitle">{UtilsI18n.get(`${FORM_ID}.exchangeHelp.rate`)}</p>
                            <p>{rateMessage}</p>
                        </Box>
                        <Box flex>
                            <p className="exchangeTipTitle">{UtilsI18n.get(`${FORM_ID}.exchangeHelp.interest`)}</p>
                            <NumberFormat
                                value={interest}
                                maxLength="25"
                                fixedDecimalScale
                                thousandSeparator={thousandSeparator}
                                displayType="text"
                                prefix={`${UtilsI18n.get(`core.currency.label.${values.currency}`)} `}
                                suffix={exchangeHelpMessageToShow && "*"}
                                decimalScale={decimalScale}
                                decimalSeparator={decimalSeparator}
                                type="text"
                            />
                        </Box>
                        {exchangeHelpMessageToShow && <span>{exchangeHelpMessageToShow}</span>}
                    </div>
                </Box>
            );
        }

        return <Box />;
    };

    handleAcceptChangingDate = () => {
        const {
            setFieldError,
            setFieldValue,
            termDeposit: { unavailableDaysUSD, unavailableDaysUYU },
        } = this.props;
        const { selectedCurrency, termToChange } = this.state;
        const excludedDates = getExcludedDates(unavailableDaysUSD, unavailableDaysUYU, selectedCurrency);

        let datePickerDate = UtilsDate.addDay(new Date(), termToChange);

        for (let i = 0; i < excludedDates.length; i += 1) {
            datePickerDate = UtilsDate.addDay(datePickerDate, 1);

            if (excludedDates.includes(UtilsDate.specificDate(datePickerDate, FORMAT_DATE_SCHEDULER_FIELD))) {
                // eslint-disable-next-line no-continue
                continue;
            } else {
                // +1 because the cycling starts at 0
                setFieldValue("customTerm", Number(termToChange + i + 1));
                setFieldValue("endDate", UtilsDate.setTime(datePickerDate));
                setFieldError("endDate", null);

                this.setState({
                    showChangingDateModal: false,
                    termToChange: -1,
                });

                break;
            }
        }
    };

    handleCancelChangingDate = () => {
        this.setState({
            showChangingDateModal: false,
            termToChange: -1,
        });
    };

    handleAcceptChangingDateTerm = () => {
        const {
            dispatch,
            setFieldError,
            setFieldValue,
            termDeposit: { unavailableDaysUSD, unavailableDaysUYU },
            values,
        } = this.props;
        const { cost, debitAccount, term } = values;

        const { selectedCurrency, selectedTerm } = this.state;

        const excludedDates = getExcludedDates(unavailableDaysUSD, unavailableDaysUYU, selectedCurrency);

        let datePickerDate = UtilsDate.addDay(new Date(), term);

        const moreOptionsLabel = UtilsI18n.get(`${FORM_ID}.term.option.moreOptions`);

        for (let i = 0; i < excludedDates.length; i += 1) {
            datePickerDate = UtilsDate.addDay(datePickerDate, 1);

            if (!excludedDates.includes(UtilsDate.specificDate(datePickerDate, FORMAT_DATE_SCHEDULER_FIELD))) {
                setFieldValue("customTerm", Number(selectedTerm + i + 1));
                setFieldValue("endDate", UtilsDate.setTime(datePickerDate));

                setFieldError("endDate", null);

                break;
            }
        }

        setFieldValue("term", moreOptionsLabel);

        if (selectedTerm && cost && debitAccount) {
            setTimeout(() => this.getCustomInterest(cost, debitAccount, moreOptionsLabel), 100);

            dispatch(SelectorsActionInvestment.closeChangeDateModal());

            this.setState({
                selectedTerm: selectedCurrency.terms.length > 0 ? selectedCurrency.terms[0].days : -1,
            });
        }
    };

    handleCancelChangeDate = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionInvestment.closeChangeDateModal());
    };

    handleChangeCheckBox = (fieldName) => {
        const { setFieldValue, values } = this.props;

        setFieldValue(fieldName, !values[fieldName]);
    };

    termChange = (e) => {
        const {
            activeEnvironment,
            currencyHasChanged,
            dispatch,
            errors: { cost: errorCost },
            lang,
            setFieldValue,
            tempFormData,
            termDeposit: { currencies, marketRate },
            values: { cost, currency, customTerm, debitAccount },
        } = this.props;
        const { selectedCurrency } = this.state;

        const segment = activeEnvironment.type === ENVIRONMENT_TYPE.CORPORATE ? "CMB" : EMPTY_STR;

        const term = e.value;

        const moreOptionsLabel = UtilsI18n.get(`${FORM_ID}.term.option.moreOptions`);
        const isMoreOptions = term === moreOptionsLabel;

        if (isMoreOptions) {
            const defaultTerm = getDefaultTerm(selectedCurrency, segment);

            setFieldValue("customTerm", tempFormData?.customTerm || defaultTerm);
        }

        const selectedCurrencyInfo = currencies.find((c) => c.currency === currency);

        this.validateAmount(currency, cost, e.value);

        let termToUse = term;

        if (currencyHasChanged && tempFormData?.customTerm) {
            termToUse = tempFormData?.customTerm;

            dispatch(SelectorsActionInvestment.currencyHasChangedRequest());
        }

        if (isMoreOptions && (customTerm || currencyHasChanged) && cost && debitAccount) {
            this.getCustomInterest(cost, debitAccount, termToUse);
        }

        if (selectedCurrencyInfo) {
            const selectedTerm = selectedCurrencyInfo.terms.find((term2) => term2.days === term);

            if (selectedTerm) {
                this.setState({
                    selectedTerm: term,
                });

                const selectedRate =
                    selectedTerm.rates.length > 1
                        ? selectedTerm.rates.reduce(
                              (properRate, rate) =>
                                  rate.amountFrom <= cost && cost <= rate.amountTo + 0.01 ? rate : properRate,
                              {},
                          )
                        : selectedTerm.rates[0];

                if (selectedRate) {
                    const rateTypeLabel = UtilsI18n.get(`wm.termDeposit.rate.${selectedCurrencyInfo.rateType}`);
                    const rateMessage = `${UtilsNumber.numberToLocaleFormat(
                        selectedRate.rate,
                        lang,
                    )}% ${rateTypeLabel}`;

                    let amount;

                    if (selectedCurrencyInfo.currency === CDP_CURRENCY.UI) {
                        const UIValue = marketRate.buyArbitrated;

                        amount = cost / UIValue;
                    } else {
                        amount = cost;
                    }

                    const interest = calcInterest(
                        amount,
                        getTerm(termToUse, customTerm),
                        selectedRate.rate,
                        selectedCurrencyInfo.rateType,
                        selectedCurrencyInfo.base,
                    );

                    setTimeout(() => {
                        this.setState({
                            interest,
                            rateMessage,
                            showRatesToolTip: cost && !errorCost,
                        });
                    }, 100);
                }
            }
        }
    };

    validateAmount = (currency, amount, term) => {
        const {
            values: { customTerm },
        } = this.props;
        const moreOptionsLabel = UtilsI18n.get(`${FORM_ID}.term.option.moreOptions`);
        const isMoreOptions = term === moreOptionsLabel;

        if (amount && (term || (isMoreOptions && customTerm))) {
            const {
                formatNumber,
                lang,
                maximumDecimals,
                minimumDecimals,
                setFieldError,
                setFieldTouched,
                termDeposit: { currencies },
            } = this.props;

            const selectedCurrency = currencies.find((cur) => cur.currency === currency);

            if (selectedCurrency) {
                const { decimalSeparator, thousandSeparator } = UtilsNumber.numberFormat(lang);
                const decimalPlaces = this.amountRef
                    ? UtilsNumber.countDecimalPlaces(this.amountRef.value, decimalSeparator)
                    : 0;
                const decimalScale = Math.max(Math.min(decimalPlaces, maximumDecimals), minimumDecimals);

                const selectedPredefinedTerm = !isMoreOptions
                    ? selectedCurrency.terms.find((tm) => tm.days === term)
                    : selectedCurrency.terms.reduce((previousTerm, currentTerm) =>
                          previousTerm.days < currentTerm.days ? previousTerm : currentTerm,
                      );

                if (selectedPredefinedTerm) {
                    const minValue = selectedPredefinedTerm.rates.reduce(
                        (min, p) => (p.amountFrom < min ? p.amountFrom : min),
                        selectedPredefinedTerm.rates[0].amountFrom,
                    );

                    if (minValue > amount) {
                        const currencyToShow = currency === 9999 ? 0 : currency;
                        const minValueDisplay = formatNumber(
                            decimalSeparator,
                            thousandSeparator,
                            decimalScale,
                            minValue,
                        );

                        setFieldError(
                            "cost",
                            UtilsI18n.replaceParams(UtilsI18n.get(`${FORM_ID}.term.error.minValue`), {
                                currency: UtilsI18n.get(`core.currency.label.${currencyToShow}`),
                                minValueDisplay,
                            }),
                        );
                        setFieldTouched("cost");
                    } else {
                        setFieldError("cost", EMPTY_STR);
                    }
                }
            }
        }
    };

    render() {
        const {
            errors,
            lang,
            maximumDecimals,
            minimumDecimals,
            setFieldValue,
            showChangeDateModal,
            termDeposit: {
                accountList,
                currencies,
                instructions,
                marketRate,
                timezoneOffset,
                unavailableDaysUSD,
                unavailableDaysUYU,
            },
            touched,
            values,
        } = this.props;
        const {
            days,
            modalData,
            showChangingDateModal,
            selectedCurrency,
            showDetailsModal,
            showRatesToolTip,
            showUItip,
        } = this.state;

        const { decimalSeparator, thousandSeparator } = UtilsNumber.numberFormat(lang);
        const decimalPlaces = this.amountRef
            ? UtilsNumber.countDecimalPlaces(this.amountRef.value, decimalSeparator)
            : 0;
        const decimalScale = Math.max(Math.min(decimalPlaces, maximumDecimals), minimumDecimals);
        const listDays = [...days];
        const moreOptionsLabel = UtilsI18n.get(`${FORM_ID}.term.option.moreOptions`);
        const isMoreOptions = values.term === moreOptionsLabel;
        const { cost, customTerm, debitAccount } = values;

        listDays.push(moreOptionsLabel);

        let showCustomTerm = false;

        if (customTerm && isMoreOptions) {
            showCustomTerm = true;
        }

        const showTooltip =
            (!isMoreOptions || showCustomTerm) &&
            debitAccount &&
            cost &&
            Number(cost) > ZERO_NUMBER &&
            showRatesToolTip;

        return (
            <Form id={Style.ID} noValidate="novalidate" className="col col-12 col-lg-6 col-md-9 col-sm-12 termDeposit">
                <div className="form-section">
                    <div className="form-section-title">{UtilsI18n.get(`${FORM_ID}.termDeposit.title`)}</div>
                    <div className="form-content">
                        <div className="form-group--stepper-field">
                            <div className="inline">
                                <FieldLabel labelKey={`${FORM_ID}.currency.label`} />
                                <div className="Select flex-container slideFromBottom has-value Select--single">
                                    <Field
                                        component={Selector}
                                        idForm={FORM_ID}
                                        name="currency"
                                        className="flex-container slideFromBottom selector"
                                        placeholder="Moneda"
                                        optionClassName="needsclick"
                                        onChange={(e) => {
                                            this.currencyChange(e);
                                        }}
                                        options={currencies.map((c) => {
                                            return {
                                                label: UtilsI18n.get(`core.currency.label.${c.currency}`),
                                                value: c.currency,
                                            };
                                        })}
                                        renderAs="combo"
                                        searchable={false}
                                        creatable={false}
                                        clearable={false}
                                        hideLabel
                                    />
                                    <LinkOptions
                                        className="link"
                                        handleClick={() => {
                                            this.setState({ showDetailsModal: true });
                                        }}
                                        text={UtilsI18n.replaceParams(
                                            UtilsI18n.get(`${FORM_ID}.availableCurrencyConversion`),
                                            {
                                                currency: UtilsI18n.get(`core.currency.label.${values.currency || 0}`),
                                            },
                                        )}
                                    />
                                </div>
                            </div>
                            <FieldLabel
                                labelText={UtilsI18n.replaceParams(UtilsI18n.get(`${FORM_ID}.cost.label`), {
                                    currency: UtilsI18n.get(
                                        `core.currency.label.${
                                            values.currency === 9999 || !values.currency ? 0 : values.currency
                                        }`,
                                    ),
                                })}
                            />
                            <Field
                                idForm={FORM_ID}
                                hidelabel
                                name="cost"
                                type="text"
                                component={NumberFormat}
                                allowNegative={false}
                                className={
                                    touched.cost && errors.cost
                                        ? "form-control no-focus-border has-error"
                                        : "form-control no-focus-border"
                                }
                                decimalScale={decimalScale}
                                decimalSeparator={decimalSeparator}
                                onChange={({ target }) => {
                                    const num = UtilsNumber.toNumber(target.value, decimalSeparator);
                                    setFieldValue(`cost`, num);
                                }}
                                maxLength="16"
                                fixedDecimalScale
                                thousandSeparator={thousandSeparator}
                                onBlur={(e) => {
                                    this.amountBlur(e);
                                }}
                                style={{ width: "100%" }}
                                value={values.cost}
                            />
                            {touched.cost && errors.cost && (
                                <FieldError error={errors.cost} style={{ width: "100%" }} />
                            )}
                            {showUItip && (
                                <React.Fragment>
                                    <div />
                                    <div className="exchangeTip">
                                        <Box flex>
                                            <p className="exchangeTipTitle">
                                                {UtilsI18n.get(`${FORM_ID}.exchangeHelp.one`)}
                                            </p>
                                            <p>{marketRate.buyArbitratedFormatted}</p>
                                        </Box>
                                        <Box flex>
                                            <p className="exchangeTipTitle">
                                                {UtilsI18n.get(`${FORM_ID}.exchangeHelp.two`)}
                                            </p>
                                            <NumberFormat
                                                value={values.cost / marketRate.buyArbitrated}
                                                maxLength="15"
                                                fixedDecimalScale
                                                thousandSeparator={thousandSeparator}
                                                displayType="text"
                                                suffix=" UI"
                                                decimalScale={decimalScale}
                                                decimalSeparator={decimalSeparator}
                                                type="text"
                                            />
                                        </Box>
                                    </div>
                                </React.Fragment>
                            )}
                            <FieldLabel labelKey={`${FORM_ID}.term.label`} />
                            <div className="Select flex-container slideFromBottom has-value Select--single">
                                <Field
                                    component={Selector}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="term"
                                    className="flex-container slideFromBottom selector"
                                    optionClassName="needsclick"
                                    options={listDays.map((day, index) => {
                                        return {
                                            label:
                                                index < listDays.length - 1
                                                    ? UtilsI18n.replaceParams(UtilsI18n.get(`${FORM_ID}.term.option`), {
                                                          quantity: day,
                                                      })
                                                    : day,
                                            value: day,
                                        };
                                    })}
                                    settedValue={listDays[0]}
                                    onChange={this.termChange}
                                    renderAs="combo"
                                    searchable={false}
                                    creatable={false}
                                    clearable={false}
                                    hideLabel
                                />
                            </div>
                            {isMoreOptions && (
                                <React.Fragment>
                                    <span className="tooltip-date">{UtilsI18n.get(`${FORM_ID}.tooltip.date`)}</span>
                                    <div className="info-date">
                                        <div>
                                            <FieldLabel labelText={UtilsI18n.get(`${FORM_ID}.days.label.manually`)} />
                                            <Field
                                                className={
                                                    touched.customTerm && errors.customTerm
                                                        ? "form-control no-focus-border has-error"
                                                        : "form-control no-focus-border"
                                                }
                                                component={TextField}
                                                idForm={FORM_ID}
                                                hidelabel
                                                hidePlaceholder
                                                maxLength={4}
                                                name="customTerm"
                                                onBlur={this.changeCustomTerm}
                                                pattern={ONLY_NUMBER}
                                                value={values.customTerm}
                                                type="text"
                                            />
                                        </div>
                                        <div className="info-label datepicker">
                                            <Field
                                                className={touched.endDate && errors.endDate ? "has-error" : EMPTY_STR}
                                                component={DateField}
                                                hidePlaceholder
                                                name="endDate"
                                                id="endDate"
                                                minDate={UtilsDate.setTime(new Date())}
                                                maxDate={this.getMaxDate()}
                                                idForm={FORM_ID}
                                                excludeDates={getExcludedDates(
                                                    unavailableDaysUSD,
                                                    unavailableDaysUYU,
                                                    selectedCurrency,
                                                )}
                                                timezoneOffset={timezoneOffset}
                                                handleChange={(e) => {
                                                    this.datePickerChange(e);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                            {this.getRateTooltip(thousandSeparator, decimalSeparator, decimalScale, showTooltip)}
                        </div>
                    </div>
                </div>
                <div className="form-section">
                    <div className="form-section-title">{UtilsI18n.get(`${FORM_ID}.accounts.title`)}</div>
                    <div className="form-content">
                        <div className="form-group--stepper-field">
                            <FieldLabel labelKey={`${FORM_ID}.debitAccount.label`} />
                            <div className="Select flex-container slideFromBottom has-value Select--single">
                                <Field
                                    component={Selector}
                                    idForm={FORM_ID}
                                    hidePlaceholder
                                    name="debitAccount"
                                    className="flex-container slideFromBottom selector"
                                    optionClassName="needsclick"
                                    options={accountList
                                        .filter(
                                            (account) =>
                                                Number(account.extraInfo.split(PIPE_SEPARATOR)[4]) ===
                                                    Number(values.currency) ||
                                                (Number(values.currency) === Number(CDP_CURRENCY.UI) &&
                                                    Number(account.extraInfo.split(PIPE_SEPARATOR)[4]) ===
                                                        Number(CDP_CURRENCY.UYU)),
                                        )
                                        .map((a) => ({
                                            label: a.productAlias,
                                            value: a.idProduct,
                                        }))}
                                    renderAs="combo"
                                    onChange={this.changeDebitAccount}
                                    searchable={false}
                                    creatable={false}
                                    clearable={false}
                                    hideLabel
                                />
                            </div>
                            <FieldLabel labelKey={`${FORM_ID}.expirationInstruction.label`} />
                            <div className="Select flex-container slideFromBottom has-value Select--single">
                                <Field
                                    component={Selector}
                                    idForm={FORM_ID}
                                    hidePlaceholder
                                    name="expirationInstruction"
                                    className="flex-container slideFromBottom selector"
                                    optionClassName="needsclick"
                                    options={instructions.map((instruction) => {
                                        return {
                                            label: UtilsI18n.get(
                                                `investment.termDeposit.form.expirationInstruction.value.${instruction}`,
                                            ),
                                            value: instruction,
                                        };
                                    })}
                                    renderAs="combo"
                                    searchable={false}
                                    creatable={false}
                                    clearable={false}
                                    hideLabel
                                />
                            </div>
                            {values.expirationInstruction === 2 && (
                                <React.Fragment>
                                    <FieldLabel labelKey={`${FORM_ID}.creditAccount.label`} />
                                    <div className="Select flex-container slideFromBottom has-value Select--single">
                                        <Field
                                            component={Selector}
                                            idForm={FORM_ID}
                                            name="creditAccount"
                                            className="flex-container slideFromBottom selector"
                                            optionClassName="needsclick"
                                            options={accountList
                                                .filter(
                                                    (account) =>
                                                        Number(account.extraInfo.split(PIPE_SEPARATOR)[4]) ===
                                                            Number(values.currency) ||
                                                        (Number(values.currency) === Number(CDP_CURRENCY.UI) &&
                                                            Number(account.extraInfo.split(PIPE_SEPARATOR)[4]) ===
                                                                Number(CDP_CURRENCY.UYU)),
                                                )
                                                .map((a) => ({
                                                    label: a.productAlias,
                                                    value: a.idProduct,
                                                }))}
                                            renderAs="combo"
                                            searchable={false}
                                            creatable={false}
                                            clearable={false}
                                            hideLabel
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                            <div className="conditions">
                                <div className="checkbox-container">
                                    <Field
                                        idForm={FORM_ID}
                                        component={Checkbox}
                                        checked={values.conditions}
                                        name="conditions"
                                        onChange={() => this.handleChangeCheckBox("conditions")}
                                    />
                                    <span>{UtilsI18n.get(`${FORM_ID}.finish.conditions`)}</span>
                                </div>
                                <div className="scrollable">
                                    <p className="paragraph">
                                        {UtilsI18n.get("investment.termDeposit.form.finish.conditions.text.one")}
                                    </p>
                                    <p className="paragraph">
                                        {UtilsI18n.get("investment.termDeposit.form.finish.conditions.text.two")}
                                    </p>
                                    <p className="paragraph">
                                        {UtilsI18n.get("investment.termDeposit.form.finish.conditions.text.three")}
                                    </p>
                                    <p className="bold paragraph">
                                        {UtilsI18n.get("investment.termDeposit.form.finish.conditions.text.four")}
                                    </p>
                                    <p className="bold paragraph">
                                        {UtilsI18n.get("investment.termDeposit.form.finish.conditions.text.five")}
                                    </p>
                                    <p className="bold">
                                        {UtilsI18n.get("investment.termDeposit.form.finish.conditions.text.six")}
                                    </p>
                                    <p className="bold">
                                        {UtilsI18n.get("investment.termDeposit.form.finish.conditions.text.serven")}
                                    </p>
                                    <p className="bold">
                                        {UtilsI18n.get("investment.termDeposit.form.finish.conditions.text.eight")}
                                    </p>
                                    <p className="bold">
                                        {UtilsI18n.get("investment.termDeposit.form.finish.conditions.text.nine")}
                                    </p>
                                    <p className="bold">
                                        {UtilsI18n.get("investment.termDeposit.form.finish.conditions.text.ten")}
                                    </p>
                                    <p className="bold">
                                        {UtilsI18n.get("investment.termDeposit.form.finish.conditions.text.eleven")}
                                    </p>
                                    <p className="bold">
                                        {UtilsI18n.get("investment.termDeposit.form.finish.conditions.text.twelve")}
                                    </p>
                                    <p className="bold paragraph">
                                        {UtilsI18n.get("investment.termDeposit.form.finish.conditions.text.thirteen")}
                                    </p>
                                </div>
                                {touched.conditions && errors.conditions && !values.conditions && (
                                    <FieldError error={errors.conditions} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-button-center">
                    <Button
                        key="primaryButton"
                        variant="primary"
                        bsStyle="primary"
                        label={`${FORM_ID}.button.continue`}
                        loading={false}
                        type="submit"
                    />
                </div>
                <TermDepositModal
                    currency={values.currency}
                    data={modalData}
                    decimalSeparator={decimalSeparator}
                    handleCloseModal={() => {
                        this.setState({ showDetailsModal: false });
                    }}
                    maximumDecimals={maximumDecimals}
                    minimumDecimals={minimumDecimals}
                    show={showDetailsModal}
                    thousandSeparator={thousandSeparator}
                />
                <ConfirmationModal
                    handleAcept={this.handleAcceptChangingDate}
                    handleCancel={this.handleCancelChangingDate}
                    idLabel={`${FORM_ID}.nonWorkinDate.modal.title`}
                    show={showChangingDateModal}
                />
                <ConfirmationModal
                    handleAcept={this.handleAcceptChangingDateTerm}
                    handleCancel={this.handleCancelChangeDate}
                    idLabel={`${FORM_ID}.nonWorkinDate.modal.title`}
                    show={showChangeDateModal}
                />
            </Form>
        );
    }
}

const mapStateToProps = (store) => ({
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
    currencyHasChanged: SelectorsStoreInvestment.isCurrencyHasChanged(store),
    fetching: SelectorsStoreInvestment.getFetching(store),
    lang: SelectorsStoreI18n.getLang(store),
    maximumDecimals: UtilsConfig.getInteger("defaultDecimal.maximum"),
    minimumDecimals: UtilsConfig.getInteger("defaultDecimal.minimum"),
    showChangeDateModal: SelectorsStoreInvestment.getShowChangeDateModal(store),
    tempFormData: SelectorsStoreInvestment.getTempFormData(store),
    termDeposit: SelectorsStoreInvestment.getTermDeposit(store),
});

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => {
            const { activeEnvironment, tempFormData, termDeposit } = props;
            const { accountList, currencies, formData, instructions } = termDeposit;
            const firstCurrency = currencies[0];

            const segment = activeEnvironment.type === ENVIRONMENT_TYPE.CORPORATE ? "CMB" : EMPTY_STR;

            let defaultCurrency = EMPTY_STR;
            let defaultDate = new Date();
            let defaultTerm = EMPTY_STR;

            if (formData.currency !== undefined) {
                defaultCurrency = formData.currency;
            } else if (termDeposit.currencies.length > 0) {
                defaultCurrency = firstCurrency.currency;
            }

            // eslint-disable-next-line prefer-destructuring
            const defaultInstruction = instructions[0];

            const defaultDebitAccount = getDefaultAccount(accountList, defaultCurrency);

            if (firstCurrency) {
                defaultTerm = getDefaultTerm(firstCurrency, segment);
            }

            defaultDate = UtilsDate.addDay(defaultDate, Number(defaultTerm));

            return {
                conditions: formData?.conditions || tempFormData?.conditions || false,
                cost: formData?.cost || tempFormData?.cost || EMPTY_STR,
                creditAccount: formData?.creditAccount || tempFormData?.creditAccount || defaultDebitAccount,
                currency: tempFormData?.currency || defaultCurrency,
                customRate: termDeposit?.customRate || 0,
                customTerm: formData?.customTerm || tempFormData?.customTerm || 0,
                debitAccount: formData?.debitAccount || tempFormData?.debitAccount || defaultDebitAccount,
                endDate: UtilsDate.setTime(formData.endDate || tempFormData?.endDate || defaultDate),
                expirationInstruction:
                    formData?.expirationInstruction || tempFormData?.expirationInstruction || defaultInstruction,
                interest: formData?.interest || tempFormData?.interest || 0,
                rate: formData?.rate || tempFormData?.rate || 0,
                rateType: formData?.rateType || tempFormData?.rateType || EMPTY_STR,
                term: formData?.term || tempFormData?.term || defaultTerm,
            };
        },
        validationSchema: (props) => {
            return Yup.lazy((values) => {
                const { cost: amount, currency, endDate, term } = values;
                const {
                    termDeposit: { currencies, unavailableDaysUSD, unavailableDaysUYU },
                } = props;
                const moreOptionsLabel = UtilsI18n.get(`${FORM_ID}.term.option.moreOptions`);
                const isMoreOptions = term === moreOptionsLabel;

                const selectedCurrency = currencies.find((cur) => cur.currency === currency);

                return Yup.object().shape({
                    conditions: Yup.boolean().oneOf([true], UtilsI18n.get(`${FORM_ID}.conditions.required`)),
                    cost: Yup.number()
                        .test("minimumCost", UtilsI18n.get("wm.termdeposit.open.amountOutOfRange"), () => {
                            if (!amount || !term) {
                                return false;
                            }

                            if (selectedCurrency) {
                                const selectedPredefinedTerm = !isMoreOptions
                                    ? selectedCurrency.terms.find((tm) => tm.days === term)
                                    : selectedCurrency.terms.reduce((previousTerm, currentTerm) =>
                                          previousTerm.days < currentTerm.days ? previousTerm : currentTerm,
                                      );

                                if (selectedPredefinedTerm) {
                                    const minValue = selectedPredefinedTerm.rates.reduce(
                                        (min, p) => (p.amountFrom < min ? p.amountFrom : min),
                                        selectedPredefinedTerm.rates[0].amountFrom,
                                    );

                                    if (amount >= minValue) {
                                        return true;
                                    }

                                    return false;
                                }
                            }

                            return false;
                        })
                        .required(UtilsI18n.get(`${FORM_ID}.cost.required`)),
                    creditAccount:
                        values.expirationInstruction === 2
                            ? Yup.string().required(UtilsI18n.get(`${FORM_ID}.creditAccount.required`))
                            : Yup.string(),
                    currency: Yup.string().required(UtilsI18n.get(`${FORM_ID}.currency.required`)),
                    customTerm: isMoreOptions
                        ? Yup.number()
                              .min(1, UtilsI18n.get("wm.termdeposit.cmb.minimumTerm.notMet"))
                              .required(UtilsI18n.get(`${FORM_ID}.customTerm.required`))
                        : Yup.number(),
                    debitAccount: Yup.string().required(UtilsI18n.get(`${FORM_ID}.debitAccount.required`)),
                    endDate: isMoreOptions
                        ? Yup.date()
                              .required(UtilsI18n.get(`${FORM_ID}.endDate.required`))
                              .test("nonWorkingDateTest", UtilsI18n.get(`${FORM_ID}.endDate.nonWorkingDate`), () => {
                                  if (
                                      getExcludedDates(
                                          unavailableDaysUSD,
                                          unavailableDaysUYU,
                                          selectedCurrency,
                                      ).includes(UtilsDate.specificDate(endDate, FORMAT_DATE_SCHEDULER_FIELD))
                                  ) {
                                      return false;
                                  }

                                  return true;
                              })
                        : Yup.date(),
                    expirationInstruction: Yup.string().required(
                        UtilsI18n.get(`${FORM_ID}.expirationInstruction.required`),
                    ),
                    term: Yup.string().required(UtilsI18n.get(`${FORM_ID}.term.required`)),
                });
            });
        },
        handleSubmit: (data, formikBag) => {
            const {
                dispatch,
                termDeposit: { currencies, customRate, unavailableDaysUSD, unavailableDaysUYU },
            } = formikBag.props;
            const moreOptionsLabel = UtilsI18n.get(`${FORM_ID}.term.option.moreOptions`);
            const isMoreOptions = data.term === moreOptionsLabel;

            let formData = {
                amount: { quantity: data.cost, currency: data.currency.toString() },
                instruction: data.expirationInstruction,
                _otp: data.otp,
                ...data,
            };

            let rateToUse;

            const selectedCurrencyInfo = currencies.find((c) => c.currency === data.currency);

            if (!isMoreOptions) {
                if (
                    getExcludedDates(unavailableDaysUSD, unavailableDaysUYU, selectedCurrencyInfo).includes(
                        UtilsDate.specificDate(UtilsDate.addDay(new Date(), data.term), FORMAT_DATE_SCHEDULER_FIELD),
                    )
                ) {
                    dispatch(SelectorsActionInvestment.openChangeDateModal());

                    return;
                }

                const selectedTerm = selectedCurrencyInfo.terms.find((term) => term.days === data.term);
                const selectedRate =
                    selectedTerm.rates.length > 1
                        ? selectedTerm.rates.reduce(
                              (properRate, rate) =>
                                  rate.amountFrom <= data.cost && data.cost <= rate.amountTo + 0.01 ? rate : properRate,
                              {},
                          )
                        : selectedTerm.rates[0];

                rateToUse = selectedRate.rate;
            } else {
                rateToUse = customRate;
            }

            const interest = calcInterest(
                data.cost,
                isMoreOptions ? data.customTerm : data.term,
                rateToUse,
                selectedCurrencyInfo.rateType,
                selectedCurrencyInfo.base,
            );

            formData = {
                ...formData,
                rate: rateToUse,
                rateType: selectedCurrencyInfo.rateType,
                interest,
            };

            dispatch(SelectorsActionInvestment.termDepositPreviewRequest({ formData, formikBag }));
        },
    }),
)(Component);
