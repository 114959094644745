import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { CDP_CURRENCY, PIPE_SEPARATOR, ZERO_NUMBER } from "~/constants";
import Container from "~/containers/Internal/Form/Cards";
import { SelectorsAction as SelectorsActionLoan, SelectorsStore as SelectorsStoreLoan } from "~/store/loan";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import UtilsFlag from "~/util/flag";

import I18n from "~/components/I18n";
import Image from "~/components/Image";
import FormattedAmount from "~/pages/_components/FormattedAmount";

import Loan from "~/pages/loans/_components/Loan";

import Style from "./List.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        isFetching: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        isRequestAvailable: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        loans: PropTypes.oneOfType([PropTypes.array]),
    },
    defaults: {
        isFetching: false,
        isRequestAvailable: false,
        loans: [],
    },
};

export function Component(props) {
    const { dispatch, isFetching, loans } = props;

    const isEmpty = !isFetching && !loans.length;
    const productAsCard = loans.length < 5;
    const expensesUSD = [];
    const expensesUYU = [];

    React.useEffect(() => {
        dispatch(SelectorsActionLoan.listLoans());
    }, [dispatch]);

    const renderLoans = () => {
        const items = Object.entries(loans).map(([id, loan]) => (
            <Loan loan={loan} key={`${id} - ${loan.number}`} productAsCard={productAsCard} />
        ));

        if (items.length > 0) {
            return items;
        }

        return (
            <div className="noLoans">
                <I18n id="loans.noLoansToShow" />
            </div>
        );
    };

    const currencyUSD = loans.find((i) => i.currency === CDP_CURRENCY.USD) ? CDP_CURRENCY.USD : false;
    const currencyUYU = loans.find((i) => i.currency === CDP_CURRENCY.UYU) ? CDP_CURRENCY.UYU : false;

    const sumTotalToPayUSD = loans
        .map((loan) => {
            const { currency, totalDollarPackage, totalExpiredDollarPackages, totalToPay } = loan;

            if (currency === CDP_CURRENCY.USD) {
                const administrativeExpense = totalDollarPackage + totalExpiredDollarPackages;

                expensesUSD.push(administrativeExpense);

                return totalToPay;
            }

            return ZERO_NUMBER;
        })
        .reduce((prev, curr) => prev + curr, 0);

    const sumTotalToPayUYU = loans
        .map((loan) => {
            const { currency, totalExpiredPesosPackages, totalPesosPackage, totalToPay } = loan;

            if (currency === CDP_CURRENCY.UYU) {
                const administrativeExpense = totalPesosPackage + totalExpiredPesosPackages;

                expensesUYU.push(administrativeExpense);

                return totalToPay;
            }

            return ZERO_NUMBER;
        })
        .reduce((prev, curr) => prev + curr, 0);

    const [administrativeExpenseUYU] = expensesUYU;
    const [administrativeExpenseUSD] = expensesUSD;
    const totalLoansToPayUYU = sumTotalToPayUYU + administrativeExpenseUYU;
    const totalLoansToPayUSD = sumTotalToPayUSD + (administrativeExpenseUYU ? ZERO_NUMBER : administrativeExpenseUSD);

    return (
        <Container name={NAME} head-title="menu.loans.myLoans" wait={isFetching} className={Style.CLASS}>
            <div className="container-head">
                <div className="head-title">
                    <I18n id="menu.loans.myLoans" />
                </div>
                <div className="body">
                    <span className="title-body">
                        <I18n id="loans.card.title" />
                    </span>
                    <div className="amount-body">
                        {currencyUSD && (
                            <span>
                                <Image src={`${UtilsFlag.getFlag(CDP_CURRENCY.USD)}.svg`} />
                                <FormattedAmount quantity={totalLoansToPayUSD} currency={currencyUSD} />
                            </span>
                        )}
                        {currencyUSD && currencyUYU && <div className="separator">{PIPE_SEPARATOR}</div>}
                        {currencyUYU && (
                            <span>
                                <Image src={`${UtilsFlag.getFlag(CDP_CURRENCY.UYU)}.svg`} />
                                <FormattedAmount quantity={totalLoansToPayUYU} currency={currencyUYU} />
                            </span>
                        )}
                    </div>
                    <I18n className="label-footer" id="loans.card.description.list" />
                </div>
            </div>

            {isFetching && <I18n id="global.loading" />}
            {isEmpty && <I18n id="loans.list.empty" />}
            {!isEmpty && renderLoans()}
        </Container>
    );
}

const mapStateToProps = (store) => ({
    loans: SelectorsStoreLoan.getLoans(store),
    isFetching: SelectorsStoreLoan.getFetching(store),
    isRequestAvailable: SelectorsStoreSession.getActiveEnvironmentForms(store, "loans").find(
        ({ idForm }) => idForm === "requestLoan",
    ),
});

export default Connect(mapStateToProps)(Component);
