import React from "react";

import PropTypes from "prop-types";

import * as I18n from "~/util/i18n";

import Image from "~/components/Image";

export const NAME = "ShareItem";

export const PROP = {};
PROP.members = {
    /** propTypes for ContentTitle sub-component */
    typesContent: {
        /** Either an I18n identifier, or an instance of a Component to be rendered as title */
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    },
};

PROP.types = {
    /**  An event handler for Item clicks */
    onClick: PropTypes.func,
    /**  Boolean prop to change style whether the item is selected or not */
    hasPermission: PropTypes.bool,
    /**  In case you want to show a different style */
    className: PropTypes.string,
    /** Type of transaction */
    type: PropTypes.string.isRequired,
    ...PROP.members.typesContent,
};
PROP.defaults = {
    onClick: null,

    hasPermission: false,
    className: "transfer-options-item",
    ...PROP.members.defaultsContent,
};

export function Component(props) {
    const { onClick, hasPermission, href, className, dispatch, ...propsContent } = props;
    const handleClick = (...args) => {
        onClick.apply(this, args);
    };

    return (
        <li className={className}>
            <a onClick={handleClick}>
                <Component.Content {...propsContent} />
            </a>
        </li>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

Component.Content = function Content(props) {
    const { title, type } = props;

    const isValidType = type === "local" || type === "foreign";

    return (
        <div className="item-content">
            <div className="item-image">{isValidType ? <Image src={`transfers-dashboard-${type}.svg`} /> : null}</div>
            {React.isValidElement(title) ? title : <span className="item-text">{I18n.get(title)}</span>}
        </div>
    );
};
Component.Content.propTypes = PROP.members.typesContent;
Component.Content.defaultProps = PROP.members.defaultsContent;

export default Component;
