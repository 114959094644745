import React from "react";

import { connect as Connect } from "react-redux";

import { SelectorsAction as SelectorsActionAccount, SelectorsStore as SelectorsStoreAccount } from "~/store/account";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import Element from "~/components/Component";
import DataDate from "~/components/DataDate";
import GridLayout from "~/components/GridLayout";

import Style from "./_Vouchers.rules.scss";
import VouchersFilters from "./_VouchersFilters";
import { NAME_NOTE, TYPE_NOTE } from "./_index.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...TypesRedux,
    },
    defaults: {
        ...DefaultsRedux,
    },
};
export function Component(props) {
    const { dispatch, list, pageNumber, fetching, i18n, next, firstTime } = props;

    const {
        loading,
        labelMore,
        labelNoMore,
        labelDate,
        labelDocType,
        labelSerie,
        labelNumber,
        labelDocument,
        labelDownload,
        labelNoRecords,
    } = i18n;

    const COLUMN_HEADINGS = [labelDate, labelDocType, labelSerie, labelNumber, labelDocument];

    React.useEffect(() => {
        if (firstTime) {
            dispatch(SelectorsActionAccount.listBillingTickets());
        }
    }, [dispatch, firstTime]);

    return (
        <div id={Style.ID}>
            <VouchersFilters />
            <GridLayout className="header">
                {COLUMN_HEADINGS.map((heading) => (
                    <Box> {heading}</Box>
                ))}
            </GridLayout>
            {list.length > 0 ? renderListItems() : renderNoRecordLabel()}
            {fetching && <Note value={loading} />}
            {!fetching && next && (
                <Note intersection-trigger="true" key="more" value={labelMore} onClick={() => handleTicketsFetch()} />
            )}
            {!fetching && !next && list.length > 0 && <Note value={labelNoMore} className="no-more-movements" />}
        </div>
    );

    function renderNoRecordLabel() {
        if (!fetching) {
            return <Note value={labelNoRecords} className="no-more-movements" />;
        }
        return null;
    }

    function renderListItems() {
        return list.map(({ date, number, serie, docType, docTypeNumber }) => {
            return (
                <GridLayout>
                    <div>
                        <DataDate value={date} />
                    </div>
                    <div>{docType}</div>
                    <div>{serie}</div>
                    <div>{number}</div>
                    <Button
                        size="sm"
                        type="button"
                        variant="link"
                        onClick={() => handleDownloadTicket({ number, serie, docTypeNumber })}>
                        {labelDownload}
                    </Button>
                </GridLayout>
            );
        });
    }

    function handleDownloadTicket(params) {
        dispatch(SelectorsActionAccount.downloadBillingTicket(params));
    }

    function Note({ value, ...rest }) {
        return (
            <Element type={TYPE_NOTE} name={NAME_NOTE} tag="span" {...rest}>
                {value}
            </Element>
        );
    }

    function handleTicketsFetch() {
        dispatch(SelectorsActionAccount.listBillingTickets({ page: pageNumber + 1 }));
    }
}

Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

const mapStateToProps = (store) => ({
    i18n: {
        loading: StoreI18n.getMessage(store, "global.loading"),
        labelMore: StoreI18n.getMessage(store, "accounts.movements.moreMovements"),
        labelNoMore: StoreI18n.getMessage(store, "accounts.movements.noMoreMovements"),
        labelNoRecords: StoreI18n.getMessage(store, "client.account.billingTickets.noRecords.label"),
        labelDate: StoreI18n.getMessage(store, "client.account.billingTickets.date.label"),
        labelDocType: StoreI18n.getMessage(store, "client.account.billingTickets.docType.label"),
        labelSerie: StoreI18n.getMessage(store, "client.account.billingTickets.serie.label"),
        labelNumber: StoreI18n.getMessage(store, "client.account.billingTickets.number.label"),
        labelDocument: StoreI18n.getMessage(store, "client.account.billingTickets.document.label"),
        labelDownload: StoreI18n.getMessage(store, "client.account.billingTickets.download.label"),
    },
    list: SelectorsStoreAccount.getEBillingTicketsList(store),
    pageNumber: SelectorsStoreAccount.getPageNumber(store),
    fetching: SelectorsStoreAccount.getFetchingTickets(store),
    next: SelectorsStoreAccount.getNext(store),
    firstTime: SelectorsStoreAccount.getFirstTime(store),
});

export default Connect(mapStateToProps)(Component);
