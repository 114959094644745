import { TYPE as TYPE_PRODUCTS } from "~/store/products";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    const { statements, moreStatements, selectedStatement, loansList, alias, ...rest } = action;

    switch (action.type) {
        case TYPE.LIST_LOANS_REQUEST:
        case TYPE.LOAN_READ_REQUEST:
        case TYPE.LOAN_FETCH_MORE_STATEMENTS_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.LIST_LOANS_SUCCESS:
            return {
                ...storeProp,
                loansList,
                fetching: false,
            };
        case TYPE.LOAN_READ_SUCCESS:
            return {
                ...storeProp,
                selectedLoan: action.loan,
                numberOfFees: action.numberOfFees,
                fetching: false,
            };

        case TYPE.LOAN_FETCH_MORE_STATEMENTS_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                statements: storeProp.statements.concat(statements),
                moreStatements,
                ...rest,
            };
        case TYPE.TOGGLE_OPTIONS: {
            return {
                ...storeProp,
                isOptionsVisible: !storeProp.isOptionsVisible,
            };
        }
        case TYPE.CLOSE_OPTIONS:
            return {
                ...storeProp,
                isOptionsVisible: false,
            };
        case TYPE.SET_SELECTED_FILTER:
            return {
                ...storeProp,
                selectedFilter: action.selectedFilter,
            };
        case TYPE.SET_SELECTED_STATEMENT:
            return {
                ...storeProp,
                selectedStatement,
            };
        case TYPE_PRODUCTS.CHANGE_PRODUCT_ALIAS_SUCCESS: {
            return {
                ...storeProp,
                selectedLoan: {
                    ...storeProp.selectedLoan,
                    productAlias: alias,
                },
            };
        }
        default:
            return storeProp;
    }
};
