import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, LEVEL, ONLY_NUMBER, SCOPE, SPACE_STR } from "~/constants";
import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import {
    SelectorsStore as SelectorsStoreComexImport,
    SelectorsAction as SelectorsActionComexImport,
    PROP as PropComex,
} from "~/store/comex/import";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsAction as SelectorActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionSettings, SelectorsStore } from "~/store/settings";
import { SelectorsAction as SelectorsActionTemplate } from "~/store/template";
import { getInteger } from "~/util/config";
import * as ConfigUtil from "~/util/config";
import * as UtilsI18n from "~/util/i18n";
import { numberFormat as NumberFormat, formatNumber } from "~/util/number";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import Select from "~/components/Select";
import EmailList from "~/pages/_components/fields/EmailList";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TextArea from "~/pages/_components/fields/TextArea";
import TextField from "~/pages/_components/fields/TextField";
import AmountField from "~/pages/_components/fields/formik/AmountField";

import OperationNumber from "~/pages/comEx/_components/OperationNumber";
import OperationNumberDetail from "~/pages/comEx/_components/OperationNumberDetail";
import CreateTemplateModal from "~/pages/forms/Step1Edit/_ModalTemplateCreate";
import ListTemplatesModal from "~/pages/forms/Step1Edit/_ModalTemplateList";
import { Termsandconditions } from "~/pages/forms/_components/_fields";
import Scheduler from "~/pages/forms/_components/_fields/Scheduler";
import DocumentField from "~/pages/settings/changePersonalInformation/field/DocumentField";

import Style from "../../../Step1Edit.rules.scss";

const FORM_ID = "comex.import.cancellationCollection";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...PropComex.types,
    },
    defaults: {
        ...PropComex.defaults,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        draftLoaded: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(SelectorsActionComexImport.billingCancellationPreRequest());
        dispatch(SelectorsActionSettings.changeAddressesRequest({}));
    }

    componentDidUpdate() {
        const {
            dispatch,
            setFieldError,
            setFieldTouched,
            setValues,
            transaction,
            setFieldValue,
            values,
            preData,
            match,
            formData = {},
        } = this.props;

        const { operationNumber } = match.params;
        const { availableOperationList } = preData;
        const { draftLoaded, loadOperationNumber } = this.state;

        if (!loadOperationNumber && availableOperationList.length > 0 && !values.operationNumber && operationNumber) {
            const operationSelected = availableOperationList.filter(
                (item) => item.othersFields.NROOPERACION === operationNumber,
            );
            this.setState({
                loadOperationNumber: true,
            });

            if (operationSelected.length > 0) {
                setFieldValue("item", operationSelected[0]);
                setFieldValue("operationNumber", operationNumber);

                setFieldValue("drawer", operationSelected[0].othersFields.GIRADOR);
                setFieldValue("amountField", {
                    amount: operationSelected[0].balance * -1,
                    currency: operationSelected[0].accountCurrency,
                });
                setFieldValue("oldAmount", operationSelected[0].balance);
                setFieldValue("canBePartialCancellation", false);
                setFieldValue(`formattedAmount`, operationSelected[0].balance * -1);
            }
        }

        // si viene trasaction.data, no estoy volviendo del paso 2 y no se cargo el draft -> cargo draft
        if (transaction?.data && Object.keys(formData).length === 0 && !draftLoaded) {
            this.setState({
                draftLoaded: true,
            });
            const transactionData = transaction?.data;
            /* carga de errores */
            if (transactionData.backendFormErrors?.cdpErrorFields) {
                const { cdpErrorFields } = transactionData.backendFormErrors;
                cdpErrorFields.map((errorField) => {
                    setFieldError(errorField, SPACE_STR);
                    setFieldTouched(errorField, true);
                    return null;
                });
            }
            if (transactionData.backendFormErrors?.message) {
                const errorMessage = transactionData?.backendFormErrors?.message;
                dispatch(
                    SelectorActionNotification.showNotification({
                        message: errorMessage,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.COMEX_BILLING_CANCELLATION],
                    }),
                );
            }

            setValues(transaction.data);
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(GoBack());
    };

    handleFormKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            event.preventDefault();
        }
    };

    handleFieldKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            this.handleSearch();
        }
    };

    handleSelectChange = (id) => {
        const { setFieldValue } = this.props;
        setFieldValue("increment", id);
    };

    handleSelectChangeFieldName = (id, fieldName) => {
        const { setFieldValue } = this.props;
        setFieldValue(fieldName, id);
    };

    handleItemSelect = (item) => {
        const { setFieldValue, setFieldError } = this.props;
        const { othersFields } = item;
        const amount = item.balance * -1;
        const formattedAmount = formatNumber(amount, UtilsI18n.getLang());
        if (othersFields) {
            setFieldValue("operationNumber", othersFields.NROOPERACION);
            setFieldValue("item", item);
            setFieldValue("drawer", othersFields.GIRADOR);
            setFieldValue("amountField", { amount: item.balance * -1, currency: item.accountCurrency });
            setFieldValue("oldAmount", item.balance);
            setFieldValue("canBePartialCancellation", false);
            setFieldValue(`formattedAmount`, formattedAmount);
            setFieldError("operationNumber", null);
        } else {
            setFieldValue("item", null);
            setFieldValue("operationNumber", EMPTY_STR);
            setFieldValue("drawer", EMPTY_STR);
            setFieldValue("amountField", EMPTY_STR);
            setFieldValue("oldAmount", EMPTY_STR);
        }
    };

    handleBlur = () => {
        const { values, preData, setFieldValue } = this.props;
        const { availableOperationList } = preData;
        if (values.operationNumber) {
            const operationSelected = availableOperationList.filter(
                (item) => item.othersFields.NROOPERACION === values.operationNumber,
            );
            if (operationSelected.length > 0) {
                setFieldValue("item", operationSelected[0]);
                setFieldValue("drawer", operationSelected[0].othersFields.GIRADOR);
                setFieldValue("amountField", {
                    amount: operationSelected[0].balance * -1,
                    currency: operationSelected[0].accountCurrency,
                });
                setFieldValue("oldAmount", operationSelected[0].balance);
                setFieldValue("canBePartialCancellation", false);
                setFieldValue(`formattedAmount`, operationSelected[0].balance * -1);
            } else {
                setFieldValue("item", null);
                setFieldValue("operationNumber", EMPTY_STR);
                setFieldValue("drawer", EMPTY_STR);
                setFieldValue("amountField", EMPTY_STR);
                setFieldValue("oldAmount", EMPTY_STR);
            }
        }
    };

    handleAmountBlur = () => {
        const { setFieldValue } = this.props;

        const {
            values: { amountField, oldAmount },
        } = this.props;
        setFieldValue("canBePartialCancellation", amountField.amount < oldAmount * -1);
    };

    handleModalLoad = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionComex.toggleCreditLetterList());
    };

    handleCancel = () => {
        const { dispatch, setSubmitting, transaction } = this.props;

        dispatch(
            SelectorsActionForm.cancelTransactionCustom({
                credentials: {},
                idTransaction: transaction.idTransaction,
                formikBag: { setSubmitting },
            }),
        );
    };

    handleDraft = () => {
        const { dispatch, values, transaction } = this.props;
        dispatch(
            SelectorsActionForm.saveDraft({
                idActivityDraft: "comex.import.billingCancellation.send",
                idTransactionToSave: transaction?.idTransaction,
                transactionData: values,
            }),
        );
    };

    handleTemplateSave = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionTemplate.createTemplate());
    };

    handleTemplateLoad = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionTemplate.toggleTemplateList());
    };

    handleSelectTemplate = (template) => {
        const { setValues, setErrors, initialValues } = this.props;
        setValues(initialValues);
        setValues(template);
        setErrors({});
    };

    render() {
        const { values, emailValidationRegex, preData, countryList, setFieldValue, transaction } = this.props;
        const { nextValidDate, unavailableDays, availableOperationList, documentTypeList } = preData;
        const termsAndConditions = "comex.import.cancellationCollection.disclaimer";
        const i18nMap = {
            termsAndConditions,
        };
        const cancelEnabled = transaction && transaction.idTransactionStatus === STATUS.DRAFT;
        const othersFields = values.item ? values.item.othersFields : null;

        const currency = othersFields ? othersFields.MONEDA : EMPTY_STR;

        const data = {
            options: [
                {
                    id: currency,
                    label: currency ? UtilsI18n.get(`currency.label.${currency}`) : "",
                },
            ],
        };

        return (
            <React.Fragment>
                <Form
                    className="col col-12 col-lg-6 col-md-9 col-sm-12"
                    id={Style.ID}
                    noValidate="novalidate"
                    onKeyDown={this.handleFormKeyDown}>
                    <div className="form-section loadTemplates">
                        <Button
                            onClick={this.handleTemplateLoad}
                            label="forms.templates.load"
                            bsStyle="default"
                            image="template.svg"
                        />
                    </div>
                    <section className="fields container--layout align-items-center flex-grow">
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.section.operation`)}</h3>
                        </Box>

                        <Box flex align="end" className="form-group search-field">
                            <Field
                                idForm={FORM_ID}
                                name="operationNumber"
                                hidePlaceholder
                                pattern={ONLY_NUMBER}
                                type="text"
                                component={TextField}
                                onBlur={this.handleBlur}
                                tooltip={UtilsI18n.get(`${FORM_ID}.operationNumber.tooltip`)}
                            />

                            <Box className="ml-3 mb-2">
                                <Button
                                    onClick={this.handleModalLoad}
                                    bsStyle="primary"
                                    label="global.search"
                                    className="search"
                                />
                            </Box>
                        </Box>

                        {values.item && <OperationNumberDetail othersFields={othersFields} idForm={FORM_ID} />}

                        <Field
                            clearable={false}
                            component={AmountField}
                            data={data}
                            idForm={FORM_ID}
                            name="amountField"
                            placeholder={EMPTY_STR}
                            searchable={false}
                            decimalPlaces={2}
                            fixedDecimalScale
                            hideCurrency={!currency}
                            handleBlur={this.handleAmountBlur}
                            handleChange={(formattedValue) => {
                                setFieldValue(`formattedAmount`, formattedValue);
                            }}
                            maxLength={getInteger("amount.length")}
                        />

                        {values.canBePartialCancellation && (
                            <Box className="form-group">
                                <FieldLabel labelKey={`${FORM_ID}.cancellationType.label`} />
                                <Box className="input-group" flex align="end">
                                    <Field
                                        component={Select}
                                        name="cancellationType"
                                        placeholder={EMPTY_STR}
                                        value={values.cancellationType}
                                        clearable={false}
                                        onChange={(item) => {
                                            if (item) {
                                                setFieldValue("cancellationType", item.value);
                                            }
                                        }}
                                        nameType="idCountry"
                                        valueKey="value"
                                        labelKey="label"
                                        options={[
                                            { value: "parcial", label: "Parcial" },
                                            { value: "total", label: "Total" },
                                        ]}
                                        className="flex-container slideFromBottom border-right-solid"
                                        optionClassName="needsclick"
                                        idForm={FORM_ID}
                                    />
                                </Box>
                            </Box>
                        )}

                        <Field
                            component={TextArea}
                            idForm={FORM_ID}
                            name="observations"
                            mode={MODE.EDIT}
                            className="form-control"
                            maxLength={500}
                            hidePlaceholder
                        />

                        <Box className="form-group document-field">
                            <Field
                                idForm={FORM_ID}
                                name="authorizedDocument"
                                component={DocumentField}
                                data={{ countries: countryList, documentTypes: documentTypeList }}
                                handleError
                                autoFocus={false}
                                hidePlaceholder
                                hideDocumentPlaceholder
                                tooltip={UtilsI18n.get("client.comex.generic.documentNumber.tooltip")}
                            />
                        </Box>

                        <Field
                            idForm={FORM_ID}
                            name="authorizedDocumentName"
                            hidePlaceholder
                            type="text"
                            component={TextField}
                            tooltip={UtilsI18n.get(`${FORM_ID}.name.tooltip`)}
                            maxLength={140}
                        />

                        <Field
                            component={EmailList}
                            idForm={FORM_ID}
                            name="notificationEmailsField"
                            hideSwiftTip
                            className="form-group email-field"
                            renderSuggestion={false}
                            value={values.notificationEmailsField}
                            data={{ emailValidationRegex, addMessage: EMPTY_STR }}
                            optional={UtilsI18n.get("form.field.optional")}
                            tooltip={UtilsI18n.get(`${FORM_ID}.notificationEmails.tooltip`)}
                        />

                        <hr />
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">
                                {UtilsI18n.get(`${FORM_ID}.section.showPreview`)}
                            </h3>
                        </Box>

                        <Field
                            component={Termsandconditions}
                            idForm={FORM_ID}
                            name="disclaimer"
                            mode={MODE.EDIT}
                            className="form-control"
                            i18nMap={i18nMap}
                        />
                        {nextValidDate && (
                            <Field
                                component={Scheduler}
                                data={{
                                    firstWorkingDate: nextValidDate,
                                    maxDaysToSchedule: ConfigUtil.get("client.comex.valueDate.maxDaysToSchedule"),
                                    nonWorkingDays: unavailableDays,
                                    lang: "es",
                                    mode: "edit",
                                    programable: false,
                                    schedulable: true,
                                }}
                                name="scheduler"
                                idForm={FORM_ID}
                            />
                        )}
                    </section>

                    <footer>
                        <Button
                            onClick={this.handleTemplateSave}
                            className="templateSave"
                            label="forms.saveTemplate.link"
                        />
                        {cancelEnabled && <Button onClick={this.handleCancel} label="forms.cancelTransaction.link" />}
                        <Button type="submit" label="global.next" bsStyle="primary" />
                    </footer>
                </Form>
                <OperationNumber
                    list={availableOperationList}
                    idForm={FORM_ID}
                    handleItemClick={this.handleItemSelect}
                />

                <CreateTemplateModal values={values} idActivityTemplate="comex.import.billingCancellation.send" />
                <ListTemplatesModal
                    idActivityTemplate="comex.import.billingCancellation.send"
                    onSelect={this.handleSelectTemplate}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        preData: SelectorsStoreComexImport.getBillingCancellationOpenPre(store),
        formData: SelectorsStoreComexImport.getBillingCancellationFormData(store),
        countryList: SelectorsStore.getCountryList(store),
        documents: SelectorsStore.getDocuments(store),
        // emailValidationRegex: SelectorsStoreConfig.getConfig(store)["email.validationFormat"],
        transaction: SelectorsStoreComexImport.getTransaction(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: false,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ formData }) => {
            return {
                operationNumber: formData?.operationNumber || EMPTY_STR,
                oldAmount: formData?.oldAmount || 0,
                amountField: formData?.amountField || { amount: EMPTY_STR },
                formattedAmount: formData?.formattedAmount || EMPTY_STR,
                canBePartialCancellation: formData?.canBePartialCancellation || false,
                observations: formData?.observations || EMPTY_STR,
                notificationEmailsField: formData?.notificationEmailsField || [],
                item: formData?.item || null,
                drawer: formData?.drawer || EMPTY_STR,
                authorizedDocument: formData?.authorizedDocument || { country: "858", type: "12", document: EMPTY_STR },
                authorizedDocumentName: formData?.authorizedDocumentName || EMPTY_STR,
                cancellationType: formData?.cancellationType || "parcial",
                scheduler: formData?.scheduler,
            };
        },
        handleSubmit: (props, formikBag) => {
            const {
                dispatch,
                preData: { accountNumber },
                transaction,
            } = formikBag.props;
            const {
                authorizedDocument,
                notificationEmailsField,
                amountField,
                oldAmount,
                cancellationType,
                scheduler,
            } = props;

            let isTotal = false;
            const { amount: invoiceAmount } = amountField;
            if (invoiceAmount && oldAmount) {
                if (invoiceAmount + oldAmount >= 0) {
                    isTotal = true;
                }
            }

            const currency = amountField.currency?.toString();
            const amount = { quantity: invoiceAmount, currency };

            const formData = {
                ...props,
                accountNumber,
                notificationEmails: notificationEmailsField.join(","),
                authorizedDocumentCountryId: authorizedDocument.country,
                authorizedDocumentDocumentType: authorizedDocument.type,
                authorizedDocumentDocumentNumber: authorizedDocument.document,
                invoiceAmount,
                amountCurrency: currency,
                amount,
                cancellationType: isTotal ? "total" : cancellationType,
                valueDate: scheduler.valueDate,
                scheduler,
                // draft
                idTransaction: transaction?.idTransaction,
            };

            dispatch(
                SelectorsActionComexImport.billingCancellationPreviewRequest({
                    formData,
                    formikBag,
                }),
            );
        },
    }),
)(Component);
