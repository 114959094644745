import PropTypes from "prop-types";

import { EMPTY_STR, ZERO_NUMBER } from "~/constants";
import { MODE } from "~/constants/form";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "wm.funds.buy";

export const PATH = {
    WM_FUNDS_BUY_PRE: "/client.wm.funds.buy.pre",
    WM_FUNDS_BUY_PREVIEW: "/client.wm.funds.buy.preview",
    WM_FUNDS_BUY_SEND: "/client.wm.funds.buy.send",
};

export const TYPE = FactoryType(NAME, [
    "CANCEL_CUSTOM_TRANSACTION_REQUEST",
    "CLOSE_TRANSACTION_PREVIEW",
    "PREVIEW_CANCEL_REQUEST",
    "PREVIEW_PAPER_REQUEST",
    "PREVIEW_PAPER_SUCCESS",
    "READ_PAPER_REQUEST",
    "READ_PAPER_SUCCESS",
    "READ_TRANSACTION_REQUEST",
    "READ_TRANSACTION_SUCCESS",
    "RESET_FORM",
    "RESTART_FLOW",
    "SELECTED_ID_PAPER",
    "SEND_FORM_PAPER_REQUEST",
    "SEND_FORM_PAPER_SUCCESS",
    "SIGN_TRANSACTION_PREVIEW_REQUEST",
    "SIGN_TRANSACTION_PREVIEW_SUCCESS",
    "SIGN_TRANSACTION_REQUEST",
    "TASK_FINISHED",
]);

export const PROP = {};

PROP.types = {
    ...TypesRedux,
    amount: PropTypes.number,
    currency: PropTypes.number,
    debitAccountAlias: PropTypes.string,
    debitAccounts: PropTypes.array,
    disclaimer: PropTypes.bool,
    idDebitAccount: PropTypes.string,
    idPaper: PropTypes.number,
    idPaperSelected: PropTypes.number,
    isFetching: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    mode: PropTypes.string,
    paper: PropTypes.object,
    paperName: PropTypes.string,
    prevMode: PropTypes.string,
    timezoneOffset: PropTypes.number,
    transaction: PropTypes.object,
};

PROP.defaults = {
    ...DefaultsRedux,
    amount: ZERO_NUMBER,
    currency: ZERO_NUMBER,
    debitAccountAlias: EMPTY_STR,
    debitAccounts: [],
    disclaimer: false,
    idDebitAccount: EMPTY_STR,
    idPaper: ZERO_NUMBER,
    idPaperSelected: ZERO_NUMBER,
    isFetching: false,
    isSubmitting: false,
    mode: MODE.EDIT,
    paper: {},
    paperName: EMPTY_STR,
    prevMode: null,
    timezoneOffset: ZERO_NUMBER,
    transaction: {},
};
