import React from "react";

import { connect as Connect } from "react-redux";

import { CDP_CURRENCY } from "~/constants";
import { SelectorsStore as SelectorsStoreFactoring } from "~/store/factoring/advancePayments";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import DeviceUtils from "~/util/device";

import HighOrder from "~/components/HighOrder";
import Image from "~/components/Image";

import Style from "./Step3View.rules.scss";
import TransactionResume from "./TransactionResume";

export const { NAME } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const { currentDiscountableUSD, currentDiscountableUYU, isFromBackoffice, transaction } = props;

    const [totalDiscountableUYU, setTotalDiscountableUYU] = React.useState({ amount: 0, total: 0 });
    const [totalDiscountableUSD, setTotalDiscountableUSD] = React.useState({ amount: 0, total: 0 });

    React.useEffect(() => {
        if (isFromBackoffice) {
            const { data } = transaction;

            if (data.paymentList) {
                let counterUYU = { amount: 0, total: 0 };
                let counterUSD = { amount: 0, total: 0 };

                data.paymentList.forEach(({ currency, netAmount }) => {
                    if (`${currency}` === CDP_CURRENCY.UYU) {
                        counterUYU = {
                            amount: counterUYU.amount + netAmount,
                            total: counterUYU.total + 1,
                        };
                    } else {
                        counterUSD = {
                            amount: counterUSD.amount + netAmount,
                            total: counterUSD.total + 1,
                        };
                    }

                    setTotalDiscountableUYU(counterUYU);
                    setTotalDiscountableUSD(counterUSD);
                });
            }
        }
    }, [isFromBackoffice, transaction]);

    const discountableUSD = isFromBackoffice ? totalDiscountableUSD : currentDiscountableUSD;
    const discountableUYU = isFromBackoffice ? totalDiscountableUYU : currentDiscountableUYU;

    function handleShareClick(idTransactionToRead) {
        const { dispatch } = props;

        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    }

    return (
        <React.Fragment>
            {DeviceUtils.isMobileNative() && (
                <a className="share-button" onClick={() => handleShareClick(transaction.idTransaction)}>
                    <Image src="share.svg" className="svg-icon" />
                </a>
            )}
            <TransactionResume
                discountableUSD={discountableUSD}
                discountableUYU={discountableUYU}
                transaction={transaction}
            />
        </React.Fragment>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        currentDiscountableUSD: SelectorsStoreFactoring.getCurrentDiscountableUSD(store),
        currentDiscountableUYU: SelectorsStoreFactoring.getCurrentDiscountableUYU(store),
        transaction: SelectorsStoreFactoring.getTransaction(store),
    };
};

export default HighOrder(Connect(mapStateToProps))(Component);
