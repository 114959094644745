const routes = {
    "administration.advanced.group.create.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/groupFormData/${id}/ticket`,
    "administration.advanced.group.modify.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/groupFormData/${id}/ticket`,
    "administration.advanced.send.user.data": (id, administrationScheme) =>
        `/administration/${administrationScheme}/${id}/ticket`,
    "administration.groups.blockunblock.send": (id) => `/administration/groups/actions/${id}/ticket`,
    "administration.groups.delete.send": (id) => `/administration/groups/actions/${id}/ticket`,
    "administration.medium.modify.channels.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/channels/${id}/ticket`,
    "administration.medium.modify.permissions.send": (id) => `/administration/medium/permissions/${id}/ticket`,
    "administration.medium.modify.signature.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/signature/${id}/ticket`,
    "administration.restrictions.manage.send": (id) => `/administration/restrictions/manage/${id}/ticket`,
    "administration.restrictions.user.delete.send": (id) => `/administration/restrictions/user/delete/${id}/ticket`,
    "administration.signatures.create.send": (id) => `/administration/advanced/signaturesSchemes/${id}/ticket`,
    "administration.signatures.delete.send": (id) => `/administration/advanced/signaturesSchemes/${id}/ticket`,
    "administration.signatures.medium.modify.send": (id) => `/administration/medium/signature/${id}/ticket`,
    "administration.signatures.modify.send": (id) => `/administration/advanced/signaturesSchemes/${id}/ticket`,
    "administration.simple.modify.permissions.send": (id) => `/administration/simple/permissions/${id}/ticket`,
    "administration.user.detail.groups.modify.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/groupsOfUser/${id}/ticket`,
    "administration.users.blockunblock.send": (id) => `/administration/users/actions/${id}/ticket`,
    "administration.users.delete.send": (id) => `/administration/users/actions/${id}/ticket`,
    "administration.users.invite.send": (id) => `/administration/medium/userInvite/${id}/ticket`,
    "client.factoring.advancePayments.send": (id) => `/advancePayments/transaction/${id}/ticket`,
    "client.factoring.paymentOrders.upload.execute": (id) => `/uploadPaymentOrders/transaction/${id}/ticket`,
    "client.factoring.suppliers.upload.execute": (id) => `/uploadSuppliers/transaction/${id}/ticket`,
    "client.wm.bonds.buy.send": (id) => `/wm/bonds/buy/${id}/ticket`,
    "client.wm.bonds.sell.send": (id) => `/wm/bonds/sell/${id}/ticket`,
    "client.wm.funds.buy.send": (id) => `/wm/funds/buy/${id}/ticket`,
    "client.wm.funds.sell.send": (id) => `/wm/funds/sell/${id}/ticket`,
    "client.wm.funds.switch.send": (id) => `/wm/funds/switch/${id}/ticket`,
    "client.wm.shares.buy.send": (id) => `/wm/shares/buy/${id}/ticket`,
    "client.wm.shares.sell.send": (id) => `/wm/shares/sell/${id}/ticket`,
    "comex.bondsAndGuarantees.modifyBondsAndGuarantees.send": (id) =>
        `/comex/bondsAndGuarantees/modifyBondsAndGuarantees/${id}/ticket`,
    "comex.bondsAndGuarantees.requestBondsAndGuarantees": (id) =>
        `/comex/bondsAndGuarantees/requestBondsAndGuarantees/${id}/ticket`,
    "comex.bondsAndGuarantees.requestBondsAndGuarantees.send": (id) =>
        `/comex/bondsAndGuarantees/requestBondsAndGuarantees/${id}/ticket`,
    "comex.export.billingClaim.send": (id) => `/comex/export/billingClaim/${id}/ticket`,
    "comex.export.buyCreditLetter.send": (id) => `/comex/export/compracartacredito/${id}/ticket`,
    "comex.export.cessionCreditLetter.send": (id) => `/comex/export/cessionCreditLetter/${id}/ticket`,
    "comex.export.discrepancyDocumentAuthorization.send": (id) =>
        `/comex/export/discrepancyDocumentsAuthorization/${id}/ticket`,
    "comex.export.presentationDocumentsCreditLetter.send": (id) =>
        `/comex/export/presentationDocumentsCreditLetter/${id}/ticket`,
    "comex.export.presentBillingDocuments.send": (id) => `/comex/export/presentBillingDocuments/${id}/ticket`,
    "comex.export.transferCreditLetter.send": (id) => `/comex/export/transferCreditLetter/${id}/ticket`,
    "comex.import.billingCancellation.send": (id) => `/comex/import/billingcancellation/${id}/ticket`,
    "comex.import.cancellationCreditLetter.send": (id) => `/comex/import/cancellationCreditLetter/${id}/ticket`,
    "comex.import.creditLetterDocumentPresentation.send": (id) => `/comex/import/documentPresentation/${id}/ticket`,
    "comex.import.genericForm.send": (id) => `/comex/other/formulariogenerico/${id}/ticket`,
    "comex.import.liftCreditLetterWithDiscrepancies.send": (id) =>
        `/comex/import/liftCreditLetterDiscrepancies/${id}/ticket`,
    "comex.import.modifyCreditLetter.send": (id) => `/comex/import/modifyCreditLetter/${id}/ticket`,
    "comex.import.requestEndorsement.send": (id) => `/comex/import/requestEndorsement/${id}/ticket`,
    "comex.imports.creditLetterOpen.send": (id) => `/comex/import/creditLetterOpen/${id}/ticket`,
    "connectedParties.registerBantotal": (id) => `/connectedParties/${id}/ticket`,
    "creditCards.limit.increase.send": (id) => `/creditCardsLimitIncrease/${id}/ticket`,
    "creditCards.purchaseNotification.send": (id) => `/purchaseNotification/${id}/ticket`,
    "creditCards.tripOrOnlinePurchaseNotification.send": (id) => `/tripOrOnlinePurchaseNotification/${id}/ticket`,
    "investment.riskProfile.send": (id) => `/investment/transaction/riskProfile/${id}/ticket`,
    "investment.termDeposit.send": (id) => `/investment/transaction/termDeposit/${id}/ticket`,
    "investment.termDepositCMB.send": (id) => `/investment/transaction/termDeposit/${id}/ticket`,
    "pay.customs.payCustoms": (id) => `/customsPayment/${id}/ticket`,
    "payments.qrModo.send": (id) => `/servicePayment/qrModo/transaction/${id}/ticket`,
    "preferences.modify.accounts.data": (id) => `/settings/changeDataAccounts/${id}/ticket`,
    "preferences.modifyuser.configuration.execute": (id) => `/settings/changePersonalInformation/ticket/${id}`,
    "preferences.update.email.accounts.data": (id) =>
        `/settings/changeDataAccounts/changeEmail/transaction/${id}/ticket`,
    "preferences.userData.mail.update.execute": (id) => `/settings/changeEmail/transaction/${id}/ticket`,
    "preferences.userData.mobilePhone.update": (id) => `/settings/changeMobilePhone/transaction/${id}/ticket`,
    "preferentialTradingPrice.send": (id) => `/preferentialTradingPrice/${id}/ticket`,
    "service.payment.send": (id) => `/servicePayment/${id}/ticket`,
    "transfers.alias.administration.aliasDeletionSend": (id) => `/aliasDeletion/${id}/ticket`,
    "transfers.alias.administration.aliasEnrollmentSend": (id) => `/aliasEnrollment/${id}/ticket`,
};

export default routes;
