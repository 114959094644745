import React from "react";

import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { COMMA, EMPTY_STR } from "~/constants";
import { STATUS } from "~/constants/transaction";
import { SelectorsStore as SelectorsStoreComex, SelectorsAction as SelectorsActionComex } from "~/store/comex";
import { SelectorsAction as SelectorsActionFile } from "~/store/files";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import DeviceUtils from "~/util/device";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import DataDate from "~/components/DataDate";
import HighOrder from "~/components/HighOrder";
import Token from "~/components/Token/Token";
import TicketPrintInformation from "~/pages/_components/TicketPrintInformation";

import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        isSubmitting: PropTypes.bool,
    },
    defaults: {
        isSubmitting: false,
    },
};

const printForm = () => {
    const elements = window.document.getElementsByClassName("no_print");

    for (let i = 0; i < elements.length; i += 1) {
        elements[i].style.display = "none";
    }

    window.print();

    for (let i = 0; i < elements.length; i += 1) {
        elements[i].style.display = EMPTY_STR;
    }

    return true;
};

export function Component(props) {
    const { isSubmitting, dispatch, formData } = props;
    const { valueDate, subject, message, attachments, attachmentsNames } = formData;

    const shareTicket = () => {
        dispatch(
            SelectorsActionTransactions.shareTicketRequest({
                transactionData: formData,
                idActivity: "comex.import.genericForm.send",
            }),
        );
    };

    const downloadFile = (idFile, fileName) => {
        dispatch(SelectorsActionFile.downloadFileRequest({ idFile, fileName }));
    };

    const fileIds = attachments ? attachments.split(COMMA) : EMPTY_STR;

    return (
        <Form id={Style.ID}>
            <TicketPrintInformation />
            <Box flex directionColumn align="flex-start" className="mb-3">
                <Box className="info-label">
                    <h4 className="bold">{UtilsI18n.get("comex.other.otherProcedures.step2.valueDate")}</h4>
                    <DataDate value={valueDate} />
                </Box>
                <Box className="info-label">
                    <h4 className="bold">{UtilsI18n.get("comex.other.otherProcedures.step2.subject")}</h4>
                    <span>{subject}</span>
                </Box>
                {message && (
                    <Box className="info-label">
                        <h4 className="bold">{UtilsI18n.get("comex.other.otherProcedures.step2.message")}</h4>
                        <span>{message}</span>
                    </Box>
                )}
                {attachments && (
                    <Box className="info-label">
                        <Box className="bold"> {UtilsI18n.get("comex.other.otherProcedures.step2.attachments")}</Box>
                        {fileIds.map((fileId, index) => (
                            <Box className="data-wrapper">
                                <span className="data-label">
                                    <a onClick={() => downloadFile(fileId, attachmentsNames[index])}>
                                        {attachmentsNames[index]}
                                    </a>
                                </span>
                            </Box>
                        ))}
                    </Box>
                )}
            </Box>

            <div className="no_print">
                <Token labelButton="global.send" isSubmitting={isSubmitting} />
            </div>
            {!DeviceUtils.isMobileNative() && (
                <div className="buttonsTicketConfirmation">
                    <Button
                        block={false}
                        bsStyle="link"
                        label="comex.preview.print.label"
                        onClick={() => printForm()}
                    />
                </div>
            )}
            <div className="buttonsTicketConfirmation">
                <Button bsStyle="link" block={false} label="forms.ticket.printPDF" onClick={() => shareTicket()} />
            </div>
        </Form>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        formData: SelectorsStoreComex.getOtherProcedureFormData(store),
        transaction: SelectorsStoreComex.getTransaction(store),
        user: SelectorsStoreSession.getUser(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithRouter,
    WithFormik({
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        validationSchema: (props) => {
            const { user } = props;

            return Yup.object().shape({
                otp: user.needsBiometric
                    ? Yup.string().nullable()
                    : Yup.string().required(UtilsI18n.get("comex.other.otherProcedures.otp.required")),
            });
        },
        handleSubmit: ({ otp }, formikBag) => {
            const {
                dispatch,
                history,
                formData,
                transaction: { idTransaction, idActivity, idTransactionStatus },
            } = formikBag.props;

            const isFromTransactionsHistory = history.location.pathname.includes("/ticket");

            if (isFromTransactionsHistory && idTransactionStatus !== STATUS.DRAFT) {
                dispatch(
                    SelectorsActionComex.signTransaction({
                        idForm: null,
                        idActivity,
                        idTransaction,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            } else {
                dispatch(
                    SelectorsActionComex.otherProcedureSendRequest({
                        formData,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            }
        },
    }),
)(Component);
