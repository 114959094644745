import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, MAX_LENGTH_OTP, MIN_LENGTH_OTP } from "~/constants";
import { SelectorsStore } from "~/store/enrollment";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as UtilsI18n from "~/util/i18n";

import NumberFormatInput from "~/components/NumberFormatInput";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";

export const NAME = "OTP";

export const PROP = {
    types: {
        autoComplete: PropTypes.string,
        autoFocus: PropTypes.bool,
        className: PropTypes.string,
        errors: PropTypes.arrayOf(PropTypes.string),
        hidePlaceholder: PropTypes.bool,
        id: PropTypes.string,
        inputRef: PropTypes.func,
        keyPrefix: PropTypes.string,
        maxLength: PropTypes.number,
        minLength: PropTypes.number,
        pattern: PropTypes.string,
        renderhard: PropTypes.bool,
        value: PropTypes.string,
    },
    defaults: {
        autoComplete: "off",
        autoFocus: false,
        className: "form-control",
        errors: null,
        hidePlaceholder: false,
        id: "otp",
        inputRef: null,
        keyPrefix: EMPTY_STR,
        maxLength: MAX_LENGTH_OTP,
        minLength: MIN_LENGTH_OTP,
        pattern: EMPTY_STR,
        renderhard: false,
        value: undefined,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    renderHard = (props) => {
        const {
            autoComplete,
            autoFocus,
            className,
            field,
            form: { errors, handleChange, touched },
            hidePlaceholder,
            hidelabel,
            id,
            idForm,
            maxLength,
            minLength,
            pattern,
            toggleIsFocused,
        } = props;

        const fieldName = field.name;
        const hasError = touched[fieldName] && errors[fieldName];
        const i18NPrefix = `${idForm}.${fieldName}`;
        const placeholder = hidePlaceholder ? EMPTY_STR : UtilsI18n.get(`${i18NPrefix}.placeholder`);
        const cls = `form-group ${hasError ? "has-error" : EMPTY_STR}`;

        return (
            <div
                ref={(node) => {
                    this.node = node;
                }}
                className={cls}>
                <div className="form-group-otp">
                    {!hidelabel && <FieldLabel labelKey={`${i18NPrefix}.label`} />}
                    <div className="input-group">
                        <NumberFormatInput
                            allowLeadingZeros
                            allowNegative={false}
                            autoComplete={autoComplete}
                            autoFocus={autoFocus}
                            className={className}
                            id={id}
                            inputRef={this.inputRef}
                            maxLength={maxLength}
                            minLength={minLength}
                            name={fieldName}
                            onChange={handleChange}
                            onFocus={toggleIsFocused}
                            pattern={pattern}
                            placeholder={placeholder}
                            type="tel"
                            value={field?.value || EMPTY_STR}
                        />
                    </div>
                </div>
                {hasError && <FieldError error={errors[fieldName]} />}
            </div>
        );
    };

    renderSoft = () => <div />;

    render() {
        const { user, needsBiometric, renderhard } = this.props;
        let renderSoft = false;

        if (user) {
            renderSoft = user.needsBiometric;
        } else {
            renderSoft = needsBiometric;
        }

        return renderSoft && !renderhard ? this.renderSoft() : this.renderHard(this.props);
    }
}

const connectedComponent = Connect((store) => ({
    needsBiometric: SelectorsStore.needsBiometric(store),
    user: SelectorsStoreSession.getUser(store),
}))(Component);

export default connectedComponent;
