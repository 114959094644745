import React from "react";

import { push as Push } from "connected-react-router";
import { withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EVENT_BACKBUTTON, REGISTER_BIOMETRIC, SCOPE } from "~/constants";
import ContainerExternal from "~/containers/External/Form";
import ContainerInternal from "~/containers/Internal/Form/Simple";
import {
    SelectorsAction as SelectorsActionBiometric,
    SelectorsStore as SelectorsStoreBiometric,
    TYPE_VERIFY_BIOMETRIC,
} from "~/store/biometric";
import { SelectorsStore as SelectorsStoreChangeMyPhone } from "~/store/changeMyPhone";
import { SelectorsAction, SelectorsStore, PROP as PROP_STORE } from "~/store/enrollment";
import {
    SelectorsStore as SelectorsStoreMigration,
    SelectorsAction as SelectorsActionMigration,
} from "~/store/migration";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as ConfigUtils from "~/util/config";
import DeviceUtils from "~/util/device";
import { Defaults as DefaultsHtmlElement, Types as TypesHtmlElement } from "~/util/prop/html-element";
import { Defaults as DefaultsRedux, Types as TypesRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import WithExchangeToken from "~/pages/_components/withExchangeToken";

import CancelConfirmationModal from "~/pages/enrollment/_CancelConfirmationModal";

export const NAME = "RegisterBiometric";

export const PROP = {
    types: {
        statusVerifyBiometric: PropTypes.string,
        registerMigrationFinish: PropTypes.bool,
        registerBiometricMigration: PropTypes.bool,
        ...PROP_STORE.types,
        ...TypesRedux,
        ...TypesHtmlElement,
    },
    defaults: {
        statusVerifyBiometric: null,
        registerMigrationFinish: false,
        registerBiometricMigration: false,
        ...PROP_STORE.defaults,
        ...DefaultsRedux,
        ...DefaultsHtmlElement,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {
        showFingerPrint: true,
        registerBiometric: true,
        show: false,
    };

    componentDidMount() {
        const { showFingerPrint } = this.state;
        const { registerMigrationFinish, registerBiometricMigration } = this.props;

        if (showFingerPrint) {
            if (registerBiometricMigration) {
                // Si es una migracion
                if (!registerMigrationFinish) {
                    // Si no finalizo la migracion ...
                    this.handleRetry();
                }
            } else {
                this.handleRetry();
            }
        }
        if (DeviceUtils.isMobileNative()) {
            document.addEventListener(EVENT_BACKBUTTON, this.showModal);
        }
    }

    componentDidUpdate() {
        const { statusVerifyBiometric } = this.props;
        const { registerBiometric } = this.state;
        if (registerBiometric && statusVerifyBiometric === TYPE_VERIFY_BIOMETRIC.SUCCESS) {
            this.handleAddSoftOTP();
        }
    }

    componentWillUnmount() {
        if (DeviceUtils.isMobileNative()) {
            document.removeEventListener(EVENT_BACKBUTTON, this.showModal);
        }
    }

    handleRetry = () => {
        const { dispatch } = this.props;
        this.setState({ showFingerPrint: false }, function registerBiometric() {
            const retryAuthenticationNumber = ConfigUtils.getInteger("authentication.facephiMaxRetries", 3);
            dispatch(
                SelectorsActionBiometric.retryAuthNumber({
                    retryAuthenticationNumber,
                }),
            );
            this.handleRegisterBiometric();
        });
    };

    handleRegisterBiometric = () => {
        const { dispatch, exchangeTokenChangeMyPhone, isChangeMyPhone, userNameChangeMyPhone, isFacephi } = this.props;
        let { exchangeToken } = this.props;
        if (isChangeMyPhone) {
            exchangeToken = exchangeTokenChangeMyPhone;
        }
        if (isFacephi) {
            dispatch(
                SelectorsActionBiometric.registerBiometricFacephi({
                    exchangeToken,
                    isChangeMyPhone,
                    userNameChangeMyPhone,
                }),
            );
        } else {
            dispatch(
                SelectorsActionBiometric.registerBiometric({
                    exchangeToken,
                    isChangeMyPhone,
                    userNameChangeMyPhone,
                    pathFrom: REGISTER_BIOMETRIC,
                }),
            );
        }
    };

    handleAddSoftOTP = () => {
        const {
            dispatch,
            exchangeTokenChangeMyPhone,
            registerBiometricMigration,
            biometricType,
            isChangeMyPhone,
            userNameChangeMyPhone,
        } = this.props;
        let { exchangeToken, username } = this.props;
        this.setState({ registerBiometric: false });
        if (registerBiometricMigration) {
            return dispatch(SelectorsActionMigration.addSoftOTPWithBiometric({ biometricType }));
        }
        if (isChangeMyPhone) {
            exchangeToken = exchangeTokenChangeMyPhone;
            username = userNameChangeMyPhone;
        }
        return dispatch(SelectorsAction.addSoftOTPWithBiometric({ exchangeToken, username, biometricType }));
    };

    handleConfirmation = () => {
        const { dispatch, registerBiometricMigration } = this.props;
        if (registerBiometricMigration) {
            dispatch(Push("/desktop"));
        } else {
            dispatch(Push("/"));
        }
    };

    handleCancelation = () => {
        this.setState({ show: false });
    };

    showModal = () => {
        this.setState({ show: true });
    };

    handleBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    render() {
        const { fetching, fetchingMigration, fetchingBiometric, isLoggedIn } = this.props;
        const { show } = this.state;
        const fetchingPage = fetching || fetchingMigration || fetchingBiometric;

        const Container = ({ ...rest }) =>
            isLoggedIn ? <ContainerInternal {...rest} /> : <ContainerExternal {...rest} />;
        Container.displayName = `${NAME}.Container`;

        return (
            <React.Fragment>
                <CancelConfirmationModal
                    show={show}
                    handleCancelation={this.handleCancelation}
                    handleConfirmation={this.handleConfirmation}
                />
                <Container
                    id={NAME}
                    head-title="enrollment.registerBiometric.title"
                    name={NAME}
                    head-onBackWeb={this.handleBack}
                    head-onClose={this.showModal}
                    wait={fetchingPage}
                    scopeToShowNotification={SCOPE.REGISTER_BIOMETRIC}>
                    <div className="enrollment page-success">
                        <div className="enrollment-content">
                            <div className="login-row main-row">
                                <div className="login-row simple-row">
                                    <span className="login-message">
                                        {!fetchingPage && <I18n id="enrollment.registerBiometric.body.message" />}
                                    </span>
                                </div>
                                <div className="login-form-field">
                                    <Button
                                        className="login-form-button main-button"
                                        bsStyle="primary"
                                        label="enrollment.registerBiometric.button"
                                        loading={false}
                                        onClick={this.handleRegisterBiometric}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>{" "}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    exchangeToken: SelectorsStore.exchangeToken(store),
    exchangeTokenChangeMyPhone: SelectorsStoreChangeMyPhone.exchangeToken(store),
    isChangeMyPhone: SelectorsStoreChangeMyPhone.isChangeMyPhone(store),
    isFacephi: SelectorsStoreBiometric.isFacephi(store),
    fetching: SelectorsStore.fetching(store),
    username: SelectorsStore.username(store),
    userNameChangeMyPhone: SelectorsStoreChangeMyPhone.username(store),
    statusVerifyBiometric: SelectorsStoreBiometric.statusVerifyBiometric(store),
    biometricType: SelectorsStoreBiometric.biometricType(store),
    registerBiometricMigration: SelectorsStoreMigration.registerBiometricMigration(store),
    fetchingMigration: SelectorsStoreMigration.fetching(store),
    registerMigrationFinish: SelectorsStoreMigration.getRegisterMigrationFinish(store),
    fetchingBiometric: SelectorsStoreBiometric.fetching(store),
    isLoggedIn: SelectorsStoreSession.isLoggedIn(store),
});
export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
    }),
)(WithExchangeToken(Component));
// )(Component);
