import { LOCATION_CHANGE } from "connected-react-router";

import { MODE } from "~/constants/form";
import { TYPE as TypesBackoffice } from "~/store/backoffice";
import { TYPE as TypesBiometric } from "~/store/biometric";
import { TYPE as TYPE_PRODUCTS } from "~/store/products";
import { TYPE as TYPE_SESSION } from "~/store/session";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            const shouldNotKeep = action.payload.location.pathname.indexOf("creditCards/") === -1;

            if (shouldNotKeep) {
                return {
                    ...PROP.defaults,
                };
            }
            return {
                ...storeProp,
            };
        }
        case TYPE.DETAILS_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.DETAILS_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
            };
        case TYPE.DETAILS_SUCCESS: {
            const creditCardList =
                action.detail.creditCardList &&
                action.detail.creditCardList.sort((a, b) => b.ownershipType.localeCompare(a.ownershipType));

            return {
                ...storeProp,
                detail: { ...action.detail, creditCardList },
                hasMoreMovements: action.hasMoreMovements,
                isFetching: false,
                movements: action.movements,
                pageNumber: action.pageNumber,
            };
        }
        case TYPE.FETCH_MORE_MOVEMENTS_REQUEST:
        case TYPE.MOVEMENTS_REQUEST:
            return {
                ...storeProp,
                isFetchingMovements: true,
            };

        case TYPE.MOVEMENTS_FAILURE:
            return { ...storeProp, isFetchingMovements: false };
        case TYPE.MOVEMENTS_SUCCESS:
            return {
                ...storeProp,
                hasMoreMovements: action.hasMoreMovements,
                isFetchingMovements: false,
                movements: action.movements,
            };
        case TYPE.CREDIT_CARD_MOVEMENTS_REQUEST:
            return {
                ...storeProp,
                isFetchingMovementsByCreditCard: true,
            };
        case TYPE.CREDIT_CARD_MOVEMENTS_FAILURE:
            return { ...storeProp, isFetchingMovementsByCreditCard: false };
        case TYPE.CREDIT_CARD_MOVEMENTS_SUCCESS: {
            const { creditCardNumber } = action.creditCard;
            let items = storeProp.creditCardList;
            const index = storeProp.creditCardList.findIndex((item) => item.creditCardNumber === creditCardNumber);

            if (index > -1) {
                storeProp.creditCardList.splice(index, 1);
            }

            items = storeProp.creditCardList.concat([action.creditCard]);

            return {
                ...storeProp,
                isFetchingMovementsByCreditCard: false,
                creditCardList: items,
            };
        }
        case TYPE.FETCH_MORE_MOVEMENTS_FAILURE:
            return {
                ...storeProp,
                isFetchingMovements: false,
            };
        case TYPE.FETCH_MORE_MOVEMENTS_SUCCESS:
            return {
                ...storeProp,
                isFetchingMovements: false,
                hasMoreMovements: action.hasMoreMovements,
                movements: storeProp.movements ? storeProp.movements.concat(action.movements) : action.movements,
                pageNumber: action.pageNumber,
            };
        case TYPE.SHOW_OPTIONS:
            return {
                ...storeProp,
                isOptionsVisible: true,
            };
        case TYPE.HIDE_OPTIONS:
            return {
                ...storeProp,
                isOptionsVisible: false,
            };
        case TYPE_PRODUCTS.CHANGE_PRODUCT_ALIAS_SUCCESS:
            return {
                ...storeProp,
                detail: {
                    ...storeProp.detail,
                    productAlias: action.alias,
                },
            };
        case TYPE.READ_CREDIT_CARD_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.READ_CREDIT_CARD_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
            };
        case TYPE.READ_CREDIT_CARD_SUCCESS:
            return {
                ...storeProp,
                creditCard: action.creditCard,
                isFetching: false,
            };
        case TYPE.CREDIT_CARD_STATEMENTS_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.CREDIT_CARD_STATEMENTS_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
            };
        case TYPE.CREDIT_CARD_STATEMENTS_SUCCESS: {
            const { statements } = storeProp;
            const { id, statements: newStatements } = action;

            if (!statements.id) {
                statements[id] = newStatements;
            } else {
                statements.id.push(newStatements);
            }
            return {
                ...storeProp,
                isFetching: false,
                statements,
            };
        }
        case TYPE.CREDIT_CARD_STATEMENT_DOWNLOAD_REQUEST:
            return {
                ...storeProp,
                isDownloading: true,
            };
        case TYPE.CREDIT_CARD_STATEMENT_DOWNLOAD_SUCCESS:
        case TYPE.CREDIT_CARD_STATEMENT_DOWNLOAD_FAILURE:
            return {
                ...storeProp,
                isDownloading: false,
            };
        case TYPE.LIST_REQUEST:
            return {
                ...storeProp,
                creditCards: { isFetching: true, list: [] },
            };
        case TYPE.LIST_FAILURE:
            return {
                ...storeProp,
                creditCards: { isFetching: false, list: [] },
            };
        case TYPE.LIST_SUCCESS:
            return {
                ...storeProp,
                creditCards: { isFetching: false, list: action.list },
            };
        case TYPE.UPDATE_NOTE_REQUEST:
        case TYPE.DETAILS_REQUEST_MOVEMENT:
            return {
                ...storeProp,
            };
        case TYPE.UPDATE_NOTE_FAILURE:
        case TYPE.DETAILS_FAILURE_MOVEMENT:
            return {
                ...storeProp,
                creditCardMovementDetail: { detail: null, isFetching: false },
            };
        case TYPE.DETAILS_SUCCESS_MOVEMENT:
            return {
                ...storeProp,
                creditCardMovementDetail: { detail: action.movement, isFetching: false },
            };
        case TYPE.UPDATE_NOTE_SUCCESS:
            return {
                ...storeProp,
                creditCardMovementDetail: {
                    detail: {
                        ...storeProp.detail,
                        note: action.note,
                    },
                    isFetching: false,
                },
            };
        case TYPE_SESSION.CHANGE_ENVIRONMENT_SUCCESS:
            return { ...storeProp, detail: PROP.defaults.detail };
        case TYPE.LIMIT_INCREASE_PRE_REQUEST:
        case TYPE.LIMIT_INCREASE_PREVIEW_REQUEST:
        case TYPE.LIMIT_INCREASE_SEND_REQUEST:
        case TYPE.PURCHASE_NOTIFICATION_PRE_REQUEST:
        case TYPE.PURCHASE_NOTIFICATION_PREVIEW_REQUEST:
        case TYPE.PURCHASE_NOTIFICATION_SEND_REQUEST:
        case TYPE.TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_PRE_REQUEST:
        case TYPE.TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_PREVIEW_REQUEST:
        case TYPE.TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_SEND_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.LIMIT_INCREASE_PRE_FAILURE:
        case TYPE.LIMIT_INCREASE_PREVIEW_FAILURE:
        case TYPE.LIMIT_INCREASE_SEND_FAILURE:
        case TYPE.PURCHASE_NOTIFICATION_PRE_FAILURE:
        case TYPE.PURCHASE_NOTIFICATION_PREVIEW_FAILURE:
        case TYPE.PURCHASE_NOTIFICATION_SEND_FAILURE:
        case TYPE.READ_TRANSACTION_FAILURE:
        case TYPE.SIGN_TRANSACTION_FAILURE:
        case TYPE.TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_PRE_FAILURE:
        case TYPE.TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_PREVIEW_FAILURE:
        case TYPE.TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_SEND_FAILURE:
        case TypesBiometric.SHOW_PASSWORD_MODAL:
            return {
                ...storeProp,
                isFetching: false,
            };
        case TYPE.LIMIT_INCREASE_PRE_SUCCESS:
        case TYPE.PURCHASE_NOTIFICATION_PRE_SUCCESS:
        case TYPE.TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_PRE_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                timezoneOffset: action.timezoneOffset,
                visaMasterAccounts: action.visaMasterAccounts,
            };
        case TYPE.LIMIT_INCREASE_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                limitIncrease: {
                    formData: storeProp.visaMasterAccounts,
                    limitType: action.limitType,
                    newLimit: action.amount,
                    visaMasterAccount: action.accountVisaMaster,
                },
                mode: MODE.PREVIEW,
            };
        case TYPE.LIMIT_INCREASE_SEND_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };
        case TYPE.PURCHASE_NOTIFICATION_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                mode: MODE.PREVIEW,
                purchaseData: {
                    cardAccountsNotificationsUpdated: action.cardAccountsNotificationsUpdated,
                    notifications: action.notifications,
                    selectedCardAccount: action.selectedCardAccount,
                },
            };
        case TYPE.PURCHASE_NOTIFICATION_SEND_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };
        case TYPE.TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                mode: MODE.PREVIEW,
                tripOrOnlinePurchase: {
                    accountVisaMaster: action.accountVisaMaster,
                    beginDate: action.beginDate,
                    checkboxes: action.checkboxes,
                    countries: action.countries,
                    countryCodes: action.countryCodes,
                    creditCardList: action.creditCardList,
                    endDate: action.endDate,
                    formData: storeProp.visaMasterAccounts,
                    operationType: action.operationType,
                    store: action.store,
                },
            };
        case TYPE.TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_SEND_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };
        case TYPE.READ_TRANSACTION_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.READ_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };
        case TYPE.SIGN_TRANSACTION_PREVIEW:
            return {
                ...storeProp,
                mode: MODE.PREVIEW,
                prevMode: storeProp.mode,
            };
        case TYPE.SIGN_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                prevMode: storeProp.mode,
                transaction: action.transaction,
            };
        case TYPE.CLEAN_UP:
            return {
                ...PROP.defaults,
                statements: {},
            };
        case TYPE.FORM_TO_STEP_1:
            return {
                ...storeProp,
                mode: MODE.EDIT,
            };
        case TYPE.CHANGE_STATUS_REQUEST:
            return {
                ...storeProp,
                isChangingStatusCreditCard: true,
            };
        case TYPE.CHANGE_STATUS_FAILURE:
            return {
                ...storeProp,
                isChangingStatusCreditCard: false,
            };
        case TYPE.CHANGE_STATUS_SUCCESS: {
            const { detail } = storeProp;
            const { creditCardstatus, creditCardPosition } = action;

            return {
                ...storeProp,
                detail: {
                    ...detail,
                    creditCardList: detail.creditCardList.map((creditCardItem, i) => {
                        const creditCard = creditCardItem;

                        if (i === creditCardPosition) {
                            creditCard.status = creditCardstatus;
                        }

                        return creditCard;
                    }),
                },
                isChangingStatusCreditCard: false,
            };
        }

        case TypesBackoffice.BACKOFFICE_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                prevMode: storeProp.mode,
                transaction: action.transaction,
            };
        default:
            return {
                ...storeProp,
            };
    }
};
