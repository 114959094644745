import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, CDP_CURRENCY } from "~/constants";
import { SelectorsStore as SelectorsStoreUploadSuppliers } from "~/store/factoring/uploadSuppliers";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import UtilsFlag from "~/util/flag";
import { generateId as GenerateId } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";

import ExpandElement from "~/components/ExpandElement";
import GridLayout from "~/components/GridLayout";
import HighOrder from "~/components/HighOrder";
import Image from "~/components/Image";

import ExtraData from "./ExtraData";
import Style from "./UploadSuppliersDisplayList.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        suppliersDisplayList: PropTypes.arrayOf(
            PropTypes.shape({
                code: PropTypes.string,
                name: PropTypes.string,
                paymentType: PropTypes.string,
                account: PropTypes.string,
                currency: PropTypes.string,
            }),
        ),
    },
    defaults: {},
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    shouldComponentUpdate(nextProps) {
        if (
            this.props.suppliersList === nextProps.suppliersList &&
            this.props.transaction === nextProps.transaction &&
            this.props.isMobile === nextProps.isMobile
        ) {
            return false;
        }
        return true;
    }

    Flag = ({ currency }) => {
        return (
            <React.Fragment>
                {currency.toString() === CDP_CURRENCY.UI ? (
                    <React.Fragment>
                        <Image src={`${UtilsFlag.getFlag(CDP_CURRENCY.UYU)}.svg`} />
                        <Image src={`${UtilsFlag.getFlag(CDP_CURRENCY.USD)}.svg`} />
                    </React.Fragment>
                ) : (
                    <Image src={`${UtilsFlag.getFlag(`${currency}`)}.svg`} />
                )}
            </React.Fragment>
        );
    };

    render() {
        const { transaction, suppliersList, i18n, isMobile } = this.props;
        const COLUMN_HEADINGS = [
            i18n.index,
            i18n.code,
            i18n.name,
            i18n.paymentType,
            i18n.currencyAndAccount,
            EMPTY_STR,
        ];
        const COLUMN_HEADINGS_MOBILE = [i18n.name, i18n.currencyAndAccount];

        // Obtenemos los proveedores desde la transaccion si estamos en el listado de transacciones.
        const suppliers = transaction && transaction.data ? transaction.data.suppliersList : suppliersList;
        return (
            <React.Fragment>
                {suppliers && (
                    <div id={Style.ID}>
                        <GridLayout className="header">
                            {(!isMobile ? COLUMN_HEADINGS : COLUMN_HEADINGS_MOBILE).map((heading) => {
                                return <div key={`header-${GenerateId()}`}>{heading}</div>;
                            })}
                        </GridLayout>
                        {suppliers.map((detail, index) => {
                            const { supplierCode, name, paymentType, account, currency } = detail;
                            return (
                                <ExpandElement key={`suppliers-${GenerateId()}`}>
                                    <GridLayout className="item">
                                        {!isMobile && (
                                            <React.Fragment>
                                                <div>{index + 1}</div>
                                                <div>{supplierCode}</div>
                                            </React.Fragment>
                                        )}
                                        <div>{name}</div>
                                        {!isMobile && (
                                            <div>
                                                {UtilsI18n.get(`client.factoring.suppliers.paymentType.${paymentType}`)}
                                            </div>
                                        )}
                                        <div className="currency">
                                            {this.Flag({ currency })}
                                            <div className="currency-label">
                                                {UtilsI18n.get(`currency.label.${currency}`)}
                                            </div>
                                            {account}
                                        </div>
                                    </GridLayout>
                                    <GridLayout className="extra-data">
                                        <ExtraData detail={detail} />
                                    </GridLayout>
                                </ExpandElement>
                            );
                        })}
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreUploadSuppliers.getTransaction(store),
        suppliersList: SelectorsStoreUploadSuppliers.getSuppliers(store),
        i18n: {
            index: StoreI18n.getMessage(store, "client.factoring.uploadSuppliers.header.index"),
            code: StoreI18n.getMessage(store, "client.factoring.uploadSuppliers.header.code"),
            name: StoreI18n.getMessage(store, "client.factoring.uploadSuppliers.header.name"),
            paymentType: StoreI18n.getMessage(store, "client.factoring.uploadSuppliers.header.paymentType"),
            currencyAndAccount: StoreI18n.getMessage(
                store,
                "client.factoring.uploadSuppliers.header.currencyAndAccount",
            ),
        },
    };
};

export default HighOrder(HighOrder.Resizable, Connect(mapStateToProps))(Component);
