import React from "react";

import AdministrationTicket from "~/pages/administration/_components/tickets/AdministrationTicket";
import UserInviteTicket from "~/pages/administration/_components/tickets/UserInviteTicket";

export const NAME = "UserInviteTicketContent";

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    return (
        <AdministrationTicket {...props}>
            <UserInviteTicket {...props} />
        </AdministrationTicket>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
