import { routerActions as RouterActions } from "connected-react-router";
import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import {
    LEVEL,
    SCOPE,
    RESPONSE_TYPE,
    INSUFFICIENT_SIGNATURES,
    ADMINISTRATION_USER_ACTIONS,
    SUPPORTED_FILE_TYPES,
    GLOBAL_UNEXPECTED_ERROR_KEY,
    EMPTY_STR,
    ADMINISTRATION_USER_ACTIONS_TO_STATUS,
} from "~/constants";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as UtilDownload from "~/util/download";
import { credentialsWithUnderscore as credentialsWithUnderscoreFormat } from "~/util/form";
import * as UtilI18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsMiddleware } from "./_selectors";

const sagas = [
    TakeLatest(TYPE.LOAD_LIST_REQUEST, loadListRequest),
    TakeLatest(TYPE.LOAD_MORE_REQUEST, loadMoreRequest),
    TakeLatest(TYPE.EXPORT_LIST_REQUEST, exportListRequest),
    TakeLatest(TYPE.CHANGE_USER_STATUS_PREVIEW, changeUserStatusPreview),
    TakeLatest(TYPE.CHANGE_USER_STATUS_CONFIRMATION, changeUserStatusConfirmation),
    TakeLatest(TYPE.UPDATE_DISPATCHER_REQUEST, updateDispatcher),
    TakeLatest(TYPE.RESEND_INVITE_REQUEST, resendInviteRequest),
];

export default sagas;

function* loadListRequest(props) {
    const { filters } = props;
    const params = filters || { pageNumber: 1, orderBy: "last_login DESC" };

    const response = yield Call(SelectorsMiddleware.loadListRequest, params);
    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.loadListFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADMINISTRATION_USERS],
            }),
        );
    } else {
        yield Put(SelectorsAction.loadListSuccess({ data: response.data.data }));
    }
}

function* loadMoreRequest(props) {
    const response = yield Call(SelectorsMiddleware.loadListRequest, props);
    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.loadListFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADMINISTRATION_USERS],
            }),
        );
    } else {
        yield Put(SelectorsAction.loadMoreSuccess({ data: response.data.data }));
    }
}

function* exportListRequest(props) {
    const { format = SUPPORTED_FILE_TYPES.XLS } = props;
    const params = { format, orderBy: "last_login DESC" };

    const { type, data } = yield Call(SelectorsMiddleware.exportListRequest, params);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.exportListFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADMINISTRATION_USERS],
            }),
        );
    } else {
        const { content, fileName } = data.data;
        if (format === SUPPORTED_FILE_TYPES.PDF) {
            UtilDownload.downloadPdf(fileName, content);
        } else {
            UtilDownload.downloadXls(fileName, content);
        }
        yield Put(SelectorsAction.exportListSuccess());
    }
}

function* changeUserStatusPreview(props) {
    const { userList, userNameList, userAction } = props;
    const params = {
        userIdList: userList,
        userNameList,
        newStatus: ADMINISTRATION_USER_ACTIONS_TO_STATUS[userAction],
    };
    let response = null;
    if (userAction === ADMINISTRATION_USER_ACTIONS.BLOCK || userAction === ADMINISTRATION_USER_ACTIONS.UNBLOCK) {
        response = yield Call(SelectorsMiddleware.changeUsersStatusPreview, params);
    } else if (userAction === ADMINISTRATION_USER_ACTIONS.DELETE) {
        response = yield Call(SelectorsMiddleware.deleteUsersPreview, params);
    }
    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADMINISTRATION_USERS],
            }),
        );
    } else {
        const responseCredentials = yield Call(SelectorsMiddlewareForm.listCredentialsGroups, {
            idForm: EMPTY_STR,
            idActivityToRead: "administration.users.blockunblock.send",
        });
        const credentialGroups = responseCredentials.data.data.groups;
        yield Put(
            SelectorsAction.changeUserStatusPreviewSuccess({ userList, userNameList, userAction, credentialGroups }),
        );

        yield Put(
            SelectorsAction.changeUserStatusConfirmation({ userList, userNameList, userAction, credentialGroups }),
        );
    }
}

function* changeUserStatusConfirmation(props) {
    const { userList, userNameList, userAction, credentialGroups } = props;
    const paramsSend = {
        userIdList: userList,
        userNameList,
    };

    let response = null;

    const credentialsWithUnderscore = credentialsWithUnderscoreFormat(credentialGroups);

    let successMessageSubKey = userAction;

    if (userAction === ADMINISTRATION_USER_ACTIONS.BLOCK || userAction === ADMINISTRATION_USER_ACTIONS.UNBLOCK) {
        response = yield Call(
            SelectorsMiddleware.changeUsersStatusConfirmation,
            { ...paramsSend, newStatus: ADMINISTRATION_USER_ACTIONS_TO_STATUS[userAction] },
            credentialsWithUnderscore,
        );
        successMessageSubKey = ADMINISTRATION_USER_ACTIONS_TO_STATUS[userAction];
    } else if (userAction === ADMINISTRATION_USER_ACTIONS.DELETE) {
        response = yield Call(SelectorsMiddleware.deleteUsersConfirmation, paramsSend, credentialsWithUnderscore);
    }

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADMINISTRATION],
            }),
        );
    } else {
        if (response.data.code && response.data.code === INSUFFICIENT_SIGNATURES) {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.ADMINISTRATION],
                }),
            );
        } else {
            let successMessageKey = "administration.users.blockunblock.success";

            if (userList.length > 1) {
                successMessageKey = `${successMessageKey}.plural`;
            }
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get(successMessageKey),
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.ADMINISTRATION],
                }),
            );
            yield Put(SelectorsAction.changeUserStatusConfirmationSuccess());
        }
        yield Put(SelectorsAction.loadListRequest());
    }
}

function* updateDispatcher(props) {
    const {
        data: { dispatcher, userId, credentials, ...rest },
        formikBag: { setSubmitting, setErrors },
    } = props;

    const credentialsWithUnderscore = credentialsWithUnderscoreFormat(credentials);

    const response = yield Call(SelectorsMiddleware.updateDispacther, {
        ...rest,
        ...credentialsWithUnderscore,
        userId,
        dispatcher,
    });

    setSubmitting(false);

    if (response.type === RESPONSE_TYPE.WARNING) {
        setErrors(response.data.data);
        const errorMessage = response.data.message || UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY);
        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADMINISTRATION],
            }),
        );
        yield Put(SelectorsAction.updateDispatcherFailure());
    } else {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.message,
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.ADMINISTRATION],
            }),
        );
        yield Put(RouterActions.goBack());
        yield Put(SelectorsAction.updateDispatcherSuccess({ data: response.data.data.userExtendedInfo }));
    }
}

function* resendInviteRequest(props) {
    const { invitationCodeId } = props;

    const response = yield Call(SelectorsMiddleware.resendInviteRequest, { invitationCodeId });

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.data.NO_FIELD || UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADMINISTRATION_USERS],
            }),
        );
    } else {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.message,
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.ADMINISTRATION_USERS],
            }),
        );
        yield Put(SelectorsAction.loadListRequest());
    }
}
