import React from "react";

import { EMPTY_STR, NO_DATA } from "~/constants/index";
import * as UtilsConfig from "~/util/config";
import { generateId as GenerateId } from "~/util/general";
import * as i18n from "~/util/i18n";

import DataNumber from "~/components/DataNumber";
import GridLayout from "~/components/GridLayout";
import I18n from "~/components/I18n";

import Style from "./NotificationItem.rules.scss";

export const { NAME, NAME_MESSAGES } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

const tableHeadersProducts = ["description", "value", "expirationDate"];
const tableHeadersDocuments = ["description", "expirationDate"];

export class Component extends React.Component {
    getProductItems = () => {
        const { productItems = [] } = this.props;

        return productItems.map((item) => (
            <GridLayout className="body position-clickable" key={GenerateId()}>
                <div>{item.description}</div>
                <DataNumber value={item.value} prefix={item.currency} className="position-amount" />

                <div className="position-amount">{item.expirationDateFromBackend}</div>
            </GridLayout>
        ));
    };

    getDocumentsItems = () => {
        const { documentsItems = [] } = this.props;
        const documentsKeys = Object.keys(documentsItems);

        return documentsKeys.map((documentKey) => (
            <GridLayout className="body position-clickable" key={GenerateId()}>
                <div>
                    <I18n id={`wm.documentsAboutToExpire.${documentKey}`} />
                </div>
                <div className="position-amount">{NO_DATA}</div>
            </GridLayout>
        ));
    };

    render() {
        const { documentsItems, fundsPermission, otherInvestmentsPermission, productItems } = this.props;
        const fillersProducts = {
            param1: productItems.length,
            param2: UtilsConfig.get(`wm.position.daysBeforeExpirationDateAlert`, 5),
        };
        const documentItemsQuantity = Object.keys(documentsItems).length;

        return (
            <section>
                <div>
                    {/* Products about to expire */}
                    <div className="product-notification-quantity">
                        {productItems.length === 1
                            ? i18n.get("wm.productsAboutToExpire.message", null, fillersProducts)
                            : i18n.get("wm.productsAboutToExpire.message.plural", null, fillersProducts)}
                    </div>
                    <GridLayout className="header">
                        {tableHeadersProducts.map((heading, index) => (
                            <div key={GenerateId()} className={index !== 0 ? "position-amount" : EMPTY_STR}>
                                {i18n.get(`wm.notifications.${heading}`)}
                            </div>
                        ))}
                    </GridLayout>
                    {this.getProductItems()}

                    {/* Documents about to expire */}
                    {(fundsPermission || otherInvestmentsPermission) && documentItemsQuantity > 0 && (
                        <React.Fragment>
                            <div className="product-notification-quantity">
                                {documentItemsQuantity === 1
                                    ? i18n.get("wm.documentsAboutToExpire.message", null, {
                                          param1: documentItemsQuantity,
                                      })
                                    : i18n.get("wm.documentsAboutToExpire.message.plural", null, {
                                          param1: documentItemsQuantity,
                                      })}
                            </div>
                            <GridLayout className="header">
                                {tableHeadersDocuments.map((heading, index) => (
                                    <div key={GenerateId()} className={index !== 0 ? "position-amount" : EMPTY_STR}>
                                        {i18n.get(`wm.notifications.${heading}`)}
                                    </div>
                                ))}
                            </GridLayout>
                            {this.getDocumentsItems()}
                        </React.Fragment>
                    )}
                </div>
            </section>
        );
    }
}

export default Component;
