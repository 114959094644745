import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import {
    SelectorsAction as SelectorsActionComexExport,
    SelectorsStore as SelectorsStoreComexExport,
} from "~/store/comex/export";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import HighOrder from "~/components/HighOrder";

import Step1Edit from "./Step1Edit/Step1Edit";
import Step2Preview from "./Step2Preview/Step2Preview";
import Step3View from "./Step3View/Step3View";

export const NAME = "CessionCreditLetter";

export const PROP = {
    types: {
        fetching: PropTypes.bool.isRequired,
        setFieldValue: PropTypes.func.isRequired,
        validateForm: PropTypes.func.isRequired,
        ...TypesRedux,
    },
    defaults: {
        ...DefaultsRedux,
    },
};

const FORM_ID = "comex.export.cessionCreditLetter";

export const CREDENTIAL_TYPE_OTP = "otp";

export function Component(props) {
    const { fetching, mode } = props;

    function handleBack() {
        const { dispatch, history } = props;

        if (mode === MODE.VIEW) {
            dispatch(SelectorsActionComex.restartFlow());
            dispatch(SelectorsActionComexExport.restartFlow());
        } else if (mode === MODE.EDIT) {
            history.goBack();
        } else {
            dispatch(SelectorsActionComex.toStepOne());
        }
    }

    return (
        <Container
            className="CreditLetter"
            head-onBack={handleBack}
            head-title={`${FORM_ID}.title`}
            image="businessPortfolio.svg"
            name={NAME}
            scopeToShowNotification={SCOPE.COMEX_EXPORT_CESSION_CREDIT_LETTER}
            step={mode}
            wait={fetching}>
            {mode === MODE.EDIT && <Step1Edit />}
            {mode === MODE.PREVIEW && <Step2Preview />}
            {mode === MODE.VIEW && <Step3View ticketConfirmation />}
        </Container>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    fetching: SelectorsStoreComexExport.getFetching(store),
    mode: SelectorsStoreComexExport.getMode(store),
});

export default HighOrder(Connect(mapStateToProps))(Component);
