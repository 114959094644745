import React from "react";

import { push as Push } from "connected-react-router";
import { Field, Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import { EMPTY_STR, INVITATION_CODE_GROUP_LENGTH } from "~/constants";
import Container from "~/containers/External/Form";
import {
    SelectorsAction as SelectorsActionRecoverPassword,
    SelectorsStore as SelectorsStoreRecoverPassword,
} from "~/store/recoverypassword";
import * as UtilsConfig from "~/util/config";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import TextField from "~/pages/_components/fields/TextField";

import Style from "./RecoveryPass.scss";

export const NAME = "RecoveryPassStep2";
const { CLASS } = Style;

const FORM_ID = "recoveryPassword.step2";

export const PROP = {
    types: {
        isFetching: PropTypes.bool,
    },
    defaults: {
        isFetching: false,
    },
};

export function Component(props) {
    const { isFetching, dispatch } = props;
    const groupLength = UtilsConfig.getInteger("validationCodes.id.groups.length");
    const separators = groupLength - 1;
    const codeLength = groupLength * INVITATION_CODE_GROUP_LENGTH + separators;

    const onHeaderClose = () => {
        dispatch(Push("/"));
    };

    const onResendCode = () => {
        dispatch(SelectorsActionRecoverPassword.recoverPassCleanUp());
        dispatch(Push("/recoveryPass"));
    };

    return (
        <Container
            icon={<Image src="administration-title-icon.svg" />}
            id={NAME}
            head-title={`${FORM_ID}.pageTitle`}
            name={NAME}
            head-onClose={onHeaderClose}
            className={CLASS}
            wait={isFetching}>
            <section>
                <p>
                    <I18n id={`${FORM_ID}.intro`} />
                </p>
                <Button className="link" onClick={() => onResendCode()}>
                    <I18n id={`${FORM_ID}.resendCode`} />
                </Button>
            </section>
            <Form>
                <div>
                    <Field
                        component={TextField}
                        id="verificationCode"
                        idForm={FORM_ID}
                        maxLength={codeLength}
                        name="verificationCode"
                        type="text"
                        autoComplete="off"
                    />
                </div>
                <div>
                    <Button className="primary" type="submit">
                        {UtilsI18n.get("global.continue")}
                    </Button>
                </div>
            </Form>
        </Container>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    isFetching: SelectorsStoreRecoverPassword.isFetching(store),
});

export default Compose(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            verificationCode: EMPTY_STR,
        }),
        validationSchema: () =>
            Yup.lazy(() => {
                return Yup.object().shape({
                    verificationCode: Yup.string().required(UtilsI18n.get(`${FORM_ID}.verificationCode.empty`)),
                });
            }),
        handleSubmit: (data, formikBag) => {
            const { dispatch } = formikBag.props;
            const { verificationCode } = data;

            dispatch(SelectorsActionRecoverPassword.recoverPassStep2Request({ formikBag, verificationCode }));
        },
    }),
)(Component);
