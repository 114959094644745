import React from "react";

import { ResponsiveBar } from "@nivo/bar";
import ClassNames from "classnames";
import PropTypes from "prop-types";

import * as UtilsI18n from "~/util/i18n";

import Namespace from "~/components/BarChart/Chart.scss";
import Button from "~/components/Button/Button";
import Element from "~/components/Component";
import DataNumber from "~/components/DataNumber";

export const { CLASS, NAME, TYPE, TAG, COLOR } = Namespace;

export const PROP = {
    types: {
        tag: PropTypes.string,
        sortedData: PropTypes.object,
        indexBy: PropTypes.string,
        keys: PropTypes.array,
    },
    defaults: {},
};
/**
 * Renders a bar chart of given list filtered by currency.
 * @param {string} tag Main element wrapper, section is used as default
 * @param {object} sortedData An object with a list of items, indexed by its currency. eg:
 * sortedData: {
 *  currency1: [indexBy: "something", key1: "test1", key2: "test2"]
 *  currency2: [indexBy: "anotherThing", key1: "test1", key2: "test2"]
 * }
 * @param {string} indexBy Property that groups data
 * @param {array} keys Array of names of the properties that form a group of data
 */
export function Component(props) {
    const { tag, sortedData, indexBy, keys } = props;

    const [selectedCurrency, setSelectedCurrency] = React.useState(Object.keys(sortedData)[0]);
    const [toggleChart, setToggleChart] = React.useState(true);

    React.useEffect(() => {
        setSelectedCurrency(Object.keys(sortedData)[0]);
    }, [sortedData]);

    function getMaxValue(preppedData) {
        const reducer = (prev, { weekOne, weekTwo, weekThree, weekFour }) =>
            Math.max(prev, weekOne, weekTwo, weekThree, weekFour);
        const maxValue = preppedData.reduce(reducer, 0);

        let padding;
        if (maxValue <= 10) {
            padding = 10;
        } else if (maxValue > 10 && maxValue <= 1000) {
            padding = 100;
        } else if (maxValue > 10000 && maxValue <= 10000) {
            padding = 1000;
        } else if (maxValue > 10000 && maxValue <= 100000) {
            padding = 5000;
        } else {
            padding = 50000;
        }
        return maxValue + padding;
    }
    const maxValue = sortedData[selectedCurrency] ? getMaxValue(sortedData[selectedCurrency]) : null;

    return (
        <Element name={NAME} tag={tag || TAG} type={TYPE}>
            <div className="toggleChart">
                <Button
                    className={ClassNames({ "is-open": toggleChart })}
                    onClick={() => {
                        setToggleChart(!toggleChart);
                    }}
                />
            </div>
            <div className={ClassNames({ "is-open": toggleChart }, "chart")}>
                <div className="currencySelector">
                    {Object.keys(sortedData).map((currency) => (
                        <span
                            key={currency}
                            className={ClassNames({ selected: currency === selectedCurrency })}
                            onClick={() => {
                                setSelectedCurrency(currency);
                            }}>
                            {UtilsI18n.get(`currency.label.${currency}`)}
                        </span>
                    ))}
                </div>
                {sortedData[selectedCurrency] && (
                    <ResponsiveBar
                        data={sortedData[selectedCurrency]}
                        keys={keys}
                        indexBy={indexBy}
                        margin={{ top: 10, right: 0, bottom: 80, left: 50 }}
                        padding={0.5}
                        innerPadding={10}
                        groupMode="grouped"
                        colors={COLOR}
                        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                        axisLeft={{
                            tickValues: 5,
                            tickSize: 0,
                        }}
                        axisBottom={{
                            tickSize: 0,
                        }}
                        enableLabel={false}
                        animate
                        tooltip={({ value, indexValue, id }) => (
                            <div>
                                <strong>{indexValue}</strong>
                                <br />
                                <span>
                                    {UtilsI18n.get(`factoring.barChart.tooltip.${id}`)}
                                    <strong className="tooltip-amount">
                                        <DataNumber value={value} />
                                    </strong>
                                </span>
                            </div>
                        )}
                        motionStiffness={90}
                        motionDamping={13}
                        maxValue={maxValue}
                    />
                )}
            </div>
        </Element>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
