import React from "react";

import PropTypes from "prop-types";

import { get as Get } from "~/util/i18n";

import Namespace from "./I18n.scss";

export const { NAME, TYPE } = Namespace;

export const PROP = {
    types: {
        /** The identifier for the message */
        id: PropTypes.string.isRequired,
        /** The fallback value in case the id is not found on the database */
        defaultValue: PropTypes.string,
        className: PropTypes.string,
        tag: PropTypes.string,
    },
    defaults: {
        defaultValue: null,
        className: undefined,
        tag: "span",
    },
};

export const FILTERED_PROPS = ["dispatch"];

/**
 * Internationalization Component.
 *
 * Renders a <span /> containing a message defined on the database using the current language.
 * @param {PROP.types} props
 */
export function Component(props) {
    const { id, defaultValue, className, tag: Tag, ...rest } = props;
    // if more props are sent, send them as argument to the Get method (filtering specified).
    const text = Get(
        id,
        defaultValue,
        Object.keys(rest)
            .filter((key) => FILTERED_PROPS.indexOf(key) === -1)
            .reduce((acc, key) => ({ ...acc, [key]: rest[key] }), {}),
    );
    return <Tag data-name={NAME} data-type={TYPE} className={className} dangerouslySetInnerHTML={{ __html: text }} />;
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
