import { createStore as CreateStore, compose as Compose } from "redux";
import { END } from "redux-saga";

import { NODE_ENV, NODE_ENV_PRODUCTION } from "~/constants";
import Logger from "~/util/logger";

import _Middleware, { Saga as MiddlewareSaga } from "./_middleware";
import _Reducer from "./_reducer";
import _Sagas from "./_sagas";

export const Middleware = _Middleware;

export const Sagas = _Sagas;

export const Reducer = _Reducer;

const { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: RDEC } = window;
const compose = !RDEC ? Compose : RDEC({ trace: true, traceLimit: 25 });

export const Store =
    NODE_ENV === NODE_ENV_PRODUCTION ? CreateStore(Reducer, Middleware) : CreateStore(Reducer, compose(Middleware));

Store.close = () => Store.dispatch(END);

MiddlewareSaga.run(Sagas)
    .toPromise()
    .catch((err) => Logger.error("SagaError: %o", err, "[Store]"));

export default Store;
