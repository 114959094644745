import React from "react";

import ClassNames from "classnames";
import { push as Push } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import { compose as Compose } from "redux";

import { CDP_CURRENCY, EMPTY_STR, LEVEL, SCOPE } from "~/constants";
import { STATUS } from "~/constants/transaction";
import RouteTicket from "~/store/form/_sagas/_routeTicket";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import {
    SelectorsAction as SelectorsActionTransactions,
    SelectorsStore as SelectorsStoreTransactions,
} from "~/store/transactions";
import * as UtilsConfig from "~/util/config";
import { differenceInCalendarDays, stringToDate } from "~/util/date";
import * as UtilI18n from "~/util/i18n";
import { Types as TypesRedux } from "~/util/prop/redux";

import DataNumber from "~/components/DataNumber";
import GridLayout from "~/components/GridLayout";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import ChevromRight from "~/pages/_components/listItem/ChevromRight";

import Checkbox from "~/pages/forms/_components/_fields/_commons/Checkbox";
import TransactionStatus from "~/pages/transactions/_components/TransactionStatus";

export const NAME = "TransactionsItem";

export const PROP = {
    types: {
        ...TypesRedux,
        approve: PropTypes.bool,
        isDeletingDraft: PropTypes.bool,
        itemRef: PropTypes.func,
        onClickApproval: PropTypes.func,
        transaction: PropTypes.shape({
            transaction: PropTypes.shape({
                activityName: PropTypes.string.isRequired,
                fullCreationDateTimeAsString: PropTypes.string.isRequired,
                idActivity: PropTypes.string.isRequired,
                idTransactionStatus: PropTypes.string.isRequired,
                shortDescriptionForMobile: PropTypes.string.isRequired,
                signaturesLabel: PropTypes.string.isRequired,
                statusLabel: PropTypes.string.isRequired,
                valueDateAsString: PropTypes.string.isRequired,
                valueDateLabel: PropTypes.string.isRequired,
            }).isRequired,
            cancelEnabled: PropTypes.bool.isRequired,
            editableInMobile: PropTypes.bool.isRequired,
        }).isRequired,
    },

    defaults: {
        approve: false,
        isDeletingDraft: false,
        itemRef: () => {},
        onClickApproval: () => {},
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {
        isChecked: false,
    };

    componentDidMount() {
        const { approve } = this.props;

        this.setState({ isChecked: approve });
    }

    handleDeleteTransactionDraft = (event, idTransaction) => {
        event.stopPropagation();
        event.preventDefault();

        const { dispatch } = this.props;

        dispatch(SelectorsActionTransactions.deleteDraftRequest({ idTransactionToDelete: idTransaction }));
    };

    handleCancelTransaction = (event, idTransaction) => {
        event.stopPropagation();
        event.preventDefault();

        const { dispatch } = this.props;

        dispatch(Push(`/form/requestTransactionCancellation?referenceToCancel=${idTransaction}`));
    };

    handleCheckTransaction = (idTransaction) => {
        const { onClickApproval } = this.props;
        const { isChecked } = this.state;

        onClickApproval(idTransaction);

        this.setState({ isChecked: !isChecked });
    };

    getImage = (transactionStatus) => {
        let imgName;

        switch (transactionStatus) {
            case STATUS.FINISHED:
                imgName = "ok";

                break;
            case STATUS.DRAFT:
                imgName = "draft_1";

                break;
            case STATUS.SCHEDULED:
                imgName = "schedulet";

                break;
            case STATUS.CANCELLED:
            case STATUS.FAILED:
                imgName = "alertIconError";

                break;
            case STATUS.PROCESSING:
                imgName = "alertIconCron";

                break;
            case STATUS.ACCEPTED:
                imgName = "process";

                break;
            default:
                imgName = "alertIconMedium";

                break;
        }

        return `${imgName}.svg`;
    };

    getClassImage = (transactionStatus) => {
        if (transactionStatus === STATUS.DRAFT) {
            return "transaction-status gray";
        }

        if (transactionStatus === STATUS.SCHEDULED) {
            return "transaction-status gray";
        }

        return "transaction-status";
    };

    onClick = (event) => {
        const { dispatch, isMobile, transaction: transactionReceived } = this.props;
        const { editableInMobile, transaction } = transactionReceived;
        const { idTransactionStatus } = transaction;

        const isNotEditable =
            isMobile &&
            !editableInMobile &&
            (idTransactionStatus === STATUS.DRAFT || idTransactionStatus === STATUS.RETURNED);

        if (isNotEditable) {
            dispatch(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get("transactions.trasactionItem.isNotEditable"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.TRANSACTIONS],
                }),
            );

            event.preventDefault();
        } else {
            dispatch(SelectorsActionTransactions.selectedTransaction());
        }
    };

    handleTracking = (event) => {
        const { dispatch, transaction: transactionReceived } = this.props;
        const { transaction } = transactionReceived;
        const { idTransaction } = transaction;

        event.preventDefault();
        event.stopPropagation();

        dispatch(SelectorsActionTransactions.gpiTracking({ idTransactionToRead: idTransaction }));
    };

    handleTrackingEnvironment = () => {
        const { productGroupId } = this.props;
        const availableEnvironments = UtilsConfig.getArray("client.gpi.tracking.enabled.environments");

        return availableEnvironments.length === 0 || availableEnvironments.includes(productGroupId);
    };

    handleTrackIcon = (idTransactionStatus, idForm, valueDateAsString) => {
        if (this.handleTrackingEnvironment()) {
            if (idTransactionStatus !== STATUS.FINISHED) {
                return false;
            }

            const transactionIdFormsToTrack = UtilsConfig.getArray("client.gpi.tracking.transaction.idForm.filter");

            if (!idForm || !transactionIdFormsToTrack.includes(idForm)) {
                return false;
            }

            const today = new Date();
            const daysBefore = parseInt(UtilsConfig.get("client.gpi.tracking.daysBefore"), 0);
            const dateAsDate = stringToDate(valueDateAsString);
            const difference = differenceInCalendarDays(today, dateAsDate);

            if (difference <= daysBefore) {
                return true;
            }

            return false;
        }

        return false;
    };

    getStatus = (statusLabel, signaturesLabel, idTransactionStatus) => {
        const { isMobile } = this.props;

        return (
            <React.Fragment>
                <div className="status transaction-status-display">
                    <Image
                        src={this.getImage(idTransactionStatus)}
                        className={this.getClassImage(idTransactionStatus)}
                    />
                    <span>{statusLabel}</span>
                </div>
                {!isMobile && signaturesLabel && (
                    <React.Fragment>
                        <div>{signaturesLabel}</div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    };

    render() {
        const { administrationScheme, isMobile, itemRef, location, transaction: transactionReceived } = this.props;
        const { editableInMobile, transaction } = transactionReceived;

        const {
            activityName,
            executing,
            fullCreationDateTimeAsString,
            idActivity,
            idForm,
            idTransaction,
            idTransactionStatus,
            shortDescriptionForMobile,
            signaturesLabel,
            statusDescription,
            statusLabel,
            valueDateAsString,
            valueDateLabel,
        } = transaction;

        const { transactionAmounts } = transactionReceived;
        const { amountData, secondAmountData } = transactionAmounts;
        const { amount } = amountData;
        let { currency } = amountData;

        currency = Number.isNaN(Number(currency)) ? CDP_CURRENCY[currency] : currency;

        const showAmount = amount !== 0 && amount !== -1;
        const { isChecked } = this.state;

        const isNotEditable =
            isMobile &&
            !editableInMobile &&
            (idTransactionStatus === STATUS.DRAFT || idTransactionStatus === STATUS.RETURNED);

        let to = EMPTY_STR;

        if (idForm) {
            to = isNotEditable ? undefined : `/transaction/${idTransaction}`;
        } else {
            to = isNotEditable ? undefined : RouteTicket[idActivity](idTransaction, administrationScheme);
        }

        const content = (
            <React.Fragment>
                <div className="table-data table-data-icon">
                    <TransactionStatus idTransactionStatus={idTransactionStatus} showLabel={false} />
                    {statusDescription && (
                        <span className="data-date">
                            <I18n id={statusDescription} />
                        </span>
                    )}
                </div>
                <div className="table-data">
                    <span className="data-text">
                        <I18n id={`activities.${idActivity}`} />
                    </span>
                    {!isMobile && shortDescriptionForMobile !== null && shortDescriptionForMobile !== EMPTY_STR && (
                        <span className="data-date">
                            <span className="content" dangerouslySetInnerHTML={{ __html: shortDescriptionForMobile }} />
                        </span>
                    )}
                </div>
                <div className="table-data table-data-date">
                    {valueDateLabel && valueDateAsString ? (
                        <div>
                            {valueDateLabel} {valueDateAsString}
                        </div>
                    ) : undefined}
                    {showAmount && <FormattedAmount currency={currency} quantity={amount} />}
                    {secondAmountData && (
                        <DataNumber value={secondAmountData.amount} prefix={secondAmountData.currency} />
                    )}
                </div>
                <div className="table-data data-status">
                    {this.getStatus(statusLabel, signaturesLabel, idTransactionStatus)}
                </div>
                <ChevromRight />
                {this.handleTrackIcon(idTransactionStatus, idForm, valueDateAsString) && (
                    <React.Fragment>
                        <div className="tracking-button-container" onClick={this.handleTracking}>
                            <div className="tracking-button">
                                <Image src="search.svg" />
                            </div>
                            <I18n id="client.transactions.history.tracking.transfer" />
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );

        return (
            <div className="flex-display">
                {idTransactionStatus === STATUS.PENDING && !executing && (
                    <div className={ClassNames("table-data", { "checkbox-item": isMobile })}>
                        <Checkbox checked={isChecked} onChange={() => this.handleCheckTransaction(idTransaction)} />
                    </div>
                )}
                <Link
                    to={{ pathname: to, state: { from: location.pathname } }}
                    className="table-row"
                    innerRef={itemRef}
                    onClick={this.onClick}>
                    {isMobile ? (
                        <React.Fragment>{content}</React.Fragment>
                    ) : (
                        <GridLayout className={this.handleTrackingEnvironment() ? "body tracking" : "body"}>
                            <div className="transaction-list-data">
                                <div className="title">{activityName}</div>
                                <div>{shortDescriptionForMobile}</div>
                            </div>
                            <div className="transaction-list-data">
                                <div className="date">{fullCreationDateTimeAsString}</div>
                                {valueDateLabel && valueDateAsString ? (
                                    <div>
                                        {valueDateLabel} {valueDateAsString}
                                    </div>
                                ) : undefined}
                            </div>
                            <div className="align-right transaction-list-data">
                                {showAmount && <DataNumber value={amount} prefix={currency} />}
                                {secondAmountData && (
                                    <DataNumber value={secondAmountData.amount} prefix={secondAmountData.currency} />
                                )}
                            </div>
                            <div className="transaction-list-data transaction-list-data-status">
                                {this.getStatus(statusLabel, signaturesLabel, idTransactionStatus)}
                            </div>
                            {this.handleTrackIcon(idTransactionStatus, idForm, valueDateAsString) && (
                                <div className="transaction-list-data align-center">
                                    <div className="tracking-button" onClick={this.handleTracking}>
                                        <Image className="tracking-icon" src="search.svg" />
                                    </div>
                                </div>
                            )}
                        </GridLayout>
                    )}
                </Link>
            </div>
        );
    }
}

const mapStateToProps = (store) => ({
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
    administrationScheme: SelectorsStoreSession.getAdministrationScheme(store),
    isDeletingDraft: SelectorsStoreTransactions.isDeletingDraft(store),
    i18n: {
        cleanFiltersLabel: StoreI18n.getMessage(store, "product.filters.cleanFilters"),
    },
    productGroupId: SelectorsStoreSession.getProductGroupId(store),
});

export default Compose(WithRouter, Connect(mapStateToProps))(Component);
