import { takeLatest as TakeLatest, call as Call, put as Put, select as Select } from "redux-saga/effects";

import { LEVEL, SCOPE, RESPONSE_TYPE, GLOBAL_UNEXPECTED_ERROR_KEY } from "~/constants";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { downloadXls as DownloadXls } from "~/util/download";
import * as i18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsStore, SelectorsMiddleware } from "./_selectors";

export default [
    TakeLatest(TYPE.LOAD_LIST_PAYMENT_ORDERS_REQUEST, listPaymentOrdersRequest),
    TakeLatest(TYPE.LOAD_LIST_SUPPLIERS_REQUEST, listSuppliers),
    TakeLatest(TYPE.EXPORT_PAYMENT_ORDERS_REQUEST, exportPaymentOrdersRequest),
];

function* listPaymentOrdersRequest(props) {
    const { isExport = false } = props;

    const filters = yield Select(SelectorsStore.getFilters);
    const moreOrders = yield Select(SelectorsStore.getMoreOrders);
    const offset = moreOrders ? yield Select(SelectorsStore.getOffset) : 0;

    const response = yield Call(SelectorsMiddleware.listPaymentOrdersRequest, { filters, offset, isExport });

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.listPaymentOrdersFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: i18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                level: LEVEL.ERROR,
                scopes: [SCOPE.PAYMENT_ORDERS],
            }),
        );
    } else {
        const { data } = response.data;
        const paymentOrderList = yield Select(SelectorsStore.getPaymentOrders);
        data.paymentOrderList = moreOrders ? paymentOrderList.concat(data.paymentOrderList) : data.paymentOrderList;
        data.nextOffset = data.moreLines ? data.nextOffset : 0;

        yield Put(SelectorsAction.listPaymentOrdersSuccess(data));
    }
}

function* listSuppliers({ filters }) {
    let selectedFilter = filters;
    let selectedPage = 1;
    if (!selectedFilter) {
        const filterSupplier = yield Select(SelectorsStore.getFiltersSuppliers);
        const pageNumber = yield Select(SelectorsStore.getPageNumber);
        selectedFilter = filterSupplier;
        selectedPage = pageNumber;
    }
    const response = yield Call(SelectorsMiddleware.listSuppliersRequest, {
        pageNumber: selectedPage,
        filters: selectedFilter,
    });

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.listSuppliersFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: i18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                level: LEVEL.ERROR,
                scopes: [SCOPE.SUPPLIERS],
            }),
        );
    } else {
        const { data } = response.data;
        yield Put(SelectorsAction.listSuppliersSuccess(data));
    }
}

function* exportPaymentOrdersRequest(props) {
    const { isExport = true } = props;

    const filters = yield Select(SelectorsStore.getFilters);
    const offset = yield Select(SelectorsStore.getOffset);

    const response = yield Call(SelectorsMiddleware.exportPaymentOrdersRequest, {
        filters,
        offset,
        isExport,
    });

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.exportPaymentOrdersFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: i18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                level: LEVEL.ERROR,
                scopes: [SCOPE.SUPPLIERS],
            }),
        );
    } else {
        const { content, fileName } = response.data.data;
        DownloadXls(fileName, content);
        yield Put(SelectorsAction.exportPaymentOrdersSuccess());
    }
}
