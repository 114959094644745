import React from "react";

import ClassNames from "classnames";
import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { MODE } from "~/constants/form";
import { DEVICE_MOBILE } from "~/util/device";

import Box from "~/components/Box";
import HighOrder from "~/components/HighOrder";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import withFocus from "~/pages/_components/withFocus";

import Style from "./TickerField.rules.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {
        field: PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.any,
        }).isRequired,
        form: PropTypes.shape({
            setFieldValue: PropTypes.func.isRequired,
            touched: PropTypes.object.isRequired,
            errors: PropTypes.shape({
                environments: PropTypes.shape({
                    environmentType: PropTypes.string,
                    idEnvironment: PropTypes.string,
                    name: PropTypes.string,
                }),
                selectEnvironment: PropTypes.bool,
            }),
        }).isRequired,
        isDesktop: PropTypes.bool,
        mobileOS: PropTypes.string,
        mode: PropTypes.string,
    },
    defaults: {
        mode: MODE.EDIT,
        mobileOS: EMPTY_STR,
        isDesktop: false,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        window.addEventListener("resize", this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    }

    onResize = () => {
        const { isFocused, isDesktop, mobileOS } = this.props;
        if (isFocused && !isDesktop && mobileOS === DEVICE_MOBILE.ANDROID) {
            this.node.scrollIntoView({
                block: "center",
                behavior: "smooth",
            });
        }
    };

    handleOnChange = ({ target }, fieldtype) => {
        const { field, form } = this.props;
        const { value } = target;
        form.setFieldValue(`${field.name}.${fieldtype}`, value);
    };

    render() {
        const {
            field,
            form: { touched, errors },
            idForm,
            toggleIsFocused,
            isFocused,
            onKeyDown,
            mode,
            value,
            tooltip,
            ...props
        } = this.props;

        const hasError = touched[field.name] && errors[field.name];

        return (
            <Box
                ref={(node) => {
                    this.node = node;
                }}
                className={ClassNames("form-group", CLASS, { "has-error": hasError }, { "has-focus": isFocused })}>
                <FieldLabel labelKey={`${idForm}.${field.name}.label`} />
                {mode === MODE.EDIT ? (
                    <Box flex className="my-2">
                        <input
                            className="ticker-1"
                            {...field}
                            {...props}
                            name="ticker1"
                            type="text"
                            onFocus={toggleIsFocused}
                            value={field.value.ticker1}
                            maxLength={5}
                            onChange={(e) => this.handleOnChange(e, "ticker1")}
                            onKeyDown={onKeyDown}
                        />
                        <input
                            className="ticker-2"
                            {...field}
                            {...props}
                            name="ticker2"
                            type="text"
                            onFocus={toggleIsFocused}
                            maxLength={2}
                            value={field.value.ticker2}
                            onChange={(e) => this.handleOnChange(e, "ticker2")}
                            onKeyDown={onKeyDown}
                        />
                        {tooltip && (
                            <div className="tooltip">
                                <span className="tooltip-text"> {tooltip}</span>
                            </div>
                        )}
                    </Box>
                ) : (
                    <Box>{value}</Box>
                )}

                {hasError && <FieldError error={errors[field.name]} />}
            </Box>
        );
    }
}

export default HighOrder(HighOrder.Resizable, withFocus)(Component);
