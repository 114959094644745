import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { MODE } from "~/constants/form";

import I18n from "~/components/I18n";

export const NAME = "FieldLabel";

export const PROP = {
    types: {
        id: PropTypes.string,
        labelKey: PropTypes.string,
        labelText: PropTypes.string,
        mode: PropTypes.string,
        optional: PropTypes.string,
        useColon: PropTypes.bool,
    },
    defaults: {
        id: EMPTY_STR,
        mode: MODE.EDIT,
        labelKey: EMPTY_STR,
        labelText: EMPTY_STR,
        optional: EMPTY_STR,
        useColon: false,
    },
};

export function Component(props) {
    const { id, labelKey, labelText, mode, optional, useColon, classContainer = "" } = props;

    if (mode === MODE.EDIT) {
        return (
            <div className={`${classContainer} form-group-text`}>
                <label className="control-label" htmlFor={id}>
                    {labelKey ? <I18n id={labelKey} /> : labelText}
                    {useColon ? ":" : EMPTY_STR}
                    {optional && <small className="text-optional">{optional}</small>}
                </label>
            </div>
        );
    }

    return (
        <span className="data-label">
            {labelKey ? <I18n id={labelKey} /> : labelText}
            {useColon ? ":" : EMPTY_STR}
        </span>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
