import { FactoryAction } from "~/util/redux";

import { NAME, TYPE } from "./_consts";

export const SelectorsAction = {
    setShowAboutIbancaModal: (props) => FactoryAction(TYPE.SHOW_ABOUT_IBANCA_MODAL, props),
};

export const SelectorsStore = {
    getShowAboutIbancaModal: (store) => store[NAME].showAboutIbancaModal,
};
