import PropTypes from "prop-types";

import { MODE } from "~/constants/form";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "administrationTicket";

export const TYPE = FactoryType(NAME, [
    "CANCEL_ADMINISTRATIVE_TRANSACTION",
    "CANCEL_PREVIEW_STEP",
    "LOAD_ADMINISTRATION_TICKET_FAILURE",
    "LOAD_ADMINISTRATION_TICKET_REQUEST",
    "LOAD_ADMINISTRATION_TICKET_SUCCESS",
    "SIGN_ADMINISTRATIVE_TRANSACTION",
    "SIGN_ADMINISTRATIVE_TRANSACTION_PREVIEW_REQUEST",
    "SIGN_ADMINISTRATIVE_TRANSACTION_SUCCESS",
]);

export const PROP = {};

PROP.members = {};

PROP.types = {
    ...TypesRedux,
    data: PropTypes.object,
    fetching: PropTypes.bool,
    userData: PropTypes.object,
};

PROP.defaults = {
    ...DefaultsRedux,
    data: {},
    fetching: true,
    mode: MODE.EDIT,
    userData: null,
};
