import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { FactoryType } from "~/util/redux";

export const NAME = "signatures";

export const TYPE = (name) =>
    FactoryType(name, [
        "CREATE_SIGNATURES_SCHEME_CONFIRM_PRE",
        "CREATE_SIGNATURES_SCHEME_FAILURE",
        "CREATE_SIGNATURES_SCHEME_PRE_FAILURE",
        "CREATE_SIGNATURES_SCHEME_PRE_REQUEST",
        "CREATE_SIGNATURES_SCHEME_PRE_SUCCESS",
        "CREATE_SIGNATURES_SCHEME_REQUEST",
        "CREATE_SIGNATURES_SCHEME_SUCCESS",
        "DELETE_SIGNATURES_SCHEME_CONFIRM_PRE",
        "DELETE_SIGNATURES_SCHEME_FAILURE",
        "DELETE_SIGNATURES_SCHEME_PRE_FAILURE",
        "DELETE_SIGNATURES_SCHEME_PRE_REQUEST",
        "DELETE_SIGNATURES_SCHEME_PRE_SUCCESS",
        "DELETE_SIGNATURES_SCHEME_REQUEST",
        "DELETE_SIGNATURES_SCHEME_SUCCESS",
        "LIST_SIGNATURES_SCHEMES_FAILURE",
        "LIST_SIGNATURES_SCHEMES_REQUEST",
        "LIST_SIGNATURES_SCHEMES_SUCCESS",
        "LOAD_FORM_SUCCESS",
        "LOAD_SIGNATURES_SCHEMES_TICKET_FAILURE",
        "LOAD_SIGNATURES_SCHEMES_TICKET_REQUEST",
        "LOAD_SIGNATURES_SCHEMES_TICKET_SUCCESS",
        "LOAD_SIGNATURE_TICKET_FAILURE",
        "LOAD_SIGNATURE_TICKET_REQUEST",
        "LOAD_SIGNATURE_TICKET_SUCCESS",
        "MODIFY_SIGNATURES_SCHEME_CONFIRM_PRE",
        "MODIFY_SIGNATURES_SCHEME_FAILURE",
        "MODIFY_SIGNATURES_SCHEME_PRE_FAILURE",
        "MODIFY_SIGNATURES_SCHEME_PRE_REQUEST",
        "MODIFY_SIGNATURES_SCHEME_PRE_SUCCESS",
        "MODIFY_SIGNATURES_SCHEME_REQUEST",
        "MODIFY_SIGNATURES_SCHEME_SUCCESS",
        "SIGNATURES_SCHEME_EXPORT_LIST_FAILURE",
        "SIGNATURES_SCHEME_EXPORT_LIST_REQUEST",
        "SIGNATURES_SCHEME_EXPORT_LIST_SUCCESS",
        "SIGNATURES_SCHEME_LOAD_MORE_REQUEST",
        "SIGNATURES_SCHEME_LOAD_MORE_SUCCESS",
    ]);

export const PROP = {};

PROP.members = {};

PROP.types = {
    caps: PropTypes.object,
    credentialGroups: PropTypes.array,
    currencies: PropTypes.array,
    dataToSubmit: PropTypes.object,
    enabledChannels: PropTypes.array,
    enabledChannelsFrequencies: PropTypes.array,
    fetching: PropTypes.bool,
    functionalGroups: PropTypes.array,
    idActivity: PropTypes.any,
    idTransaction: PropTypes.any,
    nonRemovableChannels: PropTypes.array,
    signatureDispatch: PropTypes.bool,
    signatureLevel: PropTypes.object,
    signatureLevelsCounts: PropTypes.object,
    signatureType: PropTypes.string,
    topAmount: PropTypes.object,
};

PROP.defaults = {
    caps: {},
    credentialGroups: [],
    currencies: [],
    dataToSubmit: {},
    enabledChannels: [],
    enabledChannelsFrequencies: [],
    fetching: true,
    functionalGroups: [],
    idActivity: null,
    idTransaction: null,
    nonRemovableChannels: [],
    signatureDispatch: false,
    signatureLevel: null,
    signatureLevelsCounts: {},
    signatureType: EMPTY_STR,
    topAmount: {},
};
