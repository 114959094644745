import React from "react";

import { connect as Connect } from "react-redux";

import { CDP_CURRENCY, PIPE_SEPARATOR, ZERO_NUMBER } from "~/constants";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsStore as StoreWidget, SelectorsAction as ActionWidget } from "~/store/widget";
import UtilsFlag from "~/util/flag";

import DataDate from "~/components/DataDate";
import DataNumber from "~/components/DataNumber";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";
import Slides from "~/components/Slides";
import FormattedAmount from "~/pages/_components/FormattedAmount";

import Style from "./Loans.rules.scss";
import WidgetCard from "./_common/WidgetCard";
import Widget from "./_common/_Widget";

export const { TYPE, NAME } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const { dispatch, isFetching, loans, store: widget, ...rest } = props;
    const expensesUSD = [];
    const expensesUYU = [];

    React.useEffect(() => {
        dispatch(ActionWidget.listRequest({ widget }));
    }, [dispatch, widget]);

    const currencyUSD = loans.find((i) => i.currency === CDP_CURRENCY.USD) ? CDP_CURRENCY.USD : false;
    const currencyUYU = loans.find((i) => i.currency === CDP_CURRENCY.UYU) ? CDP_CURRENCY.UYU : false;

    const sumTotalToPayUSD = loans
        .map((loan) => {
            const { currency, totalDollarPackage, totalExpiredDollarPackages, totalToPay } = loan;

            if (currency === CDP_CURRENCY.USD) {
                const administrativeExpense = totalDollarPackage + totalExpiredDollarPackages;

                expensesUSD.push(administrativeExpense);

                return totalToPay;
            }

            return ZERO_NUMBER;
        })
        .reduce((prev, curr) => prev + curr, 0);

    const sumTotalToPayUYU = loans
        .map((loan) => {
            const { currency, totalExpiredPesosPackages, totalPesosPackage, totalToPay } = loan;

            if (currency === CDP_CURRENCY.UYU) {
                const administrativeExpense = totalPesosPackage + totalExpiredPesosPackages;

                expensesUYU.push(administrativeExpense);

                return totalToPay;
            }

            return ZERO_NUMBER;
        })
        .reduce((prev, curr) => prev + curr, 0);

    const [administrativeExpenseUYU] = expensesUYU;
    const [administrativeExpenseUSD] = expensesUSD;
    const totalLoansToPayUYU = sumTotalToPayUYU + administrativeExpenseUYU;
    const totalLoansToPayUSD = sumTotalToPayUSD + (administrativeExpenseUYU ? ZERO_NUMBER : administrativeExpenseUSD);

    return (
        <Widget
            {...rest}
            id={Style.ID}
            wait={isFetching}
            header-title="menu.loans"
            header-icon="prestamo.svg"
            header-href={!loans.length ? "/form/requestLoan" : "/loans"}>
            {!loans.length ? (
                <Link to="/form/requestLoan" role="banner">
                    <div>
                        <Image src="automobile-loan.svg" />
                        <Image src="consumer-loan.svg" />
                        <Image src="loan-loan.svg" />
                    </div>
                    <I18n id="menu.loans.requestLoan" />
                </Link>
            ) : (
                <React.Fragment>
                    <Slides>
                        {loans.map((card) => {
                            const {
                                idProduct,
                                installmentAmountUI,
                                isExpired,
                                loanType,
                                nextDueDate,
                                number,
                                productAlias,
                                totalToPay,
                            } = card;

                            const image = `${loanType}-loan.svg`;

                            return (
                                <WidgetCard
                                    key={idProduct}
                                    href={`/loans/${idProduct}`}
                                    image={image}
                                    title={`${productAlias} ${number}`}
                                    data-isExpired={isExpired || undefined}>
                                    {[
                                        <DataDate key="loans.list.item.expiration" value={nextDueDate} />,
                                        <DataNumber
                                            key="loans.list.item.nextInstallment"
                                            suffix={installmentAmountUI}
                                            value={totalToPay}
                                        />,
                                    ]}
                                </WidgetCard>
                            );
                        })}
                    </Slides>
                    {loans.length && (
                        <div className="container-head">
                            <span className="label-head">
                                <I18n id="loans.card.title" />
                            </span>
                            <div className="amount-body">
                                {currencyUSD && (
                                    <span>
                                        <Image src={`${UtilsFlag.getFlag(CDP_CURRENCY.USD)}.svg`} />
                                        <FormattedAmount quantity={totalLoansToPayUSD} currency={currencyUSD} />
                                    </span>
                                )}
                                {currencyUSD && currencyUYU && <div className="separator">{PIPE_SEPARATOR}</div>}
                                {currencyUYU && (
                                    <span>
                                        <Image src={`${UtilsFlag.getFlag(CDP_CURRENCY.UYU)}.svg`} />
                                        <FormattedAmount quantity={totalLoansToPayUYU} currency={currencyUYU} />
                                    </span>
                                )}
                            </div>
                            <I18n className="label-footer" id="loans.card.description.widget" />
                        </div>
                    )}
                </React.Fragment>
            )}
        </Widget>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

function mapStateToProps(store, props) {
    const { store: widget } = props;
    const { isFetching, data = [] } = StoreWidget.getWidget(store, widget) || {};

    return {
        isFetching,
        loans: data,
        getMessage: (key) => StoreI18n.getMessage(store, key), // forces i18n re render
    };
}

export default Connect(mapStateToProps)(Component);
