import React from "react";

import { Formik, Field, Form } from "formik";
import { Grid, Row, Col } from "react-bootstrap";
import { connect as Connect } from "react-redux";

import { CREDENTIAL_TYPE_OTP } from "~/constants";
import { PROP as STORE_PROP, OPERATIONS as STORE_OPERATIONS } from "~/store/preferentialTradingPrice";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as UtilsConfig from "~/util/config";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import MainContainer from "~/pages/_components/MainContainer";
import Credential from "~/pages/_components/fields/credentials/Credential";

import FormWaiter from "~/pages/forms/_components/FormWaiter";

import Timer from "../components/Timer";
import Style from "./PreviewFX.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...STORE_PROP.types.previewData,
    },
    defaults: {
        ...STORE_PROP.defaultsPreviewData,
    },
};

export function Component(props) {
    const {
        destinationAccount,
        destinationAmount,
        destinationCurrency,
        errors,
        fetching,
        handleReturn,
        handleSend,
        operation,
        quotation,
        quotationCurrency1,
        quotationCurrency2,
        renderFormWaiter,
        showInProcessDisclaimer,
        sourceAccount,
        sourceAmount,
        sourceCurrency,
        user,
    } = props;

    const timerImage = "cronometro.svg";
    const configTime = UtilsConfig.get("client.preferentialTradingPrice.confirmation.transactionTimer", 10);

    const handlePreviewTimeOut = () => handleReturn();

    const rateCurrency1 = operation === STORE_OPERATIONS.BUYING ? quotationCurrency2 : quotationCurrency1;
    const rateCurrency2 = operation === STORE_OPERATIONS.BUYING ? quotationCurrency1 : quotationCurrency2;
    if (renderFormWaiter) {
        return (
            <MainContainer shouldHideOnLoad form>
                <FormWaiter />
            </MainContainer>
        );
    }

    return (
        <React.Fragment>
            <div id={Style.ID}>
                <section className="container--layout align-items-center flex-grow">
                    <div className="form-content container container-top">
                        <div className="justify-content-center row">
                            <div>
                                <h1>
                                    <Image src={timerImage} className="svg-icon svg-logo timer" />
                                    <Timer
                                        second={configTime}
                                        fetching={fetching}
                                        errors={errors}
                                        handleTimeOut={handlePreviewTimeOut}
                                    />
                                </h1>
                                <h2>{UtilsI18n.get("preferentialTradingPrice.confirmation.timerTitle")}</h2>
                                <p>
                                    <I18n
                                        id="preferentialTradingPrice.confirmation.label"
                                        SOURCE_CURRENCY={sourceCurrency}
                                        SOURCE_AMOUNT={sourceAmount}
                                        RATE={quotation}
                                        CURRENCY1={rateCurrency1}
                                        CURRENCY2={rateCurrency2}
                                        SOURCE_ACCOUNT={sourceAccount}
                                        DESTINATION_CURRENCY={destinationCurrency}
                                        DESTINATION_AMOUNT={destinationAmount}
                                        DESTINATION_ACCOUNT={destinationAccount}
                                    />
                                </p>
                                {showInProcessDisclaimer && (
                                    <div className="container-disclaimer">
                                        <I18n id="preferentialTradingPrice.confirmation.disclaimer" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
                <Formik onSubmit={handleSend}>
                    <Form>
                        {!user.needsBiometric && (
                            <section className="container--layout align-items-center">
                                <Grid className="form-content container--flex-middle">
                                    <Row className="justify-content-center">
                                        <Col>
                                            <I18n id="form.credential.hint" />

                                            <Field
                                                idForm="form.credential"
                                                name="otp"
                                                component={Credential}
                                                type={CREDENTIAL_TYPE_OTP}
                                                className="form-control"
                                                hidePlaceholder
                                            />
                                        </Col>
                                    </Row>
                                </Grid>
                            </section>
                        )}
                        <section className="container--layout align-items-center">
                            <Grid className="form-footer">
                                <Row className="justify-content-center">
                                    <Col>
                                        <Button
                                            type="submit"
                                            label="global.send"
                                            bsStyle="primary"
                                            loading={fetching}
                                        />
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </Form>
                </Formik>
            </div>
        </React.Fragment>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (state) => ({
    user: SelectorsStoreSession.getUser(state),
});

export default Connect(mapStateToProps)(Component);
