import React from "react";

import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";

import { CDP_CURRENCY, COMMA, EMPTY_STR, LEVEL, NOT_ES, ONLY_NUMBER, SCOPE, SPACE_STR, YES_ES } from "~/constants";
import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import {
    SelectorsStore as SelectorsStoreComex,
    PROP as PropComex,
    SelectorsAction as SelectorsActionComex,
} from "~/store/comex";
import {
    SelectorsStore as SelectorsStoreComexExport,
    SelectorsAction as SelectorsActionComexExport,
} from "~/store/comex/export";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsAction as SelectorActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionTemplate } from "~/store/template";
import * as ConfigUtil from "~/util/config";
import { formatDate as FormatDate, toDate as ToDate } from "~/util/date";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import EmailList from "~/pages/_components/fields/EmailList";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TextArea from "~/pages/_components/fields/TextArea";
import TextField from "~/pages/_components/fields/TextField";
import AmountField from "~/pages/_components/fields/formik/AmountField";

import OperationNumber from "~/pages/comEx/_components/OperationNumber";
import OperationNumberDetail from "~/pages/comEx/_components/OperationNumberDetail";
import CreateTemplateModal from "~/pages/forms/Step1Edit/_ModalTemplateCreate";
import ListTemplatesModal from "~/pages/forms/Step1Edit/_ModalTemplateList";
import { Termsandconditions } from "~/pages/forms/_components/_fields";
import Scheduler from "~/pages/forms/_components/_fields/Scheduler";
import Checkbox from "~/pages/forms/_components/_fields/_commons/Checkbox";

import FileUploader from "../../../_components/FileUploader";
import { FORM_ID, FIELD_NAME, ID_ACTIVITY_SEND, i18nMap } from "../FormConstants";
import AddDocument from "./AddDocument";
import AddDocumentModal from "./AddDocumentModal";
import Style from "./Step1Edit.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...PropComex.types,
    },
    defaults: {
        ...PropComex.defaults,
    },
};

export function Component(props) {
    const {
        dispatch,
        emailValidationRegex,
        errors,
        match,
        preData,
        setErrors,
        setFieldError,
        setFieldTouched,
        setFieldValue,
        setSubmitting,
        setValues,
        transaction,
        values,
    } = props;

    const [showDocumentsModal, setShowDocumentsModal] = React.useState(false);
    const [documentEdition, setDocumentEdition] = React.useState({});
    const [inBank, setInBank] = React.useState(false);
    const [loadOperationNumber, setLoadOperationNumber] = React.useState(false);

    const { availableOperations, currencyList, documentList, nextValidDate, unavailableDays } = preData;

    const cancelEnabled = transaction && transaction.idTransactionStatus === STATUS.DRAFT;

    const othersFields = values?.item?.othersFields;

    let documentListFilter = [];

    const options = currencyList.map((c) => ({
        id: c,
        label: UtilsI18n.get(`currency.label.${c}`),
    }));

    const addDocuments = values[FIELD_NAME.DOCUMENTS]
        ?.map((doc) => doc.type)
        .filter((type) => type !== "FACTURA_COMERCIAL" && type !== "OTROS");

    if (documentList) {
        documentListFilter = documentList.filter((doc) => !addDocuments.includes(doc));
    }

    React.useEffect(() => {
        dispatch(SelectorsActionComexExport.presentationCreditLetterPreRequest());
    }, [dispatch]);

    React.useEffect(() => {
        const { operationNumber } = match.params;

        if (!loadOperationNumber && availableOperations?.length > 0 && !values?.operationNumber && operationNumber) {
            const operationSelected = availableOperations.filter(
                (item) => item.othersFields.NROOPERACION === operationNumber,
            );

            setLoadOperationNumber(true);

            if (operationSelected.length > 0) {
                setFieldValue("item", operationSelected[0]);
                setFieldValue("operationNumber", operationNumber);
            }
        }
    }, [availableOperations, loadOperationNumber, match.params, setFieldValue, values]);

    React.useEffect(() => {
        if (transaction?.data) {
            const transactionData = transaction?.data;

            /* carga de errores */
            if (transactionData.backendFormErrors?.cdpErrorFields) {
                const { cdpErrorFields } = transactionData.backendFormErrors;

                cdpErrorFields.map((errorField) => {
                    if (errorField === "valueDate") {
                        setFieldError("scheduler", SPACE_STR);
                        setFieldTouched("scheduler", true);

                        transactionData.scheduler = {
                            editing: true,
                            valueDate: transactionData?.valueDate,
                            selectedOption: transactionData?.selectedOption,
                        };
                    } else {
                        setFieldError(errorField, SPACE_STR);
                        setFieldTouched(errorField, true);
                    }
                    return null;
                });
            }

            if (transactionData.backendFormErrors?.message) {
                const errorMessage = transactionData?.backendFormErrors?.message;
                dispatch(
                    SelectorActionNotification.showNotification({
                        message: errorMessage,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.COMEX],
                    }),
                );
            } // modificaciones para que agarren bien los fields

            if (transactionData.valueDate) {
                const valueDate = ToDate(transactionData.valueDate);
                transactionData.valueDate = valueDate;
            }

            if (transactionData.dueDate) {
                const dueDate = ToDate(transactionData.dueDate);
                transactionData.dueDate = dueDate;
            }

            if (transactionData.boardingDate) {
                const boardingDate = ToDate(transactionData.boardingDate);
                transactionData.boardingDate = boardingDate;
            }

            let dataTransaction;

            if (transaction.data.utilization) {
                dataTransaction = {
                    ...transaction.data,
                    [FIELD_NAME.INVOICE_AMOUNT]: {
                        amount: transaction.data[FIELD_NAME.INVOICE_AMOUNT],
                        currency: transaction.data.invoiceAmountCurrency,
                    },
                    [FIELD_NAME.UTILIZATION_AMOUNT]: {
                        ...transaction.data.utilization,
                    },
                };
            }

            setValues(dataTransaction || transaction.data);
        }
    }, [dispatch, setFieldError, setFieldTouched, setValues, transaction]);

    const handleFormKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            event.preventDefault();
        }
    };

    const handleSelectTemplate = (template) => {
        const templateToBeLoaded = template;

        if (templateToBeLoaded.scheduler.valueDate) {
            const valueDate = ToDate(templateToBeLoaded.scheduler.valueDate);
            templateToBeLoaded.scheduler.valueDate = valueDate;
        }

        if (templateToBeLoaded.dueDate) {
            const dueDate = ToDate(templateToBeLoaded.dueDate);
            templateToBeLoaded.dueDate = dueDate;
        }

        if (templateToBeLoaded.boardingDate) {
            const boardingDate = ToDate(templateToBeLoaded.boardingDate);
            templateToBeLoaded.boardingDate = boardingDate;
        }

        setValues(templateToBeLoaded);
        setErrors({});
    };

    const handleItemSelect = (item) => {
        const { othersFields: othersField } = item;

        if (othersField) {
            setFieldValue(FIELD_NAME.OPERATION_NUMBER, othersField.NROOPERACION);
            setFieldValue(FIELD_NAME.ITEM, item);

            setFieldError(FIELD_NAME.OPERATION_NUMBER, null);
        } else {
            setFieldValue(FIELD_NAME.ITEM, null);
            setFieldValue(FIELD_NAME.OPERATION_NUMBER, EMPTY_STR);
        }
    };

    const handleModalLoad = () => {
        dispatch(SelectorsActionComex.toggleCreditLetterList());
    };

    const handleBlur = () => {
        if (values.operationNumber) {
            const operationSelected = availableOperations.filter(
                (item) => item.othersFields.NROOPERACION === values.operationNumber,
            );

            if (operationSelected.length > 0) {
                setFieldValue(FIELD_NAME.ITEM, operationSelected[0]);
            } else {
                setFieldValue(FIELD_NAME.ITEM, null);
                setFieldValue(FIELD_NAME.OPERATION_NUMBER, EMPTY_STR);
            }
        }
    };

    const handleCancel = () => {
        dispatch(
            SelectorsActionForm.cancelTransactionCustom({
                credentials: {},
                idTransaction: transaction.idTransaction,
                formikBag: { setSubmitting },
            }),
        );
    };

    const handleDraft = () => {
        dispatch(
            SelectorsActionForm.saveDraft({
                idActivityDraft: ID_ACTIVITY_SEND,
                idTransactionToSave: transaction?.idTransaction,
                transactionData: { ...values, valueDate: FormatDate(values.scheduler.valueDate) } || {},
            }),
        );
    };

    const handleTemplateSave = () => {
        dispatch(SelectorsActionTemplate.createTemplate());
    };

    const handleTemplateLoad = () => {
        dispatch(SelectorsActionTemplate.toggleTemplateList());
    };

    const handleCheckChange = (fieldName) => {
        setFieldValue(fieldName, !values[fieldName]);
    };

    const editDocument = (doc) => {
        setDocumentEdition(doc);
        setShowDocumentsModal(true);
    };
    const handleDocumentModal = (show) => {
        setShowDocumentsModal(show);
    };
    const addEditDocument = (doc) => {
        let newDocuments;

        if (Object.entries(documentEdition).length > 0) {
            const index = values.documents.findIndex((d) => d === documentEdition);
            newDocuments = [...values.documents];
            newDocuments[index] = doc;
        } else {
            newDocuments = [...values.documents].concat(doc);

            setFieldError(FIELD_NAME.DOCUMENTS, EMPTY_STR);
        }

        setFieldValue(FIELD_NAME.DOCUMENTS, newDocuments);

        setDocumentEdition({});
    };

    const currencyImport = values.creditLetter
        ? values?.item?.accountCurrency ?? transaction?.data?.utilizationAmountCurrency
        : values?.invoiceAmount?.currency;
    let optionImport;

    if (currencyImport) {
        optionImport = [
            {
                id: currencyImport,
                label: UtilsI18n.get(`currency.label.${currencyImport}`),
            },
        ];
    }

    React.useEffect(() => {
        if (currencyImport) {
            setFieldValue("utilizationAmount.currency", currencyImport);
        }
    }, [currencyImport, setFieldValue]);

    React.useEffect(() => {
        setFieldValue(`${FIELD_NAME.INVOICE_AMOUNT}.currency`, CDP_CURRENCY.USD);
    }, [setFieldValue]);

    return (
        <React.Fragment>
            <Form id={Style.ID} noValidate="novalidate" onKeyDown={handleFormKeyDown}>
                <div className="form-section loadTemplates">
                    <Button
                        onClick={handleTemplateLoad}
                        label="forms.templates.load"
                        bsStyle="default"
                        image="template.svg"
                    />
                </div>
                <section className="fields container--layout align-items-center flex-grow">
                    <Box className="form-section-title">
                        <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.operation`)}</h3>
                    </Box>
                    <Box align="center" className="form-group search-field checkbox-container">
                        <FieldLabel labelKey={`${FORM_ID}.checkbox.label`} classContainer="label--check" />
                        <Field
                            name={FIELD_NAME.CREDIT_LETTER}
                            label={UtilsI18n.get(`${FORM_ID}.in.the.bank`)}
                            onChange={() => {
                                handleCheckChange(FIELD_NAME.CREDIT_LETTER);
                                setInBank(!inBank);
                            }}
                            checked={values.creditLetter}
                            component={Checkbox}
                            maxLength={30}
                        />
                    </Box>
                    {values.creditLetter && (
                        <React.Fragment>
                            <Box flex align="end" className="form-group search-field">
                                <Field
                                    idForm={FORM_ID}
                                    name={FIELD_NAME.OPERATION_NUMBER}
                                    hidePlaceholder
                                    pattern={ONLY_NUMBER}
                                    type="text"
                                    component={TextField}
                                    onBlur={handleBlur}
                                    maxLength={30}
                                    tooltip={UtilsI18n.get(`${FORM_ID}.operationNumber.tooltip`)}
                                />

                                <Box className="ml-3 mb-2 ">
                                    <Button
                                        onClick={handleModalLoad}
                                        label="global.search"
                                        bsStyle="primary"
                                        className="search"
                                    />
                                </Box>
                            </Box>
                            {values.item && <OperationNumberDetail othersFields={othersFields} idForm={FORM_ID} />}
                        </React.Fragment>
                    )}

                    {!values.creditLetter && (
                        <React.Fragment>
                            <Field
                                autoComplete="off"
                                component={TextField}
                                idForm={FORM_ID}
                                name={FIELD_NAME.CREDIT_LETTER_NUMBER}
                                placeholder={EMPTY_STR}
                                maxLength={30}
                            />
                            <React.Fragment>
                                <Field
                                    clearable={false}
                                    component={AmountField}
                                    data={{ options }}
                                    idForm={FORM_ID}
                                    name={FIELD_NAME.INVOICE_AMOUNT}
                                    searchable={false}
                                    decimalPlaces={2}
                                    fixedDecimalScale
                                    maxLength={ConfigUtil.getInteger("amount.length")}
                                />
                            </React.Fragment>
                        </React.Fragment>
                    )}

                    <Field
                        autoComplete="off"
                        component={TextField}
                        idForm={FORM_ID}
                        name={FIELD_NAME.INVOICE_NUMBER}
                        placeholder={EMPTY_STR}
                        maxLength={80}
                    />

                    <Field
                        clearable={false}
                        component={AmountField}
                        data={{ options: optionImport ?? [] }}
                        idForm={FORM_ID}
                        name={FIELD_NAME.UTILIZATION_AMOUNT}
                        placeholder={EMPTY_STR}
                        searchable={false}
                        decimalPlaces={2}
                        fixedDecimalScale
                        value={currencyImport ?? EMPTY_STR}
                        hideCurrency={!currencyImport}
                        tooltip={UtilsI18n.get(`${FORM_ID}.utilizationAmount.tooltip`)}
                        maxLength={ConfigUtil.getInteger("amount.length")}
                    />

                    <Box className="form-group show">
                        <AddDocument
                            formId={FORM_ID}
                            {...props}
                            editDocument={editDocument}
                            showDocumentModal={handleDocumentModal}
                        />
                    </Box>

                    <Field
                        autoComplete="off"
                        component={TextField}
                        idForm={FORM_ID}
                        name={FIELD_NAME.COURIER_NAME}
                        placeholder={EMPTY_STR}
                        tooltip={UtilsI18n.get(`${FORM_ID}.courierName.tooltip`)}
                        maxLength={30}
                    />
                    <Field
                        autoComplete="off"
                        component={TextField}
                        idForm={FORM_ID}
                        name={FIELD_NAME.COURIER_ACCOUNT_NUMBER}
                        placeholder={EMPTY_STR}
                        tooltip={UtilsI18n.get(`${FORM_ID}.courierNumber.tooltip`)}
                        maxLength={20}
                    />

                    <Box className="form-group">
                        <Field
                            name={FIELD_NAME.FILES}
                            idForm={FORM_ID}
                            maxFiles={5}
                            errors={errors}
                            component={FileUploader}
                            emptyMessageKey={`${FORM_ID}.attachments.emptyMessage`}
                            values={values}
                            setFieldValue={setFieldValue}
                        />
                    </Box>

                    <Box className="form-group search-field">
                        <FieldLabel labelKey={`${FORM_ID}.discrepancies.label`} classContainer="label--check" />
                        <Field
                            name={FIELD_NAME.DISCREPANCIES}
                            onChange={() => handleCheckChange(FIELD_NAME.DISCREPANCIES)}
                            checked={values.discrepancies}
                            component={Checkbox}
                        />
                    </Box>
                    <Field
                        component={TextArea}
                        idForm={FORM_ID}
                        name={FIELD_NAME.OBSERVATIONS}
                        mode={MODE.EDIT}
                        className="form-control"
                        maxLength={500}
                        hidePlaceholder
                    />
                    <Field
                        component={EmailList}
                        idForm={FORM_ID}
                        name={FIELD_NAME.NOTIFICATION_EMAILS}
                        hideSwiftTip
                        className="form-group email-field"
                        renderSuggestion={false}
                        data={{ emailValidationRegex, addMessage: EMPTY_STR }}
                        tooltip={UtilsI18n.get(`${FORM_ID}.notificationEmails.tooltip`)}
                        value={values.notificationEmails}
                    />

                    <hr />
                    <Box className="form-section-title">
                        <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.section.showPreview`)}</h3>
                    </Box>

                    <Field
                        component={Termsandconditions}
                        idForm={FORM_ID}
                        name={FIELD_NAME.DISCLAIMER}
                        mode={MODE.EDIT}
                        className="form-control"
                        i18nMap={i18nMap}
                    />
                    {nextValidDate && (
                        <Field
                            component={Scheduler}
                            data={{
                                firstWorkingDate: nextValidDate,
                                maxDaysToSchedule: ConfigUtil.get("client.comex.valueDate.maxDaysToSchedule"),
                                nonWorkingDays: unavailableDays,
                                lang: "es",
                                mode: "edit",
                                programable: false,
                                schedulable: true,
                            }}
                            name={FIELD_NAME.SCHEDULER}
                            idForm={FORM_ID}
                        />
                    )}
                </section>
                <footer>
                    <Button onClick={handleDraft} label="forms.saveDraft.link" />
                    {cancelEnabled && <Button onClick={handleCancel} label="forms.cancelTransaction.link" />}
                    <Button onClick={handleTemplateSave} label="forms.saveTemplate.link" className="templateSave" />
                    <Button
                        key="primaryButton"
                        variant="primary"
                        bsStyle="primary"
                        label="global.next"
                        loading={false}
                        type="submit"
                    />
                </footer>
            </Form>
            <ListTemplatesModal idActivityTemplate={ID_ACTIVITY_SEND} onSelect={handleSelectTemplate} />
            <CreateTemplateModal values={values} idActivityTemplate={ID_ACTIVITY_SEND} backdrop="static" />
            <AddDocumentModal
                show={showDocumentsModal}
                terms={values.terms}
                submit={addEditDocument}
                handleCloseModal={() => {
                    handleDocumentModal(false);
                    setDocumentEdition({});
                }}
                document={documentEdition}
                documentTypeList={documentListFilter}
            />
            <OperationNumber list={availableOperations} idForm={FORM_ID} handleItemClick={handleItemSelect} />
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => {
    return {
        preData: SelectorsStoreComexExport.getComexFormPre(store),
        transaction: SelectorsStoreComex.getTransaction(store),
        operationNumberModalVisible: SelectorsStoreComex.isOperationNumberModalVisible(store),
        prevData: SelectorsStoreComexExport.getComexFormPrevData(store),
    };
};

export default HighOrder(
    WithRouter,
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: false,
        validateOnChange: false,
        validateOnBlur: false,

        mapPropsToValues: ({ prevData }) => {
            const attachments = prevData?.attachments;
            const hasAttachments = attachments !== undefined && attachments !== EMPTY_STR;

            return {
                [FIELD_NAME.COURIER_ACCOUNT_NUMBER]: prevData[FIELD_NAME.COURIER_ACCOUNT_NUMBER] ?? EMPTY_STR,
                [FIELD_NAME.COURIER_NAME]: prevData[FIELD_NAME.COURIER_NAME] ?? EMPTY_STR,
                [FIELD_NAME.CREDIT_LETTER]: prevData[FIELD_NAME.CREDIT_LETTER] ?? true,
                [FIELD_NAME.CREDIT_LETTER_NUMBER]: prevData[FIELD_NAME.CREDIT_LETTER_NUMBER] ?? EMPTY_STR,
                [FIELD_NAME.DISCREPANCIES]: prevData[FIELD_NAME.DISCREPANCIES] === YES_ES,
                [FIELD_NAME.DOCUMENTS]: prevData[FIELD_NAME.DOCUMENTS] ?? [],
                [FIELD_NAME.FILES]: hasAttachments ? attachments?.split(COMMA) : [],
                [FIELD_NAME.INVOICE_AMOUNT]: prevData?.invoiceAmount
                    ? { amount: prevData[FIELD_NAME.INVOICE_AMOUNT], currency: prevData.invoiceAmountCurrency }
                    : {},
                [FIELD_NAME.INVOICE_NUMBER]: prevData[FIELD_NAME.INVOICE_NUMBER] ?? EMPTY_STR,
                [FIELD_NAME.ITEM]: prevData[FIELD_NAME.ITEM] ?? null,
                [FIELD_NAME.NOTIFICATION_EMAILS]: prevData[FIELD_NAME.NOTIFICATION_EMAILS] ?? EMPTY_STR,
                [FIELD_NAME.OBSERVATIONS]: prevData[FIELD_NAME.OBSERVATIONS] ?? EMPTY_STR,
                [FIELD_NAME.OPERATION_NUMBER]: prevData[FIELD_NAME.OPERATION_NUMBER] ?? EMPTY_STR,
                [FIELD_NAME.SCHEDULER]: prevData?.scheduler
                    ? {
                          editing: true,
                          selectedOption: prevData?.scheduler.selectedOption,
                          valueDate: prevData?.scheduler.valueDate,
                      }
                    : null,
                [FIELD_NAME.UTILIZATION_AMOUNT]: prevData?.utilization ?? {},
            };
        },
        handleSubmit: (value, formikBag) => {
            const { dispatch } = formikBag.props;
            const {
                boardingDate,
                courierAccountNumber,
                courierName,
                creditLetter,
                creditLetterNumber,
                discrepancies,
                documents,
                files,
                invoiceAmount,
                invoiceNumber,
                item,
                notificationEmails,
                observations,
                operationNumber,
                scheduler,
                utilizationAmount,
            } = value;

            let originalCreditLetterNumber;
            let originalAmount;
            let invoiceAmountCurrency;
            let oldAmount;

            if (creditLetter) {
                if (item?.othersFields) {
                    originalCreditLetterNumber = item?.othersFields?.CARTADECREDITO;
                    originalAmount = `${UtilsI18n.get(`currency.label.${item.othersFields.MONEDA}`)} ${parseFloat(
                        item.othersFields.IMPORTE,
                    )}`;
                    invoiceAmountCurrency = item?.othersFields ? item.othersFields.MONEDA : EMPTY_STR;
                    oldAmount = item?.othersFields ? item.othersFields.IMPORTE : EMPTY_STR;
                }
            } else {
                originalCreditLetterNumber = value[FIELD_NAME.CREDIT_LETTER_NUMBER];

                invoiceAmountCurrency = invoiceAmount?.currency;
                oldAmount = value[FIELD_NAME.INVOICE_AMOUNT]?.amount ?? EMPTY_STR;
            }

            const amount = creditLetter ? oldAmount : invoiceAmount?.amount ?? 0;
            const attachmentsFiles = files ? files.join(COMMA) : EMPTY_STR;
            const valueDateString = scheduler ? FormatDate(scheduler.valueDate) : new Date();
            const boardingDateString = boardingDate ? FormatDate(new Date(boardingDate)) : EMPTY_STR;
            const utilizationCurrency = utilizationAmount?.currency;

            const formData = {
                attachments: attachmentsFiles,
                boardingDate: boardingDateString,
                courierAccountNumber,
                courierName,
                creditLetter,
                creditLetterNumber,
                discrepancies: discrepancies ? YES_ES : NOT_ES,
                documents,
                files,
                invoiceAmount: amount,
                invoiceAmountCurrency,
                invoiceNumber,
                item,
                notificationEmails,
                observations,
                oldAmount,
                operationNumber: operationNumber ?? EMPTY_STR,
                originalAmount,
                originalCreditLetterNumber,
                scheduler,
                utilizationAmountCurrency: utilizationCurrency,
                utilization: utilizationAmount,
                utilizationAmount: utilizationAmount?.amount,
                valueDate: valueDateString,
            };

            dispatch(
                SelectorsActionComexExport.presentationCreditLetterPreviewRequest({
                    formData,
                    formikBag,
                }),
            );
        },
    }),
)(Component);
