import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { TYPE as TypesBackoffice } from "~/store/backoffice";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    const { data } = action;

    switch (action.type) {
        case TYPE.LOAD_ADMINISTRATION_TICKET_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.LOAD_ADMINISTRATION_TICKET_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.LOAD_ADMINISTRATION_TICKET_SUCCESS:
            return {
                fetching: false,
                data,
                mode:
                    storeProp.mode === MODE.VIEW || data.idTransactionStatus === STATUS.FINISHED
                        ? MODE.VIEW
                        : MODE.EDIT,
            };
        case TYPE.SIGN_ADMINISTRATIVE_TRANSACTION:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.SIGN_ADMINISTRATIVE_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
            };
        case TYPE.SIGN_ADMINISTRATIVE_TRANSACTION_PREVIEW_REQUEST:
            return {
                ...storeProp,
                mode: MODE.PREVIEW,
            };
        case TYPE.CANCEL_ADMINISTRATIVE_TRANSACTION:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.CANCEL_PREVIEW_STEP:
            return {
                ...storeProp,
                mode: MODE.EDIT,
            };
        case TypesBackoffice.BACKOFFICE_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                data: action.transaction,
                fetching: false,
                mode: MODE.VIEW,
            };
        case TypesBackoffice.READ_USER_DATA_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                user: action.userData.user,
            };
        default:
            return storeProp;
    }
};
