import React from "react";

import PropTypes from "prop-types";
// TODO: Button should be imported from the component Button, not Bootstrap. This is also used in Head.
import { Button } from "react-bootstrap";
import { withRouter as WithRouter } from "react-router-dom";

import { Types as TypesHtmlElement, Defaults as DefaultsHtmlElement } from "~/util/prop/html-element";

import Image from "~/components/Image";
import Header from "~/pages/_components/Header";

import DefaultContent from "./_DefaultContent";

export const NAME = "ExternalHeaderMobile";

export const PROP = {
    types: {
        ...TypesHtmlElement,
        children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
        showBackAction: PropTypes.bool,
        showExitAction: PropTypes.bool,
        handleExitAction: PropTypes.func,
        handleBackAction: PropTypes.func,
    },
    defaults: {
        ...DefaultsHtmlElement,
        children: null,
        showBackAction: false,
        showExitAction: false,
        handleExitAction: null,
    },
};

export function Component(props) {
    const { handleExitAction, children, showBackAction, showExitAction, history } = props;

    function handleBackAction() {
        history.goBack();
    }

    return (
        <div className="enrollment-header">
            {showBackAction && (
                <div className="view-header-mobile">
                    <Header>
                        <div className="toolbar-item toolbar-item--fixed toolbar-item-left">
                            <Button onClick={handleBackAction} className="toolbar-btn view-back">
                                <Image className="svg-icon svg-caret" src="arrowLeft.svg" />
                            </Button>
                        </div>
                    </Header>
                </div>
            )}
            {children || <DefaultContent />}
            {showExitAction && (
                <div
                    className="enrollment-header-close"
                    onClick={handleExitAction}
                    onKeyDown={handleExitAction}
                    role="link"
                    tabIndex={0}>
                    <Image src="cross.svg" />
                </div>
            )}
        </div>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default WithRouter(Component);
