/* global window */

import * as SecureStorageUtils from "~/util/secureStorage";

const facephiSS = "facephi";

export const getFacephiSS = () => SecureStorageUtils.get(facephiSS);

export const setFacephiActive = (commonName) => SecureStorageUtils.set(facephiSS, commonName);

export const setInactive = () => SecureStorageUtils.remove(facephiSS);

export const register = () => window.security.facephi.register();

export const authenticate = (enableLiveness = false) => window.security.facephi.authenticate(enableLiveness);

export const error = {
    BadExtractorConfiguration: 9,
    CameraPermissionDenied: 3,
    ControlNotInitializedError: 8,
    ExtractionLicenseError: 6,
    HardwareError: 5,
    NoError: 2,
    SettingsPermissionDenied: 4,
    UnexpectedCaptureError: 7,
    UnknownError: 1,
    FaceError: 1000,
};

export const status = {
    Ok: 1,
    Error: 2,
    CancelByUser: 3,
    Timeout: 4,
};
