import { push as Push } from "connected-react-router";
import { call as Call, delay as Delay, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import { BIOMETRIC_ID_USER, DOT, EMPTY_STR, ONE, REACT_APP_VERSION, RESPONSE_TYPE } from "~/constants";
import Store from "~/store";
import { E_SSL_HANDSHAKE } from "~/store/apiMobile";
import { TYPE as TYPES_APP } from "~/store/app";
import { SelectorsStore as SelectorsStoreI18n, SelectorsAction as SelectorsActionI18n } from "~/store/i18n";
import * as UtilsConfig from "~/util/config";
import UtilsDevice, { DISPLAY, DEVICE_MOBILE } from "~/util/device";
import ConfigureApi from "~/util/http";
import * as UtilsI18n from "~/util/i18n";
import Logger from "~/util/logger";
import { initializeRasp as InitializeRasp } from "~/util/rasp";
import * as SecureStorageUtils from "~/util/secureStorage";
import { isUserMakingUseOfMinVersion as IsUserMakingUseOfMinVersion } from "~/util/settings";
import * as SoftToken from "~/util/softToken";

import { SelectorsAction, SelectorsMiddleware } from "./_selectors";

const sagas = [
    TakeLatest(TYPES_APP.INIT, initializeCordovaHTTP),
    TakeLatest(TYPES_APP.INIT, initializeRaspFunc),
    TakeLatest(TYPES_APP.INIT, isTrustedFunc),
    TakeLatest(TYPES_APP.INIT, updateConfig),
];

export default sagas;

function* initializeCordovaHTTP() {
    while (true) {
        if (UtilsDevice.isMobileNative()) {
            try {
                const response = yield Call(ConfigureApi);

                if (!response) {
                    throw new Error(`CordovaHTTP couldn't have been initialized`);
                }

                break;
            } catch (error) {
                yield Delay(2000);
            }
        } else {
            break;
        }
    }
}

function* initializeRaspFunc() {
    while (true) {
        if (UtilsDevice.isMobileNative()) {
            try {
                const raspConfigNames = UtilsConfig.getFilteredLikeKeys("rasp");
                const filterRaspConfigBy = (platform) =>
                    raspConfigNames.filter((configurationName) => configurationName.includes(platform));
                const mobileConfigNames = filterRaspConfigBy(DISPLAY.MOBILE.toLowerCase());

                let deviceConfigList = [];
                const raspPluginConfig = [];

                if (UtilsDevice.mobileOS() === DEVICE_MOBILE.ANDROID) {
                    const androidConfigNames = filterRaspConfigBy(DEVICE_MOBILE.ANDROID.toLowerCase());

                    deviceConfigList = [...mobileConfigNames, ...androidConfigNames];
                }

                if (UtilsDevice.mobileOS() === DEVICE_MOBILE.IOS) {
                    const iosConfigNames = filterRaspConfigBy(DEVICE_MOBILE.IOS.toLowerCase());

                    deviceConfigList = [...mobileConfigNames, ...iosConfigNames];
                }

                deviceConfigList.forEach((configurationName) => {
                    const configName = configurationName.split(DOT).pop();
                    const configLevel = UtilsConfig.get(configurationName);
                    const configMessage = UtilsI18n.get(`${configurationName}.message`);

                    const raspProperty = {
                        event: configName,
                        level: configLevel,
                        message: configMessage,
                    };

                    raspPluginConfig.push(raspProperty);
                });

                yield Call(InitializeRasp, raspPluginConfig);

                break;
            } catch (error) {
                yield Delay(2000);
            }
        } else {
            break;
        }
    }
}

function* isTrustedFunc() {
    let isTrusted = false;
    let params = null;

    while (true) {
        if (UtilsDevice.isMobileNative()) {
            try {
                isTrusted = yield Call(SoftToken.isTrustedDevice);

                let idUserBiometric;

                try {
                    idUserBiometric = yield Call(SecureStorageUtils.get, BIOMETRIC_ID_USER);
                } catch (error) {
                    idUserBiometric = null;
                }

                if (isTrusted === ONE || isTrusted === 1) {
                    isTrusted = true;

                    if (!idUserBiometric) {
                        isTrusted = false;
                    }
                } else {
                    isTrusted = false;

                    if (idUserBiometric) {
                        yield Call(SecureStorageUtils.remove, BIOMETRIC_ID_USER);
                    }
                }

                params = { isTrusted };

                yield Put(SelectorsAction.isTrusted(params));

                const response = yield Call(SelectorsMiddleware.minVersionApp);

                if (response.type !== RESPONSE_TYPE.WARNING) {
                    const { minimumVersion } = response.data.data;

                    const version = REACT_APP_VERSION;

                    if (!IsUserMakingUseOfMinVersion(version, minimumVersion)) {
                        yield Put(Push("/oldVersion"));

                        break;
                    }

                    break;
                }

                break;
            } catch (error) {
                params = { isTrusted };

                switch (error) {
                    case SoftToken.error.NO_VASCOSS_FOUND:
                    case SoftToken.error.NO_VALID_VASCOSS:
                        yield Put(SelectorsAction.isTrusted(params));

                        return;
                    case SoftToken.error.NO_PERMISSION_GRANTED:
                        return;
                    default:
                        // SSL_PINNING error is detected and thrown to be caught by the generic saga's error handler
                        if (error.request && error.code === E_SSL_HANDSHAKE) {
                            throw error;
                        }

                        yield Delay(2000);
                }
            }
        } else {
            break;
        }
    }

    params = { isTrusted };

    yield Put(SelectorsAction.isTrusted(params));
}

function* updateConfig() {
    while (true) {
        try {
            yield Put(SelectorsAction.updateRequest());

            const response = yield Call(SelectorsMiddleware.listConfiguration);

            if (response.status !== 304) {
                const { data } = response.data;

                const initialLang = SelectorsStoreI18n.getLang(Store.getState());

                const lang = data["frontend.i18n.default.lang"];

                if (!initialLang) {
                    yield Put(SelectorsActionI18n.setInitLang({ lang }));

                    UtilsConfig.setRecaptchaLang(lang);
                } else {
                    UtilsConfig.setRecaptchaLang(initialLang);
                }

                yield Put(SelectorsAction.updateSuccess({ items: data }));

                yield Delay(UtilsConfig.getTimeInMillis("frontend.configuration.refresh.interval", 2000));
            }
        } catch (err) {
            // SSL_PINNING error is detected and thrown to be caught by the generic saga's error handler
            if (err.request && err.code === E_SSL_HANDSHAKE) {
                throw err;
            }

            Logger.error(err, EMPTY_STR);

            yield Put(SelectorsAction.updateFailure());

            yield Delay(2000);
        }
    }
}
