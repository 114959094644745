import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE, SUPPORTED_FILE_TYPES } from "~/constants";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsAction as ActionsTransactionLines } from "~/store/transactionLines";
import {
    download as Download,
    downloadBase64 as DownloadBase64,
    downloadPdf as DownloadPdf,
    downloadXls as DownloadXls,
    getBlobContent as GetBlobContent,
} from "~/util/download";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction } from "./_selectors";

export default [
    TakeLatest(TYPE.DOWNLOAD_FILE_LINK_REQUEST, downloadFileLink),
    TakeLatest(TYPE.DOWNLOAD_FILE_REQUEST, downloadFileRequest),
    TakeLatest(TYPE.GET_FILE_CONTENTS_REQUEST, getFileContentsRequest),
    TakeLatest(TYPE.GET_FILE_TRANSACTION_LINES_REQUEST, getFileTransactionLinesRequest),
];

function* downloadFileLink(props) {
    const { idFile } = props;

    const {
        type,
        data: { message, data },
    } = yield Call(SelectorsMiddleware.downloadFileLink, { idFile });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.downloadFileLinkFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.FORM],
            }),
        );
    } else {
        const { content, fileName } = data;

        const fileType = fileName.substring(fileName.length - 3, fileName.length);

        switch (fileType.toLowerCase()) {
            case SUPPORTED_FILE_TYPES.PDF:
                DownloadPdf(fileName, content);
                break;
            case SUPPORTED_FILE_TYPES.XLS:
                DownloadXls(fileName, content);
                break;
            default:
                Download(fileName, content);
                break;
        }

        yield Put(SelectorsAction.downloadFileLinkSuccess());
    }
}

function* downloadFileRequest(props) {
    const { idFile, fileName } = props;

    const {
        type,
        data: { message, data },
    } = yield Call(SelectorsMiddleware.downloadStream, { idFile });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.downloadFileFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.FORM],
            }),
        );
    } else {
        const { file } = data;

        DownloadBase64(fileName, file);
        yield Put(SelectorsAction.downloadFileSuccess());
    }
}

function* getFileContentsRequest(props) {
    const { idFile, deleteFile } = props;

    const {
        type,
        data: { message, data },
    } = yield Call(SelectorsMiddleware.downloadStream, { idFile });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.downloadFileFailure());
        yield Put(SelectorsAction.getFileContentsFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.FORM],
            }),
        );
    } else {
        const content = GetBlobContent(data)
            .split("\n")
            .slice(1)
            .map((line, i) => {
                const [
                    creditAccountNumber,
                    creditAmountCurrency,
                    creditAmountQuantity,
                    creditAccountName,
                    bankIdentifier,
                ] = line.split(",");
                return {
                    creditAccountNumber,
                    creditAmountCurrency,
                    creditAmountQuantity: Number(creditAmountQuantity),
                    creditAccountName,
                    bankIdentifier,
                    lineNumber: i + 1,
                };
            });

        if (deleteFile) {
            yield Call(SelectorsMiddleware.deleteFile, { idFile: Number(idFile) });
        }
        yield Put(ActionsTransactionLines.getFileContentsSuccess(content));
    }
}

function* getFileTransactionLinesRequest(props) {
    const { idFile, idForm, idFormField, deleteFile } = props;

    const {
        type,
        data: { message, data },
    } = yield Call(SelectorsMiddleware.getTransactionLines, { idFile, idForm, idFormField });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.getFileTransactionLinesFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.FORM],
            }),
        );
    } else {
        const { lines } = data;

        if (deleteFile) {
            yield Call(SelectorsMiddleware.deleteFile, { idFile: Number(idFile) });
        }
        let _lines = lines;
        if (lines !== undefined) {
            _lines = lines.map((line, index) => ({
                ...line,
                creditAccountNumber: line.creditAccountNumber.toString(),
                lineNumber: index + 1,
            }));
        }
        yield Put(ActionsTransactionLines.getFileTransactionLinesSuccess({ lines: _lines }));
    }
}
