import React from "react";

import { routerActions as ActionsRouter } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { CDP_CURRENCY, EMPTY_STR, ZERO } from "~/constants";
import { ID_FORM } from "~/constants/form";
import { PROP as FILES_PROP } from "~/store/files";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsStore as SelectorsStoreMultilineFile } from "~/store/multilinefile";
import {
    SelectorsAction as SelectorsActionTransactionLines,
    SelectorsStore as SelectorsStoreTransactionLines,
} from "~/store/transactionLines";
import DeviceUtils from "~/util/device";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import I18n from "~/components/I18n";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import DetailBox from "~/pages/_components/detailBox/DetailBox";

export const NAME = "ManualPayment";

export const PROP = {
    types: {
        ...TypesRedux,
        errors: PropTypes.shape({}),
        isReadOnly: PropTypes.bool,
        localBanks: PropTypes.array,
        linesWithNoAmount: PropTypes.number.isRequired,
        paymentCurrency: PropTypes.string.isRequired,
        totalAmount: PropTypes.shape({
            quantity: PropTypes.number,
            currency: PropTypes.string,
        }),
        transactionLines: PropTypes.arrayOf(PropTypes.shape(FILES_PROP.listItemProps)),
    },
    defaults: {
        ...DefaultsRedux,
        errors: null,
        isReadOnly: false,
        localBanks: [],
        totalAmount: {
            quantity: 0,
            currency: EMPTY_STR,
        },
        transactionLines: [],
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        const { dispatch, isReadOnly, localBanks } = this.props;

        if (!isReadOnly && localBanks.length === 0) {
            dispatch(SelectorsActionTransactionLines.listLocalBanksRequest());
        }
    }

    handleClick = () => {
        const { dispatch, idForm, isReadOnly, form } = this.props;
        const view = isReadOnly ? "processDetail" : "manual";

        if (!isReadOnly) {
            dispatch(
                SelectorsActionTransactionLines.setIsEditingPayment({
                    isEditingPayment: false,
                    lineNumber: 0,
                }),
            );

            dispatch(SelectorsActionForm.setData({ data: form.values }));
        }

        dispatch(ActionsRouter.replace(`/form/${idForm}/${view}`));
    };

    renderLinesInfo = () => {
        const { errors, transactionLines } = this.props;
        const errorsLength = errors && Object.keys(errors).length;
        return (
            <React.Fragment>
                <div>
                    {transactionLines.length} 
                    <I18n className="total-line" id="forms.inputFile.massivePayments.total.lines" />
                </div>
                {errorsLength > 0 && (
                    <div>
                        <I18n id="forms.inputFile.massivePayments.invalid.lines" />
                        {` ${errorsLength} / ${transactionLines.length - errorsLength} `}
                        <I18n id="forms.inputFile.massivePayments.valid.lines" />
                    </div>
                )}
            </React.Fragment>
        );
    };

    render() {
        const { transactionLines, paymentCurrency, totalAmount, isReadOnly, idForm } = this.props;
        const { currency: amountCurrency, quantity: amountQuantity } = totalAmount;

        let buttonLabel;
        if (isReadOnly) {
            buttonLabel = "forms.inputFile.massivePayments.transaction.detail";
        } else {
            buttonLabel = `forms.inputFile.massivePayments${
                idForm === ID_FORM.SUPPLIERS_PAYMENT ? ".suppliers" : EMPTY_STR
            }.${transactionLines.length ? "edit" : "add"}Payments`;
        }
        const isMobile = DeviceUtils.isMobileNative();

        return (
            <DetailBox>
                <DetailBox.Data label="forms.inputFile.massivePayments.currency">
                    <I18n id={`currency.label.${paymentCurrency || CDP_CURRENCY.UYU}`} />
                </DetailBox.Data>
                <DetailBox.Data label="forms.inputFile.massivePayments.payments">
                    {this.renderLinesInfo()}
                </DetailBox.Data>
                <DetailBox.Data label="forms.inputFile.massivePayments.totalAmount">
                    <FormattedAmount
                        className="data-desc"
                        currency={amountCurrency || CDP_CURRENCY.UYU}
                        quantity={amountQuantity || ZERO}
                    />
                </DetailBox.Data>
                {!isMobile && (
                    <div className="detailBox-row">
                        <Button
                            bsStyle="primary"
                            className="btn-small"
                            onClick={this.handleClick}
                            label={buttonLabel}
                        />
                    </div>
                )}
            </DetailBox>
        );
    }
}

const mapStateToProps = (store) => ({
    errors: SelectorsStoreTransactionLines.getErrors(store),
    hasFile: SelectorsStoreMultilineFile.hasFile(store),
    linesWithNoAmount: SelectorsStoreTransactionLines.getLinesWithNoAmount(store).length,
    localBanks: SelectorsStoreTransactionLines.getLocalBanks(store),
    paymentCurrency: SelectorsStoreTransactionLines.getCurrency(store),
    totalAmount: SelectorsStoreTransactionLines.getTotalAmount(store),
    transactionLines: SelectorsStoreTransactionLines.getTransactionLines(store),
});

export default Connect(mapStateToProps)(Component);
