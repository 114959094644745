import React from "react";

import { goBack as GoBack } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { MODE } from "~/constants/form";
import ContainerExternal from "~/containers/External/Payment/Steps";
import ContainerInternal from "~/containers/Internal/Form/Steps";
import {
    SelectorsAction as SelectorsActionConnectedParties,
    SelectorsStore as SelectorsStoreConnectedParties,
} from "~/store/connectedParties";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import { CLASS } from "./ConnectedPartiesReadTicket.rules.scss";
import Ticket from "./Ticket";

export const NAME = "ConnectedPartiesTicket";

export const PROP = {
    types: {
        ...TypesRedux,
        fetching: PropTypes.bool,
        isFromBackoffice: PropTypes.bool,
        regiteredBantotal: PropTypes.bool,
    },
    defaults: {
        ...DefaultsRedux,
        fetching: false,
        isFromBackoffice: false,
        regiteredBantotal: true,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    componentDidMount() {
        const { dispatch, isFromBackoffice, match } = this.props;

        if (!isFromBackoffice) {
            const { id } = match.params;

            dispatch(SelectorsActionConnectedParties.readTransactionRequest({ idTransaction: id }));
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(GoBack());
    };

    render() {
        const { fetching, isFromBackoffice, regiteredBantotal } = this.props;

        const Container = isFromBackoffice ? ContainerExternal : ContainerInternal;

        return (
            <Container
                className={CLASS}
                head-onBackWeb={regiteredBantotal && this.handleBack}
                head-onClose={this.close}
                head-title="activities.connectedParties.registerBantotal"
                name={NAME}
                step={MODE.VIEW}
                wait={fetching}>
                <Ticket />
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    fetching: SelectorsStoreConnectedParties.getFetching(store),
    regiteredBantotal: SelectorsStoreConnectedParties.isRegisteredBantotal(store),
});

export default Connect(mapStateToProps)(Component);
