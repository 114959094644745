import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { MODE } from "~/constants/form";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "comexImport";

export const PATH = {
    IMPORT_CREDIT_LETTER_PRE_REQUEST: "/comex.imports.creditLetterOpen.pre",
    IMPORT_CREDIT_LETTER_PREVIEW_REQUEST: "/comex.imports.creditLetterOpen.preview",
    IMPORT_CREDIT_LETTER_SEND_REQUEST: "/comex.imports.creditLetterOpen.send",
    MODIFY_CREDIT_LETTER_PRE_REQUEST: "/comex.import.modifyCreditLetter.pre",
    MODIFY_CREDIT_LETTER_PREVIEW_REQUEST: "/comex.import.modifyCreditLetter.preview",
    MODIFY_CREDIT_LETTER_REQUEST: "/comex.import.modifyCreditLetter.send",
    CANCELLATION_CREDIT_LETTER_PRE_REQUEST: "/comex.import.cancellationCreditLetter.pre",
    CANCELLATION_CREDIT_LETTER_PREVIEW_REQUEST: "/comex.import.cancellationCreditLetter.preview",
    CANCELLATION_CREDIT_LETTER_REQUEST: "/comex.import.cancellationCreditLetter.send",

    REQUEST_ENDORSEMENT_PRE_REQUEST: "/comex.import.requestEndorsement.pre",

    BILLING_CANCELATION_PRE_REQUEST: "/comex.import.billingCancellation.pre",
    BILLING_CANCELATION_PREVIEW_REQUEST: "/comex.import.billingCancellation.preview",
    BILLING_CANCELATION_REQUEST: "/comex.import.billingCancellation.send",
    REQUEST_ENDORSEMENT_PREVIEW_REQUEST: "/comex.import.requestEndorsement.preview",
    REQUEST_ENDORSEMENT_REQUEST: "/comex.import.requestEndorsement.send",
    DOCUMENT_PRESENTATION_PRE_REQUEST: "/comex.import.creditLetterDocumentPresentation.pre",
    DOCUMENT_PRESENTATION_PREVIEW_REQUEST: "/comex.import.creditLetterDocumentPresentation.preview",
    DOCUMENT_PRESENTATION_REQUEST: "/comex.import.creditLetterDocumentPresentation.send",

    LIFT_CREDIT_LETTER_DISCREPANCIES_PRE_REQUEST: "/comex.import.liftCreditLetterWithDiscrepancies.pre",
    LIFT_CREDIT_LETTER_DISCREPANCIES_PREVIEW_REQUEST: "/comex.import.liftCreditLetterWithDiscrepancies.preview",
    LIFT_CREDIT_LETTER_DISCREPANCIES_SEND_REQUEST: "/comex.import.liftCreditLetterWithDiscrepancies.send",
};
export const TYPE = FactoryType(NAME, [
    "IMPORT_CREDIT_LETTER_PRE_REQUEST",
    "IMPORT_CREDIT_LETTER_PRE_SUCCESS",
    "IMPORT_CREDIT_LETTER_PRE_FAILURE",
    "IMPORT_CREDIT_LETTER_PREVIEW_REQUEST",
    "IMPORT_CREDIT_LETTER_PREVIEW_SUCCESS",
    "IMPORT_CREDIT_LETTER_PREVIEW_FAILURE",
    "IMPORT_CREDIT_LETTER_SEND_REQUEST",
    "IMPORT_CREDIT_LETTER_SEND_SUCCESS",
    "IMPORT_CREDIT_LETTER_SEND_FAILURE",

    /** MODIFY CREDIT LETTER */
    "MODIFY_CREDIT_LETTER_PRE_REQUEST",
    "MODIFY_CREDIT_LETTER_PRE_SUCCESS",
    "MODIFY_CREDIT_LETTER_PRE_FAILURE",

    "MODIFY_CREDIT_LETTER_PREVIEW_REQUEST",
    "MODIFY_CREDIT_LETTER_PREVIEW_SUCCESS",
    "MODIFY_CREDIT_LETTER_PREVIEW_FAILURE",

    "MODIFY_CREDIT_LETTER_SEND_FORM",
    "MODIFY_CREDIT_LETTER_SEND_FORM_SUCCESS",
    "MODIFY_CREDIT_LETTER_SEND_FORM_FAILURE",

    "READ_TRANSACTION_REQUEST",
    "READ_TRANSACTION_SUCCESS",
    "CLOSE_TRANSACTION_PREVIEW",

    "CANCEL_TRANSACTION_REQUEST",
    "SIGN_TRANSACTION_PREVIEW",

    "SIGN_TRANSACTION_REQUEST",

    /** CANCELLATION CREDIT LETTER */
    "COMEX_CANCELLATION_CREDIT_LETTER_PRE_REQUEST",
    "COMEX_CANCELLATION_CREDIT_LETTER_PRE_SUCCESS",
    "COMEX_CANCELLATION_CREDIT_LETTER_PRE_FAILURE",

    "CANCELLATION_CREDIT_LETTER_PREVIEW_REQUEST",
    "CANCELLATION_CREDIT_LETTER_PREVIEW_SUCCESS",
    "CANCELLATION_CREDIT_LETTER_PREVIEW_FAILURE",

    "CANCELLATION_CREDIT_LETTER_SEND_REQUEST",
    "CANCELLATION_CREDIT_LETTER_SEND_SUCCESS",
    "CANCELLATION_CREDIT_LETTER_SEND_FAILURE",

    /** LIFTING CREDIT LETTER WITH DISCREPANCIES */
    "LIFT_CREDIT_LETTER_DISCREPANCIES_PRE_REQUEST",
    "LIFT_CREDIT_LETTER_DISCREPANCIES_PRE_SUCCESS",
    "LIFT_CREDIT_LETTER_DISCREPANCIES_PRE_FAILURE",

    "LIFT_CREDIT_LETTER_DISCREPANCIES_PREVIEW_REQUEST",
    "LIFT_CREDIT_LETTER_DISCREPANCIES_PREVIEW_SUCCESS",
    "LIFT_CREDIT_LETTER_DISCREPANCIES_PREVIEW_FAILURE",

    "LIFT_CREDIT_LETTER_DISCREPANCIES_SEND_FORM",
    "LIFT_CREDIT_LETTER_DISCREPANCIES_SEND_FORM_SUCCESS",
    "LIFT_CREDIT_LETTER_DISCREPANCIES_SEND_FORM_FAILURE",

    /* REQUEST ENDORSEMENT */
    "REQUEST_ENDORSEMENT_PRE_REQUEST",
    "REQUEST_ENDORSEMENT_PRE_SUCCESS",
    "REQUEST_ENDORSEMENT_PRE_FAILURE",

    "BILLING_CANCELATION_PRE_REQUEST",
    "BILLING_CANCELATION_PRE_SUCCESS",
    "BILLING_CANCELATION_PRE_FAILURE",
    "BILLING_CANCELATION_PREVIEW_REQUEST",
    "BILLING_CANCELATION_PREVIEW_SUCCESS",
    "BILLING_CANCELATION_PREVIEW_FAILURE",
    "BILLING_CANCELATION_SEND_FORM",
    "BILLING_CANCELATION_SEND_FORM_SUCCESS",
    "BILLING_CANCELATION_SEND_FORM_FAILURE",

    "REQUEST_ENDORSEMENT_PREVIEW_REQUEST",
    "REQUEST_ENDORSEMENT_PREVIEW_SUCCESS",
    "REQUEST_ENDORSEMENT_PREVIEW_FAILURE",

    "REQUEST_ENDORSEMENT_SEND_REQUEST",
    "REQUEST_ENDORSEMENT_SEND_SUCCESS",
    "REQUEST_ENDORSEMENT_SEND_FAILURE",

    "DOCUMENT_PRESENTATION_PRE_REQUEST",
    "DOCUMENT_PRESENTATION_PRE_SUCCESS",
    "DOCUMENT_PRESENTATION_PRE_FAILURE",
    "DOCUMENT_PRESENTATION_PREVIEW_REQUEST",
    "DOCUMENT_PRESENTATION_PREVIEW_SUCCESS",
    "DOCUMENT_PRESENTATION_PREVIEW_FAILURE",
    "DOCUMENT_PRESENTATION_SEND_FORM",
    "DOCUMENT_PRESENTATION_SEND_FORM_SUCCESS",
    "DOCUMENT_PRESENTATION_SEND_FORM_FAILURE",
    "COMEX_FORM_SEND_DATA_FAILURE",

    "RESTART_FLOW",
]);

export const PROP = {};

PROP.members = {};

PROP.types = {
    ...TypesRedux,
    fetching: PropTypes.bool,
    transactionList: PropTypes.array,
    formData: PropTypes.object,
    preData: PropTypes.object,
    transaction: PropTypes.object,
    mode: PropTypes.string,
};

PROP.defaults = {
    ...DefaultsRedux,
    fetching: false,
    transactionList: false,
    transaction: {},
    mode: MODE.EDIT,
    positions: {
        positionImport: [],
        positionExport: [],
        positionPrefinancing: [],
        positionGuarantee: [],
        positionImportTotal: 0,
        positionExportTotal: 0,
        positionGuaranteeTotal: 0,
    },
    creditLetterOpen: {
        preData: {
            documentTypeList: [],
            currencyList: [],
        },
        formData: undefined,
    },
    modifyCreditLetter: {
        preData: {
            nextValidDate: EMPTY_STR,
            availableOperationList: [],
            maxDaysToSchedule: 0,
            unavailableDays: [],
            currencyList: [],
            hasContract: false,
        },
        formData: {},
        prevData: {},
    },
    cancellationCreditLetter: {
        preData: {
            nextValidDate: EMPTY_STR,
            availableOperationList: [],
            maxDaysToSchedule: 0,
            unavailableDays: [],
            currencyList: [],
        },
        formData: {},
        prevData: {},
    },
    liftCreditLetterDiscrepancies: {
        preData: {
            nextValidDate: EMPTY_STR,
            availableOperationList: [],
            maxDaysToSchedule: 0,
            unavailableDays: [],
            countryList: [],
            documentTypeList: [],
        },
        formData: {},
        prevData: {
            authorizeDebit: true,
        },
    },
    requestEndorsement: {
        preData: {
            nextValidDate: EMPTY_STR,
            availableOperationList: [],
            maxDaysToSchedule: 0,
            unavailableDays: [],
        },
        formData: {},
        prevData: {},
    },
    billingCancelation: {
        preData: {
            nextValidDate: EMPTY_STR,
            availableOperationList: [],
            maxDaysToSchedule: 0,
            unavailableDays: [],
            currencyList: [],
            documentTypeList: [],
        },
        formData: {},
        prevData: {},
    },
    documentPresentation: {
        preData: {
            nextValidDate: EMPTY_STR,
            availableOperationList: [],
            maxDaysToSchedule: 0,
            unavailableDays: [],
            currencyList: [],
            documentTypeList: [],
        },
        formData: {},
        prevData: {},
    },
    prevMode: EMPTY_STR,
};
