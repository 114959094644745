import React from "react";

import { Form, withFormik as WithFormik, Field } from "formik";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { connect as Connect } from "react-redux";

import { BANKTYPE, CDP_CURRENCY, COMMA, EMPTY_STR, LANGUAGE, LEVEL, ONLY_NUMBER, SCOPE, SPACE_STR } from "~/constants";
import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { SelectorsAction as SelectorsActionComex, SelectorsStore as SelectorsStoreComex } from "~/store/comex";
import {
    SelectorsAction as SelectorsActionComexImport,
    SelectorsStore as SelectorsStoreComexImport,
} from "~/store/comex/import";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { SelectorsAction as SelectorActionNotification } from "~/store/notification";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionTemplate } from "~/store/template";
import { getInteger } from "~/util/config";
import * as ConfigUtil from "~/util/config";
import { toDate } from "~/util/date";
import * as UtilsI18n from "~/util/i18n";
import * as UtilsNumber from "~/util/number";

import Button from "~/components/Button";
import GridLayout from "~/components/GridLayout";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import DateField from "~/pages/_components/fields/DateField";
import EmailList from "~/pages/_components/fields/EmailList";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TextArea from "~/pages/_components/fields/TextArea";
import TextField from "~/pages/_components/fields/TextField";
import AmountField from "~/pages/_components/fields/formik/AmountField";
import Selector from "~/pages/_components/fields/formik/Selector";

import CreditLetterModal from "~/pages/comEx/_components/CreditLetterModal";
import CreateTemplateModal from "~/pages/forms/Step1Edit/_ModalTemplateCreate";
import ListTemplatesModal from "~/pages/forms/Step1Edit/_ModalTemplateList";
import { Termsandconditions } from "~/pages/forms/_components/_fields";
import Scheduler from "~/pages/forms/_components/_fields/Scheduler";
import Checkbox from "~/pages/forms/_components/_fields/_commons/Checkbox";

import AddDocumentModal from "../../../_components/AddDocumentModal";
import BankSearchForm from "../../../_components/BankSearch";
import DocumentSection from "../../../_components/DocumentSection";
import FileUploader from "../../../_components/FileUploader";
import LinkOptions from "../../../_components/LinkOptions";
import Style from "./Step1EditLetter.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        isSubmitting: PropTypes.bool,
        suppliersDisplayList: PropTypes.array.isRequired,
    },
    defaults: {
        isSubmitting: false,
    },
};

const FORM_ID = "comex.imports.creditLetter.form";

const calcInterest = (amount, idTerm, rate, rateType, base) => {
    let result = 0;

    if (rateType === "TEA") {
        /* eslint-disable-next-line */
        result = amount * (Math.pow(1 + rate / 100, idTerm / base) - 1);
    } else if (rateType === "TLA") {
        result = (((amount * rate) / 100) * idTerm) / base;
    }

    return result;
};

const getFormData = (data, idTransaction) => {
    const beneficiaryLines = data.beneficiaryLine2to5 ? data.beneficiaryLine2to5.split(/\r\n|\n|\r/) : [];

    const newFormData = {
        ...data,
        amount: { currency: data.amount.currency, quantity: data.amount.amount || 0 },
        attachments: data.files && data.files.length > 0 ? data.files.join(COMMA) : EMPTY_STR,
        available: UtilsI18n.get(`comex.imports.creditLetter.form.available.${data.available}`),
        // eslint-disable-next-line eqeqeq
        availableMixed: data.available != "MIXTO" ? EMPTY_STR : data.availableMixed,
        // eslint-disable-next-line eqeqeq
        availablePeriodDays: data.available != "PLAZO" ? EMPTY_STR : data.availablePeriodDays,
        // eslint-disable-next-line eqeqeq
        availablePeriodFrom: data.available != "PLAZO" ? EMPTY_STR : data.availablePeriodFrom,
        availablePeriodOther:
            // eslint-disable-next-line eqeqeq
            data.available != "PLAZO" || data.availablePeriodFrom != "OTRA" ? EMPTY_STR : data.availablePeriodOther,
        // store values para volver del paso 2 al 1
        availableValueToStore: data.available,
        // campos modificados para enviar a la api
        beneficiaryLine2: beneficiaryLines.length > 0 ? beneficiaryLines[0] : EMPTY_STR,
        beneficiaryLine3: beneficiaryLines.length > 1 ? beneficiaryLines[1] : EMPTY_STR,
        beneficiaryLine4: beneficiaryLines.length > 2 ? beneficiaryLines[2] : EMPTY_STR,
        beneficiaryLine5: beneficiaryLines.length > 3 ? beneficiaryLines[3] : EMPTY_STR,
        idTransaction,
        notificationEmails: data.notificationEmailsField?.join(COMMA),
        // If available is true PAGO_A_LA_VISTA (there is no need for a Letter of Credit),
        // otherwise it takes field's value
        requiresLetterOfCredit: data.available === "PAGO_A_LA_VISTA" ? false : data.requiresLetterOfCredit,
        // If amount tolerance is true (there is no tolerance, merchandise tolerance is false),
        // otherwise it takes field's value
        toleranceMerchandise: data.amountTolerance ? false : data.toleranceMerchandise,
    };

    return newFormData;
};

function equalBanks(bank1, bank2) {
    if (bank1 && bank2) {
        if (Object.keys(bank1).length !== Object.keys(bank2).length) {
            return false;
        }

        const differences = Object.keys(bank1).filter((k) => bank2[k] !== bank1[k]);

        return differences.length === 0;
    }
    return !bank1 && !bank2;
}

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        amountQuantity: "0,00",
        days: [],
        documentEdition: {},
        draftLoaded: false,
        modalData: [],
        selectedBank: {},
        showDetailsModal: false,
        showDocumentsModal: false,
        showRatesToolTip: false,
        showUItip: false,
    };

    static getDerivedStateFromProps(props, state) {
        const { errors, selectedBank, setErrors, setFieldValue, values } = props;

        if (state && !equalBanks(selectedBank, state.selectedBank) && setFieldValue) {
            setFieldValue("advisingBankBIC", selectedBank.code || values.advisingBankBIC.toUpperCase());
            setFieldValue("advisingBankName", selectedBank.bankName || EMPTY_STR);

            if (setErrors && selectedBank.code) {
                setErrors(delete { ...errors }.advisingBankBIC);
            }

            return { ...state, selectedBank };
        }

        return { ...state };
    }

    componentDidMount() {
        const { dispatch, isChangingEnvironment } = this.props;

        if (!isChangingEnvironment) {
            dispatch(SelectorsActionComexImport.importCreditLetterOpenPreRequest());
        }
    }

    componentDidUpdate() {
        const { dispatch, formData = {}, setFieldError, setFieldTouched, setValues, transaction } = this.props;
        const { draftLoaded } = this.state;

        // si viene trasaction.data, no estoy volviendo del paso 2 y no se cargo el draft -> cargo draft
        if (transaction?.data && Object.keys(formData).length === 0 && !draftLoaded) {
            this.setState({
                draftLoaded: true,
            });

            const transactionData = transaction?.data;

            const amount = {
                amount: transactionData.amount?.quantity,
                currency: transactionData.amount.currency || CDP_CURRENCY.USD,
            };

            const { lang } = this.props;
            const { decimalSeparator } = UtilsNumber.numberFormat(lang);
            const formattedValueByLang = UtilsNumber.toNumberFormat(amount.amount, decimalSeparator);

            /* carga de errores */
            if (transactionData.backendFormErrors?.cdpErrorFields) {
                const { cdpErrorFields } = transactionData.backendFormErrors;

                cdpErrorFields.map((errorField) => {
                    setFieldError(errorField, SPACE_STR);
                    setFieldTouched(errorField, true);
                    return null;
                });
            }

            if (transactionData.backendFormErrors?.message) {
                const errorMessage = transactionData?.backendFormErrors?.message;

                dispatch(
                    SelectorActionNotification.showNotification({
                        message: errorMessage,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.COMEX_IMPORT_CREDIT_LETTER_OPEN],
                    }),
                );
            }

            /*  carga del draft - modificaciones para que agarren bien los fields */
            if (transactionData.valueDate) {
                const valueDate = toDate(transactionData.valueDate);

                transactionData.valueDate = valueDate;
            }

            if (transactionData.expirationDate) {
                const expirationDate = toDate(transactionData.expirationDate);

                transactionData.expirationDate = expirationDate;
            }

            if (transactionData.shippingExpirationDate) {
                const shippingExpirationDate = toDate(transactionData.shippingExpirationDate);

                transactionData.shippingExpirationDate = shippingExpirationDate;
            }

            transactionData.amount = amount;
            transactionData.available = transactionData.availableValueToStore;

            setValues(transaction.data);

            dispatch(
                SelectorsActionComex.getAmountTextRequest({
                    currency: amount.currency,
                    value: amount.amount && amount.amount !== EMPTY_STR ? formattedValueByLang : 0,
                }),
            );
        }
    }

    amountBlur = (e) => {
        const {
            errors,
            termDeposit: { currencies },
            values: { currency, term },
        } = this.props;

        if (currency === 9999 && e.target.value) {
            this.setState({
                showUItip: true,
            });
        }

        this.validateAmount(currency, UtilsNumber.toNumber(e.target.value), term);

        const val = UtilsNumber.toNumber(e.target.value);
        const selectedCurrencyInfo = currencies.find((c) => c.currency === currency);

        if (selectedCurrencyInfo) {
            const selectedTerm = selectedCurrencyInfo.terms.find((term2) => term2.days === term);

            if (selectedTerm) {
                const selectedRate = selectedTerm.rates.reduce(
                    (properRate, rate) => (rate.amountFrom <= val && val <= rate.amountTo + 0.01 ? rate : properRate),
                    {},
                );

                if (selectedRate) {
                    const rateTypeLabel = UtilsI18n.get(`wm.termDeposit.rate.${selectedCurrencyInfo.rateType}`);
                    const rateMessage = `${selectedRate.rate}% ${rateTypeLabel}`;

                    const interest = calcInterest(
                        val,
                        term,
                        selectedRate.rate,
                        selectedCurrencyInfo.rateType,
                        selectedCurrencyInfo.base,
                    );

                    setTimeout(() => {
                        this.setState({
                            showRatesToolTip: term && val && !errors.cost,
                            rateMessage,
                            interest,
                        });
                    }, 100);
                }
            }
        }
    };

    handleChangeAmount = (value) => {
        const { dispatch, values } = this.props;
        const { amount } = values;
        // const { amountQuantity } = this.state;
        const currency = amount && amount.currency ? amount.currency : CDP_CURRENCY.USD;
        let amountToSet = 0;

        if (value != null && value !== undefined) {
            if (value === EMPTY_STR) {
                dispatch(SelectorsActionComex.getAmountTextRequest({ currency, value: 0 }));
            } else {
                dispatch(SelectorsActionComex.getAmountTextRequest({ currency, value }));

                amountToSet = value;
            }
        }

        this.setState({
            amountQuantity: amountToSet,
        });
    };

    handleCurrencyChange = (value) => {
        const { dispatch } = this.props;
        const { amountQuantity } = this.state;

        dispatch(SelectorsActionComex.getAmountTextRequest({ currency: value, value: amountQuantity }));
    };

    editDocument = (doc) => {
        this.setState({
            documentEdition: doc,
            showDocumentsModal: true,
        });
    };

    addEditDocument = (doc) => {
        const { setFieldError, setFieldValue, values } = this.props;
        const { documentEdition } = this.state;

        let newDocuments;
        if (Object.entries(documentEdition).length > 0) {
            const index = values.documents.findIndex((d) => d === documentEdition);

            newDocuments = [...values.documents];
            newDocuments[index] = doc;
        } else {
            newDocuments = [...values.documents].concat(doc);

            setFieldError("documents", EMPTY_STR);
        }

        setFieldValue("documents", newDocuments);

        this.setState({
            documentEdition: {},
        });
    };

    getAvailableDocumentTypeList = () => {
        const {
            preData: { documentTypeList },
            values: { documents },
        } = this.props;

        if (!documents) {
            return documentTypeList;
        }

        const unavailableDocs = documents.filter((d) => d.type !== "OTROS" && d.type !== "FACTURA_COMERCIAL");

        return documentTypeList.filter((d) => !unavailableDocs.find((d2) => d2.type === d));
    };

    termChange = (e) => {
        const {
            errors,
            termDeposit: { currencies },
            values: { currency, cost },
        } = this.props;

        this.validateAmount(currency, cost, e.value);

        const term = e.value;
        const selectedCurrencyInfo = currencies.find((c) => c.currency === currency);

        if (selectedCurrencyInfo) {
            const selectedTerm = selectedCurrencyInfo.terms.find((term2) => term2.days === term);

            if (selectedTerm) {
                const selectedRate = selectedTerm.rates.reduce(
                    (properRate, rate) => (rate.amountFrom <= cost && cost <= rate.amountTo + 0.01 ? rate : properRate),
                    {},
                );

                if (selectedRate) {
                    const rateTypeLabel = UtilsI18n.get(`wm.termDeposit.rate.${selectedCurrencyInfo.rateType}`);
                    const rateMessage = `${selectedRate.rate}% ${rateTypeLabel}`;

                    const interest = calcInterest(
                        cost,
                        term,
                        selectedRate.rate,
                        selectedCurrencyInfo.rateType,
                        selectedCurrencyInfo.base,
                    );

                    setTimeout(() => {
                        this.setState({
                            showRatesToolTip: cost && !errors.cost,
                            rateMessage,
                            interest,
                        });
                    }, 100);
                }
            }
        }
    };

    validateAmount = (currency, amount, term) => {
        if (amount && term) {
            const {
                formatNumber,
                lang,
                maximumDecimals,
                minimumDecimals,
                setFieldError,
                setFieldTouched,
                termDeposit: { currencies },
            } = this.props;

            const { decimalSeparator, thousandSeparator } = UtilsNumber.numberFormat(lang);
            const decimalPlaces = this.amountRef
                ? UtilsNumber.countDecimalPlaces(this.amountRef.value, decimalSeparator)
                : 0;
            const decimalScale = Math.max(Math.min(decimalPlaces, maximumDecimals), minimumDecimals);
            const c = currencies.find((cur) => cur.currency === currency);

            if (c) {
                const t = c.terms.find((tm) => tm.days === term);

                if (t) {
                    const minValue = t.rates.reduce(
                        (min, p) => (p.amountFrom < min ? p.amountFrom : min),
                        t.rates[0].amountFrom,
                    );

                    if (minValue > amount) {
                        const currencyToShow = currency === 9999 ? 0 : currency;

                        const minValueDisplay = formatNumber(
                            decimalSeparator,
                            thousandSeparator,
                            decimalScale,
                            minValue,
                        );

                        setFieldError(
                            "cost",
                            `El importe debe ser mayor a ${UtilsI18n.get(
                                `core.currency.label.${currencyToShow}`,
                            )} ${minValueDisplay}. Consulte las tasas vigentes`,
                        );

                        setFieldTouched("cost");
                    } else {
                        setFieldError("cost", EMPTY_STR);
                    }
                }
            }
        }
    };

    getRadioButtonOptions = () => [
        { id: "TODAY", label: "Hoy" },
        { id: "FUTURE", label: "En una fecha futura" },
    ];

    getRateTooltip = (thousandSeparator, decimalSeparator, decimalScale) => {
        const { values } = this.props;
        const { interest, rateMessage } = this.state;

        return (
            <GridLayout className="body position-clickable">
                <div />
                <div className="exchangeTip">
                    <GridLayout className="body position-clickable">
                        <p className="exchangeTipTitle">{UtilsI18n.get(`${FORM_ID}.exchangeHelp.rate`)}</p>
                        <p>{rateMessage}</p>
                    </GridLayout>
                    <GridLayout className="body position-clickable">
                        <p className="exchangeTipTitle">{UtilsI18n.get(`${FORM_ID}.exchangeHelp.interest`)}</p>
                        <NumberFormat
                            value={interest}
                            maxLength="25"
                            fixedDecimalScale
                            thousandSeparator={thousandSeparator}
                            displayType="text"
                            prefix={`${UtilsI18n.get(`core.currency.label.${values.currency}`)} `}
                            suffix="*"
                            decimalScale={decimalScale}
                            decimalSeparator={decimalSeparator}
                            type="text"
                        />
                    </GridLayout>
                    <p>
                        <span>{UtilsI18n.get(`${FORM_ID}.exchangeHelp.bottom`)}</span>
                    </p>
                </div>
            </GridLayout>
        );
    };

    handleCancel = () => {
        const { dispatch, setSubmitting, transaction } = this.props;

        dispatch(
            SelectorsActionForm.cancelTransactionCustom({
                credentials: {},
                idTransaction: transaction.idTransaction,
                formikBag: { setSubmitting },
            }),
        );
    };

    handleDraft = () => {
        const { dispatch, transaction, values } = this.props;
        const formDataValues = getFormData(values, transaction?.idTransaction);

        dispatch(
            SelectorsActionForm.saveDraft({
                idActivityDraft: "comex.imports.creditLetterOpen.send",
                idTransactionToSave: transaction?.idTransaction,
                transactionData: formDataValues || {},
            }),
        );
    };

    handleTemplateSave = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionTemplate.createTemplate());
    };

    handleTemplateLoad = () => {
        const { dispatch } = this.props;

        dispatch(SelectorsActionTemplate.toggleTemplateList());
    };

    handleSelectTemplate = (template) => {
        const { dispatch, lang, setErrors, setValues } = this.props;

        const templateToBeLoaded = template;

        const { amount: value, currency } = templateToBeLoaded.amount;

        const { decimalSeparator } = UtilsNumber.numberFormat(lang);
        const formattedValueByLang = UtilsNumber.toNumberFormat(value, decimalSeparator);

        if (templateToBeLoaded.valueDate) {
            const valueDate = toDate(templateToBeLoaded.valueDate);

            templateToBeLoaded.valueDate = valueDate;
        }
        if (templateToBeLoaded.expirationDate) {
            const expirationDate = toDate(templateToBeLoaded.expirationDate);

            templateToBeLoaded.expirationDate = expirationDate;
        }
        if (templateToBeLoaded.shippingExpirationDate) {
            const shippingExpirationDate = toDate(templateToBeLoaded.shippingExpirationDate);

            templateToBeLoaded.shippingExpirationDate = shippingExpirationDate;
        }

        setValues(templateToBeLoaded);
        setErrors({});

        dispatch(
            SelectorsActionComex.getAmountTextRequest({
                currency,
                value: value && value !== EMPTY_STR ? formattedValueByLang : 0,
            }),
        );
    };

    handleCheckChange = (fieldName) => {
        const { setFieldValue, values } = this.props;

        setFieldValue(fieldName, !values[fieldName]);

        if (fieldName === "domestic" && !values.domestic) {
            setFieldValue("shippedBy", EMPTY_STR);
        } else {
            setFieldValue("shippedBy", "AEREO");
        }
    };

    searchBank = (e) => {
        const { dispatch, setErrors, setSubmitting } = this.props;

        const request = {
            filters: {
                bank: {},
                channel: "frontend",
                code: e.target.value.toUpperCase(),
                country: EMPTY_STR,
                name: EMPTY_STR,
                type: BANKTYPE.SWIFT,
            },
            idField: "advisingBankBIC",
            setErrors,
            setSubmitting,
        };

        dispatch(SelectorsActionComex.searchBankRequest(request));
    };

    handleDocumentModal = (show) => {
        this.setState({
            showDocumentsModal: show,
        });
    };

    clearBankErrors = (e, fieldName) => {
        const { errors, setErrors, setFieldValue } = this.props;

        if (Object.keys(errors).includes("advisingBankName")) {
            setErrors(delete { ...errors }.advisingBankName);
        }

        if (Object.keys(errors).includes("advisingBankBIC")) {
            setErrors(delete { ...errors }.advisingBankBIC);
        }

        setFieldValue(fieldName, e.target.value.toUpperCase());
    };

    step1Form = () => {
        const {
            activeEnvironment,
            amountString,
            emailValidationRegex,
            errors,
            preData,
            setFieldValue,
            touched,
            transaction,
            values,
        } = this.props;
        const { modalData, showDetailsModal } = this.state;
        const termsAndConditions = "comex.imports.creditLetter.form.disclaimer";
        const i18nMap = {
            termsAndConditions,
        };

        const cancelEnabled = transaction && transaction.idTransactionStatus === STATUS.DRAFT;

        return (
            <React.Fragment>
                <div>
                    <div>
                        <div className="loadTemplates showTemplate">
                            <Button
                                onClick={this.handleTemplateLoad}
                                label="forms.templates.load"
                                bsStyle="default"
                                image="template.svg"
                            />
                        </div>
                        <div className="form-content">
                            <div className="form-section-title">{UtilsI18n.get(`${FORM_ID}.title`)}</div>

                            <div className="form-group--stepper-field">
                                <Field
                                    autoComplete="off"
                                    component={TextField}
                                    idForm={FORM_ID}
                                    name="advisingBankBIC"
                                    showEmptyError
                                    maxLength={11}
                                    style={{ textTransform: "uppercase" }}
                                    value={values.advisingBankBIC.toUpperCase()}
                                    handleOnChange={(e) => this.clearBankErrors(e, "advisingBankBIC")}
                                    handleOnChangeAtLast
                                    placeholder={EMPTY_STR}
                                    onBlur={(e) => this.searchBank(e)}
                                />
                                <LinkOptions
                                    handleClick={() => {
                                        const { dispatch } = this.props;
                                        dispatch(SelectorsActionComex.setBankSearchInfo({ showBankSearch: true }));
                                    }}
                                    text="Buscar"
                                />
                                <Field
                                    autoComplete="off"
                                    component={TextField}
                                    idForm={FORM_ID}
                                    name="advisingBankName"
                                    style={{ textTransform: "uppercase" }}
                                    handleOnChange={(e) => this.clearBankErrors(e, "advisingBankName")}
                                    handleOnChangeAtLast
                                    placeholder={EMPTY_STR}
                                    maxLength={160}
                                />
                                <Field
                                    autoComplete="off"
                                    component={TextArea}
                                    idForm={FORM_ID}
                                    name="advisingBankAddress"
                                    handleOnChange={(e) =>
                                        setFieldValue("advisingBankAddress", e.target.value.toUpperCase())
                                    }
                                    handleOnChangeAtLast
                                    style={{ textTransform: "uppercase" }}
                                    placeholder={EMPTY_STR}
                                    maxLength={200}
                                />
                                <FieldLabel labelKey={EMPTY_STR} />
                                <div className="checkbox-container">
                                    <div
                                        className={`check-group ${
                                            errors.confirmationInstructions && touched.confirmationInstructions
                                                ? "hasError"
                                                : EMPTY_STR
                                        } `}>
                                        <Field
                                            name="confirmationInstructions"
                                            label={UtilsI18n.get(
                                                "comex.imports.creditLetter.form.confirmationInstructions",
                                            )}
                                            onChange={() => this.handleCheckChange("confirmationInstructions")}
                                            checked={values.confirmationInstructions}
                                            component={Checkbox}
                                        />
                                        {errors.confirmationInstructions && touched.confirmationInstructions && (
                                            <FieldError error={errors.confirmationInstructions} />
                                        )}
                                    </div>

                                    <div
                                        className={`check-group ${
                                            errors.transferable && touched.transferable ? "hasError" : EMPTY_STR
                                        } `}>
                                        <Field
                                            name="transferable"
                                            label={UtilsI18n.get("comex.imports.creditLetter.form.transferable")}
                                            onChange={() => this.handleCheckChange("transferable")}
                                            checked={values.transferable}
                                            component={Checkbox}
                                        />
                                        {errors.transferable && touched.transferable && (
                                            <FieldError error={errors.transferable} />
                                        )}
                                    </div>

                                    <div
                                        className={`check-group ${
                                            errors.domestic && touched.domestic ? "hasError" : EMPTY_STR
                                        } `}>
                                        <Field
                                            name="domestic"
                                            label={UtilsI18n.get("comex.imports.creditLetter.form.domestica")}
                                            onChange={() => this.handleCheckChange("domestic")}
                                            checked={values.domestic}
                                            component={Checkbox}
                                        />
                                        {errors.domestic && touched.domestic && <FieldError error={errors.domestic} />}
                                    </div>
                                </div>
                                <Field
                                    component={DateField}
                                    maxDate={null}
                                    idForm={FORM_ID}
                                    id="expirationDate"
                                    name="expirationDate"
                                    autoComplete="off"
                                />
                                <Field
                                    autoComplete="off"
                                    component={TextField}
                                    idForm={FORM_ID}
                                    maxLength={29}
                                    name="expirationPlace"
                                    placeholder={EMPTY_STR}
                                    tooltip={UtilsI18n.get(`${FORM_ID}.expirationPlace.tooltip`)}
                                />
                                <FieldLabel labelKey={`${FORM_ID}.purchaserName`} />
                                {activeEnvironment.name}
                                <Field
                                    autoComplete="off"
                                    component={TextArea}
                                    idForm={FORM_ID}
                                    maxLength={65}
                                    name="purchaserAddress"
                                    placeholder={EMPTY_STR}
                                />
                                <Field
                                    autoComplete="off"
                                    component={TextField}
                                    idForm={FORM_ID}
                                    maxLength={34}
                                    name="beneficiaryLine1"
                                    placeholder={EMPTY_STR}
                                />
                                <Field
                                    autoComplete="off"
                                    component={TextArea}
                                    idForm={FORM_ID}
                                    maxLength={140}
                                    name="beneficiaryLine2to5"
                                    placeholder={EMPTY_STR}
                                />
                                <FieldLabel
                                    labelKey={`${FORM_ID}.amount.label`}
                                    classContainer={errors.quantity && touched.quantity && "has-error"}
                                />
                                <Field
                                    clearable={false}
                                    component={AmountField}
                                    idForm={FORM_ID}
                                    maxLength={getInteger("amount.length")}
                                    name="amount"
                                    hideLabel
                                    handleChange={this.handleChangeAmount}
                                    onCurrencyChange={this.handleCurrencyChange}
                                    data={{
                                        options: preData.currencyList.map((c) => {
                                            return { id: c, label: UtilsI18n.get(`core.currency.label.${c}`) };
                                        }),
                                    }}
                                    decimalPlaces={2}
                                    fixedDecimalScale
                                />
                                {errors.quantity && touched.quantity && <FieldError error={errors.quantity} />}
                                <FieldLabel labelKey={`${FORM_ID}.amountInWords`} />
                                <p>{amountString}</p>
                                <FieldLabel
                                    labelKey={`${FORM_ID}.amountTolerance.label`}
                                    classContainer={`${
                                        errors.amountTolerance && touched.amountTolerance ? "has-error" : EMPTY_STR
                                    }`}
                                />
                                <div className="checkbox-container">
                                    <Field
                                        name="amountTolerance"
                                        label={UtilsI18n.get("comex.imports.creditLetter.form.amountTolerance.description")}
                                        checked={values.amountTolerance}
                                        component={Checkbox}
                                        onChange={() => this.handleCheckChange("amountTolerance")}
                                    />
                                </div>
                                {errors.amountTolerance && touched.amountTolerance && (
                                    <FieldError error={errors.amountTolerance} />
                                )}
                                {!values.amountTolerance && (
                                    <React.Fragment>
                                        <FieldLabel labelKey={`${FORM_ID}.tolerance`} />
                                        <div className="flex-container">
                                            <div className="percentage">
                                                <FieldLabel labelKey={`${FORM_ID}.amountToleranceMin.label`} />
                                                <Field
                                                    autoComplete="off"
                                                    component={TextField}
                                                    hidelabel
                                                    maxLength={2}
                                                    pattern={ONLY_NUMBER}
                                                    idForm={FORM_ID}
                                                    name="amountToleranceMin"
                                                    placeholder={EMPTY_STR}
                                                />
                                                <FieldLabel labelKey={`${FORM_ID}.percentageSymbol`} />
                                            </div>
                                            <div className="percentage">
                                                <FieldLabel labelKey={`${FORM_ID}.amountToleranceMax.label`} />
                                                <Field
                                                    autoComplete="off"
                                                    component={TextField}
                                                    hidelabel
                                                    maxLength={2}
                                                    pattern={ONLY_NUMBER}
                                                    idForm={FORM_ID}
                                                    name="amountToleranceMax"
                                                    placeholder={EMPTY_STR}
                                                />
                                                <FieldLabel labelKey={`${FORM_ID}.percentageSymbol`} />
                                            </div>
                                        </div>
                                        <div
                                            className={`checkbox-container check-group ${
                                                errors.toleranceMerchandise && touched.toleranceMerchandise
                                                    ? "hasError"
                                                    : EMPTY_STR
                                            } `}>
                                                <Field
                                                    name="toleranceMerchandise"
                                                    label={UtilsI18n.get(
                                                        "comex.imports.creditLetter.form.toleranceMerchandise.label",
                                                    )}
                                                    checked={values.toleranceMerchandise}
                                                    component={Checkbox}
                                                    onChange={() => this.handleCheckChange("toleranceMerchandise")}
                                                />
                                            {errors.toleranceMerchandise && touched.toleranceMerchandise && (
                                                <FieldError error={errors.toleranceMerchandise} />
                                            )}
                                        </div>
                                    </React.Fragment>
                                )}
                                <Field
                                    component={Selector}
                                    idForm={FORM_ID}
                                    hidePlaceholder
                                    name="available"
                                    className="flex-container slideFromBottom selector-letter"
                                    optionClassName="needsclick"
                                    options={[
                                        {
                                            label: UtilsI18n.get(
                                                "comex.imports.creditLetter.form.available.PAGO_A_LA_VISTA",
                                            ),
                                            value: "PAGO_A_LA_VISTA",
                                        },
                                        {
                                            label: UtilsI18n.get("comex.imports.creditLetter.form.available.PLAZO"),
                                            value: "PLAZO",
                                        },
                                        {
                                            label: UtilsI18n.get("comex.imports.creditLetter.form.available.MIXTO"),
                                            value: "MIXTO",
                                        },
                                    ]}
                                    renderAs="combo"
                                    creatable={false}
                                    clearable={false}
                                />
                                {values.available === "MIXTO" && (
                                    <Field
                                        autoComplete="off"
                                        component={TextArea}
                                        hidelabel
                                        idForm={FORM_ID}
                                        name="availableMixed"
                                        placeholder={EMPTY_STR}
                                        maxLength={150}
                                    />
                                )}
                                {values.available === "PLAZO" && (
                                    <React.Fragment>
                                        <FieldLabel labelKey={`${FORM_ID}.availablePeriodDays.label`} />
                                        <div className="percentage term">
                                            <Field
                                                autoComplete="off"
                                                component={TextField}
                                                hidelabel
                                                maxLength={3}
                                                pattern={ONLY_NUMBER}
                                                idForm={FORM_ID}
                                                name="availablePeriodDays"
                                                placeholder={EMPTY_STR}
                                            />
                                            <p className="availablePeriodFrom-label">
                                                {UtilsI18n.get("comex.imports.creditLetter.form.from")}
                                            </p>
                                            <Field
                                                component={Selector}
                                                idForm={FORM_ID}
                                                hidePlaceholder
                                                name="availablePeriodFrom"
                                                className="flex-container slideFromBottom selector-letter"
                                                optionClassName="needsclick"
                                                options={[
                                                    {
                                                        label: UtilsI18n.get(
                                                            "comex.imports.creditLetter.form.availablePeriodFrom.FECHAEMBARQUE",
                                                        ),
                                                        value: "FECHAEMBARQUE",
                                                    },
                                                    {
                                                        label: UtilsI18n.get(
                                                            "comex.imports.creditLetter.form.availablePeriodFrom.FECHAFACTURA",
                                                        ),
                                                        value: "FECHAFACTURA",
                                                    },
                                                    {
                                                        label: UtilsI18n.get(
                                                            "comex.imports.creditLetter.form.availablePeriodFrom.VISTA",
                                                        ),
                                                        value: "VISTA",
                                                    },
                                                    {
                                                        label: UtilsI18n.get(
                                                            "comex.imports.creditLetter.form.availablePeriodFrom.OTRA",
                                                        ),
                                                        value: "OTRA",
                                                    },
                                                ]}
                                                renderAs="combo"
                                                creatable={false}
                                                clearable={false}
                                                hideLabel
                                            />
                                        </div>
                                        {values.availablePeriodFrom === "OTRA" && (
                                            <React.Fragment>
                                                <FieldLabel labelKey={EMPTY_STR} />
                                                <Field
                                                    autoComplete="off"
                                                    component={TextField}
                                                    hidelabel
                                                    idForm={FORM_ID}
                                                    name="availablePeriodOther"
                                                    placeholder={EMPTY_STR}
                                                    tooltip={UtilsI18n.get(`${FORM_ID}.availablePeriodOther.tooltip`)}
                                                />
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}
                                {values.available !== "PAGO_A_LA_VISTA" && (
                                    <div className="checkbox-container">
                                        <Field
                                            name="requiresLetterOfCredit"
                                            label={UtilsI18n.get("comex.imports.creditLetter.form.requiresLetterOfCredit")}
                                            onChange={() => this.handleCheckChange("requiresLetterOfCredit")}
                                            checked={values.requiresLetterOfCredit}
                                            component={Checkbox}
                                        />
                                    </div>
                                )}
                                <FieldLabel
                                    labelKey={`${FORM_ID}.partialShipments.label`}
                                    classContainer={`${
                                        errors.partialShipments && touched.partialShipments ? "has-error" : EMPTY_STR
                                    }`}
                                />
                                <div className="checkbox-container">
                                    <Field
                                        name="partialShipments"
                                        label={UtilsI18n.get(
                                            "comex.imports.creditLetter.form.partialShipments.description",
                                        )}
                                        checked={values.partialShipments}
                                        component={Checkbox}
                                        onChange={() => this.handleCheckChange("partialShipments")}
                                    />
                                </div>
                                {errors.partialShipments && touched.partialShipments && (
                                    <FieldError error={errors.partialShipments} />
                                )}
                                <FieldLabel
                                    labelKey={`${FORM_ID}.transhipment.label`}
                                    classContainer={`${
                                        errors.transhipment && touched.transhipment ? "has-error" : EMPTY_STR
                                    }`}
                                />
                                <div className="checkbox-container">
                                    <Field
                                        name="transhipment"
                                        label={UtilsI18n.get("comex.imports.creditLetter.form.transhipment.description")}
                                        checked={values.transhipment}
                                        component={Checkbox}
                                        onChange={() => this.handleCheckChange("transhipment")}
                                    />
                                </div>
                                {errors.transhipment && touched.transhipment && (
                                    <FieldError error={errors.transhipment} />
                                )}
                                <div className="field-spacing field-phone">
                                    <Field
                                        component={DateField}
                                        maxDate={null}
                                        idForm={FORM_ID}
                                        id="shippingExpirationDate"
                                        name="shippingExpirationDate"
                                        autoComplete="off"
                                    />
                                </div>
                                <Field
                                    autoComplete="off"
                                    component={TextField}
                                    idForm={FORM_ID}
                                    maxLength={390}
                                    name="shippingPeriod"
                                    placeholder={EMPTY_STR}
                                    tooltip={UtilsI18n.get(`${FORM_ID}.shippingPeriod.tooltip`)}
                                />
                                <Field
                                    autoComplete="off"
                                    component={TextField}
                                    idForm={FORM_ID}
                                    maxLength={65}
                                    name="deliveryLocation"
                                    placeholder={EMPTY_STR}
                                />
                                <Field
                                    autoComplete="off"
                                    component={TextField}
                                    idForm={FORM_ID}
                                    maxLength={65}
                                    name="shipmentAirPort"
                                    placeholder={EMPTY_STR}
                                />
                                <Field
                                    autoComplete="off"
                                    component={TextField}
                                    idForm={FORM_ID}
                                    maxLength={65}
                                    name="destinationAirPort"
                                    placeholder={EMPTY_STR}
                                />
                                <Field
                                    autoComplete="off"
                                    component={TextField}
                                    idForm={FORM_ID}
                                    maxLength={65}
                                    name="destinationPlace"
                                    placeholder={EMPTY_STR}
                                />
                                <Field
                                    autoComplete="off"
                                    component={TextField}
                                    idForm={FORM_ID}
                                    maxLength={650}
                                    name="description"
                                    placeholder={EMPTY_STR}
                                />
                                <DocumentSection
                                    formId={FORM_ID}
                                    {...this.props}
                                    editDocument={this.editDocument}
                                    showDocumentModal={this.handleDocumentModal}
                                />
                                <Field
                                    autoComplete="off"
                                    component={TextField}
                                    idForm={FORM_ID}
                                    maxLength={500}
                                    name="instructions"
                                    placeholder={EMPTY_STR}
                                />
                                <FieldLabel labelKey={`${FORM_ID}.docsPresentationDays.label`} />
                                <div className="percentage">
                                    <Field
                                        autoComplete="off"
                                        component={TextField}
                                        hidelabel
                                        maxLength={2}
                                        pattern={ONLY_NUMBER}
                                        idForm={FORM_ID}
                                        name="docsPresentationDays"
                                        placeholder={EMPTY_STR}
                                    />
                                    <I18n id="comex.imports.creditLetter.form.docsPresentationDays.description" />
                                </div>
                                <Field
                                    component={Selector}
                                    idForm={FORM_ID}
                                    hidePlaceholder
                                    name="bankExpenses"
                                    className="flex-container slideFromBottom selector-letter"
                                    optionClassName="needsclick"
                                    options={[
                                        {
                                            value: "BENEFICIARIO",
                                            label: UtilsI18n.get(`${FORM_ID}.bankExpenses.beneficiario`),
                                        },
                                        {
                                            value: "ORDENANTE",
                                            label: UtilsI18n.get(`${FORM_ID}.bankExpenses.ordenante`),
                                        },
                                        { value: "OTRO", label: UtilsI18n.get(`${FORM_ID}.bankExpenses.otros`) },
                                    ]}
                                    renderAs="combo"
                                    creatable={false}
                                    clearable={false}
                                />

                                {values.bankExpenses === "OTRO" && (
                                    <Field
                                        autoComplete="off"
                                        component={TextField}
                                        idForm={FORM_ID}
                                        name="bankExpensesOther"
                                        placeholder={EMPTY_STR}
                                        maxLength={150}
                                    />
                                )}

                                <Field
                                    name="files"
                                    idForm={FORM_ID}
                                    emptyMessageKey={`${FORM_ID}.attachments.emptyMessage`}
                                    maxFiles={5}
                                    errors={errors}
                                    component={FileUploader}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                />

                                <Field
                                    component={EmailList}
                                    idForm={FORM_ID}
                                    name="notificationEmailsField"
                                    hideSwiftTip
                                    className="form-group email-field"
                                    renderSuggestion={false}
                                    data={{ emailValidationRegex, addMessage: EMPTY_STR }}
                                    value={values.notificationEmailsField}
                                    optional={UtilsI18n.get("form.field.optional")}
                                    tooltip={UtilsI18n.get(`${FORM_ID}.notificationEmails.tooltip`)}
                                />
                                {preData.nextValidDate && (
                                    <Field
                                        component={Scheduler}
                                        data={{
                                            firstWorkingDate: preData.nextValidDate,
                                            maxDaysToSchedule: ConfigUtil.get(
                                                "client.comex.valueDate.maxDaysToSchedule",
                                            ),
                                            nonWorkingDays: preData.unavailableDays,
                                            lang: LANGUAGE.ES,
                                            mode: MODE.EDIT,
                                            programable: false,
                                            schedulable: true,
                                        }}
                                        name="scheduler"
                                        idForm={FORM_ID}
                                    />
                                )}
                            </div>

                            <hr />
                            <div className="form-section-title">{UtilsI18n.get(`${FORM_ID}.section.showPreview`)}</div>

                            <div className="ml-4">
                                <Field
                                    component={Termsandconditions}
                                    idForm={FORM_ID}
                                    name="disclaimer"
                                    mode="edit"
                                    className="form-control"
                                    i18nMap={i18nMap}
                                />
                            </div>
                        </div>
                    </div>
                    <footer>
                        <Button onClick={this.handleDraft} label="forms.saveDraft.link" />
                        <Button onClick={this.handleTemplateSave} label="forms.saveTemplate.link" />
                        {cancelEnabled && <Button onClick={this.handleCancel} label="forms.cancelTransaction.link" />}
                        <Button
                            key="primaryButton"
                            variant="primary"
                            bsStyle="primary"
                            label="comex.imports.creditLetter.form.attachments.next"
                            loading={false}
                            type="submit"
                        />
                    </footer>

                    <CreditLetterModal
                        show={showDetailsModal}
                        data={modalData}
                        currency={values.currency}
                        handleCloseModal={() => {
                            this.setState({ showDetailsModal: false });
                        }}
                    />
                </div>

                <CreditLetterModal
                    show={showDetailsModal}
                    data={modalData}
                    currency={values.currency}
                    handleCloseModal={() => {
                        this.setState({ showDetailsModal: false });
                    }}
                />
            </React.Fragment>
        );
    };

    bankSearchForm = () => {
        const {
            preData: { bankSearchCountryList },
        } = this.props;

        return (
            <BankSearchForm
                countries={bankSearchCountryList}
                handleSelect={this.handleSelectBank}
                scope={SCOPE.COMEX_IMPORT_CREDIT_LETTER_OPEN}
            />
        );
    };

    render() {
        const { showBankSearch, values } = this.props;
        const { documentEdition, showDocumentsModal } = this.state;

        return (
            <React.Fragment>
                <Form id={Style.ID} className="col col-12 col-lg-6 col-md-9 col-sm-12 creditLetter">
                    {!showBankSearch && this.step1Form()}
                    {showBankSearch && this.bankSearchForm()}
                </Form>

                <AddDocumentModal
                    show={showDocumentsModal}
                    terms={values.terms}
                    shippedBy={values.shippedBy}
                    submit={this.addEditDocument}
                    handleCloseModal={() => {
                        this.setState({ showDocumentsModal: false, documentEdition: {} });
                    }}
                    document={documentEdition}
                    documentTypeList={this.getAvailableDocumentTypeList()}
                />

                <CreateTemplateModal
                    values={values}
                    idActivityTemplate="comex.imports.creditLetterOpen.send"
                    backdrop="static"
                />
                <ListTemplatesModal
                    idActivityTemplate="comex.imports.creditLetterOpen.send"
                    onSelect={this.handleSelectTemplate}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store) || {},
    amountString: SelectorsStoreComex.getAmountText(store),
    fetching: SelectorsStoreComexImport.getFetching(store),
    // para guardar los values del form (borrador, template, o volver del paso 2 al 1)
    formData: SelectorsStoreComexImport.getImportCreditLetterOpenFormData(store),
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    lang: SelectorsStoreI18n.getLang(store),
    preData: SelectorsStoreComexImport.getImportCreditLetterOpenPre(store),
    selectedBank: SelectorsStoreComex.getSelectedBank(store),
    showBankSearch: SelectorsStoreComex.getShowBankSearch(store),
    transaction: SelectorsStoreComexImport.getTransaction(store),
});

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: false,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ formData }) => ({
            advisingBankAddress: formData?.advisingBankAddress || EMPTY_STR,
            advisingBankBIC: formData?.advisingBankBIC || EMPTY_STR,
            advisingBankName: formData?.advisingBankName || EMPTY_STR,
            amount: {
                currency: formData?.amount.currency || CDP_CURRENCY.USD,
                amount: formData?.amount.quantity || null,
            },
            amountTolerance: formData?.amountTolerance !== false,
            amountToleranceMax: formData?.amountToleranceMax || EMPTY_STR,
            amountToleranceMin: formData?.amountToleranceMin || EMPTY_STR,
            attachmentsNames: formData?.attachmentsNames || [],
            authorizeCopies: formData?.authorizeCopies || "BENEFICIARIO",
            authorizeDisclaimer: formData?.authorizeDisclaimer !== false,
            available: formData?.availableValueToStore || "PAGO_A_LA_VISTA",
            availableMixed: formData?.availableValueToStore || EMPTY_STR,
            availablePeriodDays: formData?.availablePeriodDays || EMPTY_STR,
            availablePeriodFrom: formData?.availablePeriodFrom || "FECHAEMBARQUE",
            availablePeriodOther: formData?.availablePeriodOther || EMPTY_STR,
            bankExpenses: formData?.bankExpenses || "BENEFICIARIO",
            bankExpensesOther: formData?.bankExpensesOther || EMPTY_STR,
            beneficiaryLine1: formData?.beneficiaryLine1 || EMPTY_STR,
            beneficiaryLine2to5: formData?.beneficiaryLine2to5 || EMPTY_STR,
            confirmationInstructions: formData?.confirmationInstructions || false,
            deliveryLocation: formData?.deliveryLocation || EMPTY_STR,
            description: formData?.description || EMPTY_STR,
            destinationAirPort: formData?.destinationAirPort || EMPTY_STR,
            destinationPlace: formData?.destinationPlace || EMPTY_STR,
            docsPresentationDays: formData?.docsPresentationDays || "21",
            documents: formData?.documents || [],
            domestic: formData?.domestic || false,
            expirationDate: formData?.expirationDate,
            expirationPlace: formData?.expirationPlace || EMPTY_STR,
            files: formData?.files || [],
            instructions: formData?.instructions || EMPTY_STR,
            notificationEmailsField: formData?.notificationEmailsField || [],
            partialShipments: formData?.partialShipments !== false,
            purchaserAddress: formData?.purchaserAddress || EMPTY_STR,
            requiresLetterOfCredit: formData?.requiresLetterOfCredit !== false,
            scheduler: formData?.scheduler,
            shipmentAirPort: formData?.shipmentAirPort || EMPTY_STR,
            shippedBy: formData?.shippedBy || "AEREO",
            shippingExpirationDate: formData?.shippingExpirationDate || EMPTY_STR,
            shippingPeriod: formData?.shippingPeriod || EMPTY_STR,
            termsDetail: formData?.termsDetail || EMPTY_STR,
            terms: formData?.terms || "EXW",
            toleranceMerchandise: formData?.toleranceMerchandise !== false,
            transferable: formData?.transferable || false,
            transhipment: formData?.transhipment !== false,
        }),
        handleSubmit: (data, formikBag) => {
            const { dispatch } = formikBag.props;

            const formData = getFormData(data, formikBag.props.transaction?.idTransaction);
            formData.purchaserName = formikBag.props.activeEnvironment.name;

            dispatch(SelectorsActionComexImport.importCreditLetterOpenPreviewRequest({ formData, formikBag }));
        },
    }),
)(Component);
