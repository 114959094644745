import React from "react";

import PropTypes from "prop-types";
import { Modal, Grid, Row, Col } from "react-bootstrap";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, EVENT_BACKBUTTON } from "~/constants";
import { SelectorsAction as SelectorsActionTemplate, SelectorsStore as SelectorsStoreTemplate } from "~/store/template";
import { generateId as GenerateId } from "~/util/general";

import I18n from "~/components/I18n";
import Image from "~/components/Image";
import ConfirmationModal from "~/pages/_components/ConfirmationModal";
import MainContainer from "~/pages/_components/MainContainer";

export const NAME = "ListTemplatesModal";

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        idForm: PropTypes.string,
        isFetching: PropTypes.bool.isRequired,
        isVisible: PropTypes.bool.isRequired,
        list: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                transactionData: PropTypes.string.any,
            }),
        ).isRequired,
        onSelect: PropTypes.func.isRequired,
    },
    defaults: {
        idForm: EMPTY_STR,
    },
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        deleteTemplate: false,
    };

    componentDidMount() {
        document.addEventListener(EVENT_BACKBUTTON, this.closeModal, true);
    }

    componentDidUpdate(prevProps) {
        const { dispatch, idActivityTemplate, idForm, isVisible } = this.props;

        if (!prevProps.isVisible && isVisible) {
            dispatch(SelectorsActionTemplate.loadTemplateList({ idForm, idActivityTemplate }));
        }
    }

    componentWillUnmount() {
        document.removeEventListener(EVENT_BACKBUTTON, this.closeModal, true);
    }

    closeModal = () => {
        const { isVisible } = this.props;

        if (isVisible) {
            const { dispatch } = this.props;

            dispatch(SelectorsActionTemplate.toggleTemplateList());
        }
    };

    handleHide = () => {
        const { dispatch, isVisible } = this.props;

        if (isVisible) {
            dispatch(SelectorsActionTemplate.toggleTemplateList());
        }
    };

    handleItemClick = (event, item) => {
        event.stopPropagation();

        this.setState({ deleteTemplate: true, item });
    };

    handleDeleteTemplate = () => {
        this.setState({ deleteTemplate: false }, () => {
            const { dispatch } = this.props;
            const { item } = this.state;

            dispatch(SelectorsActionTemplate.deleteTemplate(item));
        });
    };

    renderList = () => {
        const { dispatch, idForm, isFetching, list, onSelect } = this.props;
        const isEmpty = !isFetching && list.length === 0;

        return (
            <React.Fragment>
                {isEmpty && (
                    <p className="text-lead">
                        <I18n id="forms.templates.empty" />
                    </p>
                )}
                <ul className="navigational-list">
                    {list.map((item) => (
                        <li className="navigational-list-item" key={GenerateId()}>
                            <div className="flex-display">
                                <button
                                    type="button"
                                    onClick={() => {
                                        dispatch(
                                            SelectorsActionTemplate.persistLoadedTemplateName({
                                                idFormLoadedTemplate: idForm,
                                                loadedTemplateName: item.name,
                                            }),
                                        );
                                        onSelect(item.transactionData, idForm);
                                        this.handleHide();
                                    }}>
                                    <span>{item.name}</span>
                                </button>
                                <div className="close" onClick={(e) => this.handleItemClick(e, item)}>
                                    <Image className="svg-icon svg-caret" src="delete-message.svg" />
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </React.Fragment>
        );
    };

    render() {
        const { isFetching, isVisible } = this.props;
        const { deleteTemplate } = this.state;

        return (
            <React.Fragment>
                <Modal onHide={this.handleHide} show={isVisible}>
                    <Modal.Header closeButton>
                        <section className="container--layout align-items-center">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6}>
                                        <Modal.Title>
                                            <I18n id="forms.templates.title" />
                                        </Modal.Title>
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </Modal.Header>
                    <Modal.Body>
                        <MainContainer showLoader={isFetching} shouldHideOnLoad>
                            <section className="container--layout align-items-start flex-grow">
                                <Grid className="form-content">
                                    <Row className="justify-content-center">
                                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                            {this.renderList()}
                                        </Col>
                                    </Row>
                                </Grid>
                            </section>
                        </MainContainer>
                    </Modal.Body>
                </Modal>
                {deleteTemplate && (
                    <ConfirmationModal
                        show
                        handleAcept={() => this.handleDeleteTemplate()}
                        handleCancel={() => this.setState({ deleteTemplate: false })}
                        idLabel="forms.templates.deleteConfirmation"
                    />
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    isFetching: SelectorsStoreTemplate.isFetching(store),
    isVisible: SelectorsStoreTemplate.isTemplateListModalVisible(store),
    list: SelectorsStoreTemplate.getTemplateList(store),
});

export default Connect(mapStateToProps)(Component);
