import React from "react";

import classnames from "classnames";
import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import {
    SelectorsAction as SelectorsActionSwiftMessages,
    SelectorsStore as SelectorsStoreSwiftMessages,
} from "~/store/swift";
import { getInteger } from "~/util/config";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import DateField from "~/pages/_components/fields/DateField";
import Select from "~/pages/_components/fields/Select";
import TextField from "~/pages/_components/fields/TextField";
import AmountField from "~/pages/_components/fields/formik/AmountField";

import Style from "./_Filters.rules.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

const FORM_ID = "swift.list.filters";

export function Component(props) {
    const {
        i18n,
        values,
        resetForm,
        validCurrenciesList,
        types,
        subTypes,
        setFieldValue,
        keepFilters,
        dispatch,
    } = props;
    const {
        statusLabel,
        periodLabel,
        incomingOutgoing,
        incoming,
        outgoing,
        lastMovements,
        today,
        currentWeek,
        currentMonth,
        lastMonth,
        lastSixMonth,
        dateRange,
        amountFromPlaceholder,
        amountToPlaceholder,
        anyCurrency,
        allTypes,
        allSubTypes,
        moreFiltersLabel,
        lessFiltersLabel,
        filterLabel,
        cleanFiltersLabel,
    } = i18n;

    const { dateFrom, dateTo } = values;

    const periodOptions = [
        { value: "latest", label: lastMovements },
        { value: "today", label: today },
        { value: "this_week", label: currentWeek },
        { value: "this_month", label: currentMonth },
        { value: "last_month", label: lastMonth },
        { value: "last_six_months", label: lastSixMonth },
        { value: "custom", label: dateRange },
    ];
    const validCurrencies = validCurrenciesList.map((currency) => ({
        value: currency,
        label: UtilsI18n.get(`currency.label.${currency}`),
    }));

    const statusOptions = {
        T: { value: "T", label: incomingOutgoing },
        E: { value: "E", label: incoming },
        S: { value: "S", label: outgoing },
    };

    const [typeOptions, setTypeOption] = React.useState([]);
    const [subTypeOptions, setSubTypeOptions] = React.useState([]);
    const [inOutOptions, setInOutOptions] = React.useState([]);

    const updateType = React.useCallback(() => {
        const typesList = types[values.incomingOutgoing];
        let options = [];
        let existType = false;
        if (typesList) {
            options = typesList.map((t) => {
                if (t === values.type) {
                    existType = true;
                }
                return {
                    value: t,
                    label: `${t} - ${UtilsI18n.get(`swift.message.type.MT${t}.description`)}`,
                };
            });
        }
        options = [{ value: "all", label: allTypes }, ...options];
        if (!existType) {
            setFieldValue("type", "all"); // Le pongo el valor por defecto
        }
        return options;
    }, [allTypes, setFieldValue, types, values.incomingOutgoing, values.type]);

    const updateSubtype = React.useCallback(() => {
        const subTypesList = subTypes[`${values.incomingOutgoing}-${values.type}`];
        let options = [];
        let existSubtype = false;
        if (subTypesList) {
            options = subTypesList.map((s) => {
                if (s === values.subType) {
                    existSubtype = true;
                }
                return {
                    value: s,
                    label: UtilsI18n.get(`swift.subtype.label.${s}`),
                };
            });
        }
        const allSubTypesItem = { value: "all", label: allSubTypes };
        options = [allSubTypesItem, ...options];
        if (!existSubtype) {
            setFieldValue("subType", "all"); // Le pongo el valor por defecto
        }
        return options;
    }, [allSubTypes, setFieldValue, subTypes, values.incomingOutgoing, values.subType, values.type]);

    const currencyOptions = [{ value: EMPTY_STR, label: anyCurrency }, ...validCurrencies];

    const [customPeriod, setCustomPeriod] = React.useState(false);
    const [moreFilters, setMoreFilters] = React.useState(false);

    // TO DO: revisar la logica ---> react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (keepFilters) {
            const { currency, type, subtype, beneficiary, reference, amountFrom, amountTo } = values;
            dispatch(SelectorsActionSwiftMessages.keepFilters({ keepFilters: false }));
            if (
                currency !== EMPTY_STR ||
                type !== "all" ||
                subtype !== "all" ||
                beneficiary !== EMPTY_STR ||
                reference !== EMPTY_STR ||
                amountFrom !== null ||
                amountTo !== null
            ) {
                setMoreFilters(true);
            }
        }
    }, [dispatch, keepFilters, values]);

    React.useEffect(() => {
        const updatedTypeOptions = updateType();
        const updatedSubTypeOptions = updateSubtype();
        setTypeOption(updatedTypeOptions);
        setSubTypeOptions(updatedSubTypeOptions);
    }, [types, updateSubtype, updateType, values.incomingOutgoing]); // Cargar los datos cuando cambia la prop

    React.useEffect(() => {
        setSubTypeOptions(updateSubtype());
    }, [updateSubtype, values.type]);

    React.useEffect(() => {
        setCustomPeriod(values.period === "custom");
    }, [values.period]);

    React.useEffect(() => {
        if (Object.keys(types).length) {
            const typesKeys = Object.keys(types);
            const inOutTypes =
                typesKeys.length === 3
                    ? Object.values(statusOptions)
                    : Object.values(statusOptions).filter((o) => typesKeys.includes(o.value) && o.value !== "T");

            setInOutOptions(inOutTypes);
            setFieldValue("incomingOutgoing", inOutTypes[0].value);
        }
    }, [types]);

    const handleResetFilters = () => {
        resetForm();
    };

    return (
        <Form className={CLASS}>
            <div className={classnames("basicFilter", { extended: customPeriod })}>
                <Select
                    clearable={false}
                    labelKey="label"
                    options={inOutOptions}
                    valueKey="value"
                    name="incomingOutgoing"
                    label={statusLabel}
                />
                <Select
                    clearable={false}
                    labelKey="label"
                    options={periodOptions}
                    valueKey="value"
                    name="period"
                    label={periodLabel}
                />
                {customPeriod && (
                    <React.Fragment>
                        <Field
                            component={DateField}
                            hideLabel
                            idForm={FORM_ID}
                            name="dateFrom"
                            selectsStart
                            startDate={dateFrom}
                            endDate={dateTo}
                            showMonthYearDropdown
                            maxDate={dateTo}
                            selectedDate={dateFrom}
                        />
                        <Field
                            component={DateField}
                            hideLabel
                            idForm={FORM_ID}
                            name="dateTo"
                            showMonthYearDropdown
                            selectsEnd
                            startDate={dateFrom}
                            endDate={dateTo}
                            minDate={dateFrom}
                            selectedDate={dateTo}
                        />
                    </React.Fragment>
                )}

                <Button id="filter" variant="primary" type="submit">
                    {filterLabel}
                </Button>
                <Button
                    id="cleanFilters"
                    variant="secondary"
                    className="block"
                    type="submit"
                    onClick={handleResetFilters}>
                    {cleanFiltersLabel}
                </Button>
            </div>
            <Button
                id="toggleMoreFilters"
                className={classnames({ "is-open": moreFilters }, "btnMoreFilters link")}
                onClick={() => setMoreFilters(!moreFilters)}>
                {!moreFilters ? moreFiltersLabel : lessFiltersLabel}
            </Button>
            {moreFilters && (
                <div className="moreFilters">
                    <Select
                        clearable={false}
                        labelKey="label"
                        options={currencyOptions}
                        valueKey="value"
                        name="currency"
                        label={UtilsI18n.get("swift.list.columnHeading.currency")}
                    />

                    <Field
                        clearable={false}
                        component={AmountField}
                        idForm={FORM_ID}
                        maxLength={getInteger("amount.length")}
                        name="amountFrom"
                        placeholder={amountFromPlaceholder}
                        searchable={false}
                        decimalPlaces={2}
                        fixedDecimalScale
                        hideCurrency
                    />

                    <Field
                        clearable={false}
                        component={AmountField}
                        idForm={FORM_ID}
                        maxLength={getInteger("amount.length")}
                        name="amountTo"
                        placeholder={amountToPlaceholder}
                        searchable={false}
                        decimalPlaces={2}
                        fixedDecimalScale
                        hideLabel
                        hideCurrency
                        tooltip={UtilsI18n.get("swift.filters.more.amount.tooltip")}
                    />

                    <Select
                        clearable={false}
                        labelKey="label"
                        options={typeOptions}
                        valueKey="value"
                        name="type"
                        label={UtilsI18n.get("swift.list.columnHeading.type")}
                    />
                    <Select
                        clearable={false}
                        labelKey="label"
                        options={subTypeOptions}
                        valueKey="value"
                        name="subType"
                        label={UtilsI18n.get("swift.list.columnHeading.subtype")}
                    />
                    <Field
                        autoFocus={false}
                        component={TextField}
                        idForm={FORM_ID}
                        name="beneficiary"
                        type="text"
                        hidePlaceholder="true"
                        tooltip={UtilsI18n.get("swift.filters.more.beneficiary.tooltip")}
                    />
                    <Field
                        autoFocus={false}
                        component={TextField}
                        idForm={FORM_ID}
                        name="reference"
                        type="text"
                        hidePlaceholder="true"
                        tooltip={UtilsI18n.get("swift.filters.more.reference.tooltip")}
                    />
                </div>
            )}
        </Form>
    );
}

const mapStateToProps = (store) => ({
    i18n: {
        lastMovements: StoreI18n.getMessage(store, "swift.list.filter.periodOptions.lastMovements"),
        today: StoreI18n.getMessage(store, "swift.list.filter.periodOptions.today"),
        currentWeek: StoreI18n.getMessage(store, "swift.list.filter.periodOptions.currentWeek"),
        currentMonth: StoreI18n.getMessage(store, "swift.list.filter.periodOptions.currentMonth"),
        lastMonth: StoreI18n.getMessage(store, "swift.list.filter.periodOptions.lastMonth"),
        lastSixMonth: StoreI18n.getMessage(store, "swift.list.filter.periodOptions.lastSixMonth"),
        dateRange: StoreI18n.getMessage(store, "swift.list.filter.periodOptions.dateRange"),
        incomingOutgoing: StoreI18n.getMessage(store, "swift.list.filter.statusOptions.incomingOutoing"),
        incoming: StoreI18n.getMessage(store, "swift.list.filter.statusOptions.incoming"),
        outgoing: StoreI18n.getMessage(store, "swift.list.filter.statusOptions.outgoing"),
        statusLabel: StoreI18n.getMessage(store, "swift.list.filter.status.label"),
        periodLabel: StoreI18n.getMessage(store, "swift.list.filter.period.label"),
        amountFromPlaceholder: StoreI18n.getMessage(store, "swift.list.filters.amountFrom.placeholder"),
        amountToPlaceholder: StoreI18n.getMessage(store, "swift.list.filters.amountTo.placeholder"),
        anyCurrency: StoreI18n.getMessage(store, "currency.label.any"),
        allTypes: StoreI18n.getMessage(store, "client.swift.allTypes.title"),
        allSubTypes: StoreI18n.getMessage(store, "client.swift.allSubTypes.title"),
        moreFiltersLabel: StoreI18n.getMessage(store, "list.paymentOrders.filters.button.moreFilters"),
        lessFiltersLabel: StoreI18n.getMessage(store, "list.paymentOrders.filters.button.lessFilters"),
        filterLabel: StoreI18n.getMessage(store, "product.filters.filter"),
        cleanFiltersLabel: StoreI18n.getMessage(store, "product.filters.cleanFilters"),
    },
    storeFilters: SelectorsStoreSwiftMessages.getFilters(store),
    keepFilters: SelectorsStoreSwiftMessages.keepFilters(store),
    validCurrenciesList: SelectorsStoreSwiftMessages.getValidCurrencyTypes(store),
    types: SelectorsStoreSwiftMessages.getTypes(store),
    subTypes: SelectorsStoreSwiftMessages.getSubTypes(store),
});

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        // Defaults are here because of the cleanFilters func. it sends current
        // values in the store instead of default ones
        mapPropsToValues: (props) => {
            const { storeFilters, keepFilters } = props;
            if (keepFilters) {
                return storeFilters;
            }
            return {
                incomingOutgoing: "T",
                period: "latest",
                currency: EMPTY_STR,
                type: "all",
                subtype: "all",
                beneficiary: EMPTY_STR,
                reference: EMPTY_STR,
                amountFrom: null,
                amountTo: null,
            };
        },
        handleSubmit: ({ ...filters }, formikBag) => {
            const { dispatch } = formikBag.props;
            dispatch(
                SelectorsActionSwiftMessages.loadListSwiftMessagesRequest({
                    filters,
                    offset: 1,
                    moreOrders: false,
                }),
            );
        },
    }),
)(Component);
