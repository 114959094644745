import { CHANNEL, EMPTY_STR, RESPONSE_TYPE } from "~/constants";
import Store from "~/store";
import CommonResponse from "~/store/CommonResponse";
import ErrorResponse from "~/store/ErrorResponse";
import DeviceUtils from "~/util/device";

const E_CONN_ABORTED = "ECONNABORTED";
const HEADERS_COMMON_ACCEPT = "application/json, application/octet-stream";
const HEADERS_CONTENT_TYPE = "application/json";
const SERIALIZER = "json";

export const E_SSL_HANDSHAKE = "API500E";

const PLUGIN_ERROR = {
    GENERIC: -1,
    SSL_EXCEPTION: -2,
    SERVER_NOT_FOUND: -3,
    TIMEOUT: -4,
    UNSUPPORTED_URL: -5,
    NOT_CONNECTED: -6,
    POST_PROCESSING_FAILED: -7,
    ABORTED: -8,
};

const errorInterceptor = (errorParam, resolve, reject, url) => {
    let code;
    let message;
    let request;
    let response;

    try {
        response = JSON.parse(errorParam.error);
    } catch (syntaxError) {
        // It's not a JSON
        message = errorParam.error;
    }

    /*
     * Error de request
     * The plugin returns 500 without headers
     *
     * Possible errors
     *
     * Timeout:
     *       error: "Request timed out"
     *       status: -4;
     *       (transform to code): ECONNABORTED
     *
     * Perhaps Internet is down:
     *       error: "There was an error with the request: Failed to connect"
     *                  ||  "The Internet connection appears to be offline."
     *       status: -1 || -6
     *
     * SSL - handshake :
     *       error: "TLS connection could not be established"
     *       status: -2;
     *       (transform to code): ESSLHANDSHAKE
     *
     */

    if (
        errorParam.status === PLUGIN_ERROR.GENERIC ||
        errorParam.status === PLUGIN_ERROR.SSL_EXCEPTION ||
        errorParam.status === PLUGIN_ERROR.SERVER_NOT_FOUND ||
        errorParam.status === PLUGIN_ERROR.TIMEOUT ||
        errorParam.status === PLUGIN_ERROR.NOT_CONNECTED
    ) {
        request = true;
    }

    if (errorParam.status === PLUGIN_ERROR.TIMEOUT) {
        code = E_CONN_ABORTED;
    }

    if (errorParam.status === PLUGIN_ERROR.SSL_EXCEPTION) {
        code = E_SSL_HANDSHAKE;
    }

    const error = new ErrorResponse({
        code,
        headers: errorParam.headers,
        message,
        request,
        response,
        status: errorParam.status,
        url,
    });

    if (error.status === 304) {
        resolve(error.response);
    }

    // The attribute is added to know it's a saga's error
    error.httpError = true;

    reject(error);
};

const responseInterceptor = (responseParam, resolve, reject) => {
    let data;

    try {
        data = JSON.parse(responseParam.data);
    } catch (syntaxError) {
        // It's not a JSON
        data = responseParam.data;
    }

    const response = new CommonResponse({
        data,
        headers: responseParam.headers,
        status: responseParam.status,
    });

    if (response.status === 204) {
        resolve(response);
    }

    if (!response.data.code || response.data.code.endsWith(RESPONSE_TYPE.ERROR)) {
        reject(response);
    }

    // The error type is kept (I - Info, W - Warning, E - Error)
    response.type = response.data.code.slice(-1);

    resolve(response);
};

export const commonGet = (url, data, headers) =>
    new Promise((resolve, reject) => {
        window.cordovaHTTP.httpGet(url, data, headers).then(
            (response) => {
                responseInterceptor(response, resolve, reject);
            },
            (error) => {
                errorInterceptor(error, resolve, reject, url);
            },
        );
    });

export const commonPost = (url, data, headers) =>
    new Promise((resolve, reject) => {
        window.cordovaHTTP.httpPost(url, data, headers).then(
            (response) => {
                responseInterceptor(response, resolve, reject);
            },
            (error) => {
                errorInterceptor(error, resolve, reject, url);
            },
        );
    });

export const buildUrl = (url) => window.API_URL + url;

export const channel = () => (DeviceUtils.isMobileNative() ? CHANNEL.PHONEGAP : CHANNEL.FRONTEND);

export const downloadWithAccessToken = (idActivity, params) => {
    const { idFile } = params;
    const url = buildUrl(`${idActivity}/${idFile}`);

    return commonGet(url, null, {
        responseType: "blob",
    });
};

export const executeAnonymous = (idActivity, params, etag = EMPTY_STR) => {
    const { lang } = Store.getState().i18n;
    const url = buildUrl(idActivity);

    const data = {
        lang,
        ...params,
        channel: channel(),
    };

    const headers = {
        "If-None-Match": etag,
        Authorization: EMPTY_STR,
    };

    return commonPost(url, data, headers);
};

export const executeAnonymousWithWebToken = (idActivity, params, etag = EMPTY_STR) => {
    const { lang } = Store.getState().i18n;
    const { isTrusted } = Store.getState().config;
    let paramsObject = params;
    let { _otp } = params;
    const url = buildUrl(idActivity);

    if (isTrusted) {
        const { webToken } = Store.getState().session;
        _otp = webToken;
        paramsObject = { ...params, _otp };
    }

    const data = {
        lang,
        ...paramsObject,
        channel: channel(),
    };

    const headers = {
        "If-None-Match": etag,
        Authorization: EMPTY_STR,
    };

    return commonPost(url, data, headers);
};

export const executeWithAccessToken = (idActivity, params, etag = EMPTY_STR) => {
    const url = buildUrl(idActivity);

    const data = {
        ...params,
        channel: channel(),
    };

    const headers = {
        "If-None-Match": etag,
    };

    return commonPost(url, data, headers);
};

export const executeWithExchangeToken = (idActivity, params, exchangeToken, etag = EMPTY_STR) => {
    const { lang } = Store.getState().i18n;
    const url = buildUrl(idActivity);

    const data = {
        lang,
        channel: channel(),
        ...params,
    };

    const headers = {
        "If-None-Match": etag,
        Authorization: `exchange ${exchangeToken}`,
    };

    return commonPost(url, data, headers);
};

export const executeWithWebToken = (idActivity, params, etag = EMPTY_STR) => {
    const { isTrusted } = Store.getState().config;
    let paramsObject = params;
    let { _otp } = params;
    const url = buildUrl(idActivity);

    if (isTrusted) {
        const { webToken } = Store.getState().session;
        _otp = webToken;
        paramsObject = { ...params, _otp };
    }

    const data = {
        ...paramsObject,
        channel: channel(),
    };

    const headers = {
        "If-None-Match": etag,
    };

    return commonPost(url, data, headers);
};

export const executeWithWebTokenWithExchange = (idActivity, params, exchangeToken, etag = EMPTY_STR) => {
    const { isTrusted } = Store.getState().config;
    let paramsObject = params;
    let { _otp } = params;
    const url = buildUrl(idActivity);

    if (isTrusted) {
        const { webToken } = Store.getState().session;
        _otp = webToken;
        paramsObject = { ...params, _otp };
    }

    const data = {
        ...paramsObject,
        channel: channel(),
    };

    const headers = {
        "If-None-Match": etag,
        Authorization: `exchange ${exchangeToken}`,
    };

    return commonPost(url, data, headers);
};

export const setAuthToken = (token) => {
    window.cordovaHTTP.setHeader("Authorization", `bearer ${token}`);
};

export const setHeaders = (
    accept = HEADERS_COMMON_ACCEPT,
    contentType = HEADERS_CONTENT_TYPE,
    serializer = SERIALIZER,
) => {
    window.cordovaHTTP.setHeader("Accept", accept);
    window.cordovaHTTP.setHeader("Content-type", contentType);
    window.cordovaHTTP.setDataSerializer(serializer);
};
