import React from "react";

import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import * as configUtils from "~/util/config";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import I18n from "~/components/I18n";
import FileUploader from "~/pages/_components/FileUploader";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";

export const NAME = "FileUploadComex";

export const PROP = {
    types: {
        ...TypesRedux,
    },
    defaults: {
        ...DefaultsRedux,
    },
};

const ACTIVITY_ID = "comex.imports.creditLetterOpen.send";
const FIELD_ID = "files";
const MAX_FILE_SIZE_MB = 2;

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        files: [],
        canRestoreFiles: true,
    };

    ACCEPTED_FILE_TYPES = configUtils.getArray("comex.attachments.allowedMimeTypes", [
        "application/vnd.oasis.opendocument.text",
        "application/vnd.oasis.opendocument.spreadsheet",
        "application/vnd.oasis.opendocument.presentation",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/pdf",
        "application/zip",
        "application/x-rar",
        "application/x-zip-compressed",
        "application/vnd.rar",
        "application/txt",
        "text/plain",
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/tiff",
        "image/tif",
        "image/gif",
        "image/bmp",
    ]);

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.field?.value?.length > 0 && prevState.files.length === 0 && prevState.canRestoreFiles) {
            return {
                ...prevState,
                files: nextProps.field.value,
            };
        }
        return null;
    }

    handleFileProcess = (response) => {
        const { values, setFieldValue } = this.props;

        const { data } = response;
        if (!data.isRestored) {
            const files = values && values.files ? [...values.files] : [];
            files.push(data.idFile);

            const attachmentsNames = values && values.attachmentsNames ? [...values.attachmentsNames] : [];
            attachmentsNames.push(data.fileName);

            this.setState(
                {
                    canRestoreFiles: false,
                },
                () => {
                    setFieldValue("files", files);
                    setFieldValue("attachmentsNames", attachmentsNames);
                },
            );
        }
    };

    handleRemoveFile = (serverId) => {
        const { values, setFieldValue } = this.props;

        if (values.files && values.files.length > 0) {
            const index = values.files.indexOf(serverId.toString());
            const filesCopy = [...values.files];

            if (index >= 0) {
                filesCopy.splice(index, 1);
            }

            setFieldValue("files", filesCopy);
        }
    };

    render() {
        const { values, setValue, errors, field, name, idForm, maxFiles, emptyMessageKey } = this.props;

        const { files } = this.state;
        return (
            <React.Fragment>
                <FieldLabel labelKey={`${idForm}.${FIELD_ID}.label`} />
                <div className="field-files--information">
                    <span>{UtilsI18n.get(`comex.files.permitedExtentionsAndSize`)}</span>
                </div>
                <FileUploader
                    acceptedFileTypes={this.ACCEPTED_FILE_TYPES}
                    allowMultiple
                    files={files}
                    idActivity={ACTIVITY_ID}
                    idForm={EMPTY_STR}
                    idFormField={FIELD_ID}
                    labelIdle={UtilsI18n.get(`${idForm}.${FIELD_ID}.attachDocument`)}
                    labelValidation={{ fileValidateTypeLabelExpectedTypes: UtilsI18n.get("comex.filesLegal.type") }}
                    maxFileSize={`${MAX_FILE_SIZE_MB}mb`}
                    maxFiles={maxFiles}
                    maxTotalFileSize={`${MAX_FILE_SIZE_MB}mb`}
                    name={name}
                    onFileProcess={this.handleFileProcess}
                    onRemoveFile={this.handleRemoveFile}
                    setValue={setValue}
                />
                {emptyMessageKey && (!values.files || values.files.length === 0) && (
                    <React.Fragment>
                        <I18n className="emptyMessage" id={emptyMessageKey} />
                    </React.Fragment>
                )}
                <FieldError error={errors[field.name]} />
            </React.Fragment>
        );
    }
}

export default Connect(null)(Component);
