import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE, VALIDATION_ERROR, FORM_FIELD_ERRORS } from "~/constants";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import {
    credentialsWithUnderscore as CredentialsWithUnderscore,
    hasIncorrectCredentials as HasIncorrectCredentials,
    getTouchedFields as GetTouchedFields,
} from "~/util/form";
import * as UtilI18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction } from "./_selectors";

export default [
    // Request Bonds And Guarantees
    TakeLatest(TYPE.REQUEST_BONDS_AND_GUARANTEES_PRE_REQUEST, requestBondsAndGuaranteesPre),
    TakeLatest(TYPE.REQUEST_BONDS_AND_GUARANTEES_PREVIEW_REQUEST, requestBondsAndGuaranteesPreview),
    TakeLatest(TYPE.REQUEST_BONDS_AND_GUARANTEES_SEND_REQUEST, requestBondsAndGuarantees),

    // Modify Bonds And Guarantees
    TakeLatest(TYPE.MODIFY_BONDS_AND_GUARANTEES_PRE_REQUEST, modifyBondsAndGuaranteesPre),
    TakeLatest(TYPE.MODIFY_BONDS_AND_GUARANTEES_PREVIEW_REQUEST, modifyBondsAndGuaranteesPreview),
    TakeLatest(TYPE.MODIFY_BONDS_AND_GUARANTEES_SEND_REQUEST, modifyBondsAndGuarantees),
];

// Request Bonds And Guarantees
function* requestBondsAndGuaranteesPre() {
    const { type, data } = yield Call(SelectorsMiddleware.requestBondsAndGuaranteesPreRequest);
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.requestBondsAndGuaranteesPreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_BONDS_AND_GUARANTEES],
            }),
        );
    } else {
        yield Put(SelectorsAction.requestBondsAndGuaranteesPreSuccess({ ...data.data }));
    }
}

function* requestBondsAndGuaranteesPreview({ formData, formikBag }) {
    const { type, data } = yield Call(SelectorsMiddleware.requestBondsAndGuaranteesPreviewRequest, formData);
    formikBag.setSubmitting(false);
    const errorMessage =
        data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.requestBondsAndGuaranteesPreviewFailure());
        formikBag.setErrors(data.data);
        formikBag.setTouched(GetTouchedFields(data.data));
        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_BONDS_AND_GUARANTEES],
            }),
        );
    } else {
        yield Put(SelectorsAction.requestBondsAndGuaranteesPreviewSuccess({ ...data.data }));
    }
}

function* requestBondsAndGuarantees({ formData, credentials, idTransaction, formikBag }) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);
    const { type, data } = yield Call(SelectorsMiddleware.requestBondsAndGuaranteesRequest, {
        ...formData,
        idTransaction,
        ...credentialsWithUnderscore,
    });
    formikBag.setSubmitting(false);

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);
        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
            yield Put(SelectorsAction.requestBondsAndGuaranteesSendFailure());
        } else {
            yield Put(SelectorsAction.comexFormSendDataFailure());
            const errorMessage =
                data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.COMEX_BONDS_AND_GUARANTEES],
                }),
            );
        }
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: data.idTransaction,
        });
        yield Put(SelectorsAction.requestBondsAndGuaranteesSendSuccess({ transaction }));
    }
}

// Modify Bonds And Guarantees

function* modifyBondsAndGuaranteesPre() {
    const { type, data } = yield Call(SelectorsMiddleware.modifyBondsAndGuaranteesPreRequest);
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.modifyBondsAndGuaranteesPreFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_BONDS_AND_GUARANTEES_MODIFY],
            }),
        );
    } else {
        yield Put(SelectorsAction.modifyBondsAndGuaranteesPreSuccess({ ...data.data }));
    }
}

function* modifyBondsAndGuaranteesPreview({ formData, formikBag }) {
    const { type, data } = yield Call(SelectorsMiddleware.modifyBondsAndGuaranteesPreviewRequest, formData);
    formikBag.setSubmitting(false);
    const errorMessage =
        data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.modifyBondsAndGuaranteesPreviewFailure());
        formikBag.setErrors(data.data);
        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.COMEX_BONDS_AND_GUARANTEES_MODIFY],
            }),
        );
    } else {
        yield Put(SelectorsAction.modifyBondsAndGuaranteesPreviewSuccess({ ...data.data }));
    }
}

function* modifyBondsAndGuarantees({ formData, credentials, formikBag }) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { type, data } = yield Call(SelectorsMiddleware.modifyBondsAndGuaranteesRequest, {
        ...formData,
        ...credentialsWithUnderscore,
    });
    const { idTransaction } = data;
    formikBag.setSubmitting(false);

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);
        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
            yield Put(SelectorsAction.modifyBondsAndGuaranteesSendFailure({ errors: data.data }));
        } else {
            yield Put(SelectorsAction.comexFormSendDataFailure());
            const errorMessage =
                data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilI18n.get(FORM_FIELD_ERRORS) : data.message;
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.COMEX_BONDS_AND_GUARANTEES_MODIFY],
                }),
            );
        }
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(SelectorsAction.modifyBondsAndGuaranteesSendSuccess({ transaction }));
    }
}
