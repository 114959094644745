import React from "react";

import PropTypes from "prop-types";

import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";

import Style from "./SectionTitle.rules.scss";

export const NAME = "SectionTitle";

export const PROP = {
    types: {
        title: PropTypes.string,
        onClick: PropTypes.func,
        showBottom: PropTypes.bool,
        editLabel: PropTypes.string,
    },
    defaults: { title: "", onClick: () => {}, showBottom: false, editLabel: "global.edit" },
};
export function Component({ title, showBottom, onClick, editLabel }) {
    return (
        <header id={Style.ID}>
            <h3>
                <I18n id={title} />
            </h3>
            {showBottom && (
                <div className="container-button">
                    <Button type="button" size="sm" variant="secondary" onClick={() => onClick()}>
                        <I18n id={editLabel} />
                    </Button>
                </div>
            )}
        </header>
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default React.memo(Component);
