import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

const formatDate = (date) => date.toISOString().split("T")[0];

export const SelectorsMiddleware = {
    downloadStatement: (props) => API.executeWithAccessToken(PATH.ACCOUNTS_DOWNLOAD_STATEMENT_LINE, props),
    editMovementNote: (props) => API.executeWithAccessToken(PATH.NOTES_EDIT_STATEMENT_NOTE, props),
    fetchAccount: (props) => API.executeWithAccessToken(PATH.ACCOUNTS_READ, props),
    downloadMovements: ({
        idAccount,
        format,
        sequenceNumber,
        dateFrom = null,
        dateTo = null,
        period = "LATEST_MOVS",
        filters,
    }) =>
        API.executeWithAccessToken(PATH.ACCOUNTS_DOWNLOAD_MOVEMENTS, {
            idAccount,
            format,
            sequenceNumber,
            dateFrom: dateFrom && formatDate(dateFrom),
            dateTo: dateTo && formatDate(dateTo),
            ...filters,
            period,
        }),
    getBillingTicketPDF: (props) => API.executeWithAccessToken(PATH.BILLING_TICKET_PDF, props),
    listAccounts: () => API.executeWithAccessToken(PATH.ACCOUNTS_LIST),
    listBillingTickets: (props) => API.executeWithAccessToken(PATH.BILLING_TICKETS, props),
    listMovements: (props) => {
        const { idAccount, filters } = props;
        let { dateFrom, dateTo } = filters;
        const { channels, check, minAmount, maxAmount, pageNumber, reference } = filters;

        dateFrom = dateFrom ? formatDate(dateFrom) : null;
        dateTo = dateTo ? formatDate(dateTo) : null;

        return API.executeWithAccessToken(PATH.ACCOUNTS_LIST_STATEMENTS, {
            idAccount,
            channels,
            check,
            dateFrom,
            dateTo,
            minAmount,
            maxAmount,
            pageNumber,
            reference,
        });
    },
    listStatements: (props) => API.executeWithAccessToken(PATH.ACCOUNTS_LISTSTATEMENT_LINES, props),
    movementDetails: (props) => API.executeWithAccessToken(PATH.ACCOUNTS_LIST_STATEMENT_DETAILS, props),
    pdfDownload: (props) => API.executeWithAccessToken(PATH.ACCOUNTS_PDF_STATE, props),
    readAccount: (props) => API.executeWithAccessToken(PATH.ACCOUNTS_READ, props),
    readMovementNote: (props) => API.executeWithAccessToken(PATH.NOTES_READ_STATEMENT_NOTE, props),
    stateOfAccounts: (props) => API.executeWithAccessToken(PATH.ACCOUNTS_STATE, props),
};

export const SelectorsAction = {
    cleanMovements: () => FactoryAction(TYPE.CLEAN_MOVEMENTS),
    closeOptions: () => FactoryAction(TYPE.CLOSE_OPTIONS),
    details: (props) => FactoryAction(TYPE.ACCOUNT_DETAILS_REQUEST, props),
    downloadBillingTicketFailure: () => FactoryAction(TYPE.DOWNLOAD_BILLING_TICKET_PDF_ERROR),
    downloadBillingTicket: (props) => FactoryAction(TYPE.DOWNLOAD_BILLING_TICKET_PDF_REQUEST, props),
    downloadBillingTicketSuccess: () => FactoryAction(TYPE.DOWNLOAD_BILLING_TICKET_PDF_SUCCESS),
    downloadMovements: (props) => FactoryAction(TYPE.DOWNLOAD_MOVEMENTS_REQUEST, props),
    downloadStatement: (props) => FactoryAction(TYPE.ACCOUNT_DOWNLOAD_STATEMENT_REQUEST, props),
    editMovementNote: (props) => FactoryAction(TYPE.EDIT_MOVEMENT_NOTE_REQUEST, props),
    editMovementNoteSuccess: (props) => FactoryAction(TYPE.EDIT_MOVEMENT_NOTE_SUCCESS, props),
    fetchAccount: (props) => FactoryAction(TYPE.FETCH_ACCOUNT, props),
    fetchMoreMovements: (props) => {
        const { filters } = props;
        const filterMovements = filters;
        filterMovements.pageNumber += 1;
        const rest = { ...props, filters: filterMovements };

        return FactoryAction(TYPE.ACCOUNT_FETCH_MORE_MOVEMENTS_REQUEST, rest);
    },
    fetchMovements: (props) => FactoryAction(TYPE.ACCOUNT_FETCH_MOVEMENTS_REQUEST, props),
    listAccounts: (props) => FactoryAction(TYPE.LIST_ACCOUNTS_REQUEST, props),
    listBillingTicketsFailure: () => FactoryAction(TYPE.LIST_BILLING_TICKET_ERROR),
    listBillingTickets: (props) => FactoryAction(TYPE.LIST_BILLING_TICKET_REQUEST, props),
    listBillingTicketsSuccess: (props) => FactoryAction(TYPE.LIST_BILLING_TICKET_SUCCESS, props),
    listStatements: (props) => FactoryAction(TYPE.LIST_ACCOUNT_STATEMENTS_REQUEST, props),
    movementDetails: (props) => FactoryAction(TYPE.ACCOUNT_MOVEMENT_DETAILS_REQUEST, props),
    pdfStateAccount: (props) => FactoryAction(TYPE.PDF_STATE_REQUEST, props),
    readAccount: (props) => FactoryAction(TYPE.ACCOUNT_READ_REQUEST, props),
    readMovementNote: (props) => FactoryAction(TYPE.READ_MOVEMENT_NOTE_REQUEST, props),
    readMovementNoteSuccess: (props) => FactoryAction(TYPE.READ_MOVEMENT_NOTE_SUCCESS, props),
    resetFilters: () => FactoryAction(TYPE.RESET_FILTERS),
    setFilter: (props) => FactoryAction(TYPE.SET_FILTER, props),
    setMobileFilter: (props) => FactoryAction(TYPE.SET_MOBILE_FILTER, props),
    setSelectedMovement: (props) => FactoryAction(TYPE.SET_SELECTED_MOVEMENT, props),
    setShowMulticashModal: (props) => FactoryAction(TYPE.SET_SHOW_MULTICASH_MODAL, props),
    setShowNoteModal: (props) => FactoryAction(TYPE.SET_SHOW_NOTE_MODAL, props),
    setShowRatesModal: (props) => FactoryAction(TYPE.SET_SHOW_RATES_MODAL, props),
    stateOfAccount: (props) => FactoryAction(TYPE.ACCOUNT_STATE_REQUEST, props),
    toggleOptions: () => FactoryAction(TYPE.TOGGLE_OPTIONS),
};

export const SelectorsStore = {
    getAccounts: (store) => store[NAME].accounts,
    getAccount: (store) => store[NAME].account,
    getChannelList: (store) => store[NAME].channelList,
    getClearFilter: (store) => store[NAME].clearFilter,
    getCurrency: (store) => store[NAME].currency,
    getEBillingTicketsFilters: (store) => store[NAME].billingTicketsFilter,
    getEBillingTicketsList: (store) => store[NAME].billingTickets,
    getEquivalentTotalBalance: (store) => store[NAME].equivalentTotalBalance,
    getFetchingDetails: (store) => store[NAME].fetchingDetails,
    getFetchingDownload: (store) => store[NAME].fetchingDownload,
    getFetchingMovements: (store) => store[NAME].fetchingMovements,
    getFetching: (store) => store[NAME].fetching,
    getFetchingTickets: (store) => store[NAME].fetchingTickets,
    getFiltersPersonalized: (store) => store[NAME].filters.filtersPersonalized,
    getFilters: (store) => store[NAME].filters,
    getFirstTime: (store) => store[NAME].firstTime,
    getIdSelectedAccount: (store) => store[NAME].idSelectedAccount,
    getIsOptionsVisible: (store) => store[NAME].isOptionsVisible,
    getMobileFilter: (store) => store[NAME].mobileFilter,
    getMoreMovements: (store) => store[NAME].moreMovements,
    getMovements: (store) => store[NAME].movements,
    getNext: (store) => store[NAME].next,
    getNote: (store) => store[NAME].note,
    getPageNumber: (store) => store[NAME].pageNumber,
    getRates: (store) => store[NAME].rates,
    getSelectedAccount: (store) => store[NAME].selectedAccount,
    getSelectedFilter: (store) => store[NAME].selectedFilter,
    getSelectedMovement: (store) => store[NAME].selectedMovement,
    getShowMulticashModal: (store) => store[NAME].showMulticashModal || false,
    getShowNoteModal: (store) => store[NAME].showNoteModal,
    getShowRateModal: (store) => store[NAME].showRatesModal || false,
    getStatements: (store) => store[NAME].statements,
    getStateOfAccount: (store) => store[NAME].stateAccount,
    getTotalLaw: (store) => store[NAME].totalLaw19210,
};
