import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { DOT, EMPTY_STR } from "~/constants";
import { SelectorsStore, SelectorsAction } from "~/store/investment";
import * as i18nUtils from "~/util/i18n";

import I18n from "~/components/I18n";
import Ticket from "~/components/Ticket/Ticket";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        transaction: PropTypes.shape({
            idTransaction: PropTypes.string,
            idTransactionStatus: PropTypes.string,
            valueDateAsString: PropTypes.string,
            executing: PropTypes.bool,
        }).isRequired,
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const { transaction, ticketConfirmation } = props;
    const { data } = transaction || {};
    const { questionnaireVersion } = data || {};
    const textTamplate = `client.wm.riskProfile.questionnaire${
        questionnaireVersion ? DOT + questionnaireVersion : EMPTY_STR
    }.question`;
    const answers = (data && Object.keys(data).filter((key) => /answer/.test(key))) || [];

    const sortAlphaNum = (a, b) => a.localeCompare(b, "en", { numeric: true });

    answers.sort(sortAlphaNum);

    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}>
                    <div className="data-wrapper">
                        {answers.length > 0 &&
                            answers.map((answer, i) => (
                                <div className="data-wrapper">
                                    <span className="data-label">
                                        <I18n id={`${textTamplate}.${i + 1}`} />
                                    </span>
                                    {answer === "answer2" ? (
                                        <span className="data-name">
                                            {i18nUtils.get(`${textTamplate}.${i + 1}.option.${data[answer]}.ticket`)}
                                        </span>
                                    ) : (
                                        <span className="data-name">
                                            {i18nUtils.get(`${textTamplate}.${i + 1}.option.${data[answer]}`)}
                                        </span>
                                    )}
                                </div>
                            ))}
                    </div>
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { dispatch } = props;
        const { idTransaction } = transaction;
        dispatch(
            SelectorsAction.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        const { dispatch } = props;
        dispatch(SelectorsAction.signTransactionPreview({ transaction }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStore.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
