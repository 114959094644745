import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { withFormik as WithFormik, Form } from "formik";
import Image from "~/components/Image";
import PropTypes from "prop-types";
import Button from "~/components/Button";
import Style from "./ServerError.rules.scss";

import { push as Push } from "connected-react-router";
import * as UtilsI18n from "~/util/i18n";

import HighOrder from "~/components/HighOrder";
import { connect as Connect } from "react-redux";


export const NAME = "ServerError";

export const PROP = {
    types: {
        hasActiveSession: PropTypes.bool,
        location: PropTypes.shape({
            code: PropTypes.string,
            message: PropTypes.string,
        }).isRequired,
    },
    defaults: { hasActiveSession: false},
};

export class Component extends React.Component {
    
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    handleClickGoToSafe = () => {
        const { dispatch } = this.props;
        dispatch(Push("/"));
    };

    getContent = () => {
        const { isMobile, location } = this.props;
        
        const errorTitle = "iBanca en mantenimiento.";
        
        return (
            <div className="error-page">
                    <a onClick={this.handleClickGoToSafe}>
                        <Image className="cross" src="cross.svg" />
                    </a>
                    <div className="error-text">
                        <div className="title">
                            <h1>
                                {errorTitle}
                            </h1>
                        </div>
                        <div className="content">    
                            <span
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: location.message,
                                }}
                            />    
                        </div>
                    </div>
                {isMobile ? (
                    <div className="error-button">
                        <Form className="login-form">
                            <div className="login-form-field button-field">
                                <Button
                                    className="button"
                                    type="submit"
                                    label="error.back"
                                    onClick={this.handleClickGoToSafe}
                                />
                            </div>
                        </Form>
                    </div>
                ) : (
                    <div className="logo-image">
                        <Link to={"/"}>
                            <Image src="logo-hsbc.svg" className="error-footer-icon"/>    
                        </Link>    
                    </div>
                )}
            </div>
        );
    };


    render() {
        return (
            <Fragment>
                <div className={Style.CLASS}>
                    {this.getContent()}
                </div>
            </Fragment>
        );
    }
}

export default HighOrder(
    Connect(),
    HighOrder.Resizable,
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({}),
        validationSchema: () => ({}),
        handleSubmit: () => {},
    }),
    )(Component);

