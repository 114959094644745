import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.CHECK_MOBILE_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                mobilePhone: action.mobilePhone,
                mobilePhoneClean: action.mobilePhoneClean,
            };
        case TYPE.CHECK_MOBILE_SUCCESS:
            return {
                ...storeProp,
                currentMobilePhone: action.currentMobilePhone,
                fetching: false,
                idUser: action.idUser,
            };
        case TYPE.CHANGE_MOBILE_REQUEST:
        case TYPE.ADD_SOFT_OTP_REQUEST:
        case TYPE.MIGRATION_FINISH_SOFT_REQUEST:
        case TYPE.MIGRATION_REVOKE_SOFT_REQUEST:
            return {
                ...storeProp,
                registerMigrationFinish: false,
                fetching: true,
            };
        case TYPE.CHECK_MOBILE_ERROR:
        case TYPE.CHANGE_MOBILE_ERROR:
        case TYPE.CHANGE_MOBILE_SUCCESS:
        case TYPE.SECURITY_TIPS_REQUEST:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.ADD_SOFT_OTP_ERROR:
        case TYPE.ADD_SOFT_OTP_SUCCESS:
        case TYPE.MIGRATION_FINISH_SOFT_ERROR:
        case TYPE.MIGRATION_REVOKE_SOFT_SUCCESS:
        case TYPE.MIGRATION_REVOKE_SOFT_ERROR:
        case TYPE.MIGRATION_FINISH_SOFT_SUCCESS:
            return {
                ...storeProp,
                registerMigrationFinish: true,
                fetching: false,
            };
        case TYPE.REGISTER_BIOMETRIC_MIGRATION:
            return {
                ...storeProp,
                registerBiometricMigration: action.registerBiometricMigration,
                fetching: false,
            };
        case TYPE.SECURITY_TIPS_MODAL_TRUE:
            return {
                ...storeProp,
                modal: true,
            };

        case TYPE.SECURITY_TIPS_MODAL_FALSE:
            return {
                ...storeProp,
                modal: false,
            };
        case TYPE.CLEAN_UP:
            return {
                ...PROP.defaults,
            };

        default:
            return {
                ...storeProp,
            };
    }
};
