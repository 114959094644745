import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    generalConditionsPre: (props) => API.executeWithAccessToken(PATH.GENERAL_CONDITIONS_PRE, props),
    generalConditionsSign: (props) => API.executeWithAccessToken(PATH.GENERAL_CONDITIONS_SIGN, props),
};

export const SelectorsAction = {
    generalConditionPre: (props) => FactoryAction(TYPE.GENERAL_CONDITIONS_PRE_REQUEST, props),
    errorGeneralConditionPre: (props) => FactoryAction(TYPE.GENERAL_CONDITIONS_PRE_ERROR, props),
    successGeneralConditionPre: (props) => FactoryAction(TYPE.GENERAL_CONDITIONS_PRE_SUCCESS, props),

    generalConditionSign: (props) => FactoryAction(TYPE.GENERAL_CONDITIONS_SIGN_REQUEST, props),
    errorGeneralConditionSign: (props) => FactoryAction(TYPE.GENERAL_CONDITIONS_SIGN_ERROR, props),
    successGeneralConditionSign: (props) => FactoryAction(TYPE.GENERAL_CONDITIONS_SIGN_SUCCESS, props),

    generalConditionsFromLogin: (props) => FactoryAction(TYPE.GENERAL_CONDITIONS_FROM_LOGIN, props),
    generalConditionsFromEnvironment: (props) => FactoryAction(TYPE.GENERAL_CONDITIONS_FROM_ENVIRONMENT, props),
    generalConditionsFetching: (props) => FactoryAction(TYPE.GENERAL_CONDITIONS_FETCHING, props),
};

export const SelectorsStore = {
    fetching: (store) => store[NAME].fetching,
    latestNews: (store) => store[NAME].latestNews,
    body: (store) => store[NAME].body,
    isEnvironmentChanging: (store) => store[NAME].isEnvironmentChanging,
    idCondition: (store) => store[NAME].idCondition,
    rememberEnvironment: (store) => store[NAME].rememberEnvironment,
    environment: (store) => store[NAME].environment,
    suggestMigration: (store) => store[NAME].suggestMigration,
};
