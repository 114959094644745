import React from "react";

import PropTypes from "prop-types";

import Device from "~/util/device";
import { generateId as GenerateId } from "~/util/general";
import PropHtmlElement from "~/util/prop/html-element";

import Dropdown from "~/pages/_components/Dropdown";

import LoanAction from "~/pages/loans/_components/LoanAction";

export const NAME = "LoansOperations";

export const PROP = {
    types: {
        loan: PropTypes.shape({
            idProduct: PropTypes.string,
            permissions: PropTypes.shape({}),
        }).isRequired,
        ...PropHtmlElement.types,
    },
    defaults: { ...PropHtmlElement.defaults },
};

export function Component(props) {
    const { classNames, loan } = props;
    const actions = [];

    actions.push(
        <LoanAction
            key="loan.alias"
            labelKey="loan.alias"
            to={`/loans/${loan.idProduct}/setAlias`}
            className={classNames}
        />,
    );

    if (Device.isDisplayDesktop && actions.length > 1) {
        return (
            <Dropdown key="operations" label="menu.operations" buttonClass="btn btn-quiet">
                {actions}
            </Dropdown>
        );
    }
    return actions.map((action) => (
        <li className="navigational-list-item" key={GenerateId()}>
            {action}
        </li>
    ));
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
