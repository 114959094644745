import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { SelectorsStore as SelectorsStoreForm } from "~/store/form";

import I18n from "~/components/I18n";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import FormattedRate from "~/pages/_components/FormattedRate";

export const NAME = "PayBevsa";

export const PROP = {
    types: {
        previewData: PropTypes.shape({
            debitAccountAlias: PropTypes.string.isRequired,
            isSameCurrency: PropTypes.bool.isRequired,
            idOrganism: PropTypes.string.isRequired,
            debitAmount: PropTypes.shape({
                currency: PropTypes.string.isRequired,
                quantity: PropTypes.number.isRequired,
            }).isRequired,
            bevsaAmount: PropTypes.shape({
                currency: PropTypes.string.isRequired,
                quantity: PropTypes.number.isRequired,
            }).isRequired,
            manualRevisionWarning: PropTypes.bool.isRequired,
        }).isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const { previewData } = props;
    const {
        isSameCurrency,
        bevsaAmount,
        idOrganism,
        debitAccountAlias,
        debitAmount,
        manualRevisionWarning,
        rate,
        rateFirstCurrency,
        rateSecondCurrency,
    } = previewData;

    const renderAmount = (label, amountToRender) => (
        <div className="transfer-data transfer-data-highlight">
            <span className="data-label">
                <I18n id={`forms.payBevsa.confirmation.${label}`} />
            </span>
            <FormattedAmount currency={amountToRender.currency} quantity={amountToRender.quantity} />
        </div>
    );

    const renderRate = (className, rateToRender, firstCurrency, secondCurrency) => (
        <div className={`transfer-data ${className}`}>
            <span className="data-label">
                <I18n id="forms.confirmation.rate" />
            </span>
            <FormattedRate firstCurrency={firstCurrency} secondCurrency={secondCurrency} quantity={rateToRender} />
        </div>
    );

    return (
        <React.Fragment>
            <p className="text-lead">
                <I18n id="forms.default.confirmation.title" />
            </p>
            <div className="transfer-block">
                <div className="transfer-data transfer-data-highlight">
                    <span className="data-label">
                        <I18n id="forms.payBevsa.confirmation.debitAccount" />
                    </span>
                    <span className="data-amount">{debitAccountAlias}</span>
                </div>

                {renderAmount("bevsaAmount", bevsaAmount)}

                {!isSameCurrency && (
                    <React.Fragment>
                        {renderAmount("debitAmount", debitAmount)}
                        {renderRate("data-wrapper", rate, rateFirstCurrency, rateSecondCurrency)}
                    </React.Fragment>
                )}
                <div className="transfer-data transfer-data-highlight">
                    <span className="data-label">
                        <I18n id="forms.payBevsa.confirmation.organism" />
                    </span>
                    <span className="data-amount">
                        <I18n id={`client.transaction.bevsa.organism.${idOrganism}`} />
                    </span>
                </div>
            </div>
            {manualRevisionWarning && (
                <span className="data-label">
                    <I18n id="client.transferLocal.manualRevisionWarning" />
                </span>
            )}
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    name: SelectorsStoreForm.getName(store),
    previewData: SelectorsStoreForm.getPreviewData(store),
});

export default connect(mapStateToProps)(Component);
