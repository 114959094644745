import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { EMPTY_STR, ONLY_NUMBER, SPACE_STR, LEVEL, SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { SelectorsAction as SelectorsActionComex, SelectorsStore as SelectorsStoreComex } from "~/store/comex";
import {
    SelectorsAction as SelectorsActionComexBondsAndGuarantees,
    PROP as PropComex,
    SelectorsStore as SelectorsStoreComexBondsAndGuarantees,
} from "~/store/comex/bondsAndGuarantees";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsAction as SelectorActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionTemplate } from "~/store/template";
import * as ConfigUtil from "~/util/config";
import { formatDate as FormatDate, toDate as ToDate } from "~/util/date";
import { isEmptyObj } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Select from "~/components/Select";
import DateField from "~/pages/_components/fields/DateField";
import EmailList from "~/pages/_components/fields/EmailList";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TextArea from "~/pages/_components/fields/TextArea";
import TextField from "~/pages/_components/fields/TextField";
import AmountField from "~/pages/_components/fields/formik/AmountField";

import FileUploader from "~/pages/comEx/_components/FileUploader";
import OperationNumber from "~/pages/comEx/_components/OperationNumber";
import OperationNumberDetail from "~/pages/comEx/_components/OperationNumberDetail";
import CreateTemplateModal from "~/pages/forms/Step1Edit/_ModalTemplateCreate";
import ListTemplatesModal from "~/pages/forms/Step1Edit/_ModalTemplateList";
import { Termsandconditions } from "~/pages/forms/_components/_fields";
import Scheduler from "~/pages/forms/_components/_fields/Scheduler";

import Style from "../../../Step1Edit.rules.scss";

const FORM_ID = "comex.bondsAndGuarantees.modifyBondsAndGuarantees";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...PropComex.types,
    },
    defaults: {
        ...PropComex.defaults,
    },
};

export const EMISSION_TYPE = {
    LINE_OF_CREDIT: "LINEACREDITO",
    PLEDGE_OF_DEPOSIT: "PRENDADEPOSITO",
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        draftLoaded: false,
        loadOperationNumber: false,
    };

    componentDidMount() {
        const { dispatch, formData } = this.props;
        if (isEmptyObj(formData)) {
            dispatch(SelectorsActionComexBondsAndGuarantees.modifyBondsAndGuaranteesPreRequest());
        }
    }

    componentDidUpdate() {
        const {
            dispatch,
            match,
            preData,
            setFieldError,
            setFieldTouched,
            setFieldValue,
            setValues,
            transaction,
            values,
        } = this.props;
        const { availableOperationList } = preData;
        const { operationNumber } = match.params;
        const { loadOperationNumber, draftLoaded } = this.state;

        if (!loadOperationNumber && availableOperationList.length > 0 && !values.operationNumber && operationNumber) {
            const operationSelected = availableOperationList.filter(
                (item) => item.othersFields.NROOPERACION === operationNumber,
            );

            this.setState({
                loadOperationNumber: true,
            });

            if (operationSelected.length > 0) {
                setFieldValue("item", operationSelected[0]);
                setFieldValue("operationNumber", operationNumber);
            }
        }

        if (transaction?.data && !draftLoaded) {
            this.setState({
                draftLoaded: true,
            });

            const transactionData = transaction?.data;

            /* carga de errores */
            if (transactionData.backendFormErrors?.cdpErrorFields) {
                const { cdpErrorFields } = transactionData.backendFormErrors;

                cdpErrorFields.map((errorField) => {
                    if (errorField === "valueDate") {
                        setFieldError("scheduler", SPACE_STR);
                        setFieldTouched("scheduler", true);

                        transactionData.scheduler = {
                            editing: true,
                            valueDate: transactionData?.valueDate,
                            selectedOption: transactionData?.selectedOption,
                        };
                    } else {
                        setFieldError(errorField, SPACE_STR);
                        setFieldTouched(errorField, true);
                    }

                    return null;
                });
            }

            if (transactionData.backendFormErrors?.message) {
                const errorMessage = transactionData?.backendFormErrors?.message;

                dispatch(
                    SelectorActionNotification.showNotification({
                        message: errorMessage,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.COMEX_BONDS_AND_GUARANTEES_MODIFY],
                    }),
                );
            } // modificaciones para que agarren bien los fields
            /*  carga del draft */

            if (transactionData.valueDate) {
                const valueDate = ToDate(transactionData.valueDate);

                transactionData.valueDate = valueDate;
            }

            if (transactionData.newDueDate) {
                const newDueDate = ToDate(transactionData.newDueDate);

                transactionData.newDueDate = newDueDate;
            }

            if (transactionData.dueDate) {
                const dueDate = ToDate(transactionData.dueDate);

                transactionData.dueDate = dueDate;
            }

            setValues(transaction.data);
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(GoBack());
    };

    buildOptions = () => {
        return [
            {
                id: "INCREMENTO",
                label: UtilsI18n.get(`${FORM_ID}.incremento`),
            },
            {
                id: "DISMINUCION",
                label: UtilsI18n.get(`${FORM_ID}.disminucion`),
            },
        ];
    };

    // TODO: usar un builder generico
    buildEmissionType = () => {
        return [
            {
                id: "LINEACREDITO",
                label: UtilsI18n.get(`${FORM_ID}.emissionType.LINEACREDITO`),
            },
            {
                id: "PRENDADEPOSITO",
                label: UtilsI18n.get(`${FORM_ID}.emissionType.PRENDADEPOSITO`),
            },
        ];
    };

    handleFormKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            event.preventDefault();
        }
    };

    handleFieldKeyDown = (event) => {
        if ((event.charCode || event.keyCode) === 13) {
            this.handleSearch();
        }
    };

    handleSelectChange = (id) => {
        const { setFieldValue } = this.props;
        setFieldValue("increment", id);
    };

    handleEmissionChange = (id) => {
        const { setFieldValue } = this.props;
        setFieldValue("emissionType", id);
    };

    handleItemSelect = (item) => {
        const { setFieldValue, setFieldError } = this.props;

        const { othersFields } = item;
        if (othersFields) {
            setFieldValue("operationNumber", othersFields.NROOPERACION);
            setFieldValue("item", item);
            setFieldError("operationNumber", null);
        } else {
            setFieldValue("item", null);
            setFieldValue("operationNumber", EMPTY_STR);
        }
    };

    handleCancel = () => {
        const { dispatch, setSubmitting, transaction } = this.props;

        dispatch(
            SelectorsActionForm.cancelTransactionCustom({
                credentials: {},
                idTransaction: transaction.idTransaction,
                formikBag: { setSubmitting },
            }),
        );
    };

    handleDraft = () => {
        const { dispatch, values, transaction } = this.props;

        dispatch(
            SelectorsActionForm.saveDraft({
                idActivityDraft: "comex.bondsAndGuarantees.modifyBondsAndGuarantees.send",
                idTransactionToSave: transaction?.idTransaction,
                transactionData: values || {},
            }),
        );
    };

    handleTemplateSave = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionTemplate.createTemplate());
    };

    handleTemplateLoad = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionTemplate.toggleTemplateList());
    };

    handleSelectTemplate = (template) => {
        const { setValues, setErrors } = this.props;
        const templateToBeLoaded = template;
        if (templateToBeLoaded.scheduler.valueDate) {
            const valueDate = ToDate(templateToBeLoaded.scheduler.valueDate);
            templateToBeLoaded.scheduler.valueDate = valueDate;
        }

        if (templateToBeLoaded.dueDate) {
            const dueDate = ToDate(templateToBeLoaded.dueDate);
            templateToBeLoaded.dueDate = dueDate;
        }

        setValues(templateToBeLoaded);
        setErrors({});
    };

    handleModalLoad = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionComex.toggleCreditLetterList());
    };

    handleBlur = () => {
        const { values, preData, setFieldValue } = this.props;
        const { availableOperationList } = preData;
        if (values.operationNumber) {
            const operationSelected = availableOperationList.filter(
                (item) => item.othersFields.NROOPERACION === values.operationNumber,
            );
            if (operationSelected.length > 0) {
                setFieldValue("item", operationSelected[0]);
            } else {
                setFieldValue("item", null);
                setFieldValue("operationNumber", EMPTY_STR);
            }
        }
    };

    render() {
        const { values, emailValidationRegex, errors, preData, setFieldValue, transaction } = this.props;
        const options = this.buildOptions();
        const emissionType = this.buildEmissionType();
        const { nextValidDate, unavailableDays, availableOperationList } = preData;
        const termsAndConditions = "comex.bondsAndGuarantees.modifyBondsAndGuarantees.disclaimer";
        const i18nMap = {
            termsAndConditions,
        };
        const cancelEnabled = transaction && transaction.idTransactionStatus === STATUS.DRAFT;
        const othersFields = values.item ? values.item.othersFields : null;

        const currency = othersFields ? othersFields.MONEDA : EMPTY_STR;
        const data = {
            options: [
                {
                    id: currency,
                    label: UtilsI18n.get(`currency.label.${currency}`),
                },
            ],
        };

        return (
            <React.Fragment>
                <Form
                    className="col col-12 col-lg-6 col-md-9 col-sm-12"
                    id={Style.ID}
                    noValidate="novalidate"
                    onKeyDown={this.handleFormKeyDown}>
                    <div className="form-section loadTemplates">
                        <Button
                            onClick={this.handleTemplateLoad}
                            label="forms.templates.load"
                            bsStyle="default"
                            image="template.svg"
                        />
                    </div>
                    <section className="fields container--layout align-items-center flex-grow">
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.operation`)}</h3>
                        </Box>
                        <Box flex align="end" className="form-group search-field">
                            <Field
                                idForm={FORM_ID}
                                name="operationNumber"
                                hidePlaceholder
                                pattern={ONLY_NUMBER}
                                type="text"
                                component={TextField}
                                onBlur={this.handleBlur}
                                tooltip={UtilsI18n.get(
                                    "comex.bondsAndGuarantees.modifyBondsAndGuarantees.operationNumber.tooltip",
                                )}
                            />

                            <Box className="ml-3 mb-2">
                                <Button
                                    onClick={this.handleModalLoad}
                                    label="global.search"
                                    bsStyle="primary"
                                    className="search"
                                />
                            </Box>
                        </Box>
                        {values.item && <OperationNumberDetail othersFields={othersFields} idForm={FORM_ID} />}
                        {/* TODO: Campo solo lectura si el campo viene con datos, Si el campo viene nulo entonces el
                        cliente deberá seleccionar una de las 2 opciones: */}
                        <Box className="form-group">
                            <FieldLabel labelKey={`${FORM_ID}.emissionType.label`} />

                            <Box className="input-group">
                                <Field
                                    component={Select}
                                    placeholder={EMPTY_STR}
                                    value={values.emissionType}
                                    clearable={false}
                                    onChange={(item) => {
                                        if (item) {
                                            this.handleEmissionChange(item.id);
                                        }
                                    }}
                                    valueKey="id"
                                    labelKey="label"
                                    options={emissionType}
                                    className="flex-container slideFromBottom"
                                    optionClassName="needsclick"
                                    idForm={FORM_ID}
                                />
                            </Box>
                        </Box>
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.modifyData`)}</h3>
                        </Box>
                        <Box className="form-group ">
                            <I18n id="comex.bondsAndGuarantees.modifyBondsAndGuarantees.note" />
                        </Box>
                        <Box className="form-group">
                            <Field
                                idForm={FORM_ID}
                                name="dueDate"
                                id="dueDate"
                                component={DateField}
                                showMonthYearDropdown
                                minDate={values.scheduler?.valueDate}
                                maxDate={null}
                                hidePlaceholder
                                autoComplete="off"
                            />
                        </Box>
                        <Box className="form-group">
                            <FieldLabel labelKey={`${FORM_ID}.incrementoDisminucion.label`} />

                            <Box className="input-group">
                                <Field
                                    component={Select}
                                    placeholder={EMPTY_STR}
                                    value={values.increment}
                                    clearable={false}
                                    onChange={(item) => {
                                        if (item) {
                                            this.handleSelectChange(item.id);
                                        }
                                    }}
                                    valueKey="id"
                                    labelKey="label"
                                    options={options}
                                    className="flex-container slideFromBottom"
                                    optionClassName="needsclick"
                                    idForm={FORM_ID}
                                />
                            </Box>
                        </Box>
                        {values.increment !== EMPTY_STR && (
                            <React.Fragment>
                                <Field
                                    clearable={false}
                                    component={AmountField}
                                    data={data}
                                    idForm={FORM_ID}
                                    name="invoiceAmount"
                                    placeholder={EMPTY_STR}
                                    searchable={false}
                                    decimalPlaces={2}
                                    fixedDecimalScale
                                    hideCurrency={!currency}
                                    maxLength={ConfigUtil.getInteger("amount.length")}
                                />
                            </React.Fragment>
                        )}
                        {values.increment === "INCREMENTO" &&
                            values.emissionType === EMISSION_TYPE.PLEDGE_OF_DEPOSIT && (
                                <Field
                                    component={TextArea}
                                    idForm={FORM_ID}
                                    name="pledgeOfDeposit"
                                    mode={MODE.EDIT}
                                    className="form-control"
                                    maxLength={1000}
                                    hidePlaceholder
                                />
                            )}
                        <Field
                            component={TextArea}
                            idForm={FORM_ID}
                            name="otherModifications"
                            mode={MODE.EDIT}
                            className="form-control"
                            maxLength={1500}
                            hidePlaceholder
                        />
                        <Box className="form-group">
                            <Field
                                name="files"
                                idForm={FORM_ID}
                                emptyMessageKey={`${FORM_ID}.attachments.emptyMessage`}
                                maxFiles={5}
                                errors={errors}
                                component={FileUploader}
                                values={values}
                                setFieldValue={setFieldValue}
                            />
                        </Box>
                        <Field
                            component={EmailList}
                            idForm={FORM_ID}
                            name="notificationEmails"
                            hideSwiftTip
                            className="form-group email-field"
                            renderSuggestion={false}
                            data={{ emailValidationRegex, addMessage: EMPTY_STR }}
                            isRequired={false}
                            optional={UtilsI18n.get("form.field.optional")}
                            tooltip={UtilsI18n.get(`${FORM_ID}.notificationEmails.tooltip`)}
                            value={values.notificationEmails}
                        />

                        <hr />
                        <Box className="form-section-title">
                            <h3 className="form-section-title-text">
                                {UtilsI18n.get(`${FORM_ID}.section.showPreview`)}
                            </h3>
                        </Box>

                        <Field
                            component={Termsandconditions}
                            idForm={FORM_ID}
                            name="disclaimer"
                            mode={MODE.EDIT}
                            className="form-control"
                            i18nMap={i18nMap}
                        />
                        {nextValidDate && (
                            <Field
                                component={Scheduler}
                                data={{
                                    firstWorkingDate: nextValidDate,
                                    maxDaysToSchedule: ConfigUtil.get("client.comex.valueDate.maxDaysToSchedule"),
                                    nonWorkingDays: unavailableDays,
                                    lang: "es",
                                    mode: "edit",
                                    programable: false,
                                    schedulable: true,
                                }}
                                name="scheduler"
                                idForm={FORM_ID}
                            />
                        )}
                    </section>
                    <footer>
                        <Button onClick={this.handleDraft} label="forms.saveDraft.link" />
                        <Button
                            onClick={this.handleTemplateSave}
                            className="templateSave"
                            label="forms.saveTemplate.link"
                        />
                        {cancelEnabled && <Button onClick={this.handleCancel} label="forms.cancelTransaction.link" />}
                        <Button
                            key="primaryButton"
                            variant="primary"
                            bsStyle="primary"
                            label="global.next"
                            loading={false}
                            type="submit"
                        />
                    </footer>
                </Form>
                <ListTemplatesModal
                    idActivityTemplate="comex.bondsAndGuarantees.modifyBondsAndGuarantees.send"
                    onSelect={this.handleSelectTemplate}
                />
                <CreateTemplateModal
                    values={values}
                    idActivityTemplate="comex.bondsAndGuarantees.modifyBondsAndGuarantees.send"
                    backdrop="static"
                />
                <OperationNumber
                    list={availableOperationList}
                    idForm={FORM_ID}
                    handleItemClick={this.handleItemSelect}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        preData: SelectorsStoreComexBondsAndGuarantees.getmodifyBondsAndGuaranteesOpenPre(store),
        formData: SelectorsStoreComexBondsAndGuarantees.getmodifyBondsAndGuaranteesOpenFormData(store),
        transaction: SelectorsStoreComex.getTransaction(store),
    };
};

export default HighOrder(
    WithRouter,
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: false,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: () =>
            Yup.object().shape({
                operationNumber: Yup.string().required(UtilsI18n.get(`client.${FORM_ID}.operationNumber.required`)),
            }),
        mapPropsToValues: ({ formData }) => {
            return {
                scheduler: formData?.valueDate
                    ? {
                          editing: true,
                          valueDate: formData?.valueDate,
                          selectedOption: formData?.selectedOption,
                      }
                    : null,
                operationNumber: formData?.operationNumber,
                emissionType: formData?.emissionType || EMISSION_TYPE.LINE_OF_CREDIT,
                dueDate: formData.dueDate ? ToDate(formData.dueDate) : EMPTY_STR,
                increment: formData?.increment || EMPTY_STR,
                invoiceAmount: { amount: formData?.invoiceAmount, currency: formData?.invoiceAmountCurrency },
                otherModifications: formData?.otherModifications || EMPTY_STR,
                notificationEmails: formData?.notificationEmails,
                pledgeOfDeposit: formData?.pledgeOfDeposit,
                item: formData?.item || null,
                files: formData?.attachments?.split(",") || [],
                attachmentsNames: formData?.attachmentsNames || [],
            };
        },
        handleSubmit: (props, formikBag) => {
            const { dispatch, preData } = formikBag.props;
            const { hasContract, accountAddress, accountName, accountRUT } = preData;
            const { item, files, invoiceAmount, scheduler, dueDate, pledgeOfDeposit, ...rest } = props;
            const originalDueDate = item?.othersFields ? item.othersFields.FECHAVTO : EMPTY_STR;
            const oldAmount = item?.othersFields ? item.othersFields.IMPORTE : EMPTY_STR;
            const amount = invoiceAmount ? invoiceAmount.amount : 0;
            const attachmentsFiles = files ? files.join(",") : EMPTY_STR;
            const valueDateString = scheduler ? FormatDate(scheduler.valueDate) : EMPTY_STR;
            const dueDateString = dueDate ? FormatDate(new Date(dueDate)) : EMPTY_STR;
            const invoiceAmountCurrency = item?.othersFields ? item.othersFields.MONEDA : EMPTY_STR;
            const recipient = item?.othersFields ? item.othersFields.BENEFICIARIO : EMPTY_STR;
            const pledgeOfDepositString =
                props.emissionType === EMISSION_TYPE.PLEDGE_OF_DEPOSIT && props.increment === "INCREMENTO"
                    ? pledgeOfDeposit
                    : EMPTY_STR;
            const formData = {
                ...rest,
                valueDate: valueDateString,
                dueDate: dueDateString,
                originalDueDate,
                invoiceAmount: amount,
                oldAmount,
                attachments: attachmentsFiles,
                recipient,
                invoiceAmountCurrency,
                hasContract,
                selectedOption: scheduler?.selectedOption,
                pledgeOfDeposit: pledgeOfDepositString,
                item,
                accountAddress,
                accountName,
                accountRUT,
                scheduler,
            };

            dispatch(
                SelectorsActionComexBondsAndGuarantees.modifyBondsAndGuaranteesPreviewRequest({
                    formData,
                    formikBag,
                }),
            );
        },
    }),
)(Component);
