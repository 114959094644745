import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { PRODUCT_TYPE } from "~/constants";
import Container from "~/containers/Internal/Form/Cards";
import { SelectorsAction as SelectorsActionAccount, SelectorsStore as SelectorsStoreAccount } from "~/store/account";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import Device from "~/util/device";
import UtilFlag from "~/util/flag";

import Button from "~/components/Button";
import DataNumber from "~/components/DataNumber";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";

import Style from "./List.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        accounts: PropTypes.shape({
            idProduct: PropTypes.string,
            productTypeLabel: PropTypes.string,
            number: PropTypes.string,
            currency: PropTypes.string,
            balance: PropTypes.number,
            productAlias: PropTypes.string,
            productType: PropTypes.string,
            permissions: PropTypes.shape({
                transferInternal: PropTypes.bool,
                transferForeign: PropTypes.bool,
                transferLocal: PropTypes.bool,
                transferThirdParties: PropTypes.bool,
                requestCheckbook: PropTypes.bool,
                preferentialTradingPrice: PropTypes.bool,
            }),
        }),
        dispatch: PropTypes.func.isRequired,
        isRequestAvailable: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    },
    defaults: {
        accounts: null,
        isRequestAvailable: false,
    },
};

export function Component(props) {
    const { dispatch, accounts, isRequestAvailable, fetching } = props;

    React.useLayoutEffect(() => {
        dispatch(SelectorsActionAccount.listAccounts());
    }, [dispatch]);

    const isLoading = fetching;
    const isEmpty = !fetching && !accounts.length;
    return (
        <Container name={NAME} head-title="menu.accounts.myAccounts" wait={isLoading} className={Style.CLASS}>
            {!isLoading && (
                <React.Fragment>
                    {isLoading && <I18n id="global.loading" />}
                    {isEmpty && <I18n id="accounts.list.empty" />}

                    {!isEmpty &&
                        accounts.map((account) => {
                            return renderCards(account);
                        })}

                    {!Device.isDisplayDesktop() && isRequestAvailable && (
                        <section className="container--layout align-items-center">
                            <Button
                                className="btn-outline btn-block"
                                href="/form/accountOpening/"
                                image="plus.svg"
                                label="accounts.new"
                            />
                        </section>
                    )}
                </React.Fragment>
            )}
        </Container>
    );

    function renderCards(account) {
        const {
            idProduct,
            productTypeLabel,
            number,
            currency,
            balance,
            productAlias,
            productType,
            permissions,
            uiBalance,
        } = account;
        const {
            transferInternal,
            transferForeign,
            transferLocal,
            transferThirdParties,
            requestCheckbook,
            preferentialTradingPrice,
        } = permissions || {};

        const accountOperations = [
            {
                key: "accounts.movements",
                to: `/accounts/${idProduct}`,
            },
            requestCheckbook &&
                productType === PRODUCT_TYPE.CHECKING_ACCOUNT && {
                    key: "menu.accounts.requestCheckbook",
                    to: `/form/requestCheckbook?idAccount=${idProduct}`,
                },
            preferentialTradingPrice && {
                key: "menu.accounts.preferentialTradingPrice",
                to: "/preferentialTradingPrice",
            },
            (transferInternal || transferForeign || transferLocal || transferThirdParties) && {
                key: "menu.transfers",
                to:
                    productType === PRODUCT_TYPE.CUI
                        ? `/form/transferInternal?debitAccount=${idProduct}`
                        : `/transfers?debitAccount=${idProduct}`,
            },
        ].filter(Boolean);

        return (
            <Link className="border-orange" to={`/accounts/${idProduct}`} key={idProduct}>
                <div className="dataAccount">
                    <div>
                        <h4 className="account-alias">
                            <span>{productAlias}</span>
                        </h4>
                        <span className="label">{number}</span>
                    </div>
                    <span className="account-type">{productTypeLabel}</span>
                </div>
                <div className="dataCurrency">
                    <span id={Style.CURRENCY}>
                        <I18n className="balance" id="accounts.availableBalance" />
                        <Image src={`${UtilFlag.getFlag(currency)}.svg`} />
                        <DataNumber prefix={currency} value={balance} />
                    </span>
                    {productType === PRODUCT_TYPE.CUI && (
                        <div className="dataUIBalance">
                            <span className="label-uibalance">
                                <I18n id="accounts.equivalentUIBalance" />
                            </span>
                            <span>
                                <DataNumber value={uiBalance} />
                            </span>
                        </div>
                    )}
                </div>

                <div id={Style.OPERATIONS}>
                    {accountOperations.map(({ key, to }) => (
                        <Link key={key} to={to}>
                            <I18n id={key} />
                        </Link>
                    ))}
                </div>
            </Link>
        );
    }
}

Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

const mapStateToProps = (store) => ({
    accounts: SelectorsStoreAccount.getAccounts(store),
    equivalentTotalBalance: SelectorsStoreAccount.getEquivalentTotalBalance(store),
    fetching: SelectorsStoreAccount.getFetching(store),
    isRequestAvailable: SelectorsStoreSession.getActiveEnvironmentForms(store, "accounts").find(
        ({ idForm }) => idForm === "accountOpening",
    ),
});

export default Connect(mapStateToProps)(Component);
