import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.DOWNLOAD_FILE_LINK_REQUEST:
            return { ...storeProp, fetchingDownload: true };
        case TYPE.DOWNLOAD_FILE_LINK_FAILURE:
        case TYPE.DOWNLOAD_FILE_LINK_SUCCESS:
            return { ...storeProp, fetchingDownload: false };
        default:
            return storeProp;
    }
};
