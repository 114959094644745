import { put as Put, takeLatest as TakeLatest, call as Call } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE } from "~/constants";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import {
    credentialsWithUnderscore as CredentialsWithUnderscore,
    hasIncorrectCredentials as HasIncorrectCredentials,
    adjustIdFieldErrors as AdjustIdFieldErrors,
} from "~/util/form";
import * as i18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction } from "./_selectors";

const sagas = [
    TakeLatest(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, cancelTransactionCustom),
    TakeLatest(TYPE.PREVIEW_PAPER_REQUEST, previewPaper),
    TakeLatest(TYPE.PRE_FORM_REQUEST, preFormRequest),
    TakeLatest(TYPE.READ_PAPER_TO_BUY_REQUEST, readPaperToBuy),
    TakeLatest(TYPE.READ_TRANSACTION_REQUEST, requestSwitchTransaction),
    TakeLatest(TYPE.SEND_FORM_PAPER_REQUEST, sendForm),
    TakeLatest(TYPE.SIGN_TRANSACTION_REQUEST, signTransaction),
];

export default sagas;

function* preFormRequest(props) {
    const { isReloadPaper } = props;
    const { type, data } = yield Call(SelectorsMiddleware.wealthManagementFundsSwitchPre, props);
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.taskFinished());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.WEALTH_MANAGEMENT_SWITCH_FUNDS],
            }),
        );
    } else {
        if (isReloadPaper) {
            yield Put(SelectorsAction.readPaperToSwitchSuccess({ data: data.data }));
        } else {
            yield Put(SelectorsAction.preFormSuccess({ data: data.data }));
        }
        if (Object.keys(data.data.errors).length > 0) {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: i18n.get("wm.funds.switch.invalidData.notification"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.WEALTH_MANAGEMENT_SWITCH_FUNDS],
                }),
            );
        }
    }
}

function* readPaperToBuy(props) {
    const { idPaper } = props;
    const params = {
        idPaper,
        prices: false,
        period: -1,
        dateFrom: null,
        dateTo: null,
        generatePDF: false,
        firstPrices: false,
    };
    const response = yield Call(SelectorsMiddleware.wealthManagementFundsSwitchReadBuyPaper, params);

    const { data, type } = response;
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.taskFinished());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.WEALTH_MANAGEMENT_SELL_FUNDS],
            }),
        );
    } else {
        yield Put(SelectorsAction.readPaperToBuySuccess({ data: data.data }));
    }
}

function* previewPaper(props) {
    const { type, data } = yield Call(SelectorsMiddleware.wealthManagementFundsSwitchPreview, props);
    if (type === RESPONSE_TYPE.WARNING) {
        props.formikBag.setErrors(data.data);

        yield Put(SelectorsAction.taskFinished());

        yield Put(
            SelectorsActionNotification.showNotification({
                message: i18n.get("forms.fieldsErrors"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.WEALTH_MANAGEMENT_SWITCH_FUNDS],
            }),
        );
    } else {
        yield Put(SelectorsAction.previewPaperSuccess({ data: data.data }));
    }
}

function* sendForm(props) {
    const { type, data } = yield Call(SelectorsMiddleware.wealthManagementFundsSwitchSend, props);
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.taskFinished());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.WEALTH_MANAGEMENT_SWITCH_FUNDS],
            }),
        );
    } else {
        const { idTransaction } = data;
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(SelectorsAction.paperSendFormSuccess({ transaction }));
    }
}

function* requestSwitchTransaction(props) {
    const { idTransaction } = props;
    const { type, data } = yield Call(SelectorsMiddlewareForm.readTransaction, {
        idTransactionToRead: idTransaction,
    });
    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.WEALTH_MANAGEMENT_SWITCH_FUNDS],
            }),
        );
    } else {
        yield Put(SelectorsAction.readSwitchFundsTransactionSuccess({ transaction: data.data.transaction }));
    }
}

function* cancelTransactionCustom(props) {
    const { credentials, idTransaction, formikBag } = props;
    yield* handleCancelTransaction(credentials, idTransaction, formikBag);

    formikBag.setSubmitting(false);
}

function* handleCancelTransaction(credentials, idTransaction, formikBag) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);
    const {
        data: { data },
        type,
    } = yield Call(SelectorsMiddlewareForm.cancelTransaction, {
        idTransactionToCancel: idTransaction,
        ...credentialsWithUnderscore,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data);
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: i18n.get("forms.cancelTransaction.errorMessage"),
                    level: LEVEL.ERROR,
                    scopes: SCOPE.WEALTH_MANAGEMENT_SELL_FUNDS,
                }),
            );
        }
    } else {
        yield* requestSwitchTransaction({ idTransaction });
        yield Put(
            SelectorsActionNotification.showNotification({
                message: i18n.get("forms.cancelTransaction.confirmationMessage"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.WEALTH_MANAGEMENT_SELL_FUNDS, SCOPE.DESKTOP],
            }),
        );
    }
}

function* signTransaction(props) {
    const { idForm, idActivity, idTransaction, credentials, formikBag } = props;
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { data, type } = yield Call(
        SelectorsMiddlewareForm.sign,
        { idForm, idTransaction, ...credentialsWithUnderscore },
        idActivity,
    );

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(AdjustIdFieldErrors(data.data));
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.WEALTH_MANAGEMENT_SELL_FUNDS],
                }),
            );
        }
        formikBag.setSubmitting(false);
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        yield Put(
            SelectorsAction.paperSendFormSuccess({
                transaction,
            }),
        );
        formikBag.setSubmitting(false);
    }
}
