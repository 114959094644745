import React from "react";

import { routerActions as ActionRouter } from "connected-react-router";
import { Field, Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/Simple";
import { SelectorsStore as StoreCC } from "~/store/creditCards/creditCard";
import { SelectorsAction as ActionProducts } from "~/store/products";
import { get as GetConfig } from "~/util/config";
import * as I18nUtils from "~/util/i18n";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import TextField from "~/pages/_components/fields/TextField";

const FORM_ID = "creditCard.details.alias";

export const NAME = "SetAlias";

export const PROP = {
    types: {
        id: PropTypes.string.isRequired,
        isSubmitting: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        isFetching: PropTypes.bool,
    },
    defaults: {
        isSubmitting: false,
        isFetching: false,
    },
};

export function Component(props) {
    const { isSubmitting, isFetching, dispatch } = props;

    return (
        <React.Fragment>
            <Container
                name={NAME}
                head-onBack={handleBack}
                head-title="generic.changeAlias.title"
                wait={isFetching}
                scopeToShowNotification={SCOPE.CREDIT_CARDS_SET_ALIAS}>
                <Form className="above-the-fold">
                    <Field
                        autoFocus={false}
                        component={TextField}
                        hidePlaceholder
                        idForm={FORM_ID}
                        name="setAlias"
                        type="text"
                    />
                    <Button type="submit" bsStyle="primary" label="global.accept" loading={isSubmitting} />
                </Form>
            </Container>
        </React.Fragment>
    );

    function handleBack() {
        dispatch(ActionRouter.goBack());
    }
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store, props) => ({
    // TODO: shouldn't use withRouter, connected-react-router has support for this,
    //       but it needs to have the route definition centralized.
    id: props.match.params.id,
    creditCard: StoreCC.getDetail(store),
    isFetching: StoreCC.getFetching(store),
});

export default HighOrder(
    WithRouter,
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            setAlias: props.creditCard && props.creditCard.productAlias,
            productId: props.id,
        }),
        validationSchema: () =>
            Yup.object().shape({
                setAlias: Yup.string()
                    .trim()
                    .required(I18nUtils.get("generic.field.required"))
                    .matches(
                        new RegExp(GetConfig("core.productAlias.regexPattern")),
                        I18nUtils.get("form.character.restriction"),
                    ),
            }),
        handleSubmit: (props, formikBag) => {
            const { setAlias, productId } = props;
            formikBag.props.dispatch(
                ActionProducts.changeProductAlias({
                    alias: setAlias,
                    idProduct: productId,
                    backAfterCall: true,
                }),
                formikBag,
            );
        },
    }),
)(Component);
