import React from "react";

import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { BOND_CLASSIFICATION, EMPTY_STR, WM_OTHER_INVESTMENTS_TYPES, ISIN_MAX_LENGTH_WM } from "~/constants";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { SelectorsAction as SelectorsActionWMBonds, SelectorsStore as SelectorsStoreWMBonds } from "~/store/wm/bonds";
import * as UtilsI18n from "~/util/i18n";
import { numberFormat as NumberFormatUtil } from "~/util/number";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import NumberFormatInput from "~/components/NumberFormatInput";
import RadioOption from "~/components/RadioOption";
import Select from "~/components/Select";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TextField from "~/pages/_components/fields/TextField";

import Style from "./_Form.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

const FORM_ID = "wm.bonds.buy.search";

export function Component(props) {
    const { dispatch, setFieldValue, currencyList, expirationYearsAhead, lang, values } = props;

    const EMPTY_OPTION = { id: EMPTY_STR, label: "\u00A0" };

    const { decimalSeparator, thousandSeparator } = NumberFormatUtil(lang);

    React.useEffect(() => {
        if (currencyList.length === 0) {
            dispatch(SelectorsActionWMBonds.buyBondsPre());
        }
    }, [currencyList, dispatch]);

    const buildAmountOptions = (options) => {
        if (options && options.length > 0) {
            const newOptions = options.map((elem) => {
                return {
                    id: elem,
                    label: UtilsI18n.get(`currency.label.${elem}`),
                };
            });
            return [EMPTY_OPTION, ...newOptions];
        }
        return [EMPTY_OPTION];
    };

    const builYearsOptions = (options) => {
        if (options && options.length > 0) {
            const newOptions = options.map((elem) => {
                return {
                    id: elem.value,
                    label: elem.label,
                };
            });
            return [EMPTY_OPTION, ...newOptions];
        }
        return [EMPTY_OPTION];
    };

    return (
        <Form id={Style.ID}>
            <Box className="mt-5">
                <FieldLabel labelKey="wm.bonds.buy.search.type" />
                <Box flex>
                    <Field name="classification">
                        {({ field: { value } }) => (
                            <React.Fragment>
                                <RadioOption
                                    group="classification"
                                    checked={value === BOND_CLASSIFICATION.indistinct}
                                    formId={FORM_ID}
                                    onChange={handleRadioChange}
                                    value={BOND_CLASSIFICATION.indistinct}
                                    mode="edit"
                                />
                                <RadioOption
                                    className="ml-3"
                                    group="classification"
                                    checked={value === BOND_CLASSIFICATION.sovereigns}
                                    formId={FORM_ID}
                                    onChange={handleRadioChange}
                                    value={BOND_CLASSIFICATION.sovereigns}
                                    mode="edit"
                                />
                                <RadioOption
                                    className="ml-3"
                                    group="classification"
                                    checked={value === BOND_CLASSIFICATION.corporate}
                                    formId={FORM_ID}
                                    onChange={handleRadioChange}
                                    value={BOND_CLASSIFICATION.corporate}
                                    mode="edit"
                                />
                            </React.Fragment>
                        )}
                    </Field>
                </Box>
            </Box>
            <Field autoFocus={false} component={TextField} idForm={FORM_ID} name="issuer" type="text" hidePlaceholder />

            <Box flex align="start" className="mt-3">
                <Box className="w-50">
                    <Field
                        autoFocus={false}
                        component={TextField}
                        idForm={FORM_ID}
                        name="isin"
                        type="text"
                        hidePlaceholder
                        maxLength={ISIN_MAX_LENGTH_WM}
                    />
                </Box>

                <Box flex directionColumn align="start" className="w-50 ml-5">
                    <FieldLabel labelKey={`${FORM_ID}.expiration.label`} />
                    <Box className="input-group w-100">
                        <Field
                            valueKey="id"
                            labelKey="label"
                            className="flex-container slideFromBottom"
                            optionClassName="needsclick"
                            name="expiration"
                            idForm={FORM_ID}
                            component={Select}
                            options={builYearsOptions(expirationYearsAhead)}
                            placeholder={EMPTY_STR}
                            noResultsText={EMPTY_STR}
                            onChange={handleExpirationChange}
                            value={values.expiration}
                            clearable={false}
                            searchable={false}
                        />
                    </Box>
                </Box>
            </Box>
            <Box flex className="mt-3">
                <Box className="w-50">
                    <FieldLabel labelKey={`${FORM_ID}.coupon.label`} />
                    <Box className="input-group w-100">
                        <Field
                            allowNegative={false}
                            className="form-control"
                            component={NumberFormatInput}
                            decimalScale={3}
                            decimalSeparator={decimalSeparator}
                            fixedDecimalScale
                            maxLength={20}
                            name="coupon"
                            thousandSeparator={thousandSeparator}
                            type="tel"
                            onValueChange={({ value }) => setFieldValue("coupon", value)}
                        />
                    </Box>
                </Box>
                <Box flex directionColumn align="start" className="w-50 ml-5">
                    <FieldLabel labelKey={`${FORM_ID}.currency.label`} />
                    <Box className="input-group w-100">
                        <Field
                            placeholder={EMPTY_STR}
                            clearable={false}
                            searchable={false}
                            valueKey="id"
                            labelKey="label"
                            onChange={handleCurrencyChange}
                            options={buildAmountOptions(currencyList)}
                            className="flex-container slideFromBottom"
                            optionClassName="needsclick"
                            noResultsText={EMPTY_STR}
                            component={Select}
                            idForm={FORM_ID}
                            name="currency"
                            value={values.currency}
                        />
                    </Box>
                </Box>
            </Box>

            <Button className="button" id="filter" type="submit" variant="primary">
                {UtilsI18n.get("global.search")}
            </Button>
        </Form>
    );

    function handleRadioChange(value) {
        setFieldValue("classification", value);
    }

    function handleCurrencyChange(value) {
        setFieldValue("currency", value);
    }

    function handleExpirationChange(value) {
        setFieldValue("expiration", value);
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        currencyList: SelectorsStoreWMBonds.getCurrencyList(store),
        expirationYearsAhead: SelectorsStoreWMBonds.getExpirationYearsAhead(store),
        debitAccountsList: SelectorsStoreWMBonds.getDebitAccountsList(store),
        isFetching: SelectorsStoreWMBonds.getFetching(store),
        lang: SelectorsStoreI18n.getLang(store),
    };
};

export default HighOrder(
    WithRouter,
    Connect(mapStateToProps),
    WithFormik({
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    classification: Yup.string().required(UtilsI18n.get(`${FORM_ID}.classification.required`)),
                    issuer: !values.isin
                        ? Yup.string()
                              .required(UtilsI18n.get(`${FORM_ID}.issuer.required`))
                              .min(
                                  5,
                                  UtilsI18n.get("client.wm.searchByIsinTicker.issuerMinLengthNotReached", "", {
                                      MINLENGTH: 5,
                                  }),
                              )
                        : Yup.string().notRequired(),
                    // eslint-disable-next-line no-nested-ternary
                    isin: values.isin
                        ? Yup.string().test(
                              "len",
                              UtilsI18n.get(`${FORM_ID}.isin.length`),
                              (val) => val && val.toString().length === ISIN_MAX_LENGTH_WM,
                          )
                        : !values.issuer
                        ? Yup.string().required(UtilsI18n.get(`${FORM_ID}.isin.required`))
                        : Yup.string().notRequired(),
                }),
            ),
        mapPropsToValues: (props) => {
            // TODO: get values from preview form screen
            const { isin } = props.match.params;
            return {
                classification: BOND_CLASSIFICATION.indistinct,
                isin,
                issuer: EMPTY_STR,
                currency: EMPTY_STR,
                expiration: EMPTY_STR,
            };
        },
        handleSubmit: (values, formikBag) => {
            const { dispatch } = formikBag.props;
            const { id: currency } = values.currency;
            const { id: expiration } = values.expiration;
            const { classification, coupon, isin, issuer } = values;
            dispatch(
                SelectorsActionWMBonds.searchByIsin({
                    formData: {
                        classification,
                        expiration,
                        isin,
                        issuer,
                        currency,
                        coupon,
                        type: WM_OTHER_INVESTMENTS_TYPES.BONOS,
                    },
                    formikBag: {
                        ...formikBag,
                    },
                }),
            );
        },
    }),
)(Component);
