import React from "react";

import Style from "./List.rules.scss";
import Form from "./index";

export const { NAME, TYPE } = Style;
export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const className = props.className ? `${props.className} ${Style.ID}` : Style.ID;
    return <Form {...props} className={className} />;
}
Component.displayName = NAME;
Component.proptTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
