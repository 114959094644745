import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import Container from "~/containers/Internal/Home";
import { SelectorsStore as StoreDesktop, SelectorsAction as ActionDesktop } from "~/store/desktop";
import { SelectorsStore as StoreSession } from "~/store/session";

import * as Widgets from "./_Widgets";
import Namespace from "./_Widgets/_common/_Widget.scss";
import Style from "./_index.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        isFetching: PropTypes.bool,
        widgets: PropTypes.array,
    },
    defaults: {
        isFetching: false,
        widgets: [],
    },
};

const WIDTH = {
    Accounts: Namespace.CLASS_FULL,
    CreditCards: Namespace.CLASS_HALF,
    ExchangeRates: Namespace.CLASS_HALF,
    Factoring: Namespace.CLASS_HALF,
    Loans: Namespace.CLASS_HALF,
    PendingTransactions: Namespace.CLASS_FULL,
    Position: Namespace.CLASS_HALF,
    PreferentialTradingPrice: Namespace.CLASS_HALF,
    QrPayments: Namespace.CLASS_FULL,
    ServicePayments: Namespace.CLASS_FULL,
    TermDeposit: Namespace.CLASS_HALF,
};

export function Component(props) {
    const { dispatch, environment, isFetching, widgets } = props;
    const refEnv = React.useRef(null);
    const [components, setComponents] = React.useState([]);

    React.useEffect(() => {
        // no widgets found, request them.
        if (refEnv.current !== environment) {
            dispatch(ActionDesktop.loadLayoutRequest());

            refEnv.current = environment;

            setComponents([]);

            return;
        }

        const types = widgets.reduce((acc, cur, i) => {
            const preW = i > 0 ? WIDTH[widgets[i - 1].id] : null;
            const curW = WIDTH[cur.id];

            if (i === 0 || preW !== curW) {
                return { ...acc, [cur.id]: Namespace.CLASS_ODD };
            }
            // width is same, determine if it's even or odd.
            const preVal = acc[widgets[i - 1].id];
            const curVal = preVal === Namespace.CLASS_ODD ? Namespace.CLASS_EVEN : Namespace.CLASS_ODD;

            return { ...acc, [cur.id]: curVal };
        }, {});

        setComponents(
            widgets.map(({ id, key }) => {
                const Widget = Widgets[id];

                if (id === "QrPayments") {
                    const servicePaymentsExists = widgets.some(({ id: widgetId }) => widgetId === "ServicePayments");

                    if (servicePaymentsExists) {
                        return {
                            key,
                            Widget,
                            props: {
                                store: key,
                                className: `${Namespace.CLASS_HALF} ${Namespace.CLASS_EVEN} 
                                ${Namespace.CLASS_HALF_MOBILE} `,
                            },
                        };
                    }
                }

                if (id === "ServicePayments") {
                    const qrExists = widgets.some(({ id: widgetId }) => widgetId === "QrPayments");

                    if (qrExists) {
                        return {
                            key,
                            Widget,
                            props: {
                                store: key,
                                className: `${Namespace.CLASS_HALF}  ${Namespace.CLASS_ODD} 
                                ${Namespace.CLASS_HALF_MOBILE}`,
                            },
                        };
                    }
                }

                const forceFactoringWidgetFull =
                    id === "Factoring" && types[id] && types.Position === undefined && types.TermDeposit === undefined;
                const forcePositionWidgetFull =
                    id === "Position" &&
                    types[id] &&
                    ((types.Factoring && types.TermDeposit) ||
                        (types.Factoring === undefined && types.TermDeposit === undefined));
                const forceTermDepositWidgetFull =
                    id === "TermDeposit" && types[id] && types.Factoring === undefined && types.Position === undefined;

                const className =
                    forceFactoringWidgetFull || forcePositionWidgetFull || forceTermDepositWidgetFull
                        ? Namespace.CLASS_FULL
                        : WIDTH[id];

                return { key, Widget, props: { store: key, className: `${className} ${types[id]}` } };
            }),
        );
    }, [dispatch, environment, widgets]);

    const wait = isFetching || components.length !== widgets.length;

    return (
        <React.Fragment>
            <Container name={NAME} wait={wait} className={Style.CLASS} scopeToShowNotification={SCOPE.DESKTOP}>
                {components.map(({ Widget, key, props: wprops }) => (
                    <Widget key={key} {...wprops} />
                ))}
            </Container>
        </React.Fragment>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

function mapStateToProps(store) {
    // we need to take into account when then environment changes.
    const isFetching = StoreDesktop.isFetching(store) || StoreSession.isFetching(store);
    const { id: environment } = StoreSession.getActiveEnvironment(store) || {};

    return {
        environment,
        isFetching,
        widgets: StoreDesktop.getWidgets(store),
    };
}

export default Connect(mapStateToProps)(Component);
