import { TYPE, PROP, TYPE_SHARE_LOCAL } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.SHARE_LOCAL_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                statusShareLocal: TYPE_SHARE_LOCAL.SUCCESS,
                shareLocal: action.shareLocal,
            };
        case TYPE.SHARE_LOCAL_ERROR:
            return {
                ...storeProp,
                fetching: false,
                statusShareLocal: TYPE_SHARE_LOCAL.ERROR,
            };
        case TYPE.SHARE_LOCAL_CANCEL:
            return {
                ...storeProp,
                fetching: false,
                statusShareLocal: TYPE_SHARE_LOCAL.CANCEL,
            };
        case TYPE.SHARE_LOCAL_REQUEST:
            return {
                ...storeProp,
                typeAccount: "local",
                statusShareLocal: "",
                fetching: true,
            };
        case TYPE.SHARE_FOREIGN_REQUEST:
            return {
                ...storeProp,
                typeAccount: "foreign",
                statusShareLocal: "",
                fetching: true,
            };
        default:
            return storeProp;
    }
};
