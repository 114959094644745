import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { DASH, EMPTY_STR, ZERO } from "~/constants";
import { SelectorsAction as SelectorsActionCommunication } from "~/store/communications";
import { DEFAULT_TRAY } from "~/store/communications/_consts";
import { SelectorsStore as SelectorsStoreServicePayment } from "~/store/servicePayments";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import DataNumber from "~/components/DataNumber";
import Image from "~/components/Image";
import Link from "~/components/Link";
import Ticket from "~/components/Ticket/Ticket";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const { className, dispatch, ticketConfirmation, transaction } = props;
    const { data } = transaction || {};
    const {
        couponDiscount,
        debitAccountAlias,
        idToke: dataIdToke,
        merchantName: dataMerchantName,
        netAmount,
        qrPaymentStatusResponse,
        rawAmount,
        taxDiscount,
        total_amount: totalAmount,
    } = data || {};
    const { data: statusData } = qrPaymentStatusResponse || {};
    const {
        merchant_name: statusMerchantName,
        payment_method: paymentMethod,
        reference_id: referenceId,
    } = statusData || {};
    const currency = (statusData && statusData.currency) || (data && data.currency) || EMPTY_STR;
    const idToke = referenceId || dataIdToke || EMPTY_STR;
    const merchantName = statusMerchantName || dataMerchantName || EMPTY_STR;
    const amount = totalAmount || (rawAmount && rawAmount > ZERO && currency) || EMPTY_STR;
    let subject = UtilsI18n.get("servicePayment.qrModo.step3.help.subject");

    if (transaction) {
        if (idToke) {
            subject += ` ${idToke} ${DASH} ${transaction.idTransaction}`;
        } else {
            subject += ` ${transaction.idTransaction}`;
        }
    }

    const shareTicket = (idTransactionToRead) => {
        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    };

    return (
        <section id={Style.ID}>
            {data && (
                <React.Fragment>
                    <Ticket
                        className={className || EMPTY_STR}
                        ticketConfirmation={ticketConfirmation}
                        transaction={transaction}
                        hasMobileComponent
                        displayDownloadPDF
                        handleDownloadPDF={() => shareTicket(transaction.idTransaction)}>
                        <Box directionColumn align="flex-start">
                            <div className="data-section mb-3 mt-3">
                                <React.Fragment>
                                    <h3 className="data-title">
                                        {UtilsI18n.get("servicePayment.qrModo.step3.payments.title")}
                                    </h3>
                                    {idToke && (
                                        <Box className="data-wrapper">
                                            <span className="data-label">
                                                {UtilsI18n.get("servicePayment.qrModo.step3.tokeId")}
                                            </span>
                                            <span className="data-name">{idToke}</span>
                                        </Box>
                                    )}
                                    {(debitAccountAlias || paymentMethod) && (
                                        <Box className="data-wrapper">
                                            <span className="data-label">
                                                {UtilsI18n.get("servicePayment.qrModo.step3.account.origin")}
                                            </span>
                                            <span className="data-name">
                                                {debitAccountAlias || paymentMethod.account_number}
                                            </span>
                                        </Box>
                                    )}
                                    {merchantName && (
                                        <Box className="data-wrapper">
                                            <span className="data-label">
                                                {UtilsI18n.get("servicePayment.qrModo.step3.commerce")}
                                            </span>
                                            <span className="data-name">{merchantName}</span>
                                        </Box>
                                    )}

                                    {amount && currency && (
                                        <Box className="data-wrapper">
                                            <span className="data-label">
                                                {UtilsI18n.get("servicePayment.qrModo.step3.payments.trade")}
                                            </span>
                                            <span className="data-name">
                                                <DataNumber value={amount} prefix={currency} />
                                            </span>
                                        </Box>
                                    )}
                                    {taxDiscount && taxDiscount > ZERO && currency && (
                                        <Box className="data-wrapper">
                                            <span className="data-label">
                                                {UtilsI18n.get("servicePayment.qrModo.step3.refund")}
                                            </span>
                                            <span className="data-name">
                                                <DataNumber value={taxDiscount} prefix={currency} />
                                            </span>
                                        </Box>
                                    )}
                                    {couponDiscount && couponDiscount > ZERO && currency && (
                                        <Box className="data-wrapper">
                                            <span className="data-label">
                                                {UtilsI18n.get("servicePayment.qrModo.step3.couponApplied")}
                                            </span>
                                            <span className="data-name">
                                                <DataNumber value={couponDiscount} prefix={currency} />
                                            </span>
                                        </Box>
                                    )}
                                    {netAmount && netAmount > ZERO && currency && (
                                        <Box className="data-wrapper">
                                            <span className="data-label">
                                                {UtilsI18n.get("servicePayment.qrModo.step3.amountDebited")}
                                            </span>
                                            <span className="data-name">
                                                <DataNumber value={netAmount} prefix={currency} />
                                            </span>
                                        </Box>
                                    )}
                                </React.Fragment>
                            </div>
                        </Box>
                    </Ticket>

                    <div className="help-container">
                        <div className="title">
                            <Image src="help-icon.svg" />
                            <span>{UtilsI18n.get("servicePayment.qrModo.step3.help.title")}</span>
                        </div>
                        <div className="body">
                            <Link
                                onClick={() => {
                                    dispatch(
                                        SelectorsActionCommunication.dataToNewMessage({
                                            subject,
                                            trayLabel: DEFAULT_TRAY,
                                            message: UtilsI18n.get("servicePayment.qrModo.step3.help.double"),
                                        }),
                                    );
                                }}
                                to="/communications/compose">
                                <span>{UtilsI18n.get("servicePayment.qrModo.step3.help.double")}</span>
                            </Link>
                            <Link
                                onClick={() => {
                                    dispatch(
                                        SelectorsActionCommunication.dataToNewMessage({
                                            subject,
                                            trayLabel: DEFAULT_TRAY,
                                            message: UtilsI18n.get("servicePayment.qrModo.step3.help.badAmount"),
                                        }),
                                    );
                                }}
                                to="/communications/compose">
                                <span>{UtilsI18n.get("servicePayment.qrModo.step3.help.badAmount")}</span>
                            </Link>
                            <Link
                                onClick={() => {
                                    dispatch(
                                        SelectorsActionCommunication.dataToNewMessage({
                                            subject,
                                            trayLabel: DEFAULT_TRAY,
                                            message: UtilsI18n.get("servicePayment.qrModo.step3.help.paymentNotArrive"),
                                        }),
                                    );
                                }}
                                to="/communications/compose">
                                <span>{UtilsI18n.get("servicePayment.qrModo.step3.help.paymentNotArrive")}</span>
                            </Link>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </section>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreServicePayment.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
