import React from "react";

import { push as Push } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import Container from "~/containers/Internal/Form/Simple";
import { SelectorsAction, SelectorsStore } from "~/store/share";

import Style from "./Share.rules.scss";
import ShareItem from "./_Item";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {
        typeAccount: PropTypes.string,
        shareLocal: PropTypes.bool.isRequired,
        dispatch: PropTypes.func.isRequired,
        fetching: PropTypes.bool,
    },
    defaults: {
        typeAccount: "",
        fetching: false,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    handleClose = () => {
        const { dispatch } = this.props;
        dispatch(Push("/"));
    };

    handleShareLocal = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsAction.shareLocal({ typeAccount: "local" }));
    };

    handleShareForeifgn = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsAction.shareForeign({ typeAccount: "foreign" }));
    };

    render() {
        const { fetching } = this.props;

        return (
            <Container
                name={Style.NAME}
                className={Style.CLASS}
                wait={fetching}
                head-title="share.modal.title"
                head-onClose={this.handleClose}>
                <div className="transfer-setup-block transfer-options">
                    <ul className="transfer-options-list">
                        <ShareItem type="local" title="share.item.local" onClick={this.handleShareLocal} />
                        <ShareItem type="foreign" title="share.item.foreign" onClick={this.handleShareForeifgn} />
                    </ul>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    shareLocal: SelectorsStore.shareLocal(store),
    fetching: SelectorsStore.fetching(store),
});

export default Connect(mapStateToProps)(Component);
