export const accountItemProductTypes = [10, 11, 12];

export const urlRedirectByProductType = {
    10: "/accounts/",
    11: "/accounts/",
    12: "/accounts/",
};

export const modalFieldsByProductType = {
    100: [{ id: "DESCRIPCION" }, { id: "SALDO", type: "amount" }, { id: "FECHA" }, { id: "OPERACION" }],
    101: [{ id: "DESCRIPCION" }, { id: "SALDO", type: "amount" }, { id: "FECHA" }, { id: "OPERACION" }],
    102: [
        { id: "DESCRIPCION" },
        { id: "SALDO", type: "amount" },
        { id: "FECHA" },
        { id: "OPERACION" },
        { id: "FECHAVTOSEGURO" },
        { id: "FECHAVTOTASACION" },
    ],
    103: [
        { id: "DESCRIPCION" },
        { id: "SALDO", type: "amount" },
        { id: "FECHA" },
        { id: "OPERACION" },
        { id: "FECHAVTOSEGURO" },
    ],
    104: [
        { id: "DESCRIPCION" },
        { id: "SALDO", type: "amount" },
        { id: "FECHA" },
        { id: "OPERACION" },
        { id: "OTORGANTE" },
    ],
    105: [
        { id: "DESCRIPCION" },
        { id: "SALDO", type: "amount" },
        { id: "FECHA" },
        { id: "OPERACION" },
        { id: "OTORGANTE" },
        { id: "FECHAVTOSEGURO" },
        { id: "FECHAVTOTASACION" },
    ],
    106: [{ id: "DESCRIPCION" }, { id: "SALDO", type: "amount" }, { id: "FECHA" }, { id: "OPERACION" }],
    107: [{ id: "DESCRIPCION" }, { id: "SALDO", type: "amount" }, { id: "FECHA" }, { id: "OPERACION" }],
    108: [
        { id: "DESCRIPCION" },
        { id: "IMPORTE", type: "amount" },
        { id: "FECHAPRENDA" },
        { id: "NROOPERACION" },
        { id: "OTORGANTE" },
    ],
    109: [
        { id: "DESCRIPCION" },
        { id: "SALDO", type: "amount" },
        { id: "FECHA" },
        { id: "OPERACION" },
        { id: "FECHAVTOSEGURO" },
        { id: "FECHAVTOTASACION" },
    ],
    110: [
        { id: "DESCRIPCION" },
        { id: "SALDO", type: "amount" },
        { id: "FECHA" },
        { id: "OPERACION" },
        { id: "OTORGANTE" },
        { id: "FECHAVTO" },
    ],
    111: [
        { id: "DESCRIPCION" },
        { id: "SALDO", type: "amount" },
        { id: "FECHA" },
        { id: "OPERACION" },
        { id: "OTORGANTE" },
        { id: "COBERTURA" },
    ],
    112: [
        { id: "DESCRIPCION" },
        { id: "SALDO", type: "amount" },
        { id: "FECHA" },
        { id: "OPERACION" },
        { id: "FECHAVTO" },
        { id: "OTORGANTE" },
        { id: "CUENTANOMBRE" },
    ],
    113: [
        { id: "DESCRIPCION" },
        { id: "SALDO", type: "amount" },
        { id: "FECHA" },
        { id: "OPERACION" },
        { id: "OTORGANTE" },
        { id: "CUENTANOMBRE" },
    ],
    114: [
        { id: "DESCRIPCION" },
        { id: "SALDO", type: "amount" },
        { id: "FECHA" },
        { id: "OPERACION" },
        { id: "OTORGANTE" },
        { id: "CUENTANOMBRE" },
    ],
    115: [
        { id: "DESCRIPCION" },
        { id: "SALDO", type: "amount" },
        { id: "FECHA" },
        { id: "OPERACION" },
        { id: "OTORGANTE" },
    ],
    116: [
        { id: "DESCRIPCION" },
        { id: "SALDO", type: "amount" },
        { id: "FECHA" },
        { id: "OPERACION" },
        { id: "OTORGANTE" },
        { id: "CUENTANOMBRE" },
        { id: "FECHAVTO" },
    ],
    117: [{ id: "DESCRIPCION" }, { id: "SALDO", type: "amount" }, { id: "FECHA" }, { id: "OPERACION" }],
    118: [{ id: "SALDO", type: "amount" }, { id: "OPERACION" }, { id: "FECHA" }, { id: "FECHAVTO" }],
    119: [{ id: "SALDO", type: "amount" }, { id: "OPERACION" }, { id: "FECHA" }, { id: "FECHAVTO" }],
    120: [
        { id: "IMPORTE", type: "amount" },
        { id: "BANCO" },
        { id: "NROCHEQUE" },
        { id: "FECHAINGRESO" },
        { id: "FECHAVTO" },
        { id: "LIBRADOR" },
    ],
    10: [{ id: "SALDO", type: "amount" }, { id: "LIMITEDESOBREGIRO" }, { id: "SOBREGIRODISPONIBLE" }],
    11: [{ id: "SALDO", type: "amount" }],
    12: [{ id: "SALDO", type: "amount" }],
    13: [
        { id: "IMPORTE", type: "amount" },
        { id: "FECHAVTO" },
        { id: "TASA" },
        { id: "INTERESESALVTO", type: "amount" },
        { id: "FECHAINICIO" },
        { id: "PLAZO" },
        { id: "INSTRUCCIONALVTO" },
        { id: "INTERESESALAFECHA", type: "amount" },
        { id: "IMPORTETOTAL", type: "amount" },
        { id: "CUENTA" },
    ],
    14: [
        { id: "IMPORTE", type: "amount" },
        { id: "BANCOEMISOR" },
        { id: "FECHAVTO" },
        { id: "TASA" },
        { id: "INTERESESALVTO" },
        { id: "FECHAINICIO" },
        { id: "CALIFBANCO" },
        { id: "INTERESESALAFECHA", type: "amount" },
        { id: "IMPORTETOTAL", type: "amount" },
        { id: "CUENTA" },
    ],
    15: [
        { id: "NROTARJETA_FORMATTED" },
        { id: "PAGOUYU", type: "amount", forceCurrency: "0" },
        { id: "PAGOUSD", type: "amount" },
        { id: "VTO" },
        { id: "PROXIMOVTO" },
        { id: "NROCUENTA" },
        { id: "DEBAUTO" },
        { id: "LIMOTORGADO", type: "amount" },
        { id: "MINIMOUSD", type: "amount" },
        { id: "MINIMOUYU", type: "amount", forceCurrency: "0" },
    ],
    16: [
        { id: "IMPORTE", type: "amount" },
        { id: "BANCO" },
        { id: "NROCHEQUE" },
        { id: "FECHAVTO" },
        { id: "FECHAINGRESO" },
        { id: "LIBRADO" },
    ],
    17: [
        { id: "IMPORTE", type: "amount" },
        { id: "BANCO" },
        { id: "NROCHEQUE" },
        { id: "FECHAVTO" },
        { id: "FECHAINGRESO" },
        { id: "LIBRADO" },
    ],
    18: [{ id: "CUENTA" }, { id: "SALDO", type: "amount" }],
    20: [
        { id: "VALORNOMINAL", type: "amount" },
        { id: "COMPRA" },
        { id: "TASADTO" },
        { id: "VTO" },
        { id: "INTERESESALVTO" },
        { id: "DESCRIPCION" },
        { id: "FECHAINICIO" },
        { id: "VALORCOMPRA", type: "amount" },
        { id: "PRECIOMERCADO" },
        { id: "VALOREFECTIVO", type: "amount" },
    ],
    21: [
        { id: "DESCRIPCION" },
        { id: "PAIS" },
        { id: "COMPANIA" },
        { id: "COMMONCODE" },
        { id: "CUSIP" },
        { id: "ISIN" },
        { id: "CALIFICACION" },
        { id: "CALIFICADORA" },
        { id: "CUSTODIANTE" },
        { id: "VALORNOMINAL", type: "amount" },
        { id: "PRECIOCOMPRA" },
        { id: "INTERESESGANADOS", type: "amount" },
        { id: "FECHAVTO" },
        { id: "FECHACOMPRA" },
        { id: "VALORCOMPRA", type: "amount" },
        { id: "PRECIOMERCADO" },
        { id: "INTERESESCORRIDOS", type: "amount" },
        { id: "VALOREFECTIVO", type: "amount" },
    ],
    22: [
        { id: "DESCRIPCION" },
        { id: "PAIS" },
        { id: "COMPANIA" },
        { id: "COMMONCODE" },
        { id: "CUSIP" },
        { id: "ISIN" },
        { id: "CALIFICACION" },
        { id: "CALIFICADORA" },
        { id: "CUSTODIANTE" },
        { id: "CALIFICADORA" },
        { id: "CUSTODIANTE" },
        { id: "VALORNOMINAL", type: "amount" },
        { id: "PRECIOCOMPRA" },
        { id: "INTERESESGANADOS", type: "amount" },
        { id: "FECHAVTO" },
        { id: "FECHACOMPRA" },
        { id: "VALORCOMPRA", type: "amount" },
        { id: "PRECIOMERCADO" },
        { id: "INTERESESCORRIDOS", type: "amount" },
        { id: "VALOREFECTIVO", type: "amount" },
    ],
    23: [
        { id: "DESCRIPCION" },
        { id: "PAIS" },
        { id: "COMPANIA" },
        { id: "COMMONCODE" },
        { id: "CUSIP" },
        { id: "ISIN" },
        { id: "CALIFICACION" },
        { id: "CALIFICADORA" },
        { id: "CUSTODIANTE" },
        { id: "CANTIDAD" },
        { id: "PRECIOCOMPRA", type: "amount" },
        { id: "INTERESESALAFECHA", type: "amount" },
        { id: "FECHAVTO" },
        { id: "FECHACOMPRA" },
        { id: "VALORCOMPRA", type: "amount" },
        { id: "PRECIOMERCADO", type: "amount" },
        { id: "VALOREFECTIVO", type: "amount" },
    ],
    24: [
        { id: "DESCRIPCION" },
        { id: "PAIS" },
        { id: "COMPANIA" },
        { id: "COMMONCODE" },
        { id: "CUSIP" },
        { id: "ISIN" },
        { id: "CALIFICACION" },
        { id: "CALIFICADORA" },
        { id: "CUSTODIANTE" },
        { id: "CANTIDAD" },
        { id: "PRECIOCOMPRA", type: "amount" },
        { id: "PRECIOMERCADO", type: "amount" },
        { id: "VALOREFECTIVO", type: "amount" },
        { id: "FECHACOMPRA" },
        { id: "VALORCOMPRA", type: "amount" },
    ],
    25: [
        { id: "DESCRIPCION" },
        { id: "PAIS" },
        { id: "COMPANIA" },
        { id: "COMMONCODE" },
        { id: "CUSIP" },
        { id: "ISIN" },
        { id: "CALIFICACION" },
        { id: "CALIFICADORA" },
        { id: "CUSTODIANTE" },
        { id: "CANTIDAD" },
        { id: "PRECIOCOMPRA", type: "amount" },
        { id: "PRECIOMERCADO", type: "amount" },
        { id: "VALOREFECTIVO", type: "amount" },
        { id: "FECHACOMPRA" },
        { id: "VALORCOMPRA", type: "amount" },
    ],
    26: [
        { id: "DESCRIPCION" },
        { id: "PAIS" },
        { id: "COMPANIA" },
        { id: "COMMONCODE" },
        { id: "CUSIP" },
        { id: "ISIN" },
        { id: "CALIFICACION" },
        { id: "CALIFICADORA" },
        { id: "CUSTODIANTE" },
        { id: "CANTIDAD" },
        { id: "PRECIOCOMPRA", type: "amount" },
        { id: "INTERESESALAFECHA", type: "amount" },
        { id: "FECHAVTO" },
        { id: "FECHACOMPRA" },
        { id: "VALORCOMPRA", type: "amount" },
        { id: "PRECIOMERCADO", type: "amount" },
        { id: "VALOREFECTIVO", type: "amount" },
    ],
    28: [
        { id: "DESCRIPCION" },
        { id: "TASA" },
        { id: "VALORCUOTA", type: "amount" },
        { id: "ULTCUOTAPAGA" },
        { id: "PROXCUOTA" },
        { id: "CAPITAL" },
        { id: "FECHAINICIO" },
        { id: "FECHAVTO" },
        { id: "INTERESES", type: "amount" },
        { id: "VENCIDO" },
        { id: "NROOPERACION" },
        { id: "TOTAL", type: "amount" },
    ],
    29: [
        { id: "DESCRIPCION" },
        { id: "TASA" },
        { id: "VALORCUOTA", type: "amount" },
        { id: "ULTCUOTAPAGA" },
        { id: "PROXCUOTA" },
        { id: "CAPITAL" },
        { id: "FECHAINICIO" },
        { id: "FECHAVTO" },
        { id: "INTERESES", type: "amount" },
        { id: "VENCIDO" },
        { id: "NROOPERACION" },
        { id: "TOTAL", type: "amount" },
    ],
    30: [
        { id: "DESCRIPCION" },
        { id: "TASA" },
        { id: "VALORCUOTA", type: "amount" },
        { id: "ULTCUOTAPAGA" },
        { id: "PROXCUOTA" },
        { id: "CAPITAL", type: "amount" },
        { id: "FECHAINICIO" },
        { id: "FECHAVTO" },
        { id: "INTERESES", type: "amount" },
        { id: "VENCIDO" },
        { id: "NROOPERACION" },
        { id: "TOTAL", type: "amount" },
    ],
    31: [
        { id: "SALDO", type: "amount" },
        { id: "FECHAVTO" },
        { id: "TASA" },
        { id: "PROXIMOVTO" },
        { id: "INTERESESALVTO", type: "amount" },
        { id: "CAPITAL", type: "amount" },
        { id: "FECHAINICIO" },
        { id: "NROOPERACION" },
    ],
    32: [
        { id: "SALDO", type: "amount" },
        { id: "TASAVIGENTE", type: "number" },
        { id: "PROXIMACUOTACAPITAL", type: "amount" },
        { id: "PROXIMOCOBROINTERESES", type: "amount" },
        { id: "PROXIMOVTO" },
        { id: "NROOPERACION" },
        { id: "FECHAINICIO" },
        { id: "CAPITAL", type: "amount" },
        { id: "FECHAVTO" },
    ],
    33: [
        { id: "SALDO", type: "amount" },
        { id: "TASAVIGENTE" },
        { id: "PROXIMACUOTACAPITAL", type: "amount" },
        { id: "PROXIMOCOBROINTERESES", type: "amount" },
        { id: "PROXIMOVTO" },
        { id: "NROOPERACION" },
        { id: "FECHAINICIO" },
        { id: "CAPITAL", type: "amount" },
        { id: "FECHAVTO" },
    ],
    34: [
        { id: "IMPORTECHEQUE", type: "amount" },
        { id: "NROCHEQUE" },
        { id: "FECHAVTO" },
        { id: "TASA" },
        { id: "IMPORTELIQUIDO" },
        { id: "FECHAINGRESO" },
        { id: "BANCO" },
        { id: "LIBRADOR" },
    ],
    35: [
        { id: "IMPORTEFACTURA", type: "amount" },
        { id: "NROFACTURA" },
        { id: "FECHAVTO" },
        { id: "TASA" },
        { id: "IMPORTELIQUIDO" },
        { id: "FECHAINGRESO" },
    ],
    36: [
        { id: "IMPORTECONFORME", type: "amount" },
        { id: "NROCONFORME" },
        { id: "FECHAVTO" },
        { id: "TASA" },
        { id: "IMPORTELIQUIDO" },
        { id: "FECHAINGRESO" },
    ],
    37: [
        { id: "SALDO", type: "amount" },
        { id: "NROOPERACION" },
        { id: "FECHAVTO" },
        { id: "TASA" },
        { id: "FECHAINGRESO" },
        { id: "CAPITAL" },
    ],
    38: [
        { id: "NROFACTURA" },
        { id: "FECHAVTO" },
        { id: "PROVEEDOR" },
        { id: "FECHAINGRESO" },
        { id: "IMPORTEFACTURA", type: "amount" },
    ],
    39: [
        { id: "SALDO", type: "amount" },
        { id: "FECHAVTO" },
        { id: "TASA" },
        { id: "PROXIMOVTO" },
        { id: "INTERESESALVTO", type: "amount" },
        { id: "CAPITAL" },
        { id: "FECHAINICIO" },
        { id: "INTERESALVTO", type: "amount" },
        { id: "NROOPERACION" },
    ],
    40: [{ id: "SALDO", type: "amount" }, { id: "FECHA" }, { id: "OPERACION" }],
    42: [
        { id: "IMPORTEORDEN", type: "amount" },
        { id: "FECHAINGRESO" },
        { id: "NROFACTURA" },
        { id: "FECHACOBRO" },
        { id: "EMPRESA" },
        { id: "IMPORTEDESCONTADO", type: "amount" },
    ],
    43: [{ id: "CANTIDAD" }, { id: "NROOPERACION" }, { id: "FECHAVTO" }, { id: "SALDO" }],
    60: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "FECHAVTO" },
        { id: "FECHAREMESA" },
        { id: "ORDENANTE" },
        { id: "COURRIER" },
        { id: "NROFACTURA" },
        { id: "CEDIDA" },
    ],
    61: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "FECHAVTO" },
        { id: "FECHAINGRESO" },
        { id: "ORDENANTE" },
        { id: "NROFACTURA" },
        { id: "NROFACTURA" },
    ],
    62: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "FECHAVTO" },
        { id: "TASA" },
        { id: "FECHAINGRESO" },
        { id: "GIRADO" },
    ],
    63: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "FECHAINGRESO" }, { id: "BENEFICIARIO" }],
    64: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "FECHAVTO" },
        { id: "FECHAINGRESO" },
        { id: "BENEFICIARIO" },
    ],
    65: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "FECHAVTO" },
        { id: "FECHAINGRESO" },
        { id: "BENEFICIARIO" },
    ],
    66: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "FECHAVTO" },
        { id: "FECHAINGRESO" },
        { id: "BENEFICIARIO" },
    ],
    67: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "FECHAVTO" },
        { id: "FECHAINGRESO" },
        { id: "BENEFICIARIO" },
    ],
    68: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "VTO" },
        { id: "VALIDEZ" },
        { id: "FECHAINGRESO" },
        { id: "ORDENANTE" },
        { id: "EMISOR" },
        { id: "CEDIDA" },
    ],
    69: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "VTO" },
        { id: "VALIDEZFECHAVTO" },
        { id: "FECHAINGRESO" },
        { id: "ORDENANTE" },
        { id: "CEDIDA" },
    ],
    70: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "VALIDEZ" },
        { id: "FECHAINGRESO" },
        { id: "ORDENANTE" },
    ],
    71: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "FECHAVTO" },
        { id: "FECHAINGRESO" },
        { id: "BENEFICIARIO" },
    ],
    72: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "FECHAVTO" },
        { id: "FECHAINGRESO" },
        { id: "BENEFICIARIO" },
    ],
    73: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "FECHAVTO" },
        { id: "FECHAINGRESO" },
        { id: "BENEFICIARIO" },
    ],
    74: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "VTO" },
        { id: "VALIDEZ" },
        { id: "FECHAINGRESO" },
        { id: "ORDENANTE" },
        { id: "EMISOR" },
        { id: "CEDIDA" },
    ],
    75: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "FECHAVTO" },
        { id: "FECHAREMESA" },
        { id: "ORDENANTE" },
        { id: "COURRIER" },
        { id: "NROFACTURA" },
        { id: "CEDIDA" },
    ],
    76: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "VALIDEZ" },
        { id: "FECHAREMESA" },
        { id: "ORDENANTE" },
        { id: "COURRIER" },
        { id: "NROFACTURA" },
        { id: "CEDIDA" },
    ],
    77: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "VTO" },
        { id: "VALIDEZ" },
        { id: "FECHAINGRESO" },
        { id: "ORDENANTE" },
        { id: "EMISOR" },
        { id: "CEDIDA" },
    ],
    78: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "VALIDEZ" },
        { id: "FECHAINGRESO" },
        { id: "ORDENANTE" },
        { id: "EMISOR" },
        { id: "NROFACTURA" },
        { id: "CEDIDA" },
    ],
    79: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "NRODECARTADECREDITO" }, { id: "ORDENANTE" }],
    81: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "FECHAVTO" },
        { id: "FECHAINGRESO" },
        { id: "GIRADO" },
        { id: "COURRIER" },
        { id: "NROFACTURA" },
        { id: "CEDIDA" },
    ],
    84: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "FECHAVTO" },
        { id: "FECHAINGRESO" },
        { id: "CORRESPONSAL" },
        { id: "GIRADOR" },
    ],
    85: [{ id: "SALDO", type: "amount" }, { id: "NROOPERACION" }, { id: "FECHAVTO" }, { id: "FECHAINGRESO" }],
    86: [{ id: "SALDO", type: "amount" }, { id: "NROOPERACION" }, { id: "FECHAVTO" }, { id: "FECHAINGRESO" }],
    87: [
        { id: "SALDO", type: "amount" },
        { id: "IMPORTEPREFI" },
        { id: "NROOPERACION" },
        { id: "FECHAVTO" },
        { id: "FECHAINGRESO" },
    ],
    88: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "FECHAINGRESO" }, { id: "BENEFICIARIO" }],
    89: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "FECHAVTO" },
        { id: "FECHAINGRESO" },
        { id: "BENEFICIARIO" },
    ],
    90: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "FECHAVTO" },
        { id: "FECHAINGRESO" },
        { id: "BENEFICIARIO" },
    ],
    91: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "FECHAINGRESO" },
        { id: "FECHAVTO" },
        { id: "BENEFICIARIO" },
    ],
    92: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "FECHAINGRESO" }, { id: "BENEFICIARIO" }],
    93: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "FECHAINGRESO" },
        { id: "FECHAVTO" },
        { id: "BENEFICIARIO" },
    ],
    94: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "FECHAREMESA" },
        { id: "FECHAVTO" },
        { id: "ORDENANTE" },
        { id: "EMISOR" },
        { id: "NROFACTURA" },
        { id: "CEDIDA" },
    ],
    95: [
        { id: "IMPORTE", type: "amount" },
        { id: "NROOPERACION" },
        { id: "VALIDEZFECHAVTO" },
        { id: "FECHAINGRESO" },
        { id: "ORDENANTE" },
        { id: "NROFACTURA" },
    ],
};

export const tableHeadersByProductType = {
    100: [{ id: "DESCRIPCION" }, { id: "SALDO", type: "amount" }, { id: "FECHA" }, { id: "OPERACION" }],
    101: [{ id: "DESCRIPCION" }, { id: "SALDO", type: "amount" }, { id: "FECHA" }, { id: "OPERACION" }],
    102: [{ id: "DESCRIPCION" }, { id: "SALDO", type: "amount" }, { id: "FECHA" }, { id: "OPERACION" }],
    103: [{ id: "DESCRIPCION" }, { id: "SALDO", type: "amount" }, { id: "FECHA" }, { id: "OPERACION" }],
    104: [{ id: "DESCRIPCION" }, { id: "SALDO", type: "amount" }, { id: "FECHAPRENDA" }, { id: "OPERACION" }],
    105: [{ id: "DESCRIPCION" }, { id: "SALDO", type: "amount" }, { id: "FECHA" }, { id: "OPERACION" }],
    106: [{ id: "DESCRIPCION" }, { id: "SALDO", type: "amount" }, { id: "FECHA" }, { id: "OPERACION" }],
    107: [{ id: "DESCRIPCION" }, { id: "SALDO", type: "amount" }, { id: "FECHA" }, { id: "OPERACION" }],
    108: [{ id: "DESCRIPCION" }, { id: "IMPORTE", type: "amount" }, { id: "FECHAPRENDA" }, { id: "NROOPERACION" }],
    109: [{ id: "DESCRIPCION" }, { id: "SALDO", type: "amount" }, { id: "FECHA" }, { id: "OPERACION" }],
    110: [{ id: "DESCRIPCION" }, { id: "SALDO", type: "amount" }, { id: "FECHA" }, { id: "OPERACION" }],
    111: [{ id: "DESCRIPCION" }, { id: "SALDO", type: "amount" }, { id: "FECHA" }, { id: "OPERACION" }],
    112: [{ id: "DESCRIPCION" }, { id: "SALDO", type: "amount" }, { id: "FECHA" }, { id: "OPERACION" }],
    113: [{ id: "DESCRIPCION" }, { id: "SALDO", type: "amount" }, { id: "FECHA" }, { id: "OPERACION" }],
    114: [{ id: "DESCRIPCION" }, { id: "SALDO", type: "amount" }, { id: "FECHA" }, { id: "OPERACION" }],
    115: [{ id: "DESCRIPCION" }, { id: "SALDO", type: "amount" }, { id: "FECHA" }, { id: "OPERACION" }],
    116: [{ id: "DESCRIPCION" }, { id: "SALDO", type: "amount" }, { id: "FECHA" }, { id: "OPERACION" }],
    117: [{ id: "DESCRIPCION" }, { id: "SALDO", type: "amount" }, { id: "FECHA" }, { id: "OPERACION" }],
    118: [{ id: "SALDO", type: "amount" }, { id: "OPERACION" }],
    119: [{ id: "SALDO", type: "amount" }, { id: "OPERACION" }],
    120: [{ id: "IMPORTE", type: "amount" }, { id: "BANCO" }, { id: "NROCHEQUE" }, { id: "FECHAVTO" }],
    10: [
        {
            id: "CUENTA",
        },
        {
            id: "SALDO",
            type: "amount",
        },
        {
            id: "LIMITEDESOBREGIRO",
            type: "amount",
        },
        {
            id: "SOBREGIRODISPONIBLE",
            type: "amount",
        },
    ],
    11: [{ id: "CUENTA" }, { id: "SALDO", type: "amount" }],
    12: [{ id: "CUENTA" }, { id: "SALDO", type: "amount" }],
    13: [
        {
            id: "IMPORTE",
            type: "amount",
        },
        {
            id: "FECHAVTO",
            type: "date",
        },
        {
            id: "TASA",
            type: "number",
        },
        {
            id: "INTERESESALVTO",
            type: "amount",
        },
    ],
    14: [
        { id: "IMPORTE", type: "amount" },
        { id: "BANCOEMISOR" },
        { id: "FECHAVTO" },
        { id: "TASA" },
        { id: "INTERESESALVTO" },
    ],
    15: [
        {
            id: "DESCRIPCION",
            isProductAttribute: true,
            productAttribute: "productAlias",
        },
        {
            id: "NROTARJETA_FORMATTED",
        },
        {
            id: "PAGOUYU",
            type: "amount",
            forceCurrency: "0",
        },
        {
            id: "PAGOUSD",
            type: "amount",
            forceCurrency: "2222",
        },
        {
            id: "VTO",
            type: "date",
        },
        {
            id: "PROXIMOVTO",
            type: "date",
        },
    ],
    16: [{ id: "IMPORTE", type: "amount" }, { id: "BANCO" }, { id: "NROCHEQUE" }, { id: "FECHAVTO" }],
    17: [{ id: "IMPORTE", type: "amount" }, { id: "BANCO" }, { id: "NROCHEQUE" }, { id: "FECHAVTO" }],
    18: [{ id: "CUENTA", type: "amount" }, { id: "SALDO" }],
    20: [
        { id: "VALORNOMINAL", type: "amount" },
        { id: "COMPRA" },
        { id: "TASADTO" },
        { id: "VTO" },
        { id: "INTERESESALVTO" },
    ],
    21: [
        { id: "DESCRIPCION" },
        { id: "VALORNOMINAL", type: "amount" },
        { id: "PRECIOCOMPRA" },
        { id: "INTERESESGANADOS", type: "amount" },
        { id: "FECHAVTO" },
    ],
    22: [
        { id: "DESCRIPCION" },
        { id: "VALORNOMINAL", type: "amount" },
        { id: "PRECIOCOMPRA" },
        { id: "INTERESESGANADOS", type: "amount" },
        { id: "FECHAVTO" },
    ],
    23: [
        { id: "DESCRIPCION" },
        { id: "CANTIDAD", type: "number" },
        { id: "PRECIOCOMPRA", type: "amount" },
        { id: "INTERESESALAFECHA", type: "amount" },
        { id: "FECHAVTO" },
    ],
    24: [
        { id: "DESCRIPCION" },
        { id: "CANTIDAD", type: "number" },
        { id: "PRECIOCOMPRA", type: "amount" },
        { id: "PRECIOMERCADO", type: "amount" },
        { id: "VALOREFECTIVO", type: "amount" },
    ],
    25: [
        { id: "DESCRIPCION" },
        { id: "CANTIDAD", type: "number" },
        { id: "PRECIOCOMPRA", type: "amount" },
        { id: "PRECIOMERCADO", type: "amount" },
        { id: "VALOREFECTIVO", type: "amount" },
    ],
    26: [
        { id: "DESCRIPCION" },
        { id: "CANTIDAD", type: "number" },
        { id: "PRECIOCOMPRA", type: "amount" },
        { id: "INTERESESALAFECHA" },
        { id: "FECHAVTO" },
    ],
    28: [{ id: "DESCRIPCION" }, { id: "TASA" }, { id: "VALORCUOTA" }, { id: "ULTCUOTAPAGA" }, { id: "PROXCUOTA" }],
    29: [{ id: "DESCRIPCION" }, { id: "TASA" }, { id: "VALORCUOTA" }, { id: "ULTCUOTAPAGA" }, { id: "PROXCUOTA" }],
    30: [
        { id: "DESCRIPCION" },
        { id: "TASA", type: "number" },
        { id: "VALORCUOTA", type: "amount" },
        { id: "ULTCUOTAPAGA" },
        { id: "PROXCUOTA" },
    ],
    31: [
        { id: "SALDO", type: "amount" },
        { id: "FECHAVTO" },
        { id: "TASA" },
        { id: "PROXIMOVTO" },
        { id: "INTERESESALVTO", type: "amount" },
    ],
    32: [
        { id: "SALDO", type: "amount" },
        { id: "TASAVIGENTE", type: "number" },
        { id: "PROXIMACUOTACAPITAL", type: "amount" },
        { id: "PROXIMOCOBROINTERESES", type: "amount" },
        { id: "PROXIMOVTO" },
    ],
    33: [
        { id: "SALDO", type: "amount" },
        { id: "TASAVIGENTE" },
        { id: "PROXIMACUOTACAPITAL" },
        { id: "PROXIMOCOBROINTERESES" },
        { id: "PROXIMOVTO" },
    ],
    34: [
        { id: "IMPORTECHEQUE", type: "amount" },
        { id: "NROCHEQUE" },
        { id: "FECHAVTO" },
        { id: "TASA" },
        { id: "IMPORTELIQUIDO" },
    ],
    35: [
        { id: "IMPORTEFACTURA", type: "amount" },
        { id: "NROFACTURA" },
        { id: "FECHAVTO" },
        { id: "TASA" },
        { id: "IMPORTELIQUIDO" },
    ],
    36: [
        { id: "IMPORTECONFORME", type: "amount" },
        { id: "NROCONFORME" },
        { id: "FECHAVTO" },
        { id: "TASA" },
        { id: "IMPORTELIQUIDO" },
    ],
    37: [{ id: "SALDO", type: "amount" }, { id: "NROOPERACION" }, { id: "FECHAVTO" }, { id: "TASA" }],
    38: [{ id: "PROVEEDOR" }, { id: "NROFACTURA" }, { id: "FECHAVTO" }, { id: "IMPORTEFACTURA", type: "amount" }],
    39: [
        { id: "SALDO", type: "amount" },
        { id: "FECHAVTO" },
        { id: "TASA" },
        { id: "PROXIMOVTO" },
        { id: "INTERESESALVTO" },
    ],
    40: [{ id: "SALDO", type: "amount" }, { id: "FECHA" }, { id: "OPERACION" }],
    42: [
        {
            id: "IMPORTEORDEN",
            type: "amount",
        },
        {
            id: "FECHACOBRO",
            type: "date",
        },
        {
            id: "EMPRESA",
        },
        {
            id: "IMPORTEDESCONTADO",
            type: "amount",
        },
    ],
    43: [
        {
            id: "CANTIDAD",
        },
        {
            id: "FECHAVTO",
            type: "date",
        },
        {
            id: "SALDO",
            type: "amount",
        },
    ],
    50: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }],
    60: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "FECHAVTO" }],
    61: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "FECHAVTO" }],
    62: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "FECHAVTO" }, { id: "TASA" }],
    63: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }],
    64: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "FECHAVTO" }],
    65: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "FECHAVTO" }],
    66: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "FECHAVTO" }],
    67: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "FECHAVTO" }],
    68: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "VALIDEZ" }],
    69: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "VALIDEZ" }],
    70: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "VALIDEZ" }],
    71: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "VALIDEZ" }],
    72: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "VALIDEZ" }],
    73: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "FECHAVTO" }],
    74: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "VALIDEZ" }],
    75: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "FECHAVTO" }],
    76: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "VALIDEZ" }],
    77: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "VALIDEZ" }],
    78: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "VALIDEZ" }],
    79: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "NRODECARTADECREDITO" }],
    81: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "FECHAVTO" }],
    84: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "FECHAVTO" }],
    85: [{ id: "SALDO", type: "amount" }, { id: "NROOPERACION" }, { id: "FECHAVTO" }],
    86: [{ id: "SALDO", type: "amount" }, { id: "NROOPERACION" }, { id: "FECHAVTO" }],
    87: [{ id: "SALDO", type: "amount" }, { id: "IMPORTEPREFI" }, { id: "NROOPERACION" }, { id: "FECHAVTO" }],
    88: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }],
    89: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "FECHAVTO" }],
    90: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "FECHAVTO" }],
    91: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "FECHAVTO" }],
    92: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }],
    93: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "FECHAVTO" }],
    94: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "FECHAVTO" }],
    95: [{ id: "IMPORTE", type: "amount" }, { id: "NROOPERACION" }, { id: "VALIDEZFECHAVTO" }],
};

export const productsWithPaginatedOption = [
    120, // cheques en garantia
];

export const groupByCurrencyProductType = [
    13, // deposito plazo fijo
    15, // Tarjetas de crédito
    16, // Cheques enviados al clearing
    21, // Bonos corporativos
    22, // Bonos soberanos
    24, // Acciones
    25, // Fondos
    30, // prestamos
    31, // Préstamos a plazo fijo
    32, // prestamos amortizables
    34, // descuento de cheques
    38, // factoring - ordenes de pago descontadas
    39, // Préstamo garantizado con prenda de depósito
    43, // factoring - ordenes de pago emitidas
    50, // Debit card
    63, // Cartas de crédito de importación vencidas
    69, // Cartas de crédito domésticas HSBC a negociar
    70, // Garantías / Avales - recibidos
    71, // Cartas de crédito importación a negociar
    73, // Garantías / Avales - emitidos
    74, // Cartas de crédito de exportación a negociar
    75, // Cartas de crédito de exportación con discrepancias
    81, // Cobranzas de exportación
    84, // Cobranzas de importación
    88, // Cartas de crédito de importación con discrepancias vista
    89, // Cartas de crédito de importación a negociar plazo
    104, // Prendas títulos/valores
    108, // Fianzas solidarias
    109, // Hipotecas
    110, // Stand by
    117, // Otras garantias
    120, // cheques en garantia
];

export const loansProductType = [13, 28, 29, 30, 31, 32];
