import React from "react";

import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { EMPTY_STR } from "~/constants";
import { STATUS } from "~/constants/transaction";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import {
    SelectorsStore as SelectorsStoreComexImport,
    SelectorsAction as SelectorsActionComexImport,
} from "~/store/comex/import";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import DeviceUtils from "~/util/device";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import DataDate from "~/components/DataDate";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Token from "~/components/Token/Token";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import TicketPrintInformation from "~/pages/_components/TicketPrintInformation";

import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        isSubmitting: PropTypes.bool,
    },
    defaults: {
        isSubmitting: false,
    },
};

const printForm = () => {
    const elements = window.document.getElementsByClassName("no_print");

    for (let i = 0; i < elements.length; i += 1) {
        elements[i].style.display = "none";
    }

    window.print();

    for (let i = 0; i < elements.length; i += 1) {
        elements[i].style.display = EMPTY_STR;
    }

    return true;
};

export function Component(props) {
    const { isSubmitting, dispatch, formData, transaction, location } = props;

    const shareTicket = () => {
        dispatch(
            SelectorsActionTransactions.shareTicketRequest({
                transactionData: formData,
                idActivity: "comex.import.cancellationCreditLetter.send",
            }),
        );
    };

    const isFromTransactionsHistory = location.pathname.includes("/ticket");
    const data =
        isFromTransactionsHistory && transaction?.idTransactionStatus !== STATUS.DRAFT ? transaction.data : formData;

    const {
        valueDate,
        operationNumber,
        subOperationNumber,
        invoiceAmount,
        oldAmount,
        beneficiary,
        invoiceAmountCurrency,
        accountNumber,
        authorizedDocumentName,
        formattedAmount,
        authorizedDocumentDocumentType,
        authorizedDocumentDocumentNumber,
    } = data;

    return (
        <Form id={Style.ID}>
            <TicketPrintInformation />
            <Box flex directionColumn align="flex-start" className="mb-3">
                <DataDate value={valueDate} />
                <div className="opening">
                    <I18n id="client.import.cancellationCreditLetter.step2.opening" tag="p" className="mt-3" />
                </div>
                <div className="info-label">
                    <h4 className="bold">
                        {UtilsI18n.get("client.import.cancellationCreditLetter.step2.operationNumber")}
                    </h4>
                    <span>
                        {operationNumber}
                        {subOperationNumber && `.${subOperationNumber}`}
                    </span>
                </div>
                <div className="info-label">
                    <h4 className="bold">{UtilsI18n.get("client.import.cancellationCreditLetter.step2.amount")}</h4>
                    <FormattedAmount currency={invoiceAmountCurrency} quantity={parseFloat(invoiceAmount)} />
                </div>
                <div className="info-label">
                    <h4 className="bold">{UtilsI18n.get("client.import.cancellationCreditLetter.step2.recipient")}</h4>
                    <span>{beneficiary}</span>
                </div>

                <div className="opening">
                    <I18n
                        id={`client.import.cancellationCreditLetter.step2.content.${
                            Math.abs(oldAmount) > invoiceAmount ? "partial" : "total"
                        }`}
                        accountNumber={accountNumber}
                        currency={UtilsI18n.get(`core.currency.label.${invoiceAmountCurrency}`)}
                        quantity={formattedAmount}
                        tag="p"
                        className="mt-3"
                    />
                </div>
                {authorizedDocumentDocumentNumber && (
                    <I18n
                        id="client.import.cancellationCreditLetter.step2.terms"
                        userName={authorizedDocumentName}
                        documentNumber={authorizedDocumentDocumentNumber}
                        documentType={UtilsI18n.get(`documentType.label.${authorizedDocumentDocumentType}`)}
                        tag="p"
                        className="mt-3 mb-3"
                    />
                )}
            </Box>

            <div className="no_print">
                <Token labelButton="global.send" isSubmitting={isSubmitting} />
            </div>
            {!DeviceUtils.isMobileNative() && (
                <div className="buttonsTicketConfirmation">
                    <Button
                        block={false}
                        bsStyle="link"
                        label="comex.preview.print.label"
                        onClick={() => printForm()}
                    />
                </div>
            )}
            <div className="buttonsTicketConfirmation">
                <Button bsStyle="link" block={false} label="forms.ticket.printPDF" onClick={() => shareTicket()} />
            </div>
        </Form>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        formData: SelectorsStoreComexImport.getCancellationCreditLetterFormData(store),
        transaction: SelectorsStoreComexImport.getTransaction(store),
        user: SelectorsStoreSession.getUser(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithRouter,
    WithFormik({
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        validationSchema: (props) => {
            const { user } = props;

            return Yup.object().shape({
                otp: user.needsBiometric
                    ? Yup.string().nullable()
                    : Yup.string().required(UtilsI18n.get("form.credential.otp.required")),
            });
        },
        handleSubmit: ({ otp }, formikBag) => {
            const {
                dispatch,
                history,
                formData,
                transaction: { idTransaction, idTransactionStatus, idActivity },
            } = formikBag.props;

            const isFromTransactionsHistory = history.location.pathname.includes("/ticket");

            if (isFromTransactionsHistory && idTransactionStatus !== STATUS.DRAFT) {
                dispatch(
                    SelectorsActionComex.signTransaction({
                        idForm: null,
                        idActivity,
                        idTransaction,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            } else {
                dispatch(
                    SelectorsActionComexImport.cancellationCreditLetterSendRequest({
                        formData,
                        credentials: { otp },
                        formikBag,
                        idTransaction,
                    }),
                );
            }
        },
    }),
)(Component);
