import React from "react";

import { connect as Connect } from "react-redux";

import Style from "~/containers/Internal/Info/_Filter.rules.scss";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { Categorize } from "~/util/prop";

import { Section } from "~/components/Component";

export const { CLASS, NAME, TYPE } = Style;
export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const { children } = Categorize(props);

    return (
        <Section data-name="filter-wrapper" name={NAME} type={TYPE}>
            {children}
        </Section>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    getMessage: (key) => StoreI18n.getMessage(store, key),
});

export default Connect(mapStateToProps)(Component);
