import React from "react";

import { goBack } from "connected-react-router";
import { Form, withFormik as WithFormik, Field } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { bindActionCreators } from "redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import {
    SelectorsStore as SelectorsStoreWMSwitch,
    SelectorsAction as SelectorsActionWMSwitch,
} from "~/store/wm/funds/switch";
import * as UtilsConfig from "~/util/config";
import { addDay as AddDay } from "~/util/date";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import DateField from "~/pages/_components/fields/DateField";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";

import Checkbox from "~/pages/forms/_components/_fields/_commons/Checkbox";
import SelectorType from "~/pages/settings/changePersonalInformation/field/SelectorType";
import Disclaimer from "~/pages/wm/funds/_component/Disclaimer";
import PaperDetails from "~/pages/wm/funds/_component/PaperDetails";

import Style from "./Step1Edit.rules.scss";

export const NAME = "Form";

export const FORM_ID = "wm.funds.switch";

export const PROP = {
    types: {
        isFetching: PropTypes.bool,
    },
    defaults: {
        isFetching: false,
        paymentOrdersList: [],
        totalizers: [],
    },
};

export function Component(props) {
    const {
        ActionWMSwitch,
        GoBack,
        activeEnvironment,
        debitAccounts,
        errors,
        idPaperToBuy,
        idPaperToSwitch,
        isChangingEnvironment,
        isFetching,
        papersToBuy,
        papersToSwitch,
        selectedPaperToBuy,
        selectedPaperToSwitch,
        setErrors,
        setFieldValue,
        timezoneOffset,
        touched,
        values,
    } = props;

    React.useEffect(() => {
        if (!isChangingEnvironment) {
            setFieldValue("paperToSwitch", idPaperToSwitch);
            setFieldValue("paperToBuy", idPaperToBuy);
        }
    }, [idPaperToSwitch, idPaperToBuy, setFieldValue, isChangingEnvironment]);

    const handleCancel = React.useCallback(() => {
        GoBack();
    }, [GoBack]);

    const handleAccountChange = React.useCallback(
        (value) => {
            if (value) {
                setFieldValue("amount", value);
                setErrors({ ...errors, amount: EMPTY_STR });
            }
        },
        [errors, setErrors, setFieldValue],
    );

    const handleCheckChange = (fieldName) => {
        setFieldValue(fieldName, !values[fieldName]);
    };

    const maxDaysToShow = UtilsConfig.getInteger("client.wm.allInvestments.orderValid.maxDaysToShow");
    const minDate = new Date();
    const maxDate = AddDay(minDate, maxDaysToShow);

    return (
        <Form id={Style.ID}>
            <div className="fields">
                <div className="form-section-title">
                    <div className="form-section-title-text">
                        <I18n id={`${FORM_ID}.paperToSwitch`} />
                    </div>
                    <div className="form-section">
                        <SelectorType
                            errors={errors}
                            idForm={FORM_ID}
                            label={UtilsI18n.get(`${FORM_ID}.selector.name`)}
                            labelKey="name"
                            name="paperToSwitch"
                            onChange={(val) => {
                                if (val) {
                                    ActionWMSwitch.preFormRequest({ idPaper: val, isReloadPaper: true });
                                }
                            }}
                            options={papersToSwitch ?? []}
                            valueKey="idProduct"
                            {...props}
                        />
                        {papersToBuy.length > 0 && (
                            <div className="container-paper-details">
                                <PaperDetails
                                    activeEnvironment={activeEnvironment}
                                    debitAccounts={debitAccounts}
                                    extraInfo
                                    hideName
                                    hideWarning
                                    hidelabel
                                    paper={selectedPaperToSwitch}
                                />
                            </div>
                        )}
                        {papersToBuy.length <= 0 && (
                            <FieldError error={UtilsI18n.get("wm.funds.switch.no.available.products")} />
                        )}
                    </div>
                </div>
                <div className="form-section-title">
                    <div className="form-section-title-text">
                        <I18n id={`${FORM_ID}.paperToBuy`} />
                    </div>
                    <div className="form-section">
                        {/* Paper to buy */}
                        <SelectorType
                            idForm={FORM_ID}
                            label={UtilsI18n.get(`${FORM_ID}.selector.name`)}
                            labelKey="name"
                            name="paperToBuy"
                            onChange={(val) => {
                                if (val) {
                                    ActionWMSwitch.readPaperToBuyRequest({ idPaper: val });
                                }
                            }}
                            options={papersToBuy ?? []}
                            valueKey="idProduct"
                            {...props}
                        />

                        {Object.keys(selectedPaperToBuy).length > 0 && (
                            <div className="container-paper-details">
                                <PaperDetails
                                    activeEnvironment={activeEnvironment}
                                    debitAccounts={debitAccounts}
                                    hideName
                                    paper={selectedPaperToBuy}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="form-section-title">
                    <div className="form-section-title-text">
                        <I18n id={`${FORM_ID}.comission`} />
                    </div>
                    <div className="form-section">
                        {/* Order valid until */}
                        <div className="form-group">
                            <FieldLabel
                                labelKey="wm.allInvestments.orderValidUntil.label"
                                classContainer={`${
                                    errors.orderCompleted && touched.orderCompleted ? "has-error" : EMPTY_STR
                                }`}
                            />

                            <div className="checkbox-mark">
                                <Field
                                    name="orderCompleted"
                                    label={UtilsI18n.get(`${FORM_ID}.orderCompleted.label`)}
                                    checked={values.orderCompleted}
                                    component={Checkbox}
                                    onChange={() => handleCheckChange("orderCompleted")}
                                />
                            </div>

                            {errors.orderCompleted && touched.orderCompleted && (
                                <FieldError error={errors.orderCompleted} />
                            )}
                        </div>

                        {!values.orderCompleted && (
                            <React.Fragment>
                                <div className="datepicker">
                                    <Field
                                        autoComplete="off"
                                        component={DateField}
                                        id="limitDate"
                                        idForm={FORM_ID}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        name="limitDate"
                                        timezoneOffset={timezoneOffset}
                                    />
                                    <div id="disclaimer-text">
                                        {UtilsI18n.get("wm.allInvestments.afterValidDate.disclaimer")}
                                    </div>
                                </div>
                            </React.Fragment>
                        )}

                        {/* Commission account */}
                        <SelectorType
                            handleChange={handleAccountChange}
                            idForm={FORM_ID}
                            label={UtilsI18n.get(`${FORM_ID}.selector.account`)}
                            labelKey="productAlias"
                            name="idCommissionAccount"
                            options={debitAccounts ?? []}
                            valueKey="idProduct"
                            {...props}
                        />

                        {/* Disclaimer */}
                        <Disclaimer
                            idForm={FORM_ID}
                            label={`${FORM_ID}.disclaimer.title`}
                            labelDisclaimer={`${FORM_ID}.disclaimer`}
                            name="disclaimer"
                            {...props}
                        />
                    </div>
                </div>
            </div>
            <footer>
                {/* Button */}
                {!isFetching && (
                    <React.Fragment>
                        <Button size="sm" variant="secondary" onClick={handleCancel}>
                            {UtilsI18n.get("global.cancel")}
                        </Button>
                        <Button type="submit" variant="primary">
                            {UtilsI18n.get("global.send")}
                        </Button>
                    </React.Fragment>
                )}
            </footer>
        </Form>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
    debitAccounts: SelectorsStoreWMSwitch.getDebitAccounts(store),
    disclaimer: SelectorsStoreWMSwitch.getDisclaimer(store),
    errors: SelectorsStoreWMSwitch.getErrors(store),
    idDebitAccount: SelectorsStoreWMSwitch.getIdDebitAccount(store),
    idPaperToBuy: SelectorsStoreWMSwitch.getIdPaperSelectedToBuy(store),
    idPaperToSwitch: SelectorsStoreWMSwitch.getIdPaperSelectedToSwitch(store),
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    isFetching: false,
    papersToBuy: SelectorsStoreWMSwitch.getPapersToBuyList(store),
    papersToSwitch: SelectorsStoreWMSwitch.getPapersToSwitchList(store),
    selectedPaperToBuy: SelectorsStoreWMSwitch.getSelectedPaperToBuy(store),
    selectedPaperToSwitch: SelectorsStoreWMSwitch.getSelectedPaperToSwitch(store),
    timezoneOffset: SelectorsStoreWMSwitch.getTimezoneOffset(store),
});

const mapDispatchToProps = (dispatch) => ({
    ActionWMSwitch: bindActionCreators(SelectorsActionWMSwitch, dispatch),
    GoBack: bindActionCreators(goBack, dispatch),
});

export default HighOrder(
    Connect(mapStateToProps, mapDispatchToProps),
    WithFormik({
        enableReinitialize: false,
        validateOnBlur: false,
        validateOnChange: false,
        mapPropsToValues: (props) => ({
            disclaimer: props.disclaimer,
            idCommissionAccount: props.idDebitAccount,
            limitDate: props.limitDate || EMPTY_STR,
            orderCompleted: props.orderCompleted !== false,
            paperToSwitch: props.idPaperToSwitch || 0,
        }),
        handleSubmit: (values, formikBag) => {
            const { disclaimer, idCommissionAccount, limitDate, orderCompleted, paperToBuy, paperToSwitch } = values;
            const { props } = formikBag;
            const request = {
                disclaimer: disclaimer ?? false,
                idDebitAccount: idCommissionAccount,
                idPaperToBuy: paperToBuy ?? 0,
                idPaperToSwitch: paperToSwitch,
                limitDate: orderCompleted ? EMPTY_STR : limitDate,
                orderCompleted,
                paperToBuyName: props.selectedPaperToBuy.othersFields?.DESCRIPCION ?? EMPTY_STR,
                paperToSwitchName: props.selectedPaperToSwitch.othersFields?.DESCRIPCION ?? EMPTY_STR,
                valueDate: new Date(),
            };

            const { ActionWMSwitch } = formikBag.props;

            ActionWMSwitch.previewPaperRequest({ ...request, formikBag });
        },
    }),
)(Component);
