import { replace as Replace } from "connected-react-router";
import {
    call as Call,
    delay as Delay,
    put as Put,
    select as Select,
    take as Take,
    takeLatest as TakeLatest,
} from "redux-saga/effects";

import { EMPTY_STR, LEVEL, RESPONSE_TYPE, SIGN_WITH_BIOMETRIC, SCOPE } from "~/constants";
import { STATUS } from "~/constants/transaction";
import {
    SelectorsAction as SelectorsActionBiometric,
    SelectorsStore as SelectorsStoreBiometric,
    TYPE as TYPE_BIOMETRIC,
    TYPE_VERIFY_BIOMETRIC,
} from "~/store/biometric";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import {
    SelectorsMiddleware as SelectorsMiddlewareForm,
    SelectorsStore as SelectorsStoreForm,
    TYPE as TYPE_FORM,
} from "~/store/form";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionSession } from "~/store/session";
import * as UtilsConfig from "~/util/config";
import * as UtilsDate from "~/util/date";
import Device, { DEVICE_MOBILE } from "~/util/device";
import { downloadPdf as DownloadPdf } from "~/util/download";
import * as UtilsI18n from "~/util/i18n";

import { ACTIVITY } from "~/pages/payments/qrModo/List";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsMiddleware, SelectorsStore } from "./_selectors";

export default [
    TakeLatest(TYPE.APPROVE_TRANSACTIONS_PREVIEW_REQUEST, approveTransactionsPreviewRequest),
    TakeLatest(TYPE.APPROVE_TRANSACTIONS_REQUEST, approveTransactionsRequest),
    TakeLatest(TYPE.CANCEL_TRANSACTIONS_PREVIEW_REQUEST, cancelTransactionsPreviewRequest),
    TakeLatest(TYPE.CANCEL_TRANSACTIONS_REQUEST, cancelTransactionsRequest),
    TakeLatest(TYPE.CHECK_IF_THERE_ARE_QRMODO_RUNNING_TRANSACTIONS, checkIfThereAreQRModoRunningTransactions),
    TakeLatest(TYPE.DELETE_DRAFT_REQUEST, deleteDraftRequest),
    TakeLatest(TYPE.GPI_TRACKING, gpiTracking),
    TakeLatest(TYPE.LIST_ID_ACTIVITIES_EXECUTED_REQUEST, listIdActivitiesExecuted),
    TakeLatest(TYPE.LOAD_LIST_REQUEST, loadListRequest),
    TakeLatest(TYPE.LOAD_MORE_TRANSACTIONS_REQUEST, fetchMoreTransactions),
    TakeLatest(TYPE.SHARE_TICKET_REQUEST, shareTicketRequest),
    TakeLatest(TYPE_FORM.TRANSACTION_REQUEST_DELAY, transactionTimer),
];

function* approveTransactionsPreviewRequest(props) {
    const { data: credentialData, type: credentialRespType } = yield Call(
        SelectorsMiddlewareForm.listCredentialsGroups,
        {
            idActivityToRead: "transactions.signBatch.send",
            idForm: EMPTY_STR,
        },
    );

    if (credentialRespType === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.approveTransactionsPreviewFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.TRANSACTIONS],
            }),
        );
    } else {
        const {
            data: { message: respMessage },
            type,
        } = yield Call(SelectorsMiddleware.approveTransactionsPreviewRequest, props);

        if (type === RESPONSE_TYPE.WARNING) {
            yield Put(SelectorsAction.approveTransactionsPreviewFailure());

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: respMessage || UtilsI18n.get("global.unexpectedError"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.TRANSACTIONS],
                }),
            );
        } else {
            const { groups } = credentialData.data;
            const { idTransactionList } = props;
            const totalTransactions = idTransactionList.length;

            yield Put(
                SelectorsAction.approveTransactionsPreviewSuccess({
                    credentialsGroups: groups,
                    idTransactionList,
                    totalTransactions,
                }),
            );
        }
    }
}

function* approveTransactionsRequest(param) {
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield Put(SelectorsActionBiometric.verifyBiometric({ liveness: true, pathFrom: SIGN_WITH_BIOMETRIC }));

        yield Take([
            TYPE_BIOMETRIC.VERIFY_BIOMETRIC_ERROR,
            TYPE_BIOMETRIC.VERIFY_BIOMETRIC_SUCCESS,
            TYPE_BIOMETRIC.VERIFY_BIOMETRIC_CANCEL,
            TYPE_BIOMETRIC.NO_ENROLLED_FINGERPRINT,
        ]);

        const statusVerifyBiometric = yield Select(SelectorsStoreBiometric.statusVerifyBiometric);

        switch (statusVerifyBiometric) {
            case TYPE_VERIFY_BIOMETRIC.CANCEL:
            case TYPE_VERIFY_BIOMETRIC.NO_ENROLLED_FINGERPRINT:
                yield Put(SelectorsAction.clearPreview());

                yield Put(SelectorsAction.setApproving({ isApproving: null }));

                return undefined;
            case TYPE_VERIFY_BIOMETRIC.ERROR:
                yield Put(SelectorsAction.clearPreview());

                yield Put(SelectorsAction.setApproving({ isApproving: null }));

                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: UtilsI18n.get("verify.biometric.error"),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.TRANSACTIONS],
                    }),
                );

                return undefined;
            default:
                break;
        }
    }

    const { formikBag, idTransactionList, otp } = param;

    const response = yield Call(SelectorsMiddleware.approveTransactionsRequest, {
        _otp: otp,
        idTransactionList,
    });

    if (response.type === RESPONSE_TYPE.WARNING) {
        const { data } = response;
        const hasIncorrectCredentials = Object.keys(data.data).some((key) => key === "otp");

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
        }

        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.TRANSACTIONS],
            }),
        );

        formikBag.setSubmitting(false);

        yield Put(SelectorsAction.approveTransactionsFailure({ errors: data.data }));
    } else {
        const { summaryDetail } = response.data.data;

        yield Put(SelectorsAction.approveTransactionsSuccess({ summaryDetail }));

        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("transaction.sign.success"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.TRANSACTIONS],
            }),
        );

        yield Put(SelectorsAction.resumeTransactions({ summaryDetail }));

        yield Put(Replace(`/resumeTransaction`));
    }

    return undefined;
}

function* cancelTransactionsPreviewRequest(props) {
    const { data: credentialData, type: credentialRespType } = yield Call(
        SelectorsMiddlewareForm.listCredentialsGroups,
        {
            idActivityToRead: "transactions.signBatch.send",
            idForm: EMPTY_STR,
        },
    );

    if (credentialRespType === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.cancelTransactionsPreviewFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.TRANSACTIONS],
            }),
        );
    } else {
        const response = yield Call(SelectorsMiddleware.cancelTransactionsPreviewRequest, props);

        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(SelectorsAction.cancelTransactionsPreviewFailure());

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("global.unexpectedError"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.TRANSACTIONS],
                }),
            );
        } else {
            const { groups: credentialsGroups } = credentialData.data;
            const { idTransactionList } = props;
            const totalTransactions = idTransactionList.length;

            yield Put(
                SelectorsAction.cancelTransactionsPreviewSuccess({
                    credentialsGroups,
                    idTransactionList,
                    totalTransactions,
                }),
            );
        }
    }
}

function* cancelTransactionsRequest(param) {
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield Put(SelectorsActionBiometric.verifyBiometric({ liveness: true, pathFrom: SIGN_WITH_BIOMETRIC }));

        yield Take([
            TYPE_BIOMETRIC.VERIFY_BIOMETRIC_ERROR,
            TYPE_BIOMETRIC.VERIFY_BIOMETRIC_SUCCESS,
            TYPE_BIOMETRIC.VERIFY_BIOMETRIC_CANCEL,
            TYPE_BIOMETRIC.NO_ENROLLED_FINGERPRINT,
        ]);

        const statusVerifyBiometric = yield Select(SelectorsStoreBiometric.statusVerifyBiometric);

        switch (statusVerifyBiometric) {
            case TYPE_VERIFY_BIOMETRIC.CANCEL:
            case TYPE_VERIFY_BIOMETRIC.NO_ENROLLED_FINGERPRINT:
                yield Put(SelectorsAction.cancelTransactionsFailure());

                return undefined;
            case TYPE_VERIFY_BIOMETRIC.ERROR:
                yield Put(SelectorsAction.cancelTransactionsFailure());

                yield Put(
                    yield Put(
                        SelectorsActionNotification.showNotification({
                            message: UtilsI18n.get("verify.biometric.error"),
                            level: LEVEL.ERROR,
                            scopes: [SCOPE.TRANSACTIONS],
                        }),
                    ),
                );

                return undefined;
            default:
                break;
        }
    }

    const { formikBag, idTransactionList, otp } = param;

    const response = yield Call(SelectorsMiddleware.cancelTransactionsRequest, {
        _otp: otp,
        idTransactionList,
    });

    if (response.type === RESPONSE_TYPE.WARNING) {
        const { data } = response;
        const hasIncorrectCredentials = Object.keys(data.data).some((key) => key === "otp");

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
        }

        formikBag.setSubmitting(false);

        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.TRANSACTIONS],
            }),
        );

        yield Put(SelectorsAction.cancelTransactionsFailure({ errors: data.data }));
    } else {
        const { summaryDetail } = response.data.data;
        yield Put(SelectorsAction.cancelTransactionsSuccess({ summaryDetail }));

        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("transaction.cancel.success"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.TRANSACTIONS],
            }),
        );

        yield Put(SelectorsAction.resumeTransactions({ summaryDetail }));

        yield Put(Replace(`/resumeTransaction`));
    }

    return undefined;
}

function* checkIfThereAreQRModoRunningTransactions(props) {
    const { defaultFilter } = props;

    const isInQRMODOPage = yield Select(SelectorsStore.isInQRMODOPage);

    if (isInQRMODOPage) {
        while (true) {
            // Double check just in case the while has already been executed
            const isInQRMODOPageWhileCheck = yield Select(SelectorsStore.isInQRMODOPage);

            if (!isInQRMODOPageWhileCheck) {
                break;
            }

            const transactions = yield Select(SelectorsStore.getTransactions);

            if (transactions && transactions.length > 0) {
                const refreshInterval = UtilsConfig.getTimeInMillis(
                    "client.qrModo.paymentList.runningTransactions.refreshInterval",
                );

                const qrModoRunningTransactionList = transactions.filter(
                    (element) => element.transaction.idTransactionStatus === STATUS.RUNNING,
                );

                if (
                    isInQRMODOPageWhileCheck &&
                    qrModoRunningTransactionList &&
                    qrModoRunningTransactionList.length > 0
                ) {
                    yield Delay(refreshInterval);

                    yield Put(SelectorsAction.loadListRequest(defaultFilter));
                } else {
                    break;
                }
            }
        }
    }
}

function* deleteDraftRequest(props) {
    const response = yield Call(SelectorsMiddleware.deleteDraftRequest, props);

    const { idTransactionToDelete: idTransaction } = props;

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.deleteDraftFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.TRANSACTIONS],
            }),
        );
    } else {
        const { deleted } = response.data.data;

        if (deleted) {
            yield Put(SelectorsAction.deleteDraftSuccess(idTransaction));

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("transactions.list.draft.deleted"),
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.TRANSACTIONS],
                }),
            );
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("transactions.list.draft.deleted.fail"),
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.TRANSACTIONS],
                }),
            );
        }
    }
}

function* fetchMoreTransactions(props) {
    const { pageNumber: pageNumberTransactions } = props;

    const response = yield Call(SelectorsMiddleware.loadListRequest, {
        ...props,
        pageNumber: pageNumberTransactions + 1,
    });

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.loadListFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.TRANSACTIONS],
            }),
        );
    } else {
        const { pageNumber, transactions, totalPages } = response.data.data;

        yield Put(SelectorsAction.loadMoreTransactionsSuccess({ pageNumber, transactions, totalPages }));
    }
}

function* gpiTracking(props) {
    const {
        type,
        data: { data, message },
    } = yield Call(SelectorsMiddleware.gpiTracking, props);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.gpiTrackingFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.SWIFT, SCOPE.TRANSACTIONS],
            }),
        );
    } else {
        const { content, fileName } = data;

        if (Device.isMobileNative()) {
            try {
                yield Put(SelectorsAction.gpiTrackingSuccess());

                yield Call(window.pdf.save, data);
            } catch (error) {
                yield Put(SelectorsAction.gpiTrackingFailure());

                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: UtilsI18n.get("returnCode.API593W"),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.SWIFT, SCOPE.TRANSACTIONS],
                    }),
                );
            }
        } else {
            DownloadPdf(fileName, content);

            yield Put(SelectorsAction.gpiTrackingSuccess());
        }
    }
}

function* listIdActivitiesExecuted() {
    const response = yield Call(SelectorsMiddleware.listIdActivitiesExecuted);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.listIdActivitiesExecutedFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.TRANSACTIONS],
            }),
        );
    } else {
        const { idActivitiesList } = response.data.data;

        yield Put(SelectorsAction.listIdActivitiesExecutedSuccess({ idActivitiesList }));
    }
}

function* loadListRequest(props) {
    const { dateFrom, dateTo, transaction: idActivity } = props;
    const dateFromAsDate = UtilsDate.toDate(dateFrom);
    const dateToAsDate = UtilsDate.toDate(dateTo);

    if (
        // 370 instead of 366 just in case there is a cached date
        UtilsDate.differenceInCalendarDays(dateToAsDate, dateFromAsDate) >
        UtilsConfig.getInteger("client.transactions.filters.dateFrom.yearsBack.default", 1) * 370
    ) {
        yield Put(SelectorsAction.loadListFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("transactions.filters.dateDifference.error"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.TRANSACTIONS],
            }),
        );
    } else {
        const response = yield Call(SelectorsMiddleware.loadListRequest, props);

        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(SelectorsAction.loadListFailure());

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("global.unexpectedError"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.TRANSACTIONS],
                }),
            );
        } else {
            const { data } = response.data;
            const { changeFilterToAll, delayedTransactions, pageNumber, totalPages, transactions } = data;

            if (ACTIVITY === idActivity) {
                const qrName = {
                    en: data["qrName-en"],
                    es: data["qrName-es"],
                    pt: data["qrName-pt"],
                };

                const qrData = {
                    qrCurrentInstallment: data.qrCurrentInstallment,
                    qrDueDate: data.qrDueDate,
                    qrEnabled: data.qrEnabled,
                    qrLimitAmount: data.qrLimitAmount,
                    qrLimitCurrency: data.qrLimitCurrency,
                    qrMaxLimitAmount: data.qrMaxLimitAmount,
                    qrMaxLimitCurrency: data.qrMaxLimitCurrency,
                    qrName,
                    qrTypeDiscount: data.qrTypeDiscount,
                };

                yield Put(SelectorsActionSession.saveQrData({ qrData }));
            }

            yield Put(
                SelectorsAction.loadListSuccess({
                    changeFilterToAll,
                    delayedTransactions,
                    pageNumber,
                    totalPages,
                    transactions,
                }),
            );
        }
    }
}

function* shareTicketRequest(props) {
    const response = yield Call(SelectorsMiddleware.shareTicketRequest, props);

    const { formikBag } = props;

    if (response.type === RESPONSE_TYPE.WARNING) {
        const { data } = response;
        const hasIncorrectCredentials = Object.keys(data.data).some((key) => key === "otp");

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.message,
                    level: "error",
                    scopes: [SCOPE.TRANSACTIONS],
                }),
            );
        }

        yield Put(SelectorsAction.shareTicketFailure({ errors: data.data }));
    } else {
        const { file } = response.data.data;
        const { fileTitle } = response.data.data;

        if (Device.isMobileNative()) {
            let pdfAsDataUri = `data:application/pdf;base64,${file}`;

            if (Device.mobileOS() === DEVICE_MOBILE.IOS) {
                pdfAsDataUri = `data:application/pdf:${fileTitle}.pdf;base64,${file}`;
            }

            const options = {
                files: [pdfAsDataUri],
                subject: fileTitle,
            };

            window.plugins.socialsharing.shareWithOptions(options, null, null);
        } else {
            DownloadPdf(fileTitle, file);
        }

        yield Put(SelectorsAction.shareTicketSuccess({ file }));
    }
}

function* transactionTimer() {
    const TIMEOUT = UtilsConfig.get("client.transactions.delayed.pageTimeout.warning") * 1000;

    yield Delay(TIMEOUT);

    const idTransaction = yield Select(SelectorsStoreForm.getId);
    const waitingForReponse = yield Select(SelectorsStoreForm.getWaitingForResponse);

    if (waitingForReponse && idTransaction !== null) {
        // Show DelayedModal component
        yield Put(SelectorsAction.delayedStart({ isDelayed: true }));
    }
}
