import { replace as Replace, push as Push } from "connected-react-router";
import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import { LEVEL, SCOPE, RESPONSE_TYPE, INSUFFICIENT_SIGNATURES, GLOBAL_UNEXPECTED_ERROR_KEY } from "~/constants";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as UtilI18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsMiddleware } from "./_selectors";

const sagas = [
    TakeLatest(TYPE.ADMINISTRATION_USER_INVITE_PRE_REQUEST, userInvitePre),
    TakeLatest(TYPE.ADMINISTRATION_USER_INVITE_SEND, userInviteConfirm),
    TakeLatest(TYPE.ADMINISTRATION_USER_INVITE_VERIFY, userInviteVerify),
];

export default sagas;

function* userInvitePre() {
    const response = yield Call(SelectorsMiddleware.userInvitePreRequest);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.userInvitePreFailure());
        yield Put(Replace("/administration/users"));
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADMINISTRATION_USERS_INVITE],
            }),
        );
    } else {
        yield Put(SelectorsAction.userInvitePreSuccess({ data: response.data.data }));
    }
}

function* userInviteVerify(props) {
    const {
        document: { country, type, document },
        captcha,
        formikBag,
    } = props;

    const params = {
        docCountry: country,
        docType: type,
        docNumber: document,
    };
    const response = yield Call(SelectorsMiddleware.userInviteVerify, params, captcha);

    if (response.type === RESPONSE_TYPE.WARNING) {
        if (response.data.data.NO_FIELD) {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.data.NO_FIELD || UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION_USERS_INVITE],
                }),
            );
        } else {
            formikBag.setErrors({ document: response.data.data });
        }

        yield Put(SelectorsAction.userInviteVerifyFailure());
    } else {
        yield Put(SelectorsAction.userInviteVerifySuccess({ data: response.data.data }));
        yield Put(Push("/administration/users/inviteStep2"));
    }
}

function* userInviteConfirm(props) {
    const { confirmationParams, formikBag } = props;

    const response = yield Call(SelectorsMiddleware.userInviteConfirm, {
        ...confirmationParams,
    });

    formikBag.setSubmitting(false);
    if (response.type === RESPONSE_TYPE.WARNING) {
        formikBag.setErrors(response.data.data);

        yield Put(SelectorsAction.userInviteConfirmFailure());
        yield Put(Replace("/administration/users/inviteStep2"));
    } else {
        yield Put(SelectorsAction.userInviteFinish());
        yield Put(Replace("/administration/users"));

        if (response.data.code && response.data.code === INSUFFICIENT_SIGNATURES) {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.ADMINISTRATION, SCOPE.ADMINISTRATION_USERS],
                }),
            );
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get("administration.users.invite.success"),
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.ADMINISTRATION, SCOPE.ADMINISTRATION_USERS],
                }),
            );
        }
    }
}
