// import { replace as Replace } from "connected-react-router";
import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import { LEVEL, SCOPE, EMPTY_STR, RESPONSE_TYPE, FORM_RENDER, REACT_APP_ROUTER_BASE } from "~/constants";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import Device, { DEVICE_MOBILE } from "~/util/device";
import { downloadPdf as DownloadPdf } from "~/util/download";
import * as UtilI18n from "~/util/i18n";
import { LOCATION_CHANGE } from "connected-react-router";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsMiddleware } from "./_selectors";

export default [
    TakeLatest(TYPE.LOAD_LIST_REQUEST, loadListRequest),
    TakeLatest(LOCATION_CHANGE, readTransaction),
    TakeLatest(TYPE.LOAD_MORE_TRANSACTIONS_REQUEST, fetchMoreTransactions),
    TakeLatest(TYPE.DELETE_DRAFT_REQUEST, deleteDraftRequest),
    TakeLatest(TYPE.SHARE_TICKET_REQUEST, shareTicketRequest),
];

function* loadListRequest(props) {
    const response = yield Call(SelectorsMiddleware.loadListRequest, props);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.loadListFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.TRANSACTIONS],
            }),
        );
    } else {
        const { transactions, pageNumber, totalPages, delayedTransactions, changeFilterToAll } = response.data.data;
        yield Put(
            SelectorsAction.loadListSuccess({
                transactions,
                pageNumber,
                totalPages,
                delayedTransactions,
                changeFilterToAll,
            }),
        );
    }
}
function* readTransaction(props) {
    const { payload } = props;

    const pathname = payload.location.pathname.replace(REACT_APP_ROUTER_BASE, EMPTY_STR); // remove router base
    const [, route, idTransaction] = pathname.split("/");
    if (route && route.toLowerCase() === FORM_RENDER.TRANSACTION_HISTORY) {
        const response = yield Call(SelectorsMiddleware.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(SelectorsAction.readTransactionFailure());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get("global.unexpectedError"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.TRANSACTIONS],
                }),
            );
        } else {
            const { transaction } = response.data.data;
            yield Put(SelectorsAction.readTransactionSuccess({ transaction }));
        }
    }
}

function* fetchMoreTransactions(props) {
    const { pageNumber: pageNumberTransactions } = props;
    const response = yield Call(SelectorsMiddleware.loadListRequest, {
        ...props,
        pageNumber: pageNumberTransactions + 1,
    });
    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.loadListFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.TRANSACTIONS],
            }),
        );
    } else {
        const { transactions, pageNumber, totalPages } = response.data.data;
        // TODO:UYHSBCCDP-2859 revisar los parametros de accion y reducer
        yield Put(SelectorsAction.loadMoreTransactionsSuccess({ transactions, pageNumber, totalPages }));
    }
}

function* deleteDraftRequest(props) {
    const response = yield Call(SelectorsMiddleware.deleteDraftRequest, props);
    const { idTransactionToDelete: idTransaction } = props;
    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.deleteDraftFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.TRANSACTIONS],
            }),
        );
    } else {
        const { deleted } = response.data.data;

        if (deleted) {
            yield Put(SelectorsAction.deleteDraftSuccess(idTransaction));
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get("transactions.list.draft.deleted"),
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.TRANSACTIONS],
                }),
            );
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get("transactions.list.draft.deleted.fail"),
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.TRANSACTIONS],
                }),
            );
        }
    }
}

function* shareTicketRequest(props) {
    // TODO:UYHSBCCDP-2857 no se envian todos los datos, Revisar que datos hay que enviar
    const response = yield Call(SelectorsMiddleware.shareTicketRequest, props);
    const { formikBag } = props;
    if (response.type === RESPONSE_TYPE.WARNING) {
        const { data } = response;
        const hasIncorrectCredentials = Object.keys(data.data).some((key) => key === "otp");
        if (hasIncorrectCredentials) {
            formikBag.setErrors(data.data);
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.message,
                    level: "error",
                    scopes: [SCOPE.TRANSACTIONS],
                }),
            );
        }
        yield Put(SelectorsAction.shareTicketFailure({ errors: data.data }));
    } else {
        const { file } = response.data.data;
        const { fileTitle } = response.data.data;

        if (Device.isMobileNative()) {
            let pdfAsDataUri = `data:application/pdf;base64,${file}`;

            if (Device.mobileOS() === DEVICE_MOBILE.IOS) {
                pdfAsDataUri = `data:application/pdf:${fileTitle}.pdf;base64,${file}`;
            }

            const options = {
                subject: fileTitle,
                files: [pdfAsDataUri],
            };

            window.plugins.socialsharing.shareWithOptions(options, null, null);
        } else {
            DownloadPdf(fileTitle, file);
        }

        yield Put(SelectorsAction.shareTicketSuccess({ file }));
    }
}
