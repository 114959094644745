import { ZERO_NUMBER } from "~/constants";
import * as API from "~/store/api";
import { TYPE as TYPE_FILES } from "~/store/files";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    downloadLinePDF: (props) => API.executeWithAccessToken(PATH.DOWNLOAD_PDF, props),
    importPayroll: (props) => API.executeWithAccessToken(PATH.IMPORT_PAYROLL, props),
    listLocalBanks: (props) => API.executeWithAccessToken(PATH.LIST_LOCAL_BANKS, props),
    listTransactionLines: (props) => API.executeWithAccessToken(PATH.LIST_TRANSACTION_LINES, props),
    listTransactionLinesStatus: (props) => API.executeWithAccessToken(PATH.LIST_TRANSACTION_LINES_STATUS, props),
};

export const SelectorsAction = {
    addTransactionLine: (props) => FactoryAction(TYPE.ADD_TRANSACTION_LINE, props),
    cancelEdition: (props) => FactoryAction(TYPE.CANCEL_CHANGED_TRANSACTION_LINES, props),
    changeFilters: (props) => FactoryAction(TYPE.CHANGE_FILTERS, props),
    cleanAll: (props) => FactoryAction(TYPE.CLEAN_ALL, props),
    cleanLinesWithNoAmount: (props) => FactoryAction(TYPE.CLEAN_LINES_WITH_NO_AMOUNT, props),
    clearTransactionLines: (props) => FactoryAction(TYPE.CLEAR_TRANSACTION_LINES, props),
    downloadLinePDF: (props) => FactoryAction(TYPE.DOWNLOAD_LINE_PDF, props),
    editTransactionLine: (props) => FactoryAction(TYPE.EDIT_TRANSACTION_LINE, props),
    getFileContentsSuccess: (props) => FactoryAction(TYPE_FILES.GET_FILE_CONTENTS_SUCCESS, props),
    getFileTransactionLinesSuccess: (props) => FactoryAction(TYPE_FILES.GET_FILE_TRANSACTION_LINES_SUCCESS, props),
    importPayrollConfirmationRequest: (props) => FactoryAction(TYPE.IMPORT_PAYROLL_CONFIRMATION_REQUEST, props),
    importPayrollRequest: (props) => FactoryAction(TYPE.IMPORT_PAYROLL_REQUEST, props),
    importPayrollFailure: (props) => FactoryAction(TYPE.IMPORT_PAYROLL_FAILURE, props),
    importPayrollSuccess: (props) => FactoryAction(TYPE.IMPORT_PAYROLL_SUCCESS, props),
    listLocalBanksRequest: (props) => FactoryAction(TYPE.LIST_LOCAL_BANKS_REQUEST, props),
    listLocalBanksFailure: (props) => FactoryAction(TYPE.LIST_LOCAL_BANKS_FAILURE, props),
    listLocalBanksSuccess: (props) => FactoryAction(TYPE.LIST_LOCAL_BANKS_SUCCESS, props),
    listTransactionLinesRequest: (props) => FactoryAction(TYPE.LIST_TRANSACTION_LINES_REQUEST, props),
    listTransactionLinesFailure: (props) => FactoryAction(TYPE.LIST_TRANSACTION_LINES_FAILURE, props),
    listTransactionLinesSuccess: (props) => FactoryAction(TYPE.LIST_TRANSACTION_LINES_SUCCESS, props),
    listTransactionLinesStatusRequest: (props) => FactoryAction(TYPE.LIST_TRANSACTION_LINES_STATUS_REQUEST, props),
    listTransactionLinesStatusFailure: (props) => FactoryAction(TYPE.LIST_TRANSACTION_LINES_STATUS_FAILURE, props),
    listTransactionLinesStatusSuccess: (props) => FactoryAction(TYPE.LIST_TRANSACTION_LINES_STATUS_SUCCESS, props),
    onPaymentMethodChange: (props) => FactoryAction(TYPE.ON_PAYMENT_METHOD_CHANGE, props),
    removeTransactionLine: (props) => FactoryAction(TYPE.REMOVE_TRANSACTION_LINE, props),
    setCurrency: (props) => FactoryAction(TYPE.SET_CURRENCY, props),
    setErrors: (props) => FactoryAction(TYPE.SET_ERRORS, props),
    setIsEditingPayment: (props) => FactoryAction(TYPE.SET_IS_EDITING_PAYMENT, props),
    setLinesFromTransactionData: (props) => FactoryAction(TYPE.SET_LINES_FROM_TRANSACTION_DATA, props),
    setPageNumber: (props) => FactoryAction(TYPE.SET_PAGE_NUMBER, props),
    showImportedPayroll: (props) => FactoryAction(TYPE.SHOW_IMPORTED_PAYROLL, props),
    sortLinesByName: (props) => FactoryAction(TYPE.SORT_LINES_BY_NAME, props),
};

export const SelectorsStore = {
    confirmationRequest: (store) => store[NAME].confirmationRequest,
    getActiveFilters: (store) => store[NAME].activeFilters,
    getCurrency: (store) => store[NAME].currency,
    getErrors: (store) => store[NAME].errors,
    getFetching: (store) => store[NAME].fetching,
    getLocalBanks: (store) => store[NAME].localBanks,
    getLinesBeingEdited: (store) => store[NAME].linesBeingEdited,
    getLinesWithInitialValues: (store) => store[NAME].linesWithInitialValues,
    getLinesWithNoAmount: (store) =>
        store[NAME].list ? store[NAME].list.filter((line) => !line.creditAmountQuantity) : [],
    getLinesWithStatus: (store) => store[NAME].linesWithStatus,
    getList: (store) => store[NAME].list,
    getPageNumber: (store) => store[NAME].pageNumber,
    getPayrollLines: (store) => store[NAME].payrollLines,
    getSelectedItem: (store) => {
        const { list, selectedLine } = store[NAME];
        let selectedItem = null;

        if (list && list.length > 0) {
            selectedItem = list.find((line) => line.lineNumber === selectedLine);
        }

        return selectedItem;
    },
    getSelectedLine: (store) => store[NAME].selectedLine,
    getTotalAmount: (store) =>
        store[NAME].list &&
        store[NAME].list.reduce(
            ({ currency, quantity }, { creditAmountQuantity }) => ({
                currency,
                quantity: quantity + (creditAmountQuantity || ZERO_NUMBER),
            }),
            {
                quantity: 0,
                currency: SelectorsStore.getCurrency(store),
            },
        ),
    getTotalLines: (store) => store[NAME].totalLines,
    getTransactionLines: (store) => store[NAME].list,
    isEditingPayment: (store) => store[NAME].isEditingPayment,
    isLastPage: (store) => store[NAME].lastPage,
    shouldResetLines: (store) => store[NAME].shouldResetLines,
};
