import React from "react";

import { push as Push } from "connected-react-router";
import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";

import { ALIAS_TYPES, EMPTY_STR, HSBC, ID_FORM, ONLY_NUMBER, PIPE_SEPARATOR } from "~/constants";
import Container from "~/containers/Internal/Form/Filter";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsAction as SelectorsActionSession, SelectorsStore as SelectorsStoreSession } from "~/store/session";
import {
    SelectorsAction as SelectorsActionTransfers,
    SelectorsStore as SelectorsStoreTransfers,
} from "~/store/transfers";
import * as UtilsI18n from "~/util/i18n";
import { GetMobileCountryOptions } from "~/util/phone";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import GridLayout from "~/components/GridLayout";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import CountryCellPhoneField from "~/pages/_components/fields/formik/CountryCellPhoneField";

import Style from "./AliasAdministration.rules.scss";
import StyleField from "./enrollment/Step1Edit/MobilePhoneField.rules.scss";

export const { NAME, NAME_MESSAGES } = Style;

const FORM_ID = "aliasAdministration";

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const {
        accountsByAlias,
        accountsByAliasFetching,
        activeEnvironment,
        dispatch,
        environments,
        i18n: { headTitle },
        isFetching,
        localBanks,
        userAlias,
        userAliasHasBeenFetched,
        values,
    } = props;

    const [accountsByAliasAlreadyRequested, setAccountsByAliasAlreadyRequested] = React.useState(false);

    React.useEffect(() => {
        dispatch(SelectorsActionTransfers.aliasEnrollmentPreRequest());
    }, [dispatch]);

    React.useEffect(() => {
        const { aliasData, aliasType } = userAlias;

        if (!accountsByAliasAlreadyRequested && userAliasHasBeenFetched) {
            if (aliasData && aliasData !== EMPTY_STR) {
                let aliasDataToUse = aliasData;
                let aliasTypeToUse = aliasType;

                if (aliasType !== ALIAS_TYPES.EMAIL.key && aliasType !== ALIAS_TYPES.TEXT.key) {
                    aliasTypeToUse = ALIAS_TYPES.PHONE.btValue;

                    // Special treatment for phone aliases
                    aliasDataToUse = aliasType.concat(PIPE_SEPARATOR).concat(aliasData);
                }

                const params = {
                    aliasData: aliasDataToUse,
                    aliasType: aliasTypeToUse,
                    idForm: ID_FORM.TRANSFER_LOCAL,
                    isFromAdministrationSection: true,
                    localBanksAlreadyFetched: localBanks.length > 0,
                };

                dispatch(SelectorsActionTransfers.getAccountsByAliasRequest({ params }));
                dispatch(SelectorsActionSession.resetSuggestAliasEnrollmentShowHeader());
                dispatch(SelectorsActionTransfers.aliasEnrollmentReset());

                setAccountsByAliasAlreadyRequested(true);
            }
        }
    }, [accountsByAliasAlreadyRequested, dispatch, localBanks, userAlias, userAliasHasBeenFetched]);

    const filterAccountsByAliasList = () => [
        ...accountsByAlias
            .filter((account) => HSBC === account.bcuCode)
            .map((account) => ({ ...account, isHSBC: true })),
        ...accountsByAlias
            .filter((account) => HSBC !== account.bcuCode)
            .map((account) => ({ ...account, isHSBC: false })),
    ];

    const handleAliasDeletion = (accountToDelete) => {
        const { aliasData, aliasType } = userAlias;
        const { mobilePhone } = values;

        const formData = {
            accountToDelete,
            aliasData,
            aliasType,
            mobilePhone,
            productGroupId:
                activeEnvironment && environments ? environments[activeEnvironment.id].productGroupId : EMPTY_STR,
        };

        dispatch(SelectorsActionTransfers.aliasDeletionPreviewRequest({ formData }));
    };

    const handleAliasEnrollment = () => {
        dispatch(Push("/transfers/aliasEnrollment"));
        dispatch(SelectorsActionTransfers.passQueriedAccountsByAlias({ accountsByAlias }));
    };

    const handleBack = () => {
        const { history } = props;

        history.goBack();
    };

    const makeOptionList = (accountList) => {
        const selectedAccount =
            activeEnvironment && environments ? environments[activeEnvironment.id].productGroupId : EMPTY_STR;

        return accountList.map((account, i) => ({
            currency: UtilsI18n.get(`currency.label.${account.currency}`),
            id: i,
            isActiveEnvironment: account.accountNumber === selectedAccount,
            isHSBC: account.isHSBC,
            label: `${localBanks.find((localBank) => localBank.value === account.bcuCode).label} ${
                account.accountNumber
            } ${account.isHSBC ? ` ${account.accountName}` : EMPTY_STR}`,
        }));
    };

    const optionList = makeOptionList(filterAccountsByAliasList(accountsByAlias));

    return (
        <React.Fragment>
            <Container
                name={NAME}
                wait={isFetching || accountsByAliasFetching}
                head-onBack={handleBack}
                head-title={headTitle}
                image="paymentOrders.svg">
                <div id={Style.ID}>
                    <Form>
                        <div className="form--change_mobilePhone">
                            <div className="form--change_mobilePhone--title">
                                <I18n id="transfers.aliasAdministration.title" />
                            </div>
                            <div className="container--data">
                                <div className="access-code">
                                    <div className="field-mobilephone field-spacing" id={StyleField.ID}>
                                        <FieldLabel labelKey="transfers.alias.enrollment.cellPhone" />
                                        <Field
                                            autoFocus={false}
                                            className="login-form-input"
                                            component={CountryCellPhoneField}
                                            hideLabel
                                            hidePlaceholder={false}
                                            idForm={FORM_ID}
                                            labelKey="label"
                                            name="mobilePhone"
                                            pattern={ONLY_NUMBER}
                                            readOnly
                                            type="text"
                                            valueKey="countryName"
                                        />
                                    </div>
                                </div>
                                <div className="accounts-card">
                                    <span className="accounts-card-title">
                                        <I18n id="transfers.aliasAdministration.accountList" />
                                    </span>
                                    <div className="option-list">
                                        {optionList.map(({ currency, isActiveEnvironment, isHSBC, label }) => (
                                            <GridLayout>
                                                <Box className="section-alias">
                                                    <span>{label}</span>
                                                    <span className="text-currency">{currency}</span>
                                                </Box>

                                                {isHSBC && isActiveEnvironment && (
                                                    <Box
                                                        className="box-image"
                                                        flex
                                                        justify="center"
                                                        onClick={() => handleAliasDeletion({ label, currency })}>
                                                        <Box className="image-circle">
                                                            <Image src="trash.svg" />
                                                        </Box>
                                                    </Box>
                                                )}
                                            </GridLayout>
                                        ))}
                                    </div>
                                </div>
                                <div className="buttons-container">
                                    <Button variant="primary" type="submit" onClick={handleAliasEnrollment}>
                                        <I18n id="transfers.aliasadministration.enroll.alias" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </Container>
        </React.Fragment>
    );
}

const mapStateToProps = (store) => ({
    accountsByAliasFetching: SelectorsStoreTransfers.accountsByAliasFetching(store),
    accountsByAlias: SelectorsStoreTransfers.getAccountsByAlias(store),
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
    environments: SelectorsStoreSession.getEnvironments(store),
    i18n: {
        headTitle: StoreI18n.getMessage(store, "transfers.aliasAdministration.headTitle"),
    },
    isFetching: SelectorsStoreTransfers.fetching(store),
    localBanks: SelectorsStoreTransfers.getLocalBanks(store),
    userAlias: SelectorsStoreTransfers.getUserAlias(store),
    userAliasHasBeenFetched: SelectorsStoreTransfers.getUserAliasFetched(store),
});

export default Compose(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => {
            const { userAlias } = props;

            const { aliasData, aliasType } = userAlias;

            let countryPhone = [];

            if (aliasType) {
                countryPhone = GetMobileCountryOptions();

                countryPhone = countryPhone.filter((element) => element?.value === `+${aliasType}`);
            }

            return {
                mobilePhone: {
                    country: countryPhone[0]?.country,
                    mobilePhone: aliasData,
                    prefix: `+${aliasType}`,
                },
            };
        },
    }),
)(Component);
