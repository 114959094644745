import { LOCATION_CHANGE } from "connected-react-router";

import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { TYPE as TypesBackoffice } from "~/store/backoffice";

import { TYPE as TYPE_COMEX } from "../_consts";
import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            return {
                ...PROP.defaults,
                creditLetterOpen: {
                    ...PROP.defaults.creditLetterOpen,
                    // formData: storeProp.creditLetterOpen.formData,
                },
            };
        }
        case TYPE.RESTART_FLOW:
            return PROP.defaults;
        /* Carta de credito de import */
        case TYPE.IMPORT_CREDIT_LETTER_PRE_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.IMPORT_CREDIT_LETTER_PRE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                creditLetterOpen: {
                    ...storeProp.creditLetterOpen,
                    preData: action,
                },
            };
        case TYPE.IMPORT_CREDIT_LETTER_PRE_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.IMPORT_CREDIT_LETTER_PREVIEW_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                creditLetterOpen: {
                    ...storeProp.creditLetterOpen,
                    formData: action.formData,
                },
            };
        case TYPE.IMPORT_CREDIT_LETTER_PREVIEW_FAILURE:
        case TYPE_COMEX.READ_TRANSACTION_FAILURE:
        case TYPE_COMEX.SIGN_TRANSACTION_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.IMPORT_CREDIT_LETTER_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.PREVIEW,
                prevMode: storeProp.mode,
            };
        case TYPE.IMPORT_CREDIT_LETTER_SEND_REQUEST:
        case TYPE_COMEX.READ_TRANSACTION_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.IMPORT_CREDIT_LETTER_SEND_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.IMPORT_CREDIT_LETTER_SEND_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                prevMode: storeProp.mode,
                transaction: action.transaction,
            };
        case TYPE.MODIFY_CREDIT_LETTER_PRE_REQUEST:
        case TYPE.LIFT_CREDIT_LETTER_DISCREPANCIES_PRE_REQUEST:
        case TYPE.REQUEST_ENDORSEMENT_PRE_REQUEST:
        case TYPE.MODIFY_CREDIT_LETTER_SEND_FORM:
        case TYPE.LIFT_CREDIT_LETTER_DISCREPANCIES_SEND_FORM:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.MODIFY_CREDIT_LETTER_PREVIEW_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                modifyCreditLetter: {
                    ...storeProp.modifyCreditLetter,
                    prevData: action.formData,
                },
            };
        case TYPE.MODIFY_CREDIT_LETTER_PRE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,

                modifyCreditLetter: {
                    ...storeProp.modifyCreditLetter,
                    preData: action,
                },
            };
        case TYPE.MODIFY_CREDIT_LETTER_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                mode: MODE.PREVIEW,
                fetching: false,

                modifyCreditLetter: {
                    ...storeProp.modifyCreditLetter,
                    formData: storeProp.modifyCreditLetter.prevData,
                },
                prevMode: storeProp.mode,
            };
        case TYPE.MODIFY_CREDIT_LETTER_SEND_FORM_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                transaction: action.transaction,
                prevMode: storeProp.mode,
            };
        case TYPE.COMEX_DESKTOP_PRE_FAILURE:
        case TYPE.MODIFY_CREDIT_LETTER_PRE_FAILURE:
        case TYPE.MODIFY_CREDIT_LETTER_SEND_FORM_FAILURE:
        case TYPE.REQUEST_ENDORSEMENT_PRE_FAILURE:
        case TYPE.MODIFY_CREDIT_LETTER_PREVIEW_FAILURE:
        case TYPE.REQUEST_ENDORSEMENT_PREVIEW_FAILURE:
        case TYPE.LIFT_CREDIT_LETTER_DISCREPANCIES_PREVIEW_FAILURE:
        case TYPE.LIFT_CREDIT_LETTER_DISCREPANCIES_SEND_FORM_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.REQUEST_ENDORSEMENT_PRE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                requestEndorsement: {
                    ...storeProp.requestEndorsement,
                    preData: action,
                },
            };
        case TYPE.BILLING_CANCELATION_PRE_REQUEST:
        case TYPE.BILLING_CANCELATION_SEND_FORM:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.BILLING_CANCELATION_PREVIEW_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                billingCancelation: {
                    ...storeProp.billingCancelation,
                    prevData: action.formData,
                },
            };
        case TYPE.BILLING_CANCELATION_PRE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,

                billingCancelation: {
                    ...storeProp.billingCancelation,
                    preData: action,
                },
            };
        case TYPE.BILLING_CANCELATION_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                mode: MODE.PREVIEW,
                prevMode: storeProp.mode,
                fetching: false,
                billingCancelation: {
                    ...storeProp.billingCancelation,
                    formData: storeProp.billingCancelation.prevData,
                },
            };

        case TYPE.BILLING_CANCELATION_SEND_FORM_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };
        case TYPE.BILLING_CANCELATION_SEND_FORM_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.BILLING_CANCELATION_PRE_FAILURE:
        case TYPE.BILLING_CANCELATION_PREVIEW_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };

        case TYPE.REQUEST_ENDORSEMENT_PREVIEW_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                requestEndorsement: {
                    ...storeProp.requestEndorsement,
                    prevData: action.formData,
                },
            };
        case TYPE.REQUEST_ENDORSEMENT_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                mode: MODE.PREVIEW,
                fetching: false,
                prevMode: storeProp.mode,
                requestEndorsement: {
                    ...storeProp.requestEndorsement,
                    formData: storeProp.requestEndorsement.prevData,
                },
            };
        case TYPE.REQUEST_ENDORSEMENT_SEND_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.REQUEST_ENDORSEMENT_SEND_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.REQUEST_ENDORSEMENT_SEND_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                prevMode: storeProp.mode,
                transaction: action.transaction,
            };
        case TYPE.LIFT_CREDIT_LETTER_DISCREPANCIES_PRE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,

                liftCreditLetterDiscrepancies: {
                    ...storeProp.liftCreditLetterDiscrepancies,
                    preData: action,
                },
            };
        case TYPE.LIFT_CREDIT_LETTER_DISCREPANCIES_PREVIEW_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                liftCreditLetterDiscrepancies: {
                    ...storeProp.liftCreditLetterDiscrepancies,
                    prevData: action.formData,
                },
            };
        case TYPE.LIFT_CREDIT_LETTER_DISCREPANCIES_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                mode: MODE.PREVIEW,
                fetching: false,
                prevMode: storeProp.mode,
                liftCreditLetterDiscrepancies: {
                    ...storeProp.liftCreditLetterDiscrepancies,
                    formData: storeProp.liftCreditLetterDiscrepancies.prevData,
                },
            };
        case TYPE.LIFT_CREDIT_LETTER_DISCREPANCIES_SEND_FORM_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                prevMode: storeProp.mode,
                transaction: action.transaction,
            };
        case TYPE_COMEX.LOAD_BANK_LIST_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE_COMEX.LOAD_BANK_LIST_FAILURE:
        case TYPE_COMEX.LOAD_BANK_LIST_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE_COMEX.CLOSE_TRANSACTION_PREVIEW:
            return {
                ...storeProp,
                mode: storeProp.prevMode,
            };
        case TYPE_COMEX.COMEX_FORM_TO_STEP_1:
            return {
                ...storeProp,
                mode: MODE.EDIT,
            };
        case TYPE_COMEX.READ_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                transaction: action.transaction,
                mode: action.transaction.idTransactionStatus === STATUS.DRAFT ? MODE.EDIT : MODE.VIEW,
            };
        case TYPE_COMEX.SIGN_TRANSACTION_PREVIEW:
            return {
                ...storeProp,
                prevMode: storeProp.mode,
                mode: MODE.PREVIEW,
                modifyCreditLetter: {
                    ...storeProp.modifyCreditLetter,
                    formData: action.data,
                },
            };
        case TYPE_COMEX.SIGN_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                mode: MODE.VIEW,
                prevMode: storeProp.mode,
                transaction: action.transaction,
            };
        case TYPE.DOCUMENT_PRESENTATION_PRE_REQUEST:
        case TYPE.DOCUMENT_PRESENTATION_SEND_FORM:
            return {
                ...storeProp,
                fetching: true,
            };

        /** Cancellation Credit Letter */
        case TYPE.COMEX_CANCELLATION_CREDIT_LETTER_PRE_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };

        case TYPE.DOCUMENT_PRESENTATION_PREVIEW_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                documentPresentation: {
                    ...storeProp.documentPresentation,
                    prevData: action.formData,
                },
            };
        case TYPE.DOCUMENT_PRESENTATION_PRE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,

                documentPresentation: {
                    ...storeProp.documentPresentation,
                    preData: action,
                },
            };
        case TYPE.DOCUMENT_PRESENTATION_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                mode: MODE.PREVIEW,
                prevMode: storeProp.mode,
                fetching: false,
                documentPresentation: {
                    ...storeProp.documentPresentation,
                    formData: storeProp.documentPresentation.prevData,
                },
            };

        case TYPE.DOCUMENT_PRESENTATION_SEND_FORM_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };
        case TYPE.DOCUMENT_PRESENTATION_PRE_FAILURE:
        case TYPE.DOCUMENT_PRESENTATION_PREVIEW_FAILURE:
        case TYPE.DOCUMENT_PRESENTATION_SEND_FORM_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.COMEX_CANCELLATION_CREDIT_LETTER_PRE_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                cancellationCreditLetter: {
                    ...storeProp.cancellationCreditLetter,
                    preData: action,
                },
            };
        case TYPE.COMEX_CANCELLATION_CREDIT_LETTER_PRE_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.CANCELLATION_CREDIT_LETTER_PREVIEW_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                cancellationCreditLetter: {
                    ...storeProp.cancellationCreditLetter,
                    prevData: action.formData,
                },
            };
        case TYPE.CANCELLATION_CREDIT_LETTER_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                mode: MODE.PREVIEW,
                fetching: false,

                cancellationCreditLetter: {
                    ...storeProp.cancellationCreditLetter,
                    formData: storeProp.cancellationCreditLetter.prevData,
                },
            };
        case TYPE.CANCELLATION_CREDIT_LETTER_PREVIEW_FAILURE:
        case TYPE.CANCELLATION_CREDIT_LETTER_SEND_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.CANCELLATION_CREDIT_LETTER_SEND_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.CANCELLATION_CREDIT_LETTER_SEND_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };
        case TYPE.COMEX_FORM_SEND_DATA_FAILURE:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.EDIT,
            };
        case TypesBackoffice.BACKOFFICE_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                prevMode: storeProp.mode,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };
        default:
            return storeProp;
    }
};
