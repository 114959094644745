import React from "react";

import { routerActions } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import {
    SelectorsStore as SelectorsStoreWMSellFunds,
    SelectorsAction as SelectorsActionWMSellFunds,
} from "~/store/wm/funds/sell";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import HighOrder from "~/components/HighOrder";

import FormEdit from "./Step1Edit/Step1Edit";
import FormPreview from "./Step2Preview/Step2Preview";
import FormView from "./Step3View/Step3View";

export const NAME = "SellFunds";

export const PROP = {
    types: {
        isFetching: PropTypes.bool,
        mode: PropTypes.string.isRequired,
        ...TypesRedux,
    },
    defaults: {
        isFetching: false,
        ...DefaultsRedux,
    },
};

export function Component(props) {
    const { isFetching, mode, dispatch, idPaper, history } = props;

    React.useEffect(() => {
        if (!idPaper) {
            dispatch(routerActions.goBack());
        } else {
            dispatch(SelectorsActionWMSellFunds.wealthManagementPreFormRequest({ idPaper }));
        }
        return () => {
            dispatch(SelectorsActionWMSellFunds.resetForm());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    function handleBack() {
        if (mode === MODE.EDIT) {
            history.goBack();
        } else if (mode === MODE.PREVIEW) {
            dispatch(SelectorsActionWMSellFunds.closeConfirmation());
        } else if (mode === MODE.VIEW) {
            dispatch(routerActions.replace("/wm/funds/list"));
            dispatch(SelectorsActionWMSellFunds.restartFlow());
        }
    }

    return (
        <Container
            name={NAME}
            wait={isFetching}
            step={mode}
            head-onBack={handleBack}
            head-onBackWeb={handleBack}
            head-title={UtilsI18n.get("client.wm.funds.sell.title")}
            scopeToShowNotification={SCOPE.WEALTH_MANAGEMENT_SELL_FUNDS}>
            <React.Fragment>
                {mode === MODE.EDIT && <FormEdit />}
                {mode === MODE.PREVIEW && <FormPreview />}
                {mode === MODE.VIEW && <FormView ticketConfirmation />}
            </React.Fragment>
        </Container>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        isFetching: SelectorsStoreWMSellFunds.isFetching(store),
        mode: SelectorsStoreWMSellFunds.getMode(store),
        idPaper: SelectorsStoreWMSellFunds.getIdPaper(store),
    };
};

export default HighOrder(Connect(mapStateToProps))(Component);
