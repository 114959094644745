import React from "react";

import Classnames from "classnames";
import PropTypes from "prop-types";

import Box from "~/components/Box";
import Element from "~/components/Component";
import RoundButton from "~/components/RoundButton";

import Namespace from "./Options.rules.scss";

export const { CLASS, NAME, TYPE } = Namespace;

export const PROP = {
    types: {
        onEdit: PropTypes.func,
        onRemove: PropTypes.func,
    },
    defaults: {
        onEdit: () => {},
        onRemove: () => {},
    },
};

export function Component({ onRemove, onEdit }) {
    return (
        <Element tag={Box} justify="center" className={Classnames(CLASS)} flex>
            <RoundButton image="trash" onClick={onRemove} />
            <RoundButton image="editPen" onClick={onEdit} />
        </Element>
    );
}

Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

export default React.memo(Component);
