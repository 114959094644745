import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { EMPTY_STR } from "~/constants";
import {
    SelectorsStore as SelectorsStoreUploadSuppliers,
    SelectorsAction as SelectorsActionUploadSuppliers,
} from "~/store/factoring/uploadSuppliers";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Token from "~/components/Token/Token";

import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        isSubmitting: PropTypes.bool,
        suppliersDisplayList: PropTypes.array.isRequired,
    },
    defaults: {
        isSubmitting: false,
    },
};

/**
 * Formulario: Importar proveedores.
 * Step2Preview: Mensaje de confirmacion de la operacion.
 */

export function Component(props) {
    const { isSubmitting, location, suppliersList } = props;
    const isFromTransactionsHistory = location.pathname.includes("/transaction/");

    return (
        <section id={Style.ID}>
            {!isFromTransactionsHistory && (
                <section className="message">
                    <span>{UtilsI18n.get("client.factoring.uploadSuppliers.step2.message.part1")}</span>
                    <span className="number-of-suppliers">{suppliersList.length}</span>
                    <span>{UtilsI18n.get("client.factoring.uploadSuppliers.step2.message.part2")}</span>
                </section>
            )}
            <Form>
                <Token labelButton="global.send" isSubmitting={isSubmitting}>
                    <Button size="sm" variant="secondary" disabled={isSubmitting} onClick={handleCancel}>
                        <I18n id="global.cancel" />
                    </Button>
                </Token>
            </Form>
        </section>
    );

    function handleCancel() {
        const { dispatch } = props;

        if (isFromTransactionsHistory) {
            dispatch(GoBack());
        } else {
            dispatch(SelectorsActionUploadSuppliers.previewCancelRequest());
        }
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        isSubmitting: SelectorsStoreUploadSuppliers.getIsSubmitting(store),
        suppliersList: SelectorsStoreUploadSuppliers.getSuppliers(store) || [],
        transaction: SelectorsStoreUploadSuppliers.getTransaction(store),
        user: SelectorsStoreSession.getUser(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithRouter,
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        validationSchema: (props) => {
            const { user } = props;

            return Yup.object().shape({
                otp: user.needsBiometric
                    ? Yup.string().nullable()
                    : Yup.string().required(UtilsI18n.get("client.factoring.uploadSuppliers.step2.otp.required")),
            });
        },
        handleSubmit: (data, formikBag) => {
            const {
                dispatch,
                history,
                transaction: { idTransaction },
            } = formikBag.props;
            const { otp } = data;

            const isFromTransactionsHistory = history.location.pathname.includes("/transaction/");

            if (isFromTransactionsHistory) {
                dispatch(
                    SelectorsActionUploadSuppliers.signTransaction({
                        idForm: null,
                        idActivity: "client.factoring.suppliers.upload.execute",
                        idTransaction,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            } else {
                dispatch(SelectorsActionUploadSuppliers.uploadSuppliersSendFormRequest({ otp, formikBag }));
            }
        },
    }),
)(Component);
