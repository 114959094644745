export const ACTION = {
    CREATE_TEMPLATE: "createTemplate",
    SAVE_DRAFT: "saveDraft",
    CANCEL_TRANSACTION: "cancelTransaction",
    CANCEL_DRAFT_TRANSACTION: "cancelDraftTransaction",
    MODIFY_TRANSACTION: "modifyTransaction",
    SIGN_TRANSACTION: "signTransaction",
};

export const FIELD = {
    AMOUNT: "amount",
    MULTILINEFILE: "multilinefile",
};

export const GENERIC = "generic";

export const ID_ACTIVITY = {
    TRANSFER_FOREIGN: "transfers.foreign.send",
    TRANSFER_LOCAL: "transfers.local.send",
};

export const ID_FORM = {
    FROM_BIOMETRIC: "fromBiometric",
    SALARY_PAYMENT: "salaryPayment",
    SUPPLIERS_PAYMENT: "suppliersPayment",
    TRANSFER_FOREIGN: "transferForeign",
    TRANSFER_LOCAL: "transferLocal",
};

export const MODE = {
    VIEW: "view",
    PREVIEW: "preview",
    EDIT: "edit",
    COMPONENT: "component",
};

export const MSG_KEY = {
    LESS: "forms.transaction.ticket.less",
    MORE: "forms.transaction.ticket.more",
};

export const POSTFIX = {
    SEND: ".send",
    PREVIEW: ".preview",
};

export const SCHEDULER_OPTIONS = {
    FUTURE: "FUTURE",
    MONTHLY: "MONTHLY",
    TODAY: "TODAY",
    WEEKLY: "WEEKLY",
};
