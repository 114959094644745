import React from "react";

import { compose } from "redux";

import { PROP as STORE_PROP } from "~/store/preferentialTradingPrice";
import * as UtilsI18n from "~/util/i18n";

import NumberFormatInput from "~/components/NumberFormatInput";
import FieldError from "~/pages/_components/fields/FieldError";
import withFocus from "~/pages/_components/withFocus";

import Style from "./AmountFX.rules.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {
        ...STORE_PROP.amountFx,
    },
    defaults: {
        ...STORE_PROP.defaultAmountFx,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {};

    handleChangeAmount = (event) => {
        const {
            target: { value },
        } = event;

        const { handleChangeAmount } = this.props;
        handleChangeAmount(value);
    };

    handleBlur = () => {
        const { toggleIsFocused, handleBlurError, fieldName } = this.props;
        toggleIsFocused();
        handleBlurError(fieldName);
    };

    render() {
        const {
            fieldName,
            thousandsSeparator,
            decimalSeparator,
            decimalToShow,
            currencyLabel,
            amount,
            errors,
            message,
            isFocused,
            toggleIsFocused,
        } = this.props;
        // Muestra los errores si el preview devolvio alguno y si el campo no ha sido "tocado"
        const hasError =
            !isFocused && errors !== null && !errors[fieldName.concat("Touched")] ? errors[fieldName] : false;
        return (
            <div className={`form-group ${hasError ? "has-error" : ""} ${isFocused ? "has-focus" : ""} ${CLASS}`}>
                <div className="form-group-text">
                    <label className="control-label">{UtilsI18n.get(message)}</label>
                </div>
                <div>
                    <div className="input-group">
                        <span className="currency">{currencyLabel}</span>
                        <NumberFormatInput
                            name="quantity"
                            className="form-control text-right quantity"
                            type="tel"
                            onChange={this.handleChangeAmount}
                            onFocus={toggleIsFocused}
                            onBlur={this.handleBlur}
                            maxLength={20}
                            value={amount}
                            decimalScale={decimalToShow}
                            thousandSeparator={thousandsSeparator}
                            decimalSeparator={decimalSeparator}
                            fixedDecimalScale
                            allowNegative={false}
                        />
                    </div>
                    {hasError && <FieldError error={errors[fieldName]} />}
                </div>
            </div>
        );
    }
}
export default compose(withFocus)(Component);
