import React from "react";

import PropTypes from "prop-types";

import FormattedDate from "~/pages/_components/FormattedDate";

export const NAME = "ItemExpirationDate";

export const PROP = {
    types: {
        date: PropTypes.string.isRequired,
        expirationText: PropTypes.string.isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const { expirationText, date } = props;
    return (
        <span className="data-date">
            <span className="data-date-tag">{expirationText}: </span>
            <span className="data-date-value">
                <FormattedDate date={date} />
            </span>
        </span>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
