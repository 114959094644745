export const STATUS = {
    ACCEPTED: "ACCEPTED",
    CANCELLED: "CANCELLED",
    DRAFT: "DRAFT",
    FAILED: "FAILED",
    FINISHED: "FINISHED",
    PENDING: "PENDING",
    PROCESSING: "PROCESSING",
    RETURNED: "RETURNED",
    RUNNING: "RUNNING",
    SCHEDULED: "SCHEDULED",
};

export const STATUS_FINAL = [
    STATUS.ACCEPTED,
    STATUS.CANCELLED,
    STATUS.FAILED,
    STATUS.FINISHED,
    STATUS.PROCESSING,
    STATUS.RETURNED,
];

export const STATUS_CAN_DOWNLOAD_PDF = [...STATUS_FINAL, STATUS.PENDING, STATUS.RUNNING, STATUS.SCHEDULED];

export const DEFAULT_STATUS = "DEFAULT";

export const STATUS_ICON = {
    ACCEPTED: "process",
    CANCELLED: "cancelled",
    DRAFT: "draft",
    FAILED: "cancelled",
    FAILED_RETURNED: "cancelled",
    FINISHED: "finished",
    PENDING: "sign",
    PROCESSING: "process",
    SCHEDULED: "schedule",
};
