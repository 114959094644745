import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { EMPTY_STR, PRICE_CLASSIFICATION, ZERO_NUMBER } from "~/constants";
import { MODE } from "~/constants/form";
import {
    SelectorsStore as SelectorsStoreWMBonds,
    SelectorsAction as SelectorsActionWMBonds,
    PROP as PropWMBonds,
} from "~/store/wm/bonds";
import * as UtilsConfig from "~/util/config";
import { addDay as AddDay } from "~/util/date";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import RadioOption from "~/components/RadioOption";
import Select from "~/components/Select";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import DateField from "~/pages/_components/fields/DateField";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TextField from "~/pages/_components/fields/TextField";
import AmountField from "~/pages/_components/fields/formik/AmountField";

import { Termsandconditions } from "~/pages/forms/_components/_fields";
import Checkbox from "~/pages/forms/_components/_fields/_commons/Checkbox";
import NominalValue from "~/pages/wm/otherInvestments/bonds/_components/NominalValue";
import Paper from "~/pages/wm/otherInvestments/bonds/_components/PaperSell";

import Style from "./Step1Edit.rules.scss";

const FORM_ID = "wm.bonds.sell.send";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...PropWMBonds.types,
    },
    defaults: {
        ...PropWMBonds.defaults,
    },
};

const SELLING_TYPE = {
    total: "TOTAL",
    partial: "PARCIAL",
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        const { dispatch, match } = this.props;
        const { idPaper } = match.params;

        if (idPaper) {
            dispatch(SelectorsActionWMBonds.sellBondsPre({ idPaper }));
        }
    }

    componentDidUpdate() {
        const { creditAccountList, match, paper, prevMode, processingSale, values } = this.props;
        const { othersFields } = paper;

        if (creditAccountList[0]) {
            if (!values.idCreditAccount || !this.findCreditAccount(creditAccountList, values.idCreditAccount)) {
                this.handleChange(creditAccountList[0].idProduct, "idCreditAccount");
            }
        }

        const { idPaper } = match.params;

        if (!values.idBond && idPaper) {
            this.handleBondChange(idPaper);
        }

        if (othersFields) {
            const { VALORNOMINAL } = othersFields;
            let nominalValue = parseFloat(VALORNOMINAL, 10);

            if (prevMode !== MODE.PREVIEW) {
                nominalValue -= processingSale;
            }

            if (
                nominalValue > 0 &&
                (!values.nominalValue ||
                    (values.sellingType === SELLING_TYPE.total && values.nominalValue.amount !== nominalValue))
            ) {
                this.handleChange({ amount: nominalValue }, "nominalValue");
            }
        }
    }

    buildAmountOptions = (curr) => {
        return {
            options: [
                {
                    id: curr,
                    label: UtilsI18n.get(`currency.label.${curr}`),
                },
            ],
        };
    };

    buildBondsOptions = (options) => {
        return options.map((elem) => {
            return {
                id: elem.idProduct,
                label: elem.name,
            };
        });
    };

    buildProductOptions = (options) => {
        return options.map((elem) => {
            return {
                id: elem.idProduct,
                label: elem.productAlias,
            };
        });
    };

    findCreditAccount = (creditAccountList, idCreditAccount) => {
        return creditAccountList.find((account) => account.idProduct === idCreditAccount);
    };

    handleBondChange = (id) => {
        const { dispatch, idPaper, setFieldValue } = this.props;
        const amount = 0;

        if (idPaper !== id.toString()) {
            setFieldValue("idBond", id);
            dispatch(SelectorsActionWMBonds.handleIdPaperSelected({ idPaper: id }));

            if (idPaper !== EMPTY_STR) {
                // if empty is the first render
                dispatch(SelectorsActionWMBonds.sellBondsPre({ idPaper: id }));
            }

            setFieldValue("nominalValue", { amount });
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(GoBack());
    };

    handleChange = (id, name) => {
        const { setFieldValue } = this.props;

        setFieldValue(name, id);
    };

    handleCheckChange = (fieldName) => {
        const { setFieldValue, values } = this.props;

        setFieldValue(fieldName, !values[fieldName]);
    };

    handleRadioChange = (id, name) => {
        const { paper, setFieldValue } = this.props;
        const { othersFields } = paper;
        let amount = 0;

        if (id === SELLING_TYPE.total) {
            const { VALORNOMINAL } = othersFields;

            amount = VALORNOMINAL ? parseFloat(VALORNOMINAL, 10) : 0;
        }

        setFieldValue("nominalValue", { amount });
        setFieldValue(name, id);
    };

    render() {
        const { bonds, creditAccountList, errors, paper, processingSale, timezoneOffset, touched, values } = this.props;
        const { othersFields } = paper;
        const price = PRICE_CLASSIFICATION.MARKET;
        const currency = othersFields ? othersFields.MONEDA : EMPTY_STR;
        const creditAccountOptions = this.buildProductOptions(creditAccountList);
        const bondsOptions = this.buildBondsOptions(bonds);
        const data = this.buildAmountOptions(currency);
        const minimum = othersFields ? othersFields.VENTAMINIMO : EMPTY_STR;
        const multiple = othersFields ? othersFields.VENTAMULTIPLOS : EMPTY_STR;

        const termsAndConditions = "client.wm.bonds.sell.disclaimer";
        const showAcceptOptionText = UtilsI18n.get("client.wm.bonds.finish.conditions");
        const i18nMap = {
            termsAndConditions,
            showAcceptOptionText,
        };

        const hasErrorCreditAccount = touched.idCreditAccount && errors.idCreditAccount && !values.idCreditAccount;
        const hasErrorBonds = touched.idBond && errors.idBond && !values.idBond;

        const availableForSelling = parseFloat(othersFields?.VALORNOMINAL || ZERO_NUMBER, 10) - processingSale;

        const maxDaysToShow = UtilsConfig.getInteger("client.wm.allInvestments.orderValid.maxDaysToShow");
        const minDate = new Date();
        const maxDate = AddDay(minDate, maxDaysToShow);

        return (
            <Form id={Style.ID} noValidate="novalidate" className="col col-12 col-lg-6 col-md-9 col-sm-12">
                <section className="fields container--layout align-items-center flex-grow">
                    <hr />
                    <Box className="form-section-title">
                        <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.buyData`)}</h3>
                    </Box>

                    {/* Bond data */}
                    <Box className={`form-group ${hasErrorBonds ? "has-error" : EMPTY_STR}`}>
                        <FieldLabel labelKey={`${FORM_ID}.bonds.label`} />

                        <Box className="input-group">
                            <Field
                                className="flex-container slideFromBottom"
                                clearable={false}
                                component={Select}
                                idForm={FORM_ID}
                                labelKey="label"
                                name="idBond"
                                onChange={(item) => {
                                    if (item) {
                                        this.handleBondChange(item.id);
                                    }
                                }}
                                optionClassName="needsclick"
                                options={bondsOptions}
                                placeholder={EMPTY_STR}
                                value={values.idBond}
                                valueKey="id"
                            />
                        </Box>
                    </Box>
                    <Box className="form-group">{hasErrorBonds && <FieldError error={errors.bonds} />}</Box>
                    {othersFields && (
                        <Paper
                            availableForSelling={availableForSelling}
                            isSelling
                            processingSale={processingSale}
                            othersFields={othersFields}
                        />
                    )}

                    {/* Selling type */}
                    <Box className="form-group">
                        <Box>
                            <Box flex>
                                <Field name="sellingType">
                                    {({ field: { value } }) => (
                                        <React.Fragment>
                                            <RadioOption
                                                checked={value === SELLING_TYPE.total}
                                                formId={FORM_ID}
                                                group="sellingType"
                                                mode={MODE.EDIT}
                                                onChange={this.handleRadioChange}
                                                value={SELLING_TYPE.total}
                                            />
                                            <RadioOption
                                                checked={value === SELLING_TYPE.partial}
                                                className="ml-3"
                                                formId={FORM_ID}
                                                group="sellingType"
                                                mode={MODE.EDIT}
                                                onChange={this.handleRadioChange}
                                                value={SELLING_TYPE.partial}
                                            />
                                        </React.Fragment>
                                    )}
                                </Field>
                            </Box>
                        </Box>
                    </Box>

                    {/* Nominal value */}
                    {values.sellingType === SELLING_TYPE.partial ? (
                        <React.Fragment>
                            <Field
                                clearable={false}
                                component={AmountField}
                                data={data}
                                decimalPlaces={2}
                                fixedDecimalScale
                                hideCurrency={!currency}
                                idForm={FORM_ID}
                                maxLength={UtilsConfig.getInteger("amount.length")}
                                mode={MODE.VIEW}
                                name="nominalValue"
                                placeholder={EMPTY_STR}
                                searchable={false}
                            />

                            {othersFields && <NominalValue isSelling minimum={minimum} multiple={multiple} />}
                        </React.Fragment>
                    ) : (
                        <Box className="form-group">
                            <FieldLabel labelKey={`${FORM_ID}.nominalValue.label`} />
                            <FormattedAmount currency={currency} quantity={availableForSelling} />
                        </Box>
                    )}

                    {/* Price */}
                    <Field
                        component={TextField}
                        hidePlaceholder
                        idForm={FORM_ID}
                        mode={MODE.VIEW}
                        name="price"
                        type="text"
                        value={UtilsI18n.get(`client.wm.bonds.sell.price.${price}`)}
                    />

                    {/* Order valid until */}
                    <div className="form-group">
                        <FieldLabel
                            labelKey="wm.allInvestments.orderValidUntil.label"
                            classContainer={`${
                                errors.orderCompleted && touched.orderCompleted ? "has-error" : EMPTY_STR
                            }`}
                        />

                        <Box className="label-checkbox">
                            <Field
                                name="orderCompleted"
                                label={UtilsI18n.get(`${FORM_ID}.orderCompleted.label`)}
                                checked={values.orderCompleted}
                                component={Checkbox}
                                onChange={() => this.handleCheckChange("orderCompleted")}
                            />
                        </Box>
                        {errors.orderCompleted && touched.orderCompleted && (
                            <FieldError error={errors.orderCompleted} />
                        )}
                    </div>

                    {!values.orderCompleted && (
                        <React.Fragment>
                            <Field
                                autoComplete="off"
                                component={DateField}
                                id="limitDate"
                                idForm={FORM_ID}
                                minDate={minDate}
                                maxDate={maxDate}
                                name="limitDate"
                                timezoneOffset={timezoneOffset}
                            />
                            <Box className="form-group">
                                <div id="disclaimer-text">
                                    {UtilsI18n.get("wm.allInvestments.afterValidDate.disclaimer")}
                                </div>
                            </Box>
                        </React.Fragment>
                    )}

                    {/* Credit account */}
                    <Box className={`form-group ${hasErrorCreditAccount ? "has-error" : EMPTY_STR}`}>
                        <FieldLabel labelKey={`${FORM_ID}.creditAccount.label`} />

                        <Box className="input-group">
                            <Field
                                className="flex-container slideFromBottom"
                                clearable={false}
                                component={Select}
                                id="idCreditAccount"
                                idForm={FORM_ID}
                                labelKey="label"
                                name="idCreditAccount"
                                onChange={(item) => {
                                    if (item) {
                                        this.handleChange(item.id, "idCreditAccount");
                                    }
                                }}
                                optionClassName="needsclick"
                                options={creditAccountOptions}
                                placeholder={EMPTY_STR}
                                value={values.idCreditAccount}
                                valueKey="id"
                            />
                        </Box>
                    </Box>
                    <Box className="form-group">
                        {hasErrorCreditAccount && <FieldError error={errors.idCreditAccount} />}
                    </Box>

                    {/* Disclaimer */}
                    <Box className="form-group label-checkbox">
                        <Field
                            className="form-control"
                            component={Termsandconditions}
                            i18nMap={i18nMap}
                            idForm={FORM_ID}
                            mode={MODE.EDIT}
                            name="isDisclaimer"
                            showAcceptOption
                        />
                    </Box>
                </section>

                {/* Submit */}
                <Box flex justify="center" className="mt-3">
                    <Button type="submit" variant="primary">
                        {UtilsI18n.get("client.wm.bonds.sell.send")}
                    </Button>
                </Box>
            </Form>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        bonds: SelectorsStoreWMBonds.getBonds(store),
        creditAccountList: SelectorsStoreWMBonds.getCreditAccountsList(store),
        formData: SelectorsStoreWMBonds.getFormData(store),
        idPaper: SelectorsStoreWMBonds.getIdPaper(store),
        paper: SelectorsStoreWMBonds.getPaper(store),
        prevMode: SelectorsStoreWMBonds.getPrevMode(store),
        processingSale: SelectorsStoreWMBonds.getProcessingSale(store),
        timezoneOffset: SelectorsStoreWMBonds.getTimezoneOffset(store),
    };
};

export default HighOrder(
    WithRouter,
    Connect(mapStateToProps),
    WithFormik({
        validationSchema: () =>
            Yup.lazy((props) =>
                Yup.object().shape({
                    idBond: Yup.string().trim().required(UtilsI18n.get("communications.subject.required")),
                    idCreditAccount: Yup.string()
                        .trim()
                        .required(UtilsI18n.get("wm.bonds.sell.send.creditAccount.required")),
                    isDisclaimer: Yup.boolean().oneOf(
                        [true],
                        UtilsI18n.get("client.wm.bonds.buy.isDisclaimerRequired"),
                    ),
                    limitDate: props.orderCompleted
                        ? Yup.string().notRequired()
                        : Yup.string().trim().required(UtilsI18n.get("wm.allInvestments.limitDate.required")),
                }),
            ),
        mapPropsToValues: ({ formData }) => {
            return {
                idBond: formData.idPaper,
                idCreditAccount: formData.idCreditAccount ? formData.idCreditAccount : EMPTY_STR,
                isDisclaimer: formData.isDisclaimer ? formData.isDisclaimer : false,
                limitDate: formData.limitDate || EMPTY_STR,
                nominalValue: { amount: formData.nominalValue },
                orderCompleted: formData?.orderCompleted !== false,
                price: PRICE_CLASSIFICATION.MARKET,
                sellingType: formData.sellingType ? formData.sellingType : SELLING_TYPE.total,
            };
        },
        handleSubmit: (
            { idCreditAccount, isDisclaimer, limitDate, nominalValue, orderCompleted, price, sellingType },
            formikBag,
        ) => {
            const { dispatch, paper, idPaper } = formikBag.props;
            const { DESCRIPCION: description, MONEDA: currency } = paper.othersFields;
            const nominalValueAmount = nominalValue?.amount;
            const hasNominalValue = nominalValueAmount && nominalValueAmount !== EMPTY_STR;
            const amountNominal = hasNominalValue ? nominalValueAmount : 0;

            dispatch(
                SelectorsActionWMBonds.sellBondsPreview({
                    formData: {
                        currency,
                        idCreditAccount,
                        idPaper,
                        isDisclaimer,
                        limitDate: orderCompleted ? EMPTY_STR : limitDate,
                        nominalValue: amountNominal,
                        orderCompleted,
                        paperName: description,
                        price,
                        sellingType,
                    },
                    formikBag,
                }),
            );
        },
    }),
)(Component);
