import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsStore as StoreSession } from "~/store/session";
import { Categorize, Prefix } from "~/util/prop";
import { $ } from "~/util/style";

import Notification from "~/pages/_components/Notification";

import Main from "../_Main";
import Footer from "./_Footer";
import Header, { PROP as PROP_HEADER } from "./_Header";
import Menu from "./_Menu";
import Namespace from "./_index.scss";

export const { NAME, TYPE } = Namespace;
export const PROP = {
    types: {
        children: PropTypes.node.isRequired,
        name: PropTypes.string.isRequired,
        scopeToShowNotification: PropTypes.string,
        ...Prefix("head", PROP_HEADER.types, ["menu-.*"]),
    },
    defaults: {
        children: undefined,
        name: undefined,
        scopeToShowNotification: null,
    },
};

export const WARN_HEAD = "head props are not allowed on this container. %o";

export function Component(props) {
    const { dispatch, children, wait, isFetching, head, scopeToShowNotification, isFetchingI18n, ...rest } = Categorize(
        props,
    );
    const [menu, setMenu] = React.useState(false);
    const handleMenuEnable = () => setMenu(true);
    const handleMenuDisable = () => setMenu(false);
    const classEnabled = $(menu && "coverage-show");

    return (
        <Main {...rest} wait={isFetching || isFetchingI18n || wait}>
            <div className={`coverage ${classEnabled}`} onClick={handleMenuDisable} />
            <Header {...head} menu-onClick={handleMenuEnable} />
            <Menu enabled={menu} onDisable={handleMenuDisable} />
            {scopeToShowNotification && <Notification scopeToShow={scopeToShowNotification} />}
            {children}
            <Footer />
        </Main>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

function mapStateToProps(store) {
    return {
        // whenever the session changes, block the screen.
        isFetching: StoreSession.isFetching(store),
        isFetchingI18n: StoreI18n.getFetching(store),
    };
}

export default Connect(mapStateToProps)(Component);
