import React from "react";

import { push as Push } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, PRODUCT_TYPE, DATA_NOT_AVAILABLE, SCOPE } from "~/constants";
import Container from "~/containers/Internal/Info";
import { SelectorsAction as SelectorsActionAccount, SelectorsStore as SelectorsStoreAccount } from "~/store/account";
import { OPTIONS } from "~/store/account/_consts";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsStore as StoreWidget } from "~/store/widget";
import UtilsDevice from "~/util/device";

import HighOrder from "~/components/HighOrder";

import Style from "./Details.rules.scss";
import OperationsButton from "./OperationsButton";
import CheckToCollect from "./_CheckToCollect";
import Collections from "./_Collections";
import KioskDeposit from "./_KioskDeposit";
import Movements from "./_Movements";
import Vouchers from "./_Vouchers";

export const { NAME, CLASS } = Style;

export const PROP = {
    defaults: {
        account: {
            accountHolder: EMPTY_STR,
            balance: 0,
            checkToCollect: null,
            collecting: null,
            consolidatedAmount: 0,
            countableBalance: 0,
            currency: "0",
            description: null,
            extraInfo: EMPTY_STR,
            idEnvironment: 0,
            idProduct: EMPTY_STR,
            kioskDeposit: null,
            label: EMPTY_STR,
            number: EMPTY_STR,
            overdraftLine: 0,
            pendingBalance: 0,
            productAlias: EMPTY_STR,
            productType: PRODUCT_TYPE.CA,
            productTypeLabel: null,
            productTypeShortLabel: null,
            shortLabel: EMPTY_STR,
            smsAlias: null,
            totalCheckAmount: 0,
            totalInTransit: null,
        },
        i18n: {
            labelKioskDeposit: "Depositos Buzonera",
            labelMovs: "Movimientos",
            loading: "Cargando",
            notAvailable: DATA_NOT_AVAILABLE,
        },
        isChangingEnvironment: false,
        showMulticashModal: false,
        showRatesModal: false,
    },
    types: {
        isChangingEnvironment: PropTypes.bool,
        showMulticashModal: PropTypes.bool,
        showRatesModal: PropTypes.bool,
    },
};

const TYPES = {
    CA: "savings",
    CC: "checking",
    CUI: "CUI",
};

export function Component(props) {
    const {
        account,
        accounts,
        activeEnvironment,
        dispatch,
        fetching,
        fetchingDetails,
        fetchingDownload,
        i18n,
        id,
        isChangingEnvironment,
    } = props;

    const [uiBalance, setUIBalance] = React.useState(0);

    const prevId = React.useRef();

    // check environment change
    const isSameEnv = account ? account.idEnvironment === activeEnvironment.id : true;
    const isSameAccount = !account || id !== prevId.current;
    const wait = fetching || fetchingDetails || fetchingDownload || isSameAccount;

    const {
        permissions: { billingTickets },
    } = activeEnvironment;

    // ComponentDidMount
    React.useEffect(() => {
        const idAccount = id;

        if (!isChangingEnvironment && (!idAccount || id !== prevId.current) && isSameEnv) {
            dispatch(SelectorsActionAccount.resetFilters());
            dispatch(SelectorsActionAccount.readAccount({ idAccount }));
            dispatch(SelectorsActionAccount.stateOfAccount({ idAccount }));
            dispatch(
                SelectorsActionAccount.details({
                    idAccount,
                    filters: { period: OPTIONS.LATEST_MOVS, dateFrom: null, dateTo: null },
                }),
            );

            prevId.current = id;
        }
    }, [dispatch, id, isChangingEnvironment, isSameEnv]);

    React.useEffect(() => {
        if (id) {
            const accountsFind = accounts.filter((item) => item.key === id);
            if (accountsFind.length > 0) {
                const accountFound = accountsFind[0];
                setUIBalance(accountFound.uiBalance);
            }
        }
    }, [id, accounts]);

    const productTypes = { CA: "savings", CC: "checking", CUI: "cui" };
    let auxAccount = {};

    if (account) {
        const {
            checkToCollect,
            collecting,
            countableBalance,
            currency,
            kioskDeposit,
            number,
            productAlias,
            productType,
            totalInTransit,
        } = account;

        auxAccount = {
            ...auxAccount,
            checkToCollect,
            collecting,
            countableBalance,
            currency,
            kioskDeposit,
            number,
            productAlias,
            productType,
            totalInTransit,
        };
    }

    const slides = [];

    if (!wait) {
        slides.push([
            {
                type: "Account",
                props: {
                    label: "accounts.availableBalance",
                    prefix: auxAccount.currency,
                    title: auxAccount.productAlias,
                    value: auxAccount.countableBalance,
                },
            },
        ]);

        if (auxAccount.totalInTransit) {
            slides[0].push({
                type: "Account",
                props: {
                    label: "client.account.totalInTransit",
                    prefix: auxAccount.currency,
                    value: auxAccount.totalInTransit,
                },
            });
        }

        if (account.productType === PRODUCT_TYPE.CUI) {
            slides[0].push({
                type: "Account",
                props: {
                    label: "accounts.equivalentUIBalance",
                    value: uiBalance,
                },
            });
        }
    }

    const showVouchers = UtilsDevice.isDisplayDesktop() ? billingTickets : false;

    return (
        <React.Fragment>
            <Container
                wait={wait}
                name={Style.NAME_MOVS}
                className={CLASS}
                head-title={auxAccount.productAlias}
                head-onBack={handleBack}
                info-button-component={OperationsButton}
                info-button-onClick={handleOperationsClick}
                info-button-label="menu.operations"
                info-title-label={`accounts.productType.${productTypes[auxAccount.productType]}`}
                info-title-data={auxAccount.number}
                info-data={slides}
                isCUI={account && account.productType === PRODUCT_TYPE.CUI}
                scopeToShowNotification={SCOPE.ACCOUNT_DETAILS}>
                {!wait &&
                    account && [
                        <Movements
                            container-title={i18n.labelMovs}
                            container-role="list"
                            container-name={Style.NAME_MOVS}
                            idAccount={account.idProduct}
                            accountCurrency={account.currency}
                            showMulticashModal
                            showRatesModal
                        />,
                        auxAccount.checkToCollect && (
                            <CheckToCollect
                                container-title={i18n.labelCheckToCollect}
                                container-role="list"
                                container-name={Style.NAME_CHECKTOCOLLECT}
                                accountCurrency={account.currency}
                                checkToCollect={auxAccount.checkToCollect}
                            />
                        ),
                        auxAccount.kioskDeposit && (
                            <KioskDeposit
                                container-title={i18n.labelKioskDeposit}
                                container-role="list"
                                container-name={Style.NAME_KIOSK_DEPOSIT}
                                accountCurrency={account.currency}
                                kioskDeposit={auxAccount.kioskDeposit}
                            />
                        ),
                        auxAccount.collecting && (
                            <Collections
                                container-title={i18n.labelCollections}
                                container-role="list"
                                container-name={Style.NAME_COLLECTIONS}
                                accountCurrency={account.currency}
                                collections={auxAccount.collecting}
                            />
                        ),
                        showVouchers && (
                            <Vouchers
                                container-title={i18n.labelBillingTickets}
                                container-role="list"
                                container-name={Style.NAME_VOUCHERS}
                            />
                        ),
                    ]}
            </Container>
        </React.Fragment>
    );

    function handleOperationsClick() {
        dispatch(Push(`/accounts/${account.idProduct}/operations`));
    }

    function handleBack() {
        const { history } = props;

        history.goBack();
    }
}

const mapStateToProps = (store, props) => {
    const { match } = props;

    const { data = [] } = StoreWidget.getWidget(store, "accounts") || {};

    const i18n = {
        labelBillingTickets: StoreI18n.getMessage(store, "client.account.billingTickets.title"),
        labelCheckToCollect: StoreI18n.getMessage(store, "client.account.checkToCollect.title"),
        labelCollections: StoreI18n.getMessage(store, "client.account.collecting.title"),
        labelKioskDeposit: StoreI18n.getMessage(store, "client.account.kioskDeposit.title"),
        labelMovs: StoreI18n.getMessage(store, "accounts.statements"),
        loading: StoreI18n.getMessage(store, "global.loading"),
        notAvailable: StoreI18n.getMessage(store, "data.notAvailable.short"),
    };

    const accounts = data.map((item) => {
        const { idProduct: key, productAlias: alias, productType, balance, currency, permissions, uiBalance } = item;

        return {
            alias,
            balance,
            currency,
            key,
            permissions,
            type: TYPES[productType] || "unknown",
            uiBalance,
        };
    });

    return {
        account: SelectorsStoreAccount.getSelectedAccount(store),
        accounts,
        activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
        activeEnvironmentForms: SelectorsStoreSession.getActiveEnvironmentForms(store, "accounts"),
        channels: SelectorsStoreAccount.getChannelList(store),
        fetching: SelectorsStoreAccount.getFetching(store),
        fetchingDetails: SelectorsStoreAccount.getFetchingDetails(store),
        fetchingDownload: SelectorsStoreAccount.getFetchingDownload(store),
        fetchingMovements: SelectorsStoreAccount.getFetchingMovements(store),
        i18n,
        id: match.params.id,
        isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    };
};

export default HighOrder(Connect(mapStateToProps))(Component);
