import React from "react";

import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

export const NAME = "ContainerColumn";

export const PROP = {
    types: {
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        componentClass: PropTypes.string,
        xs: PropTypes.number.isRequired,
        sm: PropTypes.number.isRequired,
        md: PropTypes.number.isRequired,
        lg: PropTypes.number.isRequired,
    },
    defaults: { className: "col", componentClass: "article" },
};
export function Component(props) {
    const { children } = props;
    return <Col {...props}>{children}</Col>;
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
