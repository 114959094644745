import PropTypes from "prop-types";

import { FactoryType } from "~/util/redux";

export const NAME = "connectedParties";

export const PATH = {
    CONNECTED_PARTIES_LIST_DEPARTMENTS: "connectedParties.listDepartments",
    CONNECTED_PARTIES_LIST_CITIES: "connectedParties.listCities",
    CONNECTED_PARTIES_PRE: "connectedParties.registerBantotalPre",
    CONNECTED_PARTIES_SUBMIT_FORM: "connectedParties.registerBantotal",
    PREFERENCES_CHANGE_USER_DATA: "preferences.change.userData.execute",
    PREFERENCES_CHANGE_USER_DATA_CMB: "preferences.change.userDataCMB",
};

export const TYPE = FactoryType(NAME, [
    "CONNECTEDPARTIES_FORCE_TO_COMPLETE_FORM",
    "CONNECTEDPARTIES_LIST_CITIES_FAILURE",
    "CONNECTEDPARTIES_LIST_CITIES_REQUEST",
    "CONNECTEDPARTIES_LIST_CITIES_SUCCESS",
    "CONNECTEDPARTIES_LIST_DEPARTMENTS_FAILURE",
    "CONNECTEDPARTIES_LIST_DEPARTMENTS_OF_BIRTH_FAILURE",
    "CONNECTEDPARTIES_LIST_DEPARTMENTS_OF_BIRTH_REQUEST",
    "CONNECTEDPARTIES_LIST_DEPARTMENTS_OF_BIRTH_SUCCESS",
    "CONNECTEDPARTIES_LIST_DEPARTMENTS_REQUEST",
    "CONNECTEDPARTIES_LIST_DEPARTMENTS_SUCCESS",
    "CONNECTEDPARTIES_PRE_FAILURE",
    "CONNECTEDPARTIES_PRE_REQUEST",
    "CONNECTEDPARTIES_PRE_SUCCESS",
    "CONNECTEDPARTIES_SUBMIT_FORM_FAILURE",
    "CONNECTEDPARTIES_SUBMIT_FORM_REQUEST",
    "CONNECTEDPARTIES_SUBMIT_FORM_SUCCESS",
    "READ_TRANSACTION_FAILURE",
    "READ_TRANSACTION_REQUEST",
    "READ_TRANSACTION_SUCCESS",
    "SET_REGISTERED_BANTOTAL",
]);

export const PROP = {};

PROP.types = {
    changeProcess: PropTypes.bool,
    cityList: PropTypes.array,
    cmbOnly: PropTypes.bool,
    countryList: PropTypes.array,
    departmentsOfBirth: PropTypes.array,
    documents: PropTypes.array,
    errors: PropTypes.any,
    fetching: PropTypes.bool,
    fetchingForm: PropTypes.bool,
    infoUser: PropTypes.object,
    maritalStatusList: PropTypes.array,
    productGroupIds: PropTypes.array,
    professions: PropTypes.array,
    regiteredBantotal: PropTypes.bool,
    townList: PropTypes.array,
    transaction: PropTypes.object,
    workActivities: PropTypes.array,
    workRoles: PropTypes.array,
    workSituations: PropTypes.array,
};

PROP.defaults = {
    changeProcess: false,
    cityList: [],
    cmbOnly: true,
    countryList: [],
    departmentsOfBirth: [],
    documents: [],
    errors: null,
    fetching: false,
    fetchingForm: false,
    infoUser: {},
    maritalStatusList: [],
    productGroupIds: [],
    professions: [],
    regiteredBantotal: true,
    transaction: {},
    townList: [],
    workActivities: [],
    workRoles: [],
    workSituations: [],
};
