import React from "react";

import { Field } from "formik";
import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import TextField from "~/pages/_components/fields/TextField";

export const NAME = "InputText";

export const PROP = {
    types: {
        ...TypesRedux,
        name: PropTypes.string.isRequired,
        mode: PropTypes.string.isRequired,
        idForm: PropTypes.string.isRequired,
        setFieldValue: PropTypes.func.isRequired,
        optional: PropTypes.string,
    },
    defaults: { optional: EMPTY_STR, ...DefaultsRedux },
};
export function Component(props) {
    const { name, mode, idForm, optional, setFieldValue, ...rest } = props;

    return (
        <Field
            name={name}
            idForm={idForm}
            component={TextField}
            autoFocus={false}
            mode={mode}
            hidePlaceholder
            optional={optional}
            handleOnChange={() => {
                setFieldValue("loadData", EMPTY_STR);
            }}
            {...rest}
        />
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;
export default Component;
