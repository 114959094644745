import React from "react";

import { Field, Formik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, REGEX_IGNORE_ACCENTS } from "~/constants";
import { SelectorsStore as SelectorsStoreBankSelector } from "~/store/bankSelector";
import { SelectorsAction as SelectorsActionForm, SelectorsStore as SelectorsStoreForm } from "~/store/form";
import * as I18nUtils from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import Container from "~/pages/_components/Container";
import PageLoading from "~/pages/_components/PageLoading";
import TextField from "~/pages/_components/fields/TextField";
import Selector from "~/pages/_components/fields/formik/Selector";
import SelectorInput from "~/pages/_components/fields/formik/SelectorInput";

const FORM_ID = "forms.bankselector";

export const NAME = "BankSearchForm";

export const PROP = {
    types: {
        codes: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
            }),
        ),
        countries: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
            }),
        ),
        loadListRequest: PropTypes.func.isRequired,
        match: PropTypes.shape({
            url: PropTypes.string.isRequired,
        }).isRequired,
        ...TypesRedux,
        bank_code: PropTypes.shape({
            code: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
        }),
        bank: PropTypes.string,
        country: PropTypes.string,
    },
    defaults: {
        ...DefaultsRedux,
        codes: [],
        countries: [],
        bank_code: {},
        bank: "",
        country: "",
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        stateIsSubmitting: false,
    };

    componentWillUnmount() {
        const { dispatch } = this.props;

        const data = { bank: EMPTY_STR, country: EMPTY_STR, bank_code: {} };
        dispatch(SelectorsActionForm.formDataSave({ data }));

        this.setState({ stateIsSubmitting: false });
    }

    handleChange = ({ type, code, setTouched, setValues, touched, values }) => {
        const bankTypeSelected = type && type.value;
        const bankCode = code ? code.toUpperCase() : EMPTY_STR;
        setTouched({ ...touched, bank_code: bankTypeSelected });
        setValues({
            ...values,
            country: bankTypeSelected === "ABA" || bankTypeSelected === "CHIPS" ? "840" : null,
            bank_code: { type: (type && type.value) || values.bank_code.type, code: bankCode },
        });
    };

    validateSearch = (obj) => {
        const {
            bank_code: { type, code },
            bank,
        } = obj;
        const errors = {};

        if (!type) {
            errors.bank_code = I18nUtils.get("forms.bankselector.typeRequired");
        }

        if (code && code.length < 3) {
            errors.bank_code = I18nUtils.get("forms.bankselector.codeToShort");
        }

        if (bank && bank.length < 3) {
            errors.bank = I18nUtils.get("forms.bankselector.nameToShort");
        }
        return errors;
    };

    handleSubmit = (values, formikbag) => {
        const { bank, country, bank_code } = values;
        const { loadListRequest, match, dispatch, isSubmitting } = this.props;
        const { setSubmitting, setErrors } = formikbag;
        const navigationAction = "push";
        const data = { bank, country, bank_code };
        dispatch(SelectorsActionForm.formDataSave({ data }));

        const { url } = match;
        const loadRequest = {
            filters: { name: bank, country, ...bank_code },
            setSubmitting,
            currentUrl: url,
            navigationAction,
            setErrors,
        };
        loadListRequest(loadRequest);

        if (isSubmitting) {
            this.setState({ stateIsSubmitting: true });
        }
    };

    customFilter(option, searchText) {
        const optionLowerCase = option.label.toLowerCase();
        const text = searchText.toLowerCase();

        if(optionLowerCase.normalize("NFD").replace(REGEX_IGNORE_ACCENTS, "").includes(text) && optionLowerCase.charAt(0).includes(text.charAt(0))){
            return true;
        }
        else {
            return false
        }
    }

    renderForm = ({ values, touched, errors, isSubmitting, handleSubmit, ...rest }) => {
        const { codes } = this.props;
        const { stateIsSubmitting } = this.state;
        let { countries } = this.props;

        if (
            (values && values.bank_code && values.bank_code.type && values.bank_code.type === "ABA") ||
            values.bank_code.type === "CHIPS"
        ) {
            countries = [
                {
                    id: "",
                    label: "form.selectors.emptyOptionLabel",
                },
                {
                    id: "840",
                    label: I18nUtils.get("country.name.840"),
                },
            ];
        }
        return (
            <PageLoading loading={isSubmitting}>
                <React.Fragment>
                    <Container className="container--layout align-items-center flex-grow">
                        <div className="container--layout--input-search-bank">
                            <Field
                                component={SelectorInput}
                                name="bank_code"
                                selectProps={{
                                    name: "type",
                                    value: values.bank_code.type,
                                    options: codes.slice(1, codes.length).map(({ id, label }) => ({
                                        value: id,
                                        label: I18nUtils.get(`forms.${label}`),
                                    })),
                                }}
                                label={I18nUtils.get("forms.bankselector.bankCode")}
                                inputProps={{
                                    name: "code",
                                    value: values.bank_code.code,
                                    placeholder: I18nUtils.get("forms.bankselector.bank.code"),
                                }}
                                onChange={(type, code) =>
                                    this.handleChange({
                                        type,
                                        code,
                                        values,
                                        touched,
                                        ...rest,
                                    })
                                }
                            />
                            <Field idForm={FORM_ID} name="bank" component={TextField} />
                            <Field
                                idForm={FORM_ID}
                                name="country"
                                component={Selector}
                                options={countries.slice(1, countries.length).map(({ id, label }) => ({
                                    value: id,
                                    label,
                                }))}
                                customFilter={this.customFilter}
                            />
                        </div>
                    </Container>
                    <Container className="container--layout align-items-center">
                        <div className="container--layout-button-search">
                            <Button
                                bsStyle="primary"
                                label="forms.bankselector.search"
                                loading={isSubmitting}
                                onClick={handleSubmit}
                                disable={stateIsSubmitting}
                            />
                        </div>
                    </Container>
                </React.Fragment>
            </PageLoading>
        );
    };

    render() {
        const { bank, bank_code, country, networkType } = this.props;

        return (
            <Formik
                initialValues={{
                    bank_code: { type: networkType, code: bank_code.code },
                    bank,
                    country,
                }}
                validate={this.validateSearch}
                onSubmit={this.handleSubmit}>
                {this.renderForm}
            </Formik>
        );
    }
}

const mapStateToProps = (store) => ({
    bank: SelectorsStoreForm.getFormDataBank(store),
    bank_code: SelectorsStoreForm.getFormDataSelect(store),
    country: SelectorsStoreForm.getFormDataCountry(store),
    networkType: SelectorsStoreBankSelector.getNetworkType(store),
});

export default Connect(mapStateToProps)(Component);
