import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";
import { getScheme as GetScheme } from "~/util/store/administration";

import { TYPE, NAME } from "./_consts";

export const SelectorsMiddleware = {
    loadGroupFormDataRequest: (id) => {
        return API.executeWithAccessToken(`/administration.advanced.group.${id ? "modify" : "create"}.pre`, { id });
    },
    submitGroupFormPreviewRequest: (formData, id) => {
        const { description, name, permissions, status, users } = formData;

        return API.executeWithAccessToken(`/administration.advanced.group.${id ? "modify" : "create"}.preview`, {
            description,
            name,
            permissions,
            status,
            users,
            id,
        });
    },
    submitGroupFormRequest: (formData, id) => {
        const { description, name, permissions, status, users } = formData;

        return API.executeWithAccessToken(`/administration.advanced.group.${id ? "modify" : "create"}.send`, {
            description,
            name,
            permissions,
            status,
            users,
            id,
        });
    },
};

export const SelectorsAction = {
    loadGroupRequest: (props) => FactoryAction(TYPE.LOAD_GROUP_REQUEST, props), // di
    loadGroupFailure: () => FactoryAction(TYPE.LOAD_GROUP_FAILURE),
    loadGroupSuccess: (props) => FactoryAction(TYPE.LOAD_GROUP_SUCCESS, props), // data

    // groupFormData

    loadGroupFormDataRequest: (props) => FactoryAction(TYPE.LOAD_GROUP_FORM_DATA_REQUEST, props), // id
    loadGroupFormDataFailure: () => FactoryAction(TYPE.LOAD_GROUP_FORM_DATA_FAILURE),
    loadGroupFormDataSuccess: (props) => FactoryAction(TYPE.LOAD_GROUP_FORM_DATA_SUCCESS, props), // group  permissions adminUsers adminGroup availableUsers
    submitGroupFormPreviewRequest: (props) => FactoryAction(TYPE.SUBMIT_GROUP_FORM_PREVIEW_REQUEST, props), //  "formData", "idGroup", "formikBag"
    submitGroupFormPreviewSuccess: (props) => FactoryAction(TYPE.SUBMIT_GROUP_FORM_PREVIEW_SUCCESS, props), //      "credentialGroups  idTransaction idActivity
    submitGroupFormRequest: (props) => FactoryAction(TYPE.SUBMIT_GROUP_FORM_REQUEST, props),
    submitGroupFormSuccess: (props) => FactoryAction(TYPE.SUBMIT_GROUP_FORM_SUCCESS, props),
    // groups
    loadGroupsRequest: (props) => FactoryAction(TYPE.LOAD_GROUPS_REQUEST, props), // id
    loadGroupsFailure: () => FactoryAction(TYPE.LOAD_GROUPS_FAILURE),
    loadGroupsSuccess: (props) => FactoryAction(TYPE.LOAD_GROUPS_SUCCESS, props),
    updateGroupsOfUserPreview: (props) => FactoryAction(TYPE.UPDATE_GROUPS_OF_USER_PREVIEW, props), // "data", "formikBag"
    updateGroupsOfUserPreviewSuccess: (props) => FactoryAction(TYPE.UPDATE_GROUPS_OF_USER_PREVIEW_SUCCESS, props), // "selectedGroupsIds credentialGroups  idTransaction  idActivity
    updateGroupsOfUserConfirm: (props) => FactoryAction(TYPE.UPDATE_GROUPS_OF_USER_CONFIRM, props), // "data formikBag"

    // ticket
    loadGroupsOfUserTicketRequest: (props) => FactoryAction(TYPE.LOAD_GROUPS_OF_USER_TICKET_REQUEST, props), // idTransaction
    loadGroupsOfUserTicketFailure: () => FactoryAction(TYPE.LOAD_GROUPS_OF_USER_FAILURE),
    loadGroupsOfUserTickeSuccess: (props) => FactoryAction(TYPE.LOAD_GROUPS_OF_USER_SUCCESS, props), // data

    // navigation

    updateHasClose: (props) => FactoryAction(TYPE.UPDATE_HAS_CLOSE, props), // hasClose
    updateHasBack: (props) => FactoryAction(TYPE.UPDATE_HAS_BACK, props), // hasBack
    goStepBack: () => FactoryAction(TYPE.STEP_BACK_REQUEST),
    reset: () => FactoryAction(TYPE.RESET),
};

export const SelectorsStore = {
    getName: (store) => GetScheme(store)[NAME].name,
    getDescription: (store) => GetScheme(store)[NAME].description,
    isAdminGroup: (store) => GetScheme(store)[NAME].adminGroup,
    getAdminUsers: (store) => GetScheme(store)[NAME]?.adminUsers,
    getGroupStatus: (store) => GetScheme(store)[NAME].groupStatus,
    getMembers: (store) => GetScheme(store)[NAME].members,
    getMode: (store) => GetScheme(store)[NAME]?.mode,
    isFetching: (store) => GetScheme(store)[NAME]?.fetching,

    // groupFormData

    getCredentialGroups: (store) => GetScheme(store)[NAME]?.credentialGroups,
    getIdTransaction: (store) => GetScheme(store)[NAME]?.idTransaction,
    getIdActivity: (store) => GetScheme(store)[NAME]?.idActivity,
    getAvailableUsers: (store) => (GetScheme(store) ? GetScheme(store)[NAME]?.availableUsers : []),
    getFormValues: (store) => GetScheme(store)[NAME]?.formValues,

    // groups

    getAvailableGroups: (store) => GetScheme(store)[NAME]?.availableGroups,
    getSelectedGroups: (store) => GetScheme(store)[NAME]?.selectedGroups,
    getGroupsToSave: (store) => GetScheme(store)[NAME]?.groupsToSave,

    // ticket
    getGroups: (store) => GetScheme(store)[NAME]?.groups,
    getUser: (store) => GetScheme(store)[NAME]?.user,

    // navigation
    isHasClose: (store) => GetScheme(store)[NAME]?.hasClose,
    isHasBack: (store) => GetScheme(store)[NAME]?.hasBack,
};
