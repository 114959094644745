import { FactoryAction } from "~/util/redux";
import { getScheme as GetScheme } from "~/util/store/administration";

import { NAME } from "./_consts";

export const SelectorsAction = (TYPE) => ({
    createSignaturesSchemeConfirmPre: (props) => FactoryAction(TYPE.CREATE_SIGNATURES_SCHEME_CONFIRM_PRE, props),
    createSignaturesSchemeFailure: () => FactoryAction(TYPE.CREATE_SIGNATURES_SCHEME_FAILURE),
    createSignaturesSchemePreFailure: (props) => FactoryAction(TYPE.CREATE_SIGNATURES_SCHEME_PRE_FAILURE, props),
    createSignaturesSchemePreRequest: () => FactoryAction(TYPE.CREATE_SIGNATURES_SCHEME_PRE_REQUEST),
    createSignaturesSchemePreSuccess: (props) => FactoryAction(TYPE.CREATE_SIGNATURES_SCHEME_PRE_SUCCESS, props),
    createSignaturesSchemeRequest: (props) => FactoryAction(TYPE.CREATE_SIGNATURES_SCHEME_REQUEST, props),
    createSignaturesSchemeSuccess: (props) => FactoryAction(TYPE.CREATE_SIGNATURES_SCHEME_SUCCESS, props),
    deleteSignaturesSchemeConfirmPre: (props) => FactoryAction(TYPE.DELETE_SIGNATURES_SCHEME_CONFIRM_PRE, props),
    deleteSignaturesSchemeFailure: () => FactoryAction(TYPE.DELETE_SIGNATURES_SCHEME_FAILURE),
    deleteSignaturesSchemePreFailure: () => FactoryAction(TYPE.DELETE_SIGNATURES_SCHEME_PRE_FAILURE),
    deleteSignaturesSchemePreRequest: (props) => FactoryAction(TYPE.DELETE_SIGNATURES_SCHEME_PRE_REQUEST, props),
    deleteSignaturesSchemePreSuccess: (props) => FactoryAction(TYPE.DELETE_SIGNATURES_SCHEME_PRE_SUCCESS, props),
    deleteSignaturesSchemeRequest: (props) => FactoryAction(TYPE.DELETE_SIGNATURES_SCHEME_REQUEST, props),
    deleteSignaturesSchemeSuccess: () => FactoryAction(TYPE.DELETE_SIGNATURES_SCHEME_SUCCESS),
    exportListFailure: () => FactoryAction(TYPE.SIGNATURES_SCHEME_EXPORT_LIST_FAILURE),
    exportListRequest: (props) => FactoryAction(TYPE.SIGNATURES_SCHEME_EXPORT_LIST_REQUEST, props),
    exportListSuccess: () => FactoryAction(TYPE.SIGNATURES_SCHEME_EXPORT_LIST_SUCCESS),
    listMoreSignaturesSchemesSuccess: (props) => FactoryAction(TYPE.SIGNATURES_SCHEME_LOAD_MORE_SUCCESS, props),
    listSignaturesSchemesFailure: () => FactoryAction(TYPE.LIST_SIGNATURES_SCHEMES_FAILURE),
    listSignaturesSchemesRequest: (props) => FactoryAction(TYPE.LIST_SIGNATURES_SCHEMES_REQUEST, props),
    listSignaturesSchemesSuccess: (props) => FactoryAction(TYPE.LIST_SIGNATURES_SCHEMES_SUCCESS, props),
    loadMoreRequest: (props) => FactoryAction(TYPE.SIGNATURES_SCHEME_LOAD_MORE_REQUEST, props),
    loadSignatureFormSuccess: (props) => FactoryAction(TYPE.LOAD_FORM_SUCCESS, props),
    loadSignatureTicketFailure: () => FactoryAction(TYPE.LOAD_SIGNATURE_TICKET_FAILURE),
    loadSignatureTicketRequest: (props) => FactoryAction(TYPE.LOAD_SIGNATURE_TICKET_REQUEST, props),
    loadSignatureTicketSuccess: (props) => FactoryAction(TYPE.LOAD_SIGNATURE_TICKET_SUCCESS, props),
    loadSignaturesSchemesTicketFailure: () => FactoryAction(TYPE.LOAD_SIGNATURES_SCHEMES_TICKET_FAILURE),
    loadSignaturesSchemesTicketRequest: (props) => FactoryAction(TYPE.LOAD_SIGNATURES_SCHEMES_TICKET_REQUEST, props),
    loadSignaturesSchemesTicketSuccess: (props) => FactoryAction(TYPE.LOAD_SIGNATURES_SCHEMES_TICKET_SUCCESS, props),
    modifySignaturesSchemeConfirmPre: (props) => FactoryAction(TYPE.MODIFY_SIGNATURES_SCHEME_CONFIRM_PRE, props),
    modifySignaturesSchemeFailure: () => FactoryAction(TYPE.MODIFY_SIGNATURES_SCHEME_FAILURE),
    modifySignaturesSchemePreFailure: () => FactoryAction(TYPE.MODIFY_SIGNATURES_SCHEME_PRE_FAILURE),
    modifySignaturesSchemePreRequest: (props) => FactoryAction(TYPE.MODIFY_SIGNATURES_SCHEME_PRE_REQUEST, props),
    modifySignaturesSchemePreSuccess: (props) => FactoryAction(TYPE.MODIFY_SIGNATURES_SCHEME_PRE_SUCCESS, props),
    modifySignaturesSchemeRequest: (props) => FactoryAction(TYPE.MODIFY_SIGNATURES_SCHEME_REQUEST, props),
    modifySignaturesSchemeSuccess: (props) => FactoryAction(TYPE.MODIFY_SIGNATURES_SCHEME_SUCCESS, props),
});

export const SelectorsStore = {
    capFrequencyList: (store) => GetScheme(store)[NAME]?.capFrequencyList,
    caps: (store) => GetScheme(store)[NAME]?.caps,
    currentPage: (store) => GetScheme(store)[NAME].currentPage,
    currentSignature: (store) => GetScheme(store)[NAME]?.currentSignature,
    currentSignatureGroupMap: (store) => GetScheme(store)[NAME]?.currentSignatureGroupMap,
    defaultSignatureCap: (store) => GetScheme(store)[NAME].defaultSignatureCap,
    enabledChannels: (store) => GetScheme(store)[NAME].enabledChannels,
    environmentProducts: (store) => GetScheme(store)[NAME]?.environmentProducts,
    fetching: (store) => GetScheme(store)[NAME]?.isFetching,
    functionalGroups: (store) => GetScheme(store)[NAME]?.functionalGroups,
    getCredentialGroups: (store) => GetScheme(store)[NAME]?.credentialGroups,
    getIdActivity: (store) => GetScheme(store)[NAME]?.idActivity,
    getIdTransaction: (store) => GetScheme(store)[NAME]?.idTransaction,
    getSignatureLevel: (store) => GetScheme(store)[NAME].signatureLevel,
    hasMoreData: (store) => GetScheme(store)[NAME].hasMoreData,
    isFetching: (store) => GetScheme(store)[NAME].fetching,
    isFetchingExport: (store) => GetScheme(store)[NAME].fetchingExport,
    isFetchingMoreSignaturesSchemes: (store) => GetScheme(store)[NAME].fetchingMoreSignaturesSchemes,
    masterCurrency: (store) => GetScheme(store)[NAME]?.masterCurrency,
    selectedFunctionalGroups: (store) => GetScheme(store)[NAME].selectedFunctionalGroups,
    selectedProducts: (store) => GetScheme(store)[NAME]?.selectedProducts,
    signatureDispatch: (store) => GetScheme(store)[NAME]?.signatureDispatch,
    signatureId: (store) => GetScheme(store)[NAME].signatureId,
    signatureLevelsCounts: (store) => GetScheme(store)[NAME]?.signatureLevelsCounts,
    signatureType: (store) => GetScheme(store)[NAME]?.signatureType,
    signatureTypeList: (store) => GetScheme(store)[NAME]?.signatureTypeList,
    signaturesSchemes: (store) => GetScheme(store)[NAME].signaturesSchemes,
    topAmount: (store) => GetScheme(store)[NAME]?.topAmount,
    totalPages: (store) => GetScheme(store)[NAME].totalPages,
};
