import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.BACKOFFICE_TRANSACTION_FAILURE:
            return { ...storeProp, fetching: false };
        case TYPE.BACKOFFICE_TRANSACTION_REQUEST:
            return { ...storeProp, fetching: true };
        case TYPE.BACKOFFICE_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                data: action.transactionData,
                fetching: false,
                idForm: action.transaction.idForm,
                formMetadata: action.form,
                transaction: action.transaction,
            };
        case TYPE.READ_ENVIRONMENT_DATA_FAILURE:
            return { ...storeProp, fetching: false };
        case TYPE.READ_ENVIRONMENT_DATA_REQUEST:
            return { ...storeProp, fetching: true };
        case TYPE.READ_ENVIRONMENT_DATA_SUCCESS:
            return {
                ...storeProp,
                environmentData: action.environmentData,
                fetching: false,
            };
        case TYPE.READ_USER_DATA_FAILURE:
            return { ...storeProp, fetching: false };
        case TYPE.READ_USER_DATA_REQUEST:
            return { ...storeProp, fetching: true };
        case TYPE.READ_USER_DATA_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                userData: action.userData,
            };
        default:
            return storeProp;
    }
};
