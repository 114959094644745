import React from "react";

import { Field } from "formik";

import { MODE } from "~/constants/form";
import * as UtilI18n from "~/util/i18n";

import I18n from "~/components/I18n";
import IndeterminateCheckbox from "~/pages/_components/IndeterminateCheckbox";
import FieldError from "~/pages/_components/fields/FieldError";

import Style from "./Disclaimer.rules.scss";

export const NAME = "Disclaimer";

export const PROP = {
    types: {},
    defaults: {},
};
export function Component(props) {
    const { idForm, name, label, labelDisclaimer, touched, errors, values = {} } = props;
    const [valueCheckbox, setValueCheckbox] = React.useState(values[name]);
    let hasError = false;

    if (touched && errors && values) {
        hasError = errors[name] && !values[name];
    }

    return (
        <div id={Style.ID}>
            <div className={hasError && "error-field"}>
                <div onClick={(event) => event.stopPropagation()}>
                    <Field idForm={idForm} name={name} autoFocus={false} mode={MODE.EDIT} hidePlaceholder>
                        {(propsField) => (
                            <IndeterminateCheckbox
                                id={idForm}
                                onCheckClick={() => {
                                    setValueCheckbox(!valueCheckbox);
                                    propsField.form.setFieldValue(name, !valueCheckbox);
                                }}
                                selectedOptionsAmount={valueCheckbox}
                                label={UtilI18n.get(label)}
                                {...propsField}
                            />
                        )}
                    </Field>
                </div>
                <div className="terms">
                    <div className="terms-block ">
                        <div className="contract-block-content form-group-text">
                            <I18n id={labelDisclaimer} />
                        </div>
                    </div>
                </div>
                {hasError && <FieldError error={errors?.disclaimer} />}
                <div className="enrollment-content-dialog">
                    <span className="enrollment-content-dialog-text" />
                </div>
            </div>
        </div>
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;
export default Component;
