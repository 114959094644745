import React from "react";

import { withFormik as WithFormik, Form, Field } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import {
    ALIAS_TYPES,
    EMPTY_STR,
    ID_FORM,
    MIDDLE_DASH,
    ONLY_NUMBER,
    PIPE_SEPARATOR,
    PLUS,
    URUGUAY_PHONE_PREFIX,
} from "~/constants";
import { MODE } from "~/constants/form";
import { SelectorsStore as SelectorsStoreSession, SelectorsAction as SelectorsActionSession } from "~/store/session";
import { SelectorsAction as SelectorsActionSettings, SelectorsStore as SelectorsStoreSettings } from "~/store/settings";
import {
    SelectorsAction as SelectorsActionTransfers,
    SelectorsStore as SelectorsStoreTransfers,
} from "~/store/transfers";
import * as UtilsI18n from "~/util/i18n";
import { GetMobileCountryOptions } from "~/util/phone";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";
import Token from "~/components/Token/Token";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import CountryCellPhoneField from "~/pages/_components/fields/formik/CountryCellPhoneField";

import { Termsandconditions } from "~/pages/forms/_components/_fields";

import StyleField from "../changePersonalInformation/field/MobilePhoneField.rules.scss";
import Style from "./ChangeMobilePhone.rules.scss";

const FORM_ID = "settings.changeMobilePhone";

export const { NAME } = Style;

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        isDesktop: PropTypes.bool.isRequired,
        isSubmitting: PropTypes.bool.isRequired,
        onCancel: PropTypes.func.isRequired,
        values: PropTypes.object.isRequired,
    },
    defaults: {
        isDesktop: false,
        isSubmitting: false,
        onCancel: null,
    },
};

export function Component({ dispatch, existsAccountsHSBC, isSubmitting, localBanks, onCancel, personalData }) {
    const termsAndConditions = "change.personal.mobilePhone.disclaimer";
    const [accountsByAliasAlreadyRequested, setAccountsByAliasAlreadyRequested] = React.useState(false);

    React.useEffect(() => {
        if (
            personalData.mobilePhone &&
            personalData.mobilePhone.trim() !== EMPTY_STR &&
            !accountsByAliasAlreadyRequested
        ) {
            const mobilePhone = personalData?.mobilePhone?.split(MIDDLE_DASH);
            const [prefix, number] = mobilePhone;

            let aliasDataToUse = EMPTY_STR;
            let aliasTypeToUse = EMPTY_STR;
            if (prefix !== ALIAS_TYPES.EMAIL.key && prefix !== ALIAS_TYPES.TEXT.key) {
                aliasTypeToUse = ALIAS_TYPES.PHONE.btValue;
                // Special treatment for phone aliases
                aliasDataToUse = prefix.substring(1).concat(PIPE_SEPARATOR).concat(number);
            }

            const params = {
                aliasData: aliasDataToUse,
                aliasType: aliasTypeToUse,
                idForm: ID_FORM.TRANSFER_LOCAL,
                isFromAdministrationSection: false,
                localBanksAlreadyFetched: localBanks.length > 0,
            };

            dispatch(SelectorsActionTransfers.getAccountsByAliasRequest({ params }));

            setAccountsByAliasAlreadyRequested(true);
        }
    }, [accountsByAliasAlreadyRequested, dispatch, localBanks.length, personalData]);

    React.useEffect(() => {
        dispatch(SelectorsActionSession.resetMobilePhoneForceUpdate());
    }, [dispatch]);

    const i18nMap = {
        termsAndConditions,
    };

    return (
        <React.Fragment>
            <div id={Style.ID}>
                <Form>
                    <div className="form--change_mobilePhone">
                        <div className="form--change_mobilePhone--title">
                            <I18n id="change.mobilePhone.label" />
                        </div>
                        <div className="container--data">
                            <p className="text-description">
                                <I18n id="change.mobilePhone.description" />
                            </p>
                            <div className="access-code">
                                <div className="field-mobilephone field-spacing" id={StyleField.ID}>
                                    <FieldLabel labelKey="change.PersonalData.mobilePhone.label" />
                                    <Field
                                        idForm={FORM_ID}
                                        name="mobilePhone"
                                        type="text"
                                        className="login-form-input"
                                        component={CountryCellPhoneField}
                                        hideLabel
                                        pattern={ONLY_NUMBER}
                                        hidePlaceholder={false}
                                        autoFocus={false}
                                        valueKey="countryName"
                                        labelKey="label"
                                        tooltip={
                                            <div className="field-phone--information">
                                                <I18n id="change.PersonalData.phone.information" />
                                                <I18n id="change.PersonalData.phone.informative" />
                                            </div>
                                        }
                                    />
                                </div>
                                {existsAccountsHSBC && (
                                    <Box className="disclaimer">
                                        <Field
                                            className="form-control"
                                            component={Termsandconditions}
                                            i18nMap={i18nMap}
                                            idForm={FORM_ID}
                                            name="disclaimer"
                                            mode={MODE.EDIT}
                                        />
                                    </Box>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="form--change_mobilePhone otp">
                        <div className="form--change_mobilePhone--title">
                            <I18n id="title.form.token" />
                        </div>
                        <Token labelButton="global.send" isSubmitting={isSubmitting}>
                            <Button size="sm" variant="secondary" disabled={isSubmitting} onClick={onCancel}>
                                <I18n id="global.cancel" />
                            </Button>
                        </Token>
                    </div>
                </Form>
            </div>
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    countryList: SelectorsStoreSettings.getCountryList(store),
    existsAccountsHSBC: SelectorsStoreTransfers.existsAccountsHSBC(store),
    localBanks: SelectorsStoreTransfers.getLocalBanks(store),
    personalData: SelectorsStoreSettings.getPersonalData(store),
    user: SelectorsStoreSession.getUser(store),
    userMobilePhoneSelected: SelectorsStoreSession.getUserMobilePhoneSelected(store),
});

export default Compose(
    WithRouter,
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => {
            const { countryList, personalData, userMobilePhoneSelected } = props;

            const mobilePhone = userMobilePhoneSelected
                ? userMobilePhoneSelected.split(MIDDLE_DASH)
                : personalData?.mobilePhone?.split(MIDDLE_DASH);

            let prefix = EMPTY_STR;
            let number = EMPTY_STR;
            let countryPhone = [];

            if (mobilePhone) {
                [prefix, number] = mobilePhone;
                countryPhone = GetMobileCountryOptions();
                prefix = prefix || `${PLUS}${URUGUAY_PHONE_PREFIX}`;
                countryPhone = countryPhone.filter((element) => element?.value === prefix);
            }

            return {
                countryList: countryList || [],
                mobilePhone: {
                    country: countryPhone[0]?.country,
                    mobilePhone: number || personalData?.mobilePhone,
                    prefix,
                },
                otp: EMPTY_STR,
            };
        },
        validationSchema: ({ user }) =>
            Yup.object().shape({
                mobilePhone: Yup.object().shape({
                    prefix: Yup.string().required(UtilsI18n.get(`${FORM_ID}.mobilePhone.mustBeAPhoneNumber`)),
                    mobilePhone: Yup.string().required(UtilsI18n.get("client.userconfiguration.empty.mobilePhone")),
                    country: Yup.string(),
                }),
                otp: user.needsBiometric
                    ? Yup.string().nullable()
                    : Yup.string().required(UtilsI18n.get("form.credential.otp.required")),
            }),
        handleSubmit: (props, formikBag) => {
            const { dispatch, userMobilePhoneSelected } = formikBag.props;
            const { setErrors, setSubmitting } = formikBag;
            const { mobilePhone, otp } = props;

            const newMobilePhone = `${mobilePhone?.prefix}-${mobilePhone?.mobilePhone}`;

            dispatch(
                SelectorsActionSettings.sendMobilePhoneCode({
                    isResending: false,
                    mobilePhone: newMobilePhone,
                    otp,
                    setErrors,
                    setSubmitting,
                    forceNotMatchUserMobilePhone: !!userMobilePhoneSelected,
                    formikBag,
                }),
            );
        },
    }),
)(Component);
