import React from "react";

import { push as Push } from "connected-react-router";
import { connect as Connect } from "react-redux";

import Container from "~/containers/External/Dash";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { Types as TypesHtmlElement, Defaults as DefaultsHtmlElement } from "~/util/prop/html-element";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";

import "./Information.scss";

export const NAME = "Information";

export const PROP = {
    types: {
        ...TypesHtmlElement,
        ...TypesRedux,
    },
    defaults: {
        ...DefaultsHtmlElement,
        ...DefaultsRedux,
    },
};

/**
 * Renders a list of information options.
 * @param {PROP.types} props
 */

export function Component(props) {
    const { dispatch, hasActiveSession } = props;

    const headClose = () => {
        if (hasActiveSession) {
            dispatch(Push("/desktop"));
        } else {
            dispatch(Push("/"));
        }
    };

    return (
        <Container name={NAME} head-onClose={headClose}>
            <div className="information-header">
                <I18n id="information.title" className="information-header-text" />
            </div>
            <div className="information-content">
                <div className="information-row simple-row">
                    <div className="information-image">
                        <Image src="information.svg" className="information-image-icon" />
                    </div>
                </div>
                <div className="information-row main-row">
                    <div className="information-list">
                        <Link to="/frequentQuestions">
                            <div className="information-item">
                                <I18n id="information.option.frequentQuestions" className="information-item-text" />
                            </div>
                        </Link>
                        <Link to="/mapLocations">
                            <div className="information-item">
                                <I18n id="information.option.branches" className="information-item-text" />
                            </div>
                        </Link>
                        <Link to="/generalConditions">
                            <div className="information-item">
                                <I18n id="information.option.generalConditions" className="information-item-text" />
                            </div>
                        </Link>
                        <Link to="/security">
                            <div className="information-item">
                                <I18n id="information.option.security" className="information-item-text" />
                            </div>
                        </Link>
                        <Link to="/informationQuery">
                            <div className="information-item">
                                <I18n id="information.option.query" className="information-item-text" />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="enrollment-content-dialog">
                <span className="enrollment-content-dialog-text" />
            </div>
        </Container>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (state) => ({
    hasActiveSession: SelectorsStoreSession.isLoggedIn(state),
});

export default Connect(mapStateToProps)(Component);
