export default class ErrorResponse {
    constructor(params) {
        const { code, headers, message, request, response, status, url } = params;

        this.headers = headers;
        this.status = status;

        if (response) {
            this.response = {
                status,
                data: response,
            };
        }

        if (request) {
            this.request = {
                responseURL: url,
            };
        }

        if (message) {
            this.message = message;
        }

        if (code) {
            this.code = code;
        }
    }
}
