import React from "react";

import Namespace from "./AnimWait.scss";
import Container from "./Component";

export const { NAME, TYPE } = Namespace;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component() {
    //
    return (
        <Container name={NAME} type={TYPE}>
            <span />
            <span />
            <span />
        </Container>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
