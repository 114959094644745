import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Field, Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, CREDENTIAL_TYPE_OTP } from "~/constants";
import {
    SelectorsAction as CustomsPaymentActions,
    SelectorsStore as CustomsPaymentSelectors,
} from "~/store/customsPayment";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import DataNumber from "~/components/DataNumber";
import HighOrder from "~/components/HighOrder";
import Credential from "~/pages/_components/fields/credentials/Credential";

import PaymentList from "../_components/PaymentList";
import Style from "./Step2Preview.rules.scss";

export const { NAME, ID } = Style;

export const PROP = {
    types: {
        fetching: PropTypes.bool.isRequired,
        ...TypesRedux,
    },
    defaults: {
        i18n: {},
        ...DefaultsRedux,
    },
};

const FORM_ID = "payments.customsPayment.step2";

export class Component extends React.Component {
    state = {
        selectedPayments: [],
        checkboxesValues: [],
        totalAmount: 0,
    };

    static getDerivedStateFromProps(props, state) {
        if (props.paymentItems && state && state.checkboxesValues && state.checkboxesValues.length === 0) {
            let totalAmount = 0;
            const checkboxesValues = [];
            const selectedPayments = [];
            props.paymentItems.forEach((element) => {
                checkboxesValues.push(true);
                selectedPayments.push(element);
                totalAmount += element.totalAmount;
            });
            return {
                ...state,
                checkboxesValues,
                selectedPayments,
                totalAmount,
            };
        }
        return { ...state };
    }

    HandleBack = () => {
        const { dispatch } = this.props;
        dispatch(GoBack());
    };

    selectPayment = (id, index) => {
        const { paymentItems } = this.props;
        const newItem = paymentItems.find((p) => p.merchantPaymentId === id);
        this.setState((prevState) => ({
            selectedPayments: prevState.selectedPayments.find((p) => p.merchantPaymentId === newItem.merchantPaymentId)
                ? prevState.selectedPayments.filter((p) => p.merchantPaymentId !== newItem.merchantPaymentId)
                : prevState.selectedPayments.concat(newItem),
            checkboxesValues: {
                ...prevState.checkboxesValues,
                [index]: !prevState.checkboxesValues[index],
            },
            totalAmount: prevState.selectedPayments.find((p) => p.merchantPaymentId === newItem.merchantPaymentId)
                ? prevState.totalAmount - newItem.totalAmount
                : prevState.totalAmount + newItem.totalAmount,
        }));
    };

    payItems = () => {
        const {
            dispatch,
            documentType,
            documentNumber,
            debitAccount,
            values,
            transaction,
            setErrors,
            setSubmitting,
        } = this.props;
        const { selectedPayments } = this.state;

        if (transaction && transaction.data) {
            const { idActivity, idTransaction } = transaction;
            dispatch(
                CustomsPaymentActions.signTransactionRequest({
                    idForm: null,
                    idActivity,
                    idTransaction,
                    credentials: { otp: values.otp },
                    formikBag: { setErrors, setSubmitting },
                }),
            );
        } else {
            dispatch(
                CustomsPaymentActions.payCustomsPreviewRequest({
                    selectedPayments,
                    documentType,
                    documentNumber,
                    debitAccount,
                    _otp: values.otp,
                    credentials: { otp: values.otp },
                    formikBag: { setErrors, setSubmitting },
                }),
            );
        }
    };

    render() {
        const { status, fetching, paymentItems, currency, mode, transaction } = this.props;
        const { totalAmount, checkboxesValues, selectedPayments } = this.state;

        let data = {
            selectedPayments,
            paymentItems,
            totalAmount,
            currency,
        };

        if (transaction && transaction.data) {
            data = {
                selectedPayments: transaction.data.selectedPayments,
                paymentItems: transaction.data.selectedPayments,
                totalAmount: transaction.data.totalAmount.quantity,
                currency: transaction.data.totalAmount.currency,
            };
        }
        const paymentTextKey =
            data.paymentItems.length > 1
                ? `${FORM_ID}.headerInfo.paymentText.plural`
                : `${FORM_ID}.headerInfo.paymentText.singular`;

        return (
            <React.Fragment>
                <section>
                    <Form id={ID} noValidate="novalidate" className="col col-12 col-lg-6 col-md-9 col-sm-12">
                        <div className="title-step-2">
                            <h3>
                                {UtilsI18n.replaceParams(UtilsI18n.get(`${FORM_ID}.headerInfo`), {
                                    quantity: data.paymentItems.length,
                                    paymentText: UtilsI18n.get(paymentTextKey),
                                    currency: UtilsI18n.get(`${FORM_ID}.headerInfo.currency.${data.currency}`),
                                })}
                            </h3>
                            <h2>
                                <DataNumber value={data.totalAmount} prefix={data.currency} className="mobile-only" />
                            </h2>
                        </div>
                        <PaymentList
                            formId={FORM_ID}
                            fetched={status && status.fetched && !fetching}
                            paymentItems={data.paymentItems}
                            currency={data.currency}
                            selectPayment={(!transaction || !transaction.data) && this.selectPayment}
                            checkboxesValues={checkboxesValues}
                            totalAmount={data.totalAmount}
                            mode={mode}
                        />
                        <div className="form-section-title">
                            <div className="Select flex-container slideFromBottom has-value Select--single opt-container">
                                <Field
                                    idForm={FORM_ID}
                                    name="otp"
                                    component={Credential}
                                    autoFocus={false}
                                    type={CREDENTIAL_TYPE_OTP}
                                />
                            </div>
                        </div>
                        {data.selectedPayments.length > 0 && (
                            <div className="container-button-center-step-two">
                                <Button
                                    key="primaryButton"
                                    variant="primary"
                                    bsStyle="primary"
                                    label={`${FORM_ID}.button.accept`}
                                    loading={false}
                                    type="submit"
                                    onClick={this.payItems}
                                />
                            </div>
                        )}
                    </Form>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    fetching: CustomsPaymentSelectors.getFetching(store),
    accounts: CustomsPaymentSelectors.getDebitAccountList(store),
    paymentItems: CustomsPaymentSelectors.getSelectedPayments(store),
    documentType: CustomsPaymentSelectors.getDocumentType(store),
    documentNumber: CustomsPaymentSelectors.getDocumentNumber(store),
    debitAccount: CustomsPaymentSelectors.getDebitAccount(store),
    currency: CustomsPaymentSelectors.getCurrency(store),
    mode: CustomsPaymentSelectors.getMode(store),
    i18n: {},
});

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        handleSubmit: () => {},
    }),
)(Component);
