/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import { push as Push } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { CDP_CURRENCY, EMPTY_STR, SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/Filter";
import { SelectorsAction as SelectorsActionComex, SelectorsStore as SelectorsStoreComex } from "~/store/comex";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import DataNumber from "~/components/DataNumber";
import Link from "~/components/Link";
import SelectButton from "~/components/SelectButton";
import Notification from "~/pages/_components/Notification";

import Style from "./ComEx.rules.scss";
import AccordionDetail from "./_components/AccordionDetail";
import AccountItem from "./_components/AccountItem";
import LinkOptions from "./_components/LinkOptions";
import PopUp from "./_components/PopUp";
import TransactionItem from "./_components/TransactionItem";
import { groupByCurrencyProductType } from "./constants";

export const { NAME, NAME_MESSAGES, TYPE_NOTE, NAME_NOTE } = Style;

export const PROP = {
    types: { isChangingEnvironment: PropTypes.bool },
    defaults: { isChangingEnvironment: false },
};

export class Component extends React.Component {
    state = {
        forceMode: "opened",
        showModal: false,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            collapsedElementsCount,
            positions: { positionExport, positionGuarantee, positionImport },
        } = nextProps;

        let positionLength = 0;

        positionLength += positionExport ? positionExport.length : 0;
        positionLength += positionGuarantee ? positionGuarantee.length : 0;
        positionLength += positionImport ? positionImport.length : 0;

        if (collapsedElementsCount === 0) {
            return { ...prevState, forceMode: "opened" };
        }

        if (collapsedElementsCount === positionLength) {
            return { ...prevState, forceMode: "closed" };
        }

        if (collapsedElementsCount > 0 && collapsedElementsCount < positionLength) {
            return prevState;
        }

        return null;
    }

    componentDidMount() {
        const { dispatch, isChangingEnvironment } = this.props;

        if (!isChangingEnvironment) {
            dispatch(SelectorsActionComex.comexDesktopPreRequest());
        }
    }

    handleBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    toggleAccordion = () => {
        const {
            collapsedElementsCount,
            dispatch,
            positions: { positionExport, positionGuarantee, positionImport },
        } = this.props;
        const { forceMode } = this.state;

        let collapseAll;
        let positionLength = 0;

        positionLength += positionExport ? positionExport.length : 0;
        positionLength += positionGuarantee ? positionGuarantee.length : 0;
        positionLength += positionImport ? positionImport.length : 0;

        if (collapsedElementsCount === 0) {
            collapseAll = true;
        } else if (collapsedElementsCount === positionLength) {
            collapseAll = false;
        } else if (collapsedElementsCount > 0 && collapsedElementsCount < positionLength) {
            collapseAll = forceMode === "opened";
        }

        dispatch(SelectorsActionComex.changeAllCollapsedElementsCount({ collapseAll }));
    };

    handleOpenModal = (fields, data, currency, title) => {
        this.setState({ showModal: true, fields, data, currency, title });
    };

    renderGroupHeader = (label, amount) => {
        return (
            <div className="groupHeader title">
                {label}
                {<DataNumber value={amount} prefix={CDP_CURRENCY.USD} className="mobile-only" />}
            </div>
        );
    };

    resolveComexActions = (productType, product) => {
        const {
            activeEnvironment: {
                permissions: {
                    comexBillingCancellation,
                    comexBillingClaim,
                    comexCreditLetterCancellation,
                    comexCreditLetterModification,
                    comexCreditLetterTransfer,
                    comexCreditLetterCession,
                    comexExportCreditLetterDocumentPresentation,
                    comexGuaranteesModification,
                    comexImportCreditLetterDocumentPresentation,
                    comexRequestEndorsement,
                    comexSentDocumentsAuthorization,
                },
            },
            dispatch,
        } = this.props;
        if (productType === 68 || productType === 74 || productType === 77) {
            if (
                comexExportCreditLetterDocumentPresentation ||
                comexSentDocumentsAuthorization ||
                comexCreditLetterTransfer ||
                comexCreditLetterCession
            ) {
                return (
                    <Box justify="end">
                        <SelectButton>
                            {comexExportCreditLetterDocumentPresentation && (
                                <Link
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const { NROOPERACION } = product.othersFields;
                                        dispatch(
                                            Push(`/comex/export/presentationDocumentsCreditLetter/${NROOPERACION}`),
                                        );
                                    }}>
                                    {UtilsI18n.get(
                                        "client.comex.newApplication.export.creditLetterDocumentPresentation",
                                    )}
                                </Link>
                            )}
                            {comexSentDocumentsAuthorization && (
                                <Link
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const { NROOPERACION } = product.othersFields;

                                        dispatch(
                                            Push(`/comex/export/discrepancyDocumentsAuthorization/${NROOPERACION}`),
                                        );
                                    }}>
                                    {UtilsI18n.get("client.comex.newApplication.export.sentDocumentsAuthorization")}
                                </Link>
                            )}
                            {comexCreditLetterTransfer && product && product.othersFields && (
                                <Link
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const { NROOPERACION } = product.othersFields;

                                        dispatch(Push(`/comex/export/transferCreditLetter/${NROOPERACION}`));
                                    }}>
                                    {UtilsI18n.get("client.comex.newApplication.export.creditLetterTransfer")}
                                </Link>
                            )}
                            {comexCreditLetterTransfer && (
                                <Link
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const { NROOPERACION } = product.othersFields;

                                        dispatch(Push(`/comex/export/cessionCreditLetter/${NROOPERACION}`));
                                    }}>
                                    {UtilsI18n.get("client.comex.newApplication.export.creditLetterCession")}
                                </Link>
                            )}
                        </SelectButton>
                    </Box>
                );
            }
        }

        if (productType === 81) {
            if (comexBillingClaim) {
                return (
                    <Box justify="end">
                        <SelectButton>
                            {comexBillingClaim && product && product.othersFields && (
                                <Link
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const { NROOPERACION } = product.othersFields;

                                        dispatch(Push(`/comex/export/billingClaim/${NROOPERACION}`));
                                    }}>
                                    {UtilsI18n.get("client.comex.newApplication.export.billingClaim")}
                                </Link>
                            )}
                        </SelectButton>
                    </Box>
                );
            }
        }

        if (productType === 71 || productType === 72 || productType === 89 || productType === 90) {
            if (comexCreditLetterModification || comexRequestEndorsement) {
                return (
                    <Box justify="end">
                        <SelectButton>
                            {comexCreditLetterModification && product && product.othersFields && (
                                <Link
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const { NROOPERACION } = product.othersFields;

                                        dispatch(Push(`/comex/import/modifyCreditLetter/${NROOPERACION}`));
                                    }}>
                                    {UtilsI18n.get("client.comex.newApplication.import.creditLetterModification")}
                                </Link>
                            )}
                            {comexRequestEndorsement && (
                                <Link
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const { NROOPERACION } = product.othersFields;

                                        dispatch(Push(`/comex/import/requestEndorsement/${NROOPERACION}`));
                                    }}>
                                    {UtilsI18n.get("client.comex.newApplication.import.requestEndorsement")}
                                </Link>
                            )}
                        </SelectButton>
                    </Box>
                );
            }
        }

        if (productType === 63 || productType === 64 || productType === 65 || productType === 66) {
            if (comexCreditLetterCancellation) {
                return (
                    <Box justify="end">
                        <SelectButton>
                            <Link
                                onClick={(e) => {
                                    e.preventDefault();
                                    const { NROOPERACION } = product.othersFields;

                                    dispatch(Push(`/comex/import/cancelaciondecartadecredito/${NROOPERACION}`));
                                }}>
                                {UtilsI18n.get("client.comex.newApplication.import.creditLetterCancellation")}
                            </Link>
                        </SelectButton>
                    </Box>
                );
            }
        }

        if (productType === 84) {
            if (comexBillingCancellation) {
                return (
                    <Box justify="end">
                        <SelectButton>
                            <Link
                                onClick={(e) => {
                                    e.preventDefault();
                                    const { NROOPERACION } = product.othersFields;

                                    dispatch(Push(`/comex/import/billingcancellation/${NROOPERACION}`));
                                }}>
                                {UtilsI18n.get("client.comex.newApplication.import.billingCancellation")}
                            </Link>
                        </SelectButton>
                    </Box>
                );
            }
        }

        if (productType === 69) {
            if (comexImportCreditLetterDocumentPresentation) {
                return (
                    <Box justify="end">
                        <SelectButton>
                            <Link
                                onClick={(e) => {
                                    e.preventDefault();
                                    const { NROOPERACION } = product.othersFields;

                                    dispatch(Push(`/comex/import/documentPresentation/${NROOPERACION}`));
                                }}>
                                {UtilsI18n.get(
                                    "administration.permissions.advanced.item.client.comex.import.creditLetterDocumentPresentation",
                                )}
                            </Link>
                        </SelectButton>
                    </Box>
                );
            }
        }

        if (productType === 73) {
            if (comexGuaranteesModification) {
                return (
                    <Box justify="end">
                        <SelectButton>
                            {comexGuaranteesModification && product && product.othersFields && (
                                <Link
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const { NROOPERACION } = product.othersFields;

                                        dispatch(
                                            Push(`/comex/bondsAndGuarantees/modifyBondsAndGuarantees/${NROOPERACION}`),
                                        );
                                    }}>
                                    {UtilsI18n.get("client.comex.newApplication.bondsAndGuarantees.modification")}
                                </Link>
                            )}
                        </SelectButton>
                    </Box>
                );
            }
        }

        return null;
    };

    render() {
        const {
            activeEnvironment: {
                permissions: {
                    comexBillingCancellation,
                    comexBillingClaim,
                    comexBillingDocumentPresentation,
                    comexCreditLetterCancellation,
                    comexCreditLetterCession,
                    comexCreditLetterDiscrepanciesUprising,
                    comexCreditLetterModification,
                    comexCreditLetterOpening,
                    comexCreditLetterPurchase,
                    comexCreditLetterTransfer,
                    comexExportCreditLetterDocumentPresentation,
                    comexExportRead,
                    comexGenericForm,
                    comexGuaranteesModification,
                    comexGuaranteesRead,
                    comexGuaranteesRequest,
                    comexImportCreditLetterDocumentPresentation,
                    comexImportRead,
                    comexRequestEndorsement,
                    comexSentDocumentsAuthorization,
                },
            },
            collapsedElementsCount,
            dispatch,
            fetching,
            i18n,
            lang,
            positions: {
                positionExport,
                positionExportTotal,
                positionGuarantee,
                positionGuaranteeTotal,
                positionImport,
                positionImportTotal,
            },
            transactionList,
        } = this.props;

        const { closeAll, openAll } = i18n;

        const { currency, data, fields, forceMode, showModal, title } = this.state;

        let buttonText;
        let showCloseAll;
        let positionLength = 0;

        positionLength += positionExport ? positionExport.length : 0;
        positionLength += positionGuarantee ? positionGuarantee.length : 0;
        positionLength += positionImport ? positionImport.length : 0;

        if (collapsedElementsCount === 0) {
            buttonText = closeAll;
            showCloseAll = true;
        }

        if (collapsedElementsCount === positionLength) {
            buttonText = openAll;
            showCloseAll = false;
        }

        if (collapsedElementsCount > 0 && collapsedElementsCount < positionLength) {
            buttonText = forceMode === "opened" ? closeAll : openAll;
            showCloseAll = forceMode === "opened";
        }

        return (
            <React.Fragment>
                <Notification scopeToShow={SCOPE.COMEX} />
                <Container
                    wait={fetching}
                    name={NAME}
                    head-onBack={this.handleBack}
                    head-title="Comex"
                    className="position"
                    image="comex.svg">
                    <div className="links header-content">
                        <React.Fragment>
                            <Box flex justify="end" className="box-button-content">
                                <SelectButton className="selectButton" label={UtilsI18n.get("comex.newApplication")}>
                                    <div>
                                        <strong className="title">
                                            {UtilsI18n.get("comex.desktop.menu.import.title")}
                                        </strong>
                                        <Link
                                            onClick={(event) => {
                                                event.preventDefault();

                                                if (comexCreditLetterOpening) {
                                                    dispatch(Push("/comex/import/creditLetter"));
                                                }
                                            }}
                                            className={!comexCreditLetterOpening ? "disabled-option" : EMPTY_STR}>
                                            {UtilsI18n.get("client.comex.newApplication.import.creditLetterOpening")}
                                        </Link>
                                        <Link
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (comexCreditLetterModification) {
                                                    dispatch(Push("/comex/import/modifyCreditLetter"));
                                                }
                                            }}
                                            className={!comexCreditLetterModification ? "disabled-option" : EMPTY_STR}>
                                            {UtilsI18n.get(
                                                "client.comex.newApplication.import.creditLetterModification",
                                            )}
                                        </Link>
                                        <Link
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (comexCreditLetterDiscrepanciesUprising) {
                                                    dispatch(Push("/comex/import/liftCreditLetterDiscrepancies"));
                                                }
                                            }}
                                            className={
                                                !comexCreditLetterDiscrepanciesUprising ? "disabled-option" : EMPTY_STR
                                            }>
                                            {UtilsI18n.get(
                                                "client.comex.newApplication.import.creditLetterDiscrepanciesUprising",
                                            )}
                                        </Link>
                                        <Link
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (comexCreditLetterCancellation) {
                                                    dispatch(Push("/comex/import/cancelaciondecartadecredito"));
                                                }
                                            }}
                                            className={!comexCreditLetterCancellation ? "disabled-option" : EMPTY_STR}>
                                            {UtilsI18n.get(
                                                "client.comex.newApplication.import.creditLetterCancellation",
                                            )}
                                        </Link>
                                        <Link
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (comexImportCreditLetterDocumentPresentation) {
                                                    dispatch(Push("/comex/import/documentPresentation"));
                                                }
                                            }}
                                            className={
                                                !comexImportCreditLetterDocumentPresentation
                                                    ? "disabled-option"
                                                    : EMPTY_STR
                                            }>
                                            {UtilsI18n.get(
                                                "client.comex.newApplication.import.creditLetterDocumentPresentation",
                                            )}
                                        </Link>
                                        <Link
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (comexRequestEndorsement) {
                                                    dispatch(Push("/comex/import/requestEndorsement"));
                                                }
                                            }}
                                            className={!comexRequestEndorsement ? "disabled-option" : EMPTY_STR}>
                                            {UtilsI18n.get("client.comex.newApplication.import.requestEndorsement")}
                                        </Link>
                                        <Link
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (comexBillingCancellation) {
                                                    dispatch(Push("/comex/import/billingcancellation"));
                                                }
                                            }}
                                            className={!comexBillingCancellation ? "disabled-option" : EMPTY_STR}>
                                            {UtilsI18n.get("client.comex.newApplication.import.billingCancellation")}
                                        </Link>
                                    </div>
                                    <div>
                                        <strong className="title">
                                            {UtilsI18n.get("comex.desktop.menu.export.title")}
                                        </strong>
                                        <Link
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (comexCreditLetterCession) {
                                                    dispatch(Push("/comex/export/cessionCreditLetter"));
                                                }
                                            }}
                                            className={!comexCreditLetterCession ? "disabled-option" : EMPTY_STR}>
                                            {UtilsI18n.get("client.comex.newApplication.export.creditLetterCession")}
                                        </Link>
                                        <Link
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (comexCreditLetterTransfer) {
                                                    dispatch(Push("/comex/export/transferCreditLetter"));
                                                }
                                            }}
                                            className={!comexCreditLetterTransfer ? "disabled-option" : EMPTY_STR}>
                                            {UtilsI18n.get("client.comex.newApplication.export.creditLetterTransfer")}
                                        </Link>
                                        <Link
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (comexCreditLetterPurchase) {
                                                    dispatch(Push("/comex/export/compracartacredito"));
                                                }
                                            }}
                                            className={!comexCreditLetterPurchase ? "disabled-option" : EMPTY_STR}>
                                            {UtilsI18n.get("client.comex.newApplication.export.creditLetterPurchase")}
                                        </Link>
                                        <Link
                                            onClick={(e) => {
                                                e.preventDefault();
                                                dispatch(Push("/comex/export/presentationDocumentsCreditLetter"));
                                            }}
                                            className={
                                                !comexExportCreditLetterDocumentPresentation
                                                    ? "disabled-option"
                                                    : EMPTY_STR
                                            }>
                                            {UtilsI18n.get(
                                                "client.comex.newApplication.export.creditLetterDocumentPresentation",
                                            )}
                                        </Link>
                                        <Link
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (comexSentDocumentsAuthorization) {
                                                    dispatch(Push("/comex/export/discrepancyDocumentsAuthorization"));
                                                }
                                            }}
                                            className={
                                                !comexSentDocumentsAuthorization ? "disabled-option" : EMPTY_STR
                                            }>
                                            {UtilsI18n.get(
                                                "client.comex.newApplication.export.sentDocumentsAuthorization",
                                            )}
                                        </Link>
                                        <Link
                                            onClick={(event) => {
                                                /* eslint-disable-next-line */
                                                comexBillingDocumentPresentation && event.preventDefault();
                                                dispatch(Push("/comex/export/presentBillingDocuments"));
                                            }}
                                            className={
                                                !comexBillingDocumentPresentation ? "disabled-option" : EMPTY_STR
                                            }>
                                            {UtilsI18n.get(
                                                "client.comex.newApplication.export.billingDocumentPresentation",
                                            )}
                                        </Link>
                                        <Link
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (comexBillingClaim) {
                                                    dispatch(Push("/comex/export/billingClaim"));
                                                }
                                            }}
                                            className={!comexBillingClaim ? "disabled-option" : EMPTY_STR}>
                                            {UtilsI18n.get("client.comex.newApplication.export.billingClaim")}
                                        </Link>
                                    </div>
                                    <div>
                                        <strong className="title">
                                            {UtilsI18n.get("comex.desktop.menu.bondsAndGuarantees.title")}
                                        </strong>
                                        <Link
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (comexGuaranteesRequest) {
                                                    dispatch(
                                                        Push("/comex/bondsAndGuarantees/requestBondsAndGuarantees"),
                                                    );
                                                }
                                            }}
                                            className={!comexGuaranteesRequest ? "disabled-option" : EMPTY_STR}>
                                            {UtilsI18n.get("client.comex.newApplication.bondsAndGuarantees.request")}
                                        </Link>
                                        <Link
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (comexGuaranteesModification) {
                                                    dispatch(
                                                        Push("/comex/bondsAndGuarantees/modifyBondsAndGuarantees"),
                                                    );
                                                }
                                            }}
                                            className={!comexGuaranteesModification ? "disabled-option" : EMPTY_STR}>
                                            {UtilsI18n.get(
                                                "client.comex.newApplication.bondsAndGuarantees.modification",
                                            )}
                                        </Link>
                                        <strong className="title">
                                            {UtilsI18n.get("comex.desktop.menu.other.title")}
                                        </strong>
                                        <Link
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (comexGenericForm) {
                                                    dispatch(Push("/comex/other/formulariogenerico"));
                                                }
                                            }}
                                            className={!comexGenericForm ? "disabled-option" : EMPTY_STR}>
                                            {UtilsI18n.get("client.comex.newApplication.other.genericForm")}
                                        </Link>
                                    </div>
                                </SelectButton>
                            </Box>
                        </React.Fragment>
                        <LinkOptions
                            image="arrow-top.svg"
                            handleClick={() => this.toggleAccordion()}
                            text={buttonText}
                            className={showCloseAll && "invert"}
                        />
                    </div>

                    <AccordionDetail
                        key="transactions"
                        title={
                            transactionList && transactionList.length > 0
                                ? UtilsI18n.replaceParams(UtilsI18n.get(`comex.desktop.transactions.headerInfo`), {
                                      quantity: transactionList.length,
                                  })
                                : UtilsI18n.get("comex.desktop.transactions.headerInfo.empty")
                        }
                        className="container-accordion-detail transactions-list"
                        forbidOpening={!transactionList || transactionList.length === 0}
                        forceMode={transactionList && transactionList.length > 0 ? forceMode : "closed"}>
                        {transactionList && transactionList.length > 0 && (
                            <TransactionItem transactions={transactionList} dispatch={dispatch} />
                        )}
                    </AccordionDetail>
                    <Box className="transaction-disclaimer">
                        {UtilsI18n.get("comex.desktop.transactions.headerInfo.disclaimer")}
                    </Box>

                    {/* POSITION */}
                    {comexImportRead &&
                        positionImport.length > 0 &&
                        this.renderGroupHeader(UtilsI18n.get("position.comex.import.title"), positionImportTotal)}
                    {comexImportRead &&
                        positionImport.map((position) => {
                            return (
                                <AccordionDetail
                                    key={position.productType}
                                    title={UtilsI18n.get(`client.position.productType.${position.productType}`)}
                                    total={position.total}
                                    className={`container-accordion-detail position_${position.productType}`}
                                    forceMode={forceMode}>
                                    <AccountItem
                                        name={UtilsI18n.get(`client.position.productType.${position.productType}`)}
                                        productType={position.productType}
                                        total={position.total}
                                        lang={lang}
                                        totalByCurrency={position.totalByCurrencyMap}
                                        productsByCurrency={position.productsByCurrency}
                                        innerAccordionForceMode={forceMode}
                                        handleOpenModal={this.handleOpenModal}
                                        groupByCurrency={groupByCurrencyProductType.includes(position.productType)}
                                        resolveActions={this.resolveComexActions}
                                    />
                                </AccordionDetail>
                            );
                        })}
                    {comexExportRead &&
                        positionExport.length > 0 &&
                        this.renderGroupHeader(UtilsI18n.get("position.comex.export.title"), positionExportTotal)}
                    {comexExportRead &&
                        positionExport.map((position) => {
                            return (
                                <AccordionDetail
                                    key={position.productType}
                                    title={UtilsI18n.get(`client.position.productType.${position.productType}`)}
                                    total={position.total}
                                    className={`container-accordion-detail position_${position.productType}`}
                                    forceMode={forceMode}>
                                    <AccountItem
                                        name={UtilsI18n.get(`client.position.productType.${position.productType}`)}
                                        productType={position.productType}
                                        total={position.total}
                                        totalByCurrency={position.totalByCurrencyMap}
                                        productsByCurrency={position.productsByCurrency}
                                        innerAccordionForceMode={forceMode}
                                        handleOpenModal={this.handleOpenModal}
                                        groupByCurrency={groupByCurrencyProductType.includes(position.productType)}
                                        resolveActions={this.resolveComexActions}
                                    />
                                </AccordionDetail>
                            );
                        })}
                    {comexGuaranteesRead &&
                        positionGuarantee.length > 0 &&
                        this.renderGroupHeader(UtilsI18n.get("position.comex.guarantee.title"), positionGuaranteeTotal)}
                    {comexGuaranteesRead &&
                        positionGuarantee.map((position) => {
                            return (
                                <AccordionDetail
                                    key={position.productType}
                                    title={UtilsI18n.get(`client.position.productType.${position.productType}`)}
                                    total={position.total}
                                    className={`container-accordion-detail position_${position.productType}`}
                                    forceMode={forceMode}>
                                    <AccountItem
                                        name={UtilsI18n.get(`client.position.productType.${position.productType}`)}
                                        productType={position.productType}
                                        total={position.total}
                                        totalByCurrency={position.totalByCurrencyMap}
                                        productsByCurrency={position.productsByCurrency}
                                        innerAccordionForceMode={forceMode}
                                        handleOpenModal={this.handleOpenModal}
                                        groupByCurrency={groupByCurrencyProductType.includes(position.productType)}
                                        resolveActions={this.resolveComexActions}
                                    />
                                </AccordionDetail>
                            );
                        })}

                    <PopUp
                        show={showModal}
                        title={title}
                        fields={fields}
                        data={data}
                        handleCloseModal={() => {
                            this.setState({ showModal: false });
                        }}
                        currency={currency}
                    />
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    collapsedElementsCount: SelectorsStoreComex.getCollapsedElementsCount(store),
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store) || {},
    fetching: SelectorsStoreComex.getFetching(store),
    i18n: {
        openAll: StoreI18n.getMessage(store, "position.accordions.openAll"),
        closeAll: StoreI18n.getMessage(store, "position.accordions.closeAll"),
        headTitle: StoreI18n.getMessage(store, "investment.title"),
    },
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    positions: SelectorsStoreComex.getPositions(store),
    transactionList: SelectorsStoreComex.getTransactionList(store),
    lang: StoreI18n.getLang(store),
});

export default Connect(mapStateToProps)(Component);
