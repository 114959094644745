import React from "react";

import { withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, ID_FORM, REQUEST_CHECKBOOK_DELIVERY_METHOD_BRANCH } from "~/constants";
import { ID_FORM as ID_FORM_OTHER } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { SelectorsAction as SelectorsActionBankSelector } from "~/store/bankSelector";
import { SelectorsAction as SelectorsActionForm, SelectorsStore as SelectorsStoreForm } from "~/store/form";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionTemplate, SelectorsStore as SelectorsStoreTemplate } from "~/store/template";
import UtilSearchBank from "~/util/bankSearchData";
import * as UtilsConfig from "~/util/config";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";

import Fields from "../_components/Fields";
import FieldHandler from "../fieldHandler";
import Style from "./Step1Edit.rules.scss";
import CreateTemplateModal from "./_ModalTemplateCreate";
import ListTemplatesModal from "./_ModalTemplateList";

export const { NAME } = Style;

export const PROP = {
    types: {
        handleSubmit: PropTypes.func,
        id: PropTypes.string,
        isFetching: PropTypes.bool,
        isModalVisible: PropTypes.bool,
        loadTemplateFromOtherPage: PropTypes.bool,
        setSubmitting: PropTypes.bool,
        transaction: PropTypes.object,
        values: PropTypes.object,
        ...TypesRedux,
    },
    defaults: {
        handleSubmit: () => {},
        id: EMPTY_STR,
        isFetching: false,
        isModalVisible: false,
        loadTemplateFromOtherPage: false,
        setSubmitting: false,
        transaction: {},
        values: {},
        ...DefaultsRedux,
    },
};

export function Component(props) {
    const {
        dispatch,
        id,
        isFetching,
        isModalVisible,
        loadTemplateFromOtherPage,
        metadata,
        setSubmitting,
        transaction,
        values,
    } = props;
    const { draftsEnabled, idActivity, templatesEnabled } = metadata;
    const cancelEnabled = transaction && transaction.idTransactionStatus === STATUS.DRAFT;

    return (
        <React.Fragment>
            <form id={Style.ID} onSubmit={handleSubmit}>
                {templatesEnabled && (
                    <div className="form-section laodTemplates">
                        <Button
                            onClick={handleTemplateLoad}
                            label="forms.templates.load"
                            bsStyle="default"
                            image="template.svg"
                        />
                    </div>
                )}

                <section className="fields container--layout align-items-center flex-grow">
                    <Fields values={values} />
                </section>

                <footer>
                    {draftsEnabled && <Button onClick={handleDraft} label="forms.saveDraft.link" />}
                    {templatesEnabled && (
                        <Button
                            onClick={handleTemplateSave}
                            label="forms.saveTemplate.link"
                            disabled={isModalVisible}
                        />
                    )}
                    {cancelEnabled && <Button onClick={handleCancel} label="forms.cancelTransaction.link" />}
                    {!isFetching && (
                        <Button
                            type="submit"
                            label={
                                <I18n id={`forms.${id}.send`} defaultValue={UtilsI18n.get("global.send", EMPTY_STR)} />
                            }
                            bsStyle="primary"
                            loading={isFetching}
                        />
                    )}
                </footer>
            </form>
            <CreateTemplateModal values={values} idForm={id} idActivityTemplate={idActivity} backdrop="static" />
            <ListTemplatesModal idForm={id} onSelect={handleSelect} />
        </React.Fragment>
    );

    function handleSubmit(event) {
        props.handleSubmit(event);

        if (id !== ID_FORM.TRANSFER_LOCAL) {
            dispatch(SelectorsActionForm.setData({ data: values }));
        }
    }

    function handleDraft() {
        // eslint-disable-next-line no-underscore-dangle
        let _values = values;

        if (id === ID_FORM_OTHER.SALARY_PAYMENT || id === ID_FORM_OTHER.SUPPLIERS_PAYMENT) {
            if (values.loadingMethod[0] === "file") {
                const files = values.file;
                const file = files[0];
                const list = file && file.list !== undefined ? files[0].list : [];

                _values = {
                    ...values,
                    file: [],
                    loadingMethod: ["manual"],
                    manualInput: list,
                };

                dispatch(
                    SelectorsActionForm.setData({
                        data: { ..._values },
                    }),
                );
            }
        }

        if (ID_FORM.TRANSFER_LOCAL === id || ID_FORM.TRANSFER_THIRD_PARTIES === id) {
            _values.transferAlias = {
                aliasData: EMPTY_STR,
                aliasType: UtilsConfig.get("cellPhone.code.URY"),
            };
        }

        dispatch(
            SelectorsActionForm.saveDraft({
                idForm: id,
                idActivityDraft: idActivity,
                idTransactionToSave: transaction ? transaction.idTransaction : null,
                transactionData: _values,
            }),
        );
    }

    function handleTemplateSave() {
        if (id === ID_FORM_OTHER.SALARY_PAYMENT || id === ID_FORM_OTHER.SUPPLIERS_PAYMENT) {
            if (values.loadingMethod[0] === "file") {
                const files = values.file;
                const file = files[0];
                const list = file && file.list !== undefined ? files[0].list : [];

                dispatch(
                    SelectorsActionForm.setData({
                        data: {
                            ...values,
                            file: [],
                            loadingMethod: ["manual"],
                            manualInput: list,
                        },
                    }),
                );
            }
        }

        if (loadTemplateFromOtherPage) {
            dispatch(SelectorsActionForm.setData({ data: { ...values } }));
        }

        dispatch(SelectorsActionTemplate.createTemplate());
    }

    function handleTemplateLoad() {
        dispatch(SelectorsActionTemplate.toggleTemplateList());
    }

    function handleCancel() {
        dispatch(
            SelectorsActionForm.cancelTransactionCustom({
                credentials: {},
                formikBag: { setSubmitting },
                idTransaction: transaction.idTransaction,
            }),
        );
    }

    function handleSelect(template, idForm) {
        const { activeEnvironment, initialValues, setErrors, setValues } = props;

        dispatch(SelectorsActionBankSelector.resetSelectedBanks());

        let templateToBeLoaded = template;

        if (template.idExpensesAccount === EMPTY_STR) {
            templateToBeLoaded.idExpensesAccount = template.debitAccount;
        }

        switch (idForm) {
            case ID_FORM.TRANSFER_LOCAL:
            case ID_FORM.TRANSFER_THIRD_PARTIES:
                if (!templateToBeLoaded.transferAlias) {
                    templateToBeLoaded.environmentType = activeEnvironment.type;
                }

                templateToBeLoaded.transferAlias = {
                    aliasData: EMPTY_STR,
                    aliasType: UtilsConfig.get("cellPhone.code.URY"),
                };

                break;
            case ID_FORM.TRANSFER_FOREIGN_FORM:
                UtilSearchBank.handleSearchBank(templateToBeLoaded, dispatch);

                break;
            case ID_FORM_OTHER.SALARY_PAYMENT:
            case ID_FORM_OTHER.SUPPLIERS_PAYMENT:
                dispatch(SelectorsActionTemplate.setSelectedTemplate(templateToBeLoaded));

                break;
            case ID_FORM.REQUEST_CHECKBOOK:
                if (!templateToBeLoaded.deliveryMethod) {
                    templateToBeLoaded.deliveryMethod = REQUEST_CHECKBOOK_DELIVERY_METHOD_BRANCH;
                }

                // eslint-disable-next-line no-case-declarations
                const initialValuesWithTemplateValues = Object.assign(initialValues, templateToBeLoaded);

                templateToBeLoaded = initialValuesWithTemplateValues;

                break;
            default:
                break;
        }

        setValues(templateToBeLoaded);
        dispatch(SelectorsActionForm.saveData());
        setErrors({});
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

function mapStateToProps(store) {
    return {
        activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
        currentLang: SelectorsStoreI18n.getLang(store),
        id: SelectorsStoreForm.getId(store),
        isFetching: SelectorsStoreForm.getFetching(store),
        isModalVisible: SelectorsStoreTemplate.isCreateModalVisible(store),
        loadTemplateFromOtherPage: SelectorsStoreTemplate.isLoadTemplateFromOtherPage(store),
        metadata: SelectorsStoreForm.getMetadata(store),
        transaction: SelectorsStoreForm.getTransaction(store),
    };
}

export default HighOrder(Connect(mapStateToProps), WithFormik(FieldHandler))(Component);
