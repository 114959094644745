import React from "react";

import PropTypes from "prop-types";
import NotificationSystem from "react-notification-system";
import { connect as Connect } from "react-redux";

import {
    SelectorsAction as SelectorsActionNotification,
    SelectorsStore as SelectorsStoreNotification,
} from "~/store/notification";
import PropRedux from "~/util/prop/redux";

import Style from "./Notification.rules.scss";

export const { CLASS, NAME, TAG: Notification } = Style;

export const PROP = {
    types: {
        ...PropRedux.types,
        children: PropTypes.arrayOf(PropTypes.element),
        dismissible: PropTypes.bool,
        scopeToShow: PropTypes.string.isRequired, // what scope this notification component must listen
        dispatch: PropTypes.func.isRequired,
        message: PropTypes.string,
        scopes: PropTypes.arrayOf(PropTypes.string),
        level: PropTypes.string,
        autoDismiss: PropTypes.number,
    },
    defaults: {
        ...PropRedux.defaults,
        message: null,
        scopes: [],
        level: null,
        children: null,
        dismissible: false,
        autoDismiss: 5,
    },
};

// TODO: [UYHSBCCDP-2994] Refactor al componente de notificaciones
const STYLE = {
    /* stylelint-disable */
    Containers: {
        DefaultStyle: {
            position: "fixed",
            left: "0px",
            width: "100%",
            zIndex: 1001,
            display: "block",
            padding: "1rem",
            marginBottom: "1rem",
            margin: "0 0 0 0 ",
            fontSize: "0.875rem",
            textAlign: "center",
        },
        tc: {
            WebkitBoxSizing: "border-box",
            position: "absolute",
            top: 0,
            bottom: "auto",
            zIndex: 9998,
            margin: "0 0 0 0",
            height: "auto",
            padding: "0 0.5rem 1rem",
            marginLeft: 0,
        },
    },
    NotificationItem: {
        DefaultStyle: {
            position: "relative",
            top: 0,
            zIndex: 1001,
            display: "block",
            width: "100%",
            padding: "0.5rem 0.75rem",
            fontSize: "1rem",
            margin: "1rem 0 0",
            transition: "0.3s ease-in-out",
            transform: "translate3d(0px, 0%, 0px)",
            willChange: "transform, opacity",
            opacity: 0,
            cursor: "default",
            fontFamily: "Roboto, sans-serif",
            fontWeight: "200",
        },
        success: {
            backgroundColor: "#e0cc5f",
            color: "#484848",
            borderTop: "none",
        },
        error: {
            backgroundColor: "#ce4a4a",
            color: "#fff",
        },
    },
    /* stylelint-enable */
};

// NOTE: Por customizacion ver: https://github.com/igorprado/react-notification-system
export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    ref = React.createRef();

    componentDidMount() {
        const { message, scopes, scopeToShow } = this.props;
        if (message && scopes && scopes.indexOf(scopeToShow) !== -1) {
            this.addNotification();
        }
    }

    componentDidUpdate() {
        const { message, scopes, scopeToShow, dispatch, removeNotification } = this.props;
        const {
            current: {
                state: { notifications },
            },
        } = this.ref;
        let isSameNotification = false;

        if (notifications.length > 0) {
            isSameNotification = message === notifications[0].message;
        }
        if (removeNotification) {
            const { current } = this.ref;
            current.clearNotifications();
        }
        if (scopes && scopes.indexOf(scopeToShow) !== -1 && !isSameNotification) {
            this.addNotification();
        }
        if (isSameNotification) {
            dispatch(SelectorsActionNotification.removeNotification());
        }
    }

    addNotification = () => {
        const { message, level, dispatch, autoDismiss, children } = this.props;
        const { current } = this.ref;
        current.clearNotifications();

        current.addNotification({
            children,
            message,
            level,
            position: "tc",
            autoDismiss,
            dismissible: false,
        });
        dispatch(SelectorsActionNotification.removeNotification());
    };

    render() {
        return (
            <Notification className={CLASS}>
                <NotificationSystem ref={this.ref} style={STYLE} />
            </Notification>
        );
    }
}

const mapStateToProps = (state) => ({
    message: SelectorsStoreNotification.getMessage(state),
    level: SelectorsStoreNotification.getLevel(state),
    scopes: SelectorsStoreNotification.getScopes(state),
    autoDismiss: SelectorsStoreNotification.getAutoDismiss(state),
    removeNotification: SelectorsStoreNotification.getRemove(state),
    children: SelectorsStoreNotification.getChildren(state),
});

export default Connect(mapStateToProps)(Component);
