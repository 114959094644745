import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.UPDATE_REQUEST:
            return { ...storeProp, fetching: true };
        case TYPE.UPDATE_SUCCESS:
            return { ...storeProp, fetching: false, items: action.items };
        case TYPE.UPDATE_FAILURE:
            return { ...storeProp, fetching: false };
        case TYPE.IS_TRUSTED:
            return { ...storeProp, isTrusted: action.isTrusted };
        default:
            return storeProp;
    }
};
