import PropTypes from "prop-types";

import { EMPTY_STR, ZERO_NUMBER } from "~/constants";
import { MODE } from "~/constants/form";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "wm.shares";

export const PATH = {
    BUY_SHARES_PRE_REQUEST: "/client.wm.shares.buy.pre",
    BUY_SHARES_PREVIEW_REQUEST: "/client.wm.shares.buy.preview",
    BUY_SHARES_REQUEST: "/client.wm.shares.buy.send",

    SEARCH_SHARES: "/client.wm.searchByIsinTicker",

    SELL_SHARES_PRE_REQUEST: "/client.wm.shares.sell.pre",
    SELL_SHARES_PREVIEW_REQUEST: "/client.wm.shares.sell.preview",
    SELL_SHARES_SEND_REQUEST: "/client.wm.shares.sell.send",

    GET_PAPER: "/client.wm.getPaper",
};

export const TYPE = FactoryType(NAME, [
    /* BUY SHARES */
    "SEARCH_SHARES_REQUEST",
    "SEARCH_SHARES_SUCCESS",
    "SEARCH_SHARES_FAILURE",

    /* BUY SHARES */
    "BUY_SHARES_PRE_REQUEST",
    "BUY_SHARES_PRE_SUCCESS",
    "BUY_SHARES_PRE_FAILURE",
    "BUY_SHARES_PREVIEW_REQUEST",
    "BUY_SHARES_PREVIEW_SUCCESS",
    "BUY_SHARES_PREVIEW_FAILURE",
    "BUY_SHARES_SEND_FORM",
    "BUY_SHARES_SEND_FORM_FAILURE",
    "BUY_SHARES_SEND_FORM_SUCCESS",

    /** SELL SHARES */
    "SELL_SHARES_PRE_REQUEST",
    "SELL_SHARES_PRE_SUCCESS",
    "SELL_SHARES_PRE_FAILURE",
    "SELL_SHARES_PREVIEW_REQUEST",
    "SELL_SHARES_PREVIEW_SUCCESS",
    "SELL_SHARES_PREVIEW_FAILURE",
    "SELL_SHARES_SEND_REQUEST",
    "SELL_SHARES_SEND_SUCCESS",
    "SELL_SHARES_SEND_FAILURE",

    "CANCEL_TRANSACTION_REQUEST",
    "CLEAR_FORM",
    "CLEAR_ON_CLASSIFICATION_CHANGE",
    "CLEAR_SEARCH_RESULTS",
    "GET_PAPER",
    "GO_STEP_BACK",
    "GO_TO_COMPONENT",
    "PAPER_ID_SELECTED",
    "READ_TRANSACTION_REQUEST",
    "READ_TRANSACTION_SUCCESS",
    "RESTART_FLOW",
    "SIGN_TRANSACTION_PREVIEW",
    "SIGN_TRANSACTION_REQUEST",
]);

export const PROP = {};

PROP.types = {
    ...TypesRedux,
    creditAccountList: PropTypes.array,
    debitAccountsList: PropTypes.array,
    formData: PropTypes.object,
    idPaper: PropTypes.string,
    isFetching: PropTypes.bool,
    mode: PropTypes.string,
    paper: PropTypes.shape({}),
    papersList: PropTypes.array,
    prevMode: PropTypes.string,
    previewData: PropTypes.object,
    processingSale: PropTypes.number,
    selectedType: PropTypes.string,
    sharesList: PropTypes.array,
    timezoneOffset: PropTypes.number,
    transaction: PropTypes.object,
};

PROP.defaults = {
    ...DefaultsRedux,
    creditAccountList: [],
    debitAccountsList: [],
    formData: {},
    idPaper: EMPTY_STR,
    isFetching: false,
    mode: MODE.EDIT,
    paper: {},
    papersList: null,
    prevMode: EMPTY_STR,
    previewData: {},
    processingSale: ZERO_NUMBER,
    selectedType: null,
    sharesList: [],
    timezoneOffset: ZERO_NUMBER,
    transaction: {
        data: {},
    },
};
