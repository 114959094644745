import * as API from "~/store/api";
import Logger from "~/util/logger";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE, STORE } from "./_consts";

export const SelectorsMiddleware = {
    listConfiguration: () => API.executeAnonymous(PATH.CONFIGURATION_LIST_CONFIGURATION),
    minVersionApp: () => API.executeAnonymous(PATH.CONFIGURATION_MIN_VERSION_APP),
};

export const SelectorsAction = {
    isTrusted: (props) => FactoryAction(TYPE.IS_TRUSTED, props),
    updateSuccess: (props) => FactoryAction(TYPE.UPDATE_SUCCESS, props),
    updateRequest: () => FactoryAction(TYPE.UPDATE_REQUEST),
    updateFailure: () => FactoryAction(TYPE.UPDATE_FAILURE),
};

export const SelectorsStore = {
    getConfig: (store) => store[NAME].items,
    getFetching: (store) => store[NAME].fetching,
    getIsTrusted: (store) => store[NAME].isTrusted,
    getItem: (store, { key, type = null }) => {
        if (!key) {
            Logger.error(`Expecting a 'key' string, got: '${key}'`);
            return undefined;
        }
        const { items } = store[NAME];
        const value = items[key];
        if (value === undefined) {
            Logger.warn(`The item '${key}', was not found in ${NAME}.`);
            return undefined;
        }
        switch (type) {
            case STORE.TYPE_ARRAY:
                return value.split(STORE.SEP_ARRAY);
            case STORE.TYPE_BOOLEAN:
                return value.toLowerCase() === "true";
            case STORE.TYPE_NUMBER: {
                const num = parseFloat(String(value).trim());
                return Number.isNaN(num) ? null : num;
            }
            default:
                return value;
        }
    },
};
