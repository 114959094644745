import PropTypes from "prop-types";

import * as arrayUtils from "~/util/array";

export const smartGroupsOptionsCount = (productTypes, values) => {
    const smartGroupAmount = productTypes.length;
    const selectedSmartGroupAmount = arrayUtils.intersection(
        values,
        productTypes.map((productType) => `ALL_${productType}`),
    ).length;

    return { selectedSmartGroupAmount, smartGroupAmount };
};

export const productsOptionsCount = (products, values) => {
    const productsAmount = products.length;
    const selectedProductsAmount = arrayUtils.intersection(
        values,
        products.map(({ idProduct }) => idProduct),
    ).length;

    return { selectedProductsAmount, productsAmount };
};

const groupShape = {
    idItem: PropTypes.string,
    label: PropTypes.string,
    ordinal: PropTypes.number,
};

groupShape.childrenList = PropTypes.arrayOf(PropTypes.shape(groupShape)).isRequired;

export const groupsPropType = PropTypes.arrayOf(PropTypes.shape(groupShape));

export const permissionsPropType = PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string), PropTypes.objectOf(PropTypes.string)]),
);

export const signatuleLevelsCountToInt = (object) =>
    Object.entries(object).reduce((values, [key, value]) => ({ ...values, [key]: parseInt(value, 10) }), {});
