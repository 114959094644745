import React from "react";

import { Modal } from "react-bootstrap";
import NumberFormat from "react-number-format";

import * as i18n from "~/util/i18n";

import Box from "~/components/Box";
import DataNumber from "~/components/DataNumber";
import GridLayout from "~/components/GridLayout";
import I18n from "~/components/I18n";
import Image from "~/components/Image";

import Style from "./AvailableForInvestmentModal.rules.scss";

export const { NAME, NAME_MESSAGES, ID } = Style;
export const PROP = {
    types: {},
    defaults: {},
};

export class Component extends React.Component {
    renderFieldValue = (data, field, currency) => {
        if (field.type === "amount") {
            return (
                <DataNumber
                    value={data[field.id]}
                    prefix={field.forceCurrency ? field.forceCurrency : currency}
                    className="mobile-only"
                />
            );
        }
        if (data[field.id]) {
            return <span>{data[field.id]}</span>;
        }
        return <span>N/A</span>;
    };

    render() {
        const { show, handleCloseModal, data } = this.props;
        const availableAmountCurrency = data && i18n.get(`core.currency.label.${data.availableAmountCurrency}`);

        return (
            <Modal show={show} id={ID} className="availableForInvestModal">
                <Modal.Body>
                    <div className="component-modal">
                        <div className="closed clickable" onClick={handleCloseModal} role="button">
                            <Image src="close.svg" />
                        </div>
                        <h2>{i18n.get("client.availableForInvestmentModal.title")}</h2>
                        <h3>{i18n.get("client.availableForInvestmentModal.subtitle")}</h3>
                        {data && data.availableForInvestItemList && (
                            <React.Fragment>
                                {data.availableForInvestItemList.map((item) => {
                                    const positivitySymbol = item.negative ? "-" : "+";
                                    return (
                                        <GridLayout>
                                            <Box>
                                                {item.operationType !== "NO" && (
                                                    <p>
                                                        {`${i18n.get(
                                                            `client.wm.availableForInvest.detail.${item.operationType}`,
                                                        )}  ${i18n.get(
                                                            `client.position.productType.${item.productType}`,
                                                        )}`}
                                                    </p>
                                                )}
                                                {item.operationType === "NO" && (
                                                    <p>{` ${i18n.get(
                                                        `client.position.productType.${item.productType}`,
                                                    )}  ${i18n.get(
                                                        `core.currency.label.${item.originalAmountCurrency}`,
                                                    )}`}</p>
                                                )}
                                            </Box>
                                            <Box justify="end">
                                                <NumberFormat
                                                    prefix={`${positivitySymbol} ${i18n.get(
                                                        `core.currency.label.${data.availableAmountCurrency}`,
                                                    )}  `}
                                                    className="form-control"
                                                    fixedDecimalScale
                                                    decimalSeparator=","
                                                    decimalScale={2}
                                                    thousandSeparator="."
                                                    displayType="text"
                                                    value={item.arbitratedAmount}
                                                />
                                            </Box>
                                        </GridLayout>
                                    );
                                })}
                                <GridLayout className="finalAmount">
                                    <Box>
                                        {i18n.get("client.availableForInvestmentModal.amount")}
                                    </Box>
                                    <Box justify="end">
                                        <NumberFormat
                                            prefix={`${availableAmountCurrency}  `}
                                            className="form-control"
                                            fixedDecimalScale
                                            decimalScale={2}
                                            decimalSeparator=","
                                            thousandSeparator="."
                                            displayType="text"
                                            value={data.availableAmount}
                                        />
                                    </Box>
                                </GridLayout>
                                <I18n
                                    tag="p"
                                    id="client.availableForInvestmentModal.explanation"
                                    className="explanation"
                                />
                            </React.Fragment>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
export default Component;
