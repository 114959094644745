import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SelectorsStore as SelectorsStoreUploadPaymentOrders } from "~/store/factoring/uploadPaymentOrders";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import UtilsFlag from "~/util/flag";
import { generateId as GenerateId } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";
import * as UtilsNumber from "~/util/number";

import ExpandElement from "~/components/ExpandElement";
import GridLayout from "~/components/GridLayout";
import HighOrder from "~/components/HighOrder";
import Image from "~/components/Image";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import FormattedDate from "~/pages/_components/FormattedDate";

import ExtraData from "./ExtraData";
import Style from "./UploadPaymentOrdersDisplayList.rules.scss";

export const { NAME } = Style;

const FORM_ID = "client.factoring.uploadPaymentOrders";

export const PROP = {
    types: {
        paymentOrdersList: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number,
                currency: PropTypes.number,
                details: PropTypes.string,
                dueDate: PropTypes.string,
                issueDate: PropTypes.string,
                paymentOrder: PropTypes.number,
                paymentType: PropTypes.string,
                supplier: PropTypes.number,
                supplierAccount: PropTypes.number,
                supplierDocNumber: PropTypes.number,
                supplierDocType: PropTypes.string,
                supplierName: PropTypes.string,
            }),
        ),
    },
    defaults: {},
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    shouldComponentUpdate(nextProps) {
        const { paymentOrdersList, totalizersList, transaction } = this.props;

        if (
            paymentOrdersList === nextProps.paymentOrdersList &&
            totalizersList === nextProps.totalizersList &&
            transaction === nextProps.transaction
        ) {
            return false;
        }

        return true;
    }

    render() {
        const { i18n, paymentOrdersList, totalizersList, transaction } = this.props;
        const COLUMN_HEADINGS = [i18n.type, i18n.number, i18n.supplier, i18n.issueDate, i18n.dueDate, i18n.amount];

        // Obtenemos las ordenes de pago desde la transaccion si estamos en el listado de transacciones.
        const paymentOrders = transaction && transaction.data ? transaction.data.paymentOrdersList : paymentOrdersList;
        const totalizers = transaction && transaction.data ? transaction.data.totalizersList : totalizersList;

        return (
            <React.Fragment>
                {paymentOrders && (
                    <div id={Style.ID}>
                        <GridLayout className="header upload-paymentOrders">
                            {COLUMN_HEADINGS.map((heading) => {
                                return <div key={`header-${GenerateId()}`}>{heading}</div>;
                            })}
                        </GridLayout>
                        {paymentOrders.map(
                            ({
                                amount,
                                currency,
                                details,
                                dueDate,
                                issueDate,
                                paymentOrder,
                                paymentType,
                                supplier,
                                supplierAccount,
                                supplierDocNumber,
                                supplierDocType,
                                supplierName,
                            }) => {
                                const detail = {
                                    details,
                                    supplier,
                                    supplierAccount,
                                    supplierDocNumber,
                                    supplierDocType,
                                };
                                return (
                                    <ExpandElement key={`paymentOrders-${GenerateId()}`}>
                                        <GridLayout className="item upload-paymentOrders">
                                            <div>{UtilsI18n.get(`${FORM_ID}.paymentType.${paymentType}`)}</div>
                                            <div>{paymentOrder}</div>
                                            <div>{supplierName || supplier}</div>
                                            <div>
                                                <FormattedDate date={issueDate} />
                                            </div>
                                            <div>
                                                <FormattedDate date={dueDate} />
                                            </div>
                                            <div className="currency">
                                                <FormattedAmount
                                                    currency={`${currency}`}
                                                    quantity={`${UtilsNumber.formatNumber(
                                                        amount,
                                                        UtilsI18n.getLang(),
                                                    )}`}
                                                />
                                            </div>
                                        </GridLayout>
                                        <GridLayout className="extra-data">
                                            <ExtraData detail={detail} />
                                        </GridLayout>
                                    </ExpandElement>
                                );
                            },
                        )}

                        {paymentOrders.length === 0 ? (
                            <div className="number-of-paymentOrders">
                                <span>{UtilsI18n.get(`${FORM_ID}.footer.total.empty.title`)}</span>
                            </div>
                        ) : (
                            totalizers.map(({ currency, amount, quantity }) => {
                                const quantityLabel = quantity === 1 ? "single" : "multiple";
                                return (
                                    <div className="number-of-paymentOrders">
                                        <span>
                                            <p>{quantity}</p>
                                            {UtilsI18n.get(
                                                `${FORM_ID}.footer.total.${currency}.${quantityLabel}.title`,
                                            )}
                                        </span>
                                        <span className="currency">
                                            <p>{UtilsI18n.get(`${FORM_ID}.footer.total.label`)}</p>
                                            <div>
                                                <Image
                                                    src={`${UtilsFlag.getFlag(`${currency}`)}.svg`}
                                                    className="svg-wrapper"
                                                />
                                                <FormattedAmount
                                                    currency={`${currency}`}
                                                    quantity={`${UtilsNumber.formatNumber(
                                                        amount,
                                                        UtilsI18n.getLang(),
                                                    )}`}
                                                />
                                            </div>
                                        </span>
                                    </div>
                                );
                            })
                        )}
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        i18n: {
            amount: StoreI18n.getMessage(store, "client.factoring.paymentOrders.list.amount"),
            dueDate: StoreI18n.getMessage(store, "client.factoring.paymentOrders.list.dueDate"),
            issueDate: StoreI18n.getMessage(store, "client.factoring.paymentOrders.list.issueDate"),
            number: StoreI18n.getMessage(store, "client.factoring.paymentOrders.list.number"),
            supplier: StoreI18n.getMessage(store, "client.factoring.paymentOrders.list.supplier"),
            type: StoreI18n.getMessage(store, "client.factoring.paymentOrders.list.type"),
        },
        paymentOrdersList: SelectorsStoreUploadPaymentOrders.getPaymentOrders(store),
        totalizersList: SelectorsStoreUploadPaymentOrders.getTotalizers(store),
        transaction: SelectorsStoreUploadPaymentOrders.getTransaction(store),
    };
};

export default HighOrder(Connect(mapStateToProps))(Component);
