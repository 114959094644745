import { TYPE as TYPE_PERMISSIONS } from "~/store/administration/common/permissions";

export const NAME = "simple";

export const PATH = {
    ADMINISTRATION_SIMPLE_READ_PERMISSIONS: "/administration.simple.read.permissions",
    ADMINISTRATION_SIMPLE_MODIFY_PERMISSIONS_PREVIEW: "/administration.simple.modify.permissions.preview",
    ADMINISTRATION_SIMPLE_MODIFY_PERMISSIONS_SEND: "/administration.simple.modify.permissions.send",
};

// Exportamos los TYPE con el prefijo del nivel de administracion que corresponda.

export const TYPE_ADMINISTRATION_LEVEL_SIMPLE_PERMISSION = TYPE_PERMISSIONS(NAME);
