import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    approveTransactionsPreviewRequest: (props) =>
        API.executeWithAccessToken(PATH.TRANSACTIONS_SIGN_BATCH_PREVIEW, props),
    approveTransactionsRequest: (props) => API.executeWithWebToken(PATH.TRANSACTIONS_SIGN_BATCH_SEND, props),
    cancelTransactionsPreviewRequest: (props) =>
        API.executeWithAccessToken(PATH.TRANSACTIONS_CANCEL_BATCH_PREVIEW, props),
    cancelTransactionsRequest: (props) => API.executeWithWebToken(PATH.TRANSACTIONS_CANCEL_BATCH_SEND, props),
    deleteDraftRequest: (props) => API.executeWithAccessToken(PATH.CORE_DELETE_TRANSACTION_DRAFT, props),
    gpiTracking: (props) => API.executeWithAccessToken(PATH.GPI_TRACKING, props),
    loadListRequest: (props) => API.executeWithAccessToken(PATH.TRANSACTIONS_LIST, props),
    listIdActivitiesExecuted: () => API.executeWithAccessToken(PATH.LIST_ID_ACTIVITIES_EXECUTED),

    shareTicketRequest: (props) =>
        // TODO:UYHSBCCDP-2857 no se envian todos los datos mandar pin  y id
        API.executeWithAccessToken(PATH.TRANSACTIONS_SHARE_TICKET_SEND, props),
};

export const SelectorsAction = {
    approveTransactionsFailure: (props) => FactoryAction(TYPE.APPROVE_TRANSACTIONS_FAILURE, props),
    approveTransactionsPreviewFailure: () => FactoryAction(TYPE.APPROVE_TRANSACTIONS_PREVIEW_FAILURE),
    approveTransactionsPreviewRequest: (props) => FactoryAction(TYPE.APPROVE_TRANSACTIONS_PREVIEW_REQUEST, props),
    approveTransactionsPreviewSuccess: (props) => FactoryAction(TYPE.APPROVE_TRANSACTIONS_PREVIEW_SUCCESS, props),
    approveTransactionsRequest: (props) => FactoryAction(TYPE.APPROVE_TRANSACTIONS_REQUEST, props),
    approveTransactionsSuccess: (props) => FactoryAction(TYPE.APPROVE_TRANSACTIONS_SUCCESS, props),
    cancelTransactionsFailure: (props) => FactoryAction(TYPE.CANCEL_TRANSACTIONS_FAILURE, props),
    cancelTransactionsPreviewFailure: () => FactoryAction(TYPE.CANCEL_TRANSACTIONS_PREVIEW_FAILURE),
    cancelTransactionsPreviewRequest: (props) => FactoryAction(TYPE.CANCEL_TRANSACTIONS_PREVIEW_REQUEST, props),
    cancelTransactionsPreviewSuccess: (props) => FactoryAction(TYPE.CANCEL_TRANSACTIONS_PREVIEW_SUCCESS, props),
    cancelTransactionsRequest: (props) => FactoryAction(TYPE.CANCEL_TRANSACTIONS_REQUEST, props),
    cancelTransactionsSuccess: (props) => FactoryAction(TYPE.CANCEL_TRANSACTIONS_SUCCESS, props),
    checkIfThereAreQRModoRunningTransactions: (props) =>
        FactoryAction(TYPE.CHECK_IF_THERE_ARE_QRMODO_RUNNING_TRANSACTIONS, props),
    clearPreview: () => FactoryAction(TYPE.CLEAR_PREVIEW),
    clearTransactionList: () => FactoryAction(TYPE.CLEAR_TRANSACTION_LIST),
    delayedStart: (props) => FactoryAction(TYPE.DELAYED_START, props),
    deleteDraftFailure: () => FactoryAction(TYPE.DELETE_DRAFT_FAILURE),
    deleteDraftRequest: (props) => FactoryAction(TYPE.DELETE_DRAFT_REQUEST, props),
    deleteDraftSuccess: (props) => FactoryAction(TYPE.DELETE_DRAFT_SUCCESS, props),
    gpiTracking: (props) => FactoryAction(TYPE.GPI_TRACKING, props),
    gpiTrackingFailure: (props) => FactoryAction(TYPE.GPI_TRACKING_FAILURE, props),
    gpiTrackingSuccess: (props) => FactoryAction(TYPE.GPI_TRACKING_SUCCESS, props),
    leaveQRModoPaymentsPage: () => FactoryAction(TYPE.LEAVE_QR_MODO_PAYMENTS_PAGE),
    listIdActivitiesExecutedRequest: () => FactoryAction(TYPE.LIST_ID_ACTIVITIES_EXECUTED_REQUEST),
    listIdActivitiesExecutedFailure: () => FactoryAction(TYPE.LIST_ID_ACTIVITIES_EXECUTED_FAILURE),
    listIdActivitiesExecutedSuccess: (props) => FactoryAction(TYPE.LIST_ID_ACTIVITIES_EXECUTED_SUCCESS, props),
    loadListFailure: () => FactoryAction(TYPE.LOAD_LIST_FAILURE),
    loadListRequest: (props) => FactoryAction(TYPE.LOAD_LIST_REQUEST, props),
    loadListSuccess: (props) => FactoryAction(TYPE.LOAD_LIST_SUCCESS, props),
    loadMoreTransactionsRequest: (props) => FactoryAction(TYPE.LOAD_MORE_TRANSACTIONS_REQUEST, props),
    loadMoreTransactionsSuccess: (props) => FactoryAction(TYPE.LOAD_MORE_TRANSACTIONS_SUCCESS, props),
    removeDelayedTransactionModal: (props) => FactoryAction(TYPE.DELAYED_STOP, props),
    resetFilter: () => FactoryAction(TYPE.RESET_FILTER),
    resumeTransactions: (props) => FactoryAction(TYPE.RESUME_TRANSACTIONS, props),
    setApproving: (props) => FactoryAction(TYPE.SET_APPROVING, props),
    selectedTransaction: () => FactoryAction(TYPE.LOADING),
    setFilters: (props) => FactoryAction(TYPE.SET_FILTERS, props),
    // TODO:UYHSBCCDP-2857 no se envian todos los datos
    shareTicketRequest: (props) => FactoryAction(TYPE.SHARE_TICKET_REQUEST, props),
    shareTicketFailure: (props) => FactoryAction(TYPE.SHARE_TICKET_FAILURE, props),
    shareTicketSuccess: (props) => FactoryAction(TYPE.SHARE_TICKET_SUCCESS, props),
};

export const SelectorsStore = {
    getCredentialsGroups: (store) => store[NAME].credentialsGroups,
    getDelayedTransactions: (store) => store[NAME].delayedTransactions,
    getErrors: (store) => store[NAME].errors,
    getFetching: (store) => store[NAME].isFetching,
    getFilterChange: (store) => store[NAME].isFilterChanged,
    getFilters: (store) => store[NAME].filters,
    getHasMoreData: (store) => store[NAME].hasMoreData,
    getIdActivitiesExecuted: (store) => store[NAME].idActivitiesList,
    getidApprovals: (store) => store[NAME].idTransactionList,
    getIsDelayed: (store) => store[NAME].isDelayed,
    getPageNumber: (store) => store[NAME].pageNumber,
    getPreviousLocation: (store) => store[NAME].previousLocation,
    getResumeTransaction: (store) => store[NAME].resumeTransaction,
    getSignAction: (store) => store[NAME].isApproving,
    getTotalTransactions: (store) => store[NAME].totalTransactions,
    getTransactions: (store) => store[NAME].transactions,
    isDeletingDraft: (store) => store[NAME].isDeletingDraft,
    isInQRMODOPage: (store) => store[NAME].isInQRMODOPage,
    isTicketSelected: (store) => store[NAME].ticketSelected,
};
