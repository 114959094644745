import React from "react";

import PropTypes from "prop-types";

import { FORMAT_DATE_SCHEDULER_FIELD } from "~/constants";
import { get as Get } from "~/util/config";
import { specificDate as SpecificDate, nowFormat as NowFormat } from "~/util/date";

import GenericDate from "~/components/GenericDate";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Select from "~/components/Select";

export const NAME = "LapseSubOption";

export const PROP = {
    types: {
        enabledWeekDays: PropTypes.arrayOf(PropTypes.bool).isRequired,
        firstWorkingDate: PropTypes.string.isRequired,
        nonWorkingDays: PropTypes.arrayOf(PropTypes.string).isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.objectOf(PropTypes.any).isRequired,
    },
    defaults: {},
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    excludeDates = () => {
        const { nonWorkingDays } = this.props;

        const excludeDates = [];
        nonWorkingDays.forEach((entry) => {
            excludeDates.push(SpecificDate(entry, FORMAT_DATE_SCHEDULER_FIELD));
        });

        return excludeDates;
    };

    filterDate = (date) => {
        const { enabledWeekDays } = this.props;

        return enabledWeekDays[(date.isoWeekday() % 7) + 1];
    };

    getUnlimitedOption = () => (
        <li className="c-control c-control--radio c-control--has-icon">{this.getOption("UNLIMITED")}</li>
    );

    getUntilOption = () => {
        const {
            firstWorkingDate,
            value: { date },
        } = this.props;

        return (
            <li className="c-control c-control--radio c-control-block c-control--has-icon">
                {this.getOption("UNTIL")}
                <div className="form-group form-group--datepicker form-group-row">
                    <div className="form-text-group">
                        <label htmlFor="until" className="control-label">
                            <I18n id="scheduler.lapseUntil" />
                        </label>
                    </div>
                    <div className="input-group input-group-datepicker">
                        <GenericDate
                            className="form-control"
                            dateFormat={Get("frontend.shortDateFormat")}
                            excludeDates={this.excludeDates()}
                            filterDate={this.filterDate}
                            minDate={SpecificDate(firstWorkingDate, FORMAT_DATE_SCHEDULER_FIELD)}
                            id="untilValue"
                            name="untilValue"
                            onChange={(newDate) => this.handleChange("UNTIL", newDate)}
                            selected={SpecificDate(date, "YYYY-MM-DD")}
                            autoComplete="off"
                        />
                    </div>
                </div>
            </li>
        );
    };

    getTimesOption = () => {
        const {
            value: { number },
        } = this.props;

        const options = [];
        for (let idx = 1; idx <= 42; idx += 1) {
            options.push({ id: idx, label: `${idx}` });
        }

        return (
            <li className="c-control c-control--radio c-control-block c-control--has-icon">
                {this.getOption("UP_TO")}
                <div className="form-group form-group--select form-group-row">
                    <div className="input-group">
                        <Select
                            className="slideFromBottom"
                            clearable={false}
                            labelKey="label"
                            onChange={(option) => this.handleChange("UP_TO", option)}
                            onChangeRaw={this.handleChangeRaw}
                            options={options}
                            searchable={false}
                            value={number || 1}
                            valueKey="id"
                        />
                    </div>
                    <div className="form-text-group">
                        <label htmlFor="" className="control-label">
                            <I18n id="scheduler.lapseTimes" />
                        </label>
                    </div>
                </div>
            </li>
        );
    };

    getOption = (id) => {
        const {
            value: { lapse },
        } = this.props;

        return (
            <React.Fragment>
                <input
                    type="radio"
                    id={id.toLowerCase()}
                    value={id}
                    className="c-control-input"
                    checked={lapse === id}
                    onChange={() => this.handleChange(id)}
                />
                <label className="c-control-label" htmlFor={id.toLowerCase()}>
                    <div className="c-control-icons">
                        <div className="c-control-mark">
                            <Image src="check.svg" className="svg-icon svg-caret" />
                        </div>
                    </div>
                    {id === "UNLIMITED" && <I18n id="scheduler.lapseUnlimited" />}
                </label>
            </React.Fragment>
        );
    };

    handleChange = (id, data) => {
        const { onChange, value } = this.props;

        if (id === "UNLIMITED") {
            onChange({ ...value, lapse: id });
        } else if (id === "UNTIL") {
            onChange({
                ...value,
                date: data || NowFormat("YYYY-MM-DD"),
                lapse: id,
            });
        } else if (id === "UP_TO") {
            onChange({ ...value, lapse: id, number: data ? data.id : 1 });
        }
    };

    handleChangeRaw = (event) => {
        event.preventDefault();
    };

    render() {
        return (
            <div className="form-group">
                <ul className="form-check-group">
                    {this.getUnlimitedOption()}
                    {this.getUntilOption()}
                    {this.getTimesOption()}
                </ul>
            </div>
        );
    }
}

export default Component;
