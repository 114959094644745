import React from "react";

// import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import Container from "~/containers/Internal/Form/Filter";
import { SelectorsAction } from "~/store/filesDownload";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as I18nUtil from "~/util/i18n";

import Box from "~/components/Box";

import Style from "./List.rules.scss";
import Files from "./_Files";
import Filter from "./_Filters";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component({ dispatch, history, lang, isChangingEnvironment }) {
    const wait = false;

    React.useEffect(() => {
        if (!isChangingEnvironment) {
            dispatch(SelectorsAction.getCategoriesRequest());
        }
    }, [dispatch, isChangingEnvironment]);

    return (
        <React.Fragment>
            <Container
                wait={wait}
                name={Style.NAME}
                className={CLASS}
                head-title="list.documents.title"
                head-onBack={handleBack}
                image="header-download-document.svg">
                {wait && <span className={Style.CLASS_NOTE}>{I18nUtil.get("global.loading")}</span>}
                {!wait && (
                    <Box>
                        <Filter lang={lang} />
                        <Files lang={lang} fetching={wait} />
                    </Box>
                )}
            </Container>
        </React.Fragment>
    );

    function handleBack() {
        history.goBack();
    }
}

const mapStateToProps = (store) => ({
    lang: StoreI18n.getLang(store) || window.navigator.language.slice(0, 2),
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
});

export default Connect(mapStateToProps)(Component);
