import { push as Push } from "connected-react-router";

import { Sagas as SagasChannels } from "~/store/administration/common/channels";
import { Sagas as SagasDetails } from "~/store/administration/common/details";
import { Sagas as SagasPermissions } from "~/store/administration/common/permissions";
import { Sagas as SagasSignatures } from "~/store/administration/common/signatures";

import {
    TYPE_ADMINISTRATION_LEVEL_MEDIUM_CHANNELS,
    TYPE_ADMINISTRATION_LEVEL_MEDIUM_DETAILS,
    TYPE_ADMINISTRATION_LEVEL_MEDIUM_PERMISSION,
    TYPE_ADMINISTRATION_LEVEL_MEDIUM_SIGNATURE,
} from "./_consts";
import {
    SelectorsMiddleware,
    SelectorsActionDetailsMedium,
    SelectorsActionPermissionsMedium,
    SelectorsActionChannelsMedium,
    SelectorsActionSignaturesMedium,
} from "./_selectors";

export default [
    ...SagasPermissions(SelectorsMiddleware, TYPE_ADMINISTRATION_LEVEL_MEDIUM_PERMISSION, {
        ...SelectorsActionPermissionsMedium,
        onSuccess: (id) => Push(`/administration/medium/permissions/${id}/confirm`),
    }),
    ...SagasChannels(SelectorsMiddleware, TYPE_ADMINISTRATION_LEVEL_MEDIUM_CHANNELS, {
        ...SelectorsActionChannelsMedium,
        onSuccess: (id) => Push(`/administration/medium/channels/${id}/confirm`),
    }),
    ...SagasDetails(SelectorsMiddleware, TYPE_ADMINISTRATION_LEVEL_MEDIUM_DETAILS, {
        ...SelectorsActionDetailsMedium,
        ...SelectorsActionPermissionsMedium,
        ...SelectorsActionChannelsMedium,
    }),
    ...SagasSignatures(SelectorsMiddleware, TYPE_ADMINISTRATION_LEVEL_MEDIUM_SIGNATURE, {
        ...SelectorsActionSignaturesMedium,
        ...SelectorsActionDetailsMedium,
    }),
];
