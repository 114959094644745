import React from "react";

import PropTypes from "prop-types";
import { Grid, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

import { SelectorsAction as SelectorsActionLoan, SelectorsStore as SelectorsStoreLoan } from "~/store/loan";

import Button from "~/components/Button";
import Head from "~/pages/_components/Head";
import MainContainer from "~/pages/_components/MainContainer";

export const NAME = "MobileFilters";

export const PROP = {
    types: { dispatch: PropTypes.func.isRequired, goBack: PropTypes.func.isRequired },
    defaults: {},
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        const { dispatch, ...rest } = this.props;
        // TODO: revisar
        dispatch(SelectorsActionLoan.readLoan({ idLoan: rest.match.params.id }));
    }

    handleClick = (selectedPredefinedFilter) => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionLoan.setSelectedFilter({ selectedFilter: selectedPredefinedFilter }));
        this.goBack();
    };

    goBack = () => {
        // this.props.history.goBack();
        const { goBack } = this.props;
        goBack();
    };

    render() {
        return (
            <React.Fragment>
                <Head onClose={this.goBack} title="accounts.movements.filters" />
                <MainContainer>
                    <div className="above-the-fold">
                        <section className="container--layout">
                            <Grid>
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col">
                                        <Button
                                            className="btn btn-secondary"
                                            key="all"
                                            label="loans.statements.filters.all"
                                            onClick={() => this.handleClick("allFees")}
                                        />
                                        <Button
                                            className="btn btn-secondary"
                                            key="paidFees"
                                            label="loans.statements.filters.paidFees"
                                            onClick={() => this.handleClick("paidFees")}
                                        />
                                        <Button
                                            className="btn btn-secondary"
                                            key="pendingFees"
                                            label="loans.statements.filters.pendingFees"
                                            onClick={() => this.handleClick("pendingFees")}
                                        />
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </div>
                </MainContainer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    account: SelectorsStoreLoan.getSelectedLoan(state),
});

export default connect(mapStateToProps)(Component);
