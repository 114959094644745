import React from "react";

import ClassNames from "classnames";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import {
    DOT,
    EMPTY_STR,
    ENVIRONMENT_TYPE,
    INVESTMENTS_TYPES,
    LEVEL,
    REACT_APP_VERSION,
    SCOPE,
    TYPE_ADMINISTRATION,
} from "~/constants";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionServicePayment } from "~/store/servicePayments";
import {
    SelectorsStore as StoreSession,
    SelectorsAction as ActionSession,
    PROP as PROP_SESSION,
} from "~/store/session";
import { SelectorsAction as SelectorsActionTransactionLines } from "~/store/transactionLines";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import * as UtilsConfig from "~/util/config";
import { LastLogin } from "~/util/settings";
import { $ } from "~/util/style";

import Container, { Element } from "~/components/Component";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";

import Style from "./_Menu.rules.scss";

export const { NAME, TYPE } = Style;
export const PROP = {
    types: {
        enabled: PropTypes.bool,
        environments: PROP_SESSION.types.environments,
        onDisable: PropTypes.func.isRequired,
        /** The currently active route name, obtained from react-router */
        pathname: PropTypes.string.isRequired,
    },
    defaults: {
        enabled: false,
        environments: PROP_SESSION.defaults.environments,
    },
};

const TOUCH = { start: 0, end: 0 };

export function Component(props) {
    const refTouch = React.useRef(TOUCH);

    const {
        activeEnvironment,
        dispatch,
        enabled,
        environments,
        forms,
        isAdministrator,
        isMobile,
        lang,
        onDisable,
        pathname,
        qrData,
        user,
    } = props;

    const { previousLoginInfo } = user;
    const {
        administrationScheme,
        environmentData: { hasRiskProfile, hasW8Signed },
        isPremierException,
        permissions,
        type,
    } = activeEnvironment;

    const investmentsPermissionsObject = { hasRiskProfilePermission: hasRiskProfile, hasW8Permission: hasW8Signed };

    const {
        accounts = false,
        advancePayments = false,
        automaticDebits = false,
        comexExportRead = false,
        comexGuaranteesRead = false,
        comexImportRead = false,
        creditcards = false,
        creditcardsPurchaseNotification = false,
        customsPayment = false,
        financing = false,
        fundsWithoutW8RiskProfile = false,
        hasAnyCardType = false,
        hasAnyFactoringPermission = false,
        limitIncrease = false,
        loans = false,
        otherInvestments = false,
        payCreditCard = false,
        paySistarbanc = false,
        position = false,
        preferentialTradingPrice: ptp = false,
        requestCheckbook: checkbook = false,
        salaryPayment = false,
        suppliersPayment = false,
        swift = false,
        termDepositCMB = false,
        tripNotice = false,
    } = permissions || {};

    const showAdmin = !isMobile && isAdministrator;
    const showSwift = !isMobile && swift;
    const showPosition = !isMobile && position;
    const showComex =
        !isMobile &&
        type === ENVIRONMENT_TYPE.CORPORATE &&
        !isPremierException &&
        (comexGuaranteesRead || comexImportRead || comexExportRead);
    const showInvestment =
        (type !== ENVIRONMENT_TYPE.CORPORATE || isPremierException) &&
        ((!isMobile && (fundsWithoutW8RiskProfile || otherInvestments)) ||
            (isMobile && ((type !== ENVIRONMENT_TYPE.RETAIL && fundsWithoutW8RiskProfile) || otherInvestments)));
    const showFinancing = !isMobile && financing;
    const showSalaryPayment = !isMobile && salaryPayment;
    const showSuppliersPayment = !isMobile && suppliersPayment;
    const showMobileAdvancePayments = isMobile && advancePayments;
    const showFactoring = (!isMobile && hasAnyFactoringPermission) || showMobileAdvancePayments;
    const showAutomaticDebits = !isMobile && automaticDebits;
    const showCustomsPayment = !isMobile && customsPayment;
    const showTransfersAliasAdministration = type !== ENVIRONMENT_TYPE.CORPORATE;
    const showQrPayment = qrData && qrData.qrEnabled;
    const paymentsSection =
        (showSalaryPayment ||
            showSuppliersPayment ||
            showFactoring ||
            showAutomaticDebits ||
            showQrPayment ||
            paySistarbanc ||
            customsPayment) &&
        (!isMobile || showMobileAdvancePayments || paySistarbanc);
    const { name, productGroupId } = environments[activeEnvironment.id] || {};
    const [activeMenuItem, setActiveMenuItem] = React.useState();

    const Item = (p) => {
        const {
            cleanTransactionsFilters,
            formCleanAll,
            hasInvestmentsPermission,
            investmentsPermission,
            investmentType,
            src,
            titleForm,
            to,
            txt,
            when,
            ...rest
        } = p;
        const active = to === pathname;
        let classActive;

        if (active) {
            setActiveMenuItem(txt);
            classActive = "active";
        }

        if (to === "/financing") {
            classActive = ClassNames(classActive, "financing");
        }

        if (active && titleForm) {
            setActiveMenuItem("menu.otherForms");
            classActive = "active";
        }

        function handleClick(e) {
            if (formCleanAll) {
                dispatch(SelectorsActionForm.cleanAll());
                dispatch(SelectorsActionServicePayment.cleanUp());
                dispatch(SelectorsActionTransactionLines.cleanAll());
            }

            if (cleanTransactionsFilters) {
                dispatch(SelectorsActionTransactions.resetFilter());
            }

            onDisable();

            if (investmentType) {
                const { hasRiskProfilePermission, hasW8Permission } = investmentsPermission;

                if (!hasInvestmentsPermission) {
                    let message;

                    if (investmentType === INVESTMENTS_TYPES.TERM_DEPOSIT) {
                        if (!hasRiskProfilePermission) {
                            message = <I18n id="wm.documentsAboutToExpire.riskProfile.expired" />;
                        }
                    } else if (!hasRiskProfilePermission && !hasW8Permission) {
                        message = <I18n id="wm.documentsAboutToExpire.both.expired" />;
                    } else if (!hasRiskProfilePermission && hasW8Permission) {
                        message = <I18n id="wm.documentsAboutToExpire.riskProfile.expired" />;
                    } else if (hasRiskProfilePermission && !hasW8Permission) {
                        message = <I18n id="wm.documentsAboutToExpire.w8.expired" />;
                    }

                    dispatch(
                        SelectorsActionNotification.showNotification({
                            message,
                            level: LEVEL.ERROR,
                            scopes: Object.values(SCOPE),
                        }),
                    );

                    e.preventDefault();
                }
            }
        }

        return (
            when && (
                <Link {...rest} to={to} className={classActive} onClick={(e) => handleClick(e)} replace={active}>
                    {src && <Image src={src} />}
                    {titleForm ? <span>{titleForm}</span> : <I18n id={txt} />}
                </Link>
            )
        );
    };

    Item.propTypes = { when: PropTypes.bool };
    Item.defaultProps = { when: true };

    const Group = (p) => {
        const { src, txt, children, when, ...rest } = p;
        const id = `menu.group.${txt}`;
        const renderSection = !(when === false);
        const itemTxt = activeMenuItem && activeMenuItem.split(DOT).slice(0, 2).join(DOT);

        return (
            renderSection && (
                <section {...rest} role="group" className={txt === itemTxt ? "group-active" : EMPTY_STR}>
                    <input type="checkbox" id={id} />
                    <label htmlFor={id}>
                        <Image src={src} />
                        <I18n id={txt} />
                    </label>
                    <nav role="navigation" aria-label="secondary">
                        {children}
                    </nav>
                </section>
            )
        );
    };

    const classEnabled = $(enabled && "enabled");
    const lastLogin = !(previousLoginInfo && previousLoginInfo.length)
        ? null
        : {
              date: LastLogin.date(previousLoginInfo[0]),
              place: LastLogin.place(previousLoginInfo[0]),
          };
    const isMediunOrAvanced =
        administrationScheme === TYPE_ADMINISTRATION.MEDIUM || administrationScheme === TYPE_ADMINISTRATION.ADVANCED;
    const isAvanced = administrationScheme === TYPE_ADMINISTRATION.ADVANCED;
    const pathSignatures =
        (administrationScheme === TYPE_ADMINISTRATION.MEDIUM && "/administration/medium/signaturesSchemes/modify") ||
        (administrationScheme === TYPE_ADMINISTRATION.ADVANCED && "/administration/advanced/signaturesSchemes");

    let enabledForms = [];

    if (activeEnvironment.type === ENVIRONMENT_TYPE.PREMIER) {
        enabledForms = UtilsConfig.getArray("menu.forms.premier");
    } else if (activeEnvironment.type === ENVIRONMENT_TYPE.CORPORATE) {
        enabledForms = UtilsConfig.getArray("menu.forms.corporate");
    } else if (activeEnvironment.type === ENVIRONMENT_TYPE.RETAIL) {
        enabledForms = UtilsConfig.getArray("menu.forms.retail");
    }

    const createItemsFromFormsList = () => {
        let menuItems = [];

        Object.entries(forms).forEach((item) => {
            const sectionForms = item[1];
            const sectionName = item[0];

            if (sectionForms && sectionForms.length > 0 && enabledForms.includes(sectionName)) {
                menuItems = menuItems.concat(
                    sectionForms.map((x) => (
                        <Item when={x.category} to={`/form/${x.idForm}`} titleForm={x.name[`${lang}`]} />
                    )),
                );
            }
        });

        return menuItems;
    };

    const formsItems = createItemsFromFormsList();

    return (
        <React.Fragment>
            <Element id={Style.ID_OVERLAY} className={classEnabled} onClick={onDisable} />
            <Container
                tag="aside"
                name={NAME}
                type={TYPE}
                id={Style.ID}
                className={classEnabled}
                onTouchStart={handleDrag}
                onTouchMove={handleDrag}
                onTouchEnd={handleDrag}>
                <header>
                    <h2>{name}</h2>
                    <h3>
                        <I18n id="menu.account.number" /> <span>{productGroupId}</span>
                    </h3>
                </header>

                <nav role="navigation" aria-label="primary">
                    <Item to="/desktop" src="menu-home.svg" txt="menu.desktop" />
                    <Item
                        cleanTransactionsFilters
                        to="/transactions/list"
                        src="transactions-step.svg"
                        txt="menu.transactions"
                    />
                    <Group
                        txt="menu.accounts"
                        src="menu-accounts.svg"
                        when={accounts || ptp || checkbook || termDepositCMB}>
                        <Item when={accounts} to="/accounts" txt="menu.accounts" />
                        <Item when={ptp} to="/preferentialTradingPrice" txt="menu.accounts.preferentialTradingPrice" />
                        <Item when={checkbook} to="/form/requestCheckbook" txt="menu.accounts.requestCheckbook" />
                        <Item when={termDepositCMB} to="/investment/termdeposit" txt="menu.deposits" />
                        <Item
                            when={showTransfersAliasAdministration}
                            to="/transfers/aliasAdministration"
                            txt="menu.transfers.aliasAdministration"
                        />
                    </Group>
                    <Item to="/transfers" src="menu-transfers.svg" txt="menu.transfers" />
                    <Group
                        when={
                            type === ENVIRONMENT_TYPE.RETAIL ||
                            type === ENVIRONMENT_TYPE.PREMIER ||
                            !!isPremierException
                        }
                        txt="menu.loans"
                        src="prestamo.svg">
                        <Item when={loans} to="/loans" txt="menu.loans" />
                        <Item to="/form/requestLoan" txt="menu.loans.requestLoan" />
                    </Group>
                    <Group txt="menu.creditcards" src="menu-cards.svg">
                        {hasAnyCardType && <Item to="/creditCards" txt="menu.creditcards.myCreditcards" />}
                        {creditcards && (
                            <React.Fragment>
                                <Item
                                    when={tripNotice}
                                    to="/tripOrOnlinePurchaseNotification"
                                    txt="menu.creditcards.tripNotice"
                                />
                                <Item
                                    when={creditcardsPurchaseNotification}
                                    to="/purchaseNotification"
                                    txt="menu.creditcards.purchaseNotification"
                                />
                                <Item when={payCreditCard} to="/form/payCreditCard" txt="menu.creditcards.pay" />
                                <Item
                                    when={limitIncrease}
                                    to="/creditCardsLimitIncrease"
                                    txt="menu.creditcards.limitIncrease"
                                />
                            </React.Fragment>
                        )}
                        <Item to="/form/requestCreditCard" txt="menu.creditcards.requestCreditCard" />
                    </Group>
                    {paymentsSection && (
                        <Group txt="menu.payments" src="menu-payments.svg" className="payments">
                            <Item
                                when={showSalaryPayment}
                                to="/form/salaryPayment"
                                txt="menu.payments.salaryPayment"
                                formCleanAll
                            />
                            <Item
                                when={showSuppliersPayment}
                                to="/form/suppliersPayment"
                                txt="menu.payments.suppliersPayment"
                                formCleanAll
                            />
                            <Item
                                when={showCustomsPayment}
                                to="/customsPayment"
                                txt="menu.payments.customsPayment"
                                formCleanAll
                            />
                            <Item
                                when={showAutomaticDebits}
                                to="/automaticDebits"
                                txt="menu.payments.automaticDebits"
                            />
                            <Item
                                when={showFactoring}
                                to={showMobileAdvancePayments ? "/advancePayments/transaction" : "/factoring"}
                                txt="menu.payments.factoring"
                            />
                            <Item when={paySistarbanc} to="/servicePayment" txt="menu.payments.service" formCleanAll />
                            <Item
                                when={showQrPayment}
                                to="/servicePayment/qrModo/list"
                                txt="menu.payments.qrPayment"
                                formCleanAll
                            />
                        </Group>
                    )}
                    {showInvestment && (
                        <Group txt="menu.investment" src="businessPortfolio.svg">
                            {!isMobile && <Item to="/investment" txt="menu.investment.portfolio" />}
                            {type !== ENVIRONMENT_TYPE.RETAIL && fundsWithoutW8RiskProfile && (
                                <Item to="/wm/funds/list" txt="menu.investment.funds" />
                            )}
                            <Item
                                when={otherInvestments}
                                hasInvestmentsPermission={hasRiskProfile}
                                investmentsPermission={investmentsPermissionsObject}
                                investmentType={INVESTMENTS_TYPES.TERM_DEPOSIT}
                                to="/investment/termdeposit"
                                txt="menu.deposits"
                            />
                            {!isMobile && (
                                <Item
                                    when={otherInvestments}
                                    hasInvestmentsPermission={hasW8Signed && hasRiskProfile}
                                    investmentsPermission={investmentsPermissionsObject}
                                    investmentType={INVESTMENTS_TYPES.BONDS}
                                    to="/wm/bonds/buy"
                                    txt="menu.bonds"
                                />
                            )}
                            {!isMobile && (
                                <Item
                                    when={otherInvestments}
                                    hasInvestmentsPermission={hasW8Signed && hasRiskProfile}
                                    investmentsPermission={investmentsPermissionsObject}
                                    investmentType={INVESTMENTS_TYPES.SHARES}
                                    to="/wm/shares/buy"
                                    txt="menu.shares"
                                />
                            )}
                            <Item
                                when={isAdministrator}
                                to="/investment/riskProfileResume"
                                txt="menu.investment.riskProfile"
                            />
                            {!isMobile && <Item to="/investment/requestInfo" txt="menu.investment.requestInfo" />}
                        </Group>
                    )}

                    <Item when={showComex} to="/comex" src="comex.svg" txt="menu.comex" />
                    <Item when={showPosition} to="/position" src="menu-position.svg" txt="menu.position" />
                    <Item when={showFinancing} to="/financing" src="financing.svg" txt="menu.financing" />
                    <Item when={showSwift} to="/swift" src="swift.svg" txt="menu.swift" />
                    {formsItems.length > 0 && (
                        <Group txt="menu.otherForms" src="menu-forms.svg">
                            {formsItems}
                        </Group>
                    )}
                    <Item to="/settings" src="menu-settings.svg" txt="menu.settings" />
                    <Item to="/mapLocations" src="sucursales-cajeros-menu.svg" txt="menu.atms" />
                    {showAdmin && (
                        <Group txt="menu.administration" src="administration-title-icon.svg" className="administration">
                            <Item when to="/administration/users" txt="menu.administration.users" />
                            <Item
                                when={isAvanced}
                                to="/administration/advanced/groups"
                                txt="menu.administration.groups"
                            />
                            <Item
                                when={isMediunOrAvanced}
                                to={pathSignatures}
                                txt="administration.menu.signatureScheme"
                            />
                        </Group>
                    )}
                    <button type="button" onClick={handleLogout}>
                        <Image src="cerrarSesion.svg" />
                        <I18n id="global.logout" />
                    </button>
                </nav>

                <footer>
                    <h5>
                        <I18n id="settings.lastLogin.date" />
                        <time>{lastLogin ? lastLogin.date : "10/20/2001 15:00"}</time>
                    </h5>
                    <h6>
                        <small>
                            <I18n id="global.version" /> <span>{REACT_APP_VERSION}</span>
                        </small>
                    </h6>
                </footer>
            </Container>
        </React.Fragment>
    );

    function handleDrag(e) {
        if (e.type === "touchstart") {
            const [{ clientX }] = e.touches;

            refTouch.current = { start: clientX, end: clientX };
        } else if (e.type === "touchmove") {
            const [{ clientX }] = e.touches;

            refTouch.current.end = clientX;
        } else {
            const { start, end } = refTouch.current;

            if (start / end >= 1.5) {
                onDisable();
            }
        }
    }

    function handleLogout() {
        return dispatch(ActionSession.logout());
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    activeEnvironment: StoreSession.getActiveEnvironment(store) || {},
    environments: StoreSession.getEnvironments(store),
    forms: StoreSession.getForms(store),
    isAdministrator: StoreSession.isAdministrator(store),
    pathname: store.router.location.pathname,
    qrData: StoreSession.getQrData(store),
    user: StoreSession.getUser(store) || {},
    lang: SelectorsStoreI18n.getLang(store),
});

export default HighOrder(Connect(mapStateToProps), HighOrder.Resizable)(Component);
