import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";

import I18n from "~/components/I18n";

import MessageActions from "~/pages/communications/_components/MessageActions";

export const NAME = "MessageItem";

export const PROP = {
    types: {
        itemRef: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        communication: PropTypes.shape({
            idCommunication: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            userRead: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
            communicationTray: PropTypes.shape({
                name: PropTypes.string,
            }),
            sentDateAsString: PropTypes.string,
            subject: PropTypes.string,
            body: PropTypes.string,
        }),
        index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        handleChangeMessageStatus: PropTypes.func.isRequired,
        handleSelectMessageClick: PropTypes.func.isRequired,
    },
    defaults: { itemRef: null, communication: {}, index: null },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    render() {
        const { itemRef, communication, index, handleChangeMessageStatus, handleSelectMessageClick } = this.props;

        const { idCommunication, userRead, idThread, sentDateAsString, subject, body } = communication;

        const formattedBody = body.length > 100 ? `${body.substring(0, 100)}...` : body;

        return (
            <li
                ref={itemRef}
                onClick={() => handleSelectMessageClick(idCommunication, idThread, userRead, index)}
                key={idCommunication}
                className={userRead ? "read-message" : EMPTY_STR}>
                <div className="message-item">
                    <div className="flex-container">
                        <I18n
                            component="span"
                            className="data-aux"
                            id={`communications.tray.${communication.idCommunicationTray}`}
                        />
                        <span className="data-date">{sentDateAsString}</span>
                    </div>

                    <div className="flex-container">
                        <div className="ellipsis">
                            <h4 className="message-title">{subject}</h4>
                            <p
                                className="data-desc list-content ellipsis"
                                dangerouslySetInnerHTML={{
                                    __html: formattedBody,
                                }}
                            />
                        </div>
                        <MessageActions
                            communication={communication}
                            handleChangeMessageStatus={handleChangeMessageStatus}
                            index={index}
                        />
                    </div>
                </div>
            </li>
        );
    }
}

export default Component;
