import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    const {
        credentialGroups,
        idActivity,
        idTransaction,
        data: {
            countryList,
            defaultCountry,
            document,
            documentTypeList,
            email,
            firstName,
            groupList,
            lastName,
            mobilePhone,
            newUser,
            roleList,
        } = {},
        document: nonEncryptedDocument,
        params,
    } = action;

    switch (action.type) {
        case TYPE.ADMINISTRATION_USER_INVITE_PRE_REQUEST:
        case TYPE.ADMINISTRATION_USER_INVITE_PREVIEW_FAILED:
            return {
                ...storeProp,
            };
        case TYPE.ADMINISTRATION_USER_INVITE_VERIFY_FAILURE:
        case TYPE.ADMINISTRATION_USER_INVITE_SEND_FAILURE:
        case TYPE.ADMINISTRATION_USER_INVITE_FINISH:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.ADMINISTRATION_USER_INVITE_PRE_REQUEST_SUCCESS:
            return {
                ...storeProp,
                countryList,
                defaultCountry,
                defaultDocumentType: documentTypeList.filter(
                    (documentType) => documentType.id_country_code === defaultCountry,
                )[0].id_document_type,
                documentTypeList,
                fetching: false,
            };
        case TYPE.ADMINISTRATION_USER_INVITE_VERIFY:
            return {
                ...storeProp,
                selectedDocument: nonEncryptedDocument,
                fetching: true,
            };
        case TYPE.ADMINISTRATION_USER_INVITE_SEND:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.ADMINISTRATION_USER_INVITE_VERIFY_SUCCESS:
            return {
                ...storeProp,
                encryptedDocument: document,
                existentUserInfo:
                    firstName && lastName
                        ? {
                              email,
                              firstName,
                              lastName,
                              mobilePhone,
                          }
                        : null,
                fetching: false,
                groupList,
                isInvitingNewUser: newUser,
                roleList,
            };
        case TYPE.ADMINISTRATION_USER_INVITE_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                confirmationParams: params,
                idTransaction,
                idActivity,
                credentialGroups,
            };
        default:
            return {
                ...storeProp,
            };
    }
};
