import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, SPACE_STR, ZERO_NUMBER } from "~/constants";
import {
    SelectorsAction as SelectorsActionServicePayments,
    SelectorsStore as SelectorsStoreServicePayment,
} from "~/store/servicePayments";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import * as UtilsDate from "~/util/date";
import DeviceUtils from "~/util/device";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import DataNumber from "~/components/DataNumber";
import ExpandElement from "~/components/ExpandElement";
import GridLayout from "~/components/GridLayout";
import I18n from "~/components/I18n";
import Ticket from "~/components/Ticket/Ticket";
import FormattedRate from "~/pages/_components/FormattedRate";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const FORM_ID = "service.payment";

    const isMobile = DeviceUtils.isDisplayMobile();

    const {
        debitAccountAlias,
        dispatch,
        isSameCurrency,
        rateFirstCurrency,
        rateSecondCurrency,
        rate,
        sistarbancValidateResult,
        ticketConfirmation,
        transaction,
    } = props;

    const { data } = transaction || {};
    const { amount: totalDebitAmount, organismDescription, serviceTypeDescription, formData } = data;
    const { billsToPay, checkboxes } = formData || {};
    const hasSameCurrency = isSameCurrency || data?.isSameCurrency;
    const totalDiscount = sistarbancValidateResult?.vatRefundAmount || data.sistarbancValidateResult?.vatRefundAmount;
    const totalCurrency = sistarbancValidateResult?.currency || data.sistarbancValidateResult?.currency;
    const account = debitAccountAlias || data?.debitAccountAlias;
    const service = `${organismDescription} - ${serviceTypeDescription}`;
    const bills = checkboxes?.map((checkbox) => ({
        idDocument: billsToPay[checkbox]?.idDocument,
        description: billsToPay[checkbox].description,
        year: billsToPay[checkbox].year,
        dueDate: billsToPay[checkbox].dueDate,
        amount: billsToPay[checkbox].amount,
        currency: billsToPay[checkbox].currency,
    }));
    const rateToRender = rate || data?.rate;
    const rateFirstCurrencyToRender = rateFirstCurrency || data?.rateFirstCurrency;
    const rateSecondCurrencyToRender = rateSecondCurrency || data?.rateSecondCurrency;

    const COLUMN_HEADINGS_WEB = [
        UtilsI18n.get(`${FORM_ID}.heading.id`),
        UtilsI18n.get(`${FORM_ID}.heading.detail`),
        UtilsI18n.get(`${FORM_ID}.heading.dueDate`),
        UtilsI18n.get(`${FORM_ID}.heading.amount`),
    ];

    const COLUMN_HEADINGS_MOBILE = [
        UtilsI18n.get(`${FORM_ID}.heading.dueDate`),
        UtilsI18n.get(`${FORM_ID}.heading.amount`),
    ];

    const renderMobileBills = ({ amount, currency, description, dueDate, idDocument }) => {
        return (
            <ExpandElement>
                <GridLayout className="body">
                    <Box>{UtilsDate.specificDate(dueDate)}</Box>
                    <Box className="justify-end">
                        <DataNumber value={amount} prefix={currency} />
                    </Box>
                </GridLayout>
                <Box className="details">
                    <Box className="item-details">
                        <I18n id={`${FORM_ID}.service.label.id`} />
                        <Box className="hightlight">{idDocument}</Box>
                    </Box>
                    {description && (
                        <Box className="item-details">
                            <I18n id={`${FORM_ID}.service.label.description`} />
                            <Box className="hightlight">
                                <span>{description}</span>
                            </Box>
                        </Box>
                    )}
                </Box>
            </ExpandElement>
        );
    };

    const renderWebBills = ({ idDocument, currency, description, dueDate, amount }) => {
        return (
            <GridLayout className="body">
                <Box className="item-block">{idDocument}</Box>
                {description ? (
                    <Box className="item-block">
                        <Box className="ellipsis">{description}</Box>
                    </Box>
                ) : (
                    <Box>{SPACE_STR}</Box>
                )}
                <Box>{UtilsDate.specificDate(dueDate)}</Box>
                <Box className="justify-end">
                    <DataNumber value={amount} prefix={currency} />
                </Box>
            </GridLayout>
        );
    };

    const shareTicket = (idTransactionToRead) => {
        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    };

    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}
                    hasMobileComponent
                    displayDownloadPDF
                    handleDownloadPDF={() => shareTicket(transaction.idTransaction)}>
                    <Box directionColumn align="flex-start" className="mb-3 data-ticket">
                        <Box className="data-title">
                            <I18n id="service.payment.step3.title.data" />
                        </Box>
                        <Box className="data-wrapper">
                            <I18n className="data-label" id="service.payment.step3.title.service" />
                            <span className="data-name">{service}</span>
                        </Box>
                        <Box className="data-wrapper">
                            <I18n className="data-label" id="service.payment.step3.title.debitAccount" />
                            <span className="data-name">{account}</span>
                        </Box>
                        {totalDiscount > ZERO_NUMBER && (
                            <Box className="data-wrapper">
                                <I18n className="data-label" id="service.payment.step3.title.discounts" />
                                <span className="data-amount">
                                    <DataNumber value={totalDiscount} prefix={totalCurrency} />
                                </span>
                            </Box>
                        )}
                        {!hasSameCurrency && (
                            <Box className="data-wrapper container-rate">
                                <I18n className="data-label" id="service.payment.step3.title.rate" />
                                <FormattedRate
                                    firstCurrency={rateFirstCurrencyToRender}
                                    secondCurrency={rateSecondCurrencyToRender}
                                    quantity={rateToRender}
                                />
                            </Box>
                        )}
                        <Box className="data-wrapper">
                            <I18n className="data-label" id="service.payment.step3.title.totalAmount" />
                            <span className="data-amount">
                                <DataNumber value={totalDebitAmount.quantity} prefix={totalDebitAmount.currency} />
                            </span>
                        </Box>
                        <Box className="data-title">
                            <I18n className="data-label" id="service.payment.title.billsPaid" />
                        </Box>
                        <GridLayout className="header">
                            {!isMobile &&
                                COLUMN_HEADINGS_WEB.map((heading, index) => (
                                    <Box justify={index === 3 ? "end" : EMPTY_STR} className="web-only">
                                        {heading}
                                    </Box>
                                ))}
                            {isMobile &&
                                COLUMN_HEADINGS_MOBILE.map((heading, index) => (
                                    <Box justify={index === 1 ? "end" : EMPTY_STR}>{heading}</Box>
                                ))}
                        </GridLayout>
                        {bills.map((movement, index) => {
                            return !isMobile ? renderWebBills(movement, index) : renderMobileBills(movement, index);
                        })}
                    </Box>
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { idTransaction } = transaction;

        dispatch(
            SelectorsActionServicePayments.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        dispatch(SelectorsActionServicePayments.signTransactionPreview({ data }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        debitAccountAlias: SelectorsStoreServicePayment.getDebitAccountAlias(store),
        formData: SelectorsStoreServicePayment.getFormData(store),
        rate: SelectorsStoreServicePayment.getRate(store),
        rateFirstCurrency: SelectorsStoreServicePayment.getRateFirstCurrency(store),
        rateSecondCurrency: SelectorsStoreServicePayment.getRateSecondCurrency(store),
        sistarbancValidateResult: SelectorsStoreServicePayment.getSistarbancValidateResult(store),
        transaction: SelectorsStoreServicePayment.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
