import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import { string } from "prop-types";
import { withRouter } from "react-router-dom";
import Namespace from "./Image.scss";
import UtilsDevice, { DEVICE_MOBILE } from "~/util/device";

export const { NAME, TYPE } = Namespace;

class WidgetImage extends Component {
    static propTypes = {
        src: string.isRequired,
        className: string,
        wrapperClassName: string,
    };

    static defaultProps = {
        className: "svg-image",
        wrapperClassName: "svg-wrapper",
    };

    render() {
        const { isMobileNative, src, className, wrapperClassName, ...rest  } = this.props;

        const path = src.replace("assets/", "").replace(/^\//, "");
        let fullPath = require(`~/assets/${path}`);
        if (UtilsDevice.isMobileNative() && fullPath.indexOf("/") === 0) {
            fullPath = fullPath.slice(1);
        }
        
        return (
            <div data-type={TYPE} data-name={NAME}  className={wrapperClassName} {...rest}>
            <div className={className}>
                <ReactSVG src={fullPath} />
            </div>
        </div>
        );
    }
}

export default withRouter(WidgetImage);
