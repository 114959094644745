import React from "react";

import ClassNames from "classnames";
import PropTypes from "prop-types";

import UtilsDevice from "~/util/device";

import Select from "~/components/Select";

export const NAME = "SelectorInput";

export const PROP = {
    types: {
        inputProps: PropTypes.shape({
            name: PropTypes.string.isRequired,
            placeholder: PropTypes.string,
            value: PropTypes.string,
        }).isRequired,
        isFocused: PropTypes.bool.isRequired,
        mobilePhoneFieldStyled: PropTypes.bool,
        onBlur: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        selectProps: PropTypes.shape({
            name: PropTypes.string.isRequired,
            options: PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.string.isRequired,
                    value: PropTypes.string.isRequired,
                }),
            ),
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        }).isRequired,
        toggleIsFocused: PropTypes.func.isRequired,
    },
    defaults: { onBlur: () => {} },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    inputRef = React.createRef();

    inputGroupRef = React.createRef();

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick, false);
    }

    handleClick = ({ target }) => {
        const { isFocused, onBlur, toggleIsFocused } = this.props;

        if (this.inputGroupRef.current.contains(target) && !isFocused) {
            toggleIsFocused();
        }

        if (!this.inputGroupRef.current.contains(target) && isFocused) {
            toggleIsFocused();

            onBlur();
        }
    };

    render() {
        const { inputMaxLength, inputProps, mobilePhoneFieldStyled, onBlur, onChange, selectProps } = this.props;
        const { type: inputType } = inputProps;

        const onBlurFunction = UtilsDevice.isMobileNative() ? onBlur : undefined;

        return (
            <div className={ClassNames("input-group", { mobilePhoneFieldStyled })} ref={this.inputGroupRef}>
                <Select
                    className="slideFromBottom flex-container verticalBorder"
                    clearable={false}
                    onChange={(selectValue) => onChange(selectValue, inputProps.value)}
                    placeholder={inputProps?.placeholder}
                    searchable={false}
                    {...selectProps}
                />
                <input
                    className="form-control"
                    maxLength={inputMaxLength}
                    onBlur={onBlurFunction}
                    ref={this.inputRef}
                    type={inputType || "text"}
                    {...inputProps}
                    onChange={({ target }) => onChange(selectProps.value, target.value)}
                />
            </div>
        );
    }
}

export default Component;
