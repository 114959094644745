import React from "react";

import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import ReactTagsInput from "react-tagsinput";

export const NAME = "TagsInput";

export const PROP = {
    types: {
        value: PropTypes.arrayOf(PropTypes.string).isRequired,
        getSuggestions: PropTypes.func.isRequired,
        onValidationReject: PropTypes.func.isRequired,
        shouldRenderSuggestions: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        renderSuggestion: PropTypes.func,
        getSuggestionValue: PropTypes.func,
        validationRegex: PropTypes.instanceOf(RegExp),
        placeholder: PropTypes.string,
        disabled: PropTypes.bool,
        isTextValid: PropTypes.func,
        maxTags: PropTypes.number,
    },
    defaults: {
        getSuggestionValue: (suggestion) => suggestion,
        renderSuggestion: (suggestion) => <span>{suggestion}</span>,
        placeholder: "",
        disabled: false,
        validationRegex: new RegExp(),
        isTextValid: null,
        maxTags: -1,
    },
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    input = React.createRef();

    handleClick = () => {
        this.input.current.input.focus();
    };

    emptyBoundFunction = () => {};

    autocompleteRenderInput = ({ addTag, ...props }) => {
        const {
            renderSuggestion,
            shouldRenderSuggestions,
            getSuggestions,
            getSuggestionValue,
            placeholder,
            isTextValid,
        } = this.props;

        return (
            <Autosuggest
                ref={this.input}
                suggestions={getSuggestions(props.value)}
                shouldRenderSuggestions={shouldRenderSuggestions}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={{
                    ...props,
                    placeholder,
                    onBlur: (event) => {
                        const newValue = event.target.defaultValue;
                        addTag(newValue);
                    },
                    onChange: (event, { newValue, method }) => {
                        if (method === "enter") {
                            event.preventDefault();
                        } else if (!isTextValid || isTextValid(newValue)) {
                            props.onChange(event);
                        }
                    },
                }}
                onSuggestionSelected={(e, { suggestion }) => addTag(suggestion)}
                onSuggestionsFetchRequested={this.emptyBoundFunction}
                onSuggestionsClearRequested={this.emptyBoundFunction}
            />
        );
    };

    handlePaste = (data) =>
        data
            .replace(/[\r\n,;]/g, " ")
            .split(" ")
            .map((d) => d.trim());

    renderTag = ({ tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other }) => (
        <span key={key} {...other}>
            <span>{getTagDisplayValue(tag)}</span>
            {!disabled && <button type="button" className={classNameRemove} onClick={() => onRemove(key)} />}
        </span>
    );

    render() {
        const { value, disabled, onChange, validationRegex, onValidationReject, maxTags, maxLength } = this.props;
        return (
            <div className="input-group" onClick={this.handleClick}>
                <ReactTagsInput
                    renderInput={this.autocompleteRenderInput}
                    pasteSplit={this.handlePaste}
                    renderTag={this.renderTag}
                    onChange={onChange}
                    value={value}
                    disabled={disabled}
                    validationRegex={validationRegex}
                    onValidationReject={onValidationReject}
                    addOnBlur
                    addOnPaste
                    maxTags={maxTags}
                    inputProps={{ maxLength }}
                />
            </div>
        );
    }
}

export default Component;
