import React from "react";

import { go as Go, push as Push } from "connected-react-router";
import { Form, withFormik as WithFormik, Field } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import {
    DEFAULT_COUNTRY,
    DEFAULT_OPTION_ADDRESS,
    EMPTY_STR,
    INVALID_TOKEN,
    IS_COUNTRY_ACTIVE,
    NO_DATA,
    LEVEL,
    SCOPE,
    TYPE_DATA_ACCOUNTS,
    ZERO,
} from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Administration/Simple";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsStore as SelectorsStoreSettings, SelectorsAction as SelectorsActionSettings } from "~/store/settings";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button/Button";
import ExpandElement from "~/components/ExpandElement";
import I18n from "~/components/I18n";
import Token from "~/components/Token/Token";
import IndeterminateCheckbox from "~/pages/_components/IndeterminateCheckbox";
import FieldReadOnly from "~/pages/_components/fields/FieldReadOnly";

import Style from "./ChangeDataAccountsForm.rules.scss";
import FormGeneric from "./FormGeneric";

export const NAME = "ChangeDataAccountsForm";
const FORM_ID = "change.PersonalData";

export const PROP = {
    types: {
        ...TypesRedux,
        accountDataForceUpdate: PropTypes.bool,
        addressesUser: PropTypes.array.isRequired,
        cityListLegal: PropTypes.array.isRequired,
        cityListMailings: PropTypes.array.isRequired,
        countryList: PropTypes.array.isRequired,
        fetching: PropTypes.bool,
        infoUser: PropTypes.object.isRequired,
        isSubmitting: PropTypes.bool,
        legal: PropTypes.object.isRequired,
        loadData: PropTypes.array.isRequired,
        mailings: PropTypes.object.isRequired,
        setFieldValue: PropTypes.func,
        townListLegal: PropTypes.array.isRequired,
        townListMailings: PropTypes.array.isRequired,
        values: PropTypes.object,
    },
    defaults: {
        ...DefaultsRedux,
        accountDataForceUpdate: false,
        fetching: false,
        isSubmitting: false,
        setFieldValue: () => {},
        values: {},
    },
};

export function Component({
    accountDataForceUpdate,
    addressesUser,
    cityListLegal,
    cityListMailings,
    countryList,
    dispatch,
    infoUser,
    fetching,
    isChangeForm,
    isSubmitting,
    legal,
    loadData,
    mailings,
    setFieldValue,
    townListLegal,
    townListMailings,
    values,
    ...rest
}) {
    const [valueCheckbox, setValueCheckbox] = React.useState(false);
    const [openInit, setOpenInit] = React.useState(true);

    const { name, email } = infoUser;

    React.useLayoutEffect(() => {
        dispatch(SelectorsActionSettings.modifyFormDataAccountsRequest());
        dispatch(SelectorsActionSettings.modifyDateAccountsRequest());
        return () => {
            dispatch(SelectorsActionSettings.userDataAccountsClean());
        };
    }, [dispatch]);

    React.useEffect(() => {
        setValueCheckbox(mailings?.sendingAccountStatement);
    }, [mailings]);

    const handleBack = React.useCallback(() => {
        dispatch(Go(-2));
    }, [dispatch]);

    const { errors } = rest;

    const errorLegal = Object.keys(errors)?.filter((error) => error?.includes(TYPE_DATA_ACCOUNTS.LEGAL))?.length > 0;
    const errorMailings =
        Object.keys(errors)?.filter((error) => error?.includes(TYPE_DATA_ACCOUNTS.MAILINGS))?.length > 0;

    React.useEffect(() => {
        const fieldLegal = Object.keys(values)?.filter((field) => field?.includes(TYPE_DATA_ACCOUNTS.LEGAL));
        const isChange = fieldLegal?.filter((key) => rest.initialValues[key] !== values[key]);

        if (isChange.length > 0 && !isChangeForm) {
            dispatch(SelectorsActionSettings.changeFormLegal());
        } else if (isChange.length === 0 && isChangeForm) {
            dispatch(SelectorsActionSettings.noChangeFormLegal());
        }
    }, [dispatch, isChangeForm, legal, rest.initialValues, values]);

    React.useEffect(() => {
        if (Object.keys(errors).length > 0) {
            dispatch(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get(`forms.fieldsErrors`),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.PERSONAL_INFORMATION],
                }),
            );
        }
    }, [dispatch, errors]);

    const onChangeEmail = () => {
        dispatch(Push("/settings/changeDataAccounts/changeEmail"));
    };

    return (
        <Container
            name={NAME}
            head-onBack={!accountDataForceUpdate && handleBack}
            wait={isSubmitting || fetching}
            head-title="change.data.accounts.title"
            scopeToShowNotification={SCOPE.PERSONAL_INFORMATION}>
            <Form>
                <div id={Style.ID}>
                    <div className="container-information">
                        <div className="containerinformation--data__name  containerinformation--data__spacing">
                            <span>{name}</span>
                        </div>
                        <div className="containerinformation--data__email containerinformation--data__spacing">
                            <FieldReadOnly value={email || NO_DATA} label="change.PersonalData.email.label" />
                            <Button type="button" size="sm" onClick={onChangeEmail}>
                                <I18n id="change.PersonalData.email.label.button" />
                            </Button>
                        </div>
                    </div>

                    <div className="change--data--form">
                        <ExpandElement openItem={errorLegal} onClick={() => setOpenInit(false)} openInit={openInit}>
                            <div className="change--data--form--collapse">
                                <I18n id={`change.PersonalData.title.${TYPE_DATA_ACCOUNTS.LEGAL}`} />
                            </div>
                            <div className="change--data--form--generic" onClick={(event) => event.stopPropagation()}>
                                <FormGeneric
                                    typeAddresses={TYPE_DATA_ACCOUNTS.LEGAL}
                                    infoForm={legal}
                                    cityList={cityListLegal}
                                    townList={townListLegal}
                                    addressesUser={addressesUser}
                                    infoLoadData={mailings}
                                    countryList={countryList}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    attachFileVisible
                                    {...rest}
                                />
                            </div>
                        </ExpandElement>
                    </div>

                    <div className="change--data--form">
                        <ExpandElement openItem={errorMailings} openInit={openInit}>
                            <div className="change--data--form--collapse">
                                <I18n id={`change.PersonalData.title.${TYPE_DATA_ACCOUNTS.MAILINGS}`} />
                            </div>
                            <div className="change--data--form--generic" onClick={(event) => event.stopPropagation()}>
                                <FormGeneric
                                    typeAddresses={TYPE_DATA_ACCOUNTS.MAILINGS}
                                    infoForm={mailings}
                                    cityList={cityListMailings}
                                    townList={townListMailings}
                                    addressesUser={addressesUser}
                                    loadData={loadData}
                                    infoLoadData={legal}
                                    countryList={countryList}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    loadDataIsVisible
                                    {...rest}
                                />
                            </div>
                        </ExpandElement>
                    </div>

                    <div className="change--data--form--mailings">
                        <div className="check" onClick={(event) => event.stopPropagation()}>
                            <Field
                                idForm={FORM_ID}
                                name="sendInformation"
                                autoFocus={false}
                                mode={MODE.EDIT}
                                hidePlaceholder>
                                {(propsField) => (
                                    <IndeterminateCheckbox
                                        id={FORM_ID}
                                        onCheckClick={() => {
                                            setValueCheckbox(!valueCheckbox);
                                            propsField.form.setFieldValue("sendInformation", !valueCheckbox);
                                        }}
                                        selectedOptionsAmount={valueCheckbox}
                                        label={UtilsI18n.get("change.PersonalData.send.information")}
                                        {...propsField}
                                    />
                                )}
                            </Field>
                        </div>
                        <div className="terms">
                            <div className="terms-block">
                                <div className="contract-block-content">
                                    <I18n id="client.envconfiguration.index.mailings.message" />
                                </div>
                            </div>
                        </div>
                        <div className="enrollment-content-dialog">
                            <span className="enrollment-content-dialog-text" />
                        </div>
                    </div>

                    <div className="change--data--form--token change--data--form__generic">
                        <div className="change--data--form--token--title">
                            <I18n id="title.form.token" />
                        </div>
                        <Token labelButton="global.send" isSubmitting={isSubmitting} {...rest}>
                            <Button
                                type="button"
                                size="sm"
                                variant="secondary"
                                disabled={isSubmitting}
                                className="form--personal_information--bottom--cancel"
                                onClick={handleBack}>
                                <I18n id="global.cancel" />
                            </Button>
                        </Token>
                    </div>
                </div>
            </Form>
        </Container>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Compose(
    Connect((store) => ({
        accountDataForceUpdate: SelectorsStoreSession.getAccountDataForceUpdate(store),
        addressesUser: SelectorsStoreSettings.getAddressesUser(store),
        cityListLegal: SelectorsStoreSettings.getCityListLegal(store),
        cityListMailings: SelectorsStoreSettings.getCityListMailings(store),
        countryList: SelectorsStoreSettings.getCountryList(store),
        infoUser: SelectorsStoreSettings.getInfoUser(store),
        fetching: SelectorsStoreSettings.getFetching(store),
        isChangeForm: SelectorsStoreSettings.isChangeForm(store),
        legal: SelectorsStoreSettings.getLegal(store),
        loadData: SelectorsStoreSettings.getLoadData(store),
        mailings: SelectorsStoreSettings.getCorrespondense(store),
        townListLegal: SelectorsStoreSettings.getTownListLegal(store),
        townListMailings: SelectorsStoreSettings.getTownListMailings(store),
        user: SelectorsStoreSession.getUser(store),
    })),
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: ({ legal, mailings }) => ({
            aptoLegal: legal?.apto || EMPTY_STR,
            aptoMailings: mailings?.apto || EMPTY_STR,
            cityLegal: Number(legal?.idCity) || DEFAULT_OPTION_ADDRESS,
            cityMailings: Number(mailings?.idCity) || DEFAULT_OPTION_ADDRESS,
            countryLegal: legal?.idCountry?.toString() || EMPTY_STR,
            countryMailings: mailings?.idCountry?.toString() || DEFAULT_COUNTRY,
            departamentLegal: Number(legal?.idDepartament) || DEFAULT_OPTION_ADDRESS,
            departamentMailings: Number(mailings?.idDepartament) || DEFAULT_OPTION_ADDRESS,
            doorNumberLegal: legal?.doorNumber || EMPTY_STR,
            doorNumberMailings: mailings?.doorNumber || EMPTY_STR,
            observationsLegal: legal?.observations || EMPTY_STR,
            observationsMailings: mailings?.observations || EMPTY_STR,
            otherCityLegal: (legal?.idCity === ZERO && legal?.city) || UtilsI18n.get("global.montevideo"),
            otherCityMailings: (mailings?.idCity === ZERO && mailings?.city) || UtilsI18n.get("global.montevideo"),
            otherDepartamentLegal: (legal?.idDepartament === ZERO && legal?.departament) || EMPTY_STR,
            otherDepartamentMailings: (mailings?.idDepartament === ZERO && mailings?.departament) || EMPTY_STR,
            otp: EMPTY_STR,
            sendInformation: mailings?.sendingAccountStatement,
            streetLegal: legal?.street || EMPTY_STR,
            streetMailings: mailings?.street || EMPTY_STR,
            zipCodeLegal: legal?.zipCode || EMPTY_STR,
            zipCodeMailings: mailings?.zipCode || EMPTY_STR,
        }),
        handleSubmit: (data, formikBag) => {
            const { cityListLegal, cityListMailings, dispatch, idUser, townListLegal, townListMailings } =
                formikBag.props;

            formikBag.setSubmitting(false);

            const {
                aptoLegal,
                aptoMailings,
                cityLegal,
                cityMailings,
                countryLegal,
                countryMailings,
                departamentLegal,
                departamentMailings,
                doorNumberLegal,
                doorNumberMailings,
                files,
                observationsLegal,
                observationsMailings,
                otherCityLegal,
                otherCityMailings,
                otherDepartamentLegal,
                otherDepartamentMailings,
                otp,
                sendInformation,
                streetLegal,
                streetMailings,
                zipCodeLegal,
                zipCodeMailings,
            } = data;

            const countryValueLegal = countryLegal?.toString().length === 0 ? ZERO : countryLegal?.toString();
            const cityValueLegal = departamentLegal?.toString().length === 0 ? ZERO : departamentLegal?.toString();
            const cityNameValueLegal =
                cityValueLegal === ZERO
                    ? otherDepartamentLegal
                    : cityListLegal?.find((c) => c?.id.toString() === cityValueLegal)?.name;
            const towsValueLegal = cityLegal?.toString().length === 0 ? ZERO : cityLegal?.toString();
            const towsNameValueLegal =
                towsValueLegal === ZERO || cityValueLegal === ZERO
                    ? otherCityLegal
                    : townListLegal?.find((c) => c?.id.toString() === towsValueLegal)?.name;
            const countryValueMailings = countryMailings?.toString().length === 0 ? ZERO : countryMailings?.toString();
            const cityValueMailings =
                departamentMailings?.toString().length === 0 ? ZERO : departamentMailings?.toString();
            const cityNameValueMailings =
                cityValueMailings === ZERO
                    ? otherDepartamentMailings
                    : cityListMailings?.find((c) => c?.id.toString() === cityValueMailings)?.name;
            const towsValueMailings = cityMailings?.toString().length === 0 ? ZERO : cityMailings?.toString();
            const towsNameValueMailings =
                towsValueMailings === ZERO || cityValueMailings === ZERO
                    ? otherCityMailings
                    : townListMailings?.find((c) => c?.id.toString() === towsValueMailings)?.name;

            const infoUserModify = {
                idUserToModify: idUser,
                legalAddressApartment: aptoLegal,
                legalAddressAttachments: files?.idFile,
                legalAddressAttachmentsNames: files?.fileName,
                legalAddressCity: cityValueLegal,
                legalAddressCityName: cityNameValueLegal,
                legalAddressCountry: countryValueLegal,
                legalAddressNumber: doorNumberLegal,
                legalAddressObservations: observationsLegal,
                legalAddressOtherCity: otherDepartamentLegal,
                legalAddressOtherTown: otherCityLegal,
                legalAddressPostalCode: zipCodeLegal,
                legalAddressStreet: streetLegal,
                legalAddressTownName: towsNameValueLegal,
                legalAddressTown: towsValueLegal,
                mailingsAddressApartment: aptoMailings,
                mailingsAddressCity: cityValueMailings,
                mailingsAddressCityName: cityNameValueMailings,
                mailingsAddressCountry: countryValueMailings,
                mailingsAddressNumber: doorNumberMailings,
                mailingsAddressObservations: observationsMailings,
                mailingsAddressOtherCity: otherDepartamentMailings,
                mailingsAddressOtherTown: otherCityMailings,
                mailingsAddressPostalCode: zipCodeMailings,
                mailingsAddressStreet: streetMailings,
                mailingsAddressTownName: towsNameValueMailings,
                mailingsAddressTown: towsValueMailings,
                mailings: sendInformation?.toString() || "false",
            };

            dispatch(SelectorsActionSettings.changeAddressesDataFormRequest({ infoUserModify, _otp: otp, formikBag }));
        },
        validationSchema: (props) => {
            return Yup.lazy((values) => {
                const { isChangeForm, user } = props;

                return Yup.object().shape({
                    countryLegal: Yup.string().trim().required(UtilsI18n.get("client.userconfiguration.emptyCountry")),
                    departamentLegal:
                        IS_COUNTRY_ACTIVE.includes(values?.countryLegal) && values?.departamentLegal !== 0
                            ? Yup.string().trim().required(UtilsI18n.get("client.userconfiguration.emptyCity"))
                            : Yup.string().notRequired(),
                    otherDepartamentLegal:
                        !IS_COUNTRY_ACTIVE.includes(values.countryLegal) || values?.departamentLegal === 0
                            ? Yup.string().trim().required(UtilsI18n.get("client.userconfiguration.emptyOtherCity"))
                            : Yup.string().notRequired(),
                    cityLegal:
                        IS_COUNTRY_ACTIVE.includes(values?.countryLegal) && values?.cityLegal !== 0
                            ? Yup.string().trim().required(UtilsI18n.get("client.userconfiguration.emptyTown"))
                            : Yup.string().notRequired(),
                    otherCityLegal:
                        !IS_COUNTRY_ACTIVE.includes(values.countryLegal) || values.cityLegal === 0
                            ? Yup.string().trim().required(UtilsI18n.get("client.userconfiguration.emptyOtherTown"))
                            : Yup.string().notRequired(),
                    streetLegal: Yup.string().trim().required(UtilsI18n.get("client.userconfiguration.emptyStreet")),
                    doorNumberLegal: Yup.string()
                        .trim()
                        .required(UtilsI18n.get("client.userconfiguration.emptyNumber")),
                    countryMailings: Yup.string()
                        .trim()
                        .required(UtilsI18n.get("client.userconfiguration.emptyCountry")),
                    departamentMailings:
                        IS_COUNTRY_ACTIVE.includes(values?.countryMailings) && values?.departamentMailings !== 0
                            ? Yup.string().trim().required(UtilsI18n.get("client.userconfiguration.emptyCity"))
                            : Yup.string().notRequired(),
                    otherDepartamentMailings:
                        !IS_COUNTRY_ACTIVE.includes(values.countryMailings) || values?.departamentMailings === 0
                            ? Yup.string().trim().required(UtilsI18n.get("client.userconfiguration.emptyOtherCity"))
                            : Yup.string().notRequired(),
                    cityMailings:
                        IS_COUNTRY_ACTIVE.includes(values?.countryMailings) && values?.cityMailings !== 0
                            ? Yup.string().trim().required(UtilsI18n.get("client.userconfiguration.emptyTown"))
                            : Yup.string().notRequired(),
                    otherCityMailings:
                        !IS_COUNTRY_ACTIVE.includes(values.countryMailings) || values.cityMailings === 0
                            ? Yup.string().trim().required(UtilsI18n.get("client.userconfiguration.emptyOtherTown"))
                            : Yup.string().notRequired(),
                    streetMailings: Yup.string().trim().required(UtilsI18n.get("client.userconfiguration.emptyStreet")),
                    doorNumberMailings: Yup.string()
                        .trim()
                        .required(UtilsI18n.get("client.userconfiguration.emptyNumber")),
                    filesLegal: Yup.object().shape({
                        idFile:
                            isChangeForm && !values.files
                                ? Yup.string().required(UtilsI18n.get("client.change.PersonalData.filesLegal.error"))
                                : Yup.string().notRequired(),
                    }),
                    otp: user.needsBiometric
                        ? Yup.string().nullable()
                        : Yup.string()
                              .trim()
                              .required(UtilsI18n.get("form.credential.otp.required"))
                              .min(6, UtilsI18n.get(`returnCode.${INVALID_TOKEN}`)),
                });
            });
        },
    }),
)(Component);
