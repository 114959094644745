import { LOCATION_CHANGE } from "connected-react-router";

import { TYPE as TypeForm } from "~/store/form";
import { shouldKeepFormState as ShouldKeepFormState } from "~/util/form";
import UtilLodash from "~/util/lodash";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.LOAD_LIST_SUCCESS:
            return {
                ...storeProp,
                banksResults: action.data.banks,
                hasMoreResults: action.data.moreResults,
            };
        case TYPE.BANK_SELECTED:
            return {
                ...storeProp,
                selectedBanks: {
                    ...storeProp.selectedBanks,
                    [action.idField]: action.bank,
                },
                errorSelectingBank: {
                    ...storeProp.errorSelectingBank,
                    [action.idField]: false,
                },
                searchBankForm: false,
            };
        case LOCATION_CHANGE: {
            const shouldKeep = ShouldKeepFormState(storeProp, action.payload.location);
            if (shouldKeep) {
                return storeProp;
            }
            return {
                ...storeProp,
                selectedBanks: PROP.defaults.selectedBanks,
                errorSelectingBank: PROP.defaults.errorSelectingBank,
            };
        }
        case TYPE.RESET_SELECTED_BANKS:
            return {
                ...storeProp,
                selectedBanks: PROP.defaults.selectedBanks,
                errorSelectingBank: PROP.defaults.errorSelectingBank,
            };
        case TYPE.RESET_SELECTED_BANK: {
            const selectedBanks = UtilLodash.omitElements(storeProp.selectedBanks, [action.idField]);
            const errorSelectingBank = UtilLodash.omitElements(storeProp.errorSelectingBank, [action.idField]);
            return {
                ...storeProp,
                selectedBanks,
                errorSelectingBank,
            };
        }
        case TYPE.CLEAR_SELECTED_BANK: {
            return {
                ...storeProp,
                selectedBanks: {},
            };
        }
        case TYPE.SEARCH_BANK_REQUEST: {
            return { ...storeProp, searchBankForm: true };
        }
        case TYPE.SEARCH_BANK_SUCCESS: {
            return {
                ...storeProp,
                errorSelectingBank: {
                    ...storeProp.errorSelectingBank,
                    [action.idField]: false,
                },
                searchBankForm: false,
            };
        }
        case TYPE.SEARCH_BANK_FAILURE: {
            return {
                ...storeProp,
                errorSelectingBank: {
                    ...storeProp.errorSelectingBank,
                    [action.idField]: true,
                },
                searchBankForm: false,
            };
        }
        case TYPE.SEARCH_BANK_SUCCESS_TEMPLATES: {
            return {
                ...storeProp,
                banksResults: action.data.banks,
            };
        }
        case TYPE.SET_NETWORK_TYPE:
            return {
                ...storeProp,
                networkType: action.networkType,
            };
        case TypeForm.CLEAN_STEPS:
            return {
                ...storeProp,
                selectedBanks: PROP.defaults.selectedBanks,
                errorSelectingBank: PROP.defaults.errorSelectingBank,
            };
        default:
            return storeProp;
    }
};
