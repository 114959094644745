import { replace as Replace } from "connected-react-router";
import { call as Call, put as Put, takeLatest as TakeLatest, select as Select } from "redux-saga/effects";

import {
    LEVEL,
    SCOPE,
    RESPONSE_TYPE,
    GLOBAL_UNEXPECTED_ERROR_KEY,
    SUPPORTED_FILE_TYPES,
    TYPE_ADMINISTRATION,
} from "~/constants";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as UtilDownload from "~/util/download";
import { credentialsWithUnderscore as credentialsWithUnderscoreFormat } from "~/util/form";
import * as UtilI18n from "~/util/i18n";
import { signatuleLevelsCountToInt as SignatuleLevelsCountToInt } from "~/util/store/administration";

const createSignaturesSchemePreRequest = (middleware, actions) =>
    function* createSignaturesSchemePreRequestSaga() {
        const response = yield Call(middleware.createSignaturesSchemePre);
        if (response?.type === RESPONSE_TYPE.WARNING) {
            yield Put(actions.createSignaturesSchemePreFailure());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION_CREATE_SIGNATURE_SCHEME],
                }),
            );
        } else {
            const responseCredentials = yield Call(SelectorsMiddlewareForm.listCredentialsGroups, {
                idForm: null,
                idActivityToRead: "administration.signatures.create.send",
            });

            const credentialGroups = responseCredentials?.data?.data.groups;
            yield Put(actions.createSignaturesSchemePreSuccess({ data: response?.data.data, credentialGroups }));
        }
    };

const createSignaturesSchemeRequest = (middleware, actions) =>
    function* createSignaturesSchemeRequestSaga({ signatureData, formikBag }) {
        const { setSubmitting, setErrors } = formikBag;
        const { credentials, signatureLevelsCounts, ...restOfParams } = signatureData;
        const credentialsWithUnderscore = credentialsWithUnderscoreFormat(credentials);
        const signatureLevelCountToInt = SignatuleLevelsCountToInt(signatureLevelsCounts);
        const { data, type } = yield Call(middleware.createSignaturesScheme, {
            ...restOfParams,
            signatureLevelsCounts: signatureLevelCountToInt,
            ...credentialsWithUnderscore,
            ...{
                capFrequencies: [restOfParams.topAmount.period] || [],
                maxAmount: restOfParams.topAmount.amount || -1,
            },
        });

        setSubmitting(false);

        if (type === RESPONSE_TYPE.WARNING) {
            const errorMessage = data.data.NO_FIELD || UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY);
            setErrors(data.data);

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION_CREATE_SIGNATURE_SCHEME],
                }),
            );
        } else {
            const administrationScheme = yield Select((state) => SelectorsStoreSession.getAdministrationScheme(state));
            yield Put(Replace(`/administration/${administrationScheme}/signaturesSchemes`));
            yield Put(actions.createSignaturesSchemeSuccess({ data: data.data }));
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.message,
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.ADMINISTRATION_SIGNATURES_SCHEME],
                }),
            );
        }
    };

const deleteSignaturesSchemePreRequest = (middleware, actions) =>
    function* deleteSignaturesSchemePreRequestSaga(params) {
        const { idSignature } = params;
        const { data, type } = yield Call(middleware.deleteSignaturesSchemePre, { id: idSignature });

        if (type === RESPONSE_TYPE.WARNING) {
            yield Put(actions.deleteSignaturesSchemePreFailure());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION_SIGNATURES_SCHEME],
                }),
            );
        } else {
            const responseCredentials = yield Call(SelectorsMiddlewareForm.listCredentialsGroups, {
                idForm: null,
                idActivity: "administration.signatures.delete.send",
            });
            const credentialGroups = responseCredentials.data.data.groups;
            yield Put(actions.deleteSignaturesSchemePreSuccess({ data: data.data, credentialGroups }));
        }
    };

const deleteSignaturesSchemeRequest = (middleware, actions) =>
    function* deleteSignaturesSchemeRequestSaga(props) {
        const { signatureId, groupsMap } = props;
        const { data, type } = yield Call(middleware.deleteSignaturesScheme, {
            signatureId,
            groupsMap,
        });

        if (type === RESPONSE_TYPE.WARNING) {
            const errorMessage = data.message || UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY);

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION_SIGNATURES_SCHEME],
                }),
            );
            yield Put(actions.deleteSignaturesSchemeFailure({ data: data.data }));
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.message,
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.ADMINISTRATION_SIGNATURES_SCHEME],
                }),
            );
            yield Put(actions.deleteSignaturesSchemeSuccess({ data: data.data }));
            yield Put(
                actions.listSignaturesSchemesRequest({
                    params: { filters: { pageNumber: 1, orderBy: "id_signature ASC" } },
                }),
            );
        }
    };

const listSignaturesSchemesRequest = (middleware, actions) =>
    function* listSignaturesSchemesRequestSaga({ params }) {
        const response = yield Call(middleware.listSignaturesSchemes, params ? params.filters : {});

        if (response?.type === RESPONSE_TYPE.WARNING) {
            yield Put(actions.listSignaturesSchemesFailure());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION_SIGNATURES_SCHEME],
                }),
            );
        } else {
            yield Put(actions.listSignaturesSchemesSuccess({ data: response?.data.data }));
        }
    };

const loadMoreSignaturesRequest = (middleware, actions) =>
    function* loadMoreSignaturesRequestSaga(props) {
        const { data, type } = yield Call(middleware.listSignaturesSchemes, props);

        if (type === RESPONSE_TYPE.WARNING) {
            yield Put(actions.listSignaturesSchemesFailure());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION_SIGNATURES_SCHEME],
                }),
            );
        } else {
            yield Put(actions.listMoreSignaturesSchemesSuccess({ data: data.data }));
        }
    };

const modifySignaturesSchemePreRequest = (middleware, actions) =>
    function* modifySignaturesSchemePreRequesSaga(params) {
        const { id } = params;
        const { data, type } = yield Call(middleware.modifySignaturesSchemePre, { id });
        if (type === RESPONSE_TYPE.WARNING) {
            yield Put(actions.modifySignaturesSchemePreFailure());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION_MODIFY_SIGNATURES_SCHEME],
                }),
            );
        } else {
            const responseCredentials = yield Call(SelectorsMiddlewareForm.listCredentialsGroups, {
                idForm: null,
                idActivityToRead: "administration.signatures.modify.send",
            });
            const credentialGroups = responseCredentials.data.data.groups;
            yield Put(actions.modifySignaturesSchemePreSuccess({ data: data.data, credentialGroups }));
        }
    };

const modifySignaturesSchemeRequest = (middleware, actions) =>
    function* modifySignaturesSchemeRequestSaga({ signatureData, formikBag }) {
        const { administrationScheme, signatureLevelsCounts, ...rest } = signatureData;

        let response = {};
        if (administrationScheme === TYPE_ADMINISTRATION.MEDIUM) {
            response = yield Call(middleware.modifySignaturesScheme, {
                ...rest,
            });
        } else if (administrationScheme === TYPE_ADMINISTRATION.ADVANCED) {
            const signatureLevelCountToInt = SignatuleLevelsCountToInt(signatureLevelsCounts);

            response = yield Call(middleware.modifySignaturesScheme, {
                ...rest,
                signatureLevelsCounts: signatureLevelCountToInt,
                ...{
                    capFrequencies: [rest.topAmount.period] || [],
                    maxAmount: rest.topAmount.amount || -1,
                },
            });
        }

        const { setSubmitting, setErrors } = formikBag;

        setSubmitting(false);

        if (response.type === RESPONSE_TYPE.WARNING) {
            const errorMessage = response.data.data.NO_FIELD || UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY);
            setErrors(response.data.data);

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION_MODIFY_SIGNATURES_SCHEME],
                }),
            );
        } else {
            yield Put(actions.modifySignaturesSchemeSuccess({ data: response.data.data }));

            if (administrationScheme === TYPE_ADMINISTRATION.ADVANCED) {
                yield Put(Replace("/administration/advanced/signaturesSchemes"));
            } else if (administrationScheme === TYPE_ADMINISTRATION.MEDIUM) {
                yield Put(Replace("/administration/medium/signaturesSchemes/modify"));
            }

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.ADMINISTRATION_SIGNATURES_SCHEME],
                }),
            );
        }
    };

const signatureSchemeExportRequest = (middleware, actions) =>
    function* signatureSchemeExportRequestSaga({ format }) {
        const { data, type } = yield Call(middleware.exportSignatureSchemes, { format, orderBy: "id_signature ASC" });

        if (type === RESPONSE_TYPE.WARNING) {
            yield Put(actions.exportListFailure());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION_USERS],
                }),
            );
        } else {
            const { content, fileName } = data.data;
            if (format === SUPPORTED_FILE_TYPES.PDF) {
                UtilDownload.downloadPdf(fileName, content);
            } else if (format === SUPPORTED_FILE_TYPES.XLS) {
                UtilDownload.downloadXls(fileName, content);
            } else {
                UtilDownload.download(fileName, content);
            }
            yield Put(actions.exportListSuccess());
        }
    };

const loadSignaturesSchemesTicketRequest = (middleware, actions) =>
    function* loadSignaturesSchemesTicketRequestSaga({ idTransaction }) {
        const transactionResponse = yield Call(SelectorsMiddlewareForm.readTransaction, idTransaction);

        if (transactionResponse.type === RESPONSE_TYPE.WARNING) {
            yield Put(actions.loadSignaturesSchemesTicketFailure());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION],
                }),
            );
        } else {
            const {
                capFrequencies,
                functionalGroups,
                maxAmount,
                signatureLevelsCounts,
                signatureType,
                signatureDispatch,
            } = transactionResponse.data.data.transaction.data;

            yield Put(
                actions.loadSignaturesSchemesTicketSuccess({
                    functionalGroups,
                    signatureLevelsCounts,
                    signatureType,
                    topAmount: { amount: maxAmount, period: capFrequencies[0] },
                    signatureDispatch,
                }),
            );
        }
    };

const loadSignatureTicketRequest = (middleware, actions) =>
    function* loadSignatureTicketRequestSagas({ idTransaction }) {
        const transactionResponse = yield Call(SelectorsMiddlewareForm.readTransaction, idTransaction);

        if (transactionResponse.type === RESPONSE_TYPE.WARNING) {
            yield Put(actions.loadSignatureTicketFailure());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADMINISTRATION],
                }),
            );
        } else {
            const { idUser, signatureLevel } = transactionResponse.data.data.transaction.data;
            const detailsResponse = yield Call(middleware.loadDetailsRequest, idUser);
            if (detailsResponse.type === RESPONSE_TYPE.WARNING) {
                yield Put(actions.loadSignatureTicketFailure());
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.ADMINISTRATION],
                    }),
                );
            } else {
                yield Put(actions.loadSignatureTicketSuccess({ signatureLevel }));
                yield Put(actions.loadDetailsSuccess({ ...detailsResponse.data.data, signatureLevel }));
            }
        }
    };

const sagas = (middleware, types, actions) => [
    TakeLatest(types.CREATE_SIGNATURES_SCHEME_PRE_REQUEST, createSignaturesSchemePreRequest(middleware, actions)),
    TakeLatest(types.CREATE_SIGNATURES_SCHEME_REQUEST, createSignaturesSchemeRequest(middleware, actions)),
    TakeLatest(types.DELETE_SIGNATURES_SCHEME_PRE_REQUEST, deleteSignaturesSchemePreRequest(middleware, actions)),
    TakeLatest(types.DELETE_SIGNATURES_SCHEME_REQUEST, deleteSignaturesSchemeRequest(middleware, actions)),
    TakeLatest(types.LIST_SIGNATURES_SCHEMES_REQUEST, listSignaturesSchemesRequest(middleware, actions)),
    TakeLatest(types.SIGNATURES_SCHEME_LOAD_MORE_REQUEST, loadMoreSignaturesRequest(middleware, actions)),
    TakeLatest(types.MODIFY_SIGNATURES_SCHEME_PRE_REQUEST, modifySignaturesSchemePreRequest(middleware, actions)),
    TakeLatest(types.MODIFY_SIGNATURES_SCHEME_REQUEST, modifySignaturesSchemeRequest(middleware, actions)),
    TakeLatest(types.SIGNATURES_SCHEME_EXPORT_LIST_REQUEST, signatureSchemeExportRequest(middleware, actions)),
    TakeLatest(types.LOAD_SIGNATURES_SCHEMES_TICKET_REQUEST, loadSignaturesSchemesTicketRequest(middleware, actions)),
    TakeLatest(types.LOAD_SIGNATURE_TICKET_REQUEST, loadSignatureTicketRequest(middleware, actions)),
];

export default sagas;
