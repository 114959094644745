import React from "react";

import { routerActions as ActionRouter } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";

import Container from "~/containers/Internal/Form/List";
import { SelectorsStore as StoreCC } from "~/store/creditCards/creditCard";

import I18n from "~/components/I18n";
import Link from "~/components/Link";

export const NAME = "CreditCardOperations";

export const PROP = {
    types: {
        id: PropTypes.string.isRequired,
        permissions: PropTypes.object,
    },
    defaults: {
        permissions: null,
    },
};

export function Component(props) {
    const { dispatch, id, permissions } = props;
    const { creditCardPurchaseNotification, limitIncrease, payCreditCard, tripNotice } = permissions || {};

    const operations = [];

    operations.push({ key: "creditCard.accountStatements", to: `/creditCards/${id}/operations/statements` });

    if (tripNotice) {
        operations.push({ key: "menu.creditcards.tripNotice", to: "/tripOrOnlinePurchaseNotification" });
    }

    if (creditCardPurchaseNotification) {
        operations.push({
            key: "menu.creditcards.purchaseNotification",
            to: "/purchaseNotification",
        });
    }

    if (limitIncrease) {
        operations.push({ key: "menu.creditcards.limitIncrease", to: "/creditCardsLimitIncrease" });
    }

    operations.push({ key: "creditCard.alias", to: `/creditCards/${id}/setAlias` });

    if (payCreditCard) {
        operations.push({ key: "creditCard.pay", to: `/form/payCreditCard?creditCard=${id}` });
    }

    return (
        <Container name={NAME} head-onBack={handleBack} head-title="menu.operations">
            {operations.map(({ key, to }) => (
                <Link key={key} to={to}>
                    <I18n id={key} />
                </Link>
            ))}
        </Container>
    );

    function handleBack() {
        dispatch(ActionRouter.goBack());
    }
}

Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

const mapStateToProps = (store, props) => {
    const detail = StoreCC.getDetail(store); // detail loaded in previous flow
    let permissions;

    if (!detail) {
        // defensive
        const creditCard = StoreCC.getCreditCard(store);
        permissions = creditCard && creditCard.permissions;
    } else {
        permissions = detail && detail.permissions;
    }

    return {
        id: props.match.params.id,
        permissions,
        isFetching: StoreCC.getFetching(store),
    };
};

export default Connect(mapStateToProps)(WithRouter(Component));
