import React from "react";

import { push as Push } from "connected-react-router";
import { connect as Connect } from "react-redux";

import { SelectorsAction as SelectorsActionGroup } from "~/store/administration/groups";
import * as UtilsI18n from "~/util/i18n";

import Operations from "~/components/Box";
import Button from "~/components/Button/Button";
import Image from "~/components/Image";
import SelectButton from "~/components/SelectButton";

export const NAME = "Operations";

export const PROP = {
    types: {},
    defaults: {},
};

export function Component({ className, dispatch, onClick }) {
    const handleClickDownload = (format) => {
        dispatch(SelectorsActionGroup.exportListRequest({ format }));
    };

    return (
        <Operations flex justify="end" className={className} onClick={onClick}>
            <Button variant="secondary" onClick={() => dispatch(Push("/administration/advanced/createGroup/step1"))}>
                <Image src="plus.svg" />
                {UtilsI18n.get("administration.advanced.group.create.link.text")}
            </Button>
            <SelectButton label={UtilsI18n.get("global.download")}>
                <Button onClick={() => handleClickDownload("csv")}>
                    {UtilsI18n.get("client.lists.export.formatCSV")}
                </Button>
                <Button onClick={() => handleClickDownload("pdf")}>
                    {UtilsI18n.get("client.lists.export.formatPDF")}
                </Button>
            </SelectButton>
        </Operations>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Connect(null)(Component);
