import React from "react";

import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { COMMA, EMPTY_STR } from "~/constants";
import { STATUS } from "~/constants/transaction";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import { SelectorsStore, SelectorsAction as SelectorsActionComexImport } from "~/store/comex/import";
import { SelectorsAction as SelectorsActionFile } from "~/store/files";
import { SelectorsStore as i18nSelectors } from "~/store/i18n";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import { specificDate as SpecificDate } from "~/util/date";
import DeviceUtils from "~/util/device";
import * as UtilsI18n from "~/util/i18n";

import DataNumber from "~/components/DataNumber";
import GridLayout from "~/components/GridLayout";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Token from "~/components/Token/Token";
import TicketPrintInformation from "~/pages/_components/TicketPrintInformation";

import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        isSubmitting: PropTypes.bool,
        suppliersDisplayList: PropTypes.array.isRequired,
    },
    defaults: {
        isSubmitting: false,
    },
};

const printForm = () => {
    const elements = window.document.getElementsByClassName("no_print");

    for (let i = 0; i < elements.length; i += 1) {
        elements[i].style.display = "none";
    }

    window.print();

    for (let i = 0; i < elements.length; i += 1) {
        elements[i].style.display = EMPTY_STR;
    }

    return true;
};

export function Component(props) {
    const { isSubmitting, location, dispatch, formData, transaction } = props;
    const COLUMN_HEADINGS = [
        UtilsI18n.get("comex.imports.creditLetter.form.columnheaders.document"),
        UtilsI18n.get("comex.imports.creditLetter.form.columnheaders.description"),
        UtilsI18n.get("comex.imports.creditLetter.form.columnheaders.original"),
        UtilsI18n.get("comex.imports.creditLetter.form.columnheaders.copies"),
    ];

    const isFromTransactionsHistory = location.pathname.includes("/ticket");
    const data =
        isFromTransactionsHistory && transaction?.idTransactionStatus !== STATUS.DRAFT ? transaction.data : formData;
    const fileIds = data && data.attachments ? data.attachments.split(COMMA) : EMPTY_STR;

    const downloadFile = (idFile, fileName) => {
        dispatch(SelectorsActionFile.downloadFileRequest({ idFile, fileName }));
    };

    const shareTicket = () => {
        dispatch(
            SelectorsActionTransactions.shareTicketRequest({
                transactionData: data,
                idActivity: "comex.imports.creditLetterOpen.send",
            }),
        );
    };

    return (
        <Form id={Style.ID}>
            <TicketPrintInformation />
            {data && (
                <div className="data-wrapper">
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="forms.transaction.ticket.date" />
                        </span>
                        <span className="data-name">{SpecificDate(data.scheduler.valueDate, "DD/MM/YYYY")}</span>
                    </div>
                    {data.advisingBankBIC && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.advisingBankBIC.label" />
                            </span>
                            <span className="data-name">{data.advisingBankBIC}</span>
                        </div>
                    )}

                    {data.advisingBankName && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.advisingBankName.label" />
                            </span>
                            <span className="data-name">{data.advisingBankName}</span>
                        </div>
                    )}

                    {data.advisingBankAddress && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.advisingBankAddress.label" />
                            </span>
                            <span className="data-name">{data.advisingBankAddress}</span>
                        </div>
                    )}

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.confirmationInstructions" />
                        </span>
                        <span className="data-name">
                            {data.confirmationInstructions
                                ? UtilsI18n.get("comex.generic.yes")
                                : UtilsI18n.get("comex.generic.no")}
                        </span>
                    </div>

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.transferable" />
                        </span>
                        <span className="data-name">
                            {data.transferable ? UtilsI18n.get("comex.generic.yes") : UtilsI18n.get("comex.generic.no")}
                        </span>
                    </div>

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.domestica" />
                        </span>
                        <span className="data-name">
                            {data.domestic ? UtilsI18n.get("comex.generic.yes") : UtilsI18n.get("comex.generic.no")}
                        </span>
                    </div>

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.expirationDate.label" />
                        </span>
                        <span className="data-name">{SpecificDate(data.expirationDate, "DD/MM/YYYY")}</span>
                    </div>

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.expirationPlace.label" />
                        </span>
                        <span className="data-name">{data.expirationPlace}</span>
                    </div>

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.purchaserName" />
                        </span>
                        <span className="data-name">{data.purchaserName}</span>
                    </div>

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.purchaserAddress.label" />
                        </span>
                        <span className="data-name">{data.purchaserAddress}</span>
                    </div>

                    {data.beneficiaryLine1 && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.beneficiaryLine1.label" />
                            </span>
                            <span className="data-name">{data.beneficiaryLine1}</span>
                        </div>
                    )}

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.beneficiaryLine2to5.label" />
                        </span>
                        <span className="data-name">
                            {data.beneficiaryLine2} {data.beneficiaryLine3} {data.beneficiaryLine4}{" "}
                            {data.beneficiaryLine5}
                        </span>
                    </div>

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.amount.label" />
                        </span>
                        <span className="data-name">
                            <DataNumber
                                value={data.amount.quantity}
                                prefix={data.amount.currency}
                                className="mobile-only"
                            />
                        </span>
                    </div>

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.amountTolerance.label" />
                        </span>
                        <span className="data-name">
                            {data.amountTolerance
                                ? UtilsI18n.get("comex.imports.creditLetter.form.amountTolerance.description")
                                : UtilsI18n.get("comex.generic.yes")}
                        </span>
                    </div>
                    {!data.amountTolerance && (
                        <React.Fragment>
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="comex.imports.creditLetter.form.amountToleranceMin.label" />
                                </span>
                                <div className="tolerance-percetage">
                                    <span className="data-name">{data.amountToleranceMin}</span>
                                    <I18n id="comex.imports.creditLetter.form.percentageSymbol" />
                                </div>
                            </div>
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="comex.imports.creditLetter.form.amountToleranceMax.label" />
                                </span>
                                <div className="tolerance-percetage">
                                    <span className="data-name">{data.amountToleranceMax}</span>
                                    <I18n id="comex.imports.creditLetter.form.percentageSymbol" />
                                </div>
                            </div>
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="comex.imports.creditLetter.form.data.toleranceMerchandise" />
                                </span>
                                <span className="data-name">
                                    {data.toleranceMerchandise
                                        ? UtilsI18n.get("comex.generic.yes")
                                        : UtilsI18n.get("comex.generic.no")}
                                </span>
                            </div>
                        </React.Fragment>
                    )}

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.available.label" />
                        </span>
                        <span className="data-name">{data.available}</span>
                    </div>

                    {data.availablePeriodDays && (
                        <React.Fragment>
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="comex.imports.creditLetter.form.availablePeriodDays.label" />
                                </span>
                                <span className="data-name">{data.availablePeriodDays} </span>
                            </div>
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="comex.imports.creditLetter.form.availablePeriodFrom" />
                                </span>
                                <span className="data-name">
                                    {UtilsI18n.get(
                                        `comex.imports.creditLetter.form.availablePeriodFrom.${data.availablePeriodFrom}`,
                                    )}{" "}
                                </span>
                            </div>
                        </React.Fragment>
                    )}

                    {data.availableMixed && (
                        <React.Fragment>
                            <div className="data-wrapper">
                                <span className="data-name">{data.availableMixed} </span>
                            </div>
                        </React.Fragment>
                    )}

                    {data.availablePeriodOther && (
                        <React.Fragment>
                            <div className="data-wrapper">
                                <span className="data-name">{data.availablePeriodOther} </span>
                            </div>
                        </React.Fragment>
                    )}

                    {data.available && data.available === "Plazo" && (
                        <React.Fragment>
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="comex.imports.creditLetter.form.data.requiresLetterOfCredit" />
                                </span>
                                <span className="data-name">
                                    {data.requiresLetterOfCredit
                                        ? UtilsI18n.get("comex.generic.yes")
                                        : UtilsI18n.get("comex.generic.no")}
                                </span>
                            </div>
                        </React.Fragment>
                    )}

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.partialShipments.label" />
                        </span>
                        <span className="data-name">
                            {data.partialShipments
                                ? UtilsI18n.get("comex.imports.creditLetter.form.partialShipments.description")
                                : UtilsI18n.get("comex.imports.creditLetter.form.partialShipments.no.description")}
                        </span>
                    </div>

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.transhipment.label" />
                        </span>
                        <span className="data-name">
                            {data.transhipment
                                ? UtilsI18n.get("comex.imports.creditLetter.form.partialShipments.description")
                                : UtilsI18n.get("comex.imports.creditLetter.form.partialShipments.no.description")}
                        </span>
                    </div>

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.shippingExpirationDate.label" />
                        </span>
                        <span className="data-name">{SpecificDate(data.shippingExpirationDate, "DD/MM/YYYY")}</span>
                    </div>

                    {data.shippingPeriod && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.shippingPeriod.label" />
                            </span>
                            <span className="data-name">{data.shippingPeriod}</span>
                        </div>
                    )}

                    {data.deliveryLocation && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.deliveryLocation.label" />
                            </span>
                            <span className="data-name">{data.deliveryLocation}</span>
                        </div>
                    )}

                    {data.shipmentAirPort && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.shipmentAirPort.label" />
                            </span>
                            <span className="data-name">{data.shipmentAirPort}</span>
                        </div>
                    )}

                    {data.destinationAirPort && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.destinationAirPort.label" />
                            </span>
                            <span className="data-name">{data.destinationAirPort}</span>
                        </div>
                    )}

                    {data.destinationPlace && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.destinationPlace.label" />
                            </span>
                            <span className="data-name">{data.destinationPlace}</span>
                        </div>
                    )}

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.description.label" />
                        </span>
                        <span className="data-name">{data.description}</span>
                    </div>

                    {data.shippedBy && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.shippedBy.label" />
                            </span>
                            <span className="data-name">{data.shippedBy}</span>
                        </div>
                    )}

                    {data.terms && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.terms" />
                            </span>
                            <span className="data-name">
                                {UtilsI18n.get(`incoterms.${data.terms}`)} {data.termsDetail}
                            </span>
                        </div>
                    )}

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.documents" />
                        </span>
                    </div>

                    <GridLayout className="header ticket-table-section">
                        {COLUMN_HEADINGS.map((heading) => {
                            return <div>{heading}</div>;
                        })}
                    </GridLayout>
                    {data.documents &&
                        data.documents.map((document) => (
                            <GridLayout className="item ticket-table-section">
                                <div>{UtilsI18n.get(`comex.creditLetter.modal.documentTypes.${document.type}`)}</div>
                                <div>{document.description}</div>
                                <div>{document.originals}</div>
                                <div>{document.copies}</div>
                            </GridLayout>
                        ))}

                    {data.authorizeDisclaimer && data.shippedBy === "TERRESTRE" && (
                        <React.Fragment>
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="comex.imports.creditLetter.form.authorizeDisclaimer" />
                                </span>
                                <span className="data-name">
                                    {UtilsI18n.get("comex.imports.creditLetter.form.preField")}{" "}
                                    {UtilsI18n.get(
                                        `comex.imports.creditLetter.form.authorizeCopies.option.${data.authorizeCopies}`,
                                    )}{" "}
                                    {UtilsI18n.get("comex.imports.creditLetter.form.authorizeDisclaimer.postField")}{" "}
                                </span>
                            </div>
                        </React.Fragment>
                    )}

                    {data.instructions && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.instructions.label" />
                            </span>
                            <span className="data-name">{data.instructions}</span>
                        </div>
                    )}

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.docsPresentationDays.label" />
                        </span>
                        <div className="tolerance-percetage">
                            <span className="data-name">{data.docsPresentationDays}</span>
                            <I18n id="comex.imports.creditLetter.form.docsPresentationDays.description" />
                        </div>
                    </div>

                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="comex.imports.creditLetter.form.bankExpenses.label" />
                        </span>
                        <span className="data-name">{data.bankExpenses}</span>
                    </div>

                    {data.bankExpensesOther && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="comex.imports.creditLetter.form.bankExpensesOther" />
                            </span>
                            <span className="data-name">{data.bankExpensesOther}</span>
                        </div>
                    )}

                    {/* adjuntos */}
                    {data.attachments && (
                        <React.Fragment>
                            <div className="data-wrapper">
                                {[UtilsI18n.get("comex.imports.creditLetter.form.attachments.heading1")].map(
                                    (heading) => {
                                        return <span className="data-label">{heading}</span>;
                                    },
                                )}
                            </div>
                            {fileIds.map((fileId, index) => (
                                <div className="data-wrapper">
                                    <span className="data-label">
                                        <a onClick={() => downloadFile(fileId, data.attachmentsNames[index])}>
                                            {data.attachmentsNames[index]}
                                        </a>
                                    </span>
                                </div>
                            ))}
                        </React.Fragment>
                    )}
                </div>
            )}
            {/* </Ticket>} */}
            <div className="no_print">
                <Token labelButton="global.send" isSubmitting={isSubmitting} />
            </div>
            <div className="buttonsTicketConfirmation">
                {!DeviceUtils.isMobileNative() && (
                    <div className="buttonsTicketConfirmation">
                        <a onClick={printForm}>
                            <I18n id="forms.ticket.print" />
                        </a>
                    </div>
                )}
                <a
                    onClick={() => {
                        shareTicket();
                    }}>
                    <I18n id="forms.ticket.printPDF" />
                </a>
            </div>
        </Form>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        formData: SelectorsStore.getImportCreditLetterOpenFormData(store),
        lang: i18nSelectors.getLang(store),
        transaction: SelectorsStore.getTransaction(store),
        user: SelectorsStoreSession.getUser(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithRouter,
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        validationSchema: (props) => {
            const { user } = props;

            return Yup.object().shape({
                otp: user.needsBiometric
                    ? Yup.string().nullable()
                    : Yup.string().required(UtilsI18n.get("form.credential.otp.required")),
            });
        },
        handleSubmit: (data, formikBag) => {
            const {
                dispatch,
                history,
                transaction: { idTransaction, idTransactionStatus },
                formData,
            } = formikBag.props;
            const { otp } = data;

            const isFromTransactionsHistory = history.location.pathname.includes("/ticket");

            if (isFromTransactionsHistory && idTransactionStatus !== STATUS.DRAFT) {
                dispatch(
                    SelectorsActionComex.signTransaction({
                        idForm: null,
                        idActivity: "comex.imports.creditLetterOpen.send",
                        idTransaction,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            } else {
                // eslint-disable-next-line no-underscore-dangle
                formData._otp = otp;
                dispatch(SelectorsActionComexImport.importCreditLetterOpenSendRequest({ formData, formikBag }));
            }
        },
    }),
)(Component);
