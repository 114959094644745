import React from "react";

import { withFormik as WithFormik, Form, Field } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import { ALIAS_TYPES, EMPTY_STR, ONLY_NUMBER, PIPE_SEPARATOR } from "~/constants";
import { MODE } from "~/constants/form";
import { SelectorsStore as SelectorsStoreSession, SelectorsStore as StoreSession } from "~/store/session";
import { SelectorsStore as SelectorsStoreSettings } from "~/store/settings";
import {
    SelectorsStore as SelectorsStoreTransfers,
    SelectorsAction as SelectorsActionTransfers,
} from "~/store/transfers";
import * as UtilsConfig from "~/util/config";
import * as UtilsI18n from "~/util/i18n";
import { GetMobileCountryOptions } from "~/util/phone";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";
import Link from "~/components/Link";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import CountryCellPhoneField from "~/pages/_components/fields/formik/CountryCellPhoneField";

import { Termsandconditions } from "~/pages/forms/_components/_fields";

import StyleField from "./MobilePhoneField.rules.scss";
import Style from "./Step1Edit.rules.scss";

const FORM_ID = "alias.enrollment";

export const { NAME } = Style;

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        isDesktop: PropTypes.bool.isRequired,
        isSubmitting: PropTypes.bool.isRequired,
        onCancel: PropTypes.func.isRequired,
        values: PropTypes.object.isRequired,
    },
    defaults: {
        isDesktop: false,
        isSubmitting: false,
        onCancel: null,
    },
};

export function Component(props) {
    const { activeEnvironment, environments, suggestAliasEnrollmentShowHeader } = props;
    const productGroupId =
        activeEnvironment && environments ? environments[activeEnvironment.id].productGroupId : EMPTY_STR;

    const termsAndConditions = "transfers.alias.enrollment.disclaimer";

    const i18nMap = {
        termsAndConditions,
    };

    return (
        <React.Fragment>
            <div id={Style.ID}>
                <Form>
                    {suggestAliasEnrollmentShowHeader && (
                        <div className="reminder-header-card">
                            <p className="reminder-header">
                                <I18n id="transfers.alias.enrollment.reminder.header" />
                            </p>
                            <p>
                                <I18n id="transfers.alias.enrollment.reminder.description" />
                            </p>
                        </div>
                    )}
                    <div className="form--change_mobilePhone">
                        <div className="form--change_mobilePhone--title">
                            <I18n id="transfers.alias.enrollment.title" />
                        </div>
                        <div className="container--data">
                            <p className="text-description">
                                {UtilsI18n.get("transfers.alias.enrollment.description", null, {
                                    param1: productGroupId,
                                })}
                            </p>
                            <div className="access-code">
                                <div className="field-mobilephone field-spacing" id={StyleField.ID}>
                                    <FieldLabel labelKey="transfers.alias.enrollment.cellPhone" />
                                    <Field
                                        autoFocus={false}
                                        className="login-form-input"
                                        component={CountryCellPhoneField}
                                        hideLabel
                                        hidePlaceholder={false}
                                        idForm={FORM_ID}
                                        labelKey="label"
                                        name="mobilePhone"
                                        pattern={ONLY_NUMBER}
                                        readOnly
                                        type="text"
                                        valueKey="countryName"
                                    />
                                </div>
                            </div>

                            <p className="text-description">
                                <I18n id="transfers.alias.enrollment.detail.1" />
                                <Link to="/settings/changeMobilePhone">
                                    <span>{` ${UtilsI18n.get("settings.options.changePersonalInformation")} `}</span>
                                </Link>
                                <I18n id="transfers.alias.enrollment.detail.2" />
                            </p>
                            <Box className="disclaimer">
                                <Field
                                    className="form-control"
                                    component={Termsandconditions}
                                    i18nMap={i18nMap}
                                    idForm={FORM_ID}
                                    name="disclaimer"
                                    mode={MODE.EDIT}
                                />
                            </Box>
                        </div>
                    </div>
                    <div className="buttons-container">
                        <Button variant="primary" type="submit">
                            <I18n id="global.send" />
                        </Button>
                    </div>
                </Form>
            </div>
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
    countryList: SelectorsStoreSettings.getCountryList(store),
    environments: SelectorsStoreSession.getEnvironments(store),
    user: SelectorsStoreSession.getUser(store),
    userAlias: SelectorsStoreTransfers.getUserAlias(store),
    suggestAliasEnrollmentShowHeader: StoreSession.getSuggestAliasEnrollmentShowHeader(store),
});

export default Compose(
    WithRouter,
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => {
            const { userAlias } = props;
            const prefixUruguay = UtilsConfig.get("cellPhone.code.URY");

            const { aliasData, aliasType } = userAlias;

            let countryPhone = GetMobileCountryOptions();

            countryPhone = countryPhone.filter(
                (element) => element?.value === (aliasType ? `+${aliasType}` : prefixUruguay),
            );

            return {
                mobilePhone: {
                    country: countryPhone[0]?.country,
                    mobilePhone: aliasData,
                    prefix: aliasType ? `+${aliasType}` : prefixUruguay,
                },
            };
        },
        validationSchema: () =>
            Yup.object().shape({
                mobilePhone: Yup.object().shape({
                    prefix: Yup.string().required(UtilsI18n.get(`${FORM_ID}.mobilePhone.mustBeAPhoneNumber`)),
                    mobilePhone: Yup.string().required(UtilsI18n.get("client.userconfiguration.empty.mobilePhone")),
                }),
            }),
        handleSubmit: (props, formikBag) => {
            const { mobilePhone } = props;
            const { activeEnvironment, dispatch, environments } = formikBag.props;

            let aliasDataToUse = mobilePhone.mobilePhone;
            let aliasTypeToUse = mobilePhone.prefix;

            if (mobilePhone.prefix !== ALIAS_TYPES.EMAIL.key && mobilePhone.prefix !== ALIAS_TYPES.TEXT.key) {
                aliasTypeToUse = ALIAS_TYPES.PHONE.btValue;

                // Special treatment for phone aliases
                aliasDataToUse = mobilePhone.prefix.substring(1).concat(PIPE_SEPARATOR).concat(mobilePhone.mobilePhone);
            }

            const formData = {
                aliasData: aliasDataToUse,
                aliasType: aliasTypeToUse,
                disclaimer: UtilsI18n.get("transfers.alias.enrollment.disclaimer"),
                mobilePhone,
                productGroupId:
                    activeEnvironment && environments ? environments[activeEnvironment.id].productGroupId : EMPTY_STR,
            };

            dispatch(SelectorsActionTransfers.aliasEnrollmentPreviewRequest({ formData, formikBag, values: props }));
        },
    }),
)(Component);
