import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    shareLocal: (props) => API.executeWithAccessToken(PATH.SHARE_LOCAL, props),
};

export const SelectorsAction = {
    shareLocal: (props) => FactoryAction(TYPE.SHARE_LOCAL_REQUEST, props),
    shareForeign: (props) => FactoryAction(TYPE.SHARE_FOREIGN_REQUEST, props),

    errorShareLocal: (props) => FactoryAction(TYPE.SHARE_LOCAL_ERROR, props),
    cancelShareLocal: (props) => FactoryAction(TYPE.SHARE_LOCAL_CANCEL, props),
    successShareLocal: (props) => FactoryAction(TYPE.SHARE_LOCAL_SUCCESS, props),
};

export const SelectorsStore = {
    fetching: (store) => store[NAME].fetching,
    shareLocal: (store) => store[NAME].shareLocal,
    statusShareLocal: (store) => store[NAME].statusShareLocal,
};
