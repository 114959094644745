import React from "react";

import { push as Push } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EVENT_BACKBUTTON, SCOPE } from "~/constants";
import Container from "~/containers/External/Dash";
import { SelectorsAction, SelectorsStore } from "~/store/changeMyPhone";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import WithExchangeToken from "~/pages/_components/withExchangeToken";

import CancelConfirmationModal from "~/pages/enrollment/_CancelConfirmationModal";

const FORM_ID = "changePhone.step2";

export const NAME = "ChangePhoneStep2";

export const PROP = {
    types: {
        fetching: PropTypes.bool,
        username: PropTypes.string,
        isSubmitting: PropTypes.bool,
        ...TypesRedux,
    },
    defaults: {
        fetching: false,
        username: null,
        isSubmitting: false,
        ...DefaultsRedux,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {
        show: false,
    };

    componentDidMount() {
        document.addEventListener(EVENT_BACKBUTTON, this.showModal, true);
    }

    componentWillUnmount() {
        document.removeEventListener(EVENT_BACKBUTTON, this.showModal, true);
    }

    handleConfirmation = () => {
        const { dispatch } = this.props;
        dispatch(Push("/"));
    };

    handleCancelation = () => {
        this.setState({ show: false });
    };

    showModal = () => {
        this.setState({ show: true });
    };

    handleYes = () => {
        const { dispatch } = this.props;
        dispatch(Push("/changeMyPhone/changePhoneStep3"));
    };

    handleNo = () => {
        // aca va a ingreso de codigo de verificacion
        const { dispatch, username } = this.props;
        dispatch(SelectorsAction.changePhoneStep2({ username }));
    };

    render() {
        const { fetching, isSubmitting } = this.props;
        const { show } = this.state;
        return (
            <React.Fragment>
                <CancelConfirmationModal
                    show={show}
                    handleCancelation={this.handleCancelation}
                    handleConfirmation={this.handleConfirmation}
                />
                <Container
                    name={NAME}
                    head-onClose={this.showModal}
                    wait={fetching}
                    scopeToShowNotification={SCOPE.CHANGE_PHONE_2}>
                    <div className="login-step step-1">
                        <div className="login-header">
                            <span className="login-header-text">
                                <I18n id={`${FORM_ID}.title`} />
                            </span>
                        </div>
                        <div className="login-content">
                            <div className="login-row simple-row">
                                <div className="login-image">
                                    <Image src="login-ibanca.svg" className="login-image-icon" />
                                </div>
                            </div>
                            <div className="login-row main-row">
                                <div className="login-form">
                                    <div className="login-form-field">
                                        <div className="login-form-field button-field">
                                            <Button
                                                className="login-form-button main-button"
                                                bsStyle="primary"
                                                label="global.yes"
                                                loading={isSubmitting}
                                                type="submit"
                                                onClick={this.handleYes}
                                            />
                                        </div>
                                    </div>
                                    <div className="login-form-field">
                                        <div className="login-form-field button-field">
                                            <Button
                                                className="login-form-button main-button"
                                                bsStyle="primary"
                                                label="global.no"
                                                loading={isSubmitting}
                                                type="submit"
                                                onClick={this.handleNo}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="enrollment-content-dialog">
                                    <span className="enrollment-content-dialog-text" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

export default HighOrder(
    Connect((store) => ({
        username: SelectorsStore.username(store),
        fetching: SelectorsStore.fetching(store),
    })),
    WithExchangeToken,
)(Component);
