import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreAdvancePayments } from "~/store/factoring/advancePayments";
import * as UtilDate from "~/util/date";

import Chart from "~/components/BarChart";

export const NAME = "PaymentOrderChart";

export const PROP = {
    types: {
        ordersToDiscountList: PropTypes.array.isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const { advancePaymentsCompaniesList, ordersToDiscountList } = props;

    let totalOrders = [];
    advancePaymentsCompaniesList.forEach(({ paymentsList }) => {
        totalOrders = totalOrders.concat(paymentsList);
    });

    const dataByCurrency = {};
    let key = EMPTY_STR;

    totalOrders.forEach((order) => {
        const date = UtilDate.toDate(order.dueDate);
        const mmYyyy = UtilDate.applyFormat(date, "MM.yyyy");
        const monthDay = UtilDate.getMonthDay(date);
        const currency = order.currency.toString();
        const itemKey = `${mmYyyy}-${currency}`;
        let monthIndex;
        let orderAmount = order.amount;
        if (ordersToDiscountList.includes(order)) {
            orderAmount = 0;
        }
        // If it is the same month and currency, just add amount by week
        if (itemKey === key) {
            monthIndex = dataByCurrency[currency].findIndex((item) => item.date === mmYyyy);
            sumByWeek(currency, monthIndex, monthDay, orderAmount);
        } else {
            key = itemKey;
            // Initializing new currency and month arrays
            if (!dataByCurrency[currency]) {
                dataByCurrency[currency] = [];
            }
            monthIndex = dataByCurrency[currency].findIndex((item) => item.date === mmYyyy);
            // if i dont find any index, its a new entry
            if (monthIndex === -1) {
                monthIndex = dataByCurrency[currency].length;
                dataByCurrency[currency].push({ date: mmYyyy, weekOne: 0, weekTwo: 0, weekThree: 0, weekFour: 0 });
            }
            sumByWeek(currency, monthIndex, monthDay, orderAmount);
        }
    });

    /**
     * Add the correct amount by week, month and currency inside the dataByCurrency array
     * @param {string} curr Currency
     * @param {number} mi Array index of the correct month inside the specific currency array
     * @param {number} day Day of the month
     * @param {number} amount Amount to add
     */
    function sumByWeek(curr, mi, day, amount) {
        switch (true) {
            case day <= 7:
                dataByCurrency[curr][mi].weekOne += amount;
                break;
            case day >= 8 && day <= 15:
                dataByCurrency[curr][mi].weekTwo += amount;
                break;
            case day >= 16 && day <= 22:
                dataByCurrency[curr][mi].weekThree += amount;
                break;
            default:
                dataByCurrency[curr][mi].weekFour += amount;
        }
    }
    return Object.keys(dataByCurrency).length ? (
        <Chart sortedData={dataByCurrency} keys={["weekOne", "weekTwo", "weekThree", "weekFour"]} indexBy="date" />
    ) : null;
}

const mapStateToProps = (store) => ({
    advancePaymentsCompaniesList: SelectorsStoreAdvancePayments.getAdvancePaymentsList(store),
});

export default Connect(mapStateToProps)(Component);
