import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    fetchCampaign: (props) => API.executeWithAccessToken(PATH.READ_RANDOM_CAMPAIGN_BY_SECTION, props),
    fetchCampaignImage: (props) => API.executeWithAccessToken(PATH.READ_CAMPAIGN_IMAGE, props),
};

export const SelectorsAction = {
    loadCampaign: (props) => FactoryAction(TYPE.LOAD_CAMPAIGN_REQUEST, props),
    removeCampaigns: () => FactoryAction(TYPE.REMOVE_CAMPAIGNS),
    loadCampaignImage: (props) => FactoryAction(TYPE.LOAD_CAMPAIGN_IMAGE, props),
    loadCampaignFailure: () => FactoryAction(TYPE.LOAD_CAMPAIGN_FAILURE),
    loadCampaignSuccess: (props) => FactoryAction(TYPE.LOAD_CAMPAIGN_SUCCESS, props),
};

export const SelectorsStore = {
    getCampaigns: (store) => store[NAME].items,
    getFetching: (store) => store[NAME].fetching,
};
