import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "share";

export const PATH = {
    SHARE_LOCAL: "/share.local",
};

export const TYPE = FactoryType(NAME, [
    "SHARE_LOCAL_REQUEST",
    "SHARE_FOREIGN_REQUEST",
    "SHARE_LOCAL_SUCCESS",
    "SHARE_LOCAL_ERROR",
    "SHARE_LOCAL_CANCEL",
]);

export const TYPE_SHARE_LOCAL = {
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
    CANCEL: "CANCEL",
};

export const PROP = {};

PROP.types = {
    fetching: PropTypes.bool,
    shareLocal: PropTypes.bool,
    typeAccount: PropTypes.string,
    pathFrom: PropTypes.string,
    ...TypesRedux,
};

PROP.defaults = {
    fetching: false,
    shareLocal: false,
    typeAccount: "",
    pathFrom: "",
    ...DefaultsRedux,
};
