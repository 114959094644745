import React from "react";

import PropTypes from "prop-types";

import * as UtilsI18n from "~/util/i18n";

export const NAME = "HeadingDataGroup";

export const PROP = {
    types: {
        data: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
            .isRequired,
        label: PropTypes.string,
        containerClassName: PropTypes.string,
        dataClassName: PropTypes.string,
    },
    defaults: { label: "", containerClassName: "data-wrapper", dataClassName: "data-desc" },
};
export function Component(props) {
    const { label, data, dataClassName, containerClassName } = props;

    const renderContent = () => {
        if (label === "") {
            return <span className={dataClassName}>{data}</span>;
        }

        return (
            <React.Fragment>
                <span className="data-label">{UtilsI18n.get(label)}</span> <span className={dataClassName}>{data}</span>
            </React.Fragment>
        );
    };

    return <div className={containerClassName}>{renderContent()}</div>;
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;
export default Component;
