import React from "react";

import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, ISIN_MAX_LENGTH_WM, SHARE_CLASSIFICATION } from "~/constants";
import {
    SelectorsAction as SelectorsActionWMShares,
    SelectorsStore as SelectorsStoreWMShares,
} from "~/store/wm/shares";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import RadioOption from "~/components/RadioOption";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TextField from "~/pages/_components/fields/TextField";

import TickerField from "./TickerField";
import Style from "./_Form.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

const FORM_ID = "wm.shares.buy.search";

export function Component(props) {
    const { dispatch, resetForm, setFieldValue, values } = props;

    const handleRadioChange = (value) => {
        resetForm({
            isin: EMPTY_STR,
            issuer: EMPTY_STR,
            ticker: { ticker1: EMPTY_STR, ticker2: EMPTY_STR },
        });
        dispatch(SelectorsActionWMShares.clearForm());
        setFieldValue("classification", value);
    };

    return (
        <Form id={Style.ID}>
            <Box className="mt-5">
                <FieldLabel labelKey="wm.shares.buy.search.type.label" />
                <Box flex>
                    <Field name="classification">
                        {({ field: { value } }) => (
                            <React.Fragment>
                                <RadioOption
                                    checked={value === SHARE_CLASSIFICATION.ORDINARY}
                                    formId={FORM_ID}
                                    group="classification"
                                    mode="edit"
                                    onChange={handleRadioChange}
                                    value={SHARE_CLASSIFICATION.ORDINARY}
                                />
                                <RadioOption
                                    checked={value === SHARE_CLASSIFICATION.PREFERRED}
                                    className="ml-3"
                                    formId={FORM_ID}
                                    group="classification"
                                    mode="edit"
                                    onChange={handleRadioChange}
                                    value={SHARE_CLASSIFICATION.PREFERRED}
                                />
                            </React.Fragment>
                        )}
                    </Field>
                </Box>
            </Box>

            {values.classification === SHARE_CLASSIFICATION.ORDINARY ? (
                <Field
                    autoFocus={false}
                    component={TickerField}
                    idForm={FORM_ID}
                    name="ticker"
                    type="text"
                    hidePlaceholder
                />
            ) : (
                <Field
                    autoFocus={false}
                    component={TextField}
                    idForm={FORM_ID}
                    name="isin"
                    type="text"
                    hidePlaceholder
                    maxLength={ISIN_MAX_LENGTH_WM}
                />
            )}

            <Field
                autoFocus={false}
                component={TextField}
                idForm={FORM_ID}
                name="issuer"
                type="text"
                hidePlaceholder
                maxLength={50}
            />
            <Button className="button" id="filter" type="submit" variant="primary">
                {UtilsI18n.get("global.search")}
            </Button>
        </Form>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        isFetching: SelectorsStoreWMShares.getIsFetching(store),
        papersList: SelectorsStoreWMShares.getPapersList(store),
        previewData: SelectorsStoreWMShares.getPreviewData(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        mapPropsToValues: (props) => {
            const { previewData = {} } = props;
            return {
                classification: previewData.classification || SHARE_CLASSIFICATION.ORDINARY,
                ticker: previewData.ticker || EMPTY_STR,
                isin: previewData.isin || EMPTY_STR,
                issuer: EMPTY_STR,
            };
        },
        handleSubmit: (values, formikBag) => {
            const { dispatch } = formikBag.props;
            const { classification, isin, issuer, ticker } = values;

            dispatch(
                SelectorsActionWMShares.searchSharesRequest({
                    previewData: {
                        classification,
                        ...(classification === SHARE_CLASSIFICATION.PREFERRED && { isin }),
                        issuer,
                        ...(classification === SHARE_CLASSIFICATION.ORDINARY && {
                            ticker1: ticker.ticker1,
                            ticker2: ticker.ticker2,
                        }),
                    },
                    formikBag: {
                        ...formikBag,
                    },
                }),
            );
        },
    }),
)(Component);
