import { replace as Replace } from "connected-react-router";
import { call as Call, put as Put, takeLatest as TakeLatest, select as Select } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE, EMPTY_STR, SUPPORTED_FILE_TYPES } from "~/constants";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionSession } from "~/store/session";
import DeviceUtils from "~/util/device";
import { download, downloadPdf, downloadXls, downloadZip } from "~/util/download";
import { adjustIdFieldErrors } from "~/util/form";
import * as i18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsStore, SelectorsAction } from "./_selectors";

export default [
    TakeLatest(TYPE.ACCOUNT_READ_REQUEST, accountRead),
    TakeLatest(TYPE.ACCOUNT_DETAILS_REQUEST, accountDetails),
    TakeLatest(TYPE.ACCOUNT_DOWNLOAD_STATEMENT_REQUEST, downloadStatement),
    TakeLatest(TYPE.ACCOUNT_FETCH_MORE_MOVEMENTS_REQUEST, fetchMoreMovements),
    TakeLatest(TYPE.ACCOUNT_MOVEMENT_DETAILS_REQUEST, movementDetails),
    TakeLatest(TYPE.EDIT_MOVEMENT_NOTE_REQUEST, editMovementNote),
    TakeLatest(TYPE.READ_MOVEMENT_NOTE_REQUEST, readMovementNote),
    TakeLatest(TYPE.FETCH_ACCOUNT, fetchAccount),
    TakeLatest(TYPE.LIST_ACCOUNT_STATEMENTS_REQUEST, listStatements),
    TakeLatest(TYPE.LIST_ACCOUNTS_REQUEST, listAccountsRequest),
    TakeLatest(TYPE.DOWNLOAD_MOVEMENTS_REQUEST, downloadMovements),
    TakeLatest(TYPE.ACCOUNT_FETCH_MOVEMENTS_REQUEST, fetchMovements),
    TakeLatest(TYPE.ACCOUNT_STATE_REQUEST, stateOfAccounts),
    TakeLatest(TYPE.PDF_STATE_REQUEST, pdfStateAccount),
    TakeLatest(TYPE.LIST_BILLING_TICKET_REQUEST, listBillingTickets),
    TakeLatest(TYPE.DOWNLOAD_BILLING_TICKET_PDF_REQUEST, downloadBillingTicketPDF),
];

function* accountDetails(props) {
    const { idAccount, formikBag } = props;
    const filters = yield Select(SelectorsStore.getFilters);
    const response = yield Call(SelectorsMiddleware.listMovements, { filters, idAccount });

    if (response) {
        if (response.type === RESPONSE_TYPE.WARNING && formikBag) {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        } else if (response.status === 200) {
            yield Put({
                type: TYPE.ACCOUNT_DETAILS_SUCCESS,
                ...response.data.data,
            });
        }
    }

    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* accountRead(props) {
    const myProps = { ...props };
    delete myProps.type;
    const response = yield Call(SelectorsMiddleware.readAccount, myProps);
    if (response && response.status === 200) {
        yield Put({
            type: TYPE.ACCOUNT_READ_SUCCESS,
            ...response.data.data,
        });
    }
}

function* downloadStatement(props) {
    const myProps = { ...props };
    delete myProps.type;

    try {
        const response = yield Call(SelectorsMiddleware.downloadStatement, myProps);

        if (response && response.status === 200) {
            const { content, fileName } = response.data.data;

            downloadPdf(fileName, content);

            yield Put({
                type: TYPE.ACCOUNT_DOWNLOAD_STATEMENT_SUCCESS,
            });
        } else {
            yield Put({ type: TYPE.ACCOUNT_DOWNLOAD_STATEMENT_FAILURE });

            const errorMessage = i18n.get("accounts.movement.detail.statementFailure");

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: errorMessage,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.MOVEMENT_DETAIL],
                }),
            );
        }
    } catch (error) {
        yield Put({ type: TYPE.ACCOUNT_DOWNLOAD_STATEMENT_FAILURE });

        const errorMessage = i18n.get("accounts.movement.detail.statementFailure");

        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.MOVEMENT_DETAIL],
            }),
        );
    }
}

function* readMovementNote(props) {
    const myProps = { ...props };
    delete myProps.type;
    const response = yield Call(SelectorsMiddleware.readMovementNote, myProps);

    if (response && response.status === 200) {
        const { data } = response.data;
        data.note = data.note !== null ? data.note : EMPTY_STR;

        yield Put(SelectorsAction.readMovementNoteSuccess(data));
    } else {
        const errorMessage = i18n.get("accounts.movement.detail.readNoteError");

        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.MOVEMENT_DETAIL],
            }),
        );
    }
}

function* editMovementNote(props) {
    const myProps = { ...props };
    delete myProps.type;
    const response = yield Call(SelectorsMiddleware.editMovementNote, myProps);

    if (response && response.status === 200) {
        const confirmationMessage = i18n.get("accounts.movement.detail.noteSaved");

        const movements = yield Select(SelectorsStore.getMovements);
        movements[movements.findIndex((mov) => mov.idStatement === myProps.idStatement)].note = myProps.note;

        yield Put(SelectorsAction.editMovementNoteSuccess({ movements }));

        yield Put(
            SelectorsActionNotification.showNotification({
                message: confirmationMessage,
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.MOVEMENT_DETAIL],
            }),
        );
    } else {
        const errorMessage = i18n.get("accounts.movement.detail.noteUnsaved");

        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.MOVEMENT_DETAIL],
            }),
        );
    }
}

function* fetchMovements(props) {
    const myProps = { ...props };
    delete myProps.type;
    const response = yield Call(SelectorsMiddleware.listMovements, myProps);

    if (response && response.status === 200) {
        yield Put({
            type: TYPE.ACCOUNT_FETCH_MOVEMENTS_SUCCESS,
            ...response.data.data,
        });
    }
}

function* fetchMoreMovements(props) {
    const myProps = { ...props };
    delete myProps.type;
    const response = yield Call(SelectorsMiddleware.listMovements, myProps);

    if (response && response.status === 200) {
        yield Put({
            type: TYPE.ACCOUNT_FETCH_MORE_MOVEMENTS_SUCCESS,
            ...response.data.data,
        });
    }
}

function* listAccountsRequest(props) {
    const response = yield Call(SelectorsMiddleware.listAccounts);

    if (response && response.status === 200) {
        const { isFromQRModoOtpRequest } = props;
        const { data } = response.data;
        const { accounts, equivalentTotalBalance } = data;

        if (isFromQRModoOtpRequest) {
            const qrName = {
                en: data["qrName-en"],
                es: data["qrName-es"],
                pt: data["qrName-pt"],
            };

            const qrData = {
                qrCurrentInstallment: data.qrCurrentInstallment,
                qrDueDate: data.qrDueDate,
                qrEnabled: data.qrEnabled,
                qrLimitAmount: data.qrLimitAmount,
                qrLimitCurrency: data.qrLimitCurrency,
                qrMaxLimitAmount: data.qrMaxLimitAmount,
                qrMaxLimitCurrency: data.qrMaxLimitCurrency,
                qrName,
                qrTypeDiscount: data.qrTypeDiscount,
            };

            yield Put(SelectorsActionSession.saveQrData({ qrData }));
        }

        if (accounts.length === 1) {
            yield Put(Replace(`/accounts/${accounts[0].idProduct}`));
        } else {
            yield Put({
                type: TYPE.LIST_ACCOUNTS_SUCCESS,
                accounts,
                equivalentTotalBalance,
            });
        }
    }
}

function* listStatements(props) {
    const myProps = { ...props };
    delete myProps.type;
    const response = yield Call(SelectorsMiddleware.listStatements, myProps);

    if (response && response.status === 200) {
        yield Put({
            type: TYPE.LIST_ACCOUNT_STATEMENTS_SUCCESS,
            ...response.data.data,
        });
    }
}

function* movementDetails(props) {
    const myProps = { ...props };
    delete myProps.type;
    const response = yield Call(SelectorsMiddleware.movementDetails, myProps);

    if (response && response.status === 200) {
        yield Put({
            type: TYPE.ACCOUNT_MOVEMENT_DETAILS_SUCCESS,
            ...response.data.data,
        });
    }
}

function* fetchAccount(props) {
    const myProps = { ...props };
    delete myProps.type;
    const response = yield Call(SelectorsMiddleware.fetchAccount, myProps);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put({ type: TYPE.FETCH_ACCOUNT_FAILURE });
        yield Put(
            SelectorsActionNotification.showNotification({
                message: i18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.FORM],
            }),
        );
    } else {
        yield Put({
            type: TYPE.FETCH_ACCOUNT_SUCCESS,
            account: response.data.data.account,
        });
    }
}

function* downloadMovements(props) {
    const { idAccount, format, sequenceNumber } = props;
    const filters = yield Select(SelectorsStore.getFilters);
    const { dateFrom, dateTo, period } = filters || {};
    const { type, data } = yield Call(SelectorsMiddleware.downloadMovements, {
        idAccount,
        format,
        sequenceNumber,
        dateFrom,
        dateTo,
        period,
        ...filters,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put({ type: TYPE.DOWNLOAD_MOVEMENTS_FAILURE });
        yield Put(
            SelectorsActionNotification.showNotification({
                message: i18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.ACCOUNT_DETAILS],
            }),
        );
    } else {
        const { content, fileName } = data.data;

        switch (format) {
            case SUPPORTED_FILE_TYPES.CSV:
                download(fileName, content);

                break;
            case SUPPORTED_FILE_TYPES.XLS:
                downloadXls(fileName, content);

                break;
            default:
                // MULTICASH
                downloadZip(fileName, content);

                break;
        }

        yield Put({ type: TYPE.DOWNLOAD_MOVEMENTS_SUCCESS });
    }
}

function* stateOfAccounts(props) {
    const myProps = { ...props };
    delete myProps.type;
    const { type, data } = yield Call(SelectorsMiddleware.stateOfAccounts, myProps);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put({ type: TYPE.DOWNLOAD_MOVEMENTS_FAILURE });
        yield Put(
            SelectorsActionNotification.showNotification({
                message: i18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.ACCOUNT_DETAILS],
            }),
        );
    } else {
        yield Put({
            type: TYPE.ACCOUNT_STATE_SUCCESS,
            stateAccount: data.data.stateAccount,
        });
    }
}

function* pdfStateAccount(props) {
    const { date, origen } = props;
    const {
        type,
        data: { message, data },
    } = yield Call(SelectorsMiddleware.pdfDownload, { date, origen });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put({ type: TYPE.PDF_STATE_SUCCESS });
        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.ACCOUNT_DOWNLOAD_PDF_FAILURE, SCOPE.ACCOUNT_DETAILS],
            }),
        );
    } else {
        const { content, filename } = data;
        if (DeviceUtils.isMobileNative()) {
            try {
                yield Put({ type: TYPE.PDF_STATE_SUCCESS });
                yield Call(window.pdf.save, data);
            } catch (error) {
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.ACCOUNT_DETAILS],
                    }),
                );
            }
        } else {
            downloadPdf(filename, content);
            yield Put({ type: TYPE.PDF_STATE_SUCCESS });
        }
    }
}

function* listBillingTickets() {
    const filters = yield Select(SelectorsStore.getEBillingTicketsFilters);
    const page = yield Select(SelectorsStore.getPageNumber);

    const { year, month, currency } = filters;
    const requestProps = {
        year,
        month,
        currency,
        page,
    };
    const response = yield Call(SelectorsMiddleware.listBillingTickets, requestProps);
    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.listBillingTicketsFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: i18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.ACCOUNT_DETAILS],
            }),
        );
    } else {
        const {
            data: {
                billingTickets: { tickets, next },
            },
        } = response.data;
        yield Put(SelectorsAction.listBillingTicketsSuccess({ tickets, next }));
    }
}

function* downloadBillingTicketPDF(props) {
    const { number: cfeNumber, serie, docTypeNumber: cfeType } = props;
    const response = yield Call(SelectorsMiddleware.getBillingTicketPDF, { cfeNumber, serie, cfeType });

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.downloadBillingTicketFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: i18n.get("client.account.billingTickets.noFile"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.ACCOUNT_DETAILS],
            }),
        );
    } else {
        const { content, fileName } = response.data.data;
        downloadPdf(fileName, content);
        yield Put(SelectorsAction.downloadBillingTicketSuccess());
    }
}
