import { routerActions } from "connected-react-router";
import { connect as Connect } from "react-redux";
import { bindActionCreators } from "redux";

import { SelectorsStore as SelectorsStoreSignatures } from "~/store/administration/common/signatures";
import { SelectorsActionSignaturesAdvanced } from "~/store/administration/advanced";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsStore as StoreSession } from "~/store/session";

import SignaturesSchemes from "~/pages/administration/_components/SignaturesSchemes";
import * as UtilsI18n from "~/util/i18n";

const mapStateToProps = (store) => ({
    activeEnvironment: StoreSession.getActiveEnvironment(store),
    currentPage: SelectorsStoreSignatures.currentPage(store),
    fetching: SelectorsStoreSignatures.fetching(store),
    fetchingExport: SelectorsStoreSignatures.isFetchingExport(store),
    fetchingMoreSignaturesSchemes: SelectorsStoreSignatures.isFetchingMoreSignaturesSchemes(store),
    hasMoreData: SelectorsStoreSignatures.hasMoreData(store),
    signaturesSchemes: SelectorsStoreSignatures.signaturesSchemes(store),
    totalPages: SelectorsStoreSignatures.totalPages(store),
    i18n: {
        signers: StoreI18n.getMessage(store, "administration.signatures.list.signers"),
        transactions: StoreI18n.getMessage(store, "administration.signatures.list.transactions"),
        functionalGroups: StoreI18n.getMessage(store, "administration.signatures.list.functionalGroups"),
        amount: StoreI18n.getMessage(store, "administration.signatures.list.amountPerPeriod"),
        get: (msg) => UtilsI18n.get(msg),
    },
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(SelectorsActionSignaturesAdvanced, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default Connect(mapStateToProps, mapDispatchToProps)(SignaturesSchemes);
