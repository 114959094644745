import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { MODE } from "~/constants/form";
import { generateId as GenID } from "~/util/general";
import * as UtilI18n from "~/util/i18n";
import { Categorize } from "~/util/prop";

import Image from "~/components/Image";

import Header from "../../_Header";
import Style from "./Steps.rules.scss";
import Form from "./index";

export const { NAME, TYPE } = Style;
export const PROP = {
    types: {
        step: PropTypes.string.isRequired,
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        hideSteps: PropTypes.bool,
        "head-title": PropTypes.string,
        "head-onBack": PropTypes.func,
    },
    defaults: {
        className: EMPTY_STR,
        hideSteps: false,
        "head-title": EMPTY_STR,
        "head-onBack": null,
    },
};

export function Component(props) {
    const { children, className, step, head, hideSteps, scopeToShowNotification } = Categorize(props);
    const { title, onBack, onBackWeb } = head;

    const modes = [
        {
            step: MODE.EDIT,
            title: UtilI18n.get("forms.steps.transactionDetails"),
            image: "transactions-step.svg",
        },
        {
            step: MODE.PREVIEW,
            title: UtilI18n.get("forms.steps.confirmation"),
            image: "icono-llave-digital.svg",
        },
        {
            step: MODE.VIEW,
            title: UtilI18n.get("forms.steps.summary"),
            image: "check-step.svg",
        },
    ];

    const stepIndex = modes.findIndex((e) => e.step === step);
    const hasBackClass = onBack || onBackWeb ? "hasBack" : EMPTY_STR;
    const hideStepsSection = hideSteps ? "hide-steps" : EMPTY_STR;
    const finalHeader = (
        <Header className={`${hasBackClass}`}>
            {hasBackClass === "hasBack" && (
                <Header.Button
                    aria-label="Back"
                    role="navigation"
                    image="left-arrow.svg"
                    onClick={onBack || onBackWeb}
                />
            )}
            <Header.Title value={title} />
        </Header>
    );

    return (
        <Form
            {...props}
            header={finalHeader}
            scopeToShowNotification={scopeToShowNotification}
            className={className ? `${className} ${Style.ID}` : Style.ID}>
            <Header>
                <ul className={`${Style.STEPS} ${hideStepsSection}`}>
                    {modes.map((currentMode, index) => {
                        return (
                            <li key={GenID()} className={(index <= stepIndex && Style.ACTIVE) || EMPTY_STR}>
                                <Image src={currentMode.image} classNameInner="step-icon" />
                                <span>{currentMode.title}</span>
                            </li>
                        );
                    })}
                    <hr />
                </ul>
            </Header>
            {children}
        </Form>
    );
}
Component.displayName = NAME;
Component.proptTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
