import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { Categorize } from "~/util/prop";

import Image from "~/components/Image";

import Header from "../../_Header";
import Style from "./Filter.rules.scss";
import Form from "./index";

export const { NAME, TYPE } = Style;
export const PROP = {
    types: {
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        "head-title": PropTypes.string,
        "head-onBack": PropTypes.func,
    },
    defaults: {
        className: EMPTY_STR,
        "head-title": EMPTY_STR,
        "head-onBack": null,
    },
};

export function Component(props) {
    const { children, className, head, image } = Categorize(props);
    const { title, onBack } = head;

    const hasBackClass = onBack ? "hasBack" : EMPTY_STR;
    const finalHeader = (
        <Header className={`${hasBackClass}`}>
            {onBack && <Header.Button aria-label="Back" role="navigation" image="left-arrow.svg" onClick={onBack} />}

            <Header.Title value={title} />
            {image && <Image src={image} classNameOuter="icon" />}
        </Header>
    );

    return (
        <Form {...props} className={className ? `${className} ${Style.ID}` : Style.ID} header={finalHeader}>
            {children}
        </Form>
    );
}
Component.displayName = NAME;
Component.proptTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
