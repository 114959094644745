import React from "react";

import { Formik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

import { AMOUNT, NO_AMOUNT, SCOPE, TYPE_ADMINISTRATION, ZERO_NUMBER } from "~/constants";
import Container from "~/containers/Internal/Administration/Simple";
import * as ConfigUtil from "~/util/config";
import * as I18nUtils from "~/util/i18n";

import FormSignatures from "~/pages/administration/_components/formComponentsSchema/FormSignatures";

import Style from "./SignatureSchemes.rules.scss";

export const NAME = "SignaturesSchemeCreate";

export const PROP = {
    types: {
        actions: PropTypes.shape({
            createSignaturesSchemePreRequest: PropTypes.func,
        }),
        activeEnvironment: PropTypes.shape({
            administrationScheme: PropTypes.string,
        }).isRequired,
        capFrequencyList: PropTypes.arrayOf(PropTypes.string),
        credentialGroups: PropTypes.arrayOf(
            PropTypes.shape({
                idCredentialGroup: PropTypes.string,
                credentials: PropTypes.arrayOf(PropTypes.string),
            }),
        ).isRequired,
        environmentProducts: PropTypes.arrayOf(Object),
        fetching: PropTypes.bool,
        functionalGroups: PropTypes.arrayOf(
            PropTypes.shape({
                idFeature: PropTypes.string,
            }),
        ),
        isDesktop: PropTypes.bool.isRequired,
        routerActions: PropTypes.shape({
            goBack: PropTypes.func,
            push: PropTypes.func,
        }),
        selectedFunctionalGroups: PropTypes.arrayOf(PropTypes.string),
    },
    defaults: {
        actions: null,
        environmentProducts: [],
        fetching: false,
        functionalGroups: [],
        routerActions: null,
    },
};

export function Component(props) {
    const {
        actions,
        activeEnvironment: { administrationScheme },
        fetching,
        idForm,
        routerActions,
        signaturesSchemes,
    } = props;

    const isSimple = administrationScheme === TYPE_ADMINISTRATION.SIMPLE;
    const isAdvanced = administrationScheme === TYPE_ADMINISTRATION.ADVANCED;
    const signatureTypeAmount = signaturesSchemes?.find((scheme) => scheme.signatureType === AMOUNT);
    const signatureTypeNoAmount = signaturesSchemes?.find((scheme) => scheme.signatureType === NO_AMOUNT);

    React.useLayoutEffect(() => {
        if (isSimple || isAdvanced) {
            routerActions.push("/");
        }

        actions.listSignaturesSchemesRequest();
        actions.createSignaturesSchemePreRequest();
    }, [actions, isAdvanced, isSimple, routerActions]);

    const handleSubmit = React.useCallback(
        (propsForm, formikBag) => {
            const { credentials, formActions, idActivity, idTransaction, signatureDispatch } = props;
            const { amountSignatureLevelsCounts, noAmountSignatureLevelsCounts } = propsForm;
            const signatureTypeAmountModify = {
                idSignature: signatureTypeAmount?.idSignature,
                signatureLevelsCounts: amountSignatureLevelsCounts,
                typeSignature: signatureTypeAmount?.signatureType,
            };
            const signatureTypeNoAmountModify = {
                idSignature: signatureTypeNoAmount?.idSignature,
                signatureLevelsCounts: noAmountSignatureLevelsCounts,
                typeSignature: signatureTypeNoAmount?.signatureType,
            };

            if (!fetching) {
                if (!idTransaction) {
                    actions.modifySignaturesSchemeRequest({
                        signatureData: {
                            administrationScheme,
                            signatureTypeAmountModify,
                            signatureTypeNoAmountModify,
                            signatureDispatch,
                        },
                        formikBag,
                    });
                } else {
                    const paramsSign = { idForm: null, idActivity, idTransaction };

                    formActions.signTransaction({ ...paramsSign, credentials, formikBag });
                }
            }
        },
        [actions, administrationScheme, fetching, props, signatureTypeAmount, signatureTypeNoAmount],
    );

    const renderContent = () => {
        const initialValues = {
            amountSignatureLevelsCounts: { A: signatureTypeAmount?.groupsMap.A },
            noAmountSignatureLevelsCounts: { A: signatureTypeNoAmount?.groupsMap.A },
        };

        const validationSchema = () => {
            const maxSignersCount = ConfigUtil.get("administration.signatures.maxNeeded");

            return Yup.lazy(() =>
                Yup.object().shape({
                    amountSignatureLevelsCounts: Yup.object().shape({
                        A: Yup.number()
                            .moreThan(
                                ZERO_NUMBER,
                                `${I18nUtils.get(`${idForm}.signersCount.notInRange`, null, {
                                    maxSignersCount,
                                })}  ${ConfigUtil.get("administration.signatures.maxNeeded")}`,
                            )
                            .max(
                                maxSignersCount,
                                `${I18nUtils.get(`${idForm}.signersCount.notInRange`, null, {
                                    maxSignersCount,
                                })}  ${ConfigUtil.get("administration.signatures.maxNeeded")}`,
                            )
                            .required(I18nUtils.get(`${idForm}.signersCount.medium.required`))
                            .typeError(I18nUtils.get(`${idForm}.signersCount.medium.required`)),
                    }),
                    noAmountSignatureLevelsCounts: Yup.object().shape({
                        A: Yup.number()
                            .moreThan(
                                ZERO_NUMBER,
                                `${I18nUtils.get(`${idForm}.signersCount.notInRange`, null, {
                                    maxSignersCount,
                                })}  ${ConfigUtil.get("administration.signatures.maxNeeded")}`,
                            )
                            .max(
                                maxSignersCount,
                                `${I18nUtils.get(`${idForm}.signersCount.notInRange`, null, {
                                    maxSignersCount,
                                })}  ${ConfigUtil.get("administration.signatures.maxNeeded")}`,
                            )
                            .required(I18nUtils.get(`${idForm}.signersCount.medium.required`))
                            .typeError(I18nUtils.get(`${idForm}.signersCount.medium.required`)),
                    }),
                }),
            );
        };

        return (
            <div>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                    validationSchema={validationSchema}>
                    {(propsFormik) => (
                        <div id={Style.ID}>
                            <FormSignatures
                                administrationScheme={administrationScheme}
                                signatureGroupMap={{}}
                                idForm={idForm}
                                typeAction="modify"
                                routerActions={routerActions}
                                {...propsFormik}
                            />
                        </div>
                    )}
                </Formik>
            </div>
        );
    };

    const handleBack = React.useCallback(() => {
        routerActions.goBack();
    }, [routerActions]);

    return (
        <Container
            name={NAME}
            wait={fetching}
            head-onBack={handleBack}
            head-title="administration.signatures.modify.title"
            image="administration-title-icon.svg"
            scopeToShowNotification={SCOPE.ADMINISTRATION_SIGNATURES_SCHEME}>
            {!fetching && renderContent()}
        </Container>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
