import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "channels";

export const TYPE = (name) =>
    FactoryType(name, [
        "LOAD_CHANNELS_REQUEST",
        "LOAD_CHANNELS_FAILURE",
        "LOAD_CHANNELS_SUCCESS",
        "UPDATE_CHANNELS_PREVIEW",
        "UPDATE_CHANNELS_PREVIEW_SUCCESS",
        "UPDATE_CHANNELS_REQUEST",
        "UPDATE_CHANNELS_REQUEST_SUCCESS",
        "LOAD_CHANNELS_TICKET_REQUEST",
        "LOAD_CHANNELS_TICKET_FAILURE",
        "LOAD_CHANNELS_TICKET_SUCCESS",
        "LOAD_FORM_SUCCESS",
    ]);

export const PROP = {};

PROP.members = {};

PROP.types = {
    caps: PropTypes.object,
    currencies: PropTypes.array,
    topAmount: PropTypes.object,
    nonRemovableChannels: PropTypes.array,
    enabledChannels: PropTypes.array,
    enabledChannelsFrequencies: PropTypes.array,
    fetching: PropTypes.bool,
    dataToSubmit: PropTypes.object,
    idTransaction: PropTypes.any,
    idActivity: PropTypes.any,
    credentialGroups: PropTypes.array,
    fetchingTicket: PropTypes.bool,
    ...TypesRedux,
};

PROP.defaults = {
    caps: {},
    currencies: [],
    topAmount: {},
    nonRemovableChannels: [],
    enabledChannels: [],
    enabledChannelsFrequencies: [],
    fetching: true,
    dataToSubmit: {},
    idTransaction: null,
    idActivity: null,
    credentialGroups: [],
    fetchingTicket: true,
    ...DefaultsRedux,
};
