import { FactoryAction } from "~/util/redux";

import { NAME, TYPE } from "./_consts";

export const SelectorsAction = {
    cancelAdministrativeTransaction: (props) => FactoryAction(TYPE.CANCEL_ADMINISTRATIVE_TRANSACTION, props),
    cancelPreviewStep: () => FactoryAction(TYPE.CANCEL_PREVIEW_STEP),
    loadAdministrationTicketFailure: () => FactoryAction(TYPE.LOAD_ADMINISTRATION_TICKET_FAILURE),
    loadAdministrationTicketRequest: (props) => FactoryAction(TYPE.LOAD_ADMINISTRATION_TICKET_REQUEST, props),
    loadAdministrationTicketSuccess: (props) => FactoryAction(TYPE.LOAD_ADMINISTRATION_TICKET_SUCCESS, props),
    signAdministrativeTransactionPreview: (props) =>
        FactoryAction(TYPE.SIGN_ADMINISTRATIVE_TRANSACTION_PREVIEW_REQUEST, props),
    signAdministrativeTransactionRequest: (props) => FactoryAction(TYPE.SIGN_ADMINISTRATIVE_TRANSACTION, props),
    signAdministrativeTransactionSuccess: (props) => FactoryAction(TYPE.SIGN_ADMINISTRATIVE_TRANSACTION_SUCCESS, props),
};

export const SelectorsStore = {
    getData: (store) => store[NAME].data,
    getMode: (store) => store[NAME].mode,
    getUser: (store) => store[NAME].user,
    isFetching: (store) => store[NAME].fetching,
};
