import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";

export const NAME = "ColumnBody";

export const PROP = {
    types: { children: PropTypes.node.isRequired, columnClass: PropTypes.string },
    defaults: { columnClass: EMPTY_STR },
};
export function Component(props) {
    const { columnClass, children } = props;
    return <div className={`col-body ${columnClass}`}>{children}</div>;
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
