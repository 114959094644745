import React from "react";

import { Field, Form } from "formik";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { bindActionCreators, compose } from "redux";

import { MODE } from "~/constants/form";
import {
    SelectorsAction as navigationActions,
    SelectorsStore as SelectorsStoreGroup,
} from "~/store/administration/advanced/group";
import * as i18nUtils from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";
import RadioOption from "~/components/RadioOption";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import FormGroup from "~/pages/_components/fields/FormGroup";
import TextField from "~/pages/_components/fields/TextField";

import AdvancedPermissionsForm from "~/pages/administration/_components/AdvancedPermissionsForm";
import PermissionsPanel from "~/pages/administration/_components/advancedPermissionsForm/PermissionsPanel";

import Style from "./Form.rules.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {
        actions: PropTypes.shape({
            reset: PropTypes.func,
            updateHasBack: PropTypes.func,
            updateHasClose: PropTypes.func,
        }).isRequired,
        buttonText: PropTypes.string.isRequired,
        credentialGroups: PropTypes.arrayOf(
            PropTypes.shape({
                credentials: PropTypes.arrayOf(PropTypes.string),
                idCredentialGroup: PropTypes.string,
            }),
        ).isRequired,
        handleSubmit: PropTypes.func.isRequired,
        idForm: PropTypes.string.isRequired,
        isSubmitting: PropTypes.bool.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }).isRequired,
        mode: PropTypes.oneOf([MODE.EDIT, MODE.VIEW]),
    },
    defaults: {
        mode: MODE.EDIT,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    componentDidMount() {
        this.updateCloseBackButtonsVisibility();
    }

    componentDidUpdate(prevProps) {
        const {
            location: { pathname },
        } = this.props;

        if (prevProps.location.pathname !== pathname) {
            this.updateCloseBackButtonsVisibility();
        }
    }

    updateCloseBackButtonsVisibility = () => {
        const {
            actions,
            location: { pathname },
        } = this.props;

        actions.reset();

        if (pathname.match(/create\/step/)) {
            actions.updateHasClose(true);
        } else if (pathname.match(/create\/confirm/)) {
            actions.updateHasBack(true);
            actions.updateHasClose(true);
        } else if (pathname.match(/group\/\d+\/step/)) {
            actions.updateHasClose(true);
        } else if (pathname.match(/group\/\d+\/confirm/)) {
            actions.updateHasBack(true);
            actions.updateHasClose(true);
        }
    };

    validateName = (value) => {
        const { idForm } = this.props;

        if (!value) {
            return i18nUtils.get(`${idForm}.name.required`);
        }

        return undefined;
    };

    handleRadioChange = (value, name) => {
        const { setFieldValue } = this.props;

        setFieldValue(name, value);
    };

    render() {
        const {
            adminGroup,
            adminUsers,
            availableUsers,
            buttonText,
            credentialGroups,
            handleSubmit,
            idForm,
            isSubmitting,
            members,
            mode,
            values,
            ...props
        } = this.props;

        const { description, name } = values;

        return (
            <Form className={CLASS}>
                <Box>
                    <Box flex justify="end" className="my-5 pr-3">
                        <h4>{i18nUtils.get(`${idForm}.title.information`)}</h4>
                    </Box>
                    <Box className="my-5">
                        <Field
                            name="name"
                            idForm={idForm}
                            component={TextField}
                            mode={mode}
                            value={name}
                            validate={this.validateName}
                            maxLength={253}
                            hidePlaceholder
                        />

                        <Field
                            name="description"
                            idForm={idForm}
                            component={TextField}
                            renderAs="textarea"
                            optional={i18nUtils.get(`${idForm}.description.optional.text`)}
                            mode={mode}
                            value={description}
                            maxLength={253}
                            hidePlaceholder
                        />

                        <FormGroup>
                            <FieldLabel labelKey={`${idForm}.status.label`} />
                            <Field name="status">
                                {({ field: { value } }) => (
                                    <React.Fragment>
                                        <RadioOption
                                            group="status"
                                            checked={value === "active"}
                                            formId={idForm}
                                            onChange={this.handleRadioChange}
                                            value="active"
                                            mode={mode}
                                        />
                                        <RadioOption
                                            group="status"
                                            checked={value === "blocked"}
                                            formId={idForm}
                                            onChange={this.handleRadioChange}
                                            value="blocked"
                                            mode={mode}
                                        />
                                    </React.Fragment>
                                )}
                            </Field>
                        </FormGroup>
                    </Box>
                    <Box flex justify="end" className="my-5 pr-3">
                        <I18n tag="h4" id="administration.groups.permissions" />
                    </Box>
                    <Box flex justify="between" className="w-100 my-5">
                        <PermissionsPanel
                            mode={mode}
                            render={(content) => (
                                <React.Fragment>
                                    <Box className="w-50 pr-4">
                                        <AdvancedPermissionsForm values={values} {...props} mode={mode} />
                                    </Box>
                                    <Box className="w-50 pl-2">{content}</Box>
                                </React.Fragment>
                            )}
                        />
                    </Box>
                </Box>
                <Box className="footer-description">
                    <I18n tag="h4" id="administration.permissions.pay.maxAmount.description" />
                    <I18n tag="h4" id="administration.permissions.preferentialTradingPrice.description" />
                </Box>
                <Box flex justify="center" className="my-5 w-100">
                    <Button type="submit" variant="primary" loading={isSubmitting}>
                        <I18n id="administration.advanced.group.create.button.confirm.text" />
                    </Button>
                </Box>
            </Form>
        );
    }
}

const mapStateToProps = (store) => ({
    adminGroup: SelectorsStoreGroup.isAdminGroup(store),
    adminUsers: SelectorsStoreGroup.getAdminUsers(store),
    availableUsers: SelectorsStoreGroup.getAvailableUsers(store),
    members: SelectorsStoreGroup.getMembers(store),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(navigationActions, dispatch),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Component);
