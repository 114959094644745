import React from "react";

import { connect as Connect } from "react-redux";

import { SelectorsStore as StoreConfig } from "~/store/config";

import { Section } from "~/components/Component";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";

import Namespace from "./_Footer.scss";

export const { NAME, TYPE } = Namespace;
export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const {
        dispatch,
        mobileNumberLabel,
        mobileNumberValue,
        phoneNumberLabel,
        phoneNumberValue,
        portalUrlLabel,
        portalUrlValue,
        ...rest
    } = props;

    const toHSBC = () => {
        window.common.openLink(portalUrlValue, portalUrlLabel);
    };

    return (
        <Section {...rest} tag="footer" name={NAME}>
            <nav role="navigation" aria-label="footer primary">
                <span className="phone no-link">
                    <Image src="telephone.svg" />
                    <I18n id="core.contactNumber" defaultValue="2915 1010" />
                </span>

                <span className="domain no-link">
                    <a onClick={() => toHSBC()}>
                        <I18n id="core.portal.url" defaultValue="hsbc.com.uy" />
                    </a>
                </span>

                <span className="whats no-link">
                    <Image src="whatsapp-external-footer.svg" />
                    <I18n id="core.mobile.contactNumber" defaultValue="2915 1010" />
                </span>

                <Link className="locate" to="/mapLocations">
                    <I18n id="footer.branches" defaultValue="Sucursales" />
                </Link>

                <Link className="secure" to="/security">
                    <I18n id="footer.security" defaultValue="Seguridad" />
                </Link>

                <Link className="faq" to="/frequentQuestions">
                    <I18n id="footer.frequentQuestions" defaultValue="Preguntas Frecuentes" />
                </Link>

                <Link className="terms" to="/generalConditions">
                    <I18n id="footer.generalConditions" defaultValue="Condiciones generales" />
                </Link>
            </nav>
        </Section>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    mobileNumberLabel: StoreConfig.getItem(store, { key: "core.mobile.contactNumber.label" }),
    mobileNumberValue: StoreConfig.getItem(store, { key: "core.mobile.contactNumber.value" }),
    phoneNumberLabel: StoreConfig.getItem(store, { key: "core.contactNumber.label" }),
    phoneNumberValue: StoreConfig.getItem(store, { key: "core.contactNumber.value" }),
    portalUrlLabel: StoreConfig.getItem(store, { key: "core.portal.url.label" }),
    portalUrlValue: StoreConfig.getItem(store, { key: "core.portal.url.value" }),
});

export default Connect(mapStateToProps)(Component);
