import React from "react";

import { push as Push } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { BIOMETRIC_TYPES, SCOPE } from "~/constants";
import Container from "~/containers/External/Dash";
import { SelectorsAction as SelectorsActionBiometric } from "~/store/biometric";
import { SelectorsStore as SelectorsStoreChangeMyPhone } from "~/store/changeMyPhone";
import { SelectorsStore as SelectorsStoreEnrollment } from "~/store/enrollment";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";

import CancelConfirmationModal from "~/pages/enrollment/_CancelConfirmationModal";

import Style from "./BiometricTypeChoice.rules.scss";

export const { NAME, CLASS } = Style;

const FORM_ID = "biometricTypeChoice";

export const PROP = {
    types: {
        fetching: PropTypes.bool,
    },
    defaults: {
        fetching: false,
    },
};

export function Component(props) {
    const { dispatch, fetching, isChangeMyPhone } = props;
    const [modal, setModal] = React.useState(false);

    const handleSelectBiometricType = (biometricType) => {
        dispatch(SelectorsActionBiometric.selectBiometricType({ biometricType }));

        if (isChangeMyPhone) {
            dispatch(Push("/changeMyPhone/registerBiometric"));
        } else {
            dispatch(Push("/enrollment/registerBiometric"));
        }
    };

    const showModal = () => {
        setModal(true);
    };

    const handleConfirmation = () => {
        dispatch(Push("/"));
    };

    const handleCancelation = () => {
        setModal(false);
    };

    return (
        <Container
            head-onClose={showModal}
            name={NAME}
            scopeToShowNotification={SCOPE.BIOMETRIC_TYPE_CHOICE}
            wait={fetching}>
            <section className={Style.CLASS}>
                <CancelConfirmationModal
                    show={modal}
                    handleCancelation={handleCancelation}
                    handleConfirmation={handleConfirmation}
                />
                <div className="login-step">
                    <div className="login-header">
                        <span className="login-header-text">
                            <I18n id={`${FORM_ID}.title`} />
                        </span>
                    </div>
                    <div className="login-row">
                        <div className="login-image">
                            <Image src="login-ibanca.svg" className="login-image-icon" />
                        </div>
                    </div>
                    <div className="login-row">
                        <div className="login-form">
                            <div className="login-form-field">
                                <div className="field-information">
                                    <I18n id={`${FORM_ID}.selectType.label`} />
                                    <I18n id={`${FORM_ID}.selectType.question`} />
                                </div>
                            </div>
                        </div>
                        <div className="container-buttons">
                            <Button
                                bsStyle="primary"
                                label={`${FORM_ID}.selectType.button.yes`}
                                type="submit"
                                onClick={() => handleSelectBiometricType(BIOMETRIC_TYPES.fingerPrint)}
                            />
                            <Button
                                className="btn-facephi"
                                bsStyle="primary"
                                label={`${FORM_ID}.selectType.button.no`}
                                type="submit"
                                onClick={() => handleSelectBiometricType(BIOMETRIC_TYPES.facephi)}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </Container>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    fetching: SelectorsStoreEnrollment.fetching(store),
    isChangeMyPhone: SelectorsStoreChangeMyPhone.isChangeMyPhone(store),
});

export default HighOrder(Connect(mapStateToProps))(Component);
