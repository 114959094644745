import { EMPTY_STR } from "~/constants";

export const isString = (value) => typeof value === "string";

export const isEmpty = (value) => isString(value) && value.trim() === EMPTY_STR;

export const trim = (value) => {
    if (isString(value)) {
        return value.trim();
    }
    return value;
};

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const insertLinebreaks = (string) => string.replace(/<br ?\/?>/g, "\n");

export const abbreviate = (string, limit) => (string.length > limit ? `${string.substring(0, limit)}…` : string);
