import React from "react";

import classnames from "classnames";
import { replace as Replace, push as Push } from "connected-react-router";
import { Form, Field, Formik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { ALL, EMPTY_STR, ENVIRONMENT_TYPE, SCOPE, TYPE_ADMINISTRATION } from "~/constants";
import { DEFAULT_STATUS, STATUS } from "~/constants/transaction";
import Container from "~/containers/Internal/Form/Filter";
import { SelectorsStore as SelectorsStoreBiometric } from "~/store/biometric";
import { SelectorsStore as SelectorsStoreSession, SelectorsStore as StoreSession } from "~/store/session";
import {
    SelectorsAction as SelectorsActionTransactions,
    SelectorsStore as SelectorsStoreTransactions,
} from "~/store/transactions";
import * as UtilsConfig from "~/util/config";
import * as UtilsDate from "~/util/date";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button";
import ButtonFilter from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Select from "~/components/Select";
import DateField from "~/pages/_components/fields/DateField";

import Checkbox from "~/pages/forms/_components/_fields/_commons/Checkbox";
import LinkOptions from "~/pages/position/LinkOptions";
import List from "~/pages/transactions/_components/List";

import Style from "./TransactionsList.rules.scss";
import ApproveMultiple from "./_components/ApproveMultiple";

const FORM_ID = "transactions.list.filters.period";

export const { NAMEWeb } = Style;

export const NAME = "TransactionsList";

export const PROP = {
    types: {
        askPassword: PropTypes.bool,
        delayedTransactions: PropTypes.bool,
        dispatch: PropTypes.func.isRequired,
        environmentType: PropTypes.string,
        fetching: PropTypes.bool.isRequired,
        filter: PropTypes.shape({ status: PropTypes.string.isRequired }),
        previousLocation: PropTypes.string,
        totalTransactions: PropTypes.arrayOf(PropTypes.object),
    },
    defaults: {
        askPassword: false,
        delayedTransactions: null,
        environmentType: null,
        fetching: false,
        filter: null,
        previousLocation: null,
        totalTransactions: null,
    },
};

const DATE_FROM_DEFAULT_BACK = UtilsConfig.getInteger("client.transactions.filters.dateFrom.yearsBack.default", 1);

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        defaultDateTo: new Date(),
        // dateFromFilter starts with a default value of the first day of the month ten years ago
        dateFromFilter: UtilsDate.substractYears(UtilsDate.setTime(), DATE_FROM_DEFAULT_BACK),
        dateToFilter: new Date(),
        defaultStatusFilter: false,
        defaultTransactionFilter: false,
        idTransactionList: [],
        moreFilters: false,
        selectAllPendingChecked: false,
        selectedFilter: "last",
        statusFilter: DEFAULT_STATUS,
        transactionFilter: DEFAULT_STATUS,
    };

    constructor(props) {
        const { state } = props.location;

        super(props);

        if (state && state.statusFilter) {
            // NOTE: While the following could be fixed, it would generate a lot of warnings,
            //       because destructuring couldn't be used in the upper scope, and this is cleaner and harmless.
            // eslint-disable-next-line react/state-in-constructor
            this.state = {
                selectAllPendingChecked: state.selectAllPendingChecked,
                selectedFilter: state.selectedFilter,
                statusFilter: state.statusFilter,
            };
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { previousLocation } = nextProps;

        if (
            nextProps.filter !== null &&
            previousLocation &&
            (previousLocation.includes("/ticket") || previousLocation.includes("/transaction"))
        ) {
            return {
                ...prevState,
                statusFilter: nextProps.filter.status,
            };
        }

        return null;
    }

    componentDidMount() {
        const { dispatch, filter, previousLocation } = this.props;
        const { defaultDateTo } = this.state;

        if (filter) {
            const { dateFrom, dateTo } = filter;

            const hasMoreFilter =
                (dateFrom && dateFrom !== UtilsDate.substractYears(UtilsDate.setTime(), DATE_FROM_DEFAULT_BACK)) ||
                (dateTo && dateTo !== defaultDateTo);

            this.setState({
                moreFilters: hasMoreFilter,
            });
        }

        this.handleClearTransactions();

        if (previousLocation && !(previousLocation.includes("/ticket") || previousLocation.includes("/transaction"))) {
            dispatch(SelectorsActionTransactions.clearTransactionList());
        }

        dispatch(SelectorsActionTransactions.listIdActivitiesExecutedRequest());
    }

    handleClearFilter = (formikBag) => {
        const { dispatch, environmentType } = this.props;
        const { defaultDateTo } = this.state;
        const { setFieldValue } = formikBag;

        setFieldValue("dateFrom", null);
        setFieldValue("dateTo", null);

        this.setState({
            dateFromFilter: UtilsDate.substractYears(UtilsDate.setTime(), DATE_FROM_DEFAULT_BACK),
            dateToFilter: defaultDateTo,
            defaultStatusFilter: false,
            defaultTransactionFilter: false,
            idTransactionList: [],
            selectAllPendingChecked: false,
            selectedFilter: "last",
            statusFilter: DEFAULT_STATUS,
            transactionFilter: ALL,
        });

        let status = DEFAULT_STATUS;

        const filters = {
            dateFrom: UtilsDate.substractYears(UtilsDate.setTime(), DATE_FROM_DEFAULT_BACK),
            dateTo: defaultDateTo,
            filter: "last",
            pageNumber: 1,
            status: DEFAULT_STATUS,
            transaction: ALL,
        };

        dispatch(SelectorsActionTransactions.setFilters({ filters }));
        dispatch(SelectorsActionTransactions.resetFilter());

        if (status === DEFAULT_STATUS && environmentType !== ENVIRONMENT_TYPE.CORPORATE) {
            status = ALL;
        }

        dispatch(
            SelectorsActionTransactions.loadListRequest({
                dateFrom: UtilsDate.substractYears(UtilsDate.setTime(), DATE_FROM_DEFAULT_BACK),
                dateTo: defaultDateTo,
                status,
                transactionFilter: ALL,
            }),
        );
    };

    onDateFromChange = (dateFrom, formikBag) => {
        const { setFieldValue } = formikBag;
        const { filter } = this.props;
        const { defaultDateTo } = this.state;

        setFieldValue("dateTo", null);

        this.setState(
            {
                dateFromFilter: dateFrom,
                dateToFilter: defaultDateTo,
            },
            () => {
                const { dateToFilter, statusFilter, transactionFilter } = this.state;

                const filters = {
                    dateFrom,
                    dateTo: dateToFilter,
                    filter: "last",
                    pageNumber: 1,
                    status: statusFilter.value ? statusFilter.value : statusFilter,
                    transaction: filter?.transaction || transactionFilter.value,
                };

                const { dispatch } = this.props;

                dispatch(SelectorsActionTransactions.setFilters({ filters }));
            },
        );
    };

    onDateToChange = (dateTo) => {
        const { filter } = this.props;

        this.setState(
            {
                dateToFilter: dateTo,
            },
            () => {
                const { dateFromFilter, statusFilter, transactionFilter } = this.state;

                let dateFrom = dateFromFilter;
                if (
                    filter &&
                    filter.dateFrom !== UtilsDate.substractYears(UtilsDate.setTime(), DATE_FROM_DEFAULT_BACK) &&
                    dateFromFilter === UtilsDate.substractYears(UtilsDate.setTime(), DATE_FROM_DEFAULT_BACK)
                ) {
                    dateFrom = filter.dateFrom;
                }

                const filters = {
                    dateFrom,
                    dateTo,
                    filter: "last",
                    pageNumber: 1,
                    status: statusFilter.value ? statusFilter.value : statusFilter,
                    transaction: filter.transaction || transactionFilter.value,
                };

                const { dispatch } = this.props;

                dispatch(SelectorsActionTransactions.setFilters({ filters }));
            },
        );
    };

    onStatusChange = (status) => {
        if (status) {
            const { filter } = this.props;
            const { defaultDateTo } = this.state;

            this.setState(
                {
                    defaultStatusFilter: true,
                    statusFilter: status,
                },
                () => {
                    const { dateFromFilter, dateToFilter, transactionFilter } = this.state;

                    let dateFrom = dateFromFilter;

                    if (
                        filter &&
                        filter.dateFrom !== UtilsDate.substractYears(UtilsDate.setTime(), DATE_FROM_DEFAULT_BACK) &&
                        dateFromFilter === UtilsDate.substractYears(UtilsDate.setTime(), DATE_FROM_DEFAULT_BACK)
                    ) {
                        dateFrom = filter.dateFrom;
                    }

                    let dateTo = dateToFilter;

                    if (filter && filter.dateTo !== defaultDateTo && dateToFilter === defaultDateTo) {
                        dateTo = filter.dateTo;
                    }

                    const filters = {
                        dateFrom,
                        dateTo,
                        filter: "last",
                        pageNumber: 1,
                        status: status.value,
                        transaction: filter?.transaction || transactionFilter.value,
                    };

                    const { dispatch } = this.props;

                    dispatch(SelectorsActionTransactions.setFilters({ filters }));
                },
            );
        }
    };

    onTransactionChange = (transaction) => {
        if (transaction) {
            const { filter } = this.props;
            const { defaultDateTo } = this.state;

            this.setState(
                {
                    defaultTransactionFilter: true,
                    transactionFilter: transaction,
                },
                () => {
                    const { dateFromFilter, dateToFilter, statusFilter } = this.state;

                    let dateFrom = dateFromFilter;

                    if (
                        filter &&
                        filter.dateFrom !== UtilsDate.substractYears(UtilsDate.setTime(), DATE_FROM_DEFAULT_BACK) &&
                        dateFromFilter === UtilsDate.substractYears(UtilsDate.setTime(), DATE_FROM_DEFAULT_BACK)
                    ) {
                        dateFrom = filter.dateFrom;
                    }

                    let dateTo = dateToFilter;

                    if (filter && filter.dateTo !== defaultDateTo && dateToFilter === defaultDateTo) {
                        dateTo = filter.dateTo;
                    }

                    const filters = {
                        dateFrom,
                        dateTo,
                        filter: "last",
                        pageNumber: 1,
                        status: statusFilter.value ? statusFilter.value : statusFilter,
                        transaction: transaction.value,
                    };

                    const { dispatch } = this.props;

                    dispatch(SelectorsActionTransactions.setFilters({ filters }));
                },
            );
        }
    };

    handleCheckAll = () => {
        const { selectAllPendingChecked } = this.state;

        this.setState({ selectAllPendingChecked: !selectAllPendingChecked });

        if (selectAllPendingChecked) {
            this.handleCheckTransactionList([]);
        }
    };

    goToHistoryTransactions = () => {
        const { dispatch } = this.props;

        dispatch(Push("/transactions/history/list"));
    };

    handleSendFilters = () => {};

    renderSelectorFilter = () => {
        const statusOptions = [
            ALL,
            STATUS.PENDING,
            STATUS.DRAFT,
            STATUS.CANCELLED,
            STATUS.FINISHED,
            STATUS.PROCESSING,
            STATUS.SCHEDULED,
            STATUS.FAILED,
        ].map((value) => ({
            value,
            label: UtilsI18n.get(`transactions.filter.status.${value}`),
        }));

        const delayedOption = {
            value: STATUS.RUNNING,
            label: <I18n id={`transactions.filter.status.${STATUS.RUNNING}`} />,
        };

        const {
            defaultDateTo,
            defaultStatusFilter,
            defaultTransactionFilter,
            idTransactionList,
            moreFilters,
            selectAllPendingChecked,
            statusFilter,
            transactionFilter,
        } = this.state;
        const {
            activeEnvironment,
            changeFilter,
            delayedTransactions,
            environmentType,
            filter,
            idActivitiesList,
            isMobile,
            transactions,
        } = this.props;

        const selectorTransactionsOptions = idActivitiesList?.map((value) => ({
            value,
            label: UtilsI18n.get(`activities.${value}`),
        }));

        function compare(a, b) {
            if (a.label < b.label) {
                return -1;
            }

            if (a.label > b.label) {
                return 1;
            }

            return 0;
        }

        selectorTransactionsOptions.sort(compare);

        selectorTransactionsOptions.unshift({
            value: ALL,
            label: UtilsI18n.get(`transactions.filter.transaction.${ALL}`),
        });

        const { administrationScheme } = activeEnvironment;
        const selectorStatusOptions = statusOptions;
        const totalTransactions = Object.keys(transactions || {}).length;
        const { dateFromFilter, dateToFilter } = this.state;

        if (delayedTransactions) {
            selectorStatusOptions.push(delayedOption);
        }

        // The reason for creating an object with a label property is for the Select component to be rendered correctly,
        // because it has the labelKey prop set to "label" as it is set in the options.
        const filterLabel = changeFilter
            ? { label: selectorStatusOptions[0].label }
            : { label: selectorStatusOptions[1].label };

        let currentStatusFilter;

        if (defaultStatusFilter) {
            if (filter !== null) {
                currentStatusFilter = selectorStatusOptions.find((option) => option.value === filter.status);
            } else if (statusFilter === DEFAULT_STATUS) {
                currentStatusFilter = environmentType !== ENVIRONMENT_TYPE.CORPORATE ? ALL : filterLabel;
            } else if (!statusFilter.label) {
                const optionLabel = selectorStatusOptions.find((option) => option.value === statusFilter);
                currentStatusFilter = { label: optionLabel.label };
            } else {
                currentStatusFilter = statusFilter;
            }
        } else if (filter && filter.status !== DEFAULT_STATUS) {
            currentStatusFilter = filter.status;
        } else if (changeFilter || environmentType !== ENVIRONMENT_TYPE.CORPORATE) {
            currentStatusFilter = ALL;
        } else {
            currentStatusFilter = STATUS.PENDING;
        }

        let currentTransactionFilter;

        if (defaultTransactionFilter) {
            if (filter !== null) {
                currentTransactionFilter = selectorTransactionsOptions.find(
                    (option) => option.value === filter.transaction,
                );
            } else if (transactionFilter === DEFAULT_STATUS) {
                currentTransactionFilter = ALL;
            }
        } else if (filter && filter.transaction) {
            currentTransactionFilter = filter.transaction;
        } else {
            currentTransactionFilter = ALL;
        }

        let dateFromFieldValue = null;

        if (
            filter &&
            filter.dateFrom &&
            filter.dateFrom.getTime() !==
                UtilsDate.substractYears(UtilsDate.setTime(), DATE_FROM_DEFAULT_BACK).getTime()
        ) {
            dateFromFieldValue = filter.dateFrom;
        }

        let dateToFieldValue = null;

        if (filter && filter.dateTo && filter.dateTo !== defaultDateTo) {
            dateToFieldValue = filter.dateTo;
        }

        return (
            <div className="filter-selector-content">
                <Formik onSubmit={this.handleSendFilters}>
                    {(formikBag) => (
                        <Form>
                            <div className="filter-group">
                                <div className={classnames("first-col", { "is-open": moreFilters })}>
                                    <div className="transaction-filter">
                                        <Field
                                            className="slideFromBottom flex-container"
                                            clearable={false}
                                            component={Select}
                                            labelKey="label"
                                            name="statusFilter"
                                            onChange={this.onStatusChange}
                                            optionClassName="needsclick"
                                            options={selectorStatusOptions}
                                            placeholder={EMPTY_STR}
                                            value={currentStatusFilter}
                                            valueKey="value"
                                        />
                                        {!isMobile && (
                                            <Field
                                                className="slideFromBottom flex-container select-transactions"
                                                clearable={false}
                                                component={Select}
                                                labelKey="label"
                                                name="transaction"
                                                onChange={this.onTransactionChange}
                                                optionClassName="needsclick"
                                                options={selectorTransactionsOptions}
                                                placeholder={EMPTY_STR}
                                                value={currentTransactionFilter}
                                                valueKey="value"
                                            />
                                        )}
                                    </div>
                                    <div
                                        data-name="moreFilters"
                                        className={classnames("more-filters", { "is-open": moreFilters })}>
                                        {isMobile && (
                                            <Field
                                                className="slideFromBottom flex-container select-transactions"
                                                clearable={false}
                                                component={Select}
                                                labelKey="label"
                                                name="transaction"
                                                onChange={this.onTransactionChange}
                                                optionClassName="needsclick"
                                                options={selectorTransactionsOptions}
                                                placeholder={EMPTY_STR}
                                                value={currentTransactionFilter}
                                                valueKey="value"
                                            />
                                        )}
                                        <div className="date-filter">
                                            <Field
                                                className="flex-container"
                                                autoComplete="off"
                                                component={DateField}
                                                selectedDate={dateFromFilter}
                                                selected={dateFromFieldValue}
                                                hideLabel
                                                id="dateFrom"
                                                idForm={FORM_ID}
                                                maxDate={new Date()}
                                                name="dateFrom"
                                                hasPlaceholder
                                                handleChange={(e) => this.onDateFromChange(e, formikBag)}
                                            />
                                            <Field
                                                className="flex-container"
                                                autoComplete="off"
                                                component={DateField}
                                                selectedDate={dateToFilter}
                                                selected={dateToFieldValue}
                                                hideLabel
                                                id="dateTo"
                                                idForm={FORM_ID}
                                                minDate={filter?.dateFrom || dateFromFilter}
                                                maxDate={new Date()}
                                                name="dateTo"
                                                hasPlaceholder
                                                handleChange={this.onDateToChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="second-col">
                                    <div className="btn-container">
                                        <ButtonFilter
                                            className="btn btn-primary btn-filter btn-filled"
                                            onClick={() => this.handleTransactionsFilter()}>
                                            {UtilsI18n.get("transactions.filters.filter")}
                                        </ButtonFilter>
                                        <ButtonFilter
                                            className="btn btn-secondary btn-filter btn-outline"
                                            onClick={() => this.handleClearFilter(formikBag)}>
                                            {UtilsI18n.get("transactions.filters.clean")}
                                        </ButtonFilter>
                                    </div>
                                    <ButtonFilter
                                        className={classnames({ "is-open": moreFilters }, "btnMoreFilters")}
                                        variant="link"
                                        onClick={() => this.handleMoreFilters()}>
                                        {UtilsI18n.get(
                                            !moreFilters
                                                ? "transactions.filters.moreFilters"
                                                : "transactions.filters.lessFilters",
                                        )}
                                    </ButtonFilter>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
                <div className="bottom-container">
                    {this.shouldRenderSelectAll(administrationScheme, totalTransactions, statusFilter, changeFilter) ? (
                        <div className="select-all-checkbox">
                            <Checkbox
                                label={
                                    isMobile
                                        ? UtilsI18n.get("select.all.transactions.checkbox")
                                        : `${idTransactionList.length} ${UtilsI18n.get(
                                              "gnb.transactions.history.selected",
                                          )}`
                                }
                                checked={selectAllPendingChecked}
                                onChange={this.handleCheckAll}
                            />
                        </div>
                    ) : (
                        <div className="select-all-checkbox mobile">{EMPTY_STR}</div>
                    )}
                    {transactions &&
                        transactions.length > 0 &&
                        (idTransactionList.length > 0 || selectAllPendingChecked) && (
                            <div className="btn-select-all">
                                <Button
                                    className="btn btn-secondary btn-outline"
                                    onClick={() => this.handleTransactionsPreview(false)}
                                    label="transaction.cancel"
                                />
                                <Button
                                    className="btn btn-primary btn-filled"
                                    onClick={() => this.handleTransactionsPreview(true)}
                                    label="transaction.accept"
                                />
                            </div>
                        )}
                </div>
            </div>
        );
    };

    handleMoreFilters = () => {
        const { defaultDateTo, moreFilters } = this.state;
        const { filter } = this.props;

        this.setState(
            {
                moreFilters: !moreFilters,
                dateFromFilter: UtilsDate.substractYears(UtilsDate.setTime(), DATE_FROM_DEFAULT_BACK),
                dateToFilter: defaultDateTo,
            },
            () => {
                const { dispatch } = this.props;
                const { statusFilter, transactionFilter } = this.state;

                const filters = {
                    dateFrom: UtilsDate.substractYears(UtilsDate.setTime(), DATE_FROM_DEFAULT_BACK),
                    dateTo: defaultDateTo,
                    status: statusFilter.value ? statusFilter.value : statusFilter,
                    transaction: filter?.transaction || transactionFilter.value,
                };

                dispatch(SelectorsActionTransactions.setFilters({ filters }));
            },
        );
    };

    handleTransactionsFilter = () => {
        const { dispatch, environmentType, filter } = this.props;
        const { defaultDateTo, dateFromFilter, dateToFilter, statusFilter, transactionFilter } = this.state;

        let reduxStatus;
        const stateStatus = statusFilter.value || statusFilter;

        if (filter && filter.status && filter.status !== DEFAULT_STATUS) {
            reduxStatus = filter.status;
        } else {
            reduxStatus = false;
        }

        let status = reduxStatus || stateStatus;

        if (status === DEFAULT_STATUS && environmentType !== ENVIRONMENT_TYPE.CORPORATE) {
            status = ALL;
        }

        let reduxTransaction;
        const stateTransaction = transactionFilter.value || transactionFilter;

        if (filter && filter.transaction) {
            reduxTransaction = filter.transaction;
        } else {
            reduxTransaction = false;
        }

        const transaction = reduxTransaction || stateTransaction;

        let dateFrom = dateFromFilter;
        if (
            filter &&
            filter.dateFrom !== UtilsDate.substractYears(UtilsDate.setTime(), DATE_FROM_DEFAULT_BACK) &&
            dateFromFilter === UtilsDate.substractYears(UtilsDate.setTime(), DATE_FROM_DEFAULT_BACK)
        ) {
            dateFrom = filter.dateFrom;
        }

        let dateTo = dateToFilter;
        if (filter && filter.dateTo !== defaultDateTo && dateToFilter === defaultDateTo) {
            dateTo = filter.dateTo;
        }

        this.setState({
            idTransactionList: [],
            selectAllPendingChecked: false,
        });

        dispatch(
            SelectorsActionTransactions.loadListRequest({
                status,
                dateFrom: UtilsDate.formatDate(dateFrom),
                dateTo: UtilsDate.formatDate(dateTo),
                transaction,
            }),
        );
    };

    handleTransactionsPreview = (approve) => {
        const { dispatch } = this.props;
        const { idTransactionList } = this.state;

        if (approve) {
            dispatch(
                SelectorsActionTransactions.approveTransactionsPreviewRequest({
                    idTransactionList,
                }),
            );
        } else {
            dispatch(
                SelectorsActionTransactions.cancelTransactionsPreviewRequest({
                    idTransactionList,
                }),
            );
        }
    };

    shouldRenderSelectAll = (administrationScheme, totalTransactions, statusFilter, changeFilter) => {
        const { activeEnvironment, transactions } = this.props;
        const withoutPending = transactions?.some((e) => e.transaction.idTransactionStatus !== "PENDING");

        return (
            (!withoutPending || (statusFilter === DEFAULT_STATUS && !changeFilter)) &&
            administrationScheme !== TYPE_ADMINISTRATION.SIMPLE &&
            activeEnvironment.type === ENVIRONMENT_TYPE.CORPORATE &&
            totalTransactions > 0
        );
    };

    renderApprovalButtons = () => <ApproveMultiple {...this.props} />;

    renderFilters = () => {
        const { statusFilter } = this.state;
        const { isMobile } = this.props;

        const filter = statusFilter?.value;

        return (
            <div className={`filter-selector filter-${filter}`}>
                {!isMobile && (
                    <span className="filter-label">
                        <I18n id="transactions.filter.title" />
                    </span>
                )}
                {this.renderSelectorFilter()}
            </div>
        );
    };

    renderList = () => {
        const { idTransactionList, moreFilters, selectAllPendingChecked, statusFilter } = this.state;
        const { isMobile, location } = this.props;

        return (
            <List
                allPendingSelected={selectAllPendingChecked}
                currentFilter={statusFilter?.value || DEFAULT_STATUS}
                handleCheckChange={this.handleCheckAll}
                handleCheckTransactionList={this.handleCheckTransactionList}
                idTransactionList={idTransactionList}
                isMobile={isMobile}
                location={location}
                moreFilters={moreFilters}
            />
        );
    };

    handleCheckTransactionList = (listElement) => {
        this.setState({ idTransactionList: listElement });
    };

    handleBack = () => {
        const { history, dispatch } = this.props;
        const { pathname } = history.location;

        if (pathname === "/transactions/list") {
            dispatch(SelectorsActionTransactions.clearPreview());
            dispatch(SelectorsActionTransactions.setApproving(), { isApproving: null });
        } else {
            history.goBack();
        }
    };

    handleClose = () => {
        const { dispatch } = this.props;
        this.handleClearTransactions();
        const pathname = "/desktop";

        dispatch(
            Replace({
                pathname,
                state: { transition: "transition-flow-close" },
            }),
        );
    };

    handleClearTransactions = () => {
        this.setState({ selectAllPendingChecked: false });
        const { dispatch } = this.props;

        dispatch(SelectorsActionTransactions.clearPreview());
    };

    renderHeader = () => {
        const { idTransactionList, isApproving } = this.props;

        if (idTransactionList.length > 0) {
            return isApproving ? "transactions.masiveAuthorization" : "transactions.masiveCancelation";
        }

        return "menu.transactions";
    };

    render() {
        const { askPassword, fetching, idTransactionList, isMobile, activeEnvironment } = this.props;
        const renderArrow = idTransactionList.length > 0;
        const {
            environmentData: { migratedEnvironment },
        } = activeEnvironment;

        const goHistory = UtilsI18n.get("transaction.go.history");

        return (
            <Container
                className={!isMobile && "list-transaction list-web-transaction"}
                head-onBack={renderArrow ? this.handleBack : null}
                head-onBackWeb={this.handleBack}
                head-onClose={this.handleClose}
                head-title={this.renderHeader()}
                image="transactions-step.svg"
                name={NAME}
                scopeToShowNotification={SCOPE.TRANSACTIONS}
                wait={fetching && !askPassword}>
                <div className={`${idTransactionList.length > 0 ? "approve-screen" : "list-screen"}`}>
                    {migratedEnvironment && (
                        <div className="links header-content has-migrated-link">
                            <LinkOptions
                                image="arrow-top.svg"
                                handleClick={() => this.goToHistoryTransactions()}
                                text={goHistory}
                            />
                        </div>
                    )}
                    {idTransactionList.length === 0 && this.renderFilters()}
                    {idTransactionList.length > 0 ? this.renderApprovalButtons() : this.renderList()}
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    activeEnvironment: StoreSession.getActiveEnvironment(store) || {},
    askPassword: SelectorsStoreBiometric.askPassword(store),
    changeFilter: SelectorsStoreTransactions.getFilterChange(store),
    delayedTransactions: SelectorsStoreTransactions.getDelayedTransactions(store),
    environmentType: SelectorsStoreSession.getActiveEnvironmentType(store),
    fetching: SelectorsStoreTransactions.getFetching(store),
    filter: SelectorsStoreTransactions.getFilters(store),
    idActivitiesList: SelectorsStoreTransactions.getIdActivitiesExecuted(store),
    idTransactionList: SelectorsStoreTransactions.getidApprovals(store),
    isApproving: SelectorsStoreTransactions.getSignAction(store),
    previousLocation: SelectorsStoreTransactions.getPreviousLocation(store),
    transactions: SelectorsStoreTransactions.getTransactions(store),
});

export default Connect(mapStateToProps)(HighOrder.Resizable(Component));
