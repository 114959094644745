import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { SelectorsStore as SelectorsStoreForm } from "~/store/form";

import I18n from "~/components/I18n";

export const NAME = "CreditCardPurchaseNotification";

export const PROP = {
    types: {
        previewData: PropTypes.shape({
            confirmationText: PropTypes.string,
        }),
    },
    defaults: { previewData: { confirmationText: "" } },
};
export function Component(props) {
    const { previewData } = props;

    return (
        <React.Fragment>
            <p className="text-lead">
                <I18n id="forms.transferInternal.confirmation.title" />
            </p>

            <div className="transfer-block">
                <div className="transfer-data transfer-data-flow">
                    <div className="data-wrapper">
                        <span className="data-label">
                            {/* TODO: ver si lo dejo asi desde el back devuelvo el key del 118n,
                             tambien hay que ver si existe alguna otra pantalla similar asi
                             se utiliza el mismo formato, ver si esta bien que desde el back
                             devuelva el idTransaction, compararlo con hasbc nazca 3
                        */}
                        </span>
                        <span className="data-name">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: previewData.confirmationText,
                                }}
                            />
                        </span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (state) => ({
    name: SelectorsStoreForm.getName(state),
    previewData: SelectorsStoreForm.getPreviewData(state),
});

export default connect(mapStateToProps)(Component);
