import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    listPaymentsPreRequest: () => API.executeWithAccessToken(PATH.LIST_PAYMENTS_PRE_REQUEST),
    listPaymentsRequest: (props) => API.executeWithAccessToken(PATH.LIST_PAYMENTS_REQUEST, props),
    payCustomsPreviewRequest: (props) => {
        const { _otp } = props;

        return API.executeWithWebToken(PATH.PAY_CUSTOMS_PREVIEW_REQUEST, props, _otp);
    },
    payCustomsRequest: (props) => API.executeWithAccessToken(PATH.PAY_CUSTOMS_REQUEST, props),
};

export const SelectorsAction = {
    cancelTransactionCustom: (props) => FactoryAction(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, props),
    finishStep1: (props) => FactoryAction(TYPE.FINISH_STEP_1, props),
    goToStep1: () => FactoryAction(TYPE.PAY_CUSTOMS_GO_TO_STEP_1),
    listPaymentsFailure: () => FactoryAction(TYPE.LIST_PAYMENTS_FAILURE),
    listPaymentsPreFailure: () => FactoryAction(TYPE.LIST_PAYMENTS_PRE_FAILURE),
    listPaymentsPreRequest: () => FactoryAction(TYPE.LIST_PAYMENTS_PRE_REQUEST),
    listPaymentsPreSuccess: (props) => FactoryAction(TYPE.LIST_PAYMENTS_PRE_SUCCESS, props),
    listPaymentsRequest: (props) => FactoryAction(TYPE.LIST_PAYMENTS_REQUEST, props),
    listPaymentsSuccess: (props) => FactoryAction(TYPE.LIST_PAYMENTS_SUCCESS, props),
    loadTicketFailure: () => FactoryAction(TYPE.PAY_CUSTOMS_LOAD_TICKET_FAILURE),
    loadTicketRequest: (props) => FactoryAction(TYPE.PAY_CUSTOMS_LOAD_TICKET_REQUEST, props),
    loadTicketSuccess: (props) => FactoryAction(TYPE.PAY_CUSTOMS_LOAD_TICKET_SUCCESS, props),
    payCustomsFailure: () => FactoryAction(TYPE.PAY_CUSTOMS_FAILURE),
    payCustomsPreviewFailure: () => FactoryAction(TYPE.PAY_CUSTOMS_PREVIEW_FAILURE),
    payCustomsPreviewRequest: (props) => FactoryAction(TYPE.PAY_CUSTOMS_PREVIEW_REQUEST, props),
    payCustomsPreviewSuccess: (props) => FactoryAction(TYPE.PAY_CUSTOMS_PREVIEW_SUCCESS, props),
    payCustomsRequest: (props) => FactoryAction(TYPE.PAY_CUSTOMS_REQUEST, props),
    payCustomsSignatureRequired: () => FactoryAction(TYPE.PAY_CUSTOMS_SIGNATURE_REQUIRED),
    payCustomsSuccess: (props) => FactoryAction(TYPE.PAY_CUSTOMS_SUCCESS, props),
    restartFlow: (props) => FactoryAction(TYPE.RESTART_FLOW, props),
    signTransactionFailure: (props) => FactoryAction(TYPE.PAY_CUSTOMS_SIGN_TRANSACTION_FAILURE, props),
    signTransactionPreview: (props) => FactoryAction(TYPE.PAY_CUSTOMS_SIGN_TRANSACTION_PREVIEW, props),
    signTransactionRequest: (props) => FactoryAction(TYPE.PAY_CUSTOMS_SIGN_TRANSACTION_REQUEST, props),
    signTransactionSuccess: (props) => FactoryAction(TYPE.PAY_CUSTOMS_SIGN_TRANSACTION_SUCCESS, props),
};

export const SelectorsStore = {
    getCurrency: (store) => store[NAME].currency,
    getDebitAccountList: (store) => store[NAME].debitAccountList,
    getDebitAccount: (store) => store[NAME].debitAccount,
    getDocumentNumber: (store) => store[NAME].documentNumber,
    getDocumentType: (store) => store[NAME].documentType,
    getFetching: (store) => store[NAME].isFetching,
    getMode: (store) => store[NAME].mode,
    getPayments: (store) => store[NAME].payments,
    getReferenceCode: (store) => store[NAME].referenceCode,
    getSelectedPayments: (store) => store[NAME].selectedPayments,
    getTotalAmount: (store) => store[NAME].totalAmount,
    getTotalPayments: (store) => store[NAME].totalPayments,
    getTransaction: (store) => store[NAME].transaction,
    getValueDate: (store) => store[NAME].valueDate,
};
