import React from "react";

import { push as Push } from "connected-react-router";
import { connect as Connect } from "react-redux";

import Container from "~/containers/External/Payment";
import * as UtilsI18n from "~/util/i18n";

import classnames from "classnames";
import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";
import Image from "~/components/Image";


import Style from "./RecoveryPass.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const { dispatch } = props;

    const onLogin = () => {
        dispatch(Push("/"));
    };
    
    return (
        <Container id={NAME} name={NAME} head-title="recoveryPasswordNoToken.step5.title">
            <div className={classnames(CLASS, "container")}>
                <div id={Style.NAME} />
                <div className="login-step">
                    <div className="login-row">
                        <div className="login-image">
                            <Image src="finished.svg" className="login-image-recovery" />
                        </div>
                    </div>
                    <section className="text-information last-step-text">
                        <p><I18n id="recoveryPasswordNoToken.step5.intro" /></p>
                        <Button className="secondary login-form-button btn-margin" onClick={onLogin}>
                            {UtilsI18n.get("recoveryPasswordNoToken.step5.back")}
                        </Button>
                    </section>
                </div>
            </div>
        </Container>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Connect()(Component);
