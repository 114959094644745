import React from "react";

import PropTypes from "prop-types";
import { Grid, Row } from "react-bootstrap";

import { EMPTY_STR } from "~/constants";

import ColumnBody from "./containerComponents/ColumnBody";
import ColumnHeader from "./containerComponents/ColumnHeader";
import ContainerColumn from "./containerComponents/ContainerColumn";

export const NAME = "Container";

export const PROP = {
    types: { className: PropTypes.string, gridClassName: PropTypes.string, children: PropTypes.node },
    defaults: { className: EMPTY_STR, gridClassName: EMPTY_STR, children: null },
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    static Column = ContainerColumn;

    static ColumnHeader = ColumnHeader;

    static ColumnBody = ColumnBody;

    render() {
        const { children, className, gridClassName } = this.props;

        return (
            <section className={className}>
                <Grid className={gridClassName}>
                    <Row className="justify-content-center">{children}</Row>
                </Grid>
            </section>
        );
    }
}

export default Component;
