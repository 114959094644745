import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "migration";

export const PATH = {
    MIGRATION_CHECK_MOBILE_PHONE: "/migration.check.mobile.phone",
    MIGRATION_CHANGE_MOBILE_PHONE: "/migration.change.mobile.phone",
    MIGRATION_SECURITY_TIPS: "/migration.security.tips.sign",
    MIGRATION_ADD_SOFT_OTP: "/add.softOTP.withBiometric.migration",
    MIGRATION_SUGGEST: "/migration.suggest",
    MIGRATION_REVOKE_SOFT_OTP: "/migration.revoke.soft.otp",
    MIGRATION_FINISH_SOFT: "/migration.finish.soft.otp",
};

export const TYPE = FactoryType(NAME, [
    "MIGRATION_SUGGEST_REQUEST",
    "MIGRATION_SUGGEST_SUCCESS",
    "MIGRATION_SUGGEST_ERROR",
    "CHECK_MOBILE_REQUEST",
    "CHECK_MOBILE_SUCCESS",
    "CHECK_MOBILE_ERROR",
    "CHANGE_MOBILE_REQUEST",
    "CHANGE_MOBILE_SUCCESS",
    "CHANGE_MOBILE_ERROR",
    "ADD_SOFT_OTP_REQUEST",
    "ADD_SOFT_OTP_SUCCESS",
    "ADD_SOFT_OTP_ERROR",
    "SECURITY_TIPS_REQUEST",
    "SECURITY_TIPS_SUCESS",
    "SECURITY_TIPS_ERROR",
    "REGISTER_BIOMETRIC_MIGRATION",
    "SECURITY_TIPS_MODAL_TRUE",
    "SECURITY_TIPS_MODAL_FALSE",
    "MIGRATION_FINISH_SOFT_REQUEST",
    "MIGRATION_FINISH_SOFT_SUCCESS",
    "MIGRATION_FINISH_SOFT_ERROR",
    "MIGRATION_REVOKE_SOFT_REQUEST",
    "MIGRATION_REVOKE_SOFT_SUCCESS",
    "MIGRATION_REVOKE_SOFT_ERROR",
    "CLEAN_UP",
]);

export const PROP = {};

PROP.types = {
    currentMobilePhone: PropTypes.string, // Este es el que ingresa el usuario
    mobilePhone: PropTypes.string, // Este es el celular que tiene hoy en dia el usuario en nazca
    mobilePhoneClean: PropTypes.string, // Este es el celular que tiene hoy en dia el usuario en nazca pero sin -
    isMobilePhoneOK: PropTypes.bool,
    fetching: PropTypes.bool,
    registerBiometricMigration: PropTypes.bool,
    idUser: PropTypes.string,
    modal: PropTypes.bool,
    registerMigrationFinish: PropTypes.bool,
    ...TypesRedux,
};

PROP.defaults = {
    registerMigrationFinish: false,
    currentMobilePhone: "XX-XXX-XXX",
    mobilePhone: "XX-XXX-XXX",
    isMobilePhoneOK: false,
    fetching: false,
    registerBiometricMigration: false,
    idUser: null,
    modal: false,
    ...DefaultsRedux,
};
