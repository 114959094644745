import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { MODE } from "~/constants/form";
import * as UtilsI18n from "~/util/i18n";

import HighOrder from "~/components/HighOrder";
import NumberFormatInput from "~/components/NumberFormatInput";
import WithFocus from "~/pages/_components/withFocus";

import FormField from "~/pages/forms/_components/_fields/_commons/formField";

export const NAME = "Numberinput";

export const PROP = {
    types: {
        name: PropTypes.string.isRequired,
        inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
        setValue: PropTypes.func.isRequired,
        hidePlaceholder: PropTypes.bool,
        toggleIsFocused: PropTypes.func.isRequired,
        mode: PropTypes.string,
        value: PropTypes.string,
        minLength: PropTypes.number,
        maxLength: PropTypes.number,
        placeholder: PropTypes.string,
        hidelabel: PropTypes.bool,
    },

    defaults: {
        mode: MODE.EDIT,
        value: EMPTY_STR,
        placeholder: EMPTY_STR,
        maxLength: 50,
        minLength: 0,
        inputRef: React.createRef(),
        hidelabel: false,
        hidePlaceholder: false,
    },
};

export class Component extends React.Component {
    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    static displayName = NAME;

    handleChange = ({ target }) => {
        const { setValue, maxLength } = this.props;
        const { value } = target;
        if (value.length <= maxLength) {
            setValue(target.value);
        }
        this.customMinLengthValidation({ target });
    };

    customMinLengthValidation = ({ target }) => {
        const { minLength } = this.props;

        if (minLength) {
            const { value } = target;
            if (value && value.length < minLength) {
                target.setCustomValidity(
                    UtilsI18n.get("generic.text.field.minLength.warning.message", null, {
                        MINLENGTH: minLength,
                        CURRENTLENGTH: value.length,
                    }),
                );
            } else {
                target.setCustomValidity("");
            }
        }
    };

    render() {
        const {
            name,
            inputRef,
            hidePlaceholder,
            toggleIsFocused,
            mode,
            value,
            minLength,
            maxLength,
            placeholder,
        } = this.props;

        if (mode === MODE.EDIT) {
            return (
                <div className="input-group">
                    <NumberFormatInput
                        className="form-control"
                        allowLeadingZeros
                        value={value || ""}
                        minLength={minLength}
                        name={name}
                        maxLength={maxLength}
                        onFocus={toggleIsFocused}
                        onBlur={toggleIsFocused}
                        type="tel"
                        placeholder={hidePlaceholder ? EMPTY_STR : placeholder}
                        onChange={this.handleChange}
                        ref={inputRef}
                    />
                </div>
            );
        }
        return <div>{value}</div>;
    }
}

export default HighOrder(WithFocus, FormField())(Component);
