import { LOCATION_CHANGE } from "connected-react-router";

import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { TYPE as TypesBackoffice } from "~/store/backoffice";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            return {
                ...PROP.defaults,
            };
        }
        case TYPE.RESET_FILTERS:
            return {
                ...storeProp,
                filtersAdvancePaymentsList: PROP.defaults.filtersAdvancePaymentsList,
                filtersSuppliers: PROP.defaults.filtersSuppliers,
            };
        case TYPE.LIST_ADVANCE_PAYMENTS_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
                moreOrders: action.moreOrders,
                filtersAdvancePaymentsList: action.filters || storeProp.filtersAdvancePaymentsList,
            };
        case TYPE.LIST_ADVANCE_PAYMENTS_FAILURE:
            return { ...storeProp, isFetching: false };
        case TYPE.LIST_ADVANCE_PAYMENTS_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                list: action.data.list,
                offset: action.data.nextOffset,
                moreLines: action.data.moreLines,
                totalAdvancePaymentBalanceAmountUYU: action.data.totalAdvancePaymentBalanceAmountUYU,
                totalAdvancePaymentBalanceAmountUSD: action.data.totalAdvancePaymentBalanceAmountUSD,
                totalDiscountableUYU: action.data.totalDiscountableUYU,
                totalDiscountableUSD: action.data.totalDiscountableUSD,
                totalAdvancePaymentAmountUYU: action.data.totalAdvancePaymentAmountUYU,
                totalAdvancePaymentAmountUSD: action.data.totalAdvancePaymentAmountUSD,
            };
        case TYPE.ADVANCE_PAYMENTS_TRANSACTION_PREVIEW_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
                currentDiscountableUSD: action.currentDiscountableUSD,
                currentDiscountableUYU: action.currentDiscountableUYU,
                ordersToDiscount: action.ordersToDiscountList,
            };
        case TYPE.ADVANCE_PAYMENTS_TRANSACTION_PREVIEW_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
            };
        case TYPE.ADVANCE_PAYMENTS_TRANSACTION_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                mode: MODE.PREVIEW,
            };
        case TYPE.ADVANCE_PAYMENTS_TRANSACTION_SEND_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.ADVANCE_PAYMENTS_TRANSACTION_SEND_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
            };
        case TYPE.ADVANCE_PAYMENTS_TRANSACTION_SEND_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };
        case TYPE.CHANGE_TO_EDIT_MODE:
            return {
                ...storeProp,
                mode: MODE.EDIT,
            };
        case TYPE.CLEAN_ORDERS_TO_DISCOUNT:
            return {
                ...storeProp,
                ordersToDiscount: [],
            };
        case TYPE.READ_TRANSACTION_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.READ_TRANSACTION_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
            };
        case TYPE.READ_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                mode: action.transaction.idTransactionStatus === STATUS.DRAFT ? MODE.EDIT : MODE.VIEW,
                transaction: action.transaction,
            };
        case TYPE.SIGN_TRANSACTION_PREVIEW_REQUEST:
            return {
                ...storeProp,
                currentDiscountableUSD: action.currentDiscountableUSD,
                currentDiscountableUYU: action.currentDiscountableUYU,
                ordersToDiscount: action.ordersToDiscountList,
                prevMode: storeProp.mode,
                mode: MODE.PREVIEW,
                fetching: true,
            };

        case TYPE.SIGN_TRANSACTION_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                prevMode: storeProp.mode,
                transaction: action.transaction,
            };
        case TYPE.RESTART_FLOW:
            return PROP.defaults;
        case TypesBackoffice.BACKOFFICE_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                prevMode: storeProp.mode,
                transaction: action.transaction,
            };
        default:
            return {
                ...storeProp,
            };
    }
};
