import React from "react";

import classnames from "classnames";
import { replace as Replace } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/Simple";
import {
    SelectorsAction as SelectorsActionCommunication,
    SelectorsStore as SelectorsStoreCommunication,
} from "~/store/communications";

import Style from "~/pages/communications/CommunicationMain.rules.scss";
import Compose from "~/pages/communications/_components/Compose";

export const { NAME } = Style;

export const PROP = {
    types: {
        match: PropTypes.oneOfType([PropTypes.object]),
        dispatch: PropTypes.func.isRequired,
        history: PropTypes.oneOfType([PropTypes.object.isRequired]).isRequired,
        detail: PropTypes.shape({ subject: PropTypes.string }),
    },
    defaults: { match: {}, detail: { subject: EMPTY_STR } },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        const {
            dispatch,
            match: {
                params: { idThread, idCommunication },
            },
        } = this.props;
        dispatch(SelectorsActionCommunication.detailRequest({ idThread, idCommunication }));
    }

    handleBack = () => {
        const { dispatch, detail } = this.props;
        dispatch(Replace(`/communications/read/${detail.idThread}/${detail.idCommunication}`));
    };

    BackInitClick = () => {
        const { dispatch } = this.props;
        dispatch(Replace(`/communications/`));
    };

    render() {
        const { detail, isFetching } = this.props;

        if (!detail) {
            return null;
        }

        return (
            <Container
                wait={isFetching}
                id={NAME}
                name={NAME}
                className={classnames(Style.CLASS, "Reply")}
                head-title={detail.subject}
                head-onBack={this.handleBack}
                head-onClose={this.BackInitClick}
                scopeToShowNotification={SCOPE.COMMUNICATIONS_REPLY}>
                <Compose isReply />
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    detail: SelectorsStoreCommunication.detail(store),
    isFetching: SelectorsStoreCommunication.isFetching(store),
});

export default Connect(mapStateToProps)(Component);
