import { TYPE as TYPE_SESSION } from "~/store/session";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.READ_DEBIT_CARD_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.READ_DEBIT_CARD_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
            };
        case TYPE.READ_DEBIT_CARD_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                debitCard: action.debitCard,
            };
        case TYPE.LIST_REQUEST:
            return {
                ...storeProp,
                debitCards: { isFetching: true, list: [] },
            };
        case TYPE.LIST_FAILURE:
            return {
                ...storeProp,
                debitCards: { isFetching: false, list: [] },
            };
        case TYPE.LIST_SUCCESS:
            return {
                ...storeProp,
                debitCards: { isFetching: false, list: action.list },
            };
        case TYPE.CHANGE_STATUS_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.CHANGE_STATUS_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
            };
        case TYPE.CHANGE_STATUS_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                debitCard: action.debitCard,
            };
        case TYPE_SESSION.CHANGE_ENVIRONMENT_SUCCESS:
            return { ...storeProp, detail: PROP.defaults.detail };
        case TYPE.CLEAN_UP:
            return {
                ...PROP.defaults,
                statements: {},
            };
        default:
            return {
                ...storeProp,
            };
    }
};
