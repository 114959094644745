import React from "react";

import { goBack as GoBack } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import {
    SelectorsStore as SelectorsStoreWMSwitchFunds,
    SelectorsAction as SelectorsActionWMSwitchFunds,
} from "~/store/wm/funds/switch";
import * as UtilsI18n from "~/util/i18n";

import FormPreview from "./Step2Preview/Step2Preview";
import FormView from "./Step3View/Step3View";

export const NAME = "SwitchFundsTransactionTicket";

export const PROP = {
    types: {
        transaction: PropTypes.shape({
            idTransaction: PropTypes.string,
            idTransactionStatus: PropTypes.string,
            valueDateAsString: PropTypes.string,
            executing: PropTypes.bool,
        }).isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const { dispatch, match, transaction, fetching, mode, prevMode, isChangingEnvironment } = props;
    const { id } = match.params;

    React.useEffect(() => {
        if (!isChangingEnvironment) {
            dispatch(SelectorsActionWMSwitchFunds.readSwitchFundsTransactionRequest({ idTransaction: id }));
        }
    }, [dispatch, id, isChangingEnvironment]);

    const wait = fetching || !transaction.data;
    return (
        <Container
            name={NAME}
            wait={wait}
            step={mode}
            head-title={UtilsI18n.get("wm.funds.switch.title")}
            head-onBack={(mode !== MODE.VIEW && mode !== MODE.EDIT && handleBack) || undefined}
            head-onBackWeb={mode === MODE.EDIT && handleBack}
            scopeToShowNotification={SCOPE.WEALTH_MANAGEMENT_SELL_FUNDS}>
            {mode === MODE.PREVIEW && <FormPreview />}
            {transaction.data && (mode === MODE.EDIT || mode === MODE.VIEW) && <FormView transaction={transaction} />}
        </Container>
    );

    function handleBack() {
        if (prevMode && prevMode !== mode && mode !== MODE.VIEW) {
            dispatch(SelectorsActionWMSwitchFunds.previewCancelRequest());
        } else {
            dispatch(GoBack());
        }
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        ...PROP.defaults,
        fetching: SelectorsStoreWMSwitchFunds.isFetching(store),
        transaction: SelectorsStoreWMSwitchFunds.getTransaction(store),
        mode: SelectorsStoreWMSwitchFunds.getMode(store),
        prevMode: SelectorsStoreWMSwitchFunds.getPrevMode(store),
        isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    };
};

export default Connect(mapStateToProps)(Component);
