import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    wealthManagementFundsSwitchPre: (props) => API.executeWithAccessToken(PATH.WM_FUNDS_SWITCH_PRE, props),
    wealthManagementFundsSwitchPreview: (props) => API.executeWithAccessToken(PATH.WM_FUNDS_SWITCH_PREVIEW, props),
    wealthManagementFundsSwitchReadBuyPaper: (props) =>
        API.executeWithAccessToken(PATH.WM_FUNDS_SWITCH_READ_BUY_PAPER, props),
    wealthManagementFundsSwitchSend: (props) => API.executeWithAccessToken(PATH.WM_FUNDS_SWITCH_SEND, props),
};

export const SelectorsAction = {
    cancelTransactionCustom: (props) => FactoryAction(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, props),
    paperSendFormRequest: (props) => FactoryAction(TYPE.SEND_FORM_PAPER_REQUEST, props),
    paperSendFormSuccess: (props) => FactoryAction(TYPE.SEND_FORM_PAPER_SUCCESS, props),
    preFormRequest: (props) => FactoryAction(TYPE.PRE_FORM_REQUEST, props),
    preFormSuccess: (props) => FactoryAction(TYPE.PRE_FORM_SUCCESS, props),
    previewCancelRequest: () => FactoryAction(TYPE.PREVIEW_CANCEL_REQUEST),
    previewPaperRequest: (props) => FactoryAction(TYPE.PREVIEW_PAPER_REQUEST, props),
    previewPaperSuccess: (props) => FactoryAction(TYPE.PREVIEW_PAPER_SUCCESS, props),
    readPaperToBuyRequest: (props) => FactoryAction(TYPE.READ_PAPER_TO_BUY_REQUEST, props),
    readPaperToBuySuccess: (props) => FactoryAction(TYPE.READ_PAPER_TO_BUY_SUCCESS, props),
    readPaperToSwitchSuccess: (props) => FactoryAction(TYPE.READ_PAPER_TO_SWITCH_SUCCESS, props),
    readSwitchFundsTransactionRequest: (props) => FactoryAction(TYPE.READ_TRANSACTION_REQUEST, props),
    readSwitchFundsTransactionSuccess: (props) => FactoryAction(TYPE.READ_TRANSACTION_SUCCESS, props),
    resetForm: () => FactoryAction(TYPE.RESET_FORM),
    restartFlow: () => FactoryAction(TYPE.RESTART_FLOW),
    selectedIdPaper: (props) => FactoryAction(TYPE.SELECTED_ID_PAPER, props),
    signTransaction: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_REQUEST, props),
    signTransactionPreview: () => FactoryAction(TYPE.SIGN_TRANSACTION_PREVIEW),
    taskFinished: () => FactoryAction(TYPE.TASK_FINISHED),
};

export const SelectorsStore = {
    getAmount: (store) => store[NAME].amount,
    getCurrency: (store) => store[NAME].currency,
    getDebitAccountAlias: (store) => store[NAME].debitAccountAlias,
    getDebitAccounts: (store) => store[NAME].debitAccounts,
    getDisclaimer: (store) => store[NAME].disclaimer,
    getErrors: (store) => store[NAME].errors,
    getIdDebitAccount: (store) => store[NAME].idDebitAccount,
    getIdPaperSelectedToBuy: (store) => store[NAME].idPaperSelectedToBuy,
    getIdPaperSelectedToSwitch: (store) => store[NAME].idPaperSelectedToSwitch,
    getIsSubmitting: (store) => store[NAME].isSubmitting,
    getlimitDate: (store) => store[NAME].limitDate,
    getMode: (store) => store[NAME].mode,
    getOrderCompleted: (store) => store[NAME].orderCompleted,
    getPaper: (store) => store[NAME].paper,
    getPaperName: (store) => store[NAME].paperName,
    getPaperToBuyName: (store) => store[NAME].paperToBuyName,
    getPaperToSwitchName: (store) => store[NAME].paperToSwitchName,
    getPapersToBuyList: (store) => store[NAME].papersToBuy,
    getPapersToSwitchList: (store) => store[NAME].papersToSwitch,
    getPrevMode: (store) => store[NAME].prevMode,
    getSelectedPaperToBuy: (store) => store[NAME].selectedPaperToBuy,
    getSelectedPaperToSwitch: (store) => store[NAME].selectedPaperToSwitch,
    getTimezoneOffset: (store) => store[NAME].timezoneOffset,
    getTransaction: (store) => store[NAME].transaction,
    isFetching: (store) => store[NAME].isFetching,
};
