import React from "react";

import { routerActions as ActionRouter } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, NO_DATA, PAPER, ZERO, ZERO_NUMBER } from "~/constants";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsAction } from "~/store/wm/funds/sell";
import * as UtilI18n from "~/util/i18n";

import Button from "~/components/Button/Button";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import FieldReadOnly from "~/pages/_components/fields/FieldReadOnly";

import ConfirmModal from "~/pages/investment/ConfirmModal";
import FormatNumber from "~/pages/wm/funds/_component/FormatNumber";

import Style from "./PaperDetails.rules.scss";

export const NAME = "PaperDetails";

export const PROP = {
    types: {
        availableForSelling: PropTypes.number,
        processingSale: PropTypes.number,
    },
    defaults: {
        availableForSelling: ZERO_NUMBER,
        processingSale: ZERO_NUMBER,
    },
};
export function Component({
    activeEnvironment,
    availableForSelling,
    dispatch,
    extraInfo,
    hideLink,
    hideName,
    hideWarning,
    hidelabel,
    i18n,
    label,
    paper,
    processingSale,
}) {
    const [showModal, setShowModal] = React.useState(false);
    const { othersFields } = paper || {};

    let countPart = EMPTY_STR;
    let isin = EMPTY_STR;
    let levelRisk = EMPTY_STR;
    let levelRiskLabel = EMPTY_STR;
    let marketPrice = EMPTY_STR;
    let paperCurrency = EMPTY_STR;
    let valueEfective = EMPTY_STR;
    let warning = false;

    if (othersFields) {
        paperCurrency = UtilI18n.get(`currency.label.${othersFields[PAPER.CURRENCY]}`);
        levelRiskLabel =
            othersFields[PAPER.LEVEL_RISK] !== ZERO
                ? UtilI18n.get(`wm.papers.risk.${othersFields[PAPER.LEVEL_RISK]}`)
                : NO_DATA;
        isin = othersFields[PAPER.ISIN];
        levelRisk = othersFields[PAPER.LEVEL_RISK];
    }

    if (activeEnvironment && othersFields) {
        const { CANTIDADCUOTAPARTES, VALOREFECTIVO, PRECIOMERCADO } = othersFields;

        warning = activeEnvironment?.riskProfileCode < levelRisk;
        valueEfective = <FormatNumber amount={parseFloat(VALOREFECTIVO, 10)} />;
        countPart = <FormatNumber amount={parseFloat(CANTIDADCUOTAPARTES, 10)} />;
        marketPrice = PRECIOMERCADO;
    }

    const handleClick = () => {
        const { LINK1, LINK2, LINK3, PAPEL, PDFOLINK, TIPOPRODUCTOORIGEN } = othersFields;

        setShowModal(false);

        let linkPaper;

        if (PDFOLINK === "LINK") {
            linkPaper = `${LINK1 || EMPTY_STR}${LINK2 || EMPTY_STR}${LINK3 || EMPTY_STR}`;

            if (linkPaper.toUpperCase().startsWith("HTTP://")) {
                dispatch(ActionRouter.push(linkPaper));
            } else {
                dispatch(
                    SelectorsAction.getPaperPDF({
                        idPaper: PAPEL,
                        productType: TIPOPRODUCTOORIGEN,
                        detailType: "localLink",
                    }),
                );
            }
        } else {
            dispatch(
                SelectorsAction.getPaperPDF({
                    idPaper: PAPEL,
                    productType: TIPOPRODUCTOORIGEN,
                    detailType: "localPDF",
                }),
            );
        }
    };

    return (
        <div className="form-section">
            <ConfirmModal
                show={showModal}
                handleCloseModal={() => setShowModal(false)}
                onSubmit={() => handleClick()}
            />
            {!hidelabel && <FieldLabel labelKey={label} />}

            <div className="paper">
                {!hideName && <span className="data-label">{othersFields?.DESCRIPCION ?? EMPTY_STR}</span>}
                <div id={Style.ID}>
                    <FieldReadOnly value={paperCurrency} className="currency" label="wm.buy.type.currency" />
                    <FieldReadOnly
                        value={<FormatNumber amount={Number(marketPrice)?.toFixed(2)} />}
                        className="price"
                        label="wm.buy.type.price"
                    />
                    <FieldReadOnly value={levelRiskLabel} className="profile" label="wm.buy.type.profile" />
                    <FieldReadOnly value={isin} className="isin" label="wm.buy.type.isin" />
                    {extraInfo && (
                        <FieldReadOnly value={valueEfective} className="value" label="wm.sell.value.efective" />
                    )}
                    {extraInfo && <FieldReadOnly value={countPart} className="parts" label="wm.sell.count" />}
                    {processingSale > 0 && (
                        <FieldReadOnly
                            value={<FormatNumber amount={parseFloat(processingSale, 10)} />}
                            className="processingSale"
                            label="client.wm.allInvestments.sell.processingSale"
                        />
                    )}
                    {processingSale > 0 && (
                        <FieldReadOnly
                            value={<FormatNumber amount={parseFloat(availableForSelling, 10)} />}
                            className="availableForSelling"
                            label="client.wm.allInvestments.sell.availableForSelling"
                        />
                    )}
                    {!hideLink && (
                        <div className="paper-button">
                            <Button type="button" variant="link" onClick={() => setShowModal(true)}>
                                {i18n.detail}
                            </Button>
                        </div>
                    )}
                </div>
            </div>

            {!hideWarning && warning && (
                <div className="form-warn">
                    <span>{UtilI18n.get("wm.funds.buy.warningHightRisk")}</span>
                </div>
            )}
        </div>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    i18n: {
        detail: StoreI18n.getMessage(store, "wm.fund.item.detail"),
    },
});

export default Connect(mapStateToProps)(Component);
