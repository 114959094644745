import { LOCATION_CHANGE } from "connected-react-router";

import { EMPTY_STR } from "~/constants";
import { MODE } from "~/constants/form";
import { STATUS } from "~/constants/transaction";
import { TYPE as TypesBackoffice } from "~/store/backoffice";
import { shouldKeepStateInUrlScope as ShouldKeepStateInUrlScope } from "~/util/form";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            const shouldKeepStateInUrlScope = ShouldKeepStateInUrlScope(action.payload.location.pathname);

            if (shouldKeepStateInUrlScope) {
                return {
                    ...storeProp,
                    prevRoute: action.payload.location,
                    requestInfoData: PROP.defaults.requestInfoData,
                };
            }
            return {
                ...PROP.defaults,
                prevRoute: action.payload.location,
            };
        }
        case TYPE.CLEAR_FORM:
            return {
                ...storeProp,
                idPaper: EMPTY_STR,
                paper: {},
                formData: {},
                papersList: null,
                debitAccountsList: [],
            };
        case TYPE.CLEAR_SEARCH_RESULTS:
            return {
                ...storeProp,
                papersList: null,
            };
        case TYPE.SEARCH_BY_ISIN_SUCCESS: {
            return {
                ...storeProp,
                fetching: false,
                papersList: action.papersList,
            };
        }
        case TYPE.SELL_BONDS_PRE_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.BUY_BONDS_PRE_REQUEST:
        case TYPE.SEARCH_BY_ISIN_REQUEST:
        case TYPE.BUY_BONDS_SEND_FORM:
        case TYPE.SELL_BONDS_SEND_FORM:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.GO_STEP_BACK:
            return {
                ...storeProp,
                mode: storeProp.mode === MODE.PREVIEW && MODE.EDIT,
                prevMode: storeProp.mode === MODE.PREVIEW && MODE.PREVIEW,
            };
        case TYPE.SEARCH_BY_ISIN_FAILURE:
        case TYPE.BUY_BONDS_FAILURE:
        case TYPE.BUY_BONDS_PRE_FAILURE:
        case TYPE.BUY_BONDS_PREVIEW_FAILURE:
        case TYPE.BUY_BONDS_SEND_FORM_FAILURE:
        case TYPE.SELL_BONDS_PRE_FAILURE:
        case TYPE.SELL_BONDS_PREVIEW_FAILURE:
        case TYPE.SELL_BONDS_SEND_FORM_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.BUY_BONDS_PRE_SUCCESS:
            return {
                ...storeProp,
                currencyList: action.currencyList,
                debitAccountsList: action.debitAccountsList,
                expirationYearsAhead: action.expirationYearsAhead,
                fetching: false,
                paper: action.paper,
                timezoneOffset: action.timezoneOffset,
            };
        case TYPE.SELL_BONDS_PRE_SUCCESS:
            return {
                ...storeProp,
                bonds: action.bonds,
                creditAccountList: action.creditAccountList,
                fetching: false,
                paper: action.paper,
                processingSale: action.processingSale,
                timezoneOffset: action.timezoneOffset,
            };
        case TYPE.BUY_BONDS_PREVIEW_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                previewData: action.previewData,
            };

        case TYPE.SELL_BONDS_PREVIEW_REQUEST:
            return {
                ...storeProp,
                fetching: true,
                formData: action.formData,
                prevMode: MODE.EDIT,
            };
        case TYPE.BUY_BONDS_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.PREVIEW,
                infoData: {
                    debitAccountAlias: action.debitAccountAlias,
                    nominalValue: action.nominalValue,
                    price: action.price,
                    currency: action.currency,
                    paperName: action.paperName,
                    limitDate: action.limitDate,
                    orderCompleted: action.orderCompleted,
                },
            };
        case TYPE.SELL_BONDS_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.PREVIEW,
                formData: {
                    ...storeProp.formData,
                    creditAccountAlias: action.creditAccountAlias,
                    currency: action.currency,
                    limitDate: action.limitDate,
                    orderCompleted: action.orderCompleted,
                },
            };

        case TYPE.PAPER_ID_SELECTED:
            return {
                ...storeProp,
                fetching: false,
                idPaper: action.idPaper,
            };
        case TYPE.BUY_BONDS_SEND_FORM_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };
        case TYPE.SELL_BONDS_SEND_FORM_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };
        case TYPE.READ_TRANSACTION_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.READ_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                transaction: action.transaction,
                mode: action.transaction.idTransactionStatus === STATUS.DRAFT ? MODE.EDIT : MODE.VIEW,
            };
        case TYPE.SIGN_TRANSACTION_PREVIEW:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.PREVIEW,
                infoData: {
                    debitAccountAlias: action.debitAccountAlias,
                    nominalValue: action.nominalValue,
                    price: action.price,
                    currency: action.currency,
                    paperName: action.paperName,
                },
            };
        case TYPE.RESTART_FLOW:
            return PROP.defaults;
        case TypesBackoffice.BACKOFFICE_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };
        default:
            return storeProp;
    }
};
