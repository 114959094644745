import React from "react";

import { replace as Replace, goBack as GoBack } from "connected-react-router";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { connect as Connect } from "react-redux";

import { EMPTY_STR, FINGERPRINT_CONFIGURATION, SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/Simple";
import {
    SelectorsAction as SelectorsActionFingerprint,
    SelectorsStore as SelectorsStoreFingerprint,
} from "~/store/fingerprint";
import DeviceUtils from "~/util/device";
import UtilLodash from "~/util/lodash";

import Button from "~/components/Button";
import I18n from "~/components/I18n";
import Wrap from "~/pages/_components/Container";

export const NAME = "FingerprintConfiguration";

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        fingerprintConfiguredUserDevices: PropTypes.shape({
            device: PropTypes.shape({
                deviceId: PropTypes.string,
                deviceModel: PropTypes.string,
            }),
        }),
        isDeviceWithFingerprint: PropTypes.bool,
        availability: PropTypes.shape({
            isHardwareDetected: PropTypes.bool.isRequired,
            isAvailable: PropTypes.bool.isRequired,
        }),
        fetching: PropTypes.bool,
    },
    defaults: {
        fingerprintConfiguredUserDevices: null,
        isDeviceWithFingerprint: false,
        availability: null,
        fetching: false,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(SelectorsActionFingerprint.fingerprintConfigurationPre());
        dispatch(SelectorsActionFingerprint.fingerprintAvailability());
    }

    renderForm = () => (
        <form className="above-the-fold">
            <Wrap className="container--layout align-items-center flex-grow">
                <Col sm={12} md={9} lg={6} xl={6} className="col">
                    <p className="text-lead">
                        <I18n id="settings.biometrics.title" />
                    </p>
                    <p className="text-lead">
                        <strong>
                            <I18n id="settings.fingerprintConfiguration.configuredDevices" />
                        </strong>
                    </p>
                    {this.renderDevices()}
                    {this.renderText()}
                </Col>
            </Wrap>
            <Wrap className="container--layout align-items-center">
                <Col sm={12} md={9} lg={6} xl={6} className="col">
                    {this.renderConfigureButton()}
                </Col>
            </Wrap>
        </form>
    );

    handleConfigureNow = (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        dispatch(SelectorsActionFingerprint.fingerprintVerification());
    };

    handleDeleteDevicePre = (e, deviceIndex) => {
        e.preventDefault();
        const { dispatch, fingerprintConfiguredUserDevices } = this.props;

        dispatch(
            SelectorsActionFingerprint.fingerprintDeleteUserDevicePre({
                device: fingerprintConfiguredUserDevices[deviceIndex],
            }),
        );
        dispatch(Replace("/settings/fingerprintConfiguration/deleteConfirmation"));
    };

    handleEnrollFingerprintOnDevice = (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        dispatch(SelectorsActionFingerprint.enrollFingerprintOnDevice({ enrollmentFrom: FINGERPRINT_CONFIGURATION }));
    };

    handleHeaderBack = () => {
        const { dispatch } = this.props;
        dispatch(GoBack());
    };

    isDeviceIdInSession = () => {
        const { fingerprintConfiguredUserDevices } = this.props;

        const isMobileNative = DeviceUtils.isMobileNative();

        if (isMobileNative && fingerprintConfiguredUserDevices) {
            const device = fingerprintConfiguredUserDevices.filter(
                (item) => item.deviceId.search(DeviceUtils.id()) !== -1,
            );
            return !UtilLodash.isEmpty(device);
        }
        return false;
    };

    isBiometricAvailable = () => {
        const { isDeviceWithFingerprint, availability } = this.props;
        const isMobileNative = DeviceUtils.isMobileNative();

        return (
            isMobileNative &&
            !isDeviceWithFingerprint &&
            availability &&
            availability.isHardwareDetected &&
            !this.isDeviceIdInSession()
        );
    };

    renderDevices() {
        const { fingerprintConfiguredUserDevices } = this.props;

        if (fingerprintConfiguredUserDevices && fingerprintConfiguredUserDevices.length) {
            return (
                <ul className="navigational-list">
                    {Object.keys(fingerprintConfiguredUserDevices).map((deviceIndex) => {
                        const { deviceModel } = fingerprintConfiguredUserDevices[deviceIndex];
                        return (
                            <li className="navigational-list-item" key={deviceIndex}>
                                <div className="navigational-list-item-container">
                                    <I18n id={`devices.apple.identifier.${deviceModel}`} defaultValue={deviceModel} />
                                    <Button
                                        block={false}
                                        className="btn-only-icon btn-circle "
                                        image="trash.svg"
                                        onClick={(e) => this.handleDeleteDevicePre(e, deviceIndex)}
                                        label="settings.fingerprintConfiguration.device.remove"
                                    />
                                </div>
                            </li>
                        );
                    })}
                </ul>
            );
        }
        return (
            <div className="notification-wrapper">
                <I18n id="settings.fingerprintConfiguration.configuredDevices.none" />
            </div>
        );
    }

    renderConfigureButton() {
        const { availability } = this.props;
        if (this.isBiometricAvailable()) {
            let label = EMPTY_STR;
            let onClick = EMPTY_STR;

            if (availability.hasEnrolledFingerprints && availability.isAvailable) {
                label = "settings.fingerprintConfiguration.device.register";
                onClick = this.handleConfigureNow;
            } else {
                label = "settings.fingerprintConfiguration.device.enrolledFingerprints.enroll";
                onClick = this.handleEnrollFingerprintOnDevice;
            }

            return <Button label={label} bsStyle="primary" onClick={onClick} />;
        }
        return null;
    }

    renderText() {
        const { availability } = this.props;
        if (this.isBiometricAvailable()) {
            if (availability.hasEnrolledFingerprints && availability.isAvailable) {
                return <I18n id="settings.fingerprintConfiguration.warning" />;
            }
            return <I18n id="settings.fingerprintConfiguration.device.enrolledFingerprints.none" />;
        }
        return null;
    }

    render() {
        const { fetching } = this.props;

        return (
            <Container
                name={NAME}
                head-title="settings.biometrics"
                head-onBackWeb={this.handleHeaderBack}
                head-onClose={this.handleHeaderBack}
                wait={fetching}
                scopeToShowNotification={SCOPE.FINGER_PRINT_CONFIGURATION}>
                {this.renderForm()}
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    fetching: SelectorsStoreFingerprint.getFetching(store),
    availability: SelectorsStoreFingerprint.getAvailability(store),
    isDeviceWithFingerprint: SelectorsStoreFingerprint.isDeviceWithFingerprint(store),
    fingerprintConfiguredUserDevices: SelectorsStoreFingerprint.getFingerprintConfiguredUserDevices(store),
});

export default Connect(mapStateToProps)(Component);
