import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    getCategories: () => API.executeWithAccessToken(PATH.GET_CATEGORIES),
    getSubCategories: (props) => API.executeWithAccessToken(PATH.GET_SUB_CATEGORIES, props),
    getFileDownloadsList: (props) => {
        const { filters, pageNumber } = props;
        const data = { ...filters, pageNumber };
        return API.executeWithAccessToken(PATH.GET_FILE_DOWNLOADS_LIST, data);
    },
    getFileDownload: (props) => API.executeWithAccessToken(PATH.DOWNLOAD_FILE, props),
};

export const SelectorsAction = {
    downloadFileRequest: (props) => FactoryAction(TYPE.GET_FILE_DOWNLOAD_REQUEST, props),
    downloadFileSuccess: (props) => FactoryAction(TYPE.GET_FILE_DOWNLOAD_SUCCESS, props),
    downloadFileFailure: (props) => FactoryAction(TYPE.GET_FILE_DOWNLOAD_FAILURE, props),

    getFileDownloadsListRequest: (props) => FactoryAction(TYPE.GET_FILE_DOWNLOADS_LIST_REQUEST, props),
    getFileDownloadsListSuccess: (props) => FactoryAction(TYPE.GET_FILE_DOWNLOADS_LIST_SUCCESS, props),
    getFileDownloadsListFailure: (props) => FactoryAction(TYPE.GET_FILE_DOWNLOADS_LIST_FAILURE, props),

    getCategoriesRequest: () => FactoryAction(TYPE.GET_CATEGORIES_REQUEST),
    getCategoriesSuccess: (props) => FactoryAction(TYPE.GET_CATEGORIES_SUCCESS, props),
    getCategoriesFailure: (props) => FactoryAction(TYPE.GET_CATEGORIES_FAILURE, props),

    getSubCategoriesRequest: (props) => FactoryAction(TYPE.GET_SUB_CATEGORIES_REQUEST, props),
    getSubCategoriesSuccess: (props) => FactoryAction(TYPE.GET_SUB_CATEGORIES_SUCCESS, props),
    getSubCategoriesFailure: (props) => FactoryAction(TYPE.GET_SUB_CATEGORIES_FAILURE, props),

    resetFilters: () => FactoryAction(TYPE.RESET_FILTERS),
    resetList: () => FactoryAction(TYPE.RESET_LIST),
};

export const SelectorsStore = {
    getCategories: (store) => store[NAME].categories,
    getFileDownloadsList: (store) => store[NAME].downloadsList,
    getPageNumber: (store) => store[NAME].pageNumber,
    getSubCategories: (store) => store[NAME].subCategories,
    isFetching: (store) => store[NAME].fetching,
    getMoreLines: (store) => store[NAME].moreLines,
    getFilters: (store) => store[NAME].filters,
};
