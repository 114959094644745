import React from "react";

import { Field, Form } from "formik";
import PropTypes from "prop-types";

import { LEVEL, EMPTY_STR, EVENT_BACKBUTTON, SCOPE, INVITATION_CODE_GROUP_LENGTH } from "~/constants";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import UtilsScanner from "~/util/barcodeScanner";
import * as ConfigUtils from "~/util/config";
import UtilsDevice from "~/util/device";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesHtmlElement, Defaults as DefaultsHtmlElement } from "~/util/prop/html-element";

import Button from "~/components/Button";
import Image from "~/components/Image";
import TextField from "~/pages/_components/fields/TextField";

export const NAME = "InvitationCodeForm";

export const PROP = {
    types: {
        ...TypesHtmlElement,
        formId: PropTypes.string,
    },
    defaults: {
        ...DefaultsHtmlElement,
        formId: EMPTY_STR,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {
        fetching: false,
    };

    handleScanSuccess = (result) => {
        const { setFieldValue } = this.props;
        if (result.cancelled) {
            document.addEventListener(EVENT_BACKBUTTON, this.ignoreBack);
        }

        setFieldValue("invitationCode", result.text);

        this.setState({ fetching: false });
    };

    ignoreBack = () => {
        document.removeEventListener(EVENT_BACKBUTTON, this.ignoreBack);
        return false;
    };

    handleScanError = () => {
        const { dispatch } = this.props;
        this.setState({ fetching: false });
        dispatch(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("enrollment.index.invitationCode.scanQRCode.error"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.INVITATION_CODE],
            }),
        );
    };

    handleClick = () => {
        this.setState({ fetching: true });
        UtilsScanner.scan(this.handleScanSuccess, this.handleScanError);
    };

    render() {
        const { formId } = this.props;
        const { fetching } = this.state;
        const groupLength = ConfigUtils.getInteger("validationCodes.id.groups.length");
        const separators = groupLength - 1; // cantidad de separadores que tiene la invitacion
        const maxLength = groupLength * INVITATION_CODE_GROUP_LENGTH + separators; // se calcula el largo del codigo de invitacion
        return (
            <React.Fragment>
                <Form className="login-form">
                    {UtilsDevice.isMobileNative() && (
                        <div className="login-form-field">
                            <Button
                                image="qr-code.svg"
                                className="login-form-button custom-button"
                                type="button"
                                onClick={this.handleClick}
                                label={`${formId}.scanQRCode`}
                                loading={fetching}
                            />
                        </div>
                    )}
                    <div className="login-form-field">
                        <div className="login-form-field-block">
                            <div className="login-form-field-image">
                                <Image src="verification-code-field.svg" className="login-form-field-icon aster-icon" />
                            </div>
                            <Field
                                idForm={formId}
                                name="invitationCode"
                                type="text"
                                maxLength={maxLength}
                                className="login-form-input"
                                hidelabel
                                autoComplete="off"
                                component={TextField}
                            />
                        </div>
                        <span className="login-form-field-validation" />
                    </div>
                    <div className="login-form-field button-field">
                        <Button
                            className="login-form-button"
                            bsStyle="primary"
                            label={`${formId}.continue`}
                            loading={false}
                            type="submit"
                        />
                    </div>
                </Form>
            </React.Fragment>
        );
    }
}

export default Component;
