import React from "react";

import ClassNames from "classnames";
import { routerActions as ActionRouter } from "connected-react-router";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router";

import { CDP_CURRENCY, CC_TYPES, EMPTY_STR, SCOPE, YES_ES } from "~/constants";
import Container from "~/containers/Internal/Info";
import { SelectorsAction as ActionDC, SelectorsStore as StoreDC, PROP as PROP_DC } from "~/store/debitCards";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";

import DataDate from "~/components/DataDate";
import DataNumber from "~/components/DataNumber";
import I18n from "~/components/I18n";
import ConfirmationModal from "~/pages/_components/ConfirmationModal";
import SwitchField from "~/pages/_components/fields/SwitchField";

import Style from "./Details.rules.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {
        ...PROP_DC.types,
    },
    defaults: {
        ...PROP_DC.defaults,
    },
};

export function Component(props) {
    const prevId = React.useRef();
    const { dispatch, debitCard, isChangingEnvironment, isFetching, id, i18n } = props;
    const { cardStatus } = debitCard || {};
    const [isActive, setIsActive] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [modalLabel, setModalLabel] = React.useState(EMPTY_STR);

    const wait = isFetching || !debitCard || id !== prevId.current;

    React.useEffect(() => {
        if (debitCard) {
            if (debitCard.cardStatus === "ACTIVA") {
                setIsActive(true);
                setModalLabel("client.debitCard.changeStatus.confirmation.D");
            } else if (debitCard.cardStatus === "INACTIVA") {
                setIsActive(false);
                setModalLabel("client.debitCard.changeStatus.confirmation.A");
            }
        }
    }, [cardStatus, debitCard]);

    React.useEffect(() => {
        if (!isChangingEnvironment && (!debitCard || id !== prevId.current)) {
            dispatch(
                ActionDC.readDebitCardRequest({
                    idDebitCard: id,
                }),
            );
            prevId.current = id;
        }
    }, [debitCard, dispatch, id, isChangingEnvironment]);

    const handleModal = React.useCallback(() => {
        setShowModal(!showModal);
    }, [showModal]);

    function handleSubmit() {
        let order;
        if (debitCard.cardStatus === "ACTIVA") {
            order = "D";
        } else if (debitCard.cardStatus === "INACTIVA") {
            order = "A";
        }
        const params = { idDebitCard: id, order };
        dispatch(ActionDC.changeStatusRequest(params));
        handleModal();
    }

    function handleHeadBack() {
        dispatch(ActionRouter.push(`/creditCards`));
    }

    const info = {};
    const slides = [];

    if (!wait) {
        const { brand, cardHolder, number, productAlias } = debitCard;

        info.brand = `cc-types/${CC_TYPES[brand]}.svg`;
        info.number = number ? number.slice(-9) : EMPTY_STR;
        info.productAlias = productAlias || EMPTY_STR;
        info.cardHolder = cardHolder || EMPTY_STR;
        slides.push([
            {
                type: "String",
                props: {
                    value: cardHolder,
                },
            },
        ]);
    }

    const labelSwitch = isActive ? i18n.enabled : i18n.disabled;
    const labelQuestion = isActive ? i18n.deactivated : i18n.activated;
    const labelCardStatus = isActive ? i18n.isActive : i18n.isInactive;

    return (
        <React.Fragment>
            {!wait && (
                <ConfirmationModal
                    show={showModal}
                    handleAcept={() => handleSubmit()}
                    handleCancel={handleModal}
                    idLabel={modalLabel}
                />
            )}
            <Container
                name={NAME}
                wait={wait}
                className={CLASS}
                head-title={info.productAlias}
                head-onBack={handleHeadBack}
                info-title-label={i18n.infoTitle}
                info-title-image={info.brand}
                info-title-data={info.number}
                info-data={slides}
                scopeToShowNotification={SCOPE.DEBIT_CARD_DETAILS}>
                {!wait && [
                    <Information
                        container-title={i18n.labelInfo}
                        container-name={Style.NAME_INFO}
                        container-role="list"
                    />,
                ]}
            </Container>
        </React.Fragment>
    );

    function Information() {
        const {
            associatedCAs,
            associatedCCs,
            cardLocation,
            dueDate,
            showDisclaimer,
            freeMovementsQty,
            buyLimitUSD,
            buyLimitUYU,
            extractionLimitUSD,
            extractionLimitUYU,
            payLimitUSD,
            payLimitUYU,
            permissions,
        } = debitCard;

        let movementsQtyMessage = EMPTY_STR;

        if (freeMovementsQty === 0) {
            movementsQtyMessage = i18n.notApplicable;
        } else if (freeMovementsQty === 999) {
            movementsQtyMessage = i18n.unlimited;
        } else {
            movementsQtyMessage = freeMovementsQty;
        }

        return (
            <div className="container-information">
                <div className="items-column">
                    {(associatedCAs || associatedCCs) && (
                        <section role="listitem">
                            <h4>{i18n.associatedAccounts}</h4>
                            <div className="flex-container">
                                {associatedCAs.map((account) => {
                                    return <span>{account}</span>;
                                })}
                                {associatedCCs.map((account) => {
                                    return <span>{account}</span>;
                                })}
                            </div>
                        </section>
                    )}
                    {dueDate && (
                        <section role="listitem">
                            <h4>{i18n.dueDate}</h4>
                            <DataDate value={dueDate} />
                        </section>
                    )}
                    <section role="listitem">
                        <h4>{i18n.freeMovementsQty}</h4>
                        <span>{movementsQtyMessage}</span>
                    </section>
                </div>
                <div className="items-column">
                    <section role="listitem">
                        <h4>{i18n.extractionLimit}</h4>
                        <div className="flex-container">
                            <DataNumber value={extractionLimitUSD} prefix={CDP_CURRENCY.USD} />
                            <DataNumber value={extractionLimitUYU} prefix={CDP_CURRENCY.UYU} />
                        </div>
                    </section>
                    <section role="listitem">
                        <h4>{i18n.payLimit}</h4>
                        <div className="flex-container">
                            <DataNumber value={payLimitUSD} prefix={CDP_CURRENCY.USD} />
                            <DataNumber value={payLimitUYU} prefix={CDP_CURRENCY.UYU} />
                        </div>
                    </section>
                    <section role="listitem">
                        <h4>{i18n.buyLimit}</h4>
                        <div className="flex-container">
                            <DataNumber value={buyLimitUSD} prefix={CDP_CURRENCY.USD} />
                            <DataNumber value={buyLimitUYU} prefix={CDP_CURRENCY.UYU} />
                        </div>
                    </section>
                </div>
                <div className="items-column">
                    <section role="listitem">
                        <h4>{i18n.cardLocation}</h4>
                        <div className="flex-container">
                            <span>{cardLocation}</span>
                        </div>
                    </section>
                    <section role="listitem" className="status-containter">
                        <h4>{i18n.status}</h4>
                        <span className={ClassNames("status-default", { "status-active": isActive })}>
                            {isActive ? i18n.isActive.toUpperCase() : i18n.isInactive.toUpperCase()}
                        </span>
                    </section>
                </div>
                {permissions.changeStatus && (
                    <div className="container-switch">
                        {showDisclaimer === YES_ES && (
                            <div className="disclaimer">
                                <span>{i18n.disclaimerTitle}</span>
                                <p>{i18n.disclaimer}</p>
                            </div>
                        )}
                        <div className="switch">
                            <I18n
                                id="debitCard.details.label.activationQuestion"
                                labelCardStatus={labelCardStatus}
                                labelQuestion={labelQuestion}
                            />
                            <span>{i18n.activationQuestion}</span>
                            <SwitchField
                                name="name"
                                key="key"
                                label={labelSwitch}
                                value={isActive}
                                onChange={handleModal}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (store, props) => {
    const { match } = props;
    const i18n = {
        activated: StoreI18n.getMessage(store, "debitCard.details.label.activationQuestion.activated"),
        associatedAccounts: StoreI18n.getMessage(store, "debitCard.details.associatedAccounts"),
        buyLimit: StoreI18n.getMessage(store, "debitCard.details.buyLimit"),
        cardLocation: StoreI18n.getMessage(store, "debitCard.details.cardLocation"),
        comissionAccount: StoreI18n.getMessage(store, "debitCard.details.comissionAccount"),
        deactivated: StoreI18n.getMessage(store, "debitCard.details.label.activationQuestion.deactivated"),
        disabled: StoreI18n.getMessage(store, "debitCard.details.disabled"),
        disclaimer: StoreI18n.getMessage(store, "debitCard.details.disclaimer"),
        disclaimerTitle: StoreI18n.getMessage(store, "debitCard.details.disclaimer.title"),
        dueDate: StoreI18n.getMessage(store, "debitCard.details.dueDate"),
        enabled: StoreI18n.getMessage(store, "debitCard.details.enabled"),
        extractionLimit: StoreI18n.getMessage(store, "debitCard.details.extractionLimit"),
        freeMovementsQty: StoreI18n.getMessage(store, "debitCard.details.freeMovementsQty"),
        infoTitle: StoreI18n.getMessage(store, "debitCard.label"),
        isActive: StoreI18n.getMessage(store, "debitCard.details.label.isActive"),
        isInactive: StoreI18n.getMessage(store, "debitCard.details.label.isInactive"),
        loading: StoreI18n.getMessage(store, "global.loading"),
        notAvailable: StoreI18n.getMessage(store, "data.notAvailable.short"),
        payLimit: StoreI18n.getMessage(store, "debitCard.details.payLimit"),
        status: StoreI18n.getMessage(store, "debitCard.details.status"),
        notApplicable: StoreI18n.getMessage(store, "debitCard.details.notApplicable"),
        unlimited: StoreI18n.getMessage(store, "debitCard.details.unlimited"),
    };
    return {
        id: match.params.id,
        debitCard: StoreDC.getDebitCard(store),
        isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
        isFetching: StoreDC.getFetching(store),
        i18n,
    };
};

export default Connect(mapStateToProps)(WithRouter(Component));
