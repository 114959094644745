import * as API from "~/store/api";
import * as UtilsConfig from "~/util/config";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

const validateFile = (props) => {
    const { invalidLines } = props;

    return invalidLines > UtilsConfig.getInteger("forms.fields.multilinefile.max.invalid.lines");
};

export const SelectorsMiddleware = {
    deleteFile: (props) => API.executeWithAccessToken(PATH.DELETE_FILE, props),
};

export const SelectorsAction = {
    cleanPond: (props) => FactoryAction(TYPE.CLEAR_POND, props),
    deleteFileRequest: (props) => FactoryAction(TYPE.DELETE_FILE_REQUEST, props),
    deleteFileFailure: (props) => FactoryAction(TYPE.DELETE_FILE_FAILURE, props),
    deleteFileSuccess: (props) => FactoryAction(TYPE.DELETE_FILE_SUCCESS, props),
    onFileProcess: (props) => {
        const params = {
            data: {
                ...props,
                invalidFile: validateFile(props),
                hasFile: true,
            },
        };

        return FactoryAction(TYPE.ON_FILE_PROCESS, params);
    },
    onFileRemoved: (props) => FactoryAction(TYPE.ON_FILE_REMOVED, props),
    onPaymentMethodChange: (props) => FactoryAction(TYPE.ON_PAYMENT_METHOD_CHANGE, props),
    setExtraData: (props) => FactoryAction(TYPE.SET_EXTRA_DATA, props),
    setMetadata: (props) => FactoryAction(TYPE.SET_METADATA, props),
};

export const SelectorsStore = {
    getFailedLines: (store) => store[NAME].failedLines,
    getFormExtraData: (store) => store[NAME].extraData,
    getIdFile: (store) => store[NAME].idFile,
    getIdRelatedFile: (store) => store[NAME].idRelatedFile,
    getNameRelatedFile: (store) => store[NAME].nameRelatedFile,
    getPendingLines: (store) => store[NAME].pendingLines,
    getProcessedFileData: (store) => store[NAME],
    getProcessedLines: (store) => {
        const { processedLines, failedLines } = store[NAME];

        return processedLines + failedLines;
    },
    getTotalLines: (store) => {
        const { pendingLines, processedLines, failedLines } = store[NAME];

        return pendingLines + processedLines + failedLines;
    },
    hasFile: (store) => store[NAME].hasFile,
    pondHasToBeCleaned: (store) => store[NAME].forcePondCleanUp,
};
