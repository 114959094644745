import React from "react";

import { push as Push } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EVENT_BACKBUTTON } from "~/constants";
import Container from "~/containers/External/Form";
import { SelectorsStore as SelectorsStoreChangeMyPhone } from "~/store/changeMyPhone";
import { SelectorsStore } from "~/store/enrollment";
import { Defaults as DefaultsHtmlElement, Types as TypesHtmlElement } from "~/util/prop/html-element";
import { Defaults as DefaultsRedux, Types as TypesRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import WithExchangeToken from "~/pages/_components/withExchangeToken";

import Style from "./FinishEnrollment.rules.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {
        ...TypesHtmlElement,
        ...TypesRedux,
        exchangeToken: PropTypes.string,
        exchangeTokenChangeMyPhone: PropTypes.string,
        finishState: PropTypes.string.isRequired,
    },
    defaults: {
        ...DefaultsHtmlElement,
        ...DefaultsRedux,
        exchangeToken: null,
        exchangeTokenChangeMyPhone: null,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    constructor(props) {
        super(props);

        const { exchangeToken, exchangeTokenChangeMyPhone, dispatch } = props;
        if (!exchangeToken && !exchangeTokenChangeMyPhone) {
            // TODO: ver si tiramos un notificacion
            dispatch(Push("/"));
        }
    }

    componentDidMount() {
        document.addEventListener(EVENT_BACKBUTTON, this.backButtonAction);
    }

    componentWillUnmount() {
        document.removeEventListener(EVENT_BACKBUTTON, this.backButtonAction);
    }

    backButtonAction = () => {
        const { dispatch } = this.props;
        dispatch(Push("/"));
    };

    handleLogin = () => {
        const { dispatch } = this.props;
        dispatch(Push("/login"));
    };

    handleExit = () => {
        const { dispatch } = this.props;
        dispatch(Push("/"));
    };

    render() {
        const { finishState } = this.props;

        return (
            <Container
                name={NAME}
                className={CLASS}
                head-onClose={this.handleExit}
                head-title="enrollment.finish.title">
                <div className="enrollment page-success">
                    <div className="success">
                        <div className="success-block">
                            <div className="login-row simple-row">
                                <span className="login-message">
                                    <Image src="finished.svg" className="svg-icon" />
                                </span>
                            </div>
                            {finishState && (
                                <div className="login-row simple-row">
                                    <span className="login-message">
                                        <I18n id={`enrollment.finish.body.${finishState}`} />
                                    </span>
                                </div>
                            )}
                            <div className="login-row main-row">
                                <div className="login-form-field button-field">
                                    <Button
                                        className="success-form-button"
                                        bsStyle="primary"
                                        label="enrollment.finish.button"
                                        onClick={this.handleLogin}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="enrollment-content-dialog">
                        <span className="enrollment-content-dialog-text" />
                    </div>
                </div>
            </Container>
        );
    }
}

export default HighOrder(
    Connect((store) => ({
        exchangeToken: SelectorsStore.exchangeToken(store),
        exchangeTokenChangeMyPhone: SelectorsStoreChangeMyPhone.exchangeToken(store),
        finishState: SelectorsStore.finishState(store),
    })),
    WithExchangeToken,
)(Component);
