import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { COMMA, EMPTY_STR, SPACE_STR } from "~/constants";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import { SelectorsStore as SelectorsStoreComexExport } from "~/store/comex/export";
import { SelectorsAction as SelectorsActionFile } from "~/store/files";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import DataDate from "~/components/DataDate";
import Ticket from "~/components/Ticket/Ticket";
import FormattedAmount from "~/pages/_components/FormattedAmount";

import Style from "./Step3View.rules.scss";

const FORM_ID = "comex.export.transferCreditLetter";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
        transaction: PropTypes.shape({
            executing: PropTypes.bool,
            idTransaction: PropTypes.string,
            idTransactionStatus: PropTypes.string,
            valueDateAsString: PropTypes.string,
        }).isRequired,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const { dispatch, ticketConfirmation, transaction } = props;
    const { data } = transaction || {};

    const {
        address,
        amount,
        attachments,
        attachmentsNames,
        confirmationExpenses,
        contact,
        deadlineDocumentSubmission,
        issuerBankExpenses,
        merchandiseQuantity,
        newDueDate,
        newShippingDate,
        operationNumber,
        otherBankExpenses,
        otherExpenses,
        phone,
        secondBank,
        secondBankAccount,
        secondBankAddress,
        secondBankName,
        secondBeneficiary,
        specialInstructions,
        transferExpenses,
    } = data;

    const fileIds = attachments ? attachments.split(COMMA) : EMPTY_STR;

    const downloadFile = (idFile, fileName) => {
        dispatch(SelectorsActionFile.downloadFileRequest({ idFile, fileName }));
    };

    const shareTicket = (idTransactionToRead) => {
        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    };

    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    displayDownloadPDF
                    handleDownloadPDF={() => shareTicket(transaction.idTransaction)}
                    handleReject={handleReject}
                    handleSign={handleSign}
                    handleModify={handleModify}
                    hasMobileComponent
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}>
                    <Box flex directionColumn align="flex-start" className="mb-3">
                        {operationNumber && (
                            <div className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.operationNumber.label`)}</h4>
                                <span>{operationNumber}</span>
                            </div>
                        )}
                        {secondBeneficiary && (
                            <div className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.secondBeneficiary.label`)}</h4>
                                <span>{secondBeneficiary}</span>
                            </div>
                        )}
                        {address && (
                            <div className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.address.label`)}</h4>
                                <span>{address}</span>
                            </div>
                        )}
                        {contact && (
                            <div className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.contact.label`)}</h4>
                                <span>{contact}</span>
                            </div>
                        )}
                        {phone && (
                            <div className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.phone.label`)}</h4>
                                <span>{phone}</span>
                            </div>
                        )}
                        {secondBank && (
                            <div className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.secondBank.label`)}</h4>
                                <span>{secondBank}</span>
                            </div>
                        )}
                        {secondBankName && (
                            <div className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.secondBankName.label`)}</h4>
                                <span>{secondBankName}</span>
                            </div>
                        )}
                        {secondBankAddress && (
                            <div className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.secondBankAddress.label`)}</h4>
                                <span>{secondBankAddress}</span>
                            </div>
                        )}
                        {secondBankAccount && (
                            <div className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.secondBankAccount.label`)}</h4>
                                <span>{secondBankAccount}</span>
                            </div>
                        )}
                        {amount && (
                            <div className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.amount.label`)}</h4>
                                <span>
                                    <FormattedAmount
                                        currency={amount.currency}
                                        quantity={amount.quantity || amount.amount}
                                    />
                                </span>
                            </div>
                        )}
                        {newDueDate && (
                            <div className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.newDueDate.label`)}</h4>
                                <span>
                                    <DataDate value={newDueDate} />
                                </span>
                            </div>
                        )}
                        {newShippingDate && (
                            <div className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.newShippingDate.label`)}</h4>
                                <span>
                                    <DataDate value={newShippingDate} />
                                </span>
                            </div>
                        )}
                        {deadlineDocumentSubmission && (
                            <div className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.deadlineDocumentSubmission.label`)}</h4>
                                <span>
                                    {deadlineDocumentSubmission}
                                    {SPACE_STR}
                                    {UtilsI18n.get(`${FORM_ID}.deadlineDocumentSubmission.description`)}
                                </span>
                            </div>
                        )}
                        {merchandiseQuantity && (
                            <div className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.merchandiseQuantity.label`)}</h4>
                                <span>{merchandiseQuantity}</span>
                            </div>
                        )}
                        {attachments && (
                            <div className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.files.label`)}</h4>
                                <span>
                                    {fileIds.map((fileId, index) => (
                                        <div className="data-wrapper">
                                            <span className="data-label">
                                                <a onClick={() => downloadFile(fileId, attachmentsNames[index])}>
                                                    {attachmentsNames[index]}
                                                </a>
                                            </span>
                                        </div>
                                    ))}
                                </span>
                            </div>
                        )}
                        {transferExpenses && (
                            <div className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.transferExpenses.label`)}</h4>
                                <span>{UtilsI18n.get(`${FORM_ID}.option.${transferExpenses}`)}</span>
                            </div>
                        )}
                        {confirmationExpenses && (
                            <div className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.confirmationExpenses.label`)}</h4>
                                <span>{UtilsI18n.get(`${FORM_ID}.option.${confirmationExpenses}`)}</span>
                            </div>
                        )}
                        {otherExpenses && (
                            <div className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.otherExpenses.label`)}</h4>
                                <span>{UtilsI18n.get(`${FORM_ID}.option.${otherExpenses}`)}</span>
                            </div>
                        )}
                        {issuerBankExpenses && (
                            <div className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.issuerBankExpenses.label`)}</h4>
                                <span>{UtilsI18n.get(`${FORM_ID}.option.${issuerBankExpenses}`)}</span>
                            </div>
                        )}
                        {otherBankExpenses && (
                            <div className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.otherBankExpenses.label`)}</h4>
                                <span>{UtilsI18n.get(`${FORM_ID}.option.${otherBankExpenses}`)}</span>
                            </div>
                        )}
                        {specialInstructions && (
                            <div className="info-label">
                                <h4 className="bold">{UtilsI18n.get(`${FORM_ID}.specialInstructions.label`)}</h4>
                                <span>{specialInstructions}</span>
                            </div>
                        )}
                    </Box>
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { idTransaction } = transaction;

        dispatch(
            SelectorsActionComex.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        dispatch(SelectorsActionComex.signTransactionPreview({ data }));
    }

    function handleModify() {
        const { idTransaction } = transaction;
        dispatch(SelectorsActionForm.modifyTransaction({ idTransactionToMove: idTransaction }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreComexExport.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
