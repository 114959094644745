import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR, FIELD_SUBTYPE } from "~/constants";
import { MODE } from "~/constants/form";
import { isEmpty as IsEmpty } from "~/util/string";

import FieldError from "~/pages/_components/fields/FieldError";
import FieldHelp from "~/pages/_components/fields/FieldHelp";
import FieldHint from "~/pages/_components/fields/FieldHint";
import FieldLabel from "~/pages/_components/fields/FieldLabel";

const AMOUNT_FIELD_NAME = "amount";

export const NAME = "formField";

export const PROP = {
    types: {
        optionalMessageMap: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
        requiredErrorMap: PropTypes.oneOfType([PropTypes.object]),
        placeholderMap: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        helpMap: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        hintMap: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        dateFormat: PropTypes.string,
        field: PropTypes.oneOfType([PropTypes.object]).isRequired,
        form: PropTypes.oneOfType([PropTypes.object]).isRequired,
        labelMap: PropTypes.oneOfType([PropTypes.object]),
        alternativeLabelMap: PropTypes.shape({
            en: PropTypes.string,
            es: PropTypes.string,
            pt: PropTypes.string,
        }),
        lang: PropTypes.string.isRequired,
        isRequired: PropTypes.bool.isRequired,
        isUseAlternativeLabel: PropTypes.bool,
        readOnly: PropTypes.bool,
        mode: PropTypes.string.isRequired,
    },
    defaults: {
        requiredErrorMap: {},
        readOnly: false,
        labelMap: false,
        dateFormat: EMPTY_STR,
        placeholderMap: {},
        helpMap: {},
        hintMap: {},
        alternativeLabelMap: {},
        isUseAlternativeLabel: false,
    },
};

export function Component(
    options = {
        /* render component without label/error wrapper */
        pureRender: false,
        /* string | (props) => string */
        formClass: EMPTY_STR,
        /* (value, props) => Boolean */
        isValidValue: null,
        isEmptyValue: null,
        /* (props) => <Element /> */
        customLabel: () => null,
    },
) {
    return (FormFieldComponent) =>
        class extends React.Component {
            static displayName = `field(${FormFieldComponent.name})`;

            static propTypes = PROP.types;

            static defaultProps = PROP.defaults;

            state = {};

            static getDerivedStateFromProps(nextProps) {
                const { form, fieldList, dependsOnProduct } = nextProps;

                if (!dependsOnProduct) {
                    return {};
                }

                const dependencyValue = form.values[dependsOnProduct];
                const dependencyField = fieldList.find(({ idField }) => idField === dependsOnProduct) || {};

                return {
                    dependencyValue,
                    dependencyField,
                };
            }

            componentDidMount() {
                const { defaultValue, field, value } = this.props;
                if (!this.isEmptyValue(defaultValue) && this.isEmptyValue(field.value)) {
                    this.setValue(defaultValue);
                } else if (this.isEmptyValue(field.value) && !this.isEmptyValue(value)) {
                    this.setValue(value);
                }
            }

            setTouched = () => {
                const {
                    form: { setFieldTouched },
                    field: { name },
                } = this.props;
                setFieldTouched(name);
            };

            isTouched = () => {
                const {
                    form: { touched },
                    field: { name },
                } = this.props;
                return !!touched[name];
            };

            setError = (msg) => {
                const {
                    form: { errors, setErrors },
                    field: { name },
                } = this.props;

                if (msg) {
                    setErrors({ ...errors, [name]: msg });
                } else {
                    const { [name]: error, ...rest } = errors;
                    setErrors(rest);
                }
            };

            hasError = () => !!this.errorText();

            errorText = () => {
                const {
                    form: { errors },
                    field: { name },
                } = this.props;

                return errors[name];
            };

            setValue = (value) => {
                const {
                    form: { setFieldValue },
                    field: { name },
                    subType,
                    idValidation,
                    fieldList,
                } = this.props;
                let currentValue = value;
                if (idValidation === "email" || this.isValidValue(currentValue)) {
                    if (name === "debitAccount" && subType !== FIELD_SUBTYPE.CURRENCY_DISCLAIMER) {
                        const [arrayTemp] = fieldList.filter((fieldValue) => fieldValue.idField === "debitAccount");
                        const [account] = arrayTemp.data.options.filter(
                            (fieldValue) => fieldValue.id === currentValue.value,
                        );
                        if (account) {
                            this.changeCurrencyAmount(currentValue);
                        } else {
                            const [firstAccount] = arrayTemp.data.options;
                            currentValue = { value: firstAccount.id, isFrequentDestination: false };
                            this.changeCurrencyAmount(currentValue);
                        }
                    }
                    setFieldValue(name, currentValue);
                    this.validate(currentValue);
                }
            };

            validate = (value) => {
                const { isRequired } = this.props;

                if (isRequired && this.isEmptyValue(value)) {
                    this.setError(this.i18n("requiredError"));
                    return false;
                }

                this.setError(null);

                return true;
            };

            /** Changes the currency of the amount field to the same of the debit account */
            changeCurrencyAmount = (value) => {
                const {
                    data,
                    form: { setFieldValue, values },
                } = this.props;

                const account = data.options.find(({ id }) => id === value.value);
                const currency = this.getCurrency(account);
                const currentAmount = values.amount;
                const { quantity } = currentAmount;
                setFieldValue(AMOUNT_FIELD_NAME, { currency, quantity: IsEmpty(quantity) ? EMPTY_STR : quantity });
            };

            /** Checks if the currency of the account selected is an option in the amount selector,
             * if not, sets the first posible */
            getCurrency = (account) => {
                const { fieldList } = this.props;
                const field = fieldList.find(({ idField }) => idField === AMOUNT_FIELD_NAME);
                const { data } = field;
                const index = data.options.find(({ id }) => id === account.balance?.currency) || data.options[0];
                return index.id;
            };

            onBlur = () => {
                this.setTouched();
            };

            i18n = (type) => {
                const { props } = this;
                const map = props[`${type}Map`] || {};
                return map[props.lang];
            };

            componentProps() {
                const { mode, readOnly, field, value } = this.props;
                const { state } = this;
                return {
                    ...this.props,
                    name: field.name,
                    value: field.value || value,
                    label: this.i18n("label"),
                    placeholder: this.i18n("placeholder"),
                    optionalMessage: this.i18n("optionalMessage"),
                    editing: mode === MODE.EDIT && !readOnly,
                    i18n: this.i18n,
                    setValue: this.setValue,
                    setError: this.setError,
                    onBlur: this.onBlur,
                    setTouched: this.setTouched,
                    dependencyField: state.dependencyField,
                    dependencyValue: state.dependencyValue,
                };
            }

            formClass() {
                if (typeof options.formClass === "function") {
                    return options.formClass(this.props);
                }
                if (typeof options.formClass === "string") {
                    return options.formClass;
                }
                return EMPTY_STR;
            }

            customLabel() {
                if (typeof options.customLabel === "function") {
                    return options.customLabel(this.props);
                }
                return null;
            }

            isValidValue(value) {
                if (value == null) {
                    return false;
                }
                if (typeof options.isValidValue === "function") {
                    const props = this.componentProps();
                    return options.isValidValue(value, props);
                }
                return true;
            }

            isEmptyValue(value) {
                if (value == null) {
                    return true;
                }
                if (Array.isArray(value)) {
                    let retorno = true;
                    value.forEach((val) => {
                        if (val !== EMPTY_STR) {
                            retorno = false;
                        }
                    });
                    return retorno;
                }
                if (typeof options.isEmptyValue === "function") {
                    const props = this.componentProps();
                    return options.isEmptyValue(value, props);
                }
                return value == null || value === EMPTY_STR;
            }

            render() {
                const props = this.componentProps();

                if (options.pureRender) {
                    return <FormFieldComponent {...props} />;
                }

                const {
                    mode,
                    isRequired,
                    isFocused,
                    ticketOnly,
                    isUseAlternativeLabel,
                    renderAs,
                    subType,
                    field: { value },
                    tooltip,
                } = this.props;

                const formClass = this.formClass();

                if (
                    (mode === MODE.VIEW || mode === MODE.PREVIEW) &&
                    subType !== FIELD_SUBTYPE.EDIT_ONLY &&
                    !this.isEmptyValue(value)
                ) {
                    return (
                        <div className={`data-wrapper ${formClass}`}>
                            {this.customLabel(this.props) ||
                                (!isUseAlternativeLabel && (
                                    <FieldLabel labelText={this.i18n("label")} mode={MODE.VIEW} />
                                )) ||
                                (isUseAlternativeLabel && (
                                    <FieldLabel labelText={this.i18n("alternativeLabel")} mode={MODE.VIEW} />
                                ))}
                            <FormFieldComponent {...props} />
                        </div>
                    );
                }
                if (mode === MODE.EDIT && !ticketOnly) {
                    return (
                        <div
                            className={`form-group ${formClass} ${
                                this.hasError() && this.isTouched() ? "has-error" : ""
                            } ${isFocused ? "has-focus" : ""}`}>
                            {this.customLabel(props) ||
                                (!isUseAlternativeLabel && (
                                    <FieldLabel
                                        labelText={this.i18n("label")}
                                        optional={
                                            // imprimo el mensaje "Opcional" si isRequiredCondition es "FALSE"
                                            // o un condicional del estilo "value(name) != EMPTY_STR"
                                            isRequired ? EMPTY_STR : this.i18n("optionalMessage")
                                        }
                                    />
                                )) ||
                                (isUseAlternativeLabel && (
                                    <FieldLabel
                                        labelText={this.i18n("alternativeLabel")}
                                        optional={
                                            isRequired || renderAs === "check"
                                                ? EMPTY_STR
                                                : this.i18n("optionalMessage")
                                        }
                                    />
                                ))}

                            <FormFieldComponent {...props} />
                            {tooltip && (
                                <div className="tooltip">
                                    <span className="tooltip-text"> {tooltip}</span>
                                </div>
                            )}
                            {this.hasError() && this.isTouched() && <FieldError error={this.errorText()} />}
                            {this.i18n("help") && <FieldHelp text={this.i18n("help")} />}
                            {this.i18n("hint") && <FieldHint text={this.i18n("hint")} />}
                        </div>
                    );
                }
                return null;
            }
        };
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
