import React from "react";

import { push as Push, replace as Replace } from "connected-react-router";
import { withFormik as WithFormik, Form } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import {
    FINGERPRINT_ENROLLMENT,
    SOFT_TOKEN_BIOMETRIC,
    TRANSFER_WITH_BIOMETRIC,
    EVENT_BACKBUTTON,
    LOGIN_STEP1,
    SIGN_WITH_BIOMETRIC,
    ASSOCIATE_USER_BIOMETRIC,
    REGISTER_BIOMETRIC,
    FX_BIOMETRIC,
    LEVEL,
    SCOPE,
} from "~/constants";
import { SelectorsStore as SelectorsStoreBiometric } from "~/store/biometric";
import {
    SelectorsAction as SelectorsActionFingerPrint,
    SelectorsStore as SelectorsStoreFingerPrint,
} from "~/store/fingerprint";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as I18nUtil from "~/util/i18n";
import UtilsDevice from "~/util/device";
import { Types as TypesHtmlElement, Defaults as DefaultsHtmlElement } from "~/util/prop/html-element";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import Modal from "~/components/Modal";

export const NAME = "EnrollmentBiometric";

export const PROP = {
    types: {
        ...TypesHtmlElement,
        history: PropTypes.shape({
            goBack: PropTypes.func.isRequired,
        }).isRequired,
        pathFrom: PropTypes.string.isRequired,
    },
    defaults: {
        ...DefaultsHtmlElement,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        document.addEventListener("resume", this.handleResume, false);
        document.addEventListener(EVENT_BACKBUTTON, this.backButtonAction);
    }

    componentWillUnmount() {
        document.removeEventListener("resume", this.handleResume);
        document.removeEventListener(EVENT_BACKBUTTON, this.backButtonAction);
    }

    handleResume = () => {
        const { dispatch, pathFrom, history } = this.props;
        switch (pathFrom) {
            case TRANSFER_WITH_BIOMETRIC:
                dispatch(Replace("/form/fromBiometric")); // Si estoy transfiriendo, vuelvo a la pagina de transferencias
                break;
            case LOGIN_STEP1:
                dispatch(Replace("/login"));
                break;
            default:
                history.goBack();
        }
    };

    handleCancel = () => {
        const { dispatch, pathFrom, history } = this.props;

        switch (pathFrom) {
            case SOFT_TOKEN_BIOMETRIC:
            case LOGIN_STEP1:
            case ASSOCIATE_USER_BIOMETRIC:
            case REGISTER_BIOMETRIC:
                dispatch(
                    SelectorsActionNotification.showNotification({
                        message: I18nUtil.get("enrollment.biometric.cancel"),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.EXTERNAL_DASHBOARD],
                    }),
                );
                dispatch(Push("/"));
                break;
            case TRANSFER_WITH_BIOMETRIC:
            case SIGN_WITH_BIOMETRIC:
                dispatch(Push("/transfers")); // Si estoy transfiriendo, vuelvo a la pagina de transferencias
                dispatch(
                    SelectorsActionNotification.showNotification({
                        message: I18nUtil.get("enrollment.biometric.cancel"),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.TRANSFER],
                    }),
                );
                break;

            case FX_BIOMETRIC:
                dispatch(Push("/desktop"));
                dispatch(
                    SelectorsActionNotification.showNotification({
                        message: I18nUtil.get("enrollment.biometric.cancel"),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.DESKTOP],
                    }),
                );
                break;
            default:
                history.goBack();
        }
    };

    backButtonAction = () => {
        const { dispatch, pathFrom, history } = this.props;
        switch (pathFrom) {
            case SOFT_TOKEN_BIOMETRIC:
            case LOGIN_STEP1:
            case ASSOCIATE_USER_BIOMETRIC:
            case REGISTER_BIOMETRIC:
                dispatch(
                    SelectorsActionNotification.showNotification({
                        message: I18nUtil.get("enrollment.biometric.cancel"),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.EXTERNAL_DASHBOARD],
                    }),
                );
                dispatch(Push("/"));
                break;
            case TRANSFER_WITH_BIOMETRIC:
            case SIGN_WITH_BIOMETRIC:
                dispatch(Replace("/form/fromBiometric")); // Si estoy transfiriendo, vuelvo a la pagina de transferencias
                dispatch(
                    SelectorsActionNotification.showNotification({
                        message: I18nUtil.get("enrollment.biometric.cancel"),
                        level: LEVEL.TRANSFER,
                        scopes: [SCOPE.EXTERNAL_DASHBOARD],
                    }),
                );
                break;

            case FX_BIOMETRIC:
                dispatch(Push("/desktop"));
                dispatch(
                    SelectorsActionNotification.showNotification({
                        message: I18nUtil.get("enrollment.biometric.cancel"),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.DESKTOP],
                    }),
                );
                break;
            default:
                history.goBack();
        }
    };

    render() {
        const { isSubmitting } = this.props;
        return (
            <Modal show>
                <Form id={`modal-${NAME.toLowerCase()}`} className="login-form">
                    <div className="modal-text">
                        <p className="text-lead">
                            {I18nUtil.get("settings.fingerprintConfiguration.device.enrolledFingerprints.none")}
                        </p>
                        <p className="text-lead">{I18nUtil.get("settings.fingerprintConfiguration.warning")}</p>
                    </div>

                    <div className="modal-buttons">
                        <div className="login-form-field button-field">
                            <Button
                                bsStyle="primary"
                                label="settings.fingerprintConfiguration.device.enrolledFingerprints.enroll"
                                loading={isSubmitting}
                                type="submit"
                            />
                        </div>
                        <div className="login-form-field button-field">
                            <Button
                                bsStyle="secondary"
                                label="global.cancel"
                                loading={isSubmitting}
                                onClick={this.handleCancel}
                            />
                        </div>
                    </div>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = (store) => ({
    availability: SelectorsStoreFingerPrint.getAvailability(store),
    pathFrom: SelectorsStoreBiometric.pathFrom(store),
});

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        handleSubmit: (values, formikBag) => {
            if (UtilsDevice.isMobileNative()) {
                formikBag.props.dispatch(
                    SelectorsActionFingerPrint.enrollFingerprintOnDevice({ enrollmentFrom: FINGERPRINT_ENROLLMENT }),
                );
            }
        },
    }),
)(Component);
