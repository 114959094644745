import React from "react";

import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { DOT, EMPTY_STR, ENVIRONMENT_TYPE } from "~/constants";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import {
    SelectorsStore as SelectorsStoreInvestment,
    SelectorsAction as SelectorsActionInvestment,
} from "~/store/investment";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { getInteger } from "~/util/config";
import * as UtilsI18n from "~/util/i18n";
import UtilsLodash from "~/util/lodash";
import * as UtilsNumber from "~/util/number";

import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Token from "~/components/Token/Token";

import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        isSubmitting: PropTypes.bool,
    },
    defaults: {
        isSubmitting: false,
    },
};

const FORM_ID = "investment.termDeposit.form";

const formatNumber = (decimalSeparator, thousandSeparator, decimalScale, num) => {
    const numParts = num.toFixed(decimalScale).replace(DOT, decimalSeparator).split(decimalSeparator);
    let l = numParts[0].length - 3;

    while (l > 0) {
        numParts[0] = `${numParts[0].slice(0, l)}${thousandSeparator}${numParts[0].slice(l)}`;

        l -= 3;
    }

    return `${numParts[0]}${decimalSeparator}${numParts[1]}`;
};

export function Component(props) {
    const { isSubmitting, lang, maximumDecimals, minimumDecimals, termDeposit, transaction } = props;
    const { formData } = termDeposit;
    const { cost, currency, customTerm, debitAccount, term } = !UtilsLodash.isEmpty(formData)
        ? formData
        : transaction.data;
    const { decimalSeparator, thousandSeparator } = UtilsNumber.numberFormat(lang);
    const decimalPlaces = 0;
    const decimalScale = Math.max(Math.min(decimalPlaces, maximumDecimals), minimumDecimals);
    const accountList = termDeposit?.accountList.length > 0 ? termDeposit.accountList : transaction.data.accountList;

    const moreOptionsLabel = UtilsI18n.get(`${FORM_ID}.term.option.moreOptions`);
    const isMoreOptions = moreOptionsLabel === term;

    return (
        <Form id={Style.ID}>
            <section className="message">
                <I18n
                    id={`${FORM_ID}.step2.info`}
                    currency_one={UtilsI18n.get(`core.currency.label.${currency}`)}
                    currency_two={
                        currency === 9999
                            ? UtilsI18n.get(`core.currency.label.0`)
                            : UtilsI18n.get(`core.currency.label.${currency}`)
                    }
                    quantity={formatNumber(decimalSeparator, thousandSeparator, decimalScale, Number(cost))}
                    term={UtilsI18n.replaceParams(UtilsI18n.get(`${FORM_ID}.term.option`), {
                        quantity: isMoreOptions ? customTerm : term,
                    })}
                    account={accountList.find((account) => account.idProduct === debitAccount).productAlias}
                />
            </section>
            <Token labelButton="global.send" isSubmitting={isSubmitting} />
        </Form>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
        lang: SelectorsStoreI18n.getLang(store),
        maximumDecimals: getInteger("defaultDecimal.maximum"),
        minimumDecimals: getInteger("defaultDecimal.minimum"),
        termDeposit: SelectorsStoreInvestment.getTermDeposit(store),
        transaction: SelectorsStoreInvestment.getTransaction(store),
        user: SelectorsStoreSession.getUser(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithRouter,
    WithFormik({
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        validationSchema: (props) => {
            const { user } = props;

            return Yup.object().shape({
                otp: user.needsBiometric
                    ? Yup.string().nullable()
                    : Yup.string().required(UtilsI18n.get("form.credential.otp.required")),
            });
        },
        handleSubmit: (data, formikBag) => {
            const {
                activeEnvironment,
                dispatch,
                history,
                termDeposit: { accountList, formData },
                transaction: { idTransaction },
            } = formikBag.props;
            const { otp } = data;

            const isFromTransactionsHistory = history.location.pathname.includes("/ticket");

            if (isFromTransactionsHistory) {
                dispatch(
                    SelectorsActionInvestment.signTransaction({
                        credentials: { otp },
                        formikBag,
                        idActivity: `investment.termDeposit${
                            activeEnvironment.type === ENVIRONMENT_TYPE.CORPORATE ? "CMB" : EMPTY_STR
                        }.send`,
                        idForm: null,
                        idTransaction,
                    }),
                );
            } else {
                const debitProductAlias = accountList
                    .find((account) => account.idProduct === formData.debitAccount)
                    .productAlias.split("(");

                formData.debitProductLabel = debitProductAlias[0].trim();
                formData.accountList = accountList;

                if (formData.creditAccount) {
                    const creditProductAlias = accountList
                        .find((account) => account.idProduct === formData.creditAccount)
                        .productAlias.split("(");

                    formData.creditProductLabel = creditProductAlias[0].trim();
                }

                dispatch(SelectorsActionInvestment.termDepositSendRequest({ formData, formikBag, otp }));
            }
        },
    }),
)(Component);
