import React from "react";

import PropTypes from "prop-types";

import { Types as TypesHtmlElement, Defaults as DefaultsHtmlElement } from "~/util/prop/html-element";

import Button from "~/components/Button";
import I18n from "~/components/I18n";
import Modal from "~/components/Modal";

export const NAME = "UserActionConfirmation";

export const PROP = {
    types: {
        show: PropTypes.bool,
        handleConfirmAction: PropTypes.func,
        handleCancelAction: PropTypes.func,
        messageKey: PropTypes.string.isRequired,
        ...TypesHtmlElement,
    },
    defaults: {
        show: false,
        handleConfirmAction: null,
        handleCancelAction: null,
        ...DefaultsHtmlElement,
    },
};

export function Component(props) {
    const { show, handleConfirmAction, handleCancelAction, messageKey } = props;
    return (
        <Modal show={show}>
            <div className="modal-text">
                <I18n id={messageKey} />
            </div>
            <div className="modal-buttons">
                <Button className="btn-primary" label="global.accept" onClick={handleConfirmAction} />
                <Button label="global.cancel" onClick={handleCancelAction} />
            </div>
        </Modal>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
