import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    searchByIsin: (props) => API.executeWithAccessToken(PATH.SEARCH_BY_ISIN, props),

    /* BUY BONDS */
    buyBondsPreRequest: (props) => API.executeWithAccessToken(PATH.BUY_BONDS_PRE_REQUEST, props),
    buyBondsPreviewRequest: (props) => API.executeWithAccessToken(PATH.BUY_BONDS_PREVIEW_REQUEST, props),
    buyBondsRequest: (props) => API.executeWithAccessToken(PATH.BUY_BONDS_REQUEST, props),

    /* SELL BONDS */
    sellBondsPreRequest: (props) => API.executeWithAccessToken(PATH.SELL_BONDS_PRE_REQUEST, props),
    sellBondsPreviewRequest: (props) => API.executeWithAccessToken(PATH.SELL_BONDS_PREVIEW_REQUEST, props),
    sellBondsRequest: (props) => API.executeWithAccessToken(PATH.SELL_BONDS_REQUEST, props),

    getPaper: (props) => API.executeWithAccessToken(PATH.GET_PAPER, props),
};

export const SelectorsAction = {
    /* SEARCH ISIN */
    searchByIsin: (props) => FactoryAction(TYPE.SEARCH_BY_ISIN_REQUEST, props),
    searchByIsinFailure: () => FactoryAction(TYPE.SEARCH_BY_ISIN_FAILURE),
    searchByIsinSuccess: (props) => FactoryAction(TYPE.SEARCH_BY_ISIN_SUCCESS, props),

    /* BUY BONDS */
    buyBondsPre: (props) => FactoryAction(TYPE.BUY_BONDS_PRE_REQUEST, props),
    buyBondsPreSuccess: (props) => FactoryAction(TYPE.BUY_BONDS_PRE_SUCCESS, props),
    buyBondsPreFailure: () => FactoryAction(TYPE.BUY_BONDS_PRE_FAILURE),
    buyBondsPreview: (props) => FactoryAction(TYPE.BUY_BONDS_PREVIEW_REQUEST, props),
    buyBondsPreviewSuccess: (props) => FactoryAction(TYPE.BUY_BONDS_PREVIEW_SUCCESS, props),
    buyBondsPreviewFailure: () => FactoryAction(TYPE.BUY_BONDS_PREVIEW_FAILURE),
    buyBondsSendForm: (props) => FactoryAction(TYPE.BUY_BONDS_SEND_FORM, props),
    buyBondsSendFormSuccess: (props) => FactoryAction(TYPE.BUY_BONDS_SEND_FORM_SUCCESS, props),
    buyBondsSendFormFailure: () => FactoryAction(TYPE.BUY_BONDS_SEND_FORM_FAILURE),

    /* SELL BONDS */
    sellBondsPre: (props) => FactoryAction(TYPE.SELL_BONDS_PRE_REQUEST, props),
    sellBondsPreSuccess: (props) => FactoryAction(TYPE.SELL_BONDS_PRE_SUCCESS, props),
    sellBondsPreFailure: () => FactoryAction(TYPE.SELL_BONDS_PRE_FAILURE),
    sellBondsPreview: (props) => FactoryAction(TYPE.SELL_BONDS_PREVIEW_REQUEST, props),
    sellBondsPreviewSuccess: (props) => FactoryAction(TYPE.SELL_BONDS_PREVIEW_SUCCESS, props),
    sellBondsPreviewFailure: () => FactoryAction(TYPE.SELL_BONDS_PREVIEW_FAILURE),
    sellBondsSendForm: (props) => FactoryAction(TYPE.SELL_BONDS_SEND_FORM, props),
    sellBondsSendFormSuccess: (props) => FactoryAction(TYPE.SELL_BONDS_SEND_FORM_SUCCESS, props),
    sellBondsSendFormFailure: () => FactoryAction(TYPE.SELL_BONDS_SEND_FORM_FAILURE),

    cancelTransaction: (props) => FactoryAction(TYPE.CANCEL_TRANSACTION_REQUEST, props),
    clearForm: () => FactoryAction(TYPE.CLEAR_FORM),
    clearSearchResults: () => FactoryAction(TYPE.CLEAR_SEARCH_RESULTS),
    getPaper: (props) => FactoryAction(TYPE.GET_PAPER, props),
    goStepBack: () => FactoryAction(TYPE.GO_STEP_BACK),
    handleIdPaperSelected: (props) => FactoryAction(TYPE.PAPER_ID_SELECTED, props),
    readTransactionRequest: (props) => FactoryAction(TYPE.READ_TRANSACTION_REQUEST, props),
    readTransactionSuccess: (props) => FactoryAction(TYPE.READ_TRANSACTION_SUCCESS, props),
    restartFlow: () => FactoryAction(TYPE.RESTART_FLOW),
    signTransaction: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_REQUEST, props),
    signTransactionPreview: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_PREVIEW, props),
};

export const SelectorsStore = {
    getBonds: (store) => store[NAME].bonds,
    getCreditAccountsList: (store) => store[NAME].creditAccountList,
    getCurrencyList: (store) => store[NAME].currencyList,
    getDebitAccountsList: (store) => store[NAME].debitAccountsList,
    getExpirationYearsAhead: (store) => store[NAME].expirationYearsAhead,
    getFetching: (store) => store[NAME].fetching,
    getFormData: (store) => store[NAME].formData,
    getIdPaper: (store) => store[NAME].idPaper,
    getInfoData: (store) => store[NAME].infoData,
    getMode: (store) => store[NAME].mode,
    getPaper: (store) => store[NAME].paper,
    getPapersList: (store) => store[NAME].papersList,
    getPrevMode: (store) => store[NAME].prevMode,
    getPreviewData: (store) => store[NAME].previewData,
    getProcessingSale: (store) => store[NAME].processingSale,
    getTimezoneOffset: (store) => store[NAME].timezoneOffset,
    getTransaction: (store) => store[NAME].transaction,
};
