import React from "react";

import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

import { EMPTY_STR, SPACE_STR } from "~/constants";
import { generateId as GenerateId } from "~/util/general";
import { FromKeys } from "~/util/prop";
import { Types as TypesHtmlElement, Defaults as DefaultsHtmlElement } from "~/util/prop/html-element";

import I18n from "~/components/I18n";
import Image from "~/components/Image";

export const NAME = "Button";

const members = {
    // TODO: These definitiions should be in their own file (~/util/prop/react-bootstrap)
    typesBootstrap: {
        ...TypesHtmlElement,
        /** Style variants */
        bsStyle: PropTypes.oneOf(["default", "primary", "success", "info", "warning", "danger", "link"]),
        /** Size variants  */
        bsSize: PropTypes.oneOf(["large", "small", "xsmall"]),
        /** The html tag to be appended to the wfinal element */
        type: PropTypes.oneOf(["button", "reset", "submit"]),
        /** A link where to reroute when clicked (it will convert the button into an "a" element) */
        href: PropTypes.string,
        /** Whether the button should behave like a block element */
        block: PropTypes.bool,
        /** Whether the button is currently active or not */
        active: PropTypes.bool,
        /** Whether the button is disabled for clicking or not */
        disabled: PropTypes.bool,
        /** A function to call once the button has been clicked */
        onClick: PropTypes.func,
    },
    defaultsBootstrap: {
        ...DefaultsHtmlElement,
        type: "button",
        block: true,
        bsStyle: null,
        bsSize: null,
        href: null,
        active: null,
        disabled: null,
        onClick: null,
    },
    typesContent: {
        label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
        image: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    },
    defaultsContent: {
        image: null,
    },
};

export const PROP = {
    types: {
        ...members.typesBootstrap,
        ...members.typesContent,
        loading: PropTypes.bool,
        loadingLabel: PropTypes.string,
    },
    defaults: { ...members.defaultsBootstrap, ...members.defaultsContent, loading: null, loadingLabel: null },
};

/**
 * Button Component
 *
 * A wrapper for Bootstrap's Button Component with a nicer loader.
 * @param {PROP.types} props
 */
export function Component(props) {
    const { loading, loadingLabel, image, label, title } = props;
    const propsBootstrap = FromKeys(members.typesBootstrap, props);
    const propsTarget = {
        ...propsBootstrap,
        id: propsBootstrap.id || GenerateId(),
        disabled: loading || propsBootstrap.disabled,
        className: [loading ? "is-loading" : EMPTY_STR, propsBootstrap.className].join(SPACE_STR),
        href: !propsBootstrap.onClick ? propsBootstrap.href : null,
        onClick: !propsBootstrap.href ? propsBootstrap.onClick : null,
        title,
    };
    const loadingLabelKey = loadingLabel || "global.loading";
    return (
        <Button {...propsTarget}>
            {loading ? (
                <React.Fragment>
                    <span className="btn-loading-indicator">
                        <span />
                        <span />
                        <span />
                    </span>
                    <span className="btn-loading-text">
                        <I18n id={loadingLabelKey} />
                    </span>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {image && (React.isValidElement(image) ? image : <Image src={image} className="svg-icon" />)}
                    {React.isValidElement(label) ? label : <I18n id={label} />}
                </React.Fragment>
            )}
        </Button>
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
