import React from "react";

import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { CDP_CURRENCY, COUNTRY_TO_CURRENCY, EMPTY_STR } from "~/constants";
import { STATUS } from "~/constants/transaction";
import {
    SelectorsStore as SelectorsStoreFactoring,
    SelectorsAction as SelectorsActionFactoring,
    PROP as ADVANCE_PAYMENTS_PROPS,
} from "~/store/factoring/advancePayments";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as UtilsI18n from "~/util/i18n";

import DataNumber from "~/components/DataNumber";
import HighOrder from "~/components/HighOrder";
import Token from "~/components/Token/Token";

import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        currentDiscountableUSD: PropTypes.shape(ADVANCE_PAYMENTS_PROPS.members.totalDiscountableOrders).isRequired,
        currentDiscountableUYU: PropTypes.shape(ADVANCE_PAYMENTS_PROPS.members.totalDiscountableOrders).isRequired,
        isSubmitting: PropTypes.bool,
    },
    defaults: {
        isSubmitting: false,
    },
};

export function Component(props) {
    const { isSubmitting, currentDiscountableUSD, currentDiscountableUYU } = props;
    const renderUYU = currentDiscountableUYU.total > 0;
    const renderUSD = currentDiscountableUSD.total > 0;
    const renderBoth = renderUYU && renderUSD;

    return (
        <section id={Style.ID}>
            <section className="message">
                <span>{UtilsI18n.get("client.factoring.advancePaymentOrders.transaction.step2.message1")}</span>
                {renderUYU &&
                    renderQuantities(
                        COUNTRY_TO_CURRENCY.URY,
                        currentDiscountableUYU.amount,
                        currentDiscountableUYU.total,
                    )}
                {renderBoth && (
                    <span>{UtilsI18n.get("client.factoring.advancePaymentOrders.transaction.step2.message.and")}</span>
                )}
                {renderUSD &&
                    renderQuantities(
                        COUNTRY_TO_CURRENCY.USA,
                        currentDiscountableUSD.amount,
                        currentDiscountableUSD.total,
                    )}
            </section>
            <Form>
                <Token labelButton="global.send" isSubmitting={isSubmitting} />
            </Form>
        </section>
    );

    function renderQuantities(currency, amount, total) {
        return (
            <React.Fragment>
                <span className="number-of-orders">
                    {total}{" "}
                    {total === 1
                        ? UtilsI18n.get("client.factoring.advancePaymentOrders.transaction.step2.message.singular")
                        : UtilsI18n.get("client.factoring.advancePaymentOrders.transaction.step2.message.plural")}
                </span>
                <span>
                    {UtilsI18n.get(`client.factoring.advancePaymentOrders.transaction.step2.message2.${currency}`)}
                </span>
                <DataNumber value={amount} prefix={CDP_CURRENCY[currency]} />
            </React.Fragment>
        );
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        currentDiscountableUYU: SelectorsStoreFactoring.getCurrentDiscountableUYU(store),
        currentDiscountableUSD: SelectorsStoreFactoring.getCurrentDiscountableUSD(store),
        ordersToDiscountList: SelectorsStoreFactoring.getOrdersToDiscountList(store),
        transaction: SelectorsStoreFactoring.getTransaction(store),
        user: SelectorsStoreSession.getUser(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithRouter,
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        validationSchema: (props) => {
            const { user } = props;

            return Yup.object().shape({
                otp: user.needsBiometric
                    ? Yup.string().nullable()
                    : Yup.string().required(UtilsI18n.get("form.credential.otp.required")),
            });
        },
        handleSubmit: ({ otp }, formikBag) => {
            const {
                dispatch,
                history,
                ordersToDiscountList,
                transaction: { idTransaction, idActivity, idTransactionStatus },
            } = formikBag.props;

            const isFromTransactionsHistory = history.location.pathname.includes("/ticket");

            if (isFromTransactionsHistory && idTransactionStatus !== STATUS.DRAFT) {
                dispatch(
                    SelectorsActionFactoring.signTransaction({
                        idForm: null,
                        idActivity,
                        idTransaction,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            } else {
                dispatch(
                    SelectorsActionFactoring.advancePaymentsTransactionSendRequest({
                        ordersToDiscountList,
                        otp,
                        formikBag,
                    }),
                );
            }
        },
    }),
)(Component);
