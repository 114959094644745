import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import FieldLabel from "~/pages/_components/fields/FieldLabel";

export const NAME = "StepperField";

export const PROP = {
    types: {
        field: PropTypes.shape({
            name: PropTypes.string,
        }).isRequired,
        handleChange: PropTypes.func,
        hideLabel: PropTypes.bool,
        idForm: PropTypes.string.isRequired,
        max: PropTypes.string.isRequired,
        min: PropTypes.string.isRequired,
    },
    defaults: {
        handleChange: null,
        hideLabel: false,
    },
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    handleChange = (event) => {
        const { field, handleChange, max, min } = this.props;
        const { value } = event.target;
        if (value >= min && value <= max && value.length <= max.length) {
            field.onChange(event);

            if (handleChange) {
                handleChange(event);
            }
        }
    };

    render() {
        const { className, field, hideLabel, idForm, max, min } = this.props;

        return (
            <div className={classNames("form-group", className)}>
                {!hideLabel && <FieldLabel labelKey={`${idForm}.${field.name}.label`} />}
                <div className="input-group signature-input">
                    <input
                        {...field}
                        className="form-control"
                        name={field.name}
                        max={max}
                        min={min}
                        onChange={this.handleChange}
                        type="number"
                    />
                </div>
            </div>
        );
    }
}

export default Component;
