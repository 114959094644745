/* global  sessionStorage */
import { push as Push } from "connected-react-router";
import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import {
    FINGERPRINT_AUTH_TOKEN,
    LEVEL,
    SCOPE,
    FINGERPRINT_CONFIGURATION,
    ERROR_MOBILE_IOS_128,
    ERROR_MOBILE_IOS_CANCEL,
    ERROR_MOBILE_IOS_CANCEL_BY_SYSTEM,
    ERROR_MOBILE_IOS_LOCK_OUT,
    ERROR_MOBILE_IOS_FAILED,
    ERROR_MOBILE_IOS_NOT_AVAILABLE,
    BIOMETRIC_TYPE,
    BIOMETRIC_TYPES,
} from "~/constants";
import Store from "~/store";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsMiddleware as SelectorsMiddlewareSettings } from "~/store/settings";
import DeviceUtils, { DEVICE_MOBILE } from "~/util/device";
import * as FingerprintUtils from "~/util/fingerprint";
import * as I18nUtils from "~/util/i18n";
import Logger from "~/util/logger";
import * as UtilsSecureStorage from "~/util/secureStorage";

import { TYPE } from "./_consts";
import { SelectorsAction } from "./_selectors";

const sagas = [
    TakeLatest(TYPE.FINGERPRINT_AVAILABILITY, isAvailable),
    TakeLatest(TYPE.FINGERPRINT_CONFIGURATION_PRE, fingerprintConfigurationPre),
    TakeLatest(TYPE.FINGERPRINT_VERIFICATION_REQUEST, verify),
    TakeLatest(TYPE.FINGERPRINT_DELETE_DEVICE, deleteDevice),
    TakeLatest(TYPE.ENROLL_FINGERPRINT_ON_DEVICE, enrollFingerprintOnDevice),
    TakeLatest(TYPE.CLEAN_UP, handleFingerprintCleanUp),
];

export default sagas;

function* isAvailable() {
    if (DeviceUtils.isMobileNative()) {
        try {
            const availability = yield Call(FingerprintUtils.isAvailable);

            yield Put(SelectorsAction.fingerPrintAvailabilitySuccess({ availability }));
        } catch (error) {
            Logger.error(error);
        }
    }
}

function* fingerprintConfigurationPre() {
    const response = yield Call(SelectorsMiddlewareSettings.listFingerprintConfiguredUserDevices);

    if (response && response.status === 200) {
        const {
            data: { isThisDeviceWithFingerPrint, devices },
        } = response.data;
        yield Put(
            SelectorsAction.fingerPrintConfigurationPreSuccess({
                isDeviceWithFingerprint: isThisDeviceWithFingerPrint,
                fingerprintConfiguredUserDevices: devices,
            }),
        );
    }
}

function* verify() {
    try {
        const message = I18nUtils.get("settings.fingerprintConfiguration.dialog.message");

        let result = yield Call(FingerprintUtils.verify, message);

        if (result && result.withFingerprint) {
            const response = yield Call(SelectorsMiddlewareSettings.saveFingerprint, {
                deviceId: DeviceUtils.id(),
                deviceModel: DeviceUtils.model(),
            });

            const msgKeyPre = "settings.fingerprintConfiguration.register";

            if (response && response.status === 200) {
                result = yield Call(
                    UtilsSecureStorage.set,
                    FINGERPRINT_AUTH_TOKEN,
                    SelectorsStoreSession.getAccessToken(Store.getState()),
                );
                yield Call(UtilsSecureStorage.set, BIOMETRIC_TYPE, BIOMETRIC_TYPES.fingerPrint);

                if (result) {
                    sessionStorage.setItem("widget.biometric.hide", true);
                    const confirmationMessage = I18nUtils.get(`${msgKeyPre}.success`);
                    yield Put(Push("/settings"));
                    yield Put(
                        SelectorsActionNotification.showNotification({
                            message: confirmationMessage,
                            level: LEVEL.SUCCESS,
                            scopes: [SCOPE.DESKTOP, SCOPE.SETTINGS],
                        }),
                    );
                }
            } else {
                const errorMessage = I18nUtils.get(`${msgKeyPre}.error`);
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: errorMessage,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.SETTINGS],
                    }),
                );
            }
        }
    } catch (error) {
        const platform = DeviceUtils.platform();
        const errorType = FingerprintUtils.getError(error);
        const msgKeyPre = "settings.fingerprintConfiguration.dialog";

        const msgKey1 = `${msgKeyPre}.error_1`;
        const msgKey2 = `${msgKeyPre}.error_2`;

        const mess = `${I18nUtils.get(msgKey1)}\n${I18nUtils.get(msgKey2)}`;
        switch (platform) {
            case DEVICE_MOBILE.ANDROID:
                if (errorType === FingerprintUtils.fingerprintErrors.FINGERPRINT_ERROR) {
                    yield Put(
                        SelectorsActionNotification.showNotification({
                            message: mess,
                            level: LEVEL.ERROR,
                            scopes: [SCOPE.FINGER_PRINT_CONFIGURATION],
                        }),
                    );
                } else if (errorType !== FingerprintUtils.fingerprintErrors.FINGERPRINT_CANCELLED) {
                    yield Put(
                        SelectorsActionNotification.showNotification({
                            message: errorType,
                            level: LEVEL.ERROR,
                            scopes: [SCOPE.FINGER_PRINT_CONFIGURATION],
                        }),
                    );
                }
                break;
            case DEVICE_MOBILE.IOS:
                // -1 - Fingerprint scan failed more than 3 times
                // -2 or -128 - User tapped the 'Cancel' button
                // -3 - User tapped the 'Enter Passcode' or 'Enter Password' button
                // -4 - The scan was cancelled by the system (Home button for example)
                // -6 - TouchID is not Available
                // -8 - TouchID is locked out from too many tries

                switch (error.code) {
                    case ERROR_MOBILE_IOS_CANCEL:
                    case ERROR_MOBILE_IOS_128:
                    case ERROR_MOBILE_IOS_CANCEL_BY_SYSTEM:
                        break;
                    case ERROR_MOBILE_IOS_LOCK_OUT:
                        yield Put(
                            SelectorsActionNotification.showNotification({
                                message: I18nUtils.get("verify.biometric.error.lockout"),
                                level: LEVEL.ERROR,
                                scopes: [SCOPE.FINGER_PRINT_CONFIGURATION],
                            }),
                        );
                        break;
                    case ERROR_MOBILE_IOS_FAILED:
                        yield Put(
                            SelectorsActionNotification.showNotification({
                                message: mess,
                                level: LEVEL.ERROR,
                                scopes: [SCOPE.FINGER_PRINT_CONFIGURATION],
                            }),
                        );
                        break;
                    case ERROR_MOBILE_IOS_NOT_AVAILABLE:
                        yield Put(
                            SelectorsActionNotification.showNotification({
                                message: I18nUtils.get("biometric.error.not.available"),
                                level: LEVEL.ERROR,
                                scopes: [SCOPE.FINGER_PRINT_CONFIGURATION],
                            }),
                        );
                        break;
                    default:
                        yield Put(
                            SelectorsActionNotification.showNotification({
                                message: error.localizedDescription,
                                level: LEVEL.ERROR,
                                scopes: [SCOPE.FINGER_PRINT_CONFIGURATION],
                            }),
                        );
                        break;
                }
                break;
            default:
                break;
        }
    }
}

function* deleteDevice(props) {
    const response = yield Call(SelectorsMiddlewareSettings.deleteFingerprint, props);

    yield Put(Push("/settings"));

    if (response && response.status === 200) {
        yield Put(SelectorsAction.cleanUp());

        const confirmationMessage = I18nUtils.get("settings.fingerprintConfiguration.device.remove.success");
        yield Put(
            SelectorsActionNotification.showNotification({
                message: confirmationMessage,
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.SETTINGS],
            }),
        );
    } else {
        const errorMessage = I18nUtils.get("settings.fingerprintConfiguration.device.remove.error");
        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.SETTINGS],
            }),
        );
    }
}

function* enrollFingerprintOnDevice({ enrollmentFrom }) {
    const response = yield Call(FingerprintUtils.enrollOnDevice);
    if (!response) {
        // TODO: sg revisar el mensaje en una parte menciona Techbank
        const errorMessage = I18nUtils.get(
            "settings.fingerprintConfiguration.device.enrolledFingerprints.enroll.error",
        );
        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.FINGER_PRINT_CONFIGURATION],
            }),
        );
    } else if (enrollmentFrom === FINGERPRINT_CONFIGURATION) {
        yield Put(Push("/settings/fingerprintConfiguration"));
    }
}

function* handleFingerprintCleanUp() {
    if (DeviceUtils.isMobileNative()) {
        try {
            yield Call(UtilsSecureStorage.remove, FINGERPRINT_AUTH_TOKEN);
        } catch (error) {
            /* do something with the error */
        }
    }
}
