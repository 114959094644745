import React from "react";

import { Field } from "formik";

import { EMPTY_STR, ZERO } from "~/constants";
import * as i18nUtils from "~/util/i18n";

import Box from "~/components/Box";
import GridLayout from "~/components/GridLayout";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";

import Style from "./AddDocument.rules.scss";

export const NAME = "AddDocument";

export const PROP = {
    types: {},
    defaults: {},
};

export function Component({ editDocument, errors, formId, setFieldValue, showDocumentModal, touched, values }) {
    const hasDocumentsError = errors.documents && touched.documents;
    const hasDocuments = values.documents && values.documents.length > 0;

    const removeDocument = (event, doc) => {
        event.stopPropagation();

        const documents = [...values.documents];

        documents.splice(doc, 1);

        setFieldValue("documents", documents);
    };

    const doc = values.documents;

    return (
        <div id={Style.ID}>
            <FieldLabel labelKey={`${formId}.documents`} />
            {!hasDocuments && (
                <span className={hasDocumentsError ? "row-data documents-error" : "row-data"}>
                    {i18nUtils.get(`${formId}.documents.description`)}
                </span>
            )}
            <Field idForm={formId} name="documents" style={{ display: "none" }} />
            {hasDocumentsError && !hasDocuments && (
                <React.Fragment>
                    <div className={`${hasDocumentsError ? "has-error" : EMPTY_STR}`}>
                        <FieldError error={errors.documents} />
                    </div>
                </React.Fragment>
            )}
            {hasDocuments && (
                <div className="documentsTable">
                    {values.documents.length > 0 && (
                        <GridLayout className={hasDocumentsError ? "documentsTableRowError" : "documentsTableRow"}>
                            <Box key="doc-header-document" className="web-only header">
                                {i18nUtils.get("comex.creditLetter.modal.documentTypes.table.header.document")}
                            </Box>
                            <Box key="doc-header-other" className="web-only header" />
                            <Box key="doc-header-original" className="web-only header">
                                {i18nUtils.get("comex.creditLetter.modal.documentTypes.table.header.original")}
                            </Box>
                            <Box key="doc-header-copies" className="web-only header">
                                {i18nUtils.get("comex.creditLetter.modal.documentTypes.table.header.copies")}
                            </Box>
                        </GridLayout>
                    )}
                    {doc.map((d, index) => (
                        <GridLayout
                            className={hasDocumentsError ? "documentsTableRowError" : "documentsTableRow"}
                            onClick={() => editDocument(d)}>
                            <Box key={`doc-row-${d.type}-document`} className="web-only row-data">
                                {i18nUtils.get(`comex.creditLetter.modal.documentTypes.${d.type}`)}
                            </Box>
                            <Box
                                key={`doc-row-${d.type}-description`}
                                className={`web-only row-data-description ${
                                    hasDocumentsError ? "red-color" : "black-color"
                                }`}>
                                {d.description && d.description.length > 14
                                    ? `${d.description.substr(0, 11)}...`
                                    : d.description}
                            </Box>
                            <Box key={`doc-row-${d.type}-original`} className="web-only row-data-cross">
                                {!d.originals ? ZERO : d.originals}
                            </Box>
                            <Box key={`doc-row-${d.type}-copies`} className="web-only row-data-cross">
                                {!d.copies ? ZERO : d.copies}
                            </Box>
                            <Box key={`doc-row-${d.type}-closed`} className="web-only row-data-cross">
                                <div
                                    className="closed clickable"
                                    onClick={(e) => {
                                        removeDocument(e, index);
                                    }}
                                    role="button">
                                    <Image className="rowCross" src="close.svg" />
                                </div>
                            </Box>
                        </GridLayout>
                    ))}
                    {hasDocumentsError && hasDocuments && (
                        <GridLayout
                            className={`documentsTableErrorMessage ${hasDocumentsError ? "has-error" : EMPTY_STR}`}>
                            <FieldError error={errors.documents} />
                        </GridLayout>
                    )}
                </div>
            )}
            <GridLayout className=" position-clickable">
                <FieldLabel labelKey="" />
                <Link
                    to="#"
                    onClick={(e) => {
                        e.preventDefault();
                        showDocumentModal(true);
                    }}>
                    <I18n id={`${formId}.documents.add`} />
                </Link>
            </GridLayout>
        </div>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
