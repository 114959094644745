import React from "react";

import { Grid, Row, Col } from "react-bootstrap";
import { connect as Connect } from "react-redux";

import { SelectorsStore as SelectorsStoreConnectedParties } from "~/store/connectedParties";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import I18n from "~/components/I18n";

import TransactionStatus from "~/pages/transactions/_components/TransactionStatus";

export const NAME = "ConnectedPartiesTicket";

export const PROP = {
    types: {
        ...TypesRedux,
    },
    defaults: {
        ...DefaultsRedux,
    },
};

export function Component(props) {
    const { transaction } = props;
    const { idTransactionStatus } = transaction;

    return (
        <div id="connectedPartiesTicket">
            <React.Fragment>
                <section className="container--layout align-items-center flex-grow">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <div className="media-object ">
                                    <figure>
                                        <TransactionStatus idTransactionStatus={idTransactionStatus} />
                                    </figure>
                                    <div className="media-object--text">
                                        <p className="text-lead">
                                            <I18n id={`forms.transaction.ticket.status.${idTransactionStatus}`} />
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </React.Fragment>
        </div>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => ({
    transaction: SelectorsStoreConnectedParties.getTransaction(store),
});

export default Connect(mapStateToProps)(Component);
