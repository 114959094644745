import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Form, withFormik as WithFormik, Field } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import { EMPTY_STR, MIDDLE_DASH, SCOPE } from "~/constants";
import Container from "~/containers/Internal/Administration/Simple";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsStore, SelectorsAction } from "~/store/settings";
import * as UtilsI18n from "~/util/i18n";
import { GetMobileCountryOptions } from "~/util/phone";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";
import Token from "~/components/Token/Token";
import SecuritySealField from "~/pages/_components/fields/SecuritySealField";

import SecuritySealList from "../modal/SecuritySealList";
import FormPersonalData from "./FormPersonalData";
import Style from "./PersonalInformationForm.rules.scss";

const FORM_ID = "change.PersonalData";

export const NAME = "PersonalInformationForm";

export const PROP = {
    types: {
        ...TypesRedux,
        errors: PropTypes.object.isRequired,
        fetching: PropTypes.bool,
        isSubmitting: PropTypes.bool.isRequired,
        securitySeal: PropTypes.shape({
            id: PropTypes.string,
            image: PropTypes.string,
        }).isRequired,
        setFieldValue: PropTypes.func.isRequired,
        values: PropTypes.object.isRequired,
    },
    defaults: {
        ...DefaultsRedux,
        fetching: false,
    },
};
export function Component({
    dispatch,
    environmentType,
    errors,
    fetching,
    isSubmitting,
    personalDataForceUpdate,
    securitySeal,
    setFieldValue,
    values,
    ...rest
}) {
    const [showSeal, setShowSeal] = React.useState(false);

    React.useLayoutEffect(() => {
        dispatch(SelectorsAction.changePersonalData({ environmentType }));
        return () => {};
    }, [dispatch, environmentType]);

    const handleBack = React.useCallback(() => {
        dispatch(GoBack());
    }, [dispatch]);

    const handleShowSealSelector = () => {
        setShowSeal(true);
    };

    const handleHideSealSelector = () => {
        setShowSeal(false);
    };

    return (
        <Container
            name={NAME}
            head-onBack={!personalDataForceUpdate && handleBack}
            head-title="change.personal.data.title"
            scopeToShowNotification={SCOPE.PERSONAL_INFORMATION}
            wait={fetching}>
            <SecuritySealList showSealModal={showSeal} onClick={handleHideSealSelector} />
            <div id={Style.ID}>
                <Form>
                    <div className="form--personal_information form--personal_information--seal">
                        <div className="form--personal_information--title">
                            <I18n id="title.form.seal" />
                        </div>
                        <div className="container--data containerinformation--image">
                            <Field
                                idForm={FORM_ID}
                                name="securitySeal"
                                component={SecuritySealField}
                                imageSrc={securitySeal?.image}
                                onClick={handleShowSealSelector}
                                hidelabel
                            />
                        </div>
                    </div>
                    <div className=" form--personal_information form--personal_information--data personal_info-cmb">
                        <div className="form--personal_information--title">
                            <I18n id="title.form.telephone" />
                        </div>
                        <FormPersonalData
                            values={values}
                            errors={errors}
                            setFieldValue={setFieldValue}
                            isCorporate
                            {...rest}
                        />
                    </div>

                    <div className="form--personal_information form--personal_information--bottom personal_info-cmb token">
                        <div className="form--personal_information--title">
                            <I18n id="title.form.token" />
                        </div>
                        <Token labelButton="global.save" isSubmitting={isSubmitting} {...rest}>
                            <Button
                                type="button"
                                size="md"
                                variant="secondary"
                                disabled={isSubmitting}
                                className="form--personal_information--bottom--cancel"
                                onClick={handleBack}>
                                <I18n id="global.cancel" />
                            </Button>
                        </Token>
                    </div>
                </Form>
            </div>
        </Container>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Compose(
    Connect((store) => ({
        environmentType: SelectorsStoreSession.getActiveEnvironment(store).type || {},
        fetching: SelectorsStore.getFetching(store),
        personalDataForceUpdate: SelectorsStoreSession.getPersonalDataForceUpdate(store),
        personalData: SelectorsStore.getPersonalData(store),
        securitySeal: SelectorsStore.getSecuritySeal(store),
        user: SelectorsStoreSession.getUser(store),
    })),
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: ({ personalData }) => {
            const mobilePhone = personalData?.mobilePhone?.split(MIDDLE_DASH);
            let prefix = EMPTY_STR;
            let number = EMPTY_STR;
            let countryPhone = [];

            if (mobilePhone) {
                [prefix, number] = mobilePhone;
                countryPhone = GetMobileCountryOptions();
                countryPhone = countryPhone.filter((element) => element?.value === prefix);
            }

            return {
                mobilePhone: {
                    country: countryPhone[0]?.country,
                    mobilePhone: number || personalData?.mobilePhone,
                    prefix,
                },
                otp: EMPTY_STR,
                phone: personalData?.phone || EMPTY_STR,
            };
        },
        handleSubmit: (data, formikBag) => {
            const { dispatch, environmentType, securitySeal } = formikBag.props;

            formikBag.setSubmitting(false);
            const { mobilePhone, otp } = data;
            const infoUserModify = {
                idSeal: securitySeal?.id,
                mobilePhone: `${mobilePhone?.prefix}-${mobilePhone?.mobilePhone}`,
                prefix: mobilePhone?.prefix,
            };

            dispatch(SelectorsAction.modifyUserRequest({ infoUserModify, environmentType, _otp: otp, formikBag }));
        },
        validationSchema: (props) => {
            return Yup.lazy(() => {
                const { user } = props;
                return Yup.object().shape({
                    otp: user.needsBiometric
                        ? Yup.string().nullable()
                        : Yup.string()
                              .trim()
                              .required(UtilsI18n.get("form.credential.otp.required"))
                              .min(6, UtilsI18n.get("returnCode.COR027W")),
                    mobilePhone: Yup.object().shape({
                        prefix: Yup.string().required(UtilsI18n.get(`${FORM_ID}.mobilePhone.mustBeAPhoneNumber`)),
                        mobilePhone: Yup.string().required(UtilsI18n.get("client.userconfiguration.empty.mobilePhone")),
                        country: Yup.string(),
                    }),
                });
            });
        },
    }),
)(Component);
