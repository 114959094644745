import React from "react";

import { push as Push } from "connected-react-router";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { connect as Connect } from "react-redux";

import { TIMER_MINUTES, LEVEL, SCOPE } from "~/constants";
import { SelectorsStore as SelectorsStoreForm } from "~/store/form";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import {
    SelectorsAction as SelectorsActionTransactions,
    SelectorsStore as SelectorsStoreTransactions,
} from "~/store/transactions";
import * as Config from "~/util/config";
import * as UtilI18n from "~/util/i18n";

import Button from "~/components/Button";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Modal from "~/components/Modal";
import Container from "~/pages/_components/Container";

export const NAME = "DelayedModal";

export const PROP = {
    types: {
        isDelayed: PropTypes.bool.isRequired,
        dispatch: PropTypes.func.isRequired,
        transaction: PropTypes.func.isRequired,
    },
    defaults: {},
};

export class Component extends React.Component {
    static displayName = NAME;

    static SECONDS_TO_SHOW;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {
        seconds: 10,
    };

    interval = null;

    componentDidUpdate() {
        const { isDelayed, waitingForReponse } = this.props;
        if (!this.interval && isDelayed && waitingForReponse) {
            this.interval = setInterval(this.increaseSeconds, 1000);
            this.SECONDS_TO_SHOW = Config.get("client.transactions.delayed.pageTimeout.redirect");
            this.setState({ seconds: 10 });
        }
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    closeModal = () => {
        const { dispatch, waitingForReponse } = this.props;
        dispatch(SelectorsActionTransactions.removeDelayedTransactionModal({ isDelayed: false }));
        clearInterval(this.interval);
        this.interval = null;

        if (waitingForReponse) {
            dispatch(
                Push(`/transactions/list`, {
                    selectedFilter: "last",
                    selectAllPendingChecked: false,
                }),
            );
            dispatch(
                SelectorsActionNotification.showNotification({
                    message: UtilI18n.get("transactionDelay.notification"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.TRANSACTIONS],
                    autoDismiss: 10,
                }),
            );
        }
    };

    increaseSeconds = () => {
        const { seconds } = this.state;
        const { waitingForReponse, isDelayed } = this.props;

        if (seconds < this.SECONDS_TO_SHOW && isDelayed && waitingForReponse) {
            this.setState({ seconds: seconds + 1 });
        } else {
            this.closeModal();
        }
    };

    render() {
        const { isDelayed } = this.props;
        const { seconds } = this.state;

        return (
            <Modal show={isDelayed}>
                <Container className="container--layout align-items-center flex-grow" gridClassName="container-fluid">
                    <Col sm={12} md={12} lg={12} xl={12}>
                        <div className="media-object media-object--state-warning text-center">
                            <figure>
                                <Image src="alert-big.svg" className="svg-icon" />
                            </figure>
                        </div>
                    </Col>

                    <Col sm={12} md={12} lg={12} xl={12}>
                        <h4 className="text-lead">
                            <I18n id="transactionDelay.title" />
                        </h4>
                        <p className="text-lead">
                            <I18n id="transactionDelay.content" />
                        </p>
                        <p className="text-lead">
                            {TIMER_MINUTES}
                            {seconds}
                        </p>
                    </Col>
                </Container>
                <Container>
                    <Col sm={12} md={12} lg={12} xl={12} className="text-center modal-buttons">
                        <Button label="global.continue" bsStyle="primary" onClick={this.closeModal} />
                    </Col>
                </Container>
            </Modal>
        );
    }
}

const mapStateToProps = (store) => ({
    isDelayed: SelectorsStoreTransactions.getIsDelayed(store),
    transaction: SelectorsStoreForm.getTransaction(store),
    waitingForReponse: SelectorsStoreForm.getWaitingForResponse(store),
});

export default Connect(mapStateToProps)(Component);
