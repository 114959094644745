/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";

import ClassNames from "classnames";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { Link } from "react-router-dom";

import { CREDIT_CARD_OWNER, EMPTY_STR, SPACE_STR, STATE_NUMBER } from "~/constants";
import { SelectorsStore as StoreCC, SelectorsAction as ActionCC } from "~/store/creditCards/creditCard";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";

import DataDate from "~/components/DataDate";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";

import Style from "./_Operations.rules.scss";

export const PROP = {
    types: {
        id: PropTypes.string.isRequired,
        permissions: PropTypes.object,
    },
    defaults: {
        permissions: null,
    },
};

export const { NAME, CLASS } = Style;

export function Component(props) {
    const { creditCard, dispatch, id, isChangingEnvironment, isMobile, permissions, statements } = props;

    const { creditCardPurchaseNotification, limitIncrease, tripNotice } = permissions || {};
    const ccStatements = statements[id];

    const { ownershipType } = creditCard;

    const [isOpen, setIsOpen] = React.useState(false);

    if (!ccStatements && !isChangingEnvironment) {
        dispatch(ActionCC.creditCardStatementsRequest({ id }));
    }

    if (ccStatements && ccStatements.length > STATE_NUMBER) {
        ccStatements.splice(STATE_NUMBER - 1, ccStatements.length - STATE_NUMBER);
    }

    const renderStatements = () => (
        <div className="section-two">
            <div
                key="creditCard.accountStatements"
                className={ClassNames("select-accountStatements", { "is-open": isOpen })}
                onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(!isOpen);
                }}>
                <Image classNameInner="icon-button" src="menu-accounts.svg" />
                <I18n id="creditCard.accountStatements" />
                <Image classNameInner="arrow-icon" src="selectArrowDown.svg" />
            </div>
            <ul className={ClassNames("list-statements", { "is-open": isOpen })}>
                {ccStatements.map(({ date }) => (
                    <li key={date}>
                        <div key={date} onClick={(e) => handleClick(e, date)}>
                            <DataDate raw value={date} type-year={false} type-month="long" type-day={false} />
                            {SPACE_STR}
                            <DataDate raw value={date} type-year="numeric" type-month={false} type-day={false} />
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );

    return (
        <section id={Style.ID}>
            <span className="title">
                <I18n id="creditCards.detail.actions.title" />
            </span>
            <div className="card-operations">
                {isMobile &&
                    ownershipType === CREDIT_CARD_OWNER &&
                    ccStatements &&
                    ccStatements.length > 0 &&
                    renderStatements()}
                <div className="section-one">
                    {creditCardPurchaseNotification && (
                        <Link key="menu.creditcards.purchaseNotification" to="/purchaseNotification">
                            <Image src="purchase-sms-notification.svg" />
                            <I18n id="menu.creditcards.purchaseNotification" />
                        </Link>
                    )}
                    {limitIncrease && (
                        <Link key="menu.creditcards.limitIncrease" to="/creditCardsLimitIncrease">
                            <Image src="temporary-limit-increase.svg" />
                            <I18n id="menu.creditcards.limitIncrease" />
                        </Link>
                    )}
                    {tripNotice && (
                        <Link key="menu.creditcards.tripNotice" to="/tripOrOnlinePurchaseNotification">
                            <Image src="travel-online-purchase-notification.svg" />
                            <I18n id="menu.creditcards.tripNotice" />
                        </Link>
                    )}
                </div>
                {!isMobile &&
                    ownershipType === CREDIT_CARD_OWNER &&
                    ccStatements &&
                    ccStatements.length > 0 &&
                    renderStatements()}
            </div>
        </section>
    );

    function handleClick(e, date) {
        e.preventDefault();

        const year = date.substr(0, 4);
        const month = date.substr(5, 2);

        dispatch(ActionCC.creditCardStatementDownloadRequest({ id, year, month }));
    }
}

Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

const mapStateToProps = (store) => {
    const detail = StoreCC.getDetail(store); // detail loaded in previous flow
    let permissions;

    if (!detail) {
        const creditCard = StoreCC.getCreditCard(store);

        permissions = creditCard && creditCard.permissions;
    } else {
        permissions = detail && detail.permissions;
    }

    return {
        id: detail ? detail.idProduct : EMPTY_STR,
        isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
        isFetching: StoreCC.getFetching(store),
        permissions,
        statements: StoreCC.getCreditCardStatements(store),
    };
};

export default Connect(mapStateToProps)(HighOrder.Resizable(Component));
