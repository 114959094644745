import React from "react";

import { connect as Connect } from "react-redux";

import Container from "~/containers/External/Form";
import * as UtilsConfig from "~/util/config";
import { generateId as GenerateId } from "~/util/general";
import * as I18nUtil from "~/util/i18n";
import { Types as TypesHtmlElement, Defaults as DefaultsHtmlElement } from "~/util/prop/html-element";

import FrequentQuestionsItem from "./_Item";

export const NAME = "FrequentQuestions";

export const PROP = {
    types: {
        ...TypesHtmlElement,
    },
    defaults: {
        ...DefaultsHtmlElement,
    },
};

/**
 * Renders a list of Frequent questions.
 * @param {PROP.types} props
 */

export function Component(props) {
    const amount = UtilsConfig.get("information.frequentQuestions.amount", 22);
    const onHeaderClose = () => {
        const { history } = props;
        history.goBack();
    };

    const frequentQuestions = Array.from(Array(amount).keys());
    return (
        <Container name={NAME} head-onBack={onHeaderClose} head-title="information.option.frequentQuestions">
            {frequentQuestions.map((i) => (
                <FrequentQuestionsItem
                    key={GenerateId()}
                    title={I18nUtil.get(`information.frequentQuestions.question${i}.title`)}
                    content={I18nUtil.get(`information.frequentQuestions.question${i}.body`)}
                />
            ))}
        </Container>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Connect(null)(Component);
