/* sessionStorage */

import { push as Push } from "connected-react-router";
import { call as Call, put as Put, takeLatest as TakeLatest, select as Select } from "redux-saga/effects";

import {
    RESPONSE_TYPE,
    LEVEL,
    SCOPE,
    SOFT_OTP,
    BIOMETRIC_ID_USER,
    FINGERPRINT_AUTH_TOKEN,
    BIOMETRIC_TYPE,
    USER_PERSONAL_NAME,
    BIOMETRIC_TYPES,
} from "~/constants";
import { SelectorsAction as SelectorsActionConfig, TYPE as TYPES_CONFIG } from "~/store/config";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { TYPE as SessionType, SelectorsStore as SelectorsStoreSession } from "~/store/session";
import UtilsDevice from "~/util/device";
import { adjustIdFieldErrors } from "~/util/form";
import { get as Get } from "~/util/i18n";
import Logger from "~/util/logger";
import * as UtilsSecureStorage from "~/util/secureStorage";
import * as SoftToken from "~/util/softToken";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction } from "./_selectors";

export default [
    TakeLatest(TYPE.MIGRATION_SUGGEST_REQUEST, function* migrationSuggest(props) {
        const { formikBag } = props;
        const response = yield Call(SelectorsMiddleware.migrationSuggest);
        if (formikBag) {
            formikBag.setSubmitting(false);
        }
        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.MIGRATION_SUGGEST],
                }),
            );
        }
    }),

    TakeLatest(TYPE.CHECK_MOBILE_REQUEST, function* checkMobilePhone(props) {
        const { mobilePhone, mobilePhoneClean, formikBag } = props;
        const response = yield Call(SelectorsMiddleware.checkMobilePhone, { _mobilePhone: mobilePhoneClean });
        if (formikBag) {
            formikBag.setSubmitting(false);
        }
        if (response.type === RESPONSE_TYPE.WARNING) {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.CHECK_MOBILE_PHONE],
                }),
            );
        } else {
            const { isCorrectMobilePhone, _currentMobilePhone, idUser } = response.data.data;
            yield Put(SelectorsAction.registerBiometricMigration({ registerBiometricMigration: true }));
            if (isCorrectMobilePhone) {
                yield Put(Push("/securityTips")); // Registro el biometrico
            } else {
                yield Put(Push("/changePhone/confirmation")); // Confirmo el cambio de celular
            }
            yield Put(
                SelectorsAction.successCheckMobilePhone({
                    // Guardo el celular que ingreso el usuario
                    newMobilePhone: mobilePhone,
                    newMobilePhoneClean: mobilePhoneClean,
                    currentMobilePhone: _currentMobilePhone,
                    idUser,
                }),
            );
        }
    }),

    TakeLatest(TYPE.CHANGE_MOBILE_REQUEST, function* changeMobilePhone(props) {
        const { newMobilePhone, formikBag } = props;
        const response = yield Call(SelectorsMiddleware.changeMobilePhone, { _mobilePhone: newMobilePhone });
        if (formikBag) {
            formikBag.setSubmitting(false);
        }
        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(SelectorsAction.errorChangeMobilePhone());
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.CHANGE_MOBILE_PHONE],
                }),
            );
        } else {
            yield Put(SelectorsAction.successChangeMobilePhone());
            yield Put(Push("/securityTips")); // Registro el biometrico
        }
    }),

    TakeLatest(TYPE.SECURITY_TIPS_REQUEST, function* securtityTipsRequestMigration(props) {
        const { idUser } = props;
        const biometricType = BIOMETRIC_TYPES.fingerPrint;
        let deviceId = "deviceId";
        let deviceModel = "deviceModel";
        if (UtilsDevice.isMobileNative()) {
            deviceId = UtilsDevice.id();
            deviceModel = UtilsDevice.model();
        }

        const response = yield Call(SelectorsMiddleware.securtityTips, {
            idUser,
            biometricType,
            deviceId,
            deviceModel,
        });
        if (response) {
            if (response.type === RESPONSE_TYPE.WARNING) {
                yield Put(SelectorsAction.securtityTipsError());
                yield Put(Push("/desktop"));
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: Get(response.data.message),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.DESKTOP],
                    }),
                );
            } else if (response.status === RESPONSE_TYPE.UNAUTHORIZED) {
                yield Put(Push("/desktop"));
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: response.data.message,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.DESKTOP],
                    }),
                ); // TODO: revisar
            } else {
                const { ...rest } = response.data.data;
                yield Put(
                    SelectorsAction.securtityTipsSuccess({
                        ...rest,
                    }),
                );
                yield Put(Push("/registerBiometric"));
            }
        }
    }),

    TakeLatest(TYPE.ADD_SOFT_OTP_REQUEST, function* addSoftOTPWithBiometricMigration(props) {
        // Luego de ingresar la huella, se llama  safeway para obtener una seed
        const { biometricType } = props;

        let deviceId = "deviceId";
        let deviceModel = "deviceModel";

        if (UtilsDevice.isMobileNative()) {
            deviceId = UtilsDevice.id();
            deviceModel = UtilsDevice.model();
        }
        let hasPermission;
        try {
            hasPermission = yield Call(SoftToken.vascoHasPermission);
        } catch {
            hasPermission = false;
        }
        if (hasPermission) {
            const response = yield Call(SelectorsMiddleware.addSoftOTPWithBiometric, {
                biometricType,
                deviceId,
                deviceModel,
            });

            if (response) {
                if (response.type === RESPONSE_TYPE.WARNING) {
                    yield Put(SelectorsAction.addSoftOTPWithBiometricError());
                    yield Put(Push("/desktop"));
                    yield Put(
                        SelectorsActionNotification.showNotification({
                            message: Get(response.data.message),
                            level: LEVEL.ERROR,
                            scopes: [SCOPE.DESKTOP],
                        }),
                    );
                } else if (response.status === RESPONSE_TYPE.UNAUTHORIZED) {
                    yield Put(Push("/desktop"));
                    yield Put(
                        SelectorsActionNotification.showNotification({
                            message: response.data.message,
                            level: LEVEL.ERROR,
                            scopes: [SCOPE.DESKTOP],
                        }),
                    ); // TODO: revisar
                } else {
                    const { seed, _commonName } = response.data.data;
                    try {
                        yield Call(SoftToken.activateOnline, { _commonName, seed });
                        yield Put(SelectorsAction.finishMigrationSoft({ biometricType }));
                    } catch (errorPlugin) {
                        Logger.error("Fallo plugin de vasco :  ", errorPlugin);

                        yield Put(
                            SelectorsAction.revokeSoftOtp({
                                key: "Vasco",
                                errorPlugin,
                            }),
                        );
                    }
                }
            }
        } else {
            yield Put(SelectorsAction.addSoftOTPWithBiometricError());
            yield Put(Push("/desktop"));
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: Get("vascoPermisson.error.migration"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.DESKTOP],
                }),
            );
        }
    }),

    TakeLatest(TYPE.MIGRATION_FINISH_SOFT_REQUEST, function* finishMigration(props) {
        const { biometricType } = props;

        let deviceId = "deviceId";
        let deviceModel = "deviceModel";
        if (UtilsDevice.isMobileNative()) {
            deviceId = UtilsDevice.id();
            deviceModel = UtilsDevice.model();
        }
        const response = yield Call(SelectorsMiddleware.finishMigrationtSoft, {
            biometricType,
            deviceId,
            deviceModel,
        });

        if (response) {
            if (response.type === RESPONSE_TYPE.WARNING || response.status === RESPONSE_TYPE.UNAUTHORIZED) {
                yield Put(SelectorsAction.finishMigrationSoftError());
                yield Put(Push("/desktop"));
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: Get(response.data.message),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.DESKTOP],
                    }),
                );
            } else {
                try {
                    const { personalName, accessToken, ...rest } = response.data.data;
                    yield Put(
                        SelectorsAction.addSoftOTPWithBiometricSuccess({
                            ...rest,
                        }),
                    );
                    if (accessToken) {
                        // Si el usuario no tiene una sesion ya creada de fingerPrint, se crea una
                        const resultFingerPrint = yield Call(
                            UtilsSecureStorage.set,
                            FINGERPRINT_AUTH_TOKEN,
                            accessToken,
                        );

                        if (resultFingerPrint) {
                            sessionStorage.setItem("widget.biometric.hide", true);
                        }
                    }
                    yield Put(Push("/finishMigration"));

                    const user = yield Select(SelectorsStoreSession.getUser);
                    const { userName } = user;
                    // Se guarda el idUser que se esta enrolando en el secureStorage y el tipo de Biometrico
                    yield Call(UtilsSecureStorage.set, BIOMETRIC_ID_USER, userName);
                    yield Call(UtilsSecureStorage.set, BIOMETRIC_TYPE, biometricType);
                    yield Call(UtilsSecureStorage.set, USER_PERSONAL_NAME, personalName);

                    // Si se migra, el celular pasa a ser trusted
                    yield Put(SelectorsActionConfig.isTrusted({ isTrusted: true }));

                    const needsBiometric = true;
                    const otpType = `${SOFT_OTP}|${biometricType}`;

                    yield Put({
                        // Se marca como que se necesita biometrico
                        type: SessionType.NEEDS_BIOMETRIC,
                        user: { ...user, needsBiometric, otpType },
                    });

                    const isTrusted = true;
                    yield Put({ type: TYPES_CONFIG.IS_TRUSTED, isTrusted }); // Se marca el dispositivo como trusted

                    yield Put(
                        SelectorsAction.finishMigrationSoftSuccess({
                            ...rest,
                        }),
                    );
                } catch (errorPlugin) {
                    Logger.error("Fallo el plugin de secureStorage:  ", errorPlugin);
                    yield Put(
                        SelectorsAction.revokeSoftOtp({
                            key: "Vasco",
                            errorPlugin,
                        }),
                    );
                }
            }
        }
    }),
    TakeLatest(TYPE.MIGRATION_REVOKE_SOFT_REQUEST, function* revokeSoftOtp(props) {
        const { errorPlugin, key } = props;

        const errorVasco = SoftToken.ConvertError(errorPlugin);

        const response = yield Call(SelectorsMiddleware.revokeSoftOtp, {
            // Se revoca el otp del usuario ya que si llego a este punto es que no se pudo instalar
            // la semilla pero habia quedado en este ingresado en safeway por el llamado anterior
            key,
            errorPlugin: errorVasco,
        });

        if (response) {
            if (response.type === RESPONSE_TYPE.WARNING || response.status === RESPONSE_TYPE.UNAUTHORIZED) {
                yield Put(SelectorsAction.revokeSoftOtpError());
                yield Put(Push("/desktop"));
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: Get(response.data.message),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.DESKTOP],
                    }),
                );
            } else {
                yield Put(Push("/desktop"));
                if (errorPlugin === SoftToken.error.NO_PERMISSION_GRANTED) {
                    yield Put(
                        SelectorsActionNotification.showNotification({
                            message: Get("vascoPermisson.error.migration"),
                            level: LEVEL.ERROR,
                            scopes: [SCOPE.DESKTOP],
                        }),
                    );
                } else {
                    yield Put(
                        SelectorsActionNotification.showNotification({
                            message: Get("addSoftOtp.error"),
                            level: LEVEL.ERROR,
                            scopes: [SCOPE.DESKTOP],
                        }),
                    );
                }
            }
        }
    }),
];
