import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { FORMAT_SHORT } from "~/constants";
import { SelectorsStore as SelectorsStoreForm } from "~/store/form";

import I18n from "~/components/I18n";

export const NAME = "ModifyAutomaticDebit";

export const PROP = {
    types: {
        previewData: PropTypes.shape({
            companyName: PropTypes.string,
            agreement: PropTypes.string,
            reference: PropTypes.string,
            debitAccountAlias: PropTypes.string,
            description: PropTypes.string,
            notification: PropTypes.string,
            paymentType: PropTypes.string,
        }).isRequired,
        dateFormat: PropTypes.string,
    },
    defaults: {
        previewData: {
            companyName: null,
            agreement: null,
            reference: null,
            debitAccountAlias: null,
            description: null,
            notification: null,
            paymentType: null,
        },
        dateFormat: FORMAT_SHORT,
    },
};

export function Component(props) {
    const {
        previewData: { companyName, agreement, debitAccountAlias, description, notification, paymentType },
    } = props;

    return (
        <React.Fragment>
            <p className="text-lead">
                <I18n id="forms.modifyAutomaticDebit.confirmation.title" />
            </p>

            <div className="data-wrapper">
                <span className="data-label">
                    <I18n id="forms.createAutomaticDebit.confirmation.idCompany" />
                </span>
                <span className="data-name">{companyName}</span>
            </div>
            <div className="data-wrapper">
                <span className="data-label">
                    <I18n id="forms.createAutomaticDebit.confirmation.agreementLabel" />
                </span>
                <span className="data-name">{agreement}</span>
            </div>

            <div className="data-wrapper">
                <span className="data-label">
                    <I18n id="forms.createAutomaticDebit.confirmation.debitAccountAlias" />
                </span>
                <span className="data-name">{debitAccountAlias}</span>
            </div>
            {description && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.createAutomaticDebit.confirmation.description" />
                    </span>
                    <span className="data-name">{description}</span>
                </div>
            )}
            <div className="data-wrapper">
                <span className="data-label">
                    <I18n id="forms.createAutomaticDebit.confirmation.notification" />
                </span>
                <span className="data-name">
                    <I18n id={`client.pay.automaticDebit.notificationList.label.${notification}`} />
                </span>
            </div>
            <div className="data-wrapper">
                <span className="data-label">
                    <I18n id="forms.createAutomaticDebit.confirmation.paymentType" />
                </span>
                <span className="data-name">
                    <I18n id={`forms.createAutomaticDebit.confirmation.paymentType.option.${paymentType}`} />
                </span>
            </div>
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    previewData: SelectorsStoreForm.getPreviewData(store),
});

export default Connect(mapStateToProps)(Component);
