import { call as Call, takeLatest as TakeLatest, put as Put } from "redux-saga/effects";

import { SelectorsMiddleware as SelectorsMiddlewareSession } from "~/store/session";

import { TYPE } from "./_consts";
import { SelectorsAction } from "./_selectors";

const sagas = [TakeLatest([TYPE.BACK_URL_SISTARBANC], urlBackSistarbanc), TakeLatest([TYPE.LOGOUT_BEVSA], logoutBevsa)];

export default sagas;

function* urlBackSistarbanc(props) {
    const { idTransactionUrl: idTransaccion, fecha, urlVuelta } = props;

    const data = { idTransaccion, fecha };
    submitFormData(data, urlVuelta, "sistarbanc");
    yield Put(SelectorsAction.backUrlSistarBancSuccess());
    yield Call(SelectorsMiddlewareSession.logout);
}

function submitFormData(data, action, id, where) {
    const form = document.createElement("form");
    form.method = "POST";
    form.id = id;
    form.action = action;

    Object.keys(data).forEach(function eachKey(key) {
        const elem = document.createElement("input");
        elem.setAttribute("type", "hidden");
        elem.value = data[key];
        elem.name = key;
        form.appendChild(elem);
    });

    if (where) {
        where.appendChild(form);
    } else {
        document.body.appendChild(form);
    }
    document.getElementById(id).submit();
}

function* logoutBevsa() {
    yield Call(SelectorsMiddlewareSession.logout);
}
