import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { CDP_CURRENCY, PIPE_SEPARATOR, EMPTY_STR, SCOPE, LEVEL } from "~/constants";
import {
    SelectorsStore as SelectorsStoreFactoring,
    SelectorsAction as SelectorsActionFactoring,
} from "~/store/factoring/advancePayments";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import UtilFlag from "~/util/flag";
import DeviceUtils from "~/util/device";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import Element from "~/components/Component";
import DataNumber from "~/components/DataNumber";
import GridLayout from "~/components/GridLayout";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import IntersectionObserver from "~/components/IntersectionObserver";
import IndeterminateCheckbox from "~/pages/_components/IndeterminateCheckbox";

import { NAME_NOTE, TYPE_NOTE } from "../../_index.scss";
import Style from "./Step1Edit.rules.scss";
import Chart from "./_Chart";
import PaymentItem from "./_PaymentItem";

export const { NAME, TYPE } = Style;

export const PROP = {
    types: {
        advancePaymentsCompaniesList: PropTypes.array.isRequired,
        totalAdvancePaymentAmountUSD: PropTypes.number.isRequired,
        totalAdvancePaymentAmountUYU: PropTypes.number.isRequired,
        totalDiscountableUYU: PropTypes.number.isRequired,
        totalDiscountableUSD: PropTypes.number.isRequired,
        fetching: PropTypes.bool.isRequired,
        moreLines: PropTypes.bool.isRequired,
        i18n: PropTypes.object,
    },
    defaults: {
        i18n: {
            loading: "Cargando",
            labelMore: "Más movimientos",
            labelNoMore: "No hay más movimientos",
            paymentOrderLabel: "Orden",
        },
    },
};

export function Component(props) {
    const {
        dispatch,
        advancePaymentsCompaniesList,
        moreLines,
        fetching,
        i18n,
        totalAdvancePaymentBalanceAmountUYU,
        totalAdvancePaymentBalanceAmountUSD,
        totalDiscountableUYU,
        totalDiscountableUSD,
        ordersToDiscountInStore,
        storeCurrentDiscountableUYU,
        storeCurrentDiscountableUSD,
    } = props;

    const [currentDiscountableUSD, setCurrentDiscountableUSD] = React.useState({ amount: 0, total: 0 });
    const [currentDiscountableUYU, setCurrentDiscountableUYU] = React.useState({ amount: 0, total: 0 });
    const [checked, setChecked] = React.useState(false);
    const [ordersToDiscountList, setOrdersToDiscountList] = React.useState([]);

    //  Update discountable totals
    React.useEffect(() => {
        if (checked && totalDiscountableUYU !== currentDiscountableUYU) {
            setCurrentDiscountableUYU(totalDiscountableUYU);
        }
        if (checked && totalDiscountableUSD !== currentDiscountableUSD) {
            setCurrentDiscountableUSD(totalDiscountableUSD);
        }
    }, [checked, currentDiscountableUSD, currentDiscountableUYU, totalDiscountableUSD, totalDiscountableUYU]);

    //  Update selected items and discountable totals if returning from step2
    React.useEffect(() => {
        if (!fetching && ordersToDiscountInStore.length > 0) {
            setCurrentDiscountableUYU(storeCurrentDiscountableUYU);
            setCurrentDiscountableUSD(storeCurrentDiscountableUSD);
            setOrdersToDiscountList(ordersToDiscountInStore);
            dispatch(SelectorsActionFactoring.cleanOrdersToDiscount());
        }
    }, [ordersToDiscountInStore, fetching, storeCurrentDiscountableUYU, storeCurrentDiscountableUSD, dispatch]);

    //  Update upper checkbox if all items were selected
    React.useEffect(() => {
        if (
            currentDiscountableUYU.total === totalDiscountableUYU.total &&
            currentDiscountableUSD.total === totalDiscountableUSD.total &&
            (totalDiscountableUSD.total > 0 || totalDiscountableUYU.total > 0)
        ) {
            setChecked(true);
        }
    }, [currentDiscountableUSD, currentDiscountableUYU, totalDiscountableUSD.total, totalDiscountableUYU.total]);

    const { paymentOrderLabel, loading, labelMore, netAmountLabel, issueDateLabel, dueDateLabel, amountLabel } = i18n;

    const isDiscounting = (currentDiscountableUSD.total > 0 || currentDiscountableUYU.total > 0);
    const separator = currentDiscountableUSD.total > 0 && currentDiscountableUYU.total > 0;
    
    const isDesktop = DeviceUtils.isDisplayDesktop();

    const HEADINGS_COLUMNS = isDesktop && 
    [
        paymentOrderLabel,
        dueDateLabel,
        issueDateLabel,
        isDiscounting ? netAmountLabel : EMPTY_STR,
        amountLabel,
        EMPTY_STR, 
    ] || [
        dueDateLabel,
        isDiscounting ? netAmountLabel : EMPTY_STR,
        amountLabel,
        EMPTY_STR, 
    ];;

    return (
        <div id={Style.ID}>
            {isDesktop && <Chart ordersToDiscountList={ordersToDiscountList} />}
            <form onSubmit={handleSubmit}>
                <IntersectionObserver onIntersect={handleMovementsFetch}>
                    <GridLayout className="header">
                        <IndeterminateCheckbox
                            id="SelectAllOrders"
                            onCheckClick={handleCheckChange}
                            selectedOptionsAmount={checked ? 1 : 0}
                        />
                           {HEADINGS_COLUMNS.map((heading, index) => (
                                    <Box justify={(index === 3 || index === 4) && "end"}>{heading}</Box>
                                ))}
                    </GridLayout>

                    {advancePaymentsCompaniesList.map((company) => RenderList(company))}

                    {fetching && <Note value={loading} />}

                    <Totalizers />

                    {!fetching && moreLines && (
                        <Note
                            onClick={() => handleMovementsFetch({ isIntersecting: true })}
                            intersection-trigger="true"
                            key="more"
                            value={labelMore}
                        />
                    )}

                    <I18n id="client.factoring.paymentOrders.selectToDiscount" />
                </IntersectionObserver>
                <footer>
                    <Button onClick={handleCancelButton} variant="secondary">
                        {UtilsI18n.get("global.cancel")}
                    </Button>
                    {!fetching && (
                        <Button type="submit" variant="primary" disabled={ordersToDiscountList.length === 0}>
                            {UtilsI18n.get("global.accept")}
                        </Button>
                    )}
                </footer>
            </form>
        </div>
    );

    function handleSubmit(e) {
        e.preventDefault();
        if (ordersToDiscountList.length !== 0) {
            dispatch(
                SelectorsActionFactoring.advancePaymentsTransactionPreviewRequest({
                    ordersToDiscountList,
                    currentDiscountableUSD,
                    currentDiscountableUYU,
                }),
            );
        } else {
            dispatch(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("client.factoring.advancePayments.advance.emptyPayments"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADVANCE_PAYMENT_ORDERS],
                }),
            );
        }
    }

    function handleCancelButton() {
        const { handleBack } = props;
        handleBack();
    }

    function handleMovementsFetch(ev) {
        const { isIntersecting } = ev;
        if (!isIntersecting) {
            return;
        }
        dispatch(SelectorsActionFactoring.listAdvancePaymentOrdersRequest({ moreOrders: true }));
    }

    function RenderList({ name, currency, totalAmount, paymentsList }) {
        return (
            <React.Fragment>
                <div className="header-orders">
                    <div>
                        <span>{name}</span>
                        {isDesktop && <span>{UtilsI18n.get(`currency.label.${currency}`) || currency}</span>}
                        <Image className="currency-flag" src={`${UtilFlag.getFlag(`${currency}`)}.svg`} />
                    </div>
                    <Box justify="end">
                        <DataNumber value={totalAmount} prefix={currency} />
                    </Box>
                </div>
                {paymentsList.map((i) => {
                    const isSelected = ordersToDiscountList.includes(i);
                    return (
                        <React.Fragment>
                        <PaymentItem
                            isSelected={isSelected}
                            payment={i}
                            handleRemovePayment={handleRemovePayment}
                            handleAddPayment={handleAddPayment}
                        />
                        </React.Fragment>
                    );
                })}
            </React.Fragment>
        );
    } 

    function Totalizers() {
        return (
            <div className="totalizers">
                <div>
                    <I18n id="client.factoring.advancePaymentOrders.transaction.totalAmount" />
                    <DataNumber value={totalAdvancePaymentBalanceAmountUSD} prefix={CDP_CURRENCY.USD} />
                    <span className="hide-pipe">{PIPE_SEPARATOR}</span>
                    <DataNumber value={totalAdvancePaymentBalanceAmountUYU} prefix={CDP_CURRENCY.UYU} />
                </div>
                {isDiscounting && (
                    <div>
                        <I18n id="client.factoring.advancePaymentOrders.transaction.totalToDiscount" />
                        {currentDiscountableUSD.total > 0 && (
                            <DataNumber value={currentDiscountableUSD.amount} prefix={CDP_CURRENCY.USD} />
                        )}
                        <span>{separator && PIPE_SEPARATOR}</span>
                        {currentDiscountableUYU.total > 0 && (
                            <DataNumber value={currentDiscountableUYU.amount} prefix={CDP_CURRENCY.UYU} />
                        )}
                    </div>
                )}
            </div>
        );
    }

    function Note({ value, ...rest }) {
        return (
            <Element type={TYPE_NOTE} name={NAME_NOTE} tag="span" {...rest}>
                {value}
            </Element>
        );
    }

    function handleCheckChange() {
        if (!checked) {
            setCurrentDiscountableUSD(totalDiscountableUSD);
            setCurrentDiscountableUYU(totalDiscountableUYU);
            let allOrders = [];
            advancePaymentsCompaniesList.forEach((company) => {
                const { paymentsList } = company;
                const discountableOrders = paymentsList.filter((payment) => payment.discountable);
                allOrders = allOrders.concat(discountableOrders);
            });
            setOrdersToDiscountList(allOrders);
        } else {
            setCurrentDiscountableUSD({ amount: 0, total: 0 });
            setCurrentDiscountableUYU({ amount: 0, total: 0 });
            setOrdersToDiscountList([]);
        }
        setChecked(!checked);
    }

    function handleRemovePayment({ id, currency, netAmount }) {
        if (checked) {
            setChecked(false);
        }
        let newAdvanceAmount;
        if (`${currency}` === CDP_CURRENCY.UYU) {
            newAdvanceAmount = {
                amount: currentDiscountableUYU.amount - netAmount,
                total: currentDiscountableUYU.total - 1,
            };
            setCurrentDiscountableUYU(newAdvanceAmount);
        } else {
            newAdvanceAmount = {
                amount: currentDiscountableUSD.amount - netAmount,
                total: currentDiscountableUSD.total - 1,
            };
            setCurrentDiscountableUSD(newAdvanceAmount);
        }
        const newList = ordersToDiscountList.filter((item) => item.id !== id);
        setOrdersToDiscountList(newList);
    }

    function handleAddPayment(payment) {
        const { currency, netAmount } = payment;
        if (`${currency}` === CDP_CURRENCY.UYU) {
            setCurrentDiscountableUYU((prevState) => ({
                ...prevState,
                amount: prevState.amount + netAmount,
                total: prevState.total + 1,
            }));
        } else {
            setCurrentDiscountableUSD((prevState) => ({
                ...prevState,
                amount: prevState.amount + netAmount,
                total: prevState.total + 1,
            }));
        }

        setOrdersToDiscountList((oldArray) => [...oldArray, payment]);
    }
}
const mapStateToProps = (store) => ({
    advancePaymentsCompaniesList: SelectorsStoreFactoring.getAdvancePaymentsList(store),
    ordersToDiscountInStore: SelectorsStoreFactoring.getOrdersToDiscountList(store),
    totalAdvancePaymentBalanceAmountUSD: SelectorsStoreFactoring.getTotalAdvancePaymentBalanceAmountUSD(store),
    totalAdvancePaymentBalanceAmountUYU: SelectorsStoreFactoring.getTotalAdvancePaymentBalanceAmountUYU(store),
    totalDiscountableUYU: SelectorsStoreFactoring.getTotalDiscountableUYU(store),
    totalDiscountableUSD: SelectorsStoreFactoring.getTotalDiscountableUSD(store),
    storeCurrentDiscountableUYU: SelectorsStoreFactoring.getCurrentDiscountableUYU(store),
    storeCurrentDiscountableUSD: SelectorsStoreFactoring.getCurrentDiscountableUSD(store),
    fetching: SelectorsStoreFactoring.isFetching(store),
    moreLines: SelectorsStoreFactoring.getMoreLines(store),
    i18n: {
        loading: StoreI18n.getMessage(store, "global.loading"),
        labelMore: StoreI18n.getMessage(store, "client.factoring.paymentOrders.list.moreOrders"),
        issueDateLabel: StoreI18n.getMessage(store, "client.factoring.paymentOrders.list.issueDate"),
        dueDateLabel: StoreI18n.getMessage(store, "client.factoring.paymentOrders.list.dueDate"),
        amountLabel: StoreI18n.getMessage(store, "client.factoring.paymentOrders.list.amount"),
        paymentOrderLabel: StoreI18n.getMessage(store, "client.factoring.advancePaymentOrders.transaction.paymentOrder"),
        netAmountLabel: StoreI18n.getMessage(store, "client.factoring.advancePaymentOrders.transaction.netAmount"),
    },
});

Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

export default Connect(mapStateToProps)(Component);
