import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SelectorsStore as SelectorsStoreAccount } from "~/store/account";
import { generateId as GenID } from "~/util/general";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Box from "~/components/Box";
import DataDate from "~/components/DataDate";
import DataNumber from "~/components/DataNumber";
import GridLayout from "~/components/GridLayout";

import Namespace from "./RatesList.rules.scss";

export const { CLASS, NAME } = Namespace;

export const PROP = {
    types: {
        ...TypesRedux,
        rates: PropTypes.array,
    },
    defaults: {
        ...DefaultsRedux,
        rates: [],
    },
};
export function Component(props) {
    const { rates } = props;
    return (
        <Box className={CLASS}>
            {rates &&
                rates.map(({ name, dateFrom, value }) => (
                    <Box flex directionColumn key={GenID()}>
                        <GridLayout>
                            <DataDate className="input-row" value={dateFrom} />
                            <div className="input-row">{name}</div>
                            <DataNumber className="input-row" suffix="%" value={value} />
                        </GridLayout>
                    </Box>
                ))}
        </Box>
    );
}

Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

const mapStateToProps = (store) => ({
    rates: SelectorsStoreAccount.getRates(store),
});

export default Connect(mapStateToProps)(Component);
