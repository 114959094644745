import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";

import { EMPTY_STR, FIELD_SUBTYPE, EXTERNAL_PAYMENTS, ID_FIELD, ID_FORM } from "~/constants";
import { MODE } from "~/constants/form";
import {
    SelectorsAction as SelectorsActionAutomaticDebits,
    SelectorsStore as SelectorsStoreAutomaticDebits,
} from "~/store/automaticDebits";
import {
    SelectorsAction,
    SelectorsStore as SelectorsStorePurchaseNotification,
} from "~/store/creditCards/purchaseNotification";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionTransactionLines } from "~/store/transactionLines";
import * as UtilsConfig from "~/util/config";
import * as UtilsI18n from "~/util/i18n";
import UtilLodash from "~/util/lodash";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Select from "~/components/Select";
import { PROP as PropEnvironments } from "~/pages/_components/Environments";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import withFocus from "~/pages/_components/withFocus";

import formField from "~/pages/forms/_components/_fields/_commons/formField";

export const NAME = "Productselector";

export const PROP = {
    types: {
        ...TypesRedux,
        data: PropTypes.shape({
            options: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired),
        }).isRequired,
        mode: PropTypes.string,
        isRequired: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        setValue: PropTypes.func.isRequired,
        editing: PropTypes.bool,
        toggleIsFocused: PropTypes.func.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({ value: PropTypes.string, isFrequestDestination: PropTypes.bool }),
        ]),
        active: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
        phone: PropTypes.string,
        listAgreements: PropTypes.array,
        activeEnvironment: PropEnvironments.activeEnvironment,
    },
    defaults: {
        ...DefaultsRedux,
        mode: EMPTY_STR,
        isRequired: false,
        editing: false,
        value: null,
        phone: null,
        listAgreements: [],
        activeEnvironment: null,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        idElementState: EMPTY_STR,
        listAgreementsState: [],
        purchaseNotificationPhone: null,
        purchaseNotificationActive: EMPTY_STR,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            subType,
            listAgreements,
            changeValueAgreement,
            dispatch,
            idField,
            form: { setFieldValue },
        } = nextProps;

        if (subType === FIELD_SUBTYPE.PURCHASE_NOTIFICATION) {
            const { active, phone, value, dependentFields } = nextProps;
            const { purchaseNotificationPhone, purchaseNotificationActive } = prevState;

            let valuesForDependees = {
                value,
            };

            let hasChanged = false;

            if (active !== purchaseNotificationActive) {
                valuesForDependees = {
                    ...valuesForDependees,
                    purchaseNotificationActive: active,
                };

                hasChanged = true;
            }

            if (phone !== purchaseNotificationPhone) {
                valuesForDependees = {
                    ...valuesForDependees,
                    purchaseNotificationPhone: phone,
                };

                hasChanged = true;
            }

            if (hasChanged && dependentFields && dependentFields.length > 0) {
                dispatch(SelectorsActionForm.dependeeChanged({ idField, value: valuesForDependees }));
            }

            return {
                purchaseNotificationActive: active,
                purchaseNotificationPhone: phone,
            };
        }
        if (subType === FIELD_SUBTYPE.AUTOMATIC_DEBIT_AGREEMENT) {
            if (changeValueAgreement) {
                dispatch(SelectorsActionAutomaticDebits.changeValue());
                setFieldValue(idField, null);
                return {
                    listAgreementsState: listAgreements,
                };
            }
        }

        return null;
    }

    componentDidMount() {
        const { mode, isRequired, form } = this.props;
        let { value } = this.props;

        if (mode === MODE.EDIT) {
            if (!value && !isRequired) {
                value = "_empty";
            }
            if (!value) {
                const { data, idForm, idField } = this.props;
                if (idForm === ID_FORM.TRANSFER_INTERNAL && idField === ID_FIELD.CREDIT_ACCOUNT) {
                    const debitAccountValue = form.values[ID_FIELD.DEBIT_ACCOUNT];
                    const nextValue = this.handleNextValue(debitAccountValue);
                    // Me quedo con el primer elemento de la lista que no sea igual al debitAccount,
                    // en caso de que sea uno solo devuelvo el mismo
                    this.handleChange({ id: nextValue.id });
                } else if (data.options[0]) {
                    this.handleChange({ id: data.options[0].id });
                }
            } else {
                // es value.value si es un campo cargado con setFieldValue (dado que el valor es
                // {value: xxx, isFrequestDestination: bool})
                // si es un valor que viene cargado por url, el value es directamente el id de la cuenta
                // es así hasta que eliminemos el isFrequestDestination y esto se resuelva de otra forma
                // (con value solito :P)
                this.handleChange({ id: value.value || value });
            }
        }
    }

    componentWillUnmount() {
        const { dispatch, subType } = this.props;

        if (subType === FIELD_SUBTYPE.PURCHASE_NOTIFICATION) {
            dispatch(SelectorsAction.cleanUp());
        }
    }

    handleNextValue = (id) => {
        const { data } = this.props;
        const next = data.options.find((elem) => elem.id !== id);
        if (id === EMPTY_STR || !next) {
            // Si se entra por primera vez al formulario (id viene vacio) o si no se encuentra
            // ningun elementto distinto al de debitAccount
            if (data.options[1]) {
                return data.options[1];
            }
            if (data.options[0]) {
                return data.options[0];
            }
        }
        return next;
    };

    handleChange = (newValue) => {
        if (newValue) {
            const {
                id,
                feesAllowed,
                label,
                newsDate,
                exampleIcon,
                exampleText,
                maxReferenceLength,
                referenceLength,
                agreementCurrency,
            } = newValue;
            const {
                dispatch,
                idField,
                setValue,
                subType,
                value,
                form: { values, setFieldValue },
            } = this.props;
            if (subType === FIELD_SUBTYPE.AUTOMATIC_DEBIT_AGREEMENT) {
                if (newValue.maxReferenceLength && values.reference) {
                    setFieldValue(FIELD_SUBTYPE.REFERENCE, [], false);
                }
                dispatch(SelectorsActionAutomaticDebits.changeNewsDate({ newsDate }));
                setValue({
                    value: id,
                    feesAllowed,
                    label,
                    exampleIcon,
                    exampleText,
                    maxReferenceLength,
                    referenceLength,
                    agreementCurrency,
                });
            } else {
                setValue({ value: id, isFrequentDestination: false });
            }

            if (subType === FIELD_SUBTYPE.MULTILINE_PAYMENT) {
                const {
                    data: { options },
                } = this.props;
                const selectedOption = options.find((element) => element.id === id);

                if (selectedOption) {
                    const { currency } = selectedOption;
                    const { idElementState } = this.state;

                    if (id !== idElementState) {
                        dispatch(SelectorsActionForm.dependeeChanged({ idField, value: selectedOption }));

                        this.setState((prevState) => ({
                            ...prevState,
                            idElementState: id,
                        }));
                    }

                    dispatch(SelectorsActionTransactionLines.setCurrency({ currency }));
                }
            } else if (
                subType === FIELD_SUBTYPE.PURCHASE_NOTIFICATION &&
                value &&
                value[0] !== id &&
                value.length !== 0
            ) {
                dispatch(SelectorsAction.requestPurchaseNotificationGetPhoneAndActive({ id }));
            } else if (subType === FIELD_SUBTYPE.CREDIT_CARD_SELECTOR && newValue.id !== value) {
                dispatch(SelectorsAction.changeCreditcard());
            }
        }
    };

    buildProductOptions = (originalOptions) => {
        const { isRequired, subType } = this.props;
        // \u00A0 es un character especial para forzar un espacio en blanco
        const options = isRequired
            ? originalOptions
            : [{ id: "_empty", isFrequentDestination: false, label: "\u00A0" }, ...originalOptions];
        return options.map((elem) => {
            if (elem.balance && elem.balance.quantity !== 0) {
                return {
                    id: elem.id,
                    label: (
                        <React.Fragment>
                            <span className="control-label">{elem.label}</span>
                            <FormattedAmount quantity={elem.balance.quantity} currency={elem.balance.currency} />
                        </React.Fragment>
                    ),
                    alias: elem.label,
                };
            }
            if (subType === FIELD_SUBTYPE.AUTOMATIC_DEBIT_AGREEMENT) {
                const defaultMaxLength = UtilsConfig.get("client.payments.automaticDebit.defaultMaxLength", 50);
                return {
                    id: elem.id,
                    label: elem.label,
                    agreementCurrency: elem.agreementCurrency,
                    feesAllowed: elem.feesAllowed,
                    maxReferenceLength: elem.maxReferenceLength > 0 ? elem.maxReferenceLength : defaultMaxLength,
                    referenceLength: elem.referenceLength,
                    newsDate: elem.newsDate,
                    exampleIcon: elem.exampleIcon,
                    exampleText: elem.exampleText,
                    alias: elem.label,
                };
            }
            return {
                id: elem.id,
                label: elem.label,
                alias: elem.label,
            };
        });
    };

    showCurrencyDisclaimer = (productId) => {
        const {
            dependencies,
            form,
            data: { options },
            subType,
            location: { state },
        } = this.props;
        let showCurrencyDisclaimer = false;
        if (subType === FIELD_SUBTYPE.CURRENCY_DISCLAIMER && productId && dependencies[0]) {
            const { values } = form;
            const dependencyField = dependencies[0];
            const amount = values[dependencyField];
            const chosenOption = options.find((option) => option.id === productId);
            if (chosenOption && chosenOption.currency !== amount.currency) {
                showCurrencyDisclaimer = true;
            }
        }
        if (subType === FIELD_SUBTYPE.AUTOMATIC_DEBIT_ACCOUNT) {
            const {
                values: { idAgreement },
            } = form;
            const agreementCurrency = idAgreement ? idAgreement.agreementCurrency : state.agreementCurrency;
            const chosenOption = options.find((option) => option.id === productId);
            if (chosenOption && Number(chosenOption.currency) !== agreementCurrency) {
                showCurrencyDisclaimer = true;
            }
        }
        return showCurrencyDisclaimer;
    };

    customFilter = (option, searchText) => {
        const aliasLower = option.alias.toLowerCase();
        const searchTextLower = searchText.toLowerCase();
        if (aliasLower.charAt(0).includes(searchTextLower.charAt(0)) && aliasLower.includes(searchTextLower)) {
            return true;
        }
        return false;
    };

    render() {
        const {
            editing,
            value,
            toggleIsFocused,
            data: { options: propsOptions },
            subType,
            dispatch,
            setValue,
        } = this.props;
        const { listAgreementsState } = this.state;

        let options = subType === FIELD_SUBTYPE.AUTOMATIC_DEBIT_AGREEMENT ? listAgreementsState : propsOptions;
        const productId = value && value.value ? value.value : value;

        if (subType === FIELD_SUBTYPE.AUTOMATIC_DEBIT_AGREEMENT) {
            //  If there's only one option set it by default or if value is coming from store
            if (
                (productId === null && options.length === 1) ||
                (productId !== null && value.label === undefined && options.length > 0)
            ) {
                const position =
                    productId === null && options.length === 1 ? 0 : options.findIndex((item) => item.id === productId);
                const {
                    newsDate,
                    agreementCurrency,
                    feesAllowed,
                    label,
                    id,
                    exampleIcon,
                    exampleText,
                    maxReferenceLength,
                    referenceLength,
                } = options[position];
                dispatch(SelectorsActionAutomaticDebits.changeNewsDate({ newsDate }));
                setValue({
                    value: id,
                    feesAllowed,
                    agreementCurrency,
                    label,
                    exampleIcon,
                    exampleText,
                    maxReferenceLength,
                    referenceLength,
                });
            }
        }

        if (editing) {
            let showCurrencyDisclaimer = this.showCurrencyDisclaimer(productId);

            if (subType === FIELD_SUBTYPE.CURRENCY_DISCLAIMER) {
                const idOptions = options.map(({ id }) => id);
                if (options.length > 0) {
                    const hasExternalPamentPermissions = options.filter(
                        (permission) =>
                            // Si el resource devuelve ese permiso es porque hay alguna cuenta
                            // con permisos para ese pago
                            permission.permissions && permission.permissions.includes(EXTERNAL_PAYMENTS.IS_EXTERNAL),
                    );
                    if (hasExternalPamentPermissions.length === 0) {
                        showCurrencyDisclaimer = false;
                        // Si estoy en alguno de los formularios externos y no tengo los respectivos
                        // permisos no se listan debitAccounts
                        options = [];
                    } else if (productId !== null && !idOptions.includes(productId)) {
                        const { id } = options[0];
                        setValue({ value: id, isFrequentDestination: false });
                    }
                }
            }
            const newOptions = this.buildProductOptions(options);
            return (
                <div>
                    <div className="input-group ">
                        <div style={{ flex: 1 }}>
                            <Select
                                placeholder={EMPTY_STR}
                                ref={this.selectorRef}
                                value={productId}
                                clearable={false}
                                onChange={this.handleChange}
                                valueKey="id"
                                labelKey="label"
                                filterOption={this.customFilter}
                                options={newOptions}
                                onFocus={toggleIsFocused}
                                onBlur={toggleIsFocused}
                                className="flex-container slideFromBottom"
                            />
                        </div>
                    </div>
                    {showCurrencyDisclaimer && (
                        <div className="disclaimer-container">
                            <div id="disclaimer-text">{UtilsI18n.get("paySistarbanc.debitAccount.disclaimerText")}</div>
                        </div>
                    )}
                </div>
            );
        }

        let { label } = options.find(({ id }) => id === productId) || {};
        if (subType === FIELD_SUBTYPE.AUTOMATIC_DEBIT_AGREEMENT) {
            label = value.label;
        }

        return (
            <React.Fragment>
                <span className="data-name">{label}</span>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    active: SelectorsStorePurchaseNotification.getActive(store),
    changeValueAgreement: SelectorsStoreAutomaticDebits.getChangetValue(store),
    listAgreements: SelectorsStoreAutomaticDebits.getListAgreements(store),
    phone: SelectorsStorePurchaseNotification.getPhone(store),
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
});
export default UtilLodash.flowRight(withFocus, formField(), Connect(mapStateToProps), WithRouter)(Component);
