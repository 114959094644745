import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

const formatDate = (date) => date.toISOString().split("T")[0];

export const SelectorsMiddleware = {
    listSwiftMessagesRequest: (props) => {
        const { offset, filters } = props;
        let { dateFrom, dateTo } = filters;
        const {
            incomingOutgoing,
            period,
            currency,
            amountFrom,
            amountTo,
            type,
            subType,
            beneficiary,
            reference,
        } = filters;
        dateFrom = dateFrom ? formatDate(dateFrom) : null;
        dateTo = dateTo ? formatDate(dateTo) : null;
        const from = amountFrom && amountFrom.amount ? amountFrom.amount : null;
        const to = amountTo && amountTo.amount ? amountTo.amount : null;

        return API.executeWithAccessToken(PATH.SWIFT_MESSAGES_LIST, {
            offset,
            dateFrom,
            dateTo,
            incomingOutgoing,
            period,
            currency,
            from,
            to,
            type,
            subType,
            beneficiary,
            reference,
        });
    },
    swiftMessagesDetailRequest: (props) => {
        const { swiftKey } = props;
        return API.executeWithAccessToken(PATH.SWIFT_MESSAGES_DETAIL, {
            swiftKey,
        });
    },
    swiftMessagesDetailSendMailRequest: (props) => {
        const { messageDetail, emails } = props;
        const emailsString = emails.toString();

        const { keyString: swiftKey } = messageDetail.key;

        return API.executeWithAccessToken(PATH.SWIFT_MESSAGES_DETAIL_SEND_MAIL, {
            swiftKey,
            emails: emailsString,
        });
    },
    pdfSwiftMessagesDetailRequest: (props) => {
        const { swiftKey } = props;
        return API.executeWithAccessToken(PATH.PDF_SWIFT_MESSAGES_DETAIL, {
            swiftKey,
        });
    },
    listSwiftPreMessagesRequest: () => API.executeWithAccessToken(PATH.SWIFT_PRE_MESSAGES_LIST),
};
export const SelectorsAction = {
    loadListSwiftMessagesRequest: (props) => FactoryAction(TYPE.LOAD_LIST_SWIFT_MESSAGES_REQUEST, props),
    listSwiftMessagesFailure: () => FactoryAction(TYPE.LOAD_LIST_SWIFT_MESSAGES_FAILURE),
    listSwiftMessagesSuccess: (props) => FactoryAction(TYPE.LOAD_LIST_SWIFT_MESSAGES_SUCCESS, props),
    loadListPreSwiftMessagesRequest: (props) => FactoryAction(TYPE.LOAD_LIST_PRE_SWIFT_MESSAGES_REQUEST, props),
    listSwiftPreMessagesFailure: () => FactoryAction(TYPE.LOAD_LIST_PRE_SWIFT_MESSAGES_FAILURE),
    listSwiftPreMessagesSuccess: (props) => FactoryAction(TYPE.LOAD_LIST_PRE_SWIFT_MESSAGES_SUCCESS, props),
    swiftMessagesDetailRequest: (props) => FactoryAction(TYPE.SWIFT_MESSAGES_DETAIL_REQUEST, props),
    swiftMessagesDetailFailure: () => FactoryAction(TYPE.SWIFT_MESSAGES_DETAIL_FAILURE),
    swiftMessagesDetailSuccess: (props) => FactoryAction(TYPE.SWIFT_MESSAGES_DETAIL_SUCCESS, props),
    swiftMessagesDetailSendMailRequest: (props) => FactoryAction(TYPE.SWIFT_MESSAGES_DETAIL_SEND_MAIL_REQUEST, props),
    swiftMessagesDetailSendMailFailure: () => FactoryAction(TYPE.SWIFT_MESSAGES_DETAIL_SEND_MAIL_FAILURE),
    swiftMessagesDetailSendMailSuccess: () => FactoryAction(TYPE.SWIFT_MESSAGES_DETAIL_SEND_MAIL_SUCCESS),
    pdfSwiftMessagesDetailRequest: (props) => FactoryAction(TYPE.PDF_SWIFT_MESSAGES_DETAIL_REQUEST, props),
    pdfSwiftMessagesDetailFailure: () => FactoryAction(TYPE.PDF_SWIFT_MESSAGES_DETAIL_FAILURE),
    pdfSwiftMessagesDetailSuccess: (props) => FactoryAction(TYPE.PDF_SWIFT_MESSAGES_DETAIL_SUCCESS, props),
    setShowSendMailModal: (props) => FactoryAction(TYPE.SET_SHOW_SEND_MAIL_MODAL, props),
    resetFilters: () => FactoryAction(TYPE.RESET_FILTERS),
    keepFilters: (props) => FactoryAction(TYPE.KEEP_FILTERS, props),
};

export const SelectorsStore = {
    isFetching: (store) => store[NAME].isFetching,
    isFetchingSendMail: (store) => store[NAME].isFetchingSendMail,
    getSwiftMessages: (store) => store[NAME].swiftMessages,
    getMessageDetail: (store) => store[NAME].messageDetail,
    getOffset: (store) => store[NAME].offset,
    getFilters: (store) => store[NAME].filters,
    getMoreLines: (store) => store[NAME].moreLines,
    getTypes: (store) => store[NAME].types,
    getSubTypes: (store) => store[NAME].subTypes,
    getValidCurrencyTypes: (store) => store[NAME].validCurrencyTypes,
    getShowSendMailModal: (store) => store[NAME].showSendMailModal,
    keepFilters: (store) => store[NAME].keepFilters,
};
