import React from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { $class } from "~/util/style";

import Namespace from "./Link.scss";

export const { NAME, TYPE } = Namespace;
export const PROP = {
    types: {
        /** The address to link. TODO: should be required, check usage on project. */
        to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
        /** what to do when link is clicked, default: do nothing, just follow the link. */
        onClick: PropTypes.func,
        /** How much time should the link wait after click to re-enable itself. */
        timeout: PropTypes.number,
        className: PropTypes.string,
    },
    defaults: {
        to: undefined,
        onClick: null,
        timeout: 1000,
        className: null,
    },
};

const TAG = "a";

export function Component(props) {
    const { onClick, to, timeout, className, ...rest } = props;
    const [isDisabled, setDisabled] = React.useState(false);
    function handleClick(...args) {
        setTimeout(() => setDisabled(false), timeout);
        setDisabled(true);
        return onClick && onClick(...args);
    }
    const common = {
        ...rest,
        onClick: handleClick,
        className: $class("disabled", { when: isDisabled, className, Namespace }),
        "data-name": NAME,
        "data-type": TYPE,
    };
    // Obtain parse the url sent, using an old trick;
    const el = document.createElement("a"); // don't use the TAG constant here.
    el.href = to;
    // if url has a protocol, use a regular link instead of router's Link.
    const Tag = String(to).indexOf(el.protocol) === 0 ? TAG : Link;
    return <Tag {...common} href={(Tag === TAG && to) || undefined} to={(Tag !== TAG && to) || undefined} />;
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
