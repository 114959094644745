import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.SHOW_ABOUT_IBANCA_MODAL:
            return { ...storeProp, showAboutIbancaModal: action.showAboutIbancaModal };
        default:
            return {
                ...storeProp,
            };
    }
};
