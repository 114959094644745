import React from "react";

import { PRODUCT_TYPE, EMPTY_STR } from "~/constants/index";
import * as UtilsConfig from "~/util/config";
import UtilFlag from "~/util/flag";
import { generateId as GenerateID } from "~/util/general";
import * as i18n from "~/util/i18n";

import Element from "~/components/Component";
import DataNumber from "~/components/DataNumber";
import GridLayout from "~/components/GridLayout";
import Image from "~/components/Image";
import Link from "~/components/Link";

import {
    accountItemProductTypes,
    urlRedirectByProductType,
    tableHeadersByProductType,
    modalFieldsByProductType,
    productsWithPaginatedOption,
} from "../constants";
import AccordionDetail from "./AccordionDetail";
import Style from "./AccountItem.rules.scss";

export const { NAME, NAME_MESSAGES } = Style;
export const PROP = {
    types: {},
    defaults: {},
};

export class Component extends React.Component {
    state = {
        increment: UtilsConfig.getInteger("client.position.rowsPerProduct"),
        maxRows: UtilsConfig.getInteger("client.position.rowsPerProduct"),
    };

    isPaginatedProduct = () => {
        const { productType } = this.props;
        const { maxRows } = this.state;
        return maxRows !== -1 && productsWithPaginatedOption.includes(productType);
    };

    setMaxRows = (currency) => {
        const { productsByCurrency } = this.props;
        const { maxRows, increment } = this.state;
        const size = productsByCurrency[currency].length;
        const nextSize = maxRows + increment;
        this.setState(() => ({ maxRows: size < nextSize ? -1 : nextSize }));
    };

    getItems = (currency) => {
        const { productsByCurrency, productType, resolveActions } = this.props;
        const { maxRows } = this.state;
        let items = productsByCurrency[currency];
        if (this.isPaginatedProduct()) {
            items = productsByCurrency[currency].slice(0, maxRows);
        }
        return items.map((product) => (
            <GridLayout
                className="body position-clickable"
                key={`movement-${product.productIdBantotal}`}
                onClick={() => {
                    this.handleOpenModal(
                        modalFieldsByProductType[productType],
                        product.othersFields,
                        currency,
                        this.getTitle(product),
                    );
                }}>
                {tableHeadersByProductType[productType].map((headerKey, index) => {
                    const dataValue = product.othersFields[headerKey.id];
                    if (headerKey.type === "amount") {
                        return (
                            <DataNumber
                                key={GenerateID()}
                                value={dataValue}
                                prefix={headerKey.forceCurrency ? headerKey.forceCurrency : currency}
                                className={index !== 0 ? "position-amount" : EMPTY_STR}
                            />
                        );
                    }
                    return <div key={GenerateID()}>{dataValue}</div>;
                })}
                {resolveActions && resolveActions(productType, product)}
            </GridLayout>
        ));
    };

    handleOpenModal = (fields, data, currency, title) => {
        const { handleOpenModal } = this.props;
        handleOpenModal(fields, data, currency, title);
    };

    getTitle = (product) => {
        let result = i18n.get("position.popup.productDetail");
        if (product.productType === PRODUCT_TYPE.CREDIT_CARD) {
            result = `${i18n.get("position.popup.productDetail")}: ${product.productAlias}`;
        }
        return result;
    };

    resolveFormat = (currency, groupByCurrency) => {
        const { productsByCurrency, productType, resolveActions } = this.props;
        if (accountItemProductTypes.includes(productType)) {
            // Formato Lista
            return (
                <div className="account-item">
                    {
                        <p>
                            <span>{i18n.get(`currency.label.${currency}`)}</span>
                            <Image src={`${UtilFlag.getFlag(currency)}.svg`} />
                        </p>
                    }
                    {productsByCurrency[currency].map(({ idProduct, productAlias, balance, hasPermission }) => (
                        <div className="account-item-balance">
                            <p>
                                {hasPermission ? (
                                    <Link key={idProduct} to={urlRedirectByProductType[productType].concat(idProduct)}>
                                        {productAlias}
                                        <span>
                                            <span className="bold">
                                                {i18n.get("accounts.movements.table.columnHeading.balance")}:{" "}
                                            </span>
                                            <DataNumber value={balance} prefix={currency} />
                                        </span>
                                    </Link>
                                ) : (
                                    <Link
                                        className="no-clickable"
                                        onClick={(event) => event.preventDefault()}
                                        key={idProduct}
                                        to="/">
                                        {productAlias}
                                        <span>
                                            <span className="bold">
                                                {i18n.get("accounts.movements.table.columnHeading.balance")}:{" "}
                                            </span>
                                            <DataNumber value={balance} prefix={currency} />
                                        </span>
                                    </Link>
                                )}
                            </p>
                        </div>
                    ))}
                </div>
            );
        }
        if (groupByCurrency) {
            const { totalByCurrency, productName } = this.props;
            return (
                <AccordionDetail
                    key={productType}
                    id={productType}
                    title={productName}
                    total={totalByCurrency[parseInt(currency, 10)].total}
                    className={`container-accordion-detail position_${productType}${groupByCurrency &&
                        "_groupByCurrency"}`}
                    currency={currency}
                    hasFlag>
                    {this.resolveFormat(currency, false)}
                </AccordionDetail>
            );
        }
        return (
            // Formato Tabla
            <React.Fragment>
                <GridLayout className="header">
                    {tableHeadersByProductType[productType].map((heading, index) => (
                        <div
                            key={heading.id}
                            className={heading.type === "amount" && index !== 0 ? "position-amount" : EMPTY_STR}>
                            {i18n.get("position.tables.headers.".concat(heading.id))}
                        </div>
                    ))}
                    {resolveActions && resolveActions(productType) && <div />}
                </GridLayout>
                {this.getItems(currency)}
                {this.isPaginatedProduct() && (
                    <Element
                        tag="span"
                        intersection-trigger="true"
                        key="more"
                        onClick={() => this.setMaxRows(currency)}
                        className="positionMoreMovements">
                        {i18n.get("position.tables.moreItems")}
                    </Element>
                )}
            </React.Fragment>
        );
    };

    render() {
        const { productsByCurrency, groupByCurrency } = this.props;
        const currencies = Object.keys(productsByCurrency);
        return (
            <section>
                {currencies.map((currency) => (
                    <div>{this.resolveFormat(currency, groupByCurrency)}</div>
                ))}
            </section>
        );
    }
}
export default Component;
