import React from "react";

import { replace as Replace } from "connected-react-router";
import { withFormik as WithFormik, Form, Field } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { EMPTY_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreForm, SelectorsAction as SelectorsActionForm } from "~/store/form";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionSettings } from "~/store/settings";
import { get as Get } from "~/util/i18n";
import * as i18n from "~/util/i18n";

import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import ConfirmationModal from "~/pages/_components/ConfirmationModal";
import TextField from "~/pages/_components/fields/TextField";

import Style from "./ChangeEmail.rules.scss";

const FORM_ID = "settings.changeEmail";

export const NAME = "ChangeEmailConfirmation";

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        isDesktop: PropTypes.bool.isRequired,
        isSubmitting: PropTypes.bool.isRequired,
        userFullName: PropTypes.string,
        environmentType: PropTypes.string,
        onUpdateEmailAccounts: PropTypes.bool,
        showModal: PropTypes.bool.isRequired,
    },
    defaults: {
        isDesktop: false,
        isSubmitting: false,
        userFullName: EMPTY_STR,
        environmentType: EMPTY_STR,
        onUpdateEmailAccounts: false,
        showModal: false,
    },
};

export function Component({ dispatch, isSubmitting, userFullName, showModal }) {
    function goToSettings() {
        dispatch(Replace("/settings"));
    }

    function handleBack() {
        dispatch(SelectorsActionSettings.updateMailPreviewCancel());
    }

    return (
        <React.Fragment>
            <div id={Style.ID}>
                <Form>
                    {showModal && (
                        <ConfirmationModal
                            show={showModal}
                            handleAcept={() => goToSettings()}
                            handleCancel={() => dispatch(SelectorsActionForm.toggleModal())}
                            idLabel="global.modal.confirmation.message"
                        />
                    )}
                    <div className="form--change_email">
                        <div className="form--change_email--title">
                            <I18n id="change.email.verify.label" />
                        </div>
                        <div className="container--data">
                            <p className="text-lead-title">
                                <span>{`${userFullName} ${Get("change.email.checkEmail.title")}`}</span>
                            </p>
                            <p>
                                <I18n id="change.email.checkEmail.description" />
                            </p>
                            <p className="text-lead-title">
                                <I18n id="change.email.resend.code.title" />
                            </p>
                            <p>
                                <I18n id="change.email.resend.code.description" />
                                <Button className="resend-code-button" onClick={handleBack}>
                                    {` ${Get("change.email.resend.code.description.link")}`}
                                </Button>
                            </p>

                            <div className="access-code">
                                <Field
                                    idForm={FORM_ID}
                                    name="mailCode"
                                    type="text"
                                    component={TextField}
                                    autoFocus
                                    hidePlaceholder
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form--change_email ">
                        <div />
                        <div className="buttons-container">
                            <Button
                                type="submit"
                                size="md"
                                variant="secondary"
                                disabled={isSubmitting}
                                onClick={() => dispatch(SelectorsActionForm.toggleModal())}>
                                <I18n id="global.cancel" />
                            </Button>
                            <Button type="submit" size="md" variant="primary" disabled={isSubmitting}>
                                <I18n id="global.continue" />
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        </React.Fragment>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (state) => ({
    showModal: SelectorsStoreForm.showModal(state),
    userFullName: SelectorsStoreSession.getUserFullName(state),
    environmentType: SelectorsStoreSession.getActiveEnvironmentType(state),
});

export default HighOrder(
    WithRouter,
    Connect(mapStateToProps),
    HighOrder.Resizable,
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            mailCode: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                mailCode: Yup.string().required(i18n.get(`${FORM_ID}.code.required`)),
            }),
        handleSubmit: ({ mailCode }, { props, ...formikBag }) => {
            const { environmentType, onUpdateEmailAccounts, dispatch } = props;
            if (onUpdateEmailAccounts) {
                dispatch(SelectorsActionSettings.updateMailAccounts({ mailCode, environmentType, formikBag }));
            } else {
                dispatch(SelectorsActionSettings.updateMail({ mailCode, environmentType, formikBag }));
            }
        },
    }),
)(Component);
