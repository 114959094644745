import React from "react";

import { withFormik as WithFormik, Form } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import { compose as Compose } from "redux";
import * as Yup from "yup";

import { EMPTY_STR } from "~/constants";
import { SelectorsAction as SelectorsActionSettings, SelectorsStore as SelectorsStoreSettings } from "~/store/settings";
import * as i18n from "~/util/i18n";

import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Token from "~/components/Token/Token";

import Style from "~/pages/settings/ChangeEmail.rules.scss";
import SelectorType from "~/pages/settings/changePersonalInformation/field/SelectorType";

import StyleForm from "./ChangeEmailDataAccounts.rules.scss";

const FORM_ID = "settings.changeEmail";
export const NAME = "ChangeEmailDataAccountsSendCode";

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        isSubmitting: PropTypes.bool.isRequired,
        onCancel: PropTypes.func.isRequired,
        listEmail: PropTypes.shape({}),
    },
    defaults: {
        isSubmitting: false,
        onCancel: null,
        listEmail: null,
    },
};

export function Component({ isSubmitting, onCancel, dispatch, listEmail }) {
    React.useEffect(() => {
        dispatch(SelectorsActionSettings.listEmailAccountsRequest());
    }, [dispatch]);

    return (
        <React.Fragment>
            <div id={Style.ID}>
                <Form noValidate="novalidate">
                    <div className="form--change_email">
                        <div className="form--change_email--title">
                            <I18n id="change.email.label" />
                        </div>
                        <div className="container--data">
                            <p className="text-description">
                                <I18n id="change.email.description.data.accounts" />
                            </p>
                            <div id={StyleForm.ID}>
                                <SelectorType
                                    idForm={FORM_ID}
                                    name="mail"
                                    hidePlaceholder
                                    options={listEmail}
                                    valueKey="id"
                                    labelKey="name"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form--change_email otp">
                        <div className="form--change_email--title">
                            <I18n id="title.form.token" />
                        </div>
                        <Token labelButton="global.send" isSubmitting={isSubmitting}>
                            <Button size="sm" variant="secondary" disabled={isSubmitting} onClick={onCancel}>
                                <I18n id="global.cancel" />
                            </Button>
                        </Token>
                    </div>
                </Form>
            </div>
        </React.Fragment>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    listEmail: SelectorsStoreSettings.getListEmail(store),
});

export default Compose(
    WithRouter,
    Connect(mapStateToProps),
    HighOrder.Resizable,
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => {
            return {
                mail: props.listEmail.filter((email) => email?.id === "emailAccount")?.[0]?.name,
                otp: EMPTY_STR,
            };
        },
        validationSchema: () =>
            Yup.object().shape({
                mail: Yup.string()
                    .trim()
                    .email(i18n.get("global.userconfiguration.emailIncorrectFormat"))
                    .required(i18n.get("global.userconfiguration.emailEmpty")),
                otp: Yup.string().required(i18n.get("form.credential.otp.required")),
            }),
        handleSubmit: ({ mail, otp }, formikBag) => {
            formikBag.props.dispatch(
                SelectorsActionSettings.sendMailCode({
                    mail,
                    otp,
                    formikBag,
                    validate: false,
                    changeDataAccounts: true,
                }),
            );
        },
    }),
)(Component);
