import React from "react";

import { push as Push } from "connected-react-router";
import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { compose as Compose } from "redux";

import { SCOPE } from "~/constants";
import Container from "~/containers/Internal/Dash";
import { SelectorsStore, SelectorsAction } from "~/store/migration";
import { Types as TypesHtmlElement, Defaults as DefaultsHtmlElement } from "~/util/prop/html-element";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import I18n from "~/components/I18n";

import CancelConfirmationModal from "~/pages/enrollment/_CancelConfirmationModal";

export const NAME = "ChangePhoneConfirmation";

export const PROP = {
    types: {
        ...TypesHtmlElement,
        ...TypesRedux,
        currentMobilePhone: PropTypes.string.isRequired,
        newMobilePhone: PropTypes.string.isRequired,
        newMobilePhoneClean: PropTypes.string.isRequired,
    },
    defaults: {
        ...DefaultsHtmlElement,
        ...DefaultsRedux,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {
        show: false,
    };

    handleMigrationCancel = () => {
        const { dispatch } = this.props;
        dispatch(Push("/checkPhone"));
    };

    handleCancelation = () => {
        this.setState({ show: false });
    };

    showModal = () => {
        this.setState({ show: true });
    };

    render() {
        const { show } = this.state;
        const { fetching, currentMobilePhone, newMobilePhoneClean } = this.props;

        return (
            <React.Fragment>
                <CancelConfirmationModal
                    show={show}
                    handleCancelation={this.handleCancelation}
                    handleConfirmation={this.handleMigrationCancel}
                />
                <Container
                    name={NAME}
                    head-onClose={this.showModal}
                    wait={fetching}
                    scopeToShowNotification={SCOPE.CHANGE_PHONE_CONFIRMATION}>
                    <div className="login-header">
                        <span className="login-header-text">
                            <I18n id="migration.title" />
                        </span>
                    </div>
                    <div className="login-content">
                        <div className="login-row simple-row">
                            <span className="login-message">
                                <I18n id="migration.change.mobilePhone.message.part1" />
                            </span>
                            <span className="login-message">{currentMobilePhone}</span>

                            <span className="login-message">
                                <I18n id="migration.change.mobilePhone.message.part2" />
                            </span>

                            <span className="login-message">{newMobilePhoneClean}</span>

                            <span className="login-message">
                                <I18n id="migration.change.mobilePhone.message.part3" />
                            </span>
                        </div>
                        <div className="login-row main-row">
                            <Form className="login-form">
                                <div className="login-form-field">
                                    <Button
                                        className="login-form-button main-button"
                                        bsStyle="primary"
                                        label="global.continue"
                                        loading={false}
                                        type="submit"
                                    />
                                </div>
                                <div className="login-form-field">
                                    <Button
                                        className="login-form-button simple-button"
                                        label="global.cancel"
                                        onClick={this.handleMigrationCancel}
                                    />
                                </div>
                            </Form>
                        </div>
                    </div>
                </Container>{" "}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    newMobilePhone: SelectorsStore.mobilePhone(store), // This is the phone with prefix added (formatted)
    newMobilePhoneClean: SelectorsStore.mobilePhoneClean(store), // This is the phone typed by the user without prefix
    // This is the phone already stored in the database
    currentMobilePhone: SelectorsStore.currentMobilePhone(store),
    fetching: SelectorsStore.fetching(store),
});

export default Compose(
    Connect(mapStateToProps),
    WithFormik({
        handleSubmit: (data, formikBag) => {
            const { dispatch } = formikBag.props;
            const { newMobilePhone } = data;
            dispatch(SelectorsAction.changeMobilePhone({ newMobilePhone, formikBag }));
        },
    }),
)(Component);
