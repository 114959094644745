import React from "react";

import PropTypes from "prop-types";

import * as i18n from "~/util/i18n";

import Select from "~/components/Select";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";

export const NAME = "MultiSelectField";

export const PROP = {
    types: {
        className: PropTypes.string,
        allOption: PropTypes.string,
        field: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired,
        form: PropTypes.shape({
            setFieldValue: PropTypes.func.isRequired,
            errors: PropTypes.objectOf(PropTypes.string),
        }).isRequired,
        hideLabel: PropTypes.bool,
        hidePlaceholder: PropTypes.bool,
        options: PropTypes.arrayOf(PropTypes.string).isRequired,
        searchable: PropTypes.bool,
        i18nOptionsPrefix: PropTypes.string,
        handleChange: PropTypes.func,
        idForm: PropTypes.string.isRequired,
        textOptionsMap: PropTypes.instanceOf(Map),
    },
    defaults: {
        allOption: null,
        hideLabel: false,
        hidePlaceholder: false,
        i18nOptionsPrefix: "",
        searchable: false,
        className: "",
        handleChange: false,
        textOptionsMap: null,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        isFocused: false,
        selectedOptions: [],
    };

    handleChange = (selected) => {
        const { allOption, field, form, handleChange } = this.props;

        if (handleChange) {
            handleChange(selected);
        }

        let includesAll = false;
        let selectedOptions = [];
        for (let index = 0; index < selected.length && !includesAll; index += 1) {
            if (selected[index].value === allOption) {
                includesAll = true;
                selectedOptions = [allOption];
            } else {
                selectedOptions = selectedOptions.concat(selected[index].value);
            }
        }

        this.setState({ selectedOptions });

        form.setFieldValue(field.name, selectedOptions);
    };

    options = () => {
        const { selectedOptions } = this.state;
        const { allOption, options, i18nOptionsPrefix, textOptionsMap } = this.props;

        let optionsToShow = [];
        let optionLabel = null;
        // TODO: REVISAR
        const max = options.length;
        let option = null;
        let i = 0;
        for (i = 0; i < max; i += 1) {
            option = options[i];
            if (!textOptionsMap) {
                optionLabel = i18n.get(i18nOptionsPrefix + option);
            } else {
                optionLabel = textOptionsMap.get(option);
            }
            if (selectedOptions && selectedOptions.length) {
                optionsToShow.push({
                    label: optionLabel,
                    value: option,
                    disabled: allOption && option !== allOption && selectedOptions.includes(allOption),
                });
            } else {
                optionsToShow = optionsToShow.concat({
                    label: optionLabel,
                    value: option,
                });
            }
        }

        return optionsToShow;
    };

    render() {
        const {
            field,
            form: { errors },
            hideLabel,
            hidePlaceholder,
            idForm,
            searchable,
            className,
        } = this.props;
        const { isFocused, selectedOptions } = this.state;
        const hasError = errors[field.name];

        return (
            <div
                className={`form-group form-group--select ${hasError ? "has-error" : ""} ${
                    isFocused ? "has-focus" : ""
                }`}>
                {!hideLabel && <FieldLabel labelKey={`${idForm}.${field.name}.label`} />}

                <div className="input-group">
                    <Select
                        multi
                        className={className}
                        onChange={this.handleChange}
                        options={this.options()}
                        placeholder={hidePlaceholder ? "" : i18n.get(`${idForm}.${field.name}.placeholder`)}
                        searchable={searchable}
                        value={selectedOptions}
                        optionClassName="needsclick"
                    />
                </div>
                {hasError && <FieldError error={errors[field.name]} />}
            </div>
        );
    }
}

export default Component;
