import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    loadLayoutRequest: () => API.executeWithAccessToken(PATH.DESKTOP_LOAD_LAYOUT),
    saveLayoutRequest: (props) => API.executeWithAccessToken(PATH.DESKTOP_SAVE_LAYOUT, props),
};

export const SelectorsAction = {
    loadLayoutRequest: () => FactoryAction(TYPE.LOAD_LAYOUT_REQUEST),
    loadLayoutFailure: () => FactoryAction(TYPE.LOAD_LAYOUT_FAILURE),
    loadLayoutSuccess: (props) => FactoryAction(TYPE.LOAD_LAYOUT_SUCCESS, props),
    saveLayoutRequest: () => FactoryAction(TYPE.SAVE_LAYOUT_REQUEST),
    saveLayoutFailure: () => FactoryAction(TYPE.SAVE_LAYOUT_FAILURE),
    saveLayoutSuccess: (props) => FactoryAction(TYPE.SAVE_LAYOUT_SUCCESS, props),
    toggleIsEditable: () => FactoryAction(TYPE.TOGGLE_IS_EDITABLE),
    deleteWidget: (props) => FactoryAction(TYPE.DELETE_WIDGET, props),
    addWidget: (props) => FactoryAction(TYPE.ADD_WIDGET, props),
    setLayout: (props) => FactoryAction(TYPE.SET_LAYOUT, props),
    addAvailableWidget: (props) => FactoryAction(TYPE.ADD_AVAILABLE_WIDGET, props),
    removeAvailableWidget: (props) => FactoryAction(TYPE.REMOVE_AVAILABLE_WIDGET, props),
    toggleEnvironmentModal: () => FactoryAction(TYPE.TOGGLE_ENVIRONMENT_MODAL),
};

export const SelectorsStore = {
    getWidgets: (store) => store[NAME].widgets,
    getLayout: (store) => store[NAME].layout,
    isFetching: (store) => store[NAME].fetching,
    isEditable: (store) => store[NAME].isEditable,
    showEnvironmentModal: (store) => store[NAME].showEnvironmentModal,
};
