import React from "react";

import TextField from "./TextField";

export const NAME = "TextArea";

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    return <TextField renderAs="textarea" {...props} />;
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
