import { MODE } from "~/constants/form";
import { TYPE as TypesBackoffice } from "~/store/backoffice";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    const { data, transaction, idPaper } = action;

    switch (action.type) {
        case TYPE.READ_PAPER_REQUEST:
        case TYPE.PREVIEW_PAPER_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
                mode: MODE.EDIT,
            };
        case TYPE.READ_PAPER_SUCCESS:
            return {
                ...storeProp,
                debitAccounts: data.debitAccounts,
                isFetching: false,
                paper: data.paper,
                timezoneOffset: data.timezoneOffset,
            };
        case TYPE.PREVIEW_CANCEL_REQUEST: {
            return {
                ...storeProp,
                mode: MODE.EDIT,
            };
        }
        case TYPE.PREVIEW_PAPER_SUCCESS: {
            const { detail } = data;

            return {
                ...storeProp,
                amount: detail.amount,
                currency: detail.currency,
                debitAccountAlias: detail.debitAccountAlias,
                disclaimer: detail.disclaimer,
                idDebitAccount: detail.idDebitAccount,
                idPaper: detail.idPaper,
                isFetching: false,
                limitDate: detail.limitDate,
                mode: MODE.PREVIEW,
                orderCompleted: detail.orderCompleted,
                paperName: detail.paperName,
                prevMode: storeProp.mode,
            };
        }
        case TYPE.SEND_FORM_PAPER_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.SEND_FORM_PAPER_SUCCESS: {
            return {
                ...storeProp,
                transaction,
                isFetching: false,
                prevMode: storeProp.mode,
                mode: MODE.VIEW,
            };
        }
        case TYPE.TASK_FINISHED:
            return {
                ...storeProp,
                isFetching: false,
                mode: MODE.EDIT,
            };
        case TYPE.READ_TRANSACTION_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.READ_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                transaction,
            };
        case TYPE.CLOSE_TRANSACTION_PREVIEW:
            return {
                ...storeProp,
                fetching: false,
                mode: storeProp.prevMode,
            };
        case TYPE.SELECTED_ID_PAPER:
            return {
                ...storeProp,
                idPaperSelected: idPaper,
            };
        case TYPE.RESET_FORM:
            return {
                ...storeProp,
                ...PROP.defaults,
            };
        case TYPE.RESTART_FLOW:
            return PROP.defaults;
        case TypesBackoffice.BACKOFFICE_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                transaction,
            };
        default:
            return storeProp;
    }
};
