import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import {
    SelectorsAction as SelectorsActionComexImport,
    SelectorsStore as SelectorsStoreComexImport,
} from "~/store/comex/import";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import HighOrder from "~/components/HighOrder";

import FormEdit from "./Step1Edit/Step1Edit";
import FormPreview from "./Step2Preview/Step2Preview";
import FormView from "./Step3View/Step3View";

export const NAME = "CancellationCollection";

export const PROP = {
    types: {
        fetching: PropTypes.bool,
        mode: PropTypes.string.isRequired,
        ...TypesRedux,
    },
    defaults: {
        fetching: false,
        ...DefaultsRedux,
    },
};

export function Component(props) {
    const { dispatch, fetching, match, mode } = props;

    const handleBack = () => {
        const { history } = props;

        if (mode === MODE.VIEW) {
            dispatch(SelectorsActionComex.restartFlow());
            dispatch(SelectorsActionComexImport.restartFlow());
        } else if (mode === MODE.PREVIEW) {
            dispatch(SelectorsActionComex.toStepOne());
        } else {
            history.goBack();
        }
    };

    return (
        <Container
            head-onBack={handleBack}
            head-title={UtilsI18n.get("comex.cancellationCollection.title")}
            name={NAME}
            scopeToShowNotification={SCOPE.COMEX_BILLING_CANCELLATION}
            step={mode}
            wait={fetching}>
            {mode === MODE.EDIT && <FormEdit match={match} />}
            {mode === MODE.PREVIEW && <FormPreview />}
            {mode === MODE.VIEW && <FormView ticketConfirmation />}
        </Container>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        fetching: SelectorsStoreComexImport.getFetching(store),
        mode: SelectorsStoreComexImport.getMode(store),
    };
};

export default HighOrder(Connect(mapStateToProps))(Component);
