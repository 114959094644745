import React from "react";

import PropTypes from "prop-types";

export const NAME = "FieldHint";

export const PROP = {
    types: { text: PropTypes.string.isRequired },
    defaults: {},
};
export function Component(props) {
    const { text } = props;

    if (text) {
        // TODO: This component requires proper HTML ;)
        return (
            <div className="form-group-text">
                <span>{text}</span>
            </div>
        );
    }
    return null;
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
