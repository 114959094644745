import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "debitCard";

export const PATH = {
    DEBITCARDS_READ: "/debitCards.read",
    DEBITCARDS_LIST: "/debitCards.list",
    CHANGE_DEBIT_CARD_STATUS: "/debitCard.changeStatus",
};

export const TYPE = FactoryType(NAME, [
    "READ_DEBIT_CARD_REQUEST",
    "READ_DEBIT_CARD_FAILURE",
    "READ_DEBIT_CARD_SUCCESS",
    "LIST_REQUEST",
    "LIST_FAILURE",
    "LIST_SUCCESS",
    "CHANGE_STATUS_REQUEST",
    "CHANGE_STATUS_FAILURE",
    "CHANGE_STATUS_SUCCESS",

    "CLEAN_UP",
]);

export const PROP = {};

PROP.members = {
    debitCards: {
        isFetching: PropTypes.bool,
        list: PropTypes.array,
    },
};

PROP.types = {
    isFetching: PropTypes.bool,
    detail: PropTypes.shape({
        idProduct: PropTypes.string.isRequired,
        accountHolder: PropTypes.string,
        debitCardLimit: PropTypes.number,
        nextClosingDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.number]),
        lastPaymentDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.number]),
        lastPaymentAmount: PropTypes.number,
    }),
    debitCards: PropTypes.shape(PROP.members.debitCards),
    ...TypesRedux,
};

PROP.defaults = {
    isFetching: false,
    detail: null,
    debitCards: {
        isFetching: false,
        list: [],
    },
    ...DefaultsRedux,
};
