import React from "react";

import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { persistStore as PersistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import { setAuthToken as SetAPIAuthHeader } from "~/store/api";
import { SelectorsAction as StoreApp } from "~/store/app";

import Store from ".";

export const NAME = "Store/Provider";

export const PROP = {
    types: {
        loading: PropTypes.node,
        children: PropTypes.element.isRequired,
    },
    defaults: {
        loading: null,
    },
};

const persistor = PersistStore(Store, null, () => {
    const { user } = Store.getState().session || {};
    if (user && user.accessToken) {
        SetAPIAuthHeader(user.accessToken);
    }
    Store.dispatch(StoreApp.init());
});

function Content({ loading, children }) {
    const [isReady = false, setReady] = React.useState();
    React.useEffect(() => {
        setReady(true);
    }, []);
    return isReady ? children : loading;
}

export function Component(props) {
    const { loading, children } = props;
    return (
        <Provider store={Store}>
            <PersistGate loading={loading} persistor={persistor}>
                <Content loading={loading}>{children}</Content>
            </PersistGate>
        </Provider>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
