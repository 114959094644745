import React from "react";

import PropTypes from "prop-types";

export const NAME = "FieldError";

export const PROP = {
    types: { error: PropTypes.string },
    defaults: { error: null },
};

export function Component(props) {
    const { error } = props;

    if (!error) {
        return null;
    }

    if (typeof error === "object") {
        const errors = Object.keys(error);

        return (
            <div className="form-group-error">
                {errors.forEach((errorMessage) => (
                    <span>{errorMessage}</span>
                ))}
            </div>
        );
    }

    return (
        // TODO: This component requires proper HTML ;)
        <div className="form-group-error">
            <span>{error}</span>
        </div>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
