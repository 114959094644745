import { LOCATION_CHANGE } from "connected-react-router";

import { EMPTY_STR } from "~/constants";
import { MODE } from "~/constants/form";
import { TYPE as TypesBackoffice } from "~/store/backoffice";
import { shouldKeepFormState as ShouldKeepFormState } from "~/util/form";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            const shouldKeep = ShouldKeepFormState(storeProp, action.payload.location.pathname);
            if (shouldKeep) {
                return {
                    ...storeProp,
                };
            }
            return {
                ...PROP.defaults,
            };
        }
        case TYPE.LIST_PAYMENTS_PRE_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
                debitAccountList: [],
            };
        case TYPE.LIST_PAYMENTS_PRE_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
                debitAccountList: [],
            };
        case TYPE.LIST_PAYMENTS_PRE_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                debitAccountList: action.data.debitAccountList,
            };
        case TYPE.LIST_PAYMENTS_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
                payments: [],
                documentType: action.data.documentType,
                documentNumber: action.data.documentNumber,
                debitAccount: action.data.account,
                currency: action.data.currency,
                selectedPayments: [],
            };
        case TYPE.LIST_PAYMENTS_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
                payments: [],
                documentType: EMPTY_STR,
                documentNumber: EMPTY_STR,
                debitAccount: null,
                currency: EMPTY_STR,
            };
        case TYPE.LIST_PAYMENTS_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                payments: action.data.paymentList,
            };

        case TYPE.PAY_CUSTOMS_PREVIEW_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
                selectedPayments: action.selectedPayments,
            };
        case TYPE.PAY_CUSTOMS_PREVIEW_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
            };
        case TYPE.PAY_CUSTOMS_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
            };

        case TYPE.PAY_CUSTOMS_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.PAY_CUSTOMS_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
                mode: MODE.EDIT,
            };
        case TYPE.PAY_CUSTOMS_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                mode: MODE.VIEW,
                selectedPayments: action.data.paymentList,
                paymentItems: action.data.paymentList,
                totalAmount: action.data.totalAmount,
                referenceCode: action.data.transactionId,
                totalPayments: action.data.totalPayments,
                valueDate: action.data.valueDate,
                idTransaction: action.data.idTransaction,
                transaction: action.data,
            };
        case TYPE.FINISH_STEP_1:
            return {
                ...storeProp,
                isFetching: false,
                selectedPayments: action.selectedPayments,
                debitAccount: action.debitAccount,
                currency: action.currency,
                mode: MODE.PREVIEW,
            };
        case TYPE.PAY_CUSTOMS_LOAD_TICKET_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.PAY_CUSTOMS_LOAD_TICKET_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
            };
        case TYPE.PAY_CUSTOMS_LOAD_TICKET_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                transaction: action.data,
                mode: MODE.VIEW,
            };
        case TYPE.PAY_CUSTOMS_GO_TO_STEP_1:
            return {
                ...storeProp,
                mode: MODE.EDIT,
            };
        case TYPE.PAY_CUSTOMS_SIGN_TRANSACTION_PREVIEW:
            return {
                ...storeProp,
                prevMode: storeProp.mode,
                mode: MODE.PREVIEW,
            };
        case TYPE.PAY_CUSTOMS_SIGN_TRANSACTION_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.PAY_CUSTOMS_SIGN_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                mode: MODE.VIEW,
                prevMode: storeProp.mode,
                transaction: action.transaction,
            };
        case TYPE.PAY_CUSTOMS_SIGN_TRANSACTION_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
            };
        case TYPE.RESTART_FLOW:
            return PROP.defaults;
        case TypesBackoffice.BACKOFFICE_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                mode: MODE.VIEW,
                transaction: action.transaction,
            };
        default:
            return storeProp;
    }
};
