import React from "react";

import { goBack as GoBack } from "connected-react-router";

import { Form, Field, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE, LEVEL } from "~/constants";
import {
    SelectorsStore as SelectorsStoreUploadSuppliers,
    SelectorsAction as SelectorsActionUploadSuppliers,
} from "~/store/factoring/uploadSuppliers";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionMultilineFile } from "~/store/multilinefile";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";

import UploadSuppliersDisplayList from "../_components/UploadSuppliersDisplayList";
import Style from "./Step1Edit.rules.scss";
import FileUploadSuppliers from "./_FileUploadSuppliers";

export const { NAME } = Style;

export const PROP = {
    types: {
        isFetching: PropTypes.bool,
        suppliersList: PropTypes.arrayOf(
            PropTypes.shape({
                code: PropTypes.string,
                name: PropTypes.string,
                paymentType: PropTypes.string,
                account: PropTypes.string,
                currency: PropTypes.string,
            }),
        ),
    },
    defaults: {
        isFetching: false,
        suppliersList: [],
    },
};

export function Component(props) {
    const { isFetching, suppliersList } = props;

    return (
        <Form id={Style.ID}>
            <Field name="files" component={FileUploadSuppliers} value={suppliersList} />
            <UploadSuppliersDisplayList />
            <div className="number-of-suppliers">
                <span>{UtilsI18n.get("client.factoring.uploadSuppliers.footer.total.title")}</span>
                {`${suppliersList.length} ${UtilsI18n.get("client.factoring.uploadSuppliers.footer.total.label")}`}
            </div>
            <footer>
                {!isFetching && (
                    <React.Fragment>
                        <Button size="sm" variant="secondary" onClick={handleCancel}>
                            {UtilsI18n.get("global.cancel")}
                        </Button>
                        <Button type="submit" variant="primary">
                            {UtilsI18n.get("global.send")}
                        </Button>
                    </React.Fragment>
                )}
            </footer>
        </Form>
    );

    function handleCancel() {
        const { dispatch, idFile } = props;
        if (idFile) {
            dispatch(SelectorsActionMultilineFile.deleteFileRequest({ idFile }));
        }
        dispatch(GoBack());
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        isFetching: SelectorsStoreUploadSuppliers.isFetching(store),
        suppliersList: SelectorsStoreUploadSuppliers.getSuppliers(store),
        idFile: SelectorsStoreUploadSuppliers.getIdFile(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        handleSubmit: (data, formikBag) => {
            const { dispatch, suppliersList } = formikBag.props;
            if (suppliersList.length !== 0) {
                dispatch(
                    SelectorsActionUploadSuppliers.uploadSuppliersPreviewFormRequest({
                        suppliersList,
                    }),
                );
            } else {
                dispatch(
                    SelectorsActionNotification.showNotification({
                        message: UtilsI18n.get("client.factoring.uploadSuppliers.emptySuppliers"),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.FACTORING_UPLOAD_SUPPLIERS],
                    }),
                );
            }
        },
    }),
)(Component);
