import React from "react";

import QueryString from "query-string";
import { connect as Connect } from "react-redux";

import Container from "~/containers/Internal/Form/Simple";
import {
    SelectorsAction as SelectorsActionCreditCard,
    SelectorsStore as SelectorsCreditcards,
} from "~/store/creditCards/creditCard";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as CreditCardsUtils from "~/util/creditCards";
import * as DateUtils from "~/util/date";
import * as i18nUtils from "~/util/i18n";

import Button from "~/components/Button";
import ListItem from "~/pages/_components/listItem/ListItem";

export const NAME = "CreditCardsPaymentList";

export const PROP = {
    types: {},
    defaults: {},
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        const { dispatch } = this.props;
        const params = { parh: "/form/payCreditCard" };
        dispatch(SelectorsActionCreditCard.listRequest(params));
    }

    renderList = () => {
        const { list } = this.props;

        const title = i18nUtils.get("creditCards.list.item.title");
        const expiredText = i18nUtils.get("creditCards.list.item.expired");
        const expirationText = i18nUtils.get("creditCards.list.item.expiration");

        return list.map(({ idProduct, minimunPaymentUSD, productAlias, ...item }) => (
            <ListItem
                {...item}
                title={title}
                name={item.shortLabel}
                reference={item.holder}
                icon={CreditCardsUtils.getImageName(item.brand)}
                expiredText={expiredText}
                expirationText={expirationText}
                isExpired={DateUtils.isDateLessThanToday(item.expirationDate)}
                amount={minimunPaymentUSD}
                currency="2222" // TODO: revisar!!!
                path={{
                    pathname: "/form/payCreditCard",
                    search: QueryString.stringify({
                        creditCard: idProduct,
                    }),
                }}
            />
        ));
    };

    render() {
        const {
            list,
            isDesktop,
            fetching,
            activeEnvironment: {
                permissisons: { payThirdPartiesCreditCard },
            },
        } = this.props;

        const mobileCreditCard = !isDesktop && payThirdPartiesCreditCard !== undefined;

        return (
            <Container name={NAME} head-title="creditCardsPayment.list.title" wait={fetching}>
                <div className="above-the-fold">
                    {list.length ? (
                        <div className="table  table--products">
                            <div className="table-body"> {this.renderList()}</div>
                        </div>
                    ) : (
                        <p>{i18nUtils.get("creditCardsPayment.list.noItems")}</p>
                    )}
                    {mobileCreditCard && (
                        <React.Fragment>
                            <Button
                                className="btn-outline btn-block"
                                href="/form/payThirdPartiesCreditCard/"
                                image="plus.svg"
                                label="creditCard.payment.other"
                            />
                        </React.Fragment>
                    )}
                </div>
            </Container>
        );
    }
}

export default Connect((state) => ({
    list: SelectorsCreditcards.getList(state),
    fetching: SelectorsCreditcards.isFetching(state),
    activeEnvironment: SelectorsStoreSession.getActiveEnvironment(state),
}))(Component);
