import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "notification";

export const TYPE = FactoryType(NAME, ["SHOW_NOTIFICATION", "REMOVE_NOTIFICATION"]);

export const PROP = {};

PROP.types = {
    message: PropTypes.any,
    level: PropTypes.any,
    scopes: PropTypes.array,
    remove: PropTypes.boolean,
    autoDismiss: PropTypes.number,
    children: PropTypes.object,
    ...TypesRedux,
};

PROP.defaults = {
    message: null,
    level: null,
    scopes: [],
    autoDismiss: 5,
    children: {},
    remove: false,
    ...DefaultsRedux,
};
