import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreSettings, SelectorsAction as SelectorsActionSettings } from "~/store/settings";

import Ticket from "~/components/Ticket/Ticket";

import ChangeMobilePhoneContent from "./ChangeMobilePhoneContent";

export const NAME = "ChangeMobilePhoneTicket";

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        transaction: PropTypes.shape({
            idTransaction: PropTypes.string,
            idTransactionStatus: PropTypes.string,
            valueDateAsString: PropTypes.string,
            executing: PropTypes.bool,
        }).isRequired,
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        transaction: null,
        ticketConfirmation: false,
    },
};
export function Component({ dispatch, ...props }) {
    const { transaction, ticketConfirmation } = props;

    function handleReject(formikBag) {
        const { idTransaction } = transaction;

        dispatch(
            SelectorsActionSettings.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        dispatch(SelectorsActionSettings.signTransactionPreview());
    }

    return (
        <Ticket
            {...props}
            ticketConfirmation={ticketConfirmation}
            transaction={transaction}
            hasMobileComponent
            handleReject={handleReject}
            handleSign={handleSign}>
            <ChangeMobilePhoneContent {...props} />
        </Ticket>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    transaction: SelectorsStoreSettings.getChangeTransactionData(store),
});

export default Connect(mapStateToProps)(Component);
