import { LOCATION_CHANGE } from "connected-react-router";
import {
    delay as Delay,
    call as Call,
    put as Put,
    takeLatest as TakeLatest,
    select as Select,
    take as Take,
} from "redux-saga/effects";

import { LEVEL, FX_BIOMETRIC, SCOPE, RESPONSE_TYPE, VALIDATION_ERROR, ID_FORM, ERROR_FORM } from "~/constants";
import {
    SelectorsAction as SelectorsActionBiometric,
    SelectorsStore as SelectorsStoreBiometric,
    TYPE as TYPE_BIOMETRIC,
    TYPE_VERIFY_BIOMETRIC,
} from "~/store/biometric";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import Store from "~/store";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as ConfigUtils from "~/util/config";
import DeviceUtils from "~/util/device";
import { downloadPdf as DownloadPdf } from "~/util/download";
import * as I18n from "~/util/i18n";
import UtilLodash from "~/util/lodash";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsMiddleware, SelectorsStore } from "./_selectors";

export default [
    // Request PREFERENTIAL TRADING PRICE PRE
    // It loads the FX screen
    TakeLatest(TYPE.REQUEST_FX, function* getPreferentialTradingPrice(props) {
        const myProps = { ...props };
        delete myProps.type;
        const response = yield Call(SelectorsMiddleware.preferentialTradingPrice, myProps);

        if (response && response.status === 200) {
            yield Put(SelectorsAction.successFX(response.data.data));
        }
    }),

    TakeLatest(LOCATION_CHANGE, function* locationChange({ payload }) {
        const [, route] = payload.location.pathname.split("/");
        if (route === ID_FORM.PREFERENTIAL_TRADING_PRICE && payload.location.pathname.indexOf("/ticket") == -1) {
            yield Put(SelectorsAction.cleanUp());
            yield Put(SelectorsAction.requestFX());
            yield Put(SelectorsAction.requestCotizacion());
        }
    }),

    TakeLatest(TYPE.REQUEST_COTIZACION, function* getPreferentialTradingPriceRate(props) {
        const myProps = { ...props };
        delete myProps.type;
        while (true) {
            const pollCotizacion = yield Select(SelectorsStore.pollCotizacion);
            if (pollCotizacion === false) {
                break;
            }

            const response = yield Call(SelectorsMiddleware.getPreferentialTradingPriceRate, myProps);
            if (response && response.status === 200) {
                yield Put(SelectorsAction.successCotizacion(response.data.data));
            }

            yield Delay(ConfigUtils.getTimeInMillis("client.preferentialTrading.refreshTime", 20000)); // default 20s
        }
    }),

    TakeLatest(TYPE.HISTORICAL_RATES_REQUEST, function* getHistoricalRates(props) {
        const { days } = props;
        const response = yield Call(SelectorsMiddleware.getHistoricalRate, { days });
        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(SelectorsAction.historicalRatesFailure());
        } else {
            const { data } = response.data;
            yield Put(SelectorsAction.historicalRatesSuccess(data));
        }
    }),

    TakeLatest(TYPE.BULLETIN_LIST_REQUEST, function* getBulletinList() {
        const response = yield Call(SelectorsMiddleware.getBulletinList);
        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(SelectorsAction.bulletinListFailure());
        } else {
            const { data } = response.data;
            yield Put(SelectorsAction.bulletinListSuccess(data));
        }
    }),

    TakeLatest(TYPE.BULLETIN_PDF_DOWNLOAD_REQUEST, function* getBulletinPdfDownload(props) {
        const response = yield Call(SelectorsMiddleware.bulletinPdfDownload, props);
        if (response.type === RESPONSE_TYPE.WARNING) {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.PREFERENTIAL_TRADING_PRICE],
                }),
            );
            yield Put(SelectorsAction.bulletinPdfDownloadFailure);
        } else {
            const { content, fileName } = response.data.data;
            yield Put(SelectorsAction.bulletinPdfDownloadSuccess());
            DownloadPdf(fileName, content);
        }
    }),

    // Request PREFERENTIAL TRADING PRICE PREVIEW
    // It loads the PREVIEW screen or fails, showing the errors in the form
    TakeLatest(TYPE.REQUEST_PREVIEW_FX, function* getPreferentialTradingPricePreview(props) {
        const myProps = { ...props };
        delete myProps.type;
        const response = yield Call(SelectorsMiddleware.getPreferentialTradingPricePreview, myProps);
        if (response.type === RESPONSE_TYPE.WARNING) {
            if (response.data.data.currencyChanged) {
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: response.data.data.NO_FIELD,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.PREFERENTIAL_TRADING_PRICE],
                    }),
                );
            } else if (response.data.data.NO_FIELD) {
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: response.data.data.NO_FIELD,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.PREFERENTIAL_TRADING_PRICE],
                    }),
                );
            } else if (response.data.message) {
                const message =
                    response.data.code === ERROR_FORM ? response.data.message : I18n.get("forms.fieldsErrors");
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.PREFERENTIAL_TRADING_PRICE],
                    }),
                );
            } else {
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: I18n.get("global.unexpectedError"),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.PREFERENTIAL_TRADING_PRICE],
                    }),
                );
            }

            yield Put(SelectorsAction.errorPreview(response.data));
        } else if (response && response.status === 200) {
            yield Put(SelectorsAction.successPreview(response.data));
        } else {
            // There was an error
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.NO_FIELD,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.PREFERENTIAL_TRADING_PRICE],
                }),
            );
            yield Put(SelectorsAction.errorPreview(response.data));
        }
    }),

    // Request PREFERENTIAL TRADING PRICE SEND
    // It loads the SEND screen
    TakeLatest(TYPE.REQUEST_SEND_FX, function* getPreferentialTradingPriceSend(props) {
        const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);
        const { formikBag } = props;

        if (isTrusted) {
            yield Put(SelectorsActionBiometric.verifyBiometric({ liveness: true, pathFrom: FX_BIOMETRIC }));
            yield Take([
                TYPE_BIOMETRIC.VERIFY_BIOMETRIC_ERROR,
                TYPE_BIOMETRIC.VERIFY_BIOMETRIC_SUCCESS,
                TYPE_BIOMETRIC.VERIFY_BIOMETRIC_CANCEL,
                TYPE_BIOMETRIC.NO_ENROLLED_FINGERPRINT,
            ]);
            const statusVerifyBiometric = yield Select(SelectorsStoreBiometric.statusVerifyBiometric);
            switch (statusVerifyBiometric) {
                case TYPE_VERIFY_BIOMETRIC.CANCEL:
                case TYPE_VERIFY_BIOMETRIC.NO_ENROLLED_FINGERPRINT:
                    yield Put(SelectorsAction.errorSend({ data: "Cancel" }));
                    return undefined;
                case TYPE_VERIFY_BIOMETRIC.ERROR:
                    yield Put(SelectorsAction.errorSend({ data: I18n.get("verify.biometric.error") }));
                    yield Put(
                        SelectorsActionNotification.showNotification({
                            message: I18n.get("verify.biometric.error"),
                            level: LEVEL.ERROR,
                            scopes: [SCOPE.PREFERENTIAL_TRADING_PRICE],
                        }),
                    );
                    return undefined;
                default:
                    yield Put(SelectorsAction.renderFormWaiter());
                    break;
            }
        }

                let { token, ...rest } = props;
        let response;

        const { webToken } = Store.getState().session;

        const tokenToUse = token ? token : webToken;

        if (UtilLodash.isEmpty(tokenToUse)) {
            response = {
                data: {
                    code: VALIDATION_ERROR,
                    message: I18n.get(`returnCode.${VALIDATION_ERROR}`),
                    data: {
                        otp: I18n.get("returnCode.COR027W"),
                    },
                },
                status: 200,
                type: RESPONSE_TYPE.WARNING,
            };
        } else {
            delete rest.type;

            response = yield Call(SelectorsMiddleware.getPreferentialTradingPriceSend, { _otp: tokenToUse, ...rest });
        }

        if (response.type === RESPONSE_TYPE.WARNING) {
            const { data } = response.data;

            let message;
            if (data.NO_FIELD) {
                message = data.NO_FIELD;
            } else {
                message = response.data.message;
            }
            yield Put(
                SelectorsActionNotification.showNotification({
                    message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.PREFERENTIAL_TRADING_PRICE],
                }),
            );
            formikBag.setErrors(data);
            yield Put(SelectorsAction.errorSend(response.data));
        } else if (response && response.status === 200) {
            const transactionResponse = yield Call(SelectorsMiddlewareForm.readTransaction, {
                idTransactionToRead: response.data.idTransaction,
            });
            const { transaction } = transactionResponse.data.data;

            yield Put(SelectorsAction.successSend({ transaction }));
        } else {
            // There was an error
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: response.data.NO_FIELD,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.PREFERENTIAL_TRADING_PRICE],
                }),
            );
            yield Put(SelectorsAction.errorSend(response.data));
        }
        return null;
    }),

    TakeLatest(TYPE.DISCLAIMER, function* getPreferentialTradingPriceDisclaimer(props) {
        const myProps = { ...props };
        delete myProps.type;
        const response = yield Call(SelectorsMiddleware.getPreferentialTradingPriceDisclaimer, myProps);
        if (response && response.status === 200) {
            const { data } = response.data;
            const { content, fileName } = data;
            if (DeviceUtils.isMobileNative()) {
                try {
                    yield Put(SelectorsAction.successPdf());
                    yield Call(window.pdf.save, data);
                } catch (error) {
                    yield Put(SelectorsAction.errorPdf());
                    yield Put(
                        SelectorsActionNotification.showNotification({
                            message: I18n.get("preferentialTradingPrice.pdf.download.error"),
                            level: LEVEL.ERROR,
                            scopes: [SCOPE.PREFERENTIAL_TRADING_PRICE],
                        }),
                    );
                }
            } else {
                yield Put(SelectorsAction.successPdf());
                DownloadPdf(fileName, content);
            }
        } else {
            yield Put(SelectorsAction.errorPdf());
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: I18n.get("preferentialTradingPrice.pdf.download.error"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.PREFERENTIAL_TRADING_PRICE],
                }),
            );
        }
    }),
    TakeLatest(TYPE.READ_FX_TICKET_REQUEST, function* readSettingsTransaction(props) {
        const { idTransaction } = props;
        const transactionResponse = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });
        if (transactionResponse.type === RESPONSE_TYPE.WARNING) {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: transactionResponse.data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.PREFERENTIAL_TRADING_PRICE],
                }),
            );
        } else {
            yield Put(SelectorsAction.readFxTicketSuccess({ transaction: transactionResponse.data.data.transaction }));
        }
    }),
];
