import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    changePhoneStep1: (props) => API.executeAnonymous(PATH.CHANGE_PHONE_STEP1, props),
    changePhoneStep2: (props) => {
        const { _exchangeToken } = props;
        return API.executeWithExchangeToken(PATH.CHANGE_PHONE_STEP2, props, _exchangeToken);
    },
    changePhoneStep3: (props) => {
        const { _exchangeToken } = props;

        return API.executeWithExchangeToken(PATH.CHANGE_PHONE_STEP3, props, _exchangeToken);
    },
    registerBiometricChangePhone: (props) => {
        const { _exchangeToken } = props;
        return API.executeWithExchangeToken(PATH.REGISTER_BIOMETRIC, props, _exchangeToken);
    },
};

export const SelectorsAction = {
    changePhoneStep1: (props) => FactoryAction(TYPE.CHANGE_PHONE_STEP1_REQUEST, props),
    errorChangePhoneStep1: (props) => FactoryAction(TYPE.CHANGE_PHONE_STEP1_ERROR, props),
    successChangePhoneStep1: (props) => FactoryAction(TYPE.CHANGE_PHONE_STEP1_SUCCESS, props),

    changePhoneStep2: (props) => FactoryAction(TYPE.CHANGE_PHONE_STEP2_REQUEST, props),
    errorChangePhoneStep2: (props) => FactoryAction(TYPE.CHANGE_PHONE_STEP2_ERROR, props),
    successChangePhoneStep2: (props) => FactoryAction(TYPE.CHANGE_PHONE_STEP2_SUCCESS, props),

    changePhoneStep3: (props) => FactoryAction(TYPE.CHANGE_PHONE_STEP3_REQUEST, props),
    errorChangePhoneStep3: (props) => FactoryAction(TYPE.CHANGE_PHONE_STEP3_ERROR, props),
    successChangePhoneStep3: (props) => FactoryAction(TYPE.CHANGE_PHONE_STEP3_SUCCESS, props),

    registerBiometric: (props) => FactoryAction(TYPE.CHANGE_MY_PHONE_REGISTER_BIOMETRIC_REQUEST, props),
    errorRegisterBiometric: (props) => FactoryAction(TYPE.CHANGE_MY_PHONE_REGISTER_BIOMETRIC_REQUEST_ERROR, props),
    successRegisterBiometric: (props) => FactoryAction(TYPE.CHANGE_MY_PHONE_REGISTER_BIOMETRIC_REQUEST_SUCCESS, props),

    cleanUp: (props) => FactoryAction(TYPE.CLEAN_UP, props),
};

export const SelectorsStore = {
    fetching: (store) => store[NAME].fetching,
    exchangeToken: (store) => store[NAME].exchangeToken,
    username: (store) => store[NAME].username,
    facephi: (store) => store[NAME].facephi,
    isChangeMyPhone: (store) => store[NAME].isChangeMyPhone,
};
