import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    recoveryPassStep1: (props) => API.executeAnonymous(PATH.SESSION_RECOVER_PASSWORD_STEP1, props),
    recoveryPassStep2: (props) => API.executeAnonymous(PATH.SESSION_RECOVER_PASSWORD_STEP2, props),
    recoveryPassStep3: (props, exchangeToken) =>
        API.executeWithExchangeToken(PATH.SESSION_RECOVER_PASSWORD_STEP3, props, exchangeToken),
};

export const SelectorsAction = {
    recoverPassCleanUp: () => FactoryAction(TYPE.RECOVER_PASS_CLEAN),
    recoverPassStep1Request: (props) => FactoryAction(TYPE.RECOVER_PASS_STEP1_REQUEST, props),
    recoverPassStep1Failure: (props) => FactoryAction(TYPE.RECOVER_PASS_STEP1_FAILURE, props),
    recoverPassStep1Success: (props) => FactoryAction(TYPE.RECOVER_PASS_STEP1_SUCCESS, props),
    recoverPassStep2Request: (props) => FactoryAction(TYPE.RECOVER_PASS_STEP2_REQUEST, props),
    recoverPassStep2Failure: (props) => FactoryAction(TYPE.RECOVER_PASS_STEP2_FAILURE, props),
    recoverPassStep2Success: (props) => FactoryAction(TYPE.RECOVER_PASS_STEP2_SUCCESS, props),
    recoverPassStep3Request: (props) => FactoryAction(TYPE.RECOVER_PASS_STEP3_REQUEST, props),
    recoverPassStep3Failure: (props) => FactoryAction(TYPE.RECOVER_PASS_STEP3_FAILURE, props),
    recoverPassStep3Success: (props) => FactoryAction(TYPE.RECOVER_PASS_STEP3_SUCCESS, props),
    rememberUsername: (props) => FactoryAction(TYPE.RECOVER_PASS_REMEMBER_USERNAME, props),
};

export const SelectorsStore = {
    getExchangeToken: (store) => store[NAME].exchangeToken,
    getVerificationCode: (store) => store[NAME].verificationCode,
    isFetching: (store) => store[NAME].fetching,
};
