import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { CDP_CURRENCY } from "~/constants";
import { SelectorsStore as StoreI18n } from "~/store/i18n";

import Box from "~/components/Box";
import DataDate from "~/components/DataDate";
import DataNumber from "~/components/DataNumber";
import GridLayout from "~/components/GridLayout";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";

import Style from "./_Collections.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        collections: PropTypes.shape({
            list: PropTypes.array.isRequired,
            totalUSD: PropTypes.string.isRequired,
            totalUYU: PropTypes.string.isRequired,
        }).isRequired,
        accountCurrency: PropTypes.string.isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const { collections, accountCurrency, i18n } = props;
    const { list, totalUSD, totalUYU } = collections;

    const COLUMN_HEADINGS = [i18n.collectingDate, i18n.paymentDate, i18n.amount];

    const totalAmount = accountCurrency === CDP_CURRENCY.UYU ? totalUYU : totalUSD;

    return (
        <div id={Style.ID}>
            <div className="totalHeader">
                <label>{i18n.infoLabel}</label>
                <div>
                    <I18n id="client.account.collecting.total" />
                    <DataNumber value={totalAmount} prefix={accountCurrency} />
                </div>
            </div>
            <GridLayout className="header">
                {COLUMN_HEADINGS.map((heading, index) => (
                    <Box justify={index === 2 && "end"}> {heading}</Box>
                ))}
            </GridLayout>
            {renderListItems()}
        </div>
    );

    function renderListItems() {
        return list.map(({ amountUYU, amountUSD, collectionDate, paymentDate }) => {
            const amount = accountCurrency === CDP_CURRENCY.UYU ? amountUYU : amountUSD;
            return (
                <GridLayout>
                    <div>
                        <DataDate value={collectionDate} />
                    </div>
                    <div>
                        <DataDate value={paymentDate} />
                    </div>
                    <Box justify="end">
                        <DataNumber value={amount} prefix={accountCurrency} />
                    </Box>
                </GridLayout>
            );
        });
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        i18n: {
            collectingDate: StoreI18n.getMessage(store, "client.account.collecting.collectingDate"),
            paymentDate: StoreI18n.getMessage(store, "client.account.collecting.paymentDate"),
            amount: StoreI18n.getMessage(store, "client.account.collecting.amount"),
            infoLabel: StoreI18n.getMessage(store, "client.account.collecting.infoLabel"),
        },
    };
};

export default HighOrder(Connect(mapStateToProps))(Component);
