import * as dateUtils from "~/util/date";
// sg el brand viene del core
const creditCardBrandsValidations = {
    amex: /^3[47]/,
    visa: /^4/,
    mastercard: /^5[1-5]/,
};

const mapImageNames = {
    visa: "visa",
    master: "mastercard",
    amex: "amex",
    indeterminate: "indeterminate",
};

export const getImageName = (brand) => mapImageNames[brand.toLowerCase()];

export const creditCardHasExpiredPayment = (expirationDate) => dateUtils.isDateLessThanToday(expirationDate);

export const detectBrand = (number) =>
    Object.keys(creditCardBrandsValidations).find((key) => creditCardBrandsValidations[key].test(number));
