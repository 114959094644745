import React from "react";

import { push as Push } from "connected-react-router";
import { Field, Form, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";

import { EVENT_BACKBUTTON, SCOPE } from "~/constants";
import Container from "~/containers/External/Form";
import { SelectorsStore, SelectorsAction, PROP as PROP_STORE } from "~/store/generalConditions";
import { SelectorsAction as SelectorsActionSession } from "~/store/session";
import DeviceUtils from "~/util/device";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";

import CancelConfirmationModal from "~/pages/enrollment/_CancelConfirmationModal";

const FORM_ID = "generalConditions";

export const NAME = "GeneralConditionsLogin";

export const PROP = {
    types: {
        ...TypesRedux,
        ...PROP_STORE.types,
    },
    defaults: {
        ...PROP_STORE.defaults,
        ...DefaultsRedux,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    state = {
        checked: false,
        show: false,
    };

    componentDidMount() {
        if (DeviceUtils.isMobileNative()) {
            document.addEventListener(EVENT_BACKBUTTON, this.showModal, false);
        }
        const { body } = this.props;
        if (!body) {
            this.generalConditionsPre();
        }
    }

    componentWillUnmount() {
        if (DeviceUtils.isMobileNative()) {
            document.removeEventListener(EVENT_BACKBUTTON, this.showModal, false);
        }
    }

    handleConfirmation = () => {
        const { dispatch, isEnvironmentChanging } = this.props;
        dispatch(SelectorsAction.generalConditionsFetching());

        if (isEnvironmentChanging) {
            dispatch(Push("/desktop"));
        } else {
            dispatch(SelectorsActionSession.logout());
        }
    };

    handleCancelation = () => {
        this.setState({ show: false });
    };

    showModal = () => {
        this.setState({ show: true });
        return false;
    };

    generalConditionsPre = () => {
        const { dispatch, body } = this.props;
        if (body === null) {
            dispatch(SelectorsAction.generalConditionPre());
        }
    };

    render() {
        const { checked, show } = this.state;
        const { body, fetching } = this.props;
        return (
            <React.Fragment>
                <CancelConfirmationModal
                    show={show}
                    handleCancelation={this.handleCancelation}
                    handleConfirmation={this.handleConfirmation}
                />
                <Container
                    title="generalConditions.title"
                    data-name={NAME}
                    wait={fetching}
                    head-onClose={this.showModal}
                    scopeToShowNotification={SCOPE.GENERAL_CONDITIONS_LOGIN}>
                    <div className="contract">
                        <Form className="contract-form">
                            <div className="contract-content">
                                <div className="contract-block">
                                    <div className="contract-block-content">
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: body,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="contract-block">
                                    <label className="contract-input">
                                        <span>
                                            <I18n id="generalConditions.conditionCheck.text" />
                                        </span>
                                        <Field
                                            idform={FORM_ID}
                                            onClick={() =>
                                                this.setState((prevState) => ({
                                                    checked: !prevState.checked,
                                                }))
                                            }
                                            name="acceptConditions"
                                            hideLabel
                                            value={checked}
                                            type="checkbox"
                                            className="checkmark"
                                        />

                                        <span className="checkmark" />
                                    </label>
                                </div>
                            </div>

                            <div className="contract-footer">
                                <Field idform={FORM_ID} name="checked" hideLabel value={checked} type="hidden" />
                                <Button
                                    className="contract-button main-button"
                                    bsStyle="primary"
                                    label="global.continue"
                                    loading={false}
                                    type="submit"
                                />
                            </div>
                        </Form>
                    </div>
                    <div className="enrollment-content-dialog">
                        <span className="enrollment-content-dialog-text" />
                    </div>
                </Container>{" "}
            </React.Fragment>
        );
    }
}

export default HighOrder(
    Connect((store) => ({
        latestNews: SelectorsStore.latestNews(store),
        body: SelectorsStore.body(store),
        fetching: SelectorsStore.fetching(store),
        isEnvironmentChanging: SelectorsStore.isEnvironmentChanging(store),
        idCondition: SelectorsStore.idCondition(store),
        environment: SelectorsStore.environment(store),
        rememberEnvironment: SelectorsStore.rememberEnvironment(store),
        suggestMigration: SelectorsStore.suggestMigration(store),
    })),
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        handleSubmit: (data, formikBag) => {
            const { dispatch } = formikBag.props;
            const { acceptConditions } = data;
            dispatch(SelectorsAction.generalConditionSign({ acceptConditions, formikBag }));
        },
    }),
)(Component);
