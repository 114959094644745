import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { DOT, SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import { SelectorsAction as SelectorsActionComex, SelectorsStore as SelectorsStoreComex } from "~/store/comex";
import {
    SelectorsAction as SelectorsActionComexImport,
    SelectorsStore as SelectorsStoreComexImport,
} from "~/store/comex/import";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import HighOrder from "~/components/HighOrder";

import Style from "./CreditLetter.rules.scss";
import Step1EditLetter from "./Step1Edit/Step1EditLetter";
import Step2Preview from "./Step2Preview/Step2Preview";
import Step3View from "./Step3View/Step3View";

export const { NAME } = Style;

export const PROP = {
    types: {
        fetching: PropTypes.bool.isRequired,
        setFieldValue: PropTypes.func.isRequired,
        validateForm: PropTypes.func.isRequired,
        ...TypesRedux,
    },
    defaults: {
        ...DefaultsRedux,
    },
};

const FORM_ID = "comex.imports.creditLetter.form";

export const CREDENTIAL_TYPE_OTP = "otp";

const formatNumber = (decimalSeparator, thousandSeparator, decimalScale, num) => {
    const numParts = num
        .toFixed(decimalScale)
        .replace(DOT, decimalSeparator)
        .split(decimalSeparator);
    let l = numParts[0].length - 3;
    while (l > 0) {
        numParts[0] = `${numParts[0].slice(0, l)}${thousandSeparator}${numParts[0].slice(l)}`;
        l -= 3;
    }
    return `${numParts[0]}${decimalSeparator}${numParts[1]}`;
};

export function Component(props) {
    const { banks, dispatch, fetching, mode } = props;

    const handleBack = () => {
        const { history, showBankSearch } = props;

        if (mode === MODE.VIEW) {
            dispatch(SelectorsActionComex.restartFlow());
            dispatch(SelectorsActionComexImport.restartFlow());
        } else if (mode === MODE.PREVIEW) {
            dispatch(SelectorsActionComex.toStepOne());
        } else if (showBankSearch) {
            if (banks.length > 0) {
                dispatch(SelectorsActionComex.setBankSearchInfo({ banks: [] }));
            } else {
                dispatch(SelectorsActionComex.setBankSearchInfo({ showBankSearch: false }));
            }
        } else {
            history.goBack();
        }
    };

    return (
        <Container
            className={`CreditLetter ${banks && banks.length > 0 && "result-bank"}`}
            head-onBack={handleBack}
            head-title={`${FORM_ID}.title`}
            image="businessPortfolio.svg"
            name={NAME}
            scopeToShowNotification={SCOPE.COMEX_IMPORT_CREDIT_LETTER_OPEN}
            step={mode}
            wait={fetching}>
            {mode === MODE.EDIT && <Step1EditLetter formatNumber={formatNumber} />}
            {mode === MODE.PREVIEW && <Step2Preview formatNumber={formatNumber} />}
            {mode === MODE.VIEW && <Step3View ticketConfirmation />}
        </Container>
    );
}

const mapStateToProps = (store) => ({
    banks: SelectorsStoreComex.getBanks(store),
    fetching: SelectorsStoreComexImport.getFetching(store),
    mode: SelectorsStoreComexImport.getMode(store),
    showBankSearch: SelectorsStoreComex.getShowBankSearch(store),
});

export default HighOrder(Connect(mapStateToProps))(Component);
