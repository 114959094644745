import React from "react";

import ClassNames from "classnames";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { getInteger } from "~/util/config";
import { numberFormat as NumberFormatUtil, toNumber, stripNumber } from "~/util/number";

import NumberFormatInput from "~/components/NumberFormatInput";

import Namespaces from "./EditableDataNumber.scss";

export const { CLASS, NAME, TAG: EditableDataNumber } = Namespaces;

const DISPLAY_TYPE = { text: "text", input: "input" };

const EVENT_KEY = {
    enter: "Enter",
    spacebar: "Spacebar",
    space: " ",
};

export const PROP = {
    types: {
        /** Defines a custom class name for the element */
        className: PropTypes.string,
        /** Element Id */
        id: PropTypes.string,
        /** Defines language for amount internationalization */
        lang: PropTypes.string,
        /** Defines onBlur event callback */
        onBlur: PropTypes.func,
        /** Defines onChange event callback */
        onChange: PropTypes.func,
        /** Adds a prefix to the input */
        prefix: PropTypes.string,
        /** Defines if is editable */
        readOnly: PropTypes.bool,
        /** Defines possible input types */
        type: PropTypes.oneOf(["text", "tel", "password"]),
        /** Defines input value */
        value: PropTypes.number,
    },
    defaults: {
        className: null,
        id: null,
        lang: "string",
        onBlur: null,
        onChange: null,
        prefix: null,
        readOnly: false,
        type: "text",
        value: 0,
    },
};

export function Component({ className, id, lang, onBlur, onChange, prefix, readOnly, type, value }) {
    const { decimalSeparator, thousandSeparator } = NumberFormatUtil(lang);
    const [displayType, setDisplayType] = React.useState(DISPLAY_TYPE.text);

    React.useEffect(() => {
        if (!readOnly && displayType === DISPLAY_TYPE.input) {
            const elem = document.getElementById(id);
            elem.focus();
            elem.select();
        }
    }, [displayType, readOnly, id]);

    const handleBlur = (event) => {
        setDisplayType(DISPLAY_TYPE.text);
        if (onBlur) {
            onBlur(event);
        }
    };

    const handleClick = () => {
        if (!readOnly) {
            setDisplayType(DISPLAY_TYPE.input);
        }
    };

    const handleInputChange = ({ target }) => {
        let _value = target.value;
        let showValue = _value;

        const maxLength = getInteger("amount.length");
        _value = stripNumber(_value, decimalSeparator);
        // manage maxLength in pasted text
        if (_value.length > maxLength) {
            showValue = _value.slice(0, maxLength);
        }
        onChange(toNumber(showValue, decimalSeparator));
    };

    const handleKeyDown = (event) => {
        const { target } = event;
        if (
            !readOnly &&
            (event.key === EVENT_KEY.enter || event.key === EVENT_KEY.space || event.key === EVENT_KEY.spacebar)
        ) {
            if (target.tagName === "INPUT") {
                onBlur(event);
            } else {
                handleClick();
            }
        }
    };

    return (
        <EditableDataNumber
            name={NAME}
            className={ClassNames(
                CLASS,
                className,
                { "is-input": displayType === DISPLAY_TYPE.input },
                { "is-read-only": readOnly },
            )}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            tabIndex={0}>
            {prefix && <small className={ClassNames({ "p-1": displayType === DISPLAY_TYPE.input })}>{prefix} </small>}
            <NumberFormatInput
                id={id}
                displayType={displayType}
                decimalScale={2}
                decimalSeparator={decimalSeparator}
                thousandSeparator={thousandSeparator}
                fixedDecimalScale
                maxLength={getInteger("amount.maxLength")}
                onBlur={handleBlur}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                type={type}
                tabIndex="-1"
                value={value}
            />
        </EditableDataNumber>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store, props) => {
    const { lang, prefix } = props;
    return {
        prefix: StoreI18n.getMessage(store, `currency.label.${prefix}`) || prefix,
        lang: lang || StoreI18n.getLang(store) || window.navigator.language.slice(0, 2),
    };
};

export default Connect(mapStateToProps)(React.memo(Component));
