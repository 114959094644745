import React from "react";

import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import { SelectorsStore as SelectorsStoreWMBonds } from "~/store/wm/bonds";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import HighOrder from "~/components/HighOrder";

import Style from "./SearchBonds.rules.scss";
import Form from "./_Form";
import SearchItemsList from "./_SearchItemsList";

export const { NAME } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const { isFetching } = props;

    return (
        <Container
            name={NAME}
            head-title={UtilsI18n.get("wm.bonds.buy.search.title")}
            wait={isFetching}
            head-onBackWeb={handleBack}
            head-onBack={handleBack}
            className={Style.CLASS}
            step={MODE.EDIT}
            scopeToShowNotification={SCOPE.BUY_BONDS}>
            <Box className="search-wrapper">
                <Form />
                <SearchItemsList />
            </Box>
        </Container>
    );

    function handleBack() {
        const { history } = props;

        history.goBack();
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        isFetching: SelectorsStoreWMBonds.getFetching(store),
    };
};

export default HighOrder(Connect(mapStateToProps))(Component);
