import PropTypes from "prop-types";

import { CDP_CURRENCY, EMPTY_STR } from "~/constants";
import { FactoryType } from "~/util/redux";

export const NAME = "transactionLines";

export const PATH = {
    DOWNLOAD_PDF: "/pay.multiline.download.line.pdf",
    IMPORT_PAYROLL: "/pay.multiline.salary.payroll",
    LIST_LOCAL_BANKS: "/banks.local.list",
    LIST_TRANSACTION_LINES: "/files.list.transaction.lines",
    LIST_TRANSACTION_LINES_STATUS: "/pay.multiline.status",
};

export const TYPE = FactoryType(NAME, [
    "ADD_TRANSACTION_LINE",
    "CANCEL_CHANGED_TRANSACTION_LINES",
    "CHANGE_FILTERS",
    "CLEAN_ALL",
    "CLEAN_LINES_WITH_NO_AMOUNT",
    "CLEAR_TRANSACTION_LINES",
    "DOWNLOAD_LINE_PDF",
    "EDIT_TRANSACTION_LINE",
    "IMPORT_PAYROLL_CONFIRMATION_REQUEST",
    "IMPORT_PAYROLL_REQUEST",
    "IMPORT_PAYROLL_FAILURE",
    "IMPORT_PAYROLL_SUCCESS",
    "LIST_LOCAL_BANKS_REQUEST",
    "LIST_LOCAL_BANKS_FAILURE",
    "LIST_LOCAL_BANKS_SUCCESS",
    "LIST_TRANSACTION_LINES_REQUEST",
    "LIST_TRANSACTION_LINES_FAILURE",
    "LIST_TRANSACTION_LINES_SUCCESS",
    "LIST_TRANSACTION_LINES_STATUS_REQUEST",
    "LIST_TRANSACTION_LINES_STATUS_FAILURE",
    "LIST_TRANSACTION_LINES_STATUS_SUCCESS",
    "ON_PAYMENT_METHOD_CHANGE",
    "REMOVE_TRANSACTION_LINE",
    "RESET_EDITED_LINES",
    "SET_CURRENCY",
    "SET_ERRORS",
    "SET_IS_EDITING_PAYMENT",
    "SET_LINES_FROM_TRANSACTION_DATA",
    "SET_PAGE_NUMBER",
    "SHOW_IMPORTED_PAYROLL",
    "SORT_LINES_BY_NAME",
]);

export const PROP = {};

PROP.types = {
    activeFilters: PropTypes.object,
    currency: PropTypes.string,
    errors: PropTypes.object,
    fetching: PropTypes.bool,
    isEditingPayment: PropTypes.bool,
    lastPage: PropTypes.bool,
    linesIndex: PropTypes.number,
    linesBeingEdited: PropTypes.number,
    linesWithInitialValues: PropTypes.array,
    linesWithStatus: PropTypes.number,
    list: PropTypes.array,
    localBanks: PropTypes.array,
    pageNumber: PropTypes.number,
    payrollLines: PropTypes.array,
    selectedLine: PropTypes.number,
    totalAmount: PropTypes.shape({
        quantity: PropTypes.number,
        currency: PropTypes.number,
    }),
    totalLines: PropTypes.number,
    confirmationRequest: PropTypes.bool,
};

PROP.defaults = {
    activeFilters: {},
    currency: CDP_CURRENCY.UYU,
    errors: null,
    fetching: false,
    isEditingPayment: false,
    lastPage: true,
    linesIndex: 0,
    linesBeingEdited: 0,
    linesWithInitialValues: [],
    linesWithStatus: 0,
    list: [],
    localBanks: [],
    pageNumber: 0,
    payrollLines: [],
    selectedLine: 0,
    totalAmount: {
        quantity: 0,
        currency: EMPTY_STR,
    },
    totalLines: 0,
    confirmationRequest: false,
};

PROP.initialValues = {
    address: EMPTY_STR,
    creditAmountQuantity: EMPTY_STR,
    creditAccountBank: null,
    creditAccountName: EMPTY_STR,
    creditAccountNumber: EMPTY_STR,
    reference: EMPTY_STR,
    email: EMPTY_STR,
};
