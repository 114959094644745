import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { MODE } from "~/constants/form";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "forms";

export const PATH = {
    CORE_FORMS_READ: "/core.forms.read",
    CORE_LIST_TRANSACTION_CREDENTIALS_GROUPS: "/core.listTransactionCredentialsGroups",
    CORE_READ_TRANSACTION: "/core.readTransaction",
    CORE_SAVE_DRAFT_TRANSACTION: "/core.saveDraftTransaction",
    CORE_CREATE_TRANSACTION_TEMPLATE: "/core.createTransactionTemplate",
    CORE_CANCEL_TRANSACTION: "/core.cancelTransaction",
    CORE_MOVE_TO_DRAFT_TRANSACTION: "/core.moveToDraftTransaction",
};

export const TYPE = FactoryType(NAME, [
    "CANCEL_CUSTOM_TRANSACTION_REQUEST",
    "CANCEL_TRANSACTION_PRE_ERROR",
    "CANCEL_TRANSACTION_PRE_REQUEST",
    "CANCEL_TRANSACTION_PRE_SUCCESS",
    "CANCEL_TRANSACTION_REQUEST",
    "CANCEL_TRANSACTION_SUCCESS",
    "CHANGE_FORM_METADATA",
    "CLEAN_ALL",
    "CLEAN_STEPS",
    "CLOSE_TRANSACTION_PREVIEW",
    "DEPENDEE_CHANGED",
    "FORM_CLOSED",
    "FORM_DATA_RESET",
    "FORM_DATA_SAVE",
    "FORM_SELECTOR_OPEN",
    "FORM_SELECTOR_TOGGLE",
    "FROM_BIOMETRIC",
    "LIST_CREDENTIALS_GROUPS_REQUEST",
    "LIST_CREDENTIALS_GROUPS_SUCCESS",
    "MODIFY_TRANSACTION_FAILURE",
    "MODIFY_TRANSACTION_REQUEST",
    "PREVIEW_FORM_REQUEST",
    "PREVIEW_FORM_SUCCESS",
    "READ_FORM_FAILURE",
    "READ_FORM_REQUEST",
    "READ_FORM_SUCCESS",
    "READ_TRANSACTION_FAILURE",
    "READ_TRANSACTION_REQUEST",
    "READ_TRANSACTION_SUCCESS",
    "SAVE_DATA",
    "SAVE_DATA_OFF",
    "SAVE_DRAFT_FAILURE",
    "SAVE_DRAFT_REQUEST",
    "SAVE_DRAFT_SUCCESS",
    "SEND_FORM_CREDENTIAL_FAILURE",
    "SEND_FORM_DATA_FAILURE",
    "SEND_FORM_FAIL",
    "SEND_FORM_REQUEST",
    "SEND_FORM_SUCCESS",
    "SET_DATA",
    "SIGN_CUSTOM_CREDENTIAL_FAILURE",
    "SIGN_CUSTOM_TRANSACTION_REQUEST",
    "SIGN_TRANSACTION_PREVIEW_FAILURE",
    "SIGN_TRANSACTION_PREVIEW_REQUEST",
    "SIGN_TRANSACTION_PREVIEW_SUCCESS",
    "SIGN_TRANSACTION_REQUEST",
    "SUGGEST_OTHER_LOCAL_TRANSFER_OPTION",
    "SWITCH_MASSIVE_PAYMENTS_TO_MANUAL",
    "TOGGLE_MODAL",
    "TRANSACTION_REQUEST_DELAY",
]);

export const PROP = {};

PROP.members = {
    commission: PropTypes.object,
    previewData: {
        accountAlias: PropTypes.string,
        branchName: PropTypes.string,
        checkbookQty: PropTypes.string,
        checkbookType: PropTypes.string,
        documentNumber: PropTypes.string,
        documentTypeName: PropTypes.string,
        name: PropTypes.string,
    },
};

PROP.types = { previewData: PropTypes.shape(PROP.members), ...TypesRedux };

PROP.defaults = {
    ...DefaultsRedux,
    childrenTransactions: null,
    comeFrom: EMPTY_STR,
    commission: null,
    credentialsGroups: [],
    data: {},
    fetching: true,
    formData: {},
    id: null,
    isCancellingTransaction: false,
    metadata: { fieldList: [], formNameMap: {} },
    mode: MODE.EDIT,
    name: EMPTY_STR,
    parentTransaction: null,
    prevMode: null,
    prevRoute: { pathname: EMPTY_STR },
    previewData: {
        accountAlias: null,
        branchName: null,
        checkbookQty: null,
        checkbookType: null,
        documentNumber: null,
        documentTypeName: null,
        name: null,
    },
    saveData: false,
    shouldKeepFormState: false,
    showModal: false,
    submit: false,
    submitAction: null,
    submitActionParams: null,
    transaction: {},
    values: null,
    waitingForResponse: false,
};
