import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex";
import { SelectorsStore as SelectorsStoreComexImport } from "~/store/comex/import";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import DataDate from "~/components/DataDate";
import I18n from "~/components/I18n";
import Ticket from "~/components/Ticket/Ticket";
import FormattedAmount from "~/pages/_components/FormattedAmount";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const { transaction, ticketConfirmation, dispatch } = props;
    const { data } = transaction;

    const {
        operationNumber,
        dueDate,
        boardingDate,
        invoiceAmount,
        beneficiary,
        oldAmount,
        otherModifications,
        invoiceAmountCurrency,
        observations,
        increment,
    } = data;
    const shareTicket = (idTransactionToRead) => {
        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    };
    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}
                    handleModify={handleModify}
                    hasMobileComponent
                    displayDownloadPDF
                    handleDownloadPDF={() => shareTicket(transaction.idTransaction)}>
                    <Box flex directionColumn align="flex-start" className="mb-3">
                        <div className="opening">
                            <I18n id="comex.import.modifyCreditLetter.step2.opening" tag="p" className="mt-3" />
                        </div>
                        <Box flex className="info-label">
                            <h4 className="bold">
                                {UtilsI18n.get("comex.import.modifyCreditLetter.step2.operationNumber")}
                            </h4>
                            <span>{operationNumber}</span>
                        </Box>
                        <Box flex className="info-label">
                            <h4 className="bold">{UtilsI18n.get("comex.import.modifyCreditLetter.step2.amount")}</h4>
                            <FormattedAmount currency={invoiceAmountCurrency} quantity={parseFloat(oldAmount)} />
                        </Box>
                        <Box flex cclassName="info-label">
                            <h4 className="bold">{UtilsI18n.get("comex.import.modifyCreditLetter.step2.recipient")}</h4>
                            <span>{beneficiary}</span>
                        </Box>

                        <div className="opening">
                            <I18n id="comex.import.modifyCreditLetter.step2.content" tag="p" className="mt-3" />
                        </div>
                        {dueDate && (
                            <Box flex className="info-label">
                                <I18n id="comex.import.modifyCreditLetter.step2.dueDate" tag="p" />
                                <DataDate value={dueDate} />
                            </Box>
                        )}
                        {boardingDate && (
                            <Box flex className="info-label">
                                <I18n id="comex.import.modifyCreditLetter.step2.boardingDate" tag="p" />
                                <DataDate value={boardingDate} />
                            </Box>
                        )}
                        {invoiceAmount && (
                            <Box flex className="info-label">
                                <I18n id={`comex.import.modifyCreditLetter.step2.invoiceAmount.${increment}`} tag="p" />{" "}
                                <FormattedAmount currency={invoiceAmountCurrency} quantity={invoiceAmount} />
                            </Box>
                        )}
                        {otherModifications && <span>{otherModifications}</span>}
                        <I18n id="comex.import.modifyCreditLetter.step2.terms" tag="p" className="mt-3" />
                        {observations && (
                            <Box flex className="info-label">
                                <I18n id="comex.import.modifyCreditLetter.step2.observations" tag="p" />
                                <span className="data-date">{observations}</span>
                            </Box>
                        )}
                    </Box>
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { idTransaction } = transaction;
        dispatch(
            SelectorsActionComex.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        dispatch(SelectorsActionComex.signTransactionPreview({ data }));
    }

    function handleModify() {
        const { idTransaction } = transaction;
        dispatch(SelectorsActionForm.modifyTransaction({ idTransactionToMove: idTransaction }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreComexImport.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
