import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "administrationGroups";

export const PATH = {
    ADMINISTRATION_GROUPS_LIST: "/administration.groups.list",
    ADMINISTRATION_GROUPS_EXPORT: "/administration.groups.export",
    ADMINISTRATION_GROUPS_BLOCKUNBLOCK_PREVIEW: "/administration.groups.blockunblock.preview",
    ADMINISTRATION_GROUPS_BLOCKUNBLOCK_SEND: "/administration.groups.blockunblock.send",
    ADMINISTRATION_GROUPS_DELETE_PREVIEW: "/administration.groups.delete.preview",
    ADMINISTRATION_GROUPS_DELETE_SEND: "/administration.groups.delete.send",
};

export const TYPE = FactoryType(NAME, [
    "LOAD_LIST_REQUEST",
    "LOAD_LIST_FAILURE",
    "LOAD_LIST_SUCCESS",
    "LOAD_MORE_REQUEST",
    "LOAD_MORE_SUCCESS",
    "EXPORT_LIST_REQUEST",
    "EXPORT_LIST_FAILURE",
    "EXPORT_LIST_SUCCESS",
    "CHANGE_GROUP_STATUS_PREVIEW",
    "CHANGE_GROUP_STATUS_PREVIEW_SUCCESS",
    "CHANGE_GROUP_STATUS_PREVIEW_ERROR",
    "CHANGE_GROUP_STATUS_CONFIRMATION",
    "HIDE_MODAL",
]);

export const PROP = {};

PROP.members = {};

PROP.types = {
    list: PropTypes.array,
    groupsExtendedInfo: PropTypes.object,
    groupAction: PropTypes.any,
    groupsToApplyAction: PropTypes.array,
    groupsNamesToApplyAction: PropTypes.array,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    hasMoreData: PropTypes.bool,
    fetching: PropTypes.bool,
    fetchingMoreGroups: PropTypes.bool,
    fetchingExport: PropTypes.bool,
    idTransaction: PropTypes.any,
    idActivity: PropTypes.any,
    credentialGroups: PropTypes.array,
    showModal: PropTypes.bool,
    ...TypesRedux,
};

PROP.defaults = {
    list: [],
    groupsExtendedInfo: {},
    groupAction: null,
    groupsToApplyAction: [],
    groupsNamesToApplyAction: [],
    currentPage: 1,
    totalPages: 0,
    hasMoreData: false,
    fetching: true,
    fetchingMoreGroups: false,
    fetchingExport: false,
    idTransaction: null,
    idActivity: null,
    credentialGroups: [],
    showModal: false,
    ...DefaultsRedux,
};
