import React from "react";

import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { EMPTY_STR, SPACE_STR } from "~/constants";
import { STATUS } from "~/constants/transaction";
import { SelectorsAction as SelectorsActionComex, SelectorsStore as SelectorsStoreComex } from "~/store/comex/";
import {
    SelectorsAction as SelectorsActionComexExport,
    SelectorsStore as SelectorsStoreComexExport,
} from "~/store/comex/export/";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import DeviceUtils from "~/util/device";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import DataDate from "~/components/DataDate";
import GridLayout from "~/components/GridLayout";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Token from "~/components/Token/Token";
import FormattedAmount from "~/pages/_components/FormattedAmount";
import TicketPrintInformation from "~/pages/_components/TicketPrintInformation";

import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        isSubmitting: PropTypes.bool,
    },
    defaults: {
        isSubmitting: false,
    },
};

const printForm = () => {
    const elements = window.document.getElementsByClassName("no_print");

    for (let i = 0; i < elements.length; i += 1) {
        elements[i].style.display = "none";
    }

    window.print();

    for (let i = 0; i < elements.length; i += 1) {
        elements[i].style.display = EMPTY_STR;
    }

    return true;
};

export function Component(props) {
    const { isSubmitting, dispatch, idForm, formData, location, transaction } = props;

    const {
        valueDate,
        invoiceNumber,
        invoiceAmount,
        invoiceAmountCurrency,
        courierName,
        accountNumber,
        sendDocuments,
        turned,
        documentList,
        documentsDelivery,
        documentsDeliveryDays,
        documentsDeliveryDaysOf,
        instructions,
        foreignExpenses,
        observations,
    } = Object.entries(formData).length > 0 ? formData : transaction?.data;
    const COLUMN_HEADINGS = [
        UtilsI18n.get(`${idForm}.columnheaders.documents.document`),
        UtilsI18n.get(`${idForm}.columnheaders.pdf.description`),
        UtilsI18n.get(`${idForm}.columnheaders.documents.original`),
        UtilsI18n.get(`${idForm}.columnheaders.documents.copies`),
    ];

    const isFromTransactionsHistory = location.pathname.includes("/ticket");
    const data =
        isFromTransactionsHistory && transaction?.idTransactionStatus !== STATUS.DRAFT ? transaction.data : formData;

    const shareTicket = () => {
        dispatch(
            SelectorsActionTransactions.shareTicketRequest({
                transactionData: data,
                idActivity: "comex.export.presentBillingDocuments.send",
            }),
        );
    };

    return (
        <Form id={Style.ID}>
            <TicketPrintInformation />
            <Box flex directionColumn align="flex-start" className="mb-3">
                <DataDate value={valueDate} />
                <div className="opening">
                    <I18n id={`${idForm}.letter.opening`} tag="p" className="mt-3" />
                </div>
                <div className="info-label">
                    <h4 className="bold">{UtilsI18n.get(`${idForm}.letter.operationNumber`)}</h4>
                    <span>{invoiceNumber}</span>
                </div>
                <div className="info-label">
                    <h4 className="bold">{UtilsI18n.get(`${idForm}.letter.invoiceAmount`)}</h4>
                    <FormattedAmount currency={invoiceAmountCurrency} quantity={parseFloat(invoiceAmount)} />
                </div>
                <div className="info-label">
                    <span>{UtilsI18n.get(`${idForm}.letter.documents`)}</span>
                </div>

                <div className="info-label table">
                    <GridLayout className="header ticket-table-section">
                        {COLUMN_HEADINGS.map((heading) => {
                            return <div>{heading}</div>;
                        })}
                    </GridLayout>
                    {documentList &&
                        documentList.map((document) => (
                            <GridLayout className="item ticket-table-section">
                                <div>{UtilsI18n.get(`comex.creditLetter.modal.documentTypes.${document.type}`)}</div>
                                <div>{document.description}</div>
                                <div>{document.originals}</div>
                                <div>{document.copies}</div>
                            </GridLayout>
                        ))}
                </div>

                <div className="info-label">
                    <span className="inline-span">
                        {UtilsI18n.get(`${idForm}.letter.sendDocuments`)}
                        {SPACE_STR}
                        {sendDocuments}
                    </span>
                </div>

                <div className="info-label">
                    <span className="inline-span">
                        {UtilsI18n.get(`${idForm}.letter.documentsDelivery1`)}
                        <p className="delivery-type">
                            {UtilsI18n.get(`${idForm}.documentsDelivery.${documentsDelivery}`)}
                        </p>
                        {documentsDelivery === "ACEPTACIONDELETRA" &&
                            UtilsI18n.get(`${idForm}.letter.documentsDelivery2`, EMPTY_STR, {
                                DELIVERY_DAYS: documentsDeliveryDays,
                                DAYS_OF: documentsDeliveryDaysOf,
                            })}
                    </span>
                </div>

                <div className="info-label">
                    <span className="inline-span">
                        {UtilsI18n.get(`${idForm}.letter.turned`)}
                        {SPACE_STR}
                        {turned}
                    </span>
                </div>

                <div className="info-label">
                    <span>
                        {UtilsI18n.get(`${idForm}.letter.foreignExpenses`)}
                        {SPACE_STR}
                        {UtilsI18n.get(`${idForm}.letter.foreignExpenses.${foreignExpenses}`)}
                    </span>
                </div>

                {instructions && (
                    <div className="info-label">
                        <span className="inline-span">
                            {UtilsI18n.get(`${idForm}.letter.instructions`)}
                            {SPACE_STR}
                            {UtilsI18n.get(`${idForm}.letter.instructions.${instructions}`)}
                        </span>
                    </div>
                )}

                {(courierName || accountNumber) && (
                    <div className="info-label">
                        <span>
                            {UtilsI18n.get(`${idForm}.letter.sendDocumentsBy`)}
                            {courierName && (
                                <React.Fragment>
                                    {SPACE_STR}
                                    {UtilsI18n.get(`${idForm}.letter.courierName`, EMPTY_STR, {
                                        COURIER_NAME: courierName,
                                    })}
                                </React.Fragment>
                            )}
                            {accountNumber && (
                                <React.Fragment>
                                    {SPACE_STR}
                                    {UtilsI18n.get(`${idForm}.letter.accountNumber`, EMPTY_STR, {
                                        ACCOUNT_NUMBER: accountNumber,
                                    })}
                                </React.Fragment>
                            )}
                        </span>
                    </div>
                )}
                <I18n id={`${idForm}.letter.terms`} tag="p" className="mt-3 mb-3" />
                {observations && (
                    <div className="info-label">
                        <span className="inline-span">
                            {UtilsI18n.get(`${idForm}.letter.observations`)}
                            {SPACE_STR}
                            {observations}
                        </span>
                    </div>
                )}
            </Box>

            <div className="no_print">
                <Token labelButton="global.send" isSubmitting={isSubmitting} />
            </div>
            {!DeviceUtils.isMobileNative() && (
                <div className="buttonsTicketConfirmation">
                    <Button
                        block={false}
                        bsStyle="link"
                        label="comex.preview.print.label"
                        onClick={() => printForm()}
                    />
                </div>
            )}
            <div className="buttonsTicketConfirmation">
                <Button bsStyle="link" block={false} label="forms.ticket.printPDF" onClick={() => shareTicket()} />
            </div>
        </Form>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        formData: SelectorsStoreComexExport.getPresentBillingDocumentsFormData(store),
        idForm: SelectorsStoreComexExport.getPresentBillingDocumentsIdForm(store),
        transaction: SelectorsStoreComex.getTransaction(store),
        user: SelectorsStoreSession.getUser(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithRouter,
    WithFormik({
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        validationSchema: (props) => {
            const { user } = props;

            return Yup.object().shape({
                otp: user.needsBiometric
                    ? Yup.string().nullable()
                    : Yup.string().required(UtilsI18n.get("form.credential.otp.required")),
            });
        },
        handleSubmit: ({ otp }, formikBag) => {
            const {
                dispatch,
                history,
                formData,
                transaction: { idTransaction, idActivity, idTransactionStatus },
            } = formikBag.props;

            const isFromTransactionsHistory = history.location.pathname.includes("/ticket");

            if (isFromTransactionsHistory && idTransactionStatus !== STATUS.DRAFT) {
                dispatch(
                    SelectorsActionComex.signTransaction({
                        idForm: null,
                        idActivity,
                        idTransaction,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            } else {
                dispatch(
                    SelectorsActionComexExport.presentBillingDocumentsSendRequest({
                        formData,
                        idTransaction,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            }
        },
    }),
)(Component);
