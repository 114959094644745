import React from "react";

import { replace as Replace } from "connected-react-router";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { connect as Connect } from "react-redux";

import {
    SelectorsAction as SelectorsActionCommunication,
    SelectorsStore as SelectorsStoreCommunication,
} from "~/store/communications";
import * as I18nUtils from "~/util/i18n";

import Button from "~/components/Button/Button";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Container from "~/pages/_components/Container";

export const NAME = "Reader";

export const PROP = {
    types: {
        dispatch: PropTypes.func.isRequired,
        isDesktop: PropTypes.bool,
        attachments: PropTypes.arrayOf(
            PropTypes.shape({
                idFile: PropTypes.number,
                fileName: PropTypes.string,
            }),
        ),

        detail: PropTypes.shape({
            idCommunication: PropTypes.number,
            subject: PropTypes.string,
            communicationTray: PropTypes.shape({
                name: PropTypes.string,
            }),
            direction: PropTypes.string,
            body: PropTypes.string,
            sentDateAsString: PropTypes.string,
        }),
    },
    defaults: { isDesktop: false, detail: null, attachments: [] },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    handleReplyClick = () => {
        const { detail, isDesktop, dispatch } = this.props;
        const { idThread, idCommunication } = detail;

        if (isDesktop) {
            dispatch(SelectorsActionCommunication.detailRequest({ idThread, idCommunication }));
            dispatch(SelectorsActionCommunication.showReplyPanel());
        } else {
            dispatch(Replace(`/communications/reply/${detail.idThread}/${detail.idCommunication}`));
        }
    };

    handleCloseClick = () => {
        const { dispatch } = this.props;
        dispatch(SelectorsActionCommunication.hideAllPanels());
    };

    handleAtachementRequest = (att) => {
        const { dispatch } = this.props;
        const { idFile } = att;
        dispatch(SelectorsActionCommunication.downloadAttachmentRequest({ idFile }));
    };

    render() {
        const { detail, attachments } = this.props;
        let direction = null;

        if (detail) {
            direction =
                detail.direction === "CUSTOMER_TO_BANK"
                    ? I18nUtils.get("communications.trays.sent")
                    : I18nUtils.get("communications.trays.received");
        }
        return (
            detail && (
                <React.Fragment>
                    <div className="above-the-fold">
                        <Container className="align-items-center flex-grow container--layout container--box">
                            <Col className="col col-12 open-conversation">
                                <ul>
                                    <li>
                                        <div className="container-message">
                                            <span>{detail.sentDateAsString}</span>
                                            <strong
                                                // eslint-disable-next-line react/no-danger
                                                dangerouslySetInnerHTML={{
                                                    __html: `${direction}: ${detail.communicationTray.name}`,
                                                }}
                                            />{" "}
                                        </div>
                                        <p
                                            // eslint-disable-next-line react/no-danger
                                            dangerouslySetInnerHTML={{
                                                __html: detail.body,
                                            }}
                                        />
                                        {attachments && attachments.length > 0 && (
                                            <p className="file-read-container">
                                                {attachments.map((att) => (
                                                    <Button
                                                        onClick={() => this.handleAtachementRequest(att)}
                                                        variant="link"
                                                        className="attachment">
                                                        <div className="icon">
                                                            <Image src="attachment.svg" />
                                                        </div>
                                                        <p>{att.fileName}</p>
                                                    </Button>
                                                ))}
                                            </p>
                                        )}
                                    </li>
                                </ul>
                            </Col>
                        </Container>

                        <Container className="align-items-center container--layout">
                            <Col className="col col-12">
                                <div className="btn-wrapper">
                                    <Button variant="primary" onClick={this.handleReplyClick}>
                                        <I18n id="communications.message.reply.label" />
                                    </Button>
                                </div>
                            </Col>
                        </Container>
                    </div>
                </React.Fragment>
            )
        );
    }
}

const mapStateToProps = (store) => ({
    detail: SelectorsStoreCommunication.detail(store),
    attachments: SelectorsStoreCommunication.attachments(store),
    isFetching: SelectorsStoreCommunication.isFetching(store),
});

export default Connect(mapStateToProps)(Component);
