import React from "react";

import { push as Push } from "connected-react-router";
import { connect as Connect } from "react-redux";

import { BIOMETRIC_ID_USER, SCOPE } from "~/constants";
import Container from "~/containers/External/Dash";
import { SelectorsStore as SelectorsStoreBiometric } from "~/store/biometric";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { SelectorsStore as SelectorsStoreLogin } from "~/store/login";
import UtilsDevice from "~/util/device";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import * as UtilsSecureStorage from "~/util/secureStorage";

import PasswordModal from "~/pages/_components/PasswordModal";

import Step1Content from "~/pages/login/_components/Step1Content";

import Style from "./LoginStep1.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: { ...TypesRedux },
    defaults: { ...DefaultsRedux },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        idUserBiometric: null,
        userPersonalName: null,
        ready: false,
    };

    componentDidMount() {
        this.handleIdUserBiometric();
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(Push("/"));
    };

    handleIdUserBiometric = async () => {
        const { isTrusted } = this.props;
        let idUserBiometric = null;
        let userPersonalName = null;

        if (isTrusted && UtilsDevice.isMobileNative()) {
            try {
                idUserBiometric = await UtilsSecureStorage.get(BIOMETRIC_ID_USER);
                userPersonalName = await UtilsSecureStorage.get("personalName");
            } catch (error) {
                idUserBiometric = null;
                userPersonalName = null;
            }
        }
        this.setState((prevState) => ({ ...prevState, idUserBiometric, userPersonalName, ready: true }));
    };

    render() {
        const { askPassword, fingerprintSubmiting, isFetching, submit } = this.props;
        const { ready, idUserBiometric, userPersonalName } = this.state;

        let loading = false;
        if (isFetching || (submit && !askPassword) || fingerprintSubmiting) {
            loading = true;
        }

        return (
            <Container
                name={NAME}
                className={Style.CLASS}
                head-onClose={this.onHeaderClose}
                wait={loading}
                scopeToShowNotification={SCOPE.LOGIN}>
                <div className="enrollment page-login">
                    <PasswordModal isNotLogged username={idUserBiometric} />
                    {!fingerprintSubmiting && ready && (
                        <Step1Content
                            className="login-form"
                            {...this.props}
                            idUserBiometric={idUserBiometric}
                            userPersonalName={userPersonalName}
                        />
                    )}
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    askPassword: SelectorsStoreBiometric.askPassword(store),
    fingerprintSubmiting: SelectorsStoreLogin.getFingerprintSubmiting(store),
    isFetching: !SelectorsStoreI18n.getMessages(store) || !SelectorsStoreConfig.getConfig(store),
    isTrusted: SelectorsStoreConfig.getIsTrusted(store),
    rememberedUser: SelectorsStoreLogin.getRememberedUser(store),
    showCaptcha: SelectorsStoreLogin.getShowCaptcha(store),
    submit: SelectorsStoreLogin.getSubmit(store),
});

export default Connect(mapStateToProps)(Component);
