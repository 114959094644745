import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    aliasDeletionPreview: (props) => API.executeWithAccessToken(PATH.ALIAS_DELETION_PREVIEW, props),
    aliasDeletionSend: (props) => API.executeWithAccessToken(PATH.ALIAS_DELETION_SEND, props),
    aliasEnrollmentPre: () => API.executeWithAccessToken(PATH.ALIAS_ENROLLMENT_PRE),
    aliasEnrollmentPreview: (props) => API.executeWithAccessToken(PATH.ALIAS_ENROLLMENT_PREVIEW, props),
    aliasEnrollmentSend: (props) => API.executeWithAccessToken(PATH.ALIAS_ENROLLMENT_SEND, props),
    getAccountsByAliasList: (props) => API.executeWithAccessToken(PATH.GET_ACCOUNTS_BY_ALIAS, props),
    latestTransactionsList: (props) => API.executeWithAccessToken(PATH.LATEST_TRANSACTIONS, props),
    templatesList: (props) => API.executeWithAccessToken(PATH.TEMPLATES, props),
};

export const SelectorsAction = {
    aliasDeletionPreviewRequest: (props) => FactoryAction(TYPE.ALIAS_DELETION_PREVIEW_REQUEST, props),
    aliasDeletionPreviewFailure: () => FactoryAction(TYPE.ALIAS_DELETION_PREVIEW_FAILURE),
    aliasDeletionPreviewSuccess: (props) => FactoryAction(TYPE.ALIAS_DELETION_PREVIEW_SUCCESS, props),
    aliasDeletionSendRequest: (props) => FactoryAction(TYPE.ALIAS_DELETION_SEND_REQUEST, props),
    aliasDeletionSendFailure: () => FactoryAction(TYPE.ALIAS_DELETION_SEND_FAILURE),
    aliasDeletionSendSuccess: (props) => FactoryAction(TYPE.ALIAS_DELETION_SEND_SUCCESS, props),
    aliasEnrollmentPreRequest: () => FactoryAction(TYPE.ALIAS_ENROLLMENT_PRE_REQUEST),
    aliasEnrollmentPreFailure: () => FactoryAction(TYPE.ALIAS_ENROLLMENT_PRE_FAILURE),
    aliasEnrollmentPreSuccess: (props) => FactoryAction(TYPE.ALIAS_ENROLLMENT_PRE_SUCCESS, props),
    aliasEnrollmentPreviewRequest: (props) => FactoryAction(TYPE.ALIAS_ENROLLMENT_PREVIEW_REQUEST, props),
    aliasEnrollmentPreviewFailure: () => FactoryAction(TYPE.ALIAS_ENROLLMENT_PREVIEW_FAILURE),
    aliasEnrollmentPreviewSuccess: (props) => FactoryAction(TYPE.ALIAS_ENROLLMENT_PREVIEW_SUCCESS, props),
    aliasEnrollmentReset: () => FactoryAction(TYPE.ALIAS_ENROLLMENT_RESET),
    aliasEnrollmentSendRequest: (props) => FactoryAction(TYPE.ALIAS_ENROLLMENT_SEND_REQUEST, props),
    aliasEnrollmentSendFailure: () => FactoryAction(TYPE.ALIAS_ENROLLMENT_SEND_FAILURE),
    aliasEnrollmentSendSuccess: (props) => FactoryAction(TYPE.ALIAS_ENROLLMENT_SEND_SUCCESS, props),
    cancelTransactionCustom: (props) => FactoryAction(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, props),
    clearAccountsByAliasList: () => FactoryAction(TYPE.CLEAR_ACCOUNTS_BY_ALIAS_LIST),
    getAccountsByAliasFailure: () => FactoryAction(TYPE.GET_ACCOUNTS_BY_ALIAS_FAILURE),
    getAccountsByAliasRequest: (props) => FactoryAction(TYPE.GET_ACCOUNTS_BY_ALIAS_REQUEST, props),
    getAccountsByAliasSuccess: (props) => FactoryAction(TYPE.GET_ACCOUNTS_BY_ALIAS_SUCCESS, props),
    latestTransactionsListRequest: (props) => FactoryAction(TYPE.LATEST_TRANSACTIONS_LIST_REQUEST, props),
    latestTransactionsListSuccess: (props) => FactoryAction(TYPE.LATEST_TRANSACTIONS_LIST_SUCCESS, props),
    passQueriedAccountsByAlias: (props) => FactoryAction(TYPE.PASS_QUERIED_ACCOUNTS_BY_ALIAS, props),
    readTransactionRequest: (props) => FactoryAction(TYPE.READ_TRANSACTION_REQUEST, props),
    readTransactionSuccess: (props) => FactoryAction(TYPE.READ_TRANSACTION_SUCCESS, props),
    readTransactionFailure: (props) => FactoryAction(TYPE.READ_TRANSACTION_FAILURE, props),
    signTransaction: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_REQUEST, props),
    signTransactionFailure: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_FAILURE, props),
    signTransactionPreview: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_PREVIEW, props),
    signTransactionSuccess: (props) => FactoryAction(TYPE.SIGN_TRANSACTION_SUCCESS, props),
    templatesListRequest: (props) => FactoryAction(TYPE.TEMPLATES_LIST_REQUEST, props),
    templatesListSuccess: (props) => FactoryAction(TYPE.TEMPLATES_LIST_SUCCESS, props),
    toggleShowModal: (props) => FactoryAction(TYPE.TOGGLE_SHOW_MODAL, props),
    toggleTemplateHasBeenLoadedFalse: () => FactoryAction(TYPE.TOGGLE_TEMPLATE_HAS_BEEN_LOADED_FALSE),
    toStepOne: () => FactoryAction(TYPE.FORM_TO_STEP_1),
    updateCurrency: (props) => FactoryAction(TYPE.UPDATE_CURRENCY, props),
};

export const SelectorsStore = {
    accountsByAliasFetching: (store) => store[NAME].accountsByAliasFetching,
    fetching: (store) => store[NAME].fetching,
    getAccountsByAlias: (store) => store[NAME].accountsByAlias,
    getFormData: (store) => store[NAME].formData,
    getLocalBanks: (store) => store[NAME].localBanks,
    getMode: (store) => store[NAME].mode,
    getNewCurrency: (store) => store[NAME].currency,
    getTransaction: (store) => store[NAME].transaction,
    getUserAlias: (store) => store[NAME].userAlias,
    getUserAliasFetched: (store) => store[NAME].userAliasHasBeenFetched,
    existsAccountsHSBC: (store) => store[NAME].existsAccountsHSBC,
    isAccountsByAliasSearchRequested: (store) => store[NAME].accountsByAliasSearchRequested,
    isShowModal: (store) => store[NAME].showModal,
    isTemplateHasBeenLoaded: (store) => store[NAME].templateHasBeenLoaded,
    latestTransactionsList: (store) => store[NAME].latestTransactionsList,
    templatesListList: (store) => store[NAME].templatesList,
};
