import React from "react";

import { goBack as GoBack } from "connected-react-router";
import { Form, Field, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE, LEVEL } from "~/constants";
import {
    SelectorsStore as SelectorsStoreUploadPaymentOrders,
    SelectorsAction as SelectorsActionUploadPaymentOrders,
} from "~/store/factoring/uploadPaymentOrders";
import { SelectorsAction as SelectorsActionMultilineFile } from "~/store/multilinefile";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as UtilsI18n from "~/util/i18n";

import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";

import UploadPaymentOrdersDisplayList from "../_components/UploadPaymentOrdersDisplayList";
import Style from "./Step1Edit.rules.scss";
import FileUploadPaymentOrders from "./_FileUploadPaymentOrders";

export const { NAME } = Style;

export const FORM_ID = "client.factoring.uploadPaymentOrders";

export const PROP = {
    types: {
        isFetching: PropTypes.bool,
        paymentOrdersList: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string,
                number: PropTypes.string,
                supplier: PropTypes.string,
                issueDate: PropTypes.string,
                dueDate: PropTypes.string,
                currency: PropTypes.string,
                amount: PropTypes.string,
            }),
        ),
        totalizers: PropTypes.arrayOf(
            PropTypes.shape({
                currency: PropTypes.string,
                amount: PropTypes.string,
            }),
        ),
    },
    defaults: {
        isFetching: false,
        paymentOrdersList: [],
        totalizers: [],
    },
};

export function Component(props) {
    const { isFetching, paymentOrdersList, dispatch, totalizers: totalizersList } = props;

    const handleDownloadClick = (e, format) => {
        e.preventDefault();
        dispatch(
            SelectorsActionUploadPaymentOrders.downloadPaymentOrdersRequest({
                format,
                paymentOrdersList,
                totalizersList,
            }),
        );
    };

    return (
        <Form id={Style.ID}>
            <Field name="files" component={FileUploadPaymentOrders} value={paymentOrdersList} />
            <UploadPaymentOrdersDisplayList />
            {paymentOrdersList && (
                <Button id="export" variant="link" onClick={(e) => handleDownloadClick(e, "xls")}>
                    {UtilsI18n.get("client.factoring.paymentOrders.export.label")}
                </Button>
            )}
            <footer>
                {!isFetching && (
                    <React.Fragment>
                        <Button size="sm" variant="secondary" onClick={handleCancel}>
                            {UtilsI18n.get("global.cancel")}
                        </Button>
                        <Button type="submit" variant="primary">
                            {UtilsI18n.get("global.send")}
                        </Button>
                    </React.Fragment>
                )}
            </footer>
        </Form>
    );

    function handleCancel() {
        const { idFile } = props;
        if (idFile) {
            dispatch(SelectorsActionMultilineFile.deleteFileRequest({ idFile }));
        }
        dispatch(GoBack());
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        isFetching: false,
        paymentOrdersList: SelectorsStoreUploadPaymentOrders.getPaymentOrders(store),
        totalizers: SelectorsStoreUploadPaymentOrders.getTotalizers(store),
        idFile: SelectorsStoreUploadPaymentOrders.getIdFile(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        handleSubmit: (data, formikBag) => {
            const { dispatch, paymentOrdersList, totalizers } = formikBag.props;
            if (paymentOrdersList.length !== 0) {
                dispatch(
                    SelectorsActionUploadPaymentOrders.uploadPaymentOrdersPreviewFormRequest({
                        paymentOrdersList,
                        totalizers,
                    }),
                );
            } else {
                dispatch(
                    SelectorsActionNotification.showNotification({
                        message: UtilsI18n.get(`${FORM_ID}.invalidPaymentOrders`),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.FACTORING_UPLOAD_PAYMENT_ORDERS],
                    }),
                );
            }
        },
    }),
)(Component);
