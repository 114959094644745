export const flattenArray = (array) => [].concat(...array);

export const removeDuplicateItems = (array) => [...new Set(array)];

export const reinsert = (array, from, to) => {
    const clonedArray = [...array];
    const valueToReinsert = clonedArray[from];

    clonedArray.splice(from, 1);
    clonedArray.splice(to, 0, valueToReinsert);

    return clonedArray;
};

export const removeElement = (array, index) => {
    const leftSide = array.slice(0, index);
    const rightSide = array.slice(index + 1, array.length);

    return [...leftSide, ...rightSide];
};

export const intersection = (array1, array2) => array1.filter((x) => array2.includes(x));

export const mapItemsIds = (array, idKey) => ({
    ids: array.map((item) => item[idKey]),
    byId: array.reduce(
        (byId, item) => ({
            ...byId,
            [item[idKey]]: item,
        }),
        {},
    ),
});
