import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    listLoans: () => API.executeWithAccessToken(PATH.LOANS_LIST),

    readLoan: (props) => API.executeWithAccessToken(PATH.LOANS_READ, props),
};

export const SelectorsAction = {
    listLoans: () => FactoryAction(TYPE.LIST_LOANS_REQUEST),
    readLoan: (props) => FactoryAction(TYPE.LOAN_READ_REQUEST, props),
    toggleOptions: () => FactoryAction(TYPE.TOGGLE_OPTIONS),
    closeOptions: () => FactoryAction(TYPE.CLOSE_OPTIONS),
    setSelectedFilter: (props) => FactoryAction(TYPE.SET_SELECTED_FILTER, props),
    setBackToDesktop: (props) => FactoryAction(TYPE.SET_BACK_TO_DESKTOP, props),
    listLoanSuccess: (props) => FactoryAction(TYPE.LIST_LOANS_SUCCESS, props),
    loanReadSuccess: (props) => FactoryAction(TYPE.LOAN_READ_SUCCESS, props),
};

export const SelectorsStore = {
    getLoans: (store) => store[NAME].loansList,
    getSelectedLoan: (store) => store[NAME].selectedLoan,
    getFetching: (store) => store[NAME].fetching,
    getIsOptionsVisible: (store) => store[NAME].isOptionsVisible,
    getSelectedFilter: (store) => store[NAME].selectedFilter,
    getBackToDesktop: (store) => store[NAME].backToDesktop,
};
