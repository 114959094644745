import React from "react";

import ClassNames from "classnames";
import PropTypes from "prop-types";

import Namespaces from "./Button.scss";

export const { CLASS, NAME, TAG: Button } = Namespaces;

// This is the new button to be used.
// Please use this instead of the one in the parent folder

const SIZES = ["sm", "md", "lg"];
const TYPES = ["button", "reset", "submit"];
const VARIANTS = ["primary", "secondary", "link", "link-underline"];

export const PROP = {
    types: {
        /** Whether the button is currently active or not */
        active: PropTypes.bool,
        /** Whether the button should behave like a block element */
        block: PropTypes.bool,
        /** Whether the button is disabled for clicking or not */
        disabled: PropTypes.bool,
        /** Wheter the button has a ref */
        htmlRef: PropTypes.func,
        /** Element Id */
        id: PropTypes.string,
        /** Element Id */
        name: PropTypes.string,
        /** A function to call once the button has been clicked */
        onClick: PropTypes.func,
        /** Size variants */
        size: PropTypes.oneOf(SIZES),
        /** Type variants */
        type: PropTypes.oneOf(TYPES),
        /** Style variants */
        variant: PropTypes.oneOf(VARIANTS),
    },
    defaults: {
        active: false,
        block: false,
        disabled: false,
        htmlRef: null,
        id: null,
        name: null,
        onClick: null,
        size: null,
        type: "button",
        variant: null,
    },
};

export function Component({
    active,
    block,
    children,
    className,
    disabled,
    htmlRef,
    id,
    name,
    onClick,
    size,
    type,
    variant,
}) {
    return (
        <Button
            className={ClassNames(CLASS, className, { active }, { block }, size, variant)}
            disabled={disabled}
            id={id}
            name={name}
            onClick={onClick}
            ref={htmlRef}
            type={type}>
            {children}
        </Button>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default React.memo(Component);
