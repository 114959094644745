import { TYPE as TYPE_APP } from "~/store/app";
import { TYPE as TypesBackoffice } from "~/store/backoffice";

import { PROP } from "./_consts";

export default (TYPE) =>
    (storeProp = PROP.defaults, action) => {
        switch (action.type) {
            case TYPE.LOAD_PERMISSIONS_REQUEST:
                return {
                    ...storeProp,
                    fetching: true,
                    futurePermissions: null,
                    groups: [],
                };
            case TYPE.LOAD_PERMISSIONS_FAILURE:
                return {
                    ...storeProp,
                    fetching: false,
                };
            case TYPE.LOAD_PERMISSIONS_SUCCESS:
                return {
                    ...storeProp,
                    permissions: action.data.permissions,
                    products: action.data.products,
                    groups: action.data.groups,
                    caps: action.data.caps,
                    capFrequencyList: action.data.capFrequencyList,
                    fetching: false,
                };
            case TYPE.UPDATE_PERMISSIONS_PREVIEW_SUCCESS:
                return {
                    ...storeProp,
                    futurePermissions: action.permissions,
                    idActivity: action.idActivity,
                    idTransaction: action.idTransaction,
                    credentialGroups: action.credentialGroups,
                };
            case TYPE.UPDATE_PERMISSIONS_REQUEST:
                return {
                    ...storeProp,
                    fetching: true,
                };
            case TYPE.UPDATE_PERMISSIONS_REQUEST_SUCCESS:
                return {
                    ...storeProp,
                    permissions: storeProp.futurePermissions,
                    fetching: false,
                    futurePermissions: null,
                };

            case TYPE.LOAD_PERMISSIONS_TICKET_REQUEST:
                return {
                    ...storeProp,
                    fetchingTicket: true,
                };
            case TYPE.LOAD_PERMISSIONS_TICKET_SUCCESS:
            case TYPE.LOAD_PERMISSIONS_TICKET_FAILURE:
                return {
                    ...storeProp,
                    fetchingTicket: false,
                };
            case TYPE_APP.CLEAN_UP:
                return {
                    ...storeProp,
                    ...PROP.defaults,
                };
            case TypesBackoffice.READ_USER_DATA_SUCCESS:
                return {
                    ...storeProp,
                    fetching: false,
                    groups: action.userData.groups,
                    permissions: action.userData.permissions,
                    products: action.userData.products,
                };
            default:
                return { ...storeProp };
        }
    };
