import PropTypes from "prop-types";

import { ZERO_NUMBER } from "~/constants";
import { MODE } from "~/constants/form";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "creditCard";

export const PATH = {
    CHANGE_CREDIT_CARD_STATUS: "/creditCard.changeStatus",
    CREDITCARD_LIST_STATEMENT_DETAILS: "/creditCard.listStatementDetails",
    CREDITCARDS_LIST_STATEMENTS: "/creditCards.listStatements",
    CREDITCARDS_LIST: "/creditCards.list",
    CREDITCARDS_READ: "/creditCards.read",
    CREDITCARDS_STATEMENT_DOWNLOAD: "/creditCards.statement.download",
    CREDITCARDS_STATEMENTS: "/creditCards.statements.execute",
    LIMIT_INCREASE_PRE: "/creditCards.limit.increase.pre",
    LIMIT_INCREASE_PREVIEW: "/creditCards.limit.increase.preview",
    LIMIT_INCREASE_SEND: "/creditCards.limit.increase.send",
    NOTES_EDIT_STATEMENT_NOTE: "/notes.editStatementNote",
    PURCHASE_NOTIFICATION_PRE: "/creditCards.purchaseNotification.pre",
    PURCHASE_NOTIFICATION_PREVIEW: "/creditCards.purchaseNotification.preview",
    PURCHASE_NOTIFICATION_SEND: "/creditCards.purchaseNotification.send",
    TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_PRE: "/creditCards.tripOrOnlinePurchaseNotification.pre",
    TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_PREVIEW: "/creditCards.tripOrOnlinePurchaseNotification.preview",
    TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_SEND: "/creditCards.tripOrOnlinePurchaseNotification.send",
};

export const TYPE = FactoryType(NAME, [
    "CANCEL_CUSTOM_TRANSACTION_REQUEST",
    "CHANGE_STATUS_FAILURE",
    "CHANGE_STATUS_REQUEST",
    "CHANGE_STATUS_SUCCESS",
    "CLEAN_UP",
    "CREDIT_CARD_MOVEMENTS_FAILURE",
    "CREDIT_CARD_MOVEMENTS_REQUEST",
    "CREDIT_CARD_MOVEMENTS_SUCCESS",
    "CREDIT_CARD_STATEMENT_DOWNLOAD_FAILURE",
    "CREDIT_CARD_STATEMENT_DOWNLOAD_REQUEST",
    "CREDIT_CARD_STATEMENT_DOWNLOAD_SUCCESS",
    "CREDIT_CARD_STATEMENTS_FAILURE",
    "CREDIT_CARD_STATEMENTS_REQUEST",
    "CREDIT_CARD_STATEMENTS_SUCCESS",
    "DETAILS_FAILURE_MOVEMENT",
    "DETAILS_FAILURE",
    "DETAILS_REQUEST_MOVEMENT",
    "DETAILS_REQUEST",
    "DETAILS_SUCCESS_MOVEMENT",
    "DETAILS_SUCCESS",
    "FETCH_MORE_MOVEMENTS_FAILURE",
    "FETCH_MORE_MOVEMENTS_REQUEST",
    "FETCH_MORE_MOVEMENTS_SUCCESS",
    "FORM_TO_STEP_1",
    "FORM_TO_STEP_1",
    "HIDE",
    "LIMIT_INCREASE_PRE_FAILURE",
    "LIMIT_INCREASE_PRE_REQUEST",
    "LIMIT_INCREASE_PRE_SUCCESS",
    "LIMIT_INCREASE_PREVIEW_FAILURE",
    "LIMIT_INCREASE_PREVIEW_REQUEST",
    "LIMIT_INCREASE_PREVIEW_SUCCESS",
    "LIMIT_INCREASE_SEND_FAILURE",
    "LIMIT_INCREASE_SEND_REQUEST",
    "LIMIT_INCREASE_SEND_SUCCESS",
    "LIST_FAILURE",
    "LIST_REQUEST",
    "LIST_SUCCESS",
    "MOVEMENTS_FAILURE",
    "MOVEMENTS_REQUEST",
    "MOVEMENTS_SUCCESS",
    "PURCHASE_NOTIFICATION_PRE_FAILURE",
    "PURCHASE_NOTIFICATION_PRE_REQUEST",
    "PURCHASE_NOTIFICATION_PRE_SUCCESS",
    "PURCHASE_NOTIFICATION_PREVIEW_FAILURE",
    "PURCHASE_NOTIFICATION_PREVIEW_REQUEST",
    "PURCHASE_NOTIFICATION_PREVIEW_SUCCESS",
    "PURCHASE_NOTIFICATION_SEND_FAILURE",
    "PURCHASE_NOTIFICATION_SEND_REQUEST",
    "PURCHASE_NOTIFICATION_SEND_SUCCESS",
    "READ_CREDIT_CARD_FAILURE",
    "READ_CREDIT_CARD_REQUEST",
    "READ_CREDIT_CARD_SUCCESS",
    "READ_TRANSACTION_FAILURE",
    "READ_TRANSACTION_REQUEST",
    "READ_TRANSACTION_SUCCESS",
    "SHOW",
    "SIGN_TRANSACTION_FAILURE",
    "SIGN_TRANSACTION_PREVIEW",
    "SIGN_TRANSACTION_REQUEST",
    "SIGN_TRANSACTION_SUCCESS",
    "TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_PRE_FAILURE",
    "TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_PRE_REQUEST",
    "TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_PRE_SUCCESS",
    "TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_PREVIEW_FAILURE",
    "TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_PREVIEW_REQUEST",
    "TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_PREVIEW_SUCCESS",
    "TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_SEND_FAILURE",
    "TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_SEND_REQUEST",
    "TRIP_OR_ONLINE_PURCHASE_NOTIFICATION_SEND_SUCCESS",
    "UPDATE_NOTE_FAILURE",
    "UPDATE_NOTE_REQUEST",
    "UPDATE_NOTE_SUCCESS",
]);

export const PROP = {};

PROP.members = {
    creditCardMovementDetail: {
        detail: PropTypes.object,
        isFetching: PropTypes.bool,
    },
    creditCards: {
        isFetching: PropTypes.bool,
        list: PropTypes.array,
    },
    creditCardList: {
        pageNumber: PropTypes.number,
        pageNumberAux: PropTypes.number,
        movements: PropTypes.array,
        creditCardNumber: PropTypes.string,
        hasMoreMovements: PropTypes.bool,
    },
};

PROP.types = {
    ...TypesRedux,
    creditCardMovementDetail: PropTypes.shape(PROP.members.creditCardMovementDetail),
    creditCards: PropTypes.shape(PROP.members.creditCards),
    creditCardList: PropTypes.arrayOf(PropTypes.shape(PROP.members.creditCardList)),
    detail: PropTypes.shape({
        accountHolder: PropTypes.string,
        creditCardLimit: PropTypes.number,
        idProduct: PropTypes.string.isRequired,
        lastPaymentAmount: PropTypes.number,
        lastPaymentDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.number]),
        nextClosingDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.number]),
    }),
    from: PropTypes.string,
    hasMoreMovements: PropTypes.bool,
    isChangingStatusCreditCard: PropTypes.bool,
    isDownloading: PropTypes.bool,
    isFetching: PropTypes.bool,
    isFetchingMovements: PropTypes.bool,
    isFetchingMovementsByCreditCard: PropTypes.bool,
    isOptionsVisible: PropTypes.bool,
    movements: PropTypes.array,
    pageNumber: PropTypes.number,
    statements: PropTypes.object,
    timezoneOffset: PropTypes.number,
};

PROP.defaults = {
    ...DefaultsRedux,
    creditCardMovementDetail: {
        detail: null,
        isFetching: false,
    },
    creditCards: {
        isFetching: false,
        list: [],
    },
    creditCardList: [],
    detail: null,
    from: null,
    hasMoreMovements: false,
    isChangingStatusCreditCard: false,
    isDownloading: false,
    isFetching: false,
    isFetchingMovements: false,
    isFetchingMovementsByCreditCard: false,
    isOptionsVisible: false,
    mode: MODE.EDIT,
    movements: [],
    pageNumber: 0,
    statements: {},
    timezoneOffset: ZERO_NUMBER,
};
