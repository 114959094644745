import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SPACE_STR, EMPTY_STR } from "~/constants";
import { SelectorsAction as SelectorsActionComex } from "~/store/comex/";
import { SelectorsStore as SelectorsStoreComexExport } from "~/store/comex/export";
import { SelectorsAction as SelectorsActionFile } from "~/store/files";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import { SelectorsAction as SelectorsActionForm } from "~/store/form";
import * as i18nUtils from "~/util/i18n";
import { toDate as ToDate } from "~/util/date";

import Box from "~/components/Box";
import DataDate from "~/components/DataDate";
import GridLayout from "~/components/GridLayout";
import I18n from "~/components/I18n";
import Ticket from "~/components/Ticket/Ticket";
import FormattedAmount from "~/pages/_components/FormattedAmount";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        transaction: PropTypes.shape({
            idTransaction: PropTypes.string,
            idTransactionStatus: PropTypes.string,
            valueDateAsString: PropTypes.string,
            executing: PropTypes.bool,
        }).isRequired,
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

export function Component(props) {
    const { transaction, ticketConfirmation, idForm, dispatch } = props;
    const { data } = transaction && transaction;
    const COLUMN_HEADINGS = [
        i18nUtils.get(`${idForm}.columnheaders.documents.document`),
        i18nUtils.get(`${idForm}.columnheaders.pdf.description`),
        i18nUtils.get(`${idForm}.columnheaders.documents.original`),
        i18nUtils.get(`${idForm}.columnheaders.documents.copies`),
    ];

    const downloadFile = (idFile, fileName) => {
        dispatch(SelectorsActionFile.downloadFileRequest({ idFile, fileName }));
    };

    const shareTicket = (idTransactionToRead) => {
        dispatch(SelectorsActionTransactions.shareTicketRequest({ idTransactionToRead }));
    };
    return (
        <section id={Style.ID}>
            {transaction.data && (
                <Ticket
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}
                    handleModify={handleModify}
                    hasMobileComponent
                    displayDownloadPDF
                    handleDownloadPDF={() => shareTicket(transaction.idTransaction)}>
                    <Box flex directionColumn align="flex-start" className="mb-3">
                        <DataDate value={data?.valueDate || ToDate(data?.scheduler?.valueDate)} />
                        <div className="opening">
                            <I18n id={`${idForm}.letter.opening`} tag="p" className="mt-3" />
                        </div>
                        <div className="info-label">
                            <h4 className="bold">{i18nUtils.get(`${idForm}.letter.operationNumber`)}</h4>
                            <span>{data?.invoiceNumber}</span>
                        </div>
                        <div className="info-label">
                            <h4 className="bold">{i18nUtils.get(`${idForm}.letter.invoiceAmount`)}</h4>
                            <FormattedAmount
                                currency={data?.invoiceAmountCurrency || data?.invoiceAmount?.currency}
                                quantity={parseFloat(data?.invoiceAmount) || parseFloat(data?.invoiceAmount?.amount)}
                            />
                        </div>
                        <div className="info-label">
                            <span>{i18nUtils.get(`${idForm}.letter.documents`)}</span>
                        </div>

                        <div className="info-label table">
                            <GridLayout className="header ticket-table-section">
                                {COLUMN_HEADINGS.map((heading) => {
                                    return <div>{heading}</div>;
                                })}
                            </GridLayout>
                            {data?.documentList &&
                                data?.documentList.map((document) => (
                                    <GridLayout className="item ticket-table-section">
                                        <div>
                                            {i18nUtils.get(`comex.creditLetter.modal.documentTypes.${document.type}`)}
                                        </div>
                                        <div>{document.description}</div>
                                        <div>{document.originals}</div>
                                        <div>{document.copies}</div>
                                    </GridLayout>
                                ))}
                        </div>

                        <div className="info-label">
                            <span className="inline-span">
                                {i18nUtils.get(`${idForm}.letter.sendDocuments`)}
                                {SPACE_STR}
                                {data?.sendDocuments}
                            </span>
                        </div>

                        <div className="info-label">
                            <span className="inline-span">
                                {i18nUtils.get(`${idForm}.letter.documentsDelivery1`)}
                                <p className="delivery-type">
                                    {i18nUtils.get(`${idForm}.documentsDelivery.${data?.documentsDelivery}`)}
                                </p>
                                {data?.documentsDelivery === "ACEPTACIONDELETRA" &&
                                    i18nUtils.get(`${idForm}.letter.documentsDelivery2`, EMPTY_STR, {
                                        DELIVERY_DAYS: data?.documentsDeliveryDays,
                                        DAYS_OF: data?.documentsDeliveryDaysOf,
                                    })}
                            </span>
                        </div>

                        <div className="info-label">
                            <span className="inline-span">
                                {i18nUtils.get(`${idForm}.letter.turned`)}
                                {SPACE_STR}
                                {data?.turned}
                            </span>
                        </div>

                        <div className="info-label">
                            <span>
                                {i18nUtils.get(`${idForm}.letter.foreignExpenses`)}
                                {SPACE_STR}
                                {i18nUtils.get(`${idForm}.letter.foreignExpenses.${data.foreignExpenses}`)}
                            </span>
                        </div>

                        {data.instructions && (
                            <div className="info-label">
                                <span className="inline-span">
                                    {i18nUtils.get(`${idForm}.letter.instructions`)}
                                    {SPACE_STR}
                                    {i18nUtils.get(`${idForm}.letter.instructions.${data?.instructions}`)}
                                </span>
                            </div>
                        )}

                        {(data.courierName || data.accountNumber) && (
                            <div className="info-label">
                                <span>
                                    {i18nUtils.get(`${idForm}.letter.sendDocumentsBy`)}
                                    {data.courierName && (
                                        <React.Fragment>
                                            {SPACE_STR}
                                            {i18nUtils.get(`${idForm}.letter.courierName`, EMPTY_STR, {
                                                COURIER_NAME: data.courierName,
                                            })}
                                        </React.Fragment>
                                    )}
                                    {data.accountNumber && (
                                        <React.Fragment>
                                            {SPACE_STR}
                                            {i18nUtils.get(`${idForm}.letter.accountNumber`, EMPTY_STR, {
                                                ACCOUNT_NUMBER: data.accountNumber,
                                            })}
                                        </React.Fragment>
                                    )}
                                </span>
                            </div>
                        )}
                        <I18n id={`${idForm}.letter.terms`} tag="p" className="mt-3 mb-3" />
                        {data.observations && (
                            <div className="info-label">
                                <span className="inline-span">
                                    {i18nUtils.get(`${idForm}.letter.observations`)}
                                    {SPACE_STR}
                                    {data.observations}
                                </span>
                            </div>
                        )}
                    </Box>
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { dispatch } = props;
        const { idTransaction } = transaction;
        dispatch(
            SelectorsActionComex.cancelTransactionCustom({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        const { dispatch } = props;
        dispatch(SelectorsActionComex.signTransactionPreview({ transaction }));
    }

    function handleModify() {
        const { idTransaction } = transaction;
        dispatch(SelectorsActionForm.modifyTransaction({ idTransactionToMove: idTransaction }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreComexExport.getTransaction(store),
        idForm: SelectorsStoreComexExport.getPresentBillingDocumentsIdForm(store),
    };
};

export default Connect(mapStateToProps)(Component);
