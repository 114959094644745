import React from "react";

import { connect as Connect } from "react-redux";

import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import FileUploader from "~/pages/_components/FileUploader";
import FieldError from "~/pages/_components/fields/FieldError";
import FieldLabel from "~/pages/_components/fields/FieldLabel";

export const NAME = "FileUploadConnectedParties";

export const PROP = {
    types: {
        ...TypesRedux,
    },
    defaults: {
        ...DefaultsRedux,
    },
};

const ACTIVITY_ID = "connectedParties.registerBantotal";
const FIELD_ID = "files";
const MAX_FILE_SIZE_MB = 2;
const ACCEPTED_FILE_TYPES = ["image/png", "image/jpeg", "image/tiff", "image/gif", "image/bmp", "image/jpg"];

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    handleFileProcess = (response) => {
        const { form } = this.props;
        const { values } = form;
        const { data } = response;
        const newData = [...values.files];
        newData.push(data.idFile);
        form.setFieldValue(FIELD_ID, newData);
    };

    handleRemoveFile = (serverId) => {
        const { form } = this.props;
        const { values } = form;

        const index = values.files.indexOf(serverId.toString());
        const filesCopy = [...values.files];
        if (index >= 0) {
            filesCopy.splice(index, 1);
        }
        form.setFieldValue(FIELD_ID, filesCopy);
    };

    render() {
        const { value, setValue, form, field, name, idForm } = this.props;
        return (
            <React.Fragment>
                <FieldLabel labelKey={`${idForm}.${FIELD_ID}.label`} />

                <div className="field-files--information">
                    <span>{UtilsI18n.get(`${idForm}.${FIELD_ID}.permitedExtentionsAndSize`)}</span>
                </div>
                <FileUploader
                    name={name}
                    idActivity={ACTIVITY_ID}
                    idForm=""
                    idFormField={FIELD_ID}
                    files={value}
                    allowMultiple
                    maxFileSize={`${MAX_FILE_SIZE_MB}mb`}
                    maxTotalFileSize={`${MAX_FILE_SIZE_MB}mb`}
                    maxFiles={2}
                    acceptedFileTypes={ACCEPTED_FILE_TYPES}
                    onFileProcess={this.handleFileProcess}
                    labelIdle={UtilsI18n.get(`${idForm}.${FIELD_ID}.attachDocument`)}
                    onRemoveFile={this.handleRemoveFile}
                    setValue={setValue}
                />
                <FieldError error={form?.errors[field.name]} />
            </React.Fragment>
        );
    }
}

export default Connect(null)(Component);
