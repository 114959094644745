import React from "react";

import { TIMEOUT } from "~/constants";
import { PROP as STORE_PROP } from "~/store/preferentialTradingPrice";

export const NAME = "Timer";
export const PROP = {
    types: { ...STORE_PROP.timer },
    defaults: { ...STORE_PROP.defaultTimer },
};

export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    state = {
        initialTime: 0,
        totalSeconds: 0,
        finishTimer: false,
    };

    componentDidMount() {
        let { second } = this.props;
        second = second && second > 0 ? second : 1;
        this.setState({ currentTime: second, initialTime: new Date(), totalSeconds: second });
        this.timer = setTimeout(this.interval, TIMEOUT); // TODO: unificar el uso de los timer, en session about to expire se hace de una forma diferente
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    timeOut = () => {
        const { handleTimeOut } = this.props;
        handleTimeOut();
    };

    timeRenderer = (timeInSeconds) => {
        let secondsCounter = timeInSeconds % 60;
        const minuteCounter = Math.floor(timeInSeconds / 60);

        if (secondsCounter < 10) {
            secondsCounter = `0${secondsCounter}`;
        }
        return (
            <span>
                {minuteCounter}:{secondsCounter}
            </span>
        );
    };

    interval = () => {
        clearTimeout(this.timer);
        const { handleTimeOut, fetching, errors } = this.props;
        const { finishTimer, initialTime, totalSeconds } = this.state;
        const now = new Date();
        const difference = Math.floor((now - initialTime) / 1000);

        if (difference < totalSeconds) {
            if (finishTimer && errors) {
                handleTimeOut();
            } else {
                this.setState((prevState) => ({ ...prevState, currentTime: totalSeconds - difference }));
                this.timer = setTimeout(this.interval, TIMEOUT);
            }
        } else if (!fetching) {
            handleTimeOut();
            // Solamente se ejecuta la accion en el caso de que no este fetching
            // para poder contemplar los casos en los que coincide el momento del timout con el request de la action
        } else {
            // Si se termina el tiempo en el mismo tiempo en que se esta haciendo el send
            // se resetea el timer para que no quede deje de correr
            this.setState((prevState) => ({ ...prevState, initialTime: new Date(), finishTimer: true }));
            // El finishTimer se utiliza para el caso donde al finalizar el timer esta en fetching pero la respuesta al servidor devolvio error
            this.timer = setTimeout(this.interval, TIMEOUT);
        }
    };

    render() {
        const { currentTime } = this.state;
        const { customTimeRender } = this.props;
        /* Por defecto usamos el renderer interno del componente,
        // pero podemos pasarle otro renderer si asi se desea
        */
        const timeRenderFunction = typeof customTimeRender === "function" ? customTimeRender : this.timeRenderer;

        return <React.Fragment>{timeRenderFunction(currentTime)}</React.Fragment>;
    }
}

export default Component;
