import React from "react";

import { Field } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import {
    COMMA,
    COMMA_PLUS_SPACE,
    DOT,
    EMPTY_STR,
    NO_SPOUSE,
    ONLY_NUMBER,
    PIPE_SEPARATOR,
    PROFESSION_UNEMPLOYED,
    REGEX_IGNORE_ACCENTS,
    ZERO_NUMBER,
} from "~/constants";
import { MODE } from "~/constants/form";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { SelectorsStore } from "~/store/settings";
import * as ConfigUtil from "~/util/config";
import * as UtilI18n from "~/util/i18n";
import { numberFormat as NumberFormatUtil } from "~/util/number";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import NumberInput from "~/pages/_components/fields/NumberInput";
import TextField from "~/pages/_components/fields/TextField";

import InfoUser from "../InfoUser";
import DocumentField from "../field/DocumentField";
import FileUploader from "../field/FileUploader";
import RadioButton from "../field/RadioButton";
import SelectorType from "../field/SelectorType";
import Style from "./FormPersonalData.rules.scss";

const FORM_ID = "change.PersonalData";

export const NAME = "FormPersonalData";

export const PROP = {
    types: {
        ...TypesRedux,
        countryList: PropTypes.array,
        documents: PropTypes.array,
        infoUser: PropTypes.shape({
            name: PropTypes.string,
            document: PropTypes.string,
            documentType: PropTypes.string,
            documentCountry: PropTypes.string,
            sex: PropTypes.string,
            birthDate: PropTypes.string,
            email: PropTypes.string,
        }),
        isCorporate: PropTypes.bool.isRequired,
        maritalStatusList: PropTypes.array,
        professions: PropTypes.array,
        values: PropTypes.object.isRequired,
    },
    defaults: {
        ...DefaultsRedux,
        countryList: [],
        documents: [],
        infoUser: {
            name: EMPTY_STR,
            document: EMPTY_STR,
            documentType: EMPTY_STR,
            documentCountry: EMPTY_STR,
            sex: EMPTY_STR,
            birthDate: EMPTY_STR,
            email: EMPTY_STR,
        },
        isCorporate: true,
        maritalStatusList: [],
        professions: [],
    },
};

export function Component({
    countryList,
    dispatch,
    documents,
    errors,
    fetching,
    infoUser,
    isCorporate,
    lang,
    maritalStatusList,
    personalData,
    professions,
    values,
    ...rest
}) {
    const modifyPersonalData = { ...personalData, monthlyIncome: parseFloat(personalData?.monthlyIncome) };
    const { monthlyIncome } = modifyPersonalData;
    const { decimalSeparator, thousandSeparator } = NumberFormatUtil(lang);

    // Se quita formato de número a values?.monthlyIncome porque:
    // El input maneja el valor con formato 1.000,48 - punto como separador de mil y coma separador de decimal
    // mientras que la función parseFloat espera el string 1000.48 - punto como separador de decimal
    const valueMonthlyIncomeNoFormat = (values?.monthlyIncome || EMPTY_STR)
        .toString()
        .replace(DOT, EMPTY_STR)
        .replace(COMMA, DOT);
    const valueMonthlyIncomeFloat = parseFloat(valueMonthlyIncomeNoFormat);
    const changeIncome = monthlyIncome !== valueMonthlyIncomeFloat;

    const customFilter = (option, searchText) => {
        const optionLowerCase = option.label ? option.label.toLowerCase() : option.name.toLowerCase();
        const text = searchText.toLowerCase();

        if (
            optionLowerCase.normalize("NFD").replace(REGEX_IGNORE_ACCENTS, EMPTY_STR).includes(text) &&
            optionLowerCase.charAt(0).includes(text.charAt(0))
        ) {
            return true;
        }

        return false;
    };

    const typeFile = ConfigUtil.get("client.accounts.attachments.allowedFileExtensions")
        ?.split(PIPE_SEPARATOR)
        .join(COMMA_PLUS_SPACE);

    const tooltip = `${UtilI18n.get("change.PersonalData.filesLegal.size")} ${Number(
        ConfigUtil.get("client.accounts.attachments.maxFileSize"),
    ).toFixed(1)} MB ${UtilI18n.get("change.PersonalData.filesLegal.information")} ${typeFile} .`;

    const isUnemployed = PROFESSION_UNEMPLOYED.includes(parseInt(values.profession, 10));
    const shouldUploadDoc = isUnemployed ? valueMonthlyIncomeFloat > 50 : changeIncome;

    return (
        <div id={Style.ID} className="container--data">
            <InfoUser
                idForm={FORM_ID}
                dispatch={dispatch}
                data={infoUser}
                mobilePhone={personalData.mobilePhone}
                wait={fetching}
            />
            {!isCorporate && (
                <React.Fragment>
                    <div className="field-spacing field-phone">
                        <Field
                            idForm={FORM_ID}
                            name="phone"
                            component={TextField}
                            autoFocus={false}
                            mode={MODE.EDIT}
                            hidePlaceholder
                            pattern={ONLY_NUMBER}
                            maxLength={20}
                        />
                    </div>
                    <div className="field-spacing">
                        <SelectorType
                            name="profession"
                            options={professions}
                            label={UtilI18n.get("change.PersonalData.profession.label")}
                            valueKey="id"
                            labelKey="label"
                            customFilter={(option, searchText) => customFilter(option, searchText)}
                            {...rest}
                        />
                    </div>

                    {values?.profession === ZERO_NUMBER && (
                        <div className="field-spacing">
                            <Field
                                idForm={FORM_ID}
                                name="otherProfession"
                                hidelabel
                                component={TextField}
                                maxLength={30}
                                autoFocus={false}
                                mode={MODE.EDIT}
                                hidePlaceholder
                            />
                        </div>
                    )}

                    <div className="field-spacing monthlyIncome">
                        <Field
                            idForm={FORM_ID}
                            name="monthlyIncome"
                            component={NumberInput}
                            autoFocus={false}
                            mode={MODE.EDIT}
                            thousandSeparator={thousandSeparator}
                            decimalSeparator={decimalSeparator}
                            decimalScale={2}
                            thousandsGroupStyle="thousand"
                            fixedDecimalScale
                            defaultValue={0}
                            inputmode="numeric"
                            hidePlaceholder
                            allowNegative
                            allowLeadingZeros={false}
                        />
                    </div>

                    {shouldUploadDoc && (
                        <Field
                            name="files"
                            idForm={FORM_ID}
                            component={FileUploader}
                            value={EMPTY_STR}
                            tooltip={tooltip}
                        />
                    )}

                    <div className="field-spacing">
                        <SelectorType
                            name="maritalStatus"
                            options={maritalStatusList}
                            label={UtilI18n.get("change.PersonalData.maritalStatus.label")}
                            valueKey="id"
                            labelKey="name"
                            customFilter={(option, searchText) => customFilter(option, searchText)}
                        />
                    </div>
                    {!NO_SPOUSE.includes(values.maritalStatus) && (
                        <React.Fragment>
                            <div className="field-spacing">
                                <Field
                                    idForm={FORM_ID}
                                    name="spouseName"
                                    component={TextField}
                                    maxLength={30}
                                    autoFocus={false}
                                    mode={MODE.EDIT}
                                    hidePlaceholder
                                />
                            </div>

                            <div className="field-spacing">
                                <Field
                                    idForm={FORM_ID}
                                    name="spouseLastname"
                                    component={TextField}
                                    autoFocus={false}
                                    mode={MODE.EDIT}
                                    maxLength={30}
                                    hidePlaceholder
                                />
                            </div>

                            <div className="field-document field-spacing">
                                <Field
                                    idForm={FORM_ID}
                                    name="spouseDocument"
                                    component={DocumentField}
                                    data={{ countries: countryList, documentTypes: documents }}
                                    autoFocus={false}
                                    mode={MODE.EDIT}
                                    handleError
                                    hidePlaceholder
                                    hideDocumentPlaceholder
                                    {...rest}
                                />
                            </div>
                        </React.Fragment>
                    )}

                    <RadioButton
                        value="own"
                        autoFocus={false}
                        hidePlaceholder
                        name="condition"
                        keyprefix={`${FORM_ID}.own`}
                    />

                    <RadioButton
                        value="thirdParties"
                        autoFocus={false}
                        hidePlaceholder
                        name="condition"
                        keyprefix={`${FORM_ID}.thirdParties`}
                    />
                </React.Fragment>
            )}
        </div>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    countryList: SelectorsStore.getCountryList(store),
    documents: SelectorsStore.getDocuments(store),
    infoUser: SelectorsStore.getInfoUser(store),
    lang: SelectorsStoreI18n.getLang(store),
    loadData: SelectorsStore.getLoadData(store),
    maritalStatusList: SelectorsStore.getMaritalStatusList(store),
    personalData: SelectorsStore.getPersonalData(store),
    professions: SelectorsStore.getProfessions(store),
});

export default Connect(mapStateToProps)(Component);
