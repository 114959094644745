import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "transactions";

export const PATH = {
    CORE_DELETE_TRANSACTION_DRAFT: "/core.deleteTransactionDraft",
    GPI_TRACKING: "/transactions.gpi.tracking",
    LIST_ID_ACTIVITIES_EXECUTED: "/transactions.list.pre",
    TRANSACTIONS_CANCEL_BATCH_PREVIEW: "/transactions.cancelbatch.preview",
    TRANSACTIONS_CANCEL_BATCH_SEND: "/transactions.cancelbatch.send",
    TRANSACTIONS_LIST: "/transactions.list.execute",
    TRANSACTIONS_SHARE_TICKET_SEND: "/transactions.shareTicket.send",
    TRANSACTIONS_SIGN_BATCH_PREVIEW: "/transactions.signbatch.preview",
    TRANSACTIONS_SIGN_BATCH_SEND: "/transactions.signbatch.send",
};

export const TYPE = FactoryType(NAME, [
    "APPROVE_TRANSACTIONS_FAILURE",
    "APPROVE_TRANSACTIONS_PREVIEW_FAILURE",
    "APPROVE_TRANSACTIONS_PREVIEW_REQUEST",
    "APPROVE_TRANSACTIONS_PREVIEW_SUCCESS",
    "APPROVE_TRANSACTIONS_REQUEST",
    "APPROVE_TRANSACTIONS_SUCCESS",
    "CANCEL_TRANSACTIONS_FAILURE",
    "CANCEL_TRANSACTIONS_PREVIEW_FAILURE",
    "CANCEL_TRANSACTIONS_PREVIEW_REQUEST",
    "CANCEL_TRANSACTIONS_PREVIEW_SUCCESS",
    "CANCEL_TRANSACTIONS_REQUEST",
    "CANCEL_TRANSACTIONS_SUCCESS",
    "CHECK_IF_THERE_ARE_QRMODO_RUNNING_TRANSACTIONS",
    "CLEAR_PREVIEW",
    "CLEAR_TRANSACTION_LIST",
    "DELAYED_START",
    "DELAYED_STOP",
    "DELETE_DRAFT_FAILURE",
    "DELETE_DRAFT_REQUEST",
    "DELETE_DRAFT_SUCCESS",
    "GPI_TRACKING",
    "GPI_TRACKING_FAILURE",
    "GPI_TRACKING_SUCCESS",
    "LEAVE_QR_MODO_PAYMENTS_PAGE",
    "LIST_ID_ACTIVITIES_EXECUTED_FAILURE",
    "LIST_ID_ACTIVITIES_EXECUTED_REQUEST",
    "LIST_ID_ACTIVITIES_EXECUTED_SUCCESS",
    "LOAD_LIST_FAILURE",
    "LOAD_LIST_REQUEST",
    "LOAD_LIST_SUCCESS",
    "LOAD_MORE_TRANSACTIONS_REQUEST",
    "LOAD_MORE_TRANSACTIONS_SUCCESS",
    "LOADING",
    "RESET_FILTER",
    "RESUME_TRANSACTIONS",
    "SET_APPROVING",
    "SET_FILTERS",
    "SHARE_TICKET_FAILURE",
    "SHARE_TICKET_REQUEST",
    "SHARE_TICKET_SUCCESS",
]);

export const PROP = {};

PROP.types = {
    credentialsGroups: PropTypes.array,
    currentLocation: PropTypes.string,
    delayedTransactions: PropTypes.string,
    errors: PropTypes.object,
    filters: PropTypes.object,
    hasMoreData: PropTypes.bool,
    idActivitiesList: PropTypes.array,
    idTransactionList: PropTypes.array,
    isApproving: PropTypes.string,
    isDelayed: PropTypes.bool,
    isDeletingDraft: PropTypes.bool,
    isFetching: PropTypes.bool,
    isFilterChanged: PropTypes.bool,
    isInQRMODOPage: PropTypes.bool,
    pageNumber: PropTypes.number,
    previousLocation: PropTypes.string,
    resumeTransaction: PropTypes.object,
    ticketSelected: PropTypes.bool,
    totalTransactions: PropTypes.string,
    transactions: PropTypes.object,
    ...TypesRedux,
};

PROP.defaults = {
    credentialsGroups: [],
    currentLocation: null,
    delayedTransactions: null,
    errors: null,
    filters: null,
    hasMoreData: false,
    idActivitiesList: [],
    idTransactionList: [],
    isApproving: null,
    isDelayed: false,
    isDeletingDraft: false,
    isFetching: false,
    isFilterChanged: false,
    isInQRMODOPage: false,
    pageNumber: 1,
    previousLocation: null,
    resumeTransaction: null,
    ticketSelected: false,
    totalTransactions: null,
    transactions: null,
    ...DefaultsRedux,
};
