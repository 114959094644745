import React from "react";

import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsStore as StoreWidget, SelectorsAction as ActionWidget } from "~/store/widget";
import * as UtilsI18n from "~/util/i18n";
import * as UtilsNumber from "~/util/number";

import Style from "./TermDeposit.rules.scss";
import Widget from "./_common/_Widget";

export const { TYPE, NAME } = Style;

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const {
        activeEnvironment: {
            permissions: { termDepositCMB = false },
        },
        data,
        dispatch,
        fetching,
        store: widget,
        ...rest
    } = props;
    const { currencies } = data;

    React.useEffect(() => {
        dispatch(ActionWidget.listRequest({ widget }));
    }, [dispatch, widget]);

    let headerHref = EMPTY_STR;

    if (termDepositCMB) {
        headerHref = "/investment/termdeposit";
    }

    return (
        <Widget
            {...rest}
            id={Style.ID}
            wait={fetching}
            header-title="menu.termDeposit"
            header-icon="depositos-a-plazo-fijo-widget.svg"
            header-href={headerHref}>
            <section>
                {currencies && (
                    <div className="container-card">
                        <ul className="table-row header">
                            <li>
                                <span>{UtilsI18n.get(`widget.termDeposit.term`)}</span>
                            </li>

                            {currencies.map((currency) => {
                                return (
                                    <li>
                                        <span>{UtilsI18n.get(`widget.termDeposit.rate`)}</span>
                                        <span className="currency">
                                            {UtilsI18n.get(`core.currency.label.${currency.currency}`)}
                                        </span>
                                    </li>
                                );
                            })}
                        </ul>
                        {currencies[0].terms.map((term, index) => {
                            return (
                                <div>
                                    <ul className="table-row body">
                                        <li>
                                            <li>
                                                <span className="day-term">
                                                    {`${term.days} ${UtilsI18n.get(`widget.termDeposit.days`)}`}
                                                </span>
                                            </li>
                                        </li>
                                        <li>
                                            <span>
                                                {UtilsNumber.formatNumber(
                                                    currencies[0].terms[index].rates[0].rate,
                                                    UtilsI18n.getLang(),
                                                )}
                                                {UtilsI18n.get(`widget.termDeposit.percentageSymbol`)}
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                {UtilsNumber.formatNumber(
                                                    currencies[1].terms[index].rates[0].rate,
                                                    UtilsI18n.getLang(),
                                                )}
                                                {UtilsI18n.get(`widget.termDeposit.percentageSymbol`)}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            );
                        })}
                    </div>
                )}
            </section>
        </Widget>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

function mapStateToProps(store, props) {
    const { store: widget } = props;
    const { fetching, data = {} } = StoreWidget.getWidget(store, widget) || {};
    const activeEnvironment = SelectorsStoreSession.getActiveEnvironment(store);

    return {
        activeEnvironment,
        data,
        fetching,
        getMessage: (key) => StoreI18n.getMessage(store, key),
    };
}

export default Connect(mapStateToProps)(Component);
