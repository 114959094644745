import React from "react";

import { goBack as GoBack } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router";

import { CC_TYPES, CDP_CURRENCY, DATA_NOT_AVAILABLE, EMPTY_STR, SCOPE } from "~/constants";
import Container from "~/containers/Internal/Form/Simple";
import {
    SelectorsAction as ActionCC,
    SelectorsStore as StoreCC,
    PROP as PROP_CC,
} from "~/store/creditCards/creditCard";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as UtilDate from "~/util/date";

import ConfirmationModal from "../_components/ConfirmationModal";
import CreditCardHeader from "./CreditCardHeader";
import Style from "./Details.rules.scss";
import Movements from "./_Movements";
import OperationsButton from "./_OperationsButton";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {
        creditCard: PROP_CC.types.detail,
        i18n: PropTypes.object,
        id: PropTypes.string.isRequired,
        isChangingEnvironment: PropTypes.bool,
        isFetching: PropTypes.bool,
    },
    defaults: {
        creditCard: PROP_CC.defaults.detail,
        i18n: {
            infoButton: "Operaciones",
            infoTitle: "Tarjeta",
            labelAccount: "Número de cuenta",
            labelDateDue: "Próximo vencimiento",
            labelDateNext: "Próximo cierre",
            labelInfo: "Información",
            labelLimit: "Límite de crédito",
            labelMovs: "Movimientos",
            loading: "Cargando",
            notAvailable: DATA_NOT_AVAILABLE,
        },
        isChangingEnvironment: false,
        isFetching: false,
    },
};

const DATE_RANGE = {
    LAST_1: UtilDate.getLastMonthPeriod(),
    LAST_2: UtilDate.getSecondLastMonthPeriod(),
    CURRENT: UtilDate.getCurrentPeriod(),
};

export const CREDIT_CARD_ACT = "ACT";
export const CREDIT_CARD_INA = "INA";
export const CHANGE_STATUS_ORDER_ACT = "ACTIVAR";
export const CHANGE_STATUS_ORDER_INA = "DESACTIVAR";

export function Component(props) {
    const prevId = React.useRef();
    const {
        activeEnvironment,
        creditCard,
        creditCardList,
        dispatch,
        id,
        i18n,
        isChangingEnvironment,
        isChangingStatusCreditCard,
        isFetching,
    } = props;

    const [showModal, setShowModal] = React.useState(false);
    const [modalLabel, setModalLabel] = React.useState(EMPTY_STR);
    const [creditCardItem, setCreditCardItem] = React.useState({});
    const [showMovements, setShowMovements] = React.useState(false);
    const [creditCardSelected, setCreditCardSelected] = React.useState({});

    // check environment change
    const isSameEnv = creditCard ? creditCard.idEnvironment === activeEnvironment.id : true;
    const wait = isFetching || !creditCard || id !== prevId.current;

    React.useEffect(() => {
        const [dateFrom, dateTo] = DATE_RANGE.CURRENT;

        if (!isChangingEnvironment && (!creditCard || id !== prevId.current) && isSameEnv) {
            dispatch(
                ActionCC.detailRequest({
                    idCreditCard: id,
                    currency: CDP_CURRENCY.UYU,
                    dateFrom: (dateFrom && UtilDate.formatDate(dateFrom)) || null,
                    dateTo: (dateTo && UtilDate.formatDate(dateTo)) || null,
                }),
            );

            prevId.current = id;
        }
    }, [creditCard, dispatch, isChangingEnvironment, id, isSameEnv]);

    const info = {};

    const handleModal = React.useCallback((item, index) => {
        if (item) {
            setCreditCardItem({ item, index });

            if (item.status === CREDIT_CARD_ACT) {
                setModalLabel("client.creditCard.changeStatus.confirmation.D");
            } else {
                setModalLabel("client.creditCard.changeStatus.confirmation.A");
            }
        }

        setShowModal(true);
    }, []);

    const handleShowMovements = (creditCardSelectedParam) => {
        setShowMovements(!showMovements);
        setCreditCardSelected(creditCardSelectedParam);

        const ccFound = creditCardList.find((item) => item.creditCardNumber === creditCardSelectedParam.number);

        dispatch(
            ActionCC.creditCardMovementsRequest({
                creditCard: creditCardSelectedParam,
                idCreditCard: id,
                pageNumber: ccFound ? ccFound.pageNumber : 1,
                pageNumberAux: ccFound ? ccFound.pageNumberAux + 1 : 1,
            }),
        );
    };

    function handleHeadBack() {
        if (showMovements) {
            handleShowMovements(creditCardSelected);
        } else {
            dispatch(GoBack());
        }
    }

    if (!wait) {
        const { brand, number, productAlias } = creditCard;

        info.brand = `cc-types/${CC_TYPES[brand]}.svg`;
        info.number = number ? number.slice(-9) : EMPTY_STR;
        info.productAlias = productAlias || EMPTY_STR;
    }

    const handleChangeSlide = (pos) => {
        setCreditCardSelected(creditCard.creditCardList.length > 0 ? creditCard.creditCardList[pos] : {});
    };

    return (
        <React.Fragment>
            {!wait && (
                <ConfirmationModal
                    show={showModal}
                    handleAcept={() => handleSubmit()}
                    handleCancel={() => closeModal()}
                    idLabel={modalLabel}
                />
            )}
            <Container
                name={Style.NAME_MOVS}
                className={CLASS}
                wait={wait || isChangingStatusCreditCard}
                head-onBack={handleHeadBack}
                head-onBackWeb={handleHeadBack}
                scopeToShowNotification={SCOPE.CREDIT_CARD_DETAILS}>
                {!wait && (
                    <React.Fragment>
                        <CreditCardHeader
                            handleModalSwitchStatus={handleModal}
                            creditCard={creditCard}
                            id={id}
                            handleShowMovements={handleShowMovements}
                            handleChangeSlide={handleChangeSlide}
                            showMovements={showMovements}
                            setShowMovements={setShowMovements}
                            creditCardSelected={creditCardSelected}
                        />
                        {!showMovements && <OperationsButton creditCard={creditCardSelected} />}
                        {showMovements && (
                            <Movements
                                container-title={i18n.labelMovs}
                                container-role="list"
                                creditCard={creditCardSelected}
                                id={id}
                            />
                        )}
                    </React.Fragment>
                )}
            </Container>
        </React.Fragment>
    );

    function closeModal() {
        setShowModal(false);
    }

    function handleSubmit() {
        let order;
        const { item, index } = creditCardItem;

        if (item.status === CREDIT_CARD_ACT) {
            order = CHANGE_STATUS_ORDER_INA;
        } else {
            order = CHANGE_STATUS_ORDER_ACT;
        }

        const params = { idCreditCard: id, order, creditCardPosition: index };

        dispatch(ActionCC.changeStatusRequest(params));

        closeModal();
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store, props) => {
    const { match } = props;
    const i18n = {
        creditCardsTitle: StoreI18n.getMessage(store, "creditCard.list.title"),
        infoButton: StoreI18n.getMessage(store, "menu.operations"),
        infoTitle: StoreI18n.getMessage(store, "creditCard.label"),
        labelAccount: StoreI18n.getMessage(store, "creditCard.account"),
        labelDateDue: StoreI18n.getMessage(store, "creditCard.dueDate.next"),
        labelDateNext: StoreI18n.getMessage(store, "creditCard.closingDate.next"),
        labelInfo: StoreI18n.getMessage(store, "creditCard.info"),
        labelLimit: StoreI18n.getMessage(store, "creditCard.creditLimit"),
        labelMovs: StoreI18n.getMessage(store, "creditCard.statements"),
        loading: StoreI18n.getMessage(store, "global.loading"),
        notAvailable: StoreI18n.getMessage(store, "data.notAvailable.short"),
    };

    return {
        activeEnvironment: SelectorsStoreSession.getActiveEnvironment(store),
        creditCard: StoreCC.getDetail(store),
        creditCardList: StoreCC.getCreditCardList(store) || [],
        i18n: {
            ...i18n,
            getCurrency: (id) =>
                DATA_NOT_AVAILABLE
                    ? i18n.notAvailable
                    : StoreI18n.getMessage(store, `currency.label.${id}`) || i18n.notAvailable,
        },
        id: match.params.id,
        isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
        isChangingStatusCreditCard: StoreCC.isChangingStatusCreditCard(store),
        isFetching: StoreCC.getFetching(store) /* || StoreCC.getDownloading(store) */,
    };
};

export default Connect(mapStateToProps)(WithRouter(Component));
