import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { EMPTY_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreWMBonds, SelectorsAction as SelectorsActionWMBonds } from "~/store/wm/bonds";
import * as UtilsI18n from "~/util/i18n";

import DataDate from "~/components/DataDate";
import DataNumber from "~/components/DataNumber";
import I18n from "~/components/I18n";
import Ticket from "~/components/Ticket/Ticket";

import Style from "./Step3View.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ticketConfirmation: PropTypes.bool,
    },
    defaults: {
        ticketConfirmation: false,
    },
};

const FORM_ID = "wm.bonds.sell.send";

export function Component(props) {
    const { ticketConfirmation, transaction } = props;
    const { data } = transaction;

    const { creditAccountAlias, currency, limitDate, nominalValue, orderCompleted, paperName, price, sellingType } =
        data;

    const orderValid = orderCompleted ? (
        UtilsI18n.get(`${FORM_ID}.orderCompleted.label`)
    ) : (
        <DataDate value={limitDate} />
    );

    return (
        <section id={Style.ID}>
            {data && (
                <Ticket
                    hasMobileComponent
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    handleReject={handleReject}
                    handleSign={handleSign}>
                    <div className="data-section no-mt">
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="client.wm.bonds.sell.step3.bondToSell" />
                            </span>
                            <span className="data-text">{paperName}</span>
                        </div>
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="client.wm.bonds.sell.step3.sellingType" />
                            </span>
                            <span className="data-text">{sellingType}</span>
                        </div>
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="client.wm.bonds.sell.step3.price" />
                            </span>
                            <span className="data-text">{UtilsI18n.get(`client.wm.bonds.sell.price.${price}`)}</span>
                        </div>
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="client.wm.bonds.sell.step3.nominalValue" />
                            </span>
                            <span className="data-name amount-number">
                                <DataNumber value={nominalValue} prefix={currency} className="format-currency" />
                            </span>
                        </div>
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="client.wm.bonds.sell.step3.account" />
                            </span>
                            <span className="data-text">{creditAccountAlias}</span>
                        </div>
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="wm.allInvestments.orderValidUntil.label" />
                            </span>
                            <span className="data-text">{orderValid}</span>
                        </div>
                    </div>
                </Ticket>
            )}
        </section>
    );

    function handleReject(formikBag) {
        const { dispatch } = props;
        const { idTransaction } = transaction;

        dispatch(
            SelectorsActionWMBonds.cancelTransaction({
                credentials: { otp: EMPTY_STR },
                idTransaction,
                formikBag,
            }),
        );
    }

    function handleSign() {
        const { dispatch } = props;

        dispatch(SelectorsActionWMBonds.signTransactionPreview({ ...data }));
    }
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        transaction: SelectorsStoreWMBonds.getTransaction(store),
    };
};

export default Connect(mapStateToProps)(Component);
