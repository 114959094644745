import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { ENVIRONMENT_TYPE } from "~/constants";
import { SelectorsAction as SelectorsActionSession, SelectorsStore as StoreSession } from "~/store/session";

import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";

import Namespace from "./_Logo.scss";

export const { NAME, TYPE } = Namespace;

export const PROP = {
    types: {
        hideHeaderAndFooter: PropTypes.bool,
        isLoggedIn: PropTypes.bool,
        isPremier: PropTypes.bool,
    },
    defaults: {
        hideHeaderAndFooter: false,
        isLoggedIn: false,
        isPremier: false,
    },
};

export function Component(props) {
    const { dispatch, isLoggedIn, isPremier, hideHeaderAndFooter, ...rest } = props;

    const src = isPremier ? "logo-hsbc-premier.svg" : "logo-hsbc.svg";

    const shouldLogout = () => {
        if (hideHeaderAndFooter) {
            dispatch(SelectorsActionSession.logout());
        }
    };

    return (
        <div {...rest} data-name={NAME} data-type={TYPE}>
            <Link to={isLoggedIn ? "/desktop" : "/"} onClick={shouldLogout}>
                <Image src={src} />
                <I18n id="global.bank.name" defaultValue="HSBC" />
            </Link>
        </div>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

function mapStateToProps(store) {
    const activeEnvironment = StoreSession.getActiveEnvironment(store) || {};

    return {
        isLoggedIn: StoreSession.isLoggedIn(store),
        isPremier: activeEnvironment.type === ENVIRONMENT_TYPE.PREMIER,
    };
}

export default Connect(mapStateToProps)(Component);
