import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { FORMAT_SHORT } from "~/constants";
import { SelectorsStore as SelectorsStoreForm } from "~/store/form";
import * as ConfigUtils from "~/util/config";
import { specificDate } from "~/util/date";
import * as I18nUtils from "~/util/i18n";

import I18n from "~/components/I18n";

export const NAME = "TripNotice";

export const PROP = {
    types: {
        previewData: PropTypes.shape({
            beginDate: PropTypes.string,
            endDate: PropTypes.string,
        }).isRequired,
        dateFormat: PropTypes.string,
    },
    defaults: {
        previewData: {
            beginDate: null,
            endDate: null,
        },
        dateFormat: FORMAT_SHORT,
    },
};

export function Component(props) {
    const {
        previewData: { beginDate, endDate },
        dateFormat,
    } = props;

    return (
        <div className="data-wrapper">
            <span className="data-label">
                <I18n
                    id="creditcards.tripNotice.confirmation"
                    START_DATE={specificDate(beginDate, dateFormat)}
                    END_DATE={specificDate(endDate, dateFormat)}
                />
            </span>
        </div>
    );
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (store) => ({
    name: SelectorsStoreForm.getName(store),
    previewData: SelectorsStoreForm.getPreviewData(store),
    dateFormat: ConfigUtils.get(`frontend.shortDateFormat.${I18nUtils.getLang()}`),
});

export default Connect(mapStateToProps)(Component);
