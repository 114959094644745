// TODO: this must be deleted
export const EXCLUDE_PHASE1 = true;

export const EXCLUDE_FOR_LOANS = true;

export const TRIMMED = "trimmed";
export const RELEASE_MOD = TRIMMED;

export const TRIMMED_MENU_ITEMS = [
    { key: "desktop", value: false },
    { key: "transactions", value: false },
    { key: "accounts", value: false },
    { key: "transfers", value: false },
    { key: "preferentialTradingPrice", value: false },
    { key: "checkbooks", value: false },
    { key: "loans", value: false },
    { key: "creditcards", value: false },
    { key: "position", value: false }, // ??
    { key: "admin", value: false },
    { key: "settings", value: false },
    { key: "atms", value: false },
];

export const TRIMMED_MENU_SETTINGS = {
    information: [
        { key: "changePersonalInformation", value: false },
        { key: "changePersonalAccount", value: false },
    ],
    security: [
        { key: "changeEmail", value: true },
        { key: "changePhone", value: true },
        { key: "changePassword", value: false },
        { key: "changeSecuritySeal", value: true },
    ],
    configuration: [
        { key: "biometricsConfiguration", value: false },
        { key: "notificationsConfiguration", value: false },
        { key: "changeEnvironment", value: false },
        { key: "changeLanguage", value: true },
    ],
    other: [
        { key: "privacy", value: true },
        { key: "termAndConditions", value: true },
        { key: "support", value: true },
    ],
};
