import * as API from "~/store/api";
import { FactoryAction } from "~/util/redux";

import { NAME, PATH, TYPE } from "./_consts";

export const SelectorsMiddleware = {
    deleteFile: (props) => API.executeWithAccessToken(PATH.DELETE_FILE, props),
    downloadFileLink: (props) => API.executeWithAccessToken(PATH.DOWNLOAD_FILE_LINK, props),
    download: (props) => API.executeWithAccessToken(PATH.DOWNLOAD_FILE, props),
    downloadStream: (props) => API.executeWithAccessToken(PATH.DOWNLOAD_FILE_STREAM, props),
    getTransactionLines: (props) => API.executeWithAccessToken(PATH.GET_FILE_TRANSACTION_LINES, props),
};

export const SelectorsAction = {
    downloadFileRequest: (props) => FactoryAction(TYPE.DOWNLOAD_FILE_REQUEST, props),
    downloadFileFailure: (props) => FactoryAction(TYPE.DOWNLOAD_FILE_FAILURE, props),
    downloadFileSuccess: (props) => FactoryAction(TYPE.DOWNLOAD_FILE_SUCCESS, props),
    downloadFileLinkRequest: (props) => FactoryAction(TYPE.DOWNLOAD_FILE_LINK_REQUEST, props),
    downloadFileLinkFailure: (props) => FactoryAction(TYPE.DOWNLOAD_FILE_LINK_FAILURE, props),
    downloadFileLinkSuccess: (props) => FactoryAction(TYPE.DOWNLOAD_FILE_LINK_SUCCESS, props),
    getFileContentsRequest: (props) => FactoryAction(TYPE.GET_FILE_CONTENTS_REQUEST, props),
    getFileContentsFailure: (props) => FactoryAction(TYPE.GET_FILE_CONTENTS_FAILURE, props),

    getFileTransactionLinesRequest: (props) => FactoryAction(TYPE.GET_FILE_TRANSACTION_LINES_REQUEST, props),
    getFileTransactionLinesFailure: (props) => FactoryAction(TYPE.GET_FILE_TRANSACTION_LINES_FAILURE, props),
};

export const SelectorsStore = {
    getFetchingDownload: (store) => store[NAME].fetchingDownload,
};
