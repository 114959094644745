import { call, put, takeLatest, select } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE, TRANSACTION_DATA, GLOBAL_UNEXPECTED_ERROR_KEY } from "~/constants";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsStore as SelectorsStoreTemplate } from "~/store/template";
import { isDateLessThanToday } from "~/util/date";
import * as i18n from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsMiddleware } from "./_selectors";

const sagas = [
    takeLatest(TYPE.LOAD_TEMPLATE_LIST, loadTemplateList),
    takeLatest(TYPE.DELETE_TEMPLATE, deleteTemplate),
    takeLatest(TYPE.SAVE_TEMPLATE_REQUEST, saveTemplateTransaction),
];

export default sagas;

function* saveTemplateTransaction(props) {
    const myProps = { ...props };
    delete myProps.type;
    const { idForm, formikBag, ...rest } = myProps;

    const list = yield select(SelectorsStoreTemplate.getTemplateList);
    const filtered = list.filter((temp) => temp.name === props.templateName && temp.idForm === idForm);
    const showConfirmationModal = yield select(SelectorsStoreTemplate.getShowConfirmationModal);
    if (filtered.length > 0 && !showConfirmationModal) {
        yield put(SelectorsAction.toggleShowConfirmationModal());
        formikBag.setSubmitting(false);
        return;
    }

    // Como es un middleware de form esto no funciona
    const {
        type,
        data: { data },
    } = yield call(SelectorsMiddlewareForm.saveTemplate, { idForm, ...rest });
    if (type === RESPONSE_TYPE.WARNING) {
        formikBag.setErrors(data);
        yield put(SelectorsAction.saveTemplateFailure());
    } else {
        formikBag.resetForm();
        const confirmationMessage = i18n.get("forms.saveTemplate.confirmationMessage");
        yield put(SelectorsAction.savetemplatesuccess({ idForm, data }));
        yield put(
            SelectorsActionNotification.showNotification({
                message: confirmationMessage,
                level: LEVEL.SUCCESS,
                scopes: [
                    SCOPE.FORM,
                    SCOPE.COMEX_IMPORT_REQUEST_ENDORSEMENT,
                    SCOPE.COMEX_IMPORT_CREDIT_LETTER_OPEN,
                    SCOPE.COMEX_IMPORT_CREDIT_LETTER_MODIFY,
                    SCOPE.COMEX_EXPORT_PRESENT_BILLING_DOCUMENTS,
                    SCOPE.COMEX_EXPORT_CREDIT_LETTER_TRANSFER,
                    SCOPE.COMEX_BONDS_AND_GUARANTEES_MODIFY,
                    SCOPE.COMEX_BONDS_AND_GUARANTEES,
                    SCOPE.COMEX_IMPORT_LIFT_CREDIT_LETTER_DISCREPANCIES,
                    SCOPE.COMEX_OTHERS,
                ],
            }),
        );
    }
    formikBag.setSubmitting(false);
}

function* loadTemplateList(props) {
    const {
        type,
        data: {
            data: { templates },
        },
    } = yield call(SelectorsMiddleware.loadList, props);
    if (type === RESPONSE_TYPE.WARNING) {
        yield put({ type: TYPE.LOAD_LIST_FAILURE });
        yield put(SelectorsAction.toggleTemplateList());
        yield put(
            SelectorsActionNotification.showNotification({
                message: i18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                level: LEVEL.ERROR,
                scopes: [SCOPE.FORM],
            }),
        );
    } else {
        const valueIsPast = templates.map((template) => {
            if (template.transactionData.scheduler) {
                const {
                    transactionData: {
                        scheduler: { valueDate, editing, selectedOption },
                    },
                } = template;
                const date = isDateLessThanToday(valueDate) ? new Date() : valueDate;

                return {
                    ...template,
                    transactionData: {
                        ...template[TRANSACTION_DATA],
                        scheduler: { valueDate: date, editing, selectedOption },
                    },
                };
            }
            return {
                ...template,
                transactionData: {
                    ...template[TRANSACTION_DATA],
                    scheduler: { valueDate: null },
                },
            };
        });

        yield put(SelectorsAction.loadTemplateListSuccess({ templates: valueIsPast }));
    }
}

function* deleteTemplate(props) {
    const { idTransactionTemplate } = props;
    const response = yield call(SelectorsMiddleware.deleteItem, props);
    if (response.type === RESPONSE_TYPE.WARNING) {
        yield put({ type: TYPE.DELETE_TEMPLATE_FAILURE });
        yield put(
            SelectorsActionNotification.showNotification({
                message: i18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                level: LEVEL.ERROR,
                scopes: [SCOPE.FORM],
            }),
        );
    } else {
        yield put(
            SelectorsAction.deleteTemplateSuccess({
                idTransactionTemplate,
            }),
        );
    }
}
