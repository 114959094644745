import React from "react";

import { connect as Connect } from "react-redux";

import {
    SelectorsAction as SelectorsActionsWMShares,
    SelectorsStore as SelectorsStoreWMShares,
} from "~/store/wm/shares";
import { generateId as GenID } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import GridLayout from "~/components/GridLayout";
import HighOrder from "~/components/HighOrder";
import Note from "~/components/Note";

import Style from "./_SearchItemsList.rules.scss";

export const { NAME, CLASS } = Style;

export const PROP = {
    types: {},
    defaults: {},
};
export function Component(props) {
    const { isFetching, moreItems, papersList } = props;

    const headerLabels = [
        UtilsI18n.get("wm.shares.buy.search.type.label"),
        UtilsI18n.get("wm.shares.buy.search.ticker.label"),
        UtilsI18n.get("wm.shares.buy.search.isin.label"),
        UtilsI18n.get("wm.shares.buy.search.issuer.label"),
    ];

    const NoteMessage = () => {
        return (
            <Box flex justify="center">
                {papersList && (
                    <React.Fragment>
                        {isFetching && <Note>{UtilsI18n.get("global.loading")}</Note>}
                        {papersList.length === 0 && (
                            <Box className="my-4">{UtilsI18n.get("wm.shares.buy.search.nomore")}</Box>
                        )}
                        {!isFetching && moreItems && (
                            <Note onClick={() => {}}>{UtilsI18n.get("wm.shares.buy.search.more")}</Note>
                        )}
                        {papersList.length > 0 && !isFetching && !moreItems && (
                            <Note className="no-more">{UtilsI18n.get("wm.shares.buy.search.list.end")}</Note>
                        )}
                    </React.Fragment>
                )}
            </Box>
        );
    };

    const handleClick = (idPaper) => {
        const { dispatch } = props;
        dispatch(SelectorsActionsWMShares.buySharesPre({ idPaper }));
        dispatch(SelectorsActionsWMShares.goStepBack());
    };

    function renderItem(paper) {
        const { productTypeOrigin, idPaper, ticker, isin, description } = paper;
        return (
            <GridLayout className="search-item" key={`searchItem-${GenID()}`} onClick={() => handleClick(idPaper)}>
                <Box>
                    <Box>
                        {productTypeOrigin === "24"
                            ? UtilsI18n.get("wm.shares.buy.searchOrdinarias")
                            : UtilsI18n.get("wm.shares.buy.searchPreferidas")}
                    </Box>
                </Box>

                <Box>
                    <Box>{ticker}</Box>
                </Box>
                <Box>
                    <Box>{isin}</Box>
                </Box>
                <Box>
                    <Box>{description}</Box>
                </Box>
            </GridLayout>
        );
    }

    return (
        <Box className={CLASS}>
            <GridLayout>
                {headerLabels.map((heading) => (
                    <Box key={`heading-${GenID()}`}>{heading}</Box>
                ))}
            </GridLayout>
            {papersList && papersList.map((paper) => renderItem(paper))}
            <NoteMessage />
        </Box>
    );
}

Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

const mapStateToProps = (store) => ({
    isFetching: SelectorsStoreWMShares.getIsFetching(store),
    papersList: SelectorsStoreWMShares.getPapersList(store),
    moreItems: SelectorsStoreWMShares.getMoreLines(store),
});

export default HighOrder(Connect(mapStateToProps))(Component);
