import React from "react";

import ClassNames from "classnames";
import PropTypes from "prop-types";

import Element from "~/components/Component";
import Image from "~/components/Image";

import Namespace from "./_Button.scss";

import useScrollPosition from "~/hooks/useScrollPosition";

export const { NAME, TYPE, TOP } = Namespace;
export const PROP = {
    types: {
        onClick: PropTypes.func,
        image: PropTypes.string.isRequired,
        tabIndex: PropTypes.string,
        role: PropTypes.string,
    },
    defaults: {
        onClick: null,
        image: undefined,
        tabIndex: "0",
        role: "button",
    },
};

export function Component(props) {
    const { className, onClick, image, ...rest } = props;
    const [stick, setSticked] = React.useState(false);

    useScrollPosition(
        ({ prevPos, currPos }) => {
            const isScrollingUp = currPos.y > prevPos.y;
            let shouldStick = currPos.y < -Math.abs(parseInt(TOP, 10) + 2);

            if (isScrollingUp) {
                shouldStick = currPos.y < -Math.abs(parseInt(TOP, 10) + 6);
            }

            if (shouldStick !== stick) {
                setSticked(shouldStick);
            }
        },
        [stick],
    );

    return (
        <Element
            role="button"
            {...rest}
            tag="button"
            className={ClassNames(className, { stick })}
            name={NAME}
            type={TYPE}
            onClick={onClick}
            onKeyPress={onClick}>
            <Image src={image} />
        </Element>
    );
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
