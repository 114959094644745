import { takeLatest as TakeLatest, call as Call, put as Put, select as Select } from "redux-saga/effects";

import {
    CDP_CURRENCY,
    EMPTY_STR,
    GLOBAL_UNEXPECTED_ERROR_KEY,
    LEVEL,
    RESPONSE_TYPE,
    SCOPE,
    SIGN_WITH_BIOMETRIC,
    VALIDATION_ERROR,
} from "~/constants";
import Store from "~/store";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import IsTrustedBiometric from "~/store/form/_sagas/_isTrustedBiometric";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import {
    credentialsWithUnderscore as CredentialsWithUnderscore,
    hasIncorrectCredentials as HasIncorrectCredentials,
    adjustIdFieldErrors as AdjustIdFieldErrors,
} from "~/util/form";
import { generateId as GenID } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";
import UtilLodash from "~/util/lodash";

import { TYPE } from "./_consts";
import { SelectorsAction, SelectorsStore, SelectorsMiddleware } from "./_selectors";

const formatDate = (date) => date.toISOString().split("T")[0];

export default [
    TakeLatest(TYPE.ADVANCE_PAYMENTS_TRANSACTION_PREVIEW_REQUEST, advancePaymentsPreview),
    TakeLatest(TYPE.ADVANCE_PAYMENTS_TRANSACTION_SEND_REQUEST, advancePaymentsSend),
    TakeLatest(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, cancelTransactionCustom),
    TakeLatest(TYPE.LIST_ADVANCE_PAYMENTS_REQUEST, listAdvancePaymentsOrders),
    TakeLatest(TYPE.READ_TRANSACTION_REQUEST, requestAdvancePaymentTransaction),
    TakeLatest(TYPE.SIGN_TRANSACTION_REQUEST, signTransaction),
];

function* advancePaymentsPreview({ ordersToDiscountList }) {
    const paymentList = ordersToDiscountList.map((payment) => {
        return {
            instructionCode: payment.instructionCode,
            rowId: payment.rowId,
            discountableAmount: payment.discountableAmount,
        };
    });

    const resp = yield Call(SelectorsMiddleware.advancePaymentsPreview, { paymentList });
    const { type, data } = resp;

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.advancePaymentsTransactionPreviewFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADVANCE_PAYMENT_ORDERS],
            }),
        );
    } else {
        yield Put(SelectorsAction.advancePaymentsTransactionPreviewSuccess(data));
    }
}

function* advancePaymentsSend(props) {
    const { otp, formikBag } = props;

    const paymentList = yield Select(SelectorsStore.getOrdersToDiscountList);
    const totalDiscountableUSD = yield Select(SelectorsStore.getCurrentDiscountableUSD);
    const totalDiscountableUYU = yield Select(SelectorsStore.getCurrentDiscountableUYU);

    const amountUSD = {
        currency: CDP_CURRENCY.USD,
        quantity: totalDiscountableUSD.amount,
    };

    const amountUYU = {
        currency: CDP_CURRENCY.UYU,
        quantity: totalDiscountableUYU.amount,
    };

    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
    }

    const { webToken } = Store.getState().session;

    const tokenToUse = otp || webToken;

    let response;

    if (UtilLodash.isEmpty(tokenToUse)) {
        response = {
            data: {
                code: VALIDATION_ERROR,
                message: UtilsI18n.get(`returnCode.${VALIDATION_ERROR}`),
                data: {
                    otp: UtilsI18n.get("returnCode.COR027W"),
                },
            },
            status: 200,
            type: RESPONSE_TYPE.WARNING,
        };
    } else {
        response = yield Call(SelectorsMiddleware.advancePaymentsSend, {
            amountUSD,
            amountUYU,
            paymentList,
            _otp: tokenToUse,
        });
    }

    if (response.type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = Object.keys(response.data.data).some((key) => key === "otp");

        if (hasIncorrectCredentials) {
            formikBag.setErrors(response.data.data);
        }

        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADVANCE_PAYMENT_ORDERS],
            }),
        );

        formikBag.setSubmitting(false);

        yield Put(SelectorsAction.advancePaymentsTransactionSendFailure());
    } else {
        const { idTransaction } = response.data;
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });

        yield Put(SelectorsAction.advancePaymentsTransactionSendSuccess({ transaction }));
    }
}

function* cancelTransactionCustom(props) {
    const { credentials, idTransaction, formikBag } = props;
    yield* handleCancelTransaction(credentials, idTransaction, formikBag);

    formikBag.setSubmitting(false);
}

function* handleCancelTransaction(credentials, idTransaction, formikBag) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);
    const {
        data: { data },
        type,
    } = yield Call(SelectorsMiddlewareForm.cancelTransaction, {
        idTransactionToCancel: idTransaction,
        ...credentialsWithUnderscore,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data);
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("forms.cancelTransaction.errorMessage"),
                    level: LEVEL.ERROR,
                    scopes: SCOPE.ADVANCE_PAYMENT_ORDERS,
                }),
            );
        }
    } else {
        yield* requestAdvancePaymentTransaction({ idTransaction });
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("forms.cancelTransaction.confirmationMessage"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.TRANSACTIONS],
            }),
        );
    }
}

function* listAdvancePaymentsOrders() {
    const filters = yield Select(SelectorsStore.getFiltersAdvancePaymentsList);
    const moreOrders = yield Select(SelectorsStore.getMoreOrders);
    const offset = moreOrders ? yield Select(SelectorsStore.getOffset) : 0;

    let { issueDateFrom, issueDateTo, dueDateFrom, dueDateTo } = filters;
    const { currency, status, paymentOrder, companyName } = filters;

    issueDateFrom = issueDateFrom ? formatDate(issueDateFrom) : null;
    issueDateTo = issueDateTo ? formatDate(issueDateTo) : null;
    dueDateFrom = dueDateFrom ? formatDate(dueDateFrom) : null;
    dueDateTo = dueDateTo ? formatDate(dueDateTo) : null;

    const myProps = {
        offset,
        paymentOrder,
        status,
        currency,
        issueDateFrom,
        issueDateTo,
        dueDateFrom,
        dueDateTo,
        companyName,
    };

    const { type, data } = yield Call(SelectorsMiddleware.listAdvancePaymentsOrdersRequest, myProps);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.listAdvancePaymentOrdersFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADVANCE_PAYMENT_ORDERS],
            }),
        );
    } else {
        yield Call(prepareAdvancePaymentsList, data.data);
    }
}

function* prepareAdvancePaymentsList(data) {
    const moreOrders = yield Select(SelectorsStore.getMoreOrders);

    let advancePaymentsList = moreOrders ? yield Select(SelectorsStore.getAdvancePaymentsList) : [];
    let totalAdvancePaymentBalanceAmountUSD = moreOrders
        ? yield Select(SelectorsStore.getTotalAdvancePaymentBalanceAmountUSD)
        : 0;
    let totalAdvancePaymentBalanceAmountUYU = moreOrders
        ? yield Select(SelectorsStore.getTotalAdvancePaymentBalanceAmountUYU)
        : 0;
    let totalDiscountableUYU = moreOrders
        ? yield Select(SelectorsStore.getTotalDiscountableUYU)
        : { amount: 0, total: 0 };
    let totalDiscountableUSD = moreOrders
        ? yield Select(SelectorsStore.getTotalDiscountableUSD)
        : { amount: 0, total: 0 };
    let totalAdvancePaymentAmountUSD = moreOrders ? yield Select(SelectorsStore.getTotalAdvancePaymentAmountUSD) : 0;
    let totalAdvancePaymentAmountUYU = moreOrders ? yield Select(SelectorsStore.getTotalAdvancePaymentAmountUYU) : 0;

    const { paymentList, moreLines } = data;
    let { nextOffset } = data;
    nextOffset = moreLines ? nextOffset : 0;
    const advancePaymentsByCompany = [];
    let key = EMPTY_STR;
    let index = -1;

    paymentList.forEach((paymentLine) => {
        const payment = {
            ...paymentLine,
            id: GenID(),
        };
        const itemKey = `${payment.companyName}+${payment.currency}`;

        if (itemKey === key) {
            advancePaymentsByCompany[index].totalAmount += payment.amount;
            advancePaymentsByCompany[index].paymentsList.push(payment);
        } else {
            key = itemKey;
            const totalAmount = payment.amount;
            const paymentsList = [];
            const name = payment.companyName;
            const { currency } = payment;
            paymentsList.push(payment);
            advancePaymentsByCompany.push({ totalAmount, paymentsList, name, currency, key });
            index = index === -1 ? 0 : index + 1;
        }

        totalAdvancePaymentAmountUYU += `${payment.currency}` === CDP_CURRENCY.UYU ? payment.amount : 0;
        totalAdvancePaymentAmountUSD += `${payment.currency}` === CDP_CURRENCY.USD ? payment.amount : 0;
        totalAdvancePaymentBalanceAmountUYU += `${payment.currency}` === CDP_CURRENCY.UYU ? payment.balanceAmount : 0;
        totalAdvancePaymentBalanceAmountUSD += `${payment.currency}` === CDP_CURRENCY.USD ? payment.balanceAmount : 0;

        if (payment.discountable) {
            if (`${payment.currency}` === CDP_CURRENCY.UYU) {
                totalDiscountableUYU = {
                    amount: totalDiscountableUYU.amount + payment.netAmount,
                    total: totalDiscountableUYU.total + 1,
                };
            } else {
                totalDiscountableUSD = {
                    amount: totalDiscountableUSD.amount + payment.netAmount,
                    total: totalDiscountableUSD.total + 1,
                };
            }
        }
    });

    //  Concat new list with actual ONLY if requesting more orders
    if (moreOrders) {
        const lastIndex = advancePaymentsList.length;

        if (advancePaymentsList[lastIndex - 1].key === advancePaymentsByCompany[0].key) {
            let { paymentsList, totalAmount } = advancePaymentsList[lastIndex - 1];
            paymentsList = paymentsList.concat(advancePaymentsByCompany[0].paymentsList);
            totalAmount += advancePaymentsByCompany[0].totalAmount;
            advancePaymentsList[lastIndex - 1].paymentsList = paymentsList;
            advancePaymentsList[lastIndex - 1].totalAmount = totalAmount;
        }

        advancePaymentsList = advancePaymentsList.concat(advancePaymentsByCompany.slice(1));
    } else {
        advancePaymentsList = advancePaymentsByCompany;
    }

    yield Put(
        SelectorsAction.listAdvancePaymentOrdersSuccess({
            data: {
                list: advancePaymentsList,
                moreLines,
                nextOffset,
                totalAdvancePaymentBalanceAmountUSD,
                totalAdvancePaymentBalanceAmountUYU,
                totalDiscountableUYU,
                totalDiscountableUSD,
                totalAdvancePaymentAmountUSD,
                totalAdvancePaymentAmountUYU,
            },
        }),
    );
}

function* requestAdvancePaymentTransaction({ idTransaction }) {
    const { type, data } = yield Call(SelectorsMiddlewareForm.readTransaction, {
        idTransactionToRead: idTransaction,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.readAdvancePaymentTransactionFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADVANCE_PAYMENTS],
            }),
        );
    } else {
        yield Put(SelectorsAction.readAdvancePaymentTransactionSuccess({ transaction: data.data.transaction }));
    }
}

function* signTransaction(props) {
    const { idForm, idActivity, idTransaction, credentials, formikBag } = props;
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
    }

    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { data, type } = yield Call(
        SelectorsMiddlewareForm.sign,
        { idForm, idTransaction, ...credentialsWithUnderscore },
        idActivity,
    );

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(AdjustIdFieldErrors(data.data));
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.ADVANCE_PAYMENTS],
                }),
            );
        }

        formikBag.setSubmitting(false);
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });

        yield Put(SelectorsAction.advancePaymentsTransactionSendSuccess({ transaction }));

        formikBag.setSubmitting(false);
    }
}
