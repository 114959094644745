import React from "react";

import PropTypes from "prop-types";

import Checkbox from "~/pages/_components/fields/Checkbox";

export const NAME = "FormikCheckbox";

export const PROP = {
    types: {
        field: PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.bool.isRequired,
        }).isRequired,
        form: PropTypes.shape({
            isSubmitting: PropTypes.bool.isRequired,
            setFieldValue: PropTypes.func.isRequired,
        }).isRequired,
        type: PropTypes.string,
        controlStyle: PropTypes.string,
    },
    defaults: { type: "checkbox", controlStyle: "checkbox" },
};

export function Component(props) {
    const { field, form, ...rest } = props;
    return <Checkbox {...field} {...rest} error={form.touched[field.name] && form.errors[field.name]} />;
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
