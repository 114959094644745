import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.LOAD_LIST_SWIFT_MESSAGES_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
                moreOrders: action.moreOrders,
                offset: action.offset ? action.offset : storeProp.offset,
                filters: action.filters || storeProp.filters,
            };
        case TYPE.SWIFT_MESSAGES_DETAIL_SEND_MAIL_SUCCESS:
            return {
                ...storeProp,
                isFetchingSendMail: false,
                showSendMailModal: false,
            };
        case TYPE.SWIFT_MESSAGES_DETAIL_SEND_MAIL_FAILURE:
            return {
                ...storeProp,
                isFetchingSendMail: false,
            };
        case TYPE.LOAD_LIST_SWIFT_MESSAGES_FAILURE:
        case TYPE.SWIFT_MESSAGES_DETAIL_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
            };
        case TYPE.LOAD_LIST_PRE_SWIFT_MESSAGES_REQUEST:
            return {
                ...PROP.defaults, // El pre se hace cuando se carga la pagina
            };
        case TYPE.LOAD_LIST_PRE_SWIFT_MESSAGES_SUCCESS:
            return {
                ...storeProp,
                types: action.types,
                subTypes: action.subTypes,
                validCurrencyTypes: action.validCurrencyTypes,
            };
        case TYPE.LOAD_LIST_SWIFT_MESSAGES_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                swiftMessages: storeProp.moreOrders
                    ? storeProp.swiftMessages.concat(action.swiftMessages)
                    : action.swiftMessages,
                offset: storeProp.moreOrders || action.moreLines ? action.nextOffset : 0,
                moreLines: action.moreLines,
            };
        case TYPE.SWIFT_MESSAGES_DETAIL_SEND_MAIL_REQUEST:
            return {
                ...storeProp,
                isFetchingSendMail: true,
            };
        case TYPE.SWIFT_MESSAGES_DETAIL_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.SWIFT_MESSAGES_DETAIL_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                messageDetail: action.swiftMessageDetail,
            };
        case TYPE.SET_SHOW_SEND_MAIL_MODAL:
            return { ...storeProp, showSendMailModal: action.showSendMailModal };
        case TYPE.RESET_FILTERS:
            return { ...storeProp, filters: PROP.defaults.filters };
        case TYPE.KEEP_FILTERS:
            return { ...storeProp, keepFilters: action.keepFilters, messageDetail: PROP.defaults.messageDetail };
        default:
            return {
                ...storeProp,
            };
    }
};
