import React from "react";

import ClassNames from "classnames";

import { Categorize, Prefix } from "~/util/prop";

import Page from "../_Page";
import Detail, { PROP as PROP_DETAIL } from "./_Detail";
import Filter, { PROP as PROP_FILTER } from "./_Filter";
import Title, { PROP as PROP_TITLE } from "./_Title";
import { NAME_TABS, TYPE_TABS } from "./_index.scss";
import Style from "./index.rules.scss";

import Internal, { PROP as PROP_INTERNAL } from "..";

export const { NAME, TYPE } = Style;
export const PROP = {
    types: {
        ...PROP_INTERNAL.types,
        ...Prefix("info", PROP_DETAIL.types),
        ...Prefix("title", PROP_TITLE.types),
        ...Prefix("filter", PROP_FILTER.types),
    },
    defaults: {
        ...PROP_INTERNAL.defaults,
        ...Prefix("info", PROP_DETAIL.defaults),
        ...Prefix("title", PROP_TITLE.defaults),
        ...Prefix("filter", PROP_FILTER.defaults),
    },
};

export function Component(props) {
    const {
        children: elements,
        className,
        filter,
        head,
        info,
        isCUI,
        name,
        scopeToShowNotification,
        titleLabel,
        wait,
        ...rest
    } = Categorize(props);

    const [current, setCurrent] = React.useState(null);
    const propsInternal = Prefix("head", head);
    const children = Array.isArray(elements)
        ? elements.reduce((acc, val) => acc.concat(val), []).filter(Boolean) // flatten
        : [elements];
    const hasTabs = children.length > 1;
    return (
        <Internal
            {...propsInternal}
            id={Style.ID}
            name={NAME}
            wait={wait}
            className={ClassNames(className, { hasTabs })}
            scopeToShowNotification={scopeToShowNotification}>
            {!wait && (
                <Detail
                    {...info}
                    alias={head.title}
                    isCUI={isCUI}
                    navigationText={head.navigationText}
                    onBack={head.onBack}
                />
            )}

            {titleLabel && <Title>{titleLabel}</Title>}

            {filter && <Filter> {filter} </Filter>}

            {!hasTabs && (
                <Page {...rest} name={name}>
                    {children}
                </Page>
            )}
            {hasTabs && <Tabs />}
        </Internal>
    );

    function Tabs() {
        // TODO: when using highorder components, their props are lost.
        // obtain tab title from props (throw error if not found.)
        const parts = children.filter(Boolean).map((child) => {
            const { container: propsContainer } = Categorize(child.props || {});
            if (!propsContainer || typeof propsContainer.title !== "string") {
                throw new Error("Expecting a 'container-title' prop in each tab");
            }
            // remove container props from the original element
            const propsElement = Object.entries(child.props).reduce(
                (acc, [key, val]) => ({
                    ...acc,
                    [key]: key.indexOf("container-") === 0 ? undefined : val,
                }),
                {},
            );
            const { title, ...props_ } = propsContainer;
            // serves as an internal identifier, obviously has to be unique,
            // so, duplicate tab names is obviously a bad idea. need them? generate an uid here.
            const key = title.replace(" ", "-").toLowerCase();
            return {
                key,
                title,
                element: React.cloneElement(child, propsElement),
                props: props_,
            };
        });
        return (
            <React.Fragment>
                <nav data-name={NAME_TABS} data-type={TYPE_TABS}>
                    {parts.map(({ key, title }, i) => {
                        // disable current tab.
                        const disabled = current !== null ? current === key : i === 0;
                        return (
                            <button
                                key={key}
                                type="button"
                                disabled={disabled}
                                onClick={handleTabClick.bind(null, key)}>
                                {title}
                            </button>
                        );
                    })}
                </nav>
                {parts
                    .filter(({ key }, i) => (current !== null ? current === key : i === 0))
                    .map((part) => {
                        return (
                            <Page key={part.key} hasTabs {...part.props}>
                                {part.element}
                            </Page>
                        );
                    })}
            </React.Fragment>
        );

        function handleTabClick(key) {
            setCurrent(key);
        }
    }
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
