import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { FactoryType } from "~/util/redux";

export const NAME = "administrationUsersInvite";

export const PATH = {
    ADMINISTRATION_USER_INVITE_PRE_REQUEST: "/administration.users.invite.pre",
    ADMINISTRATION_USER_INVITE_PREVIEW: "/administration.users.invite.preview",
    ADMINISTRATION_USER_INVITE_SEND: "/administration.users.invite.send",
    ADMINISTRATION_USER_INVITE_VERIFY: "/administration.users.invite.verify",
};

export const TYPE = FactoryType(NAME, [
    "ADMINISTRATION_USER_INVITE_FINISH",
    "ADMINISTRATION_USER_INVITE_PRE_FAILURE",
    "ADMINISTRATION_USER_INVITE_PRE_REQUEST",
    "ADMINISTRATION_USER_INVITE_PRE_REQUEST_SUCCESS",
    "ADMINISTRATION_USER_INVITE_PREVIEW",
    "ADMINISTRATION_USER_INVITE_PREVIEW_FAILURE",
    "ADMINISTRATION_USER_INVITE_PREVIEW_SUCCESS",
    "ADMINISTRATION_USER_INVITE_SEND",
    "ADMINISTRATION_USER_INVITE_SEND_FAILURE",
    "ADMINISTRATION_USER_INVITE_VERIFY",
    "ADMINISTRATION_USER_INVITE_VERIFY_FAILURE",
    "ADMINISTRATION_USER_INVITE_VERIFY_SUCCESS",
]);

export const PROP = {};

PROP.types = {
    confirmationParams: PropTypes.object,
    countryList: PropTypes.array,
    credentialGroups: PropTypes.array,
    defaultCountry: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    defaultDocumentType: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    documentTypeList: PropTypes.array,
    encryptedDocument: PropTypes.string,
    existentUserInfo: PropTypes.object,
    fetching: PropTypes.bool,
    groupList: PropTypes.array,
    idActivity: PropTypes.string,
    idTransaction: PropTypes.number,
    isInvitingNewUser: PropTypes.bool,
    roleList: PropTypes.array,
    selectedDocument: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
};

PROP.defaults = {
    confirmationParams: null,
    countryList: [],
    credentialGroups: [],
    defaultCountry: EMPTY_STR,
    defaultDocumentType: EMPTY_STR,
    documentTypeList: [],
    encryptedDocument: null,
    existentUserInfo: null,
    fetching: false,
    groupList: [],
    idActivity: null,
    idTransaction: null,
    isInvitingNewUser: false,
    roleList: [],
    selectedDocument: null,
};
