import { replace as Replace, goBack as GoBack } from "connected-react-router";
import { call as Call, takeLatest as TakeLatest, put as Put } from "redux-saga/effects";

import { RESPONSE_TYPE, LEVEL, SCOPE } from "~/constants";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as I18nUtil from "~/util/i18n";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction } from "./_selectors";

const sagas = [
    TakeLatest(TYPE.CONNECTEDPARTIES_FORCE_TO_COMPLETE_FORM, forceToCompleteForm),
    TakeLatest(TYPE.CONNECTEDPARTIES_LIST_CITIES_REQUEST, listCities),
    TakeLatest(TYPE.CONNECTEDPARTIES_LIST_DEPARTMENTS_OF_BIRTH_REQUEST, listDepartamentsOfBirth),
    TakeLatest(TYPE.CONNECTEDPARTIES_LIST_DEPARTMENTS_REQUEST, listDepartaments),
    TakeLatest(TYPE.CONNECTEDPARTIES_PRE_REQUEST, registerBantotalPre),
    TakeLatest(TYPE.CONNECTEDPARTIES_SUBMIT_FORM_REQUEST, submitForm),
    TakeLatest(TYPE.READ_TRANSACTION_REQUEST, readTransactionRequest),
];

export default sagas;

function* forceToCompleteForm() {
    yield Put(Replace("/connectedParties"));
    yield Put(
        SelectorsActionNotification.showNotification({
            message: I18nUtil.get("connectedParties.forceMessage"),
            level: LEVEL.WARNING,
            scopes: [SCOPE.PERSONAL_INFORMATION],
        }),
    );
}

function* listCities(props) {
    const {
        type,
        data: {
            data: { townList },
        },
    } = yield Call(SelectorsMiddleware.listCities, props);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.changeAddressesDepartamentFailure());
    } else {
        yield Put(
            SelectorsAction.changeAddressesCitySuccess({
                townList,
            }),
        );
    }
}

function* listDepartamentsOfBirth(props) {
    const {
        type,
        data: {
            data: { cityList },
        },
    } = yield Call(SelectorsMiddleware.listDepartments, props);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.listDepartmentsOfBirthFailure());
    } else {
        yield Put(
            SelectorsAction.listDepartmentsOfBirthSuccess({
                cityList,
            }),
        );
    }
}

function* listDepartaments(props) {
    const {
        type,
        data: {
            data: { cityList },
        },
    } = yield Call(SelectorsMiddleware.listDepartments, props);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.listDepartmentsFailure());
    } else {
        yield Put(
            SelectorsAction.listDepartmentsSuccess({
                cityList,
            }),
        );
    }
}

function* registerBantotalPre(props) {
    const {
        type,
        data: {
            data: {
                cityList,
                cmbOnly,
                correspondense,
                countryList,
                documents,
                infoUser,
                legal,
                loadData,
                maritalStatusList,
                productGroupIds,
                professions,
                townList,
                workActivities,
                workRoles,
                workSituations,
            },
        },
    } = yield Call(SelectorsMiddleware.registerBantotalPre, props);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.registerBantotalPreFailure());
        yield Put(GoBack());
    } else {
        yield Put(
            SelectorsAction.registerBantotalPreSuccess({
                cityList,
                cmbOnly,
                correspondense,
                countryList,
                documents,
                infoUser,
                legal,
                loadData,
                maritalStatusList,
                productGroupIds,
                professions,
                townList,
                workActivities,
                workRoles,
                workSituations,
            }),
        );
    }
}
function* submitForm(props) {
    const { formikBag, data } = props;

    const response = yield Call(SelectorsMiddleware.submitForm, data);

    if (response.type === RESPONSE_TYPE.WARNING) {
        formikBag.setErrors(response.data.data);

        yield Put(SelectorsAction.submitFormConnectedPartiesFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.PERSONAL_INFORMATION],
            }),
        );
    } else {
        yield Put(SelectorsAction.submitFormConnectedPartiesSuccess());
        yield Put(Replace("/desktop"));
        yield Put(
            SelectorsActionNotification.showNotification({
                message: I18nUtil.get("change.personalData.cmb.successful"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.DESKTOP],
            }),
        );
    }
}

function* readTransactionRequest(props) {
    const { idTransaction } = props;
    const { data, type } = yield Call(SelectorsMiddlewareForm.readTransaction, {
        idTransactionToRead: idTransaction,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.PERSONAL_INFORMATION],
            }),
        );
        yield Put(SelectorsAction.readTransactionFailure());
    } else {
        yield Put(SelectorsAction.readTransactionSuccess({ transaction: data.data.transaction }));
    }
}
