import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { FactoryType } from "~/util/redux";

export const NAME = "login";

export const TYPE = FactoryType(NAME, [
    "RESET",
    "INIT_LOGIN_FLOW",

    "FINALIZE_LOGIN",

    "LOGOUT_REQUEST",

    "LOGIN_STEP_1_REQUEST",
    "LOGIN_STEP_1_SUCCESS",
    "LOGIN_STEP_2_REQUEST",
    "LOGIN_STEP_3_REQUEST",
    "LOGIN_STEP_3_SUCCESS",
    "LOGIN_STEP_4_REQUEST",

    "LOGIN_FAILURE",
    "LOGIN_FAILURE_REQUIRE_CAPTCHA",
    "LOGIN_FINGERPRINT_FAILURE",
    "LOGIN_FINGERPRINT_DISCARD",
    "LOGIN_SUCCESS",

    "FINGERPRINT_LOGIN_SUBMIT",
    "FINGERPRINT_LOGIN_PRE",
    "FINGERPRINT_LOGIN_PRE_SUCCESS",

    "SET_REMEMBERED_USER",
    "REMOVE_REMEMBERED_USER",
    "LOGIN_FAILURE_LOADER",
    "CLEAN_UP",
]);

export const PROP = {};

PROP.types = {
    rememberedUser: PropTypes.string,
    exchangeToken: PropTypes.string,
    showCaptcha: PropTypes.bool,
    fetching: PropTypes.bool,
    fingerprintSubmiting: PropTypes.bool,
    lang: PropTypes.string,
    environments: PropTypes.object,
    fingerprintLoginFail: PropTypes.bool,
    disabledButton: PropTypes.bool,
    submit: PropTypes.bool,
};

PROP.defaults = {
    rememberedUser: null,
    exchangeToken: null,
    showCaptcha: false,
    fetching: false,
    fingerprintSubmiting: false,
    lang: EMPTY_STR,
    environments: {},
    fingerprintLoginFail: false,
    disabledButton: true,
    submit: false,
};
