import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { CDP_CURRENCY, PIPE_SEPARATOR } from "~/constants";
import {
    SelectorsStore as SelectorsStoreAdvancePayments,
    SelectorsAction as SelectorsActionAdvancePayments,
} from "~/store/factoring/advancePayments";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import UtilFlag from "~/util/flag";
import { generateId as GenID } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Element from "~/components/Component";
import DataDate from "~/components/DataDate";
import DataNumber from "~/components/DataNumber";
import ExpandElement from "~/components/ExpandElement";
import GridLayout from "~/components/GridLayout";
import Image from "~/components/Image";
import IntersectionObserver from "~/components/IntersectionObserver";

import { NAME_NOTE, TYPE_NOTE } from "../_index.scss";
import Style from "./_advancePayments.rules.scss";

export const { NAME, TYPE } = Style;

export const PROP = {
    types: {
        totalAdvancePaymentAmountUYU: PropTypes.number.isRequired,
        totalAdvancePaymentAmountUSD: PropTypes.number.isRequired,
        totalAdvancePaymentBalanceAmountUYU: PropTypes.number.isRequired,
        totalAdvancePaymentBalanceAmountUSD: PropTypes.number.isRequired,
        fetching: PropTypes.bool.isRequired,
        moreLines: PropTypes.bool.isRequired,
        advancePayments: PropTypes.shape({
            name: PropTypes.string,
            totalAmount: PropTypes.number,
            currency: PropTypes.string,
            paymentsList: PropTypes.shape({
                paymentOrder: PropTypes.string,
                dueDate: PropTypes.string,
                issueDate: PropTypes.string,
                currency: PropTypes.string,
                netAmount: PropTypes.number,
                balanceAmount: PropTypes.number,
                amount: PropTypes.number,
                rate: PropTypes.string,
                interests: PropTypes.string,
                discountableAmount: PropTypes.number,
                term: PropTypes.string,
            }).isRequired,
        }).isRequired,
        i18n: PropTypes.object,
    },
    defaults: {},
};

export function Component(props) {
    const {
        dispatch,
        advancePayments,
        moreLines,
        fetching,
        i18n,
        totalAdvancePaymentAmountUYU,
        totalAdvancePaymentAmountUSD,
        totalAdvancePaymentBalanceAmountUYU,
        totalAdvancePaymentBalanceAmountUSD,
    } = props;
    const { loading, labelMore, labelNoMore } = i18n;
    const COLUMN_HEADINGS = [
        i18n.headingPaymentOrder,
        i18n.headingDueDate,
        i18n.headingIssueDate,
        i18n.headingNetAmount,
        i18n.headingBalanceAmount,
        i18n.headingAmount,
        "",
    ];
    return (
        <IntersectionObserver onIntersect={handleMovementsFetch}>
            <GridLayout className="header">
                {COLUMN_HEADINGS.map((heading, index) => (
                    <Box justify={index > 2 && "end"}>{heading}</Box>
                ))}
            </GridLayout>
            {advancePayments.map(({ paymentsList, name, totalAmount, currency }) => {
                return (
                    <React.Fragment>
                        <div className="header-orders">
                            <div>
                                <span>{name}</span>
                                <span>{UtilsI18n.get(`currency.label.${currency}`) || currency}</span>
                                <Image className="currency-flag" src={`${UtilFlag.getFlag(`${currency}`)}.svg`} />
                            </div>
                            <Box justify="end">
                                <DataNumber value={totalAmount} prefix={currency} />
                            </Box>
                        </div>
                        {list(paymentsList)}
                    </React.Fragment>
                );
            })}
            <div className="totalizers">
                <div>
                    <label>{i18n.totalBalanceAmountLabel}</label>
                    {totalAdvancePaymentBalanceAmountUYU && totalAdvancePaymentBalanceAmountUSD ? (
                    <React.Fragment>
                        <DataNumber value={totalAdvancePaymentBalanceAmountUYU} prefix={CDP_CURRENCY.UYU} />
                            <span>{PIPE_SEPARATOR}</span>
                        <DataNumber value={totalAdvancePaymentBalanceAmountUSD} prefix={CDP_CURRENCY.USD} />
                    </React.Fragment>
                    ) : (
                    totalAdvancePaymentBalanceAmountUYU ?
                    <DataNumber value={totalAdvancePaymentBalanceAmountUYU} prefix={CDP_CURRENCY.UYU} />
                    :
                    <DataNumber value={totalAdvancePaymentBalanceAmountUSD} prefix={CDP_CURRENCY.USD} />
                    )}
                </div>
                <div>
                    <label>{i18n.totalAmountLabel}</label>
                    {totalAdvancePaymentAmountUYU && totalAdvancePaymentAmountUSD ? (
                    <React.Fragment>
                        <DataNumber value={totalAdvancePaymentAmountUYU} prefix={CDP_CURRENCY.UYU} />
                            <span>{PIPE_SEPARATOR}</span>
                        <DataNumber value={totalAdvancePaymentAmountUSD} prefix={CDP_CURRENCY.USD} />
                    </React.Fragment>
                    ) : (
                    totalAdvancePaymentBalanceAmountUYU ?
                    <DataNumber value={totalAdvancePaymentAmountUYU} prefix={CDP_CURRENCY.UYU} />
                    :
                    <DataNumber value={totalAdvancePaymentAmountUSD} prefix={CDP_CURRENCY.USD} />
                    )}
                </div>
            </div>
            {fetching && <Note value={loading} />}
            {!fetching && moreLines && (
                <Note
                    onClick={() => handleMovementsFetch({ isIntersecting: true })}
                    intersection-trigger
                    key="more"
                    value={labelMore}
                />
            )}

            {!fetching && !moreLines && (
                <Note value={labelNoMore} onClick={() => handleMovementsFetch({ isIntersecting: false })} />
            )}
        </IntersectionObserver>
    );

    function list(payments) {
        return payments.map(
            ({
                paymentOrder,
                dueDate,
                issueDate,
                currency,
                netAmount,
                balanceAmount,
                amount,
                rate,
                interests,
                discountableAmount,
                term,
            }) => {
                return (
                    <ExpandElement key={`advancePayments-${GenID()}`}>
                        <GridLayout>
                            <div>{paymentOrder}</div>
                            <div>
                                <DataDate value={dueDate} />
                            </div>
                            <div>
                                <DataDate value={issueDate} />
                            </div>
                            <Box justify="end">
                                <DataNumber value={netAmount} prefix={currency} />
                            </Box>
                            <Box justify="end">
                                <DataNumber value={balanceAmount} prefix={currency} />
                            </Box>
                            <Box justify="end">
                                <DataNumber value={amount} prefix={currency} />
                            </Box>
                        </GridLayout>
                        <GridLayout className="detail">
                            <div>
                                <label>{i18n.labelRate}</label>
                                {rate}%
                            </div>
                            <div>
                                <label>{i18n.labelInterests}</label>
                                {interests * -1} {/* El valor debe estar en negativo */}
                            </div>
                            <div>
                                <label>{i18n.labelDiscountableAmount}</label>
                                <DataNumber value={discountableAmount} prefix={currency} />
                            </div>
                            <div>
                                <label>{i18n.labelTerm}</label>
                                {term}
                            </div>
                        </GridLayout>
                    </ExpandElement>
                );
            },
        );
    }

    function handleMovementsFetch(ev) {
        const { isIntersecting } = ev;
        if (!isIntersecting) {
            return;
        }
        dispatch(SelectorsActionAdvancePayments.listAdvancePaymentOrdersRequest({ moreOrders: true }));
    }

    function Note({ value, ...rest }) {
        return (
            <Element type={TYPE_NOTE} name={NAME_NOTE} tag="span" {...rest}>
                {value}
            </Element>
        );
    }
}
const mapStateToProps = (store) => ({
    advancePayments: SelectorsStoreAdvancePayments.getAdvancePaymentsList(store),
    fetching: SelectorsStoreAdvancePayments.isFetching(store),
    moreLines: SelectorsStoreAdvancePayments.getMoreLines(store),
    totalAdvancePaymentAmountUSD: SelectorsStoreAdvancePayments.getTotalAdvancePaymentAmountUSD(store),
    totalAdvancePaymentAmountUYU: SelectorsStoreAdvancePayments.getTotalAdvancePaymentAmountUYU(store),
    totalAdvancePaymentBalanceAmountUYU: SelectorsStoreAdvancePayments.getTotalAdvancePaymentBalanceAmountUYU(store),
    totalAdvancePaymentBalanceAmountUSD: SelectorsStoreAdvancePayments.getTotalAdvancePaymentBalanceAmountUSD(store),

    i18n: {
        notAvailable: StoreI18n.getMessage(store, "data.notAvailable.short"),
        loading: StoreI18n.getMessage(store, "global.loading"),
        labelMore: StoreI18n.getMessage(store, "listAdvancePayments.list.moreOrders"),
        labelNoMore: StoreI18n.getMessage(store, "listAdvancePayments.list.noMoreOrders"),
        labelRate: StoreI18n.getMessage(store, "listAdvancePayments.list.detail.rate"),
        labelInterests: StoreI18n.getMessage(store, "listAdvancePayments.list.detail.interests"),
        labelDiscountableAmount: StoreI18n.getMessage(store, "listAdvancePayments.list.detail.discountableAmount"),
        labelTerm: StoreI18n.getMessage(store, "listAdvancePayments.list.detail.term"),
        headingPaymentOrder: StoreI18n.getMessage(store, "listAdvancePayments.list.header.paymentOrder"),
        headingDueDate: StoreI18n.getMessage(store, "listAdvancePayments.list.header.dueDate"),
        headingIssueDate: StoreI18n.getMessage(store, "listAdvancePayments.list.header.issueDate"),
        headingNetAmount: StoreI18n.getMessage(store, "listAdvancePayments.list.header.netAmount"),
        headingBalanceAmount: StoreI18n.getMessage(store, "listAdvancePayments.list.header.balanceAmount"),
        headingAmount: StoreI18n.getMessage(store, "listAdvancePayments.list.header.amount"),
        totalBalanceAmountLabel: StoreI18n.getMessage(
            store,
            "listAdvancePayments.list.totalizer.totalBalanceAmount.label",
        ),
        totalAmountLabel: StoreI18n.getMessage(store, "listAdvancePayments.list.totalizer.totalAmount.label"),
    },
});
export default Connect(mapStateToProps)(Component);
