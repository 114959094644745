/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React from "react";

import ClassNames from "classnames";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as ReactVirtualized from "react-virtualized";

import { EMPTY_STR, SPACE_STR, ZERO, ZERO_NUMBER, PREVIOUS, NEXT } from "~/constants";
import { STATUS } from "~/constants/transaction";
import { ID_FORM, MODE } from "~/constants/form";
import { SelectorsStore as SelectorsStoreForm } from "~/store/form";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { SelectorsStore as SelectorsStoreMultilinefile } from "~/store/multilinefile";
import {
    SelectorsAction as SelectorsActionTransactionLines,
    SelectorsStore as SelectorsStoreTransactionLines,
} from "~/store/transactionLines";
import { generateId as GenID } from "~/util/general";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import GenericButton from "~/components/Button";
import GridLayout from "~/components/GridLayout";
import HighOrder from "~/components/HighOrder";
import WidgetImage from "~/components/WidgetImage";
import FormattedAmount from "~/pages/_components/FormattedAmount";

import Namespace from "./List.rules.scss";
import Options from "./Options";
import SalaryPaymentRow from "./rows/SalaryPaymentRow";
import SuppliersPaymentRow from "./rows/SuppliersPaymentRow";

export const { CLASS, NAME } = Namespace;

export const PROP = {
    types: {
        ...TypesRedux,
        errors: PropTypes.shape({}),
        filter: PropTypes.func,
        handleBackClick: PropTypes.func,
        idForm: PropTypes.string,
        i18n: PropTypes.object,
        isReadOnly: PropTypes.bool.isRequired,
    },
    defaults: {
        ...DefaultsRedux,
        errors: null,
        filter: null,
        handleBackClick: null,
        idForm: EMPTY_STR,
        i18n: {
            accountName: EMPTY_STR,
            amount: EMPTY_STR,
            bank: EMPTY_STR,
            creditAccount: EMPTY_STR,
        },
        isReadOnly: false,
    },
};

export function Component(props) {
    const {
        dispatch,
        errors,
        handleBackClick,
        i18n,
        idForm,
        isReadOnly,
        lines,
        linesWithStatus,
        mode,
        paymentCurrency,
        totalAmount,
        transaction,
    } = props;

    const cache = React.useRef(
        new ReactVirtualized.CellMeasurerCache({
            defaultHeight: 50,
            fixedWidth: true,
        }),
    );

    const isSuppliersPayment = idForm === ID_FORM.SUPPLIERS_PAYMENT;
    const isSalaryPayment = idForm === ID_FORM.SALARY_PAYMENT;

    const COLUMN_HEADINGS = isSuppliersPayment
        ? [i18n.bank, i18n.accountName, i18n.reference, SPACE_STR, i18n.amount]
        : [
              i18n.bank,
              i18n.creditAccount,
              i18n.accountName,
              i18n.amount,
              mode === MODE.VIEW && linesWithStatus > 0 ? i18n.status : SPACE_STR,
          ];

    const [search, setSearch] = React.useState(EMPTY_STR);
    const [error, setError] = React.useState(false);
    const [scrollToIndex, setScrollToIndex] = React.useState(ZERO_NUMBER);
    const [filteredList, setFilteredList] = React.useState([]);
    const [filterIndex, setFilterIndex] = React.useState(ZERO_NUMBER);
    const [checked, setChecked] = React.useState(false);
    const [buttonPressed, setButtonPressed] = React.useState(EMPTY_STR);
    const [orderBy, setOrderBy] = React.useState(null);
    const [lineNumber, setLineNumber] = React.useState(ZERO_NUMBER);

    React.useEffect(() => {
        if (buttonPressed === PREVIOUS) {
            findPrevious();
        } else if (buttonPressed === NEXT) {
            findNext();
        }
    }, [buttonPressed, checked, findNext, findPrevious]);

    React.useEffect(() => {
        const filteredLines = filterLines();

        setFilteredList(filteredLines);
        setScrollToIndex(lineNumber);
    }, [filterLines, lineNumber, lines]);

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const handleChange = (e) => {
        const { value } = e.target;

        setSearch(value);
    };

    const filter = () => {
        const filteredLines = filterLines();

        if (search.trim() === EMPTY_STR || filteredLines.length === 0) {
            setError(true);

            return;
        }

        setError(false);
        setFilteredList(filteredLines);
        setScrollToIndex(filteredLines[ZERO_NUMBER] - 1);
    };

    const filterLines = () => {
        return lines
            .filter((row) => {
                if (row.creditAccountName && row.creditAccountName.toLowerCase().includes(search.toLowerCase())) {
                    return row;
                }
            })
            .map((row) => row.lineNumber);
    };

    const findNext = () => {
        if (checked) {
            const nextIndex = filterIndex + 1;
            const nextMatch = filteredList[nextIndex] - 1;

            if (filterIndex < filteredList.length - 1) {
                setFilterIndex(nextIndex);
                setScrollToIndex(nextMatch);
            } else if (filterIndex === filteredList.length - 1) {
                setFilterIndex(ZERO_NUMBER);
                setScrollToIndex(filteredList[ZERO_NUMBER] - 1);
            }

            setChecked(false);
        }
    };

    const findPrevious = () => {
        if (checked) {
            const nextIndex = filterIndex - 1;
            const nextMatch = filteredList[nextIndex] - 1;

            if (filterIndex > ZERO_NUMBER) {
                setFilterIndex(nextIndex);
                setScrollToIndex(nextMatch);
            } else if (filterIndex === ZERO_NUMBER) {
                setFilterIndex(filteredList.length - 1);
                setScrollToIndex(filteredList[filteredList.length - 1] - 1);
            }

            setChecked(false);
        }
    };

    const sortByName = (header) => {
        if (header === i18n.accountName) {
            dispatch(SelectorsActionTransactionLines.sortLinesByName({ order: orderBy }));
        }

        setOrderBy(!orderBy);
    };

    const checker = (value) => {
        setChecked(true);
        setButtonPressed(value);
    };

    const showOnlyWithLines = lines.length > 0;

    const rowRenderer = ({ index, style, parent }) => {
        const line = lines[index];
        const { lineNumber: lineNumberToSet, ...values } = line;

        return (
            <ReactVirtualized.CellMeasurer
                key={GenID()}
                cache={cache.current}
                parent={parent}
                columnIndex={0}
                rowIndex={index}>
                <Box flex directionColumn style={style}>
                    <GridLayout
                        className={ClassNames(
                            { "header-suppliers": isSuppliersPayment },
                            { "header-salary": isSalaryPayment },
                            { "has-error": errors && errors[`line@${lineNumberToSet}`] },
                        )}>
                        {isSuppliersPayment && (
                            <SuppliersPaymentRow
                                id={GenID()}
                                idForm={idForm}
                                isReadOnly={isReadOnly}
                                lineNumber={lineNumberToSet}
                                values={values}
                            />
                        )}
                        {isSalaryPayment && (
                            <SalaryPaymentRow
                                id={GenID()}
                                idForm={idForm}
                                isReadOnly={isReadOnly}
                                lineNumber={lineNumberToSet}
                                mode={mode}
                                paymentCurrency={paymentCurrency}
                                values={values}
                            />
                        )}
                        {!isReadOnly && (
                            <Options
                                onEdit={() => handleEditPaymentClick(lineNumberToSet)}
                                onRemove={() => handleRemoveLine(lineNumberToSet)}
                            />
                        )}
                        {mode === MODE.VIEW &&
                            (transaction.idTransactionStatus === STATUS.FINISHED ||
                                transaction.idTransactionStatus === STATUS.PROCESSING) && (
                                <a>
                                    <WidgetImage
                                        src="download_line.svg"
                                        onClick={() => downloadLinePDF(lineNumberToSet)}
                                    />
                                </a>
                            )}
                    </GridLayout>
                    {errors && errors[`line@${lineNumberToSet}`] && (
                        <Box
                            flex
                            justify="start"
                            className={ClassNames({ "has-error": errors && errors[`line@${lineNumberToSet}`] })}>
                            <span>{errors[`line@${lineNumberToSet}`]}</span>
                        </Box>
                    )}
                </Box>
            </ReactVirtualized.CellMeasurer>
        );
    };

    return (
        <Box className={ClassNames(CLASS, { view: mode === MODE.VIEW })}>
            {showOnlyWithLines && (
                <form className="container" onSubmit={handleSubmit}>
                    <Box className="wrap">
                        <input
                            className="input"
                            type="text"
                            placeholder={UtilsI18n.get("forms.inputFile.massivePayments.name.placeholder")}
                            onBlur={handleChange}
                        />
                        <Box className="info">
                            <button className="search" onClick={filter}>
                                {UtilsI18n.get("global.search")}
                            </button>
                        </Box>
                    </Box>
                    <Box>
                        <button className={PREVIOUS} onClick={() => checker(PREVIOUS)} />
                        <button className={NEXT} onClick={() => checker(NEXT)} />
                    </Box>
                    {error && (
                        <Box className="error">{UtilsI18n.get("forms.inputFile.massivePayments.invalid.search")}</Box>
                    )}
                </form>
            )}
            <GridLayout
                className={ClassNames(
                    { "header-suppliers mt-4": isSuppliersPayment },
                    { "header-salary mt-4": isSalaryPayment },
                )}>
                {COLUMN_HEADINGS.map((heading) => (
                    <div key={GenID()} onClick={() => sortByName(heading)}>
                        {heading}
                        {showOnlyWithLines && heading === i18n.accountName && (
                            <Box>
                                {orderBy === null ? (
                                    <Box className="container-order">
                                        <button className="asc" />
                                        <button className="desc" />
                                    </Box>
                                ) : (
                                    <Box className="container-order">
                                        {orderBy ? <button className="asc" /> : <button className="desc" />}
                                    </Box>
                                )}
                            </Box>
                        )}
                    </div>
                ))}
            </GridLayout>
            {showOnlyWithLines && (
                <Box className="container-virtualized">
                    <ReactVirtualized.AutoSizer>
                        {({ width, height }) => (
                            <ReactVirtualized.List
                                className="scrollbar"
                                scrollToAlignment="start"
                                scrollToIndex={scrollToIndex}
                                height={height}
                                rowCount={lines.length}
                                rowHeight={cache.current.rowHeight}
                                deferredMeasurementCache={cache.current}
                                rowRenderer={rowRenderer}
                                width={width}
                            />
                        )}
                    </ReactVirtualized.AutoSizer>
                </Box>
            )}
            <Box flex className="mt-6 footer">
                <Box flex directionColumn grow>
                    <Box flex justify="end" className="snow-dark-bg">
                        <Box className="px-4 my-4 vertical-bar">
                            {UtilsI18n.get("forms.inputFile.massivePayments.totalAmount")}
                        </Box>
                    </Box>
                    <Box flex justify="end" className="snow-bg">
                        <Box className="px-4 my-4 vertical-bar">
                            {UtilsI18n.get("forms.inputFile.massivePayments.lines")}
                        </Box>
                    </Box>
                </Box>
                <Box flex directionColumn className="values">
                    <Box className="snow-dark-bg p-4">
                        <Box>
                            <FormattedAmount
                                currency={totalAmount.currency.toString() || ZERO}
                                quantity={totalAmount.quantity || ZERO}
                            />
                        </Box>
                    </Box>
                    <Box className="snow-bg p-4">
                        <Box>{lines.length}</Box>
                    </Box>
                </Box>
            </Box>
            {mode === MODE.EDIT && (
                <Box flex justify="end" className="footer">
                    <Box flex directionColumn grow>
                        {SPACE_STR}
                    </Box>
                    <Box flex directionColumn className="values">
                        <Box flex directionColumn className="footerButton">
                            <footer>
                                <GenericButton bsStyle="primary" onClick={handleBackClick} label="global.continue" />
                            </footer>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );

    function handleEditPaymentClick(lineNumberToEdit) {
        dispatch(
            SelectorsActionTransactionLines.setIsEditingPayment({
                isEditingPayment: true,
                lineNumber: lineNumberToEdit,
            }),
        );
    }

    function handleRemoveLine(lineNumberToRemove) {
        dispatch(SelectorsActionTransactionLines.removeTransactionLine({ lineNumber: lineNumberToRemove }));
        setLineNumber(lineNumber);
    }

    function downloadLinePDF(lineNumber) {
        const idTransactionToRead = transaction.idTransaction;
        dispatch(SelectorsActionTransactionLines.downloadLinePDF({ lineNumber, idTransactionToRead }));
    }
}

Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

const mapStateToProps = (store) => ({
    activeFilters: SelectorsStoreTransactionLines.getActiveFilters(store),
    errors: SelectorsStoreTransactionLines.getErrors(store),
    fetching: SelectorsStoreTransactionLines.getFetching(store),
    i18n: {
        accountName: SelectorsStoreI18n.getMessage(store, "transaction.process.details.table.header.accountName"),
        amount: SelectorsStoreI18n.getMessage(store, "transaction.process.details.table.header.amount"),
        bank: SelectorsStoreI18n.getMessage(store, "transaction.process.details.table.header.bank"),
        creditAccount: SelectorsStoreI18n.getMessage(store, "transaction.process.details.table.header.creditAccount"),
        reference: SelectorsStoreI18n.getMessage(store, "transaction.process.details.table.header.reference"),
        status: SelectorsStoreI18n.getMessage(store, "client.payments.paySalary.payment.status"),
    },
    linesWithInitialValues: SelectorsStoreTransactionLines.getLinesWithInitialValues(store),
    linesWithStatus: SelectorsStoreTransactionLines.getLinesWithStatus(store),
    pageNumber: SelectorsStoreTransactionLines.getPageNumber(store) + 1,
    processedFileData: SelectorsStoreMultilinefile.getProcessedFileData(store),
    totalAmount: SelectorsStoreTransactionLines.getTotalAmount(store),
    transaction: SelectorsStoreForm.getTransaction(store),
});

export default HighOrder(WithRouter, Connect(mapStateToProps), HighOrder.Resizable)(Component);
