import React from "react";

import { connect as Connect } from "react-redux";

import { SelectorsStore as SelectorsStoreForm, PROP as PropForm } from "~/store/form";
import { ToRequired } from "~/util/prop";

import Box from "~/components/Box";

import ManualPayment from "~/pages/forms/_components/_fields/_multilinefile/ManualPayment";

export const NAME = "SalaryPayment";

export const PROP = {
    types: {
        ...ToRequired(PropForm.types, ["previewData"]),
    },
    defaults: {
        ...PropForm.defaults,
    },
};

export function Component() {
    return (
        <Box>
            <Box>
                <ManualPayment idForm="salaryPayment" isReadOnly />
            </Box>
        </Box>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (state) => ({
    previewData: SelectorsStoreForm.getPreviewData(state),
});

export default Connect(mapStateToProps)(Component);
