import { push as Push } from "connected-react-router";
import { call as Call, put as Put, takeLatest as TakeLatest, select as Select } from "redux-saga/effects";

import {
    ENVIRONMENT_TYPE,
    FORM_FIELD_ERRORS,
    INSUFFICIENT_SIGNATURES,
    LEVEL,
    RESPONSE_TYPE,
    SCHEDULED,
    SCOPE,
    SIGN_WITH_BIOMETRIC,
    TERM_DEPOSIT_INSUFFICIENT_BALANCE,
    TERM_DEPOSIT_INVALID_ACCOUNT_CURRENCY,
    TERM_DEPOSIT_INVALID_DEBIT_ACCOUNT,
    VALIDATION_ERROR,
    ZERO,
} from "~/constants";
import Store from "~/store";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import IsTrustedBiometric from "~/store/form/_sagas/_isTrustedBiometric";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionSession, SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { downloadPdf as DownloadPdf } from "~/util/download";
import {
    credentialsWithUnderscore as CredentialsWithUnderscore,
    hasIncorrectCredentials as HasIncorrectCredentials,
    adjustIdFieldErrors as AdjustIdFieldErrors,
} from "~/util/form";
import * as UtilsI18n from "~/util/i18n";
import UtilLodash from "~/util/lodash";

import { TYPE } from "./_consts";
import { SelectorsMiddleware, SelectorsAction } from "./_selectors";

export default [
    TakeLatest(TYPE.MY_BRIEFCASE_PRE_REQUEST, myBriefcasePreRequest),
    TakeLatest(TYPE.REQUEST_INFO_PRE_REQUEST, requestInfoPreRequest),
    TakeLatest(TYPE.REQUEST_INFO_PREVIEW_REQUEST, requestInfoPreviewRequest),
    TakeLatest(TYPE.REQUEST_INFO_SEND_FORM, requestInfoSendFormRequest),
    TakeLatest(TYPE.TERM_DEPOSIT_PRE_REQUEST, termDepositPreRequest),
    TakeLatest(TYPE.TERM_DEPOSIT_PREVIEW_REQUEST, termDepositPreviewRequest),
    TakeLatest(TYPE.TERM_DEPOSIT_SEND_REQUEST, termDepositSendRequest),
    TakeLatest(TYPE.TERM_DEPOSIT_CUSTOM_RATE_REQUEST, termDepositGetCustomRateRequest),

    TakeLatest(TYPE.READ_TRANSACTION_REQUEST, readTransactionRequest),
    TakeLatest(TYPE.CANCEL_CUSTOM_TRANSACTION_REQUEST, cancelTransactionCustom),
    TakeLatest(TYPE.SIGN_TRANSACTION_REQUEST, signTransaction),
    TakeLatest(TYPE.RISK_PROFILE_SEND_QUESTIONS_REQUEST, sendQuestions),
    TakeLatest(TYPE.RISK_PROFILE_DOWNLOAD_PDF_REQUEST, downloadPdf),
    TakeLatest(TYPE.RISK_PROFILE_READ_LAST, readLastRiskProfile),
];

function* myBriefcasePreRequest() {
    const { type, data } = yield Call(SelectorsMiddleware.myBriefcasePreRequest);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.myBriefcasePreFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.INVESTMENT_MY_BRIEFCASE],
            }),
        );
    } else {
        yield Put(SelectorsAction.myBriefcasePreSuccess({ ...data.data }));
    }
}

function* termDepositPreRequest() {
    const { type, data } = yield Call(SelectorsMiddleware.termDepositPreRequest);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.termDepositPreFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.INVESTMENT_OTHER_INVESTMENTS],
            }),
        );
    } else {
        yield Put(SelectorsAction.termDepositPreSuccess({ ...data.data }));
    }
}

function* termDepositPreviewRequest({ formData, formikBag }) {
    const environmentType = yield Select(SelectorsStoreSession.getActiveEnvironmentType);

    const { type, data } =
        environmentType !== ENVIRONMENT_TYPE.CORPORATE
            ? yield Call(SelectorsMiddleware.termDepositPreviewRequest, formData)
            : yield Call(SelectorsMiddleware.termDepositCMBPreviewRequest, formData);

    if (type === RESPONSE_TYPE.WARNING) {
        const errorMessage =
            data.code === VALIDATION_ERROR ? data.data.NO_FIELD || UtilsI18n.get(FORM_FIELD_ERRORS) : data.message;

        formikBag.setErrors(data.data);

        const touchedFields = {};
        const fieldsWithErrors = Object.keys(data.data);

        for (let index = 0; index < fieldsWithErrors.length; index += 1) {
            const field = fieldsWithErrors[index];

            touchedFields[field] = true;
        }

        formikBag.setTouched(touchedFields);

        yield Put(SelectorsAction.termDepositPreviewFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.INVESTMENT_OTHER_INVESTMENTS],
            }),
        );
    } else {
        yield Put(SelectorsAction.termDepositPreviewSuccess({ ...data.data }));
    }

    formikBag.setSubmitting(false);
}

function* termDepositSendRequest({ formData, formikBag, otp }) {
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
    }

    const { webToken } = Store.getState().session;

    const tokenToUse = otp || webToken;

    let response;

    if (UtilLodash.isEmpty(tokenToUse)) {
        response = {
            data: {
                code: VALIDATION_ERROR,
                message: UtilsI18n.get(`returnCode.${VALIDATION_ERROR}`),
                data: {
                    otp: UtilsI18n.get("returnCode.COR027W"),
                },
            },
            status: 200,
            type: RESPONSE_TYPE.WARNING,
        };
    } else {
        const environmentType = yield Select(SelectorsStoreSession.getActiveEnvironmentType);
        const data = {
            ...formData,
            _otp: tokenToUse,
        };

        response =
            environmentType !== ENVIRONMENT_TYPE.CORPORATE
                ? yield Call(SelectorsMiddleware.termDepositSendRequest, data)
                : yield Call(SelectorsMiddleware.termDepositCMBSendRequest, data);
    }

    if (response.type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = Object.keys(response.data.data).some((key) => key === "otp");

        let message = UtilsI18n.get("global.unexpectedError");

        if (hasIncorrectCredentials) {
            formikBag.setErrors(response.data.data);
        } else if (
            TERM_DEPOSIT_INVALID_ACCOUNT_CURRENCY === response.data.code ||
            TERM_DEPOSIT_INVALID_DEBIT_ACCOUNT === response.data.code ||
            TERM_DEPOSIT_INSUFFICIENT_BALANCE === response.data.code
        ) {
            message = response.data.message;
        } else if (VALIDATION_ERROR === response.data.code) {
            message = response.data.data.message;
        }

        yield Put(
            SelectorsActionNotification.showNotification({
                message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.INVESTMENT_OTHER_INVESTMENTS],
            }),
        );

        formikBag.setSubmitting(false);

        yield Put(SelectorsAction.termDepositSendFailure());
    } else {
        const { idTransaction } = response.data;
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });

        yield Put(SelectorsAction.termDepositSendSuccess({ transaction }));
    }
}

function* termDepositGetCustomRateRequest(props) {
    const { type, data } = yield Call(SelectorsMiddleware.termDepositGetCustomRate, props);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.termDepositGetCustomRateFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.INVESTMENT_OTHER_INVESTMENTS],
            }),
        );
    } else {
        yield Put(SelectorsAction.termDepositGetCustomRateSuccess({ ...data.data }));
    }
}

function* readTransactionRequest(props) {
    const { idTransaction } = props;
    const { type, data } = yield Call(SelectorsMiddlewareForm.readTransaction, {
        idTransactionToRead: idTransaction,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.INVESTMENT_OTHER_INVESTMENTS],
            }),
        );
    } else {
        yield Put(SelectorsAction.readTransactionSuccess({ transaction: data.data.transaction }));
    }
}

function* cancelTransactionCustom(props) {
    const { credentials, idTransaction, formikBag } = props;

    yield* handleCancelTransaction(credentials, idTransaction, formikBag);

    formikBag.setSubmitting(false);
}

function* handleCancelTransaction(credentials, idTransaction, formikBag) {
    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);
    const {
        data: { data },
        type,
    } = yield Call(SelectorsMiddlewareForm.cancelTransaction, {
        idTransactionToCancel: idTransaction,
        ...credentialsWithUnderscore,
    });

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data);
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("forms.cancelTransaction.errorMessage"),
                    level: LEVEL.ERROR,
                    scopes: SCOPE.INVESTMENT_OTHER_INVESTMENTS,
                }),
            );
        }
    } else {
        yield* readTransactionRequest({ idTransaction });

        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("forms.cancelTransaction.confirmationMessage"),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.INVESTMENT_OTHER_INVESTMENTS, SCOPE.DESKTOP],
            }),
        );
    }
}

function* signTransaction(props) {
    const { credentials, idActivity, idForm, idTransaction, formikBag } = props;
    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

    if (isTrusted) {
        yield* IsTrustedBiometric(formikBag, SIGN_WITH_BIOMETRIC);
    }

    const credentialsWithUnderscore = CredentialsWithUnderscore(credentials);

    const { data, type } = yield Call(
        SelectorsMiddlewareForm.sign,
        { idForm, idTransaction, ...credentialsWithUnderscore },
        idActivity,
    );

    if (type === RESPONSE_TYPE.WARNING) {
        const hasIncorrectCredentials = HasIncorrectCredentials(credentials, data);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(AdjustIdFieldErrors(data.data));
        } else {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.INVESTMENT_OTHER_INVESTMENTS],
                }),
            );
        }

        yield Put(SelectorsAction.signTransactionFailure());

        formikBag.setSubmitting(false);
    } else {
        const {
            data: {
                data: { transaction },
            },
        } = yield Call(SelectorsMiddlewareForm.readTransaction, { idTransactionToRead: idTransaction });

        yield Put(SelectorsAction.termDepositSendSuccess({ transaction }));

        if (idActivity === "investment.riskProfile.send") {
            yield Put(SelectorsActionSession.changeRiskProfileData({ ...data.data }));
        }

        formikBag.setSubmitting(false);
    }
}

function* requestInfoPreRequest() {
    const { type, data } = yield Call(SelectorsMiddleware.requestInfoPreRequest);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.requestInfoPreFailure());

        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.REQUEST_INFO],
            }),
        );
    } else {
        yield Put(SelectorsAction.requestInfoPreSuccess({ ...data.data }));
    }
}

function* requestInfoPreviewRequest({ submitData, formikBag }) {
    const { type, data } = yield Call(SelectorsMiddleware.requestInfoPreviewRequest, submitData);

    formikBag.setSubmitting(false);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.requestInfoPreviewFailure());

        formikBag.setErrors(data.data);
        const touchedFields = {};
        const fieldsWithErrors = Object.keys(data.data);
        // eslint-disable-next-line no-return-assign
        fieldsWithErrors.map((f) => (touchedFields[f] = true));
        formikBag.setTouched(touchedFields);

        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("forms.fieldsErrors"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.REQUEST_INFO],
            }),
        );
    } else {
        yield Put(SelectorsAction.requestInfoPreviewSuccess());
    }
}

function* requestInfoSendFormRequest({ submitData, formikBag }) {
    const { type, data } = yield Call(SelectorsMiddleware.requestInfoRequest, submitData);

    formikBag.setSubmitting(false);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.requestInfoSendFormFailure());
        formikBag.setErrors(data.data);

        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("forms.fieldsErrors"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.REQUEST_INFO],
            }),
        );
    } else {
        yield Put(SelectorsAction.requestInfoSendFormSuccess());
    }
}

function* sendQuestions(props) {
    const { type, data } = yield Call(SelectorsMiddleware.requestSendQuestions, props);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.riskProfileSendQuestionsFailure({ ...data.data }));

        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("global.unexpectedError"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.RISK_PROFILE_STEP_3],
            }),
        );
    } else if (data.code === SCHEDULED) {
        const { idTransaction } = data;

        const { type: transactionType, data: transactionData } = yield Call(SelectorsMiddlewareForm.readTransaction, {
            idTransactionToRead: idTransaction,
        });

        if (transactionType === RESPONSE_TYPE.WARNING) {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: transactionData.message,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.RISK_PROFILE_STEP_3],
                }),
            );

            yield Put(SelectorsAction.riskProfileSendQuestionsFailure());
        } else {
            yield Put(SelectorsAction.readTransactionSuccess({ transaction: transactionData.data.transaction }));

            yield Put(SelectorsAction.riskProfileSendQuestionsSuccess({ ...transactionData.data }));

            yield Put(Push(`/investment/transaction/riskProfile/${idTransaction}/ticket`));
        }
    } else {
        let forwardingRoute = "/wm/funds/list";
        const activeEnviroment = yield Select(SelectorsStoreSession.getActiveEnvironment);
        const {
            permissions: { fundsWithoutW8RiskProfile = false },
        } = activeEnviroment;

        if (ZERO === data.data.riskProfileCode.toString() || !fundsWithoutW8RiskProfile) {
            forwardingRoute = "/investment/riskProfileResume";
        }

        if (data.code === INSUFFICIENT_SIGNATURES) {
            forwardingRoute = "/desktop";

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.message,
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.DESKTOP],
                }),
            );
        } else if (data.code === SCHEDULED) {
            yield Put(SelectorsAction.riskProfileSendQuestionsFailure());

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: data.message,
                    level: LEVEL.SUCCESS,
                    scopes: [SCOPE.RISK_PROFILE_RESUME],
                }),
            );
        } else {
            yield Put(SelectorsAction.riskProfileReadLast());

            yield Put(SelectorsActionSession.changeRiskProfileData({ ...data.data }));

            yield Put(SelectorsAction.riskProfileSendQuestionsSuccess({ ...data.data }));

            yield Put(SelectorsAction.setFromRiskProfileQuiz({ isFromRiskProfileQuiz: true }));
        }

        yield Put(Push(forwardingRoute));
    }
}

function* downloadPdf() {
    const response = yield Call(SelectorsMiddleware.requestDownloadPDF);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.RISK_PROFILE_STEP_1, SCOPE.RISK_PROFILE_RESUME],
            }),
        );

        yield Put(SelectorsAction.riskProfileDownloadPDFFailure());
    } else {
        const { content, fileName } = response.data.data;

        DownloadPdf(fileName, content);
    }
}

function* readLastRiskProfile() {
    const { type, data } = yield Call(SelectorsMiddleware.requestReadLastRiskProfile);

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsAction.riskProfileReadLastFailure);
    } else {
        yield Put(SelectorsAction.riskProfileReadLastSuccess({ ...data.data }));
    }
}
