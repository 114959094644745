import { LOCATION_CHANGE } from "connected-react-router";

import { shouldKeepFormState as ShouldKeepFormState } from "~/util/form";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            const shouldKeep = ShouldKeepFormState(storeProp, action.payload.location.pathname);
            if (shouldKeep) {
                return {
                    ...storeProp,
                };
            }
            return {
                ...PROP.defaults,
            };
        }
        case TYPE.CLEAR_LIST:
            return {
                isFetching: false,
                comboListTerm: [],
                comboListDestination: [],
            };
        case TYPE.LIST_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.LIST_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
            };
        case TYPE.LIST_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                comboListTerm: action.data.comboListTerm,
                comboListDestination: action.data.comboListDestination,
            };
        case TYPE.CHANGE_VALUE:
            return {
                ...storeProp,
                changeValue: true,
            };
        case TYPE.CHANGE_VALUE_NOT:
            return {
                ...storeProp,
                changeValue: false,
            };
        default:
            return storeProp;
    }
};
