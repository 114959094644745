import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";

import Button from "~/components/Button";
import Image from "~/components/Image";

export const NAME = "MessageActions";

export const PROP = {
    types: {
        communication: PropTypes.shape({
            idFileList: PropTypes.array,
            direction: PropTypes.string,
            userRead: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
            idCommunication: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }).isRequired,
        handleChangeMessageStatus: PropTypes.func.isRequired,
        index: PropTypes.number.isRequired,
    },
    defaults: {},
};

export function Component(props) {
    const { communication, handleChangeMessageStatus, index } = props;
    return (
        <div className="icons">
            <div className="icon">{communication.idFileList.length > 0 && <Image src="attachment.svg" />}</div>
            {communication.direction === "BANK_TO_CUSTOMER" && (
                <Button
                    label={EMPTY_STR}
                    image={!communication.userRead ? "email.svg" : "read.svg"}
                    onClick={(e) =>
                        handleChangeMessageStatus(e, communication.idCommunication, communication.userRead, index)
                    }
                />
            )}
        </div>
    );
}
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

export default Component;
