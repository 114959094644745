import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SelectorsStore as SelectorsStorePermissions } from "~/store/administration/common/permissions";
import * as UtilAdministration from "~/util/administration";

export const NAME = "AdvancedPermissionsAmount";

export const PROP = {
    types: {
        groups: UtilAdministration.groupsPropType.isRequired,
        permissions: UtilAdministration.permissionsPropType.isRequired,
        products: PropTypes.arrayOf(
            PropTypes.shape({
                idProduct: PropTypes.string,
            }),
        ),
        children: PropTypes.func.isRequired,
    },
    defaults: { products: [] },
};
export function Component(props) {
    const { children, groups, permissions, products } = props;

    const countOptions = ({ childrenList, permissionList, idItem }, predicate) => {
        if (permissionList.length && permissionList[0].advancedAllowProductSelection) {
            return predicate ? predicate({ idItem, productTypes: permissionList[0].productTypes.split(",") }) : 1;
        }

        if (childrenList.length) {
            return childrenList.reduce((amount, option) => amount + countOptions(option, predicate), 0);
        }

        if (predicate && !predicate({ idItem })) {
            return 0;
        }

        return 1;
    };

    return children(
        groups.reduce((amounts, group) => {
            const amount = countOptions(group, ({ idItem, productTypes = [] }) => {
                const values = permissions[idItem] || [];

                if (!productTypes.length) {
                    return values.length;
                }

                const filteredProducts = products.filter(({ productType }) => productTypes.includes(productType));
                const { selectedSmartGroupAmount, smartGroupAmount } = UtilAdministration.smartGroupsOptionsCount(
                    productTypes,
                    values,
                );
                const { selectedProductsAmount, productsAmount } = UtilAdministration.productsOptionsCount(
                    filteredProducts,
                    values,
                );

                if (
                    (selectedSmartGroupAmount === smartGroupAmount && smartGroupAmount > 0) ||
                    (selectedProductsAmount === productsAmount && productsAmount > 0)
                ) {
                    return true;
                }

                return values.length;
            });

            if (!amount) {
                return amounts;
            }

            return {
                ...amounts,
                [group.idItem]: amount,
            };
        }, {}),
        groups.reduce((amounts, group) => ({ ...amounts, [group.idItem]: countOptions(group) }), {}),
    );
}

const mapStateToProps = (state, ownProps) => ({
    products: SelectorsStorePermissions.getProducts(state),
    permissions: ownProps.permissions || SelectorsStorePermissions.getPermissions(state),
    groups: SelectorsStorePermissions.getGroups(state),
});

export default Connect(mapStateToProps)(Component);
