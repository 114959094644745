import React from "react";

import { goBack as GoBack } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";

import { STATE_NUMBER, SCOPE, statementDateFormat as dateFormat } from "~/constants";
import Container from "~/containers/Internal/Form/List";
import { SelectorsAction as SelectorsActionAccount, SelectorsStore as SelectorsStoreAccount } from "~/store/account";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { specificDate as SpecificDate } from "~/util/date";

import DataDate from "~/components/DataDate";

export const NAME = "Statements";

export const PROP = {
    types: {
        i18n: PropTypes.object,
        idAccount: PropTypes.string.isRequired,
        isChangingEnvironment: PropTypes.bool,
        isFetching: PropTypes.bool,
        statements: PropTypes.arrayOf(PropTypes.object),
    },
    defaults: {
        i18n: {
            loading: "Cargando",
        },
        isChangingEnvironment: false,
        isFetching: false,
        statements: [],
    },
};

export function Component(props) {
    const { dispatch, i18n, isFetching, statements } = props;

    if (statements && statements.length > STATE_NUMBER) {
        //  Show just last 12 months
        statements.splice(STATE_NUMBER - 1, statements.length - STATE_NUMBER);
    }

    return (
        <React.Fragment>
            <Container
                name={NAME}
                head-onBack={handleBack}
                head-title="accounts.accountStatements"
                wait={isFetching}
                scopeToShowNotification={SCOPE.ACCOUNT_DOWNLOAD_PDF_FAILURE}>
                {!statements && <span>{i18n.loading}</span>}
                {statements &&
                    statements.map(({ date, origen }) => (
                        <button key={date} type="button" onClick={handleClick.bind(null, date, origen)}>
                            <DataDate raw value={date} type-year={false} type-month="long" type-day={false} />
                            <DataDate raw value={date} type-year="numeric" type-month={false} type-day={false} />
                        </button>
                    ))}
            </Container>
        </React.Fragment>
    );

    function handleBack() {
        dispatch(GoBack());
    }

    function handleClick(date, origen) {
        const specificDate = SpecificDate(date, dateFormat);
        dispatch(SelectorsActionAccount.pdfStateAccount({ date: specificDate, origen }));
    }
}
Component.displayName = NAME;
Component.defaultProps = PROP.defaults;
Component.propTypes = PROP.types;

const mapStateToProps = (store, props) => ({
    i18n: {
        loading: StoreI18n.getMessage(store, "global.loading"),
    },
    idAccount: props.match.params.id,
    isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
    isFetching: SelectorsStoreAccount.getFetching(store),
    statements: SelectorsStoreAccount.getStateOfAccount(store),
});

export default Connect(mapStateToProps)(WithRouter(Component));
