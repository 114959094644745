import React from "react";

import { push as Push } from "connected-react-router";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SelectorsStore as StoreComm, PROP as PROP_COMM } from "~/store/communications";
import { SelectorsAction as ActionSession } from "~/store/session";
import { Categorize } from "~/util/prop";
import { $ } from "~/util/style";

import Header from "../_Header";
import Environment from "./_Environment";
import Style from "./_Header.rules.scss";

export const { NAME, TYPE } = Style;
export const PROP = {
    types: {
        "menu-onClick": PropTypes.func.isRequired,
        title: PropTypes.string,
        onClose: PropTypes.func,
        onBack: PropTypes.func,
        badge: PROP_COMM.types.unreadCommunications,
    },
    defaults: {
        title: undefined,
        onClose: undefined,
        onBack: undefined,
        badge: undefined,
    },
};

export function Component(props) {
    const { dispatch, badge, onBack, onClose, menu, title } = Categorize(props);
    const className = $(!!onBack && "hasBack", !!onClose && "hasClose", !!title && "hasTitle");

    return (
        <Header id={Style.ID} name={NAME} className={className}>
            <Header.Button aria-label="Back" role="navigation" image="left-arrow.svg" onClick={onBack} />
            <Header.Button aria-label="Close" role="navigation" image="cross.svg" onClick={onClose} />
            <Header.Button aria-label="Menu" aria-haspopup="true" image="menu-open.svg" onClick={menu.onClick} />
            <Header.Logo />
            {title && <Header.Title value={title} />}
            <Environment />

            <Header.Button
                className="tool-comm"
                image="header-notifications.svg"
                role="navigation"
                onClick={handleComm}
                data-badge={badge > 99 ? "99+" : badge}
            />
            <Header.Button className="tool-share" image="share.svg" role="navigation" onClick={handleShare} />
            <Header.Button className="tool-load" image="header-download-document.svg" onClick={handleLoad} />
            <Header.Button
                className="tool-conf"
                image="header-settings.svg"
                role="navigation"
                onClick={handleSettings}
            />
            <Header.Button className="tool-exit" image="header-logout.svg" onClick={handleExit} />
        </Header>
    );

    function handleLoad() {
        dispatch(Push("/filesDownload"));
    }

    function handleSettings() {
        dispatch(Push("/settings"));
    }

    function handleComm() {
        dispatch(Push("/communications"));
    }
    function handleShare() {
        dispatch(Push("/share"));
    }

    function handleExit() {
        dispatch(ActionSession.logout());
    }
}
Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

function mapStoreToProps(store) {
    return {
        badge: StoreComm.getUnreadCommunications(store) || undefined,
    };
}

export default Connect(mapStoreToProps)(Component);
