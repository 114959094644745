import React from "react";

import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";

import { SCOPE } from "~/constants";
import { MODE } from "~/constants/form";
import Container from "~/containers/Internal/Form/Steps";
import {
    SelectorsStore as SelectorsStoreAdvancePayments,
    SelectorsAction as SelectorsActionAdvancePayments,
} from "~/store/factoring/advancePayments";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import * as UtilsI18n from "~/util/i18n";

import HighOrder from "~/components/HighOrder";

import FormEdit from "./Step1Edit/Step1Edit";
import FormPreview from "./Step2Preview/Step2Preview";
import FormView from "./Step3View/Step3View";

export const NAME = "AdvancePaymentsTransactionTicket";

export const PROP = {
    types: {
        fetching: PropTypes.bool,
        isChangingEnvironment: PropTypes.bool,
    },
    defaults: {
        fetching: false,
        isChangingEnvironment: false,
    },
};

export function Component(props) {
    const { dispatch, fetching, isChangingEnvironment, match, mode, transaction } = props;
    const { id } = match.params;

    React.useEffect(() => {
        if (!isChangingEnvironment) {
            dispatch(SelectorsActionAdvancePayments.readAdvancePaymentTransactionRequest({ idTransaction: id }));
        }
    }, [dispatch, id, isChangingEnvironment]);

    function handleBack() {
        const { history } = props;
        history.goBack();
    }

    return (
        <Container
            name={NAME}
            wait={fetching}
            step={MODE.VIEW}
            head-title={UtilsI18n.get("menu.factoring.advancePayments")}
            head-onBack={handleBack}
            scopeToShowNotification={SCOPE.ADVANCE_PAYMENT_ORDERS}>
            {mode === MODE.EDIT && <FormEdit />}
            {mode === MODE.PREVIEW && <FormPreview />}
            {mode === MODE.VIEW && <FormView transaction={transaction} />}
        </Container>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        fetching: SelectorsStoreAdvancePayments.isFetching(store),
        isChangingEnvironment: SelectorsStoreSession.isChangingEnvironment(store),
        mode: SelectorsStoreAdvancePayments.getMode(store),
        transaction: SelectorsStoreAdvancePayments.getTransaction(store),
    };
};

export default HighOrder(Connect(mapStateToProps))(Component);
