import { call as Call, put as Put, takeLatest as TakeLatest } from "redux-saga/effects";

import {
    RESPONSE_TYPE,
    LEVEL,
    SCOPE,
    INSUFFICIENT_SIGNATURES,
    ADMINISTRATION_USER_ACTIONS,
    SUPPORTED_FILE_TYPES,
    GLOBAL_UNEXPECTED_ERROR_KEY,
} from "~/constants";
import { SelectorsMiddleware as SelectorsMiddlewareForm } from "~/store/form";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import * as UtilDownload from "~/util/download";
import { credentialsToUnderscoreFormat } from "~/util/form";
import * as UtilI18n from "~/util/i18n";

import { TYPE } from "./_consts";
import {
    SelectorsMiddleware as SelectorsMiddlewareGroups,
    SelectorsAction as SelectorsActionGroups,
} from "./_selectors";

const sagas = [
    TakeLatest(TYPE.LOAD_LIST_REQUEST, loadListRequest),
    TakeLatest(TYPE.LOAD_MORE_REQUEST, loadMoreRequest),
    TakeLatest(TYPE.EXPORT_LIST_REQUEST, exportListRequest),
    TakeLatest(TYPE.CHANGE_GROUP_STATUS_PREVIEW, changeGroupStatusPreview),
    TakeLatest(TYPE.CHANGE_GROUP_STATUS_CONFIRMATION, changeGroupStatusConfirmation),
];

export default sagas;

function* loadListRequest(props) {
    const { filters = {} } = props;
    const response = yield Call(SelectorsMiddlewareGroups.loadListRequest, filters);
    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsActionGroups.loadListFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADMINISTRATION],
            }),
        );
    } else {
        yield Put(SelectorsActionGroups.loadListSuccess({ data: response.data.data }));
    }
}

function* loadMoreRequest(props) {
    const { pageNumber } = props;

    const response = yield Call(SelectorsMiddlewareGroups.loadListRequest, { pageNumber });

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsActionGroups.loadListFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADMINISTRATION],
            }),
        );
    } else {
        yield Put(SelectorsActionGroups.loadMoreSuccess({ data: response.data.data }));
    }
}

function* exportListRequest(props) {
    const { type, data } = yield Call(SelectorsMiddlewareGroups.exportListRequest, props);
    const { format } = props;

    if (type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsActionGroups.exportListFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY),
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADMINISTRATION],
            }),
        );
    } else {
        const { content, fileName } = data.data;
        switch (format) {
            case SUPPORTED_FILE_TYPES.CSV:
                UtilDownload.download(fileName, content);
                break;
            case SUPPORTED_FILE_TYPES.PDF:
                UtilDownload.downloadPdf(fileName, content);
                break;
            default:
                UtilDownload.downloadXls(fileName, content);
                break;
        }

        yield Put(SelectorsActionGroups.exportListSuccess());
    }
}

function* changeGroupStatusPreview(props) {
    const { groupIdList, groupNameList, groupAction } = props;
    const params = {
        groupIdList,
        groupNameList,
    };
    let response = null;
    if (groupAction === ADMINISTRATION_USER_ACTIONS.BLOCK || groupAction === ADMINISTRATION_USER_ACTIONS.UNBLOCK) {
        response = yield Call(SelectorsMiddlewareGroups.changeGroupsStatusPreview, {
            ...params,
            blocked: groupAction === ADMINISTRATION_USER_ACTIONS.BLOCK,
        });
    } else if (groupAction === ADMINISTRATION_USER_ACTIONS.DELETE) {
        response = yield Call(SelectorsMiddlewareGroups.deleteGroupsPreview, params);
    }
    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Put(SelectorsActionGroups.changeGroupStatusPreviewError());
        const errorMessage = response.data.data.NO_FIELD || UtilI18n.get(GLOBAL_UNEXPECTED_ERROR_KEY);

        yield Put(
            SelectorsActionNotification.showNotification({
                message: errorMessage,
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADMINISTRATION],
            }),
        );
    } else {
        const responseCredentials = yield Call(SelectorsMiddlewareForm.listCredentialsGroups, {
            idForm: null,
            idActivityToRead: "administration.groups.blockunblock.send",
        });
        const credentialGroups = responseCredentials.data.data.groups;

        yield Put(
            SelectorsActionGroups.changeGroupStatusPreviewSuccess({
                credentialGroups,
                groupList: groupIdList,
                groupNameList,
                groupAction,
            }),
        );
    }
}

function* changeGroupStatusConfirmation(props) {
    const { groupIdList, groupNameList, groupAction, credentials } = props;
    const params = {
        groupIdList,
        groupNameList,
    };
    let response = null;
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    if (groupAction === ADMINISTRATION_USER_ACTIONS.BLOCK || groupAction === ADMINISTRATION_USER_ACTIONS.UNBLOCK) {
        response = yield Call(SelectorsMiddlewareGroups.changeGroupsStatusConfirmation, {
            ...params,
            blocked: groupAction === ADMINISTRATION_USER_ACTIONS.BLOCK,
            credentials: credentialsWithUnderscore,
        });
    } else if (groupAction === ADMINISTRATION_USER_ACTIONS.DELETE) {
        response = yield Call(SelectorsMiddlewareGroups.deleteGroupsConfirmation, {
            ...params,
            credentials: credentialsWithUnderscore,
        });
    }

    yield Put(SelectorsActionGroups.hideModal());
    if (response && response.type === RESPONSE_TYPE.WARNING) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.data.NO_FIELD,
                level: LEVEL.ERROR,
                scopes: [SCOPE.ADMINISTRATION],
            }),
        );
    } else if (response.data.code && response.data.code === INSUFFICIENT_SIGNATURES) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.message,
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.ADMINISTRATION],
            }),
        );
    } else {
        yield Put(SelectorsActionGroups.loadListRequest());

        let successMessageKey = `administration.groups.${groupAction}.success`;
        if (groupIdList.length > 1) {
            successMessageKey = `${successMessageKey}.plural`;
        }
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilI18n.get(successMessageKey),
                level: LEVEL.SUCCESS,
                scopes: [SCOPE.ADMINISTRATION],
            }),
        );
    }
}
