import React from "react";

import classnames from "classnames";
import { Form, Field, withFormik as WithFormik } from "formik";
import { connect as Connect } from "react-redux";

import { CDP_CURRENCY, EMPTY_STR } from "~/constants";
import { SelectorsAction as SelectorsActionPaymentOrders } from "~/store/factoring";
import { SelectorsStore as StoreI18n } from "~/store/i18n";
import * as I18nUtil from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import HighOrder from "~/components/HighOrder";
import DateField from "~/pages/_components/fields/DateField";
import Select from "~/pages/_components/fields/Select";
import TextField from "~/pages/_components/fields/TextField";

import Style from "./_Filters.rules.scss";

export const { NAME, CLASS } = Style;
const FORM_ID = "list.paymentOrders.filters";

export const PROP = {
    types: {},
    defaults: {},
};

export function Component(props) {
    const {
        i18n,
        dispatch,
        setValues,
        submitForm,
        values: { issueDateFrom, issueDateTo, dueDateFrom, dueDateTo, supplierName },
    } = props;
    const [moreFilters, setMoreFilters] = React.useState(false);
    const [clean, setClean] = React.useState(false);

    React.useEffect(() => {
        if (clean) {
            submitForm();
            setClean(false);
        }
    }, [clean, submitForm]);

    const { allOrders, pendingOrders, descOrderders, finOrders, UYU, USD } = i18n;

    const options = [
        { value: EMPTY_STR, label: allOrders },
        { value: "PEN", label: pendingOrders },
        { value: "DES", label: descOrderders },
        { value: "FIN", label: finOrders },
    ];

    const currencyOptions = [
        { value: "9999", label: EMPTY_STR },
        { value: CDP_CURRENCY.USD, label: USD },
        { value: CDP_CURRENCY.UYU, label: UYU },
    ];

    const downloadFile = () => {
        dispatch(SelectorsActionPaymentOrders.exportPaymentOrdersRequest());
    };

    const handleFormCleanup = () => {
        setValues(
            {
                paymentOrder: EMPTY_STR,
                dueDateFrom: null,
                dueDateTo: null,
                issueDateFrom: null,
                issueDateTo: null,
                supplierName: EMPTY_STR,
                status: "PEN",
                currency: "9999",
            },
            false,
        );
        setClean(true);
    };

    const handleMoreFilters = () => {
        if (moreFilters && (issueDateFrom || issueDateTo || dueDateFrom || dueDateTo || supplierName)) {
            setValues(
                {
                    ...props.values,
                    dueDateFrom: null,
                    dueDateTo: null,
                    issueDateFrom: null,
                    issueDateTo: null,
                    supplierName: EMPTY_STR,
                },
                false,
            );
            setClean(true);
        }
        setMoreFilters(!moreFilters);
    };

    const handleChangeDueDateFrom = (dateFrom) => {
        const { setFieldValue } = props;
        if (dateFrom && dueDateTo && dateFrom > dueDateTo) {
            setFieldValue("dueDateTo", null);
        }
    };

    return (
        <Form className={CLASS}>
            <div data-name="basicFilter">
                <Field
                    autoFocus={false}
                    component={TextField}
                    idForm={FORM_ID}
                    hidelabel
                    name="paymentOrder"
                    type="text"
                />
                <Select
                    clearable={false}
                    labelKey="label"
                    options={currencyOptions}
                    valueKey="value"
                    name="currency"
                    label={I18nUtil.get("transfers.currency")}
                />
                <Select clearable={false} labelKey="label" options={options} valueKey="value" name="status" />
                <Box flex>
                    <Button type="submit" variant="primary" size="sm">
                        {I18nUtil.get("product.filters.filter")}
                    </Button>
                    <Button variant="secondary" onClick={handleFormCleanup} size="sm">
                        {I18nUtil.get("filters.clean")}
                    </Button>
                </Box>
            </div>
            <div data-name="moreFilters" className={classnames({ "is-open": moreFilters })}>
                <Field
                    autoFocus={false}
                    component={TextField}
                    idForm={FORM_ID}
                    hidelabel
                    name="supplierName"
                    type="text"
                />
                <Field
                    component={DateField}
                    hidelabel
                    idForm={FORM_ID}
                    name="issueDateFrom"
                    selectsStart
                    showMonthYearDropdown
                    maxDate={null}
                    selectedDate={issueDateFrom}
                />
                <Field
                    component={DateField}
                    hideLabel
                    idForm={FORM_ID}
                    name="issueDateTo"
                    selectsStart
                    minDate={issueDateFrom}
                    maxDate={null}
                    showMonthYearDropdown
                    selectedDate={issueDateTo}
                />
                <Field
                    component={DateField}
                    hidelabel
                    idForm={FORM_ID}
                    name="dueDateFrom"
                    showMonthYearDropdown
                    selectedDate={dueDateFrom}
                    maxDate={null}
                    handleChange={handleChangeDueDateFrom}
                />
                <Field
                    component={DateField}
                    hideLabel
                    idForm={FORM_ID}
                    name="dueDateTo"
                    selectsStart
                    showMonthYearDropdown
                    selectedDate={dueDateTo}
                    minDate={dueDateFrom}
                    maxDate={null}
                />
            </div>
            <Button
                className={classnames({ "is-open": moreFilters }, "btnMoreFilters")}
                variant="link"
                onClick={handleMoreFilters}>
                {I18nUtil.get(
                    !moreFilters
                        ? "list.paymentOrders.filters.button.moreFilters"
                        : "list.paymentOrders.filters.button.lessFilters",
                )}
            </Button>

            <Button id="export" variant="link" onClick={downloadFile}>
                {I18nUtil.get("client.factoring.paymentOrders.export.label")}
            </Button>
        </Form>
    );
}

const mapStateToProps = (store) => ({
    i18n: {
        allOrders: StoreI18n.getMessage(store, "client.factoring.paymentOrders.list.filter.status.all"),
        pendingOrders: StoreI18n.getMessage(store, "client.factoring.paymentOrders.list.filter.status.PEN"),
        descOrderders: StoreI18n.getMessage(store, "client.factoring.paymentOrders.list.filter.status.DES"),
        finOrders: StoreI18n.getMessage(store, "client.factoring.paymentOrders.list.filter.status.FIN"),
        USD: StoreI18n.getMessage(store, "currency.label.2222"),
        UYU: StoreI18n.getMessage(store, "currency.label.0"),
    },
});

export default HighOrder(
    Connect(mapStateToProps),
    WithFormik({
        mapPropsToValues: () => ({
            paymentOrder: null,
            status: "PEN",
            currency: "9999",
        }),
        handleSubmit: ({ ...filters }, formikBag) => {
            const { dispatch } = formikBag.props;

            dispatch(
                SelectorsActionPaymentOrders.loadListPaymentOrdersRequest({
                    filters,
                    moreOrders: false,
                }),
            );
        },
    }),
)(Component);
