import { MODE } from "~/constants/form";
import { TYPE as TypesBackoffice } from "~/store/backoffice";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    const { transaction, data, idPaper } = action;

    switch (action.type) {
        case TYPE.PRE_FORM_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
                mode: MODE.EDIT,
            };
        case TYPE.PRE_FORM_SUCCESS:
            return {
                ...storeProp,
                creditAccounts: data.creditAccountList,
                funds: data.funds,
                isFetching: false,
                paper: data.paper,
                processingSale: data.processingSale,
            };
        case TYPE.PREVIEW_FORM_REQUEST:
        case TYPE.READ_TRANSACTION_REQUEST:
        case TYPE.READ_PAPER_INFO:
        case TYPE.GET_PAPER:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.PREVIEW_FORM_SUCCESS: {
            const { detail } = data;

            return {
                ...storeProp,
                amount: detail.amount,
                creditAccountAlias: detail.creditAccountAlias,
                currency: detail.currency,
                disclaimer: detail.disclaimer,
                idCreditAccount: detail.idCreditAccount,
                idPaper: detail.idPaper,
                isFetching: false,
                limitDate: detail.limitDate,
                mode: MODE.PREVIEW,
                orderCompleted: detail.orderCompleted,
                paperName: detail.paperName,
                prevMode: storeProp.mode,
                share: detail.shares,
            };
        }
        case TYPE.PREVIEW_CANCEL_REQUEST: {
            return {
                ...storeProp,
                isFetching: false,
                mode: MODE.EDIT,
            };
        }
        case TYPE.SEND_FORM_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
                isSubmitting: true,
            };
        case TYPE.SEND_FORM_SUCCESS:
            return {
                ...storeProp,
                isSubmitting: false,
                isFetching: false,
                prevMode: storeProp.mode,
                transaction,
                mode: MODE.VIEW,
            };
        case TYPE.CLOSE_TRANSACTION_PREVIEW:
            return {
                ...storeProp,
                fetching: false,
                mode: storeProp.prevMode,
            };
        case TYPE.READ_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                transaction,
            };
        case TYPE.SIGN_TRANSACTION_PREVIEW_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.SIGN_TRANSACTION_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                transaction,
                fetching: false,
                prevMode: storeProp.mode,
                mode: MODE.PREVIEW,
            };
        case TYPE.TASK_FINISHED:
            return {
                ...storeProp,
                isFetching: false,
            };
        case TYPE.READ_PAPER_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                paper: data.paper,
                timezoneOffset: data.timezoneOffset,
            };
        case TYPE.SELECTED_ID_PAPER: {
            return {
                ...storeProp,
                idPaper,
            };
        }
        case TYPE.RESET_FORM: {
            return {
                ...storeProp,
                ...PROP.defaults,
            };
        }
        case TYPE.RESTART_FLOW:
            return PROP.defaults;
        case TypesBackoffice.BACKOFFICE_TRANSACTION_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                mode: MODE.VIEW,
                transaction,
            };
        default:
            return {
                ...storeProp,
            };
    }
};
