import React from "react";

import PropTypes from "prop-types";

import { EMPTY_STR } from "~/constants";
import { Categorize } from "~/util/prop";

import Image from "~/components/Image";
import Notification from "~/pages/_components/Notification";

import Header from "../_Header";
import Main from "../_Main";
import Footer from "./_Footer";
import Namespace from "./_index.scss";

export const { NAME, TYPE } = Namespace;
export const PROP = {
    types: {
        "head-onBack": PropTypes.func,
        "head-onClose": PropTypes.func,
        children: PropTypes.node.isRequired,
        id: PropTypes.string,
        name: PropTypes.string.isRequired,
        scopeToShowNotification: PropTypes.string,
        title: PropTypes.string,
        wait: PropTypes.bool,
    },
    defaults: {
        "head-onBack": null,
        "head-onClose": null,
        children: undefined,
        id: null,
        name: undefined,
        scopeToShowNotification: null,
        title: EMPTY_STR,
        wait: false,
    },
};

export function Component(props) {
    const { children, head = {}, icon, isPayment, scopeToShowNotification, wait, ...rest } = Categorize(props);
    const { hideHeaderAndFooter, id, onBack, onClose, title } = head;

    const clsBack = (!onBack && "hidden") || undefined;
    const clsClose = (!onClose && "hidden") || undefined;

    return (
        <Main {...rest} wait={wait}>
            {scopeToShowNotification && <Notification scopeToShow={scopeToShowNotification} />}
            <Header id={id}>
                <Header.Button className={clsBack} onClick={onBack} image="left-arrow.svg" />
                <Header.Logo hideHeaderAndFooter={hideHeaderAndFooter} />
                {title && <Header.Title value={title} icon={!isPayment && (icon || <Image src="information.svg" />)} />}
                <Header.Button className={clsClose} onClick={onClose} image="cross.svg" />
            </Header>
            {children}
            {!hideHeaderAndFooter && <Footer />}
        </Main>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

export default Component;
