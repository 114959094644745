import React from "react";

import { connect as Connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { compose as Compose } from "redux";

import { SelectorsStore as ChangeMyPhoneSelectors } from "~/store/changeMyPhone";
import { SelectorsStore as EnrollmentSelectors } from "~/store/enrollment";
import { SelectorsStore as LoginSelectors } from "~/store/login";
import { SelectorsStore as SelectorsStoreRecoveryPassword } from "~/store/recoverypassword";
import { SelectorsStore as RecoveryPasswordNoTokenSelectors } from "~/store/recoverypassword/noToken";

export const NAME = "withExchangeToken";

export const PROP = {
    types: {},
    defaults: {},
};
export function Component(BaseComponent) {
    return ({ ...prop }) => {
        const {
            match: { path },
            exchangeTokens,
        } = prop;

        // Verificar: Se comento para evitar una redireccion que ya es validada en otro paso
        //  TODO: esta condicion genera una doble redireccion al login cuando se cancela el login step 2, investigar porque.
        if (path.indexOf("loginStep") !== -1 && !exchangeTokens.loginExchangeToken) {
            return <Redirect to="/" />;
        }

        if (path.indexOf("recoveryPassword/step") !== -1 && !exchangeTokens.recoveryPasswordExchangeToken) {
            return <Redirect to="/recoveryPassword/step1" />;
        }

        if (path.indexOf("enrollment/") !== -1 && !exchangeTokens.enrollmentExchangeToken) {
            return <Redirect to="/" />;
        }

        if (path.indexOf("changeMyPhone/") !== -1 && !exchangeTokens.changeMyPhoneExchangeToken) {
            return <Redirect to="/" />;
        }

        if (path.indexOf("recoveryPassNoToken/step") !== -1 && !exchangeTokens.recoveryPasswordNoTokenExchangeToken) {
            return <Redirect to="/recoveryPassNoToken" />;
        }

        return <BaseComponent {...prop} />;
    };
}

Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;
Component.displayName = NAME;

const mapStateToProps = (state) => ({
    exchangeTokens: {
        loginExchangeToken: LoginSelectors.getExchangeToken(state),
        recoveryPasswordExchangeToken: SelectorsStoreRecoveryPassword.getExchangeToken(state),
        enrollmentExchangeToken: EnrollmentSelectors.exchangeToken(state),
        changeMyPhoneExchangeToken: ChangeMyPhoneSelectors.exchangeToken(state),
        recoveryPasswordNoTokenExchangeToken: RecoveryPasswordNoTokenSelectors.getExchangeToken(state),
    },
});

export default Compose(Connect(mapStateToProps), Component);
