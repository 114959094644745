import { LOCATION_CHANGE } from "connected-react-router";

import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return {
                ...storeProp,
                previousLocation: storeProp.currentLocation,
                currentLocation: action.payload.location.pathname,
            };
        case TYPE.LOAD_LIST_REQUEST:
        case TYPE.LOAD_MORE_TRANSACTIONS_REQUEST:
        case TYPE.APPROVE_TRANSACTIONS_PREVIEW_REQUEST:
        case TYPE.CANCEL_TRANSACTIONS_PREVIEW_REQUEST:
        case TYPE.APPROVE_TRANSACTIONS_REQUEST:
        case TYPE.CANCEL_TRANSACTIONS_REQUEST:
        case TYPE.LIST_ID_ACTIVITIES_EXECUTED_REQUEST:
        case TYPE.SHARE_TICKET_REQUEST:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.LOAD_LIST_FAILURE:
        case TYPE.APPROVE_TRANSACTIONS_PREVIEW_FAILURE:
        case TYPE.LIST_ID_ACTIVITIES_EXECUTED_FAILURE:
        case TYPE.CANCEL_TRANSACTIONS_PREVIEW_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
            };
        case TYPE.LOAD_LIST_SUCCESS:
            return {
                ...storeProp,
                transactions: action.transactions,
                hasMoreData: action.pageNumber > 0 && action.pageNumber < action.totalPages,
                isFetching: false,
                pageNumber: action.pageNumber,
                delayedTransactions: action.delayedTransactions,
                isFilterChanged: action.changeFilterToAll,
            };
        case TYPE.SET_FILTERS:
            return {
                ...storeProp,
                filters: action.filters,
            };

        case TYPE.LOAD_MORE_TRANSACTIONS_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                transactions: storeProp.transactions
                    ? storeProp.transactions.concat(action.transactions)
                    : action.transactions,
                hasMoreData: action.pageNumber < action.totalPages,
                pageNumber: action.pageNumber,
                delayedTransactions: action.delayedTransactions,
            };
        case TYPE.DELETE_DRAFT_REQUEST:
            return {
                ...storeProp,
                isDeletingDraft: true,
            };
        case TYPE.DELETE_DRAFT_FAILURE:
            return {
                ...storeProp,
                isDeletingDraft: false,
            };
        case TYPE.DELETE_DRAFT_SUCCESS:
            return {
                ...storeProp,
                isDeletingDraft: false,
                transactions: storeProp.transactions.filter(
                    (item) => item.transaction.idTransaction !== action.idTransaction,
                ),
            };
        case TYPE.APPROVE_TRANSACTIONS_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                idTransactionList: action.idTransactionList,
                totalTransactions: action.totalTransactions,
                credentialsGroups: action.credentialsGroups,
                isApproving: true,
                isFetching: false,
            };
        case TYPE.CANCEL_TRANSACTIONS_PREVIEW_SUCCESS:
            return {
                ...storeProp,
                idTransactionList: action.idTransactionList,
                totalTransactions: action.totalTransactions,
                credentialsGroups: action.credentialsGroups,
                isApproving: false,
                isFetching: false,
            };
        case TYPE.LIST_ID_ACTIVITIES_EXECUTED_SUCCESS:
            return {
                ...storeProp,
                idActivitiesList: action.idActivitiesList,
                isFetching: false,
            };
        case TYPE.APPROVE_TRANSACTIONS_FAILURE:
        case TYPE.CANCEL_TRANSACTIONS_FAILURE:
        case TYPE.SHARE_TICKET_FAILURE:
            return {
                ...storeProp,
                isFetching: false,
                errors: action.errors,
            };
        case TYPE.APPROVE_TRANSACTIONS_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                errors: null,
                totalTransactions: 0,
                idTransactionList: [],
                isApproving: true,
            };
        case TYPE.CANCEL_TRANSACTIONS_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                errors: null,
                totalTransactions: 0,
                idTransactionList: [],
                isApproving: null,
            };

        case TYPE.CLEAR_PREVIEW:
            return {
                ...storeProp,
                totalTransactions: 0,
                idTransactionList: [],
                fetching: false,
            };
        case TYPE.SET_APPROVING:
            return {
                ...storeProp,
                isApproving: action.isApproving,
            };

        case TYPE.RESUME_TRANSACTIONS:
            return {
                ...storeProp,
                resumeTransaction: action.summaryDetail,
            };
        case TYPE.SHARE_TICKET_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
                errors: null,
                file: action.file,
            };
        case TYPE.DELAYED_START:
        case TYPE.DELAYED_STOP:
            return {
                ...storeProp,
                isDelayed: action.isDelayed,
            };
        case TYPE.RESET_FILTER:
            return {
                ...storeProp,
                filters: null,
                transaction: null,
                ticketSelected: false,
            };
        case TYPE.CLEAR_TRANSACTION_LIST:
            return {
                ...storeProp,
                transactions: null,
                filters: null,
                previousLocation: null,
            };
        case TYPE.LOADING:
            return {
                ...storeProp,
                ticketSelected: true,
            };
        case TYPE.CHECK_IF_THERE_ARE_QRMODO_RUNNING_TRANSACTIONS:
            return {
                ...storeProp,
                isInQRMODOPage: true,
            };
        case TYPE.LEAVE_QR_MODO_PAYMENTS_PAGE:
            return {
                ...storeProp,
                isInQRMODOPage: false,
            };
        case TYPE.GPI_TRACKING:
            return {
                ...storeProp,
                isFetching: true,
            };
        case TYPE.GPI_TRACKING_FAILURE:
        case TYPE.GPI_TRACKING_SUCCESS:
            return {
                ...storeProp,
                isFetching: false,
            };
        default:
            return { ...storeProp };
    }
};
