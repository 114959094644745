import React from "react";

import { EMPTY_STR } from "~/constants";
import * as DateUtils from "~/util/date";
import UtilFlag from "~/util/flag";
import * as I18nUtils from "~/util/i18n";

import DataDate from "~/components/DataDate";
import DataNumber from "~/components/DataNumber";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import Link from "~/components/Link";
import ListItem from "~/pages/_components/listItem/ListItem";

export const NAME = "Loan";

export const PROP = {
    types: {},
    defaults: {},
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    productType = () => {
        // TODO: refactor crear un metodo getProducType ya q esto
        // esta repetido en varios lugares
        const { productType } = this.props;
        const msgKey = productType === "PA" ? "pa" : "pi";

        return I18nUtils.get(`loans.list.item.title.${msgKey}`);
    };

    render() {
        const { loan, productAsCard, ...props } = this.props;
        const { idProduct, productAlias, number, currency, nextDueDate, productType, totalToPay } = loan;

        const isExpired = DateUtils.isDateLessThanToday(nextDueDate);
        const loanExpiredClass = isExpired ? "expired" : EMPTY_STR;
        const productName = productAlias || number;
        const expiredText = I18nUtils.get("loans.list.item.expired");
        const expirationText = I18nUtils.get("loans.list.item.expiration");

        const reference = `${I18nUtils.get("global.number")} ${number}`;
        const loanCard = (
            <Link
                to={{ pathname: `/loans/${idProduct}` }}
                key={`loan-${idProduct}`}
                className={`border-gray ${loanExpiredClass}`}>
                <h4>
                    <span>{productAlias || number}</span>
                </h4>
                <ul>
                    <li className="label">
                        <I18n id={`loans.productType.${productType}`} />
                        <span>{number}</span>
                    </li>
                    {nextDueDate && (
                        <li className="duedate">
                            <I18n id="loans.list.item.expiration" />
                            <DataDate value={nextDueDate} />
                        </li>
                    )}
                    <li className="granted">
                        <I18n id="loans.nextDue" />
                        <div className="amount">
                            <Image src={`${UtilFlag.getFlag(`${currency}`)}.svg`} />
                            <DataNumber prefix={currency} value={totalToPay} />
                        </div>
                    </li>
                </ul>
            </Link>
        );

        const item = (
            <ListItem
                {...props}
                title={I18nUtils.get(`productType.${productType}`)}
                name={productName}
                reference={reference}
                expiredText={expiredText}
                expirationText={expirationText}
                isExpired={isExpired}
                amount={totalToPay}
                amountLabel={I18nUtils.get("loans.nextDue")}
                expirationDate={nextDueDate}
                currency={currency}
                path={{ pathname: `/loans/${idProduct}` }}
            />
        );

        return (productAsCard && loanCard) || item;
    }
}
export default Component;
