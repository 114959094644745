import { push as Push } from "connected-react-router";

import { Sagas as SagasChannels } from "~/store/administration/common/channels";
import { Sagas as SagasDetails } from "~/store/administration/common/details";
import { Sagas as SagasPermissions } from "~/store/administration/common/permissions";
import { Sagas as SagasSignatures } from "~/store/administration/common/signatures";

import {
    TYPE_ADMINISTRATION_LEVEL_ADVANCED_CHANNELS,
    TYPE_ADMINISTRATION_LEVEL_ADVANCED_DETAILS,
    TYPE_ADMINISTRATION_LEVEL_ADVANCED_PERMISSION,
    TYPE_ADMINISTRATION_LEVEL_ADVANCED_SIGNATURE,
} from "./_consts";
import {
    SelectorsMiddleware,
    SelectorsActionDetailsAdvanced,
    SelectorsActionPermissionsAdvanced,
    SelectorsActionChannelsAdvanced,
    SelectorsActionSignaturesAdvanced,
} from "./_selectors";
import { Sagas as SagasGroup } from "./group";

export default [
    ...SagasPermissions(SelectorsMiddleware, TYPE_ADMINISTRATION_LEVEL_ADVANCED_PERMISSION, {
        ...SelectorsActionPermissionsAdvanced,
        onSuccess: (id) => Push(`/administration/simple/permissions/${id}/confirm`),
    }),
    ...SagasChannels(SelectorsMiddleware, TYPE_ADMINISTRATION_LEVEL_ADVANCED_CHANNELS, {
        ...SelectorsActionChannelsAdvanced,
        onSuccess: (id) => Push(`/administration/advanced/channels/${id}/confirm`),
    }),
    ...SagasDetails(SelectorsMiddleware, TYPE_ADMINISTRATION_LEVEL_ADVANCED_DETAILS, {
        ...SelectorsActionDetailsAdvanced,
        ...SelectorsActionPermissionsAdvanced,
        ...SelectorsActionChannelsAdvanced,
        ...SelectorsActionSignaturesAdvanced,
    }),
    ...SagasSignatures(SelectorsMiddleware, TYPE_ADMINISTRATION_LEVEL_ADVANCED_SIGNATURE, {
        ...SelectorsActionSignaturesAdvanced,
        ...SelectorsActionDetailsAdvanced,
    }),
    ...SagasGroup,
];
