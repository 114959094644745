import { LOCATION_CHANGE } from "connected-react-router";

import { HSBC } from "~/constants";
import { ID_FORM, MODE } from "~/constants/form";
import { TYPE as TYPE_FILES } from "~/store/files";
import { TYPE as TYPE_FORM } from "~/store/form";
import { TYPE as TYPE_MULTILINEFILE } from "~/store/multilinefile";
import { TYPE as TYPE_TEMPLATE } from "~/store/template";
import {
    shouldKeepStateInUrlScope as ShouldKeepStateInUrlScope,
    checkIdFormInRoute as CheckIdFormInRoute,
    isFromPaymentTransactionLines as IsFromPaymentTransactionLines,
} from "~/util/form";

import { TYPE, PROP } from "./_consts";

const isInPayroll = (payrollLines, paymentLine) => {
    if (paymentLine.creditAccountBank !== HSBC) {
        return paymentLine;
    }

    return payrollLines.find(
        (line) =>
            line.creditAccountBank === paymentLine.creditAccountBank &&
            line.creditAccountNumber === paymentLine.creditAccountNumber,
    );
};

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            const shouldKeepState =
                ShouldKeepStateInUrlScope(action.payload.location.pathname) &&
                CheckIdFormInRoute(action.payload.location.pathname, storeProp.idForm);
            const isFromPayment = IsFromPaymentTransactionLines(action.payload.location.pathname, storeProp.idForm);
            const isFromBackoffice = CheckIdFormInRoute(action.payload.location.pathname, "backoffice");

            // This fix is introduced because the selected currency was lost when switching to payment lines' page,
            // UYU was always set by default.
            const locationChangeProps = {
                ...PROP.defaults,
                currency: storeProp.currency,
            };

            return shouldKeepState || isFromPayment || isFromBackoffice ? storeProp : locationChangeProps;
        }
        case TYPE.ADD_TRANSACTION_LINE: {
            const linesIndex = storeProp.linesIndex + 1;
            const { payrollLines } = storeProp;
            const { paymentLine } = action;
            const inPayroll = isInPayroll(payrollLines, paymentLine);

            if (inPayroll) {
                paymentLine.creditAccountName = inPayroll.creditAccountName;
                paymentLine.payroll = 1;
            }

            const indexedList = [...storeProp.list, { ...paymentLine }];

            return {
                ...storeProp,
                list: indexedList.map((payment, index) => ({ ...payment, lineNumber: index + 1 })),
                linesIndex,
                idForm: action.idForm,
            };
        }
        case TYPE.CANCEL_CHANGED_TRANSACTION_LINES:
            return {
                ...storeProp,
                activeFilters: action.filterData,
            };
        case TYPE.CHANGE_FILTERS:
            return {
                ...storeProp,
                shouldLoadDraft: false,
                shouldResetLines: true,
            };
        case TYPE.CLEAR_TRANSACTION_LINES:
            return {
                ...storeProp,
                list: [],
                fetching: false,
            };
        case TYPE.EDIT_TRANSACTION_LINE: {
            const { payrollLines } = storeProp;
            const { paymentLine } = action;
            const inPayroll = isInPayroll(payrollLines, paymentLine);

            if (inPayroll) {
                paymentLine.creditAccountName = inPayroll.creditAccountName;
                paymentLine.payroll = 1;
            }

            let errors;

            if (storeProp.errors) {
                const { [`line@${action.paymentLine.lineNumber}`]: omit, ...restErrors } = storeProp.errors;
                errors = restErrors;
            }

            return {
                ...storeProp,
                list: storeProp.list.map((line) => {
                    let mappedLine = line;
                    if (action.paymentLine.lineNumber === line.lineNumber) {
                        mappedLine = paymentLine;
                    }

                    return mappedLine;
                }),
                idForm: action.idForm,
                errors,
            };
        }
        case TYPE.LIST_LOCAL_BANKS_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.LIST_LOCAL_BANKS_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.LIST_LOCAL_BANKS_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                localBanks: action.banks,
            };
        case TYPE.LIST_TRANSACTION_LINES_REQUEST:
            if (!action.pageNumber) {
                return PROP.defaults;
            }
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.LIST_TRANSACTION_LINES_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.LIST_TRANSACTION_LINES_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                pageNumber: storeProp.pageNumber + 1,
                totalLines: action.totalLines,
                lastPage: action.isLastPage,
                list: [...storeProp.list, ...action.list],
            };
        case TYPE.LIST_TRANSACTION_LINES_STATUS_SUCCESS: {
            const linesWithStatus = storeProp.list.map((line) => ({
                ...line,
                status: action.transactionLinesStatus?.find((status) => status.lineNumber === line.lineNumber)?.status,
            }));

            return {
                ...storeProp,
                list: linesWithStatus,
                linesWithStatus: action.transactionLinesStatus && action.transactionLinesStatus.length,
            };
        }
        case TYPE.ON_PAYMENT_METHOD_CHANGE:
            return { ...storeProp, list: [], errors: [] };
        case TYPE.REMOVE_TRANSACTION_LINE: {
            if (storeProp.errors) {
                const { errors } = storeProp;

                delete errors[`line@${action.lineNumber}`];
            }

            return {
                ...storeProp,
                list: storeProp.list
                    .filter((payment) => payment.lineNumber !== action.lineNumber)
                    .map((payment, index) => ({ ...payment, lineNumber: index + 1 })),
            };
        }
        case TYPE.SORT_LINES_BY_NAME: {
            return {
                ...storeProp,
                list: storeProp.list
                    .sort(
                        action.order
                            ? (a, b) => a.creditAccountName.localeCompare(b.creditAccountName)
                            : (a, b) => b.creditAccountName.localeCompare(a.creditAccountName),
                    )
                    .map((payment, index) => ({ ...payment, lineNumber: index + 1 })),
            };
        }
        case TYPE.RESET_EDITED_LINES:
            return {
                ...storeProp,
                linesBeingEdited: 0,
            };
        case TYPE.SET_CURRENCY: {
            const lines = storeProp.list
                ? storeProp.list.map((line) => ({
                      ...line,
                      creditAmountCurrency: action.currency,
                  }))
                : [];

            return {
                ...storeProp,
                currency: action.currency,
                list: lines,
            };
        }
        case TYPE.SET_ERRORS: {
            const data = action.transactionLines ? action.transactionLines : action;
            const { type: omit, ...errors } = data;

            return {
                ...storeProp,
                errors,
            };
        }
        case TYPE.SET_IS_EDITING_PAYMENT:
            if (action.lineNumber !== undefined) {
                return {
                    ...storeProp,
                    isEditingPayment: action.isEditingPayment,
                    selectedLine: action.lineNumber,
                };
            }

            return {
                ...storeProp,
                isEditingPayment: action.isEditingPayment,
            };
        case TYPE.SET_LINES_FROM_TRANSACTION_DATA:
            return {
                ...storeProp,
                list: [...action.linesFromTransactionData],
            };
        case TYPE.SET_PAGE_NUMBER:
            return {
                ...storeProp,
                pageNumber: action.pageNumber,
            };
        case TYPE_FILES.GET_FILE_CONTENTS_REQUEST:
        case TYPE_FILES.GET_FILE_TRANSACTION_LINES_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE_FILES.GET_FILE_CONTENTS_FAILURE:
        case TYPE_FILES.GET_FILE_TRANSACTION_LINES_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE_FILES.GET_FILE_CONTENTS_SUCCESS:
            return { ...storeProp, list: action.payload, fetching: false };
        case TYPE_FILES.GET_FILE_TRANSACTION_LINES_SUCCESS: {
            const lines =
                action.lines.map((line) => ({
                    ...line,
                    creditAccountBankShortLabel: storeProp.localBanks.find(
                        (bank) => bank.value === line.creditAccountBank,
                    )?.shortLabel,
                    creditAccountName:
                        isInPayroll(storeProp.payrollLines, line)?.creditAccountName ||
                        line.creditAccountName ||
                        storeProp.creditAccountNameMask,
                    fromFile: 1,
                })) || [];

            const linesIndex = Math.max(...lines.map((item) => item.lineNumber));

            return {
                ...storeProp,
                list: lines,
                linesIndex,
                fetching: false,
            };
        }
        case TYPE_TEMPLATE.SET_SELECTED_TEMPLATE:
        case TYPE_FORM.READ_TRANSACTION_SUCCESS: {
            const data = action.transaction ? action.transaction.data : action;
            const { loadingMethod, manualInput } = data;

            let list = [];
            if (loadingMethod && loadingMethod[0] === "manual" && manualInput.length > 0) {
                list = manualInput;
            }

            let { currency, linesIndex } = PROP.defaults;
            if (list.length > 0) {
                const { payrollLines } = storeProp;

                currency = list[0].creditAmountCurrency;
                linesIndex = Math.max(...list.map((item) => item.lineNumber));

                if (payrollLines.length > 0) {
                    list = list.map((item) => ({
                        ...item,
                        payroll:
                            payrollLines.find(
                                (line) =>
                                    line.creditAccountBank === item.creditAccountBank &&
                                    line.creditAccountNumber === item.creditAccountNumber,
                            ) && 1,
                        creditAccountName:
                            isInPayroll(payrollLines, item)?.creditAccountName || storeProp.creditAccountNameMask,
                    }));
                }
            }

            return {
                ...storeProp,
                errors: null,
                currency,
                lineNumber: linesIndex,
                linesIndex,
                list,
                idForm: action.idForm,
            };
        }
        case TYPE_MULTILINEFILE.ON_FILE_PROCESS: {
            const listWithBanksName = action.data.lines.map((line) => ({
                ...line,
                fromFile: 1,
                creditAccountBankShortLabel: storeProp.localBanks.find((bank) => bank.value === line.creditAccountBank)
                    ?.shortLabel,
            }));

            return {
                ...storeProp,
                currency: action.data.totalAmount.currency,
                list: listWithBanksName,
                idForm: action.data.idForm,
            };
        }
        case TYPE_MULTILINEFILE.ON_FILE_REMOVED:
            if (storeProp.mode === MODE.VIEW) {
                return storeProp;
            }

            return { ...storeProp, list: [], errors: null };
        case TYPE_FORM.SEND_FORM_SUCCESS:
            return {
                ...storeProp,
                mode: MODE.VIEW,
            };
        case TYPE.SEND_FORM_DATA_FAILURE:
            return {
                ...storeProp,
                mode: MODE.EDIT,
            };
        case TYPE.CLEAN_LINES_WITH_NO_AMOUNT: {
            const linesWithoutAmount = storeProp.list.filter((line) => !line.creditAmountQuantity);
            const linesWithAmount = storeProp.list.filter((line) => line.creditAmountQuantity);
            const linesIndex = Math.max(...linesWithAmount.map((item) => item.lineNumber));

            if (storeProp.errors) {
                const { errors } = storeProp;
                linesWithoutAmount.filter((line) => delete errors[`line@${line.lineNumber}`]);
            }

            return {
                ...storeProp,
                list: linesWithAmount,
                linesIndex,
            };
        }
        case TYPE_FORM.CLEAN_STEPS:
        case TYPE.CLEAN_ALL:
            return PROP.defaults;
        case TYPE.IMPORT_PAYROLL_CONFIRMATION_REQUEST:
            return {
                ...storeProp,
                confirmationRequest: action.confirmationRequest,
            };
        case TYPE.IMPORT_PAYROLL_REQUEST:
            return {
                ...storeProp,
                creditAccountNameMask: action.creditAccountNameMask,
                fetching: true,
            };
        case TYPE.IMPORT_PAYROLL_FAILURE:
            return {
                ...storeProp,
                fetching: false,
            };
        case TYPE.IMPORT_PAYROLL_SUCCESS:
            return {
                ...storeProp,
                payrollLines: action.lines || [],
                idForm: ID_FORM.SALARY_PAYMENT,
                fetching: false,
            };
        case TYPE.SHOW_IMPORTED_PAYROLL: {
            const payrollLines =
                storeProp.payrollLines.map((line) => ({
                    ...line,
                    creditAccountBankShortLabel: storeProp.localBanks.find(
                        (bank) => bank.value === line.creditAccountBank,
                    )?.shortLabel,
                    creditAmountCurrency: storeProp.currency,
                    creditAmountQuantity: 0,
                })) || [];
            const payrollIndex = payrollLines.length > 0 ? Math.max(...payrollLines.map((item) => item.lineNumber)) : 0;

            return {
                ...storeProp,
                list: payrollLines,
                idForm: ID_FORM.SALARY_PAYMENT,
                linesIndex: payrollIndex,
                fetching: false,
            };
        }
        default:
            return storeProp;
    }
};
