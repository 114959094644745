import { replace as Replace, push as Push } from "connected-react-router";
import { takeLatest as TakeLatest, take as Take, call as Call, put as Put, select as Select } from "redux-saga/effects";

import {
    FINGERPRINT_AUTH_TOKEN,
    IS_CDP_USER,
    LEVEL,
    SCOPE,
    SOFT_OTP,
    RESPONSE_TYPE,
    MOBILE_LOGIN_TERMS_NOT_ACCEPTED,
    API_INVALID_USER_OR_SECOND_FACTOR,
    USER_DISABLED,
    USER_DOESNT_EXIST,
    API_INVALID_USER_OR_SECOND_FACTOR_CAPTCHA_REQUIRED,
    INVALID_CAPTCHA,
    API_INVALID_PASSWORD_CAPTCHA_REQUIRED,
    API_INVALID_PASSWORD,
    VALIDATION_ERROR,
    USER_OTP_NOT_ACTIVE,
    API_FINGER_SESSION_ENDED,
    BIOMETRIC_ID_USER,
    NOTIFICATION_DURATION,
    BIOMETRIC_TYPE,
    BIOMETRIC_TYPES,
    ERROR_MOBILE_IOS_FAILED,
    ERROR_MOBILE_IOS_128,
    ERROR_MOBILE_IOS_CANCEL,
    ERROR_MOBILE_IOS_CANCEL_BY_SYSTEM,
    ERROR_MOBILE_IOS_LOCK_OUT,
    API_INVALID_IP_RANGE,
    BIOMETRIC_FACE_ID,
    LOGIN_STEP1,
} from "~/constants";
import { TYPE as TYPES_APP } from "~/store/app";
import {
    SelectorsAction as SelectorsActionBiometric,
    SelectorsMiddleware,
    SelectorsStore as SelectorsStoreBiometric,
    TYPE as TYPE_BIOMETRIC,
    TYPE_VERIFY_BIOMETRIC,
} from "~/store/biometric";
import { SelectorsStore as SelectorsStoreConfig } from "~/store/config";
import { SelectorsAction as SelectorsActionConnectedParties } from "~/store/connectedParties";
import { SelectorsStore as SelectorsExternalPayments } from "~/store/externalPayments";
import { TYPE as TYPE_FORM } from "~/store/form";
import { SelectorsAction as SelectorsActionGeneralConditions } from "~/store/generalConditions";
import { SelectorsAction as SelectorsActionI18n } from "~/store/i18n";
import { SelectorsAction as SelectorsActionNotification } from "~/store/notification";
import { SelectorsAction as SelectorsActionRecoveryPassword } from "~/store/recoverypassword";
import {
    SelectorsMiddleware as SelectorsMiddlewareSession,
    SelectorsStore as SelectorsStoreSession,
} from "~/store/session";
import { SelectorsAction as SelectorsActionStatus } from "~/store/status";
import * as UtilsConfig from "~/util/config";
import UtilsDevice, { DEVICE_MOBILE } from "~/util/device";
import * as UtilsFacephi from "~/util/facephi";
import * as UtilsFingerprint from "~/util/fingerprint";
import { adjustIdFieldErrors } from "~/util/form";
import * as UtilsI18n from "~/util/i18n";
import * as UtilsSecureStorage from "~/util/secureStorage";
import * as UtilsVUFingerprint from "~/util/vuFingerprint";

import { TYPE } from "./_consts";
import { SelectorsStore, SelectorsAction } from "./_selectors";

const sagas = [
    TakeLatest(TYPE.LOGIN_STEP_1_REQUEST, handleLoginStep1Request),
    TakeLatest(TYPE.LOGIN_STEP_2_REQUEST, handleLoginStep2Request),
    TakeLatest(TYPE.LOGIN_STEP_3_REQUEST, handleLoginStep3Request),
    TakeLatest(TYPE.LOGIN_STEP_4_REQUEST, handleLoginStep4Request),
    TakeLatest(TYPE.FINALIZE_LOGIN, handleFinalizeLogin),

    TakeLatest(TYPE.FINGERPRINT_LOGIN_PRE, handleFingerprintLoginPre),
];

export default sagas;

function* handleFinalizeLogin({ response }) {
    const { _accessToken, isAdministrator } = response.data.data;
    if (!_accessToken) {
        const {
            generalConditionsShowExpiration,
            generalConditions,
            generalConditionsText,
            generalConditionsExpirationDate,
        } = response.data.data;
        const termsAndConditions = {
            // just joining the long named variables into a single object
            showExpiration: generalConditionsShowExpiration,
            generalConditions,
            body: generalConditionsText,
            expirationDate: generalConditionsExpirationDate,
        };

        yield Put(SelectorsAction.loginStep3Success({ termsAndConditions }));
        yield Put(Replace({ pathname: "/loginStep4", withFlow: true }));
    } else {
        const loginData = yield Call(processLoginSuccess, response);
        const { environments, lang, userId } = response.data.data;
        const { lastHref } = yield Select((state) => state.status);
        const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);

        const isFromSistarbanc = yield Select(SelectorsExternalPayments.getIsFromSistarbanc);
        const isFromBevsa = yield Select(SelectorsExternalPayments.getIsFromBevsa);
        const params = yield Select(SelectorsExternalPayments.getParams);
        const redirectSistarbanc = "/form/paySistarbanc".concat(params.queryString);
        const redirectBevsa = "/form/payBevsa".concat(params.queryString);

        // Verifica que el dispositvo sea trsusted y de que el tipo de OTP sea SOFT
        const needsBiometric = isTrusted && loginData.user.otpType.indexOf(SOFT_OTP) !== -1;
        UtilsConfig.setRecaptchaLang(lang);
        yield Put(SelectorsActionI18n.setLang({ lang }));
        yield Call(SelectorsMiddlewareSession.setAuthToken, _accessToken);
        yield Put({
            type: TYPE.LOGIN_SUCCESS,
            environment: loginData.environment,
            user: { ...loginData.user, needsBiometric, userId },
            environments,
            isAdministrator,
        });
        const { shouldLogoutAndShowWarning, suggestMigration } = response.data.data;
        const { code } = response.data;

        yield Put(
            SelectorsActionConnectedParties.setRegisteredBantotal({ regiteredBantotal: response.registeredBantotal }),
        );

        if (!response.registeredBantotal) {
            yield Put(Push("/connectedParties"));
        } else if (code === MOBILE_LOGIN_TERMS_NOT_ACCEPTED) {
            yield Put(SelectorsActionGeneralConditions.generalConditionsFromLogin({ suggestMigration }));
            yield Put(Push("/generalConditionsLogin"));
        } else if (isFromSistarbanc) {
            yield Put(Replace(redirectSistarbanc));
        } else if (isFromBevsa) {
            yield Put(Replace(redirectBevsa));
        } else if (shouldLogoutAndShowWarning) {
            yield Put(Push("/versionWarningAndLogout"));
        } else if (suggestMigration) {
            yield Put(Push("/migrationSuggest"));
        } else if (lastHref) {
            if (lastHref.pathname !== "/login") {
                yield Put(Replace(lastHref));
            }
            yield Put(SelectorsActionStatus.deleteLastHref());
        } else {
            yield Put(Replace("/desktop"));
            yield Put(SelectorsActionStatus.resetComeFromLogin());
        }
    }
}

function* handleLoginStep1Request(props) {
    const { username, recaptchaResponse, formikBag, secondFactor } = props;

    const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);
    if (isTrusted) {
        yield Put(SelectorsActionBiometric.verifyBiometric({ liveness: false, pathFrom: LOGIN_STEP1 }));
        yield Take([
            TYPE_BIOMETRIC.VERIFY_BIOMETRIC_ERROR,
            TYPE_BIOMETRIC.VERIFY_BIOMETRIC_SUCCESS,
            TYPE_BIOMETRIC.VERIFY_BIOMETRIC_CANCEL,
            TYPE_BIOMETRIC.NO_ENROLLED_FINGERPRINT,
        ]);
        const statusVerifyBiometric = yield Select(SelectorsStoreBiometric.statusVerifyBiometric);

        switch (statusVerifyBiometric) {
            case TYPE_VERIFY_BIOMETRIC.CANCEL:
            case TYPE_VERIFY_BIOMETRIC.NO_ENROLLED_FINGERPRINT:
                yield Put(SelectorsAction.cleanUp());
                formikBag.setSubmitting(false);
                return undefined;
            case TYPE_VERIFY_BIOMETRIC.ERROR:
                yield Put(SelectorsAction.cleanUp());
                yield Put({ type: TYPE_FORM.SEND_FORM_DATA_FAILURE });
                formikBag.setSubmitting(false);
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: UtilsI18n.get("verify.biometric.error"),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.LOGIN],
                    }),
                );
                return undefined;
            default:
                break;
        }
    }

    yield Call(requestLoginStep1, username, secondFactor, recaptchaResponse, formikBag);

    return undefined;
}

function* requestLoginStep1(username, secondFactor, recaptchaResponse, formikBag) {
    const langApp = UtilsI18n.getLang();

    let deviceId;
    let deviceModel;
    let isTrusted;
    if (UtilsDevice.isMobileNative()) {
        deviceId = UtilsDevice.id();
        deviceModel = UtilsDevice.model();
        isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);
    }

    const response = yield Call(SelectorsMiddlewareSession.loginStep1, {
        _userId: username,
        _otp: secondFactor,
        _captcha: recaptchaResponse,
        lang: langApp,
        deviceId,
        deviceModel,
        isSoftToken: isTrusted,
    });
    formikBag.setSubmitting(false);

    if (response.type === RESPONSE_TYPE.WARNING) {
        yield Call(handleWarningResponseStep1, response, formikBag);
    } else {
        // NOTE: Added "environment" for Nazca3. See comment o step3 request.
        const { _userId, _exchangeToken, _securitySeal, _userFullName, environment, lang, _deviceInfo } =
            response.data.data;
        yield Put(
            SelectorsAction.loginStep1Success({
                exchangeToken: _exchangeToken,
                securitySeal: _securitySeal,
                userFullName: _userFullName,
                username: _userId,
                environment,
                lang,
            }),
        );

        if (UtilsDevice.isMobileNative()) {
            yield Call(UtilsSecureStorage.set, IS_CDP_USER, "true");
            let existBiometricType = true;
            const biometricType = _deviceInfo && _deviceInfo.biometricType;
            try {
                yield Call(UtilsSecureStorage.get, BIOMETRIC_TYPE);
            } catch (error) {
                existBiometricType = false;
                if (biometricType) {
                    yield Call(UtilsSecureStorage.set, BIOMETRIC_TYPE, biometricType);
                    return null;
                }
            }
            if (_deviceInfo && (existBiometricType || biometricType)) {
                const { accessToken } = _deviceInfo;
                try {
                    yield Call(UtilsSecureStorage.get, BIOMETRIC_ID_USER);
                } catch (error) {
                    yield Call(UtilsSecureStorage.set, BIOMETRIC_ID_USER, username);
                }

                yield Call(UtilsSecureStorage.set, FINGERPRINT_AUTH_TOKEN, accessToken);
                sessionStorage.setItem("widget.biometric.hide", true);
                yield Call(SelectorsMiddlewareSession.setAuthToken, accessToken);
                yield Call(successFingerprintSession, true);
            } else {
                yield Put(Replace("/loginStep2"));
            }
        } else {
            yield Put(Replace("/loginStep2"));
        }
        yield Put(SelectorsActionI18n.setLang({ lang }));
        yield Put(SelectorsActionRecoveryPassword.rememberUsername({ username }));
    }

    return undefined;
}

function* handleWarningResponseStep1(response, formikBag) {
    const { code } = response.data;
    yield Put(SelectorsAction.loginFailureLoader());
    if (code === USER_OTP_NOT_ACTIVE || code === API_INVALID_USER_OR_SECOND_FACTOR) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get(`returnCode.${code}`),
                level: LEVEL.ERROR,
                scopes: [SCOPE.LOGIN],
                autoDismiss: NOTIFICATION_DURATION.TERMS_NOT_ACCEPTED,
            }),
        );
    } else {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        switch (code) {
            case API_INVALID_IP_RANGE:
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: UtilsI18n.get("login.invalidCountry"),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.LOGIN],
                    }),
                );
                break;
            case USER_DOESNT_EXIST:
            case USER_DISABLED:
                // Wrong credentials || blocked user || no environments available
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: response.data.message,
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.LOGIN],
                    }),
                );
                break;
            case API_INVALID_USER_OR_SECOND_FACTOR_CAPTCHA_REQUIRED:
            case INVALID_CAPTCHA:
                // Captcha required || captcha invalid
                yield Put(SelectorsAction.loginFailureRequireCaptcha());
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: UtilsI18n.get("login.invalidEntry"),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.LOGIN],
                    }),
                );
                break;
            default:
                yield Put(SelectorsAction.loginFailure());
                break;
        }
    }

    yield Put({ type: TYPE_FORM.SEND_FORM_DATA_FAILURE });
}

function* handleLoginStep2Request(props) {
    const { formikBag, password, recaptchaResponse } = props;
    const exchangeToken = yield Select(SelectorsStore.getExchangeToken);
    // NOTE: Removed password from this call.
    //       it's not going to be used until below when force-yielding the step3 handler.
    const response = yield Call(SelectorsMiddlewareSession.loginStep2, exchangeToken, {
        _captcha: recaptchaResponse,
        _password: password,
    });

    if (response.type === RESPONSE_TYPE.WARNING) {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        formikBag.setSubmitting(false);
        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.LOGIN],
            }),
        );
        const { code } = response.data;

        switch (code) {
            case API_INVALID_PASSWORD_CAPTCHA_REQUIRED:
            case INVALID_CAPTCHA:
                // Capcha required || capcha invalid
                yield Put(SelectorsAction.loginFailureRequireCaptcha());
                break;
            case VALIDATION_ERROR:
            case API_INVALID_PASSWORD:
                yield Put(SelectorsAction.loginFailure());
                break;
            default:
                // exchangeToken expired, restart flow
                yield Put({ type: TYPES_APP.BACK_TO_STEP_0 });
                yield Put(Replace("/"));
                break;
        }
    } else {
        const { environmentToChange, registeredBantotal } = response.data.data;

        const deviceLocationVU = yield Call(getDeviceLocationVU);

        const response3 = yield Call(SelectorsMiddlewareSession.loginStep3, exchangeToken, {
            setAsDefault: true,
            environment: environmentToChange,
            _password: password,
            ...deviceLocationVU,
        });

        if (response3.type === RESPONSE_TYPE.WARNING) {
            // API responded a warning, handle accordingly
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
            formikBag.setSubmitting(false);

            const { message, code } = response3.data;
            yield Put(SelectorsAction.loginFailure());
            switch (code) {
                case VALIDATION_ERROR:
                case API_INVALID_PASSWORD:
                case API_INVALID_PASSWORD_CAPTCHA_REQUIRED:
                case INVALID_CAPTCHA:
                    // Wrong credentials || capcha required || capcha invalid
                    yield Put(
                        SelectorsActionNotification.showNotification({
                            message,
                            level: LEVEL.ERROR,
                            scopes: [SCOPE.LOGIN],
                        }),
                    );

                    if (code === API_INVALID_PASSWORD_CAPTCHA_REQUIRED || code === INVALID_CAPTCHA) {
                        // Capcha required || capcha invalid
                        yield Put(SelectorsAction.loginFailureRequireCaptcha());
                    }

                    yield Put({ type: TYPE_FORM.SEND_FORM_DATA_FAILURE });
                    break;
                case MOBILE_LOGIN_TERMS_NOT_ACCEPTED:
                    yield Call(handleFinalizeLogin, { response: response3 });
                    break;
                default:
                    yield Put(
                        SelectorsActionNotification.showNotification({
                            message,
                            level: LEVEL.ERROR,
                            scopes: [SCOPE.LOGIN],
                        }),
                    );
                    break;
            }
        } else {
            yield Call(handleFinalizeLogin, { response: { ...response3, registeredBantotal } });
        }
    }
}

function* handleLoginStep3Request(props) {
    const { idEnvironment, rememberEnvironment, formikBag } = props;
    const exchangeToken = yield Select(SelectorsStore.getExchangeToken);

    const response = yield Call(SelectorsMiddlewareSession.loginStep3, exchangeToken, {
        environment: idEnvironment,
        setAsDefault: rememberEnvironment,
        _password: {
            // isFromMessenger,
            // isFromGoogle,
            // isFromAmazon,
            // isFromWhatsapp,
            // accountLinkingToken,
            // clientNumber,
        },
    });

    if (response.type === RESPONSE_TYPE.WARNING) {
        // en este paso lo unico que podria suceder es que se venciera el exchange token
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        formikBag.setSubmitting(false);
        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.LOGIN],
            }),
        );
        // por lo que borro lo que tengo de sesion y me voy al step0
        yield Put({ type: TYPES_APP.BACK_TO_STEP_0 });
        yield Put(Replace("/"));
    } else {
        yield Call(handleFinalizeLogin, { response });
    }
}

function* handleLoginStep4Request({ acceptConditions, formikBag }) {
    const exchangeToken = yield Select(SelectorsStore.getExchangeToken);

    const deviceLocationVU = yield Call(getDeviceLocationVU);

    const response = yield Call(SelectorsMiddlewareSession.loginStep4, exchangeToken, {
        acceptConditions,
        ...deviceLocationVU,
    });

    if (response.type === RESPONSE_TYPE.WARNING) {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        formikBag.setSubmitting(false);
        yield Put(
            SelectorsActionNotification.showNotification({
                message: response.data.message,
                level: LEVEL.ERROR,
                scopes: [SCOPE.LOGIN],
            }),
        );
        yield Put(SelectorsAction.loginFailure());
    } else {
        const isTrusted = yield Select(SelectorsStoreConfig.getIsTrusted);
        const loginData = yield Call(processLoginSuccess, response);
        const { data } = response.data;
        const { lang, environments } = data;
        const { lastHref } = yield Select((state) => state.status);

        UtilsConfig.setRecaptchaLang(lang);

        yield Put(SelectorsActionI18n.setLang({ lang }));

        const { _accessToken: accessToken, isAdministrator } = data;
        yield Call(SelectorsMiddlewareSession.setAuthToken, accessToken);

        const needsBiometric = isTrusted && loginData.user.otpType.indexOf(SOFT_OTP) !== -1;

        yield Put({
            type: TYPE.LOGIN_SUCCESS,
            environment: loginData.environment,
            environments,
            isAdministrator,
            user: { ...loginData.user, needsBiometric },
        });

        if (lastHref) {
            // If the session has expired, and the previous url has been saved, a redirection is performed to this url
            yield Put(Replace(lastHref));
            yield Put(SelectorsActionStatus.deleteLastHref());
        } else {
            yield Put(SelectorsActionStatus.resetComeFromLogin());
            // otherwise, desktop is visualized
            yield Put(Replace("/desktop"));
        }
    }
}

function processLoginSuccess(response) {
    const {
        _accessToken,
        _securitySeal,
        _ua,
        defaultAvatarId,
        defaultEnvironmentId,
        email,
        otpType,
        previousLoginInfo,
        userFullName,
        userName,
        ...data
    } = response.data.data;

    const user = {
        accessToken: _accessToken,
        defaultAvatarId,
        defaultEnvironmentId,
        email,
        otpType,
        previousLoginInfo,
        qrModoUserAgent: _ua,
        securitySeal: _securitySeal,
        userFullName,
        userName,
    };

    let forms = null;

    if (data.forms) {
        forms = {};

        for (let i = 0; i < data.forms.length; i += 1) {
            let category = forms[data.forms[i].category];

            if (!category) {
                category = [];

                forms[data.forms[i].category] = category;
            }

            category.push(data.forms[i]);
        }
    }

    const environment = {
        administrationScheme: data.administrationScheme,
        environmentData: data.environmentData,
        factoring: data.factoring,
        forms,
        id: data.activeIdEnvironment,
        isPremierException: data.premierException,
        name: data.activeEnvironmentName,
        permissions: data.permissions,
        riskProfileCode: data.riskProfileCode,
        riskProfileExpiration: data.riskProfileExpiration,
        type: data.activeEnvironmentType,
    };

    return {
        environment,
        user,
    };
}

function* handleFingerprintLoginPre() {
    let fingerprintAuthToken = null;
    let isCDPUser = false;
    let fingerprintAuthTokenExists = true;

    try {
        fingerprintAuthToken = yield Call(UtilsSecureStorage.get, FINGERPRINT_AUTH_TOKEN);
        isCDPUser = yield Call(UtilsSecureStorage.get, IS_CDP_USER);
    } catch (error) {
        fingerprintAuthTokenExists = false;
        // if it doesn't have a fingerprint, enable the button in step1
        yield Put(SelectorsAction.discardFingerprintLogin());
    }

    if (fingerprintAuthTokenExists && isCDPUser) {
        const fingerPrintLoginFail = yield Select(SelectorsStore.getFingerprintLoginFail);

        if (!fingerPrintLoginFail) {
            const availability = yield Call(UtilsFingerprint.isAvailable);

            try {
                const biometricType = yield Call(UtilsSecureStorage.get, BIOMETRIC_TYPE);
                if (biometricType === BIOMETRIC_TYPES.fingerPrint) {
                    if (availability && availability.isAvailable && availability.hasEnrolledFingerprints) {
                        const message = UtilsI18n.get("settings.fingerprintConfiguration.dialog.message.login");
                        yield Call(UtilsFingerprint.verify, message);
                    } else {
                        yield Put(SelectorsAction.setFingerprintFailure());
                        return undefined;
                    }
                } else {
                    const facephiUser = yield Call(UtilsSecureStorage.get, BIOMETRIC_ID_USER);

                    if (facephiUser) {
                        yield Call(facephiValidation, facephiUser);
                    }
                }
                const response = yield Call(SelectorsMiddlewareSession.checkFingerprintSession, fingerprintAuthToken);
                if (response && response.status === 200) {
                    const { existSessionWithFingerPrint } = response.data.data;

                    yield Call(successFingerprintSession, existSessionWithFingerPrint);
                } else {
                    yield Put(SelectorsAction.setFingerprintFailure());
                    yield Put(
                        SelectorsActionNotification.showNotification({
                            message: UtilsI18n.get(response.message),
                            level: LEVEL.ERROR,
                            scopes: [SCOPE.LOGIN],
                        }),
                    );
                }
            } catch (error) {
                yield Call(handleFingerprintLoginError, error, availability);
            }
        }
    }
    return undefined;
}

function* successFingerprintSession(existSessionWithFingerPrint) {
    if (!existSessionWithFingerPrint) {
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("login.fingerprint.session.expired"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.EXTERNAL_DASHBOARD],
            }),
        );
        yield Call(UtilsSecureStorage.remove, FINGERPRINT_AUTH_TOKEN);
        yield Put(SelectorsAction.setFingerprintFailure());
    } else {
        yield Put(SelectorsAction.setLoginFingerprintSubmit());

        const deviceLocationVU = yield Call(getDeviceLocationVU);

        const response = yield Call(SelectorsMiddlewareSession.fingerprintLogin, {
            ...deviceLocationVU,
        });

        const { registeredBantotal } = response.data.data;

        if (response && response.status === 200) {
            const { code } = response.data;
            switch (code) {
                case API_FINGER_SESSION_ENDED:
                case USER_OTP_NOT_ACTIVE:
                    yield Put(SelectorsAction.setFingerprintFailure());
                    yield Put(
                        SelectorsActionNotification.showNotification({
                            message: UtilsI18n.get(`returnCode.${code}`),
                            level: LEVEL.ERROR,
                            scopes: [SCOPE.LOGIN, SCOPE.EXTERNAL_DASHBOARD],
                            autoDismiss: NOTIFICATION_DURATION.TERMS_NOT_ACCEPTED,
                        }),
                    );
                    yield Put(Replace("/"));
                    break;
                case API_INVALID_IP_RANGE:
                    yield Put(SelectorsAction.setFingerprintFailure());
                    yield Put(
                        SelectorsActionNotification.showNotification({
                            message: UtilsI18n.get("login.invalidCountry"),
                            level: LEVEL.ERROR,
                            scopes: [SCOPE.LOGIN, SCOPE.EXTERNAL_DASHBOARD],
                        }),
                    );
                    break;
                default:
                    yield Call(handleFinalizeLogin, { response: { ...response, registeredBantotal } });

                    break;
            }
        }
    }
}

function* facephiValidation(facephiUser) {
    yield Put(SelectorsAction.setLoginFingerprintSubmit());
    const facephiPluginResult = yield Call(UtilsFacephi.authenticate, false);
    if (facephiPluginResult) {
        if (facephiPluginResult.finishStatus === UtilsFacephi.status.Ok) {
            const bioExtraInfo = "";
            const bioInfo = facephiPluginResult.template;
            const userID = facephiUser;
            const response = yield Call(SelectorsMiddleware.faceAuthentication, {
                bioInfo,
                bioExtraInfo,
                userID,
            });
            if (response && response.status === 200) {
                const { data } = response.data;
                if (!data.faceIsValid) {
                    yield Put(SelectorsAction.setFingerprintFailure());
                    yield Put(
                        SelectorsActionNotification.showNotification({
                            message: UtilsI18n.get("verify.biometric.error.facephi"),
                            level: LEVEL.ERROR,
                            scopes: [SCOPE.LOGIN],
                        }),
                    );
                }
            }
            return undefined;
        }
        if (facephiPluginResult.finishStatus !== UtilsFacephi.status.CancelByUser) {
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("login.fingerprint.session.wrongAccess"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.LOGIN, SCOPE.EXTERNAL_DASHBOARD],
                }),
            );
        }
        yield Put(SelectorsAction.setFingerprintFailure());
    }
    return undefined;
}

function* handleFingerprintLoginError(error, availability) {
    yield Put(SelectorsAction.discardFingerprintLogin());

    const mess = `${UtilsI18n.get("settings.fingerprintConfiguration.dialog.error_1")}\n${UtilsI18n.get(
        "settings.fingerprintConfiguration.dialog.error_2",
    )}`;
    if (error.response) {
        switch (error.response.status) {
            case 200:
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: UtilsI18n.get("login.fingerprint.session.wrongAccess"),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.LOGIN, SCOPE.EXTERNAL_DASHBOARD],
                    }),
                );

                break;
            case 401:
            case 403:
                yield Put(
                    SelectorsActionNotification.showNotification({
                        message: UtilsI18n.get("login.fingerprint.session.expired"),
                        level: LEVEL.ERROR,
                        scopes: [SCOPE.EXTERNAL_DASHBOARD],
                    }),
                );
                yield Call(UtilsSecureStorage.remove, FINGERPRINT_AUTH_TOKEN);
                break;
            default:
                break;
        }
        yield Put(SelectorsAction.setFingerprintFailure());
        return undefined;
    }
    if (
        // When Facephi fails the error contains an errorType
        Object.prototype.hasOwnProperty.call(error, "errorType") &&
        error.errorType === UtilsFacephi.error.CameraPermissionDenied
    ) {
        yield Put(SelectorsAction.setFingerprintFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get("enrollment.index.invitationCode.scanQRCode.error"),
                level: LEVEL.ERROR,
                scopes: [SCOPE.LOGIN],
            }),
        );
        return false;
    }

    if (
        // When api failed the error contains a data
        Object.prototype.hasOwnProperty.call(error, "data") &&
        error.data !== null
    ) {
        yield Put(SelectorsAction.setFingerprintFailure());
        yield Put(
            SelectorsActionNotification.showNotification({
                message: UtilsI18n.get(error.data.message, error.data.message),
                level: LEVEL.ERROR,
                scopes: [SCOPE.LOGIN],
            }),
        );
        return false;
    }

    let errorType = UtilsFingerprint.getError(error);
    if (error.code !== null && error.code !== undefined) {
        errorType = error.code;
    }

    switch (errorType) {
        case UtilsFingerprint.fingerprintErrors.FINGERPRINT_ERROR:
        case ERROR_MOBILE_IOS_FAILED:
            yield Put(SelectorsAction.setFingerprintFailure());

            yield Put(
                SelectorsActionNotification.showNotification({
                    message: mess,
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.LOGIN],
                }),
            );
            break;
        case UtilsFingerprint.fingerprintErrors.FINGERPRINT_CANCELLED:
        case ERROR_MOBILE_IOS_128:
        case ERROR_MOBILE_IOS_CANCEL:
        case ERROR_MOBILE_IOS_CANCEL_BY_SYSTEM:
            if (availability.type === BIOMETRIC_FACE_ID) {
                yield Put(SelectorsAction.setFingerprintFailure());
            }
            break;
        case ERROR_MOBILE_IOS_LOCK_OUT:
            yield Put(
                SelectorsActionNotification.showNotification({
                    message: UtilsI18n.get("verify.biometric.error.lockout"),
                    level: LEVEL.ERROR,
                    scopes: [SCOPE.LOGIN],
                }),
            );
            break;
        default:
            yield Put(SelectorsAction.setFingerprintFailure());
            break;
    }
    return undefined;
}

function* getDeviceLocationVU() {
    let deviceLocationVU;
    let fingerprint;
    let fingerprintData;

    try {
        deviceLocationVU = yield Select(SelectorsStoreSession.getDeviceLocationVU);

        if (!deviceLocationVU || (deviceLocationVU.latitude === null && deviceLocationVU.longitude === null)) {
            deviceLocationVU = yield Call(UtilsVUFingerprint.getDeviceLocationVU);
        }

        fingerprint = yield Call(UtilsVUFingerprint.getFingerprintVU);

        if (UtilsDevice.isMobileNative()) {
            fingerprintData = yield Call(UtilsVUFingerprint.getFingerprintDeviceInfoVU);

            if (UtilsDevice.mobileOS() === DEVICE_MOBILE.IOS) {
                fingerprintData = JSON.stringify(fingerprintData);
            }
        }
    } catch (ex) {
        // eslint-disable-next-line no-console
        console.log("Error fingerprint VU ", ex);
    }

    return { deviceLocationVU, fingerprint, fingerprintData };
}
