/* global document navigator cordova */

import React from "react";

import { push as Push } from "connected-react-router";

import { EMPTY_STR, EVENT_BACKBUTTON } from "~/constants";
import * as configUtils from "~/util/config";
import UtilsDevice, { DEVICE_MOBILE } from "~/util/device";
import * as UtilsI18n from "~/util/i18n";
import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";

import Button from "~/components/Button";
import Image from "~/components/Image";
import Modal from "~/components/Modal";

import Style from "./OldVersion.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...TypesRedux,
    },
    defaults: {
        ...DefaultsRedux,
    },
};
export class Component extends React.Component {
    static displayName = NAME;

    static defaultProps = PROP.defaults;

    static propTypes = PROP.types;

    componentDidMount() {
        if (UtilsDevice.isMobileNative()) {
            document.addEventListener(EVENT_BACKBUTTON, this.backButtonAction);
        }
    }

    componentWillUnmount() {
        if (UtilsDevice.isMobileNative()) {
            document.removeEventListener(EVENT_BACKBUTTON, this.backButtonAction);
        }
    }

    onConfirm = (buttonIndex) => {
        if (buttonIndex === 2) {
            navigator.app.exitApp();
        } else {
            document.addEventListener(EVENT_BACKBUTTON, this.backButtonAction);
        }
    };

    backButtonAction = () => {
        navigator.notification.confirm(UtilsI18n.get("desktop.askCloseApp"), this.onConfirm, EMPTY_STR, [
            UtilsI18n.get("global.no"),
            UtilsI18n.get("global.yes"),
        ]);
    };

    handleClick = () => {
        const { dispatch } = this.props;
        if (UtilsDevice.isMobileNative()) {
            let marketId;
            if (UtilsDevice.mobileOS() === DEVICE_MOBILE.ANDROID) {
                marketId = configUtils.get("client.app.market.androidId");
            } else {
                marketId = configUtils.get("client.app.market.iosId");
            }
            cordova.plugins.market.open(marketId);
        } else {
            dispatch(Push("/"));
        }
    };

    render() {
        return (
            <Modal show>
                <div id={Style.ID}>
                    <div className="modal-text">
                        <div className="logo-image">
                            <Image src="logo-hsbc-black.svg" />
                        </div>
                        <div className="text-container">
                            <h2>{UtilsI18n.get("min.version.label.title")}</h2>
                            <p>{UtilsI18n.get("min.version.label.note")}</p>
                        </div>
                        <button onClick={() => this.handleClick()} className="store-image">
                            <Image
                                src={
                                    UtilsDevice.mobileOS() === DEVICE_MOBILE.ANDROID ? "playstore.png" : "appstore.png"
                                }
                            />
                        </button>
                        <div className="modal-buttons">
                            <div className="login-form">
                                <div className="login-form-field button-field">
                                    <Button
                                        bsStyle="primary"
                                        onClick={() => this.handleClick()}
                                        label="min.version.label.button"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default Component;
