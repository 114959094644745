import UtilLodash from "~/util/lodash";

/**
 * Action Factory
 *
 * Creates an Action from given type and props.
 * @param {string} type - A string containing a unique name to identify te action.
 * @returns {ActionClosure} - A closure function expecting a props argument that will be extended with given type.
 */
export const FactoryAction = (type, props) => ({ type, ...(UtilLodash.isObject(props) ? props : {}) });

/**
 * Type Factory
 *
 * Populates a TYPE object given a name and an array of keys.
 * @param {string} name - The NAME constant of a Store Manager.
 * @param {array} keys - An Array of keys to populate the TYPE object with.
 * @returns {TYPE} - A TYPE Object populated with corresponding keys.
 */
export const FactoryType = (name, keys) =>
    keys.reduce((acc, key) => ({ ...acc, [key]: name ? [name, key].join("/") : key }), {});

// TODO: Replace all instances of this method on project in favor of FactoryAction
export const makeActionCreator = (type, ...argNames) => (...args) => {
    const action = { type };

    argNames.forEach((_, index) => {
        action[argNames[index]] = args[index];
    });

    return action;
};

// TODO: Replace all instance of this method in favor of FactoryReducer
export const createReducer = (iniState, handlers) => (state = iniState, action) => {
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
        return handlers[action.type](state, action);
    }

    return state;
};
