import PropTypes from "prop-types";

import { Types as TypesRedux, Defaults as DefaultsRedux } from "~/util/prop/redux";
import { FactoryType } from "~/util/redux";

export const NAME = "automaticDebits";

export const PATH = {
    AUTOMATIC_DEBIT_UPDATE_FORM: "/createAutomaticDebit.updateForm",
    AUTOMATIC_DEBIT_LIST: "/client.automaticDebits.list",
};

export const TYPE = FactoryType(NAME, [
    "CLEAR_LIST",
    "LIST_AGREEMENTS_REQUEST",
    "LIST_AGREEMENTS_FAILURE",
    "LIST_AGREEMENTS_SUCCESS",
    "LIST_AUTOMATIC_DEBITS_REQUEST",
    "LIST_AUTOMATIC_DEBITS_FAILURE",
    "LIST_AUTOMATIC_DEBITS_SUCCESS",
    "CHANGE_NEWS_DATE",
    "CHANGE_VALUE",
]);

export const PROP = {};

PROP.types = {
    isFetching: PropTypes.bool,
    listAgreements: PropTypes.array,
    listAutomaticDebits: PropTypes.array,
    listBanredAutomaticDebits: PropTypes.array,
    changeValue: PropTypes.bool,
    newsDate: PropTypes.string,
    ...TypesRedux,
};

PROP.defaults = {
    isFetching: false,
    listAgreements: [],
    listAutomaticDebits: [],
    listBanredAutomaticDebits: [],
    changeValue: false,
    ...DefaultsRedux,
};
