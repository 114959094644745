import React from "react";

import { push as Push } from "connected-react-router";

import { EMPTY_STR } from "~/constants/index";
import { STATUS } from "~/constants/transaction";
import RouteTicket from "~/store/form/_sagas/_routeTicket";
import { generateId as GenID } from "~/util/general";
import * as i18nUtils from "~/util/i18n";

import DataNumber from "~/components/DataNumber";
import GridLayout from "~/components/GridLayout";

import Style from "./TransactionItem.rules.scss";

import { ReactComponent as ProcessingIcon } from "~/assets/alertIconCron.svg";
import { ReactComponent as ReturnedIcon } from "~/assets/alertIconError.svg";
import { ReactComponent as DefaultIcon } from "~/assets/alertIconMedium.svg";
import { ReactComponent as ScheduleIcon } from "~/assets/schedulet.svg";

export const { NAME_MESSAGES } = Style;
export const NAME = "TransactionItem";

export const PROP = {
    types: {},
    defaults: {},
};

const HEADERS = [
    "comex.desktop.transactions.header.one",
    "comex.desktop.transactions.header.two",
    "comex.desktop.transactions.header.three",
    "comex.desktop.transactions.header.four",
    "comex.desktop.transactions.header.five",
];
const MAX_ROWS = 5;

export class Component extends React.Component {
    getImage = (transactionStatus) => {
        if (transactionStatus === STATUS.SCHEDULED) {
            return <ScheduleIcon />;
        }
        if (transactionStatus === STATUS.PROCESSING) {
            return <ProcessingIcon />;
        }
        if (transactionStatus === STATUS.SCHEDULED) {
            return <ScheduleIcon />;
        }
        if (transactionStatus === STATUS.DRAFT) {
            return <ReturnedIcon />;
        }
        return <DefaultIcon />;
    };

    getClassImage = (transactionStatus) => {
        if (transactionStatus === STATUS.DRAFT) {
            return "transaction-status gray";
        }
        if (transactionStatus === STATUS.SCHEDULED) {
            return "transaction-status gray";
        }
        return "transaction-status";
    };

    getStatus = (transaction) => {
        // const { isMobile } = this.props;
        const { statusLabel, /* signaturesLabel, signatures, */ idTransactionStatus } = transaction;
        return (
            <React.Fragment>
                <div className="transactionItemStatus">
                    <span>
                        {idTransactionStatus === STATUS.DRAFT
                            ? i18nUtils.get("transaction.status.RETURNED")
                            : statusLabel}
                    </span>
                    {this.getImage(idTransactionStatus)}
                </div>
                {/* {!isMobile && signaturesLabel && (
                    <React.Fragment>
                        <div>
                            {signaturesLabel}
                            <span>
                                <div>
                                    <I18n id={`transaction.status.${idTransactionStatus}.signedByTooltip`} />
                                </div>
                            </span>

                            <span>
                                {signatures.map((item) => (
                                    <div>
                                        <small>
                                            {item.userFirstName} {item.userLastName}
                                        </small>
                                        <br />
                                    </div>
                                ))}
                            </span>
                        </div>
                    </React.Fragment>
                )} */}
            </React.Fragment>
        );
    };

    redirectToTicket = (transaction) => {
        const { dispatch } = this.props;
        const { idActivity, idTransaction } = transaction;
        const urlToTicket = RouteTicket[idActivity](idTransaction);
        dispatch(Push(urlToTicket));
    };

    render() {
        const { transactions } = this.props;
        const items = transactions || [];

        return (
            <section>
                <div className="transactionItem">
                    <div className={items.length > MAX_ROWS ? "header-smaller" : "header"}>
                        <GridLayout>
                            {HEADERS.map((heading) => (
                                <div key={GenID()}>{i18nUtils.get(heading)}</div>
                            ))}
                        </GridLayout>
                    </div>
                    <div className={items.length > MAX_ROWS ? "table-body scrollable" : "table-body"}>
                        {items.map((t) => {
                            let currency = EMPTY_STR;
                            let amount = EMPTY_STR;
                            if (t.data) {
                                currency =
                                    t.data?.amount?.currency ||
                                    t.data?.amountCurrency ||
                                    t.data?.invoiceAmountCurrency ||
                                    t.data?.currency ||
                                    t.data?.transferAmount?.currency ||
                                    t.data?.guaranteeAmountCurrency ||
                                    EMPTY_STR;
                                amount =
                                    t.data?.amount?.quantity ||
                                    t.data?.amount ||
                                    t.data?.invoiceAmount ||
                                    t.data?.transferAmount?.quantity ||
                                    t.data?.oldAmount ||
                                    t.data?.guaranteeAmount ||
                                    EMPTY_STR;
                            }

                            return (
                                <GridLayout
                                    key={GenID()}
                                    className="body position-clickable"
                                    onClick={() => {
                                        this.redirectToTicket(t);
                                    }}>
                                    <div>{t.submitDateTimeAsString ? t.submitDateTimeAsString : EMPTY_STR}</div>
                                    <div>{t.data && t.data.operationNumber ? t.data.operationNumber : EMPTY_STR}</div>
                                    <div>{t.activityName}</div>
                                    <div>
                                        {t.data && currency !== EMPTY_STR && (
                                            <DataNumber value={amount} prefix={currency} />
                                        )}
                                    </div>
                                    <div className="transaction-list-data transaction-list-data-status">
                                        <span>{this.getStatus(t)}</span>
                                    </div>
                                </GridLayout>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}
export default Component;
