import React from "react";

import ClassNames from "classnames";
import { goBack as GoBack } from "connected-react-router";
import { Form, Field, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { COMMA_PLUS_SPACE, DASH, EMPTY_STR, PIPE_SEPARATOR, SPACE_STR, ZERO_NUMBER } from "~/constants";
import { MODE } from "~/constants/form";
import {
    SelectorsAction as SelectorsActionServicePayment,
    PROP as PropServicePayment,
    SelectorsStore as SelectorsStoreServicePayment,
} from "~/store/servicePayments";
import * as UtilsConfig from "~/util/config";
import * as UtilsDate from "~/util/date";
import * as UtilsI18n from "~/util/i18n";
import UtilsLodash from "~/util/lodash";

import Box from "~/components/Box";
import Button from "~/components/Button/Button";
import DataNumber from "~/components/DataNumber";
import ExpandElement from "~/components/ExpandElement";
import GridLayout from "~/components/GridLayout";
import HighOrder from "~/components/HighOrder";
import I18n from "~/components/I18n";
import Image from "~/components/Image";
import FieldLabel from "~/pages/_components/fields/FieldLabel";
import TextField from "~/pages/_components/fields/TextField";
import Checkbox from "~/pages/_components/fields/formik/Checkbox";
import Selector from "~/pages/_components/fields/formik/Selector";

import Style from "./Step1Edit.rules.scss";

const FORM_ID = "service.payment";

export const { NAME } = Style;

export const PROP = {
    types: {
        ...PropServicePayment.types,
        isMobile: PropTypes.bool,
        isDesktop: PropTypes.bool,
    },
    defaults: {
        ...PropServicePayment.defaults,
        isMobile: false,
        isDesktop: false,
    },
};

function concatIdOrganismServiceType(idOrganism, serviceType) {
    return idOrganism + PIPE_SEPARATOR + serviceType;
}

function organismDescriptionToShow(organism) {
    if (organism.organismDescription && organism.organismDescription.trim() !== EMPTY_STR) {
        return organism.organismDescription;
    }

    return organism.description;
}

function serviceDescriptionToShow(organism) {
    if (organism && organism.serviceDescription && organism.serviceDescription.trim() !== EMPTY_STR) {
        return organism.serviceDescription;
    }

    return organism?.serviceType;
}

function splitIdOrganismServiceType(idOrganism) {
    return idOrganism.split(PIPE_SEPARATOR);
}

export class Component extends React.Component {
    state = {
        checkError: false,
        withoutBills: false,
        companyIsOpened: false,
        sistarbancAvailableServicesSortId: [],
    };

    componentDidMount() {
        const { dispatch } = this.props;
        this.setState({
            sistarbancAvailableServicesSortId: UtilsConfig.getArray(
                `client.sistarbanc.sistarbancAvailableServices.sort`,
            ),
        });

        dispatch(SelectorsActionServicePayment.servicePaymentPreRequest());
    }

    componentDidUpdate(prevProps) {
        const { billsToPay, setFieldValue } = this.props;
        const { billsToPay: prevBillsToPay } = prevProps;

        if (!UtilsLodash.isEqual(billsToPay, prevBillsToPay)) {
            if (billsToPay) {
                setFieldValue(
                    "checkboxes",
                    billsToPay.map(() => false),
                );

                billsToPay.forEach((bill, index) => setFieldValue(`bill_${index}`, false));
            } else {
                setFieldValue("checkboxes", []);
            }
        }
    }

    handleAccountChange = (id) => {
        const { setErrors, setFieldValue } = this.props;

        setFieldValue("debitAccount", id);

        setErrors({});
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(GoBack());
    };

    handleCheckChange = (index) => {
        const { setFieldValue, setErrors, values } = this.props;
        const { checkboxes } = values;

        checkboxes[index] = !checkboxes[index];

        this.setState({ checkError: checkboxes.includes(true) });

        setFieldValue("checkboxes", checkboxes);
        setFieldValue(`bill_${index}`, checkboxes[index]);
        setErrors({});
    };

    handleFetchBillsToPay = () => {
        const { dispatch, setErrors, setFieldError, setFieldTouched, sistarbancAvailableServices, values, billsToPay } =
            this.props;
        const { idOrganism, serviceType } = values;

        setErrors({});

        const organismFields = sistarbancAvailableServices.find(
            (service) => idOrganism === concatIdOrganismServiceType(service.idOrganism, service.serviceType),
        ).fields;

        const idServiceList = [];
        const validationErrors = [];

        organismFields.forEach((orgField) => {
            const fieldValue = values[orgField.name];
            const regex = new RegExp(orgField.format);

            if (regex.test(fieldValue)) {
                idServiceList.push(values[orgField.name]);
            } else {
                validationErrors.push(orgField.name);
            }
        });

        if (!billsToPay) {
            this.setState({ withoutBills: !(billsToPay?.length > 0) });
        }

        const splitIdOrganism = splitIdOrganismServiceType(idOrganism);
        const params = { idServiceList, idOrganism: splitIdOrganism[0], serviceType };

        if (validationErrors.length === 0) {
            dispatch(SelectorsActionServicePayment.fetchServiceDataRequest(params));
        } else {
            validationErrors.forEach((fieldName) => {
                setFieldTouched(fieldName, true, false);
                setFieldError(fieldName, UtilsI18n.get(`${FORM_ID}.step1.field.error`), false);
            });
        }
    };

    handleIsOpenCallBack = (childProp) => {
        this.setState({
            companyIsOpened: childProp,
        });
    };

    handleOrganismChange = (id) => {
        const { dispatch, setErrors, setFieldValue, sistarbancAvailableServices } = this.props;

        dispatch(SelectorsActionServicePayment.cleanBills());

        const organism = sistarbancAvailableServices.find(
            (service) => id === concatIdOrganismServiceType(service.idOrganism, service.serviceType),
        );
        const organismLogo = sistarbancAvailableServices.find(
            (service) => id === concatIdOrganismServiceType(service.idOrganism, service.serviceType),
        )?.urlLogoImage;

        setFieldValue("checkboxes", []);
        setFieldValue("idOrganism", id);
        setFieldValue("organismLogo", organismLogo);
        setFieldValue("serviceType", organism?.serviceType);
        setFieldValue("serviceTypeDescription", serviceDescriptionToShow(organism));

        // The values of the fields corresponding to the new organism selected are initialized
        const organismFields = organism?.fields;
        if (organismFields) {
            organismFields.forEach(function setFields(orgField) {
                setFieldValue(orgField.name, EMPTY_STR);
            });
        }

        this.setState({
            checkError: false,
            withoutBills: false,
        });

        setErrors({});
    };

    render() {
        const { billsToPay, debitAccounts, sistarbancAvailableServices, values, isDesktop, isMobile } = this.props;
        const { withoutBills, companyIsOpened, sistarbancAvailableServicesSortId } = this.state;
        const { debitAccount, idOrganism, organismLogo, serviceTypeDescription, checkboxes } = values;
        let organismFieldsAsComponents;

        const COLUMN_HEADINGS_WEB = [
            UtilsI18n.get(`${FORM_ID}.heading.id`),
            UtilsI18n.get(`${FORM_ID}.heading.detail`),
            UtilsI18n.get(`${FORM_ID}.heading.dueDate`),
            UtilsI18n.get(`${FORM_ID}.heading.amount`),
        ];

        const COLUMN_HEADINGS_MOBILE = [
            UtilsI18n.get(`${FORM_ID}.heading.dueDate`),
            UtilsI18n.get(`${FORM_ID}.heading.amount`),
        ];

        const accountOptions = debitAccounts?.map((account) => ({
            error: EMPTY_STR,
            id: account.idProduct,
            label: account.productAlias,
            value: account.idProduct,
        }));

        const billImage = sistarbancAvailableServices?.find(
            (service) => idOrganism === concatIdOrganismServiceType(service.idOrganism, service.serviceType),
        )?.urlBillImage;

        const hasBills = billsToPay?.length > 0;

        const organismFields = sistarbancAvailableServices?.find(
            (service) => idOrganism === concatIdOrganismServiceType(service.idOrganism, service.serviceType),
        )?.fields;

        if (organismFields) {
            const tooltipIdentification = organismFields.map((field) => field.name).join(COMMA_PLUS_SPACE);

            organismFieldsAsComponents = organismFields.map((field, index) => {
                return (
                    <Box className="info-label input-field tooltip-image">
                        <FieldLabel
                            labelText={UtilsI18n.replaceParams(UtilsI18n.get(`${FORM_ID}.step1.data.payment.label`), {
                                DATA: field.name,
                            })}
                        />
                        <Field
                            autoComplete="off"
                            className="form-control"
                            component={TextField}
                            hidePlaceholder
                            hidelabel
                            idForm={FORM_ID}
                            mode={MODE.EDIT}
                            name={field.name}
                            optional={UtilsI18n.get("form.field.optional")}
                            tooltip={
                                index === 0 && (
                                    <React.Fragment>
                                        <I18n id={`${FORM_ID}.step1.data.tooltip`} DATA={tooltipIdentification} />
                                        <Image src={billImage} />
                                    </React.Fragment>
                                )
                            }
                            type="text"
                        />
                    </Box>
                );
            });
        }

        let organismOptions = [];

        if (sistarbancAvailableServices) {
            const organismOptionsMain = sistarbancAvailableServices
                .filter((service) => {
                    return sistarbancAvailableServicesSortId.some((sortId) => {
                        const id = concatIdOrganismServiceType(service.idOrganism, service.serviceType);
                        const idContent = id.split(PIPE_SEPARATOR);

                        return idContent[0] === sortId;
                    });
                })
                .map((service) => ({
                    id: concatIdOrganismServiceType(service.idOrganism, service.serviceType),
                    error: EMPTY_STR,
                    label:
                        organismDescriptionToShow(service) +
                        SPACE_STR +
                        DASH +
                        SPACE_STR +
                        serviceDescriptionToShow(service),
                    value: concatIdOrganismServiceType(service.idOrganism, service.serviceType),
                }))
                .sort((option1, option2) => {
                    const id1 = option1.id.toUpperCase();
                    const idContent1 = id1.split(PIPE_SEPARATOR);

                    const id2 = option2.id.toUpperCase();
                    const idContent2 = id2.split(PIPE_SEPARATOR);

                    return (
                        sistarbancAvailableServicesSortId.indexOf(idContent1[0]) -
                        sistarbancAvailableServicesSortId.indexOf(idContent2[0])
                    );
                });

            const organismOptionsSecond = sistarbancAvailableServices
                .filter((service) => {
                    return !organismOptionsMain.some((option) => {
                        const id = concatIdOrganismServiceType(service.idOrganism, service.serviceType);

                        return id === option.id;
                    });
                })
                .map((service) => ({
                    error: EMPTY_STR,
                    id: concatIdOrganismServiceType(service.idOrganism, service.serviceType),
                    label:
                        organismDescriptionToShow(service) +
                        SPACE_STR +
                        DASH +
                        SPACE_STR +
                        serviceDescriptionToShow(service),
                    value: concatIdOrganismServiceType(service.idOrganism, service.serviceType),
                }));

            if (organismOptionsSecond) {
                organismOptionsSecond.sort((option1, option2) => {
                    const label1 = option1.label.toUpperCase();
                    const label2 = option2.label.toUpperCase();

                    if (label1 < label2) {
                        return -1;
                    }
                    if (label1 > label2) {
                        return 1;
                    }

                    return 0;
                });

                organismOptions = organismOptionsMain.concat(organismOptionsSecond);
            }
        }

        organismOptions.unshift({
            error: EMPTY_STR,
            id: concatIdOrganismServiceType(EMPTY_STR, EMPTY_STR),
            label: UtilsI18n.get(`${FORM_ID}.idOrganism.default.option`),
            value: concatIdOrganismServiceType(EMPTY_STR, EMPTY_STR),
        });

        const amountsToPay = {};

        if (billsToPay) {
            checkboxes.forEach((checkbox, index) => {
                if (checkbox) {
                    const { currency } = billsToPay[index];

                    if (!(currency in amountsToPay)) {
                        amountsToPay[currency] = billsToPay[index].amount;
                    } else {
                        amountsToPay[currency] += billsToPay[index].amount;
                    }
                }
            });
        }

        const renderMobileBills = ({ amount, description, dueDate, idDocument, currency }, index) => {
            const { checkError } = this.state;

            return (
                <div className="container-body">
                    <div className="check-error-mobile">
                        <Field
                            canRenderFieldError={!checkError}
                            checked={values.checkboxes[index]}
                            component={Checkbox}
                            controlStyle="checkbox"
                            hideLabel
                            hidePlaceholder
                            idForm={FORM_ID}
                            name={`bill_${index}`}
                            onChange={() => this.handleCheckChange(index)}
                        />
                    </div>
                    <div className="container-data">
                        <ExpandElement>
                            <GridLayout className="body">
                                <Box>{UtilsDate.specificDate(dueDate)}</Box>
                                <Box className="justify-end">
                                    <DataNumber value={amount} prefix={currency} />
                                </Box>
                            </GridLayout>
                            <Box className="details">
                                <Box>
                                    <I18n className="hightlight" id={`${FORM_ID}.service.label.id`} />
                                    <Box className="item-data">
                                        <span>{idDocument}</span>
                                    </Box>
                                </Box>
                                {description && (
                                    <Box>
                                        <I18n className="hightlight" id={`${FORM_ID}.service.label.description`} />
                                        <Box className="item-data">{description}</Box>
                                    </Box>
                                )}
                            </Box>
                        </ExpandElement>
                    </div>
                </div>
            );
        };

        const renderWebBills = ({ amount, currency, description, dueDate, idDocument }, index) => {
            const { checkError } = this.state;

            return (
                <GridLayout className="body">
                    <div className={ClassNames({ "check-error": !checkError }, "container-check")}>
                        <Field
                            canRenderFieldError={!checkError}
                            checked={values.checkboxes[index]}
                            component={Checkbox}
                            controlStyle="checkbox"
                            hideLabel
                            hidePlaceholder
                            idForm={FORM_ID}
                            name={`bill_${index}`}
                            onChange={() => this.handleCheckChange(index)}
                        />
                    </div>
                    <Box className="item-block">{idDocument}</Box>
                    {description ? <Box className="item-block">{description}</Box> : <Box>{SPACE_STR}</Box>}
                    <Box>{UtilsDate.specificDate(dueDate)}</Box>
                    <Box className="justify-end">
                        <DataNumber value={amount} prefix={currency} />
                    </Box>
                </GridLayout>
            );
        };

        return (
            <React.Fragment>
                <Form
                    className={ClassNames("col col-12 col-lg-6 col-md-9 col-sm-12", {
                        "company-opened-p0": companyIsOpened && isMobile,
                    })}
                    id={Style.ID}>
                    <section>
                        {((!companyIsOpened && isMobile) || isDesktop) && (
                            <Box className="form-section-title">
                                <h3 className="form-section-title-text">{UtilsI18n.get(`${FORM_ID}.service.title`)}</h3>
                            </Box>
                        )}
                        <Box className="form-group form-data">
                            <Box className={ClassNames("company-row", { "row-desktop": !isMobile })}>
                                <Box
                                    className={ClassNames(
                                        "company-input-group",
                                        "input-select",
                                        {
                                            "company-opened-m0": companyIsOpened && isMobile,
                                        },
                                        { "clean-select-input": companyIsOpened },
                                    )}>
                                    <Field
                                        className="flex-container slideFromBottom"
                                        clearable={false}
                                        component={Selector}
                                        hidePlaceholder
                                        hideLabel={companyIsOpened && isMobile}
                                        idForm={FORM_ID}
                                        isSearchableOnMobile
                                        labelKey="label"
                                        name="idOrganism"
                                        onChange={(item) => {
                                            if (item) {
                                                this.handleOrganismChange(item.id);
                                            }
                                        }}
                                        optionClassName="needsclick"
                                        options={organismOptions}
                                        value={idOrganism}
                                        valueKey="id"
                                        isOpenCallback={this.handleIsOpenCallBack}
                                    />
                                </Box>
                                {organismLogo && !companyIsOpened && (
                                    <Box className="company-img-container">
                                        <Image src={organismLogo} />
                                    </Box>
                                )}
                            </Box>

                            {((!companyIsOpened && isMobile) || isDesktop) &&
                                idOrganism !== concatIdOrganismServiceType(EMPTY_STR, EMPTY_STR) && (
                                    <React.Fragment>
                                        {/* serviceTypeDescription */}
                                        <Box className="service-type">
                                            <Field
                                                component={TextField}
                                                hidePlaceholder
                                                idForm={FORM_ID}
                                                mode={MODE.VIEW}
                                                name="serviceTypeDescription"
                                                type="text"
                                                value={serviceTypeDescription}
                                            />
                                        </Box>

                                        {/* serviceTypeDescription */}
                                        {/*
                                        Uncomment when payment history is developed
                                        Uncomment when payment history is developed
                                        Uncomment when payment history is developed
                                        <Box className="info-label input-field">
                                            <Field
                                                autoComplete="off"
                                                className="form-control"
                                                component={TextField}
                                                hidePlaceholder
                                                idForm={FORM_ID}
                                                mode={MODE.EDIT}
                                                name="description"
                                                optional={UtilsI18n.get("form.field.optional")}
                                                tooltip={<I18n id={`${FORM_ID}.step1.description.tooltip`} />}
                                                type="text"
                                            />
                                        </Box>
                                        */}
                                    </React.Fragment>
                                )}
                        </Box>

                        {/* debitAccount */}
                        {((!companyIsOpened && isMobile) || isDesktop) &&
                            idOrganism !== concatIdOrganismServiceType(EMPTY_STR, EMPTY_STR) && (
                                <React.Fragment>
                                    <Box className="form-section-title">
                                        <h3 className="form-section-title-text">
                                            {UtilsI18n.get(`${FORM_ID}.step1.payment.title`)}
                                        </h3>
                                    </Box>
                                    <Box className="form-group form-data">
                                        <Box className="input-select account-select">
                                            <Field
                                                className="flex-container slideFromBottom"
                                                clearable={false}
                                                component={Selector}
                                                idForm={FORM_ID}
                                                labelKey="label"
                                                name="debitAccount"
                                                onChange={(item) => {
                                                    if (item) {
                                                        this.handleAccountChange(item.id);
                                                    }
                                                }}
                                                optionClassName="needsclick"
                                                options={accountOptions}
                                                value={debitAccount}
                                                valueKey="id"
                                            />
                                        </Box>
                                        {organismFieldsAsComponents}
                                    </Box>
                                </React.Fragment>
                            )}

                        {/* bills */}
                        {hasBills && (
                            <React.Fragment>
                                <Box className="form-section-title">
                                    <h3 className="form-section-title-text">
                                        {UtilsI18n.get(`${FORM_ID}.step1.bills.title`)}
                                    </h3>
                                </Box>
                                <Box className="form-group form-data bills">
                                    <GridLayout className="header">
                                        {!isMobile &&
                                            COLUMN_HEADINGS_WEB.map((heading, index) => (
                                                <Box justify={index === 3 ? "end" : EMPTY_STR} className="web-only">
                                                    {heading}
                                                </Box>
                                            ))}
                                        {isMobile &&
                                            COLUMN_HEADINGS_MOBILE.map((heading, index) => (
                                                <Box justify={index === 1 ? "end" : EMPTY_STR}>{heading}</Box>
                                            ))}
                                    </GridLayout>
                                    {billsToPay.map((movement, index) => {
                                        return !isMobile
                                            ? renderWebBills(movement, index)
                                            : renderMobileBills(movement, index);
                                    })}
                                </Box>
                                <Box className="form-group form-data totalizer">
                                    <I18n className="highlights" id={`${FORM_ID}.step1.payment.totalizer`} />
                                    <DataNumber prefix={ZERO_NUMBER} value={amountsToPay["0"] ? amountsToPay[0] : 0} />
                                </Box>
                            </React.Fragment>
                        )}
                        {billsToPay === null && withoutBills && (
                            <Box className="form-group form-data with-out-bills">
                                <I18n id={`${FORM_ID}.step1.bills.withoutBills`} />
                            </Box>
                        )}

                        {((!companyIsOpened && isMobile) || isDesktop) &&
                            idOrganism !== concatIdOrganismServiceType(EMPTY_STR, EMPTY_STR) && (
                                <Box className="button">
                                    <Button
                                        onClick={this.handleFetchBillsToPay}
                                        variant={!hasBills ? "primary" : "secondary"}>
                                        {UtilsI18n.get(`${FORM_ID}.step1.bills.button.fetch`)}
                                    </Button>
                                    {hasBills && (
                                        <Button variant="primary" type="submit">
                                            {UtilsI18n.get(`${FORM_ID}.step1.bills.button.send`)}
                                        </Button>
                                    )}
                                </Box>
                            )}
                    </section>
                </Form>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        billsToPay: SelectorsStoreServicePayment.getDocumentsToPay(store),
        debitAccounts: SelectorsStoreServicePayment.getDebitAccounts(store),
        formData: SelectorsStoreServicePayment.getFormData(store),
        sistarbancAvailableServices: SelectorsStoreServicePayment.getSistarbancAvailableServices(store),
    };
};

export default HighOrder(
    HighOrder.Resizable,
    WithRouter,
    Connect(mapStateToProps),
    WithFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => {
            const { debitAccounts, formData, sistarbancAvailableServices } = props;

            const bills = [];
            let checkboxes = [];
            let idOrganism = EMPTY_STR;
            let serviceType = EMPTY_STR;
            let serviceTypeDescription = EMPTY_STR;

            if (formData) {
                formData.billsToPay.forEach((bill, index) => {
                    bills.push(`bill_${index}`);
                });

                checkboxes = Array(formData.billsToPay.length).fill(false);

                formData.checkboxes.forEach((checkbox) => {
                    checkboxes[checkbox] = true;
                });

                idOrganism = formData.idOrganism;
                serviceType = formData.serviceType;
                serviceTypeDescription = formData.serviceTypeDescription;
            }

            const organism =
                sistarbancAvailableServices &&
                sistarbancAvailableServices.find(
                    (service) => service.idOrganism === idOrganism && service.serviceType === serviceType,
                );

            const organismFields = organism && organism.fields;

            const resp = {
                checkboxes,
                debitAccount: (formData && formData.debitAccount) || (debitAccounts && debitAccounts[0].idProduct),
                description: (formData && formData.description) || EMPTY_STR,
                idOrganism: concatIdOrganismServiceType(idOrganism, serviceType),
                organismLogo: organism && organism.urlLogoImage,
                serviceType,
                serviceTypeDescription,
            };

            if (bills && bills.length > 0) {
                bills.forEach((bill, index) => {
                    resp[bills[index]] = formData.checkboxes.includes(index);
                });
            }

            if (organismFields) {
                if (formData) {
                    organismFields.forEach((field, index) => {
                        resp[`${field.name}`] = formData.idServiceList[index];
                    });
                } else {
                    organismFields.forEach((field) => {
                        resp[`${field.name}`] = EMPTY_STR;
                    });
                }
            }

            return resp;
        },
        validationSchema: (props) => {
            return Yup.lazy((values) => {
                const { billsToPay } = props;
                const { checkboxes } = values;

                const checkToHighlightIfError = `bill_${billsToPay?.length - 1}`;

                return Yup.object().shape({
                    debitAccount: Yup.string().required(UtilsI18n.get(`${FORM_ID}.step1.debitAccount.required`)),
                    idOrganism: Yup.string().required(UtilsI18n.get(`${FORM_ID}.step1.idOrganism.required`)),
                    serviceTypeDescription: Yup.string().required(
                        UtilsI18n.get(`${FORM_ID}.step1.serviceTypeDescription.required`),
                    ),
                    [checkToHighlightIfError]: checkboxes.some((checkbox) => checkbox === true)
                        ? Yup.boolean().notRequired()
                        : Yup.boolean().oneOf([true], UtilsI18n.get(`${FORM_ID}.step1.checkboxes.required`)),
                });
            });
        },
        handleSubmit: (props, formikBag) => {
            const { billsToPay, dispatch, sistarbancAvailableServices } = formikBag.props;
            const { checkboxes, debitAccount, description, idOrganism, serviceType, serviceTypeDescription } = props;
            const idServiceList = [];
            const splitIdOrganism = splitIdOrganismServiceType(idOrganism);

            const organism =
                sistarbancAvailableServices &&
                sistarbancAvailableServices.find(
                    (service) => idOrganism === concatIdOrganismServiceType(service.idOrganism, service.serviceType),
                );

            const organismFields = organism?.fields;

            const formData = {
                billsToPay,
                billsToPayIdList: billsToPay.map((bill) => {
                    return bill.idDocument;
                }),
                checkboxes: checkboxes.map((check, i) => (check ? i : -1)).filter((index) => index !== -1),
                debitAccount,
                description,
                idOrganism: splitIdOrganism[0],
                organismDescription: organismDescriptionToShow(organism),
                serviceType,
                serviceTypeDescription,
            };

            organismFields.forEach((orgField) => {
                idServiceList.push(props[orgField.name]);
            });

            formData.idServiceList = idServiceList;

            dispatch(
                SelectorsActionServicePayment.servicePaymentPreviewRequest({ formData, formikBag, values: props }),
            );
        },
    }),
)(Component);
