import { TYPE, PROP } from "./_consts";

export default (storeProp = PROP.defaults, action) => {
    switch (action.type) {
        case TYPE.LOAD_CAMPAIGN_REQUEST:
            return {
                ...storeProp,
                fetching: true,
            };
        case TYPE.LOAD_CAMPAIGN_SUCCESS:
            return {
                ...storeProp,
                fetching: false,
                items: [...storeProp.items, action.item],
            };
        case TYPE.LOAD_CAMPAIGN_FAILURE:
            return { ...storeProp, fetching: false };

        case TYPE.REMOVE_CAMPAIGNS:
            return PROP.defaults;

        default:
            return storeProp;
    }
};
