import React from "react";

import { Field } from "formik";
import PropTypes from "prop-types";

import { FORMAT_DATE_SCHEDULER_FIELD } from "~/constants";
import { specificDate, isSameDay as IsSameDay, addDay as AddDay, getISOWeek as GetISOWeek } from "~/util/date";

import I18n from "~/components/I18n";
import DateField from "~/pages/_components/fields/DateField";

import { PROP as PropLSO } from "~/pages/forms/_components/_fields/_scheduler/LapseSubOption";

export const NAME = "FutureFrequencySubOption";

export const PROP = {
    types: {
        enabledWeekDays: PropLSO.enabledWeekDays,
        firstWorkingDate: PropLSO.firstWorkingDate,
        nonWorkingDays: PropLSO.nonWorkingDays,
        maxDaysToSchedule: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropLSO.value,
    },
    defaults: {
        enabledWeekDays: null,
        firstWorkingDate: null,
        nonWorkingDays: null,
        value: null,
    },
};

export class Component extends React.Component {
    static displayName = NAME;

    static propTypes = PROP.types;

    static defaultProps = PROP.defaults;

    getExcludeDates = () => {
        const { nonWorkingDays } = this.props;

        const excludeDates = [];
        nonWorkingDays.forEach((entry) => {
            excludeDates.push(specificDate(entry, FORMAT_DATE_SCHEDULER_FIELD));
        });

        return excludeDates;
    };

    filterDate = (date) => {
        const { enabledWeekDays } = this.props;
        return enabledWeekDays[(GetISOWeek(date) % 7) + 1];
    };

    handleChange = (date) => {
        const { onChange } = this.props;

        if (date) {
            onChange(date);
        }
    };

    handleChangeRaw = (event) => {
        event.preventDefault();
    };

    render() {
        const { firstWorkingDate, maxDaysToSchedule, value, idForm } = this.props;
        let selected = new Date(specificDate(value, FORMAT_DATE_SCHEDULER_FIELD));
        let minDate = new Date(specificDate(firstWorkingDate, FORMAT_DATE_SCHEDULER_FIELD));
        const maxDate = AddDay(minDate, maxDaysToSchedule);
        if (IsSameDay(minDate)) {
            do {
                minDate = AddDay(minDate, 1);
            } while (this.getExcludeDates().includes(specificDate(minDate, FORMAT_DATE_SCHEDULER_FIELD)));
            if (minDate > selected) {
                selected = minDate;
                this.handleChange(selected);
            }
        }

        return (
            <div className="scheduler-frequency-suboptions">
                <div className="form-group form-group--datepicker form-group-row">
                    <div className="form-text-group">
                        <label className="control-label">
                            <I18n id="scheduler.executionFutureSubOption" />
                        </label>
                    </div>
                    <Field
                        hideLabel
                        component={DateField}
                        className="form-control"
                        excludeDates={this.getExcludeDates()}
                        filterDate={this.filterDate}
                        maxDate={maxDate}
                        minDate={minDate}
                        id="futureValue"
                        name="futureValue"
                        onChange={this.handleChange}
                        onChangeRaw={this.handleChangeRaw}
                        selected={selected}
                        autoComplete="off"
                    />
                    {idForm && (
                        <div className="tooltip">
                            <span className="tooltip-text">
                                {" "}
                                <I18n id={`${idForm}.date.tooltip`} maxDaysToSchedule={maxDaysToSchedule} />
                            </span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default Component;
