import React from "react";

import { Form, withFormik as WithFormik } from "formik";
import PropTypes from "prop-types";
import { connect as Connect } from "react-redux";
import { withRouter as WithRouter } from "react-router-dom";
import * as Yup from "yup";

import { EMPTY_STR } from "~/constants";
import { SelectorsStore as SelectorsStoreComex, SelectorsAction as SelectorsActionComex } from "~/store/comex";
import {
    SelectorsStore as SelectorsStoreComexExport,
    SelectorsAction as SelectorsActionExport,
} from "~/store/comex/export";
import { SelectorsStore as SelectorsStoreI18n } from "~/store/i18n";
import { SelectorsStore as SelectorsStoreSession } from "~/store/session";
import { SelectorsAction as SelectorsActionTransactions } from "~/store/transactions";
import { specificDate as SpecificDate } from "~/util/date";
import DeviceUtils from "~/util/device";
import * as UtilsI18n from "~/util/i18n";

import Box from "~/components/Box";
import Button from "~/components/Button";
import HighOrder from "~/components/HighOrder";
import Token from "~/components/Token/Token";
import TicketPrintInformation from "~/pages/_components/TicketPrintInformation";

import { ID_ACTIVITY_SEND } from "../FormConstants";
import Style from "./Step2Preview.rules.scss";

export const { NAME } = Style;

export const PROP = {
    types: {
        isSubmitting: PropTypes.bool,
    },
    defaults: {
        isSubmitting: false,
    },
};

const printForm = () => {
    const elements = window.document.getElementsByClassName("no_print");

    for (let i = 0; i < elements.length; i += 1) {
        elements[i].style.display = "none";
    }

    window.print();

    for (let i = 0; i < elements.length; i += 1) {
        elements[i].style.display = EMPTY_STR;
    }

    return true;
};
export function Component(props) {
    const { isSubmitting, dispatch, formData } = props;

    const {
        valueDate,
        creditLetterNumber,
        accountNumber,
        issuingBank,
        interestRate,
        transferAmountInWords,
        amountInWords,
        currency,
        operationNumber,
        subOperationNumber,
        amountString,
        transferAmountString,
    } = formData;

    const buildAmount = (amountBuild) => `${UtilsI18n.get(`currency.label.${currency}`)} ${amountBuild} `;

    const shareTicket = () => {
        dispatch(
            SelectorsActionTransactions.shareTicketRequest({
                transactionData: formData,
                idActivity: ID_ACTIVITY_SEND,
            }),
        );
    };

    return (
        <Form id={Style.ID}>
            <TicketPrintInformation />
            <Box flex directionColumn align="flex-start" className="mb-3">
                <p
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: UtilsI18n.get("comex.export.creditLetterPurchase.letter.notCeded", null, {
                            FECHA: SpecificDate(valueDate),
                            NRO_SUB_OPERACION: `${subOperationNumber}.`,
                            NRO_DE_CUENTA: accountNumber,
                            NRO_OPERACION: operationNumber,
                            NRO_CARTA_CREDITO: creditLetterNumber,
                            BANCO_EMISOR: issuingBank,
                            BENEFICIARIO: accountNumber,
                            IMPORTE: buildAmount(transferAmountString),
                            IMPORTE_LETRA: `( ${transferAmountInWords} )`,
                            TASA: interestRate,
                            SALDO_CONTABLE: buildAmount(amountString),
                            SALDO_CONTABLE_LETRA: `( ${amountInWords} ) `,
                        }),
                    }}
                />
            </Box>

            <div className="no_print">
                <Token labelButton="global.send" isSubmitting={isSubmitting} />
            </div>
            {!DeviceUtils.isMobileNative() && (
                <div className="buttonsTicketConfirmation">
                    <Button
                        block={false}
                        bsStyle="link"
                        label="comex.preview.print.label"
                        onClick={() => printForm()}
                    />
                </div>
            )}
            <div className="buttonsTicketConfirmation">
                <Button bsStyle="link" block={false} label="forms.ticket.printPDF" onClick={() => shareTicket()} />
            </div>
        </Form>
    );
}

Component.displayName = NAME;
Component.propTypes = PROP.types;
Component.defaultProps = PROP.defaults;

const mapStateToProps = (store) => {
    return {
        formData: SelectorsStoreComexExport.getcomexFormTicket(store),
        lang: SelectorsStoreI18n.getLang(store),
        prevData: SelectorsStoreComexExport.getComexFormPrevData(store),
        transaction: SelectorsStoreComex.getTransaction(store),
        user: SelectorsStoreSession.getUser(store),
    };
};

export default HighOrder(
    Connect(mapStateToProps),
    WithRouter,
    WithFormik({
        mapPropsToValues: () => ({
            otp: EMPTY_STR,
        }),
        validationSchema: (props) => {
            const { user } = props;

            return Yup.object().shape({
                otp: user.needsBiometric
                    ? Yup.string().nullable()
                    : Yup.string().required(
                          UtilsI18n.get("comex.export.presentationDocumentsCreditLetter.step2.otp.required"),
                      ),
            });
        },
        handleSubmit: ({ otp }, formikBag) => {
            const {
                dispatch,
                history,
                formData,
                transaction: { idTransaction, idActivity },
                prevData,
            } = formikBag.props;

            const isFromTransactionsHistory = history.location.pathname.includes("/ticket");
            if (isFromTransactionsHistory) {
                dispatch(
                    SelectorsActionComex.signTransaction({
                        idForm: null,
                        idActivity,
                        idTransaction,
                        credentials: { otp },
                        formikBag,
                    }),
                );
            } else {
                dispatch(
                    SelectorsActionExport.purchaseDocumentsSendRequest({
                        formData: {
                            ...formData,
                            item: prevData.item,
                            valueDate: prevData.valueDate,
                            scheduler: prevData.scheduler,
                            invoiceAmount: formData.amount,
                            amount: { quantity: formData.amount, currency: formData.currency },
                        },
                        credentials: { otp },
                        formikBag,
                        idTransaction,
                    }),
                );
            }
        },
    }),
)(Component);
